/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.palexy = (function() {
    
        /**
         * Namespace palexy.
         * @exports palexy
         * @namespace
         */
        var palexy = {};
    
        palexy.sherlock = (function() {
    
            /**
             * Namespace sherlock.
             * @memberof palexy
             * @namespace
             */
            var sherlock = {};
    
            sherlock.v1 = (function() {
    
                /**
                 * Namespace v1.
                 * @memberof palexy.sherlock
                 * @namespace
                 */
                var v1 = {};
    
                v1.SherlockTask = (function() {
    
                    /**
                     * Properties of a SherlockTask.
                     * @memberof palexy.sherlock.v1
                     * @interface ISherlockTask
                     * @property {palexy.sherlock.v1.ITaskAdvanceInfo|null} [taskAdvanceInfo] SherlockTask taskAdvanceInfo
                     * @property {palexy.sherlock.v1.SherlockTask.TaskStatus|null} [taskStatus] SherlockTask taskStatus
                     * @property {google.protobuf.ITimestamp|null} [createTime] SherlockTask createTime
                     * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] SherlockTask lastUpdateTime
                     * @property {google.protobuf.ITimestamp|null} [startProcessingTime] SherlockTask startProcessingTime
                     * @property {google.protobuf.ITimestamp|null} [endProcessingTime] SherlockTask endProcessingTime
                     * @property {boolean|null} [generateBbLevelDetail] SherlockTask generateBbLevelDetail
                     * @property {string|null} [name] SherlockTask name
                     * @property {palexy.sherlock.v1.SherlockTask.TaskType|null} [taskType] SherlockTask taskType
                     * @property {string|null} [displayName] SherlockTask displayName
                     * @property {number|null} [id] SherlockTask id
                     * @property {number|null} [storeConfigId] SherlockTask storeConfigId
                     * @property {Array.<palexy.sherlock.v1.ISherlockTaskResult>|null} [taskResults] SherlockTask taskResults
                     */
    
                    /**
                     * Constructs a new SherlockTask.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a SherlockTask.
                     * @implements ISherlockTask
                     * @constructor
                     * @param {palexy.sherlock.v1.ISherlockTask=} [properties] Properties to set
                     */
                    function SherlockTask(properties) {
                        this.taskResults = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SherlockTask taskAdvanceInfo.
                     * @member {palexy.sherlock.v1.ITaskAdvanceInfo|null|undefined} taskAdvanceInfo
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.taskAdvanceInfo = null;
    
                    /**
                     * SherlockTask taskStatus.
                     * @member {palexy.sherlock.v1.SherlockTask.TaskStatus} taskStatus
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.taskStatus = 0;
    
                    /**
                     * SherlockTask createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.createTime = null;
    
                    /**
                     * SherlockTask lastUpdateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.lastUpdateTime = null;
    
                    /**
                     * SherlockTask startProcessingTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} startProcessingTime
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.startProcessingTime = null;
    
                    /**
                     * SherlockTask endProcessingTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} endProcessingTime
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.endProcessingTime = null;
    
                    /**
                     * SherlockTask generateBbLevelDetail.
                     * @member {boolean} generateBbLevelDetail
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.generateBbLevelDetail = false;
    
                    /**
                     * SherlockTask name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.name = "";
    
                    /**
                     * SherlockTask taskType.
                     * @member {palexy.sherlock.v1.SherlockTask.TaskType} taskType
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.taskType = 0;
    
                    /**
                     * SherlockTask displayName.
                     * @member {string} displayName
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.displayName = "";
    
                    /**
                     * SherlockTask id.
                     * @member {number} id
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * SherlockTask storeConfigId.
                     * @member {number} storeConfigId
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.storeConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * SherlockTask taskResults.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTaskResult>} taskResults
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     */
                    SherlockTask.prototype.taskResults = $util.emptyArray;
    
                    /**
                     * Creates a new SherlockTask instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @static
                     * @param {palexy.sherlock.v1.ISherlockTask=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.SherlockTask} SherlockTask instance
                     */
                    SherlockTask.create = function create(properties) {
                        return new SherlockTask(properties);
                    };
    
                    /**
                     * Encodes the specified SherlockTask message. Does not implicitly {@link palexy.sherlock.v1.SherlockTask.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @static
                     * @param {palexy.sherlock.v1.ISherlockTask} message SherlockTask message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SherlockTask.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.taskAdvanceInfo != null && Object.hasOwnProperty.call(message, "taskAdvanceInfo"))
                            $root.palexy.sherlock.v1.TaskAdvanceInfo.encode(message.taskAdvanceInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.taskStatus != null && Object.hasOwnProperty.call(message, "taskStatus"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.taskStatus);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.startProcessingTime != null && Object.hasOwnProperty.call(message, "startProcessingTime"))
                            $root.google.protobuf.Timestamp.encode(message.startProcessingTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.endProcessingTime != null && Object.hasOwnProperty.call(message, "endProcessingTime"))
                            $root.google.protobuf.Timestamp.encode(message.endProcessingTime, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.generateBbLevelDetail != null && Object.hasOwnProperty.call(message, "generateBbLevelDetail"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.generateBbLevelDetail);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.name);
                        if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.taskType);
                        if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.displayName);
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.id);
                        if (message.storeConfigId != null && Object.hasOwnProperty.call(message, "storeConfigId"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int64(message.storeConfigId);
                        if (message.taskResults != null && message.taskResults.length)
                            for (var i = 0; i < message.taskResults.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTaskResult.encode(message.taskResults[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SherlockTask message, length delimited. Does not implicitly {@link palexy.sherlock.v1.SherlockTask.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @static
                     * @param {palexy.sherlock.v1.ISherlockTask} message SherlockTask message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SherlockTask.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SherlockTask message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.SherlockTask} SherlockTask
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SherlockTask.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.SherlockTask();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.taskAdvanceInfo = $root.palexy.sherlock.v1.TaskAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.taskStatus = reader.int32();
                                break;
                            case 3:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.startProcessingTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.endProcessingTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.generateBbLevelDetail = reader.bool();
                                break;
                            case 8:
                                message.name = reader.string();
                                break;
                            case 9:
                                message.taskType = reader.int32();
                                break;
                            case 10:
                                message.displayName = reader.string();
                                break;
                            case 11:
                                message.id = reader.int64();
                                break;
                            case 12:
                                message.storeConfigId = reader.int64();
                                break;
                            case 13:
                                if (!(message.taskResults && message.taskResults.length))
                                    message.taskResults = [];
                                message.taskResults.push($root.palexy.sherlock.v1.SherlockTaskResult.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SherlockTask message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.SherlockTask} SherlockTask
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SherlockTask.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SherlockTask message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SherlockTask.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.taskAdvanceInfo != null && message.hasOwnProperty("taskAdvanceInfo")) {
                            var error = $root.palexy.sherlock.v1.TaskAdvanceInfo.verify(message.taskAdvanceInfo);
                            if (error)
                                return "taskAdvanceInfo." + error;
                        }
                        if (message.taskStatus != null && message.hasOwnProperty("taskStatus"))
                            switch (message.taskStatus) {
                            default:
                                return "taskStatus: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                            if (error)
                                return "lastUpdateTime." + error;
                        }
                        if (message.startProcessingTime != null && message.hasOwnProperty("startProcessingTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.startProcessingTime);
                            if (error)
                                return "startProcessingTime." + error;
                        }
                        if (message.endProcessingTime != null && message.hasOwnProperty("endProcessingTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.endProcessingTime);
                            if (error)
                                return "endProcessingTime." + error;
                        }
                        if (message.generateBbLevelDetail != null && message.hasOwnProperty("generateBbLevelDetail"))
                            if (typeof message.generateBbLevelDetail !== "boolean")
                                return "generateBbLevelDetail: boolean expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.taskType != null && message.hasOwnProperty("taskType"))
                            switch (message.taskType) {
                            default:
                                return "taskType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                                break;
                            }
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (!$util.isInteger(message.storeConfigId) && !(message.storeConfigId && $util.isInteger(message.storeConfigId.low) && $util.isInteger(message.storeConfigId.high)))
                                return "storeConfigId: integer|Long expected";
                        if (message.taskResults != null && message.hasOwnProperty("taskResults")) {
                            if (!Array.isArray(message.taskResults))
                                return "taskResults: array expected";
                            for (var i = 0; i < message.taskResults.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTaskResult.verify(message.taskResults[i]);
                                if (error)
                                    return "taskResults." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a SherlockTask message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.SherlockTask} SherlockTask
                     */
                    SherlockTask.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.SherlockTask)
                            return object;
                        var message = new $root.palexy.sherlock.v1.SherlockTask();
                        if (object.taskAdvanceInfo != null) {
                            if (typeof object.taskAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.SherlockTask.taskAdvanceInfo: object expected");
                            message.taskAdvanceInfo = $root.palexy.sherlock.v1.TaskAdvanceInfo.fromObject(object.taskAdvanceInfo);
                        }
                        switch (object.taskStatus) {
                        case "STATUS_UNKNOWN":
                        case 0:
                            message.taskStatus = 0;
                            break;
                        case "STATUS_CREATED":
                        case 1:
                            message.taskStatus = 1;
                            break;
                        case "STATUS_DONE":
                        case 2:
                            message.taskStatus = 2;
                            break;
                        case "STATUS_FAILED":
                        case 3:
                            message.taskStatus = 3;
                            break;
                        }
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.sherlock.v1.SherlockTask.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.lastUpdateTime != null) {
                            if (typeof object.lastUpdateTime !== "object")
                                throw TypeError(".palexy.sherlock.v1.SherlockTask.lastUpdateTime: object expected");
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                        }
                        if (object.startProcessingTime != null) {
                            if (typeof object.startProcessingTime !== "object")
                                throw TypeError(".palexy.sherlock.v1.SherlockTask.startProcessingTime: object expected");
                            message.startProcessingTime = $root.google.protobuf.Timestamp.fromObject(object.startProcessingTime);
                        }
                        if (object.endProcessingTime != null) {
                            if (typeof object.endProcessingTime !== "object")
                                throw TypeError(".palexy.sherlock.v1.SherlockTask.endProcessingTime: object expected");
                            message.endProcessingTime = $root.google.protobuf.Timestamp.fromObject(object.endProcessingTime);
                        }
                        if (object.generateBbLevelDetail != null)
                            message.generateBbLevelDetail = Boolean(object.generateBbLevelDetail);
                        if (object.name != null)
                            message.name = String(object.name);
                        switch (object.taskType) {
                        case "TYPE_UNKNOWN":
                        case 0:
                            message.taskType = 0;
                            break;
                        case "TYPE_TEST":
                        case 1:
                            message.taskType = 1;
                            break;
                        case "TYPE_DEBUG_PERSON":
                        case 2:
                            message.taskType = 2;
                            break;
                        case "TYPE_EXTRACT_TRACKLET":
                        case 3:
                            message.taskType = 3;
                            break;
                        case "TYPE_DELETE_TASK_FILES":
                        case 4:
                            message.taskType = 4;
                            break;
                        case "TYPE_CALCULATE_MERGE_ACCURACY":
                        case 5:
                            message.taskType = 5;
                            break;
                        case "TYPE_EXECUTE_POST_MERGE_CONFIG":
                        case 6:
                            message.taskType = 6;
                            break;
                        case "TYPE_SCAN_MERGE_CONFIG":
                        case 7:
                            message.taskType = 7;
                            break;
                        case "TYPE_RERUN_MERGE_CONFIG":
                        case 8:
                            message.taskType = 8;
                            break;
                        case "TYPE_DEBUG_EXPERIMENT":
                        case 9:
                            message.taskType = 9;
                            break;
                        case "TYPE_PREPARE_QC_VIDEOS":
                        case 10:
                            message.taskType = 10;
                            break;
                        case "TYPE_HS_COLLECT_REID":
                        case 11:
                            message.taskType = 11;
                            break;
                        case "TYPE_TINKER_POST_PROCESS":
                        case 12:
                            message.taskType = 12;
                            break;
                        case "TYPE_FORECAST_GENERATE":
                        case 13:
                            message.taskType = 13;
                            break;
                        case "TYPE_EXPORT_HAKUHODO":
                        case 14:
                            message.taskType = 14;
                            break;
                        case "TYPE_EXPORT_OSOOL_EVENTS":
                        case 15:
                            message.taskType = 15;
                            break;
                        case "TYPE_HAKUHODO_JOIN_PARTIAL_MERGING_RESULTS":
                        case 16:
                            message.taskType = 16;
                            break;
                        case "TYPE_HAKUHODO_EDIT_TRAFFIC":
                        case 17:
                            message.taskType = 17;
                            break;
                        case "TYPE_HAKUHODO_EXTRAPOLATE_DEMOGRAPHY":
                        case 18:
                            message.taskType = 18;
                            break;
                        case "TYPE_BATCH_DELETE_VIDEOS":
                        case 19:
                            message.taskType = 19;
                            break;
                        case "TYPE_BUILD_SEED":
                        case 20:
                            message.taskType = 20;
                            break;
                        }
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.storeConfigId != null)
                            if ($util.Long)
                                (message.storeConfigId = $util.Long.fromValue(object.storeConfigId)).unsigned = false;
                            else if (typeof object.storeConfigId === "string")
                                message.storeConfigId = parseInt(object.storeConfigId, 10);
                            else if (typeof object.storeConfigId === "number")
                                message.storeConfigId = object.storeConfigId;
                            else if (typeof object.storeConfigId === "object")
                                message.storeConfigId = new $util.LongBits(object.storeConfigId.low >>> 0, object.storeConfigId.high >>> 0).toNumber();
                        if (object.taskResults) {
                            if (!Array.isArray(object.taskResults))
                                throw TypeError(".palexy.sherlock.v1.SherlockTask.taskResults: array expected");
                            message.taskResults = [];
                            for (var i = 0; i < object.taskResults.length; ++i) {
                                if (typeof object.taskResults[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.SherlockTask.taskResults: object expected");
                                message.taskResults[i] = $root.palexy.sherlock.v1.SherlockTaskResult.fromObject(object.taskResults[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SherlockTask message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @static
                     * @param {palexy.sherlock.v1.SherlockTask} message SherlockTask
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SherlockTask.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.taskResults = [];
                        if (options.defaults) {
                            object.taskAdvanceInfo = null;
                            object.taskStatus = options.enums === String ? "STATUS_UNKNOWN" : 0;
                            object.createTime = null;
                            object.lastUpdateTime = null;
                            object.startProcessingTime = null;
                            object.endProcessingTime = null;
                            object.generateBbLevelDetail = false;
                            object.name = "";
                            object.taskType = options.enums === String ? "TYPE_UNKNOWN" : 0;
                            object.displayName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeConfigId = options.longs === String ? "0" : 0;
                        }
                        if (message.taskAdvanceInfo != null && message.hasOwnProperty("taskAdvanceInfo"))
                            object.taskAdvanceInfo = $root.palexy.sherlock.v1.TaskAdvanceInfo.toObject(message.taskAdvanceInfo, options);
                        if (message.taskStatus != null && message.hasOwnProperty("taskStatus"))
                            object.taskStatus = options.enums === String ? $root.palexy.sherlock.v1.SherlockTask.TaskStatus[message.taskStatus] : message.taskStatus;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                            object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                        if (message.startProcessingTime != null && message.hasOwnProperty("startProcessingTime"))
                            object.startProcessingTime = $root.google.protobuf.Timestamp.toObject(message.startProcessingTime, options);
                        if (message.endProcessingTime != null && message.hasOwnProperty("endProcessingTime"))
                            object.endProcessingTime = $root.google.protobuf.Timestamp.toObject(message.endProcessingTime, options);
                        if (message.generateBbLevelDetail != null && message.hasOwnProperty("generateBbLevelDetail"))
                            object.generateBbLevelDetail = message.generateBbLevelDetail;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.taskType != null && message.hasOwnProperty("taskType"))
                            object.taskType = options.enums === String ? $root.palexy.sherlock.v1.SherlockTask.TaskType[message.taskType] : message.taskType;
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (typeof message.storeConfigId === "number")
                                object.storeConfigId = options.longs === String ? String(message.storeConfigId) : message.storeConfigId;
                            else
                                object.storeConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.storeConfigId) : options.longs === Number ? new $util.LongBits(message.storeConfigId.low >>> 0, message.storeConfigId.high >>> 0).toNumber() : message.storeConfigId;
                        if (message.taskResults && message.taskResults.length) {
                            object.taskResults = [];
                            for (var j = 0; j < message.taskResults.length; ++j)
                                object.taskResults[j] = $root.palexy.sherlock.v1.SherlockTaskResult.toObject(message.taskResults[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this SherlockTask to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.SherlockTask
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SherlockTask.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * TaskType enum.
                     * @name palexy.sherlock.v1.SherlockTask.TaskType
                     * @enum {number}
                     * @property {number} TYPE_UNKNOWN=0 TYPE_UNKNOWN value
                     * @property {number} TYPE_TEST=1 TYPE_TEST value
                     * @property {number} TYPE_DEBUG_PERSON=2 TYPE_DEBUG_PERSON value
                     * @property {number} TYPE_EXTRACT_TRACKLET=3 TYPE_EXTRACT_TRACKLET value
                     * @property {number} TYPE_DELETE_TASK_FILES=4 TYPE_DELETE_TASK_FILES value
                     * @property {number} TYPE_CALCULATE_MERGE_ACCURACY=5 TYPE_CALCULATE_MERGE_ACCURACY value
                     * @property {number} TYPE_EXECUTE_POST_MERGE_CONFIG=6 TYPE_EXECUTE_POST_MERGE_CONFIG value
                     * @property {number} TYPE_SCAN_MERGE_CONFIG=7 TYPE_SCAN_MERGE_CONFIG value
                     * @property {number} TYPE_RERUN_MERGE_CONFIG=8 TYPE_RERUN_MERGE_CONFIG value
                     * @property {number} TYPE_DEBUG_EXPERIMENT=9 TYPE_DEBUG_EXPERIMENT value
                     * @property {number} TYPE_PREPARE_QC_VIDEOS=10 TYPE_PREPARE_QC_VIDEOS value
                     * @property {number} TYPE_HS_COLLECT_REID=11 TYPE_HS_COLLECT_REID value
                     * @property {number} TYPE_TINKER_POST_PROCESS=12 TYPE_TINKER_POST_PROCESS value
                     * @property {number} TYPE_FORECAST_GENERATE=13 TYPE_FORECAST_GENERATE value
                     * @property {number} TYPE_EXPORT_HAKUHODO=14 TYPE_EXPORT_HAKUHODO value
                     * @property {number} TYPE_EXPORT_OSOOL_EVENTS=15 TYPE_EXPORT_OSOOL_EVENTS value
                     * @property {number} TYPE_HAKUHODO_JOIN_PARTIAL_MERGING_RESULTS=16 TYPE_HAKUHODO_JOIN_PARTIAL_MERGING_RESULTS value
                     * @property {number} TYPE_HAKUHODO_EDIT_TRAFFIC=17 TYPE_HAKUHODO_EDIT_TRAFFIC value
                     * @property {number} TYPE_HAKUHODO_EXTRAPOLATE_DEMOGRAPHY=18 TYPE_HAKUHODO_EXTRAPOLATE_DEMOGRAPHY value
                     * @property {number} TYPE_BATCH_DELETE_VIDEOS=19 TYPE_BATCH_DELETE_VIDEOS value
                     * @property {number} TYPE_BUILD_SEED=20 TYPE_BUILD_SEED value
                     */
                    SherlockTask.TaskType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "TYPE_UNKNOWN"] = 0;
                        values[valuesById[1] = "TYPE_TEST"] = 1;
                        values[valuesById[2] = "TYPE_DEBUG_PERSON"] = 2;
                        values[valuesById[3] = "TYPE_EXTRACT_TRACKLET"] = 3;
                        values[valuesById[4] = "TYPE_DELETE_TASK_FILES"] = 4;
                        values[valuesById[5] = "TYPE_CALCULATE_MERGE_ACCURACY"] = 5;
                        values[valuesById[6] = "TYPE_EXECUTE_POST_MERGE_CONFIG"] = 6;
                        values[valuesById[7] = "TYPE_SCAN_MERGE_CONFIG"] = 7;
                        values[valuesById[8] = "TYPE_RERUN_MERGE_CONFIG"] = 8;
                        values[valuesById[9] = "TYPE_DEBUG_EXPERIMENT"] = 9;
                        values[valuesById[10] = "TYPE_PREPARE_QC_VIDEOS"] = 10;
                        values[valuesById[11] = "TYPE_HS_COLLECT_REID"] = 11;
                        values[valuesById[12] = "TYPE_TINKER_POST_PROCESS"] = 12;
                        values[valuesById[13] = "TYPE_FORECAST_GENERATE"] = 13;
                        values[valuesById[14] = "TYPE_EXPORT_HAKUHODO"] = 14;
                        values[valuesById[15] = "TYPE_EXPORT_OSOOL_EVENTS"] = 15;
                        values[valuesById[16] = "TYPE_HAKUHODO_JOIN_PARTIAL_MERGING_RESULTS"] = 16;
                        values[valuesById[17] = "TYPE_HAKUHODO_EDIT_TRAFFIC"] = 17;
                        values[valuesById[18] = "TYPE_HAKUHODO_EXTRAPOLATE_DEMOGRAPHY"] = 18;
                        values[valuesById[19] = "TYPE_BATCH_DELETE_VIDEOS"] = 19;
                        values[valuesById[20] = "TYPE_BUILD_SEED"] = 20;
                        return values;
                    })();
    
                    /**
                     * TaskStatus enum.
                     * @name palexy.sherlock.v1.SherlockTask.TaskStatus
                     * @enum {number}
                     * @property {number} STATUS_UNKNOWN=0 STATUS_UNKNOWN value
                     * @property {number} STATUS_CREATED=1 STATUS_CREATED value
                     * @property {number} STATUS_DONE=2 STATUS_DONE value
                     * @property {number} STATUS_FAILED=3 STATUS_FAILED value
                     */
                    SherlockTask.TaskStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "STATUS_UNKNOWN"] = 0;
                        values[valuesById[1] = "STATUS_CREATED"] = 1;
                        values[valuesById[2] = "STATUS_DONE"] = 2;
                        values[valuesById[3] = "STATUS_FAILED"] = 3;
                        return values;
                    })();
    
                    /**
                     * StorageProvider enum.
                     * @name palexy.sherlock.v1.SherlockTask.StorageProvider
                     * @enum {number}
                     * @property {number} UNKNOWN_PROVIDER=0 UNKNOWN_PROVIDER value
                     * @property {number} GCP=1 GCP value
                     * @property {number} GOOGLE_DRIVE=2 GOOGLE_DRIVE value
                     */
                    SherlockTask.StorageProvider = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_PROVIDER"] = 0;
                        values[valuesById[1] = "GCP"] = 1;
                        values[valuesById[2] = "GOOGLE_DRIVE"] = 2;
                        return values;
                    })();
    
                    return SherlockTask;
                })();
    
                v1.TaskAdvanceInfo = (function() {
    
                    /**
                     * Properties of a TaskAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface ITaskAdvanceInfo
                     * @property {palexy.sherlock.v1.IDebugPersonAdvanceInfo|null} [debugPersonAdvanceInfo] TaskAdvanceInfo debugPersonAdvanceInfo
                     * @property {palexy.sherlock.v1.ICalculateMergeAccuracyAdvanceInfo|null} [calculateMergeAccuracyAdvanceInfo] TaskAdvanceInfo calculateMergeAccuracyAdvanceInfo
                     * @property {palexy.sherlock.v1.IExecutePostMergeConfigAdvanceInfo|null} [executePostMergeConfigAdvanceInfo] TaskAdvanceInfo executePostMergeConfigAdvanceInfo
                     * @property {palexy.sherlock.v1.IDeleteTaskFilesAdvanceInfo|null} [deleteTaskFilesAdvanceInfo] TaskAdvanceInfo deleteTaskFilesAdvanceInfo
                     * @property {palexy.sherlock.v1.IScanMergeConfigAdvanceInfo|null} [scanMergeConfigAdvanceInfo] TaskAdvanceInfo scanMergeConfigAdvanceInfo
                     * @property {palexy.sherlock.v1.IRerunMergeConfigAdvanceInfo|null} [rerunMergeConfigAdvanceInfo] TaskAdvanceInfo rerunMergeConfigAdvanceInfo
                     * @property {palexy.sherlock.v1.IDebugExperimentAdvanceInfo|null} [debugExperimentAdvanceInfo] TaskAdvanceInfo debugExperimentAdvanceInfo
                     * @property {palexy.sherlock.v1.IPrepareQcVideosAdvanceInfo|null} [prepareQcVideosAdvanceInfo] TaskAdvanceInfo prepareQcVideosAdvanceInfo
                     * @property {palexy.sherlock.v1.IHSCollectReidAdvanceInfo|null} [hsCollectReidAdvanceInfo] TaskAdvanceInfo hsCollectReidAdvanceInfo
                     * @property {palexy.sherlock.v1.ITinkerPostProcessAdvanceInfo|null} [tinkerPostProcessAdvanceInfo] TaskAdvanceInfo tinkerPostProcessAdvanceInfo
                     * @property {palexy.sherlock.v1.IForecastGenerateAdvanceInfo|null} [forecastGenerateAdvanceInfo] TaskAdvanceInfo forecastGenerateAdvanceInfo
                     * @property {palexy.sherlock.v1.IExportHakuhodoAdvanceInfo|null} [exportHakuhodoAdvanceInfo] TaskAdvanceInfo exportHakuhodoAdvanceInfo
                     * @property {palexy.sherlock.v1.IExportOsoolEventsAdvanceInfo|null} [exportOsoolEventsAdvanceInfo] TaskAdvanceInfo exportOsoolEventsAdvanceInfo
                     * @property {palexy.sherlock.v1.IHakuhodoJoinPartialMergingResultsAdvanceInfo|null} [hakuhodoJoinPartialMergingResultsAdvanceInfo] TaskAdvanceInfo hakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @property {palexy.sherlock.v1.IHakuhodoEditTrafficAdvanceInfo|null} [hakuhodoEditTrafficAdvanceInfo] TaskAdvanceInfo hakuhodoEditTrafficAdvanceInfo
                     * @property {palexy.sherlock.v1.IHakuhodoExtrapolateDemographyAdvanceInfo|null} [hakuhodoExtrapolateDemographyAdvanceInfo] TaskAdvanceInfo hakuhodoExtrapolateDemographyAdvanceInfo
                     * @property {palexy.sherlock.v1.IBatchDeleteVideosAdvanceInfo|null} [batchDeleteVideosAdvanceInfo] TaskAdvanceInfo batchDeleteVideosAdvanceInfo
                     * @property {palexy.sherlock.v1.IBuildSeedAdvanceInfo|null} [buildSeedAdvanceInfo] TaskAdvanceInfo buildSeedAdvanceInfo
                     */
    
                    /**
                     * Constructs a new TaskAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a TaskAdvanceInfo.
                     * @implements ITaskAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.ITaskAdvanceInfo=} [properties] Properties to set
                     */
                    function TaskAdvanceInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TaskAdvanceInfo debugPersonAdvanceInfo.
                     * @member {palexy.sherlock.v1.IDebugPersonAdvanceInfo|null|undefined} debugPersonAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.debugPersonAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo calculateMergeAccuracyAdvanceInfo.
                     * @member {palexy.sherlock.v1.ICalculateMergeAccuracyAdvanceInfo|null|undefined} calculateMergeAccuracyAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.calculateMergeAccuracyAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo executePostMergeConfigAdvanceInfo.
                     * @member {palexy.sherlock.v1.IExecutePostMergeConfigAdvanceInfo|null|undefined} executePostMergeConfigAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.executePostMergeConfigAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo deleteTaskFilesAdvanceInfo.
                     * @member {palexy.sherlock.v1.IDeleteTaskFilesAdvanceInfo|null|undefined} deleteTaskFilesAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.deleteTaskFilesAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo scanMergeConfigAdvanceInfo.
                     * @member {palexy.sherlock.v1.IScanMergeConfigAdvanceInfo|null|undefined} scanMergeConfigAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.scanMergeConfigAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo rerunMergeConfigAdvanceInfo.
                     * @member {palexy.sherlock.v1.IRerunMergeConfigAdvanceInfo|null|undefined} rerunMergeConfigAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.rerunMergeConfigAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo debugExperimentAdvanceInfo.
                     * @member {palexy.sherlock.v1.IDebugExperimentAdvanceInfo|null|undefined} debugExperimentAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.debugExperimentAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo prepareQcVideosAdvanceInfo.
                     * @member {palexy.sherlock.v1.IPrepareQcVideosAdvanceInfo|null|undefined} prepareQcVideosAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.prepareQcVideosAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo hsCollectReidAdvanceInfo.
                     * @member {palexy.sherlock.v1.IHSCollectReidAdvanceInfo|null|undefined} hsCollectReidAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.hsCollectReidAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo tinkerPostProcessAdvanceInfo.
                     * @member {palexy.sherlock.v1.ITinkerPostProcessAdvanceInfo|null|undefined} tinkerPostProcessAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.tinkerPostProcessAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo forecastGenerateAdvanceInfo.
                     * @member {palexy.sherlock.v1.IForecastGenerateAdvanceInfo|null|undefined} forecastGenerateAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.forecastGenerateAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo exportHakuhodoAdvanceInfo.
                     * @member {palexy.sherlock.v1.IExportHakuhodoAdvanceInfo|null|undefined} exportHakuhodoAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.exportHakuhodoAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo exportOsoolEventsAdvanceInfo.
                     * @member {palexy.sherlock.v1.IExportOsoolEventsAdvanceInfo|null|undefined} exportOsoolEventsAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.exportOsoolEventsAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo hakuhodoJoinPartialMergingResultsAdvanceInfo.
                     * @member {palexy.sherlock.v1.IHakuhodoJoinPartialMergingResultsAdvanceInfo|null|undefined} hakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.hakuhodoJoinPartialMergingResultsAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo hakuhodoEditTrafficAdvanceInfo.
                     * @member {palexy.sherlock.v1.IHakuhodoEditTrafficAdvanceInfo|null|undefined} hakuhodoEditTrafficAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.hakuhodoEditTrafficAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo hakuhodoExtrapolateDemographyAdvanceInfo.
                     * @member {palexy.sherlock.v1.IHakuhodoExtrapolateDemographyAdvanceInfo|null|undefined} hakuhodoExtrapolateDemographyAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.hakuhodoExtrapolateDemographyAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo batchDeleteVideosAdvanceInfo.
                     * @member {palexy.sherlock.v1.IBatchDeleteVideosAdvanceInfo|null|undefined} batchDeleteVideosAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.batchDeleteVideosAdvanceInfo = null;
    
                    /**
                     * TaskAdvanceInfo buildSeedAdvanceInfo.
                     * @member {palexy.sherlock.v1.IBuildSeedAdvanceInfo|null|undefined} buildSeedAdvanceInfo
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    TaskAdvanceInfo.prototype.buildSeedAdvanceInfo = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * TaskAdvanceInfo advanceInfoOneof.
                     * @member {"debugPersonAdvanceInfo"|"calculateMergeAccuracyAdvanceInfo"|"executePostMergeConfigAdvanceInfo"|"deleteTaskFilesAdvanceInfo"|"scanMergeConfigAdvanceInfo"|"rerunMergeConfigAdvanceInfo"|"debugExperimentAdvanceInfo"|"prepareQcVideosAdvanceInfo"|"hsCollectReidAdvanceInfo"|"tinkerPostProcessAdvanceInfo"|"forecastGenerateAdvanceInfo"|"exportHakuhodoAdvanceInfo"|"exportOsoolEventsAdvanceInfo"|"hakuhodoJoinPartialMergingResultsAdvanceInfo"|"hakuhodoEditTrafficAdvanceInfo"|"hakuhodoExtrapolateDemographyAdvanceInfo"|"batchDeleteVideosAdvanceInfo"|"buildSeedAdvanceInfo"|undefined} advanceInfoOneof
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     */
                    Object.defineProperty(TaskAdvanceInfo.prototype, "advanceInfoOneof", {
                        get: $util.oneOfGetter($oneOfFields = ["debugPersonAdvanceInfo", "calculateMergeAccuracyAdvanceInfo", "executePostMergeConfigAdvanceInfo", "deleteTaskFilesAdvanceInfo", "scanMergeConfigAdvanceInfo", "rerunMergeConfigAdvanceInfo", "debugExperimentAdvanceInfo", "prepareQcVideosAdvanceInfo", "hsCollectReidAdvanceInfo", "tinkerPostProcessAdvanceInfo", "forecastGenerateAdvanceInfo", "exportHakuhodoAdvanceInfo", "exportOsoolEventsAdvanceInfo", "hakuhodoJoinPartialMergingResultsAdvanceInfo", "hakuhodoEditTrafficAdvanceInfo", "hakuhodoExtrapolateDemographyAdvanceInfo", "batchDeleteVideosAdvanceInfo", "buildSeedAdvanceInfo"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new TaskAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ITaskAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.TaskAdvanceInfo} TaskAdvanceInfo instance
                     */
                    TaskAdvanceInfo.create = function create(properties) {
                        return new TaskAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified TaskAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.TaskAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ITaskAdvanceInfo} message TaskAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.debugPersonAdvanceInfo != null && Object.hasOwnProperty.call(message, "debugPersonAdvanceInfo"))
                            $root.palexy.sherlock.v1.DebugPersonAdvanceInfo.encode(message.debugPersonAdvanceInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.calculateMergeAccuracyAdvanceInfo != null && Object.hasOwnProperty.call(message, "calculateMergeAccuracyAdvanceInfo"))
                            $root.palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo.encode(message.calculateMergeAccuracyAdvanceInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.executePostMergeConfigAdvanceInfo != null && Object.hasOwnProperty.call(message, "executePostMergeConfigAdvanceInfo"))
                            $root.palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo.encode(message.executePostMergeConfigAdvanceInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.deleteTaskFilesAdvanceInfo != null && Object.hasOwnProperty.call(message, "deleteTaskFilesAdvanceInfo"))
                            $root.palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo.encode(message.deleteTaskFilesAdvanceInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.scanMergeConfigAdvanceInfo != null && Object.hasOwnProperty.call(message, "scanMergeConfigAdvanceInfo"))
                            $root.palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.encode(message.scanMergeConfigAdvanceInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.rerunMergeConfigAdvanceInfo != null && Object.hasOwnProperty.call(message, "rerunMergeConfigAdvanceInfo"))
                            $root.palexy.sherlock.v1.RerunMergeConfigAdvanceInfo.encode(message.rerunMergeConfigAdvanceInfo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.debugExperimentAdvanceInfo != null && Object.hasOwnProperty.call(message, "debugExperimentAdvanceInfo"))
                            $root.palexy.sherlock.v1.DebugExperimentAdvanceInfo.encode(message.debugExperimentAdvanceInfo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.prepareQcVideosAdvanceInfo != null && Object.hasOwnProperty.call(message, "prepareQcVideosAdvanceInfo"))
                            $root.palexy.sherlock.v1.PrepareQcVideosAdvanceInfo.encode(message.prepareQcVideosAdvanceInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.hsCollectReidAdvanceInfo != null && Object.hasOwnProperty.call(message, "hsCollectReidAdvanceInfo"))
                            $root.palexy.sherlock.v1.HSCollectReidAdvanceInfo.encode(message.hsCollectReidAdvanceInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.tinkerPostProcessAdvanceInfo != null && Object.hasOwnProperty.call(message, "tinkerPostProcessAdvanceInfo"))
                            $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.encode(message.tinkerPostProcessAdvanceInfo, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.forecastGenerateAdvanceInfo != null && Object.hasOwnProperty.call(message, "forecastGenerateAdvanceInfo"))
                            $root.palexy.sherlock.v1.ForecastGenerateAdvanceInfo.encode(message.forecastGenerateAdvanceInfo, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.exportHakuhodoAdvanceInfo != null && Object.hasOwnProperty.call(message, "exportHakuhodoAdvanceInfo"))
                            $root.palexy.sherlock.v1.ExportHakuhodoAdvanceInfo.encode(message.exportHakuhodoAdvanceInfo, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                        if (message.exportOsoolEventsAdvanceInfo != null && Object.hasOwnProperty.call(message, "exportOsoolEventsAdvanceInfo"))
                            $root.palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo.encode(message.exportOsoolEventsAdvanceInfo, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.hakuhodoJoinPartialMergingResultsAdvanceInfo != null && Object.hasOwnProperty.call(message, "hakuhodoJoinPartialMergingResultsAdvanceInfo"))
                            $root.palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo.encode(message.hakuhodoJoinPartialMergingResultsAdvanceInfo, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                        if (message.hakuhodoEditTrafficAdvanceInfo != null && Object.hasOwnProperty.call(message, "hakuhodoEditTrafficAdvanceInfo"))
                            $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.encode(message.hakuhodoEditTrafficAdvanceInfo, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                        if (message.hakuhodoExtrapolateDemographyAdvanceInfo != null && Object.hasOwnProperty.call(message, "hakuhodoExtrapolateDemographyAdvanceInfo"))
                            $root.palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo.encode(message.hakuhodoExtrapolateDemographyAdvanceInfo, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.batchDeleteVideosAdvanceInfo != null && Object.hasOwnProperty.call(message, "batchDeleteVideosAdvanceInfo"))
                            $root.palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo.encode(message.batchDeleteVideosAdvanceInfo, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        if (message.buildSeedAdvanceInfo != null && Object.hasOwnProperty.call(message, "buildSeedAdvanceInfo"))
                            $root.palexy.sherlock.v1.BuildSeedAdvanceInfo.encode(message.buildSeedAdvanceInfo, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TaskAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.TaskAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ITaskAdvanceInfo} message TaskAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TaskAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.TaskAdvanceInfo} TaskAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.TaskAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.debugPersonAdvanceInfo = $root.palexy.sherlock.v1.DebugPersonAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.calculateMergeAccuracyAdvanceInfo = $root.palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.executePostMergeConfigAdvanceInfo = $root.palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.deleteTaskFilesAdvanceInfo = $root.palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.scanMergeConfigAdvanceInfo = $root.palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.rerunMergeConfigAdvanceInfo = $root.palexy.sherlock.v1.RerunMergeConfigAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.debugExperimentAdvanceInfo = $root.palexy.sherlock.v1.DebugExperimentAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.prepareQcVideosAdvanceInfo = $root.palexy.sherlock.v1.PrepareQcVideosAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.hsCollectReidAdvanceInfo = $root.palexy.sherlock.v1.HSCollectReidAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.tinkerPostProcessAdvanceInfo = $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.forecastGenerateAdvanceInfo = $root.palexy.sherlock.v1.ForecastGenerateAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.exportHakuhodoAdvanceInfo = $root.palexy.sherlock.v1.ExportHakuhodoAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 13:
                                message.exportOsoolEventsAdvanceInfo = $root.palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 14:
                                message.hakuhodoJoinPartialMergingResultsAdvanceInfo = $root.palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 15:
                                message.hakuhodoEditTrafficAdvanceInfo = $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 16:
                                message.hakuhodoExtrapolateDemographyAdvanceInfo = $root.palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 17:
                                message.batchDeleteVideosAdvanceInfo = $root.palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            case 18:
                                message.buildSeedAdvanceInfo = $root.palexy.sherlock.v1.BuildSeedAdvanceInfo.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TaskAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.TaskAdvanceInfo} TaskAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TaskAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TaskAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.debugPersonAdvanceInfo != null && message.hasOwnProperty("debugPersonAdvanceInfo")) {
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.DebugPersonAdvanceInfo.verify(message.debugPersonAdvanceInfo);
                                if (error)
                                    return "debugPersonAdvanceInfo." + error;
                            }
                        }
                        if (message.calculateMergeAccuracyAdvanceInfo != null && message.hasOwnProperty("calculateMergeAccuracyAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo.verify(message.calculateMergeAccuracyAdvanceInfo);
                                if (error)
                                    return "calculateMergeAccuracyAdvanceInfo." + error;
                            }
                        }
                        if (message.executePostMergeConfigAdvanceInfo != null && message.hasOwnProperty("executePostMergeConfigAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo.verify(message.executePostMergeConfigAdvanceInfo);
                                if (error)
                                    return "executePostMergeConfigAdvanceInfo." + error;
                            }
                        }
                        if (message.deleteTaskFilesAdvanceInfo != null && message.hasOwnProperty("deleteTaskFilesAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo.verify(message.deleteTaskFilesAdvanceInfo);
                                if (error)
                                    return "deleteTaskFilesAdvanceInfo." + error;
                            }
                        }
                        if (message.scanMergeConfigAdvanceInfo != null && message.hasOwnProperty("scanMergeConfigAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.verify(message.scanMergeConfigAdvanceInfo);
                                if (error)
                                    return "scanMergeConfigAdvanceInfo." + error;
                            }
                        }
                        if (message.rerunMergeConfigAdvanceInfo != null && message.hasOwnProperty("rerunMergeConfigAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.RerunMergeConfigAdvanceInfo.verify(message.rerunMergeConfigAdvanceInfo);
                                if (error)
                                    return "rerunMergeConfigAdvanceInfo." + error;
                            }
                        }
                        if (message.debugExperimentAdvanceInfo != null && message.hasOwnProperty("debugExperimentAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.DebugExperimentAdvanceInfo.verify(message.debugExperimentAdvanceInfo);
                                if (error)
                                    return "debugExperimentAdvanceInfo." + error;
                            }
                        }
                        if (message.prepareQcVideosAdvanceInfo != null && message.hasOwnProperty("prepareQcVideosAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.PrepareQcVideosAdvanceInfo.verify(message.prepareQcVideosAdvanceInfo);
                                if (error)
                                    return "prepareQcVideosAdvanceInfo." + error;
                            }
                        }
                        if (message.hsCollectReidAdvanceInfo != null && message.hasOwnProperty("hsCollectReidAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.HSCollectReidAdvanceInfo.verify(message.hsCollectReidAdvanceInfo);
                                if (error)
                                    return "hsCollectReidAdvanceInfo." + error;
                            }
                        }
                        if (message.tinkerPostProcessAdvanceInfo != null && message.hasOwnProperty("tinkerPostProcessAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.verify(message.tinkerPostProcessAdvanceInfo);
                                if (error)
                                    return "tinkerPostProcessAdvanceInfo." + error;
                            }
                        }
                        if (message.forecastGenerateAdvanceInfo != null && message.hasOwnProperty("forecastGenerateAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.ForecastGenerateAdvanceInfo.verify(message.forecastGenerateAdvanceInfo);
                                if (error)
                                    return "forecastGenerateAdvanceInfo." + error;
                            }
                        }
                        if (message.exportHakuhodoAdvanceInfo != null && message.hasOwnProperty("exportHakuhodoAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.ExportHakuhodoAdvanceInfo.verify(message.exportHakuhodoAdvanceInfo);
                                if (error)
                                    return "exportHakuhodoAdvanceInfo." + error;
                            }
                        }
                        if (message.exportOsoolEventsAdvanceInfo != null && message.hasOwnProperty("exportOsoolEventsAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo.verify(message.exportOsoolEventsAdvanceInfo);
                                if (error)
                                    return "exportOsoolEventsAdvanceInfo." + error;
                            }
                        }
                        if (message.hakuhodoJoinPartialMergingResultsAdvanceInfo != null && message.hasOwnProperty("hakuhodoJoinPartialMergingResultsAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo.verify(message.hakuhodoJoinPartialMergingResultsAdvanceInfo);
                                if (error)
                                    return "hakuhodoJoinPartialMergingResultsAdvanceInfo." + error;
                            }
                        }
                        if (message.hakuhodoEditTrafficAdvanceInfo != null && message.hasOwnProperty("hakuhodoEditTrafficAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.verify(message.hakuhodoEditTrafficAdvanceInfo);
                                if (error)
                                    return "hakuhodoEditTrafficAdvanceInfo." + error;
                            }
                        }
                        if (message.hakuhodoExtrapolateDemographyAdvanceInfo != null && message.hasOwnProperty("hakuhodoExtrapolateDemographyAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo.verify(message.hakuhodoExtrapolateDemographyAdvanceInfo);
                                if (error)
                                    return "hakuhodoExtrapolateDemographyAdvanceInfo." + error;
                            }
                        }
                        if (message.batchDeleteVideosAdvanceInfo != null && message.hasOwnProperty("batchDeleteVideosAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo.verify(message.batchDeleteVideosAdvanceInfo);
                                if (error)
                                    return "batchDeleteVideosAdvanceInfo." + error;
                            }
                        }
                        if (message.buildSeedAdvanceInfo != null && message.hasOwnProperty("buildSeedAdvanceInfo")) {
                            if (properties.advanceInfoOneof === 1)
                                return "advanceInfoOneof: multiple values";
                            properties.advanceInfoOneof = 1;
                            {
                                var error = $root.palexy.sherlock.v1.BuildSeedAdvanceInfo.verify(message.buildSeedAdvanceInfo);
                                if (error)
                                    return "buildSeedAdvanceInfo." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a TaskAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.TaskAdvanceInfo} TaskAdvanceInfo
                     */
                    TaskAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.TaskAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.TaskAdvanceInfo();
                        if (object.debugPersonAdvanceInfo != null) {
                            if (typeof object.debugPersonAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.debugPersonAdvanceInfo: object expected");
                            message.debugPersonAdvanceInfo = $root.palexy.sherlock.v1.DebugPersonAdvanceInfo.fromObject(object.debugPersonAdvanceInfo);
                        }
                        if (object.calculateMergeAccuracyAdvanceInfo != null) {
                            if (typeof object.calculateMergeAccuracyAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.calculateMergeAccuracyAdvanceInfo: object expected");
                            message.calculateMergeAccuracyAdvanceInfo = $root.palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo.fromObject(object.calculateMergeAccuracyAdvanceInfo);
                        }
                        if (object.executePostMergeConfigAdvanceInfo != null) {
                            if (typeof object.executePostMergeConfigAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.executePostMergeConfigAdvanceInfo: object expected");
                            message.executePostMergeConfigAdvanceInfo = $root.palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo.fromObject(object.executePostMergeConfigAdvanceInfo);
                        }
                        if (object.deleteTaskFilesAdvanceInfo != null) {
                            if (typeof object.deleteTaskFilesAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.deleteTaskFilesAdvanceInfo: object expected");
                            message.deleteTaskFilesAdvanceInfo = $root.palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo.fromObject(object.deleteTaskFilesAdvanceInfo);
                        }
                        if (object.scanMergeConfigAdvanceInfo != null) {
                            if (typeof object.scanMergeConfigAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.scanMergeConfigAdvanceInfo: object expected");
                            message.scanMergeConfigAdvanceInfo = $root.palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.fromObject(object.scanMergeConfigAdvanceInfo);
                        }
                        if (object.rerunMergeConfigAdvanceInfo != null) {
                            if (typeof object.rerunMergeConfigAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.rerunMergeConfigAdvanceInfo: object expected");
                            message.rerunMergeConfigAdvanceInfo = $root.palexy.sherlock.v1.RerunMergeConfigAdvanceInfo.fromObject(object.rerunMergeConfigAdvanceInfo);
                        }
                        if (object.debugExperimentAdvanceInfo != null) {
                            if (typeof object.debugExperimentAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.debugExperimentAdvanceInfo: object expected");
                            message.debugExperimentAdvanceInfo = $root.palexy.sherlock.v1.DebugExperimentAdvanceInfo.fromObject(object.debugExperimentAdvanceInfo);
                        }
                        if (object.prepareQcVideosAdvanceInfo != null) {
                            if (typeof object.prepareQcVideosAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.prepareQcVideosAdvanceInfo: object expected");
                            message.prepareQcVideosAdvanceInfo = $root.palexy.sherlock.v1.PrepareQcVideosAdvanceInfo.fromObject(object.prepareQcVideosAdvanceInfo);
                        }
                        if (object.hsCollectReidAdvanceInfo != null) {
                            if (typeof object.hsCollectReidAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.hsCollectReidAdvanceInfo: object expected");
                            message.hsCollectReidAdvanceInfo = $root.palexy.sherlock.v1.HSCollectReidAdvanceInfo.fromObject(object.hsCollectReidAdvanceInfo);
                        }
                        if (object.tinkerPostProcessAdvanceInfo != null) {
                            if (typeof object.tinkerPostProcessAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.tinkerPostProcessAdvanceInfo: object expected");
                            message.tinkerPostProcessAdvanceInfo = $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.fromObject(object.tinkerPostProcessAdvanceInfo);
                        }
                        if (object.forecastGenerateAdvanceInfo != null) {
                            if (typeof object.forecastGenerateAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.forecastGenerateAdvanceInfo: object expected");
                            message.forecastGenerateAdvanceInfo = $root.palexy.sherlock.v1.ForecastGenerateAdvanceInfo.fromObject(object.forecastGenerateAdvanceInfo);
                        }
                        if (object.exportHakuhodoAdvanceInfo != null) {
                            if (typeof object.exportHakuhodoAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.exportHakuhodoAdvanceInfo: object expected");
                            message.exportHakuhodoAdvanceInfo = $root.palexy.sherlock.v1.ExportHakuhodoAdvanceInfo.fromObject(object.exportHakuhodoAdvanceInfo);
                        }
                        if (object.exportOsoolEventsAdvanceInfo != null) {
                            if (typeof object.exportOsoolEventsAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.exportOsoolEventsAdvanceInfo: object expected");
                            message.exportOsoolEventsAdvanceInfo = $root.palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo.fromObject(object.exportOsoolEventsAdvanceInfo);
                        }
                        if (object.hakuhodoJoinPartialMergingResultsAdvanceInfo != null) {
                            if (typeof object.hakuhodoJoinPartialMergingResultsAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.hakuhodoJoinPartialMergingResultsAdvanceInfo: object expected");
                            message.hakuhodoJoinPartialMergingResultsAdvanceInfo = $root.palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo.fromObject(object.hakuhodoJoinPartialMergingResultsAdvanceInfo);
                        }
                        if (object.hakuhodoEditTrafficAdvanceInfo != null) {
                            if (typeof object.hakuhodoEditTrafficAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.hakuhodoEditTrafficAdvanceInfo: object expected");
                            message.hakuhodoEditTrafficAdvanceInfo = $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.fromObject(object.hakuhodoEditTrafficAdvanceInfo);
                        }
                        if (object.hakuhodoExtrapolateDemographyAdvanceInfo != null) {
                            if (typeof object.hakuhodoExtrapolateDemographyAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.hakuhodoExtrapolateDemographyAdvanceInfo: object expected");
                            message.hakuhodoExtrapolateDemographyAdvanceInfo = $root.palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo.fromObject(object.hakuhodoExtrapolateDemographyAdvanceInfo);
                        }
                        if (object.batchDeleteVideosAdvanceInfo != null) {
                            if (typeof object.batchDeleteVideosAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.batchDeleteVideosAdvanceInfo: object expected");
                            message.batchDeleteVideosAdvanceInfo = $root.palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo.fromObject(object.batchDeleteVideosAdvanceInfo);
                        }
                        if (object.buildSeedAdvanceInfo != null) {
                            if (typeof object.buildSeedAdvanceInfo !== "object")
                                throw TypeError(".palexy.sherlock.v1.TaskAdvanceInfo.buildSeedAdvanceInfo: object expected");
                            message.buildSeedAdvanceInfo = $root.palexy.sherlock.v1.BuildSeedAdvanceInfo.fromObject(object.buildSeedAdvanceInfo);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TaskAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.TaskAdvanceInfo} message TaskAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TaskAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.debugPersonAdvanceInfo != null && message.hasOwnProperty("debugPersonAdvanceInfo")) {
                            object.debugPersonAdvanceInfo = $root.palexy.sherlock.v1.DebugPersonAdvanceInfo.toObject(message.debugPersonAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "debugPersonAdvanceInfo";
                        }
                        if (message.calculateMergeAccuracyAdvanceInfo != null && message.hasOwnProperty("calculateMergeAccuracyAdvanceInfo")) {
                            object.calculateMergeAccuracyAdvanceInfo = $root.palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo.toObject(message.calculateMergeAccuracyAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "calculateMergeAccuracyAdvanceInfo";
                        }
                        if (message.executePostMergeConfigAdvanceInfo != null && message.hasOwnProperty("executePostMergeConfigAdvanceInfo")) {
                            object.executePostMergeConfigAdvanceInfo = $root.palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo.toObject(message.executePostMergeConfigAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "executePostMergeConfigAdvanceInfo";
                        }
                        if (message.deleteTaskFilesAdvanceInfo != null && message.hasOwnProperty("deleteTaskFilesAdvanceInfo")) {
                            object.deleteTaskFilesAdvanceInfo = $root.palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo.toObject(message.deleteTaskFilesAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "deleteTaskFilesAdvanceInfo";
                        }
                        if (message.scanMergeConfigAdvanceInfo != null && message.hasOwnProperty("scanMergeConfigAdvanceInfo")) {
                            object.scanMergeConfigAdvanceInfo = $root.palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.toObject(message.scanMergeConfigAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "scanMergeConfigAdvanceInfo";
                        }
                        if (message.rerunMergeConfigAdvanceInfo != null && message.hasOwnProperty("rerunMergeConfigAdvanceInfo")) {
                            object.rerunMergeConfigAdvanceInfo = $root.palexy.sherlock.v1.RerunMergeConfigAdvanceInfo.toObject(message.rerunMergeConfigAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "rerunMergeConfigAdvanceInfo";
                        }
                        if (message.debugExperimentAdvanceInfo != null && message.hasOwnProperty("debugExperimentAdvanceInfo")) {
                            object.debugExperimentAdvanceInfo = $root.palexy.sherlock.v1.DebugExperimentAdvanceInfo.toObject(message.debugExperimentAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "debugExperimentAdvanceInfo";
                        }
                        if (message.prepareQcVideosAdvanceInfo != null && message.hasOwnProperty("prepareQcVideosAdvanceInfo")) {
                            object.prepareQcVideosAdvanceInfo = $root.palexy.sherlock.v1.PrepareQcVideosAdvanceInfo.toObject(message.prepareQcVideosAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "prepareQcVideosAdvanceInfo";
                        }
                        if (message.hsCollectReidAdvanceInfo != null && message.hasOwnProperty("hsCollectReidAdvanceInfo")) {
                            object.hsCollectReidAdvanceInfo = $root.palexy.sherlock.v1.HSCollectReidAdvanceInfo.toObject(message.hsCollectReidAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "hsCollectReidAdvanceInfo";
                        }
                        if (message.tinkerPostProcessAdvanceInfo != null && message.hasOwnProperty("tinkerPostProcessAdvanceInfo")) {
                            object.tinkerPostProcessAdvanceInfo = $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.toObject(message.tinkerPostProcessAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "tinkerPostProcessAdvanceInfo";
                        }
                        if (message.forecastGenerateAdvanceInfo != null && message.hasOwnProperty("forecastGenerateAdvanceInfo")) {
                            object.forecastGenerateAdvanceInfo = $root.palexy.sherlock.v1.ForecastGenerateAdvanceInfo.toObject(message.forecastGenerateAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "forecastGenerateAdvanceInfo";
                        }
                        if (message.exportHakuhodoAdvanceInfo != null && message.hasOwnProperty("exportHakuhodoAdvanceInfo")) {
                            object.exportHakuhodoAdvanceInfo = $root.palexy.sherlock.v1.ExportHakuhodoAdvanceInfo.toObject(message.exportHakuhodoAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "exportHakuhodoAdvanceInfo";
                        }
                        if (message.exportOsoolEventsAdvanceInfo != null && message.hasOwnProperty("exportOsoolEventsAdvanceInfo")) {
                            object.exportOsoolEventsAdvanceInfo = $root.palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo.toObject(message.exportOsoolEventsAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "exportOsoolEventsAdvanceInfo";
                        }
                        if (message.hakuhodoJoinPartialMergingResultsAdvanceInfo != null && message.hasOwnProperty("hakuhodoJoinPartialMergingResultsAdvanceInfo")) {
                            object.hakuhodoJoinPartialMergingResultsAdvanceInfo = $root.palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo.toObject(message.hakuhodoJoinPartialMergingResultsAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "hakuhodoJoinPartialMergingResultsAdvanceInfo";
                        }
                        if (message.hakuhodoEditTrafficAdvanceInfo != null && message.hasOwnProperty("hakuhodoEditTrafficAdvanceInfo")) {
                            object.hakuhodoEditTrafficAdvanceInfo = $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.toObject(message.hakuhodoEditTrafficAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "hakuhodoEditTrafficAdvanceInfo";
                        }
                        if (message.hakuhodoExtrapolateDemographyAdvanceInfo != null && message.hasOwnProperty("hakuhodoExtrapolateDemographyAdvanceInfo")) {
                            object.hakuhodoExtrapolateDemographyAdvanceInfo = $root.palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo.toObject(message.hakuhodoExtrapolateDemographyAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "hakuhodoExtrapolateDemographyAdvanceInfo";
                        }
                        if (message.batchDeleteVideosAdvanceInfo != null && message.hasOwnProperty("batchDeleteVideosAdvanceInfo")) {
                            object.batchDeleteVideosAdvanceInfo = $root.palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo.toObject(message.batchDeleteVideosAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "batchDeleteVideosAdvanceInfo";
                        }
                        if (message.buildSeedAdvanceInfo != null && message.hasOwnProperty("buildSeedAdvanceInfo")) {
                            object.buildSeedAdvanceInfo = $root.palexy.sherlock.v1.BuildSeedAdvanceInfo.toObject(message.buildSeedAdvanceInfo, options);
                            if (options.oneofs)
                                object.advanceInfoOneof = "buildSeedAdvanceInfo";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this TaskAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.TaskAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TaskAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TaskAdvanceInfo;
                })();
    
                v1.DebugPersonAdvanceInfo = (function() {
    
                    /**
                     * Properties of a DebugPersonAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IDebugPersonAdvanceInfo
                     * @property {palexy.sherlock.v1.IPersonFilter|null} [personFilter] DebugPersonAdvanceInfo personFilter
                     * @property {boolean|null} [includeFfmpegResults] DebugPersonAdvanceInfo includeFfmpegResults
                     */
    
                    /**
                     * Constructs a new DebugPersonAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DebugPersonAdvanceInfo.
                     * @implements IDebugPersonAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IDebugPersonAdvanceInfo=} [properties] Properties to set
                     */
                    function DebugPersonAdvanceInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DebugPersonAdvanceInfo personFilter.
                     * @member {palexy.sherlock.v1.IPersonFilter|null|undefined} personFilter
                     * @memberof palexy.sherlock.v1.DebugPersonAdvanceInfo
                     * @instance
                     */
                    DebugPersonAdvanceInfo.prototype.personFilter = null;
    
                    /**
                     * DebugPersonAdvanceInfo includeFfmpegResults.
                     * @member {boolean} includeFfmpegResults
                     * @memberof palexy.sherlock.v1.DebugPersonAdvanceInfo
                     * @instance
                     */
                    DebugPersonAdvanceInfo.prototype.includeFfmpegResults = false;
    
                    /**
                     * Creates a new DebugPersonAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DebugPersonAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IDebugPersonAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DebugPersonAdvanceInfo} DebugPersonAdvanceInfo instance
                     */
                    DebugPersonAdvanceInfo.create = function create(properties) {
                        return new DebugPersonAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified DebugPersonAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.DebugPersonAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DebugPersonAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IDebugPersonAdvanceInfo} message DebugPersonAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DebugPersonAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.personFilter != null && Object.hasOwnProperty.call(message, "personFilter"))
                            $root.palexy.sherlock.v1.PersonFilter.encode(message.personFilter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.includeFfmpegResults != null && Object.hasOwnProperty.call(message, "includeFfmpegResults"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeFfmpegResults);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DebugPersonAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DebugPersonAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DebugPersonAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IDebugPersonAdvanceInfo} message DebugPersonAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DebugPersonAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DebugPersonAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DebugPersonAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DebugPersonAdvanceInfo} DebugPersonAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DebugPersonAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DebugPersonAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.personFilter = $root.palexy.sherlock.v1.PersonFilter.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.includeFfmpegResults = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DebugPersonAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DebugPersonAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DebugPersonAdvanceInfo} DebugPersonAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DebugPersonAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DebugPersonAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DebugPersonAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DebugPersonAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.personFilter != null && message.hasOwnProperty("personFilter")) {
                            var error = $root.palexy.sherlock.v1.PersonFilter.verify(message.personFilter);
                            if (error)
                                return "personFilter." + error;
                        }
                        if (message.includeFfmpegResults != null && message.hasOwnProperty("includeFfmpegResults"))
                            if (typeof message.includeFfmpegResults !== "boolean")
                                return "includeFfmpegResults: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a DebugPersonAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DebugPersonAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DebugPersonAdvanceInfo} DebugPersonAdvanceInfo
                     */
                    DebugPersonAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DebugPersonAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.DebugPersonAdvanceInfo();
                        if (object.personFilter != null) {
                            if (typeof object.personFilter !== "object")
                                throw TypeError(".palexy.sherlock.v1.DebugPersonAdvanceInfo.personFilter: object expected");
                            message.personFilter = $root.palexy.sherlock.v1.PersonFilter.fromObject(object.personFilter);
                        }
                        if (object.includeFfmpegResults != null)
                            message.includeFfmpegResults = Boolean(object.includeFfmpegResults);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DebugPersonAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DebugPersonAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.DebugPersonAdvanceInfo} message DebugPersonAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DebugPersonAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.personFilter = null;
                            object.includeFfmpegResults = false;
                        }
                        if (message.personFilter != null && message.hasOwnProperty("personFilter"))
                            object.personFilter = $root.palexy.sherlock.v1.PersonFilter.toObject(message.personFilter, options);
                        if (message.includeFfmpegResults != null && message.hasOwnProperty("includeFfmpegResults"))
                            object.includeFfmpegResults = message.includeFfmpegResults;
                        return object;
                    };
    
                    /**
                     * Converts this DebugPersonAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DebugPersonAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DebugPersonAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DebugPersonAdvanceInfo;
                })();
    
                v1.DebugExperimentAdvanceInfo = (function() {
    
                    /**
                     * Properties of a DebugExperimentAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IDebugExperimentAdvanceInfo
                     * @property {palexy.sherlock.v1.IExperimentFilter|null} [experimentFilter] DebugExperimentAdvanceInfo experimentFilter
                     */
    
                    /**
                     * Constructs a new DebugExperimentAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DebugExperimentAdvanceInfo.
                     * @implements IDebugExperimentAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IDebugExperimentAdvanceInfo=} [properties] Properties to set
                     */
                    function DebugExperimentAdvanceInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DebugExperimentAdvanceInfo experimentFilter.
                     * @member {palexy.sherlock.v1.IExperimentFilter|null|undefined} experimentFilter
                     * @memberof palexy.sherlock.v1.DebugExperimentAdvanceInfo
                     * @instance
                     */
                    DebugExperimentAdvanceInfo.prototype.experimentFilter = null;
    
                    /**
                     * Creates a new DebugExperimentAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DebugExperimentAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IDebugExperimentAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DebugExperimentAdvanceInfo} DebugExperimentAdvanceInfo instance
                     */
                    DebugExperimentAdvanceInfo.create = function create(properties) {
                        return new DebugExperimentAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified DebugExperimentAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.DebugExperimentAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DebugExperimentAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IDebugExperimentAdvanceInfo} message DebugExperimentAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DebugExperimentAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.experimentFilter != null && Object.hasOwnProperty.call(message, "experimentFilter"))
                            $root.palexy.sherlock.v1.ExperimentFilter.encode(message.experimentFilter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DebugExperimentAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DebugExperimentAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DebugExperimentAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IDebugExperimentAdvanceInfo} message DebugExperimentAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DebugExperimentAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DebugExperimentAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DebugExperimentAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DebugExperimentAdvanceInfo} DebugExperimentAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DebugExperimentAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DebugExperimentAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.experimentFilter = $root.palexy.sherlock.v1.ExperimentFilter.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DebugExperimentAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DebugExperimentAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DebugExperimentAdvanceInfo} DebugExperimentAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DebugExperimentAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DebugExperimentAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DebugExperimentAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DebugExperimentAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.experimentFilter != null && message.hasOwnProperty("experimentFilter")) {
                            var error = $root.palexy.sherlock.v1.ExperimentFilter.verify(message.experimentFilter);
                            if (error)
                                return "experimentFilter." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DebugExperimentAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DebugExperimentAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DebugExperimentAdvanceInfo} DebugExperimentAdvanceInfo
                     */
                    DebugExperimentAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DebugExperimentAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.DebugExperimentAdvanceInfo();
                        if (object.experimentFilter != null) {
                            if (typeof object.experimentFilter !== "object")
                                throw TypeError(".palexy.sherlock.v1.DebugExperimentAdvanceInfo.experimentFilter: object expected");
                            message.experimentFilter = $root.palexy.sherlock.v1.ExperimentFilter.fromObject(object.experimentFilter);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DebugExperimentAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DebugExperimentAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.DebugExperimentAdvanceInfo} message DebugExperimentAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DebugExperimentAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.experimentFilter = null;
                        if (message.experimentFilter != null && message.hasOwnProperty("experimentFilter"))
                            object.experimentFilter = $root.palexy.sherlock.v1.ExperimentFilter.toObject(message.experimentFilter, options);
                        return object;
                    };
    
                    /**
                     * Converts this DebugExperimentAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DebugExperimentAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DebugExperimentAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DebugExperimentAdvanceInfo;
                })();
    
                v1.CalculateMergeAccuracyAdvanceInfo = (function() {
    
                    /**
                     * Properties of a CalculateMergeAccuracyAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface ICalculateMergeAccuracyAdvanceInfo
                     * @property {Array.<palexy.sherlock.v1.IPredictedAndLabelItem>|null} [predictAndLabelItems] CalculateMergeAccuracyAdvanceInfo predictAndLabelItems
                     */
    
                    /**
                     * Constructs a new CalculateMergeAccuracyAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CalculateMergeAccuracyAdvanceInfo.
                     * @implements ICalculateMergeAccuracyAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.ICalculateMergeAccuracyAdvanceInfo=} [properties] Properties to set
                     */
                    function CalculateMergeAccuracyAdvanceInfo(properties) {
                        this.predictAndLabelItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CalculateMergeAccuracyAdvanceInfo predictAndLabelItems.
                     * @member {Array.<palexy.sherlock.v1.IPredictedAndLabelItem>} predictAndLabelItems
                     * @memberof palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo
                     * @instance
                     */
                    CalculateMergeAccuracyAdvanceInfo.prototype.predictAndLabelItems = $util.emptyArray;
    
                    /**
                     * Creates a new CalculateMergeAccuracyAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateMergeAccuracyAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo} CalculateMergeAccuracyAdvanceInfo instance
                     */
                    CalculateMergeAccuracyAdvanceInfo.create = function create(properties) {
                        return new CalculateMergeAccuracyAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified CalculateMergeAccuracyAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateMergeAccuracyAdvanceInfo} message CalculateMergeAccuracyAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CalculateMergeAccuracyAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.predictAndLabelItems != null && message.predictAndLabelItems.length)
                            for (var i = 0; i < message.predictAndLabelItems.length; ++i)
                                $root.palexy.sherlock.v1.PredictedAndLabelItem.encode(message.predictAndLabelItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CalculateMergeAccuracyAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateMergeAccuracyAdvanceInfo} message CalculateMergeAccuracyAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CalculateMergeAccuracyAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CalculateMergeAccuracyAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo} CalculateMergeAccuracyAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CalculateMergeAccuracyAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.predictAndLabelItems && message.predictAndLabelItems.length))
                                    message.predictAndLabelItems = [];
                                message.predictAndLabelItems.push($root.palexy.sherlock.v1.PredictedAndLabelItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CalculateMergeAccuracyAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo} CalculateMergeAccuracyAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CalculateMergeAccuracyAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CalculateMergeAccuracyAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CalculateMergeAccuracyAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.predictAndLabelItems != null && message.hasOwnProperty("predictAndLabelItems")) {
                            if (!Array.isArray(message.predictAndLabelItems))
                                return "predictAndLabelItems: array expected";
                            for (var i = 0; i < message.predictAndLabelItems.length; ++i) {
                                var error = $root.palexy.sherlock.v1.PredictedAndLabelItem.verify(message.predictAndLabelItems[i]);
                                if (error)
                                    return "predictAndLabelItems." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CalculateMergeAccuracyAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo} CalculateMergeAccuracyAdvanceInfo
                     */
                    CalculateMergeAccuracyAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo();
                        if (object.predictAndLabelItems) {
                            if (!Array.isArray(object.predictAndLabelItems))
                                throw TypeError(".palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo.predictAndLabelItems: array expected");
                            message.predictAndLabelItems = [];
                            for (var i = 0; i < object.predictAndLabelItems.length; ++i) {
                                if (typeof object.predictAndLabelItems[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo.predictAndLabelItems: object expected");
                                message.predictAndLabelItems[i] = $root.palexy.sherlock.v1.PredictedAndLabelItem.fromObject(object.predictAndLabelItems[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CalculateMergeAccuracyAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo} message CalculateMergeAccuracyAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CalculateMergeAccuracyAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.predictAndLabelItems = [];
                        if (message.predictAndLabelItems && message.predictAndLabelItems.length) {
                            object.predictAndLabelItems = [];
                            for (var j = 0; j < message.predictAndLabelItems.length; ++j)
                                object.predictAndLabelItems[j] = $root.palexy.sherlock.v1.PredictedAndLabelItem.toObject(message.predictAndLabelItems[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CalculateMergeAccuracyAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CalculateMergeAccuracyAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CalculateMergeAccuracyAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CalculateMergeAccuracyAdvanceInfo;
                })();
    
                v1.ExecutePostMergeConfigAdvanceInfo = (function() {
    
                    /**
                     * Properties of an ExecutePostMergeConfigAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IExecutePostMergeConfigAdvanceInfo
                     * @property {string|null} [mergeFileLink] ExecutePostMergeConfigAdvanceInfo mergeFileLink
                     * @property {string|null} [allBbLink] ExecutePostMergeConfigAdvanceInfo allBbLink
                     * @property {palexy.streaming.v1.IPostMergeConfig|null} [config] ExecutePostMergeConfigAdvanceInfo config
                     * @property {number|null} [storeConfigItemId] ExecutePostMergeConfigAdvanceInfo storeConfigItemId
                     */
    
                    /**
                     * Constructs a new ExecutePostMergeConfigAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an ExecutePostMergeConfigAdvanceInfo.
                     * @implements IExecutePostMergeConfigAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IExecutePostMergeConfigAdvanceInfo=} [properties] Properties to set
                     */
                    function ExecutePostMergeConfigAdvanceInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExecutePostMergeConfigAdvanceInfo mergeFileLink.
                     * @member {string} mergeFileLink
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @instance
                     */
                    ExecutePostMergeConfigAdvanceInfo.prototype.mergeFileLink = "";
    
                    /**
                     * ExecutePostMergeConfigAdvanceInfo allBbLink.
                     * @member {string} allBbLink
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @instance
                     */
                    ExecutePostMergeConfigAdvanceInfo.prototype.allBbLink = "";
    
                    /**
                     * ExecutePostMergeConfigAdvanceInfo config.
                     * @member {palexy.streaming.v1.IPostMergeConfig|null|undefined} config
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @instance
                     */
                    ExecutePostMergeConfigAdvanceInfo.prototype.config = null;
    
                    /**
                     * ExecutePostMergeConfigAdvanceInfo storeConfigItemId.
                     * @member {number} storeConfigItemId
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @instance
                     */
                    ExecutePostMergeConfigAdvanceInfo.prototype.storeConfigItemId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ExecutePostMergeConfigAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IExecutePostMergeConfigAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo} ExecutePostMergeConfigAdvanceInfo instance
                     */
                    ExecutePostMergeConfigAdvanceInfo.create = function create(properties) {
                        return new ExecutePostMergeConfigAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified ExecutePostMergeConfigAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IExecutePostMergeConfigAdvanceInfo} message ExecutePostMergeConfigAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExecutePostMergeConfigAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergeFileLink != null && Object.hasOwnProperty.call(message, "mergeFileLink"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.mergeFileLink);
                        if (message.allBbLink != null && Object.hasOwnProperty.call(message, "allBbLink"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.allBbLink);
                        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                            $root.palexy.streaming.v1.PostMergeConfig.encode(message.config, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.storeConfigItemId != null && Object.hasOwnProperty.call(message, "storeConfigItemId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.storeConfigItemId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExecutePostMergeConfigAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IExecutePostMergeConfigAdvanceInfo} message ExecutePostMergeConfigAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExecutePostMergeConfigAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExecutePostMergeConfigAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo} ExecutePostMergeConfigAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExecutePostMergeConfigAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.mergeFileLink = reader.string();
                                break;
                            case 2:
                                message.allBbLink = reader.string();
                                break;
                            case 3:
                                message.config = $root.palexy.streaming.v1.PostMergeConfig.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.storeConfigItemId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExecutePostMergeConfigAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo} ExecutePostMergeConfigAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExecutePostMergeConfigAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExecutePostMergeConfigAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExecutePostMergeConfigAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergeFileLink != null && message.hasOwnProperty("mergeFileLink"))
                            if (!$util.isString(message.mergeFileLink))
                                return "mergeFileLink: string expected";
                        if (message.allBbLink != null && message.hasOwnProperty("allBbLink"))
                            if (!$util.isString(message.allBbLink))
                                return "allBbLink: string expected";
                        if (message.config != null && message.hasOwnProperty("config")) {
                            var error = $root.palexy.streaming.v1.PostMergeConfig.verify(message.config);
                            if (error)
                                return "config." + error;
                        }
                        if (message.storeConfigItemId != null && message.hasOwnProperty("storeConfigItemId"))
                            if (!$util.isInteger(message.storeConfigItemId) && !(message.storeConfigItemId && $util.isInteger(message.storeConfigItemId.low) && $util.isInteger(message.storeConfigItemId.high)))
                                return "storeConfigItemId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExecutePostMergeConfigAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo} ExecutePostMergeConfigAdvanceInfo
                     */
                    ExecutePostMergeConfigAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo();
                        if (object.mergeFileLink != null)
                            message.mergeFileLink = String(object.mergeFileLink);
                        if (object.allBbLink != null)
                            message.allBbLink = String(object.allBbLink);
                        if (object.config != null) {
                            if (typeof object.config !== "object")
                                throw TypeError(".palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo.config: object expected");
                            message.config = $root.palexy.streaming.v1.PostMergeConfig.fromObject(object.config);
                        }
                        if (object.storeConfigItemId != null)
                            if ($util.Long)
                                (message.storeConfigItemId = $util.Long.fromValue(object.storeConfigItemId)).unsigned = false;
                            else if (typeof object.storeConfigItemId === "string")
                                message.storeConfigItemId = parseInt(object.storeConfigItemId, 10);
                            else if (typeof object.storeConfigItemId === "number")
                                message.storeConfigItemId = object.storeConfigItemId;
                            else if (typeof object.storeConfigItemId === "object")
                                message.storeConfigItemId = new $util.LongBits(object.storeConfigItemId.low >>> 0, object.storeConfigItemId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExecutePostMergeConfigAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo} message ExecutePostMergeConfigAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExecutePostMergeConfigAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.mergeFileLink = "";
                            object.allBbLink = "";
                            object.config = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeConfigItemId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeConfigItemId = options.longs === String ? "0" : 0;
                        }
                        if (message.mergeFileLink != null && message.hasOwnProperty("mergeFileLink"))
                            object.mergeFileLink = message.mergeFileLink;
                        if (message.allBbLink != null && message.hasOwnProperty("allBbLink"))
                            object.allBbLink = message.allBbLink;
                        if (message.config != null && message.hasOwnProperty("config"))
                            object.config = $root.palexy.streaming.v1.PostMergeConfig.toObject(message.config, options);
                        if (message.storeConfigItemId != null && message.hasOwnProperty("storeConfigItemId"))
                            if (typeof message.storeConfigItemId === "number")
                                object.storeConfigItemId = options.longs === String ? String(message.storeConfigItemId) : message.storeConfigItemId;
                            else
                                object.storeConfigItemId = options.longs === String ? $util.Long.prototype.toString.call(message.storeConfigItemId) : options.longs === Number ? new $util.LongBits(message.storeConfigItemId.low >>> 0, message.storeConfigItemId.high >>> 0).toNumber() : message.storeConfigItemId;
                        return object;
                    };
    
                    /**
                     * Converts this ExecutePostMergeConfigAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ExecutePostMergeConfigAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExecutePostMergeConfigAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExecutePostMergeConfigAdvanceInfo;
                })();
    
                v1.DeleteTaskFilesAdvanceInfo = (function() {
    
                    /**
                     * Properties of a DeleteTaskFilesAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IDeleteTaskFilesAdvanceInfo
                     * @property {string|null} [taskName] DeleteTaskFilesAdvanceInfo taskName
                     * @property {Array.<palexy.sherlock.v1.IGoogleCloudStorageFile>|null} [googleCloudStorageFiles] DeleteTaskFilesAdvanceInfo googleCloudStorageFiles
                     * @property {Array.<palexy.sherlock.v1.IGoogleDriveFile>|null} [googleDriveFiles] DeleteTaskFilesAdvanceInfo googleDriveFiles
                     * @property {string|null} [googleDriveTaskResultDirId] DeleteTaskFilesAdvanceInfo googleDriveTaskResultDirId
                     */
    
                    /**
                     * Constructs a new DeleteTaskFilesAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DeleteTaskFilesAdvanceInfo.
                     * @implements IDeleteTaskFilesAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IDeleteTaskFilesAdvanceInfo=} [properties] Properties to set
                     */
                    function DeleteTaskFilesAdvanceInfo(properties) {
                        this.googleCloudStorageFiles = [];
                        this.googleDriveFiles = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteTaskFilesAdvanceInfo taskName.
                     * @member {string} taskName
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @instance
                     */
                    DeleteTaskFilesAdvanceInfo.prototype.taskName = "";
    
                    /**
                     * DeleteTaskFilesAdvanceInfo googleCloudStorageFiles.
                     * @member {Array.<palexy.sherlock.v1.IGoogleCloudStorageFile>} googleCloudStorageFiles
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @instance
                     */
                    DeleteTaskFilesAdvanceInfo.prototype.googleCloudStorageFiles = $util.emptyArray;
    
                    /**
                     * DeleteTaskFilesAdvanceInfo googleDriveFiles.
                     * @member {Array.<palexy.sherlock.v1.IGoogleDriveFile>} googleDriveFiles
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @instance
                     */
                    DeleteTaskFilesAdvanceInfo.prototype.googleDriveFiles = $util.emptyArray;
    
                    /**
                     * DeleteTaskFilesAdvanceInfo googleDriveTaskResultDirId.
                     * @member {string} googleDriveTaskResultDirId
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @instance
                     */
                    DeleteTaskFilesAdvanceInfo.prototype.googleDriveTaskResultDirId = "";
    
                    /**
                     * Creates a new DeleteTaskFilesAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteTaskFilesAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo} DeleteTaskFilesAdvanceInfo instance
                     */
                    DeleteTaskFilesAdvanceInfo.create = function create(properties) {
                        return new DeleteTaskFilesAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteTaskFilesAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteTaskFilesAdvanceInfo} message DeleteTaskFilesAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteTaskFilesAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.taskName != null && Object.hasOwnProperty.call(message, "taskName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskName);
                        if (message.googleCloudStorageFiles != null && message.googleCloudStorageFiles.length)
                            for (var i = 0; i < message.googleCloudStorageFiles.length; ++i)
                                $root.palexy.sherlock.v1.GoogleCloudStorageFile.encode(message.googleCloudStorageFiles[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.googleDriveFiles != null && message.googleDriveFiles.length)
                            for (var i = 0; i < message.googleDriveFiles.length; ++i)
                                $root.palexy.sherlock.v1.GoogleDriveFile.encode(message.googleDriveFiles[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.googleDriveTaskResultDirId != null && Object.hasOwnProperty.call(message, "googleDriveTaskResultDirId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.googleDriveTaskResultDirId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteTaskFilesAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteTaskFilesAdvanceInfo} message DeleteTaskFilesAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteTaskFilesAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteTaskFilesAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo} DeleteTaskFilesAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteTaskFilesAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.taskName = reader.string();
                                break;
                            case 2:
                                if (!(message.googleCloudStorageFiles && message.googleCloudStorageFiles.length))
                                    message.googleCloudStorageFiles = [];
                                message.googleCloudStorageFiles.push($root.palexy.sherlock.v1.GoogleCloudStorageFile.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                if (!(message.googleDriveFiles && message.googleDriveFiles.length))
                                    message.googleDriveFiles = [];
                                message.googleDriveFiles.push($root.palexy.sherlock.v1.GoogleDriveFile.decode(reader, reader.uint32()));
                                break;
                            case 4:
                                message.googleDriveTaskResultDirId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteTaskFilesAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo} DeleteTaskFilesAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteTaskFilesAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteTaskFilesAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteTaskFilesAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.taskName != null && message.hasOwnProperty("taskName"))
                            if (!$util.isString(message.taskName))
                                return "taskName: string expected";
                        if (message.googleCloudStorageFiles != null && message.hasOwnProperty("googleCloudStorageFiles")) {
                            if (!Array.isArray(message.googleCloudStorageFiles))
                                return "googleCloudStorageFiles: array expected";
                            for (var i = 0; i < message.googleCloudStorageFiles.length; ++i) {
                                var error = $root.palexy.sherlock.v1.GoogleCloudStorageFile.verify(message.googleCloudStorageFiles[i]);
                                if (error)
                                    return "googleCloudStorageFiles." + error;
                            }
                        }
                        if (message.googleDriveFiles != null && message.hasOwnProperty("googleDriveFiles")) {
                            if (!Array.isArray(message.googleDriveFiles))
                                return "googleDriveFiles: array expected";
                            for (var i = 0; i < message.googleDriveFiles.length; ++i) {
                                var error = $root.palexy.sherlock.v1.GoogleDriveFile.verify(message.googleDriveFiles[i]);
                                if (error)
                                    return "googleDriveFiles." + error;
                            }
                        }
                        if (message.googleDriveTaskResultDirId != null && message.hasOwnProperty("googleDriveTaskResultDirId"))
                            if (!$util.isString(message.googleDriveTaskResultDirId))
                                return "googleDriveTaskResultDirId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteTaskFilesAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo} DeleteTaskFilesAdvanceInfo
                     */
                    DeleteTaskFilesAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo();
                        if (object.taskName != null)
                            message.taskName = String(object.taskName);
                        if (object.googleCloudStorageFiles) {
                            if (!Array.isArray(object.googleCloudStorageFiles))
                                throw TypeError(".palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo.googleCloudStorageFiles: array expected");
                            message.googleCloudStorageFiles = [];
                            for (var i = 0; i < object.googleCloudStorageFiles.length; ++i) {
                                if (typeof object.googleCloudStorageFiles[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo.googleCloudStorageFiles: object expected");
                                message.googleCloudStorageFiles[i] = $root.palexy.sherlock.v1.GoogleCloudStorageFile.fromObject(object.googleCloudStorageFiles[i]);
                            }
                        }
                        if (object.googleDriveFiles) {
                            if (!Array.isArray(object.googleDriveFiles))
                                throw TypeError(".palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo.googleDriveFiles: array expected");
                            message.googleDriveFiles = [];
                            for (var i = 0; i < object.googleDriveFiles.length; ++i) {
                                if (typeof object.googleDriveFiles[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo.googleDriveFiles: object expected");
                                message.googleDriveFiles[i] = $root.palexy.sherlock.v1.GoogleDriveFile.fromObject(object.googleDriveFiles[i]);
                            }
                        }
                        if (object.googleDriveTaskResultDirId != null)
                            message.googleDriveTaskResultDirId = String(object.googleDriveTaskResultDirId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteTaskFilesAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo} message DeleteTaskFilesAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteTaskFilesAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.googleCloudStorageFiles = [];
                            object.googleDriveFiles = [];
                        }
                        if (options.defaults) {
                            object.taskName = "";
                            object.googleDriveTaskResultDirId = "";
                        }
                        if (message.taskName != null && message.hasOwnProperty("taskName"))
                            object.taskName = message.taskName;
                        if (message.googleCloudStorageFiles && message.googleCloudStorageFiles.length) {
                            object.googleCloudStorageFiles = [];
                            for (var j = 0; j < message.googleCloudStorageFiles.length; ++j)
                                object.googleCloudStorageFiles[j] = $root.palexy.sherlock.v1.GoogleCloudStorageFile.toObject(message.googleCloudStorageFiles[j], options);
                        }
                        if (message.googleDriveFiles && message.googleDriveFiles.length) {
                            object.googleDriveFiles = [];
                            for (var j = 0; j < message.googleDriveFiles.length; ++j)
                                object.googleDriveFiles[j] = $root.palexy.sherlock.v1.GoogleDriveFile.toObject(message.googleDriveFiles[j], options);
                        }
                        if (message.googleDriveTaskResultDirId != null && message.hasOwnProperty("googleDriveTaskResultDirId"))
                            object.googleDriveTaskResultDirId = message.googleDriveTaskResultDirId;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteTaskFilesAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DeleteTaskFilesAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteTaskFilesAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteTaskFilesAdvanceInfo;
                })();
    
                v1.BatchDeleteVideosAdvanceInfo = (function() {
    
                    /**
                     * Properties of a BatchDeleteVideosAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IBatchDeleteVideosAdvanceInfo
                     * @property {Array.<string>|null} [names] BatchDeleteVideosAdvanceInfo names
                     */
    
                    /**
                     * Constructs a new BatchDeleteVideosAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BatchDeleteVideosAdvanceInfo.
                     * @implements IBatchDeleteVideosAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IBatchDeleteVideosAdvanceInfo=} [properties] Properties to set
                     */
                    function BatchDeleteVideosAdvanceInfo(properties) {
                        this.names = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchDeleteVideosAdvanceInfo names.
                     * @member {Array.<string>} names
                     * @memberof palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo
                     * @instance
                     */
                    BatchDeleteVideosAdvanceInfo.prototype.names = $util.emptyArray;
    
                    /**
                     * Creates a new BatchDeleteVideosAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IBatchDeleteVideosAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo} BatchDeleteVideosAdvanceInfo instance
                     */
                    BatchDeleteVideosAdvanceInfo.create = function create(properties) {
                        return new BatchDeleteVideosAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified BatchDeleteVideosAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IBatchDeleteVideosAdvanceInfo} message BatchDeleteVideosAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteVideosAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.names != null && message.names.length)
                            for (var i = 0; i < message.names.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.names[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchDeleteVideosAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IBatchDeleteVideosAdvanceInfo} message BatchDeleteVideosAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteVideosAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchDeleteVideosAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo} BatchDeleteVideosAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteVideosAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.names && message.names.length))
                                    message.names = [];
                                message.names.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchDeleteVideosAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo} BatchDeleteVideosAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteVideosAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchDeleteVideosAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchDeleteVideosAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.names != null && message.hasOwnProperty("names")) {
                            if (!Array.isArray(message.names))
                                return "names: array expected";
                            for (var i = 0; i < message.names.length; ++i)
                                if (!$util.isString(message.names[i]))
                                    return "names: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchDeleteVideosAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo} BatchDeleteVideosAdvanceInfo
                     */
                    BatchDeleteVideosAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo();
                        if (object.names) {
                            if (!Array.isArray(object.names))
                                throw TypeError(".palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo.names: array expected");
                            message.names = [];
                            for (var i = 0; i < object.names.length; ++i)
                                message.names[i] = String(object.names[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchDeleteVideosAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo} message BatchDeleteVideosAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchDeleteVideosAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.names = [];
                        if (message.names && message.names.length) {
                            object.names = [];
                            for (var j = 0; j < message.names.length; ++j)
                                object.names[j] = message.names[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchDeleteVideosAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BatchDeleteVideosAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchDeleteVideosAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchDeleteVideosAdvanceInfo;
                })();
    
                v1.PrepareQcVideosAdvanceInfo = (function() {
    
                    /**
                     * Properties of a PrepareQcVideosAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IPrepareQcVideosAdvanceInfo
                     * @property {number|null} [storeId] PrepareQcVideosAdvanceInfo storeId
                     * @property {string|null} [dateId] PrepareQcVideosAdvanceInfo dateId
                     * @property {number|null} [startHour] PrepareQcVideosAdvanceInfo startHour
                     * @property {number|null} [endHour] PrepareQcVideosAdvanceInfo endHour
                     * @property {Array.<string>|null} [cameraIds] PrepareQcVideosAdvanceInfo cameraIds
                     * @property {boolean|null} [compressVideo] PrepareQcVideosAdvanceInfo compressVideo
                     * @property {Array.<string>|null} [notificationEmails] PrepareQcVideosAdvanceInfo notificationEmails
                     * @property {palexy.sherlock.v1.SherlockTask.StorageProvider|null} [storageProvider] PrepareQcVideosAdvanceInfo storageProvider
                     * @property {boolean|null} [anonymizeFace] PrepareQcVideosAdvanceInfo anonymizeFace
                     * @property {number|null} [fps] PrepareQcVideosAdvanceInfo fps
                     */
    
                    /**
                     * Constructs a new PrepareQcVideosAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a PrepareQcVideosAdvanceInfo.
                     * @implements IPrepareQcVideosAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IPrepareQcVideosAdvanceInfo=} [properties] Properties to set
                     */
                    function PrepareQcVideosAdvanceInfo(properties) {
                        this.cameraIds = [];
                        this.notificationEmails = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PrepareQcVideosAdvanceInfo storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @instance
                     */
                    PrepareQcVideosAdvanceInfo.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PrepareQcVideosAdvanceInfo dateId.
                     * @member {string} dateId
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @instance
                     */
                    PrepareQcVideosAdvanceInfo.prototype.dateId = "";
    
                    /**
                     * PrepareQcVideosAdvanceInfo startHour.
                     * @member {number} startHour
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @instance
                     */
                    PrepareQcVideosAdvanceInfo.prototype.startHour = 0;
    
                    /**
                     * PrepareQcVideosAdvanceInfo endHour.
                     * @member {number} endHour
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @instance
                     */
                    PrepareQcVideosAdvanceInfo.prototype.endHour = 0;
    
                    /**
                     * PrepareQcVideosAdvanceInfo cameraIds.
                     * @member {Array.<string>} cameraIds
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @instance
                     */
                    PrepareQcVideosAdvanceInfo.prototype.cameraIds = $util.emptyArray;
    
                    /**
                     * PrepareQcVideosAdvanceInfo compressVideo.
                     * @member {boolean} compressVideo
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @instance
                     */
                    PrepareQcVideosAdvanceInfo.prototype.compressVideo = false;
    
                    /**
                     * PrepareQcVideosAdvanceInfo notificationEmails.
                     * @member {Array.<string>} notificationEmails
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @instance
                     */
                    PrepareQcVideosAdvanceInfo.prototype.notificationEmails = $util.emptyArray;
    
                    /**
                     * PrepareQcVideosAdvanceInfo storageProvider.
                     * @member {palexy.sherlock.v1.SherlockTask.StorageProvider} storageProvider
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @instance
                     */
                    PrepareQcVideosAdvanceInfo.prototype.storageProvider = 0;
    
                    /**
                     * PrepareQcVideosAdvanceInfo anonymizeFace.
                     * @member {boolean} anonymizeFace
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @instance
                     */
                    PrepareQcVideosAdvanceInfo.prototype.anonymizeFace = false;
    
                    /**
                     * PrepareQcVideosAdvanceInfo fps.
                     * @member {number} fps
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @instance
                     */
                    PrepareQcVideosAdvanceInfo.prototype.fps = 0;
    
                    /**
                     * Creates a new PrepareQcVideosAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IPrepareQcVideosAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.PrepareQcVideosAdvanceInfo} PrepareQcVideosAdvanceInfo instance
                     */
                    PrepareQcVideosAdvanceInfo.create = function create(properties) {
                        return new PrepareQcVideosAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified PrepareQcVideosAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.PrepareQcVideosAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IPrepareQcVideosAdvanceInfo} message PrepareQcVideosAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PrepareQcVideosAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        if (message.startHour != null && Object.hasOwnProperty.call(message, "startHour"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.startHour);
                        if (message.endHour != null && Object.hasOwnProperty.call(message, "endHour"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.endHour);
                        if (message.cameraIds != null && message.cameraIds.length)
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.cameraIds[i]);
                        if (message.compressVideo != null && Object.hasOwnProperty.call(message, "compressVideo"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.compressVideo);
                        if (message.notificationEmails != null && message.notificationEmails.length)
                            for (var i = 0; i < message.notificationEmails.length; ++i)
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.notificationEmails[i]);
                        if (message.storageProvider != null && Object.hasOwnProperty.call(message, "storageProvider"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.storageProvider);
                        if (message.anonymizeFace != null && Object.hasOwnProperty.call(message, "anonymizeFace"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.anonymizeFace);
                        if (message.fps != null && Object.hasOwnProperty.call(message, "fps"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.fps);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PrepareQcVideosAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.PrepareQcVideosAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IPrepareQcVideosAdvanceInfo} message PrepareQcVideosAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PrepareQcVideosAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PrepareQcVideosAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.PrepareQcVideosAdvanceInfo} PrepareQcVideosAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PrepareQcVideosAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.PrepareQcVideosAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            case 3:
                                message.startHour = reader.int32();
                                break;
                            case 4:
                                message.endHour = reader.int32();
                                break;
                            case 5:
                                if (!(message.cameraIds && message.cameraIds.length))
                                    message.cameraIds = [];
                                message.cameraIds.push(reader.string());
                                break;
                            case 6:
                                message.compressVideo = reader.bool();
                                break;
                            case 7:
                                if (!(message.notificationEmails && message.notificationEmails.length))
                                    message.notificationEmails = [];
                                message.notificationEmails.push(reader.string());
                                break;
                            case 8:
                                message.storageProvider = reader.int32();
                                break;
                            case 9:
                                message.anonymizeFace = reader.bool();
                                break;
                            case 10:
                                message.fps = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PrepareQcVideosAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.PrepareQcVideosAdvanceInfo} PrepareQcVideosAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PrepareQcVideosAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PrepareQcVideosAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PrepareQcVideosAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.startHour != null && message.hasOwnProperty("startHour"))
                            if (!$util.isInteger(message.startHour))
                                return "startHour: integer expected";
                        if (message.endHour != null && message.hasOwnProperty("endHour"))
                            if (!$util.isInteger(message.endHour))
                                return "endHour: integer expected";
                        if (message.cameraIds != null && message.hasOwnProperty("cameraIds")) {
                            if (!Array.isArray(message.cameraIds))
                                return "cameraIds: array expected";
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                if (!$util.isString(message.cameraIds[i]))
                                    return "cameraIds: string[] expected";
                        }
                        if (message.compressVideo != null && message.hasOwnProperty("compressVideo"))
                            if (typeof message.compressVideo !== "boolean")
                                return "compressVideo: boolean expected";
                        if (message.notificationEmails != null && message.hasOwnProperty("notificationEmails")) {
                            if (!Array.isArray(message.notificationEmails))
                                return "notificationEmails: array expected";
                            for (var i = 0; i < message.notificationEmails.length; ++i)
                                if (!$util.isString(message.notificationEmails[i]))
                                    return "notificationEmails: string[] expected";
                        }
                        if (message.storageProvider != null && message.hasOwnProperty("storageProvider"))
                            switch (message.storageProvider) {
                            default:
                                return "storageProvider: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.anonymizeFace != null && message.hasOwnProperty("anonymizeFace"))
                            if (typeof message.anonymizeFace !== "boolean")
                                return "anonymizeFace: boolean expected";
                        if (message.fps != null && message.hasOwnProperty("fps"))
                            if (!$util.isInteger(message.fps))
                                return "fps: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a PrepareQcVideosAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.PrepareQcVideosAdvanceInfo} PrepareQcVideosAdvanceInfo
                     */
                    PrepareQcVideosAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.PrepareQcVideosAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.PrepareQcVideosAdvanceInfo();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.startHour != null)
                            message.startHour = object.startHour | 0;
                        if (object.endHour != null)
                            message.endHour = object.endHour | 0;
                        if (object.cameraIds) {
                            if (!Array.isArray(object.cameraIds))
                                throw TypeError(".palexy.sherlock.v1.PrepareQcVideosAdvanceInfo.cameraIds: array expected");
                            message.cameraIds = [];
                            for (var i = 0; i < object.cameraIds.length; ++i)
                                message.cameraIds[i] = String(object.cameraIds[i]);
                        }
                        if (object.compressVideo != null)
                            message.compressVideo = Boolean(object.compressVideo);
                        if (object.notificationEmails) {
                            if (!Array.isArray(object.notificationEmails))
                                throw TypeError(".palexy.sherlock.v1.PrepareQcVideosAdvanceInfo.notificationEmails: array expected");
                            message.notificationEmails = [];
                            for (var i = 0; i < object.notificationEmails.length; ++i)
                                message.notificationEmails[i] = String(object.notificationEmails[i]);
                        }
                        switch (object.storageProvider) {
                        case "UNKNOWN_PROVIDER":
                        case 0:
                            message.storageProvider = 0;
                            break;
                        case "GCP":
                        case 1:
                            message.storageProvider = 1;
                            break;
                        case "GOOGLE_DRIVE":
                        case 2:
                            message.storageProvider = 2;
                            break;
                        }
                        if (object.anonymizeFace != null)
                            message.anonymizeFace = Boolean(object.anonymizeFace);
                        if (object.fps != null)
                            message.fps = object.fps | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PrepareQcVideosAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.PrepareQcVideosAdvanceInfo} message PrepareQcVideosAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PrepareQcVideosAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.cameraIds = [];
                            object.notificationEmails = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.startHour = 0;
                            object.endHour = 0;
                            object.compressVideo = false;
                            object.storageProvider = options.enums === String ? "UNKNOWN_PROVIDER" : 0;
                            object.anonymizeFace = false;
                            object.fps = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.startHour != null && message.hasOwnProperty("startHour"))
                            object.startHour = message.startHour;
                        if (message.endHour != null && message.hasOwnProperty("endHour"))
                            object.endHour = message.endHour;
                        if (message.cameraIds && message.cameraIds.length) {
                            object.cameraIds = [];
                            for (var j = 0; j < message.cameraIds.length; ++j)
                                object.cameraIds[j] = message.cameraIds[j];
                        }
                        if (message.compressVideo != null && message.hasOwnProperty("compressVideo"))
                            object.compressVideo = message.compressVideo;
                        if (message.notificationEmails && message.notificationEmails.length) {
                            object.notificationEmails = [];
                            for (var j = 0; j < message.notificationEmails.length; ++j)
                                object.notificationEmails[j] = message.notificationEmails[j];
                        }
                        if (message.storageProvider != null && message.hasOwnProperty("storageProvider"))
                            object.storageProvider = options.enums === String ? $root.palexy.sherlock.v1.SherlockTask.StorageProvider[message.storageProvider] : message.storageProvider;
                        if (message.anonymizeFace != null && message.hasOwnProperty("anonymizeFace"))
                            object.anonymizeFace = message.anonymizeFace;
                        if (message.fps != null && message.hasOwnProperty("fps"))
                            object.fps = message.fps;
                        return object;
                    };
    
                    /**
                     * Converts this PrepareQcVideosAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.PrepareQcVideosAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PrepareQcVideosAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PrepareQcVideosAdvanceInfo;
                })();
    
                v1.HSCollectReidAdvanceInfo = (function() {
    
                    /**
                     * Properties of a HSCollectReidAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IHSCollectReidAdvanceInfo
                     * @property {string|null} [humanSupervisorTaskName] HSCollectReidAdvanceInfo humanSupervisorTaskName
                     */
    
                    /**
                     * Constructs a new HSCollectReidAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a HSCollectReidAdvanceInfo.
                     * @implements IHSCollectReidAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IHSCollectReidAdvanceInfo=} [properties] Properties to set
                     */
                    function HSCollectReidAdvanceInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * HSCollectReidAdvanceInfo humanSupervisorTaskName.
                     * @member {string} humanSupervisorTaskName
                     * @memberof palexy.sherlock.v1.HSCollectReidAdvanceInfo
                     * @instance
                     */
                    HSCollectReidAdvanceInfo.prototype.humanSupervisorTaskName = "";
    
                    /**
                     * Creates a new HSCollectReidAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.HSCollectReidAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHSCollectReidAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.HSCollectReidAdvanceInfo} HSCollectReidAdvanceInfo instance
                     */
                    HSCollectReidAdvanceInfo.create = function create(properties) {
                        return new HSCollectReidAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified HSCollectReidAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.HSCollectReidAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.HSCollectReidAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHSCollectReidAdvanceInfo} message HSCollectReidAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HSCollectReidAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.humanSupervisorTaskName != null && Object.hasOwnProperty.call(message, "humanSupervisorTaskName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.humanSupervisorTaskName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified HSCollectReidAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.HSCollectReidAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.HSCollectReidAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHSCollectReidAdvanceInfo} message HSCollectReidAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HSCollectReidAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a HSCollectReidAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.HSCollectReidAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.HSCollectReidAdvanceInfo} HSCollectReidAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HSCollectReidAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.HSCollectReidAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.humanSupervisorTaskName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a HSCollectReidAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.HSCollectReidAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.HSCollectReidAdvanceInfo} HSCollectReidAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HSCollectReidAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a HSCollectReidAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.HSCollectReidAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    HSCollectReidAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.humanSupervisorTaskName != null && message.hasOwnProperty("humanSupervisorTaskName"))
                            if (!$util.isString(message.humanSupervisorTaskName))
                                return "humanSupervisorTaskName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a HSCollectReidAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.HSCollectReidAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.HSCollectReidAdvanceInfo} HSCollectReidAdvanceInfo
                     */
                    HSCollectReidAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.HSCollectReidAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.HSCollectReidAdvanceInfo();
                        if (object.humanSupervisorTaskName != null)
                            message.humanSupervisorTaskName = String(object.humanSupervisorTaskName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a HSCollectReidAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.HSCollectReidAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.HSCollectReidAdvanceInfo} message HSCollectReidAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    HSCollectReidAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.humanSupervisorTaskName = "";
                        if (message.humanSupervisorTaskName != null && message.hasOwnProperty("humanSupervisorTaskName"))
                            object.humanSupervisorTaskName = message.humanSupervisorTaskName;
                        return object;
                    };
    
                    /**
                     * Converts this HSCollectReidAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.HSCollectReidAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    HSCollectReidAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return HSCollectReidAdvanceInfo;
                })();
    
                v1.TinkerPostProcessAdvanceInfo = (function() {
    
                    /**
                     * Properties of a TinkerPostProcessAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface ITinkerPostProcessAdvanceInfo
                     * @property {number|null} [tinkerTaskId] TinkerPostProcessAdvanceInfo tinkerTaskId
                     * @property {Array.<palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.ICameraLink>|null} [cameraLinks] TinkerPostProcessAdvanceInfo cameraLinks
                     * @property {Array.<palexy.dvr.v1.IDVR>|null} [dvrs] TinkerPostProcessAdvanceInfo dvrs
                     */
    
                    /**
                     * Constructs a new TinkerPostProcessAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a TinkerPostProcessAdvanceInfo.
                     * @implements ITinkerPostProcessAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.ITinkerPostProcessAdvanceInfo=} [properties] Properties to set
                     */
                    function TinkerPostProcessAdvanceInfo(properties) {
                        this.cameraLinks = [];
                        this.dvrs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TinkerPostProcessAdvanceInfo tinkerTaskId.
                     * @member {number} tinkerTaskId
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @instance
                     */
                    TinkerPostProcessAdvanceInfo.prototype.tinkerTaskId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * TinkerPostProcessAdvanceInfo cameraLinks.
                     * @member {Array.<palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.ICameraLink>} cameraLinks
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @instance
                     */
                    TinkerPostProcessAdvanceInfo.prototype.cameraLinks = $util.emptyArray;
    
                    /**
                     * TinkerPostProcessAdvanceInfo dvrs.
                     * @member {Array.<palexy.dvr.v1.IDVR>} dvrs
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @instance
                     */
                    TinkerPostProcessAdvanceInfo.prototype.dvrs = $util.emptyArray;
    
                    /**
                     * Creates a new TinkerPostProcessAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ITinkerPostProcessAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo} TinkerPostProcessAdvanceInfo instance
                     */
                    TinkerPostProcessAdvanceInfo.create = function create(properties) {
                        return new TinkerPostProcessAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified TinkerPostProcessAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ITinkerPostProcessAdvanceInfo} message TinkerPostProcessAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TinkerPostProcessAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.tinkerTaskId != null && Object.hasOwnProperty.call(message, "tinkerTaskId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.tinkerTaskId);
                        if (message.cameraLinks != null && message.cameraLinks.length)
                            for (var i = 0; i < message.cameraLinks.length; ++i)
                                $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink.encode(message.cameraLinks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.dvrs != null && message.dvrs.length)
                            for (var i = 0; i < message.dvrs.length; ++i)
                                $root.palexy.dvr.v1.DVR.encode(message.dvrs[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TinkerPostProcessAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ITinkerPostProcessAdvanceInfo} message TinkerPostProcessAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TinkerPostProcessAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TinkerPostProcessAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo} TinkerPostProcessAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TinkerPostProcessAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.tinkerTaskId = reader.int64();
                                break;
                            case 2:
                                if (!(message.cameraLinks && message.cameraLinks.length))
                                    message.cameraLinks = [];
                                message.cameraLinks.push($root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                if (!(message.dvrs && message.dvrs.length))
                                    message.dvrs = [];
                                message.dvrs.push($root.palexy.dvr.v1.DVR.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TinkerPostProcessAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo} TinkerPostProcessAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TinkerPostProcessAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TinkerPostProcessAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TinkerPostProcessAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.tinkerTaskId != null && message.hasOwnProperty("tinkerTaskId"))
                            if (!$util.isInteger(message.tinkerTaskId) && !(message.tinkerTaskId && $util.isInteger(message.tinkerTaskId.low) && $util.isInteger(message.tinkerTaskId.high)))
                                return "tinkerTaskId: integer|Long expected";
                        if (message.cameraLinks != null && message.hasOwnProperty("cameraLinks")) {
                            if (!Array.isArray(message.cameraLinks))
                                return "cameraLinks: array expected";
                            for (var i = 0; i < message.cameraLinks.length; ++i) {
                                var error = $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink.verify(message.cameraLinks[i]);
                                if (error)
                                    return "cameraLinks." + error;
                            }
                        }
                        if (message.dvrs != null && message.hasOwnProperty("dvrs")) {
                            if (!Array.isArray(message.dvrs))
                                return "dvrs: array expected";
                            for (var i = 0; i < message.dvrs.length; ++i) {
                                var error = $root.palexy.dvr.v1.DVR.verify(message.dvrs[i]);
                                if (error)
                                    return "dvrs." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a TinkerPostProcessAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo} TinkerPostProcessAdvanceInfo
                     */
                    TinkerPostProcessAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo();
                        if (object.tinkerTaskId != null)
                            if ($util.Long)
                                (message.tinkerTaskId = $util.Long.fromValue(object.tinkerTaskId)).unsigned = false;
                            else if (typeof object.tinkerTaskId === "string")
                                message.tinkerTaskId = parseInt(object.tinkerTaskId, 10);
                            else if (typeof object.tinkerTaskId === "number")
                                message.tinkerTaskId = object.tinkerTaskId;
                            else if (typeof object.tinkerTaskId === "object")
                                message.tinkerTaskId = new $util.LongBits(object.tinkerTaskId.low >>> 0, object.tinkerTaskId.high >>> 0).toNumber();
                        if (object.cameraLinks) {
                            if (!Array.isArray(object.cameraLinks))
                                throw TypeError(".palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.cameraLinks: array expected");
                            message.cameraLinks = [];
                            for (var i = 0; i < object.cameraLinks.length; ++i) {
                                if (typeof object.cameraLinks[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.cameraLinks: object expected");
                                message.cameraLinks[i] = $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink.fromObject(object.cameraLinks[i]);
                            }
                        }
                        if (object.dvrs) {
                            if (!Array.isArray(object.dvrs))
                                throw TypeError(".palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.dvrs: array expected");
                            message.dvrs = [];
                            for (var i = 0; i < object.dvrs.length; ++i) {
                                if (typeof object.dvrs[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.dvrs: object expected");
                                message.dvrs[i] = $root.palexy.dvr.v1.DVR.fromObject(object.dvrs[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TinkerPostProcessAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo} message TinkerPostProcessAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TinkerPostProcessAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.cameraLinks = [];
                            object.dvrs = [];
                        }
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.tinkerTaskId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.tinkerTaskId = options.longs === String ? "0" : 0;
                        if (message.tinkerTaskId != null && message.hasOwnProperty("tinkerTaskId"))
                            if (typeof message.tinkerTaskId === "number")
                                object.tinkerTaskId = options.longs === String ? String(message.tinkerTaskId) : message.tinkerTaskId;
                            else
                                object.tinkerTaskId = options.longs === String ? $util.Long.prototype.toString.call(message.tinkerTaskId) : options.longs === Number ? new $util.LongBits(message.tinkerTaskId.low >>> 0, message.tinkerTaskId.high >>> 0).toNumber() : message.tinkerTaskId;
                        if (message.cameraLinks && message.cameraLinks.length) {
                            object.cameraLinks = [];
                            for (var j = 0; j < message.cameraLinks.length; ++j)
                                object.cameraLinks[j] = $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink.toObject(message.cameraLinks[j], options);
                        }
                        if (message.dvrs && message.dvrs.length) {
                            object.dvrs = [];
                            for (var j = 0; j < message.dvrs.length; ++j)
                                object.dvrs[j] = $root.palexy.dvr.v1.DVR.toObject(message.dvrs[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this TinkerPostProcessAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TinkerPostProcessAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    TinkerPostProcessAdvanceInfo.CameraLink = (function() {
    
                        /**
                         * Properties of a CameraLink.
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                         * @interface ICameraLink
                         * @property {palexy.dvr.v1.IDVRCamera|null} [dvrCamera] CameraLink dvrCamera
                         * @property {palexy.streaming.v1.ICamera|null} [camera] CameraLink camera
                         */
    
                        /**
                         * Constructs a new CameraLink.
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo
                         * @classdesc Represents a CameraLink.
                         * @implements ICameraLink
                         * @constructor
                         * @param {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.ICameraLink=} [properties] Properties to set
                         */
                        function CameraLink(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * CameraLink dvrCamera.
                         * @member {palexy.dvr.v1.IDVRCamera|null|undefined} dvrCamera
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink
                         * @instance
                         */
                        CameraLink.prototype.dvrCamera = null;
    
                        /**
                         * CameraLink camera.
                         * @member {palexy.streaming.v1.ICamera|null|undefined} camera
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink
                         * @instance
                         */
                        CameraLink.prototype.camera = null;
    
                        /**
                         * Creates a new CameraLink instance using the specified properties.
                         * @function create
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink
                         * @static
                         * @param {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.ICameraLink=} [properties] Properties to set
                         * @returns {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink} CameraLink instance
                         */
                        CameraLink.create = function create(properties) {
                            return new CameraLink(properties);
                        };
    
                        /**
                         * Encodes the specified CameraLink message. Does not implicitly {@link palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink
                         * @static
                         * @param {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.ICameraLink} message CameraLink message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CameraLink.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.dvrCamera != null && Object.hasOwnProperty.call(message, "dvrCamera"))
                                $root.palexy.dvr.v1.DVRCamera.encode(message.dvrCamera, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.camera != null && Object.hasOwnProperty.call(message, "camera"))
                                $root.palexy.streaming.v1.Camera.encode(message.camera, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };
    
                        /**
                         * Encodes the specified CameraLink message, length delimited. Does not implicitly {@link palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink
                         * @static
                         * @param {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.ICameraLink} message CameraLink message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CameraLink.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a CameraLink message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink} CameraLink
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CameraLink.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.dvrCamera = $root.palexy.dvr.v1.DVRCamera.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.camera = $root.palexy.streaming.v1.Camera.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a CameraLink message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink} CameraLink
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CameraLink.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a CameraLink message.
                         * @function verify
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CameraLink.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.dvrCamera != null && message.hasOwnProperty("dvrCamera")) {
                                var error = $root.palexy.dvr.v1.DVRCamera.verify(message.dvrCamera);
                                if (error)
                                    return "dvrCamera." + error;
                            }
                            if (message.camera != null && message.hasOwnProperty("camera")) {
                                var error = $root.palexy.streaming.v1.Camera.verify(message.camera);
                                if (error)
                                    return "camera." + error;
                            }
                            return null;
                        };
    
                        /**
                         * Creates a CameraLink message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink} CameraLink
                         */
                        CameraLink.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink)
                                return object;
                            var message = new $root.palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink();
                            if (object.dvrCamera != null) {
                                if (typeof object.dvrCamera !== "object")
                                    throw TypeError(".palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink.dvrCamera: object expected");
                                message.dvrCamera = $root.palexy.dvr.v1.DVRCamera.fromObject(object.dvrCamera);
                            }
                            if (object.camera != null) {
                                if (typeof object.camera !== "object")
                                    throw TypeError(".palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink.camera: object expected");
                                message.camera = $root.palexy.streaming.v1.Camera.fromObject(object.camera);
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a CameraLink message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink
                         * @static
                         * @param {palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink} message CameraLink
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CameraLink.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.dvrCamera = null;
                                object.camera = null;
                            }
                            if (message.dvrCamera != null && message.hasOwnProperty("dvrCamera"))
                                object.dvrCamera = $root.palexy.dvr.v1.DVRCamera.toObject(message.dvrCamera, options);
                            if (message.camera != null && message.hasOwnProperty("camera"))
                                object.camera = $root.palexy.streaming.v1.Camera.toObject(message.camera, options);
                            return object;
                        };
    
                        /**
                         * Converts this CameraLink to JSON.
                         * @function toJSON
                         * @memberof palexy.sherlock.v1.TinkerPostProcessAdvanceInfo.CameraLink
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CameraLink.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return CameraLink;
                    })();
    
                    return TinkerPostProcessAdvanceInfo;
                })();
    
                v1.ForecastGenerateAdvanceInfo = (function() {
    
                    /**
                     * Properties of a ForecastGenerateAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IForecastGenerateAdvanceInfo
                     * @property {number|null} [dateId] ForecastGenerateAdvanceInfo dateId
                     * @property {Array.<number>|null} [storeIds] ForecastGenerateAdvanceInfo storeIds
                     * @property {number|null} [forecastModelId] ForecastGenerateAdvanceInfo forecastModelId
                     * @property {number|null} [forecastModelVersionId] ForecastGenerateAdvanceInfo forecastModelVersionId
                     * @property {number|null} [storeId] ForecastGenerateAdvanceInfo storeId
                     */
    
                    /**
                     * Constructs a new ForecastGenerateAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ForecastGenerateAdvanceInfo.
                     * @implements IForecastGenerateAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IForecastGenerateAdvanceInfo=} [properties] Properties to set
                     */
                    function ForecastGenerateAdvanceInfo(properties) {
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ForecastGenerateAdvanceInfo dateId.
                     * @member {number} dateId
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @instance
                     */
                    ForecastGenerateAdvanceInfo.prototype.dateId = 0;
    
                    /**
                     * ForecastGenerateAdvanceInfo storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @instance
                     */
                    ForecastGenerateAdvanceInfo.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * ForecastGenerateAdvanceInfo forecastModelId.
                     * @member {number} forecastModelId
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @instance
                     */
                    ForecastGenerateAdvanceInfo.prototype.forecastModelId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ForecastGenerateAdvanceInfo forecastModelVersionId.
                     * @member {number} forecastModelVersionId
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @instance
                     */
                    ForecastGenerateAdvanceInfo.prototype.forecastModelVersionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ForecastGenerateAdvanceInfo storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @instance
                     */
                    ForecastGenerateAdvanceInfo.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ForecastGenerateAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IForecastGenerateAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ForecastGenerateAdvanceInfo} ForecastGenerateAdvanceInfo instance
                     */
                    ForecastGenerateAdvanceInfo.create = function create(properties) {
                        return new ForecastGenerateAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified ForecastGenerateAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.ForecastGenerateAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IForecastGenerateAdvanceInfo} message ForecastGenerateAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastGenerateAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dateId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.forecastModelId != null && Object.hasOwnProperty.call(message, "forecastModelId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.forecastModelId);
                        if (message.forecastModelVersionId != null && Object.hasOwnProperty.call(message, "forecastModelVersionId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.forecastModelVersionId);
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 5, wireType 2 =*/42).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ForecastGenerateAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ForecastGenerateAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IForecastGenerateAdvanceInfo} message ForecastGenerateAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastGenerateAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ForecastGenerateAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ForecastGenerateAdvanceInfo} ForecastGenerateAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastGenerateAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ForecastGenerateAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dateId = reader.int32();
                                break;
                            case 5:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 3:
                                message.forecastModelId = reader.int64();
                                break;
                            case 4:
                                message.forecastModelVersionId = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ForecastGenerateAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ForecastGenerateAdvanceInfo} ForecastGenerateAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastGenerateAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ForecastGenerateAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ForecastGenerateAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.forecastModelId != null && message.hasOwnProperty("forecastModelId"))
                            if (!$util.isInteger(message.forecastModelId) && !(message.forecastModelId && $util.isInteger(message.forecastModelId.low) && $util.isInteger(message.forecastModelId.high)))
                                return "forecastModelId: integer|Long expected";
                        if (message.forecastModelVersionId != null && message.hasOwnProperty("forecastModelVersionId"))
                            if (!$util.isInteger(message.forecastModelVersionId) && !(message.forecastModelVersionId && $util.isInteger(message.forecastModelVersionId.low) && $util.isInteger(message.forecastModelVersionId.high)))
                                return "forecastModelVersionId: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ForecastGenerateAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ForecastGenerateAdvanceInfo} ForecastGenerateAdvanceInfo
                     */
                    ForecastGenerateAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ForecastGenerateAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ForecastGenerateAdvanceInfo();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.sherlock.v1.ForecastGenerateAdvanceInfo.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.forecastModelId != null)
                            if ($util.Long)
                                (message.forecastModelId = $util.Long.fromValue(object.forecastModelId)).unsigned = false;
                            else if (typeof object.forecastModelId === "string")
                                message.forecastModelId = parseInt(object.forecastModelId, 10);
                            else if (typeof object.forecastModelId === "number")
                                message.forecastModelId = object.forecastModelId;
                            else if (typeof object.forecastModelId === "object")
                                message.forecastModelId = new $util.LongBits(object.forecastModelId.low >>> 0, object.forecastModelId.high >>> 0).toNumber();
                        if (object.forecastModelVersionId != null)
                            if ($util.Long)
                                (message.forecastModelVersionId = $util.Long.fromValue(object.forecastModelVersionId)).unsigned = false;
                            else if (typeof object.forecastModelVersionId === "string")
                                message.forecastModelVersionId = parseInt(object.forecastModelVersionId, 10);
                            else if (typeof object.forecastModelVersionId === "number")
                                message.forecastModelVersionId = object.forecastModelVersionId;
                            else if (typeof object.forecastModelVersionId === "object")
                                message.forecastModelVersionId = new $util.LongBits(object.forecastModelVersionId.low >>> 0, object.forecastModelVersionId.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ForecastGenerateAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ForecastGenerateAdvanceInfo} message ForecastGenerateAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ForecastGenerateAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeIds = [];
                        if (options.defaults) {
                            object.dateId = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.forecastModelId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.forecastModelId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.forecastModelVersionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.forecastModelVersionId = options.longs === String ? "0" : 0;
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.forecastModelId != null && message.hasOwnProperty("forecastModelId"))
                            if (typeof message.forecastModelId === "number")
                                object.forecastModelId = options.longs === String ? String(message.forecastModelId) : message.forecastModelId;
                            else
                                object.forecastModelId = options.longs === String ? $util.Long.prototype.toString.call(message.forecastModelId) : options.longs === Number ? new $util.LongBits(message.forecastModelId.low >>> 0, message.forecastModelId.high >>> 0).toNumber() : message.forecastModelId;
                        if (message.forecastModelVersionId != null && message.hasOwnProperty("forecastModelVersionId"))
                            if (typeof message.forecastModelVersionId === "number")
                                object.forecastModelVersionId = options.longs === String ? String(message.forecastModelVersionId) : message.forecastModelVersionId;
                            else
                                object.forecastModelVersionId = options.longs === String ? $util.Long.prototype.toString.call(message.forecastModelVersionId) : options.longs === Number ? new $util.LongBits(message.forecastModelVersionId.low >>> 0, message.forecastModelVersionId.high >>> 0).toNumber() : message.forecastModelVersionId;
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ForecastGenerateAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ForecastGenerateAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ForecastGenerateAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ForecastGenerateAdvanceInfo;
                })();
    
                v1.ExportHakuhodoAdvanceInfo = (function() {
    
                    /**
                     * Properties of an ExportHakuhodoAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IExportHakuhodoAdvanceInfo
                     * @property {number|null} [startDateIdInclusive] ExportHakuhodoAdvanceInfo startDateIdInclusive
                     * @property {number|null} [endDateIdInclusive] ExportHakuhodoAdvanceInfo endDateIdInclusive
                     * @property {number|null} [storeId] ExportHakuhodoAdvanceInfo storeId
                     * @property {number|null} [directionalPassByStoreId] ExportHakuhodoAdvanceInfo directionalPassByStoreId
                     * @property {boolean|null} [keepSectionVisitOnly] ExportHakuhodoAdvanceInfo keepSectionVisitOnly
                     */
    
                    /**
                     * Constructs a new ExportHakuhodoAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an ExportHakuhodoAdvanceInfo.
                     * @implements IExportHakuhodoAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IExportHakuhodoAdvanceInfo=} [properties] Properties to set
                     */
                    function ExportHakuhodoAdvanceInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExportHakuhodoAdvanceInfo startDateIdInclusive.
                     * @member {number} startDateIdInclusive
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @instance
                     */
                    ExportHakuhodoAdvanceInfo.prototype.startDateIdInclusive = 0;
    
                    /**
                     * ExportHakuhodoAdvanceInfo endDateIdInclusive.
                     * @member {number} endDateIdInclusive
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @instance
                     */
                    ExportHakuhodoAdvanceInfo.prototype.endDateIdInclusive = 0;
    
                    /**
                     * ExportHakuhodoAdvanceInfo storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @instance
                     */
                    ExportHakuhodoAdvanceInfo.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExportHakuhodoAdvanceInfo directionalPassByStoreId.
                     * @member {number} directionalPassByStoreId
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @instance
                     */
                    ExportHakuhodoAdvanceInfo.prototype.directionalPassByStoreId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExportHakuhodoAdvanceInfo keepSectionVisitOnly.
                     * @member {boolean} keepSectionVisitOnly
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @instance
                     */
                    ExportHakuhodoAdvanceInfo.prototype.keepSectionVisitOnly = false;
    
                    /**
                     * Creates a new ExportHakuhodoAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IExportHakuhodoAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ExportHakuhodoAdvanceInfo} ExportHakuhodoAdvanceInfo instance
                     */
                    ExportHakuhodoAdvanceInfo.create = function create(properties) {
                        return new ExportHakuhodoAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified ExportHakuhodoAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.ExportHakuhodoAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IExportHakuhodoAdvanceInfo} message ExportHakuhodoAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportHakuhodoAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startDateIdInclusive != null && Object.hasOwnProperty.call(message, "startDateIdInclusive"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.startDateIdInclusive);
                        if (message.endDateIdInclusive != null && Object.hasOwnProperty.call(message, "endDateIdInclusive"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.endDateIdInclusive);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        if (message.directionalPassByStoreId != null && Object.hasOwnProperty.call(message, "directionalPassByStoreId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.directionalPassByStoreId);
                        if (message.keepSectionVisitOnly != null && Object.hasOwnProperty.call(message, "keepSectionVisitOnly"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.keepSectionVisitOnly);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportHakuhodoAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ExportHakuhodoAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IExportHakuhodoAdvanceInfo} message ExportHakuhodoAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportHakuhodoAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportHakuhodoAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ExportHakuhodoAdvanceInfo} ExportHakuhodoAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportHakuhodoAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ExportHakuhodoAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startDateIdInclusive = reader.int32();
                                break;
                            case 2:
                                message.endDateIdInclusive = reader.int32();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 4:
                                message.directionalPassByStoreId = reader.int64();
                                break;
                            case 5:
                                message.keepSectionVisitOnly = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportHakuhodoAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ExportHakuhodoAdvanceInfo} ExportHakuhodoAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportHakuhodoAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportHakuhodoAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportHakuhodoAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.startDateIdInclusive != null && message.hasOwnProperty("startDateIdInclusive"))
                            if (!$util.isInteger(message.startDateIdInclusive))
                                return "startDateIdInclusive: integer expected";
                        if (message.endDateIdInclusive != null && message.hasOwnProperty("endDateIdInclusive"))
                            if (!$util.isInteger(message.endDateIdInclusive))
                                return "endDateIdInclusive: integer expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.directionalPassByStoreId != null && message.hasOwnProperty("directionalPassByStoreId"))
                            if (!$util.isInteger(message.directionalPassByStoreId) && !(message.directionalPassByStoreId && $util.isInteger(message.directionalPassByStoreId.low) && $util.isInteger(message.directionalPassByStoreId.high)))
                                return "directionalPassByStoreId: integer|Long expected";
                        if (message.keepSectionVisitOnly != null && message.hasOwnProperty("keepSectionVisitOnly"))
                            if (typeof message.keepSectionVisitOnly !== "boolean")
                                return "keepSectionVisitOnly: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExportHakuhodoAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ExportHakuhodoAdvanceInfo} ExportHakuhodoAdvanceInfo
                     */
                    ExportHakuhodoAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ExportHakuhodoAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ExportHakuhodoAdvanceInfo();
                        if (object.startDateIdInclusive != null)
                            message.startDateIdInclusive = object.startDateIdInclusive | 0;
                        if (object.endDateIdInclusive != null)
                            message.endDateIdInclusive = object.endDateIdInclusive | 0;
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.directionalPassByStoreId != null)
                            if ($util.Long)
                                (message.directionalPassByStoreId = $util.Long.fromValue(object.directionalPassByStoreId)).unsigned = false;
                            else if (typeof object.directionalPassByStoreId === "string")
                                message.directionalPassByStoreId = parseInt(object.directionalPassByStoreId, 10);
                            else if (typeof object.directionalPassByStoreId === "number")
                                message.directionalPassByStoreId = object.directionalPassByStoreId;
                            else if (typeof object.directionalPassByStoreId === "object")
                                message.directionalPassByStoreId = new $util.LongBits(object.directionalPassByStoreId.low >>> 0, object.directionalPassByStoreId.high >>> 0).toNumber();
                        if (object.keepSectionVisitOnly != null)
                            message.keepSectionVisitOnly = Boolean(object.keepSectionVisitOnly);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExportHakuhodoAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ExportHakuhodoAdvanceInfo} message ExportHakuhodoAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportHakuhodoAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startDateIdInclusive = 0;
                            object.endDateIdInclusive = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.directionalPassByStoreId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.directionalPassByStoreId = options.longs === String ? "0" : 0;
                            object.keepSectionVisitOnly = false;
                        }
                        if (message.startDateIdInclusive != null && message.hasOwnProperty("startDateIdInclusive"))
                            object.startDateIdInclusive = message.startDateIdInclusive;
                        if (message.endDateIdInclusive != null && message.hasOwnProperty("endDateIdInclusive"))
                            object.endDateIdInclusive = message.endDateIdInclusive;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.directionalPassByStoreId != null && message.hasOwnProperty("directionalPassByStoreId"))
                            if (typeof message.directionalPassByStoreId === "number")
                                object.directionalPassByStoreId = options.longs === String ? String(message.directionalPassByStoreId) : message.directionalPassByStoreId;
                            else
                                object.directionalPassByStoreId = options.longs === String ? $util.Long.prototype.toString.call(message.directionalPassByStoreId) : options.longs === Number ? new $util.LongBits(message.directionalPassByStoreId.low >>> 0, message.directionalPassByStoreId.high >>> 0).toNumber() : message.directionalPassByStoreId;
                        if (message.keepSectionVisitOnly != null && message.hasOwnProperty("keepSectionVisitOnly"))
                            object.keepSectionVisitOnly = message.keepSectionVisitOnly;
                        return object;
                    };
    
                    /**
                     * Converts this ExportHakuhodoAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ExportHakuhodoAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportHakuhodoAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExportHakuhodoAdvanceInfo;
                })();
    
                v1.HakuhodoJoinPartialMergingResultsAdvanceInfo = (function() {
    
                    /**
                     * Properties of a HakuhodoJoinPartialMergingResultsAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IHakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @property {number|null} [startDateIdInclusive] HakuhodoJoinPartialMergingResultsAdvanceInfo startDateIdInclusive
                     * @property {number|null} [endDateIdInclusive] HakuhodoJoinPartialMergingResultsAdvanceInfo endDateIdInclusive
                     * @property {Array.<number>|null} [storeIds] HakuhodoJoinPartialMergingResultsAdvanceInfo storeIds
                     */
    
                    /**
                     * Constructs a new HakuhodoJoinPartialMergingResultsAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a HakuhodoJoinPartialMergingResultsAdvanceInfo.
                     * @implements IHakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IHakuhodoJoinPartialMergingResultsAdvanceInfo=} [properties] Properties to set
                     */
                    function HakuhodoJoinPartialMergingResultsAdvanceInfo(properties) {
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * HakuhodoJoinPartialMergingResultsAdvanceInfo startDateIdInclusive.
                     * @member {number} startDateIdInclusive
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @instance
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.prototype.startDateIdInclusive = 0;
    
                    /**
                     * HakuhodoJoinPartialMergingResultsAdvanceInfo endDateIdInclusive.
                     * @member {number} endDateIdInclusive
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @instance
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.prototype.endDateIdInclusive = 0;
    
                    /**
                     * HakuhodoJoinPartialMergingResultsAdvanceInfo storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @instance
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * Creates a new HakuhodoJoinPartialMergingResultsAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHakuhodoJoinPartialMergingResultsAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo} HakuhodoJoinPartialMergingResultsAdvanceInfo instance
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.create = function create(properties) {
                        return new HakuhodoJoinPartialMergingResultsAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified HakuhodoJoinPartialMergingResultsAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHakuhodoJoinPartialMergingResultsAdvanceInfo} message HakuhodoJoinPartialMergingResultsAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startDateIdInclusive != null && Object.hasOwnProperty.call(message, "startDateIdInclusive"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.startDateIdInclusive);
                        if (message.endDateIdInclusive != null && Object.hasOwnProperty.call(message, "endDateIdInclusive"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.endDateIdInclusive);
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified HakuhodoJoinPartialMergingResultsAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHakuhodoJoinPartialMergingResultsAdvanceInfo} message HakuhodoJoinPartialMergingResultsAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a HakuhodoJoinPartialMergingResultsAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo} HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startDateIdInclusive = reader.int32();
                                break;
                            case 2:
                                message.endDateIdInclusive = reader.int32();
                                break;
                            case 3:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a HakuhodoJoinPartialMergingResultsAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo} HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a HakuhodoJoinPartialMergingResultsAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.startDateIdInclusive != null && message.hasOwnProperty("startDateIdInclusive"))
                            if (!$util.isInteger(message.startDateIdInclusive))
                                return "startDateIdInclusive: integer expected";
                        if (message.endDateIdInclusive != null && message.hasOwnProperty("endDateIdInclusive"))
                            if (!$util.isInteger(message.endDateIdInclusive))
                                return "endDateIdInclusive: integer expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a HakuhodoJoinPartialMergingResultsAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo} HakuhodoJoinPartialMergingResultsAdvanceInfo
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo();
                        if (object.startDateIdInclusive != null)
                            message.startDateIdInclusive = object.startDateIdInclusive | 0;
                        if (object.endDateIdInclusive != null)
                            message.endDateIdInclusive = object.endDateIdInclusive | 0;
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a HakuhodoJoinPartialMergingResultsAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo} message HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeIds = [];
                        if (options.defaults) {
                            object.startDateIdInclusive = 0;
                            object.endDateIdInclusive = 0;
                        }
                        if (message.startDateIdInclusive != null && message.hasOwnProperty("startDateIdInclusive"))
                            object.startDateIdInclusive = message.startDateIdInclusive;
                        if (message.endDateIdInclusive != null && message.hasOwnProperty("endDateIdInclusive"))
                            object.endDateIdInclusive = message.endDateIdInclusive;
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this HakuhodoJoinPartialMergingResultsAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    HakuhodoJoinPartialMergingResultsAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return HakuhodoJoinPartialMergingResultsAdvanceInfo;
                })();
    
                v1.HakuhodoEditTrafficAdvanceInfo = (function() {
    
                    /**
                     * Properties of a HakuhodoEditTrafficAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IHakuhodoEditTrafficAdvanceInfo
                     * @property {Array.<palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.ITrafficEdit>|null} [trafficEdits] HakuhodoEditTrafficAdvanceInfo trafficEdits
                     * @property {Array.<palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.IPassByEdit>|null} [passByEdits] HakuhodoEditTrafficAdvanceInfo passByEdits
                     */
    
                    /**
                     * Constructs a new HakuhodoEditTrafficAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a HakuhodoEditTrafficAdvanceInfo.
                     * @implements IHakuhodoEditTrafficAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IHakuhodoEditTrafficAdvanceInfo=} [properties] Properties to set
                     */
                    function HakuhodoEditTrafficAdvanceInfo(properties) {
                        this.trafficEdits = [];
                        this.passByEdits = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * HakuhodoEditTrafficAdvanceInfo trafficEdits.
                     * @member {Array.<palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.ITrafficEdit>} trafficEdits
                     * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                     * @instance
                     */
                    HakuhodoEditTrafficAdvanceInfo.prototype.trafficEdits = $util.emptyArray;
    
                    /**
                     * HakuhodoEditTrafficAdvanceInfo passByEdits.
                     * @member {Array.<palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.IPassByEdit>} passByEdits
                     * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                     * @instance
                     */
                    HakuhodoEditTrafficAdvanceInfo.prototype.passByEdits = $util.emptyArray;
    
                    /**
                     * Creates a new HakuhodoEditTrafficAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHakuhodoEditTrafficAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo} HakuhodoEditTrafficAdvanceInfo instance
                     */
                    HakuhodoEditTrafficAdvanceInfo.create = function create(properties) {
                        return new HakuhodoEditTrafficAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified HakuhodoEditTrafficAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHakuhodoEditTrafficAdvanceInfo} message HakuhodoEditTrafficAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HakuhodoEditTrafficAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.trafficEdits != null && message.trafficEdits.length)
                            for (var i = 0; i < message.trafficEdits.length; ++i)
                                $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit.encode(message.trafficEdits[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.passByEdits != null && message.passByEdits.length)
                            for (var i = 0; i < message.passByEdits.length; ++i)
                                $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit.encode(message.passByEdits[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified HakuhodoEditTrafficAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHakuhodoEditTrafficAdvanceInfo} message HakuhodoEditTrafficAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HakuhodoEditTrafficAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a HakuhodoEditTrafficAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo} HakuhodoEditTrafficAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HakuhodoEditTrafficAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.trafficEdits && message.trafficEdits.length))
                                    message.trafficEdits = [];
                                message.trafficEdits.push($root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.passByEdits && message.passByEdits.length))
                                    message.passByEdits = [];
                                message.passByEdits.push($root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a HakuhodoEditTrafficAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo} HakuhodoEditTrafficAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HakuhodoEditTrafficAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a HakuhodoEditTrafficAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    HakuhodoEditTrafficAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.trafficEdits != null && message.hasOwnProperty("trafficEdits")) {
                            if (!Array.isArray(message.trafficEdits))
                                return "trafficEdits: array expected";
                            for (var i = 0; i < message.trafficEdits.length; ++i) {
                                var error = $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit.verify(message.trafficEdits[i]);
                                if (error)
                                    return "trafficEdits." + error;
                            }
                        }
                        if (message.passByEdits != null && message.hasOwnProperty("passByEdits")) {
                            if (!Array.isArray(message.passByEdits))
                                return "passByEdits: array expected";
                            for (var i = 0; i < message.passByEdits.length; ++i) {
                                var error = $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit.verify(message.passByEdits[i]);
                                if (error)
                                    return "passByEdits." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a HakuhodoEditTrafficAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo} HakuhodoEditTrafficAdvanceInfo
                     */
                    HakuhodoEditTrafficAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo();
                        if (object.trafficEdits) {
                            if (!Array.isArray(object.trafficEdits))
                                throw TypeError(".palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.trafficEdits: array expected");
                            message.trafficEdits = [];
                            for (var i = 0; i < object.trafficEdits.length; ++i) {
                                if (typeof object.trafficEdits[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.trafficEdits: object expected");
                                message.trafficEdits[i] = $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit.fromObject(object.trafficEdits[i]);
                            }
                        }
                        if (object.passByEdits) {
                            if (!Array.isArray(object.passByEdits))
                                throw TypeError(".palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.passByEdits: array expected");
                            message.passByEdits = [];
                            for (var i = 0; i < object.passByEdits.length; ++i) {
                                if (typeof object.passByEdits[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.passByEdits: object expected");
                                message.passByEdits[i] = $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit.fromObject(object.passByEdits[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a HakuhodoEditTrafficAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo} message HakuhodoEditTrafficAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    HakuhodoEditTrafficAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.trafficEdits = [];
                            object.passByEdits = [];
                        }
                        if (message.trafficEdits && message.trafficEdits.length) {
                            object.trafficEdits = [];
                            for (var j = 0; j < message.trafficEdits.length; ++j)
                                object.trafficEdits[j] = $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit.toObject(message.trafficEdits[j], options);
                        }
                        if (message.passByEdits && message.passByEdits.length) {
                            object.passByEdits = [];
                            for (var j = 0; j < message.passByEdits.length; ++j)
                                object.passByEdits[j] = $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit.toObject(message.passByEdits[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this HakuhodoEditTrafficAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    HakuhodoEditTrafficAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    HakuhodoEditTrafficAdvanceInfo.TrafficEdit = (function() {
    
                        /**
                         * Properties of a TrafficEdit.
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                         * @interface ITrafficEdit
                         * @property {number|null} [storeId] TrafficEdit storeId
                         * @property {number|null} [dateId] TrafficEdit dateId
                         * @property {number|null} [value] TrafficEdit value
                         */
    
                        /**
                         * Constructs a new TrafficEdit.
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                         * @classdesc Represents a TrafficEdit.
                         * @implements ITrafficEdit
                         * @constructor
                         * @param {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.ITrafficEdit=} [properties] Properties to set
                         */
                        function TrafficEdit(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * TrafficEdit storeId.
                         * @member {number} storeId
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @instance
                         */
                        TrafficEdit.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * TrafficEdit dateId.
                         * @member {number} dateId
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @instance
                         */
                        TrafficEdit.prototype.dateId = 0;
    
                        /**
                         * TrafficEdit value.
                         * @member {number} value
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @instance
                         */
                        TrafficEdit.prototype.value = 0;
    
                        /**
                         * Creates a new TrafficEdit instance using the specified properties.
                         * @function create
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @static
                         * @param {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.ITrafficEdit=} [properties] Properties to set
                         * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit} TrafficEdit instance
                         */
                        TrafficEdit.create = function create(properties) {
                            return new TrafficEdit(properties);
                        };
    
                        /**
                         * Encodes the specified TrafficEdit message. Does not implicitly {@link palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @static
                         * @param {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.ITrafficEdit} message TrafficEdit message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TrafficEdit.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                            if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateId);
                            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.value);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified TrafficEdit message, length delimited. Does not implicitly {@link palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @static
                         * @param {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.ITrafficEdit} message TrafficEdit message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TrafficEdit.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a TrafficEdit message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit} TrafficEdit
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TrafficEdit.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.storeId = reader.int64();
                                    break;
                                case 2:
                                    message.dateId = reader.int32();
                                    break;
                                case 3:
                                    message.value = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a TrafficEdit message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit} TrafficEdit
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TrafficEdit.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a TrafficEdit message.
                         * @function verify
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        TrafficEdit.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                    return "storeId: integer|Long expected";
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                if (!$util.isInteger(message.dateId))
                                    return "dateId: integer expected";
                            if (message.value != null && message.hasOwnProperty("value"))
                                if (!$util.isInteger(message.value))
                                    return "value: integer expected";
                            return null;
                        };
    
                        /**
                         * Creates a TrafficEdit message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit} TrafficEdit
                         */
                        TrafficEdit.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit)
                                return object;
                            var message = new $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit();
                            if (object.storeId != null)
                                if ($util.Long)
                                    (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                                else if (typeof object.storeId === "string")
                                    message.storeId = parseInt(object.storeId, 10);
                                else if (typeof object.storeId === "number")
                                    message.storeId = object.storeId;
                                else if (typeof object.storeId === "object")
                                    message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                            if (object.dateId != null)
                                message.dateId = object.dateId | 0;
                            if (object.value != null)
                                message.value = object.value | 0;
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a TrafficEdit message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @static
                         * @param {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit} message TrafficEdit
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        TrafficEdit.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.storeId = options.longs === String ? "0" : 0;
                                object.dateId = 0;
                                object.value = 0;
                            }
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (typeof message.storeId === "number")
                                    object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                                else
                                    object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                object.dateId = message.dateId;
                            if (message.value != null && message.hasOwnProperty("value"))
                                object.value = message.value;
                            return object;
                        };
    
                        /**
                         * Converts this TrafficEdit to JSON.
                         * @function toJSON
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        TrafficEdit.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return TrafficEdit;
                    })();
    
                    HakuhodoEditTrafficAdvanceInfo.PassByEdit = (function() {
    
                        /**
                         * Properties of a PassByEdit.
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                         * @interface IPassByEdit
                         * @property {number|null} [storeId] PassByEdit storeId
                         * @property {number|null} [dateId] PassByEdit dateId
                         * @property {number|null} [hour] PassByEdit hour
                         * @property {number|null} [value] PassByEdit value
                         */
    
                        /**
                         * Constructs a new PassByEdit.
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo
                         * @classdesc Represents a PassByEdit.
                         * @implements IPassByEdit
                         * @constructor
                         * @param {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.IPassByEdit=} [properties] Properties to set
                         */
                        function PassByEdit(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * PassByEdit storeId.
                         * @member {number} storeId
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @instance
                         */
                        PassByEdit.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * PassByEdit dateId.
                         * @member {number} dateId
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @instance
                         */
                        PassByEdit.prototype.dateId = 0;
    
                        /**
                         * PassByEdit hour.
                         * @member {number} hour
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @instance
                         */
                        PassByEdit.prototype.hour = 0;
    
                        /**
                         * PassByEdit value.
                         * @member {number} value
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @instance
                         */
                        PassByEdit.prototype.value = 0;
    
                        /**
                         * Creates a new PassByEdit instance using the specified properties.
                         * @function create
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @static
                         * @param {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.IPassByEdit=} [properties] Properties to set
                         * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit} PassByEdit instance
                         */
                        PassByEdit.create = function create(properties) {
                            return new PassByEdit(properties);
                        };
    
                        /**
                         * Encodes the specified PassByEdit message. Does not implicitly {@link palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @static
                         * @param {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.IPassByEdit} message PassByEdit message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PassByEdit.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                            if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateId);
                            if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.hour);
                            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.value);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified PassByEdit message, length delimited. Does not implicitly {@link palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @static
                         * @param {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.IPassByEdit} message PassByEdit message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PassByEdit.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a PassByEdit message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit} PassByEdit
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PassByEdit.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.storeId = reader.int64();
                                    break;
                                case 2:
                                    message.dateId = reader.int32();
                                    break;
                                case 3:
                                    message.hour = reader.int32();
                                    break;
                                case 4:
                                    message.value = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a PassByEdit message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit} PassByEdit
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PassByEdit.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a PassByEdit message.
                         * @function verify
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PassByEdit.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                    return "storeId: integer|Long expected";
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                if (!$util.isInteger(message.dateId))
                                    return "dateId: integer expected";
                            if (message.hour != null && message.hasOwnProperty("hour"))
                                if (!$util.isInteger(message.hour))
                                    return "hour: integer expected";
                            if (message.value != null && message.hasOwnProperty("value"))
                                if (!$util.isInteger(message.value))
                                    return "value: integer expected";
                            return null;
                        };
    
                        /**
                         * Creates a PassByEdit message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit} PassByEdit
                         */
                        PassByEdit.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit)
                                return object;
                            var message = new $root.palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit();
                            if (object.storeId != null)
                                if ($util.Long)
                                    (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                                else if (typeof object.storeId === "string")
                                    message.storeId = parseInt(object.storeId, 10);
                                else if (typeof object.storeId === "number")
                                    message.storeId = object.storeId;
                                else if (typeof object.storeId === "object")
                                    message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                            if (object.dateId != null)
                                message.dateId = object.dateId | 0;
                            if (object.hour != null)
                                message.hour = object.hour | 0;
                            if (object.value != null)
                                message.value = object.value | 0;
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a PassByEdit message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @static
                         * @param {palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit} message PassByEdit
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PassByEdit.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.storeId = options.longs === String ? "0" : 0;
                                object.dateId = 0;
                                object.hour = 0;
                                object.value = 0;
                            }
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (typeof message.storeId === "number")
                                    object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                                else
                                    object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                object.dateId = message.dateId;
                            if (message.hour != null && message.hasOwnProperty("hour"))
                                object.hour = message.hour;
                            if (message.value != null && message.hasOwnProperty("value"))
                                object.value = message.value;
                            return object;
                        };
    
                        /**
                         * Converts this PassByEdit to JSON.
                         * @function toJSON
                         * @memberof palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PassByEdit.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return PassByEdit;
                    })();
    
                    return HakuhodoEditTrafficAdvanceInfo;
                })();
    
                v1.HakuhodoExtrapolateDemographyAdvanceInfo = (function() {
    
                    /**
                     * Properties of a HakuhodoExtrapolateDemographyAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IHakuhodoExtrapolateDemographyAdvanceInfo
                     * @property {number|null} [startDateIdInclusive] HakuhodoExtrapolateDemographyAdvanceInfo startDateIdInclusive
                     * @property {number|null} [endDateIdInclusive] HakuhodoExtrapolateDemographyAdvanceInfo endDateIdInclusive
                     * @property {number|null} [storeId] HakuhodoExtrapolateDemographyAdvanceInfo storeId
                     */
    
                    /**
                     * Constructs a new HakuhodoExtrapolateDemographyAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a HakuhodoExtrapolateDemographyAdvanceInfo.
                     * @implements IHakuhodoExtrapolateDemographyAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IHakuhodoExtrapolateDemographyAdvanceInfo=} [properties] Properties to set
                     */
                    function HakuhodoExtrapolateDemographyAdvanceInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * HakuhodoExtrapolateDemographyAdvanceInfo startDateIdInclusive.
                     * @member {number} startDateIdInclusive
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @instance
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.prototype.startDateIdInclusive = 0;
    
                    /**
                     * HakuhodoExtrapolateDemographyAdvanceInfo endDateIdInclusive.
                     * @member {number} endDateIdInclusive
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @instance
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.prototype.endDateIdInclusive = 0;
    
                    /**
                     * HakuhodoExtrapolateDemographyAdvanceInfo storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @instance
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new HakuhodoExtrapolateDemographyAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHakuhodoExtrapolateDemographyAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo} HakuhodoExtrapolateDemographyAdvanceInfo instance
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.create = function create(properties) {
                        return new HakuhodoExtrapolateDemographyAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified HakuhodoExtrapolateDemographyAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHakuhodoExtrapolateDemographyAdvanceInfo} message HakuhodoExtrapolateDemographyAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startDateIdInclusive != null && Object.hasOwnProperty.call(message, "startDateIdInclusive"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.startDateIdInclusive);
                        if (message.endDateIdInclusive != null && Object.hasOwnProperty.call(message, "endDateIdInclusive"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.endDateIdInclusive);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified HakuhodoExtrapolateDemographyAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IHakuhodoExtrapolateDemographyAdvanceInfo} message HakuhodoExtrapolateDemographyAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a HakuhodoExtrapolateDemographyAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo} HakuhodoExtrapolateDemographyAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startDateIdInclusive = reader.int32();
                                break;
                            case 2:
                                message.endDateIdInclusive = reader.int32();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a HakuhodoExtrapolateDemographyAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo} HakuhodoExtrapolateDemographyAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a HakuhodoExtrapolateDemographyAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.startDateIdInclusive != null && message.hasOwnProperty("startDateIdInclusive"))
                            if (!$util.isInteger(message.startDateIdInclusive))
                                return "startDateIdInclusive: integer expected";
                        if (message.endDateIdInclusive != null && message.hasOwnProperty("endDateIdInclusive"))
                            if (!$util.isInteger(message.endDateIdInclusive))
                                return "endDateIdInclusive: integer expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a HakuhodoExtrapolateDemographyAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo} HakuhodoExtrapolateDemographyAdvanceInfo
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo();
                        if (object.startDateIdInclusive != null)
                            message.startDateIdInclusive = object.startDateIdInclusive | 0;
                        if (object.endDateIdInclusive != null)
                            message.endDateIdInclusive = object.endDateIdInclusive | 0;
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a HakuhodoExtrapolateDemographyAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo} message HakuhodoExtrapolateDemographyAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startDateIdInclusive = 0;
                            object.endDateIdInclusive = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                        }
                        if (message.startDateIdInclusive != null && message.hasOwnProperty("startDateIdInclusive"))
                            object.startDateIdInclusive = message.startDateIdInclusive;
                        if (message.endDateIdInclusive != null && message.hasOwnProperty("endDateIdInclusive"))
                            object.endDateIdInclusive = message.endDateIdInclusive;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        return object;
                    };
    
                    /**
                     * Converts this HakuhodoExtrapolateDemographyAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    HakuhodoExtrapolateDemographyAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return HakuhodoExtrapolateDemographyAdvanceInfo;
                })();
    
                v1.ExportOsoolEventsAdvanceInfo = (function() {
    
                    /**
                     * Properties of an ExportOsoolEventsAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IExportOsoolEventsAdvanceInfo
                     * @property {number|null} [startDateIdInclusive] ExportOsoolEventsAdvanceInfo startDateIdInclusive
                     * @property {number|null} [endDateIdInclusive] ExportOsoolEventsAdvanceInfo endDateIdInclusive
                     */
    
                    /**
                     * Constructs a new ExportOsoolEventsAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an ExportOsoolEventsAdvanceInfo.
                     * @implements IExportOsoolEventsAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IExportOsoolEventsAdvanceInfo=} [properties] Properties to set
                     */
                    function ExportOsoolEventsAdvanceInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExportOsoolEventsAdvanceInfo startDateIdInclusive.
                     * @member {number} startDateIdInclusive
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo
                     * @instance
                     */
                    ExportOsoolEventsAdvanceInfo.prototype.startDateIdInclusive = 0;
    
                    /**
                     * ExportOsoolEventsAdvanceInfo endDateIdInclusive.
                     * @member {number} endDateIdInclusive
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo
                     * @instance
                     */
                    ExportOsoolEventsAdvanceInfo.prototype.endDateIdInclusive = 0;
    
                    /**
                     * Creates a new ExportOsoolEventsAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IExportOsoolEventsAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo} ExportOsoolEventsAdvanceInfo instance
                     */
                    ExportOsoolEventsAdvanceInfo.create = function create(properties) {
                        return new ExportOsoolEventsAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified ExportOsoolEventsAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IExportOsoolEventsAdvanceInfo} message ExportOsoolEventsAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportOsoolEventsAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startDateIdInclusive != null && Object.hasOwnProperty.call(message, "startDateIdInclusive"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.startDateIdInclusive);
                        if (message.endDateIdInclusive != null && Object.hasOwnProperty.call(message, "endDateIdInclusive"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.endDateIdInclusive);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportOsoolEventsAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IExportOsoolEventsAdvanceInfo} message ExportOsoolEventsAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportOsoolEventsAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportOsoolEventsAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo} ExportOsoolEventsAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportOsoolEventsAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startDateIdInclusive = reader.int32();
                                break;
                            case 2:
                                message.endDateIdInclusive = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportOsoolEventsAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo} ExportOsoolEventsAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportOsoolEventsAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportOsoolEventsAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportOsoolEventsAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.startDateIdInclusive != null && message.hasOwnProperty("startDateIdInclusive"))
                            if (!$util.isInteger(message.startDateIdInclusive))
                                return "startDateIdInclusive: integer expected";
                        if (message.endDateIdInclusive != null && message.hasOwnProperty("endDateIdInclusive"))
                            if (!$util.isInteger(message.endDateIdInclusive))
                                return "endDateIdInclusive: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExportOsoolEventsAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo} ExportOsoolEventsAdvanceInfo
                     */
                    ExportOsoolEventsAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo();
                        if (object.startDateIdInclusive != null)
                            message.startDateIdInclusive = object.startDateIdInclusive | 0;
                        if (object.endDateIdInclusive != null)
                            message.endDateIdInclusive = object.endDateIdInclusive | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExportOsoolEventsAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo} message ExportOsoolEventsAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportOsoolEventsAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startDateIdInclusive = 0;
                            object.endDateIdInclusive = 0;
                        }
                        if (message.startDateIdInclusive != null && message.hasOwnProperty("startDateIdInclusive"))
                            object.startDateIdInclusive = message.startDateIdInclusive;
                        if (message.endDateIdInclusive != null && message.hasOwnProperty("endDateIdInclusive"))
                            object.endDateIdInclusive = message.endDateIdInclusive;
                        return object;
                    };
    
                    /**
                     * Converts this ExportOsoolEventsAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportOsoolEventsAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExportOsoolEventsAdvanceInfo;
                })();
    
                v1.BuildSeedAdvanceInfo = (function() {
    
                    /**
                     * Properties of a BuildSeedAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IBuildSeedAdvanceInfo
                     * @property {number|null} [seedVersionId] BuildSeedAdvanceInfo seedVersionId
                     */
    
                    /**
                     * Constructs a new BuildSeedAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BuildSeedAdvanceInfo.
                     * @implements IBuildSeedAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IBuildSeedAdvanceInfo=} [properties] Properties to set
                     */
                    function BuildSeedAdvanceInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BuildSeedAdvanceInfo seedVersionId.
                     * @member {number} seedVersionId
                     * @memberof palexy.sherlock.v1.BuildSeedAdvanceInfo
                     * @instance
                     */
                    BuildSeedAdvanceInfo.prototype.seedVersionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new BuildSeedAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BuildSeedAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IBuildSeedAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BuildSeedAdvanceInfo} BuildSeedAdvanceInfo instance
                     */
                    BuildSeedAdvanceInfo.create = function create(properties) {
                        return new BuildSeedAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified BuildSeedAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.BuildSeedAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BuildSeedAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IBuildSeedAdvanceInfo} message BuildSeedAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BuildSeedAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.seedVersionId != null && Object.hasOwnProperty.call(message, "seedVersionId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seedVersionId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BuildSeedAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BuildSeedAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BuildSeedAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IBuildSeedAdvanceInfo} message BuildSeedAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BuildSeedAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BuildSeedAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BuildSeedAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BuildSeedAdvanceInfo} BuildSeedAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BuildSeedAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BuildSeedAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.seedVersionId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BuildSeedAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BuildSeedAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BuildSeedAdvanceInfo} BuildSeedAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BuildSeedAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BuildSeedAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BuildSeedAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BuildSeedAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.seedVersionId != null && message.hasOwnProperty("seedVersionId"))
                            if (!$util.isInteger(message.seedVersionId) && !(message.seedVersionId && $util.isInteger(message.seedVersionId.low) && $util.isInteger(message.seedVersionId.high)))
                                return "seedVersionId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a BuildSeedAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BuildSeedAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BuildSeedAdvanceInfo} BuildSeedAdvanceInfo
                     */
                    BuildSeedAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BuildSeedAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BuildSeedAdvanceInfo();
                        if (object.seedVersionId != null)
                            if ($util.Long)
                                (message.seedVersionId = $util.Long.fromValue(object.seedVersionId)).unsigned = false;
                            else if (typeof object.seedVersionId === "string")
                                message.seedVersionId = parseInt(object.seedVersionId, 10);
                            else if (typeof object.seedVersionId === "number")
                                message.seedVersionId = object.seedVersionId;
                            else if (typeof object.seedVersionId === "object")
                                message.seedVersionId = new $util.LongBits(object.seedVersionId.low >>> 0, object.seedVersionId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BuildSeedAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BuildSeedAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.BuildSeedAdvanceInfo} message BuildSeedAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BuildSeedAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.seedVersionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.seedVersionId = options.longs === String ? "0" : 0;
                        if (message.seedVersionId != null && message.hasOwnProperty("seedVersionId"))
                            if (typeof message.seedVersionId === "number")
                                object.seedVersionId = options.longs === String ? String(message.seedVersionId) : message.seedVersionId;
                            else
                                object.seedVersionId = options.longs === String ? $util.Long.prototype.toString.call(message.seedVersionId) : options.longs === Number ? new $util.LongBits(message.seedVersionId.low >>> 0, message.seedVersionId.high >>> 0).toNumber() : message.seedVersionId;
                        return object;
                    };
    
                    /**
                     * Converts this BuildSeedAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BuildSeedAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BuildSeedAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BuildSeedAdvanceInfo;
                })();
    
                v1.PredictedAndLabelItem = (function() {
    
                    /**
                     * Properties of a PredictedAndLabelItem.
                     * @memberof palexy.sherlock.v1
                     * @interface IPredictedAndLabelItem
                     * @property {string|null} [predictedBbLink] PredictedAndLabelItem predictedBbLink
                     * @property {string|null} [humanLabelBbLink] PredictedAndLabelItem humanLabelBbLink
                     */
    
                    /**
                     * Constructs a new PredictedAndLabelItem.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a PredictedAndLabelItem.
                     * @implements IPredictedAndLabelItem
                     * @constructor
                     * @param {palexy.sherlock.v1.IPredictedAndLabelItem=} [properties] Properties to set
                     */
                    function PredictedAndLabelItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PredictedAndLabelItem predictedBbLink.
                     * @member {string} predictedBbLink
                     * @memberof palexy.sherlock.v1.PredictedAndLabelItem
                     * @instance
                     */
                    PredictedAndLabelItem.prototype.predictedBbLink = "";
    
                    /**
                     * PredictedAndLabelItem humanLabelBbLink.
                     * @member {string} humanLabelBbLink
                     * @memberof palexy.sherlock.v1.PredictedAndLabelItem
                     * @instance
                     */
                    PredictedAndLabelItem.prototype.humanLabelBbLink = "";
    
                    /**
                     * Creates a new PredictedAndLabelItem instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.PredictedAndLabelItem
                     * @static
                     * @param {palexy.sherlock.v1.IPredictedAndLabelItem=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.PredictedAndLabelItem} PredictedAndLabelItem instance
                     */
                    PredictedAndLabelItem.create = function create(properties) {
                        return new PredictedAndLabelItem(properties);
                    };
    
                    /**
                     * Encodes the specified PredictedAndLabelItem message. Does not implicitly {@link palexy.sherlock.v1.PredictedAndLabelItem.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.PredictedAndLabelItem
                     * @static
                     * @param {palexy.sherlock.v1.IPredictedAndLabelItem} message PredictedAndLabelItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PredictedAndLabelItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.predictedBbLink != null && Object.hasOwnProperty.call(message, "predictedBbLink"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.predictedBbLink);
                        if (message.humanLabelBbLink != null && Object.hasOwnProperty.call(message, "humanLabelBbLink"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.humanLabelBbLink);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PredictedAndLabelItem message, length delimited. Does not implicitly {@link palexy.sherlock.v1.PredictedAndLabelItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.PredictedAndLabelItem
                     * @static
                     * @param {palexy.sherlock.v1.IPredictedAndLabelItem} message PredictedAndLabelItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PredictedAndLabelItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PredictedAndLabelItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.PredictedAndLabelItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.PredictedAndLabelItem} PredictedAndLabelItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PredictedAndLabelItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.PredictedAndLabelItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.predictedBbLink = reader.string();
                                break;
                            case 2:
                                message.humanLabelBbLink = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PredictedAndLabelItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.PredictedAndLabelItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.PredictedAndLabelItem} PredictedAndLabelItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PredictedAndLabelItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PredictedAndLabelItem message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.PredictedAndLabelItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PredictedAndLabelItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.predictedBbLink != null && message.hasOwnProperty("predictedBbLink"))
                            if (!$util.isString(message.predictedBbLink))
                                return "predictedBbLink: string expected";
                        if (message.humanLabelBbLink != null && message.hasOwnProperty("humanLabelBbLink"))
                            if (!$util.isString(message.humanLabelBbLink))
                                return "humanLabelBbLink: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a PredictedAndLabelItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.PredictedAndLabelItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.PredictedAndLabelItem} PredictedAndLabelItem
                     */
                    PredictedAndLabelItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.PredictedAndLabelItem)
                            return object;
                        var message = new $root.palexy.sherlock.v1.PredictedAndLabelItem();
                        if (object.predictedBbLink != null)
                            message.predictedBbLink = String(object.predictedBbLink);
                        if (object.humanLabelBbLink != null)
                            message.humanLabelBbLink = String(object.humanLabelBbLink);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PredictedAndLabelItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.PredictedAndLabelItem
                     * @static
                     * @param {palexy.sherlock.v1.PredictedAndLabelItem} message PredictedAndLabelItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PredictedAndLabelItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.predictedBbLink = "";
                            object.humanLabelBbLink = "";
                        }
                        if (message.predictedBbLink != null && message.hasOwnProperty("predictedBbLink"))
                            object.predictedBbLink = message.predictedBbLink;
                        if (message.humanLabelBbLink != null && message.hasOwnProperty("humanLabelBbLink"))
                            object.humanLabelBbLink = message.humanLabelBbLink;
                        return object;
                    };
    
                    /**
                     * Converts this PredictedAndLabelItem to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.PredictedAndLabelItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PredictedAndLabelItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PredictedAndLabelItem;
                })();
    
                v1.SherlockTaskResult = (function() {
    
                    /**
                     * Properties of a SherlockTaskResult.
                     * @memberof palexy.sherlock.v1
                     * @interface ISherlockTaskResult
                     * @property {number|null} [id] SherlockTaskResult id
                     * @property {google.protobuf.ITimestamp|null} [createTime] SherlockTaskResult createTime
                     * @property {number|null} [taskId] SherlockTaskResult taskId
                     * @property {palexy.sherlock.v1.ICoreSherlockTaskResult|null} [coreTaskResult] SherlockTaskResult coreTaskResult
                     */
    
                    /**
                     * Constructs a new SherlockTaskResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a SherlockTaskResult.
                     * @implements ISherlockTaskResult
                     * @constructor
                     * @param {palexy.sherlock.v1.ISherlockTaskResult=} [properties] Properties to set
                     */
                    function SherlockTaskResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SherlockTaskResult id.
                     * @member {number} id
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @instance
                     */
                    SherlockTaskResult.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * SherlockTaskResult createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @instance
                     */
                    SherlockTaskResult.prototype.createTime = null;
    
                    /**
                     * SherlockTaskResult taskId.
                     * @member {number} taskId
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @instance
                     */
                    SherlockTaskResult.prototype.taskId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * SherlockTaskResult coreTaskResult.
                     * @member {palexy.sherlock.v1.ICoreSherlockTaskResult|null|undefined} coreTaskResult
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @instance
                     */
                    SherlockTaskResult.prototype.coreTaskResult = null;
    
                    /**
                     * Creates a new SherlockTaskResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @static
                     * @param {palexy.sherlock.v1.ISherlockTaskResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.SherlockTaskResult} SherlockTaskResult instance
                     */
                    SherlockTaskResult.create = function create(properties) {
                        return new SherlockTaskResult(properties);
                    };
    
                    /**
                     * Encodes the specified SherlockTaskResult message. Does not implicitly {@link palexy.sherlock.v1.SherlockTaskResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @static
                     * @param {palexy.sherlock.v1.ISherlockTaskResult} message SherlockTaskResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SherlockTaskResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.taskId);
                        if (message.coreTaskResult != null && Object.hasOwnProperty.call(message, "coreTaskResult"))
                            $root.palexy.sherlock.v1.CoreSherlockTaskResult.encode(message.coreTaskResult, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SherlockTaskResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.SherlockTaskResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @static
                     * @param {palexy.sherlock.v1.ISherlockTaskResult} message SherlockTaskResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SherlockTaskResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SherlockTaskResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.SherlockTaskResult} SherlockTaskResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SherlockTaskResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.SherlockTaskResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.taskId = reader.int64();
                                break;
                            case 4:
                                message.coreTaskResult = $root.palexy.sherlock.v1.CoreSherlockTaskResult.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SherlockTaskResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.SherlockTaskResult} SherlockTaskResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SherlockTaskResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SherlockTaskResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SherlockTaskResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            if (!$util.isInteger(message.taskId) && !(message.taskId && $util.isInteger(message.taskId.low) && $util.isInteger(message.taskId.high)))
                                return "taskId: integer|Long expected";
                        if (message.coreTaskResult != null && message.hasOwnProperty("coreTaskResult")) {
                            var error = $root.palexy.sherlock.v1.CoreSherlockTaskResult.verify(message.coreTaskResult);
                            if (error)
                                return "coreTaskResult." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a SherlockTaskResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.SherlockTaskResult} SherlockTaskResult
                     */
                    SherlockTaskResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.SherlockTaskResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.SherlockTaskResult();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.sherlock.v1.SherlockTaskResult.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.taskId != null)
                            if ($util.Long)
                                (message.taskId = $util.Long.fromValue(object.taskId)).unsigned = false;
                            else if (typeof object.taskId === "string")
                                message.taskId = parseInt(object.taskId, 10);
                            else if (typeof object.taskId === "number")
                                message.taskId = object.taskId;
                            else if (typeof object.taskId === "object")
                                message.taskId = new $util.LongBits(object.taskId.low >>> 0, object.taskId.high >>> 0).toNumber();
                        if (object.coreTaskResult != null) {
                            if (typeof object.coreTaskResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.SherlockTaskResult.coreTaskResult: object expected");
                            message.coreTaskResult = $root.palexy.sherlock.v1.CoreSherlockTaskResult.fromObject(object.coreTaskResult);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SherlockTaskResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @static
                     * @param {palexy.sherlock.v1.SherlockTaskResult} message SherlockTaskResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SherlockTaskResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.createTime = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.taskId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.taskId = options.longs === String ? "0" : 0;
                            object.coreTaskResult = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            if (typeof message.taskId === "number")
                                object.taskId = options.longs === String ? String(message.taskId) : message.taskId;
                            else
                                object.taskId = options.longs === String ? $util.Long.prototype.toString.call(message.taskId) : options.longs === Number ? new $util.LongBits(message.taskId.low >>> 0, message.taskId.high >>> 0).toNumber() : message.taskId;
                        if (message.coreTaskResult != null && message.hasOwnProperty("coreTaskResult"))
                            object.coreTaskResult = $root.palexy.sherlock.v1.CoreSherlockTaskResult.toObject(message.coreTaskResult, options);
                        return object;
                    };
    
                    /**
                     * Converts this SherlockTaskResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.SherlockTaskResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SherlockTaskResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SherlockTaskResult;
                })();
    
                v1.CoreSherlockTaskResult = (function() {
    
                    /**
                     * Properties of a CoreSherlockTaskResult.
                     * @memberof palexy.sherlock.v1
                     * @interface ICoreSherlockTaskResult
                     * @property {palexy.sherlock.v1.IDebugPersonResult|null} [debugPersonResult] CoreSherlockTaskResult debugPersonResult
                     * @property {palexy.sherlock.v1.IMergeAccuracyResult|null} [mergeAccuracyResult] CoreSherlockTaskResult mergeAccuracyResult
                     * @property {palexy.sherlock.v1.IPostMergeResult|null} [postMergeResult] CoreSherlockTaskResult postMergeResult
                     * @property {palexy.sherlock.v1.IScanMergeConfigResult|null} [scanMergeConfigResult] CoreSherlockTaskResult scanMergeConfigResult
                     * @property {palexy.sherlock.v1.IRerunMergeConfigResult|null} [rerunMergeConfigResult] CoreSherlockTaskResult rerunMergeConfigResult
                     * @property {palexy.sherlock.v1.IDebugExperimentResult|null} [debugExperimentResult] CoreSherlockTaskResult debugExperimentResult
                     * @property {palexy.sherlock.v1.IPrepareQcVideosResult|null} [prepareQcVideosResult] CoreSherlockTaskResult prepareQcVideosResult
                     * @property {palexy.sherlock.v1.IExportHakuhodoResult|null} [exportHakuhodoResult] CoreSherlockTaskResult exportHakuhodoResult
                     * @property {palexy.sherlock.v1.IExportOsoolEventsResult|null} [exportOsoolEventsResult] CoreSherlockTaskResult exportOsoolEventsResult
                     */
    
                    /**
                     * Constructs a new CoreSherlockTaskResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CoreSherlockTaskResult.
                     * @implements ICoreSherlockTaskResult
                     * @constructor
                     * @param {palexy.sherlock.v1.ICoreSherlockTaskResult=} [properties] Properties to set
                     */
                    function CoreSherlockTaskResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CoreSherlockTaskResult debugPersonResult.
                     * @member {palexy.sherlock.v1.IDebugPersonResult|null|undefined} debugPersonResult
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @instance
                     */
                    CoreSherlockTaskResult.prototype.debugPersonResult = null;
    
                    /**
                     * CoreSherlockTaskResult mergeAccuracyResult.
                     * @member {palexy.sherlock.v1.IMergeAccuracyResult|null|undefined} mergeAccuracyResult
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @instance
                     */
                    CoreSherlockTaskResult.prototype.mergeAccuracyResult = null;
    
                    /**
                     * CoreSherlockTaskResult postMergeResult.
                     * @member {palexy.sherlock.v1.IPostMergeResult|null|undefined} postMergeResult
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @instance
                     */
                    CoreSherlockTaskResult.prototype.postMergeResult = null;
    
                    /**
                     * CoreSherlockTaskResult scanMergeConfigResult.
                     * @member {palexy.sherlock.v1.IScanMergeConfigResult|null|undefined} scanMergeConfigResult
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @instance
                     */
                    CoreSherlockTaskResult.prototype.scanMergeConfigResult = null;
    
                    /**
                     * CoreSherlockTaskResult rerunMergeConfigResult.
                     * @member {palexy.sherlock.v1.IRerunMergeConfigResult|null|undefined} rerunMergeConfigResult
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @instance
                     */
                    CoreSherlockTaskResult.prototype.rerunMergeConfigResult = null;
    
                    /**
                     * CoreSherlockTaskResult debugExperimentResult.
                     * @member {palexy.sherlock.v1.IDebugExperimentResult|null|undefined} debugExperimentResult
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @instance
                     */
                    CoreSherlockTaskResult.prototype.debugExperimentResult = null;
    
                    /**
                     * CoreSherlockTaskResult prepareQcVideosResult.
                     * @member {palexy.sherlock.v1.IPrepareQcVideosResult|null|undefined} prepareQcVideosResult
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @instance
                     */
                    CoreSherlockTaskResult.prototype.prepareQcVideosResult = null;
    
                    /**
                     * CoreSherlockTaskResult exportHakuhodoResult.
                     * @member {palexy.sherlock.v1.IExportHakuhodoResult|null|undefined} exportHakuhodoResult
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @instance
                     */
                    CoreSherlockTaskResult.prototype.exportHakuhodoResult = null;
    
                    /**
                     * CoreSherlockTaskResult exportOsoolEventsResult.
                     * @member {palexy.sherlock.v1.IExportOsoolEventsResult|null|undefined} exportOsoolEventsResult
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @instance
                     */
                    CoreSherlockTaskResult.prototype.exportOsoolEventsResult = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * CoreSherlockTaskResult result.
                     * @member {"debugPersonResult"|"mergeAccuracyResult"|"postMergeResult"|"scanMergeConfigResult"|"rerunMergeConfigResult"|"debugExperimentResult"|"prepareQcVideosResult"|"exportHakuhodoResult"|"exportOsoolEventsResult"|undefined} result
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @instance
                     */
                    Object.defineProperty(CoreSherlockTaskResult.prototype, "result", {
                        get: $util.oneOfGetter($oneOfFields = ["debugPersonResult", "mergeAccuracyResult", "postMergeResult", "scanMergeConfigResult", "rerunMergeConfigResult", "debugExperimentResult", "prepareQcVideosResult", "exportHakuhodoResult", "exportOsoolEventsResult"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new CoreSherlockTaskResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @static
                     * @param {palexy.sherlock.v1.ICoreSherlockTaskResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CoreSherlockTaskResult} CoreSherlockTaskResult instance
                     */
                    CoreSherlockTaskResult.create = function create(properties) {
                        return new CoreSherlockTaskResult(properties);
                    };
    
                    /**
                     * Encodes the specified CoreSherlockTaskResult message. Does not implicitly {@link palexy.sherlock.v1.CoreSherlockTaskResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @static
                     * @param {palexy.sherlock.v1.ICoreSherlockTaskResult} message CoreSherlockTaskResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CoreSherlockTaskResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.debugPersonResult != null && Object.hasOwnProperty.call(message, "debugPersonResult"))
                            $root.palexy.sherlock.v1.DebugPersonResult.encode(message.debugPersonResult, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.mergeAccuracyResult != null && Object.hasOwnProperty.call(message, "mergeAccuracyResult"))
                            $root.palexy.sherlock.v1.MergeAccuracyResult.encode(message.mergeAccuracyResult, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.postMergeResult != null && Object.hasOwnProperty.call(message, "postMergeResult"))
                            $root.palexy.sherlock.v1.PostMergeResult.encode(message.postMergeResult, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.scanMergeConfigResult != null && Object.hasOwnProperty.call(message, "scanMergeConfigResult"))
                            $root.palexy.sherlock.v1.ScanMergeConfigResult.encode(message.scanMergeConfigResult, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.rerunMergeConfigResult != null && Object.hasOwnProperty.call(message, "rerunMergeConfigResult"))
                            $root.palexy.sherlock.v1.RerunMergeConfigResult.encode(message.rerunMergeConfigResult, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.debugExperimentResult != null && Object.hasOwnProperty.call(message, "debugExperimentResult"))
                            $root.palexy.sherlock.v1.DebugExperimentResult.encode(message.debugExperimentResult, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.prepareQcVideosResult != null && Object.hasOwnProperty.call(message, "prepareQcVideosResult"))
                            $root.palexy.sherlock.v1.PrepareQcVideosResult.encode(message.prepareQcVideosResult, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.exportHakuhodoResult != null && Object.hasOwnProperty.call(message, "exportHakuhodoResult"))
                            $root.palexy.sherlock.v1.ExportHakuhodoResult.encode(message.exportHakuhodoResult, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.exportOsoolEventsResult != null && Object.hasOwnProperty.call(message, "exportOsoolEventsResult"))
                            $root.palexy.sherlock.v1.ExportOsoolEventsResult.encode(message.exportOsoolEventsResult, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CoreSherlockTaskResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CoreSherlockTaskResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @static
                     * @param {palexy.sherlock.v1.ICoreSherlockTaskResult} message CoreSherlockTaskResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CoreSherlockTaskResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CoreSherlockTaskResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CoreSherlockTaskResult} CoreSherlockTaskResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CoreSherlockTaskResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CoreSherlockTaskResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.debugPersonResult = $root.palexy.sherlock.v1.DebugPersonResult.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.mergeAccuracyResult = $root.palexy.sherlock.v1.MergeAccuracyResult.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.postMergeResult = $root.palexy.sherlock.v1.PostMergeResult.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.scanMergeConfigResult = $root.palexy.sherlock.v1.ScanMergeConfigResult.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.rerunMergeConfigResult = $root.palexy.sherlock.v1.RerunMergeConfigResult.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.debugExperimentResult = $root.palexy.sherlock.v1.DebugExperimentResult.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.prepareQcVideosResult = $root.palexy.sherlock.v1.PrepareQcVideosResult.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.exportHakuhodoResult = $root.palexy.sherlock.v1.ExportHakuhodoResult.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.exportOsoolEventsResult = $root.palexy.sherlock.v1.ExportOsoolEventsResult.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CoreSherlockTaskResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CoreSherlockTaskResult} CoreSherlockTaskResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CoreSherlockTaskResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CoreSherlockTaskResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CoreSherlockTaskResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.debugPersonResult != null && message.hasOwnProperty("debugPersonResult")) {
                            properties.result = 1;
                            {
                                var error = $root.palexy.sherlock.v1.DebugPersonResult.verify(message.debugPersonResult);
                                if (error)
                                    return "debugPersonResult." + error;
                            }
                        }
                        if (message.mergeAccuracyResult != null && message.hasOwnProperty("mergeAccuracyResult")) {
                            if (properties.result === 1)
                                return "result: multiple values";
                            properties.result = 1;
                            {
                                var error = $root.palexy.sherlock.v1.MergeAccuracyResult.verify(message.mergeAccuracyResult);
                                if (error)
                                    return "mergeAccuracyResult." + error;
                            }
                        }
                        if (message.postMergeResult != null && message.hasOwnProperty("postMergeResult")) {
                            if (properties.result === 1)
                                return "result: multiple values";
                            properties.result = 1;
                            {
                                var error = $root.palexy.sherlock.v1.PostMergeResult.verify(message.postMergeResult);
                                if (error)
                                    return "postMergeResult." + error;
                            }
                        }
                        if (message.scanMergeConfigResult != null && message.hasOwnProperty("scanMergeConfigResult")) {
                            if (properties.result === 1)
                                return "result: multiple values";
                            properties.result = 1;
                            {
                                var error = $root.palexy.sherlock.v1.ScanMergeConfigResult.verify(message.scanMergeConfigResult);
                                if (error)
                                    return "scanMergeConfigResult." + error;
                            }
                        }
                        if (message.rerunMergeConfigResult != null && message.hasOwnProperty("rerunMergeConfigResult")) {
                            if (properties.result === 1)
                                return "result: multiple values";
                            properties.result = 1;
                            {
                                var error = $root.palexy.sherlock.v1.RerunMergeConfigResult.verify(message.rerunMergeConfigResult);
                                if (error)
                                    return "rerunMergeConfigResult." + error;
                            }
                        }
                        if (message.debugExperimentResult != null && message.hasOwnProperty("debugExperimentResult")) {
                            if (properties.result === 1)
                                return "result: multiple values";
                            properties.result = 1;
                            {
                                var error = $root.palexy.sherlock.v1.DebugExperimentResult.verify(message.debugExperimentResult);
                                if (error)
                                    return "debugExperimentResult." + error;
                            }
                        }
                        if (message.prepareQcVideosResult != null && message.hasOwnProperty("prepareQcVideosResult")) {
                            if (properties.result === 1)
                                return "result: multiple values";
                            properties.result = 1;
                            {
                                var error = $root.palexy.sherlock.v1.PrepareQcVideosResult.verify(message.prepareQcVideosResult);
                                if (error)
                                    return "prepareQcVideosResult." + error;
                            }
                        }
                        if (message.exportHakuhodoResult != null && message.hasOwnProperty("exportHakuhodoResult")) {
                            if (properties.result === 1)
                                return "result: multiple values";
                            properties.result = 1;
                            {
                                var error = $root.palexy.sherlock.v1.ExportHakuhodoResult.verify(message.exportHakuhodoResult);
                                if (error)
                                    return "exportHakuhodoResult." + error;
                            }
                        }
                        if (message.exportOsoolEventsResult != null && message.hasOwnProperty("exportOsoolEventsResult")) {
                            if (properties.result === 1)
                                return "result: multiple values";
                            properties.result = 1;
                            {
                                var error = $root.palexy.sherlock.v1.ExportOsoolEventsResult.verify(message.exportOsoolEventsResult);
                                if (error)
                                    return "exportOsoolEventsResult." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CoreSherlockTaskResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CoreSherlockTaskResult} CoreSherlockTaskResult
                     */
                    CoreSherlockTaskResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CoreSherlockTaskResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CoreSherlockTaskResult();
                        if (object.debugPersonResult != null) {
                            if (typeof object.debugPersonResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.CoreSherlockTaskResult.debugPersonResult: object expected");
                            message.debugPersonResult = $root.palexy.sherlock.v1.DebugPersonResult.fromObject(object.debugPersonResult);
                        }
                        if (object.mergeAccuracyResult != null) {
                            if (typeof object.mergeAccuracyResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.CoreSherlockTaskResult.mergeAccuracyResult: object expected");
                            message.mergeAccuracyResult = $root.palexy.sherlock.v1.MergeAccuracyResult.fromObject(object.mergeAccuracyResult);
                        }
                        if (object.postMergeResult != null) {
                            if (typeof object.postMergeResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.CoreSherlockTaskResult.postMergeResult: object expected");
                            message.postMergeResult = $root.palexy.sherlock.v1.PostMergeResult.fromObject(object.postMergeResult);
                        }
                        if (object.scanMergeConfigResult != null) {
                            if (typeof object.scanMergeConfigResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.CoreSherlockTaskResult.scanMergeConfigResult: object expected");
                            message.scanMergeConfigResult = $root.palexy.sherlock.v1.ScanMergeConfigResult.fromObject(object.scanMergeConfigResult);
                        }
                        if (object.rerunMergeConfigResult != null) {
                            if (typeof object.rerunMergeConfigResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.CoreSherlockTaskResult.rerunMergeConfigResult: object expected");
                            message.rerunMergeConfigResult = $root.palexy.sherlock.v1.RerunMergeConfigResult.fromObject(object.rerunMergeConfigResult);
                        }
                        if (object.debugExperimentResult != null) {
                            if (typeof object.debugExperimentResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.CoreSherlockTaskResult.debugExperimentResult: object expected");
                            message.debugExperimentResult = $root.palexy.sherlock.v1.DebugExperimentResult.fromObject(object.debugExperimentResult);
                        }
                        if (object.prepareQcVideosResult != null) {
                            if (typeof object.prepareQcVideosResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.CoreSherlockTaskResult.prepareQcVideosResult: object expected");
                            message.prepareQcVideosResult = $root.palexy.sherlock.v1.PrepareQcVideosResult.fromObject(object.prepareQcVideosResult);
                        }
                        if (object.exportHakuhodoResult != null) {
                            if (typeof object.exportHakuhodoResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.CoreSherlockTaskResult.exportHakuhodoResult: object expected");
                            message.exportHakuhodoResult = $root.palexy.sherlock.v1.ExportHakuhodoResult.fromObject(object.exportHakuhodoResult);
                        }
                        if (object.exportOsoolEventsResult != null) {
                            if (typeof object.exportOsoolEventsResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.CoreSherlockTaskResult.exportOsoolEventsResult: object expected");
                            message.exportOsoolEventsResult = $root.palexy.sherlock.v1.ExportOsoolEventsResult.fromObject(object.exportOsoolEventsResult);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CoreSherlockTaskResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @static
                     * @param {palexy.sherlock.v1.CoreSherlockTaskResult} message CoreSherlockTaskResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CoreSherlockTaskResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.debugPersonResult != null && message.hasOwnProperty("debugPersonResult")) {
                            object.debugPersonResult = $root.palexy.sherlock.v1.DebugPersonResult.toObject(message.debugPersonResult, options);
                            if (options.oneofs)
                                object.result = "debugPersonResult";
                        }
                        if (message.mergeAccuracyResult != null && message.hasOwnProperty("mergeAccuracyResult")) {
                            object.mergeAccuracyResult = $root.palexy.sherlock.v1.MergeAccuracyResult.toObject(message.mergeAccuracyResult, options);
                            if (options.oneofs)
                                object.result = "mergeAccuracyResult";
                        }
                        if (message.postMergeResult != null && message.hasOwnProperty("postMergeResult")) {
                            object.postMergeResult = $root.palexy.sherlock.v1.PostMergeResult.toObject(message.postMergeResult, options);
                            if (options.oneofs)
                                object.result = "postMergeResult";
                        }
                        if (message.scanMergeConfigResult != null && message.hasOwnProperty("scanMergeConfigResult")) {
                            object.scanMergeConfigResult = $root.palexy.sherlock.v1.ScanMergeConfigResult.toObject(message.scanMergeConfigResult, options);
                            if (options.oneofs)
                                object.result = "scanMergeConfigResult";
                        }
                        if (message.rerunMergeConfigResult != null && message.hasOwnProperty("rerunMergeConfigResult")) {
                            object.rerunMergeConfigResult = $root.palexy.sherlock.v1.RerunMergeConfigResult.toObject(message.rerunMergeConfigResult, options);
                            if (options.oneofs)
                                object.result = "rerunMergeConfigResult";
                        }
                        if (message.debugExperimentResult != null && message.hasOwnProperty("debugExperimentResult")) {
                            object.debugExperimentResult = $root.palexy.sherlock.v1.DebugExperimentResult.toObject(message.debugExperimentResult, options);
                            if (options.oneofs)
                                object.result = "debugExperimentResult";
                        }
                        if (message.prepareQcVideosResult != null && message.hasOwnProperty("prepareQcVideosResult")) {
                            object.prepareQcVideosResult = $root.palexy.sherlock.v1.PrepareQcVideosResult.toObject(message.prepareQcVideosResult, options);
                            if (options.oneofs)
                                object.result = "prepareQcVideosResult";
                        }
                        if (message.exportHakuhodoResult != null && message.hasOwnProperty("exportHakuhodoResult")) {
                            object.exportHakuhodoResult = $root.palexy.sherlock.v1.ExportHakuhodoResult.toObject(message.exportHakuhodoResult, options);
                            if (options.oneofs)
                                object.result = "exportHakuhodoResult";
                        }
                        if (message.exportOsoolEventsResult != null && message.hasOwnProperty("exportOsoolEventsResult")) {
                            object.exportOsoolEventsResult = $root.palexy.sherlock.v1.ExportOsoolEventsResult.toObject(message.exportOsoolEventsResult, options);
                            if (options.oneofs)
                                object.result = "exportOsoolEventsResult";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CoreSherlockTaskResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CoreSherlockTaskResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CoreSherlockTaskResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CoreSherlockTaskResult;
                })();
    
                v1.PostMergeResult = (function() {
    
                    /**
                     * Properties of a PostMergeResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IPostMergeResult
                     * @property {palexy.sherlock.v1.IDebugPersonResult|null} [debugPersonResult] PostMergeResult debugPersonResult
                     */
    
                    /**
                     * Constructs a new PostMergeResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a PostMergeResult.
                     * @implements IPostMergeResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IPostMergeResult=} [properties] Properties to set
                     */
                    function PostMergeResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PostMergeResult debugPersonResult.
                     * @member {palexy.sherlock.v1.IDebugPersonResult|null|undefined} debugPersonResult
                     * @memberof palexy.sherlock.v1.PostMergeResult
                     * @instance
                     */
                    PostMergeResult.prototype.debugPersonResult = null;
    
                    /**
                     * Creates a new PostMergeResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.PostMergeResult
                     * @static
                     * @param {palexy.sherlock.v1.IPostMergeResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.PostMergeResult} PostMergeResult instance
                     */
                    PostMergeResult.create = function create(properties) {
                        return new PostMergeResult(properties);
                    };
    
                    /**
                     * Encodes the specified PostMergeResult message. Does not implicitly {@link palexy.sherlock.v1.PostMergeResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.PostMergeResult
                     * @static
                     * @param {palexy.sherlock.v1.IPostMergeResult} message PostMergeResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PostMergeResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.debugPersonResult != null && Object.hasOwnProperty.call(message, "debugPersonResult"))
                            $root.palexy.sherlock.v1.DebugPersonResult.encode(message.debugPersonResult, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PostMergeResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.PostMergeResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.PostMergeResult
                     * @static
                     * @param {palexy.sherlock.v1.IPostMergeResult} message PostMergeResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PostMergeResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PostMergeResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.PostMergeResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.PostMergeResult} PostMergeResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PostMergeResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.PostMergeResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.debugPersonResult = $root.palexy.sherlock.v1.DebugPersonResult.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PostMergeResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.PostMergeResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.PostMergeResult} PostMergeResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PostMergeResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PostMergeResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.PostMergeResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PostMergeResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.debugPersonResult != null && message.hasOwnProperty("debugPersonResult")) {
                            var error = $root.palexy.sherlock.v1.DebugPersonResult.verify(message.debugPersonResult);
                            if (error)
                                return "debugPersonResult." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PostMergeResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.PostMergeResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.PostMergeResult} PostMergeResult
                     */
                    PostMergeResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.PostMergeResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.PostMergeResult();
                        if (object.debugPersonResult != null) {
                            if (typeof object.debugPersonResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.PostMergeResult.debugPersonResult: object expected");
                            message.debugPersonResult = $root.palexy.sherlock.v1.DebugPersonResult.fromObject(object.debugPersonResult);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PostMergeResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.PostMergeResult
                     * @static
                     * @param {palexy.sherlock.v1.PostMergeResult} message PostMergeResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PostMergeResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.debugPersonResult = null;
                        if (message.debugPersonResult != null && message.hasOwnProperty("debugPersonResult"))
                            object.debugPersonResult = $root.palexy.sherlock.v1.DebugPersonResult.toObject(message.debugPersonResult, options);
                        return object;
                    };
    
                    /**
                     * Converts this PostMergeResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.PostMergeResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PostMergeResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PostMergeResult;
                })();
    
                v1.DebugPersonResult = (function() {
    
                    /**
                     * Properties of a DebugPersonResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IDebugPersonResult
                     * @property {palexy.sherlock.v1.IGeneralPersonInfoResult|null} [generalPersonInfoResult] DebugPersonResult generalPersonInfoResult
                     * @property {palexy.sherlock.v1.IExtractBBResult|null} [extractBbResult] DebugPersonResult extractBbResult
                     */
    
                    /**
                     * Constructs a new DebugPersonResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DebugPersonResult.
                     * @implements IDebugPersonResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IDebugPersonResult=} [properties] Properties to set
                     */
                    function DebugPersonResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DebugPersonResult generalPersonInfoResult.
                     * @member {palexy.sherlock.v1.IGeneralPersonInfoResult|null|undefined} generalPersonInfoResult
                     * @memberof palexy.sherlock.v1.DebugPersonResult
                     * @instance
                     */
                    DebugPersonResult.prototype.generalPersonInfoResult = null;
    
                    /**
                     * DebugPersonResult extractBbResult.
                     * @member {palexy.sherlock.v1.IExtractBBResult|null|undefined} extractBbResult
                     * @memberof palexy.sherlock.v1.DebugPersonResult
                     * @instance
                     */
                    DebugPersonResult.prototype.extractBbResult = null;
    
                    /**
                     * Creates a new DebugPersonResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DebugPersonResult
                     * @static
                     * @param {palexy.sherlock.v1.IDebugPersonResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DebugPersonResult} DebugPersonResult instance
                     */
                    DebugPersonResult.create = function create(properties) {
                        return new DebugPersonResult(properties);
                    };
    
                    /**
                     * Encodes the specified DebugPersonResult message. Does not implicitly {@link palexy.sherlock.v1.DebugPersonResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DebugPersonResult
                     * @static
                     * @param {palexy.sherlock.v1.IDebugPersonResult} message DebugPersonResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DebugPersonResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.generalPersonInfoResult != null && Object.hasOwnProperty.call(message, "generalPersonInfoResult"))
                            $root.palexy.sherlock.v1.GeneralPersonInfoResult.encode(message.generalPersonInfoResult, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.extractBbResult != null && Object.hasOwnProperty.call(message, "extractBbResult"))
                            $root.palexy.sherlock.v1.ExtractBBResult.encode(message.extractBbResult, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DebugPersonResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DebugPersonResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DebugPersonResult
                     * @static
                     * @param {palexy.sherlock.v1.IDebugPersonResult} message DebugPersonResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DebugPersonResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DebugPersonResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DebugPersonResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DebugPersonResult} DebugPersonResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DebugPersonResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DebugPersonResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.generalPersonInfoResult = $root.palexy.sherlock.v1.GeneralPersonInfoResult.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.extractBbResult = $root.palexy.sherlock.v1.ExtractBBResult.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DebugPersonResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DebugPersonResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DebugPersonResult} DebugPersonResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DebugPersonResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DebugPersonResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DebugPersonResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DebugPersonResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.generalPersonInfoResult != null && message.hasOwnProperty("generalPersonInfoResult")) {
                            var error = $root.palexy.sherlock.v1.GeneralPersonInfoResult.verify(message.generalPersonInfoResult);
                            if (error)
                                return "generalPersonInfoResult." + error;
                        }
                        if (message.extractBbResult != null && message.hasOwnProperty("extractBbResult")) {
                            var error = $root.palexy.sherlock.v1.ExtractBBResult.verify(message.extractBbResult);
                            if (error)
                                return "extractBbResult." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DebugPersonResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DebugPersonResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DebugPersonResult} DebugPersonResult
                     */
                    DebugPersonResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DebugPersonResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.DebugPersonResult();
                        if (object.generalPersonInfoResult != null) {
                            if (typeof object.generalPersonInfoResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.DebugPersonResult.generalPersonInfoResult: object expected");
                            message.generalPersonInfoResult = $root.palexy.sherlock.v1.GeneralPersonInfoResult.fromObject(object.generalPersonInfoResult);
                        }
                        if (object.extractBbResult != null) {
                            if (typeof object.extractBbResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.DebugPersonResult.extractBbResult: object expected");
                            message.extractBbResult = $root.palexy.sherlock.v1.ExtractBBResult.fromObject(object.extractBbResult);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DebugPersonResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DebugPersonResult
                     * @static
                     * @param {palexy.sherlock.v1.DebugPersonResult} message DebugPersonResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DebugPersonResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.generalPersonInfoResult = null;
                            object.extractBbResult = null;
                        }
                        if (message.generalPersonInfoResult != null && message.hasOwnProperty("generalPersonInfoResult"))
                            object.generalPersonInfoResult = $root.palexy.sherlock.v1.GeneralPersonInfoResult.toObject(message.generalPersonInfoResult, options);
                        if (message.extractBbResult != null && message.hasOwnProperty("extractBbResult"))
                            object.extractBbResult = $root.palexy.sherlock.v1.ExtractBBResult.toObject(message.extractBbResult, options);
                        return object;
                    };
    
                    /**
                     * Converts this DebugPersonResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DebugPersonResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DebugPersonResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DebugPersonResult;
                })();
    
                v1.DebugExperimentResult = (function() {
    
                    /**
                     * Properties of a DebugExperimentResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IDebugExperimentResult
                     * @property {string|null} [trackingDataLink] DebugExperimentResult trackingDataLink
                     */
    
                    /**
                     * Constructs a new DebugExperimentResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DebugExperimentResult.
                     * @implements IDebugExperimentResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IDebugExperimentResult=} [properties] Properties to set
                     */
                    function DebugExperimentResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DebugExperimentResult trackingDataLink.
                     * @member {string} trackingDataLink
                     * @memberof palexy.sherlock.v1.DebugExperimentResult
                     * @instance
                     */
                    DebugExperimentResult.prototype.trackingDataLink = "";
    
                    /**
                     * Creates a new DebugExperimentResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DebugExperimentResult
                     * @static
                     * @param {palexy.sherlock.v1.IDebugExperimentResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DebugExperimentResult} DebugExperimentResult instance
                     */
                    DebugExperimentResult.create = function create(properties) {
                        return new DebugExperimentResult(properties);
                    };
    
                    /**
                     * Encodes the specified DebugExperimentResult message. Does not implicitly {@link palexy.sherlock.v1.DebugExperimentResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DebugExperimentResult
                     * @static
                     * @param {palexy.sherlock.v1.IDebugExperimentResult} message DebugExperimentResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DebugExperimentResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.trackingDataLink != null && Object.hasOwnProperty.call(message, "trackingDataLink"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.trackingDataLink);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DebugExperimentResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DebugExperimentResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DebugExperimentResult
                     * @static
                     * @param {palexy.sherlock.v1.IDebugExperimentResult} message DebugExperimentResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DebugExperimentResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DebugExperimentResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DebugExperimentResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DebugExperimentResult} DebugExperimentResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DebugExperimentResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DebugExperimentResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.trackingDataLink = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DebugExperimentResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DebugExperimentResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DebugExperimentResult} DebugExperimentResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DebugExperimentResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DebugExperimentResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DebugExperimentResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DebugExperimentResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.trackingDataLink != null && message.hasOwnProperty("trackingDataLink"))
                            if (!$util.isString(message.trackingDataLink))
                                return "trackingDataLink: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a DebugExperimentResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DebugExperimentResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DebugExperimentResult} DebugExperimentResult
                     */
                    DebugExperimentResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DebugExperimentResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.DebugExperimentResult();
                        if (object.trackingDataLink != null)
                            message.trackingDataLink = String(object.trackingDataLink);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DebugExperimentResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DebugExperimentResult
                     * @static
                     * @param {palexy.sherlock.v1.DebugExperimentResult} message DebugExperimentResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DebugExperimentResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.trackingDataLink = "";
                        if (message.trackingDataLink != null && message.hasOwnProperty("trackingDataLink"))
                            object.trackingDataLink = message.trackingDataLink;
                        return object;
                    };
    
                    /**
                     * Converts this DebugExperimentResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DebugExperimentResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DebugExperimentResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DebugExperimentResult;
                })();
    
                v1.PrepareQcVideosResult = (function() {
    
                    /**
                     * Properties of a PrepareQcVideosResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IPrepareQcVideosResult
                     * @property {Array.<palexy.sherlock.v1.IRemoteFile>|null} [qcVideos] PrepareQcVideosResult qcVideos
                     * @property {palexy.sherlock.v1.SherlockTask.StorageProvider|null} [storageProvider] PrepareQcVideosResult storageProvider
                     * @property {string|null} [googleDriveTaskResultDirId] PrepareQcVideosResult googleDriveTaskResultDirId
                     * @property {string|null} [googleDriveTaskResultWebViewLink] PrepareQcVideosResult googleDriveTaskResultWebViewLink
                     */
    
                    /**
                     * Constructs a new PrepareQcVideosResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a PrepareQcVideosResult.
                     * @implements IPrepareQcVideosResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IPrepareQcVideosResult=} [properties] Properties to set
                     */
                    function PrepareQcVideosResult(properties) {
                        this.qcVideos = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PrepareQcVideosResult qcVideos.
                     * @member {Array.<palexy.sherlock.v1.IRemoteFile>} qcVideos
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @instance
                     */
                    PrepareQcVideosResult.prototype.qcVideos = $util.emptyArray;
    
                    /**
                     * PrepareQcVideosResult storageProvider.
                     * @member {palexy.sherlock.v1.SherlockTask.StorageProvider} storageProvider
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @instance
                     */
                    PrepareQcVideosResult.prototype.storageProvider = 0;
    
                    /**
                     * PrepareQcVideosResult googleDriveTaskResultDirId.
                     * @member {string} googleDriveTaskResultDirId
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @instance
                     */
                    PrepareQcVideosResult.prototype.googleDriveTaskResultDirId = "";
    
                    /**
                     * PrepareQcVideosResult googleDriveTaskResultWebViewLink.
                     * @member {string} googleDriveTaskResultWebViewLink
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @instance
                     */
                    PrepareQcVideosResult.prototype.googleDriveTaskResultWebViewLink = "";
    
                    /**
                     * Creates a new PrepareQcVideosResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @static
                     * @param {palexy.sherlock.v1.IPrepareQcVideosResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.PrepareQcVideosResult} PrepareQcVideosResult instance
                     */
                    PrepareQcVideosResult.create = function create(properties) {
                        return new PrepareQcVideosResult(properties);
                    };
    
                    /**
                     * Encodes the specified PrepareQcVideosResult message. Does not implicitly {@link palexy.sherlock.v1.PrepareQcVideosResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @static
                     * @param {palexy.sherlock.v1.IPrepareQcVideosResult} message PrepareQcVideosResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PrepareQcVideosResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.qcVideos != null && message.qcVideos.length)
                            for (var i = 0; i < message.qcVideos.length; ++i)
                                $root.palexy.sherlock.v1.RemoteFile.encode(message.qcVideos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.storageProvider != null && Object.hasOwnProperty.call(message, "storageProvider"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.storageProvider);
                        if (message.googleDriveTaskResultDirId != null && Object.hasOwnProperty.call(message, "googleDriveTaskResultDirId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.googleDriveTaskResultDirId);
                        if (message.googleDriveTaskResultWebViewLink != null && Object.hasOwnProperty.call(message, "googleDriveTaskResultWebViewLink"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.googleDriveTaskResultWebViewLink);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PrepareQcVideosResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.PrepareQcVideosResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @static
                     * @param {palexy.sherlock.v1.IPrepareQcVideosResult} message PrepareQcVideosResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PrepareQcVideosResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PrepareQcVideosResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.PrepareQcVideosResult} PrepareQcVideosResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PrepareQcVideosResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.PrepareQcVideosResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.qcVideos && message.qcVideos.length))
                                    message.qcVideos = [];
                                message.qcVideos.push($root.palexy.sherlock.v1.RemoteFile.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.storageProvider = reader.int32();
                                break;
                            case 3:
                                message.googleDriveTaskResultDirId = reader.string();
                                break;
                            case 4:
                                message.googleDriveTaskResultWebViewLink = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PrepareQcVideosResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.PrepareQcVideosResult} PrepareQcVideosResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PrepareQcVideosResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PrepareQcVideosResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PrepareQcVideosResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.qcVideos != null && message.hasOwnProperty("qcVideos")) {
                            if (!Array.isArray(message.qcVideos))
                                return "qcVideos: array expected";
                            for (var i = 0; i < message.qcVideos.length; ++i) {
                                var error = $root.palexy.sherlock.v1.RemoteFile.verify(message.qcVideos[i]);
                                if (error)
                                    return "qcVideos." + error;
                            }
                        }
                        if (message.storageProvider != null && message.hasOwnProperty("storageProvider"))
                            switch (message.storageProvider) {
                            default:
                                return "storageProvider: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.googleDriveTaskResultDirId != null && message.hasOwnProperty("googleDriveTaskResultDirId"))
                            if (!$util.isString(message.googleDriveTaskResultDirId))
                                return "googleDriveTaskResultDirId: string expected";
                        if (message.googleDriveTaskResultWebViewLink != null && message.hasOwnProperty("googleDriveTaskResultWebViewLink"))
                            if (!$util.isString(message.googleDriveTaskResultWebViewLink))
                                return "googleDriveTaskResultWebViewLink: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a PrepareQcVideosResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.PrepareQcVideosResult} PrepareQcVideosResult
                     */
                    PrepareQcVideosResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.PrepareQcVideosResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.PrepareQcVideosResult();
                        if (object.qcVideos) {
                            if (!Array.isArray(object.qcVideos))
                                throw TypeError(".palexy.sherlock.v1.PrepareQcVideosResult.qcVideos: array expected");
                            message.qcVideos = [];
                            for (var i = 0; i < object.qcVideos.length; ++i) {
                                if (typeof object.qcVideos[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.PrepareQcVideosResult.qcVideos: object expected");
                                message.qcVideos[i] = $root.palexy.sherlock.v1.RemoteFile.fromObject(object.qcVideos[i]);
                            }
                        }
                        switch (object.storageProvider) {
                        case "UNKNOWN_PROVIDER":
                        case 0:
                            message.storageProvider = 0;
                            break;
                        case "GCP":
                        case 1:
                            message.storageProvider = 1;
                            break;
                        case "GOOGLE_DRIVE":
                        case 2:
                            message.storageProvider = 2;
                            break;
                        }
                        if (object.googleDriveTaskResultDirId != null)
                            message.googleDriveTaskResultDirId = String(object.googleDriveTaskResultDirId);
                        if (object.googleDriveTaskResultWebViewLink != null)
                            message.googleDriveTaskResultWebViewLink = String(object.googleDriveTaskResultWebViewLink);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PrepareQcVideosResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @static
                     * @param {palexy.sherlock.v1.PrepareQcVideosResult} message PrepareQcVideosResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PrepareQcVideosResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.qcVideos = [];
                        if (options.defaults) {
                            object.storageProvider = options.enums === String ? "UNKNOWN_PROVIDER" : 0;
                            object.googleDriveTaskResultDirId = "";
                            object.googleDriveTaskResultWebViewLink = "";
                        }
                        if (message.qcVideos && message.qcVideos.length) {
                            object.qcVideos = [];
                            for (var j = 0; j < message.qcVideos.length; ++j)
                                object.qcVideos[j] = $root.palexy.sherlock.v1.RemoteFile.toObject(message.qcVideos[j], options);
                        }
                        if (message.storageProvider != null && message.hasOwnProperty("storageProvider"))
                            object.storageProvider = options.enums === String ? $root.palexy.sherlock.v1.SherlockTask.StorageProvider[message.storageProvider] : message.storageProvider;
                        if (message.googleDriveTaskResultDirId != null && message.hasOwnProperty("googleDriveTaskResultDirId"))
                            object.googleDriveTaskResultDirId = message.googleDriveTaskResultDirId;
                        if (message.googleDriveTaskResultWebViewLink != null && message.hasOwnProperty("googleDriveTaskResultWebViewLink"))
                            object.googleDriveTaskResultWebViewLink = message.googleDriveTaskResultWebViewLink;
                        return object;
                    };
    
                    /**
                     * Converts this PrepareQcVideosResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.PrepareQcVideosResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PrepareQcVideosResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PrepareQcVideosResult;
                })();
    
                v1.ExportHakuhodoResult = (function() {
    
                    /**
                     * Properties of an ExportHakuhodoResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IExportHakuhodoResult
                     * @property {string|null} [googleDriveTaskResultWebViewLink] ExportHakuhodoResult googleDriveTaskResultWebViewLink
                     */
    
                    /**
                     * Constructs a new ExportHakuhodoResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an ExportHakuhodoResult.
                     * @implements IExportHakuhodoResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IExportHakuhodoResult=} [properties] Properties to set
                     */
                    function ExportHakuhodoResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExportHakuhodoResult googleDriveTaskResultWebViewLink.
                     * @member {string} googleDriveTaskResultWebViewLink
                     * @memberof palexy.sherlock.v1.ExportHakuhodoResult
                     * @instance
                     */
                    ExportHakuhodoResult.prototype.googleDriveTaskResultWebViewLink = "";
    
                    /**
                     * Creates a new ExportHakuhodoResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ExportHakuhodoResult
                     * @static
                     * @param {palexy.sherlock.v1.IExportHakuhodoResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ExportHakuhodoResult} ExportHakuhodoResult instance
                     */
                    ExportHakuhodoResult.create = function create(properties) {
                        return new ExportHakuhodoResult(properties);
                    };
    
                    /**
                     * Encodes the specified ExportHakuhodoResult message. Does not implicitly {@link palexy.sherlock.v1.ExportHakuhodoResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ExportHakuhodoResult
                     * @static
                     * @param {palexy.sherlock.v1.IExportHakuhodoResult} message ExportHakuhodoResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportHakuhodoResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.googleDriveTaskResultWebViewLink != null && Object.hasOwnProperty.call(message, "googleDriveTaskResultWebViewLink"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.googleDriveTaskResultWebViewLink);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportHakuhodoResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ExportHakuhodoResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ExportHakuhodoResult
                     * @static
                     * @param {palexy.sherlock.v1.IExportHakuhodoResult} message ExportHakuhodoResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportHakuhodoResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportHakuhodoResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ExportHakuhodoResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ExportHakuhodoResult} ExportHakuhodoResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportHakuhodoResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ExportHakuhodoResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.googleDriveTaskResultWebViewLink = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportHakuhodoResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ExportHakuhodoResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ExportHakuhodoResult} ExportHakuhodoResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportHakuhodoResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportHakuhodoResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ExportHakuhodoResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportHakuhodoResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.googleDriveTaskResultWebViewLink != null && message.hasOwnProperty("googleDriveTaskResultWebViewLink"))
                            if (!$util.isString(message.googleDriveTaskResultWebViewLink))
                                return "googleDriveTaskResultWebViewLink: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExportHakuhodoResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ExportHakuhodoResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ExportHakuhodoResult} ExportHakuhodoResult
                     */
                    ExportHakuhodoResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ExportHakuhodoResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ExportHakuhodoResult();
                        if (object.googleDriveTaskResultWebViewLink != null)
                            message.googleDriveTaskResultWebViewLink = String(object.googleDriveTaskResultWebViewLink);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExportHakuhodoResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ExportHakuhodoResult
                     * @static
                     * @param {palexy.sherlock.v1.ExportHakuhodoResult} message ExportHakuhodoResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportHakuhodoResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.googleDriveTaskResultWebViewLink = "";
                        if (message.googleDriveTaskResultWebViewLink != null && message.hasOwnProperty("googleDriveTaskResultWebViewLink"))
                            object.googleDriveTaskResultWebViewLink = message.googleDriveTaskResultWebViewLink;
                        return object;
                    };
    
                    /**
                     * Converts this ExportHakuhodoResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ExportHakuhodoResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportHakuhodoResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExportHakuhodoResult;
                })();
    
                v1.ExportOsoolEventsResult = (function() {
    
                    /**
                     * Properties of an ExportOsoolEventsResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IExportOsoolEventsResult
                     * @property {string|null} [output] ExportOsoolEventsResult output
                     */
    
                    /**
                     * Constructs a new ExportOsoolEventsResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an ExportOsoolEventsResult.
                     * @implements IExportOsoolEventsResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IExportOsoolEventsResult=} [properties] Properties to set
                     */
                    function ExportOsoolEventsResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExportOsoolEventsResult output.
                     * @member {string} output
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsResult
                     * @instance
                     */
                    ExportOsoolEventsResult.prototype.output = "";
    
                    /**
                     * Creates a new ExportOsoolEventsResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsResult
                     * @static
                     * @param {palexy.sherlock.v1.IExportOsoolEventsResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ExportOsoolEventsResult} ExportOsoolEventsResult instance
                     */
                    ExportOsoolEventsResult.create = function create(properties) {
                        return new ExportOsoolEventsResult(properties);
                    };
    
                    /**
                     * Encodes the specified ExportOsoolEventsResult message. Does not implicitly {@link palexy.sherlock.v1.ExportOsoolEventsResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsResult
                     * @static
                     * @param {palexy.sherlock.v1.IExportOsoolEventsResult} message ExportOsoolEventsResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportOsoolEventsResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.output != null && Object.hasOwnProperty.call(message, "output"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.output);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportOsoolEventsResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ExportOsoolEventsResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsResult
                     * @static
                     * @param {palexy.sherlock.v1.IExportOsoolEventsResult} message ExportOsoolEventsResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportOsoolEventsResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportOsoolEventsResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ExportOsoolEventsResult} ExportOsoolEventsResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportOsoolEventsResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ExportOsoolEventsResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.output = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportOsoolEventsResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ExportOsoolEventsResult} ExportOsoolEventsResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportOsoolEventsResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportOsoolEventsResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportOsoolEventsResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.output != null && message.hasOwnProperty("output"))
                            if (!$util.isString(message.output))
                                return "output: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExportOsoolEventsResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ExportOsoolEventsResult} ExportOsoolEventsResult
                     */
                    ExportOsoolEventsResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ExportOsoolEventsResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ExportOsoolEventsResult();
                        if (object.output != null)
                            message.output = String(object.output);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExportOsoolEventsResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsResult
                     * @static
                     * @param {palexy.sherlock.v1.ExportOsoolEventsResult} message ExportOsoolEventsResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportOsoolEventsResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.output = "";
                        if (message.output != null && message.hasOwnProperty("output"))
                            object.output = message.output;
                        return object;
                    };
    
                    /**
                     * Converts this ExportOsoolEventsResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ExportOsoolEventsResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportOsoolEventsResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExportOsoolEventsResult;
                })();
    
                v1.GoogleCloudStorageFile = (function() {
    
                    /**
                     * Properties of a GoogleCloudStorageFile.
                     * @memberof palexy.sherlock.v1
                     * @interface IGoogleCloudStorageFile
                     * @property {string|null} [bucket] GoogleCloudStorageFile bucket
                     * @property {string|null} [object] GoogleCloudStorageFile object
                     * @property {string|null} [resourceUrl] GoogleCloudStorageFile resourceUrl
                     * @property {string|null} [downloadableUrl] GoogleCloudStorageFile downloadableUrl
                     */
    
                    /**
                     * Constructs a new GoogleCloudStorageFile.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GoogleCloudStorageFile.
                     * @implements IGoogleCloudStorageFile
                     * @constructor
                     * @param {palexy.sherlock.v1.IGoogleCloudStorageFile=} [properties] Properties to set
                     */
                    function GoogleCloudStorageFile(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GoogleCloudStorageFile bucket.
                     * @member {string} bucket
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @instance
                     */
                    GoogleCloudStorageFile.prototype.bucket = "";
    
                    /**
                     * GoogleCloudStorageFile object.
                     * @member {string} object
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @instance
                     */
                    GoogleCloudStorageFile.prototype.object = "";
    
                    /**
                     * GoogleCloudStorageFile resourceUrl.
                     * @member {string} resourceUrl
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @instance
                     */
                    GoogleCloudStorageFile.prototype.resourceUrl = "";
    
                    /**
                     * GoogleCloudStorageFile downloadableUrl.
                     * @member {string} downloadableUrl
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @instance
                     */
                    GoogleCloudStorageFile.prototype.downloadableUrl = "";
    
                    /**
                     * Creates a new GoogleCloudStorageFile instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @static
                     * @param {palexy.sherlock.v1.IGoogleCloudStorageFile=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GoogleCloudStorageFile} GoogleCloudStorageFile instance
                     */
                    GoogleCloudStorageFile.create = function create(properties) {
                        return new GoogleCloudStorageFile(properties);
                    };
    
                    /**
                     * Encodes the specified GoogleCloudStorageFile message. Does not implicitly {@link palexy.sherlock.v1.GoogleCloudStorageFile.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @static
                     * @param {palexy.sherlock.v1.IGoogleCloudStorageFile} message GoogleCloudStorageFile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GoogleCloudStorageFile.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.bucket != null && Object.hasOwnProperty.call(message, "bucket"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.bucket);
                        if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.object);
                        if (message.resourceUrl != null && Object.hasOwnProperty.call(message, "resourceUrl"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.resourceUrl);
                        if (message.downloadableUrl != null && Object.hasOwnProperty.call(message, "downloadableUrl"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.downloadableUrl);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GoogleCloudStorageFile message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GoogleCloudStorageFile.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @static
                     * @param {palexy.sherlock.v1.IGoogleCloudStorageFile} message GoogleCloudStorageFile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GoogleCloudStorageFile.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GoogleCloudStorageFile message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GoogleCloudStorageFile} GoogleCloudStorageFile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GoogleCloudStorageFile.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GoogleCloudStorageFile();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.bucket = reader.string();
                                break;
                            case 2:
                                message.object = reader.string();
                                break;
                            case 3:
                                message.resourceUrl = reader.string();
                                break;
                            case 4:
                                message.downloadableUrl = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GoogleCloudStorageFile message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GoogleCloudStorageFile} GoogleCloudStorageFile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GoogleCloudStorageFile.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GoogleCloudStorageFile message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GoogleCloudStorageFile.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.bucket != null && message.hasOwnProperty("bucket"))
                            if (!$util.isString(message.bucket))
                                return "bucket: string expected";
                        if (message.object != null && message.hasOwnProperty("object"))
                            if (!$util.isString(message.object))
                                return "object: string expected";
                        if (message.resourceUrl != null && message.hasOwnProperty("resourceUrl"))
                            if (!$util.isString(message.resourceUrl))
                                return "resourceUrl: string expected";
                        if (message.downloadableUrl != null && message.hasOwnProperty("downloadableUrl"))
                            if (!$util.isString(message.downloadableUrl))
                                return "downloadableUrl: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GoogleCloudStorageFile message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GoogleCloudStorageFile} GoogleCloudStorageFile
                     */
                    GoogleCloudStorageFile.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GoogleCloudStorageFile)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GoogleCloudStorageFile();
                        if (object.bucket != null)
                            message.bucket = String(object.bucket);
                        if (object.object != null)
                            message.object = String(object.object);
                        if (object.resourceUrl != null)
                            message.resourceUrl = String(object.resourceUrl);
                        if (object.downloadableUrl != null)
                            message.downloadableUrl = String(object.downloadableUrl);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GoogleCloudStorageFile message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @static
                     * @param {palexy.sherlock.v1.GoogleCloudStorageFile} message GoogleCloudStorageFile
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GoogleCloudStorageFile.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.bucket = "";
                            object.object = "";
                            object.resourceUrl = "";
                            object.downloadableUrl = "";
                        }
                        if (message.bucket != null && message.hasOwnProperty("bucket"))
                            object.bucket = message.bucket;
                        if (message.object != null && message.hasOwnProperty("object"))
                            object.object = message.object;
                        if (message.resourceUrl != null && message.hasOwnProperty("resourceUrl"))
                            object.resourceUrl = message.resourceUrl;
                        if (message.downloadableUrl != null && message.hasOwnProperty("downloadableUrl"))
                            object.downloadableUrl = message.downloadableUrl;
                        return object;
                    };
    
                    /**
                     * Converts this GoogleCloudStorageFile to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GoogleCloudStorageFile
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GoogleCloudStorageFile.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GoogleCloudStorageFile;
                })();
    
                v1.GoogleDriveFile = (function() {
    
                    /**
                     * Properties of a GoogleDriveFile.
                     * @memberof palexy.sherlock.v1
                     * @interface IGoogleDriveFile
                     * @property {string|null} [owner] GoogleDriveFile owner
                     * @property {string|null} [fileId] GoogleDriveFile fileId
                     * @property {string|null} [resourceUrl] GoogleDriveFile resourceUrl
                     * @property {string|null} [downloadableUrl] GoogleDriveFile downloadableUrl
                     */
    
                    /**
                     * Constructs a new GoogleDriveFile.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GoogleDriveFile.
                     * @implements IGoogleDriveFile
                     * @constructor
                     * @param {palexy.sherlock.v1.IGoogleDriveFile=} [properties] Properties to set
                     */
                    function GoogleDriveFile(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GoogleDriveFile owner.
                     * @member {string} owner
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @instance
                     */
                    GoogleDriveFile.prototype.owner = "";
    
                    /**
                     * GoogleDriveFile fileId.
                     * @member {string} fileId
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @instance
                     */
                    GoogleDriveFile.prototype.fileId = "";
    
                    /**
                     * GoogleDriveFile resourceUrl.
                     * @member {string} resourceUrl
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @instance
                     */
                    GoogleDriveFile.prototype.resourceUrl = "";
    
                    /**
                     * GoogleDriveFile downloadableUrl.
                     * @member {string} downloadableUrl
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @instance
                     */
                    GoogleDriveFile.prototype.downloadableUrl = "";
    
                    /**
                     * Creates a new GoogleDriveFile instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @static
                     * @param {palexy.sherlock.v1.IGoogleDriveFile=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GoogleDriveFile} GoogleDriveFile instance
                     */
                    GoogleDriveFile.create = function create(properties) {
                        return new GoogleDriveFile(properties);
                    };
    
                    /**
                     * Encodes the specified GoogleDriveFile message. Does not implicitly {@link palexy.sherlock.v1.GoogleDriveFile.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @static
                     * @param {palexy.sherlock.v1.IGoogleDriveFile} message GoogleDriveFile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GoogleDriveFile.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.owner);
                        if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.fileId);
                        if (message.resourceUrl != null && Object.hasOwnProperty.call(message, "resourceUrl"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.resourceUrl);
                        if (message.downloadableUrl != null && Object.hasOwnProperty.call(message, "downloadableUrl"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.downloadableUrl);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GoogleDriveFile message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GoogleDriveFile.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @static
                     * @param {palexy.sherlock.v1.IGoogleDriveFile} message GoogleDriveFile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GoogleDriveFile.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GoogleDriveFile message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GoogleDriveFile} GoogleDriveFile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GoogleDriveFile.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GoogleDriveFile();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.owner = reader.string();
                                break;
                            case 2:
                                message.fileId = reader.string();
                                break;
                            case 3:
                                message.resourceUrl = reader.string();
                                break;
                            case 4:
                                message.downloadableUrl = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GoogleDriveFile message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GoogleDriveFile} GoogleDriveFile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GoogleDriveFile.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GoogleDriveFile message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GoogleDriveFile.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.owner != null && message.hasOwnProperty("owner"))
                            if (!$util.isString(message.owner))
                                return "owner: string expected";
                        if (message.fileId != null && message.hasOwnProperty("fileId"))
                            if (!$util.isString(message.fileId))
                                return "fileId: string expected";
                        if (message.resourceUrl != null && message.hasOwnProperty("resourceUrl"))
                            if (!$util.isString(message.resourceUrl))
                                return "resourceUrl: string expected";
                        if (message.downloadableUrl != null && message.hasOwnProperty("downloadableUrl"))
                            if (!$util.isString(message.downloadableUrl))
                                return "downloadableUrl: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GoogleDriveFile message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GoogleDriveFile} GoogleDriveFile
                     */
                    GoogleDriveFile.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GoogleDriveFile)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GoogleDriveFile();
                        if (object.owner != null)
                            message.owner = String(object.owner);
                        if (object.fileId != null)
                            message.fileId = String(object.fileId);
                        if (object.resourceUrl != null)
                            message.resourceUrl = String(object.resourceUrl);
                        if (object.downloadableUrl != null)
                            message.downloadableUrl = String(object.downloadableUrl);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GoogleDriveFile message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @static
                     * @param {palexy.sherlock.v1.GoogleDriveFile} message GoogleDriveFile
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GoogleDriveFile.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.owner = "";
                            object.fileId = "";
                            object.resourceUrl = "";
                            object.downloadableUrl = "";
                        }
                        if (message.owner != null && message.hasOwnProperty("owner"))
                            object.owner = message.owner;
                        if (message.fileId != null && message.hasOwnProperty("fileId"))
                            object.fileId = message.fileId;
                        if (message.resourceUrl != null && message.hasOwnProperty("resourceUrl"))
                            object.resourceUrl = message.resourceUrl;
                        if (message.downloadableUrl != null && message.hasOwnProperty("downloadableUrl"))
                            object.downloadableUrl = message.downloadableUrl;
                        return object;
                    };
    
                    /**
                     * Converts this GoogleDriveFile to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GoogleDriveFile
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GoogleDriveFile.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GoogleDriveFile;
                })();
    
                v1.RemoteFile = (function() {
    
                    /**
                     * Properties of a RemoteFile.
                     * @memberof palexy.sherlock.v1
                     * @interface IRemoteFile
                     * @property {string|null} [filename] RemoteFile filename
                     * @property {palexy.sherlock.v1.IGoogleCloudStorageFile|null} [googleCloudStorageFile] RemoteFile googleCloudStorageFile
                     * @property {palexy.sherlock.v1.IGoogleDriveFile|null} [googleDriveFile] RemoteFile googleDriveFile
                     */
    
                    /**
                     * Constructs a new RemoteFile.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a RemoteFile.
                     * @implements IRemoteFile
                     * @constructor
                     * @param {palexy.sherlock.v1.IRemoteFile=} [properties] Properties to set
                     */
                    function RemoteFile(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RemoteFile filename.
                     * @member {string} filename
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @instance
                     */
                    RemoteFile.prototype.filename = "";
    
                    /**
                     * RemoteFile googleCloudStorageFile.
                     * @member {palexy.sherlock.v1.IGoogleCloudStorageFile|null|undefined} googleCloudStorageFile
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @instance
                     */
                    RemoteFile.prototype.googleCloudStorageFile = null;
    
                    /**
                     * RemoteFile googleDriveFile.
                     * @member {palexy.sherlock.v1.IGoogleDriveFile|null|undefined} googleDriveFile
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @instance
                     */
                    RemoteFile.prototype.googleDriveFile = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * RemoteFile resource.
                     * @member {"googleCloudStorageFile"|"googleDriveFile"|undefined} resource
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @instance
                     */
                    Object.defineProperty(RemoteFile.prototype, "resource", {
                        get: $util.oneOfGetter($oneOfFields = ["googleCloudStorageFile", "googleDriveFile"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new RemoteFile instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @static
                     * @param {palexy.sherlock.v1.IRemoteFile=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.RemoteFile} RemoteFile instance
                     */
                    RemoteFile.create = function create(properties) {
                        return new RemoteFile(properties);
                    };
    
                    /**
                     * Encodes the specified RemoteFile message. Does not implicitly {@link palexy.sherlock.v1.RemoteFile.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @static
                     * @param {palexy.sherlock.v1.IRemoteFile} message RemoteFile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoteFile.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.filename);
                        if (message.googleCloudStorageFile != null && Object.hasOwnProperty.call(message, "googleCloudStorageFile"))
                            $root.palexy.sherlock.v1.GoogleCloudStorageFile.encode(message.googleCloudStorageFile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.googleDriveFile != null && Object.hasOwnProperty.call(message, "googleDriveFile"))
                            $root.palexy.sherlock.v1.GoogleDriveFile.encode(message.googleDriveFile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RemoteFile message, length delimited. Does not implicitly {@link palexy.sherlock.v1.RemoteFile.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @static
                     * @param {palexy.sherlock.v1.IRemoteFile} message RemoteFile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoteFile.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RemoteFile message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.RemoteFile} RemoteFile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoteFile.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.RemoteFile();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.filename = reader.string();
                                break;
                            case 2:
                                message.googleCloudStorageFile = $root.palexy.sherlock.v1.GoogleCloudStorageFile.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.googleDriveFile = $root.palexy.sherlock.v1.GoogleDriveFile.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RemoteFile message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.RemoteFile} RemoteFile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoteFile.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RemoteFile message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RemoteFile.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.filename != null && message.hasOwnProperty("filename"))
                            if (!$util.isString(message.filename))
                                return "filename: string expected";
                        if (message.googleCloudStorageFile != null && message.hasOwnProperty("googleCloudStorageFile")) {
                            properties.resource = 1;
                            {
                                var error = $root.palexy.sherlock.v1.GoogleCloudStorageFile.verify(message.googleCloudStorageFile);
                                if (error)
                                    return "googleCloudStorageFile." + error;
                            }
                        }
                        if (message.googleDriveFile != null && message.hasOwnProperty("googleDriveFile")) {
                            if (properties.resource === 1)
                                return "resource: multiple values";
                            properties.resource = 1;
                            {
                                var error = $root.palexy.sherlock.v1.GoogleDriveFile.verify(message.googleDriveFile);
                                if (error)
                                    return "googleDriveFile." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a RemoteFile message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.RemoteFile} RemoteFile
                     */
                    RemoteFile.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.RemoteFile)
                            return object;
                        var message = new $root.palexy.sherlock.v1.RemoteFile();
                        if (object.filename != null)
                            message.filename = String(object.filename);
                        if (object.googleCloudStorageFile != null) {
                            if (typeof object.googleCloudStorageFile !== "object")
                                throw TypeError(".palexy.sherlock.v1.RemoteFile.googleCloudStorageFile: object expected");
                            message.googleCloudStorageFile = $root.palexy.sherlock.v1.GoogleCloudStorageFile.fromObject(object.googleCloudStorageFile);
                        }
                        if (object.googleDriveFile != null) {
                            if (typeof object.googleDriveFile !== "object")
                                throw TypeError(".palexy.sherlock.v1.RemoteFile.googleDriveFile: object expected");
                            message.googleDriveFile = $root.palexy.sherlock.v1.GoogleDriveFile.fromObject(object.googleDriveFile);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RemoteFile message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @static
                     * @param {palexy.sherlock.v1.RemoteFile} message RemoteFile
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RemoteFile.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.filename = "";
                        if (message.filename != null && message.hasOwnProperty("filename"))
                            object.filename = message.filename;
                        if (message.googleCloudStorageFile != null && message.hasOwnProperty("googleCloudStorageFile")) {
                            object.googleCloudStorageFile = $root.palexy.sherlock.v1.GoogleCloudStorageFile.toObject(message.googleCloudStorageFile, options);
                            if (options.oneofs)
                                object.resource = "googleCloudStorageFile";
                        }
                        if (message.googleDriveFile != null && message.hasOwnProperty("googleDriveFile")) {
                            object.googleDriveFile = $root.palexy.sherlock.v1.GoogleDriveFile.toObject(message.googleDriveFile, options);
                            if (options.oneofs)
                                object.resource = "googleDriveFile";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this RemoteFile to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.RemoteFile
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RemoteFile.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RemoteFile;
                })();
    
                v1.GeneralPersonInfoResult = (function() {
    
                    /**
                     * Properties of a GeneralPersonInfoResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IGeneralPersonInfoResult
                     * @property {Array.<palexy.streaming.v1.IRawICASummaryRecord>|null} [icaSummaryRecords] GeneralPersonInfoResult icaSummaryRecords
                     */
    
                    /**
                     * Constructs a new GeneralPersonInfoResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GeneralPersonInfoResult.
                     * @implements IGeneralPersonInfoResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IGeneralPersonInfoResult=} [properties] Properties to set
                     */
                    function GeneralPersonInfoResult(properties) {
                        this.icaSummaryRecords = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GeneralPersonInfoResult icaSummaryRecords.
                     * @member {Array.<palexy.streaming.v1.IRawICASummaryRecord>} icaSummaryRecords
                     * @memberof palexy.sherlock.v1.GeneralPersonInfoResult
                     * @instance
                     */
                    GeneralPersonInfoResult.prototype.icaSummaryRecords = $util.emptyArray;
    
                    /**
                     * Creates a new GeneralPersonInfoResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GeneralPersonInfoResult
                     * @static
                     * @param {palexy.sherlock.v1.IGeneralPersonInfoResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GeneralPersonInfoResult} GeneralPersonInfoResult instance
                     */
                    GeneralPersonInfoResult.create = function create(properties) {
                        return new GeneralPersonInfoResult(properties);
                    };
    
                    /**
                     * Encodes the specified GeneralPersonInfoResult message. Does not implicitly {@link palexy.sherlock.v1.GeneralPersonInfoResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GeneralPersonInfoResult
                     * @static
                     * @param {palexy.sherlock.v1.IGeneralPersonInfoResult} message GeneralPersonInfoResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GeneralPersonInfoResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.icaSummaryRecords != null && message.icaSummaryRecords.length)
                            for (var i = 0; i < message.icaSummaryRecords.length; ++i)
                                $root.palexy.streaming.v1.RawICASummaryRecord.encode(message.icaSummaryRecords[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GeneralPersonInfoResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GeneralPersonInfoResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GeneralPersonInfoResult
                     * @static
                     * @param {palexy.sherlock.v1.IGeneralPersonInfoResult} message GeneralPersonInfoResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GeneralPersonInfoResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GeneralPersonInfoResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GeneralPersonInfoResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GeneralPersonInfoResult} GeneralPersonInfoResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GeneralPersonInfoResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GeneralPersonInfoResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.icaSummaryRecords && message.icaSummaryRecords.length))
                                    message.icaSummaryRecords = [];
                                message.icaSummaryRecords.push($root.palexy.streaming.v1.RawICASummaryRecord.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GeneralPersonInfoResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GeneralPersonInfoResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GeneralPersonInfoResult} GeneralPersonInfoResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GeneralPersonInfoResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GeneralPersonInfoResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GeneralPersonInfoResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GeneralPersonInfoResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.icaSummaryRecords != null && message.hasOwnProperty("icaSummaryRecords")) {
                            if (!Array.isArray(message.icaSummaryRecords))
                                return "icaSummaryRecords: array expected";
                            for (var i = 0; i < message.icaSummaryRecords.length; ++i) {
                                var error = $root.palexy.streaming.v1.RawICASummaryRecord.verify(message.icaSummaryRecords[i]);
                                if (error)
                                    return "icaSummaryRecords." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GeneralPersonInfoResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GeneralPersonInfoResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GeneralPersonInfoResult} GeneralPersonInfoResult
                     */
                    GeneralPersonInfoResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GeneralPersonInfoResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GeneralPersonInfoResult();
                        if (object.icaSummaryRecords) {
                            if (!Array.isArray(object.icaSummaryRecords))
                                throw TypeError(".palexy.sherlock.v1.GeneralPersonInfoResult.icaSummaryRecords: array expected");
                            message.icaSummaryRecords = [];
                            for (var i = 0; i < object.icaSummaryRecords.length; ++i) {
                                if (typeof object.icaSummaryRecords[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.GeneralPersonInfoResult.icaSummaryRecords: object expected");
                                message.icaSummaryRecords[i] = $root.palexy.streaming.v1.RawICASummaryRecord.fromObject(object.icaSummaryRecords[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GeneralPersonInfoResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GeneralPersonInfoResult
                     * @static
                     * @param {palexy.sherlock.v1.GeneralPersonInfoResult} message GeneralPersonInfoResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GeneralPersonInfoResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.icaSummaryRecords = [];
                        if (message.icaSummaryRecords && message.icaSummaryRecords.length) {
                            object.icaSummaryRecords = [];
                            for (var j = 0; j < message.icaSummaryRecords.length; ++j)
                                object.icaSummaryRecords[j] = $root.palexy.streaming.v1.RawICASummaryRecord.toObject(message.icaSummaryRecords[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GeneralPersonInfoResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GeneralPersonInfoResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GeneralPersonInfoResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GeneralPersonInfoResult;
                })();
    
                v1.PersonFilter = (function() {
    
                    /**
                     * Properties of a PersonFilter.
                     * @memberof palexy.sherlock.v1
                     * @interface IPersonFilter
                     * @property {number|null} [storeId] PersonFilter storeId
                     * @property {string|null} [startDateTime] PersonFilter startDateTime
                     * @property {string|null} [endDateTime] PersonFilter endDateTime
                     */
    
                    /**
                     * Constructs a new PersonFilter.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a PersonFilter.
                     * @implements IPersonFilter
                     * @constructor
                     * @param {palexy.sherlock.v1.IPersonFilter=} [properties] Properties to set
                     */
                    function PersonFilter(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PersonFilter storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @instance
                     */
                    PersonFilter.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PersonFilter startDateTime.
                     * @member {string} startDateTime
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @instance
                     */
                    PersonFilter.prototype.startDateTime = "";
    
                    /**
                     * PersonFilter endDateTime.
                     * @member {string} endDateTime
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @instance
                     */
                    PersonFilter.prototype.endDateTime = "";
    
                    /**
                     * Creates a new PersonFilter instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @static
                     * @param {palexy.sherlock.v1.IPersonFilter=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.PersonFilter} PersonFilter instance
                     */
                    PersonFilter.create = function create(properties) {
                        return new PersonFilter(properties);
                    };
    
                    /**
                     * Encodes the specified PersonFilter message. Does not implicitly {@link palexy.sherlock.v1.PersonFilter.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @static
                     * @param {palexy.sherlock.v1.IPersonFilter} message PersonFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PersonFilter.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.startDateTime != null && Object.hasOwnProperty.call(message, "startDateTime"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.startDateTime);
                        if (message.endDateTime != null && Object.hasOwnProperty.call(message, "endDateTime"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.endDateTime);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PersonFilter message, length delimited. Does not implicitly {@link palexy.sherlock.v1.PersonFilter.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @static
                     * @param {palexy.sherlock.v1.IPersonFilter} message PersonFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PersonFilter.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PersonFilter message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.PersonFilter} PersonFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PersonFilter.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.PersonFilter();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.startDateTime = reader.string();
                                break;
                            case 3:
                                message.endDateTime = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PersonFilter message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.PersonFilter} PersonFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PersonFilter.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PersonFilter message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PersonFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.startDateTime != null && message.hasOwnProperty("startDateTime"))
                            if (!$util.isString(message.startDateTime))
                                return "startDateTime: string expected";
                        if (message.endDateTime != null && message.hasOwnProperty("endDateTime"))
                            if (!$util.isString(message.endDateTime))
                                return "endDateTime: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a PersonFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.PersonFilter} PersonFilter
                     */
                    PersonFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.PersonFilter)
                            return object;
                        var message = new $root.palexy.sherlock.v1.PersonFilter();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.startDateTime != null)
                            message.startDateTime = String(object.startDateTime);
                        if (object.endDateTime != null)
                            message.endDateTime = String(object.endDateTime);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PersonFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @static
                     * @param {palexy.sherlock.v1.PersonFilter} message PersonFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PersonFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.startDateTime = "";
                            object.endDateTime = "";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.startDateTime != null && message.hasOwnProperty("startDateTime"))
                            object.startDateTime = message.startDateTime;
                        if (message.endDateTime != null && message.hasOwnProperty("endDateTime"))
                            object.endDateTime = message.endDateTime;
                        return object;
                    };
    
                    /**
                     * Converts this PersonFilter to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.PersonFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PersonFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PersonFilter;
                })();
    
                v1.ExperimentFilter = (function() {
    
                    /**
                     * Properties of an ExperimentFilter.
                     * @memberof palexy.sherlock.v1
                     * @interface IExperimentFilter
                     * @property {number|null} [storeId] ExperimentFilter storeId
                     * @property {string|null} [startDateTime] ExperimentFilter startDateTime
                     * @property {string|null} [endDateTime] ExperimentFilter endDateTime
                     * @property {string|null} [experimentName] ExperimentFilter experimentName
                     */
    
                    /**
                     * Constructs a new ExperimentFilter.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an ExperimentFilter.
                     * @implements IExperimentFilter
                     * @constructor
                     * @param {palexy.sherlock.v1.IExperimentFilter=} [properties] Properties to set
                     */
                    function ExperimentFilter(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExperimentFilter storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @instance
                     */
                    ExperimentFilter.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExperimentFilter startDateTime.
                     * @member {string} startDateTime
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @instance
                     */
                    ExperimentFilter.prototype.startDateTime = "";
    
                    /**
                     * ExperimentFilter endDateTime.
                     * @member {string} endDateTime
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @instance
                     */
                    ExperimentFilter.prototype.endDateTime = "";
    
                    /**
                     * ExperimentFilter experimentName.
                     * @member {string} experimentName
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @instance
                     */
                    ExperimentFilter.prototype.experimentName = "";
    
                    /**
                     * Creates a new ExperimentFilter instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @static
                     * @param {palexy.sherlock.v1.IExperimentFilter=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ExperimentFilter} ExperimentFilter instance
                     */
                    ExperimentFilter.create = function create(properties) {
                        return new ExperimentFilter(properties);
                    };
    
                    /**
                     * Encodes the specified ExperimentFilter message. Does not implicitly {@link palexy.sherlock.v1.ExperimentFilter.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @static
                     * @param {palexy.sherlock.v1.IExperimentFilter} message ExperimentFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExperimentFilter.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.startDateTime != null && Object.hasOwnProperty.call(message, "startDateTime"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.startDateTime);
                        if (message.endDateTime != null && Object.hasOwnProperty.call(message, "endDateTime"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.endDateTime);
                        if (message.experimentName != null && Object.hasOwnProperty.call(message, "experimentName"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.experimentName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExperimentFilter message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ExperimentFilter.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @static
                     * @param {palexy.sherlock.v1.IExperimentFilter} message ExperimentFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExperimentFilter.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExperimentFilter message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ExperimentFilter} ExperimentFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExperimentFilter.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ExperimentFilter();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.startDateTime = reader.string();
                                break;
                            case 3:
                                message.endDateTime = reader.string();
                                break;
                            case 5:
                                message.experimentName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExperimentFilter message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ExperimentFilter} ExperimentFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExperimentFilter.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExperimentFilter message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExperimentFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.startDateTime != null && message.hasOwnProperty("startDateTime"))
                            if (!$util.isString(message.startDateTime))
                                return "startDateTime: string expected";
                        if (message.endDateTime != null && message.hasOwnProperty("endDateTime"))
                            if (!$util.isString(message.endDateTime))
                                return "endDateTime: string expected";
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            if (!$util.isString(message.experimentName))
                                return "experimentName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExperimentFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ExperimentFilter} ExperimentFilter
                     */
                    ExperimentFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ExperimentFilter)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ExperimentFilter();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.startDateTime != null)
                            message.startDateTime = String(object.startDateTime);
                        if (object.endDateTime != null)
                            message.endDateTime = String(object.endDateTime);
                        if (object.experimentName != null)
                            message.experimentName = String(object.experimentName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExperimentFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @static
                     * @param {palexy.sherlock.v1.ExperimentFilter} message ExperimentFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExperimentFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.startDateTime = "";
                            object.endDateTime = "";
                            object.experimentName = "";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.startDateTime != null && message.hasOwnProperty("startDateTime"))
                            object.startDateTime = message.startDateTime;
                        if (message.endDateTime != null && message.hasOwnProperty("endDateTime"))
                            object.endDateTime = message.endDateTime;
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            object.experimentName = message.experimentName;
                        return object;
                    };
    
                    /**
                     * Converts this ExperimentFilter to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ExperimentFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExperimentFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExperimentFilter;
                })();
    
                v1.ExtractBBResult = (function() {
    
                    /**
                     * Properties of an ExtractBBResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IExtractBBResult
                     * @property {Array.<palexy.sherlock.v1.IDetailResult>|null} [detailResults] ExtractBBResult detailResults
                     * @property {string|null} [allBbDataLink] ExtractBBResult allBbDataLink
                     * @property {string|null} [humanLabelLink] ExtractBBResult humanLabelLink
                     * @property {string|null} [reconstructedMergeFileLink] ExtractBBResult reconstructedMergeFileLink
                     * @property {string|null} [trackingDataLink] ExtractBBResult trackingDataLink
                     */
    
                    /**
                     * Constructs a new ExtractBBResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an ExtractBBResult.
                     * @implements IExtractBBResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IExtractBBResult=} [properties] Properties to set
                     */
                    function ExtractBBResult(properties) {
                        this.detailResults = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExtractBBResult detailResults.
                     * @member {Array.<palexy.sherlock.v1.IDetailResult>} detailResults
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @instance
                     */
                    ExtractBBResult.prototype.detailResults = $util.emptyArray;
    
                    /**
                     * ExtractBBResult allBbDataLink.
                     * @member {string} allBbDataLink
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @instance
                     */
                    ExtractBBResult.prototype.allBbDataLink = "";
    
                    /**
                     * ExtractBBResult humanLabelLink.
                     * @member {string} humanLabelLink
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @instance
                     */
                    ExtractBBResult.prototype.humanLabelLink = "";
    
                    /**
                     * ExtractBBResult reconstructedMergeFileLink.
                     * @member {string} reconstructedMergeFileLink
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @instance
                     */
                    ExtractBBResult.prototype.reconstructedMergeFileLink = "";
    
                    /**
                     * ExtractBBResult trackingDataLink.
                     * @member {string} trackingDataLink
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @instance
                     */
                    ExtractBBResult.prototype.trackingDataLink = "";
    
                    /**
                     * Creates a new ExtractBBResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @static
                     * @param {palexy.sherlock.v1.IExtractBBResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ExtractBBResult} ExtractBBResult instance
                     */
                    ExtractBBResult.create = function create(properties) {
                        return new ExtractBBResult(properties);
                    };
    
                    /**
                     * Encodes the specified ExtractBBResult message. Does not implicitly {@link palexy.sherlock.v1.ExtractBBResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @static
                     * @param {palexy.sherlock.v1.IExtractBBResult} message ExtractBBResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExtractBBResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.detailResults != null && message.detailResults.length)
                            for (var i = 0; i < message.detailResults.length; ++i)
                                $root.palexy.sherlock.v1.DetailResult.encode(message.detailResults[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.allBbDataLink != null && Object.hasOwnProperty.call(message, "allBbDataLink"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.allBbDataLink);
                        if (message.humanLabelLink != null && Object.hasOwnProperty.call(message, "humanLabelLink"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.humanLabelLink);
                        if (message.reconstructedMergeFileLink != null && Object.hasOwnProperty.call(message, "reconstructedMergeFileLink"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.reconstructedMergeFileLink);
                        if (message.trackingDataLink != null && Object.hasOwnProperty.call(message, "trackingDataLink"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.trackingDataLink);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExtractBBResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ExtractBBResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @static
                     * @param {palexy.sherlock.v1.IExtractBBResult} message ExtractBBResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExtractBBResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExtractBBResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ExtractBBResult} ExtractBBResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExtractBBResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ExtractBBResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.detailResults && message.detailResults.length))
                                    message.detailResults = [];
                                message.detailResults.push($root.palexy.sherlock.v1.DetailResult.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.allBbDataLink = reader.string();
                                break;
                            case 3:
                                message.humanLabelLink = reader.string();
                                break;
                            case 4:
                                message.reconstructedMergeFileLink = reader.string();
                                break;
                            case 5:
                                message.trackingDataLink = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExtractBBResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ExtractBBResult} ExtractBBResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExtractBBResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExtractBBResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExtractBBResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.detailResults != null && message.hasOwnProperty("detailResults")) {
                            if (!Array.isArray(message.detailResults))
                                return "detailResults: array expected";
                            for (var i = 0; i < message.detailResults.length; ++i) {
                                var error = $root.palexy.sherlock.v1.DetailResult.verify(message.detailResults[i]);
                                if (error)
                                    return "detailResults." + error;
                            }
                        }
                        if (message.allBbDataLink != null && message.hasOwnProperty("allBbDataLink"))
                            if (!$util.isString(message.allBbDataLink))
                                return "allBbDataLink: string expected";
                        if (message.humanLabelLink != null && message.hasOwnProperty("humanLabelLink"))
                            if (!$util.isString(message.humanLabelLink))
                                return "humanLabelLink: string expected";
                        if (message.reconstructedMergeFileLink != null && message.hasOwnProperty("reconstructedMergeFileLink"))
                            if (!$util.isString(message.reconstructedMergeFileLink))
                                return "reconstructedMergeFileLink: string expected";
                        if (message.trackingDataLink != null && message.hasOwnProperty("trackingDataLink"))
                            if (!$util.isString(message.trackingDataLink))
                                return "trackingDataLink: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExtractBBResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ExtractBBResult} ExtractBBResult
                     */
                    ExtractBBResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ExtractBBResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ExtractBBResult();
                        if (object.detailResults) {
                            if (!Array.isArray(object.detailResults))
                                throw TypeError(".palexy.sherlock.v1.ExtractBBResult.detailResults: array expected");
                            message.detailResults = [];
                            for (var i = 0; i < object.detailResults.length; ++i) {
                                if (typeof object.detailResults[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ExtractBBResult.detailResults: object expected");
                                message.detailResults[i] = $root.palexy.sherlock.v1.DetailResult.fromObject(object.detailResults[i]);
                            }
                        }
                        if (object.allBbDataLink != null)
                            message.allBbDataLink = String(object.allBbDataLink);
                        if (object.humanLabelLink != null)
                            message.humanLabelLink = String(object.humanLabelLink);
                        if (object.reconstructedMergeFileLink != null)
                            message.reconstructedMergeFileLink = String(object.reconstructedMergeFileLink);
                        if (object.trackingDataLink != null)
                            message.trackingDataLink = String(object.trackingDataLink);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExtractBBResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @static
                     * @param {palexy.sherlock.v1.ExtractBBResult} message ExtractBBResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExtractBBResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.detailResults = [];
                        if (options.defaults) {
                            object.allBbDataLink = "";
                            object.humanLabelLink = "";
                            object.reconstructedMergeFileLink = "";
                            object.trackingDataLink = "";
                        }
                        if (message.detailResults && message.detailResults.length) {
                            object.detailResults = [];
                            for (var j = 0; j < message.detailResults.length; ++j)
                                object.detailResults[j] = $root.palexy.sherlock.v1.DetailResult.toObject(message.detailResults[j], options);
                        }
                        if (message.allBbDataLink != null && message.hasOwnProperty("allBbDataLink"))
                            object.allBbDataLink = message.allBbDataLink;
                        if (message.humanLabelLink != null && message.hasOwnProperty("humanLabelLink"))
                            object.humanLabelLink = message.humanLabelLink;
                        if (message.reconstructedMergeFileLink != null && message.hasOwnProperty("reconstructedMergeFileLink"))
                            object.reconstructedMergeFileLink = message.reconstructedMergeFileLink;
                        if (message.trackingDataLink != null && message.hasOwnProperty("trackingDataLink"))
                            object.trackingDataLink = message.trackingDataLink;
                        return object;
                    };
    
                    /**
                     * Converts this ExtractBBResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ExtractBBResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExtractBBResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExtractBBResult;
                })();
    
                v1.DetailResult = (function() {
    
                    /**
                     * Properties of a DetailResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IDetailResult
                     * @property {string|null} [personId] DetailResult personId
                     * @property {Array.<palexy.sherlock.v1.IActionSummaryEntry>|null} [actionSummaryEntries] DetailResult actionSummaryEntries
                     * @property {string|null} [gender] DetailResult gender
                     * @property {number|null} [ageRange] DetailResult ageRange
                     * @property {string|null} [startTime] DetailResult startTime
                     * @property {string|null} [endTime] DetailResult endTime
                     * @property {number|null} [durationInSeconds] DetailResult durationInSeconds
                     * @property {number|null} [totalBb] DetailResult totalBb
                     */
    
                    /**
                     * Constructs a new DetailResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DetailResult.
                     * @implements IDetailResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IDetailResult=} [properties] Properties to set
                     */
                    function DetailResult(properties) {
                        this.actionSummaryEntries = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DetailResult personId.
                     * @member {string} personId
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @instance
                     */
                    DetailResult.prototype.personId = "";
    
                    /**
                     * DetailResult actionSummaryEntries.
                     * @member {Array.<palexy.sherlock.v1.IActionSummaryEntry>} actionSummaryEntries
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @instance
                     */
                    DetailResult.prototype.actionSummaryEntries = $util.emptyArray;
    
                    /**
                     * DetailResult gender.
                     * @member {string} gender
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @instance
                     */
                    DetailResult.prototype.gender = "";
    
                    /**
                     * DetailResult ageRange.
                     * @member {number} ageRange
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @instance
                     */
                    DetailResult.prototype.ageRange = 0;
    
                    /**
                     * DetailResult startTime.
                     * @member {string} startTime
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @instance
                     */
                    DetailResult.prototype.startTime = "";
    
                    /**
                     * DetailResult endTime.
                     * @member {string} endTime
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @instance
                     */
                    DetailResult.prototype.endTime = "";
    
                    /**
                     * DetailResult durationInSeconds.
                     * @member {number} durationInSeconds
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @instance
                     */
                    DetailResult.prototype.durationInSeconds = 0;
    
                    /**
                     * DetailResult totalBb.
                     * @member {number} totalBb
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @instance
                     */
                    DetailResult.prototype.totalBb = 0;
    
                    /**
                     * Creates a new DetailResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @static
                     * @param {palexy.sherlock.v1.IDetailResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DetailResult} DetailResult instance
                     */
                    DetailResult.create = function create(properties) {
                        return new DetailResult(properties);
                    };
    
                    /**
                     * Encodes the specified DetailResult message. Does not implicitly {@link palexy.sherlock.v1.DetailResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @static
                     * @param {palexy.sherlock.v1.IDetailResult} message DetailResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DetailResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.personId != null && Object.hasOwnProperty.call(message, "personId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.personId);
                        if (message.actionSummaryEntries != null && message.actionSummaryEntries.length)
                            for (var i = 0; i < message.actionSummaryEntries.length; ++i)
                                $root.palexy.sherlock.v1.ActionSummaryEntry.encode(message.actionSummaryEntries[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.gender);
                        if (message.ageRange != null && Object.hasOwnProperty.call(message, "ageRange"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.ageRange);
                        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.startTime);
                        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.endTime);
                        if (message.durationInSeconds != null && Object.hasOwnProperty.call(message, "durationInSeconds"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.durationInSeconds);
                        if (message.totalBb != null && Object.hasOwnProperty.call(message, "totalBb"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.totalBb);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DetailResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DetailResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @static
                     * @param {palexy.sherlock.v1.IDetailResult} message DetailResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DetailResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DetailResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DetailResult} DetailResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DetailResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DetailResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.personId = reader.string();
                                break;
                            case 2:
                                if (!(message.actionSummaryEntries && message.actionSummaryEntries.length))
                                    message.actionSummaryEntries = [];
                                message.actionSummaryEntries.push($root.palexy.sherlock.v1.ActionSummaryEntry.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.gender = reader.string();
                                break;
                            case 4:
                                message.ageRange = reader.int32();
                                break;
                            case 5:
                                message.startTime = reader.string();
                                break;
                            case 6:
                                message.endTime = reader.string();
                                break;
                            case 7:
                                message.durationInSeconds = reader.int32();
                                break;
                            case 8:
                                message.totalBb = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DetailResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DetailResult} DetailResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DetailResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DetailResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DetailResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.personId != null && message.hasOwnProperty("personId"))
                            if (!$util.isString(message.personId))
                                return "personId: string expected";
                        if (message.actionSummaryEntries != null && message.hasOwnProperty("actionSummaryEntries")) {
                            if (!Array.isArray(message.actionSummaryEntries))
                                return "actionSummaryEntries: array expected";
                            for (var i = 0; i < message.actionSummaryEntries.length; ++i) {
                                var error = $root.palexy.sherlock.v1.ActionSummaryEntry.verify(message.actionSummaryEntries[i]);
                                if (error)
                                    return "actionSummaryEntries." + error;
                            }
                        }
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            if (!$util.isString(message.gender))
                                return "gender: string expected";
                        if (message.ageRange != null && message.hasOwnProperty("ageRange"))
                            if (!$util.isInteger(message.ageRange))
                                return "ageRange: integer expected";
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            if (!$util.isString(message.startTime))
                                return "startTime: string expected";
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            if (!$util.isString(message.endTime))
                                return "endTime: string expected";
                        if (message.durationInSeconds != null && message.hasOwnProperty("durationInSeconds"))
                            if (!$util.isInteger(message.durationInSeconds))
                                return "durationInSeconds: integer expected";
                        if (message.totalBb != null && message.hasOwnProperty("totalBb"))
                            if (!$util.isInteger(message.totalBb))
                                return "totalBb: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a DetailResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DetailResult} DetailResult
                     */
                    DetailResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DetailResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.DetailResult();
                        if (object.personId != null)
                            message.personId = String(object.personId);
                        if (object.actionSummaryEntries) {
                            if (!Array.isArray(object.actionSummaryEntries))
                                throw TypeError(".palexy.sherlock.v1.DetailResult.actionSummaryEntries: array expected");
                            message.actionSummaryEntries = [];
                            for (var i = 0; i < object.actionSummaryEntries.length; ++i) {
                                if (typeof object.actionSummaryEntries[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.DetailResult.actionSummaryEntries: object expected");
                                message.actionSummaryEntries[i] = $root.palexy.sherlock.v1.ActionSummaryEntry.fromObject(object.actionSummaryEntries[i]);
                            }
                        }
                        if (object.gender != null)
                            message.gender = String(object.gender);
                        if (object.ageRange != null)
                            message.ageRange = object.ageRange | 0;
                        if (object.startTime != null)
                            message.startTime = String(object.startTime);
                        if (object.endTime != null)
                            message.endTime = String(object.endTime);
                        if (object.durationInSeconds != null)
                            message.durationInSeconds = object.durationInSeconds | 0;
                        if (object.totalBb != null)
                            message.totalBb = object.totalBb | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DetailResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @static
                     * @param {palexy.sherlock.v1.DetailResult} message DetailResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DetailResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.actionSummaryEntries = [];
                        if (options.defaults) {
                            object.personId = "";
                            object.gender = "";
                            object.ageRange = 0;
                            object.startTime = "";
                            object.endTime = "";
                            object.durationInSeconds = 0;
                            object.totalBb = 0;
                        }
                        if (message.personId != null && message.hasOwnProperty("personId"))
                            object.personId = message.personId;
                        if (message.actionSummaryEntries && message.actionSummaryEntries.length) {
                            object.actionSummaryEntries = [];
                            for (var j = 0; j < message.actionSummaryEntries.length; ++j)
                                object.actionSummaryEntries[j] = $root.palexy.sherlock.v1.ActionSummaryEntry.toObject(message.actionSummaryEntries[j], options);
                        }
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            object.gender = message.gender;
                        if (message.ageRange != null && message.hasOwnProperty("ageRange"))
                            object.ageRange = message.ageRange;
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            object.startTime = message.startTime;
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            object.endTime = message.endTime;
                        if (message.durationInSeconds != null && message.hasOwnProperty("durationInSeconds"))
                            object.durationInSeconds = message.durationInSeconds;
                        if (message.totalBb != null && message.hasOwnProperty("totalBb"))
                            object.totalBb = message.totalBb;
                        return object;
                    };
    
                    /**
                     * Converts this DetailResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DetailResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DetailResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DetailResult;
                })();
    
                v1.ActionSummaryEntry = (function() {
    
                    /**
                     * Properties of an ActionSummaryEntry.
                     * @memberof palexy.sherlock.v1
                     * @interface IActionSummaryEntry
                     * @property {string|null} [actionType] ActionSummaryEntry actionType
                     * @property {Array.<string>|null} [bbImageLinks] ActionSummaryEntry bbImageLinks
                     */
    
                    /**
                     * Constructs a new ActionSummaryEntry.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an ActionSummaryEntry.
                     * @implements IActionSummaryEntry
                     * @constructor
                     * @param {palexy.sherlock.v1.IActionSummaryEntry=} [properties] Properties to set
                     */
                    function ActionSummaryEntry(properties) {
                        this.bbImageLinks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ActionSummaryEntry actionType.
                     * @member {string} actionType
                     * @memberof palexy.sherlock.v1.ActionSummaryEntry
                     * @instance
                     */
                    ActionSummaryEntry.prototype.actionType = "";
    
                    /**
                     * ActionSummaryEntry bbImageLinks.
                     * @member {Array.<string>} bbImageLinks
                     * @memberof palexy.sherlock.v1.ActionSummaryEntry
                     * @instance
                     */
                    ActionSummaryEntry.prototype.bbImageLinks = $util.emptyArray;
    
                    /**
                     * Creates a new ActionSummaryEntry instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ActionSummaryEntry
                     * @static
                     * @param {palexy.sherlock.v1.IActionSummaryEntry=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ActionSummaryEntry} ActionSummaryEntry instance
                     */
                    ActionSummaryEntry.create = function create(properties) {
                        return new ActionSummaryEntry(properties);
                    };
    
                    /**
                     * Encodes the specified ActionSummaryEntry message. Does not implicitly {@link palexy.sherlock.v1.ActionSummaryEntry.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ActionSummaryEntry
                     * @static
                     * @param {palexy.sherlock.v1.IActionSummaryEntry} message ActionSummaryEntry message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ActionSummaryEntry.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.actionType != null && Object.hasOwnProperty.call(message, "actionType"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.actionType);
                        if (message.bbImageLinks != null && message.bbImageLinks.length)
                            for (var i = 0; i < message.bbImageLinks.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.bbImageLinks[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ActionSummaryEntry message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ActionSummaryEntry.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ActionSummaryEntry
                     * @static
                     * @param {palexy.sherlock.v1.IActionSummaryEntry} message ActionSummaryEntry message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ActionSummaryEntry.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ActionSummaryEntry message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ActionSummaryEntry
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ActionSummaryEntry} ActionSummaryEntry
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ActionSummaryEntry.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ActionSummaryEntry();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.actionType = reader.string();
                                break;
                            case 2:
                                if (!(message.bbImageLinks && message.bbImageLinks.length))
                                    message.bbImageLinks = [];
                                message.bbImageLinks.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ActionSummaryEntry message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ActionSummaryEntry
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ActionSummaryEntry} ActionSummaryEntry
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ActionSummaryEntry.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ActionSummaryEntry message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ActionSummaryEntry
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ActionSummaryEntry.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.actionType != null && message.hasOwnProperty("actionType"))
                            if (!$util.isString(message.actionType))
                                return "actionType: string expected";
                        if (message.bbImageLinks != null && message.hasOwnProperty("bbImageLinks")) {
                            if (!Array.isArray(message.bbImageLinks))
                                return "bbImageLinks: array expected";
                            for (var i = 0; i < message.bbImageLinks.length; ++i)
                                if (!$util.isString(message.bbImageLinks[i]))
                                    return "bbImageLinks: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ActionSummaryEntry message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ActionSummaryEntry
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ActionSummaryEntry} ActionSummaryEntry
                     */
                    ActionSummaryEntry.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ActionSummaryEntry)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ActionSummaryEntry();
                        if (object.actionType != null)
                            message.actionType = String(object.actionType);
                        if (object.bbImageLinks) {
                            if (!Array.isArray(object.bbImageLinks))
                                throw TypeError(".palexy.sherlock.v1.ActionSummaryEntry.bbImageLinks: array expected");
                            message.bbImageLinks = [];
                            for (var i = 0; i < object.bbImageLinks.length; ++i)
                                message.bbImageLinks[i] = String(object.bbImageLinks[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ActionSummaryEntry message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ActionSummaryEntry
                     * @static
                     * @param {palexy.sherlock.v1.ActionSummaryEntry} message ActionSummaryEntry
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ActionSummaryEntry.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.bbImageLinks = [];
                        if (options.defaults)
                            object.actionType = "";
                        if (message.actionType != null && message.hasOwnProperty("actionType"))
                            object.actionType = message.actionType;
                        if (message.bbImageLinks && message.bbImageLinks.length) {
                            object.bbImageLinks = [];
                            for (var j = 0; j < message.bbImageLinks.length; ++j)
                                object.bbImageLinks[j] = message.bbImageLinks[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ActionSummaryEntry to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ActionSummaryEntry
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ActionSummaryEntry.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ActionSummaryEntry;
                })();
    
                v1.AddtionalActionInfo = (function() {
    
                    /**
                     * Properties of an AddtionalActionInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IAddtionalActionInfo
                     * @property {string|null} [cameraId] AddtionalActionInfo cameraId
                     * @property {number|null} [coordinateX] AddtionalActionInfo coordinateX
                     * @property {number|null} [coordinateY] AddtionalActionInfo coordinateY
                     * @property {string|null} [recordingDateTime] AddtionalActionInfo recordingDateTime
                     */
    
                    /**
                     * Constructs a new AddtionalActionInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an AddtionalActionInfo.
                     * @implements IAddtionalActionInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IAddtionalActionInfo=} [properties] Properties to set
                     */
                    function AddtionalActionInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AddtionalActionInfo cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @instance
                     */
                    AddtionalActionInfo.prototype.cameraId = "";
    
                    /**
                     * AddtionalActionInfo coordinateX.
                     * @member {number} coordinateX
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @instance
                     */
                    AddtionalActionInfo.prototype.coordinateX = 0;
    
                    /**
                     * AddtionalActionInfo coordinateY.
                     * @member {number} coordinateY
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @instance
                     */
                    AddtionalActionInfo.prototype.coordinateY = 0;
    
                    /**
                     * AddtionalActionInfo recordingDateTime.
                     * @member {string} recordingDateTime
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @instance
                     */
                    AddtionalActionInfo.prototype.recordingDateTime = "";
    
                    /**
                     * Creates a new AddtionalActionInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @static
                     * @param {palexy.sherlock.v1.IAddtionalActionInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.AddtionalActionInfo} AddtionalActionInfo instance
                     */
                    AddtionalActionInfo.create = function create(properties) {
                        return new AddtionalActionInfo(properties);
                    };
    
                    /**
                     * Encodes the specified AddtionalActionInfo message. Does not implicitly {@link palexy.sherlock.v1.AddtionalActionInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @static
                     * @param {palexy.sherlock.v1.IAddtionalActionInfo} message AddtionalActionInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AddtionalActionInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cameraId);
                        if (message.coordinateX != null && Object.hasOwnProperty.call(message, "coordinateX"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.coordinateX);
                        if (message.coordinateY != null && Object.hasOwnProperty.call(message, "coordinateY"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.coordinateY);
                        if (message.recordingDateTime != null && Object.hasOwnProperty.call(message, "recordingDateTime"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.recordingDateTime);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AddtionalActionInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.AddtionalActionInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @static
                     * @param {palexy.sherlock.v1.IAddtionalActionInfo} message AddtionalActionInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AddtionalActionInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AddtionalActionInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.AddtionalActionInfo} AddtionalActionInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AddtionalActionInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.AddtionalActionInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraId = reader.string();
                                break;
                            case 2:
                                message.coordinateX = reader.float();
                                break;
                            case 3:
                                message.coordinateY = reader.float();
                                break;
                            case 4:
                                message.recordingDateTime = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AddtionalActionInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.AddtionalActionInfo} AddtionalActionInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AddtionalActionInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AddtionalActionInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AddtionalActionInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.coordinateX != null && message.hasOwnProperty("coordinateX"))
                            if (typeof message.coordinateX !== "number")
                                return "coordinateX: number expected";
                        if (message.coordinateY != null && message.hasOwnProperty("coordinateY"))
                            if (typeof message.coordinateY !== "number")
                                return "coordinateY: number expected";
                        if (message.recordingDateTime != null && message.hasOwnProperty("recordingDateTime"))
                            if (!$util.isString(message.recordingDateTime))
                                return "recordingDateTime: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an AddtionalActionInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.AddtionalActionInfo} AddtionalActionInfo
                     */
                    AddtionalActionInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.AddtionalActionInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.AddtionalActionInfo();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.coordinateX != null)
                            message.coordinateX = Number(object.coordinateX);
                        if (object.coordinateY != null)
                            message.coordinateY = Number(object.coordinateY);
                        if (object.recordingDateTime != null)
                            message.recordingDateTime = String(object.recordingDateTime);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AddtionalActionInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @static
                     * @param {palexy.sherlock.v1.AddtionalActionInfo} message AddtionalActionInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AddtionalActionInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.cameraId = "";
                            object.coordinateX = 0;
                            object.coordinateY = 0;
                            object.recordingDateTime = "";
                        }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.coordinateX != null && message.hasOwnProperty("coordinateX"))
                            object.coordinateX = options.json && !isFinite(message.coordinateX) ? String(message.coordinateX) : message.coordinateX;
                        if (message.coordinateY != null && message.hasOwnProperty("coordinateY"))
                            object.coordinateY = options.json && !isFinite(message.coordinateY) ? String(message.coordinateY) : message.coordinateY;
                        if (message.recordingDateTime != null && message.hasOwnProperty("recordingDateTime"))
                            object.recordingDateTime = message.recordingDateTime;
                        return object;
                    };
    
                    /**
                     * Converts this AddtionalActionInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.AddtionalActionInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AddtionalActionInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AddtionalActionInfo;
                })();
    
                v1.MergeAccuracyResult = (function() {
    
                    /**
                     * Properties of a MergeAccuracyResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IMergeAccuracyResult
                     * @property {Array.<palexy.sherlock.v1.IMergeAccuracyResultItem>|null} [mergeAccuracyResultItems] MergeAccuracyResult mergeAccuracyResultItems
                     * @property {number|null} [avgPrecision] MergeAccuracyResult avgPrecision
                     * @property {number|null} [avgRecall] MergeAccuracyResult avgRecall
                     * @property {number|null} [avgF1] MergeAccuracyResult avgF1
                     */
    
                    /**
                     * Constructs a new MergeAccuracyResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a MergeAccuracyResult.
                     * @implements IMergeAccuracyResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IMergeAccuracyResult=} [properties] Properties to set
                     */
                    function MergeAccuracyResult(properties) {
                        this.mergeAccuracyResultItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MergeAccuracyResult mergeAccuracyResultItems.
                     * @member {Array.<palexy.sherlock.v1.IMergeAccuracyResultItem>} mergeAccuracyResultItems
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @instance
                     */
                    MergeAccuracyResult.prototype.mergeAccuracyResultItems = $util.emptyArray;
    
                    /**
                     * MergeAccuracyResult avgPrecision.
                     * @member {number} avgPrecision
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @instance
                     */
                    MergeAccuracyResult.prototype.avgPrecision = 0;
    
                    /**
                     * MergeAccuracyResult avgRecall.
                     * @member {number} avgRecall
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @instance
                     */
                    MergeAccuracyResult.prototype.avgRecall = 0;
    
                    /**
                     * MergeAccuracyResult avgF1.
                     * @member {number} avgF1
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @instance
                     */
                    MergeAccuracyResult.prototype.avgF1 = 0;
    
                    /**
                     * Creates a new MergeAccuracyResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @static
                     * @param {palexy.sherlock.v1.IMergeAccuracyResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.MergeAccuracyResult} MergeAccuracyResult instance
                     */
                    MergeAccuracyResult.create = function create(properties) {
                        return new MergeAccuracyResult(properties);
                    };
    
                    /**
                     * Encodes the specified MergeAccuracyResult message. Does not implicitly {@link palexy.sherlock.v1.MergeAccuracyResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @static
                     * @param {palexy.sherlock.v1.IMergeAccuracyResult} message MergeAccuracyResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergeAccuracyResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergeAccuracyResultItems != null && message.mergeAccuracyResultItems.length)
                            for (var i = 0; i < message.mergeAccuracyResultItems.length; ++i)
                                $root.palexy.sherlock.v1.MergeAccuracyResultItem.encode(message.mergeAccuracyResultItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.avgPrecision != null && Object.hasOwnProperty.call(message, "avgPrecision"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.avgPrecision);
                        if (message.avgRecall != null && Object.hasOwnProperty.call(message, "avgRecall"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.avgRecall);
                        if (message.avgF1 != null && Object.hasOwnProperty.call(message, "avgF1"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.avgF1);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MergeAccuracyResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.MergeAccuracyResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @static
                     * @param {palexy.sherlock.v1.IMergeAccuracyResult} message MergeAccuracyResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergeAccuracyResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MergeAccuracyResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.MergeAccuracyResult} MergeAccuracyResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergeAccuracyResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.MergeAccuracyResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.mergeAccuracyResultItems && message.mergeAccuracyResultItems.length))
                                    message.mergeAccuracyResultItems = [];
                                message.mergeAccuracyResultItems.push($root.palexy.sherlock.v1.MergeAccuracyResultItem.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.avgPrecision = reader.float();
                                break;
                            case 3:
                                message.avgRecall = reader.float();
                                break;
                            case 4:
                                message.avgF1 = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MergeAccuracyResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.MergeAccuracyResult} MergeAccuracyResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergeAccuracyResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MergeAccuracyResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MergeAccuracyResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergeAccuracyResultItems != null && message.hasOwnProperty("mergeAccuracyResultItems")) {
                            if (!Array.isArray(message.mergeAccuracyResultItems))
                                return "mergeAccuracyResultItems: array expected";
                            for (var i = 0; i < message.mergeAccuracyResultItems.length; ++i) {
                                var error = $root.palexy.sherlock.v1.MergeAccuracyResultItem.verify(message.mergeAccuracyResultItems[i]);
                                if (error)
                                    return "mergeAccuracyResultItems." + error;
                            }
                        }
                        if (message.avgPrecision != null && message.hasOwnProperty("avgPrecision"))
                            if (typeof message.avgPrecision !== "number")
                                return "avgPrecision: number expected";
                        if (message.avgRecall != null && message.hasOwnProperty("avgRecall"))
                            if (typeof message.avgRecall !== "number")
                                return "avgRecall: number expected";
                        if (message.avgF1 != null && message.hasOwnProperty("avgF1"))
                            if (typeof message.avgF1 !== "number")
                                return "avgF1: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a MergeAccuracyResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.MergeAccuracyResult} MergeAccuracyResult
                     */
                    MergeAccuracyResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.MergeAccuracyResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.MergeAccuracyResult();
                        if (object.mergeAccuracyResultItems) {
                            if (!Array.isArray(object.mergeAccuracyResultItems))
                                throw TypeError(".palexy.sherlock.v1.MergeAccuracyResult.mergeAccuracyResultItems: array expected");
                            message.mergeAccuracyResultItems = [];
                            for (var i = 0; i < object.mergeAccuracyResultItems.length; ++i) {
                                if (typeof object.mergeAccuracyResultItems[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.MergeAccuracyResult.mergeAccuracyResultItems: object expected");
                                message.mergeAccuracyResultItems[i] = $root.palexy.sherlock.v1.MergeAccuracyResultItem.fromObject(object.mergeAccuracyResultItems[i]);
                            }
                        }
                        if (object.avgPrecision != null)
                            message.avgPrecision = Number(object.avgPrecision);
                        if (object.avgRecall != null)
                            message.avgRecall = Number(object.avgRecall);
                        if (object.avgF1 != null)
                            message.avgF1 = Number(object.avgF1);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MergeAccuracyResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @static
                     * @param {palexy.sherlock.v1.MergeAccuracyResult} message MergeAccuracyResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MergeAccuracyResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.mergeAccuracyResultItems = [];
                        if (options.defaults) {
                            object.avgPrecision = 0;
                            object.avgRecall = 0;
                            object.avgF1 = 0;
                        }
                        if (message.mergeAccuracyResultItems && message.mergeAccuracyResultItems.length) {
                            object.mergeAccuracyResultItems = [];
                            for (var j = 0; j < message.mergeAccuracyResultItems.length; ++j)
                                object.mergeAccuracyResultItems[j] = $root.palexy.sherlock.v1.MergeAccuracyResultItem.toObject(message.mergeAccuracyResultItems[j], options);
                        }
                        if (message.avgPrecision != null && message.hasOwnProperty("avgPrecision"))
                            object.avgPrecision = options.json && !isFinite(message.avgPrecision) ? String(message.avgPrecision) : message.avgPrecision;
                        if (message.avgRecall != null && message.hasOwnProperty("avgRecall"))
                            object.avgRecall = options.json && !isFinite(message.avgRecall) ? String(message.avgRecall) : message.avgRecall;
                        if (message.avgF1 != null && message.hasOwnProperty("avgF1"))
                            object.avgF1 = options.json && !isFinite(message.avgF1) ? String(message.avgF1) : message.avgF1;
                        return object;
                    };
    
                    /**
                     * Converts this MergeAccuracyResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.MergeAccuracyResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MergeAccuracyResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MergeAccuracyResult;
                })();
    
                v1.MergeAccuracyResultItem = (function() {
    
                    /**
                     * Properties of a MergeAccuracyResultItem.
                     * @memberof palexy.sherlock.v1
                     * @interface IMergeAccuracyResultItem
                     * @property {palexy.sherlock.v1.IPredictedAndLabelItem|null} [predictedAndLabelItem] MergeAccuracyResultItem predictedAndLabelItem
                     * @property {number|null} [precision] MergeAccuracyResultItem precision
                     * @property {number|null} [recall] MergeAccuracyResultItem recall
                     * @property {number|null} [f1] MergeAccuracyResultItem f1
                     */
    
                    /**
                     * Constructs a new MergeAccuracyResultItem.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a MergeAccuracyResultItem.
                     * @implements IMergeAccuracyResultItem
                     * @constructor
                     * @param {palexy.sherlock.v1.IMergeAccuracyResultItem=} [properties] Properties to set
                     */
                    function MergeAccuracyResultItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MergeAccuracyResultItem predictedAndLabelItem.
                     * @member {palexy.sherlock.v1.IPredictedAndLabelItem|null|undefined} predictedAndLabelItem
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @instance
                     */
                    MergeAccuracyResultItem.prototype.predictedAndLabelItem = null;
    
                    /**
                     * MergeAccuracyResultItem precision.
                     * @member {number} precision
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @instance
                     */
                    MergeAccuracyResultItem.prototype.precision = 0;
    
                    /**
                     * MergeAccuracyResultItem recall.
                     * @member {number} recall
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @instance
                     */
                    MergeAccuracyResultItem.prototype.recall = 0;
    
                    /**
                     * MergeAccuracyResultItem f1.
                     * @member {number} f1
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @instance
                     */
                    MergeAccuracyResultItem.prototype.f1 = 0;
    
                    /**
                     * Creates a new MergeAccuracyResultItem instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @static
                     * @param {palexy.sherlock.v1.IMergeAccuracyResultItem=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.MergeAccuracyResultItem} MergeAccuracyResultItem instance
                     */
                    MergeAccuracyResultItem.create = function create(properties) {
                        return new MergeAccuracyResultItem(properties);
                    };
    
                    /**
                     * Encodes the specified MergeAccuracyResultItem message. Does not implicitly {@link palexy.sherlock.v1.MergeAccuracyResultItem.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @static
                     * @param {palexy.sherlock.v1.IMergeAccuracyResultItem} message MergeAccuracyResultItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergeAccuracyResultItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.predictedAndLabelItem != null && Object.hasOwnProperty.call(message, "predictedAndLabelItem"))
                            $root.palexy.sherlock.v1.PredictedAndLabelItem.encode(message.predictedAndLabelItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.precision != null && Object.hasOwnProperty.call(message, "precision"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.precision);
                        if (message.recall != null && Object.hasOwnProperty.call(message, "recall"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.recall);
                        if (message.f1 != null && Object.hasOwnProperty.call(message, "f1"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.f1);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MergeAccuracyResultItem message, length delimited. Does not implicitly {@link palexy.sherlock.v1.MergeAccuracyResultItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @static
                     * @param {palexy.sherlock.v1.IMergeAccuracyResultItem} message MergeAccuracyResultItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergeAccuracyResultItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MergeAccuracyResultItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.MergeAccuracyResultItem} MergeAccuracyResultItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergeAccuracyResultItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.MergeAccuracyResultItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.predictedAndLabelItem = $root.palexy.sherlock.v1.PredictedAndLabelItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.precision = reader.float();
                                break;
                            case 3:
                                message.recall = reader.float();
                                break;
                            case 4:
                                message.f1 = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MergeAccuracyResultItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.MergeAccuracyResultItem} MergeAccuracyResultItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergeAccuracyResultItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MergeAccuracyResultItem message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MergeAccuracyResultItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.predictedAndLabelItem != null && message.hasOwnProperty("predictedAndLabelItem")) {
                            var error = $root.palexy.sherlock.v1.PredictedAndLabelItem.verify(message.predictedAndLabelItem);
                            if (error)
                                return "predictedAndLabelItem." + error;
                        }
                        if (message.precision != null && message.hasOwnProperty("precision"))
                            if (typeof message.precision !== "number")
                                return "precision: number expected";
                        if (message.recall != null && message.hasOwnProperty("recall"))
                            if (typeof message.recall !== "number")
                                return "recall: number expected";
                        if (message.f1 != null && message.hasOwnProperty("f1"))
                            if (typeof message.f1 !== "number")
                                return "f1: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a MergeAccuracyResultItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.MergeAccuracyResultItem} MergeAccuracyResultItem
                     */
                    MergeAccuracyResultItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.MergeAccuracyResultItem)
                            return object;
                        var message = new $root.palexy.sherlock.v1.MergeAccuracyResultItem();
                        if (object.predictedAndLabelItem != null) {
                            if (typeof object.predictedAndLabelItem !== "object")
                                throw TypeError(".palexy.sherlock.v1.MergeAccuracyResultItem.predictedAndLabelItem: object expected");
                            message.predictedAndLabelItem = $root.palexy.sherlock.v1.PredictedAndLabelItem.fromObject(object.predictedAndLabelItem);
                        }
                        if (object.precision != null)
                            message.precision = Number(object.precision);
                        if (object.recall != null)
                            message.recall = Number(object.recall);
                        if (object.f1 != null)
                            message.f1 = Number(object.f1);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MergeAccuracyResultItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @static
                     * @param {palexy.sherlock.v1.MergeAccuracyResultItem} message MergeAccuracyResultItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MergeAccuracyResultItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.predictedAndLabelItem = null;
                            object.precision = 0;
                            object.recall = 0;
                            object.f1 = 0;
                        }
                        if (message.predictedAndLabelItem != null && message.hasOwnProperty("predictedAndLabelItem"))
                            object.predictedAndLabelItem = $root.palexy.sherlock.v1.PredictedAndLabelItem.toObject(message.predictedAndLabelItem, options);
                        if (message.precision != null && message.hasOwnProperty("precision"))
                            object.precision = options.json && !isFinite(message.precision) ? String(message.precision) : message.precision;
                        if (message.recall != null && message.hasOwnProperty("recall"))
                            object.recall = options.json && !isFinite(message.recall) ? String(message.recall) : message.recall;
                        if (message.f1 != null && message.hasOwnProperty("f1"))
                            object.f1 = options.json && !isFinite(message.f1) ? String(message.f1) : message.f1;
                        return object;
                    };
    
                    /**
                     * Converts this MergeAccuracyResultItem to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.MergeAccuracyResultItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MergeAccuracyResultItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MergeAccuracyResultItem;
                })();
    
                v1.RerunMergeConfigAdvanceInfo = (function() {
    
                    /**
                     * Properties of a RerunMergeConfigAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IRerunMergeConfigAdvanceInfo
                     * @property {Array.<palexy.sherlock.v1.IRerunMergeConfigDebugData>|null} [debugData] RerunMergeConfigAdvanceInfo debugData
                     * @property {palexy.streaming.v1.IMergingConfig|null} [mergingConfig] RerunMergeConfigAdvanceInfo mergingConfig
                     */
    
                    /**
                     * Constructs a new RerunMergeConfigAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a RerunMergeConfigAdvanceInfo.
                     * @implements IRerunMergeConfigAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IRerunMergeConfigAdvanceInfo=} [properties] Properties to set
                     */
                    function RerunMergeConfigAdvanceInfo(properties) {
                        this.debugData = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RerunMergeConfigAdvanceInfo debugData.
                     * @member {Array.<palexy.sherlock.v1.IRerunMergeConfigDebugData>} debugData
                     * @memberof palexy.sherlock.v1.RerunMergeConfigAdvanceInfo
                     * @instance
                     */
                    RerunMergeConfigAdvanceInfo.prototype.debugData = $util.emptyArray;
    
                    /**
                     * RerunMergeConfigAdvanceInfo mergingConfig.
                     * @member {palexy.streaming.v1.IMergingConfig|null|undefined} mergingConfig
                     * @memberof palexy.sherlock.v1.RerunMergeConfigAdvanceInfo
                     * @instance
                     */
                    RerunMergeConfigAdvanceInfo.prototype.mergingConfig = null;
    
                    /**
                     * Creates a new RerunMergeConfigAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.RerunMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IRerunMergeConfigAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.RerunMergeConfigAdvanceInfo} RerunMergeConfigAdvanceInfo instance
                     */
                    RerunMergeConfigAdvanceInfo.create = function create(properties) {
                        return new RerunMergeConfigAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified RerunMergeConfigAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.RerunMergeConfigAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.RerunMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IRerunMergeConfigAdvanceInfo} message RerunMergeConfigAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunMergeConfigAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.debugData != null && message.debugData.length)
                            for (var i = 0; i < message.debugData.length; ++i)
                                $root.palexy.sherlock.v1.RerunMergeConfigDebugData.encode(message.debugData[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.mergingConfig != null && Object.hasOwnProperty.call(message, "mergingConfig"))
                            $root.palexy.streaming.v1.MergingConfig.encode(message.mergingConfig, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RerunMergeConfigAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.RerunMergeConfigAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.RerunMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IRerunMergeConfigAdvanceInfo} message RerunMergeConfigAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunMergeConfigAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RerunMergeConfigAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.RerunMergeConfigAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.RerunMergeConfigAdvanceInfo} RerunMergeConfigAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunMergeConfigAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.RerunMergeConfigAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.debugData && message.debugData.length))
                                    message.debugData = [];
                                message.debugData.push($root.palexy.sherlock.v1.RerunMergeConfigDebugData.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.mergingConfig = $root.palexy.streaming.v1.MergingConfig.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RerunMergeConfigAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.RerunMergeConfigAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.RerunMergeConfigAdvanceInfo} RerunMergeConfigAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunMergeConfigAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RerunMergeConfigAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.RerunMergeConfigAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RerunMergeConfigAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.debugData != null && message.hasOwnProperty("debugData")) {
                            if (!Array.isArray(message.debugData))
                                return "debugData: array expected";
                            for (var i = 0; i < message.debugData.length; ++i) {
                                var error = $root.palexy.sherlock.v1.RerunMergeConfigDebugData.verify(message.debugData[i]);
                                if (error)
                                    return "debugData." + error;
                            }
                        }
                        if (message.mergingConfig != null && message.hasOwnProperty("mergingConfig")) {
                            var error = $root.palexy.streaming.v1.MergingConfig.verify(message.mergingConfig);
                            if (error)
                                return "mergingConfig." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a RerunMergeConfigAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.RerunMergeConfigAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.RerunMergeConfigAdvanceInfo} RerunMergeConfigAdvanceInfo
                     */
                    RerunMergeConfigAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.RerunMergeConfigAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.RerunMergeConfigAdvanceInfo();
                        if (object.debugData) {
                            if (!Array.isArray(object.debugData))
                                throw TypeError(".palexy.sherlock.v1.RerunMergeConfigAdvanceInfo.debugData: array expected");
                            message.debugData = [];
                            for (var i = 0; i < object.debugData.length; ++i) {
                                if (typeof object.debugData[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.RerunMergeConfigAdvanceInfo.debugData: object expected");
                                message.debugData[i] = $root.palexy.sherlock.v1.RerunMergeConfigDebugData.fromObject(object.debugData[i]);
                            }
                        }
                        if (object.mergingConfig != null) {
                            if (typeof object.mergingConfig !== "object")
                                throw TypeError(".palexy.sherlock.v1.RerunMergeConfigAdvanceInfo.mergingConfig: object expected");
                            message.mergingConfig = $root.palexy.streaming.v1.MergingConfig.fromObject(object.mergingConfig);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RerunMergeConfigAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.RerunMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.RerunMergeConfigAdvanceInfo} message RerunMergeConfigAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RerunMergeConfigAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.debugData = [];
                        if (options.defaults)
                            object.mergingConfig = null;
                        if (message.debugData && message.debugData.length) {
                            object.debugData = [];
                            for (var j = 0; j < message.debugData.length; ++j)
                                object.debugData[j] = $root.palexy.sherlock.v1.RerunMergeConfigDebugData.toObject(message.debugData[j], options);
                        }
                        if (message.mergingConfig != null && message.hasOwnProperty("mergingConfig"))
                            object.mergingConfig = $root.palexy.streaming.v1.MergingConfig.toObject(message.mergingConfig, options);
                        return object;
                    };
    
                    /**
                     * Converts this RerunMergeConfigAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.RerunMergeConfigAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RerunMergeConfigAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RerunMergeConfigAdvanceInfo;
                })();
    
                v1.RerunMergeConfigDebugData = (function() {
    
                    /**
                     * Properties of a RerunMergeConfigDebugData.
                     * @memberof palexy.sherlock.v1
                     * @interface IRerunMergeConfigDebugData
                     * @property {string|null} [displayName] RerunMergeConfigDebugData displayName
                     * @property {palexy.sherlock.v1.IExtractBBResult|null} [extractBbResult] RerunMergeConfigDebugData extractBbResult
                     */
    
                    /**
                     * Constructs a new RerunMergeConfigDebugData.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a RerunMergeConfigDebugData.
                     * @implements IRerunMergeConfigDebugData
                     * @constructor
                     * @param {palexy.sherlock.v1.IRerunMergeConfigDebugData=} [properties] Properties to set
                     */
                    function RerunMergeConfigDebugData(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RerunMergeConfigDebugData displayName.
                     * @member {string} displayName
                     * @memberof palexy.sherlock.v1.RerunMergeConfigDebugData
                     * @instance
                     */
                    RerunMergeConfigDebugData.prototype.displayName = "";
    
                    /**
                     * RerunMergeConfigDebugData extractBbResult.
                     * @member {palexy.sherlock.v1.IExtractBBResult|null|undefined} extractBbResult
                     * @memberof palexy.sherlock.v1.RerunMergeConfigDebugData
                     * @instance
                     */
                    RerunMergeConfigDebugData.prototype.extractBbResult = null;
    
                    /**
                     * Creates a new RerunMergeConfigDebugData instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.RerunMergeConfigDebugData
                     * @static
                     * @param {palexy.sherlock.v1.IRerunMergeConfigDebugData=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.RerunMergeConfigDebugData} RerunMergeConfigDebugData instance
                     */
                    RerunMergeConfigDebugData.create = function create(properties) {
                        return new RerunMergeConfigDebugData(properties);
                    };
    
                    /**
                     * Encodes the specified RerunMergeConfigDebugData message. Does not implicitly {@link palexy.sherlock.v1.RerunMergeConfigDebugData.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.RerunMergeConfigDebugData
                     * @static
                     * @param {palexy.sherlock.v1.IRerunMergeConfigDebugData} message RerunMergeConfigDebugData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunMergeConfigDebugData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.displayName);
                        if (message.extractBbResult != null && Object.hasOwnProperty.call(message, "extractBbResult"))
                            $root.palexy.sherlock.v1.ExtractBBResult.encode(message.extractBbResult, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RerunMergeConfigDebugData message, length delimited. Does not implicitly {@link palexy.sherlock.v1.RerunMergeConfigDebugData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.RerunMergeConfigDebugData
                     * @static
                     * @param {palexy.sherlock.v1.IRerunMergeConfigDebugData} message RerunMergeConfigDebugData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunMergeConfigDebugData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RerunMergeConfigDebugData message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.RerunMergeConfigDebugData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.RerunMergeConfigDebugData} RerunMergeConfigDebugData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunMergeConfigDebugData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.RerunMergeConfigDebugData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.displayName = reader.string();
                                break;
                            case 2:
                                message.extractBbResult = $root.palexy.sherlock.v1.ExtractBBResult.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RerunMergeConfigDebugData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.RerunMergeConfigDebugData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.RerunMergeConfigDebugData} RerunMergeConfigDebugData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunMergeConfigDebugData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RerunMergeConfigDebugData message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.RerunMergeConfigDebugData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RerunMergeConfigDebugData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        if (message.extractBbResult != null && message.hasOwnProperty("extractBbResult")) {
                            var error = $root.palexy.sherlock.v1.ExtractBBResult.verify(message.extractBbResult);
                            if (error)
                                return "extractBbResult." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a RerunMergeConfigDebugData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.RerunMergeConfigDebugData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.RerunMergeConfigDebugData} RerunMergeConfigDebugData
                     */
                    RerunMergeConfigDebugData.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.RerunMergeConfigDebugData)
                            return object;
                        var message = new $root.palexy.sherlock.v1.RerunMergeConfigDebugData();
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        if (object.extractBbResult != null) {
                            if (typeof object.extractBbResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.RerunMergeConfigDebugData.extractBbResult: object expected");
                            message.extractBbResult = $root.palexy.sherlock.v1.ExtractBBResult.fromObject(object.extractBbResult);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RerunMergeConfigDebugData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.RerunMergeConfigDebugData
                     * @static
                     * @param {palexy.sherlock.v1.RerunMergeConfigDebugData} message RerunMergeConfigDebugData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RerunMergeConfigDebugData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.displayName = "";
                            object.extractBbResult = null;
                        }
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        if (message.extractBbResult != null && message.hasOwnProperty("extractBbResult"))
                            object.extractBbResult = $root.palexy.sherlock.v1.ExtractBBResult.toObject(message.extractBbResult, options);
                        return object;
                    };
    
                    /**
                     * Converts this RerunMergeConfigDebugData to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.RerunMergeConfigDebugData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RerunMergeConfigDebugData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RerunMergeConfigDebugData;
                })();
    
                v1.RerunMergeConfigResult = (function() {
    
                    /**
                     * Properties of a RerunMergeConfigResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IRerunMergeConfigResult
                     * @property {Array.<string>|null} [mergingResultLinks] RerunMergeConfigResult mergingResultLinks
                     */
    
                    /**
                     * Constructs a new RerunMergeConfigResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a RerunMergeConfigResult.
                     * @implements IRerunMergeConfigResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IRerunMergeConfigResult=} [properties] Properties to set
                     */
                    function RerunMergeConfigResult(properties) {
                        this.mergingResultLinks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RerunMergeConfigResult mergingResultLinks.
                     * @member {Array.<string>} mergingResultLinks
                     * @memberof palexy.sherlock.v1.RerunMergeConfigResult
                     * @instance
                     */
                    RerunMergeConfigResult.prototype.mergingResultLinks = $util.emptyArray;
    
                    /**
                     * Creates a new RerunMergeConfigResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.RerunMergeConfigResult
                     * @static
                     * @param {palexy.sherlock.v1.IRerunMergeConfigResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.RerunMergeConfigResult} RerunMergeConfigResult instance
                     */
                    RerunMergeConfigResult.create = function create(properties) {
                        return new RerunMergeConfigResult(properties);
                    };
    
                    /**
                     * Encodes the specified RerunMergeConfigResult message. Does not implicitly {@link palexy.sherlock.v1.RerunMergeConfigResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.RerunMergeConfigResult
                     * @static
                     * @param {palexy.sherlock.v1.IRerunMergeConfigResult} message RerunMergeConfigResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunMergeConfigResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergingResultLinks != null && message.mergingResultLinks.length)
                            for (var i = 0; i < message.mergingResultLinks.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.mergingResultLinks[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RerunMergeConfigResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.RerunMergeConfigResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.RerunMergeConfigResult
                     * @static
                     * @param {palexy.sherlock.v1.IRerunMergeConfigResult} message RerunMergeConfigResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunMergeConfigResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RerunMergeConfigResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.RerunMergeConfigResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.RerunMergeConfigResult} RerunMergeConfigResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunMergeConfigResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.RerunMergeConfigResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.mergingResultLinks && message.mergingResultLinks.length))
                                    message.mergingResultLinks = [];
                                message.mergingResultLinks.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RerunMergeConfigResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.RerunMergeConfigResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.RerunMergeConfigResult} RerunMergeConfigResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunMergeConfigResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RerunMergeConfigResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.RerunMergeConfigResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RerunMergeConfigResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergingResultLinks != null && message.hasOwnProperty("mergingResultLinks")) {
                            if (!Array.isArray(message.mergingResultLinks))
                                return "mergingResultLinks: array expected";
                            for (var i = 0; i < message.mergingResultLinks.length; ++i)
                                if (!$util.isString(message.mergingResultLinks[i]))
                                    return "mergingResultLinks: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a RerunMergeConfigResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.RerunMergeConfigResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.RerunMergeConfigResult} RerunMergeConfigResult
                     */
                    RerunMergeConfigResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.RerunMergeConfigResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.RerunMergeConfigResult();
                        if (object.mergingResultLinks) {
                            if (!Array.isArray(object.mergingResultLinks))
                                throw TypeError(".palexy.sherlock.v1.RerunMergeConfigResult.mergingResultLinks: array expected");
                            message.mergingResultLinks = [];
                            for (var i = 0; i < object.mergingResultLinks.length; ++i)
                                message.mergingResultLinks[i] = String(object.mergingResultLinks[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RerunMergeConfigResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.RerunMergeConfigResult
                     * @static
                     * @param {palexy.sherlock.v1.RerunMergeConfigResult} message RerunMergeConfigResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RerunMergeConfigResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.mergingResultLinks = [];
                        if (message.mergingResultLinks && message.mergingResultLinks.length) {
                            object.mergingResultLinks = [];
                            for (var j = 0; j < message.mergingResultLinks.length; ++j)
                                object.mergingResultLinks[j] = message.mergingResultLinks[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this RerunMergeConfigResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.RerunMergeConfigResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RerunMergeConfigResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RerunMergeConfigResult;
                })();
    
                v1.ScanMergeConfigAdvanceInfo = (function() {
    
                    /**
                     * Properties of a ScanMergeConfigAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @interface IScanMergeConfigAdvanceInfo
                     * @property {Array.<palexy.sherlock.v1.IScanMergeConfigDebugData>|null} [debugData] ScanMergeConfigAdvanceInfo debugData
                     * @property {palexy.streaming.v1.IMergingConfig|null} [defaultConfig] ScanMergeConfigAdvanceInfo defaultConfig
                     * @property {Array.<palexy.sherlock.v1.IParameter>|null} [scanParameters] ScanMergeConfigAdvanceInfo scanParameters
                     * @property {palexy.sherlock.v1.IScanMergeConfigState|null} [state] ScanMergeConfigAdvanceInfo state
                     */
    
                    /**
                     * Constructs a new ScanMergeConfigAdvanceInfo.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ScanMergeConfigAdvanceInfo.
                     * @implements IScanMergeConfigAdvanceInfo
                     * @constructor
                     * @param {palexy.sherlock.v1.IScanMergeConfigAdvanceInfo=} [properties] Properties to set
                     */
                    function ScanMergeConfigAdvanceInfo(properties) {
                        this.debugData = [];
                        this.scanParameters = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ScanMergeConfigAdvanceInfo debugData.
                     * @member {Array.<palexy.sherlock.v1.IScanMergeConfigDebugData>} debugData
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @instance
                     */
                    ScanMergeConfigAdvanceInfo.prototype.debugData = $util.emptyArray;
    
                    /**
                     * ScanMergeConfigAdvanceInfo defaultConfig.
                     * @member {palexy.streaming.v1.IMergingConfig|null|undefined} defaultConfig
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @instance
                     */
                    ScanMergeConfigAdvanceInfo.prototype.defaultConfig = null;
    
                    /**
                     * ScanMergeConfigAdvanceInfo scanParameters.
                     * @member {Array.<palexy.sherlock.v1.IParameter>} scanParameters
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @instance
                     */
                    ScanMergeConfigAdvanceInfo.prototype.scanParameters = $util.emptyArray;
    
                    /**
                     * ScanMergeConfigAdvanceInfo state.
                     * @member {palexy.sherlock.v1.IScanMergeConfigState|null|undefined} state
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @instance
                     */
                    ScanMergeConfigAdvanceInfo.prototype.state = null;
    
                    /**
                     * Creates a new ScanMergeConfigAdvanceInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigAdvanceInfo=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ScanMergeConfigAdvanceInfo} ScanMergeConfigAdvanceInfo instance
                     */
                    ScanMergeConfigAdvanceInfo.create = function create(properties) {
                        return new ScanMergeConfigAdvanceInfo(properties);
                    };
    
                    /**
                     * Encodes the specified ScanMergeConfigAdvanceInfo message. Does not implicitly {@link palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigAdvanceInfo} message ScanMergeConfigAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScanMergeConfigAdvanceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.debugData != null && message.debugData.length)
                            for (var i = 0; i < message.debugData.length; ++i)
                                $root.palexy.sherlock.v1.ScanMergeConfigDebugData.encode(message.debugData[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.defaultConfig != null && Object.hasOwnProperty.call(message, "defaultConfig"))
                            $root.palexy.streaming.v1.MergingConfig.encode(message.defaultConfig, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.scanParameters != null && message.scanParameters.length)
                            for (var i = 0; i < message.scanParameters.length; ++i)
                                $root.palexy.sherlock.v1.Parameter.encode(message.scanParameters[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            $root.palexy.sherlock.v1.ScanMergeConfigState.encode(message.state, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ScanMergeConfigAdvanceInfo message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigAdvanceInfo} message ScanMergeConfigAdvanceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScanMergeConfigAdvanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ScanMergeConfigAdvanceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ScanMergeConfigAdvanceInfo} ScanMergeConfigAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScanMergeConfigAdvanceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ScanMergeConfigAdvanceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.debugData && message.debugData.length))
                                    message.debugData = [];
                                message.debugData.push($root.palexy.sherlock.v1.ScanMergeConfigDebugData.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.defaultConfig = $root.palexy.streaming.v1.MergingConfig.decode(reader, reader.uint32());
                                break;
                            case 3:
                                if (!(message.scanParameters && message.scanParameters.length))
                                    message.scanParameters = [];
                                message.scanParameters.push($root.palexy.sherlock.v1.Parameter.decode(reader, reader.uint32()));
                                break;
                            case 4:
                                message.state = $root.palexy.sherlock.v1.ScanMergeConfigState.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ScanMergeConfigAdvanceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ScanMergeConfigAdvanceInfo} ScanMergeConfigAdvanceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScanMergeConfigAdvanceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ScanMergeConfigAdvanceInfo message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ScanMergeConfigAdvanceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.debugData != null && message.hasOwnProperty("debugData")) {
                            if (!Array.isArray(message.debugData))
                                return "debugData: array expected";
                            for (var i = 0; i < message.debugData.length; ++i) {
                                var error = $root.palexy.sherlock.v1.ScanMergeConfigDebugData.verify(message.debugData[i]);
                                if (error)
                                    return "debugData." + error;
                            }
                        }
                        if (message.defaultConfig != null && message.hasOwnProperty("defaultConfig")) {
                            var error = $root.palexy.streaming.v1.MergingConfig.verify(message.defaultConfig);
                            if (error)
                                return "defaultConfig." + error;
                        }
                        if (message.scanParameters != null && message.hasOwnProperty("scanParameters")) {
                            if (!Array.isArray(message.scanParameters))
                                return "scanParameters: array expected";
                            for (var i = 0; i < message.scanParameters.length; ++i) {
                                var error = $root.palexy.sherlock.v1.Parameter.verify(message.scanParameters[i]);
                                if (error)
                                    return "scanParameters." + error;
                            }
                        }
                        if (message.state != null && message.hasOwnProperty("state")) {
                            var error = $root.palexy.sherlock.v1.ScanMergeConfigState.verify(message.state);
                            if (error)
                                return "state." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ScanMergeConfigAdvanceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ScanMergeConfigAdvanceInfo} ScanMergeConfigAdvanceInfo
                     */
                    ScanMergeConfigAdvanceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ScanMergeConfigAdvanceInfo)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ScanMergeConfigAdvanceInfo();
                        if (object.debugData) {
                            if (!Array.isArray(object.debugData))
                                throw TypeError(".palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.debugData: array expected");
                            message.debugData = [];
                            for (var i = 0; i < object.debugData.length; ++i) {
                                if (typeof object.debugData[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.debugData: object expected");
                                message.debugData[i] = $root.palexy.sherlock.v1.ScanMergeConfigDebugData.fromObject(object.debugData[i]);
                            }
                        }
                        if (object.defaultConfig != null) {
                            if (typeof object.defaultConfig !== "object")
                                throw TypeError(".palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.defaultConfig: object expected");
                            message.defaultConfig = $root.palexy.streaming.v1.MergingConfig.fromObject(object.defaultConfig);
                        }
                        if (object.scanParameters) {
                            if (!Array.isArray(object.scanParameters))
                                throw TypeError(".palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.scanParameters: array expected");
                            message.scanParameters = [];
                            for (var i = 0; i < object.scanParameters.length; ++i) {
                                if (typeof object.scanParameters[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.scanParameters: object expected");
                                message.scanParameters[i] = $root.palexy.sherlock.v1.Parameter.fromObject(object.scanParameters[i]);
                            }
                        }
                        if (object.state != null) {
                            if (typeof object.state !== "object")
                                throw TypeError(".palexy.sherlock.v1.ScanMergeConfigAdvanceInfo.state: object expected");
                            message.state = $root.palexy.sherlock.v1.ScanMergeConfigState.fromObject(object.state);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ScanMergeConfigAdvanceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @static
                     * @param {palexy.sherlock.v1.ScanMergeConfigAdvanceInfo} message ScanMergeConfigAdvanceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ScanMergeConfigAdvanceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.debugData = [];
                            object.scanParameters = [];
                        }
                        if (options.defaults) {
                            object.defaultConfig = null;
                            object.state = null;
                        }
                        if (message.debugData && message.debugData.length) {
                            object.debugData = [];
                            for (var j = 0; j < message.debugData.length; ++j)
                                object.debugData[j] = $root.palexy.sherlock.v1.ScanMergeConfigDebugData.toObject(message.debugData[j], options);
                        }
                        if (message.defaultConfig != null && message.hasOwnProperty("defaultConfig"))
                            object.defaultConfig = $root.palexy.streaming.v1.MergingConfig.toObject(message.defaultConfig, options);
                        if (message.scanParameters && message.scanParameters.length) {
                            object.scanParameters = [];
                            for (var j = 0; j < message.scanParameters.length; ++j)
                                object.scanParameters[j] = $root.palexy.sherlock.v1.Parameter.toObject(message.scanParameters[j], options);
                        }
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = $root.palexy.sherlock.v1.ScanMergeConfigState.toObject(message.state, options);
                        return object;
                    };
    
                    /**
                     * Converts this ScanMergeConfigAdvanceInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ScanMergeConfigAdvanceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ScanMergeConfigAdvanceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ScanMergeConfigAdvanceInfo;
                })();
    
                v1.ScanMergeConfigDebugData = (function() {
    
                    /**
                     * Properties of a ScanMergeConfigDebugData.
                     * @memberof palexy.sherlock.v1
                     * @interface IScanMergeConfigDebugData
                     * @property {string|null} [displayName] ScanMergeConfigDebugData displayName
                     * @property {palexy.sherlock.v1.IExtractBBResult|null} [extractBbResult] ScanMergeConfigDebugData extractBbResult
                     */
    
                    /**
                     * Constructs a new ScanMergeConfigDebugData.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ScanMergeConfigDebugData.
                     * @implements IScanMergeConfigDebugData
                     * @constructor
                     * @param {palexy.sherlock.v1.IScanMergeConfigDebugData=} [properties] Properties to set
                     */
                    function ScanMergeConfigDebugData(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ScanMergeConfigDebugData displayName.
                     * @member {string} displayName
                     * @memberof palexy.sherlock.v1.ScanMergeConfigDebugData
                     * @instance
                     */
                    ScanMergeConfigDebugData.prototype.displayName = "";
    
                    /**
                     * ScanMergeConfigDebugData extractBbResult.
                     * @member {palexy.sherlock.v1.IExtractBBResult|null|undefined} extractBbResult
                     * @memberof palexy.sherlock.v1.ScanMergeConfigDebugData
                     * @instance
                     */
                    ScanMergeConfigDebugData.prototype.extractBbResult = null;
    
                    /**
                     * Creates a new ScanMergeConfigDebugData instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ScanMergeConfigDebugData
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigDebugData=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ScanMergeConfigDebugData} ScanMergeConfigDebugData instance
                     */
                    ScanMergeConfigDebugData.create = function create(properties) {
                        return new ScanMergeConfigDebugData(properties);
                    };
    
                    /**
                     * Encodes the specified ScanMergeConfigDebugData message. Does not implicitly {@link palexy.sherlock.v1.ScanMergeConfigDebugData.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ScanMergeConfigDebugData
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigDebugData} message ScanMergeConfigDebugData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScanMergeConfigDebugData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.displayName);
                        if (message.extractBbResult != null && Object.hasOwnProperty.call(message, "extractBbResult"))
                            $root.palexy.sherlock.v1.ExtractBBResult.encode(message.extractBbResult, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ScanMergeConfigDebugData message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ScanMergeConfigDebugData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ScanMergeConfigDebugData
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigDebugData} message ScanMergeConfigDebugData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScanMergeConfigDebugData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ScanMergeConfigDebugData message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ScanMergeConfigDebugData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ScanMergeConfigDebugData} ScanMergeConfigDebugData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScanMergeConfigDebugData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ScanMergeConfigDebugData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.displayName = reader.string();
                                break;
                            case 2:
                                message.extractBbResult = $root.palexy.sherlock.v1.ExtractBBResult.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ScanMergeConfigDebugData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ScanMergeConfigDebugData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ScanMergeConfigDebugData} ScanMergeConfigDebugData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScanMergeConfigDebugData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ScanMergeConfigDebugData message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ScanMergeConfigDebugData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ScanMergeConfigDebugData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        if (message.extractBbResult != null && message.hasOwnProperty("extractBbResult")) {
                            var error = $root.palexy.sherlock.v1.ExtractBBResult.verify(message.extractBbResult);
                            if (error)
                                return "extractBbResult." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ScanMergeConfigDebugData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ScanMergeConfigDebugData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ScanMergeConfigDebugData} ScanMergeConfigDebugData
                     */
                    ScanMergeConfigDebugData.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ScanMergeConfigDebugData)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ScanMergeConfigDebugData();
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        if (object.extractBbResult != null) {
                            if (typeof object.extractBbResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.ScanMergeConfigDebugData.extractBbResult: object expected");
                            message.extractBbResult = $root.palexy.sherlock.v1.ExtractBBResult.fromObject(object.extractBbResult);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ScanMergeConfigDebugData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ScanMergeConfigDebugData
                     * @static
                     * @param {palexy.sherlock.v1.ScanMergeConfigDebugData} message ScanMergeConfigDebugData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ScanMergeConfigDebugData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.displayName = "";
                            object.extractBbResult = null;
                        }
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        if (message.extractBbResult != null && message.hasOwnProperty("extractBbResult"))
                            object.extractBbResult = $root.palexy.sherlock.v1.ExtractBBResult.toObject(message.extractBbResult, options);
                        return object;
                    };
    
                    /**
                     * Converts this ScanMergeConfigDebugData to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ScanMergeConfigDebugData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ScanMergeConfigDebugData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ScanMergeConfigDebugData;
                })();
    
                v1.Parameter = (function() {
    
                    /**
                     * Properties of a Parameter.
                     * @memberof palexy.sherlock.v1
                     * @interface IParameter
                     * @property {string|null} [name] Parameter name
                     * @property {palexy.sherlock.v1.IParameterRange|null} [range] Parameter range
                     */
    
                    /**
                     * Constructs a new Parameter.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a Parameter.
                     * @implements IParameter
                     * @constructor
                     * @param {palexy.sherlock.v1.IParameter=} [properties] Properties to set
                     */
                    function Parameter(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Parameter name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.Parameter
                     * @instance
                     */
                    Parameter.prototype.name = "";
    
                    /**
                     * Parameter range.
                     * @member {palexy.sherlock.v1.IParameterRange|null|undefined} range
                     * @memberof palexy.sherlock.v1.Parameter
                     * @instance
                     */
                    Parameter.prototype.range = null;
    
                    /**
                     * Creates a new Parameter instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.Parameter
                     * @static
                     * @param {palexy.sherlock.v1.IParameter=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.Parameter} Parameter instance
                     */
                    Parameter.create = function create(properties) {
                        return new Parameter(properties);
                    };
    
                    /**
                     * Encodes the specified Parameter message. Does not implicitly {@link palexy.sherlock.v1.Parameter.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.Parameter
                     * @static
                     * @param {palexy.sherlock.v1.IParameter} message Parameter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Parameter.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.range != null && Object.hasOwnProperty.call(message, "range"))
                            $root.palexy.sherlock.v1.ParameterRange.encode(message.range, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Parameter message, length delimited. Does not implicitly {@link palexy.sherlock.v1.Parameter.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.Parameter
                     * @static
                     * @param {palexy.sherlock.v1.IParameter} message Parameter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Parameter.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Parameter message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.Parameter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.Parameter} Parameter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Parameter.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.Parameter();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.range = $root.palexy.sherlock.v1.ParameterRange.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Parameter message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.Parameter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.Parameter} Parameter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Parameter.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Parameter message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.Parameter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Parameter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.range != null && message.hasOwnProperty("range")) {
                            var error = $root.palexy.sherlock.v1.ParameterRange.verify(message.range);
                            if (error)
                                return "range." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Parameter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.Parameter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.Parameter} Parameter
                     */
                    Parameter.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.Parameter)
                            return object;
                        var message = new $root.palexy.sherlock.v1.Parameter();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.range != null) {
                            if (typeof object.range !== "object")
                                throw TypeError(".palexy.sherlock.v1.Parameter.range: object expected");
                            message.range = $root.palexy.sherlock.v1.ParameterRange.fromObject(object.range);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Parameter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.Parameter
                     * @static
                     * @param {palexy.sherlock.v1.Parameter} message Parameter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Parameter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.range = null;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.range != null && message.hasOwnProperty("range"))
                            object.range = $root.palexy.sherlock.v1.ParameterRange.toObject(message.range, options);
                        return object;
                    };
    
                    /**
                     * Converts this Parameter to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.Parameter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Parameter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Parameter;
                })();
    
                v1.ParameterRange = (function() {
    
                    /**
                     * Properties of a ParameterRange.
                     * @memberof palexy.sherlock.v1
                     * @interface IParameterRange
                     * @property {number|null} [min] ParameterRange min
                     * @property {number|null} [max] ParameterRange max
                     * @property {number|null} [step] ParameterRange step
                     */
    
                    /**
                     * Constructs a new ParameterRange.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ParameterRange.
                     * @implements IParameterRange
                     * @constructor
                     * @param {palexy.sherlock.v1.IParameterRange=} [properties] Properties to set
                     */
                    function ParameterRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ParameterRange min.
                     * @member {number} min
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @instance
                     */
                    ParameterRange.prototype.min = 0;
    
                    /**
                     * ParameterRange max.
                     * @member {number} max
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @instance
                     */
                    ParameterRange.prototype.max = 0;
    
                    /**
                     * ParameterRange step.
                     * @member {number} step
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @instance
                     */
                    ParameterRange.prototype.step = 0;
    
                    /**
                     * Creates a new ParameterRange instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @static
                     * @param {palexy.sherlock.v1.IParameterRange=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ParameterRange} ParameterRange instance
                     */
                    ParameterRange.create = function create(properties) {
                        return new ParameterRange(properties);
                    };
    
                    /**
                     * Encodes the specified ParameterRange message. Does not implicitly {@link palexy.sherlock.v1.ParameterRange.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @static
                     * @param {palexy.sherlock.v1.IParameterRange} message ParameterRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ParameterRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.min != null && Object.hasOwnProperty.call(message, "min"))
                            writer.uint32(/* id 1, wireType 5 =*/13).float(message.min);
                        if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.max);
                        if (message.step != null && Object.hasOwnProperty.call(message, "step"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.step);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ParameterRange message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ParameterRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @static
                     * @param {palexy.sherlock.v1.IParameterRange} message ParameterRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ParameterRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ParameterRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ParameterRange} ParameterRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ParameterRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ParameterRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.min = reader.float();
                                break;
                            case 2:
                                message.max = reader.float();
                                break;
                            case 3:
                                message.step = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ParameterRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ParameterRange} ParameterRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ParameterRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ParameterRange message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ParameterRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.min != null && message.hasOwnProperty("min"))
                            if (typeof message.min !== "number")
                                return "min: number expected";
                        if (message.max != null && message.hasOwnProperty("max"))
                            if (typeof message.max !== "number")
                                return "max: number expected";
                        if (message.step != null && message.hasOwnProperty("step"))
                            if (typeof message.step !== "number")
                                return "step: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a ParameterRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ParameterRange} ParameterRange
                     */
                    ParameterRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ParameterRange)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ParameterRange();
                        if (object.min != null)
                            message.min = Number(object.min);
                        if (object.max != null)
                            message.max = Number(object.max);
                        if (object.step != null)
                            message.step = Number(object.step);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ParameterRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @static
                     * @param {palexy.sherlock.v1.ParameterRange} message ParameterRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ParameterRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.min = 0;
                            object.max = 0;
                            object.step = 0;
                        }
                        if (message.min != null && message.hasOwnProperty("min"))
                            object.min = options.json && !isFinite(message.min) ? String(message.min) : message.min;
                        if (message.max != null && message.hasOwnProperty("max"))
                            object.max = options.json && !isFinite(message.max) ? String(message.max) : message.max;
                        if (message.step != null && message.hasOwnProperty("step"))
                            object.step = options.json && !isFinite(message.step) ? String(message.step) : message.step;
                        return object;
                    };
    
                    /**
                     * Converts this ParameterRange to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ParameterRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ParameterRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ParameterRange;
                })();
    
                v1.ScanMergeConfigState = (function() {
    
                    /**
                     * Properties of a ScanMergeConfigState.
                     * @memberof palexy.sherlock.v1
                     * @interface IScanMergeConfigState
                     * @property {palexy.streaming.v1.IMergingConfig|null} [processingConfig] ScanMergeConfigState processingConfig
                     * @property {palexy.sherlock.v1.IScanMergeConfigResult|null} [currentResult] ScanMergeConfigState currentResult
                     */
    
                    /**
                     * Constructs a new ScanMergeConfigState.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ScanMergeConfigState.
                     * @implements IScanMergeConfigState
                     * @constructor
                     * @param {palexy.sherlock.v1.IScanMergeConfigState=} [properties] Properties to set
                     */
                    function ScanMergeConfigState(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ScanMergeConfigState processingConfig.
                     * @member {palexy.streaming.v1.IMergingConfig|null|undefined} processingConfig
                     * @memberof palexy.sherlock.v1.ScanMergeConfigState
                     * @instance
                     */
                    ScanMergeConfigState.prototype.processingConfig = null;
    
                    /**
                     * ScanMergeConfigState currentResult.
                     * @member {palexy.sherlock.v1.IScanMergeConfigResult|null|undefined} currentResult
                     * @memberof palexy.sherlock.v1.ScanMergeConfigState
                     * @instance
                     */
                    ScanMergeConfigState.prototype.currentResult = null;
    
                    /**
                     * Creates a new ScanMergeConfigState instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ScanMergeConfigState
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigState=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ScanMergeConfigState} ScanMergeConfigState instance
                     */
                    ScanMergeConfigState.create = function create(properties) {
                        return new ScanMergeConfigState(properties);
                    };
    
                    /**
                     * Encodes the specified ScanMergeConfigState message. Does not implicitly {@link palexy.sherlock.v1.ScanMergeConfigState.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ScanMergeConfigState
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigState} message ScanMergeConfigState message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScanMergeConfigState.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.processingConfig != null && Object.hasOwnProperty.call(message, "processingConfig"))
                            $root.palexy.streaming.v1.MergingConfig.encode(message.processingConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.currentResult != null && Object.hasOwnProperty.call(message, "currentResult"))
                            $root.palexy.sherlock.v1.ScanMergeConfigResult.encode(message.currentResult, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ScanMergeConfigState message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ScanMergeConfigState.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ScanMergeConfigState
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigState} message ScanMergeConfigState message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScanMergeConfigState.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ScanMergeConfigState message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ScanMergeConfigState
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ScanMergeConfigState} ScanMergeConfigState
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScanMergeConfigState.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ScanMergeConfigState();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.processingConfig = $root.palexy.streaming.v1.MergingConfig.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.currentResult = $root.palexy.sherlock.v1.ScanMergeConfigResult.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ScanMergeConfigState message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ScanMergeConfigState
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ScanMergeConfigState} ScanMergeConfigState
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScanMergeConfigState.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ScanMergeConfigState message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ScanMergeConfigState
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ScanMergeConfigState.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.processingConfig != null && message.hasOwnProperty("processingConfig")) {
                            var error = $root.palexy.streaming.v1.MergingConfig.verify(message.processingConfig);
                            if (error)
                                return "processingConfig." + error;
                        }
                        if (message.currentResult != null && message.hasOwnProperty("currentResult")) {
                            var error = $root.palexy.sherlock.v1.ScanMergeConfigResult.verify(message.currentResult);
                            if (error)
                                return "currentResult." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ScanMergeConfigState message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ScanMergeConfigState
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ScanMergeConfigState} ScanMergeConfigState
                     */
                    ScanMergeConfigState.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ScanMergeConfigState)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ScanMergeConfigState();
                        if (object.processingConfig != null) {
                            if (typeof object.processingConfig !== "object")
                                throw TypeError(".palexy.sherlock.v1.ScanMergeConfigState.processingConfig: object expected");
                            message.processingConfig = $root.palexy.streaming.v1.MergingConfig.fromObject(object.processingConfig);
                        }
                        if (object.currentResult != null) {
                            if (typeof object.currentResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.ScanMergeConfigState.currentResult: object expected");
                            message.currentResult = $root.palexy.sherlock.v1.ScanMergeConfigResult.fromObject(object.currentResult);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ScanMergeConfigState message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ScanMergeConfigState
                     * @static
                     * @param {palexy.sherlock.v1.ScanMergeConfigState} message ScanMergeConfigState
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ScanMergeConfigState.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.processingConfig = null;
                            object.currentResult = null;
                        }
                        if (message.processingConfig != null && message.hasOwnProperty("processingConfig"))
                            object.processingConfig = $root.palexy.streaming.v1.MergingConfig.toObject(message.processingConfig, options);
                        if (message.currentResult != null && message.hasOwnProperty("currentResult"))
                            object.currentResult = $root.palexy.sherlock.v1.ScanMergeConfigResult.toObject(message.currentResult, options);
                        return object;
                    };
    
                    /**
                     * Converts this ScanMergeConfigState to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ScanMergeConfigState
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ScanMergeConfigState.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ScanMergeConfigState;
                })();
    
                v1.ScanMergeConfigResult = (function() {
    
                    /**
                     * Properties of a ScanMergeConfigResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IScanMergeConfigResult
                     * @property {palexy.sherlock.v1.IScanMergeConfigResultItem|null} [referenceItem] ScanMergeConfigResult referenceItem
                     * @property {Array.<palexy.sherlock.v1.IScanMergeConfigResultItem>|null} [items] ScanMergeConfigResult items
                     */
    
                    /**
                     * Constructs a new ScanMergeConfigResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ScanMergeConfigResult.
                     * @implements IScanMergeConfigResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IScanMergeConfigResult=} [properties] Properties to set
                     */
                    function ScanMergeConfigResult(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ScanMergeConfigResult referenceItem.
                     * @member {palexy.sherlock.v1.IScanMergeConfigResultItem|null|undefined} referenceItem
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResult
                     * @instance
                     */
                    ScanMergeConfigResult.prototype.referenceItem = null;
    
                    /**
                     * ScanMergeConfigResult items.
                     * @member {Array.<palexy.sherlock.v1.IScanMergeConfigResultItem>} items
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResult
                     * @instance
                     */
                    ScanMergeConfigResult.prototype.items = $util.emptyArray;
    
                    /**
                     * Creates a new ScanMergeConfigResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResult
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ScanMergeConfigResult} ScanMergeConfigResult instance
                     */
                    ScanMergeConfigResult.create = function create(properties) {
                        return new ScanMergeConfigResult(properties);
                    };
    
                    /**
                     * Encodes the specified ScanMergeConfigResult message. Does not implicitly {@link palexy.sherlock.v1.ScanMergeConfigResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResult
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigResult} message ScanMergeConfigResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScanMergeConfigResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.referenceItem != null && Object.hasOwnProperty.call(message, "referenceItem"))
                            $root.palexy.sherlock.v1.ScanMergeConfigResultItem.encode(message.referenceItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.palexy.sherlock.v1.ScanMergeConfigResultItem.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ScanMergeConfigResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ScanMergeConfigResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResult
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigResult} message ScanMergeConfigResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScanMergeConfigResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ScanMergeConfigResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ScanMergeConfigResult} ScanMergeConfigResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScanMergeConfigResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ScanMergeConfigResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.referenceItem = $root.palexy.sherlock.v1.ScanMergeConfigResultItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.palexy.sherlock.v1.ScanMergeConfigResultItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ScanMergeConfigResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ScanMergeConfigResult} ScanMergeConfigResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScanMergeConfigResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ScanMergeConfigResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ScanMergeConfigResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.referenceItem != null && message.hasOwnProperty("referenceItem")) {
                            var error = $root.palexy.sherlock.v1.ScanMergeConfigResultItem.verify(message.referenceItem);
                            if (error)
                                return "referenceItem." + error;
                        }
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.palexy.sherlock.v1.ScanMergeConfigResultItem.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ScanMergeConfigResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ScanMergeConfigResult} ScanMergeConfigResult
                     */
                    ScanMergeConfigResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ScanMergeConfigResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ScanMergeConfigResult();
                        if (object.referenceItem != null) {
                            if (typeof object.referenceItem !== "object")
                                throw TypeError(".palexy.sherlock.v1.ScanMergeConfigResult.referenceItem: object expected");
                            message.referenceItem = $root.palexy.sherlock.v1.ScanMergeConfigResultItem.fromObject(object.referenceItem);
                        }
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".palexy.sherlock.v1.ScanMergeConfigResult.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ScanMergeConfigResult.items: object expected");
                                message.items[i] = $root.palexy.sherlock.v1.ScanMergeConfigResultItem.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ScanMergeConfigResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResult
                     * @static
                     * @param {palexy.sherlock.v1.ScanMergeConfigResult} message ScanMergeConfigResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ScanMergeConfigResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (options.defaults)
                            object.referenceItem = null;
                        if (message.referenceItem != null && message.hasOwnProperty("referenceItem"))
                            object.referenceItem = $root.palexy.sherlock.v1.ScanMergeConfigResultItem.toObject(message.referenceItem, options);
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.palexy.sherlock.v1.ScanMergeConfigResultItem.toObject(message.items[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ScanMergeConfigResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ScanMergeConfigResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ScanMergeConfigResult;
                })();
    
                v1.ScanMergeConfigResultItem = (function() {
    
                    /**
                     * Properties of a ScanMergeConfigResultItem.
                     * @memberof palexy.sherlock.v1
                     * @interface IScanMergeConfigResultItem
                     * @property {palexy.sherlock.v1.IMergeAccuracyResult|null} [result] ScanMergeConfigResultItem result
                     * @property {palexy.streaming.v1.IMergingConfig|null} [config] ScanMergeConfigResultItem config
                     */
    
                    /**
                     * Constructs a new ScanMergeConfigResultItem.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ScanMergeConfigResultItem.
                     * @implements IScanMergeConfigResultItem
                     * @constructor
                     * @param {palexy.sherlock.v1.IScanMergeConfigResultItem=} [properties] Properties to set
                     */
                    function ScanMergeConfigResultItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ScanMergeConfigResultItem result.
                     * @member {palexy.sherlock.v1.IMergeAccuracyResult|null|undefined} result
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResultItem
                     * @instance
                     */
                    ScanMergeConfigResultItem.prototype.result = null;
    
                    /**
                     * ScanMergeConfigResultItem config.
                     * @member {palexy.streaming.v1.IMergingConfig|null|undefined} config
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResultItem
                     * @instance
                     */
                    ScanMergeConfigResultItem.prototype.config = null;
    
                    /**
                     * Creates a new ScanMergeConfigResultItem instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResultItem
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigResultItem=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ScanMergeConfigResultItem} ScanMergeConfigResultItem instance
                     */
                    ScanMergeConfigResultItem.create = function create(properties) {
                        return new ScanMergeConfigResultItem(properties);
                    };
    
                    /**
                     * Encodes the specified ScanMergeConfigResultItem message. Does not implicitly {@link palexy.sherlock.v1.ScanMergeConfigResultItem.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResultItem
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigResultItem} message ScanMergeConfigResultItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScanMergeConfigResultItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                            $root.palexy.sherlock.v1.MergeAccuracyResult.encode(message.result, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                            $root.palexy.streaming.v1.MergingConfig.encode(message.config, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ScanMergeConfigResultItem message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ScanMergeConfigResultItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResultItem
                     * @static
                     * @param {palexy.sherlock.v1.IScanMergeConfigResultItem} message ScanMergeConfigResultItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScanMergeConfigResultItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ScanMergeConfigResultItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResultItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ScanMergeConfigResultItem} ScanMergeConfigResultItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScanMergeConfigResultItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ScanMergeConfigResultItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.result = $root.palexy.sherlock.v1.MergeAccuracyResult.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.config = $root.palexy.streaming.v1.MergingConfig.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ScanMergeConfigResultItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResultItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ScanMergeConfigResultItem} ScanMergeConfigResultItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScanMergeConfigResultItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ScanMergeConfigResultItem message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResultItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ScanMergeConfigResultItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.result != null && message.hasOwnProperty("result")) {
                            var error = $root.palexy.sherlock.v1.MergeAccuracyResult.verify(message.result);
                            if (error)
                                return "result." + error;
                        }
                        if (message.config != null && message.hasOwnProperty("config")) {
                            var error = $root.palexy.streaming.v1.MergingConfig.verify(message.config);
                            if (error)
                                return "config." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ScanMergeConfigResultItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResultItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ScanMergeConfigResultItem} ScanMergeConfigResultItem
                     */
                    ScanMergeConfigResultItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ScanMergeConfigResultItem)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ScanMergeConfigResultItem();
                        if (object.result != null) {
                            if (typeof object.result !== "object")
                                throw TypeError(".palexy.sherlock.v1.ScanMergeConfigResultItem.result: object expected");
                            message.result = $root.palexy.sherlock.v1.MergeAccuracyResult.fromObject(object.result);
                        }
                        if (object.config != null) {
                            if (typeof object.config !== "object")
                                throw TypeError(".palexy.sherlock.v1.ScanMergeConfigResultItem.config: object expected");
                            message.config = $root.palexy.streaming.v1.MergingConfig.fromObject(object.config);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ScanMergeConfigResultItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResultItem
                     * @static
                     * @param {palexy.sherlock.v1.ScanMergeConfigResultItem} message ScanMergeConfigResultItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ScanMergeConfigResultItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.result = null;
                            object.config = null;
                        }
                        if (message.result != null && message.hasOwnProperty("result"))
                            object.result = $root.palexy.sherlock.v1.MergeAccuracyResult.toObject(message.result, options);
                        if (message.config != null && message.hasOwnProperty("config"))
                            object.config = $root.palexy.streaming.v1.MergingConfig.toObject(message.config, options);
                        return object;
                    };
    
                    /**
                     * Converts this ScanMergeConfigResultItem to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ScanMergeConfigResultItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ScanMergeConfigResultItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ScanMergeConfigResultItem;
                })();
    
                v1.SherlockTaskService = (function() {
    
                    /**
                     * Constructs a new SherlockTaskService service.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a SherlockTaskService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function SherlockTaskService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (SherlockTaskService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SherlockTaskService;
    
                    /**
                     * Creates new SherlockTaskService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {SherlockTaskService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    SherlockTaskService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#listTasks}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef ListTasksCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.ListTasksResponse} [response] ListTasksResponse
                     */
    
                    /**
                     * Calls ListTasks.
                     * @function listTasks
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IListTasksRequest} request ListTasksRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.ListTasksCallback} callback Node-style callback called with the error, if any, and ListTasksResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.listTasks = function listTasks(request, callback) {
                        return this.rpcCall(listTasks, $root.palexy.sherlock.v1.ListTasksRequest, $root.palexy.sherlock.v1.ListTasksResponse, request, callback);
                    }, "name", { value: "ListTasks" });
    
                    /**
                     * Calls ListTasks.
                     * @function listTasks
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IListTasksRequest} request ListTasksRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.ListTasksResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#createTask}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef CreateTaskCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.SherlockTask} [response] SherlockTask
                     */
    
                    /**
                     * Calls CreateTask.
                     * @function createTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateTaskRequest} request CreateTaskRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.CreateTaskCallback} callback Node-style callback called with the error, if any, and SherlockTask
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.createTask = function createTask(request, callback) {
                        return this.rpcCall(createTask, $root.palexy.sherlock.v1.CreateTaskRequest, $root.palexy.sherlock.v1.SherlockTask, request, callback);
                    }, "name", { value: "CreateTask" });
    
                    /**
                     * Calls CreateTask.
                     * @function createTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateTaskRequest} request CreateTaskRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.SherlockTask>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#batchCreateTask}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef BatchCreateTaskCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.BatchCreateTaskResponse} [response] BatchCreateTaskResponse
                     */
    
                    /**
                     * Calls BatchCreateTask.
                     * @function batchCreateTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IBatchCreateTaskRequest} request BatchCreateTaskRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.BatchCreateTaskCallback} callback Node-style callback called with the error, if any, and BatchCreateTaskResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.batchCreateTask = function batchCreateTask(request, callback) {
                        return this.rpcCall(batchCreateTask, $root.palexy.sherlock.v1.BatchCreateTaskRequest, $root.palexy.sherlock.v1.BatchCreateTaskResponse, request, callback);
                    }, "name", { value: "BatchCreateTask" });
    
                    /**
                     * Calls BatchCreateTask.
                     * @function batchCreateTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IBatchCreateTaskRequest} request BatchCreateTaskRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.BatchCreateTaskResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#updateTask}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef UpdateTaskCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.SherlockTask} [response] SherlockTask
                     */
    
                    /**
                     * Calls UpdateTask.
                     * @function updateTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateTaskRequest} request UpdateTaskRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.UpdateTaskCallback} callback Node-style callback called with the error, if any, and SherlockTask
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.updateTask = function updateTask(request, callback) {
                        return this.rpcCall(updateTask, $root.palexy.sherlock.v1.UpdateTaskRequest, $root.palexy.sherlock.v1.SherlockTask, request, callback);
                    }, "name", { value: "UpdateTask" });
    
                    /**
                     * Calls UpdateTask.
                     * @function updateTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateTaskRequest} request UpdateTaskRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.SherlockTask>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#retryTask}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef RetryTaskCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls RetryTask.
                     * @function retryTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IRetryTaskRequest} request RetryTaskRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.RetryTaskCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.retryTask = function retryTask(request, callback) {
                        return this.rpcCall(retryTask, $root.palexy.sherlock.v1.RetryTaskRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "RetryTask" });
    
                    /**
                     * Calls RetryTask.
                     * @function retryTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IRetryTaskRequest} request RetryTaskRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#getTask}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef GetTaskCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.SherlockTask} [response] SherlockTask
                     */
    
                    /**
                     * Calls GetTask.
                     * @function getTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IGetTaskRequest} request GetTaskRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.GetTaskCallback} callback Node-style callback called with the error, if any, and SherlockTask
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.getTask = function getTask(request, callback) {
                        return this.rpcCall(getTask, $root.palexy.sherlock.v1.GetTaskRequest, $root.palexy.sherlock.v1.SherlockTask, request, callback);
                    }, "name", { value: "GetTask" });
    
                    /**
                     * Calls GetTask.
                     * @function getTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IGetTaskRequest} request GetTaskRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.SherlockTask>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#deleteTask}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef DeleteTaskCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteTask.
                     * @function deleteTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IDeleteTaskRequest} request DeleteTaskRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.DeleteTaskCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.deleteTask = function deleteTask(request, callback) {
                        return this.rpcCall(deleteTask, $root.palexy.sherlock.v1.DeleteTaskRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteTask" });
    
                    /**
                     * Calls DeleteTask.
                     * @function deleteTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.IDeleteTaskRequest} request DeleteTaskRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#createDebugTaskForStoreConfig}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef CreateDebugTaskForStoreConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse} [response] CreateDebugTaskForStoreConfigResponse
                     */
    
                    /**
                     * Calls CreateDebugTaskForStoreConfig.
                     * @function createDebugTaskForStoreConfig
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForStoreConfigRequest} request CreateDebugTaskForStoreConfigRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.CreateDebugTaskForStoreConfigCallback} callback Node-style callback called with the error, if any, and CreateDebugTaskForStoreConfigResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.createDebugTaskForStoreConfig = function createDebugTaskForStoreConfig(request, callback) {
                        return this.rpcCall(createDebugTaskForStoreConfig, $root.palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest, $root.palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse, request, callback);
                    }, "name", { value: "CreateDebugTaskForStoreConfig" });
    
                    /**
                     * Calls CreateDebugTaskForStoreConfig.
                     * @function createDebugTaskForStoreConfig
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForStoreConfigRequest} request CreateDebugTaskForStoreConfigRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#createDebugTaskForExperiment}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef CreateDebugTaskForExperimentCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateDebugTaskForExperimentResponse} [response] CreateDebugTaskForExperimentResponse
                     */
    
                    /**
                     * Calls CreateDebugTaskForExperiment.
                     * @function createDebugTaskForExperiment
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForExperimentRequest} request CreateDebugTaskForExperimentRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.CreateDebugTaskForExperimentCallback} callback Node-style callback called with the error, if any, and CreateDebugTaskForExperimentResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.createDebugTaskForExperiment = function createDebugTaskForExperiment(request, callback) {
                        return this.rpcCall(createDebugTaskForExperiment, $root.palexy.sherlock.v1.CreateDebugTaskForExperimentRequest, $root.palexy.sherlock.v1.CreateDebugTaskForExperimentResponse, request, callback);
                    }, "name", { value: "CreateDebugTaskForExperiment" });
    
                    /**
                     * Calls CreateDebugTaskForExperiment.
                     * @function createDebugTaskForExperiment
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForExperimentRequest} request CreateDebugTaskForExperimentRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateDebugTaskForExperimentResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#createAccuracyTasksForStoreConfig}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef CreateAccuracyTasksForStoreConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse} [response] CreateAccuracyTasksForStoreConfigResponse
                     */
    
                    /**
                     * Calls CreateAccuracyTasksForStoreConfig.
                     * @function createAccuracyTasksForStoreConfig
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateAccuracyTasksForStoreConfigRequest} request CreateAccuracyTasksForStoreConfigRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.CreateAccuracyTasksForStoreConfigCallback} callback Node-style callback called with the error, if any, and CreateAccuracyTasksForStoreConfigResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.createAccuracyTasksForStoreConfig = function createAccuracyTasksForStoreConfig(request, callback) {
                        return this.rpcCall(createAccuracyTasksForStoreConfig, $root.palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest, $root.palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse, request, callback);
                    }, "name", { value: "CreateAccuracyTasksForStoreConfig" });
    
                    /**
                     * Calls CreateAccuracyTasksForStoreConfig.
                     * @function createAccuracyTasksForStoreConfig
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateAccuracyTasksForStoreConfigRequest} request CreateAccuracyTasksForStoreConfigRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#createTasksForConfigItem}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef CreateTasksForConfigItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateTasksForConfigItemResponse} [response] CreateTasksForConfigItemResponse
                     */
    
                    /**
                     * Calls CreateTasksForConfigItem.
                     * @function createTasksForConfigItem
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateTasksForConfigItemRequest} request CreateTasksForConfigItemRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.CreateTasksForConfigItemCallback} callback Node-style callback called with the error, if any, and CreateTasksForConfigItemResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.createTasksForConfigItem = function createTasksForConfigItem(request, callback) {
                        return this.rpcCall(createTasksForConfigItem, $root.palexy.sherlock.v1.CreateTasksForConfigItemRequest, $root.palexy.sherlock.v1.CreateTasksForConfigItemResponse, request, callback);
                    }, "name", { value: "CreateTasksForConfigItem" });
    
                    /**
                     * Calls CreateTasksForConfigItem.
                     * @function createTasksForConfigItem
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateTasksForConfigItemRequest} request CreateTasksForConfigItemRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateTasksForConfigItemResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#createScanningTaskForConfigItem}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef CreateScanningTaskForConfigItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse} [response] CreateScanningTaskForConfigItemResponse
                     */
    
                    /**
                     * Calls CreateScanningTaskForConfigItem.
                     * @function createScanningTaskForConfigItem
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateScanningTaskForConfigItemRequest} request CreateScanningTaskForConfigItemRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.CreateScanningTaskForConfigItemCallback} callback Node-style callback called with the error, if any, and CreateScanningTaskForConfigItemResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.createScanningTaskForConfigItem = function createScanningTaskForConfigItem(request, callback) {
                        return this.rpcCall(createScanningTaskForConfigItem, $root.palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest, $root.palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse, request, callback);
                    }, "name", { value: "CreateScanningTaskForConfigItem" });
    
                    /**
                     * Calls CreateScanningTaskForConfigItem.
                     * @function createScanningTaskForConfigItem
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateScanningTaskForConfigItemRequest} request CreateScanningTaskForConfigItemRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#createRerunningTaskForConfigItem}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef CreateRerunningTaskForConfigItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse} [response] CreateRerunningTaskForConfigItemResponse
                     */
    
                    /**
                     * Calls CreateRerunningTaskForConfigItem.
                     * @function createRerunningTaskForConfigItem
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateRerunningTaskForConfigItemRequest} request CreateRerunningTaskForConfigItemRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.CreateRerunningTaskForConfigItemCallback} callback Node-style callback called with the error, if any, and CreateRerunningTaskForConfigItemResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.createRerunningTaskForConfigItem = function createRerunningTaskForConfigItem(request, callback) {
                        return this.rpcCall(createRerunningTaskForConfigItem, $root.palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest, $root.palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse, request, callback);
                    }, "name", { value: "CreateRerunningTaskForConfigItem" });
    
                    /**
                     * Calls CreateRerunningTaskForConfigItem.
                     * @function createRerunningTaskForConfigItem
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateRerunningTaskForConfigItemRequest} request CreateRerunningTaskForConfigItemRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskService#createPrepareQcVideosTask}.
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @typedef CreatePrepareQcVideosTaskCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse} [response] CreatePrepareQcVideosTaskResponse
                     */
    
                    /**
                     * Calls CreatePrepareQcVideosTask.
                     * @function createPrepareQcVideosTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreatePrepareQcVideosTaskRequest} request CreatePrepareQcVideosTaskRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskService.CreatePrepareQcVideosTaskCallback} callback Node-style callback called with the error, if any, and CreatePrepareQcVideosTaskResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskService.prototype.createPrepareQcVideosTask = function createPrepareQcVideosTask(request, callback) {
                        return this.rpcCall(createPrepareQcVideosTask, $root.palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest, $root.palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse, request, callback);
                    }, "name", { value: "CreatePrepareQcVideosTask" });
    
                    /**
                     * Calls CreatePrepareQcVideosTask.
                     * @function createPrepareQcVideosTask
                     * @memberof palexy.sherlock.v1.SherlockTaskService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreatePrepareQcVideosTaskRequest} request CreatePrepareQcVideosTaskRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse>} Promise
                     * @variation 2
                     */
    
                    return SherlockTaskService;
                })();
    
                v1.SherlockTaskResultService = (function() {
    
                    /**
                     * Constructs a new SherlockTaskResultService service.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a SherlockTaskResultService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function SherlockTaskResultService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (SherlockTaskResultService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SherlockTaskResultService;
    
                    /**
                     * Creates new SherlockTaskResultService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.sherlock.v1.SherlockTaskResultService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {SherlockTaskResultService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    SherlockTaskResultService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskResultService#listTaskResults}.
                     * @memberof palexy.sherlock.v1.SherlockTaskResultService
                     * @typedef ListTaskResultsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.ListTaskResultsResponse} [response] ListTaskResultsResponse
                     */
    
                    /**
                     * Calls ListTaskResults.
                     * @function listTaskResults
                     * @memberof palexy.sherlock.v1.SherlockTaskResultService
                     * @instance
                     * @param {palexy.sherlock.v1.IListTaskResultsRequest} request ListTaskResultsRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskResultService.ListTaskResultsCallback} callback Node-style callback called with the error, if any, and ListTaskResultsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskResultService.prototype.listTaskResults = function listTaskResults(request, callback) {
                        return this.rpcCall(listTaskResults, $root.palexy.sherlock.v1.ListTaskResultsRequest, $root.palexy.sherlock.v1.ListTaskResultsResponse, request, callback);
                    }, "name", { value: "ListTaskResults" });
    
                    /**
                     * Calls ListTaskResults.
                     * @function listTaskResults
                     * @memberof palexy.sherlock.v1.SherlockTaskResultService
                     * @instance
                     * @param {palexy.sherlock.v1.IListTaskResultsRequest} request ListTaskResultsRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.ListTaskResultsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskResultService#createTaskResult}.
                     * @memberof palexy.sherlock.v1.SherlockTaskResultService
                     * @typedef CreateTaskResultCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.SherlockTaskResult} [response] SherlockTaskResult
                     */
    
                    /**
                     * Calls CreateTaskResult.
                     * @function createTaskResult
                     * @memberof palexy.sherlock.v1.SherlockTaskResultService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateTaskResultRequest} request CreateTaskResultRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskResultService.CreateTaskResultCallback} callback Node-style callback called with the error, if any, and SherlockTaskResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskResultService.prototype.createTaskResult = function createTaskResult(request, callback) {
                        return this.rpcCall(createTaskResult, $root.palexy.sherlock.v1.CreateTaskResultRequest, $root.palexy.sherlock.v1.SherlockTaskResult, request, callback);
                    }, "name", { value: "CreateTaskResult" });
    
                    /**
                     * Calls CreateTaskResult.
                     * @function createTaskResult
                     * @memberof palexy.sherlock.v1.SherlockTaskResultService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateTaskResultRequest} request CreateTaskResultRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.SherlockTaskResult>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.SherlockTaskResultService#updateHumanLabelLink}.
                     * @memberof palexy.sherlock.v1.SherlockTaskResultService
                     * @typedef UpdateHumanLabelLinkCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.SherlockTaskResult} [response] SherlockTaskResult
                     */
    
                    /**
                     * Calls UpdateHumanLabelLink.
                     * @function updateHumanLabelLink
                     * @memberof palexy.sherlock.v1.SherlockTaskResultService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateHumanLabelLinkRequest} request UpdateHumanLabelLinkRequest message or plain object
                     * @param {palexy.sherlock.v1.SherlockTaskResultService.UpdateHumanLabelLinkCallback} callback Node-style callback called with the error, if any, and SherlockTaskResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SherlockTaskResultService.prototype.updateHumanLabelLink = function updateHumanLabelLink(request, callback) {
                        return this.rpcCall(updateHumanLabelLink, $root.palexy.sherlock.v1.UpdateHumanLabelLinkRequest, $root.palexy.sherlock.v1.SherlockTaskResult, request, callback);
                    }, "name", { value: "UpdateHumanLabelLink" });
    
                    /**
                     * Calls UpdateHumanLabelLink.
                     * @function updateHumanLabelLink
                     * @memberof palexy.sherlock.v1.SherlockTaskResultService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateHumanLabelLinkRequest} request UpdateHumanLabelLinkRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.SherlockTaskResult>} Promise
                     * @variation 2
                     */
    
                    return SherlockTaskResultService;
                })();
    
                v1.ListTaskResultsRequest = (function() {
    
                    /**
                     * Properties of a ListTaskResultsRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IListTaskResultsRequest
                     * @property {string|null} [task] ListTaskResultsRequest task
                     */
    
                    /**
                     * Constructs a new ListTaskResultsRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListTaskResultsRequest.
                     * @implements IListTaskResultsRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IListTaskResultsRequest=} [properties] Properties to set
                     */
                    function ListTaskResultsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListTaskResultsRequest task.
                     * @member {string} task
                     * @memberof palexy.sherlock.v1.ListTaskResultsRequest
                     * @instance
                     */
                    ListTaskResultsRequest.prototype.task = "";
    
                    /**
                     * Creates a new ListTaskResultsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListTaskResultsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTaskResultsRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListTaskResultsRequest} ListTaskResultsRequest instance
                     */
                    ListTaskResultsRequest.create = function create(properties) {
                        return new ListTaskResultsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListTaskResultsRequest message. Does not implicitly {@link palexy.sherlock.v1.ListTaskResultsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListTaskResultsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTaskResultsRequest} message ListTaskResultsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTaskResultsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.task);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListTaskResultsRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListTaskResultsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListTaskResultsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTaskResultsRequest} message ListTaskResultsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTaskResultsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListTaskResultsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListTaskResultsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListTaskResultsRequest} ListTaskResultsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTaskResultsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListTaskResultsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.task = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListTaskResultsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListTaskResultsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListTaskResultsRequest} ListTaskResultsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTaskResultsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListTaskResultsRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListTaskResultsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListTaskResultsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.task != null && message.hasOwnProperty("task"))
                            if (!$util.isString(message.task))
                                return "task: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListTaskResultsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListTaskResultsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListTaskResultsRequest} ListTaskResultsRequest
                     */
                    ListTaskResultsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListTaskResultsRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListTaskResultsRequest();
                        if (object.task != null)
                            message.task = String(object.task);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListTaskResultsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListTaskResultsRequest
                     * @static
                     * @param {palexy.sherlock.v1.ListTaskResultsRequest} message ListTaskResultsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListTaskResultsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.task = "";
                        if (message.task != null && message.hasOwnProperty("task"))
                            object.task = message.task;
                        return object;
                    };
    
                    /**
                     * Converts this ListTaskResultsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListTaskResultsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListTaskResultsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListTaskResultsRequest;
                })();
    
                v1.ListTaskResultsResponse = (function() {
    
                    /**
                     * Properties of a ListTaskResultsResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IListTaskResultsResponse
                     * @property {Array.<palexy.sherlock.v1.ISherlockTaskResult>|null} [taskResults] ListTaskResultsResponse taskResults
                     */
    
                    /**
                     * Constructs a new ListTaskResultsResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListTaskResultsResponse.
                     * @implements IListTaskResultsResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IListTaskResultsResponse=} [properties] Properties to set
                     */
                    function ListTaskResultsResponse(properties) {
                        this.taskResults = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListTaskResultsResponse taskResults.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTaskResult>} taskResults
                     * @memberof palexy.sherlock.v1.ListTaskResultsResponse
                     * @instance
                     */
                    ListTaskResultsResponse.prototype.taskResults = $util.emptyArray;
    
                    /**
                     * Creates a new ListTaskResultsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListTaskResultsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTaskResultsResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListTaskResultsResponse} ListTaskResultsResponse instance
                     */
                    ListTaskResultsResponse.create = function create(properties) {
                        return new ListTaskResultsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListTaskResultsResponse message. Does not implicitly {@link palexy.sherlock.v1.ListTaskResultsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListTaskResultsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTaskResultsResponse} message ListTaskResultsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTaskResultsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.taskResults != null && message.taskResults.length)
                            for (var i = 0; i < message.taskResults.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTaskResult.encode(message.taskResults[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListTaskResultsResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListTaskResultsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListTaskResultsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTaskResultsResponse} message ListTaskResultsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTaskResultsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListTaskResultsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListTaskResultsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListTaskResultsResponse} ListTaskResultsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTaskResultsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListTaskResultsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.taskResults && message.taskResults.length))
                                    message.taskResults = [];
                                message.taskResults.push($root.palexy.sherlock.v1.SherlockTaskResult.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListTaskResultsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListTaskResultsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListTaskResultsResponse} ListTaskResultsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTaskResultsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListTaskResultsResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListTaskResultsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListTaskResultsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.taskResults != null && message.hasOwnProperty("taskResults")) {
                            if (!Array.isArray(message.taskResults))
                                return "taskResults: array expected";
                            for (var i = 0; i < message.taskResults.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTaskResult.verify(message.taskResults[i]);
                                if (error)
                                    return "taskResults." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListTaskResultsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListTaskResultsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListTaskResultsResponse} ListTaskResultsResponse
                     */
                    ListTaskResultsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListTaskResultsResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListTaskResultsResponse();
                        if (object.taskResults) {
                            if (!Array.isArray(object.taskResults))
                                throw TypeError(".palexy.sherlock.v1.ListTaskResultsResponse.taskResults: array expected");
                            message.taskResults = [];
                            for (var i = 0; i < object.taskResults.length; ++i) {
                                if (typeof object.taskResults[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ListTaskResultsResponse.taskResults: object expected");
                                message.taskResults[i] = $root.palexy.sherlock.v1.SherlockTaskResult.fromObject(object.taskResults[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListTaskResultsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListTaskResultsResponse
                     * @static
                     * @param {palexy.sherlock.v1.ListTaskResultsResponse} message ListTaskResultsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListTaskResultsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.taskResults = [];
                        if (message.taskResults && message.taskResults.length) {
                            object.taskResults = [];
                            for (var j = 0; j < message.taskResults.length; ++j)
                                object.taskResults[j] = $root.palexy.sherlock.v1.SherlockTaskResult.toObject(message.taskResults[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListTaskResultsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListTaskResultsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListTaskResultsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListTaskResultsResponse;
                })();
    
                v1.CreateTaskResultRequest = (function() {
    
                    /**
                     * Properties of a CreateTaskResultRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateTaskResultRequest
                     * @property {string|null} [parent] CreateTaskResultRequest parent
                     * @property {palexy.sherlock.v1.ISherlockTaskResult|null} [taskResult] CreateTaskResultRequest taskResult
                     */
    
                    /**
                     * Constructs a new CreateTaskResultRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateTaskResultRequest.
                     * @implements ICreateTaskResultRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateTaskResultRequest=} [properties] Properties to set
                     */
                    function CreateTaskResultRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateTaskResultRequest parent.
                     * @member {string} parent
                     * @memberof palexy.sherlock.v1.CreateTaskResultRequest
                     * @instance
                     */
                    CreateTaskResultRequest.prototype.parent = "";
    
                    /**
                     * CreateTaskResultRequest taskResult.
                     * @member {palexy.sherlock.v1.ISherlockTaskResult|null|undefined} taskResult
                     * @memberof palexy.sherlock.v1.CreateTaskResultRequest
                     * @instance
                     */
                    CreateTaskResultRequest.prototype.taskResult = null;
    
                    /**
                     * Creates a new CreateTaskResultRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateTaskResultRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTaskResultRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateTaskResultRequest} CreateTaskResultRequest instance
                     */
                    CreateTaskResultRequest.create = function create(properties) {
                        return new CreateTaskResultRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateTaskResultRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateTaskResultRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateTaskResultRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTaskResultRequest} message CreateTaskResultRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTaskResultRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.parent != null && Object.hasOwnProperty.call(message, "parent"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.parent);
                        if (message.taskResult != null && Object.hasOwnProperty.call(message, "taskResult"))
                            $root.palexy.sherlock.v1.SherlockTaskResult.encode(message.taskResult, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateTaskResultRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateTaskResultRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTaskResultRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTaskResultRequest} message CreateTaskResultRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTaskResultRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateTaskResultRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateTaskResultRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateTaskResultRequest} CreateTaskResultRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTaskResultRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateTaskResultRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.parent = reader.string();
                                break;
                            case 2:
                                message.taskResult = $root.palexy.sherlock.v1.SherlockTaskResult.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateTaskResultRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTaskResultRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateTaskResultRequest} CreateTaskResultRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTaskResultRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateTaskResultRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateTaskResultRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateTaskResultRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.parent != null && message.hasOwnProperty("parent"))
                            if (!$util.isString(message.parent))
                                return "parent: string expected";
                        if (message.taskResult != null && message.hasOwnProperty("taskResult")) {
                            var error = $root.palexy.sherlock.v1.SherlockTaskResult.verify(message.taskResult);
                            if (error)
                                return "taskResult." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateTaskResultRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateTaskResultRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateTaskResultRequest} CreateTaskResultRequest
                     */
                    CreateTaskResultRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateTaskResultRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateTaskResultRequest();
                        if (object.parent != null)
                            message.parent = String(object.parent);
                        if (object.taskResult != null) {
                            if (typeof object.taskResult !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateTaskResultRequest.taskResult: object expected");
                            message.taskResult = $root.palexy.sherlock.v1.SherlockTaskResult.fromObject(object.taskResult);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateTaskResultRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateTaskResultRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateTaskResultRequest} message CreateTaskResultRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateTaskResultRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.parent = "";
                            object.taskResult = null;
                        }
                        if (message.parent != null && message.hasOwnProperty("parent"))
                            object.parent = message.parent;
                        if (message.taskResult != null && message.hasOwnProperty("taskResult"))
                            object.taskResult = $root.palexy.sherlock.v1.SherlockTaskResult.toObject(message.taskResult, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateTaskResultRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateTaskResultRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateTaskResultRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateTaskResultRequest;
                })();
    
                v1.CreateTaskRequest = (function() {
    
                    /**
                     * Properties of a CreateTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateTaskRequest
                     * @property {palexy.sherlock.v1.ISherlockTask|null} [sherlockTask] CreateTaskRequest sherlockTask
                     */
    
                    /**
                     * Constructs a new CreateTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateTaskRequest.
                     * @implements ICreateTaskRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateTaskRequest=} [properties] Properties to set
                     */
                    function CreateTaskRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateTaskRequest sherlockTask.
                     * @member {palexy.sherlock.v1.ISherlockTask|null|undefined} sherlockTask
                     * @memberof palexy.sherlock.v1.CreateTaskRequest
                     * @instance
                     */
                    CreateTaskRequest.prototype.sherlockTask = null;
    
                    /**
                     * Creates a new CreateTaskRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTaskRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateTaskRequest} CreateTaskRequest instance
                     */
                    CreateTaskRequest.create = function create(properties) {
                        return new CreateTaskRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateTaskRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateTaskRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTaskRequest} message CreateTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTaskRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sherlockTask != null && Object.hasOwnProperty.call(message, "sherlockTask"))
                            $root.palexy.sherlock.v1.SherlockTask.encode(message.sherlockTask, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateTaskRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateTaskRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTaskRequest} message CreateTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateTaskRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateTaskRequest} CreateTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTaskRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateTaskRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sherlockTask = $root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateTaskRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateTaskRequest} CreateTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateTaskRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateTaskRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateTaskRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sherlockTask != null && message.hasOwnProperty("sherlockTask")) {
                            var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.sherlockTask);
                            if (error)
                                return "sherlockTask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateTaskRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateTaskRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateTaskRequest} CreateTaskRequest
                     */
                    CreateTaskRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateTaskRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateTaskRequest();
                        if (object.sherlockTask != null) {
                            if (typeof object.sherlockTask !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateTaskRequest.sherlockTask: object expected");
                            message.sherlockTask = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.sherlockTask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateTaskRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateTaskRequest} message CreateTaskRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateTaskRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.sherlockTask = null;
                        if (message.sherlockTask != null && message.hasOwnProperty("sherlockTask"))
                            object.sherlockTask = $root.palexy.sherlock.v1.SherlockTask.toObject(message.sherlockTask, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateTaskRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateTaskRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateTaskRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateTaskRequest;
                })();
    
                v1.BatchCreateTaskRequest = (function() {
    
                    /**
                     * Properties of a BatchCreateTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IBatchCreateTaskRequest
                     * @property {Array.<palexy.sherlock.v1.ISherlockTask>|null} [sherlockTasks] BatchCreateTaskRequest sherlockTasks
                     */
    
                    /**
                     * Constructs a new BatchCreateTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BatchCreateTaskRequest.
                     * @implements IBatchCreateTaskRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IBatchCreateTaskRequest=} [properties] Properties to set
                     */
                    function BatchCreateTaskRequest(properties) {
                        this.sherlockTasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateTaskRequest sherlockTasks.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTask>} sherlockTasks
                     * @memberof palexy.sherlock.v1.BatchCreateTaskRequest
                     * @instance
                     */
                    BatchCreateTaskRequest.prototype.sherlockTasks = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateTaskRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BatchCreateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTaskRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BatchCreateTaskRequest} BatchCreateTaskRequest instance
                     */
                    BatchCreateTaskRequest.create = function create(properties) {
                        return new BatchCreateTaskRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateTaskRequest message. Does not implicitly {@link palexy.sherlock.v1.BatchCreateTaskRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BatchCreateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTaskRequest} message BatchCreateTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateTaskRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sherlockTasks != null && message.sherlockTasks.length)
                            for (var i = 0; i < message.sherlockTasks.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTask.encode(message.sherlockTasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateTaskRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BatchCreateTaskRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTaskRequest} message BatchCreateTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateTaskRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BatchCreateTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BatchCreateTaskRequest} BatchCreateTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateTaskRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BatchCreateTaskRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.sherlockTasks && message.sherlockTasks.length))
                                    message.sherlockTasks = [];
                                message.sherlockTasks.push($root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateTaskRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BatchCreateTaskRequest} BatchCreateTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateTaskRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BatchCreateTaskRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateTaskRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sherlockTasks != null && message.hasOwnProperty("sherlockTasks")) {
                            if (!Array.isArray(message.sherlockTasks))
                                return "sherlockTasks: array expected";
                            for (var i = 0; i < message.sherlockTasks.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.sherlockTasks[i]);
                                if (error)
                                    return "sherlockTasks." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateTaskRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BatchCreateTaskRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BatchCreateTaskRequest} BatchCreateTaskRequest
                     */
                    BatchCreateTaskRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BatchCreateTaskRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BatchCreateTaskRequest();
                        if (object.sherlockTasks) {
                            if (!Array.isArray(object.sherlockTasks))
                                throw TypeError(".palexy.sherlock.v1.BatchCreateTaskRequest.sherlockTasks: array expected");
                            message.sherlockTasks = [];
                            for (var i = 0; i < object.sherlockTasks.length; ++i) {
                                if (typeof object.sherlockTasks[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.BatchCreateTaskRequest.sherlockTasks: object expected");
                                message.sherlockTasks[i] = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.sherlockTasks[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateTaskRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BatchCreateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.BatchCreateTaskRequest} message BatchCreateTaskRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateTaskRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.sherlockTasks = [];
                        if (message.sherlockTasks && message.sherlockTasks.length) {
                            object.sherlockTasks = [];
                            for (var j = 0; j < message.sherlockTasks.length; ++j)
                                object.sherlockTasks[j] = $root.palexy.sherlock.v1.SherlockTask.toObject(message.sherlockTasks[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateTaskRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BatchCreateTaskRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateTaskRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateTaskRequest;
                })();
    
                v1.BatchCreateTaskResponse = (function() {
    
                    /**
                     * Properties of a BatchCreateTaskResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IBatchCreateTaskResponse
                     * @property {Array.<palexy.sherlock.v1.ISherlockTask>|null} [sherlockTask] BatchCreateTaskResponse sherlockTask
                     */
    
                    /**
                     * Constructs a new BatchCreateTaskResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BatchCreateTaskResponse.
                     * @implements IBatchCreateTaskResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IBatchCreateTaskResponse=} [properties] Properties to set
                     */
                    function BatchCreateTaskResponse(properties) {
                        this.sherlockTask = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateTaskResponse sherlockTask.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTask>} sherlockTask
                     * @memberof palexy.sherlock.v1.BatchCreateTaskResponse
                     * @instance
                     */
                    BatchCreateTaskResponse.prototype.sherlockTask = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateTaskResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BatchCreateTaskResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTaskResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BatchCreateTaskResponse} BatchCreateTaskResponse instance
                     */
                    BatchCreateTaskResponse.create = function create(properties) {
                        return new BatchCreateTaskResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateTaskResponse message. Does not implicitly {@link palexy.sherlock.v1.BatchCreateTaskResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BatchCreateTaskResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTaskResponse} message BatchCreateTaskResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateTaskResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sherlockTask != null && message.sherlockTask.length)
                            for (var i = 0; i < message.sherlockTask.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTask.encode(message.sherlockTask[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateTaskResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BatchCreateTaskResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateTaskResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTaskResponse} message BatchCreateTaskResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateTaskResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BatchCreateTaskResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BatchCreateTaskResponse} BatchCreateTaskResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateTaskResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BatchCreateTaskResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.sherlockTask && message.sherlockTask.length))
                                    message.sherlockTask = [];
                                message.sherlockTask.push($root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateTaskResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateTaskResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BatchCreateTaskResponse} BatchCreateTaskResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateTaskResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BatchCreateTaskResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateTaskResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sherlockTask != null && message.hasOwnProperty("sherlockTask")) {
                            if (!Array.isArray(message.sherlockTask))
                                return "sherlockTask: array expected";
                            for (var i = 0; i < message.sherlockTask.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.sherlockTask[i]);
                                if (error)
                                    return "sherlockTask." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateTaskResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BatchCreateTaskResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BatchCreateTaskResponse} BatchCreateTaskResponse
                     */
                    BatchCreateTaskResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BatchCreateTaskResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BatchCreateTaskResponse();
                        if (object.sherlockTask) {
                            if (!Array.isArray(object.sherlockTask))
                                throw TypeError(".palexy.sherlock.v1.BatchCreateTaskResponse.sherlockTask: array expected");
                            message.sherlockTask = [];
                            for (var i = 0; i < object.sherlockTask.length; ++i) {
                                if (typeof object.sherlockTask[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.BatchCreateTaskResponse.sherlockTask: object expected");
                                message.sherlockTask[i] = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.sherlockTask[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateTaskResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BatchCreateTaskResponse
                     * @static
                     * @param {palexy.sherlock.v1.BatchCreateTaskResponse} message BatchCreateTaskResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateTaskResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.sherlockTask = [];
                        if (message.sherlockTask && message.sherlockTask.length) {
                            object.sherlockTask = [];
                            for (var j = 0; j < message.sherlockTask.length; ++j)
                                object.sherlockTask[j] = $root.palexy.sherlock.v1.SherlockTask.toObject(message.sherlockTask[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateTaskResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BatchCreateTaskResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateTaskResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateTaskResponse;
                })();
    
                v1.ListTasksRequest = (function() {
    
                    /**
                     * Properties of a ListTasksRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IListTasksRequest
                     * @property {palexy.sherlock.v1.ITaskFilter|null} [taskFilter] ListTasksRequest taskFilter
                     * @property {boolean|null} [includeTaskResults] ListTasksRequest includeTaskResults
                     * @property {number|null} [pageSize] ListTasksRequest pageSize
                     * @property {number|null} [pageIndex] ListTasksRequest pageIndex
                     */
    
                    /**
                     * Constructs a new ListTasksRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListTasksRequest.
                     * @implements IListTasksRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IListTasksRequest=} [properties] Properties to set
                     */
                    function ListTasksRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListTasksRequest taskFilter.
                     * @member {palexy.sherlock.v1.ITaskFilter|null|undefined} taskFilter
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @instance
                     */
                    ListTasksRequest.prototype.taskFilter = null;
    
                    /**
                     * ListTasksRequest includeTaskResults.
                     * @member {boolean} includeTaskResults
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @instance
                     */
                    ListTasksRequest.prototype.includeTaskResults = false;
    
                    /**
                     * ListTasksRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @instance
                     */
                    ListTasksRequest.prototype.pageSize = 0;
    
                    /**
                     * ListTasksRequest pageIndex.
                     * @member {number} pageIndex
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @instance
                     */
                    ListTasksRequest.prototype.pageIndex = 0;
    
                    /**
                     * Creates a new ListTasksRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTasksRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListTasksRequest} ListTasksRequest instance
                     */
                    ListTasksRequest.create = function create(properties) {
                        return new ListTasksRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListTasksRequest message. Does not implicitly {@link palexy.sherlock.v1.ListTasksRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTasksRequest} message ListTasksRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTasksRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.taskFilter != null && Object.hasOwnProperty.call(message, "taskFilter"))
                            $root.palexy.sherlock.v1.TaskFilter.encode(message.taskFilter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.includeTaskResults != null && Object.hasOwnProperty.call(message, "includeTaskResults"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeTaskResults);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
                        if (message.pageIndex != null && Object.hasOwnProperty.call(message, "pageIndex"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pageIndex);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListTasksRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListTasksRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTasksRequest} message ListTasksRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListTasksRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListTasksRequest} ListTasksRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTasksRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListTasksRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.taskFilter = $root.palexy.sherlock.v1.TaskFilter.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.includeTaskResults = reader.bool();
                                break;
                            case 3:
                                message.pageSize = reader.int32();
                                break;
                            case 4:
                                message.pageIndex = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListTasksRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListTasksRequest} ListTasksRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTasksRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListTasksRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListTasksRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.taskFilter != null && message.hasOwnProperty("taskFilter")) {
                            var error = $root.palexy.sherlock.v1.TaskFilter.verify(message.taskFilter);
                            if (error)
                                return "taskFilter." + error;
                        }
                        if (message.includeTaskResults != null && message.hasOwnProperty("includeTaskResults"))
                            if (typeof message.includeTaskResults !== "boolean")
                                return "includeTaskResults: boolean expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageIndex != null && message.hasOwnProperty("pageIndex"))
                            if (!$util.isInteger(message.pageIndex))
                                return "pageIndex: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListTasksRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListTasksRequest} ListTasksRequest
                     */
                    ListTasksRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListTasksRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListTasksRequest();
                        if (object.taskFilter != null) {
                            if (typeof object.taskFilter !== "object")
                                throw TypeError(".palexy.sherlock.v1.ListTasksRequest.taskFilter: object expected");
                            message.taskFilter = $root.palexy.sherlock.v1.TaskFilter.fromObject(object.taskFilter);
                        }
                        if (object.includeTaskResults != null)
                            message.includeTaskResults = Boolean(object.includeTaskResults);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageIndex != null)
                            message.pageIndex = object.pageIndex | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListTasksRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @static
                     * @param {palexy.sherlock.v1.ListTasksRequest} message ListTasksRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListTasksRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.taskFilter = null;
                            object.includeTaskResults = false;
                            object.pageSize = 0;
                            object.pageIndex = 0;
                        }
                        if (message.taskFilter != null && message.hasOwnProperty("taskFilter"))
                            object.taskFilter = $root.palexy.sherlock.v1.TaskFilter.toObject(message.taskFilter, options);
                        if (message.includeTaskResults != null && message.hasOwnProperty("includeTaskResults"))
                            object.includeTaskResults = message.includeTaskResults;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageIndex != null && message.hasOwnProperty("pageIndex"))
                            object.pageIndex = message.pageIndex;
                        return object;
                    };
    
                    /**
                     * Converts this ListTasksRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListTasksRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListTasksRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListTasksRequest;
                })();
    
                v1.TaskFilter = (function() {
    
                    /**
                     * Properties of a TaskFilter.
                     * @memberof palexy.sherlock.v1
                     * @interface ITaskFilter
                     * @property {palexy.sherlock.v1.SherlockTask.TaskStatus|null} [taskStatus] TaskFilter taskStatus
                     * @property {number|null} [storeConfigId] TaskFilter storeConfigId
                     * @property {palexy.sherlock.v1.SherlockTask.TaskType|null} [taskType] TaskFilter taskType
                     */
    
                    /**
                     * Constructs a new TaskFilter.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a TaskFilter.
                     * @implements ITaskFilter
                     * @constructor
                     * @param {palexy.sherlock.v1.ITaskFilter=} [properties] Properties to set
                     */
                    function TaskFilter(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TaskFilter taskStatus.
                     * @member {palexy.sherlock.v1.SherlockTask.TaskStatus} taskStatus
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @instance
                     */
                    TaskFilter.prototype.taskStatus = 0;
    
                    /**
                     * TaskFilter storeConfigId.
                     * @member {number} storeConfigId
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @instance
                     */
                    TaskFilter.prototype.storeConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * TaskFilter taskType.
                     * @member {palexy.sherlock.v1.SherlockTask.TaskType} taskType
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @instance
                     */
                    TaskFilter.prototype.taskType = 0;
    
                    /**
                     * Creates a new TaskFilter instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @static
                     * @param {palexy.sherlock.v1.ITaskFilter=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.TaskFilter} TaskFilter instance
                     */
                    TaskFilter.create = function create(properties) {
                        return new TaskFilter(properties);
                    };
    
                    /**
                     * Encodes the specified TaskFilter message. Does not implicitly {@link palexy.sherlock.v1.TaskFilter.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @static
                     * @param {palexy.sherlock.v1.ITaskFilter} message TaskFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskFilter.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.taskStatus != null && Object.hasOwnProperty.call(message, "taskStatus"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.taskStatus);
                        if (message.storeConfigId != null && Object.hasOwnProperty.call(message, "storeConfigId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeConfigId);
                        if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.taskType);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TaskFilter message, length delimited. Does not implicitly {@link palexy.sherlock.v1.TaskFilter.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @static
                     * @param {palexy.sherlock.v1.ITaskFilter} message TaskFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskFilter.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TaskFilter message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.TaskFilter} TaskFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskFilter.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.TaskFilter();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.taskStatus = reader.int32();
                                break;
                            case 2:
                                message.storeConfigId = reader.int64();
                                break;
                            case 3:
                                message.taskType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TaskFilter message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.TaskFilter} TaskFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskFilter.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TaskFilter message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TaskFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.taskStatus != null && message.hasOwnProperty("taskStatus"))
                            switch (message.taskStatus) {
                            default:
                                return "taskStatus: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (!$util.isInteger(message.storeConfigId) && !(message.storeConfigId && $util.isInteger(message.storeConfigId.low) && $util.isInteger(message.storeConfigId.high)))
                                return "storeConfigId: integer|Long expected";
                        if (message.taskType != null && message.hasOwnProperty("taskType"))
                            switch (message.taskType) {
                            default:
                                return "taskType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a TaskFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.TaskFilter} TaskFilter
                     */
                    TaskFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.TaskFilter)
                            return object;
                        var message = new $root.palexy.sherlock.v1.TaskFilter();
                        switch (object.taskStatus) {
                        case "STATUS_UNKNOWN":
                        case 0:
                            message.taskStatus = 0;
                            break;
                        case "STATUS_CREATED":
                        case 1:
                            message.taskStatus = 1;
                            break;
                        case "STATUS_DONE":
                        case 2:
                            message.taskStatus = 2;
                            break;
                        case "STATUS_FAILED":
                        case 3:
                            message.taskStatus = 3;
                            break;
                        }
                        if (object.storeConfigId != null)
                            if ($util.Long)
                                (message.storeConfigId = $util.Long.fromValue(object.storeConfigId)).unsigned = false;
                            else if (typeof object.storeConfigId === "string")
                                message.storeConfigId = parseInt(object.storeConfigId, 10);
                            else if (typeof object.storeConfigId === "number")
                                message.storeConfigId = object.storeConfigId;
                            else if (typeof object.storeConfigId === "object")
                                message.storeConfigId = new $util.LongBits(object.storeConfigId.low >>> 0, object.storeConfigId.high >>> 0).toNumber();
                        switch (object.taskType) {
                        case "TYPE_UNKNOWN":
                        case 0:
                            message.taskType = 0;
                            break;
                        case "TYPE_TEST":
                        case 1:
                            message.taskType = 1;
                            break;
                        case "TYPE_DEBUG_PERSON":
                        case 2:
                            message.taskType = 2;
                            break;
                        case "TYPE_EXTRACT_TRACKLET":
                        case 3:
                            message.taskType = 3;
                            break;
                        case "TYPE_DELETE_TASK_FILES":
                        case 4:
                            message.taskType = 4;
                            break;
                        case "TYPE_CALCULATE_MERGE_ACCURACY":
                        case 5:
                            message.taskType = 5;
                            break;
                        case "TYPE_EXECUTE_POST_MERGE_CONFIG":
                        case 6:
                            message.taskType = 6;
                            break;
                        case "TYPE_SCAN_MERGE_CONFIG":
                        case 7:
                            message.taskType = 7;
                            break;
                        case "TYPE_RERUN_MERGE_CONFIG":
                        case 8:
                            message.taskType = 8;
                            break;
                        case "TYPE_DEBUG_EXPERIMENT":
                        case 9:
                            message.taskType = 9;
                            break;
                        case "TYPE_PREPARE_QC_VIDEOS":
                        case 10:
                            message.taskType = 10;
                            break;
                        case "TYPE_HS_COLLECT_REID":
                        case 11:
                            message.taskType = 11;
                            break;
                        case "TYPE_TINKER_POST_PROCESS":
                        case 12:
                            message.taskType = 12;
                            break;
                        case "TYPE_FORECAST_GENERATE":
                        case 13:
                            message.taskType = 13;
                            break;
                        case "TYPE_EXPORT_HAKUHODO":
                        case 14:
                            message.taskType = 14;
                            break;
                        case "TYPE_EXPORT_OSOOL_EVENTS":
                        case 15:
                            message.taskType = 15;
                            break;
                        case "TYPE_HAKUHODO_JOIN_PARTIAL_MERGING_RESULTS":
                        case 16:
                            message.taskType = 16;
                            break;
                        case "TYPE_HAKUHODO_EDIT_TRAFFIC":
                        case 17:
                            message.taskType = 17;
                            break;
                        case "TYPE_HAKUHODO_EXTRAPOLATE_DEMOGRAPHY":
                        case 18:
                            message.taskType = 18;
                            break;
                        case "TYPE_BATCH_DELETE_VIDEOS":
                        case 19:
                            message.taskType = 19;
                            break;
                        case "TYPE_BUILD_SEED":
                        case 20:
                            message.taskType = 20;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TaskFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @static
                     * @param {palexy.sherlock.v1.TaskFilter} message TaskFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TaskFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.taskStatus = options.enums === String ? "STATUS_UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeConfigId = options.longs === String ? "0" : 0;
                            object.taskType = options.enums === String ? "TYPE_UNKNOWN" : 0;
                        }
                        if (message.taskStatus != null && message.hasOwnProperty("taskStatus"))
                            object.taskStatus = options.enums === String ? $root.palexy.sherlock.v1.SherlockTask.TaskStatus[message.taskStatus] : message.taskStatus;
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (typeof message.storeConfigId === "number")
                                object.storeConfigId = options.longs === String ? String(message.storeConfigId) : message.storeConfigId;
                            else
                                object.storeConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.storeConfigId) : options.longs === Number ? new $util.LongBits(message.storeConfigId.low >>> 0, message.storeConfigId.high >>> 0).toNumber() : message.storeConfigId;
                        if (message.taskType != null && message.hasOwnProperty("taskType"))
                            object.taskType = options.enums === String ? $root.palexy.sherlock.v1.SherlockTask.TaskType[message.taskType] : message.taskType;
                        return object;
                    };
    
                    /**
                     * Converts this TaskFilter to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.TaskFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TaskFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TaskFilter;
                })();
    
                v1.ListTasksResponse = (function() {
    
                    /**
                     * Properties of a ListTasksResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IListTasksResponse
                     * @property {Array.<palexy.sherlock.v1.ISherlockTask>|null} [sherlockTasks] ListTasksResponse sherlockTasks
                     * @property {number|null} [pageSize] ListTasksResponse pageSize
                     * @property {number|null} [pageIndex] ListTasksResponse pageIndex
                     * @property {number|null} [totalElements] ListTasksResponse totalElements
                     */
    
                    /**
                     * Constructs a new ListTasksResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListTasksResponse.
                     * @implements IListTasksResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IListTasksResponse=} [properties] Properties to set
                     */
                    function ListTasksResponse(properties) {
                        this.sherlockTasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListTasksResponse sherlockTasks.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTask>} sherlockTasks
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @instance
                     */
                    ListTasksResponse.prototype.sherlockTasks = $util.emptyArray;
    
                    /**
                     * ListTasksResponse pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @instance
                     */
                    ListTasksResponse.prototype.pageSize = 0;
    
                    /**
                     * ListTasksResponse pageIndex.
                     * @member {number} pageIndex
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @instance
                     */
                    ListTasksResponse.prototype.pageIndex = 0;
    
                    /**
                     * ListTasksResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @instance
                     */
                    ListTasksResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListTasksResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTasksResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListTasksResponse} ListTasksResponse instance
                     */
                    ListTasksResponse.create = function create(properties) {
                        return new ListTasksResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListTasksResponse message. Does not implicitly {@link palexy.sherlock.v1.ListTasksResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTasksResponse} message ListTasksResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTasksResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sherlockTasks != null && message.sherlockTasks.length)
                            for (var i = 0; i < message.sherlockTasks.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTask.encode(message.sherlockTasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageSize);
                        if (message.pageIndex != null && Object.hasOwnProperty.call(message, "pageIndex"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageIndex);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.totalElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListTasksResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListTasksResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTasksResponse} message ListTasksResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTasksResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListTasksResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListTasksResponse} ListTasksResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTasksResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListTasksResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.sherlockTasks && message.sherlockTasks.length))
                                    message.sherlockTasks = [];
                                message.sherlockTasks.push($root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.pageSize = reader.int32();
                                break;
                            case 3:
                                message.pageIndex = reader.int32();
                                break;
                            case 4:
                                message.totalElements = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListTasksResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListTasksResponse} ListTasksResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTasksResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListTasksResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListTasksResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sherlockTasks != null && message.hasOwnProperty("sherlockTasks")) {
                            if (!Array.isArray(message.sherlockTasks))
                                return "sherlockTasks: array expected";
                            for (var i = 0; i < message.sherlockTasks.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.sherlockTasks[i]);
                                if (error)
                                    return "sherlockTasks." + error;
                            }
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageIndex != null && message.hasOwnProperty("pageIndex"))
                            if (!$util.isInteger(message.pageIndex))
                                return "pageIndex: integer expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListTasksResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListTasksResponse} ListTasksResponse
                     */
                    ListTasksResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListTasksResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListTasksResponse();
                        if (object.sherlockTasks) {
                            if (!Array.isArray(object.sherlockTasks))
                                throw TypeError(".palexy.sherlock.v1.ListTasksResponse.sherlockTasks: array expected");
                            message.sherlockTasks = [];
                            for (var i = 0; i < object.sherlockTasks.length; ++i) {
                                if (typeof object.sherlockTasks[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ListTasksResponse.sherlockTasks: object expected");
                                message.sherlockTasks[i] = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.sherlockTasks[i]);
                            }
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageIndex != null)
                            message.pageIndex = object.pageIndex | 0;
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListTasksResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @static
                     * @param {palexy.sherlock.v1.ListTasksResponse} message ListTasksResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListTasksResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.sherlockTasks = [];
                        if (options.defaults) {
                            object.pageSize = 0;
                            object.pageIndex = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                        }
                        if (message.sherlockTasks && message.sherlockTasks.length) {
                            object.sherlockTasks = [];
                            for (var j = 0; j < message.sherlockTasks.length; ++j)
                                object.sherlockTasks[j] = $root.palexy.sherlock.v1.SherlockTask.toObject(message.sherlockTasks[j], options);
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageIndex != null && message.hasOwnProperty("pageIndex"))
                            object.pageIndex = message.pageIndex;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        return object;
                    };
    
                    /**
                     * Converts this ListTasksResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListTasksResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListTasksResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListTasksResponse;
                })();
    
                v1.SherlockTaskAndAckId = (function() {
    
                    /**
                     * Properties of a SherlockTaskAndAckId.
                     * @memberof palexy.sherlock.v1
                     * @interface ISherlockTaskAndAckId
                     * @property {palexy.sherlock.v1.ISherlockTask|null} [task] SherlockTaskAndAckId task
                     * @property {string|null} [ackId] SherlockTaskAndAckId ackId
                     */
    
                    /**
                     * Constructs a new SherlockTaskAndAckId.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a SherlockTaskAndAckId.
                     * @implements ISherlockTaskAndAckId
                     * @constructor
                     * @param {palexy.sherlock.v1.ISherlockTaskAndAckId=} [properties] Properties to set
                     */
                    function SherlockTaskAndAckId(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SherlockTaskAndAckId task.
                     * @member {palexy.sherlock.v1.ISherlockTask|null|undefined} task
                     * @memberof palexy.sherlock.v1.SherlockTaskAndAckId
                     * @instance
                     */
                    SherlockTaskAndAckId.prototype.task = null;
    
                    /**
                     * SherlockTaskAndAckId ackId.
                     * @member {string} ackId
                     * @memberof palexy.sherlock.v1.SherlockTaskAndAckId
                     * @instance
                     */
                    SherlockTaskAndAckId.prototype.ackId = "";
    
                    /**
                     * Creates a new SherlockTaskAndAckId instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.SherlockTaskAndAckId
                     * @static
                     * @param {palexy.sherlock.v1.ISherlockTaskAndAckId=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.SherlockTaskAndAckId} SherlockTaskAndAckId instance
                     */
                    SherlockTaskAndAckId.create = function create(properties) {
                        return new SherlockTaskAndAckId(properties);
                    };
    
                    /**
                     * Encodes the specified SherlockTaskAndAckId message. Does not implicitly {@link palexy.sherlock.v1.SherlockTaskAndAckId.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.SherlockTaskAndAckId
                     * @static
                     * @param {palexy.sherlock.v1.ISherlockTaskAndAckId} message SherlockTaskAndAckId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SherlockTaskAndAckId.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                            $root.palexy.sherlock.v1.SherlockTask.encode(message.task, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.ackId != null && Object.hasOwnProperty.call(message, "ackId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.ackId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SherlockTaskAndAckId message, length delimited. Does not implicitly {@link palexy.sherlock.v1.SherlockTaskAndAckId.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.SherlockTaskAndAckId
                     * @static
                     * @param {palexy.sherlock.v1.ISherlockTaskAndAckId} message SherlockTaskAndAckId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SherlockTaskAndAckId.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SherlockTaskAndAckId message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.SherlockTaskAndAckId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.SherlockTaskAndAckId} SherlockTaskAndAckId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SherlockTaskAndAckId.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.SherlockTaskAndAckId();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.task = $root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.ackId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SherlockTaskAndAckId message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.SherlockTaskAndAckId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.SherlockTaskAndAckId} SherlockTaskAndAckId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SherlockTaskAndAckId.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SherlockTaskAndAckId message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.SherlockTaskAndAckId
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SherlockTaskAndAckId.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.task != null && message.hasOwnProperty("task")) {
                            var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.task);
                            if (error)
                                return "task." + error;
                        }
                        if (message.ackId != null && message.hasOwnProperty("ackId"))
                            if (!$util.isString(message.ackId))
                                return "ackId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a SherlockTaskAndAckId message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.SherlockTaskAndAckId
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.SherlockTaskAndAckId} SherlockTaskAndAckId
                     */
                    SherlockTaskAndAckId.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.SherlockTaskAndAckId)
                            return object;
                        var message = new $root.palexy.sherlock.v1.SherlockTaskAndAckId();
                        if (object.task != null) {
                            if (typeof object.task !== "object")
                                throw TypeError(".palexy.sherlock.v1.SherlockTaskAndAckId.task: object expected");
                            message.task = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.task);
                        }
                        if (object.ackId != null)
                            message.ackId = String(object.ackId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SherlockTaskAndAckId message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.SherlockTaskAndAckId
                     * @static
                     * @param {palexy.sherlock.v1.SherlockTaskAndAckId} message SherlockTaskAndAckId
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SherlockTaskAndAckId.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.task = null;
                            object.ackId = "";
                        }
                        if (message.task != null && message.hasOwnProperty("task"))
                            object.task = $root.palexy.sherlock.v1.SherlockTask.toObject(message.task, options);
                        if (message.ackId != null && message.hasOwnProperty("ackId"))
                            object.ackId = message.ackId;
                        return object;
                    };
    
                    /**
                     * Converts this SherlockTaskAndAckId to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.SherlockTaskAndAckId
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SherlockTaskAndAckId.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SherlockTaskAndAckId;
                })();
    
                v1.UpdateTaskRequest = (function() {
    
                    /**
                     * Properties of an UpdateTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IUpdateTaskRequest
                     * @property {palexy.sherlock.v1.ISherlockTask|null} [task] UpdateTaskRequest task
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateTaskRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UpdateTaskRequest.
                     * @implements IUpdateTaskRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IUpdateTaskRequest=} [properties] Properties to set
                     */
                    function UpdateTaskRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateTaskRequest task.
                     * @member {palexy.sherlock.v1.ISherlockTask|null|undefined} task
                     * @memberof palexy.sherlock.v1.UpdateTaskRequest
                     * @instance
                     */
                    UpdateTaskRequest.prototype.task = null;
    
                    /**
                     * UpdateTaskRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.sherlock.v1.UpdateTaskRequest
                     * @instance
                     */
                    UpdateTaskRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateTaskRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UpdateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateTaskRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UpdateTaskRequest} UpdateTaskRequest instance
                     */
                    UpdateTaskRequest.create = function create(properties) {
                        return new UpdateTaskRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateTaskRequest message. Does not implicitly {@link palexy.sherlock.v1.UpdateTaskRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UpdateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateTaskRequest} message UpdateTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateTaskRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                            $root.palexy.sherlock.v1.SherlockTask.encode(message.task, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateTaskRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UpdateTaskRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateTaskRequest} message UpdateTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateTaskRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UpdateTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UpdateTaskRequest} UpdateTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateTaskRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UpdateTaskRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.task = $root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateTaskRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UpdateTaskRequest} UpdateTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateTaskRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UpdateTaskRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateTaskRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.task != null && message.hasOwnProperty("task")) {
                            var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.task);
                            if (error)
                                return "task." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateTaskRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UpdateTaskRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UpdateTaskRequest} UpdateTaskRequest
                     */
                    UpdateTaskRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UpdateTaskRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.UpdateTaskRequest();
                        if (object.task != null) {
                            if (typeof object.task !== "object")
                                throw TypeError(".palexy.sherlock.v1.UpdateTaskRequest.task: object expected");
                            message.task = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.task);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.sherlock.v1.UpdateTaskRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateTaskRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UpdateTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.UpdateTaskRequest} message UpdateTaskRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateTaskRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.task = null;
                            object.updateMask = null;
                        }
                        if (message.task != null && message.hasOwnProperty("task"))
                            object.task = $root.palexy.sherlock.v1.SherlockTask.toObject(message.task, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateTaskRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UpdateTaskRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateTaskRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateTaskRequest;
                })();
    
                v1.GetTaskRequest = (function() {
    
                    /**
                     * Properties of a GetTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IGetTaskRequest
                     * @property {string|null} [name] GetTaskRequest name
                     */
    
                    /**
                     * Constructs a new GetTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GetTaskRequest.
                     * @implements IGetTaskRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IGetTaskRequest=} [properties] Properties to set
                     */
                    function GetTaskRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetTaskRequest name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.GetTaskRequest
                     * @instance
                     */
                    GetTaskRequest.prototype.name = "";
    
                    /**
                     * Creates a new GetTaskRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GetTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGetTaskRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GetTaskRequest} GetTaskRequest instance
                     */
                    GetTaskRequest.create = function create(properties) {
                        return new GetTaskRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetTaskRequest message. Does not implicitly {@link palexy.sherlock.v1.GetTaskRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GetTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGetTaskRequest} message GetTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetTaskRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetTaskRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GetTaskRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GetTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGetTaskRequest} message GetTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetTaskRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GetTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GetTaskRequest} GetTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetTaskRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GetTaskRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetTaskRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GetTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GetTaskRequest} GetTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetTaskRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GetTaskRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetTaskRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetTaskRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GetTaskRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GetTaskRequest} GetTaskRequest
                     */
                    GetTaskRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GetTaskRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GetTaskRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetTaskRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GetTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.GetTaskRequest} message GetTaskRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetTaskRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this GetTaskRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GetTaskRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetTaskRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetTaskRequest;
                })();
    
                v1.DeleteTaskRequest = (function() {
    
                    /**
                     * Properties of a DeleteTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IDeleteTaskRequest
                     * @property {string|null} [name] DeleteTaskRequest name
                     */
    
                    /**
                     * Constructs a new DeleteTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DeleteTaskRequest.
                     * @implements IDeleteTaskRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IDeleteTaskRequest=} [properties] Properties to set
                     */
                    function DeleteTaskRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteTaskRequest name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.DeleteTaskRequest
                     * @instance
                     */
                    DeleteTaskRequest.prototype.name = "";
    
                    /**
                     * Creates a new DeleteTaskRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DeleteTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteTaskRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DeleteTaskRequest} DeleteTaskRequest instance
                     */
                    DeleteTaskRequest.create = function create(properties) {
                        return new DeleteTaskRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteTaskRequest message. Does not implicitly {@link palexy.sherlock.v1.DeleteTaskRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DeleteTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteTaskRequest} message DeleteTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteTaskRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteTaskRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DeleteTaskRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteTaskRequest} message DeleteTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteTaskRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DeleteTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DeleteTaskRequest} DeleteTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteTaskRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DeleteTaskRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteTaskRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DeleteTaskRequest} DeleteTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteTaskRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DeleteTaskRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteTaskRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteTaskRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DeleteTaskRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DeleteTaskRequest} DeleteTaskRequest
                     */
                    DeleteTaskRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DeleteTaskRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.DeleteTaskRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteTaskRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DeleteTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.DeleteTaskRequest} message DeleteTaskRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteTaskRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteTaskRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DeleteTaskRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteTaskRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteTaskRequest;
                })();
    
                v1.CreateDebugTaskForStoreConfigResponse = (function() {
    
                    /**
                     * Properties of a CreateDebugTaskForStoreConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateDebugTaskForStoreConfigResponse
                     * @property {Array.<palexy.sherlock.v1.ISherlockTask>|null} [tasks] CreateDebugTaskForStoreConfigResponse tasks
                     */
    
                    /**
                     * Constructs a new CreateDebugTaskForStoreConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateDebugTaskForStoreConfigResponse.
                     * @implements ICreateDebugTaskForStoreConfigResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForStoreConfigResponse=} [properties] Properties to set
                     */
                    function CreateDebugTaskForStoreConfigResponse(properties) {
                        this.tasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateDebugTaskForStoreConfigResponse tasks.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTask>} tasks
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse
                     * @instance
                     */
                    CreateDebugTaskForStoreConfigResponse.prototype.tasks = $util.emptyArray;
    
                    /**
                     * Creates a new CreateDebugTaskForStoreConfigResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForStoreConfigResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse} CreateDebugTaskForStoreConfigResponse instance
                     */
                    CreateDebugTaskForStoreConfigResponse.create = function create(properties) {
                        return new CreateDebugTaskForStoreConfigResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateDebugTaskForStoreConfigResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForStoreConfigResponse} message CreateDebugTaskForStoreConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebugTaskForStoreConfigResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.tasks != null && message.tasks.length)
                            for (var i = 0; i < message.tasks.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTask.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateDebugTaskForStoreConfigResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForStoreConfigResponse} message CreateDebugTaskForStoreConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebugTaskForStoreConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateDebugTaskForStoreConfigResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse} CreateDebugTaskForStoreConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebugTaskForStoreConfigResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateDebugTaskForStoreConfigResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse} CreateDebugTaskForStoreConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebugTaskForStoreConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateDebugTaskForStoreConfigResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateDebugTaskForStoreConfigResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.tasks != null && message.hasOwnProperty("tasks")) {
                            if (!Array.isArray(message.tasks))
                                return "tasks: array expected";
                            for (var i = 0; i < message.tasks.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.tasks[i]);
                                if (error)
                                    return "tasks." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateDebugTaskForStoreConfigResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse} CreateDebugTaskForStoreConfigResponse
                     */
                    CreateDebugTaskForStoreConfigResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse();
                        if (object.tasks) {
                            if (!Array.isArray(object.tasks))
                                throw TypeError(".palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse.tasks: array expected");
                            message.tasks = [];
                            for (var i = 0; i < object.tasks.length; ++i) {
                                if (typeof object.tasks[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse.tasks: object expected");
                                message.tasks[i] = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.tasks[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateDebugTaskForStoreConfigResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse} message CreateDebugTaskForStoreConfigResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateDebugTaskForStoreConfigResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.tasks = [];
                        if (message.tasks && message.tasks.length) {
                            object.tasks = [];
                            for (var j = 0; j < message.tasks.length; ++j)
                                object.tasks[j] = $root.palexy.sherlock.v1.SherlockTask.toObject(message.tasks[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CreateDebugTaskForStoreConfigResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateDebugTaskForStoreConfigResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateDebugTaskForStoreConfigResponse;
                })();
    
                v1.CreateDebugTaskForExperimentResponse = (function() {
    
                    /**
                     * Properties of a CreateDebugTaskForExperimentResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateDebugTaskForExperimentResponse
                     * @property {Array.<palexy.sherlock.v1.ISherlockTask>|null} [tasks] CreateDebugTaskForExperimentResponse tasks
                     */
    
                    /**
                     * Constructs a new CreateDebugTaskForExperimentResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateDebugTaskForExperimentResponse.
                     * @implements ICreateDebugTaskForExperimentResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForExperimentResponse=} [properties] Properties to set
                     */
                    function CreateDebugTaskForExperimentResponse(properties) {
                        this.tasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateDebugTaskForExperimentResponse tasks.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTask>} tasks
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentResponse
                     * @instance
                     */
                    CreateDebugTaskForExperimentResponse.prototype.tasks = $util.emptyArray;
    
                    /**
                     * Creates a new CreateDebugTaskForExperimentResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForExperimentResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForExperimentResponse} CreateDebugTaskForExperimentResponse instance
                     */
                    CreateDebugTaskForExperimentResponse.create = function create(properties) {
                        return new CreateDebugTaskForExperimentResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateDebugTaskForExperimentResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateDebugTaskForExperimentResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForExperimentResponse} message CreateDebugTaskForExperimentResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebugTaskForExperimentResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.tasks != null && message.tasks.length)
                            for (var i = 0; i < message.tasks.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTask.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateDebugTaskForExperimentResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateDebugTaskForExperimentResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForExperimentResponse} message CreateDebugTaskForExperimentResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebugTaskForExperimentResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateDebugTaskForExperimentResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForExperimentResponse} CreateDebugTaskForExperimentResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebugTaskForExperimentResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateDebugTaskForExperimentResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateDebugTaskForExperimentResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForExperimentResponse} CreateDebugTaskForExperimentResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebugTaskForExperimentResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateDebugTaskForExperimentResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateDebugTaskForExperimentResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.tasks != null && message.hasOwnProperty("tasks")) {
                            if (!Array.isArray(message.tasks))
                                return "tasks: array expected";
                            for (var i = 0; i < message.tasks.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.tasks[i]);
                                if (error)
                                    return "tasks." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateDebugTaskForExperimentResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForExperimentResponse} CreateDebugTaskForExperimentResponse
                     */
                    CreateDebugTaskForExperimentResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateDebugTaskForExperimentResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateDebugTaskForExperimentResponse();
                        if (object.tasks) {
                            if (!Array.isArray(object.tasks))
                                throw TypeError(".palexy.sherlock.v1.CreateDebugTaskForExperimentResponse.tasks: array expected");
                            message.tasks = [];
                            for (var i = 0; i < object.tasks.length; ++i) {
                                if (typeof object.tasks[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.CreateDebugTaskForExperimentResponse.tasks: object expected");
                                message.tasks[i] = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.tasks[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateDebugTaskForExperimentResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateDebugTaskForExperimentResponse} message CreateDebugTaskForExperimentResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateDebugTaskForExperimentResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.tasks = [];
                        if (message.tasks && message.tasks.length) {
                            object.tasks = [];
                            for (var j = 0; j < message.tasks.length; ++j)
                                object.tasks[j] = $root.palexy.sherlock.v1.SherlockTask.toObject(message.tasks[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CreateDebugTaskForExperimentResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateDebugTaskForExperimentResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateDebugTaskForExperimentResponse;
                })();
    
                v1.CreateDebugTaskForStoreConfigRequest = (function() {
    
                    /**
                     * Properties of a CreateDebugTaskForStoreConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateDebugTaskForStoreConfigRequest
                     * @property {number|null} [storeConfigId] CreateDebugTaskForStoreConfigRequest storeConfigId
                     * @property {number|null} [storeId] CreateDebugTaskForStoreConfigRequest storeId
                     * @property {string|null} [dateId] CreateDebugTaskForStoreConfigRequest dateId
                     * @property {number|null} [startHour] CreateDebugTaskForStoreConfigRequest startHour
                     * @property {number|null} [endHour] CreateDebugTaskForStoreConfigRequest endHour
                     * @property {number|null} [maxHoursToSplit] CreateDebugTaskForStoreConfigRequest maxHoursToSplit
                     * @property {boolean|null} [includeFfmpegResults] CreateDebugTaskForStoreConfigRequest includeFfmpegResults
                     * @property {boolean|null} [generateBbLevelDetail] CreateDebugTaskForStoreConfigRequest generateBbLevelDetail
                     */
    
                    /**
                     * Constructs a new CreateDebugTaskForStoreConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateDebugTaskForStoreConfigRequest.
                     * @implements ICreateDebugTaskForStoreConfigRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForStoreConfigRequest=} [properties] Properties to set
                     */
                    function CreateDebugTaskForStoreConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateDebugTaskForStoreConfigRequest storeConfigId.
                     * @member {number} storeConfigId
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @instance
                     */
                    CreateDebugTaskForStoreConfigRequest.prototype.storeConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CreateDebugTaskForStoreConfigRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @instance
                     */
                    CreateDebugTaskForStoreConfigRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CreateDebugTaskForStoreConfigRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @instance
                     */
                    CreateDebugTaskForStoreConfigRequest.prototype.dateId = "";
    
                    /**
                     * CreateDebugTaskForStoreConfigRequest startHour.
                     * @member {number} startHour
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @instance
                     */
                    CreateDebugTaskForStoreConfigRequest.prototype.startHour = 0;
    
                    /**
                     * CreateDebugTaskForStoreConfigRequest endHour.
                     * @member {number} endHour
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @instance
                     */
                    CreateDebugTaskForStoreConfigRequest.prototype.endHour = 0;
    
                    /**
                     * CreateDebugTaskForStoreConfigRequest maxHoursToSplit.
                     * @member {number} maxHoursToSplit
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @instance
                     */
                    CreateDebugTaskForStoreConfigRequest.prototype.maxHoursToSplit = 0;
    
                    /**
                     * CreateDebugTaskForStoreConfigRequest includeFfmpegResults.
                     * @member {boolean} includeFfmpegResults
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @instance
                     */
                    CreateDebugTaskForStoreConfigRequest.prototype.includeFfmpegResults = false;
    
                    /**
                     * CreateDebugTaskForStoreConfigRequest generateBbLevelDetail.
                     * @member {boolean} generateBbLevelDetail
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @instance
                     */
                    CreateDebugTaskForStoreConfigRequest.prototype.generateBbLevelDetail = false;
    
                    /**
                     * Creates a new CreateDebugTaskForStoreConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForStoreConfigRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest} CreateDebugTaskForStoreConfigRequest instance
                     */
                    CreateDebugTaskForStoreConfigRequest.create = function create(properties) {
                        return new CreateDebugTaskForStoreConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateDebugTaskForStoreConfigRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForStoreConfigRequest} message CreateDebugTaskForStoreConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebugTaskForStoreConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeConfigId != null && Object.hasOwnProperty.call(message, "storeConfigId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeConfigId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.dateId);
                        if (message.startHour != null && Object.hasOwnProperty.call(message, "startHour"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.startHour);
                        if (message.endHour != null && Object.hasOwnProperty.call(message, "endHour"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.endHour);
                        if (message.maxHoursToSplit != null && Object.hasOwnProperty.call(message, "maxHoursToSplit"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.maxHoursToSplit);
                        if (message.includeFfmpegResults != null && Object.hasOwnProperty.call(message, "includeFfmpegResults"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.includeFfmpegResults);
                        if (message.generateBbLevelDetail != null && Object.hasOwnProperty.call(message, "generateBbLevelDetail"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.generateBbLevelDetail);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateDebugTaskForStoreConfigRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForStoreConfigRequest} message CreateDebugTaskForStoreConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebugTaskForStoreConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateDebugTaskForStoreConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest} CreateDebugTaskForStoreConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebugTaskForStoreConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeConfigId = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.dateId = reader.string();
                                break;
                            case 4:
                                message.startHour = reader.int32();
                                break;
                            case 5:
                                message.endHour = reader.int32();
                                break;
                            case 6:
                                message.maxHoursToSplit = reader.int32();
                                break;
                            case 7:
                                message.includeFfmpegResults = reader.bool();
                                break;
                            case 8:
                                message.generateBbLevelDetail = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateDebugTaskForStoreConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest} CreateDebugTaskForStoreConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebugTaskForStoreConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateDebugTaskForStoreConfigRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateDebugTaskForStoreConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (!$util.isInteger(message.storeConfigId) && !(message.storeConfigId && $util.isInteger(message.storeConfigId.low) && $util.isInteger(message.storeConfigId.high)))
                                return "storeConfigId: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.startHour != null && message.hasOwnProperty("startHour"))
                            if (!$util.isInteger(message.startHour))
                                return "startHour: integer expected";
                        if (message.endHour != null && message.hasOwnProperty("endHour"))
                            if (!$util.isInteger(message.endHour))
                                return "endHour: integer expected";
                        if (message.maxHoursToSplit != null && message.hasOwnProperty("maxHoursToSplit"))
                            if (!$util.isInteger(message.maxHoursToSplit))
                                return "maxHoursToSplit: integer expected";
                        if (message.includeFfmpegResults != null && message.hasOwnProperty("includeFfmpegResults"))
                            if (typeof message.includeFfmpegResults !== "boolean")
                                return "includeFfmpegResults: boolean expected";
                        if (message.generateBbLevelDetail != null && message.hasOwnProperty("generateBbLevelDetail"))
                            if (typeof message.generateBbLevelDetail !== "boolean")
                                return "generateBbLevelDetail: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateDebugTaskForStoreConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest} CreateDebugTaskForStoreConfigRequest
                     */
                    CreateDebugTaskForStoreConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest();
                        if (object.storeConfigId != null)
                            if ($util.Long)
                                (message.storeConfigId = $util.Long.fromValue(object.storeConfigId)).unsigned = false;
                            else if (typeof object.storeConfigId === "string")
                                message.storeConfigId = parseInt(object.storeConfigId, 10);
                            else if (typeof object.storeConfigId === "number")
                                message.storeConfigId = object.storeConfigId;
                            else if (typeof object.storeConfigId === "object")
                                message.storeConfigId = new $util.LongBits(object.storeConfigId.low >>> 0, object.storeConfigId.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.startHour != null)
                            message.startHour = object.startHour | 0;
                        if (object.endHour != null)
                            message.endHour = object.endHour | 0;
                        if (object.maxHoursToSplit != null)
                            message.maxHoursToSplit = object.maxHoursToSplit | 0;
                        if (object.includeFfmpegResults != null)
                            message.includeFfmpegResults = Boolean(object.includeFfmpegResults);
                        if (object.generateBbLevelDetail != null)
                            message.generateBbLevelDetail = Boolean(object.generateBbLevelDetail);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateDebugTaskForStoreConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest} message CreateDebugTaskForStoreConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateDebugTaskForStoreConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeConfigId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.startHour = 0;
                            object.endHour = 0;
                            object.maxHoursToSplit = 0;
                            object.includeFfmpegResults = false;
                            object.generateBbLevelDetail = false;
                        }
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (typeof message.storeConfigId === "number")
                                object.storeConfigId = options.longs === String ? String(message.storeConfigId) : message.storeConfigId;
                            else
                                object.storeConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.storeConfigId) : options.longs === Number ? new $util.LongBits(message.storeConfigId.low >>> 0, message.storeConfigId.high >>> 0).toNumber() : message.storeConfigId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.startHour != null && message.hasOwnProperty("startHour"))
                            object.startHour = message.startHour;
                        if (message.endHour != null && message.hasOwnProperty("endHour"))
                            object.endHour = message.endHour;
                        if (message.maxHoursToSplit != null && message.hasOwnProperty("maxHoursToSplit"))
                            object.maxHoursToSplit = message.maxHoursToSplit;
                        if (message.includeFfmpegResults != null && message.hasOwnProperty("includeFfmpegResults"))
                            object.includeFfmpegResults = message.includeFfmpegResults;
                        if (message.generateBbLevelDetail != null && message.hasOwnProperty("generateBbLevelDetail"))
                            object.generateBbLevelDetail = message.generateBbLevelDetail;
                        return object;
                    };
    
                    /**
                     * Converts this CreateDebugTaskForStoreConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateDebugTaskForStoreConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateDebugTaskForStoreConfigRequest;
                })();
    
                v1.CreateDebugTaskForExperimentRequest = (function() {
    
                    /**
                     * Properties of a CreateDebugTaskForExperimentRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateDebugTaskForExperimentRequest
                     * @property {number|null} [storeConfigId] CreateDebugTaskForExperimentRequest storeConfigId
                     * @property {number|null} [storeId] CreateDebugTaskForExperimentRequest storeId
                     * @property {string|null} [dateId] CreateDebugTaskForExperimentRequest dateId
                     * @property {number|null} [startHour] CreateDebugTaskForExperimentRequest startHour
                     * @property {number|null} [endHour] CreateDebugTaskForExperimentRequest endHour
                     * @property {number|null} [maxHoursToSplit] CreateDebugTaskForExperimentRequest maxHoursToSplit
                     * @property {string|null} [experimentName] CreateDebugTaskForExperimentRequest experimentName
                     */
    
                    /**
                     * Constructs a new CreateDebugTaskForExperimentRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateDebugTaskForExperimentRequest.
                     * @implements ICreateDebugTaskForExperimentRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForExperimentRequest=} [properties] Properties to set
                     */
                    function CreateDebugTaskForExperimentRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateDebugTaskForExperimentRequest storeConfigId.
                     * @member {number} storeConfigId
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @instance
                     */
                    CreateDebugTaskForExperimentRequest.prototype.storeConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CreateDebugTaskForExperimentRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @instance
                     */
                    CreateDebugTaskForExperimentRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CreateDebugTaskForExperimentRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @instance
                     */
                    CreateDebugTaskForExperimentRequest.prototype.dateId = "";
    
                    /**
                     * CreateDebugTaskForExperimentRequest startHour.
                     * @member {number} startHour
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @instance
                     */
                    CreateDebugTaskForExperimentRequest.prototype.startHour = 0;
    
                    /**
                     * CreateDebugTaskForExperimentRequest endHour.
                     * @member {number} endHour
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @instance
                     */
                    CreateDebugTaskForExperimentRequest.prototype.endHour = 0;
    
                    /**
                     * CreateDebugTaskForExperimentRequest maxHoursToSplit.
                     * @member {number} maxHoursToSplit
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @instance
                     */
                    CreateDebugTaskForExperimentRequest.prototype.maxHoursToSplit = 0;
    
                    /**
                     * CreateDebugTaskForExperimentRequest experimentName.
                     * @member {string} experimentName
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @instance
                     */
                    CreateDebugTaskForExperimentRequest.prototype.experimentName = "";
    
                    /**
                     * Creates a new CreateDebugTaskForExperimentRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForExperimentRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForExperimentRequest} CreateDebugTaskForExperimentRequest instance
                     */
                    CreateDebugTaskForExperimentRequest.create = function create(properties) {
                        return new CreateDebugTaskForExperimentRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateDebugTaskForExperimentRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateDebugTaskForExperimentRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForExperimentRequest} message CreateDebugTaskForExperimentRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebugTaskForExperimentRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeConfigId != null && Object.hasOwnProperty.call(message, "storeConfigId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeConfigId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.dateId);
                        if (message.startHour != null && Object.hasOwnProperty.call(message, "startHour"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.startHour);
                        if (message.endHour != null && Object.hasOwnProperty.call(message, "endHour"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.endHour);
                        if (message.maxHoursToSplit != null && Object.hasOwnProperty.call(message, "maxHoursToSplit"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.maxHoursToSplit);
                        if (message.experimentName != null && Object.hasOwnProperty.call(message, "experimentName"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.experimentName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateDebugTaskForExperimentRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateDebugTaskForExperimentRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateDebugTaskForExperimentRequest} message CreateDebugTaskForExperimentRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebugTaskForExperimentRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateDebugTaskForExperimentRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForExperimentRequest} CreateDebugTaskForExperimentRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebugTaskForExperimentRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateDebugTaskForExperimentRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeConfigId = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.dateId = reader.string();
                                break;
                            case 4:
                                message.startHour = reader.int32();
                                break;
                            case 5:
                                message.endHour = reader.int32();
                                break;
                            case 6:
                                message.maxHoursToSplit = reader.int32();
                                break;
                            case 7:
                                message.experimentName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateDebugTaskForExperimentRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForExperimentRequest} CreateDebugTaskForExperimentRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebugTaskForExperimentRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateDebugTaskForExperimentRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateDebugTaskForExperimentRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (!$util.isInteger(message.storeConfigId) && !(message.storeConfigId && $util.isInteger(message.storeConfigId.low) && $util.isInteger(message.storeConfigId.high)))
                                return "storeConfigId: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.startHour != null && message.hasOwnProperty("startHour"))
                            if (!$util.isInteger(message.startHour))
                                return "startHour: integer expected";
                        if (message.endHour != null && message.hasOwnProperty("endHour"))
                            if (!$util.isInteger(message.endHour))
                                return "endHour: integer expected";
                        if (message.maxHoursToSplit != null && message.hasOwnProperty("maxHoursToSplit"))
                            if (!$util.isInteger(message.maxHoursToSplit))
                                return "maxHoursToSplit: integer expected";
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            if (!$util.isString(message.experimentName))
                                return "experimentName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateDebugTaskForExperimentRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateDebugTaskForExperimentRequest} CreateDebugTaskForExperimentRequest
                     */
                    CreateDebugTaskForExperimentRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateDebugTaskForExperimentRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateDebugTaskForExperimentRequest();
                        if (object.storeConfigId != null)
                            if ($util.Long)
                                (message.storeConfigId = $util.Long.fromValue(object.storeConfigId)).unsigned = false;
                            else if (typeof object.storeConfigId === "string")
                                message.storeConfigId = parseInt(object.storeConfigId, 10);
                            else if (typeof object.storeConfigId === "number")
                                message.storeConfigId = object.storeConfigId;
                            else if (typeof object.storeConfigId === "object")
                                message.storeConfigId = new $util.LongBits(object.storeConfigId.low >>> 0, object.storeConfigId.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.startHour != null)
                            message.startHour = object.startHour | 0;
                        if (object.endHour != null)
                            message.endHour = object.endHour | 0;
                        if (object.maxHoursToSplit != null)
                            message.maxHoursToSplit = object.maxHoursToSplit | 0;
                        if (object.experimentName != null)
                            message.experimentName = String(object.experimentName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateDebugTaskForExperimentRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateDebugTaskForExperimentRequest} message CreateDebugTaskForExperimentRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateDebugTaskForExperimentRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeConfigId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.startHour = 0;
                            object.endHour = 0;
                            object.maxHoursToSplit = 0;
                            object.experimentName = "";
                        }
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (typeof message.storeConfigId === "number")
                                object.storeConfigId = options.longs === String ? String(message.storeConfigId) : message.storeConfigId;
                            else
                                object.storeConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.storeConfigId) : options.longs === Number ? new $util.LongBits(message.storeConfigId.low >>> 0, message.storeConfigId.high >>> 0).toNumber() : message.storeConfigId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.startHour != null && message.hasOwnProperty("startHour"))
                            object.startHour = message.startHour;
                        if (message.endHour != null && message.hasOwnProperty("endHour"))
                            object.endHour = message.endHour;
                        if (message.maxHoursToSplit != null && message.hasOwnProperty("maxHoursToSplit"))
                            object.maxHoursToSplit = message.maxHoursToSplit;
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            object.experimentName = message.experimentName;
                        return object;
                    };
    
                    /**
                     * Converts this CreateDebugTaskForExperimentRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateDebugTaskForExperimentRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateDebugTaskForExperimentRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateDebugTaskForExperimentRequest;
                })();
    
                v1.UpdateHumanLabelLinkRequest = (function() {
    
                    /**
                     * Properties of an UpdateHumanLabelLinkRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IUpdateHumanLabelLinkRequest
                     * @property {string|null} [humanLabelLink] UpdateHumanLabelLinkRequest humanLabelLink
                     * @property {string|null} [taskResult] UpdateHumanLabelLinkRequest taskResult
                     */
    
                    /**
                     * Constructs a new UpdateHumanLabelLinkRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UpdateHumanLabelLinkRequest.
                     * @implements IUpdateHumanLabelLinkRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IUpdateHumanLabelLinkRequest=} [properties] Properties to set
                     */
                    function UpdateHumanLabelLinkRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateHumanLabelLinkRequest humanLabelLink.
                     * @member {string} humanLabelLink
                     * @memberof palexy.sherlock.v1.UpdateHumanLabelLinkRequest
                     * @instance
                     */
                    UpdateHumanLabelLinkRequest.prototype.humanLabelLink = "";
    
                    /**
                     * UpdateHumanLabelLinkRequest taskResult.
                     * @member {string} taskResult
                     * @memberof palexy.sherlock.v1.UpdateHumanLabelLinkRequest
                     * @instance
                     */
                    UpdateHumanLabelLinkRequest.prototype.taskResult = "";
    
                    /**
                     * Creates a new UpdateHumanLabelLinkRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UpdateHumanLabelLinkRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateHumanLabelLinkRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UpdateHumanLabelLinkRequest} UpdateHumanLabelLinkRequest instance
                     */
                    UpdateHumanLabelLinkRequest.create = function create(properties) {
                        return new UpdateHumanLabelLinkRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateHumanLabelLinkRequest message. Does not implicitly {@link palexy.sherlock.v1.UpdateHumanLabelLinkRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UpdateHumanLabelLinkRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateHumanLabelLinkRequest} message UpdateHumanLabelLinkRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateHumanLabelLinkRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.humanLabelLink != null && Object.hasOwnProperty.call(message, "humanLabelLink"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.humanLabelLink);
                        if (message.taskResult != null && Object.hasOwnProperty.call(message, "taskResult"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskResult);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateHumanLabelLinkRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UpdateHumanLabelLinkRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateHumanLabelLinkRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateHumanLabelLinkRequest} message UpdateHumanLabelLinkRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateHumanLabelLinkRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateHumanLabelLinkRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UpdateHumanLabelLinkRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UpdateHumanLabelLinkRequest} UpdateHumanLabelLinkRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateHumanLabelLinkRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UpdateHumanLabelLinkRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.humanLabelLink = reader.string();
                                break;
                            case 2:
                                message.taskResult = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateHumanLabelLinkRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateHumanLabelLinkRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UpdateHumanLabelLinkRequest} UpdateHumanLabelLinkRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateHumanLabelLinkRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateHumanLabelLinkRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UpdateHumanLabelLinkRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateHumanLabelLinkRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.humanLabelLink != null && message.hasOwnProperty("humanLabelLink"))
                            if (!$util.isString(message.humanLabelLink))
                                return "humanLabelLink: string expected";
                        if (message.taskResult != null && message.hasOwnProperty("taskResult"))
                            if (!$util.isString(message.taskResult))
                                return "taskResult: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpdateHumanLabelLinkRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UpdateHumanLabelLinkRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UpdateHumanLabelLinkRequest} UpdateHumanLabelLinkRequest
                     */
                    UpdateHumanLabelLinkRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UpdateHumanLabelLinkRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.UpdateHumanLabelLinkRequest();
                        if (object.humanLabelLink != null)
                            message.humanLabelLink = String(object.humanLabelLink);
                        if (object.taskResult != null)
                            message.taskResult = String(object.taskResult);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateHumanLabelLinkRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UpdateHumanLabelLinkRequest
                     * @static
                     * @param {palexy.sherlock.v1.UpdateHumanLabelLinkRequest} message UpdateHumanLabelLinkRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateHumanLabelLinkRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.humanLabelLink = "";
                            object.taskResult = "";
                        }
                        if (message.humanLabelLink != null && message.hasOwnProperty("humanLabelLink"))
                            object.humanLabelLink = message.humanLabelLink;
                        if (message.taskResult != null && message.hasOwnProperty("taskResult"))
                            object.taskResult = message.taskResult;
                        return object;
                    };
    
                    /**
                     * Converts this UpdateHumanLabelLinkRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UpdateHumanLabelLinkRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateHumanLabelLinkRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateHumanLabelLinkRequest;
                })();
    
                v1.RetryTaskRequest = (function() {
    
                    /**
                     * Properties of a RetryTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IRetryTaskRequest
                     * @property {string|null} [task] RetryTaskRequest task
                     */
    
                    /**
                     * Constructs a new RetryTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a RetryTaskRequest.
                     * @implements IRetryTaskRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IRetryTaskRequest=} [properties] Properties to set
                     */
                    function RetryTaskRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RetryTaskRequest task.
                     * @member {string} task
                     * @memberof palexy.sherlock.v1.RetryTaskRequest
                     * @instance
                     */
                    RetryTaskRequest.prototype.task = "";
    
                    /**
                     * Creates a new RetryTaskRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.RetryTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IRetryTaskRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.RetryTaskRequest} RetryTaskRequest instance
                     */
                    RetryTaskRequest.create = function create(properties) {
                        return new RetryTaskRequest(properties);
                    };
    
                    /**
                     * Encodes the specified RetryTaskRequest message. Does not implicitly {@link palexy.sherlock.v1.RetryTaskRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.RetryTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IRetryTaskRequest} message RetryTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RetryTaskRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.task);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RetryTaskRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.RetryTaskRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.RetryTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.IRetryTaskRequest} message RetryTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RetryTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RetryTaskRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.RetryTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.RetryTaskRequest} RetryTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RetryTaskRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.RetryTaskRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.task = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RetryTaskRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.RetryTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.RetryTaskRequest} RetryTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RetryTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RetryTaskRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.RetryTaskRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RetryTaskRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.task != null && message.hasOwnProperty("task"))
                            if (!$util.isString(message.task))
                                return "task: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a RetryTaskRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.RetryTaskRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.RetryTaskRequest} RetryTaskRequest
                     */
                    RetryTaskRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.RetryTaskRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.RetryTaskRequest();
                        if (object.task != null)
                            message.task = String(object.task);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RetryTaskRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.RetryTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.RetryTaskRequest} message RetryTaskRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RetryTaskRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.task = "";
                        if (message.task != null && message.hasOwnProperty("task"))
                            object.task = message.task;
                        return object;
                    };
    
                    /**
                     * Converts this RetryTaskRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.RetryTaskRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RetryTaskRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RetryTaskRequest;
                })();
    
                v1.CreateAccuracyTasksForStoreConfigRequest = (function() {
    
                    /**
                     * Properties of a CreateAccuracyTasksForStoreConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateAccuracyTasksForStoreConfigRequest
                     * @property {number|null} [storeConfigId] CreateAccuracyTasksForStoreConfigRequest storeConfigId
                     * @property {number|null} [postMergeStoreConfigItemId] CreateAccuracyTasksForStoreConfigRequest postMergeStoreConfigItemId
                     */
    
                    /**
                     * Constructs a new CreateAccuracyTasksForStoreConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateAccuracyTasksForStoreConfigRequest.
                     * @implements ICreateAccuracyTasksForStoreConfigRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateAccuracyTasksForStoreConfigRequest=} [properties] Properties to set
                     */
                    function CreateAccuracyTasksForStoreConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateAccuracyTasksForStoreConfigRequest storeConfigId.
                     * @member {number} storeConfigId
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest
                     * @instance
                     */
                    CreateAccuracyTasksForStoreConfigRequest.prototype.storeConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CreateAccuracyTasksForStoreConfigRequest postMergeStoreConfigItemId.
                     * @member {number} postMergeStoreConfigItemId
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest
                     * @instance
                     */
                    CreateAccuracyTasksForStoreConfigRequest.prototype.postMergeStoreConfigItemId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new CreateAccuracyTasksForStoreConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateAccuracyTasksForStoreConfigRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest} CreateAccuracyTasksForStoreConfigRequest instance
                     */
                    CreateAccuracyTasksForStoreConfigRequest.create = function create(properties) {
                        return new CreateAccuracyTasksForStoreConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateAccuracyTasksForStoreConfigRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateAccuracyTasksForStoreConfigRequest} message CreateAccuracyTasksForStoreConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateAccuracyTasksForStoreConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeConfigId != null && Object.hasOwnProperty.call(message, "storeConfigId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeConfigId);
                        if (message.postMergeStoreConfigItemId != null && Object.hasOwnProperty.call(message, "postMergeStoreConfigItemId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.postMergeStoreConfigItemId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateAccuracyTasksForStoreConfigRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateAccuracyTasksForStoreConfigRequest} message CreateAccuracyTasksForStoreConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateAccuracyTasksForStoreConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateAccuracyTasksForStoreConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest} CreateAccuracyTasksForStoreConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateAccuracyTasksForStoreConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeConfigId = reader.int64();
                                break;
                            case 2:
                                message.postMergeStoreConfigItemId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateAccuracyTasksForStoreConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest} CreateAccuracyTasksForStoreConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateAccuracyTasksForStoreConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateAccuracyTasksForStoreConfigRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateAccuracyTasksForStoreConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (!$util.isInteger(message.storeConfigId) && !(message.storeConfigId && $util.isInteger(message.storeConfigId.low) && $util.isInteger(message.storeConfigId.high)))
                                return "storeConfigId: integer|Long expected";
                        if (message.postMergeStoreConfigItemId != null && message.hasOwnProperty("postMergeStoreConfigItemId"))
                            if (!$util.isInteger(message.postMergeStoreConfigItemId) && !(message.postMergeStoreConfigItemId && $util.isInteger(message.postMergeStoreConfigItemId.low) && $util.isInteger(message.postMergeStoreConfigItemId.high)))
                                return "postMergeStoreConfigItemId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateAccuracyTasksForStoreConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest} CreateAccuracyTasksForStoreConfigRequest
                     */
                    CreateAccuracyTasksForStoreConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest();
                        if (object.storeConfigId != null)
                            if ($util.Long)
                                (message.storeConfigId = $util.Long.fromValue(object.storeConfigId)).unsigned = false;
                            else if (typeof object.storeConfigId === "string")
                                message.storeConfigId = parseInt(object.storeConfigId, 10);
                            else if (typeof object.storeConfigId === "number")
                                message.storeConfigId = object.storeConfigId;
                            else if (typeof object.storeConfigId === "object")
                                message.storeConfigId = new $util.LongBits(object.storeConfigId.low >>> 0, object.storeConfigId.high >>> 0).toNumber();
                        if (object.postMergeStoreConfigItemId != null)
                            if ($util.Long)
                                (message.postMergeStoreConfigItemId = $util.Long.fromValue(object.postMergeStoreConfigItemId)).unsigned = false;
                            else if (typeof object.postMergeStoreConfigItemId === "string")
                                message.postMergeStoreConfigItemId = parseInt(object.postMergeStoreConfigItemId, 10);
                            else if (typeof object.postMergeStoreConfigItemId === "number")
                                message.postMergeStoreConfigItemId = object.postMergeStoreConfigItemId;
                            else if (typeof object.postMergeStoreConfigItemId === "object")
                                message.postMergeStoreConfigItemId = new $util.LongBits(object.postMergeStoreConfigItemId.low >>> 0, object.postMergeStoreConfigItemId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateAccuracyTasksForStoreConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest} message CreateAccuracyTasksForStoreConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateAccuracyTasksForStoreConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeConfigId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.postMergeStoreConfigItemId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.postMergeStoreConfigItemId = options.longs === String ? "0" : 0;
                        }
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (typeof message.storeConfigId === "number")
                                object.storeConfigId = options.longs === String ? String(message.storeConfigId) : message.storeConfigId;
                            else
                                object.storeConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.storeConfigId) : options.longs === Number ? new $util.LongBits(message.storeConfigId.low >>> 0, message.storeConfigId.high >>> 0).toNumber() : message.storeConfigId;
                        if (message.postMergeStoreConfigItemId != null && message.hasOwnProperty("postMergeStoreConfigItemId"))
                            if (typeof message.postMergeStoreConfigItemId === "number")
                                object.postMergeStoreConfigItemId = options.longs === String ? String(message.postMergeStoreConfigItemId) : message.postMergeStoreConfigItemId;
                            else
                                object.postMergeStoreConfigItemId = options.longs === String ? $util.Long.prototype.toString.call(message.postMergeStoreConfigItemId) : options.longs === Number ? new $util.LongBits(message.postMergeStoreConfigItemId.low >>> 0, message.postMergeStoreConfigItemId.high >>> 0).toNumber() : message.postMergeStoreConfigItemId;
                        return object;
                    };
    
                    /**
                     * Converts this CreateAccuracyTasksForStoreConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateAccuracyTasksForStoreConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateAccuracyTasksForStoreConfigRequest;
                })();
    
                v1.CreateAccuracyTasksForStoreConfigResponse = (function() {
    
                    /**
                     * Properties of a CreateAccuracyTasksForStoreConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateAccuracyTasksForStoreConfigResponse
                     * @property {Array.<palexy.sherlock.v1.ISherlockTask>|null} [tasks] CreateAccuracyTasksForStoreConfigResponse tasks
                     */
    
                    /**
                     * Constructs a new CreateAccuracyTasksForStoreConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateAccuracyTasksForStoreConfigResponse.
                     * @implements ICreateAccuracyTasksForStoreConfigResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateAccuracyTasksForStoreConfigResponse=} [properties] Properties to set
                     */
                    function CreateAccuracyTasksForStoreConfigResponse(properties) {
                        this.tasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateAccuracyTasksForStoreConfigResponse tasks.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTask>} tasks
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse
                     * @instance
                     */
                    CreateAccuracyTasksForStoreConfigResponse.prototype.tasks = $util.emptyArray;
    
                    /**
                     * Creates a new CreateAccuracyTasksForStoreConfigResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateAccuracyTasksForStoreConfigResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse} CreateAccuracyTasksForStoreConfigResponse instance
                     */
                    CreateAccuracyTasksForStoreConfigResponse.create = function create(properties) {
                        return new CreateAccuracyTasksForStoreConfigResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateAccuracyTasksForStoreConfigResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateAccuracyTasksForStoreConfigResponse} message CreateAccuracyTasksForStoreConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateAccuracyTasksForStoreConfigResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.tasks != null && message.tasks.length)
                            for (var i = 0; i < message.tasks.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTask.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateAccuracyTasksForStoreConfigResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateAccuracyTasksForStoreConfigResponse} message CreateAccuracyTasksForStoreConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateAccuracyTasksForStoreConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateAccuracyTasksForStoreConfigResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse} CreateAccuracyTasksForStoreConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateAccuracyTasksForStoreConfigResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateAccuracyTasksForStoreConfigResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse} CreateAccuracyTasksForStoreConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateAccuracyTasksForStoreConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateAccuracyTasksForStoreConfigResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateAccuracyTasksForStoreConfigResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.tasks != null && message.hasOwnProperty("tasks")) {
                            if (!Array.isArray(message.tasks))
                                return "tasks: array expected";
                            for (var i = 0; i < message.tasks.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.tasks[i]);
                                if (error)
                                    return "tasks." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateAccuracyTasksForStoreConfigResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse} CreateAccuracyTasksForStoreConfigResponse
                     */
                    CreateAccuracyTasksForStoreConfigResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse();
                        if (object.tasks) {
                            if (!Array.isArray(object.tasks))
                                throw TypeError(".palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse.tasks: array expected");
                            message.tasks = [];
                            for (var i = 0; i < object.tasks.length; ++i) {
                                if (typeof object.tasks[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse.tasks: object expected");
                                message.tasks[i] = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.tasks[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateAccuracyTasksForStoreConfigResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse} message CreateAccuracyTasksForStoreConfigResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateAccuracyTasksForStoreConfigResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.tasks = [];
                        if (message.tasks && message.tasks.length) {
                            object.tasks = [];
                            for (var j = 0; j < message.tasks.length; ++j)
                                object.tasks[j] = $root.palexy.sherlock.v1.SherlockTask.toObject(message.tasks[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CreateAccuracyTasksForStoreConfigResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateAccuracyTasksForStoreConfigResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateAccuracyTasksForStoreConfigResponse;
                })();
    
                v1.CreateTasksForConfigItemRequest = (function() {
    
                    /**
                     * Properties of a CreateTasksForConfigItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateTasksForConfigItemRequest
                     * @property {palexy.streaming.v1.IStoreConfigItem|null} [configItem] CreateTasksForConfigItemRequest configItem
                     */
    
                    /**
                     * Constructs a new CreateTasksForConfigItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateTasksForConfigItemRequest.
                     * @implements ICreateTasksForConfigItemRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateTasksForConfigItemRequest=} [properties] Properties to set
                     */
                    function CreateTasksForConfigItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateTasksForConfigItemRequest configItem.
                     * @member {palexy.streaming.v1.IStoreConfigItem|null|undefined} configItem
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemRequest
                     * @instance
                     */
                    CreateTasksForConfigItemRequest.prototype.configItem = null;
    
                    /**
                     * Creates a new CreateTasksForConfigItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTasksForConfigItemRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateTasksForConfigItemRequest} CreateTasksForConfigItemRequest instance
                     */
                    CreateTasksForConfigItemRequest.create = function create(properties) {
                        return new CreateTasksForConfigItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateTasksForConfigItemRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateTasksForConfigItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTasksForConfigItemRequest} message CreateTasksForConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTasksForConfigItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.configItem != null && Object.hasOwnProperty.call(message, "configItem"))
                            $root.palexy.streaming.v1.StoreConfigItem.encode(message.configItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateTasksForConfigItemRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateTasksForConfigItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTasksForConfigItemRequest} message CreateTasksForConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTasksForConfigItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateTasksForConfigItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateTasksForConfigItemRequest} CreateTasksForConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTasksForConfigItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateTasksForConfigItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.configItem = $root.palexy.streaming.v1.StoreConfigItem.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateTasksForConfigItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateTasksForConfigItemRequest} CreateTasksForConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTasksForConfigItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateTasksForConfigItemRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateTasksForConfigItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.configItem != null && message.hasOwnProperty("configItem")) {
                            var error = $root.palexy.streaming.v1.StoreConfigItem.verify(message.configItem);
                            if (error)
                                return "configItem." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateTasksForConfigItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateTasksForConfigItemRequest} CreateTasksForConfigItemRequest
                     */
                    CreateTasksForConfigItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateTasksForConfigItemRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateTasksForConfigItemRequest();
                        if (object.configItem != null) {
                            if (typeof object.configItem !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateTasksForConfigItemRequest.configItem: object expected");
                            message.configItem = $root.palexy.streaming.v1.StoreConfigItem.fromObject(object.configItem);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateTasksForConfigItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateTasksForConfigItemRequest} message CreateTasksForConfigItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateTasksForConfigItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.configItem = null;
                        if (message.configItem != null && message.hasOwnProperty("configItem"))
                            object.configItem = $root.palexy.streaming.v1.StoreConfigItem.toObject(message.configItem, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateTasksForConfigItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateTasksForConfigItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateTasksForConfigItemRequest;
                })();
    
                v1.CreateTasksForConfigItemResponse = (function() {
    
                    /**
                     * Properties of a CreateTasksForConfigItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateTasksForConfigItemResponse
                     * @property {Array.<palexy.sherlock.v1.ISherlockTask>|null} [tasks] CreateTasksForConfigItemResponse tasks
                     */
    
                    /**
                     * Constructs a new CreateTasksForConfigItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateTasksForConfigItemResponse.
                     * @implements ICreateTasksForConfigItemResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateTasksForConfigItemResponse=} [properties] Properties to set
                     */
                    function CreateTasksForConfigItemResponse(properties) {
                        this.tasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateTasksForConfigItemResponse tasks.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTask>} tasks
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemResponse
                     * @instance
                     */
                    CreateTasksForConfigItemResponse.prototype.tasks = $util.emptyArray;
    
                    /**
                     * Creates a new CreateTasksForConfigItemResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTasksForConfigItemResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateTasksForConfigItemResponse} CreateTasksForConfigItemResponse instance
                     */
                    CreateTasksForConfigItemResponse.create = function create(properties) {
                        return new CreateTasksForConfigItemResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateTasksForConfigItemResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateTasksForConfigItemResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTasksForConfigItemResponse} message CreateTasksForConfigItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTasksForConfigItemResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.tasks != null && message.tasks.length)
                            for (var i = 0; i < message.tasks.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTask.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateTasksForConfigItemResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateTasksForConfigItemResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTasksForConfigItemResponse} message CreateTasksForConfigItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTasksForConfigItemResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateTasksForConfigItemResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateTasksForConfigItemResponse} CreateTasksForConfigItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTasksForConfigItemResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateTasksForConfigItemResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateTasksForConfigItemResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateTasksForConfigItemResponse} CreateTasksForConfigItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTasksForConfigItemResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateTasksForConfigItemResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateTasksForConfigItemResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.tasks != null && message.hasOwnProperty("tasks")) {
                            if (!Array.isArray(message.tasks))
                                return "tasks: array expected";
                            for (var i = 0; i < message.tasks.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.tasks[i]);
                                if (error)
                                    return "tasks." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateTasksForConfigItemResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateTasksForConfigItemResponse} CreateTasksForConfigItemResponse
                     */
                    CreateTasksForConfigItemResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateTasksForConfigItemResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateTasksForConfigItemResponse();
                        if (object.tasks) {
                            if (!Array.isArray(object.tasks))
                                throw TypeError(".palexy.sherlock.v1.CreateTasksForConfigItemResponse.tasks: array expected");
                            message.tasks = [];
                            for (var i = 0; i < object.tasks.length; ++i) {
                                if (typeof object.tasks[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.CreateTasksForConfigItemResponse.tasks: object expected");
                                message.tasks[i] = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.tasks[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateTasksForConfigItemResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateTasksForConfigItemResponse} message CreateTasksForConfigItemResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateTasksForConfigItemResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.tasks = [];
                        if (message.tasks && message.tasks.length) {
                            object.tasks = [];
                            for (var j = 0; j < message.tasks.length; ++j)
                                object.tasks[j] = $root.palexy.sherlock.v1.SherlockTask.toObject(message.tasks[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CreateTasksForConfigItemResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateTasksForConfigItemResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateTasksForConfigItemResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateTasksForConfigItemResponse;
                })();
    
                v1.CreateScanningTaskForConfigItemRequest = (function() {
    
                    /**
                     * Properties of a CreateScanningTaskForConfigItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateScanningTaskForConfigItemRequest
                     * @property {palexy.streaming.v1.IStoreConfigItem|null} [configItem] CreateScanningTaskForConfigItemRequest configItem
                     * @property {Array.<number>|null} [debugTaskIds] CreateScanningTaskForConfigItemRequest debugTaskIds
                     * @property {Array.<palexy.sherlock.v1.IParameter>|null} [params] CreateScanningTaskForConfigItemRequest params
                     */
    
                    /**
                     * Constructs a new CreateScanningTaskForConfigItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateScanningTaskForConfigItemRequest.
                     * @implements ICreateScanningTaskForConfigItemRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateScanningTaskForConfigItemRequest=} [properties] Properties to set
                     */
                    function CreateScanningTaskForConfigItemRequest(properties) {
                        this.debugTaskIds = [];
                        this.params = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateScanningTaskForConfigItemRequest configItem.
                     * @member {palexy.streaming.v1.IStoreConfigItem|null|undefined} configItem
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @instance
                     */
                    CreateScanningTaskForConfigItemRequest.prototype.configItem = null;
    
                    /**
                     * CreateScanningTaskForConfigItemRequest debugTaskIds.
                     * @member {Array.<number>} debugTaskIds
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @instance
                     */
                    CreateScanningTaskForConfigItemRequest.prototype.debugTaskIds = $util.emptyArray;
    
                    /**
                     * CreateScanningTaskForConfigItemRequest params.
                     * @member {Array.<palexy.sherlock.v1.IParameter>} params
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @instance
                     */
                    CreateScanningTaskForConfigItemRequest.prototype.params = $util.emptyArray;
    
                    /**
                     * Creates a new CreateScanningTaskForConfigItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateScanningTaskForConfigItemRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest} CreateScanningTaskForConfigItemRequest instance
                     */
                    CreateScanningTaskForConfigItemRequest.create = function create(properties) {
                        return new CreateScanningTaskForConfigItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateScanningTaskForConfigItemRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateScanningTaskForConfigItemRequest} message CreateScanningTaskForConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateScanningTaskForConfigItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.configItem != null && Object.hasOwnProperty.call(message, "configItem"))
                            $root.palexy.streaming.v1.StoreConfigItem.encode(message.configItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.debugTaskIds != null && message.debugTaskIds.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.debugTaskIds.length; ++i)
                                writer.int64(message.debugTaskIds[i]);
                            writer.ldelim();
                        }
                        if (message.params != null && message.params.length)
                            for (var i = 0; i < message.params.length; ++i)
                                $root.palexy.sherlock.v1.Parameter.encode(message.params[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateScanningTaskForConfigItemRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateScanningTaskForConfigItemRequest} message CreateScanningTaskForConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateScanningTaskForConfigItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateScanningTaskForConfigItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest} CreateScanningTaskForConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateScanningTaskForConfigItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.configItem = $root.palexy.streaming.v1.StoreConfigItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.debugTaskIds && message.debugTaskIds.length))
                                    message.debugTaskIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.debugTaskIds.push(reader.int64());
                                } else
                                    message.debugTaskIds.push(reader.int64());
                                break;
                            case 3:
                                if (!(message.params && message.params.length))
                                    message.params = [];
                                message.params.push($root.palexy.sherlock.v1.Parameter.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateScanningTaskForConfigItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest} CreateScanningTaskForConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateScanningTaskForConfigItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateScanningTaskForConfigItemRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateScanningTaskForConfigItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.configItem != null && message.hasOwnProperty("configItem")) {
                            var error = $root.palexy.streaming.v1.StoreConfigItem.verify(message.configItem);
                            if (error)
                                return "configItem." + error;
                        }
                        if (message.debugTaskIds != null && message.hasOwnProperty("debugTaskIds")) {
                            if (!Array.isArray(message.debugTaskIds))
                                return "debugTaskIds: array expected";
                            for (var i = 0; i < message.debugTaskIds.length; ++i)
                                if (!$util.isInteger(message.debugTaskIds[i]) && !(message.debugTaskIds[i] && $util.isInteger(message.debugTaskIds[i].low) && $util.isInteger(message.debugTaskIds[i].high)))
                                    return "debugTaskIds: integer|Long[] expected";
                        }
                        if (message.params != null && message.hasOwnProperty("params")) {
                            if (!Array.isArray(message.params))
                                return "params: array expected";
                            for (var i = 0; i < message.params.length; ++i) {
                                var error = $root.palexy.sherlock.v1.Parameter.verify(message.params[i]);
                                if (error)
                                    return "params." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateScanningTaskForConfigItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest} CreateScanningTaskForConfigItemRequest
                     */
                    CreateScanningTaskForConfigItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest();
                        if (object.configItem != null) {
                            if (typeof object.configItem !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest.configItem: object expected");
                            message.configItem = $root.palexy.streaming.v1.StoreConfigItem.fromObject(object.configItem);
                        }
                        if (object.debugTaskIds) {
                            if (!Array.isArray(object.debugTaskIds))
                                throw TypeError(".palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest.debugTaskIds: array expected");
                            message.debugTaskIds = [];
                            for (var i = 0; i < object.debugTaskIds.length; ++i)
                                if ($util.Long)
                                    (message.debugTaskIds[i] = $util.Long.fromValue(object.debugTaskIds[i])).unsigned = false;
                                else if (typeof object.debugTaskIds[i] === "string")
                                    message.debugTaskIds[i] = parseInt(object.debugTaskIds[i], 10);
                                else if (typeof object.debugTaskIds[i] === "number")
                                    message.debugTaskIds[i] = object.debugTaskIds[i];
                                else if (typeof object.debugTaskIds[i] === "object")
                                    message.debugTaskIds[i] = new $util.LongBits(object.debugTaskIds[i].low >>> 0, object.debugTaskIds[i].high >>> 0).toNumber();
                        }
                        if (object.params) {
                            if (!Array.isArray(object.params))
                                throw TypeError(".palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest.params: array expected");
                            message.params = [];
                            for (var i = 0; i < object.params.length; ++i) {
                                if (typeof object.params[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest.params: object expected");
                                message.params[i] = $root.palexy.sherlock.v1.Parameter.fromObject(object.params[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateScanningTaskForConfigItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest} message CreateScanningTaskForConfigItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateScanningTaskForConfigItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.debugTaskIds = [];
                            object.params = [];
                        }
                        if (options.defaults)
                            object.configItem = null;
                        if (message.configItem != null && message.hasOwnProperty("configItem"))
                            object.configItem = $root.palexy.streaming.v1.StoreConfigItem.toObject(message.configItem, options);
                        if (message.debugTaskIds && message.debugTaskIds.length) {
                            object.debugTaskIds = [];
                            for (var j = 0; j < message.debugTaskIds.length; ++j)
                                if (typeof message.debugTaskIds[j] === "number")
                                    object.debugTaskIds[j] = options.longs === String ? String(message.debugTaskIds[j]) : message.debugTaskIds[j];
                                else
                                    object.debugTaskIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.debugTaskIds[j]) : options.longs === Number ? new $util.LongBits(message.debugTaskIds[j].low >>> 0, message.debugTaskIds[j].high >>> 0).toNumber() : message.debugTaskIds[j];
                        }
                        if (message.params && message.params.length) {
                            object.params = [];
                            for (var j = 0; j < message.params.length; ++j)
                                object.params[j] = $root.palexy.sherlock.v1.Parameter.toObject(message.params[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CreateScanningTaskForConfigItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateScanningTaskForConfigItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateScanningTaskForConfigItemRequest;
                })();
    
                v1.CreateScanningTaskForConfigItemResponse = (function() {
    
                    /**
                     * Properties of a CreateScanningTaskForConfigItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateScanningTaskForConfigItemResponse
                     * @property {Array.<palexy.sherlock.v1.ISherlockTask>|null} [tasks] CreateScanningTaskForConfigItemResponse tasks
                     */
    
                    /**
                     * Constructs a new CreateScanningTaskForConfigItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateScanningTaskForConfigItemResponse.
                     * @implements ICreateScanningTaskForConfigItemResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateScanningTaskForConfigItemResponse=} [properties] Properties to set
                     */
                    function CreateScanningTaskForConfigItemResponse(properties) {
                        this.tasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateScanningTaskForConfigItemResponse tasks.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTask>} tasks
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse
                     * @instance
                     */
                    CreateScanningTaskForConfigItemResponse.prototype.tasks = $util.emptyArray;
    
                    /**
                     * Creates a new CreateScanningTaskForConfigItemResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateScanningTaskForConfigItemResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse} CreateScanningTaskForConfigItemResponse instance
                     */
                    CreateScanningTaskForConfigItemResponse.create = function create(properties) {
                        return new CreateScanningTaskForConfigItemResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateScanningTaskForConfigItemResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateScanningTaskForConfigItemResponse} message CreateScanningTaskForConfigItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateScanningTaskForConfigItemResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.tasks != null && message.tasks.length)
                            for (var i = 0; i < message.tasks.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTask.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateScanningTaskForConfigItemResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateScanningTaskForConfigItemResponse} message CreateScanningTaskForConfigItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateScanningTaskForConfigItemResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateScanningTaskForConfigItemResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse} CreateScanningTaskForConfigItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateScanningTaskForConfigItemResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateScanningTaskForConfigItemResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse} CreateScanningTaskForConfigItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateScanningTaskForConfigItemResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateScanningTaskForConfigItemResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateScanningTaskForConfigItemResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.tasks != null && message.hasOwnProperty("tasks")) {
                            if (!Array.isArray(message.tasks))
                                return "tasks: array expected";
                            for (var i = 0; i < message.tasks.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.tasks[i]);
                                if (error)
                                    return "tasks." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateScanningTaskForConfigItemResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse} CreateScanningTaskForConfigItemResponse
                     */
                    CreateScanningTaskForConfigItemResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse();
                        if (object.tasks) {
                            if (!Array.isArray(object.tasks))
                                throw TypeError(".palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse.tasks: array expected");
                            message.tasks = [];
                            for (var i = 0; i < object.tasks.length; ++i) {
                                if (typeof object.tasks[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse.tasks: object expected");
                                message.tasks[i] = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.tasks[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateScanningTaskForConfigItemResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse} message CreateScanningTaskForConfigItemResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateScanningTaskForConfigItemResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.tasks = [];
                        if (message.tasks && message.tasks.length) {
                            object.tasks = [];
                            for (var j = 0; j < message.tasks.length; ++j)
                                object.tasks[j] = $root.palexy.sherlock.v1.SherlockTask.toObject(message.tasks[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CreateScanningTaskForConfigItemResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateScanningTaskForConfigItemResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateScanningTaskForConfigItemResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateScanningTaskForConfigItemResponse;
                })();
    
                v1.CreateRerunningTaskForConfigItemRequest = (function() {
    
                    /**
                     * Properties of a CreateRerunningTaskForConfigItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateRerunningTaskForConfigItemRequest
                     * @property {palexy.streaming.v1.IStoreConfigItem|null} [configItem] CreateRerunningTaskForConfigItemRequest configItem
                     * @property {Array.<number>|null} [debugTaskIds] CreateRerunningTaskForConfigItemRequest debugTaskIds
                     */
    
                    /**
                     * Constructs a new CreateRerunningTaskForConfigItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateRerunningTaskForConfigItemRequest.
                     * @implements ICreateRerunningTaskForConfigItemRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateRerunningTaskForConfigItemRequest=} [properties] Properties to set
                     */
                    function CreateRerunningTaskForConfigItemRequest(properties) {
                        this.debugTaskIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateRerunningTaskForConfigItemRequest configItem.
                     * @member {palexy.streaming.v1.IStoreConfigItem|null|undefined} configItem
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest
                     * @instance
                     */
                    CreateRerunningTaskForConfigItemRequest.prototype.configItem = null;
    
                    /**
                     * CreateRerunningTaskForConfigItemRequest debugTaskIds.
                     * @member {Array.<number>} debugTaskIds
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest
                     * @instance
                     */
                    CreateRerunningTaskForConfigItemRequest.prototype.debugTaskIds = $util.emptyArray;
    
                    /**
                     * Creates a new CreateRerunningTaskForConfigItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateRerunningTaskForConfigItemRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest} CreateRerunningTaskForConfigItemRequest instance
                     */
                    CreateRerunningTaskForConfigItemRequest.create = function create(properties) {
                        return new CreateRerunningTaskForConfigItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateRerunningTaskForConfigItemRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateRerunningTaskForConfigItemRequest} message CreateRerunningTaskForConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateRerunningTaskForConfigItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.configItem != null && Object.hasOwnProperty.call(message, "configItem"))
                            $root.palexy.streaming.v1.StoreConfigItem.encode(message.configItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.debugTaskIds != null && message.debugTaskIds.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.debugTaskIds.length; ++i)
                                writer.int64(message.debugTaskIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateRerunningTaskForConfigItemRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateRerunningTaskForConfigItemRequest} message CreateRerunningTaskForConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateRerunningTaskForConfigItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateRerunningTaskForConfigItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest} CreateRerunningTaskForConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateRerunningTaskForConfigItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.configItem = $root.palexy.streaming.v1.StoreConfigItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.debugTaskIds && message.debugTaskIds.length))
                                    message.debugTaskIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.debugTaskIds.push(reader.int64());
                                } else
                                    message.debugTaskIds.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateRerunningTaskForConfigItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest} CreateRerunningTaskForConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateRerunningTaskForConfigItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateRerunningTaskForConfigItemRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateRerunningTaskForConfigItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.configItem != null && message.hasOwnProperty("configItem")) {
                            var error = $root.palexy.streaming.v1.StoreConfigItem.verify(message.configItem);
                            if (error)
                                return "configItem." + error;
                        }
                        if (message.debugTaskIds != null && message.hasOwnProperty("debugTaskIds")) {
                            if (!Array.isArray(message.debugTaskIds))
                                return "debugTaskIds: array expected";
                            for (var i = 0; i < message.debugTaskIds.length; ++i)
                                if (!$util.isInteger(message.debugTaskIds[i]) && !(message.debugTaskIds[i] && $util.isInteger(message.debugTaskIds[i].low) && $util.isInteger(message.debugTaskIds[i].high)))
                                    return "debugTaskIds: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateRerunningTaskForConfigItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest} CreateRerunningTaskForConfigItemRequest
                     */
                    CreateRerunningTaskForConfigItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest();
                        if (object.configItem != null) {
                            if (typeof object.configItem !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest.configItem: object expected");
                            message.configItem = $root.palexy.streaming.v1.StoreConfigItem.fromObject(object.configItem);
                        }
                        if (object.debugTaskIds) {
                            if (!Array.isArray(object.debugTaskIds))
                                throw TypeError(".palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest.debugTaskIds: array expected");
                            message.debugTaskIds = [];
                            for (var i = 0; i < object.debugTaskIds.length; ++i)
                                if ($util.Long)
                                    (message.debugTaskIds[i] = $util.Long.fromValue(object.debugTaskIds[i])).unsigned = false;
                                else if (typeof object.debugTaskIds[i] === "string")
                                    message.debugTaskIds[i] = parseInt(object.debugTaskIds[i], 10);
                                else if (typeof object.debugTaskIds[i] === "number")
                                    message.debugTaskIds[i] = object.debugTaskIds[i];
                                else if (typeof object.debugTaskIds[i] === "object")
                                    message.debugTaskIds[i] = new $util.LongBits(object.debugTaskIds[i].low >>> 0, object.debugTaskIds[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateRerunningTaskForConfigItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest} message CreateRerunningTaskForConfigItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateRerunningTaskForConfigItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.debugTaskIds = [];
                        if (options.defaults)
                            object.configItem = null;
                        if (message.configItem != null && message.hasOwnProperty("configItem"))
                            object.configItem = $root.palexy.streaming.v1.StoreConfigItem.toObject(message.configItem, options);
                        if (message.debugTaskIds && message.debugTaskIds.length) {
                            object.debugTaskIds = [];
                            for (var j = 0; j < message.debugTaskIds.length; ++j)
                                if (typeof message.debugTaskIds[j] === "number")
                                    object.debugTaskIds[j] = options.longs === String ? String(message.debugTaskIds[j]) : message.debugTaskIds[j];
                                else
                                    object.debugTaskIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.debugTaskIds[j]) : options.longs === Number ? new $util.LongBits(message.debugTaskIds[j].low >>> 0, message.debugTaskIds[j].high >>> 0).toNumber() : message.debugTaskIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CreateRerunningTaskForConfigItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateRerunningTaskForConfigItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateRerunningTaskForConfigItemRequest;
                })();
    
                v1.CreateRerunningTaskForConfigItemResponse = (function() {
    
                    /**
                     * Properties of a CreateRerunningTaskForConfigItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateRerunningTaskForConfigItemResponse
                     * @property {Array.<palexy.sherlock.v1.ISherlockTask>|null} [tasks] CreateRerunningTaskForConfigItemResponse tasks
                     */
    
                    /**
                     * Constructs a new CreateRerunningTaskForConfigItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateRerunningTaskForConfigItemResponse.
                     * @implements ICreateRerunningTaskForConfigItemResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateRerunningTaskForConfigItemResponse=} [properties] Properties to set
                     */
                    function CreateRerunningTaskForConfigItemResponse(properties) {
                        this.tasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateRerunningTaskForConfigItemResponse tasks.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTask>} tasks
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse
                     * @instance
                     */
                    CreateRerunningTaskForConfigItemResponse.prototype.tasks = $util.emptyArray;
    
                    /**
                     * Creates a new CreateRerunningTaskForConfigItemResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateRerunningTaskForConfigItemResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse} CreateRerunningTaskForConfigItemResponse instance
                     */
                    CreateRerunningTaskForConfigItemResponse.create = function create(properties) {
                        return new CreateRerunningTaskForConfigItemResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateRerunningTaskForConfigItemResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateRerunningTaskForConfigItemResponse} message CreateRerunningTaskForConfigItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateRerunningTaskForConfigItemResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.tasks != null && message.tasks.length)
                            for (var i = 0; i < message.tasks.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTask.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateRerunningTaskForConfigItemResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateRerunningTaskForConfigItemResponse} message CreateRerunningTaskForConfigItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateRerunningTaskForConfigItemResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateRerunningTaskForConfigItemResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse} CreateRerunningTaskForConfigItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateRerunningTaskForConfigItemResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateRerunningTaskForConfigItemResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse} CreateRerunningTaskForConfigItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateRerunningTaskForConfigItemResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateRerunningTaskForConfigItemResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateRerunningTaskForConfigItemResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.tasks != null && message.hasOwnProperty("tasks")) {
                            if (!Array.isArray(message.tasks))
                                return "tasks: array expected";
                            for (var i = 0; i < message.tasks.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.tasks[i]);
                                if (error)
                                    return "tasks." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateRerunningTaskForConfigItemResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse} CreateRerunningTaskForConfigItemResponse
                     */
                    CreateRerunningTaskForConfigItemResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse();
                        if (object.tasks) {
                            if (!Array.isArray(object.tasks))
                                throw TypeError(".palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse.tasks: array expected");
                            message.tasks = [];
                            for (var i = 0; i < object.tasks.length; ++i) {
                                if (typeof object.tasks[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse.tasks: object expected");
                                message.tasks[i] = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.tasks[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateRerunningTaskForConfigItemResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse} message CreateRerunningTaskForConfigItemResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateRerunningTaskForConfigItemResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.tasks = [];
                        if (message.tasks && message.tasks.length) {
                            object.tasks = [];
                            for (var j = 0; j < message.tasks.length; ++j)
                                object.tasks[j] = $root.palexy.sherlock.v1.SherlockTask.toObject(message.tasks[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CreateRerunningTaskForConfigItemResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateRerunningTaskForConfigItemResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateRerunningTaskForConfigItemResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateRerunningTaskForConfigItemResponse;
                })();
    
                v1.CreatePrepareQcVideosTaskRequest = (function() {
    
                    /**
                     * Properties of a CreatePrepareQcVideosTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreatePrepareQcVideosTaskRequest
                     * @property {number|null} [storeId] CreatePrepareQcVideosTaskRequest storeId
                     * @property {string|null} [dateId] CreatePrepareQcVideosTaskRequest dateId
                     * @property {number|null} [startHour] CreatePrepareQcVideosTaskRequest startHour
                     * @property {number|null} [endHour] CreatePrepareQcVideosTaskRequest endHour
                     * @property {Array.<string>|null} [cameraIds] CreatePrepareQcVideosTaskRequest cameraIds
                     * @property {boolean|null} [compressVideo] CreatePrepareQcVideosTaskRequest compressVideo
                     * @property {Array.<string>|null} [notificationEmails] CreatePrepareQcVideosTaskRequest notificationEmails
                     * @property {palexy.sherlock.v1.SherlockTask.StorageProvider|null} [storageProvider] CreatePrepareQcVideosTaskRequest storageProvider
                     * @property {boolean|null} [anonymizeFace] CreatePrepareQcVideosTaskRequest anonymizeFace
                     * @property {number|null} [fps] CreatePrepareQcVideosTaskRequest fps
                     * @property {boolean|null} [createQcPlan] CreatePrepareQcVideosTaskRequest createQcPlan
                     */
    
                    /**
                     * Constructs a new CreatePrepareQcVideosTaskRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreatePrepareQcVideosTaskRequest.
                     * @implements ICreatePrepareQcVideosTaskRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreatePrepareQcVideosTaskRequest=} [properties] Properties to set
                     */
                    function CreatePrepareQcVideosTaskRequest(properties) {
                        this.cameraIds = [];
                        this.notificationEmails = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreatePrepareQcVideosTaskRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CreatePrepareQcVideosTaskRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.dateId = "";
    
                    /**
                     * CreatePrepareQcVideosTaskRequest startHour.
                     * @member {number} startHour
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.startHour = 0;
    
                    /**
                     * CreatePrepareQcVideosTaskRequest endHour.
                     * @member {number} endHour
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.endHour = 0;
    
                    /**
                     * CreatePrepareQcVideosTaskRequest cameraIds.
                     * @member {Array.<string>} cameraIds
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.cameraIds = $util.emptyArray;
    
                    /**
                     * CreatePrepareQcVideosTaskRequest compressVideo.
                     * @member {boolean} compressVideo
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.compressVideo = false;
    
                    /**
                     * CreatePrepareQcVideosTaskRequest notificationEmails.
                     * @member {Array.<string>} notificationEmails
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.notificationEmails = $util.emptyArray;
    
                    /**
                     * CreatePrepareQcVideosTaskRequest storageProvider.
                     * @member {palexy.sherlock.v1.SherlockTask.StorageProvider} storageProvider
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.storageProvider = 0;
    
                    /**
                     * CreatePrepareQcVideosTaskRequest anonymizeFace.
                     * @member {boolean} anonymizeFace
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.anonymizeFace = false;
    
                    /**
                     * CreatePrepareQcVideosTaskRequest fps.
                     * @member {number} fps
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.fps = 0;
    
                    /**
                     * CreatePrepareQcVideosTaskRequest createQcPlan.
                     * @member {boolean} createQcPlan
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.createQcPlan = false;
    
                    /**
                     * Creates a new CreatePrepareQcVideosTaskRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreatePrepareQcVideosTaskRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest} CreatePrepareQcVideosTaskRequest instance
                     */
                    CreatePrepareQcVideosTaskRequest.create = function create(properties) {
                        return new CreatePrepareQcVideosTaskRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreatePrepareQcVideosTaskRequest message. Does not implicitly {@link palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreatePrepareQcVideosTaskRequest} message CreatePrepareQcVideosTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreatePrepareQcVideosTaskRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        if (message.startHour != null && Object.hasOwnProperty.call(message, "startHour"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.startHour);
                        if (message.endHour != null && Object.hasOwnProperty.call(message, "endHour"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.endHour);
                        if (message.cameraIds != null && message.cameraIds.length)
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.cameraIds[i]);
                        if (message.compressVideo != null && Object.hasOwnProperty.call(message, "compressVideo"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.compressVideo);
                        if (message.notificationEmails != null && message.notificationEmails.length)
                            for (var i = 0; i < message.notificationEmails.length; ++i)
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.notificationEmails[i]);
                        if (message.storageProvider != null && Object.hasOwnProperty.call(message, "storageProvider"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.storageProvider);
                        if (message.anonymizeFace != null && Object.hasOwnProperty.call(message, "anonymizeFace"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.anonymizeFace);
                        if (message.fps != null && Object.hasOwnProperty.call(message, "fps"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.fps);
                        if (message.createQcPlan != null && Object.hasOwnProperty.call(message, "createQcPlan"))
                            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.createQcPlan);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreatePrepareQcVideosTaskRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreatePrepareQcVideosTaskRequest} message CreatePrepareQcVideosTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreatePrepareQcVideosTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreatePrepareQcVideosTaskRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest} CreatePrepareQcVideosTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreatePrepareQcVideosTaskRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            case 3:
                                message.startHour = reader.int32();
                                break;
                            case 4:
                                message.endHour = reader.int32();
                                break;
                            case 5:
                                if (!(message.cameraIds && message.cameraIds.length))
                                    message.cameraIds = [];
                                message.cameraIds.push(reader.string());
                                break;
                            case 6:
                                message.compressVideo = reader.bool();
                                break;
                            case 7:
                                if (!(message.notificationEmails && message.notificationEmails.length))
                                    message.notificationEmails = [];
                                message.notificationEmails.push(reader.string());
                                break;
                            case 8:
                                message.storageProvider = reader.int32();
                                break;
                            case 9:
                                message.anonymizeFace = reader.bool();
                                break;
                            case 10:
                                message.fps = reader.int32();
                                break;
                            case 11:
                                message.createQcPlan = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreatePrepareQcVideosTaskRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest} CreatePrepareQcVideosTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreatePrepareQcVideosTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreatePrepareQcVideosTaskRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreatePrepareQcVideosTaskRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.startHour != null && message.hasOwnProperty("startHour"))
                            if (!$util.isInteger(message.startHour))
                                return "startHour: integer expected";
                        if (message.endHour != null && message.hasOwnProperty("endHour"))
                            if (!$util.isInteger(message.endHour))
                                return "endHour: integer expected";
                        if (message.cameraIds != null && message.hasOwnProperty("cameraIds")) {
                            if (!Array.isArray(message.cameraIds))
                                return "cameraIds: array expected";
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                if (!$util.isString(message.cameraIds[i]))
                                    return "cameraIds: string[] expected";
                        }
                        if (message.compressVideo != null && message.hasOwnProperty("compressVideo"))
                            if (typeof message.compressVideo !== "boolean")
                                return "compressVideo: boolean expected";
                        if (message.notificationEmails != null && message.hasOwnProperty("notificationEmails")) {
                            if (!Array.isArray(message.notificationEmails))
                                return "notificationEmails: array expected";
                            for (var i = 0; i < message.notificationEmails.length; ++i)
                                if (!$util.isString(message.notificationEmails[i]))
                                    return "notificationEmails: string[] expected";
                        }
                        if (message.storageProvider != null && message.hasOwnProperty("storageProvider"))
                            switch (message.storageProvider) {
                            default:
                                return "storageProvider: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.anonymizeFace != null && message.hasOwnProperty("anonymizeFace"))
                            if (typeof message.anonymizeFace !== "boolean")
                                return "anonymizeFace: boolean expected";
                        if (message.fps != null && message.hasOwnProperty("fps"))
                            if (!$util.isInteger(message.fps))
                                return "fps: integer expected";
                        if (message.createQcPlan != null && message.hasOwnProperty("createQcPlan"))
                            if (typeof message.createQcPlan !== "boolean")
                                return "createQcPlan: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreatePrepareQcVideosTaskRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest} CreatePrepareQcVideosTaskRequest
                     */
                    CreatePrepareQcVideosTaskRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.startHour != null)
                            message.startHour = object.startHour | 0;
                        if (object.endHour != null)
                            message.endHour = object.endHour | 0;
                        if (object.cameraIds) {
                            if (!Array.isArray(object.cameraIds))
                                throw TypeError(".palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest.cameraIds: array expected");
                            message.cameraIds = [];
                            for (var i = 0; i < object.cameraIds.length; ++i)
                                message.cameraIds[i] = String(object.cameraIds[i]);
                        }
                        if (object.compressVideo != null)
                            message.compressVideo = Boolean(object.compressVideo);
                        if (object.notificationEmails) {
                            if (!Array.isArray(object.notificationEmails))
                                throw TypeError(".palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest.notificationEmails: array expected");
                            message.notificationEmails = [];
                            for (var i = 0; i < object.notificationEmails.length; ++i)
                                message.notificationEmails[i] = String(object.notificationEmails[i]);
                        }
                        switch (object.storageProvider) {
                        case "UNKNOWN_PROVIDER":
                        case 0:
                            message.storageProvider = 0;
                            break;
                        case "GCP":
                        case 1:
                            message.storageProvider = 1;
                            break;
                        case "GOOGLE_DRIVE":
                        case 2:
                            message.storageProvider = 2;
                            break;
                        }
                        if (object.anonymizeFace != null)
                            message.anonymizeFace = Boolean(object.anonymizeFace);
                        if (object.fps != null)
                            message.fps = object.fps | 0;
                        if (object.createQcPlan != null)
                            message.createQcPlan = Boolean(object.createQcPlan);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreatePrepareQcVideosTaskRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest} message CreatePrepareQcVideosTaskRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreatePrepareQcVideosTaskRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.cameraIds = [];
                            object.notificationEmails = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.startHour = 0;
                            object.endHour = 0;
                            object.compressVideo = false;
                            object.storageProvider = options.enums === String ? "UNKNOWN_PROVIDER" : 0;
                            object.anonymizeFace = false;
                            object.fps = 0;
                            object.createQcPlan = false;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.startHour != null && message.hasOwnProperty("startHour"))
                            object.startHour = message.startHour;
                        if (message.endHour != null && message.hasOwnProperty("endHour"))
                            object.endHour = message.endHour;
                        if (message.cameraIds && message.cameraIds.length) {
                            object.cameraIds = [];
                            for (var j = 0; j < message.cameraIds.length; ++j)
                                object.cameraIds[j] = message.cameraIds[j];
                        }
                        if (message.compressVideo != null && message.hasOwnProperty("compressVideo"))
                            object.compressVideo = message.compressVideo;
                        if (message.notificationEmails && message.notificationEmails.length) {
                            object.notificationEmails = [];
                            for (var j = 0; j < message.notificationEmails.length; ++j)
                                object.notificationEmails[j] = message.notificationEmails[j];
                        }
                        if (message.storageProvider != null && message.hasOwnProperty("storageProvider"))
                            object.storageProvider = options.enums === String ? $root.palexy.sherlock.v1.SherlockTask.StorageProvider[message.storageProvider] : message.storageProvider;
                        if (message.anonymizeFace != null && message.hasOwnProperty("anonymizeFace"))
                            object.anonymizeFace = message.anonymizeFace;
                        if (message.fps != null && message.hasOwnProperty("fps"))
                            object.fps = message.fps;
                        if (message.createQcPlan != null && message.hasOwnProperty("createQcPlan"))
                            object.createQcPlan = message.createQcPlan;
                        return object;
                    };
    
                    /**
                     * Converts this CreatePrepareQcVideosTaskRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreatePrepareQcVideosTaskRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreatePrepareQcVideosTaskRequest;
                })();
    
                v1.CreatePrepareQcVideosTaskResponse = (function() {
    
                    /**
                     * Properties of a CreatePrepareQcVideosTaskResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreatePrepareQcVideosTaskResponse
                     * @property {Array.<palexy.sherlock.v1.ISherlockTask>|null} [tasks] CreatePrepareQcVideosTaskResponse tasks
                     */
    
                    /**
                     * Constructs a new CreatePrepareQcVideosTaskResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreatePrepareQcVideosTaskResponse.
                     * @implements ICreatePrepareQcVideosTaskResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreatePrepareQcVideosTaskResponse=} [properties] Properties to set
                     */
                    function CreatePrepareQcVideosTaskResponse(properties) {
                        this.tasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreatePrepareQcVideosTaskResponse tasks.
                     * @member {Array.<palexy.sherlock.v1.ISherlockTask>} tasks
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse
                     * @instance
                     */
                    CreatePrepareQcVideosTaskResponse.prototype.tasks = $util.emptyArray;
    
                    /**
                     * Creates a new CreatePrepareQcVideosTaskResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreatePrepareQcVideosTaskResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse} CreatePrepareQcVideosTaskResponse instance
                     */
                    CreatePrepareQcVideosTaskResponse.create = function create(properties) {
                        return new CreatePrepareQcVideosTaskResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreatePrepareQcVideosTaskResponse message. Does not implicitly {@link palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreatePrepareQcVideosTaskResponse} message CreatePrepareQcVideosTaskResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreatePrepareQcVideosTaskResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.tasks != null && message.tasks.length)
                            for (var i = 0; i < message.tasks.length; ++i)
                                $root.palexy.sherlock.v1.SherlockTask.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreatePrepareQcVideosTaskResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreatePrepareQcVideosTaskResponse} message CreatePrepareQcVideosTaskResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreatePrepareQcVideosTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreatePrepareQcVideosTaskResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse} CreatePrepareQcVideosTaskResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreatePrepareQcVideosTaskResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.palexy.sherlock.v1.SherlockTask.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreatePrepareQcVideosTaskResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse} CreatePrepareQcVideosTaskResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreatePrepareQcVideosTaskResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreatePrepareQcVideosTaskResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreatePrepareQcVideosTaskResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.tasks != null && message.hasOwnProperty("tasks")) {
                            if (!Array.isArray(message.tasks))
                                return "tasks: array expected";
                            for (var i = 0; i < message.tasks.length; ++i) {
                                var error = $root.palexy.sherlock.v1.SherlockTask.verify(message.tasks[i]);
                                if (error)
                                    return "tasks." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreatePrepareQcVideosTaskResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse} CreatePrepareQcVideosTaskResponse
                     */
                    CreatePrepareQcVideosTaskResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse();
                        if (object.tasks) {
                            if (!Array.isArray(object.tasks))
                                throw TypeError(".palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse.tasks: array expected");
                            message.tasks = [];
                            for (var i = 0; i < object.tasks.length; ++i) {
                                if (typeof object.tasks[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse.tasks: object expected");
                                message.tasks[i] = $root.palexy.sherlock.v1.SherlockTask.fromObject(object.tasks[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreatePrepareQcVideosTaskResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse} message CreatePrepareQcVideosTaskResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreatePrepareQcVideosTaskResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.tasks = [];
                        if (message.tasks && message.tasks.length) {
                            object.tasks = [];
                            for (var j = 0; j < message.tasks.length; ++j)
                                object.tasks[j] = $root.palexy.sherlock.v1.SherlockTask.toObject(message.tasks[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CreatePrepareQcVideosTaskResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreatePrepareQcVideosTaskResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreatePrepareQcVideosTaskResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreatePrepareQcVideosTaskResponse;
                })();
    
                v1.GoogleCloudService = (function() {
    
                    /**
                     * Constructs a new GoogleCloudService service.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GoogleCloudService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function GoogleCloudService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (GoogleCloudService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = GoogleCloudService;
    
                    /**
                     * Creates new GoogleCloudService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.sherlock.v1.GoogleCloudService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {GoogleCloudService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    GoogleCloudService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.GoogleCloudService#generateSignedUrl}.
                     * @memberof palexy.sherlock.v1.GoogleCloudService
                     * @typedef GenerateSignedUrlCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.GenerateSignedUrlResponse} [response] GenerateSignedUrlResponse
                     */
    
                    /**
                     * Calls GenerateSignedUrl.
                     * @function generateSignedUrl
                     * @memberof palexy.sherlock.v1.GoogleCloudService
                     * @instance
                     * @param {palexy.sherlock.v1.IGenerateSignedUrlRequest} request GenerateSignedUrlRequest message or plain object
                     * @param {palexy.sherlock.v1.GoogleCloudService.GenerateSignedUrlCallback} callback Node-style callback called with the error, if any, and GenerateSignedUrlResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(GoogleCloudService.prototype.generateSignedUrl = function generateSignedUrl(request, callback) {
                        return this.rpcCall(generateSignedUrl, $root.palexy.sherlock.v1.GenerateSignedUrlRequest, $root.palexy.sherlock.v1.GenerateSignedUrlResponse, request, callback);
                    }, "name", { value: "GenerateSignedUrl" });
    
                    /**
                     * Calls GenerateSignedUrl.
                     * @function generateSignedUrl
                     * @memberof palexy.sherlock.v1.GoogleCloudService
                     * @instance
                     * @param {palexy.sherlock.v1.IGenerateSignedUrlRequest} request GenerateSignedUrlRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.GenerateSignedUrlResponse>} Promise
                     * @variation 2
                     */
    
                    return GoogleCloudService;
                })();
    
                v1.GenerateSignedUrlRequest = (function() {
    
                    /**
                     * Properties of a GenerateSignedUrlRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IGenerateSignedUrlRequest
                     * @property {string|null} [bucketName] GenerateSignedUrlRequest bucketName
                     * @property {string|null} [objectName] GenerateSignedUrlRequest objectName
                     * @property {number|null} [expiration] GenerateSignedUrlRequest expiration
                     */
    
                    /**
                     * Constructs a new GenerateSignedUrlRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GenerateSignedUrlRequest.
                     * @implements IGenerateSignedUrlRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IGenerateSignedUrlRequest=} [properties] Properties to set
                     */
                    function GenerateSignedUrlRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GenerateSignedUrlRequest bucketName.
                     * @member {string} bucketName
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @instance
                     */
                    GenerateSignedUrlRequest.prototype.bucketName = "";
    
                    /**
                     * GenerateSignedUrlRequest objectName.
                     * @member {string} objectName
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @instance
                     */
                    GenerateSignedUrlRequest.prototype.objectName = "";
    
                    /**
                     * GenerateSignedUrlRequest expiration.
                     * @member {number} expiration
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @instance
                     */
                    GenerateSignedUrlRequest.prototype.expiration = 0;
    
                    /**
                     * Creates a new GenerateSignedUrlRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGenerateSignedUrlRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GenerateSignedUrlRequest} GenerateSignedUrlRequest instance
                     */
                    GenerateSignedUrlRequest.create = function create(properties) {
                        return new GenerateSignedUrlRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GenerateSignedUrlRequest message. Does not implicitly {@link palexy.sherlock.v1.GenerateSignedUrlRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGenerateSignedUrlRequest} message GenerateSignedUrlRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateSignedUrlRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.bucketName != null && Object.hasOwnProperty.call(message, "bucketName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.bucketName);
                        if (message.objectName != null && Object.hasOwnProperty.call(message, "objectName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.objectName);
                        if (message.expiration != null && Object.hasOwnProperty.call(message, "expiration"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.expiration);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GenerateSignedUrlRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GenerateSignedUrlRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGenerateSignedUrlRequest} message GenerateSignedUrlRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateSignedUrlRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GenerateSignedUrlRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GenerateSignedUrlRequest} GenerateSignedUrlRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateSignedUrlRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GenerateSignedUrlRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.bucketName = reader.string();
                                break;
                            case 2:
                                message.objectName = reader.string();
                                break;
                            case 3:
                                message.expiration = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GenerateSignedUrlRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GenerateSignedUrlRequest} GenerateSignedUrlRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateSignedUrlRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GenerateSignedUrlRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GenerateSignedUrlRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.bucketName != null && message.hasOwnProperty("bucketName"))
                            if (!$util.isString(message.bucketName))
                                return "bucketName: string expected";
                        if (message.objectName != null && message.hasOwnProperty("objectName"))
                            if (!$util.isString(message.objectName))
                                return "objectName: string expected";
                        if (message.expiration != null && message.hasOwnProperty("expiration"))
                            if (!$util.isInteger(message.expiration))
                                return "expiration: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a GenerateSignedUrlRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GenerateSignedUrlRequest} GenerateSignedUrlRequest
                     */
                    GenerateSignedUrlRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GenerateSignedUrlRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GenerateSignedUrlRequest();
                        if (object.bucketName != null)
                            message.bucketName = String(object.bucketName);
                        if (object.objectName != null)
                            message.objectName = String(object.objectName);
                        if (object.expiration != null)
                            message.expiration = object.expiration | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GenerateSignedUrlRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @static
                     * @param {palexy.sherlock.v1.GenerateSignedUrlRequest} message GenerateSignedUrlRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GenerateSignedUrlRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.bucketName = "";
                            object.objectName = "";
                            object.expiration = 0;
                        }
                        if (message.bucketName != null && message.hasOwnProperty("bucketName"))
                            object.bucketName = message.bucketName;
                        if (message.objectName != null && message.hasOwnProperty("objectName"))
                            object.objectName = message.objectName;
                        if (message.expiration != null && message.hasOwnProperty("expiration"))
                            object.expiration = message.expiration;
                        return object;
                    };
    
                    /**
                     * Converts this GenerateSignedUrlRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GenerateSignedUrlRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GenerateSignedUrlRequest;
                })();
    
                v1.GenerateSignedUrlResponse = (function() {
    
                    /**
                     * Properties of a GenerateSignedUrlResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IGenerateSignedUrlResponse
                     * @property {string|null} [url] GenerateSignedUrlResponse url
                     * @property {number|null} [expiration] GenerateSignedUrlResponse expiration
                     */
    
                    /**
                     * Constructs a new GenerateSignedUrlResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GenerateSignedUrlResponse.
                     * @implements IGenerateSignedUrlResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IGenerateSignedUrlResponse=} [properties] Properties to set
                     */
                    function GenerateSignedUrlResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GenerateSignedUrlResponse url.
                     * @member {string} url
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlResponse
                     * @instance
                     */
                    GenerateSignedUrlResponse.prototype.url = "";
    
                    /**
                     * GenerateSignedUrlResponse expiration.
                     * @member {number} expiration
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlResponse
                     * @instance
                     */
                    GenerateSignedUrlResponse.prototype.expiration = 0;
    
                    /**
                     * Creates a new GenerateSignedUrlResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGenerateSignedUrlResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GenerateSignedUrlResponse} GenerateSignedUrlResponse instance
                     */
                    GenerateSignedUrlResponse.create = function create(properties) {
                        return new GenerateSignedUrlResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GenerateSignedUrlResponse message. Does not implicitly {@link palexy.sherlock.v1.GenerateSignedUrlResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGenerateSignedUrlResponse} message GenerateSignedUrlResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateSignedUrlResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                        if (message.expiration != null && Object.hasOwnProperty.call(message, "expiration"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.expiration);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GenerateSignedUrlResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GenerateSignedUrlResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGenerateSignedUrlResponse} message GenerateSignedUrlResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateSignedUrlResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GenerateSignedUrlResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GenerateSignedUrlResponse} GenerateSignedUrlResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateSignedUrlResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GenerateSignedUrlResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.url = reader.string();
                                break;
                            case 2:
                                message.expiration = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GenerateSignedUrlResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GenerateSignedUrlResponse} GenerateSignedUrlResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateSignedUrlResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GenerateSignedUrlResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GenerateSignedUrlResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.url != null && message.hasOwnProperty("url"))
                            if (!$util.isString(message.url))
                                return "url: string expected";
                        if (message.expiration != null && message.hasOwnProperty("expiration"))
                            if (!$util.isInteger(message.expiration))
                                return "expiration: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a GenerateSignedUrlResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GenerateSignedUrlResponse} GenerateSignedUrlResponse
                     */
                    GenerateSignedUrlResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GenerateSignedUrlResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GenerateSignedUrlResponse();
                        if (object.url != null)
                            message.url = String(object.url);
                        if (object.expiration != null)
                            message.expiration = object.expiration | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GenerateSignedUrlResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlResponse
                     * @static
                     * @param {palexy.sherlock.v1.GenerateSignedUrlResponse} message GenerateSignedUrlResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GenerateSignedUrlResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.url = "";
                            object.expiration = 0;
                        }
                        if (message.url != null && message.hasOwnProperty("url"))
                            object.url = message.url;
                        if (message.expiration != null && message.hasOwnProperty("expiration"))
                            object.expiration = message.expiration;
                        return object;
                    };
    
                    /**
                     * Converts this GenerateSignedUrlResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GenerateSignedUrlResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GenerateSignedUrlResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GenerateSignedUrlResponse;
                })();
    
                v1.QcDataService = (function() {
    
                    /**
                     * Constructs a new QcDataService service.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a QcDataService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function QcDataService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (QcDataService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = QcDataService;
    
                    /**
                     * Creates new QcDataService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {QcDataService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    QcDataService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcDataService#uploadQcFile}.
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @typedef UploadQcFileCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.UploadQcFileResponse} [response] UploadQcFileResponse
                     */
    
                    /**
                     * Calls UploadQcFile.
                     * @function uploadQcFile
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @instance
                     * @param {palexy.sherlock.v1.IUploadQcFileRequest} request UploadQcFileRequest message or plain object
                     * @param {palexy.sherlock.v1.QcDataService.UploadQcFileCallback} callback Node-style callback called with the error, if any, and UploadQcFileResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcDataService.prototype.uploadQcFile = function uploadQcFile(request, callback) {
                        return this.rpcCall(uploadQcFile, $root.palexy.sherlock.v1.UploadQcFileRequest, $root.palexy.sherlock.v1.UploadQcFileResponse, request, callback);
                    }, "name", { value: "UploadQcFile" });
    
                    /**
                     * Calls UploadQcFile.
                     * @function uploadQcFile
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @instance
                     * @param {palexy.sherlock.v1.IUploadQcFileRequest} request UploadQcFileRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.UploadQcFileResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcDataService#createQcFile}.
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @typedef CreateQcFileCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.QcFile} [response] QcFile
                     */
    
                    /**
                     * Calls CreateQcFile.
                     * @function createQcFile
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @instance
                     * @param {palexy.sherlock.v1.IQcFile} request QcFile message or plain object
                     * @param {palexy.sherlock.v1.QcDataService.CreateQcFileCallback} callback Node-style callback called with the error, if any, and QcFile
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcDataService.prototype.createQcFile = function createQcFile(request, callback) {
                        return this.rpcCall(createQcFile, $root.palexy.sherlock.v1.QcFile, $root.palexy.sherlock.v1.QcFile, request, callback);
                    }, "name", { value: "CreateQcFile" });
    
                    /**
                     * Calls CreateQcFile.
                     * @function createQcFile
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @instance
                     * @param {palexy.sherlock.v1.IQcFile} request QcFile message or plain object
                     * @returns {Promise<palexy.sherlock.v1.QcFile>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcDataService#listQcFiles}.
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @typedef ListQcFilesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.ListQcFilesResponse} [response] ListQcFilesResponse
                     */
    
                    /**
                     * Calls ListQcFiles.
                     * @function listQcFiles
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @instance
                     * @param {palexy.sherlock.v1.IListQcFilesRequest} request ListQcFilesRequest message or plain object
                     * @param {palexy.sherlock.v1.QcDataService.ListQcFilesCallback} callback Node-style callback called with the error, if any, and ListQcFilesResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcDataService.prototype.listQcFiles = function listQcFiles(request, callback) {
                        return this.rpcCall(listQcFiles, $root.palexy.sherlock.v1.ListQcFilesRequest, $root.palexy.sherlock.v1.ListQcFilesResponse, request, callback);
                    }, "name", { value: "ListQcFiles" });
    
                    /**
                     * Calls ListQcFiles.
                     * @function listQcFiles
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @instance
                     * @param {palexy.sherlock.v1.IListQcFilesRequest} request ListQcFilesRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.ListQcFilesResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcDataService#getQcFile}.
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @typedef GetQcFileCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.GetQcFileResponse} [response] GetQcFileResponse
                     */
    
                    /**
                     * Calls GetQcFile.
                     * @function getQcFile
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @instance
                     * @param {palexy.sherlock.v1.IGetQcFileRequest} request GetQcFileRequest message or plain object
                     * @param {palexy.sherlock.v1.QcDataService.GetQcFileCallback} callback Node-style callback called with the error, if any, and GetQcFileResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcDataService.prototype.getQcFile = function getQcFile(request, callback) {
                        return this.rpcCall(getQcFile, $root.palexy.sherlock.v1.GetQcFileRequest, $root.palexy.sherlock.v1.GetQcFileResponse, request, callback);
                    }, "name", { value: "GetQcFile" });
    
                    /**
                     * Calls GetQcFile.
                     * @function getQcFile
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @instance
                     * @param {palexy.sherlock.v1.IGetQcFileRequest} request GetQcFileRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.GetQcFileResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcDataService#deleteQcFile}.
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @typedef DeleteQcFileCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.DeleteQcFileResponse} [response] DeleteQcFileResponse
                     */
    
                    /**
                     * Calls DeleteQcFile.
                     * @function deleteQcFile
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @instance
                     * @param {palexy.sherlock.v1.IDeleteQcFileRequest} request DeleteQcFileRequest message or plain object
                     * @param {palexy.sherlock.v1.QcDataService.DeleteQcFileCallback} callback Node-style callback called with the error, if any, and DeleteQcFileResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcDataService.prototype.deleteQcFile = function deleteQcFile(request, callback) {
                        return this.rpcCall(deleteQcFile, $root.palexy.sherlock.v1.DeleteQcFileRequest, $root.palexy.sherlock.v1.DeleteQcFileResponse, request, callback);
                    }, "name", { value: "DeleteQcFile" });
    
                    /**
                     * Calls DeleteQcFile.
                     * @function deleteQcFile
                     * @memberof palexy.sherlock.v1.QcDataService
                     * @instance
                     * @param {palexy.sherlock.v1.IDeleteQcFileRequest} request DeleteQcFileRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.DeleteQcFileResponse>} Promise
                     * @variation 2
                     */
    
                    return QcDataService;
                })();
    
                v1.UploadQcFileRequest = (function() {
    
                    /**
                     * Properties of an UploadQcFileRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IUploadQcFileRequest
                     * @property {Uint8Array|null} [content] UploadQcFileRequest content
                     * @property {palexy.sherlock.v1.IQcFile|null} [qcFile] UploadQcFileRequest qcFile
                     */
    
                    /**
                     * Constructs a new UploadQcFileRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UploadQcFileRequest.
                     * @implements IUploadQcFileRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IUploadQcFileRequest=} [properties] Properties to set
                     */
                    function UploadQcFileRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UploadQcFileRequest content.
                     * @member {Uint8Array} content
                     * @memberof palexy.sherlock.v1.UploadQcFileRequest
                     * @instance
                     */
                    UploadQcFileRequest.prototype.content = $util.newBuffer([]);
    
                    /**
                     * UploadQcFileRequest qcFile.
                     * @member {palexy.sherlock.v1.IQcFile|null|undefined} qcFile
                     * @memberof palexy.sherlock.v1.UploadQcFileRequest
                     * @instance
                     */
                    UploadQcFileRequest.prototype.qcFile = null;
    
                    /**
                     * Creates a new UploadQcFileRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UploadQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUploadQcFileRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UploadQcFileRequest} UploadQcFileRequest instance
                     */
                    UploadQcFileRequest.create = function create(properties) {
                        return new UploadQcFileRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UploadQcFileRequest message. Does not implicitly {@link palexy.sherlock.v1.UploadQcFileRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UploadQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUploadQcFileRequest} message UploadQcFileRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UploadQcFileRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.content);
                        if (message.qcFile != null && Object.hasOwnProperty.call(message, "qcFile"))
                            $root.palexy.sherlock.v1.QcFile.encode(message.qcFile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UploadQcFileRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UploadQcFileRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UploadQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUploadQcFileRequest} message UploadQcFileRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UploadQcFileRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UploadQcFileRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UploadQcFileRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UploadQcFileRequest} UploadQcFileRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UploadQcFileRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UploadQcFileRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.content = reader.bytes();
                                break;
                            case 2:
                                message.qcFile = $root.palexy.sherlock.v1.QcFile.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UploadQcFileRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UploadQcFileRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UploadQcFileRequest} UploadQcFileRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UploadQcFileRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UploadQcFileRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UploadQcFileRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UploadQcFileRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.content != null && message.hasOwnProperty("content"))
                            if (!(message.content && typeof message.content.length === "number" || $util.isString(message.content)))
                                return "content: buffer expected";
                        if (message.qcFile != null && message.hasOwnProperty("qcFile")) {
                            var error = $root.palexy.sherlock.v1.QcFile.verify(message.qcFile);
                            if (error)
                                return "qcFile." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UploadQcFileRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UploadQcFileRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UploadQcFileRequest} UploadQcFileRequest
                     */
                    UploadQcFileRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UploadQcFileRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.UploadQcFileRequest();
                        if (object.content != null)
                            if (typeof object.content === "string")
                                $util.base64.decode(object.content, message.content = $util.newBuffer($util.base64.length(object.content)), 0);
                            else if (object.content.length)
                                message.content = object.content;
                        if (object.qcFile != null) {
                            if (typeof object.qcFile !== "object")
                                throw TypeError(".palexy.sherlock.v1.UploadQcFileRequest.qcFile: object expected");
                            message.qcFile = $root.palexy.sherlock.v1.QcFile.fromObject(object.qcFile);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UploadQcFileRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UploadQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.UploadQcFileRequest} message UploadQcFileRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UploadQcFileRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if (options.bytes === String)
                                object.content = "";
                            else {
                                object.content = [];
                                if (options.bytes !== Array)
                                    object.content = $util.newBuffer(object.content);
                            }
                            object.qcFile = null;
                        }
                        if (message.content != null && message.hasOwnProperty("content"))
                            object.content = options.bytes === String ? $util.base64.encode(message.content, 0, message.content.length) : options.bytes === Array ? Array.prototype.slice.call(message.content) : message.content;
                        if (message.qcFile != null && message.hasOwnProperty("qcFile"))
                            object.qcFile = $root.palexy.sherlock.v1.QcFile.toObject(message.qcFile, options);
                        return object;
                    };
    
                    /**
                     * Converts this UploadQcFileRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UploadQcFileRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UploadQcFileRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UploadQcFileRequest;
                })();
    
                v1.UploadQcFileResponse = (function() {
    
                    /**
                     * Properties of an UploadQcFileResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IUploadQcFileResponse
                     * @property {palexy.sherlock.v1.IQcFile|null} [qcFile] UploadQcFileResponse qcFile
                     */
    
                    /**
                     * Constructs a new UploadQcFileResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UploadQcFileResponse.
                     * @implements IUploadQcFileResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IUploadQcFileResponse=} [properties] Properties to set
                     */
                    function UploadQcFileResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UploadQcFileResponse qcFile.
                     * @member {palexy.sherlock.v1.IQcFile|null|undefined} qcFile
                     * @memberof palexy.sherlock.v1.UploadQcFileResponse
                     * @instance
                     */
                    UploadQcFileResponse.prototype.qcFile = null;
    
                    /**
                     * Creates a new UploadQcFileResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UploadQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUploadQcFileResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UploadQcFileResponse} UploadQcFileResponse instance
                     */
                    UploadQcFileResponse.create = function create(properties) {
                        return new UploadQcFileResponse(properties);
                    };
    
                    /**
                     * Encodes the specified UploadQcFileResponse message. Does not implicitly {@link palexy.sherlock.v1.UploadQcFileResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UploadQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUploadQcFileResponse} message UploadQcFileResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UploadQcFileResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.qcFile != null && Object.hasOwnProperty.call(message, "qcFile"))
                            $root.palexy.sherlock.v1.QcFile.encode(message.qcFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UploadQcFileResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UploadQcFileResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UploadQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUploadQcFileResponse} message UploadQcFileResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UploadQcFileResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UploadQcFileResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UploadQcFileResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UploadQcFileResponse} UploadQcFileResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UploadQcFileResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UploadQcFileResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.qcFile = $root.palexy.sherlock.v1.QcFile.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UploadQcFileResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UploadQcFileResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UploadQcFileResponse} UploadQcFileResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UploadQcFileResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UploadQcFileResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UploadQcFileResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UploadQcFileResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.qcFile != null && message.hasOwnProperty("qcFile")) {
                            var error = $root.palexy.sherlock.v1.QcFile.verify(message.qcFile);
                            if (error)
                                return "qcFile." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UploadQcFileResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UploadQcFileResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UploadQcFileResponse} UploadQcFileResponse
                     */
                    UploadQcFileResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UploadQcFileResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.UploadQcFileResponse();
                        if (object.qcFile != null) {
                            if (typeof object.qcFile !== "object")
                                throw TypeError(".palexy.sherlock.v1.UploadQcFileResponse.qcFile: object expected");
                            message.qcFile = $root.palexy.sherlock.v1.QcFile.fromObject(object.qcFile);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UploadQcFileResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UploadQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.UploadQcFileResponse} message UploadQcFileResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UploadQcFileResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.qcFile = null;
                        if (message.qcFile != null && message.hasOwnProperty("qcFile"))
                            object.qcFile = $root.palexy.sherlock.v1.QcFile.toObject(message.qcFile, options);
                        return object;
                    };
    
                    /**
                     * Converts this UploadQcFileResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UploadQcFileResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UploadQcFileResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UploadQcFileResponse;
                })();
    
                v1.QcFile = (function() {
    
                    /**
                     * Properties of a QcFile.
                     * @memberof palexy.sherlock.v1
                     * @interface IQcFile
                     * @property {string|null} [name] QcFile name
                     * @property {palexy.sherlock.v1.QcFile.FileType|null} [fileType] QcFile fileType
                     * @property {number|null} [storeId] QcFile storeId
                     * @property {string|null} [fileName] QcFile fileName
                     * @property {palexy.sherlock.v1.QcFile.QcType|null} [qcType] QcFile qcType
                     * @property {string|null} [dateId] QcFile dateId
                     * @property {palexy.sherlock.v1.IQcFileData|null} [qcFileData] QcFile qcFileData
                     * @property {number|null} [createTimeMs] QcFile createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] QcFile lastUpdateTimeMs
                     * @property {number|null} [id] QcFile id
                     * @property {number|null} [startHour] QcFile startHour
                     * @property {number|null} [endHour] QcFile endHour
                     */
    
                    /**
                     * Constructs a new QcFile.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a QcFile.
                     * @implements IQcFile
                     * @constructor
                     * @param {palexy.sherlock.v1.IQcFile=} [properties] Properties to set
                     */
                    function QcFile(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * QcFile name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.name = "";
    
                    /**
                     * QcFile fileType.
                     * @member {palexy.sherlock.v1.QcFile.FileType} fileType
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.fileType = 0;
    
                    /**
                     * QcFile storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcFile fileName.
                     * @member {string} fileName
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.fileName = "";
    
                    /**
                     * QcFile qcType.
                     * @member {palexy.sherlock.v1.QcFile.QcType} qcType
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.qcType = 0;
    
                    /**
                     * QcFile dateId.
                     * @member {string} dateId
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.dateId = "";
    
                    /**
                     * QcFile qcFileData.
                     * @member {palexy.sherlock.v1.IQcFileData|null|undefined} qcFileData
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.qcFileData = null;
    
                    /**
                     * QcFile createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcFile lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcFile id.
                     * @member {number} id
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcFile startHour.
                     * @member {number} startHour
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.startHour = 0;
    
                    /**
                     * QcFile endHour.
                     * @member {number} endHour
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     */
                    QcFile.prototype.endHour = 0;
    
                    /**
                     * Creates a new QcFile instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.QcFile
                     * @static
                     * @param {palexy.sherlock.v1.IQcFile=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.QcFile} QcFile instance
                     */
                    QcFile.create = function create(properties) {
                        return new QcFile(properties);
                    };
    
                    /**
                     * Encodes the specified QcFile message. Does not implicitly {@link palexy.sherlock.v1.QcFile.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.QcFile
                     * @static
                     * @param {palexy.sherlock.v1.IQcFile} message QcFile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QcFile.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.fileType != null && Object.hasOwnProperty.call(message, "fileType"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.fileType);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.fileName);
                        if (message.qcType != null && Object.hasOwnProperty.call(message, "qcType"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.qcType);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.dateId);
                        if (message.qcFileData != null && Object.hasOwnProperty.call(message, "qcFileData"))
                            $root.palexy.sherlock.v1.QcFileData.encode(message.qcFileData, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int64(message.lastUpdateTimeMs);
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int64(message.id);
                        if (message.startHour != null && Object.hasOwnProperty.call(message, "startHour"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.startHour);
                        if (message.endHour != null && Object.hasOwnProperty.call(message, "endHour"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.endHour);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified QcFile message, length delimited. Does not implicitly {@link palexy.sherlock.v1.QcFile.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.QcFile
                     * @static
                     * @param {palexy.sherlock.v1.IQcFile} message QcFile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QcFile.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a QcFile message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.QcFile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.QcFile} QcFile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QcFile.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.QcFile();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.fileType = reader.int32();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 4:
                                message.fileName = reader.string();
                                break;
                            case 5:
                                message.qcType = reader.int32();
                                break;
                            case 6:
                                message.dateId = reader.string();
                                break;
                            case 7:
                                message.qcFileData = $root.palexy.sherlock.v1.QcFileData.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.createTimeMs = reader.int64();
                                break;
                            case 9:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 10:
                                message.id = reader.int64();
                                break;
                            case 11:
                                message.startHour = reader.int32();
                                break;
                            case 12:
                                message.endHour = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a QcFile message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.QcFile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.QcFile} QcFile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QcFile.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a QcFile message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.QcFile
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    QcFile.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.fileType != null && message.hasOwnProperty("fileType"))
                            switch (message.fileType) {
                            default:
                                return "fileType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            if (!$util.isString(message.fileName))
                                return "fileName: string expected";
                        if (message.qcType != null && message.hasOwnProperty("qcType"))
                            switch (message.qcType) {
                            default:
                                return "qcType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.qcFileData != null && message.hasOwnProperty("qcFileData")) {
                            var error = $root.palexy.sherlock.v1.QcFileData.verify(message.qcFileData);
                            if (error)
                                return "qcFileData." + error;
                        }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.startHour != null && message.hasOwnProperty("startHour"))
                            if (!$util.isInteger(message.startHour))
                                return "startHour: integer expected";
                        if (message.endHour != null && message.hasOwnProperty("endHour"))
                            if (!$util.isInteger(message.endHour))
                                return "endHour: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a QcFile message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.QcFile
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.QcFile} QcFile
                     */
                    QcFile.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.QcFile)
                            return object;
                        var message = new $root.palexy.sherlock.v1.QcFile();
                        if (object.name != null)
                            message.name = String(object.name);
                        switch (object.fileType) {
                        case "UNKNOWN":
                        case 0:
                            message.fileType = 0;
                            break;
                        case "CSV":
                        case 1:
                            message.fileType = 1;
                            break;
                        }
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.fileName != null)
                            message.fileName = String(object.fileName);
                        switch (object.qcType) {
                        case "QC_TYPE_UNKNOWN":
                        case 0:
                            message.qcType = 0;
                            break;
                        case "ICA_STANDARD_COUNTING":
                        case 1:
                            message.qcType = 1;
                            break;
                        case "ICA_CUSTOMER_JOURNEY":
                        case 2:
                            message.qcType = 2;
                            break;
                        case "ICA_ENTRANCE_COUNTING":
                        case 3:
                            message.qcType = 3;
                            break;
                        }
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.qcFileData != null) {
                            if (typeof object.qcFileData !== "object")
                                throw TypeError(".palexy.sherlock.v1.QcFile.qcFileData: object expected");
                            message.qcFileData = $root.palexy.sherlock.v1.QcFileData.fromObject(object.qcFileData);
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.startHour != null)
                            message.startHour = object.startHour | 0;
                        if (object.endHour != null)
                            message.endHour = object.endHour | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a QcFile message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.QcFile
                     * @static
                     * @param {palexy.sherlock.v1.QcFile} message QcFile
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    QcFile.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.fileType = options.enums === String ? "UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.fileName = "";
                            object.qcType = options.enums === String ? "QC_TYPE_UNKNOWN" : 0;
                            object.dateId = "";
                            object.qcFileData = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.startHour = 0;
                            object.endHour = 0;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.fileType != null && message.hasOwnProperty("fileType"))
                            object.fileType = options.enums === String ? $root.palexy.sherlock.v1.QcFile.FileType[message.fileType] : message.fileType;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            object.fileName = message.fileName;
                        if (message.qcType != null && message.hasOwnProperty("qcType"))
                            object.qcType = options.enums === String ? $root.palexy.sherlock.v1.QcFile.QcType[message.qcType] : message.qcType;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.qcFileData != null && message.hasOwnProperty("qcFileData"))
                            object.qcFileData = $root.palexy.sherlock.v1.QcFileData.toObject(message.qcFileData, options);
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.startHour != null && message.hasOwnProperty("startHour"))
                            object.startHour = message.startHour;
                        if (message.endHour != null && message.hasOwnProperty("endHour"))
                            object.endHour = message.endHour;
                        return object;
                    };
    
                    /**
                     * Converts this QcFile to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.QcFile
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    QcFile.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * FileType enum.
                     * @name palexy.sherlock.v1.QcFile.FileType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} CSV=1 CSV value
                     */
                    QcFile.FileType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "CSV"] = 1;
                        return values;
                    })();
    
                    /**
                     * QcType enum.
                     * @name palexy.sherlock.v1.QcFile.QcType
                     * @enum {number}
                     * @property {number} QC_TYPE_UNKNOWN=0 QC_TYPE_UNKNOWN value
                     * @property {number} ICA_STANDARD_COUNTING=1 ICA_STANDARD_COUNTING value
                     * @property {number} ICA_CUSTOMER_JOURNEY=2 ICA_CUSTOMER_JOURNEY value
                     * @property {number} ICA_ENTRANCE_COUNTING=3 ICA_ENTRANCE_COUNTING value
                     */
                    QcFile.QcType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "QC_TYPE_UNKNOWN"] = 0;
                        values[valuesById[1] = "ICA_STANDARD_COUNTING"] = 1;
                        values[valuesById[2] = "ICA_CUSTOMER_JOURNEY"] = 2;
                        values[valuesById[3] = "ICA_ENTRANCE_COUNTING"] = 3;
                        return values;
                    })();
    
                    return QcFile;
                })();
    
                v1.QcFileData = (function() {
    
                    /**
                     * Properties of a QcFileData.
                     * @memberof palexy.sherlock.v1
                     * @interface IQcFileData
                     * @property {palexy.sherlock.v1.IIcaStandardCountingData|null} [icaStandarCountingData] QcFileData icaStandarCountingData
                     * @property {palexy.sherlock.v1.IIcaCustomerJourneyData|null} [icaCustomerJourneyData] QcFileData icaCustomerJourneyData
                     * @property {palexy.sherlock.v1.IIcaEntranceCountingData|null} [icaEntranceCountingData] QcFileData icaEntranceCountingData
                     */
    
                    /**
                     * Constructs a new QcFileData.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a QcFileData.
                     * @implements IQcFileData
                     * @constructor
                     * @param {palexy.sherlock.v1.IQcFileData=} [properties] Properties to set
                     */
                    function QcFileData(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * QcFileData icaStandarCountingData.
                     * @member {palexy.sherlock.v1.IIcaStandardCountingData|null|undefined} icaStandarCountingData
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @instance
                     */
                    QcFileData.prototype.icaStandarCountingData = null;
    
                    /**
                     * QcFileData icaCustomerJourneyData.
                     * @member {palexy.sherlock.v1.IIcaCustomerJourneyData|null|undefined} icaCustomerJourneyData
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @instance
                     */
                    QcFileData.prototype.icaCustomerJourneyData = null;
    
                    /**
                     * QcFileData icaEntranceCountingData.
                     * @member {palexy.sherlock.v1.IIcaEntranceCountingData|null|undefined} icaEntranceCountingData
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @instance
                     */
                    QcFileData.prototype.icaEntranceCountingData = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * QcFileData qcFileDetailData.
                     * @member {"icaStandarCountingData"|"icaCustomerJourneyData"|"icaEntranceCountingData"|undefined} qcFileDetailData
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @instance
                     */
                    Object.defineProperty(QcFileData.prototype, "qcFileDetailData", {
                        get: $util.oneOfGetter($oneOfFields = ["icaStandarCountingData", "icaCustomerJourneyData", "icaEntranceCountingData"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new QcFileData instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @static
                     * @param {palexy.sherlock.v1.IQcFileData=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.QcFileData} QcFileData instance
                     */
                    QcFileData.create = function create(properties) {
                        return new QcFileData(properties);
                    };
    
                    /**
                     * Encodes the specified QcFileData message. Does not implicitly {@link palexy.sherlock.v1.QcFileData.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @static
                     * @param {palexy.sherlock.v1.IQcFileData} message QcFileData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QcFileData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.icaStandarCountingData != null && Object.hasOwnProperty.call(message, "icaStandarCountingData"))
                            $root.palexy.sherlock.v1.IcaStandardCountingData.encode(message.icaStandarCountingData, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.icaCustomerJourneyData != null && Object.hasOwnProperty.call(message, "icaCustomerJourneyData"))
                            $root.palexy.sherlock.v1.IcaCustomerJourneyData.encode(message.icaCustomerJourneyData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.icaEntranceCountingData != null && Object.hasOwnProperty.call(message, "icaEntranceCountingData"))
                            $root.palexy.sherlock.v1.IcaEntranceCountingData.encode(message.icaEntranceCountingData, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified QcFileData message, length delimited. Does not implicitly {@link palexy.sherlock.v1.QcFileData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @static
                     * @param {palexy.sherlock.v1.IQcFileData} message QcFileData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QcFileData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a QcFileData message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.QcFileData} QcFileData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QcFileData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.QcFileData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.icaStandarCountingData = $root.palexy.sherlock.v1.IcaStandardCountingData.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.icaCustomerJourneyData = $root.palexy.sherlock.v1.IcaCustomerJourneyData.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.icaEntranceCountingData = $root.palexy.sherlock.v1.IcaEntranceCountingData.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a QcFileData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.QcFileData} QcFileData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QcFileData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a QcFileData message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    QcFileData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.icaStandarCountingData != null && message.hasOwnProperty("icaStandarCountingData")) {
                            properties.qcFileDetailData = 1;
                            {
                                var error = $root.palexy.sherlock.v1.IcaStandardCountingData.verify(message.icaStandarCountingData);
                                if (error)
                                    return "icaStandarCountingData." + error;
                            }
                        }
                        if (message.icaCustomerJourneyData != null && message.hasOwnProperty("icaCustomerJourneyData")) {
                            if (properties.qcFileDetailData === 1)
                                return "qcFileDetailData: multiple values";
                            properties.qcFileDetailData = 1;
                            {
                                var error = $root.palexy.sherlock.v1.IcaCustomerJourneyData.verify(message.icaCustomerJourneyData);
                                if (error)
                                    return "icaCustomerJourneyData." + error;
                            }
                        }
                        if (message.icaEntranceCountingData != null && message.hasOwnProperty("icaEntranceCountingData")) {
                            if (properties.qcFileDetailData === 1)
                                return "qcFileDetailData: multiple values";
                            properties.qcFileDetailData = 1;
                            {
                                var error = $root.palexy.sherlock.v1.IcaEntranceCountingData.verify(message.icaEntranceCountingData);
                                if (error)
                                    return "icaEntranceCountingData." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a QcFileData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.QcFileData} QcFileData
                     */
                    QcFileData.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.QcFileData)
                            return object;
                        var message = new $root.palexy.sherlock.v1.QcFileData();
                        if (object.icaStandarCountingData != null) {
                            if (typeof object.icaStandarCountingData !== "object")
                                throw TypeError(".palexy.sherlock.v1.QcFileData.icaStandarCountingData: object expected");
                            message.icaStandarCountingData = $root.palexy.sherlock.v1.IcaStandardCountingData.fromObject(object.icaStandarCountingData);
                        }
                        if (object.icaCustomerJourneyData != null) {
                            if (typeof object.icaCustomerJourneyData !== "object")
                                throw TypeError(".palexy.sherlock.v1.QcFileData.icaCustomerJourneyData: object expected");
                            message.icaCustomerJourneyData = $root.palexy.sherlock.v1.IcaCustomerJourneyData.fromObject(object.icaCustomerJourneyData);
                        }
                        if (object.icaEntranceCountingData != null) {
                            if (typeof object.icaEntranceCountingData !== "object")
                                throw TypeError(".palexy.sherlock.v1.QcFileData.icaEntranceCountingData: object expected");
                            message.icaEntranceCountingData = $root.palexy.sherlock.v1.IcaEntranceCountingData.fromObject(object.icaEntranceCountingData);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a QcFileData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @static
                     * @param {palexy.sherlock.v1.QcFileData} message QcFileData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    QcFileData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.icaStandarCountingData != null && message.hasOwnProperty("icaStandarCountingData")) {
                            object.icaStandarCountingData = $root.palexy.sherlock.v1.IcaStandardCountingData.toObject(message.icaStandarCountingData, options);
                            if (options.oneofs)
                                object.qcFileDetailData = "icaStandarCountingData";
                        }
                        if (message.icaCustomerJourneyData != null && message.hasOwnProperty("icaCustomerJourneyData")) {
                            object.icaCustomerJourneyData = $root.palexy.sherlock.v1.IcaCustomerJourneyData.toObject(message.icaCustomerJourneyData, options);
                            if (options.oneofs)
                                object.qcFileDetailData = "icaCustomerJourneyData";
                        }
                        if (message.icaEntranceCountingData != null && message.hasOwnProperty("icaEntranceCountingData")) {
                            object.icaEntranceCountingData = $root.palexy.sherlock.v1.IcaEntranceCountingData.toObject(message.icaEntranceCountingData, options);
                            if (options.oneofs)
                                object.qcFileDetailData = "icaEntranceCountingData";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this QcFileData to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.QcFileData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    QcFileData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return QcFileData;
                })();
    
                v1.IcaStandardCountingData = (function() {
    
                    /**
                     * Properties of an IcaStandardCountingData.
                     * @memberof palexy.sherlock.v1
                     * @interface IIcaStandardCountingData
                     * @property {Array.<palexy.sherlock.v1.IIcaStandardCountingDataRow>|null} [rows] IcaStandardCountingData rows
                     */
    
                    /**
                     * Constructs a new IcaStandardCountingData.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an IcaStandardCountingData.
                     * @implements IIcaStandardCountingData
                     * @constructor
                     * @param {palexy.sherlock.v1.IIcaStandardCountingData=} [properties] Properties to set
                     */
                    function IcaStandardCountingData(properties) {
                        this.rows = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * IcaStandardCountingData rows.
                     * @member {Array.<palexy.sherlock.v1.IIcaStandardCountingDataRow>} rows
                     * @memberof palexy.sherlock.v1.IcaStandardCountingData
                     * @instance
                     */
                    IcaStandardCountingData.prototype.rows = $util.emptyArray;
    
                    /**
                     * Creates a new IcaStandardCountingData instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.IcaStandardCountingData
                     * @static
                     * @param {palexy.sherlock.v1.IIcaStandardCountingData=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.IcaStandardCountingData} IcaStandardCountingData instance
                     */
                    IcaStandardCountingData.create = function create(properties) {
                        return new IcaStandardCountingData(properties);
                    };
    
                    /**
                     * Encodes the specified IcaStandardCountingData message. Does not implicitly {@link palexy.sherlock.v1.IcaStandardCountingData.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.IcaStandardCountingData
                     * @static
                     * @param {palexy.sherlock.v1.IIcaStandardCountingData} message IcaStandardCountingData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaStandardCountingData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rows != null && message.rows.length)
                            for (var i = 0; i < message.rows.length; ++i)
                                $root.palexy.sherlock.v1.IcaStandardCountingDataRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified IcaStandardCountingData message, length delimited. Does not implicitly {@link palexy.sherlock.v1.IcaStandardCountingData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.IcaStandardCountingData
                     * @static
                     * @param {palexy.sherlock.v1.IIcaStandardCountingData} message IcaStandardCountingData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaStandardCountingData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an IcaStandardCountingData message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.IcaStandardCountingData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.IcaStandardCountingData} IcaStandardCountingData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaStandardCountingData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.IcaStandardCountingData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.rows && message.rows.length))
                                    message.rows = [];
                                message.rows.push($root.palexy.sherlock.v1.IcaStandardCountingDataRow.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an IcaStandardCountingData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.IcaStandardCountingData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.IcaStandardCountingData} IcaStandardCountingData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaStandardCountingData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an IcaStandardCountingData message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.IcaStandardCountingData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    IcaStandardCountingData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rows != null && message.hasOwnProperty("rows")) {
                            if (!Array.isArray(message.rows))
                                return "rows: array expected";
                            for (var i = 0; i < message.rows.length; ++i) {
                                var error = $root.palexy.sherlock.v1.IcaStandardCountingDataRow.verify(message.rows[i]);
                                if (error)
                                    return "rows." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an IcaStandardCountingData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.IcaStandardCountingData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.IcaStandardCountingData} IcaStandardCountingData
                     */
                    IcaStandardCountingData.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.IcaStandardCountingData)
                            return object;
                        var message = new $root.palexy.sherlock.v1.IcaStandardCountingData();
                        if (object.rows) {
                            if (!Array.isArray(object.rows))
                                throw TypeError(".palexy.sherlock.v1.IcaStandardCountingData.rows: array expected");
                            message.rows = [];
                            for (var i = 0; i < object.rows.length; ++i) {
                                if (typeof object.rows[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.IcaStandardCountingData.rows: object expected");
                                message.rows[i] = $root.palexy.sherlock.v1.IcaStandardCountingDataRow.fromObject(object.rows[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an IcaStandardCountingData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.IcaStandardCountingData
                     * @static
                     * @param {palexy.sherlock.v1.IcaStandardCountingData} message IcaStandardCountingData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IcaStandardCountingData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.rows = [];
                        if (message.rows && message.rows.length) {
                            object.rows = [];
                            for (var j = 0; j < message.rows.length; ++j)
                                object.rows[j] = $root.palexy.sherlock.v1.IcaStandardCountingDataRow.toObject(message.rows[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this IcaStandardCountingData to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.IcaStandardCountingData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IcaStandardCountingData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return IcaStandardCountingData;
                })();
    
                v1.IcaStandardCountingDataRow = (function() {
    
                    /**
                     * Properties of an IcaStandardCountingDataRow.
                     * @memberof palexy.sherlock.v1
                     * @interface IIcaStandardCountingDataRow
                     * @property {string|null} [visitorId] IcaStandardCountingDataRow visitorId
                     * @property {palexy.sherlock.v1.IcaStandardCountingDataRow.Gender|null} [gender] IcaStandardCountingDataRow gender
                     * @property {palexy.streaming.v1.AgeGroup|null} [ageGroup] IcaStandardCountingDataRow ageGroup
                     * @property {string|null} [startTime] IcaStandardCountingDataRow startTime
                     * @property {string|null} [endTime] IcaStandardCountingDataRow endTime
                     * @property {palexy.sherlock.v1.IcaStandardCountingDataRow.PurchaseType|null} [purchaseType] IcaStandardCountingDataRow purchaseType
                     * @property {boolean|null} [hasInteraction] IcaStandardCountingDataRow hasInteraction
                     * @property {boolean|null} [isStaff] IcaStandardCountingDataRow isStaff
                     * @property {string|null} [groupId] IcaStandardCountingDataRow groupId
                     * @property {string|null} [firstInteractionTime] IcaStandardCountingDataRow firstInteractionTime
                     * @property {string|null} [firstGreetingTime] IcaStandardCountingDataRow firstGreetingTime
                     */
    
                    /**
                     * Constructs a new IcaStandardCountingDataRow.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an IcaStandardCountingDataRow.
                     * @implements IIcaStandardCountingDataRow
                     * @constructor
                     * @param {palexy.sherlock.v1.IIcaStandardCountingDataRow=} [properties] Properties to set
                     */
                    function IcaStandardCountingDataRow(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * IcaStandardCountingDataRow visitorId.
                     * @member {string} visitorId
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     */
                    IcaStandardCountingDataRow.prototype.visitorId = "";
    
                    /**
                     * IcaStandardCountingDataRow gender.
                     * @member {palexy.sherlock.v1.IcaStandardCountingDataRow.Gender} gender
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     */
                    IcaStandardCountingDataRow.prototype.gender = 0;
    
                    /**
                     * IcaStandardCountingDataRow ageGroup.
                     * @member {palexy.streaming.v1.AgeGroup} ageGroup
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     */
                    IcaStandardCountingDataRow.prototype.ageGroup = 0;
    
                    /**
                     * IcaStandardCountingDataRow startTime.
                     * @member {string} startTime
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     */
                    IcaStandardCountingDataRow.prototype.startTime = "";
    
                    /**
                     * IcaStandardCountingDataRow endTime.
                     * @member {string} endTime
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     */
                    IcaStandardCountingDataRow.prototype.endTime = "";
    
                    /**
                     * IcaStandardCountingDataRow purchaseType.
                     * @member {palexy.sherlock.v1.IcaStandardCountingDataRow.PurchaseType} purchaseType
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     */
                    IcaStandardCountingDataRow.prototype.purchaseType = 0;
    
                    /**
                     * IcaStandardCountingDataRow hasInteraction.
                     * @member {boolean} hasInteraction
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     */
                    IcaStandardCountingDataRow.prototype.hasInteraction = false;
    
                    /**
                     * IcaStandardCountingDataRow isStaff.
                     * @member {boolean} isStaff
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     */
                    IcaStandardCountingDataRow.prototype.isStaff = false;
    
                    /**
                     * IcaStandardCountingDataRow groupId.
                     * @member {string} groupId
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     */
                    IcaStandardCountingDataRow.prototype.groupId = "";
    
                    /**
                     * IcaStandardCountingDataRow firstInteractionTime.
                     * @member {string} firstInteractionTime
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     */
                    IcaStandardCountingDataRow.prototype.firstInteractionTime = "";
    
                    /**
                     * IcaStandardCountingDataRow firstGreetingTime.
                     * @member {string} firstGreetingTime
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     */
                    IcaStandardCountingDataRow.prototype.firstGreetingTime = "";
    
                    /**
                     * Creates a new IcaStandardCountingDataRow instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IIcaStandardCountingDataRow=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.IcaStandardCountingDataRow} IcaStandardCountingDataRow instance
                     */
                    IcaStandardCountingDataRow.create = function create(properties) {
                        return new IcaStandardCountingDataRow(properties);
                    };
    
                    /**
                     * Encodes the specified IcaStandardCountingDataRow message. Does not implicitly {@link palexy.sherlock.v1.IcaStandardCountingDataRow.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IIcaStandardCountingDataRow} message IcaStandardCountingDataRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaStandardCountingDataRow.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.visitorId != null && Object.hasOwnProperty.call(message, "visitorId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.visitorId);
                        if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.gender);
                        if (message.ageGroup != null && Object.hasOwnProperty.call(message, "ageGroup"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ageGroup);
                        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.startTime);
                        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.endTime);
                        if (message.purchaseType != null && Object.hasOwnProperty.call(message, "purchaseType"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.purchaseType);
                        if (message.hasInteraction != null && Object.hasOwnProperty.call(message, "hasInteraction"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.hasInteraction);
                        if (message.isStaff != null && Object.hasOwnProperty.call(message, "isStaff"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isStaff);
                        if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.groupId);
                        if (message.firstInteractionTime != null && Object.hasOwnProperty.call(message, "firstInteractionTime"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.firstInteractionTime);
                        if (message.firstGreetingTime != null && Object.hasOwnProperty.call(message, "firstGreetingTime"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.firstGreetingTime);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified IcaStandardCountingDataRow message, length delimited. Does not implicitly {@link palexy.sherlock.v1.IcaStandardCountingDataRow.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IIcaStandardCountingDataRow} message IcaStandardCountingDataRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaStandardCountingDataRow.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an IcaStandardCountingDataRow message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.IcaStandardCountingDataRow} IcaStandardCountingDataRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaStandardCountingDataRow.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.IcaStandardCountingDataRow();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.visitorId = reader.string();
                                break;
                            case 2:
                                message.gender = reader.int32();
                                break;
                            case 3:
                                message.ageGroup = reader.int32();
                                break;
                            case 4:
                                message.startTime = reader.string();
                                break;
                            case 5:
                                message.endTime = reader.string();
                                break;
                            case 6:
                                message.purchaseType = reader.int32();
                                break;
                            case 7:
                                message.hasInteraction = reader.bool();
                                break;
                            case 8:
                                message.isStaff = reader.bool();
                                break;
                            case 9:
                                message.groupId = reader.string();
                                break;
                            case 10:
                                message.firstInteractionTime = reader.string();
                                break;
                            case 11:
                                message.firstGreetingTime = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an IcaStandardCountingDataRow message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.IcaStandardCountingDataRow} IcaStandardCountingDataRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaStandardCountingDataRow.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an IcaStandardCountingDataRow message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    IcaStandardCountingDataRow.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.visitorId != null && message.hasOwnProperty("visitorId"))
                            if (!$util.isString(message.visitorId))
                                return "visitorId: string expected";
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            switch (message.gender) {
                            default:
                                return "gender: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.ageGroup != null && message.hasOwnProperty("ageGroup"))
                            switch (message.ageGroup) {
                            default:
                                return "ageGroup: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            if (!$util.isString(message.startTime))
                                return "startTime: string expected";
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            if (!$util.isString(message.endTime))
                                return "endTime: string expected";
                        if (message.purchaseType != null && message.hasOwnProperty("purchaseType"))
                            switch (message.purchaseType) {
                            default:
                                return "purchaseType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.hasInteraction != null && message.hasOwnProperty("hasInteraction"))
                            if (typeof message.hasInteraction !== "boolean")
                                return "hasInteraction: boolean expected";
                        if (message.isStaff != null && message.hasOwnProperty("isStaff"))
                            if (typeof message.isStaff !== "boolean")
                                return "isStaff: boolean expected";
                        if (message.groupId != null && message.hasOwnProperty("groupId"))
                            if (!$util.isString(message.groupId))
                                return "groupId: string expected";
                        if (message.firstInteractionTime != null && message.hasOwnProperty("firstInteractionTime"))
                            if (!$util.isString(message.firstInteractionTime))
                                return "firstInteractionTime: string expected";
                        if (message.firstGreetingTime != null && message.hasOwnProperty("firstGreetingTime"))
                            if (!$util.isString(message.firstGreetingTime))
                                return "firstGreetingTime: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an IcaStandardCountingDataRow message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.IcaStandardCountingDataRow} IcaStandardCountingDataRow
                     */
                    IcaStandardCountingDataRow.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.IcaStandardCountingDataRow)
                            return object;
                        var message = new $root.palexy.sherlock.v1.IcaStandardCountingDataRow();
                        if (object.visitorId != null)
                            message.visitorId = String(object.visitorId);
                        switch (object.gender) {
                        case "U":
                        case 0:
                            message.gender = 0;
                            break;
                        case "F":
                        case 1:
                            message.gender = 1;
                            break;
                        case "M":
                        case 2:
                            message.gender = 2;
                            break;
                        }
                        switch (object.ageGroup) {
                        case "UNKNOWN_AGE_GROUP":
                        case 0:
                            message.ageGroup = 0;
                            break;
                        case "G_LESS_THAN_12":
                        case 1:
                            message.ageGroup = 1;
                            break;
                        case "G_12_20":
                        case 2:
                            message.ageGroup = 2;
                            break;
                        case "G_20_30":
                        case 3:
                            message.ageGroup = 3;
                            break;
                        case "G_30_40":
                        case 4:
                            message.ageGroup = 4;
                            break;
                        case "G_GREATER_THAN_40":
                        case 5:
                            message.ageGroup = 5;
                            break;
                        }
                        if (object.startTime != null)
                            message.startTime = String(object.startTime);
                        if (object.endTime != null)
                            message.endTime = String(object.endTime);
                        switch (object.purchaseType) {
                        case "PURCHASE_TYPE_UNKNOWN":
                        case 0:
                            message.purchaseType = 0;
                            break;
                        case "HAS_PURCHASE":
                        case 1:
                            message.purchaseType = 1;
                            break;
                        case "NO_PURCHASE":
                        case 2:
                            message.purchaseType = 2;
                            break;
                        case "GROUP_PURCHASE":
                        case 3:
                            message.purchaseType = 3;
                            break;
                        }
                        if (object.hasInteraction != null)
                            message.hasInteraction = Boolean(object.hasInteraction);
                        if (object.isStaff != null)
                            message.isStaff = Boolean(object.isStaff);
                        if (object.groupId != null)
                            message.groupId = String(object.groupId);
                        if (object.firstInteractionTime != null)
                            message.firstInteractionTime = String(object.firstInteractionTime);
                        if (object.firstGreetingTime != null)
                            message.firstGreetingTime = String(object.firstGreetingTime);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an IcaStandardCountingDataRow message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IcaStandardCountingDataRow} message IcaStandardCountingDataRow
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IcaStandardCountingDataRow.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.visitorId = "";
                            object.gender = options.enums === String ? "U" : 0;
                            object.ageGroup = options.enums === String ? "UNKNOWN_AGE_GROUP" : 0;
                            object.startTime = "";
                            object.endTime = "";
                            object.purchaseType = options.enums === String ? "PURCHASE_TYPE_UNKNOWN" : 0;
                            object.hasInteraction = false;
                            object.isStaff = false;
                            object.groupId = "";
                            object.firstInteractionTime = "";
                            object.firstGreetingTime = "";
                        }
                        if (message.visitorId != null && message.hasOwnProperty("visitorId"))
                            object.visitorId = message.visitorId;
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            object.gender = options.enums === String ? $root.palexy.sherlock.v1.IcaStandardCountingDataRow.Gender[message.gender] : message.gender;
                        if (message.ageGroup != null && message.hasOwnProperty("ageGroup"))
                            object.ageGroup = options.enums === String ? $root.palexy.streaming.v1.AgeGroup[message.ageGroup] : message.ageGroup;
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            object.startTime = message.startTime;
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            object.endTime = message.endTime;
                        if (message.purchaseType != null && message.hasOwnProperty("purchaseType"))
                            object.purchaseType = options.enums === String ? $root.palexy.sherlock.v1.IcaStandardCountingDataRow.PurchaseType[message.purchaseType] : message.purchaseType;
                        if (message.hasInteraction != null && message.hasOwnProperty("hasInteraction"))
                            object.hasInteraction = message.hasInteraction;
                        if (message.isStaff != null && message.hasOwnProperty("isStaff"))
                            object.isStaff = message.isStaff;
                        if (message.groupId != null && message.hasOwnProperty("groupId"))
                            object.groupId = message.groupId;
                        if (message.firstInteractionTime != null && message.hasOwnProperty("firstInteractionTime"))
                            object.firstInteractionTime = message.firstInteractionTime;
                        if (message.firstGreetingTime != null && message.hasOwnProperty("firstGreetingTime"))
                            object.firstGreetingTime = message.firstGreetingTime;
                        return object;
                    };
    
                    /**
                     * Converts this IcaStandardCountingDataRow to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.IcaStandardCountingDataRow
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IcaStandardCountingDataRow.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gender enum.
                     * @name palexy.sherlock.v1.IcaStandardCountingDataRow.Gender
                     * @enum {number}
                     * @property {number} U=0 U value
                     * @property {number} F=1 F value
                     * @property {number} M=2 M value
                     */
                    IcaStandardCountingDataRow.Gender = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "U"] = 0;
                        values[valuesById[1] = "F"] = 1;
                        values[valuesById[2] = "M"] = 2;
                        return values;
                    })();
    
                    /**
                     * PurchaseType enum.
                     * @name palexy.sherlock.v1.IcaStandardCountingDataRow.PurchaseType
                     * @enum {number}
                     * @property {number} PURCHASE_TYPE_UNKNOWN=0 PURCHASE_TYPE_UNKNOWN value
                     * @property {number} HAS_PURCHASE=1 HAS_PURCHASE value
                     * @property {number} NO_PURCHASE=2 NO_PURCHASE value
                     * @property {number} GROUP_PURCHASE=3 GROUP_PURCHASE value
                     */
                    IcaStandardCountingDataRow.PurchaseType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "PURCHASE_TYPE_UNKNOWN"] = 0;
                        values[valuesById[1] = "HAS_PURCHASE"] = 1;
                        values[valuesById[2] = "NO_PURCHASE"] = 2;
                        values[valuesById[3] = "GROUP_PURCHASE"] = 3;
                        return values;
                    })();
    
                    return IcaStandardCountingDataRow;
                })();
    
                v1.IcaCustomerJourneyData = (function() {
    
                    /**
                     * Properties of an IcaCustomerJourneyData.
                     * @memberof palexy.sherlock.v1
                     * @interface IIcaCustomerJourneyData
                     * @property {Array.<palexy.sherlock.v1.IIcaCustomerJourneyDataRow>|null} [rows] IcaCustomerJourneyData rows
                     * @property {Array.<string>|null} [sections] IcaCustomerJourneyData sections
                     */
    
                    /**
                     * Constructs a new IcaCustomerJourneyData.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an IcaCustomerJourneyData.
                     * @implements IIcaCustomerJourneyData
                     * @constructor
                     * @param {palexy.sherlock.v1.IIcaCustomerJourneyData=} [properties] Properties to set
                     */
                    function IcaCustomerJourneyData(properties) {
                        this.rows = [];
                        this.sections = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * IcaCustomerJourneyData rows.
                     * @member {Array.<palexy.sherlock.v1.IIcaCustomerJourneyDataRow>} rows
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyData
                     * @instance
                     */
                    IcaCustomerJourneyData.prototype.rows = $util.emptyArray;
    
                    /**
                     * IcaCustomerJourneyData sections.
                     * @member {Array.<string>} sections
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyData
                     * @instance
                     */
                    IcaCustomerJourneyData.prototype.sections = $util.emptyArray;
    
                    /**
                     * Creates a new IcaCustomerJourneyData instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyData
                     * @static
                     * @param {palexy.sherlock.v1.IIcaCustomerJourneyData=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.IcaCustomerJourneyData} IcaCustomerJourneyData instance
                     */
                    IcaCustomerJourneyData.create = function create(properties) {
                        return new IcaCustomerJourneyData(properties);
                    };
    
                    /**
                     * Encodes the specified IcaCustomerJourneyData message. Does not implicitly {@link palexy.sherlock.v1.IcaCustomerJourneyData.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyData
                     * @static
                     * @param {palexy.sherlock.v1.IIcaCustomerJourneyData} message IcaCustomerJourneyData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaCustomerJourneyData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rows != null && message.rows.length)
                            for (var i = 0; i < message.rows.length; ++i)
                                $root.palexy.sherlock.v1.IcaCustomerJourneyDataRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.sections != null && message.sections.length)
                            for (var i = 0; i < message.sections.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sections[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified IcaCustomerJourneyData message, length delimited. Does not implicitly {@link palexy.sherlock.v1.IcaCustomerJourneyData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyData
                     * @static
                     * @param {palexy.sherlock.v1.IIcaCustomerJourneyData} message IcaCustomerJourneyData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaCustomerJourneyData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an IcaCustomerJourneyData message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.IcaCustomerJourneyData} IcaCustomerJourneyData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaCustomerJourneyData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.IcaCustomerJourneyData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.rows && message.rows.length))
                                    message.rows = [];
                                message.rows.push($root.palexy.sherlock.v1.IcaCustomerJourneyDataRow.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.sections && message.sections.length))
                                    message.sections = [];
                                message.sections.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an IcaCustomerJourneyData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.IcaCustomerJourneyData} IcaCustomerJourneyData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaCustomerJourneyData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an IcaCustomerJourneyData message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    IcaCustomerJourneyData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rows != null && message.hasOwnProperty("rows")) {
                            if (!Array.isArray(message.rows))
                                return "rows: array expected";
                            for (var i = 0; i < message.rows.length; ++i) {
                                var error = $root.palexy.sherlock.v1.IcaCustomerJourneyDataRow.verify(message.rows[i]);
                                if (error)
                                    return "rows." + error;
                            }
                        }
                        if (message.sections != null && message.hasOwnProperty("sections")) {
                            if (!Array.isArray(message.sections))
                                return "sections: array expected";
                            for (var i = 0; i < message.sections.length; ++i)
                                if (!$util.isString(message.sections[i]))
                                    return "sections: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates an IcaCustomerJourneyData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.IcaCustomerJourneyData} IcaCustomerJourneyData
                     */
                    IcaCustomerJourneyData.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.IcaCustomerJourneyData)
                            return object;
                        var message = new $root.palexy.sherlock.v1.IcaCustomerJourneyData();
                        if (object.rows) {
                            if (!Array.isArray(object.rows))
                                throw TypeError(".palexy.sherlock.v1.IcaCustomerJourneyData.rows: array expected");
                            message.rows = [];
                            for (var i = 0; i < object.rows.length; ++i) {
                                if (typeof object.rows[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.IcaCustomerJourneyData.rows: object expected");
                                message.rows[i] = $root.palexy.sherlock.v1.IcaCustomerJourneyDataRow.fromObject(object.rows[i]);
                            }
                        }
                        if (object.sections) {
                            if (!Array.isArray(object.sections))
                                throw TypeError(".palexy.sherlock.v1.IcaCustomerJourneyData.sections: array expected");
                            message.sections = [];
                            for (var i = 0; i < object.sections.length; ++i)
                                message.sections[i] = String(object.sections[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an IcaCustomerJourneyData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyData
                     * @static
                     * @param {palexy.sherlock.v1.IcaCustomerJourneyData} message IcaCustomerJourneyData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IcaCustomerJourneyData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.rows = [];
                            object.sections = [];
                        }
                        if (message.rows && message.rows.length) {
                            object.rows = [];
                            for (var j = 0; j < message.rows.length; ++j)
                                object.rows[j] = $root.palexy.sherlock.v1.IcaCustomerJourneyDataRow.toObject(message.rows[j], options);
                        }
                        if (message.sections && message.sections.length) {
                            object.sections = [];
                            for (var j = 0; j < message.sections.length; ++j)
                                object.sections[j] = message.sections[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this IcaCustomerJourneyData to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IcaCustomerJourneyData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return IcaCustomerJourneyData;
                })();
    
                v1.IcaCustomerJourneyDataRow = (function() {
    
                    /**
                     * Properties of an IcaCustomerJourneyDataRow.
                     * @memberof palexy.sherlock.v1
                     * @interface IIcaCustomerJourneyDataRow
                     * @property {string|null} [visitorId] IcaCustomerJourneyDataRow visitorId
                     * @property {string|null} [section] IcaCustomerJourneyDataRow section
                     * @property {string|null} [startTime] IcaCustomerJourneyDataRow startTime
                     * @property {string|null} [endTime] IcaCustomerJourneyDataRow endTime
                     * @property {boolean|null} [interested] IcaCustomerJourneyDataRow interested
                     * @property {boolean|null} [isStaff] IcaCustomerJourneyDataRow isStaff
                     */
    
                    /**
                     * Constructs a new IcaCustomerJourneyDataRow.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an IcaCustomerJourneyDataRow.
                     * @implements IIcaCustomerJourneyDataRow
                     * @constructor
                     * @param {palexy.sherlock.v1.IIcaCustomerJourneyDataRow=} [properties] Properties to set
                     */
                    function IcaCustomerJourneyDataRow(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * IcaCustomerJourneyDataRow visitorId.
                     * @member {string} visitorId
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @instance
                     */
                    IcaCustomerJourneyDataRow.prototype.visitorId = "";
    
                    /**
                     * IcaCustomerJourneyDataRow section.
                     * @member {string} section
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @instance
                     */
                    IcaCustomerJourneyDataRow.prototype.section = "";
    
                    /**
                     * IcaCustomerJourneyDataRow startTime.
                     * @member {string} startTime
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @instance
                     */
                    IcaCustomerJourneyDataRow.prototype.startTime = "";
    
                    /**
                     * IcaCustomerJourneyDataRow endTime.
                     * @member {string} endTime
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @instance
                     */
                    IcaCustomerJourneyDataRow.prototype.endTime = "";
    
                    /**
                     * IcaCustomerJourneyDataRow interested.
                     * @member {boolean} interested
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @instance
                     */
                    IcaCustomerJourneyDataRow.prototype.interested = false;
    
                    /**
                     * IcaCustomerJourneyDataRow isStaff.
                     * @member {boolean} isStaff
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @instance
                     */
                    IcaCustomerJourneyDataRow.prototype.isStaff = false;
    
                    /**
                     * Creates a new IcaCustomerJourneyDataRow instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IIcaCustomerJourneyDataRow=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.IcaCustomerJourneyDataRow} IcaCustomerJourneyDataRow instance
                     */
                    IcaCustomerJourneyDataRow.create = function create(properties) {
                        return new IcaCustomerJourneyDataRow(properties);
                    };
    
                    /**
                     * Encodes the specified IcaCustomerJourneyDataRow message. Does not implicitly {@link palexy.sherlock.v1.IcaCustomerJourneyDataRow.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IIcaCustomerJourneyDataRow} message IcaCustomerJourneyDataRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaCustomerJourneyDataRow.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.visitorId != null && Object.hasOwnProperty.call(message, "visitorId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.visitorId);
                        if (message.section != null && Object.hasOwnProperty.call(message, "section"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.section);
                        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.startTime);
                        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.endTime);
                        if (message.interested != null && Object.hasOwnProperty.call(message, "interested"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.interested);
                        if (message.isStaff != null && Object.hasOwnProperty.call(message, "isStaff"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isStaff);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified IcaCustomerJourneyDataRow message, length delimited. Does not implicitly {@link palexy.sherlock.v1.IcaCustomerJourneyDataRow.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IIcaCustomerJourneyDataRow} message IcaCustomerJourneyDataRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaCustomerJourneyDataRow.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an IcaCustomerJourneyDataRow message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.IcaCustomerJourneyDataRow} IcaCustomerJourneyDataRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaCustomerJourneyDataRow.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.IcaCustomerJourneyDataRow();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.visitorId = reader.string();
                                break;
                            case 2:
                                message.section = reader.string();
                                break;
                            case 3:
                                message.startTime = reader.string();
                                break;
                            case 4:
                                message.endTime = reader.string();
                                break;
                            case 5:
                                message.interested = reader.bool();
                                break;
                            case 6:
                                message.isStaff = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an IcaCustomerJourneyDataRow message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.IcaCustomerJourneyDataRow} IcaCustomerJourneyDataRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaCustomerJourneyDataRow.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an IcaCustomerJourneyDataRow message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    IcaCustomerJourneyDataRow.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.visitorId != null && message.hasOwnProperty("visitorId"))
                            if (!$util.isString(message.visitorId))
                                return "visitorId: string expected";
                        if (message.section != null && message.hasOwnProperty("section"))
                            if (!$util.isString(message.section))
                                return "section: string expected";
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            if (!$util.isString(message.startTime))
                                return "startTime: string expected";
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            if (!$util.isString(message.endTime))
                                return "endTime: string expected";
                        if (message.interested != null && message.hasOwnProperty("interested"))
                            if (typeof message.interested !== "boolean")
                                return "interested: boolean expected";
                        if (message.isStaff != null && message.hasOwnProperty("isStaff"))
                            if (typeof message.isStaff !== "boolean")
                                return "isStaff: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates an IcaCustomerJourneyDataRow message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.IcaCustomerJourneyDataRow} IcaCustomerJourneyDataRow
                     */
                    IcaCustomerJourneyDataRow.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.IcaCustomerJourneyDataRow)
                            return object;
                        var message = new $root.palexy.sherlock.v1.IcaCustomerJourneyDataRow();
                        if (object.visitorId != null)
                            message.visitorId = String(object.visitorId);
                        if (object.section != null)
                            message.section = String(object.section);
                        if (object.startTime != null)
                            message.startTime = String(object.startTime);
                        if (object.endTime != null)
                            message.endTime = String(object.endTime);
                        if (object.interested != null)
                            message.interested = Boolean(object.interested);
                        if (object.isStaff != null)
                            message.isStaff = Boolean(object.isStaff);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an IcaCustomerJourneyDataRow message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IcaCustomerJourneyDataRow} message IcaCustomerJourneyDataRow
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IcaCustomerJourneyDataRow.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.visitorId = "";
                            object.section = "";
                            object.startTime = "";
                            object.endTime = "";
                            object.interested = false;
                            object.isStaff = false;
                        }
                        if (message.visitorId != null && message.hasOwnProperty("visitorId"))
                            object.visitorId = message.visitorId;
                        if (message.section != null && message.hasOwnProperty("section"))
                            object.section = message.section;
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            object.startTime = message.startTime;
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            object.endTime = message.endTime;
                        if (message.interested != null && message.hasOwnProperty("interested"))
                            object.interested = message.interested;
                        if (message.isStaff != null && message.hasOwnProperty("isStaff"))
                            object.isStaff = message.isStaff;
                        return object;
                    };
    
                    /**
                     * Converts this IcaCustomerJourneyDataRow to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.IcaCustomerJourneyDataRow
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IcaCustomerJourneyDataRow.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return IcaCustomerJourneyDataRow;
                })();
    
                v1.IcaEntranceCountingData = (function() {
    
                    /**
                     * Properties of an IcaEntranceCountingData.
                     * @memberof palexy.sherlock.v1
                     * @interface IIcaEntranceCountingData
                     * @property {Array.<palexy.sherlock.v1.IIcaEntranceCountingDataRow>|null} [rows] IcaEntranceCountingData rows
                     */
    
                    /**
                     * Constructs a new IcaEntranceCountingData.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an IcaEntranceCountingData.
                     * @implements IIcaEntranceCountingData
                     * @constructor
                     * @param {palexy.sherlock.v1.IIcaEntranceCountingData=} [properties] Properties to set
                     */
                    function IcaEntranceCountingData(properties) {
                        this.rows = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * IcaEntranceCountingData rows.
                     * @member {Array.<palexy.sherlock.v1.IIcaEntranceCountingDataRow>} rows
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingData
                     * @instance
                     */
                    IcaEntranceCountingData.prototype.rows = $util.emptyArray;
    
                    /**
                     * Creates a new IcaEntranceCountingData instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingData
                     * @static
                     * @param {palexy.sherlock.v1.IIcaEntranceCountingData=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.IcaEntranceCountingData} IcaEntranceCountingData instance
                     */
                    IcaEntranceCountingData.create = function create(properties) {
                        return new IcaEntranceCountingData(properties);
                    };
    
                    /**
                     * Encodes the specified IcaEntranceCountingData message. Does not implicitly {@link palexy.sherlock.v1.IcaEntranceCountingData.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingData
                     * @static
                     * @param {palexy.sherlock.v1.IIcaEntranceCountingData} message IcaEntranceCountingData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaEntranceCountingData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rows != null && message.rows.length)
                            for (var i = 0; i < message.rows.length; ++i)
                                $root.palexy.sherlock.v1.IcaEntranceCountingDataRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified IcaEntranceCountingData message, length delimited. Does not implicitly {@link palexy.sherlock.v1.IcaEntranceCountingData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingData
                     * @static
                     * @param {palexy.sherlock.v1.IIcaEntranceCountingData} message IcaEntranceCountingData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaEntranceCountingData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an IcaEntranceCountingData message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.IcaEntranceCountingData} IcaEntranceCountingData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaEntranceCountingData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.IcaEntranceCountingData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.rows && message.rows.length))
                                    message.rows = [];
                                message.rows.push($root.palexy.sherlock.v1.IcaEntranceCountingDataRow.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an IcaEntranceCountingData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.IcaEntranceCountingData} IcaEntranceCountingData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaEntranceCountingData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an IcaEntranceCountingData message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    IcaEntranceCountingData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rows != null && message.hasOwnProperty("rows")) {
                            if (!Array.isArray(message.rows))
                                return "rows: array expected";
                            for (var i = 0; i < message.rows.length; ++i) {
                                var error = $root.palexy.sherlock.v1.IcaEntranceCountingDataRow.verify(message.rows[i]);
                                if (error)
                                    return "rows." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an IcaEntranceCountingData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.IcaEntranceCountingData} IcaEntranceCountingData
                     */
                    IcaEntranceCountingData.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.IcaEntranceCountingData)
                            return object;
                        var message = new $root.palexy.sherlock.v1.IcaEntranceCountingData();
                        if (object.rows) {
                            if (!Array.isArray(object.rows))
                                throw TypeError(".palexy.sherlock.v1.IcaEntranceCountingData.rows: array expected");
                            message.rows = [];
                            for (var i = 0; i < object.rows.length; ++i) {
                                if (typeof object.rows[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.IcaEntranceCountingData.rows: object expected");
                                message.rows[i] = $root.palexy.sherlock.v1.IcaEntranceCountingDataRow.fromObject(object.rows[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an IcaEntranceCountingData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingData
                     * @static
                     * @param {palexy.sherlock.v1.IcaEntranceCountingData} message IcaEntranceCountingData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IcaEntranceCountingData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.rows = [];
                        if (message.rows && message.rows.length) {
                            object.rows = [];
                            for (var j = 0; j < message.rows.length; ++j)
                                object.rows[j] = $root.palexy.sherlock.v1.IcaEntranceCountingDataRow.toObject(message.rows[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this IcaEntranceCountingData to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IcaEntranceCountingData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return IcaEntranceCountingData;
                })();
    
                v1.IcaEntranceCountingDataRow = (function() {
    
                    /**
                     * Properties of an IcaEntranceCountingDataRow.
                     * @memberof palexy.sherlock.v1
                     * @interface IIcaEntranceCountingDataRow
                     * @property {string|null} [cameraId] IcaEntranceCountingDataRow cameraId
                     * @property {number|null} [hour] IcaEntranceCountingDataRow hour
                     * @property {number|null} [numVisitors] IcaEntranceCountingDataRow numVisitors
                     */
    
                    /**
                     * Constructs a new IcaEntranceCountingDataRow.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an IcaEntranceCountingDataRow.
                     * @implements IIcaEntranceCountingDataRow
                     * @constructor
                     * @param {palexy.sherlock.v1.IIcaEntranceCountingDataRow=} [properties] Properties to set
                     */
                    function IcaEntranceCountingDataRow(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * IcaEntranceCountingDataRow cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @instance
                     */
                    IcaEntranceCountingDataRow.prototype.cameraId = "";
    
                    /**
                     * IcaEntranceCountingDataRow hour.
                     * @member {number} hour
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @instance
                     */
                    IcaEntranceCountingDataRow.prototype.hour = 0;
    
                    /**
                     * IcaEntranceCountingDataRow numVisitors.
                     * @member {number} numVisitors
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @instance
                     */
                    IcaEntranceCountingDataRow.prototype.numVisitors = 0;
    
                    /**
                     * Creates a new IcaEntranceCountingDataRow instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IIcaEntranceCountingDataRow=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.IcaEntranceCountingDataRow} IcaEntranceCountingDataRow instance
                     */
                    IcaEntranceCountingDataRow.create = function create(properties) {
                        return new IcaEntranceCountingDataRow(properties);
                    };
    
                    /**
                     * Encodes the specified IcaEntranceCountingDataRow message. Does not implicitly {@link palexy.sherlock.v1.IcaEntranceCountingDataRow.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IIcaEntranceCountingDataRow} message IcaEntranceCountingDataRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaEntranceCountingDataRow.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cameraId);
                        if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.hour);
                        if (message.numVisitors != null && Object.hasOwnProperty.call(message, "numVisitors"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.numVisitors);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified IcaEntranceCountingDataRow message, length delimited. Does not implicitly {@link palexy.sherlock.v1.IcaEntranceCountingDataRow.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IIcaEntranceCountingDataRow} message IcaEntranceCountingDataRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IcaEntranceCountingDataRow.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an IcaEntranceCountingDataRow message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.IcaEntranceCountingDataRow} IcaEntranceCountingDataRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaEntranceCountingDataRow.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.IcaEntranceCountingDataRow();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraId = reader.string();
                                break;
                            case 2:
                                message.hour = reader.int32();
                                break;
                            case 3:
                                message.numVisitors = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an IcaEntranceCountingDataRow message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.IcaEntranceCountingDataRow} IcaEntranceCountingDataRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IcaEntranceCountingDataRow.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an IcaEntranceCountingDataRow message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    IcaEntranceCountingDataRow.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.hour != null && message.hasOwnProperty("hour"))
                            if (!$util.isInteger(message.hour))
                                return "hour: integer expected";
                        if (message.numVisitors != null && message.hasOwnProperty("numVisitors"))
                            if (!$util.isInteger(message.numVisitors))
                                return "numVisitors: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an IcaEntranceCountingDataRow message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.IcaEntranceCountingDataRow} IcaEntranceCountingDataRow
                     */
                    IcaEntranceCountingDataRow.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.IcaEntranceCountingDataRow)
                            return object;
                        var message = new $root.palexy.sherlock.v1.IcaEntranceCountingDataRow();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.hour != null)
                            message.hour = object.hour | 0;
                        if (object.numVisitors != null)
                            message.numVisitors = object.numVisitors | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an IcaEntranceCountingDataRow message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @static
                     * @param {palexy.sherlock.v1.IcaEntranceCountingDataRow} message IcaEntranceCountingDataRow
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IcaEntranceCountingDataRow.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.cameraId = "";
                            object.hour = 0;
                            object.numVisitors = 0;
                        }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.hour != null && message.hasOwnProperty("hour"))
                            object.hour = message.hour;
                        if (message.numVisitors != null && message.hasOwnProperty("numVisitors"))
                            object.numVisitors = message.numVisitors;
                        return object;
                    };
    
                    /**
                     * Converts this IcaEntranceCountingDataRow to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.IcaEntranceCountingDataRow
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IcaEntranceCountingDataRow.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return IcaEntranceCountingDataRow;
                })();
    
                v1.ListQcFilesRequest = (function() {
    
                    /**
                     * Properties of a ListQcFilesRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IListQcFilesRequest
                     * @property {Array.<number>|null} [storeIds] ListQcFilesRequest storeIds
                     * @property {string|null} [startDate] ListQcFilesRequest startDate
                     * @property {string|null} [endDate] ListQcFilesRequest endDate
                     * @property {string|null} [pageToken] ListQcFilesRequest pageToken
                     * @property {number|null} [pageSize] ListQcFilesRequest pageSize
                     * @property {boolean|null} [excludeQcFileData] ListQcFilesRequest excludeQcFileData
                     */
    
                    /**
                     * Constructs a new ListQcFilesRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListQcFilesRequest.
                     * @implements IListQcFilesRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IListQcFilesRequest=} [properties] Properties to set
                     */
                    function ListQcFilesRequest(properties) {
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListQcFilesRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @instance
                     */
                    ListQcFilesRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * ListQcFilesRequest startDate.
                     * @member {string} startDate
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @instance
                     */
                    ListQcFilesRequest.prototype.startDate = "";
    
                    /**
                     * ListQcFilesRequest endDate.
                     * @member {string} endDate
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @instance
                     */
                    ListQcFilesRequest.prototype.endDate = "";
    
                    /**
                     * ListQcFilesRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @instance
                     */
                    ListQcFilesRequest.prototype.pageToken = "";
    
                    /**
                     * ListQcFilesRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @instance
                     */
                    ListQcFilesRequest.prototype.pageSize = 0;
    
                    /**
                     * ListQcFilesRequest excludeQcFileData.
                     * @member {boolean} excludeQcFileData
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @instance
                     */
                    ListQcFilesRequest.prototype.excludeQcFileData = false;
    
                    /**
                     * Creates a new ListQcFilesRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListQcFilesRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListQcFilesRequest} ListQcFilesRequest instance
                     */
                    ListQcFilesRequest.create = function create(properties) {
                        return new ListQcFilesRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListQcFilesRequest message. Does not implicitly {@link palexy.sherlock.v1.ListQcFilesRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListQcFilesRequest} message ListQcFilesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcFilesRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.startDate);
                        if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.endDate);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.pageToken);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.pageSize);
                        if (message.excludeQcFileData != null && Object.hasOwnProperty.call(message, "excludeQcFileData"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.excludeQcFileData);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListQcFilesRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListQcFilesRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListQcFilesRequest} message ListQcFilesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcFilesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListQcFilesRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListQcFilesRequest} ListQcFilesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcFilesRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListQcFilesRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 2:
                                message.startDate = reader.string();
                                break;
                            case 3:
                                message.endDate = reader.string();
                                break;
                            case 4:
                                message.pageToken = reader.string();
                                break;
                            case 5:
                                message.pageSize = reader.int32();
                                break;
                            case 6:
                                message.excludeQcFileData = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListQcFilesRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListQcFilesRequest} ListQcFilesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcFilesRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListQcFilesRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListQcFilesRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.excludeQcFileData != null && message.hasOwnProperty("excludeQcFileData"))
                            if (typeof message.excludeQcFileData !== "boolean")
                                return "excludeQcFileData: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListQcFilesRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListQcFilesRequest} ListQcFilesRequest
                     */
                    ListQcFilesRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListQcFilesRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListQcFilesRequest();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.sherlock.v1.ListQcFilesRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.excludeQcFileData != null)
                            message.excludeQcFileData = Boolean(object.excludeQcFileData);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListQcFilesRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @static
                     * @param {palexy.sherlock.v1.ListQcFilesRequest} message ListQcFilesRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListQcFilesRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeIds = [];
                        if (options.defaults) {
                            object.startDate = "";
                            object.endDate = "";
                            object.pageToken = "";
                            object.pageSize = 0;
                            object.excludeQcFileData = false;
                        }
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.excludeQcFileData != null && message.hasOwnProperty("excludeQcFileData"))
                            object.excludeQcFileData = message.excludeQcFileData;
                        return object;
                    };
    
                    /**
                     * Converts this ListQcFilesRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListQcFilesRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListQcFilesRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListQcFilesRequest;
                })();
    
                v1.ListQcFilesResponse = (function() {
    
                    /**
                     * Properties of a ListQcFilesResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IListQcFilesResponse
                     * @property {Array.<palexy.sherlock.v1.IQcFile>|null} [qcFiles] ListQcFilesResponse qcFiles
                     * @property {string|null} [nextPageToken] ListQcFilesResponse nextPageToken
                     * @property {number|null} [totalElements] ListQcFilesResponse totalElements
                     */
    
                    /**
                     * Constructs a new ListQcFilesResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListQcFilesResponse.
                     * @implements IListQcFilesResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IListQcFilesResponse=} [properties] Properties to set
                     */
                    function ListQcFilesResponse(properties) {
                        this.qcFiles = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListQcFilesResponse qcFiles.
                     * @member {Array.<palexy.sherlock.v1.IQcFile>} qcFiles
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @instance
                     */
                    ListQcFilesResponse.prototype.qcFiles = $util.emptyArray;
    
                    /**
                     * ListQcFilesResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @instance
                     */
                    ListQcFilesResponse.prototype.nextPageToken = "";
    
                    /**
                     * ListQcFilesResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @instance
                     */
                    ListQcFilesResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListQcFilesResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListQcFilesResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListQcFilesResponse} ListQcFilesResponse instance
                     */
                    ListQcFilesResponse.create = function create(properties) {
                        return new ListQcFilesResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListQcFilesResponse message. Does not implicitly {@link palexy.sherlock.v1.ListQcFilesResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListQcFilesResponse} message ListQcFilesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcFilesResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.qcFiles != null && message.qcFiles.length)
                            for (var i = 0; i < message.qcFiles.length; ++i)
                                $root.palexy.sherlock.v1.QcFile.encode(message.qcFiles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListQcFilesResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListQcFilesResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListQcFilesResponse} message ListQcFilesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcFilesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListQcFilesResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListQcFilesResponse} ListQcFilesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcFilesResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListQcFilesResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.qcFiles && message.qcFiles.length))
                                    message.qcFiles = [];
                                message.qcFiles.push($root.palexy.sherlock.v1.QcFile.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            case 3:
                                message.totalElements = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListQcFilesResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListQcFilesResponse} ListQcFilesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcFilesResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListQcFilesResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListQcFilesResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.qcFiles != null && message.hasOwnProperty("qcFiles")) {
                            if (!Array.isArray(message.qcFiles))
                                return "qcFiles: array expected";
                            for (var i = 0; i < message.qcFiles.length; ++i) {
                                var error = $root.palexy.sherlock.v1.QcFile.verify(message.qcFiles[i]);
                                if (error)
                                    return "qcFiles." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListQcFilesResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListQcFilesResponse} ListQcFilesResponse
                     */
                    ListQcFilesResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListQcFilesResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListQcFilesResponse();
                        if (object.qcFiles) {
                            if (!Array.isArray(object.qcFiles))
                                throw TypeError(".palexy.sherlock.v1.ListQcFilesResponse.qcFiles: array expected");
                            message.qcFiles = [];
                            for (var i = 0; i < object.qcFiles.length; ++i) {
                                if (typeof object.qcFiles[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ListQcFilesResponse.qcFiles: object expected");
                                message.qcFiles[i] = $root.palexy.sherlock.v1.QcFile.fromObject(object.qcFiles[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListQcFilesResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @static
                     * @param {palexy.sherlock.v1.ListQcFilesResponse} message ListQcFilesResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListQcFilesResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.qcFiles = [];
                        if (options.defaults) {
                            object.nextPageToken = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                        }
                        if (message.qcFiles && message.qcFiles.length) {
                            object.qcFiles = [];
                            for (var j = 0; j < message.qcFiles.length; ++j)
                                object.qcFiles[j] = $root.palexy.sherlock.v1.QcFile.toObject(message.qcFiles[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        return object;
                    };
    
                    /**
                     * Converts this ListQcFilesResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListQcFilesResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListQcFilesResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListQcFilesResponse;
                })();
    
                v1.GetQcFileRequest = (function() {
    
                    /**
                     * Properties of a GetQcFileRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IGetQcFileRequest
                     * @property {string|null} [name] GetQcFileRequest name
                     */
    
                    /**
                     * Constructs a new GetQcFileRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GetQcFileRequest.
                     * @implements IGetQcFileRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IGetQcFileRequest=} [properties] Properties to set
                     */
                    function GetQcFileRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetQcFileRequest name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.GetQcFileRequest
                     * @instance
                     */
                    GetQcFileRequest.prototype.name = "";
    
                    /**
                     * Creates a new GetQcFileRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GetQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGetQcFileRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GetQcFileRequest} GetQcFileRequest instance
                     */
                    GetQcFileRequest.create = function create(properties) {
                        return new GetQcFileRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetQcFileRequest message. Does not implicitly {@link palexy.sherlock.v1.GetQcFileRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GetQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGetQcFileRequest} message GetQcFileRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetQcFileRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetQcFileRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GetQcFileRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GetQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGetQcFileRequest} message GetQcFileRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetQcFileRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetQcFileRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GetQcFileRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GetQcFileRequest} GetQcFileRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetQcFileRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GetQcFileRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetQcFileRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GetQcFileRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GetQcFileRequest} GetQcFileRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetQcFileRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetQcFileRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GetQcFileRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetQcFileRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetQcFileRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GetQcFileRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GetQcFileRequest} GetQcFileRequest
                     */
                    GetQcFileRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GetQcFileRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GetQcFileRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetQcFileRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GetQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.GetQcFileRequest} message GetQcFileRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetQcFileRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this GetQcFileRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GetQcFileRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetQcFileRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetQcFileRequest;
                })();
    
                v1.GetQcFileResponse = (function() {
    
                    /**
                     * Properties of a GetQcFileResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IGetQcFileResponse
                     * @property {palexy.sherlock.v1.IQcFile|null} [qcFile] GetQcFileResponse qcFile
                     */
    
                    /**
                     * Constructs a new GetQcFileResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GetQcFileResponse.
                     * @implements IGetQcFileResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IGetQcFileResponse=} [properties] Properties to set
                     */
                    function GetQcFileResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetQcFileResponse qcFile.
                     * @member {palexy.sherlock.v1.IQcFile|null|undefined} qcFile
                     * @memberof palexy.sherlock.v1.GetQcFileResponse
                     * @instance
                     */
                    GetQcFileResponse.prototype.qcFile = null;
    
                    /**
                     * Creates a new GetQcFileResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GetQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGetQcFileResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GetQcFileResponse} GetQcFileResponse instance
                     */
                    GetQcFileResponse.create = function create(properties) {
                        return new GetQcFileResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetQcFileResponse message. Does not implicitly {@link palexy.sherlock.v1.GetQcFileResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GetQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGetQcFileResponse} message GetQcFileResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetQcFileResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.qcFile != null && Object.hasOwnProperty.call(message, "qcFile"))
                            $root.palexy.sherlock.v1.QcFile.encode(message.qcFile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetQcFileResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GetQcFileResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GetQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGetQcFileResponse} message GetQcFileResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetQcFileResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetQcFileResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GetQcFileResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GetQcFileResponse} GetQcFileResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetQcFileResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GetQcFileResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.qcFile = $root.palexy.sherlock.v1.QcFile.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetQcFileResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GetQcFileResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GetQcFileResponse} GetQcFileResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetQcFileResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetQcFileResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GetQcFileResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetQcFileResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.qcFile != null && message.hasOwnProperty("qcFile")) {
                            var error = $root.palexy.sherlock.v1.QcFile.verify(message.qcFile);
                            if (error)
                                return "qcFile." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetQcFileResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GetQcFileResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GetQcFileResponse} GetQcFileResponse
                     */
                    GetQcFileResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GetQcFileResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GetQcFileResponse();
                        if (object.qcFile != null) {
                            if (typeof object.qcFile !== "object")
                                throw TypeError(".palexy.sherlock.v1.GetQcFileResponse.qcFile: object expected");
                            message.qcFile = $root.palexy.sherlock.v1.QcFile.fromObject(object.qcFile);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetQcFileResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GetQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.GetQcFileResponse} message GetQcFileResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetQcFileResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.qcFile = null;
                        if (message.qcFile != null && message.hasOwnProperty("qcFile"))
                            object.qcFile = $root.palexy.sherlock.v1.QcFile.toObject(message.qcFile, options);
                        return object;
                    };
    
                    /**
                     * Converts this GetQcFileResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GetQcFileResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetQcFileResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetQcFileResponse;
                })();
    
                v1.DeleteQcFileRequest = (function() {
    
                    /**
                     * Properties of a DeleteQcFileRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IDeleteQcFileRequest
                     * @property {string|null} [name] DeleteQcFileRequest name
                     */
    
                    /**
                     * Constructs a new DeleteQcFileRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DeleteQcFileRequest.
                     * @implements IDeleteQcFileRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IDeleteQcFileRequest=} [properties] Properties to set
                     */
                    function DeleteQcFileRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteQcFileRequest name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.DeleteQcFileRequest
                     * @instance
                     */
                    DeleteQcFileRequest.prototype.name = "";
    
                    /**
                     * Creates a new DeleteQcFileRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DeleteQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcFileRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DeleteQcFileRequest} DeleteQcFileRequest instance
                     */
                    DeleteQcFileRequest.create = function create(properties) {
                        return new DeleteQcFileRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteQcFileRequest message. Does not implicitly {@link palexy.sherlock.v1.DeleteQcFileRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DeleteQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcFileRequest} message DeleteQcFileRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcFileRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteQcFileRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DeleteQcFileRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcFileRequest} message DeleteQcFileRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcFileRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteQcFileRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DeleteQcFileRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DeleteQcFileRequest} DeleteQcFileRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcFileRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DeleteQcFileRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteQcFileRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcFileRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DeleteQcFileRequest} DeleteQcFileRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcFileRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteQcFileRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DeleteQcFileRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteQcFileRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteQcFileRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DeleteQcFileRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DeleteQcFileRequest} DeleteQcFileRequest
                     */
                    DeleteQcFileRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DeleteQcFileRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.DeleteQcFileRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteQcFileRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DeleteQcFileRequest
                     * @static
                     * @param {palexy.sherlock.v1.DeleteQcFileRequest} message DeleteQcFileRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteQcFileRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteQcFileRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DeleteQcFileRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteQcFileRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteQcFileRequest;
                })();
    
                v1.DeleteQcFileResponse = (function() {
    
                    /**
                     * Properties of a DeleteQcFileResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IDeleteQcFileResponse
                     */
    
                    /**
                     * Constructs a new DeleteQcFileResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DeleteQcFileResponse.
                     * @implements IDeleteQcFileResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IDeleteQcFileResponse=} [properties] Properties to set
                     */
                    function DeleteQcFileResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new DeleteQcFileResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DeleteQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcFileResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DeleteQcFileResponse} DeleteQcFileResponse instance
                     */
                    DeleteQcFileResponse.create = function create(properties) {
                        return new DeleteQcFileResponse(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteQcFileResponse message. Does not implicitly {@link palexy.sherlock.v1.DeleteQcFileResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DeleteQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcFileResponse} message DeleteQcFileResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcFileResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteQcFileResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DeleteQcFileResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcFileResponse} message DeleteQcFileResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcFileResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteQcFileResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DeleteQcFileResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DeleteQcFileResponse} DeleteQcFileResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcFileResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DeleteQcFileResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteQcFileResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcFileResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DeleteQcFileResponse} DeleteQcFileResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcFileResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteQcFileResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DeleteQcFileResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteQcFileResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteQcFileResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DeleteQcFileResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DeleteQcFileResponse} DeleteQcFileResponse
                     */
                    DeleteQcFileResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DeleteQcFileResponse)
                            return object;
                        return new $root.palexy.sherlock.v1.DeleteQcFileResponse();
                    };
    
                    /**
                     * Creates a plain object from a DeleteQcFileResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DeleteQcFileResponse
                     * @static
                     * @param {palexy.sherlock.v1.DeleteQcFileResponse} message DeleteQcFileResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteQcFileResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this DeleteQcFileResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DeleteQcFileResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteQcFileResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteQcFileResponse;
                })();
    
                v1.AccuracyService = (function() {
    
                    /**
                     * Constructs a new AccuracyService service.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an AccuracyService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function AccuracyService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (AccuracyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AccuracyService;
    
                    /**
                     * Creates new AccuracyService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {AccuracyService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    AccuracyService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.AccuracyService#calculateAndSaveAccuracy}.
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @typedef CalculateAndSaveAccuracyCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CalculateAndSaveAccuracyResponse} [response] CalculateAndSaveAccuracyResponse
                     */
    
                    /**
                     * Calls CalculateAndSaveAccuracy.
                     * @function calculateAndSaveAccuracy
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @instance
                     * @param {palexy.sherlock.v1.ICalculateAndSaveAccuracyRequest} request CalculateAndSaveAccuracyRequest message or plain object
                     * @param {palexy.sherlock.v1.AccuracyService.CalculateAndSaveAccuracyCallback} callback Node-style callback called with the error, if any, and CalculateAndSaveAccuracyResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AccuracyService.prototype.calculateAndSaveAccuracy = function calculateAndSaveAccuracy(request, callback) {
                        return this.rpcCall(calculateAndSaveAccuracy, $root.palexy.sherlock.v1.CalculateAndSaveAccuracyRequest, $root.palexy.sherlock.v1.CalculateAndSaveAccuracyResponse, request, callback);
                    }, "name", { value: "CalculateAndSaveAccuracy" });
    
                    /**
                     * Calls CalculateAndSaveAccuracy.
                     * @function calculateAndSaveAccuracy
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @instance
                     * @param {palexy.sherlock.v1.ICalculateAndSaveAccuracyRequest} request CalculateAndSaveAccuracyRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CalculateAndSaveAccuracyResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.AccuracyService#batchCalculateAndSaveAccuracy}.
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @typedef BatchCalculateAndSaveAccuracyCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse} [response] BatchCalculateAndSaveAccuracyResponse
                     */
    
                    /**
                     * Calls BatchCalculateAndSaveAccuracy.
                     * @function batchCalculateAndSaveAccuracy
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @instance
                     * @param {palexy.sherlock.v1.IBatchCalculateAndSaveAccuracyRequest} request BatchCalculateAndSaveAccuracyRequest message or plain object
                     * @param {palexy.sherlock.v1.AccuracyService.BatchCalculateAndSaveAccuracyCallback} callback Node-style callback called with the error, if any, and BatchCalculateAndSaveAccuracyResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AccuracyService.prototype.batchCalculateAndSaveAccuracy = function batchCalculateAndSaveAccuracy(request, callback) {
                        return this.rpcCall(batchCalculateAndSaveAccuracy, $root.palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest, $root.palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse, request, callback);
                    }, "name", { value: "BatchCalculateAndSaveAccuracy" });
    
                    /**
                     * Calls BatchCalculateAndSaveAccuracy.
                     * @function batchCalculateAndSaveAccuracy
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @instance
                     * @param {palexy.sherlock.v1.IBatchCalculateAndSaveAccuracyRequest} request BatchCalculateAndSaveAccuracyRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.AccuracyService#listAccuracyResults}.
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @typedef ListAccuracyResultsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.ListAccuracyResultsResponse} [response] ListAccuracyResultsResponse
                     */
    
                    /**
                     * Calls ListAccuracyResults.
                     * @function listAccuracyResults
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @instance
                     * @param {palexy.sherlock.v1.IListAccuracyResultsRequest} request ListAccuracyResultsRequest message or plain object
                     * @param {palexy.sherlock.v1.AccuracyService.ListAccuracyResultsCallback} callback Node-style callback called with the error, if any, and ListAccuracyResultsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AccuracyService.prototype.listAccuracyResults = function listAccuracyResults(request, callback) {
                        return this.rpcCall(listAccuracyResults, $root.palexy.sherlock.v1.ListAccuracyResultsRequest, $root.palexy.sherlock.v1.ListAccuracyResultsResponse, request, callback);
                    }, "name", { value: "ListAccuracyResults" });
    
                    /**
                     * Calls ListAccuracyResults.
                     * @function listAccuracyResults
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @instance
                     * @param {palexy.sherlock.v1.IListAccuracyResultsRequest} request ListAccuracyResultsRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.ListAccuracyResultsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.AccuracyService#calculateAndSaveExperimentAccuracy}.
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @typedef CalculateAndSaveExperimentAccuracyCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse} [response] CalculateAndSaveExperimentAccuracyResponse
                     */
    
                    /**
                     * Calls CalculateAndSaveExperimentAccuracy.
                     * @function calculateAndSaveExperimentAccuracy
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @instance
                     * @param {palexy.sherlock.v1.ICalculateAndSaveExperimentAccuracyRequest} request CalculateAndSaveExperimentAccuracyRequest message or plain object
                     * @param {palexy.sherlock.v1.AccuracyService.CalculateAndSaveExperimentAccuracyCallback} callback Node-style callback called with the error, if any, and CalculateAndSaveExperimentAccuracyResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AccuracyService.prototype.calculateAndSaveExperimentAccuracy = function calculateAndSaveExperimentAccuracy(request, callback) {
                        return this.rpcCall(calculateAndSaveExperimentAccuracy, $root.palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest, $root.palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse, request, callback);
                    }, "name", { value: "CalculateAndSaveExperimentAccuracy" });
    
                    /**
                     * Calls CalculateAndSaveExperimentAccuracy.
                     * @function calculateAndSaveExperimentAccuracy
                     * @memberof palexy.sherlock.v1.AccuracyService
                     * @instance
                     * @param {palexy.sherlock.v1.ICalculateAndSaveExperimentAccuracyRequest} request CalculateAndSaveExperimentAccuracyRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse>} Promise
                     * @variation 2
                     */
    
                    return AccuracyService;
                })();
    
                v1.CalculateAndSaveAccuracyRequest = (function() {
    
                    /**
                     * Properties of a CalculateAndSaveAccuracyRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICalculateAndSaveAccuracyRequest
                     * @property {number|null} [storeId] CalculateAndSaveAccuracyRequest storeId
                     * @property {string|null} [dateId] CalculateAndSaveAccuracyRequest dateId
                     * @property {palexy.sherlock.v1.QcFile.QcType|null} [qcType] CalculateAndSaveAccuracyRequest qcType
                     */
    
                    /**
                     * Constructs a new CalculateAndSaveAccuracyRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CalculateAndSaveAccuracyRequest.
                     * @implements ICalculateAndSaveAccuracyRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICalculateAndSaveAccuracyRequest=} [properties] Properties to set
                     */
                    function CalculateAndSaveAccuracyRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CalculateAndSaveAccuracyRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @instance
                     */
                    CalculateAndSaveAccuracyRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CalculateAndSaveAccuracyRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @instance
                     */
                    CalculateAndSaveAccuracyRequest.prototype.dateId = "";
    
                    /**
                     * CalculateAndSaveAccuracyRequest qcType.
                     * @member {palexy.sherlock.v1.QcFile.QcType} qcType
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @instance
                     */
                    CalculateAndSaveAccuracyRequest.prototype.qcType = 0;
    
                    /**
                     * Creates a new CalculateAndSaveAccuracyRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveAccuracyRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CalculateAndSaveAccuracyRequest} CalculateAndSaveAccuracyRequest instance
                     */
                    CalculateAndSaveAccuracyRequest.create = function create(properties) {
                        return new CalculateAndSaveAccuracyRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CalculateAndSaveAccuracyRequest message. Does not implicitly {@link palexy.sherlock.v1.CalculateAndSaveAccuracyRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveAccuracyRequest} message CalculateAndSaveAccuracyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CalculateAndSaveAccuracyRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        if (message.qcType != null && Object.hasOwnProperty.call(message, "qcType"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.qcType);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CalculateAndSaveAccuracyRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CalculateAndSaveAccuracyRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveAccuracyRequest} message CalculateAndSaveAccuracyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CalculateAndSaveAccuracyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CalculateAndSaveAccuracyRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CalculateAndSaveAccuracyRequest} CalculateAndSaveAccuracyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CalculateAndSaveAccuracyRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CalculateAndSaveAccuracyRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            case 3:
                                message.qcType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CalculateAndSaveAccuracyRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CalculateAndSaveAccuracyRequest} CalculateAndSaveAccuracyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CalculateAndSaveAccuracyRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CalculateAndSaveAccuracyRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CalculateAndSaveAccuracyRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.qcType != null && message.hasOwnProperty("qcType"))
                            switch (message.qcType) {
                            default:
                                return "qcType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a CalculateAndSaveAccuracyRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CalculateAndSaveAccuracyRequest} CalculateAndSaveAccuracyRequest
                     */
                    CalculateAndSaveAccuracyRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CalculateAndSaveAccuracyRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CalculateAndSaveAccuracyRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        switch (object.qcType) {
                        case "QC_TYPE_UNKNOWN":
                        case 0:
                            message.qcType = 0;
                            break;
                        case "ICA_STANDARD_COUNTING":
                        case 1:
                            message.qcType = 1;
                            break;
                        case "ICA_CUSTOMER_JOURNEY":
                        case 2:
                            message.qcType = 2;
                            break;
                        case "ICA_ENTRANCE_COUNTING":
                        case 3:
                            message.qcType = 3;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CalculateAndSaveAccuracyRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.CalculateAndSaveAccuracyRequest} message CalculateAndSaveAccuracyRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CalculateAndSaveAccuracyRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.qcType = options.enums === String ? "QC_TYPE_UNKNOWN" : 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.qcType != null && message.hasOwnProperty("qcType"))
                            object.qcType = options.enums === String ? $root.palexy.sherlock.v1.QcFile.QcType[message.qcType] : message.qcType;
                        return object;
                    };
    
                    /**
                     * Converts this CalculateAndSaveAccuracyRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CalculateAndSaveAccuracyRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CalculateAndSaveAccuracyRequest;
                })();
    
                v1.CalculateAndSaveAccuracyResponse = (function() {
    
                    /**
                     * Properties of a CalculateAndSaveAccuracyResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICalculateAndSaveAccuracyResponse
                     * @property {Array.<palexy.sherlock.v1.IAccuracyResult>|null} [accuracyResults] CalculateAndSaveAccuracyResponse accuracyResults
                     */
    
                    /**
                     * Constructs a new CalculateAndSaveAccuracyResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CalculateAndSaveAccuracyResponse.
                     * @implements ICalculateAndSaveAccuracyResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICalculateAndSaveAccuracyResponse=} [properties] Properties to set
                     */
                    function CalculateAndSaveAccuracyResponse(properties) {
                        this.accuracyResults = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CalculateAndSaveAccuracyResponse accuracyResults.
                     * @member {Array.<palexy.sherlock.v1.IAccuracyResult>} accuracyResults
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyResponse
                     * @instance
                     */
                    CalculateAndSaveAccuracyResponse.prototype.accuracyResults = $util.emptyArray;
    
                    /**
                     * Creates a new CalculateAndSaveAccuracyResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveAccuracyResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CalculateAndSaveAccuracyResponse} CalculateAndSaveAccuracyResponse instance
                     */
                    CalculateAndSaveAccuracyResponse.create = function create(properties) {
                        return new CalculateAndSaveAccuracyResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CalculateAndSaveAccuracyResponse message. Does not implicitly {@link palexy.sherlock.v1.CalculateAndSaveAccuracyResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveAccuracyResponse} message CalculateAndSaveAccuracyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CalculateAndSaveAccuracyResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.accuracyResults != null && message.accuracyResults.length)
                            for (var i = 0; i < message.accuracyResults.length; ++i)
                                $root.palexy.sherlock.v1.AccuracyResult.encode(message.accuracyResults[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CalculateAndSaveAccuracyResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CalculateAndSaveAccuracyResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveAccuracyResponse} message CalculateAndSaveAccuracyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CalculateAndSaveAccuracyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CalculateAndSaveAccuracyResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CalculateAndSaveAccuracyResponse} CalculateAndSaveAccuracyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CalculateAndSaveAccuracyResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CalculateAndSaveAccuracyResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.accuracyResults && message.accuracyResults.length))
                                    message.accuracyResults = [];
                                message.accuracyResults.push($root.palexy.sherlock.v1.AccuracyResult.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CalculateAndSaveAccuracyResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CalculateAndSaveAccuracyResponse} CalculateAndSaveAccuracyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CalculateAndSaveAccuracyResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CalculateAndSaveAccuracyResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CalculateAndSaveAccuracyResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.accuracyResults != null && message.hasOwnProperty("accuracyResults")) {
                            if (!Array.isArray(message.accuracyResults))
                                return "accuracyResults: array expected";
                            for (var i = 0; i < message.accuracyResults.length; ++i) {
                                var error = $root.palexy.sherlock.v1.AccuracyResult.verify(message.accuracyResults[i]);
                                if (error)
                                    return "accuracyResults." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CalculateAndSaveAccuracyResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CalculateAndSaveAccuracyResponse} CalculateAndSaveAccuracyResponse
                     */
                    CalculateAndSaveAccuracyResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CalculateAndSaveAccuracyResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CalculateAndSaveAccuracyResponse();
                        if (object.accuracyResults) {
                            if (!Array.isArray(object.accuracyResults))
                                throw TypeError(".palexy.sherlock.v1.CalculateAndSaveAccuracyResponse.accuracyResults: array expected");
                            message.accuracyResults = [];
                            for (var i = 0; i < object.accuracyResults.length; ++i) {
                                if (typeof object.accuracyResults[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.CalculateAndSaveAccuracyResponse.accuracyResults: object expected");
                                message.accuracyResults[i] = $root.palexy.sherlock.v1.AccuracyResult.fromObject(object.accuracyResults[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CalculateAndSaveAccuracyResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.CalculateAndSaveAccuracyResponse} message CalculateAndSaveAccuracyResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CalculateAndSaveAccuracyResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.accuracyResults = [];
                        if (message.accuracyResults && message.accuracyResults.length) {
                            object.accuracyResults = [];
                            for (var j = 0; j < message.accuracyResults.length; ++j)
                                object.accuracyResults[j] = $root.palexy.sherlock.v1.AccuracyResult.toObject(message.accuracyResults[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CalculateAndSaveAccuracyResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CalculateAndSaveAccuracyResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CalculateAndSaveAccuracyResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CalculateAndSaveAccuracyResponse;
                })();
    
                /**
                 * AccuracyType enum.
                 * @name palexy.sherlock.v1.AccuracyType
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} VISITOR_COUNTING_FOR_1H_SLOT=1 VISITOR_COUNTING_FOR_1H_SLOT value
                 * @property {number} VISITOR_COUNTING_FOR_WHOLE_DAY=2 VISITOR_COUNTING_FOR_WHOLE_DAY value
                 * @property {number} CONVERSION_RATE_FOR_WHOLE_DAY=3 CONVERSION_RATE_FOR_WHOLE_DAY value
                 * @property {number} INTERACTION_RATE_FOR_WHOLE_DAY=4 INTERACTION_RATE_FOR_WHOLE_DAY value
                 * @property {number} AGE_DISTRIBUTION_FOR_WHOLE_DAY=5 AGE_DISTRIBUTION_FOR_WHOLE_DAY value
                 * @property {number} GENDER_DISTRIBUTION_FOR_WHOLE_DAY=6 GENDER_DISTRIBUTION_FOR_WHOLE_DAY value
                 * @property {number} STAFF_CONVERSION_RATE_FOR_WHOLE_DAY=7 STAFF_CONVERSION_RATE_FOR_WHOLE_DAY value
                 * @property {number} VISITOR_COUNTING_FOR_1H_SLOT_INCLUDE_LONG_STAFF=8 VISITOR_COUNTING_FOR_1H_SLOT_INCLUDE_LONG_STAFF value
                 * @property {number} VISITOR_COUNTING_FOR_WHOLE_DAY_INCLUDE_LONG_STAFF=9 VISITOR_COUNTING_FOR_WHOLE_DAY_INCLUDE_LONG_STAFF value
                 * @property {number} DWELL_TIME_FOR_WHOLE_DAY=10 DWELL_TIME_FOR_WHOLE_DAY value
                 * @property {number} BUYER_DWELL_TIME_FOR_WHOLE_DAY=11 BUYER_DWELL_TIME_FOR_WHOLE_DAY value
                 * @property {number} VISITOR_COUNTING_ON_SECTION_FOR_WHOLE_DAY=12 VISITOR_COUNTING_ON_SECTION_FOR_WHOLE_DAY value
                 * @property {number} VISITOR_COUNTING_ON_SECTION_FOR_1H_SLOT=13 VISITOR_COUNTING_ON_SECTION_FOR_1H_SLOT value
                 * @property {number} GROUP_COUNTING_FOR_1H_SLOT=14 GROUP_COUNTING_FOR_1H_SLOT value
                 * @property {number} GROUP_COUNTING_FOR_WHOLE_DAY=15 GROUP_COUNTING_FOR_WHOLE_DAY value
                 * @property {number} TIME_TO_FIRST_INTERACTION_DISTRIBUTION_FOR_WHOLE_DAY=16 TIME_TO_FIRST_INTERACTION_DISTRIBUTION_FOR_WHOLE_DAY value
                 * @property {number} TIME_TO_FIRST_INTERACTION_MEDIAN_FOR_WHOLE_DAY=17 TIME_TO_FIRST_INTERACTION_MEDIAN_FOR_WHOLE_DAY value
                 * @property {number} TIME_TO_FIRST_GREETING_DISTRIBUTION_FOR_WHOLE_DAY=18 TIME_TO_FIRST_GREETING_DISTRIBUTION_FOR_WHOLE_DAY value
                 * @property {number} TIME_TO_FIRST_GREETING_MEDIAN_FOR_WHOLE_DAY=19 TIME_TO_FIRST_GREETING_MEDIAN_FOR_WHOLE_DAY value
                 * @property {number} GREETING_RATE_FOR_WHOLE_DAY=20 GREETING_RATE_FOR_WHOLE_DAY value
                 * @property {number} GROUP_RATE_FOR_WHOLE_DAY=21 GROUP_RATE_FOR_WHOLE_DAY value
                 * @property {number} GROUP_WITH_KID_RATE_FOR_WHOLE_DAY=22 GROUP_WITH_KID_RATE_FOR_WHOLE_DAY value
                 * @property {number} ENTRANCE_COUNTING_BY_CAMERA_FOR_1H_SLOT=23 ENTRANCE_COUNTING_BY_CAMERA_FOR_1H_SLOT value
                 * @property {number} ENTRANCE_COUNTING_FOR_1H_SLOT=24 ENTRANCE_COUNTING_FOR_1H_SLOT value
                 */
                v1.AccuracyType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "VISITOR_COUNTING_FOR_1H_SLOT"] = 1;
                    values[valuesById[2] = "VISITOR_COUNTING_FOR_WHOLE_DAY"] = 2;
                    values[valuesById[3] = "CONVERSION_RATE_FOR_WHOLE_DAY"] = 3;
                    values[valuesById[4] = "INTERACTION_RATE_FOR_WHOLE_DAY"] = 4;
                    values[valuesById[5] = "AGE_DISTRIBUTION_FOR_WHOLE_DAY"] = 5;
                    values[valuesById[6] = "GENDER_DISTRIBUTION_FOR_WHOLE_DAY"] = 6;
                    values[valuesById[7] = "STAFF_CONVERSION_RATE_FOR_WHOLE_DAY"] = 7;
                    values[valuesById[8] = "VISITOR_COUNTING_FOR_1H_SLOT_INCLUDE_LONG_STAFF"] = 8;
                    values[valuesById[9] = "VISITOR_COUNTING_FOR_WHOLE_DAY_INCLUDE_LONG_STAFF"] = 9;
                    values[valuesById[10] = "DWELL_TIME_FOR_WHOLE_DAY"] = 10;
                    values[valuesById[11] = "BUYER_DWELL_TIME_FOR_WHOLE_DAY"] = 11;
                    values[valuesById[12] = "VISITOR_COUNTING_ON_SECTION_FOR_WHOLE_DAY"] = 12;
                    values[valuesById[13] = "VISITOR_COUNTING_ON_SECTION_FOR_1H_SLOT"] = 13;
                    values[valuesById[14] = "GROUP_COUNTING_FOR_1H_SLOT"] = 14;
                    values[valuesById[15] = "GROUP_COUNTING_FOR_WHOLE_DAY"] = 15;
                    values[valuesById[16] = "TIME_TO_FIRST_INTERACTION_DISTRIBUTION_FOR_WHOLE_DAY"] = 16;
                    values[valuesById[17] = "TIME_TO_FIRST_INTERACTION_MEDIAN_FOR_WHOLE_DAY"] = 17;
                    values[valuesById[18] = "TIME_TO_FIRST_GREETING_DISTRIBUTION_FOR_WHOLE_DAY"] = 18;
                    values[valuesById[19] = "TIME_TO_FIRST_GREETING_MEDIAN_FOR_WHOLE_DAY"] = 19;
                    values[valuesById[20] = "GREETING_RATE_FOR_WHOLE_DAY"] = 20;
                    values[valuesById[21] = "GROUP_RATE_FOR_WHOLE_DAY"] = 21;
                    values[valuesById[22] = "GROUP_WITH_KID_RATE_FOR_WHOLE_DAY"] = 22;
                    values[valuesById[23] = "ENTRANCE_COUNTING_BY_CAMERA_FOR_1H_SLOT"] = 23;
                    values[valuesById[24] = "ENTRANCE_COUNTING_FOR_1H_SLOT"] = 24;
                    return values;
                })();
    
                v1.AccuracyResult = (function() {
    
                    /**
                     * Properties of an AccuracyResult.
                     * @memberof palexy.sherlock.v1
                     * @interface IAccuracyResult
                     * @property {string|null} [name] AccuracyResult name
                     * @property {palexy.sherlock.v1.AccuracyType|null} [accuracyType] AccuracyResult accuracyType
                     * @property {number|null} [storeId] AccuracyResult storeId
                     * @property {number|null} [accuracyNumber] AccuracyResult accuracyNumber
                     * @property {string|null} [dateId] AccuracyResult dateId
                     * @property {number|null} [createTimeMs] AccuracyResult createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] AccuracyResult lastUpdateTimeMs
                     * @property {string|null} [additionalData] AccuracyResult additionalData
                     * @property {number|null} [qcFileId] AccuracyResult qcFileId
                     * @property {number|null} [hourSlot] AccuracyResult hourSlot
                     * @property {number|null} [systemResult] AccuracyResult systemResult
                     * @property {number|null} [qcResultMin] AccuracyResult qcResultMin
                     * @property {number|null} [qcResultMax] AccuracyResult qcResultMax
                     * @property {string|null} [experimentProgrammingName] AccuracyResult experimentProgrammingName
                     * @property {string|null} [sectionName] AccuracyResult sectionName
                     * @property {string|null} [cameraId] AccuracyResult cameraId
                     */
    
                    /**
                     * Constructs a new AccuracyResult.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an AccuracyResult.
                     * @implements IAccuracyResult
                     * @constructor
                     * @param {palexy.sherlock.v1.IAccuracyResult=} [properties] Properties to set
                     */
                    function AccuracyResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AccuracyResult name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.name = "";
    
                    /**
                     * AccuracyResult accuracyType.
                     * @member {palexy.sherlock.v1.AccuracyType} accuracyType
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.accuracyType = 0;
    
                    /**
                     * AccuracyResult storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AccuracyResult accuracyNumber.
                     * @member {number} accuracyNumber
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.accuracyNumber = 0;
    
                    /**
                     * AccuracyResult dateId.
                     * @member {string} dateId
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.dateId = "";
    
                    /**
                     * AccuracyResult createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AccuracyResult lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AccuracyResult additionalData.
                     * @member {string} additionalData
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.additionalData = "";
    
                    /**
                     * AccuracyResult qcFileId.
                     * @member {number} qcFileId
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.qcFileId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AccuracyResult hourSlot.
                     * @member {number} hourSlot
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.hourSlot = 0;
    
                    /**
                     * AccuracyResult systemResult.
                     * @member {number} systemResult
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.systemResult = 0;
    
                    /**
                     * AccuracyResult qcResultMin.
                     * @member {number} qcResultMin
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.qcResultMin = 0;
    
                    /**
                     * AccuracyResult qcResultMax.
                     * @member {number} qcResultMax
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.qcResultMax = 0;
    
                    /**
                     * AccuracyResult experimentProgrammingName.
                     * @member {string} experimentProgrammingName
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.experimentProgrammingName = "";
    
                    /**
                     * AccuracyResult sectionName.
                     * @member {string} sectionName
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.sectionName = "";
    
                    /**
                     * AccuracyResult cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     */
                    AccuracyResult.prototype.cameraId = "";
    
                    /**
                     * Creates a new AccuracyResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @static
                     * @param {palexy.sherlock.v1.IAccuracyResult=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.AccuracyResult} AccuracyResult instance
                     */
                    AccuracyResult.create = function create(properties) {
                        return new AccuracyResult(properties);
                    };
    
                    /**
                     * Encodes the specified AccuracyResult message. Does not implicitly {@link palexy.sherlock.v1.AccuracyResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @static
                     * @param {palexy.sherlock.v1.IAccuracyResult} message AccuracyResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AccuracyResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.accuracyType != null && Object.hasOwnProperty.call(message, "accuracyType"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.accuracyType);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        if (message.accuracyNumber != null && Object.hasOwnProperty.call(message, "accuracyNumber"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.accuracyNumber);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.dateId);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.lastUpdateTimeMs);
                        if (message.additionalData != null && Object.hasOwnProperty.call(message, "additionalData"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.additionalData);
                        if (message.qcFileId != null && Object.hasOwnProperty.call(message, "qcFileId"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int64(message.qcFileId);
                        if (message.hourSlot != null && Object.hasOwnProperty.call(message, "hourSlot"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.hourSlot);
                        if (message.systemResult != null && Object.hasOwnProperty.call(message, "systemResult"))
                            writer.uint32(/* id 11, wireType 5 =*/93).float(message.systemResult);
                        if (message.qcResultMin != null && Object.hasOwnProperty.call(message, "qcResultMin"))
                            writer.uint32(/* id 12, wireType 5 =*/101).float(message.qcResultMin);
                        if (message.qcResultMax != null && Object.hasOwnProperty.call(message, "qcResultMax"))
                            writer.uint32(/* id 13, wireType 5 =*/109).float(message.qcResultMax);
                        if (message.experimentProgrammingName != null && Object.hasOwnProperty.call(message, "experimentProgrammingName"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.experimentProgrammingName);
                        if (message.sectionName != null && Object.hasOwnProperty.call(message, "sectionName"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.sectionName);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 16, wireType 2 =*/130).string(message.cameraId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AccuracyResult message, length delimited. Does not implicitly {@link palexy.sherlock.v1.AccuracyResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @static
                     * @param {palexy.sherlock.v1.IAccuracyResult} message AccuracyResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AccuracyResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AccuracyResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.AccuracyResult} AccuracyResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AccuracyResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.AccuracyResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.accuracyType = reader.int32();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 4:
                                message.accuracyNumber = reader.float();
                                break;
                            case 5:
                                message.dateId = reader.string();
                                break;
                            case 6:
                                message.createTimeMs = reader.int64();
                                break;
                            case 7:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 8:
                                message.additionalData = reader.string();
                                break;
                            case 9:
                                message.qcFileId = reader.int64();
                                break;
                            case 10:
                                message.hourSlot = reader.int32();
                                break;
                            case 11:
                                message.systemResult = reader.float();
                                break;
                            case 12:
                                message.qcResultMin = reader.float();
                                break;
                            case 13:
                                message.qcResultMax = reader.float();
                                break;
                            case 14:
                                message.experimentProgrammingName = reader.string();
                                break;
                            case 15:
                                message.sectionName = reader.string();
                                break;
                            case 16:
                                message.cameraId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AccuracyResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.AccuracyResult} AccuracyResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AccuracyResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AccuracyResult message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AccuracyResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.accuracyType != null && message.hasOwnProperty("accuracyType"))
                            switch (message.accuracyType) {
                            default:
                                return "accuracyType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                            case 22:
                            case 23:
                            case 24:
                                break;
                            }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.accuracyNumber != null && message.hasOwnProperty("accuracyNumber"))
                            if (typeof message.accuracyNumber !== "number")
                                return "accuracyNumber: number expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.additionalData != null && message.hasOwnProperty("additionalData"))
                            if (!$util.isString(message.additionalData))
                                return "additionalData: string expected";
                        if (message.qcFileId != null && message.hasOwnProperty("qcFileId"))
                            if (!$util.isInteger(message.qcFileId) && !(message.qcFileId && $util.isInteger(message.qcFileId.low) && $util.isInteger(message.qcFileId.high)))
                                return "qcFileId: integer|Long expected";
                        if (message.hourSlot != null && message.hasOwnProperty("hourSlot"))
                            if (!$util.isInteger(message.hourSlot))
                                return "hourSlot: integer expected";
                        if (message.systemResult != null && message.hasOwnProperty("systemResult"))
                            if (typeof message.systemResult !== "number")
                                return "systemResult: number expected";
                        if (message.qcResultMin != null && message.hasOwnProperty("qcResultMin"))
                            if (typeof message.qcResultMin !== "number")
                                return "qcResultMin: number expected";
                        if (message.qcResultMax != null && message.hasOwnProperty("qcResultMax"))
                            if (typeof message.qcResultMax !== "number")
                                return "qcResultMax: number expected";
                        if (message.experimentProgrammingName != null && message.hasOwnProperty("experimentProgrammingName"))
                            if (!$util.isString(message.experimentProgrammingName))
                                return "experimentProgrammingName: string expected";
                        if (message.sectionName != null && message.hasOwnProperty("sectionName"))
                            if (!$util.isString(message.sectionName))
                                return "sectionName: string expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an AccuracyResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.AccuracyResult} AccuracyResult
                     */
                    AccuracyResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.AccuracyResult)
                            return object;
                        var message = new $root.palexy.sherlock.v1.AccuracyResult();
                        if (object.name != null)
                            message.name = String(object.name);
                        switch (object.accuracyType) {
                        case "UNKNOWN":
                        case 0:
                            message.accuracyType = 0;
                            break;
                        case "VISITOR_COUNTING_FOR_1H_SLOT":
                        case 1:
                            message.accuracyType = 1;
                            break;
                        case "VISITOR_COUNTING_FOR_WHOLE_DAY":
                        case 2:
                            message.accuracyType = 2;
                            break;
                        case "CONVERSION_RATE_FOR_WHOLE_DAY":
                        case 3:
                            message.accuracyType = 3;
                            break;
                        case "INTERACTION_RATE_FOR_WHOLE_DAY":
                        case 4:
                            message.accuracyType = 4;
                            break;
                        case "AGE_DISTRIBUTION_FOR_WHOLE_DAY":
                        case 5:
                            message.accuracyType = 5;
                            break;
                        case "GENDER_DISTRIBUTION_FOR_WHOLE_DAY":
                        case 6:
                            message.accuracyType = 6;
                            break;
                        case "STAFF_CONVERSION_RATE_FOR_WHOLE_DAY":
                        case 7:
                            message.accuracyType = 7;
                            break;
                        case "VISITOR_COUNTING_FOR_1H_SLOT_INCLUDE_LONG_STAFF":
                        case 8:
                            message.accuracyType = 8;
                            break;
                        case "VISITOR_COUNTING_FOR_WHOLE_DAY_INCLUDE_LONG_STAFF":
                        case 9:
                            message.accuracyType = 9;
                            break;
                        case "DWELL_TIME_FOR_WHOLE_DAY":
                        case 10:
                            message.accuracyType = 10;
                            break;
                        case "BUYER_DWELL_TIME_FOR_WHOLE_DAY":
                        case 11:
                            message.accuracyType = 11;
                            break;
                        case "VISITOR_COUNTING_ON_SECTION_FOR_WHOLE_DAY":
                        case 12:
                            message.accuracyType = 12;
                            break;
                        case "VISITOR_COUNTING_ON_SECTION_FOR_1H_SLOT":
                        case 13:
                            message.accuracyType = 13;
                            break;
                        case "GROUP_COUNTING_FOR_1H_SLOT":
                        case 14:
                            message.accuracyType = 14;
                            break;
                        case "GROUP_COUNTING_FOR_WHOLE_DAY":
                        case 15:
                            message.accuracyType = 15;
                            break;
                        case "TIME_TO_FIRST_INTERACTION_DISTRIBUTION_FOR_WHOLE_DAY":
                        case 16:
                            message.accuracyType = 16;
                            break;
                        case "TIME_TO_FIRST_INTERACTION_MEDIAN_FOR_WHOLE_DAY":
                        case 17:
                            message.accuracyType = 17;
                            break;
                        case "TIME_TO_FIRST_GREETING_DISTRIBUTION_FOR_WHOLE_DAY":
                        case 18:
                            message.accuracyType = 18;
                            break;
                        case "TIME_TO_FIRST_GREETING_MEDIAN_FOR_WHOLE_DAY":
                        case 19:
                            message.accuracyType = 19;
                            break;
                        case "GREETING_RATE_FOR_WHOLE_DAY":
                        case 20:
                            message.accuracyType = 20;
                            break;
                        case "GROUP_RATE_FOR_WHOLE_DAY":
                        case 21:
                            message.accuracyType = 21;
                            break;
                        case "GROUP_WITH_KID_RATE_FOR_WHOLE_DAY":
                        case 22:
                            message.accuracyType = 22;
                            break;
                        case "ENTRANCE_COUNTING_BY_CAMERA_FOR_1H_SLOT":
                        case 23:
                            message.accuracyType = 23;
                            break;
                        case "ENTRANCE_COUNTING_FOR_1H_SLOT":
                        case 24:
                            message.accuracyType = 24;
                            break;
                        }
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.accuracyNumber != null)
                            message.accuracyNumber = Number(object.accuracyNumber);
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.additionalData != null)
                            message.additionalData = String(object.additionalData);
                        if (object.qcFileId != null)
                            if ($util.Long)
                                (message.qcFileId = $util.Long.fromValue(object.qcFileId)).unsigned = false;
                            else if (typeof object.qcFileId === "string")
                                message.qcFileId = parseInt(object.qcFileId, 10);
                            else if (typeof object.qcFileId === "number")
                                message.qcFileId = object.qcFileId;
                            else if (typeof object.qcFileId === "object")
                                message.qcFileId = new $util.LongBits(object.qcFileId.low >>> 0, object.qcFileId.high >>> 0).toNumber();
                        if (object.hourSlot != null)
                            message.hourSlot = object.hourSlot | 0;
                        if (object.systemResult != null)
                            message.systemResult = Number(object.systemResult);
                        if (object.qcResultMin != null)
                            message.qcResultMin = Number(object.qcResultMin);
                        if (object.qcResultMax != null)
                            message.qcResultMax = Number(object.qcResultMax);
                        if (object.experimentProgrammingName != null)
                            message.experimentProgrammingName = String(object.experimentProgrammingName);
                        if (object.sectionName != null)
                            message.sectionName = String(object.sectionName);
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AccuracyResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @static
                     * @param {palexy.sherlock.v1.AccuracyResult} message AccuracyResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AccuracyResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.accuracyType = options.enums === String ? "UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.accuracyNumber = 0;
                            object.dateId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            object.additionalData = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.qcFileId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.qcFileId = options.longs === String ? "0" : 0;
                            object.hourSlot = 0;
                            object.systemResult = 0;
                            object.qcResultMin = 0;
                            object.qcResultMax = 0;
                            object.experimentProgrammingName = "";
                            object.sectionName = "";
                            object.cameraId = "";
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.accuracyType != null && message.hasOwnProperty("accuracyType"))
                            object.accuracyType = options.enums === String ? $root.palexy.sherlock.v1.AccuracyType[message.accuracyType] : message.accuracyType;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.accuracyNumber != null && message.hasOwnProperty("accuracyNumber"))
                            object.accuracyNumber = options.json && !isFinite(message.accuracyNumber) ? String(message.accuracyNumber) : message.accuracyNumber;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.additionalData != null && message.hasOwnProperty("additionalData"))
                            object.additionalData = message.additionalData;
                        if (message.qcFileId != null && message.hasOwnProperty("qcFileId"))
                            if (typeof message.qcFileId === "number")
                                object.qcFileId = options.longs === String ? String(message.qcFileId) : message.qcFileId;
                            else
                                object.qcFileId = options.longs === String ? $util.Long.prototype.toString.call(message.qcFileId) : options.longs === Number ? new $util.LongBits(message.qcFileId.low >>> 0, message.qcFileId.high >>> 0).toNumber() : message.qcFileId;
                        if (message.hourSlot != null && message.hasOwnProperty("hourSlot"))
                            object.hourSlot = message.hourSlot;
                        if (message.systemResult != null && message.hasOwnProperty("systemResult"))
                            object.systemResult = options.json && !isFinite(message.systemResult) ? String(message.systemResult) : message.systemResult;
                        if (message.qcResultMin != null && message.hasOwnProperty("qcResultMin"))
                            object.qcResultMin = options.json && !isFinite(message.qcResultMin) ? String(message.qcResultMin) : message.qcResultMin;
                        if (message.qcResultMax != null && message.hasOwnProperty("qcResultMax"))
                            object.qcResultMax = options.json && !isFinite(message.qcResultMax) ? String(message.qcResultMax) : message.qcResultMax;
                        if (message.experimentProgrammingName != null && message.hasOwnProperty("experimentProgrammingName"))
                            object.experimentProgrammingName = message.experimentProgrammingName;
                        if (message.sectionName != null && message.hasOwnProperty("sectionName"))
                            object.sectionName = message.sectionName;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        return object;
                    };
    
                    /**
                     * Converts this AccuracyResult to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.AccuracyResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AccuracyResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AccuracyResult;
                })();
    
                v1.BatchCalculateAndSaveAccuracyRequest = (function() {
    
                    /**
                     * Properties of a BatchCalculateAndSaveAccuracyRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IBatchCalculateAndSaveAccuracyRequest
                     * @property {Array.<number>|null} [storeIds] BatchCalculateAndSaveAccuracyRequest storeIds
                     * @property {string|null} [startDate] BatchCalculateAndSaveAccuracyRequest startDate
                     * @property {string|null} [endDate] BatchCalculateAndSaveAccuracyRequest endDate
                     */
    
                    /**
                     * Constructs a new BatchCalculateAndSaveAccuracyRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BatchCalculateAndSaveAccuracyRequest.
                     * @implements IBatchCalculateAndSaveAccuracyRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IBatchCalculateAndSaveAccuracyRequest=} [properties] Properties to set
                     */
                    function BatchCalculateAndSaveAccuracyRequest(properties) {
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCalculateAndSaveAccuracyRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @instance
                     */
                    BatchCalculateAndSaveAccuracyRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * BatchCalculateAndSaveAccuracyRequest startDate.
                     * @member {string} startDate
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @instance
                     */
                    BatchCalculateAndSaveAccuracyRequest.prototype.startDate = "";
    
                    /**
                     * BatchCalculateAndSaveAccuracyRequest endDate.
                     * @member {string} endDate
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @instance
                     */
                    BatchCalculateAndSaveAccuracyRequest.prototype.endDate = "";
    
                    /**
                     * Creates a new BatchCalculateAndSaveAccuracyRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCalculateAndSaveAccuracyRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest} BatchCalculateAndSaveAccuracyRequest instance
                     */
                    BatchCalculateAndSaveAccuracyRequest.create = function create(properties) {
                        return new BatchCalculateAndSaveAccuracyRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCalculateAndSaveAccuracyRequest message. Does not implicitly {@link palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCalculateAndSaveAccuracyRequest} message BatchCalculateAndSaveAccuracyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCalculateAndSaveAccuracyRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.startDate);
                        if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.endDate);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCalculateAndSaveAccuracyRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCalculateAndSaveAccuracyRequest} message BatchCalculateAndSaveAccuracyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCalculateAndSaveAccuracyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCalculateAndSaveAccuracyRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest} BatchCalculateAndSaveAccuracyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCalculateAndSaveAccuracyRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 2:
                                message.startDate = reader.string();
                                break;
                            case 3:
                                message.endDate = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCalculateAndSaveAccuracyRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest} BatchCalculateAndSaveAccuracyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCalculateAndSaveAccuracyRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCalculateAndSaveAccuracyRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCalculateAndSaveAccuracyRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchCalculateAndSaveAccuracyRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest} BatchCalculateAndSaveAccuracyRequest
                     */
                    BatchCalculateAndSaveAccuracyRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCalculateAndSaveAccuracyRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest} message BatchCalculateAndSaveAccuracyRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCalculateAndSaveAccuracyRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeIds = [];
                        if (options.defaults) {
                            object.startDate = "";
                            object.endDate = "";
                        }
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        return object;
                    };
    
                    /**
                     * Converts this BatchCalculateAndSaveAccuracyRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCalculateAndSaveAccuracyRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCalculateAndSaveAccuracyRequest;
                })();
    
                v1.BatchCalculateAndSaveAccuracyResponse = (function() {
    
                    /**
                     * Properties of a BatchCalculateAndSaveAccuracyResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IBatchCalculateAndSaveAccuracyResponse
                     * @property {Array.<palexy.sherlock.v1.IAccuracyResult>|null} [accuracyResults] BatchCalculateAndSaveAccuracyResponse accuracyResults
                     */
    
                    /**
                     * Constructs a new BatchCalculateAndSaveAccuracyResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BatchCalculateAndSaveAccuracyResponse.
                     * @implements IBatchCalculateAndSaveAccuracyResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IBatchCalculateAndSaveAccuracyResponse=} [properties] Properties to set
                     */
                    function BatchCalculateAndSaveAccuracyResponse(properties) {
                        this.accuracyResults = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCalculateAndSaveAccuracyResponse accuracyResults.
                     * @member {Array.<palexy.sherlock.v1.IAccuracyResult>} accuracyResults
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse
                     * @instance
                     */
                    BatchCalculateAndSaveAccuracyResponse.prototype.accuracyResults = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCalculateAndSaveAccuracyResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCalculateAndSaveAccuracyResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse} BatchCalculateAndSaveAccuracyResponse instance
                     */
                    BatchCalculateAndSaveAccuracyResponse.create = function create(properties) {
                        return new BatchCalculateAndSaveAccuracyResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCalculateAndSaveAccuracyResponse message. Does not implicitly {@link palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCalculateAndSaveAccuracyResponse} message BatchCalculateAndSaveAccuracyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCalculateAndSaveAccuracyResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.accuracyResults != null && message.accuracyResults.length)
                            for (var i = 0; i < message.accuracyResults.length; ++i)
                                $root.palexy.sherlock.v1.AccuracyResult.encode(message.accuracyResults[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCalculateAndSaveAccuracyResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCalculateAndSaveAccuracyResponse} message BatchCalculateAndSaveAccuracyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCalculateAndSaveAccuracyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCalculateAndSaveAccuracyResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse} BatchCalculateAndSaveAccuracyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCalculateAndSaveAccuracyResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.accuracyResults && message.accuracyResults.length))
                                    message.accuracyResults = [];
                                message.accuracyResults.push($root.palexy.sherlock.v1.AccuracyResult.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCalculateAndSaveAccuracyResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse} BatchCalculateAndSaveAccuracyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCalculateAndSaveAccuracyResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCalculateAndSaveAccuracyResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCalculateAndSaveAccuracyResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.accuracyResults != null && message.hasOwnProperty("accuracyResults")) {
                            if (!Array.isArray(message.accuracyResults))
                                return "accuracyResults: array expected";
                            for (var i = 0; i < message.accuracyResults.length; ++i) {
                                var error = $root.palexy.sherlock.v1.AccuracyResult.verify(message.accuracyResults[i]);
                                if (error)
                                    return "accuracyResults." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCalculateAndSaveAccuracyResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse} BatchCalculateAndSaveAccuracyResponse
                     */
                    BatchCalculateAndSaveAccuracyResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse();
                        if (object.accuracyResults) {
                            if (!Array.isArray(object.accuracyResults))
                                throw TypeError(".palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse.accuracyResults: array expected");
                            message.accuracyResults = [];
                            for (var i = 0; i < object.accuracyResults.length; ++i) {
                                if (typeof object.accuracyResults[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse.accuracyResults: object expected");
                                message.accuracyResults[i] = $root.palexy.sherlock.v1.AccuracyResult.fromObject(object.accuracyResults[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCalculateAndSaveAccuracyResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse} message BatchCalculateAndSaveAccuracyResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCalculateAndSaveAccuracyResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.accuracyResults = [];
                        if (message.accuracyResults && message.accuracyResults.length) {
                            object.accuracyResults = [];
                            for (var j = 0; j < message.accuracyResults.length; ++j)
                                object.accuracyResults[j] = $root.palexy.sherlock.v1.AccuracyResult.toObject(message.accuracyResults[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCalculateAndSaveAccuracyResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCalculateAndSaveAccuracyResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCalculateAndSaveAccuracyResponse;
                })();
    
                v1.QcCountingConfig = (function() {
    
                    /**
                     * Properties of a QcCountingConfig.
                     * @memberof palexy.sherlock.v1
                     * @interface IQcCountingConfig
                     * @property {number|null} [minDurationSeconds_] QcCountingConfig minDurationSeconds_
                     * @property {number|null} [softMinDurationSeconds] QcCountingConfig softMinDurationSeconds
                     * @property {number|null} [softMaxDurationSeconds] QcCountingConfig softMaxDurationSeconds
                     * @property {boolean|null} [softNoUniformStaff] QcCountingConfig softNoUniformStaff
                     * @property {boolean|null} [removeUnderTwelve] QcCountingConfig removeUnderTwelve
                     * @property {boolean|null} [removeBetweenTwelveAndTwenty] QcCountingConfig removeBetweenTwelveAndTwenty
                     */
    
                    /**
                     * Constructs a new QcCountingConfig.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a QcCountingConfig.
                     * @implements IQcCountingConfig
                     * @constructor
                     * @param {palexy.sherlock.v1.IQcCountingConfig=} [properties] Properties to set
                     */
                    function QcCountingConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * QcCountingConfig minDurationSeconds_.
                     * @member {number} minDurationSeconds_
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @instance
                     */
                    QcCountingConfig.prototype.minDurationSeconds_ = 0;
    
                    /**
                     * QcCountingConfig softMinDurationSeconds.
                     * @member {number} softMinDurationSeconds
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @instance
                     */
                    QcCountingConfig.prototype.softMinDurationSeconds = 0;
    
                    /**
                     * QcCountingConfig softMaxDurationSeconds.
                     * @member {number} softMaxDurationSeconds
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @instance
                     */
                    QcCountingConfig.prototype.softMaxDurationSeconds = 0;
    
                    /**
                     * QcCountingConfig softNoUniformStaff.
                     * @member {boolean} softNoUniformStaff
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @instance
                     */
                    QcCountingConfig.prototype.softNoUniformStaff = false;
    
                    /**
                     * QcCountingConfig removeUnderTwelve.
                     * @member {boolean} removeUnderTwelve
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @instance
                     */
                    QcCountingConfig.prototype.removeUnderTwelve = false;
    
                    /**
                     * QcCountingConfig removeBetweenTwelveAndTwenty.
                     * @member {boolean} removeBetweenTwelveAndTwenty
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @instance
                     */
                    QcCountingConfig.prototype.removeBetweenTwelveAndTwenty = false;
    
                    /**
                     * Creates a new QcCountingConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @static
                     * @param {palexy.sherlock.v1.IQcCountingConfig=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.QcCountingConfig} QcCountingConfig instance
                     */
                    QcCountingConfig.create = function create(properties) {
                        return new QcCountingConfig(properties);
                    };
    
                    /**
                     * Encodes the specified QcCountingConfig message. Does not implicitly {@link palexy.sherlock.v1.QcCountingConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @static
                     * @param {palexy.sherlock.v1.IQcCountingConfig} message QcCountingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QcCountingConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minDurationSeconds_ != null && Object.hasOwnProperty.call(message, "minDurationSeconds_"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minDurationSeconds_);
                        if (message.softMinDurationSeconds != null && Object.hasOwnProperty.call(message, "softMinDurationSeconds"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.softMinDurationSeconds);
                        if (message.softMaxDurationSeconds != null && Object.hasOwnProperty.call(message, "softMaxDurationSeconds"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.softMaxDurationSeconds);
                        if (message.softNoUniformStaff != null && Object.hasOwnProperty.call(message, "softNoUniformStaff"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.softNoUniformStaff);
                        if (message.removeUnderTwelve != null && Object.hasOwnProperty.call(message, "removeUnderTwelve"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.removeUnderTwelve);
                        if (message.removeBetweenTwelveAndTwenty != null && Object.hasOwnProperty.call(message, "removeBetweenTwelveAndTwenty"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.removeBetweenTwelveAndTwenty);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified QcCountingConfig message, length delimited. Does not implicitly {@link palexy.sherlock.v1.QcCountingConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @static
                     * @param {palexy.sherlock.v1.IQcCountingConfig} message QcCountingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QcCountingConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a QcCountingConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.QcCountingConfig} QcCountingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QcCountingConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.QcCountingConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.minDurationSeconds_ = reader.int32();
                                break;
                            case 2:
                                message.softMinDurationSeconds = reader.int32();
                                break;
                            case 3:
                                message.softMaxDurationSeconds = reader.int32();
                                break;
                            case 4:
                                message.softNoUniformStaff = reader.bool();
                                break;
                            case 5:
                                message.removeUnderTwelve = reader.bool();
                                break;
                            case 6:
                                message.removeBetweenTwelveAndTwenty = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a QcCountingConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.QcCountingConfig} QcCountingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QcCountingConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a QcCountingConfig message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    QcCountingConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minDurationSeconds_ != null && message.hasOwnProperty("minDurationSeconds_"))
                            if (!$util.isInteger(message.minDurationSeconds_))
                                return "minDurationSeconds_: integer expected";
                        if (message.softMinDurationSeconds != null && message.hasOwnProperty("softMinDurationSeconds"))
                            if (!$util.isInteger(message.softMinDurationSeconds))
                                return "softMinDurationSeconds: integer expected";
                        if (message.softMaxDurationSeconds != null && message.hasOwnProperty("softMaxDurationSeconds"))
                            if (!$util.isInteger(message.softMaxDurationSeconds))
                                return "softMaxDurationSeconds: integer expected";
                        if (message.softNoUniformStaff != null && message.hasOwnProperty("softNoUniformStaff"))
                            if (typeof message.softNoUniformStaff !== "boolean")
                                return "softNoUniformStaff: boolean expected";
                        if (message.removeUnderTwelve != null && message.hasOwnProperty("removeUnderTwelve"))
                            if (typeof message.removeUnderTwelve !== "boolean")
                                return "removeUnderTwelve: boolean expected";
                        if (message.removeBetweenTwelveAndTwenty != null && message.hasOwnProperty("removeBetweenTwelveAndTwenty"))
                            if (typeof message.removeBetweenTwelveAndTwenty !== "boolean")
                                return "removeBetweenTwelveAndTwenty: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a QcCountingConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.QcCountingConfig} QcCountingConfig
                     */
                    QcCountingConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.QcCountingConfig)
                            return object;
                        var message = new $root.palexy.sherlock.v1.QcCountingConfig();
                        if (object.minDurationSeconds_ != null)
                            message.minDurationSeconds_ = object.minDurationSeconds_ | 0;
                        if (object.softMinDurationSeconds != null)
                            message.softMinDurationSeconds = object.softMinDurationSeconds | 0;
                        if (object.softMaxDurationSeconds != null)
                            message.softMaxDurationSeconds = object.softMaxDurationSeconds | 0;
                        if (object.softNoUniformStaff != null)
                            message.softNoUniformStaff = Boolean(object.softNoUniformStaff);
                        if (object.removeUnderTwelve != null)
                            message.removeUnderTwelve = Boolean(object.removeUnderTwelve);
                        if (object.removeBetweenTwelveAndTwenty != null)
                            message.removeBetweenTwelveAndTwenty = Boolean(object.removeBetweenTwelveAndTwenty);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a QcCountingConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @static
                     * @param {palexy.sherlock.v1.QcCountingConfig} message QcCountingConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    QcCountingConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minDurationSeconds_ = 0;
                            object.softMinDurationSeconds = 0;
                            object.softMaxDurationSeconds = 0;
                            object.softNoUniformStaff = false;
                            object.removeUnderTwelve = false;
                            object.removeBetweenTwelveAndTwenty = false;
                        }
                        if (message.minDurationSeconds_ != null && message.hasOwnProperty("minDurationSeconds_"))
                            object.minDurationSeconds_ = message.minDurationSeconds_;
                        if (message.softMinDurationSeconds != null && message.hasOwnProperty("softMinDurationSeconds"))
                            object.softMinDurationSeconds = message.softMinDurationSeconds;
                        if (message.softMaxDurationSeconds != null && message.hasOwnProperty("softMaxDurationSeconds"))
                            object.softMaxDurationSeconds = message.softMaxDurationSeconds;
                        if (message.softNoUniformStaff != null && message.hasOwnProperty("softNoUniformStaff"))
                            object.softNoUniformStaff = message.softNoUniformStaff;
                        if (message.removeUnderTwelve != null && message.hasOwnProperty("removeUnderTwelve"))
                            object.removeUnderTwelve = message.removeUnderTwelve;
                        if (message.removeBetweenTwelveAndTwenty != null && message.hasOwnProperty("removeBetweenTwelveAndTwenty"))
                            object.removeBetweenTwelveAndTwenty = message.removeBetweenTwelveAndTwenty;
                        return object;
                    };
    
                    /**
                     * Converts this QcCountingConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.QcCountingConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    QcCountingConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return QcCountingConfig;
                })();
    
                v1.ListAccuracyResultsRequest = (function() {
    
                    /**
                     * Properties of a ListAccuracyResultsRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IListAccuracyResultsRequest
                     * @property {number|null} [qcFileId] ListAccuracyResultsRequest qcFileId
                     * @property {string|null} [pageToken] ListAccuracyResultsRequest pageToken
                     * @property {number|null} [pageSize] ListAccuracyResultsRequest pageSize
                     */
    
                    /**
                     * Constructs a new ListAccuracyResultsRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListAccuracyResultsRequest.
                     * @implements IListAccuracyResultsRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IListAccuracyResultsRequest=} [properties] Properties to set
                     */
                    function ListAccuracyResultsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListAccuracyResultsRequest qcFileId.
                     * @member {number} qcFileId
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @instance
                     */
                    ListAccuracyResultsRequest.prototype.qcFileId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListAccuracyResultsRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @instance
                     */
                    ListAccuracyResultsRequest.prototype.pageToken = "";
    
                    /**
                     * ListAccuracyResultsRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @instance
                     */
                    ListAccuracyResultsRequest.prototype.pageSize = 0;
    
                    /**
                     * Creates a new ListAccuracyResultsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListAccuracyResultsRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListAccuracyResultsRequest} ListAccuracyResultsRequest instance
                     */
                    ListAccuracyResultsRequest.create = function create(properties) {
                        return new ListAccuracyResultsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListAccuracyResultsRequest message. Does not implicitly {@link palexy.sherlock.v1.ListAccuracyResultsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListAccuracyResultsRequest} message ListAccuracyResultsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAccuracyResultsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.qcFileId != null && Object.hasOwnProperty.call(message, "qcFileId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.qcFileId);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pageToken);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListAccuracyResultsRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListAccuracyResultsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListAccuracyResultsRequest} message ListAccuracyResultsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAccuracyResultsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListAccuracyResultsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListAccuracyResultsRequest} ListAccuracyResultsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAccuracyResultsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListAccuracyResultsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.qcFileId = reader.int64();
                                break;
                            case 2:
                                message.pageToken = reader.string();
                                break;
                            case 3:
                                message.pageSize = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListAccuracyResultsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListAccuracyResultsRequest} ListAccuracyResultsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAccuracyResultsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListAccuracyResultsRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListAccuracyResultsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.qcFileId != null && message.hasOwnProperty("qcFileId"))
                            if (!$util.isInteger(message.qcFileId) && !(message.qcFileId && $util.isInteger(message.qcFileId.low) && $util.isInteger(message.qcFileId.high)))
                                return "qcFileId: integer|Long expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListAccuracyResultsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListAccuracyResultsRequest} ListAccuracyResultsRequest
                     */
                    ListAccuracyResultsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListAccuracyResultsRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListAccuracyResultsRequest();
                        if (object.qcFileId != null)
                            if ($util.Long)
                                (message.qcFileId = $util.Long.fromValue(object.qcFileId)).unsigned = false;
                            else if (typeof object.qcFileId === "string")
                                message.qcFileId = parseInt(object.qcFileId, 10);
                            else if (typeof object.qcFileId === "number")
                                message.qcFileId = object.qcFileId;
                            else if (typeof object.qcFileId === "object")
                                message.qcFileId = new $util.LongBits(object.qcFileId.low >>> 0, object.qcFileId.high >>> 0).toNumber();
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListAccuracyResultsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @static
                     * @param {palexy.sherlock.v1.ListAccuracyResultsRequest} message ListAccuracyResultsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListAccuracyResultsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.qcFileId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.qcFileId = options.longs === String ? "0" : 0;
                            object.pageToken = "";
                            object.pageSize = 0;
                        }
                        if (message.qcFileId != null && message.hasOwnProperty("qcFileId"))
                            if (typeof message.qcFileId === "number")
                                object.qcFileId = options.longs === String ? String(message.qcFileId) : message.qcFileId;
                            else
                                object.qcFileId = options.longs === String ? $util.Long.prototype.toString.call(message.qcFileId) : options.longs === Number ? new $util.LongBits(message.qcFileId.low >>> 0, message.qcFileId.high >>> 0).toNumber() : message.qcFileId;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        return object;
                    };
    
                    /**
                     * Converts this ListAccuracyResultsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListAccuracyResultsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListAccuracyResultsRequest;
                })();
    
                v1.ListAccuracyResultsResponse = (function() {
    
                    /**
                     * Properties of a ListAccuracyResultsResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IListAccuracyResultsResponse
                     * @property {Array.<palexy.sherlock.v1.IAccuracyResult>|null} [results] ListAccuracyResultsResponse results
                     */
    
                    /**
                     * Constructs a new ListAccuracyResultsResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListAccuracyResultsResponse.
                     * @implements IListAccuracyResultsResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IListAccuracyResultsResponse=} [properties] Properties to set
                     */
                    function ListAccuracyResultsResponse(properties) {
                        this.results = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListAccuracyResultsResponse results.
                     * @member {Array.<palexy.sherlock.v1.IAccuracyResult>} results
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsResponse
                     * @instance
                     */
                    ListAccuracyResultsResponse.prototype.results = $util.emptyArray;
    
                    /**
                     * Creates a new ListAccuracyResultsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListAccuracyResultsResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListAccuracyResultsResponse} ListAccuracyResultsResponse instance
                     */
                    ListAccuracyResultsResponse.create = function create(properties) {
                        return new ListAccuracyResultsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListAccuracyResultsResponse message. Does not implicitly {@link palexy.sherlock.v1.ListAccuracyResultsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListAccuracyResultsResponse} message ListAccuracyResultsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAccuracyResultsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.results != null && message.results.length)
                            for (var i = 0; i < message.results.length; ++i)
                                $root.palexy.sherlock.v1.AccuracyResult.encode(message.results[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListAccuracyResultsResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListAccuracyResultsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListAccuracyResultsResponse} message ListAccuracyResultsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAccuracyResultsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListAccuracyResultsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListAccuracyResultsResponse} ListAccuracyResultsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAccuracyResultsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListAccuracyResultsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.results && message.results.length))
                                    message.results = [];
                                message.results.push($root.palexy.sherlock.v1.AccuracyResult.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListAccuracyResultsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListAccuracyResultsResponse} ListAccuracyResultsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAccuracyResultsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListAccuracyResultsResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListAccuracyResultsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.results != null && message.hasOwnProperty("results")) {
                            if (!Array.isArray(message.results))
                                return "results: array expected";
                            for (var i = 0; i < message.results.length; ++i) {
                                var error = $root.palexy.sherlock.v1.AccuracyResult.verify(message.results[i]);
                                if (error)
                                    return "results." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListAccuracyResultsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListAccuracyResultsResponse} ListAccuracyResultsResponse
                     */
                    ListAccuracyResultsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListAccuracyResultsResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListAccuracyResultsResponse();
                        if (object.results) {
                            if (!Array.isArray(object.results))
                                throw TypeError(".palexy.sherlock.v1.ListAccuracyResultsResponse.results: array expected");
                            message.results = [];
                            for (var i = 0; i < object.results.length; ++i) {
                                if (typeof object.results[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ListAccuracyResultsResponse.results: object expected");
                                message.results[i] = $root.palexy.sherlock.v1.AccuracyResult.fromObject(object.results[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListAccuracyResultsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsResponse
                     * @static
                     * @param {palexy.sherlock.v1.ListAccuracyResultsResponse} message ListAccuracyResultsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListAccuracyResultsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.results = [];
                        if (message.results && message.results.length) {
                            object.results = [];
                            for (var j = 0; j < message.results.length; ++j)
                                object.results[j] = $root.palexy.sherlock.v1.AccuracyResult.toObject(message.results[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListAccuracyResultsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListAccuracyResultsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListAccuracyResultsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListAccuracyResultsResponse;
                })();
    
                v1.CalculateAndSaveExperimentAccuracyRequest = (function() {
    
                    /**
                     * Properties of a CalculateAndSaveExperimentAccuracyRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICalculateAndSaveExperimentAccuracyRequest
                     * @property {number|null} [storeId] CalculateAndSaveExperimentAccuracyRequest storeId
                     * @property {string|null} [dateId] CalculateAndSaveExperimentAccuracyRequest dateId
                     * @property {string|null} [experimentProgrammingName] CalculateAndSaveExperimentAccuracyRequest experimentProgrammingName
                     * @property {string|null} [mergeResultFileName] CalculateAndSaveExperimentAccuracyRequest mergeResultFileName
                     * @property {string|null} [mergeResultBucket] CalculateAndSaveExperimentAccuracyRequest mergeResultBucket
                     * @property {palexy.sherlock.v1.QcFile.QcType|null} [qcType] CalculateAndSaveExperimentAccuracyRequest qcType
                     */
    
                    /**
                     * Constructs a new CalculateAndSaveExperimentAccuracyRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CalculateAndSaveExperimentAccuracyRequest.
                     * @implements ICalculateAndSaveExperimentAccuracyRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICalculateAndSaveExperimentAccuracyRequest=} [properties] Properties to set
                     */
                    function CalculateAndSaveExperimentAccuracyRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CalculateAndSaveExperimentAccuracyRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @instance
                     */
                    CalculateAndSaveExperimentAccuracyRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CalculateAndSaveExperimentAccuracyRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @instance
                     */
                    CalculateAndSaveExperimentAccuracyRequest.prototype.dateId = "";
    
                    /**
                     * CalculateAndSaveExperimentAccuracyRequest experimentProgrammingName.
                     * @member {string} experimentProgrammingName
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @instance
                     */
                    CalculateAndSaveExperimentAccuracyRequest.prototype.experimentProgrammingName = "";
    
                    /**
                     * CalculateAndSaveExperimentAccuracyRequest mergeResultFileName.
                     * @member {string} mergeResultFileName
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @instance
                     */
                    CalculateAndSaveExperimentAccuracyRequest.prototype.mergeResultFileName = "";
    
                    /**
                     * CalculateAndSaveExperimentAccuracyRequest mergeResultBucket.
                     * @member {string} mergeResultBucket
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @instance
                     */
                    CalculateAndSaveExperimentAccuracyRequest.prototype.mergeResultBucket = "";
    
                    /**
                     * CalculateAndSaveExperimentAccuracyRequest qcType.
                     * @member {palexy.sherlock.v1.QcFile.QcType} qcType
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @instance
                     */
                    CalculateAndSaveExperimentAccuracyRequest.prototype.qcType = 0;
    
                    /**
                     * Creates a new CalculateAndSaveExperimentAccuracyRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveExperimentAccuracyRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest} CalculateAndSaveExperimentAccuracyRequest instance
                     */
                    CalculateAndSaveExperimentAccuracyRequest.create = function create(properties) {
                        return new CalculateAndSaveExperimentAccuracyRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CalculateAndSaveExperimentAccuracyRequest message. Does not implicitly {@link palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveExperimentAccuracyRequest} message CalculateAndSaveExperimentAccuracyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CalculateAndSaveExperimentAccuracyRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        if (message.experimentProgrammingName != null && Object.hasOwnProperty.call(message, "experimentProgrammingName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.experimentProgrammingName);
                        if (message.mergeResultFileName != null && Object.hasOwnProperty.call(message, "mergeResultFileName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.mergeResultFileName);
                        if (message.mergeResultBucket != null && Object.hasOwnProperty.call(message, "mergeResultBucket"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.mergeResultBucket);
                        if (message.qcType != null && Object.hasOwnProperty.call(message, "qcType"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.qcType);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CalculateAndSaveExperimentAccuracyRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveExperimentAccuracyRequest} message CalculateAndSaveExperimentAccuracyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CalculateAndSaveExperimentAccuracyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CalculateAndSaveExperimentAccuracyRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest} CalculateAndSaveExperimentAccuracyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CalculateAndSaveExperimentAccuracyRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            case 3:
                                message.experimentProgrammingName = reader.string();
                                break;
                            case 4:
                                message.mergeResultFileName = reader.string();
                                break;
                            case 5:
                                message.mergeResultBucket = reader.string();
                                break;
                            case 6:
                                message.qcType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CalculateAndSaveExperimentAccuracyRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest} CalculateAndSaveExperimentAccuracyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CalculateAndSaveExperimentAccuracyRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CalculateAndSaveExperimentAccuracyRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CalculateAndSaveExperimentAccuracyRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.experimentProgrammingName != null && message.hasOwnProperty("experimentProgrammingName"))
                            if (!$util.isString(message.experimentProgrammingName))
                                return "experimentProgrammingName: string expected";
                        if (message.mergeResultFileName != null && message.hasOwnProperty("mergeResultFileName"))
                            if (!$util.isString(message.mergeResultFileName))
                                return "mergeResultFileName: string expected";
                        if (message.mergeResultBucket != null && message.hasOwnProperty("mergeResultBucket"))
                            if (!$util.isString(message.mergeResultBucket))
                                return "mergeResultBucket: string expected";
                        if (message.qcType != null && message.hasOwnProperty("qcType"))
                            switch (message.qcType) {
                            default:
                                return "qcType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a CalculateAndSaveExperimentAccuracyRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest} CalculateAndSaveExperimentAccuracyRequest
                     */
                    CalculateAndSaveExperimentAccuracyRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.experimentProgrammingName != null)
                            message.experimentProgrammingName = String(object.experimentProgrammingName);
                        if (object.mergeResultFileName != null)
                            message.mergeResultFileName = String(object.mergeResultFileName);
                        if (object.mergeResultBucket != null)
                            message.mergeResultBucket = String(object.mergeResultBucket);
                        switch (object.qcType) {
                        case "QC_TYPE_UNKNOWN":
                        case 0:
                            message.qcType = 0;
                            break;
                        case "ICA_STANDARD_COUNTING":
                        case 1:
                            message.qcType = 1;
                            break;
                        case "ICA_CUSTOMER_JOURNEY":
                        case 2:
                            message.qcType = 2;
                            break;
                        case "ICA_ENTRANCE_COUNTING":
                        case 3:
                            message.qcType = 3;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CalculateAndSaveExperimentAccuracyRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @static
                     * @param {palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest} message CalculateAndSaveExperimentAccuracyRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CalculateAndSaveExperimentAccuracyRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.experimentProgrammingName = "";
                            object.mergeResultFileName = "";
                            object.mergeResultBucket = "";
                            object.qcType = options.enums === String ? "QC_TYPE_UNKNOWN" : 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.experimentProgrammingName != null && message.hasOwnProperty("experimentProgrammingName"))
                            object.experimentProgrammingName = message.experimentProgrammingName;
                        if (message.mergeResultFileName != null && message.hasOwnProperty("mergeResultFileName"))
                            object.mergeResultFileName = message.mergeResultFileName;
                        if (message.mergeResultBucket != null && message.hasOwnProperty("mergeResultBucket"))
                            object.mergeResultBucket = message.mergeResultBucket;
                        if (message.qcType != null && message.hasOwnProperty("qcType"))
                            object.qcType = options.enums === String ? $root.palexy.sherlock.v1.QcFile.QcType[message.qcType] : message.qcType;
                        return object;
                    };
    
                    /**
                     * Converts this CalculateAndSaveExperimentAccuracyRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CalculateAndSaveExperimentAccuracyRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CalculateAndSaveExperimentAccuracyRequest;
                })();
    
                v1.CalculateAndSaveExperimentAccuracyResponse = (function() {
    
                    /**
                     * Properties of a CalculateAndSaveExperimentAccuracyResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICalculateAndSaveExperimentAccuracyResponse
                     * @property {Array.<palexy.sherlock.v1.IAccuracyResult>|null} [accuracyResults] CalculateAndSaveExperimentAccuracyResponse accuracyResults
                     */
    
                    /**
                     * Constructs a new CalculateAndSaveExperimentAccuracyResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CalculateAndSaveExperimentAccuracyResponse.
                     * @implements ICalculateAndSaveExperimentAccuracyResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICalculateAndSaveExperimentAccuracyResponse=} [properties] Properties to set
                     */
                    function CalculateAndSaveExperimentAccuracyResponse(properties) {
                        this.accuracyResults = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CalculateAndSaveExperimentAccuracyResponse accuracyResults.
                     * @member {Array.<palexy.sherlock.v1.IAccuracyResult>} accuracyResults
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse
                     * @instance
                     */
                    CalculateAndSaveExperimentAccuracyResponse.prototype.accuracyResults = $util.emptyArray;
    
                    /**
                     * Creates a new CalculateAndSaveExperimentAccuracyResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveExperimentAccuracyResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse} CalculateAndSaveExperimentAccuracyResponse instance
                     */
                    CalculateAndSaveExperimentAccuracyResponse.create = function create(properties) {
                        return new CalculateAndSaveExperimentAccuracyResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CalculateAndSaveExperimentAccuracyResponse message. Does not implicitly {@link palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveExperimentAccuracyResponse} message CalculateAndSaveExperimentAccuracyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CalculateAndSaveExperimentAccuracyResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.accuracyResults != null && message.accuracyResults.length)
                            for (var i = 0; i < message.accuracyResults.length; ++i)
                                $root.palexy.sherlock.v1.AccuracyResult.encode(message.accuracyResults[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CalculateAndSaveExperimentAccuracyResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICalculateAndSaveExperimentAccuracyResponse} message CalculateAndSaveExperimentAccuracyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CalculateAndSaveExperimentAccuracyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CalculateAndSaveExperimentAccuracyResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse} CalculateAndSaveExperimentAccuracyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CalculateAndSaveExperimentAccuracyResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.accuracyResults && message.accuracyResults.length))
                                    message.accuracyResults = [];
                                message.accuracyResults.push($root.palexy.sherlock.v1.AccuracyResult.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CalculateAndSaveExperimentAccuracyResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse} CalculateAndSaveExperimentAccuracyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CalculateAndSaveExperimentAccuracyResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CalculateAndSaveExperimentAccuracyResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CalculateAndSaveExperimentAccuracyResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.accuracyResults != null && message.hasOwnProperty("accuracyResults")) {
                            if (!Array.isArray(message.accuracyResults))
                                return "accuracyResults: array expected";
                            for (var i = 0; i < message.accuracyResults.length; ++i) {
                                var error = $root.palexy.sherlock.v1.AccuracyResult.verify(message.accuracyResults[i]);
                                if (error)
                                    return "accuracyResults." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CalculateAndSaveExperimentAccuracyResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse} CalculateAndSaveExperimentAccuracyResponse
                     */
                    CalculateAndSaveExperimentAccuracyResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse();
                        if (object.accuracyResults) {
                            if (!Array.isArray(object.accuracyResults))
                                throw TypeError(".palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse.accuracyResults: array expected");
                            message.accuracyResults = [];
                            for (var i = 0; i < object.accuracyResults.length; ++i) {
                                if (typeof object.accuracyResults[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse.accuracyResults: object expected");
                                message.accuracyResults[i] = $root.palexy.sherlock.v1.AccuracyResult.fromObject(object.accuracyResults[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CalculateAndSaveExperimentAccuracyResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse
                     * @static
                     * @param {palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse} message CalculateAndSaveExperimentAccuracyResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CalculateAndSaveExperimentAccuracyResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.accuracyResults = [];
                        if (message.accuracyResults && message.accuracyResults.length) {
                            object.accuracyResults = [];
                            for (var j = 0; j < message.accuracyResults.length; ++j)
                                object.accuracyResults[j] = $root.palexy.sherlock.v1.AccuracyResult.toObject(message.accuracyResults[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CalculateAndSaveExperimentAccuracyResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CalculateAndSaveExperimentAccuracyResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CalculateAndSaveExperimentAccuracyResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CalculateAndSaveExperimentAccuracyResponse;
                })();
    
                v1.ExperimentService = (function() {
    
                    /**
                     * Constructs a new ExperimentService service.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an ExperimentService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ExperimentService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (ExperimentService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ExperimentService;
    
                    /**
                     * Creates new ExperimentService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ExperimentService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ExperimentService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#batchCreateTestCases}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef BatchCreateTestCasesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.BatchCreateTestCasesResponse} [response] BatchCreateTestCasesResponse
                     */
    
                    /**
                     * Calls BatchCreateTestCases.
                     * @function batchCreateTestCases
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IBatchCreateTestCasesRequest} request BatchCreateTestCasesRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.BatchCreateTestCasesCallback} callback Node-style callback called with the error, if any, and BatchCreateTestCasesResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.batchCreateTestCases = function batchCreateTestCases(request, callback) {
                        return this.rpcCall(batchCreateTestCases, $root.palexy.sherlock.v1.BatchCreateTestCasesRequest, $root.palexy.sherlock.v1.BatchCreateTestCasesResponse, request, callback);
                    }, "name", { value: "BatchCreateTestCases" });
    
                    /**
                     * Calls BatchCreateTestCases.
                     * @function batchCreateTestCases
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IBatchCreateTestCasesRequest} request BatchCreateTestCasesRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.BatchCreateTestCasesResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#createCache}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef CreateCacheCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateCacheResponse} [response] CreateCacheResponse
                     */
    
                    /**
                     * Calls CreateCache.
                     * @function createCache
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateCacheRequest} request CreateCacheRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.CreateCacheCallback} callback Node-style callback called with the error, if any, and CreateCacheResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.createCache = function createCache(request, callback) {
                        return this.rpcCall(createCache, $root.palexy.sherlock.v1.CreateCacheRequest, $root.palexy.sherlock.v1.CreateCacheResponse, request, callback);
                    }, "name", { value: "CreateCache" });
    
                    /**
                     * Calls CreateCache.
                     * @function createCache
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateCacheRequest} request CreateCacheRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateCacheResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#listCaches}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef ListCachesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.ListCachesResponse} [response] ListCachesResponse
                     */
    
                    /**
                     * Calls ListCaches.
                     * @function listCaches
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IListCachesRequest} request ListCachesRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.ListCachesCallback} callback Node-style callback called with the error, if any, and ListCachesResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.listCaches = function listCaches(request, callback) {
                        return this.rpcCall(listCaches, $root.palexy.sherlock.v1.ListCachesRequest, $root.palexy.sherlock.v1.ListCachesResponse, request, callback);
                    }, "name", { value: "ListCaches" });
    
                    /**
                     * Calls ListCaches.
                     * @function listCaches
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IListCachesRequest} request ListCachesRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.ListCachesResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#addCacheItem}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef AddCacheItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.AddCacheItemResponse} [response] AddCacheItemResponse
                     */
    
                    /**
                     * Calls AddCacheItem.
                     * @function addCacheItem
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IAddCacheItemRequest} request AddCacheItemRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.AddCacheItemCallback} callback Node-style callback called with the error, if any, and AddCacheItemResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.addCacheItem = function addCacheItem(request, callback) {
                        return this.rpcCall(addCacheItem, $root.palexy.sherlock.v1.AddCacheItemRequest, $root.palexy.sherlock.v1.AddCacheItemResponse, request, callback);
                    }, "name", { value: "AddCacheItem" });
    
                    /**
                     * Calls AddCacheItem.
                     * @function addCacheItem
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IAddCacheItemRequest} request AddCacheItemRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.AddCacheItemResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#findCacheItemByFileName}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef FindCacheItemByFileNameCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.FindCacheItemByFileNameResponse} [response] FindCacheItemByFileNameResponse
                     */
    
                    /**
                     * Calls FindCacheItemByFileName.
                     * @function findCacheItemByFileName
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IFindCacheItemByFileNameRequest} request FindCacheItemByFileNameRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.FindCacheItemByFileNameCallback} callback Node-style callback called with the error, if any, and FindCacheItemByFileNameResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.findCacheItemByFileName = function findCacheItemByFileName(request, callback) {
                        return this.rpcCall(findCacheItemByFileName, $root.palexy.sherlock.v1.FindCacheItemByFileNameRequest, $root.palexy.sherlock.v1.FindCacheItemByFileNameResponse, request, callback);
                    }, "name", { value: "FindCacheItemByFileName" });
    
                    /**
                     * Calls FindCacheItemByFileName.
                     * @function findCacheItemByFileName
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IFindCacheItemByFileNameRequest} request FindCacheItemByFileNameRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.FindCacheItemByFileNameResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#createExperiment}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef CreateExperimentCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateExperimentResponse} [response] CreateExperimentResponse
                     */
    
                    /**
                     * Calls CreateExperiment.
                     * @function createExperiment
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateExperimentRequest} request CreateExperimentRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.CreateExperimentCallback} callback Node-style callback called with the error, if any, and CreateExperimentResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.createExperiment = function createExperiment(request, callback) {
                        return this.rpcCall(createExperiment, $root.palexy.sherlock.v1.CreateExperimentRequest, $root.palexy.sherlock.v1.CreateExperimentResponse, request, callback);
                    }, "name", { value: "CreateExperiment" });
    
                    /**
                     * Calls CreateExperiment.
                     * @function createExperiment
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateExperimentRequest} request CreateExperimentRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateExperimentResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#getExperiment}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef GetExperimentCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.GetExperimentResponse} [response] GetExperimentResponse
                     */
    
                    /**
                     * Calls GetExperiment.
                     * @function getExperiment
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IGetExperimentRequest} request GetExperimentRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.GetExperimentCallback} callback Node-style callback called with the error, if any, and GetExperimentResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.getExperiment = function getExperiment(request, callback) {
                        return this.rpcCall(getExperiment, $root.palexy.sherlock.v1.GetExperimentRequest, $root.palexy.sherlock.v1.GetExperimentResponse, request, callback);
                    }, "name", { value: "GetExperiment" });
    
                    /**
                     * Calls GetExperiment.
                     * @function getExperiment
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IGetExperimentRequest} request GetExperimentRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.GetExperimentResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#listExperiments}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef ListExperimentsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.ListExperimentsResponse} [response] ListExperimentsResponse
                     */
    
                    /**
                     * Calls ListExperiments.
                     * @function listExperiments
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IListExperimentsRequest} request ListExperimentsRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.ListExperimentsCallback} callback Node-style callback called with the error, if any, and ListExperimentsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.listExperiments = function listExperiments(request, callback) {
                        return this.rpcCall(listExperiments, $root.palexy.sherlock.v1.ListExperimentsRequest, $root.palexy.sherlock.v1.ListExperimentsResponse, request, callback);
                    }, "name", { value: "ListExperiments" });
    
                    /**
                     * Calls ListExperiments.
                     * @function listExperiments
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IListExperimentsRequest} request ListExperimentsRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.ListExperimentsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#updateExperiment}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef UpdateExperimentCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.UpdateExperimentResponse} [response] UpdateExperimentResponse
                     */
    
                    /**
                     * Calls UpdateExperiment.
                     * @function updateExperiment
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateExperimentRequest} request UpdateExperimentRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.UpdateExperimentCallback} callback Node-style callback called with the error, if any, and UpdateExperimentResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.updateExperiment = function updateExperiment(request, callback) {
                        return this.rpcCall(updateExperiment, $root.palexy.sherlock.v1.UpdateExperimentRequest, $root.palexy.sherlock.v1.UpdateExperimentResponse, request, callback);
                    }, "name", { value: "UpdateExperiment" });
    
                    /**
                     * Calls UpdateExperiment.
                     * @function updateExperiment
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateExperimentRequest} request UpdateExperimentRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.UpdateExperimentResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#listExperimentTestCases}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef ListExperimentTestCasesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.ListExperimentTestCasesResponse} [response] ListExperimentTestCasesResponse
                     */
    
                    /**
                     * Calls ListExperimentTestCases.
                     * @function listExperimentTestCases
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IListExperimentTestCasesRequest} request ListExperimentTestCasesRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.ListExperimentTestCasesCallback} callback Node-style callback called with the error, if any, and ListExperimentTestCasesResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.listExperimentTestCases = function listExperimentTestCases(request, callback) {
                        return this.rpcCall(listExperimentTestCases, $root.palexy.sherlock.v1.ListExperimentTestCasesRequest, $root.palexy.sherlock.v1.ListExperimentTestCasesResponse, request, callback);
                    }, "name", { value: "ListExperimentTestCases" });
    
                    /**
                     * Calls ListExperimentTestCases.
                     * @function listExperimentTestCases
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IListExperimentTestCasesRequest} request ListExperimentTestCasesRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.ListExperimentTestCasesResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#listTestCases}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef ListTestCasesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.ListTestCasesResponse} [response] ListTestCasesResponse
                     */
    
                    /**
                     * Calls ListTestCases.
                     * @function listTestCases
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IListTestCasesRequest} request ListTestCasesRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.ListTestCasesCallback} callback Node-style callback called with the error, if any, and ListTestCasesResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.listTestCases = function listTestCases(request, callback) {
                        return this.rpcCall(listTestCases, $root.palexy.sherlock.v1.ListTestCasesRequest, $root.palexy.sherlock.v1.ListTestCasesResponse, request, callback);
                    }, "name", { value: "ListTestCases" });
    
                    /**
                     * Calls ListTestCases.
                     * @function listTestCases
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IListTestCasesRequest} request ListTestCasesRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.ListTestCasesResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#updateExperimentTestCaseState}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef UpdateExperimentTestCaseStateCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse} [response] UpdateExperimentTestCaseStateResponse
                     */
    
                    /**
                     * Calls UpdateExperimentTestCaseState.
                     * @function updateExperimentTestCaseState
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateExperimentTestCaseStateRequest} request UpdateExperimentTestCaseStateRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.UpdateExperimentTestCaseStateCallback} callback Node-style callback called with the error, if any, and UpdateExperimentTestCaseStateResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.updateExperimentTestCaseState = function updateExperimentTestCaseState(request, callback) {
                        return this.rpcCall(updateExperimentTestCaseState, $root.palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest, $root.palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse, request, callback);
                    }, "name", { value: "UpdateExperimentTestCaseState" });
    
                    /**
                     * Calls UpdateExperimentTestCaseState.
                     * @function updateExperimentTestCaseState
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateExperimentTestCaseStateRequest} request UpdateExperimentTestCaseStateRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#createExperimentVideo}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef CreateExperimentVideoCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateExperimentVideoResponse} [response] CreateExperimentVideoResponse
                     */
    
                    /**
                     * Calls CreateExperimentVideo.
                     * @function createExperimentVideo
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateExperimentVideoRequest} request CreateExperimentVideoRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.CreateExperimentVideoCallback} callback Node-style callback called with the error, if any, and CreateExperimentVideoResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.createExperimentVideo = function createExperimentVideo(request, callback) {
                        return this.rpcCall(createExperimentVideo, $root.palexy.sherlock.v1.CreateExperimentVideoRequest, $root.palexy.sherlock.v1.CreateExperimentVideoResponse, request, callback);
                    }, "name", { value: "CreateExperimentVideo" });
    
                    /**
                     * Calls CreateExperimentVideo.
                     * @function createExperimentVideo
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateExperimentVideoRequest} request CreateExperimentVideoRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateExperimentVideoResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#fetchExperimentVideosReadyForMerging}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef FetchExperimentVideosReadyForMergingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse} [response] FetchExperimentVideosReadyForMergingResponse
                     */
    
                    /**
                     * Calls FetchExperimentVideosReadyForMerging.
                     * @function fetchExperimentVideosReadyForMerging
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosReadyForMergingRequest} request FetchExperimentVideosReadyForMergingRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.FetchExperimentVideosReadyForMergingCallback} callback Node-style callback called with the error, if any, and FetchExperimentVideosReadyForMergingResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.fetchExperimentVideosReadyForMerging = function fetchExperimentVideosReadyForMerging(request, callback) {
                        return this.rpcCall(fetchExperimentVideosReadyForMerging, $root.palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest, $root.palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse, request, callback);
                    }, "name", { value: "FetchExperimentVideosReadyForMerging" });
    
                    /**
                     * Calls FetchExperimentVideosReadyForMerging.
                     * @function fetchExperimentVideosReadyForMerging
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosReadyForMergingRequest} request FetchExperimentVideosReadyForMergingRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#fetchExperimentVideosDoneMerging}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef FetchExperimentVideosDoneMergingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse} [response] FetchExperimentVideosDoneMergingResponse
                     */
    
                    /**
                     * Calls FetchExperimentVideosDoneMerging.
                     * @function fetchExperimentVideosDoneMerging
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosDoneMergingRequest} request FetchExperimentVideosDoneMergingRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.FetchExperimentVideosDoneMergingCallback} callback Node-style callback called with the error, if any, and FetchExperimentVideosDoneMergingResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.fetchExperimentVideosDoneMerging = function fetchExperimentVideosDoneMerging(request, callback) {
                        return this.rpcCall(fetchExperimentVideosDoneMerging, $root.palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest, $root.palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse, request, callback);
                    }, "name", { value: "FetchExperimentVideosDoneMerging" });
    
                    /**
                     * Calls FetchExperimentVideosDoneMerging.
                     * @function fetchExperimentVideosDoneMerging
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosDoneMergingRequest} request FetchExperimentVideosDoneMergingRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#createTestSet}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef CreateTestSetCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateTestSetResponse} [response] CreateTestSetResponse
                     */
    
                    /**
                     * Calls CreateTestSet.
                     * @function createTestSet
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateTestSetRequest} request CreateTestSetRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.CreateTestSetCallback} callback Node-style callback called with the error, if any, and CreateTestSetResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.createTestSet = function createTestSet(request, callback) {
                        return this.rpcCall(createTestSet, $root.palexy.sherlock.v1.CreateTestSetRequest, $root.palexy.sherlock.v1.CreateTestSetResponse, request, callback);
                    }, "name", { value: "CreateTestSet" });
    
                    /**
                     * Calls CreateTestSet.
                     * @function createTestSet
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateTestSetRequest} request CreateTestSetRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateTestSetResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#listTestSets}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef ListTestSetsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.ListTestSetsResponse} [response] ListTestSetsResponse
                     */
    
                    /**
                     * Calls ListTestSets.
                     * @function listTestSets
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IListTestSetsRequest} request ListTestSetsRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.ListTestSetsCallback} callback Node-style callback called with the error, if any, and ListTestSetsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.listTestSets = function listTestSets(request, callback) {
                        return this.rpcCall(listTestSets, $root.palexy.sherlock.v1.ListTestSetsRequest, $root.palexy.sherlock.v1.ListTestSetsResponse, request, callback);
                    }, "name", { value: "ListTestSets" });
    
                    /**
                     * Calls ListTestSets.
                     * @function listTestSets
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IListTestSetsRequest} request ListTestSetsRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.ListTestSetsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#addTestCaseToTestSet}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef AddTestCaseToTestSetCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.AddTestCaseToTestSetResponse} [response] AddTestCaseToTestSetResponse
                     */
    
                    /**
                     * Calls AddTestCaseToTestSet.
                     * @function addTestCaseToTestSet
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IAddTestCaseToTestSetRequest} request AddTestCaseToTestSetRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.AddTestCaseToTestSetCallback} callback Node-style callback called with the error, if any, and AddTestCaseToTestSetResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.addTestCaseToTestSet = function addTestCaseToTestSet(request, callback) {
                        return this.rpcCall(addTestCaseToTestSet, $root.palexy.sherlock.v1.AddTestCaseToTestSetRequest, $root.palexy.sherlock.v1.AddTestCaseToTestSetResponse, request, callback);
                    }, "name", { value: "AddTestCaseToTestSet" });
    
                    /**
                     * Calls AddTestCaseToTestSet.
                     * @function addTestCaseToTestSet
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IAddTestCaseToTestSetRequest} request AddTestCaseToTestSetRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.AddTestCaseToTestSetResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.ExperimentService#removeTestCaseFromTestSet}.
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @typedef RemoveTestCaseFromTestSetCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse} [response] RemoveTestCaseFromTestSetResponse
                     */
    
                    /**
                     * Calls RemoveTestCaseFromTestSet.
                     * @function removeTestCaseFromTestSet
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IRemoveTestCaseFromTestSetRequest} request RemoveTestCaseFromTestSetRequest message or plain object
                     * @param {palexy.sherlock.v1.ExperimentService.RemoveTestCaseFromTestSetCallback} callback Node-style callback called with the error, if any, and RemoveTestCaseFromTestSetResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExperimentService.prototype.removeTestCaseFromTestSet = function removeTestCaseFromTestSet(request, callback) {
                        return this.rpcCall(removeTestCaseFromTestSet, $root.palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest, $root.palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse, request, callback);
                    }, "name", { value: "RemoveTestCaseFromTestSet" });
    
                    /**
                     * Calls RemoveTestCaseFromTestSet.
                     * @function removeTestCaseFromTestSet
                     * @memberof palexy.sherlock.v1.ExperimentService
                     * @instance
                     * @param {palexy.sherlock.v1.IRemoveTestCaseFromTestSetRequest} request RemoveTestCaseFromTestSetRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse>} Promise
                     * @variation 2
                     */
    
                    return ExperimentService;
                })();
    
                v1.BatchCreateTestCasesRequest = (function() {
    
                    /**
                     * Properties of a BatchCreateTestCasesRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IBatchCreateTestCasesRequest
                     * @property {Array.<number>|null} [qcFileIds] BatchCreateTestCasesRequest qcFileIds
                     * @property {boolean|null} [includeCurrentTrackingDataAsCache] BatchCreateTestCasesRequest includeCurrentTrackingDataAsCache
                     * @property {number|null} [resultCacheId] BatchCreateTestCasesRequest resultCacheId
                     * @property {string|null} [description] BatchCreateTestCasesRequest description
                     */
    
                    /**
                     * Constructs a new BatchCreateTestCasesRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BatchCreateTestCasesRequest.
                     * @implements IBatchCreateTestCasesRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IBatchCreateTestCasesRequest=} [properties] Properties to set
                     */
                    function BatchCreateTestCasesRequest(properties) {
                        this.qcFileIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateTestCasesRequest qcFileIds.
                     * @member {Array.<number>} qcFileIds
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @instance
                     */
                    BatchCreateTestCasesRequest.prototype.qcFileIds = $util.emptyArray;
    
                    /**
                     * BatchCreateTestCasesRequest includeCurrentTrackingDataAsCache.
                     * @member {boolean} includeCurrentTrackingDataAsCache
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @instance
                     */
                    BatchCreateTestCasesRequest.prototype.includeCurrentTrackingDataAsCache = false;
    
                    /**
                     * BatchCreateTestCasesRequest resultCacheId.
                     * @member {number} resultCacheId
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @instance
                     */
                    BatchCreateTestCasesRequest.prototype.resultCacheId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * BatchCreateTestCasesRequest description.
                     * @member {string} description
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @instance
                     */
                    BatchCreateTestCasesRequest.prototype.description = "";
    
                    /**
                     * Creates a new BatchCreateTestCasesRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTestCasesRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BatchCreateTestCasesRequest} BatchCreateTestCasesRequest instance
                     */
                    BatchCreateTestCasesRequest.create = function create(properties) {
                        return new BatchCreateTestCasesRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateTestCasesRequest message. Does not implicitly {@link palexy.sherlock.v1.BatchCreateTestCasesRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTestCasesRequest} message BatchCreateTestCasesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateTestCasesRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.qcFileIds != null && message.qcFileIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.qcFileIds.length; ++i)
                                writer.int64(message.qcFileIds[i]);
                            writer.ldelim();
                        }
                        if (message.includeCurrentTrackingDataAsCache != null && Object.hasOwnProperty.call(message, "includeCurrentTrackingDataAsCache"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeCurrentTrackingDataAsCache);
                        if (message.resultCacheId != null && Object.hasOwnProperty.call(message, "resultCacheId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.resultCacheId);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateTestCasesRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BatchCreateTestCasesRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTestCasesRequest} message BatchCreateTestCasesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateTestCasesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateTestCasesRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BatchCreateTestCasesRequest} BatchCreateTestCasesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateTestCasesRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BatchCreateTestCasesRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.qcFileIds && message.qcFileIds.length))
                                    message.qcFileIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.qcFileIds.push(reader.int64());
                                } else
                                    message.qcFileIds.push(reader.int64());
                                break;
                            case 2:
                                message.includeCurrentTrackingDataAsCache = reader.bool();
                                break;
                            case 3:
                                message.resultCacheId = reader.int64();
                                break;
                            case 4:
                                message.description = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateTestCasesRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BatchCreateTestCasesRequest} BatchCreateTestCasesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateTestCasesRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateTestCasesRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateTestCasesRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.qcFileIds != null && message.hasOwnProperty("qcFileIds")) {
                            if (!Array.isArray(message.qcFileIds))
                                return "qcFileIds: array expected";
                            for (var i = 0; i < message.qcFileIds.length; ++i)
                                if (!$util.isInteger(message.qcFileIds[i]) && !(message.qcFileIds[i] && $util.isInteger(message.qcFileIds[i].low) && $util.isInteger(message.qcFileIds[i].high)))
                                    return "qcFileIds: integer|Long[] expected";
                        }
                        if (message.includeCurrentTrackingDataAsCache != null && message.hasOwnProperty("includeCurrentTrackingDataAsCache"))
                            if (typeof message.includeCurrentTrackingDataAsCache !== "boolean")
                                return "includeCurrentTrackingDataAsCache: boolean expected";
                        if (message.resultCacheId != null && message.hasOwnProperty("resultCacheId"))
                            if (!$util.isInteger(message.resultCacheId) && !(message.resultCacheId && $util.isInteger(message.resultCacheId.low) && $util.isInteger(message.resultCacheId.high)))
                                return "resultCacheId: integer|Long expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateTestCasesRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BatchCreateTestCasesRequest} BatchCreateTestCasesRequest
                     */
                    BatchCreateTestCasesRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BatchCreateTestCasesRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BatchCreateTestCasesRequest();
                        if (object.qcFileIds) {
                            if (!Array.isArray(object.qcFileIds))
                                throw TypeError(".palexy.sherlock.v1.BatchCreateTestCasesRequest.qcFileIds: array expected");
                            message.qcFileIds = [];
                            for (var i = 0; i < object.qcFileIds.length; ++i)
                                if ($util.Long)
                                    (message.qcFileIds[i] = $util.Long.fromValue(object.qcFileIds[i])).unsigned = false;
                                else if (typeof object.qcFileIds[i] === "string")
                                    message.qcFileIds[i] = parseInt(object.qcFileIds[i], 10);
                                else if (typeof object.qcFileIds[i] === "number")
                                    message.qcFileIds[i] = object.qcFileIds[i];
                                else if (typeof object.qcFileIds[i] === "object")
                                    message.qcFileIds[i] = new $util.LongBits(object.qcFileIds[i].low >>> 0, object.qcFileIds[i].high >>> 0).toNumber();
                        }
                        if (object.includeCurrentTrackingDataAsCache != null)
                            message.includeCurrentTrackingDataAsCache = Boolean(object.includeCurrentTrackingDataAsCache);
                        if (object.resultCacheId != null)
                            if ($util.Long)
                                (message.resultCacheId = $util.Long.fromValue(object.resultCacheId)).unsigned = false;
                            else if (typeof object.resultCacheId === "string")
                                message.resultCacheId = parseInt(object.resultCacheId, 10);
                            else if (typeof object.resultCacheId === "number")
                                message.resultCacheId = object.resultCacheId;
                            else if (typeof object.resultCacheId === "object")
                                message.resultCacheId = new $util.LongBits(object.resultCacheId.low >>> 0, object.resultCacheId.high >>> 0).toNumber();
                        if (object.description != null)
                            message.description = String(object.description);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateTestCasesRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.BatchCreateTestCasesRequest} message BatchCreateTestCasesRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateTestCasesRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.qcFileIds = [];
                        if (options.defaults) {
                            object.includeCurrentTrackingDataAsCache = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.resultCacheId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.resultCacheId = options.longs === String ? "0" : 0;
                            object.description = "";
                        }
                        if (message.qcFileIds && message.qcFileIds.length) {
                            object.qcFileIds = [];
                            for (var j = 0; j < message.qcFileIds.length; ++j)
                                if (typeof message.qcFileIds[j] === "number")
                                    object.qcFileIds[j] = options.longs === String ? String(message.qcFileIds[j]) : message.qcFileIds[j];
                                else
                                    object.qcFileIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.qcFileIds[j]) : options.longs === Number ? new $util.LongBits(message.qcFileIds[j].low >>> 0, message.qcFileIds[j].high >>> 0).toNumber() : message.qcFileIds[j];
                        }
                        if (message.includeCurrentTrackingDataAsCache != null && message.hasOwnProperty("includeCurrentTrackingDataAsCache"))
                            object.includeCurrentTrackingDataAsCache = message.includeCurrentTrackingDataAsCache;
                        if (message.resultCacheId != null && message.hasOwnProperty("resultCacheId"))
                            if (typeof message.resultCacheId === "number")
                                object.resultCacheId = options.longs === String ? String(message.resultCacheId) : message.resultCacheId;
                            else
                                object.resultCacheId = options.longs === String ? $util.Long.prototype.toString.call(message.resultCacheId) : options.longs === Number ? new $util.LongBits(message.resultCacheId.low >>> 0, message.resultCacheId.high >>> 0).toNumber() : message.resultCacheId;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateTestCasesRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateTestCasesRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateTestCasesRequest;
                })();
    
                v1.BatchCreateTestCasesResponse = (function() {
    
                    /**
                     * Properties of a BatchCreateTestCasesResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IBatchCreateTestCasesResponse
                     * @property {Array.<palexy.sherlock.v1.IExperimentTestCase>|null} [testCases] BatchCreateTestCasesResponse testCases
                     * @property {Array.<string>|null} [failedMessages] BatchCreateTestCasesResponse failedMessages
                     */
    
                    /**
                     * Constructs a new BatchCreateTestCasesResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BatchCreateTestCasesResponse.
                     * @implements IBatchCreateTestCasesResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IBatchCreateTestCasesResponse=} [properties] Properties to set
                     */
                    function BatchCreateTestCasesResponse(properties) {
                        this.testCases = [];
                        this.failedMessages = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateTestCasesResponse testCases.
                     * @member {Array.<palexy.sherlock.v1.IExperimentTestCase>} testCases
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesResponse
                     * @instance
                     */
                    BatchCreateTestCasesResponse.prototype.testCases = $util.emptyArray;
    
                    /**
                     * BatchCreateTestCasesResponse failedMessages.
                     * @member {Array.<string>} failedMessages
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesResponse
                     * @instance
                     */
                    BatchCreateTestCasesResponse.prototype.failedMessages = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateTestCasesResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTestCasesResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BatchCreateTestCasesResponse} BatchCreateTestCasesResponse instance
                     */
                    BatchCreateTestCasesResponse.create = function create(properties) {
                        return new BatchCreateTestCasesResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateTestCasesResponse message. Does not implicitly {@link palexy.sherlock.v1.BatchCreateTestCasesResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTestCasesResponse} message BatchCreateTestCasesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateTestCasesResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.testCases != null && message.testCases.length)
                            for (var i = 0; i < message.testCases.length; ++i)
                                $root.palexy.sherlock.v1.ExperimentTestCase.encode(message.testCases[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.failedMessages != null && message.failedMessages.length)
                            for (var i = 0; i < message.failedMessages.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.failedMessages[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateTestCasesResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BatchCreateTestCasesResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateTestCasesResponse} message BatchCreateTestCasesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateTestCasesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateTestCasesResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BatchCreateTestCasesResponse} BatchCreateTestCasesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateTestCasesResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BatchCreateTestCasesResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.testCases && message.testCases.length))
                                    message.testCases = [];
                                message.testCases.push($root.palexy.sherlock.v1.ExperimentTestCase.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.failedMessages && message.failedMessages.length))
                                    message.failedMessages = [];
                                message.failedMessages.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateTestCasesResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BatchCreateTestCasesResponse} BatchCreateTestCasesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateTestCasesResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateTestCasesResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateTestCasesResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.testCases != null && message.hasOwnProperty("testCases")) {
                            if (!Array.isArray(message.testCases))
                                return "testCases: array expected";
                            for (var i = 0; i < message.testCases.length; ++i) {
                                var error = $root.palexy.sherlock.v1.ExperimentTestCase.verify(message.testCases[i]);
                                if (error)
                                    return "testCases." + error;
                            }
                        }
                        if (message.failedMessages != null && message.hasOwnProperty("failedMessages")) {
                            if (!Array.isArray(message.failedMessages))
                                return "failedMessages: array expected";
                            for (var i = 0; i < message.failedMessages.length; ++i)
                                if (!$util.isString(message.failedMessages[i]))
                                    return "failedMessages: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateTestCasesResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BatchCreateTestCasesResponse} BatchCreateTestCasesResponse
                     */
                    BatchCreateTestCasesResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BatchCreateTestCasesResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BatchCreateTestCasesResponse();
                        if (object.testCases) {
                            if (!Array.isArray(object.testCases))
                                throw TypeError(".palexy.sherlock.v1.BatchCreateTestCasesResponse.testCases: array expected");
                            message.testCases = [];
                            for (var i = 0; i < object.testCases.length; ++i) {
                                if (typeof object.testCases[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.BatchCreateTestCasesResponse.testCases: object expected");
                                message.testCases[i] = $root.palexy.sherlock.v1.ExperimentTestCase.fromObject(object.testCases[i]);
                            }
                        }
                        if (object.failedMessages) {
                            if (!Array.isArray(object.failedMessages))
                                throw TypeError(".palexy.sherlock.v1.BatchCreateTestCasesResponse.failedMessages: array expected");
                            message.failedMessages = [];
                            for (var i = 0; i < object.failedMessages.length; ++i)
                                message.failedMessages[i] = String(object.failedMessages[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateTestCasesResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.BatchCreateTestCasesResponse} message BatchCreateTestCasesResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateTestCasesResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.testCases = [];
                            object.failedMessages = [];
                        }
                        if (message.testCases && message.testCases.length) {
                            object.testCases = [];
                            for (var j = 0; j < message.testCases.length; ++j)
                                object.testCases[j] = $root.palexy.sherlock.v1.ExperimentTestCase.toObject(message.testCases[j], options);
                        }
                        if (message.failedMessages && message.failedMessages.length) {
                            object.failedMessages = [];
                            for (var j = 0; j < message.failedMessages.length; ++j)
                                object.failedMessages[j] = message.failedMessages[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateTestCasesResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BatchCreateTestCasesResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateTestCasesResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateTestCasesResponse;
                })();
    
                v1.ExperimentTestCase = (function() {
    
                    /**
                     * Properties of an ExperimentTestCase.
                     * @memberof palexy.sherlock.v1
                     * @interface IExperimentTestCase
                     * @property {number|null} [id] ExperimentTestCase id
                     * @property {string|null} [name] ExperimentTestCase name
                     * @property {number|null} [storeId] ExperimentTestCase storeId
                     * @property {string|null} [dateId] ExperimentTestCase dateId
                     * @property {palexy.sherlock.v1.ITestCaseVideos|null} [videos] ExperimentTestCase videos
                     * @property {number|null} [qcFileId] ExperimentTestCase qcFileId
                     * @property {number|null} [createTimeMs] ExperimentTestCase createTimeMs
                     * @property {string|null} [description] ExperimentTestCase description
                     */
    
                    /**
                     * Constructs a new ExperimentTestCase.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an ExperimentTestCase.
                     * @implements IExperimentTestCase
                     * @constructor
                     * @param {palexy.sherlock.v1.IExperimentTestCase=} [properties] Properties to set
                     */
                    function ExperimentTestCase(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExperimentTestCase id.
                     * @member {number} id
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @instance
                     */
                    ExperimentTestCase.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExperimentTestCase name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @instance
                     */
                    ExperimentTestCase.prototype.name = "";
    
                    /**
                     * ExperimentTestCase storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @instance
                     */
                    ExperimentTestCase.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExperimentTestCase dateId.
                     * @member {string} dateId
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @instance
                     */
                    ExperimentTestCase.prototype.dateId = "";
    
                    /**
                     * ExperimentTestCase videos.
                     * @member {palexy.sherlock.v1.ITestCaseVideos|null|undefined} videos
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @instance
                     */
                    ExperimentTestCase.prototype.videos = null;
    
                    /**
                     * ExperimentTestCase qcFileId.
                     * @member {number} qcFileId
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @instance
                     */
                    ExperimentTestCase.prototype.qcFileId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExperimentTestCase createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @instance
                     */
                    ExperimentTestCase.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExperimentTestCase description.
                     * @member {string} description
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @instance
                     */
                    ExperimentTestCase.prototype.description = "";
    
                    /**
                     * Creates a new ExperimentTestCase instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @static
                     * @param {palexy.sherlock.v1.IExperimentTestCase=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ExperimentTestCase} ExperimentTestCase instance
                     */
                    ExperimentTestCase.create = function create(properties) {
                        return new ExperimentTestCase(properties);
                    };
    
                    /**
                     * Encodes the specified ExperimentTestCase message. Does not implicitly {@link palexy.sherlock.v1.ExperimentTestCase.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @static
                     * @param {palexy.sherlock.v1.IExperimentTestCase} message ExperimentTestCase message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExperimentTestCase.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.dateId);
                        if (message.videos != null && Object.hasOwnProperty.call(message, "videos"))
                            $root.palexy.sherlock.v1.TestCaseVideos.encode(message.videos, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.qcFileId != null && Object.hasOwnProperty.call(message, "qcFileId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.qcFileId);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createTimeMs);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.description);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExperimentTestCase message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ExperimentTestCase.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @static
                     * @param {palexy.sherlock.v1.IExperimentTestCase} message ExperimentTestCase message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExperimentTestCase.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExperimentTestCase message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ExperimentTestCase} ExperimentTestCase
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExperimentTestCase.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ExperimentTestCase();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 4:
                                message.dateId = reader.string();
                                break;
                            case 5:
                                message.videos = $root.palexy.sherlock.v1.TestCaseVideos.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.qcFileId = reader.int64();
                                break;
                            case 7:
                                message.createTimeMs = reader.int64();
                                break;
                            case 8:
                                message.description = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExperimentTestCase message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ExperimentTestCase} ExperimentTestCase
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExperimentTestCase.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExperimentTestCase message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExperimentTestCase.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.videos != null && message.hasOwnProperty("videos")) {
                            var error = $root.palexy.sherlock.v1.TestCaseVideos.verify(message.videos);
                            if (error)
                                return "videos." + error;
                        }
                        if (message.qcFileId != null && message.hasOwnProperty("qcFileId"))
                            if (!$util.isInteger(message.qcFileId) && !(message.qcFileId && $util.isInteger(message.qcFileId.low) && $util.isInteger(message.qcFileId.high)))
                                return "qcFileId: integer|Long expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExperimentTestCase message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ExperimentTestCase} ExperimentTestCase
                     */
                    ExperimentTestCase.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ExperimentTestCase)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ExperimentTestCase();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.videos != null) {
                            if (typeof object.videos !== "object")
                                throw TypeError(".palexy.sherlock.v1.ExperimentTestCase.videos: object expected");
                            message.videos = $root.palexy.sherlock.v1.TestCaseVideos.fromObject(object.videos);
                        }
                        if (object.qcFileId != null)
                            if ($util.Long)
                                (message.qcFileId = $util.Long.fromValue(object.qcFileId)).unsigned = false;
                            else if (typeof object.qcFileId === "string")
                                message.qcFileId = parseInt(object.qcFileId, 10);
                            else if (typeof object.qcFileId === "number")
                                message.qcFileId = object.qcFileId;
                            else if (typeof object.qcFileId === "object")
                                message.qcFileId = new $util.LongBits(object.qcFileId.low >>> 0, object.qcFileId.high >>> 0).toNumber();
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.description != null)
                            message.description = String(object.description);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExperimentTestCase message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @static
                     * @param {palexy.sherlock.v1.ExperimentTestCase} message ExperimentTestCase
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExperimentTestCase.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.videos = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.qcFileId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.qcFileId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            object.description = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.videos != null && message.hasOwnProperty("videos"))
                            object.videos = $root.palexy.sherlock.v1.TestCaseVideos.toObject(message.videos, options);
                        if (message.qcFileId != null && message.hasOwnProperty("qcFileId"))
                            if (typeof message.qcFileId === "number")
                                object.qcFileId = options.longs === String ? String(message.qcFileId) : message.qcFileId;
                            else
                                object.qcFileId = options.longs === String ? $util.Long.prototype.toString.call(message.qcFileId) : options.longs === Number ? new $util.LongBits(message.qcFileId.low >>> 0, message.qcFileId.high >>> 0).toNumber() : message.qcFileId;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        return object;
                    };
    
                    /**
                     * Converts this ExperimentTestCase to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ExperimentTestCase
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExperimentTestCase.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExperimentTestCase;
                })();
    
                v1.TestCaseVideos = (function() {
    
                    /**
                     * Properties of a TestCaseVideos.
                     * @memberof palexy.sherlock.v1
                     * @interface ITestCaseVideos
                     * @property {Array.<palexy.streaming.v1.IVideo>|null} [videos] TestCaseVideos videos
                     */
    
                    /**
                     * Constructs a new TestCaseVideos.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a TestCaseVideos.
                     * @implements ITestCaseVideos
                     * @constructor
                     * @param {palexy.sherlock.v1.ITestCaseVideos=} [properties] Properties to set
                     */
                    function TestCaseVideos(properties) {
                        this.videos = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TestCaseVideos videos.
                     * @member {Array.<palexy.streaming.v1.IVideo>} videos
                     * @memberof palexy.sherlock.v1.TestCaseVideos
                     * @instance
                     */
                    TestCaseVideos.prototype.videos = $util.emptyArray;
    
                    /**
                     * Creates a new TestCaseVideos instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.TestCaseVideos
                     * @static
                     * @param {palexy.sherlock.v1.ITestCaseVideos=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.TestCaseVideos} TestCaseVideos instance
                     */
                    TestCaseVideos.create = function create(properties) {
                        return new TestCaseVideos(properties);
                    };
    
                    /**
                     * Encodes the specified TestCaseVideos message. Does not implicitly {@link palexy.sherlock.v1.TestCaseVideos.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.TestCaseVideos
                     * @static
                     * @param {palexy.sherlock.v1.ITestCaseVideos} message TestCaseVideos message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TestCaseVideos.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.videos != null && message.videos.length)
                            for (var i = 0; i < message.videos.length; ++i)
                                $root.palexy.streaming.v1.Video.encode(message.videos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TestCaseVideos message, length delimited. Does not implicitly {@link palexy.sherlock.v1.TestCaseVideos.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.TestCaseVideos
                     * @static
                     * @param {palexy.sherlock.v1.ITestCaseVideos} message TestCaseVideos message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TestCaseVideos.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TestCaseVideos message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.TestCaseVideos
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.TestCaseVideos} TestCaseVideos
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TestCaseVideos.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.TestCaseVideos();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.videos && message.videos.length))
                                    message.videos = [];
                                message.videos.push($root.palexy.streaming.v1.Video.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TestCaseVideos message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.TestCaseVideos
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.TestCaseVideos} TestCaseVideos
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TestCaseVideos.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TestCaseVideos message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.TestCaseVideos
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TestCaseVideos.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.videos != null && message.hasOwnProperty("videos")) {
                            if (!Array.isArray(message.videos))
                                return "videos: array expected";
                            for (var i = 0; i < message.videos.length; ++i) {
                                var error = $root.palexy.streaming.v1.Video.verify(message.videos[i]);
                                if (error)
                                    return "videos." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a TestCaseVideos message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.TestCaseVideos
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.TestCaseVideos} TestCaseVideos
                     */
                    TestCaseVideos.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.TestCaseVideos)
                            return object;
                        var message = new $root.palexy.sherlock.v1.TestCaseVideos();
                        if (object.videos) {
                            if (!Array.isArray(object.videos))
                                throw TypeError(".palexy.sherlock.v1.TestCaseVideos.videos: array expected");
                            message.videos = [];
                            for (var i = 0; i < object.videos.length; ++i) {
                                if (typeof object.videos[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.TestCaseVideos.videos: object expected");
                                message.videos[i] = $root.palexy.streaming.v1.Video.fromObject(object.videos[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TestCaseVideos message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.TestCaseVideos
                     * @static
                     * @param {palexy.sherlock.v1.TestCaseVideos} message TestCaseVideos
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TestCaseVideos.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.videos = [];
                        if (message.videos && message.videos.length) {
                            object.videos = [];
                            for (var j = 0; j < message.videos.length; ++j)
                                object.videos[j] = $root.palexy.streaming.v1.Video.toObject(message.videos[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this TestCaseVideos to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.TestCaseVideos
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TestCaseVideos.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TestCaseVideos;
                })();
    
                v1.TestSet = (function() {
    
                    /**
                     * Properties of a TestSet.
                     * @memberof palexy.sherlock.v1
                     * @interface ITestSet
                     * @property {number|null} [id] TestSet id
                     * @property {string|null} [name] TestSet name
                     * @property {string|null} [displayName] TestSet displayName
                     * @property {string|null} [description] TestSet description
                     * @property {number|null} [createTimeMs] TestSet createTimeMs
                     * @property {number|null} [updateTimeMs] TestSet updateTimeMs
                     */
    
                    /**
                     * Constructs a new TestSet.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a TestSet.
                     * @implements ITestSet
                     * @constructor
                     * @param {palexy.sherlock.v1.ITestSet=} [properties] Properties to set
                     */
                    function TestSet(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TestSet id.
                     * @member {number} id
                     * @memberof palexy.sherlock.v1.TestSet
                     * @instance
                     */
                    TestSet.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * TestSet name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.TestSet
                     * @instance
                     */
                    TestSet.prototype.name = "";
    
                    /**
                     * TestSet displayName.
                     * @member {string} displayName
                     * @memberof palexy.sherlock.v1.TestSet
                     * @instance
                     */
                    TestSet.prototype.displayName = "";
    
                    /**
                     * TestSet description.
                     * @member {string} description
                     * @memberof palexy.sherlock.v1.TestSet
                     * @instance
                     */
                    TestSet.prototype.description = "";
    
                    /**
                     * TestSet createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.sherlock.v1.TestSet
                     * @instance
                     */
                    TestSet.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * TestSet updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.sherlock.v1.TestSet
                     * @instance
                     */
                    TestSet.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new TestSet instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.TestSet
                     * @static
                     * @param {palexy.sherlock.v1.ITestSet=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.TestSet} TestSet instance
                     */
                    TestSet.create = function create(properties) {
                        return new TestSet(properties);
                    };
    
                    /**
                     * Encodes the specified TestSet message. Does not implicitly {@link palexy.sherlock.v1.TestSet.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.TestSet
                     * @static
                     * @param {palexy.sherlock.v1.ITestSet} message TestSet message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TestSet.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.displayName);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.updateTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TestSet message, length delimited. Does not implicitly {@link palexy.sherlock.v1.TestSet.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.TestSet
                     * @static
                     * @param {palexy.sherlock.v1.ITestSet} message TestSet message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TestSet.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TestSet message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.TestSet
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.TestSet} TestSet
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TestSet.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.TestSet();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.displayName = reader.string();
                                break;
                            case 4:
                                message.description = reader.string();
                                break;
                            case 5:
                                message.createTimeMs = reader.int64();
                                break;
                            case 6:
                                message.updateTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TestSet message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.TestSet
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.TestSet} TestSet
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TestSet.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TestSet message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.TestSet
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TestSet.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a TestSet message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.TestSet
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.TestSet} TestSet
                     */
                    TestSet.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.TestSet)
                            return object;
                        var message = new $root.palexy.sherlock.v1.TestSet();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TestSet message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.TestSet
                     * @static
                     * @param {palexy.sherlock.v1.TestSet} message TestSet
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TestSet.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.displayName = "";
                            object.description = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this TestSet to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.TestSet
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TestSet.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TestSet;
                })();
    
                v1.CreateTestSetRequest = (function() {
    
                    /**
                     * Properties of a CreateTestSetRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateTestSetRequest
                     * @property {palexy.sherlock.v1.ITestSet|null} [testSet] CreateTestSetRequest testSet
                     */
    
                    /**
                     * Constructs a new CreateTestSetRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateTestSetRequest.
                     * @implements ICreateTestSetRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateTestSetRequest=} [properties] Properties to set
                     */
                    function CreateTestSetRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateTestSetRequest testSet.
                     * @member {palexy.sherlock.v1.ITestSet|null|undefined} testSet
                     * @memberof palexy.sherlock.v1.CreateTestSetRequest
                     * @instance
                     */
                    CreateTestSetRequest.prototype.testSet = null;
    
                    /**
                     * Creates a new CreateTestSetRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTestSetRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateTestSetRequest} CreateTestSetRequest instance
                     */
                    CreateTestSetRequest.create = function create(properties) {
                        return new CreateTestSetRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateTestSetRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateTestSetRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTestSetRequest} message CreateTestSetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTestSetRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.testSet != null && Object.hasOwnProperty.call(message, "testSet"))
                            $root.palexy.sherlock.v1.TestSet.encode(message.testSet, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateTestSetRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateTestSetRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTestSetRequest} message CreateTestSetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTestSetRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateTestSetRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateTestSetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateTestSetRequest} CreateTestSetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTestSetRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateTestSetRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.testSet = $root.palexy.sherlock.v1.TestSet.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateTestSetRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTestSetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateTestSetRequest} CreateTestSetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTestSetRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateTestSetRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateTestSetRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateTestSetRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.testSet != null && message.hasOwnProperty("testSet")) {
                            var error = $root.palexy.sherlock.v1.TestSet.verify(message.testSet);
                            if (error)
                                return "testSet." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateTestSetRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateTestSetRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateTestSetRequest} CreateTestSetRequest
                     */
                    CreateTestSetRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateTestSetRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateTestSetRequest();
                        if (object.testSet != null) {
                            if (typeof object.testSet !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateTestSetRequest.testSet: object expected");
                            message.testSet = $root.palexy.sherlock.v1.TestSet.fromObject(object.testSet);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateTestSetRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateTestSetRequest} message CreateTestSetRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateTestSetRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.testSet = null;
                        if (message.testSet != null && message.hasOwnProperty("testSet"))
                            object.testSet = $root.palexy.sherlock.v1.TestSet.toObject(message.testSet, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateTestSetRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateTestSetRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateTestSetRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateTestSetRequest;
                })();
    
                v1.CreateTestSetResponse = (function() {
    
                    /**
                     * Properties of a CreateTestSetResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateTestSetResponse
                     * @property {palexy.sherlock.v1.ITestSet|null} [testSet] CreateTestSetResponse testSet
                     */
    
                    /**
                     * Constructs a new CreateTestSetResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateTestSetResponse.
                     * @implements ICreateTestSetResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateTestSetResponse=} [properties] Properties to set
                     */
                    function CreateTestSetResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateTestSetResponse testSet.
                     * @member {palexy.sherlock.v1.ITestSet|null|undefined} testSet
                     * @memberof palexy.sherlock.v1.CreateTestSetResponse
                     * @instance
                     */
                    CreateTestSetResponse.prototype.testSet = null;
    
                    /**
                     * Creates a new CreateTestSetResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTestSetResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateTestSetResponse} CreateTestSetResponse instance
                     */
                    CreateTestSetResponse.create = function create(properties) {
                        return new CreateTestSetResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateTestSetResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateTestSetResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTestSetResponse} message CreateTestSetResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTestSetResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.testSet != null && Object.hasOwnProperty.call(message, "testSet"))
                            $root.palexy.sherlock.v1.TestSet.encode(message.testSet, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateTestSetResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateTestSetResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateTestSetResponse} message CreateTestSetResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTestSetResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateTestSetResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateTestSetResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateTestSetResponse} CreateTestSetResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTestSetResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateTestSetResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.testSet = $root.palexy.sherlock.v1.TestSet.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateTestSetResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateTestSetResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateTestSetResponse} CreateTestSetResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTestSetResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateTestSetResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateTestSetResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateTestSetResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.testSet != null && message.hasOwnProperty("testSet")) {
                            var error = $root.palexy.sherlock.v1.TestSet.verify(message.testSet);
                            if (error)
                                return "testSet." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateTestSetResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateTestSetResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateTestSetResponse} CreateTestSetResponse
                     */
                    CreateTestSetResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateTestSetResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateTestSetResponse();
                        if (object.testSet != null) {
                            if (typeof object.testSet !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateTestSetResponse.testSet: object expected");
                            message.testSet = $root.palexy.sherlock.v1.TestSet.fromObject(object.testSet);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateTestSetResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateTestSetResponse} message CreateTestSetResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateTestSetResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.testSet = null;
                        if (message.testSet != null && message.hasOwnProperty("testSet"))
                            object.testSet = $root.palexy.sherlock.v1.TestSet.toObject(message.testSet, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateTestSetResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateTestSetResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateTestSetResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateTestSetResponse;
                })();
    
                v1.ListTestSetsRequest = (function() {
    
                    /**
                     * Properties of a ListTestSetsRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IListTestSetsRequest
                     * @property {string|null} [pageToken] ListTestSetsRequest pageToken
                     * @property {number|null} [pageSize] ListTestSetsRequest pageSize
                     */
    
                    /**
                     * Constructs a new ListTestSetsRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListTestSetsRequest.
                     * @implements IListTestSetsRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IListTestSetsRequest=} [properties] Properties to set
                     */
                    function ListTestSetsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListTestSetsRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.sherlock.v1.ListTestSetsRequest
                     * @instance
                     */
                    ListTestSetsRequest.prototype.pageToken = "";
    
                    /**
                     * ListTestSetsRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.sherlock.v1.ListTestSetsRequest
                     * @instance
                     */
                    ListTestSetsRequest.prototype.pageSize = 0;
    
                    /**
                     * Creates a new ListTestSetsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListTestSetsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTestSetsRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListTestSetsRequest} ListTestSetsRequest instance
                     */
                    ListTestSetsRequest.create = function create(properties) {
                        return new ListTestSetsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListTestSetsRequest message. Does not implicitly {@link palexy.sherlock.v1.ListTestSetsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListTestSetsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTestSetsRequest} message ListTestSetsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTestSetsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.pageToken);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageSize);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListTestSetsRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListTestSetsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListTestSetsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTestSetsRequest} message ListTestSetsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTestSetsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListTestSetsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListTestSetsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListTestSetsRequest} ListTestSetsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTestSetsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListTestSetsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.pageToken = reader.string();
                                break;
                            case 2:
                                message.pageSize = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListTestSetsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListTestSetsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListTestSetsRequest} ListTestSetsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTestSetsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListTestSetsRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListTestSetsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListTestSetsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListTestSetsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListTestSetsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListTestSetsRequest} ListTestSetsRequest
                     */
                    ListTestSetsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListTestSetsRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListTestSetsRequest();
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListTestSetsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListTestSetsRequest
                     * @static
                     * @param {palexy.sherlock.v1.ListTestSetsRequest} message ListTestSetsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListTestSetsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.pageToken = "";
                            object.pageSize = 0;
                        }
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        return object;
                    };
    
                    /**
                     * Converts this ListTestSetsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListTestSetsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListTestSetsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListTestSetsRequest;
                })();
    
                v1.ListTestSetsResponse = (function() {
    
                    /**
                     * Properties of a ListTestSetsResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IListTestSetsResponse
                     * @property {Array.<palexy.sherlock.v1.ITestSet>|null} [testSets] ListTestSetsResponse testSets
                     * @property {string|null} [nextPageToken] ListTestSetsResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListTestSetsResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListTestSetsResponse.
                     * @implements IListTestSetsResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IListTestSetsResponse=} [properties] Properties to set
                     */
                    function ListTestSetsResponse(properties) {
                        this.testSets = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListTestSetsResponse testSets.
                     * @member {Array.<palexy.sherlock.v1.ITestSet>} testSets
                     * @memberof palexy.sherlock.v1.ListTestSetsResponse
                     * @instance
                     */
                    ListTestSetsResponse.prototype.testSets = $util.emptyArray;
    
                    /**
                     * ListTestSetsResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.sherlock.v1.ListTestSetsResponse
                     * @instance
                     */
                    ListTestSetsResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListTestSetsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListTestSetsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTestSetsResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListTestSetsResponse} ListTestSetsResponse instance
                     */
                    ListTestSetsResponse.create = function create(properties) {
                        return new ListTestSetsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListTestSetsResponse message. Does not implicitly {@link palexy.sherlock.v1.ListTestSetsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListTestSetsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTestSetsResponse} message ListTestSetsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTestSetsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.testSets != null && message.testSets.length)
                            for (var i = 0; i < message.testSets.length; ++i)
                                $root.palexy.sherlock.v1.TestSet.encode(message.testSets[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListTestSetsResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListTestSetsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListTestSetsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTestSetsResponse} message ListTestSetsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTestSetsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListTestSetsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListTestSetsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListTestSetsResponse} ListTestSetsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTestSetsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListTestSetsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.testSets && message.testSets.length))
                                    message.testSets = [];
                                message.testSets.push($root.palexy.sherlock.v1.TestSet.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListTestSetsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListTestSetsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListTestSetsResponse} ListTestSetsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTestSetsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListTestSetsResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListTestSetsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListTestSetsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.testSets != null && message.hasOwnProperty("testSets")) {
                            if (!Array.isArray(message.testSets))
                                return "testSets: array expected";
                            for (var i = 0; i < message.testSets.length; ++i) {
                                var error = $root.palexy.sherlock.v1.TestSet.verify(message.testSets[i]);
                                if (error)
                                    return "testSets." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListTestSetsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListTestSetsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListTestSetsResponse} ListTestSetsResponse
                     */
                    ListTestSetsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListTestSetsResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListTestSetsResponse();
                        if (object.testSets) {
                            if (!Array.isArray(object.testSets))
                                throw TypeError(".palexy.sherlock.v1.ListTestSetsResponse.testSets: array expected");
                            message.testSets = [];
                            for (var i = 0; i < object.testSets.length; ++i) {
                                if (typeof object.testSets[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ListTestSetsResponse.testSets: object expected");
                                message.testSets[i] = $root.palexy.sherlock.v1.TestSet.fromObject(object.testSets[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListTestSetsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListTestSetsResponse
                     * @static
                     * @param {palexy.sherlock.v1.ListTestSetsResponse} message ListTestSetsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListTestSetsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.testSets = [];
                        if (options.defaults)
                            object.nextPageToken = "";
                        if (message.testSets && message.testSets.length) {
                            object.testSets = [];
                            for (var j = 0; j < message.testSets.length; ++j)
                                object.testSets[j] = $root.palexy.sherlock.v1.TestSet.toObject(message.testSets[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListTestSetsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListTestSetsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListTestSetsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListTestSetsResponse;
                })();
    
                v1.AddTestCaseToTestSetRequest = (function() {
    
                    /**
                     * Properties of an AddTestCaseToTestSetRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IAddTestCaseToTestSetRequest
                     * @property {string|null} [testSet] AddTestCaseToTestSetRequest testSet
                     * @property {number|null} [testCaseId] AddTestCaseToTestSetRequest testCaseId
                     */
    
                    /**
                     * Constructs a new AddTestCaseToTestSetRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an AddTestCaseToTestSetRequest.
                     * @implements IAddTestCaseToTestSetRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IAddTestCaseToTestSetRequest=} [properties] Properties to set
                     */
                    function AddTestCaseToTestSetRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AddTestCaseToTestSetRequest testSet.
                     * @member {string} testSet
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetRequest
                     * @instance
                     */
                    AddTestCaseToTestSetRequest.prototype.testSet = "";
    
                    /**
                     * AddTestCaseToTestSetRequest testCaseId.
                     * @member {number} testCaseId
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetRequest
                     * @instance
                     */
                    AddTestCaseToTestSetRequest.prototype.testCaseId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new AddTestCaseToTestSetRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.IAddTestCaseToTestSetRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.AddTestCaseToTestSetRequest} AddTestCaseToTestSetRequest instance
                     */
                    AddTestCaseToTestSetRequest.create = function create(properties) {
                        return new AddTestCaseToTestSetRequest(properties);
                    };
    
                    /**
                     * Encodes the specified AddTestCaseToTestSetRequest message. Does not implicitly {@link palexy.sherlock.v1.AddTestCaseToTestSetRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.IAddTestCaseToTestSetRequest} message AddTestCaseToTestSetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AddTestCaseToTestSetRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.testSet != null && Object.hasOwnProperty.call(message, "testSet"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.testSet);
                        if (message.testCaseId != null && Object.hasOwnProperty.call(message, "testCaseId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.testCaseId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AddTestCaseToTestSetRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.AddTestCaseToTestSetRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.IAddTestCaseToTestSetRequest} message AddTestCaseToTestSetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AddTestCaseToTestSetRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AddTestCaseToTestSetRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.AddTestCaseToTestSetRequest} AddTestCaseToTestSetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AddTestCaseToTestSetRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.AddTestCaseToTestSetRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.testSet = reader.string();
                                break;
                            case 2:
                                message.testCaseId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AddTestCaseToTestSetRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.AddTestCaseToTestSetRequest} AddTestCaseToTestSetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AddTestCaseToTestSetRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AddTestCaseToTestSetRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AddTestCaseToTestSetRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.testSet != null && message.hasOwnProperty("testSet"))
                            if (!$util.isString(message.testSet))
                                return "testSet: string expected";
                        if (message.testCaseId != null && message.hasOwnProperty("testCaseId"))
                            if (!$util.isInteger(message.testCaseId) && !(message.testCaseId && $util.isInteger(message.testCaseId.low) && $util.isInteger(message.testCaseId.high)))
                                return "testCaseId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an AddTestCaseToTestSetRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.AddTestCaseToTestSetRequest} AddTestCaseToTestSetRequest
                     */
                    AddTestCaseToTestSetRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.AddTestCaseToTestSetRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.AddTestCaseToTestSetRequest();
                        if (object.testSet != null)
                            message.testSet = String(object.testSet);
                        if (object.testCaseId != null)
                            if ($util.Long)
                                (message.testCaseId = $util.Long.fromValue(object.testCaseId)).unsigned = false;
                            else if (typeof object.testCaseId === "string")
                                message.testCaseId = parseInt(object.testCaseId, 10);
                            else if (typeof object.testCaseId === "number")
                                message.testCaseId = object.testCaseId;
                            else if (typeof object.testCaseId === "object")
                                message.testCaseId = new $util.LongBits(object.testCaseId.low >>> 0, object.testCaseId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AddTestCaseToTestSetRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.AddTestCaseToTestSetRequest} message AddTestCaseToTestSetRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AddTestCaseToTestSetRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.testSet = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.testCaseId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.testCaseId = options.longs === String ? "0" : 0;
                        }
                        if (message.testSet != null && message.hasOwnProperty("testSet"))
                            object.testSet = message.testSet;
                        if (message.testCaseId != null && message.hasOwnProperty("testCaseId"))
                            if (typeof message.testCaseId === "number")
                                object.testCaseId = options.longs === String ? String(message.testCaseId) : message.testCaseId;
                            else
                                object.testCaseId = options.longs === String ? $util.Long.prototype.toString.call(message.testCaseId) : options.longs === Number ? new $util.LongBits(message.testCaseId.low >>> 0, message.testCaseId.high >>> 0).toNumber() : message.testCaseId;
                        return object;
                    };
    
                    /**
                     * Converts this AddTestCaseToTestSetRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AddTestCaseToTestSetRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AddTestCaseToTestSetRequest;
                })();
    
                v1.AddTestCaseToTestSetResponse = (function() {
    
                    /**
                     * Properties of an AddTestCaseToTestSetResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IAddTestCaseToTestSetResponse
                     */
    
                    /**
                     * Constructs a new AddTestCaseToTestSetResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an AddTestCaseToTestSetResponse.
                     * @implements IAddTestCaseToTestSetResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IAddTestCaseToTestSetResponse=} [properties] Properties to set
                     */
                    function AddTestCaseToTestSetResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new AddTestCaseToTestSetResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.IAddTestCaseToTestSetResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.AddTestCaseToTestSetResponse} AddTestCaseToTestSetResponse instance
                     */
                    AddTestCaseToTestSetResponse.create = function create(properties) {
                        return new AddTestCaseToTestSetResponse(properties);
                    };
    
                    /**
                     * Encodes the specified AddTestCaseToTestSetResponse message. Does not implicitly {@link palexy.sherlock.v1.AddTestCaseToTestSetResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.IAddTestCaseToTestSetResponse} message AddTestCaseToTestSetResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AddTestCaseToTestSetResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AddTestCaseToTestSetResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.AddTestCaseToTestSetResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.IAddTestCaseToTestSetResponse} message AddTestCaseToTestSetResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AddTestCaseToTestSetResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AddTestCaseToTestSetResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.AddTestCaseToTestSetResponse} AddTestCaseToTestSetResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AddTestCaseToTestSetResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.AddTestCaseToTestSetResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AddTestCaseToTestSetResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.AddTestCaseToTestSetResponse} AddTestCaseToTestSetResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AddTestCaseToTestSetResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AddTestCaseToTestSetResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AddTestCaseToTestSetResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates an AddTestCaseToTestSetResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.AddTestCaseToTestSetResponse} AddTestCaseToTestSetResponse
                     */
                    AddTestCaseToTestSetResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.AddTestCaseToTestSetResponse)
                            return object;
                        return new $root.palexy.sherlock.v1.AddTestCaseToTestSetResponse();
                    };
    
                    /**
                     * Creates a plain object from an AddTestCaseToTestSetResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.AddTestCaseToTestSetResponse} message AddTestCaseToTestSetResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AddTestCaseToTestSetResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this AddTestCaseToTestSetResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.AddTestCaseToTestSetResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AddTestCaseToTestSetResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AddTestCaseToTestSetResponse;
                })();
    
                v1.RemoveTestCaseFromTestSetRequest = (function() {
    
                    /**
                     * Properties of a RemoveTestCaseFromTestSetRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IRemoveTestCaseFromTestSetRequest
                     * @property {string|null} [testSet] RemoveTestCaseFromTestSetRequest testSet
                     * @property {number|null} [testCaseId] RemoveTestCaseFromTestSetRequest testCaseId
                     */
    
                    /**
                     * Constructs a new RemoveTestCaseFromTestSetRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a RemoveTestCaseFromTestSetRequest.
                     * @implements IRemoveTestCaseFromTestSetRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IRemoveTestCaseFromTestSetRequest=} [properties] Properties to set
                     */
                    function RemoveTestCaseFromTestSetRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RemoveTestCaseFromTestSetRequest testSet.
                     * @member {string} testSet
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest
                     * @instance
                     */
                    RemoveTestCaseFromTestSetRequest.prototype.testSet = "";
    
                    /**
                     * RemoveTestCaseFromTestSetRequest testCaseId.
                     * @member {number} testCaseId
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest
                     * @instance
                     */
                    RemoveTestCaseFromTestSetRequest.prototype.testCaseId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new RemoveTestCaseFromTestSetRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.IRemoveTestCaseFromTestSetRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest} RemoveTestCaseFromTestSetRequest instance
                     */
                    RemoveTestCaseFromTestSetRequest.create = function create(properties) {
                        return new RemoveTestCaseFromTestSetRequest(properties);
                    };
    
                    /**
                     * Encodes the specified RemoveTestCaseFromTestSetRequest message. Does not implicitly {@link palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.IRemoveTestCaseFromTestSetRequest} message RemoveTestCaseFromTestSetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveTestCaseFromTestSetRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.testSet != null && Object.hasOwnProperty.call(message, "testSet"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.testSet);
                        if (message.testCaseId != null && Object.hasOwnProperty.call(message, "testCaseId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.testCaseId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RemoveTestCaseFromTestSetRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.IRemoveTestCaseFromTestSetRequest} message RemoveTestCaseFromTestSetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveTestCaseFromTestSetRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RemoveTestCaseFromTestSetRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest} RemoveTestCaseFromTestSetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveTestCaseFromTestSetRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.testSet = reader.string();
                                break;
                            case 2:
                                message.testCaseId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RemoveTestCaseFromTestSetRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest} RemoveTestCaseFromTestSetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveTestCaseFromTestSetRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RemoveTestCaseFromTestSetRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RemoveTestCaseFromTestSetRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.testSet != null && message.hasOwnProperty("testSet"))
                            if (!$util.isString(message.testSet))
                                return "testSet: string expected";
                        if (message.testCaseId != null && message.hasOwnProperty("testCaseId"))
                            if (!$util.isInteger(message.testCaseId) && !(message.testCaseId && $util.isInteger(message.testCaseId.low) && $util.isInteger(message.testCaseId.high)))
                                return "testCaseId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a RemoveTestCaseFromTestSetRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest} RemoveTestCaseFromTestSetRequest
                     */
                    RemoveTestCaseFromTestSetRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest();
                        if (object.testSet != null)
                            message.testSet = String(object.testSet);
                        if (object.testCaseId != null)
                            if ($util.Long)
                                (message.testCaseId = $util.Long.fromValue(object.testCaseId)).unsigned = false;
                            else if (typeof object.testCaseId === "string")
                                message.testCaseId = parseInt(object.testCaseId, 10);
                            else if (typeof object.testCaseId === "number")
                                message.testCaseId = object.testCaseId;
                            else if (typeof object.testCaseId === "object")
                                message.testCaseId = new $util.LongBits(object.testCaseId.low >>> 0, object.testCaseId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RemoveTestCaseFromTestSetRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest
                     * @static
                     * @param {palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest} message RemoveTestCaseFromTestSetRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RemoveTestCaseFromTestSetRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.testSet = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.testCaseId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.testCaseId = options.longs === String ? "0" : 0;
                        }
                        if (message.testSet != null && message.hasOwnProperty("testSet"))
                            object.testSet = message.testSet;
                        if (message.testCaseId != null && message.hasOwnProperty("testCaseId"))
                            if (typeof message.testCaseId === "number")
                                object.testCaseId = options.longs === String ? String(message.testCaseId) : message.testCaseId;
                            else
                                object.testCaseId = options.longs === String ? $util.Long.prototype.toString.call(message.testCaseId) : options.longs === Number ? new $util.LongBits(message.testCaseId.low >>> 0, message.testCaseId.high >>> 0).toNumber() : message.testCaseId;
                        return object;
                    };
    
                    /**
                     * Converts this RemoveTestCaseFromTestSetRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RemoveTestCaseFromTestSetRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RemoveTestCaseFromTestSetRequest;
                })();
    
                v1.RemoveTestCaseFromTestSetResponse = (function() {
    
                    /**
                     * Properties of a RemoveTestCaseFromTestSetResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IRemoveTestCaseFromTestSetResponse
                     */
    
                    /**
                     * Constructs a new RemoveTestCaseFromTestSetResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a RemoveTestCaseFromTestSetResponse.
                     * @implements IRemoveTestCaseFromTestSetResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IRemoveTestCaseFromTestSetResponse=} [properties] Properties to set
                     */
                    function RemoveTestCaseFromTestSetResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new RemoveTestCaseFromTestSetResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.IRemoveTestCaseFromTestSetResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse} RemoveTestCaseFromTestSetResponse instance
                     */
                    RemoveTestCaseFromTestSetResponse.create = function create(properties) {
                        return new RemoveTestCaseFromTestSetResponse(properties);
                    };
    
                    /**
                     * Encodes the specified RemoveTestCaseFromTestSetResponse message. Does not implicitly {@link palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.IRemoveTestCaseFromTestSetResponse} message RemoveTestCaseFromTestSetResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveTestCaseFromTestSetResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RemoveTestCaseFromTestSetResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.IRemoveTestCaseFromTestSetResponse} message RemoveTestCaseFromTestSetResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveTestCaseFromTestSetResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RemoveTestCaseFromTestSetResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse} RemoveTestCaseFromTestSetResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveTestCaseFromTestSetResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RemoveTestCaseFromTestSetResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse} RemoveTestCaseFromTestSetResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveTestCaseFromTestSetResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RemoveTestCaseFromTestSetResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RemoveTestCaseFromTestSetResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a RemoveTestCaseFromTestSetResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse} RemoveTestCaseFromTestSetResponse
                     */
                    RemoveTestCaseFromTestSetResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse)
                            return object;
                        return new $root.palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse();
                    };
    
                    /**
                     * Creates a plain object from a RemoveTestCaseFromTestSetResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse
                     * @static
                     * @param {palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse} message RemoveTestCaseFromTestSetResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RemoveTestCaseFromTestSetResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this RemoveTestCaseFromTestSetResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RemoveTestCaseFromTestSetResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RemoveTestCaseFromTestSetResponse;
                })();
    
                v1.ListTestCasesRequest = (function() {
    
                    /**
                     * Properties of a ListTestCasesRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IListTestCasesRequest
                     * @property {number|null} [testSetId] ListTestCasesRequest testSetId
                     * @property {string|null} [pageToken] ListTestCasesRequest pageToken
                     * @property {number|null} [pageSize] ListTestCasesRequest pageSize
                     */
    
                    /**
                     * Constructs a new ListTestCasesRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListTestCasesRequest.
                     * @implements IListTestCasesRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IListTestCasesRequest=} [properties] Properties to set
                     */
                    function ListTestCasesRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListTestCasesRequest testSetId.
                     * @member {number} testSetId
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @instance
                     */
                    ListTestCasesRequest.prototype.testSetId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListTestCasesRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @instance
                     */
                    ListTestCasesRequest.prototype.pageToken = "";
    
                    /**
                     * ListTestCasesRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @instance
                     */
                    ListTestCasesRequest.prototype.pageSize = 0;
    
                    /**
                     * Creates a new ListTestCasesRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTestCasesRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListTestCasesRequest} ListTestCasesRequest instance
                     */
                    ListTestCasesRequest.create = function create(properties) {
                        return new ListTestCasesRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListTestCasesRequest message. Does not implicitly {@link palexy.sherlock.v1.ListTestCasesRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTestCasesRequest} message ListTestCasesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTestCasesRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.testSetId != null && Object.hasOwnProperty.call(message, "testSetId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.testSetId);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pageToken);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListTestCasesRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListTestCasesRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListTestCasesRequest} message ListTestCasesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTestCasesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListTestCasesRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListTestCasesRequest} ListTestCasesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTestCasesRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListTestCasesRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.testSetId = reader.int64();
                                break;
                            case 2:
                                message.pageToken = reader.string();
                                break;
                            case 3:
                                message.pageSize = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListTestCasesRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListTestCasesRequest} ListTestCasesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTestCasesRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListTestCasesRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListTestCasesRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.testSetId != null && message.hasOwnProperty("testSetId"))
                            if (!$util.isInteger(message.testSetId) && !(message.testSetId && $util.isInteger(message.testSetId.low) && $util.isInteger(message.testSetId.high)))
                                return "testSetId: integer|Long expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListTestCasesRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListTestCasesRequest} ListTestCasesRequest
                     */
                    ListTestCasesRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListTestCasesRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListTestCasesRequest();
                        if (object.testSetId != null)
                            if ($util.Long)
                                (message.testSetId = $util.Long.fromValue(object.testSetId)).unsigned = false;
                            else if (typeof object.testSetId === "string")
                                message.testSetId = parseInt(object.testSetId, 10);
                            else if (typeof object.testSetId === "number")
                                message.testSetId = object.testSetId;
                            else if (typeof object.testSetId === "object")
                                message.testSetId = new $util.LongBits(object.testSetId.low >>> 0, object.testSetId.high >>> 0).toNumber();
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListTestCasesRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.ListTestCasesRequest} message ListTestCasesRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListTestCasesRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.testSetId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.testSetId = options.longs === String ? "0" : 0;
                            object.pageToken = "";
                            object.pageSize = 0;
                        }
                        if (message.testSetId != null && message.hasOwnProperty("testSetId"))
                            if (typeof message.testSetId === "number")
                                object.testSetId = options.longs === String ? String(message.testSetId) : message.testSetId;
                            else
                                object.testSetId = options.longs === String ? $util.Long.prototype.toString.call(message.testSetId) : options.longs === Number ? new $util.LongBits(message.testSetId.low >>> 0, message.testSetId.high >>> 0).toNumber() : message.testSetId;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        return object;
                    };
    
                    /**
                     * Converts this ListTestCasesRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListTestCasesRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListTestCasesRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListTestCasesRequest;
                })();
    
                v1.ListTestCasesResponse = (function() {
    
                    /**
                     * Properties of a ListTestCasesResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IListTestCasesResponse
                     * @property {Array.<palexy.sherlock.v1.IExperimentTestCase>|null} [testCases] ListTestCasesResponse testCases
                     * @property {string|null} [nextPageToken] ListTestCasesResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListTestCasesResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListTestCasesResponse.
                     * @implements IListTestCasesResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IListTestCasesResponse=} [properties] Properties to set
                     */
                    function ListTestCasesResponse(properties) {
                        this.testCases = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListTestCasesResponse testCases.
                     * @member {Array.<palexy.sherlock.v1.IExperimentTestCase>} testCases
                     * @memberof palexy.sherlock.v1.ListTestCasesResponse
                     * @instance
                     */
                    ListTestCasesResponse.prototype.testCases = $util.emptyArray;
    
                    /**
                     * ListTestCasesResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.sherlock.v1.ListTestCasesResponse
                     * @instance
                     */
                    ListTestCasesResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListTestCasesResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTestCasesResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListTestCasesResponse} ListTestCasesResponse instance
                     */
                    ListTestCasesResponse.create = function create(properties) {
                        return new ListTestCasesResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListTestCasesResponse message. Does not implicitly {@link palexy.sherlock.v1.ListTestCasesResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTestCasesResponse} message ListTestCasesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTestCasesResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.testCases != null && message.testCases.length)
                            for (var i = 0; i < message.testCases.length; ++i)
                                $root.palexy.sherlock.v1.ExperimentTestCase.encode(message.testCases[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListTestCasesResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListTestCasesResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListTestCasesResponse} message ListTestCasesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTestCasesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListTestCasesResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListTestCasesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListTestCasesResponse} ListTestCasesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTestCasesResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListTestCasesResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.testCases && message.testCases.length))
                                    message.testCases = [];
                                message.testCases.push($root.palexy.sherlock.v1.ExperimentTestCase.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListTestCasesResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListTestCasesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListTestCasesResponse} ListTestCasesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTestCasesResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListTestCasesResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListTestCasesResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListTestCasesResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.testCases != null && message.hasOwnProperty("testCases")) {
                            if (!Array.isArray(message.testCases))
                                return "testCases: array expected";
                            for (var i = 0; i < message.testCases.length; ++i) {
                                var error = $root.palexy.sherlock.v1.ExperimentTestCase.verify(message.testCases[i]);
                                if (error)
                                    return "testCases." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListTestCasesResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListTestCasesResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListTestCasesResponse} ListTestCasesResponse
                     */
                    ListTestCasesResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListTestCasesResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListTestCasesResponse();
                        if (object.testCases) {
                            if (!Array.isArray(object.testCases))
                                throw TypeError(".palexy.sherlock.v1.ListTestCasesResponse.testCases: array expected");
                            message.testCases = [];
                            for (var i = 0; i < object.testCases.length; ++i) {
                                if (typeof object.testCases[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ListTestCasesResponse.testCases: object expected");
                                message.testCases[i] = $root.palexy.sherlock.v1.ExperimentTestCase.fromObject(object.testCases[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListTestCasesResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.ListTestCasesResponse} message ListTestCasesResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListTestCasesResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.testCases = [];
                        if (options.defaults)
                            object.nextPageToken = "";
                        if (message.testCases && message.testCases.length) {
                            object.testCases = [];
                            for (var j = 0; j < message.testCases.length; ++j)
                                object.testCases[j] = $root.palexy.sherlock.v1.ExperimentTestCase.toObject(message.testCases[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListTestCasesResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListTestCasesResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListTestCasesResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListTestCasesResponse;
                })();
    
                v1.AddCacheItemRequest = (function() {
    
                    /**
                     * Properties of an AddCacheItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IAddCacheItemRequest
                     * @property {string|null} [cache] AddCacheItemRequest cache
                     * @property {palexy.sherlock.v1.IProcessingResultCacheItem|null} [cacheItem] AddCacheItemRequest cacheItem
                     * @property {Uint8Array|null} [fileContent] AddCacheItemRequest fileContent
                     */
    
                    /**
                     * Constructs a new AddCacheItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an AddCacheItemRequest.
                     * @implements IAddCacheItemRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IAddCacheItemRequest=} [properties] Properties to set
                     */
                    function AddCacheItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AddCacheItemRequest cache.
                     * @member {string} cache
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @instance
                     */
                    AddCacheItemRequest.prototype.cache = "";
    
                    /**
                     * AddCacheItemRequest cacheItem.
                     * @member {palexy.sherlock.v1.IProcessingResultCacheItem|null|undefined} cacheItem
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @instance
                     */
                    AddCacheItemRequest.prototype.cacheItem = null;
    
                    /**
                     * AddCacheItemRequest fileContent.
                     * @member {Uint8Array} fileContent
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @instance
                     */
                    AddCacheItemRequest.prototype.fileContent = $util.newBuffer([]);
    
                    /**
                     * Creates a new AddCacheItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.IAddCacheItemRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.AddCacheItemRequest} AddCacheItemRequest instance
                     */
                    AddCacheItemRequest.create = function create(properties) {
                        return new AddCacheItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified AddCacheItemRequest message. Does not implicitly {@link palexy.sherlock.v1.AddCacheItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.IAddCacheItemRequest} message AddCacheItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AddCacheItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cache != null && Object.hasOwnProperty.call(message, "cache"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cache);
                        if (message.cacheItem != null && Object.hasOwnProperty.call(message, "cacheItem"))
                            $root.palexy.sherlock.v1.ProcessingResultCacheItem.encode(message.cacheItem, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.fileContent != null && Object.hasOwnProperty.call(message, "fileContent"))
                            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.fileContent);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AddCacheItemRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.AddCacheItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.IAddCacheItemRequest} message AddCacheItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AddCacheItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AddCacheItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.AddCacheItemRequest} AddCacheItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AddCacheItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.AddCacheItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cache = reader.string();
                                break;
                            case 2:
                                message.cacheItem = $root.palexy.sherlock.v1.ProcessingResultCacheItem.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.fileContent = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AddCacheItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.AddCacheItemRequest} AddCacheItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AddCacheItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AddCacheItemRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AddCacheItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cache != null && message.hasOwnProperty("cache"))
                            if (!$util.isString(message.cache))
                                return "cache: string expected";
                        if (message.cacheItem != null && message.hasOwnProperty("cacheItem")) {
                            var error = $root.palexy.sherlock.v1.ProcessingResultCacheItem.verify(message.cacheItem);
                            if (error)
                                return "cacheItem." + error;
                        }
                        if (message.fileContent != null && message.hasOwnProperty("fileContent"))
                            if (!(message.fileContent && typeof message.fileContent.length === "number" || $util.isString(message.fileContent)))
                                return "fileContent: buffer expected";
                        return null;
                    };
    
                    /**
                     * Creates an AddCacheItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.AddCacheItemRequest} AddCacheItemRequest
                     */
                    AddCacheItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.AddCacheItemRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.AddCacheItemRequest();
                        if (object.cache != null)
                            message.cache = String(object.cache);
                        if (object.cacheItem != null) {
                            if (typeof object.cacheItem !== "object")
                                throw TypeError(".palexy.sherlock.v1.AddCacheItemRequest.cacheItem: object expected");
                            message.cacheItem = $root.palexy.sherlock.v1.ProcessingResultCacheItem.fromObject(object.cacheItem);
                        }
                        if (object.fileContent != null)
                            if (typeof object.fileContent === "string")
                                $util.base64.decode(object.fileContent, message.fileContent = $util.newBuffer($util.base64.length(object.fileContent)), 0);
                            else if (object.fileContent.length)
                                message.fileContent = object.fileContent;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AddCacheItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.AddCacheItemRequest} message AddCacheItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AddCacheItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.cache = "";
                            object.cacheItem = null;
                            if (options.bytes === String)
                                object.fileContent = "";
                            else {
                                object.fileContent = [];
                                if (options.bytes !== Array)
                                    object.fileContent = $util.newBuffer(object.fileContent);
                            }
                        }
                        if (message.cache != null && message.hasOwnProperty("cache"))
                            object.cache = message.cache;
                        if (message.cacheItem != null && message.hasOwnProperty("cacheItem"))
                            object.cacheItem = $root.palexy.sherlock.v1.ProcessingResultCacheItem.toObject(message.cacheItem, options);
                        if (message.fileContent != null && message.hasOwnProperty("fileContent"))
                            object.fileContent = options.bytes === String ? $util.base64.encode(message.fileContent, 0, message.fileContent.length) : options.bytes === Array ? Array.prototype.slice.call(message.fileContent) : message.fileContent;
                        return object;
                    };
    
                    /**
                     * Converts this AddCacheItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.AddCacheItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AddCacheItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AddCacheItemRequest;
                })();
    
                v1.AddCacheItemResponse = (function() {
    
                    /**
                     * Properties of an AddCacheItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IAddCacheItemResponse
                     * @property {palexy.sherlock.v1.IProcessingResultCacheItem|null} [cacheItem] AddCacheItemResponse cacheItem
                     */
    
                    /**
                     * Constructs a new AddCacheItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an AddCacheItemResponse.
                     * @implements IAddCacheItemResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IAddCacheItemResponse=} [properties] Properties to set
                     */
                    function AddCacheItemResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AddCacheItemResponse cacheItem.
                     * @member {palexy.sherlock.v1.IProcessingResultCacheItem|null|undefined} cacheItem
                     * @memberof palexy.sherlock.v1.AddCacheItemResponse
                     * @instance
                     */
                    AddCacheItemResponse.prototype.cacheItem = null;
    
                    /**
                     * Creates a new AddCacheItemResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.AddCacheItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.IAddCacheItemResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.AddCacheItemResponse} AddCacheItemResponse instance
                     */
                    AddCacheItemResponse.create = function create(properties) {
                        return new AddCacheItemResponse(properties);
                    };
    
                    /**
                     * Encodes the specified AddCacheItemResponse message. Does not implicitly {@link palexy.sherlock.v1.AddCacheItemResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.AddCacheItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.IAddCacheItemResponse} message AddCacheItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AddCacheItemResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cacheItem != null && Object.hasOwnProperty.call(message, "cacheItem"))
                            $root.palexy.sherlock.v1.ProcessingResultCacheItem.encode(message.cacheItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AddCacheItemResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.AddCacheItemResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.AddCacheItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.IAddCacheItemResponse} message AddCacheItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AddCacheItemResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AddCacheItemResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.AddCacheItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.AddCacheItemResponse} AddCacheItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AddCacheItemResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.AddCacheItemResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cacheItem = $root.palexy.sherlock.v1.ProcessingResultCacheItem.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AddCacheItemResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.AddCacheItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.AddCacheItemResponse} AddCacheItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AddCacheItemResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AddCacheItemResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.AddCacheItemResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AddCacheItemResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cacheItem != null && message.hasOwnProperty("cacheItem")) {
                            var error = $root.palexy.sherlock.v1.ProcessingResultCacheItem.verify(message.cacheItem);
                            if (error)
                                return "cacheItem." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an AddCacheItemResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.AddCacheItemResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.AddCacheItemResponse} AddCacheItemResponse
                     */
                    AddCacheItemResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.AddCacheItemResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.AddCacheItemResponse();
                        if (object.cacheItem != null) {
                            if (typeof object.cacheItem !== "object")
                                throw TypeError(".palexy.sherlock.v1.AddCacheItemResponse.cacheItem: object expected");
                            message.cacheItem = $root.palexy.sherlock.v1.ProcessingResultCacheItem.fromObject(object.cacheItem);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AddCacheItemResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.AddCacheItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.AddCacheItemResponse} message AddCacheItemResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AddCacheItemResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.cacheItem = null;
                        if (message.cacheItem != null && message.hasOwnProperty("cacheItem"))
                            object.cacheItem = $root.palexy.sherlock.v1.ProcessingResultCacheItem.toObject(message.cacheItem, options);
                        return object;
                    };
    
                    /**
                     * Converts this AddCacheItemResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.AddCacheItemResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AddCacheItemResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AddCacheItemResponse;
                })();
    
                v1.CreateCacheRequest = (function() {
    
                    /**
                     * Properties of a CreateCacheRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateCacheRequest
                     * @property {palexy.sherlock.v1.IProcessingResultCache|null} [cache] CreateCacheRequest cache
                     */
    
                    /**
                     * Constructs a new CreateCacheRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateCacheRequest.
                     * @implements ICreateCacheRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateCacheRequest=} [properties] Properties to set
                     */
                    function CreateCacheRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateCacheRequest cache.
                     * @member {palexy.sherlock.v1.IProcessingResultCache|null|undefined} cache
                     * @memberof palexy.sherlock.v1.CreateCacheRequest
                     * @instance
                     */
                    CreateCacheRequest.prototype.cache = null;
    
                    /**
                     * Creates a new CreateCacheRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateCacheRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateCacheRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateCacheRequest} CreateCacheRequest instance
                     */
                    CreateCacheRequest.create = function create(properties) {
                        return new CreateCacheRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateCacheRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateCacheRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateCacheRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateCacheRequest} message CreateCacheRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateCacheRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cache != null && Object.hasOwnProperty.call(message, "cache"))
                            $root.palexy.sherlock.v1.ProcessingResultCache.encode(message.cache, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateCacheRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateCacheRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateCacheRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateCacheRequest} message CreateCacheRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateCacheRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateCacheRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateCacheRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateCacheRequest} CreateCacheRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateCacheRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateCacheRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cache = $root.palexy.sherlock.v1.ProcessingResultCache.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateCacheRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateCacheRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateCacheRequest} CreateCacheRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateCacheRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateCacheRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateCacheRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateCacheRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cache != null && message.hasOwnProperty("cache")) {
                            var error = $root.palexy.sherlock.v1.ProcessingResultCache.verify(message.cache);
                            if (error)
                                return "cache." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateCacheRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateCacheRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateCacheRequest} CreateCacheRequest
                     */
                    CreateCacheRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateCacheRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateCacheRequest();
                        if (object.cache != null) {
                            if (typeof object.cache !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateCacheRequest.cache: object expected");
                            message.cache = $root.palexy.sherlock.v1.ProcessingResultCache.fromObject(object.cache);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateCacheRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateCacheRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateCacheRequest} message CreateCacheRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateCacheRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.cache = null;
                        if (message.cache != null && message.hasOwnProperty("cache"))
                            object.cache = $root.palexy.sherlock.v1.ProcessingResultCache.toObject(message.cache, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateCacheRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateCacheRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateCacheRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateCacheRequest;
                })();
    
                v1.CreateCacheResponse = (function() {
    
                    /**
                     * Properties of a CreateCacheResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateCacheResponse
                     * @property {palexy.sherlock.v1.IProcessingResultCache|null} [cache] CreateCacheResponse cache
                     */
    
                    /**
                     * Constructs a new CreateCacheResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateCacheResponse.
                     * @implements ICreateCacheResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateCacheResponse=} [properties] Properties to set
                     */
                    function CreateCacheResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateCacheResponse cache.
                     * @member {palexy.sherlock.v1.IProcessingResultCache|null|undefined} cache
                     * @memberof palexy.sherlock.v1.CreateCacheResponse
                     * @instance
                     */
                    CreateCacheResponse.prototype.cache = null;
    
                    /**
                     * Creates a new CreateCacheResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateCacheResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateCacheResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateCacheResponse} CreateCacheResponse instance
                     */
                    CreateCacheResponse.create = function create(properties) {
                        return new CreateCacheResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateCacheResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateCacheResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateCacheResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateCacheResponse} message CreateCacheResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateCacheResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cache != null && Object.hasOwnProperty.call(message, "cache"))
                            $root.palexy.sherlock.v1.ProcessingResultCache.encode(message.cache, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateCacheResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateCacheResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateCacheResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateCacheResponse} message CreateCacheResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateCacheResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateCacheResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateCacheResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateCacheResponse} CreateCacheResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateCacheResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateCacheResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cache = $root.palexy.sherlock.v1.ProcessingResultCache.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateCacheResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateCacheResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateCacheResponse} CreateCacheResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateCacheResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateCacheResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateCacheResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateCacheResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cache != null && message.hasOwnProperty("cache")) {
                            var error = $root.palexy.sherlock.v1.ProcessingResultCache.verify(message.cache);
                            if (error)
                                return "cache." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateCacheResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateCacheResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateCacheResponse} CreateCacheResponse
                     */
                    CreateCacheResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateCacheResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateCacheResponse();
                        if (object.cache != null) {
                            if (typeof object.cache !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateCacheResponse.cache: object expected");
                            message.cache = $root.palexy.sherlock.v1.ProcessingResultCache.fromObject(object.cache);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateCacheResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateCacheResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateCacheResponse} message CreateCacheResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateCacheResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.cache = null;
                        if (message.cache != null && message.hasOwnProperty("cache"))
                            object.cache = $root.palexy.sherlock.v1.ProcessingResultCache.toObject(message.cache, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateCacheResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateCacheResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateCacheResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateCacheResponse;
                })();
    
                v1.ListCachesRequest = (function() {
    
                    /**
                     * Properties of a ListCachesRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IListCachesRequest
                     * @property {string|null} [pageToken] ListCachesRequest pageToken
                     * @property {number|null} [pageSize] ListCachesRequest pageSize
                     */
    
                    /**
                     * Constructs a new ListCachesRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListCachesRequest.
                     * @implements IListCachesRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IListCachesRequest=} [properties] Properties to set
                     */
                    function ListCachesRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCachesRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.sherlock.v1.ListCachesRequest
                     * @instance
                     */
                    ListCachesRequest.prototype.pageToken = "";
    
                    /**
                     * ListCachesRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.sherlock.v1.ListCachesRequest
                     * @instance
                     */
                    ListCachesRequest.prototype.pageSize = 0;
    
                    /**
                     * Creates a new ListCachesRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListCachesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListCachesRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListCachesRequest} ListCachesRequest instance
                     */
                    ListCachesRequest.create = function create(properties) {
                        return new ListCachesRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListCachesRequest message. Does not implicitly {@link palexy.sherlock.v1.ListCachesRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListCachesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListCachesRequest} message ListCachesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCachesRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.pageToken);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageSize);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCachesRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListCachesRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListCachesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListCachesRequest} message ListCachesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCachesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCachesRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListCachesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListCachesRequest} ListCachesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCachesRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListCachesRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.pageToken = reader.string();
                                break;
                            case 2:
                                message.pageSize = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCachesRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListCachesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListCachesRequest} ListCachesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCachesRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCachesRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListCachesRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCachesRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListCachesRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListCachesRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListCachesRequest} ListCachesRequest
                     */
                    ListCachesRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListCachesRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListCachesRequest();
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCachesRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListCachesRequest
                     * @static
                     * @param {palexy.sherlock.v1.ListCachesRequest} message ListCachesRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCachesRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.pageToken = "";
                            object.pageSize = 0;
                        }
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        return object;
                    };
    
                    /**
                     * Converts this ListCachesRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListCachesRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCachesRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCachesRequest;
                })();
    
                v1.ListCachesResponse = (function() {
    
                    /**
                     * Properties of a ListCachesResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IListCachesResponse
                     * @property {Array.<palexy.sherlock.v1.IProcessingResultCache>|null} [caches] ListCachesResponse caches
                     * @property {string|null} [nextPageToken] ListCachesResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListCachesResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListCachesResponse.
                     * @implements IListCachesResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IListCachesResponse=} [properties] Properties to set
                     */
                    function ListCachesResponse(properties) {
                        this.caches = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCachesResponse caches.
                     * @member {Array.<palexy.sherlock.v1.IProcessingResultCache>} caches
                     * @memberof palexy.sherlock.v1.ListCachesResponse
                     * @instance
                     */
                    ListCachesResponse.prototype.caches = $util.emptyArray;
    
                    /**
                     * ListCachesResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.sherlock.v1.ListCachesResponse
                     * @instance
                     */
                    ListCachesResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListCachesResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListCachesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListCachesResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListCachesResponse} ListCachesResponse instance
                     */
                    ListCachesResponse.create = function create(properties) {
                        return new ListCachesResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListCachesResponse message. Does not implicitly {@link palexy.sherlock.v1.ListCachesResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListCachesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListCachesResponse} message ListCachesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCachesResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.caches != null && message.caches.length)
                            for (var i = 0; i < message.caches.length; ++i)
                                $root.palexy.sherlock.v1.ProcessingResultCache.encode(message.caches[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCachesResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListCachesResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListCachesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListCachesResponse} message ListCachesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCachesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCachesResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListCachesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListCachesResponse} ListCachesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCachesResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListCachesResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.caches && message.caches.length))
                                    message.caches = [];
                                message.caches.push($root.palexy.sherlock.v1.ProcessingResultCache.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCachesResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListCachesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListCachesResponse} ListCachesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCachesResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCachesResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListCachesResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCachesResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.caches != null && message.hasOwnProperty("caches")) {
                            if (!Array.isArray(message.caches))
                                return "caches: array expected";
                            for (var i = 0; i < message.caches.length; ++i) {
                                var error = $root.palexy.sherlock.v1.ProcessingResultCache.verify(message.caches[i]);
                                if (error)
                                    return "caches." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListCachesResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListCachesResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListCachesResponse} ListCachesResponse
                     */
                    ListCachesResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListCachesResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListCachesResponse();
                        if (object.caches) {
                            if (!Array.isArray(object.caches))
                                throw TypeError(".palexy.sherlock.v1.ListCachesResponse.caches: array expected");
                            message.caches = [];
                            for (var i = 0; i < object.caches.length; ++i) {
                                if (typeof object.caches[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ListCachesResponse.caches: object expected");
                                message.caches[i] = $root.palexy.sherlock.v1.ProcessingResultCache.fromObject(object.caches[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCachesResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListCachesResponse
                     * @static
                     * @param {palexy.sherlock.v1.ListCachesResponse} message ListCachesResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCachesResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.caches = [];
                        if (options.defaults)
                            object.nextPageToken = "";
                        if (message.caches && message.caches.length) {
                            object.caches = [];
                            for (var j = 0; j < message.caches.length; ++j)
                                object.caches[j] = $root.palexy.sherlock.v1.ProcessingResultCache.toObject(message.caches[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListCachesResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListCachesResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCachesResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCachesResponse;
                })();
    
                v1.ProcessingResultCache = (function() {
    
                    /**
                     * Properties of a ProcessingResultCache.
                     * @memberof palexy.sherlock.v1
                     * @interface IProcessingResultCache
                     * @property {number|null} [id] ProcessingResultCache id
                     * @property {string|null} [name] ProcessingResultCache name
                     * @property {string|null} [displayName] ProcessingResultCache displayName
                     * @property {string|null} [cacheAgeGroupGenderVersion] ProcessingResultCache cacheAgeGroupGenderVersion
                     * @property {string|null} [cacheYoloVersion] ProcessingResultCache cacheYoloVersion
                     * @property {string|null} [cacheReidVersion] ProcessingResultCache cacheReidVersion
                     * @property {number|null} [createTimeMs] ProcessingResultCache createTimeMs
                     */
    
                    /**
                     * Constructs a new ProcessingResultCache.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ProcessingResultCache.
                     * @implements IProcessingResultCache
                     * @constructor
                     * @param {palexy.sherlock.v1.IProcessingResultCache=} [properties] Properties to set
                     */
                    function ProcessingResultCache(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ProcessingResultCache id.
                     * @member {number} id
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @instance
                     */
                    ProcessingResultCache.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ProcessingResultCache name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @instance
                     */
                    ProcessingResultCache.prototype.name = "";
    
                    /**
                     * ProcessingResultCache displayName.
                     * @member {string} displayName
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @instance
                     */
                    ProcessingResultCache.prototype.displayName = "";
    
                    /**
                     * ProcessingResultCache cacheAgeGroupGenderVersion.
                     * @member {string} cacheAgeGroupGenderVersion
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @instance
                     */
                    ProcessingResultCache.prototype.cacheAgeGroupGenderVersion = "";
    
                    /**
                     * ProcessingResultCache cacheYoloVersion.
                     * @member {string} cacheYoloVersion
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @instance
                     */
                    ProcessingResultCache.prototype.cacheYoloVersion = "";
    
                    /**
                     * ProcessingResultCache cacheReidVersion.
                     * @member {string} cacheReidVersion
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @instance
                     */
                    ProcessingResultCache.prototype.cacheReidVersion = "";
    
                    /**
                     * ProcessingResultCache createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @instance
                     */
                    ProcessingResultCache.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ProcessingResultCache instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @static
                     * @param {palexy.sherlock.v1.IProcessingResultCache=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ProcessingResultCache} ProcessingResultCache instance
                     */
                    ProcessingResultCache.create = function create(properties) {
                        return new ProcessingResultCache(properties);
                    };
    
                    /**
                     * Encodes the specified ProcessingResultCache message. Does not implicitly {@link palexy.sherlock.v1.ProcessingResultCache.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @static
                     * @param {palexy.sherlock.v1.IProcessingResultCache} message ProcessingResultCache message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProcessingResultCache.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.displayName);
                        if (message.cacheAgeGroupGenderVersion != null && Object.hasOwnProperty.call(message, "cacheAgeGroupGenderVersion"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.cacheAgeGroupGenderVersion);
                        if (message.cacheYoloVersion != null && Object.hasOwnProperty.call(message, "cacheYoloVersion"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.cacheYoloVersion);
                        if (message.cacheReidVersion != null && Object.hasOwnProperty.call(message, "cacheReidVersion"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.cacheReidVersion);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ProcessingResultCache message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ProcessingResultCache.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @static
                     * @param {palexy.sherlock.v1.IProcessingResultCache} message ProcessingResultCache message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProcessingResultCache.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ProcessingResultCache message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ProcessingResultCache} ProcessingResultCache
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProcessingResultCache.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ProcessingResultCache();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.displayName = reader.string();
                                break;
                            case 4:
                                message.cacheAgeGroupGenderVersion = reader.string();
                                break;
                            case 5:
                                message.cacheYoloVersion = reader.string();
                                break;
                            case 6:
                                message.cacheReidVersion = reader.string();
                                break;
                            case 7:
                                message.createTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ProcessingResultCache message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ProcessingResultCache} ProcessingResultCache
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProcessingResultCache.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ProcessingResultCache message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProcessingResultCache.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        if (message.cacheAgeGroupGenderVersion != null && message.hasOwnProperty("cacheAgeGroupGenderVersion"))
                            if (!$util.isString(message.cacheAgeGroupGenderVersion))
                                return "cacheAgeGroupGenderVersion: string expected";
                        if (message.cacheYoloVersion != null && message.hasOwnProperty("cacheYoloVersion"))
                            if (!$util.isString(message.cacheYoloVersion))
                                return "cacheYoloVersion: string expected";
                        if (message.cacheReidVersion != null && message.hasOwnProperty("cacheReidVersion"))
                            if (!$util.isString(message.cacheReidVersion))
                                return "cacheReidVersion: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ProcessingResultCache message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ProcessingResultCache} ProcessingResultCache
                     */
                    ProcessingResultCache.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ProcessingResultCache)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ProcessingResultCache();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        if (object.cacheAgeGroupGenderVersion != null)
                            message.cacheAgeGroupGenderVersion = String(object.cacheAgeGroupGenderVersion);
                        if (object.cacheYoloVersion != null)
                            message.cacheYoloVersion = String(object.cacheYoloVersion);
                        if (object.cacheReidVersion != null)
                            message.cacheReidVersion = String(object.cacheReidVersion);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ProcessingResultCache message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @static
                     * @param {palexy.sherlock.v1.ProcessingResultCache} message ProcessingResultCache
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProcessingResultCache.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.displayName = "";
                            object.cacheAgeGroupGenderVersion = "";
                            object.cacheYoloVersion = "";
                            object.cacheReidVersion = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        if (message.cacheAgeGroupGenderVersion != null && message.hasOwnProperty("cacheAgeGroupGenderVersion"))
                            object.cacheAgeGroupGenderVersion = message.cacheAgeGroupGenderVersion;
                        if (message.cacheYoloVersion != null && message.hasOwnProperty("cacheYoloVersion"))
                            object.cacheYoloVersion = message.cacheYoloVersion;
                        if (message.cacheReidVersion != null && message.hasOwnProperty("cacheReidVersion"))
                            object.cacheReidVersion = message.cacheReidVersion;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this ProcessingResultCache to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ProcessingResultCache
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProcessingResultCache.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ProcessingResultCache;
                })();
    
                v1.ProcessingResultCacheItem = (function() {
    
                    /**
                     * Properties of a ProcessingResultCacheItem.
                     * @memberof palexy.sherlock.v1
                     * @interface IProcessingResultCacheItem
                     * @property {number|null} [id] ProcessingResultCacheItem id
                     * @property {string|null} [name] ProcessingResultCacheItem name
                     * @property {string|null} [originalFileName] ProcessingResultCacheItem originalFileName
                     * @property {string|null} [fileName] ProcessingResultCacheItem fileName
                     * @property {string|null} [bucket] ProcessingResultCacheItem bucket
                     * @property {string|null} [cloudProvider] ProcessingResultCacheItem cloudProvider
                     * @property {number|null} [createTimeMs] ProcessingResultCacheItem createTimeMs
                     * @property {number|null} [cacheId] ProcessingResultCacheItem cacheId
                     */
    
                    /**
                     * Constructs a new ProcessingResultCacheItem.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ProcessingResultCacheItem.
                     * @implements IProcessingResultCacheItem
                     * @constructor
                     * @param {palexy.sherlock.v1.IProcessingResultCacheItem=} [properties] Properties to set
                     */
                    function ProcessingResultCacheItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ProcessingResultCacheItem id.
                     * @member {number} id
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @instance
                     */
                    ProcessingResultCacheItem.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ProcessingResultCacheItem name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @instance
                     */
                    ProcessingResultCacheItem.prototype.name = "";
    
                    /**
                     * ProcessingResultCacheItem originalFileName.
                     * @member {string} originalFileName
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @instance
                     */
                    ProcessingResultCacheItem.prototype.originalFileName = "";
    
                    /**
                     * ProcessingResultCacheItem fileName.
                     * @member {string} fileName
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @instance
                     */
                    ProcessingResultCacheItem.prototype.fileName = "";
    
                    /**
                     * ProcessingResultCacheItem bucket.
                     * @member {string} bucket
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @instance
                     */
                    ProcessingResultCacheItem.prototype.bucket = "";
    
                    /**
                     * ProcessingResultCacheItem cloudProvider.
                     * @member {string} cloudProvider
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @instance
                     */
                    ProcessingResultCacheItem.prototype.cloudProvider = "";
    
                    /**
                     * ProcessingResultCacheItem createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @instance
                     */
                    ProcessingResultCacheItem.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ProcessingResultCacheItem cacheId.
                     * @member {number} cacheId
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @instance
                     */
                    ProcessingResultCacheItem.prototype.cacheId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ProcessingResultCacheItem instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @static
                     * @param {palexy.sherlock.v1.IProcessingResultCacheItem=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ProcessingResultCacheItem} ProcessingResultCacheItem instance
                     */
                    ProcessingResultCacheItem.create = function create(properties) {
                        return new ProcessingResultCacheItem(properties);
                    };
    
                    /**
                     * Encodes the specified ProcessingResultCacheItem message. Does not implicitly {@link palexy.sherlock.v1.ProcessingResultCacheItem.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @static
                     * @param {palexy.sherlock.v1.IProcessingResultCacheItem} message ProcessingResultCacheItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProcessingResultCacheItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.originalFileName != null && Object.hasOwnProperty.call(message, "originalFileName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.originalFileName);
                        if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.fileName);
                        if (message.bucket != null && Object.hasOwnProperty.call(message, "bucket"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.bucket);
                        if (message.cloudProvider != null && Object.hasOwnProperty.call(message, "cloudProvider"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.cloudProvider);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createTimeMs);
                        if (message.cacheId != null && Object.hasOwnProperty.call(message, "cacheId"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.cacheId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ProcessingResultCacheItem message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ProcessingResultCacheItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @static
                     * @param {palexy.sherlock.v1.IProcessingResultCacheItem} message ProcessingResultCacheItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProcessingResultCacheItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ProcessingResultCacheItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ProcessingResultCacheItem} ProcessingResultCacheItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProcessingResultCacheItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ProcessingResultCacheItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.originalFileName = reader.string();
                                break;
                            case 4:
                                message.fileName = reader.string();
                                break;
                            case 5:
                                message.bucket = reader.string();
                                break;
                            case 6:
                                message.cloudProvider = reader.string();
                                break;
                            case 7:
                                message.createTimeMs = reader.int64();
                                break;
                            case 8:
                                message.cacheId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ProcessingResultCacheItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ProcessingResultCacheItem} ProcessingResultCacheItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProcessingResultCacheItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ProcessingResultCacheItem message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProcessingResultCacheItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.originalFileName != null && message.hasOwnProperty("originalFileName"))
                            if (!$util.isString(message.originalFileName))
                                return "originalFileName: string expected";
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            if (!$util.isString(message.fileName))
                                return "fileName: string expected";
                        if (message.bucket != null && message.hasOwnProperty("bucket"))
                            if (!$util.isString(message.bucket))
                                return "bucket: string expected";
                        if (message.cloudProvider != null && message.hasOwnProperty("cloudProvider"))
                            if (!$util.isString(message.cloudProvider))
                                return "cloudProvider: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.cacheId != null && message.hasOwnProperty("cacheId"))
                            if (!$util.isInteger(message.cacheId) && !(message.cacheId && $util.isInteger(message.cacheId.low) && $util.isInteger(message.cacheId.high)))
                                return "cacheId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ProcessingResultCacheItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ProcessingResultCacheItem} ProcessingResultCacheItem
                     */
                    ProcessingResultCacheItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ProcessingResultCacheItem)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ProcessingResultCacheItem();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.originalFileName != null)
                            message.originalFileName = String(object.originalFileName);
                        if (object.fileName != null)
                            message.fileName = String(object.fileName);
                        if (object.bucket != null)
                            message.bucket = String(object.bucket);
                        if (object.cloudProvider != null)
                            message.cloudProvider = String(object.cloudProvider);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.cacheId != null)
                            if ($util.Long)
                                (message.cacheId = $util.Long.fromValue(object.cacheId)).unsigned = false;
                            else if (typeof object.cacheId === "string")
                                message.cacheId = parseInt(object.cacheId, 10);
                            else if (typeof object.cacheId === "number")
                                message.cacheId = object.cacheId;
                            else if (typeof object.cacheId === "object")
                                message.cacheId = new $util.LongBits(object.cacheId.low >>> 0, object.cacheId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ProcessingResultCacheItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @static
                     * @param {palexy.sherlock.v1.ProcessingResultCacheItem} message ProcessingResultCacheItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProcessingResultCacheItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.originalFileName = "";
                            object.fileName = "";
                            object.bucket = "";
                            object.cloudProvider = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cacheId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cacheId = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.originalFileName != null && message.hasOwnProperty("originalFileName"))
                            object.originalFileName = message.originalFileName;
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            object.fileName = message.fileName;
                        if (message.bucket != null && message.hasOwnProperty("bucket"))
                            object.bucket = message.bucket;
                        if (message.cloudProvider != null && message.hasOwnProperty("cloudProvider"))
                            object.cloudProvider = message.cloudProvider;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.cacheId != null && message.hasOwnProperty("cacheId"))
                            if (typeof message.cacheId === "number")
                                object.cacheId = options.longs === String ? String(message.cacheId) : message.cacheId;
                            else
                                object.cacheId = options.longs === String ? $util.Long.prototype.toString.call(message.cacheId) : options.longs === Number ? new $util.LongBits(message.cacheId.low >>> 0, message.cacheId.high >>> 0).toNumber() : message.cacheId;
                        return object;
                    };
    
                    /**
                     * Converts this ProcessingResultCacheItem to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ProcessingResultCacheItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProcessingResultCacheItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ProcessingResultCacheItem;
                })();
    
                v1.FindCacheItemByFileNameRequest = (function() {
    
                    /**
                     * Properties of a FindCacheItemByFileNameRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IFindCacheItemByFileNameRequest
                     * @property {string|null} [cache] FindCacheItemByFileNameRequest cache
                     * @property {string|null} [fileName] FindCacheItemByFileNameRequest fileName
                     */
    
                    /**
                     * Constructs a new FindCacheItemByFileNameRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a FindCacheItemByFileNameRequest.
                     * @implements IFindCacheItemByFileNameRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IFindCacheItemByFileNameRequest=} [properties] Properties to set
                     */
                    function FindCacheItemByFileNameRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FindCacheItemByFileNameRequest cache.
                     * @member {string} cache
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameRequest
                     * @instance
                     */
                    FindCacheItemByFileNameRequest.prototype.cache = "";
    
                    /**
                     * FindCacheItemByFileNameRequest fileName.
                     * @member {string} fileName
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameRequest
                     * @instance
                     */
                    FindCacheItemByFileNameRequest.prototype.fileName = "";
    
                    /**
                     * Creates a new FindCacheItemByFileNameRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameRequest
                     * @static
                     * @param {palexy.sherlock.v1.IFindCacheItemByFileNameRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.FindCacheItemByFileNameRequest} FindCacheItemByFileNameRequest instance
                     */
                    FindCacheItemByFileNameRequest.create = function create(properties) {
                        return new FindCacheItemByFileNameRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FindCacheItemByFileNameRequest message. Does not implicitly {@link palexy.sherlock.v1.FindCacheItemByFileNameRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameRequest
                     * @static
                     * @param {palexy.sherlock.v1.IFindCacheItemByFileNameRequest} message FindCacheItemByFileNameRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FindCacheItemByFileNameRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cache != null && Object.hasOwnProperty.call(message, "cache"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cache);
                        if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.fileName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FindCacheItemByFileNameRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.FindCacheItemByFileNameRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameRequest
                     * @static
                     * @param {palexy.sherlock.v1.IFindCacheItemByFileNameRequest} message FindCacheItemByFileNameRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FindCacheItemByFileNameRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FindCacheItemByFileNameRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.FindCacheItemByFileNameRequest} FindCacheItemByFileNameRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FindCacheItemByFileNameRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.FindCacheItemByFileNameRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cache = reader.string();
                                break;
                            case 2:
                                message.fileName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FindCacheItemByFileNameRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.FindCacheItemByFileNameRequest} FindCacheItemByFileNameRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FindCacheItemByFileNameRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FindCacheItemByFileNameRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FindCacheItemByFileNameRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cache != null && message.hasOwnProperty("cache"))
                            if (!$util.isString(message.cache))
                                return "cache: string expected";
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            if (!$util.isString(message.fileName))
                                return "fileName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a FindCacheItemByFileNameRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.FindCacheItemByFileNameRequest} FindCacheItemByFileNameRequest
                     */
                    FindCacheItemByFileNameRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.FindCacheItemByFileNameRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.FindCacheItemByFileNameRequest();
                        if (object.cache != null)
                            message.cache = String(object.cache);
                        if (object.fileName != null)
                            message.fileName = String(object.fileName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FindCacheItemByFileNameRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameRequest
                     * @static
                     * @param {palexy.sherlock.v1.FindCacheItemByFileNameRequest} message FindCacheItemByFileNameRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FindCacheItemByFileNameRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.cache = "";
                            object.fileName = "";
                        }
                        if (message.cache != null && message.hasOwnProperty("cache"))
                            object.cache = message.cache;
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            object.fileName = message.fileName;
                        return object;
                    };
    
                    /**
                     * Converts this FindCacheItemByFileNameRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FindCacheItemByFileNameRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FindCacheItemByFileNameRequest;
                })();
    
                v1.FindCacheItemByFileNameResponse = (function() {
    
                    /**
                     * Properties of a FindCacheItemByFileNameResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IFindCacheItemByFileNameResponse
                     * @property {palexy.sherlock.v1.IProcessingResultCacheItem|null} [item] FindCacheItemByFileNameResponse item
                     */
    
                    /**
                     * Constructs a new FindCacheItemByFileNameResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a FindCacheItemByFileNameResponse.
                     * @implements IFindCacheItemByFileNameResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IFindCacheItemByFileNameResponse=} [properties] Properties to set
                     */
                    function FindCacheItemByFileNameResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FindCacheItemByFileNameResponse item.
                     * @member {palexy.sherlock.v1.IProcessingResultCacheItem|null|undefined} item
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameResponse
                     * @instance
                     */
                    FindCacheItemByFileNameResponse.prototype.item = null;
    
                    /**
                     * Creates a new FindCacheItemByFileNameResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameResponse
                     * @static
                     * @param {palexy.sherlock.v1.IFindCacheItemByFileNameResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.FindCacheItemByFileNameResponse} FindCacheItemByFileNameResponse instance
                     */
                    FindCacheItemByFileNameResponse.create = function create(properties) {
                        return new FindCacheItemByFileNameResponse(properties);
                    };
    
                    /**
                     * Encodes the specified FindCacheItemByFileNameResponse message. Does not implicitly {@link palexy.sherlock.v1.FindCacheItemByFileNameResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameResponse
                     * @static
                     * @param {palexy.sherlock.v1.IFindCacheItemByFileNameResponse} message FindCacheItemByFileNameResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FindCacheItemByFileNameResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                            $root.palexy.sherlock.v1.ProcessingResultCacheItem.encode(message.item, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FindCacheItemByFileNameResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.FindCacheItemByFileNameResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameResponse
                     * @static
                     * @param {palexy.sherlock.v1.IFindCacheItemByFileNameResponse} message FindCacheItemByFileNameResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FindCacheItemByFileNameResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FindCacheItemByFileNameResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.FindCacheItemByFileNameResponse} FindCacheItemByFileNameResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FindCacheItemByFileNameResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.FindCacheItemByFileNameResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.item = $root.palexy.sherlock.v1.ProcessingResultCacheItem.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FindCacheItemByFileNameResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.FindCacheItemByFileNameResponse} FindCacheItemByFileNameResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FindCacheItemByFileNameResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FindCacheItemByFileNameResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FindCacheItemByFileNameResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.item != null && message.hasOwnProperty("item")) {
                            var error = $root.palexy.sherlock.v1.ProcessingResultCacheItem.verify(message.item);
                            if (error)
                                return "item." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a FindCacheItemByFileNameResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.FindCacheItemByFileNameResponse} FindCacheItemByFileNameResponse
                     */
                    FindCacheItemByFileNameResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.FindCacheItemByFileNameResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.FindCacheItemByFileNameResponse();
                        if (object.item != null) {
                            if (typeof object.item !== "object")
                                throw TypeError(".palexy.sherlock.v1.FindCacheItemByFileNameResponse.item: object expected");
                            message.item = $root.palexy.sherlock.v1.ProcessingResultCacheItem.fromObject(object.item);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FindCacheItemByFileNameResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameResponse
                     * @static
                     * @param {palexy.sherlock.v1.FindCacheItemByFileNameResponse} message FindCacheItemByFileNameResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FindCacheItemByFileNameResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.item = null;
                        if (message.item != null && message.hasOwnProperty("item"))
                            object.item = $root.palexy.sherlock.v1.ProcessingResultCacheItem.toObject(message.item, options);
                        return object;
                    };
    
                    /**
                     * Converts this FindCacheItemByFileNameResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.FindCacheItemByFileNameResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FindCacheItemByFileNameResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FindCacheItemByFileNameResponse;
                })();
    
                v1.CreateExperimentRequest = (function() {
    
                    /**
                     * Properties of a CreateExperimentRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateExperimentRequest
                     * @property {palexy.sherlock.v1.IExperiment|null} [experiment] CreateExperimentRequest experiment
                     * @property {Array.<number>|null} [testCaseIds] CreateExperimentRequest testCaseIds
                     * @property {number|null} [testSetId] CreateExperimentRequest testSetId
                     */
    
                    /**
                     * Constructs a new CreateExperimentRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateExperimentRequest.
                     * @implements ICreateExperimentRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateExperimentRequest=} [properties] Properties to set
                     */
                    function CreateExperimentRequest(properties) {
                        this.testCaseIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateExperimentRequest experiment.
                     * @member {palexy.sherlock.v1.IExperiment|null|undefined} experiment
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @instance
                     */
                    CreateExperimentRequest.prototype.experiment = null;
    
                    /**
                     * CreateExperimentRequest testCaseIds.
                     * @member {Array.<number>} testCaseIds
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @instance
                     */
                    CreateExperimentRequest.prototype.testCaseIds = $util.emptyArray;
    
                    /**
                     * CreateExperimentRequest testSetId.
                     * @member {number} testSetId
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @instance
                     */
                    CreateExperimentRequest.prototype.testSetId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new CreateExperimentRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateExperimentRequest} CreateExperimentRequest instance
                     */
                    CreateExperimentRequest.create = function create(properties) {
                        return new CreateExperimentRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateExperimentRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateExperimentRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentRequest} message CreateExperimentRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateExperimentRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.experiment != null && Object.hasOwnProperty.call(message, "experiment"))
                            $root.palexy.sherlock.v1.Experiment.encode(message.experiment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.testCaseIds != null && message.testCaseIds.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.testCaseIds.length; ++i)
                                writer.int64(message.testCaseIds[i]);
                            writer.ldelim();
                        }
                        if (message.testSetId != null && Object.hasOwnProperty.call(message, "testSetId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.testSetId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateExperimentRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateExperimentRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentRequest} message CreateExperimentRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateExperimentRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateExperimentRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateExperimentRequest} CreateExperimentRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateExperimentRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateExperimentRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.experiment = $root.palexy.sherlock.v1.Experiment.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.testCaseIds && message.testCaseIds.length))
                                    message.testCaseIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.testCaseIds.push(reader.int64());
                                } else
                                    message.testCaseIds.push(reader.int64());
                                break;
                            case 3:
                                message.testSetId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateExperimentRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateExperimentRequest} CreateExperimentRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateExperimentRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateExperimentRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateExperimentRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.experiment != null && message.hasOwnProperty("experiment")) {
                            var error = $root.palexy.sherlock.v1.Experiment.verify(message.experiment);
                            if (error)
                                return "experiment." + error;
                        }
                        if (message.testCaseIds != null && message.hasOwnProperty("testCaseIds")) {
                            if (!Array.isArray(message.testCaseIds))
                                return "testCaseIds: array expected";
                            for (var i = 0; i < message.testCaseIds.length; ++i)
                                if (!$util.isInteger(message.testCaseIds[i]) && !(message.testCaseIds[i] && $util.isInteger(message.testCaseIds[i].low) && $util.isInteger(message.testCaseIds[i].high)))
                                    return "testCaseIds: integer|Long[] expected";
                        }
                        if (message.testSetId != null && message.hasOwnProperty("testSetId"))
                            if (!$util.isInteger(message.testSetId) && !(message.testSetId && $util.isInteger(message.testSetId.low) && $util.isInteger(message.testSetId.high)))
                                return "testSetId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateExperimentRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateExperimentRequest} CreateExperimentRequest
                     */
                    CreateExperimentRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateExperimentRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateExperimentRequest();
                        if (object.experiment != null) {
                            if (typeof object.experiment !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateExperimentRequest.experiment: object expected");
                            message.experiment = $root.palexy.sherlock.v1.Experiment.fromObject(object.experiment);
                        }
                        if (object.testCaseIds) {
                            if (!Array.isArray(object.testCaseIds))
                                throw TypeError(".palexy.sherlock.v1.CreateExperimentRequest.testCaseIds: array expected");
                            message.testCaseIds = [];
                            for (var i = 0; i < object.testCaseIds.length; ++i)
                                if ($util.Long)
                                    (message.testCaseIds[i] = $util.Long.fromValue(object.testCaseIds[i])).unsigned = false;
                                else if (typeof object.testCaseIds[i] === "string")
                                    message.testCaseIds[i] = parseInt(object.testCaseIds[i], 10);
                                else if (typeof object.testCaseIds[i] === "number")
                                    message.testCaseIds[i] = object.testCaseIds[i];
                                else if (typeof object.testCaseIds[i] === "object")
                                    message.testCaseIds[i] = new $util.LongBits(object.testCaseIds[i].low >>> 0, object.testCaseIds[i].high >>> 0).toNumber();
                        }
                        if (object.testSetId != null)
                            if ($util.Long)
                                (message.testSetId = $util.Long.fromValue(object.testSetId)).unsigned = false;
                            else if (typeof object.testSetId === "string")
                                message.testSetId = parseInt(object.testSetId, 10);
                            else if (typeof object.testSetId === "number")
                                message.testSetId = object.testSetId;
                            else if (typeof object.testSetId === "object")
                                message.testSetId = new $util.LongBits(object.testSetId.low >>> 0, object.testSetId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateExperimentRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateExperimentRequest} message CreateExperimentRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateExperimentRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.testCaseIds = [];
                        if (options.defaults) {
                            object.experiment = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.testSetId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.testSetId = options.longs === String ? "0" : 0;
                        }
                        if (message.experiment != null && message.hasOwnProperty("experiment"))
                            object.experiment = $root.palexy.sherlock.v1.Experiment.toObject(message.experiment, options);
                        if (message.testCaseIds && message.testCaseIds.length) {
                            object.testCaseIds = [];
                            for (var j = 0; j < message.testCaseIds.length; ++j)
                                if (typeof message.testCaseIds[j] === "number")
                                    object.testCaseIds[j] = options.longs === String ? String(message.testCaseIds[j]) : message.testCaseIds[j];
                                else
                                    object.testCaseIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.testCaseIds[j]) : options.longs === Number ? new $util.LongBits(message.testCaseIds[j].low >>> 0, message.testCaseIds[j].high >>> 0).toNumber() : message.testCaseIds[j];
                        }
                        if (message.testSetId != null && message.hasOwnProperty("testSetId"))
                            if (typeof message.testSetId === "number")
                                object.testSetId = options.longs === String ? String(message.testSetId) : message.testSetId;
                            else
                                object.testSetId = options.longs === String ? $util.Long.prototype.toString.call(message.testSetId) : options.longs === Number ? new $util.LongBits(message.testSetId.low >>> 0, message.testSetId.high >>> 0).toNumber() : message.testSetId;
                        return object;
                    };
    
                    /**
                     * Converts this CreateExperimentRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateExperimentRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateExperimentRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateExperimentRequest;
                })();
    
                v1.CreateExperimentResponse = (function() {
    
                    /**
                     * Properties of a CreateExperimentResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateExperimentResponse
                     * @property {palexy.sherlock.v1.IExperiment|null} [experiment] CreateExperimentResponse experiment
                     */
    
                    /**
                     * Constructs a new CreateExperimentResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateExperimentResponse.
                     * @implements ICreateExperimentResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateExperimentResponse=} [properties] Properties to set
                     */
                    function CreateExperimentResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateExperimentResponse experiment.
                     * @member {palexy.sherlock.v1.IExperiment|null|undefined} experiment
                     * @memberof palexy.sherlock.v1.CreateExperimentResponse
                     * @instance
                     */
                    CreateExperimentResponse.prototype.experiment = null;
    
                    /**
                     * Creates a new CreateExperimentResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateExperimentResponse} CreateExperimentResponse instance
                     */
                    CreateExperimentResponse.create = function create(properties) {
                        return new CreateExperimentResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateExperimentResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateExperimentResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentResponse} message CreateExperimentResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateExperimentResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.experiment != null && Object.hasOwnProperty.call(message, "experiment"))
                            $root.palexy.sherlock.v1.Experiment.encode(message.experiment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateExperimentResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateExperimentResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentResponse} message CreateExperimentResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateExperimentResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateExperimentResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateExperimentResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateExperimentResponse} CreateExperimentResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateExperimentResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateExperimentResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.experiment = $root.palexy.sherlock.v1.Experiment.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateExperimentResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateExperimentResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateExperimentResponse} CreateExperimentResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateExperimentResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateExperimentResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateExperimentResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateExperimentResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.experiment != null && message.hasOwnProperty("experiment")) {
                            var error = $root.palexy.sherlock.v1.Experiment.verify(message.experiment);
                            if (error)
                                return "experiment." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateExperimentResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateExperimentResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateExperimentResponse} CreateExperimentResponse
                     */
                    CreateExperimentResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateExperimentResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateExperimentResponse();
                        if (object.experiment != null) {
                            if (typeof object.experiment !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateExperimentResponse.experiment: object expected");
                            message.experiment = $root.palexy.sherlock.v1.Experiment.fromObject(object.experiment);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateExperimentResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateExperimentResponse} message CreateExperimentResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateExperimentResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.experiment = null;
                        if (message.experiment != null && message.hasOwnProperty("experiment"))
                            object.experiment = $root.palexy.sherlock.v1.Experiment.toObject(message.experiment, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateExperimentResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateExperimentResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateExperimentResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateExperimentResponse;
                })();
    
                v1.Experiment = (function() {
    
                    /**
                     * Properties of an Experiment.
                     * @memberof palexy.sherlock.v1
                     * @interface IExperiment
                     * @property {string|null} [programmaticName] Experiment programmaticName
                     * @property {string|null} [name] Experiment name
                     * @property {string|null} [description] Experiment description
                     * @property {string|null} [videoProcessorVersion] Experiment videoProcessorVersion
                     * @property {string|null} [trackingProcessorVersion] Experiment trackingProcessorVersion
                     * @property {string|null} [mergingProcessorVersion] Experiment mergingProcessorVersion
                     * @property {string|null} [cacheAgeGroupGenderVersion] Experiment cacheAgeGroupGenderVersion
                     * @property {string|null} [cacheYoloVersion] Experiment cacheYoloVersion
                     * @property {string|null} [cacheReidVersion] Experiment cacheReidVersion
                     * @property {palexy.sherlock.v1.IEnvironmentKVList|null} [processingEnvironmentKvs] Experiment processingEnvironmentKvs
                     * @property {palexy.sherlock.v1.IEnvironmentKVList|null} [trackingEnvironmentKvs] Experiment trackingEnvironmentKvs
                     * @property {palexy.sherlock.v1.IEnvironmentKVList|null} [mergingEnvironmentKvs] Experiment mergingEnvironmentKvs
                     * @property {number|null} [overrideMergingSingleCameraThreshold] Experiment overrideMergingSingleCameraThreshold
                     * @property {number|null} [overrideMergingCrossCameraThreshold] Experiment overrideMergingCrossCameraThreshold
                     * @property {number|null} [cacheId] Experiment cacheId
                     * @property {palexy.sherlock.v1.Experiment.ExperimentState|null} [state] Experiment state
                     * @property {number|null} [createTimeMs] Experiment createTimeMs
                     * @property {number|null} [updateTimeMs] Experiment updateTimeMs
                     */
    
                    /**
                     * Constructs a new Experiment.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an Experiment.
                     * @implements IExperiment
                     * @constructor
                     * @param {palexy.sherlock.v1.IExperiment=} [properties] Properties to set
                     */
                    function Experiment(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Experiment programmaticName.
                     * @member {string} programmaticName
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.programmaticName = "";
    
                    /**
                     * Experiment name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.name = "";
    
                    /**
                     * Experiment description.
                     * @member {string} description
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.description = "";
    
                    /**
                     * Experiment videoProcessorVersion.
                     * @member {string} videoProcessorVersion
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.videoProcessorVersion = "";
    
                    /**
                     * Experiment trackingProcessorVersion.
                     * @member {string} trackingProcessorVersion
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.trackingProcessorVersion = "";
    
                    /**
                     * Experiment mergingProcessorVersion.
                     * @member {string} mergingProcessorVersion
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.mergingProcessorVersion = "";
    
                    /**
                     * Experiment cacheAgeGroupGenderVersion.
                     * @member {string} cacheAgeGroupGenderVersion
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.cacheAgeGroupGenderVersion = "";
    
                    /**
                     * Experiment cacheYoloVersion.
                     * @member {string} cacheYoloVersion
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.cacheYoloVersion = "";
    
                    /**
                     * Experiment cacheReidVersion.
                     * @member {string} cacheReidVersion
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.cacheReidVersion = "";
    
                    /**
                     * Experiment processingEnvironmentKvs.
                     * @member {palexy.sherlock.v1.IEnvironmentKVList|null|undefined} processingEnvironmentKvs
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.processingEnvironmentKvs = null;
    
                    /**
                     * Experiment trackingEnvironmentKvs.
                     * @member {palexy.sherlock.v1.IEnvironmentKVList|null|undefined} trackingEnvironmentKvs
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.trackingEnvironmentKvs = null;
    
                    /**
                     * Experiment mergingEnvironmentKvs.
                     * @member {palexy.sherlock.v1.IEnvironmentKVList|null|undefined} mergingEnvironmentKvs
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.mergingEnvironmentKvs = null;
    
                    /**
                     * Experiment overrideMergingSingleCameraThreshold.
                     * @member {number} overrideMergingSingleCameraThreshold
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.overrideMergingSingleCameraThreshold = 0;
    
                    /**
                     * Experiment overrideMergingCrossCameraThreshold.
                     * @member {number} overrideMergingCrossCameraThreshold
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.overrideMergingCrossCameraThreshold = 0;
    
                    /**
                     * Experiment cacheId.
                     * @member {number} cacheId
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.cacheId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Experiment state.
                     * @member {palexy.sherlock.v1.Experiment.ExperimentState} state
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.state = 0;
    
                    /**
                     * Experiment createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Experiment updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     */
                    Experiment.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new Experiment instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.Experiment
                     * @static
                     * @param {palexy.sherlock.v1.IExperiment=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.Experiment} Experiment instance
                     */
                    Experiment.create = function create(properties) {
                        return new Experiment(properties);
                    };
    
                    /**
                     * Encodes the specified Experiment message. Does not implicitly {@link palexy.sherlock.v1.Experiment.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.Experiment
                     * @static
                     * @param {palexy.sherlock.v1.IExperiment} message Experiment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Experiment.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.programmaticName != null && Object.hasOwnProperty.call(message, "programmaticName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.programmaticName);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                        if (message.videoProcessorVersion != null && Object.hasOwnProperty.call(message, "videoProcessorVersion"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.videoProcessorVersion);
                        if (message.trackingProcessorVersion != null && Object.hasOwnProperty.call(message, "trackingProcessorVersion"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.trackingProcessorVersion);
                        if (message.mergingProcessorVersion != null && Object.hasOwnProperty.call(message, "mergingProcessorVersion"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.mergingProcessorVersion);
                        if (message.cacheAgeGroupGenderVersion != null && Object.hasOwnProperty.call(message, "cacheAgeGroupGenderVersion"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.cacheAgeGroupGenderVersion);
                        if (message.cacheYoloVersion != null && Object.hasOwnProperty.call(message, "cacheYoloVersion"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.cacheYoloVersion);
                        if (message.cacheReidVersion != null && Object.hasOwnProperty.call(message, "cacheReidVersion"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.cacheReidVersion);
                        if (message.processingEnvironmentKvs != null && Object.hasOwnProperty.call(message, "processingEnvironmentKvs"))
                            $root.palexy.sherlock.v1.EnvironmentKVList.encode(message.processingEnvironmentKvs, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.trackingEnvironmentKvs != null && Object.hasOwnProperty.call(message, "trackingEnvironmentKvs"))
                            $root.palexy.sherlock.v1.EnvironmentKVList.encode(message.trackingEnvironmentKvs, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.mergingEnvironmentKvs != null && Object.hasOwnProperty.call(message, "mergingEnvironmentKvs"))
                            $root.palexy.sherlock.v1.EnvironmentKVList.encode(message.mergingEnvironmentKvs, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                        if (message.overrideMergingSingleCameraThreshold != null && Object.hasOwnProperty.call(message, "overrideMergingSingleCameraThreshold"))
                            writer.uint32(/* id 13, wireType 5 =*/109).float(message.overrideMergingSingleCameraThreshold);
                        if (message.overrideMergingCrossCameraThreshold != null && Object.hasOwnProperty.call(message, "overrideMergingCrossCameraThreshold"))
                            writer.uint32(/* id 14, wireType 5 =*/117).float(message.overrideMergingCrossCameraThreshold);
                        if (message.cacheId != null && Object.hasOwnProperty.call(message, "cacheId"))
                            writer.uint32(/* id 15, wireType 0 =*/120).int64(message.cacheId);
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 16, wireType 0 =*/128).int32(message.state);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 17, wireType 0 =*/136).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 18, wireType 0 =*/144).int64(message.updateTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Experiment message, length delimited. Does not implicitly {@link palexy.sherlock.v1.Experiment.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.Experiment
                     * @static
                     * @param {palexy.sherlock.v1.IExperiment} message Experiment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Experiment.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Experiment message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.Experiment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.Experiment} Experiment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Experiment.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.Experiment();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.programmaticName = reader.string();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.description = reader.string();
                                break;
                            case 4:
                                message.videoProcessorVersion = reader.string();
                                break;
                            case 5:
                                message.trackingProcessorVersion = reader.string();
                                break;
                            case 6:
                                message.mergingProcessorVersion = reader.string();
                                break;
                            case 7:
                                message.cacheAgeGroupGenderVersion = reader.string();
                                break;
                            case 8:
                                message.cacheYoloVersion = reader.string();
                                break;
                            case 9:
                                message.cacheReidVersion = reader.string();
                                break;
                            case 10:
                                message.processingEnvironmentKvs = $root.palexy.sherlock.v1.EnvironmentKVList.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.trackingEnvironmentKvs = $root.palexy.sherlock.v1.EnvironmentKVList.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.mergingEnvironmentKvs = $root.palexy.sherlock.v1.EnvironmentKVList.decode(reader, reader.uint32());
                                break;
                            case 13:
                                message.overrideMergingSingleCameraThreshold = reader.float();
                                break;
                            case 14:
                                message.overrideMergingCrossCameraThreshold = reader.float();
                                break;
                            case 15:
                                message.cacheId = reader.int64();
                                break;
                            case 16:
                                message.state = reader.int32();
                                break;
                            case 17:
                                message.createTimeMs = reader.int64();
                                break;
                            case 18:
                                message.updateTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Experiment message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.Experiment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.Experiment} Experiment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Experiment.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Experiment message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.Experiment
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Experiment.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.programmaticName != null && message.hasOwnProperty("programmaticName"))
                            if (!$util.isString(message.programmaticName))
                                return "programmaticName: string expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.videoProcessorVersion != null && message.hasOwnProperty("videoProcessorVersion"))
                            if (!$util.isString(message.videoProcessorVersion))
                                return "videoProcessorVersion: string expected";
                        if (message.trackingProcessorVersion != null && message.hasOwnProperty("trackingProcessorVersion"))
                            if (!$util.isString(message.trackingProcessorVersion))
                                return "trackingProcessorVersion: string expected";
                        if (message.mergingProcessorVersion != null && message.hasOwnProperty("mergingProcessorVersion"))
                            if (!$util.isString(message.mergingProcessorVersion))
                                return "mergingProcessorVersion: string expected";
                        if (message.cacheAgeGroupGenderVersion != null && message.hasOwnProperty("cacheAgeGroupGenderVersion"))
                            if (!$util.isString(message.cacheAgeGroupGenderVersion))
                                return "cacheAgeGroupGenderVersion: string expected";
                        if (message.cacheYoloVersion != null && message.hasOwnProperty("cacheYoloVersion"))
                            if (!$util.isString(message.cacheYoloVersion))
                                return "cacheYoloVersion: string expected";
                        if (message.cacheReidVersion != null && message.hasOwnProperty("cacheReidVersion"))
                            if (!$util.isString(message.cacheReidVersion))
                                return "cacheReidVersion: string expected";
                        if (message.processingEnvironmentKvs != null && message.hasOwnProperty("processingEnvironmentKvs")) {
                            var error = $root.palexy.sherlock.v1.EnvironmentKVList.verify(message.processingEnvironmentKvs);
                            if (error)
                                return "processingEnvironmentKvs." + error;
                        }
                        if (message.trackingEnvironmentKvs != null && message.hasOwnProperty("trackingEnvironmentKvs")) {
                            var error = $root.palexy.sherlock.v1.EnvironmentKVList.verify(message.trackingEnvironmentKvs);
                            if (error)
                                return "trackingEnvironmentKvs." + error;
                        }
                        if (message.mergingEnvironmentKvs != null && message.hasOwnProperty("mergingEnvironmentKvs")) {
                            var error = $root.palexy.sherlock.v1.EnvironmentKVList.verify(message.mergingEnvironmentKvs);
                            if (error)
                                return "mergingEnvironmentKvs." + error;
                        }
                        if (message.overrideMergingSingleCameraThreshold != null && message.hasOwnProperty("overrideMergingSingleCameraThreshold"))
                            if (typeof message.overrideMergingSingleCameraThreshold !== "number")
                                return "overrideMergingSingleCameraThreshold: number expected";
                        if (message.overrideMergingCrossCameraThreshold != null && message.hasOwnProperty("overrideMergingCrossCameraThreshold"))
                            if (typeof message.overrideMergingCrossCameraThreshold !== "number")
                                return "overrideMergingCrossCameraThreshold: number expected";
                        if (message.cacheId != null && message.hasOwnProperty("cacheId"))
                            if (!$util.isInteger(message.cacheId) && !(message.cacheId && $util.isInteger(message.cacheId.low) && $util.isInteger(message.cacheId.high)))
                                return "cacheId: integer|Long expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an Experiment message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.Experiment
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.Experiment} Experiment
                     */
                    Experiment.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.Experiment)
                            return object;
                        var message = new $root.palexy.sherlock.v1.Experiment();
                        if (object.programmaticName != null)
                            message.programmaticName = String(object.programmaticName);
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.videoProcessorVersion != null)
                            message.videoProcessorVersion = String(object.videoProcessorVersion);
                        if (object.trackingProcessorVersion != null)
                            message.trackingProcessorVersion = String(object.trackingProcessorVersion);
                        if (object.mergingProcessorVersion != null)
                            message.mergingProcessorVersion = String(object.mergingProcessorVersion);
                        if (object.cacheAgeGroupGenderVersion != null)
                            message.cacheAgeGroupGenderVersion = String(object.cacheAgeGroupGenderVersion);
                        if (object.cacheYoloVersion != null)
                            message.cacheYoloVersion = String(object.cacheYoloVersion);
                        if (object.cacheReidVersion != null)
                            message.cacheReidVersion = String(object.cacheReidVersion);
                        if (object.processingEnvironmentKvs != null) {
                            if (typeof object.processingEnvironmentKvs !== "object")
                                throw TypeError(".palexy.sherlock.v1.Experiment.processingEnvironmentKvs: object expected");
                            message.processingEnvironmentKvs = $root.palexy.sherlock.v1.EnvironmentKVList.fromObject(object.processingEnvironmentKvs);
                        }
                        if (object.trackingEnvironmentKvs != null) {
                            if (typeof object.trackingEnvironmentKvs !== "object")
                                throw TypeError(".palexy.sherlock.v1.Experiment.trackingEnvironmentKvs: object expected");
                            message.trackingEnvironmentKvs = $root.palexy.sherlock.v1.EnvironmentKVList.fromObject(object.trackingEnvironmentKvs);
                        }
                        if (object.mergingEnvironmentKvs != null) {
                            if (typeof object.mergingEnvironmentKvs !== "object")
                                throw TypeError(".palexy.sherlock.v1.Experiment.mergingEnvironmentKvs: object expected");
                            message.mergingEnvironmentKvs = $root.palexy.sherlock.v1.EnvironmentKVList.fromObject(object.mergingEnvironmentKvs);
                        }
                        if (object.overrideMergingSingleCameraThreshold != null)
                            message.overrideMergingSingleCameraThreshold = Number(object.overrideMergingSingleCameraThreshold);
                        if (object.overrideMergingCrossCameraThreshold != null)
                            message.overrideMergingCrossCameraThreshold = Number(object.overrideMergingCrossCameraThreshold);
                        if (object.cacheId != null)
                            if ($util.Long)
                                (message.cacheId = $util.Long.fromValue(object.cacheId)).unsigned = false;
                            else if (typeof object.cacheId === "string")
                                message.cacheId = parseInt(object.cacheId, 10);
                            else if (typeof object.cacheId === "number")
                                message.cacheId = object.cacheId;
                            else if (typeof object.cacheId === "object")
                                message.cacheId = new $util.LongBits(object.cacheId.low >>> 0, object.cacheId.high >>> 0).toNumber();
                        switch (object.state) {
                        case "UNKNOWN_STATE":
                        case 0:
                            message.state = 0;
                            break;
                        case "NEW":
                        case 1:
                            message.state = 1;
                            break;
                        case "SETTING_UP":
                        case 2:
                            message.state = 2;
                            break;
                        case "PROCESSING":
                        case 3:
                            message.state = 3;
                            break;
                        case "TEARING_DOWN":
                        case 4:
                            message.state = 4;
                            break;
                        case "DONE":
                        case 5:
                            message.state = 5;
                            break;
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an Experiment message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.Experiment
                     * @static
                     * @param {palexy.sherlock.v1.Experiment} message Experiment
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Experiment.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.programmaticName = "";
                            object.name = "";
                            object.description = "";
                            object.videoProcessorVersion = "";
                            object.trackingProcessorVersion = "";
                            object.mergingProcessorVersion = "";
                            object.cacheAgeGroupGenderVersion = "";
                            object.cacheYoloVersion = "";
                            object.cacheReidVersion = "";
                            object.processingEnvironmentKvs = null;
                            object.trackingEnvironmentKvs = null;
                            object.mergingEnvironmentKvs = null;
                            object.overrideMergingSingleCameraThreshold = 0;
                            object.overrideMergingCrossCameraThreshold = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cacheId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cacheId = options.longs === String ? "0" : 0;
                            object.state = options.enums === String ? "UNKNOWN_STATE" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.programmaticName != null && message.hasOwnProperty("programmaticName"))
                            object.programmaticName = message.programmaticName;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.videoProcessorVersion != null && message.hasOwnProperty("videoProcessorVersion"))
                            object.videoProcessorVersion = message.videoProcessorVersion;
                        if (message.trackingProcessorVersion != null && message.hasOwnProperty("trackingProcessorVersion"))
                            object.trackingProcessorVersion = message.trackingProcessorVersion;
                        if (message.mergingProcessorVersion != null && message.hasOwnProperty("mergingProcessorVersion"))
                            object.mergingProcessorVersion = message.mergingProcessorVersion;
                        if (message.cacheAgeGroupGenderVersion != null && message.hasOwnProperty("cacheAgeGroupGenderVersion"))
                            object.cacheAgeGroupGenderVersion = message.cacheAgeGroupGenderVersion;
                        if (message.cacheYoloVersion != null && message.hasOwnProperty("cacheYoloVersion"))
                            object.cacheYoloVersion = message.cacheYoloVersion;
                        if (message.cacheReidVersion != null && message.hasOwnProperty("cacheReidVersion"))
                            object.cacheReidVersion = message.cacheReidVersion;
                        if (message.processingEnvironmentKvs != null && message.hasOwnProperty("processingEnvironmentKvs"))
                            object.processingEnvironmentKvs = $root.palexy.sherlock.v1.EnvironmentKVList.toObject(message.processingEnvironmentKvs, options);
                        if (message.trackingEnvironmentKvs != null && message.hasOwnProperty("trackingEnvironmentKvs"))
                            object.trackingEnvironmentKvs = $root.palexy.sherlock.v1.EnvironmentKVList.toObject(message.trackingEnvironmentKvs, options);
                        if (message.mergingEnvironmentKvs != null && message.hasOwnProperty("mergingEnvironmentKvs"))
                            object.mergingEnvironmentKvs = $root.palexy.sherlock.v1.EnvironmentKVList.toObject(message.mergingEnvironmentKvs, options);
                        if (message.overrideMergingSingleCameraThreshold != null && message.hasOwnProperty("overrideMergingSingleCameraThreshold"))
                            object.overrideMergingSingleCameraThreshold = options.json && !isFinite(message.overrideMergingSingleCameraThreshold) ? String(message.overrideMergingSingleCameraThreshold) : message.overrideMergingSingleCameraThreshold;
                        if (message.overrideMergingCrossCameraThreshold != null && message.hasOwnProperty("overrideMergingCrossCameraThreshold"))
                            object.overrideMergingCrossCameraThreshold = options.json && !isFinite(message.overrideMergingCrossCameraThreshold) ? String(message.overrideMergingCrossCameraThreshold) : message.overrideMergingCrossCameraThreshold;
                        if (message.cacheId != null && message.hasOwnProperty("cacheId"))
                            if (typeof message.cacheId === "number")
                                object.cacheId = options.longs === String ? String(message.cacheId) : message.cacheId;
                            else
                                object.cacheId = options.longs === String ? $util.Long.prototype.toString.call(message.cacheId) : options.longs === Number ? new $util.LongBits(message.cacheId.low >>> 0, message.cacheId.high >>> 0).toNumber() : message.cacheId;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.sherlock.v1.Experiment.ExperimentState[message.state] : message.state;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this Experiment to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.Experiment
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Experiment.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * ExperimentState enum.
                     * @name palexy.sherlock.v1.Experiment.ExperimentState
                     * @enum {number}
                     * @property {number} UNKNOWN_STATE=0 UNKNOWN_STATE value
                     * @property {number} NEW=1 NEW value
                     * @property {number} SETTING_UP=2 SETTING_UP value
                     * @property {number} PROCESSING=3 PROCESSING value
                     * @property {number} TEARING_DOWN=4 TEARING_DOWN value
                     * @property {number} DONE=5 DONE value
                     */
                    Experiment.ExperimentState = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_STATE"] = 0;
                        values[valuesById[1] = "NEW"] = 1;
                        values[valuesById[2] = "SETTING_UP"] = 2;
                        values[valuesById[3] = "PROCESSING"] = 3;
                        values[valuesById[4] = "TEARING_DOWN"] = 4;
                        values[valuesById[5] = "DONE"] = 5;
                        return values;
                    })();
    
                    /**
                     * ExperimentTestCaseState enum.
                     * @name palexy.sherlock.v1.Experiment.ExperimentTestCaseState
                     * @enum {number}
                     * @property {number} TEST_UNKNOWN_STATE=0 TEST_UNKNOWN_STATE value
                     * @property {number} WAITING_FOR_SETTING_UP=1 WAITING_FOR_SETTING_UP value
                     * @property {number} EVALUATING=2 EVALUATING value
                     * @property {number} DONE_EVALUATION=3 DONE_EVALUATION value
                     */
                    Experiment.ExperimentTestCaseState = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "TEST_UNKNOWN_STATE"] = 0;
                        values[valuesById[1] = "WAITING_FOR_SETTING_UP"] = 1;
                        values[valuesById[2] = "EVALUATING"] = 2;
                        values[valuesById[3] = "DONE_EVALUATION"] = 3;
                        return values;
                    })();
    
                    return Experiment;
                })();
    
                v1.EnvironmentKVList = (function() {
    
                    /**
                     * Properties of an EnvironmentKVList.
                     * @memberof palexy.sherlock.v1
                     * @interface IEnvironmentKVList
                     * @property {Array.<palexy.sherlock.v1.IEnvironmentKV>|null} [kvList] EnvironmentKVList kvList
                     */
    
                    /**
                     * Constructs a new EnvironmentKVList.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an EnvironmentKVList.
                     * @implements IEnvironmentKVList
                     * @constructor
                     * @param {palexy.sherlock.v1.IEnvironmentKVList=} [properties] Properties to set
                     */
                    function EnvironmentKVList(properties) {
                        this.kvList = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * EnvironmentKVList kvList.
                     * @member {Array.<palexy.sherlock.v1.IEnvironmentKV>} kvList
                     * @memberof palexy.sherlock.v1.EnvironmentKVList
                     * @instance
                     */
                    EnvironmentKVList.prototype.kvList = $util.emptyArray;
    
                    /**
                     * Creates a new EnvironmentKVList instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.EnvironmentKVList
                     * @static
                     * @param {palexy.sherlock.v1.IEnvironmentKVList=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.EnvironmentKVList} EnvironmentKVList instance
                     */
                    EnvironmentKVList.create = function create(properties) {
                        return new EnvironmentKVList(properties);
                    };
    
                    /**
                     * Encodes the specified EnvironmentKVList message. Does not implicitly {@link palexy.sherlock.v1.EnvironmentKVList.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.EnvironmentKVList
                     * @static
                     * @param {palexy.sherlock.v1.IEnvironmentKVList} message EnvironmentKVList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EnvironmentKVList.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.kvList != null && message.kvList.length)
                            for (var i = 0; i < message.kvList.length; ++i)
                                $root.palexy.sherlock.v1.EnvironmentKV.encode(message.kvList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified EnvironmentKVList message, length delimited. Does not implicitly {@link palexy.sherlock.v1.EnvironmentKVList.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.EnvironmentKVList
                     * @static
                     * @param {palexy.sherlock.v1.IEnvironmentKVList} message EnvironmentKVList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EnvironmentKVList.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an EnvironmentKVList message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.EnvironmentKVList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.EnvironmentKVList} EnvironmentKVList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EnvironmentKVList.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.EnvironmentKVList();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.kvList && message.kvList.length))
                                    message.kvList = [];
                                message.kvList.push($root.palexy.sherlock.v1.EnvironmentKV.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an EnvironmentKVList message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.EnvironmentKVList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.EnvironmentKVList} EnvironmentKVList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EnvironmentKVList.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an EnvironmentKVList message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.EnvironmentKVList
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EnvironmentKVList.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.kvList != null && message.hasOwnProperty("kvList")) {
                            if (!Array.isArray(message.kvList))
                                return "kvList: array expected";
                            for (var i = 0; i < message.kvList.length; ++i) {
                                var error = $root.palexy.sherlock.v1.EnvironmentKV.verify(message.kvList[i]);
                                if (error)
                                    return "kvList." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an EnvironmentKVList message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.EnvironmentKVList
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.EnvironmentKVList} EnvironmentKVList
                     */
                    EnvironmentKVList.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.EnvironmentKVList)
                            return object;
                        var message = new $root.palexy.sherlock.v1.EnvironmentKVList();
                        if (object.kvList) {
                            if (!Array.isArray(object.kvList))
                                throw TypeError(".palexy.sherlock.v1.EnvironmentKVList.kvList: array expected");
                            message.kvList = [];
                            for (var i = 0; i < object.kvList.length; ++i) {
                                if (typeof object.kvList[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.EnvironmentKVList.kvList: object expected");
                                message.kvList[i] = $root.palexy.sherlock.v1.EnvironmentKV.fromObject(object.kvList[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an EnvironmentKVList message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.EnvironmentKVList
                     * @static
                     * @param {palexy.sherlock.v1.EnvironmentKVList} message EnvironmentKVList
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EnvironmentKVList.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.kvList = [];
                        if (message.kvList && message.kvList.length) {
                            object.kvList = [];
                            for (var j = 0; j < message.kvList.length; ++j)
                                object.kvList[j] = $root.palexy.sherlock.v1.EnvironmentKV.toObject(message.kvList[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this EnvironmentKVList to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.EnvironmentKVList
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EnvironmentKVList.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return EnvironmentKVList;
                })();
    
                v1.EnvironmentKV = (function() {
    
                    /**
                     * Properties of an EnvironmentKV.
                     * @memberof palexy.sherlock.v1
                     * @interface IEnvironmentKV
                     * @property {string|null} [key] EnvironmentKV key
                     * @property {string|null} [value] EnvironmentKV value
                     */
    
                    /**
                     * Constructs a new EnvironmentKV.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an EnvironmentKV.
                     * @implements IEnvironmentKV
                     * @constructor
                     * @param {palexy.sherlock.v1.IEnvironmentKV=} [properties] Properties to set
                     */
                    function EnvironmentKV(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * EnvironmentKV key.
                     * @member {string} key
                     * @memberof palexy.sherlock.v1.EnvironmentKV
                     * @instance
                     */
                    EnvironmentKV.prototype.key = "";
    
                    /**
                     * EnvironmentKV value.
                     * @member {string} value
                     * @memberof palexy.sherlock.v1.EnvironmentKV
                     * @instance
                     */
                    EnvironmentKV.prototype.value = "";
    
                    /**
                     * Creates a new EnvironmentKV instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.EnvironmentKV
                     * @static
                     * @param {palexy.sherlock.v1.IEnvironmentKV=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.EnvironmentKV} EnvironmentKV instance
                     */
                    EnvironmentKV.create = function create(properties) {
                        return new EnvironmentKV(properties);
                    };
    
                    /**
                     * Encodes the specified EnvironmentKV message. Does not implicitly {@link palexy.sherlock.v1.EnvironmentKV.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.EnvironmentKV
                     * @static
                     * @param {palexy.sherlock.v1.IEnvironmentKV} message EnvironmentKV message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EnvironmentKV.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified EnvironmentKV message, length delimited. Does not implicitly {@link palexy.sherlock.v1.EnvironmentKV.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.EnvironmentKV
                     * @static
                     * @param {palexy.sherlock.v1.IEnvironmentKV} message EnvironmentKV message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EnvironmentKV.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an EnvironmentKV message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.EnvironmentKV
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.EnvironmentKV} EnvironmentKV
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EnvironmentKV.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.EnvironmentKV();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.key = reader.string();
                                break;
                            case 2:
                                message.value = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an EnvironmentKV message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.EnvironmentKV
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.EnvironmentKV} EnvironmentKV
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EnvironmentKV.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an EnvironmentKV message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.EnvironmentKV
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EnvironmentKV.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.key != null && message.hasOwnProperty("key"))
                            if (!$util.isString(message.key))
                                return "key: string expected";
                        if (message.value != null && message.hasOwnProperty("value"))
                            if (!$util.isString(message.value))
                                return "value: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an EnvironmentKV message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.EnvironmentKV
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.EnvironmentKV} EnvironmentKV
                     */
                    EnvironmentKV.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.EnvironmentKV)
                            return object;
                        var message = new $root.palexy.sherlock.v1.EnvironmentKV();
                        if (object.key != null)
                            message.key = String(object.key);
                        if (object.value != null)
                            message.value = String(object.value);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an EnvironmentKV message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.EnvironmentKV
                     * @static
                     * @param {palexy.sherlock.v1.EnvironmentKV} message EnvironmentKV
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EnvironmentKV.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.key = "";
                            object.value = "";
                        }
                        if (message.key != null && message.hasOwnProperty("key"))
                            object.key = message.key;
                        if (message.value != null && message.hasOwnProperty("value"))
                            object.value = message.value;
                        return object;
                    };
    
                    /**
                     * Converts this EnvironmentKV to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.EnvironmentKV
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EnvironmentKV.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return EnvironmentKV;
                })();
    
                v1.ListExperimentsRequest = (function() {
    
                    /**
                     * Properties of a ListExperimentsRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IListExperimentsRequest
                     * @property {boolean|null} [onlyIncludeNotDone] ListExperimentsRequest onlyIncludeNotDone
                     * @property {string|null} [pageToken] ListExperimentsRequest pageToken
                     * @property {number|null} [pageSize] ListExperimentsRequest pageSize
                     */
    
                    /**
                     * Constructs a new ListExperimentsRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListExperimentsRequest.
                     * @implements IListExperimentsRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IListExperimentsRequest=} [properties] Properties to set
                     */
                    function ListExperimentsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListExperimentsRequest onlyIncludeNotDone.
                     * @member {boolean} onlyIncludeNotDone
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @instance
                     */
                    ListExperimentsRequest.prototype.onlyIncludeNotDone = false;
    
                    /**
                     * ListExperimentsRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @instance
                     */
                    ListExperimentsRequest.prototype.pageToken = "";
    
                    /**
                     * ListExperimentsRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @instance
                     */
                    ListExperimentsRequest.prototype.pageSize = 0;
    
                    /**
                     * Creates a new ListExperimentsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentsRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListExperimentsRequest} ListExperimentsRequest instance
                     */
                    ListExperimentsRequest.create = function create(properties) {
                        return new ListExperimentsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListExperimentsRequest message. Does not implicitly {@link palexy.sherlock.v1.ListExperimentsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentsRequest} message ListExperimentsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExperimentsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.onlyIncludeNotDone != null && Object.hasOwnProperty.call(message, "onlyIncludeNotDone"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.onlyIncludeNotDone);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pageToken);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListExperimentsRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListExperimentsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentsRequest} message ListExperimentsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExperimentsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListExperimentsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListExperimentsRequest} ListExperimentsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExperimentsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListExperimentsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.onlyIncludeNotDone = reader.bool();
                                break;
                            case 2:
                                message.pageToken = reader.string();
                                break;
                            case 3:
                                message.pageSize = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListExperimentsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListExperimentsRequest} ListExperimentsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExperimentsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListExperimentsRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListExperimentsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.onlyIncludeNotDone != null && message.hasOwnProperty("onlyIncludeNotDone"))
                            if (typeof message.onlyIncludeNotDone !== "boolean")
                                return "onlyIncludeNotDone: boolean expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListExperimentsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListExperimentsRequest} ListExperimentsRequest
                     */
                    ListExperimentsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListExperimentsRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListExperimentsRequest();
                        if (object.onlyIncludeNotDone != null)
                            message.onlyIncludeNotDone = Boolean(object.onlyIncludeNotDone);
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListExperimentsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @static
                     * @param {palexy.sherlock.v1.ListExperimentsRequest} message ListExperimentsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListExperimentsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.onlyIncludeNotDone = false;
                            object.pageToken = "";
                            object.pageSize = 0;
                        }
                        if (message.onlyIncludeNotDone != null && message.hasOwnProperty("onlyIncludeNotDone"))
                            object.onlyIncludeNotDone = message.onlyIncludeNotDone;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        return object;
                    };
    
                    /**
                     * Converts this ListExperimentsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListExperimentsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListExperimentsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListExperimentsRequest;
                })();
    
                v1.GetExperimentRequest = (function() {
    
                    /**
                     * Properties of a GetExperimentRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IGetExperimentRequest
                     * @property {string|null} [experimentName] GetExperimentRequest experimentName
                     */
    
                    /**
                     * Constructs a new GetExperimentRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GetExperimentRequest.
                     * @implements IGetExperimentRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IGetExperimentRequest=} [properties] Properties to set
                     */
                    function GetExperimentRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetExperimentRequest experimentName.
                     * @member {string} experimentName
                     * @memberof palexy.sherlock.v1.GetExperimentRequest
                     * @instance
                     */
                    GetExperimentRequest.prototype.experimentName = "";
    
                    /**
                     * Creates a new GetExperimentRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GetExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGetExperimentRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GetExperimentRequest} GetExperimentRequest instance
                     */
                    GetExperimentRequest.create = function create(properties) {
                        return new GetExperimentRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetExperimentRequest message. Does not implicitly {@link palexy.sherlock.v1.GetExperimentRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GetExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGetExperimentRequest} message GetExperimentRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetExperimentRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.experimentName != null && Object.hasOwnProperty.call(message, "experimentName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.experimentName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetExperimentRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GetExperimentRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GetExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGetExperimentRequest} message GetExperimentRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetExperimentRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetExperimentRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GetExperimentRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GetExperimentRequest} GetExperimentRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetExperimentRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GetExperimentRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.experimentName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetExperimentRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GetExperimentRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GetExperimentRequest} GetExperimentRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetExperimentRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetExperimentRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GetExperimentRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetExperimentRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            if (!$util.isString(message.experimentName))
                                return "experimentName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetExperimentRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GetExperimentRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GetExperimentRequest} GetExperimentRequest
                     */
                    GetExperimentRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GetExperimentRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GetExperimentRequest();
                        if (object.experimentName != null)
                            message.experimentName = String(object.experimentName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetExperimentRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GetExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.GetExperimentRequest} message GetExperimentRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetExperimentRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.experimentName = "";
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            object.experimentName = message.experimentName;
                        return object;
                    };
    
                    /**
                     * Converts this GetExperimentRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GetExperimentRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetExperimentRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetExperimentRequest;
                })();
    
                v1.GetExperimentResponse = (function() {
    
                    /**
                     * Properties of a GetExperimentResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IGetExperimentResponse
                     * @property {palexy.sherlock.v1.IExperiment|null} [experiment] GetExperimentResponse experiment
                     */
    
                    /**
                     * Constructs a new GetExperimentResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GetExperimentResponse.
                     * @implements IGetExperimentResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IGetExperimentResponse=} [properties] Properties to set
                     */
                    function GetExperimentResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetExperimentResponse experiment.
                     * @member {palexy.sherlock.v1.IExperiment|null|undefined} experiment
                     * @memberof palexy.sherlock.v1.GetExperimentResponse
                     * @instance
                     */
                    GetExperimentResponse.prototype.experiment = null;
    
                    /**
                     * Creates a new GetExperimentResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GetExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGetExperimentResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GetExperimentResponse} GetExperimentResponse instance
                     */
                    GetExperimentResponse.create = function create(properties) {
                        return new GetExperimentResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetExperimentResponse message. Does not implicitly {@link palexy.sherlock.v1.GetExperimentResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GetExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGetExperimentResponse} message GetExperimentResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetExperimentResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.experiment != null && Object.hasOwnProperty.call(message, "experiment"))
                            $root.palexy.sherlock.v1.Experiment.encode(message.experiment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetExperimentResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GetExperimentResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GetExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGetExperimentResponse} message GetExperimentResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetExperimentResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetExperimentResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GetExperimentResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GetExperimentResponse} GetExperimentResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetExperimentResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GetExperimentResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.experiment = $root.palexy.sherlock.v1.Experiment.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetExperimentResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GetExperimentResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GetExperimentResponse} GetExperimentResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetExperimentResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetExperimentResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GetExperimentResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetExperimentResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.experiment != null && message.hasOwnProperty("experiment")) {
                            var error = $root.palexy.sherlock.v1.Experiment.verify(message.experiment);
                            if (error)
                                return "experiment." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetExperimentResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GetExperimentResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GetExperimentResponse} GetExperimentResponse
                     */
                    GetExperimentResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GetExperimentResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GetExperimentResponse();
                        if (object.experiment != null) {
                            if (typeof object.experiment !== "object")
                                throw TypeError(".palexy.sherlock.v1.GetExperimentResponse.experiment: object expected");
                            message.experiment = $root.palexy.sherlock.v1.Experiment.fromObject(object.experiment);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetExperimentResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GetExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.GetExperimentResponse} message GetExperimentResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetExperimentResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.experiment = null;
                        if (message.experiment != null && message.hasOwnProperty("experiment"))
                            object.experiment = $root.palexy.sherlock.v1.Experiment.toObject(message.experiment, options);
                        return object;
                    };
    
                    /**
                     * Converts this GetExperimentResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GetExperimentResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetExperimentResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetExperimentResponse;
                })();
    
                v1.ListExperimentsResponse = (function() {
    
                    /**
                     * Properties of a ListExperimentsResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IListExperimentsResponse
                     * @property {Array.<palexy.sherlock.v1.IExperiment>|null} [experiments] ListExperimentsResponse experiments
                     * @property {string|null} [nextPageToken] ListExperimentsResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListExperimentsResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListExperimentsResponse.
                     * @implements IListExperimentsResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IListExperimentsResponse=} [properties] Properties to set
                     */
                    function ListExperimentsResponse(properties) {
                        this.experiments = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListExperimentsResponse experiments.
                     * @member {Array.<palexy.sherlock.v1.IExperiment>} experiments
                     * @memberof palexy.sherlock.v1.ListExperimentsResponse
                     * @instance
                     */
                    ListExperimentsResponse.prototype.experiments = $util.emptyArray;
    
                    /**
                     * ListExperimentsResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.sherlock.v1.ListExperimentsResponse
                     * @instance
                     */
                    ListExperimentsResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListExperimentsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListExperimentsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentsResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListExperimentsResponse} ListExperimentsResponse instance
                     */
                    ListExperimentsResponse.create = function create(properties) {
                        return new ListExperimentsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListExperimentsResponse message. Does not implicitly {@link palexy.sherlock.v1.ListExperimentsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListExperimentsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentsResponse} message ListExperimentsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExperimentsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.experiments != null && message.experiments.length)
                            for (var i = 0; i < message.experiments.length; ++i)
                                $root.palexy.sherlock.v1.Experiment.encode(message.experiments[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListExperimentsResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListExperimentsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListExperimentsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentsResponse} message ListExperimentsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExperimentsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListExperimentsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListExperimentsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListExperimentsResponse} ListExperimentsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExperimentsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListExperimentsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.experiments && message.experiments.length))
                                    message.experiments = [];
                                message.experiments.push($root.palexy.sherlock.v1.Experiment.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListExperimentsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListExperimentsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListExperimentsResponse} ListExperimentsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExperimentsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListExperimentsResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListExperimentsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListExperimentsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.experiments != null && message.hasOwnProperty("experiments")) {
                            if (!Array.isArray(message.experiments))
                                return "experiments: array expected";
                            for (var i = 0; i < message.experiments.length; ++i) {
                                var error = $root.palexy.sherlock.v1.Experiment.verify(message.experiments[i]);
                                if (error)
                                    return "experiments." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListExperimentsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListExperimentsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListExperimentsResponse} ListExperimentsResponse
                     */
                    ListExperimentsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListExperimentsResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListExperimentsResponse();
                        if (object.experiments) {
                            if (!Array.isArray(object.experiments))
                                throw TypeError(".palexy.sherlock.v1.ListExperimentsResponse.experiments: array expected");
                            message.experiments = [];
                            for (var i = 0; i < object.experiments.length; ++i) {
                                if (typeof object.experiments[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ListExperimentsResponse.experiments: object expected");
                                message.experiments[i] = $root.palexy.sherlock.v1.Experiment.fromObject(object.experiments[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListExperimentsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListExperimentsResponse
                     * @static
                     * @param {palexy.sherlock.v1.ListExperimentsResponse} message ListExperimentsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListExperimentsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.experiments = [];
                        if (options.defaults)
                            object.nextPageToken = "";
                        if (message.experiments && message.experiments.length) {
                            object.experiments = [];
                            for (var j = 0; j < message.experiments.length; ++j)
                                object.experiments[j] = $root.palexy.sherlock.v1.Experiment.toObject(message.experiments[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListExperimentsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListExperimentsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListExperimentsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListExperimentsResponse;
                })();
    
                v1.UpdateExperimentRequest = (function() {
    
                    /**
                     * Properties of an UpdateExperimentRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IUpdateExperimentRequest
                     * @property {palexy.sherlock.v1.IExperiment|null} [experiment] UpdateExperimentRequest experiment
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateExperimentRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateExperimentRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UpdateExperimentRequest.
                     * @implements IUpdateExperimentRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IUpdateExperimentRequest=} [properties] Properties to set
                     */
                    function UpdateExperimentRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateExperimentRequest experiment.
                     * @member {palexy.sherlock.v1.IExperiment|null|undefined} experiment
                     * @memberof palexy.sherlock.v1.UpdateExperimentRequest
                     * @instance
                     */
                    UpdateExperimentRequest.prototype.experiment = null;
    
                    /**
                     * UpdateExperimentRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.sherlock.v1.UpdateExperimentRequest
                     * @instance
                     */
                    UpdateExperimentRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateExperimentRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UpdateExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UpdateExperimentRequest} UpdateExperimentRequest instance
                     */
                    UpdateExperimentRequest.create = function create(properties) {
                        return new UpdateExperimentRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateExperimentRequest message. Does not implicitly {@link palexy.sherlock.v1.UpdateExperimentRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UpdateExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentRequest} message UpdateExperimentRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateExperimentRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.experiment != null && Object.hasOwnProperty.call(message, "experiment"))
                            $root.palexy.sherlock.v1.Experiment.encode(message.experiment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateExperimentRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UpdateExperimentRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentRequest} message UpdateExperimentRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateExperimentRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateExperimentRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UpdateExperimentRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UpdateExperimentRequest} UpdateExperimentRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateExperimentRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UpdateExperimentRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.experiment = $root.palexy.sherlock.v1.Experiment.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateExperimentRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateExperimentRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UpdateExperimentRequest} UpdateExperimentRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateExperimentRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateExperimentRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UpdateExperimentRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateExperimentRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.experiment != null && message.hasOwnProperty("experiment")) {
                            var error = $root.palexy.sherlock.v1.Experiment.verify(message.experiment);
                            if (error)
                                return "experiment." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateExperimentRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UpdateExperimentRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UpdateExperimentRequest} UpdateExperimentRequest
                     */
                    UpdateExperimentRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UpdateExperimentRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.UpdateExperimentRequest();
                        if (object.experiment != null) {
                            if (typeof object.experiment !== "object")
                                throw TypeError(".palexy.sherlock.v1.UpdateExperimentRequest.experiment: object expected");
                            message.experiment = $root.palexy.sherlock.v1.Experiment.fromObject(object.experiment);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.sherlock.v1.UpdateExperimentRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateExperimentRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UpdateExperimentRequest
                     * @static
                     * @param {palexy.sherlock.v1.UpdateExperimentRequest} message UpdateExperimentRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateExperimentRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.experiment = null;
                            object.updateMask = null;
                        }
                        if (message.experiment != null && message.hasOwnProperty("experiment"))
                            object.experiment = $root.palexy.sherlock.v1.Experiment.toObject(message.experiment, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateExperimentRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UpdateExperimentRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateExperimentRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateExperimentRequest;
                })();
    
                v1.UpdateExperimentResponse = (function() {
    
                    /**
                     * Properties of an UpdateExperimentResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IUpdateExperimentResponse
                     * @property {palexy.sherlock.v1.IExperiment|null} [experiment] UpdateExperimentResponse experiment
                     */
    
                    /**
                     * Constructs a new UpdateExperimentResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UpdateExperimentResponse.
                     * @implements IUpdateExperimentResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IUpdateExperimentResponse=} [properties] Properties to set
                     */
                    function UpdateExperimentResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateExperimentResponse experiment.
                     * @member {palexy.sherlock.v1.IExperiment|null|undefined} experiment
                     * @memberof palexy.sherlock.v1.UpdateExperimentResponse
                     * @instance
                     */
                    UpdateExperimentResponse.prototype.experiment = null;
    
                    /**
                     * Creates a new UpdateExperimentResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UpdateExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UpdateExperimentResponse} UpdateExperimentResponse instance
                     */
                    UpdateExperimentResponse.create = function create(properties) {
                        return new UpdateExperimentResponse(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateExperimentResponse message. Does not implicitly {@link palexy.sherlock.v1.UpdateExperimentResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UpdateExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentResponse} message UpdateExperimentResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateExperimentResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.experiment != null && Object.hasOwnProperty.call(message, "experiment"))
                            $root.palexy.sherlock.v1.Experiment.encode(message.experiment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateExperimentResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UpdateExperimentResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentResponse} message UpdateExperimentResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateExperimentResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateExperimentResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UpdateExperimentResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UpdateExperimentResponse} UpdateExperimentResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateExperimentResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UpdateExperimentResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.experiment = $root.palexy.sherlock.v1.Experiment.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateExperimentResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateExperimentResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UpdateExperimentResponse} UpdateExperimentResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateExperimentResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateExperimentResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UpdateExperimentResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateExperimentResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.experiment != null && message.hasOwnProperty("experiment")) {
                            var error = $root.palexy.sherlock.v1.Experiment.verify(message.experiment);
                            if (error)
                                return "experiment." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateExperimentResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UpdateExperimentResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UpdateExperimentResponse} UpdateExperimentResponse
                     */
                    UpdateExperimentResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UpdateExperimentResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.UpdateExperimentResponse();
                        if (object.experiment != null) {
                            if (typeof object.experiment !== "object")
                                throw TypeError(".palexy.sherlock.v1.UpdateExperimentResponse.experiment: object expected");
                            message.experiment = $root.palexy.sherlock.v1.Experiment.fromObject(object.experiment);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateExperimentResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UpdateExperimentResponse
                     * @static
                     * @param {palexy.sherlock.v1.UpdateExperimentResponse} message UpdateExperimentResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateExperimentResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.experiment = null;
                        if (message.experiment != null && message.hasOwnProperty("experiment"))
                            object.experiment = $root.palexy.sherlock.v1.Experiment.toObject(message.experiment, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateExperimentResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UpdateExperimentResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateExperimentResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateExperimentResponse;
                })();
    
                v1.ListExperimentTestCasesRequest = (function() {
    
                    /**
                     * Properties of a ListExperimentTestCasesRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IListExperimentTestCasesRequest
                     * @property {palexy.sherlock.v1.Experiment.ExperimentTestCaseState|null} [testCaseState] ListExperimentTestCasesRequest testCaseState
                     * @property {string|null} [experimentName] ListExperimentTestCasesRequest experimentName
                     * @property {string|null} [pageToken] ListExperimentTestCasesRequest pageToken
                     * @property {number|null} [pageSize] ListExperimentTestCasesRequest pageSize
                     * @property {string|null} [dateId] ListExperimentTestCasesRequest dateId
                     * @property {number|null} [storeId] ListExperimentTestCasesRequest storeId
                     */
    
                    /**
                     * Constructs a new ListExperimentTestCasesRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListExperimentTestCasesRequest.
                     * @implements IListExperimentTestCasesRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IListExperimentTestCasesRequest=} [properties] Properties to set
                     */
                    function ListExperimentTestCasesRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListExperimentTestCasesRequest testCaseState.
                     * @member {palexy.sherlock.v1.Experiment.ExperimentTestCaseState} testCaseState
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @instance
                     */
                    ListExperimentTestCasesRequest.prototype.testCaseState = 0;
    
                    /**
                     * ListExperimentTestCasesRequest experimentName.
                     * @member {string} experimentName
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @instance
                     */
                    ListExperimentTestCasesRequest.prototype.experimentName = "";
    
                    /**
                     * ListExperimentTestCasesRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @instance
                     */
                    ListExperimentTestCasesRequest.prototype.pageToken = "";
    
                    /**
                     * ListExperimentTestCasesRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @instance
                     */
                    ListExperimentTestCasesRequest.prototype.pageSize = 0;
    
                    /**
                     * ListExperimentTestCasesRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @instance
                     */
                    ListExperimentTestCasesRequest.prototype.dateId = "";
    
                    /**
                     * ListExperimentTestCasesRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @instance
                     */
                    ListExperimentTestCasesRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListExperimentTestCasesRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentTestCasesRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListExperimentTestCasesRequest} ListExperimentTestCasesRequest instance
                     */
                    ListExperimentTestCasesRequest.create = function create(properties) {
                        return new ListExperimentTestCasesRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListExperimentTestCasesRequest message. Does not implicitly {@link palexy.sherlock.v1.ListExperimentTestCasesRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentTestCasesRequest} message ListExperimentTestCasesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExperimentTestCasesRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.testCaseState != null && Object.hasOwnProperty.call(message, "testCaseState"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.testCaseState);
                        if (message.experimentName != null && Object.hasOwnProperty.call(message, "experimentName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.experimentName);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.pageToken);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pageSize);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.dateId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.storeId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListExperimentTestCasesRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListExperimentTestCasesRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentTestCasesRequest} message ListExperimentTestCasesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExperimentTestCasesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListExperimentTestCasesRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListExperimentTestCasesRequest} ListExperimentTestCasesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExperimentTestCasesRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListExperimentTestCasesRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.testCaseState = reader.int32();
                                break;
                            case 2:
                                message.experimentName = reader.string();
                                break;
                            case 3:
                                message.pageToken = reader.string();
                                break;
                            case 4:
                                message.pageSize = reader.int32();
                                break;
                            case 5:
                                message.dateId = reader.string();
                                break;
                            case 6:
                                message.storeId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListExperimentTestCasesRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListExperimentTestCasesRequest} ListExperimentTestCasesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExperimentTestCasesRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListExperimentTestCasesRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListExperimentTestCasesRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.testCaseState != null && message.hasOwnProperty("testCaseState"))
                            switch (message.testCaseState) {
                            default:
                                return "testCaseState: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            if (!$util.isString(message.experimentName))
                                return "experimentName: string expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListExperimentTestCasesRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListExperimentTestCasesRequest} ListExperimentTestCasesRequest
                     */
                    ListExperimentTestCasesRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListExperimentTestCasesRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListExperimentTestCasesRequest();
                        switch (object.testCaseState) {
                        case "TEST_UNKNOWN_STATE":
                        case 0:
                            message.testCaseState = 0;
                            break;
                        case "WAITING_FOR_SETTING_UP":
                        case 1:
                            message.testCaseState = 1;
                            break;
                        case "EVALUATING":
                        case 2:
                            message.testCaseState = 2;
                            break;
                        case "DONE_EVALUATION":
                        case 3:
                            message.testCaseState = 3;
                            break;
                        }
                        if (object.experimentName != null)
                            message.experimentName = String(object.experimentName);
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListExperimentTestCasesRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @static
                     * @param {palexy.sherlock.v1.ListExperimentTestCasesRequest} message ListExperimentTestCasesRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListExperimentTestCasesRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.testCaseState = options.enums === String ? "TEST_UNKNOWN_STATE" : 0;
                            object.experimentName = "";
                            object.pageToken = "";
                            object.pageSize = 0;
                            object.dateId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                        }
                        if (message.testCaseState != null && message.hasOwnProperty("testCaseState"))
                            object.testCaseState = options.enums === String ? $root.palexy.sherlock.v1.Experiment.ExperimentTestCaseState[message.testCaseState] : message.testCaseState;
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            object.experimentName = message.experimentName;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        return object;
                    };
    
                    /**
                     * Converts this ListExperimentTestCasesRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListExperimentTestCasesRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListExperimentTestCasesRequest;
                })();
    
                v1.ListExperimentTestCasesResponse = (function() {
    
                    /**
                     * Properties of a ListExperimentTestCasesResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IListExperimentTestCasesResponse
                     * @property {Array.<palexy.sherlock.v1.IExperimentTestCase>|null} [testCases] ListExperimentTestCasesResponse testCases
                     * @property {string|null} [nextPageToken] ListExperimentTestCasesResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListExperimentTestCasesResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListExperimentTestCasesResponse.
                     * @implements IListExperimentTestCasesResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IListExperimentTestCasesResponse=} [properties] Properties to set
                     */
                    function ListExperimentTestCasesResponse(properties) {
                        this.testCases = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListExperimentTestCasesResponse testCases.
                     * @member {Array.<palexy.sherlock.v1.IExperimentTestCase>} testCases
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesResponse
                     * @instance
                     */
                    ListExperimentTestCasesResponse.prototype.testCases = $util.emptyArray;
    
                    /**
                     * ListExperimentTestCasesResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesResponse
                     * @instance
                     */
                    ListExperimentTestCasesResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListExperimentTestCasesResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentTestCasesResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListExperimentTestCasesResponse} ListExperimentTestCasesResponse instance
                     */
                    ListExperimentTestCasesResponse.create = function create(properties) {
                        return new ListExperimentTestCasesResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListExperimentTestCasesResponse message. Does not implicitly {@link palexy.sherlock.v1.ListExperimentTestCasesResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentTestCasesResponse} message ListExperimentTestCasesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExperimentTestCasesResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.testCases != null && message.testCases.length)
                            for (var i = 0; i < message.testCases.length; ++i)
                                $root.palexy.sherlock.v1.ExperimentTestCase.encode(message.testCases[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListExperimentTestCasesResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListExperimentTestCasesResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListExperimentTestCasesResponse} message ListExperimentTestCasesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExperimentTestCasesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListExperimentTestCasesResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListExperimentTestCasesResponse} ListExperimentTestCasesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExperimentTestCasesResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListExperimentTestCasesResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.testCases && message.testCases.length))
                                    message.testCases = [];
                                message.testCases.push($root.palexy.sherlock.v1.ExperimentTestCase.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListExperimentTestCasesResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListExperimentTestCasesResponse} ListExperimentTestCasesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExperimentTestCasesResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListExperimentTestCasesResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListExperimentTestCasesResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.testCases != null && message.hasOwnProperty("testCases")) {
                            if (!Array.isArray(message.testCases))
                                return "testCases: array expected";
                            for (var i = 0; i < message.testCases.length; ++i) {
                                var error = $root.palexy.sherlock.v1.ExperimentTestCase.verify(message.testCases[i]);
                                if (error)
                                    return "testCases." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListExperimentTestCasesResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListExperimentTestCasesResponse} ListExperimentTestCasesResponse
                     */
                    ListExperimentTestCasesResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListExperimentTestCasesResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListExperimentTestCasesResponse();
                        if (object.testCases) {
                            if (!Array.isArray(object.testCases))
                                throw TypeError(".palexy.sherlock.v1.ListExperimentTestCasesResponse.testCases: array expected");
                            message.testCases = [];
                            for (var i = 0; i < object.testCases.length; ++i) {
                                if (typeof object.testCases[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ListExperimentTestCasesResponse.testCases: object expected");
                                message.testCases[i] = $root.palexy.sherlock.v1.ExperimentTestCase.fromObject(object.testCases[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListExperimentTestCasesResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesResponse
                     * @static
                     * @param {palexy.sherlock.v1.ListExperimentTestCasesResponse} message ListExperimentTestCasesResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListExperimentTestCasesResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.testCases = [];
                        if (options.defaults)
                            object.nextPageToken = "";
                        if (message.testCases && message.testCases.length) {
                            object.testCases = [];
                            for (var j = 0; j < message.testCases.length; ++j)
                                object.testCases[j] = $root.palexy.sherlock.v1.ExperimentTestCase.toObject(message.testCases[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListExperimentTestCasesResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListExperimentTestCasesResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListExperimentTestCasesResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListExperimentTestCasesResponse;
                })();
    
                v1.CreateExperimentVideoRequest = (function() {
    
                    /**
                     * Properties of a CreateExperimentVideoRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateExperimentVideoRequest
                     * @property {palexy.streaming.v1.IVideo|null} [video] CreateExperimentVideoRequest video
                     * @property {string|null} [experimentName] CreateExperimentVideoRequest experimentName
                     * @property {number|null} [storeId] CreateExperimentVideoRequest storeId
                     * @property {string|null} [processingQueueName] CreateExperimentVideoRequest processingQueueName
                     */
    
                    /**
                     * Constructs a new CreateExperimentVideoRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateExperimentVideoRequest.
                     * @implements ICreateExperimentVideoRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateExperimentVideoRequest=} [properties] Properties to set
                     */
                    function CreateExperimentVideoRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateExperimentVideoRequest video.
                     * @member {palexy.streaming.v1.IVideo|null|undefined} video
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @instance
                     */
                    CreateExperimentVideoRequest.prototype.video = null;
    
                    /**
                     * CreateExperimentVideoRequest experimentName.
                     * @member {string} experimentName
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @instance
                     */
                    CreateExperimentVideoRequest.prototype.experimentName = "";
    
                    /**
                     * CreateExperimentVideoRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @instance
                     */
                    CreateExperimentVideoRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CreateExperimentVideoRequest processingQueueName.
                     * @member {string} processingQueueName
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @instance
                     */
                    CreateExperimentVideoRequest.prototype.processingQueueName = "";
    
                    /**
                     * Creates a new CreateExperimentVideoRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentVideoRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateExperimentVideoRequest} CreateExperimentVideoRequest instance
                     */
                    CreateExperimentVideoRequest.create = function create(properties) {
                        return new CreateExperimentVideoRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateExperimentVideoRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateExperimentVideoRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentVideoRequest} message CreateExperimentVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateExperimentVideoRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.video != null && Object.hasOwnProperty.call(message, "video"))
                            $root.palexy.streaming.v1.Video.encode(message.video, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.experimentName != null && Object.hasOwnProperty.call(message, "experimentName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.experimentName);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        if (message.processingQueueName != null && Object.hasOwnProperty.call(message, "processingQueueName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.processingQueueName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateExperimentVideoRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateExperimentVideoRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentVideoRequest} message CreateExperimentVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateExperimentVideoRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateExperimentVideoRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateExperimentVideoRequest} CreateExperimentVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateExperimentVideoRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateExperimentVideoRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.video = $root.palexy.streaming.v1.Video.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.experimentName = reader.string();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 4:
                                message.processingQueueName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateExperimentVideoRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateExperimentVideoRequest} CreateExperimentVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateExperimentVideoRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateExperimentVideoRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateExperimentVideoRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.video != null && message.hasOwnProperty("video")) {
                            var error = $root.palexy.streaming.v1.Video.verify(message.video);
                            if (error)
                                return "video." + error;
                        }
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            if (!$util.isString(message.experimentName))
                                return "experimentName: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.processingQueueName != null && message.hasOwnProperty("processingQueueName"))
                            if (!$util.isString(message.processingQueueName))
                                return "processingQueueName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateExperimentVideoRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateExperimentVideoRequest} CreateExperimentVideoRequest
                     */
                    CreateExperimentVideoRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateExperimentVideoRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateExperimentVideoRequest();
                        if (object.video != null) {
                            if (typeof object.video !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateExperimentVideoRequest.video: object expected");
                            message.video = $root.palexy.streaming.v1.Video.fromObject(object.video);
                        }
                        if (object.experimentName != null)
                            message.experimentName = String(object.experimentName);
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.processingQueueName != null)
                            message.processingQueueName = String(object.processingQueueName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateExperimentVideoRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateExperimentVideoRequest} message CreateExperimentVideoRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateExperimentVideoRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.video = null;
                            object.experimentName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.processingQueueName = "";
                        }
                        if (message.video != null && message.hasOwnProperty("video"))
                            object.video = $root.palexy.streaming.v1.Video.toObject(message.video, options);
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            object.experimentName = message.experimentName;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.processingQueueName != null && message.hasOwnProperty("processingQueueName"))
                            object.processingQueueName = message.processingQueueName;
                        return object;
                    };
    
                    /**
                     * Converts this CreateExperimentVideoRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateExperimentVideoRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateExperimentVideoRequest;
                })();
    
                v1.CreateExperimentVideoResponse = (function() {
    
                    /**
                     * Properties of a CreateExperimentVideoResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateExperimentVideoResponse
                     * @property {palexy.streaming.v1.IVideo|null} [video] CreateExperimentVideoResponse video
                     */
    
                    /**
                     * Constructs a new CreateExperimentVideoResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateExperimentVideoResponse.
                     * @implements ICreateExperimentVideoResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateExperimentVideoResponse=} [properties] Properties to set
                     */
                    function CreateExperimentVideoResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateExperimentVideoResponse video.
                     * @member {palexy.streaming.v1.IVideo|null|undefined} video
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoResponse
                     * @instance
                     */
                    CreateExperimentVideoResponse.prototype.video = null;
    
                    /**
                     * Creates a new CreateExperimentVideoResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentVideoResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateExperimentVideoResponse} CreateExperimentVideoResponse instance
                     */
                    CreateExperimentVideoResponse.create = function create(properties) {
                        return new CreateExperimentVideoResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateExperimentVideoResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateExperimentVideoResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentVideoResponse} message CreateExperimentVideoResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateExperimentVideoResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.video != null && Object.hasOwnProperty.call(message, "video"))
                            $root.palexy.streaming.v1.Video.encode(message.video, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateExperimentVideoResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateExperimentVideoResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateExperimentVideoResponse} message CreateExperimentVideoResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateExperimentVideoResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateExperimentVideoResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateExperimentVideoResponse} CreateExperimentVideoResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateExperimentVideoResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateExperimentVideoResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.video = $root.palexy.streaming.v1.Video.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateExperimentVideoResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateExperimentVideoResponse} CreateExperimentVideoResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateExperimentVideoResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateExperimentVideoResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateExperimentVideoResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.video != null && message.hasOwnProperty("video")) {
                            var error = $root.palexy.streaming.v1.Video.verify(message.video);
                            if (error)
                                return "video." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateExperimentVideoResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateExperimentVideoResponse} CreateExperimentVideoResponse
                     */
                    CreateExperimentVideoResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateExperimentVideoResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateExperimentVideoResponse();
                        if (object.video != null) {
                            if (typeof object.video !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateExperimentVideoResponse.video: object expected");
                            message.video = $root.palexy.streaming.v1.Video.fromObject(object.video);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateExperimentVideoResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateExperimentVideoResponse} message CreateExperimentVideoResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateExperimentVideoResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.video = null;
                        if (message.video != null && message.hasOwnProperty("video"))
                            object.video = $root.palexy.streaming.v1.Video.toObject(message.video, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateExperimentVideoResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateExperimentVideoResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateExperimentVideoResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateExperimentVideoResponse;
                })();
    
                v1.UpdateExperimentTestCaseStateRequest = (function() {
    
                    /**
                     * Properties of an UpdateExperimentTestCaseStateRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IUpdateExperimentTestCaseStateRequest
                     * @property {string|null} [experimentName] UpdateExperimentTestCaseStateRequest experimentName
                     * @property {number|null} [testCaseId] UpdateExperimentTestCaseStateRequest testCaseId
                     * @property {palexy.sherlock.v1.Experiment.ExperimentTestCaseState|null} [newState] UpdateExperimentTestCaseStateRequest newState
                     */
    
                    /**
                     * Constructs a new UpdateExperimentTestCaseStateRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UpdateExperimentTestCaseStateRequest.
                     * @implements IUpdateExperimentTestCaseStateRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IUpdateExperimentTestCaseStateRequest=} [properties] Properties to set
                     */
                    function UpdateExperimentTestCaseStateRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateExperimentTestCaseStateRequest experimentName.
                     * @member {string} experimentName
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @instance
                     */
                    UpdateExperimentTestCaseStateRequest.prototype.experimentName = "";
    
                    /**
                     * UpdateExperimentTestCaseStateRequest testCaseId.
                     * @member {number} testCaseId
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @instance
                     */
                    UpdateExperimentTestCaseStateRequest.prototype.testCaseId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * UpdateExperimentTestCaseStateRequest newState.
                     * @member {palexy.sherlock.v1.Experiment.ExperimentTestCaseState} newState
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @instance
                     */
                    UpdateExperimentTestCaseStateRequest.prototype.newState = 0;
    
                    /**
                     * Creates a new UpdateExperimentTestCaseStateRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentTestCaseStateRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest} UpdateExperimentTestCaseStateRequest instance
                     */
                    UpdateExperimentTestCaseStateRequest.create = function create(properties) {
                        return new UpdateExperimentTestCaseStateRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateExperimentTestCaseStateRequest message. Does not implicitly {@link palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentTestCaseStateRequest} message UpdateExperimentTestCaseStateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateExperimentTestCaseStateRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.experimentName != null && Object.hasOwnProperty.call(message, "experimentName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.experimentName);
                        if (message.testCaseId != null && Object.hasOwnProperty.call(message, "testCaseId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.testCaseId);
                        if (message.newState != null && Object.hasOwnProperty.call(message, "newState"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.newState);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateExperimentTestCaseStateRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentTestCaseStateRequest} message UpdateExperimentTestCaseStateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateExperimentTestCaseStateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateExperimentTestCaseStateRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest} UpdateExperimentTestCaseStateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateExperimentTestCaseStateRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.experimentName = reader.string();
                                break;
                            case 2:
                                message.testCaseId = reader.int64();
                                break;
                            case 3:
                                message.newState = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateExperimentTestCaseStateRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest} UpdateExperimentTestCaseStateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateExperimentTestCaseStateRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateExperimentTestCaseStateRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateExperimentTestCaseStateRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            if (!$util.isString(message.experimentName))
                                return "experimentName: string expected";
                        if (message.testCaseId != null && message.hasOwnProperty("testCaseId"))
                            if (!$util.isInteger(message.testCaseId) && !(message.testCaseId && $util.isInteger(message.testCaseId.low) && $util.isInteger(message.testCaseId.high)))
                                return "testCaseId: integer|Long expected";
                        if (message.newState != null && message.hasOwnProperty("newState"))
                            switch (message.newState) {
                            default:
                                return "newState: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateExperimentTestCaseStateRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest} UpdateExperimentTestCaseStateRequest
                     */
                    UpdateExperimentTestCaseStateRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest();
                        if (object.experimentName != null)
                            message.experimentName = String(object.experimentName);
                        if (object.testCaseId != null)
                            if ($util.Long)
                                (message.testCaseId = $util.Long.fromValue(object.testCaseId)).unsigned = false;
                            else if (typeof object.testCaseId === "string")
                                message.testCaseId = parseInt(object.testCaseId, 10);
                            else if (typeof object.testCaseId === "number")
                                message.testCaseId = object.testCaseId;
                            else if (typeof object.testCaseId === "object")
                                message.testCaseId = new $util.LongBits(object.testCaseId.low >>> 0, object.testCaseId.high >>> 0).toNumber();
                        switch (object.newState) {
                        case "TEST_UNKNOWN_STATE":
                        case 0:
                            message.newState = 0;
                            break;
                        case "WAITING_FOR_SETTING_UP":
                        case 1:
                            message.newState = 1;
                            break;
                        case "EVALUATING":
                        case 2:
                            message.newState = 2;
                            break;
                        case "DONE_EVALUATION":
                        case 3:
                            message.newState = 3;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateExperimentTestCaseStateRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @static
                     * @param {palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest} message UpdateExperimentTestCaseStateRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateExperimentTestCaseStateRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.experimentName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.testCaseId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.testCaseId = options.longs === String ? "0" : 0;
                            object.newState = options.enums === String ? "TEST_UNKNOWN_STATE" : 0;
                        }
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            object.experimentName = message.experimentName;
                        if (message.testCaseId != null && message.hasOwnProperty("testCaseId"))
                            if (typeof message.testCaseId === "number")
                                object.testCaseId = options.longs === String ? String(message.testCaseId) : message.testCaseId;
                            else
                                object.testCaseId = options.longs === String ? $util.Long.prototype.toString.call(message.testCaseId) : options.longs === Number ? new $util.LongBits(message.testCaseId.low >>> 0, message.testCaseId.high >>> 0).toNumber() : message.testCaseId;
                        if (message.newState != null && message.hasOwnProperty("newState"))
                            object.newState = options.enums === String ? $root.palexy.sherlock.v1.Experiment.ExperimentTestCaseState[message.newState] : message.newState;
                        return object;
                    };
    
                    /**
                     * Converts this UpdateExperimentTestCaseStateRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateExperimentTestCaseStateRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateExperimentTestCaseStateRequest;
                })();
    
                v1.UpdateExperimentTestCaseStateResponse = (function() {
    
                    /**
                     * Properties of an UpdateExperimentTestCaseStateResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IUpdateExperimentTestCaseStateResponse
                     */
    
                    /**
                     * Constructs a new UpdateExperimentTestCaseStateResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UpdateExperimentTestCaseStateResponse.
                     * @implements IUpdateExperimentTestCaseStateResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IUpdateExperimentTestCaseStateResponse=} [properties] Properties to set
                     */
                    function UpdateExperimentTestCaseStateResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new UpdateExperimentTestCaseStateResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentTestCaseStateResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse} UpdateExperimentTestCaseStateResponse instance
                     */
                    UpdateExperimentTestCaseStateResponse.create = function create(properties) {
                        return new UpdateExperimentTestCaseStateResponse(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateExperimentTestCaseStateResponse message. Does not implicitly {@link palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentTestCaseStateResponse} message UpdateExperimentTestCaseStateResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateExperimentTestCaseStateResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateExperimentTestCaseStateResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateExperimentTestCaseStateResponse} message UpdateExperimentTestCaseStateResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateExperimentTestCaseStateResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateExperimentTestCaseStateResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse} UpdateExperimentTestCaseStateResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateExperimentTestCaseStateResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateExperimentTestCaseStateResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse} UpdateExperimentTestCaseStateResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateExperimentTestCaseStateResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateExperimentTestCaseStateResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateExperimentTestCaseStateResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpdateExperimentTestCaseStateResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse} UpdateExperimentTestCaseStateResponse
                     */
                    UpdateExperimentTestCaseStateResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse)
                            return object;
                        return new $root.palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse();
                    };
    
                    /**
                     * Creates a plain object from an UpdateExperimentTestCaseStateResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse
                     * @static
                     * @param {palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse} message UpdateExperimentTestCaseStateResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateExperimentTestCaseStateResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this UpdateExperimentTestCaseStateResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UpdateExperimentTestCaseStateResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateExperimentTestCaseStateResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateExperimentTestCaseStateResponse;
                })();
    
                v1.FetchExperimentVideosReadyForMergingRequest = (function() {
    
                    /**
                     * Properties of a FetchExperimentVideosReadyForMergingRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IFetchExperimentVideosReadyForMergingRequest
                     * @property {string|null} [experimentName] FetchExperimentVideosReadyForMergingRequest experimentName
                     */
    
                    /**
                     * Constructs a new FetchExperimentVideosReadyForMergingRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a FetchExperimentVideosReadyForMergingRequest.
                     * @implements IFetchExperimentVideosReadyForMergingRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosReadyForMergingRequest=} [properties] Properties to set
                     */
                    function FetchExperimentVideosReadyForMergingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchExperimentVideosReadyForMergingRequest experimentName.
                     * @member {string} experimentName
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest
                     * @instance
                     */
                    FetchExperimentVideosReadyForMergingRequest.prototype.experimentName = "";
    
                    /**
                     * Creates a new FetchExperimentVideosReadyForMergingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosReadyForMergingRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest} FetchExperimentVideosReadyForMergingRequest instance
                     */
                    FetchExperimentVideosReadyForMergingRequest.create = function create(properties) {
                        return new FetchExperimentVideosReadyForMergingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FetchExperimentVideosReadyForMergingRequest message. Does not implicitly {@link palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosReadyForMergingRequest} message FetchExperimentVideosReadyForMergingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchExperimentVideosReadyForMergingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.experimentName != null && Object.hasOwnProperty.call(message, "experimentName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.experimentName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchExperimentVideosReadyForMergingRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosReadyForMergingRequest} message FetchExperimentVideosReadyForMergingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchExperimentVideosReadyForMergingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchExperimentVideosReadyForMergingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest} FetchExperimentVideosReadyForMergingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchExperimentVideosReadyForMergingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.experimentName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchExperimentVideosReadyForMergingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest} FetchExperimentVideosReadyForMergingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchExperimentVideosReadyForMergingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchExperimentVideosReadyForMergingRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchExperimentVideosReadyForMergingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            if (!$util.isString(message.experimentName))
                                return "experimentName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a FetchExperimentVideosReadyForMergingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest} FetchExperimentVideosReadyForMergingRequest
                     */
                    FetchExperimentVideosReadyForMergingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest();
                        if (object.experimentName != null)
                            message.experimentName = String(object.experimentName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchExperimentVideosReadyForMergingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest
                     * @static
                     * @param {palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest} message FetchExperimentVideosReadyForMergingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchExperimentVideosReadyForMergingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.experimentName = "";
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            object.experimentName = message.experimentName;
                        return object;
                    };
    
                    /**
                     * Converts this FetchExperimentVideosReadyForMergingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchExperimentVideosReadyForMergingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchExperimentVideosReadyForMergingRequest;
                })();
    
                v1.FetchExperimentVideosReadyForMergingResponse = (function() {
    
                    /**
                     * Properties of a FetchExperimentVideosReadyForMergingResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IFetchExperimentVideosReadyForMergingResponse
                     * @property {Array.<palexy.streaming.v1.IMergeItem>|null} [mergeItems] FetchExperimentVideosReadyForMergingResponse mergeItems
                     */
    
                    /**
                     * Constructs a new FetchExperimentVideosReadyForMergingResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a FetchExperimentVideosReadyForMergingResponse.
                     * @implements IFetchExperimentVideosReadyForMergingResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosReadyForMergingResponse=} [properties] Properties to set
                     */
                    function FetchExperimentVideosReadyForMergingResponse(properties) {
                        this.mergeItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchExperimentVideosReadyForMergingResponse mergeItems.
                     * @member {Array.<palexy.streaming.v1.IMergeItem>} mergeItems
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse
                     * @instance
                     */
                    FetchExperimentVideosReadyForMergingResponse.prototype.mergeItems = $util.emptyArray;
    
                    /**
                     * Creates a new FetchExperimentVideosReadyForMergingResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosReadyForMergingResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse} FetchExperimentVideosReadyForMergingResponse instance
                     */
                    FetchExperimentVideosReadyForMergingResponse.create = function create(properties) {
                        return new FetchExperimentVideosReadyForMergingResponse(properties);
                    };
    
                    /**
                     * Encodes the specified FetchExperimentVideosReadyForMergingResponse message. Does not implicitly {@link palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosReadyForMergingResponse} message FetchExperimentVideosReadyForMergingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchExperimentVideosReadyForMergingResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergeItems != null && message.mergeItems.length)
                            for (var i = 0; i < message.mergeItems.length; ++i)
                                $root.palexy.streaming.v1.MergeItem.encode(message.mergeItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchExperimentVideosReadyForMergingResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosReadyForMergingResponse} message FetchExperimentVideosReadyForMergingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchExperimentVideosReadyForMergingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchExperimentVideosReadyForMergingResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse} FetchExperimentVideosReadyForMergingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchExperimentVideosReadyForMergingResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.mergeItems && message.mergeItems.length))
                                    message.mergeItems = [];
                                message.mergeItems.push($root.palexy.streaming.v1.MergeItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchExperimentVideosReadyForMergingResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse} FetchExperimentVideosReadyForMergingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchExperimentVideosReadyForMergingResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchExperimentVideosReadyForMergingResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchExperimentVideosReadyForMergingResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergeItems != null && message.hasOwnProperty("mergeItems")) {
                            if (!Array.isArray(message.mergeItems))
                                return "mergeItems: array expected";
                            for (var i = 0; i < message.mergeItems.length; ++i) {
                                var error = $root.palexy.streaming.v1.MergeItem.verify(message.mergeItems[i]);
                                if (error)
                                    return "mergeItems." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a FetchExperimentVideosReadyForMergingResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse} FetchExperimentVideosReadyForMergingResponse
                     */
                    FetchExperimentVideosReadyForMergingResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse();
                        if (object.mergeItems) {
                            if (!Array.isArray(object.mergeItems))
                                throw TypeError(".palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse.mergeItems: array expected");
                            message.mergeItems = [];
                            for (var i = 0; i < object.mergeItems.length; ++i) {
                                if (typeof object.mergeItems[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse.mergeItems: object expected");
                                message.mergeItems[i] = $root.palexy.streaming.v1.MergeItem.fromObject(object.mergeItems[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchExperimentVideosReadyForMergingResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse
                     * @static
                     * @param {palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse} message FetchExperimentVideosReadyForMergingResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchExperimentVideosReadyForMergingResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.mergeItems = [];
                        if (message.mergeItems && message.mergeItems.length) {
                            object.mergeItems = [];
                            for (var j = 0; j < message.mergeItems.length; ++j)
                                object.mergeItems[j] = $root.palexy.streaming.v1.MergeItem.toObject(message.mergeItems[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this FetchExperimentVideosReadyForMergingResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosReadyForMergingResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchExperimentVideosReadyForMergingResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchExperimentVideosReadyForMergingResponse;
                })();
    
                v1.FetchExperimentVideosDoneMergingRequest = (function() {
    
                    /**
                     * Properties of a FetchExperimentVideosDoneMergingRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IFetchExperimentVideosDoneMergingRequest
                     * @property {string|null} [experimentName] FetchExperimentVideosDoneMergingRequest experimentName
                     */
    
                    /**
                     * Constructs a new FetchExperimentVideosDoneMergingRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a FetchExperimentVideosDoneMergingRequest.
                     * @implements IFetchExperimentVideosDoneMergingRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosDoneMergingRequest=} [properties] Properties to set
                     */
                    function FetchExperimentVideosDoneMergingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchExperimentVideosDoneMergingRequest experimentName.
                     * @member {string} experimentName
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest
                     * @instance
                     */
                    FetchExperimentVideosDoneMergingRequest.prototype.experimentName = "";
    
                    /**
                     * Creates a new FetchExperimentVideosDoneMergingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosDoneMergingRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest} FetchExperimentVideosDoneMergingRequest instance
                     */
                    FetchExperimentVideosDoneMergingRequest.create = function create(properties) {
                        return new FetchExperimentVideosDoneMergingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FetchExperimentVideosDoneMergingRequest message. Does not implicitly {@link palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosDoneMergingRequest} message FetchExperimentVideosDoneMergingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchExperimentVideosDoneMergingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.experimentName != null && Object.hasOwnProperty.call(message, "experimentName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.experimentName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchExperimentVideosDoneMergingRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosDoneMergingRequest} message FetchExperimentVideosDoneMergingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchExperimentVideosDoneMergingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchExperimentVideosDoneMergingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest} FetchExperimentVideosDoneMergingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchExperimentVideosDoneMergingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.experimentName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchExperimentVideosDoneMergingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest} FetchExperimentVideosDoneMergingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchExperimentVideosDoneMergingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchExperimentVideosDoneMergingRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchExperimentVideosDoneMergingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            if (!$util.isString(message.experimentName))
                                return "experimentName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a FetchExperimentVideosDoneMergingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest} FetchExperimentVideosDoneMergingRequest
                     */
                    FetchExperimentVideosDoneMergingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest();
                        if (object.experimentName != null)
                            message.experimentName = String(object.experimentName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchExperimentVideosDoneMergingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest
                     * @static
                     * @param {palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest} message FetchExperimentVideosDoneMergingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchExperimentVideosDoneMergingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.experimentName = "";
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            object.experimentName = message.experimentName;
                        return object;
                    };
    
                    /**
                     * Converts this FetchExperimentVideosDoneMergingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchExperimentVideosDoneMergingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchExperimentVideosDoneMergingRequest;
                })();
    
                v1.FetchExperimentVideosDoneMergingResponse = (function() {
    
                    /**
                     * Properties of a FetchExperimentVideosDoneMergingResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IFetchExperimentVideosDoneMergingResponse
                     * @property {Array.<palexy.streaming.v1.IMergeItem>|null} [mergeItems] FetchExperimentVideosDoneMergingResponse mergeItems
                     */
    
                    /**
                     * Constructs a new FetchExperimentVideosDoneMergingResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a FetchExperimentVideosDoneMergingResponse.
                     * @implements IFetchExperimentVideosDoneMergingResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosDoneMergingResponse=} [properties] Properties to set
                     */
                    function FetchExperimentVideosDoneMergingResponse(properties) {
                        this.mergeItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchExperimentVideosDoneMergingResponse mergeItems.
                     * @member {Array.<palexy.streaming.v1.IMergeItem>} mergeItems
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse
                     * @instance
                     */
                    FetchExperimentVideosDoneMergingResponse.prototype.mergeItems = $util.emptyArray;
    
                    /**
                     * Creates a new FetchExperimentVideosDoneMergingResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosDoneMergingResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse} FetchExperimentVideosDoneMergingResponse instance
                     */
                    FetchExperimentVideosDoneMergingResponse.create = function create(properties) {
                        return new FetchExperimentVideosDoneMergingResponse(properties);
                    };
    
                    /**
                     * Encodes the specified FetchExperimentVideosDoneMergingResponse message. Does not implicitly {@link palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosDoneMergingResponse} message FetchExperimentVideosDoneMergingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchExperimentVideosDoneMergingResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergeItems != null && message.mergeItems.length)
                            for (var i = 0; i < message.mergeItems.length; ++i)
                                $root.palexy.streaming.v1.MergeItem.encode(message.mergeItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchExperimentVideosDoneMergingResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse
                     * @static
                     * @param {palexy.sherlock.v1.IFetchExperimentVideosDoneMergingResponse} message FetchExperimentVideosDoneMergingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchExperimentVideosDoneMergingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchExperimentVideosDoneMergingResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse} FetchExperimentVideosDoneMergingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchExperimentVideosDoneMergingResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.mergeItems && message.mergeItems.length))
                                    message.mergeItems = [];
                                message.mergeItems.push($root.palexy.streaming.v1.MergeItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchExperimentVideosDoneMergingResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse} FetchExperimentVideosDoneMergingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchExperimentVideosDoneMergingResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchExperimentVideosDoneMergingResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchExperimentVideosDoneMergingResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergeItems != null && message.hasOwnProperty("mergeItems")) {
                            if (!Array.isArray(message.mergeItems))
                                return "mergeItems: array expected";
                            for (var i = 0; i < message.mergeItems.length; ++i) {
                                var error = $root.palexy.streaming.v1.MergeItem.verify(message.mergeItems[i]);
                                if (error)
                                    return "mergeItems." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a FetchExperimentVideosDoneMergingResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse} FetchExperimentVideosDoneMergingResponse
                     */
                    FetchExperimentVideosDoneMergingResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse();
                        if (object.mergeItems) {
                            if (!Array.isArray(object.mergeItems))
                                throw TypeError(".palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse.mergeItems: array expected");
                            message.mergeItems = [];
                            for (var i = 0; i < object.mergeItems.length; ++i) {
                                if (typeof object.mergeItems[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse.mergeItems: object expected");
                                message.mergeItems[i] = $root.palexy.streaming.v1.MergeItem.fromObject(object.mergeItems[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchExperimentVideosDoneMergingResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse
                     * @static
                     * @param {palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse} message FetchExperimentVideosDoneMergingResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchExperimentVideosDoneMergingResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.mergeItems = [];
                        if (message.mergeItems && message.mergeItems.length) {
                            object.mergeItems = [];
                            for (var j = 0; j < message.mergeItems.length; ++j)
                                object.mergeItems[j] = $root.palexy.streaming.v1.MergeItem.toObject(message.mergeItems[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this FetchExperimentVideosDoneMergingResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.FetchExperimentVideosDoneMergingResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchExperimentVideosDoneMergingResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchExperimentVideosDoneMergingResponse;
                })();
    
                v1.QcPlannerService = (function() {
    
                    /**
                     * Constructs a new QcPlannerService service.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a QcPlannerService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function QcPlannerService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (QcPlannerService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = QcPlannerService;
    
                    /**
                     * Creates new QcPlannerService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {QcPlannerService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    QcPlannerService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcPlannerService#listQcPeriodConfigs}.
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @typedef ListQcPeriodConfigsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.ListQcPeriodConfigsResponse} [response] ListQcPeriodConfigsResponse
                     */
    
                    /**
                     * Calls ListQcPeriodConfigs.
                     * @function listQcPeriodConfigs
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IListQcPeriodConfigsRequest} request ListQcPeriodConfigsRequest message or plain object
                     * @param {palexy.sherlock.v1.QcPlannerService.ListQcPeriodConfigsCallback} callback Node-style callback called with the error, if any, and ListQcPeriodConfigsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcPlannerService.prototype.listQcPeriodConfigs = function listQcPeriodConfigs(request, callback) {
                        return this.rpcCall(listQcPeriodConfigs, $root.palexy.sherlock.v1.ListQcPeriodConfigsRequest, $root.palexy.sherlock.v1.ListQcPeriodConfigsResponse, request, callback);
                    }, "name", { value: "ListQcPeriodConfigs" });
    
                    /**
                     * Calls ListQcPeriodConfigs.
                     * @function listQcPeriodConfigs
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IListQcPeriodConfigsRequest} request ListQcPeriodConfigsRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.ListQcPeriodConfigsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcPlannerService#createQcPeriodConfig}.
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @typedef CreateQcPeriodConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateQcPeriodConfigResponse} [response] CreateQcPeriodConfigResponse
                     */
    
                    /**
                     * Calls CreateQcPeriodConfig.
                     * @function createQcPeriodConfig
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateQcPeriodConfigRequest} request CreateQcPeriodConfigRequest message or plain object
                     * @param {palexy.sherlock.v1.QcPlannerService.CreateQcPeriodConfigCallback} callback Node-style callback called with the error, if any, and CreateQcPeriodConfigResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcPlannerService.prototype.createQcPeriodConfig = function createQcPeriodConfig(request, callback) {
                        return this.rpcCall(createQcPeriodConfig, $root.palexy.sherlock.v1.CreateQcPeriodConfigRequest, $root.palexy.sherlock.v1.CreateQcPeriodConfigResponse, request, callback);
                    }, "name", { value: "CreateQcPeriodConfig" });
    
                    /**
                     * Calls CreateQcPeriodConfig.
                     * @function createQcPeriodConfig
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateQcPeriodConfigRequest} request CreateQcPeriodConfigRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateQcPeriodConfigResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcPlannerService#deleteQcPeriodConfig}.
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @typedef DeleteQcPeriodConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.DeleteQcPeriodConfigResponse} [response] DeleteQcPeriodConfigResponse
                     */
    
                    /**
                     * Calls DeleteQcPeriodConfig.
                     * @function deleteQcPeriodConfig
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IDeleteQcPeriodConfigRequest} request DeleteQcPeriodConfigRequest message or plain object
                     * @param {palexy.sherlock.v1.QcPlannerService.DeleteQcPeriodConfigCallback} callback Node-style callback called with the error, if any, and DeleteQcPeriodConfigResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcPlannerService.prototype.deleteQcPeriodConfig = function deleteQcPeriodConfig(request, callback) {
                        return this.rpcCall(deleteQcPeriodConfig, $root.palexy.sherlock.v1.DeleteQcPeriodConfigRequest, $root.palexy.sherlock.v1.DeleteQcPeriodConfigResponse, request, callback);
                    }, "name", { value: "DeleteQcPeriodConfig" });
    
                    /**
                     * Calls DeleteQcPeriodConfig.
                     * @function deleteQcPeriodConfig
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IDeleteQcPeriodConfigRequest} request DeleteQcPeriodConfigRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.DeleteQcPeriodConfigResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcPlannerService#updateQcPeriodConfig}.
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @typedef UpdateQcPeriodConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.UpdateQcPeriodConfigResponse} [response] UpdateQcPeriodConfigResponse
                     */
    
                    /**
                     * Calls UpdateQcPeriodConfig.
                     * @function updateQcPeriodConfig
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateQcPeriodConfigRequest} request UpdateQcPeriodConfigRequest message or plain object
                     * @param {palexy.sherlock.v1.QcPlannerService.UpdateQcPeriodConfigCallback} callback Node-style callback called with the error, if any, and UpdateQcPeriodConfigResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcPlannerService.prototype.updateQcPeriodConfig = function updateQcPeriodConfig(request, callback) {
                        return this.rpcCall(updateQcPeriodConfig, $root.palexy.sherlock.v1.UpdateQcPeriodConfigRequest, $root.palexy.sherlock.v1.UpdateQcPeriodConfigResponse, request, callback);
                    }, "name", { value: "UpdateQcPeriodConfig" });
    
                    /**
                     * Calls UpdateQcPeriodConfig.
                     * @function updateQcPeriodConfig
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateQcPeriodConfigRequest} request UpdateQcPeriodConfigRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.UpdateQcPeriodConfigResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcPlannerService#generatePlanForConfig}.
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @typedef GeneratePlanForConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.GeneratePlanForConfigResponse} [response] GeneratePlanForConfigResponse
                     */
    
                    /**
                     * Calls GeneratePlanForConfig.
                     * @function generatePlanForConfig
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IGeneratePlanForConfigRequest} request GeneratePlanForConfigRequest message or plain object
                     * @param {palexy.sherlock.v1.QcPlannerService.GeneratePlanForConfigCallback} callback Node-style callback called with the error, if any, and GeneratePlanForConfigResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcPlannerService.prototype.generatePlanForConfig = function generatePlanForConfig(request, callback) {
                        return this.rpcCall(generatePlanForConfig, $root.palexy.sherlock.v1.GeneratePlanForConfigRequest, $root.palexy.sherlock.v1.GeneratePlanForConfigResponse, request, callback);
                    }, "name", { value: "GeneratePlanForConfig" });
    
                    /**
                     * Calls GeneratePlanForConfig.
                     * @function generatePlanForConfig
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IGeneratePlanForConfigRequest} request GeneratePlanForConfigRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.GeneratePlanForConfigResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcPlannerService#listQcPlanItems}.
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @typedef ListQcPlanItemsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.ListQcPlanItemsResponse} [response] ListQcPlanItemsResponse
                     */
    
                    /**
                     * Calls ListQcPlanItems.
                     * @function listQcPlanItems
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IListQcPlanItemsRequest} request ListQcPlanItemsRequest message or plain object
                     * @param {palexy.sherlock.v1.QcPlannerService.ListQcPlanItemsCallback} callback Node-style callback called with the error, if any, and ListQcPlanItemsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcPlannerService.prototype.listQcPlanItems = function listQcPlanItems(request, callback) {
                        return this.rpcCall(listQcPlanItems, $root.palexy.sherlock.v1.ListQcPlanItemsRequest, $root.palexy.sherlock.v1.ListQcPlanItemsResponse, request, callback);
                    }, "name", { value: "ListQcPlanItems" });
    
                    /**
                     * Calls ListQcPlanItems.
                     * @function listQcPlanItems
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IListQcPlanItemsRequest} request ListQcPlanItemsRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.ListQcPlanItemsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcPlannerService#createQcPlanItem}.
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @typedef CreateQcPlanItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.CreateQcPlanItemResponse} [response] CreateQcPlanItemResponse
                     */
    
                    /**
                     * Calls CreateQcPlanItem.
                     * @function createQcPlanItem
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateQcPlanItemRequest} request CreateQcPlanItemRequest message or plain object
                     * @param {palexy.sherlock.v1.QcPlannerService.CreateQcPlanItemCallback} callback Node-style callback called with the error, if any, and CreateQcPlanItemResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcPlannerService.prototype.createQcPlanItem = function createQcPlanItem(request, callback) {
                        return this.rpcCall(createQcPlanItem, $root.palexy.sherlock.v1.CreateQcPlanItemRequest, $root.palexy.sherlock.v1.CreateQcPlanItemResponse, request, callback);
                    }, "name", { value: "CreateQcPlanItem" });
    
                    /**
                     * Calls CreateQcPlanItem.
                     * @function createQcPlanItem
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.ICreateQcPlanItemRequest} request CreateQcPlanItemRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.CreateQcPlanItemResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcPlannerService#batchCreateQcPlanItems}.
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @typedef BatchCreateQcPlanItemsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.BatchCreateQcPlanItemsResponse} [response] BatchCreateQcPlanItemsResponse
                     */
    
                    /**
                     * Calls BatchCreateQcPlanItems.
                     * @function batchCreateQcPlanItems
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IBatchCreateQcPlanItemsRequest} request BatchCreateQcPlanItemsRequest message or plain object
                     * @param {palexy.sherlock.v1.QcPlannerService.BatchCreateQcPlanItemsCallback} callback Node-style callback called with the error, if any, and BatchCreateQcPlanItemsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcPlannerService.prototype.batchCreateQcPlanItems = function batchCreateQcPlanItems(request, callback) {
                        return this.rpcCall(batchCreateQcPlanItems, $root.palexy.sherlock.v1.BatchCreateQcPlanItemsRequest, $root.palexy.sherlock.v1.BatchCreateQcPlanItemsResponse, request, callback);
                    }, "name", { value: "BatchCreateQcPlanItems" });
    
                    /**
                     * Calls BatchCreateQcPlanItems.
                     * @function batchCreateQcPlanItems
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IBatchCreateQcPlanItemsRequest} request BatchCreateQcPlanItemsRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.BatchCreateQcPlanItemsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcPlannerService#deleteQcPlanItem}.
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @typedef DeleteQcPlanItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.DeleteQcPlanItemResponse} [response] DeleteQcPlanItemResponse
                     */
    
                    /**
                     * Calls DeleteQcPlanItem.
                     * @function deleteQcPlanItem
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IDeleteQcPlanItemRequest} request DeleteQcPlanItemRequest message or plain object
                     * @param {palexy.sherlock.v1.QcPlannerService.DeleteQcPlanItemCallback} callback Node-style callback called with the error, if any, and DeleteQcPlanItemResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcPlannerService.prototype.deleteQcPlanItem = function deleteQcPlanItem(request, callback) {
                        return this.rpcCall(deleteQcPlanItem, $root.palexy.sherlock.v1.DeleteQcPlanItemRequest, $root.palexy.sherlock.v1.DeleteQcPlanItemResponse, request, callback);
                    }, "name", { value: "DeleteQcPlanItem" });
    
                    /**
                     * Calls DeleteQcPlanItem.
                     * @function deleteQcPlanItem
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IDeleteQcPlanItemRequest} request DeleteQcPlanItemRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.DeleteQcPlanItemResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcPlannerService#updateVideoTableForQcPlanItem}.
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @typedef UpdateVideoTableForQcPlanItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse} [response] UpdateVideoTableForQcPlanItemResponse
                     */
    
                    /**
                     * Calls UpdateVideoTableForQcPlanItem.
                     * @function updateVideoTableForQcPlanItem
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateVideoTableForQcPlanItemRequest} request UpdateVideoTableForQcPlanItemRequest message or plain object
                     * @param {palexy.sherlock.v1.QcPlannerService.UpdateVideoTableForQcPlanItemCallback} callback Node-style callback called with the error, if any, and UpdateVideoTableForQcPlanItemResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcPlannerService.prototype.updateVideoTableForQcPlanItem = function updateVideoTableForQcPlanItem(request, callback) {
                        return this.rpcCall(updateVideoTableForQcPlanItem, $root.palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest, $root.palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse, request, callback);
                    }, "name", { value: "UpdateVideoTableForQcPlanItem" });
    
                    /**
                     * Calls UpdateVideoTableForQcPlanItem.
                     * @function updateVideoTableForQcPlanItem
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IUpdateVideoTableForQcPlanItemRequest} request UpdateVideoTableForQcPlanItemRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.sherlock.v1.QcPlannerService#batchDeleteQcPlanItems}.
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @typedef BatchDeleteQcPlanItemsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse} [response] BatchDeleteQcPlanItemsResponse
                     */
    
                    /**
                     * Calls BatchDeleteQcPlanItems.
                     * @function batchDeleteQcPlanItems
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IBatchDeleteQcPlanItemsRequest} request BatchDeleteQcPlanItemsRequest message or plain object
                     * @param {palexy.sherlock.v1.QcPlannerService.BatchDeleteQcPlanItemsCallback} callback Node-style callback called with the error, if any, and BatchDeleteQcPlanItemsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(QcPlannerService.prototype.batchDeleteQcPlanItems = function batchDeleteQcPlanItems(request, callback) {
                        return this.rpcCall(batchDeleteQcPlanItems, $root.palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest, $root.palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse, request, callback);
                    }, "name", { value: "BatchDeleteQcPlanItems" });
    
                    /**
                     * Calls BatchDeleteQcPlanItems.
                     * @function batchDeleteQcPlanItems
                     * @memberof palexy.sherlock.v1.QcPlannerService
                     * @instance
                     * @param {palexy.sherlock.v1.IBatchDeleteQcPlanItemsRequest} request BatchDeleteQcPlanItemsRequest message or plain object
                     * @returns {Promise<palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse>} Promise
                     * @variation 2
                     */
    
                    return QcPlannerService;
                })();
    
                v1.ListQcPeriodConfigsRequest = (function() {
    
                    /**
                     * Properties of a ListQcPeriodConfigsRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IListQcPeriodConfigsRequest
                     * @property {number|null} [companyId] ListQcPeriodConfigsRequest companyId
                     */
    
                    /**
                     * Constructs a new ListQcPeriodConfigsRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListQcPeriodConfigsRequest.
                     * @implements IListQcPeriodConfigsRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IListQcPeriodConfigsRequest=} [properties] Properties to set
                     */
                    function ListQcPeriodConfigsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListQcPeriodConfigsRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsRequest
                     * @instance
                     */
                    ListQcPeriodConfigsRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListQcPeriodConfigsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPeriodConfigsRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListQcPeriodConfigsRequest} ListQcPeriodConfigsRequest instance
                     */
                    ListQcPeriodConfigsRequest.create = function create(properties) {
                        return new ListQcPeriodConfigsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListQcPeriodConfigsRequest message. Does not implicitly {@link palexy.sherlock.v1.ListQcPeriodConfigsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPeriodConfigsRequest} message ListQcPeriodConfigsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcPeriodConfigsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListQcPeriodConfigsRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListQcPeriodConfigsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPeriodConfigsRequest} message ListQcPeriodConfigsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcPeriodConfigsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListQcPeriodConfigsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListQcPeriodConfigsRequest} ListQcPeriodConfigsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcPeriodConfigsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListQcPeriodConfigsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListQcPeriodConfigsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListQcPeriodConfigsRequest} ListQcPeriodConfigsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcPeriodConfigsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListQcPeriodConfigsRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListQcPeriodConfigsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListQcPeriodConfigsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListQcPeriodConfigsRequest} ListQcPeriodConfigsRequest
                     */
                    ListQcPeriodConfigsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListQcPeriodConfigsRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListQcPeriodConfigsRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListQcPeriodConfigsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsRequest
                     * @static
                     * @param {palexy.sherlock.v1.ListQcPeriodConfigsRequest} message ListQcPeriodConfigsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListQcPeriodConfigsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        return object;
                    };
    
                    /**
                     * Converts this ListQcPeriodConfigsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListQcPeriodConfigsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListQcPeriodConfigsRequest;
                })();
    
                v1.ListQcPeriodConfigsResponse = (function() {
    
                    /**
                     * Properties of a ListQcPeriodConfigsResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IListQcPeriodConfigsResponse
                     * @property {Array.<palexy.sherlock.v1.IQcPeriodConfig>|null} [qcPeriodConfigs] ListQcPeriodConfigsResponse qcPeriodConfigs
                     */
    
                    /**
                     * Constructs a new ListQcPeriodConfigsResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListQcPeriodConfigsResponse.
                     * @implements IListQcPeriodConfigsResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IListQcPeriodConfigsResponse=} [properties] Properties to set
                     */
                    function ListQcPeriodConfigsResponse(properties) {
                        this.qcPeriodConfigs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListQcPeriodConfigsResponse qcPeriodConfigs.
                     * @member {Array.<palexy.sherlock.v1.IQcPeriodConfig>} qcPeriodConfigs
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsResponse
                     * @instance
                     */
                    ListQcPeriodConfigsResponse.prototype.qcPeriodConfigs = $util.emptyArray;
    
                    /**
                     * Creates a new ListQcPeriodConfigsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPeriodConfigsResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListQcPeriodConfigsResponse} ListQcPeriodConfigsResponse instance
                     */
                    ListQcPeriodConfigsResponse.create = function create(properties) {
                        return new ListQcPeriodConfigsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListQcPeriodConfigsResponse message. Does not implicitly {@link palexy.sherlock.v1.ListQcPeriodConfigsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPeriodConfigsResponse} message ListQcPeriodConfigsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcPeriodConfigsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.qcPeriodConfigs != null && message.qcPeriodConfigs.length)
                            for (var i = 0; i < message.qcPeriodConfigs.length; ++i)
                                $root.palexy.sherlock.v1.QcPeriodConfig.encode(message.qcPeriodConfigs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListQcPeriodConfigsResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListQcPeriodConfigsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPeriodConfigsResponse} message ListQcPeriodConfigsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcPeriodConfigsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListQcPeriodConfigsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListQcPeriodConfigsResponse} ListQcPeriodConfigsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcPeriodConfigsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListQcPeriodConfigsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.qcPeriodConfigs && message.qcPeriodConfigs.length))
                                    message.qcPeriodConfigs = [];
                                message.qcPeriodConfigs.push($root.palexy.sherlock.v1.QcPeriodConfig.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListQcPeriodConfigsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListQcPeriodConfigsResponse} ListQcPeriodConfigsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcPeriodConfigsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListQcPeriodConfigsResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListQcPeriodConfigsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.qcPeriodConfigs != null && message.hasOwnProperty("qcPeriodConfigs")) {
                            if (!Array.isArray(message.qcPeriodConfigs))
                                return "qcPeriodConfigs: array expected";
                            for (var i = 0; i < message.qcPeriodConfigs.length; ++i) {
                                var error = $root.palexy.sherlock.v1.QcPeriodConfig.verify(message.qcPeriodConfigs[i]);
                                if (error)
                                    return "qcPeriodConfigs." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListQcPeriodConfigsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListQcPeriodConfigsResponse} ListQcPeriodConfigsResponse
                     */
                    ListQcPeriodConfigsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListQcPeriodConfigsResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListQcPeriodConfigsResponse();
                        if (object.qcPeriodConfigs) {
                            if (!Array.isArray(object.qcPeriodConfigs))
                                throw TypeError(".palexy.sherlock.v1.ListQcPeriodConfigsResponse.qcPeriodConfigs: array expected");
                            message.qcPeriodConfigs = [];
                            for (var i = 0; i < object.qcPeriodConfigs.length; ++i) {
                                if (typeof object.qcPeriodConfigs[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ListQcPeriodConfigsResponse.qcPeriodConfigs: object expected");
                                message.qcPeriodConfigs[i] = $root.palexy.sherlock.v1.QcPeriodConfig.fromObject(object.qcPeriodConfigs[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListQcPeriodConfigsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsResponse
                     * @static
                     * @param {palexy.sherlock.v1.ListQcPeriodConfigsResponse} message ListQcPeriodConfigsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListQcPeriodConfigsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.qcPeriodConfigs = [];
                        if (message.qcPeriodConfigs && message.qcPeriodConfigs.length) {
                            object.qcPeriodConfigs = [];
                            for (var j = 0; j < message.qcPeriodConfigs.length; ++j)
                                object.qcPeriodConfigs[j] = $root.palexy.sherlock.v1.QcPeriodConfig.toObject(message.qcPeriodConfigs[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListQcPeriodConfigsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListQcPeriodConfigsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListQcPeriodConfigsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListQcPeriodConfigsResponse;
                })();
    
                v1.QcPeriodConfig = (function() {
    
                    /**
                     * Properties of a QcPeriodConfig.
                     * @memberof palexy.sherlock.v1
                     * @interface IQcPeriodConfig
                     * @property {number|null} [id] QcPeriodConfig id
                     * @property {string|null} [name] QcPeriodConfig name
                     * @property {number|null} [companyId] QcPeriodConfig companyId
                     * @property {string|null} [startingDateId] QcPeriodConfig startingDateId
                     * @property {number|null} [numDaysInPeriod] QcPeriodConfig numDaysInPeriod
                     * @property {number|null} [percentageOfStore] QcPeriodConfig percentageOfStore
                     * @property {number|null} [numQcDaysPerStore] QcPeriodConfig numQcDaysPerStore
                     * @property {boolean|null} [isActive] QcPeriodConfig isActive
                     * @property {string|null} [creator] QcPeriodConfig creator
                     * @property {number|null} [createTimeMs] QcPeriodConfig createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] QcPeriodConfig lastUpdateTimeMs
                     * @property {string|null} [plannedUntilDateId] QcPeriodConfig plannedUntilDateId
                     * @property {number|null} [planDetailLastGenerateTimeMs] QcPeriodConfig planDetailLastGenerateTimeMs
                     * @property {string|null} [displayName] QcPeriodConfig displayName
                     */
    
                    /**
                     * Constructs a new QcPeriodConfig.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a QcPeriodConfig.
                     * @implements IQcPeriodConfig
                     * @constructor
                     * @param {palexy.sherlock.v1.IQcPeriodConfig=} [properties] Properties to set
                     */
                    function QcPeriodConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * QcPeriodConfig id.
                     * @member {number} id
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcPeriodConfig name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.name = "";
    
                    /**
                     * QcPeriodConfig companyId.
                     * @member {number} companyId
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcPeriodConfig startingDateId.
                     * @member {string} startingDateId
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.startingDateId = "";
    
                    /**
                     * QcPeriodConfig numDaysInPeriod.
                     * @member {number} numDaysInPeriod
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.numDaysInPeriod = 0;
    
                    /**
                     * QcPeriodConfig percentageOfStore.
                     * @member {number} percentageOfStore
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.percentageOfStore = 0;
    
                    /**
                     * QcPeriodConfig numQcDaysPerStore.
                     * @member {number} numQcDaysPerStore
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.numQcDaysPerStore = 0;
    
                    /**
                     * QcPeriodConfig isActive.
                     * @member {boolean} isActive
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.isActive = false;
    
                    /**
                     * QcPeriodConfig creator.
                     * @member {string} creator
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.creator = "";
    
                    /**
                     * QcPeriodConfig createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcPeriodConfig lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcPeriodConfig plannedUntilDateId.
                     * @member {string} plannedUntilDateId
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.plannedUntilDateId = "";
    
                    /**
                     * QcPeriodConfig planDetailLastGenerateTimeMs.
                     * @member {number} planDetailLastGenerateTimeMs
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.planDetailLastGenerateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcPeriodConfig displayName.
                     * @member {string} displayName
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     */
                    QcPeriodConfig.prototype.displayName = "";
    
                    /**
                     * Creates a new QcPeriodConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @static
                     * @param {palexy.sherlock.v1.IQcPeriodConfig=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.QcPeriodConfig} QcPeriodConfig instance
                     */
                    QcPeriodConfig.create = function create(properties) {
                        return new QcPeriodConfig(properties);
                    };
    
                    /**
                     * Encodes the specified QcPeriodConfig message. Does not implicitly {@link palexy.sherlock.v1.QcPeriodConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @static
                     * @param {palexy.sherlock.v1.IQcPeriodConfig} message QcPeriodConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QcPeriodConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.companyId);
                        if (message.startingDateId != null && Object.hasOwnProperty.call(message, "startingDateId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.startingDateId);
                        if (message.numDaysInPeriod != null && Object.hasOwnProperty.call(message, "numDaysInPeriod"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.numDaysInPeriod);
                        if (message.percentageOfStore != null && Object.hasOwnProperty.call(message, "percentageOfStore"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.percentageOfStore);
                        if (message.numQcDaysPerStore != null && Object.hasOwnProperty.call(message, "numQcDaysPerStore"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.numQcDaysPerStore);
                        if (message.isActive != null && Object.hasOwnProperty.call(message, "isActive"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isActive);
                        if (message.creator != null && Object.hasOwnProperty.call(message, "creator"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.creator);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.lastUpdateTimeMs);
                        if (message.plannedUntilDateId != null && Object.hasOwnProperty.call(message, "plannedUntilDateId"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.plannedUntilDateId);
                        if (message.planDetailLastGenerateTimeMs != null && Object.hasOwnProperty.call(message, "planDetailLastGenerateTimeMs"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int64(message.planDetailLastGenerateTimeMs);
                        if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.displayName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified QcPeriodConfig message, length delimited. Does not implicitly {@link palexy.sherlock.v1.QcPeriodConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @static
                     * @param {palexy.sherlock.v1.IQcPeriodConfig} message QcPeriodConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QcPeriodConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a QcPeriodConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.QcPeriodConfig} QcPeriodConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QcPeriodConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.QcPeriodConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.companyId = reader.int64();
                                break;
                            case 4:
                                message.startingDateId = reader.string();
                                break;
                            case 5:
                                message.numDaysInPeriod = reader.int32();
                                break;
                            case 6:
                                message.percentageOfStore = reader.int32();
                                break;
                            case 7:
                                message.numQcDaysPerStore = reader.int32();
                                break;
                            case 8:
                                message.isActive = reader.bool();
                                break;
                            case 9:
                                message.creator = reader.string();
                                break;
                            case 10:
                                message.createTimeMs = reader.int64();
                                break;
                            case 11:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 12:
                                message.plannedUntilDateId = reader.string();
                                break;
                            case 13:
                                message.planDetailLastGenerateTimeMs = reader.int64();
                                break;
                            case 14:
                                message.displayName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a QcPeriodConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.QcPeriodConfig} QcPeriodConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QcPeriodConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a QcPeriodConfig message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    QcPeriodConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.startingDateId != null && message.hasOwnProperty("startingDateId"))
                            if (!$util.isString(message.startingDateId))
                                return "startingDateId: string expected";
                        if (message.numDaysInPeriod != null && message.hasOwnProperty("numDaysInPeriod"))
                            if (!$util.isInteger(message.numDaysInPeriod))
                                return "numDaysInPeriod: integer expected";
                        if (message.percentageOfStore != null && message.hasOwnProperty("percentageOfStore"))
                            if (!$util.isInteger(message.percentageOfStore))
                                return "percentageOfStore: integer expected";
                        if (message.numQcDaysPerStore != null && message.hasOwnProperty("numQcDaysPerStore"))
                            if (!$util.isInteger(message.numQcDaysPerStore))
                                return "numQcDaysPerStore: integer expected";
                        if (message.isActive != null && message.hasOwnProperty("isActive"))
                            if (typeof message.isActive !== "boolean")
                                return "isActive: boolean expected";
                        if (message.creator != null && message.hasOwnProperty("creator"))
                            if (!$util.isString(message.creator))
                                return "creator: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.plannedUntilDateId != null && message.hasOwnProperty("plannedUntilDateId"))
                            if (!$util.isString(message.plannedUntilDateId))
                                return "plannedUntilDateId: string expected";
                        if (message.planDetailLastGenerateTimeMs != null && message.hasOwnProperty("planDetailLastGenerateTimeMs"))
                            if (!$util.isInteger(message.planDetailLastGenerateTimeMs) && !(message.planDetailLastGenerateTimeMs && $util.isInteger(message.planDetailLastGenerateTimeMs.low) && $util.isInteger(message.planDetailLastGenerateTimeMs.high)))
                                return "planDetailLastGenerateTimeMs: integer|Long expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a QcPeriodConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.QcPeriodConfig} QcPeriodConfig
                     */
                    QcPeriodConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.QcPeriodConfig)
                            return object;
                        var message = new $root.palexy.sherlock.v1.QcPeriodConfig();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.startingDateId != null)
                            message.startingDateId = String(object.startingDateId);
                        if (object.numDaysInPeriod != null)
                            message.numDaysInPeriod = object.numDaysInPeriod | 0;
                        if (object.percentageOfStore != null)
                            message.percentageOfStore = object.percentageOfStore | 0;
                        if (object.numQcDaysPerStore != null)
                            message.numQcDaysPerStore = object.numQcDaysPerStore | 0;
                        if (object.isActive != null)
                            message.isActive = Boolean(object.isActive);
                        if (object.creator != null)
                            message.creator = String(object.creator);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.plannedUntilDateId != null)
                            message.plannedUntilDateId = String(object.plannedUntilDateId);
                        if (object.planDetailLastGenerateTimeMs != null)
                            if ($util.Long)
                                (message.planDetailLastGenerateTimeMs = $util.Long.fromValue(object.planDetailLastGenerateTimeMs)).unsigned = false;
                            else if (typeof object.planDetailLastGenerateTimeMs === "string")
                                message.planDetailLastGenerateTimeMs = parseInt(object.planDetailLastGenerateTimeMs, 10);
                            else if (typeof object.planDetailLastGenerateTimeMs === "number")
                                message.planDetailLastGenerateTimeMs = object.planDetailLastGenerateTimeMs;
                            else if (typeof object.planDetailLastGenerateTimeMs === "object")
                                message.planDetailLastGenerateTimeMs = new $util.LongBits(object.planDetailLastGenerateTimeMs.low >>> 0, object.planDetailLastGenerateTimeMs.high >>> 0).toNumber();
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a QcPeriodConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @static
                     * @param {palexy.sherlock.v1.QcPeriodConfig} message QcPeriodConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    QcPeriodConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.startingDateId = "";
                            object.numDaysInPeriod = 0;
                            object.percentageOfStore = 0;
                            object.numQcDaysPerStore = 0;
                            object.isActive = false;
                            object.creator = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            object.plannedUntilDateId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.planDetailLastGenerateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.planDetailLastGenerateTimeMs = options.longs === String ? "0" : 0;
                            object.displayName = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.startingDateId != null && message.hasOwnProperty("startingDateId"))
                            object.startingDateId = message.startingDateId;
                        if (message.numDaysInPeriod != null && message.hasOwnProperty("numDaysInPeriod"))
                            object.numDaysInPeriod = message.numDaysInPeriod;
                        if (message.percentageOfStore != null && message.hasOwnProperty("percentageOfStore"))
                            object.percentageOfStore = message.percentageOfStore;
                        if (message.numQcDaysPerStore != null && message.hasOwnProperty("numQcDaysPerStore"))
                            object.numQcDaysPerStore = message.numQcDaysPerStore;
                        if (message.isActive != null && message.hasOwnProperty("isActive"))
                            object.isActive = message.isActive;
                        if (message.creator != null && message.hasOwnProperty("creator"))
                            object.creator = message.creator;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.plannedUntilDateId != null && message.hasOwnProperty("plannedUntilDateId"))
                            object.plannedUntilDateId = message.plannedUntilDateId;
                        if (message.planDetailLastGenerateTimeMs != null && message.hasOwnProperty("planDetailLastGenerateTimeMs"))
                            if (typeof message.planDetailLastGenerateTimeMs === "number")
                                object.planDetailLastGenerateTimeMs = options.longs === String ? String(message.planDetailLastGenerateTimeMs) : message.planDetailLastGenerateTimeMs;
                            else
                                object.planDetailLastGenerateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.planDetailLastGenerateTimeMs) : options.longs === Number ? new $util.LongBits(message.planDetailLastGenerateTimeMs.low >>> 0, message.planDetailLastGenerateTimeMs.high >>> 0).toNumber() : message.planDetailLastGenerateTimeMs;
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        return object;
                    };
    
                    /**
                     * Converts this QcPeriodConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.QcPeriodConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    QcPeriodConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return QcPeriodConfig;
                })();
    
                v1.CreateQcPeriodConfigRequest = (function() {
    
                    /**
                     * Properties of a CreateQcPeriodConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateQcPeriodConfigRequest
                     * @property {palexy.sherlock.v1.IQcPeriodConfig|null} [periodConfig] CreateQcPeriodConfigRequest periodConfig
                     */
    
                    /**
                     * Constructs a new CreateQcPeriodConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateQcPeriodConfigRequest.
                     * @implements ICreateQcPeriodConfigRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateQcPeriodConfigRequest=} [properties] Properties to set
                     */
                    function CreateQcPeriodConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateQcPeriodConfigRequest periodConfig.
                     * @member {palexy.sherlock.v1.IQcPeriodConfig|null|undefined} periodConfig
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigRequest
                     * @instance
                     */
                    CreateQcPeriodConfigRequest.prototype.periodConfig = null;
    
                    /**
                     * Creates a new CreateQcPeriodConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPeriodConfigRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateQcPeriodConfigRequest} CreateQcPeriodConfigRequest instance
                     */
                    CreateQcPeriodConfigRequest.create = function create(properties) {
                        return new CreateQcPeriodConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateQcPeriodConfigRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateQcPeriodConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPeriodConfigRequest} message CreateQcPeriodConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateQcPeriodConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.periodConfig != null && Object.hasOwnProperty.call(message, "periodConfig"))
                            $root.palexy.sherlock.v1.QcPeriodConfig.encode(message.periodConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateQcPeriodConfigRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateQcPeriodConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPeriodConfigRequest} message CreateQcPeriodConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateQcPeriodConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateQcPeriodConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateQcPeriodConfigRequest} CreateQcPeriodConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateQcPeriodConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateQcPeriodConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.periodConfig = $root.palexy.sherlock.v1.QcPeriodConfig.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateQcPeriodConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateQcPeriodConfigRequest} CreateQcPeriodConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateQcPeriodConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateQcPeriodConfigRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateQcPeriodConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.periodConfig != null && message.hasOwnProperty("periodConfig")) {
                            var error = $root.palexy.sherlock.v1.QcPeriodConfig.verify(message.periodConfig);
                            if (error)
                                return "periodConfig." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateQcPeriodConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateQcPeriodConfigRequest} CreateQcPeriodConfigRequest
                     */
                    CreateQcPeriodConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateQcPeriodConfigRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateQcPeriodConfigRequest();
                        if (object.periodConfig != null) {
                            if (typeof object.periodConfig !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateQcPeriodConfigRequest.periodConfig: object expected");
                            message.periodConfig = $root.palexy.sherlock.v1.QcPeriodConfig.fromObject(object.periodConfig);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateQcPeriodConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateQcPeriodConfigRequest} message CreateQcPeriodConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateQcPeriodConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.periodConfig = null;
                        if (message.periodConfig != null && message.hasOwnProperty("periodConfig"))
                            object.periodConfig = $root.palexy.sherlock.v1.QcPeriodConfig.toObject(message.periodConfig, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateQcPeriodConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateQcPeriodConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateQcPeriodConfigRequest;
                })();
    
                v1.CreateQcPeriodConfigResponse = (function() {
    
                    /**
                     * Properties of a CreateQcPeriodConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateQcPeriodConfigResponse
                     * @property {palexy.sherlock.v1.IQcPeriodConfig|null} [periodConfig] CreateQcPeriodConfigResponse periodConfig
                     */
    
                    /**
                     * Constructs a new CreateQcPeriodConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateQcPeriodConfigResponse.
                     * @implements ICreateQcPeriodConfigResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateQcPeriodConfigResponse=} [properties] Properties to set
                     */
                    function CreateQcPeriodConfigResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateQcPeriodConfigResponse periodConfig.
                     * @member {palexy.sherlock.v1.IQcPeriodConfig|null|undefined} periodConfig
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigResponse
                     * @instance
                     */
                    CreateQcPeriodConfigResponse.prototype.periodConfig = null;
    
                    /**
                     * Creates a new CreateQcPeriodConfigResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPeriodConfigResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateQcPeriodConfigResponse} CreateQcPeriodConfigResponse instance
                     */
                    CreateQcPeriodConfigResponse.create = function create(properties) {
                        return new CreateQcPeriodConfigResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateQcPeriodConfigResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateQcPeriodConfigResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPeriodConfigResponse} message CreateQcPeriodConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateQcPeriodConfigResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.periodConfig != null && Object.hasOwnProperty.call(message, "periodConfig"))
                            $root.palexy.sherlock.v1.QcPeriodConfig.encode(message.periodConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateQcPeriodConfigResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateQcPeriodConfigResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPeriodConfigResponse} message CreateQcPeriodConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateQcPeriodConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateQcPeriodConfigResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateQcPeriodConfigResponse} CreateQcPeriodConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateQcPeriodConfigResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateQcPeriodConfigResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.periodConfig = $root.palexy.sherlock.v1.QcPeriodConfig.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateQcPeriodConfigResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateQcPeriodConfigResponse} CreateQcPeriodConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateQcPeriodConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateQcPeriodConfigResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateQcPeriodConfigResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.periodConfig != null && message.hasOwnProperty("periodConfig")) {
                            var error = $root.palexy.sherlock.v1.QcPeriodConfig.verify(message.periodConfig);
                            if (error)
                                return "periodConfig." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateQcPeriodConfigResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateQcPeriodConfigResponse} CreateQcPeriodConfigResponse
                     */
                    CreateQcPeriodConfigResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateQcPeriodConfigResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateQcPeriodConfigResponse();
                        if (object.periodConfig != null) {
                            if (typeof object.periodConfig !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateQcPeriodConfigResponse.periodConfig: object expected");
                            message.periodConfig = $root.palexy.sherlock.v1.QcPeriodConfig.fromObject(object.periodConfig);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateQcPeriodConfigResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateQcPeriodConfigResponse} message CreateQcPeriodConfigResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateQcPeriodConfigResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.periodConfig = null;
                        if (message.periodConfig != null && message.hasOwnProperty("periodConfig"))
                            object.periodConfig = $root.palexy.sherlock.v1.QcPeriodConfig.toObject(message.periodConfig, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateQcPeriodConfigResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateQcPeriodConfigResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateQcPeriodConfigResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateQcPeriodConfigResponse;
                })();
    
                v1.DeleteQcPeriodConfigRequest = (function() {
    
                    /**
                     * Properties of a DeleteQcPeriodConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IDeleteQcPeriodConfigRequest
                     * @property {string|null} [name] DeleteQcPeriodConfigRequest name
                     */
    
                    /**
                     * Constructs a new DeleteQcPeriodConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DeleteQcPeriodConfigRequest.
                     * @implements IDeleteQcPeriodConfigRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IDeleteQcPeriodConfigRequest=} [properties] Properties to set
                     */
                    function DeleteQcPeriodConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteQcPeriodConfigRequest name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigRequest
                     * @instance
                     */
                    DeleteQcPeriodConfigRequest.prototype.name = "";
    
                    /**
                     * Creates a new DeleteQcPeriodConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPeriodConfigRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DeleteQcPeriodConfigRequest} DeleteQcPeriodConfigRequest instance
                     */
                    DeleteQcPeriodConfigRequest.create = function create(properties) {
                        return new DeleteQcPeriodConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteQcPeriodConfigRequest message. Does not implicitly {@link palexy.sherlock.v1.DeleteQcPeriodConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPeriodConfigRequest} message DeleteQcPeriodConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcPeriodConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteQcPeriodConfigRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DeleteQcPeriodConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPeriodConfigRequest} message DeleteQcPeriodConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcPeriodConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteQcPeriodConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DeleteQcPeriodConfigRequest} DeleteQcPeriodConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcPeriodConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DeleteQcPeriodConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteQcPeriodConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DeleteQcPeriodConfigRequest} DeleteQcPeriodConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcPeriodConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteQcPeriodConfigRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteQcPeriodConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteQcPeriodConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DeleteQcPeriodConfigRequest} DeleteQcPeriodConfigRequest
                     */
                    DeleteQcPeriodConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DeleteQcPeriodConfigRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.DeleteQcPeriodConfigRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteQcPeriodConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.DeleteQcPeriodConfigRequest} message DeleteQcPeriodConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteQcPeriodConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteQcPeriodConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteQcPeriodConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteQcPeriodConfigRequest;
                })();
    
                v1.DeleteQcPeriodConfigResponse = (function() {
    
                    /**
                     * Properties of a DeleteQcPeriodConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IDeleteQcPeriodConfigResponse
                     */
    
                    /**
                     * Constructs a new DeleteQcPeriodConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DeleteQcPeriodConfigResponse.
                     * @implements IDeleteQcPeriodConfigResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IDeleteQcPeriodConfigResponse=} [properties] Properties to set
                     */
                    function DeleteQcPeriodConfigResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new DeleteQcPeriodConfigResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPeriodConfigResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DeleteQcPeriodConfigResponse} DeleteQcPeriodConfigResponse instance
                     */
                    DeleteQcPeriodConfigResponse.create = function create(properties) {
                        return new DeleteQcPeriodConfigResponse(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteQcPeriodConfigResponse message. Does not implicitly {@link palexy.sherlock.v1.DeleteQcPeriodConfigResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPeriodConfigResponse} message DeleteQcPeriodConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcPeriodConfigResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteQcPeriodConfigResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DeleteQcPeriodConfigResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPeriodConfigResponse} message DeleteQcPeriodConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcPeriodConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteQcPeriodConfigResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DeleteQcPeriodConfigResponse} DeleteQcPeriodConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcPeriodConfigResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DeleteQcPeriodConfigResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteQcPeriodConfigResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DeleteQcPeriodConfigResponse} DeleteQcPeriodConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcPeriodConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteQcPeriodConfigResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteQcPeriodConfigResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteQcPeriodConfigResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DeleteQcPeriodConfigResponse} DeleteQcPeriodConfigResponse
                     */
                    DeleteQcPeriodConfigResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DeleteQcPeriodConfigResponse)
                            return object;
                        return new $root.palexy.sherlock.v1.DeleteQcPeriodConfigResponse();
                    };
    
                    /**
                     * Creates a plain object from a DeleteQcPeriodConfigResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.DeleteQcPeriodConfigResponse} message DeleteQcPeriodConfigResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteQcPeriodConfigResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this DeleteQcPeriodConfigResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DeleteQcPeriodConfigResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteQcPeriodConfigResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteQcPeriodConfigResponse;
                })();
    
                v1.UpdateQcPeriodConfigRequest = (function() {
    
                    /**
                     * Properties of an UpdateQcPeriodConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IUpdateQcPeriodConfigRequest
                     * @property {palexy.sherlock.v1.IQcPeriodConfig|null} [config] UpdateQcPeriodConfigRequest config
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateQcPeriodConfigRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateQcPeriodConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UpdateQcPeriodConfigRequest.
                     * @implements IUpdateQcPeriodConfigRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IUpdateQcPeriodConfigRequest=} [properties] Properties to set
                     */
                    function UpdateQcPeriodConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateQcPeriodConfigRequest config.
                     * @member {palexy.sherlock.v1.IQcPeriodConfig|null|undefined} config
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigRequest
                     * @instance
                     */
                    UpdateQcPeriodConfigRequest.prototype.config = null;
    
                    /**
                     * UpdateQcPeriodConfigRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigRequest
                     * @instance
                     */
                    UpdateQcPeriodConfigRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateQcPeriodConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateQcPeriodConfigRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UpdateQcPeriodConfigRequest} UpdateQcPeriodConfigRequest instance
                     */
                    UpdateQcPeriodConfigRequest.create = function create(properties) {
                        return new UpdateQcPeriodConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateQcPeriodConfigRequest message. Does not implicitly {@link palexy.sherlock.v1.UpdateQcPeriodConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateQcPeriodConfigRequest} message UpdateQcPeriodConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateQcPeriodConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                            $root.palexy.sherlock.v1.QcPeriodConfig.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateQcPeriodConfigRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UpdateQcPeriodConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateQcPeriodConfigRequest} message UpdateQcPeriodConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateQcPeriodConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateQcPeriodConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UpdateQcPeriodConfigRequest} UpdateQcPeriodConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateQcPeriodConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UpdateQcPeriodConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.config = $root.palexy.sherlock.v1.QcPeriodConfig.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateQcPeriodConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UpdateQcPeriodConfigRequest} UpdateQcPeriodConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateQcPeriodConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateQcPeriodConfigRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateQcPeriodConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.config != null && message.hasOwnProperty("config")) {
                            var error = $root.palexy.sherlock.v1.QcPeriodConfig.verify(message.config);
                            if (error)
                                return "config." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateQcPeriodConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UpdateQcPeriodConfigRequest} UpdateQcPeriodConfigRequest
                     */
                    UpdateQcPeriodConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UpdateQcPeriodConfigRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.UpdateQcPeriodConfigRequest();
                        if (object.config != null) {
                            if (typeof object.config !== "object")
                                throw TypeError(".palexy.sherlock.v1.UpdateQcPeriodConfigRequest.config: object expected");
                            message.config = $root.palexy.sherlock.v1.QcPeriodConfig.fromObject(object.config);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.sherlock.v1.UpdateQcPeriodConfigRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateQcPeriodConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.UpdateQcPeriodConfigRequest} message UpdateQcPeriodConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateQcPeriodConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.config = null;
                            object.updateMask = null;
                        }
                        if (message.config != null && message.hasOwnProperty("config"))
                            object.config = $root.palexy.sherlock.v1.QcPeriodConfig.toObject(message.config, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateQcPeriodConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateQcPeriodConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateQcPeriodConfigRequest;
                })();
    
                v1.UpdateQcPeriodConfigResponse = (function() {
    
                    /**
                     * Properties of an UpdateQcPeriodConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IUpdateQcPeriodConfigResponse
                     * @property {palexy.sherlock.v1.IQcPeriodConfig|null} [config] UpdateQcPeriodConfigResponse config
                     */
    
                    /**
                     * Constructs a new UpdateQcPeriodConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UpdateQcPeriodConfigResponse.
                     * @implements IUpdateQcPeriodConfigResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IUpdateQcPeriodConfigResponse=} [properties] Properties to set
                     */
                    function UpdateQcPeriodConfigResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateQcPeriodConfigResponse config.
                     * @member {palexy.sherlock.v1.IQcPeriodConfig|null|undefined} config
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigResponse
                     * @instance
                     */
                    UpdateQcPeriodConfigResponse.prototype.config = null;
    
                    /**
                     * Creates a new UpdateQcPeriodConfigResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateQcPeriodConfigResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UpdateQcPeriodConfigResponse} UpdateQcPeriodConfigResponse instance
                     */
                    UpdateQcPeriodConfigResponse.create = function create(properties) {
                        return new UpdateQcPeriodConfigResponse(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateQcPeriodConfigResponse message. Does not implicitly {@link palexy.sherlock.v1.UpdateQcPeriodConfigResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateQcPeriodConfigResponse} message UpdateQcPeriodConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateQcPeriodConfigResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                            $root.palexy.sherlock.v1.QcPeriodConfig.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateQcPeriodConfigResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UpdateQcPeriodConfigResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateQcPeriodConfigResponse} message UpdateQcPeriodConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateQcPeriodConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateQcPeriodConfigResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UpdateQcPeriodConfigResponse} UpdateQcPeriodConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateQcPeriodConfigResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UpdateQcPeriodConfigResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.config = $root.palexy.sherlock.v1.QcPeriodConfig.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateQcPeriodConfigResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UpdateQcPeriodConfigResponse} UpdateQcPeriodConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateQcPeriodConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateQcPeriodConfigResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateQcPeriodConfigResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.config != null && message.hasOwnProperty("config")) {
                            var error = $root.palexy.sherlock.v1.QcPeriodConfig.verify(message.config);
                            if (error)
                                return "config." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateQcPeriodConfigResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UpdateQcPeriodConfigResponse} UpdateQcPeriodConfigResponse
                     */
                    UpdateQcPeriodConfigResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UpdateQcPeriodConfigResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.UpdateQcPeriodConfigResponse();
                        if (object.config != null) {
                            if (typeof object.config !== "object")
                                throw TypeError(".palexy.sherlock.v1.UpdateQcPeriodConfigResponse.config: object expected");
                            message.config = $root.palexy.sherlock.v1.QcPeriodConfig.fromObject(object.config);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateQcPeriodConfigResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.UpdateQcPeriodConfigResponse} message UpdateQcPeriodConfigResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateQcPeriodConfigResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.config = null;
                        if (message.config != null && message.hasOwnProperty("config"))
                            object.config = $root.palexy.sherlock.v1.QcPeriodConfig.toObject(message.config, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateQcPeriodConfigResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UpdateQcPeriodConfigResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateQcPeriodConfigResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateQcPeriodConfigResponse;
                })();
    
                v1.QcPlanItem = (function() {
    
                    /**
                     * Properties of a QcPlanItem.
                     * @memberof palexy.sherlock.v1
                     * @interface IQcPlanItem
                     * @property {number|null} [id] QcPlanItem id
                     * @property {string|null} [name] QcPlanItem name
                     * @property {number|null} [periodConfigId] QcPlanItem periodConfigId
                     * @property {number|null} [companyId] QcPlanItem companyId
                     * @property {number|null} [storeId] QcPlanItem storeId
                     * @property {string|null} [dateId] QcPlanItem dateId
                     * @property {number|null} [createTimeMs] QcPlanItem createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] QcPlanItem lastUpdateTimeMs
                     * @property {boolean|null} [isCustomItem] QcPlanItem isCustomItem
                     * @property {boolean|null} [isAppliedToVideoTable] QcPlanItem isAppliedToVideoTable
                     * @property {number|null} [videoTableAppliedTimeMs] QcPlanItem videoTableAppliedTimeMs
                     */
    
                    /**
                     * Constructs a new QcPlanItem.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a QcPlanItem.
                     * @implements IQcPlanItem
                     * @constructor
                     * @param {palexy.sherlock.v1.IQcPlanItem=} [properties] Properties to set
                     */
                    function QcPlanItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * QcPlanItem id.
                     * @member {number} id
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     */
                    QcPlanItem.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcPlanItem name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     */
                    QcPlanItem.prototype.name = "";
    
                    /**
                     * QcPlanItem periodConfigId.
                     * @member {number} periodConfigId
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     */
                    QcPlanItem.prototype.periodConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcPlanItem companyId.
                     * @member {number} companyId
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     */
                    QcPlanItem.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcPlanItem storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     */
                    QcPlanItem.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcPlanItem dateId.
                     * @member {string} dateId
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     */
                    QcPlanItem.prototype.dateId = "";
    
                    /**
                     * QcPlanItem createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     */
                    QcPlanItem.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcPlanItem lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     */
                    QcPlanItem.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * QcPlanItem isCustomItem.
                     * @member {boolean} isCustomItem
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     */
                    QcPlanItem.prototype.isCustomItem = false;
    
                    /**
                     * QcPlanItem isAppliedToVideoTable.
                     * @member {boolean} isAppliedToVideoTable
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     */
                    QcPlanItem.prototype.isAppliedToVideoTable = false;
    
                    /**
                     * QcPlanItem videoTableAppliedTimeMs.
                     * @member {number} videoTableAppliedTimeMs
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     */
                    QcPlanItem.prototype.videoTableAppliedTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new QcPlanItem instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @static
                     * @param {palexy.sherlock.v1.IQcPlanItem=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.QcPlanItem} QcPlanItem instance
                     */
                    QcPlanItem.create = function create(properties) {
                        return new QcPlanItem(properties);
                    };
    
                    /**
                     * Encodes the specified QcPlanItem message. Does not implicitly {@link palexy.sherlock.v1.QcPlanItem.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @static
                     * @param {palexy.sherlock.v1.IQcPlanItem} message QcPlanItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QcPlanItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.periodConfigId != null && Object.hasOwnProperty.call(message, "periodConfigId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.periodConfigId);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.companyId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.dateId);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.lastUpdateTimeMs);
                        if (message.isCustomItem != null && Object.hasOwnProperty.call(message, "isCustomItem"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isCustomItem);
                        if (message.isAppliedToVideoTable != null && Object.hasOwnProperty.call(message, "isAppliedToVideoTable"))
                            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isAppliedToVideoTable);
                        if (message.videoTableAppliedTimeMs != null && Object.hasOwnProperty.call(message, "videoTableAppliedTimeMs"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.videoTableAppliedTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified QcPlanItem message, length delimited. Does not implicitly {@link palexy.sherlock.v1.QcPlanItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @static
                     * @param {palexy.sherlock.v1.IQcPlanItem} message QcPlanItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QcPlanItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a QcPlanItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.QcPlanItem} QcPlanItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QcPlanItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.QcPlanItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.periodConfigId = reader.int64();
                                break;
                            case 4:
                                message.companyId = reader.int64();
                                break;
                            case 5:
                                message.storeId = reader.int64();
                                break;
                            case 6:
                                message.dateId = reader.string();
                                break;
                            case 7:
                                message.createTimeMs = reader.int64();
                                break;
                            case 8:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 9:
                                message.isCustomItem = reader.bool();
                                break;
                            case 10:
                                message.isAppliedToVideoTable = reader.bool();
                                break;
                            case 11:
                                message.videoTableAppliedTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a QcPlanItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.QcPlanItem} QcPlanItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QcPlanItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a QcPlanItem message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    QcPlanItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.periodConfigId != null && message.hasOwnProperty("periodConfigId"))
                            if (!$util.isInteger(message.periodConfigId) && !(message.periodConfigId && $util.isInteger(message.periodConfigId.low) && $util.isInteger(message.periodConfigId.high)))
                                return "periodConfigId: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.isCustomItem != null && message.hasOwnProperty("isCustomItem"))
                            if (typeof message.isCustomItem !== "boolean")
                                return "isCustomItem: boolean expected";
                        if (message.isAppliedToVideoTable != null && message.hasOwnProperty("isAppliedToVideoTable"))
                            if (typeof message.isAppliedToVideoTable !== "boolean")
                                return "isAppliedToVideoTable: boolean expected";
                        if (message.videoTableAppliedTimeMs != null && message.hasOwnProperty("videoTableAppliedTimeMs"))
                            if (!$util.isInteger(message.videoTableAppliedTimeMs) && !(message.videoTableAppliedTimeMs && $util.isInteger(message.videoTableAppliedTimeMs.low) && $util.isInteger(message.videoTableAppliedTimeMs.high)))
                                return "videoTableAppliedTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a QcPlanItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.QcPlanItem} QcPlanItem
                     */
                    QcPlanItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.QcPlanItem)
                            return object;
                        var message = new $root.palexy.sherlock.v1.QcPlanItem();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.periodConfigId != null)
                            if ($util.Long)
                                (message.periodConfigId = $util.Long.fromValue(object.periodConfigId)).unsigned = false;
                            else if (typeof object.periodConfigId === "string")
                                message.periodConfigId = parseInt(object.periodConfigId, 10);
                            else if (typeof object.periodConfigId === "number")
                                message.periodConfigId = object.periodConfigId;
                            else if (typeof object.periodConfigId === "object")
                                message.periodConfigId = new $util.LongBits(object.periodConfigId.low >>> 0, object.periodConfigId.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.isCustomItem != null)
                            message.isCustomItem = Boolean(object.isCustomItem);
                        if (object.isAppliedToVideoTable != null)
                            message.isAppliedToVideoTable = Boolean(object.isAppliedToVideoTable);
                        if (object.videoTableAppliedTimeMs != null)
                            if ($util.Long)
                                (message.videoTableAppliedTimeMs = $util.Long.fromValue(object.videoTableAppliedTimeMs)).unsigned = false;
                            else if (typeof object.videoTableAppliedTimeMs === "string")
                                message.videoTableAppliedTimeMs = parseInt(object.videoTableAppliedTimeMs, 10);
                            else if (typeof object.videoTableAppliedTimeMs === "number")
                                message.videoTableAppliedTimeMs = object.videoTableAppliedTimeMs;
                            else if (typeof object.videoTableAppliedTimeMs === "object")
                                message.videoTableAppliedTimeMs = new $util.LongBits(object.videoTableAppliedTimeMs.low >>> 0, object.videoTableAppliedTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a QcPlanItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @static
                     * @param {palexy.sherlock.v1.QcPlanItem} message QcPlanItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    QcPlanItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.periodConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.periodConfigId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            object.isCustomItem = false;
                            object.isAppliedToVideoTable = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.videoTableAppliedTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.videoTableAppliedTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.periodConfigId != null && message.hasOwnProperty("periodConfigId"))
                            if (typeof message.periodConfigId === "number")
                                object.periodConfigId = options.longs === String ? String(message.periodConfigId) : message.periodConfigId;
                            else
                                object.periodConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.periodConfigId) : options.longs === Number ? new $util.LongBits(message.periodConfigId.low >>> 0, message.periodConfigId.high >>> 0).toNumber() : message.periodConfigId;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.isCustomItem != null && message.hasOwnProperty("isCustomItem"))
                            object.isCustomItem = message.isCustomItem;
                        if (message.isAppliedToVideoTable != null && message.hasOwnProperty("isAppliedToVideoTable"))
                            object.isAppliedToVideoTable = message.isAppliedToVideoTable;
                        if (message.videoTableAppliedTimeMs != null && message.hasOwnProperty("videoTableAppliedTimeMs"))
                            if (typeof message.videoTableAppliedTimeMs === "number")
                                object.videoTableAppliedTimeMs = options.longs === String ? String(message.videoTableAppliedTimeMs) : message.videoTableAppliedTimeMs;
                            else
                                object.videoTableAppliedTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.videoTableAppliedTimeMs) : options.longs === Number ? new $util.LongBits(message.videoTableAppliedTimeMs.low >>> 0, message.videoTableAppliedTimeMs.high >>> 0).toNumber() : message.videoTableAppliedTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this QcPlanItem to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.QcPlanItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    QcPlanItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return QcPlanItem;
                })();
    
                v1.ListQcPlanItemsRequest = (function() {
    
                    /**
                     * Properties of a ListQcPlanItemsRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IListQcPlanItemsRequest
                     * @property {number|null} [periodConfigId] ListQcPlanItemsRequest periodConfigId
                     * @property {number|null} [companyId] ListQcPlanItemsRequest companyId
                     * @property {palexy.sherlock.v1.IBoolValue|null} [isCustomItem] ListQcPlanItemsRequest isCustomItem
                     * @property {string|null} [fromDate] ListQcPlanItemsRequest fromDate
                     * @property {string|null} [toDate] ListQcPlanItemsRequest toDate
                     * @property {palexy.sherlock.v1.IBoolValue|null} [isAppliedToVideoTable] ListQcPlanItemsRequest isAppliedToVideoTable
                     * @property {number|null} [storeId] ListQcPlanItemsRequest storeId
                     */
    
                    /**
                     * Constructs a new ListQcPlanItemsRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListQcPlanItemsRequest.
                     * @implements IListQcPlanItemsRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IListQcPlanItemsRequest=} [properties] Properties to set
                     */
                    function ListQcPlanItemsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListQcPlanItemsRequest periodConfigId.
                     * @member {number} periodConfigId
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @instance
                     */
                    ListQcPlanItemsRequest.prototype.periodConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListQcPlanItemsRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @instance
                     */
                    ListQcPlanItemsRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListQcPlanItemsRequest isCustomItem.
                     * @member {palexy.sherlock.v1.IBoolValue|null|undefined} isCustomItem
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @instance
                     */
                    ListQcPlanItemsRequest.prototype.isCustomItem = null;
    
                    /**
                     * ListQcPlanItemsRequest fromDate.
                     * @member {string} fromDate
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @instance
                     */
                    ListQcPlanItemsRequest.prototype.fromDate = "";
    
                    /**
                     * ListQcPlanItemsRequest toDate.
                     * @member {string} toDate
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @instance
                     */
                    ListQcPlanItemsRequest.prototype.toDate = "";
    
                    /**
                     * ListQcPlanItemsRequest isAppliedToVideoTable.
                     * @member {palexy.sherlock.v1.IBoolValue|null|undefined} isAppliedToVideoTable
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @instance
                     */
                    ListQcPlanItemsRequest.prototype.isAppliedToVideoTable = null;
    
                    /**
                     * ListQcPlanItemsRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @instance
                     */
                    ListQcPlanItemsRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListQcPlanItemsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPlanItemsRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListQcPlanItemsRequest} ListQcPlanItemsRequest instance
                     */
                    ListQcPlanItemsRequest.create = function create(properties) {
                        return new ListQcPlanItemsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListQcPlanItemsRequest message. Does not implicitly {@link palexy.sherlock.v1.ListQcPlanItemsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPlanItemsRequest} message ListQcPlanItemsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcPlanItemsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.periodConfigId != null && Object.hasOwnProperty.call(message, "periodConfigId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.periodConfigId);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.isCustomItem != null && Object.hasOwnProperty.call(message, "isCustomItem"))
                            $root.palexy.sherlock.v1.BoolValue.encode(message.isCustomItem, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.fromDate != null && Object.hasOwnProperty.call(message, "fromDate"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.fromDate);
                        if (message.toDate != null && Object.hasOwnProperty.call(message, "toDate"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.toDate);
                        if (message.isAppliedToVideoTable != null && Object.hasOwnProperty.call(message, "isAppliedToVideoTable"))
                            $root.palexy.sherlock.v1.BoolValue.encode(message.isAppliedToVideoTable, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.storeId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListQcPlanItemsRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListQcPlanItemsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPlanItemsRequest} message ListQcPlanItemsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcPlanItemsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListQcPlanItemsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListQcPlanItemsRequest} ListQcPlanItemsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcPlanItemsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListQcPlanItemsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.periodConfigId = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.isCustomItem = $root.palexy.sherlock.v1.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.fromDate = reader.string();
                                break;
                            case 5:
                                message.toDate = reader.string();
                                break;
                            case 6:
                                message.isAppliedToVideoTable = $root.palexy.sherlock.v1.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.storeId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListQcPlanItemsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListQcPlanItemsRequest} ListQcPlanItemsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcPlanItemsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListQcPlanItemsRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListQcPlanItemsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.periodConfigId != null && message.hasOwnProperty("periodConfigId"))
                            if (!$util.isInteger(message.periodConfigId) && !(message.periodConfigId && $util.isInteger(message.periodConfigId.low) && $util.isInteger(message.periodConfigId.high)))
                                return "periodConfigId: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.isCustomItem != null && message.hasOwnProperty("isCustomItem")) {
                            var error = $root.palexy.sherlock.v1.BoolValue.verify(message.isCustomItem);
                            if (error)
                                return "isCustomItem." + error;
                        }
                        if (message.fromDate != null && message.hasOwnProperty("fromDate"))
                            if (!$util.isString(message.fromDate))
                                return "fromDate: string expected";
                        if (message.toDate != null && message.hasOwnProperty("toDate"))
                            if (!$util.isString(message.toDate))
                                return "toDate: string expected";
                        if (message.isAppliedToVideoTable != null && message.hasOwnProperty("isAppliedToVideoTable")) {
                            var error = $root.palexy.sherlock.v1.BoolValue.verify(message.isAppliedToVideoTable);
                            if (error)
                                return "isAppliedToVideoTable." + error;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListQcPlanItemsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListQcPlanItemsRequest} ListQcPlanItemsRequest
                     */
                    ListQcPlanItemsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListQcPlanItemsRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListQcPlanItemsRequest();
                        if (object.periodConfigId != null)
                            if ($util.Long)
                                (message.periodConfigId = $util.Long.fromValue(object.periodConfigId)).unsigned = false;
                            else if (typeof object.periodConfigId === "string")
                                message.periodConfigId = parseInt(object.periodConfigId, 10);
                            else if (typeof object.periodConfigId === "number")
                                message.periodConfigId = object.periodConfigId;
                            else if (typeof object.periodConfigId === "object")
                                message.periodConfigId = new $util.LongBits(object.periodConfigId.low >>> 0, object.periodConfigId.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.isCustomItem != null) {
                            if (typeof object.isCustomItem !== "object")
                                throw TypeError(".palexy.sherlock.v1.ListQcPlanItemsRequest.isCustomItem: object expected");
                            message.isCustomItem = $root.palexy.sherlock.v1.BoolValue.fromObject(object.isCustomItem);
                        }
                        if (object.fromDate != null)
                            message.fromDate = String(object.fromDate);
                        if (object.toDate != null)
                            message.toDate = String(object.toDate);
                        if (object.isAppliedToVideoTable != null) {
                            if (typeof object.isAppliedToVideoTable !== "object")
                                throw TypeError(".palexy.sherlock.v1.ListQcPlanItemsRequest.isAppliedToVideoTable: object expected");
                            message.isAppliedToVideoTable = $root.palexy.sherlock.v1.BoolValue.fromObject(object.isAppliedToVideoTable);
                        }
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListQcPlanItemsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.ListQcPlanItemsRequest} message ListQcPlanItemsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListQcPlanItemsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.periodConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.periodConfigId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.isCustomItem = null;
                            object.fromDate = "";
                            object.toDate = "";
                            object.isAppliedToVideoTable = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                        }
                        if (message.periodConfigId != null && message.hasOwnProperty("periodConfigId"))
                            if (typeof message.periodConfigId === "number")
                                object.periodConfigId = options.longs === String ? String(message.periodConfigId) : message.periodConfigId;
                            else
                                object.periodConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.periodConfigId) : options.longs === Number ? new $util.LongBits(message.periodConfigId.low >>> 0, message.periodConfigId.high >>> 0).toNumber() : message.periodConfigId;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.isCustomItem != null && message.hasOwnProperty("isCustomItem"))
                            object.isCustomItem = $root.palexy.sherlock.v1.BoolValue.toObject(message.isCustomItem, options);
                        if (message.fromDate != null && message.hasOwnProperty("fromDate"))
                            object.fromDate = message.fromDate;
                        if (message.toDate != null && message.hasOwnProperty("toDate"))
                            object.toDate = message.toDate;
                        if (message.isAppliedToVideoTable != null && message.hasOwnProperty("isAppliedToVideoTable"))
                            object.isAppliedToVideoTable = $root.palexy.sherlock.v1.BoolValue.toObject(message.isAppliedToVideoTable, options);
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        return object;
                    };
    
                    /**
                     * Converts this ListQcPlanItemsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListQcPlanItemsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListQcPlanItemsRequest;
                })();
    
                v1.BoolValue = (function() {
    
                    /**
                     * Properties of a BoolValue.
                     * @memberof palexy.sherlock.v1
                     * @interface IBoolValue
                     * @property {boolean|null} [value] BoolValue value
                     */
    
                    /**
                     * Constructs a new BoolValue.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BoolValue.
                     * @implements IBoolValue
                     * @constructor
                     * @param {palexy.sherlock.v1.IBoolValue=} [properties] Properties to set
                     */
                    function BoolValue(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BoolValue value.
                     * @member {boolean} value
                     * @memberof palexy.sherlock.v1.BoolValue
                     * @instance
                     */
                    BoolValue.prototype.value = false;
    
                    /**
                     * Creates a new BoolValue instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BoolValue
                     * @static
                     * @param {palexy.sherlock.v1.IBoolValue=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BoolValue} BoolValue instance
                     */
                    BoolValue.create = function create(properties) {
                        return new BoolValue(properties);
                    };
    
                    /**
                     * Encodes the specified BoolValue message. Does not implicitly {@link palexy.sherlock.v1.BoolValue.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BoolValue
                     * @static
                     * @param {palexy.sherlock.v1.IBoolValue} message BoolValue message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BoolValue.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.value);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BoolValue message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BoolValue.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BoolValue
                     * @static
                     * @param {palexy.sherlock.v1.IBoolValue} message BoolValue message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BoolValue.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BoolValue message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BoolValue
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BoolValue} BoolValue
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BoolValue.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BoolValue();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.value = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BoolValue message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BoolValue
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BoolValue} BoolValue
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BoolValue.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BoolValue message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BoolValue
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BoolValue.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.value != null && message.hasOwnProperty("value"))
                            if (typeof message.value !== "boolean")
                                return "value: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a BoolValue message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BoolValue
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BoolValue} BoolValue
                     */
                    BoolValue.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BoolValue)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BoolValue();
                        if (object.value != null)
                            message.value = Boolean(object.value);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BoolValue message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BoolValue
                     * @static
                     * @param {palexy.sherlock.v1.BoolValue} message BoolValue
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BoolValue.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.value = false;
                        if (message.value != null && message.hasOwnProperty("value"))
                            object.value = message.value;
                        return object;
                    };
    
                    /**
                     * Converts this BoolValue to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BoolValue
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BoolValue.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BoolValue;
                })();
    
                v1.ListQcPlanItemsResponse = (function() {
    
                    /**
                     * Properties of a ListQcPlanItemsResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IListQcPlanItemsResponse
                     * @property {Array.<palexy.sherlock.v1.IQcPlanItem>|null} [items] ListQcPlanItemsResponse items
                     */
    
                    /**
                     * Constructs a new ListQcPlanItemsResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a ListQcPlanItemsResponse.
                     * @implements IListQcPlanItemsResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IListQcPlanItemsResponse=} [properties] Properties to set
                     */
                    function ListQcPlanItemsResponse(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListQcPlanItemsResponse items.
                     * @member {Array.<palexy.sherlock.v1.IQcPlanItem>} items
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsResponse
                     * @instance
                     */
                    ListQcPlanItemsResponse.prototype.items = $util.emptyArray;
    
                    /**
                     * Creates a new ListQcPlanItemsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPlanItemsResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.ListQcPlanItemsResponse} ListQcPlanItemsResponse instance
                     */
                    ListQcPlanItemsResponse.create = function create(properties) {
                        return new ListQcPlanItemsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListQcPlanItemsResponse message. Does not implicitly {@link palexy.sherlock.v1.ListQcPlanItemsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPlanItemsResponse} message ListQcPlanItemsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcPlanItemsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.palexy.sherlock.v1.QcPlanItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListQcPlanItemsResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.ListQcPlanItemsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IListQcPlanItemsResponse} message ListQcPlanItemsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListQcPlanItemsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListQcPlanItemsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.ListQcPlanItemsResponse} ListQcPlanItemsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcPlanItemsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.ListQcPlanItemsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.palexy.sherlock.v1.QcPlanItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListQcPlanItemsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.ListQcPlanItemsResponse} ListQcPlanItemsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListQcPlanItemsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListQcPlanItemsResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListQcPlanItemsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.palexy.sherlock.v1.QcPlanItem.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListQcPlanItemsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.ListQcPlanItemsResponse} ListQcPlanItemsResponse
                     */
                    ListQcPlanItemsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.ListQcPlanItemsResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.ListQcPlanItemsResponse();
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".palexy.sherlock.v1.ListQcPlanItemsResponse.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.ListQcPlanItemsResponse.items: object expected");
                                message.items[i] = $root.palexy.sherlock.v1.QcPlanItem.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListQcPlanItemsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.ListQcPlanItemsResponse} message ListQcPlanItemsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListQcPlanItemsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.palexy.sherlock.v1.QcPlanItem.toObject(message.items[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListQcPlanItemsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.ListQcPlanItemsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListQcPlanItemsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListQcPlanItemsResponse;
                })();
    
                v1.CreateQcPlanItemRequest = (function() {
    
                    /**
                     * Properties of a CreateQcPlanItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateQcPlanItemRequest
                     * @property {palexy.sherlock.v1.IQcPlanItem|null} [item] CreateQcPlanItemRequest item
                     */
    
                    /**
                     * Constructs a new CreateQcPlanItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateQcPlanItemRequest.
                     * @implements ICreateQcPlanItemRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateQcPlanItemRequest=} [properties] Properties to set
                     */
                    function CreateQcPlanItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateQcPlanItemRequest item.
                     * @member {palexy.sherlock.v1.IQcPlanItem|null|undefined} item
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemRequest
                     * @instance
                     */
                    CreateQcPlanItemRequest.prototype.item = null;
    
                    /**
                     * Creates a new CreateQcPlanItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPlanItemRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateQcPlanItemRequest} CreateQcPlanItemRequest instance
                     */
                    CreateQcPlanItemRequest.create = function create(properties) {
                        return new CreateQcPlanItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateQcPlanItemRequest message. Does not implicitly {@link palexy.sherlock.v1.CreateQcPlanItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPlanItemRequest} message CreateQcPlanItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateQcPlanItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                            $root.palexy.sherlock.v1.QcPlanItem.encode(message.item, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateQcPlanItemRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateQcPlanItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPlanItemRequest} message CreateQcPlanItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateQcPlanItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateQcPlanItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateQcPlanItemRequest} CreateQcPlanItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateQcPlanItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateQcPlanItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.item = $root.palexy.sherlock.v1.QcPlanItem.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateQcPlanItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateQcPlanItemRequest} CreateQcPlanItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateQcPlanItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateQcPlanItemRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateQcPlanItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.item != null && message.hasOwnProperty("item")) {
                            var error = $root.palexy.sherlock.v1.QcPlanItem.verify(message.item);
                            if (error)
                                return "item." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateQcPlanItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateQcPlanItemRequest} CreateQcPlanItemRequest
                     */
                    CreateQcPlanItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateQcPlanItemRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateQcPlanItemRequest();
                        if (object.item != null) {
                            if (typeof object.item !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateQcPlanItemRequest.item: object expected");
                            message.item = $root.palexy.sherlock.v1.QcPlanItem.fromObject(object.item);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateQcPlanItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.CreateQcPlanItemRequest} message CreateQcPlanItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateQcPlanItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.item = null;
                        if (message.item != null && message.hasOwnProperty("item"))
                            object.item = $root.palexy.sherlock.v1.QcPlanItem.toObject(message.item, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateQcPlanItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateQcPlanItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateQcPlanItemRequest;
                })();
    
                v1.CreateQcPlanItemResponse = (function() {
    
                    /**
                     * Properties of a CreateQcPlanItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface ICreateQcPlanItemResponse
                     * @property {palexy.sherlock.v1.IQcPlanItem|null} [item] CreateQcPlanItemResponse item
                     */
    
                    /**
                     * Constructs a new CreateQcPlanItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a CreateQcPlanItemResponse.
                     * @implements ICreateQcPlanItemResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.ICreateQcPlanItemResponse=} [properties] Properties to set
                     */
                    function CreateQcPlanItemResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateQcPlanItemResponse item.
                     * @member {palexy.sherlock.v1.IQcPlanItem|null|undefined} item
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemResponse
                     * @instance
                     */
                    CreateQcPlanItemResponse.prototype.item = null;
    
                    /**
                     * Creates a new CreateQcPlanItemResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPlanItemResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.CreateQcPlanItemResponse} CreateQcPlanItemResponse instance
                     */
                    CreateQcPlanItemResponse.create = function create(properties) {
                        return new CreateQcPlanItemResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateQcPlanItemResponse message. Does not implicitly {@link palexy.sherlock.v1.CreateQcPlanItemResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPlanItemResponse} message CreateQcPlanItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateQcPlanItemResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                            $root.palexy.sherlock.v1.QcPlanItem.encode(message.item, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateQcPlanItemResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.CreateQcPlanItemResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.ICreateQcPlanItemResponse} message CreateQcPlanItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateQcPlanItemResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateQcPlanItemResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.CreateQcPlanItemResponse} CreateQcPlanItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateQcPlanItemResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.CreateQcPlanItemResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.item = $root.palexy.sherlock.v1.QcPlanItem.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateQcPlanItemResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.CreateQcPlanItemResponse} CreateQcPlanItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateQcPlanItemResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateQcPlanItemResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateQcPlanItemResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.item != null && message.hasOwnProperty("item")) {
                            var error = $root.palexy.sherlock.v1.QcPlanItem.verify(message.item);
                            if (error)
                                return "item." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateQcPlanItemResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.CreateQcPlanItemResponse} CreateQcPlanItemResponse
                     */
                    CreateQcPlanItemResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.CreateQcPlanItemResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.CreateQcPlanItemResponse();
                        if (object.item != null) {
                            if (typeof object.item !== "object")
                                throw TypeError(".palexy.sherlock.v1.CreateQcPlanItemResponse.item: object expected");
                            message.item = $root.palexy.sherlock.v1.QcPlanItem.fromObject(object.item);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateQcPlanItemResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.CreateQcPlanItemResponse} message CreateQcPlanItemResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateQcPlanItemResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.item = null;
                        if (message.item != null && message.hasOwnProperty("item"))
                            object.item = $root.palexy.sherlock.v1.QcPlanItem.toObject(message.item, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateQcPlanItemResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.CreateQcPlanItemResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateQcPlanItemResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateQcPlanItemResponse;
                })();
    
                v1.BatchCreateQcPlanItemsRequest = (function() {
    
                    /**
                     * Properties of a BatchCreateQcPlanItemsRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IBatchCreateQcPlanItemsRequest
                     * @property {Array.<palexy.sherlock.v1.IQcPlanItem>|null} [items] BatchCreateQcPlanItemsRequest items
                     */
    
                    /**
                     * Constructs a new BatchCreateQcPlanItemsRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BatchCreateQcPlanItemsRequest.
                     * @implements IBatchCreateQcPlanItemsRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IBatchCreateQcPlanItemsRequest=} [properties] Properties to set
                     */
                    function BatchCreateQcPlanItemsRequest(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateQcPlanItemsRequest items.
                     * @member {Array.<palexy.sherlock.v1.IQcPlanItem>} items
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsRequest
                     * @instance
                     */
                    BatchCreateQcPlanItemsRequest.prototype.items = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateQcPlanItemsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateQcPlanItemsRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BatchCreateQcPlanItemsRequest} BatchCreateQcPlanItemsRequest instance
                     */
                    BatchCreateQcPlanItemsRequest.create = function create(properties) {
                        return new BatchCreateQcPlanItemsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateQcPlanItemsRequest message. Does not implicitly {@link palexy.sherlock.v1.BatchCreateQcPlanItemsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateQcPlanItemsRequest} message BatchCreateQcPlanItemsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateQcPlanItemsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.palexy.sherlock.v1.QcPlanItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateQcPlanItemsRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BatchCreateQcPlanItemsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateQcPlanItemsRequest} message BatchCreateQcPlanItemsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateQcPlanItemsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateQcPlanItemsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BatchCreateQcPlanItemsRequest} BatchCreateQcPlanItemsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateQcPlanItemsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BatchCreateQcPlanItemsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.palexy.sherlock.v1.QcPlanItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateQcPlanItemsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BatchCreateQcPlanItemsRequest} BatchCreateQcPlanItemsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateQcPlanItemsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateQcPlanItemsRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateQcPlanItemsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.palexy.sherlock.v1.QcPlanItem.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateQcPlanItemsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BatchCreateQcPlanItemsRequest} BatchCreateQcPlanItemsRequest
                     */
                    BatchCreateQcPlanItemsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BatchCreateQcPlanItemsRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BatchCreateQcPlanItemsRequest();
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".palexy.sherlock.v1.BatchCreateQcPlanItemsRequest.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.BatchCreateQcPlanItemsRequest.items: object expected");
                                message.items[i] = $root.palexy.sherlock.v1.QcPlanItem.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateQcPlanItemsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.BatchCreateQcPlanItemsRequest} message BatchCreateQcPlanItemsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateQcPlanItemsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.palexy.sherlock.v1.QcPlanItem.toObject(message.items[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateQcPlanItemsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateQcPlanItemsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateQcPlanItemsRequest;
                })();
    
                v1.BatchCreateQcPlanItemsResponse = (function() {
    
                    /**
                     * Properties of a BatchCreateQcPlanItemsResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IBatchCreateQcPlanItemsResponse
                     * @property {Array.<palexy.sherlock.v1.IQcPlanItem>|null} [items] BatchCreateQcPlanItemsResponse items
                     */
    
                    /**
                     * Constructs a new BatchCreateQcPlanItemsResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BatchCreateQcPlanItemsResponse.
                     * @implements IBatchCreateQcPlanItemsResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IBatchCreateQcPlanItemsResponse=} [properties] Properties to set
                     */
                    function BatchCreateQcPlanItemsResponse(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateQcPlanItemsResponse items.
                     * @member {Array.<palexy.sherlock.v1.IQcPlanItem>} items
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsResponse
                     * @instance
                     */
                    BatchCreateQcPlanItemsResponse.prototype.items = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateQcPlanItemsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateQcPlanItemsResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BatchCreateQcPlanItemsResponse} BatchCreateQcPlanItemsResponse instance
                     */
                    BatchCreateQcPlanItemsResponse.create = function create(properties) {
                        return new BatchCreateQcPlanItemsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateQcPlanItemsResponse message. Does not implicitly {@link palexy.sherlock.v1.BatchCreateQcPlanItemsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateQcPlanItemsResponse} message BatchCreateQcPlanItemsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateQcPlanItemsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.palexy.sherlock.v1.QcPlanItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateQcPlanItemsResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BatchCreateQcPlanItemsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchCreateQcPlanItemsResponse} message BatchCreateQcPlanItemsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateQcPlanItemsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateQcPlanItemsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BatchCreateQcPlanItemsResponse} BatchCreateQcPlanItemsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateQcPlanItemsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BatchCreateQcPlanItemsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.palexy.sherlock.v1.QcPlanItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateQcPlanItemsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BatchCreateQcPlanItemsResponse} BatchCreateQcPlanItemsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateQcPlanItemsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateQcPlanItemsResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateQcPlanItemsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.palexy.sherlock.v1.QcPlanItem.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateQcPlanItemsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BatchCreateQcPlanItemsResponse} BatchCreateQcPlanItemsResponse
                     */
                    BatchCreateQcPlanItemsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BatchCreateQcPlanItemsResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BatchCreateQcPlanItemsResponse();
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".palexy.sherlock.v1.BatchCreateQcPlanItemsResponse.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.BatchCreateQcPlanItemsResponse.items: object expected");
                                message.items[i] = $root.palexy.sherlock.v1.QcPlanItem.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateQcPlanItemsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.BatchCreateQcPlanItemsResponse} message BatchCreateQcPlanItemsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateQcPlanItemsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.palexy.sherlock.v1.QcPlanItem.toObject(message.items[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateQcPlanItemsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BatchCreateQcPlanItemsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateQcPlanItemsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateQcPlanItemsResponse;
                })();
    
                v1.BatchDeleteQcPlanItemsRequest = (function() {
    
                    /**
                     * Properties of a BatchDeleteQcPlanItemsRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IBatchDeleteQcPlanItemsRequest
                     * @property {string|null} [fromDateId] BatchDeleteQcPlanItemsRequest fromDateId
                     * @property {number|null} [companyId] BatchDeleteQcPlanItemsRequest companyId
                     * @property {number|null} [periodConfigId] BatchDeleteQcPlanItemsRequest periodConfigId
                     */
    
                    /**
                     * Constructs a new BatchDeleteQcPlanItemsRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BatchDeleteQcPlanItemsRequest.
                     * @implements IBatchDeleteQcPlanItemsRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IBatchDeleteQcPlanItemsRequest=} [properties] Properties to set
                     */
                    function BatchDeleteQcPlanItemsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchDeleteQcPlanItemsRequest fromDateId.
                     * @member {string} fromDateId
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @instance
                     */
                    BatchDeleteQcPlanItemsRequest.prototype.fromDateId = "";
    
                    /**
                     * BatchDeleteQcPlanItemsRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @instance
                     */
                    BatchDeleteQcPlanItemsRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * BatchDeleteQcPlanItemsRequest periodConfigId.
                     * @member {number} periodConfigId
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @instance
                     */
                    BatchDeleteQcPlanItemsRequest.prototype.periodConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new BatchDeleteQcPlanItemsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchDeleteQcPlanItemsRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest} BatchDeleteQcPlanItemsRequest instance
                     */
                    BatchDeleteQcPlanItemsRequest.create = function create(properties) {
                        return new BatchDeleteQcPlanItemsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchDeleteQcPlanItemsRequest message. Does not implicitly {@link palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchDeleteQcPlanItemsRequest} message BatchDeleteQcPlanItemsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteQcPlanItemsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.fromDateId != null && Object.hasOwnProperty.call(message, "fromDateId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.fromDateId);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.periodConfigId != null && Object.hasOwnProperty.call(message, "periodConfigId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.periodConfigId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchDeleteQcPlanItemsRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.IBatchDeleteQcPlanItemsRequest} message BatchDeleteQcPlanItemsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteQcPlanItemsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchDeleteQcPlanItemsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest} BatchDeleteQcPlanItemsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteQcPlanItemsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.fromDateId = reader.string();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.periodConfigId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchDeleteQcPlanItemsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest} BatchDeleteQcPlanItemsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteQcPlanItemsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchDeleteQcPlanItemsRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchDeleteQcPlanItemsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.fromDateId != null && message.hasOwnProperty("fromDateId"))
                            if (!$util.isString(message.fromDateId))
                                return "fromDateId: string expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.periodConfigId != null && message.hasOwnProperty("periodConfigId"))
                            if (!$util.isInteger(message.periodConfigId) && !(message.periodConfigId && $util.isInteger(message.periodConfigId.low) && $util.isInteger(message.periodConfigId.high)))
                                return "periodConfigId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchDeleteQcPlanItemsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest} BatchDeleteQcPlanItemsRequest
                     */
                    BatchDeleteQcPlanItemsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest();
                        if (object.fromDateId != null)
                            message.fromDateId = String(object.fromDateId);
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.periodConfigId != null)
                            if ($util.Long)
                                (message.periodConfigId = $util.Long.fromValue(object.periodConfigId)).unsigned = false;
                            else if (typeof object.periodConfigId === "string")
                                message.periodConfigId = parseInt(object.periodConfigId, 10);
                            else if (typeof object.periodConfigId === "number")
                                message.periodConfigId = object.periodConfigId;
                            else if (typeof object.periodConfigId === "object")
                                message.periodConfigId = new $util.LongBits(object.periodConfigId.low >>> 0, object.periodConfigId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchDeleteQcPlanItemsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @static
                     * @param {palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest} message BatchDeleteQcPlanItemsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchDeleteQcPlanItemsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.fromDateId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.periodConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.periodConfigId = options.longs === String ? "0" : 0;
                        }
                        if (message.fromDateId != null && message.hasOwnProperty("fromDateId"))
                            object.fromDateId = message.fromDateId;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.periodConfigId != null && message.hasOwnProperty("periodConfigId"))
                            if (typeof message.periodConfigId === "number")
                                object.periodConfigId = options.longs === String ? String(message.periodConfigId) : message.periodConfigId;
                            else
                                object.periodConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.periodConfigId) : options.longs === Number ? new $util.LongBits(message.periodConfigId.low >>> 0, message.periodConfigId.high >>> 0).toNumber() : message.periodConfigId;
                        return object;
                    };
    
                    /**
                     * Converts this BatchDeleteQcPlanItemsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchDeleteQcPlanItemsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchDeleteQcPlanItemsRequest;
                })();
    
                v1.BatchDeleteQcPlanItemsResponse = (function() {
    
                    /**
                     * Properties of a BatchDeleteQcPlanItemsResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IBatchDeleteQcPlanItemsResponse
                     */
    
                    /**
                     * Constructs a new BatchDeleteQcPlanItemsResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a BatchDeleteQcPlanItemsResponse.
                     * @implements IBatchDeleteQcPlanItemsResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IBatchDeleteQcPlanItemsResponse=} [properties] Properties to set
                     */
                    function BatchDeleteQcPlanItemsResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new BatchDeleteQcPlanItemsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchDeleteQcPlanItemsResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse} BatchDeleteQcPlanItemsResponse instance
                     */
                    BatchDeleteQcPlanItemsResponse.create = function create(properties) {
                        return new BatchDeleteQcPlanItemsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchDeleteQcPlanItemsResponse message. Does not implicitly {@link palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchDeleteQcPlanItemsResponse} message BatchDeleteQcPlanItemsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteQcPlanItemsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchDeleteQcPlanItemsResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.IBatchDeleteQcPlanItemsResponse} message BatchDeleteQcPlanItemsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteQcPlanItemsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchDeleteQcPlanItemsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse} BatchDeleteQcPlanItemsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteQcPlanItemsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchDeleteQcPlanItemsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse} BatchDeleteQcPlanItemsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteQcPlanItemsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchDeleteQcPlanItemsResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchDeleteQcPlanItemsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchDeleteQcPlanItemsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse} BatchDeleteQcPlanItemsResponse
                     */
                    BatchDeleteQcPlanItemsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse)
                            return object;
                        return new $root.palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse();
                    };
    
                    /**
                     * Creates a plain object from a BatchDeleteQcPlanItemsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse
                     * @static
                     * @param {palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse} message BatchDeleteQcPlanItemsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchDeleteQcPlanItemsResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this BatchDeleteQcPlanItemsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.BatchDeleteQcPlanItemsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchDeleteQcPlanItemsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchDeleteQcPlanItemsResponse;
                })();
    
                v1.UpdateVideoTableForQcPlanItemRequest = (function() {
    
                    /**
                     * Properties of an UpdateVideoTableForQcPlanItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IUpdateVideoTableForQcPlanItemRequest
                     * @property {string|null} [name] UpdateVideoTableForQcPlanItemRequest name
                     */
    
                    /**
                     * Constructs a new UpdateVideoTableForQcPlanItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UpdateVideoTableForQcPlanItemRequest.
                     * @implements IUpdateVideoTableForQcPlanItemRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IUpdateVideoTableForQcPlanItemRequest=} [properties] Properties to set
                     */
                    function UpdateVideoTableForQcPlanItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateVideoTableForQcPlanItemRequest name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest
                     * @instance
                     */
                    UpdateVideoTableForQcPlanItemRequest.prototype.name = "";
    
                    /**
                     * Creates a new UpdateVideoTableForQcPlanItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateVideoTableForQcPlanItemRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest} UpdateVideoTableForQcPlanItemRequest instance
                     */
                    UpdateVideoTableForQcPlanItemRequest.create = function create(properties) {
                        return new UpdateVideoTableForQcPlanItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateVideoTableForQcPlanItemRequest message. Does not implicitly {@link palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateVideoTableForQcPlanItemRequest} message UpdateVideoTableForQcPlanItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateVideoTableForQcPlanItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateVideoTableForQcPlanItemRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateVideoTableForQcPlanItemRequest} message UpdateVideoTableForQcPlanItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateVideoTableForQcPlanItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateVideoTableForQcPlanItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest} UpdateVideoTableForQcPlanItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateVideoTableForQcPlanItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateVideoTableForQcPlanItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest} UpdateVideoTableForQcPlanItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateVideoTableForQcPlanItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateVideoTableForQcPlanItemRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateVideoTableForQcPlanItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpdateVideoTableForQcPlanItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest} UpdateVideoTableForQcPlanItemRequest
                     */
                    UpdateVideoTableForQcPlanItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateVideoTableForQcPlanItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest} message UpdateVideoTableForQcPlanItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateVideoTableForQcPlanItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this UpdateVideoTableForQcPlanItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateVideoTableForQcPlanItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateVideoTableForQcPlanItemRequest;
                })();
    
                v1.UpdateVideoTableForQcPlanItemResponse = (function() {
    
                    /**
                     * Properties of an UpdateVideoTableForQcPlanItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IUpdateVideoTableForQcPlanItemResponse
                     * @property {number|null} [numUpdateVideos] UpdateVideoTableForQcPlanItemResponse numUpdateVideos
                     */
    
                    /**
                     * Constructs a new UpdateVideoTableForQcPlanItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents an UpdateVideoTableForQcPlanItemResponse.
                     * @implements IUpdateVideoTableForQcPlanItemResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IUpdateVideoTableForQcPlanItemResponse=} [properties] Properties to set
                     */
                    function UpdateVideoTableForQcPlanItemResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateVideoTableForQcPlanItemResponse numUpdateVideos.
                     * @member {number} numUpdateVideos
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse
                     * @instance
                     */
                    UpdateVideoTableForQcPlanItemResponse.prototype.numUpdateVideos = 0;
    
                    /**
                     * Creates a new UpdateVideoTableForQcPlanItemResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateVideoTableForQcPlanItemResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse} UpdateVideoTableForQcPlanItemResponse instance
                     */
                    UpdateVideoTableForQcPlanItemResponse.create = function create(properties) {
                        return new UpdateVideoTableForQcPlanItemResponse(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateVideoTableForQcPlanItemResponse message. Does not implicitly {@link palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateVideoTableForQcPlanItemResponse} message UpdateVideoTableForQcPlanItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateVideoTableForQcPlanItemResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.numUpdateVideos != null && Object.hasOwnProperty.call(message, "numUpdateVideos"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.numUpdateVideos);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateVideoTableForQcPlanItemResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.IUpdateVideoTableForQcPlanItemResponse} message UpdateVideoTableForQcPlanItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateVideoTableForQcPlanItemResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateVideoTableForQcPlanItemResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse} UpdateVideoTableForQcPlanItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateVideoTableForQcPlanItemResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.numUpdateVideos = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateVideoTableForQcPlanItemResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse} UpdateVideoTableForQcPlanItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateVideoTableForQcPlanItemResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateVideoTableForQcPlanItemResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateVideoTableForQcPlanItemResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.numUpdateVideos != null && message.hasOwnProperty("numUpdateVideos"))
                            if (!$util.isInteger(message.numUpdateVideos))
                                return "numUpdateVideos: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpdateVideoTableForQcPlanItemResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse} UpdateVideoTableForQcPlanItemResponse
                     */
                    UpdateVideoTableForQcPlanItemResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse();
                        if (object.numUpdateVideos != null)
                            message.numUpdateVideos = object.numUpdateVideos | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateVideoTableForQcPlanItemResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse} message UpdateVideoTableForQcPlanItemResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateVideoTableForQcPlanItemResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.numUpdateVideos = 0;
                        if (message.numUpdateVideos != null && message.hasOwnProperty("numUpdateVideos"))
                            object.numUpdateVideos = message.numUpdateVideos;
                        return object;
                    };
    
                    /**
                     * Converts this UpdateVideoTableForQcPlanItemResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.UpdateVideoTableForQcPlanItemResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateVideoTableForQcPlanItemResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateVideoTableForQcPlanItemResponse;
                })();
    
                v1.DeleteQcPlanItemRequest = (function() {
    
                    /**
                     * Properties of a DeleteQcPlanItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IDeleteQcPlanItemRequest
                     * @property {string|null} [name] DeleteQcPlanItemRequest name
                     */
    
                    /**
                     * Constructs a new DeleteQcPlanItemRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DeleteQcPlanItemRequest.
                     * @implements IDeleteQcPlanItemRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IDeleteQcPlanItemRequest=} [properties] Properties to set
                     */
                    function DeleteQcPlanItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteQcPlanItemRequest name.
                     * @member {string} name
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemRequest
                     * @instance
                     */
                    DeleteQcPlanItemRequest.prototype.name = "";
    
                    /**
                     * Creates a new DeleteQcPlanItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPlanItemRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DeleteQcPlanItemRequest} DeleteQcPlanItemRequest instance
                     */
                    DeleteQcPlanItemRequest.create = function create(properties) {
                        return new DeleteQcPlanItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteQcPlanItemRequest message. Does not implicitly {@link palexy.sherlock.v1.DeleteQcPlanItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPlanItemRequest} message DeleteQcPlanItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcPlanItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteQcPlanItemRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DeleteQcPlanItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPlanItemRequest} message DeleteQcPlanItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcPlanItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteQcPlanItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DeleteQcPlanItemRequest} DeleteQcPlanItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcPlanItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DeleteQcPlanItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteQcPlanItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DeleteQcPlanItemRequest} DeleteQcPlanItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcPlanItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteQcPlanItemRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteQcPlanItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteQcPlanItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DeleteQcPlanItemRequest} DeleteQcPlanItemRequest
                     */
                    DeleteQcPlanItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DeleteQcPlanItemRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.DeleteQcPlanItemRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteQcPlanItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemRequest
                     * @static
                     * @param {palexy.sherlock.v1.DeleteQcPlanItemRequest} message DeleteQcPlanItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteQcPlanItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteQcPlanItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteQcPlanItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteQcPlanItemRequest;
                })();
    
                v1.DeleteQcPlanItemResponse = (function() {
    
                    /**
                     * Properties of a DeleteQcPlanItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IDeleteQcPlanItemResponse
                     */
    
                    /**
                     * Constructs a new DeleteQcPlanItemResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a DeleteQcPlanItemResponse.
                     * @implements IDeleteQcPlanItemResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IDeleteQcPlanItemResponse=} [properties] Properties to set
                     */
                    function DeleteQcPlanItemResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new DeleteQcPlanItemResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPlanItemResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.DeleteQcPlanItemResponse} DeleteQcPlanItemResponse instance
                     */
                    DeleteQcPlanItemResponse.create = function create(properties) {
                        return new DeleteQcPlanItemResponse(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteQcPlanItemResponse message. Does not implicitly {@link palexy.sherlock.v1.DeleteQcPlanItemResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPlanItemResponse} message DeleteQcPlanItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcPlanItemResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteQcPlanItemResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.DeleteQcPlanItemResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.IDeleteQcPlanItemResponse} message DeleteQcPlanItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteQcPlanItemResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteQcPlanItemResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.DeleteQcPlanItemResponse} DeleteQcPlanItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcPlanItemResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.DeleteQcPlanItemResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteQcPlanItemResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.DeleteQcPlanItemResponse} DeleteQcPlanItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteQcPlanItemResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteQcPlanItemResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteQcPlanItemResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteQcPlanItemResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.DeleteQcPlanItemResponse} DeleteQcPlanItemResponse
                     */
                    DeleteQcPlanItemResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.DeleteQcPlanItemResponse)
                            return object;
                        return new $root.palexy.sherlock.v1.DeleteQcPlanItemResponse();
                    };
    
                    /**
                     * Creates a plain object from a DeleteQcPlanItemResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemResponse
                     * @static
                     * @param {palexy.sherlock.v1.DeleteQcPlanItemResponse} message DeleteQcPlanItemResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteQcPlanItemResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this DeleteQcPlanItemResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.DeleteQcPlanItemResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteQcPlanItemResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteQcPlanItemResponse;
                })();
    
                v1.GeneratePlanForConfigRequest = (function() {
    
                    /**
                     * Properties of a GeneratePlanForConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @interface IGeneratePlanForConfigRequest
                     * @property {number|null} [periodConfigId] GeneratePlanForConfigRequest periodConfigId
                     * @property {string|null} [periodStartingDateId] GeneratePlanForConfigRequest periodStartingDateId
                     */
    
                    /**
                     * Constructs a new GeneratePlanForConfigRequest.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GeneratePlanForConfigRequest.
                     * @implements IGeneratePlanForConfigRequest
                     * @constructor
                     * @param {palexy.sherlock.v1.IGeneratePlanForConfigRequest=} [properties] Properties to set
                     */
                    function GeneratePlanForConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GeneratePlanForConfigRequest periodConfigId.
                     * @member {number} periodConfigId
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigRequest
                     * @instance
                     */
                    GeneratePlanForConfigRequest.prototype.periodConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GeneratePlanForConfigRequest periodStartingDateId.
                     * @member {string} periodStartingDateId
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigRequest
                     * @instance
                     */
                    GeneratePlanForConfigRequest.prototype.periodStartingDateId = "";
    
                    /**
                     * Creates a new GeneratePlanForConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGeneratePlanForConfigRequest=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GeneratePlanForConfigRequest} GeneratePlanForConfigRequest instance
                     */
                    GeneratePlanForConfigRequest.create = function create(properties) {
                        return new GeneratePlanForConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GeneratePlanForConfigRequest message. Does not implicitly {@link palexy.sherlock.v1.GeneratePlanForConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGeneratePlanForConfigRequest} message GeneratePlanForConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GeneratePlanForConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.periodConfigId != null && Object.hasOwnProperty.call(message, "periodConfigId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.periodConfigId);
                        if (message.periodStartingDateId != null && Object.hasOwnProperty.call(message, "periodStartingDateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.periodStartingDateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GeneratePlanForConfigRequest message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GeneratePlanForConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.IGeneratePlanForConfigRequest} message GeneratePlanForConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GeneratePlanForConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GeneratePlanForConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GeneratePlanForConfigRequest} GeneratePlanForConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GeneratePlanForConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GeneratePlanForConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.periodConfigId = reader.int64();
                                break;
                            case 2:
                                message.periodStartingDateId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GeneratePlanForConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GeneratePlanForConfigRequest} GeneratePlanForConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GeneratePlanForConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GeneratePlanForConfigRequest message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GeneratePlanForConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.periodConfigId != null && message.hasOwnProperty("periodConfigId"))
                            if (!$util.isInteger(message.periodConfigId) && !(message.periodConfigId && $util.isInteger(message.periodConfigId.low) && $util.isInteger(message.periodConfigId.high)))
                                return "periodConfigId: integer|Long expected";
                        if (message.periodStartingDateId != null && message.hasOwnProperty("periodStartingDateId"))
                            if (!$util.isString(message.periodStartingDateId))
                                return "periodStartingDateId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GeneratePlanForConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GeneratePlanForConfigRequest} GeneratePlanForConfigRequest
                     */
                    GeneratePlanForConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GeneratePlanForConfigRequest)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GeneratePlanForConfigRequest();
                        if (object.periodConfigId != null)
                            if ($util.Long)
                                (message.periodConfigId = $util.Long.fromValue(object.periodConfigId)).unsigned = false;
                            else if (typeof object.periodConfigId === "string")
                                message.periodConfigId = parseInt(object.periodConfigId, 10);
                            else if (typeof object.periodConfigId === "number")
                                message.periodConfigId = object.periodConfigId;
                            else if (typeof object.periodConfigId === "object")
                                message.periodConfigId = new $util.LongBits(object.periodConfigId.low >>> 0, object.periodConfigId.high >>> 0).toNumber();
                        if (object.periodStartingDateId != null)
                            message.periodStartingDateId = String(object.periodStartingDateId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GeneratePlanForConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigRequest
                     * @static
                     * @param {palexy.sherlock.v1.GeneratePlanForConfigRequest} message GeneratePlanForConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GeneratePlanForConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.periodConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.periodConfigId = options.longs === String ? "0" : 0;
                            object.periodStartingDateId = "";
                        }
                        if (message.periodConfigId != null && message.hasOwnProperty("periodConfigId"))
                            if (typeof message.periodConfigId === "number")
                                object.periodConfigId = options.longs === String ? String(message.periodConfigId) : message.periodConfigId;
                            else
                                object.periodConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.periodConfigId) : options.longs === Number ? new $util.LongBits(message.periodConfigId.low >>> 0, message.periodConfigId.high >>> 0).toNumber() : message.periodConfigId;
                        if (message.periodStartingDateId != null && message.hasOwnProperty("periodStartingDateId"))
                            object.periodStartingDateId = message.periodStartingDateId;
                        return object;
                    };
    
                    /**
                     * Converts this GeneratePlanForConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GeneratePlanForConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GeneratePlanForConfigRequest;
                })();
    
                v1.GeneratePlanForConfigResponse = (function() {
    
                    /**
                     * Properties of a GeneratePlanForConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @interface IGeneratePlanForConfigResponse
                     * @property {Array.<palexy.sherlock.v1.IQcPlanItem>|null} [generatedItems] GeneratePlanForConfigResponse generatedItems
                     */
    
                    /**
                     * Constructs a new GeneratePlanForConfigResponse.
                     * @memberof palexy.sherlock.v1
                     * @classdesc Represents a GeneratePlanForConfigResponse.
                     * @implements IGeneratePlanForConfigResponse
                     * @constructor
                     * @param {palexy.sherlock.v1.IGeneratePlanForConfigResponse=} [properties] Properties to set
                     */
                    function GeneratePlanForConfigResponse(properties) {
                        this.generatedItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GeneratePlanForConfigResponse generatedItems.
                     * @member {Array.<palexy.sherlock.v1.IQcPlanItem>} generatedItems
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigResponse
                     * @instance
                     */
                    GeneratePlanForConfigResponse.prototype.generatedItems = $util.emptyArray;
    
                    /**
                     * Creates a new GeneratePlanForConfigResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGeneratePlanForConfigResponse=} [properties] Properties to set
                     * @returns {palexy.sherlock.v1.GeneratePlanForConfigResponse} GeneratePlanForConfigResponse instance
                     */
                    GeneratePlanForConfigResponse.create = function create(properties) {
                        return new GeneratePlanForConfigResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GeneratePlanForConfigResponse message. Does not implicitly {@link palexy.sherlock.v1.GeneratePlanForConfigResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGeneratePlanForConfigResponse} message GeneratePlanForConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GeneratePlanForConfigResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.generatedItems != null && message.generatedItems.length)
                            for (var i = 0; i < message.generatedItems.length; ++i)
                                $root.palexy.sherlock.v1.QcPlanItem.encode(message.generatedItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GeneratePlanForConfigResponse message, length delimited. Does not implicitly {@link palexy.sherlock.v1.GeneratePlanForConfigResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.IGeneratePlanForConfigResponse} message GeneratePlanForConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GeneratePlanForConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GeneratePlanForConfigResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.sherlock.v1.GeneratePlanForConfigResponse} GeneratePlanForConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GeneratePlanForConfigResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.sherlock.v1.GeneratePlanForConfigResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.generatedItems && message.generatedItems.length))
                                    message.generatedItems = [];
                                message.generatedItems.push($root.palexy.sherlock.v1.QcPlanItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GeneratePlanForConfigResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.sherlock.v1.GeneratePlanForConfigResponse} GeneratePlanForConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GeneratePlanForConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GeneratePlanForConfigResponse message.
                     * @function verify
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GeneratePlanForConfigResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.generatedItems != null && message.hasOwnProperty("generatedItems")) {
                            if (!Array.isArray(message.generatedItems))
                                return "generatedItems: array expected";
                            for (var i = 0; i < message.generatedItems.length; ++i) {
                                var error = $root.palexy.sherlock.v1.QcPlanItem.verify(message.generatedItems[i]);
                                if (error)
                                    return "generatedItems." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GeneratePlanForConfigResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.sherlock.v1.GeneratePlanForConfigResponse} GeneratePlanForConfigResponse
                     */
                    GeneratePlanForConfigResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.sherlock.v1.GeneratePlanForConfigResponse)
                            return object;
                        var message = new $root.palexy.sherlock.v1.GeneratePlanForConfigResponse();
                        if (object.generatedItems) {
                            if (!Array.isArray(object.generatedItems))
                                throw TypeError(".palexy.sherlock.v1.GeneratePlanForConfigResponse.generatedItems: array expected");
                            message.generatedItems = [];
                            for (var i = 0; i < object.generatedItems.length; ++i) {
                                if (typeof object.generatedItems[i] !== "object")
                                    throw TypeError(".palexy.sherlock.v1.GeneratePlanForConfigResponse.generatedItems: object expected");
                                message.generatedItems[i] = $root.palexy.sherlock.v1.QcPlanItem.fromObject(object.generatedItems[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GeneratePlanForConfigResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigResponse
                     * @static
                     * @param {palexy.sherlock.v1.GeneratePlanForConfigResponse} message GeneratePlanForConfigResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GeneratePlanForConfigResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.generatedItems = [];
                        if (message.generatedItems && message.generatedItems.length) {
                            object.generatedItems = [];
                            for (var j = 0; j < message.generatedItems.length; ++j)
                                object.generatedItems[j] = $root.palexy.sherlock.v1.QcPlanItem.toObject(message.generatedItems[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GeneratePlanForConfigResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.sherlock.v1.GeneratePlanForConfigResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GeneratePlanForConfigResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GeneratePlanForConfigResponse;
                })();
    
                return v1;
            })();
    
            return sherlock;
        })();
    
        palexy.streaming = (function() {
    
            /**
             * Namespace streaming.
             * @memberof palexy
             * @namespace
             */
            var streaming = {};
    
            streaming.v1 = (function() {
    
                /**
                 * Namespace v1.
                 * @memberof palexy.streaming
                 * @namespace
                 */
                var v1 = {};
    
                v1.WorkerService = (function() {
    
                    /**
                     * Constructs a new WorkerService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a WorkerService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function WorkerService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (WorkerService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WorkerService;
    
                    /**
                     * Creates new WorkerService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.WorkerService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {WorkerService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    WorkerService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.WorkerService#createWorker}.
                     * @memberof palexy.streaming.v1.WorkerService
                     * @typedef CreateWorkerCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Worker} [response] Worker
                     */
    
                    /**
                     * Calls CreateWorker.
                     * @function createWorker
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IWorker} request Worker message or plain object
                     * @param {palexy.streaming.v1.WorkerService.CreateWorkerCallback} callback Node-style callback called with the error, if any, and Worker
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WorkerService.prototype.createWorker = function createWorker(request, callback) {
                        return this.rpcCall(createWorker, $root.palexy.streaming.v1.Worker, $root.palexy.streaming.v1.Worker, request, callback);
                    }, "name", { value: "CreateWorker" });
    
                    /**
                     * Calls CreateWorker.
                     * @function createWorker
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IWorker} request Worker message or plain object
                     * @returns {Promise<palexy.streaming.v1.Worker>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.WorkerService#listWorkerCameras}.
                     * @memberof palexy.streaming.v1.WorkerService
                     * @typedef ListWorkerCamerasCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListWorkerCamerasResponse} [response] ListWorkerCamerasResponse
                     */
    
                    /**
                     * Calls ListWorkerCameras.
                     * @function listWorkerCameras
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IListWorkerCamerasRequest} request ListWorkerCamerasRequest message or plain object
                     * @param {palexy.streaming.v1.WorkerService.ListWorkerCamerasCallback} callback Node-style callback called with the error, if any, and ListWorkerCamerasResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WorkerService.prototype.listWorkerCameras = function listWorkerCameras(request, callback) {
                        return this.rpcCall(listWorkerCameras, $root.palexy.streaming.v1.ListWorkerCamerasRequest, $root.palexy.streaming.v1.ListWorkerCamerasResponse, request, callback);
                    }, "name", { value: "ListWorkerCameras" });
    
                    /**
                     * Calls ListWorkerCameras.
                     * @function listWorkerCameras
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IListWorkerCamerasRequest} request ListWorkerCamerasRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListWorkerCamerasResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.WorkerService#listWorkers}.
                     * @memberof palexy.streaming.v1.WorkerService
                     * @typedef ListWorkersCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListWorkersResponse} [response] ListWorkersResponse
                     */
    
                    /**
                     * Calls ListWorkers.
                     * @function listWorkers
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IListWorkersRequest} request ListWorkersRequest message or plain object
                     * @param {palexy.streaming.v1.WorkerService.ListWorkersCallback} callback Node-style callback called with the error, if any, and ListWorkersResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WorkerService.prototype.listWorkers = function listWorkers(request, callback) {
                        return this.rpcCall(listWorkers, $root.palexy.streaming.v1.ListWorkersRequest, $root.palexy.streaming.v1.ListWorkersResponse, request, callback);
                    }, "name", { value: "ListWorkers" });
    
                    /**
                     * Calls ListWorkers.
                     * @function listWorkers
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IListWorkersRequest} request ListWorkersRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListWorkersResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.WorkerService#updateWorkerHeartbeat}.
                     * @memberof palexy.streaming.v1.WorkerService
                     * @typedef UpdateWorkerHeartbeatCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls UpdateWorkerHeartbeat.
                     * @function updateWorkerHeartbeat
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateWorkerHeartbeatRequest} request UpdateWorkerHeartbeatRequest message or plain object
                     * @param {palexy.streaming.v1.WorkerService.UpdateWorkerHeartbeatCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WorkerService.prototype.updateWorkerHeartbeat = function updateWorkerHeartbeat(request, callback) {
                        return this.rpcCall(updateWorkerHeartbeat, $root.palexy.streaming.v1.UpdateWorkerHeartbeatRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "UpdateWorkerHeartbeat" });
    
                    /**
                     * Calls UpdateWorkerHeartbeat.
                     * @function updateWorkerHeartbeat
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateWorkerHeartbeatRequest} request UpdateWorkerHeartbeatRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.WorkerService#terminateWorker}.
                     * @memberof palexy.streaming.v1.WorkerService
                     * @typedef TerminateWorkerCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls TerminateWorker.
                     * @function terminateWorker
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.ITerminateWorkerRequest} request TerminateWorkerRequest message or plain object
                     * @param {palexy.streaming.v1.WorkerService.TerminateWorkerCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WorkerService.prototype.terminateWorker = function terminateWorker(request, callback) {
                        return this.rpcCall(terminateWorker, $root.palexy.streaming.v1.TerminateWorkerRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "TerminateWorker" });
    
                    /**
                     * Calls TerminateWorker.
                     * @function terminateWorker
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.ITerminateWorkerRequest} request TerminateWorkerRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.WorkerService#updateWorkerCameraAssignments}.
                     * @memberof palexy.streaming.v1.WorkerService
                     * @typedef UpdateWorkerCameraAssignmentsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls UpdateWorkerCameraAssignments.
                     * @function updateWorkerCameraAssignments
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateWorkerCameraAssignmentsRequest} request UpdateWorkerCameraAssignmentsRequest message or plain object
                     * @param {palexy.streaming.v1.WorkerService.UpdateWorkerCameraAssignmentsCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WorkerService.prototype.updateWorkerCameraAssignments = function updateWorkerCameraAssignments(request, callback) {
                        return this.rpcCall(updateWorkerCameraAssignments, $root.palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "UpdateWorkerCameraAssignments" });
    
                    /**
                     * Calls UpdateWorkerCameraAssignments.
                     * @function updateWorkerCameraAssignments
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateWorkerCameraAssignmentsRequest} request UpdateWorkerCameraAssignmentsRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.WorkerService#getWorker}.
                     * @memberof palexy.streaming.v1.WorkerService
                     * @typedef GetWorkerCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Worker} [response] Worker
                     */
    
                    /**
                     * Calls GetWorker.
                     * @function getWorker
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IGetWorkerRequest} request GetWorkerRequest message or plain object
                     * @param {palexy.streaming.v1.WorkerService.GetWorkerCallback} callback Node-style callback called with the error, if any, and Worker
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WorkerService.prototype.getWorker = function getWorker(request, callback) {
                        return this.rpcCall(getWorker, $root.palexy.streaming.v1.GetWorkerRequest, $root.palexy.streaming.v1.Worker, request, callback);
                    }, "name", { value: "GetWorker" });
    
                    /**
                     * Calls GetWorker.
                     * @function getWorker
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IGetWorkerRequest} request GetWorkerRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Worker>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.WorkerService#upgradeWorker}.
                     * @memberof palexy.streaming.v1.WorkerService
                     * @typedef UpgradeWorkerCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Worker} [response] Worker
                     */
    
                    /**
                     * Calls UpgradeWorker.
                     * @function upgradeWorker
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IUpgradeWorkerRequest} request UpgradeWorkerRequest message or plain object
                     * @param {palexy.streaming.v1.WorkerService.UpgradeWorkerCallback} callback Node-style callback called with the error, if any, and Worker
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WorkerService.prototype.upgradeWorker = function upgradeWorker(request, callback) {
                        return this.rpcCall(upgradeWorker, $root.palexy.streaming.v1.UpgradeWorkerRequest, $root.palexy.streaming.v1.Worker, request, callback);
                    }, "name", { value: "UpgradeWorker" });
    
                    /**
                     * Calls UpgradeWorker.
                     * @function upgradeWorker
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {palexy.streaming.v1.IUpgradeWorkerRequest} request UpgradeWorkerRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Worker>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.WorkerService#listAvailableVersions}.
                     * @memberof palexy.streaming.v1.WorkerService
                     * @typedef ListAvailableVersionsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListAvailableVersionsResponse} [response] ListAvailableVersionsResponse
                     */
    
                    /**
                     * Calls ListAvailableVersions.
                     * @function listAvailableVersions
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {google.protobuf.IEmpty} request Empty message or plain object
                     * @param {palexy.streaming.v1.WorkerService.ListAvailableVersionsCallback} callback Node-style callback called with the error, if any, and ListAvailableVersionsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WorkerService.prototype.listAvailableVersions = function listAvailableVersions(request, callback) {
                        return this.rpcCall(listAvailableVersions, $root.google.protobuf.Empty, $root.palexy.streaming.v1.ListAvailableVersionsResponse, request, callback);
                    }, "name", { value: "ListAvailableVersions" });
    
                    /**
                     * Calls ListAvailableVersions.
                     * @function listAvailableVersions
                     * @memberof palexy.streaming.v1.WorkerService
                     * @instance
                     * @param {google.protobuf.IEmpty} request Empty message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListAvailableVersionsResponse>} Promise
                     * @variation 2
                     */
    
                    return WorkerService;
                })();
    
                v1.ListAvailableVersionsResponse = (function() {
    
                    /**
                     * Properties of a ListAvailableVersionsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListAvailableVersionsResponse
                     * @property {Array.<palexy.streaming.v1.IBoxAvailableVersion>|null} [versions] ListAvailableVersionsResponse versions
                     */
    
                    /**
                     * Constructs a new ListAvailableVersionsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListAvailableVersionsResponse.
                     * @implements IListAvailableVersionsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListAvailableVersionsResponse=} [properties] Properties to set
                     */
                    function ListAvailableVersionsResponse(properties) {
                        this.versions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListAvailableVersionsResponse versions.
                     * @member {Array.<palexy.streaming.v1.IBoxAvailableVersion>} versions
                     * @memberof palexy.streaming.v1.ListAvailableVersionsResponse
                     * @instance
                     */
                    ListAvailableVersionsResponse.prototype.versions = $util.emptyArray;
    
                    /**
                     * Creates a new ListAvailableVersionsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListAvailableVersionsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAvailableVersionsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListAvailableVersionsResponse} ListAvailableVersionsResponse instance
                     */
                    ListAvailableVersionsResponse.create = function create(properties) {
                        return new ListAvailableVersionsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListAvailableVersionsResponse message. Does not implicitly {@link palexy.streaming.v1.ListAvailableVersionsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListAvailableVersionsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAvailableVersionsResponse} message ListAvailableVersionsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAvailableVersionsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.versions != null && message.versions.length)
                            for (var i = 0; i < message.versions.length; ++i)
                                $root.palexy.streaming.v1.BoxAvailableVersion.encode(message.versions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListAvailableVersionsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListAvailableVersionsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListAvailableVersionsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAvailableVersionsResponse} message ListAvailableVersionsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAvailableVersionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListAvailableVersionsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListAvailableVersionsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListAvailableVersionsResponse} ListAvailableVersionsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAvailableVersionsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListAvailableVersionsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.versions && message.versions.length))
                                    message.versions = [];
                                message.versions.push($root.palexy.streaming.v1.BoxAvailableVersion.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListAvailableVersionsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListAvailableVersionsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListAvailableVersionsResponse} ListAvailableVersionsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAvailableVersionsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListAvailableVersionsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListAvailableVersionsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListAvailableVersionsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.versions != null && message.hasOwnProperty("versions")) {
                            if (!Array.isArray(message.versions))
                                return "versions: array expected";
                            for (var i = 0; i < message.versions.length; ++i) {
                                var error = $root.palexy.streaming.v1.BoxAvailableVersion.verify(message.versions[i]);
                                if (error)
                                    return "versions." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListAvailableVersionsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListAvailableVersionsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListAvailableVersionsResponse} ListAvailableVersionsResponse
                     */
                    ListAvailableVersionsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListAvailableVersionsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListAvailableVersionsResponse();
                        if (object.versions) {
                            if (!Array.isArray(object.versions))
                                throw TypeError(".palexy.streaming.v1.ListAvailableVersionsResponse.versions: array expected");
                            message.versions = [];
                            for (var i = 0; i < object.versions.length; ++i) {
                                if (typeof object.versions[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListAvailableVersionsResponse.versions: object expected");
                                message.versions[i] = $root.palexy.streaming.v1.BoxAvailableVersion.fromObject(object.versions[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListAvailableVersionsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListAvailableVersionsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListAvailableVersionsResponse} message ListAvailableVersionsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListAvailableVersionsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.versions = [];
                        if (message.versions && message.versions.length) {
                            object.versions = [];
                            for (var j = 0; j < message.versions.length; ++j)
                                object.versions[j] = $root.palexy.streaming.v1.BoxAvailableVersion.toObject(message.versions[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListAvailableVersionsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListAvailableVersionsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListAvailableVersionsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListAvailableVersionsResponse;
                })();
    
                v1.UpgradeWorkerRequest = (function() {
    
                    /**
                     * Properties of an UpgradeWorkerRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpgradeWorkerRequest
                     * @property {number|null} [workerId] UpgradeWorkerRequest workerId
                     * @property {string|null} [version] UpgradeWorkerRequest version
                     */
    
                    /**
                     * Constructs a new UpgradeWorkerRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpgradeWorkerRequest.
                     * @implements IUpgradeWorkerRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpgradeWorkerRequest=} [properties] Properties to set
                     */
                    function UpgradeWorkerRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpgradeWorkerRequest workerId.
                     * @member {number} workerId
                     * @memberof palexy.streaming.v1.UpgradeWorkerRequest
                     * @instance
                     */
                    UpgradeWorkerRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * UpgradeWorkerRequest version.
                     * @member {string} version
                     * @memberof palexy.streaming.v1.UpgradeWorkerRequest
                     * @instance
                     */
                    UpgradeWorkerRequest.prototype.version = "";
    
                    /**
                     * Creates a new UpgradeWorkerRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpgradeWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpgradeWorkerRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpgradeWorkerRequest} UpgradeWorkerRequest instance
                     */
                    UpgradeWorkerRequest.create = function create(properties) {
                        return new UpgradeWorkerRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpgradeWorkerRequest message. Does not implicitly {@link palexy.streaming.v1.UpgradeWorkerRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpgradeWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpgradeWorkerRequest} message UpgradeWorkerRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpgradeWorkerRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
                        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpgradeWorkerRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpgradeWorkerRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpgradeWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpgradeWorkerRequest} message UpgradeWorkerRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpgradeWorkerRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpgradeWorkerRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpgradeWorkerRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpgradeWorkerRequest} UpgradeWorkerRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpgradeWorkerRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpgradeWorkerRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.workerId = reader.int64();
                                break;
                            case 2:
                                message.version = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpgradeWorkerRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpgradeWorkerRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpgradeWorkerRequest} UpgradeWorkerRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpgradeWorkerRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpgradeWorkerRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpgradeWorkerRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpgradeWorkerRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.workerId != null && message.hasOwnProperty("workerId"))
                            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                                return "workerId: integer|Long expected";
                        if (message.version != null && message.hasOwnProperty("version"))
                            if (!$util.isString(message.version))
                                return "version: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpgradeWorkerRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpgradeWorkerRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpgradeWorkerRequest} UpgradeWorkerRequest
                     */
                    UpgradeWorkerRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpgradeWorkerRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpgradeWorkerRequest();
                        if (object.workerId != null)
                            if ($util.Long)
                                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
                            else if (typeof object.workerId === "string")
                                message.workerId = parseInt(object.workerId, 10);
                            else if (typeof object.workerId === "number")
                                message.workerId = object.workerId;
                            else if (typeof object.workerId === "object")
                                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
                        if (object.version != null)
                            message.version = String(object.version);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpgradeWorkerRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpgradeWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.UpgradeWorkerRequest} message UpgradeWorkerRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpgradeWorkerRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.workerId = options.longs === String ? "0" : 0;
                            object.version = "";
                        }
                        if (message.workerId != null && message.hasOwnProperty("workerId"))
                            if (typeof message.workerId === "number")
                                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
                            else
                                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
                        if (message.version != null && message.hasOwnProperty("version"))
                            object.version = message.version;
                        return object;
                    };
    
                    /**
                     * Converts this UpgradeWorkerRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpgradeWorkerRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpgradeWorkerRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpgradeWorkerRequest;
                })();
    
                v1.BoxAvailableVersion = (function() {
    
                    /**
                     * Properties of a BoxAvailableVersion.
                     * @memberof palexy.streaming.v1
                     * @interface IBoxAvailableVersion
                     * @property {number|null} [id] BoxAvailableVersion id
                     * @property {string|null} [version] BoxAvailableVersion version
                     */
    
                    /**
                     * Constructs a new BoxAvailableVersion.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BoxAvailableVersion.
                     * @implements IBoxAvailableVersion
                     * @constructor
                     * @param {palexy.streaming.v1.IBoxAvailableVersion=} [properties] Properties to set
                     */
                    function BoxAvailableVersion(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BoxAvailableVersion id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.BoxAvailableVersion
                     * @instance
                     */
                    BoxAvailableVersion.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * BoxAvailableVersion version.
                     * @member {string} version
                     * @memberof palexy.streaming.v1.BoxAvailableVersion
                     * @instance
                     */
                    BoxAvailableVersion.prototype.version = "";
    
                    /**
                     * Creates a new BoxAvailableVersion instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BoxAvailableVersion
                     * @static
                     * @param {palexy.streaming.v1.IBoxAvailableVersion=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BoxAvailableVersion} BoxAvailableVersion instance
                     */
                    BoxAvailableVersion.create = function create(properties) {
                        return new BoxAvailableVersion(properties);
                    };
    
                    /**
                     * Encodes the specified BoxAvailableVersion message. Does not implicitly {@link palexy.streaming.v1.BoxAvailableVersion.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BoxAvailableVersion
                     * @static
                     * @param {palexy.streaming.v1.IBoxAvailableVersion} message BoxAvailableVersion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BoxAvailableVersion.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BoxAvailableVersion message, length delimited. Does not implicitly {@link palexy.streaming.v1.BoxAvailableVersion.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BoxAvailableVersion
                     * @static
                     * @param {palexy.streaming.v1.IBoxAvailableVersion} message BoxAvailableVersion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BoxAvailableVersion.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BoxAvailableVersion message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BoxAvailableVersion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BoxAvailableVersion} BoxAvailableVersion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BoxAvailableVersion.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BoxAvailableVersion();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.version = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BoxAvailableVersion message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BoxAvailableVersion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BoxAvailableVersion} BoxAvailableVersion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BoxAvailableVersion.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BoxAvailableVersion message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BoxAvailableVersion
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BoxAvailableVersion.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.version != null && message.hasOwnProperty("version"))
                            if (!$util.isString(message.version))
                                return "version: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a BoxAvailableVersion message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BoxAvailableVersion
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BoxAvailableVersion} BoxAvailableVersion
                     */
                    BoxAvailableVersion.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BoxAvailableVersion)
                            return object;
                        var message = new $root.palexy.streaming.v1.BoxAvailableVersion();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.version != null)
                            message.version = String(object.version);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BoxAvailableVersion message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BoxAvailableVersion
                     * @static
                     * @param {palexy.streaming.v1.BoxAvailableVersion} message BoxAvailableVersion
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BoxAvailableVersion.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.version = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.version != null && message.hasOwnProperty("version"))
                            object.version = message.version;
                        return object;
                    };
    
                    /**
                     * Converts this BoxAvailableVersion to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BoxAvailableVersion
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BoxAvailableVersion.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BoxAvailableVersion;
                })();
    
                v1.Worker = (function() {
    
                    /**
                     * Properties of a Worker.
                     * @memberof palexy.streaming.v1
                     * @interface IWorker
                     * @property {string|null} [name] Worker name
                     * @property {number|null} [maximumCapacity] Worker maximumCapacity
                     * @property {Array.<palexy.streaming.v1.ICamera>|null} [assignedCameras] Worker assignedCameras
                     * @property {google.protobuf.ITimestamp|null} [lastHeartbeatTime] Worker lastHeartbeatTime
                     * @property {string|null} [location] Worker location
                     * @property {palexy.streaming.v1.Worker.WorkerStatus|null} [status] Worker status
                     * @property {boolean|null} [strictlyMatchWithSameLocation] Worker strictlyMatchWithSameLocation
                     * @property {number|null} [id] Worker id
                     * @property {google.protobuf.ITimestamp|null} [createTime] Worker createTime
                     * @property {string|null} [runningVersion] Worker runningVersion
                     * @property {string|null} [desiredVersion] Worker desiredVersion
                     */
    
                    /**
                     * Constructs a new Worker.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Worker.
                     * @implements IWorker
                     * @constructor
                     * @param {palexy.streaming.v1.IWorker=} [properties] Properties to set
                     */
                    function Worker(properties) {
                        this.assignedCameras = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Worker name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     */
                    Worker.prototype.name = "";
    
                    /**
                     * Worker maximumCapacity.
                     * @member {number} maximumCapacity
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     */
                    Worker.prototype.maximumCapacity = 0;
    
                    /**
                     * Worker assignedCameras.
                     * @member {Array.<palexy.streaming.v1.ICamera>} assignedCameras
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     */
                    Worker.prototype.assignedCameras = $util.emptyArray;
    
                    /**
                     * Worker lastHeartbeatTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastHeartbeatTime
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     */
                    Worker.prototype.lastHeartbeatTime = null;
    
                    /**
                     * Worker location.
                     * @member {string} location
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     */
                    Worker.prototype.location = "";
    
                    /**
                     * Worker status.
                     * @member {palexy.streaming.v1.Worker.WorkerStatus} status
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     */
                    Worker.prototype.status = 0;
    
                    /**
                     * Worker strictlyMatchWithSameLocation.
                     * @member {boolean} strictlyMatchWithSameLocation
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     */
                    Worker.prototype.strictlyMatchWithSameLocation = false;
    
                    /**
                     * Worker id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     */
                    Worker.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Worker createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     */
                    Worker.prototype.createTime = null;
    
                    /**
                     * Worker runningVersion.
                     * @member {string} runningVersion
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     */
                    Worker.prototype.runningVersion = "";
    
                    /**
                     * Worker desiredVersion.
                     * @member {string} desiredVersion
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     */
                    Worker.prototype.desiredVersion = "";
    
                    /**
                     * Creates a new Worker instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Worker
                     * @static
                     * @param {palexy.streaming.v1.IWorker=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Worker} Worker instance
                     */
                    Worker.create = function create(properties) {
                        return new Worker(properties);
                    };
    
                    /**
                     * Encodes the specified Worker message. Does not implicitly {@link palexy.streaming.v1.Worker.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Worker
                     * @static
                     * @param {palexy.streaming.v1.IWorker} message Worker message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Worker.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.maximumCapacity != null && Object.hasOwnProperty.call(message, "maximumCapacity"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maximumCapacity);
                        if (message.assignedCameras != null && message.assignedCameras.length)
                            for (var i = 0; i < message.assignedCameras.length; ++i)
                                $root.palexy.streaming.v1.Camera.encode(message.assignedCameras[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.lastHeartbeatTime != null && Object.hasOwnProperty.call(message, "lastHeartbeatTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastHeartbeatTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.location);
                        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.status);
                        if (message.strictlyMatchWithSameLocation != null && Object.hasOwnProperty.call(message, "strictlyMatchWithSameLocation"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.strictlyMatchWithSameLocation);
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.id);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.runningVersion != null && Object.hasOwnProperty.call(message, "runningVersion"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.runningVersion);
                        if (message.desiredVersion != null && Object.hasOwnProperty.call(message, "desiredVersion"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.desiredVersion);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Worker message, length delimited. Does not implicitly {@link palexy.streaming.v1.Worker.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Worker
                     * @static
                     * @param {palexy.streaming.v1.IWorker} message Worker message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Worker.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Worker message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Worker
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Worker} Worker
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Worker.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Worker();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.maximumCapacity = reader.int32();
                                break;
                            case 3:
                                if (!(message.assignedCameras && message.assignedCameras.length))
                                    message.assignedCameras = [];
                                message.assignedCameras.push($root.palexy.streaming.v1.Camera.decode(reader, reader.uint32()));
                                break;
                            case 4:
                                message.lastHeartbeatTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.location = reader.string();
                                break;
                            case 6:
                                message.status = reader.int32();
                                break;
                            case 7:
                                message.strictlyMatchWithSameLocation = reader.bool();
                                break;
                            case 8:
                                message.id = reader.int64();
                                break;
                            case 9:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.runningVersion = reader.string();
                                break;
                            case 11:
                                message.desiredVersion = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Worker message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Worker
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Worker} Worker
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Worker.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Worker message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Worker
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Worker.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.maximumCapacity != null && message.hasOwnProperty("maximumCapacity"))
                            if (!$util.isInteger(message.maximumCapacity))
                                return "maximumCapacity: integer expected";
                        if (message.assignedCameras != null && message.hasOwnProperty("assignedCameras")) {
                            if (!Array.isArray(message.assignedCameras))
                                return "assignedCameras: array expected";
                            for (var i = 0; i < message.assignedCameras.length; ++i) {
                                var error = $root.palexy.streaming.v1.Camera.verify(message.assignedCameras[i]);
                                if (error)
                                    return "assignedCameras." + error;
                            }
                        }
                        if (message.lastHeartbeatTime != null && message.hasOwnProperty("lastHeartbeatTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastHeartbeatTime);
                            if (error)
                                return "lastHeartbeatTime." + error;
                        }
                        if (message.location != null && message.hasOwnProperty("location"))
                            if (!$util.isString(message.location))
                                return "location: string expected";
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.strictlyMatchWithSameLocation != null && message.hasOwnProperty("strictlyMatchWithSameLocation"))
                            if (typeof message.strictlyMatchWithSameLocation !== "boolean")
                                return "strictlyMatchWithSameLocation: boolean expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.runningVersion != null && message.hasOwnProperty("runningVersion"))
                            if (!$util.isString(message.runningVersion))
                                return "runningVersion: string expected";
                        if (message.desiredVersion != null && message.hasOwnProperty("desiredVersion"))
                            if (!$util.isString(message.desiredVersion))
                                return "desiredVersion: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a Worker message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Worker
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Worker} Worker
                     */
                    Worker.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Worker)
                            return object;
                        var message = new $root.palexy.streaming.v1.Worker();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.maximumCapacity != null)
                            message.maximumCapacity = object.maximumCapacity | 0;
                        if (object.assignedCameras) {
                            if (!Array.isArray(object.assignedCameras))
                                throw TypeError(".palexy.streaming.v1.Worker.assignedCameras: array expected");
                            message.assignedCameras = [];
                            for (var i = 0; i < object.assignedCameras.length; ++i) {
                                if (typeof object.assignedCameras[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.Worker.assignedCameras: object expected");
                                message.assignedCameras[i] = $root.palexy.streaming.v1.Camera.fromObject(object.assignedCameras[i]);
                            }
                        }
                        if (object.lastHeartbeatTime != null) {
                            if (typeof object.lastHeartbeatTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Worker.lastHeartbeatTime: object expected");
                            message.lastHeartbeatTime = $root.google.protobuf.Timestamp.fromObject(object.lastHeartbeatTime);
                        }
                        if (object.location != null)
                            message.location = String(object.location);
                        switch (object.status) {
                        case "WORKER_STATUS_UNKNOWN":
                        case 0:
                            message.status = 0;
                            break;
                        case "NORMAL":
                        case 1:
                            message.status = 1;
                            break;
                        case "TERMINATING":
                        case 2:
                            message.status = 2;
                            break;
                        }
                        if (object.strictlyMatchWithSameLocation != null)
                            message.strictlyMatchWithSameLocation = Boolean(object.strictlyMatchWithSameLocation);
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Worker.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.runningVersion != null)
                            message.runningVersion = String(object.runningVersion);
                        if (object.desiredVersion != null)
                            message.desiredVersion = String(object.desiredVersion);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Worker message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Worker
                     * @static
                     * @param {palexy.streaming.v1.Worker} message Worker
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Worker.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.assignedCameras = [];
                        if (options.defaults) {
                            object.name = "";
                            object.maximumCapacity = 0;
                            object.lastHeartbeatTime = null;
                            object.location = "";
                            object.status = options.enums === String ? "WORKER_STATUS_UNKNOWN" : 0;
                            object.strictlyMatchWithSameLocation = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.createTime = null;
                            object.runningVersion = "";
                            object.desiredVersion = "";
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.maximumCapacity != null && message.hasOwnProperty("maximumCapacity"))
                            object.maximumCapacity = message.maximumCapacity;
                        if (message.assignedCameras && message.assignedCameras.length) {
                            object.assignedCameras = [];
                            for (var j = 0; j < message.assignedCameras.length; ++j)
                                object.assignedCameras[j] = $root.palexy.streaming.v1.Camera.toObject(message.assignedCameras[j], options);
                        }
                        if (message.lastHeartbeatTime != null && message.hasOwnProperty("lastHeartbeatTime"))
                            object.lastHeartbeatTime = $root.google.protobuf.Timestamp.toObject(message.lastHeartbeatTime, options);
                        if (message.location != null && message.hasOwnProperty("location"))
                            object.location = message.location;
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.palexy.streaming.v1.Worker.WorkerStatus[message.status] : message.status;
                        if (message.strictlyMatchWithSameLocation != null && message.hasOwnProperty("strictlyMatchWithSameLocation"))
                            object.strictlyMatchWithSameLocation = message.strictlyMatchWithSameLocation;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.runningVersion != null && message.hasOwnProperty("runningVersion"))
                            object.runningVersion = message.runningVersion;
                        if (message.desiredVersion != null && message.hasOwnProperty("desiredVersion"))
                            object.desiredVersion = message.desiredVersion;
                        return object;
                    };
    
                    /**
                     * Converts this Worker to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Worker
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Worker.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * WorkerStatus enum.
                     * @name palexy.streaming.v1.Worker.WorkerStatus
                     * @enum {number}
                     * @property {number} WORKER_STATUS_UNKNOWN=0 WORKER_STATUS_UNKNOWN value
                     * @property {number} NORMAL=1 NORMAL value
                     * @property {number} TERMINATING=2 TERMINATING value
                     */
                    Worker.WorkerStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "WORKER_STATUS_UNKNOWN"] = 0;
                        values[valuesById[1] = "NORMAL"] = 1;
                        values[valuesById[2] = "TERMINATING"] = 2;
                        return values;
                    })();
    
                    return Worker;
                })();
    
                v1.GetWorkerRequest = (function() {
    
                    /**
                     * Properties of a GetWorkerRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetWorkerRequest
                     * @property {number|null} [workerId] GetWorkerRequest workerId
                     * @property {string|null} [workerName] GetWorkerRequest workerName
                     */
    
                    /**
                     * Constructs a new GetWorkerRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetWorkerRequest.
                     * @implements IGetWorkerRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetWorkerRequest=} [properties] Properties to set
                     */
                    function GetWorkerRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetWorkerRequest workerId.
                     * @member {number} workerId
                     * @memberof palexy.streaming.v1.GetWorkerRequest
                     * @instance
                     */
                    GetWorkerRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetWorkerRequest workerName.
                     * @member {string} workerName
                     * @memberof palexy.streaming.v1.GetWorkerRequest
                     * @instance
                     */
                    GetWorkerRequest.prototype.workerName = "";
    
                    /**
                     * Creates a new GetWorkerRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetWorkerRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetWorkerRequest} GetWorkerRequest instance
                     */
                    GetWorkerRequest.create = function create(properties) {
                        return new GetWorkerRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetWorkerRequest message. Does not implicitly {@link palexy.streaming.v1.GetWorkerRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetWorkerRequest} message GetWorkerRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetWorkerRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
                        if (message.workerName != null && Object.hasOwnProperty.call(message, "workerName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.workerName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetWorkerRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetWorkerRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetWorkerRequest} message GetWorkerRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetWorkerRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetWorkerRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetWorkerRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetWorkerRequest} GetWorkerRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetWorkerRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetWorkerRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.workerId = reader.int64();
                                break;
                            case 2:
                                message.workerName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetWorkerRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetWorkerRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetWorkerRequest} GetWorkerRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetWorkerRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetWorkerRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetWorkerRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetWorkerRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.workerId != null && message.hasOwnProperty("workerId"))
                            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                                return "workerId: integer|Long expected";
                        if (message.workerName != null && message.hasOwnProperty("workerName"))
                            if (!$util.isString(message.workerName))
                                return "workerName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetWorkerRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetWorkerRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetWorkerRequest} GetWorkerRequest
                     */
                    GetWorkerRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetWorkerRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetWorkerRequest();
                        if (object.workerId != null)
                            if ($util.Long)
                                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
                            else if (typeof object.workerId === "string")
                                message.workerId = parseInt(object.workerId, 10);
                            else if (typeof object.workerId === "number")
                                message.workerId = object.workerId;
                            else if (typeof object.workerId === "object")
                                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
                        if (object.workerName != null)
                            message.workerName = String(object.workerName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetWorkerRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.GetWorkerRequest} message GetWorkerRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetWorkerRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.workerId = options.longs === String ? "0" : 0;
                            object.workerName = "";
                        }
                        if (message.workerId != null && message.hasOwnProperty("workerId"))
                            if (typeof message.workerId === "number")
                                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
                            else
                                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
                        if (message.workerName != null && message.hasOwnProperty("workerName"))
                            object.workerName = message.workerName;
                        return object;
                    };
    
                    /**
                     * Converts this GetWorkerRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetWorkerRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetWorkerRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetWorkerRequest;
                })();
    
                v1.ListWorkerCamerasRequest = (function() {
    
                    /**
                     * Properties of a ListWorkerCamerasRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListWorkerCamerasRequest
                     * @property {string|null} [worker] ListWorkerCamerasRequest worker
                     * @property {number|null} [pageSize] ListWorkerCamerasRequest pageSize
                     * @property {string|null} [pageToken] ListWorkerCamerasRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListWorkerCamerasRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListWorkerCamerasRequest.
                     * @implements IListWorkerCamerasRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListWorkerCamerasRequest=} [properties] Properties to set
                     */
                    function ListWorkerCamerasRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListWorkerCamerasRequest worker.
                     * @member {string} worker
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @instance
                     */
                    ListWorkerCamerasRequest.prototype.worker = "";
    
                    /**
                     * ListWorkerCamerasRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @instance
                     */
                    ListWorkerCamerasRequest.prototype.pageSize = 0;
    
                    /**
                     * ListWorkerCamerasRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @instance
                     */
                    ListWorkerCamerasRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListWorkerCamerasRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @static
                     * @param {palexy.streaming.v1.IListWorkerCamerasRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListWorkerCamerasRequest} ListWorkerCamerasRequest instance
                     */
                    ListWorkerCamerasRequest.create = function create(properties) {
                        return new ListWorkerCamerasRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListWorkerCamerasRequest message. Does not implicitly {@link palexy.streaming.v1.ListWorkerCamerasRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @static
                     * @param {palexy.streaming.v1.IListWorkerCamerasRequest} message ListWorkerCamerasRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListWorkerCamerasRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.worker != null && Object.hasOwnProperty.call(message, "worker"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.worker);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListWorkerCamerasRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListWorkerCamerasRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @static
                     * @param {palexy.streaming.v1.IListWorkerCamerasRequest} message ListWorkerCamerasRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListWorkerCamerasRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListWorkerCamerasRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListWorkerCamerasRequest} ListWorkerCamerasRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListWorkerCamerasRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListWorkerCamerasRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.worker = reader.string();
                                break;
                            case 2:
                                message.pageSize = reader.int32();
                                break;
                            case 3:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListWorkerCamerasRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListWorkerCamerasRequest} ListWorkerCamerasRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListWorkerCamerasRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListWorkerCamerasRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListWorkerCamerasRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.worker != null && message.hasOwnProperty("worker"))
                            if (!$util.isString(message.worker))
                                return "worker: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListWorkerCamerasRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListWorkerCamerasRequest} ListWorkerCamerasRequest
                     */
                    ListWorkerCamerasRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListWorkerCamerasRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListWorkerCamerasRequest();
                        if (object.worker != null)
                            message.worker = String(object.worker);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListWorkerCamerasRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @static
                     * @param {palexy.streaming.v1.ListWorkerCamerasRequest} message ListWorkerCamerasRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListWorkerCamerasRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.worker = "";
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.worker != null && message.hasOwnProperty("worker"))
                            object.worker = message.worker;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListWorkerCamerasRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListWorkerCamerasRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListWorkerCamerasRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListWorkerCamerasRequest;
                })();
    
                v1.ListWorkerCamerasResponse = (function() {
    
                    /**
                     * Properties of a ListWorkerCamerasResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListWorkerCamerasResponse
                     * @property {Array.<palexy.streaming.v1.ICamera>|null} [cameras] ListWorkerCamerasResponse cameras
                     * @property {string|null} [nextPageToken] ListWorkerCamerasResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListWorkerCamerasResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListWorkerCamerasResponse.
                     * @implements IListWorkerCamerasResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListWorkerCamerasResponse=} [properties] Properties to set
                     */
                    function ListWorkerCamerasResponse(properties) {
                        this.cameras = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListWorkerCamerasResponse cameras.
                     * @member {Array.<palexy.streaming.v1.ICamera>} cameras
                     * @memberof palexy.streaming.v1.ListWorkerCamerasResponse
                     * @instance
                     */
                    ListWorkerCamerasResponse.prototype.cameras = $util.emptyArray;
    
                    /**
                     * ListWorkerCamerasResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListWorkerCamerasResponse
                     * @instance
                     */
                    ListWorkerCamerasResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListWorkerCamerasResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListWorkerCamerasResponse
                     * @static
                     * @param {palexy.streaming.v1.IListWorkerCamerasResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListWorkerCamerasResponse} ListWorkerCamerasResponse instance
                     */
                    ListWorkerCamerasResponse.create = function create(properties) {
                        return new ListWorkerCamerasResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListWorkerCamerasResponse message. Does not implicitly {@link palexy.streaming.v1.ListWorkerCamerasResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListWorkerCamerasResponse
                     * @static
                     * @param {palexy.streaming.v1.IListWorkerCamerasResponse} message ListWorkerCamerasResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListWorkerCamerasResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameras != null && message.cameras.length)
                            for (var i = 0; i < message.cameras.length; ++i)
                                $root.palexy.streaming.v1.Camera.encode(message.cameras[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListWorkerCamerasResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListWorkerCamerasResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListWorkerCamerasResponse
                     * @static
                     * @param {palexy.streaming.v1.IListWorkerCamerasResponse} message ListWorkerCamerasResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListWorkerCamerasResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListWorkerCamerasResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListWorkerCamerasResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListWorkerCamerasResponse} ListWorkerCamerasResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListWorkerCamerasResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListWorkerCamerasResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.cameras && message.cameras.length))
                                    message.cameras = [];
                                message.cameras.push($root.palexy.streaming.v1.Camera.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListWorkerCamerasResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListWorkerCamerasResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListWorkerCamerasResponse} ListWorkerCamerasResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListWorkerCamerasResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListWorkerCamerasResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListWorkerCamerasResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListWorkerCamerasResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameras != null && message.hasOwnProperty("cameras")) {
                            if (!Array.isArray(message.cameras))
                                return "cameras: array expected";
                            for (var i = 0; i < message.cameras.length; ++i) {
                                var error = $root.palexy.streaming.v1.Camera.verify(message.cameras[i]);
                                if (error)
                                    return "cameras." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListWorkerCamerasResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListWorkerCamerasResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListWorkerCamerasResponse} ListWorkerCamerasResponse
                     */
                    ListWorkerCamerasResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListWorkerCamerasResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListWorkerCamerasResponse();
                        if (object.cameras) {
                            if (!Array.isArray(object.cameras))
                                throw TypeError(".palexy.streaming.v1.ListWorkerCamerasResponse.cameras: array expected");
                            message.cameras = [];
                            for (var i = 0; i < object.cameras.length; ++i) {
                                if (typeof object.cameras[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListWorkerCamerasResponse.cameras: object expected");
                                message.cameras[i] = $root.palexy.streaming.v1.Camera.fromObject(object.cameras[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListWorkerCamerasResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListWorkerCamerasResponse
                     * @static
                     * @param {palexy.streaming.v1.ListWorkerCamerasResponse} message ListWorkerCamerasResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListWorkerCamerasResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.cameras = [];
                        if (options.defaults)
                            object.nextPageToken = "";
                        if (message.cameras && message.cameras.length) {
                            object.cameras = [];
                            for (var j = 0; j < message.cameras.length; ++j)
                                object.cameras[j] = $root.palexy.streaming.v1.Camera.toObject(message.cameras[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListWorkerCamerasResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListWorkerCamerasResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListWorkerCamerasResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListWorkerCamerasResponse;
                })();
    
                v1.ListWorkersRequest = (function() {
    
                    /**
                     * Properties of a ListWorkersRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListWorkersRequest
                     * @property {number|null} [pageSize] ListWorkersRequest pageSize
                     * @property {string|null} [pageToken] ListWorkersRequest pageToken
                     * @property {string|null} [location] ListWorkersRequest location
                     * @property {string|null} [searchKey] ListWorkersRequest searchKey
                     * @property {string|null} [sortBy] ListWorkersRequest sortBy
                     * @property {string|null} [sortDirection] ListWorkersRequest sortDirection
                     */
    
                    /**
                     * Constructs a new ListWorkersRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListWorkersRequest.
                     * @implements IListWorkersRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListWorkersRequest=} [properties] Properties to set
                     */
                    function ListWorkersRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListWorkersRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @instance
                     */
                    ListWorkersRequest.prototype.pageSize = 0;
    
                    /**
                     * ListWorkersRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @instance
                     */
                    ListWorkersRequest.prototype.pageToken = "";
    
                    /**
                     * ListWorkersRequest location.
                     * @member {string} location
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @instance
                     */
                    ListWorkersRequest.prototype.location = "";
    
                    /**
                     * ListWorkersRequest searchKey.
                     * @member {string} searchKey
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @instance
                     */
                    ListWorkersRequest.prototype.searchKey = "";
    
                    /**
                     * ListWorkersRequest sortBy.
                     * @member {string} sortBy
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @instance
                     */
                    ListWorkersRequest.prototype.sortBy = "";
    
                    /**
                     * ListWorkersRequest sortDirection.
                     * @member {string} sortDirection
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @instance
                     */
                    ListWorkersRequest.prototype.sortDirection = "";
    
                    /**
                     * Creates a new ListWorkersRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @static
                     * @param {palexy.streaming.v1.IListWorkersRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListWorkersRequest} ListWorkersRequest instance
                     */
                    ListWorkersRequest.create = function create(properties) {
                        return new ListWorkersRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListWorkersRequest message. Does not implicitly {@link palexy.streaming.v1.ListWorkersRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @static
                     * @param {palexy.streaming.v1.IListWorkersRequest} message ListWorkersRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListWorkersRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pageToken);
                        if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.location);
                        if (message.searchKey != null && Object.hasOwnProperty.call(message, "searchKey"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.searchKey);
                        if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.sortBy);
                        if (message.sortDirection != null && Object.hasOwnProperty.call(message, "sortDirection"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.sortDirection);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListWorkersRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListWorkersRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @static
                     * @param {palexy.streaming.v1.IListWorkersRequest} message ListWorkersRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListWorkersRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListWorkersRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListWorkersRequest} ListWorkersRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListWorkersRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListWorkersRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.pageSize = reader.int32();
                                break;
                            case 2:
                                message.pageToken = reader.string();
                                break;
                            case 3:
                                message.location = reader.string();
                                break;
                            case 4:
                                message.searchKey = reader.string();
                                break;
                            case 5:
                                message.sortBy = reader.string();
                                break;
                            case 6:
                                message.sortDirection = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListWorkersRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListWorkersRequest} ListWorkersRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListWorkersRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListWorkersRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListWorkersRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.location != null && message.hasOwnProperty("location"))
                            if (!$util.isString(message.location))
                                return "location: string expected";
                        if (message.searchKey != null && message.hasOwnProperty("searchKey"))
                            if (!$util.isString(message.searchKey))
                                return "searchKey: string expected";
                        if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                            if (!$util.isString(message.sortBy))
                                return "sortBy: string expected";
                        if (message.sortDirection != null && message.hasOwnProperty("sortDirection"))
                            if (!$util.isString(message.sortDirection))
                                return "sortDirection: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListWorkersRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListWorkersRequest} ListWorkersRequest
                     */
                    ListWorkersRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListWorkersRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListWorkersRequest();
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.location != null)
                            message.location = String(object.location);
                        if (object.searchKey != null)
                            message.searchKey = String(object.searchKey);
                        if (object.sortBy != null)
                            message.sortBy = String(object.sortBy);
                        if (object.sortDirection != null)
                            message.sortDirection = String(object.sortDirection);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListWorkersRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @static
                     * @param {palexy.streaming.v1.ListWorkersRequest} message ListWorkersRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListWorkersRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.location = "";
                            object.searchKey = "";
                            object.sortBy = "";
                            object.sortDirection = "";
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.location != null && message.hasOwnProperty("location"))
                            object.location = message.location;
                        if (message.searchKey != null && message.hasOwnProperty("searchKey"))
                            object.searchKey = message.searchKey;
                        if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                            object.sortBy = message.sortBy;
                        if (message.sortDirection != null && message.hasOwnProperty("sortDirection"))
                            object.sortDirection = message.sortDirection;
                        return object;
                    };
    
                    /**
                     * Converts this ListWorkersRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListWorkersRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListWorkersRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListWorkersRequest;
                })();
    
                v1.ListWorkersResponse = (function() {
    
                    /**
                     * Properties of a ListWorkersResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListWorkersResponse
                     * @property {Array.<palexy.streaming.v1.IWorker>|null} [workers] ListWorkersResponse workers
                     * @property {string|null} [nextPageToken] ListWorkersResponse nextPageToken
                     * @property {number|null} [totalElements] ListWorkersResponse totalElements
                     */
    
                    /**
                     * Constructs a new ListWorkersResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListWorkersResponse.
                     * @implements IListWorkersResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListWorkersResponse=} [properties] Properties to set
                     */
                    function ListWorkersResponse(properties) {
                        this.workers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListWorkersResponse workers.
                     * @member {Array.<palexy.streaming.v1.IWorker>} workers
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @instance
                     */
                    ListWorkersResponse.prototype.workers = $util.emptyArray;
    
                    /**
                     * ListWorkersResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @instance
                     */
                    ListWorkersResponse.prototype.nextPageToken = "";
    
                    /**
                     * ListWorkersResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @instance
                     */
                    ListWorkersResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListWorkersResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @static
                     * @param {palexy.streaming.v1.IListWorkersResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListWorkersResponse} ListWorkersResponse instance
                     */
                    ListWorkersResponse.create = function create(properties) {
                        return new ListWorkersResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListWorkersResponse message. Does not implicitly {@link palexy.streaming.v1.ListWorkersResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @static
                     * @param {palexy.streaming.v1.IListWorkersResponse} message ListWorkersResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListWorkersResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.workers != null && message.workers.length)
                            for (var i = 0; i < message.workers.length; ++i)
                                $root.palexy.streaming.v1.Worker.encode(message.workers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListWorkersResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListWorkersResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @static
                     * @param {palexy.streaming.v1.IListWorkersResponse} message ListWorkersResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListWorkersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListWorkersResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListWorkersResponse} ListWorkersResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListWorkersResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListWorkersResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.workers && message.workers.length))
                                    message.workers = [];
                                message.workers.push($root.palexy.streaming.v1.Worker.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            case 3:
                                message.totalElements = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListWorkersResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListWorkersResponse} ListWorkersResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListWorkersResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListWorkersResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListWorkersResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.workers != null && message.hasOwnProperty("workers")) {
                            if (!Array.isArray(message.workers))
                                return "workers: array expected";
                            for (var i = 0; i < message.workers.length; ++i) {
                                var error = $root.palexy.streaming.v1.Worker.verify(message.workers[i]);
                                if (error)
                                    return "workers." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListWorkersResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListWorkersResponse} ListWorkersResponse
                     */
                    ListWorkersResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListWorkersResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListWorkersResponse();
                        if (object.workers) {
                            if (!Array.isArray(object.workers))
                                throw TypeError(".palexy.streaming.v1.ListWorkersResponse.workers: array expected");
                            message.workers = [];
                            for (var i = 0; i < object.workers.length; ++i) {
                                if (typeof object.workers[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListWorkersResponse.workers: object expected");
                                message.workers[i] = $root.palexy.streaming.v1.Worker.fromObject(object.workers[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListWorkersResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @static
                     * @param {palexy.streaming.v1.ListWorkersResponse} message ListWorkersResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListWorkersResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.workers = [];
                        if (options.defaults) {
                            object.nextPageToken = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                        }
                        if (message.workers && message.workers.length) {
                            object.workers = [];
                            for (var j = 0; j < message.workers.length; ++j)
                                object.workers[j] = $root.palexy.streaming.v1.Worker.toObject(message.workers[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        return object;
                    };
    
                    /**
                     * Converts this ListWorkersResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListWorkersResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListWorkersResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListWorkersResponse;
                })();
    
                v1.Camera = (function() {
    
                    /**
                     * Properties of a Camera.
                     * @memberof palexy.streaming.v1
                     * @interface ICamera
                     * @property {string|null} [name] Camera name
                     * @property {string|null} [username] Camera username
                     * @property {string|null} [password] Camera password
                     * @property {string|null} [rtspUrl] Camera rtspUrl
                     * @property {string|null} [cameraId] Camera cameraId
                     * @property {number|null} [cameraDatabaseId] Camera cameraDatabaseId
                     * @property {string|null} [startTime] Camera startTime
                     * @property {string|null} [endTime] Camera endTime
                     * @property {string|null} [timezone] Camera timezone
                     * @property {palexy.streaming.v1.ITransformationMatrix|null} [transformationMatrix] Camera transformationMatrix
                     * @property {palexy.streaming.v1.IExcludedAreas|null} [excludedAreas] Camera excludedAreas
                     * @property {number|null} [areaId] Camera areaId
                     * @property {palexy.streaming.v1.IArea|null} [area] Camera area
                     * @property {palexy.streaming.v1.Camera.StreamingState|null} [streamingState] Camera streamingState
                     * @property {string|null} [streamingStateStatusMessage] Camera streamingStateStatusMessage
                     * @property {google.protobuf.ITimestamp|null} [streamingStateLastUpdateTime] Camera streamingStateLastUpdateTime
                     * @property {google.protobuf.ITimestamp|null} [lastAlertTime] Camera lastAlertTime
                     * @property {number|null} [numAlert] Camera numAlert
                     * @property {string|null} [streamingWorkerName] Camera streamingWorkerName
                     * @property {google.protobuf.ITimestamp|null} [streamingWorkerLastUpdateTime] Camera streamingWorkerLastUpdateTime
                     * @property {number|null} [storeId] Camera storeId
                     * @property {number|null} [cameraResolutionWidth] Camera cameraResolutionWidth
                     * @property {number|null} [cameraResolutionHeight] Camera cameraResolutionHeight
                     * @property {number|null} [versionId] Camera versionId
                     * @property {palexy.streaming.v1.Camera.ShiftStatus|null} [shiftStatus] Camera shiftStatus
                     * @property {google.protobuf.ITimestamp|null} [lastShiftCheckingTime] Camera lastShiftCheckingTime
                     * @property {palexy.streaming.v1.IStore|null} [store] Camera store
                     * @property {string|null} [streamingLocation] Camera streamingLocation
                     * @property {palexy.streaming.v1.ICompany|null} [company] Camera company
                     * @property {Array.<palexy.streaming.v1.Camera.Action>|null} [actions] Camera actions
                     * @property {palexy.streaming.v1.Camera.Orientation|null} [orientation] Camera orientation
                     * @property {Array.<palexy.streaming.v1.IPreprocessingOption>|null} [preprocessingOptions] Camera preprocessingOptions
                     * @property {palexy.streaming.v1.Camera.Type|null} [type] Camera type
                     * @property {Array.<palexy.streaming.v1.ICamera>|null} [subviews] Camera subviews
                     * @property {string|null} [fps] Camera fps
                     * @property {boolean|null} [isDisabled] Camera isDisabled
                     * @property {boolean|null} [useWallclockAsTimestamps] Camera useWallclockAsTimestamps
                     * @property {google.protobuf.ITimestamp|null} [firstSentLevel_2NotificationTime] Camera firstSentLevel_2NotificationTime
                     * @property {number|null} [parentCameraDatabaseId] Camera parentCameraDatabaseId
                     * @property {palexy.streaming.v1.ICustomStreamingTime|null} [customStreamingTime] Camera customStreamingTime
                     * @property {palexy.streaming.v1.IListWeekdayCustomStreamingTime|null} [listWeekdayCustomStreamingTime] Camera listWeekdayCustomStreamingTime
                     * @property {number|null} [dvrCameraId] Camera dvrCameraId
                     * @property {boolean|null} [isStreamingOnly] Camera isStreamingOnly
                     * @property {boolean|null} [isShifted] Camera isShifted
                     * @property {palexy.streaming.v1.Camera.RtspTransportProtocol|null} [rtspTransportProtocol] Camera rtspTransportProtocol
                     * @property {palexy.streaming.v1.Camera.DownloadStatus|null} [downloadStatus] Camera downloadStatus
                     * @property {palexy.streaming.v1.Camera.RetrieveVideoType|null} [retrieveVideoType] Camera retrieveVideoType
                     * @property {boolean|null} [useFfmpegSsFlag] Camera useFfmpegSsFlag
                     * @property {number|null} [referenceCameraId] Camera referenceCameraId
                     * @property {Array.<palexy.streaming.v1.ICamera>|null} [referencingCameras] Camera referencingCameras
                     * @property {boolean|null} [isEnableProcessing] Camera isEnableProcessing
                     */
    
                    /**
                     * Constructs a new Camera.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Camera.
                     * @implements ICamera
                     * @constructor
                     * @param {palexy.streaming.v1.ICamera=} [properties] Properties to set
                     */
                    function Camera(properties) {
                        this.actions = [];
                        this.preprocessingOptions = [];
                        this.subviews = [];
                        this.referencingCameras = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Camera name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.name = "";
    
                    /**
                     * Camera username.
                     * @member {string} username
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.username = "";
    
                    /**
                     * Camera password.
                     * @member {string} password
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.password = "";
    
                    /**
                     * Camera rtspUrl.
                     * @member {string} rtspUrl
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.rtspUrl = "";
    
                    /**
                     * Camera cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.cameraId = "";
    
                    /**
                     * Camera cameraDatabaseId.
                     * @member {number} cameraDatabaseId
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.cameraDatabaseId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Camera startTime.
                     * @member {string} startTime
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.startTime = "";
    
                    /**
                     * Camera endTime.
                     * @member {string} endTime
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.endTime = "";
    
                    /**
                     * Camera timezone.
                     * @member {string} timezone
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.timezone = "";
    
                    /**
                     * Camera transformationMatrix.
                     * @member {palexy.streaming.v1.ITransformationMatrix|null|undefined} transformationMatrix
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.transformationMatrix = null;
    
                    /**
                     * Camera excludedAreas.
                     * @member {palexy.streaming.v1.IExcludedAreas|null|undefined} excludedAreas
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.excludedAreas = null;
    
                    /**
                     * Camera areaId.
                     * @member {number} areaId
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.areaId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Camera area.
                     * @member {palexy.streaming.v1.IArea|null|undefined} area
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.area = null;
    
                    /**
                     * Camera streamingState.
                     * @member {palexy.streaming.v1.Camera.StreamingState} streamingState
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.streamingState = 0;
    
                    /**
                     * Camera streamingStateStatusMessage.
                     * @member {string} streamingStateStatusMessage
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.streamingStateStatusMessage = "";
    
                    /**
                     * Camera streamingStateLastUpdateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} streamingStateLastUpdateTime
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.streamingStateLastUpdateTime = null;
    
                    /**
                     * Camera lastAlertTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastAlertTime
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.lastAlertTime = null;
    
                    /**
                     * Camera numAlert.
                     * @member {number} numAlert
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.numAlert = 0;
    
                    /**
                     * Camera streamingWorkerName.
                     * @member {string} streamingWorkerName
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.streamingWorkerName = "";
    
                    /**
                     * Camera streamingWorkerLastUpdateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} streamingWorkerLastUpdateTime
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.streamingWorkerLastUpdateTime = null;
    
                    /**
                     * Camera storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Camera cameraResolutionWidth.
                     * @member {number} cameraResolutionWidth
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.cameraResolutionWidth = 0;
    
                    /**
                     * Camera cameraResolutionHeight.
                     * @member {number} cameraResolutionHeight
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.cameraResolutionHeight = 0;
    
                    /**
                     * Camera versionId.
                     * @member {number} versionId
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.versionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Camera shiftStatus.
                     * @member {palexy.streaming.v1.Camera.ShiftStatus} shiftStatus
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.shiftStatus = 0;
    
                    /**
                     * Camera lastShiftCheckingTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastShiftCheckingTime
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.lastShiftCheckingTime = null;
    
                    /**
                     * Camera store.
                     * @member {palexy.streaming.v1.IStore|null|undefined} store
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.store = null;
    
                    /**
                     * Camera streamingLocation.
                     * @member {string} streamingLocation
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.streamingLocation = "";
    
                    /**
                     * Camera company.
                     * @member {palexy.streaming.v1.ICompany|null|undefined} company
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.company = null;
    
                    /**
                     * Camera actions.
                     * @member {Array.<palexy.streaming.v1.Camera.Action>} actions
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.actions = $util.emptyArray;
    
                    /**
                     * Camera orientation.
                     * @member {palexy.streaming.v1.Camera.Orientation} orientation
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.orientation = 0;
    
                    /**
                     * Camera preprocessingOptions.
                     * @member {Array.<palexy.streaming.v1.IPreprocessingOption>} preprocessingOptions
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.preprocessingOptions = $util.emptyArray;
    
                    /**
                     * Camera type.
                     * @member {palexy.streaming.v1.Camera.Type} type
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.type = 0;
    
                    /**
                     * Camera subviews.
                     * @member {Array.<palexy.streaming.v1.ICamera>} subviews
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.subviews = $util.emptyArray;
    
                    /**
                     * Camera fps.
                     * @member {string} fps
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.fps = "";
    
                    /**
                     * Camera isDisabled.
                     * @member {boolean} isDisabled
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.isDisabled = false;
    
                    /**
                     * Camera useWallclockAsTimestamps.
                     * @member {boolean} useWallclockAsTimestamps
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.useWallclockAsTimestamps = false;
    
                    /**
                     * Camera firstSentLevel_2NotificationTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} firstSentLevel_2NotificationTime
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.firstSentLevel_2NotificationTime = null;
    
                    /**
                     * Camera parentCameraDatabaseId.
                     * @member {number} parentCameraDatabaseId
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.parentCameraDatabaseId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Camera customStreamingTime.
                     * @member {palexy.streaming.v1.ICustomStreamingTime|null|undefined} customStreamingTime
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.customStreamingTime = null;
    
                    /**
                     * Camera listWeekdayCustomStreamingTime.
                     * @member {palexy.streaming.v1.IListWeekdayCustomStreamingTime|null|undefined} listWeekdayCustomStreamingTime
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.listWeekdayCustomStreamingTime = null;
    
                    /**
                     * Camera dvrCameraId.
                     * @member {number} dvrCameraId
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.dvrCameraId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Camera isStreamingOnly.
                     * @member {boolean} isStreamingOnly
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.isStreamingOnly = false;
    
                    /**
                     * Camera isShifted.
                     * @member {boolean} isShifted
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.isShifted = false;
    
                    /**
                     * Camera rtspTransportProtocol.
                     * @member {palexy.streaming.v1.Camera.RtspTransportProtocol} rtspTransportProtocol
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.rtspTransportProtocol = 0;
    
                    /**
                     * Camera downloadStatus.
                     * @member {palexy.streaming.v1.Camera.DownloadStatus} downloadStatus
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.downloadStatus = 0;
    
                    /**
                     * Camera retrieveVideoType.
                     * @member {palexy.streaming.v1.Camera.RetrieveVideoType} retrieveVideoType
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.retrieveVideoType = 0;
    
                    /**
                     * Camera useFfmpegSsFlag.
                     * @member {boolean} useFfmpegSsFlag
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.useFfmpegSsFlag = false;
    
                    /**
                     * Camera referenceCameraId.
                     * @member {number} referenceCameraId
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.referenceCameraId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Camera referencingCameras.
                     * @member {Array.<palexy.streaming.v1.ICamera>} referencingCameras
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.referencingCameras = $util.emptyArray;
    
                    /**
                     * Camera isEnableProcessing.
                     * @member {boolean} isEnableProcessing
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     */
                    Camera.prototype.isEnableProcessing = false;
    
                    /**
                     * Creates a new Camera instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Camera
                     * @static
                     * @param {palexy.streaming.v1.ICamera=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Camera} Camera instance
                     */
                    Camera.create = function create(properties) {
                        return new Camera(properties);
                    };
    
                    /**
                     * Encodes the specified Camera message. Does not implicitly {@link palexy.streaming.v1.Camera.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Camera
                     * @static
                     * @param {palexy.streaming.v1.ICamera} message Camera message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Camera.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.username);
                        if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.password);
                        if (message.rtspUrl != null && Object.hasOwnProperty.call(message, "rtspUrl"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.rtspUrl);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.cameraId);
                        if (message.cameraDatabaseId != null && Object.hasOwnProperty.call(message, "cameraDatabaseId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.cameraDatabaseId);
                        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.startTime);
                        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.endTime);
                        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.timezone);
                        if (message.transformationMatrix != null && Object.hasOwnProperty.call(message, "transformationMatrix"))
                            $root.palexy.streaming.v1.TransformationMatrix.encode(message.transformationMatrix, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.excludedAreas != null && Object.hasOwnProperty.call(message, "excludedAreas"))
                            $root.palexy.streaming.v1.ExcludedAreas.encode(message.excludedAreas, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.areaId != null && Object.hasOwnProperty.call(message, "areaId"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int64(message.areaId);
                        if (message.area != null && Object.hasOwnProperty.call(message, "area"))
                            $root.palexy.streaming.v1.Area.encode(message.area, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.streamingState != null && Object.hasOwnProperty.call(message, "streamingState"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.streamingState);
                        if (message.streamingStateStatusMessage != null && Object.hasOwnProperty.call(message, "streamingStateStatusMessage"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.streamingStateStatusMessage);
                        if (message.streamingStateLastUpdateTime != null && Object.hasOwnProperty.call(message, "streamingStateLastUpdateTime"))
                            $root.google.protobuf.Timestamp.encode(message.streamingStateLastUpdateTime, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.lastAlertTime != null && Object.hasOwnProperty.call(message, "lastAlertTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastAlertTime, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        if (message.numAlert != null && Object.hasOwnProperty.call(message, "numAlert"))
                            writer.uint32(/* id 18, wireType 0 =*/144).int32(message.numAlert);
                        if (message.streamingWorkerName != null && Object.hasOwnProperty.call(message, "streamingWorkerName"))
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.streamingWorkerName);
                        if (message.streamingWorkerLastUpdateTime != null && Object.hasOwnProperty.call(message, "streamingWorkerLastUpdateTime"))
                            $root.google.protobuf.Timestamp.encode(message.streamingWorkerLastUpdateTime, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 21, wireType 0 =*/168).int64(message.storeId);
                        if (message.cameraResolutionWidth != null && Object.hasOwnProperty.call(message, "cameraResolutionWidth"))
                            writer.uint32(/* id 22, wireType 0 =*/176).int32(message.cameraResolutionWidth);
                        if (message.cameraResolutionHeight != null && Object.hasOwnProperty.call(message, "cameraResolutionHeight"))
                            writer.uint32(/* id 23, wireType 0 =*/184).int32(message.cameraResolutionHeight);
                        if (message.versionId != null && Object.hasOwnProperty.call(message, "versionId"))
                            writer.uint32(/* id 24, wireType 0 =*/192).int64(message.versionId);
                        if (message.shiftStatus != null && Object.hasOwnProperty.call(message, "shiftStatus"))
                            writer.uint32(/* id 25, wireType 0 =*/200).int32(message.shiftStatus);
                        if (message.lastShiftCheckingTime != null && Object.hasOwnProperty.call(message, "lastShiftCheckingTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastShiftCheckingTime, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                        if (message.store != null && Object.hasOwnProperty.call(message, "store"))
                            $root.palexy.streaming.v1.Store.encode(message.store, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                        if (message.streamingLocation != null && Object.hasOwnProperty.call(message, "streamingLocation"))
                            writer.uint32(/* id 28, wireType 2 =*/226).string(message.streamingLocation);
                        if (message.company != null && Object.hasOwnProperty.call(message, "company"))
                            $root.palexy.streaming.v1.Company.encode(message.company, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                        if (message.actions != null && message.actions.length) {
                            writer.uint32(/* id 30, wireType 2 =*/242).fork();
                            for (var i = 0; i < message.actions.length; ++i)
                                writer.int32(message.actions[i]);
                            writer.ldelim();
                        }
                        if (message.orientation != null && Object.hasOwnProperty.call(message, "orientation"))
                            writer.uint32(/* id 31, wireType 0 =*/248).int32(message.orientation);
                        if (message.preprocessingOptions != null && message.preprocessingOptions.length)
                            for (var i = 0; i < message.preprocessingOptions.length; ++i)
                                $root.palexy.streaming.v1.PreprocessingOption.encode(message.preprocessingOptions[i], writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 33, wireType 0 =*/264).int32(message.type);
                        if (message.subviews != null && message.subviews.length)
                            for (var i = 0; i < message.subviews.length; ++i)
                                $root.palexy.streaming.v1.Camera.encode(message.subviews[i], writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                        if (message.fps != null && Object.hasOwnProperty.call(message, "fps"))
                            writer.uint32(/* id 35, wireType 2 =*/282).string(message.fps);
                        if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                            writer.uint32(/* id 36, wireType 0 =*/288).bool(message.isDisabled);
                        if (message.useWallclockAsTimestamps != null && Object.hasOwnProperty.call(message, "useWallclockAsTimestamps"))
                            writer.uint32(/* id 37, wireType 0 =*/296).bool(message.useWallclockAsTimestamps);
                        if (message.firstSentLevel_2NotificationTime != null && Object.hasOwnProperty.call(message, "firstSentLevel_2NotificationTime"))
                            $root.google.protobuf.Timestamp.encode(message.firstSentLevel_2NotificationTime, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
                        if (message.parentCameraDatabaseId != null && Object.hasOwnProperty.call(message, "parentCameraDatabaseId"))
                            writer.uint32(/* id 39, wireType 0 =*/312).int64(message.parentCameraDatabaseId);
                        if (message.customStreamingTime != null && Object.hasOwnProperty.call(message, "customStreamingTime"))
                            $root.palexy.streaming.v1.CustomStreamingTime.encode(message.customStreamingTime, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                        if (message.listWeekdayCustomStreamingTime != null && Object.hasOwnProperty.call(message, "listWeekdayCustomStreamingTime"))
                            $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime.encode(message.listWeekdayCustomStreamingTime, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
                        if (message.dvrCameraId != null && Object.hasOwnProperty.call(message, "dvrCameraId"))
                            writer.uint32(/* id 42, wireType 0 =*/336).int64(message.dvrCameraId);
                        if (message.isStreamingOnly != null && Object.hasOwnProperty.call(message, "isStreamingOnly"))
                            writer.uint32(/* id 43, wireType 0 =*/344).bool(message.isStreamingOnly);
                        if (message.isShifted != null && Object.hasOwnProperty.call(message, "isShifted"))
                            writer.uint32(/* id 44, wireType 0 =*/352).bool(message.isShifted);
                        if (message.rtspTransportProtocol != null && Object.hasOwnProperty.call(message, "rtspTransportProtocol"))
                            writer.uint32(/* id 45, wireType 0 =*/360).int32(message.rtspTransportProtocol);
                        if (message.downloadStatus != null && Object.hasOwnProperty.call(message, "downloadStatus"))
                            writer.uint32(/* id 46, wireType 0 =*/368).int32(message.downloadStatus);
                        if (message.retrieveVideoType != null && Object.hasOwnProperty.call(message, "retrieveVideoType"))
                            writer.uint32(/* id 47, wireType 0 =*/376).int32(message.retrieveVideoType);
                        if (message.useFfmpegSsFlag != null && Object.hasOwnProperty.call(message, "useFfmpegSsFlag"))
                            writer.uint32(/* id 48, wireType 0 =*/384).bool(message.useFfmpegSsFlag);
                        if (message.referenceCameraId != null && Object.hasOwnProperty.call(message, "referenceCameraId"))
                            writer.uint32(/* id 49, wireType 0 =*/392).int64(message.referenceCameraId);
                        if (message.referencingCameras != null && message.referencingCameras.length)
                            for (var i = 0; i < message.referencingCameras.length; ++i)
                                $root.palexy.streaming.v1.Camera.encode(message.referencingCameras[i], writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                        if (message.isEnableProcessing != null && Object.hasOwnProperty.call(message, "isEnableProcessing"))
                            writer.uint32(/* id 51, wireType 0 =*/408).bool(message.isEnableProcessing);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Camera message, length delimited. Does not implicitly {@link palexy.streaming.v1.Camera.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Camera
                     * @static
                     * @param {palexy.streaming.v1.ICamera} message Camera message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Camera.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Camera message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Camera
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Camera} Camera
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Camera.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Camera();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.username = reader.string();
                                break;
                            case 3:
                                message.password = reader.string();
                                break;
                            case 4:
                                message.rtspUrl = reader.string();
                                break;
                            case 5:
                                message.cameraId = reader.string();
                                break;
                            case 6:
                                message.cameraDatabaseId = reader.int64();
                                break;
                            case 7:
                                message.startTime = reader.string();
                                break;
                            case 8:
                                message.endTime = reader.string();
                                break;
                            case 9:
                                message.timezone = reader.string();
                                break;
                            case 10:
                                message.transformationMatrix = $root.palexy.streaming.v1.TransformationMatrix.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.excludedAreas = $root.palexy.streaming.v1.ExcludedAreas.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.areaId = reader.int64();
                                break;
                            case 13:
                                message.area = $root.palexy.streaming.v1.Area.decode(reader, reader.uint32());
                                break;
                            case 14:
                                message.streamingState = reader.int32();
                                break;
                            case 15:
                                message.streamingStateStatusMessage = reader.string();
                                break;
                            case 16:
                                message.streamingStateLastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 17:
                                message.lastAlertTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 18:
                                message.numAlert = reader.int32();
                                break;
                            case 19:
                                message.streamingWorkerName = reader.string();
                                break;
                            case 20:
                                message.streamingWorkerLastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 21:
                                message.storeId = reader.int64();
                                break;
                            case 22:
                                message.cameraResolutionWidth = reader.int32();
                                break;
                            case 23:
                                message.cameraResolutionHeight = reader.int32();
                                break;
                            case 24:
                                message.versionId = reader.int64();
                                break;
                            case 25:
                                message.shiftStatus = reader.int32();
                                break;
                            case 26:
                                message.lastShiftCheckingTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 27:
                                message.store = $root.palexy.streaming.v1.Store.decode(reader, reader.uint32());
                                break;
                            case 28:
                                message.streamingLocation = reader.string();
                                break;
                            case 29:
                                message.company = $root.palexy.streaming.v1.Company.decode(reader, reader.uint32());
                                break;
                            case 30:
                                if (!(message.actions && message.actions.length))
                                    message.actions = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.actions.push(reader.int32());
                                } else
                                    message.actions.push(reader.int32());
                                break;
                            case 31:
                                message.orientation = reader.int32();
                                break;
                            case 32:
                                if (!(message.preprocessingOptions && message.preprocessingOptions.length))
                                    message.preprocessingOptions = [];
                                message.preprocessingOptions.push($root.palexy.streaming.v1.PreprocessingOption.decode(reader, reader.uint32()));
                                break;
                            case 33:
                                message.type = reader.int32();
                                break;
                            case 34:
                                if (!(message.subviews && message.subviews.length))
                                    message.subviews = [];
                                message.subviews.push($root.palexy.streaming.v1.Camera.decode(reader, reader.uint32()));
                                break;
                            case 35:
                                message.fps = reader.string();
                                break;
                            case 36:
                                message.isDisabled = reader.bool();
                                break;
                            case 37:
                                message.useWallclockAsTimestamps = reader.bool();
                                break;
                            case 38:
                                message.firstSentLevel_2NotificationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 39:
                                message.parentCameraDatabaseId = reader.int64();
                                break;
                            case 40:
                                message.customStreamingTime = $root.palexy.streaming.v1.CustomStreamingTime.decode(reader, reader.uint32());
                                break;
                            case 41:
                                message.listWeekdayCustomStreamingTime = $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime.decode(reader, reader.uint32());
                                break;
                            case 42:
                                message.dvrCameraId = reader.int64();
                                break;
                            case 43:
                                message.isStreamingOnly = reader.bool();
                                break;
                            case 44:
                                message.isShifted = reader.bool();
                                break;
                            case 45:
                                message.rtspTransportProtocol = reader.int32();
                                break;
                            case 46:
                                message.downloadStatus = reader.int32();
                                break;
                            case 47:
                                message.retrieveVideoType = reader.int32();
                                break;
                            case 48:
                                message.useFfmpegSsFlag = reader.bool();
                                break;
                            case 49:
                                message.referenceCameraId = reader.int64();
                                break;
                            case 50:
                                if (!(message.referencingCameras && message.referencingCameras.length))
                                    message.referencingCameras = [];
                                message.referencingCameras.push($root.palexy.streaming.v1.Camera.decode(reader, reader.uint32()));
                                break;
                            case 51:
                                message.isEnableProcessing = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Camera message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Camera
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Camera} Camera
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Camera.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Camera message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Camera
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Camera.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.username != null && message.hasOwnProperty("username"))
                            if (!$util.isString(message.username))
                                return "username: string expected";
                        if (message.password != null && message.hasOwnProperty("password"))
                            if (!$util.isString(message.password))
                                return "password: string expected";
                        if (message.rtspUrl != null && message.hasOwnProperty("rtspUrl"))
                            if (!$util.isString(message.rtspUrl))
                                return "rtspUrl: string expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.cameraDatabaseId != null && message.hasOwnProperty("cameraDatabaseId"))
                            if (!$util.isInteger(message.cameraDatabaseId) && !(message.cameraDatabaseId && $util.isInteger(message.cameraDatabaseId.low) && $util.isInteger(message.cameraDatabaseId.high)))
                                return "cameraDatabaseId: integer|Long expected";
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            if (!$util.isString(message.startTime))
                                return "startTime: string expected";
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            if (!$util.isString(message.endTime))
                                return "endTime: string expected";
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            if (!$util.isString(message.timezone))
                                return "timezone: string expected";
                        if (message.transformationMatrix != null && message.hasOwnProperty("transformationMatrix")) {
                            var error = $root.palexy.streaming.v1.TransformationMatrix.verify(message.transformationMatrix);
                            if (error)
                                return "transformationMatrix." + error;
                        }
                        if (message.excludedAreas != null && message.hasOwnProperty("excludedAreas")) {
                            var error = $root.palexy.streaming.v1.ExcludedAreas.verify(message.excludedAreas);
                            if (error)
                                return "excludedAreas." + error;
                        }
                        if (message.areaId != null && message.hasOwnProperty("areaId"))
                            if (!$util.isInteger(message.areaId) && !(message.areaId && $util.isInteger(message.areaId.low) && $util.isInteger(message.areaId.high)))
                                return "areaId: integer|Long expected";
                        if (message.area != null && message.hasOwnProperty("area")) {
                            var error = $root.palexy.streaming.v1.Area.verify(message.area);
                            if (error)
                                return "area." + error;
                        }
                        if (message.streamingState != null && message.hasOwnProperty("streamingState"))
                            switch (message.streamingState) {
                            default:
                                return "streamingState: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.streamingStateStatusMessage != null && message.hasOwnProperty("streamingStateStatusMessage"))
                            if (!$util.isString(message.streamingStateStatusMessage))
                                return "streamingStateStatusMessage: string expected";
                        if (message.streamingStateLastUpdateTime != null && message.hasOwnProperty("streamingStateLastUpdateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.streamingStateLastUpdateTime);
                            if (error)
                                return "streamingStateLastUpdateTime." + error;
                        }
                        if (message.lastAlertTime != null && message.hasOwnProperty("lastAlertTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastAlertTime);
                            if (error)
                                return "lastAlertTime." + error;
                        }
                        if (message.numAlert != null && message.hasOwnProperty("numAlert"))
                            if (!$util.isInteger(message.numAlert))
                                return "numAlert: integer expected";
                        if (message.streamingWorkerName != null && message.hasOwnProperty("streamingWorkerName"))
                            if (!$util.isString(message.streamingWorkerName))
                                return "streamingWorkerName: string expected";
                        if (message.streamingWorkerLastUpdateTime != null && message.hasOwnProperty("streamingWorkerLastUpdateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.streamingWorkerLastUpdateTime);
                            if (error)
                                return "streamingWorkerLastUpdateTime." + error;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.cameraResolutionWidth != null && message.hasOwnProperty("cameraResolutionWidth"))
                            if (!$util.isInteger(message.cameraResolutionWidth))
                                return "cameraResolutionWidth: integer expected";
                        if (message.cameraResolutionHeight != null && message.hasOwnProperty("cameraResolutionHeight"))
                            if (!$util.isInteger(message.cameraResolutionHeight))
                                return "cameraResolutionHeight: integer expected";
                        if (message.versionId != null && message.hasOwnProperty("versionId"))
                            if (!$util.isInteger(message.versionId) && !(message.versionId && $util.isInteger(message.versionId.low) && $util.isInteger(message.versionId.high)))
                                return "versionId: integer|Long expected";
                        if (message.shiftStatus != null && message.hasOwnProperty("shiftStatus"))
                            switch (message.shiftStatus) {
                            default:
                                return "shiftStatus: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.lastShiftCheckingTime != null && message.hasOwnProperty("lastShiftCheckingTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastShiftCheckingTime);
                            if (error)
                                return "lastShiftCheckingTime." + error;
                        }
                        if (message.store != null && message.hasOwnProperty("store")) {
                            var error = $root.palexy.streaming.v1.Store.verify(message.store);
                            if (error)
                                return "store." + error;
                        }
                        if (message.streamingLocation != null && message.hasOwnProperty("streamingLocation"))
                            if (!$util.isString(message.streamingLocation))
                                return "streamingLocation: string expected";
                        if (message.company != null && message.hasOwnProperty("company")) {
                            var error = $root.palexy.streaming.v1.Company.verify(message.company);
                            if (error)
                                return "company." + error;
                        }
                        if (message.actions != null && message.hasOwnProperty("actions")) {
                            if (!Array.isArray(message.actions))
                                return "actions: array expected";
                            for (var i = 0; i < message.actions.length; ++i)
                                switch (message.actions[i]) {
                                default:
                                    return "actions: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                    break;
                                }
                        }
                        if (message.orientation != null && message.hasOwnProperty("orientation"))
                            switch (message.orientation) {
                            default:
                                return "orientation: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.preprocessingOptions != null && message.hasOwnProperty("preprocessingOptions")) {
                            if (!Array.isArray(message.preprocessingOptions))
                                return "preprocessingOptions: array expected";
                            for (var i = 0; i < message.preprocessingOptions.length; ++i) {
                                var error = $root.palexy.streaming.v1.PreprocessingOption.verify(message.preprocessingOptions[i]);
                                if (error)
                                    return "preprocessingOptions." + error;
                            }
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.subviews != null && message.hasOwnProperty("subviews")) {
                            if (!Array.isArray(message.subviews))
                                return "subviews: array expected";
                            for (var i = 0; i < message.subviews.length; ++i) {
                                var error = $root.palexy.streaming.v1.Camera.verify(message.subviews[i]);
                                if (error)
                                    return "subviews." + error;
                            }
                        }
                        if (message.fps != null && message.hasOwnProperty("fps"))
                            if (!$util.isString(message.fps))
                                return "fps: string expected";
                        if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                            if (typeof message.isDisabled !== "boolean")
                                return "isDisabled: boolean expected";
                        if (message.useWallclockAsTimestamps != null && message.hasOwnProperty("useWallclockAsTimestamps"))
                            if (typeof message.useWallclockAsTimestamps !== "boolean")
                                return "useWallclockAsTimestamps: boolean expected";
                        if (message.firstSentLevel_2NotificationTime != null && message.hasOwnProperty("firstSentLevel_2NotificationTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.firstSentLevel_2NotificationTime);
                            if (error)
                                return "firstSentLevel_2NotificationTime." + error;
                        }
                        if (message.parentCameraDatabaseId != null && message.hasOwnProperty("parentCameraDatabaseId"))
                            if (!$util.isInteger(message.parentCameraDatabaseId) && !(message.parentCameraDatabaseId && $util.isInteger(message.parentCameraDatabaseId.low) && $util.isInteger(message.parentCameraDatabaseId.high)))
                                return "parentCameraDatabaseId: integer|Long expected";
                        if (message.customStreamingTime != null && message.hasOwnProperty("customStreamingTime")) {
                            var error = $root.palexy.streaming.v1.CustomStreamingTime.verify(message.customStreamingTime);
                            if (error)
                                return "customStreamingTime." + error;
                        }
                        if (message.listWeekdayCustomStreamingTime != null && message.hasOwnProperty("listWeekdayCustomStreamingTime")) {
                            var error = $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime.verify(message.listWeekdayCustomStreamingTime);
                            if (error)
                                return "listWeekdayCustomStreamingTime." + error;
                        }
                        if (message.dvrCameraId != null && message.hasOwnProperty("dvrCameraId"))
                            if (!$util.isInteger(message.dvrCameraId) && !(message.dvrCameraId && $util.isInteger(message.dvrCameraId.low) && $util.isInteger(message.dvrCameraId.high)))
                                return "dvrCameraId: integer|Long expected";
                        if (message.isStreamingOnly != null && message.hasOwnProperty("isStreamingOnly"))
                            if (typeof message.isStreamingOnly !== "boolean")
                                return "isStreamingOnly: boolean expected";
                        if (message.isShifted != null && message.hasOwnProperty("isShifted"))
                            if (typeof message.isShifted !== "boolean")
                                return "isShifted: boolean expected";
                        if (message.rtspTransportProtocol != null && message.hasOwnProperty("rtspTransportProtocol"))
                            switch (message.rtspTransportProtocol) {
                            default:
                                return "rtspTransportProtocol: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.downloadStatus != null && message.hasOwnProperty("downloadStatus"))
                            switch (message.downloadStatus) {
                            default:
                                return "downloadStatus: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.retrieveVideoType != null && message.hasOwnProperty("retrieveVideoType"))
                            switch (message.retrieveVideoType) {
                            default:
                                return "retrieveVideoType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.useFfmpegSsFlag != null && message.hasOwnProperty("useFfmpegSsFlag"))
                            if (typeof message.useFfmpegSsFlag !== "boolean")
                                return "useFfmpegSsFlag: boolean expected";
                        if (message.referenceCameraId != null && message.hasOwnProperty("referenceCameraId"))
                            if (!$util.isInteger(message.referenceCameraId) && !(message.referenceCameraId && $util.isInteger(message.referenceCameraId.low) && $util.isInteger(message.referenceCameraId.high)))
                                return "referenceCameraId: integer|Long expected";
                        if (message.referencingCameras != null && message.hasOwnProperty("referencingCameras")) {
                            if (!Array.isArray(message.referencingCameras))
                                return "referencingCameras: array expected";
                            for (var i = 0; i < message.referencingCameras.length; ++i) {
                                var error = $root.palexy.streaming.v1.Camera.verify(message.referencingCameras[i]);
                                if (error)
                                    return "referencingCameras." + error;
                            }
                        }
                        if (message.isEnableProcessing != null && message.hasOwnProperty("isEnableProcessing"))
                            if (typeof message.isEnableProcessing !== "boolean")
                                return "isEnableProcessing: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a Camera message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Camera
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Camera} Camera
                     */
                    Camera.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Camera)
                            return object;
                        var message = new $root.palexy.streaming.v1.Camera();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.username != null)
                            message.username = String(object.username);
                        if (object.password != null)
                            message.password = String(object.password);
                        if (object.rtspUrl != null)
                            message.rtspUrl = String(object.rtspUrl);
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.cameraDatabaseId != null)
                            if ($util.Long)
                                (message.cameraDatabaseId = $util.Long.fromValue(object.cameraDatabaseId)).unsigned = false;
                            else if (typeof object.cameraDatabaseId === "string")
                                message.cameraDatabaseId = parseInt(object.cameraDatabaseId, 10);
                            else if (typeof object.cameraDatabaseId === "number")
                                message.cameraDatabaseId = object.cameraDatabaseId;
                            else if (typeof object.cameraDatabaseId === "object")
                                message.cameraDatabaseId = new $util.LongBits(object.cameraDatabaseId.low >>> 0, object.cameraDatabaseId.high >>> 0).toNumber();
                        if (object.startTime != null)
                            message.startTime = String(object.startTime);
                        if (object.endTime != null)
                            message.endTime = String(object.endTime);
                        if (object.timezone != null)
                            message.timezone = String(object.timezone);
                        if (object.transformationMatrix != null) {
                            if (typeof object.transformationMatrix !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.transformationMatrix: object expected");
                            message.transformationMatrix = $root.palexy.streaming.v1.TransformationMatrix.fromObject(object.transformationMatrix);
                        }
                        if (object.excludedAreas != null) {
                            if (typeof object.excludedAreas !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.excludedAreas: object expected");
                            message.excludedAreas = $root.palexy.streaming.v1.ExcludedAreas.fromObject(object.excludedAreas);
                        }
                        if (object.areaId != null)
                            if ($util.Long)
                                (message.areaId = $util.Long.fromValue(object.areaId)).unsigned = false;
                            else if (typeof object.areaId === "string")
                                message.areaId = parseInt(object.areaId, 10);
                            else if (typeof object.areaId === "number")
                                message.areaId = object.areaId;
                            else if (typeof object.areaId === "object")
                                message.areaId = new $util.LongBits(object.areaId.low >>> 0, object.areaId.high >>> 0).toNumber();
                        if (object.area != null) {
                            if (typeof object.area !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.area: object expected");
                            message.area = $root.palexy.streaming.v1.Area.fromObject(object.area);
                        }
                        switch (object.streamingState) {
                        case "UNKNOWN":
                        case 0:
                            message.streamingState = 0;
                            break;
                        case "NORMAL":
                        case 1:
                            message.streamingState = 1;
                            break;
                        case "BROKEN":
                        case 2:
                            message.streamingState = 2;
                            break;
                        }
                        if (object.streamingStateStatusMessage != null)
                            message.streamingStateStatusMessage = String(object.streamingStateStatusMessage);
                        if (object.streamingStateLastUpdateTime != null) {
                            if (typeof object.streamingStateLastUpdateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.streamingStateLastUpdateTime: object expected");
                            message.streamingStateLastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.streamingStateLastUpdateTime);
                        }
                        if (object.lastAlertTime != null) {
                            if (typeof object.lastAlertTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.lastAlertTime: object expected");
                            message.lastAlertTime = $root.google.protobuf.Timestamp.fromObject(object.lastAlertTime);
                        }
                        if (object.numAlert != null)
                            message.numAlert = object.numAlert | 0;
                        if (object.streamingWorkerName != null)
                            message.streamingWorkerName = String(object.streamingWorkerName);
                        if (object.streamingWorkerLastUpdateTime != null) {
                            if (typeof object.streamingWorkerLastUpdateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.streamingWorkerLastUpdateTime: object expected");
                            message.streamingWorkerLastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.streamingWorkerLastUpdateTime);
                        }
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.cameraResolutionWidth != null)
                            message.cameraResolutionWidth = object.cameraResolutionWidth | 0;
                        if (object.cameraResolutionHeight != null)
                            message.cameraResolutionHeight = object.cameraResolutionHeight | 0;
                        if (object.versionId != null)
                            if ($util.Long)
                                (message.versionId = $util.Long.fromValue(object.versionId)).unsigned = false;
                            else if (typeof object.versionId === "string")
                                message.versionId = parseInt(object.versionId, 10);
                            else if (typeof object.versionId === "number")
                                message.versionId = object.versionId;
                            else if (typeof object.versionId === "object")
                                message.versionId = new $util.LongBits(object.versionId.low >>> 0, object.versionId.high >>> 0).toNumber();
                        switch (object.shiftStatus) {
                        case "SHIFT_UNKNOWN":
                        case 0:
                            message.shiftStatus = 0;
                            break;
                        case "SHIFTED":
                        case 1:
                            message.shiftStatus = 1;
                            break;
                        case "NOT_SHIFTED":
                        case 2:
                            message.shiftStatus = 2;
                            break;
                        }
                        if (object.lastShiftCheckingTime != null) {
                            if (typeof object.lastShiftCheckingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.lastShiftCheckingTime: object expected");
                            message.lastShiftCheckingTime = $root.google.protobuf.Timestamp.fromObject(object.lastShiftCheckingTime);
                        }
                        if (object.store != null) {
                            if (typeof object.store !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.store: object expected");
                            message.store = $root.palexy.streaming.v1.Store.fromObject(object.store);
                        }
                        if (object.streamingLocation != null)
                            message.streamingLocation = String(object.streamingLocation);
                        if (object.company != null) {
                            if (typeof object.company !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.company: object expected");
                            message.company = $root.palexy.streaming.v1.Company.fromObject(object.company);
                        }
                        if (object.actions) {
                            if (!Array.isArray(object.actions))
                                throw TypeError(".palexy.streaming.v1.Camera.actions: array expected");
                            message.actions = [];
                            for (var i = 0; i < object.actions.length; ++i)
                                switch (object.actions[i]) {
                                default:
                                case "ACTION_UNKNOWN":
                                case 0:
                                    message.actions[i] = 0;
                                    break;
                                case "ENTRANCE":
                                case 1:
                                    message.actions[i] = 1;
                                    break;
                                case "QUEUE_TIME":
                                case 2:
                                    message.actions[i] = 2;
                                    break;
                                case "SECTION":
                                case 3:
                                    message.actions[i] = 3;
                                    break;
                                case "PASS_BY":
                                case 4:
                                    message.actions[i] = 4;
                                    break;
                                case "DEFAULT":
                                case 5:
                                    message.actions[i] = 5;
                                    break;
                                case "WIDE_SPACE_ENTRANCE":
                                case 6:
                                    message.actions[i] = 6;
                                    break;
                                }
                        }
                        switch (object.orientation) {
                        case "ORIENTATION_UNKNOWN":
                        case 0:
                            message.orientation = 0;
                            break;
                        case "ROTATE_0":
                        case 1:
                            message.orientation = 1;
                            break;
                        case "ROTATE_90_CLOCKWISE":
                        case 2:
                            message.orientation = 2;
                            break;
                        case "ROTATE_90_COUNTERCLOCKWISE":
                        case 3:
                            message.orientation = 3;
                            break;
                        case "ROTATE_180":
                        case 4:
                            message.orientation = 4;
                            break;
                        }
                        if (object.preprocessingOptions) {
                            if (!Array.isArray(object.preprocessingOptions))
                                throw TypeError(".palexy.streaming.v1.Camera.preprocessingOptions: array expected");
                            message.preprocessingOptions = [];
                            for (var i = 0; i < object.preprocessingOptions.length; ++i) {
                                if (typeof object.preprocessingOptions[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.Camera.preprocessingOptions: object expected");
                                message.preprocessingOptions[i] = $root.palexy.streaming.v1.PreprocessingOption.fromObject(object.preprocessingOptions[i]);
                            }
                        }
                        switch (object.type) {
                        case "BASIC":
                        case 0:
                            message.type = 0;
                            break;
                        case "FISHEYE":
                        case 1:
                            message.type = 1;
                            break;
                        case "FISHEYE_SUBVIEW":
                        case 2:
                            message.type = 2;
                            break;
                        }
                        if (object.subviews) {
                            if (!Array.isArray(object.subviews))
                                throw TypeError(".palexy.streaming.v1.Camera.subviews: array expected");
                            message.subviews = [];
                            for (var i = 0; i < object.subviews.length; ++i) {
                                if (typeof object.subviews[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.Camera.subviews: object expected");
                                message.subviews[i] = $root.palexy.streaming.v1.Camera.fromObject(object.subviews[i]);
                            }
                        }
                        if (object.fps != null)
                            message.fps = String(object.fps);
                        if (object.isDisabled != null)
                            message.isDisabled = Boolean(object.isDisabled);
                        if (object.useWallclockAsTimestamps != null)
                            message.useWallclockAsTimestamps = Boolean(object.useWallclockAsTimestamps);
                        if (object.firstSentLevel_2NotificationTime != null) {
                            if (typeof object.firstSentLevel_2NotificationTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.firstSentLevel_2NotificationTime: object expected");
                            message.firstSentLevel_2NotificationTime = $root.google.protobuf.Timestamp.fromObject(object.firstSentLevel_2NotificationTime);
                        }
                        if (object.parentCameraDatabaseId != null)
                            if ($util.Long)
                                (message.parentCameraDatabaseId = $util.Long.fromValue(object.parentCameraDatabaseId)).unsigned = false;
                            else if (typeof object.parentCameraDatabaseId === "string")
                                message.parentCameraDatabaseId = parseInt(object.parentCameraDatabaseId, 10);
                            else if (typeof object.parentCameraDatabaseId === "number")
                                message.parentCameraDatabaseId = object.parentCameraDatabaseId;
                            else if (typeof object.parentCameraDatabaseId === "object")
                                message.parentCameraDatabaseId = new $util.LongBits(object.parentCameraDatabaseId.low >>> 0, object.parentCameraDatabaseId.high >>> 0).toNumber();
                        if (object.customStreamingTime != null) {
                            if (typeof object.customStreamingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.customStreamingTime: object expected");
                            message.customStreamingTime = $root.palexy.streaming.v1.CustomStreamingTime.fromObject(object.customStreamingTime);
                        }
                        if (object.listWeekdayCustomStreamingTime != null) {
                            if (typeof object.listWeekdayCustomStreamingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Camera.listWeekdayCustomStreamingTime: object expected");
                            message.listWeekdayCustomStreamingTime = $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime.fromObject(object.listWeekdayCustomStreamingTime);
                        }
                        if (object.dvrCameraId != null)
                            if ($util.Long)
                                (message.dvrCameraId = $util.Long.fromValue(object.dvrCameraId)).unsigned = false;
                            else if (typeof object.dvrCameraId === "string")
                                message.dvrCameraId = parseInt(object.dvrCameraId, 10);
                            else if (typeof object.dvrCameraId === "number")
                                message.dvrCameraId = object.dvrCameraId;
                            else if (typeof object.dvrCameraId === "object")
                                message.dvrCameraId = new $util.LongBits(object.dvrCameraId.low >>> 0, object.dvrCameraId.high >>> 0).toNumber();
                        if (object.isStreamingOnly != null)
                            message.isStreamingOnly = Boolean(object.isStreamingOnly);
                        if (object.isShifted != null)
                            message.isShifted = Boolean(object.isShifted);
                        switch (object.rtspTransportProtocol) {
                        case "PROTOCOL_UNKNOWN":
                        case 0:
                            message.rtspTransportProtocol = 0;
                            break;
                        case "TCP":
                        case 1:
                            message.rtspTransportProtocol = 1;
                            break;
                        case "UDP":
                        case 2:
                            message.rtspTransportProtocol = 2;
                            break;
                        }
                        switch (object.downloadStatus) {
                        case "DOWNLOAD_STATUS_UNKNOWN":
                        case 0:
                            message.downloadStatus = 0;
                            break;
                        case "DOWNLOAD_NORMAL":
                        case 1:
                            message.downloadStatus = 1;
                            break;
                        case "DOWNLOAD_BROKEN":
                        case 2:
                            message.downloadStatus = 2;
                            break;
                        }
                        switch (object.retrieveVideoType) {
                        case "RETRIEVE_VIDEO_TYPE_UNKNOWN":
                        case 0:
                            message.retrieveVideoType = 0;
                            break;
                        case "RTSP":
                        case 1:
                            message.retrieveVideoType = 1;
                            break;
                        case "DOWNLOAD":
                        case 2:
                            message.retrieveVideoType = 2;
                            break;
                        }
                        if (object.useFfmpegSsFlag != null)
                            message.useFfmpegSsFlag = Boolean(object.useFfmpegSsFlag);
                        if (object.referenceCameraId != null)
                            if ($util.Long)
                                (message.referenceCameraId = $util.Long.fromValue(object.referenceCameraId)).unsigned = false;
                            else if (typeof object.referenceCameraId === "string")
                                message.referenceCameraId = parseInt(object.referenceCameraId, 10);
                            else if (typeof object.referenceCameraId === "number")
                                message.referenceCameraId = object.referenceCameraId;
                            else if (typeof object.referenceCameraId === "object")
                                message.referenceCameraId = new $util.LongBits(object.referenceCameraId.low >>> 0, object.referenceCameraId.high >>> 0).toNumber();
                        if (object.referencingCameras) {
                            if (!Array.isArray(object.referencingCameras))
                                throw TypeError(".palexy.streaming.v1.Camera.referencingCameras: array expected");
                            message.referencingCameras = [];
                            for (var i = 0; i < object.referencingCameras.length; ++i) {
                                if (typeof object.referencingCameras[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.Camera.referencingCameras: object expected");
                                message.referencingCameras[i] = $root.palexy.streaming.v1.Camera.fromObject(object.referencingCameras[i]);
                            }
                        }
                        if (object.isEnableProcessing != null)
                            message.isEnableProcessing = Boolean(object.isEnableProcessing);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Camera message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Camera
                     * @static
                     * @param {palexy.streaming.v1.Camera} message Camera
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Camera.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.actions = [];
                            object.preprocessingOptions = [];
                            object.subviews = [];
                            object.referencingCameras = [];
                        }
                        if (options.defaults) {
                            object.name = "";
                            object.username = "";
                            object.password = "";
                            object.rtspUrl = "";
                            object.cameraId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraDatabaseId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraDatabaseId = options.longs === String ? "0" : 0;
                            object.startTime = "";
                            object.endTime = "";
                            object.timezone = "";
                            object.transformationMatrix = null;
                            object.excludedAreas = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.areaId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.areaId = options.longs === String ? "0" : 0;
                            object.area = null;
                            object.streamingState = options.enums === String ? "UNKNOWN" : 0;
                            object.streamingStateStatusMessage = "";
                            object.streamingStateLastUpdateTime = null;
                            object.lastAlertTime = null;
                            object.numAlert = 0;
                            object.streamingWorkerName = "";
                            object.streamingWorkerLastUpdateTime = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.cameraResolutionWidth = 0;
                            object.cameraResolutionHeight = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.versionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.versionId = options.longs === String ? "0" : 0;
                            object.shiftStatus = options.enums === String ? "SHIFT_UNKNOWN" : 0;
                            object.lastShiftCheckingTime = null;
                            object.store = null;
                            object.streamingLocation = "";
                            object.company = null;
                            object.orientation = options.enums === String ? "ORIENTATION_UNKNOWN" : 0;
                            object.type = options.enums === String ? "BASIC" : 0;
                            object.fps = "";
                            object.isDisabled = false;
                            object.useWallclockAsTimestamps = false;
                            object.firstSentLevel_2NotificationTime = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.parentCameraDatabaseId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.parentCameraDatabaseId = options.longs === String ? "0" : 0;
                            object.customStreamingTime = null;
                            object.listWeekdayCustomStreamingTime = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.dvrCameraId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.dvrCameraId = options.longs === String ? "0" : 0;
                            object.isStreamingOnly = false;
                            object.isShifted = false;
                            object.rtspTransportProtocol = options.enums === String ? "PROTOCOL_UNKNOWN" : 0;
                            object.downloadStatus = options.enums === String ? "DOWNLOAD_STATUS_UNKNOWN" : 0;
                            object.retrieveVideoType = options.enums === String ? "RETRIEVE_VIDEO_TYPE_UNKNOWN" : 0;
                            object.useFfmpegSsFlag = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.referenceCameraId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.referenceCameraId = options.longs === String ? "0" : 0;
                            object.isEnableProcessing = false;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.username != null && message.hasOwnProperty("username"))
                            object.username = message.username;
                        if (message.password != null && message.hasOwnProperty("password"))
                            object.password = message.password;
                        if (message.rtspUrl != null && message.hasOwnProperty("rtspUrl"))
                            object.rtspUrl = message.rtspUrl;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.cameraDatabaseId != null && message.hasOwnProperty("cameraDatabaseId"))
                            if (typeof message.cameraDatabaseId === "number")
                                object.cameraDatabaseId = options.longs === String ? String(message.cameraDatabaseId) : message.cameraDatabaseId;
                            else
                                object.cameraDatabaseId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraDatabaseId) : options.longs === Number ? new $util.LongBits(message.cameraDatabaseId.low >>> 0, message.cameraDatabaseId.high >>> 0).toNumber() : message.cameraDatabaseId;
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            object.startTime = message.startTime;
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            object.endTime = message.endTime;
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            object.timezone = message.timezone;
                        if (message.transformationMatrix != null && message.hasOwnProperty("transformationMatrix"))
                            object.transformationMatrix = $root.palexy.streaming.v1.TransformationMatrix.toObject(message.transformationMatrix, options);
                        if (message.excludedAreas != null && message.hasOwnProperty("excludedAreas"))
                            object.excludedAreas = $root.palexy.streaming.v1.ExcludedAreas.toObject(message.excludedAreas, options);
                        if (message.areaId != null && message.hasOwnProperty("areaId"))
                            if (typeof message.areaId === "number")
                                object.areaId = options.longs === String ? String(message.areaId) : message.areaId;
                            else
                                object.areaId = options.longs === String ? $util.Long.prototype.toString.call(message.areaId) : options.longs === Number ? new $util.LongBits(message.areaId.low >>> 0, message.areaId.high >>> 0).toNumber() : message.areaId;
                        if (message.area != null && message.hasOwnProperty("area"))
                            object.area = $root.palexy.streaming.v1.Area.toObject(message.area, options);
                        if (message.streamingState != null && message.hasOwnProperty("streamingState"))
                            object.streamingState = options.enums === String ? $root.palexy.streaming.v1.Camera.StreamingState[message.streamingState] : message.streamingState;
                        if (message.streamingStateStatusMessage != null && message.hasOwnProperty("streamingStateStatusMessage"))
                            object.streamingStateStatusMessage = message.streamingStateStatusMessage;
                        if (message.streamingStateLastUpdateTime != null && message.hasOwnProperty("streamingStateLastUpdateTime"))
                            object.streamingStateLastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.streamingStateLastUpdateTime, options);
                        if (message.lastAlertTime != null && message.hasOwnProperty("lastAlertTime"))
                            object.lastAlertTime = $root.google.protobuf.Timestamp.toObject(message.lastAlertTime, options);
                        if (message.numAlert != null && message.hasOwnProperty("numAlert"))
                            object.numAlert = message.numAlert;
                        if (message.streamingWorkerName != null && message.hasOwnProperty("streamingWorkerName"))
                            object.streamingWorkerName = message.streamingWorkerName;
                        if (message.streamingWorkerLastUpdateTime != null && message.hasOwnProperty("streamingWorkerLastUpdateTime"))
                            object.streamingWorkerLastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.streamingWorkerLastUpdateTime, options);
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.cameraResolutionWidth != null && message.hasOwnProperty("cameraResolutionWidth"))
                            object.cameraResolutionWidth = message.cameraResolutionWidth;
                        if (message.cameraResolutionHeight != null && message.hasOwnProperty("cameraResolutionHeight"))
                            object.cameraResolutionHeight = message.cameraResolutionHeight;
                        if (message.versionId != null && message.hasOwnProperty("versionId"))
                            if (typeof message.versionId === "number")
                                object.versionId = options.longs === String ? String(message.versionId) : message.versionId;
                            else
                                object.versionId = options.longs === String ? $util.Long.prototype.toString.call(message.versionId) : options.longs === Number ? new $util.LongBits(message.versionId.low >>> 0, message.versionId.high >>> 0).toNumber() : message.versionId;
                        if (message.shiftStatus != null && message.hasOwnProperty("shiftStatus"))
                            object.shiftStatus = options.enums === String ? $root.palexy.streaming.v1.Camera.ShiftStatus[message.shiftStatus] : message.shiftStatus;
                        if (message.lastShiftCheckingTime != null && message.hasOwnProperty("lastShiftCheckingTime"))
                            object.lastShiftCheckingTime = $root.google.protobuf.Timestamp.toObject(message.lastShiftCheckingTime, options);
                        if (message.store != null && message.hasOwnProperty("store"))
                            object.store = $root.palexy.streaming.v1.Store.toObject(message.store, options);
                        if (message.streamingLocation != null && message.hasOwnProperty("streamingLocation"))
                            object.streamingLocation = message.streamingLocation;
                        if (message.company != null && message.hasOwnProperty("company"))
                            object.company = $root.palexy.streaming.v1.Company.toObject(message.company, options);
                        if (message.actions && message.actions.length) {
                            object.actions = [];
                            for (var j = 0; j < message.actions.length; ++j)
                                object.actions[j] = options.enums === String ? $root.palexy.streaming.v1.Camera.Action[message.actions[j]] : message.actions[j];
                        }
                        if (message.orientation != null && message.hasOwnProperty("orientation"))
                            object.orientation = options.enums === String ? $root.palexy.streaming.v1.Camera.Orientation[message.orientation] : message.orientation;
                        if (message.preprocessingOptions && message.preprocessingOptions.length) {
                            object.preprocessingOptions = [];
                            for (var j = 0; j < message.preprocessingOptions.length; ++j)
                                object.preprocessingOptions[j] = $root.palexy.streaming.v1.PreprocessingOption.toObject(message.preprocessingOptions[j], options);
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.Camera.Type[message.type] : message.type;
                        if (message.subviews && message.subviews.length) {
                            object.subviews = [];
                            for (var j = 0; j < message.subviews.length; ++j)
                                object.subviews[j] = $root.palexy.streaming.v1.Camera.toObject(message.subviews[j], options);
                        }
                        if (message.fps != null && message.hasOwnProperty("fps"))
                            object.fps = message.fps;
                        if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                            object.isDisabled = message.isDisabled;
                        if (message.useWallclockAsTimestamps != null && message.hasOwnProperty("useWallclockAsTimestamps"))
                            object.useWallclockAsTimestamps = message.useWallclockAsTimestamps;
                        if (message.firstSentLevel_2NotificationTime != null && message.hasOwnProperty("firstSentLevel_2NotificationTime"))
                            object.firstSentLevel_2NotificationTime = $root.google.protobuf.Timestamp.toObject(message.firstSentLevel_2NotificationTime, options);
                        if (message.parentCameraDatabaseId != null && message.hasOwnProperty("parentCameraDatabaseId"))
                            if (typeof message.parentCameraDatabaseId === "number")
                                object.parentCameraDatabaseId = options.longs === String ? String(message.parentCameraDatabaseId) : message.parentCameraDatabaseId;
                            else
                                object.parentCameraDatabaseId = options.longs === String ? $util.Long.prototype.toString.call(message.parentCameraDatabaseId) : options.longs === Number ? new $util.LongBits(message.parentCameraDatabaseId.low >>> 0, message.parentCameraDatabaseId.high >>> 0).toNumber() : message.parentCameraDatabaseId;
                        if (message.customStreamingTime != null && message.hasOwnProperty("customStreamingTime"))
                            object.customStreamingTime = $root.palexy.streaming.v1.CustomStreamingTime.toObject(message.customStreamingTime, options);
                        if (message.listWeekdayCustomStreamingTime != null && message.hasOwnProperty("listWeekdayCustomStreamingTime"))
                            object.listWeekdayCustomStreamingTime = $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime.toObject(message.listWeekdayCustomStreamingTime, options);
                        if (message.dvrCameraId != null && message.hasOwnProperty("dvrCameraId"))
                            if (typeof message.dvrCameraId === "number")
                                object.dvrCameraId = options.longs === String ? String(message.dvrCameraId) : message.dvrCameraId;
                            else
                                object.dvrCameraId = options.longs === String ? $util.Long.prototype.toString.call(message.dvrCameraId) : options.longs === Number ? new $util.LongBits(message.dvrCameraId.low >>> 0, message.dvrCameraId.high >>> 0).toNumber() : message.dvrCameraId;
                        if (message.isStreamingOnly != null && message.hasOwnProperty("isStreamingOnly"))
                            object.isStreamingOnly = message.isStreamingOnly;
                        if (message.isShifted != null && message.hasOwnProperty("isShifted"))
                            object.isShifted = message.isShifted;
                        if (message.rtspTransportProtocol != null && message.hasOwnProperty("rtspTransportProtocol"))
                            object.rtspTransportProtocol = options.enums === String ? $root.palexy.streaming.v1.Camera.RtspTransportProtocol[message.rtspTransportProtocol] : message.rtspTransportProtocol;
                        if (message.downloadStatus != null && message.hasOwnProperty("downloadStatus"))
                            object.downloadStatus = options.enums === String ? $root.palexy.streaming.v1.Camera.DownloadStatus[message.downloadStatus] : message.downloadStatus;
                        if (message.retrieveVideoType != null && message.hasOwnProperty("retrieveVideoType"))
                            object.retrieveVideoType = options.enums === String ? $root.palexy.streaming.v1.Camera.RetrieveVideoType[message.retrieveVideoType] : message.retrieveVideoType;
                        if (message.useFfmpegSsFlag != null && message.hasOwnProperty("useFfmpegSsFlag"))
                            object.useFfmpegSsFlag = message.useFfmpegSsFlag;
                        if (message.referenceCameraId != null && message.hasOwnProperty("referenceCameraId"))
                            if (typeof message.referenceCameraId === "number")
                                object.referenceCameraId = options.longs === String ? String(message.referenceCameraId) : message.referenceCameraId;
                            else
                                object.referenceCameraId = options.longs === String ? $util.Long.prototype.toString.call(message.referenceCameraId) : options.longs === Number ? new $util.LongBits(message.referenceCameraId.low >>> 0, message.referenceCameraId.high >>> 0).toNumber() : message.referenceCameraId;
                        if (message.referencingCameras && message.referencingCameras.length) {
                            object.referencingCameras = [];
                            for (var j = 0; j < message.referencingCameras.length; ++j)
                                object.referencingCameras[j] = $root.palexy.streaming.v1.Camera.toObject(message.referencingCameras[j], options);
                        }
                        if (message.isEnableProcessing != null && message.hasOwnProperty("isEnableProcessing"))
                            object.isEnableProcessing = message.isEnableProcessing;
                        return object;
                    };
    
                    /**
                     * Converts this Camera to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Camera
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Camera.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * RetrieveVideoType enum.
                     * @name palexy.streaming.v1.Camera.RetrieveVideoType
                     * @enum {number}
                     * @property {number} RETRIEVE_VIDEO_TYPE_UNKNOWN=0 RETRIEVE_VIDEO_TYPE_UNKNOWN value
                     * @property {number} RTSP=1 RTSP value
                     * @property {number} DOWNLOAD=2 DOWNLOAD value
                     */
                    Camera.RetrieveVideoType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "RETRIEVE_VIDEO_TYPE_UNKNOWN"] = 0;
                        values[valuesById[1] = "RTSP"] = 1;
                        values[valuesById[2] = "DOWNLOAD"] = 2;
                        return values;
                    })();
    
                    /**
                     * StreamingState enum.
                     * @name palexy.streaming.v1.Camera.StreamingState
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} NORMAL=1 NORMAL value
                     * @property {number} BROKEN=2 BROKEN value
                     */
                    Camera.StreamingState = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "NORMAL"] = 1;
                        values[valuesById[2] = "BROKEN"] = 2;
                        return values;
                    })();
    
                    /**
                     * DownloadStatus enum.
                     * @name palexy.streaming.v1.Camera.DownloadStatus
                     * @enum {number}
                     * @property {number} DOWNLOAD_STATUS_UNKNOWN=0 DOWNLOAD_STATUS_UNKNOWN value
                     * @property {number} DOWNLOAD_NORMAL=1 DOWNLOAD_NORMAL value
                     * @property {number} DOWNLOAD_BROKEN=2 DOWNLOAD_BROKEN value
                     */
                    Camera.DownloadStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "DOWNLOAD_STATUS_UNKNOWN"] = 0;
                        values[valuesById[1] = "DOWNLOAD_NORMAL"] = 1;
                        values[valuesById[2] = "DOWNLOAD_BROKEN"] = 2;
                        return values;
                    })();
    
                    /**
                     * ShiftStatus enum.
                     * @name palexy.streaming.v1.Camera.ShiftStatus
                     * @enum {number}
                     * @property {number} SHIFT_UNKNOWN=0 SHIFT_UNKNOWN value
                     * @property {number} SHIFTED=1 SHIFTED value
                     * @property {number} NOT_SHIFTED=2 NOT_SHIFTED value
                     */
                    Camera.ShiftStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "SHIFT_UNKNOWN"] = 0;
                        values[valuesById[1] = "SHIFTED"] = 1;
                        values[valuesById[2] = "NOT_SHIFTED"] = 2;
                        return values;
                    })();
    
                    /**
                     * Action enum.
                     * @name palexy.streaming.v1.Camera.Action
                     * @enum {number}
                     * @property {number} ACTION_UNKNOWN=0 ACTION_UNKNOWN value
                     * @property {number} ENTRANCE=1 ENTRANCE value
                     * @property {number} QUEUE_TIME=2 QUEUE_TIME value
                     * @property {number} SECTION=3 SECTION value
                     * @property {number} PASS_BY=4 PASS_BY value
                     * @property {number} DEFAULT=5 DEFAULT value
                     * @property {number} WIDE_SPACE_ENTRANCE=6 WIDE_SPACE_ENTRANCE value
                     */
                    Camera.Action = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "ACTION_UNKNOWN"] = 0;
                        values[valuesById[1] = "ENTRANCE"] = 1;
                        values[valuesById[2] = "QUEUE_TIME"] = 2;
                        values[valuesById[3] = "SECTION"] = 3;
                        values[valuesById[4] = "PASS_BY"] = 4;
                        values[valuesById[5] = "DEFAULT"] = 5;
                        values[valuesById[6] = "WIDE_SPACE_ENTRANCE"] = 6;
                        return values;
                    })();
    
                    /**
                     * Orientation enum.
                     * @name palexy.streaming.v1.Camera.Orientation
                     * @enum {number}
                     * @property {number} ORIENTATION_UNKNOWN=0 ORIENTATION_UNKNOWN value
                     * @property {number} ROTATE_0=1 ROTATE_0 value
                     * @property {number} ROTATE_90_CLOCKWISE=2 ROTATE_90_CLOCKWISE value
                     * @property {number} ROTATE_90_COUNTERCLOCKWISE=3 ROTATE_90_COUNTERCLOCKWISE value
                     * @property {number} ROTATE_180=4 ROTATE_180 value
                     */
                    Camera.Orientation = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "ORIENTATION_UNKNOWN"] = 0;
                        values[valuesById[1] = "ROTATE_0"] = 1;
                        values[valuesById[2] = "ROTATE_90_CLOCKWISE"] = 2;
                        values[valuesById[3] = "ROTATE_90_COUNTERCLOCKWISE"] = 3;
                        values[valuesById[4] = "ROTATE_180"] = 4;
                        return values;
                    })();
    
                    /**
                     * Type enum.
                     * @name palexy.streaming.v1.Camera.Type
                     * @enum {number}
                     * @property {number} BASIC=0 BASIC value
                     * @property {number} FISHEYE=1 FISHEYE value
                     * @property {number} FISHEYE_SUBVIEW=2 FISHEYE_SUBVIEW value
                     */
                    Camera.Type = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "BASIC"] = 0;
                        values[valuesById[1] = "FISHEYE"] = 1;
                        values[valuesById[2] = "FISHEYE_SUBVIEW"] = 2;
                        return values;
                    })();
    
                    /**
                     * RtspTransportProtocol enum.
                     * @name palexy.streaming.v1.Camera.RtspTransportProtocol
                     * @enum {number}
                     * @property {number} PROTOCOL_UNKNOWN=0 PROTOCOL_UNKNOWN value
                     * @property {number} TCP=1 TCP value
                     * @property {number} UDP=2 UDP value
                     */
                    Camera.RtspTransportProtocol = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "PROTOCOL_UNKNOWN"] = 0;
                        values[valuesById[1] = "TCP"] = 1;
                        values[valuesById[2] = "UDP"] = 2;
                        return values;
                    })();
    
                    return Camera;
                })();
    
                v1.StreamingTime = (function() {
    
                    /**
                     * Properties of a StreamingTime.
                     * @memberof palexy.streaming.v1
                     * @interface IStreamingTime
                     * @property {string|null} [startTime] StreamingTime startTime
                     * @property {string|null} [endTime] StreamingTime endTime
                     */
    
                    /**
                     * Constructs a new StreamingTime.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StreamingTime.
                     * @implements IStreamingTime
                     * @constructor
                     * @param {palexy.streaming.v1.IStreamingTime=} [properties] Properties to set
                     */
                    function StreamingTime(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StreamingTime startTime.
                     * @member {string} startTime
                     * @memberof palexy.streaming.v1.StreamingTime
                     * @instance
                     */
                    StreamingTime.prototype.startTime = "";
    
                    /**
                     * StreamingTime endTime.
                     * @member {string} endTime
                     * @memberof palexy.streaming.v1.StreamingTime
                     * @instance
                     */
                    StreamingTime.prototype.endTime = "";
    
                    /**
                     * Creates a new StreamingTime instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StreamingTime
                     * @static
                     * @param {palexy.streaming.v1.IStreamingTime=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StreamingTime} StreamingTime instance
                     */
                    StreamingTime.create = function create(properties) {
                        return new StreamingTime(properties);
                    };
    
                    /**
                     * Encodes the specified StreamingTime message. Does not implicitly {@link palexy.streaming.v1.StreamingTime.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StreamingTime
                     * @static
                     * @param {palexy.streaming.v1.IStreamingTime} message StreamingTime message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StreamingTime.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.startTime);
                        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.endTime);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StreamingTime message, length delimited. Does not implicitly {@link palexy.streaming.v1.StreamingTime.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StreamingTime
                     * @static
                     * @param {palexy.streaming.v1.IStreamingTime} message StreamingTime message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StreamingTime.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StreamingTime message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StreamingTime
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StreamingTime} StreamingTime
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StreamingTime.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StreamingTime();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startTime = reader.string();
                                break;
                            case 2:
                                message.endTime = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StreamingTime message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StreamingTime
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StreamingTime} StreamingTime
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StreamingTime.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StreamingTime message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StreamingTime
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StreamingTime.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            if (!$util.isString(message.startTime))
                                return "startTime: string expected";
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            if (!$util.isString(message.endTime))
                                return "endTime: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a StreamingTime message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StreamingTime
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StreamingTime} StreamingTime
                     */
                    StreamingTime.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StreamingTime)
                            return object;
                        var message = new $root.palexy.streaming.v1.StreamingTime();
                        if (object.startTime != null)
                            message.startTime = String(object.startTime);
                        if (object.endTime != null)
                            message.endTime = String(object.endTime);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StreamingTime message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StreamingTime
                     * @static
                     * @param {palexy.streaming.v1.StreamingTime} message StreamingTime
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StreamingTime.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startTime = "";
                            object.endTime = "";
                        }
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            object.startTime = message.startTime;
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            object.endTime = message.endTime;
                        return object;
                    };
    
                    /**
                     * Converts this StreamingTime to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StreamingTime
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StreamingTime.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StreamingTime;
                })();
    
                v1.CustomStreamingTime = (function() {
    
                    /**
                     * Properties of a CustomStreamingTime.
                     * @memberof palexy.streaming.v1
                     * @interface ICustomStreamingTime
                     * @property {palexy.streaming.v1.IStreamingTime|null} [mondayStreamingTime] CustomStreamingTime mondayStreamingTime
                     * @property {palexy.streaming.v1.IStreamingTime|null} [tuesdayStreamingTime] CustomStreamingTime tuesdayStreamingTime
                     * @property {palexy.streaming.v1.IStreamingTime|null} [wednesdayStreamingTime] CustomStreamingTime wednesdayStreamingTime
                     * @property {palexy.streaming.v1.IStreamingTime|null} [thursdayStreamingTime] CustomStreamingTime thursdayStreamingTime
                     * @property {palexy.streaming.v1.IStreamingTime|null} [fridayStreamingTime] CustomStreamingTime fridayStreamingTime
                     * @property {palexy.streaming.v1.IStreamingTime|null} [saturdayStreamingTime] CustomStreamingTime saturdayStreamingTime
                     * @property {palexy.streaming.v1.IStreamingTime|null} [sundayStreamingTime] CustomStreamingTime sundayStreamingTime
                     */
    
                    /**
                     * Constructs a new CustomStreamingTime.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CustomStreamingTime.
                     * @implements ICustomStreamingTime
                     * @constructor
                     * @param {palexy.streaming.v1.ICustomStreamingTime=} [properties] Properties to set
                     */
                    function CustomStreamingTime(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CustomStreamingTime mondayStreamingTime.
                     * @member {palexy.streaming.v1.IStreamingTime|null|undefined} mondayStreamingTime
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @instance
                     */
                    CustomStreamingTime.prototype.mondayStreamingTime = null;
    
                    /**
                     * CustomStreamingTime tuesdayStreamingTime.
                     * @member {palexy.streaming.v1.IStreamingTime|null|undefined} tuesdayStreamingTime
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @instance
                     */
                    CustomStreamingTime.prototype.tuesdayStreamingTime = null;
    
                    /**
                     * CustomStreamingTime wednesdayStreamingTime.
                     * @member {palexy.streaming.v1.IStreamingTime|null|undefined} wednesdayStreamingTime
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @instance
                     */
                    CustomStreamingTime.prototype.wednesdayStreamingTime = null;
    
                    /**
                     * CustomStreamingTime thursdayStreamingTime.
                     * @member {palexy.streaming.v1.IStreamingTime|null|undefined} thursdayStreamingTime
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @instance
                     */
                    CustomStreamingTime.prototype.thursdayStreamingTime = null;
    
                    /**
                     * CustomStreamingTime fridayStreamingTime.
                     * @member {palexy.streaming.v1.IStreamingTime|null|undefined} fridayStreamingTime
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @instance
                     */
                    CustomStreamingTime.prototype.fridayStreamingTime = null;
    
                    /**
                     * CustomStreamingTime saturdayStreamingTime.
                     * @member {palexy.streaming.v1.IStreamingTime|null|undefined} saturdayStreamingTime
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @instance
                     */
                    CustomStreamingTime.prototype.saturdayStreamingTime = null;
    
                    /**
                     * CustomStreamingTime sundayStreamingTime.
                     * @member {palexy.streaming.v1.IStreamingTime|null|undefined} sundayStreamingTime
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @instance
                     */
                    CustomStreamingTime.prototype.sundayStreamingTime = null;
    
                    /**
                     * Creates a new CustomStreamingTime instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.ICustomStreamingTime=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CustomStreamingTime} CustomStreamingTime instance
                     */
                    CustomStreamingTime.create = function create(properties) {
                        return new CustomStreamingTime(properties);
                    };
    
                    /**
                     * Encodes the specified CustomStreamingTime message. Does not implicitly {@link palexy.streaming.v1.CustomStreamingTime.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.ICustomStreamingTime} message CustomStreamingTime message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomStreamingTime.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mondayStreamingTime != null && Object.hasOwnProperty.call(message, "mondayStreamingTime"))
                            $root.palexy.streaming.v1.StreamingTime.encode(message.mondayStreamingTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.tuesdayStreamingTime != null && Object.hasOwnProperty.call(message, "tuesdayStreamingTime"))
                            $root.palexy.streaming.v1.StreamingTime.encode(message.tuesdayStreamingTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.wednesdayStreamingTime != null && Object.hasOwnProperty.call(message, "wednesdayStreamingTime"))
                            $root.palexy.streaming.v1.StreamingTime.encode(message.wednesdayStreamingTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.thursdayStreamingTime != null && Object.hasOwnProperty.call(message, "thursdayStreamingTime"))
                            $root.palexy.streaming.v1.StreamingTime.encode(message.thursdayStreamingTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.fridayStreamingTime != null && Object.hasOwnProperty.call(message, "fridayStreamingTime"))
                            $root.palexy.streaming.v1.StreamingTime.encode(message.fridayStreamingTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.saturdayStreamingTime != null && Object.hasOwnProperty.call(message, "saturdayStreamingTime"))
                            $root.palexy.streaming.v1.StreamingTime.encode(message.saturdayStreamingTime, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.sundayStreamingTime != null && Object.hasOwnProperty.call(message, "sundayStreamingTime"))
                            $root.palexy.streaming.v1.StreamingTime.encode(message.sundayStreamingTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CustomStreamingTime message, length delimited. Does not implicitly {@link palexy.streaming.v1.CustomStreamingTime.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.ICustomStreamingTime} message CustomStreamingTime message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomStreamingTime.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CustomStreamingTime message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CustomStreamingTime} CustomStreamingTime
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomStreamingTime.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CustomStreamingTime();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.mondayStreamingTime = $root.palexy.streaming.v1.StreamingTime.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.tuesdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.wednesdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.thursdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.fridayStreamingTime = $root.palexy.streaming.v1.StreamingTime.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.saturdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.sundayStreamingTime = $root.palexy.streaming.v1.StreamingTime.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CustomStreamingTime message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CustomStreamingTime} CustomStreamingTime
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomStreamingTime.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CustomStreamingTime message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomStreamingTime.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mondayStreamingTime != null && message.hasOwnProperty("mondayStreamingTime")) {
                            var error = $root.palexy.streaming.v1.StreamingTime.verify(message.mondayStreamingTime);
                            if (error)
                                return "mondayStreamingTime." + error;
                        }
                        if (message.tuesdayStreamingTime != null && message.hasOwnProperty("tuesdayStreamingTime")) {
                            var error = $root.palexy.streaming.v1.StreamingTime.verify(message.tuesdayStreamingTime);
                            if (error)
                                return "tuesdayStreamingTime." + error;
                        }
                        if (message.wednesdayStreamingTime != null && message.hasOwnProperty("wednesdayStreamingTime")) {
                            var error = $root.palexy.streaming.v1.StreamingTime.verify(message.wednesdayStreamingTime);
                            if (error)
                                return "wednesdayStreamingTime." + error;
                        }
                        if (message.thursdayStreamingTime != null && message.hasOwnProperty("thursdayStreamingTime")) {
                            var error = $root.palexy.streaming.v1.StreamingTime.verify(message.thursdayStreamingTime);
                            if (error)
                                return "thursdayStreamingTime." + error;
                        }
                        if (message.fridayStreamingTime != null && message.hasOwnProperty("fridayStreamingTime")) {
                            var error = $root.palexy.streaming.v1.StreamingTime.verify(message.fridayStreamingTime);
                            if (error)
                                return "fridayStreamingTime." + error;
                        }
                        if (message.saturdayStreamingTime != null && message.hasOwnProperty("saturdayStreamingTime")) {
                            var error = $root.palexy.streaming.v1.StreamingTime.verify(message.saturdayStreamingTime);
                            if (error)
                                return "saturdayStreamingTime." + error;
                        }
                        if (message.sundayStreamingTime != null && message.hasOwnProperty("sundayStreamingTime")) {
                            var error = $root.palexy.streaming.v1.StreamingTime.verify(message.sundayStreamingTime);
                            if (error)
                                return "sundayStreamingTime." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CustomStreamingTime message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CustomStreamingTime} CustomStreamingTime
                     */
                    CustomStreamingTime.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CustomStreamingTime)
                            return object;
                        var message = new $root.palexy.streaming.v1.CustomStreamingTime();
                        if (object.mondayStreamingTime != null) {
                            if (typeof object.mondayStreamingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CustomStreamingTime.mondayStreamingTime: object expected");
                            message.mondayStreamingTime = $root.palexy.streaming.v1.StreamingTime.fromObject(object.mondayStreamingTime);
                        }
                        if (object.tuesdayStreamingTime != null) {
                            if (typeof object.tuesdayStreamingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CustomStreamingTime.tuesdayStreamingTime: object expected");
                            message.tuesdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.fromObject(object.tuesdayStreamingTime);
                        }
                        if (object.wednesdayStreamingTime != null) {
                            if (typeof object.wednesdayStreamingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CustomStreamingTime.wednesdayStreamingTime: object expected");
                            message.wednesdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.fromObject(object.wednesdayStreamingTime);
                        }
                        if (object.thursdayStreamingTime != null) {
                            if (typeof object.thursdayStreamingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CustomStreamingTime.thursdayStreamingTime: object expected");
                            message.thursdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.fromObject(object.thursdayStreamingTime);
                        }
                        if (object.fridayStreamingTime != null) {
                            if (typeof object.fridayStreamingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CustomStreamingTime.fridayStreamingTime: object expected");
                            message.fridayStreamingTime = $root.palexy.streaming.v1.StreamingTime.fromObject(object.fridayStreamingTime);
                        }
                        if (object.saturdayStreamingTime != null) {
                            if (typeof object.saturdayStreamingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CustomStreamingTime.saturdayStreamingTime: object expected");
                            message.saturdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.fromObject(object.saturdayStreamingTime);
                        }
                        if (object.sundayStreamingTime != null) {
                            if (typeof object.sundayStreamingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CustomStreamingTime.sundayStreamingTime: object expected");
                            message.sundayStreamingTime = $root.palexy.streaming.v1.StreamingTime.fromObject(object.sundayStreamingTime);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CustomStreamingTime message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.CustomStreamingTime} message CustomStreamingTime
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomStreamingTime.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.mondayStreamingTime = null;
                            object.tuesdayStreamingTime = null;
                            object.wednesdayStreamingTime = null;
                            object.thursdayStreamingTime = null;
                            object.fridayStreamingTime = null;
                            object.saturdayStreamingTime = null;
                            object.sundayStreamingTime = null;
                        }
                        if (message.mondayStreamingTime != null && message.hasOwnProperty("mondayStreamingTime"))
                            object.mondayStreamingTime = $root.palexy.streaming.v1.StreamingTime.toObject(message.mondayStreamingTime, options);
                        if (message.tuesdayStreamingTime != null && message.hasOwnProperty("tuesdayStreamingTime"))
                            object.tuesdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.toObject(message.tuesdayStreamingTime, options);
                        if (message.wednesdayStreamingTime != null && message.hasOwnProperty("wednesdayStreamingTime"))
                            object.wednesdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.toObject(message.wednesdayStreamingTime, options);
                        if (message.thursdayStreamingTime != null && message.hasOwnProperty("thursdayStreamingTime"))
                            object.thursdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.toObject(message.thursdayStreamingTime, options);
                        if (message.fridayStreamingTime != null && message.hasOwnProperty("fridayStreamingTime"))
                            object.fridayStreamingTime = $root.palexy.streaming.v1.StreamingTime.toObject(message.fridayStreamingTime, options);
                        if (message.saturdayStreamingTime != null && message.hasOwnProperty("saturdayStreamingTime"))
                            object.saturdayStreamingTime = $root.palexy.streaming.v1.StreamingTime.toObject(message.saturdayStreamingTime, options);
                        if (message.sundayStreamingTime != null && message.hasOwnProperty("sundayStreamingTime"))
                            object.sundayStreamingTime = $root.palexy.streaming.v1.StreamingTime.toObject(message.sundayStreamingTime, options);
                        return object;
                    };
    
                    /**
                     * Converts this CustomStreamingTime to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CustomStreamingTime
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomStreamingTime.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CustomStreamingTime;
                })();
    
                v1.WeekdayCustomStreamingTime = (function() {
    
                    /**
                     * Properties of a WeekdayCustomStreamingTime.
                     * @memberof palexy.streaming.v1
                     * @interface IWeekdayCustomStreamingTime
                     * @property {palexy.streaming.v1.WeekdayCustomStreamingTime.DayOfWeek|null} [dayOfWeek] WeekdayCustomStreamingTime dayOfWeek
                     * @property {Array.<palexy.streaming.v1.IStreamingTime>|null} [streamingTimes] WeekdayCustomStreamingTime streamingTimes
                     */
    
                    /**
                     * Constructs a new WeekdayCustomStreamingTime.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a WeekdayCustomStreamingTime.
                     * @implements IWeekdayCustomStreamingTime
                     * @constructor
                     * @param {palexy.streaming.v1.IWeekdayCustomStreamingTime=} [properties] Properties to set
                     */
                    function WeekdayCustomStreamingTime(properties) {
                        this.streamingTimes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * WeekdayCustomStreamingTime dayOfWeek.
                     * @member {palexy.streaming.v1.WeekdayCustomStreamingTime.DayOfWeek} dayOfWeek
                     * @memberof palexy.streaming.v1.WeekdayCustomStreamingTime
                     * @instance
                     */
                    WeekdayCustomStreamingTime.prototype.dayOfWeek = 0;
    
                    /**
                     * WeekdayCustomStreamingTime streamingTimes.
                     * @member {Array.<palexy.streaming.v1.IStreamingTime>} streamingTimes
                     * @memberof palexy.streaming.v1.WeekdayCustomStreamingTime
                     * @instance
                     */
                    WeekdayCustomStreamingTime.prototype.streamingTimes = $util.emptyArray;
    
                    /**
                     * Creates a new WeekdayCustomStreamingTime instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.WeekdayCustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.IWeekdayCustomStreamingTime=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.WeekdayCustomStreamingTime} WeekdayCustomStreamingTime instance
                     */
                    WeekdayCustomStreamingTime.create = function create(properties) {
                        return new WeekdayCustomStreamingTime(properties);
                    };
    
                    /**
                     * Encodes the specified WeekdayCustomStreamingTime message. Does not implicitly {@link palexy.streaming.v1.WeekdayCustomStreamingTime.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.WeekdayCustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.IWeekdayCustomStreamingTime} message WeekdayCustomStreamingTime message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WeekdayCustomStreamingTime.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dayOfWeek != null && Object.hasOwnProperty.call(message, "dayOfWeek"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dayOfWeek);
                        if (message.streamingTimes != null && message.streamingTimes.length)
                            for (var i = 0; i < message.streamingTimes.length; ++i)
                                $root.palexy.streaming.v1.StreamingTime.encode(message.streamingTimes[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified WeekdayCustomStreamingTime message, length delimited. Does not implicitly {@link palexy.streaming.v1.WeekdayCustomStreamingTime.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.WeekdayCustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.IWeekdayCustomStreamingTime} message WeekdayCustomStreamingTime message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WeekdayCustomStreamingTime.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a WeekdayCustomStreamingTime message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.WeekdayCustomStreamingTime
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.WeekdayCustomStreamingTime} WeekdayCustomStreamingTime
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WeekdayCustomStreamingTime.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.WeekdayCustomStreamingTime();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dayOfWeek = reader.int32();
                                break;
                            case 2:
                                if (!(message.streamingTimes && message.streamingTimes.length))
                                    message.streamingTimes = [];
                                message.streamingTimes.push($root.palexy.streaming.v1.StreamingTime.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a WeekdayCustomStreamingTime message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.WeekdayCustomStreamingTime
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.WeekdayCustomStreamingTime} WeekdayCustomStreamingTime
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WeekdayCustomStreamingTime.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a WeekdayCustomStreamingTime message.
                     * @function verify
                     * @memberof palexy.streaming.v1.WeekdayCustomStreamingTime
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    WeekdayCustomStreamingTime.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                            switch (message.dayOfWeek) {
                            default:
                                return "dayOfWeek: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                                break;
                            }
                        if (message.streamingTimes != null && message.hasOwnProperty("streamingTimes")) {
                            if (!Array.isArray(message.streamingTimes))
                                return "streamingTimes: array expected";
                            for (var i = 0; i < message.streamingTimes.length; ++i) {
                                var error = $root.palexy.streaming.v1.StreamingTime.verify(message.streamingTimes[i]);
                                if (error)
                                    return "streamingTimes." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a WeekdayCustomStreamingTime message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.WeekdayCustomStreamingTime
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.WeekdayCustomStreamingTime} WeekdayCustomStreamingTime
                     */
                    WeekdayCustomStreamingTime.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.WeekdayCustomStreamingTime)
                            return object;
                        var message = new $root.palexy.streaming.v1.WeekdayCustomStreamingTime();
                        switch (object.dayOfWeek) {
                        case "DAY_OF_WEEK_UNSPECIFIED":
                        case 0:
                            message.dayOfWeek = 0;
                            break;
                        case "MONDAY":
                        case 1:
                            message.dayOfWeek = 1;
                            break;
                        case "TUESDAY":
                        case 2:
                            message.dayOfWeek = 2;
                            break;
                        case "WEDNESDAY":
                        case 3:
                            message.dayOfWeek = 3;
                            break;
                        case "THURSDAY":
                        case 4:
                            message.dayOfWeek = 4;
                            break;
                        case "FRIDAY":
                        case 5:
                            message.dayOfWeek = 5;
                            break;
                        case "SATURDAY":
                        case 6:
                            message.dayOfWeek = 6;
                            break;
                        case "SUNDAY":
                        case 7:
                            message.dayOfWeek = 7;
                            break;
                        }
                        if (object.streamingTimes) {
                            if (!Array.isArray(object.streamingTimes))
                                throw TypeError(".palexy.streaming.v1.WeekdayCustomStreamingTime.streamingTimes: array expected");
                            message.streamingTimes = [];
                            for (var i = 0; i < object.streamingTimes.length; ++i) {
                                if (typeof object.streamingTimes[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.WeekdayCustomStreamingTime.streamingTimes: object expected");
                                message.streamingTimes[i] = $root.palexy.streaming.v1.StreamingTime.fromObject(object.streamingTimes[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a WeekdayCustomStreamingTime message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.WeekdayCustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.WeekdayCustomStreamingTime} message WeekdayCustomStreamingTime
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    WeekdayCustomStreamingTime.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.streamingTimes = [];
                        if (options.defaults)
                            object.dayOfWeek = options.enums === String ? "DAY_OF_WEEK_UNSPECIFIED" : 0;
                        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                            object.dayOfWeek = options.enums === String ? $root.palexy.streaming.v1.WeekdayCustomStreamingTime.DayOfWeek[message.dayOfWeek] : message.dayOfWeek;
                        if (message.streamingTimes && message.streamingTimes.length) {
                            object.streamingTimes = [];
                            for (var j = 0; j < message.streamingTimes.length; ++j)
                                object.streamingTimes[j] = $root.palexy.streaming.v1.StreamingTime.toObject(message.streamingTimes[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this WeekdayCustomStreamingTime to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.WeekdayCustomStreamingTime
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    WeekdayCustomStreamingTime.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * DayOfWeek enum.
                     * @name palexy.streaming.v1.WeekdayCustomStreamingTime.DayOfWeek
                     * @enum {number}
                     * @property {number} DAY_OF_WEEK_UNSPECIFIED=0 DAY_OF_WEEK_UNSPECIFIED value
                     * @property {number} MONDAY=1 MONDAY value
                     * @property {number} TUESDAY=2 TUESDAY value
                     * @property {number} WEDNESDAY=3 WEDNESDAY value
                     * @property {number} THURSDAY=4 THURSDAY value
                     * @property {number} FRIDAY=5 FRIDAY value
                     * @property {number} SATURDAY=6 SATURDAY value
                     * @property {number} SUNDAY=7 SUNDAY value
                     */
                    WeekdayCustomStreamingTime.DayOfWeek = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "DAY_OF_WEEK_UNSPECIFIED"] = 0;
                        values[valuesById[1] = "MONDAY"] = 1;
                        values[valuesById[2] = "TUESDAY"] = 2;
                        values[valuesById[3] = "WEDNESDAY"] = 3;
                        values[valuesById[4] = "THURSDAY"] = 4;
                        values[valuesById[5] = "FRIDAY"] = 5;
                        values[valuesById[6] = "SATURDAY"] = 6;
                        values[valuesById[7] = "SUNDAY"] = 7;
                        return values;
                    })();
    
                    return WeekdayCustomStreamingTime;
                })();
    
                v1.ListWeekdayCustomStreamingTime = (function() {
    
                    /**
                     * Properties of a ListWeekdayCustomStreamingTime.
                     * @memberof palexy.streaming.v1
                     * @interface IListWeekdayCustomStreamingTime
                     * @property {Array.<palexy.streaming.v1.IWeekdayCustomStreamingTime>|null} [weekdayCustomStreamingTimes] ListWeekdayCustomStreamingTime weekdayCustomStreamingTimes
                     */
    
                    /**
                     * Constructs a new ListWeekdayCustomStreamingTime.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListWeekdayCustomStreamingTime.
                     * @implements IListWeekdayCustomStreamingTime
                     * @constructor
                     * @param {palexy.streaming.v1.IListWeekdayCustomStreamingTime=} [properties] Properties to set
                     */
                    function ListWeekdayCustomStreamingTime(properties) {
                        this.weekdayCustomStreamingTimes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListWeekdayCustomStreamingTime weekdayCustomStreamingTimes.
                     * @member {Array.<palexy.streaming.v1.IWeekdayCustomStreamingTime>} weekdayCustomStreamingTimes
                     * @memberof palexy.streaming.v1.ListWeekdayCustomStreamingTime
                     * @instance
                     */
                    ListWeekdayCustomStreamingTime.prototype.weekdayCustomStreamingTimes = $util.emptyArray;
    
                    /**
                     * Creates a new ListWeekdayCustomStreamingTime instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListWeekdayCustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.IListWeekdayCustomStreamingTime=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListWeekdayCustomStreamingTime} ListWeekdayCustomStreamingTime instance
                     */
                    ListWeekdayCustomStreamingTime.create = function create(properties) {
                        return new ListWeekdayCustomStreamingTime(properties);
                    };
    
                    /**
                     * Encodes the specified ListWeekdayCustomStreamingTime message. Does not implicitly {@link palexy.streaming.v1.ListWeekdayCustomStreamingTime.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListWeekdayCustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.IListWeekdayCustomStreamingTime} message ListWeekdayCustomStreamingTime message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListWeekdayCustomStreamingTime.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.weekdayCustomStreamingTimes != null && message.weekdayCustomStreamingTimes.length)
                            for (var i = 0; i < message.weekdayCustomStreamingTimes.length; ++i)
                                $root.palexy.streaming.v1.WeekdayCustomStreamingTime.encode(message.weekdayCustomStreamingTimes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListWeekdayCustomStreamingTime message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListWeekdayCustomStreamingTime.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListWeekdayCustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.IListWeekdayCustomStreamingTime} message ListWeekdayCustomStreamingTime message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListWeekdayCustomStreamingTime.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListWeekdayCustomStreamingTime message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListWeekdayCustomStreamingTime
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListWeekdayCustomStreamingTime} ListWeekdayCustomStreamingTime
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListWeekdayCustomStreamingTime.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.weekdayCustomStreamingTimes && message.weekdayCustomStreamingTimes.length))
                                    message.weekdayCustomStreamingTimes = [];
                                message.weekdayCustomStreamingTimes.push($root.palexy.streaming.v1.WeekdayCustomStreamingTime.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListWeekdayCustomStreamingTime message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListWeekdayCustomStreamingTime
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListWeekdayCustomStreamingTime} ListWeekdayCustomStreamingTime
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListWeekdayCustomStreamingTime.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListWeekdayCustomStreamingTime message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListWeekdayCustomStreamingTime
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListWeekdayCustomStreamingTime.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.weekdayCustomStreamingTimes != null && message.hasOwnProperty("weekdayCustomStreamingTimes")) {
                            if (!Array.isArray(message.weekdayCustomStreamingTimes))
                                return "weekdayCustomStreamingTimes: array expected";
                            for (var i = 0; i < message.weekdayCustomStreamingTimes.length; ++i) {
                                var error = $root.palexy.streaming.v1.WeekdayCustomStreamingTime.verify(message.weekdayCustomStreamingTimes[i]);
                                if (error)
                                    return "weekdayCustomStreamingTimes." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListWeekdayCustomStreamingTime message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListWeekdayCustomStreamingTime
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListWeekdayCustomStreamingTime} ListWeekdayCustomStreamingTime
                     */
                    ListWeekdayCustomStreamingTime.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime();
                        if (object.weekdayCustomStreamingTimes) {
                            if (!Array.isArray(object.weekdayCustomStreamingTimes))
                                throw TypeError(".palexy.streaming.v1.ListWeekdayCustomStreamingTime.weekdayCustomStreamingTimes: array expected");
                            message.weekdayCustomStreamingTimes = [];
                            for (var i = 0; i < object.weekdayCustomStreamingTimes.length; ++i) {
                                if (typeof object.weekdayCustomStreamingTimes[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListWeekdayCustomStreamingTime.weekdayCustomStreamingTimes: object expected");
                                message.weekdayCustomStreamingTimes[i] = $root.palexy.streaming.v1.WeekdayCustomStreamingTime.fromObject(object.weekdayCustomStreamingTimes[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListWeekdayCustomStreamingTime message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListWeekdayCustomStreamingTime
                     * @static
                     * @param {palexy.streaming.v1.ListWeekdayCustomStreamingTime} message ListWeekdayCustomStreamingTime
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListWeekdayCustomStreamingTime.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.weekdayCustomStreamingTimes = [];
                        if (message.weekdayCustomStreamingTimes && message.weekdayCustomStreamingTimes.length) {
                            object.weekdayCustomStreamingTimes = [];
                            for (var j = 0; j < message.weekdayCustomStreamingTimes.length; ++j)
                                object.weekdayCustomStreamingTimes[j] = $root.palexy.streaming.v1.WeekdayCustomStreamingTime.toObject(message.weekdayCustomStreamingTimes[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListWeekdayCustomStreamingTime to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListWeekdayCustomStreamingTime
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListWeekdayCustomStreamingTime.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListWeekdayCustomStreamingTime;
                })();
    
                v1.PreprocessingOption = (function() {
    
                    /**
                     * Properties of a PreprocessingOption.
                     * @memberof palexy.streaming.v1
                     * @interface IPreprocessingOption
                     * @property {palexy.streaming.v1.IWarpOption|null} [warpOption] PreprocessingOption warpOption
                     */
    
                    /**
                     * Constructs a new PreprocessingOption.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PreprocessingOption.
                     * @implements IPreprocessingOption
                     * @constructor
                     * @param {palexy.streaming.v1.IPreprocessingOption=} [properties] Properties to set
                     */
                    function PreprocessingOption(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PreprocessingOption warpOption.
                     * @member {palexy.streaming.v1.IWarpOption|null|undefined} warpOption
                     * @memberof palexy.streaming.v1.PreprocessingOption
                     * @instance
                     */
                    PreprocessingOption.prototype.warpOption = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * PreprocessingOption option.
                     * @member {"warpOption"|undefined} option
                     * @memberof palexy.streaming.v1.PreprocessingOption
                     * @instance
                     */
                    Object.defineProperty(PreprocessingOption.prototype, "option", {
                        get: $util.oneOfGetter($oneOfFields = ["warpOption"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new PreprocessingOption instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PreprocessingOption
                     * @static
                     * @param {palexy.streaming.v1.IPreprocessingOption=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PreprocessingOption} PreprocessingOption instance
                     */
                    PreprocessingOption.create = function create(properties) {
                        return new PreprocessingOption(properties);
                    };
    
                    /**
                     * Encodes the specified PreprocessingOption message. Does not implicitly {@link palexy.streaming.v1.PreprocessingOption.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PreprocessingOption
                     * @static
                     * @param {palexy.streaming.v1.IPreprocessingOption} message PreprocessingOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PreprocessingOption.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.warpOption != null && Object.hasOwnProperty.call(message, "warpOption"))
                            $root.palexy.streaming.v1.WarpOption.encode(message.warpOption, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PreprocessingOption message, length delimited. Does not implicitly {@link palexy.streaming.v1.PreprocessingOption.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PreprocessingOption
                     * @static
                     * @param {palexy.streaming.v1.IPreprocessingOption} message PreprocessingOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PreprocessingOption.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PreprocessingOption message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PreprocessingOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PreprocessingOption} PreprocessingOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PreprocessingOption.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PreprocessingOption();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.warpOption = $root.palexy.streaming.v1.WarpOption.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PreprocessingOption message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PreprocessingOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PreprocessingOption} PreprocessingOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PreprocessingOption.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PreprocessingOption message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PreprocessingOption
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PreprocessingOption.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.warpOption != null && message.hasOwnProperty("warpOption")) {
                            properties.option = 1;
                            {
                                var error = $root.palexy.streaming.v1.WarpOption.verify(message.warpOption);
                                if (error)
                                    return "warpOption." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PreprocessingOption message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PreprocessingOption
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PreprocessingOption} PreprocessingOption
                     */
                    PreprocessingOption.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PreprocessingOption)
                            return object;
                        var message = new $root.palexy.streaming.v1.PreprocessingOption();
                        if (object.warpOption != null) {
                            if (typeof object.warpOption !== "object")
                                throw TypeError(".palexy.streaming.v1.PreprocessingOption.warpOption: object expected");
                            message.warpOption = $root.palexy.streaming.v1.WarpOption.fromObject(object.warpOption);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PreprocessingOption message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PreprocessingOption
                     * @static
                     * @param {palexy.streaming.v1.PreprocessingOption} message PreprocessingOption
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PreprocessingOption.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.warpOption != null && message.hasOwnProperty("warpOption")) {
                            object.warpOption = $root.palexy.streaming.v1.WarpOption.toObject(message.warpOption, options);
                            if (options.oneofs)
                                object.option = "warpOption";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this PreprocessingOption to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PreprocessingOption
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PreprocessingOption.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PreprocessingOption;
                })();
    
                v1.WarpOption = (function() {
    
                    /**
                     * Properties of a WarpOption.
                     * @memberof palexy.streaming.v1
                     * @interface IWarpOption
                     * @property {number|null} [ihFov] WarpOption ihFov
                     * @property {number|null} [ivFov] WarpOption ivFov
                     * @property {number|null} [hFov] WarpOption hFov
                     * @property {number|null} [vFov] WarpOption vFov
                     * @property {number|null} [width] WarpOption width
                     * @property {number|null} [height] WarpOption height
                     * @property {number|null} [yaw] WarpOption yaw
                     * @property {number|null} [pitch] WarpOption pitch
                     * @property {number|null} [roll] WarpOption roll
                     */
    
                    /**
                     * Constructs a new WarpOption.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a WarpOption.
                     * @implements IWarpOption
                     * @constructor
                     * @param {palexy.streaming.v1.IWarpOption=} [properties] Properties to set
                     */
                    function WarpOption(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * WarpOption ihFov.
                     * @member {number} ihFov
                     * @memberof palexy.streaming.v1.WarpOption
                     * @instance
                     */
                    WarpOption.prototype.ihFov = 0;
    
                    /**
                     * WarpOption ivFov.
                     * @member {number} ivFov
                     * @memberof palexy.streaming.v1.WarpOption
                     * @instance
                     */
                    WarpOption.prototype.ivFov = 0;
    
                    /**
                     * WarpOption hFov.
                     * @member {number} hFov
                     * @memberof palexy.streaming.v1.WarpOption
                     * @instance
                     */
                    WarpOption.prototype.hFov = 0;
    
                    /**
                     * WarpOption vFov.
                     * @member {number} vFov
                     * @memberof palexy.streaming.v1.WarpOption
                     * @instance
                     */
                    WarpOption.prototype.vFov = 0;
    
                    /**
                     * WarpOption width.
                     * @member {number} width
                     * @memberof palexy.streaming.v1.WarpOption
                     * @instance
                     */
                    WarpOption.prototype.width = 0;
    
                    /**
                     * WarpOption height.
                     * @member {number} height
                     * @memberof palexy.streaming.v1.WarpOption
                     * @instance
                     */
                    WarpOption.prototype.height = 0;
    
                    /**
                     * WarpOption yaw.
                     * @member {number} yaw
                     * @memberof palexy.streaming.v1.WarpOption
                     * @instance
                     */
                    WarpOption.prototype.yaw = 0;
    
                    /**
                     * WarpOption pitch.
                     * @member {number} pitch
                     * @memberof palexy.streaming.v1.WarpOption
                     * @instance
                     */
                    WarpOption.prototype.pitch = 0;
    
                    /**
                     * WarpOption roll.
                     * @member {number} roll
                     * @memberof palexy.streaming.v1.WarpOption
                     * @instance
                     */
                    WarpOption.prototype.roll = 0;
    
                    /**
                     * Creates a new WarpOption instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.WarpOption
                     * @static
                     * @param {palexy.streaming.v1.IWarpOption=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.WarpOption} WarpOption instance
                     */
                    WarpOption.create = function create(properties) {
                        return new WarpOption(properties);
                    };
    
                    /**
                     * Encodes the specified WarpOption message. Does not implicitly {@link palexy.streaming.v1.WarpOption.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.WarpOption
                     * @static
                     * @param {palexy.streaming.v1.IWarpOption} message WarpOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WarpOption.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.ihFov != null && Object.hasOwnProperty.call(message, "ihFov"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ihFov);
                        if (message.ivFov != null && Object.hasOwnProperty.call(message, "ivFov"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ivFov);
                        if (message.hFov != null && Object.hasOwnProperty.call(message, "hFov"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.hFov);
                        if (message.vFov != null && Object.hasOwnProperty.call(message, "vFov"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.vFov);
                        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.width);
                        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.height);
                        if (message.yaw != null && Object.hasOwnProperty.call(message, "yaw"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.yaw);
                        if (message.pitch != null && Object.hasOwnProperty.call(message, "pitch"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.pitch);
                        if (message.roll != null && Object.hasOwnProperty.call(message, "roll"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.roll);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified WarpOption message, length delimited. Does not implicitly {@link palexy.streaming.v1.WarpOption.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.WarpOption
                     * @static
                     * @param {palexy.streaming.v1.IWarpOption} message WarpOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WarpOption.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a WarpOption message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.WarpOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.WarpOption} WarpOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WarpOption.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.WarpOption();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.ihFov = reader.int32();
                                break;
                            case 2:
                                message.ivFov = reader.int32();
                                break;
                            case 3:
                                message.hFov = reader.int32();
                                break;
                            case 4:
                                message.vFov = reader.int32();
                                break;
                            case 5:
                                message.width = reader.int32();
                                break;
                            case 6:
                                message.height = reader.int32();
                                break;
                            case 7:
                                message.yaw = reader.int32();
                                break;
                            case 8:
                                message.pitch = reader.int32();
                                break;
                            case 9:
                                message.roll = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a WarpOption message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.WarpOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.WarpOption} WarpOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WarpOption.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a WarpOption message.
                     * @function verify
                     * @memberof palexy.streaming.v1.WarpOption
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    WarpOption.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.ihFov != null && message.hasOwnProperty("ihFov"))
                            if (!$util.isInteger(message.ihFov))
                                return "ihFov: integer expected";
                        if (message.ivFov != null && message.hasOwnProperty("ivFov"))
                            if (!$util.isInteger(message.ivFov))
                                return "ivFov: integer expected";
                        if (message.hFov != null && message.hasOwnProperty("hFov"))
                            if (!$util.isInteger(message.hFov))
                                return "hFov: integer expected";
                        if (message.vFov != null && message.hasOwnProperty("vFov"))
                            if (!$util.isInteger(message.vFov))
                                return "vFov: integer expected";
                        if (message.width != null && message.hasOwnProperty("width"))
                            if (!$util.isInteger(message.width))
                                return "width: integer expected";
                        if (message.height != null && message.hasOwnProperty("height"))
                            if (!$util.isInteger(message.height))
                                return "height: integer expected";
                        if (message.yaw != null && message.hasOwnProperty("yaw"))
                            if (!$util.isInteger(message.yaw))
                                return "yaw: integer expected";
                        if (message.pitch != null && message.hasOwnProperty("pitch"))
                            if (!$util.isInteger(message.pitch))
                                return "pitch: integer expected";
                        if (message.roll != null && message.hasOwnProperty("roll"))
                            if (!$util.isInteger(message.roll))
                                return "roll: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a WarpOption message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.WarpOption
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.WarpOption} WarpOption
                     */
                    WarpOption.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.WarpOption)
                            return object;
                        var message = new $root.palexy.streaming.v1.WarpOption();
                        if (object.ihFov != null)
                            message.ihFov = object.ihFov | 0;
                        if (object.ivFov != null)
                            message.ivFov = object.ivFov | 0;
                        if (object.hFov != null)
                            message.hFov = object.hFov | 0;
                        if (object.vFov != null)
                            message.vFov = object.vFov | 0;
                        if (object.width != null)
                            message.width = object.width | 0;
                        if (object.height != null)
                            message.height = object.height | 0;
                        if (object.yaw != null)
                            message.yaw = object.yaw | 0;
                        if (object.pitch != null)
                            message.pitch = object.pitch | 0;
                        if (object.roll != null)
                            message.roll = object.roll | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a WarpOption message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.WarpOption
                     * @static
                     * @param {palexy.streaming.v1.WarpOption} message WarpOption
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    WarpOption.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.ihFov = 0;
                            object.ivFov = 0;
                            object.hFov = 0;
                            object.vFov = 0;
                            object.width = 0;
                            object.height = 0;
                            object.yaw = 0;
                            object.pitch = 0;
                            object.roll = 0;
                        }
                        if (message.ihFov != null && message.hasOwnProperty("ihFov"))
                            object.ihFov = message.ihFov;
                        if (message.ivFov != null && message.hasOwnProperty("ivFov"))
                            object.ivFov = message.ivFov;
                        if (message.hFov != null && message.hasOwnProperty("hFov"))
                            object.hFov = message.hFov;
                        if (message.vFov != null && message.hasOwnProperty("vFov"))
                            object.vFov = message.vFov;
                        if (message.width != null && message.hasOwnProperty("width"))
                            object.width = message.width;
                        if (message.height != null && message.hasOwnProperty("height"))
                            object.height = message.height;
                        if (message.yaw != null && message.hasOwnProperty("yaw"))
                            object.yaw = message.yaw;
                        if (message.pitch != null && message.hasOwnProperty("pitch"))
                            object.pitch = message.pitch;
                        if (message.roll != null && message.hasOwnProperty("roll"))
                            object.roll = message.roll;
                        return object;
                    };
    
                    /**
                     * Converts this WarpOption to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.WarpOption
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    WarpOption.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return WarpOption;
                })();
    
                v1.Area = (function() {
    
                    /**
                     * Properties of an Area.
                     * @memberof palexy.streaming.v1
                     * @interface IArea
                     * @property {string|null} [name] Area name
                     * @property {number|null} [storeId] Area storeId
                     * @property {number|null} [width] Area width
                     * @property {number|null} [height] Area height
                     * @property {number|null} [id] Area id
                     */
    
                    /**
                     * Constructs a new Area.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an Area.
                     * @implements IArea
                     * @constructor
                     * @param {palexy.streaming.v1.IArea=} [properties] Properties to set
                     */
                    function Area(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Area name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.Area
                     * @instance
                     */
                    Area.prototype.name = "";
    
                    /**
                     * Area storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.Area
                     * @instance
                     */
                    Area.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Area width.
                     * @member {number} width
                     * @memberof palexy.streaming.v1.Area
                     * @instance
                     */
                    Area.prototype.width = 0;
    
                    /**
                     * Area height.
                     * @member {number} height
                     * @memberof palexy.streaming.v1.Area
                     * @instance
                     */
                    Area.prototype.height = 0;
    
                    /**
                     * Area id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.Area
                     * @instance
                     */
                    Area.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new Area instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Area
                     * @static
                     * @param {palexy.streaming.v1.IArea=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Area} Area instance
                     */
                    Area.create = function create(properties) {
                        return new Area(properties);
                    };
    
                    /**
                     * Encodes the specified Area message. Does not implicitly {@link palexy.streaming.v1.Area.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Area
                     * @static
                     * @param {palexy.streaming.v1.IArea} message Area message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Area.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.width);
                        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.height);
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Area message, length delimited. Does not implicitly {@link palexy.streaming.v1.Area.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Area
                     * @static
                     * @param {palexy.streaming.v1.IArea} message Area message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Area.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Area message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Area
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Area} Area
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Area.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Area();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.width = reader.float();
                                break;
                            case 4:
                                message.height = reader.float();
                                break;
                            case 5:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Area message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Area
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Area} Area
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Area.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Area message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Area
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Area.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.width != null && message.hasOwnProperty("width"))
                            if (typeof message.width !== "number")
                                return "width: number expected";
                        if (message.height != null && message.hasOwnProperty("height"))
                            if (typeof message.height !== "number")
                                return "height: number expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an Area message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Area
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Area} Area
                     */
                    Area.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Area)
                            return object;
                        var message = new $root.palexy.streaming.v1.Area();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.width != null)
                            message.width = Number(object.width);
                        if (object.height != null)
                            message.height = Number(object.height);
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an Area message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Area
                     * @static
                     * @param {palexy.streaming.v1.Area} message Area
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Area.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.width = 0;
                            object.height = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.width != null && message.hasOwnProperty("width"))
                            object.width = options.json && !isFinite(message.width) ? String(message.width) : message.width;
                        if (message.height != null && message.hasOwnProperty("height"))
                            object.height = options.json && !isFinite(message.height) ? String(message.height) : message.height;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this Area to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Area
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Area.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Area;
                })();
    
                v1.ExcludedAreas = (function() {
    
                    /**
                     * Properties of an ExcludedAreas.
                     * @memberof palexy.streaming.v1
                     * @interface IExcludedAreas
                     * @property {Array.<palexy.streaming.v1.IPolygon>|null} [polygons] ExcludedAreas polygons
                     */
    
                    /**
                     * Constructs a new ExcludedAreas.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ExcludedAreas.
                     * @implements IExcludedAreas
                     * @constructor
                     * @param {palexy.streaming.v1.IExcludedAreas=} [properties] Properties to set
                     */
                    function ExcludedAreas(properties) {
                        this.polygons = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExcludedAreas polygons.
                     * @member {Array.<palexy.streaming.v1.IPolygon>} polygons
                     * @memberof palexy.streaming.v1.ExcludedAreas
                     * @instance
                     */
                    ExcludedAreas.prototype.polygons = $util.emptyArray;
    
                    /**
                     * Creates a new ExcludedAreas instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ExcludedAreas
                     * @static
                     * @param {palexy.streaming.v1.IExcludedAreas=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ExcludedAreas} ExcludedAreas instance
                     */
                    ExcludedAreas.create = function create(properties) {
                        return new ExcludedAreas(properties);
                    };
    
                    /**
                     * Encodes the specified ExcludedAreas message. Does not implicitly {@link palexy.streaming.v1.ExcludedAreas.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ExcludedAreas
                     * @static
                     * @param {palexy.streaming.v1.IExcludedAreas} message ExcludedAreas message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExcludedAreas.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.polygons != null && message.polygons.length)
                            for (var i = 0; i < message.polygons.length; ++i)
                                $root.palexy.streaming.v1.Polygon.encode(message.polygons[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExcludedAreas message, length delimited. Does not implicitly {@link palexy.streaming.v1.ExcludedAreas.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ExcludedAreas
                     * @static
                     * @param {palexy.streaming.v1.IExcludedAreas} message ExcludedAreas message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExcludedAreas.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExcludedAreas message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ExcludedAreas
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ExcludedAreas} ExcludedAreas
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExcludedAreas.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ExcludedAreas();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.polygons && message.polygons.length))
                                    message.polygons = [];
                                message.polygons.push($root.palexy.streaming.v1.Polygon.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExcludedAreas message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ExcludedAreas
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ExcludedAreas} ExcludedAreas
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExcludedAreas.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExcludedAreas message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ExcludedAreas
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExcludedAreas.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.polygons != null && message.hasOwnProperty("polygons")) {
                            if (!Array.isArray(message.polygons))
                                return "polygons: array expected";
                            for (var i = 0; i < message.polygons.length; ++i) {
                                var error = $root.palexy.streaming.v1.Polygon.verify(message.polygons[i]);
                                if (error)
                                    return "polygons." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ExcludedAreas message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ExcludedAreas
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ExcludedAreas} ExcludedAreas
                     */
                    ExcludedAreas.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ExcludedAreas)
                            return object;
                        var message = new $root.palexy.streaming.v1.ExcludedAreas();
                        if (object.polygons) {
                            if (!Array.isArray(object.polygons))
                                throw TypeError(".palexy.streaming.v1.ExcludedAreas.polygons: array expected");
                            message.polygons = [];
                            for (var i = 0; i < object.polygons.length; ++i) {
                                if (typeof object.polygons[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ExcludedAreas.polygons: object expected");
                                message.polygons[i] = $root.palexy.streaming.v1.Polygon.fromObject(object.polygons[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExcludedAreas message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ExcludedAreas
                     * @static
                     * @param {palexy.streaming.v1.ExcludedAreas} message ExcludedAreas
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExcludedAreas.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.polygons = [];
                        if (message.polygons && message.polygons.length) {
                            object.polygons = [];
                            for (var j = 0; j < message.polygons.length; ++j)
                                object.polygons[j] = $root.palexy.streaming.v1.Polygon.toObject(message.polygons[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ExcludedAreas to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ExcludedAreas
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExcludedAreas.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExcludedAreas;
                })();
    
                v1.Polygon = (function() {
    
                    /**
                     * Properties of a Polygon.
                     * @memberof palexy.streaming.v1
                     * @interface IPolygon
                     * @property {Array.<palexy.streaming.v1.IPoint>|null} [points] Polygon points
                     */
    
                    /**
                     * Constructs a new Polygon.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Polygon.
                     * @implements IPolygon
                     * @constructor
                     * @param {palexy.streaming.v1.IPolygon=} [properties] Properties to set
                     */
                    function Polygon(properties) {
                        this.points = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Polygon points.
                     * @member {Array.<palexy.streaming.v1.IPoint>} points
                     * @memberof palexy.streaming.v1.Polygon
                     * @instance
                     */
                    Polygon.prototype.points = $util.emptyArray;
    
                    /**
                     * Creates a new Polygon instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Polygon
                     * @static
                     * @param {palexy.streaming.v1.IPolygon=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Polygon} Polygon instance
                     */
                    Polygon.create = function create(properties) {
                        return new Polygon(properties);
                    };
    
                    /**
                     * Encodes the specified Polygon message. Does not implicitly {@link palexy.streaming.v1.Polygon.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Polygon
                     * @static
                     * @param {palexy.streaming.v1.IPolygon} message Polygon message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Polygon.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.points != null && message.points.length)
                            for (var i = 0; i < message.points.length; ++i)
                                $root.palexy.streaming.v1.Point.encode(message.points[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Polygon message, length delimited. Does not implicitly {@link palexy.streaming.v1.Polygon.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Polygon
                     * @static
                     * @param {palexy.streaming.v1.IPolygon} message Polygon message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Polygon.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Polygon message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Polygon
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Polygon} Polygon
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Polygon.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Polygon();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.points && message.points.length))
                                    message.points = [];
                                message.points.push($root.palexy.streaming.v1.Point.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Polygon message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Polygon
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Polygon} Polygon
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Polygon.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Polygon message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Polygon
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Polygon.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.points != null && message.hasOwnProperty("points")) {
                            if (!Array.isArray(message.points))
                                return "points: array expected";
                            for (var i = 0; i < message.points.length; ++i) {
                                var error = $root.palexy.streaming.v1.Point.verify(message.points[i]);
                                if (error)
                                    return "points." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Polygon message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Polygon
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Polygon} Polygon
                     */
                    Polygon.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Polygon)
                            return object;
                        var message = new $root.palexy.streaming.v1.Polygon();
                        if (object.points) {
                            if (!Array.isArray(object.points))
                                throw TypeError(".palexy.streaming.v1.Polygon.points: array expected");
                            message.points = [];
                            for (var i = 0; i < object.points.length; ++i) {
                                if (typeof object.points[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.Polygon.points: object expected");
                                message.points[i] = $root.palexy.streaming.v1.Point.fromObject(object.points[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Polygon message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Polygon
                     * @static
                     * @param {palexy.streaming.v1.Polygon} message Polygon
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Polygon.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.points = [];
                        if (message.points && message.points.length) {
                            object.points = [];
                            for (var j = 0; j < message.points.length; ++j)
                                object.points[j] = $root.palexy.streaming.v1.Point.toObject(message.points[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Polygon to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Polygon
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Polygon.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Polygon;
                })();
    
                v1.TransformationMatrix = (function() {
    
                    /**
                     * Properties of a TransformationMatrix.
                     * @memberof palexy.streaming.v1
                     * @interface ITransformationMatrix
                     * @property {palexy.streaming.v1.ITransformationPair|null} [pair_1] TransformationMatrix pair_1
                     * @property {palexy.streaming.v1.ITransformationPair|null} [pair_2] TransformationMatrix pair_2
                     * @property {palexy.streaming.v1.ITransformationPair|null} [pair_3] TransformationMatrix pair_3
                     * @property {palexy.streaming.v1.ITransformationPair|null} [pair_4] TransformationMatrix pair_4
                     */
    
                    /**
                     * Constructs a new TransformationMatrix.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a TransformationMatrix.
                     * @implements ITransformationMatrix
                     * @constructor
                     * @param {palexy.streaming.v1.ITransformationMatrix=} [properties] Properties to set
                     */
                    function TransformationMatrix(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TransformationMatrix pair_1.
                     * @member {palexy.streaming.v1.ITransformationPair|null|undefined} pair_1
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @instance
                     */
                    TransformationMatrix.prototype.pair_1 = null;
    
                    /**
                     * TransformationMatrix pair_2.
                     * @member {palexy.streaming.v1.ITransformationPair|null|undefined} pair_2
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @instance
                     */
                    TransformationMatrix.prototype.pair_2 = null;
    
                    /**
                     * TransformationMatrix pair_3.
                     * @member {palexy.streaming.v1.ITransformationPair|null|undefined} pair_3
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @instance
                     */
                    TransformationMatrix.prototype.pair_3 = null;
    
                    /**
                     * TransformationMatrix pair_4.
                     * @member {palexy.streaming.v1.ITransformationPair|null|undefined} pair_4
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @instance
                     */
                    TransformationMatrix.prototype.pair_4 = null;
    
                    /**
                     * Creates a new TransformationMatrix instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @static
                     * @param {palexy.streaming.v1.ITransformationMatrix=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.TransformationMatrix} TransformationMatrix instance
                     */
                    TransformationMatrix.create = function create(properties) {
                        return new TransformationMatrix(properties);
                    };
    
                    /**
                     * Encodes the specified TransformationMatrix message. Does not implicitly {@link palexy.streaming.v1.TransformationMatrix.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @static
                     * @param {palexy.streaming.v1.ITransformationMatrix} message TransformationMatrix message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TransformationMatrix.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pair_1 != null && Object.hasOwnProperty.call(message, "pair_1"))
                            $root.palexy.streaming.v1.TransformationPair.encode(message.pair_1, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.pair_2 != null && Object.hasOwnProperty.call(message, "pair_2"))
                            $root.palexy.streaming.v1.TransformationPair.encode(message.pair_2, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.pair_3 != null && Object.hasOwnProperty.call(message, "pair_3"))
                            $root.palexy.streaming.v1.TransformationPair.encode(message.pair_3, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.pair_4 != null && Object.hasOwnProperty.call(message, "pair_4"))
                            $root.palexy.streaming.v1.TransformationPair.encode(message.pair_4, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TransformationMatrix message, length delimited. Does not implicitly {@link palexy.streaming.v1.TransformationMatrix.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @static
                     * @param {palexy.streaming.v1.ITransformationMatrix} message TransformationMatrix message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TransformationMatrix.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TransformationMatrix message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.TransformationMatrix} TransformationMatrix
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TransformationMatrix.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.TransformationMatrix();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.pair_1 = $root.palexy.streaming.v1.TransformationPair.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.pair_2 = $root.palexy.streaming.v1.TransformationPair.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.pair_3 = $root.palexy.streaming.v1.TransformationPair.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.pair_4 = $root.palexy.streaming.v1.TransformationPair.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TransformationMatrix message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.TransformationMatrix} TransformationMatrix
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TransformationMatrix.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TransformationMatrix message.
                     * @function verify
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TransformationMatrix.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pair_1 != null && message.hasOwnProperty("pair_1")) {
                            var error = $root.palexy.streaming.v1.TransformationPair.verify(message.pair_1);
                            if (error)
                                return "pair_1." + error;
                        }
                        if (message.pair_2 != null && message.hasOwnProperty("pair_2")) {
                            var error = $root.palexy.streaming.v1.TransformationPair.verify(message.pair_2);
                            if (error)
                                return "pair_2." + error;
                        }
                        if (message.pair_3 != null && message.hasOwnProperty("pair_3")) {
                            var error = $root.palexy.streaming.v1.TransformationPair.verify(message.pair_3);
                            if (error)
                                return "pair_3." + error;
                        }
                        if (message.pair_4 != null && message.hasOwnProperty("pair_4")) {
                            var error = $root.palexy.streaming.v1.TransformationPair.verify(message.pair_4);
                            if (error)
                                return "pair_4." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a TransformationMatrix message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.TransformationMatrix} TransformationMatrix
                     */
                    TransformationMatrix.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.TransformationMatrix)
                            return object;
                        var message = new $root.palexy.streaming.v1.TransformationMatrix();
                        if (object.pair_1 != null) {
                            if (typeof object.pair_1 !== "object")
                                throw TypeError(".palexy.streaming.v1.TransformationMatrix.pair_1: object expected");
                            message.pair_1 = $root.palexy.streaming.v1.TransformationPair.fromObject(object.pair_1);
                        }
                        if (object.pair_2 != null) {
                            if (typeof object.pair_2 !== "object")
                                throw TypeError(".palexy.streaming.v1.TransformationMatrix.pair_2: object expected");
                            message.pair_2 = $root.palexy.streaming.v1.TransformationPair.fromObject(object.pair_2);
                        }
                        if (object.pair_3 != null) {
                            if (typeof object.pair_3 !== "object")
                                throw TypeError(".palexy.streaming.v1.TransformationMatrix.pair_3: object expected");
                            message.pair_3 = $root.palexy.streaming.v1.TransformationPair.fromObject(object.pair_3);
                        }
                        if (object.pair_4 != null) {
                            if (typeof object.pair_4 !== "object")
                                throw TypeError(".palexy.streaming.v1.TransformationMatrix.pair_4: object expected");
                            message.pair_4 = $root.palexy.streaming.v1.TransformationPair.fromObject(object.pair_4);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TransformationMatrix message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @static
                     * @param {palexy.streaming.v1.TransformationMatrix} message TransformationMatrix
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TransformationMatrix.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.pair_1 = null;
                            object.pair_2 = null;
                            object.pair_3 = null;
                            object.pair_4 = null;
                        }
                        if (message.pair_1 != null && message.hasOwnProperty("pair_1"))
                            object.pair_1 = $root.palexy.streaming.v1.TransformationPair.toObject(message.pair_1, options);
                        if (message.pair_2 != null && message.hasOwnProperty("pair_2"))
                            object.pair_2 = $root.palexy.streaming.v1.TransformationPair.toObject(message.pair_2, options);
                        if (message.pair_3 != null && message.hasOwnProperty("pair_3"))
                            object.pair_3 = $root.palexy.streaming.v1.TransformationPair.toObject(message.pair_3, options);
                        if (message.pair_4 != null && message.hasOwnProperty("pair_4"))
                            object.pair_4 = $root.palexy.streaming.v1.TransformationPair.toObject(message.pair_4, options);
                        return object;
                    };
    
                    /**
                     * Converts this TransformationMatrix to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.TransformationMatrix
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TransformationMatrix.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TransformationMatrix;
                })();
    
                v1.TransformationPair = (function() {
    
                    /**
                     * Properties of a TransformationPair.
                     * @memberof palexy.streaming.v1
                     * @interface ITransformationPair
                     * @property {palexy.streaming.v1.IPoint|null} [cameraPoint] TransformationPair cameraPoint
                     * @property {palexy.streaming.v1.IPoint|null} [floorPoint] TransformationPair floorPoint
                     */
    
                    /**
                     * Constructs a new TransformationPair.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a TransformationPair.
                     * @implements ITransformationPair
                     * @constructor
                     * @param {palexy.streaming.v1.ITransformationPair=} [properties] Properties to set
                     */
                    function TransformationPair(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TransformationPair cameraPoint.
                     * @member {palexy.streaming.v1.IPoint|null|undefined} cameraPoint
                     * @memberof palexy.streaming.v1.TransformationPair
                     * @instance
                     */
                    TransformationPair.prototype.cameraPoint = null;
    
                    /**
                     * TransformationPair floorPoint.
                     * @member {palexy.streaming.v1.IPoint|null|undefined} floorPoint
                     * @memberof palexy.streaming.v1.TransformationPair
                     * @instance
                     */
                    TransformationPair.prototype.floorPoint = null;
    
                    /**
                     * Creates a new TransformationPair instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.TransformationPair
                     * @static
                     * @param {palexy.streaming.v1.ITransformationPair=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.TransformationPair} TransformationPair instance
                     */
                    TransformationPair.create = function create(properties) {
                        return new TransformationPair(properties);
                    };
    
                    /**
                     * Encodes the specified TransformationPair message. Does not implicitly {@link palexy.streaming.v1.TransformationPair.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.TransformationPair
                     * @static
                     * @param {palexy.streaming.v1.ITransformationPair} message TransformationPair message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TransformationPair.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraPoint != null && Object.hasOwnProperty.call(message, "cameraPoint"))
                            $root.palexy.streaming.v1.Point.encode(message.cameraPoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.floorPoint != null && Object.hasOwnProperty.call(message, "floorPoint"))
                            $root.palexy.streaming.v1.Point.encode(message.floorPoint, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TransformationPair message, length delimited. Does not implicitly {@link palexy.streaming.v1.TransformationPair.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.TransformationPair
                     * @static
                     * @param {palexy.streaming.v1.ITransformationPair} message TransformationPair message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TransformationPair.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TransformationPair message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.TransformationPair
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.TransformationPair} TransformationPair
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TransformationPair.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.TransformationPair();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraPoint = $root.palexy.streaming.v1.Point.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.floorPoint = $root.palexy.streaming.v1.Point.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TransformationPair message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.TransformationPair
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.TransformationPair} TransformationPair
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TransformationPair.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TransformationPair message.
                     * @function verify
                     * @memberof palexy.streaming.v1.TransformationPair
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TransformationPair.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraPoint != null && message.hasOwnProperty("cameraPoint")) {
                            var error = $root.palexy.streaming.v1.Point.verify(message.cameraPoint);
                            if (error)
                                return "cameraPoint." + error;
                        }
                        if (message.floorPoint != null && message.hasOwnProperty("floorPoint")) {
                            var error = $root.palexy.streaming.v1.Point.verify(message.floorPoint);
                            if (error)
                                return "floorPoint." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a TransformationPair message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.TransformationPair
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.TransformationPair} TransformationPair
                     */
                    TransformationPair.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.TransformationPair)
                            return object;
                        var message = new $root.palexy.streaming.v1.TransformationPair();
                        if (object.cameraPoint != null) {
                            if (typeof object.cameraPoint !== "object")
                                throw TypeError(".palexy.streaming.v1.TransformationPair.cameraPoint: object expected");
                            message.cameraPoint = $root.palexy.streaming.v1.Point.fromObject(object.cameraPoint);
                        }
                        if (object.floorPoint != null) {
                            if (typeof object.floorPoint !== "object")
                                throw TypeError(".palexy.streaming.v1.TransformationPair.floorPoint: object expected");
                            message.floorPoint = $root.palexy.streaming.v1.Point.fromObject(object.floorPoint);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TransformationPair message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.TransformationPair
                     * @static
                     * @param {palexy.streaming.v1.TransformationPair} message TransformationPair
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TransformationPair.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.cameraPoint = null;
                            object.floorPoint = null;
                        }
                        if (message.cameraPoint != null && message.hasOwnProperty("cameraPoint"))
                            object.cameraPoint = $root.palexy.streaming.v1.Point.toObject(message.cameraPoint, options);
                        if (message.floorPoint != null && message.hasOwnProperty("floorPoint"))
                            object.floorPoint = $root.palexy.streaming.v1.Point.toObject(message.floorPoint, options);
                        return object;
                    };
    
                    /**
                     * Converts this TransformationPair to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.TransformationPair
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TransformationPair.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TransformationPair;
                })();
    
                v1.Point = (function() {
    
                    /**
                     * Properties of a Point.
                     * @memberof palexy.streaming.v1
                     * @interface IPoint
                     * @property {number|null} [x] Point x
                     * @property {number|null} [y] Point y
                     */
    
                    /**
                     * Constructs a new Point.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Point.
                     * @implements IPoint
                     * @constructor
                     * @param {palexy.streaming.v1.IPoint=} [properties] Properties to set
                     */
                    function Point(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Point x.
                     * @member {number} x
                     * @memberof palexy.streaming.v1.Point
                     * @instance
                     */
                    Point.prototype.x = 0;
    
                    /**
                     * Point y.
                     * @member {number} y
                     * @memberof palexy.streaming.v1.Point
                     * @instance
                     */
                    Point.prototype.y = 0;
    
                    /**
                     * Creates a new Point instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Point
                     * @static
                     * @param {palexy.streaming.v1.IPoint=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Point} Point instance
                     */
                    Point.create = function create(properties) {
                        return new Point(properties);
                    };
    
                    /**
                     * Encodes the specified Point message. Does not implicitly {@link palexy.streaming.v1.Point.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Point
                     * @static
                     * @param {palexy.streaming.v1.IPoint} message Point message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Point.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                            writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
                        if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.y);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Point message, length delimited. Does not implicitly {@link palexy.streaming.v1.Point.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Point
                     * @static
                     * @param {palexy.streaming.v1.IPoint} message Point message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Point.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Point message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Point
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Point} Point
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Point.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Point();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.x = reader.float();
                                break;
                            case 2:
                                message.y = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Point message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Point
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Point} Point
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Point.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Point message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Point
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Point.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.x != null && message.hasOwnProperty("x"))
                            if (typeof message.x !== "number")
                                return "x: number expected";
                        if (message.y != null && message.hasOwnProperty("y"))
                            if (typeof message.y !== "number")
                                return "y: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a Point message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Point
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Point} Point
                     */
                    Point.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Point)
                            return object;
                        var message = new $root.palexy.streaming.v1.Point();
                        if (object.x != null)
                            message.x = Number(object.x);
                        if (object.y != null)
                            message.y = Number(object.y);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Point message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Point
                     * @static
                     * @param {palexy.streaming.v1.Point} message Point
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Point.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.x = 0;
                            object.y = 0;
                        }
                        if (message.x != null && message.hasOwnProperty("x"))
                            object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
                        if (message.y != null && message.hasOwnProperty("y"))
                            object.y = options.json && !isFinite(message.y) ? String(message.y) : message.y;
                        return object;
                    };
    
                    /**
                     * Converts this Point to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Point
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Point.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Point;
                })();
    
                v1.UpdateWorkerHeartbeatRequest = (function() {
    
                    /**
                     * Properties of an UpdateWorkerHeartbeatRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateWorkerHeartbeatRequest
                     * @property {string|null} [worker] UpdateWorkerHeartbeatRequest worker
                     */
    
                    /**
                     * Constructs a new UpdateWorkerHeartbeatRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateWorkerHeartbeatRequest.
                     * @implements IUpdateWorkerHeartbeatRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateWorkerHeartbeatRequest=} [properties] Properties to set
                     */
                    function UpdateWorkerHeartbeatRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateWorkerHeartbeatRequest worker.
                     * @member {string} worker
                     * @memberof palexy.streaming.v1.UpdateWorkerHeartbeatRequest
                     * @instance
                     */
                    UpdateWorkerHeartbeatRequest.prototype.worker = "";
    
                    /**
                     * Creates a new UpdateWorkerHeartbeatRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateWorkerHeartbeatRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateWorkerHeartbeatRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateWorkerHeartbeatRequest} UpdateWorkerHeartbeatRequest instance
                     */
                    UpdateWorkerHeartbeatRequest.create = function create(properties) {
                        return new UpdateWorkerHeartbeatRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateWorkerHeartbeatRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateWorkerHeartbeatRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateWorkerHeartbeatRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateWorkerHeartbeatRequest} message UpdateWorkerHeartbeatRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateWorkerHeartbeatRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.worker != null && Object.hasOwnProperty.call(message, "worker"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.worker);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateWorkerHeartbeatRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateWorkerHeartbeatRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateWorkerHeartbeatRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateWorkerHeartbeatRequest} message UpdateWorkerHeartbeatRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateWorkerHeartbeatRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateWorkerHeartbeatRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateWorkerHeartbeatRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateWorkerHeartbeatRequest} UpdateWorkerHeartbeatRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateWorkerHeartbeatRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateWorkerHeartbeatRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.worker = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateWorkerHeartbeatRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateWorkerHeartbeatRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateWorkerHeartbeatRequest} UpdateWorkerHeartbeatRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateWorkerHeartbeatRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateWorkerHeartbeatRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateWorkerHeartbeatRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateWorkerHeartbeatRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.worker != null && message.hasOwnProperty("worker"))
                            if (!$util.isString(message.worker))
                                return "worker: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpdateWorkerHeartbeatRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateWorkerHeartbeatRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateWorkerHeartbeatRequest} UpdateWorkerHeartbeatRequest
                     */
                    UpdateWorkerHeartbeatRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateWorkerHeartbeatRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateWorkerHeartbeatRequest();
                        if (object.worker != null)
                            message.worker = String(object.worker);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateWorkerHeartbeatRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateWorkerHeartbeatRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateWorkerHeartbeatRequest} message UpdateWorkerHeartbeatRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateWorkerHeartbeatRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.worker = "";
                        if (message.worker != null && message.hasOwnProperty("worker"))
                            object.worker = message.worker;
                        return object;
                    };
    
                    /**
                     * Converts this UpdateWorkerHeartbeatRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateWorkerHeartbeatRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateWorkerHeartbeatRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateWorkerHeartbeatRequest;
                })();
    
                v1.TerminateWorkerRequest = (function() {
    
                    /**
                     * Properties of a TerminateWorkerRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ITerminateWorkerRequest
                     * @property {string|null} [worker] TerminateWorkerRequest worker
                     */
    
                    /**
                     * Constructs a new TerminateWorkerRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a TerminateWorkerRequest.
                     * @implements ITerminateWorkerRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ITerminateWorkerRequest=} [properties] Properties to set
                     */
                    function TerminateWorkerRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TerminateWorkerRequest worker.
                     * @member {string} worker
                     * @memberof palexy.streaming.v1.TerminateWorkerRequest
                     * @instance
                     */
                    TerminateWorkerRequest.prototype.worker = "";
    
                    /**
                     * Creates a new TerminateWorkerRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.TerminateWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.ITerminateWorkerRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.TerminateWorkerRequest} TerminateWorkerRequest instance
                     */
                    TerminateWorkerRequest.create = function create(properties) {
                        return new TerminateWorkerRequest(properties);
                    };
    
                    /**
                     * Encodes the specified TerminateWorkerRequest message. Does not implicitly {@link palexy.streaming.v1.TerminateWorkerRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.TerminateWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.ITerminateWorkerRequest} message TerminateWorkerRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TerminateWorkerRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.worker != null && Object.hasOwnProperty.call(message, "worker"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.worker);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TerminateWorkerRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.TerminateWorkerRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.TerminateWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.ITerminateWorkerRequest} message TerminateWorkerRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TerminateWorkerRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TerminateWorkerRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.TerminateWorkerRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.TerminateWorkerRequest} TerminateWorkerRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TerminateWorkerRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.TerminateWorkerRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.worker = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TerminateWorkerRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.TerminateWorkerRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.TerminateWorkerRequest} TerminateWorkerRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TerminateWorkerRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TerminateWorkerRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.TerminateWorkerRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TerminateWorkerRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.worker != null && message.hasOwnProperty("worker"))
                            if (!$util.isString(message.worker))
                                return "worker: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a TerminateWorkerRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.TerminateWorkerRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.TerminateWorkerRequest} TerminateWorkerRequest
                     */
                    TerminateWorkerRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.TerminateWorkerRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.TerminateWorkerRequest();
                        if (object.worker != null)
                            message.worker = String(object.worker);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TerminateWorkerRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.TerminateWorkerRequest
                     * @static
                     * @param {palexy.streaming.v1.TerminateWorkerRequest} message TerminateWorkerRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TerminateWorkerRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.worker = "";
                        if (message.worker != null && message.hasOwnProperty("worker"))
                            object.worker = message.worker;
                        return object;
                    };
    
                    /**
                     * Converts this TerminateWorkerRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.TerminateWorkerRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TerminateWorkerRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TerminateWorkerRequest;
                })();
    
                v1.UpdateWorkerCameraAssignmentsRequest = (function() {
    
                    /**
                     * Properties of an UpdateWorkerCameraAssignmentsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateWorkerCameraAssignmentsRequest
                     * @property {string|null} [worker] UpdateWorkerCameraAssignmentsRequest worker
                     * @property {palexy.streaming.v1.IListAssignedCameras|null} [assignedCameras] UpdateWorkerCameraAssignmentsRequest assignedCameras
                     */
    
                    /**
                     * Constructs a new UpdateWorkerCameraAssignmentsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateWorkerCameraAssignmentsRequest.
                     * @implements IUpdateWorkerCameraAssignmentsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateWorkerCameraAssignmentsRequest=} [properties] Properties to set
                     */
                    function UpdateWorkerCameraAssignmentsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateWorkerCameraAssignmentsRequest worker.
                     * @member {string} worker
                     * @memberof palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest
                     * @instance
                     */
                    UpdateWorkerCameraAssignmentsRequest.prototype.worker = "";
    
                    /**
                     * UpdateWorkerCameraAssignmentsRequest assignedCameras.
                     * @member {palexy.streaming.v1.IListAssignedCameras|null|undefined} assignedCameras
                     * @memberof palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest
                     * @instance
                     */
                    UpdateWorkerCameraAssignmentsRequest.prototype.assignedCameras = null;
    
                    /**
                     * Creates a new UpdateWorkerCameraAssignmentsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateWorkerCameraAssignmentsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest} UpdateWorkerCameraAssignmentsRequest instance
                     */
                    UpdateWorkerCameraAssignmentsRequest.create = function create(properties) {
                        return new UpdateWorkerCameraAssignmentsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateWorkerCameraAssignmentsRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateWorkerCameraAssignmentsRequest} message UpdateWorkerCameraAssignmentsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateWorkerCameraAssignmentsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.worker != null && Object.hasOwnProperty.call(message, "worker"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.worker);
                        if (message.assignedCameras != null && Object.hasOwnProperty.call(message, "assignedCameras"))
                            $root.palexy.streaming.v1.ListAssignedCameras.encode(message.assignedCameras, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateWorkerCameraAssignmentsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateWorkerCameraAssignmentsRequest} message UpdateWorkerCameraAssignmentsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateWorkerCameraAssignmentsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateWorkerCameraAssignmentsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest} UpdateWorkerCameraAssignmentsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateWorkerCameraAssignmentsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.worker = reader.string();
                                break;
                            case 2:
                                message.assignedCameras = $root.palexy.streaming.v1.ListAssignedCameras.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateWorkerCameraAssignmentsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest} UpdateWorkerCameraAssignmentsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateWorkerCameraAssignmentsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateWorkerCameraAssignmentsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateWorkerCameraAssignmentsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.worker != null && message.hasOwnProperty("worker"))
                            if (!$util.isString(message.worker))
                                return "worker: string expected";
                        if (message.assignedCameras != null && message.hasOwnProperty("assignedCameras")) {
                            var error = $root.palexy.streaming.v1.ListAssignedCameras.verify(message.assignedCameras);
                            if (error)
                                return "assignedCameras." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateWorkerCameraAssignmentsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest} UpdateWorkerCameraAssignmentsRequest
                     */
                    UpdateWorkerCameraAssignmentsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest();
                        if (object.worker != null)
                            message.worker = String(object.worker);
                        if (object.assignedCameras != null) {
                            if (typeof object.assignedCameras !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest.assignedCameras: object expected");
                            message.assignedCameras = $root.palexy.streaming.v1.ListAssignedCameras.fromObject(object.assignedCameras);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateWorkerCameraAssignmentsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest} message UpdateWorkerCameraAssignmentsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateWorkerCameraAssignmentsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.worker = "";
                            object.assignedCameras = null;
                        }
                        if (message.worker != null && message.hasOwnProperty("worker"))
                            object.worker = message.worker;
                        if (message.assignedCameras != null && message.hasOwnProperty("assignedCameras"))
                            object.assignedCameras = $root.palexy.streaming.v1.ListAssignedCameras.toObject(message.assignedCameras, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateWorkerCameraAssignmentsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateWorkerCameraAssignmentsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateWorkerCameraAssignmentsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateWorkerCameraAssignmentsRequest;
                })();
    
                v1.ListAssignedCameras = (function() {
    
                    /**
                     * Properties of a ListAssignedCameras.
                     * @memberof palexy.streaming.v1
                     * @interface IListAssignedCameras
                     * @property {Array.<palexy.streaming.v1.ICamera>|null} [assignedCameras] ListAssignedCameras assignedCameras
                     */
    
                    /**
                     * Constructs a new ListAssignedCameras.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListAssignedCameras.
                     * @implements IListAssignedCameras
                     * @constructor
                     * @param {palexy.streaming.v1.IListAssignedCameras=} [properties] Properties to set
                     */
                    function ListAssignedCameras(properties) {
                        this.assignedCameras = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListAssignedCameras assignedCameras.
                     * @member {Array.<palexy.streaming.v1.ICamera>} assignedCameras
                     * @memberof palexy.streaming.v1.ListAssignedCameras
                     * @instance
                     */
                    ListAssignedCameras.prototype.assignedCameras = $util.emptyArray;
    
                    /**
                     * Creates a new ListAssignedCameras instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListAssignedCameras
                     * @static
                     * @param {palexy.streaming.v1.IListAssignedCameras=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListAssignedCameras} ListAssignedCameras instance
                     */
                    ListAssignedCameras.create = function create(properties) {
                        return new ListAssignedCameras(properties);
                    };
    
                    /**
                     * Encodes the specified ListAssignedCameras message. Does not implicitly {@link palexy.streaming.v1.ListAssignedCameras.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListAssignedCameras
                     * @static
                     * @param {palexy.streaming.v1.IListAssignedCameras} message ListAssignedCameras message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAssignedCameras.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.assignedCameras != null && message.assignedCameras.length)
                            for (var i = 0; i < message.assignedCameras.length; ++i)
                                $root.palexy.streaming.v1.Camera.encode(message.assignedCameras[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListAssignedCameras message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListAssignedCameras.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListAssignedCameras
                     * @static
                     * @param {palexy.streaming.v1.IListAssignedCameras} message ListAssignedCameras message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAssignedCameras.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListAssignedCameras message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListAssignedCameras
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListAssignedCameras} ListAssignedCameras
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAssignedCameras.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListAssignedCameras();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 2:
                                if (!(message.assignedCameras && message.assignedCameras.length))
                                    message.assignedCameras = [];
                                message.assignedCameras.push($root.palexy.streaming.v1.Camera.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListAssignedCameras message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListAssignedCameras
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListAssignedCameras} ListAssignedCameras
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAssignedCameras.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListAssignedCameras message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListAssignedCameras
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListAssignedCameras.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.assignedCameras != null && message.hasOwnProperty("assignedCameras")) {
                            if (!Array.isArray(message.assignedCameras))
                                return "assignedCameras: array expected";
                            for (var i = 0; i < message.assignedCameras.length; ++i) {
                                var error = $root.palexy.streaming.v1.Camera.verify(message.assignedCameras[i]);
                                if (error)
                                    return "assignedCameras." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListAssignedCameras message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListAssignedCameras
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListAssignedCameras} ListAssignedCameras
                     */
                    ListAssignedCameras.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListAssignedCameras)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListAssignedCameras();
                        if (object.assignedCameras) {
                            if (!Array.isArray(object.assignedCameras))
                                throw TypeError(".palexy.streaming.v1.ListAssignedCameras.assignedCameras: array expected");
                            message.assignedCameras = [];
                            for (var i = 0; i < object.assignedCameras.length; ++i) {
                                if (typeof object.assignedCameras[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListAssignedCameras.assignedCameras: object expected");
                                message.assignedCameras[i] = $root.palexy.streaming.v1.Camera.fromObject(object.assignedCameras[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListAssignedCameras message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListAssignedCameras
                     * @static
                     * @param {palexy.streaming.v1.ListAssignedCameras} message ListAssignedCameras
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListAssignedCameras.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.assignedCameras = [];
                        if (message.assignedCameras && message.assignedCameras.length) {
                            object.assignedCameras = [];
                            for (var j = 0; j < message.assignedCameras.length; ++j)
                                object.assignedCameras[j] = $root.palexy.streaming.v1.Camera.toObject(message.assignedCameras[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListAssignedCameras to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListAssignedCameras
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListAssignedCameras.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListAssignedCameras;
                })();
    
                v1.CameraService = (function() {
    
                    /**
                     * Constructs a new CameraService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CameraService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function CameraService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (CameraService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CameraService;
    
                    /**
                     * Creates new CameraService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.CameraService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {CameraService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    CameraService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraService#listCameras}.
                     * @memberof palexy.streaming.v1.CameraService
                     * @typedef ListCamerasCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListCamerasResponse} [response] ListCamerasResponse
                     */
    
                    /**
                     * Calls ListCameras.
                     * @function listCameras
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IListCamerasRequest} request ListCamerasRequest message or plain object
                     * @param {palexy.streaming.v1.CameraService.ListCamerasCallback} callback Node-style callback called with the error, if any, and ListCamerasResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraService.prototype.listCameras = function listCameras(request, callback) {
                        return this.rpcCall(listCameras, $root.palexy.streaming.v1.ListCamerasRequest, $root.palexy.streaming.v1.ListCamerasResponse, request, callback);
                    }, "name", { value: "ListCameras" });
    
                    /**
                     * Calls ListCameras.
                     * @function listCameras
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IListCamerasRequest} request ListCamerasRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListCamerasResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraService#getCamera}.
                     * @memberof palexy.streaming.v1.CameraService
                     * @typedef GetCameraCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Camera} [response] Camera
                     */
    
                    /**
                     * Calls GetCamera.
                     * @function getCamera
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IGetCameraRequest} request GetCameraRequest message or plain object
                     * @param {palexy.streaming.v1.CameraService.GetCameraCallback} callback Node-style callback called with the error, if any, and Camera
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraService.prototype.getCamera = function getCamera(request, callback) {
                        return this.rpcCall(getCamera, $root.palexy.streaming.v1.GetCameraRequest, $root.palexy.streaming.v1.Camera, request, callback);
                    }, "name", { value: "GetCamera" });
    
                    /**
                     * Calls GetCamera.
                     * @function getCamera
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IGetCameraRequest} request GetCameraRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Camera>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraService#updateCamera}.
                     * @memberof palexy.streaming.v1.CameraService
                     * @typedef UpdateCameraCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Camera} [response] Camera
                     */
    
                    /**
                     * Calls UpdateCamera.
                     * @function updateCamera
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateCameraRequest} request UpdateCameraRequest message or plain object
                     * @param {palexy.streaming.v1.CameraService.UpdateCameraCallback} callback Node-style callback called with the error, if any, and Camera
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraService.prototype.updateCamera = function updateCamera(request, callback) {
                        return this.rpcCall(updateCamera, $root.palexy.streaming.v1.UpdateCameraRequest, $root.palexy.streaming.v1.Camera, request, callback);
                    }, "name", { value: "UpdateCamera" });
    
                    /**
                     * Calls UpdateCamera.
                     * @function updateCamera
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateCameraRequest} request UpdateCameraRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Camera>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraService#reprocessCameraData}.
                     * @memberof palexy.streaming.v1.CameraService
                     * @typedef ReprocessCameraDataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ReprocessCameraDataResponse} [response] ReprocessCameraDataResponse
                     */
    
                    /**
                     * Calls ReprocessCameraData.
                     * @function reprocessCameraData
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IReprocessCameraDataRequest} request ReprocessCameraDataRequest message or plain object
                     * @param {palexy.streaming.v1.CameraService.ReprocessCameraDataCallback} callback Node-style callback called with the error, if any, and ReprocessCameraDataResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraService.prototype.reprocessCameraData = function reprocessCameraData(request, callback) {
                        return this.rpcCall(reprocessCameraData, $root.palexy.streaming.v1.ReprocessCameraDataRequest, $root.palexy.streaming.v1.ReprocessCameraDataResponse, request, callback);
                    }, "name", { value: "ReprocessCameraData" });
    
                    /**
                     * Calls ReprocessCameraData.
                     * @function reprocessCameraData
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IReprocessCameraDataRequest} request ReprocessCameraDataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ReprocessCameraDataResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraService#detectCameraShift}.
                     * @memberof palexy.streaming.v1.CameraService
                     * @typedef DetectCameraShiftCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.DetectCameraShiftResponse} [response] DetectCameraShiftResponse
                     */
    
                    /**
                     * Calls DetectCameraShift.
                     * @function detectCameraShift
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IDetectCameraShiftRequest} request DetectCameraShiftRequest message or plain object
                     * @param {palexy.streaming.v1.CameraService.DetectCameraShiftCallback} callback Node-style callback called with the error, if any, and DetectCameraShiftResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraService.prototype.detectCameraShift = function detectCameraShift(request, callback) {
                        return this.rpcCall(detectCameraShift, $root.palexy.streaming.v1.DetectCameraShiftRequest, $root.palexy.streaming.v1.DetectCameraShiftResponse, request, callback);
                    }, "name", { value: "DetectCameraShift" });
    
                    /**
                     * Calls DetectCameraShift.
                     * @function detectCameraShift
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IDetectCameraShiftRequest} request DetectCameraShiftRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.DetectCameraShiftResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraService#getCameraFisheyePreview}.
                     * @memberof palexy.streaming.v1.CameraService
                     * @typedef GetCameraFisheyePreviewCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.GetCameraFisheyePreviewResponse} [response] GetCameraFisheyePreviewResponse
                     */
    
                    /**
                     * Calls GetCameraFisheyePreview.
                     * @function getCameraFisheyePreview
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IGetCameraFisheyePreviewRequest} request GetCameraFisheyePreviewRequest message or plain object
                     * @param {palexy.streaming.v1.CameraService.GetCameraFisheyePreviewCallback} callback Node-style callback called with the error, if any, and GetCameraFisheyePreviewResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraService.prototype.getCameraFisheyePreview = function getCameraFisheyePreview(request, callback) {
                        return this.rpcCall(getCameraFisheyePreview, $root.palexy.streaming.v1.GetCameraFisheyePreviewRequest, $root.palexy.streaming.v1.GetCameraFisheyePreviewResponse, request, callback);
                    }, "name", { value: "GetCameraFisheyePreview" });
    
                    /**
                     * Calls GetCameraFisheyePreview.
                     * @function getCameraFisheyePreview
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IGetCameraFisheyePreviewRequest} request GetCameraFisheyePreviewRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.GetCameraFisheyePreviewResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraService#takeSnapshot}.
                     * @memberof palexy.streaming.v1.CameraService
                     * @typedef TakeSnapshotCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.TakeSnapshotResponse} [response] TakeSnapshotResponse
                     */
    
                    /**
                     * Calls TakeSnapshot.
                     * @function takeSnapshot
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.ITakeSnapshotRequest} request TakeSnapshotRequest message or plain object
                     * @param {palexy.streaming.v1.CameraService.TakeSnapshotCallback} callback Node-style callback called with the error, if any, and TakeSnapshotResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraService.prototype.takeSnapshot = function takeSnapshot(request, callback) {
                        return this.rpcCall(takeSnapshot, $root.palexy.streaming.v1.TakeSnapshotRequest, $root.palexy.streaming.v1.TakeSnapshotResponse, request, callback);
                    }, "name", { value: "TakeSnapshot" });
    
                    /**
                     * Calls TakeSnapshot.
                     * @function takeSnapshot
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.ITakeSnapshotRequest} request TakeSnapshotRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.TakeSnapshotResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraService#uploadPhoto}.
                     * @memberof palexy.streaming.v1.CameraService
                     * @typedef UploadPhotoCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.UploadPhotoResponse} [response] UploadPhotoResponse
                     */
    
                    /**
                     * Calls UploadPhoto.
                     * @function uploadPhoto
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IUploadPhotoRequest} request UploadPhotoRequest message or plain object
                     * @param {palexy.streaming.v1.CameraService.UploadPhotoCallback} callback Node-style callback called with the error, if any, and UploadPhotoResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraService.prototype.uploadPhoto = function uploadPhoto(request, callback) {
                        return this.rpcCall(uploadPhoto, $root.palexy.streaming.v1.UploadPhotoRequest, $root.palexy.streaming.v1.UploadPhotoResponse, request, callback);
                    }, "name", { value: "UploadPhoto" });
    
                    /**
                     * Calls UploadPhoto.
                     * @function uploadPhoto
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IUploadPhotoRequest} request UploadPhotoRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.UploadPhotoResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraService#fetchCamerasReadyToScheduleDownload}.
                     * @memberof palexy.streaming.v1.CameraService
                     * @typedef FetchCamerasReadyToScheduleDownloadCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse} [response] FetchCamerasReadyToScheduleDownloadResponse
                     */
    
                    /**
                     * Calls FetchCamerasReadyToScheduleDownload.
                     * @function fetchCamerasReadyToScheduleDownload
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchCamerasReadyToScheduleDownloadRequest} request FetchCamerasReadyToScheduleDownloadRequest message or plain object
                     * @param {palexy.streaming.v1.CameraService.FetchCamerasReadyToScheduleDownloadCallback} callback Node-style callback called with the error, if any, and FetchCamerasReadyToScheduleDownloadResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraService.prototype.fetchCamerasReadyToScheduleDownload = function fetchCamerasReadyToScheduleDownload(request, callback) {
                        return this.rpcCall(fetchCamerasReadyToScheduleDownload, $root.palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest, $root.palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse, request, callback);
                    }, "name", { value: "FetchCamerasReadyToScheduleDownload" });
    
                    /**
                     * Calls FetchCamerasReadyToScheduleDownload.
                     * @function fetchCamerasReadyToScheduleDownload
                     * @memberof palexy.streaming.v1.CameraService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchCamerasReadyToScheduleDownloadRequest} request FetchCamerasReadyToScheduleDownloadRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse>} Promise
                     * @variation 2
                     */
    
                    return CameraService;
                })();
    
                v1.ReprocessCameraDataRequest = (function() {
    
                    /**
                     * Properties of a ReprocessCameraDataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IReprocessCameraDataRequest
                     * @property {string|null} [fromDate] ReprocessCameraDataRequest fromDate
                     * @property {string|null} [toDate] ReprocessCameraDataRequest toDate
                     * @property {string|null} [cameraId] ReprocessCameraDataRequest cameraId
                     * @property {number|null} [companyId] ReprocessCameraDataRequest companyId
                     * @property {string|null} [processingQueueId] ReprocessCameraDataRequest processingQueueId
                     * @property {boolean|null} [skipFeatureExtraction] ReprocessCameraDataRequest skipFeatureExtraction
                     */
    
                    /**
                     * Constructs a new ReprocessCameraDataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ReprocessCameraDataRequest.
                     * @implements IReprocessCameraDataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IReprocessCameraDataRequest=} [properties] Properties to set
                     */
                    function ReprocessCameraDataRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReprocessCameraDataRequest fromDate.
                     * @member {string} fromDate
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @instance
                     */
                    ReprocessCameraDataRequest.prototype.fromDate = "";
    
                    /**
                     * ReprocessCameraDataRequest toDate.
                     * @member {string} toDate
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @instance
                     */
                    ReprocessCameraDataRequest.prototype.toDate = "";
    
                    /**
                     * ReprocessCameraDataRequest cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @instance
                     */
                    ReprocessCameraDataRequest.prototype.cameraId = "";
    
                    /**
                     * ReprocessCameraDataRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @instance
                     */
                    ReprocessCameraDataRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ReprocessCameraDataRequest processingQueueId.
                     * @member {string} processingQueueId
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @instance
                     */
                    ReprocessCameraDataRequest.prototype.processingQueueId = "";
    
                    /**
                     * ReprocessCameraDataRequest skipFeatureExtraction.
                     * @member {boolean} skipFeatureExtraction
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @instance
                     */
                    ReprocessCameraDataRequest.prototype.skipFeatureExtraction = false;
    
                    /**
                     * Creates a new ReprocessCameraDataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @static
                     * @param {palexy.streaming.v1.IReprocessCameraDataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ReprocessCameraDataRequest} ReprocessCameraDataRequest instance
                     */
                    ReprocessCameraDataRequest.create = function create(properties) {
                        return new ReprocessCameraDataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ReprocessCameraDataRequest message. Does not implicitly {@link palexy.streaming.v1.ReprocessCameraDataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @static
                     * @param {palexy.streaming.v1.IReprocessCameraDataRequest} message ReprocessCameraDataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReprocessCameraDataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.fromDate != null && Object.hasOwnProperty.call(message, "fromDate"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.fromDate);
                        if (message.toDate != null && Object.hasOwnProperty.call(message, "toDate"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.toDate);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.cameraId);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.companyId);
                        if (message.processingQueueId != null && Object.hasOwnProperty.call(message, "processingQueueId"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.processingQueueId);
                        if (message.skipFeatureExtraction != null && Object.hasOwnProperty.call(message, "skipFeatureExtraction"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.skipFeatureExtraction);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReprocessCameraDataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ReprocessCameraDataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @static
                     * @param {palexy.streaming.v1.IReprocessCameraDataRequest} message ReprocessCameraDataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReprocessCameraDataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReprocessCameraDataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ReprocessCameraDataRequest} ReprocessCameraDataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReprocessCameraDataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ReprocessCameraDataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.fromDate = reader.string();
                                break;
                            case 2:
                                message.toDate = reader.string();
                                break;
                            case 3:
                                message.cameraId = reader.string();
                                break;
                            case 4:
                                message.companyId = reader.int64();
                                break;
                            case 5:
                                message.processingQueueId = reader.string();
                                break;
                            case 6:
                                message.skipFeatureExtraction = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReprocessCameraDataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ReprocessCameraDataRequest} ReprocessCameraDataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReprocessCameraDataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReprocessCameraDataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReprocessCameraDataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.fromDate != null && message.hasOwnProperty("fromDate"))
                            if (!$util.isString(message.fromDate))
                                return "fromDate: string expected";
                        if (message.toDate != null && message.hasOwnProperty("toDate"))
                            if (!$util.isString(message.toDate))
                                return "toDate: string expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.processingQueueId != null && message.hasOwnProperty("processingQueueId"))
                            if (!$util.isString(message.processingQueueId))
                                return "processingQueueId: string expected";
                        if (message.skipFeatureExtraction != null && message.hasOwnProperty("skipFeatureExtraction"))
                            if (typeof message.skipFeatureExtraction !== "boolean")
                                return "skipFeatureExtraction: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ReprocessCameraDataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ReprocessCameraDataRequest} ReprocessCameraDataRequest
                     */
                    ReprocessCameraDataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ReprocessCameraDataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ReprocessCameraDataRequest();
                        if (object.fromDate != null)
                            message.fromDate = String(object.fromDate);
                        if (object.toDate != null)
                            message.toDate = String(object.toDate);
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.processingQueueId != null)
                            message.processingQueueId = String(object.processingQueueId);
                        if (object.skipFeatureExtraction != null)
                            message.skipFeatureExtraction = Boolean(object.skipFeatureExtraction);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReprocessCameraDataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @static
                     * @param {palexy.streaming.v1.ReprocessCameraDataRequest} message ReprocessCameraDataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReprocessCameraDataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.fromDate = "";
                            object.toDate = "";
                            object.cameraId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.processingQueueId = "";
                            object.skipFeatureExtraction = false;
                        }
                        if (message.fromDate != null && message.hasOwnProperty("fromDate"))
                            object.fromDate = message.fromDate;
                        if (message.toDate != null && message.hasOwnProperty("toDate"))
                            object.toDate = message.toDate;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.processingQueueId != null && message.hasOwnProperty("processingQueueId"))
                            object.processingQueueId = message.processingQueueId;
                        if (message.skipFeatureExtraction != null && message.hasOwnProperty("skipFeatureExtraction"))
                            object.skipFeatureExtraction = message.skipFeatureExtraction;
                        return object;
                    };
    
                    /**
                     * Converts this ReprocessCameraDataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ReprocessCameraDataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReprocessCameraDataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ReprocessCameraDataRequest;
                })();
    
                v1.ReprocessCameraDataResponse = (function() {
    
                    /**
                     * Properties of a ReprocessCameraDataResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IReprocessCameraDataResponse
                     * @property {string|null} [message] ReprocessCameraDataResponse message
                     */
    
                    /**
                     * Constructs a new ReprocessCameraDataResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ReprocessCameraDataResponse.
                     * @implements IReprocessCameraDataResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IReprocessCameraDataResponse=} [properties] Properties to set
                     */
                    function ReprocessCameraDataResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReprocessCameraDataResponse message.
                     * @member {string} message
                     * @memberof palexy.streaming.v1.ReprocessCameraDataResponse
                     * @instance
                     */
                    ReprocessCameraDataResponse.prototype.message = "";
    
                    /**
                     * Creates a new ReprocessCameraDataResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ReprocessCameraDataResponse
                     * @static
                     * @param {palexy.streaming.v1.IReprocessCameraDataResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ReprocessCameraDataResponse} ReprocessCameraDataResponse instance
                     */
                    ReprocessCameraDataResponse.create = function create(properties) {
                        return new ReprocessCameraDataResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ReprocessCameraDataResponse message. Does not implicitly {@link palexy.streaming.v1.ReprocessCameraDataResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ReprocessCameraDataResponse
                     * @static
                     * @param {palexy.streaming.v1.IReprocessCameraDataResponse} message ReprocessCameraDataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReprocessCameraDataResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReprocessCameraDataResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ReprocessCameraDataResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ReprocessCameraDataResponse
                     * @static
                     * @param {palexy.streaming.v1.IReprocessCameraDataResponse} message ReprocessCameraDataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReprocessCameraDataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReprocessCameraDataResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ReprocessCameraDataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ReprocessCameraDataResponse} ReprocessCameraDataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReprocessCameraDataResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ReprocessCameraDataResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.message = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReprocessCameraDataResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ReprocessCameraDataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ReprocessCameraDataResponse} ReprocessCameraDataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReprocessCameraDataResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReprocessCameraDataResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ReprocessCameraDataResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReprocessCameraDataResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ReprocessCameraDataResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ReprocessCameraDataResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ReprocessCameraDataResponse} ReprocessCameraDataResponse
                     */
                    ReprocessCameraDataResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ReprocessCameraDataResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ReprocessCameraDataResponse();
                        if (object.message != null)
                            message.message = String(object.message);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReprocessCameraDataResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ReprocessCameraDataResponse
                     * @static
                     * @param {palexy.streaming.v1.ReprocessCameraDataResponse} message ReprocessCameraDataResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReprocessCameraDataResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.message = "";
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        return object;
                    };
    
                    /**
                     * Converts this ReprocessCameraDataResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ReprocessCameraDataResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReprocessCameraDataResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ReprocessCameraDataResponse;
                })();
    
                v1.UpdateCameraRequest = (function() {
    
                    /**
                     * Properties of an UpdateCameraRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateCameraRequest
                     * @property {palexy.streaming.v1.ICamera|null} [camera] UpdateCameraRequest camera
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateCameraRequest updateMask
                     * @property {string|null} [agent] UpdateCameraRequest agent
                     */
    
                    /**
                     * Constructs a new UpdateCameraRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateCameraRequest.
                     * @implements IUpdateCameraRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateCameraRequest=} [properties] Properties to set
                     */
                    function UpdateCameraRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateCameraRequest camera.
                     * @member {palexy.streaming.v1.ICamera|null|undefined} camera
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @instance
                     */
                    UpdateCameraRequest.prototype.camera = null;
    
                    /**
                     * UpdateCameraRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @instance
                     */
                    UpdateCameraRequest.prototype.updateMask = null;
    
                    /**
                     * UpdateCameraRequest agent.
                     * @member {string} agent
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @instance
                     */
                    UpdateCameraRequest.prototype.agent = "";
    
                    /**
                     * Creates a new UpdateCameraRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateCameraRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateCameraRequest} UpdateCameraRequest instance
                     */
                    UpdateCameraRequest.create = function create(properties) {
                        return new UpdateCameraRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateCameraRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateCameraRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateCameraRequest} message UpdateCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateCameraRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.camera != null && Object.hasOwnProperty.call(message, "camera"))
                            $root.palexy.streaming.v1.Camera.encode(message.camera, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.agent != null && Object.hasOwnProperty.call(message, "agent"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.agent);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateCameraRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateCameraRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateCameraRequest} message UpdateCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateCameraRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateCameraRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateCameraRequest} UpdateCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateCameraRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateCameraRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.camera = $root.palexy.streaming.v1.Camera.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.agent = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateCameraRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateCameraRequest} UpdateCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateCameraRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateCameraRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateCameraRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.camera != null && message.hasOwnProperty("camera")) {
                            var error = $root.palexy.streaming.v1.Camera.verify(message.camera);
                            if (error)
                                return "camera." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        if (message.agent != null && message.hasOwnProperty("agent"))
                            if (!$util.isString(message.agent))
                                return "agent: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpdateCameraRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateCameraRequest} UpdateCameraRequest
                     */
                    UpdateCameraRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateCameraRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateCameraRequest();
                        if (object.camera != null) {
                            if (typeof object.camera !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateCameraRequest.camera: object expected");
                            message.camera = $root.palexy.streaming.v1.Camera.fromObject(object.camera);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateCameraRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        if (object.agent != null)
                            message.agent = String(object.agent);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateCameraRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateCameraRequest} message UpdateCameraRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateCameraRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.camera = null;
                            object.updateMask = null;
                            object.agent = "";
                        }
                        if (message.camera != null && message.hasOwnProperty("camera"))
                            object.camera = $root.palexy.streaming.v1.Camera.toObject(message.camera, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        if (message.agent != null && message.hasOwnProperty("agent"))
                            object.agent = message.agent;
                        return object;
                    };
    
                    /**
                     * Converts this UpdateCameraRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateCameraRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateCameraRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateCameraRequest;
                })();
    
                v1.GetCameraRequest = (function() {
    
                    /**
                     * Properties of a GetCameraRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetCameraRequest
                     * @property {string|null} [name] GetCameraRequest name
                     * @property {boolean|null} [includeAreaData] GetCameraRequest includeAreaData
                     * @property {string|null} [versionDateTime] GetCameraRequest versionDateTime
                     * @property {boolean|null} [includeStoreData] GetCameraRequest includeStoreData
                     * @property {boolean|null} [includeCompanyConfig] GetCameraRequest includeCompanyConfig
                     * @property {boolean|null} [includeSubviews] GetCameraRequest includeSubviews
                     * @property {number|null} [cameraDatabaseId] GetCameraRequest cameraDatabaseId
                     * @property {boolean|null} [includeReferencingCameras] GetCameraRequest includeReferencingCameras
                     */
    
                    /**
                     * Constructs a new GetCameraRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetCameraRequest.
                     * @implements IGetCameraRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetCameraRequest=} [properties] Properties to set
                     */
                    function GetCameraRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetCameraRequest name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @instance
                     */
                    GetCameraRequest.prototype.name = "";
    
                    /**
                     * GetCameraRequest includeAreaData.
                     * @member {boolean} includeAreaData
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @instance
                     */
                    GetCameraRequest.prototype.includeAreaData = false;
    
                    /**
                     * GetCameraRequest versionDateTime.
                     * @member {string} versionDateTime
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @instance
                     */
                    GetCameraRequest.prototype.versionDateTime = "";
    
                    /**
                     * GetCameraRequest includeStoreData.
                     * @member {boolean} includeStoreData
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @instance
                     */
                    GetCameraRequest.prototype.includeStoreData = false;
    
                    /**
                     * GetCameraRequest includeCompanyConfig.
                     * @member {boolean} includeCompanyConfig
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @instance
                     */
                    GetCameraRequest.prototype.includeCompanyConfig = false;
    
                    /**
                     * GetCameraRequest includeSubviews.
                     * @member {boolean} includeSubviews
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @instance
                     */
                    GetCameraRequest.prototype.includeSubviews = false;
    
                    /**
                     * GetCameraRequest cameraDatabaseId.
                     * @member {number} cameraDatabaseId
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @instance
                     */
                    GetCameraRequest.prototype.cameraDatabaseId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetCameraRequest includeReferencingCameras.
                     * @member {boolean} includeReferencingCameras
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @instance
                     */
                    GetCameraRequest.prototype.includeReferencingCameras = false;
    
                    /**
                     * Creates a new GetCameraRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCameraRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetCameraRequest} GetCameraRequest instance
                     */
                    GetCameraRequest.create = function create(properties) {
                        return new GetCameraRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetCameraRequest message. Does not implicitly {@link palexy.streaming.v1.GetCameraRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCameraRequest} message GetCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCameraRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.includeAreaData != null && Object.hasOwnProperty.call(message, "includeAreaData"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeAreaData);
                        if (message.versionDateTime != null && Object.hasOwnProperty.call(message, "versionDateTime"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.versionDateTime);
                        if (message.includeStoreData != null && Object.hasOwnProperty.call(message, "includeStoreData"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeStoreData);
                        if (message.includeCompanyConfig != null && Object.hasOwnProperty.call(message, "includeCompanyConfig"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.includeCompanyConfig);
                        if (message.includeSubviews != null && Object.hasOwnProperty.call(message, "includeSubviews"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.includeSubviews);
                        if (message.cameraDatabaseId != null && Object.hasOwnProperty.call(message, "cameraDatabaseId"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.cameraDatabaseId);
                        if (message.includeReferencingCameras != null && Object.hasOwnProperty.call(message, "includeReferencingCameras"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.includeReferencingCameras);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetCameraRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetCameraRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCameraRequest} message GetCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCameraRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetCameraRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetCameraRequest} GetCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCameraRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetCameraRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.includeAreaData = reader.bool();
                                break;
                            case 3:
                                message.versionDateTime = reader.string();
                                break;
                            case 4:
                                message.includeStoreData = reader.bool();
                                break;
                            case 5:
                                message.includeCompanyConfig = reader.bool();
                                break;
                            case 6:
                                message.includeSubviews = reader.bool();
                                break;
                            case 7:
                                message.cameraDatabaseId = reader.int64();
                                break;
                            case 8:
                                message.includeReferencingCameras = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetCameraRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetCameraRequest} GetCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCameraRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetCameraRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetCameraRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.includeAreaData != null && message.hasOwnProperty("includeAreaData"))
                            if (typeof message.includeAreaData !== "boolean")
                                return "includeAreaData: boolean expected";
                        if (message.versionDateTime != null && message.hasOwnProperty("versionDateTime"))
                            if (!$util.isString(message.versionDateTime))
                                return "versionDateTime: string expected";
                        if (message.includeStoreData != null && message.hasOwnProperty("includeStoreData"))
                            if (typeof message.includeStoreData !== "boolean")
                                return "includeStoreData: boolean expected";
                        if (message.includeCompanyConfig != null && message.hasOwnProperty("includeCompanyConfig"))
                            if (typeof message.includeCompanyConfig !== "boolean")
                                return "includeCompanyConfig: boolean expected";
                        if (message.includeSubviews != null && message.hasOwnProperty("includeSubviews"))
                            if (typeof message.includeSubviews !== "boolean")
                                return "includeSubviews: boolean expected";
                        if (message.cameraDatabaseId != null && message.hasOwnProperty("cameraDatabaseId"))
                            if (!$util.isInteger(message.cameraDatabaseId) && !(message.cameraDatabaseId && $util.isInteger(message.cameraDatabaseId.low) && $util.isInteger(message.cameraDatabaseId.high)))
                                return "cameraDatabaseId: integer|Long expected";
                        if (message.includeReferencingCameras != null && message.hasOwnProperty("includeReferencingCameras"))
                            if (typeof message.includeReferencingCameras !== "boolean")
                                return "includeReferencingCameras: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetCameraRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetCameraRequest} GetCameraRequest
                     */
                    GetCameraRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetCameraRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetCameraRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.includeAreaData != null)
                            message.includeAreaData = Boolean(object.includeAreaData);
                        if (object.versionDateTime != null)
                            message.versionDateTime = String(object.versionDateTime);
                        if (object.includeStoreData != null)
                            message.includeStoreData = Boolean(object.includeStoreData);
                        if (object.includeCompanyConfig != null)
                            message.includeCompanyConfig = Boolean(object.includeCompanyConfig);
                        if (object.includeSubviews != null)
                            message.includeSubviews = Boolean(object.includeSubviews);
                        if (object.cameraDatabaseId != null)
                            if ($util.Long)
                                (message.cameraDatabaseId = $util.Long.fromValue(object.cameraDatabaseId)).unsigned = false;
                            else if (typeof object.cameraDatabaseId === "string")
                                message.cameraDatabaseId = parseInt(object.cameraDatabaseId, 10);
                            else if (typeof object.cameraDatabaseId === "number")
                                message.cameraDatabaseId = object.cameraDatabaseId;
                            else if (typeof object.cameraDatabaseId === "object")
                                message.cameraDatabaseId = new $util.LongBits(object.cameraDatabaseId.low >>> 0, object.cameraDatabaseId.high >>> 0).toNumber();
                        if (object.includeReferencingCameras != null)
                            message.includeReferencingCameras = Boolean(object.includeReferencingCameras);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetCameraRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @static
                     * @param {palexy.streaming.v1.GetCameraRequest} message GetCameraRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetCameraRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.includeAreaData = false;
                            object.versionDateTime = "";
                            object.includeStoreData = false;
                            object.includeCompanyConfig = false;
                            object.includeSubviews = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraDatabaseId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraDatabaseId = options.longs === String ? "0" : 0;
                            object.includeReferencingCameras = false;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.includeAreaData != null && message.hasOwnProperty("includeAreaData"))
                            object.includeAreaData = message.includeAreaData;
                        if (message.versionDateTime != null && message.hasOwnProperty("versionDateTime"))
                            object.versionDateTime = message.versionDateTime;
                        if (message.includeStoreData != null && message.hasOwnProperty("includeStoreData"))
                            object.includeStoreData = message.includeStoreData;
                        if (message.includeCompanyConfig != null && message.hasOwnProperty("includeCompanyConfig"))
                            object.includeCompanyConfig = message.includeCompanyConfig;
                        if (message.includeSubviews != null && message.hasOwnProperty("includeSubviews"))
                            object.includeSubviews = message.includeSubviews;
                        if (message.cameraDatabaseId != null && message.hasOwnProperty("cameraDatabaseId"))
                            if (typeof message.cameraDatabaseId === "number")
                                object.cameraDatabaseId = options.longs === String ? String(message.cameraDatabaseId) : message.cameraDatabaseId;
                            else
                                object.cameraDatabaseId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraDatabaseId) : options.longs === Number ? new $util.LongBits(message.cameraDatabaseId.low >>> 0, message.cameraDatabaseId.high >>> 0).toNumber() : message.cameraDatabaseId;
                        if (message.includeReferencingCameras != null && message.hasOwnProperty("includeReferencingCameras"))
                            object.includeReferencingCameras = message.includeReferencingCameras;
                        return object;
                    };
    
                    /**
                     * Converts this GetCameraRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetCameraRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetCameraRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetCameraRequest;
                })();
    
                v1.ListCamerasRequest = (function() {
    
                    /**
                     * Properties of a ListCamerasRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListCamerasRequest
                     * @property {number|null} [pageSize] ListCamerasRequest pageSize
                     * @property {string|null} [pageToken] ListCamerasRequest pageToken
                     * @property {palexy.streaming.v1.Camera.StreamingState|null} [streamingState] ListCamerasRequest streamingState
                     * @property {boolean|null} [uncheckedPerspective] ListCamerasRequest uncheckedPerspective
                     * @property {boolean|null} [includeNotProcessing] ListCamerasRequest includeNotProcessing
                     * @property {google.protobuf.IInt64Value|null} [storeId] ListCamerasRequest storeId
                     * @property {boolean|null} [includeDisabled] ListCamerasRequest includeDisabled
                     * @property {string|null} [streamingLocation] ListCamerasRequest streamingLocation
                     * @property {palexy.streaming.v1.Camera.RetrieveVideoType|null} [retrieveVideoType] ListCamerasRequest retrieveVideoType
                     * @property {google.protobuf.IBoolValue|null} [isReferencingCamera] ListCamerasRequest isReferencingCamera
                     */
    
                    /**
                     * Constructs a new ListCamerasRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListCamerasRequest.
                     * @implements IListCamerasRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListCamerasRequest=} [properties] Properties to set
                     */
                    function ListCamerasRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCamerasRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @instance
                     */
                    ListCamerasRequest.prototype.pageSize = 0;
    
                    /**
                     * ListCamerasRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @instance
                     */
                    ListCamerasRequest.prototype.pageToken = "";
    
                    /**
                     * ListCamerasRequest streamingState.
                     * @member {palexy.streaming.v1.Camera.StreamingState} streamingState
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @instance
                     */
                    ListCamerasRequest.prototype.streamingState = 0;
    
                    /**
                     * ListCamerasRequest uncheckedPerspective.
                     * @member {boolean} uncheckedPerspective
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @instance
                     */
                    ListCamerasRequest.prototype.uncheckedPerspective = false;
    
                    /**
                     * ListCamerasRequest includeNotProcessing.
                     * @member {boolean} includeNotProcessing
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @instance
                     */
                    ListCamerasRequest.prototype.includeNotProcessing = false;
    
                    /**
                     * ListCamerasRequest storeId.
                     * @member {google.protobuf.IInt64Value|null|undefined} storeId
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @instance
                     */
                    ListCamerasRequest.prototype.storeId = null;
    
                    /**
                     * ListCamerasRequest includeDisabled.
                     * @member {boolean} includeDisabled
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @instance
                     */
                    ListCamerasRequest.prototype.includeDisabled = false;
    
                    /**
                     * ListCamerasRequest streamingLocation.
                     * @member {string} streamingLocation
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @instance
                     */
                    ListCamerasRequest.prototype.streamingLocation = "";
    
                    /**
                     * ListCamerasRequest retrieveVideoType.
                     * @member {palexy.streaming.v1.Camera.RetrieveVideoType} retrieveVideoType
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @instance
                     */
                    ListCamerasRequest.prototype.retrieveVideoType = 0;
    
                    /**
                     * ListCamerasRequest isReferencingCamera.
                     * @member {google.protobuf.IBoolValue|null|undefined} isReferencingCamera
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @instance
                     */
                    ListCamerasRequest.prototype.isReferencingCamera = null;
    
                    /**
                     * Creates a new ListCamerasRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCamerasRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListCamerasRequest} ListCamerasRequest instance
                     */
                    ListCamerasRequest.create = function create(properties) {
                        return new ListCamerasRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListCamerasRequest message. Does not implicitly {@link palexy.streaming.v1.ListCamerasRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCamerasRequest} message ListCamerasRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCamerasRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pageToken);
                        if (message.streamingState != null && Object.hasOwnProperty.call(message, "streamingState"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.streamingState);
                        if (message.uncheckedPerspective != null && Object.hasOwnProperty.call(message, "uncheckedPerspective"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.uncheckedPerspective);
                        if (message.includeNotProcessing != null && Object.hasOwnProperty.call(message, "includeNotProcessing"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.includeNotProcessing);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            $root.google.protobuf.Int64Value.encode(message.storeId, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.includeDisabled != null && Object.hasOwnProperty.call(message, "includeDisabled"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.includeDisabled);
                        if (message.streamingLocation != null && Object.hasOwnProperty.call(message, "streamingLocation"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.streamingLocation);
                        if (message.retrieveVideoType != null && Object.hasOwnProperty.call(message, "retrieveVideoType"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.retrieveVideoType);
                        if (message.isReferencingCamera != null && Object.hasOwnProperty.call(message, "isReferencingCamera"))
                            $root.google.protobuf.BoolValue.encode(message.isReferencingCamera, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCamerasRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListCamerasRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCamerasRequest} message ListCamerasRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCamerasRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCamerasRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListCamerasRequest} ListCamerasRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCamerasRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListCamerasRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.pageSize = reader.int32();
                                break;
                            case 2:
                                message.pageToken = reader.string();
                                break;
                            case 3:
                                message.streamingState = reader.int32();
                                break;
                            case 4:
                                message.uncheckedPerspective = reader.bool();
                                break;
                            case 5:
                                message.includeNotProcessing = reader.bool();
                                break;
                            case 6:
                                message.storeId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.includeDisabled = reader.bool();
                                break;
                            case 8:
                                message.streamingLocation = reader.string();
                                break;
                            case 9:
                                message.retrieveVideoType = reader.int32();
                                break;
                            case 10:
                                message.isReferencingCamera = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCamerasRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListCamerasRequest} ListCamerasRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCamerasRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCamerasRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCamerasRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.streamingState != null && message.hasOwnProperty("streamingState"))
                            switch (message.streamingState) {
                            default:
                                return "streamingState: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.uncheckedPerspective != null && message.hasOwnProperty("uncheckedPerspective"))
                            if (typeof message.uncheckedPerspective !== "boolean")
                                return "uncheckedPerspective: boolean expected";
                        if (message.includeNotProcessing != null && message.hasOwnProperty("includeNotProcessing"))
                            if (typeof message.includeNotProcessing !== "boolean")
                                return "includeNotProcessing: boolean expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.storeId);
                            if (error)
                                return "storeId." + error;
                        }
                        if (message.includeDisabled != null && message.hasOwnProperty("includeDisabled"))
                            if (typeof message.includeDisabled !== "boolean")
                                return "includeDisabled: boolean expected";
                        if (message.streamingLocation != null && message.hasOwnProperty("streamingLocation"))
                            if (!$util.isString(message.streamingLocation))
                                return "streamingLocation: string expected";
                        if (message.retrieveVideoType != null && message.hasOwnProperty("retrieveVideoType"))
                            switch (message.retrieveVideoType) {
                            default:
                                return "retrieveVideoType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.isReferencingCamera != null && message.hasOwnProperty("isReferencingCamera")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isReferencingCamera);
                            if (error)
                                return "isReferencingCamera." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListCamerasRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListCamerasRequest} ListCamerasRequest
                     */
                    ListCamerasRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListCamerasRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListCamerasRequest();
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        switch (object.streamingState) {
                        case "UNKNOWN":
                        case 0:
                            message.streamingState = 0;
                            break;
                        case "NORMAL":
                        case 1:
                            message.streamingState = 1;
                            break;
                        case "BROKEN":
                        case 2:
                            message.streamingState = 2;
                            break;
                        }
                        if (object.uncheckedPerspective != null)
                            message.uncheckedPerspective = Boolean(object.uncheckedPerspective);
                        if (object.includeNotProcessing != null)
                            message.includeNotProcessing = Boolean(object.includeNotProcessing);
                        if (object.storeId != null) {
                            if (typeof object.storeId !== "object")
                                throw TypeError(".palexy.streaming.v1.ListCamerasRequest.storeId: object expected");
                            message.storeId = $root.google.protobuf.Int64Value.fromObject(object.storeId);
                        }
                        if (object.includeDisabled != null)
                            message.includeDisabled = Boolean(object.includeDisabled);
                        if (object.streamingLocation != null)
                            message.streamingLocation = String(object.streamingLocation);
                        switch (object.retrieveVideoType) {
                        case "RETRIEVE_VIDEO_TYPE_UNKNOWN":
                        case 0:
                            message.retrieveVideoType = 0;
                            break;
                        case "RTSP":
                        case 1:
                            message.retrieveVideoType = 1;
                            break;
                        case "DOWNLOAD":
                        case 2:
                            message.retrieveVideoType = 2;
                            break;
                        }
                        if (object.isReferencingCamera != null) {
                            if (typeof object.isReferencingCamera !== "object")
                                throw TypeError(".palexy.streaming.v1.ListCamerasRequest.isReferencingCamera: object expected");
                            message.isReferencingCamera = $root.google.protobuf.BoolValue.fromObject(object.isReferencingCamera);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCamerasRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @static
                     * @param {palexy.streaming.v1.ListCamerasRequest} message ListCamerasRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCamerasRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.streamingState = options.enums === String ? "UNKNOWN" : 0;
                            object.uncheckedPerspective = false;
                            object.includeNotProcessing = false;
                            object.storeId = null;
                            object.includeDisabled = false;
                            object.streamingLocation = "";
                            object.retrieveVideoType = options.enums === String ? "RETRIEVE_VIDEO_TYPE_UNKNOWN" : 0;
                            object.isReferencingCamera = null;
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.streamingState != null && message.hasOwnProperty("streamingState"))
                            object.streamingState = options.enums === String ? $root.palexy.streaming.v1.Camera.StreamingState[message.streamingState] : message.streamingState;
                        if (message.uncheckedPerspective != null && message.hasOwnProperty("uncheckedPerspective"))
                            object.uncheckedPerspective = message.uncheckedPerspective;
                        if (message.includeNotProcessing != null && message.hasOwnProperty("includeNotProcessing"))
                            object.includeNotProcessing = message.includeNotProcessing;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = $root.google.protobuf.Int64Value.toObject(message.storeId, options);
                        if (message.includeDisabled != null && message.hasOwnProperty("includeDisabled"))
                            object.includeDisabled = message.includeDisabled;
                        if (message.streamingLocation != null && message.hasOwnProperty("streamingLocation"))
                            object.streamingLocation = message.streamingLocation;
                        if (message.retrieveVideoType != null && message.hasOwnProperty("retrieveVideoType"))
                            object.retrieveVideoType = options.enums === String ? $root.palexy.streaming.v1.Camera.RetrieveVideoType[message.retrieveVideoType] : message.retrieveVideoType;
                        if (message.isReferencingCamera != null && message.hasOwnProperty("isReferencingCamera"))
                            object.isReferencingCamera = $root.google.protobuf.BoolValue.toObject(message.isReferencingCamera, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListCamerasRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListCamerasRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCamerasRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCamerasRequest;
                })();
    
                v1.ListCamerasResponse = (function() {
    
                    /**
                     * Properties of a ListCamerasResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListCamerasResponse
                     * @property {Array.<palexy.streaming.v1.ICamera>|null} [cameras] ListCamerasResponse cameras
                     * @property {string|null} [nextPageToken] ListCamerasResponse nextPageToken
                     * @property {number|null} [totalElements] ListCamerasResponse totalElements
                     */
    
                    /**
                     * Constructs a new ListCamerasResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListCamerasResponse.
                     * @implements IListCamerasResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListCamerasResponse=} [properties] Properties to set
                     */
                    function ListCamerasResponse(properties) {
                        this.cameras = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCamerasResponse cameras.
                     * @member {Array.<palexy.streaming.v1.ICamera>} cameras
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @instance
                     */
                    ListCamerasResponse.prototype.cameras = $util.emptyArray;
    
                    /**
                     * ListCamerasResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @instance
                     */
                    ListCamerasResponse.prototype.nextPageToken = "";
    
                    /**
                     * ListCamerasResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @instance
                     */
                    ListCamerasResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListCamerasResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCamerasResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListCamerasResponse} ListCamerasResponse instance
                     */
                    ListCamerasResponse.create = function create(properties) {
                        return new ListCamerasResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListCamerasResponse message. Does not implicitly {@link palexy.streaming.v1.ListCamerasResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCamerasResponse} message ListCamerasResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCamerasResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameras != null && message.cameras.length)
                            for (var i = 0; i < message.cameras.length; ++i)
                                $root.palexy.streaming.v1.Camera.encode(message.cameras[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCamerasResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListCamerasResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCamerasResponse} message ListCamerasResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCamerasResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCamerasResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListCamerasResponse} ListCamerasResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCamerasResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListCamerasResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.cameras && message.cameras.length))
                                    message.cameras = [];
                                message.cameras.push($root.palexy.streaming.v1.Camera.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            case 3:
                                message.totalElements = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCamerasResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListCamerasResponse} ListCamerasResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCamerasResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCamerasResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCamerasResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameras != null && message.hasOwnProperty("cameras")) {
                            if (!Array.isArray(message.cameras))
                                return "cameras: array expected";
                            for (var i = 0; i < message.cameras.length; ++i) {
                                var error = $root.palexy.streaming.v1.Camera.verify(message.cameras[i]);
                                if (error)
                                    return "cameras." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListCamerasResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListCamerasResponse} ListCamerasResponse
                     */
                    ListCamerasResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListCamerasResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListCamerasResponse();
                        if (object.cameras) {
                            if (!Array.isArray(object.cameras))
                                throw TypeError(".palexy.streaming.v1.ListCamerasResponse.cameras: array expected");
                            message.cameras = [];
                            for (var i = 0; i < object.cameras.length; ++i) {
                                if (typeof object.cameras[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListCamerasResponse.cameras: object expected");
                                message.cameras[i] = $root.palexy.streaming.v1.Camera.fromObject(object.cameras[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCamerasResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @static
                     * @param {palexy.streaming.v1.ListCamerasResponse} message ListCamerasResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCamerasResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.cameras = [];
                        if (options.defaults) {
                            object.nextPageToken = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                        }
                        if (message.cameras && message.cameras.length) {
                            object.cameras = [];
                            for (var j = 0; j < message.cameras.length; ++j)
                                object.cameras[j] = $root.palexy.streaming.v1.Camera.toObject(message.cameras[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        return object;
                    };
    
                    /**
                     * Converts this ListCamerasResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListCamerasResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCamerasResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCamerasResponse;
                })();
    
                v1.GetCameraFisheyePreviewRequest = (function() {
    
                    /**
                     * Properties of a GetCameraFisheyePreviewRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetCameraFisheyePreviewRequest
                     * @property {number|null} [cameraId] GetCameraFisheyePreviewRequest cameraId
                     * @property {number|null} [photoId] GetCameraFisheyePreviewRequest photoId
                     * @property {palexy.streaming.v1.IWarpOption|null} [warpOption] GetCameraFisheyePreviewRequest warpOption
                     */
    
                    /**
                     * Constructs a new GetCameraFisheyePreviewRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetCameraFisheyePreviewRequest.
                     * @implements IGetCameraFisheyePreviewRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetCameraFisheyePreviewRequest=} [properties] Properties to set
                     */
                    function GetCameraFisheyePreviewRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetCameraFisheyePreviewRequest cameraId.
                     * @member {number} cameraId
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @instance
                     */
                    GetCameraFisheyePreviewRequest.prototype.cameraId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetCameraFisheyePreviewRequest photoId.
                     * @member {number} photoId
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @instance
                     */
                    GetCameraFisheyePreviewRequest.prototype.photoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetCameraFisheyePreviewRequest warpOption.
                     * @member {palexy.streaming.v1.IWarpOption|null|undefined} warpOption
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @instance
                     */
                    GetCameraFisheyePreviewRequest.prototype.warpOption = null;
    
                    /**
                     * Creates a new GetCameraFisheyePreviewRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCameraFisheyePreviewRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetCameraFisheyePreviewRequest} GetCameraFisheyePreviewRequest instance
                     */
                    GetCameraFisheyePreviewRequest.create = function create(properties) {
                        return new GetCameraFisheyePreviewRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetCameraFisheyePreviewRequest message. Does not implicitly {@link palexy.streaming.v1.GetCameraFisheyePreviewRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCameraFisheyePreviewRequest} message GetCameraFisheyePreviewRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCameraFisheyePreviewRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.cameraId);
                        if (message.photoId != null && Object.hasOwnProperty.call(message, "photoId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.photoId);
                        if (message.warpOption != null && Object.hasOwnProperty.call(message, "warpOption"))
                            $root.palexy.streaming.v1.WarpOption.encode(message.warpOption, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetCameraFisheyePreviewRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetCameraFisheyePreviewRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCameraFisheyePreviewRequest} message GetCameraFisheyePreviewRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCameraFisheyePreviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetCameraFisheyePreviewRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetCameraFisheyePreviewRequest} GetCameraFisheyePreviewRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCameraFisheyePreviewRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetCameraFisheyePreviewRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraId = reader.int64();
                                break;
                            case 2:
                                message.photoId = reader.int64();
                                break;
                            case 3:
                                message.warpOption = $root.palexy.streaming.v1.WarpOption.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetCameraFisheyePreviewRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetCameraFisheyePreviewRequest} GetCameraFisheyePreviewRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCameraFisheyePreviewRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetCameraFisheyePreviewRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetCameraFisheyePreviewRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isInteger(message.cameraId) && !(message.cameraId && $util.isInteger(message.cameraId.low) && $util.isInteger(message.cameraId.high)))
                                return "cameraId: integer|Long expected";
                        if (message.photoId != null && message.hasOwnProperty("photoId"))
                            if (!$util.isInteger(message.photoId) && !(message.photoId && $util.isInteger(message.photoId.low) && $util.isInteger(message.photoId.high)))
                                return "photoId: integer|Long expected";
                        if (message.warpOption != null && message.hasOwnProperty("warpOption")) {
                            var error = $root.palexy.streaming.v1.WarpOption.verify(message.warpOption);
                            if (error)
                                return "warpOption." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetCameraFisheyePreviewRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetCameraFisheyePreviewRequest} GetCameraFisheyePreviewRequest
                     */
                    GetCameraFisheyePreviewRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetCameraFisheyePreviewRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetCameraFisheyePreviewRequest();
                        if (object.cameraId != null)
                            if ($util.Long)
                                (message.cameraId = $util.Long.fromValue(object.cameraId)).unsigned = false;
                            else if (typeof object.cameraId === "string")
                                message.cameraId = parseInt(object.cameraId, 10);
                            else if (typeof object.cameraId === "number")
                                message.cameraId = object.cameraId;
                            else if (typeof object.cameraId === "object")
                                message.cameraId = new $util.LongBits(object.cameraId.low >>> 0, object.cameraId.high >>> 0).toNumber();
                        if (object.photoId != null)
                            if ($util.Long)
                                (message.photoId = $util.Long.fromValue(object.photoId)).unsigned = false;
                            else if (typeof object.photoId === "string")
                                message.photoId = parseInt(object.photoId, 10);
                            else if (typeof object.photoId === "number")
                                message.photoId = object.photoId;
                            else if (typeof object.photoId === "object")
                                message.photoId = new $util.LongBits(object.photoId.low >>> 0, object.photoId.high >>> 0).toNumber();
                        if (object.warpOption != null) {
                            if (typeof object.warpOption !== "object")
                                throw TypeError(".palexy.streaming.v1.GetCameraFisheyePreviewRequest.warpOption: object expected");
                            message.warpOption = $root.palexy.streaming.v1.WarpOption.fromObject(object.warpOption);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetCameraFisheyePreviewRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @static
                     * @param {palexy.streaming.v1.GetCameraFisheyePreviewRequest} message GetCameraFisheyePreviewRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetCameraFisheyePreviewRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.photoId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.photoId = options.longs === String ? "0" : 0;
                            object.warpOption = null;
                        }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (typeof message.cameraId === "number")
                                object.cameraId = options.longs === String ? String(message.cameraId) : message.cameraId;
                            else
                                object.cameraId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraId) : options.longs === Number ? new $util.LongBits(message.cameraId.low >>> 0, message.cameraId.high >>> 0).toNumber() : message.cameraId;
                        if (message.photoId != null && message.hasOwnProperty("photoId"))
                            if (typeof message.photoId === "number")
                                object.photoId = options.longs === String ? String(message.photoId) : message.photoId;
                            else
                                object.photoId = options.longs === String ? $util.Long.prototype.toString.call(message.photoId) : options.longs === Number ? new $util.LongBits(message.photoId.low >>> 0, message.photoId.high >>> 0).toNumber() : message.photoId;
                        if (message.warpOption != null && message.hasOwnProperty("warpOption"))
                            object.warpOption = $root.palexy.streaming.v1.WarpOption.toObject(message.warpOption, options);
                        return object;
                    };
    
                    /**
                     * Converts this GetCameraFisheyePreviewRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetCameraFisheyePreviewRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetCameraFisheyePreviewRequest;
                })();
    
                v1.GetCameraFisheyePreviewResponse = (function() {
    
                    /**
                     * Properties of a GetCameraFisheyePreviewResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IGetCameraFisheyePreviewResponse
                     * @property {palexy.streaming.v1.ICameraPhoto|null} [dewarpedImage] GetCameraFisheyePreviewResponse dewarpedImage
                     * @property {palexy.streaming.v1.ICameraPhoto|null} [previewImage] GetCameraFisheyePreviewResponse previewImage
                     */
    
                    /**
                     * Constructs a new GetCameraFisheyePreviewResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetCameraFisheyePreviewResponse.
                     * @implements IGetCameraFisheyePreviewResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IGetCameraFisheyePreviewResponse=} [properties] Properties to set
                     */
                    function GetCameraFisheyePreviewResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetCameraFisheyePreviewResponse dewarpedImage.
                     * @member {palexy.streaming.v1.ICameraPhoto|null|undefined} dewarpedImage
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewResponse
                     * @instance
                     */
                    GetCameraFisheyePreviewResponse.prototype.dewarpedImage = null;
    
                    /**
                     * GetCameraFisheyePreviewResponse previewImage.
                     * @member {palexy.streaming.v1.ICameraPhoto|null|undefined} previewImage
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewResponse
                     * @instance
                     */
                    GetCameraFisheyePreviewResponse.prototype.previewImage = null;
    
                    /**
                     * Creates a new GetCameraFisheyePreviewResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetCameraFisheyePreviewResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetCameraFisheyePreviewResponse} GetCameraFisheyePreviewResponse instance
                     */
                    GetCameraFisheyePreviewResponse.create = function create(properties) {
                        return new GetCameraFisheyePreviewResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetCameraFisheyePreviewResponse message. Does not implicitly {@link palexy.streaming.v1.GetCameraFisheyePreviewResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetCameraFisheyePreviewResponse} message GetCameraFisheyePreviewResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCameraFisheyePreviewResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dewarpedImage != null && Object.hasOwnProperty.call(message, "dewarpedImage"))
                            $root.palexy.streaming.v1.CameraPhoto.encode(message.dewarpedImage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.previewImage != null && Object.hasOwnProperty.call(message, "previewImage"))
                            $root.palexy.streaming.v1.CameraPhoto.encode(message.previewImage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetCameraFisheyePreviewResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetCameraFisheyePreviewResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetCameraFisheyePreviewResponse} message GetCameraFisheyePreviewResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCameraFisheyePreviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetCameraFisheyePreviewResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetCameraFisheyePreviewResponse} GetCameraFisheyePreviewResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCameraFisheyePreviewResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetCameraFisheyePreviewResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dewarpedImage = $root.palexy.streaming.v1.CameraPhoto.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.previewImage = $root.palexy.streaming.v1.CameraPhoto.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetCameraFisheyePreviewResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetCameraFisheyePreviewResponse} GetCameraFisheyePreviewResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCameraFisheyePreviewResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetCameraFisheyePreviewResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetCameraFisheyePreviewResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dewarpedImage != null && message.hasOwnProperty("dewarpedImage")) {
                            var error = $root.palexy.streaming.v1.CameraPhoto.verify(message.dewarpedImage);
                            if (error)
                                return "dewarpedImage." + error;
                        }
                        if (message.previewImage != null && message.hasOwnProperty("previewImage")) {
                            var error = $root.palexy.streaming.v1.CameraPhoto.verify(message.previewImage);
                            if (error)
                                return "previewImage." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetCameraFisheyePreviewResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetCameraFisheyePreviewResponse} GetCameraFisheyePreviewResponse
                     */
                    GetCameraFisheyePreviewResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetCameraFisheyePreviewResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetCameraFisheyePreviewResponse();
                        if (object.dewarpedImage != null) {
                            if (typeof object.dewarpedImage !== "object")
                                throw TypeError(".palexy.streaming.v1.GetCameraFisheyePreviewResponse.dewarpedImage: object expected");
                            message.dewarpedImage = $root.palexy.streaming.v1.CameraPhoto.fromObject(object.dewarpedImage);
                        }
                        if (object.previewImage != null) {
                            if (typeof object.previewImage !== "object")
                                throw TypeError(".palexy.streaming.v1.GetCameraFisheyePreviewResponse.previewImage: object expected");
                            message.previewImage = $root.palexy.streaming.v1.CameraPhoto.fromObject(object.previewImage);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetCameraFisheyePreviewResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewResponse
                     * @static
                     * @param {palexy.streaming.v1.GetCameraFisheyePreviewResponse} message GetCameraFisheyePreviewResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetCameraFisheyePreviewResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.dewarpedImage = null;
                            object.previewImage = null;
                        }
                        if (message.dewarpedImage != null && message.hasOwnProperty("dewarpedImage"))
                            object.dewarpedImage = $root.palexy.streaming.v1.CameraPhoto.toObject(message.dewarpedImage, options);
                        if (message.previewImage != null && message.hasOwnProperty("previewImage"))
                            object.previewImage = $root.palexy.streaming.v1.CameraPhoto.toObject(message.previewImage, options);
                        return object;
                    };
    
                    /**
                     * Converts this GetCameraFisheyePreviewResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetCameraFisheyePreviewResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetCameraFisheyePreviewResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetCameraFisheyePreviewResponse;
                })();
    
                v1.TakeSnapshotRequest = (function() {
    
                    /**
                     * Properties of a TakeSnapshotRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ITakeSnapshotRequest
                     * @property {number|null} [cameraId] TakeSnapshotRequest cameraId
                     * @property {string|null} [type] TakeSnapshotRequest type
                     * @property {string|null} [ext] TakeSnapshotRequest ext
                     * @property {boolean|null} [skipProcessing] TakeSnapshotRequest skipProcessing
                     */
    
                    /**
                     * Constructs a new TakeSnapshotRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a TakeSnapshotRequest.
                     * @implements ITakeSnapshotRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ITakeSnapshotRequest=} [properties] Properties to set
                     */
                    function TakeSnapshotRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TakeSnapshotRequest cameraId.
                     * @member {number} cameraId
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @instance
                     */
                    TakeSnapshotRequest.prototype.cameraId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * TakeSnapshotRequest type.
                     * @member {string} type
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @instance
                     */
                    TakeSnapshotRequest.prototype.type = "";
    
                    /**
                     * TakeSnapshotRequest ext.
                     * @member {string} ext
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @instance
                     */
                    TakeSnapshotRequest.prototype.ext = "";
    
                    /**
                     * TakeSnapshotRequest skipProcessing.
                     * @member {boolean} skipProcessing
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @instance
                     */
                    TakeSnapshotRequest.prototype.skipProcessing = false;
    
                    /**
                     * Creates a new TakeSnapshotRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.ITakeSnapshotRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.TakeSnapshotRequest} TakeSnapshotRequest instance
                     */
                    TakeSnapshotRequest.create = function create(properties) {
                        return new TakeSnapshotRequest(properties);
                    };
    
                    /**
                     * Encodes the specified TakeSnapshotRequest message. Does not implicitly {@link palexy.streaming.v1.TakeSnapshotRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.ITakeSnapshotRequest} message TakeSnapshotRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TakeSnapshotRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.cameraId);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
                        if (message.ext != null && Object.hasOwnProperty.call(message, "ext"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.ext);
                        if (message.skipProcessing != null && Object.hasOwnProperty.call(message, "skipProcessing"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.skipProcessing);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TakeSnapshotRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.TakeSnapshotRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.ITakeSnapshotRequest} message TakeSnapshotRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TakeSnapshotRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TakeSnapshotRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.TakeSnapshotRequest} TakeSnapshotRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TakeSnapshotRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.TakeSnapshotRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraId = reader.int64();
                                break;
                            case 2:
                                message.type = reader.string();
                                break;
                            case 3:
                                message.ext = reader.string();
                                break;
                            case 4:
                                message.skipProcessing = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TakeSnapshotRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.TakeSnapshotRequest} TakeSnapshotRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TakeSnapshotRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TakeSnapshotRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TakeSnapshotRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isInteger(message.cameraId) && !(message.cameraId && $util.isInteger(message.cameraId.low) && $util.isInteger(message.cameraId.high)))
                                return "cameraId: integer|Long expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            if (!$util.isString(message.type))
                                return "type: string expected";
                        if (message.ext != null && message.hasOwnProperty("ext"))
                            if (!$util.isString(message.ext))
                                return "ext: string expected";
                        if (message.skipProcessing != null && message.hasOwnProperty("skipProcessing"))
                            if (typeof message.skipProcessing !== "boolean")
                                return "skipProcessing: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a TakeSnapshotRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.TakeSnapshotRequest} TakeSnapshotRequest
                     */
                    TakeSnapshotRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.TakeSnapshotRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.TakeSnapshotRequest();
                        if (object.cameraId != null)
                            if ($util.Long)
                                (message.cameraId = $util.Long.fromValue(object.cameraId)).unsigned = false;
                            else if (typeof object.cameraId === "string")
                                message.cameraId = parseInt(object.cameraId, 10);
                            else if (typeof object.cameraId === "number")
                                message.cameraId = object.cameraId;
                            else if (typeof object.cameraId === "object")
                                message.cameraId = new $util.LongBits(object.cameraId.low >>> 0, object.cameraId.high >>> 0).toNumber();
                        if (object.type != null)
                            message.type = String(object.type);
                        if (object.ext != null)
                            message.ext = String(object.ext);
                        if (object.skipProcessing != null)
                            message.skipProcessing = Boolean(object.skipProcessing);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TakeSnapshotRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.TakeSnapshotRequest} message TakeSnapshotRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TakeSnapshotRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraId = options.longs === String ? "0" : 0;
                            object.type = "";
                            object.ext = "";
                            object.skipProcessing = false;
                        }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (typeof message.cameraId === "number")
                                object.cameraId = options.longs === String ? String(message.cameraId) : message.cameraId;
                            else
                                object.cameraId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraId) : options.longs === Number ? new $util.LongBits(message.cameraId.low >>> 0, message.cameraId.high >>> 0).toNumber() : message.cameraId;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = message.type;
                        if (message.ext != null && message.hasOwnProperty("ext"))
                            object.ext = message.ext;
                        if (message.skipProcessing != null && message.hasOwnProperty("skipProcessing"))
                            object.skipProcessing = message.skipProcessing;
                        return object;
                    };
    
                    /**
                     * Converts this TakeSnapshotRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.TakeSnapshotRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TakeSnapshotRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TakeSnapshotRequest;
                })();
    
                v1.TakeSnapshotResponse = (function() {
    
                    /**
                     * Properties of a TakeSnapshotResponse.
                     * @memberof palexy.streaming.v1
                     * @interface ITakeSnapshotResponse
                     * @property {palexy.streaming.v1.ICameraPhoto|null} [cameraPhoto] TakeSnapshotResponse cameraPhoto
                     */
    
                    /**
                     * Constructs a new TakeSnapshotResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a TakeSnapshotResponse.
                     * @implements ITakeSnapshotResponse
                     * @constructor
                     * @param {palexy.streaming.v1.ITakeSnapshotResponse=} [properties] Properties to set
                     */
                    function TakeSnapshotResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TakeSnapshotResponse cameraPhoto.
                     * @member {palexy.streaming.v1.ICameraPhoto|null|undefined} cameraPhoto
                     * @memberof palexy.streaming.v1.TakeSnapshotResponse
                     * @instance
                     */
                    TakeSnapshotResponse.prototype.cameraPhoto = null;
    
                    /**
                     * Creates a new TakeSnapshotResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.TakeSnapshotResponse
                     * @static
                     * @param {palexy.streaming.v1.ITakeSnapshotResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.TakeSnapshotResponse} TakeSnapshotResponse instance
                     */
                    TakeSnapshotResponse.create = function create(properties) {
                        return new TakeSnapshotResponse(properties);
                    };
    
                    /**
                     * Encodes the specified TakeSnapshotResponse message. Does not implicitly {@link palexy.streaming.v1.TakeSnapshotResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.TakeSnapshotResponse
                     * @static
                     * @param {palexy.streaming.v1.ITakeSnapshotResponse} message TakeSnapshotResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TakeSnapshotResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraPhoto != null && Object.hasOwnProperty.call(message, "cameraPhoto"))
                            $root.palexy.streaming.v1.CameraPhoto.encode(message.cameraPhoto, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TakeSnapshotResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.TakeSnapshotResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.TakeSnapshotResponse
                     * @static
                     * @param {palexy.streaming.v1.ITakeSnapshotResponse} message TakeSnapshotResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TakeSnapshotResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TakeSnapshotResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.TakeSnapshotResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.TakeSnapshotResponse} TakeSnapshotResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TakeSnapshotResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.TakeSnapshotResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraPhoto = $root.palexy.streaming.v1.CameraPhoto.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TakeSnapshotResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.TakeSnapshotResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.TakeSnapshotResponse} TakeSnapshotResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TakeSnapshotResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TakeSnapshotResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.TakeSnapshotResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TakeSnapshotResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraPhoto != null && message.hasOwnProperty("cameraPhoto")) {
                            var error = $root.palexy.streaming.v1.CameraPhoto.verify(message.cameraPhoto);
                            if (error)
                                return "cameraPhoto." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a TakeSnapshotResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.TakeSnapshotResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.TakeSnapshotResponse} TakeSnapshotResponse
                     */
                    TakeSnapshotResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.TakeSnapshotResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.TakeSnapshotResponse();
                        if (object.cameraPhoto != null) {
                            if (typeof object.cameraPhoto !== "object")
                                throw TypeError(".palexy.streaming.v1.TakeSnapshotResponse.cameraPhoto: object expected");
                            message.cameraPhoto = $root.palexy.streaming.v1.CameraPhoto.fromObject(object.cameraPhoto);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TakeSnapshotResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.TakeSnapshotResponse
                     * @static
                     * @param {palexy.streaming.v1.TakeSnapshotResponse} message TakeSnapshotResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TakeSnapshotResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.cameraPhoto = null;
                        if (message.cameraPhoto != null && message.hasOwnProperty("cameraPhoto"))
                            object.cameraPhoto = $root.palexy.streaming.v1.CameraPhoto.toObject(message.cameraPhoto, options);
                        return object;
                    };
    
                    /**
                     * Converts this TakeSnapshotResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.TakeSnapshotResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TakeSnapshotResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TakeSnapshotResponse;
                })();
    
                v1.UploadPhotoRequest = (function() {
    
                    /**
                     * Properties of an UploadPhotoRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUploadPhotoRequest
                     * @property {palexy.streaming.v1.ICameraPhoto|null} [cameraPhoto] UploadPhotoRequest cameraPhoto
                     */
    
                    /**
                     * Constructs a new UploadPhotoRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UploadPhotoRequest.
                     * @implements IUploadPhotoRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUploadPhotoRequest=} [properties] Properties to set
                     */
                    function UploadPhotoRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UploadPhotoRequest cameraPhoto.
                     * @member {palexy.streaming.v1.ICameraPhoto|null|undefined} cameraPhoto
                     * @memberof palexy.streaming.v1.UploadPhotoRequest
                     * @instance
                     */
                    UploadPhotoRequest.prototype.cameraPhoto = null;
    
                    /**
                     * Creates a new UploadPhotoRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UploadPhotoRequest
                     * @static
                     * @param {palexy.streaming.v1.IUploadPhotoRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UploadPhotoRequest} UploadPhotoRequest instance
                     */
                    UploadPhotoRequest.create = function create(properties) {
                        return new UploadPhotoRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UploadPhotoRequest message. Does not implicitly {@link palexy.streaming.v1.UploadPhotoRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UploadPhotoRequest
                     * @static
                     * @param {palexy.streaming.v1.IUploadPhotoRequest} message UploadPhotoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UploadPhotoRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraPhoto != null && Object.hasOwnProperty.call(message, "cameraPhoto"))
                            $root.palexy.streaming.v1.CameraPhoto.encode(message.cameraPhoto, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UploadPhotoRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UploadPhotoRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UploadPhotoRequest
                     * @static
                     * @param {palexy.streaming.v1.IUploadPhotoRequest} message UploadPhotoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UploadPhotoRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UploadPhotoRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UploadPhotoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UploadPhotoRequest} UploadPhotoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UploadPhotoRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UploadPhotoRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraPhoto = $root.palexy.streaming.v1.CameraPhoto.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UploadPhotoRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UploadPhotoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UploadPhotoRequest} UploadPhotoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UploadPhotoRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UploadPhotoRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UploadPhotoRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UploadPhotoRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraPhoto != null && message.hasOwnProperty("cameraPhoto")) {
                            var error = $root.palexy.streaming.v1.CameraPhoto.verify(message.cameraPhoto);
                            if (error)
                                return "cameraPhoto." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UploadPhotoRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UploadPhotoRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UploadPhotoRequest} UploadPhotoRequest
                     */
                    UploadPhotoRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UploadPhotoRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UploadPhotoRequest();
                        if (object.cameraPhoto != null) {
                            if (typeof object.cameraPhoto !== "object")
                                throw TypeError(".palexy.streaming.v1.UploadPhotoRequest.cameraPhoto: object expected");
                            message.cameraPhoto = $root.palexy.streaming.v1.CameraPhoto.fromObject(object.cameraPhoto);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UploadPhotoRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UploadPhotoRequest
                     * @static
                     * @param {palexy.streaming.v1.UploadPhotoRequest} message UploadPhotoRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UploadPhotoRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.cameraPhoto = null;
                        if (message.cameraPhoto != null && message.hasOwnProperty("cameraPhoto"))
                            object.cameraPhoto = $root.palexy.streaming.v1.CameraPhoto.toObject(message.cameraPhoto, options);
                        return object;
                    };
    
                    /**
                     * Converts this UploadPhotoRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UploadPhotoRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UploadPhotoRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UploadPhotoRequest;
                })();
    
                v1.UploadPhotoResponse = (function() {
    
                    /**
                     * Properties of an UploadPhotoResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IUploadPhotoResponse
                     * @property {palexy.streaming.v1.ICameraPhoto|null} [cameraPhoto] UploadPhotoResponse cameraPhoto
                     */
    
                    /**
                     * Constructs a new UploadPhotoResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UploadPhotoResponse.
                     * @implements IUploadPhotoResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IUploadPhotoResponse=} [properties] Properties to set
                     */
                    function UploadPhotoResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UploadPhotoResponse cameraPhoto.
                     * @member {palexy.streaming.v1.ICameraPhoto|null|undefined} cameraPhoto
                     * @memberof palexy.streaming.v1.UploadPhotoResponse
                     * @instance
                     */
                    UploadPhotoResponse.prototype.cameraPhoto = null;
    
                    /**
                     * Creates a new UploadPhotoResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UploadPhotoResponse
                     * @static
                     * @param {palexy.streaming.v1.IUploadPhotoResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UploadPhotoResponse} UploadPhotoResponse instance
                     */
                    UploadPhotoResponse.create = function create(properties) {
                        return new UploadPhotoResponse(properties);
                    };
    
                    /**
                     * Encodes the specified UploadPhotoResponse message. Does not implicitly {@link palexy.streaming.v1.UploadPhotoResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UploadPhotoResponse
                     * @static
                     * @param {palexy.streaming.v1.IUploadPhotoResponse} message UploadPhotoResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UploadPhotoResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraPhoto != null && Object.hasOwnProperty.call(message, "cameraPhoto"))
                            $root.palexy.streaming.v1.CameraPhoto.encode(message.cameraPhoto, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UploadPhotoResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.UploadPhotoResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UploadPhotoResponse
                     * @static
                     * @param {palexy.streaming.v1.IUploadPhotoResponse} message UploadPhotoResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UploadPhotoResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UploadPhotoResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UploadPhotoResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UploadPhotoResponse} UploadPhotoResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UploadPhotoResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UploadPhotoResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraPhoto = $root.palexy.streaming.v1.CameraPhoto.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UploadPhotoResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UploadPhotoResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UploadPhotoResponse} UploadPhotoResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UploadPhotoResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UploadPhotoResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UploadPhotoResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UploadPhotoResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraPhoto != null && message.hasOwnProperty("cameraPhoto")) {
                            var error = $root.palexy.streaming.v1.CameraPhoto.verify(message.cameraPhoto);
                            if (error)
                                return "cameraPhoto." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UploadPhotoResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UploadPhotoResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UploadPhotoResponse} UploadPhotoResponse
                     */
                    UploadPhotoResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UploadPhotoResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.UploadPhotoResponse();
                        if (object.cameraPhoto != null) {
                            if (typeof object.cameraPhoto !== "object")
                                throw TypeError(".palexy.streaming.v1.UploadPhotoResponse.cameraPhoto: object expected");
                            message.cameraPhoto = $root.palexy.streaming.v1.CameraPhoto.fromObject(object.cameraPhoto);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UploadPhotoResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UploadPhotoResponse
                     * @static
                     * @param {palexy.streaming.v1.UploadPhotoResponse} message UploadPhotoResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UploadPhotoResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.cameraPhoto = null;
                        if (message.cameraPhoto != null && message.hasOwnProperty("cameraPhoto"))
                            object.cameraPhoto = $root.palexy.streaming.v1.CameraPhoto.toObject(message.cameraPhoto, options);
                        return object;
                    };
    
                    /**
                     * Converts this UploadPhotoResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UploadPhotoResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UploadPhotoResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UploadPhotoResponse;
                })();
    
                v1.FetchCamerasReadyToScheduleDownloadRequest = (function() {
    
                    /**
                     * Properties of a FetchCamerasReadyToScheduleDownloadRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchCamerasReadyToScheduleDownloadRequest
                     * @property {string|null} [dateId] FetchCamerasReadyToScheduleDownloadRequest dateId
                     * @property {string|null} [timezone] FetchCamerasReadyToScheduleDownloadRequest timezone
                     */
    
                    /**
                     * Constructs a new FetchCamerasReadyToScheduleDownloadRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchCamerasReadyToScheduleDownloadRequest.
                     * @implements IFetchCamerasReadyToScheduleDownloadRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchCamerasReadyToScheduleDownloadRequest=} [properties] Properties to set
                     */
                    function FetchCamerasReadyToScheduleDownloadRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchCamerasReadyToScheduleDownloadRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest
                     * @instance
                     */
                    FetchCamerasReadyToScheduleDownloadRequest.prototype.dateId = "";
    
                    /**
                     * FetchCamerasReadyToScheduleDownloadRequest timezone.
                     * @member {string} timezone
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest
                     * @instance
                     */
                    FetchCamerasReadyToScheduleDownloadRequest.prototype.timezone = "";
    
                    /**
                     * Creates a new FetchCamerasReadyToScheduleDownloadRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchCamerasReadyToScheduleDownloadRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest} FetchCamerasReadyToScheduleDownloadRequest instance
                     */
                    FetchCamerasReadyToScheduleDownloadRequest.create = function create(properties) {
                        return new FetchCamerasReadyToScheduleDownloadRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FetchCamerasReadyToScheduleDownloadRequest message. Does not implicitly {@link palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchCamerasReadyToScheduleDownloadRequest} message FetchCamerasReadyToScheduleDownloadRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchCamerasReadyToScheduleDownloadRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.dateId);
                        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.timezone);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchCamerasReadyToScheduleDownloadRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchCamerasReadyToScheduleDownloadRequest} message FetchCamerasReadyToScheduleDownloadRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchCamerasReadyToScheduleDownloadRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchCamerasReadyToScheduleDownloadRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest} FetchCamerasReadyToScheduleDownloadRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchCamerasReadyToScheduleDownloadRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dateId = reader.string();
                                break;
                            case 2:
                                message.timezone = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchCamerasReadyToScheduleDownloadRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest} FetchCamerasReadyToScheduleDownloadRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchCamerasReadyToScheduleDownloadRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchCamerasReadyToScheduleDownloadRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchCamerasReadyToScheduleDownloadRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            if (!$util.isString(message.timezone))
                                return "timezone: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a FetchCamerasReadyToScheduleDownloadRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest} FetchCamerasReadyToScheduleDownloadRequest
                     */
                    FetchCamerasReadyToScheduleDownloadRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.timezone != null)
                            message.timezone = String(object.timezone);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchCamerasReadyToScheduleDownloadRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest
                     * @static
                     * @param {palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest} message FetchCamerasReadyToScheduleDownloadRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchCamerasReadyToScheduleDownloadRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.dateId = "";
                            object.timezone = "";
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            object.timezone = message.timezone;
                        return object;
                    };
    
                    /**
                     * Converts this FetchCamerasReadyToScheduleDownloadRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchCamerasReadyToScheduleDownloadRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchCamerasReadyToScheduleDownloadRequest;
                })();
    
                v1.FetchCamerasReadyToScheduleDownloadResponse = (function() {
    
                    /**
                     * Properties of a FetchCamerasReadyToScheduleDownloadResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchCamerasReadyToScheduleDownloadResponse
                     * @property {Array.<palexy.streaming.v1.ICamera>|null} [cameras] FetchCamerasReadyToScheduleDownloadResponse cameras
                     */
    
                    /**
                     * Constructs a new FetchCamerasReadyToScheduleDownloadResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchCamerasReadyToScheduleDownloadResponse.
                     * @implements IFetchCamerasReadyToScheduleDownloadResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchCamerasReadyToScheduleDownloadResponse=} [properties] Properties to set
                     */
                    function FetchCamerasReadyToScheduleDownloadResponse(properties) {
                        this.cameras = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchCamerasReadyToScheduleDownloadResponse cameras.
                     * @member {Array.<palexy.streaming.v1.ICamera>} cameras
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse
                     * @instance
                     */
                    FetchCamerasReadyToScheduleDownloadResponse.prototype.cameras = $util.emptyArray;
    
                    /**
                     * Creates a new FetchCamerasReadyToScheduleDownloadResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchCamerasReadyToScheduleDownloadResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse} FetchCamerasReadyToScheduleDownloadResponse instance
                     */
                    FetchCamerasReadyToScheduleDownloadResponse.create = function create(properties) {
                        return new FetchCamerasReadyToScheduleDownloadResponse(properties);
                    };
    
                    /**
                     * Encodes the specified FetchCamerasReadyToScheduleDownloadResponse message. Does not implicitly {@link palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchCamerasReadyToScheduleDownloadResponse} message FetchCamerasReadyToScheduleDownloadResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchCamerasReadyToScheduleDownloadResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameras != null && message.cameras.length)
                            for (var i = 0; i < message.cameras.length; ++i)
                                $root.palexy.streaming.v1.Camera.encode(message.cameras[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchCamerasReadyToScheduleDownloadResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchCamerasReadyToScheduleDownloadResponse} message FetchCamerasReadyToScheduleDownloadResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchCamerasReadyToScheduleDownloadResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchCamerasReadyToScheduleDownloadResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse} FetchCamerasReadyToScheduleDownloadResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchCamerasReadyToScheduleDownloadResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.cameras && message.cameras.length))
                                    message.cameras = [];
                                message.cameras.push($root.palexy.streaming.v1.Camera.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchCamerasReadyToScheduleDownloadResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse} FetchCamerasReadyToScheduleDownloadResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchCamerasReadyToScheduleDownloadResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchCamerasReadyToScheduleDownloadResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchCamerasReadyToScheduleDownloadResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameras != null && message.hasOwnProperty("cameras")) {
                            if (!Array.isArray(message.cameras))
                                return "cameras: array expected";
                            for (var i = 0; i < message.cameras.length; ++i) {
                                var error = $root.palexy.streaming.v1.Camera.verify(message.cameras[i]);
                                if (error)
                                    return "cameras." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a FetchCamerasReadyToScheduleDownloadResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse} FetchCamerasReadyToScheduleDownloadResponse
                     */
                    FetchCamerasReadyToScheduleDownloadResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse();
                        if (object.cameras) {
                            if (!Array.isArray(object.cameras))
                                throw TypeError(".palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse.cameras: array expected");
                            message.cameras = [];
                            for (var i = 0; i < object.cameras.length; ++i) {
                                if (typeof object.cameras[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse.cameras: object expected");
                                message.cameras[i] = $root.palexy.streaming.v1.Camera.fromObject(object.cameras[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchCamerasReadyToScheduleDownloadResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse
                     * @static
                     * @param {palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse} message FetchCamerasReadyToScheduleDownloadResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchCamerasReadyToScheduleDownloadResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.cameras = [];
                        if (message.cameras && message.cameras.length) {
                            object.cameras = [];
                            for (var j = 0; j < message.cameras.length; ++j)
                                object.cameras[j] = $root.palexy.streaming.v1.Camera.toObject(message.cameras[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this FetchCamerasReadyToScheduleDownloadResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchCamerasReadyToScheduleDownloadResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchCamerasReadyToScheduleDownloadResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchCamerasReadyToScheduleDownloadResponse;
                })();
    
                v1.VideoService = (function() {
    
                    /**
                     * Constructs a new VideoService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a VideoService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function VideoService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (VideoService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = VideoService;
    
                    /**
                     * Creates new VideoService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.VideoService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {VideoService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    VideoService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.VideoService#createVideo}.
                     * @memberof palexy.streaming.v1.VideoService
                     * @typedef CreateVideoCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Video} [response] Video
                     */
    
                    /**
                     * Calls CreateVideo.
                     * @function createVideo
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateVideoRequest} request CreateVideoRequest message or plain object
                     * @param {palexy.streaming.v1.VideoService.CreateVideoCallback} callback Node-style callback called with the error, if any, and Video
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(VideoService.prototype.createVideo = function createVideo(request, callback) {
                        return this.rpcCall(createVideo, $root.palexy.streaming.v1.CreateVideoRequest, $root.palexy.streaming.v1.Video, request, callback);
                    }, "name", { value: "CreateVideo" });
    
                    /**
                     * Calls CreateVideo.
                     * @function createVideo
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateVideoRequest} request CreateVideoRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Video>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.VideoService#updateVideo}.
                     * @memberof palexy.streaming.v1.VideoService
                     * @typedef UpdateVideoCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Video} [response] Video
                     */
    
                    /**
                     * Calls UpdateVideo.
                     * @function updateVideo
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateVideoRequest} request UpdateVideoRequest message or plain object
                     * @param {palexy.streaming.v1.VideoService.UpdateVideoCallback} callback Node-style callback called with the error, if any, and Video
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(VideoService.prototype.updateVideo = function updateVideo(request, callback) {
                        return this.rpcCall(updateVideo, $root.palexy.streaming.v1.UpdateVideoRequest, $root.palexy.streaming.v1.Video, request, callback);
                    }, "name", { value: "UpdateVideo" });
    
                    /**
                     * Calls UpdateVideo.
                     * @function updateVideo
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateVideoRequest} request UpdateVideoRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Video>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.VideoService#deleteVideo}.
                     * @memberof palexy.streaming.v1.VideoService
                     * @typedef DeleteVideoCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteVideo.
                     * @function deleteVideo
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteVideoRequest} request DeleteVideoRequest message or plain object
                     * @param {palexy.streaming.v1.VideoService.DeleteVideoCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(VideoService.prototype.deleteVideo = function deleteVideo(request, callback) {
                        return this.rpcCall(deleteVideo, $root.palexy.streaming.v1.DeleteVideoRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteVideo" });
    
                    /**
                     * Calls DeleteVideo.
                     * @function deleteVideo
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteVideoRequest} request DeleteVideoRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.VideoService#submitDeleteVideoTasks}.
                     * @memberof palexy.streaming.v1.VideoService
                     * @typedef SubmitDeleteVideoTasksCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls SubmitDeleteVideoTasks.
                     * @function submitDeleteVideoTasks
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.ISubmitDeleteVideoTasksRequest} request SubmitDeleteVideoTasksRequest message or plain object
                     * @param {palexy.streaming.v1.VideoService.SubmitDeleteVideoTasksCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(VideoService.prototype.submitDeleteVideoTasks = function submitDeleteVideoTasks(request, callback) {
                        return this.rpcCall(submitDeleteVideoTasks, $root.palexy.streaming.v1.SubmitDeleteVideoTasksRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "SubmitDeleteVideoTasks" });
    
                    /**
                     * Calls SubmitDeleteVideoTasks.
                     * @function submitDeleteVideoTasks
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.ISubmitDeleteVideoTasksRequest} request SubmitDeleteVideoTasksRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.VideoService#batchUpdateVideos}.
                     * @memberof palexy.streaming.v1.VideoService
                     * @typedef BatchUpdateVideosCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls BatchUpdateVideos.
                     * @function batchUpdateVideos
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchUpdateVideoRequest} request BatchUpdateVideoRequest message or plain object
                     * @param {palexy.streaming.v1.VideoService.BatchUpdateVideosCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(VideoService.prototype.batchUpdateVideos = function batchUpdateVideos(request, callback) {
                        return this.rpcCall(batchUpdateVideos, $root.palexy.streaming.v1.BatchUpdateVideoRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "BatchUpdateVideos" });
    
                    /**
                     * Calls BatchUpdateVideos.
                     * @function batchUpdateVideos
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchUpdateVideoRequest} request BatchUpdateVideoRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.VideoService#fetchVideosReadyForMerge}.
                     * @memberof palexy.streaming.v1.VideoService
                     * @typedef FetchVideosReadyForMergeCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.FetchVideosReadyForMergeResponse} [response] FetchVideosReadyForMergeResponse
                     */
    
                    /**
                     * Calls FetchVideosReadyForMerge.
                     * @function fetchVideosReadyForMerge
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchVideosReadyForMergeRequest} request FetchVideosReadyForMergeRequest message or plain object
                     * @param {palexy.streaming.v1.VideoService.FetchVideosReadyForMergeCallback} callback Node-style callback called with the error, if any, and FetchVideosReadyForMergeResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(VideoService.prototype.fetchVideosReadyForMerge = function fetchVideosReadyForMerge(request, callback) {
                        return this.rpcCall(fetchVideosReadyForMerge, $root.palexy.streaming.v1.FetchVideosReadyForMergeRequest, $root.palexy.streaming.v1.FetchVideosReadyForMergeResponse, request, callback);
                    }, "name", { value: "FetchVideosReadyForMerge" });
    
                    /**
                     * Calls FetchVideosReadyForMerge.
                     * @function fetchVideosReadyForMerge
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchVideosReadyForMergeRequest} request FetchVideosReadyForMergeRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.FetchVideosReadyForMergeResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.VideoService#fetchVideos}.
                     * @memberof palexy.streaming.v1.VideoService
                     * @typedef FetchVideosCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.FetchVideosResponse} [response] FetchVideosResponse
                     */
    
                    /**
                     * Calls FetchVideos.
                     * @function fetchVideos
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchVideosRequest} request FetchVideosRequest message or plain object
                     * @param {palexy.streaming.v1.VideoService.FetchVideosCallback} callback Node-style callback called with the error, if any, and FetchVideosResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(VideoService.prototype.fetchVideos = function fetchVideos(request, callback) {
                        return this.rpcCall(fetchVideos, $root.palexy.streaming.v1.FetchVideosRequest, $root.palexy.streaming.v1.FetchVideosResponse, request, callback);
                    }, "name", { value: "FetchVideos" });
    
                    /**
                     * Calls FetchVideos.
                     * @function fetchVideos
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchVideosRequest} request FetchVideosRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.FetchVideosResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.VideoService#getVideo}.
                     * @memberof palexy.streaming.v1.VideoService
                     * @typedef GetVideoCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Video} [response] Video
                     */
    
                    /**
                     * Calls GetVideo.
                     * @function getVideo
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IGetVideoRequest} request GetVideoRequest message or plain object
                     * @param {palexy.streaming.v1.VideoService.GetVideoCallback} callback Node-style callback called with the error, if any, and Video
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(VideoService.prototype.getVideo = function getVideo(request, callback) {
                        return this.rpcCall(getVideo, $root.palexy.streaming.v1.GetVideoRequest, $root.palexy.streaming.v1.Video, request, callback);
                    }, "name", { value: "GetVideo" });
    
                    /**
                     * Calls GetVideo.
                     * @function getVideo
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IGetVideoRequest} request GetVideoRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Video>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.VideoService#fetchVideosReadyForPartialMerge}.
                     * @memberof palexy.streaming.v1.VideoService
                     * @typedef FetchVideosReadyForPartialMergeCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse} [response] FetchVideosReadyForPartialMergeResponse
                     */
    
                    /**
                     * Calls FetchVideosReadyForPartialMerge.
                     * @function fetchVideosReadyForPartialMerge
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchVideosReadyForPartialMergeRequest} request FetchVideosReadyForPartialMergeRequest message or plain object
                     * @param {palexy.streaming.v1.VideoService.FetchVideosReadyForPartialMergeCallback} callback Node-style callback called with the error, if any, and FetchVideosReadyForPartialMergeResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(VideoService.prototype.fetchVideosReadyForPartialMerge = function fetchVideosReadyForPartialMerge(request, callback) {
                        return this.rpcCall(fetchVideosReadyForPartialMerge, $root.palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest, $root.palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse, request, callback);
                    }, "name", { value: "FetchVideosReadyForPartialMerge" });
    
                    /**
                     * Calls FetchVideosReadyForPartialMerge.
                     * @function fetchVideosReadyForPartialMerge
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchVideosReadyForPartialMergeRequest} request FetchVideosReadyForPartialMergeRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.VideoService#fetchVideosForCleaningUp}.
                     * @memberof palexy.streaming.v1.VideoService
                     * @typedef FetchVideosForCleaningUpCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.FetchVideosResponse} [response] FetchVideosResponse
                     */
    
                    /**
                     * Calls FetchVideosForCleaningUp.
                     * @function fetchVideosForCleaningUp
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchVideosForCleaningUpRequest} request FetchVideosForCleaningUpRequest message or plain object
                     * @param {palexy.streaming.v1.VideoService.FetchVideosForCleaningUpCallback} callback Node-style callback called with the error, if any, and FetchVideosResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(VideoService.prototype.fetchVideosForCleaningUp = function fetchVideosForCleaningUp(request, callback) {
                        return this.rpcCall(fetchVideosForCleaningUp, $root.palexy.streaming.v1.FetchVideosForCleaningUpRequest, $root.palexy.streaming.v1.FetchVideosResponse, request, callback);
                    }, "name", { value: "FetchVideosForCleaningUp" });
    
                    /**
                     * Calls FetchVideosForCleaningUp.
                     * @function fetchVideosForCleaningUp
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchVideosForCleaningUpRequest} request FetchVideosForCleaningUpRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.FetchVideosResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.VideoService#fetchVideosForArchiving}.
                     * @memberof palexy.streaming.v1.VideoService
                     * @typedef FetchVideosForArchivingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.FetchVideosResponse} [response] FetchVideosResponse
                     */
    
                    /**
                     * Calls FetchVideosForArchiving.
                     * @function fetchVideosForArchiving
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchVideosForArchivingRequest} request FetchVideosForArchivingRequest message or plain object
                     * @param {palexy.streaming.v1.VideoService.FetchVideosForArchivingCallback} callback Node-style callback called with the error, if any, and FetchVideosResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(VideoService.prototype.fetchVideosForArchiving = function fetchVideosForArchiving(request, callback) {
                        return this.rpcCall(fetchVideosForArchiving, $root.palexy.streaming.v1.FetchVideosForArchivingRequest, $root.palexy.streaming.v1.FetchVideosResponse, request, callback);
                    }, "name", { value: "FetchVideosForArchiving" });
    
                    /**
                     * Calls FetchVideosForArchiving.
                     * @function fetchVideosForArchiving
                     * @memberof palexy.streaming.v1.VideoService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchVideosForArchivingRequest} request FetchVideosForArchivingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.FetchVideosResponse>} Promise
                     * @variation 2
                     */
    
                    return VideoService;
                })();
    
                v1.GetVideoRequest = (function() {
    
                    /**
                     * Properties of a GetVideoRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetVideoRequest
                     * @property {string|null} [name] GetVideoRequest name
                     */
    
                    /**
                     * Constructs a new GetVideoRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetVideoRequest.
                     * @implements IGetVideoRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetVideoRequest=} [properties] Properties to set
                     */
                    function GetVideoRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetVideoRequest name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.GetVideoRequest
                     * @instance
                     */
                    GetVideoRequest.prototype.name = "";
    
                    /**
                     * Creates a new GetVideoRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetVideoRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetVideoRequest} GetVideoRequest instance
                     */
                    GetVideoRequest.create = function create(properties) {
                        return new GetVideoRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetVideoRequest message. Does not implicitly {@link palexy.streaming.v1.GetVideoRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetVideoRequest} message GetVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetVideoRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetVideoRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetVideoRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetVideoRequest} message GetVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetVideoRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetVideoRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetVideoRequest} GetVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetVideoRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetVideoRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetVideoRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetVideoRequest} GetVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetVideoRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetVideoRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetVideoRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetVideoRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetVideoRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetVideoRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetVideoRequest} GetVideoRequest
                     */
                    GetVideoRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetVideoRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetVideoRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetVideoRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.GetVideoRequest} message GetVideoRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetVideoRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this GetVideoRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetVideoRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetVideoRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetVideoRequest;
                })();
    
                v1.BatchUpdateVideoRequest = (function() {
    
                    /**
                     * Properties of a BatchUpdateVideoRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchUpdateVideoRequest
                     * @property {Array.<palexy.streaming.v1.IUpdateVideoRequest>|null} [updateVideoRequests] BatchUpdateVideoRequest updateVideoRequests
                     * @property {palexy.streaming.v1.ISendToQueueRequest|null} [sendToQueueRequest] BatchUpdateVideoRequest sendToQueueRequest
                     */
    
                    /**
                     * Constructs a new BatchUpdateVideoRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchUpdateVideoRequest.
                     * @implements IBatchUpdateVideoRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchUpdateVideoRequest=} [properties] Properties to set
                     */
                    function BatchUpdateVideoRequest(properties) {
                        this.updateVideoRequests = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchUpdateVideoRequest updateVideoRequests.
                     * @member {Array.<palexy.streaming.v1.IUpdateVideoRequest>} updateVideoRequests
                     * @memberof palexy.streaming.v1.BatchUpdateVideoRequest
                     * @instance
                     */
                    BatchUpdateVideoRequest.prototype.updateVideoRequests = $util.emptyArray;
    
                    /**
                     * BatchUpdateVideoRequest sendToQueueRequest.
                     * @member {palexy.streaming.v1.ISendToQueueRequest|null|undefined} sendToQueueRequest
                     * @memberof palexy.streaming.v1.BatchUpdateVideoRequest
                     * @instance
                     */
                    BatchUpdateVideoRequest.prototype.sendToQueueRequest = null;
    
                    /**
                     * Creates a new BatchUpdateVideoRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchUpdateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateVideoRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchUpdateVideoRequest} BatchUpdateVideoRequest instance
                     */
                    BatchUpdateVideoRequest.create = function create(properties) {
                        return new BatchUpdateVideoRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchUpdateVideoRequest message. Does not implicitly {@link palexy.streaming.v1.BatchUpdateVideoRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchUpdateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateVideoRequest} message BatchUpdateVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateVideoRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.updateVideoRequests != null && message.updateVideoRequests.length)
                            for (var i = 0; i < message.updateVideoRequests.length; ++i)
                                $root.palexy.streaming.v1.UpdateVideoRequest.encode(message.updateVideoRequests[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.sendToQueueRequest != null && Object.hasOwnProperty.call(message, "sendToQueueRequest"))
                            $root.palexy.streaming.v1.SendToQueueRequest.encode(message.sendToQueueRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchUpdateVideoRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchUpdateVideoRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateVideoRequest} message BatchUpdateVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateVideoRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchUpdateVideoRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchUpdateVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchUpdateVideoRequest} BatchUpdateVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateVideoRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchUpdateVideoRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.updateVideoRequests && message.updateVideoRequests.length))
                                    message.updateVideoRequests = [];
                                message.updateVideoRequests.push($root.palexy.streaming.v1.UpdateVideoRequest.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.sendToQueueRequest = $root.palexy.streaming.v1.SendToQueueRequest.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchUpdateVideoRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchUpdateVideoRequest} BatchUpdateVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateVideoRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchUpdateVideoRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchUpdateVideoRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchUpdateVideoRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.updateVideoRequests != null && message.hasOwnProperty("updateVideoRequests")) {
                            if (!Array.isArray(message.updateVideoRequests))
                                return "updateVideoRequests: array expected";
                            for (var i = 0; i < message.updateVideoRequests.length; ++i) {
                                var error = $root.palexy.streaming.v1.UpdateVideoRequest.verify(message.updateVideoRequests[i]);
                                if (error)
                                    return "updateVideoRequests." + error;
                            }
                        }
                        if (message.sendToQueueRequest != null && message.hasOwnProperty("sendToQueueRequest")) {
                            var error = $root.palexy.streaming.v1.SendToQueueRequest.verify(message.sendToQueueRequest);
                            if (error)
                                return "sendToQueueRequest." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchUpdateVideoRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchUpdateVideoRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchUpdateVideoRequest} BatchUpdateVideoRequest
                     */
                    BatchUpdateVideoRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchUpdateVideoRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchUpdateVideoRequest();
                        if (object.updateVideoRequests) {
                            if (!Array.isArray(object.updateVideoRequests))
                                throw TypeError(".palexy.streaming.v1.BatchUpdateVideoRequest.updateVideoRequests: array expected");
                            message.updateVideoRequests = [];
                            for (var i = 0; i < object.updateVideoRequests.length; ++i) {
                                if (typeof object.updateVideoRequests[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchUpdateVideoRequest.updateVideoRequests: object expected");
                                message.updateVideoRequests[i] = $root.palexy.streaming.v1.UpdateVideoRequest.fromObject(object.updateVideoRequests[i]);
                            }
                        }
                        if (object.sendToQueueRequest != null) {
                            if (typeof object.sendToQueueRequest !== "object")
                                throw TypeError(".palexy.streaming.v1.BatchUpdateVideoRequest.sendToQueueRequest: object expected");
                            message.sendToQueueRequest = $root.palexy.streaming.v1.SendToQueueRequest.fromObject(object.sendToQueueRequest);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchUpdateVideoRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchUpdateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchUpdateVideoRequest} message BatchUpdateVideoRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchUpdateVideoRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.updateVideoRequests = [];
                        if (options.defaults)
                            object.sendToQueueRequest = null;
                        if (message.updateVideoRequests && message.updateVideoRequests.length) {
                            object.updateVideoRequests = [];
                            for (var j = 0; j < message.updateVideoRequests.length; ++j)
                                object.updateVideoRequests[j] = $root.palexy.streaming.v1.UpdateVideoRequest.toObject(message.updateVideoRequests[j], options);
                        }
                        if (message.sendToQueueRequest != null && message.hasOwnProperty("sendToQueueRequest"))
                            object.sendToQueueRequest = $root.palexy.streaming.v1.SendToQueueRequest.toObject(message.sendToQueueRequest, options);
                        return object;
                    };
    
                    /**
                     * Converts this BatchUpdateVideoRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchUpdateVideoRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchUpdateVideoRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchUpdateVideoRequest;
                })();
    
                v1.SendToQueueRequest = (function() {
    
                    /**
                     * Properties of a SendToQueueRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ISendToQueueRequest
                     * @property {string|null} [destinationQueueName] SendToQueueRequest destinationQueueName
                     * @property {Uint8Array|null} [messageContent] SendToQueueRequest messageContent
                     * @property {string|null} [traceId] SendToQueueRequest traceId
                     */
    
                    /**
                     * Constructs a new SendToQueueRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SendToQueueRequest.
                     * @implements ISendToQueueRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ISendToQueueRequest=} [properties] Properties to set
                     */
                    function SendToQueueRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SendToQueueRequest destinationQueueName.
                     * @member {string} destinationQueueName
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @instance
                     */
                    SendToQueueRequest.prototype.destinationQueueName = "";
    
                    /**
                     * SendToQueueRequest messageContent.
                     * @member {Uint8Array} messageContent
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @instance
                     */
                    SendToQueueRequest.prototype.messageContent = $util.newBuffer([]);
    
                    /**
                     * SendToQueueRequest traceId.
                     * @member {string} traceId
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @instance
                     */
                    SendToQueueRequest.prototype.traceId = "";
    
                    /**
                     * Creates a new SendToQueueRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendToQueueRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SendToQueueRequest} SendToQueueRequest instance
                     */
                    SendToQueueRequest.create = function create(properties) {
                        return new SendToQueueRequest(properties);
                    };
    
                    /**
                     * Encodes the specified SendToQueueRequest message. Does not implicitly {@link palexy.streaming.v1.SendToQueueRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendToQueueRequest} message SendToQueueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendToQueueRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.destinationQueueName != null && Object.hasOwnProperty.call(message, "destinationQueueName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.destinationQueueName);
                        if (message.messageContent != null && Object.hasOwnProperty.call(message, "messageContent"))
                            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.messageContent);
                        if (message.traceId != null && Object.hasOwnProperty.call(message, "traceId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.traceId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SendToQueueRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.SendToQueueRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendToQueueRequest} message SendToQueueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendToQueueRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SendToQueueRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SendToQueueRequest} SendToQueueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendToQueueRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SendToQueueRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.destinationQueueName = reader.string();
                                break;
                            case 2:
                                message.messageContent = reader.bytes();
                                break;
                            case 3:
                                message.traceId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SendToQueueRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SendToQueueRequest} SendToQueueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendToQueueRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SendToQueueRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SendToQueueRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.destinationQueueName != null && message.hasOwnProperty("destinationQueueName"))
                            if (!$util.isString(message.destinationQueueName))
                                return "destinationQueueName: string expected";
                        if (message.messageContent != null && message.hasOwnProperty("messageContent"))
                            if (!(message.messageContent && typeof message.messageContent.length === "number" || $util.isString(message.messageContent)))
                                return "messageContent: buffer expected";
                        if (message.traceId != null && message.hasOwnProperty("traceId"))
                            if (!$util.isString(message.traceId))
                                return "traceId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a SendToQueueRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SendToQueueRequest} SendToQueueRequest
                     */
                    SendToQueueRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SendToQueueRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.SendToQueueRequest();
                        if (object.destinationQueueName != null)
                            message.destinationQueueName = String(object.destinationQueueName);
                        if (object.messageContent != null)
                            if (typeof object.messageContent === "string")
                                $util.base64.decode(object.messageContent, message.messageContent = $util.newBuffer($util.base64.length(object.messageContent)), 0);
                            else if (object.messageContent.length)
                                message.messageContent = object.messageContent;
                        if (object.traceId != null)
                            message.traceId = String(object.traceId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SendToQueueRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @static
                     * @param {palexy.streaming.v1.SendToQueueRequest} message SendToQueueRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SendToQueueRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.destinationQueueName = "";
                            if (options.bytes === String)
                                object.messageContent = "";
                            else {
                                object.messageContent = [];
                                if (options.bytes !== Array)
                                    object.messageContent = $util.newBuffer(object.messageContent);
                            }
                            object.traceId = "";
                        }
                        if (message.destinationQueueName != null && message.hasOwnProperty("destinationQueueName"))
                            object.destinationQueueName = message.destinationQueueName;
                        if (message.messageContent != null && message.hasOwnProperty("messageContent"))
                            object.messageContent = options.bytes === String ? $util.base64.encode(message.messageContent, 0, message.messageContent.length) : options.bytes === Array ? Array.prototype.slice.call(message.messageContent) : message.messageContent;
                        if (message.traceId != null && message.hasOwnProperty("traceId"))
                            object.traceId = message.traceId;
                        return object;
                    };
    
                    /**
                     * Converts this SendToQueueRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SendToQueueRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SendToQueueRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SendToQueueRequest;
                })();
    
                v1.FetchVideosRequest = (function() {
    
                    /**
                     * Properties of a FetchVideosRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchVideosRequest
                     * @property {palexy.streaming.v1.IVideoFilter|null} [filter] FetchVideosRequest filter
                     * @property {number|null} [pageSize] FetchVideosRequest pageSize
                     * @property {string|null} [pageToken] FetchVideosRequest pageToken
                     */
    
                    /**
                     * Constructs a new FetchVideosRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchVideosRequest.
                     * @implements IFetchVideosRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchVideosRequest=} [properties] Properties to set
                     */
                    function FetchVideosRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchVideosRequest filter.
                     * @member {palexy.streaming.v1.IVideoFilter|null|undefined} filter
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @instance
                     */
                    FetchVideosRequest.prototype.filter = null;
    
                    /**
                     * FetchVideosRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @instance
                     */
                    FetchVideosRequest.prototype.pageSize = 0;
    
                    /**
                     * FetchVideosRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @instance
                     */
                    FetchVideosRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new FetchVideosRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchVideosRequest} FetchVideosRequest instance
                     */
                    FetchVideosRequest.create = function create(properties) {
                        return new FetchVideosRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FetchVideosRequest message. Does not implicitly {@link palexy.streaming.v1.FetchVideosRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosRequest} message FetchVideosRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                            $root.palexy.streaming.v1.VideoFilter.encode(message.filter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchVideosRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchVideosRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosRequest} message FetchVideosRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchVideosRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchVideosRequest} FetchVideosRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchVideosRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.filter = $root.palexy.streaming.v1.VideoFilter.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.pageSize = reader.int32();
                                break;
                            case 3:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchVideosRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchVideosRequest} FetchVideosRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchVideosRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchVideosRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.filter != null && message.hasOwnProperty("filter")) {
                            var error = $root.palexy.streaming.v1.VideoFilter.verify(message.filter);
                            if (error)
                                return "filter." + error;
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a FetchVideosRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchVideosRequest} FetchVideosRequest
                     */
                    FetchVideosRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchVideosRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchVideosRequest();
                        if (object.filter != null) {
                            if (typeof object.filter !== "object")
                                throw TypeError(".palexy.streaming.v1.FetchVideosRequest.filter: object expected");
                            message.filter = $root.palexy.streaming.v1.VideoFilter.fromObject(object.filter);
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchVideosRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @static
                     * @param {palexy.streaming.v1.FetchVideosRequest} message FetchVideosRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchVideosRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.filter = null;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.filter != null && message.hasOwnProperty("filter"))
                            object.filter = $root.palexy.streaming.v1.VideoFilter.toObject(message.filter, options);
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this FetchVideosRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchVideosRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchVideosRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchVideosRequest;
                })();
    
                v1.FetchVideosResponse = (function() {
    
                    /**
                     * Properties of a FetchVideosResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchVideosResponse
                     * @property {Array.<palexy.streaming.v1.IVideo>|null} [videos] FetchVideosResponse videos
                     * @property {string|null} [nextPageToken] FetchVideosResponse nextPageToken
                     * @property {number|null} [totalElements] FetchVideosResponse totalElements
                     */
    
                    /**
                     * Constructs a new FetchVideosResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchVideosResponse.
                     * @implements IFetchVideosResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchVideosResponse=} [properties] Properties to set
                     */
                    function FetchVideosResponse(properties) {
                        this.videos = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchVideosResponse videos.
                     * @member {Array.<palexy.streaming.v1.IVideo>} videos
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @instance
                     */
                    FetchVideosResponse.prototype.videos = $util.emptyArray;
    
                    /**
                     * FetchVideosResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @instance
                     */
                    FetchVideosResponse.prototype.nextPageToken = "";
    
                    /**
                     * FetchVideosResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @instance
                     */
                    FetchVideosResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new FetchVideosResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchVideosResponse} FetchVideosResponse instance
                     */
                    FetchVideosResponse.create = function create(properties) {
                        return new FetchVideosResponse(properties);
                    };
    
                    /**
                     * Encodes the specified FetchVideosResponse message. Does not implicitly {@link palexy.streaming.v1.FetchVideosResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosResponse} message FetchVideosResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.videos != null && message.videos.length)
                            for (var i = 0; i < message.videos.length; ++i)
                                $root.palexy.streaming.v1.Video.encode(message.videos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchVideosResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchVideosResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosResponse} message FetchVideosResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchVideosResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchVideosResponse} FetchVideosResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchVideosResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.videos && message.videos.length))
                                    message.videos = [];
                                message.videos.push($root.palexy.streaming.v1.Video.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            case 3:
                                message.totalElements = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchVideosResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchVideosResponse} FetchVideosResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchVideosResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchVideosResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.videos != null && message.hasOwnProperty("videos")) {
                            if (!Array.isArray(message.videos))
                                return "videos: array expected";
                            for (var i = 0; i < message.videos.length; ++i) {
                                var error = $root.palexy.streaming.v1.Video.verify(message.videos[i]);
                                if (error)
                                    return "videos." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a FetchVideosResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchVideosResponse} FetchVideosResponse
                     */
                    FetchVideosResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchVideosResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchVideosResponse();
                        if (object.videos) {
                            if (!Array.isArray(object.videos))
                                throw TypeError(".palexy.streaming.v1.FetchVideosResponse.videos: array expected");
                            message.videos = [];
                            for (var i = 0; i < object.videos.length; ++i) {
                                if (typeof object.videos[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.FetchVideosResponse.videos: object expected");
                                message.videos[i] = $root.palexy.streaming.v1.Video.fromObject(object.videos[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchVideosResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @static
                     * @param {palexy.streaming.v1.FetchVideosResponse} message FetchVideosResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchVideosResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.videos = [];
                        if (options.defaults) {
                            object.nextPageToken = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                        }
                        if (message.videos && message.videos.length) {
                            object.videos = [];
                            for (var j = 0; j < message.videos.length; ++j)
                                object.videos[j] = $root.palexy.streaming.v1.Video.toObject(message.videos[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        return object;
                    };
    
                    /**
                     * Converts this FetchVideosResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchVideosResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchVideosResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchVideosResponse;
                })();
    
                v1.FetchStoreSnapshotsResponse = (function() {
    
                    /**
                     * Properties of a FetchStoreSnapshotsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchStoreSnapshotsResponse
                     * @property {Array.<palexy.streaming.v1.IStoreSnapshot>|null} [storeSnapshots] FetchStoreSnapshotsResponse storeSnapshots
                     * @property {string|null} [nextPageToken] FetchStoreSnapshotsResponse nextPageToken
                     * @property {number|null} [totalElements] FetchStoreSnapshotsResponse totalElements
                     */
    
                    /**
                     * Constructs a new FetchStoreSnapshotsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchStoreSnapshotsResponse.
                     * @implements IFetchStoreSnapshotsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchStoreSnapshotsResponse=} [properties] Properties to set
                     */
                    function FetchStoreSnapshotsResponse(properties) {
                        this.storeSnapshots = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchStoreSnapshotsResponse storeSnapshots.
                     * @member {Array.<palexy.streaming.v1.IStoreSnapshot>} storeSnapshots
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @instance
                     */
                    FetchStoreSnapshotsResponse.prototype.storeSnapshots = $util.emptyArray;
    
                    /**
                     * FetchStoreSnapshotsResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @instance
                     */
                    FetchStoreSnapshotsResponse.prototype.nextPageToken = "";
    
                    /**
                     * FetchStoreSnapshotsResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @instance
                     */
                    FetchStoreSnapshotsResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new FetchStoreSnapshotsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchStoreSnapshotsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchStoreSnapshotsResponse} FetchStoreSnapshotsResponse instance
                     */
                    FetchStoreSnapshotsResponse.create = function create(properties) {
                        return new FetchStoreSnapshotsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified FetchStoreSnapshotsResponse message. Does not implicitly {@link palexy.streaming.v1.FetchStoreSnapshotsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchStoreSnapshotsResponse} message FetchStoreSnapshotsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchStoreSnapshotsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeSnapshots != null && message.storeSnapshots.length)
                            for (var i = 0; i < message.storeSnapshots.length; ++i)
                                $root.palexy.streaming.v1.StoreSnapshot.encode(message.storeSnapshots[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchStoreSnapshotsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchStoreSnapshotsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchStoreSnapshotsResponse} message FetchStoreSnapshotsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchStoreSnapshotsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchStoreSnapshotsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchStoreSnapshotsResponse} FetchStoreSnapshotsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchStoreSnapshotsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchStoreSnapshotsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeSnapshots && message.storeSnapshots.length))
                                    message.storeSnapshots = [];
                                message.storeSnapshots.push($root.palexy.streaming.v1.StoreSnapshot.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            case 3:
                                message.totalElements = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchStoreSnapshotsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchStoreSnapshotsResponse} FetchStoreSnapshotsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchStoreSnapshotsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchStoreSnapshotsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchStoreSnapshotsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeSnapshots != null && message.hasOwnProperty("storeSnapshots")) {
                            if (!Array.isArray(message.storeSnapshots))
                                return "storeSnapshots: array expected";
                            for (var i = 0; i < message.storeSnapshots.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreSnapshot.verify(message.storeSnapshots[i]);
                                if (error)
                                    return "storeSnapshots." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a FetchStoreSnapshotsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchStoreSnapshotsResponse} FetchStoreSnapshotsResponse
                     */
                    FetchStoreSnapshotsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchStoreSnapshotsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchStoreSnapshotsResponse();
                        if (object.storeSnapshots) {
                            if (!Array.isArray(object.storeSnapshots))
                                throw TypeError(".palexy.streaming.v1.FetchStoreSnapshotsResponse.storeSnapshots: array expected");
                            message.storeSnapshots = [];
                            for (var i = 0; i < object.storeSnapshots.length; ++i) {
                                if (typeof object.storeSnapshots[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.FetchStoreSnapshotsResponse.storeSnapshots: object expected");
                                message.storeSnapshots[i] = $root.palexy.streaming.v1.StoreSnapshot.fromObject(object.storeSnapshots[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchStoreSnapshotsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @static
                     * @param {palexy.streaming.v1.FetchStoreSnapshotsResponse} message FetchStoreSnapshotsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchStoreSnapshotsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeSnapshots = [];
                        if (options.defaults) {
                            object.nextPageToken = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                        }
                        if (message.storeSnapshots && message.storeSnapshots.length) {
                            object.storeSnapshots = [];
                            for (var j = 0; j < message.storeSnapshots.length; ++j)
                                object.storeSnapshots[j] = $root.palexy.streaming.v1.StoreSnapshot.toObject(message.storeSnapshots[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        return object;
                    };
    
                    /**
                     * Converts this FetchStoreSnapshotsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchStoreSnapshotsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchStoreSnapshotsResponse;
                })();
    
                v1.VideoFilter = (function() {
    
                    /**
                     * Properties of a VideoFilter.
                     * @memberof palexy.streaming.v1
                     * @interface IVideoFilter
                     * @property {palexy.streaming.v1.IDateFilter|null} [fromDate] VideoFilter fromDate
                     * @property {palexy.streaming.v1.IDateFilter|null} [to] VideoFilter to
                     * @property {palexy.streaming.v1.Video.State|null} [state] VideoFilter state
                     * @property {boolean|null} [isAlreadyPurged] VideoFilter isAlreadyPurged
                     * @property {Array.<string>|null} [cameraIds] VideoFilter cameraIds
                     * @property {number|null} [storeId] VideoFilter storeId
                     * @property {boolean|null} [archived] VideoFilter archived
                     * @property {google.protobuf.IBoolValue|null} [isAlreadyPurgedValue] VideoFilter isAlreadyPurgedValue
                     * @property {google.protobuf.IBoolValue|null} [archivedValue] VideoFilter archivedValue
                     * @property {google.protobuf.IBoolValue|null} [isQcData] VideoFilter isQcData
                     * @property {google.protobuf.IBoolValue|null} [isVideoAlreadyPurged] VideoFilter isVideoAlreadyPurged
                     * @property {google.protobuf.IBoolValue|null} [isTrackingFileAlreadyPurged] VideoFilter isTrackingFileAlreadyPurged
                     * @property {google.protobuf.IBoolValue|null} [isVideoArchived] VideoFilter isVideoArchived
                     * @property {google.protobuf.IBoolValue|null} [isTrackingFileArchived] VideoFilter isTrackingFileArchived
                     * @property {string|null} [cloudProvider] VideoFilter cloudProvider
                     * @property {google.protobuf.IBoolValue|null} [isVideoPassedMinimumStorageDuration] VideoFilter isVideoPassedMinimumStorageDuration
                     * @property {google.protobuf.IBoolValue|null} [isTrackingFilePassedMinimumStorageDuration] VideoFilter isTrackingFilePassedMinimumStorageDuration
                     */
    
                    /**
                     * Constructs a new VideoFilter.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a VideoFilter.
                     * @implements IVideoFilter
                     * @constructor
                     * @param {palexy.streaming.v1.IVideoFilter=} [properties] Properties to set
                     */
                    function VideoFilter(properties) {
                        this.cameraIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * VideoFilter fromDate.
                     * @member {palexy.streaming.v1.IDateFilter|null|undefined} fromDate
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.fromDate = null;
    
                    /**
                     * VideoFilter to.
                     * @member {palexy.streaming.v1.IDateFilter|null|undefined} to
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.to = null;
    
                    /**
                     * VideoFilter state.
                     * @member {palexy.streaming.v1.Video.State} state
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.state = 0;
    
                    /**
                     * VideoFilter isAlreadyPurged.
                     * @member {boolean} isAlreadyPurged
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.isAlreadyPurged = false;
    
                    /**
                     * VideoFilter cameraIds.
                     * @member {Array.<string>} cameraIds
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.cameraIds = $util.emptyArray;
    
                    /**
                     * VideoFilter storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * VideoFilter archived.
                     * @member {boolean} archived
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.archived = false;
    
                    /**
                     * VideoFilter isAlreadyPurgedValue.
                     * @member {google.protobuf.IBoolValue|null|undefined} isAlreadyPurgedValue
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.isAlreadyPurgedValue = null;
    
                    /**
                     * VideoFilter archivedValue.
                     * @member {google.protobuf.IBoolValue|null|undefined} archivedValue
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.archivedValue = null;
    
                    /**
                     * VideoFilter isQcData.
                     * @member {google.protobuf.IBoolValue|null|undefined} isQcData
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.isQcData = null;
    
                    /**
                     * VideoFilter isVideoAlreadyPurged.
                     * @member {google.protobuf.IBoolValue|null|undefined} isVideoAlreadyPurged
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.isVideoAlreadyPurged = null;
    
                    /**
                     * VideoFilter isTrackingFileAlreadyPurged.
                     * @member {google.protobuf.IBoolValue|null|undefined} isTrackingFileAlreadyPurged
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.isTrackingFileAlreadyPurged = null;
    
                    /**
                     * VideoFilter isVideoArchived.
                     * @member {google.protobuf.IBoolValue|null|undefined} isVideoArchived
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.isVideoArchived = null;
    
                    /**
                     * VideoFilter isTrackingFileArchived.
                     * @member {google.protobuf.IBoolValue|null|undefined} isTrackingFileArchived
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.isTrackingFileArchived = null;
    
                    /**
                     * VideoFilter cloudProvider.
                     * @member {string} cloudProvider
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.cloudProvider = "";
    
                    /**
                     * VideoFilter isVideoPassedMinimumStorageDuration.
                     * @member {google.protobuf.IBoolValue|null|undefined} isVideoPassedMinimumStorageDuration
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.isVideoPassedMinimumStorageDuration = null;
    
                    /**
                     * VideoFilter isTrackingFilePassedMinimumStorageDuration.
                     * @member {google.protobuf.IBoolValue|null|undefined} isTrackingFilePassedMinimumStorageDuration
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     */
                    VideoFilter.prototype.isTrackingFilePassedMinimumStorageDuration = null;
    
                    /**
                     * Creates a new VideoFilter instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @static
                     * @param {palexy.streaming.v1.IVideoFilter=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.VideoFilter} VideoFilter instance
                     */
                    VideoFilter.create = function create(properties) {
                        return new VideoFilter(properties);
                    };
    
                    /**
                     * Encodes the specified VideoFilter message. Does not implicitly {@link palexy.streaming.v1.VideoFilter.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @static
                     * @param {palexy.streaming.v1.IVideoFilter} message VideoFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VideoFilter.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.fromDate != null && Object.hasOwnProperty.call(message, "fromDate"))
                            $root.palexy.streaming.v1.DateFilter.encode(message.fromDate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                            $root.palexy.streaming.v1.DateFilter.encode(message.to, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.state);
                        if (message.isAlreadyPurged != null && Object.hasOwnProperty.call(message, "isAlreadyPurged"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isAlreadyPurged);
                        if (message.cameraIds != null && message.cameraIds.length)
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.cameraIds[i]);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.storeId);
                        if (message.archived != null && Object.hasOwnProperty.call(message, "archived"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.archived);
                        if (message.isAlreadyPurgedValue != null && Object.hasOwnProperty.call(message, "isAlreadyPurgedValue"))
                            $root.google.protobuf.BoolValue.encode(message.isAlreadyPurgedValue, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.archivedValue != null && Object.hasOwnProperty.call(message, "archivedValue"))
                            $root.google.protobuf.BoolValue.encode(message.archivedValue, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.isTrackingFileAlreadyPurged != null && Object.hasOwnProperty.call(message, "isTrackingFileAlreadyPurged"))
                            $root.google.protobuf.BoolValue.encode(message.isTrackingFileAlreadyPurged, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.isQcData != null && Object.hasOwnProperty.call(message, "isQcData"))
                            $root.google.protobuf.BoolValue.encode(message.isQcData, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.isVideoAlreadyPurged != null && Object.hasOwnProperty.call(message, "isVideoAlreadyPurged"))
                            $root.google.protobuf.BoolValue.encode(message.isVideoAlreadyPurged, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                        if (message.isVideoArchived != null && Object.hasOwnProperty.call(message, "isVideoArchived"))
                            $root.google.protobuf.BoolValue.encode(message.isVideoArchived, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.isTrackingFileArchived != null && Object.hasOwnProperty.call(message, "isTrackingFileArchived"))
                            $root.google.protobuf.BoolValue.encode(message.isTrackingFileArchived, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                        if (message.cloudProvider != null && Object.hasOwnProperty.call(message, "cloudProvider"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.cloudProvider);
                        if (message.isVideoPassedMinimumStorageDuration != null && Object.hasOwnProperty.call(message, "isVideoPassedMinimumStorageDuration"))
                            $root.google.protobuf.BoolValue.encode(message.isVideoPassedMinimumStorageDuration, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.isTrackingFilePassedMinimumStorageDuration != null && Object.hasOwnProperty.call(message, "isTrackingFilePassedMinimumStorageDuration"))
                            $root.google.protobuf.BoolValue.encode(message.isTrackingFilePassedMinimumStorageDuration, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified VideoFilter message, length delimited. Does not implicitly {@link palexy.streaming.v1.VideoFilter.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @static
                     * @param {palexy.streaming.v1.IVideoFilter} message VideoFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VideoFilter.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a VideoFilter message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.VideoFilter} VideoFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VideoFilter.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.VideoFilter();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.fromDate = $root.palexy.streaming.v1.DateFilter.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.to = $root.palexy.streaming.v1.DateFilter.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.state = reader.int32();
                                break;
                            case 4:
                                message.isAlreadyPurged = reader.bool();
                                break;
                            case 5:
                                if (!(message.cameraIds && message.cameraIds.length))
                                    message.cameraIds = [];
                                message.cameraIds.push(reader.string());
                                break;
                            case 6:
                                message.storeId = reader.int64();
                                break;
                            case 7:
                                message.archived = reader.bool();
                                break;
                            case 8:
                                message.isAlreadyPurgedValue = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.archivedValue = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.isQcData = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.isVideoAlreadyPurged = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.isTrackingFileAlreadyPurged = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 13:
                                message.isVideoArchived = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 14:
                                message.isTrackingFileArchived = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 15:
                                message.cloudProvider = reader.string();
                                break;
                            case 16:
                                message.isVideoPassedMinimumStorageDuration = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 17:
                                message.isTrackingFilePassedMinimumStorageDuration = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a VideoFilter message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.VideoFilter} VideoFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VideoFilter.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a VideoFilter message.
                     * @function verify
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    VideoFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.fromDate != null && message.hasOwnProperty("fromDate")) {
                            var error = $root.palexy.streaming.v1.DateFilter.verify(message.fromDate);
                            if (error)
                                return "fromDate." + error;
                        }
                        if (message.to != null && message.hasOwnProperty("to")) {
                            var error = $root.palexy.streaming.v1.DateFilter.verify(message.to);
                            if (error)
                                return "to." + error;
                        }
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 7:
                            case 6:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        if (message.isAlreadyPurged != null && message.hasOwnProperty("isAlreadyPurged"))
                            if (typeof message.isAlreadyPurged !== "boolean")
                                return "isAlreadyPurged: boolean expected";
                        if (message.cameraIds != null && message.hasOwnProperty("cameraIds")) {
                            if (!Array.isArray(message.cameraIds))
                                return "cameraIds: array expected";
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                if (!$util.isString(message.cameraIds[i]))
                                    return "cameraIds: string[] expected";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.archived != null && message.hasOwnProperty("archived"))
                            if (typeof message.archived !== "boolean")
                                return "archived: boolean expected";
                        if (message.isAlreadyPurgedValue != null && message.hasOwnProperty("isAlreadyPurgedValue")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isAlreadyPurgedValue);
                            if (error)
                                return "isAlreadyPurgedValue." + error;
                        }
                        if (message.archivedValue != null && message.hasOwnProperty("archivedValue")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.archivedValue);
                            if (error)
                                return "archivedValue." + error;
                        }
                        if (message.isQcData != null && message.hasOwnProperty("isQcData")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isQcData);
                            if (error)
                                return "isQcData." + error;
                        }
                        if (message.isVideoAlreadyPurged != null && message.hasOwnProperty("isVideoAlreadyPurged")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isVideoAlreadyPurged);
                            if (error)
                                return "isVideoAlreadyPurged." + error;
                        }
                        if (message.isTrackingFileAlreadyPurged != null && message.hasOwnProperty("isTrackingFileAlreadyPurged")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isTrackingFileAlreadyPurged);
                            if (error)
                                return "isTrackingFileAlreadyPurged." + error;
                        }
                        if (message.isVideoArchived != null && message.hasOwnProperty("isVideoArchived")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isVideoArchived);
                            if (error)
                                return "isVideoArchived." + error;
                        }
                        if (message.isTrackingFileArchived != null && message.hasOwnProperty("isTrackingFileArchived")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isTrackingFileArchived);
                            if (error)
                                return "isTrackingFileArchived." + error;
                        }
                        if (message.cloudProvider != null && message.hasOwnProperty("cloudProvider"))
                            if (!$util.isString(message.cloudProvider))
                                return "cloudProvider: string expected";
                        if (message.isVideoPassedMinimumStorageDuration != null && message.hasOwnProperty("isVideoPassedMinimumStorageDuration")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isVideoPassedMinimumStorageDuration);
                            if (error)
                                return "isVideoPassedMinimumStorageDuration." + error;
                        }
                        if (message.isTrackingFilePassedMinimumStorageDuration != null && message.hasOwnProperty("isTrackingFilePassedMinimumStorageDuration")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isTrackingFilePassedMinimumStorageDuration);
                            if (error)
                                return "isTrackingFilePassedMinimumStorageDuration." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a VideoFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.VideoFilter} VideoFilter
                     */
                    VideoFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.VideoFilter)
                            return object;
                        var message = new $root.palexy.streaming.v1.VideoFilter();
                        if (object.fromDate != null) {
                            if (typeof object.fromDate !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoFilter.fromDate: object expected");
                            message.fromDate = $root.palexy.streaming.v1.DateFilter.fromObject(object.fromDate);
                        }
                        if (object.to != null) {
                            if (typeof object.to !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoFilter.to: object expected");
                            message.to = $root.palexy.streaming.v1.DateFilter.fromObject(object.to);
                        }
                        switch (object.state) {
                        case "UNKNOWN":
                        case 0:
                            message.state = 0;
                            break;
                        case "WAITING_FOR_VIDEO_FILE":
                        case 7:
                            message.state = 7;
                            break;
                        case "WAITING_FOR_PREPROCESSING":
                        case 6:
                            message.state = 6;
                            break;
                        case "WAITING_FOR_PROCESSING":
                        case 1:
                            message.state = 1;
                            break;
                        case "DONE_TRACKING":
                        case 2:
                            message.state = 2;
                            break;
                        case "WAITING_FOR_MERGING":
                        case 3:
                            message.state = 3;
                            break;
                        case "DONE":
                        case 4:
                            message.state = 4;
                            break;
                        case "DONE_CALCULATING_ACCURACY":
                        case 5:
                            message.state = 5;
                            break;
                        }
                        if (object.isAlreadyPurged != null)
                            message.isAlreadyPurged = Boolean(object.isAlreadyPurged);
                        if (object.cameraIds) {
                            if (!Array.isArray(object.cameraIds))
                                throw TypeError(".palexy.streaming.v1.VideoFilter.cameraIds: array expected");
                            message.cameraIds = [];
                            for (var i = 0; i < object.cameraIds.length; ++i)
                                message.cameraIds[i] = String(object.cameraIds[i]);
                        }
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.archived != null)
                            message.archived = Boolean(object.archived);
                        if (object.isAlreadyPurgedValue != null) {
                            if (typeof object.isAlreadyPurgedValue !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoFilter.isAlreadyPurgedValue: object expected");
                            message.isAlreadyPurgedValue = $root.google.protobuf.BoolValue.fromObject(object.isAlreadyPurgedValue);
                        }
                        if (object.archivedValue != null) {
                            if (typeof object.archivedValue !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoFilter.archivedValue: object expected");
                            message.archivedValue = $root.google.protobuf.BoolValue.fromObject(object.archivedValue);
                        }
                        if (object.isQcData != null) {
                            if (typeof object.isQcData !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoFilter.isQcData: object expected");
                            message.isQcData = $root.google.protobuf.BoolValue.fromObject(object.isQcData);
                        }
                        if (object.isVideoAlreadyPurged != null) {
                            if (typeof object.isVideoAlreadyPurged !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoFilter.isVideoAlreadyPurged: object expected");
                            message.isVideoAlreadyPurged = $root.google.protobuf.BoolValue.fromObject(object.isVideoAlreadyPurged);
                        }
                        if (object.isTrackingFileAlreadyPurged != null) {
                            if (typeof object.isTrackingFileAlreadyPurged !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoFilter.isTrackingFileAlreadyPurged: object expected");
                            message.isTrackingFileAlreadyPurged = $root.google.protobuf.BoolValue.fromObject(object.isTrackingFileAlreadyPurged);
                        }
                        if (object.isVideoArchived != null) {
                            if (typeof object.isVideoArchived !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoFilter.isVideoArchived: object expected");
                            message.isVideoArchived = $root.google.protobuf.BoolValue.fromObject(object.isVideoArchived);
                        }
                        if (object.isTrackingFileArchived != null) {
                            if (typeof object.isTrackingFileArchived !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoFilter.isTrackingFileArchived: object expected");
                            message.isTrackingFileArchived = $root.google.protobuf.BoolValue.fromObject(object.isTrackingFileArchived);
                        }
                        if (object.cloudProvider != null)
                            message.cloudProvider = String(object.cloudProvider);
                        if (object.isVideoPassedMinimumStorageDuration != null) {
                            if (typeof object.isVideoPassedMinimumStorageDuration !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoFilter.isVideoPassedMinimumStorageDuration: object expected");
                            message.isVideoPassedMinimumStorageDuration = $root.google.protobuf.BoolValue.fromObject(object.isVideoPassedMinimumStorageDuration);
                        }
                        if (object.isTrackingFilePassedMinimumStorageDuration != null) {
                            if (typeof object.isTrackingFilePassedMinimumStorageDuration !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoFilter.isTrackingFilePassedMinimumStorageDuration: object expected");
                            message.isTrackingFilePassedMinimumStorageDuration = $root.google.protobuf.BoolValue.fromObject(object.isTrackingFilePassedMinimumStorageDuration);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a VideoFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @static
                     * @param {palexy.streaming.v1.VideoFilter} message VideoFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    VideoFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.cameraIds = [];
                        if (options.defaults) {
                            object.fromDate = null;
                            object.to = null;
                            object.state = options.enums === String ? "UNKNOWN" : 0;
                            object.isAlreadyPurged = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.archived = false;
                            object.isAlreadyPurgedValue = null;
                            object.archivedValue = null;
                            object.isTrackingFileAlreadyPurged = null;
                            object.isQcData = null;
                            object.isVideoAlreadyPurged = null;
                            object.isVideoArchived = null;
                            object.isTrackingFileArchived = null;
                            object.cloudProvider = "";
                            object.isVideoPassedMinimumStorageDuration = null;
                            object.isTrackingFilePassedMinimumStorageDuration = null;
                        }
                        if (message.fromDate != null && message.hasOwnProperty("fromDate"))
                            object.fromDate = $root.palexy.streaming.v1.DateFilter.toObject(message.fromDate, options);
                        if (message.to != null && message.hasOwnProperty("to"))
                            object.to = $root.palexy.streaming.v1.DateFilter.toObject(message.to, options);
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.streaming.v1.Video.State[message.state] : message.state;
                        if (message.isAlreadyPurged != null && message.hasOwnProperty("isAlreadyPurged"))
                            object.isAlreadyPurged = message.isAlreadyPurged;
                        if (message.cameraIds && message.cameraIds.length) {
                            object.cameraIds = [];
                            for (var j = 0; j < message.cameraIds.length; ++j)
                                object.cameraIds[j] = message.cameraIds[j];
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.archived != null && message.hasOwnProperty("archived"))
                            object.archived = message.archived;
                        if (message.isAlreadyPurgedValue != null && message.hasOwnProperty("isAlreadyPurgedValue"))
                            object.isAlreadyPurgedValue = $root.google.protobuf.BoolValue.toObject(message.isAlreadyPurgedValue, options);
                        if (message.archivedValue != null && message.hasOwnProperty("archivedValue"))
                            object.archivedValue = $root.google.protobuf.BoolValue.toObject(message.archivedValue, options);
                        if (message.isTrackingFileAlreadyPurged != null && message.hasOwnProperty("isTrackingFileAlreadyPurged"))
                            object.isTrackingFileAlreadyPurged = $root.google.protobuf.BoolValue.toObject(message.isTrackingFileAlreadyPurged, options);
                        if (message.isQcData != null && message.hasOwnProperty("isQcData"))
                            object.isQcData = $root.google.protobuf.BoolValue.toObject(message.isQcData, options);
                        if (message.isVideoAlreadyPurged != null && message.hasOwnProperty("isVideoAlreadyPurged"))
                            object.isVideoAlreadyPurged = $root.google.protobuf.BoolValue.toObject(message.isVideoAlreadyPurged, options);
                        if (message.isVideoArchived != null && message.hasOwnProperty("isVideoArchived"))
                            object.isVideoArchived = $root.google.protobuf.BoolValue.toObject(message.isVideoArchived, options);
                        if (message.isTrackingFileArchived != null && message.hasOwnProperty("isTrackingFileArchived"))
                            object.isTrackingFileArchived = $root.google.protobuf.BoolValue.toObject(message.isTrackingFileArchived, options);
                        if (message.cloudProvider != null && message.hasOwnProperty("cloudProvider"))
                            object.cloudProvider = message.cloudProvider;
                        if (message.isVideoPassedMinimumStorageDuration != null && message.hasOwnProperty("isVideoPassedMinimumStorageDuration"))
                            object.isVideoPassedMinimumStorageDuration = $root.google.protobuf.BoolValue.toObject(message.isVideoPassedMinimumStorageDuration, options);
                        if (message.isTrackingFilePassedMinimumStorageDuration != null && message.hasOwnProperty("isTrackingFilePassedMinimumStorageDuration"))
                            object.isTrackingFilePassedMinimumStorageDuration = $root.google.protobuf.BoolValue.toObject(message.isTrackingFilePassedMinimumStorageDuration, options);
                        return object;
                    };
    
                    /**
                     * Converts this VideoFilter to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.VideoFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    VideoFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return VideoFilter;
                })();
    
                v1.FetchVideosReadyForMergeRequest = (function() {
    
                    /**
                     * Properties of a FetchVideosReadyForMergeRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchVideosReadyForMergeRequest
                     * @property {number|null} [maxNumberOfAreas] FetchVideosReadyForMergeRequest maxNumberOfAreas
                     * @property {string|null} [timezone] FetchVideosReadyForMergeRequest timezone
                     */
    
                    /**
                     * Constructs a new FetchVideosReadyForMergeRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchVideosReadyForMergeRequest.
                     * @implements IFetchVideosReadyForMergeRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchVideosReadyForMergeRequest=} [properties] Properties to set
                     */
                    function FetchVideosReadyForMergeRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchVideosReadyForMergeRequest maxNumberOfAreas.
                     * @member {number} maxNumberOfAreas
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeRequest
                     * @instance
                     */
                    FetchVideosReadyForMergeRequest.prototype.maxNumberOfAreas = 0;
    
                    /**
                     * FetchVideosReadyForMergeRequest timezone.
                     * @member {string} timezone
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeRequest
                     * @instance
                     */
                    FetchVideosReadyForMergeRequest.prototype.timezone = "";
    
                    /**
                     * Creates a new FetchVideosReadyForMergeRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForMergeRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchVideosReadyForMergeRequest} FetchVideosReadyForMergeRequest instance
                     */
                    FetchVideosReadyForMergeRequest.create = function create(properties) {
                        return new FetchVideosReadyForMergeRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FetchVideosReadyForMergeRequest message. Does not implicitly {@link palexy.streaming.v1.FetchVideosReadyForMergeRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForMergeRequest} message FetchVideosReadyForMergeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosReadyForMergeRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.maxNumberOfAreas != null && Object.hasOwnProperty.call(message, "maxNumberOfAreas"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.maxNumberOfAreas);
                        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.timezone);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchVideosReadyForMergeRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchVideosReadyForMergeRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForMergeRequest} message FetchVideosReadyForMergeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosReadyForMergeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchVideosReadyForMergeRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchVideosReadyForMergeRequest} FetchVideosReadyForMergeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosReadyForMergeRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchVideosReadyForMergeRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.maxNumberOfAreas = reader.int32();
                                break;
                            case 2:
                                message.timezone = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchVideosReadyForMergeRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchVideosReadyForMergeRequest} FetchVideosReadyForMergeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosReadyForMergeRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchVideosReadyForMergeRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchVideosReadyForMergeRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.maxNumberOfAreas != null && message.hasOwnProperty("maxNumberOfAreas"))
                            if (!$util.isInteger(message.maxNumberOfAreas))
                                return "maxNumberOfAreas: integer expected";
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            if (!$util.isString(message.timezone))
                                return "timezone: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a FetchVideosReadyForMergeRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchVideosReadyForMergeRequest} FetchVideosReadyForMergeRequest
                     */
                    FetchVideosReadyForMergeRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchVideosReadyForMergeRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchVideosReadyForMergeRequest();
                        if (object.maxNumberOfAreas != null)
                            message.maxNumberOfAreas = object.maxNumberOfAreas | 0;
                        if (object.timezone != null)
                            message.timezone = String(object.timezone);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchVideosReadyForMergeRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeRequest
                     * @static
                     * @param {palexy.streaming.v1.FetchVideosReadyForMergeRequest} message FetchVideosReadyForMergeRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchVideosReadyForMergeRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.maxNumberOfAreas = 0;
                            object.timezone = "";
                        }
                        if (message.maxNumberOfAreas != null && message.hasOwnProperty("maxNumberOfAreas"))
                            object.maxNumberOfAreas = message.maxNumberOfAreas;
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            object.timezone = message.timezone;
                        return object;
                    };
    
                    /**
                     * Converts this FetchVideosReadyForMergeRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchVideosReadyForMergeRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchVideosReadyForMergeRequest;
                })();
    
                v1.FetchVideosReadyForMergeResponse = (function() {
    
                    /**
                     * Properties of a FetchVideosReadyForMergeResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchVideosReadyForMergeResponse
                     * @property {Array.<palexy.streaming.v1.IMergeItem>|null} [mergeItems] FetchVideosReadyForMergeResponse mergeItems
                     */
    
                    /**
                     * Constructs a new FetchVideosReadyForMergeResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchVideosReadyForMergeResponse.
                     * @implements IFetchVideosReadyForMergeResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchVideosReadyForMergeResponse=} [properties] Properties to set
                     */
                    function FetchVideosReadyForMergeResponse(properties) {
                        this.mergeItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchVideosReadyForMergeResponse mergeItems.
                     * @member {Array.<palexy.streaming.v1.IMergeItem>} mergeItems
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeResponse
                     * @instance
                     */
                    FetchVideosReadyForMergeResponse.prototype.mergeItems = $util.emptyArray;
    
                    /**
                     * Creates a new FetchVideosReadyForMergeResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForMergeResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchVideosReadyForMergeResponse} FetchVideosReadyForMergeResponse instance
                     */
                    FetchVideosReadyForMergeResponse.create = function create(properties) {
                        return new FetchVideosReadyForMergeResponse(properties);
                    };
    
                    /**
                     * Encodes the specified FetchVideosReadyForMergeResponse message. Does not implicitly {@link palexy.streaming.v1.FetchVideosReadyForMergeResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForMergeResponse} message FetchVideosReadyForMergeResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosReadyForMergeResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergeItems != null && message.mergeItems.length)
                            for (var i = 0; i < message.mergeItems.length; ++i)
                                $root.palexy.streaming.v1.MergeItem.encode(message.mergeItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchVideosReadyForMergeResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchVideosReadyForMergeResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForMergeResponse} message FetchVideosReadyForMergeResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosReadyForMergeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchVideosReadyForMergeResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchVideosReadyForMergeResponse} FetchVideosReadyForMergeResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosReadyForMergeResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchVideosReadyForMergeResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.mergeItems && message.mergeItems.length))
                                    message.mergeItems = [];
                                message.mergeItems.push($root.palexy.streaming.v1.MergeItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchVideosReadyForMergeResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchVideosReadyForMergeResponse} FetchVideosReadyForMergeResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosReadyForMergeResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchVideosReadyForMergeResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchVideosReadyForMergeResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergeItems != null && message.hasOwnProperty("mergeItems")) {
                            if (!Array.isArray(message.mergeItems))
                                return "mergeItems: array expected";
                            for (var i = 0; i < message.mergeItems.length; ++i) {
                                var error = $root.palexy.streaming.v1.MergeItem.verify(message.mergeItems[i]);
                                if (error)
                                    return "mergeItems." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a FetchVideosReadyForMergeResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchVideosReadyForMergeResponse} FetchVideosReadyForMergeResponse
                     */
                    FetchVideosReadyForMergeResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchVideosReadyForMergeResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchVideosReadyForMergeResponse();
                        if (object.mergeItems) {
                            if (!Array.isArray(object.mergeItems))
                                throw TypeError(".palexy.streaming.v1.FetchVideosReadyForMergeResponse.mergeItems: array expected");
                            message.mergeItems = [];
                            for (var i = 0; i < object.mergeItems.length; ++i) {
                                if (typeof object.mergeItems[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.FetchVideosReadyForMergeResponse.mergeItems: object expected");
                                message.mergeItems[i] = $root.palexy.streaming.v1.MergeItem.fromObject(object.mergeItems[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchVideosReadyForMergeResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeResponse
                     * @static
                     * @param {palexy.streaming.v1.FetchVideosReadyForMergeResponse} message FetchVideosReadyForMergeResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchVideosReadyForMergeResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.mergeItems = [];
                        if (message.mergeItems && message.mergeItems.length) {
                            object.mergeItems = [];
                            for (var j = 0; j < message.mergeItems.length; ++j)
                                object.mergeItems[j] = $root.palexy.streaming.v1.MergeItem.toObject(message.mergeItems[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this FetchVideosReadyForMergeResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchVideosReadyForMergeResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchVideosReadyForMergeResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchVideosReadyForMergeResponse;
                })();
    
                v1.FetchVideosReadyForPartialMergeRequest = (function() {
    
                    /**
                     * Properties of a FetchVideosReadyForPartialMergeRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchVideosReadyForPartialMergeRequest
                     * @property {number|null} [companyId] FetchVideosReadyForPartialMergeRequest companyId
                     * @property {string|null} [dateId] FetchVideosReadyForPartialMergeRequest dateId
                     * @property {string|null} [startTime] FetchVideosReadyForPartialMergeRequest startTime
                     * @property {string|null} [endTime] FetchVideosReadyForPartialMergeRequest endTime
                     * @property {string|null} [timezone] FetchVideosReadyForPartialMergeRequest timezone
                     * @property {number|null} [maxNumberOfItems] FetchVideosReadyForPartialMergeRequest maxNumberOfItems
                     * @property {number|null} [numSecondsToLookBack] FetchVideosReadyForPartialMergeRequest numSecondsToLookBack
                     */
    
                    /**
                     * Constructs a new FetchVideosReadyForPartialMergeRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchVideosReadyForPartialMergeRequest.
                     * @implements IFetchVideosReadyForPartialMergeRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchVideosReadyForPartialMergeRequest=} [properties] Properties to set
                     */
                    function FetchVideosReadyForPartialMergeRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchVideosReadyForPartialMergeRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @instance
                     */
                    FetchVideosReadyForPartialMergeRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * FetchVideosReadyForPartialMergeRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @instance
                     */
                    FetchVideosReadyForPartialMergeRequest.prototype.dateId = "";
    
                    /**
                     * FetchVideosReadyForPartialMergeRequest startTime.
                     * @member {string} startTime
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @instance
                     */
                    FetchVideosReadyForPartialMergeRequest.prototype.startTime = "";
    
                    /**
                     * FetchVideosReadyForPartialMergeRequest endTime.
                     * @member {string} endTime
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @instance
                     */
                    FetchVideosReadyForPartialMergeRequest.prototype.endTime = "";
    
                    /**
                     * FetchVideosReadyForPartialMergeRequest timezone.
                     * @member {string} timezone
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @instance
                     */
                    FetchVideosReadyForPartialMergeRequest.prototype.timezone = "";
    
                    /**
                     * FetchVideosReadyForPartialMergeRequest maxNumberOfItems.
                     * @member {number} maxNumberOfItems
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @instance
                     */
                    FetchVideosReadyForPartialMergeRequest.prototype.maxNumberOfItems = 0;
    
                    /**
                     * FetchVideosReadyForPartialMergeRequest numSecondsToLookBack.
                     * @member {number} numSecondsToLookBack
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @instance
                     */
                    FetchVideosReadyForPartialMergeRequest.prototype.numSecondsToLookBack = 0;
    
                    /**
                     * Creates a new FetchVideosReadyForPartialMergeRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForPartialMergeRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest} FetchVideosReadyForPartialMergeRequest instance
                     */
                    FetchVideosReadyForPartialMergeRequest.create = function create(properties) {
                        return new FetchVideosReadyForPartialMergeRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FetchVideosReadyForPartialMergeRequest message. Does not implicitly {@link palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForPartialMergeRequest} message FetchVideosReadyForPartialMergeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosReadyForPartialMergeRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.startTime);
                        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.endTime);
                        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.timezone);
                        if (message.maxNumberOfItems != null && Object.hasOwnProperty.call(message, "maxNumberOfItems"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.maxNumberOfItems);
                        if (message.numSecondsToLookBack != null && Object.hasOwnProperty.call(message, "numSecondsToLookBack"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.numSecondsToLookBack);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchVideosReadyForPartialMergeRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForPartialMergeRequest} message FetchVideosReadyForPartialMergeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosReadyForPartialMergeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchVideosReadyForPartialMergeRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest} FetchVideosReadyForPartialMergeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosReadyForPartialMergeRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            case 3:
                                message.startTime = reader.string();
                                break;
                            case 4:
                                message.endTime = reader.string();
                                break;
                            case 5:
                                message.timezone = reader.string();
                                break;
                            case 6:
                                message.maxNumberOfItems = reader.int32();
                                break;
                            case 7:
                                message.numSecondsToLookBack = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchVideosReadyForPartialMergeRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest} FetchVideosReadyForPartialMergeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosReadyForPartialMergeRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchVideosReadyForPartialMergeRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchVideosReadyForPartialMergeRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            if (!$util.isString(message.startTime))
                                return "startTime: string expected";
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            if (!$util.isString(message.endTime))
                                return "endTime: string expected";
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            if (!$util.isString(message.timezone))
                                return "timezone: string expected";
                        if (message.maxNumberOfItems != null && message.hasOwnProperty("maxNumberOfItems"))
                            if (!$util.isInteger(message.maxNumberOfItems))
                                return "maxNumberOfItems: integer expected";
                        if (message.numSecondsToLookBack != null && message.hasOwnProperty("numSecondsToLookBack"))
                            if (!$util.isInteger(message.numSecondsToLookBack))
                                return "numSecondsToLookBack: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a FetchVideosReadyForPartialMergeRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest} FetchVideosReadyForPartialMergeRequest
                     */
                    FetchVideosReadyForPartialMergeRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.startTime != null)
                            message.startTime = String(object.startTime);
                        if (object.endTime != null)
                            message.endTime = String(object.endTime);
                        if (object.timezone != null)
                            message.timezone = String(object.timezone);
                        if (object.maxNumberOfItems != null)
                            message.maxNumberOfItems = object.maxNumberOfItems | 0;
                        if (object.numSecondsToLookBack != null)
                            message.numSecondsToLookBack = object.numSecondsToLookBack | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchVideosReadyForPartialMergeRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @static
                     * @param {palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest} message FetchVideosReadyForPartialMergeRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchVideosReadyForPartialMergeRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.startTime = "";
                            object.endTime = "";
                            object.timezone = "";
                            object.maxNumberOfItems = 0;
                            object.numSecondsToLookBack = 0;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            object.startTime = message.startTime;
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            object.endTime = message.endTime;
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            object.timezone = message.timezone;
                        if (message.maxNumberOfItems != null && message.hasOwnProperty("maxNumberOfItems"))
                            object.maxNumberOfItems = message.maxNumberOfItems;
                        if (message.numSecondsToLookBack != null && message.hasOwnProperty("numSecondsToLookBack"))
                            object.numSecondsToLookBack = message.numSecondsToLookBack;
                        return object;
                    };
    
                    /**
                     * Converts this FetchVideosReadyForPartialMergeRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchVideosReadyForPartialMergeRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchVideosReadyForPartialMergeRequest;
                })();
    
                v1.FetchVideosReadyForPartialMergeResponse = (function() {
    
                    /**
                     * Properties of a FetchVideosReadyForPartialMergeResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchVideosReadyForPartialMergeResponse
                     * @property {Array.<palexy.streaming.v1.IMergeItem>|null} [mergeItems] FetchVideosReadyForPartialMergeResponse mergeItems
                     */
    
                    /**
                     * Constructs a new FetchVideosReadyForPartialMergeResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchVideosReadyForPartialMergeResponse.
                     * @implements IFetchVideosReadyForPartialMergeResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchVideosReadyForPartialMergeResponse=} [properties] Properties to set
                     */
                    function FetchVideosReadyForPartialMergeResponse(properties) {
                        this.mergeItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchVideosReadyForPartialMergeResponse mergeItems.
                     * @member {Array.<palexy.streaming.v1.IMergeItem>} mergeItems
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse
                     * @instance
                     */
                    FetchVideosReadyForPartialMergeResponse.prototype.mergeItems = $util.emptyArray;
    
                    /**
                     * Creates a new FetchVideosReadyForPartialMergeResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForPartialMergeResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse} FetchVideosReadyForPartialMergeResponse instance
                     */
                    FetchVideosReadyForPartialMergeResponse.create = function create(properties) {
                        return new FetchVideosReadyForPartialMergeResponse(properties);
                    };
    
                    /**
                     * Encodes the specified FetchVideosReadyForPartialMergeResponse message. Does not implicitly {@link palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForPartialMergeResponse} message FetchVideosReadyForPartialMergeResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosReadyForPartialMergeResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergeItems != null && message.mergeItems.length)
                            for (var i = 0; i < message.mergeItems.length; ++i)
                                $root.palexy.streaming.v1.MergeItem.encode(message.mergeItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchVideosReadyForPartialMergeResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosReadyForPartialMergeResponse} message FetchVideosReadyForPartialMergeResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosReadyForPartialMergeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchVideosReadyForPartialMergeResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse} FetchVideosReadyForPartialMergeResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosReadyForPartialMergeResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.mergeItems && message.mergeItems.length))
                                    message.mergeItems = [];
                                message.mergeItems.push($root.palexy.streaming.v1.MergeItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchVideosReadyForPartialMergeResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse} FetchVideosReadyForPartialMergeResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosReadyForPartialMergeResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchVideosReadyForPartialMergeResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchVideosReadyForPartialMergeResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergeItems != null && message.hasOwnProperty("mergeItems")) {
                            if (!Array.isArray(message.mergeItems))
                                return "mergeItems: array expected";
                            for (var i = 0; i < message.mergeItems.length; ++i) {
                                var error = $root.palexy.streaming.v1.MergeItem.verify(message.mergeItems[i]);
                                if (error)
                                    return "mergeItems." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a FetchVideosReadyForPartialMergeResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse} FetchVideosReadyForPartialMergeResponse
                     */
                    FetchVideosReadyForPartialMergeResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse();
                        if (object.mergeItems) {
                            if (!Array.isArray(object.mergeItems))
                                throw TypeError(".palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse.mergeItems: array expected");
                            message.mergeItems = [];
                            for (var i = 0; i < object.mergeItems.length; ++i) {
                                if (typeof object.mergeItems[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse.mergeItems: object expected");
                                message.mergeItems[i] = $root.palexy.streaming.v1.MergeItem.fromObject(object.mergeItems[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchVideosReadyForPartialMergeResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse
                     * @static
                     * @param {palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse} message FetchVideosReadyForPartialMergeResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchVideosReadyForPartialMergeResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.mergeItems = [];
                        if (message.mergeItems && message.mergeItems.length) {
                            object.mergeItems = [];
                            for (var j = 0; j < message.mergeItems.length; ++j)
                                object.mergeItems[j] = $root.palexy.streaming.v1.MergeItem.toObject(message.mergeItems[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this FetchVideosReadyForPartialMergeResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchVideosReadyForPartialMergeResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchVideosReadyForPartialMergeResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchVideosReadyForPartialMergeResponse;
                })();
    
                v1.FetchVideosForArchivingRequest = (function() {
    
                    /**
                     * Properties of a FetchVideosForArchivingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchVideosForArchivingRequest
                     * @property {Array.<number>|null} [storeIds] FetchVideosForArchivingRequest storeIds
                     * @property {boolean|null} [isTrackingFile] FetchVideosForArchivingRequest isTrackingFile
                     * @property {palexy.streaming.v1.IDateRange|null} [daterange] FetchVideosForArchivingRequest daterange
                     * @property {palexy.streaming.v1.IDateRange|null} [qcDaterange] FetchVideosForArchivingRequest qcDaterange
                     * @property {number|null} [pageSize] FetchVideosForArchivingRequest pageSize
                     * @property {string|null} [pageToken] FetchVideosForArchivingRequest pageToken
                     */
    
                    /**
                     * Constructs a new FetchVideosForArchivingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchVideosForArchivingRequest.
                     * @implements IFetchVideosForArchivingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchVideosForArchivingRequest=} [properties] Properties to set
                     */
                    function FetchVideosForArchivingRequest(properties) {
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchVideosForArchivingRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @instance
                     */
                    FetchVideosForArchivingRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * FetchVideosForArchivingRequest isTrackingFile.
                     * @member {boolean} isTrackingFile
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @instance
                     */
                    FetchVideosForArchivingRequest.prototype.isTrackingFile = false;
    
                    /**
                     * FetchVideosForArchivingRequest daterange.
                     * @member {palexy.streaming.v1.IDateRange|null|undefined} daterange
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @instance
                     */
                    FetchVideosForArchivingRequest.prototype.daterange = null;
    
                    /**
                     * FetchVideosForArchivingRequest qcDaterange.
                     * @member {palexy.streaming.v1.IDateRange|null|undefined} qcDaterange
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @instance
                     */
                    FetchVideosForArchivingRequest.prototype.qcDaterange = null;
    
                    /**
                     * FetchVideosForArchivingRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @instance
                     */
                    FetchVideosForArchivingRequest.prototype.pageSize = 0;
    
                    /**
                     * FetchVideosForArchivingRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @instance
                     */
                    FetchVideosForArchivingRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new FetchVideosForArchivingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosForArchivingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchVideosForArchivingRequest} FetchVideosForArchivingRequest instance
                     */
                    FetchVideosForArchivingRequest.create = function create(properties) {
                        return new FetchVideosForArchivingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FetchVideosForArchivingRequest message. Does not implicitly {@link palexy.streaming.v1.FetchVideosForArchivingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosForArchivingRequest} message FetchVideosForArchivingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosForArchivingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.isTrackingFile != null && Object.hasOwnProperty.call(message, "isTrackingFile"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isTrackingFile);
                        if (message.daterange != null && Object.hasOwnProperty.call(message, "daterange"))
                            $root.palexy.streaming.v1.DateRange.encode(message.daterange, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.qcDaterange != null && Object.hasOwnProperty.call(message, "qcDaterange"))
                            $root.palexy.streaming.v1.DateRange.encode(message.qcDaterange, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchVideosForArchivingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchVideosForArchivingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosForArchivingRequest} message FetchVideosForArchivingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosForArchivingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchVideosForArchivingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchVideosForArchivingRequest} FetchVideosForArchivingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosForArchivingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchVideosForArchivingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 2:
                                message.isTrackingFile = reader.bool();
                                break;
                            case 3:
                                message.daterange = $root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.qcDaterange = $root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.pageSize = reader.int32();
                                break;
                            case 6:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchVideosForArchivingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchVideosForArchivingRequest} FetchVideosForArchivingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosForArchivingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchVideosForArchivingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchVideosForArchivingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.isTrackingFile != null && message.hasOwnProperty("isTrackingFile"))
                            if (typeof message.isTrackingFile !== "boolean")
                                return "isTrackingFile: boolean expected";
                        if (message.daterange != null && message.hasOwnProperty("daterange")) {
                            var error = $root.palexy.streaming.v1.DateRange.verify(message.daterange);
                            if (error)
                                return "daterange." + error;
                        }
                        if (message.qcDaterange != null && message.hasOwnProperty("qcDaterange")) {
                            var error = $root.palexy.streaming.v1.DateRange.verify(message.qcDaterange);
                            if (error)
                                return "qcDaterange." + error;
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a FetchVideosForArchivingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchVideosForArchivingRequest} FetchVideosForArchivingRequest
                     */
                    FetchVideosForArchivingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchVideosForArchivingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchVideosForArchivingRequest();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.streaming.v1.FetchVideosForArchivingRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.isTrackingFile != null)
                            message.isTrackingFile = Boolean(object.isTrackingFile);
                        if (object.daterange != null) {
                            if (typeof object.daterange !== "object")
                                throw TypeError(".palexy.streaming.v1.FetchVideosForArchivingRequest.daterange: object expected");
                            message.daterange = $root.palexy.streaming.v1.DateRange.fromObject(object.daterange);
                        }
                        if (object.qcDaterange != null) {
                            if (typeof object.qcDaterange !== "object")
                                throw TypeError(".palexy.streaming.v1.FetchVideosForArchivingRequest.qcDaterange: object expected");
                            message.qcDaterange = $root.palexy.streaming.v1.DateRange.fromObject(object.qcDaterange);
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchVideosForArchivingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @static
                     * @param {palexy.streaming.v1.FetchVideosForArchivingRequest} message FetchVideosForArchivingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchVideosForArchivingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeIds = [];
                        if (options.defaults) {
                            object.isTrackingFile = false;
                            object.daterange = null;
                            object.qcDaterange = null;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.isTrackingFile != null && message.hasOwnProperty("isTrackingFile"))
                            object.isTrackingFile = message.isTrackingFile;
                        if (message.daterange != null && message.hasOwnProperty("daterange"))
                            object.daterange = $root.palexy.streaming.v1.DateRange.toObject(message.daterange, options);
                        if (message.qcDaterange != null && message.hasOwnProperty("qcDaterange"))
                            object.qcDaterange = $root.palexy.streaming.v1.DateRange.toObject(message.qcDaterange, options);
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this FetchVideosForArchivingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchVideosForArchivingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchVideosForArchivingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchVideosForArchivingRequest;
                })();
    
                v1.FetchVideosForCleaningUpRequest = (function() {
    
                    /**
                     * Properties of a FetchVideosForCleaningUpRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchVideosForCleaningUpRequest
                     * @property {Array.<number>|null} [storeIds] FetchVideosForCleaningUpRequest storeIds
                     * @property {boolean|null} [isTrackingFile] FetchVideosForCleaningUpRequest isTrackingFile
                     * @property {palexy.streaming.v1.IDateRange|null} [daterange] FetchVideosForCleaningUpRequest daterange
                     * @property {palexy.streaming.v1.IDateRange|null} [qcDaterange] FetchVideosForCleaningUpRequest qcDaterange
                     * @property {number|null} [pageSize] FetchVideosForCleaningUpRequest pageSize
                     * @property {string|null} [pageToken] FetchVideosForCleaningUpRequest pageToken
                     */
    
                    /**
                     * Constructs a new FetchVideosForCleaningUpRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchVideosForCleaningUpRequest.
                     * @implements IFetchVideosForCleaningUpRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchVideosForCleaningUpRequest=} [properties] Properties to set
                     */
                    function FetchVideosForCleaningUpRequest(properties) {
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchVideosForCleaningUpRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @instance
                     */
                    FetchVideosForCleaningUpRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * FetchVideosForCleaningUpRequest isTrackingFile.
                     * @member {boolean} isTrackingFile
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @instance
                     */
                    FetchVideosForCleaningUpRequest.prototype.isTrackingFile = false;
    
                    /**
                     * FetchVideosForCleaningUpRequest daterange.
                     * @member {palexy.streaming.v1.IDateRange|null|undefined} daterange
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @instance
                     */
                    FetchVideosForCleaningUpRequest.prototype.daterange = null;
    
                    /**
                     * FetchVideosForCleaningUpRequest qcDaterange.
                     * @member {palexy.streaming.v1.IDateRange|null|undefined} qcDaterange
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @instance
                     */
                    FetchVideosForCleaningUpRequest.prototype.qcDaterange = null;
    
                    /**
                     * FetchVideosForCleaningUpRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @instance
                     */
                    FetchVideosForCleaningUpRequest.prototype.pageSize = 0;
    
                    /**
                     * FetchVideosForCleaningUpRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @instance
                     */
                    FetchVideosForCleaningUpRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new FetchVideosForCleaningUpRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosForCleaningUpRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchVideosForCleaningUpRequest} FetchVideosForCleaningUpRequest instance
                     */
                    FetchVideosForCleaningUpRequest.create = function create(properties) {
                        return new FetchVideosForCleaningUpRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FetchVideosForCleaningUpRequest message. Does not implicitly {@link palexy.streaming.v1.FetchVideosForCleaningUpRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosForCleaningUpRequest} message FetchVideosForCleaningUpRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosForCleaningUpRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.isTrackingFile != null && Object.hasOwnProperty.call(message, "isTrackingFile"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isTrackingFile);
                        if (message.daterange != null && Object.hasOwnProperty.call(message, "daterange"))
                            $root.palexy.streaming.v1.DateRange.encode(message.daterange, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.qcDaterange != null && Object.hasOwnProperty.call(message, "qcDaterange"))
                            $root.palexy.streaming.v1.DateRange.encode(message.qcDaterange, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchVideosForCleaningUpRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchVideosForCleaningUpRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchVideosForCleaningUpRequest} message FetchVideosForCleaningUpRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchVideosForCleaningUpRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchVideosForCleaningUpRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchVideosForCleaningUpRequest} FetchVideosForCleaningUpRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosForCleaningUpRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchVideosForCleaningUpRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 2:
                                message.isTrackingFile = reader.bool();
                                break;
                            case 3:
                                message.daterange = $root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.qcDaterange = $root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.pageSize = reader.int32();
                                break;
                            case 6:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchVideosForCleaningUpRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchVideosForCleaningUpRequest} FetchVideosForCleaningUpRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchVideosForCleaningUpRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchVideosForCleaningUpRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchVideosForCleaningUpRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.isTrackingFile != null && message.hasOwnProperty("isTrackingFile"))
                            if (typeof message.isTrackingFile !== "boolean")
                                return "isTrackingFile: boolean expected";
                        if (message.daterange != null && message.hasOwnProperty("daterange")) {
                            var error = $root.palexy.streaming.v1.DateRange.verify(message.daterange);
                            if (error)
                                return "daterange." + error;
                        }
                        if (message.qcDaterange != null && message.hasOwnProperty("qcDaterange")) {
                            var error = $root.palexy.streaming.v1.DateRange.verify(message.qcDaterange);
                            if (error)
                                return "qcDaterange." + error;
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a FetchVideosForCleaningUpRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchVideosForCleaningUpRequest} FetchVideosForCleaningUpRequest
                     */
                    FetchVideosForCleaningUpRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchVideosForCleaningUpRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchVideosForCleaningUpRequest();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.streaming.v1.FetchVideosForCleaningUpRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.isTrackingFile != null)
                            message.isTrackingFile = Boolean(object.isTrackingFile);
                        if (object.daterange != null) {
                            if (typeof object.daterange !== "object")
                                throw TypeError(".palexy.streaming.v1.FetchVideosForCleaningUpRequest.daterange: object expected");
                            message.daterange = $root.palexy.streaming.v1.DateRange.fromObject(object.daterange);
                        }
                        if (object.qcDaterange != null) {
                            if (typeof object.qcDaterange !== "object")
                                throw TypeError(".palexy.streaming.v1.FetchVideosForCleaningUpRequest.qcDaterange: object expected");
                            message.qcDaterange = $root.palexy.streaming.v1.DateRange.fromObject(object.qcDaterange);
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchVideosForCleaningUpRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @static
                     * @param {palexy.streaming.v1.FetchVideosForCleaningUpRequest} message FetchVideosForCleaningUpRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchVideosForCleaningUpRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeIds = [];
                        if (options.defaults) {
                            object.isTrackingFile = false;
                            object.daterange = null;
                            object.qcDaterange = null;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.isTrackingFile != null && message.hasOwnProperty("isTrackingFile"))
                            object.isTrackingFile = message.isTrackingFile;
                        if (message.daterange != null && message.hasOwnProperty("daterange"))
                            object.daterange = $root.palexy.streaming.v1.DateRange.toObject(message.daterange, options);
                        if (message.qcDaterange != null && message.hasOwnProperty("qcDaterange"))
                            object.qcDaterange = $root.palexy.streaming.v1.DateRange.toObject(message.qcDaterange, options);
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this FetchVideosForCleaningUpRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchVideosForCleaningUpRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchVideosForCleaningUpRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchVideosForCleaningUpRequest;
                })();
    
                v1.FetchStoreSnapshotsForCleaningUpRequest = (function() {
    
                    /**
                     * Properties of a FetchStoreSnapshotsForCleaningUpRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchStoreSnapshotsForCleaningUpRequest
                     * @property {number|null} [pageSize] FetchStoreSnapshotsForCleaningUpRequest pageSize
                     * @property {string|null} [pageToken] FetchStoreSnapshotsForCleaningUpRequest pageToken
                     */
    
                    /**
                     * Constructs a new FetchStoreSnapshotsForCleaningUpRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchStoreSnapshotsForCleaningUpRequest.
                     * @implements IFetchStoreSnapshotsForCleaningUpRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchStoreSnapshotsForCleaningUpRequest=} [properties] Properties to set
                     */
                    function FetchStoreSnapshotsForCleaningUpRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchStoreSnapshotsForCleaningUpRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest
                     * @instance
                     */
                    FetchStoreSnapshotsForCleaningUpRequest.prototype.pageSize = 0;
    
                    /**
                     * FetchStoreSnapshotsForCleaningUpRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest
                     * @instance
                     */
                    FetchStoreSnapshotsForCleaningUpRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new FetchStoreSnapshotsForCleaningUpRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchStoreSnapshotsForCleaningUpRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest} FetchStoreSnapshotsForCleaningUpRequest instance
                     */
                    FetchStoreSnapshotsForCleaningUpRequest.create = function create(properties) {
                        return new FetchStoreSnapshotsForCleaningUpRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FetchStoreSnapshotsForCleaningUpRequest message. Does not implicitly {@link palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchStoreSnapshotsForCleaningUpRequest} message FetchStoreSnapshotsForCleaningUpRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchStoreSnapshotsForCleaningUpRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchStoreSnapshotsForCleaningUpRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchStoreSnapshotsForCleaningUpRequest} message FetchStoreSnapshotsForCleaningUpRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchStoreSnapshotsForCleaningUpRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchStoreSnapshotsForCleaningUpRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest} FetchStoreSnapshotsForCleaningUpRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchStoreSnapshotsForCleaningUpRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.pageSize = reader.int32();
                                break;
                            case 2:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchStoreSnapshotsForCleaningUpRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest} FetchStoreSnapshotsForCleaningUpRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchStoreSnapshotsForCleaningUpRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchStoreSnapshotsForCleaningUpRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchStoreSnapshotsForCleaningUpRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a FetchStoreSnapshotsForCleaningUpRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest} FetchStoreSnapshotsForCleaningUpRequest
                     */
                    FetchStoreSnapshotsForCleaningUpRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest();
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchStoreSnapshotsForCleaningUpRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest
                     * @static
                     * @param {palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest} message FetchStoreSnapshotsForCleaningUpRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchStoreSnapshotsForCleaningUpRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this FetchStoreSnapshotsForCleaningUpRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchStoreSnapshotsForCleaningUpRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FetchStoreSnapshotsForCleaningUpRequest;
                })();
    
                v1.MergeItem = (function() {
    
                    /**
                     * Properties of a MergeItem.
                     * @memberof palexy.streaming.v1
                     * @interface IMergeItem
                     * @property {number|null} [areaId] MergeItem areaId
                     * @property {string|null} [dateId] MergeItem dateId
                     * @property {Array.<palexy.streaming.v1.IVideo>|null} [videos] MergeItem videos
                     * @property {number|null} [storeId] MergeItem storeId
                     * @property {palexy.streaming.v1.MergeItem.MergeType|null} [mergeType] MergeItem mergeType
                     * @property {string|null} [partialMergeStartTime] MergeItem partialMergeStartTime
                     * @property {string|null} [partialMergeEndTime] MergeItem partialMergeEndTime
                     * @property {string|null} [mergeId] MergeItem mergeId
                     */
    
                    /**
                     * Constructs a new MergeItem.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MergeItem.
                     * @implements IMergeItem
                     * @constructor
                     * @param {palexy.streaming.v1.IMergeItem=} [properties] Properties to set
                     */
                    function MergeItem(properties) {
                        this.videos = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MergeItem areaId.
                     * @member {number} areaId
                     * @memberof palexy.streaming.v1.MergeItem
                     * @instance
                     */
                    MergeItem.prototype.areaId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MergeItem dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.MergeItem
                     * @instance
                     */
                    MergeItem.prototype.dateId = "";
    
                    /**
                     * MergeItem videos.
                     * @member {Array.<palexy.streaming.v1.IVideo>} videos
                     * @memberof palexy.streaming.v1.MergeItem
                     * @instance
                     */
                    MergeItem.prototype.videos = $util.emptyArray;
    
                    /**
                     * MergeItem storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.MergeItem
                     * @instance
                     */
                    MergeItem.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MergeItem mergeType.
                     * @member {palexy.streaming.v1.MergeItem.MergeType} mergeType
                     * @memberof palexy.streaming.v1.MergeItem
                     * @instance
                     */
                    MergeItem.prototype.mergeType = 0;
    
                    /**
                     * MergeItem partialMergeStartTime.
                     * @member {string} partialMergeStartTime
                     * @memberof palexy.streaming.v1.MergeItem
                     * @instance
                     */
                    MergeItem.prototype.partialMergeStartTime = "";
    
                    /**
                     * MergeItem partialMergeEndTime.
                     * @member {string} partialMergeEndTime
                     * @memberof palexy.streaming.v1.MergeItem
                     * @instance
                     */
                    MergeItem.prototype.partialMergeEndTime = "";
    
                    /**
                     * MergeItem mergeId.
                     * @member {string} mergeId
                     * @memberof palexy.streaming.v1.MergeItem
                     * @instance
                     */
                    MergeItem.prototype.mergeId = "";
    
                    /**
                     * Creates a new MergeItem instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MergeItem
                     * @static
                     * @param {palexy.streaming.v1.IMergeItem=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MergeItem} MergeItem instance
                     */
                    MergeItem.create = function create(properties) {
                        return new MergeItem(properties);
                    };
    
                    /**
                     * Encodes the specified MergeItem message. Does not implicitly {@link palexy.streaming.v1.MergeItem.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MergeItem
                     * @static
                     * @param {palexy.streaming.v1.IMergeItem} message MergeItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergeItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.areaId != null && Object.hasOwnProperty.call(message, "areaId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.areaId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        if (message.videos != null && message.videos.length)
                            for (var i = 0; i < message.videos.length; ++i)
                                $root.palexy.streaming.v1.Video.encode(message.videos[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.storeId);
                        if (message.mergeType != null && Object.hasOwnProperty.call(message, "mergeType"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.mergeType);
                        if (message.partialMergeStartTime != null && Object.hasOwnProperty.call(message, "partialMergeStartTime"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.partialMergeStartTime);
                        if (message.partialMergeEndTime != null && Object.hasOwnProperty.call(message, "partialMergeEndTime"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.partialMergeEndTime);
                        if (message.mergeId != null && Object.hasOwnProperty.call(message, "mergeId"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.mergeId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MergeItem message, length delimited. Does not implicitly {@link palexy.streaming.v1.MergeItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MergeItem
                     * @static
                     * @param {palexy.streaming.v1.IMergeItem} message MergeItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergeItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MergeItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MergeItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MergeItem} MergeItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergeItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MergeItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.areaId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            case 3:
                                if (!(message.videos && message.videos.length))
                                    message.videos = [];
                                message.videos.push($root.palexy.streaming.v1.Video.decode(reader, reader.uint32()));
                                break;
                            case 4:
                                message.storeId = reader.int64();
                                break;
                            case 5:
                                message.mergeType = reader.int32();
                                break;
                            case 6:
                                message.partialMergeStartTime = reader.string();
                                break;
                            case 7:
                                message.partialMergeEndTime = reader.string();
                                break;
                            case 8:
                                message.mergeId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MergeItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MergeItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MergeItem} MergeItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergeItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MergeItem message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MergeItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MergeItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.areaId != null && message.hasOwnProperty("areaId"))
                            if (!$util.isInteger(message.areaId) && !(message.areaId && $util.isInteger(message.areaId.low) && $util.isInteger(message.areaId.high)))
                                return "areaId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.videos != null && message.hasOwnProperty("videos")) {
                            if (!Array.isArray(message.videos))
                                return "videos: array expected";
                            for (var i = 0; i < message.videos.length; ++i) {
                                var error = $root.palexy.streaming.v1.Video.verify(message.videos[i]);
                                if (error)
                                    return "videos." + error;
                            }
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.mergeType != null && message.hasOwnProperty("mergeType"))
                            switch (message.mergeType) {
                            default:
                                return "mergeType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.partialMergeStartTime != null && message.hasOwnProperty("partialMergeStartTime"))
                            if (!$util.isString(message.partialMergeStartTime))
                                return "partialMergeStartTime: string expected";
                        if (message.partialMergeEndTime != null && message.hasOwnProperty("partialMergeEndTime"))
                            if (!$util.isString(message.partialMergeEndTime))
                                return "partialMergeEndTime: string expected";
                        if (message.mergeId != null && message.hasOwnProperty("mergeId"))
                            if (!$util.isString(message.mergeId))
                                return "mergeId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a MergeItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MergeItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MergeItem} MergeItem
                     */
                    MergeItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MergeItem)
                            return object;
                        var message = new $root.palexy.streaming.v1.MergeItem();
                        if (object.areaId != null)
                            if ($util.Long)
                                (message.areaId = $util.Long.fromValue(object.areaId)).unsigned = false;
                            else if (typeof object.areaId === "string")
                                message.areaId = parseInt(object.areaId, 10);
                            else if (typeof object.areaId === "number")
                                message.areaId = object.areaId;
                            else if (typeof object.areaId === "object")
                                message.areaId = new $util.LongBits(object.areaId.low >>> 0, object.areaId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.videos) {
                            if (!Array.isArray(object.videos))
                                throw TypeError(".palexy.streaming.v1.MergeItem.videos: array expected");
                            message.videos = [];
                            for (var i = 0; i < object.videos.length; ++i) {
                                if (typeof object.videos[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.MergeItem.videos: object expected");
                                message.videos[i] = $root.palexy.streaming.v1.Video.fromObject(object.videos[i]);
                            }
                        }
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        switch (object.mergeType) {
                        case "UNKNOWN":
                        case 0:
                            message.mergeType = 0;
                            break;
                        case "FULL_DAY_MERGE":
                        case 1:
                            message.mergeType = 1;
                            break;
                        case "PARTIAL_MERGE":
                        case 2:
                            message.mergeType = 2;
                            break;
                        }
                        if (object.partialMergeStartTime != null)
                            message.partialMergeStartTime = String(object.partialMergeStartTime);
                        if (object.partialMergeEndTime != null)
                            message.partialMergeEndTime = String(object.partialMergeEndTime);
                        if (object.mergeId != null)
                            message.mergeId = String(object.mergeId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MergeItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MergeItem
                     * @static
                     * @param {palexy.streaming.v1.MergeItem} message MergeItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MergeItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.videos = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.areaId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.areaId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.mergeType = options.enums === String ? "UNKNOWN" : 0;
                            object.partialMergeStartTime = "";
                            object.partialMergeEndTime = "";
                            object.mergeId = "";
                        }
                        if (message.areaId != null && message.hasOwnProperty("areaId"))
                            if (typeof message.areaId === "number")
                                object.areaId = options.longs === String ? String(message.areaId) : message.areaId;
                            else
                                object.areaId = options.longs === String ? $util.Long.prototype.toString.call(message.areaId) : options.longs === Number ? new $util.LongBits(message.areaId.low >>> 0, message.areaId.high >>> 0).toNumber() : message.areaId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.videos && message.videos.length) {
                            object.videos = [];
                            for (var j = 0; j < message.videos.length; ++j)
                                object.videos[j] = $root.palexy.streaming.v1.Video.toObject(message.videos[j], options);
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.mergeType != null && message.hasOwnProperty("mergeType"))
                            object.mergeType = options.enums === String ? $root.palexy.streaming.v1.MergeItem.MergeType[message.mergeType] : message.mergeType;
                        if (message.partialMergeStartTime != null && message.hasOwnProperty("partialMergeStartTime"))
                            object.partialMergeStartTime = message.partialMergeStartTime;
                        if (message.partialMergeEndTime != null && message.hasOwnProperty("partialMergeEndTime"))
                            object.partialMergeEndTime = message.partialMergeEndTime;
                        if (message.mergeId != null && message.hasOwnProperty("mergeId"))
                            object.mergeId = message.mergeId;
                        return object;
                    };
    
                    /**
                     * Converts this MergeItem to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MergeItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MergeItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * MergeType enum.
                     * @name palexy.streaming.v1.MergeItem.MergeType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} FULL_DAY_MERGE=1 FULL_DAY_MERGE value
                     * @property {number} PARTIAL_MERGE=2 PARTIAL_MERGE value
                     */
                    MergeItem.MergeType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "FULL_DAY_MERGE"] = 1;
                        values[valuesById[2] = "PARTIAL_MERGE"] = 2;
                        return values;
                    })();
    
                    return MergeItem;
                })();
    
                v1.MergeItemWithAckId = (function() {
    
                    /**
                     * Properties of a MergeItemWithAckId.
                     * @memberof palexy.streaming.v1
                     * @interface IMergeItemWithAckId
                     * @property {palexy.streaming.v1.IMergeItem|null} [mergeItem] MergeItemWithAckId mergeItem
                     * @property {string|null} [ackId] MergeItemWithAckId ackId
                     */
    
                    /**
                     * Constructs a new MergeItemWithAckId.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MergeItemWithAckId.
                     * @implements IMergeItemWithAckId
                     * @constructor
                     * @param {palexy.streaming.v1.IMergeItemWithAckId=} [properties] Properties to set
                     */
                    function MergeItemWithAckId(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MergeItemWithAckId mergeItem.
                     * @member {palexy.streaming.v1.IMergeItem|null|undefined} mergeItem
                     * @memberof palexy.streaming.v1.MergeItemWithAckId
                     * @instance
                     */
                    MergeItemWithAckId.prototype.mergeItem = null;
    
                    /**
                     * MergeItemWithAckId ackId.
                     * @member {string} ackId
                     * @memberof palexy.streaming.v1.MergeItemWithAckId
                     * @instance
                     */
                    MergeItemWithAckId.prototype.ackId = "";
    
                    /**
                     * Creates a new MergeItemWithAckId instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MergeItemWithAckId
                     * @static
                     * @param {palexy.streaming.v1.IMergeItemWithAckId=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MergeItemWithAckId} MergeItemWithAckId instance
                     */
                    MergeItemWithAckId.create = function create(properties) {
                        return new MergeItemWithAckId(properties);
                    };
    
                    /**
                     * Encodes the specified MergeItemWithAckId message. Does not implicitly {@link palexy.streaming.v1.MergeItemWithAckId.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MergeItemWithAckId
                     * @static
                     * @param {palexy.streaming.v1.IMergeItemWithAckId} message MergeItemWithAckId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergeItemWithAckId.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergeItem != null && Object.hasOwnProperty.call(message, "mergeItem"))
                            $root.palexy.streaming.v1.MergeItem.encode(message.mergeItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.ackId != null && Object.hasOwnProperty.call(message, "ackId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.ackId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MergeItemWithAckId message, length delimited. Does not implicitly {@link palexy.streaming.v1.MergeItemWithAckId.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MergeItemWithAckId
                     * @static
                     * @param {palexy.streaming.v1.IMergeItemWithAckId} message MergeItemWithAckId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergeItemWithAckId.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MergeItemWithAckId message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MergeItemWithAckId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MergeItemWithAckId} MergeItemWithAckId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergeItemWithAckId.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MergeItemWithAckId();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.mergeItem = $root.palexy.streaming.v1.MergeItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.ackId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MergeItemWithAckId message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MergeItemWithAckId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MergeItemWithAckId} MergeItemWithAckId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergeItemWithAckId.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MergeItemWithAckId message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MergeItemWithAckId
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MergeItemWithAckId.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergeItem != null && message.hasOwnProperty("mergeItem")) {
                            var error = $root.palexy.streaming.v1.MergeItem.verify(message.mergeItem);
                            if (error)
                                return "mergeItem." + error;
                        }
                        if (message.ackId != null && message.hasOwnProperty("ackId"))
                            if (!$util.isString(message.ackId))
                                return "ackId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a MergeItemWithAckId message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MergeItemWithAckId
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MergeItemWithAckId} MergeItemWithAckId
                     */
                    MergeItemWithAckId.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MergeItemWithAckId)
                            return object;
                        var message = new $root.palexy.streaming.v1.MergeItemWithAckId();
                        if (object.mergeItem != null) {
                            if (typeof object.mergeItem !== "object")
                                throw TypeError(".palexy.streaming.v1.MergeItemWithAckId.mergeItem: object expected");
                            message.mergeItem = $root.palexy.streaming.v1.MergeItem.fromObject(object.mergeItem);
                        }
                        if (object.ackId != null)
                            message.ackId = String(object.ackId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MergeItemWithAckId message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MergeItemWithAckId
                     * @static
                     * @param {palexy.streaming.v1.MergeItemWithAckId} message MergeItemWithAckId
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MergeItemWithAckId.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.mergeItem = null;
                            object.ackId = "";
                        }
                        if (message.mergeItem != null && message.hasOwnProperty("mergeItem"))
                            object.mergeItem = $root.palexy.streaming.v1.MergeItem.toObject(message.mergeItem, options);
                        if (message.ackId != null && message.hasOwnProperty("ackId"))
                            object.ackId = message.ackId;
                        return object;
                    };
    
                    /**
                     * Converts this MergeItemWithAckId to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MergeItemWithAckId
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MergeItemWithAckId.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MergeItemWithAckId;
                })();
    
                v1.CreateVideoRequest = (function() {
    
                    /**
                     * Properties of a CreateVideoRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateVideoRequest
                     * @property {string|null} [parent] CreateVideoRequest parent
                     * @property {palexy.streaming.v1.IVideo|null} [video] CreateVideoRequest video
                     * @property {string|null} [processingQueueName] CreateVideoRequest processingQueueName
                     */
    
                    /**
                     * Constructs a new CreateVideoRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateVideoRequest.
                     * @implements ICreateVideoRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateVideoRequest=} [properties] Properties to set
                     */
                    function CreateVideoRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateVideoRequest parent.
                     * @member {string} parent
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @instance
                     */
                    CreateVideoRequest.prototype.parent = "";
    
                    /**
                     * CreateVideoRequest video.
                     * @member {palexy.streaming.v1.IVideo|null|undefined} video
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @instance
                     */
                    CreateVideoRequest.prototype.video = null;
    
                    /**
                     * CreateVideoRequest processingQueueName.
                     * @member {string} processingQueueName
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @instance
                     */
                    CreateVideoRequest.prototype.processingQueueName = "";
    
                    /**
                     * Creates a new CreateVideoRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateVideoRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateVideoRequest} CreateVideoRequest instance
                     */
                    CreateVideoRequest.create = function create(properties) {
                        return new CreateVideoRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateVideoRequest message. Does not implicitly {@link palexy.streaming.v1.CreateVideoRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateVideoRequest} message CreateVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateVideoRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.parent != null && Object.hasOwnProperty.call(message, "parent"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.parent);
                        if (message.video != null && Object.hasOwnProperty.call(message, "video"))
                            $root.palexy.streaming.v1.Video.encode(message.video, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.processingQueueName != null && Object.hasOwnProperty.call(message, "processingQueueName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.processingQueueName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateVideoRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateVideoRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateVideoRequest} message CreateVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateVideoRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateVideoRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateVideoRequest} CreateVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateVideoRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateVideoRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.parent = reader.string();
                                break;
                            case 2:
                                message.video = $root.palexy.streaming.v1.Video.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.processingQueueName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateVideoRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateVideoRequest} CreateVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateVideoRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateVideoRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateVideoRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.parent != null && message.hasOwnProperty("parent"))
                            if (!$util.isString(message.parent))
                                return "parent: string expected";
                        if (message.video != null && message.hasOwnProperty("video")) {
                            var error = $root.palexy.streaming.v1.Video.verify(message.video);
                            if (error)
                                return "video." + error;
                        }
                        if (message.processingQueueName != null && message.hasOwnProperty("processingQueueName"))
                            if (!$util.isString(message.processingQueueName))
                                return "processingQueueName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateVideoRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateVideoRequest} CreateVideoRequest
                     */
                    CreateVideoRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateVideoRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateVideoRequest();
                        if (object.parent != null)
                            message.parent = String(object.parent);
                        if (object.video != null) {
                            if (typeof object.video !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateVideoRequest.video: object expected");
                            message.video = $root.palexy.streaming.v1.Video.fromObject(object.video);
                        }
                        if (object.processingQueueName != null)
                            message.processingQueueName = String(object.processingQueueName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateVideoRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateVideoRequest} message CreateVideoRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateVideoRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.parent = "";
                            object.video = null;
                            object.processingQueueName = "";
                        }
                        if (message.parent != null && message.hasOwnProperty("parent"))
                            object.parent = message.parent;
                        if (message.video != null && message.hasOwnProperty("video"))
                            object.video = $root.palexy.streaming.v1.Video.toObject(message.video, options);
                        if (message.processingQueueName != null && message.hasOwnProperty("processingQueueName"))
                            object.processingQueueName = message.processingQueueName;
                        return object;
                    };
    
                    /**
                     * Converts this CreateVideoRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateVideoRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateVideoRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateVideoRequest;
                })();
    
                v1.UpdateVideoRequest = (function() {
    
                    /**
                     * Properties of an UpdateVideoRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateVideoRequest
                     * @property {palexy.streaming.v1.IVideo|null} [video] UpdateVideoRequest video
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateVideoRequest updateMask
                     * @property {boolean|null} [needPushToQueue] UpdateVideoRequest needPushToQueue
                     * @property {string|null} [queueName] UpdateVideoRequest queueName
                     * @property {Array.<palexy.streaming.v1.IVideo>|null} [additionalVideosToCreate] UpdateVideoRequest additionalVideosToCreate
                     */
    
                    /**
                     * Constructs a new UpdateVideoRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateVideoRequest.
                     * @implements IUpdateVideoRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateVideoRequest=} [properties] Properties to set
                     */
                    function UpdateVideoRequest(properties) {
                        this.additionalVideosToCreate = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateVideoRequest video.
                     * @member {palexy.streaming.v1.IVideo|null|undefined} video
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @instance
                     */
                    UpdateVideoRequest.prototype.video = null;
    
                    /**
                     * UpdateVideoRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @instance
                     */
                    UpdateVideoRequest.prototype.updateMask = null;
    
                    /**
                     * UpdateVideoRequest needPushToQueue.
                     * @member {boolean} needPushToQueue
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @instance
                     */
                    UpdateVideoRequest.prototype.needPushToQueue = false;
    
                    /**
                     * UpdateVideoRequest queueName.
                     * @member {string} queueName
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @instance
                     */
                    UpdateVideoRequest.prototype.queueName = "";
    
                    /**
                     * UpdateVideoRequest additionalVideosToCreate.
                     * @member {Array.<palexy.streaming.v1.IVideo>} additionalVideosToCreate
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @instance
                     */
                    UpdateVideoRequest.prototype.additionalVideosToCreate = $util.emptyArray;
    
                    /**
                     * Creates a new UpdateVideoRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateVideoRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateVideoRequest} UpdateVideoRequest instance
                     */
                    UpdateVideoRequest.create = function create(properties) {
                        return new UpdateVideoRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateVideoRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateVideoRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateVideoRequest} message UpdateVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateVideoRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.video != null && Object.hasOwnProperty.call(message, "video"))
                            $root.palexy.streaming.v1.Video.encode(message.video, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.needPushToQueue != null && Object.hasOwnProperty.call(message, "needPushToQueue"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.needPushToQueue);
                        if (message.queueName != null && Object.hasOwnProperty.call(message, "queueName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.queueName);
                        if (message.additionalVideosToCreate != null && message.additionalVideosToCreate.length)
                            for (var i = 0; i < message.additionalVideosToCreate.length; ++i)
                                $root.palexy.streaming.v1.Video.encode(message.additionalVideosToCreate[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateVideoRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateVideoRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateVideoRequest} message UpdateVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateVideoRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateVideoRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateVideoRequest} UpdateVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateVideoRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateVideoRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.video = $root.palexy.streaming.v1.Video.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.needPushToQueue = reader.bool();
                                break;
                            case 4:
                                message.queueName = reader.string();
                                break;
                            case 5:
                                if (!(message.additionalVideosToCreate && message.additionalVideosToCreate.length))
                                    message.additionalVideosToCreate = [];
                                message.additionalVideosToCreate.push($root.palexy.streaming.v1.Video.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateVideoRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateVideoRequest} UpdateVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateVideoRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateVideoRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateVideoRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.video != null && message.hasOwnProperty("video")) {
                            var error = $root.palexy.streaming.v1.Video.verify(message.video);
                            if (error)
                                return "video." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        if (message.needPushToQueue != null && message.hasOwnProperty("needPushToQueue"))
                            if (typeof message.needPushToQueue !== "boolean")
                                return "needPushToQueue: boolean expected";
                        if (message.queueName != null && message.hasOwnProperty("queueName"))
                            if (!$util.isString(message.queueName))
                                return "queueName: string expected";
                        if (message.additionalVideosToCreate != null && message.hasOwnProperty("additionalVideosToCreate")) {
                            if (!Array.isArray(message.additionalVideosToCreate))
                                return "additionalVideosToCreate: array expected";
                            for (var i = 0; i < message.additionalVideosToCreate.length; ++i) {
                                var error = $root.palexy.streaming.v1.Video.verify(message.additionalVideosToCreate[i]);
                                if (error)
                                    return "additionalVideosToCreate." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateVideoRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateVideoRequest} UpdateVideoRequest
                     */
                    UpdateVideoRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateVideoRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateVideoRequest();
                        if (object.video != null) {
                            if (typeof object.video !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateVideoRequest.video: object expected");
                            message.video = $root.palexy.streaming.v1.Video.fromObject(object.video);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateVideoRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        if (object.needPushToQueue != null)
                            message.needPushToQueue = Boolean(object.needPushToQueue);
                        if (object.queueName != null)
                            message.queueName = String(object.queueName);
                        if (object.additionalVideosToCreate) {
                            if (!Array.isArray(object.additionalVideosToCreate))
                                throw TypeError(".palexy.streaming.v1.UpdateVideoRequest.additionalVideosToCreate: array expected");
                            message.additionalVideosToCreate = [];
                            for (var i = 0; i < object.additionalVideosToCreate.length; ++i) {
                                if (typeof object.additionalVideosToCreate[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.UpdateVideoRequest.additionalVideosToCreate: object expected");
                                message.additionalVideosToCreate[i] = $root.palexy.streaming.v1.Video.fromObject(object.additionalVideosToCreate[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateVideoRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateVideoRequest} message UpdateVideoRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateVideoRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.additionalVideosToCreate = [];
                        if (options.defaults) {
                            object.video = null;
                            object.updateMask = null;
                            object.needPushToQueue = false;
                            object.queueName = "";
                        }
                        if (message.video != null && message.hasOwnProperty("video"))
                            object.video = $root.palexy.streaming.v1.Video.toObject(message.video, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        if (message.needPushToQueue != null && message.hasOwnProperty("needPushToQueue"))
                            object.needPushToQueue = message.needPushToQueue;
                        if (message.queueName != null && message.hasOwnProperty("queueName"))
                            object.queueName = message.queueName;
                        if (message.additionalVideosToCreate && message.additionalVideosToCreate.length) {
                            object.additionalVideosToCreate = [];
                            for (var j = 0; j < message.additionalVideosToCreate.length; ++j)
                                object.additionalVideosToCreate[j] = $root.palexy.streaming.v1.Video.toObject(message.additionalVideosToCreate[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this UpdateVideoRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateVideoRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateVideoRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateVideoRequest;
                })();
    
                v1.DeleteVideoRequest = (function() {
    
                    /**
                     * Properties of a DeleteVideoRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteVideoRequest
                     * @property {string|null} [name] DeleteVideoRequest name
                     */
    
                    /**
                     * Constructs a new DeleteVideoRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteVideoRequest.
                     * @implements IDeleteVideoRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteVideoRequest=} [properties] Properties to set
                     */
                    function DeleteVideoRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteVideoRequest name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.DeleteVideoRequest
                     * @instance
                     */
                    DeleteVideoRequest.prototype.name = "";
    
                    /**
                     * Creates a new DeleteVideoRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteVideoRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteVideoRequest} DeleteVideoRequest instance
                     */
                    DeleteVideoRequest.create = function create(properties) {
                        return new DeleteVideoRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteVideoRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteVideoRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteVideoRequest} message DeleteVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteVideoRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteVideoRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteVideoRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteVideoRequest} message DeleteVideoRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteVideoRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteVideoRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteVideoRequest} DeleteVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteVideoRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteVideoRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteVideoRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteVideoRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteVideoRequest} DeleteVideoRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteVideoRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteVideoRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteVideoRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteVideoRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteVideoRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteVideoRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteVideoRequest} DeleteVideoRequest
                     */
                    DeleteVideoRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteVideoRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteVideoRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteVideoRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteVideoRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteVideoRequest} message DeleteVideoRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteVideoRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteVideoRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteVideoRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteVideoRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteVideoRequest;
                })();
    
                v1.SubmitDeleteVideoTasksRequest = (function() {
    
                    /**
                     * Properties of a SubmitDeleteVideoTasksRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ISubmitDeleteVideoTasksRequest
                     * @property {Array.<string>|null} [names] SubmitDeleteVideoTasksRequest names
                     */
    
                    /**
                     * Constructs a new SubmitDeleteVideoTasksRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SubmitDeleteVideoTasksRequest.
                     * @implements ISubmitDeleteVideoTasksRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ISubmitDeleteVideoTasksRequest=} [properties] Properties to set
                     */
                    function SubmitDeleteVideoTasksRequest(properties) {
                        this.names = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SubmitDeleteVideoTasksRequest names.
                     * @member {Array.<string>} names
                     * @memberof palexy.streaming.v1.SubmitDeleteVideoTasksRequest
                     * @instance
                     */
                    SubmitDeleteVideoTasksRequest.prototype.names = $util.emptyArray;
    
                    /**
                     * Creates a new SubmitDeleteVideoTasksRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SubmitDeleteVideoTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.ISubmitDeleteVideoTasksRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SubmitDeleteVideoTasksRequest} SubmitDeleteVideoTasksRequest instance
                     */
                    SubmitDeleteVideoTasksRequest.create = function create(properties) {
                        return new SubmitDeleteVideoTasksRequest(properties);
                    };
    
                    /**
                     * Encodes the specified SubmitDeleteVideoTasksRequest message. Does not implicitly {@link palexy.streaming.v1.SubmitDeleteVideoTasksRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SubmitDeleteVideoTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.ISubmitDeleteVideoTasksRequest} message SubmitDeleteVideoTasksRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SubmitDeleteVideoTasksRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.names != null && message.names.length)
                            for (var i = 0; i < message.names.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.names[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SubmitDeleteVideoTasksRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.SubmitDeleteVideoTasksRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SubmitDeleteVideoTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.ISubmitDeleteVideoTasksRequest} message SubmitDeleteVideoTasksRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SubmitDeleteVideoTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SubmitDeleteVideoTasksRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SubmitDeleteVideoTasksRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SubmitDeleteVideoTasksRequest} SubmitDeleteVideoTasksRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SubmitDeleteVideoTasksRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SubmitDeleteVideoTasksRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.names && message.names.length))
                                    message.names = [];
                                message.names.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SubmitDeleteVideoTasksRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SubmitDeleteVideoTasksRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SubmitDeleteVideoTasksRequest} SubmitDeleteVideoTasksRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SubmitDeleteVideoTasksRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SubmitDeleteVideoTasksRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SubmitDeleteVideoTasksRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SubmitDeleteVideoTasksRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.names != null && message.hasOwnProperty("names")) {
                            if (!Array.isArray(message.names))
                                return "names: array expected";
                            for (var i = 0; i < message.names.length; ++i)
                                if (!$util.isString(message.names[i]))
                                    return "names: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a SubmitDeleteVideoTasksRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SubmitDeleteVideoTasksRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SubmitDeleteVideoTasksRequest} SubmitDeleteVideoTasksRequest
                     */
                    SubmitDeleteVideoTasksRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SubmitDeleteVideoTasksRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.SubmitDeleteVideoTasksRequest();
                        if (object.names) {
                            if (!Array.isArray(object.names))
                                throw TypeError(".palexy.streaming.v1.SubmitDeleteVideoTasksRequest.names: array expected");
                            message.names = [];
                            for (var i = 0; i < object.names.length; ++i)
                                message.names[i] = String(object.names[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SubmitDeleteVideoTasksRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SubmitDeleteVideoTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.SubmitDeleteVideoTasksRequest} message SubmitDeleteVideoTasksRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SubmitDeleteVideoTasksRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.names = [];
                        if (message.names && message.names.length) {
                            object.names = [];
                            for (var j = 0; j < message.names.length; ++j)
                                object.names[j] = message.names[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this SubmitDeleteVideoTasksRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SubmitDeleteVideoTasksRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SubmitDeleteVideoTasksRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SubmitDeleteVideoTasksRequest;
                })();
    
                v1.Video = (function() {
    
                    /**
                     * Properties of a Video.
                     * @memberof palexy.streaming.v1
                     * @interface IVideo
                     * @property {string|null} [name] Video name
                     * @property {string|null} [bucket] Video bucket
                     * @property {string|null} [fileName] Video fileName
                     * @property {number|null} [sizeInBytes] Video sizeInBytes
                     * @property {google.protobuf.IDuration|null} [duration] Video duration
                     * @property {google.protobuf.ITimestamp|null} [createTime] Video createTime
                     * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] Video lastUpdateTime
                     * @property {palexy.streaming.v1.Video.State|null} [state] Video state
                     * @property {string|null} [cameraId] Video cameraId
                     * @property {string|null} [dateId] Video dateId
                     * @property {boolean|null} [isAlreadyPurged] Video isAlreadyPurged
                     * @property {google.protobuf.ITimestamp|null} [videoStartTime] Video videoStartTime
                     * @property {boolean|null} [archived] Video archived
                     * @property {string|null} [cloudProvider] Video cloudProvider
                     * @property {boolean|null} [skipFeatureExtraction] Video skipFeatureExtraction
                     * @property {boolean|null} [isTrackingFileAlreadyPurged] Video isTrackingFileAlreadyPurged
                     * @property {boolean|null} [isQcData] Video isQcData
                     * @property {google.protobuf.ITimestamp|null} [minimumVideoArchiveTime] Video minimumVideoArchiveTime
                     * @property {google.protobuf.ITimestamp|null} [minimumTrackingFileArchiveTime] Video minimumTrackingFileArchiveTime
                     * @property {palexy.streaming.v1.Video.PartialMergeState|null} [partialMergeState] Video partialMergeState
                     * @property {string|null} [partialMergeId] Video partialMergeId
                     * @property {google.protobuf.ITimestamp|null} [donePartialMergeTime] Video donePartialMergeTime
                     * @property {boolean|null} [isStreamingOnly] Video isStreamingOnly
                     * @property {number|null} [numBb] Video numBb
                     */
    
                    /**
                     * Constructs a new Video.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Video.
                     * @implements IVideo
                     * @constructor
                     * @param {palexy.streaming.v1.IVideo=} [properties] Properties to set
                     */
                    function Video(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Video name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.name = "";
    
                    /**
                     * Video bucket.
                     * @member {string} bucket
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.bucket = "";
    
                    /**
                     * Video fileName.
                     * @member {string} fileName
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.fileName = "";
    
                    /**
                     * Video sizeInBytes.
                     * @member {number} sizeInBytes
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.sizeInBytes = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Video duration.
                     * @member {google.protobuf.IDuration|null|undefined} duration
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.duration = null;
    
                    /**
                     * Video createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.createTime = null;
    
                    /**
                     * Video lastUpdateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.lastUpdateTime = null;
    
                    /**
                     * Video state.
                     * @member {palexy.streaming.v1.Video.State} state
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.state = 0;
    
                    /**
                     * Video cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.cameraId = "";
    
                    /**
                     * Video dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.dateId = "";
    
                    /**
                     * Video isAlreadyPurged.
                     * @member {boolean} isAlreadyPurged
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.isAlreadyPurged = false;
    
                    /**
                     * Video videoStartTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} videoStartTime
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.videoStartTime = null;
    
                    /**
                     * Video archived.
                     * @member {boolean} archived
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.archived = false;
    
                    /**
                     * Video cloudProvider.
                     * @member {string} cloudProvider
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.cloudProvider = "";
    
                    /**
                     * Video skipFeatureExtraction.
                     * @member {boolean} skipFeatureExtraction
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.skipFeatureExtraction = false;
    
                    /**
                     * Video isTrackingFileAlreadyPurged.
                     * @member {boolean} isTrackingFileAlreadyPurged
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.isTrackingFileAlreadyPurged = false;
    
                    /**
                     * Video isQcData.
                     * @member {boolean} isQcData
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.isQcData = false;
    
                    /**
                     * Video minimumVideoArchiveTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} minimumVideoArchiveTime
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.minimumVideoArchiveTime = null;
    
                    /**
                     * Video minimumTrackingFileArchiveTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} minimumTrackingFileArchiveTime
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.minimumTrackingFileArchiveTime = null;
    
                    /**
                     * Video partialMergeState.
                     * @member {palexy.streaming.v1.Video.PartialMergeState} partialMergeState
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.partialMergeState = 0;
    
                    /**
                     * Video partialMergeId.
                     * @member {string} partialMergeId
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.partialMergeId = "";
    
                    /**
                     * Video donePartialMergeTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} donePartialMergeTime
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.donePartialMergeTime = null;
    
                    /**
                     * Video isStreamingOnly.
                     * @member {boolean} isStreamingOnly
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.isStreamingOnly = false;
    
                    /**
                     * Video numBb.
                     * @member {number} numBb
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     */
                    Video.prototype.numBb = 0;
    
                    /**
                     * Creates a new Video instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Video
                     * @static
                     * @param {palexy.streaming.v1.IVideo=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Video} Video instance
                     */
                    Video.create = function create(properties) {
                        return new Video(properties);
                    };
    
                    /**
                     * Encodes the specified Video message. Does not implicitly {@link palexy.streaming.v1.Video.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Video
                     * @static
                     * @param {palexy.streaming.v1.IVideo} message Video message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Video.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.bucket != null && Object.hasOwnProperty.call(message, "bucket"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.bucket);
                        if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fileName);
                        if (message.sizeInBytes != null && Object.hasOwnProperty.call(message, "sizeInBytes"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.sizeInBytes);
                        if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                            $root.google.protobuf.Duration.encode(message.duration, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.state);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.cameraId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.dateId);
                        if (message.isAlreadyPurged != null && Object.hasOwnProperty.call(message, "isAlreadyPurged"))
                            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isAlreadyPurged);
                        if (message.videoStartTime != null && Object.hasOwnProperty.call(message, "videoStartTime"))
                            $root.google.protobuf.Timestamp.encode(message.videoStartTime, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                        if (message.archived != null && Object.hasOwnProperty.call(message, "archived"))
                            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.archived);
                        if (message.cloudProvider != null && Object.hasOwnProperty.call(message, "cloudProvider"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.cloudProvider);
                        if (message.skipFeatureExtraction != null && Object.hasOwnProperty.call(message, "skipFeatureExtraction"))
                            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.skipFeatureExtraction);
                        if (message.isTrackingFileAlreadyPurged != null && Object.hasOwnProperty.call(message, "isTrackingFileAlreadyPurged"))
                            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.isTrackingFileAlreadyPurged);
                        if (message.isQcData != null && Object.hasOwnProperty.call(message, "isQcData"))
                            writer.uint32(/* id 17, wireType 0 =*/136).bool(message.isQcData);
                        if (message.minimumVideoArchiveTime != null && Object.hasOwnProperty.call(message, "minimumVideoArchiveTime"))
                            $root.google.protobuf.Timestamp.encode(message.minimumVideoArchiveTime, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                        if (message.minimumTrackingFileArchiveTime != null && Object.hasOwnProperty.call(message, "minimumTrackingFileArchiveTime"))
                            $root.google.protobuf.Timestamp.encode(message.minimumTrackingFileArchiveTime, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                        if (message.partialMergeState != null && Object.hasOwnProperty.call(message, "partialMergeState"))
                            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.partialMergeState);
                        if (message.partialMergeId != null && Object.hasOwnProperty.call(message, "partialMergeId"))
                            writer.uint32(/* id 21, wireType 2 =*/170).string(message.partialMergeId);
                        if (message.donePartialMergeTime != null && Object.hasOwnProperty.call(message, "donePartialMergeTime"))
                            $root.google.protobuf.Timestamp.encode(message.donePartialMergeTime, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                        if (message.isStreamingOnly != null && Object.hasOwnProperty.call(message, "isStreamingOnly"))
                            writer.uint32(/* id 23, wireType 0 =*/184).bool(message.isStreamingOnly);
                        if (message.numBb != null && Object.hasOwnProperty.call(message, "numBb"))
                            writer.uint32(/* id 24, wireType 0 =*/192).int32(message.numBb);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Video message, length delimited. Does not implicitly {@link palexy.streaming.v1.Video.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Video
                     * @static
                     * @param {palexy.streaming.v1.IVideo} message Video message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Video.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Video message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Video
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Video} Video
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Video.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Video();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.bucket = reader.string();
                                break;
                            case 3:
                                message.fileName = reader.string();
                                break;
                            case 4:
                                message.sizeInBytes = reader.int64();
                                break;
                            case 5:
                                message.duration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.state = reader.int32();
                                break;
                            case 9:
                                message.cameraId = reader.string();
                                break;
                            case 10:
                                message.dateId = reader.string();
                                break;
                            case 11:
                                message.isAlreadyPurged = reader.bool();
                                break;
                            case 12:
                                message.videoStartTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 13:
                                message.archived = reader.bool();
                                break;
                            case 14:
                                message.cloudProvider = reader.string();
                                break;
                            case 15:
                                message.skipFeatureExtraction = reader.bool();
                                break;
                            case 16:
                                message.isTrackingFileAlreadyPurged = reader.bool();
                                break;
                            case 17:
                                message.isQcData = reader.bool();
                                break;
                            case 18:
                                message.minimumVideoArchiveTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 19:
                                message.minimumTrackingFileArchiveTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 20:
                                message.partialMergeState = reader.int32();
                                break;
                            case 21:
                                message.partialMergeId = reader.string();
                                break;
                            case 22:
                                message.donePartialMergeTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 23:
                                message.isStreamingOnly = reader.bool();
                                break;
                            case 24:
                                message.numBb = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Video message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Video
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Video} Video
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Video.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Video message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Video
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Video.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.bucket != null && message.hasOwnProperty("bucket"))
                            if (!$util.isString(message.bucket))
                                return "bucket: string expected";
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            if (!$util.isString(message.fileName))
                                return "fileName: string expected";
                        if (message.sizeInBytes != null && message.hasOwnProperty("sizeInBytes"))
                            if (!$util.isInteger(message.sizeInBytes) && !(message.sizeInBytes && $util.isInteger(message.sizeInBytes.low) && $util.isInteger(message.sizeInBytes.high)))
                                return "sizeInBytes: integer|Long expected";
                        if (message.duration != null && message.hasOwnProperty("duration")) {
                            var error = $root.google.protobuf.Duration.verify(message.duration);
                            if (error)
                                return "duration." + error;
                        }
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                            if (error)
                                return "lastUpdateTime." + error;
                        }
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 7:
                            case 6:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.isAlreadyPurged != null && message.hasOwnProperty("isAlreadyPurged"))
                            if (typeof message.isAlreadyPurged !== "boolean")
                                return "isAlreadyPurged: boolean expected";
                        if (message.videoStartTime != null && message.hasOwnProperty("videoStartTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.videoStartTime);
                            if (error)
                                return "videoStartTime." + error;
                        }
                        if (message.archived != null && message.hasOwnProperty("archived"))
                            if (typeof message.archived !== "boolean")
                                return "archived: boolean expected";
                        if (message.cloudProvider != null && message.hasOwnProperty("cloudProvider"))
                            if (!$util.isString(message.cloudProvider))
                                return "cloudProvider: string expected";
                        if (message.skipFeatureExtraction != null && message.hasOwnProperty("skipFeatureExtraction"))
                            if (typeof message.skipFeatureExtraction !== "boolean")
                                return "skipFeatureExtraction: boolean expected";
                        if (message.isTrackingFileAlreadyPurged != null && message.hasOwnProperty("isTrackingFileAlreadyPurged"))
                            if (typeof message.isTrackingFileAlreadyPurged !== "boolean")
                                return "isTrackingFileAlreadyPurged: boolean expected";
                        if (message.isQcData != null && message.hasOwnProperty("isQcData"))
                            if (typeof message.isQcData !== "boolean")
                                return "isQcData: boolean expected";
                        if (message.minimumVideoArchiveTime != null && message.hasOwnProperty("minimumVideoArchiveTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.minimumVideoArchiveTime);
                            if (error)
                                return "minimumVideoArchiveTime." + error;
                        }
                        if (message.minimumTrackingFileArchiveTime != null && message.hasOwnProperty("minimumTrackingFileArchiveTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.minimumTrackingFileArchiveTime);
                            if (error)
                                return "minimumTrackingFileArchiveTime." + error;
                        }
                        if (message.partialMergeState != null && message.hasOwnProperty("partialMergeState"))
                            switch (message.partialMergeState) {
                            default:
                                return "partialMergeState: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.partialMergeId != null && message.hasOwnProperty("partialMergeId"))
                            if (!$util.isString(message.partialMergeId))
                                return "partialMergeId: string expected";
                        if (message.donePartialMergeTime != null && message.hasOwnProperty("donePartialMergeTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.donePartialMergeTime);
                            if (error)
                                return "donePartialMergeTime." + error;
                        }
                        if (message.isStreamingOnly != null && message.hasOwnProperty("isStreamingOnly"))
                            if (typeof message.isStreamingOnly !== "boolean")
                                return "isStreamingOnly: boolean expected";
                        if (message.numBb != null && message.hasOwnProperty("numBb"))
                            if (!$util.isInteger(message.numBb))
                                return "numBb: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a Video message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Video
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Video} Video
                     */
                    Video.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Video)
                            return object;
                        var message = new $root.palexy.streaming.v1.Video();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.bucket != null)
                            message.bucket = String(object.bucket);
                        if (object.fileName != null)
                            message.fileName = String(object.fileName);
                        if (object.sizeInBytes != null)
                            if ($util.Long)
                                (message.sizeInBytes = $util.Long.fromValue(object.sizeInBytes)).unsigned = false;
                            else if (typeof object.sizeInBytes === "string")
                                message.sizeInBytes = parseInt(object.sizeInBytes, 10);
                            else if (typeof object.sizeInBytes === "number")
                                message.sizeInBytes = object.sizeInBytes;
                            else if (typeof object.sizeInBytes === "object")
                                message.sizeInBytes = new $util.LongBits(object.sizeInBytes.low >>> 0, object.sizeInBytes.high >>> 0).toNumber();
                        if (object.duration != null) {
                            if (typeof object.duration !== "object")
                                throw TypeError(".palexy.streaming.v1.Video.duration: object expected");
                            message.duration = $root.google.protobuf.Duration.fromObject(object.duration);
                        }
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Video.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.lastUpdateTime != null) {
                            if (typeof object.lastUpdateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Video.lastUpdateTime: object expected");
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                        }
                        switch (object.state) {
                        case "UNKNOWN":
                        case 0:
                            message.state = 0;
                            break;
                        case "WAITING_FOR_VIDEO_FILE":
                        case 7:
                            message.state = 7;
                            break;
                        case "WAITING_FOR_PREPROCESSING":
                        case 6:
                            message.state = 6;
                            break;
                        case "WAITING_FOR_PROCESSING":
                        case 1:
                            message.state = 1;
                            break;
                        case "DONE_TRACKING":
                        case 2:
                            message.state = 2;
                            break;
                        case "WAITING_FOR_MERGING":
                        case 3:
                            message.state = 3;
                            break;
                        case "DONE":
                        case 4:
                            message.state = 4;
                            break;
                        case "DONE_CALCULATING_ACCURACY":
                        case 5:
                            message.state = 5;
                            break;
                        }
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.isAlreadyPurged != null)
                            message.isAlreadyPurged = Boolean(object.isAlreadyPurged);
                        if (object.videoStartTime != null) {
                            if (typeof object.videoStartTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Video.videoStartTime: object expected");
                            message.videoStartTime = $root.google.protobuf.Timestamp.fromObject(object.videoStartTime);
                        }
                        if (object.archived != null)
                            message.archived = Boolean(object.archived);
                        if (object.cloudProvider != null)
                            message.cloudProvider = String(object.cloudProvider);
                        if (object.skipFeatureExtraction != null)
                            message.skipFeatureExtraction = Boolean(object.skipFeatureExtraction);
                        if (object.isTrackingFileAlreadyPurged != null)
                            message.isTrackingFileAlreadyPurged = Boolean(object.isTrackingFileAlreadyPurged);
                        if (object.isQcData != null)
                            message.isQcData = Boolean(object.isQcData);
                        if (object.minimumVideoArchiveTime != null) {
                            if (typeof object.minimumVideoArchiveTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Video.minimumVideoArchiveTime: object expected");
                            message.minimumVideoArchiveTime = $root.google.protobuf.Timestamp.fromObject(object.minimumVideoArchiveTime);
                        }
                        if (object.minimumTrackingFileArchiveTime != null) {
                            if (typeof object.minimumTrackingFileArchiveTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Video.minimumTrackingFileArchiveTime: object expected");
                            message.minimumTrackingFileArchiveTime = $root.google.protobuf.Timestamp.fromObject(object.minimumTrackingFileArchiveTime);
                        }
                        switch (object.partialMergeState) {
                        case "UNKNOWN_MERGE_STATE":
                        case 0:
                            message.partialMergeState = 0;
                            break;
                        case "DEFAULT_STATE":
                        case 1:
                            message.partialMergeState = 1;
                            break;
                        case "WAITING_FOR_PARTIALLY_MERGING":
                        case 2:
                            message.partialMergeState = 2;
                            break;
                        case "DONE_PARTIAL_MERGE":
                        case 3:
                            message.partialMergeState = 3;
                            break;
                        case "SKIP_MERGE":
                        case 4:
                            message.partialMergeState = 4;
                            break;
                        }
                        if (object.partialMergeId != null)
                            message.partialMergeId = String(object.partialMergeId);
                        if (object.donePartialMergeTime != null) {
                            if (typeof object.donePartialMergeTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Video.donePartialMergeTime: object expected");
                            message.donePartialMergeTime = $root.google.protobuf.Timestamp.fromObject(object.donePartialMergeTime);
                        }
                        if (object.isStreamingOnly != null)
                            message.isStreamingOnly = Boolean(object.isStreamingOnly);
                        if (object.numBb != null)
                            message.numBb = object.numBb | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Video message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Video
                     * @static
                     * @param {palexy.streaming.v1.Video} message Video
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Video.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.bucket = "";
                            object.fileName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sizeInBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sizeInBytes = options.longs === String ? "0" : 0;
                            object.duration = null;
                            object.createTime = null;
                            object.lastUpdateTime = null;
                            object.state = options.enums === String ? "UNKNOWN" : 0;
                            object.cameraId = "";
                            object.dateId = "";
                            object.isAlreadyPurged = false;
                            object.videoStartTime = null;
                            object.archived = false;
                            object.cloudProvider = "";
                            object.skipFeatureExtraction = false;
                            object.isTrackingFileAlreadyPurged = false;
                            object.isQcData = false;
                            object.minimumVideoArchiveTime = null;
                            object.minimumTrackingFileArchiveTime = null;
                            object.partialMergeState = options.enums === String ? "UNKNOWN_MERGE_STATE" : 0;
                            object.partialMergeId = "";
                            object.donePartialMergeTime = null;
                            object.isStreamingOnly = false;
                            object.numBb = 0;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.bucket != null && message.hasOwnProperty("bucket"))
                            object.bucket = message.bucket;
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            object.fileName = message.fileName;
                        if (message.sizeInBytes != null && message.hasOwnProperty("sizeInBytes"))
                            if (typeof message.sizeInBytes === "number")
                                object.sizeInBytes = options.longs === String ? String(message.sizeInBytes) : message.sizeInBytes;
                            else
                                object.sizeInBytes = options.longs === String ? $util.Long.prototype.toString.call(message.sizeInBytes) : options.longs === Number ? new $util.LongBits(message.sizeInBytes.low >>> 0, message.sizeInBytes.high >>> 0).toNumber() : message.sizeInBytes;
                        if (message.duration != null && message.hasOwnProperty("duration"))
                            object.duration = $root.google.protobuf.Duration.toObject(message.duration, options);
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                            object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.streaming.v1.Video.State[message.state] : message.state;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.isAlreadyPurged != null && message.hasOwnProperty("isAlreadyPurged"))
                            object.isAlreadyPurged = message.isAlreadyPurged;
                        if (message.videoStartTime != null && message.hasOwnProperty("videoStartTime"))
                            object.videoStartTime = $root.google.protobuf.Timestamp.toObject(message.videoStartTime, options);
                        if (message.archived != null && message.hasOwnProperty("archived"))
                            object.archived = message.archived;
                        if (message.cloudProvider != null && message.hasOwnProperty("cloudProvider"))
                            object.cloudProvider = message.cloudProvider;
                        if (message.skipFeatureExtraction != null && message.hasOwnProperty("skipFeatureExtraction"))
                            object.skipFeatureExtraction = message.skipFeatureExtraction;
                        if (message.isTrackingFileAlreadyPurged != null && message.hasOwnProperty("isTrackingFileAlreadyPurged"))
                            object.isTrackingFileAlreadyPurged = message.isTrackingFileAlreadyPurged;
                        if (message.isQcData != null && message.hasOwnProperty("isQcData"))
                            object.isQcData = message.isQcData;
                        if (message.minimumVideoArchiveTime != null && message.hasOwnProperty("minimumVideoArchiveTime"))
                            object.minimumVideoArchiveTime = $root.google.protobuf.Timestamp.toObject(message.minimumVideoArchiveTime, options);
                        if (message.minimumTrackingFileArchiveTime != null && message.hasOwnProperty("minimumTrackingFileArchiveTime"))
                            object.minimumTrackingFileArchiveTime = $root.google.protobuf.Timestamp.toObject(message.minimumTrackingFileArchiveTime, options);
                        if (message.partialMergeState != null && message.hasOwnProperty("partialMergeState"))
                            object.partialMergeState = options.enums === String ? $root.palexy.streaming.v1.Video.PartialMergeState[message.partialMergeState] : message.partialMergeState;
                        if (message.partialMergeId != null && message.hasOwnProperty("partialMergeId"))
                            object.partialMergeId = message.partialMergeId;
                        if (message.donePartialMergeTime != null && message.hasOwnProperty("donePartialMergeTime"))
                            object.donePartialMergeTime = $root.google.protobuf.Timestamp.toObject(message.donePartialMergeTime, options);
                        if (message.isStreamingOnly != null && message.hasOwnProperty("isStreamingOnly"))
                            object.isStreamingOnly = message.isStreamingOnly;
                        if (message.numBb != null && message.hasOwnProperty("numBb"))
                            object.numBb = message.numBb;
                        return object;
                    };
    
                    /**
                     * Converts this Video to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Video
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Video.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * State enum.
                     * @name palexy.streaming.v1.Video.State
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} WAITING_FOR_VIDEO_FILE=7 WAITING_FOR_VIDEO_FILE value
                     * @property {number} WAITING_FOR_PREPROCESSING=6 WAITING_FOR_PREPROCESSING value
                     * @property {number} WAITING_FOR_PROCESSING=1 WAITING_FOR_PROCESSING value
                     * @property {number} DONE_TRACKING=2 DONE_TRACKING value
                     * @property {number} WAITING_FOR_MERGING=3 WAITING_FOR_MERGING value
                     * @property {number} DONE=4 DONE value
                     * @property {number} DONE_CALCULATING_ACCURACY=5 DONE_CALCULATING_ACCURACY value
                     */
                    Video.State = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[7] = "WAITING_FOR_VIDEO_FILE"] = 7;
                        values[valuesById[6] = "WAITING_FOR_PREPROCESSING"] = 6;
                        values[valuesById[1] = "WAITING_FOR_PROCESSING"] = 1;
                        values[valuesById[2] = "DONE_TRACKING"] = 2;
                        values[valuesById[3] = "WAITING_FOR_MERGING"] = 3;
                        values[valuesById[4] = "DONE"] = 4;
                        values[valuesById[5] = "DONE_CALCULATING_ACCURACY"] = 5;
                        return values;
                    })();
    
                    /**
                     * PartialMergeState enum.
                     * @name palexy.streaming.v1.Video.PartialMergeState
                     * @enum {number}
                     * @property {number} UNKNOWN_MERGE_STATE=0 UNKNOWN_MERGE_STATE value
                     * @property {number} DEFAULT_STATE=1 DEFAULT_STATE value
                     * @property {number} WAITING_FOR_PARTIALLY_MERGING=2 WAITING_FOR_PARTIALLY_MERGING value
                     * @property {number} DONE_PARTIAL_MERGE=3 DONE_PARTIAL_MERGE value
                     * @property {number} SKIP_MERGE=4 SKIP_MERGE value
                     */
                    Video.PartialMergeState = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_MERGE_STATE"] = 0;
                        values[valuesById[1] = "DEFAULT_STATE"] = 1;
                        values[valuesById[2] = "WAITING_FOR_PARTIALLY_MERGING"] = 2;
                        values[valuesById[3] = "DONE_PARTIAL_MERGE"] = 3;
                        values[valuesById[4] = "SKIP_MERGE"] = 4;
                        return values;
                    })();
    
                    return Video;
                })();
    
                v1.VideoAndAckId = (function() {
    
                    /**
                     * Properties of a VideoAndAckId.
                     * @memberof palexy.streaming.v1
                     * @interface IVideoAndAckId
                     * @property {palexy.streaming.v1.IVideo|null} [video] VideoAndAckId video
                     * @property {string|null} [ackId] VideoAndAckId ackId
                     */
    
                    /**
                     * Constructs a new VideoAndAckId.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a VideoAndAckId.
                     * @implements IVideoAndAckId
                     * @constructor
                     * @param {palexy.streaming.v1.IVideoAndAckId=} [properties] Properties to set
                     */
                    function VideoAndAckId(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * VideoAndAckId video.
                     * @member {palexy.streaming.v1.IVideo|null|undefined} video
                     * @memberof palexy.streaming.v1.VideoAndAckId
                     * @instance
                     */
                    VideoAndAckId.prototype.video = null;
    
                    /**
                     * VideoAndAckId ackId.
                     * @member {string} ackId
                     * @memberof palexy.streaming.v1.VideoAndAckId
                     * @instance
                     */
                    VideoAndAckId.prototype.ackId = "";
    
                    /**
                     * Creates a new VideoAndAckId instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.VideoAndAckId
                     * @static
                     * @param {palexy.streaming.v1.IVideoAndAckId=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.VideoAndAckId} VideoAndAckId instance
                     */
                    VideoAndAckId.create = function create(properties) {
                        return new VideoAndAckId(properties);
                    };
    
                    /**
                     * Encodes the specified VideoAndAckId message. Does not implicitly {@link palexy.streaming.v1.VideoAndAckId.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.VideoAndAckId
                     * @static
                     * @param {palexy.streaming.v1.IVideoAndAckId} message VideoAndAckId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VideoAndAckId.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.video != null && Object.hasOwnProperty.call(message, "video"))
                            $root.palexy.streaming.v1.Video.encode(message.video, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.ackId != null && Object.hasOwnProperty.call(message, "ackId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.ackId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified VideoAndAckId message, length delimited. Does not implicitly {@link palexy.streaming.v1.VideoAndAckId.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.VideoAndAckId
                     * @static
                     * @param {palexy.streaming.v1.IVideoAndAckId} message VideoAndAckId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VideoAndAckId.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a VideoAndAckId message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.VideoAndAckId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.VideoAndAckId} VideoAndAckId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VideoAndAckId.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.VideoAndAckId();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.video = $root.palexy.streaming.v1.Video.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.ackId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a VideoAndAckId message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.VideoAndAckId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.VideoAndAckId} VideoAndAckId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VideoAndAckId.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a VideoAndAckId message.
                     * @function verify
                     * @memberof palexy.streaming.v1.VideoAndAckId
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    VideoAndAckId.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.video != null && message.hasOwnProperty("video")) {
                            var error = $root.palexy.streaming.v1.Video.verify(message.video);
                            if (error)
                                return "video." + error;
                        }
                        if (message.ackId != null && message.hasOwnProperty("ackId"))
                            if (!$util.isString(message.ackId))
                                return "ackId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a VideoAndAckId message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.VideoAndAckId
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.VideoAndAckId} VideoAndAckId
                     */
                    VideoAndAckId.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.VideoAndAckId)
                            return object;
                        var message = new $root.palexy.streaming.v1.VideoAndAckId();
                        if (object.video != null) {
                            if (typeof object.video !== "object")
                                throw TypeError(".palexy.streaming.v1.VideoAndAckId.video: object expected");
                            message.video = $root.palexy.streaming.v1.Video.fromObject(object.video);
                        }
                        if (object.ackId != null)
                            message.ackId = String(object.ackId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a VideoAndAckId message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.VideoAndAckId
                     * @static
                     * @param {palexy.streaming.v1.VideoAndAckId} message VideoAndAckId
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    VideoAndAckId.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.video = null;
                            object.ackId = "";
                        }
                        if (message.video != null && message.hasOwnProperty("video"))
                            object.video = $root.palexy.streaming.v1.Video.toObject(message.video, options);
                        if (message.ackId != null && message.hasOwnProperty("ackId"))
                            object.ackId = message.ackId;
                        return object;
                    };
    
                    /**
                     * Converts this VideoAndAckId to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.VideoAndAckId
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    VideoAndAckId.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return VideoAndAckId;
                })();
    
                v1.NotificationService = (function() {
    
                    /**
                     * Constructs a new NotificationService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a NotificationService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function NotificationService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (NotificationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = NotificationService;
    
                    /**
                     * Creates new NotificationService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.NotificationService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {NotificationService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    NotificationService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#sendCameraNotification}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef SendCameraNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls SendCameraNotification.
                     * @function sendCameraNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.ICameraNotificationRequest} request CameraNotificationRequest message or plain object
                     * @param {palexy.streaming.v1.NotificationService.SendCameraNotificationCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.sendCameraNotification = function sendCameraNotification(request, callback) {
                        return this.rpcCall(sendCameraNotification, $root.palexy.streaming.v1.CameraNotificationRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "SendCameraNotification" });
    
                    /**
                     * Calls SendCameraNotification.
                     * @function sendCameraNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.ICameraNotificationRequest} request CameraNotificationRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#listNotification}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef ListNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListNotificationResponse} [response] ListNotificationResponse
                     */
    
                    /**
                     * Calls ListNotification.
                     * @function listNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IListNotificationRequest} request ListNotificationRequest message or plain object
                     * @param {palexy.streaming.v1.NotificationService.ListNotificationCallback} callback Node-style callback called with the error, if any, and ListNotificationResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.listNotification = function listNotification(request, callback) {
                        return this.rpcCall(listNotification, $root.palexy.streaming.v1.ListNotificationRequest, $root.palexy.streaming.v1.ListNotificationResponse, request, callback);
                    }, "name", { value: "ListNotification" });
    
                    /**
                     * Calls ListNotification.
                     * @function listNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IListNotificationRequest} request ListNotificationRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListNotificationResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#getNotification}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef GetNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Notification} [response] Notification
                     */
    
                    /**
                     * Calls GetNotification.
                     * @function getNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IGetNotificationRequest} request GetNotificationRequest message or plain object
                     * @param {palexy.streaming.v1.NotificationService.GetNotificationCallback} callback Node-style callback called with the error, if any, and Notification
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.getNotification = function getNotification(request, callback) {
                        return this.rpcCall(getNotification, $root.palexy.streaming.v1.GetNotificationRequest, $root.palexy.streaming.v1.Notification, request, callback);
                    }, "name", { value: "GetNotification" });
    
                    /**
                     * Calls GetNotification.
                     * @function getNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IGetNotificationRequest} request GetNotificationRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Notification>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#createNotification}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef CreateNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Notification} [response] Notification
                     */
    
                    /**
                     * Calls CreateNotification.
                     * @function createNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.INotification} request Notification message or plain object
                     * @param {palexy.streaming.v1.NotificationService.CreateNotificationCallback} callback Node-style callback called with the error, if any, and Notification
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.createNotification = function createNotification(request, callback) {
                        return this.rpcCall(createNotification, $root.palexy.streaming.v1.Notification, $root.palexy.streaming.v1.Notification, request, callback);
                    }, "name", { value: "CreateNotification" });
    
                    /**
                     * Calls CreateNotification.
                     * @function createNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.INotification} request Notification message or plain object
                     * @returns {Promise<palexy.streaming.v1.Notification>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#batchCreateNotification}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef BatchCreateNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchCreateNotificationResponse} [response] BatchCreateNotificationResponse
                     */
    
                    /**
                     * Calls BatchCreateNotification.
                     * @function batchCreateNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreateNotificationRequest} request BatchCreateNotificationRequest message or plain object
                     * @param {palexy.streaming.v1.NotificationService.BatchCreateNotificationCallback} callback Node-style callback called with the error, if any, and BatchCreateNotificationResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.batchCreateNotification = function batchCreateNotification(request, callback) {
                        return this.rpcCall(batchCreateNotification, $root.palexy.streaming.v1.BatchCreateNotificationRequest, $root.palexy.streaming.v1.BatchCreateNotificationResponse, request, callback);
                    }, "name", { value: "BatchCreateNotification" });
    
                    /**
                     * Calls BatchCreateNotification.
                     * @function batchCreateNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreateNotificationRequest} request BatchCreateNotificationRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchCreateNotificationResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#markAllNotificationsAsRead}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef MarkAllNotificationsAsReadCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls MarkAllNotificationsAsRead.
                     * @function markAllNotificationsAsRead
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IMarkAllNotificationsAsReadRequest} request MarkAllNotificationsAsReadRequest message or plain object
                     * @param {palexy.streaming.v1.NotificationService.MarkAllNotificationsAsReadCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.markAllNotificationsAsRead = function markAllNotificationsAsRead(request, callback) {
                        return this.rpcCall(markAllNotificationsAsRead, $root.palexy.streaming.v1.MarkAllNotificationsAsReadRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "MarkAllNotificationsAsRead" });
    
                    /**
                     * Calls MarkAllNotificationsAsRead.
                     * @function markAllNotificationsAsRead
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IMarkAllNotificationsAsReadRequest} request MarkAllNotificationsAsReadRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#updateNotification}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef UpdateNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Notification} [response] Notification
                     */
    
                    /**
                     * Calls UpdateNotification.
                     * @function updateNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateNotificationRequest} request UpdateNotificationRequest message or plain object
                     * @param {palexy.streaming.v1.NotificationService.UpdateNotificationCallback} callback Node-style callback called with the error, if any, and Notification
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.updateNotification = function updateNotification(request, callback) {
                        return this.rpcCall(updateNotification, $root.palexy.streaming.v1.UpdateNotificationRequest, $root.palexy.streaming.v1.Notification, request, callback);
                    }, "name", { value: "UpdateNotification" });
    
                    /**
                     * Calls UpdateNotification.
                     * @function updateNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateNotificationRequest} request UpdateNotificationRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Notification>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#sendNotification}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef SendNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.SendNotificationResponse} [response] SendNotificationResponse
                     */
    
                    /**
                     * Calls SendNotification.
                     * @function sendNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.ISendNotificationRequest} request SendNotificationRequest message or plain object
                     * @param {palexy.streaming.v1.NotificationService.SendNotificationCallback} callback Node-style callback called with the error, if any, and SendNotificationResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.sendNotification = function sendNotification(request, callback) {
                        return this.rpcCall(sendNotification, $root.palexy.streaming.v1.SendNotificationRequest, $root.palexy.streaming.v1.SendNotificationResponse, request, callback);
                    }, "name", { value: "SendNotification" });
    
                    /**
                     * Calls SendNotification.
                     * @function sendNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.ISendNotificationRequest} request SendNotificationRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.SendNotificationResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#sendExportRawICANotification}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef SendExportRawICANotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ExportRawICANotificationResponse} [response] ExportRawICANotificationResponse
                     */
    
                    /**
                     * Calls SendExportRawICANotification.
                     * @function sendExportRawICANotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IExportRawICANotificationRequest} request ExportRawICANotificationRequest message or plain object
                     * @param {palexy.streaming.v1.NotificationService.SendExportRawICANotificationCallback} callback Node-style callback called with the error, if any, and ExportRawICANotificationResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.sendExportRawICANotification = function sendExportRawICANotification(request, callback) {
                        return this.rpcCall(sendExportRawICANotification, $root.palexy.streaming.v1.ExportRawICANotificationRequest, $root.palexy.streaming.v1.ExportRawICANotificationResponse, request, callback);
                    }, "name", { value: "SendExportRawICANotification" });
    
                    /**
                     * Calls SendExportRawICANotification.
                     * @function sendExportRawICANotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IExportRawICANotificationRequest} request ExportRawICANotificationRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ExportRawICANotificationResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#sendVerificationEmail}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef SendVerificationEmailCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls SendVerificationEmail.
                     * @function sendVerificationEmail
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.ISendVerificationEmailRequest} request SendVerificationEmailRequest message or plain object
                     * @param {palexy.streaming.v1.NotificationService.SendVerificationEmailCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.sendVerificationEmail = function sendVerificationEmail(request, callback) {
                        return this.rpcCall(sendVerificationEmail, $root.palexy.streaming.v1.SendVerificationEmailRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "SendVerificationEmail" });
    
                    /**
                     * Calls SendVerificationEmail.
                     * @function sendVerificationEmail
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.ISendVerificationEmailRequest} request SendVerificationEmailRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#sendScheduledReportNotification}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef SendScheduledReportNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls SendScheduledReportNotification.
                     * @function sendScheduledReportNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.ISendScheduledReportNotificationRequest} request SendScheduledReportNotificationRequest message or plain object
                     * @param {palexy.streaming.v1.NotificationService.SendScheduledReportNotificationCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.sendScheduledReportNotification = function sendScheduledReportNotification(request, callback) {
                        return this.rpcCall(sendScheduledReportNotification, $root.palexy.streaming.v1.SendScheduledReportNotificationRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "SendScheduledReportNotification" });
    
                    /**
                     * Calls SendScheduledReportNotification.
                     * @function sendScheduledReportNotification
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.ISendScheduledReportNotificationRequest} request SendScheduledReportNotificationRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NotificationService#sendOtp}.
                     * @memberof palexy.streaming.v1.NotificationService
                     * @typedef SendOtpCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls SendOtp.
                     * @function sendOtp
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.ISendOtpRequest} request SendOtpRequest message or plain object
                     * @param {palexy.streaming.v1.NotificationService.SendOtpCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NotificationService.prototype.sendOtp = function sendOtp(request, callback) {
                        return this.rpcCall(sendOtp, $root.palexy.streaming.v1.SendOtpRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "SendOtp" });
    
                    /**
                     * Calls SendOtp.
                     * @function sendOtp
                     * @memberof palexy.streaming.v1.NotificationService
                     * @instance
                     * @param {palexy.streaming.v1.ISendOtpRequest} request SendOtpRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return NotificationService;
                })();
    
                v1.CameraNotificationRequest = (function() {
    
                    /**
                     * Properties of a CameraNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICameraNotificationRequest
                     * @property {palexy.streaming.v1.CameraNotificationRequest.NotificationType|null} [notificationType] CameraNotificationRequest notificationType
                     * @property {string|null} [cameraId] CameraNotificationRequest cameraId
                     * @property {palexy.streaming.v1.ICameraEmptyVideoAlert|null} [cameraEmptyVideoAlert] CameraNotificationRequest cameraEmptyVideoAlert
                     */
    
                    /**
                     * Constructs a new CameraNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CameraNotificationRequest.
                     * @implements ICameraNotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICameraNotificationRequest=} [properties] Properties to set
                     */
                    function CameraNotificationRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CameraNotificationRequest notificationType.
                     * @member {palexy.streaming.v1.CameraNotificationRequest.NotificationType} notificationType
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @instance
                     */
                    CameraNotificationRequest.prototype.notificationType = 0;
    
                    /**
                     * CameraNotificationRequest cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @instance
                     */
                    CameraNotificationRequest.prototype.cameraId = "";
    
                    /**
                     * CameraNotificationRequest cameraEmptyVideoAlert.
                     * @member {palexy.streaming.v1.ICameraEmptyVideoAlert|null|undefined} cameraEmptyVideoAlert
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @instance
                     */
                    CameraNotificationRequest.prototype.cameraEmptyVideoAlert = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * CameraNotificationRequest notificationObject.
                     * @member {"cameraEmptyVideoAlert"|undefined} notificationObject
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @instance
                     */
                    Object.defineProperty(CameraNotificationRequest.prototype, "notificationObject", {
                        get: $util.oneOfGetter($oneOfFields = ["cameraEmptyVideoAlert"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new CameraNotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ICameraNotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CameraNotificationRequest} CameraNotificationRequest instance
                     */
                    CameraNotificationRequest.create = function create(properties) {
                        return new CameraNotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CameraNotificationRequest message. Does not implicitly {@link palexy.streaming.v1.CameraNotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ICameraNotificationRequest} message CameraNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraNotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notificationType != null && Object.hasOwnProperty.call(message, "notificationType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.notificationType);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.cameraId);
                        if (message.cameraEmptyVideoAlert != null && Object.hasOwnProperty.call(message, "cameraEmptyVideoAlert"))
                            $root.palexy.streaming.v1.CameraEmptyVideoAlert.encode(message.cameraEmptyVideoAlert, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CameraNotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CameraNotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ICameraNotificationRequest} message CameraNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CameraNotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CameraNotificationRequest} CameraNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraNotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CameraNotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.notificationType = reader.int32();
                                break;
                            case 2:
                                message.cameraId = reader.string();
                                break;
                            case 3:
                                message.cameraEmptyVideoAlert = $root.palexy.streaming.v1.CameraEmptyVideoAlert.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CameraNotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CameraNotificationRequest} CameraNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CameraNotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CameraNotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.notificationType != null && message.hasOwnProperty("notificationType"))
                            switch (message.notificationType) {
                            default:
                                return "notificationType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.cameraEmptyVideoAlert != null && message.hasOwnProperty("cameraEmptyVideoAlert")) {
                            properties.notificationObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.CameraEmptyVideoAlert.verify(message.cameraEmptyVideoAlert);
                                if (error)
                                    return "cameraEmptyVideoAlert." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CameraNotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CameraNotificationRequest} CameraNotificationRequest
                     */
                    CameraNotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CameraNotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CameraNotificationRequest();
                        switch (object.notificationType) {
                        case "UNKNOWN":
                        case 0:
                            message.notificationType = 0;
                            break;
                        case "CAMERA_BROKEN_ALERT_LEVEL_2":
                        case 1:
                            message.notificationType = 1;
                            break;
                        case "CAMERA_BROKEN_ALERT_LEVEL_1":
                        case 2:
                            message.notificationType = 2;
                            break;
                        case "CAMERA_EMPTY_VIDEO_ALERT":
                        case 3:
                            message.notificationType = 3;
                            break;
                        }
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.cameraEmptyVideoAlert != null) {
                            if (typeof object.cameraEmptyVideoAlert !== "object")
                                throw TypeError(".palexy.streaming.v1.CameraNotificationRequest.cameraEmptyVideoAlert: object expected");
                            message.cameraEmptyVideoAlert = $root.palexy.streaming.v1.CameraEmptyVideoAlert.fromObject(object.cameraEmptyVideoAlert);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CameraNotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.CameraNotificationRequest} message CameraNotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CameraNotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.notificationType = options.enums === String ? "UNKNOWN" : 0;
                            object.cameraId = "";
                        }
                        if (message.notificationType != null && message.hasOwnProperty("notificationType"))
                            object.notificationType = options.enums === String ? $root.palexy.streaming.v1.CameraNotificationRequest.NotificationType[message.notificationType] : message.notificationType;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.cameraEmptyVideoAlert != null && message.hasOwnProperty("cameraEmptyVideoAlert")) {
                            object.cameraEmptyVideoAlert = $root.palexy.streaming.v1.CameraEmptyVideoAlert.toObject(message.cameraEmptyVideoAlert, options);
                            if (options.oneofs)
                                object.notificationObject = "cameraEmptyVideoAlert";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CameraNotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CameraNotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CameraNotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * NotificationType enum.
                     * @name palexy.streaming.v1.CameraNotificationRequest.NotificationType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} CAMERA_BROKEN_ALERT_LEVEL_2=1 CAMERA_BROKEN_ALERT_LEVEL_2 value
                     * @property {number} CAMERA_BROKEN_ALERT_LEVEL_1=2 CAMERA_BROKEN_ALERT_LEVEL_1 value
                     * @property {number} CAMERA_EMPTY_VIDEO_ALERT=3 CAMERA_EMPTY_VIDEO_ALERT value
                     */
                    CameraNotificationRequest.NotificationType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "CAMERA_BROKEN_ALERT_LEVEL_2"] = 1;
                        values[valuesById[2] = "CAMERA_BROKEN_ALERT_LEVEL_1"] = 2;
                        values[valuesById[3] = "CAMERA_EMPTY_VIDEO_ALERT"] = 3;
                        return values;
                    })();
    
                    return CameraNotificationRequest;
                })();
    
                v1.CameraEmptyVideoAlert = (function() {
    
                    /**
                     * Properties of a CameraEmptyVideoAlert.
                     * @memberof palexy.streaming.v1
                     * @interface ICameraEmptyVideoAlert
                     * @property {string|null} [videoName] CameraEmptyVideoAlert videoName
                     */
    
                    /**
                     * Constructs a new CameraEmptyVideoAlert.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CameraEmptyVideoAlert.
                     * @implements ICameraEmptyVideoAlert
                     * @constructor
                     * @param {palexy.streaming.v1.ICameraEmptyVideoAlert=} [properties] Properties to set
                     */
                    function CameraEmptyVideoAlert(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CameraEmptyVideoAlert videoName.
                     * @member {string} videoName
                     * @memberof palexy.streaming.v1.CameraEmptyVideoAlert
                     * @instance
                     */
                    CameraEmptyVideoAlert.prototype.videoName = "";
    
                    /**
                     * Creates a new CameraEmptyVideoAlert instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CameraEmptyVideoAlert
                     * @static
                     * @param {palexy.streaming.v1.ICameraEmptyVideoAlert=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CameraEmptyVideoAlert} CameraEmptyVideoAlert instance
                     */
                    CameraEmptyVideoAlert.create = function create(properties) {
                        return new CameraEmptyVideoAlert(properties);
                    };
    
                    /**
                     * Encodes the specified CameraEmptyVideoAlert message. Does not implicitly {@link palexy.streaming.v1.CameraEmptyVideoAlert.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CameraEmptyVideoAlert
                     * @static
                     * @param {palexy.streaming.v1.ICameraEmptyVideoAlert} message CameraEmptyVideoAlert message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraEmptyVideoAlert.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.videoName != null && Object.hasOwnProperty.call(message, "videoName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.videoName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CameraEmptyVideoAlert message, length delimited. Does not implicitly {@link palexy.streaming.v1.CameraEmptyVideoAlert.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CameraEmptyVideoAlert
                     * @static
                     * @param {palexy.streaming.v1.ICameraEmptyVideoAlert} message CameraEmptyVideoAlert message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraEmptyVideoAlert.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CameraEmptyVideoAlert message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CameraEmptyVideoAlert
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CameraEmptyVideoAlert} CameraEmptyVideoAlert
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraEmptyVideoAlert.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CameraEmptyVideoAlert();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.videoName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CameraEmptyVideoAlert message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CameraEmptyVideoAlert
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CameraEmptyVideoAlert} CameraEmptyVideoAlert
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraEmptyVideoAlert.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CameraEmptyVideoAlert message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CameraEmptyVideoAlert
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CameraEmptyVideoAlert.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.videoName != null && message.hasOwnProperty("videoName"))
                            if (!$util.isString(message.videoName))
                                return "videoName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a CameraEmptyVideoAlert message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CameraEmptyVideoAlert
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CameraEmptyVideoAlert} CameraEmptyVideoAlert
                     */
                    CameraEmptyVideoAlert.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CameraEmptyVideoAlert)
                            return object;
                        var message = new $root.palexy.streaming.v1.CameraEmptyVideoAlert();
                        if (object.videoName != null)
                            message.videoName = String(object.videoName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CameraEmptyVideoAlert message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CameraEmptyVideoAlert
                     * @static
                     * @param {palexy.streaming.v1.CameraEmptyVideoAlert} message CameraEmptyVideoAlert
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CameraEmptyVideoAlert.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.videoName = "";
                        if (message.videoName != null && message.hasOwnProperty("videoName"))
                            object.videoName = message.videoName;
                        return object;
                    };
    
                    /**
                     * Converts this CameraEmptyVideoAlert to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CameraEmptyVideoAlert
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CameraEmptyVideoAlert.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CameraEmptyVideoAlert;
                })();
    
                v1.Notification = (function() {
    
                    /**
                     * Properties of a Notification.
                     * @memberof palexy.streaming.v1
                     * @interface INotification
                     * @property {number|null} [id] Notification id
                     * @property {number|null} [storeId] Notification storeId
                     * @property {number|null} [accountId] Notification accountId
                     * @property {palexy.streaming.v1.Notification.Type|null} [type] Notification type
                     * @property {string|null} [message] Notification message
                     * @property {boolean|null} [isRead] Notification isRead
                     * @property {google.protobuf.ITimestamp|null} [createTime] Notification createTime
                     * @property {google.protobuf.ITimestamp|null} [sentTime] Notification sentTime
                     * @property {palexy.streaming.v1.IDailyMetricDroppedAlert|null} [dailyTrafficDroppedAlert] Notification dailyTrafficDroppedAlert
                     * @property {palexy.streaming.v1.IDailyMetricDroppedAlert|null} [dailyCvrDroppedAlert] Notification dailyCvrDroppedAlert
                     * @property {palexy.streaming.v1.IShiftAlert|null} [shiftAlert] Notification shiftAlert
                     */
    
                    /**
                     * Constructs a new Notification.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Notification.
                     * @implements INotification
                     * @constructor
                     * @param {palexy.streaming.v1.INotification=} [properties] Properties to set
                     */
                    function Notification(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Notification id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Notification.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Notification storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Notification.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Notification accountId.
                     * @member {number} accountId
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Notification.prototype.accountId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Notification type.
                     * @member {palexy.streaming.v1.Notification.Type} type
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Notification.prototype.type = 0;
    
                    /**
                     * Notification message.
                     * @member {string} message
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Notification.prototype.message = "";
    
                    /**
                     * Notification isRead.
                     * @member {boolean} isRead
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Notification.prototype.isRead = false;
    
                    /**
                     * Notification createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Notification.prototype.createTime = null;
    
                    /**
                     * Notification sentTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} sentTime
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Notification.prototype.sentTime = null;
    
                    /**
                     * Notification dailyTrafficDroppedAlert.
                     * @member {palexy.streaming.v1.IDailyMetricDroppedAlert|null|undefined} dailyTrafficDroppedAlert
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Notification.prototype.dailyTrafficDroppedAlert = null;
    
                    /**
                     * Notification dailyCvrDroppedAlert.
                     * @member {palexy.streaming.v1.IDailyMetricDroppedAlert|null|undefined} dailyCvrDroppedAlert
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Notification.prototype.dailyCvrDroppedAlert = null;
    
                    /**
                     * Notification shiftAlert.
                     * @member {palexy.streaming.v1.IShiftAlert|null|undefined} shiftAlert
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Notification.prototype.shiftAlert = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * Notification notificationObject.
                     * @member {"dailyTrafficDroppedAlert"|"dailyCvrDroppedAlert"|"shiftAlert"|undefined} notificationObject
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     */
                    Object.defineProperty(Notification.prototype, "notificationObject", {
                        get: $util.oneOfGetter($oneOfFields = ["dailyTrafficDroppedAlert", "dailyCvrDroppedAlert", "shiftAlert"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new Notification instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Notification
                     * @static
                     * @param {palexy.streaming.v1.INotification=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Notification} Notification instance
                     */
                    Notification.create = function create(properties) {
                        return new Notification(properties);
                    };
    
                    /**
                     * Encodes the specified Notification message. Does not implicitly {@link palexy.streaming.v1.Notification.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Notification
                     * @static
                     * @param {palexy.streaming.v1.INotification} message Notification message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Notification.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.accountId);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.message);
                        if (message.isRead != null && Object.hasOwnProperty.call(message, "isRead"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isRead);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.sentTime != null && Object.hasOwnProperty.call(message, "sentTime"))
                            $root.google.protobuf.Timestamp.encode(message.sentTime, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.dailyTrafficDroppedAlert != null && Object.hasOwnProperty.call(message, "dailyTrafficDroppedAlert"))
                            $root.palexy.streaming.v1.DailyMetricDroppedAlert.encode(message.dailyTrafficDroppedAlert, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.dailyCvrDroppedAlert != null && Object.hasOwnProperty.call(message, "dailyCvrDroppedAlert"))
                            $root.palexy.streaming.v1.DailyMetricDroppedAlert.encode(message.dailyCvrDroppedAlert, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.shiftAlert != null && Object.hasOwnProperty.call(message, "shiftAlert"))
                            $root.palexy.streaming.v1.ShiftAlert.encode(message.shiftAlert, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Notification message, length delimited. Does not implicitly {@link palexy.streaming.v1.Notification.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Notification
                     * @static
                     * @param {palexy.streaming.v1.INotification} message Notification message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Notification.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Notification message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Notification
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Notification} Notification
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Notification.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Notification();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.accountId = reader.int64();
                                break;
                            case 4:
                                message.type = reader.int32();
                                break;
                            case 5:
                                message.message = reader.string();
                                break;
                            case 6:
                                message.isRead = reader.bool();
                                break;
                            case 7:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.sentTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.dailyTrafficDroppedAlert = $root.palexy.streaming.v1.DailyMetricDroppedAlert.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.dailyCvrDroppedAlert = $root.palexy.streaming.v1.DailyMetricDroppedAlert.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.shiftAlert = $root.palexy.streaming.v1.ShiftAlert.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Notification message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Notification
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Notification} Notification
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Notification.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Notification message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Notification
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Notification.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (!$util.isInteger(message.accountId) && !(message.accountId && $util.isInteger(message.accountId.low) && $util.isInteger(message.accountId.high)))
                                return "accountId: integer|Long expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        if (message.isRead != null && message.hasOwnProperty("isRead"))
                            if (typeof message.isRead !== "boolean")
                                return "isRead: boolean expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.sentTime != null && message.hasOwnProperty("sentTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.sentTime);
                            if (error)
                                return "sentTime." + error;
                        }
                        if (message.dailyTrafficDroppedAlert != null && message.hasOwnProperty("dailyTrafficDroppedAlert")) {
                            properties.notificationObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.DailyMetricDroppedAlert.verify(message.dailyTrafficDroppedAlert);
                                if (error)
                                    return "dailyTrafficDroppedAlert." + error;
                            }
                        }
                        if (message.dailyCvrDroppedAlert != null && message.hasOwnProperty("dailyCvrDroppedAlert")) {
                            if (properties.notificationObject === 1)
                                return "notificationObject: multiple values";
                            properties.notificationObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.DailyMetricDroppedAlert.verify(message.dailyCvrDroppedAlert);
                                if (error)
                                    return "dailyCvrDroppedAlert." + error;
                            }
                        }
                        if (message.shiftAlert != null && message.hasOwnProperty("shiftAlert")) {
                            if (properties.notificationObject === 1)
                                return "notificationObject: multiple values";
                            properties.notificationObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.ShiftAlert.verify(message.shiftAlert);
                                if (error)
                                    return "shiftAlert." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Notification message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Notification
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Notification} Notification
                     */
                    Notification.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Notification)
                            return object;
                        var message = new $root.palexy.streaming.v1.Notification();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.accountId != null)
                            if ($util.Long)
                                (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = false;
                            else if (typeof object.accountId === "string")
                                message.accountId = parseInt(object.accountId, 10);
                            else if (typeof object.accountId === "number")
                                message.accountId = object.accountId;
                            else if (typeof object.accountId === "object")
                                message.accountId = new $util.LongBits(object.accountId.low >>> 0, object.accountId.high >>> 0).toNumber();
                        switch (object.type) {
                        case "UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "DAILY_TRAFFIC_DROPPED_ALERT":
                        case 1:
                            message.type = 1;
                            break;
                        case "DAILY_CVR_DROPPED_ALERT":
                        case 2:
                            message.type = 2;
                            break;
                        case "SHIFT_ALERT":
                        case 3:
                            message.type = 3;
                            break;
                        }
                        if (object.message != null)
                            message.message = String(object.message);
                        if (object.isRead != null)
                            message.isRead = Boolean(object.isRead);
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Notification.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.sentTime != null) {
                            if (typeof object.sentTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Notification.sentTime: object expected");
                            message.sentTime = $root.google.protobuf.Timestamp.fromObject(object.sentTime);
                        }
                        if (object.dailyTrafficDroppedAlert != null) {
                            if (typeof object.dailyTrafficDroppedAlert !== "object")
                                throw TypeError(".palexy.streaming.v1.Notification.dailyTrafficDroppedAlert: object expected");
                            message.dailyTrafficDroppedAlert = $root.palexy.streaming.v1.DailyMetricDroppedAlert.fromObject(object.dailyTrafficDroppedAlert);
                        }
                        if (object.dailyCvrDroppedAlert != null) {
                            if (typeof object.dailyCvrDroppedAlert !== "object")
                                throw TypeError(".palexy.streaming.v1.Notification.dailyCvrDroppedAlert: object expected");
                            message.dailyCvrDroppedAlert = $root.palexy.streaming.v1.DailyMetricDroppedAlert.fromObject(object.dailyCvrDroppedAlert);
                        }
                        if (object.shiftAlert != null) {
                            if (typeof object.shiftAlert !== "object")
                                throw TypeError(".palexy.streaming.v1.Notification.shiftAlert: object expected");
                            message.shiftAlert = $root.palexy.streaming.v1.ShiftAlert.fromObject(object.shiftAlert);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Notification message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Notification
                     * @static
                     * @param {palexy.streaming.v1.Notification} message Notification
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Notification.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.accountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.accountId = options.longs === String ? "0" : 0;
                            object.type = options.enums === String ? "UNKNOWN" : 0;
                            object.message = "";
                            object.isRead = false;
                            object.createTime = null;
                            object.sentTime = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (typeof message.accountId === "number")
                                object.accountId = options.longs === String ? String(message.accountId) : message.accountId;
                            else
                                object.accountId = options.longs === String ? $util.Long.prototype.toString.call(message.accountId) : options.longs === Number ? new $util.LongBits(message.accountId.low >>> 0, message.accountId.high >>> 0).toNumber() : message.accountId;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.Notification.Type[message.type] : message.type;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        if (message.isRead != null && message.hasOwnProperty("isRead"))
                            object.isRead = message.isRead;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.sentTime != null && message.hasOwnProperty("sentTime"))
                            object.sentTime = $root.google.protobuf.Timestamp.toObject(message.sentTime, options);
                        if (message.dailyTrafficDroppedAlert != null && message.hasOwnProperty("dailyTrafficDroppedAlert")) {
                            object.dailyTrafficDroppedAlert = $root.palexy.streaming.v1.DailyMetricDroppedAlert.toObject(message.dailyTrafficDroppedAlert, options);
                            if (options.oneofs)
                                object.notificationObject = "dailyTrafficDroppedAlert";
                        }
                        if (message.dailyCvrDroppedAlert != null && message.hasOwnProperty("dailyCvrDroppedAlert")) {
                            object.dailyCvrDroppedAlert = $root.palexy.streaming.v1.DailyMetricDroppedAlert.toObject(message.dailyCvrDroppedAlert, options);
                            if (options.oneofs)
                                object.notificationObject = "dailyCvrDroppedAlert";
                        }
                        if (message.shiftAlert != null && message.hasOwnProperty("shiftAlert")) {
                            object.shiftAlert = $root.palexy.streaming.v1.ShiftAlert.toObject(message.shiftAlert, options);
                            if (options.oneofs)
                                object.notificationObject = "shiftAlert";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Notification to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Notification
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Notification.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Type enum.
                     * @name palexy.streaming.v1.Notification.Type
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} DAILY_TRAFFIC_DROPPED_ALERT=1 DAILY_TRAFFIC_DROPPED_ALERT value
                     * @property {number} DAILY_CVR_DROPPED_ALERT=2 DAILY_CVR_DROPPED_ALERT value
                     * @property {number} SHIFT_ALERT=3 SHIFT_ALERT value
                     */
                    Notification.Type = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "DAILY_TRAFFIC_DROPPED_ALERT"] = 1;
                        values[valuesById[2] = "DAILY_CVR_DROPPED_ALERT"] = 2;
                        values[valuesById[3] = "SHIFT_ALERT"] = 3;
                        return values;
                    })();
    
                    return Notification;
                })();
    
                v1.ShiftAlert = (function() {
    
                    /**
                     * Properties of a ShiftAlert.
                     * @memberof palexy.streaming.v1
                     * @interface IShiftAlert
                     * @property {google.protobuf.ITimestamp|null} [eventCreateTime] ShiftAlert eventCreateTime
                     * @property {string|null} [cameraId] ShiftAlert cameraId
                     * @property {string|null} [currentStatus] ShiftAlert currentStatus
                     * @property {string|null} [subjectId] ShiftAlert subjectId
                     * @property {number|null} [alertLevel] ShiftAlert alertLevel
                     */
    
                    /**
                     * Constructs a new ShiftAlert.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ShiftAlert.
                     * @implements IShiftAlert
                     * @constructor
                     * @param {palexy.streaming.v1.IShiftAlert=} [properties] Properties to set
                     */
                    function ShiftAlert(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ShiftAlert eventCreateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} eventCreateTime
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @instance
                     */
                    ShiftAlert.prototype.eventCreateTime = null;
    
                    /**
                     * ShiftAlert cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @instance
                     */
                    ShiftAlert.prototype.cameraId = "";
    
                    /**
                     * ShiftAlert currentStatus.
                     * @member {string} currentStatus
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @instance
                     */
                    ShiftAlert.prototype.currentStatus = "";
    
                    /**
                     * ShiftAlert subjectId.
                     * @member {string} subjectId
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @instance
                     */
                    ShiftAlert.prototype.subjectId = "";
    
                    /**
                     * ShiftAlert alertLevel.
                     * @member {number} alertLevel
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @instance
                     */
                    ShiftAlert.prototype.alertLevel = 0;
    
                    /**
                     * Creates a new ShiftAlert instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @static
                     * @param {palexy.streaming.v1.IShiftAlert=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ShiftAlert} ShiftAlert instance
                     */
                    ShiftAlert.create = function create(properties) {
                        return new ShiftAlert(properties);
                    };
    
                    /**
                     * Encodes the specified ShiftAlert message. Does not implicitly {@link palexy.streaming.v1.ShiftAlert.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @static
                     * @param {palexy.streaming.v1.IShiftAlert} message ShiftAlert message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShiftAlert.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.eventCreateTime != null && Object.hasOwnProperty.call(message, "eventCreateTime"))
                            $root.google.protobuf.Timestamp.encode(message.eventCreateTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.cameraId);
                        if (message.currentStatus != null && Object.hasOwnProperty.call(message, "currentStatus"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.currentStatus);
                        if (message.subjectId != null && Object.hasOwnProperty.call(message, "subjectId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.subjectId);
                        if (message.alertLevel != null && Object.hasOwnProperty.call(message, "alertLevel"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.alertLevel);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ShiftAlert message, length delimited. Does not implicitly {@link palexy.streaming.v1.ShiftAlert.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @static
                     * @param {palexy.streaming.v1.IShiftAlert} message ShiftAlert message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShiftAlert.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ShiftAlert message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ShiftAlert} ShiftAlert
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShiftAlert.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ShiftAlert();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.eventCreateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.cameraId = reader.string();
                                break;
                            case 3:
                                message.currentStatus = reader.string();
                                break;
                            case 4:
                                message.subjectId = reader.string();
                                break;
                            case 5:
                                message.alertLevel = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ShiftAlert message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ShiftAlert} ShiftAlert
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShiftAlert.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ShiftAlert message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShiftAlert.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.eventCreateTime != null && message.hasOwnProperty("eventCreateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.eventCreateTime);
                            if (error)
                                return "eventCreateTime." + error;
                        }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.currentStatus != null && message.hasOwnProperty("currentStatus"))
                            if (!$util.isString(message.currentStatus))
                                return "currentStatus: string expected";
                        if (message.subjectId != null && message.hasOwnProperty("subjectId"))
                            if (!$util.isString(message.subjectId))
                                return "subjectId: string expected";
                        if (message.alertLevel != null && message.hasOwnProperty("alertLevel"))
                            if (!$util.isInteger(message.alertLevel))
                                return "alertLevel: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ShiftAlert message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ShiftAlert} ShiftAlert
                     */
                    ShiftAlert.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ShiftAlert)
                            return object;
                        var message = new $root.palexy.streaming.v1.ShiftAlert();
                        if (object.eventCreateTime != null) {
                            if (typeof object.eventCreateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.ShiftAlert.eventCreateTime: object expected");
                            message.eventCreateTime = $root.google.protobuf.Timestamp.fromObject(object.eventCreateTime);
                        }
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.currentStatus != null)
                            message.currentStatus = String(object.currentStatus);
                        if (object.subjectId != null)
                            message.subjectId = String(object.subjectId);
                        if (object.alertLevel != null)
                            message.alertLevel = object.alertLevel | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ShiftAlert message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @static
                     * @param {palexy.streaming.v1.ShiftAlert} message ShiftAlert
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShiftAlert.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.eventCreateTime = null;
                            object.cameraId = "";
                            object.currentStatus = "";
                            object.subjectId = "";
                            object.alertLevel = 0;
                        }
                        if (message.eventCreateTime != null && message.hasOwnProperty("eventCreateTime"))
                            object.eventCreateTime = $root.google.protobuf.Timestamp.toObject(message.eventCreateTime, options);
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.currentStatus != null && message.hasOwnProperty("currentStatus"))
                            object.currentStatus = message.currentStatus;
                        if (message.subjectId != null && message.hasOwnProperty("subjectId"))
                            object.subjectId = message.subjectId;
                        if (message.alertLevel != null && message.hasOwnProperty("alertLevel"))
                            object.alertLevel = message.alertLevel;
                        return object;
                    };
    
                    /**
                     * Converts this ShiftAlert to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ShiftAlert
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShiftAlert.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ShiftAlert;
                })();
    
                v1.DailyMetricDroppedAlert = (function() {
    
                    /**
                     * Properties of a DailyMetricDroppedAlert.
                     * @memberof palexy.streaming.v1
                     * @interface IDailyMetricDroppedAlert
                     * @property {string|null} [dateId] DailyMetricDroppedAlert dateId
                     * @property {number|null} [previousMetric] DailyMetricDroppedAlert previousMetric
                     * @property {number|null} [currentMetric] DailyMetricDroppedAlert currentMetric
                     */
    
                    /**
                     * Constructs a new DailyMetricDroppedAlert.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DailyMetricDroppedAlert.
                     * @implements IDailyMetricDroppedAlert
                     * @constructor
                     * @param {palexy.streaming.v1.IDailyMetricDroppedAlert=} [properties] Properties to set
                     */
                    function DailyMetricDroppedAlert(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DailyMetricDroppedAlert dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @instance
                     */
                    DailyMetricDroppedAlert.prototype.dateId = "";
    
                    /**
                     * DailyMetricDroppedAlert previousMetric.
                     * @member {number} previousMetric
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @instance
                     */
                    DailyMetricDroppedAlert.prototype.previousMetric = 0;
    
                    /**
                     * DailyMetricDroppedAlert currentMetric.
                     * @member {number} currentMetric
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @instance
                     */
                    DailyMetricDroppedAlert.prototype.currentMetric = 0;
    
                    /**
                     * Creates a new DailyMetricDroppedAlert instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @static
                     * @param {palexy.streaming.v1.IDailyMetricDroppedAlert=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DailyMetricDroppedAlert} DailyMetricDroppedAlert instance
                     */
                    DailyMetricDroppedAlert.create = function create(properties) {
                        return new DailyMetricDroppedAlert(properties);
                    };
    
                    /**
                     * Encodes the specified DailyMetricDroppedAlert message. Does not implicitly {@link palexy.streaming.v1.DailyMetricDroppedAlert.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @static
                     * @param {palexy.streaming.v1.IDailyMetricDroppedAlert} message DailyMetricDroppedAlert message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DailyMetricDroppedAlert.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.dateId);
                        if (message.previousMetric != null && Object.hasOwnProperty.call(message, "previousMetric"))
                            writer.uint32(/* id 2, wireType 1 =*/17).double(message.previousMetric);
                        if (message.currentMetric != null && Object.hasOwnProperty.call(message, "currentMetric"))
                            writer.uint32(/* id 3, wireType 1 =*/25).double(message.currentMetric);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DailyMetricDroppedAlert message, length delimited. Does not implicitly {@link palexy.streaming.v1.DailyMetricDroppedAlert.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @static
                     * @param {palexy.streaming.v1.IDailyMetricDroppedAlert} message DailyMetricDroppedAlert message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DailyMetricDroppedAlert.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DailyMetricDroppedAlert message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DailyMetricDroppedAlert} DailyMetricDroppedAlert
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DailyMetricDroppedAlert.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DailyMetricDroppedAlert();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dateId = reader.string();
                                break;
                            case 2:
                                message.previousMetric = reader.double();
                                break;
                            case 3:
                                message.currentMetric = reader.double();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DailyMetricDroppedAlert message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DailyMetricDroppedAlert} DailyMetricDroppedAlert
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DailyMetricDroppedAlert.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DailyMetricDroppedAlert message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DailyMetricDroppedAlert.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.previousMetric != null && message.hasOwnProperty("previousMetric"))
                            if (typeof message.previousMetric !== "number")
                                return "previousMetric: number expected";
                        if (message.currentMetric != null && message.hasOwnProperty("currentMetric"))
                            if (typeof message.currentMetric !== "number")
                                return "currentMetric: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a DailyMetricDroppedAlert message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DailyMetricDroppedAlert} DailyMetricDroppedAlert
                     */
                    DailyMetricDroppedAlert.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DailyMetricDroppedAlert)
                            return object;
                        var message = new $root.palexy.streaming.v1.DailyMetricDroppedAlert();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.previousMetric != null)
                            message.previousMetric = Number(object.previousMetric);
                        if (object.currentMetric != null)
                            message.currentMetric = Number(object.currentMetric);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DailyMetricDroppedAlert message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @static
                     * @param {palexy.streaming.v1.DailyMetricDroppedAlert} message DailyMetricDroppedAlert
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DailyMetricDroppedAlert.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.dateId = "";
                            object.previousMetric = 0;
                            object.currentMetric = 0;
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.previousMetric != null && message.hasOwnProperty("previousMetric"))
                            object.previousMetric = options.json && !isFinite(message.previousMetric) ? String(message.previousMetric) : message.previousMetric;
                        if (message.currentMetric != null && message.hasOwnProperty("currentMetric"))
                            object.currentMetric = options.json && !isFinite(message.currentMetric) ? String(message.currentMetric) : message.currentMetric;
                        return object;
                    };
    
                    /**
                     * Converts this DailyMetricDroppedAlert to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DailyMetricDroppedAlert
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DailyMetricDroppedAlert.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DailyMetricDroppedAlert;
                })();
    
                v1.ListNotificationRequest = (function() {
    
                    /**
                     * Properties of a ListNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListNotificationRequest
                     * @property {number|null} [storeId] ListNotificationRequest storeId
                     * @property {number|null} [accountId] ListNotificationRequest accountId
                     * @property {google.protobuf.ITimestamp|null} [from] ListNotificationRequest from
                     * @property {google.protobuf.ITimestamp|null} [to] ListNotificationRequest to
                     * @property {palexy.streaming.v1.Notification.Type|null} [type] ListNotificationRequest type
                     * @property {google.protobuf.IBoolValue|null} [isRead] ListNotificationRequest isRead
                     * @property {google.protobuf.IBoolValue|null} [isSent] ListNotificationRequest isSent
                     * @property {number|null} [limit] ListNotificationRequest limit
                     * @property {google.protobuf.IFieldMask|null} [fieldMask] ListNotificationRequest fieldMask
                     */
    
                    /**
                     * Constructs a new ListNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListNotificationRequest.
                     * @implements IListNotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListNotificationRequest=} [properties] Properties to set
                     */
                    function ListNotificationRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListNotificationRequest storeId.
                     * @member {number|null|undefined} storeId
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @instance
                     */
                    ListNotificationRequest.prototype.storeId = null;
    
                    /**
                     * ListNotificationRequest accountId.
                     * @member {number|null|undefined} accountId
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @instance
                     */
                    ListNotificationRequest.prototype.accountId = null;
    
                    /**
                     * ListNotificationRequest from.
                     * @member {google.protobuf.ITimestamp|null|undefined} from
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @instance
                     */
                    ListNotificationRequest.prototype.from = null;
    
                    /**
                     * ListNotificationRequest to.
                     * @member {google.protobuf.ITimestamp|null|undefined} to
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @instance
                     */
                    ListNotificationRequest.prototype.to = null;
    
                    /**
                     * ListNotificationRequest type.
                     * @member {palexy.streaming.v1.Notification.Type} type
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @instance
                     */
                    ListNotificationRequest.prototype.type = 0;
    
                    /**
                     * ListNotificationRequest isRead.
                     * @member {google.protobuf.IBoolValue|null|undefined} isRead
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @instance
                     */
                    ListNotificationRequest.prototype.isRead = null;
    
                    /**
                     * ListNotificationRequest isSent.
                     * @member {google.protobuf.IBoolValue|null|undefined} isSent
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @instance
                     */
                    ListNotificationRequest.prototype.isSent = null;
    
                    /**
                     * ListNotificationRequest limit.
                     * @member {number} limit
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @instance
                     */
                    ListNotificationRequest.prototype.limit = 0;
    
                    /**
                     * ListNotificationRequest fieldMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} fieldMask
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @instance
                     */
                    ListNotificationRequest.prototype.fieldMask = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * ListNotificationRequest ownerFilter.
                     * @member {"storeId"|"accountId"|undefined} ownerFilter
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @instance
                     */
                    Object.defineProperty(ListNotificationRequest.prototype, "ownerFilter", {
                        get: $util.oneOfGetter($oneOfFields = ["storeId", "accountId"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new ListNotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IListNotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListNotificationRequest} ListNotificationRequest instance
                     */
                    ListNotificationRequest.create = function create(properties) {
                        return new ListNotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListNotificationRequest message. Does not implicitly {@link palexy.streaming.v1.ListNotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IListNotificationRequest} message ListNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListNotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.accountId);
                        if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                            $root.google.protobuf.Timestamp.encode(message.from, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                            $root.google.protobuf.Timestamp.encode(message.to, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                        if (message.isRead != null && Object.hasOwnProperty.call(message, "isRead"))
                            $root.google.protobuf.BoolValue.encode(message.isRead, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.isSent != null && Object.hasOwnProperty.call(message, "isSent"))
                            $root.google.protobuf.BoolValue.encode(message.isSent, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.limit);
                        if (message.fieldMask != null && Object.hasOwnProperty.call(message, "fieldMask"))
                            $root.google.protobuf.FieldMask.encode(message.fieldMask, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListNotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListNotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IListNotificationRequest} message ListNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListNotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListNotificationRequest} ListNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListNotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListNotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.accountId = reader.int64();
                                break;
                            case 3:
                                message.from = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.to = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.type = reader.int32();
                                break;
                            case 6:
                                message.isRead = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.isSent = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.limit = reader.int32();
                                break;
                            case 9:
                                message.fieldMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListNotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListNotificationRequest} ListNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListNotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListNotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            properties.ownerFilter = 1;
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        }
                        if (message.accountId != null && message.hasOwnProperty("accountId")) {
                            if (properties.ownerFilter === 1)
                                return "ownerFilter: multiple values";
                            properties.ownerFilter = 1;
                            if (!$util.isInteger(message.accountId) && !(message.accountId && $util.isInteger(message.accountId.low) && $util.isInteger(message.accountId.high)))
                                return "accountId: integer|Long expected";
                        }
                        if (message.from != null && message.hasOwnProperty("from")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.from);
                            if (error)
                                return "from." + error;
                        }
                        if (message.to != null && message.hasOwnProperty("to")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.to);
                            if (error)
                                return "to." + error;
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.isRead != null && message.hasOwnProperty("isRead")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isRead);
                            if (error)
                                return "isRead." + error;
                        }
                        if (message.isSent != null && message.hasOwnProperty("isSent")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isSent);
                            if (error)
                                return "isSent." + error;
                        }
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            if (!$util.isInteger(message.limit))
                                return "limit: integer expected";
                        if (message.fieldMask != null && message.hasOwnProperty("fieldMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.fieldMask);
                            if (error)
                                return "fieldMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListNotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListNotificationRequest} ListNotificationRequest
                     */
                    ListNotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListNotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListNotificationRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.accountId != null)
                            if ($util.Long)
                                (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = false;
                            else if (typeof object.accountId === "string")
                                message.accountId = parseInt(object.accountId, 10);
                            else if (typeof object.accountId === "number")
                                message.accountId = object.accountId;
                            else if (typeof object.accountId === "object")
                                message.accountId = new $util.LongBits(object.accountId.low >>> 0, object.accountId.high >>> 0).toNumber();
                        if (object.from != null) {
                            if (typeof object.from !== "object")
                                throw TypeError(".palexy.streaming.v1.ListNotificationRequest.from: object expected");
                            message.from = $root.google.protobuf.Timestamp.fromObject(object.from);
                        }
                        if (object.to != null) {
                            if (typeof object.to !== "object")
                                throw TypeError(".palexy.streaming.v1.ListNotificationRequest.to: object expected");
                            message.to = $root.google.protobuf.Timestamp.fromObject(object.to);
                        }
                        switch (object.type) {
                        case "UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "DAILY_TRAFFIC_DROPPED_ALERT":
                        case 1:
                            message.type = 1;
                            break;
                        case "DAILY_CVR_DROPPED_ALERT":
                        case 2:
                            message.type = 2;
                            break;
                        case "SHIFT_ALERT":
                        case 3:
                            message.type = 3;
                            break;
                        }
                        if (object.isRead != null) {
                            if (typeof object.isRead !== "object")
                                throw TypeError(".palexy.streaming.v1.ListNotificationRequest.isRead: object expected");
                            message.isRead = $root.google.protobuf.BoolValue.fromObject(object.isRead);
                        }
                        if (object.isSent != null) {
                            if (typeof object.isSent !== "object")
                                throw TypeError(".palexy.streaming.v1.ListNotificationRequest.isSent: object expected");
                            message.isSent = $root.google.protobuf.BoolValue.fromObject(object.isSent);
                        }
                        if (object.limit != null)
                            message.limit = object.limit | 0;
                        if (object.fieldMask != null) {
                            if (typeof object.fieldMask !== "object")
                                throw TypeError(".palexy.streaming.v1.ListNotificationRequest.fieldMask: object expected");
                            message.fieldMask = $root.google.protobuf.FieldMask.fromObject(object.fieldMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListNotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ListNotificationRequest} message ListNotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListNotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.from = null;
                            object.to = null;
                            object.type = options.enums === String ? "UNKNOWN" : 0;
                            object.isRead = null;
                            object.isSent = null;
                            object.limit = 0;
                            object.fieldMask = null;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            if (options.oneofs)
                                object.ownerFilter = "storeId";
                        }
                        if (message.accountId != null && message.hasOwnProperty("accountId")) {
                            if (typeof message.accountId === "number")
                                object.accountId = options.longs === String ? String(message.accountId) : message.accountId;
                            else
                                object.accountId = options.longs === String ? $util.Long.prototype.toString.call(message.accountId) : options.longs === Number ? new $util.LongBits(message.accountId.low >>> 0, message.accountId.high >>> 0).toNumber() : message.accountId;
                            if (options.oneofs)
                                object.ownerFilter = "accountId";
                        }
                        if (message.from != null && message.hasOwnProperty("from"))
                            object.from = $root.google.protobuf.Timestamp.toObject(message.from, options);
                        if (message.to != null && message.hasOwnProperty("to"))
                            object.to = $root.google.protobuf.Timestamp.toObject(message.to, options);
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.Notification.Type[message.type] : message.type;
                        if (message.isRead != null && message.hasOwnProperty("isRead"))
                            object.isRead = $root.google.protobuf.BoolValue.toObject(message.isRead, options);
                        if (message.isSent != null && message.hasOwnProperty("isSent"))
                            object.isSent = $root.google.protobuf.BoolValue.toObject(message.isSent, options);
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            object.limit = message.limit;
                        if (message.fieldMask != null && message.hasOwnProperty("fieldMask"))
                            object.fieldMask = $root.google.protobuf.FieldMask.toObject(message.fieldMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListNotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListNotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListNotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListNotificationRequest;
                })();
    
                v1.ListNotificationResponse = (function() {
    
                    /**
                     * Properties of a ListNotificationResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListNotificationResponse
                     * @property {Array.<palexy.streaming.v1.INotification>|null} [notifications] ListNotificationResponse notifications
                     */
    
                    /**
                     * Constructs a new ListNotificationResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListNotificationResponse.
                     * @implements IListNotificationResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListNotificationResponse=} [properties] Properties to set
                     */
                    function ListNotificationResponse(properties) {
                        this.notifications = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListNotificationResponse notifications.
                     * @member {Array.<palexy.streaming.v1.INotification>} notifications
                     * @memberof palexy.streaming.v1.ListNotificationResponse
                     * @instance
                     */
                    ListNotificationResponse.prototype.notifications = $util.emptyArray;
    
                    /**
                     * Creates a new ListNotificationResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IListNotificationResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListNotificationResponse} ListNotificationResponse instance
                     */
                    ListNotificationResponse.create = function create(properties) {
                        return new ListNotificationResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListNotificationResponse message. Does not implicitly {@link palexy.streaming.v1.ListNotificationResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IListNotificationResponse} message ListNotificationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListNotificationResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notifications != null && message.notifications.length)
                            for (var i = 0; i < message.notifications.length; ++i)
                                $root.palexy.streaming.v1.Notification.encode(message.notifications[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListNotificationResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListNotificationResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IListNotificationResponse} message ListNotificationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListNotificationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListNotificationResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListNotificationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListNotificationResponse} ListNotificationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListNotificationResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListNotificationResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.notifications && message.notifications.length))
                                    message.notifications = [];
                                message.notifications.push($root.palexy.streaming.v1.Notification.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListNotificationResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListNotificationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListNotificationResponse} ListNotificationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListNotificationResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListNotificationResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListNotificationResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListNotificationResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notifications != null && message.hasOwnProperty("notifications")) {
                            if (!Array.isArray(message.notifications))
                                return "notifications: array expected";
                            for (var i = 0; i < message.notifications.length; ++i) {
                                var error = $root.palexy.streaming.v1.Notification.verify(message.notifications[i]);
                                if (error)
                                    return "notifications." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListNotificationResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListNotificationResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListNotificationResponse} ListNotificationResponse
                     */
                    ListNotificationResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListNotificationResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListNotificationResponse();
                        if (object.notifications) {
                            if (!Array.isArray(object.notifications))
                                throw TypeError(".palexy.streaming.v1.ListNotificationResponse.notifications: array expected");
                            message.notifications = [];
                            for (var i = 0; i < object.notifications.length; ++i) {
                                if (typeof object.notifications[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListNotificationResponse.notifications: object expected");
                                message.notifications[i] = $root.palexy.streaming.v1.Notification.fromObject(object.notifications[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListNotificationResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.ListNotificationResponse} message ListNotificationResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListNotificationResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.notifications = [];
                        if (message.notifications && message.notifications.length) {
                            object.notifications = [];
                            for (var j = 0; j < message.notifications.length; ++j)
                                object.notifications[j] = $root.palexy.streaming.v1.Notification.toObject(message.notifications[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListNotificationResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListNotificationResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListNotificationResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListNotificationResponse;
                })();
    
                v1.GetNotificationRequest = (function() {
    
                    /**
                     * Properties of a GetNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetNotificationRequest
                     * @property {number|null} [notificationId] GetNotificationRequest notificationId
                     */
    
                    /**
                     * Constructs a new GetNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetNotificationRequest.
                     * @implements IGetNotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetNotificationRequest=} [properties] Properties to set
                     */
                    function GetNotificationRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetNotificationRequest notificationId.
                     * @member {number} notificationId
                     * @memberof palexy.streaming.v1.GetNotificationRequest
                     * @instance
                     */
                    GetNotificationRequest.prototype.notificationId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetNotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetNotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetNotificationRequest} GetNotificationRequest instance
                     */
                    GetNotificationRequest.create = function create(properties) {
                        return new GetNotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetNotificationRequest message. Does not implicitly {@link palexy.streaming.v1.GetNotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetNotificationRequest} message GetNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notificationId != null && Object.hasOwnProperty.call(message, "notificationId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.notificationId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetNotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetNotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetNotificationRequest} message GetNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetNotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetNotificationRequest} GetNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetNotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.notificationId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetNotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetNotificationRequest} GetNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetNotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetNotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetNotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (!$util.isInteger(message.notificationId) && !(message.notificationId && $util.isInteger(message.notificationId.low) && $util.isInteger(message.notificationId.high)))
                                return "notificationId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetNotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetNotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetNotificationRequest} GetNotificationRequest
                     */
                    GetNotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetNotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetNotificationRequest();
                        if (object.notificationId != null)
                            if ($util.Long)
                                (message.notificationId = $util.Long.fromValue(object.notificationId)).unsigned = false;
                            else if (typeof object.notificationId === "string")
                                message.notificationId = parseInt(object.notificationId, 10);
                            else if (typeof object.notificationId === "number")
                                message.notificationId = object.notificationId;
                            else if (typeof object.notificationId === "object")
                                message.notificationId = new $util.LongBits(object.notificationId.low >>> 0, object.notificationId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetNotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.GetNotificationRequest} message GetNotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetNotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.notificationId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.notificationId = options.longs === String ? "0" : 0;
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (typeof message.notificationId === "number")
                                object.notificationId = options.longs === String ? String(message.notificationId) : message.notificationId;
                            else
                                object.notificationId = options.longs === String ? $util.Long.prototype.toString.call(message.notificationId) : options.longs === Number ? new $util.LongBits(message.notificationId.low >>> 0, message.notificationId.high >>> 0).toNumber() : message.notificationId;
                        return object;
                    };
    
                    /**
                     * Converts this GetNotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetNotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetNotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetNotificationRequest;
                })();
    
                v1.BatchCreateNotificationRequest = (function() {
    
                    /**
                     * Properties of a BatchCreateNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreateNotificationRequest
                     * @property {Array.<palexy.streaming.v1.INotification>|null} [notifications] BatchCreateNotificationRequest notifications
                     */
    
                    /**
                     * Constructs a new BatchCreateNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreateNotificationRequest.
                     * @implements IBatchCreateNotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreateNotificationRequest=} [properties] Properties to set
                     */
                    function BatchCreateNotificationRequest(properties) {
                        this.notifications = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateNotificationRequest notifications.
                     * @member {Array.<palexy.streaming.v1.INotification>} notifications
                     * @memberof palexy.streaming.v1.BatchCreateNotificationRequest
                     * @instance
                     */
                    BatchCreateNotificationRequest.prototype.notifications = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateNotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreateNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateNotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreateNotificationRequest} BatchCreateNotificationRequest instance
                     */
                    BatchCreateNotificationRequest.create = function create(properties) {
                        return new BatchCreateNotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateNotificationRequest message. Does not implicitly {@link palexy.streaming.v1.BatchCreateNotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreateNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateNotificationRequest} message BatchCreateNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateNotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notifications != null && message.notifications.length)
                            for (var i = 0; i < message.notifications.length; ++i)
                                $root.palexy.streaming.v1.Notification.encode(message.notifications[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateNotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreateNotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateNotificationRequest} message BatchCreateNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateNotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreateNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreateNotificationRequest} BatchCreateNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateNotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreateNotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.notifications && message.notifications.length))
                                    message.notifications = [];
                                message.notifications.push($root.palexy.streaming.v1.Notification.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateNotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreateNotificationRequest} BatchCreateNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateNotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreateNotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateNotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notifications != null && message.hasOwnProperty("notifications")) {
                            if (!Array.isArray(message.notifications))
                                return "notifications: array expected";
                            for (var i = 0; i < message.notifications.length; ++i) {
                                var error = $root.palexy.streaming.v1.Notification.verify(message.notifications[i]);
                                if (error)
                                    return "notifications." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateNotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreateNotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreateNotificationRequest} BatchCreateNotificationRequest
                     */
                    BatchCreateNotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreateNotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreateNotificationRequest();
                        if (object.notifications) {
                            if (!Array.isArray(object.notifications))
                                throw TypeError(".palexy.streaming.v1.BatchCreateNotificationRequest.notifications: array expected");
                            message.notifications = [];
                            for (var i = 0; i < object.notifications.length; ++i) {
                                if (typeof object.notifications[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreateNotificationRequest.notifications: object expected");
                                message.notifications[i] = $root.palexy.streaming.v1.Notification.fromObject(object.notifications[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateNotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreateNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchCreateNotificationRequest} message BatchCreateNotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateNotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.notifications = [];
                        if (message.notifications && message.notifications.length) {
                            object.notifications = [];
                            for (var j = 0; j < message.notifications.length; ++j)
                                object.notifications[j] = $root.palexy.streaming.v1.Notification.toObject(message.notifications[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateNotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreateNotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateNotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateNotificationRequest;
                })();
    
                v1.BatchCreateNotificationResponse = (function() {
    
                    /**
                     * Properties of a BatchCreateNotificationResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreateNotificationResponse
                     * @property {Array.<palexy.streaming.v1.INotification>|null} [notifications] BatchCreateNotificationResponse notifications
                     */
    
                    /**
                     * Constructs a new BatchCreateNotificationResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreateNotificationResponse.
                     * @implements IBatchCreateNotificationResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreateNotificationResponse=} [properties] Properties to set
                     */
                    function BatchCreateNotificationResponse(properties) {
                        this.notifications = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateNotificationResponse notifications.
                     * @member {Array.<palexy.streaming.v1.INotification>} notifications
                     * @memberof palexy.streaming.v1.BatchCreateNotificationResponse
                     * @instance
                     */
                    BatchCreateNotificationResponse.prototype.notifications = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateNotificationResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreateNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateNotificationResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreateNotificationResponse} BatchCreateNotificationResponse instance
                     */
                    BatchCreateNotificationResponse.create = function create(properties) {
                        return new BatchCreateNotificationResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateNotificationResponse message. Does not implicitly {@link palexy.streaming.v1.BatchCreateNotificationResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreateNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateNotificationResponse} message BatchCreateNotificationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateNotificationResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notifications != null && message.notifications.length)
                            for (var i = 0; i < message.notifications.length; ++i)
                                $root.palexy.streaming.v1.Notification.encode(message.notifications[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateNotificationResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreateNotificationResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateNotificationResponse} message BatchCreateNotificationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateNotificationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateNotificationResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreateNotificationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreateNotificationResponse} BatchCreateNotificationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateNotificationResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreateNotificationResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.notifications && message.notifications.length))
                                    message.notifications = [];
                                message.notifications.push($root.palexy.streaming.v1.Notification.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateNotificationResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateNotificationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreateNotificationResponse} BatchCreateNotificationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateNotificationResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateNotificationResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreateNotificationResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateNotificationResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notifications != null && message.hasOwnProperty("notifications")) {
                            if (!Array.isArray(message.notifications))
                                return "notifications: array expected";
                            for (var i = 0; i < message.notifications.length; ++i) {
                                var error = $root.palexy.streaming.v1.Notification.verify(message.notifications[i]);
                                if (error)
                                    return "notifications." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateNotificationResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreateNotificationResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreateNotificationResponse} BatchCreateNotificationResponse
                     */
                    BatchCreateNotificationResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreateNotificationResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreateNotificationResponse();
                        if (object.notifications) {
                            if (!Array.isArray(object.notifications))
                                throw TypeError(".palexy.streaming.v1.BatchCreateNotificationResponse.notifications: array expected");
                            message.notifications = [];
                            for (var i = 0; i < object.notifications.length; ++i) {
                                if (typeof object.notifications[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreateNotificationResponse.notifications: object expected");
                                message.notifications[i] = $root.palexy.streaming.v1.Notification.fromObject(object.notifications[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateNotificationResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreateNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchCreateNotificationResponse} message BatchCreateNotificationResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateNotificationResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.notifications = [];
                        if (message.notifications && message.notifications.length) {
                            object.notifications = [];
                            for (var j = 0; j < message.notifications.length; ++j)
                                object.notifications[j] = $root.palexy.streaming.v1.Notification.toObject(message.notifications[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateNotificationResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreateNotificationResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateNotificationResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateNotificationResponse;
                })();
    
                v1.MarkAllNotificationsAsReadRequest = (function() {
    
                    /**
                     * Properties of a MarkAllNotificationsAsReadRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IMarkAllNotificationsAsReadRequest
                     * @property {number|null} [accountId] MarkAllNotificationsAsReadRequest accountId
                     */
    
                    /**
                     * Constructs a new MarkAllNotificationsAsReadRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MarkAllNotificationsAsReadRequest.
                     * @implements IMarkAllNotificationsAsReadRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IMarkAllNotificationsAsReadRequest=} [properties] Properties to set
                     */
                    function MarkAllNotificationsAsReadRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MarkAllNotificationsAsReadRequest accountId.
                     * @member {number} accountId
                     * @memberof palexy.streaming.v1.MarkAllNotificationsAsReadRequest
                     * @instance
                     */
                    MarkAllNotificationsAsReadRequest.prototype.accountId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new MarkAllNotificationsAsReadRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MarkAllNotificationsAsReadRequest
                     * @static
                     * @param {palexy.streaming.v1.IMarkAllNotificationsAsReadRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MarkAllNotificationsAsReadRequest} MarkAllNotificationsAsReadRequest instance
                     */
                    MarkAllNotificationsAsReadRequest.create = function create(properties) {
                        return new MarkAllNotificationsAsReadRequest(properties);
                    };
    
                    /**
                     * Encodes the specified MarkAllNotificationsAsReadRequest message. Does not implicitly {@link palexy.streaming.v1.MarkAllNotificationsAsReadRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MarkAllNotificationsAsReadRequest
                     * @static
                     * @param {palexy.streaming.v1.IMarkAllNotificationsAsReadRequest} message MarkAllNotificationsAsReadRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MarkAllNotificationsAsReadRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.accountId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MarkAllNotificationsAsReadRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.MarkAllNotificationsAsReadRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MarkAllNotificationsAsReadRequest
                     * @static
                     * @param {palexy.streaming.v1.IMarkAllNotificationsAsReadRequest} message MarkAllNotificationsAsReadRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MarkAllNotificationsAsReadRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MarkAllNotificationsAsReadRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MarkAllNotificationsAsReadRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MarkAllNotificationsAsReadRequest} MarkAllNotificationsAsReadRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MarkAllNotificationsAsReadRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MarkAllNotificationsAsReadRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.accountId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MarkAllNotificationsAsReadRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MarkAllNotificationsAsReadRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MarkAllNotificationsAsReadRequest} MarkAllNotificationsAsReadRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MarkAllNotificationsAsReadRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MarkAllNotificationsAsReadRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MarkAllNotificationsAsReadRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MarkAllNotificationsAsReadRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (!$util.isInteger(message.accountId) && !(message.accountId && $util.isInteger(message.accountId.low) && $util.isInteger(message.accountId.high)))
                                return "accountId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a MarkAllNotificationsAsReadRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MarkAllNotificationsAsReadRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MarkAllNotificationsAsReadRequest} MarkAllNotificationsAsReadRequest
                     */
                    MarkAllNotificationsAsReadRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MarkAllNotificationsAsReadRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.MarkAllNotificationsAsReadRequest();
                        if (object.accountId != null)
                            if ($util.Long)
                                (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = false;
                            else if (typeof object.accountId === "string")
                                message.accountId = parseInt(object.accountId, 10);
                            else if (typeof object.accountId === "number")
                                message.accountId = object.accountId;
                            else if (typeof object.accountId === "object")
                                message.accountId = new $util.LongBits(object.accountId.low >>> 0, object.accountId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MarkAllNotificationsAsReadRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MarkAllNotificationsAsReadRequest
                     * @static
                     * @param {palexy.streaming.v1.MarkAllNotificationsAsReadRequest} message MarkAllNotificationsAsReadRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MarkAllNotificationsAsReadRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.accountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.accountId = options.longs === String ? "0" : 0;
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (typeof message.accountId === "number")
                                object.accountId = options.longs === String ? String(message.accountId) : message.accountId;
                            else
                                object.accountId = options.longs === String ? $util.Long.prototype.toString.call(message.accountId) : options.longs === Number ? new $util.LongBits(message.accountId.low >>> 0, message.accountId.high >>> 0).toNumber() : message.accountId;
                        return object;
                    };
    
                    /**
                     * Converts this MarkAllNotificationsAsReadRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MarkAllNotificationsAsReadRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MarkAllNotificationsAsReadRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MarkAllNotificationsAsReadRequest;
                })();
    
                v1.UpdateNotificationRequest = (function() {
    
                    /**
                     * Properties of an UpdateNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateNotificationRequest
                     * @property {palexy.streaming.v1.INotification|null} [notification] UpdateNotificationRequest notification
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateNotificationRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateNotificationRequest.
                     * @implements IUpdateNotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateNotificationRequest=} [properties] Properties to set
                     */
                    function UpdateNotificationRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateNotificationRequest notification.
                     * @member {palexy.streaming.v1.INotification|null|undefined} notification
                     * @memberof palexy.streaming.v1.UpdateNotificationRequest
                     * @instance
                     */
                    UpdateNotificationRequest.prototype.notification = null;
    
                    /**
                     * UpdateNotificationRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateNotificationRequest
                     * @instance
                     */
                    UpdateNotificationRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateNotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateNotificationRequest} UpdateNotificationRequest instance
                     */
                    UpdateNotificationRequest.create = function create(properties) {
                        return new UpdateNotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateNotificationRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateNotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNotificationRequest} message UpdateNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateNotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notification != null && Object.hasOwnProperty.call(message, "notification"))
                            $root.palexy.streaming.v1.Notification.encode(message.notification, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateNotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateNotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNotificationRequest} message UpdateNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateNotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateNotificationRequest} UpdateNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateNotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateNotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.notification = $root.palexy.streaming.v1.Notification.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateNotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateNotificationRequest} UpdateNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateNotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateNotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateNotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notification != null && message.hasOwnProperty("notification")) {
                            var error = $root.palexy.streaming.v1.Notification.verify(message.notification);
                            if (error)
                                return "notification." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateNotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateNotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateNotificationRequest} UpdateNotificationRequest
                     */
                    UpdateNotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateNotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateNotificationRequest();
                        if (object.notification != null) {
                            if (typeof object.notification !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateNotificationRequest.notification: object expected");
                            message.notification = $root.palexy.streaming.v1.Notification.fromObject(object.notification);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateNotificationRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateNotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateNotificationRequest} message UpdateNotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateNotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.notification = null;
                            object.updateMask = null;
                        }
                        if (message.notification != null && message.hasOwnProperty("notification"))
                            object.notification = $root.palexy.streaming.v1.Notification.toObject(message.notification, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateNotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateNotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateNotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateNotificationRequest;
                })();
    
                v1.SendNotificationRequest = (function() {
    
                    /**
                     * Properties of a SendNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ISendNotificationRequest
                     * @property {Array.<number>|null} [notificationIds] SendNotificationRequest notificationIds
                     */
    
                    /**
                     * Constructs a new SendNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SendNotificationRequest.
                     * @implements ISendNotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ISendNotificationRequest=} [properties] Properties to set
                     */
                    function SendNotificationRequest(properties) {
                        this.notificationIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SendNotificationRequest notificationIds.
                     * @member {Array.<number>} notificationIds
                     * @memberof palexy.streaming.v1.SendNotificationRequest
                     * @instance
                     */
                    SendNotificationRequest.prototype.notificationIds = $util.emptyArray;
    
                    /**
                     * Creates a new SendNotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SendNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendNotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SendNotificationRequest} SendNotificationRequest instance
                     */
                    SendNotificationRequest.create = function create(properties) {
                        return new SendNotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified SendNotificationRequest message. Does not implicitly {@link palexy.streaming.v1.SendNotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SendNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendNotificationRequest} message SendNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendNotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notificationIds != null && message.notificationIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.notificationIds.length; ++i)
                                writer.int64(message.notificationIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SendNotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.SendNotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SendNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendNotificationRequest} message SendNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SendNotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SendNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SendNotificationRequest} SendNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendNotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SendNotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.notificationIds && message.notificationIds.length))
                                    message.notificationIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.notificationIds.push(reader.int64());
                                } else
                                    message.notificationIds.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SendNotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SendNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SendNotificationRequest} SendNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SendNotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SendNotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SendNotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notificationIds != null && message.hasOwnProperty("notificationIds")) {
                            if (!Array.isArray(message.notificationIds))
                                return "notificationIds: array expected";
                            for (var i = 0; i < message.notificationIds.length; ++i)
                                if (!$util.isInteger(message.notificationIds[i]) && !(message.notificationIds[i] && $util.isInteger(message.notificationIds[i].low) && $util.isInteger(message.notificationIds[i].high)))
                                    return "notificationIds: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a SendNotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SendNotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SendNotificationRequest} SendNotificationRequest
                     */
                    SendNotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SendNotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.SendNotificationRequest();
                        if (object.notificationIds) {
                            if (!Array.isArray(object.notificationIds))
                                throw TypeError(".palexy.streaming.v1.SendNotificationRequest.notificationIds: array expected");
                            message.notificationIds = [];
                            for (var i = 0; i < object.notificationIds.length; ++i)
                                if ($util.Long)
                                    (message.notificationIds[i] = $util.Long.fromValue(object.notificationIds[i])).unsigned = false;
                                else if (typeof object.notificationIds[i] === "string")
                                    message.notificationIds[i] = parseInt(object.notificationIds[i], 10);
                                else if (typeof object.notificationIds[i] === "number")
                                    message.notificationIds[i] = object.notificationIds[i];
                                else if (typeof object.notificationIds[i] === "object")
                                    message.notificationIds[i] = new $util.LongBits(object.notificationIds[i].low >>> 0, object.notificationIds[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SendNotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SendNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.SendNotificationRequest} message SendNotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SendNotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.notificationIds = [];
                        if (message.notificationIds && message.notificationIds.length) {
                            object.notificationIds = [];
                            for (var j = 0; j < message.notificationIds.length; ++j)
                                if (typeof message.notificationIds[j] === "number")
                                    object.notificationIds[j] = options.longs === String ? String(message.notificationIds[j]) : message.notificationIds[j];
                                else
                                    object.notificationIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.notificationIds[j]) : options.longs === Number ? new $util.LongBits(message.notificationIds[j].low >>> 0, message.notificationIds[j].high >>> 0).toNumber() : message.notificationIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this SendNotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SendNotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SendNotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SendNotificationRequest;
                })();
    
                v1.SendNotificationResponse = (function() {
    
                    /**
                     * Properties of a SendNotificationResponse.
                     * @memberof palexy.streaming.v1
                     * @interface ISendNotificationResponse
                     * @property {number|null} [failedCount] SendNotificationResponse failedCount
                     */
    
                    /**
                     * Constructs a new SendNotificationResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SendNotificationResponse.
                     * @implements ISendNotificationResponse
                     * @constructor
                     * @param {palexy.streaming.v1.ISendNotificationResponse=} [properties] Properties to set
                     */
                    function SendNotificationResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SendNotificationResponse failedCount.
                     * @member {number} failedCount
                     * @memberof palexy.streaming.v1.SendNotificationResponse
                     * @instance
                     */
                    SendNotificationResponse.prototype.failedCount = 0;
    
                    /**
                     * Creates a new SendNotificationResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SendNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.ISendNotificationResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SendNotificationResponse} SendNotificationResponse instance
                     */
                    SendNotificationResponse.create = function create(properties) {
                        return new SendNotificationResponse(properties);
                    };
    
                    /**
                     * Encodes the specified SendNotificationResponse message. Does not implicitly {@link palexy.streaming.v1.SendNotificationResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SendNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.ISendNotificationResponse} message SendNotificationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendNotificationResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.failedCount != null && Object.hasOwnProperty.call(message, "failedCount"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.failedCount);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SendNotificationResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.SendNotificationResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SendNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.ISendNotificationResponse} message SendNotificationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendNotificationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SendNotificationResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SendNotificationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SendNotificationResponse} SendNotificationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendNotificationResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SendNotificationResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.failedCount = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SendNotificationResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SendNotificationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SendNotificationResponse} SendNotificationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendNotificationResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SendNotificationResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SendNotificationResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SendNotificationResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.failedCount != null && message.hasOwnProperty("failedCount"))
                            if (!$util.isInteger(message.failedCount))
                                return "failedCount: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a SendNotificationResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SendNotificationResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SendNotificationResponse} SendNotificationResponse
                     */
                    SendNotificationResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SendNotificationResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.SendNotificationResponse();
                        if (object.failedCount != null)
                            message.failedCount = object.failedCount | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SendNotificationResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SendNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.SendNotificationResponse} message SendNotificationResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SendNotificationResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.failedCount = 0;
                        if (message.failedCount != null && message.hasOwnProperty("failedCount"))
                            object.failedCount = message.failedCount;
                        return object;
                    };
    
                    /**
                     * Converts this SendNotificationResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SendNotificationResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SendNotificationResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SendNotificationResponse;
                })();
    
                v1.ExportRawICANotificationRequest = (function() {
    
                    /**
                     * Properties of an ExportRawICANotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IExportRawICANotificationRequest
                     * @property {number|null} [companyId] ExportRawICANotificationRequest companyId
                     * @property {number|null} [dataDateId] ExportRawICANotificationRequest dataDateId
                     * @property {Array.<palexy.streaming.v1.IExportedFile>|null} [files] ExportRawICANotificationRequest files
                     */
    
                    /**
                     * Constructs a new ExportRawICANotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ExportRawICANotificationRequest.
                     * @implements IExportRawICANotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IExportRawICANotificationRequest=} [properties] Properties to set
                     */
                    function ExportRawICANotificationRequest(properties) {
                        this.files = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExportRawICANotificationRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @instance
                     */
                    ExportRawICANotificationRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExportRawICANotificationRequest dataDateId.
                     * @member {number} dataDateId
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @instance
                     */
                    ExportRawICANotificationRequest.prototype.dataDateId = 0;
    
                    /**
                     * ExportRawICANotificationRequest files.
                     * @member {Array.<palexy.streaming.v1.IExportedFile>} files
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @instance
                     */
                    ExportRawICANotificationRequest.prototype.files = $util.emptyArray;
    
                    /**
                     * Creates a new ExportRawICANotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IExportRawICANotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ExportRawICANotificationRequest} ExportRawICANotificationRequest instance
                     */
                    ExportRawICANotificationRequest.create = function create(properties) {
                        return new ExportRawICANotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ExportRawICANotificationRequest message. Does not implicitly {@link palexy.streaming.v1.ExportRawICANotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IExportRawICANotificationRequest} message ExportRawICANotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportRawICANotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.dataDateId != null && Object.hasOwnProperty.call(message, "dataDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dataDateId);
                        if (message.files != null && message.files.length)
                            for (var i = 0; i < message.files.length; ++i)
                                $root.palexy.streaming.v1.ExportedFile.encode(message.files[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportRawICANotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ExportRawICANotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IExportRawICANotificationRequest} message ExportRawICANotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportRawICANotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportRawICANotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ExportRawICANotificationRequest} ExportRawICANotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportRawICANotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ExportRawICANotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.dataDateId = reader.int32();
                                break;
                            case 3:
                                if (!(message.files && message.files.length))
                                    message.files = [];
                                message.files.push($root.palexy.streaming.v1.ExportedFile.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportRawICANotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ExportRawICANotificationRequest} ExportRawICANotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportRawICANotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportRawICANotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportRawICANotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.dataDateId != null && message.hasOwnProperty("dataDateId"))
                            if (!$util.isInteger(message.dataDateId))
                                return "dataDateId: integer expected";
                        if (message.files != null && message.hasOwnProperty("files")) {
                            if (!Array.isArray(message.files))
                                return "files: array expected";
                            for (var i = 0; i < message.files.length; ++i) {
                                var error = $root.palexy.streaming.v1.ExportedFile.verify(message.files[i]);
                                if (error)
                                    return "files." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ExportRawICANotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ExportRawICANotificationRequest} ExportRawICANotificationRequest
                     */
                    ExportRawICANotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ExportRawICANotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ExportRawICANotificationRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.dataDateId != null)
                            message.dataDateId = object.dataDateId | 0;
                        if (object.files) {
                            if (!Array.isArray(object.files))
                                throw TypeError(".palexy.streaming.v1.ExportRawICANotificationRequest.files: array expected");
                            message.files = [];
                            for (var i = 0; i < object.files.length; ++i) {
                                if (typeof object.files[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ExportRawICANotificationRequest.files: object expected");
                                message.files[i] = $root.palexy.streaming.v1.ExportedFile.fromObject(object.files[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExportRawICANotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ExportRawICANotificationRequest} message ExportRawICANotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportRawICANotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.files = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.dataDateId = 0;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.dataDateId != null && message.hasOwnProperty("dataDateId"))
                            object.dataDateId = message.dataDateId;
                        if (message.files && message.files.length) {
                            object.files = [];
                            for (var j = 0; j < message.files.length; ++j)
                                object.files[j] = $root.palexy.streaming.v1.ExportedFile.toObject(message.files[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ExportRawICANotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ExportRawICANotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportRawICANotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExportRawICANotificationRequest;
                })();
    
                v1.ExportRawICANotificationResponse = (function() {
    
                    /**
                     * Properties of an ExportRawICANotificationResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IExportRawICANotificationResponse
                     */
    
                    /**
                     * Constructs a new ExportRawICANotificationResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ExportRawICANotificationResponse.
                     * @implements IExportRawICANotificationResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IExportRawICANotificationResponse=} [properties] Properties to set
                     */
                    function ExportRawICANotificationResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new ExportRawICANotificationResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ExportRawICANotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IExportRawICANotificationResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ExportRawICANotificationResponse} ExportRawICANotificationResponse instance
                     */
                    ExportRawICANotificationResponse.create = function create(properties) {
                        return new ExportRawICANotificationResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ExportRawICANotificationResponse message. Does not implicitly {@link palexy.streaming.v1.ExportRawICANotificationResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ExportRawICANotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IExportRawICANotificationResponse} message ExportRawICANotificationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportRawICANotificationResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportRawICANotificationResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ExportRawICANotificationResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ExportRawICANotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IExportRawICANotificationResponse} message ExportRawICANotificationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportRawICANotificationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportRawICANotificationResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ExportRawICANotificationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ExportRawICANotificationResponse} ExportRawICANotificationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportRawICANotificationResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ExportRawICANotificationResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportRawICANotificationResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ExportRawICANotificationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ExportRawICANotificationResponse} ExportRawICANotificationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportRawICANotificationResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportRawICANotificationResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ExportRawICANotificationResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportRawICANotificationResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExportRawICANotificationResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ExportRawICANotificationResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ExportRawICANotificationResponse} ExportRawICANotificationResponse
                     */
                    ExportRawICANotificationResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ExportRawICANotificationResponse)
                            return object;
                        return new $root.palexy.streaming.v1.ExportRawICANotificationResponse();
                    };
    
                    /**
                     * Creates a plain object from an ExportRawICANotificationResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ExportRawICANotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.ExportRawICANotificationResponse} message ExportRawICANotificationResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportRawICANotificationResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this ExportRawICANotificationResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ExportRawICANotificationResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportRawICANotificationResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExportRawICANotificationResponse;
                })();
    
                v1.SendVerificationEmailRequest = (function() {
    
                    /**
                     * Properties of a SendVerificationEmailRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ISendVerificationEmailRequest
                     * @property {palexy.streaming.v1.SendVerificationEmailRequest.EmailVerificationType|null} [verificationType] SendVerificationEmailRequest verificationType
                     * @property {string|null} [username] SendVerificationEmailRequest username
                     * @property {string|null} [email] SendVerificationEmailRequest email
                     * @property {string|null} [fullName] SendVerificationEmailRequest fullName
                     * @property {string|null} [verificationLink] SendVerificationEmailRequest verificationLink
                     * @property {google.protobuf.ITimestamp|null} [expiryDateTime] SendVerificationEmailRequest expiryDateTime
                     */
    
                    /**
                     * Constructs a new SendVerificationEmailRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SendVerificationEmailRequest.
                     * @implements ISendVerificationEmailRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ISendVerificationEmailRequest=} [properties] Properties to set
                     */
                    function SendVerificationEmailRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SendVerificationEmailRequest verificationType.
                     * @member {palexy.streaming.v1.SendVerificationEmailRequest.EmailVerificationType} verificationType
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @instance
                     */
                    SendVerificationEmailRequest.prototype.verificationType = 0;
    
                    /**
                     * SendVerificationEmailRequest username.
                     * @member {string} username
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @instance
                     */
                    SendVerificationEmailRequest.prototype.username = "";
    
                    /**
                     * SendVerificationEmailRequest email.
                     * @member {string} email
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @instance
                     */
                    SendVerificationEmailRequest.prototype.email = "";
    
                    /**
                     * SendVerificationEmailRequest fullName.
                     * @member {string} fullName
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @instance
                     */
                    SendVerificationEmailRequest.prototype.fullName = "";
    
                    /**
                     * SendVerificationEmailRequest verificationLink.
                     * @member {string} verificationLink
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @instance
                     */
                    SendVerificationEmailRequest.prototype.verificationLink = "";
    
                    /**
                     * SendVerificationEmailRequest expiryDateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} expiryDateTime
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @instance
                     */
                    SendVerificationEmailRequest.prototype.expiryDateTime = null;
    
                    /**
                     * Creates a new SendVerificationEmailRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendVerificationEmailRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SendVerificationEmailRequest} SendVerificationEmailRequest instance
                     */
                    SendVerificationEmailRequest.create = function create(properties) {
                        return new SendVerificationEmailRequest(properties);
                    };
    
                    /**
                     * Encodes the specified SendVerificationEmailRequest message. Does not implicitly {@link palexy.streaming.v1.SendVerificationEmailRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendVerificationEmailRequest} message SendVerificationEmailRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendVerificationEmailRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.verificationType != null && Object.hasOwnProperty.call(message, "verificationType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.verificationType);
                        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.username);
                        if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
                        if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.fullName);
                        if (message.verificationLink != null && Object.hasOwnProperty.call(message, "verificationLink"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.verificationLink);
                        if (message.expiryDateTime != null && Object.hasOwnProperty.call(message, "expiryDateTime"))
                            $root.google.protobuf.Timestamp.encode(message.expiryDateTime, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SendVerificationEmailRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.SendVerificationEmailRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendVerificationEmailRequest} message SendVerificationEmailRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendVerificationEmailRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SendVerificationEmailRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SendVerificationEmailRequest} SendVerificationEmailRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendVerificationEmailRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SendVerificationEmailRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.verificationType = reader.int32();
                                break;
                            case 2:
                                message.username = reader.string();
                                break;
                            case 3:
                                message.email = reader.string();
                                break;
                            case 4:
                                message.fullName = reader.string();
                                break;
                            case 5:
                                message.verificationLink = reader.string();
                                break;
                            case 6:
                                message.expiryDateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SendVerificationEmailRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SendVerificationEmailRequest} SendVerificationEmailRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendVerificationEmailRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SendVerificationEmailRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SendVerificationEmailRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.verificationType != null && message.hasOwnProperty("verificationType"))
                            switch (message.verificationType) {
                            default:
                                return "verificationType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.username != null && message.hasOwnProperty("username"))
                            if (!$util.isString(message.username))
                                return "username: string expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.fullName != null && message.hasOwnProperty("fullName"))
                            if (!$util.isString(message.fullName))
                                return "fullName: string expected";
                        if (message.verificationLink != null && message.hasOwnProperty("verificationLink"))
                            if (!$util.isString(message.verificationLink))
                                return "verificationLink: string expected";
                        if (message.expiryDateTime != null && message.hasOwnProperty("expiryDateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.expiryDateTime);
                            if (error)
                                return "expiryDateTime." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a SendVerificationEmailRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SendVerificationEmailRequest} SendVerificationEmailRequest
                     */
                    SendVerificationEmailRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SendVerificationEmailRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.SendVerificationEmailRequest();
                        switch (object.verificationType) {
                        case "UNKNOWN":
                        case 0:
                            message.verificationType = 0;
                            break;
                        case "ACCOUNT_CONFIRMATION":
                        case 1:
                            message.verificationType = 1;
                            break;
                        case "PASSWORD_RESET":
                        case 2:
                            message.verificationType = 2;
                            break;
                        case "EMAIL_VERIFICATION":
                        case 3:
                            message.verificationType = 3;
                            break;
                        }
                        if (object.username != null)
                            message.username = String(object.username);
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.fullName != null)
                            message.fullName = String(object.fullName);
                        if (object.verificationLink != null)
                            message.verificationLink = String(object.verificationLink);
                        if (object.expiryDateTime != null) {
                            if (typeof object.expiryDateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.SendVerificationEmailRequest.expiryDateTime: object expected");
                            message.expiryDateTime = $root.google.protobuf.Timestamp.fromObject(object.expiryDateTime);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SendVerificationEmailRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @static
                     * @param {palexy.streaming.v1.SendVerificationEmailRequest} message SendVerificationEmailRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SendVerificationEmailRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.verificationType = options.enums === String ? "UNKNOWN" : 0;
                            object.username = "";
                            object.email = "";
                            object.fullName = "";
                            object.verificationLink = "";
                            object.expiryDateTime = null;
                        }
                        if (message.verificationType != null && message.hasOwnProperty("verificationType"))
                            object.verificationType = options.enums === String ? $root.palexy.streaming.v1.SendVerificationEmailRequest.EmailVerificationType[message.verificationType] : message.verificationType;
                        if (message.username != null && message.hasOwnProperty("username"))
                            object.username = message.username;
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.fullName != null && message.hasOwnProperty("fullName"))
                            object.fullName = message.fullName;
                        if (message.verificationLink != null && message.hasOwnProperty("verificationLink"))
                            object.verificationLink = message.verificationLink;
                        if (message.expiryDateTime != null && message.hasOwnProperty("expiryDateTime"))
                            object.expiryDateTime = $root.google.protobuf.Timestamp.toObject(message.expiryDateTime, options);
                        return object;
                    };
    
                    /**
                     * Converts this SendVerificationEmailRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SendVerificationEmailRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SendVerificationEmailRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * EmailVerificationType enum.
                     * @name palexy.streaming.v1.SendVerificationEmailRequest.EmailVerificationType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} ACCOUNT_CONFIRMATION=1 ACCOUNT_CONFIRMATION value
                     * @property {number} PASSWORD_RESET=2 PASSWORD_RESET value
                     * @property {number} EMAIL_VERIFICATION=3 EMAIL_VERIFICATION value
                     */
                    SendVerificationEmailRequest.EmailVerificationType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "ACCOUNT_CONFIRMATION"] = 1;
                        values[valuesById[2] = "PASSWORD_RESET"] = 2;
                        values[valuesById[3] = "EMAIL_VERIFICATION"] = 3;
                        return values;
                    })();
    
                    return SendVerificationEmailRequest;
                })();
    
                v1.SendScheduledReportNotificationRequest = (function() {
    
                    /**
                     * Properties of a SendScheduledReportNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ISendScheduledReportNotificationRequest
                     * @property {palexy.streaming.v1.ReportSchedule.ScheduleType|null} [reportType] SendScheduledReportNotificationRequest reportType
                     * @property {number|null} [companyId] SendScheduledReportNotificationRequest companyId
                     * @property {string|null} [email] SendScheduledReportNotificationRequest email
                     * @property {palexy.streaming.v1.IEmailAttachment|null} [attachment] SendScheduledReportNotificationRequest attachment
                     * @property {palexy.streaming.v1.IDateRange|null} [dateRange] SendScheduledReportNotificationRequest dateRange
                     * @property {number|null} [reportScheduleId] SendScheduledReportNotificationRequest reportScheduleId
                     */
    
                    /**
                     * Constructs a new SendScheduledReportNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SendScheduledReportNotificationRequest.
                     * @implements ISendScheduledReportNotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ISendScheduledReportNotificationRequest=} [properties] Properties to set
                     */
                    function SendScheduledReportNotificationRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SendScheduledReportNotificationRequest reportType.
                     * @member {palexy.streaming.v1.ReportSchedule.ScheduleType} reportType
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @instance
                     */
                    SendScheduledReportNotificationRequest.prototype.reportType = 0;
    
                    /**
                     * SendScheduledReportNotificationRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @instance
                     */
                    SendScheduledReportNotificationRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * SendScheduledReportNotificationRequest email.
                     * @member {string} email
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @instance
                     */
                    SendScheduledReportNotificationRequest.prototype.email = "";
    
                    /**
                     * SendScheduledReportNotificationRequest attachment.
                     * @member {palexy.streaming.v1.IEmailAttachment|null|undefined} attachment
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @instance
                     */
                    SendScheduledReportNotificationRequest.prototype.attachment = null;
    
                    /**
                     * SendScheduledReportNotificationRequest dateRange.
                     * @member {palexy.streaming.v1.IDateRange|null|undefined} dateRange
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @instance
                     */
                    SendScheduledReportNotificationRequest.prototype.dateRange = null;
    
                    /**
                     * SendScheduledReportNotificationRequest reportScheduleId.
                     * @member {number} reportScheduleId
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @instance
                     */
                    SendScheduledReportNotificationRequest.prototype.reportScheduleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new SendScheduledReportNotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendScheduledReportNotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SendScheduledReportNotificationRequest} SendScheduledReportNotificationRequest instance
                     */
                    SendScheduledReportNotificationRequest.create = function create(properties) {
                        return new SendScheduledReportNotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified SendScheduledReportNotificationRequest message. Does not implicitly {@link palexy.streaming.v1.SendScheduledReportNotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendScheduledReportNotificationRequest} message SendScheduledReportNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendScheduledReportNotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reportType != null && Object.hasOwnProperty.call(message, "reportType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.reportType);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
                        if (message.attachment != null && Object.hasOwnProperty.call(message, "attachment"))
                            $root.palexy.streaming.v1.EmailAttachment.encode(message.attachment, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.dateRange != null && Object.hasOwnProperty.call(message, "dateRange"))
                            $root.palexy.streaming.v1.DateRange.encode(message.dateRange, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.reportScheduleId != null && Object.hasOwnProperty.call(message, "reportScheduleId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.reportScheduleId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SendScheduledReportNotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.SendScheduledReportNotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendScheduledReportNotificationRequest} message SendScheduledReportNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendScheduledReportNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SendScheduledReportNotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SendScheduledReportNotificationRequest} SendScheduledReportNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendScheduledReportNotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SendScheduledReportNotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.reportType = reader.int32();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.email = reader.string();
                                break;
                            case 4:
                                message.attachment = $root.palexy.streaming.v1.EmailAttachment.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.dateRange = $root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.reportScheduleId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SendScheduledReportNotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SendScheduledReportNotificationRequest} SendScheduledReportNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendScheduledReportNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SendScheduledReportNotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SendScheduledReportNotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reportType != null && message.hasOwnProperty("reportType"))
                            switch (message.reportType) {
                            default:
                                return "reportType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.attachment != null && message.hasOwnProperty("attachment")) {
                            var error = $root.palexy.streaming.v1.EmailAttachment.verify(message.attachment);
                            if (error)
                                return "attachment." + error;
                        }
                        if (message.dateRange != null && message.hasOwnProperty("dateRange")) {
                            var error = $root.palexy.streaming.v1.DateRange.verify(message.dateRange);
                            if (error)
                                return "dateRange." + error;
                        }
                        if (message.reportScheduleId != null && message.hasOwnProperty("reportScheduleId"))
                            if (!$util.isInteger(message.reportScheduleId) && !(message.reportScheduleId && $util.isInteger(message.reportScheduleId.low) && $util.isInteger(message.reportScheduleId.high)))
                                return "reportScheduleId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a SendScheduledReportNotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SendScheduledReportNotificationRequest} SendScheduledReportNotificationRequest
                     */
                    SendScheduledReportNotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SendScheduledReportNotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.SendScheduledReportNotificationRequest();
                        switch (object.reportType) {
                        case "UNKNOWN":
                        case 0:
                            message.reportType = 0;
                            break;
                        case "WEEKLY":
                        case 1:
                            message.reportType = 1;
                            break;
                        case "MONTHLY":
                        case 2:
                            message.reportType = 2;
                            break;
                        }
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.attachment != null) {
                            if (typeof object.attachment !== "object")
                                throw TypeError(".palexy.streaming.v1.SendScheduledReportNotificationRequest.attachment: object expected");
                            message.attachment = $root.palexy.streaming.v1.EmailAttachment.fromObject(object.attachment);
                        }
                        if (object.dateRange != null) {
                            if (typeof object.dateRange !== "object")
                                throw TypeError(".palexy.streaming.v1.SendScheduledReportNotificationRequest.dateRange: object expected");
                            message.dateRange = $root.palexy.streaming.v1.DateRange.fromObject(object.dateRange);
                        }
                        if (object.reportScheduleId != null)
                            if ($util.Long)
                                (message.reportScheduleId = $util.Long.fromValue(object.reportScheduleId)).unsigned = false;
                            else if (typeof object.reportScheduleId === "string")
                                message.reportScheduleId = parseInt(object.reportScheduleId, 10);
                            else if (typeof object.reportScheduleId === "number")
                                message.reportScheduleId = object.reportScheduleId;
                            else if (typeof object.reportScheduleId === "object")
                                message.reportScheduleId = new $util.LongBits(object.reportScheduleId.low >>> 0, object.reportScheduleId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SendScheduledReportNotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.SendScheduledReportNotificationRequest} message SendScheduledReportNotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SendScheduledReportNotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.reportType = options.enums === String ? "UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.email = "";
                            object.attachment = null;
                            object.dateRange = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.reportScheduleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.reportScheduleId = options.longs === String ? "0" : 0;
                        }
                        if (message.reportType != null && message.hasOwnProperty("reportType"))
                            object.reportType = options.enums === String ? $root.palexy.streaming.v1.ReportSchedule.ScheduleType[message.reportType] : message.reportType;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.attachment != null && message.hasOwnProperty("attachment"))
                            object.attachment = $root.palexy.streaming.v1.EmailAttachment.toObject(message.attachment, options);
                        if (message.dateRange != null && message.hasOwnProperty("dateRange"))
                            object.dateRange = $root.palexy.streaming.v1.DateRange.toObject(message.dateRange, options);
                        if (message.reportScheduleId != null && message.hasOwnProperty("reportScheduleId"))
                            if (typeof message.reportScheduleId === "number")
                                object.reportScheduleId = options.longs === String ? String(message.reportScheduleId) : message.reportScheduleId;
                            else
                                object.reportScheduleId = options.longs === String ? $util.Long.prototype.toString.call(message.reportScheduleId) : options.longs === Number ? new $util.LongBits(message.reportScheduleId.low >>> 0, message.reportScheduleId.high >>> 0).toNumber() : message.reportScheduleId;
                        return object;
                    };
    
                    /**
                     * Converts this SendScheduledReportNotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SendScheduledReportNotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SendScheduledReportNotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SendScheduledReportNotificationRequest;
                })();
    
                v1.EmailAttachment = (function() {
    
                    /**
                     * Properties of an EmailAttachment.
                     * @memberof palexy.streaming.v1
                     * @interface IEmailAttachment
                     * @property {string|null} [fileName] EmailAttachment fileName
                     * @property {Uint8Array|null} [data] EmailAttachment data
                     */
    
                    /**
                     * Constructs a new EmailAttachment.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an EmailAttachment.
                     * @implements IEmailAttachment
                     * @constructor
                     * @param {palexy.streaming.v1.IEmailAttachment=} [properties] Properties to set
                     */
                    function EmailAttachment(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * EmailAttachment fileName.
                     * @member {string} fileName
                     * @memberof palexy.streaming.v1.EmailAttachment
                     * @instance
                     */
                    EmailAttachment.prototype.fileName = "";
    
                    /**
                     * EmailAttachment data.
                     * @member {Uint8Array} data
                     * @memberof palexy.streaming.v1.EmailAttachment
                     * @instance
                     */
                    EmailAttachment.prototype.data = $util.newBuffer([]);
    
                    /**
                     * Creates a new EmailAttachment instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.EmailAttachment
                     * @static
                     * @param {palexy.streaming.v1.IEmailAttachment=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.EmailAttachment} EmailAttachment instance
                     */
                    EmailAttachment.create = function create(properties) {
                        return new EmailAttachment(properties);
                    };
    
                    /**
                     * Encodes the specified EmailAttachment message. Does not implicitly {@link palexy.streaming.v1.EmailAttachment.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.EmailAttachment
                     * @static
                     * @param {palexy.streaming.v1.IEmailAttachment} message EmailAttachment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EmailAttachment.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileName);
                        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.data);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified EmailAttachment message, length delimited. Does not implicitly {@link palexy.streaming.v1.EmailAttachment.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.EmailAttachment
                     * @static
                     * @param {palexy.streaming.v1.IEmailAttachment} message EmailAttachment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EmailAttachment.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an EmailAttachment message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.EmailAttachment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.EmailAttachment} EmailAttachment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EmailAttachment.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.EmailAttachment();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.fileName = reader.string();
                                break;
                            case 2:
                                message.data = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an EmailAttachment message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.EmailAttachment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.EmailAttachment} EmailAttachment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EmailAttachment.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an EmailAttachment message.
                     * @function verify
                     * @memberof palexy.streaming.v1.EmailAttachment
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EmailAttachment.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            if (!$util.isString(message.fileName))
                                return "fileName: string expected";
                        if (message.data != null && message.hasOwnProperty("data"))
                            if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                                return "data: buffer expected";
                        return null;
                    };
    
                    /**
                     * Creates an EmailAttachment message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.EmailAttachment
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.EmailAttachment} EmailAttachment
                     */
                    EmailAttachment.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.EmailAttachment)
                            return object;
                        var message = new $root.palexy.streaming.v1.EmailAttachment();
                        if (object.fileName != null)
                            message.fileName = String(object.fileName);
                        if (object.data != null)
                            if (typeof object.data === "string")
                                $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                            else if (object.data.length)
                                message.data = object.data;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an EmailAttachment message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.EmailAttachment
                     * @static
                     * @param {palexy.streaming.v1.EmailAttachment} message EmailAttachment
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EmailAttachment.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.fileName = "";
                            if (options.bytes === String)
                                object.data = "";
                            else {
                                object.data = [];
                                if (options.bytes !== Array)
                                    object.data = $util.newBuffer(object.data);
                            }
                        }
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            object.fileName = message.fileName;
                        if (message.data != null && message.hasOwnProperty("data"))
                            object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                        return object;
                    };
    
                    /**
                     * Converts this EmailAttachment to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.EmailAttachment
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EmailAttachment.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return EmailAttachment;
                })();
    
                v1.SendOtpRequest = (function() {
    
                    /**
                     * Properties of a SendOtpRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ISendOtpRequest
                     * @property {palexy.streaming.v1.SendOtpRequest.OtpType|null} [type] SendOtpRequest type
                     * @property {string|null} [otp] SendOtpRequest otp
                     * @property {google.protobuf.ITimestamp|null} [expiryDateTime] SendOtpRequest expiryDateTime
                     * @property {string|null} [email] SendOtpRequest email
                     */
    
                    /**
                     * Constructs a new SendOtpRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SendOtpRequest.
                     * @implements ISendOtpRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ISendOtpRequest=} [properties] Properties to set
                     */
                    function SendOtpRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SendOtpRequest type.
                     * @member {palexy.streaming.v1.SendOtpRequest.OtpType} type
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @instance
                     */
                    SendOtpRequest.prototype.type = 0;
    
                    /**
                     * SendOtpRequest otp.
                     * @member {string} otp
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @instance
                     */
                    SendOtpRequest.prototype.otp = "";
    
                    /**
                     * SendOtpRequest expiryDateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} expiryDateTime
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @instance
                     */
                    SendOtpRequest.prototype.expiryDateTime = null;
    
                    /**
                     * SendOtpRequest email.
                     * @member {string|null|undefined} email
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @instance
                     */
                    SendOtpRequest.prototype.email = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * SendOtpRequest receiver.
                     * @member {"email"|undefined} receiver
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @instance
                     */
                    Object.defineProperty(SendOtpRequest.prototype, "receiver", {
                        get: $util.oneOfGetter($oneOfFields = ["email"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new SendOtpRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendOtpRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SendOtpRequest} SendOtpRequest instance
                     */
                    SendOtpRequest.create = function create(properties) {
                        return new SendOtpRequest(properties);
                    };
    
                    /**
                     * Encodes the specified SendOtpRequest message. Does not implicitly {@link palexy.streaming.v1.SendOtpRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendOtpRequest} message SendOtpRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendOtpRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        if (message.otp != null && Object.hasOwnProperty.call(message, "otp"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.otp);
                        if (message.expiryDateTime != null && Object.hasOwnProperty.call(message, "expiryDateTime"))
                            $root.google.protobuf.Timestamp.encode(message.expiryDateTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SendOtpRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.SendOtpRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendOtpRequest} message SendOtpRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendOtpRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SendOtpRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SendOtpRequest} SendOtpRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendOtpRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SendOtpRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.type = reader.int32();
                                break;
                            case 2:
                                message.otp = reader.string();
                                break;
                            case 3:
                                message.expiryDateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.email = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SendOtpRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SendOtpRequest} SendOtpRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendOtpRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SendOtpRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SendOtpRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.otp != null && message.hasOwnProperty("otp"))
                            if (!$util.isString(message.otp))
                                return "otp: string expected";
                        if (message.expiryDateTime != null && message.hasOwnProperty("expiryDateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.expiryDateTime);
                            if (error)
                                return "expiryDateTime." + error;
                        }
                        if (message.email != null && message.hasOwnProperty("email")) {
                            properties.receiver = 1;
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a SendOtpRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SendOtpRequest} SendOtpRequest
                     */
                    SendOtpRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SendOtpRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.SendOtpRequest();
                        switch (object.type) {
                        case "OTP_TYPE_UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "MFA_SETUP":
                        case 1:
                            message.type = 1;
                            break;
                        case "MFA_LOGIN":
                        case 2:
                            message.type = 2;
                            break;
                        }
                        if (object.otp != null)
                            message.otp = String(object.otp);
                        if (object.expiryDateTime != null) {
                            if (typeof object.expiryDateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.SendOtpRequest.expiryDateTime: object expected");
                            message.expiryDateTime = $root.google.protobuf.Timestamp.fromObject(object.expiryDateTime);
                        }
                        if (object.email != null)
                            message.email = String(object.email);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SendOtpRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @static
                     * @param {palexy.streaming.v1.SendOtpRequest} message SendOtpRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SendOtpRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.type = options.enums === String ? "OTP_TYPE_UNKNOWN" : 0;
                            object.otp = "";
                            object.expiryDateTime = null;
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.SendOtpRequest.OtpType[message.type] : message.type;
                        if (message.otp != null && message.hasOwnProperty("otp"))
                            object.otp = message.otp;
                        if (message.expiryDateTime != null && message.hasOwnProperty("expiryDateTime"))
                            object.expiryDateTime = $root.google.protobuf.Timestamp.toObject(message.expiryDateTime, options);
                        if (message.email != null && message.hasOwnProperty("email")) {
                            object.email = message.email;
                            if (options.oneofs)
                                object.receiver = "email";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this SendOtpRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SendOtpRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SendOtpRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * OtpType enum.
                     * @name palexy.streaming.v1.SendOtpRequest.OtpType
                     * @enum {number}
                     * @property {number} OTP_TYPE_UNKNOWN=0 OTP_TYPE_UNKNOWN value
                     * @property {number} MFA_SETUP=1 MFA_SETUP value
                     * @property {number} MFA_LOGIN=2 MFA_LOGIN value
                     */
                    SendOtpRequest.OtpType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "OTP_TYPE_UNKNOWN"] = 0;
                        values[valuesById[1] = "MFA_SETUP"] = 1;
                        values[valuesById[2] = "MFA_LOGIN"] = 2;
                        return values;
                    })();
    
                    return SendOtpRequest;
                })();
    
                v1.RawICASummaryRecord = (function() {
    
                    /**
                     * Properties of a RawICASummaryRecord.
                     * @memberof palexy.streaming.v1
                     * @interface IRawICASummaryRecord
                     * @property {string|null} [personId] RawICASummaryRecord personId
                     * @property {boolean|null} [isStaff] RawICASummaryRecord isStaff
                     * @property {string|null} [gender] RawICASummaryRecord gender
                     * @property {number|null} [ageRange] RawICASummaryRecord ageRange
                     * @property {number|null} [storeId] RawICASummaryRecord storeId
                     * @property {Array.<string>|null} [cameraIds] RawICASummaryRecord cameraIds
                     * @property {Array.<string>|null} [actionsInStore] RawICASummaryRecord actionsInStore
                     * @property {Array.<number>|null} [hours] RawICASummaryRecord hours
                     * @property {number|null} [storeVisitDuration] RawICASummaryRecord storeVisitDuration
                     * @property {Array.<palexy.streaming.v1.ITotalActionItem>|null} [totalActionsInStore] RawICASummaryRecord totalActionsInStore
                     * @property {Array.<palexy.streaming.v1.IMetadataDetail>|null} [metadataDetails] RawICASummaryRecord metadataDetails
                     */
    
                    /**
                     * Constructs a new RawICASummaryRecord.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RawICASummaryRecord.
                     * @implements IRawICASummaryRecord
                     * @constructor
                     * @param {palexy.streaming.v1.IRawICASummaryRecord=} [properties] Properties to set
                     */
                    function RawICASummaryRecord(properties) {
                        this.cameraIds = [];
                        this.actionsInStore = [];
                        this.hours = [];
                        this.totalActionsInStore = [];
                        this.metadataDetails = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RawICASummaryRecord personId.
                     * @member {string} personId
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     */
                    RawICASummaryRecord.prototype.personId = "";
    
                    /**
                     * RawICASummaryRecord isStaff.
                     * @member {boolean} isStaff
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     */
                    RawICASummaryRecord.prototype.isStaff = false;
    
                    /**
                     * RawICASummaryRecord gender.
                     * @member {string} gender
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     */
                    RawICASummaryRecord.prototype.gender = "";
    
                    /**
                     * RawICASummaryRecord ageRange.
                     * @member {number} ageRange
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     */
                    RawICASummaryRecord.prototype.ageRange = 0;
    
                    /**
                     * RawICASummaryRecord storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     */
                    RawICASummaryRecord.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICASummaryRecord cameraIds.
                     * @member {Array.<string>} cameraIds
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     */
                    RawICASummaryRecord.prototype.cameraIds = $util.emptyArray;
    
                    /**
                     * RawICASummaryRecord actionsInStore.
                     * @member {Array.<string>} actionsInStore
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     */
                    RawICASummaryRecord.prototype.actionsInStore = $util.emptyArray;
    
                    /**
                     * RawICASummaryRecord hours.
                     * @member {Array.<number>} hours
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     */
                    RawICASummaryRecord.prototype.hours = $util.emptyArray;
    
                    /**
                     * RawICASummaryRecord storeVisitDuration.
                     * @member {number} storeVisitDuration
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     */
                    RawICASummaryRecord.prototype.storeVisitDuration = 0;
    
                    /**
                     * RawICASummaryRecord totalActionsInStore.
                     * @member {Array.<palexy.streaming.v1.ITotalActionItem>} totalActionsInStore
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     */
                    RawICASummaryRecord.prototype.totalActionsInStore = $util.emptyArray;
    
                    /**
                     * RawICASummaryRecord metadataDetails.
                     * @member {Array.<palexy.streaming.v1.IMetadataDetail>} metadataDetails
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     */
                    RawICASummaryRecord.prototype.metadataDetails = $util.emptyArray;
    
                    /**
                     * Creates a new RawICASummaryRecord instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @static
                     * @param {palexy.streaming.v1.IRawICASummaryRecord=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RawICASummaryRecord} RawICASummaryRecord instance
                     */
                    RawICASummaryRecord.create = function create(properties) {
                        return new RawICASummaryRecord(properties);
                    };
    
                    /**
                     * Encodes the specified RawICASummaryRecord message. Does not implicitly {@link palexy.streaming.v1.RawICASummaryRecord.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @static
                     * @param {palexy.streaming.v1.IRawICASummaryRecord} message RawICASummaryRecord message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RawICASummaryRecord.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.personId != null && Object.hasOwnProperty.call(message, "personId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.personId);
                        if (message.isStaff != null && Object.hasOwnProperty.call(message, "isStaff"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isStaff);
                        if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.gender);
                        if (message.ageRange != null && Object.hasOwnProperty.call(message, "ageRange"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.ageRange);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.storeId);
                        if (message.cameraIds != null && message.cameraIds.length)
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.cameraIds[i]);
                        if (message.actionsInStore != null && message.actionsInStore.length)
                            for (var i = 0; i < message.actionsInStore.length; ++i)
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.actionsInStore[i]);
                        if (message.hours != null && message.hours.length) {
                            writer.uint32(/* id 8, wireType 2 =*/66).fork();
                            for (var i = 0; i < message.hours.length; ++i)
                                writer.int32(message.hours[i]);
                            writer.ldelim();
                        }
                        if (message.storeVisitDuration != null && Object.hasOwnProperty.call(message, "storeVisitDuration"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.storeVisitDuration);
                        if (message.totalActionsInStore != null && message.totalActionsInStore.length)
                            for (var i = 0; i < message.totalActionsInStore.length; ++i)
                                $root.palexy.streaming.v1.TotalActionItem.encode(message.totalActionsInStore[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.metadataDetails != null && message.metadataDetails.length)
                            for (var i = 0; i < message.metadataDetails.length; ++i)
                                $root.palexy.streaming.v1.MetadataDetail.encode(message.metadataDetails[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RawICASummaryRecord message, length delimited. Does not implicitly {@link palexy.streaming.v1.RawICASummaryRecord.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @static
                     * @param {palexy.streaming.v1.IRawICASummaryRecord} message RawICASummaryRecord message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RawICASummaryRecord.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RawICASummaryRecord message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RawICASummaryRecord} RawICASummaryRecord
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RawICASummaryRecord.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RawICASummaryRecord();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.personId = reader.string();
                                break;
                            case 2:
                                message.isStaff = reader.bool();
                                break;
                            case 3:
                                message.gender = reader.string();
                                break;
                            case 4:
                                message.ageRange = reader.int32();
                                break;
                            case 5:
                                message.storeId = reader.int64();
                                break;
                            case 6:
                                if (!(message.cameraIds && message.cameraIds.length))
                                    message.cameraIds = [];
                                message.cameraIds.push(reader.string());
                                break;
                            case 7:
                                if (!(message.actionsInStore && message.actionsInStore.length))
                                    message.actionsInStore = [];
                                message.actionsInStore.push(reader.string());
                                break;
                            case 8:
                                if (!(message.hours && message.hours.length))
                                    message.hours = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.hours.push(reader.int32());
                                } else
                                    message.hours.push(reader.int32());
                                break;
                            case 9:
                                message.storeVisitDuration = reader.int32();
                                break;
                            case 10:
                                if (!(message.totalActionsInStore && message.totalActionsInStore.length))
                                    message.totalActionsInStore = [];
                                message.totalActionsInStore.push($root.palexy.streaming.v1.TotalActionItem.decode(reader, reader.uint32()));
                                break;
                            case 11:
                                if (!(message.metadataDetails && message.metadataDetails.length))
                                    message.metadataDetails = [];
                                message.metadataDetails.push($root.palexy.streaming.v1.MetadataDetail.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RawICASummaryRecord message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RawICASummaryRecord} RawICASummaryRecord
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RawICASummaryRecord.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RawICASummaryRecord message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RawICASummaryRecord.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.personId != null && message.hasOwnProperty("personId"))
                            if (!$util.isString(message.personId))
                                return "personId: string expected";
                        if (message.isStaff != null && message.hasOwnProperty("isStaff"))
                            if (typeof message.isStaff !== "boolean")
                                return "isStaff: boolean expected";
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            if (!$util.isString(message.gender))
                                return "gender: string expected";
                        if (message.ageRange != null && message.hasOwnProperty("ageRange"))
                            if (!$util.isInteger(message.ageRange))
                                return "ageRange: integer expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.cameraIds != null && message.hasOwnProperty("cameraIds")) {
                            if (!Array.isArray(message.cameraIds))
                                return "cameraIds: array expected";
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                if (!$util.isString(message.cameraIds[i]))
                                    return "cameraIds: string[] expected";
                        }
                        if (message.actionsInStore != null && message.hasOwnProperty("actionsInStore")) {
                            if (!Array.isArray(message.actionsInStore))
                                return "actionsInStore: array expected";
                            for (var i = 0; i < message.actionsInStore.length; ++i)
                                if (!$util.isString(message.actionsInStore[i]))
                                    return "actionsInStore: string[] expected";
                        }
                        if (message.hours != null && message.hasOwnProperty("hours")) {
                            if (!Array.isArray(message.hours))
                                return "hours: array expected";
                            for (var i = 0; i < message.hours.length; ++i)
                                if (!$util.isInteger(message.hours[i]))
                                    return "hours: integer[] expected";
                        }
                        if (message.storeVisitDuration != null && message.hasOwnProperty("storeVisitDuration"))
                            if (!$util.isInteger(message.storeVisitDuration))
                                return "storeVisitDuration: integer expected";
                        if (message.totalActionsInStore != null && message.hasOwnProperty("totalActionsInStore")) {
                            if (!Array.isArray(message.totalActionsInStore))
                                return "totalActionsInStore: array expected";
                            for (var i = 0; i < message.totalActionsInStore.length; ++i) {
                                var error = $root.palexy.streaming.v1.TotalActionItem.verify(message.totalActionsInStore[i]);
                                if (error)
                                    return "totalActionsInStore." + error;
                            }
                        }
                        if (message.metadataDetails != null && message.hasOwnProperty("metadataDetails")) {
                            if (!Array.isArray(message.metadataDetails))
                                return "metadataDetails: array expected";
                            for (var i = 0; i < message.metadataDetails.length; ++i) {
                                var error = $root.palexy.streaming.v1.MetadataDetail.verify(message.metadataDetails[i]);
                                if (error)
                                    return "metadataDetails." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a RawICASummaryRecord message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RawICASummaryRecord} RawICASummaryRecord
                     */
                    RawICASummaryRecord.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RawICASummaryRecord)
                            return object;
                        var message = new $root.palexy.streaming.v1.RawICASummaryRecord();
                        if (object.personId != null)
                            message.personId = String(object.personId);
                        if (object.isStaff != null)
                            message.isStaff = Boolean(object.isStaff);
                        if (object.gender != null)
                            message.gender = String(object.gender);
                        if (object.ageRange != null)
                            message.ageRange = object.ageRange | 0;
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.cameraIds) {
                            if (!Array.isArray(object.cameraIds))
                                throw TypeError(".palexy.streaming.v1.RawICASummaryRecord.cameraIds: array expected");
                            message.cameraIds = [];
                            for (var i = 0; i < object.cameraIds.length; ++i)
                                message.cameraIds[i] = String(object.cameraIds[i]);
                        }
                        if (object.actionsInStore) {
                            if (!Array.isArray(object.actionsInStore))
                                throw TypeError(".palexy.streaming.v1.RawICASummaryRecord.actionsInStore: array expected");
                            message.actionsInStore = [];
                            for (var i = 0; i < object.actionsInStore.length; ++i)
                                message.actionsInStore[i] = String(object.actionsInStore[i]);
                        }
                        if (object.hours) {
                            if (!Array.isArray(object.hours))
                                throw TypeError(".palexy.streaming.v1.RawICASummaryRecord.hours: array expected");
                            message.hours = [];
                            for (var i = 0; i < object.hours.length; ++i)
                                message.hours[i] = object.hours[i] | 0;
                        }
                        if (object.storeVisitDuration != null)
                            message.storeVisitDuration = object.storeVisitDuration | 0;
                        if (object.totalActionsInStore) {
                            if (!Array.isArray(object.totalActionsInStore))
                                throw TypeError(".palexy.streaming.v1.RawICASummaryRecord.totalActionsInStore: array expected");
                            message.totalActionsInStore = [];
                            for (var i = 0; i < object.totalActionsInStore.length; ++i) {
                                if (typeof object.totalActionsInStore[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.RawICASummaryRecord.totalActionsInStore: object expected");
                                message.totalActionsInStore[i] = $root.palexy.streaming.v1.TotalActionItem.fromObject(object.totalActionsInStore[i]);
                            }
                        }
                        if (object.metadataDetails) {
                            if (!Array.isArray(object.metadataDetails))
                                throw TypeError(".palexy.streaming.v1.RawICASummaryRecord.metadataDetails: array expected");
                            message.metadataDetails = [];
                            for (var i = 0; i < object.metadataDetails.length; ++i) {
                                if (typeof object.metadataDetails[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.RawICASummaryRecord.metadataDetails: object expected");
                                message.metadataDetails[i] = $root.palexy.streaming.v1.MetadataDetail.fromObject(object.metadataDetails[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RawICASummaryRecord message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @static
                     * @param {palexy.streaming.v1.RawICASummaryRecord} message RawICASummaryRecord
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RawICASummaryRecord.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.cameraIds = [];
                            object.actionsInStore = [];
                            object.hours = [];
                            object.totalActionsInStore = [];
                            object.metadataDetails = [];
                        }
                        if (options.defaults) {
                            object.personId = "";
                            object.isStaff = false;
                            object.gender = "";
                            object.ageRange = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.storeVisitDuration = 0;
                        }
                        if (message.personId != null && message.hasOwnProperty("personId"))
                            object.personId = message.personId;
                        if (message.isStaff != null && message.hasOwnProperty("isStaff"))
                            object.isStaff = message.isStaff;
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            object.gender = message.gender;
                        if (message.ageRange != null && message.hasOwnProperty("ageRange"))
                            object.ageRange = message.ageRange;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.cameraIds && message.cameraIds.length) {
                            object.cameraIds = [];
                            for (var j = 0; j < message.cameraIds.length; ++j)
                                object.cameraIds[j] = message.cameraIds[j];
                        }
                        if (message.actionsInStore && message.actionsInStore.length) {
                            object.actionsInStore = [];
                            for (var j = 0; j < message.actionsInStore.length; ++j)
                                object.actionsInStore[j] = message.actionsInStore[j];
                        }
                        if (message.hours && message.hours.length) {
                            object.hours = [];
                            for (var j = 0; j < message.hours.length; ++j)
                                object.hours[j] = message.hours[j];
                        }
                        if (message.storeVisitDuration != null && message.hasOwnProperty("storeVisitDuration"))
                            object.storeVisitDuration = message.storeVisitDuration;
                        if (message.totalActionsInStore && message.totalActionsInStore.length) {
                            object.totalActionsInStore = [];
                            for (var j = 0; j < message.totalActionsInStore.length; ++j)
                                object.totalActionsInStore[j] = $root.palexy.streaming.v1.TotalActionItem.toObject(message.totalActionsInStore[j], options);
                        }
                        if (message.metadataDetails && message.metadataDetails.length) {
                            object.metadataDetails = [];
                            for (var j = 0; j < message.metadataDetails.length; ++j)
                                object.metadataDetails[j] = $root.palexy.streaming.v1.MetadataDetail.toObject(message.metadataDetails[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this RawICASummaryRecord to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RawICASummaryRecord
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RawICASummaryRecord.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RawICASummaryRecord;
                })();
    
                v1.MetadataDetail = (function() {
    
                    /**
                     * Properties of a MetadataDetail.
                     * @memberof palexy.streaming.v1
                     * @interface IMetadataDetail
                     * @property {palexy.streaming.v1.ITotalActionItem|null} [totalActionItem] MetadataDetail totalActionItem
                     * @property {number|null} [metadataValueId] MetadataDetail metadataValueId
                     * @property {number|null} [metadataValue] MetadataDetail metadataValue
                     * @property {number|null} [totalDuration] MetadataDetail totalDuration
                     */
    
                    /**
                     * Constructs a new MetadataDetail.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MetadataDetail.
                     * @implements IMetadataDetail
                     * @constructor
                     * @param {palexy.streaming.v1.IMetadataDetail=} [properties] Properties to set
                     */
                    function MetadataDetail(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MetadataDetail totalActionItem.
                     * @member {palexy.streaming.v1.ITotalActionItem|null|undefined} totalActionItem
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @instance
                     */
                    MetadataDetail.prototype.totalActionItem = null;
    
                    /**
                     * MetadataDetail metadataValueId.
                     * @member {number} metadataValueId
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @instance
                     */
                    MetadataDetail.prototype.metadataValueId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MetadataDetail metadataValue.
                     * @member {number} metadataValue
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @instance
                     */
                    MetadataDetail.prototype.metadataValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MetadataDetail totalDuration.
                     * @member {number} totalDuration
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @instance
                     */
                    MetadataDetail.prototype.totalDuration = 0;
    
                    /**
                     * Creates a new MetadataDetail instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @static
                     * @param {palexy.streaming.v1.IMetadataDetail=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MetadataDetail} MetadataDetail instance
                     */
                    MetadataDetail.create = function create(properties) {
                        return new MetadataDetail(properties);
                    };
    
                    /**
                     * Encodes the specified MetadataDetail message. Does not implicitly {@link palexy.streaming.v1.MetadataDetail.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @static
                     * @param {palexy.streaming.v1.IMetadataDetail} message MetadataDetail message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MetadataDetail.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.totalActionItem != null && Object.hasOwnProperty.call(message, "totalActionItem"))
                            $root.palexy.streaming.v1.TotalActionItem.encode(message.totalActionItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.metadataValueId != null && Object.hasOwnProperty.call(message, "metadataValueId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.metadataValueId);
                        if (message.metadataValue != null && Object.hasOwnProperty.call(message, "metadataValue"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.metadataValue);
                        if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalDuration);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MetadataDetail message, length delimited. Does not implicitly {@link palexy.streaming.v1.MetadataDetail.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @static
                     * @param {palexy.streaming.v1.IMetadataDetail} message MetadataDetail message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MetadataDetail.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MetadataDetail message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MetadataDetail} MetadataDetail
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MetadataDetail.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MetadataDetail();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.totalActionItem = $root.palexy.streaming.v1.TotalActionItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.metadataValueId = reader.int64();
                                break;
                            case 3:
                                message.metadataValue = reader.int64();
                                break;
                            case 4:
                                message.totalDuration = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MetadataDetail message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MetadataDetail} MetadataDetail
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MetadataDetail.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MetadataDetail message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MetadataDetail.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.totalActionItem != null && message.hasOwnProperty("totalActionItem")) {
                            var error = $root.palexy.streaming.v1.TotalActionItem.verify(message.totalActionItem);
                            if (error)
                                return "totalActionItem." + error;
                        }
                        if (message.metadataValueId != null && message.hasOwnProperty("metadataValueId"))
                            if (!$util.isInteger(message.metadataValueId) && !(message.metadataValueId && $util.isInteger(message.metadataValueId.low) && $util.isInteger(message.metadataValueId.high)))
                                return "metadataValueId: integer|Long expected";
                        if (message.metadataValue != null && message.hasOwnProperty("metadataValue"))
                            if (!$util.isInteger(message.metadataValue) && !(message.metadataValue && $util.isInteger(message.metadataValue.low) && $util.isInteger(message.metadataValue.high)))
                                return "metadataValue: integer|Long expected";
                        if (message.totalDuration != null && message.hasOwnProperty("totalDuration"))
                            if (!$util.isInteger(message.totalDuration))
                                return "totalDuration: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a MetadataDetail message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MetadataDetail} MetadataDetail
                     */
                    MetadataDetail.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MetadataDetail)
                            return object;
                        var message = new $root.palexy.streaming.v1.MetadataDetail();
                        if (object.totalActionItem != null) {
                            if (typeof object.totalActionItem !== "object")
                                throw TypeError(".palexy.streaming.v1.MetadataDetail.totalActionItem: object expected");
                            message.totalActionItem = $root.palexy.streaming.v1.TotalActionItem.fromObject(object.totalActionItem);
                        }
                        if (object.metadataValueId != null)
                            if ($util.Long)
                                (message.metadataValueId = $util.Long.fromValue(object.metadataValueId)).unsigned = false;
                            else if (typeof object.metadataValueId === "string")
                                message.metadataValueId = parseInt(object.metadataValueId, 10);
                            else if (typeof object.metadataValueId === "number")
                                message.metadataValueId = object.metadataValueId;
                            else if (typeof object.metadataValueId === "object")
                                message.metadataValueId = new $util.LongBits(object.metadataValueId.low >>> 0, object.metadataValueId.high >>> 0).toNumber();
                        if (object.metadataValue != null)
                            if ($util.Long)
                                (message.metadataValue = $util.Long.fromValue(object.metadataValue)).unsigned = false;
                            else if (typeof object.metadataValue === "string")
                                message.metadataValue = parseInt(object.metadataValue, 10);
                            else if (typeof object.metadataValue === "number")
                                message.metadataValue = object.metadataValue;
                            else if (typeof object.metadataValue === "object")
                                message.metadataValue = new $util.LongBits(object.metadataValue.low >>> 0, object.metadataValue.high >>> 0).toNumber();
                        if (object.totalDuration != null)
                            message.totalDuration = object.totalDuration | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MetadataDetail message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @static
                     * @param {palexy.streaming.v1.MetadataDetail} message MetadataDetail
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MetadataDetail.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.totalActionItem = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataValueId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataValueId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataValue = options.longs === String ? "0" : 0;
                            object.totalDuration = 0;
                        }
                        if (message.totalActionItem != null && message.hasOwnProperty("totalActionItem"))
                            object.totalActionItem = $root.palexy.streaming.v1.TotalActionItem.toObject(message.totalActionItem, options);
                        if (message.metadataValueId != null && message.hasOwnProperty("metadataValueId"))
                            if (typeof message.metadataValueId === "number")
                                object.metadataValueId = options.longs === String ? String(message.metadataValueId) : message.metadataValueId;
                            else
                                object.metadataValueId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataValueId) : options.longs === Number ? new $util.LongBits(message.metadataValueId.low >>> 0, message.metadataValueId.high >>> 0).toNumber() : message.metadataValueId;
                        if (message.metadataValue != null && message.hasOwnProperty("metadataValue"))
                            if (typeof message.metadataValue === "number")
                                object.metadataValue = options.longs === String ? String(message.metadataValue) : message.metadataValue;
                            else
                                object.metadataValue = options.longs === String ? $util.Long.prototype.toString.call(message.metadataValue) : options.longs === Number ? new $util.LongBits(message.metadataValue.low >>> 0, message.metadataValue.high >>> 0).toNumber() : message.metadataValue;
                        if (message.totalDuration != null && message.hasOwnProperty("totalDuration"))
                            object.totalDuration = message.totalDuration;
                        return object;
                    };
    
                    /**
                     * Converts this MetadataDetail to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MetadataDetail
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MetadataDetail.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MetadataDetail;
                })();
    
                v1.TotalActionItem = (function() {
    
                    /**
                     * Properties of a TotalActionItem.
                     * @memberof palexy.streaming.v1
                     * @interface ITotalActionItem
                     * @property {string|null} [actionType] TotalActionItem actionType
                     * @property {number|null} [total] TotalActionItem total
                     */
    
                    /**
                     * Constructs a new TotalActionItem.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a TotalActionItem.
                     * @implements ITotalActionItem
                     * @constructor
                     * @param {palexy.streaming.v1.ITotalActionItem=} [properties] Properties to set
                     */
                    function TotalActionItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TotalActionItem actionType.
                     * @member {string} actionType
                     * @memberof palexy.streaming.v1.TotalActionItem
                     * @instance
                     */
                    TotalActionItem.prototype.actionType = "";
    
                    /**
                     * TotalActionItem total.
                     * @member {number} total
                     * @memberof palexy.streaming.v1.TotalActionItem
                     * @instance
                     */
                    TotalActionItem.prototype.total = 0;
    
                    /**
                     * Creates a new TotalActionItem instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.TotalActionItem
                     * @static
                     * @param {palexy.streaming.v1.ITotalActionItem=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.TotalActionItem} TotalActionItem instance
                     */
                    TotalActionItem.create = function create(properties) {
                        return new TotalActionItem(properties);
                    };
    
                    /**
                     * Encodes the specified TotalActionItem message. Does not implicitly {@link palexy.streaming.v1.TotalActionItem.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.TotalActionItem
                     * @static
                     * @param {palexy.streaming.v1.ITotalActionItem} message TotalActionItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TotalActionItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.actionType != null && Object.hasOwnProperty.call(message, "actionType"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.actionType);
                        if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.total);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TotalActionItem message, length delimited. Does not implicitly {@link palexy.streaming.v1.TotalActionItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.TotalActionItem
                     * @static
                     * @param {palexy.streaming.v1.ITotalActionItem} message TotalActionItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TotalActionItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TotalActionItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.TotalActionItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.TotalActionItem} TotalActionItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TotalActionItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.TotalActionItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.actionType = reader.string();
                                break;
                            case 2:
                                message.total = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TotalActionItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.TotalActionItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.TotalActionItem} TotalActionItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TotalActionItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TotalActionItem message.
                     * @function verify
                     * @memberof palexy.streaming.v1.TotalActionItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TotalActionItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.actionType != null && message.hasOwnProperty("actionType"))
                            if (!$util.isString(message.actionType))
                                return "actionType: string expected";
                        if (message.total != null && message.hasOwnProperty("total"))
                            if (!$util.isInteger(message.total))
                                return "total: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a TotalActionItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.TotalActionItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.TotalActionItem} TotalActionItem
                     */
                    TotalActionItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.TotalActionItem)
                            return object;
                        var message = new $root.palexy.streaming.v1.TotalActionItem();
                        if (object.actionType != null)
                            message.actionType = String(object.actionType);
                        if (object.total != null)
                            message.total = object.total | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TotalActionItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.TotalActionItem
                     * @static
                     * @param {palexy.streaming.v1.TotalActionItem} message TotalActionItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TotalActionItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.actionType = "";
                            object.total = 0;
                        }
                        if (message.actionType != null && message.hasOwnProperty("actionType"))
                            object.actionType = message.actionType;
                        if (message.total != null && message.hasOwnProperty("total"))
                            object.total = message.total;
                        return object;
                    };
    
                    /**
                     * Converts this TotalActionItem to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.TotalActionItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TotalActionItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TotalActionItem;
                })();
    
                v1.RawICARecordService = (function() {
    
                    /**
                     * Constructs a new RawICARecordService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RawICARecordService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function RawICARecordService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (RawICARecordService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RawICARecordService;
    
                    /**
                     * Creates new RawICARecordService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.RawICARecordService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {RawICARecordService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    RawICARecordService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICARecordService#listRecords}.
                     * @memberof palexy.streaming.v1.RawICARecordService
                     * @typedef ListRecordsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListRawICARecordsResponse} [response] ListRawICARecordsResponse
                     */
    
                    /**
                     * Calls ListRecords.
                     * @function listRecords
                     * @memberof palexy.streaming.v1.RawICARecordService
                     * @instance
                     * @param {palexy.streaming.v1.IListRawICARecordsRequest} request ListRawICARecordsRequest message or plain object
                     * @param {palexy.streaming.v1.RawICARecordService.ListRecordsCallback} callback Node-style callback called with the error, if any, and ListRawICARecordsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICARecordService.prototype.listRecords = function listRecords(request, callback) {
                        return this.rpcCall(listRecords, $root.palexy.streaming.v1.ListRawICARecordsRequest, $root.palexy.streaming.v1.ListRawICARecordsResponse, request, callback);
                    }, "name", { value: "ListRecords" });
    
                    /**
                     * Calls ListRecords.
                     * @function listRecords
                     * @memberof palexy.streaming.v1.RawICARecordService
                     * @instance
                     * @param {palexy.streaming.v1.IListRawICARecordsRequest} request ListRawICARecordsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListRawICARecordsResponse>} Promise
                     * @variation 2
                     */
    
                    return RawICARecordService;
                })();
    
                v1.ListRawICARecordsRequest = (function() {
    
                    /**
                     * Properties of a ListRawICARecordsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListRawICARecordsRequest
                     * @property {palexy.streaming.v1.IFilter|null} [filter] ListRawICARecordsRequest filter
                     * @property {google.protobuf.IFieldMask|null} [getFields] ListRawICARecordsRequest getFields
                     */
    
                    /**
                     * Constructs a new ListRawICARecordsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListRawICARecordsRequest.
                     * @implements IListRawICARecordsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListRawICARecordsRequest=} [properties] Properties to set
                     */
                    function ListRawICARecordsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListRawICARecordsRequest filter.
                     * @member {palexy.streaming.v1.IFilter|null|undefined} filter
                     * @memberof palexy.streaming.v1.ListRawICARecordsRequest
                     * @instance
                     */
                    ListRawICARecordsRequest.prototype.filter = null;
    
                    /**
                     * ListRawICARecordsRequest getFields.
                     * @member {google.protobuf.IFieldMask|null|undefined} getFields
                     * @memberof palexy.streaming.v1.ListRawICARecordsRequest
                     * @instance
                     */
                    ListRawICARecordsRequest.prototype.getFields = null;
    
                    /**
                     * Creates a new ListRawICARecordsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListRawICARecordsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListRawICARecordsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListRawICARecordsRequest} ListRawICARecordsRequest instance
                     */
                    ListRawICARecordsRequest.create = function create(properties) {
                        return new ListRawICARecordsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListRawICARecordsRequest message. Does not implicitly {@link palexy.streaming.v1.ListRawICARecordsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListRawICARecordsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListRawICARecordsRequest} message ListRawICARecordsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICARecordsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                            $root.palexy.streaming.v1.Filter.encode(message.filter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.getFields != null && Object.hasOwnProperty.call(message, "getFields"))
                            $root.google.protobuf.FieldMask.encode(message.getFields, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListRawICARecordsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListRawICARecordsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICARecordsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListRawICARecordsRequest} message ListRawICARecordsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICARecordsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListRawICARecordsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListRawICARecordsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListRawICARecordsRequest} ListRawICARecordsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICARecordsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListRawICARecordsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.filter = $root.palexy.streaming.v1.Filter.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.getFields = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListRawICARecordsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICARecordsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListRawICARecordsRequest} ListRawICARecordsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICARecordsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListRawICARecordsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListRawICARecordsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListRawICARecordsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.filter != null && message.hasOwnProperty("filter")) {
                            var error = $root.palexy.streaming.v1.Filter.verify(message.filter);
                            if (error)
                                return "filter." + error;
                        }
                        if (message.getFields != null && message.hasOwnProperty("getFields")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.getFields);
                            if (error)
                                return "getFields." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListRawICARecordsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListRawICARecordsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListRawICARecordsRequest} ListRawICARecordsRequest
                     */
                    ListRawICARecordsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListRawICARecordsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListRawICARecordsRequest();
                        if (object.filter != null) {
                            if (typeof object.filter !== "object")
                                throw TypeError(".palexy.streaming.v1.ListRawICARecordsRequest.filter: object expected");
                            message.filter = $root.palexy.streaming.v1.Filter.fromObject(object.filter);
                        }
                        if (object.getFields != null) {
                            if (typeof object.getFields !== "object")
                                throw TypeError(".palexy.streaming.v1.ListRawICARecordsRequest.getFields: object expected");
                            message.getFields = $root.google.protobuf.FieldMask.fromObject(object.getFields);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListRawICARecordsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListRawICARecordsRequest
                     * @static
                     * @param {palexy.streaming.v1.ListRawICARecordsRequest} message ListRawICARecordsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListRawICARecordsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.filter = null;
                            object.getFields = null;
                        }
                        if (message.filter != null && message.hasOwnProperty("filter"))
                            object.filter = $root.palexy.streaming.v1.Filter.toObject(message.filter, options);
                        if (message.getFields != null && message.hasOwnProperty("getFields"))
                            object.getFields = $root.google.protobuf.FieldMask.toObject(message.getFields, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListRawICARecordsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListRawICARecordsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListRawICARecordsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListRawICARecordsRequest;
                })();
    
                v1.Filter = (function() {
    
                    /**
                     * Properties of a Filter.
                     * @memberof palexy.streaming.v1
                     * @interface IFilter
                     * @property {number|null} [storeId] Filter storeId
                     * @property {string|null} [startDateTime] Filter startDateTime
                     * @property {string|null} [endDateTime] Filter endDateTime
                     * @property {string|null} [cameraId] Filter cameraId
                     * @property {string|null} [actionType] Filter actionType
                     * @property {google.protobuf.IBoolValue|null} [isStaff] Filter isStaff
                     * @property {google.protobuf.IInt64Value|null} [limit] Filter limit
                     * @property {Array.<string>|null} [actionTypes] Filter actionTypes
                     * @property {boolean|null} [useIcaMergingBucket] Filter useIcaMergingBucket
                     */
    
                    /**
                     * Constructs a new Filter.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Filter.
                     * @implements IFilter
                     * @constructor
                     * @param {palexy.streaming.v1.IFilter=} [properties] Properties to set
                     */
                    function Filter(properties) {
                        this.actionTypes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Filter storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.Filter
                     * @instance
                     */
                    Filter.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Filter startDateTime.
                     * @member {string} startDateTime
                     * @memberof palexy.streaming.v1.Filter
                     * @instance
                     */
                    Filter.prototype.startDateTime = "";
    
                    /**
                     * Filter endDateTime.
                     * @member {string} endDateTime
                     * @memberof palexy.streaming.v1.Filter
                     * @instance
                     */
                    Filter.prototype.endDateTime = "";
    
                    /**
                     * Filter cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.Filter
                     * @instance
                     */
                    Filter.prototype.cameraId = "";
    
                    /**
                     * Filter actionType.
                     * @member {string} actionType
                     * @memberof palexy.streaming.v1.Filter
                     * @instance
                     */
                    Filter.prototype.actionType = "";
    
                    /**
                     * Filter isStaff.
                     * @member {google.protobuf.IBoolValue|null|undefined} isStaff
                     * @memberof palexy.streaming.v1.Filter
                     * @instance
                     */
                    Filter.prototype.isStaff = null;
    
                    /**
                     * Filter limit.
                     * @member {google.protobuf.IInt64Value|null|undefined} limit
                     * @memberof palexy.streaming.v1.Filter
                     * @instance
                     */
                    Filter.prototype.limit = null;
    
                    /**
                     * Filter actionTypes.
                     * @member {Array.<string>} actionTypes
                     * @memberof palexy.streaming.v1.Filter
                     * @instance
                     */
                    Filter.prototype.actionTypes = $util.emptyArray;
    
                    /**
                     * Filter useIcaMergingBucket.
                     * @member {boolean} useIcaMergingBucket
                     * @memberof palexy.streaming.v1.Filter
                     * @instance
                     */
                    Filter.prototype.useIcaMergingBucket = false;
    
                    /**
                     * Creates a new Filter instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Filter
                     * @static
                     * @param {palexy.streaming.v1.IFilter=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Filter} Filter instance
                     */
                    Filter.create = function create(properties) {
                        return new Filter(properties);
                    };
    
                    /**
                     * Encodes the specified Filter message. Does not implicitly {@link palexy.streaming.v1.Filter.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Filter
                     * @static
                     * @param {palexy.streaming.v1.IFilter} message Filter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Filter.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.startDateTime != null && Object.hasOwnProperty.call(message, "startDateTime"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.startDateTime);
                        if (message.endDateTime != null && Object.hasOwnProperty.call(message, "endDateTime"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.endDateTime);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.cameraId);
                        if (message.actionType != null && Object.hasOwnProperty.call(message, "actionType"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.actionType);
                        if (message.isStaff != null && Object.hasOwnProperty.call(message, "isStaff"))
                            $root.google.protobuf.BoolValue.encode(message.isStaff, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                            $root.google.protobuf.Int64Value.encode(message.limit, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.actionTypes != null && message.actionTypes.length)
                            for (var i = 0; i < message.actionTypes.length; ++i)
                                writer.uint32(/* id 8, wireType 2 =*/66).string(message.actionTypes[i]);
                        if (message.useIcaMergingBucket != null && Object.hasOwnProperty.call(message, "useIcaMergingBucket"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.useIcaMergingBucket);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Filter message, length delimited. Does not implicitly {@link palexy.streaming.v1.Filter.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Filter
                     * @static
                     * @param {palexy.streaming.v1.IFilter} message Filter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Filter.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Filter message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Filter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Filter} Filter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Filter.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Filter();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.startDateTime = reader.string();
                                break;
                            case 3:
                                message.endDateTime = reader.string();
                                break;
                            case 4:
                                message.cameraId = reader.string();
                                break;
                            case 5:
                                message.actionType = reader.string();
                                break;
                            case 6:
                                message.isStaff = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.limit = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 8:
                                if (!(message.actionTypes && message.actionTypes.length))
                                    message.actionTypes = [];
                                message.actionTypes.push(reader.string());
                                break;
                            case 9:
                                message.useIcaMergingBucket = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Filter message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Filter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Filter} Filter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Filter.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Filter message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Filter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Filter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.startDateTime != null && message.hasOwnProperty("startDateTime"))
                            if (!$util.isString(message.startDateTime))
                                return "startDateTime: string expected";
                        if (message.endDateTime != null && message.hasOwnProperty("endDateTime"))
                            if (!$util.isString(message.endDateTime))
                                return "endDateTime: string expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.actionType != null && message.hasOwnProperty("actionType"))
                            if (!$util.isString(message.actionType))
                                return "actionType: string expected";
                        if (message.isStaff != null && message.hasOwnProperty("isStaff")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isStaff);
                            if (error)
                                return "isStaff." + error;
                        }
                        if (message.limit != null && message.hasOwnProperty("limit")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.limit);
                            if (error)
                                return "limit." + error;
                        }
                        if (message.actionTypes != null && message.hasOwnProperty("actionTypes")) {
                            if (!Array.isArray(message.actionTypes))
                                return "actionTypes: array expected";
                            for (var i = 0; i < message.actionTypes.length; ++i)
                                if (!$util.isString(message.actionTypes[i]))
                                    return "actionTypes: string[] expected";
                        }
                        if (message.useIcaMergingBucket != null && message.hasOwnProperty("useIcaMergingBucket"))
                            if (typeof message.useIcaMergingBucket !== "boolean")
                                return "useIcaMergingBucket: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a Filter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Filter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Filter} Filter
                     */
                    Filter.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Filter)
                            return object;
                        var message = new $root.palexy.streaming.v1.Filter();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.startDateTime != null)
                            message.startDateTime = String(object.startDateTime);
                        if (object.endDateTime != null)
                            message.endDateTime = String(object.endDateTime);
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.actionType != null)
                            message.actionType = String(object.actionType);
                        if (object.isStaff != null) {
                            if (typeof object.isStaff !== "object")
                                throw TypeError(".palexy.streaming.v1.Filter.isStaff: object expected");
                            message.isStaff = $root.google.protobuf.BoolValue.fromObject(object.isStaff);
                        }
                        if (object.limit != null) {
                            if (typeof object.limit !== "object")
                                throw TypeError(".palexy.streaming.v1.Filter.limit: object expected");
                            message.limit = $root.google.protobuf.Int64Value.fromObject(object.limit);
                        }
                        if (object.actionTypes) {
                            if (!Array.isArray(object.actionTypes))
                                throw TypeError(".palexy.streaming.v1.Filter.actionTypes: array expected");
                            message.actionTypes = [];
                            for (var i = 0; i < object.actionTypes.length; ++i)
                                message.actionTypes[i] = String(object.actionTypes[i]);
                        }
                        if (object.useIcaMergingBucket != null)
                            message.useIcaMergingBucket = Boolean(object.useIcaMergingBucket);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Filter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Filter
                     * @static
                     * @param {palexy.streaming.v1.Filter} message Filter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Filter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.actionTypes = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.startDateTime = "";
                            object.endDateTime = "";
                            object.cameraId = "";
                            object.actionType = "";
                            object.isStaff = null;
                            object.limit = null;
                            object.useIcaMergingBucket = false;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.startDateTime != null && message.hasOwnProperty("startDateTime"))
                            object.startDateTime = message.startDateTime;
                        if (message.endDateTime != null && message.hasOwnProperty("endDateTime"))
                            object.endDateTime = message.endDateTime;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.actionType != null && message.hasOwnProperty("actionType"))
                            object.actionType = message.actionType;
                        if (message.isStaff != null && message.hasOwnProperty("isStaff"))
                            object.isStaff = $root.google.protobuf.BoolValue.toObject(message.isStaff, options);
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            object.limit = $root.google.protobuf.Int64Value.toObject(message.limit, options);
                        if (message.actionTypes && message.actionTypes.length) {
                            object.actionTypes = [];
                            for (var j = 0; j < message.actionTypes.length; ++j)
                                object.actionTypes[j] = message.actionTypes[j];
                        }
                        if (message.useIcaMergingBucket != null && message.hasOwnProperty("useIcaMergingBucket"))
                            object.useIcaMergingBucket = message.useIcaMergingBucket;
                        return object;
                    };
    
                    /**
                     * Converts this Filter to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Filter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Filter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Filter;
                })();
    
                v1.ListRawICARecordsResponse = (function() {
    
                    /**
                     * Properties of a ListRawICARecordsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListRawICARecordsResponse
                     * @property {Array.<palexy.streaming.v1.IRawICARecord>|null} [records] ListRawICARecordsResponse records
                     */
    
                    /**
                     * Constructs a new ListRawICARecordsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListRawICARecordsResponse.
                     * @implements IListRawICARecordsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListRawICARecordsResponse=} [properties] Properties to set
                     */
                    function ListRawICARecordsResponse(properties) {
                        this.records = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListRawICARecordsResponse records.
                     * @member {Array.<palexy.streaming.v1.IRawICARecord>} records
                     * @memberof palexy.streaming.v1.ListRawICARecordsResponse
                     * @instance
                     */
                    ListRawICARecordsResponse.prototype.records = $util.emptyArray;
    
                    /**
                     * Creates a new ListRawICARecordsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListRawICARecordsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListRawICARecordsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListRawICARecordsResponse} ListRawICARecordsResponse instance
                     */
                    ListRawICARecordsResponse.create = function create(properties) {
                        return new ListRawICARecordsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListRawICARecordsResponse message. Does not implicitly {@link palexy.streaming.v1.ListRawICARecordsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListRawICARecordsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListRawICARecordsResponse} message ListRawICARecordsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICARecordsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.records != null && message.records.length)
                            for (var i = 0; i < message.records.length; ++i)
                                $root.palexy.streaming.v1.RawICARecord.encode(message.records[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListRawICARecordsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListRawICARecordsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICARecordsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListRawICARecordsResponse} message ListRawICARecordsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICARecordsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListRawICARecordsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListRawICARecordsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListRawICARecordsResponse} ListRawICARecordsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICARecordsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListRawICARecordsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.records && message.records.length))
                                    message.records = [];
                                message.records.push($root.palexy.streaming.v1.RawICARecord.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListRawICARecordsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICARecordsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListRawICARecordsResponse} ListRawICARecordsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICARecordsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListRawICARecordsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListRawICARecordsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListRawICARecordsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.records != null && message.hasOwnProperty("records")) {
                            if (!Array.isArray(message.records))
                                return "records: array expected";
                            for (var i = 0; i < message.records.length; ++i) {
                                var error = $root.palexy.streaming.v1.RawICARecord.verify(message.records[i]);
                                if (error)
                                    return "records." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListRawICARecordsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListRawICARecordsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListRawICARecordsResponse} ListRawICARecordsResponse
                     */
                    ListRawICARecordsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListRawICARecordsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListRawICARecordsResponse();
                        if (object.records) {
                            if (!Array.isArray(object.records))
                                throw TypeError(".palexy.streaming.v1.ListRawICARecordsResponse.records: array expected");
                            message.records = [];
                            for (var i = 0; i < object.records.length; ++i) {
                                if (typeof object.records[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListRawICARecordsResponse.records: object expected");
                                message.records[i] = $root.palexy.streaming.v1.RawICARecord.fromObject(object.records[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListRawICARecordsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListRawICARecordsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListRawICARecordsResponse} message ListRawICARecordsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListRawICARecordsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.records = [];
                        if (message.records && message.records.length) {
                            object.records = [];
                            for (var j = 0; j < message.records.length; ++j)
                                object.records[j] = $root.palexy.streaming.v1.RawICARecord.toObject(message.records[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListRawICARecordsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListRawICARecordsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListRawICARecordsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListRawICARecordsResponse;
                })();
    
                v1.MergeResult = (function() {
    
                    /**
                     * Properties of a MergeResult.
                     * @memberof palexy.streaming.v1
                     * @interface IMergeResult
                     * @property {Array.<palexy.streaming.v1.IRawICARecord>|null} [records] MergeResult records
                     * @property {string|null} [dateId] MergeResult dateId
                     * @property {number|null} [storeId] MergeResult storeId
                     */
    
                    /**
                     * Constructs a new MergeResult.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MergeResult.
                     * @implements IMergeResult
                     * @constructor
                     * @param {palexy.streaming.v1.IMergeResult=} [properties] Properties to set
                     */
                    function MergeResult(properties) {
                        this.records = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MergeResult records.
                     * @member {Array.<palexy.streaming.v1.IRawICARecord>} records
                     * @memberof palexy.streaming.v1.MergeResult
                     * @instance
                     */
                    MergeResult.prototype.records = $util.emptyArray;
    
                    /**
                     * MergeResult dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.MergeResult
                     * @instance
                     */
                    MergeResult.prototype.dateId = "";
    
                    /**
                     * MergeResult storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.MergeResult
                     * @instance
                     */
                    MergeResult.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new MergeResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MergeResult
                     * @static
                     * @param {palexy.streaming.v1.IMergeResult=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MergeResult} MergeResult instance
                     */
                    MergeResult.create = function create(properties) {
                        return new MergeResult(properties);
                    };
    
                    /**
                     * Encodes the specified MergeResult message. Does not implicitly {@link palexy.streaming.v1.MergeResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MergeResult
                     * @static
                     * @param {palexy.streaming.v1.IMergeResult} message MergeResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergeResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.records != null && message.records.length)
                            for (var i = 0; i < message.records.length; ++i)
                                $root.palexy.streaming.v1.RawICARecord.encode(message.records[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MergeResult message, length delimited. Does not implicitly {@link palexy.streaming.v1.MergeResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MergeResult
                     * @static
                     * @param {palexy.streaming.v1.IMergeResult} message MergeResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergeResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MergeResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MergeResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MergeResult} MergeResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergeResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MergeResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.records && message.records.length))
                                    message.records = [];
                                message.records.push($root.palexy.streaming.v1.RawICARecord.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MergeResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MergeResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MergeResult} MergeResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergeResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MergeResult message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MergeResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MergeResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.records != null && message.hasOwnProperty("records")) {
                            if (!Array.isArray(message.records))
                                return "records: array expected";
                            for (var i = 0; i < message.records.length; ++i) {
                                var error = $root.palexy.streaming.v1.RawICARecord.verify(message.records[i]);
                                if (error)
                                    return "records." + error;
                            }
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a MergeResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MergeResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MergeResult} MergeResult
                     */
                    MergeResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MergeResult)
                            return object;
                        var message = new $root.palexy.streaming.v1.MergeResult();
                        if (object.records) {
                            if (!Array.isArray(object.records))
                                throw TypeError(".palexy.streaming.v1.MergeResult.records: array expected");
                            message.records = [];
                            for (var i = 0; i < object.records.length; ++i) {
                                if (typeof object.records[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.MergeResult.records: object expected");
                                message.records[i] = $root.palexy.streaming.v1.RawICARecord.fromObject(object.records[i]);
                            }
                        }
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MergeResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MergeResult
                     * @static
                     * @param {palexy.streaming.v1.MergeResult} message MergeResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MergeResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.records = [];
                        if (options.defaults) {
                            object.dateId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                        }
                        if (message.records && message.records.length) {
                            object.records = [];
                            for (var j = 0; j < message.records.length; ++j)
                                object.records[j] = $root.palexy.streaming.v1.RawICARecord.toObject(message.records[j], options);
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        return object;
                    };
    
                    /**
                     * Converts this MergeResult to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MergeResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MergeResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MergeResult;
                })();
    
                v1.StoreConfig = (function() {
    
                    /**
                     * Properties of a StoreConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreConfig
                     * @property {number|null} [id] StoreConfig id
                     * @property {string|null} [name] StoreConfig name
                     * @property {number|null} [storeId] StoreConfig storeId
                     * @property {google.protobuf.ITimestamp|null} [createTime] StoreConfig createTime
                     * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] StoreConfig lastUpdateTime
                     * @property {palexy.streaming.v1.IStore|null} [storeData] StoreConfig storeData
                     */
    
                    /**
                     * Constructs a new StoreConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreConfig.
                     * @implements IStoreConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreConfig=} [properties] Properties to set
                     */
                    function StoreConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreConfig id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @instance
                     */
                    StoreConfig.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreConfig name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @instance
                     */
                    StoreConfig.prototype.name = "";
    
                    /**
                     * StoreConfig storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @instance
                     */
                    StoreConfig.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreConfig createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @instance
                     */
                    StoreConfig.prototype.createTime = null;
    
                    /**
                     * StoreConfig lastUpdateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @instance
                     */
                    StoreConfig.prototype.lastUpdateTime = null;
    
                    /**
                     * StoreConfig storeData.
                     * @member {palexy.streaming.v1.IStore|null|undefined} storeData
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @instance
                     */
                    StoreConfig.prototype.storeData = null;
    
                    /**
                     * Creates a new StoreConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @static
                     * @param {palexy.streaming.v1.IStoreConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreConfig} StoreConfig instance
                     */
                    StoreConfig.create = function create(properties) {
                        return new StoreConfig(properties);
                    };
    
                    /**
                     * Encodes the specified StoreConfig message. Does not implicitly {@link palexy.streaming.v1.StoreConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @static
                     * @param {palexy.streaming.v1.IStoreConfig} message StoreConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.storeData != null && Object.hasOwnProperty.call(message, "storeData"))
                            $root.palexy.streaming.v1.Store.encode(message.storeData, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @static
                     * @param {palexy.streaming.v1.IStoreConfig} message StoreConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreConfig} StoreConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 4:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.storeData = $root.palexy.streaming.v1.Store.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreConfig} StoreConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                            if (error)
                                return "lastUpdateTime." + error;
                        }
                        if (message.storeData != null && message.hasOwnProperty("storeData")) {
                            var error = $root.palexy.streaming.v1.Store.verify(message.storeData);
                            if (error)
                                return "storeData." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a StoreConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreConfig} StoreConfig
                     */
                    StoreConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreConfig();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreConfig.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.lastUpdateTime != null) {
                            if (typeof object.lastUpdateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreConfig.lastUpdateTime: object expected");
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                        }
                        if (object.storeData != null) {
                            if (typeof object.storeData !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreConfig.storeData: object expected");
                            message.storeData = $root.palexy.streaming.v1.Store.fromObject(object.storeData);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @static
                     * @param {palexy.streaming.v1.StoreConfig} message StoreConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.createTime = null;
                            object.lastUpdateTime = null;
                            object.storeData = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                            object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                        if (message.storeData != null && message.hasOwnProperty("storeData"))
                            object.storeData = $root.palexy.streaming.v1.Store.toObject(message.storeData, options);
                        return object;
                    };
    
                    /**
                     * Converts this StoreConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StoreConfig;
                })();
    
                v1.StoreConfigItem = (function() {
    
                    /**
                     * Properties of a StoreConfigItem.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreConfigItem
                     * @property {number|null} [id] StoreConfigItem id
                     * @property {string|null} [name] StoreConfigItem name
                     * @property {palexy.streaming.v1.StoreConfigItem.StoreConfigType|null} [configType] StoreConfigItem configType
                     * @property {string|null} [displayName] StoreConfigItem displayName
                     * @property {google.protobuf.ITimestamp|null} [createTime] StoreConfigItem createTime
                     * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] StoreConfigItem lastUpdateTime
                     * @property {number|null} [storeConfigId] StoreConfigItem storeConfigId
                     * @property {palexy.streaming.v1.IConfigData|null} [configData] StoreConfigItem configData
                     * @property {string|null} [appliedDate] StoreConfigItem appliedDate
                     * @property {palexy.streaming.v1.StoreConfigItem.StoreConfigAppliedScope|null} [appliedScope] StoreConfigItem appliedScope
                     * @property {string|null} [note] StoreConfigItem note
                     */
    
                    /**
                     * Constructs a new StoreConfigItem.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreConfigItem.
                     * @implements IStoreConfigItem
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreConfigItem=} [properties] Properties to set
                     */
                    function StoreConfigItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreConfigItem id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     */
                    StoreConfigItem.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreConfigItem name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     */
                    StoreConfigItem.prototype.name = "";
    
                    /**
                     * StoreConfigItem configType.
                     * @member {palexy.streaming.v1.StoreConfigItem.StoreConfigType} configType
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     */
                    StoreConfigItem.prototype.configType = 0;
    
                    /**
                     * StoreConfigItem displayName.
                     * @member {string} displayName
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     */
                    StoreConfigItem.prototype.displayName = "";
    
                    /**
                     * StoreConfigItem createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     */
                    StoreConfigItem.prototype.createTime = null;
    
                    /**
                     * StoreConfigItem lastUpdateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     */
                    StoreConfigItem.prototype.lastUpdateTime = null;
    
                    /**
                     * StoreConfigItem storeConfigId.
                     * @member {number} storeConfigId
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     */
                    StoreConfigItem.prototype.storeConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreConfigItem configData.
                     * @member {palexy.streaming.v1.IConfigData|null|undefined} configData
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     */
                    StoreConfigItem.prototype.configData = null;
    
                    /**
                     * StoreConfigItem appliedDate.
                     * @member {string} appliedDate
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     */
                    StoreConfigItem.prototype.appliedDate = "";
    
                    /**
                     * StoreConfigItem appliedScope.
                     * @member {palexy.streaming.v1.StoreConfigItem.StoreConfigAppliedScope} appliedScope
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     */
                    StoreConfigItem.prototype.appliedScope = 0;
    
                    /**
                     * StoreConfigItem note.
                     * @member {string} note
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     */
                    StoreConfigItem.prototype.note = "";
    
                    /**
                     * Creates a new StoreConfigItem instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @static
                     * @param {palexy.streaming.v1.IStoreConfigItem=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreConfigItem} StoreConfigItem instance
                     */
                    StoreConfigItem.create = function create(properties) {
                        return new StoreConfigItem(properties);
                    };
    
                    /**
                     * Encodes the specified StoreConfigItem message. Does not implicitly {@link palexy.streaming.v1.StoreConfigItem.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @static
                     * @param {palexy.streaming.v1.IStoreConfigItem} message StoreConfigItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreConfigItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.configType != null && Object.hasOwnProperty.call(message, "configType"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.configType);
                        if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.displayName);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.storeConfigId != null && Object.hasOwnProperty.call(message, "storeConfigId"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.storeConfigId);
                        if (message.configData != null && Object.hasOwnProperty.call(message, "configData"))
                            $root.palexy.streaming.v1.ConfigData.encode(message.configData, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.appliedDate != null && Object.hasOwnProperty.call(message, "appliedDate"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.appliedDate);
                        if (message.appliedScope != null && Object.hasOwnProperty.call(message, "appliedScope"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.appliedScope);
                        if (message.note != null && Object.hasOwnProperty.call(message, "note"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.note);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreConfigItem message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreConfigItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @static
                     * @param {palexy.streaming.v1.IStoreConfigItem} message StoreConfigItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreConfigItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreConfigItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreConfigItem} StoreConfigItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreConfigItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreConfigItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.configType = reader.int32();
                                break;
                            case 4:
                                message.displayName = reader.string();
                                break;
                            case 5:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.storeConfigId = reader.int64();
                                break;
                            case 8:
                                message.configData = $root.palexy.streaming.v1.ConfigData.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.appliedDate = reader.string();
                                break;
                            case 10:
                                message.appliedScope = reader.int32();
                                break;
                            case 11:
                                message.note = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreConfigItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreConfigItem} StoreConfigItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreConfigItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreConfigItem message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreConfigItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.configType != null && message.hasOwnProperty("configType"))
                            switch (message.configType) {
                            default:
                                return "configType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                            if (error)
                                return "lastUpdateTime." + error;
                        }
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (!$util.isInteger(message.storeConfigId) && !(message.storeConfigId && $util.isInteger(message.storeConfigId.low) && $util.isInteger(message.storeConfigId.high)))
                                return "storeConfigId: integer|Long expected";
                        if (message.configData != null && message.hasOwnProperty("configData")) {
                            var error = $root.palexy.streaming.v1.ConfigData.verify(message.configData);
                            if (error)
                                return "configData." + error;
                        }
                        if (message.appliedDate != null && message.hasOwnProperty("appliedDate"))
                            if (!$util.isString(message.appliedDate))
                                return "appliedDate: string expected";
                        if (message.appliedScope != null && message.hasOwnProperty("appliedScope"))
                            switch (message.appliedScope) {
                            default:
                                return "appliedScope: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.note != null && message.hasOwnProperty("note"))
                            if (!$util.isString(message.note))
                                return "note: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a StoreConfigItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreConfigItem} StoreConfigItem
                     */
                    StoreConfigItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreConfigItem)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreConfigItem();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        switch (object.configType) {
                        case "UNKNOWN_CONFIG":
                        case 0:
                            message.configType = 0;
                            break;
                        case "POST_MERGE_CONFIG":
                        case 1:
                            message.configType = 1;
                            break;
                        case "MERGING_CONFIG":
                        case 2:
                            message.configType = 2;
                            break;
                        case "TRACKING_CONFIG":
                        case 3:
                            message.configType = 3;
                            break;
                        case "PROCESSING_CONFIG":
                        case 4:
                            message.configType = 4;
                            break;
                        }
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreConfigItem.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.lastUpdateTime != null) {
                            if (typeof object.lastUpdateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreConfigItem.lastUpdateTime: object expected");
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                        }
                        if (object.storeConfigId != null)
                            if ($util.Long)
                                (message.storeConfigId = $util.Long.fromValue(object.storeConfigId)).unsigned = false;
                            else if (typeof object.storeConfigId === "string")
                                message.storeConfigId = parseInt(object.storeConfigId, 10);
                            else if (typeof object.storeConfigId === "number")
                                message.storeConfigId = object.storeConfigId;
                            else if (typeof object.storeConfigId === "object")
                                message.storeConfigId = new $util.LongBits(object.storeConfigId.low >>> 0, object.storeConfigId.high >>> 0).toNumber();
                        if (object.configData != null) {
                            if (typeof object.configData !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreConfigItem.configData: object expected");
                            message.configData = $root.palexy.streaming.v1.ConfigData.fromObject(object.configData);
                        }
                        if (object.appliedDate != null)
                            message.appliedDate = String(object.appliedDate);
                        switch (object.appliedScope) {
                        case "UNKNOWN_SCOPE":
                        case 0:
                            message.appliedScope = 0;
                            break;
                        case "PRODUCTION":
                        case 1:
                            message.appliedScope = 1;
                            break;
                        case "EXPERIMENT":
                        case 2:
                            message.appliedScope = 2;
                            break;
                        }
                        if (object.note != null)
                            message.note = String(object.note);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreConfigItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @static
                     * @param {palexy.streaming.v1.StoreConfigItem} message StoreConfigItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreConfigItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.configType = options.enums === String ? "UNKNOWN_CONFIG" : 0;
                            object.displayName = "";
                            object.createTime = null;
                            object.lastUpdateTime = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeConfigId = options.longs === String ? "0" : 0;
                            object.configData = null;
                            object.appliedDate = "";
                            object.appliedScope = options.enums === String ? "UNKNOWN_SCOPE" : 0;
                            object.note = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.configType != null && message.hasOwnProperty("configType"))
                            object.configType = options.enums === String ? $root.palexy.streaming.v1.StoreConfigItem.StoreConfigType[message.configType] : message.configType;
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                            object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                        if (message.storeConfigId != null && message.hasOwnProperty("storeConfigId"))
                            if (typeof message.storeConfigId === "number")
                                object.storeConfigId = options.longs === String ? String(message.storeConfigId) : message.storeConfigId;
                            else
                                object.storeConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.storeConfigId) : options.longs === Number ? new $util.LongBits(message.storeConfigId.low >>> 0, message.storeConfigId.high >>> 0).toNumber() : message.storeConfigId;
                        if (message.configData != null && message.hasOwnProperty("configData"))
                            object.configData = $root.palexy.streaming.v1.ConfigData.toObject(message.configData, options);
                        if (message.appliedDate != null && message.hasOwnProperty("appliedDate"))
                            object.appliedDate = message.appliedDate;
                        if (message.appliedScope != null && message.hasOwnProperty("appliedScope"))
                            object.appliedScope = options.enums === String ? $root.palexy.streaming.v1.StoreConfigItem.StoreConfigAppliedScope[message.appliedScope] : message.appliedScope;
                        if (message.note != null && message.hasOwnProperty("note"))
                            object.note = message.note;
                        return object;
                    };
    
                    /**
                     * Converts this StoreConfigItem to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreConfigItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreConfigItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * StoreConfigType enum.
                     * @name palexy.streaming.v1.StoreConfigItem.StoreConfigType
                     * @enum {number}
                     * @property {number} UNKNOWN_CONFIG=0 UNKNOWN_CONFIG value
                     * @property {number} POST_MERGE_CONFIG=1 POST_MERGE_CONFIG value
                     * @property {number} MERGING_CONFIG=2 MERGING_CONFIG value
                     * @property {number} TRACKING_CONFIG=3 TRACKING_CONFIG value
                     * @property {number} PROCESSING_CONFIG=4 PROCESSING_CONFIG value
                     */
                    StoreConfigItem.StoreConfigType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_CONFIG"] = 0;
                        values[valuesById[1] = "POST_MERGE_CONFIG"] = 1;
                        values[valuesById[2] = "MERGING_CONFIG"] = 2;
                        values[valuesById[3] = "TRACKING_CONFIG"] = 3;
                        values[valuesById[4] = "PROCESSING_CONFIG"] = 4;
                        return values;
                    })();
    
                    /**
                     * StoreConfigAppliedScope enum.
                     * @name palexy.streaming.v1.StoreConfigItem.StoreConfigAppliedScope
                     * @enum {number}
                     * @property {number} UNKNOWN_SCOPE=0 UNKNOWN_SCOPE value
                     * @property {number} PRODUCTION=1 PRODUCTION value
                     * @property {number} EXPERIMENT=2 EXPERIMENT value
                     */
                    StoreConfigItem.StoreConfigAppliedScope = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_SCOPE"] = 0;
                        values[valuesById[1] = "PRODUCTION"] = 1;
                        values[valuesById[2] = "EXPERIMENT"] = 2;
                        return values;
                    })();
    
                    return StoreConfigItem;
                })();
    
                v1.ConfigData = (function() {
    
                    /**
                     * Properties of a ConfigData.
                     * @memberof palexy.streaming.v1
                     * @interface IConfigData
                     * @property {palexy.streaming.v1.IPostMergeConfig|null} [postMergeConfig] ConfigData postMergeConfig
                     * @property {palexy.streaming.v1.IMergingConfig|null} [mergingConfig] ConfigData mergingConfig
                     * @property {palexy.streaming.v1.IMergingConfigPreset|null} [mergingConfigPreset] ConfigData mergingConfigPreset
                     */
    
                    /**
                     * Constructs a new ConfigData.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ConfigData.
                     * @implements IConfigData
                     * @constructor
                     * @param {palexy.streaming.v1.IConfigData=} [properties] Properties to set
                     */
                    function ConfigData(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ConfigData postMergeConfig.
                     * @member {palexy.streaming.v1.IPostMergeConfig|null|undefined} postMergeConfig
                     * @memberof palexy.streaming.v1.ConfigData
                     * @instance
                     */
                    ConfigData.prototype.postMergeConfig = null;
    
                    /**
                     * ConfigData mergingConfig.
                     * @member {palexy.streaming.v1.IMergingConfig|null|undefined} mergingConfig
                     * @memberof palexy.streaming.v1.ConfigData
                     * @instance
                     */
                    ConfigData.prototype.mergingConfig = null;
    
                    /**
                     * ConfigData mergingConfigPreset.
                     * @member {palexy.streaming.v1.IMergingConfigPreset|null|undefined} mergingConfigPreset
                     * @memberof palexy.streaming.v1.ConfigData
                     * @instance
                     */
                    ConfigData.prototype.mergingConfigPreset = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * ConfigData configData.
                     * @member {"postMergeConfig"|"mergingConfig"|"mergingConfigPreset"|undefined} configData
                     * @memberof palexy.streaming.v1.ConfigData
                     * @instance
                     */
                    Object.defineProperty(ConfigData.prototype, "configData", {
                        get: $util.oneOfGetter($oneOfFields = ["postMergeConfig", "mergingConfig", "mergingConfigPreset"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new ConfigData instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ConfigData
                     * @static
                     * @param {palexy.streaming.v1.IConfigData=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ConfigData} ConfigData instance
                     */
                    ConfigData.create = function create(properties) {
                        return new ConfigData(properties);
                    };
    
                    /**
                     * Encodes the specified ConfigData message. Does not implicitly {@link palexy.streaming.v1.ConfigData.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ConfigData
                     * @static
                     * @param {palexy.streaming.v1.IConfigData} message ConfigData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ConfigData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.postMergeConfig != null && Object.hasOwnProperty.call(message, "postMergeConfig"))
                            $root.palexy.streaming.v1.PostMergeConfig.encode(message.postMergeConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.mergingConfig != null && Object.hasOwnProperty.call(message, "mergingConfig"))
                            $root.palexy.streaming.v1.MergingConfig.encode(message.mergingConfig, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.mergingConfigPreset != null && Object.hasOwnProperty.call(message, "mergingConfigPreset"))
                            $root.palexy.streaming.v1.MergingConfigPreset.encode(message.mergingConfigPreset, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ConfigData message, length delimited. Does not implicitly {@link palexy.streaming.v1.ConfigData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ConfigData
                     * @static
                     * @param {palexy.streaming.v1.IConfigData} message ConfigData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ConfigData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ConfigData message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ConfigData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ConfigData} ConfigData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ConfigData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ConfigData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.postMergeConfig = $root.palexy.streaming.v1.PostMergeConfig.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.mergingConfig = $root.palexy.streaming.v1.MergingConfig.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.mergingConfigPreset = $root.palexy.streaming.v1.MergingConfigPreset.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ConfigData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ConfigData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ConfigData} ConfigData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ConfigData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ConfigData message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ConfigData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ConfigData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.postMergeConfig != null && message.hasOwnProperty("postMergeConfig")) {
                            properties.configData = 1;
                            {
                                var error = $root.palexy.streaming.v1.PostMergeConfig.verify(message.postMergeConfig);
                                if (error)
                                    return "postMergeConfig." + error;
                            }
                        }
                        if (message.mergingConfig != null && message.hasOwnProperty("mergingConfig")) {
                            if (properties.configData === 1)
                                return "configData: multiple values";
                            properties.configData = 1;
                            {
                                var error = $root.palexy.streaming.v1.MergingConfig.verify(message.mergingConfig);
                                if (error)
                                    return "mergingConfig." + error;
                            }
                        }
                        if (message.mergingConfigPreset != null && message.hasOwnProperty("mergingConfigPreset")) {
                            if (properties.configData === 1)
                                return "configData: multiple values";
                            properties.configData = 1;
                            {
                                var error = $root.palexy.streaming.v1.MergingConfigPreset.verify(message.mergingConfigPreset);
                                if (error)
                                    return "mergingConfigPreset." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ConfigData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ConfigData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ConfigData} ConfigData
                     */
                    ConfigData.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ConfigData)
                            return object;
                        var message = new $root.palexy.streaming.v1.ConfigData();
                        if (object.postMergeConfig != null) {
                            if (typeof object.postMergeConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.ConfigData.postMergeConfig: object expected");
                            message.postMergeConfig = $root.palexy.streaming.v1.PostMergeConfig.fromObject(object.postMergeConfig);
                        }
                        if (object.mergingConfig != null) {
                            if (typeof object.mergingConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.ConfigData.mergingConfig: object expected");
                            message.mergingConfig = $root.palexy.streaming.v1.MergingConfig.fromObject(object.mergingConfig);
                        }
                        if (object.mergingConfigPreset != null) {
                            if (typeof object.mergingConfigPreset !== "object")
                                throw TypeError(".palexy.streaming.v1.ConfigData.mergingConfigPreset: object expected");
                            message.mergingConfigPreset = $root.palexy.streaming.v1.MergingConfigPreset.fromObject(object.mergingConfigPreset);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ConfigData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ConfigData
                     * @static
                     * @param {palexy.streaming.v1.ConfigData} message ConfigData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ConfigData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.postMergeConfig != null && message.hasOwnProperty("postMergeConfig")) {
                            object.postMergeConfig = $root.palexy.streaming.v1.PostMergeConfig.toObject(message.postMergeConfig, options);
                            if (options.oneofs)
                                object.configData = "postMergeConfig";
                        }
                        if (message.mergingConfig != null && message.hasOwnProperty("mergingConfig")) {
                            object.mergingConfig = $root.palexy.streaming.v1.MergingConfig.toObject(message.mergingConfig, options);
                            if (options.oneofs)
                                object.configData = "mergingConfig";
                        }
                        if (message.mergingConfigPreset != null && message.hasOwnProperty("mergingConfigPreset")) {
                            object.mergingConfigPreset = $root.palexy.streaming.v1.MergingConfigPreset.toObject(message.mergingConfigPreset, options);
                            if (options.oneofs)
                                object.configData = "mergingConfigPreset";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ConfigData to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ConfigData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ConfigData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ConfigData;
                })();
    
                v1.PostMergeConfig = (function() {
    
                    /**
                     * Properties of a PostMergeConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IPostMergeConfig
                     * @property {boolean|null} [removeSingleCameraTracklet] PostMergeConfig removeSingleCameraTracklet
                     * @property {number|null} [minDurationInSeconds] PostMergeConfig minDurationInSeconds
                     * @property {number|null} [minNumBb] PostMergeConfig minNumBb
                     * @property {boolean|null} [enableSplitOvermerge] PostMergeConfig enableSplitOvermerge
                     * @property {number|null} [splitOvermergeInnerClusterMaxFeatureDistance] PostMergeConfig splitOvermergeInnerClusterMaxFeatureDistance
                     * @property {number|null} [splitOvermergeInnerClusterMaxSameCameraOverlap] PostMergeConfig splitOvermergeInnerClusterMaxSameCameraOverlap
                     * @property {number|null} [splitOvermergeInnerClusterMaxFloorDistanceCrossCamera] PostMergeConfig splitOvermergeInnerClusterMaxFloorDistanceCrossCamera
                     * @property {number|null} [splitOvermergeInnerClusterMaxTimeDistanceSeconds] PostMergeConfig splitOvermergeInnerClusterMaxTimeDistanceSeconds
                     * @property {number|null} [splitOvermergeMinBb] PostMergeConfig splitOvermergeMinBb
                     * @property {boolean|null} [enableRemerge] PostMergeConfig enableRemerge
                     * @property {number|null} [remergeMaxTimeDistanceSeconds] PostMergeConfig remergeMaxTimeDistanceSeconds
                     * @property {number|null} [remergeMinComponentSize] PostMergeConfig remergeMinComponentSize
                     * @property {number|null} [remergeFeatureDistanceThreshold] PostMergeConfig remergeFeatureDistanceThreshold
                     * @property {number|null} [maxSecondsPerBb] PostMergeConfig maxSecondsPerBb
                     */
    
                    /**
                     * Constructs a new PostMergeConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PostMergeConfig.
                     * @implements IPostMergeConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IPostMergeConfig=} [properties] Properties to set
                     */
                    function PostMergeConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PostMergeConfig removeSingleCameraTracklet.
                     * @member {boolean} removeSingleCameraTracklet
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.removeSingleCameraTracklet = false;
    
                    /**
                     * PostMergeConfig minDurationInSeconds.
                     * @member {number} minDurationInSeconds
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.minDurationInSeconds = 0;
    
                    /**
                     * PostMergeConfig minNumBb.
                     * @member {number} minNumBb
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.minNumBb = 0;
    
                    /**
                     * PostMergeConfig enableSplitOvermerge.
                     * @member {boolean} enableSplitOvermerge
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.enableSplitOvermerge = false;
    
                    /**
                     * PostMergeConfig splitOvermergeInnerClusterMaxFeatureDistance.
                     * @member {number} splitOvermergeInnerClusterMaxFeatureDistance
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.splitOvermergeInnerClusterMaxFeatureDistance = 0;
    
                    /**
                     * PostMergeConfig splitOvermergeInnerClusterMaxSameCameraOverlap.
                     * @member {number} splitOvermergeInnerClusterMaxSameCameraOverlap
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.splitOvermergeInnerClusterMaxSameCameraOverlap = 0;
    
                    /**
                     * PostMergeConfig splitOvermergeInnerClusterMaxFloorDistanceCrossCamera.
                     * @member {number} splitOvermergeInnerClusterMaxFloorDistanceCrossCamera
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera = 0;
    
                    /**
                     * PostMergeConfig splitOvermergeInnerClusterMaxTimeDistanceSeconds.
                     * @member {number} splitOvermergeInnerClusterMaxTimeDistanceSeconds
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.splitOvermergeInnerClusterMaxTimeDistanceSeconds = 0;
    
                    /**
                     * PostMergeConfig splitOvermergeMinBb.
                     * @member {number} splitOvermergeMinBb
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.splitOvermergeMinBb = 0;
    
                    /**
                     * PostMergeConfig enableRemerge.
                     * @member {boolean} enableRemerge
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.enableRemerge = false;
    
                    /**
                     * PostMergeConfig remergeMaxTimeDistanceSeconds.
                     * @member {number} remergeMaxTimeDistanceSeconds
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.remergeMaxTimeDistanceSeconds = 0;
    
                    /**
                     * PostMergeConfig remergeMinComponentSize.
                     * @member {number} remergeMinComponentSize
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.remergeMinComponentSize = 0;
    
                    /**
                     * PostMergeConfig remergeFeatureDistanceThreshold.
                     * @member {number} remergeFeatureDistanceThreshold
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.remergeFeatureDistanceThreshold = 0;
    
                    /**
                     * PostMergeConfig maxSecondsPerBb.
                     * @member {number} maxSecondsPerBb
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     */
                    PostMergeConfig.prototype.maxSecondsPerBb = 0;
    
                    /**
                     * Creates a new PostMergeConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @static
                     * @param {palexy.streaming.v1.IPostMergeConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PostMergeConfig} PostMergeConfig instance
                     */
                    PostMergeConfig.create = function create(properties) {
                        return new PostMergeConfig(properties);
                    };
    
                    /**
                     * Encodes the specified PostMergeConfig message. Does not implicitly {@link palexy.streaming.v1.PostMergeConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @static
                     * @param {palexy.streaming.v1.IPostMergeConfig} message PostMergeConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PostMergeConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.removeSingleCameraTracklet != null && Object.hasOwnProperty.call(message, "removeSingleCameraTracklet"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.removeSingleCameraTracklet);
                        if (message.minDurationInSeconds != null && Object.hasOwnProperty.call(message, "minDurationInSeconds"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minDurationInSeconds);
                        if (message.minNumBb != null && Object.hasOwnProperty.call(message, "minNumBb"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.minNumBb);
                        if (message.enableSplitOvermerge != null && Object.hasOwnProperty.call(message, "enableSplitOvermerge"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.enableSplitOvermerge);
                        if (message.splitOvermergeInnerClusterMaxFeatureDistance != null && Object.hasOwnProperty.call(message, "splitOvermergeInnerClusterMaxFeatureDistance"))
                            writer.uint32(/* id 5, wireType 5 =*/45).float(message.splitOvermergeInnerClusterMaxFeatureDistance);
                        if (message.splitOvermergeInnerClusterMaxSameCameraOverlap != null && Object.hasOwnProperty.call(message, "splitOvermergeInnerClusterMaxSameCameraOverlap"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.splitOvermergeInnerClusterMaxSameCameraOverlap);
                        if (message.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera != null && Object.hasOwnProperty.call(message, "splitOvermergeInnerClusterMaxFloorDistanceCrossCamera"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera);
                        if (message.splitOvermergeInnerClusterMaxTimeDistanceSeconds != null && Object.hasOwnProperty.call(message, "splitOvermergeInnerClusterMaxTimeDistanceSeconds"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.splitOvermergeInnerClusterMaxTimeDistanceSeconds);
                        if (message.splitOvermergeMinBb != null && Object.hasOwnProperty.call(message, "splitOvermergeMinBb"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.splitOvermergeMinBb);
                        if (message.enableRemerge != null && Object.hasOwnProperty.call(message, "enableRemerge"))
                            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.enableRemerge);
                        if (message.remergeMaxTimeDistanceSeconds != null && Object.hasOwnProperty.call(message, "remergeMaxTimeDistanceSeconds"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.remergeMaxTimeDistanceSeconds);
                        if (message.remergeMinComponentSize != null && Object.hasOwnProperty.call(message, "remergeMinComponentSize"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.remergeMinComponentSize);
                        if (message.remergeFeatureDistanceThreshold != null && Object.hasOwnProperty.call(message, "remergeFeatureDistanceThreshold"))
                            writer.uint32(/* id 13, wireType 5 =*/109).float(message.remergeFeatureDistanceThreshold);
                        if (message.maxSecondsPerBb != null && Object.hasOwnProperty.call(message, "maxSecondsPerBb"))
                            writer.uint32(/* id 14, wireType 5 =*/117).float(message.maxSecondsPerBb);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PostMergeConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.PostMergeConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @static
                     * @param {palexy.streaming.v1.IPostMergeConfig} message PostMergeConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PostMergeConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PostMergeConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PostMergeConfig} PostMergeConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PostMergeConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PostMergeConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.removeSingleCameraTracklet = reader.bool();
                                break;
                            case 2:
                                message.minDurationInSeconds = reader.int32();
                                break;
                            case 3:
                                message.minNumBb = reader.int32();
                                break;
                            case 4:
                                message.enableSplitOvermerge = reader.bool();
                                break;
                            case 5:
                                message.splitOvermergeInnerClusterMaxFeatureDistance = reader.float();
                                break;
                            case 6:
                                message.splitOvermergeInnerClusterMaxSameCameraOverlap = reader.int32();
                                break;
                            case 7:
                                message.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera = reader.int32();
                                break;
                            case 8:
                                message.splitOvermergeInnerClusterMaxTimeDistanceSeconds = reader.int32();
                                break;
                            case 9:
                                message.splitOvermergeMinBb = reader.int32();
                                break;
                            case 10:
                                message.enableRemerge = reader.bool();
                                break;
                            case 11:
                                message.remergeMaxTimeDistanceSeconds = reader.int32();
                                break;
                            case 12:
                                message.remergeMinComponentSize = reader.int32();
                                break;
                            case 13:
                                message.remergeFeatureDistanceThreshold = reader.float();
                                break;
                            case 14:
                                message.maxSecondsPerBb = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PostMergeConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PostMergeConfig} PostMergeConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PostMergeConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PostMergeConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PostMergeConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.removeSingleCameraTracklet != null && message.hasOwnProperty("removeSingleCameraTracklet"))
                            if (typeof message.removeSingleCameraTracklet !== "boolean")
                                return "removeSingleCameraTracklet: boolean expected";
                        if (message.minDurationInSeconds != null && message.hasOwnProperty("minDurationInSeconds"))
                            if (!$util.isInteger(message.minDurationInSeconds))
                                return "minDurationInSeconds: integer expected";
                        if (message.minNumBb != null && message.hasOwnProperty("minNumBb"))
                            if (!$util.isInteger(message.minNumBb))
                                return "minNumBb: integer expected";
                        if (message.enableSplitOvermerge != null && message.hasOwnProperty("enableSplitOvermerge"))
                            if (typeof message.enableSplitOvermerge !== "boolean")
                                return "enableSplitOvermerge: boolean expected";
                        if (message.splitOvermergeInnerClusterMaxFeatureDistance != null && message.hasOwnProperty("splitOvermergeInnerClusterMaxFeatureDistance"))
                            if (typeof message.splitOvermergeInnerClusterMaxFeatureDistance !== "number")
                                return "splitOvermergeInnerClusterMaxFeatureDistance: number expected";
                        if (message.splitOvermergeInnerClusterMaxSameCameraOverlap != null && message.hasOwnProperty("splitOvermergeInnerClusterMaxSameCameraOverlap"))
                            if (!$util.isInteger(message.splitOvermergeInnerClusterMaxSameCameraOverlap))
                                return "splitOvermergeInnerClusterMaxSameCameraOverlap: integer expected";
                        if (message.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera != null && message.hasOwnProperty("splitOvermergeInnerClusterMaxFloorDistanceCrossCamera"))
                            if (!$util.isInteger(message.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera))
                                return "splitOvermergeInnerClusterMaxFloorDistanceCrossCamera: integer expected";
                        if (message.splitOvermergeInnerClusterMaxTimeDistanceSeconds != null && message.hasOwnProperty("splitOvermergeInnerClusterMaxTimeDistanceSeconds"))
                            if (!$util.isInteger(message.splitOvermergeInnerClusterMaxTimeDistanceSeconds))
                                return "splitOvermergeInnerClusterMaxTimeDistanceSeconds: integer expected";
                        if (message.splitOvermergeMinBb != null && message.hasOwnProperty("splitOvermergeMinBb"))
                            if (!$util.isInteger(message.splitOvermergeMinBb))
                                return "splitOvermergeMinBb: integer expected";
                        if (message.enableRemerge != null && message.hasOwnProperty("enableRemerge"))
                            if (typeof message.enableRemerge !== "boolean")
                                return "enableRemerge: boolean expected";
                        if (message.remergeMaxTimeDistanceSeconds != null && message.hasOwnProperty("remergeMaxTimeDistanceSeconds"))
                            if (!$util.isInteger(message.remergeMaxTimeDistanceSeconds))
                                return "remergeMaxTimeDistanceSeconds: integer expected";
                        if (message.remergeMinComponentSize != null && message.hasOwnProperty("remergeMinComponentSize"))
                            if (!$util.isInteger(message.remergeMinComponentSize))
                                return "remergeMinComponentSize: integer expected";
                        if (message.remergeFeatureDistanceThreshold != null && message.hasOwnProperty("remergeFeatureDistanceThreshold"))
                            if (typeof message.remergeFeatureDistanceThreshold !== "number")
                                return "remergeFeatureDistanceThreshold: number expected";
                        if (message.maxSecondsPerBb != null && message.hasOwnProperty("maxSecondsPerBb"))
                            if (typeof message.maxSecondsPerBb !== "number")
                                return "maxSecondsPerBb: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a PostMergeConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PostMergeConfig} PostMergeConfig
                     */
                    PostMergeConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PostMergeConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.PostMergeConfig();
                        if (object.removeSingleCameraTracklet != null)
                            message.removeSingleCameraTracklet = Boolean(object.removeSingleCameraTracklet);
                        if (object.minDurationInSeconds != null)
                            message.minDurationInSeconds = object.minDurationInSeconds | 0;
                        if (object.minNumBb != null)
                            message.minNumBb = object.minNumBb | 0;
                        if (object.enableSplitOvermerge != null)
                            message.enableSplitOvermerge = Boolean(object.enableSplitOvermerge);
                        if (object.splitOvermergeInnerClusterMaxFeatureDistance != null)
                            message.splitOvermergeInnerClusterMaxFeatureDistance = Number(object.splitOvermergeInnerClusterMaxFeatureDistance);
                        if (object.splitOvermergeInnerClusterMaxSameCameraOverlap != null)
                            message.splitOvermergeInnerClusterMaxSameCameraOverlap = object.splitOvermergeInnerClusterMaxSameCameraOverlap | 0;
                        if (object.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera != null)
                            message.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera = object.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera | 0;
                        if (object.splitOvermergeInnerClusterMaxTimeDistanceSeconds != null)
                            message.splitOvermergeInnerClusterMaxTimeDistanceSeconds = object.splitOvermergeInnerClusterMaxTimeDistanceSeconds | 0;
                        if (object.splitOvermergeMinBb != null)
                            message.splitOvermergeMinBb = object.splitOvermergeMinBb | 0;
                        if (object.enableRemerge != null)
                            message.enableRemerge = Boolean(object.enableRemerge);
                        if (object.remergeMaxTimeDistanceSeconds != null)
                            message.remergeMaxTimeDistanceSeconds = object.remergeMaxTimeDistanceSeconds | 0;
                        if (object.remergeMinComponentSize != null)
                            message.remergeMinComponentSize = object.remergeMinComponentSize | 0;
                        if (object.remergeFeatureDistanceThreshold != null)
                            message.remergeFeatureDistanceThreshold = Number(object.remergeFeatureDistanceThreshold);
                        if (object.maxSecondsPerBb != null)
                            message.maxSecondsPerBb = Number(object.maxSecondsPerBb);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PostMergeConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @static
                     * @param {palexy.streaming.v1.PostMergeConfig} message PostMergeConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PostMergeConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.removeSingleCameraTracklet = false;
                            object.minDurationInSeconds = 0;
                            object.minNumBb = 0;
                            object.enableSplitOvermerge = false;
                            object.splitOvermergeInnerClusterMaxFeatureDistance = 0;
                            object.splitOvermergeInnerClusterMaxSameCameraOverlap = 0;
                            object.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera = 0;
                            object.splitOvermergeInnerClusterMaxTimeDistanceSeconds = 0;
                            object.splitOvermergeMinBb = 0;
                            object.enableRemerge = false;
                            object.remergeMaxTimeDistanceSeconds = 0;
                            object.remergeMinComponentSize = 0;
                            object.remergeFeatureDistanceThreshold = 0;
                            object.maxSecondsPerBb = 0;
                        }
                        if (message.removeSingleCameraTracklet != null && message.hasOwnProperty("removeSingleCameraTracklet"))
                            object.removeSingleCameraTracklet = message.removeSingleCameraTracklet;
                        if (message.minDurationInSeconds != null && message.hasOwnProperty("minDurationInSeconds"))
                            object.minDurationInSeconds = message.minDurationInSeconds;
                        if (message.minNumBb != null && message.hasOwnProperty("minNumBb"))
                            object.minNumBb = message.minNumBb;
                        if (message.enableSplitOvermerge != null && message.hasOwnProperty("enableSplitOvermerge"))
                            object.enableSplitOvermerge = message.enableSplitOvermerge;
                        if (message.splitOvermergeInnerClusterMaxFeatureDistance != null && message.hasOwnProperty("splitOvermergeInnerClusterMaxFeatureDistance"))
                            object.splitOvermergeInnerClusterMaxFeatureDistance = options.json && !isFinite(message.splitOvermergeInnerClusterMaxFeatureDistance) ? String(message.splitOvermergeInnerClusterMaxFeatureDistance) : message.splitOvermergeInnerClusterMaxFeatureDistance;
                        if (message.splitOvermergeInnerClusterMaxSameCameraOverlap != null && message.hasOwnProperty("splitOvermergeInnerClusterMaxSameCameraOverlap"))
                            object.splitOvermergeInnerClusterMaxSameCameraOverlap = message.splitOvermergeInnerClusterMaxSameCameraOverlap;
                        if (message.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera != null && message.hasOwnProperty("splitOvermergeInnerClusterMaxFloorDistanceCrossCamera"))
                            object.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera = message.splitOvermergeInnerClusterMaxFloorDistanceCrossCamera;
                        if (message.splitOvermergeInnerClusterMaxTimeDistanceSeconds != null && message.hasOwnProperty("splitOvermergeInnerClusterMaxTimeDistanceSeconds"))
                            object.splitOvermergeInnerClusterMaxTimeDistanceSeconds = message.splitOvermergeInnerClusterMaxTimeDistanceSeconds;
                        if (message.splitOvermergeMinBb != null && message.hasOwnProperty("splitOvermergeMinBb"))
                            object.splitOvermergeMinBb = message.splitOvermergeMinBb;
                        if (message.enableRemerge != null && message.hasOwnProperty("enableRemerge"))
                            object.enableRemerge = message.enableRemerge;
                        if (message.remergeMaxTimeDistanceSeconds != null && message.hasOwnProperty("remergeMaxTimeDistanceSeconds"))
                            object.remergeMaxTimeDistanceSeconds = message.remergeMaxTimeDistanceSeconds;
                        if (message.remergeMinComponentSize != null && message.hasOwnProperty("remergeMinComponentSize"))
                            object.remergeMinComponentSize = message.remergeMinComponentSize;
                        if (message.remergeFeatureDistanceThreshold != null && message.hasOwnProperty("remergeFeatureDistanceThreshold"))
                            object.remergeFeatureDistanceThreshold = options.json && !isFinite(message.remergeFeatureDistanceThreshold) ? String(message.remergeFeatureDistanceThreshold) : message.remergeFeatureDistanceThreshold;
                        if (message.maxSecondsPerBb != null && message.hasOwnProperty("maxSecondsPerBb"))
                            object.maxSecondsPerBb = options.json && !isFinite(message.maxSecondsPerBb) ? String(message.maxSecondsPerBb) : message.maxSecondsPerBb;
                        return object;
                    };
    
                    /**
                     * Converts this PostMergeConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PostMergeConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PostMergeConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PostMergeConfig;
                })();
    
                v1.StoreConfigService = (function() {
    
                    /**
                     * Constructs a new StoreConfigService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreConfigService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StoreConfigService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (StoreConfigService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StoreConfigService;
    
                    /**
                     * Creates new StoreConfigService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StoreConfigService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StoreConfigService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#createStoreConfig}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef CreateStoreConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreConfig} [response] StoreConfig
                     */
    
                    /**
                     * Calls CreateStoreConfig.
                     * @function createStoreConfig
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStoreConfigRequest} request CreateStoreConfigRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.CreateStoreConfigCallback} callback Node-style callback called with the error, if any, and StoreConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.createStoreConfig = function createStoreConfig(request, callback) {
                        return this.rpcCall(createStoreConfig, $root.palexy.streaming.v1.CreateStoreConfigRequest, $root.palexy.streaming.v1.StoreConfig, request, callback);
                    }, "name", { value: "CreateStoreConfig" });
    
                    /**
                     * Calls CreateStoreConfig.
                     * @function createStoreConfig
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStoreConfigRequest} request CreateStoreConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreConfig>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#listStoreConfigs}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef ListStoreConfigsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStoreConfigsResponse} [response] ListStoreConfigsResponse
                     */
    
                    /**
                     * Calls ListStoreConfigs.
                     * @function listStoreConfigs
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreConfigsRequest} request ListStoreConfigsRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.ListStoreConfigsCallback} callback Node-style callback called with the error, if any, and ListStoreConfigsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.listStoreConfigs = function listStoreConfigs(request, callback) {
                        return this.rpcCall(listStoreConfigs, $root.palexy.streaming.v1.ListStoreConfigsRequest, $root.palexy.streaming.v1.ListStoreConfigsResponse, request, callback);
                    }, "name", { value: "ListStoreConfigs" });
    
                    /**
                     * Calls ListStoreConfigs.
                     * @function listStoreConfigs
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreConfigsRequest} request ListStoreConfigsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStoreConfigsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#getStoreConfig}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef GetStoreConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreConfig} [response] StoreConfig
                     */
    
                    /**
                     * Calls GetStoreConfig.
                     * @function getStoreConfig
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoreConfigRequest} request GetStoreConfigRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.GetStoreConfigCallback} callback Node-style callback called with the error, if any, and StoreConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.getStoreConfig = function getStoreConfig(request, callback) {
                        return this.rpcCall(getStoreConfig, $root.palexy.streaming.v1.GetStoreConfigRequest, $root.palexy.streaming.v1.StoreConfig, request, callback);
                    }, "name", { value: "GetStoreConfig" });
    
                    /**
                     * Calls GetStoreConfig.
                     * @function getStoreConfig
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoreConfigRequest} request GetStoreConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreConfig>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#updateStoreConfig}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef UpdateStoreConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreConfig} [response] StoreConfig
                     */
    
                    /**
                     * Calls UpdateStoreConfig.
                     * @function updateStoreConfig
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStoreConfigRequest} request UpdateStoreConfigRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.UpdateStoreConfigCallback} callback Node-style callback called with the error, if any, and StoreConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.updateStoreConfig = function updateStoreConfig(request, callback) {
                        return this.rpcCall(updateStoreConfig, $root.palexy.streaming.v1.UpdateStoreConfigRequest, $root.palexy.streaming.v1.StoreConfig, request, callback);
                    }, "name", { value: "UpdateStoreConfig" });
    
                    /**
                     * Calls UpdateStoreConfig.
                     * @function updateStoreConfig
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStoreConfigRequest} request UpdateStoreConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreConfig>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#createStoreConfigItem}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef CreateStoreConfigItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreConfigItem} [response] StoreConfigItem
                     */
    
                    /**
                     * Calls CreateStoreConfigItem.
                     * @function createStoreConfigItem
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStoreConfigItemRequest} request CreateStoreConfigItemRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.CreateStoreConfigItemCallback} callback Node-style callback called with the error, if any, and StoreConfigItem
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.createStoreConfigItem = function createStoreConfigItem(request, callback) {
                        return this.rpcCall(createStoreConfigItem, $root.palexy.streaming.v1.CreateStoreConfigItemRequest, $root.palexy.streaming.v1.StoreConfigItem, request, callback);
                    }, "name", { value: "CreateStoreConfigItem" });
    
                    /**
                     * Calls CreateStoreConfigItem.
                     * @function createStoreConfigItem
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStoreConfigItemRequest} request CreateStoreConfigItemRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreConfigItem>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#listStoreConfigItems}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef ListStoreConfigItemsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStoreConfigItemsResponse} [response] ListStoreConfigItemsResponse
                     */
    
                    /**
                     * Calls ListStoreConfigItems.
                     * @function listStoreConfigItems
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreConfigItemsRequest} request ListStoreConfigItemsRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.ListStoreConfigItemsCallback} callback Node-style callback called with the error, if any, and ListStoreConfigItemsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.listStoreConfigItems = function listStoreConfigItems(request, callback) {
                        return this.rpcCall(listStoreConfigItems, $root.palexy.streaming.v1.ListStoreConfigItemsRequest, $root.palexy.streaming.v1.ListStoreConfigItemsResponse, request, callback);
                    }, "name", { value: "ListStoreConfigItems" });
    
                    /**
                     * Calls ListStoreConfigItems.
                     * @function listStoreConfigItems
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreConfigItemsRequest} request ListStoreConfigItemsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStoreConfigItemsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#getStoreConfigItem}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef GetStoreConfigItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreConfigItem} [response] StoreConfigItem
                     */
    
                    /**
                     * Calls GetStoreConfigItem.
                     * @function getStoreConfigItem
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoreConfigItemRequest} request GetStoreConfigItemRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.GetStoreConfigItemCallback} callback Node-style callback called with the error, if any, and StoreConfigItem
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.getStoreConfigItem = function getStoreConfigItem(request, callback) {
                        return this.rpcCall(getStoreConfigItem, $root.palexy.streaming.v1.GetStoreConfigItemRequest, $root.palexy.streaming.v1.StoreConfigItem, request, callback);
                    }, "name", { value: "GetStoreConfigItem" });
    
                    /**
                     * Calls GetStoreConfigItem.
                     * @function getStoreConfigItem
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoreConfigItemRequest} request GetStoreConfigItemRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreConfigItem>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#findProductionStoreConfigItemByCameraIdAndDateId}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef FindProductionStoreConfigItemByCameraIdAndDateIdCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreConfigItem} [response] StoreConfigItem
                     */
    
                    /**
                     * Calls FindProductionStoreConfigItemByCameraIdAndDateId.
                     * @function findProductionStoreConfigItemByCameraIdAndDateId
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IFindProductionStoreConfigItemByCameraIdAndDateIdRequest} request FindProductionStoreConfigItemByCameraIdAndDateIdRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.FindProductionStoreConfigItemByCameraIdAndDateIdCallback} callback Node-style callback called with the error, if any, and StoreConfigItem
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.findProductionStoreConfigItemByCameraIdAndDateId = function findProductionStoreConfigItemByCameraIdAndDateId(request, callback) {
                        return this.rpcCall(findProductionStoreConfigItemByCameraIdAndDateId, $root.palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest, $root.palexy.streaming.v1.StoreConfigItem, request, callback);
                    }, "name", { value: "FindProductionStoreConfigItemByCameraIdAndDateId" });
    
                    /**
                     * Calls FindProductionStoreConfigItemByCameraIdAndDateId.
                     * @function findProductionStoreConfigItemByCameraIdAndDateId
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IFindProductionStoreConfigItemByCameraIdAndDateIdRequest} request FindProductionStoreConfigItemByCameraIdAndDateIdRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreConfigItem>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#updateStoreConfigItem}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef UpdateStoreConfigItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreConfigItem} [response] StoreConfigItem
                     */
    
                    /**
                     * Calls UpdateStoreConfigItem.
                     * @function updateStoreConfigItem
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStoreConfigItemRequest} request UpdateStoreConfigItemRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.UpdateStoreConfigItemCallback} callback Node-style callback called with the error, if any, and StoreConfigItem
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.updateStoreConfigItem = function updateStoreConfigItem(request, callback) {
                        return this.rpcCall(updateStoreConfigItem, $root.palexy.streaming.v1.UpdateStoreConfigItemRequest, $root.palexy.streaming.v1.StoreConfigItem, request, callback);
                    }, "name", { value: "UpdateStoreConfigItem" });
    
                    /**
                     * Calls UpdateStoreConfigItem.
                     * @function updateStoreConfigItem
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStoreConfigItemRequest} request UpdateStoreConfigItemRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreConfigItem>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#deleteStoreConfigItem}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef DeleteStoreConfigItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteStoreConfigItem.
                     * @function deleteStoreConfigItem
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteStoreConfigItemRequest} request DeleteStoreConfigItemRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.DeleteStoreConfigItemCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.deleteStoreConfigItem = function deleteStoreConfigItem(request, callback) {
                        return this.rpcCall(deleteStoreConfigItem, $root.palexy.streaming.v1.DeleteStoreConfigItemRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteStoreConfigItem" });
    
                    /**
                     * Calls DeleteStoreConfigItem.
                     * @function deleteStoreConfigItem
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteStoreConfigItemRequest} request DeleteStoreConfigItemRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#createMergingConfigPreset}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef CreateMergingConfigPresetCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.MergingConfigPreset} [response] MergingConfigPreset
                     */
    
                    /**
                     * Calls CreateMergingConfigPreset.
                     * @function createMergingConfigPreset
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateMergingConfigPresetRequest} request CreateMergingConfigPresetRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.CreateMergingConfigPresetCallback} callback Node-style callback called with the error, if any, and MergingConfigPreset
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.createMergingConfigPreset = function createMergingConfigPreset(request, callback) {
                        return this.rpcCall(createMergingConfigPreset, $root.palexy.streaming.v1.CreateMergingConfigPresetRequest, $root.palexy.streaming.v1.MergingConfigPreset, request, callback);
                    }, "name", { value: "CreateMergingConfigPreset" });
    
                    /**
                     * Calls CreateMergingConfigPreset.
                     * @function createMergingConfigPreset
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateMergingConfigPresetRequest} request CreateMergingConfigPresetRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.MergingConfigPreset>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#listMergingConfigPresets}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef ListMergingConfigPresetsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListMergingConfigPresetsResponse} [response] ListMergingConfigPresetsResponse
                     */
    
                    /**
                     * Calls ListMergingConfigPresets.
                     * @function listMergingConfigPresets
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IListMergingConfigPresetsRequest} request ListMergingConfigPresetsRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.ListMergingConfigPresetsCallback} callback Node-style callback called with the error, if any, and ListMergingConfigPresetsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.listMergingConfigPresets = function listMergingConfigPresets(request, callback) {
                        return this.rpcCall(listMergingConfigPresets, $root.palexy.streaming.v1.ListMergingConfigPresetsRequest, $root.palexy.streaming.v1.ListMergingConfigPresetsResponse, request, callback);
                    }, "name", { value: "ListMergingConfigPresets" });
    
                    /**
                     * Calls ListMergingConfigPresets.
                     * @function listMergingConfigPresets
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IListMergingConfigPresetsRequest} request ListMergingConfigPresetsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListMergingConfigPresetsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#getMergingConfigPreset}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef GetMergingConfigPresetCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.MergingConfigPreset} [response] MergingConfigPreset
                     */
    
                    /**
                     * Calls GetMergingConfigPreset.
                     * @function getMergingConfigPreset
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IGetMergingConfigPresetRequest} request GetMergingConfigPresetRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.GetMergingConfigPresetCallback} callback Node-style callback called with the error, if any, and MergingConfigPreset
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.getMergingConfigPreset = function getMergingConfigPreset(request, callback) {
                        return this.rpcCall(getMergingConfigPreset, $root.palexy.streaming.v1.GetMergingConfigPresetRequest, $root.palexy.streaming.v1.MergingConfigPreset, request, callback);
                    }, "name", { value: "GetMergingConfigPreset" });
    
                    /**
                     * Calls GetMergingConfigPreset.
                     * @function getMergingConfigPreset
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IGetMergingConfigPresetRequest} request GetMergingConfigPresetRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.MergingConfigPreset>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#updateMergingConfigPreset}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef UpdateMergingConfigPresetCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.MergingConfigPreset} [response] MergingConfigPreset
                     */
    
                    /**
                     * Calls UpdateMergingConfigPreset.
                     * @function updateMergingConfigPreset
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateMergingConfigPresetRequest} request UpdateMergingConfigPresetRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.UpdateMergingConfigPresetCallback} callback Node-style callback called with the error, if any, and MergingConfigPreset
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.updateMergingConfigPreset = function updateMergingConfigPreset(request, callback) {
                        return this.rpcCall(updateMergingConfigPreset, $root.palexy.streaming.v1.UpdateMergingConfigPresetRequest, $root.palexy.streaming.v1.MergingConfigPreset, request, callback);
                    }, "name", { value: "UpdateMergingConfigPreset" });
    
                    /**
                     * Calls UpdateMergingConfigPreset.
                     * @function updateMergingConfigPreset
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateMergingConfigPresetRequest} request UpdateMergingConfigPresetRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.MergingConfigPreset>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreConfigService#deleteMergingConfigPreset}.
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @typedef DeleteMergingConfigPresetCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteMergingConfigPreset.
                     * @function deleteMergingConfigPreset
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteMergingConfigPresetRequest} request DeleteMergingConfigPresetRequest message or plain object
                     * @param {palexy.streaming.v1.StoreConfigService.DeleteMergingConfigPresetCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreConfigService.prototype.deleteMergingConfigPreset = function deleteMergingConfigPreset(request, callback) {
                        return this.rpcCall(deleteMergingConfigPreset, $root.palexy.streaming.v1.DeleteMergingConfigPresetRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteMergingConfigPreset" });
    
                    /**
                     * Calls DeleteMergingConfigPreset.
                     * @function deleteMergingConfigPreset
                     * @memberof palexy.streaming.v1.StoreConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteMergingConfigPresetRequest} request DeleteMergingConfigPresetRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return StoreConfigService;
                })();
    
                v1.CreateMergingConfigPresetRequest = (function() {
    
                    /**
                     * Properties of a CreateMergingConfigPresetRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateMergingConfigPresetRequest
                     * @property {palexy.streaming.v1.IMergingConfigPreset|null} [mergingConfigPreset] CreateMergingConfigPresetRequest mergingConfigPreset
                     */
    
                    /**
                     * Constructs a new CreateMergingConfigPresetRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateMergingConfigPresetRequest.
                     * @implements ICreateMergingConfigPresetRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateMergingConfigPresetRequest=} [properties] Properties to set
                     */
                    function CreateMergingConfigPresetRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateMergingConfigPresetRequest mergingConfigPreset.
                     * @member {palexy.streaming.v1.IMergingConfigPreset|null|undefined} mergingConfigPreset
                     * @memberof palexy.streaming.v1.CreateMergingConfigPresetRequest
                     * @instance
                     */
                    CreateMergingConfigPresetRequest.prototype.mergingConfigPreset = null;
    
                    /**
                     * Creates a new CreateMergingConfigPresetRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateMergingConfigPresetRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateMergingConfigPresetRequest} CreateMergingConfigPresetRequest instance
                     */
                    CreateMergingConfigPresetRequest.create = function create(properties) {
                        return new CreateMergingConfigPresetRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateMergingConfigPresetRequest message. Does not implicitly {@link palexy.streaming.v1.CreateMergingConfigPresetRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateMergingConfigPresetRequest} message CreateMergingConfigPresetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateMergingConfigPresetRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergingConfigPreset != null && Object.hasOwnProperty.call(message, "mergingConfigPreset"))
                            $root.palexy.streaming.v1.MergingConfigPreset.encode(message.mergingConfigPreset, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateMergingConfigPresetRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateMergingConfigPresetRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateMergingConfigPresetRequest} message CreateMergingConfigPresetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateMergingConfigPresetRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateMergingConfigPresetRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateMergingConfigPresetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateMergingConfigPresetRequest} CreateMergingConfigPresetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateMergingConfigPresetRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateMergingConfigPresetRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.mergingConfigPreset = $root.palexy.streaming.v1.MergingConfigPreset.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateMergingConfigPresetRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateMergingConfigPresetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateMergingConfigPresetRequest} CreateMergingConfigPresetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateMergingConfigPresetRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateMergingConfigPresetRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateMergingConfigPresetRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateMergingConfigPresetRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergingConfigPreset != null && message.hasOwnProperty("mergingConfigPreset")) {
                            var error = $root.palexy.streaming.v1.MergingConfigPreset.verify(message.mergingConfigPreset);
                            if (error)
                                return "mergingConfigPreset." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateMergingConfigPresetRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateMergingConfigPresetRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateMergingConfigPresetRequest} CreateMergingConfigPresetRequest
                     */
                    CreateMergingConfigPresetRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateMergingConfigPresetRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateMergingConfigPresetRequest();
                        if (object.mergingConfigPreset != null) {
                            if (typeof object.mergingConfigPreset !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateMergingConfigPresetRequest.mergingConfigPreset: object expected");
                            message.mergingConfigPreset = $root.palexy.streaming.v1.MergingConfigPreset.fromObject(object.mergingConfigPreset);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateMergingConfigPresetRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateMergingConfigPresetRequest} message CreateMergingConfigPresetRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateMergingConfigPresetRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.mergingConfigPreset = null;
                        if (message.mergingConfigPreset != null && message.hasOwnProperty("mergingConfigPreset"))
                            object.mergingConfigPreset = $root.palexy.streaming.v1.MergingConfigPreset.toObject(message.mergingConfigPreset, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateMergingConfigPresetRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateMergingConfigPresetRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateMergingConfigPresetRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateMergingConfigPresetRequest;
                })();
    
                v1.ListMergingConfigPresetsRequest = (function() {
    
                    /**
                     * Properties of a ListMergingConfigPresetsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListMergingConfigPresetsRequest
                     * @property {google.protobuf.IInt64Value|null} [storeId] ListMergingConfigPresetsRequest storeId
                     * @property {boolean|null} [isForSuggestion] ListMergingConfigPresetsRequest isForSuggestion
                     */
    
                    /**
                     * Constructs a new ListMergingConfigPresetsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListMergingConfigPresetsRequest.
                     * @implements IListMergingConfigPresetsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListMergingConfigPresetsRequest=} [properties] Properties to set
                     */
                    function ListMergingConfigPresetsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListMergingConfigPresetsRequest storeId.
                     * @member {google.protobuf.IInt64Value|null|undefined} storeId
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsRequest
                     * @instance
                     */
                    ListMergingConfigPresetsRequest.prototype.storeId = null;
    
                    /**
                     * ListMergingConfigPresetsRequest isForSuggestion.
                     * @member {boolean} isForSuggestion
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsRequest
                     * @instance
                     */
                    ListMergingConfigPresetsRequest.prototype.isForSuggestion = false;
    
                    /**
                     * Creates a new ListMergingConfigPresetsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListMergingConfigPresetsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListMergingConfigPresetsRequest} ListMergingConfigPresetsRequest instance
                     */
                    ListMergingConfigPresetsRequest.create = function create(properties) {
                        return new ListMergingConfigPresetsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListMergingConfigPresetsRequest message. Does not implicitly {@link palexy.streaming.v1.ListMergingConfigPresetsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListMergingConfigPresetsRequest} message ListMergingConfigPresetsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMergingConfigPresetsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            $root.google.protobuf.Int64Value.encode(message.storeId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.isForSuggestion != null && Object.hasOwnProperty.call(message, "isForSuggestion"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isForSuggestion);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListMergingConfigPresetsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListMergingConfigPresetsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListMergingConfigPresetsRequest} message ListMergingConfigPresetsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMergingConfigPresetsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListMergingConfigPresetsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListMergingConfigPresetsRequest} ListMergingConfigPresetsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMergingConfigPresetsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListMergingConfigPresetsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.isForSuggestion = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListMergingConfigPresetsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListMergingConfigPresetsRequest} ListMergingConfigPresetsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMergingConfigPresetsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListMergingConfigPresetsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListMergingConfigPresetsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.storeId);
                            if (error)
                                return "storeId." + error;
                        }
                        if (message.isForSuggestion != null && message.hasOwnProperty("isForSuggestion"))
                            if (typeof message.isForSuggestion !== "boolean")
                                return "isForSuggestion: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListMergingConfigPresetsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListMergingConfigPresetsRequest} ListMergingConfigPresetsRequest
                     */
                    ListMergingConfigPresetsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListMergingConfigPresetsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListMergingConfigPresetsRequest();
                        if (object.storeId != null) {
                            if (typeof object.storeId !== "object")
                                throw TypeError(".palexy.streaming.v1.ListMergingConfigPresetsRequest.storeId: object expected");
                            message.storeId = $root.google.protobuf.Int64Value.fromObject(object.storeId);
                        }
                        if (object.isForSuggestion != null)
                            message.isForSuggestion = Boolean(object.isForSuggestion);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListMergingConfigPresetsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsRequest
                     * @static
                     * @param {palexy.streaming.v1.ListMergingConfigPresetsRequest} message ListMergingConfigPresetsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListMergingConfigPresetsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.storeId = null;
                            object.isForSuggestion = false;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = $root.google.protobuf.Int64Value.toObject(message.storeId, options);
                        if (message.isForSuggestion != null && message.hasOwnProperty("isForSuggestion"))
                            object.isForSuggestion = message.isForSuggestion;
                        return object;
                    };
    
                    /**
                     * Converts this ListMergingConfigPresetsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListMergingConfigPresetsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListMergingConfigPresetsRequest;
                })();
    
                v1.ListMergingConfigPresetsResponse = (function() {
    
                    /**
                     * Properties of a ListMergingConfigPresetsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListMergingConfigPresetsResponse
                     * @property {Array.<palexy.streaming.v1.IMergingConfigPreset>|null} [mergingConfigPresets] ListMergingConfigPresetsResponse mergingConfigPresets
                     */
    
                    /**
                     * Constructs a new ListMergingConfigPresetsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListMergingConfigPresetsResponse.
                     * @implements IListMergingConfigPresetsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListMergingConfigPresetsResponse=} [properties] Properties to set
                     */
                    function ListMergingConfigPresetsResponse(properties) {
                        this.mergingConfigPresets = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListMergingConfigPresetsResponse mergingConfigPresets.
                     * @member {Array.<palexy.streaming.v1.IMergingConfigPreset>} mergingConfigPresets
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsResponse
                     * @instance
                     */
                    ListMergingConfigPresetsResponse.prototype.mergingConfigPresets = $util.emptyArray;
    
                    /**
                     * Creates a new ListMergingConfigPresetsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListMergingConfigPresetsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListMergingConfigPresetsResponse} ListMergingConfigPresetsResponse instance
                     */
                    ListMergingConfigPresetsResponse.create = function create(properties) {
                        return new ListMergingConfigPresetsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListMergingConfigPresetsResponse message. Does not implicitly {@link palexy.streaming.v1.ListMergingConfigPresetsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListMergingConfigPresetsResponse} message ListMergingConfigPresetsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMergingConfigPresetsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergingConfigPresets != null && message.mergingConfigPresets.length)
                            for (var i = 0; i < message.mergingConfigPresets.length; ++i)
                                $root.palexy.streaming.v1.MergingConfigPreset.encode(message.mergingConfigPresets[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListMergingConfigPresetsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListMergingConfigPresetsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListMergingConfigPresetsResponse} message ListMergingConfigPresetsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMergingConfigPresetsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListMergingConfigPresetsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListMergingConfigPresetsResponse} ListMergingConfigPresetsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMergingConfigPresetsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListMergingConfigPresetsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.mergingConfigPresets && message.mergingConfigPresets.length))
                                    message.mergingConfigPresets = [];
                                message.mergingConfigPresets.push($root.palexy.streaming.v1.MergingConfigPreset.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListMergingConfigPresetsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListMergingConfigPresetsResponse} ListMergingConfigPresetsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMergingConfigPresetsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListMergingConfigPresetsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListMergingConfigPresetsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergingConfigPresets != null && message.hasOwnProperty("mergingConfigPresets")) {
                            if (!Array.isArray(message.mergingConfigPresets))
                                return "mergingConfigPresets: array expected";
                            for (var i = 0; i < message.mergingConfigPresets.length; ++i) {
                                var error = $root.palexy.streaming.v1.MergingConfigPreset.verify(message.mergingConfigPresets[i]);
                                if (error)
                                    return "mergingConfigPresets." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListMergingConfigPresetsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListMergingConfigPresetsResponse} ListMergingConfigPresetsResponse
                     */
                    ListMergingConfigPresetsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListMergingConfigPresetsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListMergingConfigPresetsResponse();
                        if (object.mergingConfigPresets) {
                            if (!Array.isArray(object.mergingConfigPresets))
                                throw TypeError(".palexy.streaming.v1.ListMergingConfigPresetsResponse.mergingConfigPresets: array expected");
                            message.mergingConfigPresets = [];
                            for (var i = 0; i < object.mergingConfigPresets.length; ++i) {
                                if (typeof object.mergingConfigPresets[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListMergingConfigPresetsResponse.mergingConfigPresets: object expected");
                                message.mergingConfigPresets[i] = $root.palexy.streaming.v1.MergingConfigPreset.fromObject(object.mergingConfigPresets[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListMergingConfigPresetsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListMergingConfigPresetsResponse} message ListMergingConfigPresetsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListMergingConfigPresetsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.mergingConfigPresets = [];
                        if (message.mergingConfigPresets && message.mergingConfigPresets.length) {
                            object.mergingConfigPresets = [];
                            for (var j = 0; j < message.mergingConfigPresets.length; ++j)
                                object.mergingConfigPresets[j] = $root.palexy.streaming.v1.MergingConfigPreset.toObject(message.mergingConfigPresets[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListMergingConfigPresetsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListMergingConfigPresetsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListMergingConfigPresetsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListMergingConfigPresetsResponse;
                })();
    
                v1.GetMergingConfigPresetRequest = (function() {
    
                    /**
                     * Properties of a GetMergingConfigPresetRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetMergingConfigPresetRequest
                     * @property {number|null} [mergingConfigPresetId] GetMergingConfigPresetRequest mergingConfigPresetId
                     */
    
                    /**
                     * Constructs a new GetMergingConfigPresetRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetMergingConfigPresetRequest.
                     * @implements IGetMergingConfigPresetRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetMergingConfigPresetRequest=} [properties] Properties to set
                     */
                    function GetMergingConfigPresetRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetMergingConfigPresetRequest mergingConfigPresetId.
                     * @member {number} mergingConfigPresetId
                     * @memberof palexy.streaming.v1.GetMergingConfigPresetRequest
                     * @instance
                     */
                    GetMergingConfigPresetRequest.prototype.mergingConfigPresetId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetMergingConfigPresetRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetMergingConfigPresetRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetMergingConfigPresetRequest} GetMergingConfigPresetRequest instance
                     */
                    GetMergingConfigPresetRequest.create = function create(properties) {
                        return new GetMergingConfigPresetRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetMergingConfigPresetRequest message. Does not implicitly {@link palexy.streaming.v1.GetMergingConfigPresetRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetMergingConfigPresetRequest} message GetMergingConfigPresetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetMergingConfigPresetRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergingConfigPresetId != null && Object.hasOwnProperty.call(message, "mergingConfigPresetId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.mergingConfigPresetId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetMergingConfigPresetRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetMergingConfigPresetRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetMergingConfigPresetRequest} message GetMergingConfigPresetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetMergingConfigPresetRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetMergingConfigPresetRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetMergingConfigPresetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetMergingConfigPresetRequest} GetMergingConfigPresetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetMergingConfigPresetRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetMergingConfigPresetRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.mergingConfigPresetId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetMergingConfigPresetRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetMergingConfigPresetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetMergingConfigPresetRequest} GetMergingConfigPresetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetMergingConfigPresetRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetMergingConfigPresetRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetMergingConfigPresetRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetMergingConfigPresetRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergingConfigPresetId != null && message.hasOwnProperty("mergingConfigPresetId"))
                            if (!$util.isInteger(message.mergingConfigPresetId) && !(message.mergingConfigPresetId && $util.isInteger(message.mergingConfigPresetId.low) && $util.isInteger(message.mergingConfigPresetId.high)))
                                return "mergingConfigPresetId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetMergingConfigPresetRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetMergingConfigPresetRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetMergingConfigPresetRequest} GetMergingConfigPresetRequest
                     */
                    GetMergingConfigPresetRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetMergingConfigPresetRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetMergingConfigPresetRequest();
                        if (object.mergingConfigPresetId != null)
                            if ($util.Long)
                                (message.mergingConfigPresetId = $util.Long.fromValue(object.mergingConfigPresetId)).unsigned = false;
                            else if (typeof object.mergingConfigPresetId === "string")
                                message.mergingConfigPresetId = parseInt(object.mergingConfigPresetId, 10);
                            else if (typeof object.mergingConfigPresetId === "number")
                                message.mergingConfigPresetId = object.mergingConfigPresetId;
                            else if (typeof object.mergingConfigPresetId === "object")
                                message.mergingConfigPresetId = new $util.LongBits(object.mergingConfigPresetId.low >>> 0, object.mergingConfigPresetId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetMergingConfigPresetRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.GetMergingConfigPresetRequest} message GetMergingConfigPresetRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetMergingConfigPresetRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.mergingConfigPresetId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.mergingConfigPresetId = options.longs === String ? "0" : 0;
                        if (message.mergingConfigPresetId != null && message.hasOwnProperty("mergingConfigPresetId"))
                            if (typeof message.mergingConfigPresetId === "number")
                                object.mergingConfigPresetId = options.longs === String ? String(message.mergingConfigPresetId) : message.mergingConfigPresetId;
                            else
                                object.mergingConfigPresetId = options.longs === String ? $util.Long.prototype.toString.call(message.mergingConfigPresetId) : options.longs === Number ? new $util.LongBits(message.mergingConfigPresetId.low >>> 0, message.mergingConfigPresetId.high >>> 0).toNumber() : message.mergingConfigPresetId;
                        return object;
                    };
    
                    /**
                     * Converts this GetMergingConfigPresetRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetMergingConfigPresetRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetMergingConfigPresetRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetMergingConfigPresetRequest;
                })();
    
                v1.UpdateMergingConfigPresetRequest = (function() {
    
                    /**
                     * Properties of an UpdateMergingConfigPresetRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateMergingConfigPresetRequest
                     * @property {palexy.streaming.v1.IMergingConfigPreset|null} [mergingConfigPreset] UpdateMergingConfigPresetRequest mergingConfigPreset
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateMergingConfigPresetRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateMergingConfigPresetRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateMergingConfigPresetRequest.
                     * @implements IUpdateMergingConfigPresetRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateMergingConfigPresetRequest=} [properties] Properties to set
                     */
                    function UpdateMergingConfigPresetRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateMergingConfigPresetRequest mergingConfigPreset.
                     * @member {palexy.streaming.v1.IMergingConfigPreset|null|undefined} mergingConfigPreset
                     * @memberof palexy.streaming.v1.UpdateMergingConfigPresetRequest
                     * @instance
                     */
                    UpdateMergingConfigPresetRequest.prototype.mergingConfigPreset = null;
    
                    /**
                     * UpdateMergingConfigPresetRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateMergingConfigPresetRequest
                     * @instance
                     */
                    UpdateMergingConfigPresetRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateMergingConfigPresetRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateMergingConfigPresetRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateMergingConfigPresetRequest} UpdateMergingConfigPresetRequest instance
                     */
                    UpdateMergingConfigPresetRequest.create = function create(properties) {
                        return new UpdateMergingConfigPresetRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateMergingConfigPresetRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateMergingConfigPresetRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateMergingConfigPresetRequest} message UpdateMergingConfigPresetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateMergingConfigPresetRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergingConfigPreset != null && Object.hasOwnProperty.call(message, "mergingConfigPreset"))
                            $root.palexy.streaming.v1.MergingConfigPreset.encode(message.mergingConfigPreset, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateMergingConfigPresetRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateMergingConfigPresetRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateMergingConfigPresetRequest} message UpdateMergingConfigPresetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateMergingConfigPresetRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateMergingConfigPresetRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateMergingConfigPresetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateMergingConfigPresetRequest} UpdateMergingConfigPresetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateMergingConfigPresetRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateMergingConfigPresetRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.mergingConfigPreset = $root.palexy.streaming.v1.MergingConfigPreset.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateMergingConfigPresetRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateMergingConfigPresetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateMergingConfigPresetRequest} UpdateMergingConfigPresetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateMergingConfigPresetRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateMergingConfigPresetRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateMergingConfigPresetRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateMergingConfigPresetRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergingConfigPreset != null && message.hasOwnProperty("mergingConfigPreset")) {
                            var error = $root.palexy.streaming.v1.MergingConfigPreset.verify(message.mergingConfigPreset);
                            if (error)
                                return "mergingConfigPreset." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateMergingConfigPresetRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateMergingConfigPresetRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateMergingConfigPresetRequest} UpdateMergingConfigPresetRequest
                     */
                    UpdateMergingConfigPresetRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateMergingConfigPresetRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateMergingConfigPresetRequest();
                        if (object.mergingConfigPreset != null) {
                            if (typeof object.mergingConfigPreset !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateMergingConfigPresetRequest.mergingConfigPreset: object expected");
                            message.mergingConfigPreset = $root.palexy.streaming.v1.MergingConfigPreset.fromObject(object.mergingConfigPreset);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateMergingConfigPresetRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateMergingConfigPresetRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateMergingConfigPresetRequest} message UpdateMergingConfigPresetRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateMergingConfigPresetRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.mergingConfigPreset = null;
                            object.updateMask = null;
                        }
                        if (message.mergingConfigPreset != null && message.hasOwnProperty("mergingConfigPreset"))
                            object.mergingConfigPreset = $root.palexy.streaming.v1.MergingConfigPreset.toObject(message.mergingConfigPreset, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateMergingConfigPresetRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateMergingConfigPresetRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateMergingConfigPresetRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateMergingConfigPresetRequest;
                })();
    
                v1.DeleteMergingConfigPresetRequest = (function() {
    
                    /**
                     * Properties of a DeleteMergingConfigPresetRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteMergingConfigPresetRequest
                     * @property {number|null} [mergingConfigPresetId] DeleteMergingConfigPresetRequest mergingConfigPresetId
                     */
    
                    /**
                     * Constructs a new DeleteMergingConfigPresetRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteMergingConfigPresetRequest.
                     * @implements IDeleteMergingConfigPresetRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteMergingConfigPresetRequest=} [properties] Properties to set
                     */
                    function DeleteMergingConfigPresetRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteMergingConfigPresetRequest mergingConfigPresetId.
                     * @member {number} mergingConfigPresetId
                     * @memberof palexy.streaming.v1.DeleteMergingConfigPresetRequest
                     * @instance
                     */
                    DeleteMergingConfigPresetRequest.prototype.mergingConfigPresetId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteMergingConfigPresetRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteMergingConfigPresetRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteMergingConfigPresetRequest} DeleteMergingConfigPresetRequest instance
                     */
                    DeleteMergingConfigPresetRequest.create = function create(properties) {
                        return new DeleteMergingConfigPresetRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteMergingConfigPresetRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteMergingConfigPresetRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteMergingConfigPresetRequest} message DeleteMergingConfigPresetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteMergingConfigPresetRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mergingConfigPresetId != null && Object.hasOwnProperty.call(message, "mergingConfigPresetId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.mergingConfigPresetId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteMergingConfigPresetRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteMergingConfigPresetRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteMergingConfigPresetRequest} message DeleteMergingConfigPresetRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteMergingConfigPresetRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteMergingConfigPresetRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteMergingConfigPresetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteMergingConfigPresetRequest} DeleteMergingConfigPresetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteMergingConfigPresetRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteMergingConfigPresetRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.mergingConfigPresetId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteMergingConfigPresetRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteMergingConfigPresetRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteMergingConfigPresetRequest} DeleteMergingConfigPresetRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteMergingConfigPresetRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteMergingConfigPresetRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteMergingConfigPresetRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteMergingConfigPresetRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mergingConfigPresetId != null && message.hasOwnProperty("mergingConfigPresetId"))
                            if (!$util.isInteger(message.mergingConfigPresetId) && !(message.mergingConfigPresetId && $util.isInteger(message.mergingConfigPresetId.low) && $util.isInteger(message.mergingConfigPresetId.high)))
                                return "mergingConfigPresetId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteMergingConfigPresetRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteMergingConfigPresetRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteMergingConfigPresetRequest} DeleteMergingConfigPresetRequest
                     */
                    DeleteMergingConfigPresetRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteMergingConfigPresetRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteMergingConfigPresetRequest();
                        if (object.mergingConfigPresetId != null)
                            if ($util.Long)
                                (message.mergingConfigPresetId = $util.Long.fromValue(object.mergingConfigPresetId)).unsigned = false;
                            else if (typeof object.mergingConfigPresetId === "string")
                                message.mergingConfigPresetId = parseInt(object.mergingConfigPresetId, 10);
                            else if (typeof object.mergingConfigPresetId === "number")
                                message.mergingConfigPresetId = object.mergingConfigPresetId;
                            else if (typeof object.mergingConfigPresetId === "object")
                                message.mergingConfigPresetId = new $util.LongBits(object.mergingConfigPresetId.low >>> 0, object.mergingConfigPresetId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteMergingConfigPresetRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteMergingConfigPresetRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteMergingConfigPresetRequest} message DeleteMergingConfigPresetRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteMergingConfigPresetRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.mergingConfigPresetId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.mergingConfigPresetId = options.longs === String ? "0" : 0;
                        if (message.mergingConfigPresetId != null && message.hasOwnProperty("mergingConfigPresetId"))
                            if (typeof message.mergingConfigPresetId === "number")
                                object.mergingConfigPresetId = options.longs === String ? String(message.mergingConfigPresetId) : message.mergingConfigPresetId;
                            else
                                object.mergingConfigPresetId = options.longs === String ? $util.Long.prototype.toString.call(message.mergingConfigPresetId) : options.longs === Number ? new $util.LongBits(message.mergingConfigPresetId.low >>> 0, message.mergingConfigPresetId.high >>> 0).toNumber() : message.mergingConfigPresetId;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteMergingConfigPresetRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteMergingConfigPresetRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteMergingConfigPresetRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteMergingConfigPresetRequest;
                })();
    
                v1.DeleteStoreConfigItemRequest = (function() {
    
                    /**
                     * Properties of a DeleteStoreConfigItemRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteStoreConfigItemRequest
                     * @property {string|null} [name] DeleteStoreConfigItemRequest name
                     */
    
                    /**
                     * Constructs a new DeleteStoreConfigItemRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteStoreConfigItemRequest.
                     * @implements IDeleteStoreConfigItemRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteStoreConfigItemRequest=} [properties] Properties to set
                     */
                    function DeleteStoreConfigItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteStoreConfigItemRequest name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.DeleteStoreConfigItemRequest
                     * @instance
                     */
                    DeleteStoreConfigItemRequest.prototype.name = "";
    
                    /**
                     * Creates a new DeleteStoreConfigItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStoreConfigItemRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteStoreConfigItemRequest} DeleteStoreConfigItemRequest instance
                     */
                    DeleteStoreConfigItemRequest.create = function create(properties) {
                        return new DeleteStoreConfigItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteStoreConfigItemRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteStoreConfigItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStoreConfigItemRequest} message DeleteStoreConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStoreConfigItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteStoreConfigItemRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteStoreConfigItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStoreConfigItemRequest} message DeleteStoreConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStoreConfigItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteStoreConfigItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteStoreConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteStoreConfigItemRequest} DeleteStoreConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStoreConfigItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteStoreConfigItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteStoreConfigItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteStoreConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteStoreConfigItemRequest} DeleteStoreConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStoreConfigItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteStoreConfigItemRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteStoreConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteStoreConfigItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteStoreConfigItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteStoreConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteStoreConfigItemRequest} DeleteStoreConfigItemRequest
                     */
                    DeleteStoreConfigItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteStoreConfigItemRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteStoreConfigItemRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteStoreConfigItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteStoreConfigItemRequest} message DeleteStoreConfigItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteStoreConfigItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteStoreConfigItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteStoreConfigItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteStoreConfigItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteStoreConfigItemRequest;
                })();
    
                v1.CreateStoreConfigRequest = (function() {
    
                    /**
                     * Properties of a CreateStoreConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateStoreConfigRequest
                     * @property {palexy.streaming.v1.IStoreConfig|null} [storeConfig] CreateStoreConfigRequest storeConfig
                     */
    
                    /**
                     * Constructs a new CreateStoreConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateStoreConfigRequest.
                     * @implements ICreateStoreConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateStoreConfigRequest=} [properties] Properties to set
                     */
                    function CreateStoreConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateStoreConfigRequest storeConfig.
                     * @member {palexy.streaming.v1.IStoreConfig|null|undefined} storeConfig
                     * @memberof palexy.streaming.v1.CreateStoreConfigRequest
                     * @instance
                     */
                    CreateStoreConfigRequest.prototype.storeConfig = null;
    
                    /**
                     * Creates a new CreateStoreConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateStoreConfigRequest} CreateStoreConfigRequest instance
                     */
                    CreateStoreConfigRequest.create = function create(properties) {
                        return new CreateStoreConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateStoreConfigRequest message. Does not implicitly {@link palexy.streaming.v1.CreateStoreConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreConfigRequest} message CreateStoreConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStoreConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeConfig != null && Object.hasOwnProperty.call(message, "storeConfig"))
                            $root.palexy.streaming.v1.StoreConfig.encode(message.storeConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateStoreConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateStoreConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreConfigRequest} message CreateStoreConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStoreConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateStoreConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateStoreConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateStoreConfigRequest} CreateStoreConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStoreConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateStoreConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeConfig = $root.palexy.streaming.v1.StoreConfig.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateStoreConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateStoreConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateStoreConfigRequest} CreateStoreConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStoreConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateStoreConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateStoreConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateStoreConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeConfig != null && message.hasOwnProperty("storeConfig")) {
                            var error = $root.palexy.streaming.v1.StoreConfig.verify(message.storeConfig);
                            if (error)
                                return "storeConfig." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateStoreConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateStoreConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateStoreConfigRequest} CreateStoreConfigRequest
                     */
                    CreateStoreConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateStoreConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateStoreConfigRequest();
                        if (object.storeConfig != null) {
                            if (typeof object.storeConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateStoreConfigRequest.storeConfig: object expected");
                            message.storeConfig = $root.palexy.streaming.v1.StoreConfig.fromObject(object.storeConfig);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateStoreConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateStoreConfigRequest} message CreateStoreConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateStoreConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.storeConfig = null;
                        if (message.storeConfig != null && message.hasOwnProperty("storeConfig"))
                            object.storeConfig = $root.palexy.streaming.v1.StoreConfig.toObject(message.storeConfig, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateStoreConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateStoreConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateStoreConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateStoreConfigRequest;
                })();
    
                v1.ListStoreConfigsRequest = (function() {
    
                    /**
                     * Properties of a ListStoreConfigsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreConfigsRequest
                     * @property {string|null} [cameraId] ListStoreConfigsRequest cameraId
                     */
    
                    /**
                     * Constructs a new ListStoreConfigsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreConfigsRequest.
                     * @implements IListStoreConfigsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreConfigsRequest=} [properties] Properties to set
                     */
                    function ListStoreConfigsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreConfigsRequest cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.ListStoreConfigsRequest
                     * @instance
                     */
                    ListStoreConfigsRequest.prototype.cameraId = "";
    
                    /**
                     * Creates a new ListStoreConfigsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreConfigsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreConfigsRequest} ListStoreConfigsRequest instance
                     */
                    ListStoreConfigsRequest.create = function create(properties) {
                        return new ListStoreConfigsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreConfigsRequest message. Does not implicitly {@link palexy.streaming.v1.ListStoreConfigsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreConfigsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigsRequest} message ListStoreConfigsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreConfigsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cameraId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreConfigsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreConfigsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreConfigsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigsRequest} message ListStoreConfigsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreConfigsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreConfigsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreConfigsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreConfigsRequest} ListStoreConfigsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreConfigsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreConfigsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreConfigsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreConfigsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreConfigsRequest} ListStoreConfigsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreConfigsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreConfigsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreConfigsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreConfigsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreConfigsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreConfigsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreConfigsRequest} ListStoreConfigsRequest
                     */
                    ListStoreConfigsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreConfigsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreConfigsRequest();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreConfigsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreConfigsRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStoreConfigsRequest} message ListStoreConfigsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreConfigsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.cameraId = "";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreConfigsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreConfigsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreConfigsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreConfigsRequest;
                })();
    
                v1.ListStoreConfigsResponse = (function() {
    
                    /**
                     * Properties of a ListStoreConfigsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreConfigsResponse
                     * @property {Array.<palexy.streaming.v1.IStoreConfig>|null} [storeConfigs] ListStoreConfigsResponse storeConfigs
                     */
    
                    /**
                     * Constructs a new ListStoreConfigsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreConfigsResponse.
                     * @implements IListStoreConfigsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreConfigsResponse=} [properties] Properties to set
                     */
                    function ListStoreConfigsResponse(properties) {
                        this.storeConfigs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreConfigsResponse storeConfigs.
                     * @member {Array.<palexy.streaming.v1.IStoreConfig>} storeConfigs
                     * @memberof palexy.streaming.v1.ListStoreConfigsResponse
                     * @instance
                     */
                    ListStoreConfigsResponse.prototype.storeConfigs = $util.emptyArray;
    
                    /**
                     * Creates a new ListStoreConfigsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreConfigsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreConfigsResponse} ListStoreConfigsResponse instance
                     */
                    ListStoreConfigsResponse.create = function create(properties) {
                        return new ListStoreConfigsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreConfigsResponse message. Does not implicitly {@link palexy.streaming.v1.ListStoreConfigsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreConfigsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigsResponse} message ListStoreConfigsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreConfigsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeConfigs != null && message.storeConfigs.length)
                            for (var i = 0; i < message.storeConfigs.length; ++i)
                                $root.palexy.streaming.v1.StoreConfig.encode(message.storeConfigs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreConfigsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreConfigsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreConfigsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigsResponse} message ListStoreConfigsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreConfigsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreConfigsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreConfigsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreConfigsResponse} ListStoreConfigsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreConfigsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreConfigsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeConfigs && message.storeConfigs.length))
                                    message.storeConfigs = [];
                                message.storeConfigs.push($root.palexy.streaming.v1.StoreConfig.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreConfigsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreConfigsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreConfigsResponse} ListStoreConfigsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreConfigsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreConfigsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreConfigsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreConfigsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeConfigs != null && message.hasOwnProperty("storeConfigs")) {
                            if (!Array.isArray(message.storeConfigs))
                                return "storeConfigs: array expected";
                            for (var i = 0; i < message.storeConfigs.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreConfig.verify(message.storeConfigs[i]);
                                if (error)
                                    return "storeConfigs." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreConfigsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreConfigsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreConfigsResponse} ListStoreConfigsResponse
                     */
                    ListStoreConfigsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreConfigsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreConfigsResponse();
                        if (object.storeConfigs) {
                            if (!Array.isArray(object.storeConfigs))
                                throw TypeError(".palexy.streaming.v1.ListStoreConfigsResponse.storeConfigs: array expected");
                            message.storeConfigs = [];
                            for (var i = 0; i < object.storeConfigs.length; ++i) {
                                if (typeof object.storeConfigs[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStoreConfigsResponse.storeConfigs: object expected");
                                message.storeConfigs[i] = $root.palexy.streaming.v1.StoreConfig.fromObject(object.storeConfigs[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreConfigsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreConfigsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStoreConfigsResponse} message ListStoreConfigsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreConfigsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeConfigs = [];
                        if (message.storeConfigs && message.storeConfigs.length) {
                            object.storeConfigs = [];
                            for (var j = 0; j < message.storeConfigs.length; ++j)
                                object.storeConfigs[j] = $root.palexy.streaming.v1.StoreConfig.toObject(message.storeConfigs[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreConfigsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreConfigsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreConfigsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreConfigsResponse;
                })();
    
                v1.GetStoreConfigRequest = (function() {
    
                    /**
                     * Properties of a GetStoreConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStoreConfigRequest
                     * @property {string|null} [name] GetStoreConfigRequest name
                     */
    
                    /**
                     * Constructs a new GetStoreConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStoreConfigRequest.
                     * @implements IGetStoreConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStoreConfigRequest=} [properties] Properties to set
                     */
                    function GetStoreConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStoreConfigRequest name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.GetStoreConfigRequest
                     * @instance
                     */
                    GetStoreConfigRequest.prototype.name = "";
    
                    /**
                     * Creates a new GetStoreConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStoreConfigRequest} GetStoreConfigRequest instance
                     */
                    GetStoreConfigRequest.create = function create(properties) {
                        return new GetStoreConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStoreConfigRequest message. Does not implicitly {@link palexy.streaming.v1.GetStoreConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreConfigRequest} message GetStoreConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStoreConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStoreConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreConfigRequest} message GetStoreConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStoreConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStoreConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStoreConfigRequest} GetStoreConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStoreConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStoreConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStoreConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStoreConfigRequest} GetStoreConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStoreConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStoreConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStoreConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStoreConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStoreConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStoreConfigRequest} GetStoreConfigRequest
                     */
                    GetStoreConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStoreConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStoreConfigRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStoreConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStoreConfigRequest} message GetStoreConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStoreConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this GetStoreConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStoreConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStoreConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStoreConfigRequest;
                })();
    
                v1.UpdateStoreConfigRequest = (function() {
    
                    /**
                     * Properties of an UpdateStoreConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateStoreConfigRequest
                     * @property {palexy.streaming.v1.IStoreConfig|null} [storeConfig] UpdateStoreConfigRequest storeConfig
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateStoreConfigRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateStoreConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateStoreConfigRequest.
                     * @implements IUpdateStoreConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateStoreConfigRequest=} [properties] Properties to set
                     */
                    function UpdateStoreConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateStoreConfigRequest storeConfig.
                     * @member {palexy.streaming.v1.IStoreConfig|null|undefined} storeConfig
                     * @memberof palexy.streaming.v1.UpdateStoreConfigRequest
                     * @instance
                     */
                    UpdateStoreConfigRequest.prototype.storeConfig = null;
    
                    /**
                     * UpdateStoreConfigRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateStoreConfigRequest
                     * @instance
                     */
                    UpdateStoreConfigRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateStoreConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateStoreConfigRequest} UpdateStoreConfigRequest instance
                     */
                    UpdateStoreConfigRequest.create = function create(properties) {
                        return new UpdateStoreConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateStoreConfigRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateStoreConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreConfigRequest} message UpdateStoreConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStoreConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeConfig != null && Object.hasOwnProperty.call(message, "storeConfig"))
                            $root.palexy.streaming.v1.StoreConfig.encode(message.storeConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateStoreConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateStoreConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreConfigRequest} message UpdateStoreConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStoreConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateStoreConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateStoreConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateStoreConfigRequest} UpdateStoreConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStoreConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateStoreConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeConfig = $root.palexy.streaming.v1.StoreConfig.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateStoreConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStoreConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateStoreConfigRequest} UpdateStoreConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStoreConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateStoreConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateStoreConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateStoreConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeConfig != null && message.hasOwnProperty("storeConfig")) {
                            var error = $root.palexy.streaming.v1.StoreConfig.verify(message.storeConfig);
                            if (error)
                                return "storeConfig." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateStoreConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateStoreConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateStoreConfigRequest} UpdateStoreConfigRequest
                     */
                    UpdateStoreConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateStoreConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateStoreConfigRequest();
                        if (object.storeConfig != null) {
                            if (typeof object.storeConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStoreConfigRequest.storeConfig: object expected");
                            message.storeConfig = $root.palexy.streaming.v1.StoreConfig.fromObject(object.storeConfig);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStoreConfigRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateStoreConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateStoreConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateStoreConfigRequest} message UpdateStoreConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateStoreConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.storeConfig = null;
                            object.updateMask = null;
                        }
                        if (message.storeConfig != null && message.hasOwnProperty("storeConfig"))
                            object.storeConfig = $root.palexy.streaming.v1.StoreConfig.toObject(message.storeConfig, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateStoreConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateStoreConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateStoreConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateStoreConfigRequest;
                })();
    
                v1.CreateStoreConfigItemRequest = (function() {
    
                    /**
                     * Properties of a CreateStoreConfigItemRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateStoreConfigItemRequest
                     * @property {string|null} [parent] CreateStoreConfigItemRequest parent
                     * @property {palexy.streaming.v1.IStoreConfigItem|null} [storeConfigItem] CreateStoreConfigItemRequest storeConfigItem
                     */
    
                    /**
                     * Constructs a new CreateStoreConfigItemRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateStoreConfigItemRequest.
                     * @implements ICreateStoreConfigItemRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateStoreConfigItemRequest=} [properties] Properties to set
                     */
                    function CreateStoreConfigItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateStoreConfigItemRequest parent.
                     * @member {string} parent
                     * @memberof palexy.streaming.v1.CreateStoreConfigItemRequest
                     * @instance
                     */
                    CreateStoreConfigItemRequest.prototype.parent = "";
    
                    /**
                     * CreateStoreConfigItemRequest storeConfigItem.
                     * @member {palexy.streaming.v1.IStoreConfigItem|null|undefined} storeConfigItem
                     * @memberof palexy.streaming.v1.CreateStoreConfigItemRequest
                     * @instance
                     */
                    CreateStoreConfigItemRequest.prototype.storeConfigItem = null;
    
                    /**
                     * Creates a new CreateStoreConfigItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreConfigItemRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateStoreConfigItemRequest} CreateStoreConfigItemRequest instance
                     */
                    CreateStoreConfigItemRequest.create = function create(properties) {
                        return new CreateStoreConfigItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateStoreConfigItemRequest message. Does not implicitly {@link palexy.streaming.v1.CreateStoreConfigItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreConfigItemRequest} message CreateStoreConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStoreConfigItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.parent != null && Object.hasOwnProperty.call(message, "parent"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.parent);
                        if (message.storeConfigItem != null && Object.hasOwnProperty.call(message, "storeConfigItem"))
                            $root.palexy.streaming.v1.StoreConfigItem.encode(message.storeConfigItem, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateStoreConfigItemRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateStoreConfigItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreConfigItemRequest} message CreateStoreConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStoreConfigItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateStoreConfigItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateStoreConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateStoreConfigItemRequest} CreateStoreConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStoreConfigItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateStoreConfigItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.parent = reader.string();
                                break;
                            case 2:
                                message.storeConfigItem = $root.palexy.streaming.v1.StoreConfigItem.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateStoreConfigItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateStoreConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateStoreConfigItemRequest} CreateStoreConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStoreConfigItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateStoreConfigItemRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateStoreConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateStoreConfigItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.parent != null && message.hasOwnProperty("parent"))
                            if (!$util.isString(message.parent))
                                return "parent: string expected";
                        if (message.storeConfigItem != null && message.hasOwnProperty("storeConfigItem")) {
                            var error = $root.palexy.streaming.v1.StoreConfigItem.verify(message.storeConfigItem);
                            if (error)
                                return "storeConfigItem." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateStoreConfigItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateStoreConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateStoreConfigItemRequest} CreateStoreConfigItemRequest
                     */
                    CreateStoreConfigItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateStoreConfigItemRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateStoreConfigItemRequest();
                        if (object.parent != null)
                            message.parent = String(object.parent);
                        if (object.storeConfigItem != null) {
                            if (typeof object.storeConfigItem !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateStoreConfigItemRequest.storeConfigItem: object expected");
                            message.storeConfigItem = $root.palexy.streaming.v1.StoreConfigItem.fromObject(object.storeConfigItem);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateStoreConfigItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateStoreConfigItemRequest} message CreateStoreConfigItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateStoreConfigItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.parent = "";
                            object.storeConfigItem = null;
                        }
                        if (message.parent != null && message.hasOwnProperty("parent"))
                            object.parent = message.parent;
                        if (message.storeConfigItem != null && message.hasOwnProperty("storeConfigItem"))
                            object.storeConfigItem = $root.palexy.streaming.v1.StoreConfigItem.toObject(message.storeConfigItem, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateStoreConfigItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateStoreConfigItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateStoreConfigItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateStoreConfigItemRequest;
                })();
    
                v1.ListStoreConfigItemsRequest = (function() {
    
                    /**
                     * Properties of a ListStoreConfigItemsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreConfigItemsRequest
                     * @property {string|null} [parent] ListStoreConfigItemsRequest parent
                     * @property {number|null} [storeId] ListStoreConfigItemsRequest storeId
                     * @property {palexy.streaming.v1.StoreConfigItem.StoreConfigAppliedScope|null} [appliedScope] ListStoreConfigItemsRequest appliedScope
                     */
    
                    /**
                     * Constructs a new ListStoreConfigItemsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreConfigItemsRequest.
                     * @implements IListStoreConfigItemsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreConfigItemsRequest=} [properties] Properties to set
                     */
                    function ListStoreConfigItemsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreConfigItemsRequest parent.
                     * @member {string|null|undefined} parent
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @instance
                     */
                    ListStoreConfigItemsRequest.prototype.parent = null;
    
                    /**
                     * ListStoreConfigItemsRequest storeId.
                     * @member {number|null|undefined} storeId
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @instance
                     */
                    ListStoreConfigItemsRequest.prototype.storeId = null;
    
                    /**
                     * ListStoreConfigItemsRequest appliedScope.
                     * @member {palexy.streaming.v1.StoreConfigItem.StoreConfigAppliedScope} appliedScope
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @instance
                     */
                    ListStoreConfigItemsRequest.prototype.appliedScope = 0;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * ListStoreConfigItemsRequest filter.
                     * @member {"parent"|"storeId"|undefined} filter
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @instance
                     */
                    Object.defineProperty(ListStoreConfigItemsRequest.prototype, "filter", {
                        get: $util.oneOfGetter($oneOfFields = ["parent", "storeId"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new ListStoreConfigItemsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigItemsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreConfigItemsRequest} ListStoreConfigItemsRequest instance
                     */
                    ListStoreConfigItemsRequest.create = function create(properties) {
                        return new ListStoreConfigItemsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreConfigItemsRequest message. Does not implicitly {@link palexy.streaming.v1.ListStoreConfigItemsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigItemsRequest} message ListStoreConfigItemsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreConfigItemsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.parent != null && Object.hasOwnProperty.call(message, "parent"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.parent);
                        if (message.appliedScope != null && Object.hasOwnProperty.call(message, "appliedScope"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.appliedScope);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreConfigItemsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreConfigItemsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigItemsRequest} message ListStoreConfigItemsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreConfigItemsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreConfigItemsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreConfigItemsRequest} ListStoreConfigItemsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreConfigItemsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreConfigItemsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.parent = reader.string();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.appliedScope = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreConfigItemsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreConfigItemsRequest} ListStoreConfigItemsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreConfigItemsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreConfigItemsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreConfigItemsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.parent != null && message.hasOwnProperty("parent")) {
                            properties.filter = 1;
                            if (!$util.isString(message.parent))
                                return "parent: string expected";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            if (properties.filter === 1)
                                return "filter: multiple values";
                            properties.filter = 1;
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        }
                        if (message.appliedScope != null && message.hasOwnProperty("appliedScope"))
                            switch (message.appliedScope) {
                            default:
                                return "appliedScope: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreConfigItemsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreConfigItemsRequest} ListStoreConfigItemsRequest
                     */
                    ListStoreConfigItemsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreConfigItemsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreConfigItemsRequest();
                        if (object.parent != null)
                            message.parent = String(object.parent);
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        switch (object.appliedScope) {
                        case "UNKNOWN_SCOPE":
                        case 0:
                            message.appliedScope = 0;
                            break;
                        case "PRODUCTION":
                        case 1:
                            message.appliedScope = 1;
                            break;
                        case "EXPERIMENT":
                        case 2:
                            message.appliedScope = 2;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreConfigItemsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStoreConfigItemsRequest} message ListStoreConfigItemsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreConfigItemsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.appliedScope = options.enums === String ? "UNKNOWN_SCOPE" : 0;
                        if (message.parent != null && message.hasOwnProperty("parent")) {
                            object.parent = message.parent;
                            if (options.oneofs)
                                object.filter = "parent";
                        }
                        if (message.appliedScope != null && message.hasOwnProperty("appliedScope"))
                            object.appliedScope = options.enums === String ? $root.palexy.streaming.v1.StoreConfigItem.StoreConfigAppliedScope[message.appliedScope] : message.appliedScope;
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            if (options.oneofs)
                                object.filter = "storeId";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreConfigItemsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreConfigItemsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreConfigItemsRequest;
                })();
    
                v1.ListStoreConfigItemsResponse = (function() {
    
                    /**
                     * Properties of a ListStoreConfigItemsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreConfigItemsResponse
                     * @property {Array.<palexy.streaming.v1.IStoreConfigItem>|null} [storeConfigItem] ListStoreConfigItemsResponse storeConfigItem
                     */
    
                    /**
                     * Constructs a new ListStoreConfigItemsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreConfigItemsResponse.
                     * @implements IListStoreConfigItemsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreConfigItemsResponse=} [properties] Properties to set
                     */
                    function ListStoreConfigItemsResponse(properties) {
                        this.storeConfigItem = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreConfigItemsResponse storeConfigItem.
                     * @member {Array.<palexy.streaming.v1.IStoreConfigItem>} storeConfigItem
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsResponse
                     * @instance
                     */
                    ListStoreConfigItemsResponse.prototype.storeConfigItem = $util.emptyArray;
    
                    /**
                     * Creates a new ListStoreConfigItemsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigItemsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreConfigItemsResponse} ListStoreConfigItemsResponse instance
                     */
                    ListStoreConfigItemsResponse.create = function create(properties) {
                        return new ListStoreConfigItemsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreConfigItemsResponse message. Does not implicitly {@link palexy.streaming.v1.ListStoreConfigItemsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigItemsResponse} message ListStoreConfigItemsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreConfigItemsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeConfigItem != null && message.storeConfigItem.length)
                            for (var i = 0; i < message.storeConfigItem.length; ++i)
                                $root.palexy.streaming.v1.StoreConfigItem.encode(message.storeConfigItem[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreConfigItemsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreConfigItemsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreConfigItemsResponse} message ListStoreConfigItemsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreConfigItemsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreConfigItemsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreConfigItemsResponse} ListStoreConfigItemsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreConfigItemsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreConfigItemsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeConfigItem && message.storeConfigItem.length))
                                    message.storeConfigItem = [];
                                message.storeConfigItem.push($root.palexy.streaming.v1.StoreConfigItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreConfigItemsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreConfigItemsResponse} ListStoreConfigItemsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreConfigItemsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreConfigItemsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreConfigItemsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeConfigItem != null && message.hasOwnProperty("storeConfigItem")) {
                            if (!Array.isArray(message.storeConfigItem))
                                return "storeConfigItem: array expected";
                            for (var i = 0; i < message.storeConfigItem.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreConfigItem.verify(message.storeConfigItem[i]);
                                if (error)
                                    return "storeConfigItem." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreConfigItemsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreConfigItemsResponse} ListStoreConfigItemsResponse
                     */
                    ListStoreConfigItemsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreConfigItemsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreConfigItemsResponse();
                        if (object.storeConfigItem) {
                            if (!Array.isArray(object.storeConfigItem))
                                throw TypeError(".palexy.streaming.v1.ListStoreConfigItemsResponse.storeConfigItem: array expected");
                            message.storeConfigItem = [];
                            for (var i = 0; i < object.storeConfigItem.length; ++i) {
                                if (typeof object.storeConfigItem[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStoreConfigItemsResponse.storeConfigItem: object expected");
                                message.storeConfigItem[i] = $root.palexy.streaming.v1.StoreConfigItem.fromObject(object.storeConfigItem[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreConfigItemsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStoreConfigItemsResponse} message ListStoreConfigItemsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreConfigItemsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeConfigItem = [];
                        if (message.storeConfigItem && message.storeConfigItem.length) {
                            object.storeConfigItem = [];
                            for (var j = 0; j < message.storeConfigItem.length; ++j)
                                object.storeConfigItem[j] = $root.palexy.streaming.v1.StoreConfigItem.toObject(message.storeConfigItem[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreConfigItemsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreConfigItemsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreConfigItemsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreConfigItemsResponse;
                })();
    
                v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest = (function() {
    
                    /**
                     * Properties of a FindProductionStoreConfigItemByCameraIdAndDateIdRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IFindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @property {string|null} [cameraId] FindProductionStoreConfigItemByCameraIdAndDateIdRequest cameraId
                     * @property {string|null} [dateId] FindProductionStoreConfigItemByCameraIdAndDateIdRequest dateId
                     */
    
                    /**
                     * Constructs a new FindProductionStoreConfigItemByCameraIdAndDateIdRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FindProductionStoreConfigItemByCameraIdAndDateIdRequest.
                     * @implements IFindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IFindProductionStoreConfigItemByCameraIdAndDateIdRequest=} [properties] Properties to set
                     */
                    function FindProductionStoreConfigItemByCameraIdAndDateIdRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FindProductionStoreConfigItemByCameraIdAndDateIdRequest cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @instance
                     */
                    FindProductionStoreConfigItemByCameraIdAndDateIdRequest.prototype.cameraId = "";
    
                    /**
                     * FindProductionStoreConfigItemByCameraIdAndDateIdRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @instance
                     */
                    FindProductionStoreConfigItemByCameraIdAndDateIdRequest.prototype.dateId = "";
    
                    /**
                     * Creates a new FindProductionStoreConfigItemByCameraIdAndDateIdRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @static
                     * @param {palexy.streaming.v1.IFindProductionStoreConfigItemByCameraIdAndDateIdRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest} FindProductionStoreConfigItemByCameraIdAndDateIdRequest instance
                     */
                    FindProductionStoreConfigItemByCameraIdAndDateIdRequest.create = function create(properties) {
                        return new FindProductionStoreConfigItemByCameraIdAndDateIdRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FindProductionStoreConfigItemByCameraIdAndDateIdRequest message. Does not implicitly {@link palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @static
                     * @param {palexy.streaming.v1.IFindProductionStoreConfigItemByCameraIdAndDateIdRequest} message FindProductionStoreConfigItemByCameraIdAndDateIdRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FindProductionStoreConfigItemByCameraIdAndDateIdRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cameraId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FindProductionStoreConfigItemByCameraIdAndDateIdRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @static
                     * @param {palexy.streaming.v1.IFindProductionStoreConfigItemByCameraIdAndDateIdRequest} message FindProductionStoreConfigItemByCameraIdAndDateIdRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FindProductionStoreConfigItemByCameraIdAndDateIdRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FindProductionStoreConfigItemByCameraIdAndDateIdRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest} FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FindProductionStoreConfigItemByCameraIdAndDateIdRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraId = reader.string();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FindProductionStoreConfigItemByCameraIdAndDateIdRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest} FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FindProductionStoreConfigItemByCameraIdAndDateIdRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FindProductionStoreConfigItemByCameraIdAndDateIdRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FindProductionStoreConfigItemByCameraIdAndDateIdRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a FindProductionStoreConfigItemByCameraIdAndDateIdRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest} FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     */
                    FindProductionStoreConfigItemByCameraIdAndDateIdRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FindProductionStoreConfigItemByCameraIdAndDateIdRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @static
                     * @param {palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest} message FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FindProductionStoreConfigItemByCameraIdAndDateIdRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.cameraId = "";
                            object.dateId = "";
                        }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        return object;
                    };
    
                    /**
                     * Converts this FindProductionStoreConfigItemByCameraIdAndDateIdRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FindProductionStoreConfigItemByCameraIdAndDateIdRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FindProductionStoreConfigItemByCameraIdAndDateIdRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FindProductionStoreConfigItemByCameraIdAndDateIdRequest;
                })();
    
                v1.GetStoreConfigItemRequest = (function() {
    
                    /**
                     * Properties of a GetStoreConfigItemRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStoreConfigItemRequest
                     * @property {string|null} [name] GetStoreConfigItemRequest name
                     */
    
                    /**
                     * Constructs a new GetStoreConfigItemRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStoreConfigItemRequest.
                     * @implements IGetStoreConfigItemRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStoreConfigItemRequest=} [properties] Properties to set
                     */
                    function GetStoreConfigItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStoreConfigItemRequest name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.GetStoreConfigItemRequest
                     * @instance
                     */
                    GetStoreConfigItemRequest.prototype.name = "";
    
                    /**
                     * Creates a new GetStoreConfigItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreConfigItemRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStoreConfigItemRequest} GetStoreConfigItemRequest instance
                     */
                    GetStoreConfigItemRequest.create = function create(properties) {
                        return new GetStoreConfigItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStoreConfigItemRequest message. Does not implicitly {@link palexy.streaming.v1.GetStoreConfigItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreConfigItemRequest} message GetStoreConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreConfigItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStoreConfigItemRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStoreConfigItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreConfigItemRequest} message GetStoreConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreConfigItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStoreConfigItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStoreConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStoreConfigItemRequest} GetStoreConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreConfigItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStoreConfigItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStoreConfigItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStoreConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStoreConfigItemRequest} GetStoreConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreConfigItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStoreConfigItemRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStoreConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStoreConfigItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStoreConfigItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStoreConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStoreConfigItemRequest} GetStoreConfigItemRequest
                     */
                    GetStoreConfigItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStoreConfigItemRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStoreConfigItemRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStoreConfigItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStoreConfigItemRequest} message GetStoreConfigItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStoreConfigItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this GetStoreConfigItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStoreConfigItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStoreConfigItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStoreConfigItemRequest;
                })();
    
                v1.UpdateStoreConfigItemRequest = (function() {
    
                    /**
                     * Properties of an UpdateStoreConfigItemRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateStoreConfigItemRequest
                     * @property {palexy.streaming.v1.IStoreConfigItem|null} [storeConfigItem] UpdateStoreConfigItemRequest storeConfigItem
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateStoreConfigItemRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateStoreConfigItemRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateStoreConfigItemRequest.
                     * @implements IUpdateStoreConfigItemRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateStoreConfigItemRequest=} [properties] Properties to set
                     */
                    function UpdateStoreConfigItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateStoreConfigItemRequest storeConfigItem.
                     * @member {palexy.streaming.v1.IStoreConfigItem|null|undefined} storeConfigItem
                     * @memberof palexy.streaming.v1.UpdateStoreConfigItemRequest
                     * @instance
                     */
                    UpdateStoreConfigItemRequest.prototype.storeConfigItem = null;
    
                    /**
                     * UpdateStoreConfigItemRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateStoreConfigItemRequest
                     * @instance
                     */
                    UpdateStoreConfigItemRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateStoreConfigItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreConfigItemRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateStoreConfigItemRequest} UpdateStoreConfigItemRequest instance
                     */
                    UpdateStoreConfigItemRequest.create = function create(properties) {
                        return new UpdateStoreConfigItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateStoreConfigItemRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateStoreConfigItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreConfigItemRequest} message UpdateStoreConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStoreConfigItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeConfigItem != null && Object.hasOwnProperty.call(message, "storeConfigItem"))
                            $root.palexy.streaming.v1.StoreConfigItem.encode(message.storeConfigItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateStoreConfigItemRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateStoreConfigItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreConfigItemRequest} message UpdateStoreConfigItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStoreConfigItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateStoreConfigItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateStoreConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateStoreConfigItemRequest} UpdateStoreConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStoreConfigItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateStoreConfigItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeConfigItem = $root.palexy.streaming.v1.StoreConfigItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateStoreConfigItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStoreConfigItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateStoreConfigItemRequest} UpdateStoreConfigItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStoreConfigItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateStoreConfigItemRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateStoreConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateStoreConfigItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeConfigItem != null && message.hasOwnProperty("storeConfigItem")) {
                            var error = $root.palexy.streaming.v1.StoreConfigItem.verify(message.storeConfigItem);
                            if (error)
                                return "storeConfigItem." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateStoreConfigItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateStoreConfigItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateStoreConfigItemRequest} UpdateStoreConfigItemRequest
                     */
                    UpdateStoreConfigItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateStoreConfigItemRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateStoreConfigItemRequest();
                        if (object.storeConfigItem != null) {
                            if (typeof object.storeConfigItem !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStoreConfigItemRequest.storeConfigItem: object expected");
                            message.storeConfigItem = $root.palexy.streaming.v1.StoreConfigItem.fromObject(object.storeConfigItem);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStoreConfigItemRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateStoreConfigItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateStoreConfigItemRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateStoreConfigItemRequest} message UpdateStoreConfigItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateStoreConfigItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.storeConfigItem = null;
                            object.updateMask = null;
                        }
                        if (message.storeConfigItem != null && message.hasOwnProperty("storeConfigItem"))
                            object.storeConfigItem = $root.palexy.streaming.v1.StoreConfigItem.toObject(message.storeConfigItem, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateStoreConfigItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateStoreConfigItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateStoreConfigItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateStoreConfigItemRequest;
                })();
    
                v1.StoreService = (function() {
    
                    /**
                     * Constructs a new StoreService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StoreService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (StoreService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StoreService;
    
                    /**
                     * Creates new StoreService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StoreService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StoreService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreService#getStore}.
                     * @memberof palexy.streaming.v1.StoreService
                     * @typedef GetStoreCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Store} [response] Store
                     */
    
                    /**
                     * Calls GetStore.
                     * @function getStore
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoreRequest} request GetStoreRequest message or plain object
                     * @param {palexy.streaming.v1.StoreService.GetStoreCallback} callback Node-style callback called with the error, if any, and Store
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreService.prototype.getStore = function getStore(request, callback) {
                        return this.rpcCall(getStore, $root.palexy.streaming.v1.GetStoreRequest, $root.palexy.streaming.v1.Store, request, callback);
                    }, "name", { value: "GetStore" });
    
                    /**
                     * Calls GetStore.
                     * @function getStore
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoreRequest} request GetStoreRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Store>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreService#listStores}.
                     * @memberof palexy.streaming.v1.StoreService
                     * @typedef ListStoresCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStoresResponse} [response] ListStoresResponse
                     */
    
                    /**
                     * Calls ListStores.
                     * @function listStores
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoresRequest} request ListStoresRequest message or plain object
                     * @param {palexy.streaming.v1.StoreService.ListStoresCallback} callback Node-style callback called with the error, if any, and ListStoresResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreService.prototype.listStores = function listStores(request, callback) {
                        return this.rpcCall(listStores, $root.palexy.streaming.v1.ListStoresRequest, $root.palexy.streaming.v1.ListStoresResponse, request, callback);
                    }, "name", { value: "ListStores" });
    
                    /**
                     * Calls ListStores.
                     * @function listStores
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoresRequest} request ListStoresRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStoresResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreService#listStoresForTrend}.
                     * @memberof palexy.streaming.v1.StoreService
                     * @typedef ListStoresForTrendCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStoresResponse} [response] ListStoresResponse
                     */
    
                    /**
                     * Calls ListStoresForTrend.
                     * @function listStoresForTrend
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoresForTrendRequest} request ListStoresForTrendRequest message or plain object
                     * @param {palexy.streaming.v1.StoreService.ListStoresForTrendCallback} callback Node-style callback called with the error, if any, and ListStoresResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreService.prototype.listStoresForTrend = function listStoresForTrend(request, callback) {
                        return this.rpcCall(listStoresForTrend, $root.palexy.streaming.v1.ListStoresForTrendRequest, $root.palexy.streaming.v1.ListStoresResponse, request, callback);
                    }, "name", { value: "ListStoresForTrend" });
    
                    /**
                     * Calls ListStoresForTrend.
                     * @function listStoresForTrend
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoresForTrendRequest} request ListStoresForTrendRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStoresResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreService#rerunMerging}.
                     * @memberof palexy.streaming.v1.StoreService
                     * @typedef RerunMergingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.RerunMergingResponse} [response] RerunMergingResponse
                     */
    
                    /**
                     * Calls RerunMerging.
                     * @function rerunMerging
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IRerunMergingRequest} request RerunMergingRequest message or plain object
                     * @param {palexy.streaming.v1.StoreService.RerunMergingCallback} callback Node-style callback called with the error, if any, and RerunMergingResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreService.prototype.rerunMerging = function rerunMerging(request, callback) {
                        return this.rpcCall(rerunMerging, $root.palexy.streaming.v1.RerunMergingRequest, $root.palexy.streaming.v1.RerunMergingResponse, request, callback);
                    }, "name", { value: "RerunMerging" });
    
                    /**
                     * Calls RerunMerging.
                     * @function rerunMerging
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IRerunMergingRequest} request RerunMergingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.RerunMergingResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreService#listStoreProcessingStatuses}.
                     * @memberof palexy.streaming.v1.StoreService
                     * @typedef ListStoreProcessingStatusesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStoreProcessingStatusesResponse} [response] ListStoreProcessingStatusesResponse
                     */
    
                    /**
                     * Calls ListStoreProcessingStatuses.
                     * @function listStoreProcessingStatuses
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreProcessingStatusesRequest} request ListStoreProcessingStatusesRequest message or plain object
                     * @param {palexy.streaming.v1.StoreService.ListStoreProcessingStatusesCallback} callback Node-style callback called with the error, if any, and ListStoreProcessingStatusesResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreService.prototype.listStoreProcessingStatuses = function listStoreProcessingStatuses(request, callback) {
                        return this.rpcCall(listStoreProcessingStatuses, $root.palexy.streaming.v1.ListStoreProcessingStatusesRequest, $root.palexy.streaming.v1.ListStoreProcessingStatusesResponse, request, callback);
                    }, "name", { value: "ListStoreProcessingStatuses" });
    
                    /**
                     * Calls ListStoreProcessingStatuses.
                     * @function listStoreProcessingStatuses
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreProcessingStatusesRequest} request ListStoreProcessingStatusesRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStoreProcessingStatusesResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreService#getStoresForLateVideoChecking}.
                     * @memberof palexy.streaming.v1.StoreService
                     * @typedef GetStoresForLateVideoCheckingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.GetStoresForLateVideoCheckingResponse} [response] GetStoresForLateVideoCheckingResponse
                     */
    
                    /**
                     * Calls GetStoresForLateVideoChecking.
                     * @function getStoresForLateVideoChecking
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoresForLateVideoCheckingRequest} request GetStoresForLateVideoCheckingRequest message or plain object
                     * @param {palexy.streaming.v1.StoreService.GetStoresForLateVideoCheckingCallback} callback Node-style callback called with the error, if any, and GetStoresForLateVideoCheckingResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreService.prototype.getStoresForLateVideoChecking = function getStoresForLateVideoChecking(request, callback) {
                        return this.rpcCall(getStoresForLateVideoChecking, $root.palexy.streaming.v1.GetStoresForLateVideoCheckingRequest, $root.palexy.streaming.v1.GetStoresForLateVideoCheckingResponse, request, callback);
                    }, "name", { value: "GetStoresForLateVideoChecking" });
    
                    /**
                     * Calls GetStoresForLateVideoChecking.
                     * @function getStoresForLateVideoChecking
                     * @memberof palexy.streaming.v1.StoreService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoresForLateVideoCheckingRequest} request GetStoresForLateVideoCheckingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.GetStoresForLateVideoCheckingResponse>} Promise
                     * @variation 2
                     */
    
                    return StoreService;
                })();
    
                v1.GeoCoordinate = (function() {
    
                    /**
                     * Properties of a GeoCoordinate.
                     * @memberof palexy.streaming.v1
                     * @interface IGeoCoordinate
                     * @property {number|null} [latitude] GeoCoordinate latitude
                     * @property {number|null} [longitude] GeoCoordinate longitude
                     */
    
                    /**
                     * Constructs a new GeoCoordinate.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GeoCoordinate.
                     * @implements IGeoCoordinate
                     * @constructor
                     * @param {palexy.streaming.v1.IGeoCoordinate=} [properties] Properties to set
                     */
                    function GeoCoordinate(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GeoCoordinate latitude.
                     * @member {number} latitude
                     * @memberof palexy.streaming.v1.GeoCoordinate
                     * @instance
                     */
                    GeoCoordinate.prototype.latitude = 0;
    
                    /**
                     * GeoCoordinate longitude.
                     * @member {number} longitude
                     * @memberof palexy.streaming.v1.GeoCoordinate
                     * @instance
                     */
                    GeoCoordinate.prototype.longitude = 0;
    
                    /**
                     * Creates a new GeoCoordinate instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GeoCoordinate
                     * @static
                     * @param {palexy.streaming.v1.IGeoCoordinate=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GeoCoordinate} GeoCoordinate instance
                     */
                    GeoCoordinate.create = function create(properties) {
                        return new GeoCoordinate(properties);
                    };
    
                    /**
                     * Encodes the specified GeoCoordinate message. Does not implicitly {@link palexy.streaming.v1.GeoCoordinate.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GeoCoordinate
                     * @static
                     * @param {palexy.streaming.v1.IGeoCoordinate} message GeoCoordinate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GeoCoordinate.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                            writer.uint32(/* id 1, wireType 1 =*/9).double(message.latitude);
                        if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                            writer.uint32(/* id 2, wireType 1 =*/17).double(message.longitude);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GeoCoordinate message, length delimited. Does not implicitly {@link palexy.streaming.v1.GeoCoordinate.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GeoCoordinate
                     * @static
                     * @param {palexy.streaming.v1.IGeoCoordinate} message GeoCoordinate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GeoCoordinate.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GeoCoordinate message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GeoCoordinate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GeoCoordinate} GeoCoordinate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GeoCoordinate.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GeoCoordinate();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.latitude = reader.double();
                                break;
                            case 2:
                                message.longitude = reader.double();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GeoCoordinate message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GeoCoordinate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GeoCoordinate} GeoCoordinate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GeoCoordinate.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GeoCoordinate message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GeoCoordinate
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GeoCoordinate.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.latitude != null && message.hasOwnProperty("latitude"))
                            if (typeof message.latitude !== "number")
                                return "latitude: number expected";
                        if (message.longitude != null && message.hasOwnProperty("longitude"))
                            if (typeof message.longitude !== "number")
                                return "longitude: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a GeoCoordinate message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GeoCoordinate
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GeoCoordinate} GeoCoordinate
                     */
                    GeoCoordinate.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GeoCoordinate)
                            return object;
                        var message = new $root.palexy.streaming.v1.GeoCoordinate();
                        if (object.latitude != null)
                            message.latitude = Number(object.latitude);
                        if (object.longitude != null)
                            message.longitude = Number(object.longitude);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GeoCoordinate message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GeoCoordinate
                     * @static
                     * @param {palexy.streaming.v1.GeoCoordinate} message GeoCoordinate
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GeoCoordinate.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.latitude = 0;
                            object.longitude = 0;
                        }
                        if (message.latitude != null && message.hasOwnProperty("latitude"))
                            object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                        if (message.longitude != null && message.hasOwnProperty("longitude"))
                            object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                        return object;
                    };
    
                    /**
                     * Converts this GeoCoordinate to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GeoCoordinate
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GeoCoordinate.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GeoCoordinate;
                })();
    
                v1.Store = (function() {
    
                    /**
                     * Properties of a Store.
                     * @memberof palexy.streaming.v1
                     * @interface IStore
                     * @property {number|null} [id] Store id
                     * @property {string|null} [name] Store name
                     * @property {string|null} [displayName] Store displayName
                     * @property {number|null} [retentionDays] Store retentionDays
                     * @property {number|null} [ownerId] Store ownerId
                     * @property {palexy.streaming.v1.Store.StoreType|null} [storeType] Store storeType
                     * @property {string|null} [openTime] Store openTime
                     * @property {string|null} [closeTime] Store closeTime
                     * @property {number|null} [companyId] Store companyId
                     * @property {palexy.streaming.v1.ICompany|null} [company] Store company
                     * @property {number|null} [trackingRetentionDays] Store trackingRetentionDays
                     * @property {palexy.streaming.v1.IStoreIntegrationConfig|null} [storeIntegrationConfig] Store storeIntegrationConfig
                     * @property {Array.<palexy.streaming.v1.IArea>|null} [areas] Store areas
                     * @property {palexy.streaming.v1.Store.StoreState|null} [storeState] Store storeState
                     * @property {string|null} [timezone] Store timezone
                     * @property {palexy.streaming.v1.ICustomStreamingTime|null} [customStreamingTime] Store customStreamingTime
                     * @property {palexy.streaming.v1.IListWeekdayCustomStreamingTime|null} [listWeekdayCustomStreamingTime] Store listWeekdayCustomStreamingTime
                     * @property {string|null} [mergeQueueName] Store mergeQueueName
                     * @property {Array.<palexy.streaming.v1.ITimeRange>|null} [breakTimes] Store breakTimes
                     * @property {palexy.streaming.v1.IGeoCoordinate|null} [geoCoordinate] Store geoCoordinate
                     * @property {palexy.streaming.v1.Store.CountingType|null} [countingType] Store countingType
                     * @property {string|null} [defaultStreamingLocation] Store defaultStreamingLocation
                     * @property {string|null} [code] Store code
                     * @property {string|null} [country] Store country
                     * @property {string|null} [locality] Store locality
                     * @property {string|null} [addressPrimary] Store addressPrimary
                     * @property {string|null} [adminArea] Store adminArea
                     * @property {number|null} [onTimeGreetingThresholdSec] Store onTimeGreetingThresholdSec
                     * @property {boolean|null} [externalTrafficCountingEnabled] Store externalTrafficCountingEnabled
                     * @property {Array.<palexy.store_issue.v1.StoreIssueType>|null} [disabledIssueTypes] Store disabledIssueTypes
                     * @property {palexy.streaming.v1.Store.RelationshipType|null} [relationshipType] Store relationshipType
                     * @property {number|null} [parentStoreId] Store parentStoreId
                     */
    
                    /**
                     * Constructs a new Store.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Store.
                     * @implements IStore
                     * @constructor
                     * @param {palexy.streaming.v1.IStore=} [properties] Properties to set
                     */
                    function Store(properties) {
                        this.areas = [];
                        this.breakTimes = [];
                        this.disabledIssueTypes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Store id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Store name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.name = "";
    
                    /**
                     * Store displayName.
                     * @member {string} displayName
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.displayName = "";
    
                    /**
                     * Store retentionDays.
                     * @member {number} retentionDays
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.retentionDays = 0;
    
                    /**
                     * Store ownerId.
                     * @member {number} ownerId
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.ownerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Store storeType.
                     * @member {palexy.streaming.v1.Store.StoreType} storeType
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.storeType = 0;
    
                    /**
                     * Store openTime.
                     * @member {string} openTime
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.openTime = "";
    
                    /**
                     * Store closeTime.
                     * @member {string} closeTime
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.closeTime = "";
    
                    /**
                     * Store companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Store company.
                     * @member {palexy.streaming.v1.ICompany|null|undefined} company
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.company = null;
    
                    /**
                     * Store trackingRetentionDays.
                     * @member {number} trackingRetentionDays
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.trackingRetentionDays = 0;
    
                    /**
                     * Store storeIntegrationConfig.
                     * @member {palexy.streaming.v1.IStoreIntegrationConfig|null|undefined} storeIntegrationConfig
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.storeIntegrationConfig = null;
    
                    /**
                     * Store areas.
                     * @member {Array.<palexy.streaming.v1.IArea>} areas
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.areas = $util.emptyArray;
    
                    /**
                     * Store storeState.
                     * @member {palexy.streaming.v1.Store.StoreState} storeState
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.storeState = 0;
    
                    /**
                     * Store timezone.
                     * @member {string} timezone
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.timezone = "";
    
                    /**
                     * Store customStreamingTime.
                     * @member {palexy.streaming.v1.ICustomStreamingTime|null|undefined} customStreamingTime
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.customStreamingTime = null;
    
                    /**
                     * Store listWeekdayCustomStreamingTime.
                     * @member {palexy.streaming.v1.IListWeekdayCustomStreamingTime|null|undefined} listWeekdayCustomStreamingTime
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.listWeekdayCustomStreamingTime = null;
    
                    /**
                     * Store mergeQueueName.
                     * @member {string} mergeQueueName
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.mergeQueueName = "";
    
                    /**
                     * Store breakTimes.
                     * @member {Array.<palexy.streaming.v1.ITimeRange>} breakTimes
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.breakTimes = $util.emptyArray;
    
                    /**
                     * Store geoCoordinate.
                     * @member {palexy.streaming.v1.IGeoCoordinate|null|undefined} geoCoordinate
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.geoCoordinate = null;
    
                    /**
                     * Store countingType.
                     * @member {palexy.streaming.v1.Store.CountingType} countingType
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.countingType = 0;
    
                    /**
                     * Store defaultStreamingLocation.
                     * @member {string} defaultStreamingLocation
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.defaultStreamingLocation = "";
    
                    /**
                     * Store code.
                     * @member {string} code
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.code = "";
    
                    /**
                     * Store country.
                     * @member {string} country
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.country = "";
    
                    /**
                     * Store locality.
                     * @member {string} locality
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.locality = "";
    
                    /**
                     * Store addressPrimary.
                     * @member {string} addressPrimary
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.addressPrimary = "";
    
                    /**
                     * Store adminArea.
                     * @member {string} adminArea
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.adminArea = "";
    
                    /**
                     * Store onTimeGreetingThresholdSec.
                     * @member {number} onTimeGreetingThresholdSec
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.onTimeGreetingThresholdSec = 0;
    
                    /**
                     * Store externalTrafficCountingEnabled.
                     * @member {boolean} externalTrafficCountingEnabled
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.externalTrafficCountingEnabled = false;
    
                    /**
                     * Store disabledIssueTypes.
                     * @member {Array.<palexy.store_issue.v1.StoreIssueType>} disabledIssueTypes
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.disabledIssueTypes = $util.emptyArray;
    
                    /**
                     * Store relationshipType.
                     * @member {palexy.streaming.v1.Store.RelationshipType} relationshipType
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.relationshipType = 0;
    
                    /**
                     * Store parentStoreId.
                     * @member {number} parentStoreId
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     */
                    Store.prototype.parentStoreId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new Store instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Store
                     * @static
                     * @param {palexy.streaming.v1.IStore=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Store} Store instance
                     */
                    Store.create = function create(properties) {
                        return new Store(properties);
                    };
    
                    /**
                     * Encodes the specified Store message. Does not implicitly {@link palexy.streaming.v1.Store.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Store
                     * @static
                     * @param {palexy.streaming.v1.IStore} message Store message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Store.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.displayName);
                        if (message.retentionDays != null && Object.hasOwnProperty.call(message, "retentionDays"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.retentionDays);
                        if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.ownerId);
                        if (message.storeType != null && Object.hasOwnProperty.call(message, "storeType"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.storeType);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.companyId);
                        if (message.company != null && Object.hasOwnProperty.call(message, "company"))
                            $root.palexy.streaming.v1.Company.encode(message.company, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.trackingRetentionDays != null && Object.hasOwnProperty.call(message, "trackingRetentionDays"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.trackingRetentionDays);
                        if (message.storeIntegrationConfig != null && Object.hasOwnProperty.call(message, "storeIntegrationConfig"))
                            $root.palexy.streaming.v1.StoreIntegrationConfig.encode(message.storeIntegrationConfig, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.areas != null && message.areas.length)
                            for (var i = 0; i < message.areas.length; ++i)
                                $root.palexy.streaming.v1.Area.encode(message.areas[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.openTime != null && Object.hasOwnProperty.call(message, "openTime"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.openTime);
                        if (message.closeTime != null && Object.hasOwnProperty.call(message, "closeTime"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.closeTime);
                        if (message.storeState != null && Object.hasOwnProperty.call(message, "storeState"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.storeState);
                        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.timezone);
                        if (message.customStreamingTime != null && Object.hasOwnProperty.call(message, "customStreamingTime"))
                            $root.palexy.streaming.v1.CustomStreamingTime.encode(message.customStreamingTime, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.listWeekdayCustomStreamingTime != null && Object.hasOwnProperty.call(message, "listWeekdayCustomStreamingTime"))
                            $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime.encode(message.listWeekdayCustomStreamingTime, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        if (message.mergeQueueName != null && Object.hasOwnProperty.call(message, "mergeQueueName"))
                            writer.uint32(/* id 18, wireType 2 =*/146).string(message.mergeQueueName);
                        if (message.breakTimes != null && message.breakTimes.length)
                            for (var i = 0; i < message.breakTimes.length; ++i)
                                $root.palexy.streaming.v1.TimeRange.encode(message.breakTimes[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                        if (message.geoCoordinate != null && Object.hasOwnProperty.call(message, "geoCoordinate"))
                            $root.palexy.streaming.v1.GeoCoordinate.encode(message.geoCoordinate, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                        if (message.countingType != null && Object.hasOwnProperty.call(message, "countingType"))
                            writer.uint32(/* id 22, wireType 0 =*/176).int32(message.countingType);
                        if (message.defaultStreamingLocation != null && Object.hasOwnProperty.call(message, "defaultStreamingLocation"))
                            writer.uint32(/* id 23, wireType 2 =*/186).string(message.defaultStreamingLocation);
                        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                            writer.uint32(/* id 24, wireType 2 =*/194).string(message.code);
                        if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                            writer.uint32(/* id 25, wireType 2 =*/202).string(message.country);
                        if (message.locality != null && Object.hasOwnProperty.call(message, "locality"))
                            writer.uint32(/* id 26, wireType 2 =*/210).string(message.locality);
                        if (message.addressPrimary != null && Object.hasOwnProperty.call(message, "addressPrimary"))
                            writer.uint32(/* id 27, wireType 2 =*/218).string(message.addressPrimary);
                        if (message.adminArea != null && Object.hasOwnProperty.call(message, "adminArea"))
                            writer.uint32(/* id 28, wireType 2 =*/226).string(message.adminArea);
                        if (message.onTimeGreetingThresholdSec != null && Object.hasOwnProperty.call(message, "onTimeGreetingThresholdSec"))
                            writer.uint32(/* id 29, wireType 0 =*/232).int32(message.onTimeGreetingThresholdSec);
                        if (message.externalTrafficCountingEnabled != null && Object.hasOwnProperty.call(message, "externalTrafficCountingEnabled"))
                            writer.uint32(/* id 30, wireType 0 =*/240).bool(message.externalTrafficCountingEnabled);
                        if (message.disabledIssueTypes != null && message.disabledIssueTypes.length) {
                            writer.uint32(/* id 31, wireType 2 =*/250).fork();
                            for (var i = 0; i < message.disabledIssueTypes.length; ++i)
                                writer.int32(message.disabledIssueTypes[i]);
                            writer.ldelim();
                        }
                        if (message.relationshipType != null && Object.hasOwnProperty.call(message, "relationshipType"))
                            writer.uint32(/* id 32, wireType 0 =*/256).int32(message.relationshipType);
                        if (message.parentStoreId != null && Object.hasOwnProperty.call(message, "parentStoreId"))
                            writer.uint32(/* id 33, wireType 0 =*/264).int64(message.parentStoreId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Store message, length delimited. Does not implicitly {@link palexy.streaming.v1.Store.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Store
                     * @static
                     * @param {palexy.streaming.v1.IStore} message Store message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Store.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Store message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Store
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Store} Store
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Store.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Store();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.displayName = reader.string();
                                break;
                            case 4:
                                message.retentionDays = reader.int32();
                                break;
                            case 5:
                                message.ownerId = reader.int64();
                                break;
                            case 6:
                                message.storeType = reader.int32();
                                break;
                            case 12:
                                message.openTime = reader.string();
                                break;
                            case 13:
                                message.closeTime = reader.string();
                                break;
                            case 7:
                                message.companyId = reader.int64();
                                break;
                            case 8:
                                message.company = $root.palexy.streaming.v1.Company.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.trackingRetentionDays = reader.int32();
                                break;
                            case 10:
                                message.storeIntegrationConfig = $root.palexy.streaming.v1.StoreIntegrationConfig.decode(reader, reader.uint32());
                                break;
                            case 11:
                                if (!(message.areas && message.areas.length))
                                    message.areas = [];
                                message.areas.push($root.palexy.streaming.v1.Area.decode(reader, reader.uint32()));
                                break;
                            case 14:
                                message.storeState = reader.int32();
                                break;
                            case 15:
                                message.timezone = reader.string();
                                break;
                            case 16:
                                message.customStreamingTime = $root.palexy.streaming.v1.CustomStreamingTime.decode(reader, reader.uint32());
                                break;
                            case 17:
                                message.listWeekdayCustomStreamingTime = $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime.decode(reader, reader.uint32());
                                break;
                            case 18:
                                message.mergeQueueName = reader.string();
                                break;
                            case 19:
                                if (!(message.breakTimes && message.breakTimes.length))
                                    message.breakTimes = [];
                                message.breakTimes.push($root.palexy.streaming.v1.TimeRange.decode(reader, reader.uint32()));
                                break;
                            case 21:
                                message.geoCoordinate = $root.palexy.streaming.v1.GeoCoordinate.decode(reader, reader.uint32());
                                break;
                            case 22:
                                message.countingType = reader.int32();
                                break;
                            case 23:
                                message.defaultStreamingLocation = reader.string();
                                break;
                            case 24:
                                message.code = reader.string();
                                break;
                            case 25:
                                message.country = reader.string();
                                break;
                            case 26:
                                message.locality = reader.string();
                                break;
                            case 27:
                                message.addressPrimary = reader.string();
                                break;
                            case 28:
                                message.adminArea = reader.string();
                                break;
                            case 29:
                                message.onTimeGreetingThresholdSec = reader.int32();
                                break;
                            case 30:
                                message.externalTrafficCountingEnabled = reader.bool();
                                break;
                            case 31:
                                if (!(message.disabledIssueTypes && message.disabledIssueTypes.length))
                                    message.disabledIssueTypes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.disabledIssueTypes.push(reader.int32());
                                } else
                                    message.disabledIssueTypes.push(reader.int32());
                                break;
                            case 32:
                                message.relationshipType = reader.int32();
                                break;
                            case 33:
                                message.parentStoreId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Store message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Store
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Store} Store
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Store.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Store message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Store
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Store.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        if (message.retentionDays != null && message.hasOwnProperty("retentionDays"))
                            if (!$util.isInteger(message.retentionDays))
                                return "retentionDays: integer expected";
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (!$util.isInteger(message.ownerId) && !(message.ownerId && $util.isInteger(message.ownerId.low) && $util.isInteger(message.ownerId.high)))
                                return "ownerId: integer|Long expected";
                        if (message.storeType != null && message.hasOwnProperty("storeType"))
                            switch (message.storeType) {
                            default:
                                return "storeType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.openTime != null && message.hasOwnProperty("openTime"))
                            if (!$util.isString(message.openTime))
                                return "openTime: string expected";
                        if (message.closeTime != null && message.hasOwnProperty("closeTime"))
                            if (!$util.isString(message.closeTime))
                                return "closeTime: string expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.company != null && message.hasOwnProperty("company")) {
                            var error = $root.palexy.streaming.v1.Company.verify(message.company);
                            if (error)
                                return "company." + error;
                        }
                        if (message.trackingRetentionDays != null && message.hasOwnProperty("trackingRetentionDays"))
                            if (!$util.isInteger(message.trackingRetentionDays))
                                return "trackingRetentionDays: integer expected";
                        if (message.storeIntegrationConfig != null && message.hasOwnProperty("storeIntegrationConfig")) {
                            var error = $root.palexy.streaming.v1.StoreIntegrationConfig.verify(message.storeIntegrationConfig);
                            if (error)
                                return "storeIntegrationConfig." + error;
                        }
                        if (message.areas != null && message.hasOwnProperty("areas")) {
                            if (!Array.isArray(message.areas))
                                return "areas: array expected";
                            for (var i = 0; i < message.areas.length; ++i) {
                                var error = $root.palexy.streaming.v1.Area.verify(message.areas[i]);
                                if (error)
                                    return "areas." + error;
                            }
                        }
                        if (message.storeState != null && message.hasOwnProperty("storeState"))
                            switch (message.storeState) {
                            default:
                                return "storeState: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            if (!$util.isString(message.timezone))
                                return "timezone: string expected";
                        if (message.customStreamingTime != null && message.hasOwnProperty("customStreamingTime")) {
                            var error = $root.palexy.streaming.v1.CustomStreamingTime.verify(message.customStreamingTime);
                            if (error)
                                return "customStreamingTime." + error;
                        }
                        if (message.listWeekdayCustomStreamingTime != null && message.hasOwnProperty("listWeekdayCustomStreamingTime")) {
                            var error = $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime.verify(message.listWeekdayCustomStreamingTime);
                            if (error)
                                return "listWeekdayCustomStreamingTime." + error;
                        }
                        if (message.mergeQueueName != null && message.hasOwnProperty("mergeQueueName"))
                            if (!$util.isString(message.mergeQueueName))
                                return "mergeQueueName: string expected";
                        if (message.breakTimes != null && message.hasOwnProperty("breakTimes")) {
                            if (!Array.isArray(message.breakTimes))
                                return "breakTimes: array expected";
                            for (var i = 0; i < message.breakTimes.length; ++i) {
                                var error = $root.palexy.streaming.v1.TimeRange.verify(message.breakTimes[i]);
                                if (error)
                                    return "breakTimes." + error;
                            }
                        }
                        if (message.geoCoordinate != null && message.hasOwnProperty("geoCoordinate")) {
                            var error = $root.palexy.streaming.v1.GeoCoordinate.verify(message.geoCoordinate);
                            if (error)
                                return "geoCoordinate." + error;
                        }
                        if (message.countingType != null && message.hasOwnProperty("countingType"))
                            switch (message.countingType) {
                            default:
                                return "countingType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.defaultStreamingLocation != null && message.hasOwnProperty("defaultStreamingLocation"))
                            if (!$util.isString(message.defaultStreamingLocation))
                                return "defaultStreamingLocation: string expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        if (message.country != null && message.hasOwnProperty("country"))
                            if (!$util.isString(message.country))
                                return "country: string expected";
                        if (message.locality != null && message.hasOwnProperty("locality"))
                            if (!$util.isString(message.locality))
                                return "locality: string expected";
                        if (message.addressPrimary != null && message.hasOwnProperty("addressPrimary"))
                            if (!$util.isString(message.addressPrimary))
                                return "addressPrimary: string expected";
                        if (message.adminArea != null && message.hasOwnProperty("adminArea"))
                            if (!$util.isString(message.adminArea))
                                return "adminArea: string expected";
                        if (message.onTimeGreetingThresholdSec != null && message.hasOwnProperty("onTimeGreetingThresholdSec"))
                            if (!$util.isInteger(message.onTimeGreetingThresholdSec))
                                return "onTimeGreetingThresholdSec: integer expected";
                        if (message.externalTrafficCountingEnabled != null && message.hasOwnProperty("externalTrafficCountingEnabled"))
                            if (typeof message.externalTrafficCountingEnabled !== "boolean")
                                return "externalTrafficCountingEnabled: boolean expected";
                        if (message.disabledIssueTypes != null && message.hasOwnProperty("disabledIssueTypes")) {
                            if (!Array.isArray(message.disabledIssueTypes))
                                return "disabledIssueTypes: array expected";
                            for (var i = 0; i < message.disabledIssueTypes.length; ++i)
                                switch (message.disabledIssueTypes[i]) {
                                default:
                                    return "disabledIssueTypes: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    break;
                                }
                        }
                        if (message.relationshipType != null && message.hasOwnProperty("relationshipType"))
                            switch (message.relationshipType) {
                            default:
                                return "relationshipType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.parentStoreId != null && message.hasOwnProperty("parentStoreId"))
                            if (!$util.isInteger(message.parentStoreId) && !(message.parentStoreId && $util.isInteger(message.parentStoreId.low) && $util.isInteger(message.parentStoreId.high)))
                                return "parentStoreId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a Store message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Store
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Store} Store
                     */
                    Store.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Store)
                            return object;
                        var message = new $root.palexy.streaming.v1.Store();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        if (object.retentionDays != null)
                            message.retentionDays = object.retentionDays | 0;
                        if (object.ownerId != null)
                            if ($util.Long)
                                (message.ownerId = $util.Long.fromValue(object.ownerId)).unsigned = false;
                            else if (typeof object.ownerId === "string")
                                message.ownerId = parseInt(object.ownerId, 10);
                            else if (typeof object.ownerId === "number")
                                message.ownerId = object.ownerId;
                            else if (typeof object.ownerId === "object")
                                message.ownerId = new $util.LongBits(object.ownerId.low >>> 0, object.ownerId.high >>> 0).toNumber();
                        switch (object.storeType) {
                        case "UNKNOWN":
                        case 0:
                            message.storeType = 0;
                            break;
                        case "INDEPENDENT":
                        case 1:
                            message.storeType = 1;
                            break;
                        case "SHOPPING_MALL":
                        case 2:
                            message.storeType = 2;
                            break;
                        }
                        if (object.openTime != null)
                            message.openTime = String(object.openTime);
                        if (object.closeTime != null)
                            message.closeTime = String(object.closeTime);
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.company != null) {
                            if (typeof object.company !== "object")
                                throw TypeError(".palexy.streaming.v1.Store.company: object expected");
                            message.company = $root.palexy.streaming.v1.Company.fromObject(object.company);
                        }
                        if (object.trackingRetentionDays != null)
                            message.trackingRetentionDays = object.trackingRetentionDays | 0;
                        if (object.storeIntegrationConfig != null) {
                            if (typeof object.storeIntegrationConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.Store.storeIntegrationConfig: object expected");
                            message.storeIntegrationConfig = $root.palexy.streaming.v1.StoreIntegrationConfig.fromObject(object.storeIntegrationConfig);
                        }
                        if (object.areas) {
                            if (!Array.isArray(object.areas))
                                throw TypeError(".palexy.streaming.v1.Store.areas: array expected");
                            message.areas = [];
                            for (var i = 0; i < object.areas.length; ++i) {
                                if (typeof object.areas[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.Store.areas: object expected");
                                message.areas[i] = $root.palexy.streaming.v1.Area.fromObject(object.areas[i]);
                            }
                        }
                        switch (object.storeState) {
                        case "UNKNOWN_STATE":
                        case 0:
                            message.storeState = 0;
                            break;
                        case "TUNING":
                        case 1:
                            message.storeState = 1;
                            break;
                        case "PRODUCTION_LEVEL_1":
                        case 2:
                            message.storeState = 2;
                            break;
                        case "PRODUCTION_LEVEL_2":
                        case 3:
                            message.storeState = 3;
                            break;
                        }
                        if (object.timezone != null)
                            message.timezone = String(object.timezone);
                        if (object.customStreamingTime != null) {
                            if (typeof object.customStreamingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Store.customStreamingTime: object expected");
                            message.customStreamingTime = $root.palexy.streaming.v1.CustomStreamingTime.fromObject(object.customStreamingTime);
                        }
                        if (object.listWeekdayCustomStreamingTime != null) {
                            if (typeof object.listWeekdayCustomStreamingTime !== "object")
                                throw TypeError(".palexy.streaming.v1.Store.listWeekdayCustomStreamingTime: object expected");
                            message.listWeekdayCustomStreamingTime = $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime.fromObject(object.listWeekdayCustomStreamingTime);
                        }
                        if (object.mergeQueueName != null)
                            message.mergeQueueName = String(object.mergeQueueName);
                        if (object.breakTimes) {
                            if (!Array.isArray(object.breakTimes))
                                throw TypeError(".palexy.streaming.v1.Store.breakTimes: array expected");
                            message.breakTimes = [];
                            for (var i = 0; i < object.breakTimes.length; ++i) {
                                if (typeof object.breakTimes[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.Store.breakTimes: object expected");
                                message.breakTimes[i] = $root.palexy.streaming.v1.TimeRange.fromObject(object.breakTimes[i]);
                            }
                        }
                        if (object.geoCoordinate != null) {
                            if (typeof object.geoCoordinate !== "object")
                                throw TypeError(".palexy.streaming.v1.Store.geoCoordinate: object expected");
                            message.geoCoordinate = $root.palexy.streaming.v1.GeoCoordinate.fromObject(object.geoCoordinate);
                        }
                        switch (object.countingType) {
                        case "UNKNOWN_COUNTING_TYPE":
                        case 0:
                            message.countingType = 0;
                            break;
                        case "FULL_ANALYTIC":
                        case 1:
                            message.countingType = 1;
                            break;
                        case "TRAFFIC_COUNTING":
                        case 2:
                            message.countingType = 2;
                            break;
                        case "ENTRANCE_COUNTING":
                        case 3:
                            message.countingType = 3;
                            break;
                        case "MINIMAL_CAMERA_COUNTING":
                        case 4:
                            message.countingType = 4;
                            break;
                        }
                        if (object.defaultStreamingLocation != null)
                            message.defaultStreamingLocation = String(object.defaultStreamingLocation);
                        if (object.code != null)
                            message.code = String(object.code);
                        if (object.country != null)
                            message.country = String(object.country);
                        if (object.locality != null)
                            message.locality = String(object.locality);
                        if (object.addressPrimary != null)
                            message.addressPrimary = String(object.addressPrimary);
                        if (object.adminArea != null)
                            message.adminArea = String(object.adminArea);
                        if (object.onTimeGreetingThresholdSec != null)
                            message.onTimeGreetingThresholdSec = object.onTimeGreetingThresholdSec | 0;
                        if (object.externalTrafficCountingEnabled != null)
                            message.externalTrafficCountingEnabled = Boolean(object.externalTrafficCountingEnabled);
                        if (object.disabledIssueTypes) {
                            if (!Array.isArray(object.disabledIssueTypes))
                                throw TypeError(".palexy.streaming.v1.Store.disabledIssueTypes: array expected");
                            message.disabledIssueTypes = [];
                            for (var i = 0; i < object.disabledIssueTypes.length; ++i)
                                switch (object.disabledIssueTypes[i]) {
                                default:
                                case "UNKNOWN_STORE_ISSUE_TYPE":
                                case 0:
                                    message.disabledIssueTypes[i] = 0;
                                    break;
                                case "SECTION_CONFIG":
                                case 1:
                                    message.disabledIssueTypes[i] = 1;
                                    break;
                                case "UPLOAD_VIDEOS":
                                case 2:
                                    message.disabledIssueTypes[i] = 2;
                                    break;
                                case "PREPROCESSING":
                                case 3:
                                    message.disabledIssueTypes[i] = 3;
                                    break;
                                }
                        }
                        switch (object.relationshipType) {
                        case "UNKNOWN_RELATIONSHIP_TYPE":
                        case 0:
                            message.relationshipType = 0;
                            break;
                        case "NO_RELATIONSHIP":
                        case 1:
                            message.relationshipType = 1;
                            break;
                        case "PARENT":
                        case 2:
                            message.relationshipType = 2;
                            break;
                        case "CHILD":
                        case 3:
                            message.relationshipType = 3;
                            break;
                        }
                        if (object.parentStoreId != null)
                            if ($util.Long)
                                (message.parentStoreId = $util.Long.fromValue(object.parentStoreId)).unsigned = false;
                            else if (typeof object.parentStoreId === "string")
                                message.parentStoreId = parseInt(object.parentStoreId, 10);
                            else if (typeof object.parentStoreId === "number")
                                message.parentStoreId = object.parentStoreId;
                            else if (typeof object.parentStoreId === "object")
                                message.parentStoreId = new $util.LongBits(object.parentStoreId.low >>> 0, object.parentStoreId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Store message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Store
                     * @static
                     * @param {palexy.streaming.v1.Store} message Store
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Store.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.areas = [];
                            object.breakTimes = [];
                            object.disabledIssueTypes = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.displayName = "";
                            object.retentionDays = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.ownerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.ownerId = options.longs === String ? "0" : 0;
                            object.storeType = options.enums === String ? "UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.company = null;
                            object.trackingRetentionDays = 0;
                            object.storeIntegrationConfig = null;
                            object.openTime = "";
                            object.closeTime = "";
                            object.storeState = options.enums === String ? "UNKNOWN_STATE" : 0;
                            object.timezone = "";
                            object.customStreamingTime = null;
                            object.listWeekdayCustomStreamingTime = null;
                            object.mergeQueueName = "";
                            object.geoCoordinate = null;
                            object.countingType = options.enums === String ? "UNKNOWN_COUNTING_TYPE" : 0;
                            object.defaultStreamingLocation = "";
                            object.code = "";
                            object.country = "";
                            object.locality = "";
                            object.addressPrimary = "";
                            object.adminArea = "";
                            object.onTimeGreetingThresholdSec = 0;
                            object.externalTrafficCountingEnabled = false;
                            object.relationshipType = options.enums === String ? "UNKNOWN_RELATIONSHIP_TYPE" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.parentStoreId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.parentStoreId = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        if (message.retentionDays != null && message.hasOwnProperty("retentionDays"))
                            object.retentionDays = message.retentionDays;
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (typeof message.ownerId === "number")
                                object.ownerId = options.longs === String ? String(message.ownerId) : message.ownerId;
                            else
                                object.ownerId = options.longs === String ? $util.Long.prototype.toString.call(message.ownerId) : options.longs === Number ? new $util.LongBits(message.ownerId.low >>> 0, message.ownerId.high >>> 0).toNumber() : message.ownerId;
                        if (message.storeType != null && message.hasOwnProperty("storeType"))
                            object.storeType = options.enums === String ? $root.palexy.streaming.v1.Store.StoreType[message.storeType] : message.storeType;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.company != null && message.hasOwnProperty("company"))
                            object.company = $root.palexy.streaming.v1.Company.toObject(message.company, options);
                        if (message.trackingRetentionDays != null && message.hasOwnProperty("trackingRetentionDays"))
                            object.trackingRetentionDays = message.trackingRetentionDays;
                        if (message.storeIntegrationConfig != null && message.hasOwnProperty("storeIntegrationConfig"))
                            object.storeIntegrationConfig = $root.palexy.streaming.v1.StoreIntegrationConfig.toObject(message.storeIntegrationConfig, options);
                        if (message.areas && message.areas.length) {
                            object.areas = [];
                            for (var j = 0; j < message.areas.length; ++j)
                                object.areas[j] = $root.palexy.streaming.v1.Area.toObject(message.areas[j], options);
                        }
                        if (message.openTime != null && message.hasOwnProperty("openTime"))
                            object.openTime = message.openTime;
                        if (message.closeTime != null && message.hasOwnProperty("closeTime"))
                            object.closeTime = message.closeTime;
                        if (message.storeState != null && message.hasOwnProperty("storeState"))
                            object.storeState = options.enums === String ? $root.palexy.streaming.v1.Store.StoreState[message.storeState] : message.storeState;
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            object.timezone = message.timezone;
                        if (message.customStreamingTime != null && message.hasOwnProperty("customStreamingTime"))
                            object.customStreamingTime = $root.palexy.streaming.v1.CustomStreamingTime.toObject(message.customStreamingTime, options);
                        if (message.listWeekdayCustomStreamingTime != null && message.hasOwnProperty("listWeekdayCustomStreamingTime"))
                            object.listWeekdayCustomStreamingTime = $root.palexy.streaming.v1.ListWeekdayCustomStreamingTime.toObject(message.listWeekdayCustomStreamingTime, options);
                        if (message.mergeQueueName != null && message.hasOwnProperty("mergeQueueName"))
                            object.mergeQueueName = message.mergeQueueName;
                        if (message.breakTimes && message.breakTimes.length) {
                            object.breakTimes = [];
                            for (var j = 0; j < message.breakTimes.length; ++j)
                                object.breakTimes[j] = $root.palexy.streaming.v1.TimeRange.toObject(message.breakTimes[j], options);
                        }
                        if (message.geoCoordinate != null && message.hasOwnProperty("geoCoordinate"))
                            object.geoCoordinate = $root.palexy.streaming.v1.GeoCoordinate.toObject(message.geoCoordinate, options);
                        if (message.countingType != null && message.hasOwnProperty("countingType"))
                            object.countingType = options.enums === String ? $root.palexy.streaming.v1.Store.CountingType[message.countingType] : message.countingType;
                        if (message.defaultStreamingLocation != null && message.hasOwnProperty("defaultStreamingLocation"))
                            object.defaultStreamingLocation = message.defaultStreamingLocation;
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        if (message.country != null && message.hasOwnProperty("country"))
                            object.country = message.country;
                        if (message.locality != null && message.hasOwnProperty("locality"))
                            object.locality = message.locality;
                        if (message.addressPrimary != null && message.hasOwnProperty("addressPrimary"))
                            object.addressPrimary = message.addressPrimary;
                        if (message.adminArea != null && message.hasOwnProperty("adminArea"))
                            object.adminArea = message.adminArea;
                        if (message.onTimeGreetingThresholdSec != null && message.hasOwnProperty("onTimeGreetingThresholdSec"))
                            object.onTimeGreetingThresholdSec = message.onTimeGreetingThresholdSec;
                        if (message.externalTrafficCountingEnabled != null && message.hasOwnProperty("externalTrafficCountingEnabled"))
                            object.externalTrafficCountingEnabled = message.externalTrafficCountingEnabled;
                        if (message.disabledIssueTypes && message.disabledIssueTypes.length) {
                            object.disabledIssueTypes = [];
                            for (var j = 0; j < message.disabledIssueTypes.length; ++j)
                                object.disabledIssueTypes[j] = options.enums === String ? $root.palexy.store_issue.v1.StoreIssueType[message.disabledIssueTypes[j]] : message.disabledIssueTypes[j];
                        }
                        if (message.relationshipType != null && message.hasOwnProperty("relationshipType"))
                            object.relationshipType = options.enums === String ? $root.palexy.streaming.v1.Store.RelationshipType[message.relationshipType] : message.relationshipType;
                        if (message.parentStoreId != null && message.hasOwnProperty("parentStoreId"))
                            if (typeof message.parentStoreId === "number")
                                object.parentStoreId = options.longs === String ? String(message.parentStoreId) : message.parentStoreId;
                            else
                                object.parentStoreId = options.longs === String ? $util.Long.prototype.toString.call(message.parentStoreId) : options.longs === Number ? new $util.LongBits(message.parentStoreId.low >>> 0, message.parentStoreId.high >>> 0).toNumber() : message.parentStoreId;
                        return object;
                    };
    
                    /**
                     * Converts this Store to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Store
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Store.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * StoreType enum.
                     * @name palexy.streaming.v1.Store.StoreType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} INDEPENDENT=1 INDEPENDENT value
                     * @property {number} SHOPPING_MALL=2 SHOPPING_MALL value
                     */
                    Store.StoreType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "INDEPENDENT"] = 1;
                        values[valuesById[2] = "SHOPPING_MALL"] = 2;
                        return values;
                    })();
    
                    /**
                     * StoreState enum.
                     * @name palexy.streaming.v1.Store.StoreState
                     * @enum {number}
                     * @property {number} UNKNOWN_STATE=0 UNKNOWN_STATE value
                     * @property {number} TUNING=1 TUNING value
                     * @property {number} PRODUCTION_LEVEL_1=2 PRODUCTION_LEVEL_1 value
                     * @property {number} PRODUCTION_LEVEL_2=3 PRODUCTION_LEVEL_2 value
                     */
                    Store.StoreState = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_STATE"] = 0;
                        values[valuesById[1] = "TUNING"] = 1;
                        values[valuesById[2] = "PRODUCTION_LEVEL_1"] = 2;
                        values[valuesById[3] = "PRODUCTION_LEVEL_2"] = 3;
                        return values;
                    })();
    
                    /**
                     * CountingType enum.
                     * @name palexy.streaming.v1.Store.CountingType
                     * @enum {number}
                     * @property {number} UNKNOWN_COUNTING_TYPE=0 UNKNOWN_COUNTING_TYPE value
                     * @property {number} FULL_ANALYTIC=1 FULL_ANALYTIC value
                     * @property {number} TRAFFIC_COUNTING=2 TRAFFIC_COUNTING value
                     * @property {number} ENTRANCE_COUNTING=3 ENTRANCE_COUNTING value
                     * @property {number} MINIMAL_CAMERA_COUNTING=4 MINIMAL_CAMERA_COUNTING value
                     */
                    Store.CountingType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_COUNTING_TYPE"] = 0;
                        values[valuesById[1] = "FULL_ANALYTIC"] = 1;
                        values[valuesById[2] = "TRAFFIC_COUNTING"] = 2;
                        values[valuesById[3] = "ENTRANCE_COUNTING"] = 3;
                        values[valuesById[4] = "MINIMAL_CAMERA_COUNTING"] = 4;
                        return values;
                    })();
    
                    /**
                     * RelationshipType enum.
                     * @name palexy.streaming.v1.Store.RelationshipType
                     * @enum {number}
                     * @property {number} UNKNOWN_RELATIONSHIP_TYPE=0 UNKNOWN_RELATIONSHIP_TYPE value
                     * @property {number} NO_RELATIONSHIP=1 NO_RELATIONSHIP value
                     * @property {number} PARENT=2 PARENT value
                     * @property {number} CHILD=3 CHILD value
                     */
                    Store.RelationshipType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_RELATIONSHIP_TYPE"] = 0;
                        values[valuesById[1] = "NO_RELATIONSHIP"] = 1;
                        values[valuesById[2] = "PARENT"] = 2;
                        values[valuesById[3] = "CHILD"] = 3;
                        return values;
                    })();
    
                    return Store;
                })();
    
                v1.StoreIntegrationConfig = (function() {
    
                    /**
                     * Properties of a StoreIntegrationConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreIntegrationConfig
                     * @property {string|null} [posStoreCode] StoreIntegrationConfig posStoreCode
                     * @property {string|null} [staffingStoreCode] StoreIntegrationConfig staffingStoreCode
                     * @property {string|null} [externalTrafficStoreCode] StoreIntegrationConfig externalTrafficStoreCode
                     */
    
                    /**
                     * Constructs a new StoreIntegrationConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreIntegrationConfig.
                     * @implements IStoreIntegrationConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreIntegrationConfig=} [properties] Properties to set
                     */
                    function StoreIntegrationConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreIntegrationConfig posStoreCode.
                     * @member {string} posStoreCode
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @instance
                     */
                    StoreIntegrationConfig.prototype.posStoreCode = "";
    
                    /**
                     * StoreIntegrationConfig staffingStoreCode.
                     * @member {string} staffingStoreCode
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @instance
                     */
                    StoreIntegrationConfig.prototype.staffingStoreCode = "";
    
                    /**
                     * StoreIntegrationConfig externalTrafficStoreCode.
                     * @member {string} externalTrafficStoreCode
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @instance
                     */
                    StoreIntegrationConfig.prototype.externalTrafficStoreCode = "";
    
                    /**
                     * Creates a new StoreIntegrationConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @static
                     * @param {palexy.streaming.v1.IStoreIntegrationConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreIntegrationConfig} StoreIntegrationConfig instance
                     */
                    StoreIntegrationConfig.create = function create(properties) {
                        return new StoreIntegrationConfig(properties);
                    };
    
                    /**
                     * Encodes the specified StoreIntegrationConfig message. Does not implicitly {@link palexy.streaming.v1.StoreIntegrationConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @static
                     * @param {palexy.streaming.v1.IStoreIntegrationConfig} message StoreIntegrationConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreIntegrationConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.posStoreCode != null && Object.hasOwnProperty.call(message, "posStoreCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.posStoreCode);
                        if (message.staffingStoreCode != null && Object.hasOwnProperty.call(message, "staffingStoreCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.staffingStoreCode);
                        if (message.externalTrafficStoreCode != null && Object.hasOwnProperty.call(message, "externalTrafficStoreCode"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalTrafficStoreCode);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreIntegrationConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreIntegrationConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @static
                     * @param {palexy.streaming.v1.IStoreIntegrationConfig} message StoreIntegrationConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreIntegrationConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreIntegrationConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreIntegrationConfig} StoreIntegrationConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreIntegrationConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreIntegrationConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.posStoreCode = reader.string();
                                break;
                            case 2:
                                message.staffingStoreCode = reader.string();
                                break;
                            case 3:
                                message.externalTrafficStoreCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreIntegrationConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreIntegrationConfig} StoreIntegrationConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreIntegrationConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreIntegrationConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreIntegrationConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.posStoreCode != null && message.hasOwnProperty("posStoreCode"))
                            if (!$util.isString(message.posStoreCode))
                                return "posStoreCode: string expected";
                        if (message.staffingStoreCode != null && message.hasOwnProperty("staffingStoreCode"))
                            if (!$util.isString(message.staffingStoreCode))
                                return "staffingStoreCode: string expected";
                        if (message.externalTrafficStoreCode != null && message.hasOwnProperty("externalTrafficStoreCode"))
                            if (!$util.isString(message.externalTrafficStoreCode))
                                return "externalTrafficStoreCode: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a StoreIntegrationConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreIntegrationConfig} StoreIntegrationConfig
                     */
                    StoreIntegrationConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreIntegrationConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreIntegrationConfig();
                        if (object.posStoreCode != null)
                            message.posStoreCode = String(object.posStoreCode);
                        if (object.staffingStoreCode != null)
                            message.staffingStoreCode = String(object.staffingStoreCode);
                        if (object.externalTrafficStoreCode != null)
                            message.externalTrafficStoreCode = String(object.externalTrafficStoreCode);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreIntegrationConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @static
                     * @param {palexy.streaming.v1.StoreIntegrationConfig} message StoreIntegrationConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreIntegrationConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.posStoreCode = "";
                            object.staffingStoreCode = "";
                            object.externalTrafficStoreCode = "";
                        }
                        if (message.posStoreCode != null && message.hasOwnProperty("posStoreCode"))
                            object.posStoreCode = message.posStoreCode;
                        if (message.staffingStoreCode != null && message.hasOwnProperty("staffingStoreCode"))
                            object.staffingStoreCode = message.staffingStoreCode;
                        if (message.externalTrafficStoreCode != null && message.hasOwnProperty("externalTrafficStoreCode"))
                            object.externalTrafficStoreCode = message.externalTrafficStoreCode;
                        return object;
                    };
    
                    /**
                     * Converts this StoreIntegrationConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreIntegrationConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreIntegrationConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StoreIntegrationConfig;
                })();
    
                v1.GetStoreRequest = (function() {
    
                    /**
                     * Properties of a GetStoreRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStoreRequest
                     * @property {string|null} [name] GetStoreRequest name
                     * @property {number|null} [id] GetStoreRequest id
                     * @property {boolean|null} [includeCompanyInfo] GetStoreRequest includeCompanyInfo
                     * @property {boolean|null} [includeStoreIntegrationConfig] GetStoreRequest includeStoreIntegrationConfig
                     * @property {boolean|null} [includeAreaInfo] GetStoreRequest includeAreaInfo
                     * @property {google.protobuf.IFieldMask|null} [fieldMask] GetStoreRequest fieldMask
                     */
    
                    /**
                     * Constructs a new GetStoreRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStoreRequest.
                     * @implements IGetStoreRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStoreRequest=} [properties] Properties to set
                     */
                    function GetStoreRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStoreRequest name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @instance
                     */
                    GetStoreRequest.prototype.name = "";
    
                    /**
                     * GetStoreRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @instance
                     */
                    GetStoreRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetStoreRequest includeCompanyInfo.
                     * @member {boolean} includeCompanyInfo
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @instance
                     */
                    GetStoreRequest.prototype.includeCompanyInfo = false;
    
                    /**
                     * GetStoreRequest includeStoreIntegrationConfig.
                     * @member {boolean} includeStoreIntegrationConfig
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @instance
                     */
                    GetStoreRequest.prototype.includeStoreIntegrationConfig = false;
    
                    /**
                     * GetStoreRequest includeAreaInfo.
                     * @member {boolean} includeAreaInfo
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @instance
                     */
                    GetStoreRequest.prototype.includeAreaInfo = false;
    
                    /**
                     * GetStoreRequest fieldMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} fieldMask
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @instance
                     */
                    GetStoreRequest.prototype.fieldMask = null;
    
                    /**
                     * Creates a new GetStoreRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStoreRequest} GetStoreRequest instance
                     */
                    GetStoreRequest.create = function create(properties) {
                        return new GetStoreRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStoreRequest message. Does not implicitly {@link palexy.streaming.v1.GetStoreRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreRequest} message GetStoreRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.id);
                        if (message.includeCompanyInfo != null && Object.hasOwnProperty.call(message, "includeCompanyInfo"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeCompanyInfo);
                        if (message.includeStoreIntegrationConfig != null && Object.hasOwnProperty.call(message, "includeStoreIntegrationConfig"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeStoreIntegrationConfig);
                        if (message.includeAreaInfo != null && Object.hasOwnProperty.call(message, "includeAreaInfo"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.includeAreaInfo);
                        if (message.fieldMask != null && Object.hasOwnProperty.call(message, "fieldMask"))
                            $root.google.protobuf.FieldMask.encode(message.fieldMask, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStoreRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStoreRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreRequest} message GetStoreRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStoreRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStoreRequest} GetStoreRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStoreRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.id = reader.int64();
                                break;
                            case 3:
                                message.includeCompanyInfo = reader.bool();
                                break;
                            case 4:
                                message.includeStoreIntegrationConfig = reader.bool();
                                break;
                            case 5:
                                message.includeAreaInfo = reader.bool();
                                break;
                            case 6:
                                message.fieldMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStoreRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStoreRequest} GetStoreRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStoreRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStoreRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.includeCompanyInfo != null && message.hasOwnProperty("includeCompanyInfo"))
                            if (typeof message.includeCompanyInfo !== "boolean")
                                return "includeCompanyInfo: boolean expected";
                        if (message.includeStoreIntegrationConfig != null && message.hasOwnProperty("includeStoreIntegrationConfig"))
                            if (typeof message.includeStoreIntegrationConfig !== "boolean")
                                return "includeStoreIntegrationConfig: boolean expected";
                        if (message.includeAreaInfo != null && message.hasOwnProperty("includeAreaInfo"))
                            if (typeof message.includeAreaInfo !== "boolean")
                                return "includeAreaInfo: boolean expected";
                        if (message.fieldMask != null && message.hasOwnProperty("fieldMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.fieldMask);
                            if (error)
                                return "fieldMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetStoreRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStoreRequest} GetStoreRequest
                     */
                    GetStoreRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStoreRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStoreRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.includeCompanyInfo != null)
                            message.includeCompanyInfo = Boolean(object.includeCompanyInfo);
                        if (object.includeStoreIntegrationConfig != null)
                            message.includeStoreIntegrationConfig = Boolean(object.includeStoreIntegrationConfig);
                        if (object.includeAreaInfo != null)
                            message.includeAreaInfo = Boolean(object.includeAreaInfo);
                        if (object.fieldMask != null) {
                            if (typeof object.fieldMask !== "object")
                                throw TypeError(".palexy.streaming.v1.GetStoreRequest.fieldMask: object expected");
                            message.fieldMask = $root.google.protobuf.FieldMask.fromObject(object.fieldMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStoreRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStoreRequest} message GetStoreRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStoreRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.includeCompanyInfo = false;
                            object.includeStoreIntegrationConfig = false;
                            object.includeAreaInfo = false;
                            object.fieldMask = null;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.includeCompanyInfo != null && message.hasOwnProperty("includeCompanyInfo"))
                            object.includeCompanyInfo = message.includeCompanyInfo;
                        if (message.includeStoreIntegrationConfig != null && message.hasOwnProperty("includeStoreIntegrationConfig"))
                            object.includeStoreIntegrationConfig = message.includeStoreIntegrationConfig;
                        if (message.includeAreaInfo != null && message.hasOwnProperty("includeAreaInfo"))
                            object.includeAreaInfo = message.includeAreaInfo;
                        if (message.fieldMask != null && message.hasOwnProperty("fieldMask"))
                            object.fieldMask = $root.google.protobuf.FieldMask.toObject(message.fieldMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this GetStoreRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStoreRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStoreRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStoreRequest;
                })();
    
                v1.ListStoresRequest = (function() {
    
                    /**
                     * Properties of a ListStoresRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoresRequest
                     * @property {boolean|null} [isProcessingOnly] ListStoresRequest isProcessingOnly
                     * @property {number|null} [companyId] ListStoresRequest companyId
                     * @property {boolean|null} [includeStoreIntegrationConfig] ListStoresRequest includeStoreIntegrationConfig
                     * @property {string|null} [streamingLocation] ListStoresRequest streamingLocation
                     * @property {number|null} [pageSize] ListStoresRequest pageSize
                     * @property {string|null} [pageToken] ListStoresRequest pageToken
                     * @property {google.protobuf.IFieldMask|null} [fieldMask] ListStoresRequest fieldMask
                     */
    
                    /**
                     * Constructs a new ListStoresRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoresRequest.
                     * @implements IListStoresRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoresRequest=} [properties] Properties to set
                     */
                    function ListStoresRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoresRequest isProcessingOnly.
                     * @member {boolean} isProcessingOnly
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @instance
                     */
                    ListStoresRequest.prototype.isProcessingOnly = false;
    
                    /**
                     * ListStoresRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @instance
                     */
                    ListStoresRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListStoresRequest includeStoreIntegrationConfig.
                     * @member {boolean} includeStoreIntegrationConfig
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @instance
                     */
                    ListStoresRequest.prototype.includeStoreIntegrationConfig = false;
    
                    /**
                     * ListStoresRequest streamingLocation.
                     * @member {string} streamingLocation
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @instance
                     */
                    ListStoresRequest.prototype.streamingLocation = "";
    
                    /**
                     * ListStoresRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @instance
                     */
                    ListStoresRequest.prototype.pageSize = 0;
    
                    /**
                     * ListStoresRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @instance
                     */
                    ListStoresRequest.prototype.pageToken = "";
    
                    /**
                     * ListStoresRequest fieldMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} fieldMask
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @instance
                     */
                    ListStoresRequest.prototype.fieldMask = null;
    
                    /**
                     * Creates a new ListStoresRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoresRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoresRequest} ListStoresRequest instance
                     */
                    ListStoresRequest.create = function create(properties) {
                        return new ListStoresRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoresRequest message. Does not implicitly {@link palexy.streaming.v1.ListStoresRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoresRequest} message ListStoresRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoresRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.isProcessingOnly != null && Object.hasOwnProperty.call(message, "isProcessingOnly"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isProcessingOnly);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.includeStoreIntegrationConfig != null && Object.hasOwnProperty.call(message, "includeStoreIntegrationConfig"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeStoreIntegrationConfig);
                        if (message.streamingLocation != null && Object.hasOwnProperty.call(message, "streamingLocation"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.streamingLocation);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.pageToken);
                        if (message.fieldMask != null && Object.hasOwnProperty.call(message, "fieldMask"))
                            $root.google.protobuf.FieldMask.encode(message.fieldMask, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoresRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoresRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoresRequest} message ListStoresRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoresRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoresRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoresRequest} ListStoresRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoresRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoresRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.isProcessingOnly = reader.bool();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.includeStoreIntegrationConfig = reader.bool();
                                break;
                            case 4:
                                message.streamingLocation = reader.string();
                                break;
                            case 5:
                                message.pageSize = reader.int32();
                                break;
                            case 6:
                                message.pageToken = reader.string();
                                break;
                            case 7:
                                message.fieldMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoresRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoresRequest} ListStoresRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoresRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoresRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoresRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isProcessingOnly != null && message.hasOwnProperty("isProcessingOnly"))
                            if (typeof message.isProcessingOnly !== "boolean")
                                return "isProcessingOnly: boolean expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.includeStoreIntegrationConfig != null && message.hasOwnProperty("includeStoreIntegrationConfig"))
                            if (typeof message.includeStoreIntegrationConfig !== "boolean")
                                return "includeStoreIntegrationConfig: boolean expected";
                        if (message.streamingLocation != null && message.hasOwnProperty("streamingLocation"))
                            if (!$util.isString(message.streamingLocation))
                                return "streamingLocation: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.fieldMask != null && message.hasOwnProperty("fieldMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.fieldMask);
                            if (error)
                                return "fieldMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStoresRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoresRequest} ListStoresRequest
                     */
                    ListStoresRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoresRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoresRequest();
                        if (object.isProcessingOnly != null)
                            message.isProcessingOnly = Boolean(object.isProcessingOnly);
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.includeStoreIntegrationConfig != null)
                            message.includeStoreIntegrationConfig = Boolean(object.includeStoreIntegrationConfig);
                        if (object.streamingLocation != null)
                            message.streamingLocation = String(object.streamingLocation);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.fieldMask != null) {
                            if (typeof object.fieldMask !== "object")
                                throw TypeError(".palexy.streaming.v1.ListStoresRequest.fieldMask: object expected");
                            message.fieldMask = $root.google.protobuf.FieldMask.fromObject(object.fieldMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoresRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStoresRequest} message ListStoresRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoresRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.isProcessingOnly = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.includeStoreIntegrationConfig = false;
                            object.streamingLocation = "";
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.fieldMask = null;
                        }
                        if (message.isProcessingOnly != null && message.hasOwnProperty("isProcessingOnly"))
                            object.isProcessingOnly = message.isProcessingOnly;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.includeStoreIntegrationConfig != null && message.hasOwnProperty("includeStoreIntegrationConfig"))
                            object.includeStoreIntegrationConfig = message.includeStoreIntegrationConfig;
                        if (message.streamingLocation != null && message.hasOwnProperty("streamingLocation"))
                            object.streamingLocation = message.streamingLocation;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.fieldMask != null && message.hasOwnProperty("fieldMask"))
                            object.fieldMask = $root.google.protobuf.FieldMask.toObject(message.fieldMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListStoresRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoresRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoresRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoresRequest;
                })();
    
                v1.ListStoresForTrendRequest = (function() {
    
                    /**
                     * Properties of a ListStoresForTrendRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoresForTrendRequest
                     * @property {Array.<number>|null} [storeIds] ListStoresForTrendRequest storeIds
                     * @property {boolean|null} [includeOptedOutStores] ListStoresForTrendRequest includeOptedOutStores
                     */
    
                    /**
                     * Constructs a new ListStoresForTrendRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoresForTrendRequest.
                     * @implements IListStoresForTrendRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoresForTrendRequest=} [properties] Properties to set
                     */
                    function ListStoresForTrendRequest(properties) {
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoresForTrendRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.streaming.v1.ListStoresForTrendRequest
                     * @instance
                     */
                    ListStoresForTrendRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * ListStoresForTrendRequest includeOptedOutStores.
                     * @member {boolean} includeOptedOutStores
                     * @memberof palexy.streaming.v1.ListStoresForTrendRequest
                     * @instance
                     */
                    ListStoresForTrendRequest.prototype.includeOptedOutStores = false;
    
                    /**
                     * Creates a new ListStoresForTrendRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoresForTrendRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoresForTrendRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoresForTrendRequest} ListStoresForTrendRequest instance
                     */
                    ListStoresForTrendRequest.create = function create(properties) {
                        return new ListStoresForTrendRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoresForTrendRequest message. Does not implicitly {@link palexy.streaming.v1.ListStoresForTrendRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoresForTrendRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoresForTrendRequest} message ListStoresForTrendRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoresForTrendRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.includeOptedOutStores != null && Object.hasOwnProperty.call(message, "includeOptedOutStores"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeOptedOutStores);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoresForTrendRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoresForTrendRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoresForTrendRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoresForTrendRequest} message ListStoresForTrendRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoresForTrendRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoresForTrendRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoresForTrendRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoresForTrendRequest} ListStoresForTrendRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoresForTrendRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoresForTrendRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 2:
                                message.includeOptedOutStores = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoresForTrendRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoresForTrendRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoresForTrendRequest} ListStoresForTrendRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoresForTrendRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoresForTrendRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoresForTrendRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoresForTrendRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.includeOptedOutStores != null && message.hasOwnProperty("includeOptedOutStores"))
                            if (typeof message.includeOptedOutStores !== "boolean")
                                return "includeOptedOutStores: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStoresForTrendRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoresForTrendRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoresForTrendRequest} ListStoresForTrendRequest
                     */
                    ListStoresForTrendRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoresForTrendRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoresForTrendRequest();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.streaming.v1.ListStoresForTrendRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.includeOptedOutStores != null)
                            message.includeOptedOutStores = Boolean(object.includeOptedOutStores);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoresForTrendRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoresForTrendRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStoresForTrendRequest} message ListStoresForTrendRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoresForTrendRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeIds = [];
                        if (options.defaults)
                            object.includeOptedOutStores = false;
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.includeOptedOutStores != null && message.hasOwnProperty("includeOptedOutStores"))
                            object.includeOptedOutStores = message.includeOptedOutStores;
                        return object;
                    };
    
                    /**
                     * Converts this ListStoresForTrendRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoresForTrendRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoresForTrendRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoresForTrendRequest;
                })();
    
                v1.ListStoresResponse = (function() {
    
                    /**
                     * Properties of a ListStoresResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoresResponse
                     * @property {Array.<palexy.streaming.v1.IStore>|null} [stores] ListStoresResponse stores
                     * @property {string|null} [nextPageToken] ListStoresResponse nextPageToken
                     * @property {number|null} [totalElements] ListStoresResponse totalElements
                     */
    
                    /**
                     * Constructs a new ListStoresResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoresResponse.
                     * @implements IListStoresResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoresResponse=} [properties] Properties to set
                     */
                    function ListStoresResponse(properties) {
                        this.stores = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoresResponse stores.
                     * @member {Array.<palexy.streaming.v1.IStore>} stores
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @instance
                     */
                    ListStoresResponse.prototype.stores = $util.emptyArray;
    
                    /**
                     * ListStoresResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @instance
                     */
                    ListStoresResponse.prototype.nextPageToken = "";
    
                    /**
                     * ListStoresResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @instance
                     */
                    ListStoresResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListStoresResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoresResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoresResponse} ListStoresResponse instance
                     */
                    ListStoresResponse.create = function create(properties) {
                        return new ListStoresResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoresResponse message. Does not implicitly {@link palexy.streaming.v1.ListStoresResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoresResponse} message ListStoresResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoresResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stores != null && message.stores.length)
                            for (var i = 0; i < message.stores.length; ++i)
                                $root.palexy.streaming.v1.Store.encode(message.stores[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoresResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoresResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoresResponse} message ListStoresResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoresResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoresResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoresResponse} ListStoresResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoresResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoresResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.stores && message.stores.length))
                                    message.stores = [];
                                message.stores.push($root.palexy.streaming.v1.Store.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            case 3:
                                message.totalElements = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoresResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoresResponse} ListStoresResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoresResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoresResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoresResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stores != null && message.hasOwnProperty("stores")) {
                            if (!Array.isArray(message.stores))
                                return "stores: array expected";
                            for (var i = 0; i < message.stores.length; ++i) {
                                var error = $root.palexy.streaming.v1.Store.verify(message.stores[i]);
                                if (error)
                                    return "stores." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStoresResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoresResponse} ListStoresResponse
                     */
                    ListStoresResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoresResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoresResponse();
                        if (object.stores) {
                            if (!Array.isArray(object.stores))
                                throw TypeError(".palexy.streaming.v1.ListStoresResponse.stores: array expected");
                            message.stores = [];
                            for (var i = 0; i < object.stores.length; ++i) {
                                if (typeof object.stores[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStoresResponse.stores: object expected");
                                message.stores[i] = $root.palexy.streaming.v1.Store.fromObject(object.stores[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoresResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStoresResponse} message ListStoresResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoresResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.stores = [];
                        if (options.defaults) {
                            object.nextPageToken = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                        }
                        if (message.stores && message.stores.length) {
                            object.stores = [];
                            for (var j = 0; j < message.stores.length; ++j)
                                object.stores[j] = $root.palexy.streaming.v1.Store.toObject(message.stores[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        return object;
                    };
    
                    /**
                     * Converts this ListStoresResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoresResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoresResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoresResponse;
                })();
    
                v1.RerunMergingRequest = (function() {
    
                    /**
                     * Properties of a RerunMergingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IRerunMergingRequest
                     * @property {Array.<number>|null} [storeIds] RerunMergingRequest storeIds
                     * @property {Array.<palexy.streaming.v1.IDateRange>|null} [dateRanges] RerunMergingRequest dateRanges
                     */
    
                    /**
                     * Constructs a new RerunMergingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RerunMergingRequest.
                     * @implements IRerunMergingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IRerunMergingRequest=} [properties] Properties to set
                     */
                    function RerunMergingRequest(properties) {
                        this.storeIds = [];
                        this.dateRanges = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RerunMergingRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.streaming.v1.RerunMergingRequest
                     * @instance
                     */
                    RerunMergingRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * RerunMergingRequest dateRanges.
                     * @member {Array.<palexy.streaming.v1.IDateRange>} dateRanges
                     * @memberof palexy.streaming.v1.RerunMergingRequest
                     * @instance
                     */
                    RerunMergingRequest.prototype.dateRanges = $util.emptyArray;
    
                    /**
                     * Creates a new RerunMergingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RerunMergingRequest
                     * @static
                     * @param {palexy.streaming.v1.IRerunMergingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RerunMergingRequest} RerunMergingRequest instance
                     */
                    RerunMergingRequest.create = function create(properties) {
                        return new RerunMergingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified RerunMergingRequest message. Does not implicitly {@link palexy.streaming.v1.RerunMergingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RerunMergingRequest
                     * @static
                     * @param {palexy.streaming.v1.IRerunMergingRequest} message RerunMergingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunMergingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.dateRanges != null && message.dateRanges.length)
                            for (var i = 0; i < message.dateRanges.length; ++i)
                                $root.palexy.streaming.v1.DateRange.encode(message.dateRanges[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RerunMergingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.RerunMergingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RerunMergingRequest
                     * @static
                     * @param {palexy.streaming.v1.IRerunMergingRequest} message RerunMergingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunMergingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RerunMergingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RerunMergingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RerunMergingRequest} RerunMergingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunMergingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RerunMergingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 2:
                                if (!(message.dateRanges && message.dateRanges.length))
                                    message.dateRanges = [];
                                message.dateRanges.push($root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RerunMergingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RerunMergingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RerunMergingRequest} RerunMergingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunMergingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RerunMergingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RerunMergingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RerunMergingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.dateRanges != null && message.hasOwnProperty("dateRanges")) {
                            if (!Array.isArray(message.dateRanges))
                                return "dateRanges: array expected";
                            for (var i = 0; i < message.dateRanges.length; ++i) {
                                var error = $root.palexy.streaming.v1.DateRange.verify(message.dateRanges[i]);
                                if (error)
                                    return "dateRanges." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a RerunMergingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RerunMergingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RerunMergingRequest} RerunMergingRequest
                     */
                    RerunMergingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RerunMergingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.RerunMergingRequest();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.streaming.v1.RerunMergingRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.dateRanges) {
                            if (!Array.isArray(object.dateRanges))
                                throw TypeError(".palexy.streaming.v1.RerunMergingRequest.dateRanges: array expected");
                            message.dateRanges = [];
                            for (var i = 0; i < object.dateRanges.length; ++i) {
                                if (typeof object.dateRanges[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.RerunMergingRequest.dateRanges: object expected");
                                message.dateRanges[i] = $root.palexy.streaming.v1.DateRange.fromObject(object.dateRanges[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RerunMergingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RerunMergingRequest
                     * @static
                     * @param {palexy.streaming.v1.RerunMergingRequest} message RerunMergingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RerunMergingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.storeIds = [];
                            object.dateRanges = [];
                        }
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.dateRanges && message.dateRanges.length) {
                            object.dateRanges = [];
                            for (var j = 0; j < message.dateRanges.length; ++j)
                                object.dateRanges[j] = $root.palexy.streaming.v1.DateRange.toObject(message.dateRanges[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this RerunMergingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RerunMergingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RerunMergingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RerunMergingRequest;
                })();
    
                v1.RerunMergingResponse = (function() {
    
                    /**
                     * Properties of a RerunMergingResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IRerunMergingResponse
                     * @property {number|null} [affectedRowsCount] RerunMergingResponse affectedRowsCount
                     */
    
                    /**
                     * Constructs a new RerunMergingResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RerunMergingResponse.
                     * @implements IRerunMergingResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IRerunMergingResponse=} [properties] Properties to set
                     */
                    function RerunMergingResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RerunMergingResponse affectedRowsCount.
                     * @member {number} affectedRowsCount
                     * @memberof palexy.streaming.v1.RerunMergingResponse
                     * @instance
                     */
                    RerunMergingResponse.prototype.affectedRowsCount = 0;
    
                    /**
                     * Creates a new RerunMergingResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RerunMergingResponse
                     * @static
                     * @param {palexy.streaming.v1.IRerunMergingResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RerunMergingResponse} RerunMergingResponse instance
                     */
                    RerunMergingResponse.create = function create(properties) {
                        return new RerunMergingResponse(properties);
                    };
    
                    /**
                     * Encodes the specified RerunMergingResponse message. Does not implicitly {@link palexy.streaming.v1.RerunMergingResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RerunMergingResponse
                     * @static
                     * @param {palexy.streaming.v1.IRerunMergingResponse} message RerunMergingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunMergingResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.affectedRowsCount != null && Object.hasOwnProperty.call(message, "affectedRowsCount"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.affectedRowsCount);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RerunMergingResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.RerunMergingResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RerunMergingResponse
                     * @static
                     * @param {palexy.streaming.v1.IRerunMergingResponse} message RerunMergingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunMergingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RerunMergingResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RerunMergingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RerunMergingResponse} RerunMergingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunMergingResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RerunMergingResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.affectedRowsCount = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RerunMergingResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RerunMergingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RerunMergingResponse} RerunMergingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunMergingResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RerunMergingResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RerunMergingResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RerunMergingResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.affectedRowsCount != null && message.hasOwnProperty("affectedRowsCount"))
                            if (!$util.isInteger(message.affectedRowsCount))
                                return "affectedRowsCount: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a RerunMergingResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RerunMergingResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RerunMergingResponse} RerunMergingResponse
                     */
                    RerunMergingResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RerunMergingResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.RerunMergingResponse();
                        if (object.affectedRowsCount != null)
                            message.affectedRowsCount = object.affectedRowsCount | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RerunMergingResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RerunMergingResponse
                     * @static
                     * @param {palexy.streaming.v1.RerunMergingResponse} message RerunMergingResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RerunMergingResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.affectedRowsCount = 0;
                        if (message.affectedRowsCount != null && message.hasOwnProperty("affectedRowsCount"))
                            object.affectedRowsCount = message.affectedRowsCount;
                        return object;
                    };
    
                    /**
                     * Converts this RerunMergingResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RerunMergingResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RerunMergingResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RerunMergingResponse;
                })();
    
                v1.ListStoreProcessingStatusesRequest = (function() {
    
                    /**
                     * Properties of a ListStoreProcessingStatusesRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreProcessingStatusesRequest
                     * @property {number|null} [companyId] ListStoreProcessingStatusesRequest companyId
                     * @property {Array.<number>|null} [storeIds] ListStoreProcessingStatusesRequest storeIds
                     */
    
                    /**
                     * Constructs a new ListStoreProcessingStatusesRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreProcessingStatusesRequest.
                     * @implements IListStoreProcessingStatusesRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreProcessingStatusesRequest=} [properties] Properties to set
                     */
                    function ListStoreProcessingStatusesRequest(properties) {
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreProcessingStatusesRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesRequest
                     * @instance
                     */
                    ListStoreProcessingStatusesRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListStoreProcessingStatusesRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesRequest
                     * @instance
                     */
                    ListStoreProcessingStatusesRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * Creates a new ListStoreProcessingStatusesRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreProcessingStatusesRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreProcessingStatusesRequest} ListStoreProcessingStatusesRequest instance
                     */
                    ListStoreProcessingStatusesRequest.create = function create(properties) {
                        return new ListStoreProcessingStatusesRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreProcessingStatusesRequest message. Does not implicitly {@link palexy.streaming.v1.ListStoreProcessingStatusesRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreProcessingStatusesRequest} message ListStoreProcessingStatusesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreProcessingStatusesRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreProcessingStatusesRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreProcessingStatusesRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreProcessingStatusesRequest} message ListStoreProcessingStatusesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreProcessingStatusesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreProcessingStatusesRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreProcessingStatusesRequest} ListStoreProcessingStatusesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreProcessingStatusesRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreProcessingStatusesRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreProcessingStatusesRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreProcessingStatusesRequest} ListStoreProcessingStatusesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreProcessingStatusesRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreProcessingStatusesRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreProcessingStatusesRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreProcessingStatusesRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreProcessingStatusesRequest} ListStoreProcessingStatusesRequest
                     */
                    ListStoreProcessingStatusesRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreProcessingStatusesRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreProcessingStatusesRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.streaming.v1.ListStoreProcessingStatusesRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreProcessingStatusesRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStoreProcessingStatusesRequest} message ListStoreProcessingStatusesRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreProcessingStatusesRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeIds = [];
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreProcessingStatusesRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreProcessingStatusesRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreProcessingStatusesRequest;
                })();
    
                v1.ListStoreProcessingStatusesResponse = (function() {
    
                    /**
                     * Properties of a ListStoreProcessingStatusesResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreProcessingStatusesResponse
                     * @property {Array.<palexy.streaming.v1.IStoreProcessingStatus>|null} [storeStatuses] ListStoreProcessingStatusesResponse storeStatuses
                     */
    
                    /**
                     * Constructs a new ListStoreProcessingStatusesResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreProcessingStatusesResponse.
                     * @implements IListStoreProcessingStatusesResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreProcessingStatusesResponse=} [properties] Properties to set
                     */
                    function ListStoreProcessingStatusesResponse(properties) {
                        this.storeStatuses = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreProcessingStatusesResponse storeStatuses.
                     * @member {Array.<palexy.streaming.v1.IStoreProcessingStatus>} storeStatuses
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesResponse
                     * @instance
                     */
                    ListStoreProcessingStatusesResponse.prototype.storeStatuses = $util.emptyArray;
    
                    /**
                     * Creates a new ListStoreProcessingStatusesResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreProcessingStatusesResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreProcessingStatusesResponse} ListStoreProcessingStatusesResponse instance
                     */
                    ListStoreProcessingStatusesResponse.create = function create(properties) {
                        return new ListStoreProcessingStatusesResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreProcessingStatusesResponse message. Does not implicitly {@link palexy.streaming.v1.ListStoreProcessingStatusesResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreProcessingStatusesResponse} message ListStoreProcessingStatusesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreProcessingStatusesResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeStatuses != null && message.storeStatuses.length)
                            for (var i = 0; i < message.storeStatuses.length; ++i)
                                $root.palexy.streaming.v1.StoreProcessingStatus.encode(message.storeStatuses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreProcessingStatusesResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreProcessingStatusesResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreProcessingStatusesResponse} message ListStoreProcessingStatusesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreProcessingStatusesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreProcessingStatusesResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreProcessingStatusesResponse} ListStoreProcessingStatusesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreProcessingStatusesResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreProcessingStatusesResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeStatuses && message.storeStatuses.length))
                                    message.storeStatuses = [];
                                message.storeStatuses.push($root.palexy.streaming.v1.StoreProcessingStatus.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreProcessingStatusesResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreProcessingStatusesResponse} ListStoreProcessingStatusesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreProcessingStatusesResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreProcessingStatusesResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreProcessingStatusesResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeStatuses != null && message.hasOwnProperty("storeStatuses")) {
                            if (!Array.isArray(message.storeStatuses))
                                return "storeStatuses: array expected";
                            for (var i = 0; i < message.storeStatuses.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreProcessingStatus.verify(message.storeStatuses[i]);
                                if (error)
                                    return "storeStatuses." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreProcessingStatusesResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreProcessingStatusesResponse} ListStoreProcessingStatusesResponse
                     */
                    ListStoreProcessingStatusesResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreProcessingStatusesResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreProcessingStatusesResponse();
                        if (object.storeStatuses) {
                            if (!Array.isArray(object.storeStatuses))
                                throw TypeError(".palexy.streaming.v1.ListStoreProcessingStatusesResponse.storeStatuses: array expected");
                            message.storeStatuses = [];
                            for (var i = 0; i < object.storeStatuses.length; ++i) {
                                if (typeof object.storeStatuses[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStoreProcessingStatusesResponse.storeStatuses: object expected");
                                message.storeStatuses[i] = $root.palexy.streaming.v1.StoreProcessingStatus.fromObject(object.storeStatuses[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreProcessingStatusesResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStoreProcessingStatusesResponse} message ListStoreProcessingStatusesResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreProcessingStatusesResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeStatuses = [];
                        if (message.storeStatuses && message.storeStatuses.length) {
                            object.storeStatuses = [];
                            for (var j = 0; j < message.storeStatuses.length; ++j)
                                object.storeStatuses[j] = $root.palexy.streaming.v1.StoreProcessingStatus.toObject(message.storeStatuses[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreProcessingStatusesResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreProcessingStatusesResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreProcessingStatusesResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreProcessingStatusesResponse;
                })();
    
                v1.StoreProcessingStatus = (function() {
    
                    /**
                     * Properties of a StoreProcessingStatus.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreProcessingStatus
                     * @property {palexy.streaming.v1.IStore|null} [store] StoreProcessingStatus store
                     * @property {google.protobuf.IInt64Value|null} [lastAggregateTimeMs] StoreProcessingStatus lastAggregateTimeMs
                     * @property {boolean|null} [ready] StoreProcessingStatus ready
                     * @property {string|null} [latestTimeSlot] StoreProcessingStatus latestTimeSlot
                     */
    
                    /**
                     * Constructs a new StoreProcessingStatus.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreProcessingStatus.
                     * @implements IStoreProcessingStatus
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreProcessingStatus=} [properties] Properties to set
                     */
                    function StoreProcessingStatus(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreProcessingStatus store.
                     * @member {palexy.streaming.v1.IStore|null|undefined} store
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @instance
                     */
                    StoreProcessingStatus.prototype.store = null;
    
                    /**
                     * StoreProcessingStatus lastAggregateTimeMs.
                     * @member {google.protobuf.IInt64Value|null|undefined} lastAggregateTimeMs
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @instance
                     */
                    StoreProcessingStatus.prototype.lastAggregateTimeMs = null;
    
                    /**
                     * StoreProcessingStatus ready.
                     * @member {boolean} ready
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @instance
                     */
                    StoreProcessingStatus.prototype.ready = false;
    
                    /**
                     * StoreProcessingStatus latestTimeSlot.
                     * @member {string} latestTimeSlot
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @instance
                     */
                    StoreProcessingStatus.prototype.latestTimeSlot = "";
    
                    /**
                     * Creates a new StoreProcessingStatus instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @static
                     * @param {palexy.streaming.v1.IStoreProcessingStatus=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreProcessingStatus} StoreProcessingStatus instance
                     */
                    StoreProcessingStatus.create = function create(properties) {
                        return new StoreProcessingStatus(properties);
                    };
    
                    /**
                     * Encodes the specified StoreProcessingStatus message. Does not implicitly {@link palexy.streaming.v1.StoreProcessingStatus.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @static
                     * @param {palexy.streaming.v1.IStoreProcessingStatus} message StoreProcessingStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreProcessingStatus.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.store != null && Object.hasOwnProperty.call(message, "store"))
                            $root.palexy.streaming.v1.Store.encode(message.store, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.lastAggregateTimeMs != null && Object.hasOwnProperty.call(message, "lastAggregateTimeMs"))
                            $root.google.protobuf.Int64Value.encode(message.lastAggregateTimeMs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.ready != null && Object.hasOwnProperty.call(message, "ready"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.ready);
                        if (message.latestTimeSlot != null && Object.hasOwnProperty.call(message, "latestTimeSlot"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.latestTimeSlot);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreProcessingStatus message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreProcessingStatus.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @static
                     * @param {palexy.streaming.v1.IStoreProcessingStatus} message StoreProcessingStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreProcessingStatus.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreProcessingStatus message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreProcessingStatus} StoreProcessingStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreProcessingStatus.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreProcessingStatus();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.store = $root.palexy.streaming.v1.Store.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.lastAggregateTimeMs = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.ready = reader.bool();
                                break;
                            case 4:
                                message.latestTimeSlot = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreProcessingStatus message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreProcessingStatus} StoreProcessingStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreProcessingStatus.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreProcessingStatus message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreProcessingStatus.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.store != null && message.hasOwnProperty("store")) {
                            var error = $root.palexy.streaming.v1.Store.verify(message.store);
                            if (error)
                                return "store." + error;
                        }
                        if (message.lastAggregateTimeMs != null && message.hasOwnProperty("lastAggregateTimeMs")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.lastAggregateTimeMs);
                            if (error)
                                return "lastAggregateTimeMs." + error;
                        }
                        if (message.ready != null && message.hasOwnProperty("ready"))
                            if (typeof message.ready !== "boolean")
                                return "ready: boolean expected";
                        if (message.latestTimeSlot != null && message.hasOwnProperty("latestTimeSlot"))
                            if (!$util.isString(message.latestTimeSlot))
                                return "latestTimeSlot: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a StoreProcessingStatus message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreProcessingStatus} StoreProcessingStatus
                     */
                    StoreProcessingStatus.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreProcessingStatus)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreProcessingStatus();
                        if (object.store != null) {
                            if (typeof object.store !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreProcessingStatus.store: object expected");
                            message.store = $root.palexy.streaming.v1.Store.fromObject(object.store);
                        }
                        if (object.lastAggregateTimeMs != null) {
                            if (typeof object.lastAggregateTimeMs !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreProcessingStatus.lastAggregateTimeMs: object expected");
                            message.lastAggregateTimeMs = $root.google.protobuf.Int64Value.fromObject(object.lastAggregateTimeMs);
                        }
                        if (object.ready != null)
                            message.ready = Boolean(object.ready);
                        if (object.latestTimeSlot != null)
                            message.latestTimeSlot = String(object.latestTimeSlot);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreProcessingStatus message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @static
                     * @param {palexy.streaming.v1.StoreProcessingStatus} message StoreProcessingStatus
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreProcessingStatus.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.store = null;
                            object.lastAggregateTimeMs = null;
                            object.ready = false;
                            object.latestTimeSlot = "";
                        }
                        if (message.store != null && message.hasOwnProperty("store"))
                            object.store = $root.palexy.streaming.v1.Store.toObject(message.store, options);
                        if (message.lastAggregateTimeMs != null && message.hasOwnProperty("lastAggregateTimeMs"))
                            object.lastAggregateTimeMs = $root.google.protobuf.Int64Value.toObject(message.lastAggregateTimeMs, options);
                        if (message.ready != null && message.hasOwnProperty("ready"))
                            object.ready = message.ready;
                        if (message.latestTimeSlot != null && message.hasOwnProperty("latestTimeSlot"))
                            object.latestTimeSlot = message.latestTimeSlot;
                        return object;
                    };
    
                    /**
                     * Converts this StoreProcessingStatus to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreProcessingStatus
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreProcessingStatus.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StoreProcessingStatus;
                })();
    
                v1.MetadataService = (function() {
    
                    /**
                     * Constructs a new MetadataService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MetadataService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function MetadataService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (MetadataService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MetadataService;
    
                    /**
                     * Creates new MetadataService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.MetadataService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {MetadataService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    MetadataService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.MetadataService#listMetadataType}.
                     * @memberof palexy.streaming.v1.MetadataService
                     * @typedef ListMetadataTypeCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListMetadataTypeResponse} [response] ListMetadataTypeResponse
                     */
    
                    /**
                     * Calls ListMetadataType.
                     * @function listMetadataType
                     * @memberof palexy.streaming.v1.MetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IListMetadataTypeRequest} request ListMetadataTypeRequest message or plain object
                     * @param {palexy.streaming.v1.MetadataService.ListMetadataTypeCallback} callback Node-style callback called with the error, if any, and ListMetadataTypeResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(MetadataService.prototype.listMetadataType = function listMetadataType(request, callback) {
                        return this.rpcCall(listMetadataType, $root.palexy.streaming.v1.ListMetadataTypeRequest, $root.palexy.streaming.v1.ListMetadataTypeResponse, request, callback);
                    }, "name", { value: "ListMetadataType" });
    
                    /**
                     * Calls ListMetadataType.
                     * @function listMetadataType
                     * @memberof palexy.streaming.v1.MetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IListMetadataTypeRequest} request ListMetadataTypeRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListMetadataTypeResponse>} Promise
                     * @variation 2
                     */
    
                    return MetadataService;
                })();
    
                v1.MetadataType = (function() {
    
                    /**
                     * Properties of a MetadataType.
                     * @memberof palexy.streaming.v1
                     * @interface IMetadataType
                     * @property {number|null} [id] MetadataType id
                     * @property {string|null} [name] MetadataType name
                     * @property {boolean|null} [isSystem] MetadataType isSystem
                     * @property {string|null} [systemName] MetadataType systemName
                     * @property {string|null} [type] MetadataType type
                     * @property {number|null} [ownerId] MetadataType ownerId
                     * @property {boolean|null} [isDeleted] MetadataType isDeleted
                     * @property {Array.<palexy.streaming.v1.IMetadataValue>|null} [metadataValues] MetadataType metadataValues
                     * @property {boolean|null} [isDefault] MetadataType isDefault
                     * @property {palexy.streaming.v1.MetadataType.VisitActionType|null} [visitActionType] MetadataType visitActionType
                     */
    
                    /**
                     * Constructs a new MetadataType.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MetadataType.
                     * @implements IMetadataType
                     * @constructor
                     * @param {palexy.streaming.v1.IMetadataType=} [properties] Properties to set
                     */
                    function MetadataType(properties) {
                        this.metadataValues = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MetadataType id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.MetadataType
                     * @instance
                     */
                    MetadataType.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MetadataType name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.MetadataType
                     * @instance
                     */
                    MetadataType.prototype.name = "";
    
                    /**
                     * MetadataType isSystem.
                     * @member {boolean} isSystem
                     * @memberof palexy.streaming.v1.MetadataType
                     * @instance
                     */
                    MetadataType.prototype.isSystem = false;
    
                    /**
                     * MetadataType systemName.
                     * @member {string} systemName
                     * @memberof palexy.streaming.v1.MetadataType
                     * @instance
                     */
                    MetadataType.prototype.systemName = "";
    
                    /**
                     * MetadataType type.
                     * @member {string} type
                     * @memberof palexy.streaming.v1.MetadataType
                     * @instance
                     */
                    MetadataType.prototype.type = "";
    
                    /**
                     * MetadataType ownerId.
                     * @member {number} ownerId
                     * @memberof palexy.streaming.v1.MetadataType
                     * @instance
                     */
                    MetadataType.prototype.ownerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MetadataType isDeleted.
                     * @member {boolean} isDeleted
                     * @memberof palexy.streaming.v1.MetadataType
                     * @instance
                     */
                    MetadataType.prototype.isDeleted = false;
    
                    /**
                     * MetadataType metadataValues.
                     * @member {Array.<palexy.streaming.v1.IMetadataValue>} metadataValues
                     * @memberof palexy.streaming.v1.MetadataType
                     * @instance
                     */
                    MetadataType.prototype.metadataValues = $util.emptyArray;
    
                    /**
                     * MetadataType isDefault.
                     * @member {boolean} isDefault
                     * @memberof palexy.streaming.v1.MetadataType
                     * @instance
                     */
                    MetadataType.prototype.isDefault = false;
    
                    /**
                     * MetadataType visitActionType.
                     * @member {palexy.streaming.v1.MetadataType.VisitActionType} visitActionType
                     * @memberof palexy.streaming.v1.MetadataType
                     * @instance
                     */
                    MetadataType.prototype.visitActionType = 0;
    
                    /**
                     * Creates a new MetadataType instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MetadataType
                     * @static
                     * @param {palexy.streaming.v1.IMetadataType=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MetadataType} MetadataType instance
                     */
                    MetadataType.create = function create(properties) {
                        return new MetadataType(properties);
                    };
    
                    /**
                     * Encodes the specified MetadataType message. Does not implicitly {@link palexy.streaming.v1.MetadataType.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MetadataType
                     * @static
                     * @param {palexy.streaming.v1.IMetadataType} message MetadataType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MetadataType.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.isSystem != null && Object.hasOwnProperty.call(message, "isSystem"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isSystem);
                        if (message.systemName != null && Object.hasOwnProperty.call(message, "systemName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.systemName);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.type);
                        if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.ownerId);
                        if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDeleted);
                        if (message.metadataValues != null && message.metadataValues.length)
                            for (var i = 0; i < message.metadataValues.length; ++i)
                                $root.palexy.streaming.v1.MetadataValue.encode(message.metadataValues[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.isDefault != null && Object.hasOwnProperty.call(message, "isDefault"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isDefault);
                        if (message.visitActionType != null && Object.hasOwnProperty.call(message, "visitActionType"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.visitActionType);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MetadataType message, length delimited. Does not implicitly {@link palexy.streaming.v1.MetadataType.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MetadataType
                     * @static
                     * @param {palexy.streaming.v1.IMetadataType} message MetadataType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MetadataType.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MetadataType message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MetadataType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MetadataType} MetadataType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MetadataType.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MetadataType();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.isSystem = reader.bool();
                                break;
                            case 4:
                                message.systemName = reader.string();
                                break;
                            case 5:
                                message.type = reader.string();
                                break;
                            case 6:
                                message.ownerId = reader.int64();
                                break;
                            case 7:
                                message.isDeleted = reader.bool();
                                break;
                            case 8:
                                if (!(message.metadataValues && message.metadataValues.length))
                                    message.metadataValues = [];
                                message.metadataValues.push($root.palexy.streaming.v1.MetadataValue.decode(reader, reader.uint32()));
                                break;
                            case 9:
                                message.isDefault = reader.bool();
                                break;
                            case 10:
                                message.visitActionType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MetadataType message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MetadataType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MetadataType} MetadataType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MetadataType.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MetadataType message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MetadataType
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MetadataType.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.isSystem != null && message.hasOwnProperty("isSystem"))
                            if (typeof message.isSystem !== "boolean")
                                return "isSystem: boolean expected";
                        if (message.systemName != null && message.hasOwnProperty("systemName"))
                            if (!$util.isString(message.systemName))
                                return "systemName: string expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            if (!$util.isString(message.type))
                                return "type: string expected";
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (!$util.isInteger(message.ownerId) && !(message.ownerId && $util.isInteger(message.ownerId.low) && $util.isInteger(message.ownerId.high)))
                                return "ownerId: integer|Long expected";
                        if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                            if (typeof message.isDeleted !== "boolean")
                                return "isDeleted: boolean expected";
                        if (message.metadataValues != null && message.hasOwnProperty("metadataValues")) {
                            if (!Array.isArray(message.metadataValues))
                                return "metadataValues: array expected";
                            for (var i = 0; i < message.metadataValues.length; ++i) {
                                var error = $root.palexy.streaming.v1.MetadataValue.verify(message.metadataValues[i]);
                                if (error)
                                    return "metadataValues." + error;
                            }
                        }
                        if (message.isDefault != null && message.hasOwnProperty("isDefault"))
                            if (typeof message.isDefault !== "boolean")
                                return "isDefault: boolean expected";
                        if (message.visitActionType != null && message.hasOwnProperty("visitActionType"))
                            switch (message.visitActionType) {
                            default:
                                return "visitActionType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a MetadataType message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MetadataType
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MetadataType} MetadataType
                     */
                    MetadataType.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MetadataType)
                            return object;
                        var message = new $root.palexy.streaming.v1.MetadataType();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.isSystem != null)
                            message.isSystem = Boolean(object.isSystem);
                        if (object.systemName != null)
                            message.systemName = String(object.systemName);
                        if (object.type != null)
                            message.type = String(object.type);
                        if (object.ownerId != null)
                            if ($util.Long)
                                (message.ownerId = $util.Long.fromValue(object.ownerId)).unsigned = false;
                            else if (typeof object.ownerId === "string")
                                message.ownerId = parseInt(object.ownerId, 10);
                            else if (typeof object.ownerId === "number")
                                message.ownerId = object.ownerId;
                            else if (typeof object.ownerId === "object")
                                message.ownerId = new $util.LongBits(object.ownerId.low >>> 0, object.ownerId.high >>> 0).toNumber();
                        if (object.isDeleted != null)
                            message.isDeleted = Boolean(object.isDeleted);
                        if (object.metadataValues) {
                            if (!Array.isArray(object.metadataValues))
                                throw TypeError(".palexy.streaming.v1.MetadataType.metadataValues: array expected");
                            message.metadataValues = [];
                            for (var i = 0; i < object.metadataValues.length; ++i) {
                                if (typeof object.metadataValues[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.MetadataType.metadataValues: object expected");
                                message.metadataValues[i] = $root.palexy.streaming.v1.MetadataValue.fromObject(object.metadataValues[i]);
                            }
                        }
                        if (object.isDefault != null)
                            message.isDefault = Boolean(object.isDefault);
                        switch (object.visitActionType) {
                        case "VISIT":
                        case 0:
                            message.visitActionType = 0;
                            break;
                        case "VISIT_SECTION":
                        case 1:
                            message.visitActionType = 1;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MetadataType message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MetadataType
                     * @static
                     * @param {palexy.streaming.v1.MetadataType} message MetadataType
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MetadataType.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.metadataValues = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.isSystem = false;
                            object.systemName = "";
                            object.type = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.ownerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.ownerId = options.longs === String ? "0" : 0;
                            object.isDeleted = false;
                            object.isDefault = false;
                            object.visitActionType = options.enums === String ? "VISIT" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.isSystem != null && message.hasOwnProperty("isSystem"))
                            object.isSystem = message.isSystem;
                        if (message.systemName != null && message.hasOwnProperty("systemName"))
                            object.systemName = message.systemName;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = message.type;
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (typeof message.ownerId === "number")
                                object.ownerId = options.longs === String ? String(message.ownerId) : message.ownerId;
                            else
                                object.ownerId = options.longs === String ? $util.Long.prototype.toString.call(message.ownerId) : options.longs === Number ? new $util.LongBits(message.ownerId.low >>> 0, message.ownerId.high >>> 0).toNumber() : message.ownerId;
                        if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                            object.isDeleted = message.isDeleted;
                        if (message.metadataValues && message.metadataValues.length) {
                            object.metadataValues = [];
                            for (var j = 0; j < message.metadataValues.length; ++j)
                                object.metadataValues[j] = $root.palexy.streaming.v1.MetadataValue.toObject(message.metadataValues[j], options);
                        }
                        if (message.isDefault != null && message.hasOwnProperty("isDefault"))
                            object.isDefault = message.isDefault;
                        if (message.visitActionType != null && message.hasOwnProperty("visitActionType"))
                            object.visitActionType = options.enums === String ? $root.palexy.streaming.v1.MetadataType.VisitActionType[message.visitActionType] : message.visitActionType;
                        return object;
                    };
    
                    /**
                     * Converts this MetadataType to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MetadataType
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MetadataType.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * VisitActionType enum.
                     * @name palexy.streaming.v1.MetadataType.VisitActionType
                     * @enum {number}
                     * @property {number} VISIT=0 VISIT value
                     * @property {number} VISIT_SECTION=1 VISIT_SECTION value
                     */
                    MetadataType.VisitActionType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "VISIT"] = 0;
                        values[valuesById[1] = "VISIT_SECTION"] = 1;
                        return values;
                    })();
    
                    return MetadataType;
                })();
    
                v1.MetadataValue = (function() {
    
                    /**
                     * Properties of a MetadataValue.
                     * @memberof palexy.streaming.v1
                     * @interface IMetadataValue
                     * @property {number|null} [id] MetadataValue id
                     * @property {string|null} [value] MetadataValue value
                     * @property {number|null} [ownerId] MetadataValue ownerId
                     * @property {number|null} [metadataTypeId] MetadataValue metadataTypeId
                     */
    
                    /**
                     * Constructs a new MetadataValue.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MetadataValue.
                     * @implements IMetadataValue
                     * @constructor
                     * @param {palexy.streaming.v1.IMetadataValue=} [properties] Properties to set
                     */
                    function MetadataValue(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MetadataValue id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @instance
                     */
                    MetadataValue.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MetadataValue value.
                     * @member {string} value
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @instance
                     */
                    MetadataValue.prototype.value = "";
    
                    /**
                     * MetadataValue ownerId.
                     * @member {number} ownerId
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @instance
                     */
                    MetadataValue.prototype.ownerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MetadataValue metadataTypeId.
                     * @member {number} metadataTypeId
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @instance
                     */
                    MetadataValue.prototype.metadataTypeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new MetadataValue instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @static
                     * @param {palexy.streaming.v1.IMetadataValue=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MetadataValue} MetadataValue instance
                     */
                    MetadataValue.create = function create(properties) {
                        return new MetadataValue(properties);
                    };
    
                    /**
                     * Encodes the specified MetadataValue message. Does not implicitly {@link palexy.streaming.v1.MetadataValue.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @static
                     * @param {palexy.streaming.v1.IMetadataValue} message MetadataValue message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MetadataValue.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                        if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.ownerId);
                        if (message.metadataTypeId != null && Object.hasOwnProperty.call(message, "metadataTypeId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.metadataTypeId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MetadataValue message, length delimited. Does not implicitly {@link palexy.streaming.v1.MetadataValue.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @static
                     * @param {palexy.streaming.v1.IMetadataValue} message MetadataValue message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MetadataValue.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MetadataValue message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MetadataValue} MetadataValue
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MetadataValue.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MetadataValue();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.value = reader.string();
                                break;
                            case 3:
                                message.ownerId = reader.int64();
                                break;
                            case 4:
                                message.metadataTypeId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MetadataValue message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MetadataValue} MetadataValue
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MetadataValue.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MetadataValue message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MetadataValue.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.value != null && message.hasOwnProperty("value"))
                            if (!$util.isString(message.value))
                                return "value: string expected";
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (!$util.isInteger(message.ownerId) && !(message.ownerId && $util.isInteger(message.ownerId.low) && $util.isInteger(message.ownerId.high)))
                                return "ownerId: integer|Long expected";
                        if (message.metadataTypeId != null && message.hasOwnProperty("metadataTypeId"))
                            if (!$util.isInteger(message.metadataTypeId) && !(message.metadataTypeId && $util.isInteger(message.metadataTypeId.low) && $util.isInteger(message.metadataTypeId.high)))
                                return "metadataTypeId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a MetadataValue message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MetadataValue} MetadataValue
                     */
                    MetadataValue.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MetadataValue)
                            return object;
                        var message = new $root.palexy.streaming.v1.MetadataValue();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.value != null)
                            message.value = String(object.value);
                        if (object.ownerId != null)
                            if ($util.Long)
                                (message.ownerId = $util.Long.fromValue(object.ownerId)).unsigned = false;
                            else if (typeof object.ownerId === "string")
                                message.ownerId = parseInt(object.ownerId, 10);
                            else if (typeof object.ownerId === "number")
                                message.ownerId = object.ownerId;
                            else if (typeof object.ownerId === "object")
                                message.ownerId = new $util.LongBits(object.ownerId.low >>> 0, object.ownerId.high >>> 0).toNumber();
                        if (object.metadataTypeId != null)
                            if ($util.Long)
                                (message.metadataTypeId = $util.Long.fromValue(object.metadataTypeId)).unsigned = false;
                            else if (typeof object.metadataTypeId === "string")
                                message.metadataTypeId = parseInt(object.metadataTypeId, 10);
                            else if (typeof object.metadataTypeId === "number")
                                message.metadataTypeId = object.metadataTypeId;
                            else if (typeof object.metadataTypeId === "object")
                                message.metadataTypeId = new $util.LongBits(object.metadataTypeId.low >>> 0, object.metadataTypeId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MetadataValue message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @static
                     * @param {palexy.streaming.v1.MetadataValue} message MetadataValue
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MetadataValue.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.value = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.ownerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.ownerId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataTypeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataTypeId = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.value != null && message.hasOwnProperty("value"))
                            object.value = message.value;
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (typeof message.ownerId === "number")
                                object.ownerId = options.longs === String ? String(message.ownerId) : message.ownerId;
                            else
                                object.ownerId = options.longs === String ? $util.Long.prototype.toString.call(message.ownerId) : options.longs === Number ? new $util.LongBits(message.ownerId.low >>> 0, message.ownerId.high >>> 0).toNumber() : message.ownerId;
                        if (message.metadataTypeId != null && message.hasOwnProperty("metadataTypeId"))
                            if (typeof message.metadataTypeId === "number")
                                object.metadataTypeId = options.longs === String ? String(message.metadataTypeId) : message.metadataTypeId;
                            else
                                object.metadataTypeId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataTypeId) : options.longs === Number ? new $util.LongBits(message.metadataTypeId.low >>> 0, message.metadataTypeId.high >>> 0).toNumber() : message.metadataTypeId;
                        return object;
                    };
    
                    /**
                     * Converts this MetadataValue to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MetadataValue
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MetadataValue.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MetadataValue;
                })();
    
                v1.ListMetadataTypeRequest = (function() {
    
                    /**
                     * Properties of a ListMetadataTypeRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListMetadataTypeRequest
                     * @property {number|null} [companyId] ListMetadataTypeRequest companyId
                     * @property {boolean|null} [isSystem] ListMetadataTypeRequest isSystem
                     * @property {boolean|null} [includeMetadataValues] ListMetadataTypeRequest includeMetadataValues
                     */
    
                    /**
                     * Constructs a new ListMetadataTypeRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListMetadataTypeRequest.
                     * @implements IListMetadataTypeRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListMetadataTypeRequest=} [properties] Properties to set
                     */
                    function ListMetadataTypeRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListMetadataTypeRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @instance
                     */
                    ListMetadataTypeRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListMetadataTypeRequest isSystem.
                     * @member {boolean} isSystem
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @instance
                     */
                    ListMetadataTypeRequest.prototype.isSystem = false;
    
                    /**
                     * ListMetadataTypeRequest includeMetadataValues.
                     * @member {boolean} includeMetadataValues
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @instance
                     */
                    ListMetadataTypeRequest.prototype.includeMetadataValues = false;
    
                    /**
                     * Creates a new ListMetadataTypeRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.IListMetadataTypeRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListMetadataTypeRequest} ListMetadataTypeRequest instance
                     */
                    ListMetadataTypeRequest.create = function create(properties) {
                        return new ListMetadataTypeRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListMetadataTypeRequest message. Does not implicitly {@link palexy.streaming.v1.ListMetadataTypeRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.IListMetadataTypeRequest} message ListMetadataTypeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMetadataTypeRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.isSystem != null && Object.hasOwnProperty.call(message, "isSystem"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isSystem);
                        if (message.includeMetadataValues != null && Object.hasOwnProperty.call(message, "includeMetadataValues"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeMetadataValues);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListMetadataTypeRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListMetadataTypeRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.IListMetadataTypeRequest} message ListMetadataTypeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMetadataTypeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListMetadataTypeRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListMetadataTypeRequest} ListMetadataTypeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMetadataTypeRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListMetadataTypeRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.isSystem = reader.bool();
                                break;
                            case 3:
                                message.includeMetadataValues = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListMetadataTypeRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListMetadataTypeRequest} ListMetadataTypeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMetadataTypeRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListMetadataTypeRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListMetadataTypeRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.isSystem != null && message.hasOwnProperty("isSystem"))
                            if (typeof message.isSystem !== "boolean")
                                return "isSystem: boolean expected";
                        if (message.includeMetadataValues != null && message.hasOwnProperty("includeMetadataValues"))
                            if (typeof message.includeMetadataValues !== "boolean")
                                return "includeMetadataValues: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListMetadataTypeRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListMetadataTypeRequest} ListMetadataTypeRequest
                     */
                    ListMetadataTypeRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListMetadataTypeRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListMetadataTypeRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.isSystem != null)
                            message.isSystem = Boolean(object.isSystem);
                        if (object.includeMetadataValues != null)
                            message.includeMetadataValues = Boolean(object.includeMetadataValues);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListMetadataTypeRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.ListMetadataTypeRequest} message ListMetadataTypeRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListMetadataTypeRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.isSystem = false;
                            object.includeMetadataValues = false;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.isSystem != null && message.hasOwnProperty("isSystem"))
                            object.isSystem = message.isSystem;
                        if (message.includeMetadataValues != null && message.hasOwnProperty("includeMetadataValues"))
                            object.includeMetadataValues = message.includeMetadataValues;
                        return object;
                    };
    
                    /**
                     * Converts this ListMetadataTypeRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListMetadataTypeRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListMetadataTypeRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListMetadataTypeRequest;
                })();
    
                v1.ListMetadataTypeResponse = (function() {
    
                    /**
                     * Properties of a ListMetadataTypeResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListMetadataTypeResponse
                     * @property {Array.<palexy.streaming.v1.IMetadataType>|null} [metadataTypes] ListMetadataTypeResponse metadataTypes
                     */
    
                    /**
                     * Constructs a new ListMetadataTypeResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListMetadataTypeResponse.
                     * @implements IListMetadataTypeResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListMetadataTypeResponse=} [properties] Properties to set
                     */
                    function ListMetadataTypeResponse(properties) {
                        this.metadataTypes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListMetadataTypeResponse metadataTypes.
                     * @member {Array.<palexy.streaming.v1.IMetadataType>} metadataTypes
                     * @memberof palexy.streaming.v1.ListMetadataTypeResponse
                     * @instance
                     */
                    ListMetadataTypeResponse.prototype.metadataTypes = $util.emptyArray;
    
                    /**
                     * Creates a new ListMetadataTypeResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListMetadataTypeResponse
                     * @static
                     * @param {palexy.streaming.v1.IListMetadataTypeResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListMetadataTypeResponse} ListMetadataTypeResponse instance
                     */
                    ListMetadataTypeResponse.create = function create(properties) {
                        return new ListMetadataTypeResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListMetadataTypeResponse message. Does not implicitly {@link palexy.streaming.v1.ListMetadataTypeResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListMetadataTypeResponse
                     * @static
                     * @param {palexy.streaming.v1.IListMetadataTypeResponse} message ListMetadataTypeResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMetadataTypeResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.metadataTypes != null && message.metadataTypes.length)
                            for (var i = 0; i < message.metadataTypes.length; ++i)
                                $root.palexy.streaming.v1.MetadataType.encode(message.metadataTypes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListMetadataTypeResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListMetadataTypeResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListMetadataTypeResponse
                     * @static
                     * @param {palexy.streaming.v1.IListMetadataTypeResponse} message ListMetadataTypeResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMetadataTypeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListMetadataTypeResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListMetadataTypeResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListMetadataTypeResponse} ListMetadataTypeResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMetadataTypeResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListMetadataTypeResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.metadataTypes && message.metadataTypes.length))
                                    message.metadataTypes = [];
                                message.metadataTypes.push($root.palexy.streaming.v1.MetadataType.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListMetadataTypeResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListMetadataTypeResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListMetadataTypeResponse} ListMetadataTypeResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMetadataTypeResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListMetadataTypeResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListMetadataTypeResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListMetadataTypeResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.metadataTypes != null && message.hasOwnProperty("metadataTypes")) {
                            if (!Array.isArray(message.metadataTypes))
                                return "metadataTypes: array expected";
                            for (var i = 0; i < message.metadataTypes.length; ++i) {
                                var error = $root.palexy.streaming.v1.MetadataType.verify(message.metadataTypes[i]);
                                if (error)
                                    return "metadataTypes." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListMetadataTypeResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListMetadataTypeResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListMetadataTypeResponse} ListMetadataTypeResponse
                     */
                    ListMetadataTypeResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListMetadataTypeResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListMetadataTypeResponse();
                        if (object.metadataTypes) {
                            if (!Array.isArray(object.metadataTypes))
                                throw TypeError(".palexy.streaming.v1.ListMetadataTypeResponse.metadataTypes: array expected");
                            message.metadataTypes = [];
                            for (var i = 0; i < object.metadataTypes.length; ++i) {
                                if (typeof object.metadataTypes[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListMetadataTypeResponse.metadataTypes: object expected");
                                message.metadataTypes[i] = $root.palexy.streaming.v1.MetadataType.fromObject(object.metadataTypes[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListMetadataTypeResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListMetadataTypeResponse
                     * @static
                     * @param {palexy.streaming.v1.ListMetadataTypeResponse} message ListMetadataTypeResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListMetadataTypeResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.metadataTypes = [];
                        if (message.metadataTypes && message.metadataTypes.length) {
                            object.metadataTypes = [];
                            for (var j = 0; j < message.metadataTypes.length; ++j)
                                object.metadataTypes[j] = $root.palexy.streaming.v1.MetadataType.toObject(message.metadataTypes[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListMetadataTypeResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListMetadataTypeResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListMetadataTypeResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListMetadataTypeResponse;
                })();
    
                v1.LayoutChangedEventService = (function() {
    
                    /**
                     * Constructs a new LayoutChangedEventService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a LayoutChangedEventService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function LayoutChangedEventService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (LayoutChangedEventService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = LayoutChangedEventService;
    
                    /**
                     * Creates new LayoutChangedEventService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.LayoutChangedEventService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {LayoutChangedEventService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    LayoutChangedEventService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.LayoutChangedEventService#listLayoutChangedEvents}.
                     * @memberof palexy.streaming.v1.LayoutChangedEventService
                     * @typedef ListLayoutChangedEventsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListLayoutChangedEventsResponse} [response] ListLayoutChangedEventsResponse
                     */
    
                    /**
                     * Calls ListLayoutChangedEvents.
                     * @function listLayoutChangedEvents
                     * @memberof palexy.streaming.v1.LayoutChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.IListLayoutChangedEventsRequest} request ListLayoutChangedEventsRequest message or plain object
                     * @param {palexy.streaming.v1.LayoutChangedEventService.ListLayoutChangedEventsCallback} callback Node-style callback called with the error, if any, and ListLayoutChangedEventsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(LayoutChangedEventService.prototype.listLayoutChangedEvents = function listLayoutChangedEvents(request, callback) {
                        return this.rpcCall(listLayoutChangedEvents, $root.palexy.streaming.v1.ListLayoutChangedEventsRequest, $root.palexy.streaming.v1.ListLayoutChangedEventsResponse, request, callback);
                    }, "name", { value: "ListLayoutChangedEvents" });
    
                    /**
                     * Calls ListLayoutChangedEvents.
                     * @function listLayoutChangedEvents
                     * @memberof palexy.streaming.v1.LayoutChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.IListLayoutChangedEventsRequest} request ListLayoutChangedEventsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListLayoutChangedEventsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.LayoutChangedEventService#createLayoutChangedEvent}.
                     * @memberof palexy.streaming.v1.LayoutChangedEventService
                     * @typedef CreateLayoutChangedEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.LayoutChangedEvent} [response] LayoutChangedEvent
                     */
    
                    /**
                     * Calls CreateLayoutChangedEvent.
                     * @function createLayoutChangedEvent
                     * @memberof palexy.streaming.v1.LayoutChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateLayoutChangedEventRequest} request CreateLayoutChangedEventRequest message or plain object
                     * @param {palexy.streaming.v1.LayoutChangedEventService.CreateLayoutChangedEventCallback} callback Node-style callback called with the error, if any, and LayoutChangedEvent
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(LayoutChangedEventService.prototype.createLayoutChangedEvent = function createLayoutChangedEvent(request, callback) {
                        return this.rpcCall(createLayoutChangedEvent, $root.palexy.streaming.v1.CreateLayoutChangedEventRequest, $root.palexy.streaming.v1.LayoutChangedEvent, request, callback);
                    }, "name", { value: "CreateLayoutChangedEvent" });
    
                    /**
                     * Calls CreateLayoutChangedEvent.
                     * @function createLayoutChangedEvent
                     * @memberof palexy.streaming.v1.LayoutChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateLayoutChangedEventRequest} request CreateLayoutChangedEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.LayoutChangedEvent>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.LayoutChangedEventService#batchUpdateLayoutChangedEvents}.
                     * @memberof palexy.streaming.v1.LayoutChangedEventService
                     * @typedef BatchUpdateLayoutChangedEventsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse} [response] BatchUpdateLayoutChangedEventsResponse
                     */
    
                    /**
                     * Calls BatchUpdateLayoutChangedEvents.
                     * @function batchUpdateLayoutChangedEvents
                     * @memberof palexy.streaming.v1.LayoutChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchUpdateLayoutChangedEventsRequest} request BatchUpdateLayoutChangedEventsRequest message or plain object
                     * @param {palexy.streaming.v1.LayoutChangedEventService.BatchUpdateLayoutChangedEventsCallback} callback Node-style callback called with the error, if any, and BatchUpdateLayoutChangedEventsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(LayoutChangedEventService.prototype.batchUpdateLayoutChangedEvents = function batchUpdateLayoutChangedEvents(request, callback) {
                        return this.rpcCall(batchUpdateLayoutChangedEvents, $root.palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest, $root.palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse, request, callback);
                    }, "name", { value: "BatchUpdateLayoutChangedEvents" });
    
                    /**
                     * Calls BatchUpdateLayoutChangedEvents.
                     * @function batchUpdateLayoutChangedEvents
                     * @memberof palexy.streaming.v1.LayoutChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchUpdateLayoutChangedEventsRequest} request BatchUpdateLayoutChangedEventsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse>} Promise
                     * @variation 2
                     */
    
                    return LayoutChangedEventService;
                })();
    
                v1.LayoutChangedEvent = (function() {
    
                    /**
                     * Properties of a LayoutChangedEvent.
                     * @memberof palexy.streaming.v1
                     * @interface ILayoutChangedEvent
                     * @property {number|null} [id] LayoutChangedEvent id
                     * @property {number|null} [storeId] LayoutChangedEvent storeId
                     * @property {string|null} [startDate] LayoutChangedEvent startDate
                     * @property {string|null} [endDate] LayoutChangedEvent endDate
                     * @property {palexy.streaming.v1.LayoutChangedEvent.Status|null} [status] LayoutChangedEvent status
                     * @property {number|null} [retryCount] LayoutChangedEvent retryCount
                     * @property {google.protobuf.ITimestamp|null} [createTime] LayoutChangedEvent createTime
                     * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] LayoutChangedEvent lastUpdateTime
                     */
    
                    /**
                     * Constructs a new LayoutChangedEvent.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a LayoutChangedEvent.
                     * @implements ILayoutChangedEvent
                     * @constructor
                     * @param {palexy.streaming.v1.ILayoutChangedEvent=} [properties] Properties to set
                     */
                    function LayoutChangedEvent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * LayoutChangedEvent id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @instance
                     */
                    LayoutChangedEvent.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * LayoutChangedEvent storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @instance
                     */
                    LayoutChangedEvent.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * LayoutChangedEvent startDate.
                     * @member {string} startDate
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @instance
                     */
                    LayoutChangedEvent.prototype.startDate = "";
    
                    /**
                     * LayoutChangedEvent endDate.
                     * @member {string} endDate
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @instance
                     */
                    LayoutChangedEvent.prototype.endDate = "";
    
                    /**
                     * LayoutChangedEvent status.
                     * @member {palexy.streaming.v1.LayoutChangedEvent.Status} status
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @instance
                     */
                    LayoutChangedEvent.prototype.status = 0;
    
                    /**
                     * LayoutChangedEvent retryCount.
                     * @member {number} retryCount
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @instance
                     */
                    LayoutChangedEvent.prototype.retryCount = 0;
    
                    /**
                     * LayoutChangedEvent createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @instance
                     */
                    LayoutChangedEvent.prototype.createTime = null;
    
                    /**
                     * LayoutChangedEvent lastUpdateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @instance
                     */
                    LayoutChangedEvent.prototype.lastUpdateTime = null;
    
                    /**
                     * Creates a new LayoutChangedEvent instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @static
                     * @param {palexy.streaming.v1.ILayoutChangedEvent=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.LayoutChangedEvent} LayoutChangedEvent instance
                     */
                    LayoutChangedEvent.create = function create(properties) {
                        return new LayoutChangedEvent(properties);
                    };
    
                    /**
                     * Encodes the specified LayoutChangedEvent message. Does not implicitly {@link palexy.streaming.v1.LayoutChangedEvent.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @static
                     * @param {palexy.streaming.v1.ILayoutChangedEvent} message LayoutChangedEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LayoutChangedEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.startDate);
                        if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.endDate);
                        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
                        if (message.retryCount != null && Object.hasOwnProperty.call(message, "retryCount"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.retryCount);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified LayoutChangedEvent message, length delimited. Does not implicitly {@link palexy.streaming.v1.LayoutChangedEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @static
                     * @param {palexy.streaming.v1.ILayoutChangedEvent} message LayoutChangedEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LayoutChangedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a LayoutChangedEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.LayoutChangedEvent} LayoutChangedEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LayoutChangedEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.LayoutChangedEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.startDate = reader.string();
                                break;
                            case 4:
                                message.endDate = reader.string();
                                break;
                            case 5:
                                message.status = reader.int32();
                                break;
                            case 6:
                                message.retryCount = reader.int32();
                                break;
                            case 7:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a LayoutChangedEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.LayoutChangedEvent} LayoutChangedEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LayoutChangedEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a LayoutChangedEvent message.
                     * @function verify
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    LayoutChangedEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.retryCount != null && message.hasOwnProperty("retryCount"))
                            if (!$util.isInteger(message.retryCount))
                                return "retryCount: integer expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                            if (error)
                                return "lastUpdateTime." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a LayoutChangedEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.LayoutChangedEvent} LayoutChangedEvent
                     */
                    LayoutChangedEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.LayoutChangedEvent)
                            return object;
                        var message = new $root.palexy.streaming.v1.LayoutChangedEvent();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        switch (object.status) {
                        case "UNPROCESSED":
                        case 0:
                            message.status = 0;
                            break;
                        case "WAITING_FOR_RETRY":
                        case 1:
                            message.status = 1;
                            break;
                        case "DONE":
                        case 2:
                            message.status = 2;
                            break;
                        }
                        if (object.retryCount != null)
                            message.retryCount = object.retryCount | 0;
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.LayoutChangedEvent.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.lastUpdateTime != null) {
                            if (typeof object.lastUpdateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.LayoutChangedEvent.lastUpdateTime: object expected");
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a LayoutChangedEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @static
                     * @param {palexy.streaming.v1.LayoutChangedEvent} message LayoutChangedEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LayoutChangedEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.startDate = "";
                            object.endDate = "";
                            object.status = options.enums === String ? "UNPROCESSED" : 0;
                            object.retryCount = 0;
                            object.createTime = null;
                            object.lastUpdateTime = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.palexy.streaming.v1.LayoutChangedEvent.Status[message.status] : message.status;
                        if (message.retryCount != null && message.hasOwnProperty("retryCount"))
                            object.retryCount = message.retryCount;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                            object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                        return object;
                    };
    
                    /**
                     * Converts this LayoutChangedEvent to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.LayoutChangedEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LayoutChangedEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Status enum.
                     * @name palexy.streaming.v1.LayoutChangedEvent.Status
                     * @enum {number}
                     * @property {number} UNPROCESSED=0 UNPROCESSED value
                     * @property {number} WAITING_FOR_RETRY=1 WAITING_FOR_RETRY value
                     * @property {number} DONE=2 DONE value
                     */
                    LayoutChangedEvent.Status = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNPROCESSED"] = 0;
                        values[valuesById[1] = "WAITING_FOR_RETRY"] = 1;
                        values[valuesById[2] = "DONE"] = 2;
                        return values;
                    })();
    
                    return LayoutChangedEvent;
                })();
    
                v1.ListLayoutChangedEventsRequest = (function() {
    
                    /**
                     * Properties of a ListLayoutChangedEventsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListLayoutChangedEventsRequest
                     */
    
                    /**
                     * Constructs a new ListLayoutChangedEventsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListLayoutChangedEventsRequest.
                     * @implements IListLayoutChangedEventsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListLayoutChangedEventsRequest=} [properties] Properties to set
                     */
                    function ListLayoutChangedEventsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new ListLayoutChangedEventsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutChangedEventsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListLayoutChangedEventsRequest} ListLayoutChangedEventsRequest instance
                     */
                    ListLayoutChangedEventsRequest.create = function create(properties) {
                        return new ListLayoutChangedEventsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListLayoutChangedEventsRequest message. Does not implicitly {@link palexy.streaming.v1.ListLayoutChangedEventsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutChangedEventsRequest} message ListLayoutChangedEventsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListLayoutChangedEventsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListLayoutChangedEventsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListLayoutChangedEventsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutChangedEventsRequest} message ListLayoutChangedEventsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListLayoutChangedEventsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListLayoutChangedEventsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListLayoutChangedEventsRequest} ListLayoutChangedEventsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListLayoutChangedEventsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListLayoutChangedEventsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListLayoutChangedEventsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListLayoutChangedEventsRequest} ListLayoutChangedEventsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListLayoutChangedEventsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListLayoutChangedEventsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListLayoutChangedEventsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListLayoutChangedEventsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListLayoutChangedEventsRequest} ListLayoutChangedEventsRequest
                     */
                    ListLayoutChangedEventsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListLayoutChangedEventsRequest)
                            return object;
                        return new $root.palexy.streaming.v1.ListLayoutChangedEventsRequest();
                    };
    
                    /**
                     * Creates a plain object from a ListLayoutChangedEventsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsRequest
                     * @static
                     * @param {palexy.streaming.v1.ListLayoutChangedEventsRequest} message ListLayoutChangedEventsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListLayoutChangedEventsRequest.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this ListLayoutChangedEventsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListLayoutChangedEventsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListLayoutChangedEventsRequest;
                })();
    
                v1.ListLayoutChangedEventsResponse = (function() {
    
                    /**
                     * Properties of a ListLayoutChangedEventsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListLayoutChangedEventsResponse
                     * @property {Array.<palexy.streaming.v1.ILayoutChangedEvent>|null} [layoutChangedEvents] ListLayoutChangedEventsResponse layoutChangedEvents
                     */
    
                    /**
                     * Constructs a new ListLayoutChangedEventsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListLayoutChangedEventsResponse.
                     * @implements IListLayoutChangedEventsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListLayoutChangedEventsResponse=} [properties] Properties to set
                     */
                    function ListLayoutChangedEventsResponse(properties) {
                        this.layoutChangedEvents = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListLayoutChangedEventsResponse layoutChangedEvents.
                     * @member {Array.<palexy.streaming.v1.ILayoutChangedEvent>} layoutChangedEvents
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsResponse
                     * @instance
                     */
                    ListLayoutChangedEventsResponse.prototype.layoutChangedEvents = $util.emptyArray;
    
                    /**
                     * Creates a new ListLayoutChangedEventsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutChangedEventsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListLayoutChangedEventsResponse} ListLayoutChangedEventsResponse instance
                     */
                    ListLayoutChangedEventsResponse.create = function create(properties) {
                        return new ListLayoutChangedEventsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListLayoutChangedEventsResponse message. Does not implicitly {@link palexy.streaming.v1.ListLayoutChangedEventsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutChangedEventsResponse} message ListLayoutChangedEventsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListLayoutChangedEventsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.layoutChangedEvents != null && message.layoutChangedEvents.length)
                            for (var i = 0; i < message.layoutChangedEvents.length; ++i)
                                $root.palexy.streaming.v1.LayoutChangedEvent.encode(message.layoutChangedEvents[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListLayoutChangedEventsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListLayoutChangedEventsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutChangedEventsResponse} message ListLayoutChangedEventsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListLayoutChangedEventsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListLayoutChangedEventsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListLayoutChangedEventsResponse} ListLayoutChangedEventsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListLayoutChangedEventsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListLayoutChangedEventsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.layoutChangedEvents && message.layoutChangedEvents.length))
                                    message.layoutChangedEvents = [];
                                message.layoutChangedEvents.push($root.palexy.streaming.v1.LayoutChangedEvent.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListLayoutChangedEventsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListLayoutChangedEventsResponse} ListLayoutChangedEventsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListLayoutChangedEventsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListLayoutChangedEventsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListLayoutChangedEventsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.layoutChangedEvents != null && message.hasOwnProperty("layoutChangedEvents")) {
                            if (!Array.isArray(message.layoutChangedEvents))
                                return "layoutChangedEvents: array expected";
                            for (var i = 0; i < message.layoutChangedEvents.length; ++i) {
                                var error = $root.palexy.streaming.v1.LayoutChangedEvent.verify(message.layoutChangedEvents[i]);
                                if (error)
                                    return "layoutChangedEvents." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListLayoutChangedEventsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListLayoutChangedEventsResponse} ListLayoutChangedEventsResponse
                     */
                    ListLayoutChangedEventsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListLayoutChangedEventsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListLayoutChangedEventsResponse();
                        if (object.layoutChangedEvents) {
                            if (!Array.isArray(object.layoutChangedEvents))
                                throw TypeError(".palexy.streaming.v1.ListLayoutChangedEventsResponse.layoutChangedEvents: array expected");
                            message.layoutChangedEvents = [];
                            for (var i = 0; i < object.layoutChangedEvents.length; ++i) {
                                if (typeof object.layoutChangedEvents[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListLayoutChangedEventsResponse.layoutChangedEvents: object expected");
                                message.layoutChangedEvents[i] = $root.palexy.streaming.v1.LayoutChangedEvent.fromObject(object.layoutChangedEvents[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListLayoutChangedEventsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListLayoutChangedEventsResponse} message ListLayoutChangedEventsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListLayoutChangedEventsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.layoutChangedEvents = [];
                        if (message.layoutChangedEvents && message.layoutChangedEvents.length) {
                            object.layoutChangedEvents = [];
                            for (var j = 0; j < message.layoutChangedEvents.length; ++j)
                                object.layoutChangedEvents[j] = $root.palexy.streaming.v1.LayoutChangedEvent.toObject(message.layoutChangedEvents[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListLayoutChangedEventsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListLayoutChangedEventsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListLayoutChangedEventsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListLayoutChangedEventsResponse;
                })();
    
                v1.CreateLayoutChangedEventRequest = (function() {
    
                    /**
                     * Properties of a CreateLayoutChangedEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateLayoutChangedEventRequest
                     * @property {palexy.streaming.v1.ILayoutChangedEvent|null} [layoutChangedEvent] CreateLayoutChangedEventRequest layoutChangedEvent
                     */
    
                    /**
                     * Constructs a new CreateLayoutChangedEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateLayoutChangedEventRequest.
                     * @implements ICreateLayoutChangedEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateLayoutChangedEventRequest=} [properties] Properties to set
                     */
                    function CreateLayoutChangedEventRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateLayoutChangedEventRequest layoutChangedEvent.
                     * @member {palexy.streaming.v1.ILayoutChangedEvent|null|undefined} layoutChangedEvent
                     * @memberof palexy.streaming.v1.CreateLayoutChangedEventRequest
                     * @instance
                     */
                    CreateLayoutChangedEventRequest.prototype.layoutChangedEvent = null;
    
                    /**
                     * Creates a new CreateLayoutChangedEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateLayoutChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateLayoutChangedEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateLayoutChangedEventRequest} CreateLayoutChangedEventRequest instance
                     */
                    CreateLayoutChangedEventRequest.create = function create(properties) {
                        return new CreateLayoutChangedEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateLayoutChangedEventRequest message. Does not implicitly {@link palexy.streaming.v1.CreateLayoutChangedEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateLayoutChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateLayoutChangedEventRequest} message CreateLayoutChangedEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateLayoutChangedEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.layoutChangedEvent != null && Object.hasOwnProperty.call(message, "layoutChangedEvent"))
                            $root.palexy.streaming.v1.LayoutChangedEvent.encode(message.layoutChangedEvent, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateLayoutChangedEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateLayoutChangedEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateLayoutChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateLayoutChangedEventRequest} message CreateLayoutChangedEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateLayoutChangedEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateLayoutChangedEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateLayoutChangedEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateLayoutChangedEventRequest} CreateLayoutChangedEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateLayoutChangedEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateLayoutChangedEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.layoutChangedEvent = $root.palexy.streaming.v1.LayoutChangedEvent.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateLayoutChangedEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateLayoutChangedEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateLayoutChangedEventRequest} CreateLayoutChangedEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateLayoutChangedEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateLayoutChangedEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateLayoutChangedEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateLayoutChangedEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.layoutChangedEvent != null && message.hasOwnProperty("layoutChangedEvent")) {
                            var error = $root.palexy.streaming.v1.LayoutChangedEvent.verify(message.layoutChangedEvent);
                            if (error)
                                return "layoutChangedEvent." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateLayoutChangedEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateLayoutChangedEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateLayoutChangedEventRequest} CreateLayoutChangedEventRequest
                     */
                    CreateLayoutChangedEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateLayoutChangedEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateLayoutChangedEventRequest();
                        if (object.layoutChangedEvent != null) {
                            if (typeof object.layoutChangedEvent !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateLayoutChangedEventRequest.layoutChangedEvent: object expected");
                            message.layoutChangedEvent = $root.palexy.streaming.v1.LayoutChangedEvent.fromObject(object.layoutChangedEvent);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateLayoutChangedEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateLayoutChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateLayoutChangedEventRequest} message CreateLayoutChangedEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateLayoutChangedEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.layoutChangedEvent = null;
                        if (message.layoutChangedEvent != null && message.hasOwnProperty("layoutChangedEvent"))
                            object.layoutChangedEvent = $root.palexy.streaming.v1.LayoutChangedEvent.toObject(message.layoutChangedEvent, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateLayoutChangedEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateLayoutChangedEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateLayoutChangedEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateLayoutChangedEventRequest;
                })();
    
                v1.BatchUpdateLayoutChangedEventsRequest = (function() {
    
                    /**
                     * Properties of a BatchUpdateLayoutChangedEventsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchUpdateLayoutChangedEventsRequest
                     * @property {Array.<number>|null} [layoutChangedEventIds] BatchUpdateLayoutChangedEventsRequest layoutChangedEventIds
                     * @property {palexy.streaming.v1.LayoutChangedEvent.Status|null} [status] BatchUpdateLayoutChangedEventsRequest status
                     */
    
                    /**
                     * Constructs a new BatchUpdateLayoutChangedEventsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchUpdateLayoutChangedEventsRequest.
                     * @implements IBatchUpdateLayoutChangedEventsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchUpdateLayoutChangedEventsRequest=} [properties] Properties to set
                     */
                    function BatchUpdateLayoutChangedEventsRequest(properties) {
                        this.layoutChangedEventIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchUpdateLayoutChangedEventsRequest layoutChangedEventIds.
                     * @member {Array.<number>} layoutChangedEventIds
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest
                     * @instance
                     */
                    BatchUpdateLayoutChangedEventsRequest.prototype.layoutChangedEventIds = $util.emptyArray;
    
                    /**
                     * BatchUpdateLayoutChangedEventsRequest status.
                     * @member {palexy.streaming.v1.LayoutChangedEvent.Status} status
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest
                     * @instance
                     */
                    BatchUpdateLayoutChangedEventsRequest.prototype.status = 0;
    
                    /**
                     * Creates a new BatchUpdateLayoutChangedEventsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateLayoutChangedEventsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest} BatchUpdateLayoutChangedEventsRequest instance
                     */
                    BatchUpdateLayoutChangedEventsRequest.create = function create(properties) {
                        return new BatchUpdateLayoutChangedEventsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchUpdateLayoutChangedEventsRequest message. Does not implicitly {@link palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateLayoutChangedEventsRequest} message BatchUpdateLayoutChangedEventsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateLayoutChangedEventsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.layoutChangedEventIds != null && message.layoutChangedEventIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.layoutChangedEventIds.length; ++i)
                                writer.int64(message.layoutChangedEventIds[i]);
                            writer.ldelim();
                        }
                        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchUpdateLayoutChangedEventsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateLayoutChangedEventsRequest} message BatchUpdateLayoutChangedEventsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateLayoutChangedEventsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchUpdateLayoutChangedEventsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest} BatchUpdateLayoutChangedEventsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateLayoutChangedEventsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.layoutChangedEventIds && message.layoutChangedEventIds.length))
                                    message.layoutChangedEventIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.layoutChangedEventIds.push(reader.int64());
                                } else
                                    message.layoutChangedEventIds.push(reader.int64());
                                break;
                            case 2:
                                message.status = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchUpdateLayoutChangedEventsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest} BatchUpdateLayoutChangedEventsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateLayoutChangedEventsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchUpdateLayoutChangedEventsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchUpdateLayoutChangedEventsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.layoutChangedEventIds != null && message.hasOwnProperty("layoutChangedEventIds")) {
                            if (!Array.isArray(message.layoutChangedEventIds))
                                return "layoutChangedEventIds: array expected";
                            for (var i = 0; i < message.layoutChangedEventIds.length; ++i)
                                if (!$util.isInteger(message.layoutChangedEventIds[i]) && !(message.layoutChangedEventIds[i] && $util.isInteger(message.layoutChangedEventIds[i].low) && $util.isInteger(message.layoutChangedEventIds[i].high)))
                                    return "layoutChangedEventIds: integer|Long[] expected";
                        }
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a BatchUpdateLayoutChangedEventsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest} BatchUpdateLayoutChangedEventsRequest
                     */
                    BatchUpdateLayoutChangedEventsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest();
                        if (object.layoutChangedEventIds) {
                            if (!Array.isArray(object.layoutChangedEventIds))
                                throw TypeError(".palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest.layoutChangedEventIds: array expected");
                            message.layoutChangedEventIds = [];
                            for (var i = 0; i < object.layoutChangedEventIds.length; ++i)
                                if ($util.Long)
                                    (message.layoutChangedEventIds[i] = $util.Long.fromValue(object.layoutChangedEventIds[i])).unsigned = false;
                                else if (typeof object.layoutChangedEventIds[i] === "string")
                                    message.layoutChangedEventIds[i] = parseInt(object.layoutChangedEventIds[i], 10);
                                else if (typeof object.layoutChangedEventIds[i] === "number")
                                    message.layoutChangedEventIds[i] = object.layoutChangedEventIds[i];
                                else if (typeof object.layoutChangedEventIds[i] === "object")
                                    message.layoutChangedEventIds[i] = new $util.LongBits(object.layoutChangedEventIds[i].low >>> 0, object.layoutChangedEventIds[i].high >>> 0).toNumber();
                        }
                        switch (object.status) {
                        case "UNPROCESSED":
                        case 0:
                            message.status = 0;
                            break;
                        case "WAITING_FOR_RETRY":
                        case 1:
                            message.status = 1;
                            break;
                        case "DONE":
                        case 2:
                            message.status = 2;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchUpdateLayoutChangedEventsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest} message BatchUpdateLayoutChangedEventsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchUpdateLayoutChangedEventsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.layoutChangedEventIds = [];
                        if (options.defaults)
                            object.status = options.enums === String ? "UNPROCESSED" : 0;
                        if (message.layoutChangedEventIds && message.layoutChangedEventIds.length) {
                            object.layoutChangedEventIds = [];
                            for (var j = 0; j < message.layoutChangedEventIds.length; ++j)
                                if (typeof message.layoutChangedEventIds[j] === "number")
                                    object.layoutChangedEventIds[j] = options.longs === String ? String(message.layoutChangedEventIds[j]) : message.layoutChangedEventIds[j];
                                else
                                    object.layoutChangedEventIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.layoutChangedEventIds[j]) : options.longs === Number ? new $util.LongBits(message.layoutChangedEventIds[j].low >>> 0, message.layoutChangedEventIds[j].high >>> 0).toNumber() : message.layoutChangedEventIds[j];
                        }
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.palexy.streaming.v1.LayoutChangedEvent.Status[message.status] : message.status;
                        return object;
                    };
    
                    /**
                     * Converts this BatchUpdateLayoutChangedEventsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchUpdateLayoutChangedEventsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchUpdateLayoutChangedEventsRequest;
                })();
    
                v1.BatchUpdateLayoutChangedEventsResponse = (function() {
    
                    /**
                     * Properties of a BatchUpdateLayoutChangedEventsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchUpdateLayoutChangedEventsResponse
                     */
    
                    /**
                     * Constructs a new BatchUpdateLayoutChangedEventsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchUpdateLayoutChangedEventsResponse.
                     * @implements IBatchUpdateLayoutChangedEventsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchUpdateLayoutChangedEventsResponse=} [properties] Properties to set
                     */
                    function BatchUpdateLayoutChangedEventsResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new BatchUpdateLayoutChangedEventsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateLayoutChangedEventsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse} BatchUpdateLayoutChangedEventsResponse instance
                     */
                    BatchUpdateLayoutChangedEventsResponse.create = function create(properties) {
                        return new BatchUpdateLayoutChangedEventsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchUpdateLayoutChangedEventsResponse message. Does not implicitly {@link palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateLayoutChangedEventsResponse} message BatchUpdateLayoutChangedEventsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateLayoutChangedEventsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchUpdateLayoutChangedEventsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateLayoutChangedEventsResponse} message BatchUpdateLayoutChangedEventsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateLayoutChangedEventsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchUpdateLayoutChangedEventsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse} BatchUpdateLayoutChangedEventsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateLayoutChangedEventsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchUpdateLayoutChangedEventsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse} BatchUpdateLayoutChangedEventsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateLayoutChangedEventsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchUpdateLayoutChangedEventsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchUpdateLayoutChangedEventsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchUpdateLayoutChangedEventsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse} BatchUpdateLayoutChangedEventsResponse
                     */
                    BatchUpdateLayoutChangedEventsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse)
                            return object;
                        return new $root.palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse();
                    };
    
                    /**
                     * Creates a plain object from a BatchUpdateLayoutChangedEventsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse} message BatchUpdateLayoutChangedEventsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchUpdateLayoutChangedEventsResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this BatchUpdateLayoutChangedEventsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchUpdateLayoutChangedEventsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchUpdateLayoutChangedEventsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchUpdateLayoutChangedEventsResponse;
                })();
    
                v1.MergingConfigPreset = (function() {
    
                    /**
                     * Properties of a MergingConfigPreset.
                     * @memberof palexy.streaming.v1
                     * @interface IMergingConfigPreset
                     * @property {number|null} [id] MergingConfigPreset id
                     * @property {palexy.streaming.v1.IMergingConfig|null} [config] MergingConfigPreset config
                     * @property {string|null} [name] MergingConfigPreset name
                     * @property {string|null} [note] MergingConfigPreset note
                     * @property {number|null} [createTimeMs] MergingConfigPreset createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] MergingConfigPreset lastUpdateTimeMs
                     * @property {Array.<number>|null} [companyIds] MergingConfigPreset companyIds
                     * @property {Array.<palexy.streaming.v1.Store.StoreType>|null} [storeTypes] MergingConfigPreset storeTypes
                     * @property {Array.<palexy.streaming.v1.Store.CountingType>|null} [countingTypes] MergingConfigPreset countingTypes
                     * @property {boolean|null} [isEnabledForSuggestion] MergingConfigPreset isEnabledForSuggestion
                     */
    
                    /**
                     * Constructs a new MergingConfigPreset.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MergingConfigPreset.
                     * @implements IMergingConfigPreset
                     * @constructor
                     * @param {palexy.streaming.v1.IMergingConfigPreset=} [properties] Properties to set
                     */
                    function MergingConfigPreset(properties) {
                        this.companyIds = [];
                        this.storeTypes = [];
                        this.countingTypes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MergingConfigPreset id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @instance
                     */
                    MergingConfigPreset.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MergingConfigPreset config.
                     * @member {palexy.streaming.v1.IMergingConfig|null|undefined} config
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @instance
                     */
                    MergingConfigPreset.prototype.config = null;
    
                    /**
                     * MergingConfigPreset name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @instance
                     */
                    MergingConfigPreset.prototype.name = "";
    
                    /**
                     * MergingConfigPreset note.
                     * @member {string} note
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @instance
                     */
                    MergingConfigPreset.prototype.note = "";
    
                    /**
                     * MergingConfigPreset createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @instance
                     */
                    MergingConfigPreset.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MergingConfigPreset lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @instance
                     */
                    MergingConfigPreset.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MergingConfigPreset companyIds.
                     * @member {Array.<number>} companyIds
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @instance
                     */
                    MergingConfigPreset.prototype.companyIds = $util.emptyArray;
    
                    /**
                     * MergingConfigPreset storeTypes.
                     * @member {Array.<palexy.streaming.v1.Store.StoreType>} storeTypes
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @instance
                     */
                    MergingConfigPreset.prototype.storeTypes = $util.emptyArray;
    
                    /**
                     * MergingConfigPreset countingTypes.
                     * @member {Array.<palexy.streaming.v1.Store.CountingType>} countingTypes
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @instance
                     */
                    MergingConfigPreset.prototype.countingTypes = $util.emptyArray;
    
                    /**
                     * MergingConfigPreset isEnabledForSuggestion.
                     * @member {boolean} isEnabledForSuggestion
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @instance
                     */
                    MergingConfigPreset.prototype.isEnabledForSuggestion = false;
    
                    /**
                     * Creates a new MergingConfigPreset instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @static
                     * @param {palexy.streaming.v1.IMergingConfigPreset=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MergingConfigPreset} MergingConfigPreset instance
                     */
                    MergingConfigPreset.create = function create(properties) {
                        return new MergingConfigPreset(properties);
                    };
    
                    /**
                     * Encodes the specified MergingConfigPreset message. Does not implicitly {@link palexy.streaming.v1.MergingConfigPreset.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @static
                     * @param {palexy.streaming.v1.IMergingConfigPreset} message MergingConfigPreset message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergingConfigPreset.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                            $root.palexy.streaming.v1.MergingConfig.encode(message.config, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                        if (message.note != null && Object.hasOwnProperty.call(message, "note"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.note);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.lastUpdateTimeMs);
                        if (message.companyIds != null && message.companyIds.length) {
                            writer.uint32(/* id 7, wireType 2 =*/58).fork();
                            for (var i = 0; i < message.companyIds.length; ++i)
                                writer.int64(message.companyIds[i]);
                            writer.ldelim();
                        }
                        if (message.storeTypes != null && message.storeTypes.length) {
                            writer.uint32(/* id 8, wireType 2 =*/66).fork();
                            for (var i = 0; i < message.storeTypes.length; ++i)
                                writer.int32(message.storeTypes[i]);
                            writer.ldelim();
                        }
                        if (message.countingTypes != null && message.countingTypes.length) {
                            writer.uint32(/* id 9, wireType 2 =*/74).fork();
                            for (var i = 0; i < message.countingTypes.length; ++i)
                                writer.int32(message.countingTypes[i]);
                            writer.ldelim();
                        }
                        if (message.isEnabledForSuggestion != null && Object.hasOwnProperty.call(message, "isEnabledForSuggestion"))
                            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isEnabledForSuggestion);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MergingConfigPreset message, length delimited. Does not implicitly {@link palexy.streaming.v1.MergingConfigPreset.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @static
                     * @param {palexy.streaming.v1.IMergingConfigPreset} message MergingConfigPreset message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergingConfigPreset.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MergingConfigPreset message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MergingConfigPreset} MergingConfigPreset
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergingConfigPreset.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MergingConfigPreset();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.config = $root.palexy.streaming.v1.MergingConfig.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.name = reader.string();
                                break;
                            case 4:
                                message.note = reader.string();
                                break;
                            case 5:
                                message.createTimeMs = reader.int64();
                                break;
                            case 6:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 7:
                                if (!(message.companyIds && message.companyIds.length))
                                    message.companyIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.companyIds.push(reader.int64());
                                } else
                                    message.companyIds.push(reader.int64());
                                break;
                            case 8:
                                if (!(message.storeTypes && message.storeTypes.length))
                                    message.storeTypes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeTypes.push(reader.int32());
                                } else
                                    message.storeTypes.push(reader.int32());
                                break;
                            case 9:
                                if (!(message.countingTypes && message.countingTypes.length))
                                    message.countingTypes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.countingTypes.push(reader.int32());
                                } else
                                    message.countingTypes.push(reader.int32());
                                break;
                            case 10:
                                message.isEnabledForSuggestion = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MergingConfigPreset message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MergingConfigPreset} MergingConfigPreset
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergingConfigPreset.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MergingConfigPreset message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MergingConfigPreset.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.config != null && message.hasOwnProperty("config")) {
                            var error = $root.palexy.streaming.v1.MergingConfig.verify(message.config);
                            if (error)
                                return "config." + error;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.note != null && message.hasOwnProperty("note"))
                            if (!$util.isString(message.note))
                                return "note: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                            if (!Array.isArray(message.companyIds))
                                return "companyIds: array expected";
                            for (var i = 0; i < message.companyIds.length; ++i)
                                if (!$util.isInteger(message.companyIds[i]) && !(message.companyIds[i] && $util.isInteger(message.companyIds[i].low) && $util.isInteger(message.companyIds[i].high)))
                                    return "companyIds: integer|Long[] expected";
                        }
                        if (message.storeTypes != null && message.hasOwnProperty("storeTypes")) {
                            if (!Array.isArray(message.storeTypes))
                                return "storeTypes: array expected";
                            for (var i = 0; i < message.storeTypes.length; ++i)
                                switch (message.storeTypes[i]) {
                                default:
                                    return "storeTypes: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                    break;
                                }
                        }
                        if (message.countingTypes != null && message.hasOwnProperty("countingTypes")) {
                            if (!Array.isArray(message.countingTypes))
                                return "countingTypes: array expected";
                            for (var i = 0; i < message.countingTypes.length; ++i)
                                switch (message.countingTypes[i]) {
                                default:
                                    return "countingTypes: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                    break;
                                }
                        }
                        if (message.isEnabledForSuggestion != null && message.hasOwnProperty("isEnabledForSuggestion"))
                            if (typeof message.isEnabledForSuggestion !== "boolean")
                                return "isEnabledForSuggestion: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a MergingConfigPreset message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MergingConfigPreset} MergingConfigPreset
                     */
                    MergingConfigPreset.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MergingConfigPreset)
                            return object;
                        var message = new $root.palexy.streaming.v1.MergingConfigPreset();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.config != null) {
                            if (typeof object.config !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfigPreset.config: object expected");
                            message.config = $root.palexy.streaming.v1.MergingConfig.fromObject(object.config);
                        }
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.note != null)
                            message.note = String(object.note);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.companyIds) {
                            if (!Array.isArray(object.companyIds))
                                throw TypeError(".palexy.streaming.v1.MergingConfigPreset.companyIds: array expected");
                            message.companyIds = [];
                            for (var i = 0; i < object.companyIds.length; ++i)
                                if ($util.Long)
                                    (message.companyIds[i] = $util.Long.fromValue(object.companyIds[i])).unsigned = false;
                                else if (typeof object.companyIds[i] === "string")
                                    message.companyIds[i] = parseInt(object.companyIds[i], 10);
                                else if (typeof object.companyIds[i] === "number")
                                    message.companyIds[i] = object.companyIds[i];
                                else if (typeof object.companyIds[i] === "object")
                                    message.companyIds[i] = new $util.LongBits(object.companyIds[i].low >>> 0, object.companyIds[i].high >>> 0).toNumber();
                        }
                        if (object.storeTypes) {
                            if (!Array.isArray(object.storeTypes))
                                throw TypeError(".palexy.streaming.v1.MergingConfigPreset.storeTypes: array expected");
                            message.storeTypes = [];
                            for (var i = 0; i < object.storeTypes.length; ++i)
                                switch (object.storeTypes[i]) {
                                default:
                                case "UNKNOWN":
                                case 0:
                                    message.storeTypes[i] = 0;
                                    break;
                                case "INDEPENDENT":
                                case 1:
                                    message.storeTypes[i] = 1;
                                    break;
                                case "SHOPPING_MALL":
                                case 2:
                                    message.storeTypes[i] = 2;
                                    break;
                                }
                        }
                        if (object.countingTypes) {
                            if (!Array.isArray(object.countingTypes))
                                throw TypeError(".palexy.streaming.v1.MergingConfigPreset.countingTypes: array expected");
                            message.countingTypes = [];
                            for (var i = 0; i < object.countingTypes.length; ++i)
                                switch (object.countingTypes[i]) {
                                default:
                                case "UNKNOWN_COUNTING_TYPE":
                                case 0:
                                    message.countingTypes[i] = 0;
                                    break;
                                case "FULL_ANALYTIC":
                                case 1:
                                    message.countingTypes[i] = 1;
                                    break;
                                case "TRAFFIC_COUNTING":
                                case 2:
                                    message.countingTypes[i] = 2;
                                    break;
                                case "ENTRANCE_COUNTING":
                                case 3:
                                    message.countingTypes[i] = 3;
                                    break;
                                case "MINIMAL_CAMERA_COUNTING":
                                case 4:
                                    message.countingTypes[i] = 4;
                                    break;
                                }
                        }
                        if (object.isEnabledForSuggestion != null)
                            message.isEnabledForSuggestion = Boolean(object.isEnabledForSuggestion);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MergingConfigPreset message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @static
                     * @param {palexy.streaming.v1.MergingConfigPreset} message MergingConfigPreset
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MergingConfigPreset.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.companyIds = [];
                            object.storeTypes = [];
                            object.countingTypes = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.config = null;
                            object.name = "";
                            object.note = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            object.isEnabledForSuggestion = false;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.config != null && message.hasOwnProperty("config"))
                            object.config = $root.palexy.streaming.v1.MergingConfig.toObject(message.config, options);
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.note != null && message.hasOwnProperty("note"))
                            object.note = message.note;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.companyIds && message.companyIds.length) {
                            object.companyIds = [];
                            for (var j = 0; j < message.companyIds.length; ++j)
                                if (typeof message.companyIds[j] === "number")
                                    object.companyIds[j] = options.longs === String ? String(message.companyIds[j]) : message.companyIds[j];
                                else
                                    object.companyIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.companyIds[j]) : options.longs === Number ? new $util.LongBits(message.companyIds[j].low >>> 0, message.companyIds[j].high >>> 0).toNumber() : message.companyIds[j];
                        }
                        if (message.storeTypes && message.storeTypes.length) {
                            object.storeTypes = [];
                            for (var j = 0; j < message.storeTypes.length; ++j)
                                object.storeTypes[j] = options.enums === String ? $root.palexy.streaming.v1.Store.StoreType[message.storeTypes[j]] : message.storeTypes[j];
                        }
                        if (message.countingTypes && message.countingTypes.length) {
                            object.countingTypes = [];
                            for (var j = 0; j < message.countingTypes.length; ++j)
                                object.countingTypes[j] = options.enums === String ? $root.palexy.streaming.v1.Store.CountingType[message.countingTypes[j]] : message.countingTypes[j];
                        }
                        if (message.isEnabledForSuggestion != null && message.hasOwnProperty("isEnabledForSuggestion"))
                            object.isEnabledForSuggestion = message.isEnabledForSuggestion;
                        return object;
                    };
    
                    /**
                     * Converts this MergingConfigPreset to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MergingConfigPreset
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MergingConfigPreset.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MergingConfigPreset;
                })();
    
                v1.MergingConfig = (function() {
    
                    /**
                     * Properties of a MergingConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IMergingConfig
                     * @property {number|null} [singleCameraMaxFeatureDistanceThreshold] MergingConfig singleCameraMaxFeatureDistanceThreshold
                     * @property {number|null} [maxTimeDistanceInSeconds] MergingConfig maxTimeDistanceInSeconds
                     * @property {number|null} [maxCameraDistanceInPixels] MergingConfig maxCameraDistanceInPixels
                     * @property {boolean|null} [keepStaffTracklets] MergingConfig keepStaffTracklets
                     * @property {number|null} [minimumTrackletSize] MergingConfig minimumTrackletSize
                     * @property {palexy.streaming.v1.IOutlierFilteringConfig|null} [outlierFilterConfig] MergingConfig outlierFilterConfig
                     * @property {number|null} [globalViewMaxTimeDistanceInSeconds] MergingConfig globalViewMaxTimeDistanceInSeconds
                     * @property {number|null} [crossCameraMaxFeatureDistanceThreshold] MergingConfig crossCameraMaxFeatureDistanceThreshold
                     * @property {number|null} [globalViewMaxFeatureDistanceThreshold] MergingConfig globalViewMaxFeatureDistanceThreshold
                     * @property {number|null} [maxSameCameraOverlapRatio] MergingConfig maxSameCameraOverlapRatio
                     * @property {number|null} [maxCrossCameraOverlapRatio] MergingConfig maxCrossCameraOverlapRatio
                     * @property {number|null} [genderCheckingFeatureDistanceThreshold] MergingConfig genderCheckingFeatureDistanceThreshold
                     * @property {number|null} [featureDistancePercentileWhenCompareWithGroup] MergingConfig featureDistancePercentileWhenCompareWithGroup
                     * @property {boolean|null} [skipRefreshGroupCandidateEveryMerge] MergingConfig skipRefreshGroupCandidateEveryMerge
                     * @property {number|null} [nearnessCheckMaxCameraDistanceInPixels] MergingConfig nearnessCheckMaxCameraDistanceInPixels
                     * @property {boolean|null} [skipMergingByNearness] MergingConfig skipMergingByNearness
                     * @property {palexy.streaming.v1.IEntranceMergingConfig|null} [entranceMergingConfigInSupermarket] MergingConfig entranceMergingConfigInSupermarket
                     * @property {palexy.streaming.v1.IQueueTimeMergingConfig|null} [queueTimeMergingConfigInSupermarket] MergingConfig queueTimeMergingConfigInSupermarket
                     * @property {palexy.streaming.v1.ISectionMergingConfig|null} [sectionMergingConfigInSupermarket] MergingConfig sectionMergingConfigInSupermarket
                     * @property {boolean|null} [skipDetectingOutliers] MergingConfig skipDetectingOutliers
                     * @property {boolean|null} [mergeVisitorsAtWaitingArea] MergingConfig mergeVisitorsAtWaitingArea
                     * @property {boolean|null} [skipRemergeVeryCloseClustersByFeatureDistance] MergingConfig skipRemergeVeryCloseClustersByFeatureDistance
                     * @property {number|null} [maxTimeDistanceForRemerge] MergingConfig maxTimeDistanceForRemerge
                     * @property {number|null} [veryCloseFeatureDistanceThreshold] MergingConfig veryCloseFeatureDistanceThreshold
                     * @property {palexy.streaming.v1.ICrossCameraThresAdjustByDensityConfig|null} [crossCameraAdjustByDensityConfig] MergingConfig crossCameraAdjustByDensityConfig
                     * @property {palexy.streaming.v1.IVeryCloseThresAdjustByDensityConfig|null} [veryCloseThresAdjustByDensityConfig] MergingConfig veryCloseThresAdjustByDensityConfig
                     * @property {boolean|null} [disableDensityTrafficEstimator] MergingConfig disableDensityTrafficEstimator
                     * @property {palexy.streaming.v1.IWideSpaceEntranceConfig|null} [wideSpaceEntranceConfig] MergingConfig wideSpaceEntranceConfig
                     * @property {number|null} [veryVeryCloseFeatureDistanceForStaff] MergingConfig veryVeryCloseFeatureDistanceForStaff
                     * @property {number|null} [maxTimeDistanceForVeryVeryCloseForStaff] MergingConfig maxTimeDistanceForVeryVeryCloseForStaff
                     * @property {number|null} [maxPeopleToStillUseNearness] MergingConfig maxPeopleToStillUseNearness
                     * @property {palexy.streaming.v1.ISingleCameraThresAdjustByDensityConfig|null} [singleCameraAdjustByDensityConfig] MergingConfig singleCameraAdjustByDensityConfig
                     * @property {palexy.streaming.v1.IGlobalViewThresAdjustByDensityConfig|null} [globalViewThresAdjustByDensityConfig] MergingConfig globalViewThresAdjustByDensityConfig
                     * @property {palexy.streaming.v1.ICrossCameraDetectorConfig|null} [crossCameraDetectorConfig] MergingConfig crossCameraDetectorConfig
                     * @property {number|null} [maxInternalFeatureDistanceThreshold] MergingConfig maxInternalFeatureDistanceThreshold
                     * @property {number|null} [maxFeatureThresholdAtRefinementAfterMxnMatching] MergingConfig maxFeatureThresholdAtRefinementAfterMxnMatching
                     * @property {palexy.streaming.v1.IRerunSingleCameraTrackingConfig|null} [rerunSingleCameraTrackingConfig] MergingConfig rerunSingleCameraTrackingConfig
                     * @property {boolean|null} [skipMainMerging] MergingConfig skipMainMerging
                     * @property {palexy.streaming.v1.IRunMallMergingConfig|null} [runMallMergingConfig] MergingConfig runMallMergingConfig
                     * @property {boolean|null} [skipRunRefine] MergingConfig skipRunRefine
                     * @property {palexy.streaming.v1.IMinimalCameraCountingConfig|null} [minimalCameraCountingConfig] MergingConfig minimalCameraCountingConfig
                     * @property {string|null} [name] MergingConfig name
                     * @property {string|null} [version] MergingConfig version
                     */
    
                    /**
                     * Constructs a new MergingConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MergingConfig.
                     * @implements IMergingConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IMergingConfig=} [properties] Properties to set
                     */
                    function MergingConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MergingConfig singleCameraMaxFeatureDistanceThreshold.
                     * @member {number} singleCameraMaxFeatureDistanceThreshold
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.singleCameraMaxFeatureDistanceThreshold = 0;
    
                    /**
                     * MergingConfig maxTimeDistanceInSeconds.
                     * @member {number} maxTimeDistanceInSeconds
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.maxTimeDistanceInSeconds = 0;
    
                    /**
                     * MergingConfig maxCameraDistanceInPixels.
                     * @member {number} maxCameraDistanceInPixels
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.maxCameraDistanceInPixels = 0;
    
                    /**
                     * MergingConfig keepStaffTracklets.
                     * @member {boolean} keepStaffTracklets
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.keepStaffTracklets = false;
    
                    /**
                     * MergingConfig minimumTrackletSize.
                     * @member {number} minimumTrackletSize
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.minimumTrackletSize = 0;
    
                    /**
                     * MergingConfig outlierFilterConfig.
                     * @member {palexy.streaming.v1.IOutlierFilteringConfig|null|undefined} outlierFilterConfig
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.outlierFilterConfig = null;
    
                    /**
                     * MergingConfig globalViewMaxTimeDistanceInSeconds.
                     * @member {number} globalViewMaxTimeDistanceInSeconds
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.globalViewMaxTimeDistanceInSeconds = 0;
    
                    /**
                     * MergingConfig crossCameraMaxFeatureDistanceThreshold.
                     * @member {number} crossCameraMaxFeatureDistanceThreshold
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.crossCameraMaxFeatureDistanceThreshold = 0;
    
                    /**
                     * MergingConfig globalViewMaxFeatureDistanceThreshold.
                     * @member {number} globalViewMaxFeatureDistanceThreshold
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.globalViewMaxFeatureDistanceThreshold = 0;
    
                    /**
                     * MergingConfig maxSameCameraOverlapRatio.
                     * @member {number} maxSameCameraOverlapRatio
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.maxSameCameraOverlapRatio = 0;
    
                    /**
                     * MergingConfig maxCrossCameraOverlapRatio.
                     * @member {number} maxCrossCameraOverlapRatio
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.maxCrossCameraOverlapRatio = 0;
    
                    /**
                     * MergingConfig genderCheckingFeatureDistanceThreshold.
                     * @member {number} genderCheckingFeatureDistanceThreshold
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.genderCheckingFeatureDistanceThreshold = 0;
    
                    /**
                     * MergingConfig featureDistancePercentileWhenCompareWithGroup.
                     * @member {number} featureDistancePercentileWhenCompareWithGroup
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.featureDistancePercentileWhenCompareWithGroup = 0;
    
                    /**
                     * MergingConfig skipRefreshGroupCandidateEveryMerge.
                     * @member {boolean} skipRefreshGroupCandidateEveryMerge
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.skipRefreshGroupCandidateEveryMerge = false;
    
                    /**
                     * MergingConfig nearnessCheckMaxCameraDistanceInPixels.
                     * @member {number} nearnessCheckMaxCameraDistanceInPixels
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.nearnessCheckMaxCameraDistanceInPixels = 0;
    
                    /**
                     * MergingConfig skipMergingByNearness.
                     * @member {boolean} skipMergingByNearness
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.skipMergingByNearness = false;
    
                    /**
                     * MergingConfig entranceMergingConfigInSupermarket.
                     * @member {palexy.streaming.v1.IEntranceMergingConfig|null|undefined} entranceMergingConfigInSupermarket
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.entranceMergingConfigInSupermarket = null;
    
                    /**
                     * MergingConfig queueTimeMergingConfigInSupermarket.
                     * @member {palexy.streaming.v1.IQueueTimeMergingConfig|null|undefined} queueTimeMergingConfigInSupermarket
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.queueTimeMergingConfigInSupermarket = null;
    
                    /**
                     * MergingConfig sectionMergingConfigInSupermarket.
                     * @member {palexy.streaming.v1.ISectionMergingConfig|null|undefined} sectionMergingConfigInSupermarket
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.sectionMergingConfigInSupermarket = null;
    
                    /**
                     * MergingConfig skipDetectingOutliers.
                     * @member {boolean} skipDetectingOutliers
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.skipDetectingOutliers = false;
    
                    /**
                     * MergingConfig mergeVisitorsAtWaitingArea.
                     * @member {boolean} mergeVisitorsAtWaitingArea
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.mergeVisitorsAtWaitingArea = false;
    
                    /**
                     * MergingConfig skipRemergeVeryCloseClustersByFeatureDistance.
                     * @member {boolean} skipRemergeVeryCloseClustersByFeatureDistance
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.skipRemergeVeryCloseClustersByFeatureDistance = false;
    
                    /**
                     * MergingConfig maxTimeDistanceForRemerge.
                     * @member {number} maxTimeDistanceForRemerge
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.maxTimeDistanceForRemerge = 0;
    
                    /**
                     * MergingConfig veryCloseFeatureDistanceThreshold.
                     * @member {number} veryCloseFeatureDistanceThreshold
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.veryCloseFeatureDistanceThreshold = 0;
    
                    /**
                     * MergingConfig crossCameraAdjustByDensityConfig.
                     * @member {palexy.streaming.v1.ICrossCameraThresAdjustByDensityConfig|null|undefined} crossCameraAdjustByDensityConfig
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.crossCameraAdjustByDensityConfig = null;
    
                    /**
                     * MergingConfig veryCloseThresAdjustByDensityConfig.
                     * @member {palexy.streaming.v1.IVeryCloseThresAdjustByDensityConfig|null|undefined} veryCloseThresAdjustByDensityConfig
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.veryCloseThresAdjustByDensityConfig = null;
    
                    /**
                     * MergingConfig disableDensityTrafficEstimator.
                     * @member {boolean} disableDensityTrafficEstimator
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.disableDensityTrafficEstimator = false;
    
                    /**
                     * MergingConfig wideSpaceEntranceConfig.
                     * @member {palexy.streaming.v1.IWideSpaceEntranceConfig|null|undefined} wideSpaceEntranceConfig
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.wideSpaceEntranceConfig = null;
    
                    /**
                     * MergingConfig veryVeryCloseFeatureDistanceForStaff.
                     * @member {number} veryVeryCloseFeatureDistanceForStaff
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.veryVeryCloseFeatureDistanceForStaff = 0;
    
                    /**
                     * MergingConfig maxTimeDistanceForVeryVeryCloseForStaff.
                     * @member {number} maxTimeDistanceForVeryVeryCloseForStaff
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.maxTimeDistanceForVeryVeryCloseForStaff = 0;
    
                    /**
                     * MergingConfig maxPeopleToStillUseNearness.
                     * @member {number} maxPeopleToStillUseNearness
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.maxPeopleToStillUseNearness = 0;
    
                    /**
                     * MergingConfig singleCameraAdjustByDensityConfig.
                     * @member {palexy.streaming.v1.ISingleCameraThresAdjustByDensityConfig|null|undefined} singleCameraAdjustByDensityConfig
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.singleCameraAdjustByDensityConfig = null;
    
                    /**
                     * MergingConfig globalViewThresAdjustByDensityConfig.
                     * @member {palexy.streaming.v1.IGlobalViewThresAdjustByDensityConfig|null|undefined} globalViewThresAdjustByDensityConfig
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.globalViewThresAdjustByDensityConfig = null;
    
                    /**
                     * MergingConfig crossCameraDetectorConfig.
                     * @member {palexy.streaming.v1.ICrossCameraDetectorConfig|null|undefined} crossCameraDetectorConfig
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.crossCameraDetectorConfig = null;
    
                    /**
                     * MergingConfig maxInternalFeatureDistanceThreshold.
                     * @member {number} maxInternalFeatureDistanceThreshold
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.maxInternalFeatureDistanceThreshold = 0;
    
                    /**
                     * MergingConfig maxFeatureThresholdAtRefinementAfterMxnMatching.
                     * @member {number} maxFeatureThresholdAtRefinementAfterMxnMatching
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.maxFeatureThresholdAtRefinementAfterMxnMatching = 0;
    
                    /**
                     * MergingConfig rerunSingleCameraTrackingConfig.
                     * @member {palexy.streaming.v1.IRerunSingleCameraTrackingConfig|null|undefined} rerunSingleCameraTrackingConfig
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.rerunSingleCameraTrackingConfig = null;
    
                    /**
                     * MergingConfig skipMainMerging.
                     * @member {boolean} skipMainMerging
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.skipMainMerging = false;
    
                    /**
                     * MergingConfig runMallMergingConfig.
                     * @member {palexy.streaming.v1.IRunMallMergingConfig|null|undefined} runMallMergingConfig
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.runMallMergingConfig = null;
    
                    /**
                     * MergingConfig skipRunRefine.
                     * @member {boolean} skipRunRefine
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.skipRunRefine = false;
    
                    /**
                     * MergingConfig minimalCameraCountingConfig.
                     * @member {palexy.streaming.v1.IMinimalCameraCountingConfig|null|undefined} minimalCameraCountingConfig
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.minimalCameraCountingConfig = null;
    
                    /**
                     * MergingConfig name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.name = "";
    
                    /**
                     * MergingConfig version.
                     * @member {string} version
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     */
                    MergingConfig.prototype.version = "";
    
                    /**
                     * Creates a new MergingConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IMergingConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MergingConfig} MergingConfig instance
                     */
                    MergingConfig.create = function create(properties) {
                        return new MergingConfig(properties);
                    };
    
                    /**
                     * Encodes the specified MergingConfig message. Does not implicitly {@link palexy.streaming.v1.MergingConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IMergingConfig} message MergingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergingConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
                        if (message.singleCameraMaxFeatureDistanceThreshold != null && Object.hasOwnProperty.call(message, "singleCameraMaxFeatureDistanceThreshold"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.singleCameraMaxFeatureDistanceThreshold);
                        if (message.maxTimeDistanceInSeconds != null && Object.hasOwnProperty.call(message, "maxTimeDistanceInSeconds"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.maxTimeDistanceInSeconds);
                        if (message.maxCameraDistanceInPixels != null && Object.hasOwnProperty.call(message, "maxCameraDistanceInPixels"))
                            writer.uint32(/* id 5, wireType 5 =*/45).float(message.maxCameraDistanceInPixels);
                        if (message.keepStaffTracklets != null && Object.hasOwnProperty.call(message, "keepStaffTracklets"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.keepStaffTracklets);
                        if (message.minimumTrackletSize != null && Object.hasOwnProperty.call(message, "minimumTrackletSize"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.minimumTrackletSize);
                        if (message.outlierFilterConfig != null && Object.hasOwnProperty.call(message, "outlierFilterConfig"))
                            $root.palexy.streaming.v1.OutlierFilteringConfig.encode(message.outlierFilterConfig, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.globalViewMaxTimeDistanceInSeconds != null && Object.hasOwnProperty.call(message, "globalViewMaxTimeDistanceInSeconds"))
                            writer.uint32(/* id 9, wireType 5 =*/77).float(message.globalViewMaxTimeDistanceInSeconds);
                        if (message.crossCameraMaxFeatureDistanceThreshold != null && Object.hasOwnProperty.call(message, "crossCameraMaxFeatureDistanceThreshold"))
                            writer.uint32(/* id 10, wireType 5 =*/85).float(message.crossCameraMaxFeatureDistanceThreshold);
                        if (message.globalViewMaxFeatureDistanceThreshold != null && Object.hasOwnProperty.call(message, "globalViewMaxFeatureDistanceThreshold"))
                            writer.uint32(/* id 11, wireType 5 =*/93).float(message.globalViewMaxFeatureDistanceThreshold);
                        if (message.maxSameCameraOverlapRatio != null && Object.hasOwnProperty.call(message, "maxSameCameraOverlapRatio"))
                            writer.uint32(/* id 12, wireType 5 =*/101).float(message.maxSameCameraOverlapRatio);
                        if (message.maxCrossCameraOverlapRatio != null && Object.hasOwnProperty.call(message, "maxCrossCameraOverlapRatio"))
                            writer.uint32(/* id 13, wireType 5 =*/109).float(message.maxCrossCameraOverlapRatio);
                        if (message.genderCheckingFeatureDistanceThreshold != null && Object.hasOwnProperty.call(message, "genderCheckingFeatureDistanceThreshold"))
                            writer.uint32(/* id 14, wireType 5 =*/117).float(message.genderCheckingFeatureDistanceThreshold);
                        if (message.featureDistancePercentileWhenCompareWithGroup != null && Object.hasOwnProperty.call(message, "featureDistancePercentileWhenCompareWithGroup"))
                            writer.uint32(/* id 15, wireType 0 =*/120).int32(message.featureDistancePercentileWhenCompareWithGroup);
                        if (message.skipRefreshGroupCandidateEveryMerge != null && Object.hasOwnProperty.call(message, "skipRefreshGroupCandidateEveryMerge"))
                            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.skipRefreshGroupCandidateEveryMerge);
                        if (message.nearnessCheckMaxCameraDistanceInPixels != null && Object.hasOwnProperty.call(message, "nearnessCheckMaxCameraDistanceInPixels"))
                            writer.uint32(/* id 17, wireType 0 =*/136).int32(message.nearnessCheckMaxCameraDistanceInPixels);
                        if (message.skipMergingByNearness != null && Object.hasOwnProperty.call(message, "skipMergingByNearness"))
                            writer.uint32(/* id 18, wireType 0 =*/144).bool(message.skipMergingByNearness);
                        if (message.entranceMergingConfigInSupermarket != null && Object.hasOwnProperty.call(message, "entranceMergingConfigInSupermarket"))
                            $root.palexy.streaming.v1.EntranceMergingConfig.encode(message.entranceMergingConfigInSupermarket, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                        if (message.queueTimeMergingConfigInSupermarket != null && Object.hasOwnProperty.call(message, "queueTimeMergingConfigInSupermarket"))
                            $root.palexy.streaming.v1.QueueTimeMergingConfig.encode(message.queueTimeMergingConfigInSupermarket, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                        if (message.sectionMergingConfigInSupermarket != null && Object.hasOwnProperty.call(message, "sectionMergingConfigInSupermarket"))
                            $root.palexy.streaming.v1.SectionMergingConfig.encode(message.sectionMergingConfigInSupermarket, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                        if (message.skipDetectingOutliers != null && Object.hasOwnProperty.call(message, "skipDetectingOutliers"))
                            writer.uint32(/* id 22, wireType 0 =*/176).bool(message.skipDetectingOutliers);
                        if (message.mergeVisitorsAtWaitingArea != null && Object.hasOwnProperty.call(message, "mergeVisitorsAtWaitingArea"))
                            writer.uint32(/* id 23, wireType 0 =*/184).bool(message.mergeVisitorsAtWaitingArea);
                        if (message.skipRemergeVeryCloseClustersByFeatureDistance != null && Object.hasOwnProperty.call(message, "skipRemergeVeryCloseClustersByFeatureDistance"))
                            writer.uint32(/* id 24, wireType 0 =*/192).bool(message.skipRemergeVeryCloseClustersByFeatureDistance);
                        if (message.maxTimeDistanceForRemerge != null && Object.hasOwnProperty.call(message, "maxTimeDistanceForRemerge"))
                            writer.uint32(/* id 25, wireType 0 =*/200).int32(message.maxTimeDistanceForRemerge);
                        if (message.veryCloseFeatureDistanceThreshold != null && Object.hasOwnProperty.call(message, "veryCloseFeatureDistanceThreshold"))
                            writer.uint32(/* id 26, wireType 5 =*/213).float(message.veryCloseFeatureDistanceThreshold);
                        if (message.crossCameraAdjustByDensityConfig != null && Object.hasOwnProperty.call(message, "crossCameraAdjustByDensityConfig"))
                            $root.palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig.encode(message.crossCameraAdjustByDensityConfig, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                        if (message.veryCloseThresAdjustByDensityConfig != null && Object.hasOwnProperty.call(message, "veryCloseThresAdjustByDensityConfig"))
                            $root.palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig.encode(message.veryCloseThresAdjustByDensityConfig, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                        if (message.disableDensityTrafficEstimator != null && Object.hasOwnProperty.call(message, "disableDensityTrafficEstimator"))
                            writer.uint32(/* id 29, wireType 0 =*/232).bool(message.disableDensityTrafficEstimator);
                        if (message.wideSpaceEntranceConfig != null && Object.hasOwnProperty.call(message, "wideSpaceEntranceConfig"))
                            $root.palexy.streaming.v1.WideSpaceEntranceConfig.encode(message.wideSpaceEntranceConfig, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                        if (message.veryVeryCloseFeatureDistanceForStaff != null && Object.hasOwnProperty.call(message, "veryVeryCloseFeatureDistanceForStaff"))
                            writer.uint32(/* id 31, wireType 5 =*/253).float(message.veryVeryCloseFeatureDistanceForStaff);
                        if (message.maxTimeDistanceForVeryVeryCloseForStaff != null && Object.hasOwnProperty.call(message, "maxTimeDistanceForVeryVeryCloseForStaff"))
                            writer.uint32(/* id 32, wireType 0 =*/256).int32(message.maxTimeDistanceForVeryVeryCloseForStaff);
                        if (message.maxPeopleToStillUseNearness != null && Object.hasOwnProperty.call(message, "maxPeopleToStillUseNearness"))
                            writer.uint32(/* id 33, wireType 0 =*/264).int32(message.maxPeopleToStillUseNearness);
                        if (message.singleCameraAdjustByDensityConfig != null && Object.hasOwnProperty.call(message, "singleCameraAdjustByDensityConfig"))
                            $root.palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig.encode(message.singleCameraAdjustByDensityConfig, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                        if (message.globalViewThresAdjustByDensityConfig != null && Object.hasOwnProperty.call(message, "globalViewThresAdjustByDensityConfig"))
                            $root.palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig.encode(message.globalViewThresAdjustByDensityConfig, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                        if (message.crossCameraDetectorConfig != null && Object.hasOwnProperty.call(message, "crossCameraDetectorConfig"))
                            $root.palexy.streaming.v1.CrossCameraDetectorConfig.encode(message.crossCameraDetectorConfig, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
                        if (message.maxInternalFeatureDistanceThreshold != null && Object.hasOwnProperty.call(message, "maxInternalFeatureDistanceThreshold"))
                            writer.uint32(/* id 37, wireType 5 =*/301).float(message.maxInternalFeatureDistanceThreshold);
                        if (message.maxFeatureThresholdAtRefinementAfterMxnMatching != null && Object.hasOwnProperty.call(message, "maxFeatureThresholdAtRefinementAfterMxnMatching"))
                            writer.uint32(/* id 38, wireType 5 =*/309).float(message.maxFeatureThresholdAtRefinementAfterMxnMatching);
                        if (message.rerunSingleCameraTrackingConfig != null && Object.hasOwnProperty.call(message, "rerunSingleCameraTrackingConfig"))
                            $root.palexy.streaming.v1.RerunSingleCameraTrackingConfig.encode(message.rerunSingleCameraTrackingConfig, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                        if (message.skipMainMerging != null && Object.hasOwnProperty.call(message, "skipMainMerging"))
                            writer.uint32(/* id 40, wireType 0 =*/320).bool(message.skipMainMerging);
                        if (message.runMallMergingConfig != null && Object.hasOwnProperty.call(message, "runMallMergingConfig"))
                            $root.palexy.streaming.v1.RunMallMergingConfig.encode(message.runMallMergingConfig, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
                        if (message.skipRunRefine != null && Object.hasOwnProperty.call(message, "skipRunRefine"))
                            writer.uint32(/* id 42, wireType 0 =*/336).bool(message.skipRunRefine);
                        if (message.minimalCameraCountingConfig != null && Object.hasOwnProperty.call(message, "minimalCameraCountingConfig"))
                            $root.palexy.streaming.v1.MinimalCameraCountingConfig.encode(message.minimalCameraCountingConfig, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MergingConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.MergingConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IMergingConfig} message MergingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergingConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MergingConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MergingConfig} MergingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergingConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MergingConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 3:
                                message.singleCameraMaxFeatureDistanceThreshold = reader.float();
                                break;
                            case 4:
                                message.maxTimeDistanceInSeconds = reader.float();
                                break;
                            case 5:
                                message.maxCameraDistanceInPixels = reader.float();
                                break;
                            case 6:
                                message.keepStaffTracklets = reader.bool();
                                break;
                            case 7:
                                message.minimumTrackletSize = reader.int32();
                                break;
                            case 8:
                                message.outlierFilterConfig = $root.palexy.streaming.v1.OutlierFilteringConfig.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.globalViewMaxTimeDistanceInSeconds = reader.float();
                                break;
                            case 10:
                                message.crossCameraMaxFeatureDistanceThreshold = reader.float();
                                break;
                            case 11:
                                message.globalViewMaxFeatureDistanceThreshold = reader.float();
                                break;
                            case 12:
                                message.maxSameCameraOverlapRatio = reader.float();
                                break;
                            case 13:
                                message.maxCrossCameraOverlapRatio = reader.float();
                                break;
                            case 14:
                                message.genderCheckingFeatureDistanceThreshold = reader.float();
                                break;
                            case 15:
                                message.featureDistancePercentileWhenCompareWithGroup = reader.int32();
                                break;
                            case 16:
                                message.skipRefreshGroupCandidateEveryMerge = reader.bool();
                                break;
                            case 17:
                                message.nearnessCheckMaxCameraDistanceInPixels = reader.int32();
                                break;
                            case 18:
                                message.skipMergingByNearness = reader.bool();
                                break;
                            case 19:
                                message.entranceMergingConfigInSupermarket = $root.palexy.streaming.v1.EntranceMergingConfig.decode(reader, reader.uint32());
                                break;
                            case 20:
                                message.queueTimeMergingConfigInSupermarket = $root.palexy.streaming.v1.QueueTimeMergingConfig.decode(reader, reader.uint32());
                                break;
                            case 21:
                                message.sectionMergingConfigInSupermarket = $root.palexy.streaming.v1.SectionMergingConfig.decode(reader, reader.uint32());
                                break;
                            case 22:
                                message.skipDetectingOutliers = reader.bool();
                                break;
                            case 23:
                                message.mergeVisitorsAtWaitingArea = reader.bool();
                                break;
                            case 24:
                                message.skipRemergeVeryCloseClustersByFeatureDistance = reader.bool();
                                break;
                            case 25:
                                message.maxTimeDistanceForRemerge = reader.int32();
                                break;
                            case 26:
                                message.veryCloseFeatureDistanceThreshold = reader.float();
                                break;
                            case 27:
                                message.crossCameraAdjustByDensityConfig = $root.palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig.decode(reader, reader.uint32());
                                break;
                            case 28:
                                message.veryCloseThresAdjustByDensityConfig = $root.palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig.decode(reader, reader.uint32());
                                break;
                            case 29:
                                message.disableDensityTrafficEstimator = reader.bool();
                                break;
                            case 30:
                                message.wideSpaceEntranceConfig = $root.palexy.streaming.v1.WideSpaceEntranceConfig.decode(reader, reader.uint32());
                                break;
                            case 31:
                                message.veryVeryCloseFeatureDistanceForStaff = reader.float();
                                break;
                            case 32:
                                message.maxTimeDistanceForVeryVeryCloseForStaff = reader.int32();
                                break;
                            case 33:
                                message.maxPeopleToStillUseNearness = reader.int32();
                                break;
                            case 34:
                                message.singleCameraAdjustByDensityConfig = $root.palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig.decode(reader, reader.uint32());
                                break;
                            case 35:
                                message.globalViewThresAdjustByDensityConfig = $root.palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig.decode(reader, reader.uint32());
                                break;
                            case 36:
                                message.crossCameraDetectorConfig = $root.palexy.streaming.v1.CrossCameraDetectorConfig.decode(reader, reader.uint32());
                                break;
                            case 37:
                                message.maxInternalFeatureDistanceThreshold = reader.float();
                                break;
                            case 38:
                                message.maxFeatureThresholdAtRefinementAfterMxnMatching = reader.float();
                                break;
                            case 39:
                                message.rerunSingleCameraTrackingConfig = $root.palexy.streaming.v1.RerunSingleCameraTrackingConfig.decode(reader, reader.uint32());
                                break;
                            case 40:
                                message.skipMainMerging = reader.bool();
                                break;
                            case 41:
                                message.runMallMergingConfig = $root.palexy.streaming.v1.RunMallMergingConfig.decode(reader, reader.uint32());
                                break;
                            case 42:
                                message.skipRunRefine = reader.bool();
                                break;
                            case 43:
                                message.minimalCameraCountingConfig = $root.palexy.streaming.v1.MinimalCameraCountingConfig.decode(reader, reader.uint32());
                                break;
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.version = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MergingConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MergingConfig} MergingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergingConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MergingConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MergingConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.singleCameraMaxFeatureDistanceThreshold != null && message.hasOwnProperty("singleCameraMaxFeatureDistanceThreshold"))
                            if (typeof message.singleCameraMaxFeatureDistanceThreshold !== "number")
                                return "singleCameraMaxFeatureDistanceThreshold: number expected";
                        if (message.maxTimeDistanceInSeconds != null && message.hasOwnProperty("maxTimeDistanceInSeconds"))
                            if (typeof message.maxTimeDistanceInSeconds !== "number")
                                return "maxTimeDistanceInSeconds: number expected";
                        if (message.maxCameraDistanceInPixels != null && message.hasOwnProperty("maxCameraDistanceInPixels"))
                            if (typeof message.maxCameraDistanceInPixels !== "number")
                                return "maxCameraDistanceInPixels: number expected";
                        if (message.keepStaffTracklets != null && message.hasOwnProperty("keepStaffTracklets"))
                            if (typeof message.keepStaffTracklets !== "boolean")
                                return "keepStaffTracklets: boolean expected";
                        if (message.minimumTrackletSize != null && message.hasOwnProperty("minimumTrackletSize"))
                            if (!$util.isInteger(message.minimumTrackletSize))
                                return "minimumTrackletSize: integer expected";
                        if (message.outlierFilterConfig != null && message.hasOwnProperty("outlierFilterConfig")) {
                            var error = $root.palexy.streaming.v1.OutlierFilteringConfig.verify(message.outlierFilterConfig);
                            if (error)
                                return "outlierFilterConfig." + error;
                        }
                        if (message.globalViewMaxTimeDistanceInSeconds != null && message.hasOwnProperty("globalViewMaxTimeDistanceInSeconds"))
                            if (typeof message.globalViewMaxTimeDistanceInSeconds !== "number")
                                return "globalViewMaxTimeDistanceInSeconds: number expected";
                        if (message.crossCameraMaxFeatureDistanceThreshold != null && message.hasOwnProperty("crossCameraMaxFeatureDistanceThreshold"))
                            if (typeof message.crossCameraMaxFeatureDistanceThreshold !== "number")
                                return "crossCameraMaxFeatureDistanceThreshold: number expected";
                        if (message.globalViewMaxFeatureDistanceThreshold != null && message.hasOwnProperty("globalViewMaxFeatureDistanceThreshold"))
                            if (typeof message.globalViewMaxFeatureDistanceThreshold !== "number")
                                return "globalViewMaxFeatureDistanceThreshold: number expected";
                        if (message.maxSameCameraOverlapRatio != null && message.hasOwnProperty("maxSameCameraOverlapRatio"))
                            if (typeof message.maxSameCameraOverlapRatio !== "number")
                                return "maxSameCameraOverlapRatio: number expected";
                        if (message.maxCrossCameraOverlapRatio != null && message.hasOwnProperty("maxCrossCameraOverlapRatio"))
                            if (typeof message.maxCrossCameraOverlapRatio !== "number")
                                return "maxCrossCameraOverlapRatio: number expected";
                        if (message.genderCheckingFeatureDistanceThreshold != null && message.hasOwnProperty("genderCheckingFeatureDistanceThreshold"))
                            if (typeof message.genderCheckingFeatureDistanceThreshold !== "number")
                                return "genderCheckingFeatureDistanceThreshold: number expected";
                        if (message.featureDistancePercentileWhenCompareWithGroup != null && message.hasOwnProperty("featureDistancePercentileWhenCompareWithGroup"))
                            if (!$util.isInteger(message.featureDistancePercentileWhenCompareWithGroup))
                                return "featureDistancePercentileWhenCompareWithGroup: integer expected";
                        if (message.skipRefreshGroupCandidateEveryMerge != null && message.hasOwnProperty("skipRefreshGroupCandidateEveryMerge"))
                            if (typeof message.skipRefreshGroupCandidateEveryMerge !== "boolean")
                                return "skipRefreshGroupCandidateEveryMerge: boolean expected";
                        if (message.nearnessCheckMaxCameraDistanceInPixels != null && message.hasOwnProperty("nearnessCheckMaxCameraDistanceInPixels"))
                            if (!$util.isInteger(message.nearnessCheckMaxCameraDistanceInPixels))
                                return "nearnessCheckMaxCameraDistanceInPixels: integer expected";
                        if (message.skipMergingByNearness != null && message.hasOwnProperty("skipMergingByNearness"))
                            if (typeof message.skipMergingByNearness !== "boolean")
                                return "skipMergingByNearness: boolean expected";
                        if (message.entranceMergingConfigInSupermarket != null && message.hasOwnProperty("entranceMergingConfigInSupermarket")) {
                            var error = $root.palexy.streaming.v1.EntranceMergingConfig.verify(message.entranceMergingConfigInSupermarket);
                            if (error)
                                return "entranceMergingConfigInSupermarket." + error;
                        }
                        if (message.queueTimeMergingConfigInSupermarket != null && message.hasOwnProperty("queueTimeMergingConfigInSupermarket")) {
                            var error = $root.palexy.streaming.v1.QueueTimeMergingConfig.verify(message.queueTimeMergingConfigInSupermarket);
                            if (error)
                                return "queueTimeMergingConfigInSupermarket." + error;
                        }
                        if (message.sectionMergingConfigInSupermarket != null && message.hasOwnProperty("sectionMergingConfigInSupermarket")) {
                            var error = $root.palexy.streaming.v1.SectionMergingConfig.verify(message.sectionMergingConfigInSupermarket);
                            if (error)
                                return "sectionMergingConfigInSupermarket." + error;
                        }
                        if (message.skipDetectingOutliers != null && message.hasOwnProperty("skipDetectingOutliers"))
                            if (typeof message.skipDetectingOutliers !== "boolean")
                                return "skipDetectingOutliers: boolean expected";
                        if (message.mergeVisitorsAtWaitingArea != null && message.hasOwnProperty("mergeVisitorsAtWaitingArea"))
                            if (typeof message.mergeVisitorsAtWaitingArea !== "boolean")
                                return "mergeVisitorsAtWaitingArea: boolean expected";
                        if (message.skipRemergeVeryCloseClustersByFeatureDistance != null && message.hasOwnProperty("skipRemergeVeryCloseClustersByFeatureDistance"))
                            if (typeof message.skipRemergeVeryCloseClustersByFeatureDistance !== "boolean")
                                return "skipRemergeVeryCloseClustersByFeatureDistance: boolean expected";
                        if (message.maxTimeDistanceForRemerge != null && message.hasOwnProperty("maxTimeDistanceForRemerge"))
                            if (!$util.isInteger(message.maxTimeDistanceForRemerge))
                                return "maxTimeDistanceForRemerge: integer expected";
                        if (message.veryCloseFeatureDistanceThreshold != null && message.hasOwnProperty("veryCloseFeatureDistanceThreshold"))
                            if (typeof message.veryCloseFeatureDistanceThreshold !== "number")
                                return "veryCloseFeatureDistanceThreshold: number expected";
                        if (message.crossCameraAdjustByDensityConfig != null && message.hasOwnProperty("crossCameraAdjustByDensityConfig")) {
                            var error = $root.palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig.verify(message.crossCameraAdjustByDensityConfig);
                            if (error)
                                return "crossCameraAdjustByDensityConfig." + error;
                        }
                        if (message.veryCloseThresAdjustByDensityConfig != null && message.hasOwnProperty("veryCloseThresAdjustByDensityConfig")) {
                            var error = $root.palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig.verify(message.veryCloseThresAdjustByDensityConfig);
                            if (error)
                                return "veryCloseThresAdjustByDensityConfig." + error;
                        }
                        if (message.disableDensityTrafficEstimator != null && message.hasOwnProperty("disableDensityTrafficEstimator"))
                            if (typeof message.disableDensityTrafficEstimator !== "boolean")
                                return "disableDensityTrafficEstimator: boolean expected";
                        if (message.wideSpaceEntranceConfig != null && message.hasOwnProperty("wideSpaceEntranceConfig")) {
                            var error = $root.palexy.streaming.v1.WideSpaceEntranceConfig.verify(message.wideSpaceEntranceConfig);
                            if (error)
                                return "wideSpaceEntranceConfig." + error;
                        }
                        if (message.veryVeryCloseFeatureDistanceForStaff != null && message.hasOwnProperty("veryVeryCloseFeatureDistanceForStaff"))
                            if (typeof message.veryVeryCloseFeatureDistanceForStaff !== "number")
                                return "veryVeryCloseFeatureDistanceForStaff: number expected";
                        if (message.maxTimeDistanceForVeryVeryCloseForStaff != null && message.hasOwnProperty("maxTimeDistanceForVeryVeryCloseForStaff"))
                            if (!$util.isInteger(message.maxTimeDistanceForVeryVeryCloseForStaff))
                                return "maxTimeDistanceForVeryVeryCloseForStaff: integer expected";
                        if (message.maxPeopleToStillUseNearness != null && message.hasOwnProperty("maxPeopleToStillUseNearness"))
                            if (!$util.isInteger(message.maxPeopleToStillUseNearness))
                                return "maxPeopleToStillUseNearness: integer expected";
                        if (message.singleCameraAdjustByDensityConfig != null && message.hasOwnProperty("singleCameraAdjustByDensityConfig")) {
                            var error = $root.palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig.verify(message.singleCameraAdjustByDensityConfig);
                            if (error)
                                return "singleCameraAdjustByDensityConfig." + error;
                        }
                        if (message.globalViewThresAdjustByDensityConfig != null && message.hasOwnProperty("globalViewThresAdjustByDensityConfig")) {
                            var error = $root.palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig.verify(message.globalViewThresAdjustByDensityConfig);
                            if (error)
                                return "globalViewThresAdjustByDensityConfig." + error;
                        }
                        if (message.crossCameraDetectorConfig != null && message.hasOwnProperty("crossCameraDetectorConfig")) {
                            var error = $root.palexy.streaming.v1.CrossCameraDetectorConfig.verify(message.crossCameraDetectorConfig);
                            if (error)
                                return "crossCameraDetectorConfig." + error;
                        }
                        if (message.maxInternalFeatureDistanceThreshold != null && message.hasOwnProperty("maxInternalFeatureDistanceThreshold"))
                            if (typeof message.maxInternalFeatureDistanceThreshold !== "number")
                                return "maxInternalFeatureDistanceThreshold: number expected";
                        if (message.maxFeatureThresholdAtRefinementAfterMxnMatching != null && message.hasOwnProperty("maxFeatureThresholdAtRefinementAfterMxnMatching"))
                            if (typeof message.maxFeatureThresholdAtRefinementAfterMxnMatching !== "number")
                                return "maxFeatureThresholdAtRefinementAfterMxnMatching: number expected";
                        if (message.rerunSingleCameraTrackingConfig != null && message.hasOwnProperty("rerunSingleCameraTrackingConfig")) {
                            var error = $root.palexy.streaming.v1.RerunSingleCameraTrackingConfig.verify(message.rerunSingleCameraTrackingConfig);
                            if (error)
                                return "rerunSingleCameraTrackingConfig." + error;
                        }
                        if (message.skipMainMerging != null && message.hasOwnProperty("skipMainMerging"))
                            if (typeof message.skipMainMerging !== "boolean")
                                return "skipMainMerging: boolean expected";
                        if (message.runMallMergingConfig != null && message.hasOwnProperty("runMallMergingConfig")) {
                            var error = $root.palexy.streaming.v1.RunMallMergingConfig.verify(message.runMallMergingConfig);
                            if (error)
                                return "runMallMergingConfig." + error;
                        }
                        if (message.skipRunRefine != null && message.hasOwnProperty("skipRunRefine"))
                            if (typeof message.skipRunRefine !== "boolean")
                                return "skipRunRefine: boolean expected";
                        if (message.minimalCameraCountingConfig != null && message.hasOwnProperty("minimalCameraCountingConfig")) {
                            var error = $root.palexy.streaming.v1.MinimalCameraCountingConfig.verify(message.minimalCameraCountingConfig);
                            if (error)
                                return "minimalCameraCountingConfig." + error;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.version != null && message.hasOwnProperty("version"))
                            if (!$util.isString(message.version))
                                return "version: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a MergingConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MergingConfig} MergingConfig
                     */
                    MergingConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MergingConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.MergingConfig();
                        if (object.singleCameraMaxFeatureDistanceThreshold != null)
                            message.singleCameraMaxFeatureDistanceThreshold = Number(object.singleCameraMaxFeatureDistanceThreshold);
                        if (object.maxTimeDistanceInSeconds != null)
                            message.maxTimeDistanceInSeconds = Number(object.maxTimeDistanceInSeconds);
                        if (object.maxCameraDistanceInPixels != null)
                            message.maxCameraDistanceInPixels = Number(object.maxCameraDistanceInPixels);
                        if (object.keepStaffTracklets != null)
                            message.keepStaffTracklets = Boolean(object.keepStaffTracklets);
                        if (object.minimumTrackletSize != null)
                            message.minimumTrackletSize = object.minimumTrackletSize | 0;
                        if (object.outlierFilterConfig != null) {
                            if (typeof object.outlierFilterConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.outlierFilterConfig: object expected");
                            message.outlierFilterConfig = $root.palexy.streaming.v1.OutlierFilteringConfig.fromObject(object.outlierFilterConfig);
                        }
                        if (object.globalViewMaxTimeDistanceInSeconds != null)
                            message.globalViewMaxTimeDistanceInSeconds = Number(object.globalViewMaxTimeDistanceInSeconds);
                        if (object.crossCameraMaxFeatureDistanceThreshold != null)
                            message.crossCameraMaxFeatureDistanceThreshold = Number(object.crossCameraMaxFeatureDistanceThreshold);
                        if (object.globalViewMaxFeatureDistanceThreshold != null)
                            message.globalViewMaxFeatureDistanceThreshold = Number(object.globalViewMaxFeatureDistanceThreshold);
                        if (object.maxSameCameraOverlapRatio != null)
                            message.maxSameCameraOverlapRatio = Number(object.maxSameCameraOverlapRatio);
                        if (object.maxCrossCameraOverlapRatio != null)
                            message.maxCrossCameraOverlapRatio = Number(object.maxCrossCameraOverlapRatio);
                        if (object.genderCheckingFeatureDistanceThreshold != null)
                            message.genderCheckingFeatureDistanceThreshold = Number(object.genderCheckingFeatureDistanceThreshold);
                        if (object.featureDistancePercentileWhenCompareWithGroup != null)
                            message.featureDistancePercentileWhenCompareWithGroup = object.featureDistancePercentileWhenCompareWithGroup | 0;
                        if (object.skipRefreshGroupCandidateEveryMerge != null)
                            message.skipRefreshGroupCandidateEveryMerge = Boolean(object.skipRefreshGroupCandidateEveryMerge);
                        if (object.nearnessCheckMaxCameraDistanceInPixels != null)
                            message.nearnessCheckMaxCameraDistanceInPixels = object.nearnessCheckMaxCameraDistanceInPixels | 0;
                        if (object.skipMergingByNearness != null)
                            message.skipMergingByNearness = Boolean(object.skipMergingByNearness);
                        if (object.entranceMergingConfigInSupermarket != null) {
                            if (typeof object.entranceMergingConfigInSupermarket !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.entranceMergingConfigInSupermarket: object expected");
                            message.entranceMergingConfigInSupermarket = $root.palexy.streaming.v1.EntranceMergingConfig.fromObject(object.entranceMergingConfigInSupermarket);
                        }
                        if (object.queueTimeMergingConfigInSupermarket != null) {
                            if (typeof object.queueTimeMergingConfigInSupermarket !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.queueTimeMergingConfigInSupermarket: object expected");
                            message.queueTimeMergingConfigInSupermarket = $root.palexy.streaming.v1.QueueTimeMergingConfig.fromObject(object.queueTimeMergingConfigInSupermarket);
                        }
                        if (object.sectionMergingConfigInSupermarket != null) {
                            if (typeof object.sectionMergingConfigInSupermarket !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.sectionMergingConfigInSupermarket: object expected");
                            message.sectionMergingConfigInSupermarket = $root.palexy.streaming.v1.SectionMergingConfig.fromObject(object.sectionMergingConfigInSupermarket);
                        }
                        if (object.skipDetectingOutliers != null)
                            message.skipDetectingOutliers = Boolean(object.skipDetectingOutliers);
                        if (object.mergeVisitorsAtWaitingArea != null)
                            message.mergeVisitorsAtWaitingArea = Boolean(object.mergeVisitorsAtWaitingArea);
                        if (object.skipRemergeVeryCloseClustersByFeatureDistance != null)
                            message.skipRemergeVeryCloseClustersByFeatureDistance = Boolean(object.skipRemergeVeryCloseClustersByFeatureDistance);
                        if (object.maxTimeDistanceForRemerge != null)
                            message.maxTimeDistanceForRemerge = object.maxTimeDistanceForRemerge | 0;
                        if (object.veryCloseFeatureDistanceThreshold != null)
                            message.veryCloseFeatureDistanceThreshold = Number(object.veryCloseFeatureDistanceThreshold);
                        if (object.crossCameraAdjustByDensityConfig != null) {
                            if (typeof object.crossCameraAdjustByDensityConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.crossCameraAdjustByDensityConfig: object expected");
                            message.crossCameraAdjustByDensityConfig = $root.palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig.fromObject(object.crossCameraAdjustByDensityConfig);
                        }
                        if (object.veryCloseThresAdjustByDensityConfig != null) {
                            if (typeof object.veryCloseThresAdjustByDensityConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.veryCloseThresAdjustByDensityConfig: object expected");
                            message.veryCloseThresAdjustByDensityConfig = $root.palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig.fromObject(object.veryCloseThresAdjustByDensityConfig);
                        }
                        if (object.disableDensityTrafficEstimator != null)
                            message.disableDensityTrafficEstimator = Boolean(object.disableDensityTrafficEstimator);
                        if (object.wideSpaceEntranceConfig != null) {
                            if (typeof object.wideSpaceEntranceConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.wideSpaceEntranceConfig: object expected");
                            message.wideSpaceEntranceConfig = $root.palexy.streaming.v1.WideSpaceEntranceConfig.fromObject(object.wideSpaceEntranceConfig);
                        }
                        if (object.veryVeryCloseFeatureDistanceForStaff != null)
                            message.veryVeryCloseFeatureDistanceForStaff = Number(object.veryVeryCloseFeatureDistanceForStaff);
                        if (object.maxTimeDistanceForVeryVeryCloseForStaff != null)
                            message.maxTimeDistanceForVeryVeryCloseForStaff = object.maxTimeDistanceForVeryVeryCloseForStaff | 0;
                        if (object.maxPeopleToStillUseNearness != null)
                            message.maxPeopleToStillUseNearness = object.maxPeopleToStillUseNearness | 0;
                        if (object.singleCameraAdjustByDensityConfig != null) {
                            if (typeof object.singleCameraAdjustByDensityConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.singleCameraAdjustByDensityConfig: object expected");
                            message.singleCameraAdjustByDensityConfig = $root.palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig.fromObject(object.singleCameraAdjustByDensityConfig);
                        }
                        if (object.globalViewThresAdjustByDensityConfig != null) {
                            if (typeof object.globalViewThresAdjustByDensityConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.globalViewThresAdjustByDensityConfig: object expected");
                            message.globalViewThresAdjustByDensityConfig = $root.palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig.fromObject(object.globalViewThresAdjustByDensityConfig);
                        }
                        if (object.crossCameraDetectorConfig != null) {
                            if (typeof object.crossCameraDetectorConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.crossCameraDetectorConfig: object expected");
                            message.crossCameraDetectorConfig = $root.palexy.streaming.v1.CrossCameraDetectorConfig.fromObject(object.crossCameraDetectorConfig);
                        }
                        if (object.maxInternalFeatureDistanceThreshold != null)
                            message.maxInternalFeatureDistanceThreshold = Number(object.maxInternalFeatureDistanceThreshold);
                        if (object.maxFeatureThresholdAtRefinementAfterMxnMatching != null)
                            message.maxFeatureThresholdAtRefinementAfterMxnMatching = Number(object.maxFeatureThresholdAtRefinementAfterMxnMatching);
                        if (object.rerunSingleCameraTrackingConfig != null) {
                            if (typeof object.rerunSingleCameraTrackingConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.rerunSingleCameraTrackingConfig: object expected");
                            message.rerunSingleCameraTrackingConfig = $root.palexy.streaming.v1.RerunSingleCameraTrackingConfig.fromObject(object.rerunSingleCameraTrackingConfig);
                        }
                        if (object.skipMainMerging != null)
                            message.skipMainMerging = Boolean(object.skipMainMerging);
                        if (object.runMallMergingConfig != null) {
                            if (typeof object.runMallMergingConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.runMallMergingConfig: object expected");
                            message.runMallMergingConfig = $root.palexy.streaming.v1.RunMallMergingConfig.fromObject(object.runMallMergingConfig);
                        }
                        if (object.skipRunRefine != null)
                            message.skipRunRefine = Boolean(object.skipRunRefine);
                        if (object.minimalCameraCountingConfig != null) {
                            if (typeof object.minimalCameraCountingConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingConfig.minimalCameraCountingConfig: object expected");
                            message.minimalCameraCountingConfig = $root.palexy.streaming.v1.MinimalCameraCountingConfig.fromObject(object.minimalCameraCountingConfig);
                        }
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.version != null)
                            message.version = String(object.version);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MergingConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @static
                     * @param {palexy.streaming.v1.MergingConfig} message MergingConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MergingConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.version = "";
                            object.singleCameraMaxFeatureDistanceThreshold = 0;
                            object.maxTimeDistanceInSeconds = 0;
                            object.maxCameraDistanceInPixels = 0;
                            object.keepStaffTracklets = false;
                            object.minimumTrackletSize = 0;
                            object.outlierFilterConfig = null;
                            object.globalViewMaxTimeDistanceInSeconds = 0;
                            object.crossCameraMaxFeatureDistanceThreshold = 0;
                            object.globalViewMaxFeatureDistanceThreshold = 0;
                            object.maxSameCameraOverlapRatio = 0;
                            object.maxCrossCameraOverlapRatio = 0;
                            object.genderCheckingFeatureDistanceThreshold = 0;
                            object.featureDistancePercentileWhenCompareWithGroup = 0;
                            object.skipRefreshGroupCandidateEveryMerge = false;
                            object.nearnessCheckMaxCameraDistanceInPixels = 0;
                            object.skipMergingByNearness = false;
                            object.entranceMergingConfigInSupermarket = null;
                            object.queueTimeMergingConfigInSupermarket = null;
                            object.sectionMergingConfigInSupermarket = null;
                            object.skipDetectingOutliers = false;
                            object.mergeVisitorsAtWaitingArea = false;
                            object.skipRemergeVeryCloseClustersByFeatureDistance = false;
                            object.maxTimeDistanceForRemerge = 0;
                            object.veryCloseFeatureDistanceThreshold = 0;
                            object.crossCameraAdjustByDensityConfig = null;
                            object.veryCloseThresAdjustByDensityConfig = null;
                            object.disableDensityTrafficEstimator = false;
                            object.wideSpaceEntranceConfig = null;
                            object.veryVeryCloseFeatureDistanceForStaff = 0;
                            object.maxTimeDistanceForVeryVeryCloseForStaff = 0;
                            object.maxPeopleToStillUseNearness = 0;
                            object.singleCameraAdjustByDensityConfig = null;
                            object.globalViewThresAdjustByDensityConfig = null;
                            object.crossCameraDetectorConfig = null;
                            object.maxInternalFeatureDistanceThreshold = 0;
                            object.maxFeatureThresholdAtRefinementAfterMxnMatching = 0;
                            object.rerunSingleCameraTrackingConfig = null;
                            object.skipMainMerging = false;
                            object.runMallMergingConfig = null;
                            object.skipRunRefine = false;
                            object.minimalCameraCountingConfig = null;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.version != null && message.hasOwnProperty("version"))
                            object.version = message.version;
                        if (message.singleCameraMaxFeatureDistanceThreshold != null && message.hasOwnProperty("singleCameraMaxFeatureDistanceThreshold"))
                            object.singleCameraMaxFeatureDistanceThreshold = options.json && !isFinite(message.singleCameraMaxFeatureDistanceThreshold) ? String(message.singleCameraMaxFeatureDistanceThreshold) : message.singleCameraMaxFeatureDistanceThreshold;
                        if (message.maxTimeDistanceInSeconds != null && message.hasOwnProperty("maxTimeDistanceInSeconds"))
                            object.maxTimeDistanceInSeconds = options.json && !isFinite(message.maxTimeDistanceInSeconds) ? String(message.maxTimeDistanceInSeconds) : message.maxTimeDistanceInSeconds;
                        if (message.maxCameraDistanceInPixels != null && message.hasOwnProperty("maxCameraDistanceInPixels"))
                            object.maxCameraDistanceInPixels = options.json && !isFinite(message.maxCameraDistanceInPixels) ? String(message.maxCameraDistanceInPixels) : message.maxCameraDistanceInPixels;
                        if (message.keepStaffTracklets != null && message.hasOwnProperty("keepStaffTracklets"))
                            object.keepStaffTracklets = message.keepStaffTracklets;
                        if (message.minimumTrackletSize != null && message.hasOwnProperty("minimumTrackletSize"))
                            object.minimumTrackletSize = message.minimumTrackletSize;
                        if (message.outlierFilterConfig != null && message.hasOwnProperty("outlierFilterConfig"))
                            object.outlierFilterConfig = $root.palexy.streaming.v1.OutlierFilteringConfig.toObject(message.outlierFilterConfig, options);
                        if (message.globalViewMaxTimeDistanceInSeconds != null && message.hasOwnProperty("globalViewMaxTimeDistanceInSeconds"))
                            object.globalViewMaxTimeDistanceInSeconds = options.json && !isFinite(message.globalViewMaxTimeDistanceInSeconds) ? String(message.globalViewMaxTimeDistanceInSeconds) : message.globalViewMaxTimeDistanceInSeconds;
                        if (message.crossCameraMaxFeatureDistanceThreshold != null && message.hasOwnProperty("crossCameraMaxFeatureDistanceThreshold"))
                            object.crossCameraMaxFeatureDistanceThreshold = options.json && !isFinite(message.crossCameraMaxFeatureDistanceThreshold) ? String(message.crossCameraMaxFeatureDistanceThreshold) : message.crossCameraMaxFeatureDistanceThreshold;
                        if (message.globalViewMaxFeatureDistanceThreshold != null && message.hasOwnProperty("globalViewMaxFeatureDistanceThreshold"))
                            object.globalViewMaxFeatureDistanceThreshold = options.json && !isFinite(message.globalViewMaxFeatureDistanceThreshold) ? String(message.globalViewMaxFeatureDistanceThreshold) : message.globalViewMaxFeatureDistanceThreshold;
                        if (message.maxSameCameraOverlapRatio != null && message.hasOwnProperty("maxSameCameraOverlapRatio"))
                            object.maxSameCameraOverlapRatio = options.json && !isFinite(message.maxSameCameraOverlapRatio) ? String(message.maxSameCameraOverlapRatio) : message.maxSameCameraOverlapRatio;
                        if (message.maxCrossCameraOverlapRatio != null && message.hasOwnProperty("maxCrossCameraOverlapRatio"))
                            object.maxCrossCameraOverlapRatio = options.json && !isFinite(message.maxCrossCameraOverlapRatio) ? String(message.maxCrossCameraOverlapRatio) : message.maxCrossCameraOverlapRatio;
                        if (message.genderCheckingFeatureDistanceThreshold != null && message.hasOwnProperty("genderCheckingFeatureDistanceThreshold"))
                            object.genderCheckingFeatureDistanceThreshold = options.json && !isFinite(message.genderCheckingFeatureDistanceThreshold) ? String(message.genderCheckingFeatureDistanceThreshold) : message.genderCheckingFeatureDistanceThreshold;
                        if (message.featureDistancePercentileWhenCompareWithGroup != null && message.hasOwnProperty("featureDistancePercentileWhenCompareWithGroup"))
                            object.featureDistancePercentileWhenCompareWithGroup = message.featureDistancePercentileWhenCompareWithGroup;
                        if (message.skipRefreshGroupCandidateEveryMerge != null && message.hasOwnProperty("skipRefreshGroupCandidateEveryMerge"))
                            object.skipRefreshGroupCandidateEveryMerge = message.skipRefreshGroupCandidateEveryMerge;
                        if (message.nearnessCheckMaxCameraDistanceInPixels != null && message.hasOwnProperty("nearnessCheckMaxCameraDistanceInPixels"))
                            object.nearnessCheckMaxCameraDistanceInPixels = message.nearnessCheckMaxCameraDistanceInPixels;
                        if (message.skipMergingByNearness != null && message.hasOwnProperty("skipMergingByNearness"))
                            object.skipMergingByNearness = message.skipMergingByNearness;
                        if (message.entranceMergingConfigInSupermarket != null && message.hasOwnProperty("entranceMergingConfigInSupermarket"))
                            object.entranceMergingConfigInSupermarket = $root.palexy.streaming.v1.EntranceMergingConfig.toObject(message.entranceMergingConfigInSupermarket, options);
                        if (message.queueTimeMergingConfigInSupermarket != null && message.hasOwnProperty("queueTimeMergingConfigInSupermarket"))
                            object.queueTimeMergingConfigInSupermarket = $root.palexy.streaming.v1.QueueTimeMergingConfig.toObject(message.queueTimeMergingConfigInSupermarket, options);
                        if (message.sectionMergingConfigInSupermarket != null && message.hasOwnProperty("sectionMergingConfigInSupermarket"))
                            object.sectionMergingConfigInSupermarket = $root.palexy.streaming.v1.SectionMergingConfig.toObject(message.sectionMergingConfigInSupermarket, options);
                        if (message.skipDetectingOutliers != null && message.hasOwnProperty("skipDetectingOutliers"))
                            object.skipDetectingOutliers = message.skipDetectingOutliers;
                        if (message.mergeVisitorsAtWaitingArea != null && message.hasOwnProperty("mergeVisitorsAtWaitingArea"))
                            object.mergeVisitorsAtWaitingArea = message.mergeVisitorsAtWaitingArea;
                        if (message.skipRemergeVeryCloseClustersByFeatureDistance != null && message.hasOwnProperty("skipRemergeVeryCloseClustersByFeatureDistance"))
                            object.skipRemergeVeryCloseClustersByFeatureDistance = message.skipRemergeVeryCloseClustersByFeatureDistance;
                        if (message.maxTimeDistanceForRemerge != null && message.hasOwnProperty("maxTimeDistanceForRemerge"))
                            object.maxTimeDistanceForRemerge = message.maxTimeDistanceForRemerge;
                        if (message.veryCloseFeatureDistanceThreshold != null && message.hasOwnProperty("veryCloseFeatureDistanceThreshold"))
                            object.veryCloseFeatureDistanceThreshold = options.json && !isFinite(message.veryCloseFeatureDistanceThreshold) ? String(message.veryCloseFeatureDistanceThreshold) : message.veryCloseFeatureDistanceThreshold;
                        if (message.crossCameraAdjustByDensityConfig != null && message.hasOwnProperty("crossCameraAdjustByDensityConfig"))
                            object.crossCameraAdjustByDensityConfig = $root.palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig.toObject(message.crossCameraAdjustByDensityConfig, options);
                        if (message.veryCloseThresAdjustByDensityConfig != null && message.hasOwnProperty("veryCloseThresAdjustByDensityConfig"))
                            object.veryCloseThresAdjustByDensityConfig = $root.palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig.toObject(message.veryCloseThresAdjustByDensityConfig, options);
                        if (message.disableDensityTrafficEstimator != null && message.hasOwnProperty("disableDensityTrafficEstimator"))
                            object.disableDensityTrafficEstimator = message.disableDensityTrafficEstimator;
                        if (message.wideSpaceEntranceConfig != null && message.hasOwnProperty("wideSpaceEntranceConfig"))
                            object.wideSpaceEntranceConfig = $root.palexy.streaming.v1.WideSpaceEntranceConfig.toObject(message.wideSpaceEntranceConfig, options);
                        if (message.veryVeryCloseFeatureDistanceForStaff != null && message.hasOwnProperty("veryVeryCloseFeatureDistanceForStaff"))
                            object.veryVeryCloseFeatureDistanceForStaff = options.json && !isFinite(message.veryVeryCloseFeatureDistanceForStaff) ? String(message.veryVeryCloseFeatureDistanceForStaff) : message.veryVeryCloseFeatureDistanceForStaff;
                        if (message.maxTimeDistanceForVeryVeryCloseForStaff != null && message.hasOwnProperty("maxTimeDistanceForVeryVeryCloseForStaff"))
                            object.maxTimeDistanceForVeryVeryCloseForStaff = message.maxTimeDistanceForVeryVeryCloseForStaff;
                        if (message.maxPeopleToStillUseNearness != null && message.hasOwnProperty("maxPeopleToStillUseNearness"))
                            object.maxPeopleToStillUseNearness = message.maxPeopleToStillUseNearness;
                        if (message.singleCameraAdjustByDensityConfig != null && message.hasOwnProperty("singleCameraAdjustByDensityConfig"))
                            object.singleCameraAdjustByDensityConfig = $root.palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig.toObject(message.singleCameraAdjustByDensityConfig, options);
                        if (message.globalViewThresAdjustByDensityConfig != null && message.hasOwnProperty("globalViewThresAdjustByDensityConfig"))
                            object.globalViewThresAdjustByDensityConfig = $root.palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig.toObject(message.globalViewThresAdjustByDensityConfig, options);
                        if (message.crossCameraDetectorConfig != null && message.hasOwnProperty("crossCameraDetectorConfig"))
                            object.crossCameraDetectorConfig = $root.palexy.streaming.v1.CrossCameraDetectorConfig.toObject(message.crossCameraDetectorConfig, options);
                        if (message.maxInternalFeatureDistanceThreshold != null && message.hasOwnProperty("maxInternalFeatureDistanceThreshold"))
                            object.maxInternalFeatureDistanceThreshold = options.json && !isFinite(message.maxInternalFeatureDistanceThreshold) ? String(message.maxInternalFeatureDistanceThreshold) : message.maxInternalFeatureDistanceThreshold;
                        if (message.maxFeatureThresholdAtRefinementAfterMxnMatching != null && message.hasOwnProperty("maxFeatureThresholdAtRefinementAfterMxnMatching"))
                            object.maxFeatureThresholdAtRefinementAfterMxnMatching = options.json && !isFinite(message.maxFeatureThresholdAtRefinementAfterMxnMatching) ? String(message.maxFeatureThresholdAtRefinementAfterMxnMatching) : message.maxFeatureThresholdAtRefinementAfterMxnMatching;
                        if (message.rerunSingleCameraTrackingConfig != null && message.hasOwnProperty("rerunSingleCameraTrackingConfig"))
                            object.rerunSingleCameraTrackingConfig = $root.palexy.streaming.v1.RerunSingleCameraTrackingConfig.toObject(message.rerunSingleCameraTrackingConfig, options);
                        if (message.skipMainMerging != null && message.hasOwnProperty("skipMainMerging"))
                            object.skipMainMerging = message.skipMainMerging;
                        if (message.runMallMergingConfig != null && message.hasOwnProperty("runMallMergingConfig"))
                            object.runMallMergingConfig = $root.palexy.streaming.v1.RunMallMergingConfig.toObject(message.runMallMergingConfig, options);
                        if (message.skipRunRefine != null && message.hasOwnProperty("skipRunRefine"))
                            object.skipRunRefine = message.skipRunRefine;
                        if (message.minimalCameraCountingConfig != null && message.hasOwnProperty("minimalCameraCountingConfig"))
                            object.minimalCameraCountingConfig = $root.palexy.streaming.v1.MinimalCameraCountingConfig.toObject(message.minimalCameraCountingConfig, options);
                        return object;
                    };
    
                    /**
                     * Converts this MergingConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MergingConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MergingConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MergingConfig;
                })();
    
                v1.MinimalCameraCountingConfig = (function() {
    
                    /**
                     * Properties of a MinimalCameraCountingConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IMinimalCameraCountingConfig
                     * @property {number|null} [walkingDistanceThreshold] MinimalCameraCountingConfig walkingDistanceThreshold
                     * @property {number|null} [goInAngleDegreeThreshold] MinimalCameraCountingConfig goInAngleDegreeThreshold
                     * @property {number|null} [goOutAngleDegreeThreshold] MinimalCameraCountingConfig goOutAngleDegreeThreshold
                     * @property {number|null} [consecutiveTrackletTimeThreshold] MinimalCameraCountingConfig consecutiveTrackletTimeThreshold
                     * @property {number|null} [remergeInAndOutFeatureDistanceThreshold] MinimalCameraCountingConfig remergeInAndOutFeatureDistanceThreshold
                     * @property {number|null} [remergeVeryCloseFeatureDistanceThreshold] MinimalCameraCountingConfig remergeVeryCloseFeatureDistanceThreshold
                     * @property {number|null} [remergeMaxTimeDistanceSeconds] MinimalCameraCountingConfig remergeMaxTimeDistanceSeconds
                     */
    
                    /**
                     * Constructs a new MinimalCameraCountingConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MinimalCameraCountingConfig.
                     * @implements IMinimalCameraCountingConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IMinimalCameraCountingConfig=} [properties] Properties to set
                     */
                    function MinimalCameraCountingConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MinimalCameraCountingConfig walkingDistanceThreshold.
                     * @member {number} walkingDistanceThreshold
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @instance
                     */
                    MinimalCameraCountingConfig.prototype.walkingDistanceThreshold = 0;
    
                    /**
                     * MinimalCameraCountingConfig goInAngleDegreeThreshold.
                     * @member {number} goInAngleDegreeThreshold
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @instance
                     */
                    MinimalCameraCountingConfig.prototype.goInAngleDegreeThreshold = 0;
    
                    /**
                     * MinimalCameraCountingConfig goOutAngleDegreeThreshold.
                     * @member {number} goOutAngleDegreeThreshold
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @instance
                     */
                    MinimalCameraCountingConfig.prototype.goOutAngleDegreeThreshold = 0;
    
                    /**
                     * MinimalCameraCountingConfig consecutiveTrackletTimeThreshold.
                     * @member {number} consecutiveTrackletTimeThreshold
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @instance
                     */
                    MinimalCameraCountingConfig.prototype.consecutiveTrackletTimeThreshold = 0;
    
                    /**
                     * MinimalCameraCountingConfig remergeInAndOutFeatureDistanceThreshold.
                     * @member {number} remergeInAndOutFeatureDistanceThreshold
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @instance
                     */
                    MinimalCameraCountingConfig.prototype.remergeInAndOutFeatureDistanceThreshold = 0;
    
                    /**
                     * MinimalCameraCountingConfig remergeVeryCloseFeatureDistanceThreshold.
                     * @member {number} remergeVeryCloseFeatureDistanceThreshold
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @instance
                     */
                    MinimalCameraCountingConfig.prototype.remergeVeryCloseFeatureDistanceThreshold = 0;
    
                    /**
                     * MinimalCameraCountingConfig remergeMaxTimeDistanceSeconds.
                     * @member {number} remergeMaxTimeDistanceSeconds
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @instance
                     */
                    MinimalCameraCountingConfig.prototype.remergeMaxTimeDistanceSeconds = 0;
    
                    /**
                     * Creates a new MinimalCameraCountingConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @static
                     * @param {palexy.streaming.v1.IMinimalCameraCountingConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MinimalCameraCountingConfig} MinimalCameraCountingConfig instance
                     */
                    MinimalCameraCountingConfig.create = function create(properties) {
                        return new MinimalCameraCountingConfig(properties);
                    };
    
                    /**
                     * Encodes the specified MinimalCameraCountingConfig message. Does not implicitly {@link palexy.streaming.v1.MinimalCameraCountingConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @static
                     * @param {palexy.streaming.v1.IMinimalCameraCountingConfig} message MinimalCameraCountingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MinimalCameraCountingConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.walkingDistanceThreshold != null && Object.hasOwnProperty.call(message, "walkingDistanceThreshold"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.walkingDistanceThreshold);
                        if (message.goInAngleDegreeThreshold != null && Object.hasOwnProperty.call(message, "goInAngleDegreeThreshold"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.goInAngleDegreeThreshold);
                        if (message.goOutAngleDegreeThreshold != null && Object.hasOwnProperty.call(message, "goOutAngleDegreeThreshold"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.goOutAngleDegreeThreshold);
                        if (message.consecutiveTrackletTimeThreshold != null && Object.hasOwnProperty.call(message, "consecutiveTrackletTimeThreshold"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.consecutiveTrackletTimeThreshold);
                        if (message.remergeInAndOutFeatureDistanceThreshold != null && Object.hasOwnProperty.call(message, "remergeInAndOutFeatureDistanceThreshold"))
                            writer.uint32(/* id 5, wireType 5 =*/45).float(message.remergeInAndOutFeatureDistanceThreshold);
                        if (message.remergeVeryCloseFeatureDistanceThreshold != null && Object.hasOwnProperty.call(message, "remergeVeryCloseFeatureDistanceThreshold"))
                            writer.uint32(/* id 6, wireType 5 =*/53).float(message.remergeVeryCloseFeatureDistanceThreshold);
                        if (message.remergeMaxTimeDistanceSeconds != null && Object.hasOwnProperty.call(message, "remergeMaxTimeDistanceSeconds"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.remergeMaxTimeDistanceSeconds);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MinimalCameraCountingConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.MinimalCameraCountingConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @static
                     * @param {palexy.streaming.v1.IMinimalCameraCountingConfig} message MinimalCameraCountingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MinimalCameraCountingConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MinimalCameraCountingConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MinimalCameraCountingConfig} MinimalCameraCountingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MinimalCameraCountingConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MinimalCameraCountingConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.walkingDistanceThreshold = reader.int32();
                                break;
                            case 2:
                                message.goInAngleDegreeThreshold = reader.int32();
                                break;
                            case 3:
                                message.goOutAngleDegreeThreshold = reader.int32();
                                break;
                            case 4:
                                message.consecutiveTrackletTimeThreshold = reader.int32();
                                break;
                            case 5:
                                message.remergeInAndOutFeatureDistanceThreshold = reader.float();
                                break;
                            case 6:
                                message.remergeVeryCloseFeatureDistanceThreshold = reader.float();
                                break;
                            case 7:
                                message.remergeMaxTimeDistanceSeconds = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MinimalCameraCountingConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MinimalCameraCountingConfig} MinimalCameraCountingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MinimalCameraCountingConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MinimalCameraCountingConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MinimalCameraCountingConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.walkingDistanceThreshold != null && message.hasOwnProperty("walkingDistanceThreshold"))
                            if (!$util.isInteger(message.walkingDistanceThreshold))
                                return "walkingDistanceThreshold: integer expected";
                        if (message.goInAngleDegreeThreshold != null && message.hasOwnProperty("goInAngleDegreeThreshold"))
                            if (!$util.isInteger(message.goInAngleDegreeThreshold))
                                return "goInAngleDegreeThreshold: integer expected";
                        if (message.goOutAngleDegreeThreshold != null && message.hasOwnProperty("goOutAngleDegreeThreshold"))
                            if (!$util.isInteger(message.goOutAngleDegreeThreshold))
                                return "goOutAngleDegreeThreshold: integer expected";
                        if (message.consecutiveTrackletTimeThreshold != null && message.hasOwnProperty("consecutiveTrackletTimeThreshold"))
                            if (!$util.isInteger(message.consecutiveTrackletTimeThreshold))
                                return "consecutiveTrackletTimeThreshold: integer expected";
                        if (message.remergeInAndOutFeatureDistanceThreshold != null && message.hasOwnProperty("remergeInAndOutFeatureDistanceThreshold"))
                            if (typeof message.remergeInAndOutFeatureDistanceThreshold !== "number")
                                return "remergeInAndOutFeatureDistanceThreshold: number expected";
                        if (message.remergeVeryCloseFeatureDistanceThreshold != null && message.hasOwnProperty("remergeVeryCloseFeatureDistanceThreshold"))
                            if (typeof message.remergeVeryCloseFeatureDistanceThreshold !== "number")
                                return "remergeVeryCloseFeatureDistanceThreshold: number expected";
                        if (message.remergeMaxTimeDistanceSeconds != null && message.hasOwnProperty("remergeMaxTimeDistanceSeconds"))
                            if (!$util.isInteger(message.remergeMaxTimeDistanceSeconds))
                                return "remergeMaxTimeDistanceSeconds: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a MinimalCameraCountingConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MinimalCameraCountingConfig} MinimalCameraCountingConfig
                     */
                    MinimalCameraCountingConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MinimalCameraCountingConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.MinimalCameraCountingConfig();
                        if (object.walkingDistanceThreshold != null)
                            message.walkingDistanceThreshold = object.walkingDistanceThreshold | 0;
                        if (object.goInAngleDegreeThreshold != null)
                            message.goInAngleDegreeThreshold = object.goInAngleDegreeThreshold | 0;
                        if (object.goOutAngleDegreeThreshold != null)
                            message.goOutAngleDegreeThreshold = object.goOutAngleDegreeThreshold | 0;
                        if (object.consecutiveTrackletTimeThreshold != null)
                            message.consecutiveTrackletTimeThreshold = object.consecutiveTrackletTimeThreshold | 0;
                        if (object.remergeInAndOutFeatureDistanceThreshold != null)
                            message.remergeInAndOutFeatureDistanceThreshold = Number(object.remergeInAndOutFeatureDistanceThreshold);
                        if (object.remergeVeryCloseFeatureDistanceThreshold != null)
                            message.remergeVeryCloseFeatureDistanceThreshold = Number(object.remergeVeryCloseFeatureDistanceThreshold);
                        if (object.remergeMaxTimeDistanceSeconds != null)
                            message.remergeMaxTimeDistanceSeconds = object.remergeMaxTimeDistanceSeconds | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MinimalCameraCountingConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @static
                     * @param {palexy.streaming.v1.MinimalCameraCountingConfig} message MinimalCameraCountingConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MinimalCameraCountingConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.walkingDistanceThreshold = 0;
                            object.goInAngleDegreeThreshold = 0;
                            object.goOutAngleDegreeThreshold = 0;
                            object.consecutiveTrackletTimeThreshold = 0;
                            object.remergeInAndOutFeatureDistanceThreshold = 0;
                            object.remergeVeryCloseFeatureDistanceThreshold = 0;
                            object.remergeMaxTimeDistanceSeconds = 0;
                        }
                        if (message.walkingDistanceThreshold != null && message.hasOwnProperty("walkingDistanceThreshold"))
                            object.walkingDistanceThreshold = message.walkingDistanceThreshold;
                        if (message.goInAngleDegreeThreshold != null && message.hasOwnProperty("goInAngleDegreeThreshold"))
                            object.goInAngleDegreeThreshold = message.goInAngleDegreeThreshold;
                        if (message.goOutAngleDegreeThreshold != null && message.hasOwnProperty("goOutAngleDegreeThreshold"))
                            object.goOutAngleDegreeThreshold = message.goOutAngleDegreeThreshold;
                        if (message.consecutiveTrackletTimeThreshold != null && message.hasOwnProperty("consecutiveTrackletTimeThreshold"))
                            object.consecutiveTrackletTimeThreshold = message.consecutiveTrackletTimeThreshold;
                        if (message.remergeInAndOutFeatureDistanceThreshold != null && message.hasOwnProperty("remergeInAndOutFeatureDistanceThreshold"))
                            object.remergeInAndOutFeatureDistanceThreshold = options.json && !isFinite(message.remergeInAndOutFeatureDistanceThreshold) ? String(message.remergeInAndOutFeatureDistanceThreshold) : message.remergeInAndOutFeatureDistanceThreshold;
                        if (message.remergeVeryCloseFeatureDistanceThreshold != null && message.hasOwnProperty("remergeVeryCloseFeatureDistanceThreshold"))
                            object.remergeVeryCloseFeatureDistanceThreshold = options.json && !isFinite(message.remergeVeryCloseFeatureDistanceThreshold) ? String(message.remergeVeryCloseFeatureDistanceThreshold) : message.remergeVeryCloseFeatureDistanceThreshold;
                        if (message.remergeMaxTimeDistanceSeconds != null && message.hasOwnProperty("remergeMaxTimeDistanceSeconds"))
                            object.remergeMaxTimeDistanceSeconds = message.remergeMaxTimeDistanceSeconds;
                        return object;
                    };
    
                    /**
                     * Converts this MinimalCameraCountingConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MinimalCameraCountingConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MinimalCameraCountingConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MinimalCameraCountingConfig;
                })();
    
                v1.RerunSingleCameraTrackingConfig = (function() {
    
                    /**
                     * Properties of a RerunSingleCameraTrackingConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IRerunSingleCameraTrackingConfig
                     * @property {boolean|null} [skipRerunning] RerunSingleCameraTrackingConfig skipRerunning
                     * @property {number|null} [reidThreshold] RerunSingleCameraTrackingConfig reidThreshold
                     * @property {number|null} [maxTimeDistance] RerunSingleCameraTrackingConfig maxTimeDistance
                     * @property {number|null} [minNearIou] RerunSingleCameraTrackingConfig minNearIou
                     */
    
                    /**
                     * Constructs a new RerunSingleCameraTrackingConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RerunSingleCameraTrackingConfig.
                     * @implements IRerunSingleCameraTrackingConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IRerunSingleCameraTrackingConfig=} [properties] Properties to set
                     */
                    function RerunSingleCameraTrackingConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RerunSingleCameraTrackingConfig skipRerunning.
                     * @member {boolean} skipRerunning
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @instance
                     */
                    RerunSingleCameraTrackingConfig.prototype.skipRerunning = false;
    
                    /**
                     * RerunSingleCameraTrackingConfig reidThreshold.
                     * @member {number} reidThreshold
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @instance
                     */
                    RerunSingleCameraTrackingConfig.prototype.reidThreshold = 0;
    
                    /**
                     * RerunSingleCameraTrackingConfig maxTimeDistance.
                     * @member {number} maxTimeDistance
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @instance
                     */
                    RerunSingleCameraTrackingConfig.prototype.maxTimeDistance = 0;
    
                    /**
                     * RerunSingleCameraTrackingConfig minNearIou.
                     * @member {number} minNearIou
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @instance
                     */
                    RerunSingleCameraTrackingConfig.prototype.minNearIou = 0;
    
                    /**
                     * Creates a new RerunSingleCameraTrackingConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @static
                     * @param {palexy.streaming.v1.IRerunSingleCameraTrackingConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RerunSingleCameraTrackingConfig} RerunSingleCameraTrackingConfig instance
                     */
                    RerunSingleCameraTrackingConfig.create = function create(properties) {
                        return new RerunSingleCameraTrackingConfig(properties);
                    };
    
                    /**
                     * Encodes the specified RerunSingleCameraTrackingConfig message. Does not implicitly {@link palexy.streaming.v1.RerunSingleCameraTrackingConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @static
                     * @param {palexy.streaming.v1.IRerunSingleCameraTrackingConfig} message RerunSingleCameraTrackingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunSingleCameraTrackingConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.skipRerunning != null && Object.hasOwnProperty.call(message, "skipRerunning"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.skipRerunning);
                        if (message.reidThreshold != null && Object.hasOwnProperty.call(message, "reidThreshold"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.reidThreshold);
                        if (message.maxTimeDistance != null && Object.hasOwnProperty.call(message, "maxTimeDistance"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.maxTimeDistance);
                        if (message.minNearIou != null && Object.hasOwnProperty.call(message, "minNearIou"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.minNearIou);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RerunSingleCameraTrackingConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.RerunSingleCameraTrackingConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @static
                     * @param {palexy.streaming.v1.IRerunSingleCameraTrackingConfig} message RerunSingleCameraTrackingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RerunSingleCameraTrackingConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RerunSingleCameraTrackingConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RerunSingleCameraTrackingConfig} RerunSingleCameraTrackingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunSingleCameraTrackingConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RerunSingleCameraTrackingConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.skipRerunning = reader.bool();
                                break;
                            case 2:
                                message.reidThreshold = reader.float();
                                break;
                            case 3:
                                message.maxTimeDistance = reader.int32();
                                break;
                            case 4:
                                message.minNearIou = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RerunSingleCameraTrackingConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RerunSingleCameraTrackingConfig} RerunSingleCameraTrackingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RerunSingleCameraTrackingConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RerunSingleCameraTrackingConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RerunSingleCameraTrackingConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.skipRerunning != null && message.hasOwnProperty("skipRerunning"))
                            if (typeof message.skipRerunning !== "boolean")
                                return "skipRerunning: boolean expected";
                        if (message.reidThreshold != null && message.hasOwnProperty("reidThreshold"))
                            if (typeof message.reidThreshold !== "number")
                                return "reidThreshold: number expected";
                        if (message.maxTimeDistance != null && message.hasOwnProperty("maxTimeDistance"))
                            if (!$util.isInteger(message.maxTimeDistance))
                                return "maxTimeDistance: integer expected";
                        if (message.minNearIou != null && message.hasOwnProperty("minNearIou"))
                            if (typeof message.minNearIou !== "number")
                                return "minNearIou: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a RerunSingleCameraTrackingConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RerunSingleCameraTrackingConfig} RerunSingleCameraTrackingConfig
                     */
                    RerunSingleCameraTrackingConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RerunSingleCameraTrackingConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.RerunSingleCameraTrackingConfig();
                        if (object.skipRerunning != null)
                            message.skipRerunning = Boolean(object.skipRerunning);
                        if (object.reidThreshold != null)
                            message.reidThreshold = Number(object.reidThreshold);
                        if (object.maxTimeDistance != null)
                            message.maxTimeDistance = object.maxTimeDistance | 0;
                        if (object.minNearIou != null)
                            message.minNearIou = Number(object.minNearIou);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RerunSingleCameraTrackingConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @static
                     * @param {palexy.streaming.v1.RerunSingleCameraTrackingConfig} message RerunSingleCameraTrackingConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RerunSingleCameraTrackingConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.skipRerunning = false;
                            object.reidThreshold = 0;
                            object.maxTimeDistance = 0;
                            object.minNearIou = 0;
                        }
                        if (message.skipRerunning != null && message.hasOwnProperty("skipRerunning"))
                            object.skipRerunning = message.skipRerunning;
                        if (message.reidThreshold != null && message.hasOwnProperty("reidThreshold"))
                            object.reidThreshold = options.json && !isFinite(message.reidThreshold) ? String(message.reidThreshold) : message.reidThreshold;
                        if (message.maxTimeDistance != null && message.hasOwnProperty("maxTimeDistance"))
                            object.maxTimeDistance = message.maxTimeDistance;
                        if (message.minNearIou != null && message.hasOwnProperty("minNearIou"))
                            object.minNearIou = options.json && !isFinite(message.minNearIou) ? String(message.minNearIou) : message.minNearIou;
                        return object;
                    };
    
                    /**
                     * Converts this RerunSingleCameraTrackingConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RerunSingleCameraTrackingConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RerunSingleCameraTrackingConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RerunSingleCameraTrackingConfig;
                })();
    
                v1.CrossCameraDetectorConfig = (function() {
    
                    /**
                     * Properties of a CrossCameraDetectorConfig.
                     * @memberof palexy.streaming.v1
                     * @interface ICrossCameraDetectorConfig
                     * @property {number|null} [crossCameraThres] CrossCameraDetectorConfig crossCameraThres
                     * @property {number|null} [groupDuration] CrossCameraDetectorConfig groupDuration
                     * @property {number|null} [maxDensity] CrossCameraDetectorConfig maxDensity
                     * @property {number|null} [minValidSlotsToNotOverlapping] CrossCameraDetectorConfig minValidSlotsToNotOverlapping
                     * @property {number|null} [veryCloseThres] CrossCameraDetectorConfig veryCloseThres
                     */
    
                    /**
                     * Constructs a new CrossCameraDetectorConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CrossCameraDetectorConfig.
                     * @implements ICrossCameraDetectorConfig
                     * @constructor
                     * @param {palexy.streaming.v1.ICrossCameraDetectorConfig=} [properties] Properties to set
                     */
                    function CrossCameraDetectorConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CrossCameraDetectorConfig crossCameraThres.
                     * @member {number} crossCameraThres
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @instance
                     */
                    CrossCameraDetectorConfig.prototype.crossCameraThres = 0;
    
                    /**
                     * CrossCameraDetectorConfig groupDuration.
                     * @member {number} groupDuration
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @instance
                     */
                    CrossCameraDetectorConfig.prototype.groupDuration = 0;
    
                    /**
                     * CrossCameraDetectorConfig maxDensity.
                     * @member {number} maxDensity
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @instance
                     */
                    CrossCameraDetectorConfig.prototype.maxDensity = 0;
    
                    /**
                     * CrossCameraDetectorConfig minValidSlotsToNotOverlapping.
                     * @member {number} minValidSlotsToNotOverlapping
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @instance
                     */
                    CrossCameraDetectorConfig.prototype.minValidSlotsToNotOverlapping = 0;
    
                    /**
                     * CrossCameraDetectorConfig veryCloseThres.
                     * @member {number} veryCloseThres
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @instance
                     */
                    CrossCameraDetectorConfig.prototype.veryCloseThres = 0;
    
                    /**
                     * Creates a new CrossCameraDetectorConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @static
                     * @param {palexy.streaming.v1.ICrossCameraDetectorConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CrossCameraDetectorConfig} CrossCameraDetectorConfig instance
                     */
                    CrossCameraDetectorConfig.create = function create(properties) {
                        return new CrossCameraDetectorConfig(properties);
                    };
    
                    /**
                     * Encodes the specified CrossCameraDetectorConfig message. Does not implicitly {@link palexy.streaming.v1.CrossCameraDetectorConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @static
                     * @param {palexy.streaming.v1.ICrossCameraDetectorConfig} message CrossCameraDetectorConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CrossCameraDetectorConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.crossCameraThres != null && Object.hasOwnProperty.call(message, "crossCameraThres"))
                            writer.uint32(/* id 1, wireType 5 =*/13).float(message.crossCameraThres);
                        if (message.groupDuration != null && Object.hasOwnProperty.call(message, "groupDuration"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.groupDuration);
                        if (message.maxDensity != null && Object.hasOwnProperty.call(message, "maxDensity"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.maxDensity);
                        if (message.minValidSlotsToNotOverlapping != null && Object.hasOwnProperty.call(message, "minValidSlotsToNotOverlapping"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.minValidSlotsToNotOverlapping);
                        if (message.veryCloseThres != null && Object.hasOwnProperty.call(message, "veryCloseThres"))
                            writer.uint32(/* id 5, wireType 5 =*/45).float(message.veryCloseThres);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CrossCameraDetectorConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.CrossCameraDetectorConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @static
                     * @param {palexy.streaming.v1.ICrossCameraDetectorConfig} message CrossCameraDetectorConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CrossCameraDetectorConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CrossCameraDetectorConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CrossCameraDetectorConfig} CrossCameraDetectorConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CrossCameraDetectorConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CrossCameraDetectorConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.crossCameraThres = reader.float();
                                break;
                            case 2:
                                message.groupDuration = reader.int32();
                                break;
                            case 3:
                                message.maxDensity = reader.int32();
                                break;
                            case 4:
                                message.minValidSlotsToNotOverlapping = reader.int32();
                                break;
                            case 5:
                                message.veryCloseThres = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CrossCameraDetectorConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CrossCameraDetectorConfig} CrossCameraDetectorConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CrossCameraDetectorConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CrossCameraDetectorConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CrossCameraDetectorConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.crossCameraThres != null && message.hasOwnProperty("crossCameraThres"))
                            if (typeof message.crossCameraThres !== "number")
                                return "crossCameraThres: number expected";
                        if (message.groupDuration != null && message.hasOwnProperty("groupDuration"))
                            if (!$util.isInteger(message.groupDuration))
                                return "groupDuration: integer expected";
                        if (message.maxDensity != null && message.hasOwnProperty("maxDensity"))
                            if (!$util.isInteger(message.maxDensity))
                                return "maxDensity: integer expected";
                        if (message.minValidSlotsToNotOverlapping != null && message.hasOwnProperty("minValidSlotsToNotOverlapping"))
                            if (!$util.isInteger(message.minValidSlotsToNotOverlapping))
                                return "minValidSlotsToNotOverlapping: integer expected";
                        if (message.veryCloseThres != null && message.hasOwnProperty("veryCloseThres"))
                            if (typeof message.veryCloseThres !== "number")
                                return "veryCloseThres: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a CrossCameraDetectorConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CrossCameraDetectorConfig} CrossCameraDetectorConfig
                     */
                    CrossCameraDetectorConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CrossCameraDetectorConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.CrossCameraDetectorConfig();
                        if (object.crossCameraThres != null)
                            message.crossCameraThres = Number(object.crossCameraThres);
                        if (object.groupDuration != null)
                            message.groupDuration = object.groupDuration | 0;
                        if (object.maxDensity != null)
                            message.maxDensity = object.maxDensity | 0;
                        if (object.minValidSlotsToNotOverlapping != null)
                            message.minValidSlotsToNotOverlapping = object.minValidSlotsToNotOverlapping | 0;
                        if (object.veryCloseThres != null)
                            message.veryCloseThres = Number(object.veryCloseThres);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CrossCameraDetectorConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @static
                     * @param {palexy.streaming.v1.CrossCameraDetectorConfig} message CrossCameraDetectorConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CrossCameraDetectorConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.crossCameraThres = 0;
                            object.groupDuration = 0;
                            object.maxDensity = 0;
                            object.minValidSlotsToNotOverlapping = 0;
                            object.veryCloseThres = 0;
                        }
                        if (message.crossCameraThres != null && message.hasOwnProperty("crossCameraThres"))
                            object.crossCameraThres = options.json && !isFinite(message.crossCameraThres) ? String(message.crossCameraThres) : message.crossCameraThres;
                        if (message.groupDuration != null && message.hasOwnProperty("groupDuration"))
                            object.groupDuration = message.groupDuration;
                        if (message.maxDensity != null && message.hasOwnProperty("maxDensity"))
                            object.maxDensity = message.maxDensity;
                        if (message.minValidSlotsToNotOverlapping != null && message.hasOwnProperty("minValidSlotsToNotOverlapping"))
                            object.minValidSlotsToNotOverlapping = message.minValidSlotsToNotOverlapping;
                        if (message.veryCloseThres != null && message.hasOwnProperty("veryCloseThres"))
                            object.veryCloseThres = options.json && !isFinite(message.veryCloseThres) ? String(message.veryCloseThres) : message.veryCloseThres;
                        return object;
                    };
    
                    /**
                     * Converts this CrossCameraDetectorConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CrossCameraDetectorConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CrossCameraDetectorConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CrossCameraDetectorConfig;
                })();
    
                v1.StaticObjectFilteringConfig = (function() {
    
                    /**
                     * Properties of a StaticObjectFilteringConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IStaticObjectFilteringConfig
                     * @property {number|null} [minItems] StaticObjectFilteringConfig minItems
                     * @property {number|null} [minBbIou] StaticObjectFilteringConfig minBbIou
                     * @property {number|null} [maxFeatureDistance] StaticObjectFilteringConfig maxFeatureDistance
                     * @property {number|null} [staticObjectWindowSize] StaticObjectFilteringConfig staticObjectWindowSize
                     * @property {number|null} [maxStaticObjectsPerCam] StaticObjectFilteringConfig maxStaticObjectsPerCam
                     * @property {number|null} [minDurationInStoreInSecond] StaticObjectFilteringConfig minDurationInStoreInSecond
                     * @property {number|null} [minPeakItems] StaticObjectFilteringConfig minPeakItems
                     */
    
                    /**
                     * Constructs a new StaticObjectFilteringConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaticObjectFilteringConfig.
                     * @implements IStaticObjectFilteringConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IStaticObjectFilteringConfig=} [properties] Properties to set
                     */
                    function StaticObjectFilteringConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StaticObjectFilteringConfig minItems.
                     * @member {number} minItems
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @instance
                     */
                    StaticObjectFilteringConfig.prototype.minItems = 0;
    
                    /**
                     * StaticObjectFilteringConfig minBbIou.
                     * @member {number} minBbIou
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @instance
                     */
                    StaticObjectFilteringConfig.prototype.minBbIou = 0;
    
                    /**
                     * StaticObjectFilteringConfig maxFeatureDistance.
                     * @member {number} maxFeatureDistance
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @instance
                     */
                    StaticObjectFilteringConfig.prototype.maxFeatureDistance = 0;
    
                    /**
                     * StaticObjectFilteringConfig staticObjectWindowSize.
                     * @member {number} staticObjectWindowSize
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @instance
                     */
                    StaticObjectFilteringConfig.prototype.staticObjectWindowSize = 0;
    
                    /**
                     * StaticObjectFilteringConfig maxStaticObjectsPerCam.
                     * @member {number} maxStaticObjectsPerCam
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @instance
                     */
                    StaticObjectFilteringConfig.prototype.maxStaticObjectsPerCam = 0;
    
                    /**
                     * StaticObjectFilteringConfig minDurationInStoreInSecond.
                     * @member {number} minDurationInStoreInSecond
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @instance
                     */
                    StaticObjectFilteringConfig.prototype.minDurationInStoreInSecond = 0;
    
                    /**
                     * StaticObjectFilteringConfig minPeakItems.
                     * @member {number} minPeakItems
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @instance
                     */
                    StaticObjectFilteringConfig.prototype.minPeakItems = 0;
    
                    /**
                     * Creates a new StaticObjectFilteringConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @static
                     * @param {palexy.streaming.v1.IStaticObjectFilteringConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StaticObjectFilteringConfig} StaticObjectFilteringConfig instance
                     */
                    StaticObjectFilteringConfig.create = function create(properties) {
                        return new StaticObjectFilteringConfig(properties);
                    };
    
                    /**
                     * Encodes the specified StaticObjectFilteringConfig message. Does not implicitly {@link palexy.streaming.v1.StaticObjectFilteringConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @static
                     * @param {palexy.streaming.v1.IStaticObjectFilteringConfig} message StaticObjectFilteringConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaticObjectFilteringConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minItems != null && Object.hasOwnProperty.call(message, "minItems"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minItems);
                        if (message.minBbIou != null && Object.hasOwnProperty.call(message, "minBbIou"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.minBbIou);
                        if (message.maxFeatureDistance != null && Object.hasOwnProperty.call(message, "maxFeatureDistance"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.maxFeatureDistance);
                        if (message.staticObjectWindowSize != null && Object.hasOwnProperty.call(message, "staticObjectWindowSize"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.staticObjectWindowSize);
                        if (message.maxStaticObjectsPerCam != null && Object.hasOwnProperty.call(message, "maxStaticObjectsPerCam"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.maxStaticObjectsPerCam);
                        if (message.minDurationInStoreInSecond != null && Object.hasOwnProperty.call(message, "minDurationInStoreInSecond"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.minDurationInStoreInSecond);
                        if (message.minPeakItems != null && Object.hasOwnProperty.call(message, "minPeakItems"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.minPeakItems);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StaticObjectFilteringConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.StaticObjectFilteringConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @static
                     * @param {palexy.streaming.v1.IStaticObjectFilteringConfig} message StaticObjectFilteringConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaticObjectFilteringConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StaticObjectFilteringConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StaticObjectFilteringConfig} StaticObjectFilteringConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaticObjectFilteringConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StaticObjectFilteringConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.minItems = reader.int32();
                                break;
                            case 2:
                                message.minBbIou = reader.float();
                                break;
                            case 3:
                                message.maxFeatureDistance = reader.float();
                                break;
                            case 4:
                                message.staticObjectWindowSize = reader.int32();
                                break;
                            case 5:
                                message.maxStaticObjectsPerCam = reader.int32();
                                break;
                            case 6:
                                message.minDurationInStoreInSecond = reader.int32();
                                break;
                            case 7:
                                message.minPeakItems = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StaticObjectFilteringConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StaticObjectFilteringConfig} StaticObjectFilteringConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaticObjectFilteringConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StaticObjectFilteringConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StaticObjectFilteringConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minItems != null && message.hasOwnProperty("minItems"))
                            if (!$util.isInteger(message.minItems))
                                return "minItems: integer expected";
                        if (message.minBbIou != null && message.hasOwnProperty("minBbIou"))
                            if (typeof message.minBbIou !== "number")
                                return "minBbIou: number expected";
                        if (message.maxFeatureDistance != null && message.hasOwnProperty("maxFeatureDistance"))
                            if (typeof message.maxFeatureDistance !== "number")
                                return "maxFeatureDistance: number expected";
                        if (message.staticObjectWindowSize != null && message.hasOwnProperty("staticObjectWindowSize"))
                            if (!$util.isInteger(message.staticObjectWindowSize))
                                return "staticObjectWindowSize: integer expected";
                        if (message.maxStaticObjectsPerCam != null && message.hasOwnProperty("maxStaticObjectsPerCam"))
                            if (!$util.isInteger(message.maxStaticObjectsPerCam))
                                return "maxStaticObjectsPerCam: integer expected";
                        if (message.minDurationInStoreInSecond != null && message.hasOwnProperty("minDurationInStoreInSecond"))
                            if (!$util.isInteger(message.minDurationInStoreInSecond))
                                return "minDurationInStoreInSecond: integer expected";
                        if (message.minPeakItems != null && message.hasOwnProperty("minPeakItems"))
                            if (!$util.isInteger(message.minPeakItems))
                                return "minPeakItems: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a StaticObjectFilteringConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StaticObjectFilteringConfig} StaticObjectFilteringConfig
                     */
                    StaticObjectFilteringConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StaticObjectFilteringConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.StaticObjectFilteringConfig();
                        if (object.minItems != null)
                            message.minItems = object.minItems | 0;
                        if (object.minBbIou != null)
                            message.minBbIou = Number(object.minBbIou);
                        if (object.maxFeatureDistance != null)
                            message.maxFeatureDistance = Number(object.maxFeatureDistance);
                        if (object.staticObjectWindowSize != null)
                            message.staticObjectWindowSize = object.staticObjectWindowSize | 0;
                        if (object.maxStaticObjectsPerCam != null)
                            message.maxStaticObjectsPerCam = object.maxStaticObjectsPerCam | 0;
                        if (object.minDurationInStoreInSecond != null)
                            message.minDurationInStoreInSecond = object.minDurationInStoreInSecond | 0;
                        if (object.minPeakItems != null)
                            message.minPeakItems = object.minPeakItems | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StaticObjectFilteringConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @static
                     * @param {palexy.streaming.v1.StaticObjectFilteringConfig} message StaticObjectFilteringConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StaticObjectFilteringConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minItems = 0;
                            object.minBbIou = 0;
                            object.maxFeatureDistance = 0;
                            object.staticObjectWindowSize = 0;
                            object.maxStaticObjectsPerCam = 0;
                            object.minDurationInStoreInSecond = 0;
                            object.minPeakItems = 0;
                        }
                        if (message.minItems != null && message.hasOwnProperty("minItems"))
                            object.minItems = message.minItems;
                        if (message.minBbIou != null && message.hasOwnProperty("minBbIou"))
                            object.minBbIou = options.json && !isFinite(message.minBbIou) ? String(message.minBbIou) : message.minBbIou;
                        if (message.maxFeatureDistance != null && message.hasOwnProperty("maxFeatureDistance"))
                            object.maxFeatureDistance = options.json && !isFinite(message.maxFeatureDistance) ? String(message.maxFeatureDistance) : message.maxFeatureDistance;
                        if (message.staticObjectWindowSize != null && message.hasOwnProperty("staticObjectWindowSize"))
                            object.staticObjectWindowSize = message.staticObjectWindowSize;
                        if (message.maxStaticObjectsPerCam != null && message.hasOwnProperty("maxStaticObjectsPerCam"))
                            object.maxStaticObjectsPerCam = message.maxStaticObjectsPerCam;
                        if (message.minDurationInStoreInSecond != null && message.hasOwnProperty("minDurationInStoreInSecond"))
                            object.minDurationInStoreInSecond = message.minDurationInStoreInSecond;
                        if (message.minPeakItems != null && message.hasOwnProperty("minPeakItems"))
                            object.minPeakItems = message.minPeakItems;
                        return object;
                    };
    
                    /**
                     * Converts this StaticObjectFilteringConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StaticObjectFilteringConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StaticObjectFilteringConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StaticObjectFilteringConfig;
                })();
    
                v1.GlobalViewThresAdjustByDensityConfig = (function() {
    
                    /**
                     * Properties of a GlobalViewThresAdjustByDensityConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IGlobalViewThresAdjustByDensityConfig
                     * @property {number|null} [minNumPeopleToDecrease] GlobalViewThresAdjustByDensityConfig minNumPeopleToDecrease
                     * @property {number|null} [maxNumPeopleToStopDecrease] GlobalViewThresAdjustByDensityConfig maxNumPeopleToStopDecrease
                     * @property {number|null} [minimumTimeDistance] GlobalViewThresAdjustByDensityConfig minimumTimeDistance
                     * @property {number|null} [minimumFeatureThreshold] GlobalViewThresAdjustByDensityConfig minimumFeatureThreshold
                     */
    
                    /**
                     * Constructs a new GlobalViewThresAdjustByDensityConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GlobalViewThresAdjustByDensityConfig.
                     * @implements IGlobalViewThresAdjustByDensityConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IGlobalViewThresAdjustByDensityConfig=} [properties] Properties to set
                     */
                    function GlobalViewThresAdjustByDensityConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GlobalViewThresAdjustByDensityConfig minNumPeopleToDecrease.
                     * @member {number} minNumPeopleToDecrease
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @instance
                     */
                    GlobalViewThresAdjustByDensityConfig.prototype.minNumPeopleToDecrease = 0;
    
                    /**
                     * GlobalViewThresAdjustByDensityConfig maxNumPeopleToStopDecrease.
                     * @member {number} maxNumPeopleToStopDecrease
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @instance
                     */
                    GlobalViewThresAdjustByDensityConfig.prototype.maxNumPeopleToStopDecrease = 0;
    
                    /**
                     * GlobalViewThresAdjustByDensityConfig minimumTimeDistance.
                     * @member {number} minimumTimeDistance
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @instance
                     */
                    GlobalViewThresAdjustByDensityConfig.prototype.minimumTimeDistance = 0;
    
                    /**
                     * GlobalViewThresAdjustByDensityConfig minimumFeatureThreshold.
                     * @member {number} minimumFeatureThreshold
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @instance
                     */
                    GlobalViewThresAdjustByDensityConfig.prototype.minimumFeatureThreshold = 0;
    
                    /**
                     * Creates a new GlobalViewThresAdjustByDensityConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.IGlobalViewThresAdjustByDensityConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig} GlobalViewThresAdjustByDensityConfig instance
                     */
                    GlobalViewThresAdjustByDensityConfig.create = function create(properties) {
                        return new GlobalViewThresAdjustByDensityConfig(properties);
                    };
    
                    /**
                     * Encodes the specified GlobalViewThresAdjustByDensityConfig message. Does not implicitly {@link palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.IGlobalViewThresAdjustByDensityConfig} message GlobalViewThresAdjustByDensityConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GlobalViewThresAdjustByDensityConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minNumPeopleToDecrease != null && Object.hasOwnProperty.call(message, "minNumPeopleToDecrease"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minNumPeopleToDecrease);
                        if (message.maxNumPeopleToStopDecrease != null && Object.hasOwnProperty.call(message, "maxNumPeopleToStopDecrease"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxNumPeopleToStopDecrease);
                        if (message.minimumTimeDistance != null && Object.hasOwnProperty.call(message, "minimumTimeDistance"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.minimumTimeDistance);
                        if (message.minimumFeatureThreshold != null && Object.hasOwnProperty.call(message, "minimumFeatureThreshold"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.minimumFeatureThreshold);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GlobalViewThresAdjustByDensityConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.IGlobalViewThresAdjustByDensityConfig} message GlobalViewThresAdjustByDensityConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GlobalViewThresAdjustByDensityConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GlobalViewThresAdjustByDensityConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig} GlobalViewThresAdjustByDensityConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GlobalViewThresAdjustByDensityConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.minNumPeopleToDecrease = reader.int32();
                                break;
                            case 2:
                                message.maxNumPeopleToStopDecrease = reader.int32();
                                break;
                            case 3:
                                message.minimumTimeDistance = reader.float();
                                break;
                            case 4:
                                message.minimumFeatureThreshold = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GlobalViewThresAdjustByDensityConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig} GlobalViewThresAdjustByDensityConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GlobalViewThresAdjustByDensityConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GlobalViewThresAdjustByDensityConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GlobalViewThresAdjustByDensityConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minNumPeopleToDecrease != null && message.hasOwnProperty("minNumPeopleToDecrease"))
                            if (!$util.isInteger(message.minNumPeopleToDecrease))
                                return "minNumPeopleToDecrease: integer expected";
                        if (message.maxNumPeopleToStopDecrease != null && message.hasOwnProperty("maxNumPeopleToStopDecrease"))
                            if (!$util.isInteger(message.maxNumPeopleToStopDecrease))
                                return "maxNumPeopleToStopDecrease: integer expected";
                        if (message.minimumTimeDistance != null && message.hasOwnProperty("minimumTimeDistance"))
                            if (typeof message.minimumTimeDistance !== "number")
                                return "minimumTimeDistance: number expected";
                        if (message.minimumFeatureThreshold != null && message.hasOwnProperty("minimumFeatureThreshold"))
                            if (typeof message.minimumFeatureThreshold !== "number")
                                return "minimumFeatureThreshold: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a GlobalViewThresAdjustByDensityConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig} GlobalViewThresAdjustByDensityConfig
                     */
                    GlobalViewThresAdjustByDensityConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig();
                        if (object.minNumPeopleToDecrease != null)
                            message.minNumPeopleToDecrease = object.minNumPeopleToDecrease | 0;
                        if (object.maxNumPeopleToStopDecrease != null)
                            message.maxNumPeopleToStopDecrease = object.maxNumPeopleToStopDecrease | 0;
                        if (object.minimumTimeDistance != null)
                            message.minimumTimeDistance = Number(object.minimumTimeDistance);
                        if (object.minimumFeatureThreshold != null)
                            message.minimumFeatureThreshold = Number(object.minimumFeatureThreshold);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GlobalViewThresAdjustByDensityConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig} message GlobalViewThresAdjustByDensityConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GlobalViewThresAdjustByDensityConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minNumPeopleToDecrease = 0;
                            object.maxNumPeopleToStopDecrease = 0;
                            object.minimumTimeDistance = 0;
                            object.minimumFeatureThreshold = 0;
                        }
                        if (message.minNumPeopleToDecrease != null && message.hasOwnProperty("minNumPeopleToDecrease"))
                            object.minNumPeopleToDecrease = message.minNumPeopleToDecrease;
                        if (message.maxNumPeopleToStopDecrease != null && message.hasOwnProperty("maxNumPeopleToStopDecrease"))
                            object.maxNumPeopleToStopDecrease = message.maxNumPeopleToStopDecrease;
                        if (message.minimumTimeDistance != null && message.hasOwnProperty("minimumTimeDistance"))
                            object.minimumTimeDistance = options.json && !isFinite(message.minimumTimeDistance) ? String(message.minimumTimeDistance) : message.minimumTimeDistance;
                        if (message.minimumFeatureThreshold != null && message.hasOwnProperty("minimumFeatureThreshold"))
                            object.minimumFeatureThreshold = options.json && !isFinite(message.minimumFeatureThreshold) ? String(message.minimumFeatureThreshold) : message.minimumFeatureThreshold;
                        return object;
                    };
    
                    /**
                     * Converts this GlobalViewThresAdjustByDensityConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GlobalViewThresAdjustByDensityConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GlobalViewThresAdjustByDensityConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GlobalViewThresAdjustByDensityConfig;
                })();
    
                v1.CrossCameraThresAdjustByDensityConfig = (function() {
    
                    /**
                     * Properties of a CrossCameraThresAdjustByDensityConfig.
                     * @memberof palexy.streaming.v1
                     * @interface ICrossCameraThresAdjustByDensityConfig
                     * @property {number|null} [minNumPeopleToDecrease] CrossCameraThresAdjustByDensityConfig minNumPeopleToDecrease
                     * @property {number|null} [maxNumPeopleToStopDecrease] CrossCameraThresAdjustByDensityConfig maxNumPeopleToStopDecrease
                     * @property {number|null} [minimumThreshold] CrossCameraThresAdjustByDensityConfig minimumThreshold
                     */
    
                    /**
                     * Constructs a new CrossCameraThresAdjustByDensityConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CrossCameraThresAdjustByDensityConfig.
                     * @implements ICrossCameraThresAdjustByDensityConfig
                     * @constructor
                     * @param {palexy.streaming.v1.ICrossCameraThresAdjustByDensityConfig=} [properties] Properties to set
                     */
                    function CrossCameraThresAdjustByDensityConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CrossCameraThresAdjustByDensityConfig minNumPeopleToDecrease.
                     * @member {number} minNumPeopleToDecrease
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @instance
                     */
                    CrossCameraThresAdjustByDensityConfig.prototype.minNumPeopleToDecrease = 0;
    
                    /**
                     * CrossCameraThresAdjustByDensityConfig maxNumPeopleToStopDecrease.
                     * @member {number} maxNumPeopleToStopDecrease
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @instance
                     */
                    CrossCameraThresAdjustByDensityConfig.prototype.maxNumPeopleToStopDecrease = 0;
    
                    /**
                     * CrossCameraThresAdjustByDensityConfig minimumThreshold.
                     * @member {number} minimumThreshold
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @instance
                     */
                    CrossCameraThresAdjustByDensityConfig.prototype.minimumThreshold = 0;
    
                    /**
                     * Creates a new CrossCameraThresAdjustByDensityConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.ICrossCameraThresAdjustByDensityConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig} CrossCameraThresAdjustByDensityConfig instance
                     */
                    CrossCameraThresAdjustByDensityConfig.create = function create(properties) {
                        return new CrossCameraThresAdjustByDensityConfig(properties);
                    };
    
                    /**
                     * Encodes the specified CrossCameraThresAdjustByDensityConfig message. Does not implicitly {@link palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.ICrossCameraThresAdjustByDensityConfig} message CrossCameraThresAdjustByDensityConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CrossCameraThresAdjustByDensityConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minNumPeopleToDecrease != null && Object.hasOwnProperty.call(message, "minNumPeopleToDecrease"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minNumPeopleToDecrease);
                        if (message.maxNumPeopleToStopDecrease != null && Object.hasOwnProperty.call(message, "maxNumPeopleToStopDecrease"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxNumPeopleToStopDecrease);
                        if (message.minimumThreshold != null && Object.hasOwnProperty.call(message, "minimumThreshold"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.minimumThreshold);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CrossCameraThresAdjustByDensityConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.ICrossCameraThresAdjustByDensityConfig} message CrossCameraThresAdjustByDensityConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CrossCameraThresAdjustByDensityConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CrossCameraThresAdjustByDensityConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig} CrossCameraThresAdjustByDensityConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CrossCameraThresAdjustByDensityConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.minNumPeopleToDecrease = reader.int32();
                                break;
                            case 2:
                                message.maxNumPeopleToStopDecrease = reader.int32();
                                break;
                            case 3:
                                message.minimumThreshold = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CrossCameraThresAdjustByDensityConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig} CrossCameraThresAdjustByDensityConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CrossCameraThresAdjustByDensityConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CrossCameraThresAdjustByDensityConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CrossCameraThresAdjustByDensityConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minNumPeopleToDecrease != null && message.hasOwnProperty("minNumPeopleToDecrease"))
                            if (!$util.isInteger(message.minNumPeopleToDecrease))
                                return "minNumPeopleToDecrease: integer expected";
                        if (message.maxNumPeopleToStopDecrease != null && message.hasOwnProperty("maxNumPeopleToStopDecrease"))
                            if (!$util.isInteger(message.maxNumPeopleToStopDecrease))
                                return "maxNumPeopleToStopDecrease: integer expected";
                        if (message.minimumThreshold != null && message.hasOwnProperty("minimumThreshold"))
                            if (typeof message.minimumThreshold !== "number")
                                return "minimumThreshold: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a CrossCameraThresAdjustByDensityConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig} CrossCameraThresAdjustByDensityConfig
                     */
                    CrossCameraThresAdjustByDensityConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig();
                        if (object.minNumPeopleToDecrease != null)
                            message.minNumPeopleToDecrease = object.minNumPeopleToDecrease | 0;
                        if (object.maxNumPeopleToStopDecrease != null)
                            message.maxNumPeopleToStopDecrease = object.maxNumPeopleToStopDecrease | 0;
                        if (object.minimumThreshold != null)
                            message.minimumThreshold = Number(object.minimumThreshold);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CrossCameraThresAdjustByDensityConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig} message CrossCameraThresAdjustByDensityConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CrossCameraThresAdjustByDensityConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minNumPeopleToDecrease = 0;
                            object.maxNumPeopleToStopDecrease = 0;
                            object.minimumThreshold = 0;
                        }
                        if (message.minNumPeopleToDecrease != null && message.hasOwnProperty("minNumPeopleToDecrease"))
                            object.minNumPeopleToDecrease = message.minNumPeopleToDecrease;
                        if (message.maxNumPeopleToStopDecrease != null && message.hasOwnProperty("maxNumPeopleToStopDecrease"))
                            object.maxNumPeopleToStopDecrease = message.maxNumPeopleToStopDecrease;
                        if (message.minimumThreshold != null && message.hasOwnProperty("minimumThreshold"))
                            object.minimumThreshold = options.json && !isFinite(message.minimumThreshold) ? String(message.minimumThreshold) : message.minimumThreshold;
                        return object;
                    };
    
                    /**
                     * Converts this CrossCameraThresAdjustByDensityConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CrossCameraThresAdjustByDensityConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CrossCameraThresAdjustByDensityConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CrossCameraThresAdjustByDensityConfig;
                })();
    
                v1.SingleCameraThresAdjustByDensityConfig = (function() {
    
                    /**
                     * Properties of a SingleCameraThresAdjustByDensityConfig.
                     * @memberof palexy.streaming.v1
                     * @interface ISingleCameraThresAdjustByDensityConfig
                     * @property {number|null} [minNumPeopleToDecrease] SingleCameraThresAdjustByDensityConfig minNumPeopleToDecrease
                     * @property {number|null} [maxNumPeopleToStopDecrease] SingleCameraThresAdjustByDensityConfig maxNumPeopleToStopDecrease
                     * @property {number|null} [minimumThreshold] SingleCameraThresAdjustByDensityConfig minimumThreshold
                     */
    
                    /**
                     * Constructs a new SingleCameraThresAdjustByDensityConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SingleCameraThresAdjustByDensityConfig.
                     * @implements ISingleCameraThresAdjustByDensityConfig
                     * @constructor
                     * @param {palexy.streaming.v1.ISingleCameraThresAdjustByDensityConfig=} [properties] Properties to set
                     */
                    function SingleCameraThresAdjustByDensityConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SingleCameraThresAdjustByDensityConfig minNumPeopleToDecrease.
                     * @member {number} minNumPeopleToDecrease
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @instance
                     */
                    SingleCameraThresAdjustByDensityConfig.prototype.minNumPeopleToDecrease = 0;
    
                    /**
                     * SingleCameraThresAdjustByDensityConfig maxNumPeopleToStopDecrease.
                     * @member {number} maxNumPeopleToStopDecrease
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @instance
                     */
                    SingleCameraThresAdjustByDensityConfig.prototype.maxNumPeopleToStopDecrease = 0;
    
                    /**
                     * SingleCameraThresAdjustByDensityConfig minimumThreshold.
                     * @member {number} minimumThreshold
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @instance
                     */
                    SingleCameraThresAdjustByDensityConfig.prototype.minimumThreshold = 0;
    
                    /**
                     * Creates a new SingleCameraThresAdjustByDensityConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.ISingleCameraThresAdjustByDensityConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig} SingleCameraThresAdjustByDensityConfig instance
                     */
                    SingleCameraThresAdjustByDensityConfig.create = function create(properties) {
                        return new SingleCameraThresAdjustByDensityConfig(properties);
                    };
    
                    /**
                     * Encodes the specified SingleCameraThresAdjustByDensityConfig message. Does not implicitly {@link palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.ISingleCameraThresAdjustByDensityConfig} message SingleCameraThresAdjustByDensityConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SingleCameraThresAdjustByDensityConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minNumPeopleToDecrease != null && Object.hasOwnProperty.call(message, "minNumPeopleToDecrease"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minNumPeopleToDecrease);
                        if (message.maxNumPeopleToStopDecrease != null && Object.hasOwnProperty.call(message, "maxNumPeopleToStopDecrease"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxNumPeopleToStopDecrease);
                        if (message.minimumThreshold != null && Object.hasOwnProperty.call(message, "minimumThreshold"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.minimumThreshold);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SingleCameraThresAdjustByDensityConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.ISingleCameraThresAdjustByDensityConfig} message SingleCameraThresAdjustByDensityConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SingleCameraThresAdjustByDensityConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SingleCameraThresAdjustByDensityConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig} SingleCameraThresAdjustByDensityConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SingleCameraThresAdjustByDensityConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.minNumPeopleToDecrease = reader.int32();
                                break;
                            case 2:
                                message.maxNumPeopleToStopDecrease = reader.int32();
                                break;
                            case 3:
                                message.minimumThreshold = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SingleCameraThresAdjustByDensityConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig} SingleCameraThresAdjustByDensityConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SingleCameraThresAdjustByDensityConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SingleCameraThresAdjustByDensityConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SingleCameraThresAdjustByDensityConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minNumPeopleToDecrease != null && message.hasOwnProperty("minNumPeopleToDecrease"))
                            if (!$util.isInteger(message.minNumPeopleToDecrease))
                                return "minNumPeopleToDecrease: integer expected";
                        if (message.maxNumPeopleToStopDecrease != null && message.hasOwnProperty("maxNumPeopleToStopDecrease"))
                            if (!$util.isInteger(message.maxNumPeopleToStopDecrease))
                                return "maxNumPeopleToStopDecrease: integer expected";
                        if (message.minimumThreshold != null && message.hasOwnProperty("minimumThreshold"))
                            if (typeof message.minimumThreshold !== "number")
                                return "minimumThreshold: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a SingleCameraThresAdjustByDensityConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig} SingleCameraThresAdjustByDensityConfig
                     */
                    SingleCameraThresAdjustByDensityConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig();
                        if (object.minNumPeopleToDecrease != null)
                            message.minNumPeopleToDecrease = object.minNumPeopleToDecrease | 0;
                        if (object.maxNumPeopleToStopDecrease != null)
                            message.maxNumPeopleToStopDecrease = object.maxNumPeopleToStopDecrease | 0;
                        if (object.minimumThreshold != null)
                            message.minimumThreshold = Number(object.minimumThreshold);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SingleCameraThresAdjustByDensityConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig} message SingleCameraThresAdjustByDensityConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SingleCameraThresAdjustByDensityConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minNumPeopleToDecrease = 0;
                            object.maxNumPeopleToStopDecrease = 0;
                            object.minimumThreshold = 0;
                        }
                        if (message.minNumPeopleToDecrease != null && message.hasOwnProperty("minNumPeopleToDecrease"))
                            object.minNumPeopleToDecrease = message.minNumPeopleToDecrease;
                        if (message.maxNumPeopleToStopDecrease != null && message.hasOwnProperty("maxNumPeopleToStopDecrease"))
                            object.maxNumPeopleToStopDecrease = message.maxNumPeopleToStopDecrease;
                        if (message.minimumThreshold != null && message.hasOwnProperty("minimumThreshold"))
                            object.minimumThreshold = options.json && !isFinite(message.minimumThreshold) ? String(message.minimumThreshold) : message.minimumThreshold;
                        return object;
                    };
    
                    /**
                     * Converts this SingleCameraThresAdjustByDensityConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SingleCameraThresAdjustByDensityConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SingleCameraThresAdjustByDensityConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SingleCameraThresAdjustByDensityConfig;
                })();
    
                v1.VeryCloseThresAdjustByDensityConfig = (function() {
    
                    /**
                     * Properties of a VeryCloseThresAdjustByDensityConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IVeryCloseThresAdjustByDensityConfig
                     * @property {number|null} [minNumPeopleToDecrease] VeryCloseThresAdjustByDensityConfig minNumPeopleToDecrease
                     * @property {number|null} [maxNumPeopleToStopDecrease] VeryCloseThresAdjustByDensityConfig maxNumPeopleToStopDecrease
                     * @property {number|null} [minimumThreshold] VeryCloseThresAdjustByDensityConfig minimumThreshold
                     */
    
                    /**
                     * Constructs a new VeryCloseThresAdjustByDensityConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a VeryCloseThresAdjustByDensityConfig.
                     * @implements IVeryCloseThresAdjustByDensityConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IVeryCloseThresAdjustByDensityConfig=} [properties] Properties to set
                     */
                    function VeryCloseThresAdjustByDensityConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * VeryCloseThresAdjustByDensityConfig minNumPeopleToDecrease.
                     * @member {number} minNumPeopleToDecrease
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @instance
                     */
                    VeryCloseThresAdjustByDensityConfig.prototype.minNumPeopleToDecrease = 0;
    
                    /**
                     * VeryCloseThresAdjustByDensityConfig maxNumPeopleToStopDecrease.
                     * @member {number} maxNumPeopleToStopDecrease
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @instance
                     */
                    VeryCloseThresAdjustByDensityConfig.prototype.maxNumPeopleToStopDecrease = 0;
    
                    /**
                     * VeryCloseThresAdjustByDensityConfig minimumThreshold.
                     * @member {number} minimumThreshold
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @instance
                     */
                    VeryCloseThresAdjustByDensityConfig.prototype.minimumThreshold = 0;
    
                    /**
                     * Creates a new VeryCloseThresAdjustByDensityConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.IVeryCloseThresAdjustByDensityConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig} VeryCloseThresAdjustByDensityConfig instance
                     */
                    VeryCloseThresAdjustByDensityConfig.create = function create(properties) {
                        return new VeryCloseThresAdjustByDensityConfig(properties);
                    };
    
                    /**
                     * Encodes the specified VeryCloseThresAdjustByDensityConfig message. Does not implicitly {@link palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.IVeryCloseThresAdjustByDensityConfig} message VeryCloseThresAdjustByDensityConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VeryCloseThresAdjustByDensityConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minNumPeopleToDecrease != null && Object.hasOwnProperty.call(message, "minNumPeopleToDecrease"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minNumPeopleToDecrease);
                        if (message.maxNumPeopleToStopDecrease != null && Object.hasOwnProperty.call(message, "maxNumPeopleToStopDecrease"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxNumPeopleToStopDecrease);
                        if (message.minimumThreshold != null && Object.hasOwnProperty.call(message, "minimumThreshold"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.minimumThreshold);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified VeryCloseThresAdjustByDensityConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.IVeryCloseThresAdjustByDensityConfig} message VeryCloseThresAdjustByDensityConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VeryCloseThresAdjustByDensityConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a VeryCloseThresAdjustByDensityConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig} VeryCloseThresAdjustByDensityConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VeryCloseThresAdjustByDensityConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.minNumPeopleToDecrease = reader.int32();
                                break;
                            case 2:
                                message.maxNumPeopleToStopDecrease = reader.int32();
                                break;
                            case 3:
                                message.minimumThreshold = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a VeryCloseThresAdjustByDensityConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig} VeryCloseThresAdjustByDensityConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VeryCloseThresAdjustByDensityConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a VeryCloseThresAdjustByDensityConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    VeryCloseThresAdjustByDensityConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minNumPeopleToDecrease != null && message.hasOwnProperty("minNumPeopleToDecrease"))
                            if (!$util.isInteger(message.minNumPeopleToDecrease))
                                return "minNumPeopleToDecrease: integer expected";
                        if (message.maxNumPeopleToStopDecrease != null && message.hasOwnProperty("maxNumPeopleToStopDecrease"))
                            if (!$util.isInteger(message.maxNumPeopleToStopDecrease))
                                return "maxNumPeopleToStopDecrease: integer expected";
                        if (message.minimumThreshold != null && message.hasOwnProperty("minimumThreshold"))
                            if (typeof message.minimumThreshold !== "number")
                                return "minimumThreshold: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a VeryCloseThresAdjustByDensityConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig} VeryCloseThresAdjustByDensityConfig
                     */
                    VeryCloseThresAdjustByDensityConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig();
                        if (object.minNumPeopleToDecrease != null)
                            message.minNumPeopleToDecrease = object.minNumPeopleToDecrease | 0;
                        if (object.maxNumPeopleToStopDecrease != null)
                            message.maxNumPeopleToStopDecrease = object.maxNumPeopleToStopDecrease | 0;
                        if (object.minimumThreshold != null)
                            message.minimumThreshold = Number(object.minimumThreshold);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a VeryCloseThresAdjustByDensityConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig} message VeryCloseThresAdjustByDensityConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    VeryCloseThresAdjustByDensityConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minNumPeopleToDecrease = 0;
                            object.maxNumPeopleToStopDecrease = 0;
                            object.minimumThreshold = 0;
                        }
                        if (message.minNumPeopleToDecrease != null && message.hasOwnProperty("minNumPeopleToDecrease"))
                            object.minNumPeopleToDecrease = message.minNumPeopleToDecrease;
                        if (message.maxNumPeopleToStopDecrease != null && message.hasOwnProperty("maxNumPeopleToStopDecrease"))
                            object.maxNumPeopleToStopDecrease = message.maxNumPeopleToStopDecrease;
                        if (message.minimumThreshold != null && message.hasOwnProperty("minimumThreshold"))
                            object.minimumThreshold = options.json && !isFinite(message.minimumThreshold) ? String(message.minimumThreshold) : message.minimumThreshold;
                        return object;
                    };
    
                    /**
                     * Converts this VeryCloseThresAdjustByDensityConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.VeryCloseThresAdjustByDensityConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    VeryCloseThresAdjustByDensityConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return VeryCloseThresAdjustByDensityConfig;
                })();
    
                v1.SuspectedStaffThresAdjustByDensityConfig = (function() {
    
                    /**
                     * Properties of a SuspectedStaffThresAdjustByDensityConfig.
                     * @memberof palexy.streaming.v1
                     * @interface ISuspectedStaffThresAdjustByDensityConfig
                     * @property {number|null} [minNumPeopleToDecrease] SuspectedStaffThresAdjustByDensityConfig minNumPeopleToDecrease
                     * @property {number|null} [maxNumPeopleToStopDecrease] SuspectedStaffThresAdjustByDensityConfig maxNumPeopleToStopDecrease
                     * @property {number|null} [minimumThreshold] SuspectedStaffThresAdjustByDensityConfig minimumThreshold
                     */
    
                    /**
                     * Constructs a new SuspectedStaffThresAdjustByDensityConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SuspectedStaffThresAdjustByDensityConfig.
                     * @implements ISuspectedStaffThresAdjustByDensityConfig
                     * @constructor
                     * @param {palexy.streaming.v1.ISuspectedStaffThresAdjustByDensityConfig=} [properties] Properties to set
                     */
                    function SuspectedStaffThresAdjustByDensityConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SuspectedStaffThresAdjustByDensityConfig minNumPeopleToDecrease.
                     * @member {number} minNumPeopleToDecrease
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @instance
                     */
                    SuspectedStaffThresAdjustByDensityConfig.prototype.minNumPeopleToDecrease = 0;
    
                    /**
                     * SuspectedStaffThresAdjustByDensityConfig maxNumPeopleToStopDecrease.
                     * @member {number} maxNumPeopleToStopDecrease
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @instance
                     */
                    SuspectedStaffThresAdjustByDensityConfig.prototype.maxNumPeopleToStopDecrease = 0;
    
                    /**
                     * SuspectedStaffThresAdjustByDensityConfig minimumThreshold.
                     * @member {number} minimumThreshold
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @instance
                     */
                    SuspectedStaffThresAdjustByDensityConfig.prototype.minimumThreshold = 0;
    
                    /**
                     * Creates a new SuspectedStaffThresAdjustByDensityConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.ISuspectedStaffThresAdjustByDensityConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig} SuspectedStaffThresAdjustByDensityConfig instance
                     */
                    SuspectedStaffThresAdjustByDensityConfig.create = function create(properties) {
                        return new SuspectedStaffThresAdjustByDensityConfig(properties);
                    };
    
                    /**
                     * Encodes the specified SuspectedStaffThresAdjustByDensityConfig message. Does not implicitly {@link palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.ISuspectedStaffThresAdjustByDensityConfig} message SuspectedStaffThresAdjustByDensityConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SuspectedStaffThresAdjustByDensityConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minNumPeopleToDecrease != null && Object.hasOwnProperty.call(message, "minNumPeopleToDecrease"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minNumPeopleToDecrease);
                        if (message.maxNumPeopleToStopDecrease != null && Object.hasOwnProperty.call(message, "maxNumPeopleToStopDecrease"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxNumPeopleToStopDecrease);
                        if (message.minimumThreshold != null && Object.hasOwnProperty.call(message, "minimumThreshold"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.minimumThreshold);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SuspectedStaffThresAdjustByDensityConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.ISuspectedStaffThresAdjustByDensityConfig} message SuspectedStaffThresAdjustByDensityConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SuspectedStaffThresAdjustByDensityConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SuspectedStaffThresAdjustByDensityConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig} SuspectedStaffThresAdjustByDensityConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SuspectedStaffThresAdjustByDensityConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.minNumPeopleToDecrease = reader.int32();
                                break;
                            case 2:
                                message.maxNumPeopleToStopDecrease = reader.int32();
                                break;
                            case 3:
                                message.minimumThreshold = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SuspectedStaffThresAdjustByDensityConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig} SuspectedStaffThresAdjustByDensityConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SuspectedStaffThresAdjustByDensityConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SuspectedStaffThresAdjustByDensityConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SuspectedStaffThresAdjustByDensityConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minNumPeopleToDecrease != null && message.hasOwnProperty("minNumPeopleToDecrease"))
                            if (!$util.isInteger(message.minNumPeopleToDecrease))
                                return "minNumPeopleToDecrease: integer expected";
                        if (message.maxNumPeopleToStopDecrease != null && message.hasOwnProperty("maxNumPeopleToStopDecrease"))
                            if (!$util.isInteger(message.maxNumPeopleToStopDecrease))
                                return "maxNumPeopleToStopDecrease: integer expected";
                        if (message.minimumThreshold != null && message.hasOwnProperty("minimumThreshold"))
                            if (typeof message.minimumThreshold !== "number")
                                return "minimumThreshold: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a SuspectedStaffThresAdjustByDensityConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig} SuspectedStaffThresAdjustByDensityConfig
                     */
                    SuspectedStaffThresAdjustByDensityConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig();
                        if (object.minNumPeopleToDecrease != null)
                            message.minNumPeopleToDecrease = object.minNumPeopleToDecrease | 0;
                        if (object.maxNumPeopleToStopDecrease != null)
                            message.maxNumPeopleToStopDecrease = object.maxNumPeopleToStopDecrease | 0;
                        if (object.minimumThreshold != null)
                            message.minimumThreshold = Number(object.minimumThreshold);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SuspectedStaffThresAdjustByDensityConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @static
                     * @param {palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig} message SuspectedStaffThresAdjustByDensityConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SuspectedStaffThresAdjustByDensityConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minNumPeopleToDecrease = 0;
                            object.maxNumPeopleToStopDecrease = 0;
                            object.minimumThreshold = 0;
                        }
                        if (message.minNumPeopleToDecrease != null && message.hasOwnProperty("minNumPeopleToDecrease"))
                            object.minNumPeopleToDecrease = message.minNumPeopleToDecrease;
                        if (message.maxNumPeopleToStopDecrease != null && message.hasOwnProperty("maxNumPeopleToStopDecrease"))
                            object.maxNumPeopleToStopDecrease = message.maxNumPeopleToStopDecrease;
                        if (message.minimumThreshold != null && message.hasOwnProperty("minimumThreshold"))
                            object.minimumThreshold = options.json && !isFinite(message.minimumThreshold) ? String(message.minimumThreshold) : message.minimumThreshold;
                        return object;
                    };
    
                    /**
                     * Converts this SuspectedStaffThresAdjustByDensityConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SuspectedStaffThresAdjustByDensityConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SuspectedStaffThresAdjustByDensityConfig;
                })();
    
                v1.OutlierFilteringConfig = (function() {
    
                    /**
                     * Properties of an OutlierFilteringConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IOutlierFilteringConfig
                     * @property {number|null} [minDurationInSeconds] OutlierFilteringConfig minDurationInSeconds
                     * @property {number|null} [minBb] OutlierFilteringConfig minBb
                     * @property {boolean|null} [filterByEntranceCameras] OutlierFilteringConfig filterByEntranceCameras
                     * @property {number|null} [numEntranceCameras] OutlierFilteringConfig numEntranceCameras
                     * @property {boolean|null} [filterBySuspectedStaffs] OutlierFilteringConfig filterBySuspectedStaffs
                     * @property {number|null} [minStaffClusterSize] OutlierFilteringConfig minStaffClusterSize
                     * @property {boolean|null} [prefilterByDetectStaticObjects] OutlierFilteringConfig prefilterByDetectStaticObjects
                     * @property {number|null} [prefilterByDetectStaticObjectsMinNumBoundingBox] OutlierFilteringConfig prefilterByDetectStaticObjectsMinNumBoundingBox
                     * @property {boolean|null} [filterByEntranceMetadataAppearances] OutlierFilteringConfig filterByEntranceMetadataAppearances
                     * @property {number|null} [maxDurationInSeconds] OutlierFilteringConfig maxDurationInSeconds
                     * @property {number|null} [minStaffClusterDurationInSeconds] OutlierFilteringConfig minStaffClusterDurationInSeconds
                     * @property {boolean|null} [removePeopleAtStaffSection] OutlierFilteringConfig removePeopleAtStaffSection
                     * @property {boolean|null} [useStdDerivedFloorDistanceToFilter] OutlierFilteringConfig useStdDerivedFloorDistanceToFilter
                     * @property {boolean|null} [skipStdDerivedFloorDistanceToFilter] OutlierFilteringConfig skipStdDerivedFloorDistanceToFilter
                     * @property {boolean|null} [skipStdDerivedCameraDistanceToFilter] OutlierFilteringConfig skipStdDerivedCameraDistanceToFilter
                     * @property {boolean|null} [removePeopleStartingNotFromEntrance] OutlierFilteringConfig removePeopleStartingNotFromEntrance
                     * @property {palexy.streaming.v1.IStaticObjectFilteringConfig|null} [staticObjectFilterConfig] OutlierFilteringConfig staticObjectFilterConfig
                     * @property {number|null} [minAvgValidBbProb] OutlierFilteringConfig minAvgValidBbProb
                     * @property {boolean|null} [skipRemoveClustersWithLowAvgValidBbsProb] OutlierFilteringConfig skipRemoveClustersWithLowAvgValidBbsProb
                     * @property {number|null} [maxClusterCamerasToRemovePeopleAtStaffSections] OutlierFilteringConfig maxClusterCamerasToRemovePeopleAtStaffSections
                     * @property {number|null} [maxItemsAtStaffSections] OutlierFilteringConfig maxItemsAtStaffSections
                     * @property {number|null} [suspectedStaffFeatureDistanceThreshold] OutlierFilteringConfig suspectedStaffFeatureDistanceThreshold
                     * @property {palexy.streaming.v1.ISuspectedStaffThresAdjustByDensityConfig|null} [suspectedStaffThresAdjustByDensityConfig] OutlierFilteringConfig suspectedStaffThresAdjustByDensityConfig
                     * @property {number|null} [maxGapToAddDuration] OutlierFilteringConfig maxGapToAddDuration
                     * @property {boolean|null} [keepClusterWithConfidentBbProb] OutlierFilteringConfig keepClusterWithConfidentBbProb
                     * @property {boolean|null} [skipFilterByNumSecondsPerBb] OutlierFilteringConfig skipFilterByNumSecondsPerBb
                     * @property {boolean|null} [skipRemoveShortStayWhenMerge] OutlierFilteringConfig skipRemoveShortStayWhenMerge
                     */
    
                    /**
                     * Constructs a new OutlierFilteringConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an OutlierFilteringConfig.
                     * @implements IOutlierFilteringConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IOutlierFilteringConfig=} [properties] Properties to set
                     */
                    function OutlierFilteringConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * OutlierFilteringConfig minDurationInSeconds.
                     * @member {number} minDurationInSeconds
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.minDurationInSeconds = 0;
    
                    /**
                     * OutlierFilteringConfig minBb.
                     * @member {number} minBb
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.minBb = 0;
    
                    /**
                     * OutlierFilteringConfig filterByEntranceCameras.
                     * @member {boolean} filterByEntranceCameras
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.filterByEntranceCameras = false;
    
                    /**
                     * OutlierFilteringConfig numEntranceCameras.
                     * @member {number} numEntranceCameras
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.numEntranceCameras = 0;
    
                    /**
                     * OutlierFilteringConfig filterBySuspectedStaffs.
                     * @member {boolean} filterBySuspectedStaffs
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.filterBySuspectedStaffs = false;
    
                    /**
                     * OutlierFilteringConfig minStaffClusterSize.
                     * @member {number} minStaffClusterSize
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.minStaffClusterSize = 0;
    
                    /**
                     * OutlierFilteringConfig prefilterByDetectStaticObjects.
                     * @member {boolean} prefilterByDetectStaticObjects
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.prefilterByDetectStaticObjects = false;
    
                    /**
                     * OutlierFilteringConfig prefilterByDetectStaticObjectsMinNumBoundingBox.
                     * @member {number} prefilterByDetectStaticObjectsMinNumBoundingBox
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.prefilterByDetectStaticObjectsMinNumBoundingBox = 0;
    
                    /**
                     * OutlierFilteringConfig filterByEntranceMetadataAppearances.
                     * @member {boolean} filterByEntranceMetadataAppearances
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.filterByEntranceMetadataAppearances = false;
    
                    /**
                     * OutlierFilteringConfig maxDurationInSeconds.
                     * @member {number} maxDurationInSeconds
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.maxDurationInSeconds = 0;
    
                    /**
                     * OutlierFilteringConfig minStaffClusterDurationInSeconds.
                     * @member {number} minStaffClusterDurationInSeconds
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.minStaffClusterDurationInSeconds = 0;
    
                    /**
                     * OutlierFilteringConfig removePeopleAtStaffSection.
                     * @member {boolean} removePeopleAtStaffSection
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.removePeopleAtStaffSection = false;
    
                    /**
                     * OutlierFilteringConfig useStdDerivedFloorDistanceToFilter.
                     * @member {boolean} useStdDerivedFloorDistanceToFilter
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.useStdDerivedFloorDistanceToFilter = false;
    
                    /**
                     * OutlierFilteringConfig skipStdDerivedFloorDistanceToFilter.
                     * @member {boolean} skipStdDerivedFloorDistanceToFilter
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.skipStdDerivedFloorDistanceToFilter = false;
    
                    /**
                     * OutlierFilteringConfig skipStdDerivedCameraDistanceToFilter.
                     * @member {boolean} skipStdDerivedCameraDistanceToFilter
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.skipStdDerivedCameraDistanceToFilter = false;
    
                    /**
                     * OutlierFilteringConfig removePeopleStartingNotFromEntrance.
                     * @member {boolean} removePeopleStartingNotFromEntrance
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.removePeopleStartingNotFromEntrance = false;
    
                    /**
                     * OutlierFilteringConfig staticObjectFilterConfig.
                     * @member {palexy.streaming.v1.IStaticObjectFilteringConfig|null|undefined} staticObjectFilterConfig
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.staticObjectFilterConfig = null;
    
                    /**
                     * OutlierFilteringConfig minAvgValidBbProb.
                     * @member {number} minAvgValidBbProb
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.minAvgValidBbProb = 0;
    
                    /**
                     * OutlierFilteringConfig skipRemoveClustersWithLowAvgValidBbsProb.
                     * @member {boolean} skipRemoveClustersWithLowAvgValidBbsProb
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.skipRemoveClustersWithLowAvgValidBbsProb = false;
    
                    /**
                     * OutlierFilteringConfig maxClusterCamerasToRemovePeopleAtStaffSections.
                     * @member {number} maxClusterCamerasToRemovePeopleAtStaffSections
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.maxClusterCamerasToRemovePeopleAtStaffSections = 0;
    
                    /**
                     * OutlierFilteringConfig maxItemsAtStaffSections.
                     * @member {number} maxItemsAtStaffSections
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.maxItemsAtStaffSections = 0;
    
                    /**
                     * OutlierFilteringConfig suspectedStaffFeatureDistanceThreshold.
                     * @member {number} suspectedStaffFeatureDistanceThreshold
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.suspectedStaffFeatureDistanceThreshold = 0;
    
                    /**
                     * OutlierFilteringConfig suspectedStaffThresAdjustByDensityConfig.
                     * @member {palexy.streaming.v1.ISuspectedStaffThresAdjustByDensityConfig|null|undefined} suspectedStaffThresAdjustByDensityConfig
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.suspectedStaffThresAdjustByDensityConfig = null;
    
                    /**
                     * OutlierFilteringConfig maxGapToAddDuration.
                     * @member {number} maxGapToAddDuration
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.maxGapToAddDuration = 0;
    
                    /**
                     * OutlierFilteringConfig keepClusterWithConfidentBbProb.
                     * @member {boolean} keepClusterWithConfidentBbProb
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.keepClusterWithConfidentBbProb = false;
    
                    /**
                     * OutlierFilteringConfig skipFilterByNumSecondsPerBb.
                     * @member {boolean} skipFilterByNumSecondsPerBb
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.skipFilterByNumSecondsPerBb = false;
    
                    /**
                     * OutlierFilteringConfig skipRemoveShortStayWhenMerge.
                     * @member {boolean} skipRemoveShortStayWhenMerge
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     */
                    OutlierFilteringConfig.prototype.skipRemoveShortStayWhenMerge = false;
    
                    /**
                     * Creates a new OutlierFilteringConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @static
                     * @param {palexy.streaming.v1.IOutlierFilteringConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.OutlierFilteringConfig} OutlierFilteringConfig instance
                     */
                    OutlierFilteringConfig.create = function create(properties) {
                        return new OutlierFilteringConfig(properties);
                    };
    
                    /**
                     * Encodes the specified OutlierFilteringConfig message. Does not implicitly {@link palexy.streaming.v1.OutlierFilteringConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @static
                     * @param {palexy.streaming.v1.IOutlierFilteringConfig} message OutlierFilteringConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OutlierFilteringConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minDurationInSeconds != null && Object.hasOwnProperty.call(message, "minDurationInSeconds"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minDurationInSeconds);
                        if (message.minBb != null && Object.hasOwnProperty.call(message, "minBb"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minBb);
                        if (message.filterByEntranceCameras != null && Object.hasOwnProperty.call(message, "filterByEntranceCameras"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.filterByEntranceCameras);
                        if (message.numEntranceCameras != null && Object.hasOwnProperty.call(message, "numEntranceCameras"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.numEntranceCameras);
                        if (message.filterBySuspectedStaffs != null && Object.hasOwnProperty.call(message, "filterBySuspectedStaffs"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.filterBySuspectedStaffs);
                        if (message.minStaffClusterSize != null && Object.hasOwnProperty.call(message, "minStaffClusterSize"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.minStaffClusterSize);
                        if (message.prefilterByDetectStaticObjects != null && Object.hasOwnProperty.call(message, "prefilterByDetectStaticObjects"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.prefilterByDetectStaticObjects);
                        if (message.prefilterByDetectStaticObjectsMinNumBoundingBox != null && Object.hasOwnProperty.call(message, "prefilterByDetectStaticObjectsMinNumBoundingBox"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.prefilterByDetectStaticObjectsMinNumBoundingBox);
                        if (message.filterByEntranceMetadataAppearances != null && Object.hasOwnProperty.call(message, "filterByEntranceMetadataAppearances"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.filterByEntranceMetadataAppearances);
                        if (message.maxDurationInSeconds != null && Object.hasOwnProperty.call(message, "maxDurationInSeconds"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.maxDurationInSeconds);
                        if (message.minStaffClusterDurationInSeconds != null && Object.hasOwnProperty.call(message, "minStaffClusterDurationInSeconds"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.minStaffClusterDurationInSeconds);
                        if (message.removePeopleAtStaffSection != null && Object.hasOwnProperty.call(message, "removePeopleAtStaffSection"))
                            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.removePeopleAtStaffSection);
                        if (message.useStdDerivedFloorDistanceToFilter != null && Object.hasOwnProperty.call(message, "useStdDerivedFloorDistanceToFilter"))
                            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.useStdDerivedFloorDistanceToFilter);
                        if (message.skipStdDerivedFloorDistanceToFilter != null && Object.hasOwnProperty.call(message, "skipStdDerivedFloorDistanceToFilter"))
                            writer.uint32(/* id 14, wireType 0 =*/112).bool(message.skipStdDerivedFloorDistanceToFilter);
                        if (message.skipStdDerivedCameraDistanceToFilter != null && Object.hasOwnProperty.call(message, "skipStdDerivedCameraDistanceToFilter"))
                            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.skipStdDerivedCameraDistanceToFilter);
                        if (message.removePeopleStartingNotFromEntrance != null && Object.hasOwnProperty.call(message, "removePeopleStartingNotFromEntrance"))
                            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.removePeopleStartingNotFromEntrance);
                        if (message.staticObjectFilterConfig != null && Object.hasOwnProperty.call(message, "staticObjectFilterConfig"))
                            $root.palexy.streaming.v1.StaticObjectFilteringConfig.encode(message.staticObjectFilterConfig, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        if (message.minAvgValidBbProb != null && Object.hasOwnProperty.call(message, "minAvgValidBbProb"))
                            writer.uint32(/* id 18, wireType 5 =*/149).float(message.minAvgValidBbProb);
                        if (message.skipRemoveClustersWithLowAvgValidBbsProb != null && Object.hasOwnProperty.call(message, "skipRemoveClustersWithLowAvgValidBbsProb"))
                            writer.uint32(/* id 19, wireType 0 =*/152).bool(message.skipRemoveClustersWithLowAvgValidBbsProb);
                        if (message.maxClusterCamerasToRemovePeopleAtStaffSections != null && Object.hasOwnProperty.call(message, "maxClusterCamerasToRemovePeopleAtStaffSections"))
                            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.maxClusterCamerasToRemovePeopleAtStaffSections);
                        if (message.maxItemsAtStaffSections != null && Object.hasOwnProperty.call(message, "maxItemsAtStaffSections"))
                            writer.uint32(/* id 21, wireType 0 =*/168).int32(message.maxItemsAtStaffSections);
                        if (message.suspectedStaffFeatureDistanceThreshold != null && Object.hasOwnProperty.call(message, "suspectedStaffFeatureDistanceThreshold"))
                            writer.uint32(/* id 22, wireType 5 =*/181).float(message.suspectedStaffFeatureDistanceThreshold);
                        if (message.suspectedStaffThresAdjustByDensityConfig != null && Object.hasOwnProperty.call(message, "suspectedStaffThresAdjustByDensityConfig"))
                            $root.palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig.encode(message.suspectedStaffThresAdjustByDensityConfig, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                        if (message.maxGapToAddDuration != null && Object.hasOwnProperty.call(message, "maxGapToAddDuration"))
                            writer.uint32(/* id 24, wireType 0 =*/192).int32(message.maxGapToAddDuration);
                        if (message.keepClusterWithConfidentBbProb != null && Object.hasOwnProperty.call(message, "keepClusterWithConfidentBbProb"))
                            writer.uint32(/* id 25, wireType 0 =*/200).bool(message.keepClusterWithConfidentBbProb);
                        if (message.skipFilterByNumSecondsPerBb != null && Object.hasOwnProperty.call(message, "skipFilterByNumSecondsPerBb"))
                            writer.uint32(/* id 26, wireType 0 =*/208).bool(message.skipFilterByNumSecondsPerBb);
                        if (message.skipRemoveShortStayWhenMerge != null && Object.hasOwnProperty.call(message, "skipRemoveShortStayWhenMerge"))
                            writer.uint32(/* id 27, wireType 0 =*/216).bool(message.skipRemoveShortStayWhenMerge);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified OutlierFilteringConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.OutlierFilteringConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @static
                     * @param {palexy.streaming.v1.IOutlierFilteringConfig} message OutlierFilteringConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OutlierFilteringConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an OutlierFilteringConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.OutlierFilteringConfig} OutlierFilteringConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OutlierFilteringConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.OutlierFilteringConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.minDurationInSeconds = reader.int32();
                                break;
                            case 2:
                                message.minBb = reader.int32();
                                break;
                            case 3:
                                message.filterByEntranceCameras = reader.bool();
                                break;
                            case 4:
                                message.numEntranceCameras = reader.int32();
                                break;
                            case 5:
                                message.filterBySuspectedStaffs = reader.bool();
                                break;
                            case 6:
                                message.minStaffClusterSize = reader.int32();
                                break;
                            case 7:
                                message.prefilterByDetectStaticObjects = reader.bool();
                                break;
                            case 8:
                                message.prefilterByDetectStaticObjectsMinNumBoundingBox = reader.int32();
                                break;
                            case 9:
                                message.filterByEntranceMetadataAppearances = reader.bool();
                                break;
                            case 10:
                                message.maxDurationInSeconds = reader.int32();
                                break;
                            case 11:
                                message.minStaffClusterDurationInSeconds = reader.int32();
                                break;
                            case 12:
                                message.removePeopleAtStaffSection = reader.bool();
                                break;
                            case 13:
                                message.useStdDerivedFloorDistanceToFilter = reader.bool();
                                break;
                            case 14:
                                message.skipStdDerivedFloorDistanceToFilter = reader.bool();
                                break;
                            case 15:
                                message.skipStdDerivedCameraDistanceToFilter = reader.bool();
                                break;
                            case 16:
                                message.removePeopleStartingNotFromEntrance = reader.bool();
                                break;
                            case 17:
                                message.staticObjectFilterConfig = $root.palexy.streaming.v1.StaticObjectFilteringConfig.decode(reader, reader.uint32());
                                break;
                            case 18:
                                message.minAvgValidBbProb = reader.float();
                                break;
                            case 19:
                                message.skipRemoveClustersWithLowAvgValidBbsProb = reader.bool();
                                break;
                            case 20:
                                message.maxClusterCamerasToRemovePeopleAtStaffSections = reader.int32();
                                break;
                            case 21:
                                message.maxItemsAtStaffSections = reader.int32();
                                break;
                            case 22:
                                message.suspectedStaffFeatureDistanceThreshold = reader.float();
                                break;
                            case 23:
                                message.suspectedStaffThresAdjustByDensityConfig = $root.palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig.decode(reader, reader.uint32());
                                break;
                            case 24:
                                message.maxGapToAddDuration = reader.int32();
                                break;
                            case 25:
                                message.keepClusterWithConfidentBbProb = reader.bool();
                                break;
                            case 26:
                                message.skipFilterByNumSecondsPerBb = reader.bool();
                                break;
                            case 27:
                                message.skipRemoveShortStayWhenMerge = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an OutlierFilteringConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.OutlierFilteringConfig} OutlierFilteringConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OutlierFilteringConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an OutlierFilteringConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OutlierFilteringConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minDurationInSeconds != null && message.hasOwnProperty("minDurationInSeconds"))
                            if (!$util.isInteger(message.minDurationInSeconds))
                                return "minDurationInSeconds: integer expected";
                        if (message.minBb != null && message.hasOwnProperty("minBb"))
                            if (!$util.isInteger(message.minBb))
                                return "minBb: integer expected";
                        if (message.filterByEntranceCameras != null && message.hasOwnProperty("filterByEntranceCameras"))
                            if (typeof message.filterByEntranceCameras !== "boolean")
                                return "filterByEntranceCameras: boolean expected";
                        if (message.numEntranceCameras != null && message.hasOwnProperty("numEntranceCameras"))
                            if (!$util.isInteger(message.numEntranceCameras))
                                return "numEntranceCameras: integer expected";
                        if (message.filterBySuspectedStaffs != null && message.hasOwnProperty("filterBySuspectedStaffs"))
                            if (typeof message.filterBySuspectedStaffs !== "boolean")
                                return "filterBySuspectedStaffs: boolean expected";
                        if (message.minStaffClusterSize != null && message.hasOwnProperty("minStaffClusterSize"))
                            if (!$util.isInteger(message.minStaffClusterSize))
                                return "minStaffClusterSize: integer expected";
                        if (message.prefilterByDetectStaticObjects != null && message.hasOwnProperty("prefilterByDetectStaticObjects"))
                            if (typeof message.prefilterByDetectStaticObjects !== "boolean")
                                return "prefilterByDetectStaticObjects: boolean expected";
                        if (message.prefilterByDetectStaticObjectsMinNumBoundingBox != null && message.hasOwnProperty("prefilterByDetectStaticObjectsMinNumBoundingBox"))
                            if (!$util.isInteger(message.prefilterByDetectStaticObjectsMinNumBoundingBox))
                                return "prefilterByDetectStaticObjectsMinNumBoundingBox: integer expected";
                        if (message.filterByEntranceMetadataAppearances != null && message.hasOwnProperty("filterByEntranceMetadataAppearances"))
                            if (typeof message.filterByEntranceMetadataAppearances !== "boolean")
                                return "filterByEntranceMetadataAppearances: boolean expected";
                        if (message.maxDurationInSeconds != null && message.hasOwnProperty("maxDurationInSeconds"))
                            if (!$util.isInteger(message.maxDurationInSeconds))
                                return "maxDurationInSeconds: integer expected";
                        if (message.minStaffClusterDurationInSeconds != null && message.hasOwnProperty("minStaffClusterDurationInSeconds"))
                            if (!$util.isInteger(message.minStaffClusterDurationInSeconds))
                                return "minStaffClusterDurationInSeconds: integer expected";
                        if (message.removePeopleAtStaffSection != null && message.hasOwnProperty("removePeopleAtStaffSection"))
                            if (typeof message.removePeopleAtStaffSection !== "boolean")
                                return "removePeopleAtStaffSection: boolean expected";
                        if (message.useStdDerivedFloorDistanceToFilter != null && message.hasOwnProperty("useStdDerivedFloorDistanceToFilter"))
                            if (typeof message.useStdDerivedFloorDistanceToFilter !== "boolean")
                                return "useStdDerivedFloorDistanceToFilter: boolean expected";
                        if (message.skipStdDerivedFloorDistanceToFilter != null && message.hasOwnProperty("skipStdDerivedFloorDistanceToFilter"))
                            if (typeof message.skipStdDerivedFloorDistanceToFilter !== "boolean")
                                return "skipStdDerivedFloorDistanceToFilter: boolean expected";
                        if (message.skipStdDerivedCameraDistanceToFilter != null && message.hasOwnProperty("skipStdDerivedCameraDistanceToFilter"))
                            if (typeof message.skipStdDerivedCameraDistanceToFilter !== "boolean")
                                return "skipStdDerivedCameraDistanceToFilter: boolean expected";
                        if (message.removePeopleStartingNotFromEntrance != null && message.hasOwnProperty("removePeopleStartingNotFromEntrance"))
                            if (typeof message.removePeopleStartingNotFromEntrance !== "boolean")
                                return "removePeopleStartingNotFromEntrance: boolean expected";
                        if (message.staticObjectFilterConfig != null && message.hasOwnProperty("staticObjectFilterConfig")) {
                            var error = $root.palexy.streaming.v1.StaticObjectFilteringConfig.verify(message.staticObjectFilterConfig);
                            if (error)
                                return "staticObjectFilterConfig." + error;
                        }
                        if (message.minAvgValidBbProb != null && message.hasOwnProperty("minAvgValidBbProb"))
                            if (typeof message.minAvgValidBbProb !== "number")
                                return "minAvgValidBbProb: number expected";
                        if (message.skipRemoveClustersWithLowAvgValidBbsProb != null && message.hasOwnProperty("skipRemoveClustersWithLowAvgValidBbsProb"))
                            if (typeof message.skipRemoveClustersWithLowAvgValidBbsProb !== "boolean")
                                return "skipRemoveClustersWithLowAvgValidBbsProb: boolean expected";
                        if (message.maxClusterCamerasToRemovePeopleAtStaffSections != null && message.hasOwnProperty("maxClusterCamerasToRemovePeopleAtStaffSections"))
                            if (!$util.isInteger(message.maxClusterCamerasToRemovePeopleAtStaffSections))
                                return "maxClusterCamerasToRemovePeopleAtStaffSections: integer expected";
                        if (message.maxItemsAtStaffSections != null && message.hasOwnProperty("maxItemsAtStaffSections"))
                            if (!$util.isInteger(message.maxItemsAtStaffSections))
                                return "maxItemsAtStaffSections: integer expected";
                        if (message.suspectedStaffFeatureDistanceThreshold != null && message.hasOwnProperty("suspectedStaffFeatureDistanceThreshold"))
                            if (typeof message.suspectedStaffFeatureDistanceThreshold !== "number")
                                return "suspectedStaffFeatureDistanceThreshold: number expected";
                        if (message.suspectedStaffThresAdjustByDensityConfig != null && message.hasOwnProperty("suspectedStaffThresAdjustByDensityConfig")) {
                            var error = $root.palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig.verify(message.suspectedStaffThresAdjustByDensityConfig);
                            if (error)
                                return "suspectedStaffThresAdjustByDensityConfig." + error;
                        }
                        if (message.maxGapToAddDuration != null && message.hasOwnProperty("maxGapToAddDuration"))
                            if (!$util.isInteger(message.maxGapToAddDuration))
                                return "maxGapToAddDuration: integer expected";
                        if (message.keepClusterWithConfidentBbProb != null && message.hasOwnProperty("keepClusterWithConfidentBbProb"))
                            if (typeof message.keepClusterWithConfidentBbProb !== "boolean")
                                return "keepClusterWithConfidentBbProb: boolean expected";
                        if (message.skipFilterByNumSecondsPerBb != null && message.hasOwnProperty("skipFilterByNumSecondsPerBb"))
                            if (typeof message.skipFilterByNumSecondsPerBb !== "boolean")
                                return "skipFilterByNumSecondsPerBb: boolean expected";
                        if (message.skipRemoveShortStayWhenMerge != null && message.hasOwnProperty("skipRemoveShortStayWhenMerge"))
                            if (typeof message.skipRemoveShortStayWhenMerge !== "boolean")
                                return "skipRemoveShortStayWhenMerge: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates an OutlierFilteringConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.OutlierFilteringConfig} OutlierFilteringConfig
                     */
                    OutlierFilteringConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.OutlierFilteringConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.OutlierFilteringConfig();
                        if (object.minDurationInSeconds != null)
                            message.minDurationInSeconds = object.minDurationInSeconds | 0;
                        if (object.minBb != null)
                            message.minBb = object.minBb | 0;
                        if (object.filterByEntranceCameras != null)
                            message.filterByEntranceCameras = Boolean(object.filterByEntranceCameras);
                        if (object.numEntranceCameras != null)
                            message.numEntranceCameras = object.numEntranceCameras | 0;
                        if (object.filterBySuspectedStaffs != null)
                            message.filterBySuspectedStaffs = Boolean(object.filterBySuspectedStaffs);
                        if (object.minStaffClusterSize != null)
                            message.minStaffClusterSize = object.minStaffClusterSize | 0;
                        if (object.prefilterByDetectStaticObjects != null)
                            message.prefilterByDetectStaticObjects = Boolean(object.prefilterByDetectStaticObjects);
                        if (object.prefilterByDetectStaticObjectsMinNumBoundingBox != null)
                            message.prefilterByDetectStaticObjectsMinNumBoundingBox = object.prefilterByDetectStaticObjectsMinNumBoundingBox | 0;
                        if (object.filterByEntranceMetadataAppearances != null)
                            message.filterByEntranceMetadataAppearances = Boolean(object.filterByEntranceMetadataAppearances);
                        if (object.maxDurationInSeconds != null)
                            message.maxDurationInSeconds = object.maxDurationInSeconds | 0;
                        if (object.minStaffClusterDurationInSeconds != null)
                            message.minStaffClusterDurationInSeconds = object.minStaffClusterDurationInSeconds | 0;
                        if (object.removePeopleAtStaffSection != null)
                            message.removePeopleAtStaffSection = Boolean(object.removePeopleAtStaffSection);
                        if (object.useStdDerivedFloorDistanceToFilter != null)
                            message.useStdDerivedFloorDistanceToFilter = Boolean(object.useStdDerivedFloorDistanceToFilter);
                        if (object.skipStdDerivedFloorDistanceToFilter != null)
                            message.skipStdDerivedFloorDistanceToFilter = Boolean(object.skipStdDerivedFloorDistanceToFilter);
                        if (object.skipStdDerivedCameraDistanceToFilter != null)
                            message.skipStdDerivedCameraDistanceToFilter = Boolean(object.skipStdDerivedCameraDistanceToFilter);
                        if (object.removePeopleStartingNotFromEntrance != null)
                            message.removePeopleStartingNotFromEntrance = Boolean(object.removePeopleStartingNotFromEntrance);
                        if (object.staticObjectFilterConfig != null) {
                            if (typeof object.staticObjectFilterConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.OutlierFilteringConfig.staticObjectFilterConfig: object expected");
                            message.staticObjectFilterConfig = $root.palexy.streaming.v1.StaticObjectFilteringConfig.fromObject(object.staticObjectFilterConfig);
                        }
                        if (object.minAvgValidBbProb != null)
                            message.minAvgValidBbProb = Number(object.minAvgValidBbProb);
                        if (object.skipRemoveClustersWithLowAvgValidBbsProb != null)
                            message.skipRemoveClustersWithLowAvgValidBbsProb = Boolean(object.skipRemoveClustersWithLowAvgValidBbsProb);
                        if (object.maxClusterCamerasToRemovePeopleAtStaffSections != null)
                            message.maxClusterCamerasToRemovePeopleAtStaffSections = object.maxClusterCamerasToRemovePeopleAtStaffSections | 0;
                        if (object.maxItemsAtStaffSections != null)
                            message.maxItemsAtStaffSections = object.maxItemsAtStaffSections | 0;
                        if (object.suspectedStaffFeatureDistanceThreshold != null)
                            message.suspectedStaffFeatureDistanceThreshold = Number(object.suspectedStaffFeatureDistanceThreshold);
                        if (object.suspectedStaffThresAdjustByDensityConfig != null) {
                            if (typeof object.suspectedStaffThresAdjustByDensityConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.OutlierFilteringConfig.suspectedStaffThresAdjustByDensityConfig: object expected");
                            message.suspectedStaffThresAdjustByDensityConfig = $root.palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig.fromObject(object.suspectedStaffThresAdjustByDensityConfig);
                        }
                        if (object.maxGapToAddDuration != null)
                            message.maxGapToAddDuration = object.maxGapToAddDuration | 0;
                        if (object.keepClusterWithConfidentBbProb != null)
                            message.keepClusterWithConfidentBbProb = Boolean(object.keepClusterWithConfidentBbProb);
                        if (object.skipFilterByNumSecondsPerBb != null)
                            message.skipFilterByNumSecondsPerBb = Boolean(object.skipFilterByNumSecondsPerBb);
                        if (object.skipRemoveShortStayWhenMerge != null)
                            message.skipRemoveShortStayWhenMerge = Boolean(object.skipRemoveShortStayWhenMerge);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an OutlierFilteringConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @static
                     * @param {palexy.streaming.v1.OutlierFilteringConfig} message OutlierFilteringConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OutlierFilteringConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minDurationInSeconds = 0;
                            object.minBb = 0;
                            object.filterByEntranceCameras = false;
                            object.numEntranceCameras = 0;
                            object.filterBySuspectedStaffs = false;
                            object.minStaffClusterSize = 0;
                            object.prefilterByDetectStaticObjects = false;
                            object.prefilterByDetectStaticObjectsMinNumBoundingBox = 0;
                            object.filterByEntranceMetadataAppearances = false;
                            object.maxDurationInSeconds = 0;
                            object.minStaffClusterDurationInSeconds = 0;
                            object.removePeopleAtStaffSection = false;
                            object.useStdDerivedFloorDistanceToFilter = false;
                            object.skipStdDerivedFloorDistanceToFilter = false;
                            object.skipStdDerivedCameraDistanceToFilter = false;
                            object.removePeopleStartingNotFromEntrance = false;
                            object.staticObjectFilterConfig = null;
                            object.minAvgValidBbProb = 0;
                            object.skipRemoveClustersWithLowAvgValidBbsProb = false;
                            object.maxClusterCamerasToRemovePeopleAtStaffSections = 0;
                            object.maxItemsAtStaffSections = 0;
                            object.suspectedStaffFeatureDistanceThreshold = 0;
                            object.suspectedStaffThresAdjustByDensityConfig = null;
                            object.maxGapToAddDuration = 0;
                            object.keepClusterWithConfidentBbProb = false;
                            object.skipFilterByNumSecondsPerBb = false;
                            object.skipRemoveShortStayWhenMerge = false;
                        }
                        if (message.minDurationInSeconds != null && message.hasOwnProperty("minDurationInSeconds"))
                            object.minDurationInSeconds = message.minDurationInSeconds;
                        if (message.minBb != null && message.hasOwnProperty("minBb"))
                            object.minBb = message.minBb;
                        if (message.filterByEntranceCameras != null && message.hasOwnProperty("filterByEntranceCameras"))
                            object.filterByEntranceCameras = message.filterByEntranceCameras;
                        if (message.numEntranceCameras != null && message.hasOwnProperty("numEntranceCameras"))
                            object.numEntranceCameras = message.numEntranceCameras;
                        if (message.filterBySuspectedStaffs != null && message.hasOwnProperty("filterBySuspectedStaffs"))
                            object.filterBySuspectedStaffs = message.filterBySuspectedStaffs;
                        if (message.minStaffClusterSize != null && message.hasOwnProperty("minStaffClusterSize"))
                            object.minStaffClusterSize = message.minStaffClusterSize;
                        if (message.prefilterByDetectStaticObjects != null && message.hasOwnProperty("prefilterByDetectStaticObjects"))
                            object.prefilterByDetectStaticObjects = message.prefilterByDetectStaticObjects;
                        if (message.prefilterByDetectStaticObjectsMinNumBoundingBox != null && message.hasOwnProperty("prefilterByDetectStaticObjectsMinNumBoundingBox"))
                            object.prefilterByDetectStaticObjectsMinNumBoundingBox = message.prefilterByDetectStaticObjectsMinNumBoundingBox;
                        if (message.filterByEntranceMetadataAppearances != null && message.hasOwnProperty("filterByEntranceMetadataAppearances"))
                            object.filterByEntranceMetadataAppearances = message.filterByEntranceMetadataAppearances;
                        if (message.maxDurationInSeconds != null && message.hasOwnProperty("maxDurationInSeconds"))
                            object.maxDurationInSeconds = message.maxDurationInSeconds;
                        if (message.minStaffClusterDurationInSeconds != null && message.hasOwnProperty("minStaffClusterDurationInSeconds"))
                            object.minStaffClusterDurationInSeconds = message.minStaffClusterDurationInSeconds;
                        if (message.removePeopleAtStaffSection != null && message.hasOwnProperty("removePeopleAtStaffSection"))
                            object.removePeopleAtStaffSection = message.removePeopleAtStaffSection;
                        if (message.useStdDerivedFloorDistanceToFilter != null && message.hasOwnProperty("useStdDerivedFloorDistanceToFilter"))
                            object.useStdDerivedFloorDistanceToFilter = message.useStdDerivedFloorDistanceToFilter;
                        if (message.skipStdDerivedFloorDistanceToFilter != null && message.hasOwnProperty("skipStdDerivedFloorDistanceToFilter"))
                            object.skipStdDerivedFloorDistanceToFilter = message.skipStdDerivedFloorDistanceToFilter;
                        if (message.skipStdDerivedCameraDistanceToFilter != null && message.hasOwnProperty("skipStdDerivedCameraDistanceToFilter"))
                            object.skipStdDerivedCameraDistanceToFilter = message.skipStdDerivedCameraDistanceToFilter;
                        if (message.removePeopleStartingNotFromEntrance != null && message.hasOwnProperty("removePeopleStartingNotFromEntrance"))
                            object.removePeopleStartingNotFromEntrance = message.removePeopleStartingNotFromEntrance;
                        if (message.staticObjectFilterConfig != null && message.hasOwnProperty("staticObjectFilterConfig"))
                            object.staticObjectFilterConfig = $root.palexy.streaming.v1.StaticObjectFilteringConfig.toObject(message.staticObjectFilterConfig, options);
                        if (message.minAvgValidBbProb != null && message.hasOwnProperty("minAvgValidBbProb"))
                            object.minAvgValidBbProb = options.json && !isFinite(message.minAvgValidBbProb) ? String(message.minAvgValidBbProb) : message.minAvgValidBbProb;
                        if (message.skipRemoveClustersWithLowAvgValidBbsProb != null && message.hasOwnProperty("skipRemoveClustersWithLowAvgValidBbsProb"))
                            object.skipRemoveClustersWithLowAvgValidBbsProb = message.skipRemoveClustersWithLowAvgValidBbsProb;
                        if (message.maxClusterCamerasToRemovePeopleAtStaffSections != null && message.hasOwnProperty("maxClusterCamerasToRemovePeopleAtStaffSections"))
                            object.maxClusterCamerasToRemovePeopleAtStaffSections = message.maxClusterCamerasToRemovePeopleAtStaffSections;
                        if (message.maxItemsAtStaffSections != null && message.hasOwnProperty("maxItemsAtStaffSections"))
                            object.maxItemsAtStaffSections = message.maxItemsAtStaffSections;
                        if (message.suspectedStaffFeatureDistanceThreshold != null && message.hasOwnProperty("suspectedStaffFeatureDistanceThreshold"))
                            object.suspectedStaffFeatureDistanceThreshold = options.json && !isFinite(message.suspectedStaffFeatureDistanceThreshold) ? String(message.suspectedStaffFeatureDistanceThreshold) : message.suspectedStaffFeatureDistanceThreshold;
                        if (message.suspectedStaffThresAdjustByDensityConfig != null && message.hasOwnProperty("suspectedStaffThresAdjustByDensityConfig"))
                            object.suspectedStaffThresAdjustByDensityConfig = $root.palexy.streaming.v1.SuspectedStaffThresAdjustByDensityConfig.toObject(message.suspectedStaffThresAdjustByDensityConfig, options);
                        if (message.maxGapToAddDuration != null && message.hasOwnProperty("maxGapToAddDuration"))
                            object.maxGapToAddDuration = message.maxGapToAddDuration;
                        if (message.keepClusterWithConfidentBbProb != null && message.hasOwnProperty("keepClusterWithConfidentBbProb"))
                            object.keepClusterWithConfidentBbProb = message.keepClusterWithConfidentBbProb;
                        if (message.skipFilterByNumSecondsPerBb != null && message.hasOwnProperty("skipFilterByNumSecondsPerBb"))
                            object.skipFilterByNumSecondsPerBb = message.skipFilterByNumSecondsPerBb;
                        if (message.skipRemoveShortStayWhenMerge != null && message.hasOwnProperty("skipRemoveShortStayWhenMerge"))
                            object.skipRemoveShortStayWhenMerge = message.skipRemoveShortStayWhenMerge;
                        return object;
                    };
    
                    /**
                     * Converts this OutlierFilteringConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.OutlierFilteringConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OutlierFilteringConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return OutlierFilteringConfig;
                })();
    
                /**
                 * SystemTypeName enum.
                 * @name palexy.streaming.v1.SystemTypeName
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} LYING=1 LYING value
                 * @property {number} ENTRANCE=2 ENTRANCE value
                 * @property {number} SUPERMARKET_ENTRANCE=3 SUPERMARKET_ENTRANCE value
                 * @property {number} STAFF=4 STAFF value
                 * @property {number} CASHIER=5 CASHIER value
                 * @property {number} CHANGING_ROOM=6 CHANGING_ROOM value
                 * @property {number} EXCLUDE=7 EXCLUDE value
                 * @property {number} ONLY_INCLUDE=8 ONLY_INCLUDE value
                 * @property {number} TRANSACTION=9 TRANSACTION value
                 * @property {number} VISITOR_WAITING_AREA=10 VISITOR_WAITING_AREA value
                 * @property {number} VISITOR_CHANGING_AREA=11 VISITOR_CHANGING_AREA value
                 * @property {number} VISITOR_MUST_APPEAR=12 VISITOR_MUST_APPEAR value
                 * @property {number} KEEP_TRACKLET_AREA=13 KEEP_TRACKLET_AREA value
                 * @property {number} SECTION_TESTING_AREA=14 SECTION_TESTING_AREA value
                 * @property {number} EXCLUDE_INTERACTION=15 EXCLUDE_INTERACTION value
                 * @property {number} PASS_BY_AREA=16 PASS_BY_AREA value
                 * @property {number} REMOVE_TRACKLET_AREA=17 REMOVE_TRACKLET_AREA value
                 * @property {number} WIDE_SPACE_ENTRANCE=18 WIDE_SPACE_ENTRANCE value
                 * @property {number} MALL_ENTRANCE=19 MALL_ENTRANCE value
                 * @property {number} REMOVE_VISITOR_STARTING_AREA=20 REMOVE_VISITOR_STARTING_AREA value
                 * @property {number} REMOVE_VISITOR_APPEAR_DISAPPEAR_AREA=21 REMOVE_VISITOR_APPEAR_DISAPPEAR_AREA value
                 * @property {number} HS_TAG=22 HS_TAG value
                 * @property {number} STAFF_REQUIRED_AREA=23 STAFF_REQUIRED_AREA value
                 * @property {number} SPECIAL_AREA=24 SPECIAL_AREA value
                 * @property {number} MUST_PASS=25 MUST_PASS value
                 */
                v1.SystemTypeName = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "LYING"] = 1;
                    values[valuesById[2] = "ENTRANCE"] = 2;
                    values[valuesById[3] = "SUPERMARKET_ENTRANCE"] = 3;
                    values[valuesById[4] = "STAFF"] = 4;
                    values[valuesById[5] = "CASHIER"] = 5;
                    values[valuesById[6] = "CHANGING_ROOM"] = 6;
                    values[valuesById[7] = "EXCLUDE"] = 7;
                    values[valuesById[8] = "ONLY_INCLUDE"] = 8;
                    values[valuesById[9] = "TRANSACTION"] = 9;
                    values[valuesById[10] = "VISITOR_WAITING_AREA"] = 10;
                    values[valuesById[11] = "VISITOR_CHANGING_AREA"] = 11;
                    values[valuesById[12] = "VISITOR_MUST_APPEAR"] = 12;
                    values[valuesById[13] = "KEEP_TRACKLET_AREA"] = 13;
                    values[valuesById[14] = "SECTION_TESTING_AREA"] = 14;
                    values[valuesById[15] = "EXCLUDE_INTERACTION"] = 15;
                    values[valuesById[16] = "PASS_BY_AREA"] = 16;
                    values[valuesById[17] = "REMOVE_TRACKLET_AREA"] = 17;
                    values[valuesById[18] = "WIDE_SPACE_ENTRANCE"] = 18;
                    values[valuesById[19] = "MALL_ENTRANCE"] = 19;
                    values[valuesById[20] = "REMOVE_VISITOR_STARTING_AREA"] = 20;
                    values[valuesById[21] = "REMOVE_VISITOR_APPEAR_DISAPPEAR_AREA"] = 21;
                    values[valuesById[22] = "HS_TAG"] = 22;
                    values[valuesById[23] = "STAFF_REQUIRED_AREA"] = 23;
                    values[valuesById[24] = "SPECIAL_AREA"] = 24;
                    values[valuesById[25] = "MUST_PASS"] = 25;
                    return values;
                })();
    
                /**
                 * UserTypeName enum.
                 * @name palexy.streaming.v1.UserTypeName
                 * @enum {number}
                 * @property {number} UNKNOWN_USER_TYPE=0 UNKNOWN_USER_TYPE value
                 * @property {number} SECTION=1 SECTION value
                 * @property {number} QUEUE=2 QUEUE value
                 */
                v1.UserTypeName = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_USER_TYPE"] = 0;
                    values[valuesById[1] = "SECTION"] = 1;
                    values[valuesById[2] = "QUEUE"] = 2;
                    return values;
                })();
    
                v1.CameraSectionService = (function() {
    
                    /**
                     * Constructs a new CameraSectionService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CameraSectionService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function CameraSectionService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (CameraSectionService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CameraSectionService;
    
                    /**
                     * Creates new CameraSectionService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {CameraSectionService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    CameraSectionService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraSectionService#createSystemCameraSectionVersion}.
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @typedef CreateSystemCameraSectionVersionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.CameraSectionVersion} [response] CameraSectionVersion
                     */
    
                    /**
                     * Calls CreateSystemCameraSectionVersion.
                     * @function createSystemCameraSectionVersion
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateSystemCameraSectionVersionRequest} request CreateSystemCameraSectionVersionRequest message or plain object
                     * @param {palexy.streaming.v1.CameraSectionService.CreateSystemCameraSectionVersionCallback} callback Node-style callback called with the error, if any, and CameraSectionVersion
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraSectionService.prototype.createSystemCameraSectionVersion = function createSystemCameraSectionVersion(request, callback) {
                        return this.rpcCall(createSystemCameraSectionVersion, $root.palexy.streaming.v1.CreateSystemCameraSectionVersionRequest, $root.palexy.streaming.v1.CameraSectionVersion, request, callback);
                    }, "name", { value: "CreateSystemCameraSectionVersion" });
    
                    /**
                     * Calls CreateSystemCameraSectionVersion.
                     * @function createSystemCameraSectionVersion
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateSystemCameraSectionVersionRequest} request CreateSystemCameraSectionVersionRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.CameraSectionVersion>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraSectionService#listSystemCameraSectionVersion}.
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @typedef ListSystemCameraSectionVersionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListCameraSectionVersionResponse} [response] ListCameraSectionVersionResponse
                     */
    
                    /**
                     * Calls ListSystemCameraSectionVersion.
                     * @function listSystemCameraSectionVersion
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @instance
                     * @param {palexy.streaming.v1.IListSystemCameraSectionVersionRequest} request ListSystemCameraSectionVersionRequest message or plain object
                     * @param {palexy.streaming.v1.CameraSectionService.ListSystemCameraSectionVersionCallback} callback Node-style callback called with the error, if any, and ListCameraSectionVersionResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraSectionService.prototype.listSystemCameraSectionVersion = function listSystemCameraSectionVersion(request, callback) {
                        return this.rpcCall(listSystemCameraSectionVersion, $root.palexy.streaming.v1.ListSystemCameraSectionVersionRequest, $root.palexy.streaming.v1.ListCameraSectionVersionResponse, request, callback);
                    }, "name", { value: "ListSystemCameraSectionVersion" });
    
                    /**
                     * Calls ListSystemCameraSectionVersion.
                     * @function listSystemCameraSectionVersion
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @instance
                     * @param {palexy.streaming.v1.IListSystemCameraSectionVersionRequest} request ListSystemCameraSectionVersionRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListCameraSectionVersionResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraSectionService#listSystemCameraSections}.
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @typedef ListSystemCameraSectionsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListCameraSectionsResponse} [response] ListCameraSectionsResponse
                     */
    
                    /**
                     * Calls ListSystemCameraSections.
                     * @function listSystemCameraSections
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @instance
                     * @param {palexy.streaming.v1.IListSystemCameraSectionsRequest} request ListSystemCameraSectionsRequest message or plain object
                     * @param {palexy.streaming.v1.CameraSectionService.ListSystemCameraSectionsCallback} callback Node-style callback called with the error, if any, and ListCameraSectionsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraSectionService.prototype.listSystemCameraSections = function listSystemCameraSections(request, callback) {
                        return this.rpcCall(listSystemCameraSections, $root.palexy.streaming.v1.ListSystemCameraSectionsRequest, $root.palexy.streaming.v1.ListCameraSectionsResponse, request, callback);
                    }, "name", { value: "ListSystemCameraSections" });
    
                    /**
                     * Calls ListSystemCameraSections.
                     * @function listSystemCameraSections
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @instance
                     * @param {palexy.streaming.v1.IListSystemCameraSectionsRequest} request ListSystemCameraSectionsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListCameraSectionsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraSectionService#listCameraSections}.
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @typedef ListCameraSectionsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListCameraSectionsResponse} [response] ListCameraSectionsResponse
                     */
    
                    /**
                     * Calls ListCameraSections.
                     * @function listCameraSections
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @instance
                     * @param {palexy.streaming.v1.IListCameraSectionsRequest} request ListCameraSectionsRequest message or plain object
                     * @param {palexy.streaming.v1.CameraSectionService.ListCameraSectionsCallback} callback Node-style callback called with the error, if any, and ListCameraSectionsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraSectionService.prototype.listCameraSections = function listCameraSections(request, callback) {
                        return this.rpcCall(listCameraSections, $root.palexy.streaming.v1.ListCameraSectionsRequest, $root.palexy.streaming.v1.ListCameraSectionsResponse, request, callback);
                    }, "name", { value: "ListCameraSections" });
    
                    /**
                     * Calls ListCameraSections.
                     * @function listCameraSections
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @instance
                     * @param {palexy.streaming.v1.IListCameraSectionsRequest} request ListCameraSectionsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListCameraSectionsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraSectionService#listCameraSectionsOfLayout}.
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @typedef ListCameraSectionsOfLayoutCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListCameraSectionsOfLayoutResponse} [response] ListCameraSectionsOfLayoutResponse
                     */
    
                    /**
                     * Calls ListCameraSectionsOfLayout.
                     * @function listCameraSectionsOfLayout
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @instance
                     * @param {palexy.streaming.v1.IListCameraSectionsOfLayoutRequest} request ListCameraSectionsOfLayoutRequest message or plain object
                     * @param {palexy.streaming.v1.CameraSectionService.ListCameraSectionsOfLayoutCallback} callback Node-style callback called with the error, if any, and ListCameraSectionsOfLayoutResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraSectionService.prototype.listCameraSectionsOfLayout = function listCameraSectionsOfLayout(request, callback) {
                        return this.rpcCall(listCameraSectionsOfLayout, $root.palexy.streaming.v1.ListCameraSectionsOfLayoutRequest, $root.palexy.streaming.v1.ListCameraSectionsOfLayoutResponse, request, callback);
                    }, "name", { value: "ListCameraSectionsOfLayout" });
    
                    /**
                     * Calls ListCameraSectionsOfLayout.
                     * @function listCameraSectionsOfLayout
                     * @memberof palexy.streaming.v1.CameraSectionService
                     * @instance
                     * @param {palexy.streaming.v1.IListCameraSectionsOfLayoutRequest} request ListCameraSectionsOfLayoutRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListCameraSectionsOfLayoutResponse>} Promise
                     * @variation 2
                     */
    
                    return CameraSectionService;
                })();
    
                v1.CreateSystemCameraSectionVersionRequest = (function() {
    
                    /**
                     * Properties of a CreateSystemCameraSectionVersionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateSystemCameraSectionVersionRequest
                     * @property {number|null} [cameraId] CreateSystemCameraSectionVersionRequest cameraId
                     * @property {google.protobuf.ITimestamp|null} [appliedTime] CreateSystemCameraSectionVersionRequest appliedTime
                     */
    
                    /**
                     * Constructs a new CreateSystemCameraSectionVersionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateSystemCameraSectionVersionRequest.
                     * @implements ICreateSystemCameraSectionVersionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateSystemCameraSectionVersionRequest=} [properties] Properties to set
                     */
                    function CreateSystemCameraSectionVersionRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateSystemCameraSectionVersionRequest cameraId.
                     * @member {number} cameraId
                     * @memberof palexy.streaming.v1.CreateSystemCameraSectionVersionRequest
                     * @instance
                     */
                    CreateSystemCameraSectionVersionRequest.prototype.cameraId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CreateSystemCameraSectionVersionRequest appliedTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} appliedTime
                     * @memberof palexy.streaming.v1.CreateSystemCameraSectionVersionRequest
                     * @instance
                     */
                    CreateSystemCameraSectionVersionRequest.prototype.appliedTime = null;
    
                    /**
                     * Creates a new CreateSystemCameraSectionVersionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateSystemCameraSectionVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateSystemCameraSectionVersionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateSystemCameraSectionVersionRequest} CreateSystemCameraSectionVersionRequest instance
                     */
                    CreateSystemCameraSectionVersionRequest.create = function create(properties) {
                        return new CreateSystemCameraSectionVersionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateSystemCameraSectionVersionRequest message. Does not implicitly {@link palexy.streaming.v1.CreateSystemCameraSectionVersionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateSystemCameraSectionVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateSystemCameraSectionVersionRequest} message CreateSystemCameraSectionVersionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateSystemCameraSectionVersionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.cameraId);
                        if (message.appliedTime != null && Object.hasOwnProperty.call(message, "appliedTime"))
                            $root.google.protobuf.Timestamp.encode(message.appliedTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateSystemCameraSectionVersionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateSystemCameraSectionVersionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateSystemCameraSectionVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateSystemCameraSectionVersionRequest} message CreateSystemCameraSectionVersionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateSystemCameraSectionVersionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateSystemCameraSectionVersionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateSystemCameraSectionVersionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateSystemCameraSectionVersionRequest} CreateSystemCameraSectionVersionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateSystemCameraSectionVersionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateSystemCameraSectionVersionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraId = reader.int64();
                                break;
                            case 2:
                                message.appliedTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateSystemCameraSectionVersionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateSystemCameraSectionVersionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateSystemCameraSectionVersionRequest} CreateSystemCameraSectionVersionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateSystemCameraSectionVersionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateSystemCameraSectionVersionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateSystemCameraSectionVersionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateSystemCameraSectionVersionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isInteger(message.cameraId) && !(message.cameraId && $util.isInteger(message.cameraId.low) && $util.isInteger(message.cameraId.high)))
                                return "cameraId: integer|Long expected";
                        if (message.appliedTime != null && message.hasOwnProperty("appliedTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.appliedTime);
                            if (error)
                                return "appliedTime." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateSystemCameraSectionVersionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateSystemCameraSectionVersionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateSystemCameraSectionVersionRequest} CreateSystemCameraSectionVersionRequest
                     */
                    CreateSystemCameraSectionVersionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateSystemCameraSectionVersionRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateSystemCameraSectionVersionRequest();
                        if (object.cameraId != null)
                            if ($util.Long)
                                (message.cameraId = $util.Long.fromValue(object.cameraId)).unsigned = false;
                            else if (typeof object.cameraId === "string")
                                message.cameraId = parseInt(object.cameraId, 10);
                            else if (typeof object.cameraId === "number")
                                message.cameraId = object.cameraId;
                            else if (typeof object.cameraId === "object")
                                message.cameraId = new $util.LongBits(object.cameraId.low >>> 0, object.cameraId.high >>> 0).toNumber();
                        if (object.appliedTime != null) {
                            if (typeof object.appliedTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateSystemCameraSectionVersionRequest.appliedTime: object expected");
                            message.appliedTime = $root.google.protobuf.Timestamp.fromObject(object.appliedTime);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateSystemCameraSectionVersionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateSystemCameraSectionVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateSystemCameraSectionVersionRequest} message CreateSystemCameraSectionVersionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateSystemCameraSectionVersionRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraId = options.longs === String ? "0" : 0;
                            object.appliedTime = null;
                        }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (typeof message.cameraId === "number")
                                object.cameraId = options.longs === String ? String(message.cameraId) : message.cameraId;
                            else
                                object.cameraId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraId) : options.longs === Number ? new $util.LongBits(message.cameraId.low >>> 0, message.cameraId.high >>> 0).toNumber() : message.cameraId;
                        if (message.appliedTime != null && message.hasOwnProperty("appliedTime"))
                            object.appliedTime = $root.google.protobuf.Timestamp.toObject(message.appliedTime, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateSystemCameraSectionVersionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateSystemCameraSectionVersionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateSystemCameraSectionVersionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateSystemCameraSectionVersionRequest;
                })();
    
                v1.CameraSectionVersion = (function() {
    
                    /**
                     * Properties of a CameraSectionVersion.
                     * @memberof palexy.streaming.v1
                     * @interface ICameraSectionVersion
                     * @property {number|null} [id] CameraSectionVersion id
                     * @property {number|null} [cameraId] CameraSectionVersion cameraId
                     * @property {number|null} [photoId] CameraSectionVersion photoId
                     * @property {google.protobuf.ITimestamp|null} [createTime] CameraSectionVersion createTime
                     * @property {google.protobuf.ITimestamp|null} [appliedTime] CameraSectionVersion appliedTime
                     * @property {palexy.streaming.v1.ICameraSectionVersionData|null} [data] CameraSectionVersion data
                     */
    
                    /**
                     * Constructs a new CameraSectionVersion.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CameraSectionVersion.
                     * @implements ICameraSectionVersion
                     * @constructor
                     * @param {palexy.streaming.v1.ICameraSectionVersion=} [properties] Properties to set
                     */
                    function CameraSectionVersion(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CameraSectionVersion id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @instance
                     */
                    CameraSectionVersion.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSectionVersion cameraId.
                     * @member {number} cameraId
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @instance
                     */
                    CameraSectionVersion.prototype.cameraId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSectionVersion photoId.
                     * @member {number} photoId
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @instance
                     */
                    CameraSectionVersion.prototype.photoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSectionVersion createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @instance
                     */
                    CameraSectionVersion.prototype.createTime = null;
    
                    /**
                     * CameraSectionVersion appliedTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} appliedTime
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @instance
                     */
                    CameraSectionVersion.prototype.appliedTime = null;
    
                    /**
                     * CameraSectionVersion data.
                     * @member {palexy.streaming.v1.ICameraSectionVersionData|null|undefined} data
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @instance
                     */
                    CameraSectionVersion.prototype.data = null;
    
                    /**
                     * Creates a new CameraSectionVersion instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @static
                     * @param {palexy.streaming.v1.ICameraSectionVersion=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CameraSectionVersion} CameraSectionVersion instance
                     */
                    CameraSectionVersion.create = function create(properties) {
                        return new CameraSectionVersion(properties);
                    };
    
                    /**
                     * Encodes the specified CameraSectionVersion message. Does not implicitly {@link palexy.streaming.v1.CameraSectionVersion.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @static
                     * @param {palexy.streaming.v1.ICameraSectionVersion} message CameraSectionVersion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraSectionVersion.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.cameraId);
                        if (message.photoId != null && Object.hasOwnProperty.call(message, "photoId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.photoId);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.appliedTime != null && Object.hasOwnProperty.call(message, "appliedTime"))
                            $root.google.protobuf.Timestamp.encode(message.appliedTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                            $root.palexy.streaming.v1.CameraSectionVersionData.encode(message.data, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CameraSectionVersion message, length delimited. Does not implicitly {@link palexy.streaming.v1.CameraSectionVersion.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @static
                     * @param {palexy.streaming.v1.ICameraSectionVersion} message CameraSectionVersion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraSectionVersion.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CameraSectionVersion message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CameraSectionVersion} CameraSectionVersion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraSectionVersion.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CameraSectionVersion();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.cameraId = reader.int64();
                                break;
                            case 3:
                                message.photoId = reader.int64();
                                break;
                            case 4:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.appliedTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.data = $root.palexy.streaming.v1.CameraSectionVersionData.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CameraSectionVersion message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CameraSectionVersion} CameraSectionVersion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraSectionVersion.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CameraSectionVersion message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CameraSectionVersion.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isInteger(message.cameraId) && !(message.cameraId && $util.isInteger(message.cameraId.low) && $util.isInteger(message.cameraId.high)))
                                return "cameraId: integer|Long expected";
                        if (message.photoId != null && message.hasOwnProperty("photoId"))
                            if (!$util.isInteger(message.photoId) && !(message.photoId && $util.isInteger(message.photoId.low) && $util.isInteger(message.photoId.high)))
                                return "photoId: integer|Long expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.appliedTime != null && message.hasOwnProperty("appliedTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.appliedTime);
                            if (error)
                                return "appliedTime." + error;
                        }
                        if (message.data != null && message.hasOwnProperty("data")) {
                            var error = $root.palexy.streaming.v1.CameraSectionVersionData.verify(message.data);
                            if (error)
                                return "data." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CameraSectionVersion message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CameraSectionVersion} CameraSectionVersion
                     */
                    CameraSectionVersion.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CameraSectionVersion)
                            return object;
                        var message = new $root.palexy.streaming.v1.CameraSectionVersion();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.cameraId != null)
                            if ($util.Long)
                                (message.cameraId = $util.Long.fromValue(object.cameraId)).unsigned = false;
                            else if (typeof object.cameraId === "string")
                                message.cameraId = parseInt(object.cameraId, 10);
                            else if (typeof object.cameraId === "number")
                                message.cameraId = object.cameraId;
                            else if (typeof object.cameraId === "object")
                                message.cameraId = new $util.LongBits(object.cameraId.low >>> 0, object.cameraId.high >>> 0).toNumber();
                        if (object.photoId != null)
                            if ($util.Long)
                                (message.photoId = $util.Long.fromValue(object.photoId)).unsigned = false;
                            else if (typeof object.photoId === "string")
                                message.photoId = parseInt(object.photoId, 10);
                            else if (typeof object.photoId === "number")
                                message.photoId = object.photoId;
                            else if (typeof object.photoId === "object")
                                message.photoId = new $util.LongBits(object.photoId.low >>> 0, object.photoId.high >>> 0).toNumber();
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CameraSectionVersion.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.appliedTime != null) {
                            if (typeof object.appliedTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CameraSectionVersion.appliedTime: object expected");
                            message.appliedTime = $root.google.protobuf.Timestamp.fromObject(object.appliedTime);
                        }
                        if (object.data != null) {
                            if (typeof object.data !== "object")
                                throw TypeError(".palexy.streaming.v1.CameraSectionVersion.data: object expected");
                            message.data = $root.palexy.streaming.v1.CameraSectionVersionData.fromObject(object.data);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CameraSectionVersion message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @static
                     * @param {palexy.streaming.v1.CameraSectionVersion} message CameraSectionVersion
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CameraSectionVersion.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.photoId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.photoId = options.longs === String ? "0" : 0;
                            object.createTime = null;
                            object.appliedTime = null;
                            object.data = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (typeof message.cameraId === "number")
                                object.cameraId = options.longs === String ? String(message.cameraId) : message.cameraId;
                            else
                                object.cameraId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraId) : options.longs === Number ? new $util.LongBits(message.cameraId.low >>> 0, message.cameraId.high >>> 0).toNumber() : message.cameraId;
                        if (message.photoId != null && message.hasOwnProperty("photoId"))
                            if (typeof message.photoId === "number")
                                object.photoId = options.longs === String ? String(message.photoId) : message.photoId;
                            else
                                object.photoId = options.longs === String ? $util.Long.prototype.toString.call(message.photoId) : options.longs === Number ? new $util.LongBits(message.photoId.low >>> 0, message.photoId.high >>> 0).toNumber() : message.photoId;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.appliedTime != null && message.hasOwnProperty("appliedTime"))
                            object.appliedTime = $root.google.protobuf.Timestamp.toObject(message.appliedTime, options);
                        if (message.data != null && message.hasOwnProperty("data"))
                            object.data = $root.palexy.streaming.v1.CameraSectionVersionData.toObject(message.data, options);
                        return object;
                    };
    
                    /**
                     * Converts this CameraSectionVersion to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CameraSectionVersion
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CameraSectionVersion.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CameraSectionVersion;
                })();
    
                v1.ListSystemCameraSectionVersionRequest = (function() {
    
                    /**
                     * Properties of a ListSystemCameraSectionVersionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListSystemCameraSectionVersionRequest
                     * @property {number|null} [cameraId] ListSystemCameraSectionVersionRequest cameraId
                     * @property {google.protobuf.IFieldMask|null} [getFields] ListSystemCameraSectionVersionRequest getFields
                     */
    
                    /**
                     * Constructs a new ListSystemCameraSectionVersionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListSystemCameraSectionVersionRequest.
                     * @implements IListSystemCameraSectionVersionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListSystemCameraSectionVersionRequest=} [properties] Properties to set
                     */
                    function ListSystemCameraSectionVersionRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListSystemCameraSectionVersionRequest cameraId.
                     * @member {number} cameraId
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionVersionRequest
                     * @instance
                     */
                    ListSystemCameraSectionVersionRequest.prototype.cameraId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListSystemCameraSectionVersionRequest getFields.
                     * @member {google.protobuf.IFieldMask|null|undefined} getFields
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionVersionRequest
                     * @instance
                     */
                    ListSystemCameraSectionVersionRequest.prototype.getFields = null;
    
                    /**
                     * Creates a new ListSystemCameraSectionVersionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSystemCameraSectionVersionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListSystemCameraSectionVersionRequest} ListSystemCameraSectionVersionRequest instance
                     */
                    ListSystemCameraSectionVersionRequest.create = function create(properties) {
                        return new ListSystemCameraSectionVersionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListSystemCameraSectionVersionRequest message. Does not implicitly {@link palexy.streaming.v1.ListSystemCameraSectionVersionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSystemCameraSectionVersionRequest} message ListSystemCameraSectionVersionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSystemCameraSectionVersionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.cameraId);
                        if (message.getFields != null && Object.hasOwnProperty.call(message, "getFields"))
                            $root.google.protobuf.FieldMask.encode(message.getFields, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListSystemCameraSectionVersionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListSystemCameraSectionVersionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSystemCameraSectionVersionRequest} message ListSystemCameraSectionVersionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSystemCameraSectionVersionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListSystemCameraSectionVersionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionVersionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListSystemCameraSectionVersionRequest} ListSystemCameraSectionVersionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSystemCameraSectionVersionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListSystemCameraSectionVersionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraId = reader.int64();
                                break;
                            case 2:
                                message.getFields = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListSystemCameraSectionVersionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionVersionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListSystemCameraSectionVersionRequest} ListSystemCameraSectionVersionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSystemCameraSectionVersionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListSystemCameraSectionVersionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionVersionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListSystemCameraSectionVersionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isInteger(message.cameraId) && !(message.cameraId && $util.isInteger(message.cameraId.low) && $util.isInteger(message.cameraId.high)))
                                return "cameraId: integer|Long expected";
                        if (message.getFields != null && message.hasOwnProperty("getFields")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.getFields);
                            if (error)
                                return "getFields." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListSystemCameraSectionVersionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionVersionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListSystemCameraSectionVersionRequest} ListSystemCameraSectionVersionRequest
                     */
                    ListSystemCameraSectionVersionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListSystemCameraSectionVersionRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListSystemCameraSectionVersionRequest();
                        if (object.cameraId != null)
                            if ($util.Long)
                                (message.cameraId = $util.Long.fromValue(object.cameraId)).unsigned = false;
                            else if (typeof object.cameraId === "string")
                                message.cameraId = parseInt(object.cameraId, 10);
                            else if (typeof object.cameraId === "number")
                                message.cameraId = object.cameraId;
                            else if (typeof object.cameraId === "object")
                                message.cameraId = new $util.LongBits(object.cameraId.low >>> 0, object.cameraId.high >>> 0).toNumber();
                        if (object.getFields != null) {
                            if (typeof object.getFields !== "object")
                                throw TypeError(".palexy.streaming.v1.ListSystemCameraSectionVersionRequest.getFields: object expected");
                            message.getFields = $root.google.protobuf.FieldMask.fromObject(object.getFields);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListSystemCameraSectionVersionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.ListSystemCameraSectionVersionRequest} message ListSystemCameraSectionVersionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListSystemCameraSectionVersionRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraId = options.longs === String ? "0" : 0;
                            object.getFields = null;
                        }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (typeof message.cameraId === "number")
                                object.cameraId = options.longs === String ? String(message.cameraId) : message.cameraId;
                            else
                                object.cameraId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraId) : options.longs === Number ? new $util.LongBits(message.cameraId.low >>> 0, message.cameraId.high >>> 0).toNumber() : message.cameraId;
                        if (message.getFields != null && message.hasOwnProperty("getFields"))
                            object.getFields = $root.google.protobuf.FieldMask.toObject(message.getFields, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListSystemCameraSectionVersionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionVersionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListSystemCameraSectionVersionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListSystemCameraSectionVersionRequest;
                })();
    
                v1.ListCameraSectionVersionResponse = (function() {
    
                    /**
                     * Properties of a ListCameraSectionVersionResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListCameraSectionVersionResponse
                     * @property {Array.<palexy.streaming.v1.ICameraSectionVersion>|null} [version] ListCameraSectionVersionResponse version
                     */
    
                    /**
                     * Constructs a new ListCameraSectionVersionResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListCameraSectionVersionResponse.
                     * @implements IListCameraSectionVersionResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListCameraSectionVersionResponse=} [properties] Properties to set
                     */
                    function ListCameraSectionVersionResponse(properties) {
                        this.version = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCameraSectionVersionResponse version.
                     * @member {Array.<palexy.streaming.v1.ICameraSectionVersion>} version
                     * @memberof palexy.streaming.v1.ListCameraSectionVersionResponse
                     * @instance
                     */
                    ListCameraSectionVersionResponse.prototype.version = $util.emptyArray;
    
                    /**
                     * Creates a new ListCameraSectionVersionResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListCameraSectionVersionResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionVersionResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListCameraSectionVersionResponse} ListCameraSectionVersionResponse instance
                     */
                    ListCameraSectionVersionResponse.create = function create(properties) {
                        return new ListCameraSectionVersionResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListCameraSectionVersionResponse message. Does not implicitly {@link palexy.streaming.v1.ListCameraSectionVersionResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListCameraSectionVersionResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionVersionResponse} message ListCameraSectionVersionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCameraSectionVersionResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.version != null && message.version.length)
                            for (var i = 0; i < message.version.length; ++i)
                                $root.palexy.streaming.v1.CameraSectionVersion.encode(message.version[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCameraSectionVersionResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListCameraSectionVersionResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListCameraSectionVersionResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionVersionResponse} message ListCameraSectionVersionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCameraSectionVersionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCameraSectionVersionResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListCameraSectionVersionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListCameraSectionVersionResponse} ListCameraSectionVersionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCameraSectionVersionResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListCameraSectionVersionResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.version && message.version.length))
                                    message.version = [];
                                message.version.push($root.palexy.streaming.v1.CameraSectionVersion.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCameraSectionVersionResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListCameraSectionVersionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListCameraSectionVersionResponse} ListCameraSectionVersionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCameraSectionVersionResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCameraSectionVersionResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListCameraSectionVersionResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCameraSectionVersionResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.version != null && message.hasOwnProperty("version")) {
                            if (!Array.isArray(message.version))
                                return "version: array expected";
                            for (var i = 0; i < message.version.length; ++i) {
                                var error = $root.palexy.streaming.v1.CameraSectionVersion.verify(message.version[i]);
                                if (error)
                                    return "version." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListCameraSectionVersionResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListCameraSectionVersionResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListCameraSectionVersionResponse} ListCameraSectionVersionResponse
                     */
                    ListCameraSectionVersionResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListCameraSectionVersionResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListCameraSectionVersionResponse();
                        if (object.version) {
                            if (!Array.isArray(object.version))
                                throw TypeError(".palexy.streaming.v1.ListCameraSectionVersionResponse.version: array expected");
                            message.version = [];
                            for (var i = 0; i < object.version.length; ++i) {
                                if (typeof object.version[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListCameraSectionVersionResponse.version: object expected");
                                message.version[i] = $root.palexy.streaming.v1.CameraSectionVersion.fromObject(object.version[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCameraSectionVersionResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListCameraSectionVersionResponse
                     * @static
                     * @param {palexy.streaming.v1.ListCameraSectionVersionResponse} message ListCameraSectionVersionResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCameraSectionVersionResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.version = [];
                        if (message.version && message.version.length) {
                            object.version = [];
                            for (var j = 0; j < message.version.length; ++j)
                                object.version[j] = $root.palexy.streaming.v1.CameraSectionVersion.toObject(message.version[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListCameraSectionVersionResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListCameraSectionVersionResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCameraSectionVersionResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCameraSectionVersionResponse;
                })();
    
                v1.CameraSectionVersionData = (function() {
    
                    /**
                     * Properties of a CameraSectionVersionData.
                     * @memberof palexy.streaming.v1
                     * @interface ICameraSectionVersionData
                     * @property {Array.<palexy.streaming.v1.ICameraSection>|null} [section] CameraSectionVersionData section
                     */
    
                    /**
                     * Constructs a new CameraSectionVersionData.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CameraSectionVersionData.
                     * @implements ICameraSectionVersionData
                     * @constructor
                     * @param {palexy.streaming.v1.ICameraSectionVersionData=} [properties] Properties to set
                     */
                    function CameraSectionVersionData(properties) {
                        this.section = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CameraSectionVersionData section.
                     * @member {Array.<palexy.streaming.v1.ICameraSection>} section
                     * @memberof palexy.streaming.v1.CameraSectionVersionData
                     * @instance
                     */
                    CameraSectionVersionData.prototype.section = $util.emptyArray;
    
                    /**
                     * Creates a new CameraSectionVersionData instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CameraSectionVersionData
                     * @static
                     * @param {palexy.streaming.v1.ICameraSectionVersionData=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CameraSectionVersionData} CameraSectionVersionData instance
                     */
                    CameraSectionVersionData.create = function create(properties) {
                        return new CameraSectionVersionData(properties);
                    };
    
                    /**
                     * Encodes the specified CameraSectionVersionData message. Does not implicitly {@link palexy.streaming.v1.CameraSectionVersionData.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CameraSectionVersionData
                     * @static
                     * @param {palexy.streaming.v1.ICameraSectionVersionData} message CameraSectionVersionData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraSectionVersionData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.section != null && message.section.length)
                            for (var i = 0; i < message.section.length; ++i)
                                $root.palexy.streaming.v1.CameraSection.encode(message.section[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CameraSectionVersionData message, length delimited. Does not implicitly {@link palexy.streaming.v1.CameraSectionVersionData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CameraSectionVersionData
                     * @static
                     * @param {palexy.streaming.v1.ICameraSectionVersionData} message CameraSectionVersionData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraSectionVersionData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CameraSectionVersionData message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CameraSectionVersionData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CameraSectionVersionData} CameraSectionVersionData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraSectionVersionData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CameraSectionVersionData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 2:
                                if (!(message.section && message.section.length))
                                    message.section = [];
                                message.section.push($root.palexy.streaming.v1.CameraSection.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CameraSectionVersionData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CameraSectionVersionData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CameraSectionVersionData} CameraSectionVersionData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraSectionVersionData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CameraSectionVersionData message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CameraSectionVersionData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CameraSectionVersionData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.section != null && message.hasOwnProperty("section")) {
                            if (!Array.isArray(message.section))
                                return "section: array expected";
                            for (var i = 0; i < message.section.length; ++i) {
                                var error = $root.palexy.streaming.v1.CameraSection.verify(message.section[i]);
                                if (error)
                                    return "section." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CameraSectionVersionData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CameraSectionVersionData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CameraSectionVersionData} CameraSectionVersionData
                     */
                    CameraSectionVersionData.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CameraSectionVersionData)
                            return object;
                        var message = new $root.palexy.streaming.v1.CameraSectionVersionData();
                        if (object.section) {
                            if (!Array.isArray(object.section))
                                throw TypeError(".palexy.streaming.v1.CameraSectionVersionData.section: array expected");
                            message.section = [];
                            for (var i = 0; i < object.section.length; ++i) {
                                if (typeof object.section[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.CameraSectionVersionData.section: object expected");
                                message.section[i] = $root.palexy.streaming.v1.CameraSection.fromObject(object.section[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CameraSectionVersionData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CameraSectionVersionData
                     * @static
                     * @param {palexy.streaming.v1.CameraSectionVersionData} message CameraSectionVersionData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CameraSectionVersionData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.section = [];
                        if (message.section && message.section.length) {
                            object.section = [];
                            for (var j = 0; j < message.section.length; ++j)
                                object.section[j] = $root.palexy.streaming.v1.CameraSection.toObject(message.section[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CameraSectionVersionData to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CameraSectionVersionData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CameraSectionVersionData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CameraSectionVersionData;
                })();
    
                v1.CameraSection = (function() {
    
                    /**
                     * Properties of a CameraSection.
                     * @memberof palexy.streaming.v1
                     * @interface ICameraSection
                     * @property {number|null} [id] CameraSection id
                     * @property {number|null} [photoId] CameraSection photoId
                     * @property {number|null} [cameraId] CameraSection cameraId
                     * @property {palexy.streaming.v1.IPolygon|null} [polygon] CameraSection polygon
                     * @property {number|null} [metadataTypeId] CameraSection metadataTypeId
                     * @property {number|null} [metadataValueId] CameraSection metadataValueId
                     * @property {number|null} [layoutId] CameraSection layoutId
                     * @property {google.protobuf.ITimestamp|null} [createTime] CameraSection createTime
                     * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] CameraSection lastUpdateTime
                     * @property {boolean|null} [isSystem] CameraSection isSystem
                     * @property {boolean|null} [useVisitorDirection] CameraSection useVisitorDirection
                     * @property {string|null} [metadataTypeSystemName] CameraSection metadataTypeSystemName
                     * @property {string|null} [metadataValueName] CameraSection metadataValueName
                     * @property {string|null} [pairSectionInfo] CameraSection pairSectionInfo
                     */
    
                    /**
                     * Constructs a new CameraSection.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CameraSection.
                     * @implements ICameraSection
                     * @constructor
                     * @param {palexy.streaming.v1.ICameraSection=} [properties] Properties to set
                     */
                    function CameraSection(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CameraSection id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSection photoId.
                     * @member {number} photoId
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.photoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSection cameraId.
                     * @member {number} cameraId
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.cameraId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSection polygon.
                     * @member {palexy.streaming.v1.IPolygon|null|undefined} polygon
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.polygon = null;
    
                    /**
                     * CameraSection metadataTypeId.
                     * @member {number} metadataTypeId
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.metadataTypeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSection metadataValueId.
                     * @member {number} metadataValueId
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.metadataValueId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSection layoutId.
                     * @member {number} layoutId
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.layoutId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSection createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.createTime = null;
    
                    /**
                     * CameraSection lastUpdateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.lastUpdateTime = null;
    
                    /**
                     * CameraSection isSystem.
                     * @member {boolean} isSystem
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.isSystem = false;
    
                    /**
                     * CameraSection useVisitorDirection.
                     * @member {boolean} useVisitorDirection
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.useVisitorDirection = false;
    
                    /**
                     * CameraSection metadataTypeSystemName.
                     * @member {string} metadataTypeSystemName
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.metadataTypeSystemName = "";
    
                    /**
                     * CameraSection metadataValueName.
                     * @member {string} metadataValueName
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.metadataValueName = "";
    
                    /**
                     * CameraSection pairSectionInfo.
                     * @member {string} pairSectionInfo
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     */
                    CameraSection.prototype.pairSectionInfo = "";
    
                    /**
                     * Creates a new CameraSection instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CameraSection
                     * @static
                     * @param {palexy.streaming.v1.ICameraSection=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CameraSection} CameraSection instance
                     */
                    CameraSection.create = function create(properties) {
                        return new CameraSection(properties);
                    };
    
                    /**
                     * Encodes the specified CameraSection message. Does not implicitly {@link palexy.streaming.v1.CameraSection.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CameraSection
                     * @static
                     * @param {palexy.streaming.v1.ICameraSection} message CameraSection message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraSection.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.photoId != null && Object.hasOwnProperty.call(message, "photoId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.photoId);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.cameraId);
                        if (message.polygon != null && Object.hasOwnProperty.call(message, "polygon"))
                            $root.palexy.streaming.v1.Polygon.encode(message.polygon, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.metadataTypeId != null && Object.hasOwnProperty.call(message, "metadataTypeId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.metadataTypeId);
                        if (message.metadataValueId != null && Object.hasOwnProperty.call(message, "metadataValueId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.metadataValueId);
                        if (message.layoutId != null && Object.hasOwnProperty.call(message, "layoutId"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.layoutId);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.isSystem != null && Object.hasOwnProperty.call(message, "isSystem"))
                            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isSystem);
                        if (message.metadataTypeSystemName != null && Object.hasOwnProperty.call(message, "metadataTypeSystemName"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.metadataTypeSystemName);
                        if (message.metadataValueName != null && Object.hasOwnProperty.call(message, "metadataValueName"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.metadataValueName);
                        if (message.useVisitorDirection != null && Object.hasOwnProperty.call(message, "useVisitorDirection"))
                            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.useVisitorDirection);
                        if (message.pairSectionInfo != null && Object.hasOwnProperty.call(message, "pairSectionInfo"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.pairSectionInfo);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CameraSection message, length delimited. Does not implicitly {@link palexy.streaming.v1.CameraSection.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CameraSection
                     * @static
                     * @param {palexy.streaming.v1.ICameraSection} message CameraSection message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraSection.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CameraSection message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CameraSection
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CameraSection} CameraSection
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraSection.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CameraSection();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.photoId = reader.int64();
                                break;
                            case 3:
                                message.cameraId = reader.int64();
                                break;
                            case 4:
                                message.polygon = $root.palexy.streaming.v1.Polygon.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.metadataTypeId = reader.int64();
                                break;
                            case 6:
                                message.metadataValueId = reader.int64();
                                break;
                            case 7:
                                message.layoutId = reader.int64();
                                break;
                            case 8:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.isSystem = reader.bool();
                                break;
                            case 13:
                                message.useVisitorDirection = reader.bool();
                                break;
                            case 11:
                                message.metadataTypeSystemName = reader.string();
                                break;
                            case 12:
                                message.metadataValueName = reader.string();
                                break;
                            case 14:
                                message.pairSectionInfo = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CameraSection message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CameraSection
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CameraSection} CameraSection
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraSection.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CameraSection message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CameraSection
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CameraSection.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.photoId != null && message.hasOwnProperty("photoId"))
                            if (!$util.isInteger(message.photoId) && !(message.photoId && $util.isInteger(message.photoId.low) && $util.isInteger(message.photoId.high)))
                                return "photoId: integer|Long expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isInteger(message.cameraId) && !(message.cameraId && $util.isInteger(message.cameraId.low) && $util.isInteger(message.cameraId.high)))
                                return "cameraId: integer|Long expected";
                        if (message.polygon != null && message.hasOwnProperty("polygon")) {
                            var error = $root.palexy.streaming.v1.Polygon.verify(message.polygon);
                            if (error)
                                return "polygon." + error;
                        }
                        if (message.metadataTypeId != null && message.hasOwnProperty("metadataTypeId"))
                            if (!$util.isInteger(message.metadataTypeId) && !(message.metadataTypeId && $util.isInteger(message.metadataTypeId.low) && $util.isInteger(message.metadataTypeId.high)))
                                return "metadataTypeId: integer|Long expected";
                        if (message.metadataValueId != null && message.hasOwnProperty("metadataValueId"))
                            if (!$util.isInteger(message.metadataValueId) && !(message.metadataValueId && $util.isInteger(message.metadataValueId.low) && $util.isInteger(message.metadataValueId.high)))
                                return "metadataValueId: integer|Long expected";
                        if (message.layoutId != null && message.hasOwnProperty("layoutId"))
                            if (!$util.isInteger(message.layoutId) && !(message.layoutId && $util.isInteger(message.layoutId.low) && $util.isInteger(message.layoutId.high)))
                                return "layoutId: integer|Long expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                            if (error)
                                return "lastUpdateTime." + error;
                        }
                        if (message.isSystem != null && message.hasOwnProperty("isSystem"))
                            if (typeof message.isSystem !== "boolean")
                                return "isSystem: boolean expected";
                        if (message.useVisitorDirection != null && message.hasOwnProperty("useVisitorDirection"))
                            if (typeof message.useVisitorDirection !== "boolean")
                                return "useVisitorDirection: boolean expected";
                        if (message.metadataTypeSystemName != null && message.hasOwnProperty("metadataTypeSystemName"))
                            if (!$util.isString(message.metadataTypeSystemName))
                                return "metadataTypeSystemName: string expected";
                        if (message.metadataValueName != null && message.hasOwnProperty("metadataValueName"))
                            if (!$util.isString(message.metadataValueName))
                                return "metadataValueName: string expected";
                        if (message.pairSectionInfo != null && message.hasOwnProperty("pairSectionInfo"))
                            if (!$util.isString(message.pairSectionInfo))
                                return "pairSectionInfo: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a CameraSection message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CameraSection
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CameraSection} CameraSection
                     */
                    CameraSection.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CameraSection)
                            return object;
                        var message = new $root.palexy.streaming.v1.CameraSection();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.photoId != null)
                            if ($util.Long)
                                (message.photoId = $util.Long.fromValue(object.photoId)).unsigned = false;
                            else if (typeof object.photoId === "string")
                                message.photoId = parseInt(object.photoId, 10);
                            else if (typeof object.photoId === "number")
                                message.photoId = object.photoId;
                            else if (typeof object.photoId === "object")
                                message.photoId = new $util.LongBits(object.photoId.low >>> 0, object.photoId.high >>> 0).toNumber();
                        if (object.cameraId != null)
                            if ($util.Long)
                                (message.cameraId = $util.Long.fromValue(object.cameraId)).unsigned = false;
                            else if (typeof object.cameraId === "string")
                                message.cameraId = parseInt(object.cameraId, 10);
                            else if (typeof object.cameraId === "number")
                                message.cameraId = object.cameraId;
                            else if (typeof object.cameraId === "object")
                                message.cameraId = new $util.LongBits(object.cameraId.low >>> 0, object.cameraId.high >>> 0).toNumber();
                        if (object.polygon != null) {
                            if (typeof object.polygon !== "object")
                                throw TypeError(".palexy.streaming.v1.CameraSection.polygon: object expected");
                            message.polygon = $root.palexy.streaming.v1.Polygon.fromObject(object.polygon);
                        }
                        if (object.metadataTypeId != null)
                            if ($util.Long)
                                (message.metadataTypeId = $util.Long.fromValue(object.metadataTypeId)).unsigned = false;
                            else if (typeof object.metadataTypeId === "string")
                                message.metadataTypeId = parseInt(object.metadataTypeId, 10);
                            else if (typeof object.metadataTypeId === "number")
                                message.metadataTypeId = object.metadataTypeId;
                            else if (typeof object.metadataTypeId === "object")
                                message.metadataTypeId = new $util.LongBits(object.metadataTypeId.low >>> 0, object.metadataTypeId.high >>> 0).toNumber();
                        if (object.metadataValueId != null)
                            if ($util.Long)
                                (message.metadataValueId = $util.Long.fromValue(object.metadataValueId)).unsigned = false;
                            else if (typeof object.metadataValueId === "string")
                                message.metadataValueId = parseInt(object.metadataValueId, 10);
                            else if (typeof object.metadataValueId === "number")
                                message.metadataValueId = object.metadataValueId;
                            else if (typeof object.metadataValueId === "object")
                                message.metadataValueId = new $util.LongBits(object.metadataValueId.low >>> 0, object.metadataValueId.high >>> 0).toNumber();
                        if (object.layoutId != null)
                            if ($util.Long)
                                (message.layoutId = $util.Long.fromValue(object.layoutId)).unsigned = false;
                            else if (typeof object.layoutId === "string")
                                message.layoutId = parseInt(object.layoutId, 10);
                            else if (typeof object.layoutId === "number")
                                message.layoutId = object.layoutId;
                            else if (typeof object.layoutId === "object")
                                message.layoutId = new $util.LongBits(object.layoutId.low >>> 0, object.layoutId.high >>> 0).toNumber();
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CameraSection.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.lastUpdateTime != null) {
                            if (typeof object.lastUpdateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CameraSection.lastUpdateTime: object expected");
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                        }
                        if (object.isSystem != null)
                            message.isSystem = Boolean(object.isSystem);
                        if (object.useVisitorDirection != null)
                            message.useVisitorDirection = Boolean(object.useVisitorDirection);
                        if (object.metadataTypeSystemName != null)
                            message.metadataTypeSystemName = String(object.metadataTypeSystemName);
                        if (object.metadataValueName != null)
                            message.metadataValueName = String(object.metadataValueName);
                        if (object.pairSectionInfo != null)
                            message.pairSectionInfo = String(object.pairSectionInfo);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CameraSection message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CameraSection
                     * @static
                     * @param {palexy.streaming.v1.CameraSection} message CameraSection
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CameraSection.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.photoId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.photoId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraId = options.longs === String ? "0" : 0;
                            object.polygon = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataTypeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataTypeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataValueId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataValueId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.layoutId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.layoutId = options.longs === String ? "0" : 0;
                            object.createTime = null;
                            object.lastUpdateTime = null;
                            object.isSystem = false;
                            object.metadataTypeSystemName = "";
                            object.metadataValueName = "";
                            object.useVisitorDirection = false;
                            object.pairSectionInfo = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.photoId != null && message.hasOwnProperty("photoId"))
                            if (typeof message.photoId === "number")
                                object.photoId = options.longs === String ? String(message.photoId) : message.photoId;
                            else
                                object.photoId = options.longs === String ? $util.Long.prototype.toString.call(message.photoId) : options.longs === Number ? new $util.LongBits(message.photoId.low >>> 0, message.photoId.high >>> 0).toNumber() : message.photoId;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (typeof message.cameraId === "number")
                                object.cameraId = options.longs === String ? String(message.cameraId) : message.cameraId;
                            else
                                object.cameraId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraId) : options.longs === Number ? new $util.LongBits(message.cameraId.low >>> 0, message.cameraId.high >>> 0).toNumber() : message.cameraId;
                        if (message.polygon != null && message.hasOwnProperty("polygon"))
                            object.polygon = $root.palexy.streaming.v1.Polygon.toObject(message.polygon, options);
                        if (message.metadataTypeId != null && message.hasOwnProperty("metadataTypeId"))
                            if (typeof message.metadataTypeId === "number")
                                object.metadataTypeId = options.longs === String ? String(message.metadataTypeId) : message.metadataTypeId;
                            else
                                object.metadataTypeId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataTypeId) : options.longs === Number ? new $util.LongBits(message.metadataTypeId.low >>> 0, message.metadataTypeId.high >>> 0).toNumber() : message.metadataTypeId;
                        if (message.metadataValueId != null && message.hasOwnProperty("metadataValueId"))
                            if (typeof message.metadataValueId === "number")
                                object.metadataValueId = options.longs === String ? String(message.metadataValueId) : message.metadataValueId;
                            else
                                object.metadataValueId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataValueId) : options.longs === Number ? new $util.LongBits(message.metadataValueId.low >>> 0, message.metadataValueId.high >>> 0).toNumber() : message.metadataValueId;
                        if (message.layoutId != null && message.hasOwnProperty("layoutId"))
                            if (typeof message.layoutId === "number")
                                object.layoutId = options.longs === String ? String(message.layoutId) : message.layoutId;
                            else
                                object.layoutId = options.longs === String ? $util.Long.prototype.toString.call(message.layoutId) : options.longs === Number ? new $util.LongBits(message.layoutId.low >>> 0, message.layoutId.high >>> 0).toNumber() : message.layoutId;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                            object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                        if (message.isSystem != null && message.hasOwnProperty("isSystem"))
                            object.isSystem = message.isSystem;
                        if (message.metadataTypeSystemName != null && message.hasOwnProperty("metadataTypeSystemName"))
                            object.metadataTypeSystemName = message.metadataTypeSystemName;
                        if (message.metadataValueName != null && message.hasOwnProperty("metadataValueName"))
                            object.metadataValueName = message.metadataValueName;
                        if (message.useVisitorDirection != null && message.hasOwnProperty("useVisitorDirection"))
                            object.useVisitorDirection = message.useVisitorDirection;
                        if (message.pairSectionInfo != null && message.hasOwnProperty("pairSectionInfo"))
                            object.pairSectionInfo = message.pairSectionInfo;
                        return object;
                    };
    
                    /**
                     * Converts this CameraSection to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CameraSection
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CameraSection.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CameraSection;
                })();
    
                v1.CameraSectionFilter = (function() {
    
                    /**
                     * Properties of a CameraSectionFilter.
                     * @memberof palexy.streaming.v1
                     * @interface ICameraSectionFilter
                     * @property {number|null} [metadataTypeId] CameraSectionFilter metadataTypeId
                     * @property {number|null} [metadataValueId] CameraSectionFilter metadataValueId
                     * @property {string|null} [versionDateTime] CameraSectionFilter versionDateTime
                     * @property {number|null} [versionId] CameraSectionFilter versionId
                     * @property {string|null} [dateId] CameraSectionFilter dateId
                     * @property {boolean|null} [isSystem] CameraSectionFilter isSystem
                     */
    
                    /**
                     * Constructs a new CameraSectionFilter.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CameraSectionFilter.
                     * @implements ICameraSectionFilter
                     * @constructor
                     * @param {palexy.streaming.v1.ICameraSectionFilter=} [properties] Properties to set
                     */
                    function CameraSectionFilter(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CameraSectionFilter metadataTypeId.
                     * @member {number} metadataTypeId
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @instance
                     */
                    CameraSectionFilter.prototype.metadataTypeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSectionFilter metadataValueId.
                     * @member {number} metadataValueId
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @instance
                     */
                    CameraSectionFilter.prototype.metadataValueId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSectionFilter versionDateTime.
                     * @member {string} versionDateTime
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @instance
                     */
                    CameraSectionFilter.prototype.versionDateTime = "";
    
                    /**
                     * CameraSectionFilter versionId.
                     * @member {number} versionId
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @instance
                     */
                    CameraSectionFilter.prototype.versionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraSectionFilter dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @instance
                     */
                    CameraSectionFilter.prototype.dateId = "";
    
                    /**
                     * CameraSectionFilter isSystem.
                     * @member {boolean} isSystem
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @instance
                     */
                    CameraSectionFilter.prototype.isSystem = false;
    
                    /**
                     * Creates a new CameraSectionFilter instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @static
                     * @param {palexy.streaming.v1.ICameraSectionFilter=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CameraSectionFilter} CameraSectionFilter instance
                     */
                    CameraSectionFilter.create = function create(properties) {
                        return new CameraSectionFilter(properties);
                    };
    
                    /**
                     * Encodes the specified CameraSectionFilter message. Does not implicitly {@link palexy.streaming.v1.CameraSectionFilter.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @static
                     * @param {palexy.streaming.v1.ICameraSectionFilter} message CameraSectionFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraSectionFilter.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.metadataTypeId != null && Object.hasOwnProperty.call(message, "metadataTypeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.metadataTypeId);
                        if (message.metadataValueId != null && Object.hasOwnProperty.call(message, "metadataValueId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.metadataValueId);
                        if (message.versionDateTime != null && Object.hasOwnProperty.call(message, "versionDateTime"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.versionDateTime);
                        if (message.versionId != null && Object.hasOwnProperty.call(message, "versionId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.versionId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.dateId);
                        if (message.isSystem != null && Object.hasOwnProperty.call(message, "isSystem"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isSystem);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CameraSectionFilter message, length delimited. Does not implicitly {@link palexy.streaming.v1.CameraSectionFilter.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @static
                     * @param {palexy.streaming.v1.ICameraSectionFilter} message CameraSectionFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraSectionFilter.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CameraSectionFilter message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CameraSectionFilter} CameraSectionFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraSectionFilter.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CameraSectionFilter();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.metadataTypeId = reader.int64();
                                break;
                            case 2:
                                message.metadataValueId = reader.int64();
                                break;
                            case 3:
                                message.versionDateTime = reader.string();
                                break;
                            case 4:
                                message.versionId = reader.int64();
                                break;
                            case 5:
                                message.dateId = reader.string();
                                break;
                            case 6:
                                message.isSystem = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CameraSectionFilter message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CameraSectionFilter} CameraSectionFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraSectionFilter.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CameraSectionFilter message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CameraSectionFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.metadataTypeId != null && message.hasOwnProperty("metadataTypeId"))
                            if (!$util.isInteger(message.metadataTypeId) && !(message.metadataTypeId && $util.isInteger(message.metadataTypeId.low) && $util.isInteger(message.metadataTypeId.high)))
                                return "metadataTypeId: integer|Long expected";
                        if (message.metadataValueId != null && message.hasOwnProperty("metadataValueId"))
                            if (!$util.isInteger(message.metadataValueId) && !(message.metadataValueId && $util.isInteger(message.metadataValueId.low) && $util.isInteger(message.metadataValueId.high)))
                                return "metadataValueId: integer|Long expected";
                        if (message.versionDateTime != null && message.hasOwnProperty("versionDateTime"))
                            if (!$util.isString(message.versionDateTime))
                                return "versionDateTime: string expected";
                        if (message.versionId != null && message.hasOwnProperty("versionId"))
                            if (!$util.isInteger(message.versionId) && !(message.versionId && $util.isInteger(message.versionId.low) && $util.isInteger(message.versionId.high)))
                                return "versionId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.isSystem != null && message.hasOwnProperty("isSystem"))
                            if (typeof message.isSystem !== "boolean")
                                return "isSystem: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a CameraSectionFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CameraSectionFilter} CameraSectionFilter
                     */
                    CameraSectionFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CameraSectionFilter)
                            return object;
                        var message = new $root.palexy.streaming.v1.CameraSectionFilter();
                        if (object.metadataTypeId != null)
                            if ($util.Long)
                                (message.metadataTypeId = $util.Long.fromValue(object.metadataTypeId)).unsigned = false;
                            else if (typeof object.metadataTypeId === "string")
                                message.metadataTypeId = parseInt(object.metadataTypeId, 10);
                            else if (typeof object.metadataTypeId === "number")
                                message.metadataTypeId = object.metadataTypeId;
                            else if (typeof object.metadataTypeId === "object")
                                message.metadataTypeId = new $util.LongBits(object.metadataTypeId.low >>> 0, object.metadataTypeId.high >>> 0).toNumber();
                        if (object.metadataValueId != null)
                            if ($util.Long)
                                (message.metadataValueId = $util.Long.fromValue(object.metadataValueId)).unsigned = false;
                            else if (typeof object.metadataValueId === "string")
                                message.metadataValueId = parseInt(object.metadataValueId, 10);
                            else if (typeof object.metadataValueId === "number")
                                message.metadataValueId = object.metadataValueId;
                            else if (typeof object.metadataValueId === "object")
                                message.metadataValueId = new $util.LongBits(object.metadataValueId.low >>> 0, object.metadataValueId.high >>> 0).toNumber();
                        if (object.versionDateTime != null)
                            message.versionDateTime = String(object.versionDateTime);
                        if (object.versionId != null)
                            if ($util.Long)
                                (message.versionId = $util.Long.fromValue(object.versionId)).unsigned = false;
                            else if (typeof object.versionId === "string")
                                message.versionId = parseInt(object.versionId, 10);
                            else if (typeof object.versionId === "number")
                                message.versionId = object.versionId;
                            else if (typeof object.versionId === "object")
                                message.versionId = new $util.LongBits(object.versionId.low >>> 0, object.versionId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.isSystem != null)
                            message.isSystem = Boolean(object.isSystem);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CameraSectionFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @static
                     * @param {palexy.streaming.v1.CameraSectionFilter} message CameraSectionFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CameraSectionFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataTypeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataTypeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataValueId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataValueId = options.longs === String ? "0" : 0;
                            object.versionDateTime = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.versionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.versionId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.isSystem = false;
                        }
                        if (message.metadataTypeId != null && message.hasOwnProperty("metadataTypeId"))
                            if (typeof message.metadataTypeId === "number")
                                object.metadataTypeId = options.longs === String ? String(message.metadataTypeId) : message.metadataTypeId;
                            else
                                object.metadataTypeId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataTypeId) : options.longs === Number ? new $util.LongBits(message.metadataTypeId.low >>> 0, message.metadataTypeId.high >>> 0).toNumber() : message.metadataTypeId;
                        if (message.metadataValueId != null && message.hasOwnProperty("metadataValueId"))
                            if (typeof message.metadataValueId === "number")
                                object.metadataValueId = options.longs === String ? String(message.metadataValueId) : message.metadataValueId;
                            else
                                object.metadataValueId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataValueId) : options.longs === Number ? new $util.LongBits(message.metadataValueId.low >>> 0, message.metadataValueId.high >>> 0).toNumber() : message.metadataValueId;
                        if (message.versionDateTime != null && message.hasOwnProperty("versionDateTime"))
                            object.versionDateTime = message.versionDateTime;
                        if (message.versionId != null && message.hasOwnProperty("versionId"))
                            if (typeof message.versionId === "number")
                                object.versionId = options.longs === String ? String(message.versionId) : message.versionId;
                            else
                                object.versionId = options.longs === String ? $util.Long.prototype.toString.call(message.versionId) : options.longs === Number ? new $util.LongBits(message.versionId.low >>> 0, message.versionId.high >>> 0).toNumber() : message.versionId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.isSystem != null && message.hasOwnProperty("isSystem"))
                            object.isSystem = message.isSystem;
                        return object;
                    };
    
                    /**
                     * Converts this CameraSectionFilter to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CameraSectionFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CameraSectionFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CameraSectionFilter;
                })();
    
                v1.ListSystemCameraSectionsRequest = (function() {
    
                    /**
                     * Properties of a ListSystemCameraSectionsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListSystemCameraSectionsRequest
                     * @property {number|null} [cameraPrimaryId] ListSystemCameraSectionsRequest cameraPrimaryId
                     * @property {string|null} [cameraId] ListSystemCameraSectionsRequest cameraId
                     * @property {palexy.streaming.v1.ICameraSectionFilter|null} [filter] ListSystemCameraSectionsRequest filter
                     */
    
                    /**
                     * Constructs a new ListSystemCameraSectionsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListSystemCameraSectionsRequest.
                     * @implements IListSystemCameraSectionsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListSystemCameraSectionsRequest=} [properties] Properties to set
                     */
                    function ListSystemCameraSectionsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListSystemCameraSectionsRequest cameraPrimaryId.
                     * @member {number} cameraPrimaryId
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @instance
                     */
                    ListSystemCameraSectionsRequest.prototype.cameraPrimaryId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListSystemCameraSectionsRequest cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @instance
                     */
                    ListSystemCameraSectionsRequest.prototype.cameraId = "";
    
                    /**
                     * ListSystemCameraSectionsRequest filter.
                     * @member {palexy.streaming.v1.ICameraSectionFilter|null|undefined} filter
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @instance
                     */
                    ListSystemCameraSectionsRequest.prototype.filter = null;
    
                    /**
                     * Creates a new ListSystemCameraSectionsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSystemCameraSectionsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListSystemCameraSectionsRequest} ListSystemCameraSectionsRequest instance
                     */
                    ListSystemCameraSectionsRequest.create = function create(properties) {
                        return new ListSystemCameraSectionsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListSystemCameraSectionsRequest message. Does not implicitly {@link palexy.streaming.v1.ListSystemCameraSectionsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSystemCameraSectionsRequest} message ListSystemCameraSectionsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSystemCameraSectionsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraPrimaryId != null && Object.hasOwnProperty.call(message, "cameraPrimaryId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.cameraPrimaryId);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.cameraId);
                        if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                            $root.palexy.streaming.v1.CameraSectionFilter.encode(message.filter, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListSystemCameraSectionsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListSystemCameraSectionsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSystemCameraSectionsRequest} message ListSystemCameraSectionsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSystemCameraSectionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListSystemCameraSectionsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListSystemCameraSectionsRequest} ListSystemCameraSectionsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSystemCameraSectionsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListSystemCameraSectionsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraPrimaryId = reader.int64();
                                break;
                            case 2:
                                message.cameraId = reader.string();
                                break;
                            case 3:
                                message.filter = $root.palexy.streaming.v1.CameraSectionFilter.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListSystemCameraSectionsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListSystemCameraSectionsRequest} ListSystemCameraSectionsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSystemCameraSectionsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListSystemCameraSectionsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListSystemCameraSectionsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraPrimaryId != null && message.hasOwnProperty("cameraPrimaryId"))
                            if (!$util.isInteger(message.cameraPrimaryId) && !(message.cameraPrimaryId && $util.isInteger(message.cameraPrimaryId.low) && $util.isInteger(message.cameraPrimaryId.high)))
                                return "cameraPrimaryId: integer|Long expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.filter != null && message.hasOwnProperty("filter")) {
                            var error = $root.palexy.streaming.v1.CameraSectionFilter.verify(message.filter);
                            if (error)
                                return "filter." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListSystemCameraSectionsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListSystemCameraSectionsRequest} ListSystemCameraSectionsRequest
                     */
                    ListSystemCameraSectionsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListSystemCameraSectionsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListSystemCameraSectionsRequest();
                        if (object.cameraPrimaryId != null)
                            if ($util.Long)
                                (message.cameraPrimaryId = $util.Long.fromValue(object.cameraPrimaryId)).unsigned = false;
                            else if (typeof object.cameraPrimaryId === "string")
                                message.cameraPrimaryId = parseInt(object.cameraPrimaryId, 10);
                            else if (typeof object.cameraPrimaryId === "number")
                                message.cameraPrimaryId = object.cameraPrimaryId;
                            else if (typeof object.cameraPrimaryId === "object")
                                message.cameraPrimaryId = new $util.LongBits(object.cameraPrimaryId.low >>> 0, object.cameraPrimaryId.high >>> 0).toNumber();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.filter != null) {
                            if (typeof object.filter !== "object")
                                throw TypeError(".palexy.streaming.v1.ListSystemCameraSectionsRequest.filter: object expected");
                            message.filter = $root.palexy.streaming.v1.CameraSectionFilter.fromObject(object.filter);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListSystemCameraSectionsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @static
                     * @param {palexy.streaming.v1.ListSystemCameraSectionsRequest} message ListSystemCameraSectionsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListSystemCameraSectionsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraPrimaryId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraPrimaryId = options.longs === String ? "0" : 0;
                            object.cameraId = "";
                            object.filter = null;
                        }
                        if (message.cameraPrimaryId != null && message.hasOwnProperty("cameraPrimaryId"))
                            if (typeof message.cameraPrimaryId === "number")
                                object.cameraPrimaryId = options.longs === String ? String(message.cameraPrimaryId) : message.cameraPrimaryId;
                            else
                                object.cameraPrimaryId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraPrimaryId) : options.longs === Number ? new $util.LongBits(message.cameraPrimaryId.low >>> 0, message.cameraPrimaryId.high >>> 0).toNumber() : message.cameraPrimaryId;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.filter != null && message.hasOwnProperty("filter"))
                            object.filter = $root.palexy.streaming.v1.CameraSectionFilter.toObject(message.filter, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListSystemCameraSectionsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListSystemCameraSectionsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListSystemCameraSectionsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListSystemCameraSectionsRequest;
                })();
    
                v1.ListCameraSectionsRequest = (function() {
    
                    /**
                     * Properties of a ListCameraSectionsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListCameraSectionsRequest
                     * @property {number|null} [cameraPrimaryId] ListCameraSectionsRequest cameraPrimaryId
                     * @property {string|null} [cameraId] ListCameraSectionsRequest cameraId
                     * @property {palexy.streaming.v1.ICameraSectionFilter|null} [filter] ListCameraSectionsRequest filter
                     */
    
                    /**
                     * Constructs a new ListCameraSectionsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListCameraSectionsRequest.
                     * @implements IListCameraSectionsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListCameraSectionsRequest=} [properties] Properties to set
                     */
                    function ListCameraSectionsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCameraSectionsRequest cameraPrimaryId.
                     * @member {number} cameraPrimaryId
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @instance
                     */
                    ListCameraSectionsRequest.prototype.cameraPrimaryId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListCameraSectionsRequest cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @instance
                     */
                    ListCameraSectionsRequest.prototype.cameraId = "";
    
                    /**
                     * ListCameraSectionsRequest filter.
                     * @member {palexy.streaming.v1.ICameraSectionFilter|null|undefined} filter
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @instance
                     */
                    ListCameraSectionsRequest.prototype.filter = null;
    
                    /**
                     * Creates a new ListCameraSectionsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListCameraSectionsRequest} ListCameraSectionsRequest instance
                     */
                    ListCameraSectionsRequest.create = function create(properties) {
                        return new ListCameraSectionsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListCameraSectionsRequest message. Does not implicitly {@link palexy.streaming.v1.ListCameraSectionsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsRequest} message ListCameraSectionsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCameraSectionsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraPrimaryId != null && Object.hasOwnProperty.call(message, "cameraPrimaryId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.cameraPrimaryId);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.cameraId);
                        if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                            $root.palexy.streaming.v1.CameraSectionFilter.encode(message.filter, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCameraSectionsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListCameraSectionsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsRequest} message ListCameraSectionsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCameraSectionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCameraSectionsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListCameraSectionsRequest} ListCameraSectionsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCameraSectionsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListCameraSectionsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraPrimaryId = reader.int64();
                                break;
                            case 2:
                                message.cameraId = reader.string();
                                break;
                            case 3:
                                message.filter = $root.palexy.streaming.v1.CameraSectionFilter.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCameraSectionsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListCameraSectionsRequest} ListCameraSectionsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCameraSectionsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCameraSectionsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCameraSectionsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraPrimaryId != null && message.hasOwnProperty("cameraPrimaryId"))
                            if (!$util.isInteger(message.cameraPrimaryId) && !(message.cameraPrimaryId && $util.isInteger(message.cameraPrimaryId.low) && $util.isInteger(message.cameraPrimaryId.high)))
                                return "cameraPrimaryId: integer|Long expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.filter != null && message.hasOwnProperty("filter")) {
                            var error = $root.palexy.streaming.v1.CameraSectionFilter.verify(message.filter);
                            if (error)
                                return "filter." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListCameraSectionsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListCameraSectionsRequest} ListCameraSectionsRequest
                     */
                    ListCameraSectionsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListCameraSectionsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListCameraSectionsRequest();
                        if (object.cameraPrimaryId != null)
                            if ($util.Long)
                                (message.cameraPrimaryId = $util.Long.fromValue(object.cameraPrimaryId)).unsigned = false;
                            else if (typeof object.cameraPrimaryId === "string")
                                message.cameraPrimaryId = parseInt(object.cameraPrimaryId, 10);
                            else if (typeof object.cameraPrimaryId === "number")
                                message.cameraPrimaryId = object.cameraPrimaryId;
                            else if (typeof object.cameraPrimaryId === "object")
                                message.cameraPrimaryId = new $util.LongBits(object.cameraPrimaryId.low >>> 0, object.cameraPrimaryId.high >>> 0).toNumber();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.filter != null) {
                            if (typeof object.filter !== "object")
                                throw TypeError(".palexy.streaming.v1.ListCameraSectionsRequest.filter: object expected");
                            message.filter = $root.palexy.streaming.v1.CameraSectionFilter.fromObject(object.filter);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCameraSectionsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @static
                     * @param {palexy.streaming.v1.ListCameraSectionsRequest} message ListCameraSectionsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCameraSectionsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraPrimaryId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraPrimaryId = options.longs === String ? "0" : 0;
                            object.cameraId = "";
                            object.filter = null;
                        }
                        if (message.cameraPrimaryId != null && message.hasOwnProperty("cameraPrimaryId"))
                            if (typeof message.cameraPrimaryId === "number")
                                object.cameraPrimaryId = options.longs === String ? String(message.cameraPrimaryId) : message.cameraPrimaryId;
                            else
                                object.cameraPrimaryId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraPrimaryId) : options.longs === Number ? new $util.LongBits(message.cameraPrimaryId.low >>> 0, message.cameraPrimaryId.high >>> 0).toNumber() : message.cameraPrimaryId;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.filter != null && message.hasOwnProperty("filter"))
                            object.filter = $root.palexy.streaming.v1.CameraSectionFilter.toObject(message.filter, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListCameraSectionsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListCameraSectionsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCameraSectionsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCameraSectionsRequest;
                })();
    
                v1.ListCameraSectionsResponse = (function() {
    
                    /**
                     * Properties of a ListCameraSectionsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListCameraSectionsResponse
                     * @property {Array.<palexy.streaming.v1.ICameraSection>|null} [section] ListCameraSectionsResponse section
                     */
    
                    /**
                     * Constructs a new ListCameraSectionsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListCameraSectionsResponse.
                     * @implements IListCameraSectionsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListCameraSectionsResponse=} [properties] Properties to set
                     */
                    function ListCameraSectionsResponse(properties) {
                        this.section = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCameraSectionsResponse section.
                     * @member {Array.<palexy.streaming.v1.ICameraSection>} section
                     * @memberof palexy.streaming.v1.ListCameraSectionsResponse
                     * @instance
                     */
                    ListCameraSectionsResponse.prototype.section = $util.emptyArray;
    
                    /**
                     * Creates a new ListCameraSectionsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListCameraSectionsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListCameraSectionsResponse} ListCameraSectionsResponse instance
                     */
                    ListCameraSectionsResponse.create = function create(properties) {
                        return new ListCameraSectionsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListCameraSectionsResponse message. Does not implicitly {@link palexy.streaming.v1.ListCameraSectionsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListCameraSectionsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsResponse} message ListCameraSectionsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCameraSectionsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.section != null && message.section.length)
                            for (var i = 0; i < message.section.length; ++i)
                                $root.palexy.streaming.v1.CameraSection.encode(message.section[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCameraSectionsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListCameraSectionsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListCameraSectionsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsResponse} message ListCameraSectionsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCameraSectionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCameraSectionsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListCameraSectionsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListCameraSectionsResponse} ListCameraSectionsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCameraSectionsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListCameraSectionsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.section && message.section.length))
                                    message.section = [];
                                message.section.push($root.palexy.streaming.v1.CameraSection.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCameraSectionsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListCameraSectionsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListCameraSectionsResponse} ListCameraSectionsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCameraSectionsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCameraSectionsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListCameraSectionsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCameraSectionsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.section != null && message.hasOwnProperty("section")) {
                            if (!Array.isArray(message.section))
                                return "section: array expected";
                            for (var i = 0; i < message.section.length; ++i) {
                                var error = $root.palexy.streaming.v1.CameraSection.verify(message.section[i]);
                                if (error)
                                    return "section." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListCameraSectionsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListCameraSectionsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListCameraSectionsResponse} ListCameraSectionsResponse
                     */
                    ListCameraSectionsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListCameraSectionsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListCameraSectionsResponse();
                        if (object.section) {
                            if (!Array.isArray(object.section))
                                throw TypeError(".palexy.streaming.v1.ListCameraSectionsResponse.section: array expected");
                            message.section = [];
                            for (var i = 0; i < object.section.length; ++i) {
                                if (typeof object.section[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListCameraSectionsResponse.section: object expected");
                                message.section[i] = $root.palexy.streaming.v1.CameraSection.fromObject(object.section[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCameraSectionsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListCameraSectionsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListCameraSectionsResponse} message ListCameraSectionsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCameraSectionsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.section = [];
                        if (message.section && message.section.length) {
                            object.section = [];
                            for (var j = 0; j < message.section.length; ++j)
                                object.section[j] = $root.palexy.streaming.v1.CameraSection.toObject(message.section[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListCameraSectionsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListCameraSectionsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCameraSectionsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCameraSectionsResponse;
                })();
    
                v1.ListCameraSectionsOfLayoutRequest = (function() {
    
                    /**
                     * Properties of a ListCameraSectionsOfLayoutRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListCameraSectionsOfLayoutRequest
                     * @property {string|null} [cameraId] ListCameraSectionsOfLayoutRequest cameraId
                     * @property {Array.<number>|null} [layoutIds] ListCameraSectionsOfLayoutRequest layoutIds
                     */
    
                    /**
                     * Constructs a new ListCameraSectionsOfLayoutRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListCameraSectionsOfLayoutRequest.
                     * @implements IListCameraSectionsOfLayoutRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListCameraSectionsOfLayoutRequest=} [properties] Properties to set
                     */
                    function ListCameraSectionsOfLayoutRequest(properties) {
                        this.layoutIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCameraSectionsOfLayoutRequest cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutRequest
                     * @instance
                     */
                    ListCameraSectionsOfLayoutRequest.prototype.cameraId = "";
    
                    /**
                     * ListCameraSectionsOfLayoutRequest layoutIds.
                     * @member {Array.<number>} layoutIds
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutRequest
                     * @instance
                     */
                    ListCameraSectionsOfLayoutRequest.prototype.layoutIds = $util.emptyArray;
    
                    /**
                     * Creates a new ListCameraSectionsOfLayoutRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsOfLayoutRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListCameraSectionsOfLayoutRequest} ListCameraSectionsOfLayoutRequest instance
                     */
                    ListCameraSectionsOfLayoutRequest.create = function create(properties) {
                        return new ListCameraSectionsOfLayoutRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListCameraSectionsOfLayoutRequest message. Does not implicitly {@link palexy.streaming.v1.ListCameraSectionsOfLayoutRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsOfLayoutRequest} message ListCameraSectionsOfLayoutRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCameraSectionsOfLayoutRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cameraId);
                        if (message.layoutIds != null && message.layoutIds.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.layoutIds.length; ++i)
                                writer.int64(message.layoutIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCameraSectionsOfLayoutRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListCameraSectionsOfLayoutRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsOfLayoutRequest} message ListCameraSectionsOfLayoutRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCameraSectionsOfLayoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCameraSectionsOfLayoutRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListCameraSectionsOfLayoutRequest} ListCameraSectionsOfLayoutRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCameraSectionsOfLayoutRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListCameraSectionsOfLayoutRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraId = reader.string();
                                break;
                            case 2:
                                if (!(message.layoutIds && message.layoutIds.length))
                                    message.layoutIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.layoutIds.push(reader.int64());
                                } else
                                    message.layoutIds.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCameraSectionsOfLayoutRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListCameraSectionsOfLayoutRequest} ListCameraSectionsOfLayoutRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCameraSectionsOfLayoutRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCameraSectionsOfLayoutRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCameraSectionsOfLayoutRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.layoutIds != null && message.hasOwnProperty("layoutIds")) {
                            if (!Array.isArray(message.layoutIds))
                                return "layoutIds: array expected";
                            for (var i = 0; i < message.layoutIds.length; ++i)
                                if (!$util.isInteger(message.layoutIds[i]) && !(message.layoutIds[i] && $util.isInteger(message.layoutIds[i].low) && $util.isInteger(message.layoutIds[i].high)))
                                    return "layoutIds: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListCameraSectionsOfLayoutRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListCameraSectionsOfLayoutRequest} ListCameraSectionsOfLayoutRequest
                     */
                    ListCameraSectionsOfLayoutRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListCameraSectionsOfLayoutRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListCameraSectionsOfLayoutRequest();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.layoutIds) {
                            if (!Array.isArray(object.layoutIds))
                                throw TypeError(".palexy.streaming.v1.ListCameraSectionsOfLayoutRequest.layoutIds: array expected");
                            message.layoutIds = [];
                            for (var i = 0; i < object.layoutIds.length; ++i)
                                if ($util.Long)
                                    (message.layoutIds[i] = $util.Long.fromValue(object.layoutIds[i])).unsigned = false;
                                else if (typeof object.layoutIds[i] === "string")
                                    message.layoutIds[i] = parseInt(object.layoutIds[i], 10);
                                else if (typeof object.layoutIds[i] === "number")
                                    message.layoutIds[i] = object.layoutIds[i];
                                else if (typeof object.layoutIds[i] === "object")
                                    message.layoutIds[i] = new $util.LongBits(object.layoutIds[i].low >>> 0, object.layoutIds[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCameraSectionsOfLayoutRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutRequest
                     * @static
                     * @param {palexy.streaming.v1.ListCameraSectionsOfLayoutRequest} message ListCameraSectionsOfLayoutRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCameraSectionsOfLayoutRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.layoutIds = [];
                        if (options.defaults)
                            object.cameraId = "";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.layoutIds && message.layoutIds.length) {
                            object.layoutIds = [];
                            for (var j = 0; j < message.layoutIds.length; ++j)
                                if (typeof message.layoutIds[j] === "number")
                                    object.layoutIds[j] = options.longs === String ? String(message.layoutIds[j]) : message.layoutIds[j];
                                else
                                    object.layoutIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.layoutIds[j]) : options.longs === Number ? new $util.LongBits(message.layoutIds[j].low >>> 0, message.layoutIds[j].high >>> 0).toNumber() : message.layoutIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListCameraSectionsOfLayoutRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCameraSectionsOfLayoutRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCameraSectionsOfLayoutRequest;
                })();
    
                v1.ListCameraSectionsOfLayoutResponse = (function() {
    
                    /**
                     * Properties of a ListCameraSectionsOfLayoutResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListCameraSectionsOfLayoutResponse
                     * @property {Array.<palexy.streaming.v1.ICameraSection>|null} [sections] ListCameraSectionsOfLayoutResponse sections
                     */
    
                    /**
                     * Constructs a new ListCameraSectionsOfLayoutResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListCameraSectionsOfLayoutResponse.
                     * @implements IListCameraSectionsOfLayoutResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListCameraSectionsOfLayoutResponse=} [properties] Properties to set
                     */
                    function ListCameraSectionsOfLayoutResponse(properties) {
                        this.sections = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCameraSectionsOfLayoutResponse sections.
                     * @member {Array.<palexy.streaming.v1.ICameraSection>} sections
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutResponse
                     * @instance
                     */
                    ListCameraSectionsOfLayoutResponse.prototype.sections = $util.emptyArray;
    
                    /**
                     * Creates a new ListCameraSectionsOfLayoutResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsOfLayoutResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListCameraSectionsOfLayoutResponse} ListCameraSectionsOfLayoutResponse instance
                     */
                    ListCameraSectionsOfLayoutResponse.create = function create(properties) {
                        return new ListCameraSectionsOfLayoutResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListCameraSectionsOfLayoutResponse message. Does not implicitly {@link palexy.streaming.v1.ListCameraSectionsOfLayoutResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsOfLayoutResponse} message ListCameraSectionsOfLayoutResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCameraSectionsOfLayoutResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sections != null && message.sections.length)
                            for (var i = 0; i < message.sections.length; ++i)
                                $root.palexy.streaming.v1.CameraSection.encode(message.sections[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCameraSectionsOfLayoutResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListCameraSectionsOfLayoutResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCameraSectionsOfLayoutResponse} message ListCameraSectionsOfLayoutResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCameraSectionsOfLayoutResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCameraSectionsOfLayoutResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListCameraSectionsOfLayoutResponse} ListCameraSectionsOfLayoutResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCameraSectionsOfLayoutResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListCameraSectionsOfLayoutResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.sections && message.sections.length))
                                    message.sections = [];
                                message.sections.push($root.palexy.streaming.v1.CameraSection.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCameraSectionsOfLayoutResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListCameraSectionsOfLayoutResponse} ListCameraSectionsOfLayoutResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCameraSectionsOfLayoutResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCameraSectionsOfLayoutResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCameraSectionsOfLayoutResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sections != null && message.hasOwnProperty("sections")) {
                            if (!Array.isArray(message.sections))
                                return "sections: array expected";
                            for (var i = 0; i < message.sections.length; ++i) {
                                var error = $root.palexy.streaming.v1.CameraSection.verify(message.sections[i]);
                                if (error)
                                    return "sections." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListCameraSectionsOfLayoutResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListCameraSectionsOfLayoutResponse} ListCameraSectionsOfLayoutResponse
                     */
                    ListCameraSectionsOfLayoutResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListCameraSectionsOfLayoutResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListCameraSectionsOfLayoutResponse();
                        if (object.sections) {
                            if (!Array.isArray(object.sections))
                                throw TypeError(".palexy.streaming.v1.ListCameraSectionsOfLayoutResponse.sections: array expected");
                            message.sections = [];
                            for (var i = 0; i < object.sections.length; ++i) {
                                if (typeof object.sections[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListCameraSectionsOfLayoutResponse.sections: object expected");
                                message.sections[i] = $root.palexy.streaming.v1.CameraSection.fromObject(object.sections[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCameraSectionsOfLayoutResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutResponse
                     * @static
                     * @param {palexy.streaming.v1.ListCameraSectionsOfLayoutResponse} message ListCameraSectionsOfLayoutResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCameraSectionsOfLayoutResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.sections = [];
                        if (message.sections && message.sections.length) {
                            object.sections = [];
                            for (var j = 0; j < message.sections.length; ++j)
                                object.sections[j] = $root.palexy.streaming.v1.CameraSection.toObject(message.sections[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListCameraSectionsOfLayoutResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListCameraSectionsOfLayoutResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCameraSectionsOfLayoutResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCameraSectionsOfLayoutResponse;
                })();
    
                v1.EntranceMergingConfig = (function() {
    
                    /**
                     * Properties of an EntranceMergingConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IEntranceMergingConfig
                     * @property {number|null} [numHistoryItemsPerTracklet] EntranceMergingConfig numHistoryItemsPerTracklet
                     * @property {number|null} [maximumTimeDistanceSeconds] EntranceMergingConfig maximumTimeDistanceSeconds
                     * @property {number|null} [costThresholdToSplitNewPerson] EntranceMergingConfig costThresholdToSplitNewPerson
                     * @property {number|null} [maxIouOverlapping] EntranceMergingConfig maxIouOverlapping
                     * @property {number|null} [maxAngleToBeGoingIn] EntranceMergingConfig maxAngleToBeGoingIn
                     * @property {number|null} [percentageOfLessThanMaxAngle] EntranceMergingConfig percentageOfLessThanMaxAngle
                     */
    
                    /**
                     * Constructs a new EntranceMergingConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an EntranceMergingConfig.
                     * @implements IEntranceMergingConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IEntranceMergingConfig=} [properties] Properties to set
                     */
                    function EntranceMergingConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * EntranceMergingConfig numHistoryItemsPerTracklet.
                     * @member {number} numHistoryItemsPerTracklet
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @instance
                     */
                    EntranceMergingConfig.prototype.numHistoryItemsPerTracklet = 0;
    
                    /**
                     * EntranceMergingConfig maximumTimeDistanceSeconds.
                     * @member {number} maximumTimeDistanceSeconds
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @instance
                     */
                    EntranceMergingConfig.prototype.maximumTimeDistanceSeconds = 0;
    
                    /**
                     * EntranceMergingConfig costThresholdToSplitNewPerson.
                     * @member {number} costThresholdToSplitNewPerson
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @instance
                     */
                    EntranceMergingConfig.prototype.costThresholdToSplitNewPerson = 0;
    
                    /**
                     * EntranceMergingConfig maxIouOverlapping.
                     * @member {number} maxIouOverlapping
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @instance
                     */
                    EntranceMergingConfig.prototype.maxIouOverlapping = 0;
    
                    /**
                     * EntranceMergingConfig maxAngleToBeGoingIn.
                     * @member {number} maxAngleToBeGoingIn
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @instance
                     */
                    EntranceMergingConfig.prototype.maxAngleToBeGoingIn = 0;
    
                    /**
                     * EntranceMergingConfig percentageOfLessThanMaxAngle.
                     * @member {number} percentageOfLessThanMaxAngle
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @instance
                     */
                    EntranceMergingConfig.prototype.percentageOfLessThanMaxAngle = 0;
    
                    /**
                     * Creates a new EntranceMergingConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IEntranceMergingConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.EntranceMergingConfig} EntranceMergingConfig instance
                     */
                    EntranceMergingConfig.create = function create(properties) {
                        return new EntranceMergingConfig(properties);
                    };
    
                    /**
                     * Encodes the specified EntranceMergingConfig message. Does not implicitly {@link palexy.streaming.v1.EntranceMergingConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IEntranceMergingConfig} message EntranceMergingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EntranceMergingConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.numHistoryItemsPerTracklet != null && Object.hasOwnProperty.call(message, "numHistoryItemsPerTracklet"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.numHistoryItemsPerTracklet);
                        if (message.maximumTimeDistanceSeconds != null && Object.hasOwnProperty.call(message, "maximumTimeDistanceSeconds"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maximumTimeDistanceSeconds);
                        if (message.costThresholdToSplitNewPerson != null && Object.hasOwnProperty.call(message, "costThresholdToSplitNewPerson"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.costThresholdToSplitNewPerson);
                        if (message.maxIouOverlapping != null && Object.hasOwnProperty.call(message, "maxIouOverlapping"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.maxIouOverlapping);
                        if (message.maxAngleToBeGoingIn != null && Object.hasOwnProperty.call(message, "maxAngleToBeGoingIn"))
                            writer.uint32(/* id 5, wireType 5 =*/45).float(message.maxAngleToBeGoingIn);
                        if (message.percentageOfLessThanMaxAngle != null && Object.hasOwnProperty.call(message, "percentageOfLessThanMaxAngle"))
                            writer.uint32(/* id 6, wireType 5 =*/53).float(message.percentageOfLessThanMaxAngle);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified EntranceMergingConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.EntranceMergingConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IEntranceMergingConfig} message EntranceMergingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EntranceMergingConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an EntranceMergingConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.EntranceMergingConfig} EntranceMergingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EntranceMergingConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.EntranceMergingConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.numHistoryItemsPerTracklet = reader.int32();
                                break;
                            case 2:
                                message.maximumTimeDistanceSeconds = reader.int32();
                                break;
                            case 3:
                                message.costThresholdToSplitNewPerson = reader.float();
                                break;
                            case 4:
                                message.maxIouOverlapping = reader.float();
                                break;
                            case 5:
                                message.maxAngleToBeGoingIn = reader.float();
                                break;
                            case 6:
                                message.percentageOfLessThanMaxAngle = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an EntranceMergingConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.EntranceMergingConfig} EntranceMergingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EntranceMergingConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an EntranceMergingConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EntranceMergingConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.numHistoryItemsPerTracklet != null && message.hasOwnProperty("numHistoryItemsPerTracklet"))
                            if (!$util.isInteger(message.numHistoryItemsPerTracklet))
                                return "numHistoryItemsPerTracklet: integer expected";
                        if (message.maximumTimeDistanceSeconds != null && message.hasOwnProperty("maximumTimeDistanceSeconds"))
                            if (!$util.isInteger(message.maximumTimeDistanceSeconds))
                                return "maximumTimeDistanceSeconds: integer expected";
                        if (message.costThresholdToSplitNewPerson != null && message.hasOwnProperty("costThresholdToSplitNewPerson"))
                            if (typeof message.costThresholdToSplitNewPerson !== "number")
                                return "costThresholdToSplitNewPerson: number expected";
                        if (message.maxIouOverlapping != null && message.hasOwnProperty("maxIouOverlapping"))
                            if (typeof message.maxIouOverlapping !== "number")
                                return "maxIouOverlapping: number expected";
                        if (message.maxAngleToBeGoingIn != null && message.hasOwnProperty("maxAngleToBeGoingIn"))
                            if (typeof message.maxAngleToBeGoingIn !== "number")
                                return "maxAngleToBeGoingIn: number expected";
                        if (message.percentageOfLessThanMaxAngle != null && message.hasOwnProperty("percentageOfLessThanMaxAngle"))
                            if (typeof message.percentageOfLessThanMaxAngle !== "number")
                                return "percentageOfLessThanMaxAngle: number expected";
                        return null;
                    };
    
                    /**
                     * Creates an EntranceMergingConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.EntranceMergingConfig} EntranceMergingConfig
                     */
                    EntranceMergingConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.EntranceMergingConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.EntranceMergingConfig();
                        if (object.numHistoryItemsPerTracklet != null)
                            message.numHistoryItemsPerTracklet = object.numHistoryItemsPerTracklet | 0;
                        if (object.maximumTimeDistanceSeconds != null)
                            message.maximumTimeDistanceSeconds = object.maximumTimeDistanceSeconds | 0;
                        if (object.costThresholdToSplitNewPerson != null)
                            message.costThresholdToSplitNewPerson = Number(object.costThresholdToSplitNewPerson);
                        if (object.maxIouOverlapping != null)
                            message.maxIouOverlapping = Number(object.maxIouOverlapping);
                        if (object.maxAngleToBeGoingIn != null)
                            message.maxAngleToBeGoingIn = Number(object.maxAngleToBeGoingIn);
                        if (object.percentageOfLessThanMaxAngle != null)
                            message.percentageOfLessThanMaxAngle = Number(object.percentageOfLessThanMaxAngle);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an EntranceMergingConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.EntranceMergingConfig} message EntranceMergingConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EntranceMergingConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.numHistoryItemsPerTracklet = 0;
                            object.maximumTimeDistanceSeconds = 0;
                            object.costThresholdToSplitNewPerson = 0;
                            object.maxIouOverlapping = 0;
                            object.maxAngleToBeGoingIn = 0;
                            object.percentageOfLessThanMaxAngle = 0;
                        }
                        if (message.numHistoryItemsPerTracklet != null && message.hasOwnProperty("numHistoryItemsPerTracklet"))
                            object.numHistoryItemsPerTracklet = message.numHistoryItemsPerTracklet;
                        if (message.maximumTimeDistanceSeconds != null && message.hasOwnProperty("maximumTimeDistanceSeconds"))
                            object.maximumTimeDistanceSeconds = message.maximumTimeDistanceSeconds;
                        if (message.costThresholdToSplitNewPerson != null && message.hasOwnProperty("costThresholdToSplitNewPerson"))
                            object.costThresholdToSplitNewPerson = options.json && !isFinite(message.costThresholdToSplitNewPerson) ? String(message.costThresholdToSplitNewPerson) : message.costThresholdToSplitNewPerson;
                        if (message.maxIouOverlapping != null && message.hasOwnProperty("maxIouOverlapping"))
                            object.maxIouOverlapping = options.json && !isFinite(message.maxIouOverlapping) ? String(message.maxIouOverlapping) : message.maxIouOverlapping;
                        if (message.maxAngleToBeGoingIn != null && message.hasOwnProperty("maxAngleToBeGoingIn"))
                            object.maxAngleToBeGoingIn = options.json && !isFinite(message.maxAngleToBeGoingIn) ? String(message.maxAngleToBeGoingIn) : message.maxAngleToBeGoingIn;
                        if (message.percentageOfLessThanMaxAngle != null && message.hasOwnProperty("percentageOfLessThanMaxAngle"))
                            object.percentageOfLessThanMaxAngle = options.json && !isFinite(message.percentageOfLessThanMaxAngle) ? String(message.percentageOfLessThanMaxAngle) : message.percentageOfLessThanMaxAngle;
                        return object;
                    };
    
                    /**
                     * Converts this EntranceMergingConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.EntranceMergingConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EntranceMergingConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return EntranceMergingConfig;
                })();
    
                v1.QueueTimeMergingConfig = (function() {
    
                    /**
                     * Properties of a QueueTimeMergingConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IQueueTimeMergingConfig
                     * @property {number|null} [minIouOverlapping] QueueTimeMergingConfig minIouOverlapping
                     * @property {number|null} [numHistoryItemsPerTracklet] QueueTimeMergingConfig numHistoryItemsPerTracklet
                     * @property {number|null} [maximumTimeDistanceSeconds] QueueTimeMergingConfig maximumTimeDistanceSeconds
                     * @property {number|null} [costThresholdToSplitNewPerson] QueueTimeMergingConfig costThresholdToSplitNewPerson
                     * @property {number|null} [minimumLengthOfTrackletsPreFullMerging] QueueTimeMergingConfig minimumLengthOfTrackletsPreFullMerging
                     */
    
                    /**
                     * Constructs a new QueueTimeMergingConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a QueueTimeMergingConfig.
                     * @implements IQueueTimeMergingConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IQueueTimeMergingConfig=} [properties] Properties to set
                     */
                    function QueueTimeMergingConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * QueueTimeMergingConfig minIouOverlapping.
                     * @member {number} minIouOverlapping
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @instance
                     */
                    QueueTimeMergingConfig.prototype.minIouOverlapping = 0;
    
                    /**
                     * QueueTimeMergingConfig numHistoryItemsPerTracklet.
                     * @member {number} numHistoryItemsPerTracklet
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @instance
                     */
                    QueueTimeMergingConfig.prototype.numHistoryItemsPerTracklet = 0;
    
                    /**
                     * QueueTimeMergingConfig maximumTimeDistanceSeconds.
                     * @member {number} maximumTimeDistanceSeconds
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @instance
                     */
                    QueueTimeMergingConfig.prototype.maximumTimeDistanceSeconds = 0;
    
                    /**
                     * QueueTimeMergingConfig costThresholdToSplitNewPerson.
                     * @member {number} costThresholdToSplitNewPerson
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @instance
                     */
                    QueueTimeMergingConfig.prototype.costThresholdToSplitNewPerson = 0;
    
                    /**
                     * QueueTimeMergingConfig minimumLengthOfTrackletsPreFullMerging.
                     * @member {number} minimumLengthOfTrackletsPreFullMerging
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @instance
                     */
                    QueueTimeMergingConfig.prototype.minimumLengthOfTrackletsPreFullMerging = 0;
    
                    /**
                     * Creates a new QueueTimeMergingConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IQueueTimeMergingConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.QueueTimeMergingConfig} QueueTimeMergingConfig instance
                     */
                    QueueTimeMergingConfig.create = function create(properties) {
                        return new QueueTimeMergingConfig(properties);
                    };
    
                    /**
                     * Encodes the specified QueueTimeMergingConfig message. Does not implicitly {@link palexy.streaming.v1.QueueTimeMergingConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IQueueTimeMergingConfig} message QueueTimeMergingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QueueTimeMergingConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minIouOverlapping != null && Object.hasOwnProperty.call(message, "minIouOverlapping"))
                            writer.uint32(/* id 1, wireType 5 =*/13).float(message.minIouOverlapping);
                        if (message.numHistoryItemsPerTracklet != null && Object.hasOwnProperty.call(message, "numHistoryItemsPerTracklet"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.numHistoryItemsPerTracklet);
                        if (message.maximumTimeDistanceSeconds != null && Object.hasOwnProperty.call(message, "maximumTimeDistanceSeconds"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.maximumTimeDistanceSeconds);
                        if (message.costThresholdToSplitNewPerson != null && Object.hasOwnProperty.call(message, "costThresholdToSplitNewPerson"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.costThresholdToSplitNewPerson);
                        if (message.minimumLengthOfTrackletsPreFullMerging != null && Object.hasOwnProperty.call(message, "minimumLengthOfTrackletsPreFullMerging"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.minimumLengthOfTrackletsPreFullMerging);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified QueueTimeMergingConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.QueueTimeMergingConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IQueueTimeMergingConfig} message QueueTimeMergingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QueueTimeMergingConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a QueueTimeMergingConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.QueueTimeMergingConfig} QueueTimeMergingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QueueTimeMergingConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.QueueTimeMergingConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.minIouOverlapping = reader.float();
                                break;
                            case 2:
                                message.numHistoryItemsPerTracklet = reader.int32();
                                break;
                            case 3:
                                message.maximumTimeDistanceSeconds = reader.int32();
                                break;
                            case 4:
                                message.costThresholdToSplitNewPerson = reader.float();
                                break;
                            case 5:
                                message.minimumLengthOfTrackletsPreFullMerging = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a QueueTimeMergingConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.QueueTimeMergingConfig} QueueTimeMergingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QueueTimeMergingConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a QueueTimeMergingConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    QueueTimeMergingConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minIouOverlapping != null && message.hasOwnProperty("minIouOverlapping"))
                            if (typeof message.minIouOverlapping !== "number")
                                return "minIouOverlapping: number expected";
                        if (message.numHistoryItemsPerTracklet != null && message.hasOwnProperty("numHistoryItemsPerTracklet"))
                            if (!$util.isInteger(message.numHistoryItemsPerTracklet))
                                return "numHistoryItemsPerTracklet: integer expected";
                        if (message.maximumTimeDistanceSeconds != null && message.hasOwnProperty("maximumTimeDistanceSeconds"))
                            if (!$util.isInteger(message.maximumTimeDistanceSeconds))
                                return "maximumTimeDistanceSeconds: integer expected";
                        if (message.costThresholdToSplitNewPerson != null && message.hasOwnProperty("costThresholdToSplitNewPerson"))
                            if (typeof message.costThresholdToSplitNewPerson !== "number")
                                return "costThresholdToSplitNewPerson: number expected";
                        if (message.minimumLengthOfTrackletsPreFullMerging != null && message.hasOwnProperty("minimumLengthOfTrackletsPreFullMerging"))
                            if (!$util.isInteger(message.minimumLengthOfTrackletsPreFullMerging))
                                return "minimumLengthOfTrackletsPreFullMerging: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a QueueTimeMergingConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.QueueTimeMergingConfig} QueueTimeMergingConfig
                     */
                    QueueTimeMergingConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.QueueTimeMergingConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.QueueTimeMergingConfig();
                        if (object.minIouOverlapping != null)
                            message.minIouOverlapping = Number(object.minIouOverlapping);
                        if (object.numHistoryItemsPerTracklet != null)
                            message.numHistoryItemsPerTracklet = object.numHistoryItemsPerTracklet | 0;
                        if (object.maximumTimeDistanceSeconds != null)
                            message.maximumTimeDistanceSeconds = object.maximumTimeDistanceSeconds | 0;
                        if (object.costThresholdToSplitNewPerson != null)
                            message.costThresholdToSplitNewPerson = Number(object.costThresholdToSplitNewPerson);
                        if (object.minimumLengthOfTrackletsPreFullMerging != null)
                            message.minimumLengthOfTrackletsPreFullMerging = object.minimumLengthOfTrackletsPreFullMerging | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a QueueTimeMergingConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.QueueTimeMergingConfig} message QueueTimeMergingConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    QueueTimeMergingConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minIouOverlapping = 0;
                            object.numHistoryItemsPerTracklet = 0;
                            object.maximumTimeDistanceSeconds = 0;
                            object.costThresholdToSplitNewPerson = 0;
                            object.minimumLengthOfTrackletsPreFullMerging = 0;
                        }
                        if (message.minIouOverlapping != null && message.hasOwnProperty("minIouOverlapping"))
                            object.minIouOverlapping = options.json && !isFinite(message.minIouOverlapping) ? String(message.minIouOverlapping) : message.minIouOverlapping;
                        if (message.numHistoryItemsPerTracklet != null && message.hasOwnProperty("numHistoryItemsPerTracklet"))
                            object.numHistoryItemsPerTracklet = message.numHistoryItemsPerTracklet;
                        if (message.maximumTimeDistanceSeconds != null && message.hasOwnProperty("maximumTimeDistanceSeconds"))
                            object.maximumTimeDistanceSeconds = message.maximumTimeDistanceSeconds;
                        if (message.costThresholdToSplitNewPerson != null && message.hasOwnProperty("costThresholdToSplitNewPerson"))
                            object.costThresholdToSplitNewPerson = options.json && !isFinite(message.costThresholdToSplitNewPerson) ? String(message.costThresholdToSplitNewPerson) : message.costThresholdToSplitNewPerson;
                        if (message.minimumLengthOfTrackletsPreFullMerging != null && message.hasOwnProperty("minimumLengthOfTrackletsPreFullMerging"))
                            object.minimumLengthOfTrackletsPreFullMerging = message.minimumLengthOfTrackletsPreFullMerging;
                        return object;
                    };
    
                    /**
                     * Converts this QueueTimeMergingConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.QueueTimeMergingConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    QueueTimeMergingConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return QueueTimeMergingConfig;
                })();
    
                v1.SectionMergingConfig = (function() {
    
                    /**
                     * Properties of a SectionMergingConfig.
                     * @memberof palexy.streaming.v1
                     * @interface ISectionMergingConfig
                     * @property {number|null} [minIouOverlapping] SectionMergingConfig minIouOverlapping
                     * @property {number|null} [numHistoryItemsPerTracklet] SectionMergingConfig numHistoryItemsPerTracklet
                     * @property {number|null} [maximumTimeDistanceSeconds] SectionMergingConfig maximumTimeDistanceSeconds
                     * @property {number|null} [costThresholdToSplitNewPerson] SectionMergingConfig costThresholdToSplitNewPerson
                     * @property {number|null} [minimumLengthOfTrackletsPreFullMerging] SectionMergingConfig minimumLengthOfTrackletsPreFullMerging
                     */
    
                    /**
                     * Constructs a new SectionMergingConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SectionMergingConfig.
                     * @implements ISectionMergingConfig
                     * @constructor
                     * @param {palexy.streaming.v1.ISectionMergingConfig=} [properties] Properties to set
                     */
                    function SectionMergingConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SectionMergingConfig minIouOverlapping.
                     * @member {number} minIouOverlapping
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @instance
                     */
                    SectionMergingConfig.prototype.minIouOverlapping = 0;
    
                    /**
                     * SectionMergingConfig numHistoryItemsPerTracklet.
                     * @member {number} numHistoryItemsPerTracklet
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @instance
                     */
                    SectionMergingConfig.prototype.numHistoryItemsPerTracklet = 0;
    
                    /**
                     * SectionMergingConfig maximumTimeDistanceSeconds.
                     * @member {number} maximumTimeDistanceSeconds
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @instance
                     */
                    SectionMergingConfig.prototype.maximumTimeDistanceSeconds = 0;
    
                    /**
                     * SectionMergingConfig costThresholdToSplitNewPerson.
                     * @member {number} costThresholdToSplitNewPerson
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @instance
                     */
                    SectionMergingConfig.prototype.costThresholdToSplitNewPerson = 0;
    
                    /**
                     * SectionMergingConfig minimumLengthOfTrackletsPreFullMerging.
                     * @member {number} minimumLengthOfTrackletsPreFullMerging
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @instance
                     */
                    SectionMergingConfig.prototype.minimumLengthOfTrackletsPreFullMerging = 0;
    
                    /**
                     * Creates a new SectionMergingConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.ISectionMergingConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SectionMergingConfig} SectionMergingConfig instance
                     */
                    SectionMergingConfig.create = function create(properties) {
                        return new SectionMergingConfig(properties);
                    };
    
                    /**
                     * Encodes the specified SectionMergingConfig message. Does not implicitly {@link palexy.streaming.v1.SectionMergingConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.ISectionMergingConfig} message SectionMergingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SectionMergingConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minIouOverlapping != null && Object.hasOwnProperty.call(message, "minIouOverlapping"))
                            writer.uint32(/* id 1, wireType 5 =*/13).float(message.minIouOverlapping);
                        if (message.numHistoryItemsPerTracklet != null && Object.hasOwnProperty.call(message, "numHistoryItemsPerTracklet"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.numHistoryItemsPerTracklet);
                        if (message.maximumTimeDistanceSeconds != null && Object.hasOwnProperty.call(message, "maximumTimeDistanceSeconds"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.maximumTimeDistanceSeconds);
                        if (message.costThresholdToSplitNewPerson != null && Object.hasOwnProperty.call(message, "costThresholdToSplitNewPerson"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.costThresholdToSplitNewPerson);
                        if (message.minimumLengthOfTrackletsPreFullMerging != null && Object.hasOwnProperty.call(message, "minimumLengthOfTrackletsPreFullMerging"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.minimumLengthOfTrackletsPreFullMerging);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SectionMergingConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.SectionMergingConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.ISectionMergingConfig} message SectionMergingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SectionMergingConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SectionMergingConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SectionMergingConfig} SectionMergingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SectionMergingConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SectionMergingConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.minIouOverlapping = reader.float();
                                break;
                            case 2:
                                message.numHistoryItemsPerTracklet = reader.int32();
                                break;
                            case 3:
                                message.maximumTimeDistanceSeconds = reader.int32();
                                break;
                            case 4:
                                message.costThresholdToSplitNewPerson = reader.float();
                                break;
                            case 5:
                                message.minimumLengthOfTrackletsPreFullMerging = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SectionMergingConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SectionMergingConfig} SectionMergingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SectionMergingConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SectionMergingConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SectionMergingConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minIouOverlapping != null && message.hasOwnProperty("minIouOverlapping"))
                            if (typeof message.minIouOverlapping !== "number")
                                return "minIouOverlapping: number expected";
                        if (message.numHistoryItemsPerTracklet != null && message.hasOwnProperty("numHistoryItemsPerTracklet"))
                            if (!$util.isInteger(message.numHistoryItemsPerTracklet))
                                return "numHistoryItemsPerTracklet: integer expected";
                        if (message.maximumTimeDistanceSeconds != null && message.hasOwnProperty("maximumTimeDistanceSeconds"))
                            if (!$util.isInteger(message.maximumTimeDistanceSeconds))
                                return "maximumTimeDistanceSeconds: integer expected";
                        if (message.costThresholdToSplitNewPerson != null && message.hasOwnProperty("costThresholdToSplitNewPerson"))
                            if (typeof message.costThresholdToSplitNewPerson !== "number")
                                return "costThresholdToSplitNewPerson: number expected";
                        if (message.minimumLengthOfTrackletsPreFullMerging != null && message.hasOwnProperty("minimumLengthOfTrackletsPreFullMerging"))
                            if (!$util.isInteger(message.minimumLengthOfTrackletsPreFullMerging))
                                return "minimumLengthOfTrackletsPreFullMerging: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a SectionMergingConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SectionMergingConfig} SectionMergingConfig
                     */
                    SectionMergingConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SectionMergingConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.SectionMergingConfig();
                        if (object.minIouOverlapping != null)
                            message.minIouOverlapping = Number(object.minIouOverlapping);
                        if (object.numHistoryItemsPerTracklet != null)
                            message.numHistoryItemsPerTracklet = object.numHistoryItemsPerTracklet | 0;
                        if (object.maximumTimeDistanceSeconds != null)
                            message.maximumTimeDistanceSeconds = object.maximumTimeDistanceSeconds | 0;
                        if (object.costThresholdToSplitNewPerson != null)
                            message.costThresholdToSplitNewPerson = Number(object.costThresholdToSplitNewPerson);
                        if (object.minimumLengthOfTrackletsPreFullMerging != null)
                            message.minimumLengthOfTrackletsPreFullMerging = object.minimumLengthOfTrackletsPreFullMerging | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SectionMergingConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.SectionMergingConfig} message SectionMergingConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SectionMergingConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minIouOverlapping = 0;
                            object.numHistoryItemsPerTracklet = 0;
                            object.maximumTimeDistanceSeconds = 0;
                            object.costThresholdToSplitNewPerson = 0;
                            object.minimumLengthOfTrackletsPreFullMerging = 0;
                        }
                        if (message.minIouOverlapping != null && message.hasOwnProperty("minIouOverlapping"))
                            object.minIouOverlapping = options.json && !isFinite(message.minIouOverlapping) ? String(message.minIouOverlapping) : message.minIouOverlapping;
                        if (message.numHistoryItemsPerTracklet != null && message.hasOwnProperty("numHistoryItemsPerTracklet"))
                            object.numHistoryItemsPerTracklet = message.numHistoryItemsPerTracklet;
                        if (message.maximumTimeDistanceSeconds != null && message.hasOwnProperty("maximumTimeDistanceSeconds"))
                            object.maximumTimeDistanceSeconds = message.maximumTimeDistanceSeconds;
                        if (message.costThresholdToSplitNewPerson != null && message.hasOwnProperty("costThresholdToSplitNewPerson"))
                            object.costThresholdToSplitNewPerson = options.json && !isFinite(message.costThresholdToSplitNewPerson) ? String(message.costThresholdToSplitNewPerson) : message.costThresholdToSplitNewPerson;
                        if (message.minimumLengthOfTrackletsPreFullMerging != null && message.hasOwnProperty("minimumLengthOfTrackletsPreFullMerging"))
                            object.minimumLengthOfTrackletsPreFullMerging = message.minimumLengthOfTrackletsPreFullMerging;
                        return object;
                    };
    
                    /**
                     * Converts this SectionMergingConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SectionMergingConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SectionMergingConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SectionMergingConfig;
                })();
    
                v1.WideSpaceEntranceConfig = (function() {
    
                    /**
                     * Properties of a WideSpaceEntranceConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IWideSpaceEntranceConfig
                     * @property {number|null} [minInsideItems] WideSpaceEntranceConfig minInsideItems
                     * @property {number|null} [minOutsideThreshold] WideSpaceEntranceConfig minOutsideThreshold
                     * @property {number|null} [minNotInterestingItems] WideSpaceEntranceConfig minNotInterestingItems
                     * @property {boolean|null} [skipWideSpaceEntranceFiltering] WideSpaceEntranceConfig skipWideSpaceEntranceFiltering
                     */
    
                    /**
                     * Constructs a new WideSpaceEntranceConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a WideSpaceEntranceConfig.
                     * @implements IWideSpaceEntranceConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IWideSpaceEntranceConfig=} [properties] Properties to set
                     */
                    function WideSpaceEntranceConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * WideSpaceEntranceConfig minInsideItems.
                     * @member {number} minInsideItems
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @instance
                     */
                    WideSpaceEntranceConfig.prototype.minInsideItems = 0;
    
                    /**
                     * WideSpaceEntranceConfig minOutsideThreshold.
                     * @member {number} minOutsideThreshold
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @instance
                     */
                    WideSpaceEntranceConfig.prototype.minOutsideThreshold = 0;
    
                    /**
                     * WideSpaceEntranceConfig minNotInterestingItems.
                     * @member {number} minNotInterestingItems
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @instance
                     */
                    WideSpaceEntranceConfig.prototype.minNotInterestingItems = 0;
    
                    /**
                     * WideSpaceEntranceConfig skipWideSpaceEntranceFiltering.
                     * @member {boolean} skipWideSpaceEntranceFiltering
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @instance
                     */
                    WideSpaceEntranceConfig.prototype.skipWideSpaceEntranceFiltering = false;
    
                    /**
                     * Creates a new WideSpaceEntranceConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @static
                     * @param {palexy.streaming.v1.IWideSpaceEntranceConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.WideSpaceEntranceConfig} WideSpaceEntranceConfig instance
                     */
                    WideSpaceEntranceConfig.create = function create(properties) {
                        return new WideSpaceEntranceConfig(properties);
                    };
    
                    /**
                     * Encodes the specified WideSpaceEntranceConfig message. Does not implicitly {@link palexy.streaming.v1.WideSpaceEntranceConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @static
                     * @param {palexy.streaming.v1.IWideSpaceEntranceConfig} message WideSpaceEntranceConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WideSpaceEntranceConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minInsideItems != null && Object.hasOwnProperty.call(message, "minInsideItems"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minInsideItems);
                        if (message.minOutsideThreshold != null && Object.hasOwnProperty.call(message, "minOutsideThreshold"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.minOutsideThreshold);
                        if (message.minNotInterestingItems != null && Object.hasOwnProperty.call(message, "minNotInterestingItems"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.minNotInterestingItems);
                        if (message.skipWideSpaceEntranceFiltering != null && Object.hasOwnProperty.call(message, "skipWideSpaceEntranceFiltering"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.skipWideSpaceEntranceFiltering);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified WideSpaceEntranceConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.WideSpaceEntranceConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @static
                     * @param {palexy.streaming.v1.IWideSpaceEntranceConfig} message WideSpaceEntranceConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WideSpaceEntranceConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a WideSpaceEntranceConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.WideSpaceEntranceConfig} WideSpaceEntranceConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WideSpaceEntranceConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.WideSpaceEntranceConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.minInsideItems = reader.int32();
                                break;
                            case 2:
                                message.minOutsideThreshold = reader.float();
                                break;
                            case 3:
                                message.minNotInterestingItems = reader.int32();
                                break;
                            case 4:
                                message.skipWideSpaceEntranceFiltering = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a WideSpaceEntranceConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.WideSpaceEntranceConfig} WideSpaceEntranceConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WideSpaceEntranceConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a WideSpaceEntranceConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    WideSpaceEntranceConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minInsideItems != null && message.hasOwnProperty("minInsideItems"))
                            if (!$util.isInteger(message.minInsideItems))
                                return "minInsideItems: integer expected";
                        if (message.minOutsideThreshold != null && message.hasOwnProperty("minOutsideThreshold"))
                            if (typeof message.minOutsideThreshold !== "number")
                                return "minOutsideThreshold: number expected";
                        if (message.minNotInterestingItems != null && message.hasOwnProperty("minNotInterestingItems"))
                            if (!$util.isInteger(message.minNotInterestingItems))
                                return "minNotInterestingItems: integer expected";
                        if (message.skipWideSpaceEntranceFiltering != null && message.hasOwnProperty("skipWideSpaceEntranceFiltering"))
                            if (typeof message.skipWideSpaceEntranceFiltering !== "boolean")
                                return "skipWideSpaceEntranceFiltering: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a WideSpaceEntranceConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.WideSpaceEntranceConfig} WideSpaceEntranceConfig
                     */
                    WideSpaceEntranceConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.WideSpaceEntranceConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.WideSpaceEntranceConfig();
                        if (object.minInsideItems != null)
                            message.minInsideItems = object.minInsideItems | 0;
                        if (object.minOutsideThreshold != null)
                            message.minOutsideThreshold = Number(object.minOutsideThreshold);
                        if (object.minNotInterestingItems != null)
                            message.minNotInterestingItems = object.minNotInterestingItems | 0;
                        if (object.skipWideSpaceEntranceFiltering != null)
                            message.skipWideSpaceEntranceFiltering = Boolean(object.skipWideSpaceEntranceFiltering);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a WideSpaceEntranceConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @static
                     * @param {palexy.streaming.v1.WideSpaceEntranceConfig} message WideSpaceEntranceConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    WideSpaceEntranceConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minInsideItems = 0;
                            object.minOutsideThreshold = 0;
                            object.minNotInterestingItems = 0;
                            object.skipWideSpaceEntranceFiltering = false;
                        }
                        if (message.minInsideItems != null && message.hasOwnProperty("minInsideItems"))
                            object.minInsideItems = message.minInsideItems;
                        if (message.minOutsideThreshold != null && message.hasOwnProperty("minOutsideThreshold"))
                            object.minOutsideThreshold = options.json && !isFinite(message.minOutsideThreshold) ? String(message.minOutsideThreshold) : message.minOutsideThreshold;
                        if (message.minNotInterestingItems != null && message.hasOwnProperty("minNotInterestingItems"))
                            object.minNotInterestingItems = message.minNotInterestingItems;
                        if (message.skipWideSpaceEntranceFiltering != null && message.hasOwnProperty("skipWideSpaceEntranceFiltering"))
                            object.skipWideSpaceEntranceFiltering = message.skipWideSpaceEntranceFiltering;
                        return object;
                    };
    
                    /**
                     * Converts this WideSpaceEntranceConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.WideSpaceEntranceConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    WideSpaceEntranceConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return WideSpaceEntranceConfig;
                })();
    
                v1.RunMallMergingConfig = (function() {
    
                    /**
                     * Properties of a RunMallMergingConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IRunMallMergingConfig
                     * @property {number|null} [thresholdMergePersonInOut] RunMallMergingConfig thresholdMergePersonInOut
                     * @property {number|null} [thresholdDistanceForCompanion] RunMallMergingConfig thresholdDistanceForCompanion
                     * @property {number|null} [localVeryCloseFeatureDistance] RunMallMergingConfig localVeryCloseFeatureDistance
                     * @property {number|null} [localVeryCloseIndexDistance] RunMallMergingConfig localVeryCloseIndexDistance
                     * @property {boolean|null} [runMergeLocalVeryClose] RunMallMergingConfig runMergeLocalVeryClose
                     */
    
                    /**
                     * Constructs a new RunMallMergingConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RunMallMergingConfig.
                     * @implements IRunMallMergingConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IRunMallMergingConfig=} [properties] Properties to set
                     */
                    function RunMallMergingConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RunMallMergingConfig thresholdMergePersonInOut.
                     * @member {number} thresholdMergePersonInOut
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @instance
                     */
                    RunMallMergingConfig.prototype.thresholdMergePersonInOut = 0;
    
                    /**
                     * RunMallMergingConfig thresholdDistanceForCompanion.
                     * @member {number} thresholdDistanceForCompanion
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @instance
                     */
                    RunMallMergingConfig.prototype.thresholdDistanceForCompanion = 0;
    
                    /**
                     * RunMallMergingConfig localVeryCloseFeatureDistance.
                     * @member {number} localVeryCloseFeatureDistance
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @instance
                     */
                    RunMallMergingConfig.prototype.localVeryCloseFeatureDistance = 0;
    
                    /**
                     * RunMallMergingConfig localVeryCloseIndexDistance.
                     * @member {number} localVeryCloseIndexDistance
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @instance
                     */
                    RunMallMergingConfig.prototype.localVeryCloseIndexDistance = 0;
    
                    /**
                     * RunMallMergingConfig runMergeLocalVeryClose.
                     * @member {boolean} runMergeLocalVeryClose
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @instance
                     */
                    RunMallMergingConfig.prototype.runMergeLocalVeryClose = false;
    
                    /**
                     * Creates a new RunMallMergingConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IRunMallMergingConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RunMallMergingConfig} RunMallMergingConfig instance
                     */
                    RunMallMergingConfig.create = function create(properties) {
                        return new RunMallMergingConfig(properties);
                    };
    
                    /**
                     * Encodes the specified RunMallMergingConfig message. Does not implicitly {@link palexy.streaming.v1.RunMallMergingConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IRunMallMergingConfig} message RunMallMergingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RunMallMergingConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.thresholdMergePersonInOut != null && Object.hasOwnProperty.call(message, "thresholdMergePersonInOut"))
                            writer.uint32(/* id 1, wireType 5 =*/13).float(message.thresholdMergePersonInOut);
                        if (message.thresholdDistanceForCompanion != null && Object.hasOwnProperty.call(message, "thresholdDistanceForCompanion"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.thresholdDistanceForCompanion);
                        if (message.localVeryCloseFeatureDistance != null && Object.hasOwnProperty.call(message, "localVeryCloseFeatureDistance"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.localVeryCloseFeatureDistance);
                        if (message.localVeryCloseIndexDistance != null && Object.hasOwnProperty.call(message, "localVeryCloseIndexDistance"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.localVeryCloseIndexDistance);
                        if (message.runMergeLocalVeryClose != null && Object.hasOwnProperty.call(message, "runMergeLocalVeryClose"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.runMergeLocalVeryClose);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RunMallMergingConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.RunMallMergingConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.IRunMallMergingConfig} message RunMallMergingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RunMallMergingConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RunMallMergingConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RunMallMergingConfig} RunMallMergingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RunMallMergingConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RunMallMergingConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.thresholdMergePersonInOut = reader.float();
                                break;
                            case 2:
                                message.thresholdDistanceForCompanion = reader.float();
                                break;
                            case 3:
                                message.localVeryCloseFeatureDistance = reader.float();
                                break;
                            case 4:
                                message.localVeryCloseIndexDistance = reader.int32();
                                break;
                            case 5:
                                message.runMergeLocalVeryClose = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RunMallMergingConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RunMallMergingConfig} RunMallMergingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RunMallMergingConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RunMallMergingConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RunMallMergingConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.thresholdMergePersonInOut != null && message.hasOwnProperty("thresholdMergePersonInOut"))
                            if (typeof message.thresholdMergePersonInOut !== "number")
                                return "thresholdMergePersonInOut: number expected";
                        if (message.thresholdDistanceForCompanion != null && message.hasOwnProperty("thresholdDistanceForCompanion"))
                            if (typeof message.thresholdDistanceForCompanion !== "number")
                                return "thresholdDistanceForCompanion: number expected";
                        if (message.localVeryCloseFeatureDistance != null && message.hasOwnProperty("localVeryCloseFeatureDistance"))
                            if (typeof message.localVeryCloseFeatureDistance !== "number")
                                return "localVeryCloseFeatureDistance: number expected";
                        if (message.localVeryCloseIndexDistance != null && message.hasOwnProperty("localVeryCloseIndexDistance"))
                            if (!$util.isInteger(message.localVeryCloseIndexDistance))
                                return "localVeryCloseIndexDistance: integer expected";
                        if (message.runMergeLocalVeryClose != null && message.hasOwnProperty("runMergeLocalVeryClose"))
                            if (typeof message.runMergeLocalVeryClose !== "boolean")
                                return "runMergeLocalVeryClose: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a RunMallMergingConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RunMallMergingConfig} RunMallMergingConfig
                     */
                    RunMallMergingConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RunMallMergingConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.RunMallMergingConfig();
                        if (object.thresholdMergePersonInOut != null)
                            message.thresholdMergePersonInOut = Number(object.thresholdMergePersonInOut);
                        if (object.thresholdDistanceForCompanion != null)
                            message.thresholdDistanceForCompanion = Number(object.thresholdDistanceForCompanion);
                        if (object.localVeryCloseFeatureDistance != null)
                            message.localVeryCloseFeatureDistance = Number(object.localVeryCloseFeatureDistance);
                        if (object.localVeryCloseIndexDistance != null)
                            message.localVeryCloseIndexDistance = object.localVeryCloseIndexDistance | 0;
                        if (object.runMergeLocalVeryClose != null)
                            message.runMergeLocalVeryClose = Boolean(object.runMergeLocalVeryClose);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RunMallMergingConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @static
                     * @param {palexy.streaming.v1.RunMallMergingConfig} message RunMallMergingConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RunMallMergingConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.thresholdMergePersonInOut = 0;
                            object.thresholdDistanceForCompanion = 0;
                            object.localVeryCloseFeatureDistance = 0;
                            object.localVeryCloseIndexDistance = 0;
                            object.runMergeLocalVeryClose = false;
                        }
                        if (message.thresholdMergePersonInOut != null && message.hasOwnProperty("thresholdMergePersonInOut"))
                            object.thresholdMergePersonInOut = options.json && !isFinite(message.thresholdMergePersonInOut) ? String(message.thresholdMergePersonInOut) : message.thresholdMergePersonInOut;
                        if (message.thresholdDistanceForCompanion != null && message.hasOwnProperty("thresholdDistanceForCompanion"))
                            object.thresholdDistanceForCompanion = options.json && !isFinite(message.thresholdDistanceForCompanion) ? String(message.thresholdDistanceForCompanion) : message.thresholdDistanceForCompanion;
                        if (message.localVeryCloseFeatureDistance != null && message.hasOwnProperty("localVeryCloseFeatureDistance"))
                            object.localVeryCloseFeatureDistance = options.json && !isFinite(message.localVeryCloseFeatureDistance) ? String(message.localVeryCloseFeatureDistance) : message.localVeryCloseFeatureDistance;
                        if (message.localVeryCloseIndexDistance != null && message.hasOwnProperty("localVeryCloseIndexDistance"))
                            object.localVeryCloseIndexDistance = message.localVeryCloseIndexDistance;
                        if (message.runMergeLocalVeryClose != null && message.hasOwnProperty("runMergeLocalVeryClose"))
                            object.runMergeLocalVeryClose = message.runMergeLocalVeryClose;
                        return object;
                    };
    
                    /**
                     * Converts this RunMallMergingConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RunMallMergingConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RunMallMergingConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RunMallMergingConfig;
                })();
    
                v1.DetectCameraShiftRequest = (function() {
    
                    /**
                     * Properties of a DetectCameraShiftRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDetectCameraShiftRequest
                     * @property {palexy.streaming.v1.ICamera|null} [camera] DetectCameraShiftRequest camera
                     */
    
                    /**
                     * Constructs a new DetectCameraShiftRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DetectCameraShiftRequest.
                     * @implements IDetectCameraShiftRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDetectCameraShiftRequest=} [properties] Properties to set
                     */
                    function DetectCameraShiftRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DetectCameraShiftRequest camera.
                     * @member {palexy.streaming.v1.ICamera|null|undefined} camera
                     * @memberof palexy.streaming.v1.DetectCameraShiftRequest
                     * @instance
                     */
                    DetectCameraShiftRequest.prototype.camera = null;
    
                    /**
                     * Creates a new DetectCameraShiftRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DetectCameraShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDetectCameraShiftRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DetectCameraShiftRequest} DetectCameraShiftRequest instance
                     */
                    DetectCameraShiftRequest.create = function create(properties) {
                        return new DetectCameraShiftRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DetectCameraShiftRequest message. Does not implicitly {@link palexy.streaming.v1.DetectCameraShiftRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DetectCameraShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDetectCameraShiftRequest} message DetectCameraShiftRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DetectCameraShiftRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.camera != null && Object.hasOwnProperty.call(message, "camera"))
                            $root.palexy.streaming.v1.Camera.encode(message.camera, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DetectCameraShiftRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DetectCameraShiftRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DetectCameraShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDetectCameraShiftRequest} message DetectCameraShiftRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DetectCameraShiftRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DetectCameraShiftRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DetectCameraShiftRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DetectCameraShiftRequest} DetectCameraShiftRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DetectCameraShiftRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DetectCameraShiftRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.camera = $root.palexy.streaming.v1.Camera.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DetectCameraShiftRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DetectCameraShiftRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DetectCameraShiftRequest} DetectCameraShiftRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DetectCameraShiftRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DetectCameraShiftRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DetectCameraShiftRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DetectCameraShiftRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.camera != null && message.hasOwnProperty("camera")) {
                            var error = $root.palexy.streaming.v1.Camera.verify(message.camera);
                            if (error)
                                return "camera." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DetectCameraShiftRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DetectCameraShiftRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DetectCameraShiftRequest} DetectCameraShiftRequest
                     */
                    DetectCameraShiftRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DetectCameraShiftRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DetectCameraShiftRequest();
                        if (object.camera != null) {
                            if (typeof object.camera !== "object")
                                throw TypeError(".palexy.streaming.v1.DetectCameraShiftRequest.camera: object expected");
                            message.camera = $root.palexy.streaming.v1.Camera.fromObject(object.camera);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DetectCameraShiftRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DetectCameraShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.DetectCameraShiftRequest} message DetectCameraShiftRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DetectCameraShiftRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.camera = null;
                        if (message.camera != null && message.hasOwnProperty("camera"))
                            object.camera = $root.palexy.streaming.v1.Camera.toObject(message.camera, options);
                        return object;
                    };
    
                    /**
                     * Converts this DetectCameraShiftRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DetectCameraShiftRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DetectCameraShiftRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DetectCameraShiftRequest;
                })();
    
                v1.DetectCameraShiftResponse = (function() {
    
                    /**
                     * Properties of a DetectCameraShiftResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IDetectCameraShiftResponse
                     * @property {string|null} [cameraId] DetectCameraShiftResponse cameraId
                     * @property {number|null} [cameraPrimaryId] DetectCameraShiftResponse cameraPrimaryId
                     * @property {palexy.streaming.v1.DetectCameraShiftResponse.CameraShiftStatus|null} [status] DetectCameraShiftResponse status
                     * @property {number|null} [referenceImageId] DetectCameraShiftResponse referenceImageId
                     * @property {number|null} [testImageId] DetectCameraShiftResponse testImageId
                     * @property {palexy.streaming.v1.ICameraPhoto|null} [referenceImage] DetectCameraShiftResponse referenceImage
                     * @property {palexy.streaming.v1.ICameraPhoto|null} [testImage] DetectCameraShiftResponse testImage
                     */
    
                    /**
                     * Constructs a new DetectCameraShiftResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DetectCameraShiftResponse.
                     * @implements IDetectCameraShiftResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IDetectCameraShiftResponse=} [properties] Properties to set
                     */
                    function DetectCameraShiftResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DetectCameraShiftResponse cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @instance
                     */
                    DetectCameraShiftResponse.prototype.cameraId = "";
    
                    /**
                     * DetectCameraShiftResponse cameraPrimaryId.
                     * @member {number} cameraPrimaryId
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @instance
                     */
                    DetectCameraShiftResponse.prototype.cameraPrimaryId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DetectCameraShiftResponse status.
                     * @member {palexy.streaming.v1.DetectCameraShiftResponse.CameraShiftStatus} status
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @instance
                     */
                    DetectCameraShiftResponse.prototype.status = 0;
    
                    /**
                     * DetectCameraShiftResponse referenceImageId.
                     * @member {number} referenceImageId
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @instance
                     */
                    DetectCameraShiftResponse.prototype.referenceImageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DetectCameraShiftResponse testImageId.
                     * @member {number} testImageId
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @instance
                     */
                    DetectCameraShiftResponse.prototype.testImageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DetectCameraShiftResponse referenceImage.
                     * @member {palexy.streaming.v1.ICameraPhoto|null|undefined} referenceImage
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @instance
                     */
                    DetectCameraShiftResponse.prototype.referenceImage = null;
    
                    /**
                     * DetectCameraShiftResponse testImage.
                     * @member {palexy.streaming.v1.ICameraPhoto|null|undefined} testImage
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @instance
                     */
                    DetectCameraShiftResponse.prototype.testImage = null;
    
                    /**
                     * Creates a new DetectCameraShiftResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @static
                     * @param {palexy.streaming.v1.IDetectCameraShiftResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DetectCameraShiftResponse} DetectCameraShiftResponse instance
                     */
                    DetectCameraShiftResponse.create = function create(properties) {
                        return new DetectCameraShiftResponse(properties);
                    };
    
                    /**
                     * Encodes the specified DetectCameraShiftResponse message. Does not implicitly {@link palexy.streaming.v1.DetectCameraShiftResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @static
                     * @param {palexy.streaming.v1.IDetectCameraShiftResponse} message DetectCameraShiftResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DetectCameraShiftResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cameraId);
                        if (message.cameraPrimaryId != null && Object.hasOwnProperty.call(message, "cameraPrimaryId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.cameraPrimaryId);
                        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
                        if (message.referenceImageId != null && Object.hasOwnProperty.call(message, "referenceImageId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.referenceImageId);
                        if (message.testImageId != null && Object.hasOwnProperty.call(message, "testImageId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.testImageId);
                        if (message.referenceImage != null && Object.hasOwnProperty.call(message, "referenceImage"))
                            $root.palexy.streaming.v1.CameraPhoto.encode(message.referenceImage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.testImage != null && Object.hasOwnProperty.call(message, "testImage"))
                            $root.palexy.streaming.v1.CameraPhoto.encode(message.testImage, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DetectCameraShiftResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.DetectCameraShiftResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @static
                     * @param {palexy.streaming.v1.IDetectCameraShiftResponse} message DetectCameraShiftResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DetectCameraShiftResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DetectCameraShiftResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DetectCameraShiftResponse} DetectCameraShiftResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DetectCameraShiftResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DetectCameraShiftResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraId = reader.string();
                                break;
                            case 2:
                                message.cameraPrimaryId = reader.int64();
                                break;
                            case 3:
                                message.status = reader.int32();
                                break;
                            case 4:
                                message.referenceImageId = reader.int64();
                                break;
                            case 5:
                                message.testImageId = reader.int64();
                                break;
                            case 6:
                                message.referenceImage = $root.palexy.streaming.v1.CameraPhoto.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.testImage = $root.palexy.streaming.v1.CameraPhoto.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DetectCameraShiftResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DetectCameraShiftResponse} DetectCameraShiftResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DetectCameraShiftResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DetectCameraShiftResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DetectCameraShiftResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.cameraPrimaryId != null && message.hasOwnProperty("cameraPrimaryId"))
                            if (!$util.isInteger(message.cameraPrimaryId) && !(message.cameraPrimaryId && $util.isInteger(message.cameraPrimaryId.low) && $util.isInteger(message.cameraPrimaryId.high)))
                                return "cameraPrimaryId: integer|Long expected";
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.referenceImageId != null && message.hasOwnProperty("referenceImageId"))
                            if (!$util.isInteger(message.referenceImageId) && !(message.referenceImageId && $util.isInteger(message.referenceImageId.low) && $util.isInteger(message.referenceImageId.high)))
                                return "referenceImageId: integer|Long expected";
                        if (message.testImageId != null && message.hasOwnProperty("testImageId"))
                            if (!$util.isInteger(message.testImageId) && !(message.testImageId && $util.isInteger(message.testImageId.low) && $util.isInteger(message.testImageId.high)))
                                return "testImageId: integer|Long expected";
                        if (message.referenceImage != null && message.hasOwnProperty("referenceImage")) {
                            var error = $root.palexy.streaming.v1.CameraPhoto.verify(message.referenceImage);
                            if (error)
                                return "referenceImage." + error;
                        }
                        if (message.testImage != null && message.hasOwnProperty("testImage")) {
                            var error = $root.palexy.streaming.v1.CameraPhoto.verify(message.testImage);
                            if (error)
                                return "testImage." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DetectCameraShiftResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DetectCameraShiftResponse} DetectCameraShiftResponse
                     */
                    DetectCameraShiftResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DetectCameraShiftResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.DetectCameraShiftResponse();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.cameraPrimaryId != null)
                            if ($util.Long)
                                (message.cameraPrimaryId = $util.Long.fromValue(object.cameraPrimaryId)).unsigned = false;
                            else if (typeof object.cameraPrimaryId === "string")
                                message.cameraPrimaryId = parseInt(object.cameraPrimaryId, 10);
                            else if (typeof object.cameraPrimaryId === "number")
                                message.cameraPrimaryId = object.cameraPrimaryId;
                            else if (typeof object.cameraPrimaryId === "object")
                                message.cameraPrimaryId = new $util.LongBits(object.cameraPrimaryId.low >>> 0, object.cameraPrimaryId.high >>> 0).toNumber();
                        switch (object.status) {
                        case "UNKNOWN":
                        case 0:
                            message.status = 0;
                            break;
                        case "SHIFTED":
                        case 1:
                            message.status = 1;
                            break;
                        case "NOT_SHIFTED":
                        case 2:
                            message.status = 2;
                            break;
                        case "SUSPECTED":
                        case 3:
                            message.status = 3;
                            break;
                        }
                        if (object.referenceImageId != null)
                            if ($util.Long)
                                (message.referenceImageId = $util.Long.fromValue(object.referenceImageId)).unsigned = false;
                            else if (typeof object.referenceImageId === "string")
                                message.referenceImageId = parseInt(object.referenceImageId, 10);
                            else if (typeof object.referenceImageId === "number")
                                message.referenceImageId = object.referenceImageId;
                            else if (typeof object.referenceImageId === "object")
                                message.referenceImageId = new $util.LongBits(object.referenceImageId.low >>> 0, object.referenceImageId.high >>> 0).toNumber();
                        if (object.testImageId != null)
                            if ($util.Long)
                                (message.testImageId = $util.Long.fromValue(object.testImageId)).unsigned = false;
                            else if (typeof object.testImageId === "string")
                                message.testImageId = parseInt(object.testImageId, 10);
                            else if (typeof object.testImageId === "number")
                                message.testImageId = object.testImageId;
                            else if (typeof object.testImageId === "object")
                                message.testImageId = new $util.LongBits(object.testImageId.low >>> 0, object.testImageId.high >>> 0).toNumber();
                        if (object.referenceImage != null) {
                            if (typeof object.referenceImage !== "object")
                                throw TypeError(".palexy.streaming.v1.DetectCameraShiftResponse.referenceImage: object expected");
                            message.referenceImage = $root.palexy.streaming.v1.CameraPhoto.fromObject(object.referenceImage);
                        }
                        if (object.testImage != null) {
                            if (typeof object.testImage !== "object")
                                throw TypeError(".palexy.streaming.v1.DetectCameraShiftResponse.testImage: object expected");
                            message.testImage = $root.palexy.streaming.v1.CameraPhoto.fromObject(object.testImage);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DetectCameraShiftResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @static
                     * @param {palexy.streaming.v1.DetectCameraShiftResponse} message DetectCameraShiftResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DetectCameraShiftResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.cameraId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraPrimaryId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraPrimaryId = options.longs === String ? "0" : 0;
                            object.status = options.enums === String ? "UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.referenceImageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.referenceImageId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.testImageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.testImageId = options.longs === String ? "0" : 0;
                            object.referenceImage = null;
                            object.testImage = null;
                        }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.cameraPrimaryId != null && message.hasOwnProperty("cameraPrimaryId"))
                            if (typeof message.cameraPrimaryId === "number")
                                object.cameraPrimaryId = options.longs === String ? String(message.cameraPrimaryId) : message.cameraPrimaryId;
                            else
                                object.cameraPrimaryId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraPrimaryId) : options.longs === Number ? new $util.LongBits(message.cameraPrimaryId.low >>> 0, message.cameraPrimaryId.high >>> 0).toNumber() : message.cameraPrimaryId;
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.palexy.streaming.v1.DetectCameraShiftResponse.CameraShiftStatus[message.status] : message.status;
                        if (message.referenceImageId != null && message.hasOwnProperty("referenceImageId"))
                            if (typeof message.referenceImageId === "number")
                                object.referenceImageId = options.longs === String ? String(message.referenceImageId) : message.referenceImageId;
                            else
                                object.referenceImageId = options.longs === String ? $util.Long.prototype.toString.call(message.referenceImageId) : options.longs === Number ? new $util.LongBits(message.referenceImageId.low >>> 0, message.referenceImageId.high >>> 0).toNumber() : message.referenceImageId;
                        if (message.testImageId != null && message.hasOwnProperty("testImageId"))
                            if (typeof message.testImageId === "number")
                                object.testImageId = options.longs === String ? String(message.testImageId) : message.testImageId;
                            else
                                object.testImageId = options.longs === String ? $util.Long.prototype.toString.call(message.testImageId) : options.longs === Number ? new $util.LongBits(message.testImageId.low >>> 0, message.testImageId.high >>> 0).toNumber() : message.testImageId;
                        if (message.referenceImage != null && message.hasOwnProperty("referenceImage"))
                            object.referenceImage = $root.palexy.streaming.v1.CameraPhoto.toObject(message.referenceImage, options);
                        if (message.testImage != null && message.hasOwnProperty("testImage"))
                            object.testImage = $root.palexy.streaming.v1.CameraPhoto.toObject(message.testImage, options);
                        return object;
                    };
    
                    /**
                     * Converts this DetectCameraShiftResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DetectCameraShiftResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DetectCameraShiftResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * CameraShiftStatus enum.
                     * @name palexy.streaming.v1.DetectCameraShiftResponse.CameraShiftStatus
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} SHIFTED=1 SHIFTED value
                     * @property {number} NOT_SHIFTED=2 NOT_SHIFTED value
                     * @property {number} SUSPECTED=3 SUSPECTED value
                     */
                    DetectCameraShiftResponse.CameraShiftStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "SHIFTED"] = 1;
                        values[valuesById[2] = "NOT_SHIFTED"] = 2;
                        values[valuesById[3] = "SUSPECTED"] = 3;
                        return values;
                    })();
    
                    return DetectCameraShiftResponse;
                })();
    
                v1.CameraShiftEventService = (function() {
    
                    /**
                     * Constructs a new CameraShiftEventService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CameraShiftEventService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function CameraShiftEventService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (CameraShiftEventService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CameraShiftEventService;
    
                    /**
                     * Creates new CameraShiftEventService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.CameraShiftEventService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {CameraShiftEventService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    CameraShiftEventService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraShiftEventService#createCameraShiftEvent}.
                     * @memberof palexy.streaming.v1.CameraShiftEventService
                     * @typedef CreateCameraShiftEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.CameraShiftEvent} [response] CameraShiftEvent
                     */
    
                    /**
                     * Calls CreateCameraShiftEvent.
                     * @function createCameraShiftEvent
                     * @memberof palexy.streaming.v1.CameraShiftEventService
                     * @instance
                     * @param {palexy.streaming.v1.ICameraShiftEvent} request CameraShiftEvent message or plain object
                     * @param {palexy.streaming.v1.CameraShiftEventService.CreateCameraShiftEventCallback} callback Node-style callback called with the error, if any, and CameraShiftEvent
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraShiftEventService.prototype.createCameraShiftEvent = function createCameraShiftEvent(request, callback) {
                        return this.rpcCall(createCameraShiftEvent, $root.palexy.streaming.v1.CameraShiftEvent, $root.palexy.streaming.v1.CameraShiftEvent, request, callback);
                    }, "name", { value: "CreateCameraShiftEvent" });
    
                    /**
                     * Calls CreateCameraShiftEvent.
                     * @function createCameraShiftEvent
                     * @memberof palexy.streaming.v1.CameraShiftEventService
                     * @instance
                     * @param {palexy.streaming.v1.ICameraShiftEvent} request CameraShiftEvent message or plain object
                     * @returns {Promise<palexy.streaming.v1.CameraShiftEvent>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CameraShiftEventService#updateCameraShiftEvent}.
                     * @memberof palexy.streaming.v1.CameraShiftEventService
                     * @typedef UpdateCameraShiftEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.CameraShiftEvent} [response] CameraShiftEvent
                     */
    
                    /**
                     * Calls UpdateCameraShiftEvent.
                     * @function updateCameraShiftEvent
                     * @memberof palexy.streaming.v1.CameraShiftEventService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateCameraShiftEventRequest} request UpdateCameraShiftEventRequest message or plain object
                     * @param {palexy.streaming.v1.CameraShiftEventService.UpdateCameraShiftEventCallback} callback Node-style callback called with the error, if any, and CameraShiftEvent
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CameraShiftEventService.prototype.updateCameraShiftEvent = function updateCameraShiftEvent(request, callback) {
                        return this.rpcCall(updateCameraShiftEvent, $root.palexy.streaming.v1.UpdateCameraShiftEventRequest, $root.palexy.streaming.v1.CameraShiftEvent, request, callback);
                    }, "name", { value: "UpdateCameraShiftEvent" });
    
                    /**
                     * Calls UpdateCameraShiftEvent.
                     * @function updateCameraShiftEvent
                     * @memberof palexy.streaming.v1.CameraShiftEventService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateCameraShiftEventRequest} request UpdateCameraShiftEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.CameraShiftEvent>} Promise
                     * @variation 2
                     */
    
                    return CameraShiftEventService;
                })();
    
                v1.CameraShiftEvent = (function() {
    
                    /**
                     * Properties of a CameraShiftEvent.
                     * @memberof palexy.streaming.v1
                     * @interface ICameraShiftEvent
                     * @property {number|null} [id] CameraShiftEvent id
                     * @property {string|null} [cameraId] CameraShiftEvent cameraId
                     * @property {number|null} [cameraPrimaryId] CameraShiftEvent cameraPrimaryId
                     * @property {number|null} [referenceImageId] CameraShiftEvent referenceImageId
                     * @property {number|null} [testImageId] CameraShiftEvent testImageId
                     * @property {palexy.streaming.v1.CameraShiftEvent.ShiftEventStatus|null} [status] CameraShiftEvent status
                     * @property {google.protobuf.ITimestamp|null} [createTime] CameraShiftEvent createTime
                     * @property {number|null} [activityLogId] CameraShiftEvent activityLogId
                     * @property {palexy.streaming.v1.ICameraPhoto|null} [referenceImage] CameraShiftEvent referenceImage
                     * @property {palexy.streaming.v1.ICameraPhoto|null} [testImage] CameraShiftEvent testImage
                     */
    
                    /**
                     * Constructs a new CameraShiftEvent.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CameraShiftEvent.
                     * @implements ICameraShiftEvent
                     * @constructor
                     * @param {palexy.streaming.v1.ICameraShiftEvent=} [properties] Properties to set
                     */
                    function CameraShiftEvent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CameraShiftEvent id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @instance
                     */
                    CameraShiftEvent.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraShiftEvent cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @instance
                     */
                    CameraShiftEvent.prototype.cameraId = "";
    
                    /**
                     * CameraShiftEvent cameraPrimaryId.
                     * @member {number} cameraPrimaryId
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @instance
                     */
                    CameraShiftEvent.prototype.cameraPrimaryId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraShiftEvent referenceImageId.
                     * @member {number} referenceImageId
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @instance
                     */
                    CameraShiftEvent.prototype.referenceImageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraShiftEvent testImageId.
                     * @member {number} testImageId
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @instance
                     */
                    CameraShiftEvent.prototype.testImageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraShiftEvent status.
                     * @member {palexy.streaming.v1.CameraShiftEvent.ShiftEventStatus} status
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @instance
                     */
                    CameraShiftEvent.prototype.status = 0;
    
                    /**
                     * CameraShiftEvent createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @instance
                     */
                    CameraShiftEvent.prototype.createTime = null;
    
                    /**
                     * CameraShiftEvent activityLogId.
                     * @member {number} activityLogId
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @instance
                     */
                    CameraShiftEvent.prototype.activityLogId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraShiftEvent referenceImage.
                     * @member {palexy.streaming.v1.ICameraPhoto|null|undefined} referenceImage
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @instance
                     */
                    CameraShiftEvent.prototype.referenceImage = null;
    
                    /**
                     * CameraShiftEvent testImage.
                     * @member {palexy.streaming.v1.ICameraPhoto|null|undefined} testImage
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @instance
                     */
                    CameraShiftEvent.prototype.testImage = null;
    
                    /**
                     * Creates a new CameraShiftEvent instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @static
                     * @param {palexy.streaming.v1.ICameraShiftEvent=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CameraShiftEvent} CameraShiftEvent instance
                     */
                    CameraShiftEvent.create = function create(properties) {
                        return new CameraShiftEvent(properties);
                    };
    
                    /**
                     * Encodes the specified CameraShiftEvent message. Does not implicitly {@link palexy.streaming.v1.CameraShiftEvent.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @static
                     * @param {palexy.streaming.v1.ICameraShiftEvent} message CameraShiftEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraShiftEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.cameraId);
                        if (message.cameraPrimaryId != null && Object.hasOwnProperty.call(message, "cameraPrimaryId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.cameraPrimaryId);
                        if (message.referenceImageId != null && Object.hasOwnProperty.call(message, "referenceImageId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.referenceImageId);
                        if (message.testImageId != null && Object.hasOwnProperty.call(message, "testImageId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.testImageId);
                        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.status);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.activityLogId != null && Object.hasOwnProperty.call(message, "activityLogId"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.activityLogId);
                        if (message.referenceImage != null && Object.hasOwnProperty.call(message, "referenceImage"))
                            $root.palexy.streaming.v1.CameraPhoto.encode(message.referenceImage, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.testImage != null && Object.hasOwnProperty.call(message, "testImage"))
                            $root.palexy.streaming.v1.CameraPhoto.encode(message.testImage, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CameraShiftEvent message, length delimited. Does not implicitly {@link palexy.streaming.v1.CameraShiftEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @static
                     * @param {palexy.streaming.v1.ICameraShiftEvent} message CameraShiftEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraShiftEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CameraShiftEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CameraShiftEvent} CameraShiftEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraShiftEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CameraShiftEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.cameraId = reader.string();
                                break;
                            case 3:
                                message.cameraPrimaryId = reader.int64();
                                break;
                            case 4:
                                message.referenceImageId = reader.int64();
                                break;
                            case 5:
                                message.testImageId = reader.int64();
                                break;
                            case 6:
                                message.status = reader.int32();
                                break;
                            case 7:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.activityLogId = reader.int64();
                                break;
                            case 9:
                                message.referenceImage = $root.palexy.streaming.v1.CameraPhoto.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.testImage = $root.palexy.streaming.v1.CameraPhoto.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CameraShiftEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CameraShiftEvent} CameraShiftEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraShiftEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CameraShiftEvent message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CameraShiftEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.cameraPrimaryId != null && message.hasOwnProperty("cameraPrimaryId"))
                            if (!$util.isInteger(message.cameraPrimaryId) && !(message.cameraPrimaryId && $util.isInteger(message.cameraPrimaryId.low) && $util.isInteger(message.cameraPrimaryId.high)))
                                return "cameraPrimaryId: integer|Long expected";
                        if (message.referenceImageId != null && message.hasOwnProperty("referenceImageId"))
                            if (!$util.isInteger(message.referenceImageId) && !(message.referenceImageId && $util.isInteger(message.referenceImageId.low) && $util.isInteger(message.referenceImageId.high)))
                                return "referenceImageId: integer|Long expected";
                        if (message.testImageId != null && message.hasOwnProperty("testImageId"))
                            if (!$util.isInteger(message.testImageId) && !(message.testImageId && $util.isInteger(message.testImageId.low) && $util.isInteger(message.testImageId.high)))
                                return "testImageId: integer|Long expected";
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.activityLogId != null && message.hasOwnProperty("activityLogId"))
                            if (!$util.isInteger(message.activityLogId) && !(message.activityLogId && $util.isInteger(message.activityLogId.low) && $util.isInteger(message.activityLogId.high)))
                                return "activityLogId: integer|Long expected";
                        if (message.referenceImage != null && message.hasOwnProperty("referenceImage")) {
                            var error = $root.palexy.streaming.v1.CameraPhoto.verify(message.referenceImage);
                            if (error)
                                return "referenceImage." + error;
                        }
                        if (message.testImage != null && message.hasOwnProperty("testImage")) {
                            var error = $root.palexy.streaming.v1.CameraPhoto.verify(message.testImage);
                            if (error)
                                return "testImage." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CameraShiftEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CameraShiftEvent} CameraShiftEvent
                     */
                    CameraShiftEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CameraShiftEvent)
                            return object;
                        var message = new $root.palexy.streaming.v1.CameraShiftEvent();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.cameraPrimaryId != null)
                            if ($util.Long)
                                (message.cameraPrimaryId = $util.Long.fromValue(object.cameraPrimaryId)).unsigned = false;
                            else if (typeof object.cameraPrimaryId === "string")
                                message.cameraPrimaryId = parseInt(object.cameraPrimaryId, 10);
                            else if (typeof object.cameraPrimaryId === "number")
                                message.cameraPrimaryId = object.cameraPrimaryId;
                            else if (typeof object.cameraPrimaryId === "object")
                                message.cameraPrimaryId = new $util.LongBits(object.cameraPrimaryId.low >>> 0, object.cameraPrimaryId.high >>> 0).toNumber();
                        if (object.referenceImageId != null)
                            if ($util.Long)
                                (message.referenceImageId = $util.Long.fromValue(object.referenceImageId)).unsigned = false;
                            else if (typeof object.referenceImageId === "string")
                                message.referenceImageId = parseInt(object.referenceImageId, 10);
                            else if (typeof object.referenceImageId === "number")
                                message.referenceImageId = object.referenceImageId;
                            else if (typeof object.referenceImageId === "object")
                                message.referenceImageId = new $util.LongBits(object.referenceImageId.low >>> 0, object.referenceImageId.high >>> 0).toNumber();
                        if (object.testImageId != null)
                            if ($util.Long)
                                (message.testImageId = $util.Long.fromValue(object.testImageId)).unsigned = false;
                            else if (typeof object.testImageId === "string")
                                message.testImageId = parseInt(object.testImageId, 10);
                            else if (typeof object.testImageId === "number")
                                message.testImageId = object.testImageId;
                            else if (typeof object.testImageId === "object")
                                message.testImageId = new $util.LongBits(object.testImageId.low >>> 0, object.testImageId.high >>> 0).toNumber();
                        switch (object.status) {
                        case "UNKNOWN":
                        case 0:
                            message.status = 0;
                            break;
                        case "WAITING_FOR_REVIEW":
                        case 1:
                            message.status = 1;
                            break;
                        case "SHIFTED":
                        case 2:
                            message.status = 2;
                            break;
                        case "RESOLVED":
                        case 3:
                            message.status = 3;
                            break;
                        case "NOT_SHIFTED":
                        case 4:
                            message.status = 4;
                            break;
                        case "EXPIRED":
                        case 5:
                            message.status = 5;
                            break;
                        }
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CameraShiftEvent.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.activityLogId != null)
                            if ($util.Long)
                                (message.activityLogId = $util.Long.fromValue(object.activityLogId)).unsigned = false;
                            else if (typeof object.activityLogId === "string")
                                message.activityLogId = parseInt(object.activityLogId, 10);
                            else if (typeof object.activityLogId === "number")
                                message.activityLogId = object.activityLogId;
                            else if (typeof object.activityLogId === "object")
                                message.activityLogId = new $util.LongBits(object.activityLogId.low >>> 0, object.activityLogId.high >>> 0).toNumber();
                        if (object.referenceImage != null) {
                            if (typeof object.referenceImage !== "object")
                                throw TypeError(".palexy.streaming.v1.CameraShiftEvent.referenceImage: object expected");
                            message.referenceImage = $root.palexy.streaming.v1.CameraPhoto.fromObject(object.referenceImage);
                        }
                        if (object.testImage != null) {
                            if (typeof object.testImage !== "object")
                                throw TypeError(".palexy.streaming.v1.CameraShiftEvent.testImage: object expected");
                            message.testImage = $root.palexy.streaming.v1.CameraPhoto.fromObject(object.testImage);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CameraShiftEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @static
                     * @param {palexy.streaming.v1.CameraShiftEvent} message CameraShiftEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CameraShiftEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.cameraId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraPrimaryId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraPrimaryId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.referenceImageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.referenceImageId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.testImageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.testImageId = options.longs === String ? "0" : 0;
                            object.status = options.enums === String ? "UNKNOWN" : 0;
                            object.createTime = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.activityLogId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.activityLogId = options.longs === String ? "0" : 0;
                            object.referenceImage = null;
                            object.testImage = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.cameraPrimaryId != null && message.hasOwnProperty("cameraPrimaryId"))
                            if (typeof message.cameraPrimaryId === "number")
                                object.cameraPrimaryId = options.longs === String ? String(message.cameraPrimaryId) : message.cameraPrimaryId;
                            else
                                object.cameraPrimaryId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraPrimaryId) : options.longs === Number ? new $util.LongBits(message.cameraPrimaryId.low >>> 0, message.cameraPrimaryId.high >>> 0).toNumber() : message.cameraPrimaryId;
                        if (message.referenceImageId != null && message.hasOwnProperty("referenceImageId"))
                            if (typeof message.referenceImageId === "number")
                                object.referenceImageId = options.longs === String ? String(message.referenceImageId) : message.referenceImageId;
                            else
                                object.referenceImageId = options.longs === String ? $util.Long.prototype.toString.call(message.referenceImageId) : options.longs === Number ? new $util.LongBits(message.referenceImageId.low >>> 0, message.referenceImageId.high >>> 0).toNumber() : message.referenceImageId;
                        if (message.testImageId != null && message.hasOwnProperty("testImageId"))
                            if (typeof message.testImageId === "number")
                                object.testImageId = options.longs === String ? String(message.testImageId) : message.testImageId;
                            else
                                object.testImageId = options.longs === String ? $util.Long.prototype.toString.call(message.testImageId) : options.longs === Number ? new $util.LongBits(message.testImageId.low >>> 0, message.testImageId.high >>> 0).toNumber() : message.testImageId;
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.palexy.streaming.v1.CameraShiftEvent.ShiftEventStatus[message.status] : message.status;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.activityLogId != null && message.hasOwnProperty("activityLogId"))
                            if (typeof message.activityLogId === "number")
                                object.activityLogId = options.longs === String ? String(message.activityLogId) : message.activityLogId;
                            else
                                object.activityLogId = options.longs === String ? $util.Long.prototype.toString.call(message.activityLogId) : options.longs === Number ? new $util.LongBits(message.activityLogId.low >>> 0, message.activityLogId.high >>> 0).toNumber() : message.activityLogId;
                        if (message.referenceImage != null && message.hasOwnProperty("referenceImage"))
                            object.referenceImage = $root.palexy.streaming.v1.CameraPhoto.toObject(message.referenceImage, options);
                        if (message.testImage != null && message.hasOwnProperty("testImage"))
                            object.testImage = $root.palexy.streaming.v1.CameraPhoto.toObject(message.testImage, options);
                        return object;
                    };
    
                    /**
                     * Converts this CameraShiftEvent to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CameraShiftEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CameraShiftEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * ShiftEventStatus enum.
                     * @name palexy.streaming.v1.CameraShiftEvent.ShiftEventStatus
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} WAITING_FOR_REVIEW=1 WAITING_FOR_REVIEW value
                     * @property {number} SHIFTED=2 SHIFTED value
                     * @property {number} RESOLVED=3 RESOLVED value
                     * @property {number} NOT_SHIFTED=4 NOT_SHIFTED value
                     * @property {number} EXPIRED=5 EXPIRED value
                     */
                    CameraShiftEvent.ShiftEventStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "WAITING_FOR_REVIEW"] = 1;
                        values[valuesById[2] = "SHIFTED"] = 2;
                        values[valuesById[3] = "RESOLVED"] = 3;
                        values[valuesById[4] = "NOT_SHIFTED"] = 4;
                        values[valuesById[5] = "EXPIRED"] = 5;
                        return values;
                    })();
    
                    return CameraShiftEvent;
                })();
    
                v1.CameraPhoto = (function() {
    
                    /**
                     * Properties of a CameraPhoto.
                     * @memberof palexy.streaming.v1
                     * @interface ICameraPhoto
                     * @property {number|null} [id] CameraPhoto id
                     * @property {number|null} [cameraId] CameraPhoto cameraId
                     * @property {google.protobuf.ITimestamp|null} [createTime] CameraPhoto createTime
                     * @property {Uint8Array|null} [source] CameraPhoto source
                     * @property {string|null} [ext] CameraPhoto ext
                     * @property {palexy.streaming.v1.CameraPhoto.CameraPhotoType|null} [type] CameraPhoto type
                     * @property {Uint8Array|null} [thumbnail] CameraPhoto thumbnail
                     */
    
                    /**
                     * Constructs a new CameraPhoto.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CameraPhoto.
                     * @implements ICameraPhoto
                     * @constructor
                     * @param {palexy.streaming.v1.ICameraPhoto=} [properties] Properties to set
                     */
                    function CameraPhoto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CameraPhoto id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @instance
                     */
                    CameraPhoto.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraPhoto cameraId.
                     * @member {number} cameraId
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @instance
                     */
                    CameraPhoto.prototype.cameraId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CameraPhoto createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @instance
                     */
                    CameraPhoto.prototype.createTime = null;
    
                    /**
                     * CameraPhoto source.
                     * @member {Uint8Array} source
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @instance
                     */
                    CameraPhoto.prototype.source = $util.newBuffer([]);
    
                    /**
                     * CameraPhoto ext.
                     * @member {string} ext
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @instance
                     */
                    CameraPhoto.prototype.ext = "";
    
                    /**
                     * CameraPhoto type.
                     * @member {palexy.streaming.v1.CameraPhoto.CameraPhotoType} type
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @instance
                     */
                    CameraPhoto.prototype.type = 0;
    
                    /**
                     * CameraPhoto thumbnail.
                     * @member {Uint8Array} thumbnail
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @instance
                     */
                    CameraPhoto.prototype.thumbnail = $util.newBuffer([]);
    
                    /**
                     * Creates a new CameraPhoto instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @static
                     * @param {palexy.streaming.v1.ICameraPhoto=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CameraPhoto} CameraPhoto instance
                     */
                    CameraPhoto.create = function create(properties) {
                        return new CameraPhoto(properties);
                    };
    
                    /**
                     * Encodes the specified CameraPhoto message. Does not implicitly {@link palexy.streaming.v1.CameraPhoto.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @static
                     * @param {palexy.streaming.v1.ICameraPhoto} message CameraPhoto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraPhoto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.cameraId);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                            writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.source);
                        if (message.ext != null && Object.hasOwnProperty.call(message, "ext"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.ext);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.type);
                        if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                            writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.thumbnail);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CameraPhoto message, length delimited. Does not implicitly {@link palexy.streaming.v1.CameraPhoto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @static
                     * @param {palexy.streaming.v1.ICameraPhoto} message CameraPhoto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CameraPhoto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CameraPhoto message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CameraPhoto} CameraPhoto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraPhoto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CameraPhoto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.cameraId = reader.int64();
                                break;
                            case 3:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.source = reader.bytes();
                                break;
                            case 5:
                                message.ext = reader.string();
                                break;
                            case 6:
                                message.type = reader.int32();
                                break;
                            case 7:
                                message.thumbnail = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CameraPhoto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CameraPhoto} CameraPhoto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CameraPhoto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CameraPhoto message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CameraPhoto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isInteger(message.cameraId) && !(message.cameraId && $util.isInteger(message.cameraId.low) && $util.isInteger(message.cameraId.high)))
                                return "cameraId: integer|Long expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.source != null && message.hasOwnProperty("source"))
                            if (!(message.source && typeof message.source.length === "number" || $util.isString(message.source)))
                                return "source: buffer expected";
                        if (message.ext != null && message.hasOwnProperty("ext"))
                            if (!$util.isString(message.ext))
                                return "ext: string expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                            if (!(message.thumbnail && typeof message.thumbnail.length === "number" || $util.isString(message.thumbnail)))
                                return "thumbnail: buffer expected";
                        return null;
                    };
    
                    /**
                     * Creates a CameraPhoto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CameraPhoto} CameraPhoto
                     */
                    CameraPhoto.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CameraPhoto)
                            return object;
                        var message = new $root.palexy.streaming.v1.CameraPhoto();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.cameraId != null)
                            if ($util.Long)
                                (message.cameraId = $util.Long.fromValue(object.cameraId)).unsigned = false;
                            else if (typeof object.cameraId === "string")
                                message.cameraId = parseInt(object.cameraId, 10);
                            else if (typeof object.cameraId === "number")
                                message.cameraId = object.cameraId;
                            else if (typeof object.cameraId === "object")
                                message.cameraId = new $util.LongBits(object.cameraId.low >>> 0, object.cameraId.high >>> 0).toNumber();
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.CameraPhoto.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.source != null)
                            if (typeof object.source === "string")
                                $util.base64.decode(object.source, message.source = $util.newBuffer($util.base64.length(object.source)), 0);
                            else if (object.source.length)
                                message.source = object.source;
                        if (object.ext != null)
                            message.ext = String(object.ext);
                        switch (object.type) {
                        case "UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "BASIC":
                        case 1:
                            message.type = 1;
                            break;
                        case "SHIFT_REFERENCE":
                        case 2:
                            message.type = 2;
                            break;
                        case "SHIFT_TESTING":
                        case 3:
                            message.type = 3;
                            break;
                        }
                        if (object.thumbnail != null)
                            if (typeof object.thumbnail === "string")
                                $util.base64.decode(object.thumbnail, message.thumbnail = $util.newBuffer($util.base64.length(object.thumbnail)), 0);
                            else if (object.thumbnail.length)
                                message.thumbnail = object.thumbnail;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CameraPhoto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @static
                     * @param {palexy.streaming.v1.CameraPhoto} message CameraPhoto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CameraPhoto.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cameraId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cameraId = options.longs === String ? "0" : 0;
                            object.createTime = null;
                            if (options.bytes === String)
                                object.source = "";
                            else {
                                object.source = [];
                                if (options.bytes !== Array)
                                    object.source = $util.newBuffer(object.source);
                            }
                            object.ext = "";
                            object.type = options.enums === String ? "UNKNOWN" : 0;
                            if (options.bytes === String)
                                object.thumbnail = "";
                            else {
                                object.thumbnail = [];
                                if (options.bytes !== Array)
                                    object.thumbnail = $util.newBuffer(object.thumbnail);
                            }
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (typeof message.cameraId === "number")
                                object.cameraId = options.longs === String ? String(message.cameraId) : message.cameraId;
                            else
                                object.cameraId = options.longs === String ? $util.Long.prototype.toString.call(message.cameraId) : options.longs === Number ? new $util.LongBits(message.cameraId.low >>> 0, message.cameraId.high >>> 0).toNumber() : message.cameraId;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.source != null && message.hasOwnProperty("source"))
                            object.source = options.bytes === String ? $util.base64.encode(message.source, 0, message.source.length) : options.bytes === Array ? Array.prototype.slice.call(message.source) : message.source;
                        if (message.ext != null && message.hasOwnProperty("ext"))
                            object.ext = message.ext;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.CameraPhoto.CameraPhotoType[message.type] : message.type;
                        if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                            object.thumbnail = options.bytes === String ? $util.base64.encode(message.thumbnail, 0, message.thumbnail.length) : options.bytes === Array ? Array.prototype.slice.call(message.thumbnail) : message.thumbnail;
                        return object;
                    };
    
                    /**
                     * Converts this CameraPhoto to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CameraPhoto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CameraPhoto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * CameraPhotoType enum.
                     * @name palexy.streaming.v1.CameraPhoto.CameraPhotoType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} BASIC=1 BASIC value
                     * @property {number} SHIFT_REFERENCE=2 SHIFT_REFERENCE value
                     * @property {number} SHIFT_TESTING=3 SHIFT_TESTING value
                     */
                    CameraPhoto.CameraPhotoType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "BASIC"] = 1;
                        values[valuesById[2] = "SHIFT_REFERENCE"] = 2;
                        values[valuesById[3] = "SHIFT_TESTING"] = 3;
                        return values;
                    })();
    
                    return CameraPhoto;
                })();
    
                v1.UpdateCameraShiftEventRequest = (function() {
    
                    /**
                     * Properties of an UpdateCameraShiftEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateCameraShiftEventRequest
                     * @property {palexy.streaming.v1.ICameraShiftEvent|null} [event] UpdateCameraShiftEventRequest event
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateCameraShiftEventRequest updateMask
                     * @property {string|null} [informant] UpdateCameraShiftEventRequest informant
                     */
    
                    /**
                     * Constructs a new UpdateCameraShiftEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateCameraShiftEventRequest.
                     * @implements IUpdateCameraShiftEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateCameraShiftEventRequest=} [properties] Properties to set
                     */
                    function UpdateCameraShiftEventRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateCameraShiftEventRequest event.
                     * @member {palexy.streaming.v1.ICameraShiftEvent|null|undefined} event
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @instance
                     */
                    UpdateCameraShiftEventRequest.prototype.event = null;
    
                    /**
                     * UpdateCameraShiftEventRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @instance
                     */
                    UpdateCameraShiftEventRequest.prototype.updateMask = null;
    
                    /**
                     * UpdateCameraShiftEventRequest informant.
                     * @member {string} informant
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @instance
                     */
                    UpdateCameraShiftEventRequest.prototype.informant = "";
    
                    /**
                     * Creates a new UpdateCameraShiftEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateCameraShiftEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateCameraShiftEventRequest} UpdateCameraShiftEventRequest instance
                     */
                    UpdateCameraShiftEventRequest.create = function create(properties) {
                        return new UpdateCameraShiftEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateCameraShiftEventRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateCameraShiftEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateCameraShiftEventRequest} message UpdateCameraShiftEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateCameraShiftEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.event != null && Object.hasOwnProperty.call(message, "event"))
                            $root.palexy.streaming.v1.CameraShiftEvent.encode(message.event, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.informant != null && Object.hasOwnProperty.call(message, "informant"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.informant);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateCameraShiftEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateCameraShiftEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateCameraShiftEventRequest} message UpdateCameraShiftEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateCameraShiftEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateCameraShiftEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateCameraShiftEventRequest} UpdateCameraShiftEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateCameraShiftEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateCameraShiftEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.event = $root.palexy.streaming.v1.CameraShiftEvent.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.informant = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateCameraShiftEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateCameraShiftEventRequest} UpdateCameraShiftEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateCameraShiftEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateCameraShiftEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateCameraShiftEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.event != null && message.hasOwnProperty("event")) {
                            var error = $root.palexy.streaming.v1.CameraShiftEvent.verify(message.event);
                            if (error)
                                return "event." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        if (message.informant != null && message.hasOwnProperty("informant"))
                            if (!$util.isString(message.informant))
                                return "informant: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpdateCameraShiftEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateCameraShiftEventRequest} UpdateCameraShiftEventRequest
                     */
                    UpdateCameraShiftEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateCameraShiftEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateCameraShiftEventRequest();
                        if (object.event != null) {
                            if (typeof object.event !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateCameraShiftEventRequest.event: object expected");
                            message.event = $root.palexy.streaming.v1.CameraShiftEvent.fromObject(object.event);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateCameraShiftEventRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        if (object.informant != null)
                            message.informant = String(object.informant);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateCameraShiftEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateCameraShiftEventRequest} message UpdateCameraShiftEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateCameraShiftEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.event = null;
                            object.updateMask = null;
                            object.informant = "";
                        }
                        if (message.event != null && message.hasOwnProperty("event"))
                            object.event = $root.palexy.streaming.v1.CameraShiftEvent.toObject(message.event, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        if (message.informant != null && message.hasOwnProperty("informant"))
                            object.informant = message.informant;
                        return object;
                    };
    
                    /**
                     * Converts this UpdateCameraShiftEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateCameraShiftEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateCameraShiftEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateCameraShiftEventRequest;
                })();
    
                v1.ExportService = (function() {
    
                    /**
                     * Constructs a new ExportService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ExportService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ExportService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (ExportService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ExportService;
    
                    /**
                     * Creates new ExportService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.ExportService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ExportService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ExportService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ExportService#export_}.
                     * @memberof palexy.streaming.v1.ExportService
                     * @typedef ExportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ExportICADataResponse} [response] ExportICADataResponse
                     */
    
                    /**
                     * Calls Export.
                     * @function export
                     * @memberof palexy.streaming.v1.ExportService
                     * @instance
                     * @param {palexy.streaming.v1.IExportICADataRequest} request ExportICADataRequest message or plain object
                     * @param {palexy.streaming.v1.ExportService.ExportCallback} callback Node-style callback called with the error, if any, and ExportICADataResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExportService.prototype["export"] = function export_(request, callback) {
                        return this.rpcCall(export_, $root.palexy.streaming.v1.ExportICADataRequest, $root.palexy.streaming.v1.ExportICADataResponse, request, callback);
                    }, "name", { value: "Export" });
    
                    /**
                     * Calls Export.
                     * @function export
                     * @memberof palexy.streaming.v1.ExportService
                     * @instance
                     * @param {palexy.streaming.v1.IExportICADataRequest} request ExportICADataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ExportICADataResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ExportService#deleteExportedICADataFile}.
                     * @memberof palexy.streaming.v1.ExportService
                     * @typedef DeleteExportedICADataFileCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.DeleteExportedICADataFileResponse} [response] DeleteExportedICADataFileResponse
                     */
    
                    /**
                     * Calls DeleteExportedICADataFile.
                     * @function deleteExportedICADataFile
                     * @memberof palexy.streaming.v1.ExportService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteExportedICADataFileRequest} request DeleteExportedICADataFileRequest message or plain object
                     * @param {palexy.streaming.v1.ExportService.DeleteExportedICADataFileCallback} callback Node-style callback called with the error, if any, and DeleteExportedICADataFileResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExportService.prototype.deleteExportedICADataFile = function deleteExportedICADataFile(request, callback) {
                        return this.rpcCall(deleteExportedICADataFile, $root.palexy.streaming.v1.DeleteExportedICADataFileRequest, $root.palexy.streaming.v1.DeleteExportedICADataFileResponse, request, callback);
                    }, "name", { value: "DeleteExportedICADataFile" });
    
                    /**
                     * Calls DeleteExportedICADataFile.
                     * @function deleteExportedICADataFile
                     * @memberof palexy.streaming.v1.ExportService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteExportedICADataFileRequest} request DeleteExportedICADataFileRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.DeleteExportedICADataFileResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ExportService#exportReport}.
                     * @memberof palexy.streaming.v1.ExportService
                     * @typedef ExportReportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ExportReportResponse} [response] ExportReportResponse
                     */
    
                    /**
                     * Calls ExportReport.
                     * @function exportReport
                     * @memberof palexy.streaming.v1.ExportService
                     * @instance
                     * @param {palexy.streaming.v1.IExportReportRequest} request ExportReportRequest message or plain object
                     * @param {palexy.streaming.v1.ExportService.ExportReportCallback} callback Node-style callback called with the error, if any, and ExportReportResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExportService.prototype.exportReport = function exportReport(request, callback) {
                        return this.rpcCall(exportReport, $root.palexy.streaming.v1.ExportReportRequest, $root.palexy.streaming.v1.ExportReportResponse, request, callback);
                    }, "name", { value: "ExportReport" });
    
                    /**
                     * Calls ExportReport.
                     * @function exportReport
                     * @memberof palexy.streaming.v1.ExportService
                     * @instance
                     * @param {palexy.streaming.v1.IExportReportRequest} request ExportReportRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ExportReportResponse>} Promise
                     * @variation 2
                     */
    
                    return ExportService;
                })();
    
                v1.ExportICADataRequest = (function() {
    
                    /**
                     * Properties of an ExportICADataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IExportICADataRequest
                     * @property {number|null} [companyId] ExportICADataRequest companyId
                     * @property {string|null} [dateId] ExportICADataRequest dateId
                     * @property {palexy.streaming.v1.ExportICADataRequest.FileFormat|null} [format] ExportICADataRequest format
                     * @property {Array.<string>|null} [actionTypes] ExportICADataRequest actionTypes
                     */
    
                    /**
                     * Constructs a new ExportICADataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ExportICADataRequest.
                     * @implements IExportICADataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IExportICADataRequest=} [properties] Properties to set
                     */
                    function ExportICADataRequest(properties) {
                        this.actionTypes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExportICADataRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @instance
                     */
                    ExportICADataRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExportICADataRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @instance
                     */
                    ExportICADataRequest.prototype.dateId = "";
    
                    /**
                     * ExportICADataRequest format.
                     * @member {palexy.streaming.v1.ExportICADataRequest.FileFormat} format
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @instance
                     */
                    ExportICADataRequest.prototype.format = 0;
    
                    /**
                     * ExportICADataRequest actionTypes.
                     * @member {Array.<string>} actionTypes
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @instance
                     */
                    ExportICADataRequest.prototype.actionTypes = $util.emptyArray;
    
                    /**
                     * Creates a new ExportICADataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.IExportICADataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ExportICADataRequest} ExportICADataRequest instance
                     */
                    ExportICADataRequest.create = function create(properties) {
                        return new ExportICADataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ExportICADataRequest message. Does not implicitly {@link palexy.streaming.v1.ExportICADataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.IExportICADataRequest} message ExportICADataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportICADataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        if (message.format != null && Object.hasOwnProperty.call(message, "format"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.format);
                        if (message.actionTypes != null && message.actionTypes.length)
                            for (var i = 0; i < message.actionTypes.length; ++i)
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.actionTypes[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportICADataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ExportICADataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.IExportICADataRequest} message ExportICADataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportICADataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportICADataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ExportICADataRequest} ExportICADataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportICADataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ExportICADataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            case 3:
                                message.format = reader.int32();
                                break;
                            case 4:
                                if (!(message.actionTypes && message.actionTypes.length))
                                    message.actionTypes = [];
                                message.actionTypes.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportICADataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ExportICADataRequest} ExportICADataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportICADataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportICADataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportICADataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.format != null && message.hasOwnProperty("format"))
                            switch (message.format) {
                            default:
                                return "format: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.actionTypes != null && message.hasOwnProperty("actionTypes")) {
                            if (!Array.isArray(message.actionTypes))
                                return "actionTypes: array expected";
                            for (var i = 0; i < message.actionTypes.length; ++i)
                                if (!$util.isString(message.actionTypes[i]))
                                    return "actionTypes: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ExportICADataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ExportICADataRequest} ExportICADataRequest
                     */
                    ExportICADataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ExportICADataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ExportICADataRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        switch (object.format) {
                        case "UNKNOWN":
                        case 0:
                            message.format = 0;
                            break;
                        case "CSV":
                        case 1:
                            message.format = 1;
                            break;
                        case "COMPRESSED_AVRO":
                        case 2:
                            message.format = 2;
                            break;
                        }
                        if (object.actionTypes) {
                            if (!Array.isArray(object.actionTypes))
                                throw TypeError(".palexy.streaming.v1.ExportICADataRequest.actionTypes: array expected");
                            message.actionTypes = [];
                            for (var i = 0; i < object.actionTypes.length; ++i)
                                message.actionTypes[i] = String(object.actionTypes[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExportICADataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.ExportICADataRequest} message ExportICADataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportICADataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.actionTypes = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.format = options.enums === String ? "UNKNOWN" : 0;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.format != null && message.hasOwnProperty("format"))
                            object.format = options.enums === String ? $root.palexy.streaming.v1.ExportICADataRequest.FileFormat[message.format] : message.format;
                        if (message.actionTypes && message.actionTypes.length) {
                            object.actionTypes = [];
                            for (var j = 0; j < message.actionTypes.length; ++j)
                                object.actionTypes[j] = message.actionTypes[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ExportICADataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ExportICADataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportICADataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * FileFormat enum.
                     * @name palexy.streaming.v1.ExportICADataRequest.FileFormat
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} CSV=1 CSV value
                     * @property {number} COMPRESSED_AVRO=2 COMPRESSED_AVRO value
                     */
                    ExportICADataRequest.FileFormat = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "CSV"] = 1;
                        values[valuesById[2] = "COMPRESSED_AVRO"] = 2;
                        return values;
                    })();
    
                    return ExportICADataRequest;
                })();
    
                v1.ExportICADataResponse = (function() {
    
                    /**
                     * Properties of an ExportICADataResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IExportICADataResponse
                     * @property {Array.<palexy.streaming.v1.IExportedFile>|null} [exportedFiles] ExportICADataResponse exportedFiles
                     */
    
                    /**
                     * Constructs a new ExportICADataResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ExportICADataResponse.
                     * @implements IExportICADataResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IExportICADataResponse=} [properties] Properties to set
                     */
                    function ExportICADataResponse(properties) {
                        this.exportedFiles = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExportICADataResponse exportedFiles.
                     * @member {Array.<palexy.streaming.v1.IExportedFile>} exportedFiles
                     * @memberof palexy.streaming.v1.ExportICADataResponse
                     * @instance
                     */
                    ExportICADataResponse.prototype.exportedFiles = $util.emptyArray;
    
                    /**
                     * Creates a new ExportICADataResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ExportICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.IExportICADataResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ExportICADataResponse} ExportICADataResponse instance
                     */
                    ExportICADataResponse.create = function create(properties) {
                        return new ExportICADataResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ExportICADataResponse message. Does not implicitly {@link palexy.streaming.v1.ExportICADataResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ExportICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.IExportICADataResponse} message ExportICADataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportICADataResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.exportedFiles != null && message.exportedFiles.length)
                            for (var i = 0; i < message.exportedFiles.length; ++i)
                                $root.palexy.streaming.v1.ExportedFile.encode(message.exportedFiles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportICADataResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ExportICADataResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ExportICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.IExportICADataResponse} message ExportICADataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportICADataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportICADataResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ExportICADataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ExportICADataResponse} ExportICADataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportICADataResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ExportICADataResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.exportedFiles && message.exportedFiles.length))
                                    message.exportedFiles = [];
                                message.exportedFiles.push($root.palexy.streaming.v1.ExportedFile.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportICADataResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ExportICADataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ExportICADataResponse} ExportICADataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportICADataResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportICADataResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ExportICADataResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportICADataResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.exportedFiles != null && message.hasOwnProperty("exportedFiles")) {
                            if (!Array.isArray(message.exportedFiles))
                                return "exportedFiles: array expected";
                            for (var i = 0; i < message.exportedFiles.length; ++i) {
                                var error = $root.palexy.streaming.v1.ExportedFile.verify(message.exportedFiles[i]);
                                if (error)
                                    return "exportedFiles." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ExportICADataResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ExportICADataResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ExportICADataResponse} ExportICADataResponse
                     */
                    ExportICADataResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ExportICADataResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ExportICADataResponse();
                        if (object.exportedFiles) {
                            if (!Array.isArray(object.exportedFiles))
                                throw TypeError(".palexy.streaming.v1.ExportICADataResponse.exportedFiles: array expected");
                            message.exportedFiles = [];
                            for (var i = 0; i < object.exportedFiles.length; ++i) {
                                if (typeof object.exportedFiles[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ExportICADataResponse.exportedFiles: object expected");
                                message.exportedFiles[i] = $root.palexy.streaming.v1.ExportedFile.fromObject(object.exportedFiles[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExportICADataResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ExportICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.ExportICADataResponse} message ExportICADataResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportICADataResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.exportedFiles = [];
                        if (message.exportedFiles && message.exportedFiles.length) {
                            object.exportedFiles = [];
                            for (var j = 0; j < message.exportedFiles.length; ++j)
                                object.exportedFiles[j] = $root.palexy.streaming.v1.ExportedFile.toObject(message.exportedFiles[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ExportICADataResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ExportICADataResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportICADataResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExportICADataResponse;
                })();
    
                v1.ExportedFile = (function() {
    
                    /**
                     * Properties of an ExportedFile.
                     * @memberof palexy.streaming.v1
                     * @interface IExportedFile
                     * @property {string|null} [name] ExportedFile name
                     * @property {string|null} [linkUrl] ExportedFile linkUrl
                     * @property {number|null} [rowCount] ExportedFile rowCount
                     */
    
                    /**
                     * Constructs a new ExportedFile.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ExportedFile.
                     * @implements IExportedFile
                     * @constructor
                     * @param {palexy.streaming.v1.IExportedFile=} [properties] Properties to set
                     */
                    function ExportedFile(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExportedFile name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @instance
                     */
                    ExportedFile.prototype.name = "";
    
                    /**
                     * ExportedFile linkUrl.
                     * @member {string} linkUrl
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @instance
                     */
                    ExportedFile.prototype.linkUrl = "";
    
                    /**
                     * ExportedFile rowCount.
                     * @member {number} rowCount
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @instance
                     */
                    ExportedFile.prototype.rowCount = 0;
    
                    /**
                     * Creates a new ExportedFile instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @static
                     * @param {palexy.streaming.v1.IExportedFile=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ExportedFile} ExportedFile instance
                     */
                    ExportedFile.create = function create(properties) {
                        return new ExportedFile(properties);
                    };
    
                    /**
                     * Encodes the specified ExportedFile message. Does not implicitly {@link palexy.streaming.v1.ExportedFile.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @static
                     * @param {palexy.streaming.v1.IExportedFile} message ExportedFile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportedFile.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.linkUrl != null && Object.hasOwnProperty.call(message, "linkUrl"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.linkUrl);
                        if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rowCount);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportedFile message, length delimited. Does not implicitly {@link palexy.streaming.v1.ExportedFile.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @static
                     * @param {palexy.streaming.v1.IExportedFile} message ExportedFile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportedFile.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportedFile message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ExportedFile} ExportedFile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportedFile.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ExportedFile();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.linkUrl = reader.string();
                                break;
                            case 3:
                                message.rowCount = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportedFile message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ExportedFile} ExportedFile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportedFile.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportedFile message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportedFile.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                            if (!$util.isString(message.linkUrl))
                                return "linkUrl: string expected";
                        if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                            if (!$util.isInteger(message.rowCount))
                                return "rowCount: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExportedFile message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ExportedFile} ExportedFile
                     */
                    ExportedFile.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ExportedFile)
                            return object;
                        var message = new $root.palexy.streaming.v1.ExportedFile();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.linkUrl != null)
                            message.linkUrl = String(object.linkUrl);
                        if (object.rowCount != null)
                            message.rowCount = object.rowCount | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExportedFile message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @static
                     * @param {palexy.streaming.v1.ExportedFile} message ExportedFile
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportedFile.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.linkUrl = "";
                            object.rowCount = 0;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                            object.linkUrl = message.linkUrl;
                        if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                            object.rowCount = message.rowCount;
                        return object;
                    };
    
                    /**
                     * Converts this ExportedFile to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ExportedFile
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportedFile.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExportedFile;
                })();
    
                v1.DeleteExportedICADataFileRequest = (function() {
    
                    /**
                     * Properties of a DeleteExportedICADataFileRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteExportedICADataFileRequest
                     * @property {number|null} [companyId] DeleteExportedICADataFileRequest companyId
                     * @property {string|null} [startDateId] DeleteExportedICADataFileRequest startDateId
                     * @property {string|null} [endDateId] DeleteExportedICADataFileRequest endDateId
                     */
    
                    /**
                     * Constructs a new DeleteExportedICADataFileRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteExportedICADataFileRequest.
                     * @implements IDeleteExportedICADataFileRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteExportedICADataFileRequest=} [properties] Properties to set
                     */
                    function DeleteExportedICADataFileRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteExportedICADataFileRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @instance
                     */
                    DeleteExportedICADataFileRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DeleteExportedICADataFileRequest startDateId.
                     * @member {string} startDateId
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @instance
                     */
                    DeleteExportedICADataFileRequest.prototype.startDateId = "";
    
                    /**
                     * DeleteExportedICADataFileRequest endDateId.
                     * @member {string} endDateId
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @instance
                     */
                    DeleteExportedICADataFileRequest.prototype.endDateId = "";
    
                    /**
                     * Creates a new DeleteExportedICADataFileRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteExportedICADataFileRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteExportedICADataFileRequest} DeleteExportedICADataFileRequest instance
                     */
                    DeleteExportedICADataFileRequest.create = function create(properties) {
                        return new DeleteExportedICADataFileRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteExportedICADataFileRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteExportedICADataFileRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteExportedICADataFileRequest} message DeleteExportedICADataFileRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteExportedICADataFileRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.endDateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteExportedICADataFileRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteExportedICADataFileRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteExportedICADataFileRequest} message DeleteExportedICADataFileRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteExportedICADataFileRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteExportedICADataFileRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteExportedICADataFileRequest} DeleteExportedICADataFileRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteExportedICADataFileRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteExportedICADataFileRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.startDateId = reader.string();
                                break;
                            case 3:
                                message.endDateId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteExportedICADataFileRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteExportedICADataFileRequest} DeleteExportedICADataFileRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteExportedICADataFileRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteExportedICADataFileRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteExportedICADataFileRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isString(message.startDateId))
                                return "startDateId: string expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isString(message.endDateId))
                                return "endDateId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteExportedICADataFileRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteExportedICADataFileRequest} DeleteExportedICADataFileRequest
                     */
                    DeleteExportedICADataFileRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteExportedICADataFileRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteExportedICADataFileRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.startDateId != null)
                            message.startDateId = String(object.startDateId);
                        if (object.endDateId != null)
                            message.endDateId = String(object.endDateId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteExportedICADataFileRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteExportedICADataFileRequest} message DeleteExportedICADataFileRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteExportedICADataFileRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.startDateId = "";
                            object.endDateId = "";
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteExportedICADataFileRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteExportedICADataFileRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteExportedICADataFileRequest;
                })();
    
                v1.DeleteExportedICADataFileResponse = (function() {
    
                    /**
                     * Properties of a DeleteExportedICADataFileResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteExportedICADataFileResponse
                     */
    
                    /**
                     * Constructs a new DeleteExportedICADataFileResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteExportedICADataFileResponse.
                     * @implements IDeleteExportedICADataFileResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteExportedICADataFileResponse=} [properties] Properties to set
                     */
                    function DeleteExportedICADataFileResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new DeleteExportedICADataFileResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileResponse
                     * @static
                     * @param {palexy.streaming.v1.IDeleteExportedICADataFileResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteExportedICADataFileResponse} DeleteExportedICADataFileResponse instance
                     */
                    DeleteExportedICADataFileResponse.create = function create(properties) {
                        return new DeleteExportedICADataFileResponse(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteExportedICADataFileResponse message. Does not implicitly {@link palexy.streaming.v1.DeleteExportedICADataFileResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileResponse
                     * @static
                     * @param {palexy.streaming.v1.IDeleteExportedICADataFileResponse} message DeleteExportedICADataFileResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteExportedICADataFileResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteExportedICADataFileResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteExportedICADataFileResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileResponse
                     * @static
                     * @param {palexy.streaming.v1.IDeleteExportedICADataFileResponse} message DeleteExportedICADataFileResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteExportedICADataFileResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteExportedICADataFileResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteExportedICADataFileResponse} DeleteExportedICADataFileResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteExportedICADataFileResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteExportedICADataFileResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteExportedICADataFileResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteExportedICADataFileResponse} DeleteExportedICADataFileResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteExportedICADataFileResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteExportedICADataFileResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteExportedICADataFileResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteExportedICADataFileResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteExportedICADataFileResponse} DeleteExportedICADataFileResponse
                     */
                    DeleteExportedICADataFileResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteExportedICADataFileResponse)
                            return object;
                        return new $root.palexy.streaming.v1.DeleteExportedICADataFileResponse();
                    };
    
                    /**
                     * Creates a plain object from a DeleteExportedICADataFileResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileResponse
                     * @static
                     * @param {palexy.streaming.v1.DeleteExportedICADataFileResponse} message DeleteExportedICADataFileResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteExportedICADataFileResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this DeleteExportedICADataFileResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteExportedICADataFileResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteExportedICADataFileResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteExportedICADataFileResponse;
                })();
    
                v1.ExportReportRequest = (function() {
    
                    /**
                     * Properties of an ExportReportRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IExportReportRequest
                     * @property {number|null} [companyId] ExportReportRequest companyId
                     * @property {string|null} [dateId] ExportReportRequest dateId
                     */
    
                    /**
                     * Constructs a new ExportReportRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ExportReportRequest.
                     * @implements IExportReportRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IExportReportRequest=} [properties] Properties to set
                     */
                    function ExportReportRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExportReportRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ExportReportRequest
                     * @instance
                     */
                    ExportReportRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExportReportRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.ExportReportRequest
                     * @instance
                     */
                    ExportReportRequest.prototype.dateId = "";
    
                    /**
                     * Creates a new ExportReportRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ExportReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IExportReportRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ExportReportRequest} ExportReportRequest instance
                     */
                    ExportReportRequest.create = function create(properties) {
                        return new ExportReportRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ExportReportRequest message. Does not implicitly {@link palexy.streaming.v1.ExportReportRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ExportReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IExportReportRequest} message ExportReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportReportRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportReportRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ExportReportRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ExportReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IExportReportRequest} message ExportReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportReportRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ExportReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ExportReportRequest} ExportReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportReportRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ExportReportRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportReportRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ExportReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ExportReportRequest} ExportReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportReportRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportReportRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ExportReportRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportReportRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an ExportReportRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ExportReportRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ExportReportRequest} ExportReportRequest
                     */
                    ExportReportRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ExportReportRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ExportReportRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExportReportRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ExportReportRequest
                     * @static
                     * @param {palexy.streaming.v1.ExportReportRequest} message ExportReportRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportReportRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        return object;
                    };
    
                    /**
                     * Converts this ExportReportRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ExportReportRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportReportRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExportReportRequest;
                })();
    
                v1.ExportReportResponse = (function() {
    
                    /**
                     * Properties of an ExportReportResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IExportReportResponse
                     * @property {Array.<palexy.streaming.v1.IExportedFile>|null} [exportedFiles] ExportReportResponse exportedFiles
                     */
    
                    /**
                     * Constructs a new ExportReportResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ExportReportResponse.
                     * @implements IExportReportResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IExportReportResponse=} [properties] Properties to set
                     */
                    function ExportReportResponse(properties) {
                        this.exportedFiles = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExportReportResponse exportedFiles.
                     * @member {Array.<palexy.streaming.v1.IExportedFile>} exportedFiles
                     * @memberof palexy.streaming.v1.ExportReportResponse
                     * @instance
                     */
                    ExportReportResponse.prototype.exportedFiles = $util.emptyArray;
    
                    /**
                     * Creates a new ExportReportResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ExportReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IExportReportResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ExportReportResponse} ExportReportResponse instance
                     */
                    ExportReportResponse.create = function create(properties) {
                        return new ExportReportResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ExportReportResponse message. Does not implicitly {@link palexy.streaming.v1.ExportReportResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ExportReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IExportReportResponse} message ExportReportResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportReportResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.exportedFiles != null && message.exportedFiles.length)
                            for (var i = 0; i < message.exportedFiles.length; ++i)
                                $root.palexy.streaming.v1.ExportedFile.encode(message.exportedFiles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportReportResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ExportReportResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ExportReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IExportReportResponse} message ExportReportResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportReportResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportReportResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ExportReportResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ExportReportResponse} ExportReportResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportReportResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ExportReportResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.exportedFiles && message.exportedFiles.length))
                                    message.exportedFiles = [];
                                message.exportedFiles.push($root.palexy.streaming.v1.ExportedFile.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportReportResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ExportReportResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ExportReportResponse} ExportReportResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportReportResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportReportResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ExportReportResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportReportResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.exportedFiles != null && message.hasOwnProperty("exportedFiles")) {
                            if (!Array.isArray(message.exportedFiles))
                                return "exportedFiles: array expected";
                            for (var i = 0; i < message.exportedFiles.length; ++i) {
                                var error = $root.palexy.streaming.v1.ExportedFile.verify(message.exportedFiles[i]);
                                if (error)
                                    return "exportedFiles." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ExportReportResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ExportReportResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ExportReportResponse} ExportReportResponse
                     */
                    ExportReportResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ExportReportResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ExportReportResponse();
                        if (object.exportedFiles) {
                            if (!Array.isArray(object.exportedFiles))
                                throw TypeError(".palexy.streaming.v1.ExportReportResponse.exportedFiles: array expected");
                            message.exportedFiles = [];
                            for (var i = 0; i < object.exportedFiles.length; ++i) {
                                if (typeof object.exportedFiles[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ExportReportResponse.exportedFiles: object expected");
                                message.exportedFiles[i] = $root.palexy.streaming.v1.ExportedFile.fromObject(object.exportedFiles[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExportReportResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ExportReportResponse
                     * @static
                     * @param {palexy.streaming.v1.ExportReportResponse} message ExportReportResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportReportResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.exportedFiles = [];
                        if (message.exportedFiles && message.exportedFiles.length) {
                            object.exportedFiles = [];
                            for (var j = 0; j < message.exportedFiles.length; ++j)
                                object.exportedFiles[j] = $root.palexy.streaming.v1.ExportedFile.toObject(message.exportedFiles[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ExportReportResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ExportReportResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportReportResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExportReportResponse;
                })();
    
                v1.AsyncJobService = (function() {
    
                    /**
                     * Constructs a new AsyncJobService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AsyncJobService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function AsyncJobService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (AsyncJobService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AsyncJobService;
    
                    /**
                     * Creates new AsyncJobService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {AsyncJobService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    AsyncJobService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AsyncJobService#createAsyncJob}.
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @typedef CreateAsyncJobCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.AsyncJob} [response] AsyncJob
                     */
    
                    /**
                     * Calls CreateAsyncJob.
                     * @function createAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IAsyncJob} request AsyncJob message or plain object
                     * @param {palexy.streaming.v1.AsyncJobService.CreateAsyncJobCallback} callback Node-style callback called with the error, if any, and AsyncJob
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AsyncJobService.prototype.createAsyncJob = function createAsyncJob(request, callback) {
                        return this.rpcCall(createAsyncJob, $root.palexy.streaming.v1.AsyncJob, $root.palexy.streaming.v1.AsyncJob, request, callback);
                    }, "name", { value: "CreateAsyncJob" });
    
                    /**
                     * Calls CreateAsyncJob.
                     * @function createAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IAsyncJob} request AsyncJob message or plain object
                     * @returns {Promise<palexy.streaming.v1.AsyncJob>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AsyncJobService#getAsyncJob}.
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @typedef GetAsyncJobCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.AsyncJob} [response] AsyncJob
                     */
    
                    /**
                     * Calls GetAsyncJob.
                     * @function getAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IGetAsyncJobRequest} request GetAsyncJobRequest message or plain object
                     * @param {palexy.streaming.v1.AsyncJobService.GetAsyncJobCallback} callback Node-style callback called with the error, if any, and AsyncJob
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AsyncJobService.prototype.getAsyncJob = function getAsyncJob(request, callback) {
                        return this.rpcCall(getAsyncJob, $root.palexy.streaming.v1.GetAsyncJobRequest, $root.palexy.streaming.v1.AsyncJob, request, callback);
                    }, "name", { value: "GetAsyncJob" });
    
                    /**
                     * Calls GetAsyncJob.
                     * @function getAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IGetAsyncJobRequest} request GetAsyncJobRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.AsyncJob>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AsyncJobService#batchGetAsyncJob}.
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @typedef BatchGetAsyncJobCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchGetAsyncJobResponse} [response] BatchGetAsyncJobResponse
                     */
    
                    /**
                     * Calls BatchGetAsyncJob.
                     * @function batchGetAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchGetAsyncJobRequest} request BatchGetAsyncJobRequest message or plain object
                     * @param {palexy.streaming.v1.AsyncJobService.BatchGetAsyncJobCallback} callback Node-style callback called with the error, if any, and BatchGetAsyncJobResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AsyncJobService.prototype.batchGetAsyncJob = function batchGetAsyncJob(request, callback) {
                        return this.rpcCall(batchGetAsyncJob, $root.palexy.streaming.v1.BatchGetAsyncJobRequest, $root.palexy.streaming.v1.BatchGetAsyncJobResponse, request, callback);
                    }, "name", { value: "BatchGetAsyncJob" });
    
                    /**
                     * Calls BatchGetAsyncJob.
                     * @function batchGetAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchGetAsyncJobRequest} request BatchGetAsyncJobRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchGetAsyncJobResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AsyncJobService#cancelAsyncJob}.
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @typedef CancelAsyncJobCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.AsyncJob} [response] AsyncJob
                     */
    
                    /**
                     * Calls CancelAsyncJob.
                     * @function cancelAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.ICancelAsyncJobRequest} request CancelAsyncJobRequest message or plain object
                     * @param {palexy.streaming.v1.AsyncJobService.CancelAsyncJobCallback} callback Node-style callback called with the error, if any, and AsyncJob
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AsyncJobService.prototype.cancelAsyncJob = function cancelAsyncJob(request, callback) {
                        return this.rpcCall(cancelAsyncJob, $root.palexy.streaming.v1.CancelAsyncJobRequest, $root.palexy.streaming.v1.AsyncJob, request, callback);
                    }, "name", { value: "CancelAsyncJob" });
    
                    /**
                     * Calls CancelAsyncJob.
                     * @function cancelAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.ICancelAsyncJobRequest} request CancelAsyncJobRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.AsyncJob>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AsyncJobService#listAsyncJob}.
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @typedef ListAsyncJobCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListAsyncJobResponse} [response] ListAsyncJobResponse
                     */
    
                    /**
                     * Calls ListAsyncJob.
                     * @function listAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IListAsyncJobRequest} request ListAsyncJobRequest message or plain object
                     * @param {palexy.streaming.v1.AsyncJobService.ListAsyncJobCallback} callback Node-style callback called with the error, if any, and ListAsyncJobResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AsyncJobService.prototype.listAsyncJob = function listAsyncJob(request, callback) {
                        return this.rpcCall(listAsyncJob, $root.palexy.streaming.v1.ListAsyncJobRequest, $root.palexy.streaming.v1.ListAsyncJobResponse, request, callback);
                    }, "name", { value: "ListAsyncJob" });
    
                    /**
                     * Calls ListAsyncJob.
                     * @function listAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IListAsyncJobRequest} request ListAsyncJobRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListAsyncJobResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AsyncJobService#updateAsyncJob}.
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @typedef UpdateAsyncJobCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.AsyncJob} [response] AsyncJob
                     */
    
                    /**
                     * Calls UpdateAsyncJob.
                     * @function updateAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateAsyncJobRequest} request UpdateAsyncJobRequest message or plain object
                     * @param {palexy.streaming.v1.AsyncJobService.UpdateAsyncJobCallback} callback Node-style callback called with the error, if any, and AsyncJob
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AsyncJobService.prototype.updateAsyncJob = function updateAsyncJob(request, callback) {
                        return this.rpcCall(updateAsyncJob, $root.palexy.streaming.v1.UpdateAsyncJobRequest, $root.palexy.streaming.v1.AsyncJob, request, callback);
                    }, "name", { value: "UpdateAsyncJob" });
    
                    /**
                     * Calls UpdateAsyncJob.
                     * @function updateAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateAsyncJobRequest} request UpdateAsyncJobRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.AsyncJob>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AsyncJobService#batchCreateAsyncJob}.
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @typedef BatchCreateAsyncJobCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchCreateAsyncJobResponse} [response] BatchCreateAsyncJobResponse
                     */
    
                    /**
                     * Calls BatchCreateAsyncJob.
                     * @function batchCreateAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreateAsyncJobRequest} request BatchCreateAsyncJobRequest message or plain object
                     * @param {palexy.streaming.v1.AsyncJobService.BatchCreateAsyncJobCallback} callback Node-style callback called with the error, if any, and BatchCreateAsyncJobResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AsyncJobService.prototype.batchCreateAsyncJob = function batchCreateAsyncJob(request, callback) {
                        return this.rpcCall(batchCreateAsyncJob, $root.palexy.streaming.v1.BatchCreateAsyncJobRequest, $root.palexy.streaming.v1.BatchCreateAsyncJobResponse, request, callback);
                    }, "name", { value: "BatchCreateAsyncJob" });
    
                    /**
                     * Calls BatchCreateAsyncJob.
                     * @function batchCreateAsyncJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreateAsyncJobRequest} request BatchCreateAsyncJobRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchCreateAsyncJobResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AsyncJobService#listReadyScheduleTaskJob}.
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @typedef ListReadyScheduleTaskJobCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListAsyncJobResponse} [response] ListAsyncJobResponse
                     */
    
                    /**
                     * Calls ListReadyScheduleTaskJob.
                     * @function listReadyScheduleTaskJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IListReadyScheduleTaskJobRequest} request ListReadyScheduleTaskJobRequest message or plain object
                     * @param {palexy.streaming.v1.AsyncJobService.ListReadyScheduleTaskJobCallback} callback Node-style callback called with the error, if any, and ListAsyncJobResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AsyncJobService.prototype.listReadyScheduleTaskJob = function listReadyScheduleTaskJob(request, callback) {
                        return this.rpcCall(listReadyScheduleTaskJob, $root.palexy.streaming.v1.ListReadyScheduleTaskJobRequest, $root.palexy.streaming.v1.ListAsyncJobResponse, request, callback);
                    }, "name", { value: "ListReadyScheduleTaskJob" });
    
                    /**
                     * Calls ListReadyScheduleTaskJob.
                     * @function listReadyScheduleTaskJob
                     * @memberof palexy.streaming.v1.AsyncJobService
                     * @instance
                     * @param {palexy.streaming.v1.IListReadyScheduleTaskJobRequest} request ListReadyScheduleTaskJobRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListAsyncJobResponse>} Promise
                     * @variation 2
                     */
    
                    return AsyncJobService;
                })();
    
                v1.AsyncJob = (function() {
    
                    /**
                     * Properties of an AsyncJob.
                     * @memberof palexy.streaming.v1
                     * @interface IAsyncJob
                     * @property {number|null} [id] AsyncJob id
                     * @property {number|null} [companyId] AsyncJob companyId
                     * @property {palexy.streaming.v1.AsyncJob.Type|null} [type] AsyncJob type
                     * @property {palexy.streaming.v1.AsyncJob.Status|null} [status] AsyncJob status
                     * @property {number|null} [ownerId] AsyncJob ownerId
                     * @property {google.protobuf.ITimestamp|null} [createTime] AsyncJob createTime
                     * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] AsyncJob lastUpdateTime
                     * @property {number|null} [retryCount] AsyncJob retryCount
                     * @property {palexy.streaming.v1.AsyncJob.IExport|null} [asyncExportJob] AsyncJob asyncExportJob
                     * @property {palexy.streaming.v1.AsyncJob.IPosMatching|null} [asyncPosMatchingJob] AsyncJob asyncPosMatchingJob
                     * @property {palexy.streaming.v1.AsyncJob.IPosImport|null} [asyncPosImportJob] AsyncJob asyncPosImportJob
                     * @property {palexy.streaming.v1.AsyncJob.IAggregate|null} [asyncAggregateJob] AsyncJob asyncAggregateJob
                     * @property {palexy.streaming.v1.AsyncJob.IAggregateLevel2|null} [asyncAggregateLevel_2Job] AsyncJob asyncAggregateLevel_2Job
                     * @property {palexy.streaming.v1.AsyncJob.ICalculateConsistency|null} [asyncCalculateConsistencyJob] AsyncJob asyncCalculateConsistencyJob
                     * @property {palexy.streaming.v1.AsyncJob.IScheduleTask|null} [asyncScheduleTaskJob] AsyncJob asyncScheduleTaskJob
                     * @property {palexy.streaming.v1.AsyncJob.IStaffImport|null} [staffImportJob] AsyncJob staffImportJob
                     * @property {palexy.streaming.v1.AsyncJob.IExportReport|null} [asyncExportReportJob] AsyncJob asyncExportReportJob
                     * @property {palexy.streaming.v1.AsyncJob.IUpdateWeatherData|null} [asyncUpdateWeatherDataJob] AsyncJob asyncUpdateWeatherDataJob
                     * @property {palexy.streaming.v1.AsyncJob.IGenerateInsight|null} [asyncGenerateInsightJob] AsyncJob asyncGenerateInsightJob
                     * @property {palexy.streaming.v1.AsyncJob.ISendReportSchedule|null} [asyncSendReportScheduleJob] AsyncJob asyncSendReportScheduleJob
                     * @property {palexy.streaming.v1.AsyncJob.IExternalTrafficImport|null} [asyncExternalTrafficImportJob] AsyncJob asyncExternalTrafficImportJob
                     * @property {palexy.streaming.v1.AsyncJob.IGenerateTrendReport|null} [asyncGenerateTrendReportJob] AsyncJob asyncGenerateTrendReportJob
                     */
    
                    /**
                     * Constructs a new AsyncJob.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AsyncJob.
                     * @implements IAsyncJob
                     * @constructor
                     * @param {palexy.streaming.v1.IAsyncJob=} [properties] Properties to set
                     */
                    function AsyncJob(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AsyncJob id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AsyncJob companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AsyncJob type.
                     * @member {palexy.streaming.v1.AsyncJob.Type} type
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.type = 0;
    
                    /**
                     * AsyncJob status.
                     * @member {palexy.streaming.v1.AsyncJob.Status} status
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.status = 0;
    
                    /**
                     * AsyncJob ownerId.
                     * @member {number} ownerId
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.ownerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AsyncJob createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.createTime = null;
    
                    /**
                     * AsyncJob lastUpdateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.lastUpdateTime = null;
    
                    /**
                     * AsyncJob retryCount.
                     * @member {number} retryCount
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.retryCount = 0;
    
                    /**
                     * AsyncJob asyncExportJob.
                     * @member {palexy.streaming.v1.AsyncJob.IExport|null|undefined} asyncExportJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncExportJob = null;
    
                    /**
                     * AsyncJob asyncPosMatchingJob.
                     * @member {palexy.streaming.v1.AsyncJob.IPosMatching|null|undefined} asyncPosMatchingJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncPosMatchingJob = null;
    
                    /**
                     * AsyncJob asyncPosImportJob.
                     * @member {palexy.streaming.v1.AsyncJob.IPosImport|null|undefined} asyncPosImportJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncPosImportJob = null;
    
                    /**
                     * AsyncJob asyncAggregateJob.
                     * @member {palexy.streaming.v1.AsyncJob.IAggregate|null|undefined} asyncAggregateJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncAggregateJob = null;
    
                    /**
                     * AsyncJob asyncAggregateLevel_2Job.
                     * @member {palexy.streaming.v1.AsyncJob.IAggregateLevel2|null|undefined} asyncAggregateLevel_2Job
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncAggregateLevel_2Job = null;
    
                    /**
                     * AsyncJob asyncCalculateConsistencyJob.
                     * @member {palexy.streaming.v1.AsyncJob.ICalculateConsistency|null|undefined} asyncCalculateConsistencyJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncCalculateConsistencyJob = null;
    
                    /**
                     * AsyncJob asyncScheduleTaskJob.
                     * @member {palexy.streaming.v1.AsyncJob.IScheduleTask|null|undefined} asyncScheduleTaskJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncScheduleTaskJob = null;
    
                    /**
                     * AsyncJob staffImportJob.
                     * @member {palexy.streaming.v1.AsyncJob.IStaffImport|null|undefined} staffImportJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.staffImportJob = null;
    
                    /**
                     * AsyncJob asyncExportReportJob.
                     * @member {palexy.streaming.v1.AsyncJob.IExportReport|null|undefined} asyncExportReportJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncExportReportJob = null;
    
                    /**
                     * AsyncJob asyncUpdateWeatherDataJob.
                     * @member {palexy.streaming.v1.AsyncJob.IUpdateWeatherData|null|undefined} asyncUpdateWeatherDataJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncUpdateWeatherDataJob = null;
    
                    /**
                     * AsyncJob asyncGenerateInsightJob.
                     * @member {palexy.streaming.v1.AsyncJob.IGenerateInsight|null|undefined} asyncGenerateInsightJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncGenerateInsightJob = null;
    
                    /**
                     * AsyncJob asyncSendReportScheduleJob.
                     * @member {palexy.streaming.v1.AsyncJob.ISendReportSchedule|null|undefined} asyncSendReportScheduleJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncSendReportScheduleJob = null;
    
                    /**
                     * AsyncJob asyncExternalTrafficImportJob.
                     * @member {palexy.streaming.v1.AsyncJob.IExternalTrafficImport|null|undefined} asyncExternalTrafficImportJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncExternalTrafficImportJob = null;
    
                    /**
                     * AsyncJob asyncGenerateTrendReportJob.
                     * @member {palexy.streaming.v1.AsyncJob.IGenerateTrendReport|null|undefined} asyncGenerateTrendReportJob
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    AsyncJob.prototype.asyncGenerateTrendReportJob = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * AsyncJob asyncJobObject.
                     * @member {"asyncExportJob"|"asyncPosMatchingJob"|"asyncPosImportJob"|"asyncAggregateJob"|"asyncAggregateLevel_2Job"|"asyncCalculateConsistencyJob"|"asyncScheduleTaskJob"|"staffImportJob"|"asyncExportReportJob"|"asyncUpdateWeatherDataJob"|"asyncGenerateInsightJob"|"asyncSendReportScheduleJob"|"asyncExternalTrafficImportJob"|"asyncGenerateTrendReportJob"|undefined} asyncJobObject
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     */
                    Object.defineProperty(AsyncJob.prototype, "asyncJobObject", {
                        get: $util.oneOfGetter($oneOfFields = ["asyncExportJob", "asyncPosMatchingJob", "asyncPosImportJob", "asyncAggregateJob", "asyncAggregateLevel_2Job", "asyncCalculateConsistencyJob", "asyncScheduleTaskJob", "staffImportJob", "asyncExportReportJob", "asyncUpdateWeatherDataJob", "asyncGenerateInsightJob", "asyncSendReportScheduleJob", "asyncExternalTrafficImportJob", "asyncGenerateTrendReportJob"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new AsyncJob instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @static
                     * @param {palexy.streaming.v1.IAsyncJob=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AsyncJob} AsyncJob instance
                     */
                    AsyncJob.create = function create(properties) {
                        return new AsyncJob(properties);
                    };
    
                    /**
                     * Encodes the specified AsyncJob message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @static
                     * @param {palexy.streaming.v1.IAsyncJob} message AsyncJob message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AsyncJob.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
                        if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.ownerId);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.asyncExportJob != null && Object.hasOwnProperty.call(message, "asyncExportJob"))
                            $root.palexy.streaming.v1.AsyncJob.Export.encode(message.asyncExportJob, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.asyncPosMatchingJob != null && Object.hasOwnProperty.call(message, "asyncPosMatchingJob"))
                            $root.palexy.streaming.v1.AsyncJob.PosMatching.encode(message.asyncPosMatchingJob, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.asyncPosImportJob != null && Object.hasOwnProperty.call(message, "asyncPosImportJob"))
                            $root.palexy.streaming.v1.AsyncJob.PosImport.encode(message.asyncPosImportJob, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.asyncAggregateJob != null && Object.hasOwnProperty.call(message, "asyncAggregateJob"))
                            $root.palexy.streaming.v1.AsyncJob.Aggregate.encode(message.asyncAggregateJob, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.asyncAggregateLevel_2Job != null && Object.hasOwnProperty.call(message, "asyncAggregateLevel_2Job"))
                            $root.palexy.streaming.v1.AsyncJob.AggregateLevel2.encode(message.asyncAggregateLevel_2Job, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                        if (message.retryCount != null && Object.hasOwnProperty.call(message, "retryCount"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.retryCount);
                        if (message.asyncCalculateConsistencyJob != null && Object.hasOwnProperty.call(message, "asyncCalculateConsistencyJob"))
                            $root.palexy.streaming.v1.AsyncJob.CalculateConsistency.encode(message.asyncCalculateConsistencyJob, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                        if (message.asyncScheduleTaskJob != null && Object.hasOwnProperty.call(message, "asyncScheduleTaskJob"))
                            $root.palexy.streaming.v1.AsyncJob.ScheduleTask.encode(message.asyncScheduleTaskJob, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                        if (message.staffImportJob != null && Object.hasOwnProperty.call(message, "staffImportJob"))
                            $root.palexy.streaming.v1.AsyncJob.StaffImport.encode(message.staffImportJob, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.asyncExportReportJob != null && Object.hasOwnProperty.call(message, "asyncExportReportJob"))
                            $root.palexy.streaming.v1.AsyncJob.ExportReport.encode(message.asyncExportReportJob, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        if (message.asyncUpdateWeatherDataJob != null && Object.hasOwnProperty.call(message, "asyncUpdateWeatherDataJob"))
                            $root.palexy.streaming.v1.AsyncJob.UpdateWeatherData.encode(message.asyncUpdateWeatherDataJob, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                        if (message.asyncGenerateInsightJob != null && Object.hasOwnProperty.call(message, "asyncGenerateInsightJob"))
                            $root.palexy.streaming.v1.AsyncJob.GenerateInsight.encode(message.asyncGenerateInsightJob, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                        if (message.asyncSendReportScheduleJob != null && Object.hasOwnProperty.call(message, "asyncSendReportScheduleJob"))
                            $root.palexy.streaming.v1.AsyncJob.SendReportSchedule.encode(message.asyncSendReportScheduleJob, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                        if (message.asyncExternalTrafficImportJob != null && Object.hasOwnProperty.call(message, "asyncExternalTrafficImportJob"))
                            $root.palexy.streaming.v1.AsyncJob.ExternalTrafficImport.encode(message.asyncExternalTrafficImportJob, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                        if (message.asyncGenerateTrendReportJob != null && Object.hasOwnProperty.call(message, "asyncGenerateTrendReportJob"))
                            $root.palexy.streaming.v1.AsyncJob.GenerateTrendReport.encode(message.asyncGenerateTrendReportJob, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AsyncJob message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @static
                     * @param {palexy.streaming.v1.IAsyncJob} message AsyncJob message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AsyncJob.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AsyncJob message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AsyncJob} AsyncJob
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AsyncJob.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.type = reader.int32();
                                break;
                            case 4:
                                message.status = reader.int32();
                                break;
                            case 5:
                                message.ownerId = reader.int64();
                                break;
                            case 6:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 13:
                                message.retryCount = reader.int32();
                                break;
                            case 8:
                                message.asyncExportJob = $root.palexy.streaming.v1.AsyncJob.Export.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.asyncPosMatchingJob = $root.palexy.streaming.v1.AsyncJob.PosMatching.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.asyncPosImportJob = $root.palexy.streaming.v1.AsyncJob.PosImport.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.asyncAggregateJob = $root.palexy.streaming.v1.AsyncJob.Aggregate.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.asyncAggregateLevel_2Job = $root.palexy.streaming.v1.AsyncJob.AggregateLevel2.decode(reader, reader.uint32());
                                break;
                            case 14:
                                message.asyncCalculateConsistencyJob = $root.palexy.streaming.v1.AsyncJob.CalculateConsistency.decode(reader, reader.uint32());
                                break;
                            case 15:
                                message.asyncScheduleTaskJob = $root.palexy.streaming.v1.AsyncJob.ScheduleTask.decode(reader, reader.uint32());
                                break;
                            case 16:
                                message.staffImportJob = $root.palexy.streaming.v1.AsyncJob.StaffImport.decode(reader, reader.uint32());
                                break;
                            case 17:
                                message.asyncExportReportJob = $root.palexy.streaming.v1.AsyncJob.ExportReport.decode(reader, reader.uint32());
                                break;
                            case 18:
                                message.asyncUpdateWeatherDataJob = $root.palexy.streaming.v1.AsyncJob.UpdateWeatherData.decode(reader, reader.uint32());
                                break;
                            case 19:
                                message.asyncGenerateInsightJob = $root.palexy.streaming.v1.AsyncJob.GenerateInsight.decode(reader, reader.uint32());
                                break;
                            case 20:
                                message.asyncSendReportScheduleJob = $root.palexy.streaming.v1.AsyncJob.SendReportSchedule.decode(reader, reader.uint32());
                                break;
                            case 21:
                                message.asyncExternalTrafficImportJob = $root.palexy.streaming.v1.AsyncJob.ExternalTrafficImport.decode(reader, reader.uint32());
                                break;
                            case 22:
                                message.asyncGenerateTrendReportJob = $root.palexy.streaming.v1.AsyncJob.GenerateTrendReport.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AsyncJob message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AsyncJob} AsyncJob
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AsyncJob.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AsyncJob message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AsyncJob.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                                break;
                            }
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                                break;
                            }
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (!$util.isInteger(message.ownerId) && !(message.ownerId && $util.isInteger(message.ownerId.low) && $util.isInteger(message.ownerId.high)))
                                return "ownerId: integer|Long expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                            if (error)
                                return "lastUpdateTime." + error;
                        }
                        if (message.retryCount != null && message.hasOwnProperty("retryCount"))
                            if (!$util.isInteger(message.retryCount))
                                return "retryCount: integer expected";
                        if (message.asyncExportJob != null && message.hasOwnProperty("asyncExportJob")) {
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.Export.verify(message.asyncExportJob);
                                if (error)
                                    return "asyncExportJob." + error;
                            }
                        }
                        if (message.asyncPosMatchingJob != null && message.hasOwnProperty("asyncPosMatchingJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.PosMatching.verify(message.asyncPosMatchingJob);
                                if (error)
                                    return "asyncPosMatchingJob." + error;
                            }
                        }
                        if (message.asyncPosImportJob != null && message.hasOwnProperty("asyncPosImportJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.PosImport.verify(message.asyncPosImportJob);
                                if (error)
                                    return "asyncPosImportJob." + error;
                            }
                        }
                        if (message.asyncAggregateJob != null && message.hasOwnProperty("asyncAggregateJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.Aggregate.verify(message.asyncAggregateJob);
                                if (error)
                                    return "asyncAggregateJob." + error;
                            }
                        }
                        if (message.asyncAggregateLevel_2Job != null && message.hasOwnProperty("asyncAggregateLevel_2Job")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.AggregateLevel2.verify(message.asyncAggregateLevel_2Job);
                                if (error)
                                    return "asyncAggregateLevel_2Job." + error;
                            }
                        }
                        if (message.asyncCalculateConsistencyJob != null && message.hasOwnProperty("asyncCalculateConsistencyJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.CalculateConsistency.verify(message.asyncCalculateConsistencyJob);
                                if (error)
                                    return "asyncCalculateConsistencyJob." + error;
                            }
                        }
                        if (message.asyncScheduleTaskJob != null && message.hasOwnProperty("asyncScheduleTaskJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.ScheduleTask.verify(message.asyncScheduleTaskJob);
                                if (error)
                                    return "asyncScheduleTaskJob." + error;
                            }
                        }
                        if (message.staffImportJob != null && message.hasOwnProperty("staffImportJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.StaffImport.verify(message.staffImportJob);
                                if (error)
                                    return "staffImportJob." + error;
                            }
                        }
                        if (message.asyncExportReportJob != null && message.hasOwnProperty("asyncExportReportJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.ExportReport.verify(message.asyncExportReportJob);
                                if (error)
                                    return "asyncExportReportJob." + error;
                            }
                        }
                        if (message.asyncUpdateWeatherDataJob != null && message.hasOwnProperty("asyncUpdateWeatherDataJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.UpdateWeatherData.verify(message.asyncUpdateWeatherDataJob);
                                if (error)
                                    return "asyncUpdateWeatherDataJob." + error;
                            }
                        }
                        if (message.asyncGenerateInsightJob != null && message.hasOwnProperty("asyncGenerateInsightJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.GenerateInsight.verify(message.asyncGenerateInsightJob);
                                if (error)
                                    return "asyncGenerateInsightJob." + error;
                            }
                        }
                        if (message.asyncSendReportScheduleJob != null && message.hasOwnProperty("asyncSendReportScheduleJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.SendReportSchedule.verify(message.asyncSendReportScheduleJob);
                                if (error)
                                    return "asyncSendReportScheduleJob." + error;
                            }
                        }
                        if (message.asyncExternalTrafficImportJob != null && message.hasOwnProperty("asyncExternalTrafficImportJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.ExternalTrafficImport.verify(message.asyncExternalTrafficImportJob);
                                if (error)
                                    return "asyncExternalTrafficImportJob." + error;
                            }
                        }
                        if (message.asyncGenerateTrendReportJob != null && message.hasOwnProperty("asyncGenerateTrendReportJob")) {
                            if (properties.asyncJobObject === 1)
                                return "asyncJobObject: multiple values";
                            properties.asyncJobObject = 1;
                            {
                                var error = $root.palexy.streaming.v1.AsyncJob.GenerateTrendReport.verify(message.asyncGenerateTrendReportJob);
                                if (error)
                                    return "asyncGenerateTrendReportJob." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an AsyncJob message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AsyncJob} AsyncJob
                     */
                    AsyncJob.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AsyncJob)
                            return object;
                        var message = new $root.palexy.streaming.v1.AsyncJob();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        switch (object.type) {
                        case "UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "EXPORT":
                        case 1:
                            message.type = 1;
                            break;
                        case "POS_MATCHING":
                        case 2:
                            message.type = 2;
                            break;
                        case "POS_IMPORT":
                        case 3:
                            message.type = 3;
                            break;
                        case "AGGREGATE":
                        case 4:
                            message.type = 4;
                            break;
                        case "AGGREGATE_LEVEL_2":
                        case 5:
                            message.type = 5;
                            break;
                        case "CALCULATE_CONSISTENCY":
                        case 6:
                            message.type = 6;
                            break;
                        case "SCHEDULE_TASK":
                        case 7:
                            message.type = 7;
                            break;
                        case "STAFF_DATA_IMPORT":
                        case 8:
                            message.type = 8;
                            break;
                        case "EXPORT_REPORT":
                        case 9:
                            message.type = 9;
                            break;
                        case "UPDATE_WEATHER_DATA":
                        case 10:
                            message.type = 10;
                            break;
                        case "GENERATE_INSIGHT":
                        case 11:
                            message.type = 11;
                            break;
                        case "SEND_REPORT_SCHEDULE":
                        case 12:
                            message.type = 12;
                            break;
                        case "EXTERNAL_TRAFFIC_IMPORT":
                        case 13:
                            message.type = 13;
                            break;
                        case "GENERATE_TREND_REPORT":
                        case 14:
                            message.type = 14;
                            break;
                        }
                        switch (object.status) {
                        case "UNPROCESSED":
                        case 0:
                            message.status = 0;
                            break;
                        case "PROCESSING":
                        case 1:
                            message.status = 1;
                            break;
                        case "WAIT_FOR_RETRYING":
                        case 2:
                            message.status = 2;
                            break;
                        case "DONE":
                        case 3:
                            message.status = 3;
                            break;
                        case "CANCELLED":
                        case 4:
                            message.status = 4;
                            break;
                        case "SKIPPED":
                        case 5:
                            message.status = 5;
                            break;
                        case "RECURRING":
                        case 6:
                            message.status = 6;
                            break;
                        }
                        if (object.ownerId != null)
                            if ($util.Long)
                                (message.ownerId = $util.Long.fromValue(object.ownerId)).unsigned = false;
                            else if (typeof object.ownerId === "string")
                                message.ownerId = parseInt(object.ownerId, 10);
                            else if (typeof object.ownerId === "number")
                                message.ownerId = object.ownerId;
                            else if (typeof object.ownerId === "object")
                                message.ownerId = new $util.LongBits(object.ownerId.low >>> 0, object.ownerId.high >>> 0).toNumber();
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.lastUpdateTime != null) {
                            if (typeof object.lastUpdateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.lastUpdateTime: object expected");
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                        }
                        if (object.retryCount != null)
                            message.retryCount = object.retryCount | 0;
                        if (object.asyncExportJob != null) {
                            if (typeof object.asyncExportJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncExportJob: object expected");
                            message.asyncExportJob = $root.palexy.streaming.v1.AsyncJob.Export.fromObject(object.asyncExportJob);
                        }
                        if (object.asyncPosMatchingJob != null) {
                            if (typeof object.asyncPosMatchingJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncPosMatchingJob: object expected");
                            message.asyncPosMatchingJob = $root.palexy.streaming.v1.AsyncJob.PosMatching.fromObject(object.asyncPosMatchingJob);
                        }
                        if (object.asyncPosImportJob != null) {
                            if (typeof object.asyncPosImportJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncPosImportJob: object expected");
                            message.asyncPosImportJob = $root.palexy.streaming.v1.AsyncJob.PosImport.fromObject(object.asyncPosImportJob);
                        }
                        if (object.asyncAggregateJob != null) {
                            if (typeof object.asyncAggregateJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncAggregateJob: object expected");
                            message.asyncAggregateJob = $root.palexy.streaming.v1.AsyncJob.Aggregate.fromObject(object.asyncAggregateJob);
                        }
                        if (object.asyncAggregateLevel_2Job != null) {
                            if (typeof object.asyncAggregateLevel_2Job !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncAggregateLevel_2Job: object expected");
                            message.asyncAggregateLevel_2Job = $root.palexy.streaming.v1.AsyncJob.AggregateLevel2.fromObject(object.asyncAggregateLevel_2Job);
                        }
                        if (object.asyncCalculateConsistencyJob != null) {
                            if (typeof object.asyncCalculateConsistencyJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncCalculateConsistencyJob: object expected");
                            message.asyncCalculateConsistencyJob = $root.palexy.streaming.v1.AsyncJob.CalculateConsistency.fromObject(object.asyncCalculateConsistencyJob);
                        }
                        if (object.asyncScheduleTaskJob != null) {
                            if (typeof object.asyncScheduleTaskJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncScheduleTaskJob: object expected");
                            message.asyncScheduleTaskJob = $root.palexy.streaming.v1.AsyncJob.ScheduleTask.fromObject(object.asyncScheduleTaskJob);
                        }
                        if (object.staffImportJob != null) {
                            if (typeof object.staffImportJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.staffImportJob: object expected");
                            message.staffImportJob = $root.palexy.streaming.v1.AsyncJob.StaffImport.fromObject(object.staffImportJob);
                        }
                        if (object.asyncExportReportJob != null) {
                            if (typeof object.asyncExportReportJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncExportReportJob: object expected");
                            message.asyncExportReportJob = $root.palexy.streaming.v1.AsyncJob.ExportReport.fromObject(object.asyncExportReportJob);
                        }
                        if (object.asyncUpdateWeatherDataJob != null) {
                            if (typeof object.asyncUpdateWeatherDataJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncUpdateWeatherDataJob: object expected");
                            message.asyncUpdateWeatherDataJob = $root.palexy.streaming.v1.AsyncJob.UpdateWeatherData.fromObject(object.asyncUpdateWeatherDataJob);
                        }
                        if (object.asyncGenerateInsightJob != null) {
                            if (typeof object.asyncGenerateInsightJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncGenerateInsightJob: object expected");
                            message.asyncGenerateInsightJob = $root.palexy.streaming.v1.AsyncJob.GenerateInsight.fromObject(object.asyncGenerateInsightJob);
                        }
                        if (object.asyncSendReportScheduleJob != null) {
                            if (typeof object.asyncSendReportScheduleJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncSendReportScheduleJob: object expected");
                            message.asyncSendReportScheduleJob = $root.palexy.streaming.v1.AsyncJob.SendReportSchedule.fromObject(object.asyncSendReportScheduleJob);
                        }
                        if (object.asyncExternalTrafficImportJob != null) {
                            if (typeof object.asyncExternalTrafficImportJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncExternalTrafficImportJob: object expected");
                            message.asyncExternalTrafficImportJob = $root.palexy.streaming.v1.AsyncJob.ExternalTrafficImport.fromObject(object.asyncExternalTrafficImportJob);
                        }
                        if (object.asyncGenerateTrendReportJob != null) {
                            if (typeof object.asyncGenerateTrendReportJob !== "object")
                                throw TypeError(".palexy.streaming.v1.AsyncJob.asyncGenerateTrendReportJob: object expected");
                            message.asyncGenerateTrendReportJob = $root.palexy.streaming.v1.AsyncJob.GenerateTrendReport.fromObject(object.asyncGenerateTrendReportJob);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AsyncJob message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @static
                     * @param {palexy.streaming.v1.AsyncJob} message AsyncJob
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AsyncJob.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.type = options.enums === String ? "UNKNOWN" : 0;
                            object.status = options.enums === String ? "UNPROCESSED" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.ownerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.ownerId = options.longs === String ? "0" : 0;
                            object.createTime = null;
                            object.lastUpdateTime = null;
                            object.retryCount = 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.AsyncJob.Type[message.type] : message.type;
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.palexy.streaming.v1.AsyncJob.Status[message.status] : message.status;
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (typeof message.ownerId === "number")
                                object.ownerId = options.longs === String ? String(message.ownerId) : message.ownerId;
                            else
                                object.ownerId = options.longs === String ? $util.Long.prototype.toString.call(message.ownerId) : options.longs === Number ? new $util.LongBits(message.ownerId.low >>> 0, message.ownerId.high >>> 0).toNumber() : message.ownerId;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                            object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                        if (message.asyncExportJob != null && message.hasOwnProperty("asyncExportJob")) {
                            object.asyncExportJob = $root.palexy.streaming.v1.AsyncJob.Export.toObject(message.asyncExportJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncExportJob";
                        }
                        if (message.asyncPosMatchingJob != null && message.hasOwnProperty("asyncPosMatchingJob")) {
                            object.asyncPosMatchingJob = $root.palexy.streaming.v1.AsyncJob.PosMatching.toObject(message.asyncPosMatchingJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncPosMatchingJob";
                        }
                        if (message.asyncPosImportJob != null && message.hasOwnProperty("asyncPosImportJob")) {
                            object.asyncPosImportJob = $root.palexy.streaming.v1.AsyncJob.PosImport.toObject(message.asyncPosImportJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncPosImportJob";
                        }
                        if (message.asyncAggregateJob != null && message.hasOwnProperty("asyncAggregateJob")) {
                            object.asyncAggregateJob = $root.palexy.streaming.v1.AsyncJob.Aggregate.toObject(message.asyncAggregateJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncAggregateJob";
                        }
                        if (message.asyncAggregateLevel_2Job != null && message.hasOwnProperty("asyncAggregateLevel_2Job")) {
                            object.asyncAggregateLevel_2Job = $root.palexy.streaming.v1.AsyncJob.AggregateLevel2.toObject(message.asyncAggregateLevel_2Job, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncAggregateLevel_2Job";
                        }
                        if (message.retryCount != null && message.hasOwnProperty("retryCount"))
                            object.retryCount = message.retryCount;
                        if (message.asyncCalculateConsistencyJob != null && message.hasOwnProperty("asyncCalculateConsistencyJob")) {
                            object.asyncCalculateConsistencyJob = $root.palexy.streaming.v1.AsyncJob.CalculateConsistency.toObject(message.asyncCalculateConsistencyJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncCalculateConsistencyJob";
                        }
                        if (message.asyncScheduleTaskJob != null && message.hasOwnProperty("asyncScheduleTaskJob")) {
                            object.asyncScheduleTaskJob = $root.palexy.streaming.v1.AsyncJob.ScheduleTask.toObject(message.asyncScheduleTaskJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncScheduleTaskJob";
                        }
                        if (message.staffImportJob != null && message.hasOwnProperty("staffImportJob")) {
                            object.staffImportJob = $root.palexy.streaming.v1.AsyncJob.StaffImport.toObject(message.staffImportJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "staffImportJob";
                        }
                        if (message.asyncExportReportJob != null && message.hasOwnProperty("asyncExportReportJob")) {
                            object.asyncExportReportJob = $root.palexy.streaming.v1.AsyncJob.ExportReport.toObject(message.asyncExportReportJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncExportReportJob";
                        }
                        if (message.asyncUpdateWeatherDataJob != null && message.hasOwnProperty("asyncUpdateWeatherDataJob")) {
                            object.asyncUpdateWeatherDataJob = $root.palexy.streaming.v1.AsyncJob.UpdateWeatherData.toObject(message.asyncUpdateWeatherDataJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncUpdateWeatherDataJob";
                        }
                        if (message.asyncGenerateInsightJob != null && message.hasOwnProperty("asyncGenerateInsightJob")) {
                            object.asyncGenerateInsightJob = $root.palexy.streaming.v1.AsyncJob.GenerateInsight.toObject(message.asyncGenerateInsightJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncGenerateInsightJob";
                        }
                        if (message.asyncSendReportScheduleJob != null && message.hasOwnProperty("asyncSendReportScheduleJob")) {
                            object.asyncSendReportScheduleJob = $root.palexy.streaming.v1.AsyncJob.SendReportSchedule.toObject(message.asyncSendReportScheduleJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncSendReportScheduleJob";
                        }
                        if (message.asyncExternalTrafficImportJob != null && message.hasOwnProperty("asyncExternalTrafficImportJob")) {
                            object.asyncExternalTrafficImportJob = $root.palexy.streaming.v1.AsyncJob.ExternalTrafficImport.toObject(message.asyncExternalTrafficImportJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncExternalTrafficImportJob";
                        }
                        if (message.asyncGenerateTrendReportJob != null && message.hasOwnProperty("asyncGenerateTrendReportJob")) {
                            object.asyncGenerateTrendReportJob = $root.palexy.streaming.v1.AsyncJob.GenerateTrendReport.toObject(message.asyncGenerateTrendReportJob, options);
                            if (options.oneofs)
                                object.asyncJobObject = "asyncGenerateTrendReportJob";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this AsyncJob to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AsyncJob
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AsyncJob.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Type enum.
                     * @name palexy.streaming.v1.AsyncJob.Type
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} EXPORT=1 EXPORT value
                     * @property {number} POS_MATCHING=2 POS_MATCHING value
                     * @property {number} POS_IMPORT=3 POS_IMPORT value
                     * @property {number} AGGREGATE=4 AGGREGATE value
                     * @property {number} AGGREGATE_LEVEL_2=5 AGGREGATE_LEVEL_2 value
                     * @property {number} CALCULATE_CONSISTENCY=6 CALCULATE_CONSISTENCY value
                     * @property {number} SCHEDULE_TASK=7 SCHEDULE_TASK value
                     * @property {number} STAFF_DATA_IMPORT=8 STAFF_DATA_IMPORT value
                     * @property {number} EXPORT_REPORT=9 EXPORT_REPORT value
                     * @property {number} UPDATE_WEATHER_DATA=10 UPDATE_WEATHER_DATA value
                     * @property {number} GENERATE_INSIGHT=11 GENERATE_INSIGHT value
                     * @property {number} SEND_REPORT_SCHEDULE=12 SEND_REPORT_SCHEDULE value
                     * @property {number} EXTERNAL_TRAFFIC_IMPORT=13 EXTERNAL_TRAFFIC_IMPORT value
                     * @property {number} GENERATE_TREND_REPORT=14 GENERATE_TREND_REPORT value
                     */
                    AsyncJob.Type = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "EXPORT"] = 1;
                        values[valuesById[2] = "POS_MATCHING"] = 2;
                        values[valuesById[3] = "POS_IMPORT"] = 3;
                        values[valuesById[4] = "AGGREGATE"] = 4;
                        values[valuesById[5] = "AGGREGATE_LEVEL_2"] = 5;
                        values[valuesById[6] = "CALCULATE_CONSISTENCY"] = 6;
                        values[valuesById[7] = "SCHEDULE_TASK"] = 7;
                        values[valuesById[8] = "STAFF_DATA_IMPORT"] = 8;
                        values[valuesById[9] = "EXPORT_REPORT"] = 9;
                        values[valuesById[10] = "UPDATE_WEATHER_DATA"] = 10;
                        values[valuesById[11] = "GENERATE_INSIGHT"] = 11;
                        values[valuesById[12] = "SEND_REPORT_SCHEDULE"] = 12;
                        values[valuesById[13] = "EXTERNAL_TRAFFIC_IMPORT"] = 13;
                        values[valuesById[14] = "GENERATE_TREND_REPORT"] = 14;
                        return values;
                    })();
    
                    /**
                     * Status enum.
                     * @name palexy.streaming.v1.AsyncJob.Status
                     * @enum {number}
                     * @property {number} UNPROCESSED=0 UNPROCESSED value
                     * @property {number} PROCESSING=1 PROCESSING value
                     * @property {number} WAIT_FOR_RETRYING=2 WAIT_FOR_RETRYING value
                     * @property {number} DONE=3 DONE value
                     * @property {number} CANCELLED=4 CANCELLED value
                     * @property {number} SKIPPED=5 SKIPPED value
                     * @property {number} RECURRING=6 RECURRING value
                     */
                    AsyncJob.Status = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNPROCESSED"] = 0;
                        values[valuesById[1] = "PROCESSING"] = 1;
                        values[valuesById[2] = "WAIT_FOR_RETRYING"] = 2;
                        values[valuesById[3] = "DONE"] = 3;
                        values[valuesById[4] = "CANCELLED"] = 4;
                        values[valuesById[5] = "SKIPPED"] = 5;
                        values[valuesById[6] = "RECURRING"] = 6;
                        return values;
                    })();
    
                    AsyncJob.Export = (function() {
    
                        /**
                         * Properties of an Export.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IExport
                         * @property {string|null} [exportDate] Export exportDate
                         */
    
                        /**
                         * Constructs a new Export.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents an Export.
                         * @implements IExport
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IExport=} [properties] Properties to set
                         */
                        function Export(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * Export exportDate.
                         * @member {string} exportDate
                         * @memberof palexy.streaming.v1.AsyncJob.Export
                         * @instance
                         */
                        Export.prototype.exportDate = "";
    
                        /**
                         * Creates a new Export instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.Export
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IExport=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.Export} Export instance
                         */
                        Export.create = function create(properties) {
                            return new Export(properties);
                        };
    
                        /**
                         * Encodes the specified Export message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.Export.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.Export
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IExport} message Export message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Export.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.exportDate != null && Object.hasOwnProperty.call(message, "exportDate"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.exportDate);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified Export message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.Export.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.Export
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IExport} message Export message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Export.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes an Export message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.Export
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.Export} Export
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Export.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.Export();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.exportDate = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes an Export message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.Export
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.Export} Export
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Export.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies an Export message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.Export
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Export.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.exportDate != null && message.hasOwnProperty("exportDate"))
                                if (!$util.isString(message.exportDate))
                                    return "exportDate: string expected";
                            return null;
                        };
    
                        /**
                         * Creates an Export message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.Export
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.Export} Export
                         */
                        Export.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.Export)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.Export();
                            if (object.exportDate != null)
                                message.exportDate = String(object.exportDate);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from an Export message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.Export
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.Export} message Export
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Export.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.exportDate = "";
                            if (message.exportDate != null && message.hasOwnProperty("exportDate"))
                                object.exportDate = message.exportDate;
                            return object;
                        };
    
                        /**
                         * Converts this Export to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.Export
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Export.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return Export;
                    })();
    
                    AsyncJob.PosMatching = (function() {
    
                        /**
                         * Properties of a PosMatching.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IPosMatching
                         * @property {number|null} [storeId] PosMatching storeId
                         * @property {string|null} [dateId] PosMatching dateId
                         */
    
                        /**
                         * Constructs a new PosMatching.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents a PosMatching.
                         * @implements IPosMatching
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IPosMatching=} [properties] Properties to set
                         */
                        function PosMatching(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * PosMatching storeId.
                         * @member {number} storeId
                         * @memberof palexy.streaming.v1.AsyncJob.PosMatching
                         * @instance
                         */
                        PosMatching.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * PosMatching dateId.
                         * @member {string} dateId
                         * @memberof palexy.streaming.v1.AsyncJob.PosMatching
                         * @instance
                         */
                        PosMatching.prototype.dateId = "";
    
                        /**
                         * Creates a new PosMatching instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.PosMatching
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IPosMatching=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.PosMatching} PosMatching instance
                         */
                        PosMatching.create = function create(properties) {
                            return new PosMatching(properties);
                        };
    
                        /**
                         * Encodes the specified PosMatching message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.PosMatching.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.PosMatching
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IPosMatching} message PosMatching message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PosMatching.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                            if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified PosMatching message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.PosMatching.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.PosMatching
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IPosMatching} message PosMatching message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PosMatching.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a PosMatching message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.PosMatching
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.PosMatching} PosMatching
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PosMatching.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.PosMatching();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.storeId = reader.int64();
                                    break;
                                case 2:
                                    message.dateId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a PosMatching message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.PosMatching
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.PosMatching} PosMatching
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PosMatching.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a PosMatching message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.PosMatching
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PosMatching.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                    return "storeId: integer|Long expected";
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                if (!$util.isString(message.dateId))
                                    return "dateId: string expected";
                            return null;
                        };
    
                        /**
                         * Creates a PosMatching message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.PosMatching
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.PosMatching} PosMatching
                         */
                        PosMatching.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.PosMatching)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.PosMatching();
                            if (object.storeId != null)
                                if ($util.Long)
                                    (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                                else if (typeof object.storeId === "string")
                                    message.storeId = parseInt(object.storeId, 10);
                                else if (typeof object.storeId === "number")
                                    message.storeId = object.storeId;
                                else if (typeof object.storeId === "object")
                                    message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                            if (object.dateId != null)
                                message.dateId = String(object.dateId);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a PosMatching message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.PosMatching
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.PosMatching} message PosMatching
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PosMatching.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.storeId = options.longs === String ? "0" : 0;
                                object.dateId = "";
                            }
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (typeof message.storeId === "number")
                                    object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                                else
                                    object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                object.dateId = message.dateId;
                            return object;
                        };
    
                        /**
                         * Converts this PosMatching to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.PosMatching
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PosMatching.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return PosMatching;
                    })();
    
                    AsyncJob.PosImport = (function() {
    
                        /**
                         * Properties of a PosImport.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IPosImport
                         * @property {google.protobuf.IUInt64Value|null} [storeId] PosImport storeId
                         * @property {string|null} [dateId] PosImport dateId
                         */
    
                        /**
                         * Constructs a new PosImport.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents a PosImport.
                         * @implements IPosImport
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IPosImport=} [properties] Properties to set
                         */
                        function PosImport(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * PosImport storeId.
                         * @member {google.protobuf.IUInt64Value|null|undefined} storeId
                         * @memberof palexy.streaming.v1.AsyncJob.PosImport
                         * @instance
                         */
                        PosImport.prototype.storeId = null;
    
                        /**
                         * PosImport dateId.
                         * @member {string} dateId
                         * @memberof palexy.streaming.v1.AsyncJob.PosImport
                         * @instance
                         */
                        PosImport.prototype.dateId = "";
    
                        /**
                         * Creates a new PosImport instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.PosImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IPosImport=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.PosImport} PosImport instance
                         */
                        PosImport.create = function create(properties) {
                            return new PosImport(properties);
                        };
    
                        /**
                         * Encodes the specified PosImport message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.PosImport.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.PosImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IPosImport} message PosImport message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PosImport.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                                $root.google.protobuf.UInt64Value.encode(message.storeId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified PosImport message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.PosImport.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.PosImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IPosImport} message PosImport message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PosImport.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a PosImport message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.PosImport
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.PosImport} PosImport
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PosImport.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.PosImport();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.storeId = $root.google.protobuf.UInt64Value.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.dateId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a PosImport message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.PosImport
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.PosImport} PosImport
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PosImport.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a PosImport message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.PosImport
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PosImport.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeId != null && message.hasOwnProperty("storeId")) {
                                var error = $root.google.protobuf.UInt64Value.verify(message.storeId);
                                if (error)
                                    return "storeId." + error;
                            }
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                if (!$util.isString(message.dateId))
                                    return "dateId: string expected";
                            return null;
                        };
    
                        /**
                         * Creates a PosImport message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.PosImport
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.PosImport} PosImport
                         */
                        PosImport.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.PosImport)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.PosImport();
                            if (object.storeId != null) {
                                if (typeof object.storeId !== "object")
                                    throw TypeError(".palexy.streaming.v1.AsyncJob.PosImport.storeId: object expected");
                                message.storeId = $root.google.protobuf.UInt64Value.fromObject(object.storeId);
                            }
                            if (object.dateId != null)
                                message.dateId = String(object.dateId);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a PosImport message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.PosImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.PosImport} message PosImport
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PosImport.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.storeId = null;
                                object.dateId = "";
                            }
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                object.storeId = $root.google.protobuf.UInt64Value.toObject(message.storeId, options);
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                object.dateId = message.dateId;
                            return object;
                        };
    
                        /**
                         * Converts this PosImport to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.PosImport
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PosImport.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return PosImport;
                    })();
    
                    AsyncJob.Aggregate = (function() {
    
                        /**
                         * Properties of an Aggregate.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IAggregate
                         * @property {number|null} [storeId] Aggregate storeId
                         * @property {string|null} [dateId] Aggregate dateId
                         */
    
                        /**
                         * Constructs a new Aggregate.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents an Aggregate.
                         * @implements IAggregate
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IAggregate=} [properties] Properties to set
                         */
                        function Aggregate(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * Aggregate storeId.
                         * @member {number} storeId
                         * @memberof palexy.streaming.v1.AsyncJob.Aggregate
                         * @instance
                         */
                        Aggregate.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * Aggregate dateId.
                         * @member {string} dateId
                         * @memberof palexy.streaming.v1.AsyncJob.Aggregate
                         * @instance
                         */
                        Aggregate.prototype.dateId = "";
    
                        /**
                         * Creates a new Aggregate instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.Aggregate
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IAggregate=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.Aggregate} Aggregate instance
                         */
                        Aggregate.create = function create(properties) {
                            return new Aggregate(properties);
                        };
    
                        /**
                         * Encodes the specified Aggregate message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.Aggregate.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.Aggregate
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IAggregate} message Aggregate message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Aggregate.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                            if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified Aggregate message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.Aggregate.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.Aggregate
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IAggregate} message Aggregate message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Aggregate.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes an Aggregate message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.Aggregate
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.Aggregate} Aggregate
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Aggregate.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.Aggregate();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.storeId = reader.int64();
                                    break;
                                case 2:
                                    message.dateId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes an Aggregate message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.Aggregate
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.Aggregate} Aggregate
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Aggregate.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies an Aggregate message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.Aggregate
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Aggregate.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                    return "storeId: integer|Long expected";
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                if (!$util.isString(message.dateId))
                                    return "dateId: string expected";
                            return null;
                        };
    
                        /**
                         * Creates an Aggregate message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.Aggregate
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.Aggregate} Aggregate
                         */
                        Aggregate.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.Aggregate)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.Aggregate();
                            if (object.storeId != null)
                                if ($util.Long)
                                    (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                                else if (typeof object.storeId === "string")
                                    message.storeId = parseInt(object.storeId, 10);
                                else if (typeof object.storeId === "number")
                                    message.storeId = object.storeId;
                                else if (typeof object.storeId === "object")
                                    message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                            if (object.dateId != null)
                                message.dateId = String(object.dateId);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from an Aggregate message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.Aggregate
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.Aggregate} message Aggregate
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Aggregate.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.storeId = options.longs === String ? "0" : 0;
                                object.dateId = "";
                            }
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (typeof message.storeId === "number")
                                    object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                                else
                                    object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                object.dateId = message.dateId;
                            return object;
                        };
    
                        /**
                         * Converts this Aggregate to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.Aggregate
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Aggregate.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return Aggregate;
                    })();
    
                    AsyncJob.AggregateLevel2 = (function() {
    
                        /**
                         * Properties of an AggregateLevel2.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IAggregateLevel2
                         * @property {number|null} [storeId] AggregateLevel2 storeId
                         * @property {string|null} [dateId] AggregateLevel2 dateId
                         */
    
                        /**
                         * Constructs a new AggregateLevel2.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents an AggregateLevel2.
                         * @implements IAggregateLevel2
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IAggregateLevel2=} [properties] Properties to set
                         */
                        function AggregateLevel2(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * AggregateLevel2 storeId.
                         * @member {number} storeId
                         * @memberof palexy.streaming.v1.AsyncJob.AggregateLevel2
                         * @instance
                         */
                        AggregateLevel2.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * AggregateLevel2 dateId.
                         * @member {string} dateId
                         * @memberof palexy.streaming.v1.AsyncJob.AggregateLevel2
                         * @instance
                         */
                        AggregateLevel2.prototype.dateId = "";
    
                        /**
                         * Creates a new AggregateLevel2 instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.AggregateLevel2
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IAggregateLevel2=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.AggregateLevel2} AggregateLevel2 instance
                         */
                        AggregateLevel2.create = function create(properties) {
                            return new AggregateLevel2(properties);
                        };
    
                        /**
                         * Encodes the specified AggregateLevel2 message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.AggregateLevel2.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.AggregateLevel2
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IAggregateLevel2} message AggregateLevel2 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AggregateLevel2.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                            if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified AggregateLevel2 message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.AggregateLevel2.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.AggregateLevel2
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IAggregateLevel2} message AggregateLevel2 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AggregateLevel2.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes an AggregateLevel2 message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.AggregateLevel2
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.AggregateLevel2} AggregateLevel2
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AggregateLevel2.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.AggregateLevel2();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.storeId = reader.int64();
                                    break;
                                case 2:
                                    message.dateId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes an AggregateLevel2 message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.AggregateLevel2
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.AggregateLevel2} AggregateLevel2
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AggregateLevel2.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies an AggregateLevel2 message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.AggregateLevel2
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        AggregateLevel2.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                    return "storeId: integer|Long expected";
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                if (!$util.isString(message.dateId))
                                    return "dateId: string expected";
                            return null;
                        };
    
                        /**
                         * Creates an AggregateLevel2 message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.AggregateLevel2
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.AggregateLevel2} AggregateLevel2
                         */
                        AggregateLevel2.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.AggregateLevel2)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.AggregateLevel2();
                            if (object.storeId != null)
                                if ($util.Long)
                                    (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                                else if (typeof object.storeId === "string")
                                    message.storeId = parseInt(object.storeId, 10);
                                else if (typeof object.storeId === "number")
                                    message.storeId = object.storeId;
                                else if (typeof object.storeId === "object")
                                    message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                            if (object.dateId != null)
                                message.dateId = String(object.dateId);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from an AggregateLevel2 message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.AggregateLevel2
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.AggregateLevel2} message AggregateLevel2
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        AggregateLevel2.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.storeId = options.longs === String ? "0" : 0;
                                object.dateId = "";
                            }
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (typeof message.storeId === "number")
                                    object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                                else
                                    object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                object.dateId = message.dateId;
                            return object;
                        };
    
                        /**
                         * Converts this AggregateLevel2 to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.AggregateLevel2
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        AggregateLevel2.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return AggregateLevel2;
                    })();
    
                    AsyncJob.CalculateConsistency = (function() {
    
                        /**
                         * Properties of a CalculateConsistency.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface ICalculateConsistency
                         * @property {number|null} [storeId] CalculateConsistency storeId
                         * @property {string|null} [dateId] CalculateConsistency dateId
                         */
    
                        /**
                         * Constructs a new CalculateConsistency.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents a CalculateConsistency.
                         * @implements ICalculateConsistency
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.ICalculateConsistency=} [properties] Properties to set
                         */
                        function CalculateConsistency(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * CalculateConsistency storeId.
                         * @member {number} storeId
                         * @memberof palexy.streaming.v1.AsyncJob.CalculateConsistency
                         * @instance
                         */
                        CalculateConsistency.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * CalculateConsistency dateId.
                         * @member {string} dateId
                         * @memberof palexy.streaming.v1.AsyncJob.CalculateConsistency
                         * @instance
                         */
                        CalculateConsistency.prototype.dateId = "";
    
                        /**
                         * Creates a new CalculateConsistency instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.CalculateConsistency
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.ICalculateConsistency=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.CalculateConsistency} CalculateConsistency instance
                         */
                        CalculateConsistency.create = function create(properties) {
                            return new CalculateConsistency(properties);
                        };
    
                        /**
                         * Encodes the specified CalculateConsistency message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.CalculateConsistency.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.CalculateConsistency
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.ICalculateConsistency} message CalculateConsistency message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CalculateConsistency.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                            if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified CalculateConsistency message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.CalculateConsistency.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.CalculateConsistency
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.ICalculateConsistency} message CalculateConsistency message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CalculateConsistency.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a CalculateConsistency message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.CalculateConsistency
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.CalculateConsistency} CalculateConsistency
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CalculateConsistency.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.CalculateConsistency();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.storeId = reader.int64();
                                    break;
                                case 2:
                                    message.dateId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a CalculateConsistency message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.CalculateConsistency
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.CalculateConsistency} CalculateConsistency
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CalculateConsistency.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a CalculateConsistency message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.CalculateConsistency
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CalculateConsistency.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                    return "storeId: integer|Long expected";
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                if (!$util.isString(message.dateId))
                                    return "dateId: string expected";
                            return null;
                        };
    
                        /**
                         * Creates a CalculateConsistency message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.CalculateConsistency
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.CalculateConsistency} CalculateConsistency
                         */
                        CalculateConsistency.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.CalculateConsistency)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.CalculateConsistency();
                            if (object.storeId != null)
                                if ($util.Long)
                                    (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                                else if (typeof object.storeId === "string")
                                    message.storeId = parseInt(object.storeId, 10);
                                else if (typeof object.storeId === "number")
                                    message.storeId = object.storeId;
                                else if (typeof object.storeId === "object")
                                    message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                            if (object.dateId != null)
                                message.dateId = String(object.dateId);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a CalculateConsistency message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.CalculateConsistency
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.CalculateConsistency} message CalculateConsistency
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CalculateConsistency.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.storeId = options.longs === String ? "0" : 0;
                                object.dateId = "";
                            }
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (typeof message.storeId === "number")
                                    object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                                else
                                    object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                object.dateId = message.dateId;
                            return object;
                        };
    
                        /**
                         * Converts this CalculateConsistency to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.CalculateConsistency
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CalculateConsistency.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return CalculateConsistency;
                    })();
    
                    AsyncJob.ScheduleTask = (function() {
    
                        /**
                         * Properties of a ScheduleTask.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IScheduleTask
                         * @property {palexy.streaming.v1.AsyncJob.Type|null} [scheduleTaskType] ScheduleTask scheduleTaskType
                         * @property {string|null} [cronExpression] ScheduleTask cronExpression
                         * @property {string|null} [timezone] ScheduleTask timezone
                         * @property {number|null} [lastRecurringTimeMs] ScheduleTask lastRecurringTimeMs
                         * @property {number|null} [nextRecurringTimeMs] ScheduleTask nextRecurringTimeMs
                         * @property {Object.<string,string>|null} [additionalProperties] ScheduleTask additionalProperties
                         */
    
                        /**
                         * Constructs a new ScheduleTask.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents a ScheduleTask.
                         * @implements IScheduleTask
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IScheduleTask=} [properties] Properties to set
                         */
                        function ScheduleTask(properties) {
                            this.additionalProperties = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * ScheduleTask scheduleTaskType.
                         * @member {palexy.streaming.v1.AsyncJob.Type} scheduleTaskType
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @instance
                         */
                        ScheduleTask.prototype.scheduleTaskType = 0;
    
                        /**
                         * ScheduleTask cronExpression.
                         * @member {string} cronExpression
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @instance
                         */
                        ScheduleTask.prototype.cronExpression = "";
    
                        /**
                         * ScheduleTask timezone.
                         * @member {string} timezone
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @instance
                         */
                        ScheduleTask.prototype.timezone = "";
    
                        /**
                         * ScheduleTask lastRecurringTimeMs.
                         * @member {number} lastRecurringTimeMs
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @instance
                         */
                        ScheduleTask.prototype.lastRecurringTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * ScheduleTask nextRecurringTimeMs.
                         * @member {number} nextRecurringTimeMs
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @instance
                         */
                        ScheduleTask.prototype.nextRecurringTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * ScheduleTask additionalProperties.
                         * @member {Object.<string,string>} additionalProperties
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @instance
                         */
                        ScheduleTask.prototype.additionalProperties = $util.emptyObject;
    
                        /**
                         * Creates a new ScheduleTask instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IScheduleTask=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.ScheduleTask} ScheduleTask instance
                         */
                        ScheduleTask.create = function create(properties) {
                            return new ScheduleTask(properties);
                        };
    
                        /**
                         * Encodes the specified ScheduleTask message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.ScheduleTask.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IScheduleTask} message ScheduleTask message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ScheduleTask.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.scheduleTaskType != null && Object.hasOwnProperty.call(message, "scheduleTaskType"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.scheduleTaskType);
                            if (message.cronExpression != null && Object.hasOwnProperty.call(message, "cronExpression"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.cronExpression);
                            if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.timezone);
                            if (message.lastRecurringTimeMs != null && Object.hasOwnProperty.call(message, "lastRecurringTimeMs"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.lastRecurringTimeMs);
                            if (message.nextRecurringTimeMs != null && Object.hasOwnProperty.call(message, "nextRecurringTimeMs"))
                                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.nextRecurringTimeMs);
                            if (message.additionalProperties != null && Object.hasOwnProperty.call(message, "additionalProperties"))
                                for (var keys = Object.keys(message.additionalProperties), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.additionalProperties[keys[i]]).ldelim();
                            return writer;
                        };
    
                        /**
                         * Encodes the specified ScheduleTask message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.ScheduleTask.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IScheduleTask} message ScheduleTask message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ScheduleTask.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a ScheduleTask message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.ScheduleTask} ScheduleTask
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ScheduleTask.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.ScheduleTask(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.scheduleTaskType = reader.int32();
                                    break;
                                case 2:
                                    message.cronExpression = reader.string();
                                    break;
                                case 3:
                                    message.timezone = reader.string();
                                    break;
                                case 4:
                                    message.lastRecurringTimeMs = reader.int64();
                                    break;
                                case 5:
                                    message.nextRecurringTimeMs = reader.int64();
                                    break;
                                case 6:
                                    if (message.additionalProperties === $util.emptyObject)
                                        message.additionalProperties = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.additionalProperties[key] = value;
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a ScheduleTask message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.ScheduleTask} ScheduleTask
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ScheduleTask.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a ScheduleTask message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ScheduleTask.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.scheduleTaskType != null && message.hasOwnProperty("scheduleTaskType"))
                                switch (message.scheduleTaskType) {
                                default:
                                    return "scheduleTaskType: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                case 13:
                                case 14:
                                    break;
                                }
                            if (message.cronExpression != null && message.hasOwnProperty("cronExpression"))
                                if (!$util.isString(message.cronExpression))
                                    return "cronExpression: string expected";
                            if (message.timezone != null && message.hasOwnProperty("timezone"))
                                if (!$util.isString(message.timezone))
                                    return "timezone: string expected";
                            if (message.lastRecurringTimeMs != null && message.hasOwnProperty("lastRecurringTimeMs"))
                                if (!$util.isInteger(message.lastRecurringTimeMs) && !(message.lastRecurringTimeMs && $util.isInteger(message.lastRecurringTimeMs.low) && $util.isInteger(message.lastRecurringTimeMs.high)))
                                    return "lastRecurringTimeMs: integer|Long expected";
                            if (message.nextRecurringTimeMs != null && message.hasOwnProperty("nextRecurringTimeMs"))
                                if (!$util.isInteger(message.nextRecurringTimeMs) && !(message.nextRecurringTimeMs && $util.isInteger(message.nextRecurringTimeMs.low) && $util.isInteger(message.nextRecurringTimeMs.high)))
                                    return "nextRecurringTimeMs: integer|Long expected";
                            if (message.additionalProperties != null && message.hasOwnProperty("additionalProperties")) {
                                if (!$util.isObject(message.additionalProperties))
                                    return "additionalProperties: object expected";
                                var key = Object.keys(message.additionalProperties);
                                for (var i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.additionalProperties[key[i]]))
                                        return "additionalProperties: string{k:string} expected";
                            }
                            return null;
                        };
    
                        /**
                         * Creates a ScheduleTask message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.ScheduleTask} ScheduleTask
                         */
                        ScheduleTask.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.ScheduleTask)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.ScheduleTask();
                            switch (object.scheduleTaskType) {
                            case "UNKNOWN":
                            case 0:
                                message.scheduleTaskType = 0;
                                break;
                            case "EXPORT":
                            case 1:
                                message.scheduleTaskType = 1;
                                break;
                            case "POS_MATCHING":
                            case 2:
                                message.scheduleTaskType = 2;
                                break;
                            case "POS_IMPORT":
                            case 3:
                                message.scheduleTaskType = 3;
                                break;
                            case "AGGREGATE":
                            case 4:
                                message.scheduleTaskType = 4;
                                break;
                            case "AGGREGATE_LEVEL_2":
                            case 5:
                                message.scheduleTaskType = 5;
                                break;
                            case "CALCULATE_CONSISTENCY":
                            case 6:
                                message.scheduleTaskType = 6;
                                break;
                            case "SCHEDULE_TASK":
                            case 7:
                                message.scheduleTaskType = 7;
                                break;
                            case "STAFF_DATA_IMPORT":
                            case 8:
                                message.scheduleTaskType = 8;
                                break;
                            case "EXPORT_REPORT":
                            case 9:
                                message.scheduleTaskType = 9;
                                break;
                            case "UPDATE_WEATHER_DATA":
                            case 10:
                                message.scheduleTaskType = 10;
                                break;
                            case "GENERATE_INSIGHT":
                            case 11:
                                message.scheduleTaskType = 11;
                                break;
                            case "SEND_REPORT_SCHEDULE":
                            case 12:
                                message.scheduleTaskType = 12;
                                break;
                            case "EXTERNAL_TRAFFIC_IMPORT":
                            case 13:
                                message.scheduleTaskType = 13;
                                break;
                            case "GENERATE_TREND_REPORT":
                            case 14:
                                message.scheduleTaskType = 14;
                                break;
                            }
                            if (object.cronExpression != null)
                                message.cronExpression = String(object.cronExpression);
                            if (object.timezone != null)
                                message.timezone = String(object.timezone);
                            if (object.lastRecurringTimeMs != null)
                                if ($util.Long)
                                    (message.lastRecurringTimeMs = $util.Long.fromValue(object.lastRecurringTimeMs)).unsigned = false;
                                else if (typeof object.lastRecurringTimeMs === "string")
                                    message.lastRecurringTimeMs = parseInt(object.lastRecurringTimeMs, 10);
                                else if (typeof object.lastRecurringTimeMs === "number")
                                    message.lastRecurringTimeMs = object.lastRecurringTimeMs;
                                else if (typeof object.lastRecurringTimeMs === "object")
                                    message.lastRecurringTimeMs = new $util.LongBits(object.lastRecurringTimeMs.low >>> 0, object.lastRecurringTimeMs.high >>> 0).toNumber();
                            if (object.nextRecurringTimeMs != null)
                                if ($util.Long)
                                    (message.nextRecurringTimeMs = $util.Long.fromValue(object.nextRecurringTimeMs)).unsigned = false;
                                else if (typeof object.nextRecurringTimeMs === "string")
                                    message.nextRecurringTimeMs = parseInt(object.nextRecurringTimeMs, 10);
                                else if (typeof object.nextRecurringTimeMs === "number")
                                    message.nextRecurringTimeMs = object.nextRecurringTimeMs;
                                else if (typeof object.nextRecurringTimeMs === "object")
                                    message.nextRecurringTimeMs = new $util.LongBits(object.nextRecurringTimeMs.low >>> 0, object.nextRecurringTimeMs.high >>> 0).toNumber();
                            if (object.additionalProperties) {
                                if (typeof object.additionalProperties !== "object")
                                    throw TypeError(".palexy.streaming.v1.AsyncJob.ScheduleTask.additionalProperties: object expected");
                                message.additionalProperties = {};
                                for (var keys = Object.keys(object.additionalProperties), i = 0; i < keys.length; ++i)
                                    message.additionalProperties[keys[i]] = String(object.additionalProperties[keys[i]]);
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a ScheduleTask message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.ScheduleTask} message ScheduleTask
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ScheduleTask.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults)
                                object.additionalProperties = {};
                            if (options.defaults) {
                                object.scheduleTaskType = options.enums === String ? "UNKNOWN" : 0;
                                object.cronExpression = "";
                                object.timezone = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.lastRecurringTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.lastRecurringTimeMs = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.nextRecurringTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.nextRecurringTimeMs = options.longs === String ? "0" : 0;
                            }
                            if (message.scheduleTaskType != null && message.hasOwnProperty("scheduleTaskType"))
                                object.scheduleTaskType = options.enums === String ? $root.palexy.streaming.v1.AsyncJob.Type[message.scheduleTaskType] : message.scheduleTaskType;
                            if (message.cronExpression != null && message.hasOwnProperty("cronExpression"))
                                object.cronExpression = message.cronExpression;
                            if (message.timezone != null && message.hasOwnProperty("timezone"))
                                object.timezone = message.timezone;
                            if (message.lastRecurringTimeMs != null && message.hasOwnProperty("lastRecurringTimeMs"))
                                if (typeof message.lastRecurringTimeMs === "number")
                                    object.lastRecurringTimeMs = options.longs === String ? String(message.lastRecurringTimeMs) : message.lastRecurringTimeMs;
                                else
                                    object.lastRecurringTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastRecurringTimeMs) : options.longs === Number ? new $util.LongBits(message.lastRecurringTimeMs.low >>> 0, message.lastRecurringTimeMs.high >>> 0).toNumber() : message.lastRecurringTimeMs;
                            if (message.nextRecurringTimeMs != null && message.hasOwnProperty("nextRecurringTimeMs"))
                                if (typeof message.nextRecurringTimeMs === "number")
                                    object.nextRecurringTimeMs = options.longs === String ? String(message.nextRecurringTimeMs) : message.nextRecurringTimeMs;
                                else
                                    object.nextRecurringTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.nextRecurringTimeMs) : options.longs === Number ? new $util.LongBits(message.nextRecurringTimeMs.low >>> 0, message.nextRecurringTimeMs.high >>> 0).toNumber() : message.nextRecurringTimeMs;
                            var keys2;
                            if (message.additionalProperties && (keys2 = Object.keys(message.additionalProperties)).length) {
                                object.additionalProperties = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.additionalProperties[keys2[j]] = message.additionalProperties[keys2[j]];
                            }
                            return object;
                        };
    
                        /**
                         * Converts this ScheduleTask to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.ScheduleTask
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ScheduleTask.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return ScheduleTask;
                    })();
    
                    AsyncJob.StaffImport = (function() {
    
                        /**
                         * Properties of a StaffImport.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IStaffImport
                         * @property {string|null} [dateId] StaffImport dateId
                         */
    
                        /**
                         * Constructs a new StaffImport.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents a StaffImport.
                         * @implements IStaffImport
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IStaffImport=} [properties] Properties to set
                         */
                        function StaffImport(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * StaffImport dateId.
                         * @member {string} dateId
                         * @memberof palexy.streaming.v1.AsyncJob.StaffImport
                         * @instance
                         */
                        StaffImport.prototype.dateId = "";
    
                        /**
                         * Creates a new StaffImport instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.StaffImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IStaffImport=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.StaffImport} StaffImport instance
                         */
                        StaffImport.create = function create(properties) {
                            return new StaffImport(properties);
                        };
    
                        /**
                         * Encodes the specified StaffImport message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.StaffImport.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.StaffImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IStaffImport} message StaffImport message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StaffImport.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dateId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified StaffImport message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.StaffImport.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.StaffImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IStaffImport} message StaffImport message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StaffImport.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a StaffImport message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.StaffImport
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.StaffImport} StaffImport
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StaffImport.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.StaffImport();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.dateId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a StaffImport message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.StaffImport
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.StaffImport} StaffImport
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StaffImport.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a StaffImport message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.StaffImport
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StaffImport.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                if (!$util.isString(message.dateId))
                                    return "dateId: string expected";
                            return null;
                        };
    
                        /**
                         * Creates a StaffImport message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.StaffImport
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.StaffImport} StaffImport
                         */
                        StaffImport.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.StaffImport)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.StaffImport();
                            if (object.dateId != null)
                                message.dateId = String(object.dateId);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a StaffImport message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.StaffImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.StaffImport} message StaffImport
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StaffImport.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.dateId = "";
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                object.dateId = message.dateId;
                            return object;
                        };
    
                        /**
                         * Converts this StaffImport to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.StaffImport
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StaffImport.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return StaffImport;
                    })();
    
                    AsyncJob.ExportReport = (function() {
    
                        /**
                         * Properties of an ExportReport.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IExportReport
                         * @property {string|null} [dateId] ExportReport dateId
                         */
    
                        /**
                         * Constructs a new ExportReport.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents an ExportReport.
                         * @implements IExportReport
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IExportReport=} [properties] Properties to set
                         */
                        function ExportReport(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * ExportReport dateId.
                         * @member {string} dateId
                         * @memberof palexy.streaming.v1.AsyncJob.ExportReport
                         * @instance
                         */
                        ExportReport.prototype.dateId = "";
    
                        /**
                         * Creates a new ExportReport instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.ExportReport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IExportReport=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.ExportReport} ExportReport instance
                         */
                        ExportReport.create = function create(properties) {
                            return new ExportReport(properties);
                        };
    
                        /**
                         * Encodes the specified ExportReport message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.ExportReport.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.ExportReport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IExportReport} message ExportReport message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ExportReport.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dateId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified ExportReport message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.ExportReport.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.ExportReport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IExportReport} message ExportReport message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ExportReport.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes an ExportReport message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.ExportReport
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.ExportReport} ExportReport
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ExportReport.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.ExportReport();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.dateId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes an ExportReport message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.ExportReport
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.ExportReport} ExportReport
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ExportReport.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies an ExportReport message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.ExportReport
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ExportReport.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                if (!$util.isString(message.dateId))
                                    return "dateId: string expected";
                            return null;
                        };
    
                        /**
                         * Creates an ExportReport message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.ExportReport
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.ExportReport} ExportReport
                         */
                        ExportReport.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.ExportReport)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.ExportReport();
                            if (object.dateId != null)
                                message.dateId = String(object.dateId);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from an ExportReport message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.ExportReport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.ExportReport} message ExportReport
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ExportReport.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.dateId = "";
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                object.dateId = message.dateId;
                            return object;
                        };
    
                        /**
                         * Converts this ExportReport to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.ExportReport
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ExportReport.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return ExportReport;
                    })();
    
                    AsyncJob.UpdateWeatherData = (function() {
    
                        /**
                         * Properties of an UpdateWeatherData.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IUpdateWeatherData
                         * @property {number|null} [storeId] UpdateWeatherData storeId
                         * @property {string|null} [startDateId] UpdateWeatherData startDateId
                         * @property {string|null} [endDateId] UpdateWeatherData endDateId
                         */
    
                        /**
                         * Constructs a new UpdateWeatherData.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents an UpdateWeatherData.
                         * @implements IUpdateWeatherData
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IUpdateWeatherData=} [properties] Properties to set
                         */
                        function UpdateWeatherData(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * UpdateWeatherData storeId.
                         * @member {number} storeId
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @instance
                         */
                        UpdateWeatherData.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * UpdateWeatherData startDateId.
                         * @member {string} startDateId
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @instance
                         */
                        UpdateWeatherData.prototype.startDateId = "";
    
                        /**
                         * UpdateWeatherData endDateId.
                         * @member {string} endDateId
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @instance
                         */
                        UpdateWeatherData.prototype.endDateId = "";
    
                        /**
                         * Creates a new UpdateWeatherData instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IUpdateWeatherData=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.UpdateWeatherData} UpdateWeatherData instance
                         */
                        UpdateWeatherData.create = function create(properties) {
                            return new UpdateWeatherData(properties);
                        };
    
                        /**
                         * Encodes the specified UpdateWeatherData message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.UpdateWeatherData.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IUpdateWeatherData} message UpdateWeatherData message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        UpdateWeatherData.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                            if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.startDateId);
                            if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.endDateId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified UpdateWeatherData message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.UpdateWeatherData.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IUpdateWeatherData} message UpdateWeatherData message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        UpdateWeatherData.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes an UpdateWeatherData message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.UpdateWeatherData} UpdateWeatherData
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        UpdateWeatherData.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.UpdateWeatherData();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.storeId = reader.int64();
                                    break;
                                case 2:
                                    message.startDateId = reader.string();
                                    break;
                                case 3:
                                    message.endDateId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes an UpdateWeatherData message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.UpdateWeatherData} UpdateWeatherData
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        UpdateWeatherData.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies an UpdateWeatherData message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        UpdateWeatherData.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                    return "storeId: integer|Long expected";
                            if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                                if (!$util.isString(message.startDateId))
                                    return "startDateId: string expected";
                            if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                                if (!$util.isString(message.endDateId))
                                    return "endDateId: string expected";
                            return null;
                        };
    
                        /**
                         * Creates an UpdateWeatherData message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.UpdateWeatherData} UpdateWeatherData
                         */
                        UpdateWeatherData.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.UpdateWeatherData)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.UpdateWeatherData();
                            if (object.storeId != null)
                                if ($util.Long)
                                    (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                                else if (typeof object.storeId === "string")
                                    message.storeId = parseInt(object.storeId, 10);
                                else if (typeof object.storeId === "number")
                                    message.storeId = object.storeId;
                                else if (typeof object.storeId === "object")
                                    message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                            if (object.startDateId != null)
                                message.startDateId = String(object.startDateId);
                            if (object.endDateId != null)
                                message.endDateId = String(object.endDateId);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from an UpdateWeatherData message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.UpdateWeatherData} message UpdateWeatherData
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        UpdateWeatherData.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.storeId = options.longs === String ? "0" : 0;
                                object.startDateId = "";
                                object.endDateId = "";
                            }
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (typeof message.storeId === "number")
                                    object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                                else
                                    object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                                object.startDateId = message.startDateId;
                            if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                                object.endDateId = message.endDateId;
                            return object;
                        };
    
                        /**
                         * Converts this UpdateWeatherData to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.UpdateWeatherData
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        UpdateWeatherData.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return UpdateWeatherData;
                    })();
    
                    AsyncJob.SendReportSchedule = (function() {
    
                        /**
                         * Properties of a SendReportSchedule.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface ISendReportSchedule
                         * @property {string|null} [email] SendReportSchedule email
                         * @property {number|null} [scheduleId] SendReportSchedule scheduleId
                         * @property {palexy.streaming.v1.IDateRange|null} [dateRange] SendReportSchedule dateRange
                         */
    
                        /**
                         * Constructs a new SendReportSchedule.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents a SendReportSchedule.
                         * @implements ISendReportSchedule
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.ISendReportSchedule=} [properties] Properties to set
                         */
                        function SendReportSchedule(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * SendReportSchedule email.
                         * @member {string} email
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @instance
                         */
                        SendReportSchedule.prototype.email = "";
    
                        /**
                         * SendReportSchedule scheduleId.
                         * @member {number} scheduleId
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @instance
                         */
                        SendReportSchedule.prototype.scheduleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * SendReportSchedule dateRange.
                         * @member {palexy.streaming.v1.IDateRange|null|undefined} dateRange
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @instance
                         */
                        SendReportSchedule.prototype.dateRange = null;
    
                        /**
                         * Creates a new SendReportSchedule instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.ISendReportSchedule=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.SendReportSchedule} SendReportSchedule instance
                         */
                        SendReportSchedule.create = function create(properties) {
                            return new SendReportSchedule(properties);
                        };
    
                        /**
                         * Encodes the specified SendReportSchedule message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.SendReportSchedule.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.ISendReportSchedule} message SendReportSchedule message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SendReportSchedule.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                            if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.scheduleId);
                            if (message.dateRange != null && Object.hasOwnProperty.call(message, "dateRange"))
                                $root.palexy.streaming.v1.DateRange.encode(message.dateRange, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };
    
                        /**
                         * Encodes the specified SendReportSchedule message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.SendReportSchedule.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.ISendReportSchedule} message SendReportSchedule message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SendReportSchedule.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a SendReportSchedule message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.SendReportSchedule} SendReportSchedule
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SendReportSchedule.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.SendReportSchedule();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.email = reader.string();
                                    break;
                                case 2:
                                    message.scheduleId = reader.int64();
                                    break;
                                case 3:
                                    message.dateRange = $root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a SendReportSchedule message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.SendReportSchedule} SendReportSchedule
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SendReportSchedule.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a SendReportSchedule message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SendReportSchedule.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.email != null && message.hasOwnProperty("email"))
                                if (!$util.isString(message.email))
                                    return "email: string expected";
                            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                                if (!$util.isInteger(message.scheduleId) && !(message.scheduleId && $util.isInteger(message.scheduleId.low) && $util.isInteger(message.scheduleId.high)))
                                    return "scheduleId: integer|Long expected";
                            if (message.dateRange != null && message.hasOwnProperty("dateRange")) {
                                var error = $root.palexy.streaming.v1.DateRange.verify(message.dateRange);
                                if (error)
                                    return "dateRange." + error;
                            }
                            return null;
                        };
    
                        /**
                         * Creates a SendReportSchedule message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.SendReportSchedule} SendReportSchedule
                         */
                        SendReportSchedule.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.SendReportSchedule)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.SendReportSchedule();
                            if (object.email != null)
                                message.email = String(object.email);
                            if (object.scheduleId != null)
                                if ($util.Long)
                                    (message.scheduleId = $util.Long.fromValue(object.scheduleId)).unsigned = false;
                                else if (typeof object.scheduleId === "string")
                                    message.scheduleId = parseInt(object.scheduleId, 10);
                                else if (typeof object.scheduleId === "number")
                                    message.scheduleId = object.scheduleId;
                                else if (typeof object.scheduleId === "object")
                                    message.scheduleId = new $util.LongBits(object.scheduleId.low >>> 0, object.scheduleId.high >>> 0).toNumber();
                            if (object.dateRange != null) {
                                if (typeof object.dateRange !== "object")
                                    throw TypeError(".palexy.streaming.v1.AsyncJob.SendReportSchedule.dateRange: object expected");
                                message.dateRange = $root.palexy.streaming.v1.DateRange.fromObject(object.dateRange);
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a SendReportSchedule message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.SendReportSchedule} message SendReportSchedule
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SendReportSchedule.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.email = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.scheduleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.scheduleId = options.longs === String ? "0" : 0;
                                object.dateRange = null;
                            }
                            if (message.email != null && message.hasOwnProperty("email"))
                                object.email = message.email;
                            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                                if (typeof message.scheduleId === "number")
                                    object.scheduleId = options.longs === String ? String(message.scheduleId) : message.scheduleId;
                                else
                                    object.scheduleId = options.longs === String ? $util.Long.prototype.toString.call(message.scheduleId) : options.longs === Number ? new $util.LongBits(message.scheduleId.low >>> 0, message.scheduleId.high >>> 0).toNumber() : message.scheduleId;
                            if (message.dateRange != null && message.hasOwnProperty("dateRange"))
                                object.dateRange = $root.palexy.streaming.v1.DateRange.toObject(message.dateRange, options);
                            return object;
                        };
    
                        /**
                         * Converts this SendReportSchedule to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.SendReportSchedule
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SendReportSchedule.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return SendReportSchedule;
                    })();
    
                    AsyncJob.GenerateInsight = (function() {
    
                        /**
                         * Properties of a GenerateInsight.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IGenerateInsight
                         * @property {Array.<number>|null} [storeIds] GenerateInsight storeIds
                         * @property {string|null} [insightType] GenerateInsight insightType
                         * @property {string|null} [startDateId] GenerateInsight startDateId
                         * @property {string|null} [endDateId] GenerateInsight endDateId
                         */
    
                        /**
                         * Constructs a new GenerateInsight.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents a GenerateInsight.
                         * @implements IGenerateInsight
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IGenerateInsight=} [properties] Properties to set
                         */
                        function GenerateInsight(properties) {
                            this.storeIds = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * GenerateInsight storeIds.
                         * @member {Array.<number>} storeIds
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @instance
                         */
                        GenerateInsight.prototype.storeIds = $util.emptyArray;
    
                        /**
                         * GenerateInsight insightType.
                         * @member {string} insightType
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @instance
                         */
                        GenerateInsight.prototype.insightType = "";
    
                        /**
                         * GenerateInsight startDateId.
                         * @member {string} startDateId
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @instance
                         */
                        GenerateInsight.prototype.startDateId = "";
    
                        /**
                         * GenerateInsight endDateId.
                         * @member {string} endDateId
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @instance
                         */
                        GenerateInsight.prototype.endDateId = "";
    
                        /**
                         * Creates a new GenerateInsight instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IGenerateInsight=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.GenerateInsight} GenerateInsight instance
                         */
                        GenerateInsight.create = function create(properties) {
                            return new GenerateInsight(properties);
                        };
    
                        /**
                         * Encodes the specified GenerateInsight message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.GenerateInsight.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IGenerateInsight} message GenerateInsight message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GenerateInsight.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeIds != null && message.storeIds.length) {
                                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                                for (var i = 0; i < message.storeIds.length; ++i)
                                    writer.int64(message.storeIds[i]);
                                writer.ldelim();
                            }
                            if (message.insightType != null && Object.hasOwnProperty.call(message, "insightType"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.insightType);
                            if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.startDateId);
                            if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.endDateId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified GenerateInsight message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.GenerateInsight.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IGenerateInsight} message GenerateInsight message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GenerateInsight.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a GenerateInsight message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.GenerateInsight} GenerateInsight
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GenerateInsight.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.GenerateInsight();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.storeIds && message.storeIds.length))
                                        message.storeIds = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.storeIds.push(reader.int64());
                                    } else
                                        message.storeIds.push(reader.int64());
                                    break;
                                case 2:
                                    message.insightType = reader.string();
                                    break;
                                case 3:
                                    message.startDateId = reader.string();
                                    break;
                                case 4:
                                    message.endDateId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a GenerateInsight message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.GenerateInsight} GenerateInsight
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GenerateInsight.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a GenerateInsight message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GenerateInsight.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                                if (!Array.isArray(message.storeIds))
                                    return "storeIds: array expected";
                                for (var i = 0; i < message.storeIds.length; ++i)
                                    if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                        return "storeIds: integer|Long[] expected";
                            }
                            if (message.insightType != null && message.hasOwnProperty("insightType"))
                                if (!$util.isString(message.insightType))
                                    return "insightType: string expected";
                            if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                                if (!$util.isString(message.startDateId))
                                    return "startDateId: string expected";
                            if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                                if (!$util.isString(message.endDateId))
                                    return "endDateId: string expected";
                            return null;
                        };
    
                        /**
                         * Creates a GenerateInsight message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.GenerateInsight} GenerateInsight
                         */
                        GenerateInsight.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.GenerateInsight)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.GenerateInsight();
                            if (object.storeIds) {
                                if (!Array.isArray(object.storeIds))
                                    throw TypeError(".palexy.streaming.v1.AsyncJob.GenerateInsight.storeIds: array expected");
                                message.storeIds = [];
                                for (var i = 0; i < object.storeIds.length; ++i)
                                    if ($util.Long)
                                        (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                    else if (typeof object.storeIds[i] === "string")
                                        message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                    else if (typeof object.storeIds[i] === "number")
                                        message.storeIds[i] = object.storeIds[i];
                                    else if (typeof object.storeIds[i] === "object")
                                        message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                            }
                            if (object.insightType != null)
                                message.insightType = String(object.insightType);
                            if (object.startDateId != null)
                                message.startDateId = String(object.startDateId);
                            if (object.endDateId != null)
                                message.endDateId = String(object.endDateId);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a GenerateInsight message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.GenerateInsight} message GenerateInsight
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GenerateInsight.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.storeIds = [];
                            if (options.defaults) {
                                object.insightType = "";
                                object.startDateId = "";
                                object.endDateId = "";
                            }
                            if (message.storeIds && message.storeIds.length) {
                                object.storeIds = [];
                                for (var j = 0; j < message.storeIds.length; ++j)
                                    if (typeof message.storeIds[j] === "number")
                                        object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                    else
                                        object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                            }
                            if (message.insightType != null && message.hasOwnProperty("insightType"))
                                object.insightType = message.insightType;
                            if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                                object.startDateId = message.startDateId;
                            if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                                object.endDateId = message.endDateId;
                            return object;
                        };
    
                        /**
                         * Converts this GenerateInsight to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateInsight
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GenerateInsight.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return GenerateInsight;
                    })();
    
                    AsyncJob.ExternalTrafficImport = (function() {
    
                        /**
                         * Properties of an ExternalTrafficImport.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IExternalTrafficImport
                         * @property {Array.<number>|null} [storeIds] ExternalTrafficImport storeIds
                         * @property {string|null} [dateId] ExternalTrafficImport dateId
                         */
    
                        /**
                         * Constructs a new ExternalTrafficImport.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents an ExternalTrafficImport.
                         * @implements IExternalTrafficImport
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IExternalTrafficImport=} [properties] Properties to set
                         */
                        function ExternalTrafficImport(properties) {
                            this.storeIds = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * ExternalTrafficImport storeIds.
                         * @member {Array.<number>} storeIds
                         * @memberof palexy.streaming.v1.AsyncJob.ExternalTrafficImport
                         * @instance
                         */
                        ExternalTrafficImport.prototype.storeIds = $util.emptyArray;
    
                        /**
                         * ExternalTrafficImport dateId.
                         * @member {string} dateId
                         * @memberof palexy.streaming.v1.AsyncJob.ExternalTrafficImport
                         * @instance
                         */
                        ExternalTrafficImport.prototype.dateId = "";
    
                        /**
                         * Creates a new ExternalTrafficImport instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.ExternalTrafficImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IExternalTrafficImport=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.ExternalTrafficImport} ExternalTrafficImport instance
                         */
                        ExternalTrafficImport.create = function create(properties) {
                            return new ExternalTrafficImport(properties);
                        };
    
                        /**
                         * Encodes the specified ExternalTrafficImport message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.ExternalTrafficImport.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.ExternalTrafficImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IExternalTrafficImport} message ExternalTrafficImport message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ExternalTrafficImport.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeIds != null && message.storeIds.length) {
                                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                                for (var i = 0; i < message.storeIds.length; ++i)
                                    writer.int64(message.storeIds[i]);
                                writer.ldelim();
                            }
                            if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified ExternalTrafficImport message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.ExternalTrafficImport.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.ExternalTrafficImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IExternalTrafficImport} message ExternalTrafficImport message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ExternalTrafficImport.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes an ExternalTrafficImport message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.ExternalTrafficImport
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.ExternalTrafficImport} ExternalTrafficImport
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ExternalTrafficImport.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.ExternalTrafficImport();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.storeIds && message.storeIds.length))
                                        message.storeIds = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.storeIds.push(reader.int64());
                                    } else
                                        message.storeIds.push(reader.int64());
                                    break;
                                case 2:
                                    message.dateId = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes an ExternalTrafficImport message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.ExternalTrafficImport
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.ExternalTrafficImport} ExternalTrafficImport
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ExternalTrafficImport.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies an ExternalTrafficImport message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.ExternalTrafficImport
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ExternalTrafficImport.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                                if (!Array.isArray(message.storeIds))
                                    return "storeIds: array expected";
                                for (var i = 0; i < message.storeIds.length; ++i)
                                    if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                        return "storeIds: integer|Long[] expected";
                            }
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                if (!$util.isString(message.dateId))
                                    return "dateId: string expected";
                            return null;
                        };
    
                        /**
                         * Creates an ExternalTrafficImport message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.ExternalTrafficImport
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.ExternalTrafficImport} ExternalTrafficImport
                         */
                        ExternalTrafficImport.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.ExternalTrafficImport)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.ExternalTrafficImport();
                            if (object.storeIds) {
                                if (!Array.isArray(object.storeIds))
                                    throw TypeError(".palexy.streaming.v1.AsyncJob.ExternalTrafficImport.storeIds: array expected");
                                message.storeIds = [];
                                for (var i = 0; i < object.storeIds.length; ++i)
                                    if ($util.Long)
                                        (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                    else if (typeof object.storeIds[i] === "string")
                                        message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                    else if (typeof object.storeIds[i] === "number")
                                        message.storeIds[i] = object.storeIds[i];
                                    else if (typeof object.storeIds[i] === "object")
                                        message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                            }
                            if (object.dateId != null)
                                message.dateId = String(object.dateId);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from an ExternalTrafficImport message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.ExternalTrafficImport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.ExternalTrafficImport} message ExternalTrafficImport
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ExternalTrafficImport.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.storeIds = [];
                            if (options.defaults)
                                object.dateId = "";
                            if (message.storeIds && message.storeIds.length) {
                                object.storeIds = [];
                                for (var j = 0; j < message.storeIds.length; ++j)
                                    if (typeof message.storeIds[j] === "number")
                                        object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                    else
                                        object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                            }
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                object.dateId = message.dateId;
                            return object;
                        };
    
                        /**
                         * Converts this ExternalTrafficImport to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.ExternalTrafficImport
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ExternalTrafficImport.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return ExternalTrafficImport;
                    })();
    
                    AsyncJob.GenerateTrendReport = (function() {
    
                        /**
                         * Properties of a GenerateTrendReport.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @interface IGenerateTrendReport
                         * @property {string|null} [reportType] GenerateTrendReport reportType
                         * @property {number|null} [storeSetId] GenerateTrendReport storeSetId
                         * @property {string|null} [startDateId] GenerateTrendReport startDateId
                         * @property {string|null} [endDateId] GenerateTrendReport endDateId
                         * @property {string|null} [countryCode] GenerateTrendReport countryCode
                         */
    
                        /**
                         * Constructs a new GenerateTrendReport.
                         * @memberof palexy.streaming.v1.AsyncJob
                         * @classdesc Represents a GenerateTrendReport.
                         * @implements IGenerateTrendReport
                         * @constructor
                         * @param {palexy.streaming.v1.AsyncJob.IGenerateTrendReport=} [properties] Properties to set
                         */
                        function GenerateTrendReport(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * GenerateTrendReport reportType.
                         * @member {string} reportType
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @instance
                         */
                        GenerateTrendReport.prototype.reportType = "";
    
                        /**
                         * GenerateTrendReport storeSetId.
                         * @member {number} storeSetId
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @instance
                         */
                        GenerateTrendReport.prototype.storeSetId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * GenerateTrendReport startDateId.
                         * @member {string} startDateId
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @instance
                         */
                        GenerateTrendReport.prototype.startDateId = "";
    
                        /**
                         * GenerateTrendReport endDateId.
                         * @member {string} endDateId
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @instance
                         */
                        GenerateTrendReport.prototype.endDateId = "";
    
                        /**
                         * GenerateTrendReport countryCode.
                         * @member {string} countryCode
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @instance
                         */
                        GenerateTrendReport.prototype.countryCode = "";
    
                        /**
                         * Creates a new GenerateTrendReport instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IGenerateTrendReport=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.AsyncJob.GenerateTrendReport} GenerateTrendReport instance
                         */
                        GenerateTrendReport.create = function create(properties) {
                            return new GenerateTrendReport(properties);
                        };
    
                        /**
                         * Encodes the specified GenerateTrendReport message. Does not implicitly {@link palexy.streaming.v1.AsyncJob.GenerateTrendReport.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IGenerateTrendReport} message GenerateTrendReport message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GenerateTrendReport.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.reportType != null && Object.hasOwnProperty.call(message, "reportType"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.reportType);
                            if (message.storeSetId != null && Object.hasOwnProperty.call(message, "storeSetId"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeSetId);
                            if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.startDateId);
                            if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.endDateId);
                            if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.countryCode);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified GenerateTrendReport message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJob.GenerateTrendReport.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.IGenerateTrendReport} message GenerateTrendReport message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GenerateTrendReport.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a GenerateTrendReport message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.AsyncJob.GenerateTrendReport} GenerateTrendReport
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GenerateTrendReport.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJob.GenerateTrendReport();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.reportType = reader.string();
                                    break;
                                case 2:
                                    message.storeSetId = reader.int64();
                                    break;
                                case 3:
                                    message.startDateId = reader.string();
                                    break;
                                case 4:
                                    message.endDateId = reader.string();
                                    break;
                                case 5:
                                    message.countryCode = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a GenerateTrendReport message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.AsyncJob.GenerateTrendReport} GenerateTrendReport
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GenerateTrendReport.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a GenerateTrendReport message.
                         * @function verify
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GenerateTrendReport.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.reportType != null && message.hasOwnProperty("reportType"))
                                if (!$util.isString(message.reportType))
                                    return "reportType: string expected";
                            if (message.storeSetId != null && message.hasOwnProperty("storeSetId"))
                                if (!$util.isInteger(message.storeSetId) && !(message.storeSetId && $util.isInteger(message.storeSetId.low) && $util.isInteger(message.storeSetId.high)))
                                    return "storeSetId: integer|Long expected";
                            if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                                if (!$util.isString(message.startDateId))
                                    return "startDateId: string expected";
                            if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                                if (!$util.isString(message.endDateId))
                                    return "endDateId: string expected";
                            if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                                if (!$util.isString(message.countryCode))
                                    return "countryCode: string expected";
                            return null;
                        };
    
                        /**
                         * Creates a GenerateTrendReport message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.AsyncJob.GenerateTrendReport} GenerateTrendReport
                         */
                        GenerateTrendReport.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.AsyncJob.GenerateTrendReport)
                                return object;
                            var message = new $root.palexy.streaming.v1.AsyncJob.GenerateTrendReport();
                            if (object.reportType != null)
                                message.reportType = String(object.reportType);
                            if (object.storeSetId != null)
                                if ($util.Long)
                                    (message.storeSetId = $util.Long.fromValue(object.storeSetId)).unsigned = false;
                                else if (typeof object.storeSetId === "string")
                                    message.storeSetId = parseInt(object.storeSetId, 10);
                                else if (typeof object.storeSetId === "number")
                                    message.storeSetId = object.storeSetId;
                                else if (typeof object.storeSetId === "object")
                                    message.storeSetId = new $util.LongBits(object.storeSetId.low >>> 0, object.storeSetId.high >>> 0).toNumber();
                            if (object.startDateId != null)
                                message.startDateId = String(object.startDateId);
                            if (object.endDateId != null)
                                message.endDateId = String(object.endDateId);
                            if (object.countryCode != null)
                                message.countryCode = String(object.countryCode);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a GenerateTrendReport message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @static
                         * @param {palexy.streaming.v1.AsyncJob.GenerateTrendReport} message GenerateTrendReport
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GenerateTrendReport.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.reportType = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.storeSetId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.storeSetId = options.longs === String ? "0" : 0;
                                object.startDateId = "";
                                object.endDateId = "";
                                object.countryCode = "";
                            }
                            if (message.reportType != null && message.hasOwnProperty("reportType"))
                                object.reportType = message.reportType;
                            if (message.storeSetId != null && message.hasOwnProperty("storeSetId"))
                                if (typeof message.storeSetId === "number")
                                    object.storeSetId = options.longs === String ? String(message.storeSetId) : message.storeSetId;
                                else
                                    object.storeSetId = options.longs === String ? $util.Long.prototype.toString.call(message.storeSetId) : options.longs === Number ? new $util.LongBits(message.storeSetId.low >>> 0, message.storeSetId.high >>> 0).toNumber() : message.storeSetId;
                            if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                                object.startDateId = message.startDateId;
                            if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                                object.endDateId = message.endDateId;
                            if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                                object.countryCode = message.countryCode;
                            return object;
                        };
    
                        /**
                         * Converts this GenerateTrendReport to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.AsyncJob.GenerateTrendReport
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GenerateTrendReport.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return GenerateTrendReport;
                    })();
    
                    return AsyncJob;
                })();
    
                v1.GetAsyncJobRequest = (function() {
    
                    /**
                     * Properties of a GetAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetAsyncJobRequest
                     * @property {number|null} [asyncJobId] GetAsyncJobRequest asyncJobId
                     */
    
                    /**
                     * Constructs a new GetAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetAsyncJobRequest.
                     * @implements IGetAsyncJobRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetAsyncJobRequest=} [properties] Properties to set
                     */
                    function GetAsyncJobRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetAsyncJobRequest asyncJobId.
                     * @member {number} asyncJobId
                     * @memberof palexy.streaming.v1.GetAsyncJobRequest
                     * @instance
                     */
                    GetAsyncJobRequest.prototype.asyncJobId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetAsyncJobRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetAsyncJobRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetAsyncJobRequest} GetAsyncJobRequest instance
                     */
                    GetAsyncJobRequest.create = function create(properties) {
                        return new GetAsyncJobRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetAsyncJobRequest message. Does not implicitly {@link palexy.streaming.v1.GetAsyncJobRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetAsyncJobRequest} message GetAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetAsyncJobRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.asyncJobId != null && Object.hasOwnProperty.call(message, "asyncJobId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.asyncJobId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetAsyncJobRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetAsyncJobRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetAsyncJobRequest} message GetAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetAsyncJobRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetAsyncJobRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetAsyncJobRequest} GetAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetAsyncJobRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetAsyncJobRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.asyncJobId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetAsyncJobRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetAsyncJobRequest} GetAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetAsyncJobRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetAsyncJobRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetAsyncJobRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.asyncJobId != null && message.hasOwnProperty("asyncJobId"))
                            if (!$util.isInteger(message.asyncJobId) && !(message.asyncJobId && $util.isInteger(message.asyncJobId.low) && $util.isInteger(message.asyncJobId.high)))
                                return "asyncJobId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetAsyncJobRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetAsyncJobRequest} GetAsyncJobRequest
                     */
                    GetAsyncJobRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetAsyncJobRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetAsyncJobRequest();
                        if (object.asyncJobId != null)
                            if ($util.Long)
                                (message.asyncJobId = $util.Long.fromValue(object.asyncJobId)).unsigned = false;
                            else if (typeof object.asyncJobId === "string")
                                message.asyncJobId = parseInt(object.asyncJobId, 10);
                            else if (typeof object.asyncJobId === "number")
                                message.asyncJobId = object.asyncJobId;
                            else if (typeof object.asyncJobId === "object")
                                message.asyncJobId = new $util.LongBits(object.asyncJobId.low >>> 0, object.asyncJobId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetAsyncJobRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.GetAsyncJobRequest} message GetAsyncJobRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetAsyncJobRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.asyncJobId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.asyncJobId = options.longs === String ? "0" : 0;
                        if (message.asyncJobId != null && message.hasOwnProperty("asyncJobId"))
                            if (typeof message.asyncJobId === "number")
                                object.asyncJobId = options.longs === String ? String(message.asyncJobId) : message.asyncJobId;
                            else
                                object.asyncJobId = options.longs === String ? $util.Long.prototype.toString.call(message.asyncJobId) : options.longs === Number ? new $util.LongBits(message.asyncJobId.low >>> 0, message.asyncJobId.high >>> 0).toNumber() : message.asyncJobId;
                        return object;
                    };
    
                    /**
                     * Converts this GetAsyncJobRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetAsyncJobRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetAsyncJobRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetAsyncJobRequest;
                })();
    
                v1.BatchGetAsyncJobRequest = (function() {
    
                    /**
                     * Properties of a BatchGetAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchGetAsyncJobRequest
                     * @property {Array.<number>|null} [asyncJobIds] BatchGetAsyncJobRequest asyncJobIds
                     */
    
                    /**
                     * Constructs a new BatchGetAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchGetAsyncJobRequest.
                     * @implements IBatchGetAsyncJobRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchGetAsyncJobRequest=} [properties] Properties to set
                     */
                    function BatchGetAsyncJobRequest(properties) {
                        this.asyncJobIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchGetAsyncJobRequest asyncJobIds.
                     * @member {Array.<number>} asyncJobIds
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobRequest
                     * @instance
                     */
                    BatchGetAsyncJobRequest.prototype.asyncJobIds = $util.emptyArray;
    
                    /**
                     * Creates a new BatchGetAsyncJobRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchGetAsyncJobRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchGetAsyncJobRequest} BatchGetAsyncJobRequest instance
                     */
                    BatchGetAsyncJobRequest.create = function create(properties) {
                        return new BatchGetAsyncJobRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchGetAsyncJobRequest message. Does not implicitly {@link palexy.streaming.v1.BatchGetAsyncJobRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchGetAsyncJobRequest} message BatchGetAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchGetAsyncJobRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.asyncJobIds != null && message.asyncJobIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.asyncJobIds.length; ++i)
                                writer.int64(message.asyncJobIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchGetAsyncJobRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchGetAsyncJobRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchGetAsyncJobRequest} message BatchGetAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchGetAsyncJobRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchGetAsyncJobRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchGetAsyncJobRequest} BatchGetAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchGetAsyncJobRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchGetAsyncJobRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.asyncJobIds && message.asyncJobIds.length))
                                    message.asyncJobIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.asyncJobIds.push(reader.int64());
                                } else
                                    message.asyncJobIds.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchGetAsyncJobRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchGetAsyncJobRequest} BatchGetAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchGetAsyncJobRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchGetAsyncJobRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchGetAsyncJobRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.asyncJobIds != null && message.hasOwnProperty("asyncJobIds")) {
                            if (!Array.isArray(message.asyncJobIds))
                                return "asyncJobIds: array expected";
                            for (var i = 0; i < message.asyncJobIds.length; ++i)
                                if (!$util.isInteger(message.asyncJobIds[i]) && !(message.asyncJobIds[i] && $util.isInteger(message.asyncJobIds[i].low) && $util.isInteger(message.asyncJobIds[i].high)))
                                    return "asyncJobIds: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchGetAsyncJobRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchGetAsyncJobRequest} BatchGetAsyncJobRequest
                     */
                    BatchGetAsyncJobRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchGetAsyncJobRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchGetAsyncJobRequest();
                        if (object.asyncJobIds) {
                            if (!Array.isArray(object.asyncJobIds))
                                throw TypeError(".palexy.streaming.v1.BatchGetAsyncJobRequest.asyncJobIds: array expected");
                            message.asyncJobIds = [];
                            for (var i = 0; i < object.asyncJobIds.length; ++i)
                                if ($util.Long)
                                    (message.asyncJobIds[i] = $util.Long.fromValue(object.asyncJobIds[i])).unsigned = false;
                                else if (typeof object.asyncJobIds[i] === "string")
                                    message.asyncJobIds[i] = parseInt(object.asyncJobIds[i], 10);
                                else if (typeof object.asyncJobIds[i] === "number")
                                    message.asyncJobIds[i] = object.asyncJobIds[i];
                                else if (typeof object.asyncJobIds[i] === "object")
                                    message.asyncJobIds[i] = new $util.LongBits(object.asyncJobIds[i].low >>> 0, object.asyncJobIds[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchGetAsyncJobRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchGetAsyncJobRequest} message BatchGetAsyncJobRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchGetAsyncJobRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.asyncJobIds = [];
                        if (message.asyncJobIds && message.asyncJobIds.length) {
                            object.asyncJobIds = [];
                            for (var j = 0; j < message.asyncJobIds.length; ++j)
                                if (typeof message.asyncJobIds[j] === "number")
                                    object.asyncJobIds[j] = options.longs === String ? String(message.asyncJobIds[j]) : message.asyncJobIds[j];
                                else
                                    object.asyncJobIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.asyncJobIds[j]) : options.longs === Number ? new $util.LongBits(message.asyncJobIds[j].low >>> 0, message.asyncJobIds[j].high >>> 0).toNumber() : message.asyncJobIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchGetAsyncJobRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchGetAsyncJobRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchGetAsyncJobRequest;
                })();
    
                v1.BatchGetAsyncJobResponse = (function() {
    
                    /**
                     * Properties of a BatchGetAsyncJobResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchGetAsyncJobResponse
                     * @property {Array.<palexy.streaming.v1.IAsyncJob>|null} [asyncJobs] BatchGetAsyncJobResponse asyncJobs
                     */
    
                    /**
                     * Constructs a new BatchGetAsyncJobResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchGetAsyncJobResponse.
                     * @implements IBatchGetAsyncJobResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchGetAsyncJobResponse=} [properties] Properties to set
                     */
                    function BatchGetAsyncJobResponse(properties) {
                        this.asyncJobs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchGetAsyncJobResponse asyncJobs.
                     * @member {Array.<palexy.streaming.v1.IAsyncJob>} asyncJobs
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobResponse
                     * @instance
                     */
                    BatchGetAsyncJobResponse.prototype.asyncJobs = $util.emptyArray;
    
                    /**
                     * Creates a new BatchGetAsyncJobResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchGetAsyncJobResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchGetAsyncJobResponse} BatchGetAsyncJobResponse instance
                     */
                    BatchGetAsyncJobResponse.create = function create(properties) {
                        return new BatchGetAsyncJobResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchGetAsyncJobResponse message. Does not implicitly {@link palexy.streaming.v1.BatchGetAsyncJobResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchGetAsyncJobResponse} message BatchGetAsyncJobResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchGetAsyncJobResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.asyncJobs != null && message.asyncJobs.length)
                            for (var i = 0; i < message.asyncJobs.length; ++i)
                                $root.palexy.streaming.v1.AsyncJob.encode(message.asyncJobs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchGetAsyncJobResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchGetAsyncJobResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchGetAsyncJobResponse} message BatchGetAsyncJobResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchGetAsyncJobResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchGetAsyncJobResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchGetAsyncJobResponse} BatchGetAsyncJobResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchGetAsyncJobResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchGetAsyncJobResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.asyncJobs && message.asyncJobs.length))
                                    message.asyncJobs = [];
                                message.asyncJobs.push($root.palexy.streaming.v1.AsyncJob.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchGetAsyncJobResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchGetAsyncJobResponse} BatchGetAsyncJobResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchGetAsyncJobResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchGetAsyncJobResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchGetAsyncJobResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.asyncJobs != null && message.hasOwnProperty("asyncJobs")) {
                            if (!Array.isArray(message.asyncJobs))
                                return "asyncJobs: array expected";
                            for (var i = 0; i < message.asyncJobs.length; ++i) {
                                var error = $root.palexy.streaming.v1.AsyncJob.verify(message.asyncJobs[i]);
                                if (error)
                                    return "asyncJobs." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchGetAsyncJobResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchGetAsyncJobResponse} BatchGetAsyncJobResponse
                     */
                    BatchGetAsyncJobResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchGetAsyncJobResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchGetAsyncJobResponse();
                        if (object.asyncJobs) {
                            if (!Array.isArray(object.asyncJobs))
                                throw TypeError(".palexy.streaming.v1.BatchGetAsyncJobResponse.asyncJobs: array expected");
                            message.asyncJobs = [];
                            for (var i = 0; i < object.asyncJobs.length; ++i) {
                                if (typeof object.asyncJobs[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchGetAsyncJobResponse.asyncJobs: object expected");
                                message.asyncJobs[i] = $root.palexy.streaming.v1.AsyncJob.fromObject(object.asyncJobs[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchGetAsyncJobResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchGetAsyncJobResponse} message BatchGetAsyncJobResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchGetAsyncJobResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.asyncJobs = [];
                        if (message.asyncJobs && message.asyncJobs.length) {
                            object.asyncJobs = [];
                            for (var j = 0; j < message.asyncJobs.length; ++j)
                                object.asyncJobs[j] = $root.palexy.streaming.v1.AsyncJob.toObject(message.asyncJobs[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchGetAsyncJobResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchGetAsyncJobResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchGetAsyncJobResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchGetAsyncJobResponse;
                })();
    
                v1.CancelAsyncJobRequest = (function() {
    
                    /**
                     * Properties of a CancelAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICancelAsyncJobRequest
                     * @property {number|null} [asyncJobId] CancelAsyncJobRequest asyncJobId
                     * @property {palexy.streaming.v1.AsyncJob.Status|null} [currentStatus] CancelAsyncJobRequest currentStatus
                     */
    
                    /**
                     * Constructs a new CancelAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CancelAsyncJobRequest.
                     * @implements ICancelAsyncJobRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICancelAsyncJobRequest=} [properties] Properties to set
                     */
                    function CancelAsyncJobRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CancelAsyncJobRequest asyncJobId.
                     * @member {number} asyncJobId
                     * @memberof palexy.streaming.v1.CancelAsyncJobRequest
                     * @instance
                     */
                    CancelAsyncJobRequest.prototype.asyncJobId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CancelAsyncJobRequest currentStatus.
                     * @member {palexy.streaming.v1.AsyncJob.Status} currentStatus
                     * @memberof palexy.streaming.v1.CancelAsyncJobRequest
                     * @instance
                     */
                    CancelAsyncJobRequest.prototype.currentStatus = 0;
    
                    /**
                     * Creates a new CancelAsyncJobRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CancelAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.ICancelAsyncJobRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CancelAsyncJobRequest} CancelAsyncJobRequest instance
                     */
                    CancelAsyncJobRequest.create = function create(properties) {
                        return new CancelAsyncJobRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CancelAsyncJobRequest message. Does not implicitly {@link palexy.streaming.v1.CancelAsyncJobRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CancelAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.ICancelAsyncJobRequest} message CancelAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CancelAsyncJobRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.asyncJobId != null && Object.hasOwnProperty.call(message, "asyncJobId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.asyncJobId);
                        if (message.currentStatus != null && Object.hasOwnProperty.call(message, "currentStatus"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currentStatus);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CancelAsyncJobRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CancelAsyncJobRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CancelAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.ICancelAsyncJobRequest} message CancelAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CancelAsyncJobRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CancelAsyncJobRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CancelAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CancelAsyncJobRequest} CancelAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CancelAsyncJobRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CancelAsyncJobRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.asyncJobId = reader.int64();
                                break;
                            case 2:
                                message.currentStatus = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CancelAsyncJobRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CancelAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CancelAsyncJobRequest} CancelAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CancelAsyncJobRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CancelAsyncJobRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CancelAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CancelAsyncJobRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.asyncJobId != null && message.hasOwnProperty("asyncJobId"))
                            if (!$util.isInteger(message.asyncJobId) && !(message.asyncJobId && $util.isInteger(message.asyncJobId.low) && $util.isInteger(message.asyncJobId.high)))
                                return "asyncJobId: integer|Long expected";
                        if (message.currentStatus != null && message.hasOwnProperty("currentStatus"))
                            switch (message.currentStatus) {
                            default:
                                return "currentStatus: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a CancelAsyncJobRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CancelAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CancelAsyncJobRequest} CancelAsyncJobRequest
                     */
                    CancelAsyncJobRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CancelAsyncJobRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CancelAsyncJobRequest();
                        if (object.asyncJobId != null)
                            if ($util.Long)
                                (message.asyncJobId = $util.Long.fromValue(object.asyncJobId)).unsigned = false;
                            else if (typeof object.asyncJobId === "string")
                                message.asyncJobId = parseInt(object.asyncJobId, 10);
                            else if (typeof object.asyncJobId === "number")
                                message.asyncJobId = object.asyncJobId;
                            else if (typeof object.asyncJobId === "object")
                                message.asyncJobId = new $util.LongBits(object.asyncJobId.low >>> 0, object.asyncJobId.high >>> 0).toNumber();
                        switch (object.currentStatus) {
                        case "UNPROCESSED":
                        case 0:
                            message.currentStatus = 0;
                            break;
                        case "PROCESSING":
                        case 1:
                            message.currentStatus = 1;
                            break;
                        case "WAIT_FOR_RETRYING":
                        case 2:
                            message.currentStatus = 2;
                            break;
                        case "DONE":
                        case 3:
                            message.currentStatus = 3;
                            break;
                        case "CANCELLED":
                        case 4:
                            message.currentStatus = 4;
                            break;
                        case "SKIPPED":
                        case 5:
                            message.currentStatus = 5;
                            break;
                        case "RECURRING":
                        case 6:
                            message.currentStatus = 6;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CancelAsyncJobRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CancelAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.CancelAsyncJobRequest} message CancelAsyncJobRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CancelAsyncJobRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.asyncJobId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.asyncJobId = options.longs === String ? "0" : 0;
                            object.currentStatus = options.enums === String ? "UNPROCESSED" : 0;
                        }
                        if (message.asyncJobId != null && message.hasOwnProperty("asyncJobId"))
                            if (typeof message.asyncJobId === "number")
                                object.asyncJobId = options.longs === String ? String(message.asyncJobId) : message.asyncJobId;
                            else
                                object.asyncJobId = options.longs === String ? $util.Long.prototype.toString.call(message.asyncJobId) : options.longs === Number ? new $util.LongBits(message.asyncJobId.low >>> 0, message.asyncJobId.high >>> 0).toNumber() : message.asyncJobId;
                        if (message.currentStatus != null && message.hasOwnProperty("currentStatus"))
                            object.currentStatus = options.enums === String ? $root.palexy.streaming.v1.AsyncJob.Status[message.currentStatus] : message.currentStatus;
                        return object;
                    };
    
                    /**
                     * Converts this CancelAsyncJobRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CancelAsyncJobRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CancelAsyncJobRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CancelAsyncJobRequest;
                })();
    
                v1.ListAsyncJobRequest = (function() {
    
                    /**
                     * Properties of a ListAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListAsyncJobRequest
                     * @property {number|null} [companyId] ListAsyncJobRequest companyId
                     * @property {number|null} [ownerId] ListAsyncJobRequest ownerId
                     * @property {palexy.streaming.v1.AsyncJob.Type|null} [type] ListAsyncJobRequest type
                     * @property {Array.<palexy.streaming.v1.AsyncJob.Status>|null} [statuses] ListAsyncJobRequest statuses
                     * @property {number|null} [pageSize] ListAsyncJobRequest pageSize
                     * @property {string|null} [pageToken] ListAsyncJobRequest pageToken
                     * @property {palexy.streaming.v1.IDateFilter|null} [fromDate] ListAsyncJobRequest fromDate
                     * @property {palexy.streaming.v1.IDateFilter|null} [toDate] ListAsyncJobRequest toDate
                     * @property {Array.<string>|null} [orderByFieldPaths] ListAsyncJobRequest orderByFieldPaths
                     */
    
                    /**
                     * Constructs a new ListAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListAsyncJobRequest.
                     * @implements IListAsyncJobRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListAsyncJobRequest=} [properties] Properties to set
                     */
                    function ListAsyncJobRequest(properties) {
                        this.statuses = [];
                        this.orderByFieldPaths = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListAsyncJobRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @instance
                     */
                    ListAsyncJobRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListAsyncJobRequest ownerId.
                     * @member {number} ownerId
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @instance
                     */
                    ListAsyncJobRequest.prototype.ownerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListAsyncJobRequest type.
                     * @member {palexy.streaming.v1.AsyncJob.Type} type
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @instance
                     */
                    ListAsyncJobRequest.prototype.type = 0;
    
                    /**
                     * ListAsyncJobRequest statuses.
                     * @member {Array.<palexy.streaming.v1.AsyncJob.Status>} statuses
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @instance
                     */
                    ListAsyncJobRequest.prototype.statuses = $util.emptyArray;
    
                    /**
                     * ListAsyncJobRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @instance
                     */
                    ListAsyncJobRequest.prototype.pageSize = 0;
    
                    /**
                     * ListAsyncJobRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @instance
                     */
                    ListAsyncJobRequest.prototype.pageToken = "";
    
                    /**
                     * ListAsyncJobRequest fromDate.
                     * @member {palexy.streaming.v1.IDateFilter|null|undefined} fromDate
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @instance
                     */
                    ListAsyncJobRequest.prototype.fromDate = null;
    
                    /**
                     * ListAsyncJobRequest toDate.
                     * @member {palexy.streaming.v1.IDateFilter|null|undefined} toDate
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @instance
                     */
                    ListAsyncJobRequest.prototype.toDate = null;
    
                    /**
                     * ListAsyncJobRequest orderByFieldPaths.
                     * @member {Array.<string>} orderByFieldPaths
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @instance
                     */
                    ListAsyncJobRequest.prototype.orderByFieldPaths = $util.emptyArray;
    
                    /**
                     * Creates a new ListAsyncJobRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncJobRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListAsyncJobRequest} ListAsyncJobRequest instance
                     */
                    ListAsyncJobRequest.create = function create(properties) {
                        return new ListAsyncJobRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListAsyncJobRequest message. Does not implicitly {@link palexy.streaming.v1.ListAsyncJobRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncJobRequest} message ListAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAsyncJobRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.ownerId);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                        if (message.statuses != null && message.statuses.length) {
                            writer.uint32(/* id 4, wireType 2 =*/34).fork();
                            for (var i = 0; i < message.statuses.length; ++i)
                                writer.int32(message.statuses[i]);
                            writer.ldelim();
                        }
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.pageToken);
                        if (message.fromDate != null && Object.hasOwnProperty.call(message, "fromDate"))
                            $root.palexy.streaming.v1.DateFilter.encode(message.fromDate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.toDate != null && Object.hasOwnProperty.call(message, "toDate"))
                            $root.palexy.streaming.v1.DateFilter.encode(message.toDate, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.orderByFieldPaths != null && message.orderByFieldPaths.length)
                            for (var i = 0; i < message.orderByFieldPaths.length; ++i)
                                writer.uint32(/* id 11, wireType 2 =*/90).string(message.orderByFieldPaths[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListAsyncJobRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListAsyncJobRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncJobRequest} message ListAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAsyncJobRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListAsyncJobRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListAsyncJobRequest} ListAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAsyncJobRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListAsyncJobRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.ownerId = reader.int64();
                                break;
                            case 3:
                                message.type = reader.int32();
                                break;
                            case 4:
                                if (!(message.statuses && message.statuses.length))
                                    message.statuses = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.statuses.push(reader.int32());
                                } else
                                    message.statuses.push(reader.int32());
                                break;
                            case 5:
                                message.pageSize = reader.int32();
                                break;
                            case 6:
                                message.pageToken = reader.string();
                                break;
                            case 8:
                                message.fromDate = $root.palexy.streaming.v1.DateFilter.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.toDate = $root.palexy.streaming.v1.DateFilter.decode(reader, reader.uint32());
                                break;
                            case 11:
                                if (!(message.orderByFieldPaths && message.orderByFieldPaths.length))
                                    message.orderByFieldPaths = [];
                                message.orderByFieldPaths.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListAsyncJobRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListAsyncJobRequest} ListAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAsyncJobRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListAsyncJobRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListAsyncJobRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (!$util.isInteger(message.ownerId) && !(message.ownerId && $util.isInteger(message.ownerId.low) && $util.isInteger(message.ownerId.high)))
                                return "ownerId: integer|Long expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                                break;
                            }
                        if (message.statuses != null && message.hasOwnProperty("statuses")) {
                            if (!Array.isArray(message.statuses))
                                return "statuses: array expected";
                            for (var i = 0; i < message.statuses.length; ++i)
                                switch (message.statuses[i]) {
                                default:
                                    return "statuses: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                    break;
                                }
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.fromDate != null && message.hasOwnProperty("fromDate")) {
                            var error = $root.palexy.streaming.v1.DateFilter.verify(message.fromDate);
                            if (error)
                                return "fromDate." + error;
                        }
                        if (message.toDate != null && message.hasOwnProperty("toDate")) {
                            var error = $root.palexy.streaming.v1.DateFilter.verify(message.toDate);
                            if (error)
                                return "toDate." + error;
                        }
                        if (message.orderByFieldPaths != null && message.hasOwnProperty("orderByFieldPaths")) {
                            if (!Array.isArray(message.orderByFieldPaths))
                                return "orderByFieldPaths: array expected";
                            for (var i = 0; i < message.orderByFieldPaths.length; ++i)
                                if (!$util.isString(message.orderByFieldPaths[i]))
                                    return "orderByFieldPaths: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListAsyncJobRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListAsyncJobRequest} ListAsyncJobRequest
                     */
                    ListAsyncJobRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListAsyncJobRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListAsyncJobRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.ownerId != null)
                            if ($util.Long)
                                (message.ownerId = $util.Long.fromValue(object.ownerId)).unsigned = false;
                            else if (typeof object.ownerId === "string")
                                message.ownerId = parseInt(object.ownerId, 10);
                            else if (typeof object.ownerId === "number")
                                message.ownerId = object.ownerId;
                            else if (typeof object.ownerId === "object")
                                message.ownerId = new $util.LongBits(object.ownerId.low >>> 0, object.ownerId.high >>> 0).toNumber();
                        switch (object.type) {
                        case "UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "EXPORT":
                        case 1:
                            message.type = 1;
                            break;
                        case "POS_MATCHING":
                        case 2:
                            message.type = 2;
                            break;
                        case "POS_IMPORT":
                        case 3:
                            message.type = 3;
                            break;
                        case "AGGREGATE":
                        case 4:
                            message.type = 4;
                            break;
                        case "AGGREGATE_LEVEL_2":
                        case 5:
                            message.type = 5;
                            break;
                        case "CALCULATE_CONSISTENCY":
                        case 6:
                            message.type = 6;
                            break;
                        case "SCHEDULE_TASK":
                        case 7:
                            message.type = 7;
                            break;
                        case "STAFF_DATA_IMPORT":
                        case 8:
                            message.type = 8;
                            break;
                        case "EXPORT_REPORT":
                        case 9:
                            message.type = 9;
                            break;
                        case "UPDATE_WEATHER_DATA":
                        case 10:
                            message.type = 10;
                            break;
                        case "GENERATE_INSIGHT":
                        case 11:
                            message.type = 11;
                            break;
                        case "SEND_REPORT_SCHEDULE":
                        case 12:
                            message.type = 12;
                            break;
                        case "EXTERNAL_TRAFFIC_IMPORT":
                        case 13:
                            message.type = 13;
                            break;
                        case "GENERATE_TREND_REPORT":
                        case 14:
                            message.type = 14;
                            break;
                        }
                        if (object.statuses) {
                            if (!Array.isArray(object.statuses))
                                throw TypeError(".palexy.streaming.v1.ListAsyncJobRequest.statuses: array expected");
                            message.statuses = [];
                            for (var i = 0; i < object.statuses.length; ++i)
                                switch (object.statuses[i]) {
                                default:
                                case "UNPROCESSED":
                                case 0:
                                    message.statuses[i] = 0;
                                    break;
                                case "PROCESSING":
                                case 1:
                                    message.statuses[i] = 1;
                                    break;
                                case "WAIT_FOR_RETRYING":
                                case 2:
                                    message.statuses[i] = 2;
                                    break;
                                case "DONE":
                                case 3:
                                    message.statuses[i] = 3;
                                    break;
                                case "CANCELLED":
                                case 4:
                                    message.statuses[i] = 4;
                                    break;
                                case "SKIPPED":
                                case 5:
                                    message.statuses[i] = 5;
                                    break;
                                case "RECURRING":
                                case 6:
                                    message.statuses[i] = 6;
                                    break;
                                }
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.fromDate != null) {
                            if (typeof object.fromDate !== "object")
                                throw TypeError(".palexy.streaming.v1.ListAsyncJobRequest.fromDate: object expected");
                            message.fromDate = $root.palexy.streaming.v1.DateFilter.fromObject(object.fromDate);
                        }
                        if (object.toDate != null) {
                            if (typeof object.toDate !== "object")
                                throw TypeError(".palexy.streaming.v1.ListAsyncJobRequest.toDate: object expected");
                            message.toDate = $root.palexy.streaming.v1.DateFilter.fromObject(object.toDate);
                        }
                        if (object.orderByFieldPaths) {
                            if (!Array.isArray(object.orderByFieldPaths))
                                throw TypeError(".palexy.streaming.v1.ListAsyncJobRequest.orderByFieldPaths: array expected");
                            message.orderByFieldPaths = [];
                            for (var i = 0; i < object.orderByFieldPaths.length; ++i)
                                message.orderByFieldPaths[i] = String(object.orderByFieldPaths[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListAsyncJobRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.ListAsyncJobRequest} message ListAsyncJobRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListAsyncJobRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.statuses = [];
                            object.orderByFieldPaths = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.ownerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.ownerId = options.longs === String ? "0" : 0;
                            object.type = options.enums === String ? "UNKNOWN" : 0;
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.fromDate = null;
                            object.toDate = null;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (typeof message.ownerId === "number")
                                object.ownerId = options.longs === String ? String(message.ownerId) : message.ownerId;
                            else
                                object.ownerId = options.longs === String ? $util.Long.prototype.toString.call(message.ownerId) : options.longs === Number ? new $util.LongBits(message.ownerId.low >>> 0, message.ownerId.high >>> 0).toNumber() : message.ownerId;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.AsyncJob.Type[message.type] : message.type;
                        if (message.statuses && message.statuses.length) {
                            object.statuses = [];
                            for (var j = 0; j < message.statuses.length; ++j)
                                object.statuses[j] = options.enums === String ? $root.palexy.streaming.v1.AsyncJob.Status[message.statuses[j]] : message.statuses[j];
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.fromDate != null && message.hasOwnProperty("fromDate"))
                            object.fromDate = $root.palexy.streaming.v1.DateFilter.toObject(message.fromDate, options);
                        if (message.toDate != null && message.hasOwnProperty("toDate"))
                            object.toDate = $root.palexy.streaming.v1.DateFilter.toObject(message.toDate, options);
                        if (message.orderByFieldPaths && message.orderByFieldPaths.length) {
                            object.orderByFieldPaths = [];
                            for (var j = 0; j < message.orderByFieldPaths.length; ++j)
                                object.orderByFieldPaths[j] = message.orderByFieldPaths[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListAsyncJobRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListAsyncJobRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListAsyncJobRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListAsyncJobRequest;
                })();
    
                v1.ListAsyncJobResponse = (function() {
    
                    /**
                     * Properties of a ListAsyncJobResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListAsyncJobResponse
                     * @property {Array.<palexy.streaming.v1.IAsyncJob>|null} [asyncJobs] ListAsyncJobResponse asyncJobs
                     * @property {number|null} [totalElements] ListAsyncJobResponse totalElements
                     * @property {string|null} [nextPageToken] ListAsyncJobResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListAsyncJobResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListAsyncJobResponse.
                     * @implements IListAsyncJobResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListAsyncJobResponse=} [properties] Properties to set
                     */
                    function ListAsyncJobResponse(properties) {
                        this.asyncJobs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListAsyncJobResponse asyncJobs.
                     * @member {Array.<palexy.streaming.v1.IAsyncJob>} asyncJobs
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @instance
                     */
                    ListAsyncJobResponse.prototype.asyncJobs = $util.emptyArray;
    
                    /**
                     * ListAsyncJobResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @instance
                     */
                    ListAsyncJobResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListAsyncJobResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @instance
                     */
                    ListAsyncJobResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListAsyncJobResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncJobResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListAsyncJobResponse} ListAsyncJobResponse instance
                     */
                    ListAsyncJobResponse.create = function create(properties) {
                        return new ListAsyncJobResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListAsyncJobResponse message. Does not implicitly {@link palexy.streaming.v1.ListAsyncJobResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncJobResponse} message ListAsyncJobResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAsyncJobResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.asyncJobs != null && message.asyncJobs.length)
                            for (var i = 0; i < message.asyncJobs.length; ++i)
                                $root.palexy.streaming.v1.AsyncJob.encode(message.asyncJobs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListAsyncJobResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListAsyncJobResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncJobResponse} message ListAsyncJobResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAsyncJobResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListAsyncJobResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListAsyncJobResponse} ListAsyncJobResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAsyncJobResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListAsyncJobResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.asyncJobs && message.asyncJobs.length))
                                    message.asyncJobs = [];
                                message.asyncJobs.push($root.palexy.streaming.v1.AsyncJob.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int64();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListAsyncJobResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListAsyncJobResponse} ListAsyncJobResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAsyncJobResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListAsyncJobResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListAsyncJobResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.asyncJobs != null && message.hasOwnProperty("asyncJobs")) {
                            if (!Array.isArray(message.asyncJobs))
                                return "asyncJobs: array expected";
                            for (var i = 0; i < message.asyncJobs.length; ++i) {
                                var error = $root.palexy.streaming.v1.AsyncJob.verify(message.asyncJobs[i]);
                                if (error)
                                    return "asyncJobs." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListAsyncJobResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListAsyncJobResponse} ListAsyncJobResponse
                     */
                    ListAsyncJobResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListAsyncJobResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListAsyncJobResponse();
                        if (object.asyncJobs) {
                            if (!Array.isArray(object.asyncJobs))
                                throw TypeError(".palexy.streaming.v1.ListAsyncJobResponse.asyncJobs: array expected");
                            message.asyncJobs = [];
                            for (var i = 0; i < object.asyncJobs.length; ++i) {
                                if (typeof object.asyncJobs[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListAsyncJobResponse.asyncJobs: object expected");
                                message.asyncJobs[i] = $root.palexy.streaming.v1.AsyncJob.fromObject(object.asyncJobs[i]);
                            }
                        }
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListAsyncJobResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.ListAsyncJobResponse} message ListAsyncJobResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListAsyncJobResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.asyncJobs = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                            object.nextPageToken = "";
                        }
                        if (message.asyncJobs && message.asyncJobs.length) {
                            object.asyncJobs = [];
                            for (var j = 0; j < message.asyncJobs.length; ++j)
                                object.asyncJobs[j] = $root.palexy.streaming.v1.AsyncJob.toObject(message.asyncJobs[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListAsyncJobResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListAsyncJobResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListAsyncJobResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListAsyncJobResponse;
                })();
    
                v1.UpdateAsyncJobRequest = (function() {
    
                    /**
                     * Properties of an UpdateAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateAsyncJobRequest
                     * @property {palexy.streaming.v1.IAsyncJob|null} [asyncJob] UpdateAsyncJobRequest asyncJob
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateAsyncJobRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateAsyncJobRequest.
                     * @implements IUpdateAsyncJobRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateAsyncJobRequest=} [properties] Properties to set
                     */
                    function UpdateAsyncJobRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateAsyncJobRequest asyncJob.
                     * @member {palexy.streaming.v1.IAsyncJob|null|undefined} asyncJob
                     * @memberof palexy.streaming.v1.UpdateAsyncJobRequest
                     * @instance
                     */
                    UpdateAsyncJobRequest.prototype.asyncJob = null;
    
                    /**
                     * UpdateAsyncJobRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateAsyncJobRequest
                     * @instance
                     */
                    UpdateAsyncJobRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateAsyncJobRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateAsyncJobRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateAsyncJobRequest} UpdateAsyncJobRequest instance
                     */
                    UpdateAsyncJobRequest.create = function create(properties) {
                        return new UpdateAsyncJobRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateAsyncJobRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateAsyncJobRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateAsyncJobRequest} message UpdateAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateAsyncJobRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.asyncJob != null && Object.hasOwnProperty.call(message, "asyncJob"))
                            $root.palexy.streaming.v1.AsyncJob.encode(message.asyncJob, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateAsyncJobRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateAsyncJobRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateAsyncJobRequest} message UpdateAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateAsyncJobRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateAsyncJobRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateAsyncJobRequest} UpdateAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateAsyncJobRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateAsyncJobRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.asyncJob = $root.palexy.streaming.v1.AsyncJob.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateAsyncJobRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateAsyncJobRequest} UpdateAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateAsyncJobRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateAsyncJobRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateAsyncJobRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.asyncJob != null && message.hasOwnProperty("asyncJob")) {
                            var error = $root.palexy.streaming.v1.AsyncJob.verify(message.asyncJob);
                            if (error)
                                return "asyncJob." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateAsyncJobRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateAsyncJobRequest} UpdateAsyncJobRequest
                     */
                    UpdateAsyncJobRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateAsyncJobRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateAsyncJobRequest();
                        if (object.asyncJob != null) {
                            if (typeof object.asyncJob !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateAsyncJobRequest.asyncJob: object expected");
                            message.asyncJob = $root.palexy.streaming.v1.AsyncJob.fromObject(object.asyncJob);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateAsyncJobRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateAsyncJobRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateAsyncJobRequest} message UpdateAsyncJobRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateAsyncJobRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.asyncJob = null;
                            object.updateMask = null;
                        }
                        if (message.asyncJob != null && message.hasOwnProperty("asyncJob"))
                            object.asyncJob = $root.palexy.streaming.v1.AsyncJob.toObject(message.asyncJob, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateAsyncJobRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateAsyncJobRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateAsyncJobRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateAsyncJobRequest;
                })();
    
                v1.BatchCreateAsyncJobRequest = (function() {
    
                    /**
                     * Properties of a BatchCreateAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreateAsyncJobRequest
                     * @property {Array.<palexy.streaming.v1.IAsyncJob>|null} [asyncJobs] BatchCreateAsyncJobRequest asyncJobs
                     */
    
                    /**
                     * Constructs a new BatchCreateAsyncJobRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreateAsyncJobRequest.
                     * @implements IBatchCreateAsyncJobRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreateAsyncJobRequest=} [properties] Properties to set
                     */
                    function BatchCreateAsyncJobRequest(properties) {
                        this.asyncJobs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateAsyncJobRequest asyncJobs.
                     * @member {Array.<palexy.streaming.v1.IAsyncJob>} asyncJobs
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobRequest
                     * @instance
                     */
                    BatchCreateAsyncJobRequest.prototype.asyncJobs = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateAsyncJobRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateAsyncJobRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreateAsyncJobRequest} BatchCreateAsyncJobRequest instance
                     */
                    BatchCreateAsyncJobRequest.create = function create(properties) {
                        return new BatchCreateAsyncJobRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateAsyncJobRequest message. Does not implicitly {@link palexy.streaming.v1.BatchCreateAsyncJobRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateAsyncJobRequest} message BatchCreateAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateAsyncJobRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.asyncJobs != null && message.asyncJobs.length)
                            for (var i = 0; i < message.asyncJobs.length; ++i)
                                $root.palexy.streaming.v1.AsyncJob.encode(message.asyncJobs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateAsyncJobRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreateAsyncJobRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateAsyncJobRequest} message BatchCreateAsyncJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateAsyncJobRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateAsyncJobRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreateAsyncJobRequest} BatchCreateAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateAsyncJobRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreateAsyncJobRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.asyncJobs && message.asyncJobs.length))
                                    message.asyncJobs = [];
                                message.asyncJobs.push($root.palexy.streaming.v1.AsyncJob.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateAsyncJobRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreateAsyncJobRequest} BatchCreateAsyncJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateAsyncJobRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateAsyncJobRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateAsyncJobRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.asyncJobs != null && message.hasOwnProperty("asyncJobs")) {
                            if (!Array.isArray(message.asyncJobs))
                                return "asyncJobs: array expected";
                            for (var i = 0; i < message.asyncJobs.length; ++i) {
                                var error = $root.palexy.streaming.v1.AsyncJob.verify(message.asyncJobs[i]);
                                if (error)
                                    return "asyncJobs." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateAsyncJobRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreateAsyncJobRequest} BatchCreateAsyncJobRequest
                     */
                    BatchCreateAsyncJobRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreateAsyncJobRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreateAsyncJobRequest();
                        if (object.asyncJobs) {
                            if (!Array.isArray(object.asyncJobs))
                                throw TypeError(".palexy.streaming.v1.BatchCreateAsyncJobRequest.asyncJobs: array expected");
                            message.asyncJobs = [];
                            for (var i = 0; i < object.asyncJobs.length; ++i) {
                                if (typeof object.asyncJobs[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreateAsyncJobRequest.asyncJobs: object expected");
                                message.asyncJobs[i] = $root.palexy.streaming.v1.AsyncJob.fromObject(object.asyncJobs[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateAsyncJobRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchCreateAsyncJobRequest} message BatchCreateAsyncJobRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateAsyncJobRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.asyncJobs = [];
                        if (message.asyncJobs && message.asyncJobs.length) {
                            object.asyncJobs = [];
                            for (var j = 0; j < message.asyncJobs.length; ++j)
                                object.asyncJobs[j] = $root.palexy.streaming.v1.AsyncJob.toObject(message.asyncJobs[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateAsyncJobRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateAsyncJobRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateAsyncJobRequest;
                })();
    
                v1.BatchCreateAsyncJobResponse = (function() {
    
                    /**
                     * Properties of a BatchCreateAsyncJobResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreateAsyncJobResponse
                     * @property {Array.<palexy.streaming.v1.IAsyncJob>|null} [asyncJobs] BatchCreateAsyncJobResponse asyncJobs
                     */
    
                    /**
                     * Constructs a new BatchCreateAsyncJobResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreateAsyncJobResponse.
                     * @implements IBatchCreateAsyncJobResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreateAsyncJobResponse=} [properties] Properties to set
                     */
                    function BatchCreateAsyncJobResponse(properties) {
                        this.asyncJobs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateAsyncJobResponse asyncJobs.
                     * @member {Array.<palexy.streaming.v1.IAsyncJob>} asyncJobs
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobResponse
                     * @instance
                     */
                    BatchCreateAsyncJobResponse.prototype.asyncJobs = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateAsyncJobResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateAsyncJobResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreateAsyncJobResponse} BatchCreateAsyncJobResponse instance
                     */
                    BatchCreateAsyncJobResponse.create = function create(properties) {
                        return new BatchCreateAsyncJobResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateAsyncJobResponse message. Does not implicitly {@link palexy.streaming.v1.BatchCreateAsyncJobResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateAsyncJobResponse} message BatchCreateAsyncJobResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateAsyncJobResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.asyncJobs != null && message.asyncJobs.length)
                            for (var i = 0; i < message.asyncJobs.length; ++i)
                                $root.palexy.streaming.v1.AsyncJob.encode(message.asyncJobs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateAsyncJobResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreateAsyncJobResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateAsyncJobResponse} message BatchCreateAsyncJobResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateAsyncJobResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateAsyncJobResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreateAsyncJobResponse} BatchCreateAsyncJobResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateAsyncJobResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreateAsyncJobResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.asyncJobs && message.asyncJobs.length))
                                    message.asyncJobs = [];
                                message.asyncJobs.push($root.palexy.streaming.v1.AsyncJob.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateAsyncJobResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreateAsyncJobResponse} BatchCreateAsyncJobResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateAsyncJobResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateAsyncJobResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateAsyncJobResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.asyncJobs != null && message.hasOwnProperty("asyncJobs")) {
                            if (!Array.isArray(message.asyncJobs))
                                return "asyncJobs: array expected";
                            for (var i = 0; i < message.asyncJobs.length; ++i) {
                                var error = $root.palexy.streaming.v1.AsyncJob.verify(message.asyncJobs[i]);
                                if (error)
                                    return "asyncJobs." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateAsyncJobResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreateAsyncJobResponse} BatchCreateAsyncJobResponse
                     */
                    BatchCreateAsyncJobResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreateAsyncJobResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreateAsyncJobResponse();
                        if (object.asyncJobs) {
                            if (!Array.isArray(object.asyncJobs))
                                throw TypeError(".palexy.streaming.v1.BatchCreateAsyncJobResponse.asyncJobs: array expected");
                            message.asyncJobs = [];
                            for (var i = 0; i < object.asyncJobs.length; ++i) {
                                if (typeof object.asyncJobs[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreateAsyncJobResponse.asyncJobs: object expected");
                                message.asyncJobs[i] = $root.palexy.streaming.v1.AsyncJob.fromObject(object.asyncJobs[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateAsyncJobResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchCreateAsyncJobResponse} message BatchCreateAsyncJobResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateAsyncJobResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.asyncJobs = [];
                        if (message.asyncJobs && message.asyncJobs.length) {
                            object.asyncJobs = [];
                            for (var j = 0; j < message.asyncJobs.length; ++j)
                                object.asyncJobs[j] = $root.palexy.streaming.v1.AsyncJob.toObject(message.asyncJobs[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateAsyncJobResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreateAsyncJobResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateAsyncJobResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateAsyncJobResponse;
                })();
    
                v1.ListReadyScheduleTaskJobRequest = (function() {
    
                    /**
                     * Properties of a ListReadyScheduleTaskJobRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListReadyScheduleTaskJobRequest
                     * @property {palexy.streaming.v1.AsyncJob.Type|null} [scheduleTaskType] ListReadyScheduleTaskJobRequest scheduleTaskType
                     * @property {Array.<string>|null} [orderByFieldPaths] ListReadyScheduleTaskJobRequest orderByFieldPaths
                     * @property {number|null} [pageSize] ListReadyScheduleTaskJobRequest pageSize
                     * @property {string|null} [pageToken] ListReadyScheduleTaskJobRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListReadyScheduleTaskJobRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListReadyScheduleTaskJobRequest.
                     * @implements IListReadyScheduleTaskJobRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListReadyScheduleTaskJobRequest=} [properties] Properties to set
                     */
                    function ListReadyScheduleTaskJobRequest(properties) {
                        this.orderByFieldPaths = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListReadyScheduleTaskJobRequest scheduleTaskType.
                     * @member {palexy.streaming.v1.AsyncJob.Type} scheduleTaskType
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @instance
                     */
                    ListReadyScheduleTaskJobRequest.prototype.scheduleTaskType = 0;
    
                    /**
                     * ListReadyScheduleTaskJobRequest orderByFieldPaths.
                     * @member {Array.<string>} orderByFieldPaths
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @instance
                     */
                    ListReadyScheduleTaskJobRequest.prototype.orderByFieldPaths = $util.emptyArray;
    
                    /**
                     * ListReadyScheduleTaskJobRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @instance
                     */
                    ListReadyScheduleTaskJobRequest.prototype.pageSize = 0;
    
                    /**
                     * ListReadyScheduleTaskJobRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @instance
                     */
                    ListReadyScheduleTaskJobRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListReadyScheduleTaskJobRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IListReadyScheduleTaskJobRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListReadyScheduleTaskJobRequest} ListReadyScheduleTaskJobRequest instance
                     */
                    ListReadyScheduleTaskJobRequest.create = function create(properties) {
                        return new ListReadyScheduleTaskJobRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListReadyScheduleTaskJobRequest message. Does not implicitly {@link palexy.streaming.v1.ListReadyScheduleTaskJobRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IListReadyScheduleTaskJobRequest} message ListReadyScheduleTaskJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListReadyScheduleTaskJobRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.scheduleTaskType != null && Object.hasOwnProperty.call(message, "scheduleTaskType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.scheduleTaskType);
                        if (message.orderByFieldPaths != null && message.orderByFieldPaths.length)
                            for (var i = 0; i < message.orderByFieldPaths.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.orderByFieldPaths[i]);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListReadyScheduleTaskJobRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListReadyScheduleTaskJobRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @static
                     * @param {palexy.streaming.v1.IListReadyScheduleTaskJobRequest} message ListReadyScheduleTaskJobRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListReadyScheduleTaskJobRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListReadyScheduleTaskJobRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListReadyScheduleTaskJobRequest} ListReadyScheduleTaskJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListReadyScheduleTaskJobRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListReadyScheduleTaskJobRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.scheduleTaskType = reader.int32();
                                break;
                            case 2:
                                if (!(message.orderByFieldPaths && message.orderByFieldPaths.length))
                                    message.orderByFieldPaths = [];
                                message.orderByFieldPaths.push(reader.string());
                                break;
                            case 3:
                                message.pageSize = reader.int32();
                                break;
                            case 4:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListReadyScheduleTaskJobRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListReadyScheduleTaskJobRequest} ListReadyScheduleTaskJobRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListReadyScheduleTaskJobRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListReadyScheduleTaskJobRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListReadyScheduleTaskJobRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.scheduleTaskType != null && message.hasOwnProperty("scheduleTaskType"))
                            switch (message.scheduleTaskType) {
                            default:
                                return "scheduleTaskType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                                break;
                            }
                        if (message.orderByFieldPaths != null && message.hasOwnProperty("orderByFieldPaths")) {
                            if (!Array.isArray(message.orderByFieldPaths))
                                return "orderByFieldPaths: array expected";
                            for (var i = 0; i < message.orderByFieldPaths.length; ++i)
                                if (!$util.isString(message.orderByFieldPaths[i]))
                                    return "orderByFieldPaths: string[] expected";
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListReadyScheduleTaskJobRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListReadyScheduleTaskJobRequest} ListReadyScheduleTaskJobRequest
                     */
                    ListReadyScheduleTaskJobRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListReadyScheduleTaskJobRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListReadyScheduleTaskJobRequest();
                        switch (object.scheduleTaskType) {
                        case "UNKNOWN":
                        case 0:
                            message.scheduleTaskType = 0;
                            break;
                        case "EXPORT":
                        case 1:
                            message.scheduleTaskType = 1;
                            break;
                        case "POS_MATCHING":
                        case 2:
                            message.scheduleTaskType = 2;
                            break;
                        case "POS_IMPORT":
                        case 3:
                            message.scheduleTaskType = 3;
                            break;
                        case "AGGREGATE":
                        case 4:
                            message.scheduleTaskType = 4;
                            break;
                        case "AGGREGATE_LEVEL_2":
                        case 5:
                            message.scheduleTaskType = 5;
                            break;
                        case "CALCULATE_CONSISTENCY":
                        case 6:
                            message.scheduleTaskType = 6;
                            break;
                        case "SCHEDULE_TASK":
                        case 7:
                            message.scheduleTaskType = 7;
                            break;
                        case "STAFF_DATA_IMPORT":
                        case 8:
                            message.scheduleTaskType = 8;
                            break;
                        case "EXPORT_REPORT":
                        case 9:
                            message.scheduleTaskType = 9;
                            break;
                        case "UPDATE_WEATHER_DATA":
                        case 10:
                            message.scheduleTaskType = 10;
                            break;
                        case "GENERATE_INSIGHT":
                        case 11:
                            message.scheduleTaskType = 11;
                            break;
                        case "SEND_REPORT_SCHEDULE":
                        case 12:
                            message.scheduleTaskType = 12;
                            break;
                        case "EXTERNAL_TRAFFIC_IMPORT":
                        case 13:
                            message.scheduleTaskType = 13;
                            break;
                        case "GENERATE_TREND_REPORT":
                        case 14:
                            message.scheduleTaskType = 14;
                            break;
                        }
                        if (object.orderByFieldPaths) {
                            if (!Array.isArray(object.orderByFieldPaths))
                                throw TypeError(".palexy.streaming.v1.ListReadyScheduleTaskJobRequest.orderByFieldPaths: array expected");
                            message.orderByFieldPaths = [];
                            for (var i = 0; i < object.orderByFieldPaths.length; ++i)
                                message.orderByFieldPaths[i] = String(object.orderByFieldPaths[i]);
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListReadyScheduleTaskJobRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @static
                     * @param {palexy.streaming.v1.ListReadyScheduleTaskJobRequest} message ListReadyScheduleTaskJobRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListReadyScheduleTaskJobRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.orderByFieldPaths = [];
                        if (options.defaults) {
                            object.scheduleTaskType = options.enums === String ? "UNKNOWN" : 0;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.scheduleTaskType != null && message.hasOwnProperty("scheduleTaskType"))
                            object.scheduleTaskType = options.enums === String ? $root.palexy.streaming.v1.AsyncJob.Type[message.scheduleTaskType] : message.scheduleTaskType;
                        if (message.orderByFieldPaths && message.orderByFieldPaths.length) {
                            object.orderByFieldPaths = [];
                            for (var j = 0; j < message.orderByFieldPaths.length; ++j)
                                object.orderByFieldPaths[j] = message.orderByFieldPaths[j];
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListReadyScheduleTaskJobRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListReadyScheduleTaskJobRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListReadyScheduleTaskJobRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListReadyScheduleTaskJobRequest;
                })();
    
                v1.Company = (function() {
    
                    /**
                     * Properties of a Company.
                     * @memberof palexy.streaming.v1
                     * @interface ICompany
                     * @property {number|null} [id] Company id
                     * @property {string|null} [name] Company name
                     * @property {boolean|null} [transformationMatrixEnabled] Company transformationMatrixEnabled
                     * @property {boolean|null} [logicalLayoutEnabled] Company logicalLayoutEnabled
                     * @property {string|null} [awsBucket] Company awsBucket
                     * @property {string|null} [gcsBucket] Company gcsBucket
                     * @property {string|null} [companyCode] Company companyCode
                     * @property {string|null} [partnerCode] Company partnerCode
                     * @property {string|null} [description] Company description
                     * @property {boolean|null} [entranceCounting] Company entranceCounting
                     * @property {string|null} [entranceKeyword] Company entranceKeyword
                     * @property {string|null} [dwelltimeBuckets] Company dwelltimeBuckets
                     * @property {string|null} [ageRanges] Company ageRanges
                     * @property {number|null} [sectionVisitDurationLimit] Company sectionVisitDurationLimit
                     * @property {boolean|null} [dwelltimeMetricDisabled] Company dwelltimeMetricDisabled
                     * @property {boolean|null} [interactionMetricDisabled] Company interactionMetricDisabled
                     * @property {boolean|null} [transactionMetricDisabled] Company transactionMetricDisabled
                     * @property {boolean|null} [groupMetricEnabled] Company groupMetricEnabled
                     * @property {boolean|null} [lyingMetricEnabled] Company lyingMetricEnabled
                     * @property {boolean|null} [avgDwelltimeMetricDisabled] Company avgDwelltimeMetricDisabled
                     * @property {boolean|null} [layoutViewDisabled] Company layoutViewDisabled
                     * @property {boolean|null} [topSectionsViewDisabled] Company topSectionsViewDisabled
                     * @property {boolean|null} [dailyExport] Company dailyExport
                     * @property {number|null} [lastExportTimeMs] Company lastExportTimeMs
                     * @property {string|null} [exportBucket] Company exportBucket
                     * @property {string|null} [exportNotificationEmails] Company exportNotificationEmails
                     * @property {string|null} [exportNotifyUrl] Company exportNotifyUrl
                     * @property {string|null} [integrationUsername] Company integrationUsername
                     * @property {string|null} [integrationPassword] Company integrationPassword
                     * @property {number|null} [createTimeMs] Company createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] Company lastUpdateTimeMs
                     * @property {boolean|null} [interactionLevelMetricEnabled] Company interactionLevelMetricEnabled
                     * @property {boolean|null} [visitorOccupancyMetricEnabled] Company visitorOccupancyMetricEnabled
                     * @property {string|null} [exportStorageProvider] Company exportStorageProvider
                     * @property {string|null} [exportBucketRegion] Company exportBucketRegion
                     * @property {string|null} [exportAccessKey] Company exportAccessKey
                     * @property {string|null} [exportSecretKey] Company exportSecretKey
                     * @property {palexy.streaming.v1.RawIcaStorageProvider|null} [rawIcaStorageProvider] Company rawIcaStorageProvider
                     * @property {string|null} [rawIcaBucketName] Company rawIcaBucketName
                     * @property {boolean|null} [exportPosMatchingResultEnabled] Company exportPosMatchingResultEnabled
                     * @property {boolean|null} [usePosData] Company usePosData
                     * @property {boolean|null} [salesAmountRelatedMetricsEnabled] Company salesAmountRelatedMetricsEnabled
                     * @property {boolean|null} [salesItemsRelatedMetricsEnabled] Company salesItemsRelatedMetricsEnabled
                     * @property {boolean|null} [useNetAmount] Company useNetAmount
                     * @property {boolean|null} [passbyMetricEnabled] Company passbyMetricEnabled
                     * @property {boolean|null} [createMatchingJobOnImportedPosDisabled] Company createMatchingJobOnImportedPosDisabled
                     * @property {string|null} [rawIcaMergingBucketName] Company rawIcaMergingBucketName
                     * @property {string|null} [nearRealTimeProcessingTimesOfDay] Company nearRealTimeProcessingTimesOfDay
                     * @property {boolean|null} [sectionInteractionMetricEnabled] Company sectionInteractionMetricEnabled
                     * @property {boolean|null} [sectionPosMetricEnabled] Company sectionPosMetricEnabled
                     * @property {boolean|null} [visitorDirectionFeatureEnabled] Company visitorDirectionFeatureEnabled
                     * @property {boolean|null} [interactionTimeMetricEnabled] Company interactionTimeMetricEnabled
                     * @property {number|null} [exportRewriteHistoricalStaffingDays] Company exportRewriteHistoricalStaffingDays
                     * @property {boolean|null} [exportOverwriteExistingFiles] Company exportOverwriteExistingFiles
                     * @property {boolean|null} [mfaRequired] Company mfaRequired
                     * @property {palexy.streaming.v1.Company.Industry|null} [industry] Company industry
                     * @property {boolean|null} [useSalesTransAsNetSalesTrans] Company useSalesTransAsNetSalesTrans
                     * @property {boolean|null} [useSalesItemsAsNetSalesItems] Company useSalesItemsAsNetSalesItems
                     * @property {boolean|null} [staffPerformanceAnalysisMetricsEnabled] Company staffPerformanceAnalysisMetricsEnabled
                     * @property {boolean|null} [staffPerformanceAnalysisStaffingMetricsEnabled] Company staffPerformanceAnalysisStaffingMetricsEnabled
                     * @property {Array.<palexy.streaming.v1.StoreEventType>|null} [enabledStoreEventTypes] Company enabledStoreEventTypes
                     * @property {boolean|null} [estimatedTrafficMetricsEnabled] Company estimatedTrafficMetricsEnabled
                     * @property {boolean|null} [enableStoreSnapshot] Company enableStoreSnapshot
                     * @property {boolean|null} [enableForecast] Company enableForecast
                     * @property {boolean|null} [enableTargetTrackingForecast] Company enableTargetTrackingForecast
                     * @property {boolean|null} [strictReportConsistencyEnabled] Company strictReportConsistencyEnabled
                     * @property {Array.<string>|null} [storeSnapshotScheduledTimes] Company storeSnapshotScheduledTimes
                     * @property {number|null} [storeOpenTimeBufferInSeconds] Company storeOpenTimeBufferInSeconds
                     * @property {number|null} [storeCloseTimeBufferInSeconds] Company storeCloseTimeBufferInSeconds
                     * @property {number|null} [startOfWeek] Company startOfWeek
                     * @property {Array.<number>|null} [weekends] Company weekends
                     * @property {number|null} [storeSnapshotRetentionDays] Company storeSnapshotRetentionDays
                     * @property {number|null} [onTimeGreetingThresholdSec] Company onTimeGreetingThresholdSec
                     * @property {boolean|null} [productSalesAnalysisEnabled] Company productSalesAnalysisEnabled
                     * @property {Array.<palexy.streaming.v1.MetricEnum>|null} [defaultLandingMetrics] Company defaultLandingMetrics
                     * @property {Array.<palexy.streaming.v1.MetricEnum>|null} [enabledInputTargetMetrics] Company enabledInputTargetMetrics
                     * @property {Array.<palexy.streaming.v1.MetricEnum>|null} [enabledDisplayTargetMetrics] Company enabledDisplayTargetMetrics
                     * @property {Array.<string>|null} [enabledGenerateInsightTypes] Company enabledGenerateInsightTypes
                     * @property {Array.<string>|null} [enabledDisplayInsightTypes] Company enabledDisplayInsightTypes
                     * @property {Array.<palexy.streaming.v1.MetricEnum>|null} [threeWeeksTrendInsightMetrics] Company threeWeeksTrendInsightMetrics
                     * @property {Array.<palexy.streaming.v1.ScheduledTaskType>|null} [excludedScheduledTaskTypes] Company excludedScheduledTaskTypes
                     * @property {string|null} [videoGroupSizeBuckets] Company videoGroupSizeBuckets
                     * @property {Array.<palexy.streaming.v1.ICriticalHoursOfDayOfWeek>|null} [criticalHoursOfDaysOfWeek] Company criticalHoursOfDaysOfWeek
                     * @property {boolean|null} [posStaffSyncEnabled] Company posStaffSyncEnabled
                     * @property {boolean|null} [storeRelationshipEnabled] Company storeRelationshipEnabled
                     * @property {boolean|null} [discountMetricsEnabled] Company discountMetricsEnabled
                     * @property {string|null} [discountDistributionBuckets] Company discountDistributionBuckets
                     * @property {boolean|null} [includeReturningItemsForDiscountAnalysis] Company includeReturningItemsForDiscountAnalysis
                     * @property {palexy.streaming.v1.IAccount|null} [ownerAccount] Company ownerAccount
                     * @property {Array.<string>|null} [storeTimezones] Company storeTimezones
                     */
    
                    /**
                     * Constructs a new Company.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Company.
                     * @implements ICompany
                     * @constructor
                     * @param {palexy.streaming.v1.ICompany=} [properties] Properties to set
                     */
                    function Company(properties) {
                        this.enabledStoreEventTypes = [];
                        this.storeSnapshotScheduledTimes = [];
                        this.weekends = [];
                        this.defaultLandingMetrics = [];
                        this.enabledInputTargetMetrics = [];
                        this.enabledDisplayTargetMetrics = [];
                        this.enabledGenerateInsightTypes = [];
                        this.enabledDisplayInsightTypes = [];
                        this.threeWeeksTrendInsightMetrics = [];
                        this.excludedScheduledTaskTypes = [];
                        this.criticalHoursOfDaysOfWeek = [];
                        this.storeTimezones = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Company id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Company name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.name = "";
    
                    /**
                     * Company transformationMatrixEnabled.
                     * @member {boolean} transformationMatrixEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.transformationMatrixEnabled = false;
    
                    /**
                     * Company logicalLayoutEnabled.
                     * @member {boolean} logicalLayoutEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.logicalLayoutEnabled = false;
    
                    /**
                     * Company awsBucket.
                     * @member {string} awsBucket
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.awsBucket = "";
    
                    /**
                     * Company gcsBucket.
                     * @member {string} gcsBucket
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.gcsBucket = "";
    
                    /**
                     * Company companyCode.
                     * @member {string} companyCode
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.companyCode = "";
    
                    /**
                     * Company partnerCode.
                     * @member {string} partnerCode
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.partnerCode = "";
    
                    /**
                     * Company description.
                     * @member {string} description
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.description = "";
    
                    /**
                     * Company entranceCounting.
                     * @member {boolean} entranceCounting
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.entranceCounting = false;
    
                    /**
                     * Company entranceKeyword.
                     * @member {string} entranceKeyword
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.entranceKeyword = "";
    
                    /**
                     * Company dwelltimeBuckets.
                     * @member {string} dwelltimeBuckets
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.dwelltimeBuckets = "";
    
                    /**
                     * Company ageRanges.
                     * @member {string} ageRanges
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.ageRanges = "";
    
                    /**
                     * Company sectionVisitDurationLimit.
                     * @member {number} sectionVisitDurationLimit
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.sectionVisitDurationLimit = 0;
    
                    /**
                     * Company dwelltimeMetricDisabled.
                     * @member {boolean} dwelltimeMetricDisabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.dwelltimeMetricDisabled = false;
    
                    /**
                     * Company interactionMetricDisabled.
                     * @member {boolean} interactionMetricDisabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.interactionMetricDisabled = false;
    
                    /**
                     * Company transactionMetricDisabled.
                     * @member {boolean} transactionMetricDisabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.transactionMetricDisabled = false;
    
                    /**
                     * Company groupMetricEnabled.
                     * @member {boolean} groupMetricEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.groupMetricEnabled = false;
    
                    /**
                     * Company lyingMetricEnabled.
                     * @member {boolean} lyingMetricEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.lyingMetricEnabled = false;
    
                    /**
                     * Company avgDwelltimeMetricDisabled.
                     * @member {boolean} avgDwelltimeMetricDisabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.avgDwelltimeMetricDisabled = false;
    
                    /**
                     * Company layoutViewDisabled.
                     * @member {boolean} layoutViewDisabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.layoutViewDisabled = false;
    
                    /**
                     * Company topSectionsViewDisabled.
                     * @member {boolean} topSectionsViewDisabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.topSectionsViewDisabled = false;
    
                    /**
                     * Company dailyExport.
                     * @member {boolean} dailyExport
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.dailyExport = false;
    
                    /**
                     * Company lastExportTimeMs.
                     * @member {number} lastExportTimeMs
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.lastExportTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Company exportBucket.
                     * @member {string} exportBucket
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.exportBucket = "";
    
                    /**
                     * Company exportNotificationEmails.
                     * @member {string} exportNotificationEmails
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.exportNotificationEmails = "";
    
                    /**
                     * Company exportNotifyUrl.
                     * @member {string} exportNotifyUrl
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.exportNotifyUrl = "";
    
                    /**
                     * Company integrationUsername.
                     * @member {string} integrationUsername
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.integrationUsername = "";
    
                    /**
                     * Company integrationPassword.
                     * @member {string} integrationPassword
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.integrationPassword = "";
    
                    /**
                     * Company createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Company lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Company interactionLevelMetricEnabled.
                     * @member {boolean} interactionLevelMetricEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.interactionLevelMetricEnabled = false;
    
                    /**
                     * Company visitorOccupancyMetricEnabled.
                     * @member {boolean} visitorOccupancyMetricEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.visitorOccupancyMetricEnabled = false;
    
                    /**
                     * Company exportStorageProvider.
                     * @member {string} exportStorageProvider
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.exportStorageProvider = "";
    
                    /**
                     * Company exportBucketRegion.
                     * @member {string} exportBucketRegion
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.exportBucketRegion = "";
    
                    /**
                     * Company exportAccessKey.
                     * @member {string} exportAccessKey
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.exportAccessKey = "";
    
                    /**
                     * Company exportSecretKey.
                     * @member {string} exportSecretKey
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.exportSecretKey = "";
    
                    /**
                     * Company rawIcaStorageProvider.
                     * @member {palexy.streaming.v1.RawIcaStorageProvider} rawIcaStorageProvider
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.rawIcaStorageProvider = 0;
    
                    /**
                     * Company rawIcaBucketName.
                     * @member {string} rawIcaBucketName
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.rawIcaBucketName = "";
    
                    /**
                     * Company exportPosMatchingResultEnabled.
                     * @member {boolean} exportPosMatchingResultEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.exportPosMatchingResultEnabled = false;
    
                    /**
                     * Company usePosData.
                     * @member {boolean} usePosData
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.usePosData = false;
    
                    /**
                     * Company salesAmountRelatedMetricsEnabled.
                     * @member {boolean} salesAmountRelatedMetricsEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.salesAmountRelatedMetricsEnabled = false;
    
                    /**
                     * Company salesItemsRelatedMetricsEnabled.
                     * @member {boolean} salesItemsRelatedMetricsEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.salesItemsRelatedMetricsEnabled = false;
    
                    /**
                     * Company useNetAmount.
                     * @member {boolean} useNetAmount
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.useNetAmount = false;
    
                    /**
                     * Company passbyMetricEnabled.
                     * @member {boolean} passbyMetricEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.passbyMetricEnabled = false;
    
                    /**
                     * Company createMatchingJobOnImportedPosDisabled.
                     * @member {boolean} createMatchingJobOnImportedPosDisabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.createMatchingJobOnImportedPosDisabled = false;
    
                    /**
                     * Company rawIcaMergingBucketName.
                     * @member {string} rawIcaMergingBucketName
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.rawIcaMergingBucketName = "";
    
                    /**
                     * Company nearRealTimeProcessingTimesOfDay.
                     * @member {string} nearRealTimeProcessingTimesOfDay
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.nearRealTimeProcessingTimesOfDay = "";
    
                    /**
                     * Company sectionInteractionMetricEnabled.
                     * @member {boolean} sectionInteractionMetricEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.sectionInteractionMetricEnabled = false;
    
                    /**
                     * Company sectionPosMetricEnabled.
                     * @member {boolean} sectionPosMetricEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.sectionPosMetricEnabled = false;
    
                    /**
                     * Company visitorDirectionFeatureEnabled.
                     * @member {boolean} visitorDirectionFeatureEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.visitorDirectionFeatureEnabled = false;
    
                    /**
                     * Company interactionTimeMetricEnabled.
                     * @member {boolean} interactionTimeMetricEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.interactionTimeMetricEnabled = false;
    
                    /**
                     * Company exportRewriteHistoricalStaffingDays.
                     * @member {number} exportRewriteHistoricalStaffingDays
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.exportRewriteHistoricalStaffingDays = 0;
    
                    /**
                     * Company exportOverwriteExistingFiles.
                     * @member {boolean} exportOverwriteExistingFiles
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.exportOverwriteExistingFiles = false;
    
                    /**
                     * Company mfaRequired.
                     * @member {boolean} mfaRequired
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.mfaRequired = false;
    
                    /**
                     * Company industry.
                     * @member {palexy.streaming.v1.Company.Industry} industry
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.industry = 0;
    
                    /**
                     * Company useSalesTransAsNetSalesTrans.
                     * @member {boolean} useSalesTransAsNetSalesTrans
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.useSalesTransAsNetSalesTrans = false;
    
                    /**
                     * Company useSalesItemsAsNetSalesItems.
                     * @member {boolean} useSalesItemsAsNetSalesItems
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.useSalesItemsAsNetSalesItems = false;
    
                    /**
                     * Company staffPerformanceAnalysisMetricsEnabled.
                     * @member {boolean} staffPerformanceAnalysisMetricsEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.staffPerformanceAnalysisMetricsEnabled = false;
    
                    /**
                     * Company staffPerformanceAnalysisStaffingMetricsEnabled.
                     * @member {boolean} staffPerformanceAnalysisStaffingMetricsEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.staffPerformanceAnalysisStaffingMetricsEnabled = false;
    
                    /**
                     * Company enabledStoreEventTypes.
                     * @member {Array.<palexy.streaming.v1.StoreEventType>} enabledStoreEventTypes
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.enabledStoreEventTypes = $util.emptyArray;
    
                    /**
                     * Company estimatedTrafficMetricsEnabled.
                     * @member {boolean} estimatedTrafficMetricsEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.estimatedTrafficMetricsEnabled = false;
    
                    /**
                     * Company enableStoreSnapshot.
                     * @member {boolean} enableStoreSnapshot
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.enableStoreSnapshot = false;
    
                    /**
                     * Company enableForecast.
                     * @member {boolean} enableForecast
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.enableForecast = false;
    
                    /**
                     * Company enableTargetTrackingForecast.
                     * @member {boolean} enableTargetTrackingForecast
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.enableTargetTrackingForecast = false;
    
                    /**
                     * Company strictReportConsistencyEnabled.
                     * @member {boolean} strictReportConsistencyEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.strictReportConsistencyEnabled = false;
    
                    /**
                     * Company storeSnapshotScheduledTimes.
                     * @member {Array.<string>} storeSnapshotScheduledTimes
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.storeSnapshotScheduledTimes = $util.emptyArray;
    
                    /**
                     * Company storeOpenTimeBufferInSeconds.
                     * @member {number} storeOpenTimeBufferInSeconds
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.storeOpenTimeBufferInSeconds = 0;
    
                    /**
                     * Company storeCloseTimeBufferInSeconds.
                     * @member {number} storeCloseTimeBufferInSeconds
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.storeCloseTimeBufferInSeconds = 0;
    
                    /**
                     * Company startOfWeek.
                     * @member {number} startOfWeek
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.startOfWeek = 0;
    
                    /**
                     * Company weekends.
                     * @member {Array.<number>} weekends
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.weekends = $util.emptyArray;
    
                    /**
                     * Company storeSnapshotRetentionDays.
                     * @member {number} storeSnapshotRetentionDays
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.storeSnapshotRetentionDays = 0;
    
                    /**
                     * Company onTimeGreetingThresholdSec.
                     * @member {number} onTimeGreetingThresholdSec
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.onTimeGreetingThresholdSec = 0;
    
                    /**
                     * Company productSalesAnalysisEnabled.
                     * @member {boolean} productSalesAnalysisEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.productSalesAnalysisEnabled = false;
    
                    /**
                     * Company defaultLandingMetrics.
                     * @member {Array.<palexy.streaming.v1.MetricEnum>} defaultLandingMetrics
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.defaultLandingMetrics = $util.emptyArray;
    
                    /**
                     * Company enabledInputTargetMetrics.
                     * @member {Array.<palexy.streaming.v1.MetricEnum>} enabledInputTargetMetrics
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.enabledInputTargetMetrics = $util.emptyArray;
    
                    /**
                     * Company enabledDisplayTargetMetrics.
                     * @member {Array.<palexy.streaming.v1.MetricEnum>} enabledDisplayTargetMetrics
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.enabledDisplayTargetMetrics = $util.emptyArray;
    
                    /**
                     * Company enabledGenerateInsightTypes.
                     * @member {Array.<string>} enabledGenerateInsightTypes
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.enabledGenerateInsightTypes = $util.emptyArray;
    
                    /**
                     * Company enabledDisplayInsightTypes.
                     * @member {Array.<string>} enabledDisplayInsightTypes
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.enabledDisplayInsightTypes = $util.emptyArray;
    
                    /**
                     * Company threeWeeksTrendInsightMetrics.
                     * @member {Array.<palexy.streaming.v1.MetricEnum>} threeWeeksTrendInsightMetrics
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.threeWeeksTrendInsightMetrics = $util.emptyArray;
    
                    /**
                     * Company excludedScheduledTaskTypes.
                     * @member {Array.<palexy.streaming.v1.ScheduledTaskType>} excludedScheduledTaskTypes
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.excludedScheduledTaskTypes = $util.emptyArray;
    
                    /**
                     * Company videoGroupSizeBuckets.
                     * @member {string} videoGroupSizeBuckets
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.videoGroupSizeBuckets = "";
    
                    /**
                     * Company criticalHoursOfDaysOfWeek.
                     * @member {Array.<palexy.streaming.v1.ICriticalHoursOfDayOfWeek>} criticalHoursOfDaysOfWeek
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.criticalHoursOfDaysOfWeek = $util.emptyArray;
    
                    /**
                     * Company posStaffSyncEnabled.
                     * @member {boolean} posStaffSyncEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.posStaffSyncEnabled = false;
    
                    /**
                     * Company storeRelationshipEnabled.
                     * @member {boolean} storeRelationshipEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.storeRelationshipEnabled = false;
    
                    /**
                     * Company discountMetricsEnabled.
                     * @member {boolean} discountMetricsEnabled
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.discountMetricsEnabled = false;
    
                    /**
                     * Company discountDistributionBuckets.
                     * @member {string} discountDistributionBuckets
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.discountDistributionBuckets = "";
    
                    /**
                     * Company includeReturningItemsForDiscountAnalysis.
                     * @member {boolean} includeReturningItemsForDiscountAnalysis
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.includeReturningItemsForDiscountAnalysis = false;
    
                    /**
                     * Company ownerAccount.
                     * @member {palexy.streaming.v1.IAccount|null|undefined} ownerAccount
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.ownerAccount = null;
    
                    /**
                     * Company storeTimezones.
                     * @member {Array.<string>} storeTimezones
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     */
                    Company.prototype.storeTimezones = $util.emptyArray;
    
                    /**
                     * Creates a new Company instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Company
                     * @static
                     * @param {palexy.streaming.v1.ICompany=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Company} Company instance
                     */
                    Company.create = function create(properties) {
                        return new Company(properties);
                    };
    
                    /**
                     * Encodes the specified Company message. Does not implicitly {@link palexy.streaming.v1.Company.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Company
                     * @static
                     * @param {palexy.streaming.v1.ICompany} message Company message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Company.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.transformationMatrixEnabled != null && Object.hasOwnProperty.call(message, "transformationMatrixEnabled"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.transformationMatrixEnabled);
                        if (message.logicalLayoutEnabled != null && Object.hasOwnProperty.call(message, "logicalLayoutEnabled"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.logicalLayoutEnabled);
                        if (message.awsBucket != null && Object.hasOwnProperty.call(message, "awsBucket"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.awsBucket);
                        if (message.gcsBucket != null && Object.hasOwnProperty.call(message, "gcsBucket"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.gcsBucket);
                        if (message.companyCode != null && Object.hasOwnProperty.call(message, "companyCode"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.companyCode);
                        if (message.partnerCode != null && Object.hasOwnProperty.call(message, "partnerCode"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.partnerCode);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.description);
                        if (message.entranceCounting != null && Object.hasOwnProperty.call(message, "entranceCounting"))
                            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.entranceCounting);
                        if (message.entranceKeyword != null && Object.hasOwnProperty.call(message, "entranceKeyword"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.entranceKeyword);
                        if (message.dwelltimeBuckets != null && Object.hasOwnProperty.call(message, "dwelltimeBuckets"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.dwelltimeBuckets);
                        if (message.ageRanges != null && Object.hasOwnProperty.call(message, "ageRanges"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.ageRanges);
                        if (message.sectionVisitDurationLimit != null && Object.hasOwnProperty.call(message, "sectionVisitDurationLimit"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.sectionVisitDurationLimit);
                        if (message.dwelltimeMetricDisabled != null && Object.hasOwnProperty.call(message, "dwelltimeMetricDisabled"))
                            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.dwelltimeMetricDisabled);
                        if (message.interactionMetricDisabled != null && Object.hasOwnProperty.call(message, "interactionMetricDisabled"))
                            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.interactionMetricDisabled);
                        if (message.transactionMetricDisabled != null && Object.hasOwnProperty.call(message, "transactionMetricDisabled"))
                            writer.uint32(/* id 17, wireType 0 =*/136).bool(message.transactionMetricDisabled);
                        if (message.groupMetricEnabled != null && Object.hasOwnProperty.call(message, "groupMetricEnabled"))
                            writer.uint32(/* id 18, wireType 0 =*/144).bool(message.groupMetricEnabled);
                        if (message.lyingMetricEnabled != null && Object.hasOwnProperty.call(message, "lyingMetricEnabled"))
                            writer.uint32(/* id 19, wireType 0 =*/152).bool(message.lyingMetricEnabled);
                        if (message.avgDwelltimeMetricDisabled != null && Object.hasOwnProperty.call(message, "avgDwelltimeMetricDisabled"))
                            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.avgDwelltimeMetricDisabled);
                        if (message.layoutViewDisabled != null && Object.hasOwnProperty.call(message, "layoutViewDisabled"))
                            writer.uint32(/* id 21, wireType 0 =*/168).bool(message.layoutViewDisabled);
                        if (message.topSectionsViewDisabled != null && Object.hasOwnProperty.call(message, "topSectionsViewDisabled"))
                            writer.uint32(/* id 22, wireType 0 =*/176).bool(message.topSectionsViewDisabled);
                        if (message.dailyExport != null && Object.hasOwnProperty.call(message, "dailyExport"))
                            writer.uint32(/* id 23, wireType 0 =*/184).bool(message.dailyExport);
                        if (message.lastExportTimeMs != null && Object.hasOwnProperty.call(message, "lastExportTimeMs"))
                            writer.uint32(/* id 24, wireType 0 =*/192).int64(message.lastExportTimeMs);
                        if (message.exportBucket != null && Object.hasOwnProperty.call(message, "exportBucket"))
                            writer.uint32(/* id 25, wireType 2 =*/202).string(message.exportBucket);
                        if (message.exportNotificationEmails != null && Object.hasOwnProperty.call(message, "exportNotificationEmails"))
                            writer.uint32(/* id 26, wireType 2 =*/210).string(message.exportNotificationEmails);
                        if (message.exportNotifyUrl != null && Object.hasOwnProperty.call(message, "exportNotifyUrl"))
                            writer.uint32(/* id 27, wireType 2 =*/218).string(message.exportNotifyUrl);
                        if (message.integrationUsername != null && Object.hasOwnProperty.call(message, "integrationUsername"))
                            writer.uint32(/* id 28, wireType 2 =*/226).string(message.integrationUsername);
                        if (message.rawIcaBucketName != null && Object.hasOwnProperty.call(message, "rawIcaBucketName"))
                            writer.uint32(/* id 29, wireType 2 =*/234).string(message.rawIcaBucketName);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 30, wireType 0 =*/240).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 31, wireType 0 =*/248).int64(message.lastUpdateTimeMs);
                        if (message.interactionLevelMetricEnabled != null && Object.hasOwnProperty.call(message, "interactionLevelMetricEnabled"))
                            writer.uint32(/* id 32, wireType 0 =*/256).bool(message.interactionLevelMetricEnabled);
                        if (message.visitorOccupancyMetricEnabled != null && Object.hasOwnProperty.call(message, "visitorOccupancyMetricEnabled"))
                            writer.uint32(/* id 33, wireType 0 =*/264).bool(message.visitorOccupancyMetricEnabled);
                        if (message.exportStorageProvider != null && Object.hasOwnProperty.call(message, "exportStorageProvider"))
                            writer.uint32(/* id 34, wireType 2 =*/274).string(message.exportStorageProvider);
                        if (message.exportBucketRegion != null && Object.hasOwnProperty.call(message, "exportBucketRegion"))
                            writer.uint32(/* id 35, wireType 2 =*/282).string(message.exportBucketRegion);
                        if (message.exportAccessKey != null && Object.hasOwnProperty.call(message, "exportAccessKey"))
                            writer.uint32(/* id 36, wireType 2 =*/290).string(message.exportAccessKey);
                        if (message.exportSecretKey != null && Object.hasOwnProperty.call(message, "exportSecretKey"))
                            writer.uint32(/* id 37, wireType 2 =*/298).string(message.exportSecretKey);
                        if (message.rawIcaStorageProvider != null && Object.hasOwnProperty.call(message, "rawIcaStorageProvider"))
                            writer.uint32(/* id 38, wireType 0 =*/304).int32(message.rawIcaStorageProvider);
                        if (message.integrationPassword != null && Object.hasOwnProperty.call(message, "integrationPassword"))
                            writer.uint32(/* id 39, wireType 2 =*/314).string(message.integrationPassword);
                        if (message.exportPosMatchingResultEnabled != null && Object.hasOwnProperty.call(message, "exportPosMatchingResultEnabled"))
                            writer.uint32(/* id 40, wireType 0 =*/320).bool(message.exportPosMatchingResultEnabled);
                        if (message.usePosData != null && Object.hasOwnProperty.call(message, "usePosData"))
                            writer.uint32(/* id 41, wireType 0 =*/328).bool(message.usePosData);
                        if (message.salesAmountRelatedMetricsEnabled != null && Object.hasOwnProperty.call(message, "salesAmountRelatedMetricsEnabled"))
                            writer.uint32(/* id 42, wireType 0 =*/336).bool(message.salesAmountRelatedMetricsEnabled);
                        if (message.salesItemsRelatedMetricsEnabled != null && Object.hasOwnProperty.call(message, "salesItemsRelatedMetricsEnabled"))
                            writer.uint32(/* id 43, wireType 0 =*/344).bool(message.salesItemsRelatedMetricsEnabled);
                        if (message.useNetAmount != null && Object.hasOwnProperty.call(message, "useNetAmount"))
                            writer.uint32(/* id 44, wireType 0 =*/352).bool(message.useNetAmount);
                        if (message.passbyMetricEnabled != null && Object.hasOwnProperty.call(message, "passbyMetricEnabled"))
                            writer.uint32(/* id 45, wireType 0 =*/360).bool(message.passbyMetricEnabled);
                        if (message.createMatchingJobOnImportedPosDisabled != null && Object.hasOwnProperty.call(message, "createMatchingJobOnImportedPosDisabled"))
                            writer.uint32(/* id 46, wireType 0 =*/368).bool(message.createMatchingJobOnImportedPosDisabled);
                        if (message.rawIcaMergingBucketName != null && Object.hasOwnProperty.call(message, "rawIcaMergingBucketName"))
                            writer.uint32(/* id 47, wireType 2 =*/378).string(message.rawIcaMergingBucketName);
                        if (message.nearRealTimeProcessingTimesOfDay != null && Object.hasOwnProperty.call(message, "nearRealTimeProcessingTimesOfDay"))
                            writer.uint32(/* id 48, wireType 2 =*/386).string(message.nearRealTimeProcessingTimesOfDay);
                        if (message.sectionInteractionMetricEnabled != null && Object.hasOwnProperty.call(message, "sectionInteractionMetricEnabled"))
                            writer.uint32(/* id 49, wireType 0 =*/392).bool(message.sectionInteractionMetricEnabled);
                        if (message.sectionPosMetricEnabled != null && Object.hasOwnProperty.call(message, "sectionPosMetricEnabled"))
                            writer.uint32(/* id 50, wireType 0 =*/400).bool(message.sectionPosMetricEnabled);
                        if (message.visitorDirectionFeatureEnabled != null && Object.hasOwnProperty.call(message, "visitorDirectionFeatureEnabled"))
                            writer.uint32(/* id 51, wireType 0 =*/408).bool(message.visitorDirectionFeatureEnabled);
                        if (message.interactionTimeMetricEnabled != null && Object.hasOwnProperty.call(message, "interactionTimeMetricEnabled"))
                            writer.uint32(/* id 52, wireType 0 =*/416).bool(message.interactionTimeMetricEnabled);
                        if (message.exportRewriteHistoricalStaffingDays != null && Object.hasOwnProperty.call(message, "exportRewriteHistoricalStaffingDays"))
                            writer.uint32(/* id 53, wireType 0 =*/424).int32(message.exportRewriteHistoricalStaffingDays);
                        if (message.exportOverwriteExistingFiles != null && Object.hasOwnProperty.call(message, "exportOverwriteExistingFiles"))
                            writer.uint32(/* id 54, wireType 0 =*/432).bool(message.exportOverwriteExistingFiles);
                        if (message.mfaRequired != null && Object.hasOwnProperty.call(message, "mfaRequired"))
                            writer.uint32(/* id 55, wireType 0 =*/440).bool(message.mfaRequired);
                        if (message.industry != null && Object.hasOwnProperty.call(message, "industry"))
                            writer.uint32(/* id 56, wireType 0 =*/448).int32(message.industry);
                        if (message.useSalesTransAsNetSalesTrans != null && Object.hasOwnProperty.call(message, "useSalesTransAsNetSalesTrans"))
                            writer.uint32(/* id 57, wireType 0 =*/456).bool(message.useSalesTransAsNetSalesTrans);
                        if (message.useSalesItemsAsNetSalesItems != null && Object.hasOwnProperty.call(message, "useSalesItemsAsNetSalesItems"))
                            writer.uint32(/* id 58, wireType 0 =*/464).bool(message.useSalesItemsAsNetSalesItems);
                        if (message.staffPerformanceAnalysisMetricsEnabled != null && Object.hasOwnProperty.call(message, "staffPerformanceAnalysisMetricsEnabled"))
                            writer.uint32(/* id 59, wireType 0 =*/472).bool(message.staffPerformanceAnalysisMetricsEnabled);
                        if (message.staffPerformanceAnalysisStaffingMetricsEnabled != null && Object.hasOwnProperty.call(message, "staffPerformanceAnalysisStaffingMetricsEnabled"))
                            writer.uint32(/* id 60, wireType 0 =*/480).bool(message.staffPerformanceAnalysisStaffingMetricsEnabled);
                        if (message.enabledStoreEventTypes != null && message.enabledStoreEventTypes.length) {
                            writer.uint32(/* id 61, wireType 2 =*/490).fork();
                            for (var i = 0; i < message.enabledStoreEventTypes.length; ++i)
                                writer.int32(message.enabledStoreEventTypes[i]);
                            writer.ldelim();
                        }
                        if (message.estimatedTrafficMetricsEnabled != null && Object.hasOwnProperty.call(message, "estimatedTrafficMetricsEnabled"))
                            writer.uint32(/* id 62, wireType 0 =*/496).bool(message.estimatedTrafficMetricsEnabled);
                        if (message.enableStoreSnapshot != null && Object.hasOwnProperty.call(message, "enableStoreSnapshot"))
                            writer.uint32(/* id 63, wireType 0 =*/504).bool(message.enableStoreSnapshot);
                        if (message.enableForecast != null && Object.hasOwnProperty.call(message, "enableForecast"))
                            writer.uint32(/* id 64, wireType 0 =*/512).bool(message.enableForecast);
                        if (message.enableTargetTrackingForecast != null && Object.hasOwnProperty.call(message, "enableTargetTrackingForecast"))
                            writer.uint32(/* id 65, wireType 0 =*/520).bool(message.enableTargetTrackingForecast);
                        if (message.strictReportConsistencyEnabled != null && Object.hasOwnProperty.call(message, "strictReportConsistencyEnabled"))
                            writer.uint32(/* id 66, wireType 0 =*/528).bool(message.strictReportConsistencyEnabled);
                        if (message.storeSnapshotScheduledTimes != null && message.storeSnapshotScheduledTimes.length)
                            for (var i = 0; i < message.storeSnapshotScheduledTimes.length; ++i)
                                writer.uint32(/* id 67, wireType 2 =*/538).string(message.storeSnapshotScheduledTimes[i]);
                        if (message.storeOpenTimeBufferInSeconds != null && Object.hasOwnProperty.call(message, "storeOpenTimeBufferInSeconds"))
                            writer.uint32(/* id 68, wireType 0 =*/544).int32(message.storeOpenTimeBufferInSeconds);
                        if (message.storeCloseTimeBufferInSeconds != null && Object.hasOwnProperty.call(message, "storeCloseTimeBufferInSeconds"))
                            writer.uint32(/* id 69, wireType 0 =*/552).int32(message.storeCloseTimeBufferInSeconds);
                        if (message.startOfWeek != null && Object.hasOwnProperty.call(message, "startOfWeek"))
                            writer.uint32(/* id 70, wireType 0 =*/560).int32(message.startOfWeek);
                        if (message.weekends != null && message.weekends.length) {
                            writer.uint32(/* id 71, wireType 2 =*/570).fork();
                            for (var i = 0; i < message.weekends.length; ++i)
                                writer.int32(message.weekends[i]);
                            writer.ldelim();
                        }
                        if (message.storeSnapshotRetentionDays != null && Object.hasOwnProperty.call(message, "storeSnapshotRetentionDays"))
                            writer.uint32(/* id 72, wireType 0 =*/576).int32(message.storeSnapshotRetentionDays);
                        if (message.onTimeGreetingThresholdSec != null && Object.hasOwnProperty.call(message, "onTimeGreetingThresholdSec"))
                            writer.uint32(/* id 73, wireType 0 =*/584).int32(message.onTimeGreetingThresholdSec);
                        if (message.productSalesAnalysisEnabled != null && Object.hasOwnProperty.call(message, "productSalesAnalysisEnabled"))
                            writer.uint32(/* id 74, wireType 0 =*/592).bool(message.productSalesAnalysisEnabled);
                        if (message.defaultLandingMetrics != null && message.defaultLandingMetrics.length) {
                            writer.uint32(/* id 75, wireType 2 =*/602).fork();
                            for (var i = 0; i < message.defaultLandingMetrics.length; ++i)
                                writer.int32(message.defaultLandingMetrics[i]);
                            writer.ldelim();
                        }
                        if (message.enabledInputTargetMetrics != null && message.enabledInputTargetMetrics.length) {
                            writer.uint32(/* id 76, wireType 2 =*/610).fork();
                            for (var i = 0; i < message.enabledInputTargetMetrics.length; ++i)
                                writer.int32(message.enabledInputTargetMetrics[i]);
                            writer.ldelim();
                        }
                        if (message.enabledDisplayTargetMetrics != null && message.enabledDisplayTargetMetrics.length) {
                            writer.uint32(/* id 77, wireType 2 =*/618).fork();
                            for (var i = 0; i < message.enabledDisplayTargetMetrics.length; ++i)
                                writer.int32(message.enabledDisplayTargetMetrics[i]);
                            writer.ldelim();
                        }
                        if (message.enabledGenerateInsightTypes != null && message.enabledGenerateInsightTypes.length)
                            for (var i = 0; i < message.enabledGenerateInsightTypes.length; ++i)
                                writer.uint32(/* id 78, wireType 2 =*/626).string(message.enabledGenerateInsightTypes[i]);
                        if (message.enabledDisplayInsightTypes != null && message.enabledDisplayInsightTypes.length)
                            for (var i = 0; i < message.enabledDisplayInsightTypes.length; ++i)
                                writer.uint32(/* id 79, wireType 2 =*/634).string(message.enabledDisplayInsightTypes[i]);
                        if (message.threeWeeksTrendInsightMetrics != null && message.threeWeeksTrendInsightMetrics.length) {
                            writer.uint32(/* id 80, wireType 2 =*/642).fork();
                            for (var i = 0; i < message.threeWeeksTrendInsightMetrics.length; ++i)
                                writer.int32(message.threeWeeksTrendInsightMetrics[i]);
                            writer.ldelim();
                        }
                        if (message.excludedScheduledTaskTypes != null && message.excludedScheduledTaskTypes.length) {
                            writer.uint32(/* id 81, wireType 2 =*/650).fork();
                            for (var i = 0; i < message.excludedScheduledTaskTypes.length; ++i)
                                writer.int32(message.excludedScheduledTaskTypes[i]);
                            writer.ldelim();
                        }
                        if (message.videoGroupSizeBuckets != null && Object.hasOwnProperty.call(message, "videoGroupSizeBuckets"))
                            writer.uint32(/* id 82, wireType 2 =*/658).string(message.videoGroupSizeBuckets);
                        if (message.criticalHoursOfDaysOfWeek != null && message.criticalHoursOfDaysOfWeek.length)
                            for (var i = 0; i < message.criticalHoursOfDaysOfWeek.length; ++i)
                                $root.palexy.streaming.v1.CriticalHoursOfDayOfWeek.encode(message.criticalHoursOfDaysOfWeek[i], writer.uint32(/* id 83, wireType 2 =*/666).fork()).ldelim();
                        if (message.posStaffSyncEnabled != null && Object.hasOwnProperty.call(message, "posStaffSyncEnabled"))
                            writer.uint32(/* id 84, wireType 0 =*/672).bool(message.posStaffSyncEnabled);
                        if (message.storeRelationshipEnabled != null && Object.hasOwnProperty.call(message, "storeRelationshipEnabled"))
                            writer.uint32(/* id 85, wireType 0 =*/680).bool(message.storeRelationshipEnabled);
                        if (message.discountMetricsEnabled != null && Object.hasOwnProperty.call(message, "discountMetricsEnabled"))
                            writer.uint32(/* id 86, wireType 0 =*/688).bool(message.discountMetricsEnabled);
                        if (message.discountDistributionBuckets != null && Object.hasOwnProperty.call(message, "discountDistributionBuckets"))
                            writer.uint32(/* id 87, wireType 2 =*/698).string(message.discountDistributionBuckets);
                        if (message.includeReturningItemsForDiscountAnalysis != null && Object.hasOwnProperty.call(message, "includeReturningItemsForDiscountAnalysis"))
                            writer.uint32(/* id 88, wireType 0 =*/704).bool(message.includeReturningItemsForDiscountAnalysis);
                        if (message.ownerAccount != null && Object.hasOwnProperty.call(message, "ownerAccount"))
                            $root.palexy.streaming.v1.Account.encode(message.ownerAccount, writer.uint32(/* id 1000, wireType 2 =*/8002).fork()).ldelim();
                        if (message.storeTimezones != null && message.storeTimezones.length)
                            for (var i = 0; i < message.storeTimezones.length; ++i)
                                writer.uint32(/* id 1001, wireType 2 =*/8010).string(message.storeTimezones[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Company message, length delimited. Does not implicitly {@link palexy.streaming.v1.Company.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Company
                     * @static
                     * @param {palexy.streaming.v1.ICompany} message Company message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Company.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Company message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Company
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Company} Company
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Company.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Company();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.transformationMatrixEnabled = reader.bool();
                                break;
                            case 4:
                                message.logicalLayoutEnabled = reader.bool();
                                break;
                            case 5:
                                message.awsBucket = reader.string();
                                break;
                            case 6:
                                message.gcsBucket = reader.string();
                                break;
                            case 7:
                                message.companyCode = reader.string();
                                break;
                            case 8:
                                message.partnerCode = reader.string();
                                break;
                            case 9:
                                message.description = reader.string();
                                break;
                            case 10:
                                message.entranceCounting = reader.bool();
                                break;
                            case 11:
                                message.entranceKeyword = reader.string();
                                break;
                            case 12:
                                message.dwelltimeBuckets = reader.string();
                                break;
                            case 13:
                                message.ageRanges = reader.string();
                                break;
                            case 14:
                                message.sectionVisitDurationLimit = reader.int32();
                                break;
                            case 15:
                                message.dwelltimeMetricDisabled = reader.bool();
                                break;
                            case 16:
                                message.interactionMetricDisabled = reader.bool();
                                break;
                            case 17:
                                message.transactionMetricDisabled = reader.bool();
                                break;
                            case 18:
                                message.groupMetricEnabled = reader.bool();
                                break;
                            case 19:
                                message.lyingMetricEnabled = reader.bool();
                                break;
                            case 20:
                                message.avgDwelltimeMetricDisabled = reader.bool();
                                break;
                            case 21:
                                message.layoutViewDisabled = reader.bool();
                                break;
                            case 22:
                                message.topSectionsViewDisabled = reader.bool();
                                break;
                            case 23:
                                message.dailyExport = reader.bool();
                                break;
                            case 24:
                                message.lastExportTimeMs = reader.int64();
                                break;
                            case 25:
                                message.exportBucket = reader.string();
                                break;
                            case 26:
                                message.exportNotificationEmails = reader.string();
                                break;
                            case 27:
                                message.exportNotifyUrl = reader.string();
                                break;
                            case 28:
                                message.integrationUsername = reader.string();
                                break;
                            case 39:
                                message.integrationPassword = reader.string();
                                break;
                            case 30:
                                message.createTimeMs = reader.int64();
                                break;
                            case 31:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 32:
                                message.interactionLevelMetricEnabled = reader.bool();
                                break;
                            case 33:
                                message.visitorOccupancyMetricEnabled = reader.bool();
                                break;
                            case 34:
                                message.exportStorageProvider = reader.string();
                                break;
                            case 35:
                                message.exportBucketRegion = reader.string();
                                break;
                            case 36:
                                message.exportAccessKey = reader.string();
                                break;
                            case 37:
                                message.exportSecretKey = reader.string();
                                break;
                            case 38:
                                message.rawIcaStorageProvider = reader.int32();
                                break;
                            case 29:
                                message.rawIcaBucketName = reader.string();
                                break;
                            case 40:
                                message.exportPosMatchingResultEnabled = reader.bool();
                                break;
                            case 41:
                                message.usePosData = reader.bool();
                                break;
                            case 42:
                                message.salesAmountRelatedMetricsEnabled = reader.bool();
                                break;
                            case 43:
                                message.salesItemsRelatedMetricsEnabled = reader.bool();
                                break;
                            case 44:
                                message.useNetAmount = reader.bool();
                                break;
                            case 45:
                                message.passbyMetricEnabled = reader.bool();
                                break;
                            case 46:
                                message.createMatchingJobOnImportedPosDisabled = reader.bool();
                                break;
                            case 47:
                                message.rawIcaMergingBucketName = reader.string();
                                break;
                            case 48:
                                message.nearRealTimeProcessingTimesOfDay = reader.string();
                                break;
                            case 49:
                                message.sectionInteractionMetricEnabled = reader.bool();
                                break;
                            case 50:
                                message.sectionPosMetricEnabled = reader.bool();
                                break;
                            case 51:
                                message.visitorDirectionFeatureEnabled = reader.bool();
                                break;
                            case 52:
                                message.interactionTimeMetricEnabled = reader.bool();
                                break;
                            case 53:
                                message.exportRewriteHistoricalStaffingDays = reader.int32();
                                break;
                            case 54:
                                message.exportOverwriteExistingFiles = reader.bool();
                                break;
                            case 55:
                                message.mfaRequired = reader.bool();
                                break;
                            case 56:
                                message.industry = reader.int32();
                                break;
                            case 57:
                                message.useSalesTransAsNetSalesTrans = reader.bool();
                                break;
                            case 58:
                                message.useSalesItemsAsNetSalesItems = reader.bool();
                                break;
                            case 59:
                                message.staffPerformanceAnalysisMetricsEnabled = reader.bool();
                                break;
                            case 60:
                                message.staffPerformanceAnalysisStaffingMetricsEnabled = reader.bool();
                                break;
                            case 61:
                                if (!(message.enabledStoreEventTypes && message.enabledStoreEventTypes.length))
                                    message.enabledStoreEventTypes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.enabledStoreEventTypes.push(reader.int32());
                                } else
                                    message.enabledStoreEventTypes.push(reader.int32());
                                break;
                            case 62:
                                message.estimatedTrafficMetricsEnabled = reader.bool();
                                break;
                            case 63:
                                message.enableStoreSnapshot = reader.bool();
                                break;
                            case 64:
                                message.enableForecast = reader.bool();
                                break;
                            case 65:
                                message.enableTargetTrackingForecast = reader.bool();
                                break;
                            case 66:
                                message.strictReportConsistencyEnabled = reader.bool();
                                break;
                            case 67:
                                if (!(message.storeSnapshotScheduledTimes && message.storeSnapshotScheduledTimes.length))
                                    message.storeSnapshotScheduledTimes = [];
                                message.storeSnapshotScheduledTimes.push(reader.string());
                                break;
                            case 68:
                                message.storeOpenTimeBufferInSeconds = reader.int32();
                                break;
                            case 69:
                                message.storeCloseTimeBufferInSeconds = reader.int32();
                                break;
                            case 70:
                                message.startOfWeek = reader.int32();
                                break;
                            case 71:
                                if (!(message.weekends && message.weekends.length))
                                    message.weekends = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.weekends.push(reader.int32());
                                } else
                                    message.weekends.push(reader.int32());
                                break;
                            case 72:
                                message.storeSnapshotRetentionDays = reader.int32();
                                break;
                            case 73:
                                message.onTimeGreetingThresholdSec = reader.int32();
                                break;
                            case 74:
                                message.productSalesAnalysisEnabled = reader.bool();
                                break;
                            case 75:
                                if (!(message.defaultLandingMetrics && message.defaultLandingMetrics.length))
                                    message.defaultLandingMetrics = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.defaultLandingMetrics.push(reader.int32());
                                } else
                                    message.defaultLandingMetrics.push(reader.int32());
                                break;
                            case 76:
                                if (!(message.enabledInputTargetMetrics && message.enabledInputTargetMetrics.length))
                                    message.enabledInputTargetMetrics = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.enabledInputTargetMetrics.push(reader.int32());
                                } else
                                    message.enabledInputTargetMetrics.push(reader.int32());
                                break;
                            case 77:
                                if (!(message.enabledDisplayTargetMetrics && message.enabledDisplayTargetMetrics.length))
                                    message.enabledDisplayTargetMetrics = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.enabledDisplayTargetMetrics.push(reader.int32());
                                } else
                                    message.enabledDisplayTargetMetrics.push(reader.int32());
                                break;
                            case 78:
                                if (!(message.enabledGenerateInsightTypes && message.enabledGenerateInsightTypes.length))
                                    message.enabledGenerateInsightTypes = [];
                                message.enabledGenerateInsightTypes.push(reader.string());
                                break;
                            case 79:
                                if (!(message.enabledDisplayInsightTypes && message.enabledDisplayInsightTypes.length))
                                    message.enabledDisplayInsightTypes = [];
                                message.enabledDisplayInsightTypes.push(reader.string());
                                break;
                            case 80:
                                if (!(message.threeWeeksTrendInsightMetrics && message.threeWeeksTrendInsightMetrics.length))
                                    message.threeWeeksTrendInsightMetrics = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.threeWeeksTrendInsightMetrics.push(reader.int32());
                                } else
                                    message.threeWeeksTrendInsightMetrics.push(reader.int32());
                                break;
                            case 81:
                                if (!(message.excludedScheduledTaskTypes && message.excludedScheduledTaskTypes.length))
                                    message.excludedScheduledTaskTypes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.excludedScheduledTaskTypes.push(reader.int32());
                                } else
                                    message.excludedScheduledTaskTypes.push(reader.int32());
                                break;
                            case 82:
                                message.videoGroupSizeBuckets = reader.string();
                                break;
                            case 83:
                                if (!(message.criticalHoursOfDaysOfWeek && message.criticalHoursOfDaysOfWeek.length))
                                    message.criticalHoursOfDaysOfWeek = [];
                                message.criticalHoursOfDaysOfWeek.push($root.palexy.streaming.v1.CriticalHoursOfDayOfWeek.decode(reader, reader.uint32()));
                                break;
                            case 84:
                                message.posStaffSyncEnabled = reader.bool();
                                break;
                            case 85:
                                message.storeRelationshipEnabled = reader.bool();
                                break;
                            case 86:
                                message.discountMetricsEnabled = reader.bool();
                                break;
                            case 87:
                                message.discountDistributionBuckets = reader.string();
                                break;
                            case 88:
                                message.includeReturningItemsForDiscountAnalysis = reader.bool();
                                break;
                            case 1000:
                                message.ownerAccount = $root.palexy.streaming.v1.Account.decode(reader, reader.uint32());
                                break;
                            case 1001:
                                if (!(message.storeTimezones && message.storeTimezones.length))
                                    message.storeTimezones = [];
                                message.storeTimezones.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Company message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Company
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Company} Company
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Company.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Company message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Company
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Company.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.transformationMatrixEnabled != null && message.hasOwnProperty("transformationMatrixEnabled"))
                            if (typeof message.transformationMatrixEnabled !== "boolean")
                                return "transformationMatrixEnabled: boolean expected";
                        if (message.logicalLayoutEnabled != null && message.hasOwnProperty("logicalLayoutEnabled"))
                            if (typeof message.logicalLayoutEnabled !== "boolean")
                                return "logicalLayoutEnabled: boolean expected";
                        if (message.awsBucket != null && message.hasOwnProperty("awsBucket"))
                            if (!$util.isString(message.awsBucket))
                                return "awsBucket: string expected";
                        if (message.gcsBucket != null && message.hasOwnProperty("gcsBucket"))
                            if (!$util.isString(message.gcsBucket))
                                return "gcsBucket: string expected";
                        if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                            if (!$util.isString(message.companyCode))
                                return "companyCode: string expected";
                        if (message.partnerCode != null && message.hasOwnProperty("partnerCode"))
                            if (!$util.isString(message.partnerCode))
                                return "partnerCode: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.entranceCounting != null && message.hasOwnProperty("entranceCounting"))
                            if (typeof message.entranceCounting !== "boolean")
                                return "entranceCounting: boolean expected";
                        if (message.entranceKeyword != null && message.hasOwnProperty("entranceKeyword"))
                            if (!$util.isString(message.entranceKeyword))
                                return "entranceKeyword: string expected";
                        if (message.dwelltimeBuckets != null && message.hasOwnProperty("dwelltimeBuckets"))
                            if (!$util.isString(message.dwelltimeBuckets))
                                return "dwelltimeBuckets: string expected";
                        if (message.ageRanges != null && message.hasOwnProperty("ageRanges"))
                            if (!$util.isString(message.ageRanges))
                                return "ageRanges: string expected";
                        if (message.sectionVisitDurationLimit != null && message.hasOwnProperty("sectionVisitDurationLimit"))
                            if (!$util.isInteger(message.sectionVisitDurationLimit))
                                return "sectionVisitDurationLimit: integer expected";
                        if (message.dwelltimeMetricDisabled != null && message.hasOwnProperty("dwelltimeMetricDisabled"))
                            if (typeof message.dwelltimeMetricDisabled !== "boolean")
                                return "dwelltimeMetricDisabled: boolean expected";
                        if (message.interactionMetricDisabled != null && message.hasOwnProperty("interactionMetricDisabled"))
                            if (typeof message.interactionMetricDisabled !== "boolean")
                                return "interactionMetricDisabled: boolean expected";
                        if (message.transactionMetricDisabled != null && message.hasOwnProperty("transactionMetricDisabled"))
                            if (typeof message.transactionMetricDisabled !== "boolean")
                                return "transactionMetricDisabled: boolean expected";
                        if (message.groupMetricEnabled != null && message.hasOwnProperty("groupMetricEnabled"))
                            if (typeof message.groupMetricEnabled !== "boolean")
                                return "groupMetricEnabled: boolean expected";
                        if (message.lyingMetricEnabled != null && message.hasOwnProperty("lyingMetricEnabled"))
                            if (typeof message.lyingMetricEnabled !== "boolean")
                                return "lyingMetricEnabled: boolean expected";
                        if (message.avgDwelltimeMetricDisabled != null && message.hasOwnProperty("avgDwelltimeMetricDisabled"))
                            if (typeof message.avgDwelltimeMetricDisabled !== "boolean")
                                return "avgDwelltimeMetricDisabled: boolean expected";
                        if (message.layoutViewDisabled != null && message.hasOwnProperty("layoutViewDisabled"))
                            if (typeof message.layoutViewDisabled !== "boolean")
                                return "layoutViewDisabled: boolean expected";
                        if (message.topSectionsViewDisabled != null && message.hasOwnProperty("topSectionsViewDisabled"))
                            if (typeof message.topSectionsViewDisabled !== "boolean")
                                return "topSectionsViewDisabled: boolean expected";
                        if (message.dailyExport != null && message.hasOwnProperty("dailyExport"))
                            if (typeof message.dailyExport !== "boolean")
                                return "dailyExport: boolean expected";
                        if (message.lastExportTimeMs != null && message.hasOwnProperty("lastExportTimeMs"))
                            if (!$util.isInteger(message.lastExportTimeMs) && !(message.lastExportTimeMs && $util.isInteger(message.lastExportTimeMs.low) && $util.isInteger(message.lastExportTimeMs.high)))
                                return "lastExportTimeMs: integer|Long expected";
                        if (message.exportBucket != null && message.hasOwnProperty("exportBucket"))
                            if (!$util.isString(message.exportBucket))
                                return "exportBucket: string expected";
                        if (message.exportNotificationEmails != null && message.hasOwnProperty("exportNotificationEmails"))
                            if (!$util.isString(message.exportNotificationEmails))
                                return "exportNotificationEmails: string expected";
                        if (message.exportNotifyUrl != null && message.hasOwnProperty("exportNotifyUrl"))
                            if (!$util.isString(message.exportNotifyUrl))
                                return "exportNotifyUrl: string expected";
                        if (message.integrationUsername != null && message.hasOwnProperty("integrationUsername"))
                            if (!$util.isString(message.integrationUsername))
                                return "integrationUsername: string expected";
                        if (message.integrationPassword != null && message.hasOwnProperty("integrationPassword"))
                            if (!$util.isString(message.integrationPassword))
                                return "integrationPassword: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.interactionLevelMetricEnabled != null && message.hasOwnProperty("interactionLevelMetricEnabled"))
                            if (typeof message.interactionLevelMetricEnabled !== "boolean")
                                return "interactionLevelMetricEnabled: boolean expected";
                        if (message.visitorOccupancyMetricEnabled != null && message.hasOwnProperty("visitorOccupancyMetricEnabled"))
                            if (typeof message.visitorOccupancyMetricEnabled !== "boolean")
                                return "visitorOccupancyMetricEnabled: boolean expected";
                        if (message.exportStorageProvider != null && message.hasOwnProperty("exportStorageProvider"))
                            if (!$util.isString(message.exportStorageProvider))
                                return "exportStorageProvider: string expected";
                        if (message.exportBucketRegion != null && message.hasOwnProperty("exportBucketRegion"))
                            if (!$util.isString(message.exportBucketRegion))
                                return "exportBucketRegion: string expected";
                        if (message.exportAccessKey != null && message.hasOwnProperty("exportAccessKey"))
                            if (!$util.isString(message.exportAccessKey))
                                return "exportAccessKey: string expected";
                        if (message.exportSecretKey != null && message.hasOwnProperty("exportSecretKey"))
                            if (!$util.isString(message.exportSecretKey))
                                return "exportSecretKey: string expected";
                        if (message.rawIcaStorageProvider != null && message.hasOwnProperty("rawIcaStorageProvider"))
                            switch (message.rawIcaStorageProvider) {
                            default:
                                return "rawIcaStorageProvider: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.rawIcaBucketName != null && message.hasOwnProperty("rawIcaBucketName"))
                            if (!$util.isString(message.rawIcaBucketName))
                                return "rawIcaBucketName: string expected";
                        if (message.exportPosMatchingResultEnabled != null && message.hasOwnProperty("exportPosMatchingResultEnabled"))
                            if (typeof message.exportPosMatchingResultEnabled !== "boolean")
                                return "exportPosMatchingResultEnabled: boolean expected";
                        if (message.usePosData != null && message.hasOwnProperty("usePosData"))
                            if (typeof message.usePosData !== "boolean")
                                return "usePosData: boolean expected";
                        if (message.salesAmountRelatedMetricsEnabled != null && message.hasOwnProperty("salesAmountRelatedMetricsEnabled"))
                            if (typeof message.salesAmountRelatedMetricsEnabled !== "boolean")
                                return "salesAmountRelatedMetricsEnabled: boolean expected";
                        if (message.salesItemsRelatedMetricsEnabled != null && message.hasOwnProperty("salesItemsRelatedMetricsEnabled"))
                            if (typeof message.salesItemsRelatedMetricsEnabled !== "boolean")
                                return "salesItemsRelatedMetricsEnabled: boolean expected";
                        if (message.useNetAmount != null && message.hasOwnProperty("useNetAmount"))
                            if (typeof message.useNetAmount !== "boolean")
                                return "useNetAmount: boolean expected";
                        if (message.passbyMetricEnabled != null && message.hasOwnProperty("passbyMetricEnabled"))
                            if (typeof message.passbyMetricEnabled !== "boolean")
                                return "passbyMetricEnabled: boolean expected";
                        if (message.createMatchingJobOnImportedPosDisabled != null && message.hasOwnProperty("createMatchingJobOnImportedPosDisabled"))
                            if (typeof message.createMatchingJobOnImportedPosDisabled !== "boolean")
                                return "createMatchingJobOnImportedPosDisabled: boolean expected";
                        if (message.rawIcaMergingBucketName != null && message.hasOwnProperty("rawIcaMergingBucketName"))
                            if (!$util.isString(message.rawIcaMergingBucketName))
                                return "rawIcaMergingBucketName: string expected";
                        if (message.nearRealTimeProcessingTimesOfDay != null && message.hasOwnProperty("nearRealTimeProcessingTimesOfDay"))
                            if (!$util.isString(message.nearRealTimeProcessingTimesOfDay))
                                return "nearRealTimeProcessingTimesOfDay: string expected";
                        if (message.sectionInteractionMetricEnabled != null && message.hasOwnProperty("sectionInteractionMetricEnabled"))
                            if (typeof message.sectionInteractionMetricEnabled !== "boolean")
                                return "sectionInteractionMetricEnabled: boolean expected";
                        if (message.sectionPosMetricEnabled != null && message.hasOwnProperty("sectionPosMetricEnabled"))
                            if (typeof message.sectionPosMetricEnabled !== "boolean")
                                return "sectionPosMetricEnabled: boolean expected";
                        if (message.visitorDirectionFeatureEnabled != null && message.hasOwnProperty("visitorDirectionFeatureEnabled"))
                            if (typeof message.visitorDirectionFeatureEnabled !== "boolean")
                                return "visitorDirectionFeatureEnabled: boolean expected";
                        if (message.interactionTimeMetricEnabled != null && message.hasOwnProperty("interactionTimeMetricEnabled"))
                            if (typeof message.interactionTimeMetricEnabled !== "boolean")
                                return "interactionTimeMetricEnabled: boolean expected";
                        if (message.exportRewriteHistoricalStaffingDays != null && message.hasOwnProperty("exportRewriteHistoricalStaffingDays"))
                            if (!$util.isInteger(message.exportRewriteHistoricalStaffingDays))
                                return "exportRewriteHistoricalStaffingDays: integer expected";
                        if (message.exportOverwriteExistingFiles != null && message.hasOwnProperty("exportOverwriteExistingFiles"))
                            if (typeof message.exportOverwriteExistingFiles !== "boolean")
                                return "exportOverwriteExistingFiles: boolean expected";
                        if (message.mfaRequired != null && message.hasOwnProperty("mfaRequired"))
                            if (typeof message.mfaRequired !== "boolean")
                                return "mfaRequired: boolean expected";
                        if (message.industry != null && message.hasOwnProperty("industry"))
                            switch (message.industry) {
                            default:
                                return "industry: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        if (message.useSalesTransAsNetSalesTrans != null && message.hasOwnProperty("useSalesTransAsNetSalesTrans"))
                            if (typeof message.useSalesTransAsNetSalesTrans !== "boolean")
                                return "useSalesTransAsNetSalesTrans: boolean expected";
                        if (message.useSalesItemsAsNetSalesItems != null && message.hasOwnProperty("useSalesItemsAsNetSalesItems"))
                            if (typeof message.useSalesItemsAsNetSalesItems !== "boolean")
                                return "useSalesItemsAsNetSalesItems: boolean expected";
                        if (message.staffPerformanceAnalysisMetricsEnabled != null && message.hasOwnProperty("staffPerformanceAnalysisMetricsEnabled"))
                            if (typeof message.staffPerformanceAnalysisMetricsEnabled !== "boolean")
                                return "staffPerformanceAnalysisMetricsEnabled: boolean expected";
                        if (message.staffPerformanceAnalysisStaffingMetricsEnabled != null && message.hasOwnProperty("staffPerformanceAnalysisStaffingMetricsEnabled"))
                            if (typeof message.staffPerformanceAnalysisStaffingMetricsEnabled !== "boolean")
                                return "staffPerformanceAnalysisStaffingMetricsEnabled: boolean expected";
                        if (message.enabledStoreEventTypes != null && message.hasOwnProperty("enabledStoreEventTypes")) {
                            if (!Array.isArray(message.enabledStoreEventTypes))
                                return "enabledStoreEventTypes: array expected";
                            for (var i = 0; i < message.enabledStoreEventTypes.length; ++i)
                                switch (message.enabledStoreEventTypes[i]) {
                                default:
                                    return "enabledStoreEventTypes: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                    break;
                                }
                        }
                        if (message.estimatedTrafficMetricsEnabled != null && message.hasOwnProperty("estimatedTrafficMetricsEnabled"))
                            if (typeof message.estimatedTrafficMetricsEnabled !== "boolean")
                                return "estimatedTrafficMetricsEnabled: boolean expected";
                        if (message.enableStoreSnapshot != null && message.hasOwnProperty("enableStoreSnapshot"))
                            if (typeof message.enableStoreSnapshot !== "boolean")
                                return "enableStoreSnapshot: boolean expected";
                        if (message.enableForecast != null && message.hasOwnProperty("enableForecast"))
                            if (typeof message.enableForecast !== "boolean")
                                return "enableForecast: boolean expected";
                        if (message.enableTargetTrackingForecast != null && message.hasOwnProperty("enableTargetTrackingForecast"))
                            if (typeof message.enableTargetTrackingForecast !== "boolean")
                                return "enableTargetTrackingForecast: boolean expected";
                        if (message.strictReportConsistencyEnabled != null && message.hasOwnProperty("strictReportConsistencyEnabled"))
                            if (typeof message.strictReportConsistencyEnabled !== "boolean")
                                return "strictReportConsistencyEnabled: boolean expected";
                        if (message.storeSnapshotScheduledTimes != null && message.hasOwnProperty("storeSnapshotScheduledTimes")) {
                            if (!Array.isArray(message.storeSnapshotScheduledTimes))
                                return "storeSnapshotScheduledTimes: array expected";
                            for (var i = 0; i < message.storeSnapshotScheduledTimes.length; ++i)
                                if (!$util.isString(message.storeSnapshotScheduledTimes[i]))
                                    return "storeSnapshotScheduledTimes: string[] expected";
                        }
                        if (message.storeOpenTimeBufferInSeconds != null && message.hasOwnProperty("storeOpenTimeBufferInSeconds"))
                            if (!$util.isInteger(message.storeOpenTimeBufferInSeconds))
                                return "storeOpenTimeBufferInSeconds: integer expected";
                        if (message.storeCloseTimeBufferInSeconds != null && message.hasOwnProperty("storeCloseTimeBufferInSeconds"))
                            if (!$util.isInteger(message.storeCloseTimeBufferInSeconds))
                                return "storeCloseTimeBufferInSeconds: integer expected";
                        if (message.startOfWeek != null && message.hasOwnProperty("startOfWeek"))
                            if (!$util.isInteger(message.startOfWeek))
                                return "startOfWeek: integer expected";
                        if (message.weekends != null && message.hasOwnProperty("weekends")) {
                            if (!Array.isArray(message.weekends))
                                return "weekends: array expected";
                            for (var i = 0; i < message.weekends.length; ++i)
                                if (!$util.isInteger(message.weekends[i]))
                                    return "weekends: integer[] expected";
                        }
                        if (message.storeSnapshotRetentionDays != null && message.hasOwnProperty("storeSnapshotRetentionDays"))
                            if (!$util.isInteger(message.storeSnapshotRetentionDays))
                                return "storeSnapshotRetentionDays: integer expected";
                        if (message.onTimeGreetingThresholdSec != null && message.hasOwnProperty("onTimeGreetingThresholdSec"))
                            if (!$util.isInteger(message.onTimeGreetingThresholdSec))
                                return "onTimeGreetingThresholdSec: integer expected";
                        if (message.productSalesAnalysisEnabled != null && message.hasOwnProperty("productSalesAnalysisEnabled"))
                            if (typeof message.productSalesAnalysisEnabled !== "boolean")
                                return "productSalesAnalysisEnabled: boolean expected";
                        if (message.defaultLandingMetrics != null && message.hasOwnProperty("defaultLandingMetrics")) {
                            if (!Array.isArray(message.defaultLandingMetrics))
                                return "defaultLandingMetrics: array expected";
                            for (var i = 0; i < message.defaultLandingMetrics.length; ++i)
                                switch (message.defaultLandingMetrics[i]) {
                                default:
                                    return "defaultLandingMetrics: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                case 13:
                                case 14:
                                case 15:
                                case 16:
                                case 17:
                                case 18:
                                case 19:
                                case 20:
                                case 21:
                                case 22:
                                case 23:
                                case 24:
                                case 25:
                                case 26:
                                case 27:
                                case 28:
                                case 29:
                                case 30:
                                case 31:
                                case 32:
                                case 33:
                                case 34:
                                case 35:
                                case 36:
                                case 37:
                                case 38:
                                case 39:
                                case 40:
                                case 41:
                                case 42:
                                case 43:
                                case 44:
                                case 45:
                                case 46:
                                case 47:
                                case 48:
                                case 49:
                                case 50:
                                case 51:
                                case 52:
                                case 53:
                                case 54:
                                case 55:
                                case 56:
                                case 57:
                                case 58:
                                case 59:
                                case 60:
                                case 61:
                                case 62:
                                case 63:
                                case 64:
                                case 65:
                                case 66:
                                case 67:
                                case 68:
                                case 69:
                                case 70:
                                case 71:
                                case 72:
                                case 73:
                                case 74:
                                case 75:
                                case 76:
                                case 77:
                                case 78:
                                case 79:
                                case 80:
                                case 81:
                                case 82:
                                case 83:
                                case 84:
                                case 85:
                                case 86:
                                case 87:
                                case 88:
                                case 89:
                                case 90:
                                case 91:
                                case 92:
                                case 93:
                                case 94:
                                case 95:
                                case 96:
                                case 97:
                                case 98:
                                case 99:
                                case 100:
                                case 101:
                                case 102:
                                case 103:
                                case 104:
                                case 105:
                                case 106:
                                    break;
                                }
                        }
                        if (message.enabledInputTargetMetrics != null && message.hasOwnProperty("enabledInputTargetMetrics")) {
                            if (!Array.isArray(message.enabledInputTargetMetrics))
                                return "enabledInputTargetMetrics: array expected";
                            for (var i = 0; i < message.enabledInputTargetMetrics.length; ++i)
                                switch (message.enabledInputTargetMetrics[i]) {
                                default:
                                    return "enabledInputTargetMetrics: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                case 13:
                                case 14:
                                case 15:
                                case 16:
                                case 17:
                                case 18:
                                case 19:
                                case 20:
                                case 21:
                                case 22:
                                case 23:
                                case 24:
                                case 25:
                                case 26:
                                case 27:
                                case 28:
                                case 29:
                                case 30:
                                case 31:
                                case 32:
                                case 33:
                                case 34:
                                case 35:
                                case 36:
                                case 37:
                                case 38:
                                case 39:
                                case 40:
                                case 41:
                                case 42:
                                case 43:
                                case 44:
                                case 45:
                                case 46:
                                case 47:
                                case 48:
                                case 49:
                                case 50:
                                case 51:
                                case 52:
                                case 53:
                                case 54:
                                case 55:
                                case 56:
                                case 57:
                                case 58:
                                case 59:
                                case 60:
                                case 61:
                                case 62:
                                case 63:
                                case 64:
                                case 65:
                                case 66:
                                case 67:
                                case 68:
                                case 69:
                                case 70:
                                case 71:
                                case 72:
                                case 73:
                                case 74:
                                case 75:
                                case 76:
                                case 77:
                                case 78:
                                case 79:
                                case 80:
                                case 81:
                                case 82:
                                case 83:
                                case 84:
                                case 85:
                                case 86:
                                case 87:
                                case 88:
                                case 89:
                                case 90:
                                case 91:
                                case 92:
                                case 93:
                                case 94:
                                case 95:
                                case 96:
                                case 97:
                                case 98:
                                case 99:
                                case 100:
                                case 101:
                                case 102:
                                case 103:
                                case 104:
                                case 105:
                                case 106:
                                    break;
                                }
                        }
                        if (message.enabledDisplayTargetMetrics != null && message.hasOwnProperty("enabledDisplayTargetMetrics")) {
                            if (!Array.isArray(message.enabledDisplayTargetMetrics))
                                return "enabledDisplayTargetMetrics: array expected";
                            for (var i = 0; i < message.enabledDisplayTargetMetrics.length; ++i)
                                switch (message.enabledDisplayTargetMetrics[i]) {
                                default:
                                    return "enabledDisplayTargetMetrics: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                case 13:
                                case 14:
                                case 15:
                                case 16:
                                case 17:
                                case 18:
                                case 19:
                                case 20:
                                case 21:
                                case 22:
                                case 23:
                                case 24:
                                case 25:
                                case 26:
                                case 27:
                                case 28:
                                case 29:
                                case 30:
                                case 31:
                                case 32:
                                case 33:
                                case 34:
                                case 35:
                                case 36:
                                case 37:
                                case 38:
                                case 39:
                                case 40:
                                case 41:
                                case 42:
                                case 43:
                                case 44:
                                case 45:
                                case 46:
                                case 47:
                                case 48:
                                case 49:
                                case 50:
                                case 51:
                                case 52:
                                case 53:
                                case 54:
                                case 55:
                                case 56:
                                case 57:
                                case 58:
                                case 59:
                                case 60:
                                case 61:
                                case 62:
                                case 63:
                                case 64:
                                case 65:
                                case 66:
                                case 67:
                                case 68:
                                case 69:
                                case 70:
                                case 71:
                                case 72:
                                case 73:
                                case 74:
                                case 75:
                                case 76:
                                case 77:
                                case 78:
                                case 79:
                                case 80:
                                case 81:
                                case 82:
                                case 83:
                                case 84:
                                case 85:
                                case 86:
                                case 87:
                                case 88:
                                case 89:
                                case 90:
                                case 91:
                                case 92:
                                case 93:
                                case 94:
                                case 95:
                                case 96:
                                case 97:
                                case 98:
                                case 99:
                                case 100:
                                case 101:
                                case 102:
                                case 103:
                                case 104:
                                case 105:
                                case 106:
                                    break;
                                }
                        }
                        if (message.enabledGenerateInsightTypes != null && message.hasOwnProperty("enabledGenerateInsightTypes")) {
                            if (!Array.isArray(message.enabledGenerateInsightTypes))
                                return "enabledGenerateInsightTypes: array expected";
                            for (var i = 0; i < message.enabledGenerateInsightTypes.length; ++i)
                                if (!$util.isString(message.enabledGenerateInsightTypes[i]))
                                    return "enabledGenerateInsightTypes: string[] expected";
                        }
                        if (message.enabledDisplayInsightTypes != null && message.hasOwnProperty("enabledDisplayInsightTypes")) {
                            if (!Array.isArray(message.enabledDisplayInsightTypes))
                                return "enabledDisplayInsightTypes: array expected";
                            for (var i = 0; i < message.enabledDisplayInsightTypes.length; ++i)
                                if (!$util.isString(message.enabledDisplayInsightTypes[i]))
                                    return "enabledDisplayInsightTypes: string[] expected";
                        }
                        if (message.threeWeeksTrendInsightMetrics != null && message.hasOwnProperty("threeWeeksTrendInsightMetrics")) {
                            if (!Array.isArray(message.threeWeeksTrendInsightMetrics))
                                return "threeWeeksTrendInsightMetrics: array expected";
                            for (var i = 0; i < message.threeWeeksTrendInsightMetrics.length; ++i)
                                switch (message.threeWeeksTrendInsightMetrics[i]) {
                                default:
                                    return "threeWeeksTrendInsightMetrics: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                case 13:
                                case 14:
                                case 15:
                                case 16:
                                case 17:
                                case 18:
                                case 19:
                                case 20:
                                case 21:
                                case 22:
                                case 23:
                                case 24:
                                case 25:
                                case 26:
                                case 27:
                                case 28:
                                case 29:
                                case 30:
                                case 31:
                                case 32:
                                case 33:
                                case 34:
                                case 35:
                                case 36:
                                case 37:
                                case 38:
                                case 39:
                                case 40:
                                case 41:
                                case 42:
                                case 43:
                                case 44:
                                case 45:
                                case 46:
                                case 47:
                                case 48:
                                case 49:
                                case 50:
                                case 51:
                                case 52:
                                case 53:
                                case 54:
                                case 55:
                                case 56:
                                case 57:
                                case 58:
                                case 59:
                                case 60:
                                case 61:
                                case 62:
                                case 63:
                                case 64:
                                case 65:
                                case 66:
                                case 67:
                                case 68:
                                case 69:
                                case 70:
                                case 71:
                                case 72:
                                case 73:
                                case 74:
                                case 75:
                                case 76:
                                case 77:
                                case 78:
                                case 79:
                                case 80:
                                case 81:
                                case 82:
                                case 83:
                                case 84:
                                case 85:
                                case 86:
                                case 87:
                                case 88:
                                case 89:
                                case 90:
                                case 91:
                                case 92:
                                case 93:
                                case 94:
                                case 95:
                                case 96:
                                case 97:
                                case 98:
                                case 99:
                                case 100:
                                case 101:
                                case 102:
                                case 103:
                                case 104:
                                case 105:
                                case 106:
                                    break;
                                }
                        }
                        if (message.excludedScheduledTaskTypes != null && message.hasOwnProperty("excludedScheduledTaskTypes")) {
                            if (!Array.isArray(message.excludedScheduledTaskTypes))
                                return "excludedScheduledTaskTypes: array expected";
                            for (var i = 0; i < message.excludedScheduledTaskTypes.length; ++i)
                                switch (message.excludedScheduledTaskTypes[i]) {
                                default:
                                    return "excludedScheduledTaskTypes: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    break;
                                }
                        }
                        if (message.videoGroupSizeBuckets != null && message.hasOwnProperty("videoGroupSizeBuckets"))
                            if (!$util.isString(message.videoGroupSizeBuckets))
                                return "videoGroupSizeBuckets: string expected";
                        if (message.criticalHoursOfDaysOfWeek != null && message.hasOwnProperty("criticalHoursOfDaysOfWeek")) {
                            if (!Array.isArray(message.criticalHoursOfDaysOfWeek))
                                return "criticalHoursOfDaysOfWeek: array expected";
                            for (var i = 0; i < message.criticalHoursOfDaysOfWeek.length; ++i) {
                                var error = $root.palexy.streaming.v1.CriticalHoursOfDayOfWeek.verify(message.criticalHoursOfDaysOfWeek[i]);
                                if (error)
                                    return "criticalHoursOfDaysOfWeek." + error;
                            }
                        }
                        if (message.posStaffSyncEnabled != null && message.hasOwnProperty("posStaffSyncEnabled"))
                            if (typeof message.posStaffSyncEnabled !== "boolean")
                                return "posStaffSyncEnabled: boolean expected";
                        if (message.storeRelationshipEnabled != null && message.hasOwnProperty("storeRelationshipEnabled"))
                            if (typeof message.storeRelationshipEnabled !== "boolean")
                                return "storeRelationshipEnabled: boolean expected";
                        if (message.discountMetricsEnabled != null && message.hasOwnProperty("discountMetricsEnabled"))
                            if (typeof message.discountMetricsEnabled !== "boolean")
                                return "discountMetricsEnabled: boolean expected";
                        if (message.discountDistributionBuckets != null && message.hasOwnProperty("discountDistributionBuckets"))
                            if (!$util.isString(message.discountDistributionBuckets))
                                return "discountDistributionBuckets: string expected";
                        if (message.includeReturningItemsForDiscountAnalysis != null && message.hasOwnProperty("includeReturningItemsForDiscountAnalysis"))
                            if (typeof message.includeReturningItemsForDiscountAnalysis !== "boolean")
                                return "includeReturningItemsForDiscountAnalysis: boolean expected";
                        if (message.ownerAccount != null && message.hasOwnProperty("ownerAccount")) {
                            var error = $root.palexy.streaming.v1.Account.verify(message.ownerAccount);
                            if (error)
                                return "ownerAccount." + error;
                        }
                        if (message.storeTimezones != null && message.hasOwnProperty("storeTimezones")) {
                            if (!Array.isArray(message.storeTimezones))
                                return "storeTimezones: array expected";
                            for (var i = 0; i < message.storeTimezones.length; ++i)
                                if (!$util.isString(message.storeTimezones[i]))
                                    return "storeTimezones: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Company message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Company
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Company} Company
                     */
                    Company.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Company)
                            return object;
                        var message = new $root.palexy.streaming.v1.Company();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.transformationMatrixEnabled != null)
                            message.transformationMatrixEnabled = Boolean(object.transformationMatrixEnabled);
                        if (object.logicalLayoutEnabled != null)
                            message.logicalLayoutEnabled = Boolean(object.logicalLayoutEnabled);
                        if (object.awsBucket != null)
                            message.awsBucket = String(object.awsBucket);
                        if (object.gcsBucket != null)
                            message.gcsBucket = String(object.gcsBucket);
                        if (object.companyCode != null)
                            message.companyCode = String(object.companyCode);
                        if (object.partnerCode != null)
                            message.partnerCode = String(object.partnerCode);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.entranceCounting != null)
                            message.entranceCounting = Boolean(object.entranceCounting);
                        if (object.entranceKeyword != null)
                            message.entranceKeyword = String(object.entranceKeyword);
                        if (object.dwelltimeBuckets != null)
                            message.dwelltimeBuckets = String(object.dwelltimeBuckets);
                        if (object.ageRanges != null)
                            message.ageRanges = String(object.ageRanges);
                        if (object.sectionVisitDurationLimit != null)
                            message.sectionVisitDurationLimit = object.sectionVisitDurationLimit | 0;
                        if (object.dwelltimeMetricDisabled != null)
                            message.dwelltimeMetricDisabled = Boolean(object.dwelltimeMetricDisabled);
                        if (object.interactionMetricDisabled != null)
                            message.interactionMetricDisabled = Boolean(object.interactionMetricDisabled);
                        if (object.transactionMetricDisabled != null)
                            message.transactionMetricDisabled = Boolean(object.transactionMetricDisabled);
                        if (object.groupMetricEnabled != null)
                            message.groupMetricEnabled = Boolean(object.groupMetricEnabled);
                        if (object.lyingMetricEnabled != null)
                            message.lyingMetricEnabled = Boolean(object.lyingMetricEnabled);
                        if (object.avgDwelltimeMetricDisabled != null)
                            message.avgDwelltimeMetricDisabled = Boolean(object.avgDwelltimeMetricDisabled);
                        if (object.layoutViewDisabled != null)
                            message.layoutViewDisabled = Boolean(object.layoutViewDisabled);
                        if (object.topSectionsViewDisabled != null)
                            message.topSectionsViewDisabled = Boolean(object.topSectionsViewDisabled);
                        if (object.dailyExport != null)
                            message.dailyExport = Boolean(object.dailyExport);
                        if (object.lastExportTimeMs != null)
                            if ($util.Long)
                                (message.lastExportTimeMs = $util.Long.fromValue(object.lastExportTimeMs)).unsigned = false;
                            else if (typeof object.lastExportTimeMs === "string")
                                message.lastExportTimeMs = parseInt(object.lastExportTimeMs, 10);
                            else if (typeof object.lastExportTimeMs === "number")
                                message.lastExportTimeMs = object.lastExportTimeMs;
                            else if (typeof object.lastExportTimeMs === "object")
                                message.lastExportTimeMs = new $util.LongBits(object.lastExportTimeMs.low >>> 0, object.lastExportTimeMs.high >>> 0).toNumber();
                        if (object.exportBucket != null)
                            message.exportBucket = String(object.exportBucket);
                        if (object.exportNotificationEmails != null)
                            message.exportNotificationEmails = String(object.exportNotificationEmails);
                        if (object.exportNotifyUrl != null)
                            message.exportNotifyUrl = String(object.exportNotifyUrl);
                        if (object.integrationUsername != null)
                            message.integrationUsername = String(object.integrationUsername);
                        if (object.integrationPassword != null)
                            message.integrationPassword = String(object.integrationPassword);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.interactionLevelMetricEnabled != null)
                            message.interactionLevelMetricEnabled = Boolean(object.interactionLevelMetricEnabled);
                        if (object.visitorOccupancyMetricEnabled != null)
                            message.visitorOccupancyMetricEnabled = Boolean(object.visitorOccupancyMetricEnabled);
                        if (object.exportStorageProvider != null)
                            message.exportStorageProvider = String(object.exportStorageProvider);
                        if (object.exportBucketRegion != null)
                            message.exportBucketRegion = String(object.exportBucketRegion);
                        if (object.exportAccessKey != null)
                            message.exportAccessKey = String(object.exportAccessKey);
                        if (object.exportSecretKey != null)
                            message.exportSecretKey = String(object.exportSecretKey);
                        switch (object.rawIcaStorageProvider) {
                        case "RAW_PROVIDER_UNKNOWN":
                        case 0:
                            message.rawIcaStorageProvider = 0;
                            break;
                        case "DATABASE":
                        case 1:
                            message.rawIcaStorageProvider = 1;
                            break;
                        case "GCP":
                        case 2:
                            message.rawIcaStorageProvider = 2;
                            break;
                        }
                        if (object.rawIcaBucketName != null)
                            message.rawIcaBucketName = String(object.rawIcaBucketName);
                        if (object.exportPosMatchingResultEnabled != null)
                            message.exportPosMatchingResultEnabled = Boolean(object.exportPosMatchingResultEnabled);
                        if (object.usePosData != null)
                            message.usePosData = Boolean(object.usePosData);
                        if (object.salesAmountRelatedMetricsEnabled != null)
                            message.salesAmountRelatedMetricsEnabled = Boolean(object.salesAmountRelatedMetricsEnabled);
                        if (object.salesItemsRelatedMetricsEnabled != null)
                            message.salesItemsRelatedMetricsEnabled = Boolean(object.salesItemsRelatedMetricsEnabled);
                        if (object.useNetAmount != null)
                            message.useNetAmount = Boolean(object.useNetAmount);
                        if (object.passbyMetricEnabled != null)
                            message.passbyMetricEnabled = Boolean(object.passbyMetricEnabled);
                        if (object.createMatchingJobOnImportedPosDisabled != null)
                            message.createMatchingJobOnImportedPosDisabled = Boolean(object.createMatchingJobOnImportedPosDisabled);
                        if (object.rawIcaMergingBucketName != null)
                            message.rawIcaMergingBucketName = String(object.rawIcaMergingBucketName);
                        if (object.nearRealTimeProcessingTimesOfDay != null)
                            message.nearRealTimeProcessingTimesOfDay = String(object.nearRealTimeProcessingTimesOfDay);
                        if (object.sectionInteractionMetricEnabled != null)
                            message.sectionInteractionMetricEnabled = Boolean(object.sectionInteractionMetricEnabled);
                        if (object.sectionPosMetricEnabled != null)
                            message.sectionPosMetricEnabled = Boolean(object.sectionPosMetricEnabled);
                        if (object.visitorDirectionFeatureEnabled != null)
                            message.visitorDirectionFeatureEnabled = Boolean(object.visitorDirectionFeatureEnabled);
                        if (object.interactionTimeMetricEnabled != null)
                            message.interactionTimeMetricEnabled = Boolean(object.interactionTimeMetricEnabled);
                        if (object.exportRewriteHistoricalStaffingDays != null)
                            message.exportRewriteHistoricalStaffingDays = object.exportRewriteHistoricalStaffingDays | 0;
                        if (object.exportOverwriteExistingFiles != null)
                            message.exportOverwriteExistingFiles = Boolean(object.exportOverwriteExistingFiles);
                        if (object.mfaRequired != null)
                            message.mfaRequired = Boolean(object.mfaRequired);
                        switch (object.industry) {
                        case "OTHERS":
                        case 0:
                            message.industry = 0;
                            break;
                        case "FASHION":
                        case 1:
                            message.industry = 1;
                            break;
                        case "CVS":
                        case 2:
                            message.industry = 2;
                            break;
                        case "MALL":
                        case 3:
                            message.industry = 3;
                            break;
                        case "JEWELRY":
                        case 4:
                            message.industry = 4;
                            break;
                        case "DEPARTMENT_STORE":
                        case 5:
                            message.industry = 5;
                            break;
                        }
                        if (object.useSalesTransAsNetSalesTrans != null)
                            message.useSalesTransAsNetSalesTrans = Boolean(object.useSalesTransAsNetSalesTrans);
                        if (object.useSalesItemsAsNetSalesItems != null)
                            message.useSalesItemsAsNetSalesItems = Boolean(object.useSalesItemsAsNetSalesItems);
                        if (object.staffPerformanceAnalysisMetricsEnabled != null)
                            message.staffPerformanceAnalysisMetricsEnabled = Boolean(object.staffPerformanceAnalysisMetricsEnabled);
                        if (object.staffPerformanceAnalysisStaffingMetricsEnabled != null)
                            message.staffPerformanceAnalysisStaffingMetricsEnabled = Boolean(object.staffPerformanceAnalysisStaffingMetricsEnabled);
                        if (object.enabledStoreEventTypes) {
                            if (!Array.isArray(object.enabledStoreEventTypes))
                                throw TypeError(".palexy.streaming.v1.Company.enabledStoreEventTypes: array expected");
                            message.enabledStoreEventTypes = [];
                            for (var i = 0; i < object.enabledStoreEventTypes.length; ++i)
                                switch (object.enabledStoreEventTypes[i]) {
                                default:
                                case "UNKNOWN_STORE_EVENT_TYPE":
                                case 0:
                                    message.enabledStoreEventTypes[i] = 0;
                                    break;
                                case "MISSED_CUSTOMER_INTERACTION":
                                case 1:
                                    message.enabledStoreEventTypes[i] = 1;
                                    break;
                                case "SUSPICIOUS_CASHIER_ACTIVITY":
                                case 2:
                                    message.enabledStoreEventTypes[i] = 2;
                                    break;
                                case "SOP_NON_COMPLIANCE":
                                case 3:
                                    message.enabledStoreEventTypes[i] = 3;
                                    break;
                                case "STAFF_ATTENDANCE_NON_COMPLIANCE":
                                case 4:
                                    message.enabledStoreEventTypes[i] = 4;
                                    break;
                                case "STAFF_GATHERING":
                                case 5:
                                    message.enabledStoreEventTypes[i] = 5;
                                    break;
                                case "STORE_OPEN_CLOSE_TIME_NON_COMPLIANCE":
                                case 6:
                                    message.enabledStoreEventTypes[i] = 6;
                                    break;
                                case "CUSTOMER_NEEDS_ASSISTANCE":
                                case 7:
                                    message.enabledStoreEventTypes[i] = 7;
                                    break;
                                case "STAFF_NOT_WEAR_GLOVE":
                                case 8:
                                    message.enabledStoreEventTypes[i] = 8;
                                    break;
                                case "NOT_GREETED_ON_TIME":
                                case 9:
                                    message.enabledStoreEventTypes[i] = 9;
                                    break;
                                case "STAFF_MISSING":
                                case 10:
                                    message.enabledStoreEventTypes[i] = 10;
                                    break;
                                case "STAFF_LEAVING_SPECIAL_AREA_WITHOUT_FOLLOWING_SOP":
                                case 11:
                                    message.enabledStoreEventTypes[i] = 11;
                                    break;
                                case "HIGH_TRAFFIC_CHECKOUT":
                                case 12:
                                    message.enabledStoreEventTypes[i] = 12;
                                    break;
                                }
                        }
                        if (object.estimatedTrafficMetricsEnabled != null)
                            message.estimatedTrafficMetricsEnabled = Boolean(object.estimatedTrafficMetricsEnabled);
                        if (object.enableStoreSnapshot != null)
                            message.enableStoreSnapshot = Boolean(object.enableStoreSnapshot);
                        if (object.enableForecast != null)
                            message.enableForecast = Boolean(object.enableForecast);
                        if (object.enableTargetTrackingForecast != null)
                            message.enableTargetTrackingForecast = Boolean(object.enableTargetTrackingForecast);
                        if (object.strictReportConsistencyEnabled != null)
                            message.strictReportConsistencyEnabled = Boolean(object.strictReportConsistencyEnabled);
                        if (object.storeSnapshotScheduledTimes) {
                            if (!Array.isArray(object.storeSnapshotScheduledTimes))
                                throw TypeError(".palexy.streaming.v1.Company.storeSnapshotScheduledTimes: array expected");
                            message.storeSnapshotScheduledTimes = [];
                            for (var i = 0; i < object.storeSnapshotScheduledTimes.length; ++i)
                                message.storeSnapshotScheduledTimes[i] = String(object.storeSnapshotScheduledTimes[i]);
                        }
                        if (object.storeOpenTimeBufferInSeconds != null)
                            message.storeOpenTimeBufferInSeconds = object.storeOpenTimeBufferInSeconds | 0;
                        if (object.storeCloseTimeBufferInSeconds != null)
                            message.storeCloseTimeBufferInSeconds = object.storeCloseTimeBufferInSeconds | 0;
                        if (object.startOfWeek != null)
                            message.startOfWeek = object.startOfWeek | 0;
                        if (object.weekends) {
                            if (!Array.isArray(object.weekends))
                                throw TypeError(".palexy.streaming.v1.Company.weekends: array expected");
                            message.weekends = [];
                            for (var i = 0; i < object.weekends.length; ++i)
                                message.weekends[i] = object.weekends[i] | 0;
                        }
                        if (object.storeSnapshotRetentionDays != null)
                            message.storeSnapshotRetentionDays = object.storeSnapshotRetentionDays | 0;
                        if (object.onTimeGreetingThresholdSec != null)
                            message.onTimeGreetingThresholdSec = object.onTimeGreetingThresholdSec | 0;
                        if (object.productSalesAnalysisEnabled != null)
                            message.productSalesAnalysisEnabled = Boolean(object.productSalesAnalysisEnabled);
                        if (object.defaultLandingMetrics) {
                            if (!Array.isArray(object.defaultLandingMetrics))
                                throw TypeError(".palexy.streaming.v1.Company.defaultLandingMetrics: array expected");
                            message.defaultLandingMetrics = [];
                            for (var i = 0; i < object.defaultLandingMetrics.length; ++i)
                                switch (object.defaultLandingMetrics[i]) {
                                default:
                                case "METRIC_UNKNOWN":
                                case 0:
                                    message.defaultLandingMetrics[i] = 0;
                                    break;
                                case "METRIC_BUYER_TRAFFIC":
                                case 1:
                                    message.defaultLandingMetrics[i] = 1;
                                    break;
                                case "METRIC_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 2:
                                    message.defaultLandingMetrics[i] = 2;
                                    break;
                                case "METRIC_INTERACTION":
                                case 3:
                                    message.defaultLandingMetrics[i] = 3;
                                    break;
                                case "METRIC_VISIT":
                                case 4:
                                    message.defaultLandingMetrics[i] = 4;
                                    break;
                                case "METRIC_GROUP_VISIT":
                                case 5:
                                    message.defaultLandingMetrics[i] = 5;
                                    break;
                                case "METRIC_GROUP_INTERACTION":
                                case 6:
                                    message.defaultLandingMetrics[i] = 6;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC":
                                case 7:
                                    message.defaultLandingMetrics[i] = 7;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 8:
                                    message.defaultLandingMetrics[i] = 8;
                                    break;
                                case "METRIC_BUYER_RATE":
                                case 9:
                                    message.defaultLandingMetrics[i] = 9;
                                    break;
                                case "METRIC_INTERACTION_RATE":
                                case 10:
                                    message.defaultLandingMetrics[i] = 10;
                                    break;
                                case "METRIC_INTERACTION_TIME":
                                case 11:
                                    message.defaultLandingMetrics[i] = 11;
                                    break;
                                case "METRIC_INTERACTION_TIME_AVG":
                                case 12:
                                    message.defaultLandingMetrics[i] = 12;
                                    break;
                                case "METRIC_INTERACTION_LEVEL":
                                case 13:
                                    message.defaultLandingMetrics[i] = 13;
                                    break;
                                case "METRIC_INTERACTION_BUYER_RATE":
                                case 14:
                                    message.defaultLandingMetrics[i] = 14;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_INTERACTION":
                                case 15:
                                    message.defaultLandingMetrics[i] = 15;
                                    break;
                                case "METRIC_ON_TIME_INTERACTION_RATE":
                                case 16:
                                    message.defaultLandingMetrics[i] = 16;
                                    break;
                                case "METRIC_GROUP_BUYER_RATE":
                                case 17:
                                    message.defaultLandingMetrics[i] = 17;
                                    break;
                                case "METRIC_GROUP_INTERACTION_RATE":
                                case 18:
                                    message.defaultLandingMetrics[i] = 18;
                                    break;
                                case "METRIC_GROUP_INTERACTION_BUYER_RATE":
                                case 19:
                                    message.defaultLandingMetrics[i] = 19;
                                    break;
                                case "METRIC_LYING":
                                case 20:
                                    message.defaultLandingMetrics[i] = 20;
                                    break;
                                case "METRIC_LYING_RATE":
                                case 21:
                                    message.defaultLandingMetrics[i] = 21;
                                    break;
                                case "METRIC_LYING_TIME_AVG":
                                case 22:
                                    message.defaultLandingMetrics[i] = 22;
                                    break;
                                case "METRIC_LYING_EXACT_ONE":
                                case 23:
                                    message.defaultLandingMetrics[i] = 23;
                                    break;
                                case "METRIC_LYING_AT_LEAST_ONE":
                                case 24:
                                    message.defaultLandingMetrics[i] = 24;
                                    break;
                                case "METRIC_LYING_AT_LEAST_TWO":
                                case 25:
                                    message.defaultLandingMetrics[i] = 25;
                                    break;
                                case "METRIC_DWELL_TIME":
                                case 26:
                                    message.defaultLandingMetrics[i] = 26;
                                    break;
                                case "METRIC_DWELL_TIME_AVG":
                                case 27:
                                    message.defaultLandingMetrics[i] = 27;
                                    break;
                                case "METRIC_TOTAL_GREETED_CUSTOMERS":
                                case 28:
                                    message.defaultLandingMetrics[i] = 28;
                                    break;
                                case "METRIC_GREETING_RATE":
                                case 29:
                                    message.defaultLandingMetrics[i] = 29;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_GREETING":
                                case 30:
                                    message.defaultLandingMetrics[i] = 30;
                                    break;
                                case "METRIC_ON_TIME_GREETING_RATE":
                                case 31:
                                    message.defaultLandingMetrics[i] = 31;
                                    break;
                                case "METRIC_FOOT_TRAFFIC":
                                case 32:
                                    message.defaultLandingMetrics[i] = 32;
                                    break;
                                case "METRIC_VISITOR_OCCUPANCY":
                                case 33:
                                    message.defaultLandingMetrics[i] = 33;
                                    break;
                                case "METRIC_TOTAL_GROUPS":
                                case 34:
                                    message.defaultLandingMetrics[i] = 34;
                                    break;
                                case "METRIC_TOTAL_VISITORS_GOING_AS_GROUPS":
                                case 35:
                                    message.defaultLandingMetrics[i] = 35;
                                    break;
                                case "METRIC_GROUP_RATE":
                                case 36:
                                    message.defaultLandingMetrics[i] = 36;
                                    break;
                                case "METRIC_AVG_GROUP_SIZE":
                                case 37:
                                    message.defaultLandingMetrics[i] = 37;
                                    break;
                                case "METRIC_TOTAL_GROUPS_WITH_KIDS":
                                case 38:
                                    message.defaultLandingMetrics[i] = 38;
                                    break;
                                case "METRIC_TOTAL_KNOWN_GROUPS":
                                case 39:
                                    message.defaultLandingMetrics[i] = 39;
                                    break;
                                case "METRIC_GROUPS_WITH_KIDS_RATE":
                                case 40:
                                    message.defaultLandingMetrics[i] = 40;
                                    break;
                                case "METRIC_TOTAL_GROUPS_AND_INDIVIDUALS":
                                case 41:
                                    message.defaultLandingMetrics[i] = 41;
                                    break;
                                case "METRIC_CONVERSION_BY_OPPORTUNITIES":
                                case 42:
                                    message.defaultLandingMetrics[i] = 42;
                                    break;
                                case "METRIC_PERCENT_SECTION_VISIT":
                                case 43:
                                    message.defaultLandingMetrics[i] = 43;
                                    break;
                                case "METRIC_PERCENT_SECTION_PASS_BY":
                                case 44:
                                    message.defaultLandingMetrics[i] = 44;
                                    break;
                                case "METRIC_PASS_BY":
                                case 45:
                                    message.defaultLandingMetrics[i] = 45;
                                    break;
                                case "METRIC_CAPTURE_RATE":
                                case 46:
                                    message.defaultLandingMetrics[i] = 46;
                                    break;
                                case "METRIC_TRANSACTION":
                                case 47:
                                    message.defaultLandingMetrics[i] = 47;
                                    break;
                                case "METRIC_CONVERSION_RATE":
                                case 48:
                                    message.defaultLandingMetrics[i] = 48;
                                    break;
                                case "METRIC_NET_SALES_AMOUNT":
                                case 49:
                                    message.defaultLandingMetrics[i] = 49;
                                    break;
                                case "METRIC_NET_SALES_ITEMS":
                                case 50:
                                    message.defaultLandingMetrics[i] = 50;
                                    break;
                                case "METRIC_NET_SALES_CONTRIBUTION":
                                case 51:
                                    message.defaultLandingMetrics[i] = 51;
                                    break;
                                case "METRIC_NET_SALES_RANKING":
                                case 52:
                                    message.defaultLandingMetrics[i] = 52;
                                    break;
                                case "METRIC_SHOPPER_YIELD":
                                case 53:
                                    message.defaultLandingMetrics[i] = 53;
                                    break;
                                case "METRIC_ATV":
                                case 54:
                                    message.defaultLandingMetrics[i] = 54;
                                    break;
                                case "METRIC_UPT":
                                case 55:
                                    message.defaultLandingMetrics[i] = 55;
                                    break;
                                case "METRIC_UPDPS":
                                case 56:
                                    message.defaultLandingMetrics[i] = 56;
                                    break;
                                case "METRIC_ASP":
                                case 57:
                                    message.defaultLandingMetrics[i] = 57;
                                    break;
                                case "METRIC_STAFF_HOURS":
                                case 58:
                                    message.defaultLandingMetrics[i] = 58;
                                    break;
                                case "METRIC_VISITOR_PER_STAFF_HOUR":
                                case 59:
                                    message.defaultLandingMetrics[i] = 59;
                                    break;
                                case "METRIC_SALES_PER_STAFF_HOUR":
                                case 60:
                                    message.defaultLandingMetrics[i] = 60;
                                    break;
                                case "METRIC_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 61:
                                    message.defaultLandingMetrics[i] = 61;
                                    break;
                                case "METRIC_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 62:
                                    message.defaultLandingMetrics[i] = 62;
                                    break;
                                case "METRIC_FORECAST_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 63:
                                    message.defaultLandingMetrics[i] = 63;
                                    break;
                                case "METRIC_FORECAST_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 64:
                                    message.defaultLandingMetrics[i] = 64;
                                    break;
                                case "METRIC_ROUNDED_STAFF_HOURS":
                                case 65:
                                    message.defaultLandingMetrics[i] = 65;
                                    break;
                                case "METRIC_STAFF_HOUR_WITH_VISITOR_COUNT":
                                case 66:
                                    message.defaultLandingMetrics[i] = 66;
                                    break;
                                case "METRIC_VISITORS_PER_SQM":
                                case 67:
                                    message.defaultLandingMetrics[i] = 67;
                                    break;
                                case "METRIC_AVG_VISITOR_PER_STORE_PER_DAY":
                                case 68:
                                    message.defaultLandingMetrics[i] = 68;
                                    break;
                                case "METRIC_DAYS_WITH_VISITORS":
                                case 69:
                                    message.defaultLandingMetrics[i] = 69;
                                    break;
                                case "METRIC_DAYS_WITH_NET_SALES_ITEMS":
                                case 70:
                                    message.defaultLandingMetrics[i] = 70;
                                    break;
                                case "METRIC_SALES_PER_SQM":
                                case 71:
                                    message.defaultLandingMetrics[i] = 71;
                                    break;
                                case "METRIC_SALES_PER_SALES_AREA_SQM":
                                case 72:
                                    message.defaultLandingMetrics[i] = 72;
                                    break;
                                case "METRIC_ESTIMATED_VISIT":
                                case 73:
                                    message.defaultLandingMetrics[i] = 73;
                                    break;
                                case "METRIC_ESTIMATED_PASS_BY":
                                case 74:
                                    message.defaultLandingMetrics[i] = 74;
                                    break;
                                case "METRIC_WALK_IN":
                                case 75:
                                    message.defaultLandingMetrics[i] = 75;
                                    break;
                                case "METRIC_PERCENT_SECTION_ESTIMATED_PASS_BY":
                                case 76:
                                    message.defaultLandingMetrics[i] = 76;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1":
                                case 77:
                                    message.defaultLandingMetrics[i] = 77;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1_RATE":
                                case 78:
                                    message.defaultLandingMetrics[i] = 78;
                                    break;
                                case "METRIC_CONVERTED_CUSTOM_ACTION_1_RATE":
                                case 79:
                                    message.defaultLandingMetrics[i] = 79;
                                    break;
                                case "METRIC_AVG_SALES_PER_STORE_PER_DAY":
                                case 80:
                                    message.defaultLandingMetrics[i] = 80;
                                    break;
                                case "METRIC_STAFF_TOTAL_SERVED_VISITORS":
                                case 81:
                                    message.defaultLandingMetrics[i] = 81;
                                    break;
                                case "METRIC_STAFF_CONVERSION_RATE":
                                case 82:
                                    message.defaultLandingMetrics[i] = 82;
                                    break;
                                case "METRIC_STAFF_SALES_PER_SERVED_VISITORS":
                                case 83:
                                    message.defaultLandingMetrics[i] = 83;
                                    break;
                                case "METRIC_STAFF_SERVED_VISITORS_PER_STAFF_HOUR":
                                case 84:
                                    message.defaultLandingMetrics[i] = 84;
                                    break;
                                case "METRIC_STAFF_PERCENTAGE_OF_SALES":
                                case 85:
                                    message.defaultLandingMetrics[i] = 85;
                                    break;
                                case "METRIC_DAYS_WITH_SALES":
                                case 86:
                                    message.defaultLandingMetrics[i] = 86;
                                    break;
                                case "METRIC_VIDEO_GROUP_DISTRIBUTION":
                                case 87:
                                    message.defaultLandingMetrics[i] = 87;
                                    break;
                                case "METRIC_BOUNCE_RATE":
                                case 88:
                                    message.defaultLandingMetrics[i] = 88;
                                    break;
                                case "METRIC_AVG_MALL_VISITOR_PER_STORE_PER_DAY":
                                case 89:
                                    message.defaultLandingMetrics[i] = 89;
                                    break;
                                case "METRIC_TOTAL_ON_TIME_GREETED_CUSTOMERS":
                                case 90:
                                    message.defaultLandingMetrics[i] = 90;
                                    break;
                                case "METRIC_STAFF_STORE_NET_SALES_AMOUNT":
                                case 91:
                                    message.defaultLandingMetrics[i] = 91;
                                    break;
                                case "METRIC_STAFF_UNIQUE_INTERACTIONS":
                                case 92:
                                    message.defaultLandingMetrics[i] = 92;
                                    break;
                                case "METRIC_STAFF_INTERACTION_RATE":
                                case 93:
                                    message.defaultLandingMetrics[i] = 93;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_VISITORS":
                                case 94:
                                    message.defaultLandingMetrics[i] = 94;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_VISITORS":
                                case 95:
                                    message.defaultLandingMetrics[i] = 95;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_VISITORS":
                                case 96:
                                    message.defaultLandingMetrics[i] = 96;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME":
                                case 97:
                                    message.defaultLandingMetrics[i] = 97;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME":
                                case 98:
                                    message.defaultLandingMetrics[i] = 98;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_OPPORTUNITIES":
                                case 99:
                                    message.defaultLandingMetrics[i] = 99;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 100:
                                    message.defaultLandingMetrics[i] = 100;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 101:
                                    message.defaultLandingMetrics[i] = 101;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 102:
                                    message.defaultLandingMetrics[i] = 102;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 103:
                                    message.defaultLandingMetrics[i] = 103;
                                    break;
                                case "METRIC_DISCOUNT_AMOUNT":
                                case 104:
                                    message.defaultLandingMetrics[i] = 104;
                                    break;
                                case "METRIC_DISCOUNT_RATE":
                                case 105:
                                    message.defaultLandingMetrics[i] = 105;
                                    break;
                                case "METRIC_GROSS_AMOUNT":
                                case 106:
                                    message.defaultLandingMetrics[i] = 106;
                                    break;
                                }
                        }
                        if (object.enabledInputTargetMetrics) {
                            if (!Array.isArray(object.enabledInputTargetMetrics))
                                throw TypeError(".palexy.streaming.v1.Company.enabledInputTargetMetrics: array expected");
                            message.enabledInputTargetMetrics = [];
                            for (var i = 0; i < object.enabledInputTargetMetrics.length; ++i)
                                switch (object.enabledInputTargetMetrics[i]) {
                                default:
                                case "METRIC_UNKNOWN":
                                case 0:
                                    message.enabledInputTargetMetrics[i] = 0;
                                    break;
                                case "METRIC_BUYER_TRAFFIC":
                                case 1:
                                    message.enabledInputTargetMetrics[i] = 1;
                                    break;
                                case "METRIC_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 2:
                                    message.enabledInputTargetMetrics[i] = 2;
                                    break;
                                case "METRIC_INTERACTION":
                                case 3:
                                    message.enabledInputTargetMetrics[i] = 3;
                                    break;
                                case "METRIC_VISIT":
                                case 4:
                                    message.enabledInputTargetMetrics[i] = 4;
                                    break;
                                case "METRIC_GROUP_VISIT":
                                case 5:
                                    message.enabledInputTargetMetrics[i] = 5;
                                    break;
                                case "METRIC_GROUP_INTERACTION":
                                case 6:
                                    message.enabledInputTargetMetrics[i] = 6;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC":
                                case 7:
                                    message.enabledInputTargetMetrics[i] = 7;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 8:
                                    message.enabledInputTargetMetrics[i] = 8;
                                    break;
                                case "METRIC_BUYER_RATE":
                                case 9:
                                    message.enabledInputTargetMetrics[i] = 9;
                                    break;
                                case "METRIC_INTERACTION_RATE":
                                case 10:
                                    message.enabledInputTargetMetrics[i] = 10;
                                    break;
                                case "METRIC_INTERACTION_TIME":
                                case 11:
                                    message.enabledInputTargetMetrics[i] = 11;
                                    break;
                                case "METRIC_INTERACTION_TIME_AVG":
                                case 12:
                                    message.enabledInputTargetMetrics[i] = 12;
                                    break;
                                case "METRIC_INTERACTION_LEVEL":
                                case 13:
                                    message.enabledInputTargetMetrics[i] = 13;
                                    break;
                                case "METRIC_INTERACTION_BUYER_RATE":
                                case 14:
                                    message.enabledInputTargetMetrics[i] = 14;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_INTERACTION":
                                case 15:
                                    message.enabledInputTargetMetrics[i] = 15;
                                    break;
                                case "METRIC_ON_TIME_INTERACTION_RATE":
                                case 16:
                                    message.enabledInputTargetMetrics[i] = 16;
                                    break;
                                case "METRIC_GROUP_BUYER_RATE":
                                case 17:
                                    message.enabledInputTargetMetrics[i] = 17;
                                    break;
                                case "METRIC_GROUP_INTERACTION_RATE":
                                case 18:
                                    message.enabledInputTargetMetrics[i] = 18;
                                    break;
                                case "METRIC_GROUP_INTERACTION_BUYER_RATE":
                                case 19:
                                    message.enabledInputTargetMetrics[i] = 19;
                                    break;
                                case "METRIC_LYING":
                                case 20:
                                    message.enabledInputTargetMetrics[i] = 20;
                                    break;
                                case "METRIC_LYING_RATE":
                                case 21:
                                    message.enabledInputTargetMetrics[i] = 21;
                                    break;
                                case "METRIC_LYING_TIME_AVG":
                                case 22:
                                    message.enabledInputTargetMetrics[i] = 22;
                                    break;
                                case "METRIC_LYING_EXACT_ONE":
                                case 23:
                                    message.enabledInputTargetMetrics[i] = 23;
                                    break;
                                case "METRIC_LYING_AT_LEAST_ONE":
                                case 24:
                                    message.enabledInputTargetMetrics[i] = 24;
                                    break;
                                case "METRIC_LYING_AT_LEAST_TWO":
                                case 25:
                                    message.enabledInputTargetMetrics[i] = 25;
                                    break;
                                case "METRIC_DWELL_TIME":
                                case 26:
                                    message.enabledInputTargetMetrics[i] = 26;
                                    break;
                                case "METRIC_DWELL_TIME_AVG":
                                case 27:
                                    message.enabledInputTargetMetrics[i] = 27;
                                    break;
                                case "METRIC_TOTAL_GREETED_CUSTOMERS":
                                case 28:
                                    message.enabledInputTargetMetrics[i] = 28;
                                    break;
                                case "METRIC_GREETING_RATE":
                                case 29:
                                    message.enabledInputTargetMetrics[i] = 29;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_GREETING":
                                case 30:
                                    message.enabledInputTargetMetrics[i] = 30;
                                    break;
                                case "METRIC_ON_TIME_GREETING_RATE":
                                case 31:
                                    message.enabledInputTargetMetrics[i] = 31;
                                    break;
                                case "METRIC_FOOT_TRAFFIC":
                                case 32:
                                    message.enabledInputTargetMetrics[i] = 32;
                                    break;
                                case "METRIC_VISITOR_OCCUPANCY":
                                case 33:
                                    message.enabledInputTargetMetrics[i] = 33;
                                    break;
                                case "METRIC_TOTAL_GROUPS":
                                case 34:
                                    message.enabledInputTargetMetrics[i] = 34;
                                    break;
                                case "METRIC_TOTAL_VISITORS_GOING_AS_GROUPS":
                                case 35:
                                    message.enabledInputTargetMetrics[i] = 35;
                                    break;
                                case "METRIC_GROUP_RATE":
                                case 36:
                                    message.enabledInputTargetMetrics[i] = 36;
                                    break;
                                case "METRIC_AVG_GROUP_SIZE":
                                case 37:
                                    message.enabledInputTargetMetrics[i] = 37;
                                    break;
                                case "METRIC_TOTAL_GROUPS_WITH_KIDS":
                                case 38:
                                    message.enabledInputTargetMetrics[i] = 38;
                                    break;
                                case "METRIC_TOTAL_KNOWN_GROUPS":
                                case 39:
                                    message.enabledInputTargetMetrics[i] = 39;
                                    break;
                                case "METRIC_GROUPS_WITH_KIDS_RATE":
                                case 40:
                                    message.enabledInputTargetMetrics[i] = 40;
                                    break;
                                case "METRIC_TOTAL_GROUPS_AND_INDIVIDUALS":
                                case 41:
                                    message.enabledInputTargetMetrics[i] = 41;
                                    break;
                                case "METRIC_CONVERSION_BY_OPPORTUNITIES":
                                case 42:
                                    message.enabledInputTargetMetrics[i] = 42;
                                    break;
                                case "METRIC_PERCENT_SECTION_VISIT":
                                case 43:
                                    message.enabledInputTargetMetrics[i] = 43;
                                    break;
                                case "METRIC_PERCENT_SECTION_PASS_BY":
                                case 44:
                                    message.enabledInputTargetMetrics[i] = 44;
                                    break;
                                case "METRIC_PASS_BY":
                                case 45:
                                    message.enabledInputTargetMetrics[i] = 45;
                                    break;
                                case "METRIC_CAPTURE_RATE":
                                case 46:
                                    message.enabledInputTargetMetrics[i] = 46;
                                    break;
                                case "METRIC_TRANSACTION":
                                case 47:
                                    message.enabledInputTargetMetrics[i] = 47;
                                    break;
                                case "METRIC_CONVERSION_RATE":
                                case 48:
                                    message.enabledInputTargetMetrics[i] = 48;
                                    break;
                                case "METRIC_NET_SALES_AMOUNT":
                                case 49:
                                    message.enabledInputTargetMetrics[i] = 49;
                                    break;
                                case "METRIC_NET_SALES_ITEMS":
                                case 50:
                                    message.enabledInputTargetMetrics[i] = 50;
                                    break;
                                case "METRIC_NET_SALES_CONTRIBUTION":
                                case 51:
                                    message.enabledInputTargetMetrics[i] = 51;
                                    break;
                                case "METRIC_NET_SALES_RANKING":
                                case 52:
                                    message.enabledInputTargetMetrics[i] = 52;
                                    break;
                                case "METRIC_SHOPPER_YIELD":
                                case 53:
                                    message.enabledInputTargetMetrics[i] = 53;
                                    break;
                                case "METRIC_ATV":
                                case 54:
                                    message.enabledInputTargetMetrics[i] = 54;
                                    break;
                                case "METRIC_UPT":
                                case 55:
                                    message.enabledInputTargetMetrics[i] = 55;
                                    break;
                                case "METRIC_UPDPS":
                                case 56:
                                    message.enabledInputTargetMetrics[i] = 56;
                                    break;
                                case "METRIC_ASP":
                                case 57:
                                    message.enabledInputTargetMetrics[i] = 57;
                                    break;
                                case "METRIC_STAFF_HOURS":
                                case 58:
                                    message.enabledInputTargetMetrics[i] = 58;
                                    break;
                                case "METRIC_VISITOR_PER_STAFF_HOUR":
                                case 59:
                                    message.enabledInputTargetMetrics[i] = 59;
                                    break;
                                case "METRIC_SALES_PER_STAFF_HOUR":
                                case 60:
                                    message.enabledInputTargetMetrics[i] = 60;
                                    break;
                                case "METRIC_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 61:
                                    message.enabledInputTargetMetrics[i] = 61;
                                    break;
                                case "METRIC_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 62:
                                    message.enabledInputTargetMetrics[i] = 62;
                                    break;
                                case "METRIC_FORECAST_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 63:
                                    message.enabledInputTargetMetrics[i] = 63;
                                    break;
                                case "METRIC_FORECAST_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 64:
                                    message.enabledInputTargetMetrics[i] = 64;
                                    break;
                                case "METRIC_ROUNDED_STAFF_HOURS":
                                case 65:
                                    message.enabledInputTargetMetrics[i] = 65;
                                    break;
                                case "METRIC_STAFF_HOUR_WITH_VISITOR_COUNT":
                                case 66:
                                    message.enabledInputTargetMetrics[i] = 66;
                                    break;
                                case "METRIC_VISITORS_PER_SQM":
                                case 67:
                                    message.enabledInputTargetMetrics[i] = 67;
                                    break;
                                case "METRIC_AVG_VISITOR_PER_STORE_PER_DAY":
                                case 68:
                                    message.enabledInputTargetMetrics[i] = 68;
                                    break;
                                case "METRIC_DAYS_WITH_VISITORS":
                                case 69:
                                    message.enabledInputTargetMetrics[i] = 69;
                                    break;
                                case "METRIC_DAYS_WITH_NET_SALES_ITEMS":
                                case 70:
                                    message.enabledInputTargetMetrics[i] = 70;
                                    break;
                                case "METRIC_SALES_PER_SQM":
                                case 71:
                                    message.enabledInputTargetMetrics[i] = 71;
                                    break;
                                case "METRIC_SALES_PER_SALES_AREA_SQM":
                                case 72:
                                    message.enabledInputTargetMetrics[i] = 72;
                                    break;
                                case "METRIC_ESTIMATED_VISIT":
                                case 73:
                                    message.enabledInputTargetMetrics[i] = 73;
                                    break;
                                case "METRIC_ESTIMATED_PASS_BY":
                                case 74:
                                    message.enabledInputTargetMetrics[i] = 74;
                                    break;
                                case "METRIC_WALK_IN":
                                case 75:
                                    message.enabledInputTargetMetrics[i] = 75;
                                    break;
                                case "METRIC_PERCENT_SECTION_ESTIMATED_PASS_BY":
                                case 76:
                                    message.enabledInputTargetMetrics[i] = 76;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1":
                                case 77:
                                    message.enabledInputTargetMetrics[i] = 77;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1_RATE":
                                case 78:
                                    message.enabledInputTargetMetrics[i] = 78;
                                    break;
                                case "METRIC_CONVERTED_CUSTOM_ACTION_1_RATE":
                                case 79:
                                    message.enabledInputTargetMetrics[i] = 79;
                                    break;
                                case "METRIC_AVG_SALES_PER_STORE_PER_DAY":
                                case 80:
                                    message.enabledInputTargetMetrics[i] = 80;
                                    break;
                                case "METRIC_STAFF_TOTAL_SERVED_VISITORS":
                                case 81:
                                    message.enabledInputTargetMetrics[i] = 81;
                                    break;
                                case "METRIC_STAFF_CONVERSION_RATE":
                                case 82:
                                    message.enabledInputTargetMetrics[i] = 82;
                                    break;
                                case "METRIC_STAFF_SALES_PER_SERVED_VISITORS":
                                case 83:
                                    message.enabledInputTargetMetrics[i] = 83;
                                    break;
                                case "METRIC_STAFF_SERVED_VISITORS_PER_STAFF_HOUR":
                                case 84:
                                    message.enabledInputTargetMetrics[i] = 84;
                                    break;
                                case "METRIC_STAFF_PERCENTAGE_OF_SALES":
                                case 85:
                                    message.enabledInputTargetMetrics[i] = 85;
                                    break;
                                case "METRIC_DAYS_WITH_SALES":
                                case 86:
                                    message.enabledInputTargetMetrics[i] = 86;
                                    break;
                                case "METRIC_VIDEO_GROUP_DISTRIBUTION":
                                case 87:
                                    message.enabledInputTargetMetrics[i] = 87;
                                    break;
                                case "METRIC_BOUNCE_RATE":
                                case 88:
                                    message.enabledInputTargetMetrics[i] = 88;
                                    break;
                                case "METRIC_AVG_MALL_VISITOR_PER_STORE_PER_DAY":
                                case 89:
                                    message.enabledInputTargetMetrics[i] = 89;
                                    break;
                                case "METRIC_TOTAL_ON_TIME_GREETED_CUSTOMERS":
                                case 90:
                                    message.enabledInputTargetMetrics[i] = 90;
                                    break;
                                case "METRIC_STAFF_STORE_NET_SALES_AMOUNT":
                                case 91:
                                    message.enabledInputTargetMetrics[i] = 91;
                                    break;
                                case "METRIC_STAFF_UNIQUE_INTERACTIONS":
                                case 92:
                                    message.enabledInputTargetMetrics[i] = 92;
                                    break;
                                case "METRIC_STAFF_INTERACTION_RATE":
                                case 93:
                                    message.enabledInputTargetMetrics[i] = 93;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_VISITORS":
                                case 94:
                                    message.enabledInputTargetMetrics[i] = 94;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_VISITORS":
                                case 95:
                                    message.enabledInputTargetMetrics[i] = 95;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_VISITORS":
                                case 96:
                                    message.enabledInputTargetMetrics[i] = 96;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME":
                                case 97:
                                    message.enabledInputTargetMetrics[i] = 97;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME":
                                case 98:
                                    message.enabledInputTargetMetrics[i] = 98;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_OPPORTUNITIES":
                                case 99:
                                    message.enabledInputTargetMetrics[i] = 99;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 100:
                                    message.enabledInputTargetMetrics[i] = 100;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 101:
                                    message.enabledInputTargetMetrics[i] = 101;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 102:
                                    message.enabledInputTargetMetrics[i] = 102;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 103:
                                    message.enabledInputTargetMetrics[i] = 103;
                                    break;
                                case "METRIC_DISCOUNT_AMOUNT":
                                case 104:
                                    message.enabledInputTargetMetrics[i] = 104;
                                    break;
                                case "METRIC_DISCOUNT_RATE":
                                case 105:
                                    message.enabledInputTargetMetrics[i] = 105;
                                    break;
                                case "METRIC_GROSS_AMOUNT":
                                case 106:
                                    message.enabledInputTargetMetrics[i] = 106;
                                    break;
                                }
                        }
                        if (object.enabledDisplayTargetMetrics) {
                            if (!Array.isArray(object.enabledDisplayTargetMetrics))
                                throw TypeError(".palexy.streaming.v1.Company.enabledDisplayTargetMetrics: array expected");
                            message.enabledDisplayTargetMetrics = [];
                            for (var i = 0; i < object.enabledDisplayTargetMetrics.length; ++i)
                                switch (object.enabledDisplayTargetMetrics[i]) {
                                default:
                                case "METRIC_UNKNOWN":
                                case 0:
                                    message.enabledDisplayTargetMetrics[i] = 0;
                                    break;
                                case "METRIC_BUYER_TRAFFIC":
                                case 1:
                                    message.enabledDisplayTargetMetrics[i] = 1;
                                    break;
                                case "METRIC_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 2:
                                    message.enabledDisplayTargetMetrics[i] = 2;
                                    break;
                                case "METRIC_INTERACTION":
                                case 3:
                                    message.enabledDisplayTargetMetrics[i] = 3;
                                    break;
                                case "METRIC_VISIT":
                                case 4:
                                    message.enabledDisplayTargetMetrics[i] = 4;
                                    break;
                                case "METRIC_GROUP_VISIT":
                                case 5:
                                    message.enabledDisplayTargetMetrics[i] = 5;
                                    break;
                                case "METRIC_GROUP_INTERACTION":
                                case 6:
                                    message.enabledDisplayTargetMetrics[i] = 6;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC":
                                case 7:
                                    message.enabledDisplayTargetMetrics[i] = 7;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 8:
                                    message.enabledDisplayTargetMetrics[i] = 8;
                                    break;
                                case "METRIC_BUYER_RATE":
                                case 9:
                                    message.enabledDisplayTargetMetrics[i] = 9;
                                    break;
                                case "METRIC_INTERACTION_RATE":
                                case 10:
                                    message.enabledDisplayTargetMetrics[i] = 10;
                                    break;
                                case "METRIC_INTERACTION_TIME":
                                case 11:
                                    message.enabledDisplayTargetMetrics[i] = 11;
                                    break;
                                case "METRIC_INTERACTION_TIME_AVG":
                                case 12:
                                    message.enabledDisplayTargetMetrics[i] = 12;
                                    break;
                                case "METRIC_INTERACTION_LEVEL":
                                case 13:
                                    message.enabledDisplayTargetMetrics[i] = 13;
                                    break;
                                case "METRIC_INTERACTION_BUYER_RATE":
                                case 14:
                                    message.enabledDisplayTargetMetrics[i] = 14;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_INTERACTION":
                                case 15:
                                    message.enabledDisplayTargetMetrics[i] = 15;
                                    break;
                                case "METRIC_ON_TIME_INTERACTION_RATE":
                                case 16:
                                    message.enabledDisplayTargetMetrics[i] = 16;
                                    break;
                                case "METRIC_GROUP_BUYER_RATE":
                                case 17:
                                    message.enabledDisplayTargetMetrics[i] = 17;
                                    break;
                                case "METRIC_GROUP_INTERACTION_RATE":
                                case 18:
                                    message.enabledDisplayTargetMetrics[i] = 18;
                                    break;
                                case "METRIC_GROUP_INTERACTION_BUYER_RATE":
                                case 19:
                                    message.enabledDisplayTargetMetrics[i] = 19;
                                    break;
                                case "METRIC_LYING":
                                case 20:
                                    message.enabledDisplayTargetMetrics[i] = 20;
                                    break;
                                case "METRIC_LYING_RATE":
                                case 21:
                                    message.enabledDisplayTargetMetrics[i] = 21;
                                    break;
                                case "METRIC_LYING_TIME_AVG":
                                case 22:
                                    message.enabledDisplayTargetMetrics[i] = 22;
                                    break;
                                case "METRIC_LYING_EXACT_ONE":
                                case 23:
                                    message.enabledDisplayTargetMetrics[i] = 23;
                                    break;
                                case "METRIC_LYING_AT_LEAST_ONE":
                                case 24:
                                    message.enabledDisplayTargetMetrics[i] = 24;
                                    break;
                                case "METRIC_LYING_AT_LEAST_TWO":
                                case 25:
                                    message.enabledDisplayTargetMetrics[i] = 25;
                                    break;
                                case "METRIC_DWELL_TIME":
                                case 26:
                                    message.enabledDisplayTargetMetrics[i] = 26;
                                    break;
                                case "METRIC_DWELL_TIME_AVG":
                                case 27:
                                    message.enabledDisplayTargetMetrics[i] = 27;
                                    break;
                                case "METRIC_TOTAL_GREETED_CUSTOMERS":
                                case 28:
                                    message.enabledDisplayTargetMetrics[i] = 28;
                                    break;
                                case "METRIC_GREETING_RATE":
                                case 29:
                                    message.enabledDisplayTargetMetrics[i] = 29;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_GREETING":
                                case 30:
                                    message.enabledDisplayTargetMetrics[i] = 30;
                                    break;
                                case "METRIC_ON_TIME_GREETING_RATE":
                                case 31:
                                    message.enabledDisplayTargetMetrics[i] = 31;
                                    break;
                                case "METRIC_FOOT_TRAFFIC":
                                case 32:
                                    message.enabledDisplayTargetMetrics[i] = 32;
                                    break;
                                case "METRIC_VISITOR_OCCUPANCY":
                                case 33:
                                    message.enabledDisplayTargetMetrics[i] = 33;
                                    break;
                                case "METRIC_TOTAL_GROUPS":
                                case 34:
                                    message.enabledDisplayTargetMetrics[i] = 34;
                                    break;
                                case "METRIC_TOTAL_VISITORS_GOING_AS_GROUPS":
                                case 35:
                                    message.enabledDisplayTargetMetrics[i] = 35;
                                    break;
                                case "METRIC_GROUP_RATE":
                                case 36:
                                    message.enabledDisplayTargetMetrics[i] = 36;
                                    break;
                                case "METRIC_AVG_GROUP_SIZE":
                                case 37:
                                    message.enabledDisplayTargetMetrics[i] = 37;
                                    break;
                                case "METRIC_TOTAL_GROUPS_WITH_KIDS":
                                case 38:
                                    message.enabledDisplayTargetMetrics[i] = 38;
                                    break;
                                case "METRIC_TOTAL_KNOWN_GROUPS":
                                case 39:
                                    message.enabledDisplayTargetMetrics[i] = 39;
                                    break;
                                case "METRIC_GROUPS_WITH_KIDS_RATE":
                                case 40:
                                    message.enabledDisplayTargetMetrics[i] = 40;
                                    break;
                                case "METRIC_TOTAL_GROUPS_AND_INDIVIDUALS":
                                case 41:
                                    message.enabledDisplayTargetMetrics[i] = 41;
                                    break;
                                case "METRIC_CONVERSION_BY_OPPORTUNITIES":
                                case 42:
                                    message.enabledDisplayTargetMetrics[i] = 42;
                                    break;
                                case "METRIC_PERCENT_SECTION_VISIT":
                                case 43:
                                    message.enabledDisplayTargetMetrics[i] = 43;
                                    break;
                                case "METRIC_PERCENT_SECTION_PASS_BY":
                                case 44:
                                    message.enabledDisplayTargetMetrics[i] = 44;
                                    break;
                                case "METRIC_PASS_BY":
                                case 45:
                                    message.enabledDisplayTargetMetrics[i] = 45;
                                    break;
                                case "METRIC_CAPTURE_RATE":
                                case 46:
                                    message.enabledDisplayTargetMetrics[i] = 46;
                                    break;
                                case "METRIC_TRANSACTION":
                                case 47:
                                    message.enabledDisplayTargetMetrics[i] = 47;
                                    break;
                                case "METRIC_CONVERSION_RATE":
                                case 48:
                                    message.enabledDisplayTargetMetrics[i] = 48;
                                    break;
                                case "METRIC_NET_SALES_AMOUNT":
                                case 49:
                                    message.enabledDisplayTargetMetrics[i] = 49;
                                    break;
                                case "METRIC_NET_SALES_ITEMS":
                                case 50:
                                    message.enabledDisplayTargetMetrics[i] = 50;
                                    break;
                                case "METRIC_NET_SALES_CONTRIBUTION":
                                case 51:
                                    message.enabledDisplayTargetMetrics[i] = 51;
                                    break;
                                case "METRIC_NET_SALES_RANKING":
                                case 52:
                                    message.enabledDisplayTargetMetrics[i] = 52;
                                    break;
                                case "METRIC_SHOPPER_YIELD":
                                case 53:
                                    message.enabledDisplayTargetMetrics[i] = 53;
                                    break;
                                case "METRIC_ATV":
                                case 54:
                                    message.enabledDisplayTargetMetrics[i] = 54;
                                    break;
                                case "METRIC_UPT":
                                case 55:
                                    message.enabledDisplayTargetMetrics[i] = 55;
                                    break;
                                case "METRIC_UPDPS":
                                case 56:
                                    message.enabledDisplayTargetMetrics[i] = 56;
                                    break;
                                case "METRIC_ASP":
                                case 57:
                                    message.enabledDisplayTargetMetrics[i] = 57;
                                    break;
                                case "METRIC_STAFF_HOURS":
                                case 58:
                                    message.enabledDisplayTargetMetrics[i] = 58;
                                    break;
                                case "METRIC_VISITOR_PER_STAFF_HOUR":
                                case 59:
                                    message.enabledDisplayTargetMetrics[i] = 59;
                                    break;
                                case "METRIC_SALES_PER_STAFF_HOUR":
                                case 60:
                                    message.enabledDisplayTargetMetrics[i] = 60;
                                    break;
                                case "METRIC_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 61:
                                    message.enabledDisplayTargetMetrics[i] = 61;
                                    break;
                                case "METRIC_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 62:
                                    message.enabledDisplayTargetMetrics[i] = 62;
                                    break;
                                case "METRIC_FORECAST_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 63:
                                    message.enabledDisplayTargetMetrics[i] = 63;
                                    break;
                                case "METRIC_FORECAST_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 64:
                                    message.enabledDisplayTargetMetrics[i] = 64;
                                    break;
                                case "METRIC_ROUNDED_STAFF_HOURS":
                                case 65:
                                    message.enabledDisplayTargetMetrics[i] = 65;
                                    break;
                                case "METRIC_STAFF_HOUR_WITH_VISITOR_COUNT":
                                case 66:
                                    message.enabledDisplayTargetMetrics[i] = 66;
                                    break;
                                case "METRIC_VISITORS_PER_SQM":
                                case 67:
                                    message.enabledDisplayTargetMetrics[i] = 67;
                                    break;
                                case "METRIC_AVG_VISITOR_PER_STORE_PER_DAY":
                                case 68:
                                    message.enabledDisplayTargetMetrics[i] = 68;
                                    break;
                                case "METRIC_DAYS_WITH_VISITORS":
                                case 69:
                                    message.enabledDisplayTargetMetrics[i] = 69;
                                    break;
                                case "METRIC_DAYS_WITH_NET_SALES_ITEMS":
                                case 70:
                                    message.enabledDisplayTargetMetrics[i] = 70;
                                    break;
                                case "METRIC_SALES_PER_SQM":
                                case 71:
                                    message.enabledDisplayTargetMetrics[i] = 71;
                                    break;
                                case "METRIC_SALES_PER_SALES_AREA_SQM":
                                case 72:
                                    message.enabledDisplayTargetMetrics[i] = 72;
                                    break;
                                case "METRIC_ESTIMATED_VISIT":
                                case 73:
                                    message.enabledDisplayTargetMetrics[i] = 73;
                                    break;
                                case "METRIC_ESTIMATED_PASS_BY":
                                case 74:
                                    message.enabledDisplayTargetMetrics[i] = 74;
                                    break;
                                case "METRIC_WALK_IN":
                                case 75:
                                    message.enabledDisplayTargetMetrics[i] = 75;
                                    break;
                                case "METRIC_PERCENT_SECTION_ESTIMATED_PASS_BY":
                                case 76:
                                    message.enabledDisplayTargetMetrics[i] = 76;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1":
                                case 77:
                                    message.enabledDisplayTargetMetrics[i] = 77;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1_RATE":
                                case 78:
                                    message.enabledDisplayTargetMetrics[i] = 78;
                                    break;
                                case "METRIC_CONVERTED_CUSTOM_ACTION_1_RATE":
                                case 79:
                                    message.enabledDisplayTargetMetrics[i] = 79;
                                    break;
                                case "METRIC_AVG_SALES_PER_STORE_PER_DAY":
                                case 80:
                                    message.enabledDisplayTargetMetrics[i] = 80;
                                    break;
                                case "METRIC_STAFF_TOTAL_SERVED_VISITORS":
                                case 81:
                                    message.enabledDisplayTargetMetrics[i] = 81;
                                    break;
                                case "METRIC_STAFF_CONVERSION_RATE":
                                case 82:
                                    message.enabledDisplayTargetMetrics[i] = 82;
                                    break;
                                case "METRIC_STAFF_SALES_PER_SERVED_VISITORS":
                                case 83:
                                    message.enabledDisplayTargetMetrics[i] = 83;
                                    break;
                                case "METRIC_STAFF_SERVED_VISITORS_PER_STAFF_HOUR":
                                case 84:
                                    message.enabledDisplayTargetMetrics[i] = 84;
                                    break;
                                case "METRIC_STAFF_PERCENTAGE_OF_SALES":
                                case 85:
                                    message.enabledDisplayTargetMetrics[i] = 85;
                                    break;
                                case "METRIC_DAYS_WITH_SALES":
                                case 86:
                                    message.enabledDisplayTargetMetrics[i] = 86;
                                    break;
                                case "METRIC_VIDEO_GROUP_DISTRIBUTION":
                                case 87:
                                    message.enabledDisplayTargetMetrics[i] = 87;
                                    break;
                                case "METRIC_BOUNCE_RATE":
                                case 88:
                                    message.enabledDisplayTargetMetrics[i] = 88;
                                    break;
                                case "METRIC_AVG_MALL_VISITOR_PER_STORE_PER_DAY":
                                case 89:
                                    message.enabledDisplayTargetMetrics[i] = 89;
                                    break;
                                case "METRIC_TOTAL_ON_TIME_GREETED_CUSTOMERS":
                                case 90:
                                    message.enabledDisplayTargetMetrics[i] = 90;
                                    break;
                                case "METRIC_STAFF_STORE_NET_SALES_AMOUNT":
                                case 91:
                                    message.enabledDisplayTargetMetrics[i] = 91;
                                    break;
                                case "METRIC_STAFF_UNIQUE_INTERACTIONS":
                                case 92:
                                    message.enabledDisplayTargetMetrics[i] = 92;
                                    break;
                                case "METRIC_STAFF_INTERACTION_RATE":
                                case 93:
                                    message.enabledDisplayTargetMetrics[i] = 93;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_VISITORS":
                                case 94:
                                    message.enabledDisplayTargetMetrics[i] = 94;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_VISITORS":
                                case 95:
                                    message.enabledDisplayTargetMetrics[i] = 95;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_VISITORS":
                                case 96:
                                    message.enabledDisplayTargetMetrics[i] = 96;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME":
                                case 97:
                                    message.enabledDisplayTargetMetrics[i] = 97;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME":
                                case 98:
                                    message.enabledDisplayTargetMetrics[i] = 98;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_OPPORTUNITIES":
                                case 99:
                                    message.enabledDisplayTargetMetrics[i] = 99;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 100:
                                    message.enabledDisplayTargetMetrics[i] = 100;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 101:
                                    message.enabledDisplayTargetMetrics[i] = 101;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 102:
                                    message.enabledDisplayTargetMetrics[i] = 102;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 103:
                                    message.enabledDisplayTargetMetrics[i] = 103;
                                    break;
                                case "METRIC_DISCOUNT_AMOUNT":
                                case 104:
                                    message.enabledDisplayTargetMetrics[i] = 104;
                                    break;
                                case "METRIC_DISCOUNT_RATE":
                                case 105:
                                    message.enabledDisplayTargetMetrics[i] = 105;
                                    break;
                                case "METRIC_GROSS_AMOUNT":
                                case 106:
                                    message.enabledDisplayTargetMetrics[i] = 106;
                                    break;
                                }
                        }
                        if (object.enabledGenerateInsightTypes) {
                            if (!Array.isArray(object.enabledGenerateInsightTypes))
                                throw TypeError(".palexy.streaming.v1.Company.enabledGenerateInsightTypes: array expected");
                            message.enabledGenerateInsightTypes = [];
                            for (var i = 0; i < object.enabledGenerateInsightTypes.length; ++i)
                                message.enabledGenerateInsightTypes[i] = String(object.enabledGenerateInsightTypes[i]);
                        }
                        if (object.enabledDisplayInsightTypes) {
                            if (!Array.isArray(object.enabledDisplayInsightTypes))
                                throw TypeError(".palexy.streaming.v1.Company.enabledDisplayInsightTypes: array expected");
                            message.enabledDisplayInsightTypes = [];
                            for (var i = 0; i < object.enabledDisplayInsightTypes.length; ++i)
                                message.enabledDisplayInsightTypes[i] = String(object.enabledDisplayInsightTypes[i]);
                        }
                        if (object.threeWeeksTrendInsightMetrics) {
                            if (!Array.isArray(object.threeWeeksTrendInsightMetrics))
                                throw TypeError(".palexy.streaming.v1.Company.threeWeeksTrendInsightMetrics: array expected");
                            message.threeWeeksTrendInsightMetrics = [];
                            for (var i = 0; i < object.threeWeeksTrendInsightMetrics.length; ++i)
                                switch (object.threeWeeksTrendInsightMetrics[i]) {
                                default:
                                case "METRIC_UNKNOWN":
                                case 0:
                                    message.threeWeeksTrendInsightMetrics[i] = 0;
                                    break;
                                case "METRIC_BUYER_TRAFFIC":
                                case 1:
                                    message.threeWeeksTrendInsightMetrics[i] = 1;
                                    break;
                                case "METRIC_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 2:
                                    message.threeWeeksTrendInsightMetrics[i] = 2;
                                    break;
                                case "METRIC_INTERACTION":
                                case 3:
                                    message.threeWeeksTrendInsightMetrics[i] = 3;
                                    break;
                                case "METRIC_VISIT":
                                case 4:
                                    message.threeWeeksTrendInsightMetrics[i] = 4;
                                    break;
                                case "METRIC_GROUP_VISIT":
                                case 5:
                                    message.threeWeeksTrendInsightMetrics[i] = 5;
                                    break;
                                case "METRIC_GROUP_INTERACTION":
                                case 6:
                                    message.threeWeeksTrendInsightMetrics[i] = 6;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC":
                                case 7:
                                    message.threeWeeksTrendInsightMetrics[i] = 7;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 8:
                                    message.threeWeeksTrendInsightMetrics[i] = 8;
                                    break;
                                case "METRIC_BUYER_RATE":
                                case 9:
                                    message.threeWeeksTrendInsightMetrics[i] = 9;
                                    break;
                                case "METRIC_INTERACTION_RATE":
                                case 10:
                                    message.threeWeeksTrendInsightMetrics[i] = 10;
                                    break;
                                case "METRIC_INTERACTION_TIME":
                                case 11:
                                    message.threeWeeksTrendInsightMetrics[i] = 11;
                                    break;
                                case "METRIC_INTERACTION_TIME_AVG":
                                case 12:
                                    message.threeWeeksTrendInsightMetrics[i] = 12;
                                    break;
                                case "METRIC_INTERACTION_LEVEL":
                                case 13:
                                    message.threeWeeksTrendInsightMetrics[i] = 13;
                                    break;
                                case "METRIC_INTERACTION_BUYER_RATE":
                                case 14:
                                    message.threeWeeksTrendInsightMetrics[i] = 14;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_INTERACTION":
                                case 15:
                                    message.threeWeeksTrendInsightMetrics[i] = 15;
                                    break;
                                case "METRIC_ON_TIME_INTERACTION_RATE":
                                case 16:
                                    message.threeWeeksTrendInsightMetrics[i] = 16;
                                    break;
                                case "METRIC_GROUP_BUYER_RATE":
                                case 17:
                                    message.threeWeeksTrendInsightMetrics[i] = 17;
                                    break;
                                case "METRIC_GROUP_INTERACTION_RATE":
                                case 18:
                                    message.threeWeeksTrendInsightMetrics[i] = 18;
                                    break;
                                case "METRIC_GROUP_INTERACTION_BUYER_RATE":
                                case 19:
                                    message.threeWeeksTrendInsightMetrics[i] = 19;
                                    break;
                                case "METRIC_LYING":
                                case 20:
                                    message.threeWeeksTrendInsightMetrics[i] = 20;
                                    break;
                                case "METRIC_LYING_RATE":
                                case 21:
                                    message.threeWeeksTrendInsightMetrics[i] = 21;
                                    break;
                                case "METRIC_LYING_TIME_AVG":
                                case 22:
                                    message.threeWeeksTrendInsightMetrics[i] = 22;
                                    break;
                                case "METRIC_LYING_EXACT_ONE":
                                case 23:
                                    message.threeWeeksTrendInsightMetrics[i] = 23;
                                    break;
                                case "METRIC_LYING_AT_LEAST_ONE":
                                case 24:
                                    message.threeWeeksTrendInsightMetrics[i] = 24;
                                    break;
                                case "METRIC_LYING_AT_LEAST_TWO":
                                case 25:
                                    message.threeWeeksTrendInsightMetrics[i] = 25;
                                    break;
                                case "METRIC_DWELL_TIME":
                                case 26:
                                    message.threeWeeksTrendInsightMetrics[i] = 26;
                                    break;
                                case "METRIC_DWELL_TIME_AVG":
                                case 27:
                                    message.threeWeeksTrendInsightMetrics[i] = 27;
                                    break;
                                case "METRIC_TOTAL_GREETED_CUSTOMERS":
                                case 28:
                                    message.threeWeeksTrendInsightMetrics[i] = 28;
                                    break;
                                case "METRIC_GREETING_RATE":
                                case 29:
                                    message.threeWeeksTrendInsightMetrics[i] = 29;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_GREETING":
                                case 30:
                                    message.threeWeeksTrendInsightMetrics[i] = 30;
                                    break;
                                case "METRIC_ON_TIME_GREETING_RATE":
                                case 31:
                                    message.threeWeeksTrendInsightMetrics[i] = 31;
                                    break;
                                case "METRIC_FOOT_TRAFFIC":
                                case 32:
                                    message.threeWeeksTrendInsightMetrics[i] = 32;
                                    break;
                                case "METRIC_VISITOR_OCCUPANCY":
                                case 33:
                                    message.threeWeeksTrendInsightMetrics[i] = 33;
                                    break;
                                case "METRIC_TOTAL_GROUPS":
                                case 34:
                                    message.threeWeeksTrendInsightMetrics[i] = 34;
                                    break;
                                case "METRIC_TOTAL_VISITORS_GOING_AS_GROUPS":
                                case 35:
                                    message.threeWeeksTrendInsightMetrics[i] = 35;
                                    break;
                                case "METRIC_GROUP_RATE":
                                case 36:
                                    message.threeWeeksTrendInsightMetrics[i] = 36;
                                    break;
                                case "METRIC_AVG_GROUP_SIZE":
                                case 37:
                                    message.threeWeeksTrendInsightMetrics[i] = 37;
                                    break;
                                case "METRIC_TOTAL_GROUPS_WITH_KIDS":
                                case 38:
                                    message.threeWeeksTrendInsightMetrics[i] = 38;
                                    break;
                                case "METRIC_TOTAL_KNOWN_GROUPS":
                                case 39:
                                    message.threeWeeksTrendInsightMetrics[i] = 39;
                                    break;
                                case "METRIC_GROUPS_WITH_KIDS_RATE":
                                case 40:
                                    message.threeWeeksTrendInsightMetrics[i] = 40;
                                    break;
                                case "METRIC_TOTAL_GROUPS_AND_INDIVIDUALS":
                                case 41:
                                    message.threeWeeksTrendInsightMetrics[i] = 41;
                                    break;
                                case "METRIC_CONVERSION_BY_OPPORTUNITIES":
                                case 42:
                                    message.threeWeeksTrendInsightMetrics[i] = 42;
                                    break;
                                case "METRIC_PERCENT_SECTION_VISIT":
                                case 43:
                                    message.threeWeeksTrendInsightMetrics[i] = 43;
                                    break;
                                case "METRIC_PERCENT_SECTION_PASS_BY":
                                case 44:
                                    message.threeWeeksTrendInsightMetrics[i] = 44;
                                    break;
                                case "METRIC_PASS_BY":
                                case 45:
                                    message.threeWeeksTrendInsightMetrics[i] = 45;
                                    break;
                                case "METRIC_CAPTURE_RATE":
                                case 46:
                                    message.threeWeeksTrendInsightMetrics[i] = 46;
                                    break;
                                case "METRIC_TRANSACTION":
                                case 47:
                                    message.threeWeeksTrendInsightMetrics[i] = 47;
                                    break;
                                case "METRIC_CONVERSION_RATE":
                                case 48:
                                    message.threeWeeksTrendInsightMetrics[i] = 48;
                                    break;
                                case "METRIC_NET_SALES_AMOUNT":
                                case 49:
                                    message.threeWeeksTrendInsightMetrics[i] = 49;
                                    break;
                                case "METRIC_NET_SALES_ITEMS":
                                case 50:
                                    message.threeWeeksTrendInsightMetrics[i] = 50;
                                    break;
                                case "METRIC_NET_SALES_CONTRIBUTION":
                                case 51:
                                    message.threeWeeksTrendInsightMetrics[i] = 51;
                                    break;
                                case "METRIC_NET_SALES_RANKING":
                                case 52:
                                    message.threeWeeksTrendInsightMetrics[i] = 52;
                                    break;
                                case "METRIC_SHOPPER_YIELD":
                                case 53:
                                    message.threeWeeksTrendInsightMetrics[i] = 53;
                                    break;
                                case "METRIC_ATV":
                                case 54:
                                    message.threeWeeksTrendInsightMetrics[i] = 54;
                                    break;
                                case "METRIC_UPT":
                                case 55:
                                    message.threeWeeksTrendInsightMetrics[i] = 55;
                                    break;
                                case "METRIC_UPDPS":
                                case 56:
                                    message.threeWeeksTrendInsightMetrics[i] = 56;
                                    break;
                                case "METRIC_ASP":
                                case 57:
                                    message.threeWeeksTrendInsightMetrics[i] = 57;
                                    break;
                                case "METRIC_STAFF_HOURS":
                                case 58:
                                    message.threeWeeksTrendInsightMetrics[i] = 58;
                                    break;
                                case "METRIC_VISITOR_PER_STAFF_HOUR":
                                case 59:
                                    message.threeWeeksTrendInsightMetrics[i] = 59;
                                    break;
                                case "METRIC_SALES_PER_STAFF_HOUR":
                                case 60:
                                    message.threeWeeksTrendInsightMetrics[i] = 60;
                                    break;
                                case "METRIC_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 61:
                                    message.threeWeeksTrendInsightMetrics[i] = 61;
                                    break;
                                case "METRIC_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 62:
                                    message.threeWeeksTrendInsightMetrics[i] = 62;
                                    break;
                                case "METRIC_FORECAST_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 63:
                                    message.threeWeeksTrendInsightMetrics[i] = 63;
                                    break;
                                case "METRIC_FORECAST_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 64:
                                    message.threeWeeksTrendInsightMetrics[i] = 64;
                                    break;
                                case "METRIC_ROUNDED_STAFF_HOURS":
                                case 65:
                                    message.threeWeeksTrendInsightMetrics[i] = 65;
                                    break;
                                case "METRIC_STAFF_HOUR_WITH_VISITOR_COUNT":
                                case 66:
                                    message.threeWeeksTrendInsightMetrics[i] = 66;
                                    break;
                                case "METRIC_VISITORS_PER_SQM":
                                case 67:
                                    message.threeWeeksTrendInsightMetrics[i] = 67;
                                    break;
                                case "METRIC_AVG_VISITOR_PER_STORE_PER_DAY":
                                case 68:
                                    message.threeWeeksTrendInsightMetrics[i] = 68;
                                    break;
                                case "METRIC_DAYS_WITH_VISITORS":
                                case 69:
                                    message.threeWeeksTrendInsightMetrics[i] = 69;
                                    break;
                                case "METRIC_DAYS_WITH_NET_SALES_ITEMS":
                                case 70:
                                    message.threeWeeksTrendInsightMetrics[i] = 70;
                                    break;
                                case "METRIC_SALES_PER_SQM":
                                case 71:
                                    message.threeWeeksTrendInsightMetrics[i] = 71;
                                    break;
                                case "METRIC_SALES_PER_SALES_AREA_SQM":
                                case 72:
                                    message.threeWeeksTrendInsightMetrics[i] = 72;
                                    break;
                                case "METRIC_ESTIMATED_VISIT":
                                case 73:
                                    message.threeWeeksTrendInsightMetrics[i] = 73;
                                    break;
                                case "METRIC_ESTIMATED_PASS_BY":
                                case 74:
                                    message.threeWeeksTrendInsightMetrics[i] = 74;
                                    break;
                                case "METRIC_WALK_IN":
                                case 75:
                                    message.threeWeeksTrendInsightMetrics[i] = 75;
                                    break;
                                case "METRIC_PERCENT_SECTION_ESTIMATED_PASS_BY":
                                case 76:
                                    message.threeWeeksTrendInsightMetrics[i] = 76;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1":
                                case 77:
                                    message.threeWeeksTrendInsightMetrics[i] = 77;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1_RATE":
                                case 78:
                                    message.threeWeeksTrendInsightMetrics[i] = 78;
                                    break;
                                case "METRIC_CONVERTED_CUSTOM_ACTION_1_RATE":
                                case 79:
                                    message.threeWeeksTrendInsightMetrics[i] = 79;
                                    break;
                                case "METRIC_AVG_SALES_PER_STORE_PER_DAY":
                                case 80:
                                    message.threeWeeksTrendInsightMetrics[i] = 80;
                                    break;
                                case "METRIC_STAFF_TOTAL_SERVED_VISITORS":
                                case 81:
                                    message.threeWeeksTrendInsightMetrics[i] = 81;
                                    break;
                                case "METRIC_STAFF_CONVERSION_RATE":
                                case 82:
                                    message.threeWeeksTrendInsightMetrics[i] = 82;
                                    break;
                                case "METRIC_STAFF_SALES_PER_SERVED_VISITORS":
                                case 83:
                                    message.threeWeeksTrendInsightMetrics[i] = 83;
                                    break;
                                case "METRIC_STAFF_SERVED_VISITORS_PER_STAFF_HOUR":
                                case 84:
                                    message.threeWeeksTrendInsightMetrics[i] = 84;
                                    break;
                                case "METRIC_STAFF_PERCENTAGE_OF_SALES":
                                case 85:
                                    message.threeWeeksTrendInsightMetrics[i] = 85;
                                    break;
                                case "METRIC_DAYS_WITH_SALES":
                                case 86:
                                    message.threeWeeksTrendInsightMetrics[i] = 86;
                                    break;
                                case "METRIC_VIDEO_GROUP_DISTRIBUTION":
                                case 87:
                                    message.threeWeeksTrendInsightMetrics[i] = 87;
                                    break;
                                case "METRIC_BOUNCE_RATE":
                                case 88:
                                    message.threeWeeksTrendInsightMetrics[i] = 88;
                                    break;
                                case "METRIC_AVG_MALL_VISITOR_PER_STORE_PER_DAY":
                                case 89:
                                    message.threeWeeksTrendInsightMetrics[i] = 89;
                                    break;
                                case "METRIC_TOTAL_ON_TIME_GREETED_CUSTOMERS":
                                case 90:
                                    message.threeWeeksTrendInsightMetrics[i] = 90;
                                    break;
                                case "METRIC_STAFF_STORE_NET_SALES_AMOUNT":
                                case 91:
                                    message.threeWeeksTrendInsightMetrics[i] = 91;
                                    break;
                                case "METRIC_STAFF_UNIQUE_INTERACTIONS":
                                case 92:
                                    message.threeWeeksTrendInsightMetrics[i] = 92;
                                    break;
                                case "METRIC_STAFF_INTERACTION_RATE":
                                case 93:
                                    message.threeWeeksTrendInsightMetrics[i] = 93;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_VISITORS":
                                case 94:
                                    message.threeWeeksTrendInsightMetrics[i] = 94;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_VISITORS":
                                case 95:
                                    message.threeWeeksTrendInsightMetrics[i] = 95;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_VISITORS":
                                case 96:
                                    message.threeWeeksTrendInsightMetrics[i] = 96;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME":
                                case 97:
                                    message.threeWeeksTrendInsightMetrics[i] = 97;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME":
                                case 98:
                                    message.threeWeeksTrendInsightMetrics[i] = 98;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_OPPORTUNITIES":
                                case 99:
                                    message.threeWeeksTrendInsightMetrics[i] = 99;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 100:
                                    message.threeWeeksTrendInsightMetrics[i] = 100;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 101:
                                    message.threeWeeksTrendInsightMetrics[i] = 101;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 102:
                                    message.threeWeeksTrendInsightMetrics[i] = 102;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 103:
                                    message.threeWeeksTrendInsightMetrics[i] = 103;
                                    break;
                                case "METRIC_DISCOUNT_AMOUNT":
                                case 104:
                                    message.threeWeeksTrendInsightMetrics[i] = 104;
                                    break;
                                case "METRIC_DISCOUNT_RATE":
                                case 105:
                                    message.threeWeeksTrendInsightMetrics[i] = 105;
                                    break;
                                case "METRIC_GROSS_AMOUNT":
                                case 106:
                                    message.threeWeeksTrendInsightMetrics[i] = 106;
                                    break;
                                }
                        }
                        if (object.excludedScheduledTaskTypes) {
                            if (!Array.isArray(object.excludedScheduledTaskTypes))
                                throw TypeError(".palexy.streaming.v1.Company.excludedScheduledTaskTypes: array expected");
                            message.excludedScheduledTaskTypes = [];
                            for (var i = 0; i < object.excludedScheduledTaskTypes.length; ++i)
                                switch (object.excludedScheduledTaskTypes[i]) {
                                default:
                                case "SCHEDULED_TASK_TYPE_UNKNOWN":
                                case 0:
                                    message.excludedScheduledTaskTypes[i] = 0;
                                    break;
                                case "SCHEDULED_CREATE_WEEKLY_INSIGHTS_TASKS":
                                case 1:
                                    message.excludedScheduledTaskTypes[i] = 1;
                                    break;
                                case "SCHEDULED_CREATE_MONTHLY_INSIGHTS_TASKS":
                                case 2:
                                    message.excludedScheduledTaskTypes[i] = 2;
                                    break;
                                case "SCHEDULED_CREATE_MONTHLY_TREND_REPORT_TASKS":
                                case 3:
                                    message.excludedScheduledTaskTypes[i] = 3;
                                    break;
                                }
                        }
                        if (object.videoGroupSizeBuckets != null)
                            message.videoGroupSizeBuckets = String(object.videoGroupSizeBuckets);
                        if (object.criticalHoursOfDaysOfWeek) {
                            if (!Array.isArray(object.criticalHoursOfDaysOfWeek))
                                throw TypeError(".palexy.streaming.v1.Company.criticalHoursOfDaysOfWeek: array expected");
                            message.criticalHoursOfDaysOfWeek = [];
                            for (var i = 0; i < object.criticalHoursOfDaysOfWeek.length; ++i) {
                                if (typeof object.criticalHoursOfDaysOfWeek[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.Company.criticalHoursOfDaysOfWeek: object expected");
                                message.criticalHoursOfDaysOfWeek[i] = $root.palexy.streaming.v1.CriticalHoursOfDayOfWeek.fromObject(object.criticalHoursOfDaysOfWeek[i]);
                            }
                        }
                        if (object.posStaffSyncEnabled != null)
                            message.posStaffSyncEnabled = Boolean(object.posStaffSyncEnabled);
                        if (object.storeRelationshipEnabled != null)
                            message.storeRelationshipEnabled = Boolean(object.storeRelationshipEnabled);
                        if (object.discountMetricsEnabled != null)
                            message.discountMetricsEnabled = Boolean(object.discountMetricsEnabled);
                        if (object.discountDistributionBuckets != null)
                            message.discountDistributionBuckets = String(object.discountDistributionBuckets);
                        if (object.includeReturningItemsForDiscountAnalysis != null)
                            message.includeReturningItemsForDiscountAnalysis = Boolean(object.includeReturningItemsForDiscountAnalysis);
                        if (object.ownerAccount != null) {
                            if (typeof object.ownerAccount !== "object")
                                throw TypeError(".palexy.streaming.v1.Company.ownerAccount: object expected");
                            message.ownerAccount = $root.palexy.streaming.v1.Account.fromObject(object.ownerAccount);
                        }
                        if (object.storeTimezones) {
                            if (!Array.isArray(object.storeTimezones))
                                throw TypeError(".palexy.streaming.v1.Company.storeTimezones: array expected");
                            message.storeTimezones = [];
                            for (var i = 0; i < object.storeTimezones.length; ++i)
                                message.storeTimezones[i] = String(object.storeTimezones[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Company message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Company
                     * @static
                     * @param {palexy.streaming.v1.Company} message Company
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Company.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.enabledStoreEventTypes = [];
                            object.storeSnapshotScheduledTimes = [];
                            object.weekends = [];
                            object.defaultLandingMetrics = [];
                            object.enabledInputTargetMetrics = [];
                            object.enabledDisplayTargetMetrics = [];
                            object.enabledGenerateInsightTypes = [];
                            object.enabledDisplayInsightTypes = [];
                            object.threeWeeksTrendInsightMetrics = [];
                            object.excludedScheduledTaskTypes = [];
                            object.criticalHoursOfDaysOfWeek = [];
                            object.storeTimezones = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.transformationMatrixEnabled = false;
                            object.logicalLayoutEnabled = false;
                            object.awsBucket = "";
                            object.gcsBucket = "";
                            object.companyCode = "";
                            object.partnerCode = "";
                            object.description = "";
                            object.entranceCounting = false;
                            object.entranceKeyword = "";
                            object.dwelltimeBuckets = "";
                            object.ageRanges = "";
                            object.sectionVisitDurationLimit = 0;
                            object.dwelltimeMetricDisabled = false;
                            object.interactionMetricDisabled = false;
                            object.transactionMetricDisabled = false;
                            object.groupMetricEnabled = false;
                            object.lyingMetricEnabled = false;
                            object.avgDwelltimeMetricDisabled = false;
                            object.layoutViewDisabled = false;
                            object.topSectionsViewDisabled = false;
                            object.dailyExport = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastExportTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastExportTimeMs = options.longs === String ? "0" : 0;
                            object.exportBucket = "";
                            object.exportNotificationEmails = "";
                            object.exportNotifyUrl = "";
                            object.integrationUsername = "";
                            object.rawIcaBucketName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            object.interactionLevelMetricEnabled = false;
                            object.visitorOccupancyMetricEnabled = false;
                            object.exportStorageProvider = "";
                            object.exportBucketRegion = "";
                            object.exportAccessKey = "";
                            object.exportSecretKey = "";
                            object.rawIcaStorageProvider = options.enums === String ? "RAW_PROVIDER_UNKNOWN" : 0;
                            object.integrationPassword = "";
                            object.exportPosMatchingResultEnabled = false;
                            object.usePosData = false;
                            object.salesAmountRelatedMetricsEnabled = false;
                            object.salesItemsRelatedMetricsEnabled = false;
                            object.useNetAmount = false;
                            object.passbyMetricEnabled = false;
                            object.createMatchingJobOnImportedPosDisabled = false;
                            object.rawIcaMergingBucketName = "";
                            object.nearRealTimeProcessingTimesOfDay = "";
                            object.sectionInteractionMetricEnabled = false;
                            object.sectionPosMetricEnabled = false;
                            object.visitorDirectionFeatureEnabled = false;
                            object.interactionTimeMetricEnabled = false;
                            object.exportRewriteHistoricalStaffingDays = 0;
                            object.exportOverwriteExistingFiles = false;
                            object.mfaRequired = false;
                            object.industry = options.enums === String ? "OTHERS" : 0;
                            object.useSalesTransAsNetSalesTrans = false;
                            object.useSalesItemsAsNetSalesItems = false;
                            object.staffPerformanceAnalysisMetricsEnabled = false;
                            object.staffPerformanceAnalysisStaffingMetricsEnabled = false;
                            object.estimatedTrafficMetricsEnabled = false;
                            object.enableStoreSnapshot = false;
                            object.enableForecast = false;
                            object.enableTargetTrackingForecast = false;
                            object.strictReportConsistencyEnabled = false;
                            object.storeOpenTimeBufferInSeconds = 0;
                            object.storeCloseTimeBufferInSeconds = 0;
                            object.startOfWeek = 0;
                            object.storeSnapshotRetentionDays = 0;
                            object.onTimeGreetingThresholdSec = 0;
                            object.productSalesAnalysisEnabled = false;
                            object.videoGroupSizeBuckets = "";
                            object.posStaffSyncEnabled = false;
                            object.storeRelationshipEnabled = false;
                            object.discountMetricsEnabled = false;
                            object.discountDistributionBuckets = "";
                            object.includeReturningItemsForDiscountAnalysis = false;
                            object.ownerAccount = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.transformationMatrixEnabled != null && message.hasOwnProperty("transformationMatrixEnabled"))
                            object.transformationMatrixEnabled = message.transformationMatrixEnabled;
                        if (message.logicalLayoutEnabled != null && message.hasOwnProperty("logicalLayoutEnabled"))
                            object.logicalLayoutEnabled = message.logicalLayoutEnabled;
                        if (message.awsBucket != null && message.hasOwnProperty("awsBucket"))
                            object.awsBucket = message.awsBucket;
                        if (message.gcsBucket != null && message.hasOwnProperty("gcsBucket"))
                            object.gcsBucket = message.gcsBucket;
                        if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                            object.companyCode = message.companyCode;
                        if (message.partnerCode != null && message.hasOwnProperty("partnerCode"))
                            object.partnerCode = message.partnerCode;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.entranceCounting != null && message.hasOwnProperty("entranceCounting"))
                            object.entranceCounting = message.entranceCounting;
                        if (message.entranceKeyword != null && message.hasOwnProperty("entranceKeyword"))
                            object.entranceKeyword = message.entranceKeyword;
                        if (message.dwelltimeBuckets != null && message.hasOwnProperty("dwelltimeBuckets"))
                            object.dwelltimeBuckets = message.dwelltimeBuckets;
                        if (message.ageRanges != null && message.hasOwnProperty("ageRanges"))
                            object.ageRanges = message.ageRanges;
                        if (message.sectionVisitDurationLimit != null && message.hasOwnProperty("sectionVisitDurationLimit"))
                            object.sectionVisitDurationLimit = message.sectionVisitDurationLimit;
                        if (message.dwelltimeMetricDisabled != null && message.hasOwnProperty("dwelltimeMetricDisabled"))
                            object.dwelltimeMetricDisabled = message.dwelltimeMetricDisabled;
                        if (message.interactionMetricDisabled != null && message.hasOwnProperty("interactionMetricDisabled"))
                            object.interactionMetricDisabled = message.interactionMetricDisabled;
                        if (message.transactionMetricDisabled != null && message.hasOwnProperty("transactionMetricDisabled"))
                            object.transactionMetricDisabled = message.transactionMetricDisabled;
                        if (message.groupMetricEnabled != null && message.hasOwnProperty("groupMetricEnabled"))
                            object.groupMetricEnabled = message.groupMetricEnabled;
                        if (message.lyingMetricEnabled != null && message.hasOwnProperty("lyingMetricEnabled"))
                            object.lyingMetricEnabled = message.lyingMetricEnabled;
                        if (message.avgDwelltimeMetricDisabled != null && message.hasOwnProperty("avgDwelltimeMetricDisabled"))
                            object.avgDwelltimeMetricDisabled = message.avgDwelltimeMetricDisabled;
                        if (message.layoutViewDisabled != null && message.hasOwnProperty("layoutViewDisabled"))
                            object.layoutViewDisabled = message.layoutViewDisabled;
                        if (message.topSectionsViewDisabled != null && message.hasOwnProperty("topSectionsViewDisabled"))
                            object.topSectionsViewDisabled = message.topSectionsViewDisabled;
                        if (message.dailyExport != null && message.hasOwnProperty("dailyExport"))
                            object.dailyExport = message.dailyExport;
                        if (message.lastExportTimeMs != null && message.hasOwnProperty("lastExportTimeMs"))
                            if (typeof message.lastExportTimeMs === "number")
                                object.lastExportTimeMs = options.longs === String ? String(message.lastExportTimeMs) : message.lastExportTimeMs;
                            else
                                object.lastExportTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastExportTimeMs) : options.longs === Number ? new $util.LongBits(message.lastExportTimeMs.low >>> 0, message.lastExportTimeMs.high >>> 0).toNumber() : message.lastExportTimeMs;
                        if (message.exportBucket != null && message.hasOwnProperty("exportBucket"))
                            object.exportBucket = message.exportBucket;
                        if (message.exportNotificationEmails != null && message.hasOwnProperty("exportNotificationEmails"))
                            object.exportNotificationEmails = message.exportNotificationEmails;
                        if (message.exportNotifyUrl != null && message.hasOwnProperty("exportNotifyUrl"))
                            object.exportNotifyUrl = message.exportNotifyUrl;
                        if (message.integrationUsername != null && message.hasOwnProperty("integrationUsername"))
                            object.integrationUsername = message.integrationUsername;
                        if (message.rawIcaBucketName != null && message.hasOwnProperty("rawIcaBucketName"))
                            object.rawIcaBucketName = message.rawIcaBucketName;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.interactionLevelMetricEnabled != null && message.hasOwnProperty("interactionLevelMetricEnabled"))
                            object.interactionLevelMetricEnabled = message.interactionLevelMetricEnabled;
                        if (message.visitorOccupancyMetricEnabled != null && message.hasOwnProperty("visitorOccupancyMetricEnabled"))
                            object.visitorOccupancyMetricEnabled = message.visitorOccupancyMetricEnabled;
                        if (message.exportStorageProvider != null && message.hasOwnProperty("exportStorageProvider"))
                            object.exportStorageProvider = message.exportStorageProvider;
                        if (message.exportBucketRegion != null && message.hasOwnProperty("exportBucketRegion"))
                            object.exportBucketRegion = message.exportBucketRegion;
                        if (message.exportAccessKey != null && message.hasOwnProperty("exportAccessKey"))
                            object.exportAccessKey = message.exportAccessKey;
                        if (message.exportSecretKey != null && message.hasOwnProperty("exportSecretKey"))
                            object.exportSecretKey = message.exportSecretKey;
                        if (message.rawIcaStorageProvider != null && message.hasOwnProperty("rawIcaStorageProvider"))
                            object.rawIcaStorageProvider = options.enums === String ? $root.palexy.streaming.v1.RawIcaStorageProvider[message.rawIcaStorageProvider] : message.rawIcaStorageProvider;
                        if (message.integrationPassword != null && message.hasOwnProperty("integrationPassword"))
                            object.integrationPassword = message.integrationPassword;
                        if (message.exportPosMatchingResultEnabled != null && message.hasOwnProperty("exportPosMatchingResultEnabled"))
                            object.exportPosMatchingResultEnabled = message.exportPosMatchingResultEnabled;
                        if (message.usePosData != null && message.hasOwnProperty("usePosData"))
                            object.usePosData = message.usePosData;
                        if (message.salesAmountRelatedMetricsEnabled != null && message.hasOwnProperty("salesAmountRelatedMetricsEnabled"))
                            object.salesAmountRelatedMetricsEnabled = message.salesAmountRelatedMetricsEnabled;
                        if (message.salesItemsRelatedMetricsEnabled != null && message.hasOwnProperty("salesItemsRelatedMetricsEnabled"))
                            object.salesItemsRelatedMetricsEnabled = message.salesItemsRelatedMetricsEnabled;
                        if (message.useNetAmount != null && message.hasOwnProperty("useNetAmount"))
                            object.useNetAmount = message.useNetAmount;
                        if (message.passbyMetricEnabled != null && message.hasOwnProperty("passbyMetricEnabled"))
                            object.passbyMetricEnabled = message.passbyMetricEnabled;
                        if (message.createMatchingJobOnImportedPosDisabled != null && message.hasOwnProperty("createMatchingJobOnImportedPosDisabled"))
                            object.createMatchingJobOnImportedPosDisabled = message.createMatchingJobOnImportedPosDisabled;
                        if (message.rawIcaMergingBucketName != null && message.hasOwnProperty("rawIcaMergingBucketName"))
                            object.rawIcaMergingBucketName = message.rawIcaMergingBucketName;
                        if (message.nearRealTimeProcessingTimesOfDay != null && message.hasOwnProperty("nearRealTimeProcessingTimesOfDay"))
                            object.nearRealTimeProcessingTimesOfDay = message.nearRealTimeProcessingTimesOfDay;
                        if (message.sectionInteractionMetricEnabled != null && message.hasOwnProperty("sectionInteractionMetricEnabled"))
                            object.sectionInteractionMetricEnabled = message.sectionInteractionMetricEnabled;
                        if (message.sectionPosMetricEnabled != null && message.hasOwnProperty("sectionPosMetricEnabled"))
                            object.sectionPosMetricEnabled = message.sectionPosMetricEnabled;
                        if (message.visitorDirectionFeatureEnabled != null && message.hasOwnProperty("visitorDirectionFeatureEnabled"))
                            object.visitorDirectionFeatureEnabled = message.visitorDirectionFeatureEnabled;
                        if (message.interactionTimeMetricEnabled != null && message.hasOwnProperty("interactionTimeMetricEnabled"))
                            object.interactionTimeMetricEnabled = message.interactionTimeMetricEnabled;
                        if (message.exportRewriteHistoricalStaffingDays != null && message.hasOwnProperty("exportRewriteHistoricalStaffingDays"))
                            object.exportRewriteHistoricalStaffingDays = message.exportRewriteHistoricalStaffingDays;
                        if (message.exportOverwriteExistingFiles != null && message.hasOwnProperty("exportOverwriteExistingFiles"))
                            object.exportOverwriteExistingFiles = message.exportOverwriteExistingFiles;
                        if (message.mfaRequired != null && message.hasOwnProperty("mfaRequired"))
                            object.mfaRequired = message.mfaRequired;
                        if (message.industry != null && message.hasOwnProperty("industry"))
                            object.industry = options.enums === String ? $root.palexy.streaming.v1.Company.Industry[message.industry] : message.industry;
                        if (message.useSalesTransAsNetSalesTrans != null && message.hasOwnProperty("useSalesTransAsNetSalesTrans"))
                            object.useSalesTransAsNetSalesTrans = message.useSalesTransAsNetSalesTrans;
                        if (message.useSalesItemsAsNetSalesItems != null && message.hasOwnProperty("useSalesItemsAsNetSalesItems"))
                            object.useSalesItemsAsNetSalesItems = message.useSalesItemsAsNetSalesItems;
                        if (message.staffPerformanceAnalysisMetricsEnabled != null && message.hasOwnProperty("staffPerformanceAnalysisMetricsEnabled"))
                            object.staffPerformanceAnalysisMetricsEnabled = message.staffPerformanceAnalysisMetricsEnabled;
                        if (message.staffPerformanceAnalysisStaffingMetricsEnabled != null && message.hasOwnProperty("staffPerformanceAnalysisStaffingMetricsEnabled"))
                            object.staffPerformanceAnalysisStaffingMetricsEnabled = message.staffPerformanceAnalysisStaffingMetricsEnabled;
                        if (message.enabledStoreEventTypes && message.enabledStoreEventTypes.length) {
                            object.enabledStoreEventTypes = [];
                            for (var j = 0; j < message.enabledStoreEventTypes.length; ++j)
                                object.enabledStoreEventTypes[j] = options.enums === String ? $root.palexy.streaming.v1.StoreEventType[message.enabledStoreEventTypes[j]] : message.enabledStoreEventTypes[j];
                        }
                        if (message.estimatedTrafficMetricsEnabled != null && message.hasOwnProperty("estimatedTrafficMetricsEnabled"))
                            object.estimatedTrafficMetricsEnabled = message.estimatedTrafficMetricsEnabled;
                        if (message.enableStoreSnapshot != null && message.hasOwnProperty("enableStoreSnapshot"))
                            object.enableStoreSnapshot = message.enableStoreSnapshot;
                        if (message.enableForecast != null && message.hasOwnProperty("enableForecast"))
                            object.enableForecast = message.enableForecast;
                        if (message.enableTargetTrackingForecast != null && message.hasOwnProperty("enableTargetTrackingForecast"))
                            object.enableTargetTrackingForecast = message.enableTargetTrackingForecast;
                        if (message.strictReportConsistencyEnabled != null && message.hasOwnProperty("strictReportConsistencyEnabled"))
                            object.strictReportConsistencyEnabled = message.strictReportConsistencyEnabled;
                        if (message.storeSnapshotScheduledTimes && message.storeSnapshotScheduledTimes.length) {
                            object.storeSnapshotScheduledTimes = [];
                            for (var j = 0; j < message.storeSnapshotScheduledTimes.length; ++j)
                                object.storeSnapshotScheduledTimes[j] = message.storeSnapshotScheduledTimes[j];
                        }
                        if (message.storeOpenTimeBufferInSeconds != null && message.hasOwnProperty("storeOpenTimeBufferInSeconds"))
                            object.storeOpenTimeBufferInSeconds = message.storeOpenTimeBufferInSeconds;
                        if (message.storeCloseTimeBufferInSeconds != null && message.hasOwnProperty("storeCloseTimeBufferInSeconds"))
                            object.storeCloseTimeBufferInSeconds = message.storeCloseTimeBufferInSeconds;
                        if (message.startOfWeek != null && message.hasOwnProperty("startOfWeek"))
                            object.startOfWeek = message.startOfWeek;
                        if (message.weekends && message.weekends.length) {
                            object.weekends = [];
                            for (var j = 0; j < message.weekends.length; ++j)
                                object.weekends[j] = message.weekends[j];
                        }
                        if (message.storeSnapshotRetentionDays != null && message.hasOwnProperty("storeSnapshotRetentionDays"))
                            object.storeSnapshotRetentionDays = message.storeSnapshotRetentionDays;
                        if (message.onTimeGreetingThresholdSec != null && message.hasOwnProperty("onTimeGreetingThresholdSec"))
                            object.onTimeGreetingThresholdSec = message.onTimeGreetingThresholdSec;
                        if (message.productSalesAnalysisEnabled != null && message.hasOwnProperty("productSalesAnalysisEnabled"))
                            object.productSalesAnalysisEnabled = message.productSalesAnalysisEnabled;
                        if (message.defaultLandingMetrics && message.defaultLandingMetrics.length) {
                            object.defaultLandingMetrics = [];
                            for (var j = 0; j < message.defaultLandingMetrics.length; ++j)
                                object.defaultLandingMetrics[j] = options.enums === String ? $root.palexy.streaming.v1.MetricEnum[message.defaultLandingMetrics[j]] : message.defaultLandingMetrics[j];
                        }
                        if (message.enabledInputTargetMetrics && message.enabledInputTargetMetrics.length) {
                            object.enabledInputTargetMetrics = [];
                            for (var j = 0; j < message.enabledInputTargetMetrics.length; ++j)
                                object.enabledInputTargetMetrics[j] = options.enums === String ? $root.palexy.streaming.v1.MetricEnum[message.enabledInputTargetMetrics[j]] : message.enabledInputTargetMetrics[j];
                        }
                        if (message.enabledDisplayTargetMetrics && message.enabledDisplayTargetMetrics.length) {
                            object.enabledDisplayTargetMetrics = [];
                            for (var j = 0; j < message.enabledDisplayTargetMetrics.length; ++j)
                                object.enabledDisplayTargetMetrics[j] = options.enums === String ? $root.palexy.streaming.v1.MetricEnum[message.enabledDisplayTargetMetrics[j]] : message.enabledDisplayTargetMetrics[j];
                        }
                        if (message.enabledGenerateInsightTypes && message.enabledGenerateInsightTypes.length) {
                            object.enabledGenerateInsightTypes = [];
                            for (var j = 0; j < message.enabledGenerateInsightTypes.length; ++j)
                                object.enabledGenerateInsightTypes[j] = message.enabledGenerateInsightTypes[j];
                        }
                        if (message.enabledDisplayInsightTypes && message.enabledDisplayInsightTypes.length) {
                            object.enabledDisplayInsightTypes = [];
                            for (var j = 0; j < message.enabledDisplayInsightTypes.length; ++j)
                                object.enabledDisplayInsightTypes[j] = message.enabledDisplayInsightTypes[j];
                        }
                        if (message.threeWeeksTrendInsightMetrics && message.threeWeeksTrendInsightMetrics.length) {
                            object.threeWeeksTrendInsightMetrics = [];
                            for (var j = 0; j < message.threeWeeksTrendInsightMetrics.length; ++j)
                                object.threeWeeksTrendInsightMetrics[j] = options.enums === String ? $root.palexy.streaming.v1.MetricEnum[message.threeWeeksTrendInsightMetrics[j]] : message.threeWeeksTrendInsightMetrics[j];
                        }
                        if (message.excludedScheduledTaskTypes && message.excludedScheduledTaskTypes.length) {
                            object.excludedScheduledTaskTypes = [];
                            for (var j = 0; j < message.excludedScheduledTaskTypes.length; ++j)
                                object.excludedScheduledTaskTypes[j] = options.enums === String ? $root.palexy.streaming.v1.ScheduledTaskType[message.excludedScheduledTaskTypes[j]] : message.excludedScheduledTaskTypes[j];
                        }
                        if (message.videoGroupSizeBuckets != null && message.hasOwnProperty("videoGroupSizeBuckets"))
                            object.videoGroupSizeBuckets = message.videoGroupSizeBuckets;
                        if (message.criticalHoursOfDaysOfWeek && message.criticalHoursOfDaysOfWeek.length) {
                            object.criticalHoursOfDaysOfWeek = [];
                            for (var j = 0; j < message.criticalHoursOfDaysOfWeek.length; ++j)
                                object.criticalHoursOfDaysOfWeek[j] = $root.palexy.streaming.v1.CriticalHoursOfDayOfWeek.toObject(message.criticalHoursOfDaysOfWeek[j], options);
                        }
                        if (message.posStaffSyncEnabled != null && message.hasOwnProperty("posStaffSyncEnabled"))
                            object.posStaffSyncEnabled = message.posStaffSyncEnabled;
                        if (message.storeRelationshipEnabled != null && message.hasOwnProperty("storeRelationshipEnabled"))
                            object.storeRelationshipEnabled = message.storeRelationshipEnabled;
                        if (message.discountMetricsEnabled != null && message.hasOwnProperty("discountMetricsEnabled"))
                            object.discountMetricsEnabled = message.discountMetricsEnabled;
                        if (message.discountDistributionBuckets != null && message.hasOwnProperty("discountDistributionBuckets"))
                            object.discountDistributionBuckets = message.discountDistributionBuckets;
                        if (message.includeReturningItemsForDiscountAnalysis != null && message.hasOwnProperty("includeReturningItemsForDiscountAnalysis"))
                            object.includeReturningItemsForDiscountAnalysis = message.includeReturningItemsForDiscountAnalysis;
                        if (message.ownerAccount != null && message.hasOwnProperty("ownerAccount"))
                            object.ownerAccount = $root.palexy.streaming.v1.Account.toObject(message.ownerAccount, options);
                        if (message.storeTimezones && message.storeTimezones.length) {
                            object.storeTimezones = [];
                            for (var j = 0; j < message.storeTimezones.length; ++j)
                                object.storeTimezones[j] = message.storeTimezones[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Company to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Company
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Company.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Industry enum.
                     * @name palexy.streaming.v1.Company.Industry
                     * @enum {number}
                     * @property {number} OTHERS=0 OTHERS value
                     * @property {number} FASHION=1 FASHION value
                     * @property {number} CVS=2 CVS value
                     * @property {number} MALL=3 MALL value
                     * @property {number} JEWELRY=4 JEWELRY value
                     * @property {number} DEPARTMENT_STORE=5 DEPARTMENT_STORE value
                     */
                    Company.Industry = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "OTHERS"] = 0;
                        values[valuesById[1] = "FASHION"] = 1;
                        values[valuesById[2] = "CVS"] = 2;
                        values[valuesById[3] = "MALL"] = 3;
                        values[valuesById[4] = "JEWELRY"] = 4;
                        values[valuesById[5] = "DEPARTMENT_STORE"] = 5;
                        return values;
                    })();
    
                    return Company;
                })();
    
                v1.CriticalHoursOfDayOfWeek = (function() {
    
                    /**
                     * Properties of a CriticalHoursOfDayOfWeek.
                     * @memberof palexy.streaming.v1
                     * @interface ICriticalHoursOfDayOfWeek
                     * @property {palexy.streaming.v1.DayOfWeek|null} [dayOfWeek] CriticalHoursOfDayOfWeek dayOfWeek
                     * @property {Array.<number>|null} [hours] CriticalHoursOfDayOfWeek hours
                     */
    
                    /**
                     * Constructs a new CriticalHoursOfDayOfWeek.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CriticalHoursOfDayOfWeek.
                     * @implements ICriticalHoursOfDayOfWeek
                     * @constructor
                     * @param {palexy.streaming.v1.ICriticalHoursOfDayOfWeek=} [properties] Properties to set
                     */
                    function CriticalHoursOfDayOfWeek(properties) {
                        this.hours = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CriticalHoursOfDayOfWeek dayOfWeek.
                     * @member {palexy.streaming.v1.DayOfWeek} dayOfWeek
                     * @memberof palexy.streaming.v1.CriticalHoursOfDayOfWeek
                     * @instance
                     */
                    CriticalHoursOfDayOfWeek.prototype.dayOfWeek = 0;
    
                    /**
                     * CriticalHoursOfDayOfWeek hours.
                     * @member {Array.<number>} hours
                     * @memberof palexy.streaming.v1.CriticalHoursOfDayOfWeek
                     * @instance
                     */
                    CriticalHoursOfDayOfWeek.prototype.hours = $util.emptyArray;
    
                    /**
                     * Creates a new CriticalHoursOfDayOfWeek instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CriticalHoursOfDayOfWeek
                     * @static
                     * @param {palexy.streaming.v1.ICriticalHoursOfDayOfWeek=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CriticalHoursOfDayOfWeek} CriticalHoursOfDayOfWeek instance
                     */
                    CriticalHoursOfDayOfWeek.create = function create(properties) {
                        return new CriticalHoursOfDayOfWeek(properties);
                    };
    
                    /**
                     * Encodes the specified CriticalHoursOfDayOfWeek message. Does not implicitly {@link palexy.streaming.v1.CriticalHoursOfDayOfWeek.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CriticalHoursOfDayOfWeek
                     * @static
                     * @param {palexy.streaming.v1.ICriticalHoursOfDayOfWeek} message CriticalHoursOfDayOfWeek message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CriticalHoursOfDayOfWeek.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dayOfWeek != null && Object.hasOwnProperty.call(message, "dayOfWeek"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dayOfWeek);
                        if (message.hours != null && message.hours.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.hours.length; ++i)
                                writer.int32(message.hours[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CriticalHoursOfDayOfWeek message, length delimited. Does not implicitly {@link palexy.streaming.v1.CriticalHoursOfDayOfWeek.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CriticalHoursOfDayOfWeek
                     * @static
                     * @param {palexy.streaming.v1.ICriticalHoursOfDayOfWeek} message CriticalHoursOfDayOfWeek message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CriticalHoursOfDayOfWeek.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CriticalHoursOfDayOfWeek message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CriticalHoursOfDayOfWeek
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CriticalHoursOfDayOfWeek} CriticalHoursOfDayOfWeek
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CriticalHoursOfDayOfWeek.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CriticalHoursOfDayOfWeek();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dayOfWeek = reader.int32();
                                break;
                            case 2:
                                if (!(message.hours && message.hours.length))
                                    message.hours = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.hours.push(reader.int32());
                                } else
                                    message.hours.push(reader.int32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CriticalHoursOfDayOfWeek message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CriticalHoursOfDayOfWeek
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CriticalHoursOfDayOfWeek} CriticalHoursOfDayOfWeek
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CriticalHoursOfDayOfWeek.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CriticalHoursOfDayOfWeek message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CriticalHoursOfDayOfWeek
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CriticalHoursOfDayOfWeek.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                            switch (message.dayOfWeek) {
                            default:
                                return "dayOfWeek: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                                break;
                            }
                        if (message.hours != null && message.hasOwnProperty("hours")) {
                            if (!Array.isArray(message.hours))
                                return "hours: array expected";
                            for (var i = 0; i < message.hours.length; ++i)
                                if (!$util.isInteger(message.hours[i]))
                                    return "hours: integer[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CriticalHoursOfDayOfWeek message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CriticalHoursOfDayOfWeek
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CriticalHoursOfDayOfWeek} CriticalHoursOfDayOfWeek
                     */
                    CriticalHoursOfDayOfWeek.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CriticalHoursOfDayOfWeek)
                            return object;
                        var message = new $root.palexy.streaming.v1.CriticalHoursOfDayOfWeek();
                        switch (object.dayOfWeek) {
                        case "DAY_OF_WEEK_UNSPECIFIED":
                        case 0:
                            message.dayOfWeek = 0;
                            break;
                        case "MONDAY":
                        case 1:
                            message.dayOfWeek = 1;
                            break;
                        case "TUESDAY":
                        case 2:
                            message.dayOfWeek = 2;
                            break;
                        case "WEDNESDAY":
                        case 3:
                            message.dayOfWeek = 3;
                            break;
                        case "THURSDAY":
                        case 4:
                            message.dayOfWeek = 4;
                            break;
                        case "FRIDAY":
                        case 5:
                            message.dayOfWeek = 5;
                            break;
                        case "SATURDAY":
                        case 6:
                            message.dayOfWeek = 6;
                            break;
                        case "SUNDAY":
                        case 7:
                            message.dayOfWeek = 7;
                            break;
                        }
                        if (object.hours) {
                            if (!Array.isArray(object.hours))
                                throw TypeError(".palexy.streaming.v1.CriticalHoursOfDayOfWeek.hours: array expected");
                            message.hours = [];
                            for (var i = 0; i < object.hours.length; ++i)
                                message.hours[i] = object.hours[i] | 0;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CriticalHoursOfDayOfWeek message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CriticalHoursOfDayOfWeek
                     * @static
                     * @param {palexy.streaming.v1.CriticalHoursOfDayOfWeek} message CriticalHoursOfDayOfWeek
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CriticalHoursOfDayOfWeek.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.hours = [];
                        if (options.defaults)
                            object.dayOfWeek = options.enums === String ? "DAY_OF_WEEK_UNSPECIFIED" : 0;
                        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                            object.dayOfWeek = options.enums === String ? $root.palexy.streaming.v1.DayOfWeek[message.dayOfWeek] : message.dayOfWeek;
                        if (message.hours && message.hours.length) {
                            object.hours = [];
                            for (var j = 0; j < message.hours.length; ++j)
                                object.hours[j] = message.hours[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CriticalHoursOfDayOfWeek to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CriticalHoursOfDayOfWeek
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CriticalHoursOfDayOfWeek.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CriticalHoursOfDayOfWeek;
                })();
    
                v1.CompanyService = (function() {
    
                    /**
                     * Constructs a new CompanyService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CompanyService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function CompanyService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (CompanyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CompanyService;
    
                    /**
                     * Creates new CompanyService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.CompanyService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {CompanyService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    CompanyService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CompanyService#listCompanies}.
                     * @memberof palexy.streaming.v1.CompanyService
                     * @typedef ListCompaniesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListCompaniesResponse} [response] ListCompaniesResponse
                     */
    
                    /**
                     * Calls ListCompanies.
                     * @function listCompanies
                     * @memberof palexy.streaming.v1.CompanyService
                     * @instance
                     * @param {palexy.streaming.v1.IListCompaniesRequest} request ListCompaniesRequest message or plain object
                     * @param {palexy.streaming.v1.CompanyService.ListCompaniesCallback} callback Node-style callback called with the error, if any, and ListCompaniesResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CompanyService.prototype.listCompanies = function listCompanies(request, callback) {
                        return this.rpcCall(listCompanies, $root.palexy.streaming.v1.ListCompaniesRequest, $root.palexy.streaming.v1.ListCompaniesResponse, request, callback);
                    }, "name", { value: "ListCompanies" });
    
                    /**
                     * Calls ListCompanies.
                     * @function listCompanies
                     * @memberof palexy.streaming.v1.CompanyService
                     * @instance
                     * @param {palexy.streaming.v1.IListCompaniesRequest} request ListCompaniesRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListCompaniesResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CompanyService#getCompany}.
                     * @memberof palexy.streaming.v1.CompanyService
                     * @typedef GetCompanyCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Company} [response] Company
                     */
    
                    /**
                     * Calls GetCompany.
                     * @function getCompany
                     * @memberof palexy.streaming.v1.CompanyService
                     * @instance
                     * @param {palexy.streaming.v1.IGetCompanyRequest} request GetCompanyRequest message or plain object
                     * @param {palexy.streaming.v1.CompanyService.GetCompanyCallback} callback Node-style callback called with the error, if any, and Company
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CompanyService.prototype.getCompany = function getCompany(request, callback) {
                        return this.rpcCall(getCompany, $root.palexy.streaming.v1.GetCompanyRequest, $root.palexy.streaming.v1.Company, request, callback);
                    }, "name", { value: "GetCompany" });
    
                    /**
                     * Calls GetCompany.
                     * @function getCompany
                     * @memberof palexy.streaming.v1.CompanyService
                     * @instance
                     * @param {palexy.streaming.v1.IGetCompanyRequest} request GetCompanyRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Company>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CompanyService#getCompanyCurrentTimesWithTimezone}.
                     * @memberof palexy.streaming.v1.CompanyService
                     * @typedef GetCompanyCurrentTimesWithTimezoneCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse} [response] GetCompanyCurrentTimesWithTimezoneResponse
                     */
    
                    /**
                     * Calls GetCompanyCurrentTimesWithTimezone.
                     * @function getCompanyCurrentTimesWithTimezone
                     * @memberof palexy.streaming.v1.CompanyService
                     * @instance
                     * @param {palexy.streaming.v1.IGetCompanyCurrentTimesWithTimezoneRequest} request GetCompanyCurrentTimesWithTimezoneRequest message or plain object
                     * @param {palexy.streaming.v1.CompanyService.GetCompanyCurrentTimesWithTimezoneCallback} callback Node-style callback called with the error, if any, and GetCompanyCurrentTimesWithTimezoneResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CompanyService.prototype.getCompanyCurrentTimesWithTimezone = function getCompanyCurrentTimesWithTimezone(request, callback) {
                        return this.rpcCall(getCompanyCurrentTimesWithTimezone, $root.palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest, $root.palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse, request, callback);
                    }, "name", { value: "GetCompanyCurrentTimesWithTimezone" });
    
                    /**
                     * Calls GetCompanyCurrentTimesWithTimezone.
                     * @function getCompanyCurrentTimesWithTimezone
                     * @memberof palexy.streaming.v1.CompanyService
                     * @instance
                     * @param {palexy.streaming.v1.IGetCompanyCurrentTimesWithTimezoneRequest} request GetCompanyCurrentTimesWithTimezoneRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CompanyService#findCompaniesReadyForTaskScheduling}.
                     * @memberof palexy.streaming.v1.CompanyService
                     * @typedef FindCompaniesReadyForTaskSchedulingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse} [response] FindCompaniesReadyForTaskSchedulingResponse
                     */
    
                    /**
                     * Calls FindCompaniesReadyForTaskScheduling.
                     * @function findCompaniesReadyForTaskScheduling
                     * @memberof palexy.streaming.v1.CompanyService
                     * @instance
                     * @param {palexy.streaming.v1.IFindCompaniesReadyForTaskSchedulingRequest} request FindCompaniesReadyForTaskSchedulingRequest message or plain object
                     * @param {palexy.streaming.v1.CompanyService.FindCompaniesReadyForTaskSchedulingCallback} callback Node-style callback called with the error, if any, and FindCompaniesReadyForTaskSchedulingResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CompanyService.prototype.findCompaniesReadyForTaskScheduling = function findCompaniesReadyForTaskScheduling(request, callback) {
                        return this.rpcCall(findCompaniesReadyForTaskScheduling, $root.palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest, $root.palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse, request, callback);
                    }, "name", { value: "FindCompaniesReadyForTaskScheduling" });
    
                    /**
                     * Calls FindCompaniesReadyForTaskScheduling.
                     * @function findCompaniesReadyForTaskScheduling
                     * @memberof palexy.streaming.v1.CompanyService
                     * @instance
                     * @param {palexy.streaming.v1.IFindCompaniesReadyForTaskSchedulingRequest} request FindCompaniesReadyForTaskSchedulingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse>} Promise
                     * @variation 2
                     */
    
                    return CompanyService;
                })();
    
                v1.GetCompanyCurrentTimesWithTimezoneRequest = (function() {
    
                    /**
                     * Properties of a GetCompanyCurrentTimesWithTimezoneRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetCompanyCurrentTimesWithTimezoneRequest
                     * @property {number|null} [id] GetCompanyCurrentTimesWithTimezoneRequest id
                     */
    
                    /**
                     * Constructs a new GetCompanyCurrentTimesWithTimezoneRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetCompanyCurrentTimesWithTimezoneRequest.
                     * @implements IGetCompanyCurrentTimesWithTimezoneRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetCompanyCurrentTimesWithTimezoneRequest=} [properties] Properties to set
                     */
                    function GetCompanyCurrentTimesWithTimezoneRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetCompanyCurrentTimesWithTimezoneRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest
                     * @instance
                     */
                    GetCompanyCurrentTimesWithTimezoneRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetCompanyCurrentTimesWithTimezoneRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCompanyCurrentTimesWithTimezoneRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest} GetCompanyCurrentTimesWithTimezoneRequest instance
                     */
                    GetCompanyCurrentTimesWithTimezoneRequest.create = function create(properties) {
                        return new GetCompanyCurrentTimesWithTimezoneRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetCompanyCurrentTimesWithTimezoneRequest message. Does not implicitly {@link palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCompanyCurrentTimesWithTimezoneRequest} message GetCompanyCurrentTimesWithTimezoneRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCompanyCurrentTimesWithTimezoneRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetCompanyCurrentTimesWithTimezoneRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCompanyCurrentTimesWithTimezoneRequest} message GetCompanyCurrentTimesWithTimezoneRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCompanyCurrentTimesWithTimezoneRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetCompanyCurrentTimesWithTimezoneRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest} GetCompanyCurrentTimesWithTimezoneRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCompanyCurrentTimesWithTimezoneRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetCompanyCurrentTimesWithTimezoneRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest} GetCompanyCurrentTimesWithTimezoneRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCompanyCurrentTimesWithTimezoneRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetCompanyCurrentTimesWithTimezoneRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetCompanyCurrentTimesWithTimezoneRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetCompanyCurrentTimesWithTimezoneRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest} GetCompanyCurrentTimesWithTimezoneRequest
                     */
                    GetCompanyCurrentTimesWithTimezoneRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetCompanyCurrentTimesWithTimezoneRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest
                     * @static
                     * @param {palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest} message GetCompanyCurrentTimesWithTimezoneRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetCompanyCurrentTimesWithTimezoneRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this GetCompanyCurrentTimesWithTimezoneRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetCompanyCurrentTimesWithTimezoneRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetCompanyCurrentTimesWithTimezoneRequest;
                })();
    
                v1.GetCompanyCurrentTimesWithTimezoneResponse = (function() {
    
                    /**
                     * Properties of a GetCompanyCurrentTimesWithTimezoneResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IGetCompanyCurrentTimesWithTimezoneResponse
                     * @property {Array.<string>|null} [currentTimes] GetCompanyCurrentTimesWithTimezoneResponse currentTimes
                     */
    
                    /**
                     * Constructs a new GetCompanyCurrentTimesWithTimezoneResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetCompanyCurrentTimesWithTimezoneResponse.
                     * @implements IGetCompanyCurrentTimesWithTimezoneResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IGetCompanyCurrentTimesWithTimezoneResponse=} [properties] Properties to set
                     */
                    function GetCompanyCurrentTimesWithTimezoneResponse(properties) {
                        this.currentTimes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetCompanyCurrentTimesWithTimezoneResponse currentTimes.
                     * @member {Array.<string>} currentTimes
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse
                     * @instance
                     */
                    GetCompanyCurrentTimesWithTimezoneResponse.prototype.currentTimes = $util.emptyArray;
    
                    /**
                     * Creates a new GetCompanyCurrentTimesWithTimezoneResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetCompanyCurrentTimesWithTimezoneResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse} GetCompanyCurrentTimesWithTimezoneResponse instance
                     */
                    GetCompanyCurrentTimesWithTimezoneResponse.create = function create(properties) {
                        return new GetCompanyCurrentTimesWithTimezoneResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetCompanyCurrentTimesWithTimezoneResponse message. Does not implicitly {@link palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetCompanyCurrentTimesWithTimezoneResponse} message GetCompanyCurrentTimesWithTimezoneResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCompanyCurrentTimesWithTimezoneResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.currentTimes != null && message.currentTimes.length)
                            for (var i = 0; i < message.currentTimes.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.currentTimes[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetCompanyCurrentTimesWithTimezoneResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetCompanyCurrentTimesWithTimezoneResponse} message GetCompanyCurrentTimesWithTimezoneResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCompanyCurrentTimesWithTimezoneResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetCompanyCurrentTimesWithTimezoneResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse} GetCompanyCurrentTimesWithTimezoneResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCompanyCurrentTimesWithTimezoneResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.currentTimes && message.currentTimes.length))
                                    message.currentTimes = [];
                                message.currentTimes.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetCompanyCurrentTimesWithTimezoneResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse} GetCompanyCurrentTimesWithTimezoneResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCompanyCurrentTimesWithTimezoneResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetCompanyCurrentTimesWithTimezoneResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetCompanyCurrentTimesWithTimezoneResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.currentTimes != null && message.hasOwnProperty("currentTimes")) {
                            if (!Array.isArray(message.currentTimes))
                                return "currentTimes: array expected";
                            for (var i = 0; i < message.currentTimes.length; ++i)
                                if (!$util.isString(message.currentTimes[i]))
                                    return "currentTimes: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetCompanyCurrentTimesWithTimezoneResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse} GetCompanyCurrentTimesWithTimezoneResponse
                     */
                    GetCompanyCurrentTimesWithTimezoneResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse();
                        if (object.currentTimes) {
                            if (!Array.isArray(object.currentTimes))
                                throw TypeError(".palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse.currentTimes: array expected");
                            message.currentTimes = [];
                            for (var i = 0; i < object.currentTimes.length; ++i)
                                message.currentTimes[i] = String(object.currentTimes[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetCompanyCurrentTimesWithTimezoneResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse
                     * @static
                     * @param {palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse} message GetCompanyCurrentTimesWithTimezoneResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetCompanyCurrentTimesWithTimezoneResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.currentTimes = [];
                        if (message.currentTimes && message.currentTimes.length) {
                            object.currentTimes = [];
                            for (var j = 0; j < message.currentTimes.length; ++j)
                                object.currentTimes[j] = message.currentTimes[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetCompanyCurrentTimesWithTimezoneResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetCompanyCurrentTimesWithTimezoneResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetCompanyCurrentTimesWithTimezoneResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetCompanyCurrentTimesWithTimezoneResponse;
                })();
    
                v1.ListCompaniesRequest = (function() {
    
                    /**
                     * Properties of a ListCompaniesRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListCompaniesRequest
                     * @property {google.protobuf.IBoolValue|null} [isNearRealTime] ListCompaniesRequest isNearRealTime
                     */
    
                    /**
                     * Constructs a new ListCompaniesRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListCompaniesRequest.
                     * @implements IListCompaniesRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListCompaniesRequest=} [properties] Properties to set
                     */
                    function ListCompaniesRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCompaniesRequest isNearRealTime.
                     * @member {google.protobuf.IBoolValue|null|undefined} isNearRealTime
                     * @memberof palexy.streaming.v1.ListCompaniesRequest
                     * @instance
                     */
                    ListCompaniesRequest.prototype.isNearRealTime = null;
    
                    /**
                     * Creates a new ListCompaniesRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListCompaniesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCompaniesRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListCompaniesRequest} ListCompaniesRequest instance
                     */
                    ListCompaniesRequest.create = function create(properties) {
                        return new ListCompaniesRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListCompaniesRequest message. Does not implicitly {@link palexy.streaming.v1.ListCompaniesRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListCompaniesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCompaniesRequest} message ListCompaniesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCompaniesRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.isNearRealTime != null && Object.hasOwnProperty.call(message, "isNearRealTime"))
                            $root.google.protobuf.BoolValue.encode(message.isNearRealTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCompaniesRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListCompaniesRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListCompaniesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCompaniesRequest} message ListCompaniesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCompaniesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCompaniesRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListCompaniesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListCompaniesRequest} ListCompaniesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCompaniesRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListCompaniesRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.isNearRealTime = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCompaniesRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListCompaniesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListCompaniesRequest} ListCompaniesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCompaniesRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCompaniesRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListCompaniesRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCompaniesRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isNearRealTime != null && message.hasOwnProperty("isNearRealTime")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isNearRealTime);
                            if (error)
                                return "isNearRealTime." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListCompaniesRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListCompaniesRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListCompaniesRequest} ListCompaniesRequest
                     */
                    ListCompaniesRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListCompaniesRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListCompaniesRequest();
                        if (object.isNearRealTime != null) {
                            if (typeof object.isNearRealTime !== "object")
                                throw TypeError(".palexy.streaming.v1.ListCompaniesRequest.isNearRealTime: object expected");
                            message.isNearRealTime = $root.google.protobuf.BoolValue.fromObject(object.isNearRealTime);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCompaniesRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListCompaniesRequest
                     * @static
                     * @param {palexy.streaming.v1.ListCompaniesRequest} message ListCompaniesRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCompaniesRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.isNearRealTime = null;
                        if (message.isNearRealTime != null && message.hasOwnProperty("isNearRealTime"))
                            object.isNearRealTime = $root.google.protobuf.BoolValue.toObject(message.isNearRealTime, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListCompaniesRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListCompaniesRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCompaniesRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCompaniesRequest;
                })();
    
                v1.ListCompaniesResponse = (function() {
    
                    /**
                     * Properties of a ListCompaniesResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListCompaniesResponse
                     * @property {Array.<palexy.streaming.v1.ICompany>|null} [companies] ListCompaniesResponse companies
                     */
    
                    /**
                     * Constructs a new ListCompaniesResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListCompaniesResponse.
                     * @implements IListCompaniesResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListCompaniesResponse=} [properties] Properties to set
                     */
                    function ListCompaniesResponse(properties) {
                        this.companies = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCompaniesResponse companies.
                     * @member {Array.<palexy.streaming.v1.ICompany>} companies
                     * @memberof palexy.streaming.v1.ListCompaniesResponse
                     * @instance
                     */
                    ListCompaniesResponse.prototype.companies = $util.emptyArray;
    
                    /**
                     * Creates a new ListCompaniesResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListCompaniesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCompaniesResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListCompaniesResponse} ListCompaniesResponse instance
                     */
                    ListCompaniesResponse.create = function create(properties) {
                        return new ListCompaniesResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListCompaniesResponse message. Does not implicitly {@link palexy.streaming.v1.ListCompaniesResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListCompaniesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCompaniesResponse} message ListCompaniesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCompaniesResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companies != null && message.companies.length)
                            for (var i = 0; i < message.companies.length; ++i)
                                $root.palexy.streaming.v1.Company.encode(message.companies[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCompaniesResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListCompaniesResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListCompaniesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCompaniesResponse} message ListCompaniesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCompaniesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCompaniesResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListCompaniesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListCompaniesResponse} ListCompaniesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCompaniesResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListCompaniesResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.companies && message.companies.length))
                                    message.companies = [];
                                message.companies.push($root.palexy.streaming.v1.Company.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCompaniesResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListCompaniesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListCompaniesResponse} ListCompaniesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCompaniesResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCompaniesResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListCompaniesResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCompaniesResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companies != null && message.hasOwnProperty("companies")) {
                            if (!Array.isArray(message.companies))
                                return "companies: array expected";
                            for (var i = 0; i < message.companies.length; ++i) {
                                var error = $root.palexy.streaming.v1.Company.verify(message.companies[i]);
                                if (error)
                                    return "companies." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListCompaniesResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListCompaniesResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListCompaniesResponse} ListCompaniesResponse
                     */
                    ListCompaniesResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListCompaniesResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListCompaniesResponse();
                        if (object.companies) {
                            if (!Array.isArray(object.companies))
                                throw TypeError(".palexy.streaming.v1.ListCompaniesResponse.companies: array expected");
                            message.companies = [];
                            for (var i = 0; i < object.companies.length; ++i) {
                                if (typeof object.companies[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListCompaniesResponse.companies: object expected");
                                message.companies[i] = $root.palexy.streaming.v1.Company.fromObject(object.companies[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCompaniesResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListCompaniesResponse
                     * @static
                     * @param {palexy.streaming.v1.ListCompaniesResponse} message ListCompaniesResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCompaniesResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.companies = [];
                        if (message.companies && message.companies.length) {
                            object.companies = [];
                            for (var j = 0; j < message.companies.length; ++j)
                                object.companies[j] = $root.palexy.streaming.v1.Company.toObject(message.companies[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListCompaniesResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListCompaniesResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCompaniesResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCompaniesResponse;
                })();
    
                v1.GetCompanyRequest = (function() {
    
                    /**
                     * Properties of a GetCompanyRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetCompanyRequest
                     * @property {number|null} [id] GetCompanyRequest id
                     * @property {boolean|null} [fetchOwnerAccount] GetCompanyRequest fetchOwnerAccount
                     * @property {boolean|null} [fetchStoreTimezone] GetCompanyRequest fetchStoreTimezone
                     */
    
                    /**
                     * Constructs a new GetCompanyRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetCompanyRequest.
                     * @implements IGetCompanyRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetCompanyRequest=} [properties] Properties to set
                     */
                    function GetCompanyRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetCompanyRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @instance
                     */
                    GetCompanyRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetCompanyRequest fetchOwnerAccount.
                     * @member {boolean} fetchOwnerAccount
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @instance
                     */
                    GetCompanyRequest.prototype.fetchOwnerAccount = false;
    
                    /**
                     * GetCompanyRequest fetchStoreTimezone.
                     * @member {boolean} fetchStoreTimezone
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @instance
                     */
                    GetCompanyRequest.prototype.fetchStoreTimezone = false;
    
                    /**
                     * Creates a new GetCompanyRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCompanyRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetCompanyRequest} GetCompanyRequest instance
                     */
                    GetCompanyRequest.create = function create(properties) {
                        return new GetCompanyRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetCompanyRequest message. Does not implicitly {@link palexy.streaming.v1.GetCompanyRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCompanyRequest} message GetCompanyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCompanyRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.fetchOwnerAccount != null && Object.hasOwnProperty.call(message, "fetchOwnerAccount"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.fetchOwnerAccount);
                        if (message.fetchStoreTimezone != null && Object.hasOwnProperty.call(message, "fetchStoreTimezone"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.fetchStoreTimezone);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetCompanyRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetCompanyRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetCompanyRequest} message GetCompanyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCompanyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetCompanyRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetCompanyRequest} GetCompanyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCompanyRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetCompanyRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.fetchOwnerAccount = reader.bool();
                                break;
                            case 3:
                                message.fetchStoreTimezone = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetCompanyRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetCompanyRequest} GetCompanyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCompanyRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetCompanyRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetCompanyRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.fetchOwnerAccount != null && message.hasOwnProperty("fetchOwnerAccount"))
                            if (typeof message.fetchOwnerAccount !== "boolean")
                                return "fetchOwnerAccount: boolean expected";
                        if (message.fetchStoreTimezone != null && message.hasOwnProperty("fetchStoreTimezone"))
                            if (typeof message.fetchStoreTimezone !== "boolean")
                                return "fetchStoreTimezone: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetCompanyRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetCompanyRequest} GetCompanyRequest
                     */
                    GetCompanyRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetCompanyRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetCompanyRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.fetchOwnerAccount != null)
                            message.fetchOwnerAccount = Boolean(object.fetchOwnerAccount);
                        if (object.fetchStoreTimezone != null)
                            message.fetchStoreTimezone = Boolean(object.fetchStoreTimezone);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetCompanyRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @static
                     * @param {palexy.streaming.v1.GetCompanyRequest} message GetCompanyRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetCompanyRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.fetchOwnerAccount = false;
                            object.fetchStoreTimezone = false;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.fetchOwnerAccount != null && message.hasOwnProperty("fetchOwnerAccount"))
                            object.fetchOwnerAccount = message.fetchOwnerAccount;
                        if (message.fetchStoreTimezone != null && message.hasOwnProperty("fetchStoreTimezone"))
                            object.fetchStoreTimezone = message.fetchStoreTimezone;
                        return object;
                    };
    
                    /**
                     * Converts this GetCompanyRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetCompanyRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetCompanyRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetCompanyRequest;
                })();
    
                v1.FindCompaniesReadyForTaskSchedulingRequest = (function() {
    
                    /**
                     * Properties of a FindCompaniesReadyForTaskSchedulingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IFindCompaniesReadyForTaskSchedulingRequest
                     * @property {string|null} [dueLocalDateTime] FindCompaniesReadyForTaskSchedulingRequest dueLocalDateTime
                     * @property {string|null} [eventDateId] FindCompaniesReadyForTaskSchedulingRequest eventDateId
                     * @property {palexy.streaming.v1.ScheduledTaskType|null} [scheduleTaskType] FindCompaniesReadyForTaskSchedulingRequest scheduleTaskType
                     * @property {number|null} [maxResults] FindCompaniesReadyForTaskSchedulingRequest maxResults
                     * @property {google.protobuf.IInt32Value|null} [startOfWeek] FindCompaniesReadyForTaskSchedulingRequest startOfWeek
                     */
    
                    /**
                     * Constructs a new FindCompaniesReadyForTaskSchedulingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FindCompaniesReadyForTaskSchedulingRequest.
                     * @implements IFindCompaniesReadyForTaskSchedulingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IFindCompaniesReadyForTaskSchedulingRequest=} [properties] Properties to set
                     */
                    function FindCompaniesReadyForTaskSchedulingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FindCompaniesReadyForTaskSchedulingRequest dueLocalDateTime.
                     * @member {string} dueLocalDateTime
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @instance
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.prototype.dueLocalDateTime = "";
    
                    /**
                     * FindCompaniesReadyForTaskSchedulingRequest eventDateId.
                     * @member {string} eventDateId
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @instance
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.prototype.eventDateId = "";
    
                    /**
                     * FindCompaniesReadyForTaskSchedulingRequest scheduleTaskType.
                     * @member {palexy.streaming.v1.ScheduledTaskType} scheduleTaskType
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @instance
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.prototype.scheduleTaskType = 0;
    
                    /**
                     * FindCompaniesReadyForTaskSchedulingRequest maxResults.
                     * @member {number} maxResults
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @instance
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.prototype.maxResults = 0;
    
                    /**
                     * FindCompaniesReadyForTaskSchedulingRequest startOfWeek.
                     * @member {google.protobuf.IInt32Value|null|undefined} startOfWeek
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @instance
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.prototype.startOfWeek = null;
    
                    /**
                     * Creates a new FindCompaniesReadyForTaskSchedulingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @static
                     * @param {palexy.streaming.v1.IFindCompaniesReadyForTaskSchedulingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest} FindCompaniesReadyForTaskSchedulingRequest instance
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.create = function create(properties) {
                        return new FindCompaniesReadyForTaskSchedulingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FindCompaniesReadyForTaskSchedulingRequest message. Does not implicitly {@link palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @static
                     * @param {palexy.streaming.v1.IFindCompaniesReadyForTaskSchedulingRequest} message FindCompaniesReadyForTaskSchedulingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dueLocalDateTime != null && Object.hasOwnProperty.call(message, "dueLocalDateTime"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.dueLocalDateTime);
                        if (message.eventDateId != null && Object.hasOwnProperty.call(message, "eventDateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.eventDateId);
                        if (message.scheduleTaskType != null && Object.hasOwnProperty.call(message, "scheduleTaskType"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.scheduleTaskType);
                        if (message.maxResults != null && Object.hasOwnProperty.call(message, "maxResults"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.maxResults);
                        if (message.startOfWeek != null && Object.hasOwnProperty.call(message, "startOfWeek"))
                            $root.google.protobuf.Int32Value.encode(message.startOfWeek, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FindCompaniesReadyForTaskSchedulingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @static
                     * @param {palexy.streaming.v1.IFindCompaniesReadyForTaskSchedulingRequest} message FindCompaniesReadyForTaskSchedulingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FindCompaniesReadyForTaskSchedulingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest} FindCompaniesReadyForTaskSchedulingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dueLocalDateTime = reader.string();
                                break;
                            case 2:
                                message.eventDateId = reader.string();
                                break;
                            case 3:
                                message.scheduleTaskType = reader.int32();
                                break;
                            case 4:
                                message.maxResults = reader.int32();
                                break;
                            case 5:
                                message.startOfWeek = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FindCompaniesReadyForTaskSchedulingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest} FindCompaniesReadyForTaskSchedulingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FindCompaniesReadyForTaskSchedulingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dueLocalDateTime != null && message.hasOwnProperty("dueLocalDateTime"))
                            if (!$util.isString(message.dueLocalDateTime))
                                return "dueLocalDateTime: string expected";
                        if (message.eventDateId != null && message.hasOwnProperty("eventDateId"))
                            if (!$util.isString(message.eventDateId))
                                return "eventDateId: string expected";
                        if (message.scheduleTaskType != null && message.hasOwnProperty("scheduleTaskType"))
                            switch (message.scheduleTaskType) {
                            default:
                                return "scheduleTaskType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.maxResults != null && message.hasOwnProperty("maxResults"))
                            if (!$util.isInteger(message.maxResults))
                                return "maxResults: integer expected";
                        if (message.startOfWeek != null && message.hasOwnProperty("startOfWeek")) {
                            var error = $root.google.protobuf.Int32Value.verify(message.startOfWeek);
                            if (error)
                                return "startOfWeek." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a FindCompaniesReadyForTaskSchedulingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest} FindCompaniesReadyForTaskSchedulingRequest
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest();
                        if (object.dueLocalDateTime != null)
                            message.dueLocalDateTime = String(object.dueLocalDateTime);
                        if (object.eventDateId != null)
                            message.eventDateId = String(object.eventDateId);
                        switch (object.scheduleTaskType) {
                        case "SCHEDULED_TASK_TYPE_UNKNOWN":
                        case 0:
                            message.scheduleTaskType = 0;
                            break;
                        case "SCHEDULED_CREATE_WEEKLY_INSIGHTS_TASKS":
                        case 1:
                            message.scheduleTaskType = 1;
                            break;
                        case "SCHEDULED_CREATE_MONTHLY_INSIGHTS_TASKS":
                        case 2:
                            message.scheduleTaskType = 2;
                            break;
                        case "SCHEDULED_CREATE_MONTHLY_TREND_REPORT_TASKS":
                        case 3:
                            message.scheduleTaskType = 3;
                            break;
                        }
                        if (object.maxResults != null)
                            message.maxResults = object.maxResults | 0;
                        if (object.startOfWeek != null) {
                            if (typeof object.startOfWeek !== "object")
                                throw TypeError(".palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest.startOfWeek: object expected");
                            message.startOfWeek = $root.google.protobuf.Int32Value.fromObject(object.startOfWeek);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FindCompaniesReadyForTaskSchedulingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @static
                     * @param {palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest} message FindCompaniesReadyForTaskSchedulingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.dueLocalDateTime = "";
                            object.eventDateId = "";
                            object.scheduleTaskType = options.enums === String ? "SCHEDULED_TASK_TYPE_UNKNOWN" : 0;
                            object.maxResults = 0;
                            object.startOfWeek = null;
                        }
                        if (message.dueLocalDateTime != null && message.hasOwnProperty("dueLocalDateTime"))
                            object.dueLocalDateTime = message.dueLocalDateTime;
                        if (message.eventDateId != null && message.hasOwnProperty("eventDateId"))
                            object.eventDateId = message.eventDateId;
                        if (message.scheduleTaskType != null && message.hasOwnProperty("scheduleTaskType"))
                            object.scheduleTaskType = options.enums === String ? $root.palexy.streaming.v1.ScheduledTaskType[message.scheduleTaskType] : message.scheduleTaskType;
                        if (message.maxResults != null && message.hasOwnProperty("maxResults"))
                            object.maxResults = message.maxResults;
                        if (message.startOfWeek != null && message.hasOwnProperty("startOfWeek"))
                            object.startOfWeek = $root.google.protobuf.Int32Value.toObject(message.startOfWeek, options);
                        return object;
                    };
    
                    /**
                     * Converts this FindCompaniesReadyForTaskSchedulingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FindCompaniesReadyForTaskSchedulingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FindCompaniesReadyForTaskSchedulingRequest;
                })();
    
                v1.FindCompaniesReadyForTaskSchedulingResponse = (function() {
    
                    /**
                     * Properties of a FindCompaniesReadyForTaskSchedulingResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IFindCompaniesReadyForTaskSchedulingResponse
                     * @property {Array.<palexy.streaming.v1.ICompany>|null} [companies] FindCompaniesReadyForTaskSchedulingResponse companies
                     */
    
                    /**
                     * Constructs a new FindCompaniesReadyForTaskSchedulingResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FindCompaniesReadyForTaskSchedulingResponse.
                     * @implements IFindCompaniesReadyForTaskSchedulingResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IFindCompaniesReadyForTaskSchedulingResponse=} [properties] Properties to set
                     */
                    function FindCompaniesReadyForTaskSchedulingResponse(properties) {
                        this.companies = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FindCompaniesReadyForTaskSchedulingResponse companies.
                     * @member {Array.<palexy.streaming.v1.ICompany>} companies
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse
                     * @instance
                     */
                    FindCompaniesReadyForTaskSchedulingResponse.prototype.companies = $util.emptyArray;
    
                    /**
                     * Creates a new FindCompaniesReadyForTaskSchedulingResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse
                     * @static
                     * @param {palexy.streaming.v1.IFindCompaniesReadyForTaskSchedulingResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse} FindCompaniesReadyForTaskSchedulingResponse instance
                     */
                    FindCompaniesReadyForTaskSchedulingResponse.create = function create(properties) {
                        return new FindCompaniesReadyForTaskSchedulingResponse(properties);
                    };
    
                    /**
                     * Encodes the specified FindCompaniesReadyForTaskSchedulingResponse message. Does not implicitly {@link palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse
                     * @static
                     * @param {palexy.streaming.v1.IFindCompaniesReadyForTaskSchedulingResponse} message FindCompaniesReadyForTaskSchedulingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FindCompaniesReadyForTaskSchedulingResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companies != null && message.companies.length)
                            for (var i = 0; i < message.companies.length; ++i)
                                $root.palexy.streaming.v1.Company.encode(message.companies[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FindCompaniesReadyForTaskSchedulingResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse
                     * @static
                     * @param {palexy.streaming.v1.IFindCompaniesReadyForTaskSchedulingResponse} message FindCompaniesReadyForTaskSchedulingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FindCompaniesReadyForTaskSchedulingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FindCompaniesReadyForTaskSchedulingResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse} FindCompaniesReadyForTaskSchedulingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FindCompaniesReadyForTaskSchedulingResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.companies && message.companies.length))
                                    message.companies = [];
                                message.companies.push($root.palexy.streaming.v1.Company.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FindCompaniesReadyForTaskSchedulingResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse} FindCompaniesReadyForTaskSchedulingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FindCompaniesReadyForTaskSchedulingResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FindCompaniesReadyForTaskSchedulingResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FindCompaniesReadyForTaskSchedulingResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companies != null && message.hasOwnProperty("companies")) {
                            if (!Array.isArray(message.companies))
                                return "companies: array expected";
                            for (var i = 0; i < message.companies.length; ++i) {
                                var error = $root.palexy.streaming.v1.Company.verify(message.companies[i]);
                                if (error)
                                    return "companies." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a FindCompaniesReadyForTaskSchedulingResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse} FindCompaniesReadyForTaskSchedulingResponse
                     */
                    FindCompaniesReadyForTaskSchedulingResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse();
                        if (object.companies) {
                            if (!Array.isArray(object.companies))
                                throw TypeError(".palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse.companies: array expected");
                            message.companies = [];
                            for (var i = 0; i < object.companies.length; ++i) {
                                if (typeof object.companies[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse.companies: object expected");
                                message.companies[i] = $root.palexy.streaming.v1.Company.fromObject(object.companies[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FindCompaniesReadyForTaskSchedulingResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse
                     * @static
                     * @param {palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse} message FindCompaniesReadyForTaskSchedulingResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FindCompaniesReadyForTaskSchedulingResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.companies = [];
                        if (message.companies && message.companies.length) {
                            object.companies = [];
                            for (var j = 0; j < message.companies.length; ++j)
                                object.companies[j] = $root.palexy.streaming.v1.Company.toObject(message.companies[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this FindCompaniesReadyForTaskSchedulingResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FindCompaniesReadyForTaskSchedulingResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FindCompaniesReadyForTaskSchedulingResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FindCompaniesReadyForTaskSchedulingResponse;
                })();
    
                v1.PosService = (function() {
    
                    /**
                     * Constructs a new PosService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function PosService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (PosService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PosService;
    
                    /**
                     * Creates new PosService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.PosService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {PosService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    PosService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#getPosProduct}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef GetPosProductCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosProduct} [response] PosProduct
                     */
    
                    /**
                     * Calls GetPosProduct.
                     * @function getPosProduct
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IGetPosProductRequest} request GetPosProductRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.GetPosProductCallback} callback Node-style callback called with the error, if any, and PosProduct
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.getPosProduct = function getPosProduct(request, callback) {
                        return this.rpcCall(getPosProduct, $root.palexy.streaming.v1.GetPosProductRequest, $root.palexy.streaming.v1.PosProduct, request, callback);
                    }, "name", { value: "GetPosProduct" });
    
                    /**
                     * Calls GetPosProduct.
                     * @function getPosProduct
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IGetPosProductRequest} request GetPosProductRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosProduct>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#listPosProduct}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef ListPosProductCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListPosProductResponse} [response] ListPosProductResponse
                     */
    
                    /**
                     * Calls ListPosProduct.
                     * @function listPosProduct
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListPosProductRequest} request ListPosProductRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.ListPosProductCallback} callback Node-style callback called with the error, if any, and ListPosProductResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.listPosProduct = function listPosProduct(request, callback) {
                        return this.rpcCall(listPosProduct, $root.palexy.streaming.v1.ListPosProductRequest, $root.palexy.streaming.v1.ListPosProductResponse, request, callback);
                    }, "name", { value: "ListPosProduct" });
    
                    /**
                     * Calls ListPosProduct.
                     * @function listPosProduct
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListPosProductRequest} request ListPosProductRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListPosProductResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#listPosTransaction}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef ListPosTransactionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListPosTransactionResponse} [response] ListPosTransactionResponse
                     */
    
                    /**
                     * Calls ListPosTransaction.
                     * @function listPosTransaction
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListPosTransactionRequest} request ListPosTransactionRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.ListPosTransactionCallback} callback Node-style callback called with the error, if any, and ListPosTransactionResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.listPosTransaction = function listPosTransaction(request, callback) {
                        return this.rpcCall(listPosTransaction, $root.palexy.streaming.v1.ListPosTransactionRequest, $root.palexy.streaming.v1.ListPosTransactionResponse, request, callback);
                    }, "name", { value: "ListPosTransaction" });
    
                    /**
                     * Calls ListPosTransaction.
                     * @function listPosTransaction
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListPosTransactionRequest} request ListPosTransactionRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListPosTransactionResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#importPosProduct}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef ImportPosProductCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ImportPosProductResponse} [response] ImportPosProductResponse
                     */
    
                    /**
                     * Calls ImportPosProduct.
                     * @function importPosProduct
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IImportPosProductRequest} request ImportPosProductRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.ImportPosProductCallback} callback Node-style callback called with the error, if any, and ImportPosProductResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.importPosProduct = function importPosProduct(request, callback) {
                        return this.rpcCall(importPosProduct, $root.palexy.streaming.v1.ImportPosProductRequest, $root.palexy.streaming.v1.ImportPosProductResponse, request, callback);
                    }, "name", { value: "ImportPosProduct" });
    
                    /**
                     * Calls ImportPosProduct.
                     * @function importPosProduct
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IImportPosProductRequest} request ImportPosProductRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ImportPosProductResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#importPosTransaction}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef ImportPosTransactionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ImportPosTransactionResponse} [response] ImportPosTransactionResponse
                     */
    
                    /**
                     * Calls ImportPosTransaction.
                     * @function importPosTransaction
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IImportPosTransactionRequest} request ImportPosTransactionRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.ImportPosTransactionCallback} callback Node-style callback called with the error, if any, and ImportPosTransactionResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.importPosTransaction = function importPosTransaction(request, callback) {
                        return this.rpcCall(importPosTransaction, $root.palexy.streaming.v1.ImportPosTransactionRequest, $root.palexy.streaming.v1.ImportPosTransactionResponse, request, callback);
                    }, "name", { value: "ImportPosTransaction" });
    
                    /**
                     * Calls ImportPosTransaction.
                     * @function importPosTransaction
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IImportPosTransactionRequest} request ImportPosTransactionRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ImportPosTransactionResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#createPosMetadata}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef CreatePosMetadataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosMetadata} [response] PosMetadata
                     */
    
                    /**
                     * Calls CreatePosMetadata.
                     * @function createPosMetadata
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IPosMetadata} request PosMetadata message or plain object
                     * @param {palexy.streaming.v1.PosService.CreatePosMetadataCallback} callback Node-style callback called with the error, if any, and PosMetadata
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.createPosMetadata = function createPosMetadata(request, callback) {
                        return this.rpcCall(createPosMetadata, $root.palexy.streaming.v1.PosMetadata, $root.palexy.streaming.v1.PosMetadata, request, callback);
                    }, "name", { value: "CreatePosMetadata" });
    
                    /**
                     * Calls CreatePosMetadata.
                     * @function createPosMetadata
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IPosMetadata} request PosMetadata message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosMetadata>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#updatePosMetadata}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef UpdatePosMetadataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosMetadata} [response] PosMetadata
                     */
    
                    /**
                     * Calls UpdatePosMetadata.
                     * @function updatePosMetadata
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IPosMetadata} request PosMetadata message or plain object
                     * @param {palexy.streaming.v1.PosService.UpdatePosMetadataCallback} callback Node-style callback called with the error, if any, and PosMetadata
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.updatePosMetadata = function updatePosMetadata(request, callback) {
                        return this.rpcCall(updatePosMetadata, $root.palexy.streaming.v1.PosMetadata, $root.palexy.streaming.v1.PosMetadata, request, callback);
                    }, "name", { value: "UpdatePosMetadata" });
    
                    /**
                     * Calls UpdatePosMetadata.
                     * @function updatePosMetadata
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IPosMetadata} request PosMetadata message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosMetadata>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#deletePosMetadata}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef DeletePosMetadataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeletePosMetadata.
                     * @function deletePosMetadata
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IDeletePosMetadataRequest} request DeletePosMetadataRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.DeletePosMetadataCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.deletePosMetadata = function deletePosMetadata(request, callback) {
                        return this.rpcCall(deletePosMetadata, $root.palexy.streaming.v1.DeletePosMetadataRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeletePosMetadata" });
    
                    /**
                     * Calls DeletePosMetadata.
                     * @function deletePosMetadata
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IDeletePosMetadataRequest} request DeletePosMetadataRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#getPosMetadata}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef GetPosMetadataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosMetadata} [response] PosMetadata
                     */
    
                    /**
                     * Calls GetPosMetadata.
                     * @function getPosMetadata
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IGetPosMetadataRequest} request GetPosMetadataRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.GetPosMetadataCallback} callback Node-style callback called with the error, if any, and PosMetadata
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.getPosMetadata = function getPosMetadata(request, callback) {
                        return this.rpcCall(getPosMetadata, $root.palexy.streaming.v1.GetPosMetadataRequest, $root.palexy.streaming.v1.PosMetadata, request, callback);
                    }, "name", { value: "GetPosMetadata" });
    
                    /**
                     * Calls GetPosMetadata.
                     * @function getPosMetadata
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IGetPosMetadataRequest} request GetPosMetadataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosMetadata>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#listPosMetadata}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef ListPosMetadataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListPosMetadataResponse} [response] ListPosMetadataResponse
                     */
    
                    /**
                     * Calls ListPosMetadata.
                     * @function listPosMetadata
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListPosMetadataRequest} request ListPosMetadataRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.ListPosMetadataCallback} callback Node-style callback called with the error, if any, and ListPosMetadataResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.listPosMetadata = function listPosMetadata(request, callback) {
                        return this.rpcCall(listPosMetadata, $root.palexy.streaming.v1.ListPosMetadataRequest, $root.palexy.streaming.v1.ListPosMetadataResponse, request, callback);
                    }, "name", { value: "ListPosMetadata" });
    
                    /**
                     * Calls ListPosMetadata.
                     * @function listPosMetadata
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListPosMetadataRequest} request ListPosMetadataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListPosMetadataResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#listPosMetadataValue}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef ListPosMetadataValueCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListPosMetadataValueResponse} [response] ListPosMetadataValueResponse
                     */
    
                    /**
                     * Calls ListPosMetadataValue.
                     * @function listPosMetadataValue
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListPosMetadataValueRequest} request ListPosMetadataValueRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.ListPosMetadataValueCallback} callback Node-style callback called with the error, if any, and ListPosMetadataValueResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.listPosMetadataValue = function listPosMetadataValue(request, callback) {
                        return this.rpcCall(listPosMetadataValue, $root.palexy.streaming.v1.ListPosMetadataValueRequest, $root.palexy.streaming.v1.ListPosMetadataValueResponse, request, callback);
                    }, "name", { value: "ListPosMetadataValue" });
    
                    /**
                     * Calls ListPosMetadataValue.
                     * @function listPosMetadataValue
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListPosMetadataValueRequest} request ListPosMetadataValueRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListPosMetadataValueResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#createPosImportConfig}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef CreatePosImportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosImportConfig} [response] PosImportConfig
                     */
    
                    /**
                     * Calls CreatePosImportConfig.
                     * @function createPosImportConfig
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IPosImportConfig} request PosImportConfig message or plain object
                     * @param {palexy.streaming.v1.PosService.CreatePosImportConfigCallback} callback Node-style callback called with the error, if any, and PosImportConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.createPosImportConfig = function createPosImportConfig(request, callback) {
                        return this.rpcCall(createPosImportConfig, $root.palexy.streaming.v1.PosImportConfig, $root.palexy.streaming.v1.PosImportConfig, request, callback);
                    }, "name", { value: "CreatePosImportConfig" });
    
                    /**
                     * Calls CreatePosImportConfig.
                     * @function createPosImportConfig
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IPosImportConfig} request PosImportConfig message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosImportConfig>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#updatePosImportConfig}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef UpdatePosImportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosImportConfig} [response] PosImportConfig
                     */
    
                    /**
                     * Calls UpdatePosImportConfig.
                     * @function updatePosImportConfig
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IPosImportConfig} request PosImportConfig message or plain object
                     * @param {palexy.streaming.v1.PosService.UpdatePosImportConfigCallback} callback Node-style callback called with the error, if any, and PosImportConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.updatePosImportConfig = function updatePosImportConfig(request, callback) {
                        return this.rpcCall(updatePosImportConfig, $root.palexy.streaming.v1.PosImportConfig, $root.palexy.streaming.v1.PosImportConfig, request, callback);
                    }, "name", { value: "UpdatePosImportConfig" });
    
                    /**
                     * Calls UpdatePosImportConfig.
                     * @function updatePosImportConfig
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IPosImportConfig} request PosImportConfig message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosImportConfig>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#getPosImportConfig}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef GetPosImportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosImportConfig} [response] PosImportConfig
                     */
    
                    /**
                     * Calls GetPosImportConfig.
                     * @function getPosImportConfig
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IGetPosImportConfigRequest} request GetPosImportConfigRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.GetPosImportConfigCallback} callback Node-style callback called with the error, if any, and PosImportConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.getPosImportConfig = function getPosImportConfig(request, callback) {
                        return this.rpcCall(getPosImportConfig, $root.palexy.streaming.v1.GetPosImportConfigRequest, $root.palexy.streaming.v1.PosImportConfig, request, callback);
                    }, "name", { value: "GetPosImportConfig" });
    
                    /**
                     * Calls GetPosImportConfig.
                     * @function getPosImportConfig
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IGetPosImportConfigRequest} request GetPosImportConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosImportConfig>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#listPosImportConfig}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef ListPosImportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListPosImportConfigResponse} [response] ListPosImportConfigResponse
                     */
    
                    /**
                     * Calls ListPosImportConfig.
                     * @function listPosImportConfig
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListPosImportConfigRequest} request ListPosImportConfigRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.ListPosImportConfigCallback} callback Node-style callback called with the error, if any, and ListPosImportConfigResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.listPosImportConfig = function listPosImportConfig(request, callback) {
                        return this.rpcCall(listPosImportConfig, $root.palexy.streaming.v1.ListPosImportConfigRequest, $root.palexy.streaming.v1.ListPosImportConfigResponse, request, callback);
                    }, "name", { value: "ListPosImportConfig" });
    
                    /**
                     * Calls ListPosImportConfig.
                     * @function listPosImportConfig
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListPosImportConfigRequest} request ListPosImportConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListPosImportConfigResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#matchPosTransaction}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef MatchPosTransactionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.MatchPosTransactionResponse} [response] MatchPosTransactionResponse
                     */
    
                    /**
                     * Calls MatchPosTransaction.
                     * @function matchPosTransaction
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IMatchPosTransactionRequest} request MatchPosTransactionRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.MatchPosTransactionCallback} callback Node-style callback called with the error, if any, and MatchPosTransactionResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.matchPosTransaction = function matchPosTransaction(request, callback) {
                        return this.rpcCall(matchPosTransaction, $root.palexy.streaming.v1.MatchPosTransactionRequest, $root.palexy.streaming.v1.MatchPosTransactionResponse, request, callback);
                    }, "name", { value: "MatchPosTransaction" });
    
                    /**
                     * Calls MatchPosTransaction.
                     * @function matchPosTransaction
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IMatchPosTransactionRequest} request MatchPosTransactionRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.MatchPosTransactionResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#listSectionMapping}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef ListSectionMappingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListSectionMappingResponse} [response] ListSectionMappingResponse
                     */
    
                    /**
                     * Calls ListSectionMapping.
                     * @function listSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListSectionMappingRequest} request ListSectionMappingRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.ListSectionMappingCallback} callback Node-style callback called with the error, if any, and ListSectionMappingResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.listSectionMapping = function listSectionMapping(request, callback) {
                        return this.rpcCall(listSectionMapping, $root.palexy.streaming.v1.ListSectionMappingRequest, $root.palexy.streaming.v1.ListSectionMappingResponse, request, callback);
                    }, "name", { value: "ListSectionMapping" });
    
                    /**
                     * Calls ListSectionMapping.
                     * @function listSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListSectionMappingRequest} request ListSectionMappingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListSectionMappingResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#createSectionMapping}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef CreateSectionMappingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosSectionMapping} [response] PosSectionMapping
                     */
    
                    /**
                     * Calls CreateSectionMapping.
                     * @function createSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateSectionMappingRequest} request CreateSectionMappingRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.CreateSectionMappingCallback} callback Node-style callback called with the error, if any, and PosSectionMapping
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.createSectionMapping = function createSectionMapping(request, callback) {
                        return this.rpcCall(createSectionMapping, $root.palexy.streaming.v1.CreateSectionMappingRequest, $root.palexy.streaming.v1.PosSectionMapping, request, callback);
                    }, "name", { value: "CreateSectionMapping" });
    
                    /**
                     * Calls CreateSectionMapping.
                     * @function createSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateSectionMappingRequest} request CreateSectionMappingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosSectionMapping>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#updateSectionMapping}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef UpdateSectionMappingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosSectionMapping} [response] PosSectionMapping
                     */
    
                    /**
                     * Calls UpdateSectionMapping.
                     * @function updateSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateSectionMappingRequest} request UpdateSectionMappingRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.UpdateSectionMappingCallback} callback Node-style callback called with the error, if any, and PosSectionMapping
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.updateSectionMapping = function updateSectionMapping(request, callback) {
                        return this.rpcCall(updateSectionMapping, $root.palexy.streaming.v1.UpdateSectionMappingRequest, $root.palexy.streaming.v1.PosSectionMapping, request, callback);
                    }, "name", { value: "UpdateSectionMapping" });
    
                    /**
                     * Calls UpdateSectionMapping.
                     * @function updateSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateSectionMappingRequest} request UpdateSectionMappingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosSectionMapping>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#deleteSectionMapping}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef DeleteSectionMappingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteSectionMapping.
                     * @function deleteSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteSectionMappingRequest} request DeleteSectionMappingRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.DeleteSectionMappingCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.deleteSectionMapping = function deleteSectionMapping(request, callback) {
                        return this.rpcCall(deleteSectionMapping, $root.palexy.streaming.v1.DeleteSectionMappingRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteSectionMapping" });
    
                    /**
                     * Calls DeleteSectionMapping.
                     * @function deleteSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteSectionMappingRequest} request DeleteSectionMappingRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#getSectionMapping}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef GetSectionMappingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosSectionMapping} [response] PosSectionMapping
                     */
    
                    /**
                     * Calls GetSectionMapping.
                     * @function getSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IGetSectionMappingRequest} request GetSectionMappingRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.GetSectionMappingCallback} callback Node-style callback called with the error, if any, and PosSectionMapping
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.getSectionMapping = function getSectionMapping(request, callback) {
                        return this.rpcCall(getSectionMapping, $root.palexy.streaming.v1.GetSectionMappingRequest, $root.palexy.streaming.v1.PosSectionMapping, request, callback);
                    }, "name", { value: "GetSectionMapping" });
    
                    /**
                     * Calls GetSectionMapping.
                     * @function getSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IGetSectionMappingRequest} request GetSectionMappingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosSectionMapping>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#cloneSectionMapping}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef CloneSectionMappingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosSectionMapping} [response] PosSectionMapping
                     */
    
                    /**
                     * Calls CloneSectionMapping.
                     * @function cloneSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.ICloneSectionMappingRequest} request CloneSectionMappingRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.CloneSectionMappingCallback} callback Node-style callback called with the error, if any, and PosSectionMapping
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.cloneSectionMapping = function cloneSectionMapping(request, callback) {
                        return this.rpcCall(cloneSectionMapping, $root.palexy.streaming.v1.CloneSectionMappingRequest, $root.palexy.streaming.v1.PosSectionMapping, request, callback);
                    }, "name", { value: "CloneSectionMapping" });
    
                    /**
                     * Calls CloneSectionMapping.
                     * @function cloneSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.ICloneSectionMappingRequest} request CloneSectionMappingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosSectionMapping>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#listSectionMappingItem}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef ListSectionMappingItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListSectionMappingItemResponse} [response] ListSectionMappingItemResponse
                     */
    
                    /**
                     * Calls ListSectionMappingItem.
                     * @function listSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListSectionMappingItemRequest} request ListSectionMappingItemRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.ListSectionMappingItemCallback} callback Node-style callback called with the error, if any, and ListSectionMappingItemResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.listSectionMappingItem = function listSectionMappingItem(request, callback) {
                        return this.rpcCall(listSectionMappingItem, $root.palexy.streaming.v1.ListSectionMappingItemRequest, $root.palexy.streaming.v1.ListSectionMappingItemResponse, request, callback);
                    }, "name", { value: "ListSectionMappingItem" });
    
                    /**
                     * Calls ListSectionMappingItem.
                     * @function listSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListSectionMappingItemRequest} request ListSectionMappingItemRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListSectionMappingItemResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#createSectionMappingItem}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef CreateSectionMappingItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosSectionMappingItem} [response] PosSectionMappingItem
                     */
    
                    /**
                     * Calls CreateSectionMappingItem.
                     * @function createSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateSectionMappingItemRequest} request CreateSectionMappingItemRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.CreateSectionMappingItemCallback} callback Node-style callback called with the error, if any, and PosSectionMappingItem
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.createSectionMappingItem = function createSectionMappingItem(request, callback) {
                        return this.rpcCall(createSectionMappingItem, $root.palexy.streaming.v1.CreateSectionMappingItemRequest, $root.palexy.streaming.v1.PosSectionMappingItem, request, callback);
                    }, "name", { value: "CreateSectionMappingItem" });
    
                    /**
                     * Calls CreateSectionMappingItem.
                     * @function createSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateSectionMappingItemRequest} request CreateSectionMappingItemRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosSectionMappingItem>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#updateSectionMappingItem}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef UpdateSectionMappingItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosSectionMappingItem} [response] PosSectionMappingItem
                     */
    
                    /**
                     * Calls UpdateSectionMappingItem.
                     * @function updateSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateSectionMappingItemRequest} request UpdateSectionMappingItemRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.UpdateSectionMappingItemCallback} callback Node-style callback called with the error, if any, and PosSectionMappingItem
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.updateSectionMappingItem = function updateSectionMappingItem(request, callback) {
                        return this.rpcCall(updateSectionMappingItem, $root.palexy.streaming.v1.UpdateSectionMappingItemRequest, $root.palexy.streaming.v1.PosSectionMappingItem, request, callback);
                    }, "name", { value: "UpdateSectionMappingItem" });
    
                    /**
                     * Calls UpdateSectionMappingItem.
                     * @function updateSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateSectionMappingItemRequest} request UpdateSectionMappingItemRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosSectionMappingItem>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#getSectionMappingItem}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef GetSectionMappingItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.PosSectionMappingItem} [response] PosSectionMappingItem
                     */
    
                    /**
                     * Calls GetSectionMappingItem.
                     * @function getSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IGetSectionMappingItemRequest} request GetSectionMappingItemRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.GetSectionMappingItemCallback} callback Node-style callback called with the error, if any, and PosSectionMappingItem
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.getSectionMappingItem = function getSectionMappingItem(request, callback) {
                        return this.rpcCall(getSectionMappingItem, $root.palexy.streaming.v1.GetSectionMappingItemRequest, $root.palexy.streaming.v1.PosSectionMappingItem, request, callback);
                    }, "name", { value: "GetSectionMappingItem" });
    
                    /**
                     * Calls GetSectionMappingItem.
                     * @function getSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IGetSectionMappingItemRequest} request GetSectionMappingItemRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.PosSectionMappingItem>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#deleteSectionMappingItem}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef DeleteSectionMappingItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteSectionMappingItem.
                     * @function deleteSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteSectionMappingItemRequest} request DeleteSectionMappingItemRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.DeleteSectionMappingItemCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.deleteSectionMappingItem = function deleteSectionMappingItem(request, callback) {
                        return this.rpcCall(deleteSectionMappingItem, $root.palexy.streaming.v1.DeleteSectionMappingItemRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteSectionMappingItem" });
    
                    /**
                     * Calls DeleteSectionMappingItem.
                     * @function deleteSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteSectionMappingItemRequest} request DeleteSectionMappingItemRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#importSectionMappingItem}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef ImportSectionMappingItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ImportSectionMappingItemResponse} [response] ImportSectionMappingItemResponse
                     */
    
                    /**
                     * Calls ImportSectionMappingItem.
                     * @function importSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IImportSectionMappingItemRequest} request ImportSectionMappingItemRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.ImportSectionMappingItemCallback} callback Node-style callback called with the error, if any, and ImportSectionMappingItemResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.importSectionMappingItem = function importSectionMappingItem(request, callback) {
                        return this.rpcCall(importSectionMappingItem, $root.palexy.streaming.v1.ImportSectionMappingItemRequest, $root.palexy.streaming.v1.ImportSectionMappingItemResponse, request, callback);
                    }, "name", { value: "ImportSectionMappingItem" });
    
                    /**
                     * Calls ImportSectionMappingItem.
                     * @function importSectionMappingItem
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IImportSectionMappingItemRequest} request ImportSectionMappingItemRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ImportSectionMappingItemResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.PosService#listProductSectionMapping}.
                     * @memberof palexy.streaming.v1.PosService
                     * @typedef ListProductSectionMappingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListProductSectionMappingResponse} [response] ListProductSectionMappingResponse
                     */
    
                    /**
                     * Calls ListProductSectionMapping.
                     * @function listProductSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListProductSectionMappingRequest} request ListProductSectionMappingRequest message or plain object
                     * @param {palexy.streaming.v1.PosService.ListProductSectionMappingCallback} callback Node-style callback called with the error, if any, and ListProductSectionMappingResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PosService.prototype.listProductSectionMapping = function listProductSectionMapping(request, callback) {
                        return this.rpcCall(listProductSectionMapping, $root.palexy.streaming.v1.ListProductSectionMappingRequest, $root.palexy.streaming.v1.ListProductSectionMappingResponse, request, callback);
                    }, "name", { value: "ListProductSectionMapping" });
    
                    /**
                     * Calls ListProductSectionMapping.
                     * @function listProductSectionMapping
                     * @memberof palexy.streaming.v1.PosService
                     * @instance
                     * @param {palexy.streaming.v1.IListProductSectionMappingRequest} request ListProductSectionMappingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListProductSectionMappingResponse>} Promise
                     * @variation 2
                     */
    
                    return PosService;
                })();
    
                v1.ListProductSectionMappingRequest = (function() {
    
                    /**
                     * Properties of a ListProductSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListProductSectionMappingRequest
                     * @property {number|null} [storeId] ListProductSectionMappingRequest storeId
                     * @property {number|null} [dateId] ListProductSectionMappingRequest dateId
                     */
    
                    /**
                     * Constructs a new ListProductSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListProductSectionMappingRequest.
                     * @implements IListProductSectionMappingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListProductSectionMappingRequest=} [properties] Properties to set
                     */
                    function ListProductSectionMappingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListProductSectionMappingRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.ListProductSectionMappingRequest
                     * @instance
                     */
                    ListProductSectionMappingRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListProductSectionMappingRequest dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.ListProductSectionMappingRequest
                     * @instance
                     */
                    ListProductSectionMappingRequest.prototype.dateId = 0;
    
                    /**
                     * Creates a new ListProductSectionMappingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListProductSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IListProductSectionMappingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListProductSectionMappingRequest} ListProductSectionMappingRequest instance
                     */
                    ListProductSectionMappingRequest.create = function create(properties) {
                        return new ListProductSectionMappingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListProductSectionMappingRequest message. Does not implicitly {@link palexy.streaming.v1.ListProductSectionMappingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListProductSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IListProductSectionMappingRequest} message ListProductSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListProductSectionMappingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListProductSectionMappingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListProductSectionMappingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListProductSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IListProductSectionMappingRequest} message ListProductSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListProductSectionMappingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListProductSectionMappingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListProductSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListProductSectionMappingRequest} ListProductSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListProductSectionMappingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListProductSectionMappingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListProductSectionMappingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListProductSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListProductSectionMappingRequest} ListProductSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListProductSectionMappingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListProductSectionMappingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListProductSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListProductSectionMappingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListProductSectionMappingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListProductSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListProductSectionMappingRequest} ListProductSectionMappingRequest
                     */
                    ListProductSectionMappingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListProductSectionMappingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListProductSectionMappingRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListProductSectionMappingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListProductSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.ListProductSectionMappingRequest} message ListProductSectionMappingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListProductSectionMappingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        return object;
                    };
    
                    /**
                     * Converts this ListProductSectionMappingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListProductSectionMappingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListProductSectionMappingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListProductSectionMappingRequest;
                })();
    
                v1.ListProductSectionMappingResponse = (function() {
    
                    /**
                     * Properties of a ListProductSectionMappingResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListProductSectionMappingResponse
                     * @property {Array.<palexy.streaming.v1.IProductSectionMapping>|null} [productSectionMapping] ListProductSectionMappingResponse productSectionMapping
                     */
    
                    /**
                     * Constructs a new ListProductSectionMappingResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListProductSectionMappingResponse.
                     * @implements IListProductSectionMappingResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListProductSectionMappingResponse=} [properties] Properties to set
                     */
                    function ListProductSectionMappingResponse(properties) {
                        this.productSectionMapping = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListProductSectionMappingResponse productSectionMapping.
                     * @member {Array.<palexy.streaming.v1.IProductSectionMapping>} productSectionMapping
                     * @memberof palexy.streaming.v1.ListProductSectionMappingResponse
                     * @instance
                     */
                    ListProductSectionMappingResponse.prototype.productSectionMapping = $util.emptyArray;
    
                    /**
                     * Creates a new ListProductSectionMappingResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListProductSectionMappingResponse
                     * @static
                     * @param {palexy.streaming.v1.IListProductSectionMappingResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListProductSectionMappingResponse} ListProductSectionMappingResponse instance
                     */
                    ListProductSectionMappingResponse.create = function create(properties) {
                        return new ListProductSectionMappingResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListProductSectionMappingResponse message. Does not implicitly {@link palexy.streaming.v1.ListProductSectionMappingResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListProductSectionMappingResponse
                     * @static
                     * @param {palexy.streaming.v1.IListProductSectionMappingResponse} message ListProductSectionMappingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListProductSectionMappingResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.productSectionMapping != null && message.productSectionMapping.length)
                            for (var i = 0; i < message.productSectionMapping.length; ++i)
                                $root.palexy.streaming.v1.ProductSectionMapping.encode(message.productSectionMapping[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListProductSectionMappingResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListProductSectionMappingResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListProductSectionMappingResponse
                     * @static
                     * @param {palexy.streaming.v1.IListProductSectionMappingResponse} message ListProductSectionMappingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListProductSectionMappingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListProductSectionMappingResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListProductSectionMappingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListProductSectionMappingResponse} ListProductSectionMappingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListProductSectionMappingResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListProductSectionMappingResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.productSectionMapping && message.productSectionMapping.length))
                                    message.productSectionMapping = [];
                                message.productSectionMapping.push($root.palexy.streaming.v1.ProductSectionMapping.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListProductSectionMappingResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListProductSectionMappingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListProductSectionMappingResponse} ListProductSectionMappingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListProductSectionMappingResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListProductSectionMappingResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListProductSectionMappingResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListProductSectionMappingResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.productSectionMapping != null && message.hasOwnProperty("productSectionMapping")) {
                            if (!Array.isArray(message.productSectionMapping))
                                return "productSectionMapping: array expected";
                            for (var i = 0; i < message.productSectionMapping.length; ++i) {
                                var error = $root.palexy.streaming.v1.ProductSectionMapping.verify(message.productSectionMapping[i]);
                                if (error)
                                    return "productSectionMapping." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListProductSectionMappingResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListProductSectionMappingResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListProductSectionMappingResponse} ListProductSectionMappingResponse
                     */
                    ListProductSectionMappingResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListProductSectionMappingResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListProductSectionMappingResponse();
                        if (object.productSectionMapping) {
                            if (!Array.isArray(object.productSectionMapping))
                                throw TypeError(".palexy.streaming.v1.ListProductSectionMappingResponse.productSectionMapping: array expected");
                            message.productSectionMapping = [];
                            for (var i = 0; i < object.productSectionMapping.length; ++i) {
                                if (typeof object.productSectionMapping[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListProductSectionMappingResponse.productSectionMapping: object expected");
                                message.productSectionMapping[i] = $root.palexy.streaming.v1.ProductSectionMapping.fromObject(object.productSectionMapping[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListProductSectionMappingResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListProductSectionMappingResponse
                     * @static
                     * @param {palexy.streaming.v1.ListProductSectionMappingResponse} message ListProductSectionMappingResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListProductSectionMappingResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.productSectionMapping = [];
                        if (message.productSectionMapping && message.productSectionMapping.length) {
                            object.productSectionMapping = [];
                            for (var j = 0; j < message.productSectionMapping.length; ++j)
                                object.productSectionMapping[j] = $root.palexy.streaming.v1.ProductSectionMapping.toObject(message.productSectionMapping[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListProductSectionMappingResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListProductSectionMappingResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListProductSectionMappingResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListProductSectionMappingResponse;
                })();
    
                v1.ProductSectionMapping = (function() {
    
                    /**
                     * Properties of a ProductSectionMapping.
                     * @memberof palexy.streaming.v1
                     * @interface IProductSectionMapping
                     * @property {number|null} [posProductId] ProductSectionMapping posProductId
                     * @property {number|null} [metadataValueId] ProductSectionMapping metadataValueId
                     */
    
                    /**
                     * Constructs a new ProductSectionMapping.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ProductSectionMapping.
                     * @implements IProductSectionMapping
                     * @constructor
                     * @param {palexy.streaming.v1.IProductSectionMapping=} [properties] Properties to set
                     */
                    function ProductSectionMapping(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ProductSectionMapping posProductId.
                     * @member {number} posProductId
                     * @memberof palexy.streaming.v1.ProductSectionMapping
                     * @instance
                     */
                    ProductSectionMapping.prototype.posProductId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ProductSectionMapping metadataValueId.
                     * @member {number} metadataValueId
                     * @memberof palexy.streaming.v1.ProductSectionMapping
                     * @instance
                     */
                    ProductSectionMapping.prototype.metadataValueId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ProductSectionMapping instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ProductSectionMapping
                     * @static
                     * @param {palexy.streaming.v1.IProductSectionMapping=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ProductSectionMapping} ProductSectionMapping instance
                     */
                    ProductSectionMapping.create = function create(properties) {
                        return new ProductSectionMapping(properties);
                    };
    
                    /**
                     * Encodes the specified ProductSectionMapping message. Does not implicitly {@link palexy.streaming.v1.ProductSectionMapping.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ProductSectionMapping
                     * @static
                     * @param {palexy.streaming.v1.IProductSectionMapping} message ProductSectionMapping message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductSectionMapping.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.posProductId != null && Object.hasOwnProperty.call(message, "posProductId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.posProductId);
                        if (message.metadataValueId != null && Object.hasOwnProperty.call(message, "metadataValueId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.metadataValueId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ProductSectionMapping message, length delimited. Does not implicitly {@link palexy.streaming.v1.ProductSectionMapping.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ProductSectionMapping
                     * @static
                     * @param {palexy.streaming.v1.IProductSectionMapping} message ProductSectionMapping message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductSectionMapping.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ProductSectionMapping message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ProductSectionMapping
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ProductSectionMapping} ProductSectionMapping
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductSectionMapping.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ProductSectionMapping();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.posProductId = reader.int64();
                                break;
                            case 2:
                                message.metadataValueId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ProductSectionMapping message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ProductSectionMapping
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ProductSectionMapping} ProductSectionMapping
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductSectionMapping.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ProductSectionMapping message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ProductSectionMapping
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProductSectionMapping.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.posProductId != null && message.hasOwnProperty("posProductId"))
                            if (!$util.isInteger(message.posProductId) && !(message.posProductId && $util.isInteger(message.posProductId.low) && $util.isInteger(message.posProductId.high)))
                                return "posProductId: integer|Long expected";
                        if (message.metadataValueId != null && message.hasOwnProperty("metadataValueId"))
                            if (!$util.isInteger(message.metadataValueId) && !(message.metadataValueId && $util.isInteger(message.metadataValueId.low) && $util.isInteger(message.metadataValueId.high)))
                                return "metadataValueId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ProductSectionMapping message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ProductSectionMapping
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ProductSectionMapping} ProductSectionMapping
                     */
                    ProductSectionMapping.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ProductSectionMapping)
                            return object;
                        var message = new $root.palexy.streaming.v1.ProductSectionMapping();
                        if (object.posProductId != null)
                            if ($util.Long)
                                (message.posProductId = $util.Long.fromValue(object.posProductId)).unsigned = false;
                            else if (typeof object.posProductId === "string")
                                message.posProductId = parseInt(object.posProductId, 10);
                            else if (typeof object.posProductId === "number")
                                message.posProductId = object.posProductId;
                            else if (typeof object.posProductId === "object")
                                message.posProductId = new $util.LongBits(object.posProductId.low >>> 0, object.posProductId.high >>> 0).toNumber();
                        if (object.metadataValueId != null)
                            if ($util.Long)
                                (message.metadataValueId = $util.Long.fromValue(object.metadataValueId)).unsigned = false;
                            else if (typeof object.metadataValueId === "string")
                                message.metadataValueId = parseInt(object.metadataValueId, 10);
                            else if (typeof object.metadataValueId === "number")
                                message.metadataValueId = object.metadataValueId;
                            else if (typeof object.metadataValueId === "object")
                                message.metadataValueId = new $util.LongBits(object.metadataValueId.low >>> 0, object.metadataValueId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ProductSectionMapping message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ProductSectionMapping
                     * @static
                     * @param {palexy.streaming.v1.ProductSectionMapping} message ProductSectionMapping
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProductSectionMapping.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.posProductId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.posProductId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataValueId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataValueId = options.longs === String ? "0" : 0;
                        }
                        if (message.posProductId != null && message.hasOwnProperty("posProductId"))
                            if (typeof message.posProductId === "number")
                                object.posProductId = options.longs === String ? String(message.posProductId) : message.posProductId;
                            else
                                object.posProductId = options.longs === String ? $util.Long.prototype.toString.call(message.posProductId) : options.longs === Number ? new $util.LongBits(message.posProductId.low >>> 0, message.posProductId.high >>> 0).toNumber() : message.posProductId;
                        if (message.metadataValueId != null && message.hasOwnProperty("metadataValueId"))
                            if (typeof message.metadataValueId === "number")
                                object.metadataValueId = options.longs === String ? String(message.metadataValueId) : message.metadataValueId;
                            else
                                object.metadataValueId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataValueId) : options.longs === Number ? new $util.LongBits(message.metadataValueId.low >>> 0, message.metadataValueId.high >>> 0).toNumber() : message.metadataValueId;
                        return object;
                    };
    
                    /**
                     * Converts this ProductSectionMapping to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ProductSectionMapping
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProductSectionMapping.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ProductSectionMapping;
                })();
    
                v1.GetPosProductRequest = (function() {
    
                    /**
                     * Properties of a GetPosProductRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetPosProductRequest
                     * @property {number|null} [productId] GetPosProductRequest productId
                     */
    
                    /**
                     * Constructs a new GetPosProductRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetPosProductRequest.
                     * @implements IGetPosProductRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetPosProductRequest=} [properties] Properties to set
                     */
                    function GetPosProductRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetPosProductRequest productId.
                     * @member {number} productId
                     * @memberof palexy.streaming.v1.GetPosProductRequest
                     * @instance
                     */
                    GetPosProductRequest.prototype.productId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetPosProductRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetPosProductRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetPosProductRequest} GetPosProductRequest instance
                     */
                    GetPosProductRequest.create = function create(properties) {
                        return new GetPosProductRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetPosProductRequest message. Does not implicitly {@link palexy.streaming.v1.GetPosProductRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetPosProductRequest} message GetPosProductRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetPosProductRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.productId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetPosProductRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetPosProductRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetPosProductRequest} message GetPosProductRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetPosProductRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetPosProductRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetPosProductRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetPosProductRequest} GetPosProductRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetPosProductRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetPosProductRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.productId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetPosProductRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetPosProductRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetPosProductRequest} GetPosProductRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetPosProductRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetPosProductRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetPosProductRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetPosProductRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.productId != null && message.hasOwnProperty("productId"))
                            if (!$util.isInteger(message.productId) && !(message.productId && $util.isInteger(message.productId.low) && $util.isInteger(message.productId.high)))
                                return "productId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetPosProductRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetPosProductRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetPosProductRequest} GetPosProductRequest
                     */
                    GetPosProductRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetPosProductRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetPosProductRequest();
                        if (object.productId != null)
                            if ($util.Long)
                                (message.productId = $util.Long.fromValue(object.productId)).unsigned = false;
                            else if (typeof object.productId === "string")
                                message.productId = parseInt(object.productId, 10);
                            else if (typeof object.productId === "number")
                                message.productId = object.productId;
                            else if (typeof object.productId === "object")
                                message.productId = new $util.LongBits(object.productId.low >>> 0, object.productId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetPosProductRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.GetPosProductRequest} message GetPosProductRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetPosProductRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.productId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.productId = options.longs === String ? "0" : 0;
                        if (message.productId != null && message.hasOwnProperty("productId"))
                            if (typeof message.productId === "number")
                                object.productId = options.longs === String ? String(message.productId) : message.productId;
                            else
                                object.productId = options.longs === String ? $util.Long.prototype.toString.call(message.productId) : options.longs === Number ? new $util.LongBits(message.productId.low >>> 0, message.productId.high >>> 0).toNumber() : message.productId;
                        return object;
                    };
    
                    /**
                     * Converts this GetPosProductRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetPosProductRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetPosProductRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetPosProductRequest;
                })();
    
                v1.ListPosProductRequest = (function() {
    
                    /**
                     * Properties of a ListPosProductRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListPosProductRequest
                     * @property {number|null} [companyId] ListPosProductRequest companyId
                     * @property {string|null} [name] ListPosProductRequest name
                     * @property {string|null} [code] ListPosProductRequest code
                     * @property {palexy.streaming.v1.ListPosProductRequest.IProductIdsFilter|null} [idsFilter] ListPosProductRequest idsFilter
                     * @property {palexy.streaming.v1.ListPosProductRequest.IMetadataFilter|null} [metadataFilter] ListPosProductRequest metadataFilter
                     * @property {boolean|null} [includeMetadataInfo] ListPosProductRequest includeMetadataInfo
                     * @property {number|null} [pageSize] ListPosProductRequest pageSize
                     * @property {string|null} [pageToken] ListPosProductRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListPosProductRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListPosProductRequest.
                     * @implements IListPosProductRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListPosProductRequest=} [properties] Properties to set
                     */
                    function ListPosProductRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListPosProductRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @instance
                     */
                    ListPosProductRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListPosProductRequest name.
                     * @member {string|null|undefined} name
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @instance
                     */
                    ListPosProductRequest.prototype.name = null;
    
                    /**
                     * ListPosProductRequest code.
                     * @member {string|null|undefined} code
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @instance
                     */
                    ListPosProductRequest.prototype.code = null;
    
                    /**
                     * ListPosProductRequest idsFilter.
                     * @member {palexy.streaming.v1.ListPosProductRequest.IProductIdsFilter|null|undefined} idsFilter
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @instance
                     */
                    ListPosProductRequest.prototype.idsFilter = null;
    
                    /**
                     * ListPosProductRequest metadataFilter.
                     * @member {palexy.streaming.v1.ListPosProductRequest.IMetadataFilter|null|undefined} metadataFilter
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @instance
                     */
                    ListPosProductRequest.prototype.metadataFilter = null;
    
                    /**
                     * ListPosProductRequest includeMetadataInfo.
                     * @member {boolean} includeMetadataInfo
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @instance
                     */
                    ListPosProductRequest.prototype.includeMetadataInfo = false;
    
                    /**
                     * ListPosProductRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @instance
                     */
                    ListPosProductRequest.prototype.pageSize = 0;
    
                    /**
                     * ListPosProductRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @instance
                     */
                    ListPosProductRequest.prototype.pageToken = "";
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * ListPosProductRequest productFilter.
                     * @member {"name"|"code"|"idsFilter"|"metadataFilter"|undefined} productFilter
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @instance
                     */
                    Object.defineProperty(ListPosProductRequest.prototype, "productFilter", {
                        get: $util.oneOfGetter($oneOfFields = ["name", "code", "idsFilter", "metadataFilter"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new ListPosProductRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosProductRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListPosProductRequest} ListPosProductRequest instance
                     */
                    ListPosProductRequest.create = function create(properties) {
                        return new ListPosProductRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListPosProductRequest message. Does not implicitly {@link palexy.streaming.v1.ListPosProductRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosProductRequest} message ListPosProductRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosProductRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.code);
                        if (message.includeMetadataInfo != null && Object.hasOwnProperty.call(message, "includeMetadataInfo"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeMetadataInfo);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.pageToken);
                        if (message.idsFilter != null && Object.hasOwnProperty.call(message, "idsFilter"))
                            $root.palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter.encode(message.idsFilter, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.metadataFilter != null && Object.hasOwnProperty.call(message, "metadataFilter"))
                            $root.palexy.streaming.v1.ListPosProductRequest.MetadataFilter.encode(message.metadataFilter, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListPosProductRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosProductRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosProductRequest} message ListPosProductRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosProductRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListPosProductRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListPosProductRequest} ListPosProductRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosProductRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosProductRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.code = reader.string();
                                break;
                            case 7:
                                message.idsFilter = $root.palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.metadataFilter = $root.palexy.streaming.v1.ListPosProductRequest.MetadataFilter.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.includeMetadataInfo = reader.bool();
                                break;
                            case 5:
                                message.pageSize = reader.int32();
                                break;
                            case 6:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListPosProductRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListPosProductRequest} ListPosProductRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosProductRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListPosProductRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListPosProductRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name")) {
                            properties.productFilter = 1;
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        }
                        if (message.code != null && message.hasOwnProperty("code")) {
                            if (properties.productFilter === 1)
                                return "productFilter: multiple values";
                            properties.productFilter = 1;
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        }
                        if (message.idsFilter != null && message.hasOwnProperty("idsFilter")) {
                            if (properties.productFilter === 1)
                                return "productFilter: multiple values";
                            properties.productFilter = 1;
                            {
                                var error = $root.palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter.verify(message.idsFilter);
                                if (error)
                                    return "idsFilter." + error;
                            }
                        }
                        if (message.metadataFilter != null && message.hasOwnProperty("metadataFilter")) {
                            if (properties.productFilter === 1)
                                return "productFilter: multiple values";
                            properties.productFilter = 1;
                            {
                                var error = $root.palexy.streaming.v1.ListPosProductRequest.MetadataFilter.verify(message.metadataFilter);
                                if (error)
                                    return "metadataFilter." + error;
                            }
                        }
                        if (message.includeMetadataInfo != null && message.hasOwnProperty("includeMetadataInfo"))
                            if (typeof message.includeMetadataInfo !== "boolean")
                                return "includeMetadataInfo: boolean expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListPosProductRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListPosProductRequest} ListPosProductRequest
                     */
                    ListPosProductRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListPosProductRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListPosProductRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.code != null)
                            message.code = String(object.code);
                        if (object.idsFilter != null) {
                            if (typeof object.idsFilter !== "object")
                                throw TypeError(".palexy.streaming.v1.ListPosProductRequest.idsFilter: object expected");
                            message.idsFilter = $root.palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter.fromObject(object.idsFilter);
                        }
                        if (object.metadataFilter != null) {
                            if (typeof object.metadataFilter !== "object")
                                throw TypeError(".palexy.streaming.v1.ListPosProductRequest.metadataFilter: object expected");
                            message.metadataFilter = $root.palexy.streaming.v1.ListPosProductRequest.MetadataFilter.fromObject(object.metadataFilter);
                        }
                        if (object.includeMetadataInfo != null)
                            message.includeMetadataInfo = Boolean(object.includeMetadataInfo);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListPosProductRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.ListPosProductRequest} message ListPosProductRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListPosProductRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.includeMetadataInfo = false;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.name != null && message.hasOwnProperty("name")) {
                            object.name = message.name;
                            if (options.oneofs)
                                object.productFilter = "name";
                        }
                        if (message.code != null && message.hasOwnProperty("code")) {
                            object.code = message.code;
                            if (options.oneofs)
                                object.productFilter = "code";
                        }
                        if (message.includeMetadataInfo != null && message.hasOwnProperty("includeMetadataInfo"))
                            object.includeMetadataInfo = message.includeMetadataInfo;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.idsFilter != null && message.hasOwnProperty("idsFilter")) {
                            object.idsFilter = $root.palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter.toObject(message.idsFilter, options);
                            if (options.oneofs)
                                object.productFilter = "idsFilter";
                        }
                        if (message.metadataFilter != null && message.hasOwnProperty("metadataFilter")) {
                            object.metadataFilter = $root.palexy.streaming.v1.ListPosProductRequest.MetadataFilter.toObject(message.metadataFilter, options);
                            if (options.oneofs)
                                object.productFilter = "metadataFilter";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListPosProductRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListPosProductRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListPosProductRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    ListPosProductRequest.ProductIdsFilter = (function() {
    
                        /**
                         * Properties of a ProductIdsFilter.
                         * @memberof palexy.streaming.v1.ListPosProductRequest
                         * @interface IProductIdsFilter
                         * @property {Array.<number>|null} [productIds] ProductIdsFilter productIds
                         */
    
                        /**
                         * Constructs a new ProductIdsFilter.
                         * @memberof palexy.streaming.v1.ListPosProductRequest
                         * @classdesc Represents a ProductIdsFilter.
                         * @implements IProductIdsFilter
                         * @constructor
                         * @param {palexy.streaming.v1.ListPosProductRequest.IProductIdsFilter=} [properties] Properties to set
                         */
                        function ProductIdsFilter(properties) {
                            this.productIds = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * ProductIdsFilter productIds.
                         * @member {Array.<number>} productIds
                         * @memberof palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter
                         * @instance
                         */
                        ProductIdsFilter.prototype.productIds = $util.emptyArray;
    
                        /**
                         * Creates a new ProductIdsFilter instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter
                         * @static
                         * @param {palexy.streaming.v1.ListPosProductRequest.IProductIdsFilter=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter} ProductIdsFilter instance
                         */
                        ProductIdsFilter.create = function create(properties) {
                            return new ProductIdsFilter(properties);
                        };
    
                        /**
                         * Encodes the specified ProductIdsFilter message. Does not implicitly {@link palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter
                         * @static
                         * @param {palexy.streaming.v1.ListPosProductRequest.IProductIdsFilter} message ProductIdsFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ProductIdsFilter.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.productIds != null && message.productIds.length) {
                                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                                for (var i = 0; i < message.productIds.length; ++i)
                                    writer.int64(message.productIds[i]);
                                writer.ldelim();
                            }
                            return writer;
                        };
    
                        /**
                         * Encodes the specified ProductIdsFilter message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter
                         * @static
                         * @param {palexy.streaming.v1.ListPosProductRequest.IProductIdsFilter} message ProductIdsFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ProductIdsFilter.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a ProductIdsFilter message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter} ProductIdsFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ProductIdsFilter.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.productIds && message.productIds.length))
                                        message.productIds = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.productIds.push(reader.int64());
                                    } else
                                        message.productIds.push(reader.int64());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a ProductIdsFilter message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter} ProductIdsFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ProductIdsFilter.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a ProductIdsFilter message.
                         * @function verify
                         * @memberof palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ProductIdsFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.productIds != null && message.hasOwnProperty("productIds")) {
                                if (!Array.isArray(message.productIds))
                                    return "productIds: array expected";
                                for (var i = 0; i < message.productIds.length; ++i)
                                    if (!$util.isInteger(message.productIds[i]) && !(message.productIds[i] && $util.isInteger(message.productIds[i].low) && $util.isInteger(message.productIds[i].high)))
                                        return "productIds: integer|Long[] expected";
                            }
                            return null;
                        };
    
                        /**
                         * Creates a ProductIdsFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter} ProductIdsFilter
                         */
                        ProductIdsFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter)
                                return object;
                            var message = new $root.palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter();
                            if (object.productIds) {
                                if (!Array.isArray(object.productIds))
                                    throw TypeError(".palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter.productIds: array expected");
                                message.productIds = [];
                                for (var i = 0; i < object.productIds.length; ++i)
                                    if ($util.Long)
                                        (message.productIds[i] = $util.Long.fromValue(object.productIds[i])).unsigned = false;
                                    else if (typeof object.productIds[i] === "string")
                                        message.productIds[i] = parseInt(object.productIds[i], 10);
                                    else if (typeof object.productIds[i] === "number")
                                        message.productIds[i] = object.productIds[i];
                                    else if (typeof object.productIds[i] === "object")
                                        message.productIds[i] = new $util.LongBits(object.productIds[i].low >>> 0, object.productIds[i].high >>> 0).toNumber();
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a ProductIdsFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter
                         * @static
                         * @param {palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter} message ProductIdsFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ProductIdsFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.productIds = [];
                            if (message.productIds && message.productIds.length) {
                                object.productIds = [];
                                for (var j = 0; j < message.productIds.length; ++j)
                                    if (typeof message.productIds[j] === "number")
                                        object.productIds[j] = options.longs === String ? String(message.productIds[j]) : message.productIds[j];
                                    else
                                        object.productIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.productIds[j]) : options.longs === Number ? new $util.LongBits(message.productIds[j].low >>> 0, message.productIds[j].high >>> 0).toNumber() : message.productIds[j];
                            }
                            return object;
                        };
    
                        /**
                         * Converts this ProductIdsFilter to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.ListPosProductRequest.ProductIdsFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ProductIdsFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return ProductIdsFilter;
                    })();
    
                    ListPosProductRequest.MetadataFilter = (function() {
    
                        /**
                         * Properties of a MetadataFilter.
                         * @memberof palexy.streaming.v1.ListPosProductRequest
                         * @interface IMetadataFilter
                         * @property {number|null} [metadataId] MetadataFilter metadataId
                         * @property {string|null} [metadataValue] MetadataFilter metadataValue
                         */
    
                        /**
                         * Constructs a new MetadataFilter.
                         * @memberof palexy.streaming.v1.ListPosProductRequest
                         * @classdesc Represents a MetadataFilter.
                         * @implements IMetadataFilter
                         * @constructor
                         * @param {palexy.streaming.v1.ListPosProductRequest.IMetadataFilter=} [properties] Properties to set
                         */
                        function MetadataFilter(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * MetadataFilter metadataId.
                         * @member {number} metadataId
                         * @memberof palexy.streaming.v1.ListPosProductRequest.MetadataFilter
                         * @instance
                         */
                        MetadataFilter.prototype.metadataId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * MetadataFilter metadataValue.
                         * @member {string} metadataValue
                         * @memberof palexy.streaming.v1.ListPosProductRequest.MetadataFilter
                         * @instance
                         */
                        MetadataFilter.prototype.metadataValue = "";
    
                        /**
                         * Creates a new MetadataFilter instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.ListPosProductRequest.MetadataFilter
                         * @static
                         * @param {palexy.streaming.v1.ListPosProductRequest.IMetadataFilter=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.ListPosProductRequest.MetadataFilter} MetadataFilter instance
                         */
                        MetadataFilter.create = function create(properties) {
                            return new MetadataFilter(properties);
                        };
    
                        /**
                         * Encodes the specified MetadataFilter message. Does not implicitly {@link palexy.streaming.v1.ListPosProductRequest.MetadataFilter.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.ListPosProductRequest.MetadataFilter
                         * @static
                         * @param {palexy.streaming.v1.ListPosProductRequest.IMetadataFilter} message MetadataFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        MetadataFilter.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadataId != null && Object.hasOwnProperty.call(message, "metadataId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.metadataId);
                            if (message.metadataValue != null && Object.hasOwnProperty.call(message, "metadataValue"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.metadataValue);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified MetadataFilter message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosProductRequest.MetadataFilter.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.ListPosProductRequest.MetadataFilter
                         * @static
                         * @param {palexy.streaming.v1.ListPosProductRequest.IMetadataFilter} message MetadataFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        MetadataFilter.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a MetadataFilter message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.ListPosProductRequest.MetadataFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.ListPosProductRequest.MetadataFilter} MetadataFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        MetadataFilter.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosProductRequest.MetadataFilter();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadataId = reader.int64();
                                    break;
                                case 2:
                                    message.metadataValue = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a MetadataFilter message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.ListPosProductRequest.MetadataFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.ListPosProductRequest.MetadataFilter} MetadataFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        MetadataFilter.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a MetadataFilter message.
                         * @function verify
                         * @memberof palexy.streaming.v1.ListPosProductRequest.MetadataFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        MetadataFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadataId != null && message.hasOwnProperty("metadataId"))
                                if (!$util.isInteger(message.metadataId) && !(message.metadataId && $util.isInteger(message.metadataId.low) && $util.isInteger(message.metadataId.high)))
                                    return "metadataId: integer|Long expected";
                            if (message.metadataValue != null && message.hasOwnProperty("metadataValue"))
                                if (!$util.isString(message.metadataValue))
                                    return "metadataValue: string expected";
                            return null;
                        };
    
                        /**
                         * Creates a MetadataFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.ListPosProductRequest.MetadataFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.ListPosProductRequest.MetadataFilter} MetadataFilter
                         */
                        MetadataFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.ListPosProductRequest.MetadataFilter)
                                return object;
                            var message = new $root.palexy.streaming.v1.ListPosProductRequest.MetadataFilter();
                            if (object.metadataId != null)
                                if ($util.Long)
                                    (message.metadataId = $util.Long.fromValue(object.metadataId)).unsigned = false;
                                else if (typeof object.metadataId === "string")
                                    message.metadataId = parseInt(object.metadataId, 10);
                                else if (typeof object.metadataId === "number")
                                    message.metadataId = object.metadataId;
                                else if (typeof object.metadataId === "object")
                                    message.metadataId = new $util.LongBits(object.metadataId.low >>> 0, object.metadataId.high >>> 0).toNumber();
                            if (object.metadataValue != null)
                                message.metadataValue = String(object.metadataValue);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a MetadataFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.ListPosProductRequest.MetadataFilter
                         * @static
                         * @param {palexy.streaming.v1.ListPosProductRequest.MetadataFilter} message MetadataFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        MetadataFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.metadataId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.metadataId = options.longs === String ? "0" : 0;
                                object.metadataValue = "";
                            }
                            if (message.metadataId != null && message.hasOwnProperty("metadataId"))
                                if (typeof message.metadataId === "number")
                                    object.metadataId = options.longs === String ? String(message.metadataId) : message.metadataId;
                                else
                                    object.metadataId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataId) : options.longs === Number ? new $util.LongBits(message.metadataId.low >>> 0, message.metadataId.high >>> 0).toNumber() : message.metadataId;
                            if (message.metadataValue != null && message.hasOwnProperty("metadataValue"))
                                object.metadataValue = message.metadataValue;
                            return object;
                        };
    
                        /**
                         * Converts this MetadataFilter to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.ListPosProductRequest.MetadataFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        MetadataFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return MetadataFilter;
                    })();
    
                    return ListPosProductRequest;
                })();
    
                v1.ListPosProductResponse = (function() {
    
                    /**
                     * Properties of a ListPosProductResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListPosProductResponse
                     * @property {Array.<palexy.streaming.v1.IPosProduct>|null} [posProducts] ListPosProductResponse posProducts
                     * @property {number|null} [totalElements] ListPosProductResponse totalElements
                     * @property {string|null} [nextPageToken] ListPosProductResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListPosProductResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListPosProductResponse.
                     * @implements IListPosProductResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListPosProductResponse=} [properties] Properties to set
                     */
                    function ListPosProductResponse(properties) {
                        this.posProducts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListPosProductResponse posProducts.
                     * @member {Array.<palexy.streaming.v1.IPosProduct>} posProducts
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @instance
                     */
                    ListPosProductResponse.prototype.posProducts = $util.emptyArray;
    
                    /**
                     * ListPosProductResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @instance
                     */
                    ListPosProductResponse.prototype.totalElements = 0;
    
                    /**
                     * ListPosProductResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @instance
                     */
                    ListPosProductResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListPosProductResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosProductResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListPosProductResponse} ListPosProductResponse instance
                     */
                    ListPosProductResponse.create = function create(properties) {
                        return new ListPosProductResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListPosProductResponse message. Does not implicitly {@link palexy.streaming.v1.ListPosProductResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosProductResponse} message ListPosProductResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosProductResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.posProducts != null && message.posProducts.length)
                            for (var i = 0; i < message.posProducts.length; ++i)
                                $root.palexy.streaming.v1.PosProduct.encode(message.posProducts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListPosProductResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosProductResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosProductResponse} message ListPosProductResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosProductResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListPosProductResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListPosProductResponse} ListPosProductResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosProductResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosProductResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.posProducts && message.posProducts.length))
                                    message.posProducts = [];
                                message.posProducts.push($root.palexy.streaming.v1.PosProduct.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListPosProductResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListPosProductResponse} ListPosProductResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosProductResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListPosProductResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListPosProductResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.posProducts != null && message.hasOwnProperty("posProducts")) {
                            if (!Array.isArray(message.posProducts))
                                return "posProducts: array expected";
                            for (var i = 0; i < message.posProducts.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosProduct.verify(message.posProducts[i]);
                                if (error)
                                    return "posProducts." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListPosProductResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListPosProductResponse} ListPosProductResponse
                     */
                    ListPosProductResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListPosProductResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListPosProductResponse();
                        if (object.posProducts) {
                            if (!Array.isArray(object.posProducts))
                                throw TypeError(".palexy.streaming.v1.ListPosProductResponse.posProducts: array expected");
                            message.posProducts = [];
                            for (var i = 0; i < object.posProducts.length; ++i) {
                                if (typeof object.posProducts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListPosProductResponse.posProducts: object expected");
                                message.posProducts[i] = $root.palexy.streaming.v1.PosProduct.fromObject(object.posProducts[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListPosProductResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @static
                     * @param {palexy.streaming.v1.ListPosProductResponse} message ListPosProductResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListPosProductResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.posProducts = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.posProducts && message.posProducts.length) {
                            object.posProducts = [];
                            for (var j = 0; j < message.posProducts.length; ++j)
                                object.posProducts[j] = $root.palexy.streaming.v1.PosProduct.toObject(message.posProducts[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListPosProductResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListPosProductResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListPosProductResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListPosProductResponse;
                })();
    
                v1.ListPosTransactionRequest = (function() {
    
                    /**
                     * Properties of a ListPosTransactionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListPosTransactionRequest
                     * @property {Array.<number>|null} [storeIds] ListPosTransactionRequest storeIds
                     * @property {string|null} [startDate] ListPosTransactionRequest startDate
                     * @property {string|null} [endDate] ListPosTransactionRequest endDate
                     * @property {string|null} [externalTranId] ListPosTransactionRequest externalTranId
                     * @property {boolean|null} [includeProductInfo] ListPosTransactionRequest includeProductInfo
                     * @property {boolean|null} [includeBuyerInfo] ListPosTransactionRequest includeBuyerInfo
                     * @property {boolean|null} [includeProductMetadataInfo] ListPosTransactionRequest includeProductMetadataInfo
                     * @property {number|null} [pageSize] ListPosTransactionRequest pageSize
                     * @property {string|null} [pageToken] ListPosTransactionRequest pageToken
                     * @property {Array.<string>|null} [salesStaffId] ListPosTransactionRequest salesStaffId
                     */
    
                    /**
                     * Constructs a new ListPosTransactionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListPosTransactionRequest.
                     * @implements IListPosTransactionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListPosTransactionRequest=} [properties] Properties to set
                     */
                    function ListPosTransactionRequest(properties) {
                        this.storeIds = [];
                        this.salesStaffId = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListPosTransactionRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @instance
                     */
                    ListPosTransactionRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * ListPosTransactionRequest startDate.
                     * @member {string} startDate
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @instance
                     */
                    ListPosTransactionRequest.prototype.startDate = "";
    
                    /**
                     * ListPosTransactionRequest endDate.
                     * @member {string} endDate
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @instance
                     */
                    ListPosTransactionRequest.prototype.endDate = "";
    
                    /**
                     * ListPosTransactionRequest externalTranId.
                     * @member {string} externalTranId
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @instance
                     */
                    ListPosTransactionRequest.prototype.externalTranId = "";
    
                    /**
                     * ListPosTransactionRequest includeProductInfo.
                     * @member {boolean} includeProductInfo
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @instance
                     */
                    ListPosTransactionRequest.prototype.includeProductInfo = false;
    
                    /**
                     * ListPosTransactionRequest includeBuyerInfo.
                     * @member {boolean} includeBuyerInfo
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @instance
                     */
                    ListPosTransactionRequest.prototype.includeBuyerInfo = false;
    
                    /**
                     * ListPosTransactionRequest includeProductMetadataInfo.
                     * @member {boolean} includeProductMetadataInfo
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @instance
                     */
                    ListPosTransactionRequest.prototype.includeProductMetadataInfo = false;
    
                    /**
                     * ListPosTransactionRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @instance
                     */
                    ListPosTransactionRequest.prototype.pageSize = 0;
    
                    /**
                     * ListPosTransactionRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @instance
                     */
                    ListPosTransactionRequest.prototype.pageToken = "";
    
                    /**
                     * ListPosTransactionRequest salesStaffId.
                     * @member {Array.<string>} salesStaffId
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @instance
                     */
                    ListPosTransactionRequest.prototype.salesStaffId = $util.emptyArray;
    
                    /**
                     * Creates a new ListPosTransactionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosTransactionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListPosTransactionRequest} ListPosTransactionRequest instance
                     */
                    ListPosTransactionRequest.create = function create(properties) {
                        return new ListPosTransactionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListPosTransactionRequest message. Does not implicitly {@link palexy.streaming.v1.ListPosTransactionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosTransactionRequest} message ListPosTransactionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosTransactionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.startDate);
                        if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.endDate);
                        if (message.externalTranId != null && Object.hasOwnProperty.call(message, "externalTranId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.externalTranId);
                        if (message.includeProductInfo != null && Object.hasOwnProperty.call(message, "includeProductInfo"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.includeProductInfo);
                        if (message.includeBuyerInfo != null && Object.hasOwnProperty.call(message, "includeBuyerInfo"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.includeBuyerInfo);
                        if (message.includeProductMetadataInfo != null && Object.hasOwnProperty.call(message, "includeProductMetadataInfo"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.includeProductMetadataInfo);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.pageToken);
                        if (message.salesStaffId != null && message.salesStaffId.length)
                            for (var i = 0; i < message.salesStaffId.length; ++i)
                                writer.uint32(/* id 10, wireType 2 =*/82).string(message.salesStaffId[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListPosTransactionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosTransactionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosTransactionRequest} message ListPosTransactionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosTransactionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListPosTransactionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListPosTransactionRequest} ListPosTransactionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosTransactionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosTransactionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 2:
                                message.startDate = reader.string();
                                break;
                            case 3:
                                message.endDate = reader.string();
                                break;
                            case 4:
                                message.externalTranId = reader.string();
                                break;
                            case 5:
                                message.includeProductInfo = reader.bool();
                                break;
                            case 6:
                                message.includeBuyerInfo = reader.bool();
                                break;
                            case 7:
                                message.includeProductMetadataInfo = reader.bool();
                                break;
                            case 8:
                                message.pageSize = reader.int32();
                                break;
                            case 9:
                                message.pageToken = reader.string();
                                break;
                            case 10:
                                if (!(message.salesStaffId && message.salesStaffId.length))
                                    message.salesStaffId = [];
                                message.salesStaffId.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListPosTransactionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListPosTransactionRequest} ListPosTransactionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosTransactionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListPosTransactionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListPosTransactionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        if (message.externalTranId != null && message.hasOwnProperty("externalTranId"))
                            if (!$util.isString(message.externalTranId))
                                return "externalTranId: string expected";
                        if (message.includeProductInfo != null && message.hasOwnProperty("includeProductInfo"))
                            if (typeof message.includeProductInfo !== "boolean")
                                return "includeProductInfo: boolean expected";
                        if (message.includeBuyerInfo != null && message.hasOwnProperty("includeBuyerInfo"))
                            if (typeof message.includeBuyerInfo !== "boolean")
                                return "includeBuyerInfo: boolean expected";
                        if (message.includeProductMetadataInfo != null && message.hasOwnProperty("includeProductMetadataInfo"))
                            if (typeof message.includeProductMetadataInfo !== "boolean")
                                return "includeProductMetadataInfo: boolean expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.salesStaffId != null && message.hasOwnProperty("salesStaffId")) {
                            if (!Array.isArray(message.salesStaffId))
                                return "salesStaffId: array expected";
                            for (var i = 0; i < message.salesStaffId.length; ++i)
                                if (!$util.isString(message.salesStaffId[i]))
                                    return "salesStaffId: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListPosTransactionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListPosTransactionRequest} ListPosTransactionRequest
                     */
                    ListPosTransactionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListPosTransactionRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListPosTransactionRequest();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.streaming.v1.ListPosTransactionRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        if (object.externalTranId != null)
                            message.externalTranId = String(object.externalTranId);
                        if (object.includeProductInfo != null)
                            message.includeProductInfo = Boolean(object.includeProductInfo);
                        if (object.includeBuyerInfo != null)
                            message.includeBuyerInfo = Boolean(object.includeBuyerInfo);
                        if (object.includeProductMetadataInfo != null)
                            message.includeProductMetadataInfo = Boolean(object.includeProductMetadataInfo);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.salesStaffId) {
                            if (!Array.isArray(object.salesStaffId))
                                throw TypeError(".palexy.streaming.v1.ListPosTransactionRequest.salesStaffId: array expected");
                            message.salesStaffId = [];
                            for (var i = 0; i < object.salesStaffId.length; ++i)
                                message.salesStaffId[i] = String(object.salesStaffId[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListPosTransactionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.ListPosTransactionRequest} message ListPosTransactionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListPosTransactionRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.storeIds = [];
                            object.salesStaffId = [];
                        }
                        if (options.defaults) {
                            object.startDate = "";
                            object.endDate = "";
                            object.externalTranId = "";
                            object.includeProductInfo = false;
                            object.includeBuyerInfo = false;
                            object.includeProductMetadataInfo = false;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.externalTranId != null && message.hasOwnProperty("externalTranId"))
                            object.externalTranId = message.externalTranId;
                        if (message.includeProductInfo != null && message.hasOwnProperty("includeProductInfo"))
                            object.includeProductInfo = message.includeProductInfo;
                        if (message.includeBuyerInfo != null && message.hasOwnProperty("includeBuyerInfo"))
                            object.includeBuyerInfo = message.includeBuyerInfo;
                        if (message.includeProductMetadataInfo != null && message.hasOwnProperty("includeProductMetadataInfo"))
                            object.includeProductMetadataInfo = message.includeProductMetadataInfo;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.salesStaffId && message.salesStaffId.length) {
                            object.salesStaffId = [];
                            for (var j = 0; j < message.salesStaffId.length; ++j)
                                object.salesStaffId[j] = message.salesStaffId[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListPosTransactionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListPosTransactionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListPosTransactionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListPosTransactionRequest;
                })();
    
                v1.ListPosTransactionResponse = (function() {
    
                    /**
                     * Properties of a ListPosTransactionResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListPosTransactionResponse
                     * @property {Array.<palexy.streaming.v1.IPosTransaction>|null} [posTransactions] ListPosTransactionResponse posTransactions
                     * @property {number|null} [totalElements] ListPosTransactionResponse totalElements
                     * @property {string|null} [nextPageToken] ListPosTransactionResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListPosTransactionResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListPosTransactionResponse.
                     * @implements IListPosTransactionResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListPosTransactionResponse=} [properties] Properties to set
                     */
                    function ListPosTransactionResponse(properties) {
                        this.posTransactions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListPosTransactionResponse posTransactions.
                     * @member {Array.<palexy.streaming.v1.IPosTransaction>} posTransactions
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @instance
                     */
                    ListPosTransactionResponse.prototype.posTransactions = $util.emptyArray;
    
                    /**
                     * ListPosTransactionResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @instance
                     */
                    ListPosTransactionResponse.prototype.totalElements = 0;
    
                    /**
                     * ListPosTransactionResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @instance
                     */
                    ListPosTransactionResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListPosTransactionResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosTransactionResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListPosTransactionResponse} ListPosTransactionResponse instance
                     */
                    ListPosTransactionResponse.create = function create(properties) {
                        return new ListPosTransactionResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListPosTransactionResponse message. Does not implicitly {@link palexy.streaming.v1.ListPosTransactionResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosTransactionResponse} message ListPosTransactionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosTransactionResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.posTransactions != null && message.posTransactions.length)
                            for (var i = 0; i < message.posTransactions.length; ++i)
                                $root.palexy.streaming.v1.PosTransaction.encode(message.posTransactions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListPosTransactionResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosTransactionResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosTransactionResponse} message ListPosTransactionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosTransactionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListPosTransactionResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListPosTransactionResponse} ListPosTransactionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosTransactionResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosTransactionResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.posTransactions && message.posTransactions.length))
                                    message.posTransactions = [];
                                message.posTransactions.push($root.palexy.streaming.v1.PosTransaction.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListPosTransactionResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListPosTransactionResponse} ListPosTransactionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosTransactionResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListPosTransactionResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListPosTransactionResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.posTransactions != null && message.hasOwnProperty("posTransactions")) {
                            if (!Array.isArray(message.posTransactions))
                                return "posTransactions: array expected";
                            for (var i = 0; i < message.posTransactions.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosTransaction.verify(message.posTransactions[i]);
                                if (error)
                                    return "posTransactions." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListPosTransactionResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListPosTransactionResponse} ListPosTransactionResponse
                     */
                    ListPosTransactionResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListPosTransactionResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListPosTransactionResponse();
                        if (object.posTransactions) {
                            if (!Array.isArray(object.posTransactions))
                                throw TypeError(".palexy.streaming.v1.ListPosTransactionResponse.posTransactions: array expected");
                            message.posTransactions = [];
                            for (var i = 0; i < object.posTransactions.length; ++i) {
                                if (typeof object.posTransactions[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListPosTransactionResponse.posTransactions: object expected");
                                message.posTransactions[i] = $root.palexy.streaming.v1.PosTransaction.fromObject(object.posTransactions[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListPosTransactionResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.ListPosTransactionResponse} message ListPosTransactionResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListPosTransactionResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.posTransactions = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.posTransactions && message.posTransactions.length) {
                            object.posTransactions = [];
                            for (var j = 0; j < message.posTransactions.length; ++j)
                                object.posTransactions[j] = $root.palexy.streaming.v1.PosTransaction.toObject(message.posTransactions[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListPosTransactionResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListPosTransactionResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListPosTransactionResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListPosTransactionResponse;
                })();
    
                v1.ImportPosProductRequest = (function() {
    
                    /**
                     * Properties of an ImportPosProductRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IImportPosProductRequest
                     * @property {number|null} [companyId] ImportPosProductRequest companyId
                     * @property {boolean|null} [preview] ImportPosProductRequest preview
                     * @property {Uint8Array|null} [csvData] ImportPosProductRequest csvData
                     * @property {palexy.streaming.v1.IPosProductList|null} [productList] ImportPosProductRequest productList
                     */
    
                    /**
                     * Constructs a new ImportPosProductRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ImportPosProductRequest.
                     * @implements IImportPosProductRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IImportPosProductRequest=} [properties] Properties to set
                     */
                    function ImportPosProductRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ImportPosProductRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @instance
                     */
                    ImportPosProductRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ImportPosProductRequest preview.
                     * @member {boolean} preview
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @instance
                     */
                    ImportPosProductRequest.prototype.preview = false;
    
                    /**
                     * ImportPosProductRequest csvData.
                     * @member {Uint8Array|null|undefined} csvData
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @instance
                     */
                    ImportPosProductRequest.prototype.csvData = null;
    
                    /**
                     * ImportPosProductRequest productList.
                     * @member {palexy.streaming.v1.IPosProductList|null|undefined} productList
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @instance
                     */
                    ImportPosProductRequest.prototype.productList = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * ImportPosProductRequest sourceData.
                     * @member {"csvData"|"productList"|undefined} sourceData
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @instance
                     */
                    Object.defineProperty(ImportPosProductRequest.prototype, "sourceData", {
                        get: $util.oneOfGetter($oneOfFields = ["csvData", "productList"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new ImportPosProductRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportPosProductRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ImportPosProductRequest} ImportPosProductRequest instance
                     */
                    ImportPosProductRequest.create = function create(properties) {
                        return new ImportPosProductRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ImportPosProductRequest message. Does not implicitly {@link palexy.streaming.v1.ImportPosProductRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportPosProductRequest} message ImportPosProductRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportPosProductRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.csvData != null && Object.hasOwnProperty.call(message, "csvData"))
                            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.csvData);
                        if (message.productList != null && Object.hasOwnProperty.call(message, "productList"))
                            $root.palexy.streaming.v1.PosProductList.encode(message.productList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.preview);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ImportPosProductRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ImportPosProductRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportPosProductRequest} message ImportPosProductRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportPosProductRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ImportPosProductRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ImportPosProductRequest} ImportPosProductRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportPosProductRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ImportPosProductRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 4:
                                message.preview = reader.bool();
                                break;
                            case 2:
                                message.csvData = reader.bytes();
                                break;
                            case 3:
                                message.productList = $root.palexy.streaming.v1.PosProductList.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ImportPosProductRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ImportPosProductRequest} ImportPosProductRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportPosProductRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ImportPosProductRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImportPosProductRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.preview != null && message.hasOwnProperty("preview"))
                            if (typeof message.preview !== "boolean")
                                return "preview: boolean expected";
                        if (message.csvData != null && message.hasOwnProperty("csvData")) {
                            properties.sourceData = 1;
                            if (!(message.csvData && typeof message.csvData.length === "number" || $util.isString(message.csvData)))
                                return "csvData: buffer expected";
                        }
                        if (message.productList != null && message.hasOwnProperty("productList")) {
                            if (properties.sourceData === 1)
                                return "sourceData: multiple values";
                            properties.sourceData = 1;
                            {
                                var error = $root.palexy.streaming.v1.PosProductList.verify(message.productList);
                                if (error)
                                    return "productList." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ImportPosProductRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ImportPosProductRequest} ImportPosProductRequest
                     */
                    ImportPosProductRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ImportPosProductRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ImportPosProductRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.preview != null)
                            message.preview = Boolean(object.preview);
                        if (object.csvData != null)
                            if (typeof object.csvData === "string")
                                $util.base64.decode(object.csvData, message.csvData = $util.newBuffer($util.base64.length(object.csvData)), 0);
                            else if (object.csvData.length)
                                message.csvData = object.csvData;
                        if (object.productList != null) {
                            if (typeof object.productList !== "object")
                                throw TypeError(".palexy.streaming.v1.ImportPosProductRequest.productList: object expected");
                            message.productList = $root.palexy.streaming.v1.PosProductList.fromObject(object.productList);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ImportPosProductRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @static
                     * @param {palexy.streaming.v1.ImportPosProductRequest} message ImportPosProductRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImportPosProductRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.preview = false;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.csvData != null && message.hasOwnProperty("csvData")) {
                            object.csvData = options.bytes === String ? $util.base64.encode(message.csvData, 0, message.csvData.length) : options.bytes === Array ? Array.prototype.slice.call(message.csvData) : message.csvData;
                            if (options.oneofs)
                                object.sourceData = "csvData";
                        }
                        if (message.productList != null && message.hasOwnProperty("productList")) {
                            object.productList = $root.palexy.streaming.v1.PosProductList.toObject(message.productList, options);
                            if (options.oneofs)
                                object.sourceData = "productList";
                        }
                        if (message.preview != null && message.hasOwnProperty("preview"))
                            object.preview = message.preview;
                        return object;
                    };
    
                    /**
                     * Converts this ImportPosProductRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ImportPosProductRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImportPosProductRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ImportPosProductRequest;
                })();
    
                v1.PosProductList = (function() {
    
                    /**
                     * Properties of a PosProductList.
                     * @memberof palexy.streaming.v1
                     * @interface IPosProductList
                     * @property {Array.<palexy.streaming.v1.IPosProduct>|null} [products] PosProductList products
                     */
    
                    /**
                     * Constructs a new PosProductList.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosProductList.
                     * @implements IPosProductList
                     * @constructor
                     * @param {palexy.streaming.v1.IPosProductList=} [properties] Properties to set
                     */
                    function PosProductList(properties) {
                        this.products = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosProductList products.
                     * @member {Array.<palexy.streaming.v1.IPosProduct>} products
                     * @memberof palexy.streaming.v1.PosProductList
                     * @instance
                     */
                    PosProductList.prototype.products = $util.emptyArray;
    
                    /**
                     * Creates a new PosProductList instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosProductList
                     * @static
                     * @param {palexy.streaming.v1.IPosProductList=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosProductList} PosProductList instance
                     */
                    PosProductList.create = function create(properties) {
                        return new PosProductList(properties);
                    };
    
                    /**
                     * Encodes the specified PosProductList message. Does not implicitly {@link palexy.streaming.v1.PosProductList.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosProductList
                     * @static
                     * @param {palexy.streaming.v1.IPosProductList} message PosProductList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosProductList.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.products != null && message.products.length)
                            for (var i = 0; i < message.products.length; ++i)
                                $root.palexy.streaming.v1.PosProduct.encode(message.products[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosProductList message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosProductList.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosProductList
                     * @static
                     * @param {palexy.streaming.v1.IPosProductList} message PosProductList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosProductList.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosProductList message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosProductList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosProductList} PosProductList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosProductList.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosProductList();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.products && message.products.length))
                                    message.products = [];
                                message.products.push($root.palexy.streaming.v1.PosProduct.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosProductList message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosProductList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosProductList} PosProductList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosProductList.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosProductList message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosProductList
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosProductList.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.products != null && message.hasOwnProperty("products")) {
                            if (!Array.isArray(message.products))
                                return "products: array expected";
                            for (var i = 0; i < message.products.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosProduct.verify(message.products[i]);
                                if (error)
                                    return "products." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PosProductList message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosProductList
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosProductList} PosProductList
                     */
                    PosProductList.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosProductList)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosProductList();
                        if (object.products) {
                            if (!Array.isArray(object.products))
                                throw TypeError(".palexy.streaming.v1.PosProductList.products: array expected");
                            message.products = [];
                            for (var i = 0; i < object.products.length; ++i) {
                                if (typeof object.products[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.PosProductList.products: object expected");
                                message.products[i] = $root.palexy.streaming.v1.PosProduct.fromObject(object.products[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosProductList message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosProductList
                     * @static
                     * @param {palexy.streaming.v1.PosProductList} message PosProductList
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosProductList.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.products = [];
                        if (message.products && message.products.length) {
                            object.products = [];
                            for (var j = 0; j < message.products.length; ++j)
                                object.products[j] = $root.palexy.streaming.v1.PosProduct.toObject(message.products[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this PosProductList to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosProductList
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosProductList.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosProductList;
                })();
    
                v1.ImportPosProductResponse = (function() {
    
                    /**
                     * Properties of an ImportPosProductResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IImportPosProductResponse
                     * @property {palexy.streaming.v1.IImportResult|null} [productImportResult] ImportPosProductResponse productImportResult
                     * @property {palexy.streaming.v1.IImportResult|null} [productMetadataImportResult] ImportPosProductResponse productMetadataImportResult
                     * @property {Array.<palexy.streaming.v1.IPosProduct>|null} [previewResult] ImportPosProductResponse previewResult
                     */
    
                    /**
                     * Constructs a new ImportPosProductResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ImportPosProductResponse.
                     * @implements IImportPosProductResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IImportPosProductResponse=} [properties] Properties to set
                     */
                    function ImportPosProductResponse(properties) {
                        this.previewResult = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ImportPosProductResponse productImportResult.
                     * @member {palexy.streaming.v1.IImportResult|null|undefined} productImportResult
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @instance
                     */
                    ImportPosProductResponse.prototype.productImportResult = null;
    
                    /**
                     * ImportPosProductResponse productMetadataImportResult.
                     * @member {palexy.streaming.v1.IImportResult|null|undefined} productMetadataImportResult
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @instance
                     */
                    ImportPosProductResponse.prototype.productMetadataImportResult = null;
    
                    /**
                     * ImportPosProductResponse previewResult.
                     * @member {Array.<palexy.streaming.v1.IPosProduct>} previewResult
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @instance
                     */
                    ImportPosProductResponse.prototype.previewResult = $util.emptyArray;
    
                    /**
                     * Creates a new ImportPosProductResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @static
                     * @param {palexy.streaming.v1.IImportPosProductResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ImportPosProductResponse} ImportPosProductResponse instance
                     */
                    ImportPosProductResponse.create = function create(properties) {
                        return new ImportPosProductResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ImportPosProductResponse message. Does not implicitly {@link palexy.streaming.v1.ImportPosProductResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @static
                     * @param {palexy.streaming.v1.IImportPosProductResponse} message ImportPosProductResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportPosProductResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.productImportResult != null && Object.hasOwnProperty.call(message, "productImportResult"))
                            $root.palexy.streaming.v1.ImportResult.encode(message.productImportResult, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.productMetadataImportResult != null && Object.hasOwnProperty.call(message, "productMetadataImportResult"))
                            $root.palexy.streaming.v1.ImportResult.encode(message.productMetadataImportResult, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.previewResult != null && message.previewResult.length)
                            for (var i = 0; i < message.previewResult.length; ++i)
                                $root.palexy.streaming.v1.PosProduct.encode(message.previewResult[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ImportPosProductResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ImportPosProductResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @static
                     * @param {palexy.streaming.v1.IImportPosProductResponse} message ImportPosProductResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportPosProductResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ImportPosProductResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ImportPosProductResponse} ImportPosProductResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportPosProductResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ImportPosProductResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.productImportResult = $root.palexy.streaming.v1.ImportResult.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.productMetadataImportResult = $root.palexy.streaming.v1.ImportResult.decode(reader, reader.uint32());
                                break;
                            case 3:
                                if (!(message.previewResult && message.previewResult.length))
                                    message.previewResult = [];
                                message.previewResult.push($root.palexy.streaming.v1.PosProduct.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ImportPosProductResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ImportPosProductResponse} ImportPosProductResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportPosProductResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ImportPosProductResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImportPosProductResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.productImportResult != null && message.hasOwnProperty("productImportResult")) {
                            var error = $root.palexy.streaming.v1.ImportResult.verify(message.productImportResult);
                            if (error)
                                return "productImportResult." + error;
                        }
                        if (message.productMetadataImportResult != null && message.hasOwnProperty("productMetadataImportResult")) {
                            var error = $root.palexy.streaming.v1.ImportResult.verify(message.productMetadataImportResult);
                            if (error)
                                return "productMetadataImportResult." + error;
                        }
                        if (message.previewResult != null && message.hasOwnProperty("previewResult")) {
                            if (!Array.isArray(message.previewResult))
                                return "previewResult: array expected";
                            for (var i = 0; i < message.previewResult.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosProduct.verify(message.previewResult[i]);
                                if (error)
                                    return "previewResult." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ImportPosProductResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ImportPosProductResponse} ImportPosProductResponse
                     */
                    ImportPosProductResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ImportPosProductResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ImportPosProductResponse();
                        if (object.productImportResult != null) {
                            if (typeof object.productImportResult !== "object")
                                throw TypeError(".palexy.streaming.v1.ImportPosProductResponse.productImportResult: object expected");
                            message.productImportResult = $root.palexy.streaming.v1.ImportResult.fromObject(object.productImportResult);
                        }
                        if (object.productMetadataImportResult != null) {
                            if (typeof object.productMetadataImportResult !== "object")
                                throw TypeError(".palexy.streaming.v1.ImportPosProductResponse.productMetadataImportResult: object expected");
                            message.productMetadataImportResult = $root.palexy.streaming.v1.ImportResult.fromObject(object.productMetadataImportResult);
                        }
                        if (object.previewResult) {
                            if (!Array.isArray(object.previewResult))
                                throw TypeError(".palexy.streaming.v1.ImportPosProductResponse.previewResult: array expected");
                            message.previewResult = [];
                            for (var i = 0; i < object.previewResult.length; ++i) {
                                if (typeof object.previewResult[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ImportPosProductResponse.previewResult: object expected");
                                message.previewResult[i] = $root.palexy.streaming.v1.PosProduct.fromObject(object.previewResult[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ImportPosProductResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @static
                     * @param {palexy.streaming.v1.ImportPosProductResponse} message ImportPosProductResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImportPosProductResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.previewResult = [];
                        if (options.defaults) {
                            object.productImportResult = null;
                            object.productMetadataImportResult = null;
                        }
                        if (message.productImportResult != null && message.hasOwnProperty("productImportResult"))
                            object.productImportResult = $root.palexy.streaming.v1.ImportResult.toObject(message.productImportResult, options);
                        if (message.productMetadataImportResult != null && message.hasOwnProperty("productMetadataImportResult"))
                            object.productMetadataImportResult = $root.palexy.streaming.v1.ImportResult.toObject(message.productMetadataImportResult, options);
                        if (message.previewResult && message.previewResult.length) {
                            object.previewResult = [];
                            for (var j = 0; j < message.previewResult.length; ++j)
                                object.previewResult[j] = $root.palexy.streaming.v1.PosProduct.toObject(message.previewResult[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ImportPosProductResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ImportPosProductResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImportPosProductResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ImportPosProductResponse;
                })();
    
                v1.ImportPosTransactionRequest = (function() {
    
                    /**
                     * Properties of an ImportPosTransactionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IImportPosTransactionRequest
                     * @property {number|null} [companyId] ImportPosTransactionRequest companyId
                     * @property {Array.<number>|null} [storeIds] ImportPosTransactionRequest storeIds
                     * @property {string|null} [startDate] ImportPosTransactionRequest startDate
                     * @property {string|null} [endDate] ImportPosTransactionRequest endDate
                     * @property {boolean|null} [createProductIfNotExists] ImportPosTransactionRequest createProductIfNotExists
                     * @property {boolean|null} [deleteTransactionIfExists] ImportPosTransactionRequest deleteTransactionIfExists
                     * @property {number|null} [accountId] ImportPosTransactionRequest accountId
                     * @property {boolean|null} [preview] ImportPosTransactionRequest preview
                     * @property {string|null} [startDateTime] ImportPosTransactionRequest startDateTime
                     * @property {string|null} [endDateTime] ImportPosTransactionRequest endDateTime
                     * @property {Uint8Array|null} [csvData] ImportPosTransactionRequest csvData
                     * @property {palexy.streaming.v1.IPosTransactionList|null} [transactionList] ImportPosTransactionRequest transactionList
                     */
    
                    /**
                     * Constructs a new ImportPosTransactionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ImportPosTransactionRequest.
                     * @implements IImportPosTransactionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IImportPosTransactionRequest=} [properties] Properties to set
                     */
                    function ImportPosTransactionRequest(properties) {
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ImportPosTransactionRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ImportPosTransactionRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * ImportPosTransactionRequest startDate.
                     * @member {string} startDate
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.startDate = "";
    
                    /**
                     * ImportPosTransactionRequest endDate.
                     * @member {string} endDate
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.endDate = "";
    
                    /**
                     * ImportPosTransactionRequest createProductIfNotExists.
                     * @member {boolean} createProductIfNotExists
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.createProductIfNotExists = false;
    
                    /**
                     * ImportPosTransactionRequest deleteTransactionIfExists.
                     * @member {boolean} deleteTransactionIfExists
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.deleteTransactionIfExists = false;
    
                    /**
                     * ImportPosTransactionRequest accountId.
                     * @member {number} accountId
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.accountId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ImportPosTransactionRequest preview.
                     * @member {boolean} preview
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.preview = false;
    
                    /**
                     * ImportPosTransactionRequest startDateTime.
                     * @member {string} startDateTime
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.startDateTime = "";
    
                    /**
                     * ImportPosTransactionRequest endDateTime.
                     * @member {string} endDateTime
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.endDateTime = "";
    
                    /**
                     * ImportPosTransactionRequest csvData.
                     * @member {Uint8Array|null|undefined} csvData
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.csvData = null;
    
                    /**
                     * ImportPosTransactionRequest transactionList.
                     * @member {palexy.streaming.v1.IPosTransactionList|null|undefined} transactionList
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    ImportPosTransactionRequest.prototype.transactionList = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * ImportPosTransactionRequest sourceData.
                     * @member {"csvData"|"transactionList"|undefined} sourceData
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     */
                    Object.defineProperty(ImportPosTransactionRequest.prototype, "sourceData", {
                        get: $util.oneOfGetter($oneOfFields = ["csvData", "transactionList"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new ImportPosTransactionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportPosTransactionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ImportPosTransactionRequest} ImportPosTransactionRequest instance
                     */
                    ImportPosTransactionRequest.create = function create(properties) {
                        return new ImportPosTransactionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ImportPosTransactionRequest message. Does not implicitly {@link palexy.streaming.v1.ImportPosTransactionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportPosTransactionRequest} message ImportPosTransactionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportPosTransactionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.startDate);
                        if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.endDate);
                        if (message.createProductIfNotExists != null && Object.hasOwnProperty.call(message, "createProductIfNotExists"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.createProductIfNotExists);
                        if (message.deleteTransactionIfExists != null && Object.hasOwnProperty.call(message, "deleteTransactionIfExists"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.deleteTransactionIfExists);
                        if (message.csvData != null && Object.hasOwnProperty.call(message, "csvData"))
                            writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.csvData);
                        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.accountId);
                        if (message.transactionList != null && Object.hasOwnProperty.call(message, "transactionList"))
                            $root.palexy.streaming.v1.PosTransactionList.encode(message.transactionList, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.startDateTime != null && Object.hasOwnProperty.call(message, "startDateTime"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.startDateTime);
                        if (message.endDateTime != null && Object.hasOwnProperty.call(message, "endDateTime"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.endDateTime);
                        if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.preview);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ImportPosTransactionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ImportPosTransactionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportPosTransactionRequest} message ImportPosTransactionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportPosTransactionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ImportPosTransactionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ImportPosTransactionRequest} ImportPosTransactionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportPosTransactionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ImportPosTransactionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 3:
                                message.startDate = reader.string();
                                break;
                            case 4:
                                message.endDate = reader.string();
                                break;
                            case 5:
                                message.createProductIfNotExists = reader.bool();
                                break;
                            case 6:
                                message.deleteTransactionIfExists = reader.bool();
                                break;
                            case 8:
                                message.accountId = reader.int64();
                                break;
                            case 12:
                                message.preview = reader.bool();
                                break;
                            case 10:
                                message.startDateTime = reader.string();
                                break;
                            case 11:
                                message.endDateTime = reader.string();
                                break;
                            case 7:
                                message.csvData = reader.bytes();
                                break;
                            case 9:
                                message.transactionList = $root.palexy.streaming.v1.PosTransactionList.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ImportPosTransactionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ImportPosTransactionRequest} ImportPosTransactionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportPosTransactionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ImportPosTransactionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImportPosTransactionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        if (message.createProductIfNotExists != null && message.hasOwnProperty("createProductIfNotExists"))
                            if (typeof message.createProductIfNotExists !== "boolean")
                                return "createProductIfNotExists: boolean expected";
                        if (message.deleteTransactionIfExists != null && message.hasOwnProperty("deleteTransactionIfExists"))
                            if (typeof message.deleteTransactionIfExists !== "boolean")
                                return "deleteTransactionIfExists: boolean expected";
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (!$util.isInteger(message.accountId) && !(message.accountId && $util.isInteger(message.accountId.low) && $util.isInteger(message.accountId.high)))
                                return "accountId: integer|Long expected";
                        if (message.preview != null && message.hasOwnProperty("preview"))
                            if (typeof message.preview !== "boolean")
                                return "preview: boolean expected";
                        if (message.startDateTime != null && message.hasOwnProperty("startDateTime"))
                            if (!$util.isString(message.startDateTime))
                                return "startDateTime: string expected";
                        if (message.endDateTime != null && message.hasOwnProperty("endDateTime"))
                            if (!$util.isString(message.endDateTime))
                                return "endDateTime: string expected";
                        if (message.csvData != null && message.hasOwnProperty("csvData")) {
                            properties.sourceData = 1;
                            if (!(message.csvData && typeof message.csvData.length === "number" || $util.isString(message.csvData)))
                                return "csvData: buffer expected";
                        }
                        if (message.transactionList != null && message.hasOwnProperty("transactionList")) {
                            if (properties.sourceData === 1)
                                return "sourceData: multiple values";
                            properties.sourceData = 1;
                            {
                                var error = $root.palexy.streaming.v1.PosTransactionList.verify(message.transactionList);
                                if (error)
                                    return "transactionList." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ImportPosTransactionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ImportPosTransactionRequest} ImportPosTransactionRequest
                     */
                    ImportPosTransactionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ImportPosTransactionRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ImportPosTransactionRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.streaming.v1.ImportPosTransactionRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        if (object.createProductIfNotExists != null)
                            message.createProductIfNotExists = Boolean(object.createProductIfNotExists);
                        if (object.deleteTransactionIfExists != null)
                            message.deleteTransactionIfExists = Boolean(object.deleteTransactionIfExists);
                        if (object.accountId != null)
                            if ($util.Long)
                                (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = false;
                            else if (typeof object.accountId === "string")
                                message.accountId = parseInt(object.accountId, 10);
                            else if (typeof object.accountId === "number")
                                message.accountId = object.accountId;
                            else if (typeof object.accountId === "object")
                                message.accountId = new $util.LongBits(object.accountId.low >>> 0, object.accountId.high >>> 0).toNumber();
                        if (object.preview != null)
                            message.preview = Boolean(object.preview);
                        if (object.startDateTime != null)
                            message.startDateTime = String(object.startDateTime);
                        if (object.endDateTime != null)
                            message.endDateTime = String(object.endDateTime);
                        if (object.csvData != null)
                            if (typeof object.csvData === "string")
                                $util.base64.decode(object.csvData, message.csvData = $util.newBuffer($util.base64.length(object.csvData)), 0);
                            else if (object.csvData.length)
                                message.csvData = object.csvData;
                        if (object.transactionList != null) {
                            if (typeof object.transactionList !== "object")
                                throw TypeError(".palexy.streaming.v1.ImportPosTransactionRequest.transactionList: object expected");
                            message.transactionList = $root.palexy.streaming.v1.PosTransactionList.fromObject(object.transactionList);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ImportPosTransactionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.ImportPosTransactionRequest} message ImportPosTransactionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImportPosTransactionRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeIds = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.startDate = "";
                            object.endDate = "";
                            object.createProductIfNotExists = false;
                            object.deleteTransactionIfExists = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.accountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.accountId = options.longs === String ? "0" : 0;
                            object.startDateTime = "";
                            object.endDateTime = "";
                            object.preview = false;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.createProductIfNotExists != null && message.hasOwnProperty("createProductIfNotExists"))
                            object.createProductIfNotExists = message.createProductIfNotExists;
                        if (message.deleteTransactionIfExists != null && message.hasOwnProperty("deleteTransactionIfExists"))
                            object.deleteTransactionIfExists = message.deleteTransactionIfExists;
                        if (message.csvData != null && message.hasOwnProperty("csvData")) {
                            object.csvData = options.bytes === String ? $util.base64.encode(message.csvData, 0, message.csvData.length) : options.bytes === Array ? Array.prototype.slice.call(message.csvData) : message.csvData;
                            if (options.oneofs)
                                object.sourceData = "csvData";
                        }
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (typeof message.accountId === "number")
                                object.accountId = options.longs === String ? String(message.accountId) : message.accountId;
                            else
                                object.accountId = options.longs === String ? $util.Long.prototype.toString.call(message.accountId) : options.longs === Number ? new $util.LongBits(message.accountId.low >>> 0, message.accountId.high >>> 0).toNumber() : message.accountId;
                        if (message.transactionList != null && message.hasOwnProperty("transactionList")) {
                            object.transactionList = $root.palexy.streaming.v1.PosTransactionList.toObject(message.transactionList, options);
                            if (options.oneofs)
                                object.sourceData = "transactionList";
                        }
                        if (message.startDateTime != null && message.hasOwnProperty("startDateTime"))
                            object.startDateTime = message.startDateTime;
                        if (message.endDateTime != null && message.hasOwnProperty("endDateTime"))
                            object.endDateTime = message.endDateTime;
                        if (message.preview != null && message.hasOwnProperty("preview"))
                            object.preview = message.preview;
                        return object;
                    };
    
                    /**
                     * Converts this ImportPosTransactionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ImportPosTransactionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImportPosTransactionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ImportPosTransactionRequest;
                })();
    
                v1.PosTransactionList = (function() {
    
                    /**
                     * Properties of a PosTransactionList.
                     * @memberof palexy.streaming.v1
                     * @interface IPosTransactionList
                     * @property {Array.<palexy.streaming.v1.IPosTransaction>|null} [transactions] PosTransactionList transactions
                     */
    
                    /**
                     * Constructs a new PosTransactionList.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosTransactionList.
                     * @implements IPosTransactionList
                     * @constructor
                     * @param {palexy.streaming.v1.IPosTransactionList=} [properties] Properties to set
                     */
                    function PosTransactionList(properties) {
                        this.transactions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosTransactionList transactions.
                     * @member {Array.<palexy.streaming.v1.IPosTransaction>} transactions
                     * @memberof palexy.streaming.v1.PosTransactionList
                     * @instance
                     */
                    PosTransactionList.prototype.transactions = $util.emptyArray;
    
                    /**
                     * Creates a new PosTransactionList instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosTransactionList
                     * @static
                     * @param {palexy.streaming.v1.IPosTransactionList=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosTransactionList} PosTransactionList instance
                     */
                    PosTransactionList.create = function create(properties) {
                        return new PosTransactionList(properties);
                    };
    
                    /**
                     * Encodes the specified PosTransactionList message. Does not implicitly {@link palexy.streaming.v1.PosTransactionList.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosTransactionList
                     * @static
                     * @param {palexy.streaming.v1.IPosTransactionList} message PosTransactionList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosTransactionList.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.transactions != null && message.transactions.length)
                            for (var i = 0; i < message.transactions.length; ++i)
                                $root.palexy.streaming.v1.PosTransaction.encode(message.transactions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosTransactionList message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosTransactionList.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosTransactionList
                     * @static
                     * @param {palexy.streaming.v1.IPosTransactionList} message PosTransactionList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosTransactionList.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosTransactionList message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosTransactionList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosTransactionList} PosTransactionList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosTransactionList.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosTransactionList();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.transactions && message.transactions.length))
                                    message.transactions = [];
                                message.transactions.push($root.palexy.streaming.v1.PosTransaction.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosTransactionList message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosTransactionList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosTransactionList} PosTransactionList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosTransactionList.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosTransactionList message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosTransactionList
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosTransactionList.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.transactions != null && message.hasOwnProperty("transactions")) {
                            if (!Array.isArray(message.transactions))
                                return "transactions: array expected";
                            for (var i = 0; i < message.transactions.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosTransaction.verify(message.transactions[i]);
                                if (error)
                                    return "transactions." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PosTransactionList message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosTransactionList
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosTransactionList} PosTransactionList
                     */
                    PosTransactionList.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosTransactionList)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosTransactionList();
                        if (object.transactions) {
                            if (!Array.isArray(object.transactions))
                                throw TypeError(".palexy.streaming.v1.PosTransactionList.transactions: array expected");
                            message.transactions = [];
                            for (var i = 0; i < object.transactions.length; ++i) {
                                if (typeof object.transactions[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.PosTransactionList.transactions: object expected");
                                message.transactions[i] = $root.palexy.streaming.v1.PosTransaction.fromObject(object.transactions[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosTransactionList message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosTransactionList
                     * @static
                     * @param {palexy.streaming.v1.PosTransactionList} message PosTransactionList
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosTransactionList.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.transactions = [];
                        if (message.transactions && message.transactions.length) {
                            object.transactions = [];
                            for (var j = 0; j < message.transactions.length; ++j)
                                object.transactions[j] = $root.palexy.streaming.v1.PosTransaction.toObject(message.transactions[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this PosTransactionList to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosTransactionList
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosTransactionList.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosTransactionList;
                })();
    
                v1.ImportPosTransactionResponse = (function() {
    
                    /**
                     * Properties of an ImportPosTransactionResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IImportPosTransactionResponse
                     * @property {palexy.streaming.v1.IImportPosProductResponse|null} [importProductResponse] ImportPosTransactionResponse importProductResponse
                     * @property {palexy.streaming.v1.IImportResult|null} [transactionImportResult] ImportPosTransactionResponse transactionImportResult
                     * @property {palexy.streaming.v1.IImportResult|null} [transactionProductImportResult] ImportPosTransactionResponse transactionProductImportResult
                     * @property {Array.<palexy.streaming.v1.IPosTransaction>|null} [previewResult] ImportPosTransactionResponse previewResult
                     * @property {palexy.streaming.v1.IImportResult|null} [posStaffImportResult] ImportPosTransactionResponse posStaffImportResult
                     */
    
                    /**
                     * Constructs a new ImportPosTransactionResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ImportPosTransactionResponse.
                     * @implements IImportPosTransactionResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IImportPosTransactionResponse=} [properties] Properties to set
                     */
                    function ImportPosTransactionResponse(properties) {
                        this.previewResult = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ImportPosTransactionResponse importProductResponse.
                     * @member {palexy.streaming.v1.IImportPosProductResponse|null|undefined} importProductResponse
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @instance
                     */
                    ImportPosTransactionResponse.prototype.importProductResponse = null;
    
                    /**
                     * ImportPosTransactionResponse transactionImportResult.
                     * @member {palexy.streaming.v1.IImportResult|null|undefined} transactionImportResult
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @instance
                     */
                    ImportPosTransactionResponse.prototype.transactionImportResult = null;
    
                    /**
                     * ImportPosTransactionResponse transactionProductImportResult.
                     * @member {palexy.streaming.v1.IImportResult|null|undefined} transactionProductImportResult
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @instance
                     */
                    ImportPosTransactionResponse.prototype.transactionProductImportResult = null;
    
                    /**
                     * ImportPosTransactionResponse previewResult.
                     * @member {Array.<palexy.streaming.v1.IPosTransaction>} previewResult
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @instance
                     */
                    ImportPosTransactionResponse.prototype.previewResult = $util.emptyArray;
    
                    /**
                     * ImportPosTransactionResponse posStaffImportResult.
                     * @member {palexy.streaming.v1.IImportResult|null|undefined} posStaffImportResult
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @instance
                     */
                    ImportPosTransactionResponse.prototype.posStaffImportResult = null;
    
                    /**
                     * Creates a new ImportPosTransactionResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.IImportPosTransactionResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ImportPosTransactionResponse} ImportPosTransactionResponse instance
                     */
                    ImportPosTransactionResponse.create = function create(properties) {
                        return new ImportPosTransactionResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ImportPosTransactionResponse message. Does not implicitly {@link palexy.streaming.v1.ImportPosTransactionResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.IImportPosTransactionResponse} message ImportPosTransactionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportPosTransactionResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.importProductResponse != null && Object.hasOwnProperty.call(message, "importProductResponse"))
                            $root.palexy.streaming.v1.ImportPosProductResponse.encode(message.importProductResponse, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.transactionImportResult != null && Object.hasOwnProperty.call(message, "transactionImportResult"))
                            $root.palexy.streaming.v1.ImportResult.encode(message.transactionImportResult, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.transactionProductImportResult != null && Object.hasOwnProperty.call(message, "transactionProductImportResult"))
                            $root.palexy.streaming.v1.ImportResult.encode(message.transactionProductImportResult, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.previewResult != null && message.previewResult.length)
                            for (var i = 0; i < message.previewResult.length; ++i)
                                $root.palexy.streaming.v1.PosTransaction.encode(message.previewResult[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.posStaffImportResult != null && Object.hasOwnProperty.call(message, "posStaffImportResult"))
                            $root.palexy.streaming.v1.ImportResult.encode(message.posStaffImportResult, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ImportPosTransactionResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ImportPosTransactionResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.IImportPosTransactionResponse} message ImportPosTransactionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportPosTransactionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ImportPosTransactionResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ImportPosTransactionResponse} ImportPosTransactionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportPosTransactionResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ImportPosTransactionResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.importProductResponse = $root.palexy.streaming.v1.ImportPosProductResponse.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.transactionImportResult = $root.palexy.streaming.v1.ImportResult.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.transactionProductImportResult = $root.palexy.streaming.v1.ImportResult.decode(reader, reader.uint32());
                                break;
                            case 4:
                                if (!(message.previewResult && message.previewResult.length))
                                    message.previewResult = [];
                                message.previewResult.push($root.palexy.streaming.v1.PosTransaction.decode(reader, reader.uint32()));
                                break;
                            case 5:
                                message.posStaffImportResult = $root.palexy.streaming.v1.ImportResult.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ImportPosTransactionResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ImportPosTransactionResponse} ImportPosTransactionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportPosTransactionResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ImportPosTransactionResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImportPosTransactionResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.importProductResponse != null && message.hasOwnProperty("importProductResponse")) {
                            var error = $root.palexy.streaming.v1.ImportPosProductResponse.verify(message.importProductResponse);
                            if (error)
                                return "importProductResponse." + error;
                        }
                        if (message.transactionImportResult != null && message.hasOwnProperty("transactionImportResult")) {
                            var error = $root.palexy.streaming.v1.ImportResult.verify(message.transactionImportResult);
                            if (error)
                                return "transactionImportResult." + error;
                        }
                        if (message.transactionProductImportResult != null && message.hasOwnProperty("transactionProductImportResult")) {
                            var error = $root.palexy.streaming.v1.ImportResult.verify(message.transactionProductImportResult);
                            if (error)
                                return "transactionProductImportResult." + error;
                        }
                        if (message.previewResult != null && message.hasOwnProperty("previewResult")) {
                            if (!Array.isArray(message.previewResult))
                                return "previewResult: array expected";
                            for (var i = 0; i < message.previewResult.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosTransaction.verify(message.previewResult[i]);
                                if (error)
                                    return "previewResult." + error;
                            }
                        }
                        if (message.posStaffImportResult != null && message.hasOwnProperty("posStaffImportResult")) {
                            var error = $root.palexy.streaming.v1.ImportResult.verify(message.posStaffImportResult);
                            if (error)
                                return "posStaffImportResult." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ImportPosTransactionResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ImportPosTransactionResponse} ImportPosTransactionResponse
                     */
                    ImportPosTransactionResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ImportPosTransactionResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ImportPosTransactionResponse();
                        if (object.importProductResponse != null) {
                            if (typeof object.importProductResponse !== "object")
                                throw TypeError(".palexy.streaming.v1.ImportPosTransactionResponse.importProductResponse: object expected");
                            message.importProductResponse = $root.palexy.streaming.v1.ImportPosProductResponse.fromObject(object.importProductResponse);
                        }
                        if (object.transactionImportResult != null) {
                            if (typeof object.transactionImportResult !== "object")
                                throw TypeError(".palexy.streaming.v1.ImportPosTransactionResponse.transactionImportResult: object expected");
                            message.transactionImportResult = $root.palexy.streaming.v1.ImportResult.fromObject(object.transactionImportResult);
                        }
                        if (object.transactionProductImportResult != null) {
                            if (typeof object.transactionProductImportResult !== "object")
                                throw TypeError(".palexy.streaming.v1.ImportPosTransactionResponse.transactionProductImportResult: object expected");
                            message.transactionProductImportResult = $root.palexy.streaming.v1.ImportResult.fromObject(object.transactionProductImportResult);
                        }
                        if (object.previewResult) {
                            if (!Array.isArray(object.previewResult))
                                throw TypeError(".palexy.streaming.v1.ImportPosTransactionResponse.previewResult: array expected");
                            message.previewResult = [];
                            for (var i = 0; i < object.previewResult.length; ++i) {
                                if (typeof object.previewResult[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ImportPosTransactionResponse.previewResult: object expected");
                                message.previewResult[i] = $root.palexy.streaming.v1.PosTransaction.fromObject(object.previewResult[i]);
                            }
                        }
                        if (object.posStaffImportResult != null) {
                            if (typeof object.posStaffImportResult !== "object")
                                throw TypeError(".palexy.streaming.v1.ImportPosTransactionResponse.posStaffImportResult: object expected");
                            message.posStaffImportResult = $root.palexy.streaming.v1.ImportResult.fromObject(object.posStaffImportResult);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ImportPosTransactionResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.ImportPosTransactionResponse} message ImportPosTransactionResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImportPosTransactionResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.previewResult = [];
                        if (options.defaults) {
                            object.importProductResponse = null;
                            object.transactionImportResult = null;
                            object.transactionProductImportResult = null;
                            object.posStaffImportResult = null;
                        }
                        if (message.importProductResponse != null && message.hasOwnProperty("importProductResponse"))
                            object.importProductResponse = $root.palexy.streaming.v1.ImportPosProductResponse.toObject(message.importProductResponse, options);
                        if (message.transactionImportResult != null && message.hasOwnProperty("transactionImportResult"))
                            object.transactionImportResult = $root.palexy.streaming.v1.ImportResult.toObject(message.transactionImportResult, options);
                        if (message.transactionProductImportResult != null && message.hasOwnProperty("transactionProductImportResult"))
                            object.transactionProductImportResult = $root.palexy.streaming.v1.ImportResult.toObject(message.transactionProductImportResult, options);
                        if (message.previewResult && message.previewResult.length) {
                            object.previewResult = [];
                            for (var j = 0; j < message.previewResult.length; ++j)
                                object.previewResult[j] = $root.palexy.streaming.v1.PosTransaction.toObject(message.previewResult[j], options);
                        }
                        if (message.posStaffImportResult != null && message.hasOwnProperty("posStaffImportResult"))
                            object.posStaffImportResult = $root.palexy.streaming.v1.ImportResult.toObject(message.posStaffImportResult, options);
                        return object;
                    };
    
                    /**
                     * Converts this ImportPosTransactionResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ImportPosTransactionResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImportPosTransactionResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ImportPosTransactionResponse;
                })();
    
                v1.DeletePosMetadataRequest = (function() {
    
                    /**
                     * Properties of a DeletePosMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeletePosMetadataRequest
                     * @property {number|null} [metadataId] DeletePosMetadataRequest metadataId
                     */
    
                    /**
                     * Constructs a new DeletePosMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeletePosMetadataRequest.
                     * @implements IDeletePosMetadataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeletePosMetadataRequest=} [properties] Properties to set
                     */
                    function DeletePosMetadataRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeletePosMetadataRequest metadataId.
                     * @member {number} metadataId
                     * @memberof palexy.streaming.v1.DeletePosMetadataRequest
                     * @instance
                     */
                    DeletePosMetadataRequest.prototype.metadataId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeletePosMetadataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeletePosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeletePosMetadataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeletePosMetadataRequest} DeletePosMetadataRequest instance
                     */
                    DeletePosMetadataRequest.create = function create(properties) {
                        return new DeletePosMetadataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeletePosMetadataRequest message. Does not implicitly {@link palexy.streaming.v1.DeletePosMetadataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeletePosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeletePosMetadataRequest} message DeletePosMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeletePosMetadataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.metadataId != null && Object.hasOwnProperty.call(message, "metadataId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.metadataId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeletePosMetadataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeletePosMetadataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeletePosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeletePosMetadataRequest} message DeletePosMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeletePosMetadataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeletePosMetadataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeletePosMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeletePosMetadataRequest} DeletePosMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeletePosMetadataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeletePosMetadataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.metadataId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeletePosMetadataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeletePosMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeletePosMetadataRequest} DeletePosMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeletePosMetadataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeletePosMetadataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeletePosMetadataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeletePosMetadataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.metadataId != null && message.hasOwnProperty("metadataId"))
                            if (!$util.isInteger(message.metadataId) && !(message.metadataId && $util.isInteger(message.metadataId.low) && $util.isInteger(message.metadataId.high)))
                                return "metadataId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeletePosMetadataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeletePosMetadataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeletePosMetadataRequest} DeletePosMetadataRequest
                     */
                    DeletePosMetadataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeletePosMetadataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeletePosMetadataRequest();
                        if (object.metadataId != null)
                            if ($util.Long)
                                (message.metadataId = $util.Long.fromValue(object.metadataId)).unsigned = false;
                            else if (typeof object.metadataId === "string")
                                message.metadataId = parseInt(object.metadataId, 10);
                            else if (typeof object.metadataId === "number")
                                message.metadataId = object.metadataId;
                            else if (typeof object.metadataId === "object")
                                message.metadataId = new $util.LongBits(object.metadataId.low >>> 0, object.metadataId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeletePosMetadataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeletePosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.DeletePosMetadataRequest} message DeletePosMetadataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeletePosMetadataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataId = options.longs === String ? "0" : 0;
                        if (message.metadataId != null && message.hasOwnProperty("metadataId"))
                            if (typeof message.metadataId === "number")
                                object.metadataId = options.longs === String ? String(message.metadataId) : message.metadataId;
                            else
                                object.metadataId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataId) : options.longs === Number ? new $util.LongBits(message.metadataId.low >>> 0, message.metadataId.high >>> 0).toNumber() : message.metadataId;
                        return object;
                    };
    
                    /**
                     * Converts this DeletePosMetadataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeletePosMetadataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeletePosMetadataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeletePosMetadataRequest;
                })();
    
                v1.GetPosMetadataRequest = (function() {
    
                    /**
                     * Properties of a GetPosMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetPosMetadataRequest
                     * @property {number|null} [metadataId] GetPosMetadataRequest metadataId
                     */
    
                    /**
                     * Constructs a new GetPosMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetPosMetadataRequest.
                     * @implements IGetPosMetadataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetPosMetadataRequest=} [properties] Properties to set
                     */
                    function GetPosMetadataRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetPosMetadataRequest metadataId.
                     * @member {number} metadataId
                     * @memberof palexy.streaming.v1.GetPosMetadataRequest
                     * @instance
                     */
                    GetPosMetadataRequest.prototype.metadataId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetPosMetadataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetPosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetPosMetadataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetPosMetadataRequest} GetPosMetadataRequest instance
                     */
                    GetPosMetadataRequest.create = function create(properties) {
                        return new GetPosMetadataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetPosMetadataRequest message. Does not implicitly {@link palexy.streaming.v1.GetPosMetadataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetPosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetPosMetadataRequest} message GetPosMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetPosMetadataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.metadataId != null && Object.hasOwnProperty.call(message, "metadataId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.metadataId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetPosMetadataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetPosMetadataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetPosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetPosMetadataRequest} message GetPosMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetPosMetadataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetPosMetadataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetPosMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetPosMetadataRequest} GetPosMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetPosMetadataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetPosMetadataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.metadataId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetPosMetadataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetPosMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetPosMetadataRequest} GetPosMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetPosMetadataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetPosMetadataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetPosMetadataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetPosMetadataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.metadataId != null && message.hasOwnProperty("metadataId"))
                            if (!$util.isInteger(message.metadataId) && !(message.metadataId && $util.isInteger(message.metadataId.low) && $util.isInteger(message.metadataId.high)))
                                return "metadataId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetPosMetadataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetPosMetadataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetPosMetadataRequest} GetPosMetadataRequest
                     */
                    GetPosMetadataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetPosMetadataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetPosMetadataRequest();
                        if (object.metadataId != null)
                            if ($util.Long)
                                (message.metadataId = $util.Long.fromValue(object.metadataId)).unsigned = false;
                            else if (typeof object.metadataId === "string")
                                message.metadataId = parseInt(object.metadataId, 10);
                            else if (typeof object.metadataId === "number")
                                message.metadataId = object.metadataId;
                            else if (typeof object.metadataId === "object")
                                message.metadataId = new $util.LongBits(object.metadataId.low >>> 0, object.metadataId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetPosMetadataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetPosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.GetPosMetadataRequest} message GetPosMetadataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetPosMetadataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataId = options.longs === String ? "0" : 0;
                        if (message.metadataId != null && message.hasOwnProperty("metadataId"))
                            if (typeof message.metadataId === "number")
                                object.metadataId = options.longs === String ? String(message.metadataId) : message.metadataId;
                            else
                                object.metadataId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataId) : options.longs === Number ? new $util.LongBits(message.metadataId.low >>> 0, message.metadataId.high >>> 0).toNumber() : message.metadataId;
                        return object;
                    };
    
                    /**
                     * Converts this GetPosMetadataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetPosMetadataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetPosMetadataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetPosMetadataRequest;
                })();
    
                v1.ListPosMetadataRequest = (function() {
    
                    /**
                     * Properties of a ListPosMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListPosMetadataRequest
                     * @property {number|null} [companyId] ListPosMetadataRequest companyId
                     * @property {string|null} [name] ListPosMetadataRequest name
                     * @property {number|null} [pageSize] ListPosMetadataRequest pageSize
                     * @property {string|null} [pageToken] ListPosMetadataRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListPosMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListPosMetadataRequest.
                     * @implements IListPosMetadataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListPosMetadataRequest=} [properties] Properties to set
                     */
                    function ListPosMetadataRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListPosMetadataRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @instance
                     */
                    ListPosMetadataRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListPosMetadataRequest name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @instance
                     */
                    ListPosMetadataRequest.prototype.name = "";
    
                    /**
                     * ListPosMetadataRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @instance
                     */
                    ListPosMetadataRequest.prototype.pageSize = 0;
    
                    /**
                     * ListPosMetadataRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @instance
                     */
                    ListPosMetadataRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListPosMetadataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListPosMetadataRequest} ListPosMetadataRequest instance
                     */
                    ListPosMetadataRequest.create = function create(properties) {
                        return new ListPosMetadataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListPosMetadataRequest message. Does not implicitly {@link palexy.streaming.v1.ListPosMetadataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataRequest} message ListPosMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosMetadataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListPosMetadataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosMetadataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataRequest} message ListPosMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosMetadataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListPosMetadataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListPosMetadataRequest} ListPosMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosMetadataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosMetadataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.pageSize = reader.int32();
                                break;
                            case 4:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListPosMetadataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListPosMetadataRequest} ListPosMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosMetadataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListPosMetadataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListPosMetadataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListPosMetadataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListPosMetadataRequest} ListPosMetadataRequest
                     */
                    ListPosMetadataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListPosMetadataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListPosMetadataRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListPosMetadataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.ListPosMetadataRequest} message ListPosMetadataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListPosMetadataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListPosMetadataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListPosMetadataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListPosMetadataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListPosMetadataRequest;
                })();
    
                v1.ListPosMetadataResponse = (function() {
    
                    /**
                     * Properties of a ListPosMetadataResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListPosMetadataResponse
                     * @property {Array.<palexy.streaming.v1.IPosMetadata>|null} [posMetadata] ListPosMetadataResponse posMetadata
                     * @property {number|null} [totalElements] ListPosMetadataResponse totalElements
                     * @property {string|null} [nextPageToken] ListPosMetadataResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListPosMetadataResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListPosMetadataResponse.
                     * @implements IListPosMetadataResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListPosMetadataResponse=} [properties] Properties to set
                     */
                    function ListPosMetadataResponse(properties) {
                        this.posMetadata = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListPosMetadataResponse posMetadata.
                     * @member {Array.<palexy.streaming.v1.IPosMetadata>} posMetadata
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @instance
                     */
                    ListPosMetadataResponse.prototype.posMetadata = $util.emptyArray;
    
                    /**
                     * ListPosMetadataResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @instance
                     */
                    ListPosMetadataResponse.prototype.totalElements = 0;
    
                    /**
                     * ListPosMetadataResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @instance
                     */
                    ListPosMetadataResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListPosMetadataResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListPosMetadataResponse} ListPosMetadataResponse instance
                     */
                    ListPosMetadataResponse.create = function create(properties) {
                        return new ListPosMetadataResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListPosMetadataResponse message. Does not implicitly {@link palexy.streaming.v1.ListPosMetadataResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataResponse} message ListPosMetadataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosMetadataResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.posMetadata != null && message.posMetadata.length)
                            for (var i = 0; i < message.posMetadata.length; ++i)
                                $root.palexy.streaming.v1.PosMetadata.encode(message.posMetadata[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListPosMetadataResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosMetadataResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataResponse} message ListPosMetadataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosMetadataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListPosMetadataResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListPosMetadataResponse} ListPosMetadataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosMetadataResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosMetadataResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.posMetadata && message.posMetadata.length))
                                    message.posMetadata = [];
                                message.posMetadata.push($root.palexy.streaming.v1.PosMetadata.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListPosMetadataResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListPosMetadataResponse} ListPosMetadataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosMetadataResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListPosMetadataResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListPosMetadataResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.posMetadata != null && message.hasOwnProperty("posMetadata")) {
                            if (!Array.isArray(message.posMetadata))
                                return "posMetadata: array expected";
                            for (var i = 0; i < message.posMetadata.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosMetadata.verify(message.posMetadata[i]);
                                if (error)
                                    return "posMetadata." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListPosMetadataResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListPosMetadataResponse} ListPosMetadataResponse
                     */
                    ListPosMetadataResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListPosMetadataResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListPosMetadataResponse();
                        if (object.posMetadata) {
                            if (!Array.isArray(object.posMetadata))
                                throw TypeError(".palexy.streaming.v1.ListPosMetadataResponse.posMetadata: array expected");
                            message.posMetadata = [];
                            for (var i = 0; i < object.posMetadata.length; ++i) {
                                if (typeof object.posMetadata[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListPosMetadataResponse.posMetadata: object expected");
                                message.posMetadata[i] = $root.palexy.streaming.v1.PosMetadata.fromObject(object.posMetadata[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListPosMetadataResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.ListPosMetadataResponse} message ListPosMetadataResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListPosMetadataResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.posMetadata = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.posMetadata && message.posMetadata.length) {
                            object.posMetadata = [];
                            for (var j = 0; j < message.posMetadata.length; ++j)
                                object.posMetadata[j] = $root.palexy.streaming.v1.PosMetadata.toObject(message.posMetadata[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListPosMetadataResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListPosMetadataResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListPosMetadataResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListPosMetadataResponse;
                })();
    
                v1.ListPosMetadataValueRequest = (function() {
    
                    /**
                     * Properties of a ListPosMetadataValueRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListPosMetadataValueRequest
                     * @property {number|null} [metadataId] ListPosMetadataValueRequest metadataId
                     * @property {number|null} [pageSize] ListPosMetadataValueRequest pageSize
                     * @property {string|null} [pageToken] ListPosMetadataValueRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListPosMetadataValueRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListPosMetadataValueRequest.
                     * @implements IListPosMetadataValueRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListPosMetadataValueRequest=} [properties] Properties to set
                     */
                    function ListPosMetadataValueRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListPosMetadataValueRequest metadataId.
                     * @member {number} metadataId
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @instance
                     */
                    ListPosMetadataValueRequest.prototype.metadataId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListPosMetadataValueRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @instance
                     */
                    ListPosMetadataValueRequest.prototype.pageSize = 0;
    
                    /**
                     * ListPosMetadataValueRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @instance
                     */
                    ListPosMetadataValueRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListPosMetadataValueRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataValueRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListPosMetadataValueRequest} ListPosMetadataValueRequest instance
                     */
                    ListPosMetadataValueRequest.create = function create(properties) {
                        return new ListPosMetadataValueRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListPosMetadataValueRequest message. Does not implicitly {@link palexy.streaming.v1.ListPosMetadataValueRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataValueRequest} message ListPosMetadataValueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosMetadataValueRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.metadataId != null && Object.hasOwnProperty.call(message, "metadataId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.metadataId);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListPosMetadataValueRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosMetadataValueRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataValueRequest} message ListPosMetadataValueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosMetadataValueRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListPosMetadataValueRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListPosMetadataValueRequest} ListPosMetadataValueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosMetadataValueRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosMetadataValueRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.metadataId = reader.int64();
                                break;
                            case 2:
                                message.pageSize = reader.int32();
                                break;
                            case 3:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListPosMetadataValueRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListPosMetadataValueRequest} ListPosMetadataValueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosMetadataValueRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListPosMetadataValueRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListPosMetadataValueRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.metadataId != null && message.hasOwnProperty("metadataId"))
                            if (!$util.isInteger(message.metadataId) && !(message.metadataId && $util.isInteger(message.metadataId.low) && $util.isInteger(message.metadataId.high)))
                                return "metadataId: integer|Long expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListPosMetadataValueRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListPosMetadataValueRequest} ListPosMetadataValueRequest
                     */
                    ListPosMetadataValueRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListPosMetadataValueRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListPosMetadataValueRequest();
                        if (object.metadataId != null)
                            if ($util.Long)
                                (message.metadataId = $util.Long.fromValue(object.metadataId)).unsigned = false;
                            else if (typeof object.metadataId === "string")
                                message.metadataId = parseInt(object.metadataId, 10);
                            else if (typeof object.metadataId === "number")
                                message.metadataId = object.metadataId;
                            else if (typeof object.metadataId === "object")
                                message.metadataId = new $util.LongBits(object.metadataId.low >>> 0, object.metadataId.high >>> 0).toNumber();
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListPosMetadataValueRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @static
                     * @param {palexy.streaming.v1.ListPosMetadataValueRequest} message ListPosMetadataValueRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListPosMetadataValueRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataId = options.longs === String ? "0" : 0;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.metadataId != null && message.hasOwnProperty("metadataId"))
                            if (typeof message.metadataId === "number")
                                object.metadataId = options.longs === String ? String(message.metadataId) : message.metadataId;
                            else
                                object.metadataId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataId) : options.longs === Number ? new $util.LongBits(message.metadataId.low >>> 0, message.metadataId.high >>> 0).toNumber() : message.metadataId;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListPosMetadataValueRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListPosMetadataValueRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListPosMetadataValueRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListPosMetadataValueRequest;
                })();
    
                v1.ListPosMetadataValueResponse = (function() {
    
                    /**
                     * Properties of a ListPosMetadataValueResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListPosMetadataValueResponse
                     * @property {Array.<string>|null} [values] ListPosMetadataValueResponse values
                     * @property {number|null} [totalElements] ListPosMetadataValueResponse totalElements
                     * @property {string|null} [nextPageToken] ListPosMetadataValueResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListPosMetadataValueResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListPosMetadataValueResponse.
                     * @implements IListPosMetadataValueResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListPosMetadataValueResponse=} [properties] Properties to set
                     */
                    function ListPosMetadataValueResponse(properties) {
                        this.values = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListPosMetadataValueResponse values.
                     * @member {Array.<string>} values
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @instance
                     */
                    ListPosMetadataValueResponse.prototype.values = $util.emptyArray;
    
                    /**
                     * ListPosMetadataValueResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @instance
                     */
                    ListPosMetadataValueResponse.prototype.totalElements = 0;
    
                    /**
                     * ListPosMetadataValueResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @instance
                     */
                    ListPosMetadataValueResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListPosMetadataValueResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataValueResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListPosMetadataValueResponse} ListPosMetadataValueResponse instance
                     */
                    ListPosMetadataValueResponse.create = function create(properties) {
                        return new ListPosMetadataValueResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListPosMetadataValueResponse message. Does not implicitly {@link palexy.streaming.v1.ListPosMetadataValueResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataValueResponse} message ListPosMetadataValueResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosMetadataValueResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.values != null && message.values.length)
                            for (var i = 0; i < message.values.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.values[i]);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListPosMetadataValueResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosMetadataValueResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosMetadataValueResponse} message ListPosMetadataValueResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosMetadataValueResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListPosMetadataValueResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListPosMetadataValueResponse} ListPosMetadataValueResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosMetadataValueResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosMetadataValueResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.values && message.values.length))
                                    message.values = [];
                                message.values.push(reader.string());
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListPosMetadataValueResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListPosMetadataValueResponse} ListPosMetadataValueResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosMetadataValueResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListPosMetadataValueResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListPosMetadataValueResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.values != null && message.hasOwnProperty("values")) {
                            if (!Array.isArray(message.values))
                                return "values: array expected";
                            for (var i = 0; i < message.values.length; ++i)
                                if (!$util.isString(message.values[i]))
                                    return "values: string[] expected";
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListPosMetadataValueResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListPosMetadataValueResponse} ListPosMetadataValueResponse
                     */
                    ListPosMetadataValueResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListPosMetadataValueResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListPosMetadataValueResponse();
                        if (object.values) {
                            if (!Array.isArray(object.values))
                                throw TypeError(".palexy.streaming.v1.ListPosMetadataValueResponse.values: array expected");
                            message.values = [];
                            for (var i = 0; i < object.values.length; ++i)
                                message.values[i] = String(object.values[i]);
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListPosMetadataValueResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @static
                     * @param {palexy.streaming.v1.ListPosMetadataValueResponse} message ListPosMetadataValueResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListPosMetadataValueResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.values = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.values && message.values.length) {
                            object.values = [];
                            for (var j = 0; j < message.values.length; ++j)
                                object.values[j] = message.values[j];
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListPosMetadataValueResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListPosMetadataValueResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListPosMetadataValueResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListPosMetadataValueResponse;
                })();
    
                v1.PosProduct = (function() {
    
                    /**
                     * Properties of a PosProduct.
                     * @memberof palexy.streaming.v1
                     * @interface IPosProduct
                     * @property {number|null} [id] PosProduct id
                     * @property {number|null} [companyId] PosProduct companyId
                     * @property {string|null} [name] PosProduct name
                     * @property {string|null} [code] PosProduct code
                     * @property {string|null} [description] PosProduct description
                     * @property {number|null} [createTimeMs] PosProduct createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] PosProduct lastUpdateTimeMs
                     * @property {Array.<palexy.streaming.v1.IPosProductMetadata>|null} [metadata] PosProduct metadata
                     */
    
                    /**
                     * Constructs a new PosProduct.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosProduct.
                     * @implements IPosProduct
                     * @constructor
                     * @param {palexy.streaming.v1.IPosProduct=} [properties] Properties to set
                     */
                    function PosProduct(properties) {
                        this.metadata = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosProduct id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.PosProduct
                     * @instance
                     */
                    PosProduct.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosProduct companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.PosProduct
                     * @instance
                     */
                    PosProduct.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosProduct name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.PosProduct
                     * @instance
                     */
                    PosProduct.prototype.name = "";
    
                    /**
                     * PosProduct code.
                     * @member {string} code
                     * @memberof palexy.streaming.v1.PosProduct
                     * @instance
                     */
                    PosProduct.prototype.code = "";
    
                    /**
                     * PosProduct description.
                     * @member {string} description
                     * @memberof palexy.streaming.v1.PosProduct
                     * @instance
                     */
                    PosProduct.prototype.description = "";
    
                    /**
                     * PosProduct createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.PosProduct
                     * @instance
                     */
                    PosProduct.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosProduct lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.PosProduct
                     * @instance
                     */
                    PosProduct.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosProduct metadata.
                     * @member {Array.<palexy.streaming.v1.IPosProductMetadata>} metadata
                     * @memberof palexy.streaming.v1.PosProduct
                     * @instance
                     */
                    PosProduct.prototype.metadata = $util.emptyArray;
    
                    /**
                     * Creates a new PosProduct instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosProduct
                     * @static
                     * @param {palexy.streaming.v1.IPosProduct=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosProduct} PosProduct instance
                     */
                    PosProduct.create = function create(properties) {
                        return new PosProduct(properties);
                    };
    
                    /**
                     * Encodes the specified PosProduct message. Does not implicitly {@link palexy.streaming.v1.PosProduct.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosProduct
                     * @static
                     * @param {palexy.streaming.v1.IPosProduct} message PosProduct message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosProduct.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.code);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.lastUpdateTimeMs);
                        if (message.metadata != null && message.metadata.length)
                            for (var i = 0; i < message.metadata.length; ++i)
                                $root.palexy.streaming.v1.PosProductMetadata.encode(message.metadata[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosProduct message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosProduct.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosProduct
                     * @static
                     * @param {palexy.streaming.v1.IPosProduct} message PosProduct message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosProduct.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosProduct message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosProduct
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosProduct} PosProduct
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosProduct.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosProduct();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.name = reader.string();
                                break;
                            case 4:
                                message.code = reader.string();
                                break;
                            case 5:
                                message.description = reader.string();
                                break;
                            case 6:
                                message.createTimeMs = reader.int64();
                                break;
                            case 7:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 8:
                                if (!(message.metadata && message.metadata.length))
                                    message.metadata = [];
                                message.metadata.push($root.palexy.streaming.v1.PosProductMetadata.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosProduct message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosProduct
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosProduct} PosProduct
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosProduct.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosProduct message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosProduct
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosProduct.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.metadata != null && message.hasOwnProperty("metadata")) {
                            if (!Array.isArray(message.metadata))
                                return "metadata: array expected";
                            for (var i = 0; i < message.metadata.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosProductMetadata.verify(message.metadata[i]);
                                if (error)
                                    return "metadata." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PosProduct message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosProduct
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosProduct} PosProduct
                     */
                    PosProduct.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosProduct)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosProduct();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.code != null)
                            message.code = String(object.code);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.metadata) {
                            if (!Array.isArray(object.metadata))
                                throw TypeError(".palexy.streaming.v1.PosProduct.metadata: array expected");
                            message.metadata = [];
                            for (var i = 0; i < object.metadata.length; ++i) {
                                if (typeof object.metadata[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.PosProduct.metadata: object expected");
                                message.metadata[i] = $root.palexy.streaming.v1.PosProductMetadata.fromObject(object.metadata[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosProduct message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosProduct
                     * @static
                     * @param {palexy.streaming.v1.PosProduct} message PosProduct
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosProduct.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.metadata = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.code = "";
                            object.description = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.metadata && message.metadata.length) {
                            object.metadata = [];
                            for (var j = 0; j < message.metadata.length; ++j)
                                object.metadata[j] = $root.palexy.streaming.v1.PosProductMetadata.toObject(message.metadata[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this PosProduct to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosProduct
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosProduct.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosProduct;
                })();
    
                v1.PosMetadata = (function() {
    
                    /**
                     * Properties of a PosMetadata.
                     * @memberof palexy.streaming.v1
                     * @interface IPosMetadata
                     * @property {number|null} [id] PosMetadata id
                     * @property {number|null} [companyId] PosMetadata companyId
                     * @property {string|null} [name] PosMetadata name
                     * @property {string|null} [description] PosMetadata description
                     * @property {palexy.streaming.v1.PosMetadata.DataType|null} [dataType] PosMetadata dataType
                     * @property {number|null} [createTimeMs] PosMetadata createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] PosMetadata lastUpdateTimeMs
                     * @property {number|null} [dimensionIndex] PosMetadata dimensionIndex
                     */
    
                    /**
                     * Constructs a new PosMetadata.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosMetadata.
                     * @implements IPosMetadata
                     * @constructor
                     * @param {palexy.streaming.v1.IPosMetadata=} [properties] Properties to set
                     */
                    function PosMetadata(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosMetadata id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @instance
                     */
                    PosMetadata.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosMetadata companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @instance
                     */
                    PosMetadata.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosMetadata name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @instance
                     */
                    PosMetadata.prototype.name = "";
    
                    /**
                     * PosMetadata description.
                     * @member {string} description
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @instance
                     */
                    PosMetadata.prototype.description = "";
    
                    /**
                     * PosMetadata dataType.
                     * @member {palexy.streaming.v1.PosMetadata.DataType} dataType
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @instance
                     */
                    PosMetadata.prototype.dataType = 0;
    
                    /**
                     * PosMetadata createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @instance
                     */
                    PosMetadata.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosMetadata lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @instance
                     */
                    PosMetadata.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosMetadata dimensionIndex.
                     * @member {number} dimensionIndex
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @instance
                     */
                    PosMetadata.prototype.dimensionIndex = 0;
    
                    /**
                     * Creates a new PosMetadata instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @static
                     * @param {palexy.streaming.v1.IPosMetadata=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosMetadata} PosMetadata instance
                     */
                    PosMetadata.create = function create(properties) {
                        return new PosMetadata(properties);
                    };
    
                    /**
                     * Encodes the specified PosMetadata message. Does not implicitly {@link palexy.streaming.v1.PosMetadata.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @static
                     * @param {palexy.streaming.v1.IPosMetadata} message PosMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosMetadata.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                        if (message.dataType != null && Object.hasOwnProperty.call(message, "dataType"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.dataType);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.lastUpdateTimeMs);
                        if (message.dimensionIndex != null && Object.hasOwnProperty.call(message, "dimensionIndex"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.dimensionIndex);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosMetadata message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosMetadata.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @static
                     * @param {palexy.streaming.v1.IPosMetadata} message PosMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosMetadata message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosMetadata} PosMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosMetadata.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosMetadata();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.name = reader.string();
                                break;
                            case 4:
                                message.description = reader.string();
                                break;
                            case 5:
                                message.dataType = reader.int32();
                                break;
                            case 6:
                                message.createTimeMs = reader.int64();
                                break;
                            case 7:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 8:
                                message.dimensionIndex = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosMetadata message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosMetadata} PosMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosMetadata.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosMetadata message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosMetadata.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.dataType != null && message.hasOwnProperty("dataType"))
                            switch (message.dataType) {
                            default:
                                return "dataType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.dimensionIndex != null && message.hasOwnProperty("dimensionIndex"))
                            if (!$util.isInteger(message.dimensionIndex))
                                return "dimensionIndex: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a PosMetadata message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosMetadata} PosMetadata
                     */
                    PosMetadata.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosMetadata)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosMetadata();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.description != null)
                            message.description = String(object.description);
                        switch (object.dataType) {
                        case "DATA_TYPE_UNKNOWN":
                        case 0:
                            message.dataType = 0;
                            break;
                        case "TEXT":
                        case 1:
                            message.dataType = 1;
                            break;
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.dimensionIndex != null)
                            message.dimensionIndex = object.dimensionIndex | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosMetadata message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @static
                     * @param {palexy.streaming.v1.PosMetadata} message PosMetadata
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosMetadata.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.description = "";
                            object.dataType = options.enums === String ? "DATA_TYPE_UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            object.dimensionIndex = 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.dataType != null && message.hasOwnProperty("dataType"))
                            object.dataType = options.enums === String ? $root.palexy.streaming.v1.PosMetadata.DataType[message.dataType] : message.dataType;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.dimensionIndex != null && message.hasOwnProperty("dimensionIndex"))
                            object.dimensionIndex = message.dimensionIndex;
                        return object;
                    };
    
                    /**
                     * Converts this PosMetadata to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosMetadata
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosMetadata.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * DataType enum.
                     * @name palexy.streaming.v1.PosMetadata.DataType
                     * @enum {number}
                     * @property {number} DATA_TYPE_UNKNOWN=0 DATA_TYPE_UNKNOWN value
                     * @property {number} TEXT=1 TEXT value
                     */
                    PosMetadata.DataType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "DATA_TYPE_UNKNOWN"] = 0;
                        values[valuesById[1] = "TEXT"] = 1;
                        return values;
                    })();
    
                    return PosMetadata;
                })();
    
                v1.PosProductMetadata = (function() {
    
                    /**
                     * Properties of a PosProductMetadata.
                     * @memberof palexy.streaming.v1
                     * @interface IPosProductMetadata
                     * @property {string|null} [metadataName] PosProductMetadata metadataName
                     * @property {string|null} [metadataValue] PosProductMetadata metadataValue
                     * @property {number|null} [metadataId] PosProductMetadata metadataId
                     */
    
                    /**
                     * Constructs a new PosProductMetadata.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosProductMetadata.
                     * @implements IPosProductMetadata
                     * @constructor
                     * @param {palexy.streaming.v1.IPosProductMetadata=} [properties] Properties to set
                     */
                    function PosProductMetadata(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosProductMetadata metadataName.
                     * @member {string} metadataName
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @instance
                     */
                    PosProductMetadata.prototype.metadataName = "";
    
                    /**
                     * PosProductMetadata metadataValue.
                     * @member {string} metadataValue
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @instance
                     */
                    PosProductMetadata.prototype.metadataValue = "";
    
                    /**
                     * PosProductMetadata metadataId.
                     * @member {number} metadataId
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @instance
                     */
                    PosProductMetadata.prototype.metadataId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new PosProductMetadata instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @static
                     * @param {palexy.streaming.v1.IPosProductMetadata=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosProductMetadata} PosProductMetadata instance
                     */
                    PosProductMetadata.create = function create(properties) {
                        return new PosProductMetadata(properties);
                    };
    
                    /**
                     * Encodes the specified PosProductMetadata message. Does not implicitly {@link palexy.streaming.v1.PosProductMetadata.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @static
                     * @param {palexy.streaming.v1.IPosProductMetadata} message PosProductMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosProductMetadata.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.metadataName != null && Object.hasOwnProperty.call(message, "metadataName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.metadataName);
                        if (message.metadataValue != null && Object.hasOwnProperty.call(message, "metadataValue"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.metadataValue);
                        if (message.metadataId != null && Object.hasOwnProperty.call(message, "metadataId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.metadataId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosProductMetadata message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosProductMetadata.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @static
                     * @param {palexy.streaming.v1.IPosProductMetadata} message PosProductMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosProductMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosProductMetadata message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosProductMetadata} PosProductMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosProductMetadata.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosProductMetadata();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.metadataName = reader.string();
                                break;
                            case 2:
                                message.metadataValue = reader.string();
                                break;
                            case 3:
                                message.metadataId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosProductMetadata message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosProductMetadata} PosProductMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosProductMetadata.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosProductMetadata message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosProductMetadata.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.metadataName != null && message.hasOwnProperty("metadataName"))
                            if (!$util.isString(message.metadataName))
                                return "metadataName: string expected";
                        if (message.metadataValue != null && message.hasOwnProperty("metadataValue"))
                            if (!$util.isString(message.metadataValue))
                                return "metadataValue: string expected";
                        if (message.metadataId != null && message.hasOwnProperty("metadataId"))
                            if (!$util.isInteger(message.metadataId) && !(message.metadataId && $util.isInteger(message.metadataId.low) && $util.isInteger(message.metadataId.high)))
                                return "metadataId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a PosProductMetadata message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosProductMetadata} PosProductMetadata
                     */
                    PosProductMetadata.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosProductMetadata)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosProductMetadata();
                        if (object.metadataName != null)
                            message.metadataName = String(object.metadataName);
                        if (object.metadataValue != null)
                            message.metadataValue = String(object.metadataValue);
                        if (object.metadataId != null)
                            if ($util.Long)
                                (message.metadataId = $util.Long.fromValue(object.metadataId)).unsigned = false;
                            else if (typeof object.metadataId === "string")
                                message.metadataId = parseInt(object.metadataId, 10);
                            else if (typeof object.metadataId === "number")
                                message.metadataId = object.metadataId;
                            else if (typeof object.metadataId === "object")
                                message.metadataId = new $util.LongBits(object.metadataId.low >>> 0, object.metadataId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosProductMetadata message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @static
                     * @param {palexy.streaming.v1.PosProductMetadata} message PosProductMetadata
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosProductMetadata.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.metadataName = "";
                            object.metadataValue = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataId = options.longs === String ? "0" : 0;
                        }
                        if (message.metadataName != null && message.hasOwnProperty("metadataName"))
                            object.metadataName = message.metadataName;
                        if (message.metadataValue != null && message.hasOwnProperty("metadataValue"))
                            object.metadataValue = message.metadataValue;
                        if (message.metadataId != null && message.hasOwnProperty("metadataId"))
                            if (typeof message.metadataId === "number")
                                object.metadataId = options.longs === String ? String(message.metadataId) : message.metadataId;
                            else
                                object.metadataId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataId) : options.longs === Number ? new $util.LongBits(message.metadataId.low >>> 0, message.metadataId.high >>> 0).toNumber() : message.metadataId;
                        return object;
                    };
    
                    /**
                     * Converts this PosProductMetadata to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosProductMetadata
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosProductMetadata.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosProductMetadata;
                })();
    
                v1.PosTransaction = (function() {
    
                    /**
                     * Properties of a PosTransaction.
                     * @memberof palexy.streaming.v1
                     * @interface IPosTransaction
                     * @property {number|null} [id] PosTransaction id
                     * @property {number|null} [storeId] PosTransaction storeId
                     * @property {number|null} [dateId] PosTransaction dateId
                     * @property {number|null} [ownerId] PosTransaction ownerId
                     * @property {string|null} [tranid] PosTransaction tranid
                     * @property {string|null} [currency] PosTransaction currency
                     * @property {string|null} [transactionTime] PosTransaction transactionTime
                     * @property {number|null} [insertedDateTimeMs] PosTransaction insertedDateTimeMs
                     * @property {string|null} [membershipCard] PosTransaction membershipCard
                     * @property {string|null} [externalCustomerId] PosTransaction externalCustomerId
                     * @property {string|null} [terminalId] PosTransaction terminalId
                     * @property {string|null} [salesStaffId] PosTransaction salesStaffId
                     * @property {Object.<string,string>|null} [additionalProperties] PosTransaction additionalProperties
                     * @property {Array.<palexy.streaming.v1.IPosTransactionProduct>|null} [products] PosTransaction products
                     * @property {Array.<palexy.streaming.v1.IPosCustomerTransaction>|null} [customers] PosTransaction customers
                     * @property {Array.<palexy.streaming.v1.IStaff>|null} [staffs] PosTransaction staffs
                     * @property {Array.<string>|null} [posExternalStaffIds] PosTransaction posExternalStaffIds
                     */
    
                    /**
                     * Constructs a new PosTransaction.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosTransaction.
                     * @implements IPosTransaction
                     * @constructor
                     * @param {palexy.streaming.v1.IPosTransaction=} [properties] Properties to set
                     */
                    function PosTransaction(properties) {
                        this.additionalProperties = {};
                        this.products = [];
                        this.customers = [];
                        this.staffs = [];
                        this.posExternalStaffIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosTransaction id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosTransaction storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosTransaction dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.dateId = 0;
    
                    /**
                     * PosTransaction ownerId.
                     * @member {number} ownerId
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.ownerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosTransaction tranid.
                     * @member {string} tranid
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.tranid = "";
    
                    /**
                     * PosTransaction currency.
                     * @member {string} currency
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.currency = "";
    
                    /**
                     * PosTransaction transactionTime.
                     * @member {string} transactionTime
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.transactionTime = "";
    
                    /**
                     * PosTransaction insertedDateTimeMs.
                     * @member {number} insertedDateTimeMs
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.insertedDateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosTransaction membershipCard.
                     * @member {string} membershipCard
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.membershipCard = "";
    
                    /**
                     * PosTransaction externalCustomerId.
                     * @member {string} externalCustomerId
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.externalCustomerId = "";
    
                    /**
                     * PosTransaction terminalId.
                     * @member {string} terminalId
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.terminalId = "";
    
                    /**
                     * PosTransaction salesStaffId.
                     * @member {string} salesStaffId
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.salesStaffId = "";
    
                    /**
                     * PosTransaction additionalProperties.
                     * @member {Object.<string,string>} additionalProperties
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.additionalProperties = $util.emptyObject;
    
                    /**
                     * PosTransaction products.
                     * @member {Array.<palexy.streaming.v1.IPosTransactionProduct>} products
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.products = $util.emptyArray;
    
                    /**
                     * PosTransaction customers.
                     * @member {Array.<palexy.streaming.v1.IPosCustomerTransaction>} customers
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.customers = $util.emptyArray;
    
                    /**
                     * PosTransaction staffs.
                     * @member {Array.<palexy.streaming.v1.IStaff>} staffs
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.staffs = $util.emptyArray;
    
                    /**
                     * PosTransaction posExternalStaffIds.
                     * @member {Array.<string>} posExternalStaffIds
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     */
                    PosTransaction.prototype.posExternalStaffIds = $util.emptyArray;
    
                    /**
                     * Creates a new PosTransaction instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @static
                     * @param {palexy.streaming.v1.IPosTransaction=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosTransaction} PosTransaction instance
                     */
                    PosTransaction.create = function create(properties) {
                        return new PosTransaction(properties);
                    };
    
                    /**
                     * Encodes the specified PosTransaction message. Does not implicitly {@link palexy.streaming.v1.PosTransaction.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @static
                     * @param {palexy.streaming.v1.IPosTransaction} message PosTransaction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosTransaction.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.dateId);
                        if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.ownerId);
                        if (message.tranid != null && Object.hasOwnProperty.call(message, "tranid"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.tranid);
                        if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.currency);
                        if (message.transactionTime != null && Object.hasOwnProperty.call(message, "transactionTime"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.transactionTime);
                        if (message.insertedDateTimeMs != null && Object.hasOwnProperty.call(message, "insertedDateTimeMs"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.insertedDateTimeMs);
                        if (message.products != null && message.products.length)
                            for (var i = 0; i < message.products.length; ++i)
                                $root.palexy.streaming.v1.PosTransactionProduct.encode(message.products[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.customers != null && message.customers.length)
                            for (var i = 0; i < message.customers.length; ++i)
                                $root.palexy.streaming.v1.PosCustomerTransaction.encode(message.customers[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.membershipCard != null && Object.hasOwnProperty.call(message, "membershipCard"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.membershipCard);
                        if (message.externalCustomerId != null && Object.hasOwnProperty.call(message, "externalCustomerId"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.externalCustomerId);
                        if (message.terminalId != null && Object.hasOwnProperty.call(message, "terminalId"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.terminalId);
                        if (message.salesStaffId != null && Object.hasOwnProperty.call(message, "salesStaffId"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.salesStaffId);
                        if (message.additionalProperties != null && Object.hasOwnProperty.call(message, "additionalProperties"))
                            for (var keys = Object.keys(message.additionalProperties), i = 0; i < keys.length; ++i)
                                writer.uint32(/* id 15, wireType 2 =*/122).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.additionalProperties[keys[i]]).ldelim();
                        if (message.staffs != null && message.staffs.length)
                            for (var i = 0; i < message.staffs.length; ++i)
                                $root.palexy.streaming.v1.Staff.encode(message.staffs[i], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.posExternalStaffIds != null && message.posExternalStaffIds.length)
                            for (var i = 0; i < message.posExternalStaffIds.length; ++i)
                                writer.uint32(/* id 17, wireType 2 =*/138).string(message.posExternalStaffIds[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosTransaction message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosTransaction.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @static
                     * @param {palexy.streaming.v1.IPosTransaction} message PosTransaction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosTransaction.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosTransaction message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosTransaction} PosTransaction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosTransaction.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosTransaction(), key, value;
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.dateId = reader.int32();
                                break;
                            case 4:
                                message.ownerId = reader.int64();
                                break;
                            case 5:
                                message.tranid = reader.string();
                                break;
                            case 6:
                                message.currency = reader.string();
                                break;
                            case 7:
                                message.transactionTime = reader.string();
                                break;
                            case 8:
                                message.insertedDateTimeMs = reader.int64();
                                break;
                            case 11:
                                message.membershipCard = reader.string();
                                break;
                            case 12:
                                message.externalCustomerId = reader.string();
                                break;
                            case 13:
                                message.terminalId = reader.string();
                                break;
                            case 14:
                                message.salesStaffId = reader.string();
                                break;
                            case 15:
                                if (message.additionalProperties === $util.emptyObject)
                                    message.additionalProperties = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.additionalProperties[key] = value;
                                break;
                            case 9:
                                if (!(message.products && message.products.length))
                                    message.products = [];
                                message.products.push($root.palexy.streaming.v1.PosTransactionProduct.decode(reader, reader.uint32()));
                                break;
                            case 10:
                                if (!(message.customers && message.customers.length))
                                    message.customers = [];
                                message.customers.push($root.palexy.streaming.v1.PosCustomerTransaction.decode(reader, reader.uint32()));
                                break;
                            case 16:
                                if (!(message.staffs && message.staffs.length))
                                    message.staffs = [];
                                message.staffs.push($root.palexy.streaming.v1.Staff.decode(reader, reader.uint32()));
                                break;
                            case 17:
                                if (!(message.posExternalStaffIds && message.posExternalStaffIds.length))
                                    message.posExternalStaffIds = [];
                                message.posExternalStaffIds.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosTransaction message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosTransaction} PosTransaction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosTransaction.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosTransaction message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosTransaction.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (!$util.isInteger(message.ownerId) && !(message.ownerId && $util.isInteger(message.ownerId.low) && $util.isInteger(message.ownerId.high)))
                                return "ownerId: integer|Long expected";
                        if (message.tranid != null && message.hasOwnProperty("tranid"))
                            if (!$util.isString(message.tranid))
                                return "tranid: string expected";
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            if (!$util.isString(message.currency))
                                return "currency: string expected";
                        if (message.transactionTime != null && message.hasOwnProperty("transactionTime"))
                            if (!$util.isString(message.transactionTime))
                                return "transactionTime: string expected";
                        if (message.insertedDateTimeMs != null && message.hasOwnProperty("insertedDateTimeMs"))
                            if (!$util.isInteger(message.insertedDateTimeMs) && !(message.insertedDateTimeMs && $util.isInteger(message.insertedDateTimeMs.low) && $util.isInteger(message.insertedDateTimeMs.high)))
                                return "insertedDateTimeMs: integer|Long expected";
                        if (message.membershipCard != null && message.hasOwnProperty("membershipCard"))
                            if (!$util.isString(message.membershipCard))
                                return "membershipCard: string expected";
                        if (message.externalCustomerId != null && message.hasOwnProperty("externalCustomerId"))
                            if (!$util.isString(message.externalCustomerId))
                                return "externalCustomerId: string expected";
                        if (message.terminalId != null && message.hasOwnProperty("terminalId"))
                            if (!$util.isString(message.terminalId))
                                return "terminalId: string expected";
                        if (message.salesStaffId != null && message.hasOwnProperty("salesStaffId"))
                            if (!$util.isString(message.salesStaffId))
                                return "salesStaffId: string expected";
                        if (message.additionalProperties != null && message.hasOwnProperty("additionalProperties")) {
                            if (!$util.isObject(message.additionalProperties))
                                return "additionalProperties: object expected";
                            var key = Object.keys(message.additionalProperties);
                            for (var i = 0; i < key.length; ++i)
                                if (!$util.isString(message.additionalProperties[key[i]]))
                                    return "additionalProperties: string{k:string} expected";
                        }
                        if (message.products != null && message.hasOwnProperty("products")) {
                            if (!Array.isArray(message.products))
                                return "products: array expected";
                            for (var i = 0; i < message.products.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosTransactionProduct.verify(message.products[i]);
                                if (error)
                                    return "products." + error;
                            }
                        }
                        if (message.customers != null && message.hasOwnProperty("customers")) {
                            if (!Array.isArray(message.customers))
                                return "customers: array expected";
                            for (var i = 0; i < message.customers.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosCustomerTransaction.verify(message.customers[i]);
                                if (error)
                                    return "customers." + error;
                            }
                        }
                        if (message.staffs != null && message.hasOwnProperty("staffs")) {
                            if (!Array.isArray(message.staffs))
                                return "staffs: array expected";
                            for (var i = 0; i < message.staffs.length; ++i) {
                                var error = $root.palexy.streaming.v1.Staff.verify(message.staffs[i]);
                                if (error)
                                    return "staffs." + error;
                            }
                        }
                        if (message.posExternalStaffIds != null && message.hasOwnProperty("posExternalStaffIds")) {
                            if (!Array.isArray(message.posExternalStaffIds))
                                return "posExternalStaffIds: array expected";
                            for (var i = 0; i < message.posExternalStaffIds.length; ++i)
                                if (!$util.isString(message.posExternalStaffIds[i]))
                                    return "posExternalStaffIds: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PosTransaction message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosTransaction} PosTransaction
                     */
                    PosTransaction.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosTransaction)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosTransaction();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.ownerId != null)
                            if ($util.Long)
                                (message.ownerId = $util.Long.fromValue(object.ownerId)).unsigned = false;
                            else if (typeof object.ownerId === "string")
                                message.ownerId = parseInt(object.ownerId, 10);
                            else if (typeof object.ownerId === "number")
                                message.ownerId = object.ownerId;
                            else if (typeof object.ownerId === "object")
                                message.ownerId = new $util.LongBits(object.ownerId.low >>> 0, object.ownerId.high >>> 0).toNumber();
                        if (object.tranid != null)
                            message.tranid = String(object.tranid);
                        if (object.currency != null)
                            message.currency = String(object.currency);
                        if (object.transactionTime != null)
                            message.transactionTime = String(object.transactionTime);
                        if (object.insertedDateTimeMs != null)
                            if ($util.Long)
                                (message.insertedDateTimeMs = $util.Long.fromValue(object.insertedDateTimeMs)).unsigned = false;
                            else if (typeof object.insertedDateTimeMs === "string")
                                message.insertedDateTimeMs = parseInt(object.insertedDateTimeMs, 10);
                            else if (typeof object.insertedDateTimeMs === "number")
                                message.insertedDateTimeMs = object.insertedDateTimeMs;
                            else if (typeof object.insertedDateTimeMs === "object")
                                message.insertedDateTimeMs = new $util.LongBits(object.insertedDateTimeMs.low >>> 0, object.insertedDateTimeMs.high >>> 0).toNumber();
                        if (object.membershipCard != null)
                            message.membershipCard = String(object.membershipCard);
                        if (object.externalCustomerId != null)
                            message.externalCustomerId = String(object.externalCustomerId);
                        if (object.terminalId != null)
                            message.terminalId = String(object.terminalId);
                        if (object.salesStaffId != null)
                            message.salesStaffId = String(object.salesStaffId);
                        if (object.additionalProperties) {
                            if (typeof object.additionalProperties !== "object")
                                throw TypeError(".palexy.streaming.v1.PosTransaction.additionalProperties: object expected");
                            message.additionalProperties = {};
                            for (var keys = Object.keys(object.additionalProperties), i = 0; i < keys.length; ++i)
                                message.additionalProperties[keys[i]] = String(object.additionalProperties[keys[i]]);
                        }
                        if (object.products) {
                            if (!Array.isArray(object.products))
                                throw TypeError(".palexy.streaming.v1.PosTransaction.products: array expected");
                            message.products = [];
                            for (var i = 0; i < object.products.length; ++i) {
                                if (typeof object.products[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.PosTransaction.products: object expected");
                                message.products[i] = $root.palexy.streaming.v1.PosTransactionProduct.fromObject(object.products[i]);
                            }
                        }
                        if (object.customers) {
                            if (!Array.isArray(object.customers))
                                throw TypeError(".palexy.streaming.v1.PosTransaction.customers: array expected");
                            message.customers = [];
                            for (var i = 0; i < object.customers.length; ++i) {
                                if (typeof object.customers[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.PosTransaction.customers: object expected");
                                message.customers[i] = $root.palexy.streaming.v1.PosCustomerTransaction.fromObject(object.customers[i]);
                            }
                        }
                        if (object.staffs) {
                            if (!Array.isArray(object.staffs))
                                throw TypeError(".palexy.streaming.v1.PosTransaction.staffs: array expected");
                            message.staffs = [];
                            for (var i = 0; i < object.staffs.length; ++i) {
                                if (typeof object.staffs[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.PosTransaction.staffs: object expected");
                                message.staffs[i] = $root.palexy.streaming.v1.Staff.fromObject(object.staffs[i]);
                            }
                        }
                        if (object.posExternalStaffIds) {
                            if (!Array.isArray(object.posExternalStaffIds))
                                throw TypeError(".palexy.streaming.v1.PosTransaction.posExternalStaffIds: array expected");
                            message.posExternalStaffIds = [];
                            for (var i = 0; i < object.posExternalStaffIds.length; ++i)
                                message.posExternalStaffIds[i] = String(object.posExternalStaffIds[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosTransaction message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @static
                     * @param {palexy.streaming.v1.PosTransaction} message PosTransaction
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosTransaction.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.products = [];
                            object.customers = [];
                            object.staffs = [];
                            object.posExternalStaffIds = [];
                        }
                        if (options.objects || options.defaults)
                            object.additionalProperties = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.ownerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.ownerId = options.longs === String ? "0" : 0;
                            object.tranid = "";
                            object.currency = "";
                            object.transactionTime = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.insertedDateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.insertedDateTimeMs = options.longs === String ? "0" : 0;
                            object.membershipCard = "";
                            object.externalCustomerId = "";
                            object.terminalId = "";
                            object.salesStaffId = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                            if (typeof message.ownerId === "number")
                                object.ownerId = options.longs === String ? String(message.ownerId) : message.ownerId;
                            else
                                object.ownerId = options.longs === String ? $util.Long.prototype.toString.call(message.ownerId) : options.longs === Number ? new $util.LongBits(message.ownerId.low >>> 0, message.ownerId.high >>> 0).toNumber() : message.ownerId;
                        if (message.tranid != null && message.hasOwnProperty("tranid"))
                            object.tranid = message.tranid;
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            object.currency = message.currency;
                        if (message.transactionTime != null && message.hasOwnProperty("transactionTime"))
                            object.transactionTime = message.transactionTime;
                        if (message.insertedDateTimeMs != null && message.hasOwnProperty("insertedDateTimeMs"))
                            if (typeof message.insertedDateTimeMs === "number")
                                object.insertedDateTimeMs = options.longs === String ? String(message.insertedDateTimeMs) : message.insertedDateTimeMs;
                            else
                                object.insertedDateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.insertedDateTimeMs) : options.longs === Number ? new $util.LongBits(message.insertedDateTimeMs.low >>> 0, message.insertedDateTimeMs.high >>> 0).toNumber() : message.insertedDateTimeMs;
                        if (message.products && message.products.length) {
                            object.products = [];
                            for (var j = 0; j < message.products.length; ++j)
                                object.products[j] = $root.palexy.streaming.v1.PosTransactionProduct.toObject(message.products[j], options);
                        }
                        if (message.customers && message.customers.length) {
                            object.customers = [];
                            for (var j = 0; j < message.customers.length; ++j)
                                object.customers[j] = $root.palexy.streaming.v1.PosCustomerTransaction.toObject(message.customers[j], options);
                        }
                        if (message.membershipCard != null && message.hasOwnProperty("membershipCard"))
                            object.membershipCard = message.membershipCard;
                        if (message.externalCustomerId != null && message.hasOwnProperty("externalCustomerId"))
                            object.externalCustomerId = message.externalCustomerId;
                        if (message.terminalId != null && message.hasOwnProperty("terminalId"))
                            object.terminalId = message.terminalId;
                        if (message.salesStaffId != null && message.hasOwnProperty("salesStaffId"))
                            object.salesStaffId = message.salesStaffId;
                        var keys2;
                        if (message.additionalProperties && (keys2 = Object.keys(message.additionalProperties)).length) {
                            object.additionalProperties = {};
                            for (var j = 0; j < keys2.length; ++j)
                                object.additionalProperties[keys2[j]] = message.additionalProperties[keys2[j]];
                        }
                        if (message.staffs && message.staffs.length) {
                            object.staffs = [];
                            for (var j = 0; j < message.staffs.length; ++j)
                                object.staffs[j] = $root.palexy.streaming.v1.Staff.toObject(message.staffs[j], options);
                        }
                        if (message.posExternalStaffIds && message.posExternalStaffIds.length) {
                            object.posExternalStaffIds = [];
                            for (var j = 0; j < message.posExternalStaffIds.length; ++j)
                                object.posExternalStaffIds[j] = message.posExternalStaffIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this PosTransaction to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosTransaction
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosTransaction.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosTransaction;
                })();
    
                v1.PosTransactionProduct = (function() {
    
                    /**
                     * Properties of a PosTransactionProduct.
                     * @memberof palexy.streaming.v1
                     * @interface IPosTransactionProduct
                     * @property {number|null} [productId] PosTransactionProduct productId
                     * @property {string|null} [productName] PosTransactionProduct productName
                     * @property {string|null} [productCode] PosTransactionProduct productCode
                     * @property {string|null} [productDescription] PosTransactionProduct productDescription
                     * @property {number|null} [unitPrice] PosTransactionProduct unitPrice
                     * @property {number|null} [quantity] PosTransactionProduct quantity
                     * @property {number|null} [totalAmount] PosTransactionProduct totalAmount
                     * @property {number|null} [netAmount] PosTransactionProduct netAmount
                     * @property {number|null} [unitBaseCost] PosTransactionProduct unitBaseCost
                     * @property {string|null} [transactionTime] PosTransactionProduct transactionTime
                     * @property {string|null} [unit] PosTransactionProduct unit
                     * @property {number|null} [grossAmount] PosTransactionProduct grossAmount
                     * @property {number|null} [discountAmount] PosTransactionProduct discountAmount
                     * @property {Array.<palexy.streaming.v1.IPosProductMetadata>|null} [metadata] PosTransactionProduct metadata
                     */
    
                    /**
                     * Constructs a new PosTransactionProduct.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosTransactionProduct.
                     * @implements IPosTransactionProduct
                     * @constructor
                     * @param {palexy.streaming.v1.IPosTransactionProduct=} [properties] Properties to set
                     */
                    function PosTransactionProduct(properties) {
                        this.metadata = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosTransactionProduct productId.
                     * @member {number} productId
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.productId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosTransactionProduct productName.
                     * @member {string} productName
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.productName = "";
    
                    /**
                     * PosTransactionProduct productCode.
                     * @member {string} productCode
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.productCode = "";
    
                    /**
                     * PosTransactionProduct productDescription.
                     * @member {string} productDescription
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.productDescription = "";
    
                    /**
                     * PosTransactionProduct unitPrice.
                     * @member {number} unitPrice
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.unitPrice = 0;
    
                    /**
                     * PosTransactionProduct quantity.
                     * @member {number} quantity
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.quantity = 0;
    
                    /**
                     * PosTransactionProduct totalAmount.
                     * @member {number} totalAmount
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.totalAmount = 0;
    
                    /**
                     * PosTransactionProduct netAmount.
                     * @member {number} netAmount
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.netAmount = 0;
    
                    /**
                     * PosTransactionProduct unitBaseCost.
                     * @member {number} unitBaseCost
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.unitBaseCost = 0;
    
                    /**
                     * PosTransactionProduct transactionTime.
                     * @member {string} transactionTime
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.transactionTime = "";
    
                    /**
                     * PosTransactionProduct unit.
                     * @member {string} unit
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.unit = "";
    
                    /**
                     * PosTransactionProduct grossAmount.
                     * @member {number} grossAmount
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.grossAmount = 0;
    
                    /**
                     * PosTransactionProduct discountAmount.
                     * @member {number} discountAmount
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.discountAmount = 0;
    
                    /**
                     * PosTransactionProduct metadata.
                     * @member {Array.<palexy.streaming.v1.IPosProductMetadata>} metadata
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     */
                    PosTransactionProduct.prototype.metadata = $util.emptyArray;
    
                    /**
                     * Creates a new PosTransactionProduct instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @static
                     * @param {palexy.streaming.v1.IPosTransactionProduct=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosTransactionProduct} PosTransactionProduct instance
                     */
                    PosTransactionProduct.create = function create(properties) {
                        return new PosTransactionProduct(properties);
                    };
    
                    /**
                     * Encodes the specified PosTransactionProduct message. Does not implicitly {@link palexy.streaming.v1.PosTransactionProduct.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @static
                     * @param {palexy.streaming.v1.IPosTransactionProduct} message PosTransactionProduct message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosTransactionProduct.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.productId);
                        if (message.productName != null && Object.hasOwnProperty.call(message, "productName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.productName);
                        if (message.unitPrice != null && Object.hasOwnProperty.call(message, "unitPrice"))
                            writer.uint32(/* id 3, wireType 1 =*/25).double(message.unitPrice);
                        if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                            writer.uint32(/* id 4, wireType 1 =*/33).double(message.quantity);
                        if (message.totalAmount != null && Object.hasOwnProperty.call(message, "totalAmount"))
                            writer.uint32(/* id 5, wireType 1 =*/41).double(message.totalAmount);
                        if (message.metadata != null && message.metadata.length)
                            for (var i = 0; i < message.metadata.length; ++i)
                                $root.palexy.streaming.v1.PosProductMetadata.encode(message.metadata[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.productCode != null && Object.hasOwnProperty.call(message, "productCode"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.productCode);
                        if (message.productDescription != null && Object.hasOwnProperty.call(message, "productDescription"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.productDescription);
                        if (message.netAmount != null && Object.hasOwnProperty.call(message, "netAmount"))
                            writer.uint32(/* id 9, wireType 1 =*/73).double(message.netAmount);
                        if (message.unitBaseCost != null && Object.hasOwnProperty.call(message, "unitBaseCost"))
                            writer.uint32(/* id 10, wireType 1 =*/81).double(message.unitBaseCost);
                        if (message.transactionTime != null && Object.hasOwnProperty.call(message, "transactionTime"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.transactionTime);
                        if (message.unit != null && Object.hasOwnProperty.call(message, "unit"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.unit);
                        if (message.grossAmount != null && Object.hasOwnProperty.call(message, "grossAmount"))
                            writer.uint32(/* id 13, wireType 1 =*/105).double(message.grossAmount);
                        if (message.discountAmount != null && Object.hasOwnProperty.call(message, "discountAmount"))
                            writer.uint32(/* id 14, wireType 1 =*/113).double(message.discountAmount);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosTransactionProduct message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosTransactionProduct.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @static
                     * @param {palexy.streaming.v1.IPosTransactionProduct} message PosTransactionProduct message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosTransactionProduct.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosTransactionProduct message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosTransactionProduct} PosTransactionProduct
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosTransactionProduct.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosTransactionProduct();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.productId = reader.int64();
                                break;
                            case 2:
                                message.productName = reader.string();
                                break;
                            case 7:
                                message.productCode = reader.string();
                                break;
                            case 8:
                                message.productDescription = reader.string();
                                break;
                            case 3:
                                message.unitPrice = reader.double();
                                break;
                            case 4:
                                message.quantity = reader.double();
                                break;
                            case 5:
                                message.totalAmount = reader.double();
                                break;
                            case 9:
                                message.netAmount = reader.double();
                                break;
                            case 10:
                                message.unitBaseCost = reader.double();
                                break;
                            case 11:
                                message.transactionTime = reader.string();
                                break;
                            case 12:
                                message.unit = reader.string();
                                break;
                            case 13:
                                message.grossAmount = reader.double();
                                break;
                            case 14:
                                message.discountAmount = reader.double();
                                break;
                            case 6:
                                if (!(message.metadata && message.metadata.length))
                                    message.metadata = [];
                                message.metadata.push($root.palexy.streaming.v1.PosProductMetadata.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosTransactionProduct message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosTransactionProduct} PosTransactionProduct
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosTransactionProduct.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosTransactionProduct message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosTransactionProduct.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.productId != null && message.hasOwnProperty("productId"))
                            if (!$util.isInteger(message.productId) && !(message.productId && $util.isInteger(message.productId.low) && $util.isInteger(message.productId.high)))
                                return "productId: integer|Long expected";
                        if (message.productName != null && message.hasOwnProperty("productName"))
                            if (!$util.isString(message.productName))
                                return "productName: string expected";
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            if (!$util.isString(message.productCode))
                                return "productCode: string expected";
                        if (message.productDescription != null && message.hasOwnProperty("productDescription"))
                            if (!$util.isString(message.productDescription))
                                return "productDescription: string expected";
                        if (message.unitPrice != null && message.hasOwnProperty("unitPrice"))
                            if (typeof message.unitPrice !== "number")
                                return "unitPrice: number expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity !== "number")
                                return "quantity: number expected";
                        if (message.totalAmount != null && message.hasOwnProperty("totalAmount"))
                            if (typeof message.totalAmount !== "number")
                                return "totalAmount: number expected";
                        if (message.netAmount != null && message.hasOwnProperty("netAmount"))
                            if (typeof message.netAmount !== "number")
                                return "netAmount: number expected";
                        if (message.unitBaseCost != null && message.hasOwnProperty("unitBaseCost"))
                            if (typeof message.unitBaseCost !== "number")
                                return "unitBaseCost: number expected";
                        if (message.transactionTime != null && message.hasOwnProperty("transactionTime"))
                            if (!$util.isString(message.transactionTime))
                                return "transactionTime: string expected";
                        if (message.unit != null && message.hasOwnProperty("unit"))
                            if (!$util.isString(message.unit))
                                return "unit: string expected";
                        if (message.grossAmount != null && message.hasOwnProperty("grossAmount"))
                            if (typeof message.grossAmount !== "number")
                                return "grossAmount: number expected";
                        if (message.discountAmount != null && message.hasOwnProperty("discountAmount"))
                            if (typeof message.discountAmount !== "number")
                                return "discountAmount: number expected";
                        if (message.metadata != null && message.hasOwnProperty("metadata")) {
                            if (!Array.isArray(message.metadata))
                                return "metadata: array expected";
                            for (var i = 0; i < message.metadata.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosProductMetadata.verify(message.metadata[i]);
                                if (error)
                                    return "metadata." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PosTransactionProduct message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosTransactionProduct} PosTransactionProduct
                     */
                    PosTransactionProduct.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosTransactionProduct)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosTransactionProduct();
                        if (object.productId != null)
                            if ($util.Long)
                                (message.productId = $util.Long.fromValue(object.productId)).unsigned = false;
                            else if (typeof object.productId === "string")
                                message.productId = parseInt(object.productId, 10);
                            else if (typeof object.productId === "number")
                                message.productId = object.productId;
                            else if (typeof object.productId === "object")
                                message.productId = new $util.LongBits(object.productId.low >>> 0, object.productId.high >>> 0).toNumber();
                        if (object.productName != null)
                            message.productName = String(object.productName);
                        if (object.productCode != null)
                            message.productCode = String(object.productCode);
                        if (object.productDescription != null)
                            message.productDescription = String(object.productDescription);
                        if (object.unitPrice != null)
                            message.unitPrice = Number(object.unitPrice);
                        if (object.quantity != null)
                            message.quantity = Number(object.quantity);
                        if (object.totalAmount != null)
                            message.totalAmount = Number(object.totalAmount);
                        if (object.netAmount != null)
                            message.netAmount = Number(object.netAmount);
                        if (object.unitBaseCost != null)
                            message.unitBaseCost = Number(object.unitBaseCost);
                        if (object.transactionTime != null)
                            message.transactionTime = String(object.transactionTime);
                        if (object.unit != null)
                            message.unit = String(object.unit);
                        if (object.grossAmount != null)
                            message.grossAmount = Number(object.grossAmount);
                        if (object.discountAmount != null)
                            message.discountAmount = Number(object.discountAmount);
                        if (object.metadata) {
                            if (!Array.isArray(object.metadata))
                                throw TypeError(".palexy.streaming.v1.PosTransactionProduct.metadata: array expected");
                            message.metadata = [];
                            for (var i = 0; i < object.metadata.length; ++i) {
                                if (typeof object.metadata[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.PosTransactionProduct.metadata: object expected");
                                message.metadata[i] = $root.palexy.streaming.v1.PosProductMetadata.fromObject(object.metadata[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosTransactionProduct message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @static
                     * @param {palexy.streaming.v1.PosTransactionProduct} message PosTransactionProduct
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosTransactionProduct.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.metadata = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.productId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.productId = options.longs === String ? "0" : 0;
                            object.productName = "";
                            object.unitPrice = 0;
                            object.quantity = 0;
                            object.totalAmount = 0;
                            object.productCode = "";
                            object.productDescription = "";
                            object.netAmount = 0;
                            object.unitBaseCost = 0;
                            object.transactionTime = "";
                            object.unit = "";
                            object.grossAmount = 0;
                            object.discountAmount = 0;
                        }
                        if (message.productId != null && message.hasOwnProperty("productId"))
                            if (typeof message.productId === "number")
                                object.productId = options.longs === String ? String(message.productId) : message.productId;
                            else
                                object.productId = options.longs === String ? $util.Long.prototype.toString.call(message.productId) : options.longs === Number ? new $util.LongBits(message.productId.low >>> 0, message.productId.high >>> 0).toNumber() : message.productId;
                        if (message.productName != null && message.hasOwnProperty("productName"))
                            object.productName = message.productName;
                        if (message.unitPrice != null && message.hasOwnProperty("unitPrice"))
                            object.unitPrice = options.json && !isFinite(message.unitPrice) ? String(message.unitPrice) : message.unitPrice;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            object.quantity = options.json && !isFinite(message.quantity) ? String(message.quantity) : message.quantity;
                        if (message.totalAmount != null && message.hasOwnProperty("totalAmount"))
                            object.totalAmount = options.json && !isFinite(message.totalAmount) ? String(message.totalAmount) : message.totalAmount;
                        if (message.metadata && message.metadata.length) {
                            object.metadata = [];
                            for (var j = 0; j < message.metadata.length; ++j)
                                object.metadata[j] = $root.palexy.streaming.v1.PosProductMetadata.toObject(message.metadata[j], options);
                        }
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            object.productCode = message.productCode;
                        if (message.productDescription != null && message.hasOwnProperty("productDescription"))
                            object.productDescription = message.productDescription;
                        if (message.netAmount != null && message.hasOwnProperty("netAmount"))
                            object.netAmount = options.json && !isFinite(message.netAmount) ? String(message.netAmount) : message.netAmount;
                        if (message.unitBaseCost != null && message.hasOwnProperty("unitBaseCost"))
                            object.unitBaseCost = options.json && !isFinite(message.unitBaseCost) ? String(message.unitBaseCost) : message.unitBaseCost;
                        if (message.transactionTime != null && message.hasOwnProperty("transactionTime"))
                            object.transactionTime = message.transactionTime;
                        if (message.unit != null && message.hasOwnProperty("unit"))
                            object.unit = message.unit;
                        if (message.grossAmount != null && message.hasOwnProperty("grossAmount"))
                            object.grossAmount = options.json && !isFinite(message.grossAmount) ? String(message.grossAmount) : message.grossAmount;
                        if (message.discountAmount != null && message.hasOwnProperty("discountAmount"))
                            object.discountAmount = options.json && !isFinite(message.discountAmount) ? String(message.discountAmount) : message.discountAmount;
                        return object;
                    };
    
                    /**
                     * Converts this PosTransactionProduct to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosTransactionProduct
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosTransactionProduct.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosTransactionProduct;
                })();
    
                v1.PosCustomerTransaction = (function() {
    
                    /**
                     * Properties of a PosCustomerTransaction.
                     * @memberof palexy.streaming.v1
                     * @interface IPosCustomerTransaction
                     * @property {number|null} [id] PosCustomerTransaction id
                     * @property {number|null} [posTransactionId] PosCustomerTransaction posTransactionId
                     * @property {string|null} [personId] PosCustomerTransaction personId
                     * @property {string|null} [recordingTime] PosCustomerTransaction recordingTime
                     */
    
                    /**
                     * Constructs a new PosCustomerTransaction.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosCustomerTransaction.
                     * @implements IPosCustomerTransaction
                     * @constructor
                     * @param {palexy.streaming.v1.IPosCustomerTransaction=} [properties] Properties to set
                     */
                    function PosCustomerTransaction(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosCustomerTransaction id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @instance
                     */
                    PosCustomerTransaction.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosCustomerTransaction posTransactionId.
                     * @member {number} posTransactionId
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @instance
                     */
                    PosCustomerTransaction.prototype.posTransactionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosCustomerTransaction personId.
                     * @member {string} personId
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @instance
                     */
                    PosCustomerTransaction.prototype.personId = "";
    
                    /**
                     * PosCustomerTransaction recordingTime.
                     * @member {string} recordingTime
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @instance
                     */
                    PosCustomerTransaction.prototype.recordingTime = "";
    
                    /**
                     * Creates a new PosCustomerTransaction instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @static
                     * @param {palexy.streaming.v1.IPosCustomerTransaction=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosCustomerTransaction} PosCustomerTransaction instance
                     */
                    PosCustomerTransaction.create = function create(properties) {
                        return new PosCustomerTransaction(properties);
                    };
    
                    /**
                     * Encodes the specified PosCustomerTransaction message. Does not implicitly {@link palexy.streaming.v1.PosCustomerTransaction.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @static
                     * @param {palexy.streaming.v1.IPosCustomerTransaction} message PosCustomerTransaction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosCustomerTransaction.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.posTransactionId != null && Object.hasOwnProperty.call(message, "posTransactionId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.posTransactionId);
                        if (message.personId != null && Object.hasOwnProperty.call(message, "personId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.personId);
                        if (message.recordingTime != null && Object.hasOwnProperty.call(message, "recordingTime"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.recordingTime);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosCustomerTransaction message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosCustomerTransaction.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @static
                     * @param {palexy.streaming.v1.IPosCustomerTransaction} message PosCustomerTransaction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosCustomerTransaction.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosCustomerTransaction message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosCustomerTransaction} PosCustomerTransaction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosCustomerTransaction.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosCustomerTransaction();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.posTransactionId = reader.int64();
                                break;
                            case 3:
                                message.personId = reader.string();
                                break;
                            case 4:
                                message.recordingTime = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosCustomerTransaction message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosCustomerTransaction} PosCustomerTransaction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosCustomerTransaction.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosCustomerTransaction message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosCustomerTransaction.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.posTransactionId != null && message.hasOwnProperty("posTransactionId"))
                            if (!$util.isInteger(message.posTransactionId) && !(message.posTransactionId && $util.isInteger(message.posTransactionId.low) && $util.isInteger(message.posTransactionId.high)))
                                return "posTransactionId: integer|Long expected";
                        if (message.personId != null && message.hasOwnProperty("personId"))
                            if (!$util.isString(message.personId))
                                return "personId: string expected";
                        if (message.recordingTime != null && message.hasOwnProperty("recordingTime"))
                            if (!$util.isString(message.recordingTime))
                                return "recordingTime: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a PosCustomerTransaction message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosCustomerTransaction} PosCustomerTransaction
                     */
                    PosCustomerTransaction.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosCustomerTransaction)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosCustomerTransaction();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.posTransactionId != null)
                            if ($util.Long)
                                (message.posTransactionId = $util.Long.fromValue(object.posTransactionId)).unsigned = false;
                            else if (typeof object.posTransactionId === "string")
                                message.posTransactionId = parseInt(object.posTransactionId, 10);
                            else if (typeof object.posTransactionId === "number")
                                message.posTransactionId = object.posTransactionId;
                            else if (typeof object.posTransactionId === "object")
                                message.posTransactionId = new $util.LongBits(object.posTransactionId.low >>> 0, object.posTransactionId.high >>> 0).toNumber();
                        if (object.personId != null)
                            message.personId = String(object.personId);
                        if (object.recordingTime != null)
                            message.recordingTime = String(object.recordingTime);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosCustomerTransaction message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @static
                     * @param {palexy.streaming.v1.PosCustomerTransaction} message PosCustomerTransaction
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosCustomerTransaction.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.posTransactionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.posTransactionId = options.longs === String ? "0" : 0;
                            object.personId = "";
                            object.recordingTime = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.posTransactionId != null && message.hasOwnProperty("posTransactionId"))
                            if (typeof message.posTransactionId === "number")
                                object.posTransactionId = options.longs === String ? String(message.posTransactionId) : message.posTransactionId;
                            else
                                object.posTransactionId = options.longs === String ? $util.Long.prototype.toString.call(message.posTransactionId) : options.longs === Number ? new $util.LongBits(message.posTransactionId.low >>> 0, message.posTransactionId.high >>> 0).toNumber() : message.posTransactionId;
                        if (message.personId != null && message.hasOwnProperty("personId"))
                            object.personId = message.personId;
                        if (message.recordingTime != null && message.hasOwnProperty("recordingTime"))
                            object.recordingTime = message.recordingTime;
                        return object;
                    };
    
                    /**
                     * Converts this PosCustomerTransaction to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosCustomerTransaction
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosCustomerTransaction.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosCustomerTransaction;
                })();
    
                v1.ImportResult = (function() {
    
                    /**
                     * Properties of an ImportResult.
                     * @memberof palexy.streaming.v1
                     * @interface IImportResult
                     * @property {number|null} [totalElements] ImportResult totalElements
                     * @property {number|null} [deletedElements] ImportResult deletedElements
                     * @property {number|null} [createdElements] ImportResult createdElements
                     */
    
                    /**
                     * Constructs a new ImportResult.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ImportResult.
                     * @implements IImportResult
                     * @constructor
                     * @param {palexy.streaming.v1.IImportResult=} [properties] Properties to set
                     */
                    function ImportResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ImportResult totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ImportResult
                     * @instance
                     */
                    ImportResult.prototype.totalElements = 0;
    
                    /**
                     * ImportResult deletedElements.
                     * @member {number} deletedElements
                     * @memberof palexy.streaming.v1.ImportResult
                     * @instance
                     */
                    ImportResult.prototype.deletedElements = 0;
    
                    /**
                     * ImportResult createdElements.
                     * @member {number} createdElements
                     * @memberof palexy.streaming.v1.ImportResult
                     * @instance
                     */
                    ImportResult.prototype.createdElements = 0;
    
                    /**
                     * Creates a new ImportResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ImportResult
                     * @static
                     * @param {palexy.streaming.v1.IImportResult=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ImportResult} ImportResult instance
                     */
                    ImportResult.create = function create(properties) {
                        return new ImportResult(properties);
                    };
    
                    /**
                     * Encodes the specified ImportResult message. Does not implicitly {@link palexy.streaming.v1.ImportResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ImportResult
                     * @static
                     * @param {palexy.streaming.v1.IImportResult} message ImportResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.totalElements);
                        if (message.deletedElements != null && Object.hasOwnProperty.call(message, "deletedElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.deletedElements);
                        if (message.createdElements != null && Object.hasOwnProperty.call(message, "createdElements"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.createdElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ImportResult message, length delimited. Does not implicitly {@link palexy.streaming.v1.ImportResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ImportResult
                     * @static
                     * @param {palexy.streaming.v1.IImportResult} message ImportResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ImportResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ImportResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ImportResult} ImportResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ImportResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.totalElements = reader.int32();
                                break;
                            case 2:
                                message.deletedElements = reader.int32();
                                break;
                            case 3:
                                message.createdElements = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ImportResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ImportResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ImportResult} ImportResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ImportResult message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ImportResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImportResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.deletedElements != null && message.hasOwnProperty("deletedElements"))
                            if (!$util.isInteger(message.deletedElements))
                                return "deletedElements: integer expected";
                        if (message.createdElements != null && message.hasOwnProperty("createdElements"))
                            if (!$util.isInteger(message.createdElements))
                                return "createdElements: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an ImportResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ImportResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ImportResult} ImportResult
                     */
                    ImportResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ImportResult)
                            return object;
                        var message = new $root.palexy.streaming.v1.ImportResult();
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.deletedElements != null)
                            message.deletedElements = object.deletedElements | 0;
                        if (object.createdElements != null)
                            message.createdElements = object.createdElements | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ImportResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ImportResult
                     * @static
                     * @param {palexy.streaming.v1.ImportResult} message ImportResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImportResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.deletedElements = 0;
                            object.createdElements = 0;
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.deletedElements != null && message.hasOwnProperty("deletedElements"))
                            object.deletedElements = message.deletedElements;
                        if (message.createdElements != null && message.hasOwnProperty("createdElements"))
                            object.createdElements = message.createdElements;
                        return object;
                    };
    
                    /**
                     * Converts this ImportResult to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ImportResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImportResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ImportResult;
                })();
    
                v1.PosImportConfig = (function() {
    
                    /**
                     * Properties of a PosImportConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IPosImportConfig
                     * @property {number|null} [id] PosImportConfig id
                     * @property {number|null} [companyId] PosImportConfig companyId
                     * @property {palexy.streaming.v1.PosImportConfig.ImportType|null} [importType] PosImportConfig importType
                     * @property {number|null} [lastImportTimeMs] PosImportConfig lastImportTimeMs
                     * @property {boolean|null} [generateTransactionId] PosImportConfig generateTransactionId
                     * @property {string|null} [storeCodeHeader] PosImportConfig storeCodeHeader
                     * @property {string|null} [productNameHeader] PosImportConfig productNameHeader
                     * @property {string|null} [productCodeHeader] PosImportConfig productCodeHeader
                     * @property {string|null} [productDescriptionHeader] PosImportConfig productDescriptionHeader
                     * @property {string|null} [unitPriceHeader] PosImportConfig unitPriceHeader
                     * @property {string|null} [quantityHeader] PosImportConfig quantityHeader
                     * @property {string|null} [totalAmountHeader] PosImportConfig totalAmountHeader
                     * @property {string|null} [terminalIdHeader] PosImportConfig terminalIdHeader
                     * @property {string|null} [transactionIdHeader] PosImportConfig transactionIdHeader
                     * @property {string|null} [currencyHeader] PosImportConfig currencyHeader
                     * @property {string|null} [transactionDateHeader] PosImportConfig transactionDateHeader
                     * @property {string|null} [transactionTimeHeader] PosImportConfig transactionTimeHeader
                     * @property {string|null} [membershipCardHeader] PosImportConfig membershipCardHeader
                     * @property {string|null} [customerIdHeader] PosImportConfig customerIdHeader
                     * @property {string|null} [salesStaffIdHeader] PosImportConfig salesStaffIdHeader
                     * @property {string|null} [netAmountHeader] PosImportConfig netAmountHeader
                     * @property {string|null} [unitBaseCostHeader] PosImportConfig unitBaseCostHeader
                     * @property {boolean|null} [zeroValueRecordsIgnored] PosImportConfig zeroValueRecordsIgnored
                     * @property {Array.<palexy.streaming.v1.PosImportConfig.IExclusionMetadata>|null} [exclusionMetadata] PosImportConfig exclusionMetadata
                     * @property {Object.<string,string>|null} [additionalPropertiesHeader] PosImportConfig additionalPropertiesHeader
                     * @property {string|null} [unitHeader] PosImportConfig unitHeader
                     * @property {string|null} [transactionDateFormat] PosImportConfig transactionDateFormat
                     * @property {string|null} [transactionTimeFormat] PosImportConfig transactionTimeFormat
                     * @property {boolean|null} [negateQuantity] PosImportConfig negateQuantity
                     * @property {boolean|null} [negateAmount] PosImportConfig negateAmount
                     * @property {string|null} [delimiter] PosImportConfig delimiter
                     * @property {boolean|null} [allowEmptyProduct] PosImportConfig allowEmptyProduct
                     * @property {palexy.streaming.v1.IPosStaffPropertiesHeaderList|null} [posStaffPropertiesHeaderList] PosImportConfig posStaffPropertiesHeaderList
                     * @property {string|null} [grossAmountHeader] PosImportConfig grossAmountHeader
                     * @property {string|null} [discountAmountHeader] PosImportConfig discountAmountHeader
                     * @property {string|null} [timezone] PosImportConfig timezone
                     * @property {string|null} [posApiUsername] PosImportConfig posApiUsername
                     * @property {string|null} [posApiPassword] PosImportConfig posApiPassword
                     * @property {string|null} [posApiIntegrationKey] PosImportConfig posApiIntegrationKey
                     * @property {string|null} [posApiBaseUrl] PosImportConfig posApiBaseUrl
                     * @property {string|null} [intradayUpdateTimeString] PosImportConfig intradayUpdateTimeString
                     * @property {number|null} [rewriteHistoricalDays] PosImportConfig rewriteHistoricalDays
                     * @property {string|null} [yesterdayUpdateTimeString] PosImportConfig yesterdayUpdateTimeString
                     * @property {string|null} [companyName] PosImportConfig companyName
                     * @property {string|null} [companyCode] PosImportConfig companyCode
                     * @property {boolean|null} [posStaffSyncEnabled] PosImportConfig posStaffSyncEnabled
                     * @property {palexy.streaming.v1.IAsyncJobStatusSummary|null} [manualJobStatusSummary] PosImportConfig manualJobStatusSummary
                     */
    
                    /**
                     * Constructs a new PosImportConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosImportConfig.
                     * @implements IPosImportConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IPosImportConfig=} [properties] Properties to set
                     */
                    function PosImportConfig(properties) {
                        this.exclusionMetadata = [];
                        this.additionalPropertiesHeader = {};
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosImportConfig id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosImportConfig companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosImportConfig importType.
                     * @member {palexy.streaming.v1.PosImportConfig.ImportType} importType
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.importType = 0;
    
                    /**
                     * PosImportConfig lastImportTimeMs.
                     * @member {number} lastImportTimeMs
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.lastImportTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosImportConfig generateTransactionId.
                     * @member {boolean} generateTransactionId
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.generateTransactionId = false;
    
                    /**
                     * PosImportConfig storeCodeHeader.
                     * @member {string} storeCodeHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.storeCodeHeader = "";
    
                    /**
                     * PosImportConfig productNameHeader.
                     * @member {string} productNameHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.productNameHeader = "";
    
                    /**
                     * PosImportConfig productCodeHeader.
                     * @member {string} productCodeHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.productCodeHeader = "";
    
                    /**
                     * PosImportConfig productDescriptionHeader.
                     * @member {string} productDescriptionHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.productDescriptionHeader = "";
    
                    /**
                     * PosImportConfig unitPriceHeader.
                     * @member {string} unitPriceHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.unitPriceHeader = "";
    
                    /**
                     * PosImportConfig quantityHeader.
                     * @member {string} quantityHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.quantityHeader = "";
    
                    /**
                     * PosImportConfig totalAmountHeader.
                     * @member {string} totalAmountHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.totalAmountHeader = "";
    
                    /**
                     * PosImportConfig terminalIdHeader.
                     * @member {string} terminalIdHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.terminalIdHeader = "";
    
                    /**
                     * PosImportConfig transactionIdHeader.
                     * @member {string} transactionIdHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.transactionIdHeader = "";
    
                    /**
                     * PosImportConfig currencyHeader.
                     * @member {string} currencyHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.currencyHeader = "";
    
                    /**
                     * PosImportConfig transactionDateHeader.
                     * @member {string} transactionDateHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.transactionDateHeader = "";
    
                    /**
                     * PosImportConfig transactionTimeHeader.
                     * @member {string} transactionTimeHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.transactionTimeHeader = "";
    
                    /**
                     * PosImportConfig membershipCardHeader.
                     * @member {string} membershipCardHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.membershipCardHeader = "";
    
                    /**
                     * PosImportConfig customerIdHeader.
                     * @member {string} customerIdHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.customerIdHeader = "";
    
                    /**
                     * PosImportConfig salesStaffIdHeader.
                     * @member {string} salesStaffIdHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.salesStaffIdHeader = "";
    
                    /**
                     * PosImportConfig netAmountHeader.
                     * @member {string} netAmountHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.netAmountHeader = "";
    
                    /**
                     * PosImportConfig unitBaseCostHeader.
                     * @member {string} unitBaseCostHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.unitBaseCostHeader = "";
    
                    /**
                     * PosImportConfig zeroValueRecordsIgnored.
                     * @member {boolean} zeroValueRecordsIgnored
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.zeroValueRecordsIgnored = false;
    
                    /**
                     * PosImportConfig exclusionMetadata.
                     * @member {Array.<palexy.streaming.v1.PosImportConfig.IExclusionMetadata>} exclusionMetadata
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.exclusionMetadata = $util.emptyArray;
    
                    /**
                     * PosImportConfig additionalPropertiesHeader.
                     * @member {Object.<string,string>} additionalPropertiesHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.additionalPropertiesHeader = $util.emptyObject;
    
                    /**
                     * PosImportConfig unitHeader.
                     * @member {string} unitHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.unitHeader = "";
    
                    /**
                     * PosImportConfig transactionDateFormat.
                     * @member {string} transactionDateFormat
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.transactionDateFormat = "";
    
                    /**
                     * PosImportConfig transactionTimeFormat.
                     * @member {string} transactionTimeFormat
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.transactionTimeFormat = "";
    
                    /**
                     * PosImportConfig negateQuantity.
                     * @member {boolean} negateQuantity
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.negateQuantity = false;
    
                    /**
                     * PosImportConfig negateAmount.
                     * @member {boolean} negateAmount
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.negateAmount = false;
    
                    /**
                     * PosImportConfig delimiter.
                     * @member {string} delimiter
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.delimiter = "";
    
                    /**
                     * PosImportConfig allowEmptyProduct.
                     * @member {boolean} allowEmptyProduct
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.allowEmptyProduct = false;
    
                    /**
                     * PosImportConfig posStaffPropertiesHeaderList.
                     * @member {palexy.streaming.v1.IPosStaffPropertiesHeaderList|null|undefined} posStaffPropertiesHeaderList
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.posStaffPropertiesHeaderList = null;
    
                    /**
                     * PosImportConfig grossAmountHeader.
                     * @member {string} grossAmountHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.grossAmountHeader = "";
    
                    /**
                     * PosImportConfig discountAmountHeader.
                     * @member {string} discountAmountHeader
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.discountAmountHeader = "";
    
                    /**
                     * PosImportConfig timezone.
                     * @member {string} timezone
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.timezone = "";
    
                    /**
                     * PosImportConfig posApiUsername.
                     * @member {string} posApiUsername
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.posApiUsername = "";
    
                    /**
                     * PosImportConfig posApiPassword.
                     * @member {string} posApiPassword
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.posApiPassword = "";
    
                    /**
                     * PosImportConfig posApiIntegrationKey.
                     * @member {string} posApiIntegrationKey
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.posApiIntegrationKey = "";
    
                    /**
                     * PosImportConfig posApiBaseUrl.
                     * @member {string} posApiBaseUrl
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.posApiBaseUrl = "";
    
                    /**
                     * PosImportConfig intradayUpdateTimeString.
                     * @member {string} intradayUpdateTimeString
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.intradayUpdateTimeString = "";
    
                    /**
                     * PosImportConfig rewriteHistoricalDays.
                     * @member {number} rewriteHistoricalDays
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.rewriteHistoricalDays = 0;
    
                    /**
                     * PosImportConfig yesterdayUpdateTimeString.
                     * @member {string} yesterdayUpdateTimeString
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.yesterdayUpdateTimeString = "";
    
                    /**
                     * PosImportConfig companyName.
                     * @member {string} companyName
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.companyName = "";
    
                    /**
                     * PosImportConfig companyCode.
                     * @member {string} companyCode
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.companyCode = "";
    
                    /**
                     * PosImportConfig posStaffSyncEnabled.
                     * @member {boolean} posStaffSyncEnabled
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.posStaffSyncEnabled = false;
    
                    /**
                     * PosImportConfig manualJobStatusSummary.
                     * @member {palexy.streaming.v1.IAsyncJobStatusSummary|null|undefined} manualJobStatusSummary
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     */
                    PosImportConfig.prototype.manualJobStatusSummary = null;
    
                    /**
                     * Creates a new PosImportConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @static
                     * @param {palexy.streaming.v1.IPosImportConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosImportConfig} PosImportConfig instance
                     */
                    PosImportConfig.create = function create(properties) {
                        return new PosImportConfig(properties);
                    };
    
                    /**
                     * Encodes the specified PosImportConfig message. Does not implicitly {@link palexy.streaming.v1.PosImportConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @static
                     * @param {palexy.streaming.v1.IPosImportConfig} message PosImportConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosImportConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.generateTransactionId != null && Object.hasOwnProperty.call(message, "generateTransactionId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.generateTransactionId);
                        if (message.storeCodeHeader != null && Object.hasOwnProperty.call(message, "storeCodeHeader"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.storeCodeHeader);
                        if (message.productNameHeader != null && Object.hasOwnProperty.call(message, "productNameHeader"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.productNameHeader);
                        if (message.productCodeHeader != null && Object.hasOwnProperty.call(message, "productCodeHeader"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.productCodeHeader);
                        if (message.productDescriptionHeader != null && Object.hasOwnProperty.call(message, "productDescriptionHeader"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.productDescriptionHeader);
                        if (message.unitPriceHeader != null && Object.hasOwnProperty.call(message, "unitPriceHeader"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.unitPriceHeader);
                        if (message.quantityHeader != null && Object.hasOwnProperty.call(message, "quantityHeader"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.quantityHeader);
                        if (message.totalAmountHeader != null && Object.hasOwnProperty.call(message, "totalAmountHeader"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.totalAmountHeader);
                        if (message.terminalIdHeader != null && Object.hasOwnProperty.call(message, "terminalIdHeader"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.terminalIdHeader);
                        if (message.transactionIdHeader != null && Object.hasOwnProperty.call(message, "transactionIdHeader"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.transactionIdHeader);
                        if (message.currencyHeader != null && Object.hasOwnProperty.call(message, "currencyHeader"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.currencyHeader);
                        if (message.transactionDateHeader != null && Object.hasOwnProperty.call(message, "transactionDateHeader"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.transactionDateHeader);
                        if (message.transactionTimeHeader != null && Object.hasOwnProperty.call(message, "transactionTimeHeader"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.transactionTimeHeader);
                        if (message.companyName != null && Object.hasOwnProperty.call(message, "companyName"))
                            writer.uint32(/* id 16, wireType 2 =*/130).string(message.companyName);
                        if (message.membershipCardHeader != null && Object.hasOwnProperty.call(message, "membershipCardHeader"))
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.membershipCardHeader);
                        if (message.importType != null && Object.hasOwnProperty.call(message, "importType"))
                            writer.uint32(/* id 18, wireType 0 =*/144).int32(message.importType);
                        if (message.lastImportTimeMs != null && Object.hasOwnProperty.call(message, "lastImportTimeMs"))
                            writer.uint32(/* id 19, wireType 0 =*/152).int64(message.lastImportTimeMs);
                        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
                            writer.uint32(/* id 20, wireType 2 =*/162).string(message.timezone);
                        if (message.companyCode != null && Object.hasOwnProperty.call(message, "companyCode"))
                            writer.uint32(/* id 21, wireType 2 =*/170).string(message.companyCode);
                        if (message.posApiUsername != null && Object.hasOwnProperty.call(message, "posApiUsername"))
                            writer.uint32(/* id 22, wireType 2 =*/178).string(message.posApiUsername);
                        if (message.posApiPassword != null && Object.hasOwnProperty.call(message, "posApiPassword"))
                            writer.uint32(/* id 23, wireType 2 =*/186).string(message.posApiPassword);
                        if (message.posApiIntegrationKey != null && Object.hasOwnProperty.call(message, "posApiIntegrationKey"))
                            writer.uint32(/* id 24, wireType 2 =*/194).string(message.posApiIntegrationKey);
                        if (message.posApiBaseUrl != null && Object.hasOwnProperty.call(message, "posApiBaseUrl"))
                            writer.uint32(/* id 25, wireType 2 =*/202).string(message.posApiBaseUrl);
                        if (message.customerIdHeader != null && Object.hasOwnProperty.call(message, "customerIdHeader"))
                            writer.uint32(/* id 26, wireType 2 =*/210).string(message.customerIdHeader);
                        if (message.salesStaffIdHeader != null && Object.hasOwnProperty.call(message, "salesStaffIdHeader"))
                            writer.uint32(/* id 27, wireType 2 =*/218).string(message.salesStaffIdHeader);
                        if (message.netAmountHeader != null && Object.hasOwnProperty.call(message, "netAmountHeader"))
                            writer.uint32(/* id 28, wireType 2 =*/226).string(message.netAmountHeader);
                        if (message.unitBaseCostHeader != null && Object.hasOwnProperty.call(message, "unitBaseCostHeader"))
                            writer.uint32(/* id 29, wireType 2 =*/234).string(message.unitBaseCostHeader);
                        if (message.intradayUpdateTimeString != null && Object.hasOwnProperty.call(message, "intradayUpdateTimeString"))
                            writer.uint32(/* id 30, wireType 2 =*/242).string(message.intradayUpdateTimeString);
                        if (message.rewriteHistoricalDays != null && Object.hasOwnProperty.call(message, "rewriteHistoricalDays"))
                            writer.uint32(/* id 31, wireType 0 =*/248).int32(message.rewriteHistoricalDays);
                        if (message.yesterdayUpdateTimeString != null && Object.hasOwnProperty.call(message, "yesterdayUpdateTimeString"))
                            writer.uint32(/* id 32, wireType 2 =*/258).string(message.yesterdayUpdateTimeString);
                        if (message.zeroValueRecordsIgnored != null && Object.hasOwnProperty.call(message, "zeroValueRecordsIgnored"))
                            writer.uint32(/* id 33, wireType 0 =*/264).bool(message.zeroValueRecordsIgnored);
                        if (message.exclusionMetadata != null && message.exclusionMetadata.length)
                            for (var i = 0; i < message.exclusionMetadata.length; ++i)
                                $root.palexy.streaming.v1.PosImportConfig.ExclusionMetadata.encode(message.exclusionMetadata[i], writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                        if (message.additionalPropertiesHeader != null && Object.hasOwnProperty.call(message, "additionalPropertiesHeader"))
                            for (var keys = Object.keys(message.additionalPropertiesHeader), i = 0; i < keys.length; ++i)
                                writer.uint32(/* id 35, wireType 2 =*/282).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.additionalPropertiesHeader[keys[i]]).ldelim();
                        if (message.unitHeader != null && Object.hasOwnProperty.call(message, "unitHeader"))
                            writer.uint32(/* id 36, wireType 2 =*/290).string(message.unitHeader);
                        if (message.transactionDateFormat != null && Object.hasOwnProperty.call(message, "transactionDateFormat"))
                            writer.uint32(/* id 37, wireType 2 =*/298).string(message.transactionDateFormat);
                        if (message.transactionTimeFormat != null && Object.hasOwnProperty.call(message, "transactionTimeFormat"))
                            writer.uint32(/* id 38, wireType 2 =*/306).string(message.transactionTimeFormat);
                        if (message.negateQuantity != null && Object.hasOwnProperty.call(message, "negateQuantity"))
                            writer.uint32(/* id 39, wireType 0 =*/312).bool(message.negateQuantity);
                        if (message.negateAmount != null && Object.hasOwnProperty.call(message, "negateAmount"))
                            writer.uint32(/* id 40, wireType 0 =*/320).bool(message.negateAmount);
                        if (message.manualJobStatusSummary != null && Object.hasOwnProperty.call(message, "manualJobStatusSummary"))
                            $root.palexy.streaming.v1.AsyncJobStatusSummary.encode(message.manualJobStatusSummary, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
                        if (message.delimiter != null && Object.hasOwnProperty.call(message, "delimiter"))
                            writer.uint32(/* id 42, wireType 2 =*/338).string(message.delimiter);
                        if (message.allowEmptyProduct != null && Object.hasOwnProperty.call(message, "allowEmptyProduct"))
                            writer.uint32(/* id 43, wireType 0 =*/344).bool(message.allowEmptyProduct);
                        if (message.posStaffSyncEnabled != null && Object.hasOwnProperty.call(message, "posStaffSyncEnabled"))
                            writer.uint32(/* id 44, wireType 0 =*/352).bool(message.posStaffSyncEnabled);
                        if (message.posStaffPropertiesHeaderList != null && Object.hasOwnProperty.call(message, "posStaffPropertiesHeaderList"))
                            $root.palexy.streaming.v1.PosStaffPropertiesHeaderList.encode(message.posStaffPropertiesHeaderList, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
                        if (message.grossAmountHeader != null && Object.hasOwnProperty.call(message, "grossAmountHeader"))
                            writer.uint32(/* id 46, wireType 2 =*/370).string(message.grossAmountHeader);
                        if (message.discountAmountHeader != null && Object.hasOwnProperty.call(message, "discountAmountHeader"))
                            writer.uint32(/* id 47, wireType 2 =*/378).string(message.discountAmountHeader);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosImportConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosImportConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @static
                     * @param {palexy.streaming.v1.IPosImportConfig} message PosImportConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosImportConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosImportConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosImportConfig} PosImportConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosImportConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosImportConfig(), key, value;
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 18:
                                message.importType = reader.int32();
                                break;
                            case 19:
                                message.lastImportTimeMs = reader.int64();
                                break;
                            case 3:
                                message.generateTransactionId = reader.bool();
                                break;
                            case 4:
                                message.storeCodeHeader = reader.string();
                                break;
                            case 5:
                                message.productNameHeader = reader.string();
                                break;
                            case 6:
                                message.productCodeHeader = reader.string();
                                break;
                            case 7:
                                message.productDescriptionHeader = reader.string();
                                break;
                            case 8:
                                message.unitPriceHeader = reader.string();
                                break;
                            case 9:
                                message.quantityHeader = reader.string();
                                break;
                            case 10:
                                message.totalAmountHeader = reader.string();
                                break;
                            case 11:
                                message.terminalIdHeader = reader.string();
                                break;
                            case 12:
                                message.transactionIdHeader = reader.string();
                                break;
                            case 13:
                                message.currencyHeader = reader.string();
                                break;
                            case 14:
                                message.transactionDateHeader = reader.string();
                                break;
                            case 15:
                                message.transactionTimeHeader = reader.string();
                                break;
                            case 17:
                                message.membershipCardHeader = reader.string();
                                break;
                            case 26:
                                message.customerIdHeader = reader.string();
                                break;
                            case 27:
                                message.salesStaffIdHeader = reader.string();
                                break;
                            case 28:
                                message.netAmountHeader = reader.string();
                                break;
                            case 29:
                                message.unitBaseCostHeader = reader.string();
                                break;
                            case 33:
                                message.zeroValueRecordsIgnored = reader.bool();
                                break;
                            case 34:
                                if (!(message.exclusionMetadata && message.exclusionMetadata.length))
                                    message.exclusionMetadata = [];
                                message.exclusionMetadata.push($root.palexy.streaming.v1.PosImportConfig.ExclusionMetadata.decode(reader, reader.uint32()));
                                break;
                            case 35:
                                if (message.additionalPropertiesHeader === $util.emptyObject)
                                    message.additionalPropertiesHeader = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.additionalPropertiesHeader[key] = value;
                                break;
                            case 36:
                                message.unitHeader = reader.string();
                                break;
                            case 37:
                                message.transactionDateFormat = reader.string();
                                break;
                            case 38:
                                message.transactionTimeFormat = reader.string();
                                break;
                            case 39:
                                message.negateQuantity = reader.bool();
                                break;
                            case 40:
                                message.negateAmount = reader.bool();
                                break;
                            case 42:
                                message.delimiter = reader.string();
                                break;
                            case 43:
                                message.allowEmptyProduct = reader.bool();
                                break;
                            case 45:
                                message.posStaffPropertiesHeaderList = $root.palexy.streaming.v1.PosStaffPropertiesHeaderList.decode(reader, reader.uint32());
                                break;
                            case 46:
                                message.grossAmountHeader = reader.string();
                                break;
                            case 47:
                                message.discountAmountHeader = reader.string();
                                break;
                            case 20:
                                message.timezone = reader.string();
                                break;
                            case 22:
                                message.posApiUsername = reader.string();
                                break;
                            case 23:
                                message.posApiPassword = reader.string();
                                break;
                            case 24:
                                message.posApiIntegrationKey = reader.string();
                                break;
                            case 25:
                                message.posApiBaseUrl = reader.string();
                                break;
                            case 30:
                                message.intradayUpdateTimeString = reader.string();
                                break;
                            case 31:
                                message.rewriteHistoricalDays = reader.int32();
                                break;
                            case 32:
                                message.yesterdayUpdateTimeString = reader.string();
                                break;
                            case 16:
                                message.companyName = reader.string();
                                break;
                            case 21:
                                message.companyCode = reader.string();
                                break;
                            case 44:
                                message.posStaffSyncEnabled = reader.bool();
                                break;
                            case 41:
                                message.manualJobStatusSummary = $root.palexy.streaming.v1.AsyncJobStatusSummary.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosImportConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosImportConfig} PosImportConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosImportConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosImportConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosImportConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.importType != null && message.hasOwnProperty("importType"))
                            switch (message.importType) {
                            default:
                                return "importType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.lastImportTimeMs != null && message.hasOwnProperty("lastImportTimeMs"))
                            if (!$util.isInteger(message.lastImportTimeMs) && !(message.lastImportTimeMs && $util.isInteger(message.lastImportTimeMs.low) && $util.isInteger(message.lastImportTimeMs.high)))
                                return "lastImportTimeMs: integer|Long expected";
                        if (message.generateTransactionId != null && message.hasOwnProperty("generateTransactionId"))
                            if (typeof message.generateTransactionId !== "boolean")
                                return "generateTransactionId: boolean expected";
                        if (message.storeCodeHeader != null && message.hasOwnProperty("storeCodeHeader"))
                            if (!$util.isString(message.storeCodeHeader))
                                return "storeCodeHeader: string expected";
                        if (message.productNameHeader != null && message.hasOwnProperty("productNameHeader"))
                            if (!$util.isString(message.productNameHeader))
                                return "productNameHeader: string expected";
                        if (message.productCodeHeader != null && message.hasOwnProperty("productCodeHeader"))
                            if (!$util.isString(message.productCodeHeader))
                                return "productCodeHeader: string expected";
                        if (message.productDescriptionHeader != null && message.hasOwnProperty("productDescriptionHeader"))
                            if (!$util.isString(message.productDescriptionHeader))
                                return "productDescriptionHeader: string expected";
                        if (message.unitPriceHeader != null && message.hasOwnProperty("unitPriceHeader"))
                            if (!$util.isString(message.unitPriceHeader))
                                return "unitPriceHeader: string expected";
                        if (message.quantityHeader != null && message.hasOwnProperty("quantityHeader"))
                            if (!$util.isString(message.quantityHeader))
                                return "quantityHeader: string expected";
                        if (message.totalAmountHeader != null && message.hasOwnProperty("totalAmountHeader"))
                            if (!$util.isString(message.totalAmountHeader))
                                return "totalAmountHeader: string expected";
                        if (message.terminalIdHeader != null && message.hasOwnProperty("terminalIdHeader"))
                            if (!$util.isString(message.terminalIdHeader))
                                return "terminalIdHeader: string expected";
                        if (message.transactionIdHeader != null && message.hasOwnProperty("transactionIdHeader"))
                            if (!$util.isString(message.transactionIdHeader))
                                return "transactionIdHeader: string expected";
                        if (message.currencyHeader != null && message.hasOwnProperty("currencyHeader"))
                            if (!$util.isString(message.currencyHeader))
                                return "currencyHeader: string expected";
                        if (message.transactionDateHeader != null && message.hasOwnProperty("transactionDateHeader"))
                            if (!$util.isString(message.transactionDateHeader))
                                return "transactionDateHeader: string expected";
                        if (message.transactionTimeHeader != null && message.hasOwnProperty("transactionTimeHeader"))
                            if (!$util.isString(message.transactionTimeHeader))
                                return "transactionTimeHeader: string expected";
                        if (message.membershipCardHeader != null && message.hasOwnProperty("membershipCardHeader"))
                            if (!$util.isString(message.membershipCardHeader))
                                return "membershipCardHeader: string expected";
                        if (message.customerIdHeader != null && message.hasOwnProperty("customerIdHeader"))
                            if (!$util.isString(message.customerIdHeader))
                                return "customerIdHeader: string expected";
                        if (message.salesStaffIdHeader != null && message.hasOwnProperty("salesStaffIdHeader"))
                            if (!$util.isString(message.salesStaffIdHeader))
                                return "salesStaffIdHeader: string expected";
                        if (message.netAmountHeader != null && message.hasOwnProperty("netAmountHeader"))
                            if (!$util.isString(message.netAmountHeader))
                                return "netAmountHeader: string expected";
                        if (message.unitBaseCostHeader != null && message.hasOwnProperty("unitBaseCostHeader"))
                            if (!$util.isString(message.unitBaseCostHeader))
                                return "unitBaseCostHeader: string expected";
                        if (message.zeroValueRecordsIgnored != null && message.hasOwnProperty("zeroValueRecordsIgnored"))
                            if (typeof message.zeroValueRecordsIgnored !== "boolean")
                                return "zeroValueRecordsIgnored: boolean expected";
                        if (message.exclusionMetadata != null && message.hasOwnProperty("exclusionMetadata")) {
                            if (!Array.isArray(message.exclusionMetadata))
                                return "exclusionMetadata: array expected";
                            for (var i = 0; i < message.exclusionMetadata.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosImportConfig.ExclusionMetadata.verify(message.exclusionMetadata[i]);
                                if (error)
                                    return "exclusionMetadata." + error;
                            }
                        }
                        if (message.additionalPropertiesHeader != null && message.hasOwnProperty("additionalPropertiesHeader")) {
                            if (!$util.isObject(message.additionalPropertiesHeader))
                                return "additionalPropertiesHeader: object expected";
                            var key = Object.keys(message.additionalPropertiesHeader);
                            for (var i = 0; i < key.length; ++i)
                                if (!$util.isString(message.additionalPropertiesHeader[key[i]]))
                                    return "additionalPropertiesHeader: string{k:string} expected";
                        }
                        if (message.unitHeader != null && message.hasOwnProperty("unitHeader"))
                            if (!$util.isString(message.unitHeader))
                                return "unitHeader: string expected";
                        if (message.transactionDateFormat != null && message.hasOwnProperty("transactionDateFormat"))
                            if (!$util.isString(message.transactionDateFormat))
                                return "transactionDateFormat: string expected";
                        if (message.transactionTimeFormat != null && message.hasOwnProperty("transactionTimeFormat"))
                            if (!$util.isString(message.transactionTimeFormat))
                                return "transactionTimeFormat: string expected";
                        if (message.negateQuantity != null && message.hasOwnProperty("negateQuantity"))
                            if (typeof message.negateQuantity !== "boolean")
                                return "negateQuantity: boolean expected";
                        if (message.negateAmount != null && message.hasOwnProperty("negateAmount"))
                            if (typeof message.negateAmount !== "boolean")
                                return "negateAmount: boolean expected";
                        if (message.delimiter != null && message.hasOwnProperty("delimiter"))
                            if (!$util.isString(message.delimiter))
                                return "delimiter: string expected";
                        if (message.allowEmptyProduct != null && message.hasOwnProperty("allowEmptyProduct"))
                            if (typeof message.allowEmptyProduct !== "boolean")
                                return "allowEmptyProduct: boolean expected";
                        if (message.posStaffPropertiesHeaderList != null && message.hasOwnProperty("posStaffPropertiesHeaderList")) {
                            var error = $root.palexy.streaming.v1.PosStaffPropertiesHeaderList.verify(message.posStaffPropertiesHeaderList);
                            if (error)
                                return "posStaffPropertiesHeaderList." + error;
                        }
                        if (message.grossAmountHeader != null && message.hasOwnProperty("grossAmountHeader"))
                            if (!$util.isString(message.grossAmountHeader))
                                return "grossAmountHeader: string expected";
                        if (message.discountAmountHeader != null && message.hasOwnProperty("discountAmountHeader"))
                            if (!$util.isString(message.discountAmountHeader))
                                return "discountAmountHeader: string expected";
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            if (!$util.isString(message.timezone))
                                return "timezone: string expected";
                        if (message.posApiUsername != null && message.hasOwnProperty("posApiUsername"))
                            if (!$util.isString(message.posApiUsername))
                                return "posApiUsername: string expected";
                        if (message.posApiPassword != null && message.hasOwnProperty("posApiPassword"))
                            if (!$util.isString(message.posApiPassword))
                                return "posApiPassword: string expected";
                        if (message.posApiIntegrationKey != null && message.hasOwnProperty("posApiIntegrationKey"))
                            if (!$util.isString(message.posApiIntegrationKey))
                                return "posApiIntegrationKey: string expected";
                        if (message.posApiBaseUrl != null && message.hasOwnProperty("posApiBaseUrl"))
                            if (!$util.isString(message.posApiBaseUrl))
                                return "posApiBaseUrl: string expected";
                        if (message.intradayUpdateTimeString != null && message.hasOwnProperty("intradayUpdateTimeString"))
                            if (!$util.isString(message.intradayUpdateTimeString))
                                return "intradayUpdateTimeString: string expected";
                        if (message.rewriteHistoricalDays != null && message.hasOwnProperty("rewriteHistoricalDays"))
                            if (!$util.isInteger(message.rewriteHistoricalDays))
                                return "rewriteHistoricalDays: integer expected";
                        if (message.yesterdayUpdateTimeString != null && message.hasOwnProperty("yesterdayUpdateTimeString"))
                            if (!$util.isString(message.yesterdayUpdateTimeString))
                                return "yesterdayUpdateTimeString: string expected";
                        if (message.companyName != null && message.hasOwnProperty("companyName"))
                            if (!$util.isString(message.companyName))
                                return "companyName: string expected";
                        if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                            if (!$util.isString(message.companyCode))
                                return "companyCode: string expected";
                        if (message.posStaffSyncEnabled != null && message.hasOwnProperty("posStaffSyncEnabled"))
                            if (typeof message.posStaffSyncEnabled !== "boolean")
                                return "posStaffSyncEnabled: boolean expected";
                        if (message.manualJobStatusSummary != null && message.hasOwnProperty("manualJobStatusSummary")) {
                            var error = $root.palexy.streaming.v1.AsyncJobStatusSummary.verify(message.manualJobStatusSummary);
                            if (error)
                                return "manualJobStatusSummary." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PosImportConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosImportConfig} PosImportConfig
                     */
                    PosImportConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosImportConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosImportConfig();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        switch (object.importType) {
                        case "UNKNOWN":
                        case 0:
                            message.importType = 0;
                            break;
                        case "CSV":
                        case 1:
                            message.importType = 1;
                            break;
                        case "REST_API":
                        case 2:
                            message.importType = 2;
                            break;
                        }
                        if (object.lastImportTimeMs != null)
                            if ($util.Long)
                                (message.lastImportTimeMs = $util.Long.fromValue(object.lastImportTimeMs)).unsigned = false;
                            else if (typeof object.lastImportTimeMs === "string")
                                message.lastImportTimeMs = parseInt(object.lastImportTimeMs, 10);
                            else if (typeof object.lastImportTimeMs === "number")
                                message.lastImportTimeMs = object.lastImportTimeMs;
                            else if (typeof object.lastImportTimeMs === "object")
                                message.lastImportTimeMs = new $util.LongBits(object.lastImportTimeMs.low >>> 0, object.lastImportTimeMs.high >>> 0).toNumber();
                        if (object.generateTransactionId != null)
                            message.generateTransactionId = Boolean(object.generateTransactionId);
                        if (object.storeCodeHeader != null)
                            message.storeCodeHeader = String(object.storeCodeHeader);
                        if (object.productNameHeader != null)
                            message.productNameHeader = String(object.productNameHeader);
                        if (object.productCodeHeader != null)
                            message.productCodeHeader = String(object.productCodeHeader);
                        if (object.productDescriptionHeader != null)
                            message.productDescriptionHeader = String(object.productDescriptionHeader);
                        if (object.unitPriceHeader != null)
                            message.unitPriceHeader = String(object.unitPriceHeader);
                        if (object.quantityHeader != null)
                            message.quantityHeader = String(object.quantityHeader);
                        if (object.totalAmountHeader != null)
                            message.totalAmountHeader = String(object.totalAmountHeader);
                        if (object.terminalIdHeader != null)
                            message.terminalIdHeader = String(object.terminalIdHeader);
                        if (object.transactionIdHeader != null)
                            message.transactionIdHeader = String(object.transactionIdHeader);
                        if (object.currencyHeader != null)
                            message.currencyHeader = String(object.currencyHeader);
                        if (object.transactionDateHeader != null)
                            message.transactionDateHeader = String(object.transactionDateHeader);
                        if (object.transactionTimeHeader != null)
                            message.transactionTimeHeader = String(object.transactionTimeHeader);
                        if (object.membershipCardHeader != null)
                            message.membershipCardHeader = String(object.membershipCardHeader);
                        if (object.customerIdHeader != null)
                            message.customerIdHeader = String(object.customerIdHeader);
                        if (object.salesStaffIdHeader != null)
                            message.salesStaffIdHeader = String(object.salesStaffIdHeader);
                        if (object.netAmountHeader != null)
                            message.netAmountHeader = String(object.netAmountHeader);
                        if (object.unitBaseCostHeader != null)
                            message.unitBaseCostHeader = String(object.unitBaseCostHeader);
                        if (object.zeroValueRecordsIgnored != null)
                            message.zeroValueRecordsIgnored = Boolean(object.zeroValueRecordsIgnored);
                        if (object.exclusionMetadata) {
                            if (!Array.isArray(object.exclusionMetadata))
                                throw TypeError(".palexy.streaming.v1.PosImportConfig.exclusionMetadata: array expected");
                            message.exclusionMetadata = [];
                            for (var i = 0; i < object.exclusionMetadata.length; ++i) {
                                if (typeof object.exclusionMetadata[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.PosImportConfig.exclusionMetadata: object expected");
                                message.exclusionMetadata[i] = $root.palexy.streaming.v1.PosImportConfig.ExclusionMetadata.fromObject(object.exclusionMetadata[i]);
                            }
                        }
                        if (object.additionalPropertiesHeader) {
                            if (typeof object.additionalPropertiesHeader !== "object")
                                throw TypeError(".palexy.streaming.v1.PosImportConfig.additionalPropertiesHeader: object expected");
                            message.additionalPropertiesHeader = {};
                            for (var keys = Object.keys(object.additionalPropertiesHeader), i = 0; i < keys.length; ++i)
                                message.additionalPropertiesHeader[keys[i]] = String(object.additionalPropertiesHeader[keys[i]]);
                        }
                        if (object.unitHeader != null)
                            message.unitHeader = String(object.unitHeader);
                        if (object.transactionDateFormat != null)
                            message.transactionDateFormat = String(object.transactionDateFormat);
                        if (object.transactionTimeFormat != null)
                            message.transactionTimeFormat = String(object.transactionTimeFormat);
                        if (object.negateQuantity != null)
                            message.negateQuantity = Boolean(object.negateQuantity);
                        if (object.negateAmount != null)
                            message.negateAmount = Boolean(object.negateAmount);
                        if (object.delimiter != null)
                            message.delimiter = String(object.delimiter);
                        if (object.allowEmptyProduct != null)
                            message.allowEmptyProduct = Boolean(object.allowEmptyProduct);
                        if (object.posStaffPropertiesHeaderList != null) {
                            if (typeof object.posStaffPropertiesHeaderList !== "object")
                                throw TypeError(".palexy.streaming.v1.PosImportConfig.posStaffPropertiesHeaderList: object expected");
                            message.posStaffPropertiesHeaderList = $root.palexy.streaming.v1.PosStaffPropertiesHeaderList.fromObject(object.posStaffPropertiesHeaderList);
                        }
                        if (object.grossAmountHeader != null)
                            message.grossAmountHeader = String(object.grossAmountHeader);
                        if (object.discountAmountHeader != null)
                            message.discountAmountHeader = String(object.discountAmountHeader);
                        if (object.timezone != null)
                            message.timezone = String(object.timezone);
                        if (object.posApiUsername != null)
                            message.posApiUsername = String(object.posApiUsername);
                        if (object.posApiPassword != null)
                            message.posApiPassword = String(object.posApiPassword);
                        if (object.posApiIntegrationKey != null)
                            message.posApiIntegrationKey = String(object.posApiIntegrationKey);
                        if (object.posApiBaseUrl != null)
                            message.posApiBaseUrl = String(object.posApiBaseUrl);
                        if (object.intradayUpdateTimeString != null)
                            message.intradayUpdateTimeString = String(object.intradayUpdateTimeString);
                        if (object.rewriteHistoricalDays != null)
                            message.rewriteHistoricalDays = object.rewriteHistoricalDays | 0;
                        if (object.yesterdayUpdateTimeString != null)
                            message.yesterdayUpdateTimeString = String(object.yesterdayUpdateTimeString);
                        if (object.companyName != null)
                            message.companyName = String(object.companyName);
                        if (object.companyCode != null)
                            message.companyCode = String(object.companyCode);
                        if (object.posStaffSyncEnabled != null)
                            message.posStaffSyncEnabled = Boolean(object.posStaffSyncEnabled);
                        if (object.manualJobStatusSummary != null) {
                            if (typeof object.manualJobStatusSummary !== "object")
                                throw TypeError(".palexy.streaming.v1.PosImportConfig.manualJobStatusSummary: object expected");
                            message.manualJobStatusSummary = $root.palexy.streaming.v1.AsyncJobStatusSummary.fromObject(object.manualJobStatusSummary);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosImportConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @static
                     * @param {palexy.streaming.v1.PosImportConfig} message PosImportConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosImportConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.exclusionMetadata = [];
                        if (options.objects || options.defaults)
                            object.additionalPropertiesHeader = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.generateTransactionId = false;
                            object.storeCodeHeader = "";
                            object.productNameHeader = "";
                            object.productCodeHeader = "";
                            object.productDescriptionHeader = "";
                            object.unitPriceHeader = "";
                            object.quantityHeader = "";
                            object.totalAmountHeader = "";
                            object.terminalIdHeader = "";
                            object.transactionIdHeader = "";
                            object.currencyHeader = "";
                            object.transactionDateHeader = "";
                            object.transactionTimeHeader = "";
                            object.companyName = "";
                            object.membershipCardHeader = "";
                            object.importType = options.enums === String ? "UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastImportTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastImportTimeMs = options.longs === String ? "0" : 0;
                            object.timezone = "";
                            object.companyCode = "";
                            object.posApiUsername = "";
                            object.posApiPassword = "";
                            object.posApiIntegrationKey = "";
                            object.posApiBaseUrl = "";
                            object.customerIdHeader = "";
                            object.salesStaffIdHeader = "";
                            object.netAmountHeader = "";
                            object.unitBaseCostHeader = "";
                            object.intradayUpdateTimeString = "";
                            object.rewriteHistoricalDays = 0;
                            object.yesterdayUpdateTimeString = "";
                            object.zeroValueRecordsIgnored = false;
                            object.unitHeader = "";
                            object.transactionDateFormat = "";
                            object.transactionTimeFormat = "";
                            object.negateQuantity = false;
                            object.negateAmount = false;
                            object.manualJobStatusSummary = null;
                            object.delimiter = "";
                            object.allowEmptyProduct = false;
                            object.posStaffSyncEnabled = false;
                            object.posStaffPropertiesHeaderList = null;
                            object.grossAmountHeader = "";
                            object.discountAmountHeader = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.generateTransactionId != null && message.hasOwnProperty("generateTransactionId"))
                            object.generateTransactionId = message.generateTransactionId;
                        if (message.storeCodeHeader != null && message.hasOwnProperty("storeCodeHeader"))
                            object.storeCodeHeader = message.storeCodeHeader;
                        if (message.productNameHeader != null && message.hasOwnProperty("productNameHeader"))
                            object.productNameHeader = message.productNameHeader;
                        if (message.productCodeHeader != null && message.hasOwnProperty("productCodeHeader"))
                            object.productCodeHeader = message.productCodeHeader;
                        if (message.productDescriptionHeader != null && message.hasOwnProperty("productDescriptionHeader"))
                            object.productDescriptionHeader = message.productDescriptionHeader;
                        if (message.unitPriceHeader != null && message.hasOwnProperty("unitPriceHeader"))
                            object.unitPriceHeader = message.unitPriceHeader;
                        if (message.quantityHeader != null && message.hasOwnProperty("quantityHeader"))
                            object.quantityHeader = message.quantityHeader;
                        if (message.totalAmountHeader != null && message.hasOwnProperty("totalAmountHeader"))
                            object.totalAmountHeader = message.totalAmountHeader;
                        if (message.terminalIdHeader != null && message.hasOwnProperty("terminalIdHeader"))
                            object.terminalIdHeader = message.terminalIdHeader;
                        if (message.transactionIdHeader != null && message.hasOwnProperty("transactionIdHeader"))
                            object.transactionIdHeader = message.transactionIdHeader;
                        if (message.currencyHeader != null && message.hasOwnProperty("currencyHeader"))
                            object.currencyHeader = message.currencyHeader;
                        if (message.transactionDateHeader != null && message.hasOwnProperty("transactionDateHeader"))
                            object.transactionDateHeader = message.transactionDateHeader;
                        if (message.transactionTimeHeader != null && message.hasOwnProperty("transactionTimeHeader"))
                            object.transactionTimeHeader = message.transactionTimeHeader;
                        if (message.companyName != null && message.hasOwnProperty("companyName"))
                            object.companyName = message.companyName;
                        if (message.membershipCardHeader != null && message.hasOwnProperty("membershipCardHeader"))
                            object.membershipCardHeader = message.membershipCardHeader;
                        if (message.importType != null && message.hasOwnProperty("importType"))
                            object.importType = options.enums === String ? $root.palexy.streaming.v1.PosImportConfig.ImportType[message.importType] : message.importType;
                        if (message.lastImportTimeMs != null && message.hasOwnProperty("lastImportTimeMs"))
                            if (typeof message.lastImportTimeMs === "number")
                                object.lastImportTimeMs = options.longs === String ? String(message.lastImportTimeMs) : message.lastImportTimeMs;
                            else
                                object.lastImportTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastImportTimeMs) : options.longs === Number ? new $util.LongBits(message.lastImportTimeMs.low >>> 0, message.lastImportTimeMs.high >>> 0).toNumber() : message.lastImportTimeMs;
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            object.timezone = message.timezone;
                        if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                            object.companyCode = message.companyCode;
                        if (message.posApiUsername != null && message.hasOwnProperty("posApiUsername"))
                            object.posApiUsername = message.posApiUsername;
                        if (message.posApiPassword != null && message.hasOwnProperty("posApiPassword"))
                            object.posApiPassword = message.posApiPassword;
                        if (message.posApiIntegrationKey != null && message.hasOwnProperty("posApiIntegrationKey"))
                            object.posApiIntegrationKey = message.posApiIntegrationKey;
                        if (message.posApiBaseUrl != null && message.hasOwnProperty("posApiBaseUrl"))
                            object.posApiBaseUrl = message.posApiBaseUrl;
                        if (message.customerIdHeader != null && message.hasOwnProperty("customerIdHeader"))
                            object.customerIdHeader = message.customerIdHeader;
                        if (message.salesStaffIdHeader != null && message.hasOwnProperty("salesStaffIdHeader"))
                            object.salesStaffIdHeader = message.salesStaffIdHeader;
                        if (message.netAmountHeader != null && message.hasOwnProperty("netAmountHeader"))
                            object.netAmountHeader = message.netAmountHeader;
                        if (message.unitBaseCostHeader != null && message.hasOwnProperty("unitBaseCostHeader"))
                            object.unitBaseCostHeader = message.unitBaseCostHeader;
                        if (message.intradayUpdateTimeString != null && message.hasOwnProperty("intradayUpdateTimeString"))
                            object.intradayUpdateTimeString = message.intradayUpdateTimeString;
                        if (message.rewriteHistoricalDays != null && message.hasOwnProperty("rewriteHistoricalDays"))
                            object.rewriteHistoricalDays = message.rewriteHistoricalDays;
                        if (message.yesterdayUpdateTimeString != null && message.hasOwnProperty("yesterdayUpdateTimeString"))
                            object.yesterdayUpdateTimeString = message.yesterdayUpdateTimeString;
                        if (message.zeroValueRecordsIgnored != null && message.hasOwnProperty("zeroValueRecordsIgnored"))
                            object.zeroValueRecordsIgnored = message.zeroValueRecordsIgnored;
                        if (message.exclusionMetadata && message.exclusionMetadata.length) {
                            object.exclusionMetadata = [];
                            for (var j = 0; j < message.exclusionMetadata.length; ++j)
                                object.exclusionMetadata[j] = $root.palexy.streaming.v1.PosImportConfig.ExclusionMetadata.toObject(message.exclusionMetadata[j], options);
                        }
                        var keys2;
                        if (message.additionalPropertiesHeader && (keys2 = Object.keys(message.additionalPropertiesHeader)).length) {
                            object.additionalPropertiesHeader = {};
                            for (var j = 0; j < keys2.length; ++j)
                                object.additionalPropertiesHeader[keys2[j]] = message.additionalPropertiesHeader[keys2[j]];
                        }
                        if (message.unitHeader != null && message.hasOwnProperty("unitHeader"))
                            object.unitHeader = message.unitHeader;
                        if (message.transactionDateFormat != null && message.hasOwnProperty("transactionDateFormat"))
                            object.transactionDateFormat = message.transactionDateFormat;
                        if (message.transactionTimeFormat != null && message.hasOwnProperty("transactionTimeFormat"))
                            object.transactionTimeFormat = message.transactionTimeFormat;
                        if (message.negateQuantity != null && message.hasOwnProperty("negateQuantity"))
                            object.negateQuantity = message.negateQuantity;
                        if (message.negateAmount != null && message.hasOwnProperty("negateAmount"))
                            object.negateAmount = message.negateAmount;
                        if (message.manualJobStatusSummary != null && message.hasOwnProperty("manualJobStatusSummary"))
                            object.manualJobStatusSummary = $root.palexy.streaming.v1.AsyncJobStatusSummary.toObject(message.manualJobStatusSummary, options);
                        if (message.delimiter != null && message.hasOwnProperty("delimiter"))
                            object.delimiter = message.delimiter;
                        if (message.allowEmptyProduct != null && message.hasOwnProperty("allowEmptyProduct"))
                            object.allowEmptyProduct = message.allowEmptyProduct;
                        if (message.posStaffSyncEnabled != null && message.hasOwnProperty("posStaffSyncEnabled"))
                            object.posStaffSyncEnabled = message.posStaffSyncEnabled;
                        if (message.posStaffPropertiesHeaderList != null && message.hasOwnProperty("posStaffPropertiesHeaderList"))
                            object.posStaffPropertiesHeaderList = $root.palexy.streaming.v1.PosStaffPropertiesHeaderList.toObject(message.posStaffPropertiesHeaderList, options);
                        if (message.grossAmountHeader != null && message.hasOwnProperty("grossAmountHeader"))
                            object.grossAmountHeader = message.grossAmountHeader;
                        if (message.discountAmountHeader != null && message.hasOwnProperty("discountAmountHeader"))
                            object.discountAmountHeader = message.discountAmountHeader;
                        return object;
                    };
    
                    /**
                     * Converts this PosImportConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosImportConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosImportConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * ImportType enum.
                     * @name palexy.streaming.v1.PosImportConfig.ImportType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} CSV=1 CSV value
                     * @property {number} REST_API=2 REST_API value
                     */
                    PosImportConfig.ImportType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "CSV"] = 1;
                        values[valuesById[2] = "REST_API"] = 2;
                        return values;
                    })();
    
                    PosImportConfig.ExclusionMetadata = (function() {
    
                        /**
                         * Properties of an ExclusionMetadata.
                         * @memberof palexy.streaming.v1.PosImportConfig
                         * @interface IExclusionMetadata
                         * @property {string|null} [header] ExclusionMetadata header
                         * @property {Array.<string>|null} [values] ExclusionMetadata values
                         */
    
                        /**
                         * Constructs a new ExclusionMetadata.
                         * @memberof palexy.streaming.v1.PosImportConfig
                         * @classdesc Represents an ExclusionMetadata.
                         * @implements IExclusionMetadata
                         * @constructor
                         * @param {palexy.streaming.v1.PosImportConfig.IExclusionMetadata=} [properties] Properties to set
                         */
                        function ExclusionMetadata(properties) {
                            this.values = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * ExclusionMetadata header.
                         * @member {string} header
                         * @memberof palexy.streaming.v1.PosImportConfig.ExclusionMetadata
                         * @instance
                         */
                        ExclusionMetadata.prototype.header = "";
    
                        /**
                         * ExclusionMetadata values.
                         * @member {Array.<string>} values
                         * @memberof palexy.streaming.v1.PosImportConfig.ExclusionMetadata
                         * @instance
                         */
                        ExclusionMetadata.prototype.values = $util.emptyArray;
    
                        /**
                         * Creates a new ExclusionMetadata instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.PosImportConfig.ExclusionMetadata
                         * @static
                         * @param {palexy.streaming.v1.PosImportConfig.IExclusionMetadata=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.PosImportConfig.ExclusionMetadata} ExclusionMetadata instance
                         */
                        ExclusionMetadata.create = function create(properties) {
                            return new ExclusionMetadata(properties);
                        };
    
                        /**
                         * Encodes the specified ExclusionMetadata message. Does not implicitly {@link palexy.streaming.v1.PosImportConfig.ExclusionMetadata.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.PosImportConfig.ExclusionMetadata
                         * @static
                         * @param {palexy.streaming.v1.PosImportConfig.IExclusionMetadata} message ExclusionMetadata message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ExclusionMetadata.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.header);
                            if (message.values != null && message.values.length)
                                for (var i = 0; i < message.values.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.values[i]);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified ExclusionMetadata message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosImportConfig.ExclusionMetadata.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.PosImportConfig.ExclusionMetadata
                         * @static
                         * @param {palexy.streaming.v1.PosImportConfig.IExclusionMetadata} message ExclusionMetadata message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ExclusionMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes an ExclusionMetadata message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.PosImportConfig.ExclusionMetadata
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.PosImportConfig.ExclusionMetadata} ExclusionMetadata
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ExclusionMetadata.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosImportConfig.ExclusionMetadata();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.header = reader.string();
                                    break;
                                case 2:
                                    if (!(message.values && message.values.length))
                                        message.values = [];
                                    message.values.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes an ExclusionMetadata message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.PosImportConfig.ExclusionMetadata
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.PosImportConfig.ExclusionMetadata} ExclusionMetadata
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ExclusionMetadata.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies an ExclusionMetadata message.
                         * @function verify
                         * @memberof palexy.streaming.v1.PosImportConfig.ExclusionMetadata
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ExclusionMetadata.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.header != null && message.hasOwnProperty("header"))
                                if (!$util.isString(message.header))
                                    return "header: string expected";
                            if (message.values != null && message.hasOwnProperty("values")) {
                                if (!Array.isArray(message.values))
                                    return "values: array expected";
                                for (var i = 0; i < message.values.length; ++i)
                                    if (!$util.isString(message.values[i]))
                                        return "values: string[] expected";
                            }
                            return null;
                        };
    
                        /**
                         * Creates an ExclusionMetadata message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.PosImportConfig.ExclusionMetadata
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.PosImportConfig.ExclusionMetadata} ExclusionMetadata
                         */
                        ExclusionMetadata.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.PosImportConfig.ExclusionMetadata)
                                return object;
                            var message = new $root.palexy.streaming.v1.PosImportConfig.ExclusionMetadata();
                            if (object.header != null)
                                message.header = String(object.header);
                            if (object.values) {
                                if (!Array.isArray(object.values))
                                    throw TypeError(".palexy.streaming.v1.PosImportConfig.ExclusionMetadata.values: array expected");
                                message.values = [];
                                for (var i = 0; i < object.values.length; ++i)
                                    message.values[i] = String(object.values[i]);
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from an ExclusionMetadata message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.PosImportConfig.ExclusionMetadata
                         * @static
                         * @param {palexy.streaming.v1.PosImportConfig.ExclusionMetadata} message ExclusionMetadata
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ExclusionMetadata.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.values = [];
                            if (options.defaults)
                                object.header = "";
                            if (message.header != null && message.hasOwnProperty("header"))
                                object.header = message.header;
                            if (message.values && message.values.length) {
                                object.values = [];
                                for (var j = 0; j < message.values.length; ++j)
                                    object.values[j] = message.values[j];
                            }
                            return object;
                        };
    
                        /**
                         * Converts this ExclusionMetadata to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.PosImportConfig.ExclusionMetadata
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ExclusionMetadata.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return ExclusionMetadata;
                    })();
    
                    return PosImportConfig;
                })();
    
                v1.PosStaffPropertiesHeaderList = (function() {
    
                    /**
                     * Properties of a PosStaffPropertiesHeaderList.
                     * @memberof palexy.streaming.v1
                     * @interface IPosStaffPropertiesHeaderList
                     * @property {Array.<palexy.streaming.v1.IPosStaffPropertiesHeader>|null} [posStaffPropertiesHeader] PosStaffPropertiesHeaderList posStaffPropertiesHeader
                     */
    
                    /**
                     * Constructs a new PosStaffPropertiesHeaderList.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosStaffPropertiesHeaderList.
                     * @implements IPosStaffPropertiesHeaderList
                     * @constructor
                     * @param {palexy.streaming.v1.IPosStaffPropertiesHeaderList=} [properties] Properties to set
                     */
                    function PosStaffPropertiesHeaderList(properties) {
                        this.posStaffPropertiesHeader = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosStaffPropertiesHeaderList posStaffPropertiesHeader.
                     * @member {Array.<palexy.streaming.v1.IPosStaffPropertiesHeader>} posStaffPropertiesHeader
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeaderList
                     * @instance
                     */
                    PosStaffPropertiesHeaderList.prototype.posStaffPropertiesHeader = $util.emptyArray;
    
                    /**
                     * Creates a new PosStaffPropertiesHeaderList instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeaderList
                     * @static
                     * @param {palexy.streaming.v1.IPosStaffPropertiesHeaderList=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosStaffPropertiesHeaderList} PosStaffPropertiesHeaderList instance
                     */
                    PosStaffPropertiesHeaderList.create = function create(properties) {
                        return new PosStaffPropertiesHeaderList(properties);
                    };
    
                    /**
                     * Encodes the specified PosStaffPropertiesHeaderList message. Does not implicitly {@link palexy.streaming.v1.PosStaffPropertiesHeaderList.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeaderList
                     * @static
                     * @param {palexy.streaming.v1.IPosStaffPropertiesHeaderList} message PosStaffPropertiesHeaderList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosStaffPropertiesHeaderList.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.posStaffPropertiesHeader != null && message.posStaffPropertiesHeader.length)
                            for (var i = 0; i < message.posStaffPropertiesHeader.length; ++i)
                                $root.palexy.streaming.v1.PosStaffPropertiesHeader.encode(message.posStaffPropertiesHeader[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosStaffPropertiesHeaderList message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosStaffPropertiesHeaderList.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeaderList
                     * @static
                     * @param {palexy.streaming.v1.IPosStaffPropertiesHeaderList} message PosStaffPropertiesHeaderList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosStaffPropertiesHeaderList.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosStaffPropertiesHeaderList message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeaderList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosStaffPropertiesHeaderList} PosStaffPropertiesHeaderList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosStaffPropertiesHeaderList.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosStaffPropertiesHeaderList();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.posStaffPropertiesHeader && message.posStaffPropertiesHeader.length))
                                    message.posStaffPropertiesHeader = [];
                                message.posStaffPropertiesHeader.push($root.palexy.streaming.v1.PosStaffPropertiesHeader.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosStaffPropertiesHeaderList message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeaderList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosStaffPropertiesHeaderList} PosStaffPropertiesHeaderList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosStaffPropertiesHeaderList.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosStaffPropertiesHeaderList message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeaderList
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosStaffPropertiesHeaderList.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.posStaffPropertiesHeader != null && message.hasOwnProperty("posStaffPropertiesHeader")) {
                            if (!Array.isArray(message.posStaffPropertiesHeader))
                                return "posStaffPropertiesHeader: array expected";
                            for (var i = 0; i < message.posStaffPropertiesHeader.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosStaffPropertiesHeader.verify(message.posStaffPropertiesHeader[i]);
                                if (error)
                                    return "posStaffPropertiesHeader." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PosStaffPropertiesHeaderList message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeaderList
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosStaffPropertiesHeaderList} PosStaffPropertiesHeaderList
                     */
                    PosStaffPropertiesHeaderList.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosStaffPropertiesHeaderList)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosStaffPropertiesHeaderList();
                        if (object.posStaffPropertiesHeader) {
                            if (!Array.isArray(object.posStaffPropertiesHeader))
                                throw TypeError(".palexy.streaming.v1.PosStaffPropertiesHeaderList.posStaffPropertiesHeader: array expected");
                            message.posStaffPropertiesHeader = [];
                            for (var i = 0; i < object.posStaffPropertiesHeader.length; ++i) {
                                if (typeof object.posStaffPropertiesHeader[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.PosStaffPropertiesHeaderList.posStaffPropertiesHeader: object expected");
                                message.posStaffPropertiesHeader[i] = $root.palexy.streaming.v1.PosStaffPropertiesHeader.fromObject(object.posStaffPropertiesHeader[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosStaffPropertiesHeaderList message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeaderList
                     * @static
                     * @param {palexy.streaming.v1.PosStaffPropertiesHeaderList} message PosStaffPropertiesHeaderList
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosStaffPropertiesHeaderList.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.posStaffPropertiesHeader = [];
                        if (message.posStaffPropertiesHeader && message.posStaffPropertiesHeader.length) {
                            object.posStaffPropertiesHeader = [];
                            for (var j = 0; j < message.posStaffPropertiesHeader.length; ++j)
                                object.posStaffPropertiesHeader[j] = $root.palexy.streaming.v1.PosStaffPropertiesHeader.toObject(message.posStaffPropertiesHeader[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this PosStaffPropertiesHeaderList to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeaderList
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosStaffPropertiesHeaderList.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosStaffPropertiesHeaderList;
                })();
    
                v1.PosStaffPropertiesHeader = (function() {
    
                    /**
                     * Properties of a PosStaffPropertiesHeader.
                     * @memberof palexy.streaming.v1
                     * @interface IPosStaffPropertiesHeader
                     * @property {string|null} [staffIdHeader] PosStaffPropertiesHeader staffIdHeader
                     * @property {string|null} [staffNameHeader] PosStaffPropertiesHeader staffNameHeader
                     */
    
                    /**
                     * Constructs a new PosStaffPropertiesHeader.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosStaffPropertiesHeader.
                     * @implements IPosStaffPropertiesHeader
                     * @constructor
                     * @param {palexy.streaming.v1.IPosStaffPropertiesHeader=} [properties] Properties to set
                     */
                    function PosStaffPropertiesHeader(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosStaffPropertiesHeader staffIdHeader.
                     * @member {string} staffIdHeader
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeader
                     * @instance
                     */
                    PosStaffPropertiesHeader.prototype.staffIdHeader = "";
    
                    /**
                     * PosStaffPropertiesHeader staffNameHeader.
                     * @member {string} staffNameHeader
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeader
                     * @instance
                     */
                    PosStaffPropertiesHeader.prototype.staffNameHeader = "";
    
                    /**
                     * Creates a new PosStaffPropertiesHeader instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeader
                     * @static
                     * @param {palexy.streaming.v1.IPosStaffPropertiesHeader=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosStaffPropertiesHeader} PosStaffPropertiesHeader instance
                     */
                    PosStaffPropertiesHeader.create = function create(properties) {
                        return new PosStaffPropertiesHeader(properties);
                    };
    
                    /**
                     * Encodes the specified PosStaffPropertiesHeader message. Does not implicitly {@link palexy.streaming.v1.PosStaffPropertiesHeader.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeader
                     * @static
                     * @param {palexy.streaming.v1.IPosStaffPropertiesHeader} message PosStaffPropertiesHeader message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosStaffPropertiesHeader.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffIdHeader != null && Object.hasOwnProperty.call(message, "staffIdHeader"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.staffIdHeader);
                        if (message.staffNameHeader != null && Object.hasOwnProperty.call(message, "staffNameHeader"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.staffNameHeader);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosStaffPropertiesHeader message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosStaffPropertiesHeader.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeader
                     * @static
                     * @param {palexy.streaming.v1.IPosStaffPropertiesHeader} message PosStaffPropertiesHeader message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosStaffPropertiesHeader.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosStaffPropertiesHeader message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeader
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosStaffPropertiesHeader} PosStaffPropertiesHeader
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosStaffPropertiesHeader.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosStaffPropertiesHeader();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffIdHeader = reader.string();
                                break;
                            case 2:
                                message.staffNameHeader = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosStaffPropertiesHeader message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeader
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosStaffPropertiesHeader} PosStaffPropertiesHeader
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosStaffPropertiesHeader.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosStaffPropertiesHeader message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeader
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosStaffPropertiesHeader.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffIdHeader != null && message.hasOwnProperty("staffIdHeader"))
                            if (!$util.isString(message.staffIdHeader))
                                return "staffIdHeader: string expected";
                        if (message.staffNameHeader != null && message.hasOwnProperty("staffNameHeader"))
                            if (!$util.isString(message.staffNameHeader))
                                return "staffNameHeader: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a PosStaffPropertiesHeader message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeader
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosStaffPropertiesHeader} PosStaffPropertiesHeader
                     */
                    PosStaffPropertiesHeader.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosStaffPropertiesHeader)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosStaffPropertiesHeader();
                        if (object.staffIdHeader != null)
                            message.staffIdHeader = String(object.staffIdHeader);
                        if (object.staffNameHeader != null)
                            message.staffNameHeader = String(object.staffNameHeader);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosStaffPropertiesHeader message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeader
                     * @static
                     * @param {palexy.streaming.v1.PosStaffPropertiesHeader} message PosStaffPropertiesHeader
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosStaffPropertiesHeader.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.staffIdHeader = "";
                            object.staffNameHeader = "";
                        }
                        if (message.staffIdHeader != null && message.hasOwnProperty("staffIdHeader"))
                            object.staffIdHeader = message.staffIdHeader;
                        if (message.staffNameHeader != null && message.hasOwnProperty("staffNameHeader"))
                            object.staffNameHeader = message.staffNameHeader;
                        return object;
                    };
    
                    /**
                     * Converts this PosStaffPropertiesHeader to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosStaffPropertiesHeader
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosStaffPropertiesHeader.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosStaffPropertiesHeader;
                })();
    
                v1.GetPosImportConfigRequest = (function() {
    
                    /**
                     * Properties of a GetPosImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetPosImportConfigRequest
                     * @property {number|null} [configId] GetPosImportConfigRequest configId
                     * @property {boolean|null} [includeCompanyInfo] GetPosImportConfigRequest includeCompanyInfo
                     */
    
                    /**
                     * Constructs a new GetPosImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetPosImportConfigRequest.
                     * @implements IGetPosImportConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetPosImportConfigRequest=} [properties] Properties to set
                     */
                    function GetPosImportConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetPosImportConfigRequest configId.
                     * @member {number} configId
                     * @memberof palexy.streaming.v1.GetPosImportConfigRequest
                     * @instance
                     */
                    GetPosImportConfigRequest.prototype.configId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetPosImportConfigRequest includeCompanyInfo.
                     * @member {boolean} includeCompanyInfo
                     * @memberof palexy.streaming.v1.GetPosImportConfigRequest
                     * @instance
                     */
                    GetPosImportConfigRequest.prototype.includeCompanyInfo = false;
    
                    /**
                     * Creates a new GetPosImportConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetPosImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetPosImportConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetPosImportConfigRequest} GetPosImportConfigRequest instance
                     */
                    GetPosImportConfigRequest.create = function create(properties) {
                        return new GetPosImportConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetPosImportConfigRequest message. Does not implicitly {@link palexy.streaming.v1.GetPosImportConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetPosImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetPosImportConfigRequest} message GetPosImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetPosImportConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.configId != null && Object.hasOwnProperty.call(message, "configId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.configId);
                        if (message.includeCompanyInfo != null && Object.hasOwnProperty.call(message, "includeCompanyInfo"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeCompanyInfo);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetPosImportConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetPosImportConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetPosImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetPosImportConfigRequest} message GetPosImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetPosImportConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetPosImportConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetPosImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetPosImportConfigRequest} GetPosImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetPosImportConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetPosImportConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.configId = reader.int64();
                                break;
                            case 2:
                                message.includeCompanyInfo = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetPosImportConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetPosImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetPosImportConfigRequest} GetPosImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetPosImportConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetPosImportConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetPosImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetPosImportConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.configId != null && message.hasOwnProperty("configId"))
                            if (!$util.isInteger(message.configId) && !(message.configId && $util.isInteger(message.configId.low) && $util.isInteger(message.configId.high)))
                                return "configId: integer|Long expected";
                        if (message.includeCompanyInfo != null && message.hasOwnProperty("includeCompanyInfo"))
                            if (typeof message.includeCompanyInfo !== "boolean")
                                return "includeCompanyInfo: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetPosImportConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetPosImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetPosImportConfigRequest} GetPosImportConfigRequest
                     */
                    GetPosImportConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetPosImportConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetPosImportConfigRequest();
                        if (object.configId != null)
                            if ($util.Long)
                                (message.configId = $util.Long.fromValue(object.configId)).unsigned = false;
                            else if (typeof object.configId === "string")
                                message.configId = parseInt(object.configId, 10);
                            else if (typeof object.configId === "number")
                                message.configId = object.configId;
                            else if (typeof object.configId === "object")
                                message.configId = new $util.LongBits(object.configId.low >>> 0, object.configId.high >>> 0).toNumber();
                        if (object.includeCompanyInfo != null)
                            message.includeCompanyInfo = Boolean(object.includeCompanyInfo);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetPosImportConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetPosImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.GetPosImportConfigRequest} message GetPosImportConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetPosImportConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.configId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.configId = options.longs === String ? "0" : 0;
                            object.includeCompanyInfo = false;
                        }
                        if (message.configId != null && message.hasOwnProperty("configId"))
                            if (typeof message.configId === "number")
                                object.configId = options.longs === String ? String(message.configId) : message.configId;
                            else
                                object.configId = options.longs === String ? $util.Long.prototype.toString.call(message.configId) : options.longs === Number ? new $util.LongBits(message.configId.low >>> 0, message.configId.high >>> 0).toNumber() : message.configId;
                        if (message.includeCompanyInfo != null && message.hasOwnProperty("includeCompanyInfo"))
                            object.includeCompanyInfo = message.includeCompanyInfo;
                        return object;
                    };
    
                    /**
                     * Converts this GetPosImportConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetPosImportConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetPosImportConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetPosImportConfigRequest;
                })();
    
                v1.ListPosImportConfigRequest = (function() {
    
                    /**
                     * Properties of a ListPosImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListPosImportConfigRequest
                     * @property {number|null} [companyId] ListPosImportConfigRequest companyId
                     * @property {boolean|null} [includeCompanyInfo] ListPosImportConfigRequest includeCompanyInfo
                     * @property {number|null} [pageSize] ListPosImportConfigRequest pageSize
                     * @property {string|null} [pageToken] ListPosImportConfigRequest pageToken
                     * @property {palexy.streaming.v1.PosImportConfig.ImportType|null} [importType] ListPosImportConfigRequest importType
                     * @property {boolean|null} [intradayImportIncompleteOnly] ListPosImportConfigRequest intradayImportIncompleteOnly
                     * @property {boolean|null} [fetchJobStatus] ListPosImportConfigRequest fetchJobStatus
                     */
    
                    /**
                     * Constructs a new ListPosImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListPosImportConfigRequest.
                     * @implements IListPosImportConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListPosImportConfigRequest=} [properties] Properties to set
                     */
                    function ListPosImportConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListPosImportConfigRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @instance
                     */
                    ListPosImportConfigRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListPosImportConfigRequest includeCompanyInfo.
                     * @member {boolean} includeCompanyInfo
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @instance
                     */
                    ListPosImportConfigRequest.prototype.includeCompanyInfo = false;
    
                    /**
                     * ListPosImportConfigRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @instance
                     */
                    ListPosImportConfigRequest.prototype.pageSize = 0;
    
                    /**
                     * ListPosImportConfigRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @instance
                     */
                    ListPosImportConfigRequest.prototype.pageToken = "";
    
                    /**
                     * ListPosImportConfigRequest importType.
                     * @member {palexy.streaming.v1.PosImportConfig.ImportType} importType
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @instance
                     */
                    ListPosImportConfigRequest.prototype.importType = 0;
    
                    /**
                     * ListPosImportConfigRequest intradayImportIncompleteOnly.
                     * @member {boolean} intradayImportIncompleteOnly
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @instance
                     */
                    ListPosImportConfigRequest.prototype.intradayImportIncompleteOnly = false;
    
                    /**
                     * ListPosImportConfigRequest fetchJobStatus.
                     * @member {boolean} fetchJobStatus
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @instance
                     */
                    ListPosImportConfigRequest.prototype.fetchJobStatus = false;
    
                    /**
                     * Creates a new ListPosImportConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosImportConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListPosImportConfigRequest} ListPosImportConfigRequest instance
                     */
                    ListPosImportConfigRequest.create = function create(properties) {
                        return new ListPosImportConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListPosImportConfigRequest message. Does not implicitly {@link palexy.streaming.v1.ListPosImportConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosImportConfigRequest} message ListPosImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosImportConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.includeCompanyInfo != null && Object.hasOwnProperty.call(message, "includeCompanyInfo"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeCompanyInfo);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.pageToken);
                        if (message.importType != null && Object.hasOwnProperty.call(message, "importType"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.importType);
                        if (message.intradayImportIncompleteOnly != null && Object.hasOwnProperty.call(message, "intradayImportIncompleteOnly"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.intradayImportIncompleteOnly);
                        if (message.fetchJobStatus != null && Object.hasOwnProperty.call(message, "fetchJobStatus"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.fetchJobStatus);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListPosImportConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosImportConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IListPosImportConfigRequest} message ListPosImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosImportConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListPosImportConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListPosImportConfigRequest} ListPosImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosImportConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosImportConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.includeCompanyInfo = reader.bool();
                                break;
                            case 3:
                                message.pageSize = reader.int32();
                                break;
                            case 4:
                                message.pageToken = reader.string();
                                break;
                            case 5:
                                message.importType = reader.int32();
                                break;
                            case 6:
                                message.intradayImportIncompleteOnly = reader.bool();
                                break;
                            case 7:
                                message.fetchJobStatus = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListPosImportConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListPosImportConfigRequest} ListPosImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosImportConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListPosImportConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListPosImportConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.includeCompanyInfo != null && message.hasOwnProperty("includeCompanyInfo"))
                            if (typeof message.includeCompanyInfo !== "boolean")
                                return "includeCompanyInfo: boolean expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.importType != null && message.hasOwnProperty("importType"))
                            switch (message.importType) {
                            default:
                                return "importType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.intradayImportIncompleteOnly != null && message.hasOwnProperty("intradayImportIncompleteOnly"))
                            if (typeof message.intradayImportIncompleteOnly !== "boolean")
                                return "intradayImportIncompleteOnly: boolean expected";
                        if (message.fetchJobStatus != null && message.hasOwnProperty("fetchJobStatus"))
                            if (typeof message.fetchJobStatus !== "boolean")
                                return "fetchJobStatus: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListPosImportConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListPosImportConfigRequest} ListPosImportConfigRequest
                     */
                    ListPosImportConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListPosImportConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListPosImportConfigRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.includeCompanyInfo != null)
                            message.includeCompanyInfo = Boolean(object.includeCompanyInfo);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        switch (object.importType) {
                        case "UNKNOWN":
                        case 0:
                            message.importType = 0;
                            break;
                        case "CSV":
                        case 1:
                            message.importType = 1;
                            break;
                        case "REST_API":
                        case 2:
                            message.importType = 2;
                            break;
                        }
                        if (object.intradayImportIncompleteOnly != null)
                            message.intradayImportIncompleteOnly = Boolean(object.intradayImportIncompleteOnly);
                        if (object.fetchJobStatus != null)
                            message.fetchJobStatus = Boolean(object.fetchJobStatus);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListPosImportConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.ListPosImportConfigRequest} message ListPosImportConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListPosImportConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.includeCompanyInfo = false;
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.importType = options.enums === String ? "UNKNOWN" : 0;
                            object.intradayImportIncompleteOnly = false;
                            object.fetchJobStatus = false;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.includeCompanyInfo != null && message.hasOwnProperty("includeCompanyInfo"))
                            object.includeCompanyInfo = message.includeCompanyInfo;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.importType != null && message.hasOwnProperty("importType"))
                            object.importType = options.enums === String ? $root.palexy.streaming.v1.PosImportConfig.ImportType[message.importType] : message.importType;
                        if (message.intradayImportIncompleteOnly != null && message.hasOwnProperty("intradayImportIncompleteOnly"))
                            object.intradayImportIncompleteOnly = message.intradayImportIncompleteOnly;
                        if (message.fetchJobStatus != null && message.hasOwnProperty("fetchJobStatus"))
                            object.fetchJobStatus = message.fetchJobStatus;
                        return object;
                    };
    
                    /**
                     * Converts this ListPosImportConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListPosImportConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListPosImportConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListPosImportConfigRequest;
                })();
    
                v1.ListPosImportConfigResponse = (function() {
    
                    /**
                     * Properties of a ListPosImportConfigResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListPosImportConfigResponse
                     * @property {Array.<palexy.streaming.v1.IPosImportConfig>|null} [posImportConfigs] ListPosImportConfigResponse posImportConfigs
                     * @property {number|null} [totalElements] ListPosImportConfigResponse totalElements
                     * @property {string|null} [nextPageToken] ListPosImportConfigResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListPosImportConfigResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListPosImportConfigResponse.
                     * @implements IListPosImportConfigResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListPosImportConfigResponse=} [properties] Properties to set
                     */
                    function ListPosImportConfigResponse(properties) {
                        this.posImportConfigs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListPosImportConfigResponse posImportConfigs.
                     * @member {Array.<palexy.streaming.v1.IPosImportConfig>} posImportConfigs
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @instance
                     */
                    ListPosImportConfigResponse.prototype.posImportConfigs = $util.emptyArray;
    
                    /**
                     * ListPosImportConfigResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @instance
                     */
                    ListPosImportConfigResponse.prototype.totalElements = 0;
    
                    /**
                     * ListPosImportConfigResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @instance
                     */
                    ListPosImportConfigResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListPosImportConfigResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosImportConfigResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListPosImportConfigResponse} ListPosImportConfigResponse instance
                     */
                    ListPosImportConfigResponse.create = function create(properties) {
                        return new ListPosImportConfigResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListPosImportConfigResponse message. Does not implicitly {@link palexy.streaming.v1.ListPosImportConfigResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosImportConfigResponse} message ListPosImportConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosImportConfigResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.posImportConfigs != null && message.posImportConfigs.length)
                            for (var i = 0; i < message.posImportConfigs.length; ++i)
                                $root.palexy.streaming.v1.PosImportConfig.encode(message.posImportConfigs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListPosImportConfigResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListPosImportConfigResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.IListPosImportConfigResponse} message ListPosImportConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListPosImportConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListPosImportConfigResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListPosImportConfigResponse} ListPosImportConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosImportConfigResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListPosImportConfigResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.posImportConfigs && message.posImportConfigs.length))
                                    message.posImportConfigs = [];
                                message.posImportConfigs.push($root.palexy.streaming.v1.PosImportConfig.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListPosImportConfigResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListPosImportConfigResponse} ListPosImportConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListPosImportConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListPosImportConfigResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListPosImportConfigResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.posImportConfigs != null && message.hasOwnProperty("posImportConfigs")) {
                            if (!Array.isArray(message.posImportConfigs))
                                return "posImportConfigs: array expected";
                            for (var i = 0; i < message.posImportConfigs.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosImportConfig.verify(message.posImportConfigs[i]);
                                if (error)
                                    return "posImportConfigs." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListPosImportConfigResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListPosImportConfigResponse} ListPosImportConfigResponse
                     */
                    ListPosImportConfigResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListPosImportConfigResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListPosImportConfigResponse();
                        if (object.posImportConfigs) {
                            if (!Array.isArray(object.posImportConfigs))
                                throw TypeError(".palexy.streaming.v1.ListPosImportConfigResponse.posImportConfigs: array expected");
                            message.posImportConfigs = [];
                            for (var i = 0; i < object.posImportConfigs.length; ++i) {
                                if (typeof object.posImportConfigs[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListPosImportConfigResponse.posImportConfigs: object expected");
                                message.posImportConfigs[i] = $root.palexy.streaming.v1.PosImportConfig.fromObject(object.posImportConfigs[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListPosImportConfigResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.ListPosImportConfigResponse} message ListPosImportConfigResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListPosImportConfigResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.posImportConfigs = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.posImportConfigs && message.posImportConfigs.length) {
                            object.posImportConfigs = [];
                            for (var j = 0; j < message.posImportConfigs.length; ++j)
                                object.posImportConfigs[j] = $root.palexy.streaming.v1.PosImportConfig.toObject(message.posImportConfigs[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListPosImportConfigResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListPosImportConfigResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListPosImportConfigResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListPosImportConfigResponse;
                })();
    
                v1.MatchPosTransactionRequest = (function() {
    
                    /**
                     * Properties of a MatchPosTransactionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IMatchPosTransactionRequest
                     * @property {number|null} [storeId] MatchPosTransactionRequest storeId
                     * @property {number|null} [dateId] MatchPosTransactionRequest dateId
                     */
    
                    /**
                     * Constructs a new MatchPosTransactionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MatchPosTransactionRequest.
                     * @implements IMatchPosTransactionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IMatchPosTransactionRequest=} [properties] Properties to set
                     */
                    function MatchPosTransactionRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MatchPosTransactionRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.MatchPosTransactionRequest
                     * @instance
                     */
                    MatchPosTransactionRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MatchPosTransactionRequest dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.MatchPosTransactionRequest
                     * @instance
                     */
                    MatchPosTransactionRequest.prototype.dateId = 0;
    
                    /**
                     * Creates a new MatchPosTransactionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MatchPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.IMatchPosTransactionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MatchPosTransactionRequest} MatchPosTransactionRequest instance
                     */
                    MatchPosTransactionRequest.create = function create(properties) {
                        return new MatchPosTransactionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified MatchPosTransactionRequest message. Does not implicitly {@link palexy.streaming.v1.MatchPosTransactionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MatchPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.IMatchPosTransactionRequest} message MatchPosTransactionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MatchPosTransactionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MatchPosTransactionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.MatchPosTransactionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MatchPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.IMatchPosTransactionRequest} message MatchPosTransactionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MatchPosTransactionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MatchPosTransactionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MatchPosTransactionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MatchPosTransactionRequest} MatchPosTransactionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MatchPosTransactionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MatchPosTransactionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MatchPosTransactionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MatchPosTransactionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MatchPosTransactionRequest} MatchPosTransactionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MatchPosTransactionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MatchPosTransactionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MatchPosTransactionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MatchPosTransactionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a MatchPosTransactionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MatchPosTransactionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MatchPosTransactionRequest} MatchPosTransactionRequest
                     */
                    MatchPosTransactionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MatchPosTransactionRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.MatchPosTransactionRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MatchPosTransactionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MatchPosTransactionRequest
                     * @static
                     * @param {palexy.streaming.v1.MatchPosTransactionRequest} message MatchPosTransactionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MatchPosTransactionRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        return object;
                    };
    
                    /**
                     * Converts this MatchPosTransactionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MatchPosTransactionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MatchPosTransactionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MatchPosTransactionRequest;
                })();
    
                v1.MatchPosTransactionResponse = (function() {
    
                    /**
                     * Properties of a MatchPosTransactionResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IMatchPosTransactionResponse
                     * @property {number|null} [posTransactionCount] MatchPosTransactionResponse posTransactionCount
                     * @property {number|null} [customerCount] MatchPosTransactionResponse customerCount
                     * @property {number|null} [matchedPosTransactionCount] MatchPosTransactionResponse matchedPosTransactionCount
                     */
    
                    /**
                     * Constructs a new MatchPosTransactionResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MatchPosTransactionResponse.
                     * @implements IMatchPosTransactionResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IMatchPosTransactionResponse=} [properties] Properties to set
                     */
                    function MatchPosTransactionResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MatchPosTransactionResponse posTransactionCount.
                     * @member {number} posTransactionCount
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @instance
                     */
                    MatchPosTransactionResponse.prototype.posTransactionCount = 0;
    
                    /**
                     * MatchPosTransactionResponse customerCount.
                     * @member {number} customerCount
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @instance
                     */
                    MatchPosTransactionResponse.prototype.customerCount = 0;
    
                    /**
                     * MatchPosTransactionResponse matchedPosTransactionCount.
                     * @member {number} matchedPosTransactionCount
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @instance
                     */
                    MatchPosTransactionResponse.prototype.matchedPosTransactionCount = 0;
    
                    /**
                     * Creates a new MatchPosTransactionResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.IMatchPosTransactionResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MatchPosTransactionResponse} MatchPosTransactionResponse instance
                     */
                    MatchPosTransactionResponse.create = function create(properties) {
                        return new MatchPosTransactionResponse(properties);
                    };
    
                    /**
                     * Encodes the specified MatchPosTransactionResponse message. Does not implicitly {@link palexy.streaming.v1.MatchPosTransactionResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.IMatchPosTransactionResponse} message MatchPosTransactionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MatchPosTransactionResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.posTransactionCount != null && Object.hasOwnProperty.call(message, "posTransactionCount"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.posTransactionCount);
                        if (message.customerCount != null && Object.hasOwnProperty.call(message, "customerCount"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.customerCount);
                        if (message.matchedPosTransactionCount != null && Object.hasOwnProperty.call(message, "matchedPosTransactionCount"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.matchedPosTransactionCount);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MatchPosTransactionResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.MatchPosTransactionResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.IMatchPosTransactionResponse} message MatchPosTransactionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MatchPosTransactionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MatchPosTransactionResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MatchPosTransactionResponse} MatchPosTransactionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MatchPosTransactionResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MatchPosTransactionResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.posTransactionCount = reader.int32();
                                break;
                            case 2:
                                message.customerCount = reader.int32();
                                break;
                            case 3:
                                message.matchedPosTransactionCount = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MatchPosTransactionResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MatchPosTransactionResponse} MatchPosTransactionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MatchPosTransactionResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MatchPosTransactionResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MatchPosTransactionResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.posTransactionCount != null && message.hasOwnProperty("posTransactionCount"))
                            if (!$util.isInteger(message.posTransactionCount))
                                return "posTransactionCount: integer expected";
                        if (message.customerCount != null && message.hasOwnProperty("customerCount"))
                            if (!$util.isInteger(message.customerCount))
                                return "customerCount: integer expected";
                        if (message.matchedPosTransactionCount != null && message.hasOwnProperty("matchedPosTransactionCount"))
                            if (!$util.isInteger(message.matchedPosTransactionCount))
                                return "matchedPosTransactionCount: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a MatchPosTransactionResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MatchPosTransactionResponse} MatchPosTransactionResponse
                     */
                    MatchPosTransactionResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MatchPosTransactionResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.MatchPosTransactionResponse();
                        if (object.posTransactionCount != null)
                            message.posTransactionCount = object.posTransactionCount | 0;
                        if (object.customerCount != null)
                            message.customerCount = object.customerCount | 0;
                        if (object.matchedPosTransactionCount != null)
                            message.matchedPosTransactionCount = object.matchedPosTransactionCount | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MatchPosTransactionResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @static
                     * @param {palexy.streaming.v1.MatchPosTransactionResponse} message MatchPosTransactionResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MatchPosTransactionResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.posTransactionCount = 0;
                            object.customerCount = 0;
                            object.matchedPosTransactionCount = 0;
                        }
                        if (message.posTransactionCount != null && message.hasOwnProperty("posTransactionCount"))
                            object.posTransactionCount = message.posTransactionCount;
                        if (message.customerCount != null && message.hasOwnProperty("customerCount"))
                            object.customerCount = message.customerCount;
                        if (message.matchedPosTransactionCount != null && message.hasOwnProperty("matchedPosTransactionCount"))
                            object.matchedPosTransactionCount = message.matchedPosTransactionCount;
                        return object;
                    };
    
                    /**
                     * Converts this MatchPosTransactionResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MatchPosTransactionResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MatchPosTransactionResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MatchPosTransactionResponse;
                })();
    
                v1.PosMatchingConfig = (function() {
    
                    /**
                     * Properties of a PosMatchingConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IPosMatchingConfig
                     * @property {number|null} [id] PosMatchingConfig id
                     * @property {number|null} [companyId] PosMatchingConfig companyId
                     * @property {palexy.streaming.v1.PosMatchingConfig.PosMatchingAlgorithm|null} [matchingAlgorithm] PosMatchingConfig matchingAlgorithm
                     */
    
                    /**
                     * Constructs a new PosMatchingConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosMatchingConfig.
                     * @implements IPosMatchingConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IPosMatchingConfig=} [properties] Properties to set
                     */
                    function PosMatchingConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosMatchingConfig id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @instance
                     */
                    PosMatchingConfig.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosMatchingConfig companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @instance
                     */
                    PosMatchingConfig.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosMatchingConfig matchingAlgorithm.
                     * @member {palexy.streaming.v1.PosMatchingConfig.PosMatchingAlgorithm} matchingAlgorithm
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @instance
                     */
                    PosMatchingConfig.prototype.matchingAlgorithm = 0;
    
                    /**
                     * Creates a new PosMatchingConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @static
                     * @param {palexy.streaming.v1.IPosMatchingConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosMatchingConfig} PosMatchingConfig instance
                     */
                    PosMatchingConfig.create = function create(properties) {
                        return new PosMatchingConfig(properties);
                    };
    
                    /**
                     * Encodes the specified PosMatchingConfig message. Does not implicitly {@link palexy.streaming.v1.PosMatchingConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @static
                     * @param {palexy.streaming.v1.IPosMatchingConfig} message PosMatchingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosMatchingConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.matchingAlgorithm != null && Object.hasOwnProperty.call(message, "matchingAlgorithm"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.matchingAlgorithm);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosMatchingConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosMatchingConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @static
                     * @param {palexy.streaming.v1.IPosMatchingConfig} message PosMatchingConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosMatchingConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosMatchingConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosMatchingConfig} PosMatchingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosMatchingConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosMatchingConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.matchingAlgorithm = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosMatchingConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosMatchingConfig} PosMatchingConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosMatchingConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosMatchingConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosMatchingConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.matchingAlgorithm != null && message.hasOwnProperty("matchingAlgorithm"))
                            switch (message.matchingAlgorithm) {
                            default:
                                return "matchingAlgorithm: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a PosMatchingConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosMatchingConfig} PosMatchingConfig
                     */
                    PosMatchingConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosMatchingConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosMatchingConfig();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        switch (object.matchingAlgorithm) {
                        case "UNKNOWN":
                        case 0:
                            message.matchingAlgorithm = 0;
                            break;
                        case "MERGE_WITH_ONLY_DETECTED_ACTIONS":
                        case 1:
                            message.matchingAlgorithm = 1;
                            break;
                        case "MERGE_WITH_BOTH_DETECTED_ACTIONS_AND_END_TIME":
                        case 2:
                            message.matchingAlgorithm = 2;
                            break;
                        case "DEFAULT":
                        case 3:
                            message.matchingAlgorithm = 3;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosMatchingConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @static
                     * @param {palexy.streaming.v1.PosMatchingConfig} message PosMatchingConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosMatchingConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.matchingAlgorithm = options.enums === String ? "UNKNOWN" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.matchingAlgorithm != null && message.hasOwnProperty("matchingAlgorithm"))
                            object.matchingAlgorithm = options.enums === String ? $root.palexy.streaming.v1.PosMatchingConfig.PosMatchingAlgorithm[message.matchingAlgorithm] : message.matchingAlgorithm;
                        return object;
                    };
    
                    /**
                     * Converts this PosMatchingConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosMatchingConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosMatchingConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * PosMatchingAlgorithm enum.
                     * @name palexy.streaming.v1.PosMatchingConfig.PosMatchingAlgorithm
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} MERGE_WITH_ONLY_DETECTED_ACTIONS=1 MERGE_WITH_ONLY_DETECTED_ACTIONS value
                     * @property {number} MERGE_WITH_BOTH_DETECTED_ACTIONS_AND_END_TIME=2 MERGE_WITH_BOTH_DETECTED_ACTIONS_AND_END_TIME value
                     * @property {number} DEFAULT=3 DEFAULT value
                     */
                    PosMatchingConfig.PosMatchingAlgorithm = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "MERGE_WITH_ONLY_DETECTED_ACTIONS"] = 1;
                        values[valuesById[2] = "MERGE_WITH_BOTH_DETECTED_ACTIONS_AND_END_TIME"] = 2;
                        values[valuesById[3] = "DEFAULT"] = 3;
                        return values;
                    })();
    
                    return PosMatchingConfig;
                })();
    
                v1.PosSectionMapping = (function() {
    
                    /**
                     * Properties of a PosSectionMapping.
                     * @memberof palexy.streaming.v1
                     * @interface IPosSectionMapping
                     * @property {number|null} [id] PosSectionMapping id
                     * @property {string|null} [name] PosSectionMapping name
                     * @property {number|null} [companyId] PosSectionMapping companyId
                     * @property {string|null} [appliedDate] PosSectionMapping appliedDate
                     * @property {boolean|null} [mappedBySameValue] PosSectionMapping mappedBySameValue
                     * @property {number|null} [sectionMetadataTypeId] PosSectionMapping sectionMetadataTypeId
                     * @property {number|null} [posMetadataId_1] PosSectionMapping posMetadataId_1
                     * @property {google.protobuf.IInt64Value|null} [posMetadataId_2] PosSectionMapping posMetadataId_2
                     * @property {google.protobuf.IInt64Value|null} [posMetadataId_3] PosSectionMapping posMetadataId_3
                     * @property {google.protobuf.IInt64Value|null} [posMetadataId_4] PosSectionMapping posMetadataId_4
                     * @property {number|null} [createTimeMs] PosSectionMapping createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] PosSectionMapping lastUpdateTimeMs
                     * @property {palexy.streaming.v1.IMetadataType|null} [sectionMetadataType] PosSectionMapping sectionMetadataType
                     * @property {palexy.streaming.v1.IPosMetadata|null} [posMetadata_1] PosSectionMapping posMetadata_1
                     * @property {palexy.streaming.v1.IPosMetadata|null} [posMetadata_2] PosSectionMapping posMetadata_2
                     * @property {palexy.streaming.v1.IPosMetadata|null} [posMetadata_3] PosSectionMapping posMetadata_3
                     * @property {palexy.streaming.v1.IPosMetadata|null} [posMetadata_4] PosSectionMapping posMetadata_4
                     * @property {Array.<palexy.streaming.v1.IPosSectionMappingItem>|null} [sectionMappingItems] PosSectionMapping sectionMappingItems
                     */
    
                    /**
                     * Constructs a new PosSectionMapping.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosSectionMapping.
                     * @implements IPosSectionMapping
                     * @constructor
                     * @param {palexy.streaming.v1.IPosSectionMapping=} [properties] Properties to set
                     */
                    function PosSectionMapping(properties) {
                        this.sectionMappingItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosSectionMapping id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosSectionMapping name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.name = "";
    
                    /**
                     * PosSectionMapping companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosSectionMapping appliedDate.
                     * @member {string} appliedDate
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.appliedDate = "";
    
                    /**
                     * PosSectionMapping mappedBySameValue.
                     * @member {boolean} mappedBySameValue
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.mappedBySameValue = false;
    
                    /**
                     * PosSectionMapping sectionMetadataTypeId.
                     * @member {number} sectionMetadataTypeId
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.sectionMetadataTypeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosSectionMapping posMetadataId_1.
                     * @member {number} posMetadataId_1
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.posMetadataId_1 = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosSectionMapping posMetadataId_2.
                     * @member {google.protobuf.IInt64Value|null|undefined} posMetadataId_2
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.posMetadataId_2 = null;
    
                    /**
                     * PosSectionMapping posMetadataId_3.
                     * @member {google.protobuf.IInt64Value|null|undefined} posMetadataId_3
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.posMetadataId_3 = null;
    
                    /**
                     * PosSectionMapping posMetadataId_4.
                     * @member {google.protobuf.IInt64Value|null|undefined} posMetadataId_4
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.posMetadataId_4 = null;
    
                    /**
                     * PosSectionMapping createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosSectionMapping lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosSectionMapping sectionMetadataType.
                     * @member {palexy.streaming.v1.IMetadataType|null|undefined} sectionMetadataType
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.sectionMetadataType = null;
    
                    /**
                     * PosSectionMapping posMetadata_1.
                     * @member {palexy.streaming.v1.IPosMetadata|null|undefined} posMetadata_1
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.posMetadata_1 = null;
    
                    /**
                     * PosSectionMapping posMetadata_2.
                     * @member {palexy.streaming.v1.IPosMetadata|null|undefined} posMetadata_2
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.posMetadata_2 = null;
    
                    /**
                     * PosSectionMapping posMetadata_3.
                     * @member {palexy.streaming.v1.IPosMetadata|null|undefined} posMetadata_3
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.posMetadata_3 = null;
    
                    /**
                     * PosSectionMapping posMetadata_4.
                     * @member {palexy.streaming.v1.IPosMetadata|null|undefined} posMetadata_4
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.posMetadata_4 = null;
    
                    /**
                     * PosSectionMapping sectionMappingItems.
                     * @member {Array.<palexy.streaming.v1.IPosSectionMappingItem>} sectionMappingItems
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     */
                    PosSectionMapping.prototype.sectionMappingItems = $util.emptyArray;
    
                    /**
                     * Creates a new PosSectionMapping instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @static
                     * @param {palexy.streaming.v1.IPosSectionMapping=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosSectionMapping} PosSectionMapping instance
                     */
                    PosSectionMapping.create = function create(properties) {
                        return new PosSectionMapping(properties);
                    };
    
                    /**
                     * Encodes the specified PosSectionMapping message. Does not implicitly {@link palexy.streaming.v1.PosSectionMapping.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @static
                     * @param {palexy.streaming.v1.IPosSectionMapping} message PosSectionMapping message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosSectionMapping.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.companyId);
                        if (message.appliedDate != null && Object.hasOwnProperty.call(message, "appliedDate"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.appliedDate);
                        if (message.mappedBySameValue != null && Object.hasOwnProperty.call(message, "mappedBySameValue"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.mappedBySameValue);
                        if (message.sectionMetadataTypeId != null && Object.hasOwnProperty.call(message, "sectionMetadataTypeId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.sectionMetadataTypeId);
                        if (message.posMetadataId_1 != null && Object.hasOwnProperty.call(message, "posMetadataId_1"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.posMetadataId_1);
                        if (message.posMetadataId_2 != null && Object.hasOwnProperty.call(message, "posMetadataId_2"))
                            $root.google.protobuf.Int64Value.encode(message.posMetadataId_2, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.posMetadataId_3 != null && Object.hasOwnProperty.call(message, "posMetadataId_3"))
                            $root.google.protobuf.Int64Value.encode(message.posMetadataId_3, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.posMetadataId_4 != null && Object.hasOwnProperty.call(message, "posMetadataId_4"))
                            $root.google.protobuf.Int64Value.encode(message.posMetadataId_4, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int64(message.lastUpdateTimeMs);
                        if (message.sectionMetadataType != null && Object.hasOwnProperty.call(message, "sectionMetadataType"))
                            $root.palexy.streaming.v1.MetadataType.encode(message.sectionMetadataType, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.posMetadata_1 != null && Object.hasOwnProperty.call(message, "posMetadata_1"))
                            $root.palexy.streaming.v1.PosMetadata.encode(message.posMetadata_1, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                        if (message.posMetadata_2 != null && Object.hasOwnProperty.call(message, "posMetadata_2"))
                            $root.palexy.streaming.v1.PosMetadata.encode(message.posMetadata_2, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                        if (message.posMetadata_3 != null && Object.hasOwnProperty.call(message, "posMetadata_3"))
                            $root.palexy.streaming.v1.PosMetadata.encode(message.posMetadata_3, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.posMetadata_4 != null && Object.hasOwnProperty.call(message, "posMetadata_4"))
                            $root.palexy.streaming.v1.PosMetadata.encode(message.posMetadata_4, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        if (message.sectionMappingItems != null && message.sectionMappingItems.length)
                            for (var i = 0; i < message.sectionMappingItems.length; ++i)
                                $root.palexy.streaming.v1.PosSectionMappingItem.encode(message.sectionMappingItems[i], writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosSectionMapping message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosSectionMapping.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @static
                     * @param {palexy.streaming.v1.IPosSectionMapping} message PosSectionMapping message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosSectionMapping.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosSectionMapping message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosSectionMapping} PosSectionMapping
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosSectionMapping.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosSectionMapping();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.companyId = reader.int64();
                                break;
                            case 4:
                                message.appliedDate = reader.string();
                                break;
                            case 5:
                                message.mappedBySameValue = reader.bool();
                                break;
                            case 6:
                                message.sectionMetadataTypeId = reader.int64();
                                break;
                            case 7:
                                message.posMetadataId_1 = reader.int64();
                                break;
                            case 8:
                                message.posMetadataId_2 = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.posMetadataId_3 = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.posMetadataId_4 = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.createTimeMs = reader.int64();
                                break;
                            case 12:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 13:
                                message.sectionMetadataType = $root.palexy.streaming.v1.MetadataType.decode(reader, reader.uint32());
                                break;
                            case 14:
                                message.posMetadata_1 = $root.palexy.streaming.v1.PosMetadata.decode(reader, reader.uint32());
                                break;
                            case 15:
                                message.posMetadata_2 = $root.palexy.streaming.v1.PosMetadata.decode(reader, reader.uint32());
                                break;
                            case 16:
                                message.posMetadata_3 = $root.palexy.streaming.v1.PosMetadata.decode(reader, reader.uint32());
                                break;
                            case 17:
                                message.posMetadata_4 = $root.palexy.streaming.v1.PosMetadata.decode(reader, reader.uint32());
                                break;
                            case 18:
                                if (!(message.sectionMappingItems && message.sectionMappingItems.length))
                                    message.sectionMappingItems = [];
                                message.sectionMappingItems.push($root.palexy.streaming.v1.PosSectionMappingItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosSectionMapping message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosSectionMapping} PosSectionMapping
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosSectionMapping.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosSectionMapping message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosSectionMapping.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.appliedDate != null && message.hasOwnProperty("appliedDate"))
                            if (!$util.isString(message.appliedDate))
                                return "appliedDate: string expected";
                        if (message.mappedBySameValue != null && message.hasOwnProperty("mappedBySameValue"))
                            if (typeof message.mappedBySameValue !== "boolean")
                                return "mappedBySameValue: boolean expected";
                        if (message.sectionMetadataTypeId != null && message.hasOwnProperty("sectionMetadataTypeId"))
                            if (!$util.isInteger(message.sectionMetadataTypeId) && !(message.sectionMetadataTypeId && $util.isInteger(message.sectionMetadataTypeId.low) && $util.isInteger(message.sectionMetadataTypeId.high)))
                                return "sectionMetadataTypeId: integer|Long expected";
                        if (message.posMetadataId_1 != null && message.hasOwnProperty("posMetadataId_1"))
                            if (!$util.isInteger(message.posMetadataId_1) && !(message.posMetadataId_1 && $util.isInteger(message.posMetadataId_1.low) && $util.isInteger(message.posMetadataId_1.high)))
                                return "posMetadataId_1: integer|Long expected";
                        if (message.posMetadataId_2 != null && message.hasOwnProperty("posMetadataId_2")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.posMetadataId_2);
                            if (error)
                                return "posMetadataId_2." + error;
                        }
                        if (message.posMetadataId_3 != null && message.hasOwnProperty("posMetadataId_3")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.posMetadataId_3);
                            if (error)
                                return "posMetadataId_3." + error;
                        }
                        if (message.posMetadataId_4 != null && message.hasOwnProperty("posMetadataId_4")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.posMetadataId_4);
                            if (error)
                                return "posMetadataId_4." + error;
                        }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.sectionMetadataType != null && message.hasOwnProperty("sectionMetadataType")) {
                            var error = $root.palexy.streaming.v1.MetadataType.verify(message.sectionMetadataType);
                            if (error)
                                return "sectionMetadataType." + error;
                        }
                        if (message.posMetadata_1 != null && message.hasOwnProperty("posMetadata_1")) {
                            var error = $root.palexy.streaming.v1.PosMetadata.verify(message.posMetadata_1);
                            if (error)
                                return "posMetadata_1." + error;
                        }
                        if (message.posMetadata_2 != null && message.hasOwnProperty("posMetadata_2")) {
                            var error = $root.palexy.streaming.v1.PosMetadata.verify(message.posMetadata_2);
                            if (error)
                                return "posMetadata_2." + error;
                        }
                        if (message.posMetadata_3 != null && message.hasOwnProperty("posMetadata_3")) {
                            var error = $root.palexy.streaming.v1.PosMetadata.verify(message.posMetadata_3);
                            if (error)
                                return "posMetadata_3." + error;
                        }
                        if (message.posMetadata_4 != null && message.hasOwnProperty("posMetadata_4")) {
                            var error = $root.palexy.streaming.v1.PosMetadata.verify(message.posMetadata_4);
                            if (error)
                                return "posMetadata_4." + error;
                        }
                        if (message.sectionMappingItems != null && message.hasOwnProperty("sectionMappingItems")) {
                            if (!Array.isArray(message.sectionMappingItems))
                                return "sectionMappingItems: array expected";
                            for (var i = 0; i < message.sectionMappingItems.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosSectionMappingItem.verify(message.sectionMappingItems[i]);
                                if (error)
                                    return "sectionMappingItems." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PosSectionMapping message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosSectionMapping} PosSectionMapping
                     */
                    PosSectionMapping.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosSectionMapping)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosSectionMapping();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.appliedDate != null)
                            message.appliedDate = String(object.appliedDate);
                        if (object.mappedBySameValue != null)
                            message.mappedBySameValue = Boolean(object.mappedBySameValue);
                        if (object.sectionMetadataTypeId != null)
                            if ($util.Long)
                                (message.sectionMetadataTypeId = $util.Long.fromValue(object.sectionMetadataTypeId)).unsigned = false;
                            else if (typeof object.sectionMetadataTypeId === "string")
                                message.sectionMetadataTypeId = parseInt(object.sectionMetadataTypeId, 10);
                            else if (typeof object.sectionMetadataTypeId === "number")
                                message.sectionMetadataTypeId = object.sectionMetadataTypeId;
                            else if (typeof object.sectionMetadataTypeId === "object")
                                message.sectionMetadataTypeId = new $util.LongBits(object.sectionMetadataTypeId.low >>> 0, object.sectionMetadataTypeId.high >>> 0).toNumber();
                        if (object.posMetadataId_1 != null)
                            if ($util.Long)
                                (message.posMetadataId_1 = $util.Long.fromValue(object.posMetadataId_1)).unsigned = false;
                            else if (typeof object.posMetadataId_1 === "string")
                                message.posMetadataId_1 = parseInt(object.posMetadataId_1, 10);
                            else if (typeof object.posMetadataId_1 === "number")
                                message.posMetadataId_1 = object.posMetadataId_1;
                            else if (typeof object.posMetadataId_1 === "object")
                                message.posMetadataId_1 = new $util.LongBits(object.posMetadataId_1.low >>> 0, object.posMetadataId_1.high >>> 0).toNumber();
                        if (object.posMetadataId_2 != null) {
                            if (typeof object.posMetadataId_2 !== "object")
                                throw TypeError(".palexy.streaming.v1.PosSectionMapping.posMetadataId_2: object expected");
                            message.posMetadataId_2 = $root.google.protobuf.Int64Value.fromObject(object.posMetadataId_2);
                        }
                        if (object.posMetadataId_3 != null) {
                            if (typeof object.posMetadataId_3 !== "object")
                                throw TypeError(".palexy.streaming.v1.PosSectionMapping.posMetadataId_3: object expected");
                            message.posMetadataId_3 = $root.google.protobuf.Int64Value.fromObject(object.posMetadataId_3);
                        }
                        if (object.posMetadataId_4 != null) {
                            if (typeof object.posMetadataId_4 !== "object")
                                throw TypeError(".palexy.streaming.v1.PosSectionMapping.posMetadataId_4: object expected");
                            message.posMetadataId_4 = $root.google.protobuf.Int64Value.fromObject(object.posMetadataId_4);
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.sectionMetadataType != null) {
                            if (typeof object.sectionMetadataType !== "object")
                                throw TypeError(".palexy.streaming.v1.PosSectionMapping.sectionMetadataType: object expected");
                            message.sectionMetadataType = $root.palexy.streaming.v1.MetadataType.fromObject(object.sectionMetadataType);
                        }
                        if (object.posMetadata_1 != null) {
                            if (typeof object.posMetadata_1 !== "object")
                                throw TypeError(".palexy.streaming.v1.PosSectionMapping.posMetadata_1: object expected");
                            message.posMetadata_1 = $root.palexy.streaming.v1.PosMetadata.fromObject(object.posMetadata_1);
                        }
                        if (object.posMetadata_2 != null) {
                            if (typeof object.posMetadata_2 !== "object")
                                throw TypeError(".palexy.streaming.v1.PosSectionMapping.posMetadata_2: object expected");
                            message.posMetadata_2 = $root.palexy.streaming.v1.PosMetadata.fromObject(object.posMetadata_2);
                        }
                        if (object.posMetadata_3 != null) {
                            if (typeof object.posMetadata_3 !== "object")
                                throw TypeError(".palexy.streaming.v1.PosSectionMapping.posMetadata_3: object expected");
                            message.posMetadata_3 = $root.palexy.streaming.v1.PosMetadata.fromObject(object.posMetadata_3);
                        }
                        if (object.posMetadata_4 != null) {
                            if (typeof object.posMetadata_4 !== "object")
                                throw TypeError(".palexy.streaming.v1.PosSectionMapping.posMetadata_4: object expected");
                            message.posMetadata_4 = $root.palexy.streaming.v1.PosMetadata.fromObject(object.posMetadata_4);
                        }
                        if (object.sectionMappingItems) {
                            if (!Array.isArray(object.sectionMappingItems))
                                throw TypeError(".palexy.streaming.v1.PosSectionMapping.sectionMappingItems: array expected");
                            message.sectionMappingItems = [];
                            for (var i = 0; i < object.sectionMappingItems.length; ++i) {
                                if (typeof object.sectionMappingItems[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.PosSectionMapping.sectionMappingItems: object expected");
                                message.sectionMappingItems[i] = $root.palexy.streaming.v1.PosSectionMappingItem.fromObject(object.sectionMappingItems[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosSectionMapping message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @static
                     * @param {palexy.streaming.v1.PosSectionMapping} message PosSectionMapping
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosSectionMapping.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.sectionMappingItems = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.appliedDate = "";
                            object.mappedBySameValue = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sectionMetadataTypeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sectionMetadataTypeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.posMetadataId_1 = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.posMetadataId_1 = options.longs === String ? "0" : 0;
                            object.posMetadataId_2 = null;
                            object.posMetadataId_3 = null;
                            object.posMetadataId_4 = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            object.sectionMetadataType = null;
                            object.posMetadata_1 = null;
                            object.posMetadata_2 = null;
                            object.posMetadata_3 = null;
                            object.posMetadata_4 = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.appliedDate != null && message.hasOwnProperty("appliedDate"))
                            object.appliedDate = message.appliedDate;
                        if (message.mappedBySameValue != null && message.hasOwnProperty("mappedBySameValue"))
                            object.mappedBySameValue = message.mappedBySameValue;
                        if (message.sectionMetadataTypeId != null && message.hasOwnProperty("sectionMetadataTypeId"))
                            if (typeof message.sectionMetadataTypeId === "number")
                                object.sectionMetadataTypeId = options.longs === String ? String(message.sectionMetadataTypeId) : message.sectionMetadataTypeId;
                            else
                                object.sectionMetadataTypeId = options.longs === String ? $util.Long.prototype.toString.call(message.sectionMetadataTypeId) : options.longs === Number ? new $util.LongBits(message.sectionMetadataTypeId.low >>> 0, message.sectionMetadataTypeId.high >>> 0).toNumber() : message.sectionMetadataTypeId;
                        if (message.posMetadataId_1 != null && message.hasOwnProperty("posMetadataId_1"))
                            if (typeof message.posMetadataId_1 === "number")
                                object.posMetadataId_1 = options.longs === String ? String(message.posMetadataId_1) : message.posMetadataId_1;
                            else
                                object.posMetadataId_1 = options.longs === String ? $util.Long.prototype.toString.call(message.posMetadataId_1) : options.longs === Number ? new $util.LongBits(message.posMetadataId_1.low >>> 0, message.posMetadataId_1.high >>> 0).toNumber() : message.posMetadataId_1;
                        if (message.posMetadataId_2 != null && message.hasOwnProperty("posMetadataId_2"))
                            object.posMetadataId_2 = $root.google.protobuf.Int64Value.toObject(message.posMetadataId_2, options);
                        if (message.posMetadataId_3 != null && message.hasOwnProperty("posMetadataId_3"))
                            object.posMetadataId_3 = $root.google.protobuf.Int64Value.toObject(message.posMetadataId_3, options);
                        if (message.posMetadataId_4 != null && message.hasOwnProperty("posMetadataId_4"))
                            object.posMetadataId_4 = $root.google.protobuf.Int64Value.toObject(message.posMetadataId_4, options);
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.sectionMetadataType != null && message.hasOwnProperty("sectionMetadataType"))
                            object.sectionMetadataType = $root.palexy.streaming.v1.MetadataType.toObject(message.sectionMetadataType, options);
                        if (message.posMetadata_1 != null && message.hasOwnProperty("posMetadata_1"))
                            object.posMetadata_1 = $root.palexy.streaming.v1.PosMetadata.toObject(message.posMetadata_1, options);
                        if (message.posMetadata_2 != null && message.hasOwnProperty("posMetadata_2"))
                            object.posMetadata_2 = $root.palexy.streaming.v1.PosMetadata.toObject(message.posMetadata_2, options);
                        if (message.posMetadata_3 != null && message.hasOwnProperty("posMetadata_3"))
                            object.posMetadata_3 = $root.palexy.streaming.v1.PosMetadata.toObject(message.posMetadata_3, options);
                        if (message.posMetadata_4 != null && message.hasOwnProperty("posMetadata_4"))
                            object.posMetadata_4 = $root.palexy.streaming.v1.PosMetadata.toObject(message.posMetadata_4, options);
                        if (message.sectionMappingItems && message.sectionMappingItems.length) {
                            object.sectionMappingItems = [];
                            for (var j = 0; j < message.sectionMappingItems.length; ++j)
                                object.sectionMappingItems[j] = $root.palexy.streaming.v1.PosSectionMappingItem.toObject(message.sectionMappingItems[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this PosSectionMapping to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosSectionMapping
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosSectionMapping.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosSectionMapping;
                })();
    
                v1.PosSectionMappingItem = (function() {
    
                    /**
                     * Properties of a PosSectionMappingItem.
                     * @memberof palexy.streaming.v1
                     * @interface IPosSectionMappingItem
                     * @property {number|null} [id] PosSectionMappingItem id
                     * @property {number|null} [sectionMappingId] PosSectionMappingItem sectionMappingId
                     * @property {number|null} [sectionMetadataValueId] PosSectionMappingItem sectionMetadataValueId
                     * @property {string|null} [posMetadataValue_1] PosSectionMappingItem posMetadataValue_1
                     * @property {string|null} [posMetadataValue_2] PosSectionMappingItem posMetadataValue_2
                     * @property {string|null} [posMetadataValue_3] PosSectionMappingItem posMetadataValue_3
                     * @property {string|null} [posMetadataValue_4] PosSectionMappingItem posMetadataValue_4
                     * @property {number|null} [createTimeMs] PosSectionMappingItem createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] PosSectionMappingItem lastUpdateTimeMs
                     * @property {google.protobuf.IInt64Value|null} [storeId] PosSectionMappingItem storeId
                     * @property {string|null} [productCode] PosSectionMappingItem productCode
                     * @property {palexy.streaming.v1.IMetadataValue|null} [sectionMetadataValue] PosSectionMappingItem sectionMetadataValue
                     */
    
                    /**
                     * Constructs a new PosSectionMappingItem.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosSectionMappingItem.
                     * @implements IPosSectionMappingItem
                     * @constructor
                     * @param {palexy.streaming.v1.IPosSectionMappingItem=} [properties] Properties to set
                     */
                    function PosSectionMappingItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosSectionMappingItem id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosSectionMappingItem sectionMappingId.
                     * @member {number} sectionMappingId
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.sectionMappingId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosSectionMappingItem sectionMetadataValueId.
                     * @member {number} sectionMetadataValueId
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.sectionMetadataValueId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosSectionMappingItem posMetadataValue_1.
                     * @member {string} posMetadataValue_1
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.posMetadataValue_1 = "";
    
                    /**
                     * PosSectionMappingItem posMetadataValue_2.
                     * @member {string} posMetadataValue_2
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.posMetadataValue_2 = "";
    
                    /**
                     * PosSectionMappingItem posMetadataValue_3.
                     * @member {string} posMetadataValue_3
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.posMetadataValue_3 = "";
    
                    /**
                     * PosSectionMappingItem posMetadataValue_4.
                     * @member {string} posMetadataValue_4
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.posMetadataValue_4 = "";
    
                    /**
                     * PosSectionMappingItem createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosSectionMappingItem lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * PosSectionMappingItem storeId.
                     * @member {google.protobuf.IInt64Value|null|undefined} storeId
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.storeId = null;
    
                    /**
                     * PosSectionMappingItem productCode.
                     * @member {string} productCode
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.productCode = "";
    
                    /**
                     * PosSectionMappingItem sectionMetadataValue.
                     * @member {palexy.streaming.v1.IMetadataValue|null|undefined} sectionMetadataValue
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     */
                    PosSectionMappingItem.prototype.sectionMetadataValue = null;
    
                    /**
                     * Creates a new PosSectionMappingItem instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @static
                     * @param {palexy.streaming.v1.IPosSectionMappingItem=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosSectionMappingItem} PosSectionMappingItem instance
                     */
                    PosSectionMappingItem.create = function create(properties) {
                        return new PosSectionMappingItem(properties);
                    };
    
                    /**
                     * Encodes the specified PosSectionMappingItem message. Does not implicitly {@link palexy.streaming.v1.PosSectionMappingItem.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @static
                     * @param {palexy.streaming.v1.IPosSectionMappingItem} message PosSectionMappingItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosSectionMappingItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.sectionMappingId != null && Object.hasOwnProperty.call(message, "sectionMappingId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.sectionMappingId);
                        if (message.sectionMetadataValueId != null && Object.hasOwnProperty.call(message, "sectionMetadataValueId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.sectionMetadataValueId);
                        if (message.posMetadataValue_1 != null && Object.hasOwnProperty.call(message, "posMetadataValue_1"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.posMetadataValue_1);
                        if (message.posMetadataValue_2 != null && Object.hasOwnProperty.call(message, "posMetadataValue_2"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.posMetadataValue_2);
                        if (message.posMetadataValue_3 != null && Object.hasOwnProperty.call(message, "posMetadataValue_3"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.posMetadataValue_3);
                        if (message.posMetadataValue_4 != null && Object.hasOwnProperty.call(message, "posMetadataValue_4"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.posMetadataValue_4);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int64(message.lastUpdateTimeMs);
                        if (message.sectionMetadataValue != null && Object.hasOwnProperty.call(message, "sectionMetadataValue"))
                            $root.palexy.streaming.v1.MetadataValue.encode(message.sectionMetadataValue, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            $root.google.protobuf.Int64Value.encode(message.storeId, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.productCode != null && Object.hasOwnProperty.call(message, "productCode"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.productCode);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosSectionMappingItem message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosSectionMappingItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @static
                     * @param {palexy.streaming.v1.IPosSectionMappingItem} message PosSectionMappingItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosSectionMappingItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosSectionMappingItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosSectionMappingItem} PosSectionMappingItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosSectionMappingItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosSectionMappingItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.sectionMappingId = reader.int64();
                                break;
                            case 3:
                                message.sectionMetadataValueId = reader.int64();
                                break;
                            case 4:
                                message.posMetadataValue_1 = reader.string();
                                break;
                            case 5:
                                message.posMetadataValue_2 = reader.string();
                                break;
                            case 6:
                                message.posMetadataValue_3 = reader.string();
                                break;
                            case 7:
                                message.posMetadataValue_4 = reader.string();
                                break;
                            case 8:
                                message.createTimeMs = reader.int64();
                                break;
                            case 9:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 11:
                                message.storeId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.productCode = reader.string();
                                break;
                            case 10:
                                message.sectionMetadataValue = $root.palexy.streaming.v1.MetadataValue.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosSectionMappingItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosSectionMappingItem} PosSectionMappingItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosSectionMappingItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosSectionMappingItem message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosSectionMappingItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (!$util.isInteger(message.sectionMappingId) && !(message.sectionMappingId && $util.isInteger(message.sectionMappingId.low) && $util.isInteger(message.sectionMappingId.high)))
                                return "sectionMappingId: integer|Long expected";
                        if (message.sectionMetadataValueId != null && message.hasOwnProperty("sectionMetadataValueId"))
                            if (!$util.isInteger(message.sectionMetadataValueId) && !(message.sectionMetadataValueId && $util.isInteger(message.sectionMetadataValueId.low) && $util.isInteger(message.sectionMetadataValueId.high)))
                                return "sectionMetadataValueId: integer|Long expected";
                        if (message.posMetadataValue_1 != null && message.hasOwnProperty("posMetadataValue_1"))
                            if (!$util.isString(message.posMetadataValue_1))
                                return "posMetadataValue_1: string expected";
                        if (message.posMetadataValue_2 != null && message.hasOwnProperty("posMetadataValue_2"))
                            if (!$util.isString(message.posMetadataValue_2))
                                return "posMetadataValue_2: string expected";
                        if (message.posMetadataValue_3 != null && message.hasOwnProperty("posMetadataValue_3"))
                            if (!$util.isString(message.posMetadataValue_3))
                                return "posMetadataValue_3: string expected";
                        if (message.posMetadataValue_4 != null && message.hasOwnProperty("posMetadataValue_4"))
                            if (!$util.isString(message.posMetadataValue_4))
                                return "posMetadataValue_4: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.storeId);
                            if (error)
                                return "storeId." + error;
                        }
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            if (!$util.isString(message.productCode))
                                return "productCode: string expected";
                        if (message.sectionMetadataValue != null && message.hasOwnProperty("sectionMetadataValue")) {
                            var error = $root.palexy.streaming.v1.MetadataValue.verify(message.sectionMetadataValue);
                            if (error)
                                return "sectionMetadataValue." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PosSectionMappingItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosSectionMappingItem} PosSectionMappingItem
                     */
                    PosSectionMappingItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosSectionMappingItem)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosSectionMappingItem();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.sectionMappingId != null)
                            if ($util.Long)
                                (message.sectionMappingId = $util.Long.fromValue(object.sectionMappingId)).unsigned = false;
                            else if (typeof object.sectionMappingId === "string")
                                message.sectionMappingId = parseInt(object.sectionMappingId, 10);
                            else if (typeof object.sectionMappingId === "number")
                                message.sectionMappingId = object.sectionMappingId;
                            else if (typeof object.sectionMappingId === "object")
                                message.sectionMappingId = new $util.LongBits(object.sectionMappingId.low >>> 0, object.sectionMappingId.high >>> 0).toNumber();
                        if (object.sectionMetadataValueId != null)
                            if ($util.Long)
                                (message.sectionMetadataValueId = $util.Long.fromValue(object.sectionMetadataValueId)).unsigned = false;
                            else if (typeof object.sectionMetadataValueId === "string")
                                message.sectionMetadataValueId = parseInt(object.sectionMetadataValueId, 10);
                            else if (typeof object.sectionMetadataValueId === "number")
                                message.sectionMetadataValueId = object.sectionMetadataValueId;
                            else if (typeof object.sectionMetadataValueId === "object")
                                message.sectionMetadataValueId = new $util.LongBits(object.sectionMetadataValueId.low >>> 0, object.sectionMetadataValueId.high >>> 0).toNumber();
                        if (object.posMetadataValue_1 != null)
                            message.posMetadataValue_1 = String(object.posMetadataValue_1);
                        if (object.posMetadataValue_2 != null)
                            message.posMetadataValue_2 = String(object.posMetadataValue_2);
                        if (object.posMetadataValue_3 != null)
                            message.posMetadataValue_3 = String(object.posMetadataValue_3);
                        if (object.posMetadataValue_4 != null)
                            message.posMetadataValue_4 = String(object.posMetadataValue_4);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.storeId != null) {
                            if (typeof object.storeId !== "object")
                                throw TypeError(".palexy.streaming.v1.PosSectionMappingItem.storeId: object expected");
                            message.storeId = $root.google.protobuf.Int64Value.fromObject(object.storeId);
                        }
                        if (object.productCode != null)
                            message.productCode = String(object.productCode);
                        if (object.sectionMetadataValue != null) {
                            if (typeof object.sectionMetadataValue !== "object")
                                throw TypeError(".palexy.streaming.v1.PosSectionMappingItem.sectionMetadataValue: object expected");
                            message.sectionMetadataValue = $root.palexy.streaming.v1.MetadataValue.fromObject(object.sectionMetadataValue);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosSectionMappingItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @static
                     * @param {palexy.streaming.v1.PosSectionMappingItem} message PosSectionMappingItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosSectionMappingItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sectionMappingId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sectionMappingId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sectionMetadataValueId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sectionMetadataValueId = options.longs === String ? "0" : 0;
                            object.posMetadataValue_1 = "";
                            object.posMetadataValue_2 = "";
                            object.posMetadataValue_3 = "";
                            object.posMetadataValue_4 = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            object.sectionMetadataValue = null;
                            object.storeId = null;
                            object.productCode = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (typeof message.sectionMappingId === "number")
                                object.sectionMappingId = options.longs === String ? String(message.sectionMappingId) : message.sectionMappingId;
                            else
                                object.sectionMappingId = options.longs === String ? $util.Long.prototype.toString.call(message.sectionMappingId) : options.longs === Number ? new $util.LongBits(message.sectionMappingId.low >>> 0, message.sectionMappingId.high >>> 0).toNumber() : message.sectionMappingId;
                        if (message.sectionMetadataValueId != null && message.hasOwnProperty("sectionMetadataValueId"))
                            if (typeof message.sectionMetadataValueId === "number")
                                object.sectionMetadataValueId = options.longs === String ? String(message.sectionMetadataValueId) : message.sectionMetadataValueId;
                            else
                                object.sectionMetadataValueId = options.longs === String ? $util.Long.prototype.toString.call(message.sectionMetadataValueId) : options.longs === Number ? new $util.LongBits(message.sectionMetadataValueId.low >>> 0, message.sectionMetadataValueId.high >>> 0).toNumber() : message.sectionMetadataValueId;
                        if (message.posMetadataValue_1 != null && message.hasOwnProperty("posMetadataValue_1"))
                            object.posMetadataValue_1 = message.posMetadataValue_1;
                        if (message.posMetadataValue_2 != null && message.hasOwnProperty("posMetadataValue_2"))
                            object.posMetadataValue_2 = message.posMetadataValue_2;
                        if (message.posMetadataValue_3 != null && message.hasOwnProperty("posMetadataValue_3"))
                            object.posMetadataValue_3 = message.posMetadataValue_3;
                        if (message.posMetadataValue_4 != null && message.hasOwnProperty("posMetadataValue_4"))
                            object.posMetadataValue_4 = message.posMetadataValue_4;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.sectionMetadataValue != null && message.hasOwnProperty("sectionMetadataValue"))
                            object.sectionMetadataValue = $root.palexy.streaming.v1.MetadataValue.toObject(message.sectionMetadataValue, options);
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = $root.google.protobuf.Int64Value.toObject(message.storeId, options);
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            object.productCode = message.productCode;
                        return object;
                    };
    
                    /**
                     * Converts this PosSectionMappingItem to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosSectionMappingItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosSectionMappingItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosSectionMappingItem;
                })();
    
                v1.ListSectionMappingRequest = (function() {
    
                    /**
                     * Properties of a ListSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListSectionMappingRequest
                     * @property {number|null} [companyId] ListSectionMappingRequest companyId
                     * @property {google.protobuf.IInt64Value|null} [sectionMetadataTypeId] ListSectionMappingRequest sectionMetadataTypeId
                     * @property {number|null} [pageSize] ListSectionMappingRequest pageSize
                     * @property {string|null} [pageToken] ListSectionMappingRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListSectionMappingRequest.
                     * @implements IListSectionMappingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListSectionMappingRequest=} [properties] Properties to set
                     */
                    function ListSectionMappingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListSectionMappingRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @instance
                     */
                    ListSectionMappingRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListSectionMappingRequest sectionMetadataTypeId.
                     * @member {google.protobuf.IInt64Value|null|undefined} sectionMetadataTypeId
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @instance
                     */
                    ListSectionMappingRequest.prototype.sectionMetadataTypeId = null;
    
                    /**
                     * ListSectionMappingRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @instance
                     */
                    ListSectionMappingRequest.prototype.pageSize = 0;
    
                    /**
                     * ListSectionMappingRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @instance
                     */
                    ListSectionMappingRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListSectionMappingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListSectionMappingRequest} ListSectionMappingRequest instance
                     */
                    ListSectionMappingRequest.create = function create(properties) {
                        return new ListSectionMappingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListSectionMappingRequest message. Does not implicitly {@link palexy.streaming.v1.ListSectionMappingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingRequest} message ListSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSectionMappingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.sectionMetadataTypeId != null && Object.hasOwnProperty.call(message, "sectionMetadataTypeId"))
                            $root.google.protobuf.Int64Value.encode(message.sectionMetadataTypeId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListSectionMappingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListSectionMappingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingRequest} message ListSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSectionMappingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListSectionMappingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListSectionMappingRequest} ListSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSectionMappingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListSectionMappingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.sectionMetadataTypeId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.pageSize = reader.int32();
                                break;
                            case 4:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListSectionMappingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListSectionMappingRequest} ListSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSectionMappingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListSectionMappingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListSectionMappingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.sectionMetadataTypeId != null && message.hasOwnProperty("sectionMetadataTypeId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.sectionMetadataTypeId);
                            if (error)
                                return "sectionMetadataTypeId." + error;
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListSectionMappingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListSectionMappingRequest} ListSectionMappingRequest
                     */
                    ListSectionMappingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListSectionMappingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListSectionMappingRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.sectionMetadataTypeId != null) {
                            if (typeof object.sectionMetadataTypeId !== "object")
                                throw TypeError(".palexy.streaming.v1.ListSectionMappingRequest.sectionMetadataTypeId: object expected");
                            message.sectionMetadataTypeId = $root.google.protobuf.Int64Value.fromObject(object.sectionMetadataTypeId);
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListSectionMappingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.ListSectionMappingRequest} message ListSectionMappingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListSectionMappingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.sectionMetadataTypeId = null;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.sectionMetadataTypeId != null && message.hasOwnProperty("sectionMetadataTypeId"))
                            object.sectionMetadataTypeId = $root.google.protobuf.Int64Value.toObject(message.sectionMetadataTypeId, options);
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListSectionMappingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListSectionMappingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListSectionMappingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListSectionMappingRequest;
                })();
    
                v1.ListSectionMappingResponse = (function() {
    
                    /**
                     * Properties of a ListSectionMappingResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListSectionMappingResponse
                     * @property {Array.<palexy.streaming.v1.IPosSectionMapping>|null} [sectionMappingList] ListSectionMappingResponse sectionMappingList
                     * @property {number|null} [totalElements] ListSectionMappingResponse totalElements
                     * @property {string|null} [nextPageToken] ListSectionMappingResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListSectionMappingResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListSectionMappingResponse.
                     * @implements IListSectionMappingResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListSectionMappingResponse=} [properties] Properties to set
                     */
                    function ListSectionMappingResponse(properties) {
                        this.sectionMappingList = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListSectionMappingResponse sectionMappingList.
                     * @member {Array.<palexy.streaming.v1.IPosSectionMapping>} sectionMappingList
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @instance
                     */
                    ListSectionMappingResponse.prototype.sectionMappingList = $util.emptyArray;
    
                    /**
                     * ListSectionMappingResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @instance
                     */
                    ListSectionMappingResponse.prototype.totalElements = 0;
    
                    /**
                     * ListSectionMappingResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @instance
                     */
                    ListSectionMappingResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListSectionMappingResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListSectionMappingResponse} ListSectionMappingResponse instance
                     */
                    ListSectionMappingResponse.create = function create(properties) {
                        return new ListSectionMappingResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListSectionMappingResponse message. Does not implicitly {@link palexy.streaming.v1.ListSectionMappingResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingResponse} message ListSectionMappingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSectionMappingResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMappingList != null && message.sectionMappingList.length)
                            for (var i = 0; i < message.sectionMappingList.length; ++i)
                                $root.palexy.streaming.v1.PosSectionMapping.encode(message.sectionMappingList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListSectionMappingResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListSectionMappingResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingResponse} message ListSectionMappingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSectionMappingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListSectionMappingResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListSectionMappingResponse} ListSectionMappingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSectionMappingResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListSectionMappingResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.sectionMappingList && message.sectionMappingList.length))
                                    message.sectionMappingList = [];
                                message.sectionMappingList.push($root.palexy.streaming.v1.PosSectionMapping.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListSectionMappingResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListSectionMappingResponse} ListSectionMappingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSectionMappingResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListSectionMappingResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListSectionMappingResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sectionMappingList != null && message.hasOwnProperty("sectionMappingList")) {
                            if (!Array.isArray(message.sectionMappingList))
                                return "sectionMappingList: array expected";
                            for (var i = 0; i < message.sectionMappingList.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosSectionMapping.verify(message.sectionMappingList[i]);
                                if (error)
                                    return "sectionMappingList." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListSectionMappingResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListSectionMappingResponse} ListSectionMappingResponse
                     */
                    ListSectionMappingResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListSectionMappingResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListSectionMappingResponse();
                        if (object.sectionMappingList) {
                            if (!Array.isArray(object.sectionMappingList))
                                throw TypeError(".palexy.streaming.v1.ListSectionMappingResponse.sectionMappingList: array expected");
                            message.sectionMappingList = [];
                            for (var i = 0; i < object.sectionMappingList.length; ++i) {
                                if (typeof object.sectionMappingList[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListSectionMappingResponse.sectionMappingList: object expected");
                                message.sectionMappingList[i] = $root.palexy.streaming.v1.PosSectionMapping.fromObject(object.sectionMappingList[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListSectionMappingResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @static
                     * @param {palexy.streaming.v1.ListSectionMappingResponse} message ListSectionMappingResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListSectionMappingResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.sectionMappingList = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.sectionMappingList && message.sectionMappingList.length) {
                            object.sectionMappingList = [];
                            for (var j = 0; j < message.sectionMappingList.length; ++j)
                                object.sectionMappingList[j] = $root.palexy.streaming.v1.PosSectionMapping.toObject(message.sectionMappingList[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListSectionMappingResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListSectionMappingResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListSectionMappingResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListSectionMappingResponse;
                })();
    
                v1.CreateSectionMappingRequest = (function() {
    
                    /**
                     * Properties of a CreateSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateSectionMappingRequest
                     * @property {palexy.streaming.v1.IPosSectionMapping|null} [sectionMapping] CreateSectionMappingRequest sectionMapping
                     * @property {boolean|null} [skipChangeEvent] CreateSectionMappingRequest skipChangeEvent
                     */
    
                    /**
                     * Constructs a new CreateSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateSectionMappingRequest.
                     * @implements ICreateSectionMappingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateSectionMappingRequest=} [properties] Properties to set
                     */
                    function CreateSectionMappingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateSectionMappingRequest sectionMapping.
                     * @member {palexy.streaming.v1.IPosSectionMapping|null|undefined} sectionMapping
                     * @memberof palexy.streaming.v1.CreateSectionMappingRequest
                     * @instance
                     */
                    CreateSectionMappingRequest.prototype.sectionMapping = null;
    
                    /**
                     * CreateSectionMappingRequest skipChangeEvent.
                     * @member {boolean} skipChangeEvent
                     * @memberof palexy.streaming.v1.CreateSectionMappingRequest
                     * @instance
                     */
                    CreateSectionMappingRequest.prototype.skipChangeEvent = false;
    
                    /**
                     * Creates a new CreateSectionMappingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateSectionMappingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateSectionMappingRequest} CreateSectionMappingRequest instance
                     */
                    CreateSectionMappingRequest.create = function create(properties) {
                        return new CreateSectionMappingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateSectionMappingRequest message. Does not implicitly {@link palexy.streaming.v1.CreateSectionMappingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateSectionMappingRequest} message CreateSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateSectionMappingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMapping != null && Object.hasOwnProperty.call(message, "sectionMapping"))
                            $root.palexy.streaming.v1.PosSectionMapping.encode(message.sectionMapping, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.skipChangeEvent != null && Object.hasOwnProperty.call(message, "skipChangeEvent"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.skipChangeEvent);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateSectionMappingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateSectionMappingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateSectionMappingRequest} message CreateSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateSectionMappingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateSectionMappingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateSectionMappingRequest} CreateSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateSectionMappingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateSectionMappingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sectionMapping = $root.palexy.streaming.v1.PosSectionMapping.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.skipChangeEvent = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateSectionMappingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateSectionMappingRequest} CreateSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateSectionMappingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateSectionMappingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateSectionMappingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sectionMapping != null && message.hasOwnProperty("sectionMapping")) {
                            var error = $root.palexy.streaming.v1.PosSectionMapping.verify(message.sectionMapping);
                            if (error)
                                return "sectionMapping." + error;
                        }
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            if (typeof message.skipChangeEvent !== "boolean")
                                return "skipChangeEvent: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateSectionMappingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateSectionMappingRequest} CreateSectionMappingRequest
                     */
                    CreateSectionMappingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateSectionMappingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateSectionMappingRequest();
                        if (object.sectionMapping != null) {
                            if (typeof object.sectionMapping !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateSectionMappingRequest.sectionMapping: object expected");
                            message.sectionMapping = $root.palexy.streaming.v1.PosSectionMapping.fromObject(object.sectionMapping);
                        }
                        if (object.skipChangeEvent != null)
                            message.skipChangeEvent = Boolean(object.skipChangeEvent);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateSectionMappingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateSectionMappingRequest} message CreateSectionMappingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateSectionMappingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.sectionMapping = null;
                            object.skipChangeEvent = false;
                        }
                        if (message.sectionMapping != null && message.hasOwnProperty("sectionMapping"))
                            object.sectionMapping = $root.palexy.streaming.v1.PosSectionMapping.toObject(message.sectionMapping, options);
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            object.skipChangeEvent = message.skipChangeEvent;
                        return object;
                    };
    
                    /**
                     * Converts this CreateSectionMappingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateSectionMappingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateSectionMappingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateSectionMappingRequest;
                })();
    
                v1.UpdateSectionMappingRequest = (function() {
    
                    /**
                     * Properties of an UpdateSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateSectionMappingRequest
                     * @property {palexy.streaming.v1.IPosSectionMapping|null} [sectionMapping] UpdateSectionMappingRequest sectionMapping
                     * @property {boolean|null} [skipChangeEvent] UpdateSectionMappingRequest skipChangeEvent
                     */
    
                    /**
                     * Constructs a new UpdateSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateSectionMappingRequest.
                     * @implements IUpdateSectionMappingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateSectionMappingRequest=} [properties] Properties to set
                     */
                    function UpdateSectionMappingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateSectionMappingRequest sectionMapping.
                     * @member {palexy.streaming.v1.IPosSectionMapping|null|undefined} sectionMapping
                     * @memberof palexy.streaming.v1.UpdateSectionMappingRequest
                     * @instance
                     */
                    UpdateSectionMappingRequest.prototype.sectionMapping = null;
    
                    /**
                     * UpdateSectionMappingRequest skipChangeEvent.
                     * @member {boolean} skipChangeEvent
                     * @memberof palexy.streaming.v1.UpdateSectionMappingRequest
                     * @instance
                     */
                    UpdateSectionMappingRequest.prototype.skipChangeEvent = false;
    
                    /**
                     * Creates a new UpdateSectionMappingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateSectionMappingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateSectionMappingRequest} UpdateSectionMappingRequest instance
                     */
                    UpdateSectionMappingRequest.create = function create(properties) {
                        return new UpdateSectionMappingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateSectionMappingRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateSectionMappingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateSectionMappingRequest} message UpdateSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateSectionMappingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMapping != null && Object.hasOwnProperty.call(message, "sectionMapping"))
                            $root.palexy.streaming.v1.PosSectionMapping.encode(message.sectionMapping, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.skipChangeEvent != null && Object.hasOwnProperty.call(message, "skipChangeEvent"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.skipChangeEvent);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateSectionMappingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateSectionMappingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateSectionMappingRequest} message UpdateSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateSectionMappingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateSectionMappingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateSectionMappingRequest} UpdateSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateSectionMappingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateSectionMappingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sectionMapping = $root.palexy.streaming.v1.PosSectionMapping.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.skipChangeEvent = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateSectionMappingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateSectionMappingRequest} UpdateSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateSectionMappingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateSectionMappingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateSectionMappingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sectionMapping != null && message.hasOwnProperty("sectionMapping")) {
                            var error = $root.palexy.streaming.v1.PosSectionMapping.verify(message.sectionMapping);
                            if (error)
                                return "sectionMapping." + error;
                        }
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            if (typeof message.skipChangeEvent !== "boolean")
                                return "skipChangeEvent: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpdateSectionMappingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateSectionMappingRequest} UpdateSectionMappingRequest
                     */
                    UpdateSectionMappingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateSectionMappingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateSectionMappingRequest();
                        if (object.sectionMapping != null) {
                            if (typeof object.sectionMapping !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateSectionMappingRequest.sectionMapping: object expected");
                            message.sectionMapping = $root.palexy.streaming.v1.PosSectionMapping.fromObject(object.sectionMapping);
                        }
                        if (object.skipChangeEvent != null)
                            message.skipChangeEvent = Boolean(object.skipChangeEvent);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateSectionMappingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateSectionMappingRequest} message UpdateSectionMappingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateSectionMappingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.sectionMapping = null;
                            object.skipChangeEvent = false;
                        }
                        if (message.sectionMapping != null && message.hasOwnProperty("sectionMapping"))
                            object.sectionMapping = $root.palexy.streaming.v1.PosSectionMapping.toObject(message.sectionMapping, options);
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            object.skipChangeEvent = message.skipChangeEvent;
                        return object;
                    };
    
                    /**
                     * Converts this UpdateSectionMappingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateSectionMappingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateSectionMappingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateSectionMappingRequest;
                })();
    
                v1.DeleteSectionMappingRequest = (function() {
    
                    /**
                     * Properties of a DeleteSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteSectionMappingRequest
                     * @property {number|null} [sectionMappingId] DeleteSectionMappingRequest sectionMappingId
                     * @property {boolean|null} [skipChangeEvent] DeleteSectionMappingRequest skipChangeEvent
                     */
    
                    /**
                     * Constructs a new DeleteSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteSectionMappingRequest.
                     * @implements IDeleteSectionMappingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteSectionMappingRequest=} [properties] Properties to set
                     */
                    function DeleteSectionMappingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteSectionMappingRequest sectionMappingId.
                     * @member {number} sectionMappingId
                     * @memberof palexy.streaming.v1.DeleteSectionMappingRequest
                     * @instance
                     */
                    DeleteSectionMappingRequest.prototype.sectionMappingId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DeleteSectionMappingRequest skipChangeEvent.
                     * @member {boolean} skipChangeEvent
                     * @memberof palexy.streaming.v1.DeleteSectionMappingRequest
                     * @instance
                     */
                    DeleteSectionMappingRequest.prototype.skipChangeEvent = false;
    
                    /**
                     * Creates a new DeleteSectionMappingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteSectionMappingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteSectionMappingRequest} DeleteSectionMappingRequest instance
                     */
                    DeleteSectionMappingRequest.create = function create(properties) {
                        return new DeleteSectionMappingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteSectionMappingRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteSectionMappingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteSectionMappingRequest} message DeleteSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteSectionMappingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMappingId != null && Object.hasOwnProperty.call(message, "sectionMappingId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sectionMappingId);
                        if (message.skipChangeEvent != null && Object.hasOwnProperty.call(message, "skipChangeEvent"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.skipChangeEvent);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteSectionMappingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteSectionMappingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteSectionMappingRequest} message DeleteSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteSectionMappingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteSectionMappingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteSectionMappingRequest} DeleteSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteSectionMappingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteSectionMappingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sectionMappingId = reader.int64();
                                break;
                            case 2:
                                message.skipChangeEvent = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteSectionMappingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteSectionMappingRequest} DeleteSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteSectionMappingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteSectionMappingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteSectionMappingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (!$util.isInteger(message.sectionMappingId) && !(message.sectionMappingId && $util.isInteger(message.sectionMappingId.low) && $util.isInteger(message.sectionMappingId.high)))
                                return "sectionMappingId: integer|Long expected";
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            if (typeof message.skipChangeEvent !== "boolean")
                                return "skipChangeEvent: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteSectionMappingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteSectionMappingRequest} DeleteSectionMappingRequest
                     */
                    DeleteSectionMappingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteSectionMappingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteSectionMappingRequest();
                        if (object.sectionMappingId != null)
                            if ($util.Long)
                                (message.sectionMappingId = $util.Long.fromValue(object.sectionMappingId)).unsigned = false;
                            else if (typeof object.sectionMappingId === "string")
                                message.sectionMappingId = parseInt(object.sectionMappingId, 10);
                            else if (typeof object.sectionMappingId === "number")
                                message.sectionMappingId = object.sectionMappingId;
                            else if (typeof object.sectionMappingId === "object")
                                message.sectionMappingId = new $util.LongBits(object.sectionMappingId.low >>> 0, object.sectionMappingId.high >>> 0).toNumber();
                        if (object.skipChangeEvent != null)
                            message.skipChangeEvent = Boolean(object.skipChangeEvent);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteSectionMappingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteSectionMappingRequest} message DeleteSectionMappingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteSectionMappingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sectionMappingId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sectionMappingId = options.longs === String ? "0" : 0;
                            object.skipChangeEvent = false;
                        }
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (typeof message.sectionMappingId === "number")
                                object.sectionMappingId = options.longs === String ? String(message.sectionMappingId) : message.sectionMappingId;
                            else
                                object.sectionMappingId = options.longs === String ? $util.Long.prototype.toString.call(message.sectionMappingId) : options.longs === Number ? new $util.LongBits(message.sectionMappingId.low >>> 0, message.sectionMappingId.high >>> 0).toNumber() : message.sectionMappingId;
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            object.skipChangeEvent = message.skipChangeEvent;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteSectionMappingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteSectionMappingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteSectionMappingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteSectionMappingRequest;
                })();
    
                v1.GetSectionMappingRequest = (function() {
    
                    /**
                     * Properties of a GetSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetSectionMappingRequest
                     * @property {number|null} [sectionMappingId] GetSectionMappingRequest sectionMappingId
                     * @property {boolean|null} [includeMetadataInfo] GetSectionMappingRequest includeMetadataInfo
                     */
    
                    /**
                     * Constructs a new GetSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetSectionMappingRequest.
                     * @implements IGetSectionMappingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetSectionMappingRequest=} [properties] Properties to set
                     */
                    function GetSectionMappingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetSectionMappingRequest sectionMappingId.
                     * @member {number} sectionMappingId
                     * @memberof palexy.streaming.v1.GetSectionMappingRequest
                     * @instance
                     */
                    GetSectionMappingRequest.prototype.sectionMappingId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetSectionMappingRequest includeMetadataInfo.
                     * @member {boolean} includeMetadataInfo
                     * @memberof palexy.streaming.v1.GetSectionMappingRequest
                     * @instance
                     */
                    GetSectionMappingRequest.prototype.includeMetadataInfo = false;
    
                    /**
                     * Creates a new GetSectionMappingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetSectionMappingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetSectionMappingRequest} GetSectionMappingRequest instance
                     */
                    GetSectionMappingRequest.create = function create(properties) {
                        return new GetSectionMappingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetSectionMappingRequest message. Does not implicitly {@link palexy.streaming.v1.GetSectionMappingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetSectionMappingRequest} message GetSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetSectionMappingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMappingId != null && Object.hasOwnProperty.call(message, "sectionMappingId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sectionMappingId);
                        if (message.includeMetadataInfo != null && Object.hasOwnProperty.call(message, "includeMetadataInfo"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeMetadataInfo);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetSectionMappingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetSectionMappingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetSectionMappingRequest} message GetSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetSectionMappingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetSectionMappingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetSectionMappingRequest} GetSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetSectionMappingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetSectionMappingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sectionMappingId = reader.int64();
                                break;
                            case 2:
                                message.includeMetadataInfo = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetSectionMappingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetSectionMappingRequest} GetSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetSectionMappingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetSectionMappingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetSectionMappingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (!$util.isInteger(message.sectionMappingId) && !(message.sectionMappingId && $util.isInteger(message.sectionMappingId.low) && $util.isInteger(message.sectionMappingId.high)))
                                return "sectionMappingId: integer|Long expected";
                        if (message.includeMetadataInfo != null && message.hasOwnProperty("includeMetadataInfo"))
                            if (typeof message.includeMetadataInfo !== "boolean")
                                return "includeMetadataInfo: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetSectionMappingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetSectionMappingRequest} GetSectionMappingRequest
                     */
                    GetSectionMappingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetSectionMappingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetSectionMappingRequest();
                        if (object.sectionMappingId != null)
                            if ($util.Long)
                                (message.sectionMappingId = $util.Long.fromValue(object.sectionMappingId)).unsigned = false;
                            else if (typeof object.sectionMappingId === "string")
                                message.sectionMappingId = parseInt(object.sectionMappingId, 10);
                            else if (typeof object.sectionMappingId === "number")
                                message.sectionMappingId = object.sectionMappingId;
                            else if (typeof object.sectionMappingId === "object")
                                message.sectionMappingId = new $util.LongBits(object.sectionMappingId.low >>> 0, object.sectionMappingId.high >>> 0).toNumber();
                        if (object.includeMetadataInfo != null)
                            message.includeMetadataInfo = Boolean(object.includeMetadataInfo);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetSectionMappingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.GetSectionMappingRequest} message GetSectionMappingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetSectionMappingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sectionMappingId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sectionMappingId = options.longs === String ? "0" : 0;
                            object.includeMetadataInfo = false;
                        }
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (typeof message.sectionMappingId === "number")
                                object.sectionMappingId = options.longs === String ? String(message.sectionMappingId) : message.sectionMappingId;
                            else
                                object.sectionMappingId = options.longs === String ? $util.Long.prototype.toString.call(message.sectionMappingId) : options.longs === Number ? new $util.LongBits(message.sectionMappingId.low >>> 0, message.sectionMappingId.high >>> 0).toNumber() : message.sectionMappingId;
                        if (message.includeMetadataInfo != null && message.hasOwnProperty("includeMetadataInfo"))
                            object.includeMetadataInfo = message.includeMetadataInfo;
                        return object;
                    };
    
                    /**
                     * Converts this GetSectionMappingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetSectionMappingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetSectionMappingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetSectionMappingRequest;
                })();
    
                v1.CloneSectionMappingRequest = (function() {
    
                    /**
                     * Properties of a CloneSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICloneSectionMappingRequest
                     * @property {number|null} [cloningSectionMappingId] CloneSectionMappingRequest cloningSectionMappingId
                     * @property {string|null} [name] CloneSectionMappingRequest name
                     * @property {string|null} [appliedDate] CloneSectionMappingRequest appliedDate
                     * @property {boolean|null} [skipChangeEvent] CloneSectionMappingRequest skipChangeEvent
                     */
    
                    /**
                     * Constructs a new CloneSectionMappingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CloneSectionMappingRequest.
                     * @implements ICloneSectionMappingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICloneSectionMappingRequest=} [properties] Properties to set
                     */
                    function CloneSectionMappingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CloneSectionMappingRequest cloningSectionMappingId.
                     * @member {number} cloningSectionMappingId
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @instance
                     */
                    CloneSectionMappingRequest.prototype.cloningSectionMappingId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CloneSectionMappingRequest name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @instance
                     */
                    CloneSectionMappingRequest.prototype.name = "";
    
                    /**
                     * CloneSectionMappingRequest appliedDate.
                     * @member {string} appliedDate
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @instance
                     */
                    CloneSectionMappingRequest.prototype.appliedDate = "";
    
                    /**
                     * CloneSectionMappingRequest skipChangeEvent.
                     * @member {boolean} skipChangeEvent
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @instance
                     */
                    CloneSectionMappingRequest.prototype.skipChangeEvent = false;
    
                    /**
                     * Creates a new CloneSectionMappingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.ICloneSectionMappingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CloneSectionMappingRequest} CloneSectionMappingRequest instance
                     */
                    CloneSectionMappingRequest.create = function create(properties) {
                        return new CloneSectionMappingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CloneSectionMappingRequest message. Does not implicitly {@link palexy.streaming.v1.CloneSectionMappingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.ICloneSectionMappingRequest} message CloneSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CloneSectionMappingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cloningSectionMappingId != null && Object.hasOwnProperty.call(message, "cloningSectionMappingId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.cloningSectionMappingId);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.appliedDate != null && Object.hasOwnProperty.call(message, "appliedDate"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.appliedDate);
                        if (message.skipChangeEvent != null && Object.hasOwnProperty.call(message, "skipChangeEvent"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.skipChangeEvent);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CloneSectionMappingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CloneSectionMappingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.ICloneSectionMappingRequest} message CloneSectionMappingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CloneSectionMappingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CloneSectionMappingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CloneSectionMappingRequest} CloneSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CloneSectionMappingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CloneSectionMappingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cloningSectionMappingId = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.appliedDate = reader.string();
                                break;
                            case 4:
                                message.skipChangeEvent = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CloneSectionMappingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CloneSectionMappingRequest} CloneSectionMappingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CloneSectionMappingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CloneSectionMappingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CloneSectionMappingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cloningSectionMappingId != null && message.hasOwnProperty("cloningSectionMappingId"))
                            if (!$util.isInteger(message.cloningSectionMappingId) && !(message.cloningSectionMappingId && $util.isInteger(message.cloningSectionMappingId.low) && $util.isInteger(message.cloningSectionMappingId.high)))
                                return "cloningSectionMappingId: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.appliedDate != null && message.hasOwnProperty("appliedDate"))
                            if (!$util.isString(message.appliedDate))
                                return "appliedDate: string expected";
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            if (typeof message.skipChangeEvent !== "boolean")
                                return "skipChangeEvent: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a CloneSectionMappingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CloneSectionMappingRequest} CloneSectionMappingRequest
                     */
                    CloneSectionMappingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CloneSectionMappingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CloneSectionMappingRequest();
                        if (object.cloningSectionMappingId != null)
                            if ($util.Long)
                                (message.cloningSectionMappingId = $util.Long.fromValue(object.cloningSectionMappingId)).unsigned = false;
                            else if (typeof object.cloningSectionMappingId === "string")
                                message.cloningSectionMappingId = parseInt(object.cloningSectionMappingId, 10);
                            else if (typeof object.cloningSectionMappingId === "number")
                                message.cloningSectionMappingId = object.cloningSectionMappingId;
                            else if (typeof object.cloningSectionMappingId === "object")
                                message.cloningSectionMappingId = new $util.LongBits(object.cloningSectionMappingId.low >>> 0, object.cloningSectionMappingId.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.appliedDate != null)
                            message.appliedDate = String(object.appliedDate);
                        if (object.skipChangeEvent != null)
                            message.skipChangeEvent = Boolean(object.skipChangeEvent);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CloneSectionMappingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @static
                     * @param {palexy.streaming.v1.CloneSectionMappingRequest} message CloneSectionMappingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CloneSectionMappingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cloningSectionMappingId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cloningSectionMappingId = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.appliedDate = "";
                            object.skipChangeEvent = false;
                        }
                        if (message.cloningSectionMappingId != null && message.hasOwnProperty("cloningSectionMappingId"))
                            if (typeof message.cloningSectionMappingId === "number")
                                object.cloningSectionMappingId = options.longs === String ? String(message.cloningSectionMappingId) : message.cloningSectionMappingId;
                            else
                                object.cloningSectionMappingId = options.longs === String ? $util.Long.prototype.toString.call(message.cloningSectionMappingId) : options.longs === Number ? new $util.LongBits(message.cloningSectionMappingId.low >>> 0, message.cloningSectionMappingId.high >>> 0).toNumber() : message.cloningSectionMappingId;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.appliedDate != null && message.hasOwnProperty("appliedDate"))
                            object.appliedDate = message.appliedDate;
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            object.skipChangeEvent = message.skipChangeEvent;
                        return object;
                    };
    
                    /**
                     * Converts this CloneSectionMappingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CloneSectionMappingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CloneSectionMappingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CloneSectionMappingRequest;
                })();
    
                v1.ListSectionMappingItemRequest = (function() {
    
                    /**
                     * Properties of a ListSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListSectionMappingItemRequest
                     * @property {number|null} [sectionMappingId] ListSectionMappingItemRequest sectionMappingId
                     * @property {google.protobuf.IInt64Value|null} [sectionMetadataValueId] ListSectionMappingItemRequest sectionMetadataValueId
                     * @property {google.protobuf.IInt64Value|null} [storeId] ListSectionMappingItemRequest storeId
                     * @property {number|null} [pageSize] ListSectionMappingItemRequest pageSize
                     * @property {string|null} [pageToken] ListSectionMappingItemRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListSectionMappingItemRequest.
                     * @implements IListSectionMappingItemRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListSectionMappingItemRequest=} [properties] Properties to set
                     */
                    function ListSectionMappingItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListSectionMappingItemRequest sectionMappingId.
                     * @member {number} sectionMappingId
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @instance
                     */
                    ListSectionMappingItemRequest.prototype.sectionMappingId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListSectionMappingItemRequest sectionMetadataValueId.
                     * @member {google.protobuf.IInt64Value|null|undefined} sectionMetadataValueId
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @instance
                     */
                    ListSectionMappingItemRequest.prototype.sectionMetadataValueId = null;
    
                    /**
                     * ListSectionMappingItemRequest storeId.
                     * @member {google.protobuf.IInt64Value|null|undefined} storeId
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @instance
                     */
                    ListSectionMappingItemRequest.prototype.storeId = null;
    
                    /**
                     * ListSectionMappingItemRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @instance
                     */
                    ListSectionMappingItemRequest.prototype.pageSize = 0;
    
                    /**
                     * ListSectionMappingItemRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @instance
                     */
                    ListSectionMappingItemRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListSectionMappingItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingItemRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListSectionMappingItemRequest} ListSectionMappingItemRequest instance
                     */
                    ListSectionMappingItemRequest.create = function create(properties) {
                        return new ListSectionMappingItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListSectionMappingItemRequest message. Does not implicitly {@link palexy.streaming.v1.ListSectionMappingItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingItemRequest} message ListSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSectionMappingItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMappingId != null && Object.hasOwnProperty.call(message, "sectionMappingId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sectionMappingId);
                        if (message.sectionMetadataValueId != null && Object.hasOwnProperty.call(message, "sectionMetadataValueId"))
                            $root.google.protobuf.Int64Value.encode(message.sectionMetadataValueId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.pageToken);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            $root.google.protobuf.Int64Value.encode(message.storeId, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListSectionMappingItemRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListSectionMappingItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingItemRequest} message ListSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSectionMappingItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListSectionMappingItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListSectionMappingItemRequest} ListSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSectionMappingItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListSectionMappingItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sectionMappingId = reader.int64();
                                break;
                            case 2:
                                message.sectionMetadataValueId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.storeId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.pageSize = reader.int32();
                                break;
                            case 4:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListSectionMappingItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListSectionMappingItemRequest} ListSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSectionMappingItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListSectionMappingItemRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListSectionMappingItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (!$util.isInteger(message.sectionMappingId) && !(message.sectionMappingId && $util.isInteger(message.sectionMappingId.low) && $util.isInteger(message.sectionMappingId.high)))
                                return "sectionMappingId: integer|Long expected";
                        if (message.sectionMetadataValueId != null && message.hasOwnProperty("sectionMetadataValueId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.sectionMetadataValueId);
                            if (error)
                                return "sectionMetadataValueId." + error;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.storeId);
                            if (error)
                                return "storeId." + error;
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListSectionMappingItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListSectionMappingItemRequest} ListSectionMappingItemRequest
                     */
                    ListSectionMappingItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListSectionMappingItemRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListSectionMappingItemRequest();
                        if (object.sectionMappingId != null)
                            if ($util.Long)
                                (message.sectionMappingId = $util.Long.fromValue(object.sectionMappingId)).unsigned = false;
                            else if (typeof object.sectionMappingId === "string")
                                message.sectionMappingId = parseInt(object.sectionMappingId, 10);
                            else if (typeof object.sectionMappingId === "number")
                                message.sectionMappingId = object.sectionMappingId;
                            else if (typeof object.sectionMappingId === "object")
                                message.sectionMappingId = new $util.LongBits(object.sectionMappingId.low >>> 0, object.sectionMappingId.high >>> 0).toNumber();
                        if (object.sectionMetadataValueId != null) {
                            if (typeof object.sectionMetadataValueId !== "object")
                                throw TypeError(".palexy.streaming.v1.ListSectionMappingItemRequest.sectionMetadataValueId: object expected");
                            message.sectionMetadataValueId = $root.google.protobuf.Int64Value.fromObject(object.sectionMetadataValueId);
                        }
                        if (object.storeId != null) {
                            if (typeof object.storeId !== "object")
                                throw TypeError(".palexy.streaming.v1.ListSectionMappingItemRequest.storeId: object expected");
                            message.storeId = $root.google.protobuf.Int64Value.fromObject(object.storeId);
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListSectionMappingItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.ListSectionMappingItemRequest} message ListSectionMappingItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListSectionMappingItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sectionMappingId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sectionMappingId = options.longs === String ? "0" : 0;
                            object.sectionMetadataValueId = null;
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.storeId = null;
                        }
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (typeof message.sectionMappingId === "number")
                                object.sectionMappingId = options.longs === String ? String(message.sectionMappingId) : message.sectionMappingId;
                            else
                                object.sectionMappingId = options.longs === String ? $util.Long.prototype.toString.call(message.sectionMappingId) : options.longs === Number ? new $util.LongBits(message.sectionMappingId.low >>> 0, message.sectionMappingId.high >>> 0).toNumber() : message.sectionMappingId;
                        if (message.sectionMetadataValueId != null && message.hasOwnProperty("sectionMetadataValueId"))
                            object.sectionMetadataValueId = $root.google.protobuf.Int64Value.toObject(message.sectionMetadataValueId, options);
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = $root.google.protobuf.Int64Value.toObject(message.storeId, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListSectionMappingItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListSectionMappingItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListSectionMappingItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListSectionMappingItemRequest;
                })();
    
                v1.ListSectionMappingItemResponse = (function() {
    
                    /**
                     * Properties of a ListSectionMappingItemResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListSectionMappingItemResponse
                     * @property {Array.<palexy.streaming.v1.IPosSectionMappingItem>|null} [items] ListSectionMappingItemResponse items
                     * @property {number|null} [totalElements] ListSectionMappingItemResponse totalElements
                     * @property {string|null} [nextPageToken] ListSectionMappingItemResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListSectionMappingItemResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListSectionMappingItemResponse.
                     * @implements IListSectionMappingItemResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListSectionMappingItemResponse=} [properties] Properties to set
                     */
                    function ListSectionMappingItemResponse(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListSectionMappingItemResponse items.
                     * @member {Array.<palexy.streaming.v1.IPosSectionMappingItem>} items
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @instance
                     */
                    ListSectionMappingItemResponse.prototype.items = $util.emptyArray;
    
                    /**
                     * ListSectionMappingItemResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @instance
                     */
                    ListSectionMappingItemResponse.prototype.totalElements = 0;
    
                    /**
                     * ListSectionMappingItemResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @instance
                     */
                    ListSectionMappingItemResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListSectionMappingItemResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingItemResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListSectionMappingItemResponse} ListSectionMappingItemResponse instance
                     */
                    ListSectionMappingItemResponse.create = function create(properties) {
                        return new ListSectionMappingItemResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListSectionMappingItemResponse message. Does not implicitly {@link palexy.streaming.v1.ListSectionMappingItemResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingItemResponse} message ListSectionMappingItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSectionMappingItemResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.palexy.streaming.v1.PosSectionMappingItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListSectionMappingItemResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListSectionMappingItemResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @static
                     * @param {palexy.streaming.v1.IListSectionMappingItemResponse} message ListSectionMappingItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListSectionMappingItemResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListSectionMappingItemResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListSectionMappingItemResponse} ListSectionMappingItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSectionMappingItemResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListSectionMappingItemResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.palexy.streaming.v1.PosSectionMappingItem.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListSectionMappingItemResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListSectionMappingItemResponse} ListSectionMappingItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListSectionMappingItemResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListSectionMappingItemResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListSectionMappingItemResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosSectionMappingItem.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListSectionMappingItemResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListSectionMappingItemResponse} ListSectionMappingItemResponse
                     */
                    ListSectionMappingItemResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListSectionMappingItemResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListSectionMappingItemResponse();
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".palexy.streaming.v1.ListSectionMappingItemResponse.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListSectionMappingItemResponse.items: object expected");
                                message.items[i] = $root.palexy.streaming.v1.PosSectionMappingItem.fromObject(object.items[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListSectionMappingItemResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @static
                     * @param {palexy.streaming.v1.ListSectionMappingItemResponse} message ListSectionMappingItemResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListSectionMappingItemResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.palexy.streaming.v1.PosSectionMappingItem.toObject(message.items[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListSectionMappingItemResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListSectionMappingItemResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListSectionMappingItemResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListSectionMappingItemResponse;
                })();
    
                v1.GetSectionMappingItemRequest = (function() {
    
                    /**
                     * Properties of a GetSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetSectionMappingItemRequest
                     * @property {number|null} [sectionMappingId] GetSectionMappingItemRequest sectionMappingId
                     * @property {number|null} [sectionMappingItemId] GetSectionMappingItemRequest sectionMappingItemId
                     */
    
                    /**
                     * Constructs a new GetSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetSectionMappingItemRequest.
                     * @implements IGetSectionMappingItemRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetSectionMappingItemRequest=} [properties] Properties to set
                     */
                    function GetSectionMappingItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetSectionMappingItemRequest sectionMappingId.
                     * @member {number} sectionMappingId
                     * @memberof palexy.streaming.v1.GetSectionMappingItemRequest
                     * @instance
                     */
                    GetSectionMappingItemRequest.prototype.sectionMappingId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetSectionMappingItemRequest sectionMappingItemId.
                     * @member {number} sectionMappingItemId
                     * @memberof palexy.streaming.v1.GetSectionMappingItemRequest
                     * @instance
                     */
                    GetSectionMappingItemRequest.prototype.sectionMappingItemId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetSectionMappingItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetSectionMappingItemRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetSectionMappingItemRequest} GetSectionMappingItemRequest instance
                     */
                    GetSectionMappingItemRequest.create = function create(properties) {
                        return new GetSectionMappingItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetSectionMappingItemRequest message. Does not implicitly {@link palexy.streaming.v1.GetSectionMappingItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetSectionMappingItemRequest} message GetSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetSectionMappingItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMappingId != null && Object.hasOwnProperty.call(message, "sectionMappingId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sectionMappingId);
                        if (message.sectionMappingItemId != null && Object.hasOwnProperty.call(message, "sectionMappingItemId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.sectionMappingItemId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetSectionMappingItemRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetSectionMappingItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetSectionMappingItemRequest} message GetSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetSectionMappingItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetSectionMappingItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetSectionMappingItemRequest} GetSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetSectionMappingItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetSectionMappingItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sectionMappingId = reader.int64();
                                break;
                            case 2:
                                message.sectionMappingItemId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetSectionMappingItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetSectionMappingItemRequest} GetSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetSectionMappingItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetSectionMappingItemRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetSectionMappingItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (!$util.isInteger(message.sectionMappingId) && !(message.sectionMappingId && $util.isInteger(message.sectionMappingId.low) && $util.isInteger(message.sectionMappingId.high)))
                                return "sectionMappingId: integer|Long expected";
                        if (message.sectionMappingItemId != null && message.hasOwnProperty("sectionMappingItemId"))
                            if (!$util.isInteger(message.sectionMappingItemId) && !(message.sectionMappingItemId && $util.isInteger(message.sectionMappingItemId.low) && $util.isInteger(message.sectionMappingItemId.high)))
                                return "sectionMappingItemId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetSectionMappingItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetSectionMappingItemRequest} GetSectionMappingItemRequest
                     */
                    GetSectionMappingItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetSectionMappingItemRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetSectionMappingItemRequest();
                        if (object.sectionMappingId != null)
                            if ($util.Long)
                                (message.sectionMappingId = $util.Long.fromValue(object.sectionMappingId)).unsigned = false;
                            else if (typeof object.sectionMappingId === "string")
                                message.sectionMappingId = parseInt(object.sectionMappingId, 10);
                            else if (typeof object.sectionMappingId === "number")
                                message.sectionMappingId = object.sectionMappingId;
                            else if (typeof object.sectionMappingId === "object")
                                message.sectionMappingId = new $util.LongBits(object.sectionMappingId.low >>> 0, object.sectionMappingId.high >>> 0).toNumber();
                        if (object.sectionMappingItemId != null)
                            if ($util.Long)
                                (message.sectionMappingItemId = $util.Long.fromValue(object.sectionMappingItemId)).unsigned = false;
                            else if (typeof object.sectionMappingItemId === "string")
                                message.sectionMappingItemId = parseInt(object.sectionMappingItemId, 10);
                            else if (typeof object.sectionMappingItemId === "number")
                                message.sectionMappingItemId = object.sectionMappingItemId;
                            else if (typeof object.sectionMappingItemId === "object")
                                message.sectionMappingItemId = new $util.LongBits(object.sectionMappingItemId.low >>> 0, object.sectionMappingItemId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetSectionMappingItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.GetSectionMappingItemRequest} message GetSectionMappingItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetSectionMappingItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sectionMappingId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sectionMappingId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sectionMappingItemId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sectionMappingItemId = options.longs === String ? "0" : 0;
                        }
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (typeof message.sectionMappingId === "number")
                                object.sectionMappingId = options.longs === String ? String(message.sectionMappingId) : message.sectionMappingId;
                            else
                                object.sectionMappingId = options.longs === String ? $util.Long.prototype.toString.call(message.sectionMappingId) : options.longs === Number ? new $util.LongBits(message.sectionMappingId.low >>> 0, message.sectionMappingId.high >>> 0).toNumber() : message.sectionMappingId;
                        if (message.sectionMappingItemId != null && message.hasOwnProperty("sectionMappingItemId"))
                            if (typeof message.sectionMappingItemId === "number")
                                object.sectionMappingItemId = options.longs === String ? String(message.sectionMappingItemId) : message.sectionMappingItemId;
                            else
                                object.sectionMappingItemId = options.longs === String ? $util.Long.prototype.toString.call(message.sectionMappingItemId) : options.longs === Number ? new $util.LongBits(message.sectionMappingItemId.low >>> 0, message.sectionMappingItemId.high >>> 0).toNumber() : message.sectionMappingItemId;
                        return object;
                    };
    
                    /**
                     * Converts this GetSectionMappingItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetSectionMappingItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetSectionMappingItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetSectionMappingItemRequest;
                })();
    
                v1.CreateSectionMappingItemRequest = (function() {
    
                    /**
                     * Properties of a CreateSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateSectionMappingItemRequest
                     * @property {palexy.streaming.v1.IPosSectionMappingItem|null} [sectionMappingItem] CreateSectionMappingItemRequest sectionMappingItem
                     * @property {boolean|null} [skipChangeEvent] CreateSectionMappingItemRequest skipChangeEvent
                     */
    
                    /**
                     * Constructs a new CreateSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateSectionMappingItemRequest.
                     * @implements ICreateSectionMappingItemRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateSectionMappingItemRequest=} [properties] Properties to set
                     */
                    function CreateSectionMappingItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateSectionMappingItemRequest sectionMappingItem.
                     * @member {palexy.streaming.v1.IPosSectionMappingItem|null|undefined} sectionMappingItem
                     * @memberof palexy.streaming.v1.CreateSectionMappingItemRequest
                     * @instance
                     */
                    CreateSectionMappingItemRequest.prototype.sectionMappingItem = null;
    
                    /**
                     * CreateSectionMappingItemRequest skipChangeEvent.
                     * @member {boolean} skipChangeEvent
                     * @memberof palexy.streaming.v1.CreateSectionMappingItemRequest
                     * @instance
                     */
                    CreateSectionMappingItemRequest.prototype.skipChangeEvent = false;
    
                    /**
                     * Creates a new CreateSectionMappingItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateSectionMappingItemRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateSectionMappingItemRequest} CreateSectionMappingItemRequest instance
                     */
                    CreateSectionMappingItemRequest.create = function create(properties) {
                        return new CreateSectionMappingItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateSectionMappingItemRequest message. Does not implicitly {@link palexy.streaming.v1.CreateSectionMappingItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateSectionMappingItemRequest} message CreateSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateSectionMappingItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMappingItem != null && Object.hasOwnProperty.call(message, "sectionMappingItem"))
                            $root.palexy.streaming.v1.PosSectionMappingItem.encode(message.sectionMappingItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.skipChangeEvent != null && Object.hasOwnProperty.call(message, "skipChangeEvent"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.skipChangeEvent);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateSectionMappingItemRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateSectionMappingItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateSectionMappingItemRequest} message CreateSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateSectionMappingItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateSectionMappingItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateSectionMappingItemRequest} CreateSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateSectionMappingItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateSectionMappingItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sectionMappingItem = $root.palexy.streaming.v1.PosSectionMappingItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.skipChangeEvent = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateSectionMappingItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateSectionMappingItemRequest} CreateSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateSectionMappingItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateSectionMappingItemRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateSectionMappingItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sectionMappingItem != null && message.hasOwnProperty("sectionMappingItem")) {
                            var error = $root.palexy.streaming.v1.PosSectionMappingItem.verify(message.sectionMappingItem);
                            if (error)
                                return "sectionMappingItem." + error;
                        }
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            if (typeof message.skipChangeEvent !== "boolean")
                                return "skipChangeEvent: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateSectionMappingItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateSectionMappingItemRequest} CreateSectionMappingItemRequest
                     */
                    CreateSectionMappingItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateSectionMappingItemRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateSectionMappingItemRequest();
                        if (object.sectionMappingItem != null) {
                            if (typeof object.sectionMappingItem !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateSectionMappingItemRequest.sectionMappingItem: object expected");
                            message.sectionMappingItem = $root.palexy.streaming.v1.PosSectionMappingItem.fromObject(object.sectionMappingItem);
                        }
                        if (object.skipChangeEvent != null)
                            message.skipChangeEvent = Boolean(object.skipChangeEvent);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateSectionMappingItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateSectionMappingItemRequest} message CreateSectionMappingItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateSectionMappingItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.sectionMappingItem = null;
                            object.skipChangeEvent = false;
                        }
                        if (message.sectionMappingItem != null && message.hasOwnProperty("sectionMappingItem"))
                            object.sectionMappingItem = $root.palexy.streaming.v1.PosSectionMappingItem.toObject(message.sectionMappingItem, options);
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            object.skipChangeEvent = message.skipChangeEvent;
                        return object;
                    };
    
                    /**
                     * Converts this CreateSectionMappingItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateSectionMappingItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateSectionMappingItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateSectionMappingItemRequest;
                })();
    
                v1.UpdateSectionMappingItemRequest = (function() {
    
                    /**
                     * Properties of an UpdateSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateSectionMappingItemRequest
                     * @property {palexy.streaming.v1.IPosSectionMappingItem|null} [sectionMappingItem] UpdateSectionMappingItemRequest sectionMappingItem
                     * @property {boolean|null} [skipChangeEvent] UpdateSectionMappingItemRequest skipChangeEvent
                     */
    
                    /**
                     * Constructs a new UpdateSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateSectionMappingItemRequest.
                     * @implements IUpdateSectionMappingItemRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateSectionMappingItemRequest=} [properties] Properties to set
                     */
                    function UpdateSectionMappingItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateSectionMappingItemRequest sectionMappingItem.
                     * @member {palexy.streaming.v1.IPosSectionMappingItem|null|undefined} sectionMappingItem
                     * @memberof palexy.streaming.v1.UpdateSectionMappingItemRequest
                     * @instance
                     */
                    UpdateSectionMappingItemRequest.prototype.sectionMappingItem = null;
    
                    /**
                     * UpdateSectionMappingItemRequest skipChangeEvent.
                     * @member {boolean} skipChangeEvent
                     * @memberof palexy.streaming.v1.UpdateSectionMappingItemRequest
                     * @instance
                     */
                    UpdateSectionMappingItemRequest.prototype.skipChangeEvent = false;
    
                    /**
                     * Creates a new UpdateSectionMappingItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateSectionMappingItemRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateSectionMappingItemRequest} UpdateSectionMappingItemRequest instance
                     */
                    UpdateSectionMappingItemRequest.create = function create(properties) {
                        return new UpdateSectionMappingItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateSectionMappingItemRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateSectionMappingItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateSectionMappingItemRequest} message UpdateSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateSectionMappingItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMappingItem != null && Object.hasOwnProperty.call(message, "sectionMappingItem"))
                            $root.palexy.streaming.v1.PosSectionMappingItem.encode(message.sectionMappingItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.skipChangeEvent != null && Object.hasOwnProperty.call(message, "skipChangeEvent"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.skipChangeEvent);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateSectionMappingItemRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateSectionMappingItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateSectionMappingItemRequest} message UpdateSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateSectionMappingItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateSectionMappingItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateSectionMappingItemRequest} UpdateSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateSectionMappingItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateSectionMappingItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sectionMappingItem = $root.palexy.streaming.v1.PosSectionMappingItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.skipChangeEvent = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateSectionMappingItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateSectionMappingItemRequest} UpdateSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateSectionMappingItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateSectionMappingItemRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateSectionMappingItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sectionMappingItem != null && message.hasOwnProperty("sectionMappingItem")) {
                            var error = $root.palexy.streaming.v1.PosSectionMappingItem.verify(message.sectionMappingItem);
                            if (error)
                                return "sectionMappingItem." + error;
                        }
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            if (typeof message.skipChangeEvent !== "boolean")
                                return "skipChangeEvent: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpdateSectionMappingItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateSectionMappingItemRequest} UpdateSectionMappingItemRequest
                     */
                    UpdateSectionMappingItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateSectionMappingItemRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateSectionMappingItemRequest();
                        if (object.sectionMappingItem != null) {
                            if (typeof object.sectionMappingItem !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateSectionMappingItemRequest.sectionMappingItem: object expected");
                            message.sectionMappingItem = $root.palexy.streaming.v1.PosSectionMappingItem.fromObject(object.sectionMappingItem);
                        }
                        if (object.skipChangeEvent != null)
                            message.skipChangeEvent = Boolean(object.skipChangeEvent);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateSectionMappingItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateSectionMappingItemRequest} message UpdateSectionMappingItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateSectionMappingItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.sectionMappingItem = null;
                            object.skipChangeEvent = false;
                        }
                        if (message.sectionMappingItem != null && message.hasOwnProperty("sectionMappingItem"))
                            object.sectionMappingItem = $root.palexy.streaming.v1.PosSectionMappingItem.toObject(message.sectionMappingItem, options);
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            object.skipChangeEvent = message.skipChangeEvent;
                        return object;
                    };
    
                    /**
                     * Converts this UpdateSectionMappingItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateSectionMappingItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateSectionMappingItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateSectionMappingItemRequest;
                })();
    
                v1.DeleteSectionMappingItemRequest = (function() {
    
                    /**
                     * Properties of a DeleteSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteSectionMappingItemRequest
                     * @property {number|null} [sectionMappingId] DeleteSectionMappingItemRequest sectionMappingId
                     * @property {number|null} [sectionMappingItemId] DeleteSectionMappingItemRequest sectionMappingItemId
                     * @property {boolean|null} [skipChangeEvent] DeleteSectionMappingItemRequest skipChangeEvent
                     */
    
                    /**
                     * Constructs a new DeleteSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteSectionMappingItemRequest.
                     * @implements IDeleteSectionMappingItemRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteSectionMappingItemRequest=} [properties] Properties to set
                     */
                    function DeleteSectionMappingItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteSectionMappingItemRequest sectionMappingId.
                     * @member {number} sectionMappingId
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @instance
                     */
                    DeleteSectionMappingItemRequest.prototype.sectionMappingId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DeleteSectionMappingItemRequest sectionMappingItemId.
                     * @member {number} sectionMappingItemId
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @instance
                     */
                    DeleteSectionMappingItemRequest.prototype.sectionMappingItemId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DeleteSectionMappingItemRequest skipChangeEvent.
                     * @member {boolean} skipChangeEvent
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @instance
                     */
                    DeleteSectionMappingItemRequest.prototype.skipChangeEvent = false;
    
                    /**
                     * Creates a new DeleteSectionMappingItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteSectionMappingItemRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteSectionMappingItemRequest} DeleteSectionMappingItemRequest instance
                     */
                    DeleteSectionMappingItemRequest.create = function create(properties) {
                        return new DeleteSectionMappingItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteSectionMappingItemRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteSectionMappingItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteSectionMappingItemRequest} message DeleteSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteSectionMappingItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMappingId != null && Object.hasOwnProperty.call(message, "sectionMappingId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sectionMappingId);
                        if (message.sectionMappingItemId != null && Object.hasOwnProperty.call(message, "sectionMappingItemId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.sectionMappingItemId);
                        if (message.skipChangeEvent != null && Object.hasOwnProperty.call(message, "skipChangeEvent"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.skipChangeEvent);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteSectionMappingItemRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteSectionMappingItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteSectionMappingItemRequest} message DeleteSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteSectionMappingItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteSectionMappingItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteSectionMappingItemRequest} DeleteSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteSectionMappingItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteSectionMappingItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sectionMappingId = reader.int64();
                                break;
                            case 2:
                                message.sectionMappingItemId = reader.int64();
                                break;
                            case 3:
                                message.skipChangeEvent = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteSectionMappingItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteSectionMappingItemRequest} DeleteSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteSectionMappingItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteSectionMappingItemRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteSectionMappingItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (!$util.isInteger(message.sectionMappingId) && !(message.sectionMappingId && $util.isInteger(message.sectionMappingId.low) && $util.isInteger(message.sectionMappingId.high)))
                                return "sectionMappingId: integer|Long expected";
                        if (message.sectionMappingItemId != null && message.hasOwnProperty("sectionMappingItemId"))
                            if (!$util.isInteger(message.sectionMappingItemId) && !(message.sectionMappingItemId && $util.isInteger(message.sectionMappingItemId.low) && $util.isInteger(message.sectionMappingItemId.high)))
                                return "sectionMappingItemId: integer|Long expected";
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            if (typeof message.skipChangeEvent !== "boolean")
                                return "skipChangeEvent: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteSectionMappingItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteSectionMappingItemRequest} DeleteSectionMappingItemRequest
                     */
                    DeleteSectionMappingItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteSectionMappingItemRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteSectionMappingItemRequest();
                        if (object.sectionMappingId != null)
                            if ($util.Long)
                                (message.sectionMappingId = $util.Long.fromValue(object.sectionMappingId)).unsigned = false;
                            else if (typeof object.sectionMappingId === "string")
                                message.sectionMappingId = parseInt(object.sectionMappingId, 10);
                            else if (typeof object.sectionMappingId === "number")
                                message.sectionMappingId = object.sectionMappingId;
                            else if (typeof object.sectionMappingId === "object")
                                message.sectionMappingId = new $util.LongBits(object.sectionMappingId.low >>> 0, object.sectionMappingId.high >>> 0).toNumber();
                        if (object.sectionMappingItemId != null)
                            if ($util.Long)
                                (message.sectionMappingItemId = $util.Long.fromValue(object.sectionMappingItemId)).unsigned = false;
                            else if (typeof object.sectionMappingItemId === "string")
                                message.sectionMappingItemId = parseInt(object.sectionMappingItemId, 10);
                            else if (typeof object.sectionMappingItemId === "number")
                                message.sectionMappingItemId = object.sectionMappingItemId;
                            else if (typeof object.sectionMappingItemId === "object")
                                message.sectionMappingItemId = new $util.LongBits(object.sectionMappingItemId.low >>> 0, object.sectionMappingItemId.high >>> 0).toNumber();
                        if (object.skipChangeEvent != null)
                            message.skipChangeEvent = Boolean(object.skipChangeEvent);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteSectionMappingItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteSectionMappingItemRequest} message DeleteSectionMappingItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteSectionMappingItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sectionMappingId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sectionMappingId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sectionMappingItemId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sectionMappingItemId = options.longs === String ? "0" : 0;
                            object.skipChangeEvent = false;
                        }
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (typeof message.sectionMappingId === "number")
                                object.sectionMappingId = options.longs === String ? String(message.sectionMappingId) : message.sectionMappingId;
                            else
                                object.sectionMappingId = options.longs === String ? $util.Long.prototype.toString.call(message.sectionMappingId) : options.longs === Number ? new $util.LongBits(message.sectionMappingId.low >>> 0, message.sectionMappingId.high >>> 0).toNumber() : message.sectionMappingId;
                        if (message.sectionMappingItemId != null && message.hasOwnProperty("sectionMappingItemId"))
                            if (typeof message.sectionMappingItemId === "number")
                                object.sectionMappingItemId = options.longs === String ? String(message.sectionMappingItemId) : message.sectionMappingItemId;
                            else
                                object.sectionMappingItemId = options.longs === String ? $util.Long.prototype.toString.call(message.sectionMappingItemId) : options.longs === Number ? new $util.LongBits(message.sectionMappingItemId.low >>> 0, message.sectionMappingItemId.high >>> 0).toNumber() : message.sectionMappingItemId;
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            object.skipChangeEvent = message.skipChangeEvent;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteSectionMappingItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteSectionMappingItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteSectionMappingItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteSectionMappingItemRequest;
                })();
    
                v1.ImportSectionMappingItemRequest = (function() {
    
                    /**
                     * Properties of an ImportSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IImportSectionMappingItemRequest
                     * @property {number|null} [sectionMappingId] ImportSectionMappingItemRequest sectionMappingId
                     * @property {google.protobuf.IInt64Value|null} [storeId] ImportSectionMappingItemRequest storeId
                     * @property {number|null} [accountId] ImportSectionMappingItemRequest accountId
                     * @property {boolean|null} [skipChangeEvent] ImportSectionMappingItemRequest skipChangeEvent
                     * @property {boolean|null} [preview] ImportSectionMappingItemRequest preview
                     * @property {palexy.streaming.v1.IPosSectionMappingItemList|null} [itemList] ImportSectionMappingItemRequest itemList
                     */
    
                    /**
                     * Constructs a new ImportSectionMappingItemRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ImportSectionMappingItemRequest.
                     * @implements IImportSectionMappingItemRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IImportSectionMappingItemRequest=} [properties] Properties to set
                     */
                    function ImportSectionMappingItemRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ImportSectionMappingItemRequest sectionMappingId.
                     * @member {number} sectionMappingId
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @instance
                     */
                    ImportSectionMappingItemRequest.prototype.sectionMappingId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ImportSectionMappingItemRequest storeId.
                     * @member {google.protobuf.IInt64Value|null|undefined} storeId
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @instance
                     */
                    ImportSectionMappingItemRequest.prototype.storeId = null;
    
                    /**
                     * ImportSectionMappingItemRequest accountId.
                     * @member {number} accountId
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @instance
                     */
                    ImportSectionMappingItemRequest.prototype.accountId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ImportSectionMappingItemRequest skipChangeEvent.
                     * @member {boolean} skipChangeEvent
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @instance
                     */
                    ImportSectionMappingItemRequest.prototype.skipChangeEvent = false;
    
                    /**
                     * ImportSectionMappingItemRequest preview.
                     * @member {boolean} preview
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @instance
                     */
                    ImportSectionMappingItemRequest.prototype.preview = false;
    
                    /**
                     * ImportSectionMappingItemRequest itemList.
                     * @member {palexy.streaming.v1.IPosSectionMappingItemList|null|undefined} itemList
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @instance
                     */
                    ImportSectionMappingItemRequest.prototype.itemList = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * ImportSectionMappingItemRequest sourceData.
                     * @member {"itemList"|undefined} sourceData
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @instance
                     */
                    Object.defineProperty(ImportSectionMappingItemRequest.prototype, "sourceData", {
                        get: $util.oneOfGetter($oneOfFields = ["itemList"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new ImportSectionMappingItemRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportSectionMappingItemRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ImportSectionMappingItemRequest} ImportSectionMappingItemRequest instance
                     */
                    ImportSectionMappingItemRequest.create = function create(properties) {
                        return new ImportSectionMappingItemRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ImportSectionMappingItemRequest message. Does not implicitly {@link palexy.streaming.v1.ImportSectionMappingItemRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportSectionMappingItemRequest} message ImportSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportSectionMappingItemRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMappingId != null && Object.hasOwnProperty.call(message, "sectionMappingId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sectionMappingId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            $root.google.protobuf.Int64Value.encode(message.storeId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.accountId);
                        if (message.skipChangeEvent != null && Object.hasOwnProperty.call(message, "skipChangeEvent"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.skipChangeEvent);
                        if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.preview);
                        if (message.itemList != null && Object.hasOwnProperty.call(message, "itemList"))
                            $root.palexy.streaming.v1.PosSectionMappingItemList.encode(message.itemList, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ImportSectionMappingItemRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ImportSectionMappingItemRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportSectionMappingItemRequest} message ImportSectionMappingItemRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportSectionMappingItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ImportSectionMappingItemRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ImportSectionMappingItemRequest} ImportSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportSectionMappingItemRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ImportSectionMappingItemRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sectionMappingId = reader.int64();
                                break;
                            case 2:
                                message.storeId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.accountId = reader.int64();
                                break;
                            case 4:
                                message.skipChangeEvent = reader.bool();
                                break;
                            case 5:
                                message.preview = reader.bool();
                                break;
                            case 6:
                                message.itemList = $root.palexy.streaming.v1.PosSectionMappingItemList.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ImportSectionMappingItemRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ImportSectionMappingItemRequest} ImportSectionMappingItemRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportSectionMappingItemRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ImportSectionMappingItemRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImportSectionMappingItemRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (!$util.isInteger(message.sectionMappingId) && !(message.sectionMappingId && $util.isInteger(message.sectionMappingId.low) && $util.isInteger(message.sectionMappingId.high)))
                                return "sectionMappingId: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.storeId);
                            if (error)
                                return "storeId." + error;
                        }
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (!$util.isInteger(message.accountId) && !(message.accountId && $util.isInteger(message.accountId.low) && $util.isInteger(message.accountId.high)))
                                return "accountId: integer|Long expected";
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            if (typeof message.skipChangeEvent !== "boolean")
                                return "skipChangeEvent: boolean expected";
                        if (message.preview != null && message.hasOwnProperty("preview"))
                            if (typeof message.preview !== "boolean")
                                return "preview: boolean expected";
                        if (message.itemList != null && message.hasOwnProperty("itemList")) {
                            properties.sourceData = 1;
                            {
                                var error = $root.palexy.streaming.v1.PosSectionMappingItemList.verify(message.itemList);
                                if (error)
                                    return "itemList." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ImportSectionMappingItemRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ImportSectionMappingItemRequest} ImportSectionMappingItemRequest
                     */
                    ImportSectionMappingItemRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ImportSectionMappingItemRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ImportSectionMappingItemRequest();
                        if (object.sectionMappingId != null)
                            if ($util.Long)
                                (message.sectionMappingId = $util.Long.fromValue(object.sectionMappingId)).unsigned = false;
                            else if (typeof object.sectionMappingId === "string")
                                message.sectionMappingId = parseInt(object.sectionMappingId, 10);
                            else if (typeof object.sectionMappingId === "number")
                                message.sectionMappingId = object.sectionMappingId;
                            else if (typeof object.sectionMappingId === "object")
                                message.sectionMappingId = new $util.LongBits(object.sectionMappingId.low >>> 0, object.sectionMappingId.high >>> 0).toNumber();
                        if (object.storeId != null) {
                            if (typeof object.storeId !== "object")
                                throw TypeError(".palexy.streaming.v1.ImportSectionMappingItemRequest.storeId: object expected");
                            message.storeId = $root.google.protobuf.Int64Value.fromObject(object.storeId);
                        }
                        if (object.accountId != null)
                            if ($util.Long)
                                (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = false;
                            else if (typeof object.accountId === "string")
                                message.accountId = parseInt(object.accountId, 10);
                            else if (typeof object.accountId === "number")
                                message.accountId = object.accountId;
                            else if (typeof object.accountId === "object")
                                message.accountId = new $util.LongBits(object.accountId.low >>> 0, object.accountId.high >>> 0).toNumber();
                        if (object.skipChangeEvent != null)
                            message.skipChangeEvent = Boolean(object.skipChangeEvent);
                        if (object.preview != null)
                            message.preview = Boolean(object.preview);
                        if (object.itemList != null) {
                            if (typeof object.itemList !== "object")
                                throw TypeError(".palexy.streaming.v1.ImportSectionMappingItemRequest.itemList: object expected");
                            message.itemList = $root.palexy.streaming.v1.PosSectionMappingItemList.fromObject(object.itemList);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ImportSectionMappingItemRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @static
                     * @param {palexy.streaming.v1.ImportSectionMappingItemRequest} message ImportSectionMappingItemRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImportSectionMappingItemRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sectionMappingId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sectionMappingId = options.longs === String ? "0" : 0;
                            object.storeId = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.accountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.accountId = options.longs === String ? "0" : 0;
                            object.skipChangeEvent = false;
                            object.preview = false;
                        }
                        if (message.sectionMappingId != null && message.hasOwnProperty("sectionMappingId"))
                            if (typeof message.sectionMappingId === "number")
                                object.sectionMappingId = options.longs === String ? String(message.sectionMappingId) : message.sectionMappingId;
                            else
                                object.sectionMappingId = options.longs === String ? $util.Long.prototype.toString.call(message.sectionMappingId) : options.longs === Number ? new $util.LongBits(message.sectionMappingId.low >>> 0, message.sectionMappingId.high >>> 0).toNumber() : message.sectionMappingId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = $root.google.protobuf.Int64Value.toObject(message.storeId, options);
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (typeof message.accountId === "number")
                                object.accountId = options.longs === String ? String(message.accountId) : message.accountId;
                            else
                                object.accountId = options.longs === String ? $util.Long.prototype.toString.call(message.accountId) : options.longs === Number ? new $util.LongBits(message.accountId.low >>> 0, message.accountId.high >>> 0).toNumber() : message.accountId;
                        if (message.skipChangeEvent != null && message.hasOwnProperty("skipChangeEvent"))
                            object.skipChangeEvent = message.skipChangeEvent;
                        if (message.preview != null && message.hasOwnProperty("preview"))
                            object.preview = message.preview;
                        if (message.itemList != null && message.hasOwnProperty("itemList")) {
                            object.itemList = $root.palexy.streaming.v1.PosSectionMappingItemList.toObject(message.itemList, options);
                            if (options.oneofs)
                                object.sourceData = "itemList";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ImportSectionMappingItemRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImportSectionMappingItemRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ImportSectionMappingItemRequest;
                })();
    
                v1.PosSectionMappingItemList = (function() {
    
                    /**
                     * Properties of a PosSectionMappingItemList.
                     * @memberof palexy.streaming.v1
                     * @interface IPosSectionMappingItemList
                     * @property {Array.<palexy.streaming.v1.IPosSectionMappingItem>|null} [sectionMappingItems] PosSectionMappingItemList sectionMappingItems
                     */
    
                    /**
                     * Constructs a new PosSectionMappingItemList.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PosSectionMappingItemList.
                     * @implements IPosSectionMappingItemList
                     * @constructor
                     * @param {palexy.streaming.v1.IPosSectionMappingItemList=} [properties] Properties to set
                     */
                    function PosSectionMappingItemList(properties) {
                        this.sectionMappingItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PosSectionMappingItemList sectionMappingItems.
                     * @member {Array.<palexy.streaming.v1.IPosSectionMappingItem>} sectionMappingItems
                     * @memberof palexy.streaming.v1.PosSectionMappingItemList
                     * @instance
                     */
                    PosSectionMappingItemList.prototype.sectionMappingItems = $util.emptyArray;
    
                    /**
                     * Creates a new PosSectionMappingItemList instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PosSectionMappingItemList
                     * @static
                     * @param {palexy.streaming.v1.IPosSectionMappingItemList=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PosSectionMappingItemList} PosSectionMappingItemList instance
                     */
                    PosSectionMappingItemList.create = function create(properties) {
                        return new PosSectionMappingItemList(properties);
                    };
    
                    /**
                     * Encodes the specified PosSectionMappingItemList message. Does not implicitly {@link palexy.streaming.v1.PosSectionMappingItemList.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PosSectionMappingItemList
                     * @static
                     * @param {palexy.streaming.v1.IPosSectionMappingItemList} message PosSectionMappingItemList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosSectionMappingItemList.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sectionMappingItems != null && message.sectionMappingItems.length)
                            for (var i = 0; i < message.sectionMappingItems.length; ++i)
                                $root.palexy.streaming.v1.PosSectionMappingItem.encode(message.sectionMappingItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PosSectionMappingItemList message, length delimited. Does not implicitly {@link palexy.streaming.v1.PosSectionMappingItemList.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PosSectionMappingItemList
                     * @static
                     * @param {palexy.streaming.v1.IPosSectionMappingItemList} message PosSectionMappingItemList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PosSectionMappingItemList.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PosSectionMappingItemList message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PosSectionMappingItemList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PosSectionMappingItemList} PosSectionMappingItemList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosSectionMappingItemList.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PosSectionMappingItemList();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.sectionMappingItems && message.sectionMappingItems.length))
                                    message.sectionMappingItems = [];
                                message.sectionMappingItems.push($root.palexy.streaming.v1.PosSectionMappingItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PosSectionMappingItemList message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PosSectionMappingItemList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PosSectionMappingItemList} PosSectionMappingItemList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PosSectionMappingItemList.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PosSectionMappingItemList message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PosSectionMappingItemList
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PosSectionMappingItemList.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sectionMappingItems != null && message.hasOwnProperty("sectionMappingItems")) {
                            if (!Array.isArray(message.sectionMappingItems))
                                return "sectionMappingItems: array expected";
                            for (var i = 0; i < message.sectionMappingItems.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosSectionMappingItem.verify(message.sectionMappingItems[i]);
                                if (error)
                                    return "sectionMappingItems." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a PosSectionMappingItemList message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PosSectionMappingItemList
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PosSectionMappingItemList} PosSectionMappingItemList
                     */
                    PosSectionMappingItemList.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PosSectionMappingItemList)
                            return object;
                        var message = new $root.palexy.streaming.v1.PosSectionMappingItemList();
                        if (object.sectionMappingItems) {
                            if (!Array.isArray(object.sectionMappingItems))
                                throw TypeError(".palexy.streaming.v1.PosSectionMappingItemList.sectionMappingItems: array expected");
                            message.sectionMappingItems = [];
                            for (var i = 0; i < object.sectionMappingItems.length; ++i) {
                                if (typeof object.sectionMappingItems[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.PosSectionMappingItemList.sectionMappingItems: object expected");
                                message.sectionMappingItems[i] = $root.palexy.streaming.v1.PosSectionMappingItem.fromObject(object.sectionMappingItems[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PosSectionMappingItemList message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PosSectionMappingItemList
                     * @static
                     * @param {palexy.streaming.v1.PosSectionMappingItemList} message PosSectionMappingItemList
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PosSectionMappingItemList.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.sectionMappingItems = [];
                        if (message.sectionMappingItems && message.sectionMappingItems.length) {
                            object.sectionMappingItems = [];
                            for (var j = 0; j < message.sectionMappingItems.length; ++j)
                                object.sectionMappingItems[j] = $root.palexy.streaming.v1.PosSectionMappingItem.toObject(message.sectionMappingItems[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this PosSectionMappingItemList to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PosSectionMappingItemList
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PosSectionMappingItemList.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PosSectionMappingItemList;
                })();
    
                v1.ImportSectionMappingItemResponse = (function() {
    
                    /**
                     * Properties of an ImportSectionMappingItemResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IImportSectionMappingItemResponse
                     * @property {palexy.streaming.v1.IImportResult|null} [importResult] ImportSectionMappingItemResponse importResult
                     * @property {Array.<palexy.streaming.v1.IPosSectionMappingItem>|null} [previewResult] ImportSectionMappingItemResponse previewResult
                     */
    
                    /**
                     * Constructs a new ImportSectionMappingItemResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ImportSectionMappingItemResponse.
                     * @implements IImportSectionMappingItemResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IImportSectionMappingItemResponse=} [properties] Properties to set
                     */
                    function ImportSectionMappingItemResponse(properties) {
                        this.previewResult = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ImportSectionMappingItemResponse importResult.
                     * @member {palexy.streaming.v1.IImportResult|null|undefined} importResult
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemResponse
                     * @instance
                     */
                    ImportSectionMappingItemResponse.prototype.importResult = null;
    
                    /**
                     * ImportSectionMappingItemResponse previewResult.
                     * @member {Array.<palexy.streaming.v1.IPosSectionMappingItem>} previewResult
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemResponse
                     * @instance
                     */
                    ImportSectionMappingItemResponse.prototype.previewResult = $util.emptyArray;
    
                    /**
                     * Creates a new ImportSectionMappingItemResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemResponse
                     * @static
                     * @param {palexy.streaming.v1.IImportSectionMappingItemResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ImportSectionMappingItemResponse} ImportSectionMappingItemResponse instance
                     */
                    ImportSectionMappingItemResponse.create = function create(properties) {
                        return new ImportSectionMappingItemResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ImportSectionMappingItemResponse message. Does not implicitly {@link palexy.streaming.v1.ImportSectionMappingItemResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemResponse
                     * @static
                     * @param {palexy.streaming.v1.IImportSectionMappingItemResponse} message ImportSectionMappingItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportSectionMappingItemResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.importResult != null && Object.hasOwnProperty.call(message, "importResult"))
                            $root.palexy.streaming.v1.ImportResult.encode(message.importResult, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.previewResult != null && message.previewResult.length)
                            for (var i = 0; i < message.previewResult.length; ++i)
                                $root.palexy.streaming.v1.PosSectionMappingItem.encode(message.previewResult[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ImportSectionMappingItemResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ImportSectionMappingItemResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemResponse
                     * @static
                     * @param {palexy.streaming.v1.IImportSectionMappingItemResponse} message ImportSectionMappingItemResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportSectionMappingItemResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ImportSectionMappingItemResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ImportSectionMappingItemResponse} ImportSectionMappingItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportSectionMappingItemResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ImportSectionMappingItemResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.importResult = $root.palexy.streaming.v1.ImportResult.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.previewResult && message.previewResult.length))
                                    message.previewResult = [];
                                message.previewResult.push($root.palexy.streaming.v1.PosSectionMappingItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ImportSectionMappingItemResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ImportSectionMappingItemResponse} ImportSectionMappingItemResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportSectionMappingItemResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ImportSectionMappingItemResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImportSectionMappingItemResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.importResult != null && message.hasOwnProperty("importResult")) {
                            var error = $root.palexy.streaming.v1.ImportResult.verify(message.importResult);
                            if (error)
                                return "importResult." + error;
                        }
                        if (message.previewResult != null && message.hasOwnProperty("previewResult")) {
                            if (!Array.isArray(message.previewResult))
                                return "previewResult: array expected";
                            for (var i = 0; i < message.previewResult.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosSectionMappingItem.verify(message.previewResult[i]);
                                if (error)
                                    return "previewResult." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ImportSectionMappingItemResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ImportSectionMappingItemResponse} ImportSectionMappingItemResponse
                     */
                    ImportSectionMappingItemResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ImportSectionMappingItemResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ImportSectionMappingItemResponse();
                        if (object.importResult != null) {
                            if (typeof object.importResult !== "object")
                                throw TypeError(".palexy.streaming.v1.ImportSectionMappingItemResponse.importResult: object expected");
                            message.importResult = $root.palexy.streaming.v1.ImportResult.fromObject(object.importResult);
                        }
                        if (object.previewResult) {
                            if (!Array.isArray(object.previewResult))
                                throw TypeError(".palexy.streaming.v1.ImportSectionMappingItemResponse.previewResult: array expected");
                            message.previewResult = [];
                            for (var i = 0; i < object.previewResult.length; ++i) {
                                if (typeof object.previewResult[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ImportSectionMappingItemResponse.previewResult: object expected");
                                message.previewResult[i] = $root.palexy.streaming.v1.PosSectionMappingItem.fromObject(object.previewResult[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ImportSectionMappingItemResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemResponse
                     * @static
                     * @param {palexy.streaming.v1.ImportSectionMappingItemResponse} message ImportSectionMappingItemResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImportSectionMappingItemResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.previewResult = [];
                        if (options.defaults)
                            object.importResult = null;
                        if (message.importResult != null && message.hasOwnProperty("importResult"))
                            object.importResult = $root.palexy.streaming.v1.ImportResult.toObject(message.importResult, options);
                        if (message.previewResult && message.previewResult.length) {
                            object.previewResult = [];
                            for (var j = 0; j < message.previewResult.length; ++j)
                                object.previewResult[j] = $root.palexy.streaming.v1.PosSectionMappingItem.toObject(message.previewResult[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ImportSectionMappingItemResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ImportSectionMappingItemResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImportSectionMappingItemResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ImportSectionMappingItemResponse;
                })();
    
                v1.RawICAMetadataService = (function() {
    
                    /**
                     * Constructs a new RawICAMetadataService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RawICAMetadataService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function RawICAMetadataService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (RawICAMetadataService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RawICAMetadataService;
    
                    /**
                     * Creates new RawICAMetadataService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {RawICAMetadataService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    RawICAMetadataService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAMetadataService#listRawICAMetadata}.
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @typedef ListRawICAMetadataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListRawICAMetadataResponse} [response] ListRawICAMetadataResponse
                     */
    
                    /**
                     * Calls ListRawICAMetadata.
                     * @function listRawICAMetadata
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IListRawICAMetadataRequest} request ListRawICAMetadataRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAMetadataService.ListRawICAMetadataCallback} callback Node-style callback called with the error, if any, and ListRawICAMetadataResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAMetadataService.prototype.listRawICAMetadata = function listRawICAMetadata(request, callback) {
                        return this.rpcCall(listRawICAMetadata, $root.palexy.streaming.v1.ListRawICAMetadataRequest, $root.palexy.streaming.v1.ListRawICAMetadataResponse, request, callback);
                    }, "name", { value: "ListRawICAMetadata" });
    
                    /**
                     * Calls ListRawICAMetadata.
                     * @function listRawICAMetadata
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IListRawICAMetadataRequest} request ListRawICAMetadataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListRawICAMetadataResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAMetadataService#createRawICAMetadata}.
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @typedef CreateRawICAMetadataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.CreateRawICAMetadataResponse} [response] CreateRawICAMetadataResponse
                     */
    
                    /**
                     * Calls CreateRawICAMetadata.
                     * @function createRawICAMetadata
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateRawICAMetadataRequest} request CreateRawICAMetadataRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAMetadataService.CreateRawICAMetadataCallback} callback Node-style callback called with the error, if any, and CreateRawICAMetadataResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAMetadataService.prototype.createRawICAMetadata = function createRawICAMetadata(request, callback) {
                        return this.rpcCall(createRawICAMetadata, $root.palexy.streaming.v1.CreateRawICAMetadataRequest, $root.palexy.streaming.v1.CreateRawICAMetadataResponse, request, callback);
                    }, "name", { value: "CreateRawICAMetadata" });
    
                    /**
                     * Calls CreateRawICAMetadata.
                     * @function createRawICAMetadata
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateRawICAMetadataRequest} request CreateRawICAMetadataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.CreateRawICAMetadataResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAMetadataService#updateRawICAMetadata}.
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @typedef UpdateRawICAMetadataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.RawICAMetadata} [response] RawICAMetadata
                     */
    
                    /**
                     * Calls UpdateRawICAMetadata.
                     * @function updateRawICAMetadata
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateRawICAMetadataRequest} request UpdateRawICAMetadataRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAMetadataService.UpdateRawICAMetadataCallback} callback Node-style callback called with the error, if any, and RawICAMetadata
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAMetadataService.prototype.updateRawICAMetadata = function updateRawICAMetadata(request, callback) {
                        return this.rpcCall(updateRawICAMetadata, $root.palexy.streaming.v1.UpdateRawICAMetadataRequest, $root.palexy.streaming.v1.RawICAMetadata, request, callback);
                    }, "name", { value: "UpdateRawICAMetadata" });
    
                    /**
                     * Calls UpdateRawICAMetadata.
                     * @function updateRawICAMetadata
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateRawICAMetadataRequest} request UpdateRawICAMetadataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.RawICAMetadata>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAMetadataService#getRawIcaMetadata}.
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @typedef GetRawIcaMetadataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.RawICAMetadata} [response] RawICAMetadata
                     */
    
                    /**
                     * Calls GetRawIcaMetadata.
                     * @function getRawIcaMetadata
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IGetRawIcaMetadataRequest} request GetRawIcaMetadataRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAMetadataService.GetRawIcaMetadataCallback} callback Node-style callback called with the error, if any, and RawICAMetadata
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAMetadataService.prototype.getRawIcaMetadata = function getRawIcaMetadata(request, callback) {
                        return this.rpcCall(getRawIcaMetadata, $root.palexy.streaming.v1.GetRawIcaMetadataRequest, $root.palexy.streaming.v1.RawICAMetadata, request, callback);
                    }, "name", { value: "GetRawIcaMetadata" });
    
                    /**
                     * Calls GetRawIcaMetadata.
                     * @function getRawIcaMetadata
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IGetRawIcaMetadataRequest} request GetRawIcaMetadataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.RawICAMetadata>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAMetadataService#batchUpdateStatus}.
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @typedef BatchUpdateStatusCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse} [response] BatchUpdateRawICAMetadataStatusResponse
                     */
    
                    /**
                     * Calls BatchUpdateStatus.
                     * @function batchUpdateStatus
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchUpdateRawICAMetadataStatusRequest} request BatchUpdateRawICAMetadataStatusRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAMetadataService.BatchUpdateStatusCallback} callback Node-style callback called with the error, if any, and BatchUpdateRawICAMetadataStatusResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAMetadataService.prototype.batchUpdateStatus = function batchUpdateStatus(request, callback) {
                        return this.rpcCall(batchUpdateStatus, $root.palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest, $root.palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse, request, callback);
                    }, "name", { value: "BatchUpdateStatus" });
    
                    /**
                     * Calls BatchUpdateStatus.
                     * @function batchUpdateStatus
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchUpdateRawICAMetadataStatusRequest} request BatchUpdateRawICAMetadataStatusRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAMetadataService#aggregateData}.
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @typedef AggregateDataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.AggregateRawICADataResponse} [response] AggregateRawICADataResponse
                     */
    
                    /**
                     * Calls AggregateData.
                     * @function aggregateData
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IAggregateRawICADataRequest} request AggregateRawICADataRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAMetadataService.AggregateDataCallback} callback Node-style callback called with the error, if any, and AggregateRawICADataResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAMetadataService.prototype.aggregateData = function aggregateData(request, callback) {
                        return this.rpcCall(aggregateData, $root.palexy.streaming.v1.AggregateRawICADataRequest, $root.palexy.streaming.v1.AggregateRawICADataResponse, request, callback);
                    }, "name", { value: "AggregateData" });
    
                    /**
                     * Calls AggregateData.
                     * @function aggregateData
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IAggregateRawICADataRequest} request AggregateRawICADataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.AggregateRawICADataResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAMetadataService#batchDeleteRawICAMetadata}.
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @typedef BatchDeleteRawICAMetadataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchDeleteRawICAMetadataResponse} [response] BatchDeleteRawICAMetadataResponse
                     */
    
                    /**
                     * Calls BatchDeleteRawICAMetadata.
                     * @function batchDeleteRawICAMetadata
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchDeleteRawICAMetadataRequest} request BatchDeleteRawICAMetadataRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAMetadataService.BatchDeleteRawICAMetadataCallback} callback Node-style callback called with the error, if any, and BatchDeleteRawICAMetadataResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAMetadataService.prototype.batchDeleteRawICAMetadata = function batchDeleteRawICAMetadata(request, callback) {
                        return this.rpcCall(batchDeleteRawICAMetadata, $root.palexy.streaming.v1.BatchDeleteRawICAMetadataRequest, $root.palexy.streaming.v1.BatchDeleteRawICAMetadataResponse, request, callback);
                    }, "name", { value: "BatchDeleteRawICAMetadata" });
    
                    /**
                     * Calls BatchDeleteRawICAMetadata.
                     * @function batchDeleteRawICAMetadata
                     * @memberof palexy.streaming.v1.RawICAMetadataService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchDeleteRawICAMetadataRequest} request BatchDeleteRawICAMetadataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchDeleteRawICAMetadataResponse>} Promise
                     * @variation 2
                     */
    
                    return RawICAMetadataService;
                })();
    
                v1.RawICAMetadata = (function() {
    
                    /**
                     * Properties of a RawICAMetadata.
                     * @memberof palexy.streaming.v1
                     * @interface IRawICAMetadata
                     * @property {number|null} [id] RawICAMetadata id
                     * @property {number|null} [companyId] RawICAMetadata companyId
                     * @property {number|null} [storeId] RawICAMetadata storeId
                     * @property {number|null} [dateId] RawICAMetadata dateId
                     * @property {number|null} [recordCount] RawICAMetadata recordCount
                     * @property {palexy.streaming.v1.RawIcaStorageProvider|null} [cloudProvider] RawICAMetadata cloudProvider
                     * @property {string|null} [bucketName] RawICAMetadata bucketName
                     * @property {string|null} [fileName] RawICAMetadata fileName
                     * @property {palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus|null} [status] RawICAMetadata status
                     * @property {number|null} [createTimeMs] RawICAMetadata createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] RawICAMetadata lastUpdateTimeMs
                     * @property {boolean|null} [invalid] RawICAMetadata invalid
                     * @property {string|null} [invalidReason] RawICAMetadata invalidReason
                     * @property {string|null} [simulatedFileName] RawICAMetadata simulatedFileName
                     * @property {string|null} [simulatedDataDescription] RawICAMetadata simulatedDataDescription
                     * @property {number|null} [aggregatingRetryCount] RawICAMetadata aggregatingRetryCount
                     * @property {string|null} [aggregatedLevel_1FileName] RawICAMetadata aggregatedLevel_1FileName
                     * @property {string|null} [importedFileName] RawICAMetadata importedFileName
                     * @property {Array.<palexy.streaming.v1.MetricEnum>|null} [unsupportedMetrics] RawICAMetadata unsupportedMetrics
                     * @property {boolean|null} [useEntranceCounting] RawICAMetadata useEntranceCounting
                     * @property {boolean|null} [externalTrafficHourlyMetricsEnabled] RawICAMetadata externalTrafficHourlyMetricsEnabled
                     */
    
                    /**
                     * Constructs a new RawICAMetadata.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RawICAMetadata.
                     * @implements IRawICAMetadata
                     * @constructor
                     * @param {palexy.streaming.v1.IRawICAMetadata=} [properties] Properties to set
                     */
                    function RawICAMetadata(properties) {
                        this.unsupportedMetrics = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RawICAMetadata id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICAMetadata companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICAMetadata storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICAMetadata dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.dateId = 0;
    
                    /**
                     * RawICAMetadata recordCount.
                     * @member {number} recordCount
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.recordCount = 0;
    
                    /**
                     * RawICAMetadata cloudProvider.
                     * @member {palexy.streaming.v1.RawIcaStorageProvider} cloudProvider
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.cloudProvider = 0;
    
                    /**
                     * RawICAMetadata bucketName.
                     * @member {string} bucketName
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.bucketName = "";
    
                    /**
                     * RawICAMetadata fileName.
                     * @member {string} fileName
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.fileName = "";
    
                    /**
                     * RawICAMetadata status.
                     * @member {palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus} status
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.status = 0;
    
                    /**
                     * RawICAMetadata createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICAMetadata lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICAMetadata invalid.
                     * @member {boolean} invalid
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.invalid = false;
    
                    /**
                     * RawICAMetadata invalidReason.
                     * @member {string} invalidReason
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.invalidReason = "";
    
                    /**
                     * RawICAMetadata simulatedFileName.
                     * @member {string} simulatedFileName
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.simulatedFileName = "";
    
                    /**
                     * RawICAMetadata simulatedDataDescription.
                     * @member {string} simulatedDataDescription
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.simulatedDataDescription = "";
    
                    /**
                     * RawICAMetadata aggregatingRetryCount.
                     * @member {number} aggregatingRetryCount
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.aggregatingRetryCount = 0;
    
                    /**
                     * RawICAMetadata aggregatedLevel_1FileName.
                     * @member {string} aggregatedLevel_1FileName
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.aggregatedLevel_1FileName = "";
    
                    /**
                     * RawICAMetadata importedFileName.
                     * @member {string} importedFileName
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.importedFileName = "";
    
                    /**
                     * RawICAMetadata unsupportedMetrics.
                     * @member {Array.<palexy.streaming.v1.MetricEnum>} unsupportedMetrics
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.unsupportedMetrics = $util.emptyArray;
    
                    /**
                     * RawICAMetadata useEntranceCounting.
                     * @member {boolean} useEntranceCounting
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.useEntranceCounting = false;
    
                    /**
                     * RawICAMetadata externalTrafficHourlyMetricsEnabled.
                     * @member {boolean} externalTrafficHourlyMetricsEnabled
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     */
                    RawICAMetadata.prototype.externalTrafficHourlyMetricsEnabled = false;
    
                    /**
                     * Creates a new RawICAMetadata instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @static
                     * @param {palexy.streaming.v1.IRawICAMetadata=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RawICAMetadata} RawICAMetadata instance
                     */
                    RawICAMetadata.create = function create(properties) {
                        return new RawICAMetadata(properties);
                    };
    
                    /**
                     * Encodes the specified RawICAMetadata message. Does not implicitly {@link palexy.streaming.v1.RawICAMetadata.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @static
                     * @param {palexy.streaming.v1.IRawICAMetadata} message RawICAMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RawICAMetadata.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.dateId);
                        if (message.recordCount != null && Object.hasOwnProperty.call(message, "recordCount"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.recordCount);
                        if (message.cloudProvider != null && Object.hasOwnProperty.call(message, "cloudProvider"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.cloudProvider);
                        if (message.bucketName != null && Object.hasOwnProperty.call(message, "bucketName"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.bucketName);
                        if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.fileName);
                        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.status);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.lastUpdateTimeMs);
                        if (message.invalid != null && Object.hasOwnProperty.call(message, "invalid"))
                            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.invalid);
                        if (message.invalidReason != null && Object.hasOwnProperty.call(message, "invalidReason"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.invalidReason);
                        if (message.simulatedFileName != null && Object.hasOwnProperty.call(message, "simulatedFileName"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.simulatedFileName);
                        if (message.simulatedDataDescription != null && Object.hasOwnProperty.call(message, "simulatedDataDescription"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.simulatedDataDescription);
                        if (message.aggregatingRetryCount != null && Object.hasOwnProperty.call(message, "aggregatingRetryCount"))
                            writer.uint32(/* id 16, wireType 0 =*/128).int32(message.aggregatingRetryCount);
                        if (message.aggregatedLevel_1FileName != null && Object.hasOwnProperty.call(message, "aggregatedLevel_1FileName"))
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.aggregatedLevel_1FileName);
                        if (message.importedFileName != null && Object.hasOwnProperty.call(message, "importedFileName"))
                            writer.uint32(/* id 18, wireType 2 =*/146).string(message.importedFileName);
                        if (message.unsupportedMetrics != null && message.unsupportedMetrics.length) {
                            writer.uint32(/* id 19, wireType 2 =*/154).fork();
                            for (var i = 0; i < message.unsupportedMetrics.length; ++i)
                                writer.int32(message.unsupportedMetrics[i]);
                            writer.ldelim();
                        }
                        if (message.useEntranceCounting != null && Object.hasOwnProperty.call(message, "useEntranceCounting"))
                            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.useEntranceCounting);
                        if (message.externalTrafficHourlyMetricsEnabled != null && Object.hasOwnProperty.call(message, "externalTrafficHourlyMetricsEnabled"))
                            writer.uint32(/* id 21, wireType 0 =*/168).bool(message.externalTrafficHourlyMetricsEnabled);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RawICAMetadata message, length delimited. Does not implicitly {@link palexy.streaming.v1.RawICAMetadata.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @static
                     * @param {palexy.streaming.v1.IRawICAMetadata} message RawICAMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RawICAMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RawICAMetadata message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RawICAMetadata} RawICAMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RawICAMetadata.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RawICAMetadata();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 4:
                                message.dateId = reader.int32();
                                break;
                            case 5:
                                message.recordCount = reader.int32();
                                break;
                            case 6:
                                message.cloudProvider = reader.int32();
                                break;
                            case 7:
                                message.bucketName = reader.string();
                                break;
                            case 8:
                                message.fileName = reader.string();
                                break;
                            case 9:
                                message.status = reader.int32();
                                break;
                            case 10:
                                message.createTimeMs = reader.int64();
                                break;
                            case 11:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 12:
                                message.invalid = reader.bool();
                                break;
                            case 13:
                                message.invalidReason = reader.string();
                                break;
                            case 14:
                                message.simulatedFileName = reader.string();
                                break;
                            case 15:
                                message.simulatedDataDescription = reader.string();
                                break;
                            case 16:
                                message.aggregatingRetryCount = reader.int32();
                                break;
                            case 17:
                                message.aggregatedLevel_1FileName = reader.string();
                                break;
                            case 18:
                                message.importedFileName = reader.string();
                                break;
                            case 19:
                                if (!(message.unsupportedMetrics && message.unsupportedMetrics.length))
                                    message.unsupportedMetrics = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.unsupportedMetrics.push(reader.int32());
                                } else
                                    message.unsupportedMetrics.push(reader.int32());
                                break;
                            case 20:
                                message.useEntranceCounting = reader.bool();
                                break;
                            case 21:
                                message.externalTrafficHourlyMetricsEnabled = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RawICAMetadata message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RawICAMetadata} RawICAMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RawICAMetadata.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RawICAMetadata message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RawICAMetadata.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.recordCount != null && message.hasOwnProperty("recordCount"))
                            if (!$util.isInteger(message.recordCount))
                                return "recordCount: integer expected";
                        if (message.cloudProvider != null && message.hasOwnProperty("cloudProvider"))
                            switch (message.cloudProvider) {
                            default:
                                return "cloudProvider: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.bucketName != null && message.hasOwnProperty("bucketName"))
                            if (!$util.isString(message.bucketName))
                                return "bucketName: string expected";
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            if (!$util.isString(message.fileName))
                                return "fileName: string expected";
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 10:
                                break;
                            }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.invalid != null && message.hasOwnProperty("invalid"))
                            if (typeof message.invalid !== "boolean")
                                return "invalid: boolean expected";
                        if (message.invalidReason != null && message.hasOwnProperty("invalidReason"))
                            if (!$util.isString(message.invalidReason))
                                return "invalidReason: string expected";
                        if (message.simulatedFileName != null && message.hasOwnProperty("simulatedFileName"))
                            if (!$util.isString(message.simulatedFileName))
                                return "simulatedFileName: string expected";
                        if (message.simulatedDataDescription != null && message.hasOwnProperty("simulatedDataDescription"))
                            if (!$util.isString(message.simulatedDataDescription))
                                return "simulatedDataDescription: string expected";
                        if (message.aggregatingRetryCount != null && message.hasOwnProperty("aggregatingRetryCount"))
                            if (!$util.isInteger(message.aggregatingRetryCount))
                                return "aggregatingRetryCount: integer expected";
                        if (message.aggregatedLevel_1FileName != null && message.hasOwnProperty("aggregatedLevel_1FileName"))
                            if (!$util.isString(message.aggregatedLevel_1FileName))
                                return "aggregatedLevel_1FileName: string expected";
                        if (message.importedFileName != null && message.hasOwnProperty("importedFileName"))
                            if (!$util.isString(message.importedFileName))
                                return "importedFileName: string expected";
                        if (message.unsupportedMetrics != null && message.hasOwnProperty("unsupportedMetrics")) {
                            if (!Array.isArray(message.unsupportedMetrics))
                                return "unsupportedMetrics: array expected";
                            for (var i = 0; i < message.unsupportedMetrics.length; ++i)
                                switch (message.unsupportedMetrics[i]) {
                                default:
                                    return "unsupportedMetrics: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                case 13:
                                case 14:
                                case 15:
                                case 16:
                                case 17:
                                case 18:
                                case 19:
                                case 20:
                                case 21:
                                case 22:
                                case 23:
                                case 24:
                                case 25:
                                case 26:
                                case 27:
                                case 28:
                                case 29:
                                case 30:
                                case 31:
                                case 32:
                                case 33:
                                case 34:
                                case 35:
                                case 36:
                                case 37:
                                case 38:
                                case 39:
                                case 40:
                                case 41:
                                case 42:
                                case 43:
                                case 44:
                                case 45:
                                case 46:
                                case 47:
                                case 48:
                                case 49:
                                case 50:
                                case 51:
                                case 52:
                                case 53:
                                case 54:
                                case 55:
                                case 56:
                                case 57:
                                case 58:
                                case 59:
                                case 60:
                                case 61:
                                case 62:
                                case 63:
                                case 64:
                                case 65:
                                case 66:
                                case 67:
                                case 68:
                                case 69:
                                case 70:
                                case 71:
                                case 72:
                                case 73:
                                case 74:
                                case 75:
                                case 76:
                                case 77:
                                case 78:
                                case 79:
                                case 80:
                                case 81:
                                case 82:
                                case 83:
                                case 84:
                                case 85:
                                case 86:
                                case 87:
                                case 88:
                                case 89:
                                case 90:
                                case 91:
                                case 92:
                                case 93:
                                case 94:
                                case 95:
                                case 96:
                                case 97:
                                case 98:
                                case 99:
                                case 100:
                                case 101:
                                case 102:
                                case 103:
                                case 104:
                                case 105:
                                case 106:
                                    break;
                                }
                        }
                        if (message.useEntranceCounting != null && message.hasOwnProperty("useEntranceCounting"))
                            if (typeof message.useEntranceCounting !== "boolean")
                                return "useEntranceCounting: boolean expected";
                        if (message.externalTrafficHourlyMetricsEnabled != null && message.hasOwnProperty("externalTrafficHourlyMetricsEnabled"))
                            if (typeof message.externalTrafficHourlyMetricsEnabled !== "boolean")
                                return "externalTrafficHourlyMetricsEnabled: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a RawICAMetadata message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RawICAMetadata} RawICAMetadata
                     */
                    RawICAMetadata.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RawICAMetadata)
                            return object;
                        var message = new $root.palexy.streaming.v1.RawICAMetadata();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.recordCount != null)
                            message.recordCount = object.recordCount | 0;
                        switch (object.cloudProvider) {
                        case "RAW_PROVIDER_UNKNOWN":
                        case 0:
                            message.cloudProvider = 0;
                            break;
                        case "DATABASE":
                        case 1:
                            message.cloudProvider = 1;
                            break;
                        case "GCP":
                        case 2:
                            message.cloudProvider = 2;
                            break;
                        }
                        if (object.bucketName != null)
                            message.bucketName = String(object.bucketName);
                        if (object.fileName != null)
                            message.fileName = String(object.fileName);
                        switch (object.status) {
                        case "RAW_STATUS_UNKNOWN":
                        case 0:
                            message.status = 0;
                            break;
                        case "RAW_READY":
                        case 1:
                            message.status = 1;
                            break;
                        case "AGGREGATING":
                        case 2:
                            message.status = 2;
                            break;
                        case "DONE_AGGREGATING":
                        case 3:
                            message.status = 3;
                            break;
                        case "EXTERNAL_TRAFFIC_READY":
                        case 4:
                            message.status = 4;
                            break;
                        case "DELETED":
                        case 10:
                            message.status = 10;
                            break;
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.invalid != null)
                            message.invalid = Boolean(object.invalid);
                        if (object.invalidReason != null)
                            message.invalidReason = String(object.invalidReason);
                        if (object.simulatedFileName != null)
                            message.simulatedFileName = String(object.simulatedFileName);
                        if (object.simulatedDataDescription != null)
                            message.simulatedDataDescription = String(object.simulatedDataDescription);
                        if (object.aggregatingRetryCount != null)
                            message.aggregatingRetryCount = object.aggregatingRetryCount | 0;
                        if (object.aggregatedLevel_1FileName != null)
                            message.aggregatedLevel_1FileName = String(object.aggregatedLevel_1FileName);
                        if (object.importedFileName != null)
                            message.importedFileName = String(object.importedFileName);
                        if (object.unsupportedMetrics) {
                            if (!Array.isArray(object.unsupportedMetrics))
                                throw TypeError(".palexy.streaming.v1.RawICAMetadata.unsupportedMetrics: array expected");
                            message.unsupportedMetrics = [];
                            for (var i = 0; i < object.unsupportedMetrics.length; ++i)
                                switch (object.unsupportedMetrics[i]) {
                                default:
                                case "METRIC_UNKNOWN":
                                case 0:
                                    message.unsupportedMetrics[i] = 0;
                                    break;
                                case "METRIC_BUYER_TRAFFIC":
                                case 1:
                                    message.unsupportedMetrics[i] = 1;
                                    break;
                                case "METRIC_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 2:
                                    message.unsupportedMetrics[i] = 2;
                                    break;
                                case "METRIC_INTERACTION":
                                case 3:
                                    message.unsupportedMetrics[i] = 3;
                                    break;
                                case "METRIC_VISIT":
                                case 4:
                                    message.unsupportedMetrics[i] = 4;
                                    break;
                                case "METRIC_GROUP_VISIT":
                                case 5:
                                    message.unsupportedMetrics[i] = 5;
                                    break;
                                case "METRIC_GROUP_INTERACTION":
                                case 6:
                                    message.unsupportedMetrics[i] = 6;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC":
                                case 7:
                                    message.unsupportedMetrics[i] = 7;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 8:
                                    message.unsupportedMetrics[i] = 8;
                                    break;
                                case "METRIC_BUYER_RATE":
                                case 9:
                                    message.unsupportedMetrics[i] = 9;
                                    break;
                                case "METRIC_INTERACTION_RATE":
                                case 10:
                                    message.unsupportedMetrics[i] = 10;
                                    break;
                                case "METRIC_INTERACTION_TIME":
                                case 11:
                                    message.unsupportedMetrics[i] = 11;
                                    break;
                                case "METRIC_INTERACTION_TIME_AVG":
                                case 12:
                                    message.unsupportedMetrics[i] = 12;
                                    break;
                                case "METRIC_INTERACTION_LEVEL":
                                case 13:
                                    message.unsupportedMetrics[i] = 13;
                                    break;
                                case "METRIC_INTERACTION_BUYER_RATE":
                                case 14:
                                    message.unsupportedMetrics[i] = 14;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_INTERACTION":
                                case 15:
                                    message.unsupportedMetrics[i] = 15;
                                    break;
                                case "METRIC_ON_TIME_INTERACTION_RATE":
                                case 16:
                                    message.unsupportedMetrics[i] = 16;
                                    break;
                                case "METRIC_GROUP_BUYER_RATE":
                                case 17:
                                    message.unsupportedMetrics[i] = 17;
                                    break;
                                case "METRIC_GROUP_INTERACTION_RATE":
                                case 18:
                                    message.unsupportedMetrics[i] = 18;
                                    break;
                                case "METRIC_GROUP_INTERACTION_BUYER_RATE":
                                case 19:
                                    message.unsupportedMetrics[i] = 19;
                                    break;
                                case "METRIC_LYING":
                                case 20:
                                    message.unsupportedMetrics[i] = 20;
                                    break;
                                case "METRIC_LYING_RATE":
                                case 21:
                                    message.unsupportedMetrics[i] = 21;
                                    break;
                                case "METRIC_LYING_TIME_AVG":
                                case 22:
                                    message.unsupportedMetrics[i] = 22;
                                    break;
                                case "METRIC_LYING_EXACT_ONE":
                                case 23:
                                    message.unsupportedMetrics[i] = 23;
                                    break;
                                case "METRIC_LYING_AT_LEAST_ONE":
                                case 24:
                                    message.unsupportedMetrics[i] = 24;
                                    break;
                                case "METRIC_LYING_AT_LEAST_TWO":
                                case 25:
                                    message.unsupportedMetrics[i] = 25;
                                    break;
                                case "METRIC_DWELL_TIME":
                                case 26:
                                    message.unsupportedMetrics[i] = 26;
                                    break;
                                case "METRIC_DWELL_TIME_AVG":
                                case 27:
                                    message.unsupportedMetrics[i] = 27;
                                    break;
                                case "METRIC_TOTAL_GREETED_CUSTOMERS":
                                case 28:
                                    message.unsupportedMetrics[i] = 28;
                                    break;
                                case "METRIC_GREETING_RATE":
                                case 29:
                                    message.unsupportedMetrics[i] = 29;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_GREETING":
                                case 30:
                                    message.unsupportedMetrics[i] = 30;
                                    break;
                                case "METRIC_ON_TIME_GREETING_RATE":
                                case 31:
                                    message.unsupportedMetrics[i] = 31;
                                    break;
                                case "METRIC_FOOT_TRAFFIC":
                                case 32:
                                    message.unsupportedMetrics[i] = 32;
                                    break;
                                case "METRIC_VISITOR_OCCUPANCY":
                                case 33:
                                    message.unsupportedMetrics[i] = 33;
                                    break;
                                case "METRIC_TOTAL_GROUPS":
                                case 34:
                                    message.unsupportedMetrics[i] = 34;
                                    break;
                                case "METRIC_TOTAL_VISITORS_GOING_AS_GROUPS":
                                case 35:
                                    message.unsupportedMetrics[i] = 35;
                                    break;
                                case "METRIC_GROUP_RATE":
                                case 36:
                                    message.unsupportedMetrics[i] = 36;
                                    break;
                                case "METRIC_AVG_GROUP_SIZE":
                                case 37:
                                    message.unsupportedMetrics[i] = 37;
                                    break;
                                case "METRIC_TOTAL_GROUPS_WITH_KIDS":
                                case 38:
                                    message.unsupportedMetrics[i] = 38;
                                    break;
                                case "METRIC_TOTAL_KNOWN_GROUPS":
                                case 39:
                                    message.unsupportedMetrics[i] = 39;
                                    break;
                                case "METRIC_GROUPS_WITH_KIDS_RATE":
                                case 40:
                                    message.unsupportedMetrics[i] = 40;
                                    break;
                                case "METRIC_TOTAL_GROUPS_AND_INDIVIDUALS":
                                case 41:
                                    message.unsupportedMetrics[i] = 41;
                                    break;
                                case "METRIC_CONVERSION_BY_OPPORTUNITIES":
                                case 42:
                                    message.unsupportedMetrics[i] = 42;
                                    break;
                                case "METRIC_PERCENT_SECTION_VISIT":
                                case 43:
                                    message.unsupportedMetrics[i] = 43;
                                    break;
                                case "METRIC_PERCENT_SECTION_PASS_BY":
                                case 44:
                                    message.unsupportedMetrics[i] = 44;
                                    break;
                                case "METRIC_PASS_BY":
                                case 45:
                                    message.unsupportedMetrics[i] = 45;
                                    break;
                                case "METRIC_CAPTURE_RATE":
                                case 46:
                                    message.unsupportedMetrics[i] = 46;
                                    break;
                                case "METRIC_TRANSACTION":
                                case 47:
                                    message.unsupportedMetrics[i] = 47;
                                    break;
                                case "METRIC_CONVERSION_RATE":
                                case 48:
                                    message.unsupportedMetrics[i] = 48;
                                    break;
                                case "METRIC_NET_SALES_AMOUNT":
                                case 49:
                                    message.unsupportedMetrics[i] = 49;
                                    break;
                                case "METRIC_NET_SALES_ITEMS":
                                case 50:
                                    message.unsupportedMetrics[i] = 50;
                                    break;
                                case "METRIC_NET_SALES_CONTRIBUTION":
                                case 51:
                                    message.unsupportedMetrics[i] = 51;
                                    break;
                                case "METRIC_NET_SALES_RANKING":
                                case 52:
                                    message.unsupportedMetrics[i] = 52;
                                    break;
                                case "METRIC_SHOPPER_YIELD":
                                case 53:
                                    message.unsupportedMetrics[i] = 53;
                                    break;
                                case "METRIC_ATV":
                                case 54:
                                    message.unsupportedMetrics[i] = 54;
                                    break;
                                case "METRIC_UPT":
                                case 55:
                                    message.unsupportedMetrics[i] = 55;
                                    break;
                                case "METRIC_UPDPS":
                                case 56:
                                    message.unsupportedMetrics[i] = 56;
                                    break;
                                case "METRIC_ASP":
                                case 57:
                                    message.unsupportedMetrics[i] = 57;
                                    break;
                                case "METRIC_STAFF_HOURS":
                                case 58:
                                    message.unsupportedMetrics[i] = 58;
                                    break;
                                case "METRIC_VISITOR_PER_STAFF_HOUR":
                                case 59:
                                    message.unsupportedMetrics[i] = 59;
                                    break;
                                case "METRIC_SALES_PER_STAFF_HOUR":
                                case 60:
                                    message.unsupportedMetrics[i] = 60;
                                    break;
                                case "METRIC_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 61:
                                    message.unsupportedMetrics[i] = 61;
                                    break;
                                case "METRIC_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 62:
                                    message.unsupportedMetrics[i] = 62;
                                    break;
                                case "METRIC_FORECAST_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 63:
                                    message.unsupportedMetrics[i] = 63;
                                    break;
                                case "METRIC_FORECAST_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 64:
                                    message.unsupportedMetrics[i] = 64;
                                    break;
                                case "METRIC_ROUNDED_STAFF_HOURS":
                                case 65:
                                    message.unsupportedMetrics[i] = 65;
                                    break;
                                case "METRIC_STAFF_HOUR_WITH_VISITOR_COUNT":
                                case 66:
                                    message.unsupportedMetrics[i] = 66;
                                    break;
                                case "METRIC_VISITORS_PER_SQM":
                                case 67:
                                    message.unsupportedMetrics[i] = 67;
                                    break;
                                case "METRIC_AVG_VISITOR_PER_STORE_PER_DAY":
                                case 68:
                                    message.unsupportedMetrics[i] = 68;
                                    break;
                                case "METRIC_DAYS_WITH_VISITORS":
                                case 69:
                                    message.unsupportedMetrics[i] = 69;
                                    break;
                                case "METRIC_DAYS_WITH_NET_SALES_ITEMS":
                                case 70:
                                    message.unsupportedMetrics[i] = 70;
                                    break;
                                case "METRIC_SALES_PER_SQM":
                                case 71:
                                    message.unsupportedMetrics[i] = 71;
                                    break;
                                case "METRIC_SALES_PER_SALES_AREA_SQM":
                                case 72:
                                    message.unsupportedMetrics[i] = 72;
                                    break;
                                case "METRIC_ESTIMATED_VISIT":
                                case 73:
                                    message.unsupportedMetrics[i] = 73;
                                    break;
                                case "METRIC_ESTIMATED_PASS_BY":
                                case 74:
                                    message.unsupportedMetrics[i] = 74;
                                    break;
                                case "METRIC_WALK_IN":
                                case 75:
                                    message.unsupportedMetrics[i] = 75;
                                    break;
                                case "METRIC_PERCENT_SECTION_ESTIMATED_PASS_BY":
                                case 76:
                                    message.unsupportedMetrics[i] = 76;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1":
                                case 77:
                                    message.unsupportedMetrics[i] = 77;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1_RATE":
                                case 78:
                                    message.unsupportedMetrics[i] = 78;
                                    break;
                                case "METRIC_CONVERTED_CUSTOM_ACTION_1_RATE":
                                case 79:
                                    message.unsupportedMetrics[i] = 79;
                                    break;
                                case "METRIC_AVG_SALES_PER_STORE_PER_DAY":
                                case 80:
                                    message.unsupportedMetrics[i] = 80;
                                    break;
                                case "METRIC_STAFF_TOTAL_SERVED_VISITORS":
                                case 81:
                                    message.unsupportedMetrics[i] = 81;
                                    break;
                                case "METRIC_STAFF_CONVERSION_RATE":
                                case 82:
                                    message.unsupportedMetrics[i] = 82;
                                    break;
                                case "METRIC_STAFF_SALES_PER_SERVED_VISITORS":
                                case 83:
                                    message.unsupportedMetrics[i] = 83;
                                    break;
                                case "METRIC_STAFF_SERVED_VISITORS_PER_STAFF_HOUR":
                                case 84:
                                    message.unsupportedMetrics[i] = 84;
                                    break;
                                case "METRIC_STAFF_PERCENTAGE_OF_SALES":
                                case 85:
                                    message.unsupportedMetrics[i] = 85;
                                    break;
                                case "METRIC_DAYS_WITH_SALES":
                                case 86:
                                    message.unsupportedMetrics[i] = 86;
                                    break;
                                case "METRIC_VIDEO_GROUP_DISTRIBUTION":
                                case 87:
                                    message.unsupportedMetrics[i] = 87;
                                    break;
                                case "METRIC_BOUNCE_RATE":
                                case 88:
                                    message.unsupportedMetrics[i] = 88;
                                    break;
                                case "METRIC_AVG_MALL_VISITOR_PER_STORE_PER_DAY":
                                case 89:
                                    message.unsupportedMetrics[i] = 89;
                                    break;
                                case "METRIC_TOTAL_ON_TIME_GREETED_CUSTOMERS":
                                case 90:
                                    message.unsupportedMetrics[i] = 90;
                                    break;
                                case "METRIC_STAFF_STORE_NET_SALES_AMOUNT":
                                case 91:
                                    message.unsupportedMetrics[i] = 91;
                                    break;
                                case "METRIC_STAFF_UNIQUE_INTERACTIONS":
                                case 92:
                                    message.unsupportedMetrics[i] = 92;
                                    break;
                                case "METRIC_STAFF_INTERACTION_RATE":
                                case 93:
                                    message.unsupportedMetrics[i] = 93;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_VISITORS":
                                case 94:
                                    message.unsupportedMetrics[i] = 94;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_VISITORS":
                                case 95:
                                    message.unsupportedMetrics[i] = 95;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_VISITORS":
                                case 96:
                                    message.unsupportedMetrics[i] = 96;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME":
                                case 97:
                                    message.unsupportedMetrics[i] = 97;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME":
                                case 98:
                                    message.unsupportedMetrics[i] = 98;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_OPPORTUNITIES":
                                case 99:
                                    message.unsupportedMetrics[i] = 99;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 100:
                                    message.unsupportedMetrics[i] = 100;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 101:
                                    message.unsupportedMetrics[i] = 101;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 102:
                                    message.unsupportedMetrics[i] = 102;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 103:
                                    message.unsupportedMetrics[i] = 103;
                                    break;
                                case "METRIC_DISCOUNT_AMOUNT":
                                case 104:
                                    message.unsupportedMetrics[i] = 104;
                                    break;
                                case "METRIC_DISCOUNT_RATE":
                                case 105:
                                    message.unsupportedMetrics[i] = 105;
                                    break;
                                case "METRIC_GROSS_AMOUNT":
                                case 106:
                                    message.unsupportedMetrics[i] = 106;
                                    break;
                                }
                        }
                        if (object.useEntranceCounting != null)
                            message.useEntranceCounting = Boolean(object.useEntranceCounting);
                        if (object.externalTrafficHourlyMetricsEnabled != null)
                            message.externalTrafficHourlyMetricsEnabled = Boolean(object.externalTrafficHourlyMetricsEnabled);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RawICAMetadata message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @static
                     * @param {palexy.streaming.v1.RawICAMetadata} message RawICAMetadata
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RawICAMetadata.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.unsupportedMetrics = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                            object.recordCount = 0;
                            object.cloudProvider = options.enums === String ? "RAW_PROVIDER_UNKNOWN" : 0;
                            object.bucketName = "";
                            object.fileName = "";
                            object.status = options.enums === String ? "RAW_STATUS_UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            object.invalid = false;
                            object.invalidReason = "";
                            object.simulatedFileName = "";
                            object.simulatedDataDescription = "";
                            object.aggregatingRetryCount = 0;
                            object.aggregatedLevel_1FileName = "";
                            object.importedFileName = "";
                            object.useEntranceCounting = false;
                            object.externalTrafficHourlyMetricsEnabled = false;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.recordCount != null && message.hasOwnProperty("recordCount"))
                            object.recordCount = message.recordCount;
                        if (message.cloudProvider != null && message.hasOwnProperty("cloudProvider"))
                            object.cloudProvider = options.enums === String ? $root.palexy.streaming.v1.RawIcaStorageProvider[message.cloudProvider] : message.cloudProvider;
                        if (message.bucketName != null && message.hasOwnProperty("bucketName"))
                            object.bucketName = message.bucketName;
                        if (message.fileName != null && message.hasOwnProperty("fileName"))
                            object.fileName = message.fileName;
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus[message.status] : message.status;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.invalid != null && message.hasOwnProperty("invalid"))
                            object.invalid = message.invalid;
                        if (message.invalidReason != null && message.hasOwnProperty("invalidReason"))
                            object.invalidReason = message.invalidReason;
                        if (message.simulatedFileName != null && message.hasOwnProperty("simulatedFileName"))
                            object.simulatedFileName = message.simulatedFileName;
                        if (message.simulatedDataDescription != null && message.hasOwnProperty("simulatedDataDescription"))
                            object.simulatedDataDescription = message.simulatedDataDescription;
                        if (message.aggregatingRetryCount != null && message.hasOwnProperty("aggregatingRetryCount"))
                            object.aggregatingRetryCount = message.aggregatingRetryCount;
                        if (message.aggregatedLevel_1FileName != null && message.hasOwnProperty("aggregatedLevel_1FileName"))
                            object.aggregatedLevel_1FileName = message.aggregatedLevel_1FileName;
                        if (message.importedFileName != null && message.hasOwnProperty("importedFileName"))
                            object.importedFileName = message.importedFileName;
                        if (message.unsupportedMetrics && message.unsupportedMetrics.length) {
                            object.unsupportedMetrics = [];
                            for (var j = 0; j < message.unsupportedMetrics.length; ++j)
                                object.unsupportedMetrics[j] = options.enums === String ? $root.palexy.streaming.v1.MetricEnum[message.unsupportedMetrics[j]] : message.unsupportedMetrics[j];
                        }
                        if (message.useEntranceCounting != null && message.hasOwnProperty("useEntranceCounting"))
                            object.useEntranceCounting = message.useEntranceCounting;
                        if (message.externalTrafficHourlyMetricsEnabled != null && message.hasOwnProperty("externalTrafficHourlyMetricsEnabled"))
                            object.externalTrafficHourlyMetricsEnabled = message.externalTrafficHourlyMetricsEnabled;
                        return object;
                    };
    
                    /**
                     * Converts this RawICAMetadata to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RawICAMetadata
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RawICAMetadata.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * RawICAMetadataStatus enum.
                     * @name palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus
                     * @enum {number}
                     * @property {number} RAW_STATUS_UNKNOWN=0 RAW_STATUS_UNKNOWN value
                     * @property {number} RAW_READY=1 RAW_READY value
                     * @property {number} AGGREGATING=2 AGGREGATING value
                     * @property {number} DONE_AGGREGATING=3 DONE_AGGREGATING value
                     * @property {number} EXTERNAL_TRAFFIC_READY=4 EXTERNAL_TRAFFIC_READY value
                     * @property {number} DELETED=10 DELETED value
                     */
                    RawICAMetadata.RawICAMetadataStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "RAW_STATUS_UNKNOWN"] = 0;
                        values[valuesById[1] = "RAW_READY"] = 1;
                        values[valuesById[2] = "AGGREGATING"] = 2;
                        values[valuesById[3] = "DONE_AGGREGATING"] = 3;
                        values[valuesById[4] = "EXTERNAL_TRAFFIC_READY"] = 4;
                        values[valuesById[10] = "DELETED"] = 10;
                        return values;
                    })();
    
                    return RawICAMetadata;
                })();
    
                v1.ListRawICAMetadataRequest = (function() {
    
                    /**
                     * Properties of a ListRawICAMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListRawICAMetadataRequest
                     * @property {number|null} [companyId] ListRawICAMetadataRequest companyId
                     * @property {number|null} [storeId] ListRawICAMetadataRequest storeId
                     * @property {number|null} [startDate] ListRawICAMetadataRequest startDate
                     * @property {number|null} [endDate] ListRawICAMetadataRequest endDate
                     * @property {Array.<palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus>|null} [statuses] ListRawICAMetadataRequest statuses
                     * @property {number|null} [pageSize] ListRawICAMetadataRequest pageSize
                     * @property {string|null} [pageToken] ListRawICAMetadataRequest pageToken
                     * @property {google.protobuf.IBoolValue|null} [invalid] ListRawICAMetadataRequest invalid
                     * @property {boolean|null} [smallerAggregatingRetryCountFirst] ListRawICAMetadataRequest smallerAggregatingRetryCountFirst
                     */
    
                    /**
                     * Constructs a new ListRawICAMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListRawICAMetadataRequest.
                     * @implements IListRawICAMetadataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListRawICAMetadataRequest=} [properties] Properties to set
                     */
                    function ListRawICAMetadataRequest(properties) {
                        this.statuses = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListRawICAMetadataRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @instance
                     */
                    ListRawICAMetadataRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListRawICAMetadataRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @instance
                     */
                    ListRawICAMetadataRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListRawICAMetadataRequest startDate.
                     * @member {number} startDate
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @instance
                     */
                    ListRawICAMetadataRequest.prototype.startDate = 0;
    
                    /**
                     * ListRawICAMetadataRequest endDate.
                     * @member {number} endDate
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @instance
                     */
                    ListRawICAMetadataRequest.prototype.endDate = 0;
    
                    /**
                     * ListRawICAMetadataRequest statuses.
                     * @member {Array.<palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus>} statuses
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @instance
                     */
                    ListRawICAMetadataRequest.prototype.statuses = $util.emptyArray;
    
                    /**
                     * ListRawICAMetadataRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @instance
                     */
                    ListRawICAMetadataRequest.prototype.pageSize = 0;
    
                    /**
                     * ListRawICAMetadataRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @instance
                     */
                    ListRawICAMetadataRequest.prototype.pageToken = "";
    
                    /**
                     * ListRawICAMetadataRequest invalid.
                     * @member {google.protobuf.IBoolValue|null|undefined} invalid
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @instance
                     */
                    ListRawICAMetadataRequest.prototype.invalid = null;
    
                    /**
                     * ListRawICAMetadataRequest smallerAggregatingRetryCountFirst.
                     * @member {boolean} smallerAggregatingRetryCountFirst
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @instance
                     */
                    ListRawICAMetadataRequest.prototype.smallerAggregatingRetryCountFirst = false;
    
                    /**
                     * Creates a new ListRawICAMetadataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAMetadataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListRawICAMetadataRequest} ListRawICAMetadataRequest instance
                     */
                    ListRawICAMetadataRequest.create = function create(properties) {
                        return new ListRawICAMetadataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListRawICAMetadataRequest message. Does not implicitly {@link palexy.streaming.v1.ListRawICAMetadataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAMetadataRequest} message ListRawICAMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICAMetadataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.startDate);
                        if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.endDate);
                        if (message.statuses != null && message.statuses.length) {
                            writer.uint32(/* id 5, wireType 2 =*/42).fork();
                            for (var i = 0; i < message.statuses.length; ++i)
                                writer.int32(message.statuses[i]);
                            writer.ldelim();
                        }
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.pageToken);
                        if (message.invalid != null && Object.hasOwnProperty.call(message, "invalid"))
                            $root.google.protobuf.BoolValue.encode(message.invalid, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.smallerAggregatingRetryCountFirst != null && Object.hasOwnProperty.call(message, "smallerAggregatingRetryCountFirst"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.smallerAggregatingRetryCountFirst);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListRawICAMetadataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListRawICAMetadataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAMetadataRequest} message ListRawICAMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICAMetadataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListRawICAMetadataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListRawICAMetadataRequest} ListRawICAMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICAMetadataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListRawICAMetadataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.startDate = reader.int32();
                                break;
                            case 4:
                                message.endDate = reader.int32();
                                break;
                            case 5:
                                if (!(message.statuses && message.statuses.length))
                                    message.statuses = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.statuses.push(reader.int32());
                                } else
                                    message.statuses.push(reader.int32());
                                break;
                            case 6:
                                message.pageSize = reader.int32();
                                break;
                            case 7:
                                message.pageToken = reader.string();
                                break;
                            case 8:
                                message.invalid = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.smallerAggregatingRetryCountFirst = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListRawICAMetadataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListRawICAMetadataRequest} ListRawICAMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICAMetadataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListRawICAMetadataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListRawICAMetadataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isInteger(message.startDate))
                                return "startDate: integer expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isInteger(message.endDate))
                                return "endDate: integer expected";
                        if (message.statuses != null && message.hasOwnProperty("statuses")) {
                            if (!Array.isArray(message.statuses))
                                return "statuses: array expected";
                            for (var i = 0; i < message.statuses.length; ++i)
                                switch (message.statuses[i]) {
                                default:
                                    return "statuses: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 10:
                                    break;
                                }
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.invalid != null && message.hasOwnProperty("invalid")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.invalid);
                            if (error)
                                return "invalid." + error;
                        }
                        if (message.smallerAggregatingRetryCountFirst != null && message.hasOwnProperty("smallerAggregatingRetryCountFirst"))
                            if (typeof message.smallerAggregatingRetryCountFirst !== "boolean")
                                return "smallerAggregatingRetryCountFirst: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListRawICAMetadataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListRawICAMetadataRequest} ListRawICAMetadataRequest
                     */
                    ListRawICAMetadataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListRawICAMetadataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListRawICAMetadataRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.startDate != null)
                            message.startDate = object.startDate | 0;
                        if (object.endDate != null)
                            message.endDate = object.endDate | 0;
                        if (object.statuses) {
                            if (!Array.isArray(object.statuses))
                                throw TypeError(".palexy.streaming.v1.ListRawICAMetadataRequest.statuses: array expected");
                            message.statuses = [];
                            for (var i = 0; i < object.statuses.length; ++i)
                                switch (object.statuses[i]) {
                                default:
                                case "RAW_STATUS_UNKNOWN":
                                case 0:
                                    message.statuses[i] = 0;
                                    break;
                                case "RAW_READY":
                                case 1:
                                    message.statuses[i] = 1;
                                    break;
                                case "AGGREGATING":
                                case 2:
                                    message.statuses[i] = 2;
                                    break;
                                case "DONE_AGGREGATING":
                                case 3:
                                    message.statuses[i] = 3;
                                    break;
                                case "EXTERNAL_TRAFFIC_READY":
                                case 4:
                                    message.statuses[i] = 4;
                                    break;
                                case "DELETED":
                                case 10:
                                    message.statuses[i] = 10;
                                    break;
                                }
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.invalid != null) {
                            if (typeof object.invalid !== "object")
                                throw TypeError(".palexy.streaming.v1.ListRawICAMetadataRequest.invalid: object expected");
                            message.invalid = $root.google.protobuf.BoolValue.fromObject(object.invalid);
                        }
                        if (object.smallerAggregatingRetryCountFirst != null)
                            message.smallerAggregatingRetryCountFirst = Boolean(object.smallerAggregatingRetryCountFirst);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListRawICAMetadataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.ListRawICAMetadataRequest} message ListRawICAMetadataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListRawICAMetadataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.statuses = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.startDate = 0;
                            object.endDate = 0;
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.invalid = null;
                            object.smallerAggregatingRetryCountFirst = false;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.statuses && message.statuses.length) {
                            object.statuses = [];
                            for (var j = 0; j < message.statuses.length; ++j)
                                object.statuses[j] = options.enums === String ? $root.palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus[message.statuses[j]] : message.statuses[j];
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.invalid != null && message.hasOwnProperty("invalid"))
                            object.invalid = $root.google.protobuf.BoolValue.toObject(message.invalid, options);
                        if (message.smallerAggregatingRetryCountFirst != null && message.hasOwnProperty("smallerAggregatingRetryCountFirst"))
                            object.smallerAggregatingRetryCountFirst = message.smallerAggregatingRetryCountFirst;
                        return object;
                    };
    
                    /**
                     * Converts this ListRawICAMetadataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListRawICAMetadataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListRawICAMetadataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListRawICAMetadataRequest;
                })();
    
                v1.ListRawICAMetadataResponse = (function() {
    
                    /**
                     * Properties of a ListRawICAMetadataResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListRawICAMetadataResponse
                     * @property {Array.<palexy.streaming.v1.IRawICAMetadata>|null} [rawIcaMetadata] ListRawICAMetadataResponse rawIcaMetadata
                     * @property {number|null} [totalElements] ListRawICAMetadataResponse totalElements
                     * @property {string|null} [nextPageToken] ListRawICAMetadataResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListRawICAMetadataResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListRawICAMetadataResponse.
                     * @implements IListRawICAMetadataResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListRawICAMetadataResponse=} [properties] Properties to set
                     */
                    function ListRawICAMetadataResponse(properties) {
                        this.rawIcaMetadata = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListRawICAMetadataResponse rawIcaMetadata.
                     * @member {Array.<palexy.streaming.v1.IRawICAMetadata>} rawIcaMetadata
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @instance
                     */
                    ListRawICAMetadataResponse.prototype.rawIcaMetadata = $util.emptyArray;
    
                    /**
                     * ListRawICAMetadataResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @instance
                     */
                    ListRawICAMetadataResponse.prototype.totalElements = 0;
    
                    /**
                     * ListRawICAMetadataResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @instance
                     */
                    ListRawICAMetadataResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListRawICAMetadataResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAMetadataResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListRawICAMetadataResponse} ListRawICAMetadataResponse instance
                     */
                    ListRawICAMetadataResponse.create = function create(properties) {
                        return new ListRawICAMetadataResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListRawICAMetadataResponse message. Does not implicitly {@link palexy.streaming.v1.ListRawICAMetadataResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAMetadataResponse} message ListRawICAMetadataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICAMetadataResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rawIcaMetadata != null && message.rawIcaMetadata.length)
                            for (var i = 0; i < message.rawIcaMetadata.length; ++i)
                                $root.palexy.streaming.v1.RawICAMetadata.encode(message.rawIcaMetadata[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListRawICAMetadataResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListRawICAMetadataResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAMetadataResponse} message ListRawICAMetadataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICAMetadataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListRawICAMetadataResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListRawICAMetadataResponse} ListRawICAMetadataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICAMetadataResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListRawICAMetadataResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.rawIcaMetadata && message.rawIcaMetadata.length))
                                    message.rawIcaMetadata = [];
                                message.rawIcaMetadata.push($root.palexy.streaming.v1.RawICAMetadata.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListRawICAMetadataResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListRawICAMetadataResponse} ListRawICAMetadataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICAMetadataResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListRawICAMetadataResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListRawICAMetadataResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rawIcaMetadata != null && message.hasOwnProperty("rawIcaMetadata")) {
                            if (!Array.isArray(message.rawIcaMetadata))
                                return "rawIcaMetadata: array expected";
                            for (var i = 0; i < message.rawIcaMetadata.length; ++i) {
                                var error = $root.palexy.streaming.v1.RawICAMetadata.verify(message.rawIcaMetadata[i]);
                                if (error)
                                    return "rawIcaMetadata." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListRawICAMetadataResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListRawICAMetadataResponse} ListRawICAMetadataResponse
                     */
                    ListRawICAMetadataResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListRawICAMetadataResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListRawICAMetadataResponse();
                        if (object.rawIcaMetadata) {
                            if (!Array.isArray(object.rawIcaMetadata))
                                throw TypeError(".palexy.streaming.v1.ListRawICAMetadataResponse.rawIcaMetadata: array expected");
                            message.rawIcaMetadata = [];
                            for (var i = 0; i < object.rawIcaMetadata.length; ++i) {
                                if (typeof object.rawIcaMetadata[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListRawICAMetadataResponse.rawIcaMetadata: object expected");
                                message.rawIcaMetadata[i] = $root.palexy.streaming.v1.RawICAMetadata.fromObject(object.rawIcaMetadata[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListRawICAMetadataResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.ListRawICAMetadataResponse} message ListRawICAMetadataResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListRawICAMetadataResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.rawIcaMetadata = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.rawIcaMetadata && message.rawIcaMetadata.length) {
                            object.rawIcaMetadata = [];
                            for (var j = 0; j < message.rawIcaMetadata.length; ++j)
                                object.rawIcaMetadata[j] = $root.palexy.streaming.v1.RawICAMetadata.toObject(message.rawIcaMetadata[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListRawICAMetadataResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListRawICAMetadataResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListRawICAMetadataResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListRawICAMetadataResponse;
                })();
    
                v1.CreateRawICAMetadataRequest = (function() {
    
                    /**
                     * Properties of a CreateRawICAMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateRawICAMetadataRequest
                     * @property {palexy.streaming.v1.IRawICAMetadata|null} [rawIcaMetadata] CreateRawICAMetadataRequest rawIcaMetadata
                     * @property {boolean|null} [updateIfExists] CreateRawICAMetadataRequest updateIfExists
                     */
    
                    /**
                     * Constructs a new CreateRawICAMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateRawICAMetadataRequest.
                     * @implements ICreateRawICAMetadataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateRawICAMetadataRequest=} [properties] Properties to set
                     */
                    function CreateRawICAMetadataRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateRawICAMetadataRequest rawIcaMetadata.
                     * @member {palexy.streaming.v1.IRawICAMetadata|null|undefined} rawIcaMetadata
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataRequest
                     * @instance
                     */
                    CreateRawICAMetadataRequest.prototype.rawIcaMetadata = null;
    
                    /**
                     * CreateRawICAMetadataRequest updateIfExists.
                     * @member {boolean} updateIfExists
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataRequest
                     * @instance
                     */
                    CreateRawICAMetadataRequest.prototype.updateIfExists = false;
    
                    /**
                     * Creates a new CreateRawICAMetadataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateRawICAMetadataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateRawICAMetadataRequest} CreateRawICAMetadataRequest instance
                     */
                    CreateRawICAMetadataRequest.create = function create(properties) {
                        return new CreateRawICAMetadataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateRawICAMetadataRequest message. Does not implicitly {@link palexy.streaming.v1.CreateRawICAMetadataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateRawICAMetadataRequest} message CreateRawICAMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateRawICAMetadataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rawIcaMetadata != null && Object.hasOwnProperty.call(message, "rawIcaMetadata"))
                            $root.palexy.streaming.v1.RawICAMetadata.encode(message.rawIcaMetadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateIfExists != null && Object.hasOwnProperty.call(message, "updateIfExists"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.updateIfExists);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateRawICAMetadataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateRawICAMetadataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateRawICAMetadataRequest} message CreateRawICAMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateRawICAMetadataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateRawICAMetadataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateRawICAMetadataRequest} CreateRawICAMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateRawICAMetadataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateRawICAMetadataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.rawIcaMetadata = $root.palexy.streaming.v1.RawICAMetadata.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateIfExists = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateRawICAMetadataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateRawICAMetadataRequest} CreateRawICAMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateRawICAMetadataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateRawICAMetadataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateRawICAMetadataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rawIcaMetadata != null && message.hasOwnProperty("rawIcaMetadata")) {
                            var error = $root.palexy.streaming.v1.RawICAMetadata.verify(message.rawIcaMetadata);
                            if (error)
                                return "rawIcaMetadata." + error;
                        }
                        if (message.updateIfExists != null && message.hasOwnProperty("updateIfExists"))
                            if (typeof message.updateIfExists !== "boolean")
                                return "updateIfExists: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateRawICAMetadataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateRawICAMetadataRequest} CreateRawICAMetadataRequest
                     */
                    CreateRawICAMetadataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateRawICAMetadataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateRawICAMetadataRequest();
                        if (object.rawIcaMetadata != null) {
                            if (typeof object.rawIcaMetadata !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateRawICAMetadataRequest.rawIcaMetadata: object expected");
                            message.rawIcaMetadata = $root.palexy.streaming.v1.RawICAMetadata.fromObject(object.rawIcaMetadata);
                        }
                        if (object.updateIfExists != null)
                            message.updateIfExists = Boolean(object.updateIfExists);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateRawICAMetadataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateRawICAMetadataRequest} message CreateRawICAMetadataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateRawICAMetadataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.rawIcaMetadata = null;
                            object.updateIfExists = false;
                        }
                        if (message.rawIcaMetadata != null && message.hasOwnProperty("rawIcaMetadata"))
                            object.rawIcaMetadata = $root.palexy.streaming.v1.RawICAMetadata.toObject(message.rawIcaMetadata, options);
                        if (message.updateIfExists != null && message.hasOwnProperty("updateIfExists"))
                            object.updateIfExists = message.updateIfExists;
                        return object;
                    };
    
                    /**
                     * Converts this CreateRawICAMetadataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateRawICAMetadataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateRawICAMetadataRequest;
                })();
    
                v1.CreateRawICAMetadataResponse = (function() {
    
                    /**
                     * Properties of a CreateRawICAMetadataResponse.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateRawICAMetadataResponse
                     * @property {palexy.streaming.v1.IRawICAMetadata|null} [rawIcaMetadata] CreateRawICAMetadataResponse rawIcaMetadata
                     * @property {boolean|null} [created] CreateRawICAMetadataResponse created
                     */
    
                    /**
                     * Constructs a new CreateRawICAMetadataResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateRawICAMetadataResponse.
                     * @implements ICreateRawICAMetadataResponse
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateRawICAMetadataResponse=} [properties] Properties to set
                     */
                    function CreateRawICAMetadataResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateRawICAMetadataResponse rawIcaMetadata.
                     * @member {palexy.streaming.v1.IRawICAMetadata|null|undefined} rawIcaMetadata
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataResponse
                     * @instance
                     */
                    CreateRawICAMetadataResponse.prototype.rawIcaMetadata = null;
    
                    /**
                     * CreateRawICAMetadataResponse created.
                     * @member {boolean} created
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataResponse
                     * @instance
                     */
                    CreateRawICAMetadataResponse.prototype.created = false;
    
                    /**
                     * Creates a new CreateRawICAMetadataResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.ICreateRawICAMetadataResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateRawICAMetadataResponse} CreateRawICAMetadataResponse instance
                     */
                    CreateRawICAMetadataResponse.create = function create(properties) {
                        return new CreateRawICAMetadataResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateRawICAMetadataResponse message. Does not implicitly {@link palexy.streaming.v1.CreateRawICAMetadataResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.ICreateRawICAMetadataResponse} message CreateRawICAMetadataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateRawICAMetadataResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rawIcaMetadata != null && Object.hasOwnProperty.call(message, "rawIcaMetadata"))
                            $root.palexy.streaming.v1.RawICAMetadata.encode(message.rawIcaMetadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.created);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateRawICAMetadataResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateRawICAMetadataResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.ICreateRawICAMetadataResponse} message CreateRawICAMetadataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateRawICAMetadataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateRawICAMetadataResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateRawICAMetadataResponse} CreateRawICAMetadataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateRawICAMetadataResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateRawICAMetadataResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.rawIcaMetadata = $root.palexy.streaming.v1.RawICAMetadata.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.created = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateRawICAMetadataResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateRawICAMetadataResponse} CreateRawICAMetadataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateRawICAMetadataResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateRawICAMetadataResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateRawICAMetadataResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rawIcaMetadata != null && message.hasOwnProperty("rawIcaMetadata")) {
                            var error = $root.palexy.streaming.v1.RawICAMetadata.verify(message.rawIcaMetadata);
                            if (error)
                                return "rawIcaMetadata." + error;
                        }
                        if (message.created != null && message.hasOwnProperty("created"))
                            if (typeof message.created !== "boolean")
                                return "created: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateRawICAMetadataResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateRawICAMetadataResponse} CreateRawICAMetadataResponse
                     */
                    CreateRawICAMetadataResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateRawICAMetadataResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateRawICAMetadataResponse();
                        if (object.rawIcaMetadata != null) {
                            if (typeof object.rawIcaMetadata !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateRawICAMetadataResponse.rawIcaMetadata: object expected");
                            message.rawIcaMetadata = $root.palexy.streaming.v1.RawICAMetadata.fromObject(object.rawIcaMetadata);
                        }
                        if (object.created != null)
                            message.created = Boolean(object.created);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateRawICAMetadataResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.CreateRawICAMetadataResponse} message CreateRawICAMetadataResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateRawICAMetadataResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.rawIcaMetadata = null;
                            object.created = false;
                        }
                        if (message.rawIcaMetadata != null && message.hasOwnProperty("rawIcaMetadata"))
                            object.rawIcaMetadata = $root.palexy.streaming.v1.RawICAMetadata.toObject(message.rawIcaMetadata, options);
                        if (message.created != null && message.hasOwnProperty("created"))
                            object.created = message.created;
                        return object;
                    };
    
                    /**
                     * Converts this CreateRawICAMetadataResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateRawICAMetadataResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateRawICAMetadataResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateRawICAMetadataResponse;
                })();
    
                v1.UpdateRawICAMetadataRequest = (function() {
    
                    /**
                     * Properties of an UpdateRawICAMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateRawICAMetadataRequest
                     * @property {palexy.streaming.v1.IRawICAMetadata|null} [rawIcaMetadata] UpdateRawICAMetadataRequest rawIcaMetadata
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateRawICAMetadataRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateRawICAMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateRawICAMetadataRequest.
                     * @implements IUpdateRawICAMetadataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateRawICAMetadataRequest=} [properties] Properties to set
                     */
                    function UpdateRawICAMetadataRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateRawICAMetadataRequest rawIcaMetadata.
                     * @member {palexy.streaming.v1.IRawICAMetadata|null|undefined} rawIcaMetadata
                     * @memberof palexy.streaming.v1.UpdateRawICAMetadataRequest
                     * @instance
                     */
                    UpdateRawICAMetadataRequest.prototype.rawIcaMetadata = null;
    
                    /**
                     * UpdateRawICAMetadataRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateRawICAMetadataRequest
                     * @instance
                     */
                    UpdateRawICAMetadataRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateRawICAMetadataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateRawICAMetadataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateRawICAMetadataRequest} UpdateRawICAMetadataRequest instance
                     */
                    UpdateRawICAMetadataRequest.create = function create(properties) {
                        return new UpdateRawICAMetadataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateRawICAMetadataRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateRawICAMetadataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateRawICAMetadataRequest} message UpdateRawICAMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateRawICAMetadataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rawIcaMetadata != null && Object.hasOwnProperty.call(message, "rawIcaMetadata"))
                            $root.palexy.streaming.v1.RawICAMetadata.encode(message.rawIcaMetadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateRawICAMetadataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateRawICAMetadataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateRawICAMetadataRequest} message UpdateRawICAMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateRawICAMetadataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateRawICAMetadataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateRawICAMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateRawICAMetadataRequest} UpdateRawICAMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateRawICAMetadataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateRawICAMetadataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.rawIcaMetadata = $root.palexy.streaming.v1.RawICAMetadata.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateRawICAMetadataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateRawICAMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateRawICAMetadataRequest} UpdateRawICAMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateRawICAMetadataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateRawICAMetadataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateRawICAMetadataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateRawICAMetadataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rawIcaMetadata != null && message.hasOwnProperty("rawIcaMetadata")) {
                            var error = $root.palexy.streaming.v1.RawICAMetadata.verify(message.rawIcaMetadata);
                            if (error)
                                return "rawIcaMetadata." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateRawICAMetadataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateRawICAMetadataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateRawICAMetadataRequest} UpdateRawICAMetadataRequest
                     */
                    UpdateRawICAMetadataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateRawICAMetadataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateRawICAMetadataRequest();
                        if (object.rawIcaMetadata != null) {
                            if (typeof object.rawIcaMetadata !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateRawICAMetadataRequest.rawIcaMetadata: object expected");
                            message.rawIcaMetadata = $root.palexy.streaming.v1.RawICAMetadata.fromObject(object.rawIcaMetadata);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateRawICAMetadataRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateRawICAMetadataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateRawICAMetadataRequest} message UpdateRawICAMetadataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateRawICAMetadataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.rawIcaMetadata = null;
                            object.updateMask = null;
                        }
                        if (message.rawIcaMetadata != null && message.hasOwnProperty("rawIcaMetadata"))
                            object.rawIcaMetadata = $root.palexy.streaming.v1.RawICAMetadata.toObject(message.rawIcaMetadata, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateRawICAMetadataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateRawICAMetadataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateRawICAMetadataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateRawICAMetadataRequest;
                })();
    
                v1.GetRawIcaMetadataRequest = (function() {
    
                    /**
                     * Properties of a GetRawIcaMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetRawIcaMetadataRequest
                     * @property {number|null} [rawIcaMetadataId] GetRawIcaMetadataRequest rawIcaMetadataId
                     */
    
                    /**
                     * Constructs a new GetRawIcaMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetRawIcaMetadataRequest.
                     * @implements IGetRawIcaMetadataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetRawIcaMetadataRequest=} [properties] Properties to set
                     */
                    function GetRawIcaMetadataRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetRawIcaMetadataRequest rawIcaMetadataId.
                     * @member {number} rawIcaMetadataId
                     * @memberof palexy.streaming.v1.GetRawIcaMetadataRequest
                     * @instance
                     */
                    GetRawIcaMetadataRequest.prototype.rawIcaMetadataId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetRawIcaMetadataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetRawIcaMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetRawIcaMetadataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetRawIcaMetadataRequest} GetRawIcaMetadataRequest instance
                     */
                    GetRawIcaMetadataRequest.create = function create(properties) {
                        return new GetRawIcaMetadataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetRawIcaMetadataRequest message. Does not implicitly {@link palexy.streaming.v1.GetRawIcaMetadataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetRawIcaMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetRawIcaMetadataRequest} message GetRawIcaMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetRawIcaMetadataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rawIcaMetadataId != null && Object.hasOwnProperty.call(message, "rawIcaMetadataId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.rawIcaMetadataId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetRawIcaMetadataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetRawIcaMetadataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetRawIcaMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetRawIcaMetadataRequest} message GetRawIcaMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetRawIcaMetadataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetRawIcaMetadataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetRawIcaMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetRawIcaMetadataRequest} GetRawIcaMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetRawIcaMetadataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetRawIcaMetadataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.rawIcaMetadataId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetRawIcaMetadataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetRawIcaMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetRawIcaMetadataRequest} GetRawIcaMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetRawIcaMetadataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetRawIcaMetadataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetRawIcaMetadataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetRawIcaMetadataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rawIcaMetadataId != null && message.hasOwnProperty("rawIcaMetadataId"))
                            if (!$util.isInteger(message.rawIcaMetadataId) && !(message.rawIcaMetadataId && $util.isInteger(message.rawIcaMetadataId.low) && $util.isInteger(message.rawIcaMetadataId.high)))
                                return "rawIcaMetadataId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetRawIcaMetadataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetRawIcaMetadataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetRawIcaMetadataRequest} GetRawIcaMetadataRequest
                     */
                    GetRawIcaMetadataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetRawIcaMetadataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetRawIcaMetadataRequest();
                        if (object.rawIcaMetadataId != null)
                            if ($util.Long)
                                (message.rawIcaMetadataId = $util.Long.fromValue(object.rawIcaMetadataId)).unsigned = false;
                            else if (typeof object.rawIcaMetadataId === "string")
                                message.rawIcaMetadataId = parseInt(object.rawIcaMetadataId, 10);
                            else if (typeof object.rawIcaMetadataId === "number")
                                message.rawIcaMetadataId = object.rawIcaMetadataId;
                            else if (typeof object.rawIcaMetadataId === "object")
                                message.rawIcaMetadataId = new $util.LongBits(object.rawIcaMetadataId.low >>> 0, object.rawIcaMetadataId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetRawIcaMetadataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetRawIcaMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.GetRawIcaMetadataRequest} message GetRawIcaMetadataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetRawIcaMetadataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.rawIcaMetadataId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.rawIcaMetadataId = options.longs === String ? "0" : 0;
                        if (message.rawIcaMetadataId != null && message.hasOwnProperty("rawIcaMetadataId"))
                            if (typeof message.rawIcaMetadataId === "number")
                                object.rawIcaMetadataId = options.longs === String ? String(message.rawIcaMetadataId) : message.rawIcaMetadataId;
                            else
                                object.rawIcaMetadataId = options.longs === String ? $util.Long.prototype.toString.call(message.rawIcaMetadataId) : options.longs === Number ? new $util.LongBits(message.rawIcaMetadataId.low >>> 0, message.rawIcaMetadataId.high >>> 0).toNumber() : message.rawIcaMetadataId;
                        return object;
                    };
    
                    /**
                     * Converts this GetRawIcaMetadataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetRawIcaMetadataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetRawIcaMetadataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetRawIcaMetadataRequest;
                })();
    
                v1.BatchUpdateRawICAMetadataStatusRequest = (function() {
    
                    /**
                     * Properties of a BatchUpdateRawICAMetadataStatusRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchUpdateRawICAMetadataStatusRequest
                     * @property {number|null} [storeId] BatchUpdateRawICAMetadataStatusRequest storeId
                     * @property {Array.<palexy.streaming.v1.IDateRange>|null} [dateRanges] BatchUpdateRawICAMetadataStatusRequest dateRanges
                     * @property {Array.<palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus>|null} [oldStatuses] BatchUpdateRawICAMetadataStatusRequest oldStatuses
                     * @property {palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus|null} [newStatus] BatchUpdateRawICAMetadataStatusRequest newStatus
                     * @property {boolean|null} [resetAggregatingRetryCount] BatchUpdateRawICAMetadataStatusRequest resetAggregatingRetryCount
                     */
    
                    /**
                     * Constructs a new BatchUpdateRawICAMetadataStatusRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchUpdateRawICAMetadataStatusRequest.
                     * @implements IBatchUpdateRawICAMetadataStatusRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchUpdateRawICAMetadataStatusRequest=} [properties] Properties to set
                     */
                    function BatchUpdateRawICAMetadataStatusRequest(properties) {
                        this.dateRanges = [];
                        this.oldStatuses = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchUpdateRawICAMetadataStatusRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @instance
                     */
                    BatchUpdateRawICAMetadataStatusRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * BatchUpdateRawICAMetadataStatusRequest dateRanges.
                     * @member {Array.<palexy.streaming.v1.IDateRange>} dateRanges
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @instance
                     */
                    BatchUpdateRawICAMetadataStatusRequest.prototype.dateRanges = $util.emptyArray;
    
                    /**
                     * BatchUpdateRawICAMetadataStatusRequest oldStatuses.
                     * @member {Array.<palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus>} oldStatuses
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @instance
                     */
                    BatchUpdateRawICAMetadataStatusRequest.prototype.oldStatuses = $util.emptyArray;
    
                    /**
                     * BatchUpdateRawICAMetadataStatusRequest newStatus.
                     * @member {palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus} newStatus
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @instance
                     */
                    BatchUpdateRawICAMetadataStatusRequest.prototype.newStatus = 0;
    
                    /**
                     * BatchUpdateRawICAMetadataStatusRequest resetAggregatingRetryCount.
                     * @member {boolean} resetAggregatingRetryCount
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @instance
                     */
                    BatchUpdateRawICAMetadataStatusRequest.prototype.resetAggregatingRetryCount = false;
    
                    /**
                     * Creates a new BatchUpdateRawICAMetadataStatusRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateRawICAMetadataStatusRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest} BatchUpdateRawICAMetadataStatusRequest instance
                     */
                    BatchUpdateRawICAMetadataStatusRequest.create = function create(properties) {
                        return new BatchUpdateRawICAMetadataStatusRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchUpdateRawICAMetadataStatusRequest message. Does not implicitly {@link palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateRawICAMetadataStatusRequest} message BatchUpdateRawICAMetadataStatusRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateRawICAMetadataStatusRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateRanges != null && message.dateRanges.length)
                            for (var i = 0; i < message.dateRanges.length; ++i)
                                $root.palexy.streaming.v1.DateRange.encode(message.dateRanges[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.oldStatuses != null && message.oldStatuses.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.oldStatuses.length; ++i)
                                writer.int32(message.oldStatuses[i]);
                            writer.ldelim();
                        }
                        if (message.newStatus != null && Object.hasOwnProperty.call(message, "newStatus"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.newStatus);
                        if (message.resetAggregatingRetryCount != null && Object.hasOwnProperty.call(message, "resetAggregatingRetryCount"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.resetAggregatingRetryCount);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchUpdateRawICAMetadataStatusRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateRawICAMetadataStatusRequest} message BatchUpdateRawICAMetadataStatusRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateRawICAMetadataStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchUpdateRawICAMetadataStatusRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest} BatchUpdateRawICAMetadataStatusRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateRawICAMetadataStatusRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                if (!(message.dateRanges && message.dateRanges.length))
                                    message.dateRanges = [];
                                message.dateRanges.push($root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                if (!(message.oldStatuses && message.oldStatuses.length))
                                    message.oldStatuses = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.oldStatuses.push(reader.int32());
                                } else
                                    message.oldStatuses.push(reader.int32());
                                break;
                            case 4:
                                message.newStatus = reader.int32();
                                break;
                            case 5:
                                message.resetAggregatingRetryCount = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchUpdateRawICAMetadataStatusRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest} BatchUpdateRawICAMetadataStatusRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateRawICAMetadataStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchUpdateRawICAMetadataStatusRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchUpdateRawICAMetadataStatusRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateRanges != null && message.hasOwnProperty("dateRanges")) {
                            if (!Array.isArray(message.dateRanges))
                                return "dateRanges: array expected";
                            for (var i = 0; i < message.dateRanges.length; ++i) {
                                var error = $root.palexy.streaming.v1.DateRange.verify(message.dateRanges[i]);
                                if (error)
                                    return "dateRanges." + error;
                            }
                        }
                        if (message.oldStatuses != null && message.hasOwnProperty("oldStatuses")) {
                            if (!Array.isArray(message.oldStatuses))
                                return "oldStatuses: array expected";
                            for (var i = 0; i < message.oldStatuses.length; ++i)
                                switch (message.oldStatuses[i]) {
                                default:
                                    return "oldStatuses: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 10:
                                    break;
                                }
                        }
                        if (message.newStatus != null && message.hasOwnProperty("newStatus"))
                            switch (message.newStatus) {
                            default:
                                return "newStatus: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 10:
                                break;
                            }
                        if (message.resetAggregatingRetryCount != null && message.hasOwnProperty("resetAggregatingRetryCount"))
                            if (typeof message.resetAggregatingRetryCount !== "boolean")
                                return "resetAggregatingRetryCount: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchUpdateRawICAMetadataStatusRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest} BatchUpdateRawICAMetadataStatusRequest
                     */
                    BatchUpdateRawICAMetadataStatusRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateRanges) {
                            if (!Array.isArray(object.dateRanges))
                                throw TypeError(".palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest.dateRanges: array expected");
                            message.dateRanges = [];
                            for (var i = 0; i < object.dateRanges.length; ++i) {
                                if (typeof object.dateRanges[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest.dateRanges: object expected");
                                message.dateRanges[i] = $root.palexy.streaming.v1.DateRange.fromObject(object.dateRanges[i]);
                            }
                        }
                        if (object.oldStatuses) {
                            if (!Array.isArray(object.oldStatuses))
                                throw TypeError(".palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest.oldStatuses: array expected");
                            message.oldStatuses = [];
                            for (var i = 0; i < object.oldStatuses.length; ++i)
                                switch (object.oldStatuses[i]) {
                                default:
                                case "RAW_STATUS_UNKNOWN":
                                case 0:
                                    message.oldStatuses[i] = 0;
                                    break;
                                case "RAW_READY":
                                case 1:
                                    message.oldStatuses[i] = 1;
                                    break;
                                case "AGGREGATING":
                                case 2:
                                    message.oldStatuses[i] = 2;
                                    break;
                                case "DONE_AGGREGATING":
                                case 3:
                                    message.oldStatuses[i] = 3;
                                    break;
                                case "EXTERNAL_TRAFFIC_READY":
                                case 4:
                                    message.oldStatuses[i] = 4;
                                    break;
                                case "DELETED":
                                case 10:
                                    message.oldStatuses[i] = 10;
                                    break;
                                }
                        }
                        switch (object.newStatus) {
                        case "RAW_STATUS_UNKNOWN":
                        case 0:
                            message.newStatus = 0;
                            break;
                        case "RAW_READY":
                        case 1:
                            message.newStatus = 1;
                            break;
                        case "AGGREGATING":
                        case 2:
                            message.newStatus = 2;
                            break;
                        case "DONE_AGGREGATING":
                        case 3:
                            message.newStatus = 3;
                            break;
                        case "EXTERNAL_TRAFFIC_READY":
                        case 4:
                            message.newStatus = 4;
                            break;
                        case "DELETED":
                        case 10:
                            message.newStatus = 10;
                            break;
                        }
                        if (object.resetAggregatingRetryCount != null)
                            message.resetAggregatingRetryCount = Boolean(object.resetAggregatingRetryCount);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchUpdateRawICAMetadataStatusRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest} message BatchUpdateRawICAMetadataStatusRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchUpdateRawICAMetadataStatusRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.dateRanges = [];
                            object.oldStatuses = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.newStatus = options.enums === String ? "RAW_STATUS_UNKNOWN" : 0;
                            object.resetAggregatingRetryCount = false;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateRanges && message.dateRanges.length) {
                            object.dateRanges = [];
                            for (var j = 0; j < message.dateRanges.length; ++j)
                                object.dateRanges[j] = $root.palexy.streaming.v1.DateRange.toObject(message.dateRanges[j], options);
                        }
                        if (message.oldStatuses && message.oldStatuses.length) {
                            object.oldStatuses = [];
                            for (var j = 0; j < message.oldStatuses.length; ++j)
                                object.oldStatuses[j] = options.enums === String ? $root.palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus[message.oldStatuses[j]] : message.oldStatuses[j];
                        }
                        if (message.newStatus != null && message.hasOwnProperty("newStatus"))
                            object.newStatus = options.enums === String ? $root.palexy.streaming.v1.RawICAMetadata.RawICAMetadataStatus[message.newStatus] : message.newStatus;
                        if (message.resetAggregatingRetryCount != null && message.hasOwnProperty("resetAggregatingRetryCount"))
                            object.resetAggregatingRetryCount = message.resetAggregatingRetryCount;
                        return object;
                    };
    
                    /**
                     * Converts this BatchUpdateRawICAMetadataStatusRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchUpdateRawICAMetadataStatusRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchUpdateRawICAMetadataStatusRequest;
                })();
    
                v1.BatchUpdateRawICAMetadataStatusResponse = (function() {
    
                    /**
                     * Properties of a BatchUpdateRawICAMetadataStatusResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchUpdateRawICAMetadataStatusResponse
                     * @property {number|null} [affectedRowsCount] BatchUpdateRawICAMetadataStatusResponse affectedRowsCount
                     */
    
                    /**
                     * Constructs a new BatchUpdateRawICAMetadataStatusResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchUpdateRawICAMetadataStatusResponse.
                     * @implements IBatchUpdateRawICAMetadataStatusResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchUpdateRawICAMetadataStatusResponse=} [properties] Properties to set
                     */
                    function BatchUpdateRawICAMetadataStatusResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchUpdateRawICAMetadataStatusResponse affectedRowsCount.
                     * @member {number} affectedRowsCount
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse
                     * @instance
                     */
                    BatchUpdateRawICAMetadataStatusResponse.prototype.affectedRowsCount = 0;
    
                    /**
                     * Creates a new BatchUpdateRawICAMetadataStatusResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateRawICAMetadataStatusResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse} BatchUpdateRawICAMetadataStatusResponse instance
                     */
                    BatchUpdateRawICAMetadataStatusResponse.create = function create(properties) {
                        return new BatchUpdateRawICAMetadataStatusResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchUpdateRawICAMetadataStatusResponse message. Does not implicitly {@link palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateRawICAMetadataStatusResponse} message BatchUpdateRawICAMetadataStatusResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateRawICAMetadataStatusResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.affectedRowsCount != null && Object.hasOwnProperty.call(message, "affectedRowsCount"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.affectedRowsCount);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchUpdateRawICAMetadataStatusResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateRawICAMetadataStatusResponse} message BatchUpdateRawICAMetadataStatusResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateRawICAMetadataStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchUpdateRawICAMetadataStatusResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse} BatchUpdateRawICAMetadataStatusResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateRawICAMetadataStatusResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.affectedRowsCount = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchUpdateRawICAMetadataStatusResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse} BatchUpdateRawICAMetadataStatusResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateRawICAMetadataStatusResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchUpdateRawICAMetadataStatusResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchUpdateRawICAMetadataStatusResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.affectedRowsCount != null && message.hasOwnProperty("affectedRowsCount"))
                            if (!$util.isInteger(message.affectedRowsCount))
                                return "affectedRowsCount: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchUpdateRawICAMetadataStatusResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse} BatchUpdateRawICAMetadataStatusResponse
                     */
                    BatchUpdateRawICAMetadataStatusResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse();
                        if (object.affectedRowsCount != null)
                            message.affectedRowsCount = object.affectedRowsCount | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchUpdateRawICAMetadataStatusResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse} message BatchUpdateRawICAMetadataStatusResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchUpdateRawICAMetadataStatusResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.affectedRowsCount = 0;
                        if (message.affectedRowsCount != null && message.hasOwnProperty("affectedRowsCount"))
                            object.affectedRowsCount = message.affectedRowsCount;
                        return object;
                    };
    
                    /**
                     * Converts this BatchUpdateRawICAMetadataStatusResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchUpdateRawICAMetadataStatusResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchUpdateRawICAMetadataStatusResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchUpdateRawICAMetadataStatusResponse;
                })();
    
                v1.AggregateRawICADataRequest = (function() {
    
                    /**
                     * Properties of an AggregateRawICADataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IAggregateRawICADataRequest
                     * @property {number|null} [rawIcaMetadataId] AggregateRawICADataRequest rawIcaMetadataId
                     * @property {boolean|null} [skipCustomerActivitySummaries] AggregateRawICADataRequest skipCustomerActivitySummaries
                     * @property {boolean|null} [skipCustomerJourneysV2] AggregateRawICADataRequest skipCustomerJourneysV2
                     * @property {boolean|null} [skipCustomerJourneysV3] AggregateRawICADataRequest skipCustomerJourneysV3
                     * @property {boolean|null} [skipVisitorOccupancies] AggregateRawICADataRequest skipVisitorOccupancies
                     */
    
                    /**
                     * Constructs a new AggregateRawICADataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AggregateRawICADataRequest.
                     * @implements IAggregateRawICADataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IAggregateRawICADataRequest=} [properties] Properties to set
                     */
                    function AggregateRawICADataRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AggregateRawICADataRequest rawIcaMetadataId.
                     * @member {number} rawIcaMetadataId
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @instance
                     */
                    AggregateRawICADataRequest.prototype.rawIcaMetadataId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AggregateRawICADataRequest skipCustomerActivitySummaries.
                     * @member {boolean} skipCustomerActivitySummaries
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @instance
                     */
                    AggregateRawICADataRequest.prototype.skipCustomerActivitySummaries = false;
    
                    /**
                     * AggregateRawICADataRequest skipCustomerJourneysV2.
                     * @member {boolean} skipCustomerJourneysV2
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @instance
                     */
                    AggregateRawICADataRequest.prototype.skipCustomerJourneysV2 = false;
    
                    /**
                     * AggregateRawICADataRequest skipCustomerJourneysV3.
                     * @member {boolean} skipCustomerJourneysV3
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @instance
                     */
                    AggregateRawICADataRequest.prototype.skipCustomerJourneysV3 = false;
    
                    /**
                     * AggregateRawICADataRequest skipVisitorOccupancies.
                     * @member {boolean} skipVisitorOccupancies
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @instance
                     */
                    AggregateRawICADataRequest.prototype.skipVisitorOccupancies = false;
    
                    /**
                     * Creates a new AggregateRawICADataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.IAggregateRawICADataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AggregateRawICADataRequest} AggregateRawICADataRequest instance
                     */
                    AggregateRawICADataRequest.create = function create(properties) {
                        return new AggregateRawICADataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified AggregateRawICADataRequest message. Does not implicitly {@link palexy.streaming.v1.AggregateRawICADataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.IAggregateRawICADataRequest} message AggregateRawICADataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateRawICADataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rawIcaMetadataId != null && Object.hasOwnProperty.call(message, "rawIcaMetadataId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.rawIcaMetadataId);
                        if (message.skipCustomerActivitySummaries != null && Object.hasOwnProperty.call(message, "skipCustomerActivitySummaries"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.skipCustomerActivitySummaries);
                        if (message.skipCustomerJourneysV2 != null && Object.hasOwnProperty.call(message, "skipCustomerJourneysV2"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.skipCustomerJourneysV2);
                        if (message.skipCustomerJourneysV3 != null && Object.hasOwnProperty.call(message, "skipCustomerJourneysV3"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.skipCustomerJourneysV3);
                        if (message.skipVisitorOccupancies != null && Object.hasOwnProperty.call(message, "skipVisitorOccupancies"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.skipVisitorOccupancies);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AggregateRawICADataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.AggregateRawICADataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.IAggregateRawICADataRequest} message AggregateRawICADataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateRawICADataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AggregateRawICADataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AggregateRawICADataRequest} AggregateRawICADataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateRawICADataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AggregateRawICADataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.rawIcaMetadataId = reader.int64();
                                break;
                            case 2:
                                message.skipCustomerActivitySummaries = reader.bool();
                                break;
                            case 3:
                                message.skipCustomerJourneysV2 = reader.bool();
                                break;
                            case 4:
                                message.skipCustomerJourneysV3 = reader.bool();
                                break;
                            case 5:
                                message.skipVisitorOccupancies = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AggregateRawICADataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AggregateRawICADataRequest} AggregateRawICADataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateRawICADataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AggregateRawICADataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AggregateRawICADataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rawIcaMetadataId != null && message.hasOwnProperty("rawIcaMetadataId"))
                            if (!$util.isInteger(message.rawIcaMetadataId) && !(message.rawIcaMetadataId && $util.isInteger(message.rawIcaMetadataId.low) && $util.isInteger(message.rawIcaMetadataId.high)))
                                return "rawIcaMetadataId: integer|Long expected";
                        if (message.skipCustomerActivitySummaries != null && message.hasOwnProperty("skipCustomerActivitySummaries"))
                            if (typeof message.skipCustomerActivitySummaries !== "boolean")
                                return "skipCustomerActivitySummaries: boolean expected";
                        if (message.skipCustomerJourneysV2 != null && message.hasOwnProperty("skipCustomerJourneysV2"))
                            if (typeof message.skipCustomerJourneysV2 !== "boolean")
                                return "skipCustomerJourneysV2: boolean expected";
                        if (message.skipCustomerJourneysV3 != null && message.hasOwnProperty("skipCustomerJourneysV3"))
                            if (typeof message.skipCustomerJourneysV3 !== "boolean")
                                return "skipCustomerJourneysV3: boolean expected";
                        if (message.skipVisitorOccupancies != null && message.hasOwnProperty("skipVisitorOccupancies"))
                            if (typeof message.skipVisitorOccupancies !== "boolean")
                                return "skipVisitorOccupancies: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates an AggregateRawICADataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AggregateRawICADataRequest} AggregateRawICADataRequest
                     */
                    AggregateRawICADataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AggregateRawICADataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.AggregateRawICADataRequest();
                        if (object.rawIcaMetadataId != null)
                            if ($util.Long)
                                (message.rawIcaMetadataId = $util.Long.fromValue(object.rawIcaMetadataId)).unsigned = false;
                            else if (typeof object.rawIcaMetadataId === "string")
                                message.rawIcaMetadataId = parseInt(object.rawIcaMetadataId, 10);
                            else if (typeof object.rawIcaMetadataId === "number")
                                message.rawIcaMetadataId = object.rawIcaMetadataId;
                            else if (typeof object.rawIcaMetadataId === "object")
                                message.rawIcaMetadataId = new $util.LongBits(object.rawIcaMetadataId.low >>> 0, object.rawIcaMetadataId.high >>> 0).toNumber();
                        if (object.skipCustomerActivitySummaries != null)
                            message.skipCustomerActivitySummaries = Boolean(object.skipCustomerActivitySummaries);
                        if (object.skipCustomerJourneysV2 != null)
                            message.skipCustomerJourneysV2 = Boolean(object.skipCustomerJourneysV2);
                        if (object.skipCustomerJourneysV3 != null)
                            message.skipCustomerJourneysV3 = Boolean(object.skipCustomerJourneysV3);
                        if (object.skipVisitorOccupancies != null)
                            message.skipVisitorOccupancies = Boolean(object.skipVisitorOccupancies);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AggregateRawICADataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.AggregateRawICADataRequest} message AggregateRawICADataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AggregateRawICADataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.rawIcaMetadataId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.rawIcaMetadataId = options.longs === String ? "0" : 0;
                            object.skipCustomerActivitySummaries = false;
                            object.skipCustomerJourneysV2 = false;
                            object.skipCustomerJourneysV3 = false;
                            object.skipVisitorOccupancies = false;
                        }
                        if (message.rawIcaMetadataId != null && message.hasOwnProperty("rawIcaMetadataId"))
                            if (typeof message.rawIcaMetadataId === "number")
                                object.rawIcaMetadataId = options.longs === String ? String(message.rawIcaMetadataId) : message.rawIcaMetadataId;
                            else
                                object.rawIcaMetadataId = options.longs === String ? $util.Long.prototype.toString.call(message.rawIcaMetadataId) : options.longs === Number ? new $util.LongBits(message.rawIcaMetadataId.low >>> 0, message.rawIcaMetadataId.high >>> 0).toNumber() : message.rawIcaMetadataId;
                        if (message.skipCustomerActivitySummaries != null && message.hasOwnProperty("skipCustomerActivitySummaries"))
                            object.skipCustomerActivitySummaries = message.skipCustomerActivitySummaries;
                        if (message.skipCustomerJourneysV2 != null && message.hasOwnProperty("skipCustomerJourneysV2"))
                            object.skipCustomerJourneysV2 = message.skipCustomerJourneysV2;
                        if (message.skipCustomerJourneysV3 != null && message.hasOwnProperty("skipCustomerJourneysV3"))
                            object.skipCustomerJourneysV3 = message.skipCustomerJourneysV3;
                        if (message.skipVisitorOccupancies != null && message.hasOwnProperty("skipVisitorOccupancies"))
                            object.skipVisitorOccupancies = message.skipVisitorOccupancies;
                        return object;
                    };
    
                    /**
                     * Converts this AggregateRawICADataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AggregateRawICADataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AggregateRawICADataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AggregateRawICADataRequest;
                })();
    
                v1.AggregateRawICADataResponse = (function() {
    
                    /**
                     * Properties of an AggregateRawICADataResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IAggregateRawICADataResponse
                     */
    
                    /**
                     * Constructs a new AggregateRawICADataResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AggregateRawICADataResponse.
                     * @implements IAggregateRawICADataResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IAggregateRawICADataResponse=} [properties] Properties to set
                     */
                    function AggregateRawICADataResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new AggregateRawICADataResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AggregateRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.IAggregateRawICADataResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AggregateRawICADataResponse} AggregateRawICADataResponse instance
                     */
                    AggregateRawICADataResponse.create = function create(properties) {
                        return new AggregateRawICADataResponse(properties);
                    };
    
                    /**
                     * Encodes the specified AggregateRawICADataResponse message. Does not implicitly {@link palexy.streaming.v1.AggregateRawICADataResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AggregateRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.IAggregateRawICADataResponse} message AggregateRawICADataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateRawICADataResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AggregateRawICADataResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.AggregateRawICADataResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AggregateRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.IAggregateRawICADataResponse} message AggregateRawICADataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateRawICADataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AggregateRawICADataResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AggregateRawICADataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AggregateRawICADataResponse} AggregateRawICADataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateRawICADataResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AggregateRawICADataResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AggregateRawICADataResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AggregateRawICADataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AggregateRawICADataResponse} AggregateRawICADataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateRawICADataResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AggregateRawICADataResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AggregateRawICADataResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AggregateRawICADataResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates an AggregateRawICADataResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AggregateRawICADataResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AggregateRawICADataResponse} AggregateRawICADataResponse
                     */
                    AggregateRawICADataResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AggregateRawICADataResponse)
                            return object;
                        return new $root.palexy.streaming.v1.AggregateRawICADataResponse();
                    };
    
                    /**
                     * Creates a plain object from an AggregateRawICADataResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AggregateRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.AggregateRawICADataResponse} message AggregateRawICADataResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AggregateRawICADataResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this AggregateRawICADataResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AggregateRawICADataResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AggregateRawICADataResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AggregateRawICADataResponse;
                })();
    
                v1.BatchDeleteRawICAMetadataRequest = (function() {
    
                    /**
                     * Properties of a BatchDeleteRawICAMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchDeleteRawICAMetadataRequest
                     * @property {number|null} [companyId] BatchDeleteRawICAMetadataRequest companyId
                     * @property {number|null} [storeId] BatchDeleteRawICAMetadataRequest storeId
                     * @property {string|null} [dateId] BatchDeleteRawICAMetadataRequest dateId
                     * @property {palexy.streaming.v1.IDateRange|null} [dateRange] BatchDeleteRawICAMetadataRequest dateRange
                     * @property {number|null} [editorAccountId] BatchDeleteRawICAMetadataRequest editorAccountId
                     */
    
                    /**
                     * Constructs a new BatchDeleteRawICAMetadataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchDeleteRawICAMetadataRequest.
                     * @implements IBatchDeleteRawICAMetadataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchDeleteRawICAMetadataRequest=} [properties] Properties to set
                     */
                    function BatchDeleteRawICAMetadataRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchDeleteRawICAMetadataRequest companyId.
                     * @member {number|null|undefined} companyId
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @instance
                     */
                    BatchDeleteRawICAMetadataRequest.prototype.companyId = null;
    
                    /**
                     * BatchDeleteRawICAMetadataRequest storeId.
                     * @member {number|null|undefined} storeId
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @instance
                     */
                    BatchDeleteRawICAMetadataRequest.prototype.storeId = null;
    
                    /**
                     * BatchDeleteRawICAMetadataRequest dateId.
                     * @member {string|null|undefined} dateId
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @instance
                     */
                    BatchDeleteRawICAMetadataRequest.prototype.dateId = null;
    
                    /**
                     * BatchDeleteRawICAMetadataRequest dateRange.
                     * @member {palexy.streaming.v1.IDateRange|null|undefined} dateRange
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @instance
                     */
                    BatchDeleteRawICAMetadataRequest.prototype.dateRange = null;
    
                    /**
                     * BatchDeleteRawICAMetadataRequest editorAccountId.
                     * @member {number} editorAccountId
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @instance
                     */
                    BatchDeleteRawICAMetadataRequest.prototype.editorAccountId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * BatchDeleteRawICAMetadataRequest storeFilter.
                     * @member {"companyId"|"storeId"|undefined} storeFilter
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @instance
                     */
                    Object.defineProperty(BatchDeleteRawICAMetadataRequest.prototype, "storeFilter", {
                        get: $util.oneOfGetter($oneOfFields = ["companyId", "storeId"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * BatchDeleteRawICAMetadataRequest dateFilter.
                     * @member {"dateId"|"dateRange"|undefined} dateFilter
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @instance
                     */
                    Object.defineProperty(BatchDeleteRawICAMetadataRequest.prototype, "dateFilter", {
                        get: $util.oneOfGetter($oneOfFields = ["dateId", "dateRange"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new BatchDeleteRawICAMetadataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteRawICAMetadataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchDeleteRawICAMetadataRequest} BatchDeleteRawICAMetadataRequest instance
                     */
                    BatchDeleteRawICAMetadataRequest.create = function create(properties) {
                        return new BatchDeleteRawICAMetadataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchDeleteRawICAMetadataRequest message. Does not implicitly {@link palexy.streaming.v1.BatchDeleteRawICAMetadataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteRawICAMetadataRequest} message BatchDeleteRawICAMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteRawICAMetadataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.dateId);
                        if (message.dateRange != null && Object.hasOwnProperty.call(message, "dateRange"))
                            $root.palexy.streaming.v1.DateRange.encode(message.dateRange, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.editorAccountId != null && Object.hasOwnProperty.call(message, "editorAccountId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.editorAccountId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchDeleteRawICAMetadataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchDeleteRawICAMetadataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteRawICAMetadataRequest} message BatchDeleteRawICAMetadataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteRawICAMetadataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchDeleteRawICAMetadataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchDeleteRawICAMetadataRequest} BatchDeleteRawICAMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteRawICAMetadataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchDeleteRawICAMetadataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.dateId = reader.string();
                                break;
                            case 4:
                                message.dateRange = $root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.editorAccountId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchDeleteRawICAMetadataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchDeleteRawICAMetadataRequest} BatchDeleteRawICAMetadataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteRawICAMetadataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchDeleteRawICAMetadataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchDeleteRawICAMetadataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.companyId != null && message.hasOwnProperty("companyId")) {
                            properties.storeFilter = 1;
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            if (properties.storeFilter === 1)
                                return "storeFilter: multiple values";
                            properties.storeFilter = 1;
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId")) {
                            properties.dateFilter = 1;
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        }
                        if (message.dateRange != null && message.hasOwnProperty("dateRange")) {
                            if (properties.dateFilter === 1)
                                return "dateFilter: multiple values";
                            properties.dateFilter = 1;
                            {
                                var error = $root.palexy.streaming.v1.DateRange.verify(message.dateRange);
                                if (error)
                                    return "dateRange." + error;
                            }
                        }
                        if (message.editorAccountId != null && message.hasOwnProperty("editorAccountId"))
                            if (!$util.isInteger(message.editorAccountId) && !(message.editorAccountId && $util.isInteger(message.editorAccountId.low) && $util.isInteger(message.editorAccountId.high)))
                                return "editorAccountId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchDeleteRawICAMetadataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchDeleteRawICAMetadataRequest} BatchDeleteRawICAMetadataRequest
                     */
                    BatchDeleteRawICAMetadataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchDeleteRawICAMetadataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchDeleteRawICAMetadataRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.dateRange != null) {
                            if (typeof object.dateRange !== "object")
                                throw TypeError(".palexy.streaming.v1.BatchDeleteRawICAMetadataRequest.dateRange: object expected");
                            message.dateRange = $root.palexy.streaming.v1.DateRange.fromObject(object.dateRange);
                        }
                        if (object.editorAccountId != null)
                            if ($util.Long)
                                (message.editorAccountId = $util.Long.fromValue(object.editorAccountId)).unsigned = false;
                            else if (typeof object.editorAccountId === "string")
                                message.editorAccountId = parseInt(object.editorAccountId, 10);
                            else if (typeof object.editorAccountId === "number")
                                message.editorAccountId = object.editorAccountId;
                            else if (typeof object.editorAccountId === "object")
                                message.editorAccountId = new $util.LongBits(object.editorAccountId.low >>> 0, object.editorAccountId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchDeleteRawICAMetadataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchDeleteRawICAMetadataRequest} message BatchDeleteRawICAMetadataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchDeleteRawICAMetadataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.editorAccountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.editorAccountId = options.longs === String ? "0" : 0;
                        if (message.companyId != null && message.hasOwnProperty("companyId")) {
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                            if (options.oneofs)
                                object.storeFilter = "companyId";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            if (options.oneofs)
                                object.storeFilter = "storeId";
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId")) {
                            object.dateId = message.dateId;
                            if (options.oneofs)
                                object.dateFilter = "dateId";
                        }
                        if (message.dateRange != null && message.hasOwnProperty("dateRange")) {
                            object.dateRange = $root.palexy.streaming.v1.DateRange.toObject(message.dateRange, options);
                            if (options.oneofs)
                                object.dateFilter = "dateRange";
                        }
                        if (message.editorAccountId != null && message.hasOwnProperty("editorAccountId"))
                            if (typeof message.editorAccountId === "number")
                                object.editorAccountId = options.longs === String ? String(message.editorAccountId) : message.editorAccountId;
                            else
                                object.editorAccountId = options.longs === String ? $util.Long.prototype.toString.call(message.editorAccountId) : options.longs === Number ? new $util.LongBits(message.editorAccountId.low >>> 0, message.editorAccountId.high >>> 0).toNumber() : message.editorAccountId;
                        return object;
                    };
    
                    /**
                     * Converts this BatchDeleteRawICAMetadataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchDeleteRawICAMetadataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchDeleteRawICAMetadataRequest;
                })();
    
                v1.BatchDeleteRawICAMetadataResponse = (function() {
    
                    /**
                     * Properties of a BatchDeleteRawICAMetadataResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchDeleteRawICAMetadataResponse
                     * @property {number|null} [affectedRowsCount] BatchDeleteRawICAMetadataResponse affectedRowsCount
                     */
    
                    /**
                     * Constructs a new BatchDeleteRawICAMetadataResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchDeleteRawICAMetadataResponse.
                     * @implements IBatchDeleteRawICAMetadataResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchDeleteRawICAMetadataResponse=} [properties] Properties to set
                     */
                    function BatchDeleteRawICAMetadataResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchDeleteRawICAMetadataResponse affectedRowsCount.
                     * @member {number} affectedRowsCount
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataResponse
                     * @instance
                     */
                    BatchDeleteRawICAMetadataResponse.prototype.affectedRowsCount = 0;
    
                    /**
                     * Creates a new BatchDeleteRawICAMetadataResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteRawICAMetadataResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchDeleteRawICAMetadataResponse} BatchDeleteRawICAMetadataResponse instance
                     */
                    BatchDeleteRawICAMetadataResponse.create = function create(properties) {
                        return new BatchDeleteRawICAMetadataResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchDeleteRawICAMetadataResponse message. Does not implicitly {@link palexy.streaming.v1.BatchDeleteRawICAMetadataResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteRawICAMetadataResponse} message BatchDeleteRawICAMetadataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteRawICAMetadataResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.affectedRowsCount != null && Object.hasOwnProperty.call(message, "affectedRowsCount"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.affectedRowsCount);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchDeleteRawICAMetadataResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchDeleteRawICAMetadataResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteRawICAMetadataResponse} message BatchDeleteRawICAMetadataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteRawICAMetadataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchDeleteRawICAMetadataResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchDeleteRawICAMetadataResponse} BatchDeleteRawICAMetadataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteRawICAMetadataResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchDeleteRawICAMetadataResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.affectedRowsCount = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchDeleteRawICAMetadataResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchDeleteRawICAMetadataResponse} BatchDeleteRawICAMetadataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteRawICAMetadataResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchDeleteRawICAMetadataResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchDeleteRawICAMetadataResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.affectedRowsCount != null && message.hasOwnProperty("affectedRowsCount"))
                            if (!$util.isInteger(message.affectedRowsCount))
                                return "affectedRowsCount: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchDeleteRawICAMetadataResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchDeleteRawICAMetadataResponse} BatchDeleteRawICAMetadataResponse
                     */
                    BatchDeleteRawICAMetadataResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchDeleteRawICAMetadataResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchDeleteRawICAMetadataResponse();
                        if (object.affectedRowsCount != null)
                            message.affectedRowsCount = object.affectedRowsCount | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchDeleteRawICAMetadataResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchDeleteRawICAMetadataResponse} message BatchDeleteRawICAMetadataResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchDeleteRawICAMetadataResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.affectedRowsCount = 0;
                        if (message.affectedRowsCount != null && message.hasOwnProperty("affectedRowsCount"))
                            object.affectedRowsCount = message.affectedRowsCount;
                        return object;
                    };
    
                    /**
                     * Converts this BatchDeleteRawICAMetadataResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchDeleteRawICAMetadataResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchDeleteRawICAMetadataResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchDeleteRawICAMetadataResponse;
                })();
    
                v1.LayoutService = (function() {
    
                    /**
                     * Constructs a new LayoutService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a LayoutService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function LayoutService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (LayoutService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = LayoutService;
    
                    /**
                     * Creates new LayoutService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.LayoutService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {LayoutService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    LayoutService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.LayoutService#listLayout}.
                     * @memberof palexy.streaming.v1.LayoutService
                     * @typedef ListLayoutCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListLayoutResponse} [response] ListLayoutResponse
                     */
    
                    /**
                     * Calls ListLayout.
                     * @function listLayout
                     * @memberof palexy.streaming.v1.LayoutService
                     * @instance
                     * @param {palexy.streaming.v1.IListLayoutRequest} request ListLayoutRequest message or plain object
                     * @param {palexy.streaming.v1.LayoutService.ListLayoutCallback} callback Node-style callback called with the error, if any, and ListLayoutResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(LayoutService.prototype.listLayout = function listLayout(request, callback) {
                        return this.rpcCall(listLayout, $root.palexy.streaming.v1.ListLayoutRequest, $root.palexy.streaming.v1.ListLayoutResponse, request, callback);
                    }, "name", { value: "ListLayout" });
    
                    /**
                     * Calls ListLayout.
                     * @function listLayout
                     * @memberof palexy.streaming.v1.LayoutService
                     * @instance
                     * @param {palexy.streaming.v1.IListLayoutRequest} request ListLayoutRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListLayoutResponse>} Promise
                     * @variation 2
                     */
    
                    return LayoutService;
                })();
    
                v1.Layout = (function() {
    
                    /**
                     * Properties of a Layout.
                     * @memberof palexy.streaming.v1
                     * @interface ILayout
                     * @property {number|null} [id] Layout id
                     * @property {string|null} [name] Layout name
                     * @property {number|null} [areaId] Layout areaId
                     * @property {number|null} [appliedDateId] Layout appliedDateId
                     */
    
                    /**
                     * Constructs a new Layout.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Layout.
                     * @implements ILayout
                     * @constructor
                     * @param {palexy.streaming.v1.ILayout=} [properties] Properties to set
                     */
                    function Layout(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Layout id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.Layout
                     * @instance
                     */
                    Layout.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Layout name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.Layout
                     * @instance
                     */
                    Layout.prototype.name = "";
    
                    /**
                     * Layout areaId.
                     * @member {number} areaId
                     * @memberof palexy.streaming.v1.Layout
                     * @instance
                     */
                    Layout.prototype.areaId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Layout appliedDateId.
                     * @member {number} appliedDateId
                     * @memberof palexy.streaming.v1.Layout
                     * @instance
                     */
                    Layout.prototype.appliedDateId = 0;
    
                    /**
                     * Creates a new Layout instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Layout
                     * @static
                     * @param {palexy.streaming.v1.ILayout=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Layout} Layout instance
                     */
                    Layout.create = function create(properties) {
                        return new Layout(properties);
                    };
    
                    /**
                     * Encodes the specified Layout message. Does not implicitly {@link palexy.streaming.v1.Layout.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Layout
                     * @static
                     * @param {palexy.streaming.v1.ILayout} message Layout message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Layout.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.areaId != null && Object.hasOwnProperty.call(message, "areaId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.areaId);
                        if (message.appliedDateId != null && Object.hasOwnProperty.call(message, "appliedDateId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.appliedDateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Layout message, length delimited. Does not implicitly {@link palexy.streaming.v1.Layout.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Layout
                     * @static
                     * @param {palexy.streaming.v1.ILayout} message Layout message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Layout.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Layout message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Layout
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Layout} Layout
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Layout.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Layout();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.areaId = reader.int64();
                                break;
                            case 4:
                                message.appliedDateId = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Layout message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Layout
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Layout} Layout
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Layout.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Layout message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Layout
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Layout.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.areaId != null && message.hasOwnProperty("areaId"))
                            if (!$util.isInteger(message.areaId) && !(message.areaId && $util.isInteger(message.areaId.low) && $util.isInteger(message.areaId.high)))
                                return "areaId: integer|Long expected";
                        if (message.appliedDateId != null && message.hasOwnProperty("appliedDateId"))
                            if (!$util.isInteger(message.appliedDateId))
                                return "appliedDateId: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a Layout message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Layout
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Layout} Layout
                     */
                    Layout.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Layout)
                            return object;
                        var message = new $root.palexy.streaming.v1.Layout();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.areaId != null)
                            if ($util.Long)
                                (message.areaId = $util.Long.fromValue(object.areaId)).unsigned = false;
                            else if (typeof object.areaId === "string")
                                message.areaId = parseInt(object.areaId, 10);
                            else if (typeof object.areaId === "number")
                                message.areaId = object.areaId;
                            else if (typeof object.areaId === "object")
                                message.areaId = new $util.LongBits(object.areaId.low >>> 0, object.areaId.high >>> 0).toNumber();
                        if (object.appliedDateId != null)
                            message.appliedDateId = object.appliedDateId | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Layout message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Layout
                     * @static
                     * @param {palexy.streaming.v1.Layout} message Layout
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Layout.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.areaId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.areaId = options.longs === String ? "0" : 0;
                            object.appliedDateId = 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.areaId != null && message.hasOwnProperty("areaId"))
                            if (typeof message.areaId === "number")
                                object.areaId = options.longs === String ? String(message.areaId) : message.areaId;
                            else
                                object.areaId = options.longs === String ? $util.Long.prototype.toString.call(message.areaId) : options.longs === Number ? new $util.LongBits(message.areaId.low >>> 0, message.areaId.high >>> 0).toNumber() : message.areaId;
                        if (message.appliedDateId != null && message.hasOwnProperty("appliedDateId"))
                            object.appliedDateId = message.appliedDateId;
                        return object;
                    };
    
                    /**
                     * Converts this Layout to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Layout
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Layout.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Layout;
                })();
    
                v1.ListLayoutRequest = (function() {
    
                    /**
                     * Properties of a ListLayoutRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListLayoutRequest
                     * @property {Array.<number>|null} [areaIds] ListLayoutRequest areaIds
                     * @property {google.protobuf.IInt32Value|null} [dateId] ListLayoutRequest dateId
                     */
    
                    /**
                     * Constructs a new ListLayoutRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListLayoutRequest.
                     * @implements IListLayoutRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListLayoutRequest=} [properties] Properties to set
                     */
                    function ListLayoutRequest(properties) {
                        this.areaIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListLayoutRequest areaIds.
                     * @member {Array.<number>} areaIds
                     * @memberof palexy.streaming.v1.ListLayoutRequest
                     * @instance
                     */
                    ListLayoutRequest.prototype.areaIds = $util.emptyArray;
    
                    /**
                     * ListLayoutRequest dateId.
                     * @member {google.protobuf.IInt32Value|null|undefined} dateId
                     * @memberof palexy.streaming.v1.ListLayoutRequest
                     * @instance
                     */
                    ListLayoutRequest.prototype.dateId = null;
    
                    /**
                     * Creates a new ListLayoutRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListLayoutRequest
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListLayoutRequest} ListLayoutRequest instance
                     */
                    ListLayoutRequest.create = function create(properties) {
                        return new ListLayoutRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListLayoutRequest message. Does not implicitly {@link palexy.streaming.v1.ListLayoutRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListLayoutRequest
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutRequest} message ListLayoutRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListLayoutRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.areaIds != null && message.areaIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.areaIds.length; ++i)
                                writer.int64(message.areaIds[i]);
                            writer.ldelim();
                        }
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            $root.google.protobuf.Int32Value.encode(message.dateId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListLayoutRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListLayoutRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListLayoutRequest
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutRequest} message ListLayoutRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListLayoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListLayoutRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListLayoutRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListLayoutRequest} ListLayoutRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListLayoutRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListLayoutRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.areaIds && message.areaIds.length))
                                    message.areaIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.areaIds.push(reader.int64());
                                } else
                                    message.areaIds.push(reader.int64());
                                break;
                            case 2:
                                message.dateId = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListLayoutRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListLayoutRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListLayoutRequest} ListLayoutRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListLayoutRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListLayoutRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListLayoutRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListLayoutRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.areaIds != null && message.hasOwnProperty("areaIds")) {
                            if (!Array.isArray(message.areaIds))
                                return "areaIds: array expected";
                            for (var i = 0; i < message.areaIds.length; ++i)
                                if (!$util.isInteger(message.areaIds[i]) && !(message.areaIds[i] && $util.isInteger(message.areaIds[i].low) && $util.isInteger(message.areaIds[i].high)))
                                    return "areaIds: integer|Long[] expected";
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId")) {
                            var error = $root.google.protobuf.Int32Value.verify(message.dateId);
                            if (error)
                                return "dateId." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListLayoutRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListLayoutRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListLayoutRequest} ListLayoutRequest
                     */
                    ListLayoutRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListLayoutRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListLayoutRequest();
                        if (object.areaIds) {
                            if (!Array.isArray(object.areaIds))
                                throw TypeError(".palexy.streaming.v1.ListLayoutRequest.areaIds: array expected");
                            message.areaIds = [];
                            for (var i = 0; i < object.areaIds.length; ++i)
                                if ($util.Long)
                                    (message.areaIds[i] = $util.Long.fromValue(object.areaIds[i])).unsigned = false;
                                else if (typeof object.areaIds[i] === "string")
                                    message.areaIds[i] = parseInt(object.areaIds[i], 10);
                                else if (typeof object.areaIds[i] === "number")
                                    message.areaIds[i] = object.areaIds[i];
                                else if (typeof object.areaIds[i] === "object")
                                    message.areaIds[i] = new $util.LongBits(object.areaIds[i].low >>> 0, object.areaIds[i].high >>> 0).toNumber();
                        }
                        if (object.dateId != null) {
                            if (typeof object.dateId !== "object")
                                throw TypeError(".palexy.streaming.v1.ListLayoutRequest.dateId: object expected");
                            message.dateId = $root.google.protobuf.Int32Value.fromObject(object.dateId);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListLayoutRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListLayoutRequest
                     * @static
                     * @param {palexy.streaming.v1.ListLayoutRequest} message ListLayoutRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListLayoutRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.areaIds = [];
                        if (options.defaults)
                            object.dateId = null;
                        if (message.areaIds && message.areaIds.length) {
                            object.areaIds = [];
                            for (var j = 0; j < message.areaIds.length; ++j)
                                if (typeof message.areaIds[j] === "number")
                                    object.areaIds[j] = options.longs === String ? String(message.areaIds[j]) : message.areaIds[j];
                                else
                                    object.areaIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.areaIds[j]) : options.longs === Number ? new $util.LongBits(message.areaIds[j].low >>> 0, message.areaIds[j].high >>> 0).toNumber() : message.areaIds[j];
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = $root.google.protobuf.Int32Value.toObject(message.dateId, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListLayoutRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListLayoutRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListLayoutRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListLayoutRequest;
                })();
    
                v1.ListLayoutResponse = (function() {
    
                    /**
                     * Properties of a ListLayoutResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListLayoutResponse
                     * @property {Array.<palexy.streaming.v1.ILayout>|null} [layouts] ListLayoutResponse layouts
                     */
    
                    /**
                     * Constructs a new ListLayoutResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListLayoutResponse.
                     * @implements IListLayoutResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListLayoutResponse=} [properties] Properties to set
                     */
                    function ListLayoutResponse(properties) {
                        this.layouts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListLayoutResponse layouts.
                     * @member {Array.<palexy.streaming.v1.ILayout>} layouts
                     * @memberof palexy.streaming.v1.ListLayoutResponse
                     * @instance
                     */
                    ListLayoutResponse.prototype.layouts = $util.emptyArray;
    
                    /**
                     * Creates a new ListLayoutResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListLayoutResponse
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListLayoutResponse} ListLayoutResponse instance
                     */
                    ListLayoutResponse.create = function create(properties) {
                        return new ListLayoutResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListLayoutResponse message. Does not implicitly {@link palexy.streaming.v1.ListLayoutResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListLayoutResponse
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutResponse} message ListLayoutResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListLayoutResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.layouts != null && message.layouts.length)
                            for (var i = 0; i < message.layouts.length; ++i)
                                $root.palexy.streaming.v1.Layout.encode(message.layouts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListLayoutResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListLayoutResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListLayoutResponse
                     * @static
                     * @param {palexy.streaming.v1.IListLayoutResponse} message ListLayoutResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListLayoutResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListLayoutResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListLayoutResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListLayoutResponse} ListLayoutResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListLayoutResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListLayoutResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.layouts && message.layouts.length))
                                    message.layouts = [];
                                message.layouts.push($root.palexy.streaming.v1.Layout.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListLayoutResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListLayoutResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListLayoutResponse} ListLayoutResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListLayoutResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListLayoutResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListLayoutResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListLayoutResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.layouts != null && message.hasOwnProperty("layouts")) {
                            if (!Array.isArray(message.layouts))
                                return "layouts: array expected";
                            for (var i = 0; i < message.layouts.length; ++i) {
                                var error = $root.palexy.streaming.v1.Layout.verify(message.layouts[i]);
                                if (error)
                                    return "layouts." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListLayoutResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListLayoutResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListLayoutResponse} ListLayoutResponse
                     */
                    ListLayoutResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListLayoutResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListLayoutResponse();
                        if (object.layouts) {
                            if (!Array.isArray(object.layouts))
                                throw TypeError(".palexy.streaming.v1.ListLayoutResponse.layouts: array expected");
                            message.layouts = [];
                            for (var i = 0; i < object.layouts.length; ++i) {
                                if (typeof object.layouts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListLayoutResponse.layouts: object expected");
                                message.layouts[i] = $root.palexy.streaming.v1.Layout.fromObject(object.layouts[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListLayoutResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListLayoutResponse
                     * @static
                     * @param {palexy.streaming.v1.ListLayoutResponse} message ListLayoutResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListLayoutResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.layouts = [];
                        if (message.layouts && message.layouts.length) {
                            object.layouts = [];
                            for (var j = 0; j < message.layouts.length; ++j)
                                object.layouts[j] = $root.palexy.streaming.v1.Layout.toObject(message.layouts[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListLayoutResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListLayoutResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListLayoutResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListLayoutResponse;
                })();
    
                v1.EmailService = (function() {
    
                    /**
                     * Constructs a new EmailService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an EmailService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function EmailService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (EmailService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = EmailService;
    
                    /**
                     * Creates new EmailService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.EmailService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {EmailService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    EmailService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.EmailService#sendSimpleMessage}.
                     * @memberof palexy.streaming.v1.EmailService
                     * @typedef SendSimpleMessageCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.SendSimpleMessageResponse} [response] SendSimpleMessageResponse
                     */
    
                    /**
                     * Calls SendSimpleMessage.
                     * @function sendSimpleMessage
                     * @memberof palexy.streaming.v1.EmailService
                     * @instance
                     * @param {palexy.streaming.v1.ISendSimpleMessageRequest} request SendSimpleMessageRequest message or plain object
                     * @param {palexy.streaming.v1.EmailService.SendSimpleMessageCallback} callback Node-style callback called with the error, if any, and SendSimpleMessageResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(EmailService.prototype.sendSimpleMessage = function sendSimpleMessage(request, callback) {
                        return this.rpcCall(sendSimpleMessage, $root.palexy.streaming.v1.SendSimpleMessageRequest, $root.palexy.streaming.v1.SendSimpleMessageResponse, request, callback);
                    }, "name", { value: "SendSimpleMessage" });
    
                    /**
                     * Calls SendSimpleMessage.
                     * @function sendSimpleMessage
                     * @memberof palexy.streaming.v1.EmailService
                     * @instance
                     * @param {palexy.streaming.v1.ISendSimpleMessageRequest} request SendSimpleMessageRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.SendSimpleMessageResponse>} Promise
                     * @variation 2
                     */
    
                    return EmailService;
                })();
    
                v1.SendSimpleMessageRequest = (function() {
    
                    /**
                     * Properties of a SendSimpleMessageRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ISendSimpleMessageRequest
                     * @property {Array.<string>|null} [to] SendSimpleMessageRequest to
                     * @property {string|null} [subject] SendSimpleMessageRequest subject
                     * @property {string|null} [message] SendSimpleMessageRequest message
                     */
    
                    /**
                     * Constructs a new SendSimpleMessageRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SendSimpleMessageRequest.
                     * @implements ISendSimpleMessageRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ISendSimpleMessageRequest=} [properties] Properties to set
                     */
                    function SendSimpleMessageRequest(properties) {
                        this.to = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SendSimpleMessageRequest to.
                     * @member {Array.<string>} to
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @instance
                     */
                    SendSimpleMessageRequest.prototype.to = $util.emptyArray;
    
                    /**
                     * SendSimpleMessageRequest subject.
                     * @member {string} subject
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @instance
                     */
                    SendSimpleMessageRequest.prototype.subject = "";
    
                    /**
                     * SendSimpleMessageRequest message.
                     * @member {string} message
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @instance
                     */
                    SendSimpleMessageRequest.prototype.message = "";
    
                    /**
                     * Creates a new SendSimpleMessageRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendSimpleMessageRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SendSimpleMessageRequest} SendSimpleMessageRequest instance
                     */
                    SendSimpleMessageRequest.create = function create(properties) {
                        return new SendSimpleMessageRequest(properties);
                    };
    
                    /**
                     * Encodes the specified SendSimpleMessageRequest message. Does not implicitly {@link palexy.streaming.v1.SendSimpleMessageRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendSimpleMessageRequest} message SendSimpleMessageRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendSimpleMessageRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.to != null && message.to.length)
                            for (var i = 0; i < message.to.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.to[i]);
                        if (message.subject != null && Object.hasOwnProperty.call(message, "subject"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.subject);
                        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SendSimpleMessageRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.SendSimpleMessageRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendSimpleMessageRequest} message SendSimpleMessageRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendSimpleMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SendSimpleMessageRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SendSimpleMessageRequest} SendSimpleMessageRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendSimpleMessageRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SendSimpleMessageRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.to && message.to.length))
                                    message.to = [];
                                message.to.push(reader.string());
                                break;
                            case 2:
                                message.subject = reader.string();
                                break;
                            case 3:
                                message.message = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SendSimpleMessageRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SendSimpleMessageRequest} SendSimpleMessageRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendSimpleMessageRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SendSimpleMessageRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SendSimpleMessageRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.to != null && message.hasOwnProperty("to")) {
                            if (!Array.isArray(message.to))
                                return "to: array expected";
                            for (var i = 0; i < message.to.length; ++i)
                                if (!$util.isString(message.to[i]))
                                    return "to: string[] expected";
                        }
                        if (message.subject != null && message.hasOwnProperty("subject"))
                            if (!$util.isString(message.subject))
                                return "subject: string expected";
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a SendSimpleMessageRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SendSimpleMessageRequest} SendSimpleMessageRequest
                     */
                    SendSimpleMessageRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SendSimpleMessageRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.SendSimpleMessageRequest();
                        if (object.to) {
                            if (!Array.isArray(object.to))
                                throw TypeError(".palexy.streaming.v1.SendSimpleMessageRequest.to: array expected");
                            message.to = [];
                            for (var i = 0; i < object.to.length; ++i)
                                message.to[i] = String(object.to[i]);
                        }
                        if (object.subject != null)
                            message.subject = String(object.subject);
                        if (object.message != null)
                            message.message = String(object.message);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SendSimpleMessageRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @static
                     * @param {palexy.streaming.v1.SendSimpleMessageRequest} message SendSimpleMessageRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SendSimpleMessageRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.to = [];
                        if (options.defaults) {
                            object.subject = "";
                            object.message = "";
                        }
                        if (message.to && message.to.length) {
                            object.to = [];
                            for (var j = 0; j < message.to.length; ++j)
                                object.to[j] = message.to[j];
                        }
                        if (message.subject != null && message.hasOwnProperty("subject"))
                            object.subject = message.subject;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        return object;
                    };
    
                    /**
                     * Converts this SendSimpleMessageRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SendSimpleMessageRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SendSimpleMessageRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SendSimpleMessageRequest;
                })();
    
                v1.SendSimpleMessageResponse = (function() {
    
                    /**
                     * Properties of a SendSimpleMessageResponse.
                     * @memberof palexy.streaming.v1
                     * @interface ISendSimpleMessageResponse
                     * @property {number|null} [statusCode] SendSimpleMessageResponse statusCode
                     */
    
                    /**
                     * Constructs a new SendSimpleMessageResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SendSimpleMessageResponse.
                     * @implements ISendSimpleMessageResponse
                     * @constructor
                     * @param {palexy.streaming.v1.ISendSimpleMessageResponse=} [properties] Properties to set
                     */
                    function SendSimpleMessageResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SendSimpleMessageResponse statusCode.
                     * @member {number} statusCode
                     * @memberof palexy.streaming.v1.SendSimpleMessageResponse
                     * @instance
                     */
                    SendSimpleMessageResponse.prototype.statusCode = 0;
    
                    /**
                     * Creates a new SendSimpleMessageResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SendSimpleMessageResponse
                     * @static
                     * @param {palexy.streaming.v1.ISendSimpleMessageResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SendSimpleMessageResponse} SendSimpleMessageResponse instance
                     */
                    SendSimpleMessageResponse.create = function create(properties) {
                        return new SendSimpleMessageResponse(properties);
                    };
    
                    /**
                     * Encodes the specified SendSimpleMessageResponse message. Does not implicitly {@link palexy.streaming.v1.SendSimpleMessageResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SendSimpleMessageResponse
                     * @static
                     * @param {palexy.streaming.v1.ISendSimpleMessageResponse} message SendSimpleMessageResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendSimpleMessageResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.statusCode != null && Object.hasOwnProperty.call(message, "statusCode"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.statusCode);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SendSimpleMessageResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.SendSimpleMessageResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SendSimpleMessageResponse
                     * @static
                     * @param {palexy.streaming.v1.ISendSimpleMessageResponse} message SendSimpleMessageResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendSimpleMessageResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SendSimpleMessageResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SendSimpleMessageResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SendSimpleMessageResponse} SendSimpleMessageResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendSimpleMessageResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SendSimpleMessageResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.statusCode = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SendSimpleMessageResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SendSimpleMessageResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SendSimpleMessageResponse} SendSimpleMessageResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendSimpleMessageResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SendSimpleMessageResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SendSimpleMessageResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SendSimpleMessageResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.statusCode != null && message.hasOwnProperty("statusCode"))
                            if (!$util.isInteger(message.statusCode))
                                return "statusCode: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a SendSimpleMessageResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SendSimpleMessageResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SendSimpleMessageResponse} SendSimpleMessageResponse
                     */
                    SendSimpleMessageResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SendSimpleMessageResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.SendSimpleMessageResponse();
                        if (object.statusCode != null)
                            message.statusCode = object.statusCode | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SendSimpleMessageResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SendSimpleMessageResponse
                     * @static
                     * @param {palexy.streaming.v1.SendSimpleMessageResponse} message SendSimpleMessageResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SendSimpleMessageResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.statusCode = 0;
                        if (message.statusCode != null && message.hasOwnProperty("statusCode"))
                            object.statusCode = message.statusCode;
                        return object;
                    };
    
                    /**
                     * Converts this SendSimpleMessageResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SendSimpleMessageResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SendSimpleMessageResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SendSimpleMessageResponse;
                })();
    
                /**
                 * OrderType enum.
                 * @name palexy.streaming.v1.OrderType
                 * @enum {number}
                 * @property {number} ORDER_TYPE_UNKNOWN=0 ORDER_TYPE_UNKNOWN value
                 * @property {number} ASC=1 ASC value
                 * @property {number} DESC=2 DESC value
                 * @property {number} WEIGHTED=3 WEIGHTED value
                 */
                v1.OrderType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "ORDER_TYPE_UNKNOWN"] = 0;
                    values[valuesById[1] = "ASC"] = 1;
                    values[valuesById[2] = "DESC"] = 2;
                    values[valuesById[3] = "WEIGHTED"] = 3;
                    return values;
                })();
    
                v1.MergingStatisticService = (function() {
    
                    /**
                     * Constructs a new MergingStatisticService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MergingStatisticService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function MergingStatisticService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (MergingStatisticService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MergingStatisticService;
    
                    /**
                     * Creates new MergingStatisticService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.MergingStatisticService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {MergingStatisticService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    MergingStatisticService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.MergingStatisticService#addMergingStatistic}.
                     * @memberof palexy.streaming.v1.MergingStatisticService
                     * @typedef AddMergingStatisticCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.MergingStatisticResponse} [response] MergingStatisticResponse
                     */
    
                    /**
                     * Calls AddMergingStatistic.
                     * @function addMergingStatistic
                     * @memberof palexy.streaming.v1.MergingStatisticService
                     * @instance
                     * @param {palexy.streaming.v1.IMergingStatisticRequest} request MergingStatisticRequest message or plain object
                     * @param {palexy.streaming.v1.MergingStatisticService.AddMergingStatisticCallback} callback Node-style callback called with the error, if any, and MergingStatisticResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(MergingStatisticService.prototype.addMergingStatistic = function addMergingStatistic(request, callback) {
                        return this.rpcCall(addMergingStatistic, $root.palexy.streaming.v1.MergingStatisticRequest, $root.palexy.streaming.v1.MergingStatisticResponse, request, callback);
                    }, "name", { value: "AddMergingStatistic" });
    
                    /**
                     * Calls AddMergingStatistic.
                     * @function addMergingStatistic
                     * @memberof palexy.streaming.v1.MergingStatisticService
                     * @instance
                     * @param {palexy.streaming.v1.IMergingStatisticRequest} request MergingStatisticRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.MergingStatisticResponse>} Promise
                     * @variation 2
                     */
    
                    return MergingStatisticService;
                })();
    
                v1.InOutStatistic = (function() {
    
                    /**
                     * Properties of an InOutStatistic.
                     * @memberof palexy.streaming.v1
                     * @interface IInOutStatistic
                     * @property {string|null} [heuristicName] InOutStatistic heuristicName
                     * @property {number|null} [inNumTracklet] InOutStatistic inNumTracklet
                     * @property {number|null} [inNumBb] InOutStatistic inNumBb
                     * @property {number|null} [inBbPerTracklet] InOutStatistic inBbPerTracklet
                     * @property {number|null} [in_25bbPerTracklet] InOutStatistic in_25bbPerTracklet
                     * @property {number|null} [in_50bbPerTracklet] InOutStatistic in_50bbPerTracklet
                     * @property {number|null} [in_75bbPerTracklet] InOutStatistic in_75bbPerTracklet
                     * @property {number|null} [inTimePerTracklet] InOutStatistic inTimePerTracklet
                     * @property {number|null} [outNumTracklet] InOutStatistic outNumTracklet
                     * @property {number|null} [outNumBb] InOutStatistic outNumBb
                     * @property {number|null} [outBbPerTracklet] InOutStatistic outBbPerTracklet
                     * @property {number|null} [out_25bbPerTracklet] InOutStatistic out_25bbPerTracklet
                     * @property {number|null} [out_50bbPerTracklet] InOutStatistic out_50bbPerTracklet
                     * @property {number|null} [out_75bbPerTracklet] InOutStatistic out_75bbPerTracklet
                     * @property {number|null} [outTimePerTracklet] InOutStatistic outTimePerTracklet
                     * @property {number|null} [processingTime] InOutStatistic processingTime
                     */
    
                    /**
                     * Constructs a new InOutStatistic.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an InOutStatistic.
                     * @implements IInOutStatistic
                     * @constructor
                     * @param {palexy.streaming.v1.IInOutStatistic=} [properties] Properties to set
                     */
                    function InOutStatistic(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * InOutStatistic heuristicName.
                     * @member {string} heuristicName
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.heuristicName = "";
    
                    /**
                     * InOutStatistic inNumTracklet.
                     * @member {number} inNumTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.inNumTracklet = 0;
    
                    /**
                     * InOutStatistic inNumBb.
                     * @member {number} inNumBb
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.inNumBb = 0;
    
                    /**
                     * InOutStatistic inBbPerTracklet.
                     * @member {number} inBbPerTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.inBbPerTracklet = 0;
    
                    /**
                     * InOutStatistic in_25bbPerTracklet.
                     * @member {number} in_25bbPerTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.in_25bbPerTracklet = 0;
    
                    /**
                     * InOutStatistic in_50bbPerTracklet.
                     * @member {number} in_50bbPerTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.in_50bbPerTracklet = 0;
    
                    /**
                     * InOutStatistic in_75bbPerTracklet.
                     * @member {number} in_75bbPerTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.in_75bbPerTracklet = 0;
    
                    /**
                     * InOutStatistic inTimePerTracklet.
                     * @member {number} inTimePerTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.inTimePerTracklet = 0;
    
                    /**
                     * InOutStatistic outNumTracklet.
                     * @member {number} outNumTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.outNumTracklet = 0;
    
                    /**
                     * InOutStatistic outNumBb.
                     * @member {number} outNumBb
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.outNumBb = 0;
    
                    /**
                     * InOutStatistic outBbPerTracklet.
                     * @member {number} outBbPerTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.outBbPerTracklet = 0;
    
                    /**
                     * InOutStatistic out_25bbPerTracklet.
                     * @member {number} out_25bbPerTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.out_25bbPerTracklet = 0;
    
                    /**
                     * InOutStatistic out_50bbPerTracklet.
                     * @member {number} out_50bbPerTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.out_50bbPerTracklet = 0;
    
                    /**
                     * InOutStatistic out_75bbPerTracklet.
                     * @member {number} out_75bbPerTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.out_75bbPerTracklet = 0;
    
                    /**
                     * InOutStatistic outTimePerTracklet.
                     * @member {number} outTimePerTracklet
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.outTimePerTracklet = 0;
    
                    /**
                     * InOutStatistic processingTime.
                     * @member {number} processingTime
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     */
                    InOutStatistic.prototype.processingTime = 0;
    
                    /**
                     * Creates a new InOutStatistic instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @static
                     * @param {palexy.streaming.v1.IInOutStatistic=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.InOutStatistic} InOutStatistic instance
                     */
                    InOutStatistic.create = function create(properties) {
                        return new InOutStatistic(properties);
                    };
    
                    /**
                     * Encodes the specified InOutStatistic message. Does not implicitly {@link palexy.streaming.v1.InOutStatistic.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @static
                     * @param {palexy.streaming.v1.IInOutStatistic} message InOutStatistic message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InOutStatistic.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.heuristicName != null && Object.hasOwnProperty.call(message, "heuristicName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.heuristicName);
                        if (message.inNumTracklet != null && Object.hasOwnProperty.call(message, "inNumTracklet"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.inNumTracklet);
                        if (message.inNumBb != null && Object.hasOwnProperty.call(message, "inNumBb"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.inNumBb);
                        if (message.inBbPerTracklet != null && Object.hasOwnProperty.call(message, "inBbPerTracklet"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.inBbPerTracklet);
                        if (message.in_25bbPerTracklet != null && Object.hasOwnProperty.call(message, "in_25bbPerTracklet"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.in_25bbPerTracklet);
                        if (message.in_50bbPerTracklet != null && Object.hasOwnProperty.call(message, "in_50bbPerTracklet"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.in_50bbPerTracklet);
                        if (message.in_75bbPerTracklet != null && Object.hasOwnProperty.call(message, "in_75bbPerTracklet"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.in_75bbPerTracklet);
                        if (message.inTimePerTracklet != null && Object.hasOwnProperty.call(message, "inTimePerTracklet"))
                            writer.uint32(/* id 8, wireType 5 =*/69).float(message.inTimePerTracklet);
                        if (message.outNumTracklet != null && Object.hasOwnProperty.call(message, "outNumTracklet"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.outNumTracklet);
                        if (message.outNumBb != null && Object.hasOwnProperty.call(message, "outNumBb"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.outNumBb);
                        if (message.outBbPerTracklet != null && Object.hasOwnProperty.call(message, "outBbPerTracklet"))
                            writer.uint32(/* id 11, wireType 5 =*/93).float(message.outBbPerTracklet);
                        if (message.out_25bbPerTracklet != null && Object.hasOwnProperty.call(message, "out_25bbPerTracklet"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.out_25bbPerTracklet);
                        if (message.out_50bbPerTracklet != null && Object.hasOwnProperty.call(message, "out_50bbPerTracklet"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.out_50bbPerTracklet);
                        if (message.out_75bbPerTracklet != null && Object.hasOwnProperty.call(message, "out_75bbPerTracklet"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.out_75bbPerTracklet);
                        if (message.outTimePerTracklet != null && Object.hasOwnProperty.call(message, "outTimePerTracklet"))
                            writer.uint32(/* id 15, wireType 5 =*/125).float(message.outTimePerTracklet);
                        if (message.processingTime != null && Object.hasOwnProperty.call(message, "processingTime"))
                            writer.uint32(/* id 16, wireType 5 =*/133).float(message.processingTime);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified InOutStatistic message, length delimited. Does not implicitly {@link palexy.streaming.v1.InOutStatistic.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @static
                     * @param {palexy.streaming.v1.IInOutStatistic} message InOutStatistic message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InOutStatistic.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an InOutStatistic message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.InOutStatistic} InOutStatistic
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InOutStatistic.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.InOutStatistic();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.heuristicName = reader.string();
                                break;
                            case 2:
                                message.inNumTracklet = reader.int32();
                                break;
                            case 3:
                                message.inNumBb = reader.int32();
                                break;
                            case 4:
                                message.inBbPerTracklet = reader.float();
                                break;
                            case 5:
                                message.in_25bbPerTracklet = reader.int32();
                                break;
                            case 6:
                                message.in_50bbPerTracklet = reader.int32();
                                break;
                            case 7:
                                message.in_75bbPerTracklet = reader.int32();
                                break;
                            case 8:
                                message.inTimePerTracklet = reader.float();
                                break;
                            case 9:
                                message.outNumTracklet = reader.int32();
                                break;
                            case 10:
                                message.outNumBb = reader.int32();
                                break;
                            case 11:
                                message.outBbPerTracklet = reader.float();
                                break;
                            case 12:
                                message.out_25bbPerTracklet = reader.int32();
                                break;
                            case 13:
                                message.out_50bbPerTracklet = reader.int32();
                                break;
                            case 14:
                                message.out_75bbPerTracklet = reader.int32();
                                break;
                            case 15:
                                message.outTimePerTracklet = reader.float();
                                break;
                            case 16:
                                message.processingTime = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an InOutStatistic message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.InOutStatistic} InOutStatistic
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InOutStatistic.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an InOutStatistic message.
                     * @function verify
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    InOutStatistic.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.heuristicName != null && message.hasOwnProperty("heuristicName"))
                            if (!$util.isString(message.heuristicName))
                                return "heuristicName: string expected";
                        if (message.inNumTracklet != null && message.hasOwnProperty("inNumTracklet"))
                            if (!$util.isInteger(message.inNumTracklet))
                                return "inNumTracklet: integer expected";
                        if (message.inNumBb != null && message.hasOwnProperty("inNumBb"))
                            if (!$util.isInteger(message.inNumBb))
                                return "inNumBb: integer expected";
                        if (message.inBbPerTracklet != null && message.hasOwnProperty("inBbPerTracklet"))
                            if (typeof message.inBbPerTracklet !== "number")
                                return "inBbPerTracklet: number expected";
                        if (message.in_25bbPerTracklet != null && message.hasOwnProperty("in_25bbPerTracklet"))
                            if (!$util.isInteger(message.in_25bbPerTracklet))
                                return "in_25bbPerTracklet: integer expected";
                        if (message.in_50bbPerTracklet != null && message.hasOwnProperty("in_50bbPerTracklet"))
                            if (!$util.isInteger(message.in_50bbPerTracklet))
                                return "in_50bbPerTracklet: integer expected";
                        if (message.in_75bbPerTracklet != null && message.hasOwnProperty("in_75bbPerTracklet"))
                            if (!$util.isInteger(message.in_75bbPerTracklet))
                                return "in_75bbPerTracklet: integer expected";
                        if (message.inTimePerTracklet != null && message.hasOwnProperty("inTimePerTracklet"))
                            if (typeof message.inTimePerTracklet !== "number")
                                return "inTimePerTracklet: number expected";
                        if (message.outNumTracklet != null && message.hasOwnProperty("outNumTracklet"))
                            if (!$util.isInteger(message.outNumTracklet))
                                return "outNumTracklet: integer expected";
                        if (message.outNumBb != null && message.hasOwnProperty("outNumBb"))
                            if (!$util.isInteger(message.outNumBb))
                                return "outNumBb: integer expected";
                        if (message.outBbPerTracklet != null && message.hasOwnProperty("outBbPerTracklet"))
                            if (typeof message.outBbPerTracklet !== "number")
                                return "outBbPerTracklet: number expected";
                        if (message.out_25bbPerTracklet != null && message.hasOwnProperty("out_25bbPerTracklet"))
                            if (!$util.isInteger(message.out_25bbPerTracklet))
                                return "out_25bbPerTracklet: integer expected";
                        if (message.out_50bbPerTracklet != null && message.hasOwnProperty("out_50bbPerTracklet"))
                            if (!$util.isInteger(message.out_50bbPerTracklet))
                                return "out_50bbPerTracklet: integer expected";
                        if (message.out_75bbPerTracklet != null && message.hasOwnProperty("out_75bbPerTracklet"))
                            if (!$util.isInteger(message.out_75bbPerTracklet))
                                return "out_75bbPerTracklet: integer expected";
                        if (message.outTimePerTracklet != null && message.hasOwnProperty("outTimePerTracklet"))
                            if (typeof message.outTimePerTracklet !== "number")
                                return "outTimePerTracklet: number expected";
                        if (message.processingTime != null && message.hasOwnProperty("processingTime"))
                            if (typeof message.processingTime !== "number")
                                return "processingTime: number expected";
                        return null;
                    };
    
                    /**
                     * Creates an InOutStatistic message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.InOutStatistic} InOutStatistic
                     */
                    InOutStatistic.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.InOutStatistic)
                            return object;
                        var message = new $root.palexy.streaming.v1.InOutStatistic();
                        if (object.heuristicName != null)
                            message.heuristicName = String(object.heuristicName);
                        if (object.inNumTracklet != null)
                            message.inNumTracklet = object.inNumTracklet | 0;
                        if (object.inNumBb != null)
                            message.inNumBb = object.inNumBb | 0;
                        if (object.inBbPerTracklet != null)
                            message.inBbPerTracklet = Number(object.inBbPerTracklet);
                        if (object.in_25bbPerTracklet != null)
                            message.in_25bbPerTracklet = object.in_25bbPerTracklet | 0;
                        if (object.in_50bbPerTracklet != null)
                            message.in_50bbPerTracklet = object.in_50bbPerTracklet | 0;
                        if (object.in_75bbPerTracklet != null)
                            message.in_75bbPerTracklet = object.in_75bbPerTracklet | 0;
                        if (object.inTimePerTracklet != null)
                            message.inTimePerTracklet = Number(object.inTimePerTracklet);
                        if (object.outNumTracklet != null)
                            message.outNumTracklet = object.outNumTracklet | 0;
                        if (object.outNumBb != null)
                            message.outNumBb = object.outNumBb | 0;
                        if (object.outBbPerTracklet != null)
                            message.outBbPerTracklet = Number(object.outBbPerTracklet);
                        if (object.out_25bbPerTracklet != null)
                            message.out_25bbPerTracklet = object.out_25bbPerTracklet | 0;
                        if (object.out_50bbPerTracklet != null)
                            message.out_50bbPerTracklet = object.out_50bbPerTracklet | 0;
                        if (object.out_75bbPerTracklet != null)
                            message.out_75bbPerTracklet = object.out_75bbPerTracklet | 0;
                        if (object.outTimePerTracklet != null)
                            message.outTimePerTracklet = Number(object.outTimePerTracklet);
                        if (object.processingTime != null)
                            message.processingTime = Number(object.processingTime);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an InOutStatistic message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @static
                     * @param {palexy.streaming.v1.InOutStatistic} message InOutStatistic
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    InOutStatistic.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.heuristicName = "";
                            object.inNumTracklet = 0;
                            object.inNumBb = 0;
                            object.inBbPerTracklet = 0;
                            object.in_25bbPerTracklet = 0;
                            object.in_50bbPerTracklet = 0;
                            object.in_75bbPerTracklet = 0;
                            object.inTimePerTracklet = 0;
                            object.outNumTracklet = 0;
                            object.outNumBb = 0;
                            object.outBbPerTracklet = 0;
                            object.out_25bbPerTracklet = 0;
                            object.out_50bbPerTracklet = 0;
                            object.out_75bbPerTracklet = 0;
                            object.outTimePerTracklet = 0;
                            object.processingTime = 0;
                        }
                        if (message.heuristicName != null && message.hasOwnProperty("heuristicName"))
                            object.heuristicName = message.heuristicName;
                        if (message.inNumTracklet != null && message.hasOwnProperty("inNumTracklet"))
                            object.inNumTracklet = message.inNumTracklet;
                        if (message.inNumBb != null && message.hasOwnProperty("inNumBb"))
                            object.inNumBb = message.inNumBb;
                        if (message.inBbPerTracklet != null && message.hasOwnProperty("inBbPerTracklet"))
                            object.inBbPerTracklet = options.json && !isFinite(message.inBbPerTracklet) ? String(message.inBbPerTracklet) : message.inBbPerTracklet;
                        if (message.in_25bbPerTracklet != null && message.hasOwnProperty("in_25bbPerTracklet"))
                            object.in_25bbPerTracklet = message.in_25bbPerTracklet;
                        if (message.in_50bbPerTracklet != null && message.hasOwnProperty("in_50bbPerTracklet"))
                            object.in_50bbPerTracklet = message.in_50bbPerTracklet;
                        if (message.in_75bbPerTracklet != null && message.hasOwnProperty("in_75bbPerTracklet"))
                            object.in_75bbPerTracklet = message.in_75bbPerTracklet;
                        if (message.inTimePerTracklet != null && message.hasOwnProperty("inTimePerTracklet"))
                            object.inTimePerTracklet = options.json && !isFinite(message.inTimePerTracklet) ? String(message.inTimePerTracklet) : message.inTimePerTracklet;
                        if (message.outNumTracklet != null && message.hasOwnProperty("outNumTracklet"))
                            object.outNumTracklet = message.outNumTracklet;
                        if (message.outNumBb != null && message.hasOwnProperty("outNumBb"))
                            object.outNumBb = message.outNumBb;
                        if (message.outBbPerTracklet != null && message.hasOwnProperty("outBbPerTracklet"))
                            object.outBbPerTracklet = options.json && !isFinite(message.outBbPerTracklet) ? String(message.outBbPerTracklet) : message.outBbPerTracklet;
                        if (message.out_25bbPerTracklet != null && message.hasOwnProperty("out_25bbPerTracklet"))
                            object.out_25bbPerTracklet = message.out_25bbPerTracklet;
                        if (message.out_50bbPerTracklet != null && message.hasOwnProperty("out_50bbPerTracklet"))
                            object.out_50bbPerTracklet = message.out_50bbPerTracklet;
                        if (message.out_75bbPerTracklet != null && message.hasOwnProperty("out_75bbPerTracklet"))
                            object.out_75bbPerTracklet = message.out_75bbPerTracklet;
                        if (message.outTimePerTracklet != null && message.hasOwnProperty("outTimePerTracklet"))
                            object.outTimePerTracklet = options.json && !isFinite(message.outTimePerTracklet) ? String(message.outTimePerTracklet) : message.outTimePerTracklet;
                        if (message.processingTime != null && message.hasOwnProperty("processingTime"))
                            object.processingTime = options.json && !isFinite(message.processingTime) ? String(message.processingTime) : message.processingTime;
                        return object;
                    };
    
                    /**
                     * Converts this InOutStatistic to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.InOutStatistic
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    InOutStatistic.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return InOutStatistic;
                })();
    
                v1.MergingStatisticRequest = (function() {
    
                    /**
                     * Properties of a MergingStatisticRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IMergingStatisticRequest
                     * @property {number|null} [storeId] MergingStatisticRequest storeId
                     * @property {string|null} [dateId] MergingStatisticRequest dateId
                     * @property {string|null} [experimentName] MergingStatisticRequest experimentName
                     * @property {Array.<palexy.streaming.v1.IInOutStatistic>|null} [listInOutStatistic] MergingStatisticRequest listInOutStatistic
                     */
    
                    /**
                     * Constructs a new MergingStatisticRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MergingStatisticRequest.
                     * @implements IMergingStatisticRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IMergingStatisticRequest=} [properties] Properties to set
                     */
                    function MergingStatisticRequest(properties) {
                        this.listInOutStatistic = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MergingStatisticRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @instance
                     */
                    MergingStatisticRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MergingStatisticRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @instance
                     */
                    MergingStatisticRequest.prototype.dateId = "";
    
                    /**
                     * MergingStatisticRequest experimentName.
                     * @member {string} experimentName
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @instance
                     */
                    MergingStatisticRequest.prototype.experimentName = "";
    
                    /**
                     * MergingStatisticRequest listInOutStatistic.
                     * @member {Array.<palexy.streaming.v1.IInOutStatistic>} listInOutStatistic
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @instance
                     */
                    MergingStatisticRequest.prototype.listInOutStatistic = $util.emptyArray;
    
                    /**
                     * Creates a new MergingStatisticRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @static
                     * @param {palexy.streaming.v1.IMergingStatisticRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MergingStatisticRequest} MergingStatisticRequest instance
                     */
                    MergingStatisticRequest.create = function create(properties) {
                        return new MergingStatisticRequest(properties);
                    };
    
                    /**
                     * Encodes the specified MergingStatisticRequest message. Does not implicitly {@link palexy.streaming.v1.MergingStatisticRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @static
                     * @param {palexy.streaming.v1.IMergingStatisticRequest} message MergingStatisticRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergingStatisticRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        if (message.experimentName != null && Object.hasOwnProperty.call(message, "experimentName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.experimentName);
                        if (message.listInOutStatistic != null && message.listInOutStatistic.length)
                            for (var i = 0; i < message.listInOutStatistic.length; ++i)
                                $root.palexy.streaming.v1.InOutStatistic.encode(message.listInOutStatistic[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MergingStatisticRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.MergingStatisticRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @static
                     * @param {palexy.streaming.v1.IMergingStatisticRequest} message MergingStatisticRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergingStatisticRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MergingStatisticRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MergingStatisticRequest} MergingStatisticRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergingStatisticRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MergingStatisticRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            case 3:
                                message.experimentName = reader.string();
                                break;
                            case 4:
                                if (!(message.listInOutStatistic && message.listInOutStatistic.length))
                                    message.listInOutStatistic = [];
                                message.listInOutStatistic.push($root.palexy.streaming.v1.InOutStatistic.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MergingStatisticRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MergingStatisticRequest} MergingStatisticRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergingStatisticRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MergingStatisticRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MergingStatisticRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            if (!$util.isString(message.experimentName))
                                return "experimentName: string expected";
                        if (message.listInOutStatistic != null && message.hasOwnProperty("listInOutStatistic")) {
                            if (!Array.isArray(message.listInOutStatistic))
                                return "listInOutStatistic: array expected";
                            for (var i = 0; i < message.listInOutStatistic.length; ++i) {
                                var error = $root.palexy.streaming.v1.InOutStatistic.verify(message.listInOutStatistic[i]);
                                if (error)
                                    return "listInOutStatistic." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a MergingStatisticRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MergingStatisticRequest} MergingStatisticRequest
                     */
                    MergingStatisticRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MergingStatisticRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.MergingStatisticRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.experimentName != null)
                            message.experimentName = String(object.experimentName);
                        if (object.listInOutStatistic) {
                            if (!Array.isArray(object.listInOutStatistic))
                                throw TypeError(".palexy.streaming.v1.MergingStatisticRequest.listInOutStatistic: array expected");
                            message.listInOutStatistic = [];
                            for (var i = 0; i < object.listInOutStatistic.length; ++i) {
                                if (typeof object.listInOutStatistic[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.MergingStatisticRequest.listInOutStatistic: object expected");
                                message.listInOutStatistic[i] = $root.palexy.streaming.v1.InOutStatistic.fromObject(object.listInOutStatistic[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MergingStatisticRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @static
                     * @param {palexy.streaming.v1.MergingStatisticRequest} message MergingStatisticRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MergingStatisticRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.listInOutStatistic = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.experimentName = "";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            object.experimentName = message.experimentName;
                        if (message.listInOutStatistic && message.listInOutStatistic.length) {
                            object.listInOutStatistic = [];
                            for (var j = 0; j < message.listInOutStatistic.length; ++j)
                                object.listInOutStatistic[j] = $root.palexy.streaming.v1.InOutStatistic.toObject(message.listInOutStatistic[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this MergingStatisticRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MergingStatisticRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MergingStatisticRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MergingStatisticRequest;
                })();
    
                v1.MergingStatisticRow = (function() {
    
                    /**
                     * Properties of a MergingStatisticRow.
                     * @memberof palexy.streaming.v1
                     * @interface IMergingStatisticRow
                     * @property {number|null} [id] MergingStatisticRow id
                     * @property {number|null} [storeId] MergingStatisticRow storeId
                     * @property {string|null} [dateId] MergingStatisticRow dateId
                     * @property {string|null} [experimentName] MergingStatisticRow experimentName
                     * @property {string|null} [heuristicName] MergingStatisticRow heuristicName
                     * @property {google.protobuf.ITimestamp|null} [createdTime] MergingStatisticRow createdTime
                     * @property {number|null} [inNumTracklet] MergingStatisticRow inNumTracklet
                     * @property {number|null} [inNumBb] MergingStatisticRow inNumBb
                     * @property {number|null} [inBbPerTracklet] MergingStatisticRow inBbPerTracklet
                     * @property {number|null} [inTimePerTracklet] MergingStatisticRow inTimePerTracklet
                     * @property {number|null} [in25bbPerTracklet] MergingStatisticRow in25bbPerTracklet
                     * @property {number|null} [in50bbPerTracklet] MergingStatisticRow in50bbPerTracklet
                     * @property {number|null} [in75bbPerTracklet] MergingStatisticRow in75bbPerTracklet
                     * @property {number|null} [outNumTracklet] MergingStatisticRow outNumTracklet
                     * @property {number|null} [outNumBb] MergingStatisticRow outNumBb
                     * @property {number|null} [outBbPerTracklet] MergingStatisticRow outBbPerTracklet
                     * @property {number|null} [outTimePerTracklet] MergingStatisticRow outTimePerTracklet
                     * @property {number|null} [out25bbPerTracklet] MergingStatisticRow out25bbPerTracklet
                     * @property {number|null} [out50bbPerTracklet] MergingStatisticRow out50bbPerTracklet
                     * @property {number|null} [out75bbPerTracklet] MergingStatisticRow out75bbPerTracklet
                     * @property {number|null} [processingTime] MergingStatisticRow processingTime
                     */
    
                    /**
                     * Constructs a new MergingStatisticRow.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MergingStatisticRow.
                     * @implements IMergingStatisticRow
                     * @constructor
                     * @param {palexy.streaming.v1.IMergingStatisticRow=} [properties] Properties to set
                     */
                    function MergingStatisticRow(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MergingStatisticRow id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MergingStatisticRow storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * MergingStatisticRow dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.dateId = "";
    
                    /**
                     * MergingStatisticRow experimentName.
                     * @member {string} experimentName
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.experimentName = "";
    
                    /**
                     * MergingStatisticRow heuristicName.
                     * @member {string} heuristicName
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.heuristicName = "";
    
                    /**
                     * MergingStatisticRow createdTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createdTime
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.createdTime = null;
    
                    /**
                     * MergingStatisticRow inNumTracklet.
                     * @member {number} inNumTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.inNumTracklet = 0;
    
                    /**
                     * MergingStatisticRow inNumBb.
                     * @member {number} inNumBb
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.inNumBb = 0;
    
                    /**
                     * MergingStatisticRow inBbPerTracklet.
                     * @member {number} inBbPerTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.inBbPerTracklet = 0;
    
                    /**
                     * MergingStatisticRow inTimePerTracklet.
                     * @member {number} inTimePerTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.inTimePerTracklet = 0;
    
                    /**
                     * MergingStatisticRow in25bbPerTracklet.
                     * @member {number} in25bbPerTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.in25bbPerTracklet = 0;
    
                    /**
                     * MergingStatisticRow in50bbPerTracklet.
                     * @member {number} in50bbPerTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.in50bbPerTracklet = 0;
    
                    /**
                     * MergingStatisticRow in75bbPerTracklet.
                     * @member {number} in75bbPerTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.in75bbPerTracklet = 0;
    
                    /**
                     * MergingStatisticRow outNumTracklet.
                     * @member {number} outNumTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.outNumTracklet = 0;
    
                    /**
                     * MergingStatisticRow outNumBb.
                     * @member {number} outNumBb
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.outNumBb = 0;
    
                    /**
                     * MergingStatisticRow outBbPerTracklet.
                     * @member {number} outBbPerTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.outBbPerTracklet = 0;
    
                    /**
                     * MergingStatisticRow outTimePerTracklet.
                     * @member {number} outTimePerTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.outTimePerTracklet = 0;
    
                    /**
                     * MergingStatisticRow out25bbPerTracklet.
                     * @member {number} out25bbPerTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.out25bbPerTracklet = 0;
    
                    /**
                     * MergingStatisticRow out50bbPerTracklet.
                     * @member {number} out50bbPerTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.out50bbPerTracklet = 0;
    
                    /**
                     * MergingStatisticRow out75bbPerTracklet.
                     * @member {number} out75bbPerTracklet
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.out75bbPerTracklet = 0;
    
                    /**
                     * MergingStatisticRow processingTime.
                     * @member {number} processingTime
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     */
                    MergingStatisticRow.prototype.processingTime = 0;
    
                    /**
                     * Creates a new MergingStatisticRow instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @static
                     * @param {palexy.streaming.v1.IMergingStatisticRow=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MergingStatisticRow} MergingStatisticRow instance
                     */
                    MergingStatisticRow.create = function create(properties) {
                        return new MergingStatisticRow(properties);
                    };
    
                    /**
                     * Encodes the specified MergingStatisticRow message. Does not implicitly {@link palexy.streaming.v1.MergingStatisticRow.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @static
                     * @param {palexy.streaming.v1.IMergingStatisticRow} message MergingStatisticRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergingStatisticRow.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.dateId);
                        if (message.experimentName != null && Object.hasOwnProperty.call(message, "experimentName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.experimentName);
                        if (message.heuristicName != null && Object.hasOwnProperty.call(message, "heuristicName"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.heuristicName);
                        if (message.createdTime != null && Object.hasOwnProperty.call(message, "createdTime"))
                            $root.google.protobuf.Timestamp.encode(message.createdTime, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.inNumTracklet != null && Object.hasOwnProperty.call(message, "inNumTracklet"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.inNumTracklet);
                        if (message.inNumBb != null && Object.hasOwnProperty.call(message, "inNumBb"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.inNumBb);
                        if (message.inBbPerTracklet != null && Object.hasOwnProperty.call(message, "inBbPerTracklet"))
                            writer.uint32(/* id 9, wireType 5 =*/77).float(message.inBbPerTracklet);
                        if (message.inTimePerTracklet != null && Object.hasOwnProperty.call(message, "inTimePerTracklet"))
                            writer.uint32(/* id 10, wireType 5 =*/85).float(message.inTimePerTracklet);
                        if (message.in25bbPerTracklet != null && Object.hasOwnProperty.call(message, "in25bbPerTracklet"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.in25bbPerTracklet);
                        if (message.in50bbPerTracklet != null && Object.hasOwnProperty.call(message, "in50bbPerTracklet"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.in50bbPerTracklet);
                        if (message.in75bbPerTracklet != null && Object.hasOwnProperty.call(message, "in75bbPerTracklet"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.in75bbPerTracklet);
                        if (message.outNumTracklet != null && Object.hasOwnProperty.call(message, "outNumTracklet"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.outNumTracklet);
                        if (message.outNumBb != null && Object.hasOwnProperty.call(message, "outNumBb"))
                            writer.uint32(/* id 15, wireType 0 =*/120).int32(message.outNumBb);
                        if (message.outBbPerTracklet != null && Object.hasOwnProperty.call(message, "outBbPerTracklet"))
                            writer.uint32(/* id 16, wireType 5 =*/133).float(message.outBbPerTracklet);
                        if (message.outTimePerTracklet != null && Object.hasOwnProperty.call(message, "outTimePerTracklet"))
                            writer.uint32(/* id 17, wireType 5 =*/141).float(message.outTimePerTracklet);
                        if (message.out25bbPerTracklet != null && Object.hasOwnProperty.call(message, "out25bbPerTracklet"))
                            writer.uint32(/* id 18, wireType 0 =*/144).int32(message.out25bbPerTracklet);
                        if (message.out50bbPerTracklet != null && Object.hasOwnProperty.call(message, "out50bbPerTracklet"))
                            writer.uint32(/* id 19, wireType 0 =*/152).int32(message.out50bbPerTracklet);
                        if (message.out75bbPerTracklet != null && Object.hasOwnProperty.call(message, "out75bbPerTracklet"))
                            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.out75bbPerTracklet);
                        if (message.processingTime != null && Object.hasOwnProperty.call(message, "processingTime"))
                            writer.uint32(/* id 21, wireType 5 =*/173).float(message.processingTime);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MergingStatisticRow message, length delimited. Does not implicitly {@link palexy.streaming.v1.MergingStatisticRow.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @static
                     * @param {palexy.streaming.v1.IMergingStatisticRow} message MergingStatisticRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergingStatisticRow.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MergingStatisticRow message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MergingStatisticRow} MergingStatisticRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergingStatisticRow.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MergingStatisticRow();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.dateId = reader.string();
                                break;
                            case 4:
                                message.experimentName = reader.string();
                                break;
                            case 5:
                                message.heuristicName = reader.string();
                                break;
                            case 6:
                                message.createdTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.inNumTracklet = reader.int32();
                                break;
                            case 8:
                                message.inNumBb = reader.int32();
                                break;
                            case 9:
                                message.inBbPerTracklet = reader.float();
                                break;
                            case 10:
                                message.inTimePerTracklet = reader.float();
                                break;
                            case 11:
                                message.in25bbPerTracklet = reader.int32();
                                break;
                            case 12:
                                message.in50bbPerTracklet = reader.int32();
                                break;
                            case 13:
                                message.in75bbPerTracklet = reader.int32();
                                break;
                            case 14:
                                message.outNumTracklet = reader.int32();
                                break;
                            case 15:
                                message.outNumBb = reader.int32();
                                break;
                            case 16:
                                message.outBbPerTracklet = reader.float();
                                break;
                            case 17:
                                message.outTimePerTracklet = reader.float();
                                break;
                            case 18:
                                message.out25bbPerTracklet = reader.int32();
                                break;
                            case 19:
                                message.out50bbPerTracklet = reader.int32();
                                break;
                            case 20:
                                message.out75bbPerTracklet = reader.int32();
                                break;
                            case 21:
                                message.processingTime = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MergingStatisticRow message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MergingStatisticRow} MergingStatisticRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergingStatisticRow.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MergingStatisticRow message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MergingStatisticRow.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            if (!$util.isString(message.experimentName))
                                return "experimentName: string expected";
                        if (message.heuristicName != null && message.hasOwnProperty("heuristicName"))
                            if (!$util.isString(message.heuristicName))
                                return "heuristicName: string expected";
                        if (message.createdTime != null && message.hasOwnProperty("createdTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createdTime);
                            if (error)
                                return "createdTime." + error;
                        }
                        if (message.inNumTracklet != null && message.hasOwnProperty("inNumTracklet"))
                            if (!$util.isInteger(message.inNumTracklet))
                                return "inNumTracklet: integer expected";
                        if (message.inNumBb != null && message.hasOwnProperty("inNumBb"))
                            if (!$util.isInteger(message.inNumBb))
                                return "inNumBb: integer expected";
                        if (message.inBbPerTracklet != null && message.hasOwnProperty("inBbPerTracklet"))
                            if (typeof message.inBbPerTracklet !== "number")
                                return "inBbPerTracklet: number expected";
                        if (message.inTimePerTracklet != null && message.hasOwnProperty("inTimePerTracklet"))
                            if (typeof message.inTimePerTracklet !== "number")
                                return "inTimePerTracklet: number expected";
                        if (message.in25bbPerTracklet != null && message.hasOwnProperty("in25bbPerTracklet"))
                            if (!$util.isInteger(message.in25bbPerTracklet))
                                return "in25bbPerTracklet: integer expected";
                        if (message.in50bbPerTracklet != null && message.hasOwnProperty("in50bbPerTracklet"))
                            if (!$util.isInteger(message.in50bbPerTracklet))
                                return "in50bbPerTracklet: integer expected";
                        if (message.in75bbPerTracklet != null && message.hasOwnProperty("in75bbPerTracklet"))
                            if (!$util.isInteger(message.in75bbPerTracklet))
                                return "in75bbPerTracklet: integer expected";
                        if (message.outNumTracklet != null && message.hasOwnProperty("outNumTracklet"))
                            if (!$util.isInteger(message.outNumTracklet))
                                return "outNumTracklet: integer expected";
                        if (message.outNumBb != null && message.hasOwnProperty("outNumBb"))
                            if (!$util.isInteger(message.outNumBb))
                                return "outNumBb: integer expected";
                        if (message.outBbPerTracklet != null && message.hasOwnProperty("outBbPerTracklet"))
                            if (typeof message.outBbPerTracklet !== "number")
                                return "outBbPerTracklet: number expected";
                        if (message.outTimePerTracklet != null && message.hasOwnProperty("outTimePerTracklet"))
                            if (typeof message.outTimePerTracklet !== "number")
                                return "outTimePerTracklet: number expected";
                        if (message.out25bbPerTracklet != null && message.hasOwnProperty("out25bbPerTracklet"))
                            if (!$util.isInteger(message.out25bbPerTracklet))
                                return "out25bbPerTracklet: integer expected";
                        if (message.out50bbPerTracklet != null && message.hasOwnProperty("out50bbPerTracklet"))
                            if (!$util.isInteger(message.out50bbPerTracklet))
                                return "out50bbPerTracklet: integer expected";
                        if (message.out75bbPerTracklet != null && message.hasOwnProperty("out75bbPerTracklet"))
                            if (!$util.isInteger(message.out75bbPerTracklet))
                                return "out75bbPerTracklet: integer expected";
                        if (message.processingTime != null && message.hasOwnProperty("processingTime"))
                            if (typeof message.processingTime !== "number")
                                return "processingTime: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a MergingStatisticRow message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MergingStatisticRow} MergingStatisticRow
                     */
                    MergingStatisticRow.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MergingStatisticRow)
                            return object;
                        var message = new $root.palexy.streaming.v1.MergingStatisticRow();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.experimentName != null)
                            message.experimentName = String(object.experimentName);
                        if (object.heuristicName != null)
                            message.heuristicName = String(object.heuristicName);
                        if (object.createdTime != null) {
                            if (typeof object.createdTime !== "object")
                                throw TypeError(".palexy.streaming.v1.MergingStatisticRow.createdTime: object expected");
                            message.createdTime = $root.google.protobuf.Timestamp.fromObject(object.createdTime);
                        }
                        if (object.inNumTracklet != null)
                            message.inNumTracklet = object.inNumTracklet | 0;
                        if (object.inNumBb != null)
                            message.inNumBb = object.inNumBb | 0;
                        if (object.inBbPerTracklet != null)
                            message.inBbPerTracklet = Number(object.inBbPerTracklet);
                        if (object.inTimePerTracklet != null)
                            message.inTimePerTracklet = Number(object.inTimePerTracklet);
                        if (object.in25bbPerTracklet != null)
                            message.in25bbPerTracklet = object.in25bbPerTracklet | 0;
                        if (object.in50bbPerTracklet != null)
                            message.in50bbPerTracklet = object.in50bbPerTracklet | 0;
                        if (object.in75bbPerTracklet != null)
                            message.in75bbPerTracklet = object.in75bbPerTracklet | 0;
                        if (object.outNumTracklet != null)
                            message.outNumTracklet = object.outNumTracklet | 0;
                        if (object.outNumBb != null)
                            message.outNumBb = object.outNumBb | 0;
                        if (object.outBbPerTracklet != null)
                            message.outBbPerTracklet = Number(object.outBbPerTracklet);
                        if (object.outTimePerTracklet != null)
                            message.outTimePerTracklet = Number(object.outTimePerTracklet);
                        if (object.out25bbPerTracklet != null)
                            message.out25bbPerTracklet = object.out25bbPerTracklet | 0;
                        if (object.out50bbPerTracklet != null)
                            message.out50bbPerTracklet = object.out50bbPerTracklet | 0;
                        if (object.out75bbPerTracklet != null)
                            message.out75bbPerTracklet = object.out75bbPerTracklet | 0;
                        if (object.processingTime != null)
                            message.processingTime = Number(object.processingTime);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MergingStatisticRow message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @static
                     * @param {palexy.streaming.v1.MergingStatisticRow} message MergingStatisticRow
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MergingStatisticRow.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.experimentName = "";
                            object.heuristicName = "";
                            object.createdTime = null;
                            object.inNumTracklet = 0;
                            object.inNumBb = 0;
                            object.inBbPerTracklet = 0;
                            object.inTimePerTracklet = 0;
                            object.in25bbPerTracklet = 0;
                            object.in50bbPerTracklet = 0;
                            object.in75bbPerTracklet = 0;
                            object.outNumTracklet = 0;
                            object.outNumBb = 0;
                            object.outBbPerTracklet = 0;
                            object.outTimePerTracklet = 0;
                            object.out25bbPerTracklet = 0;
                            object.out50bbPerTracklet = 0;
                            object.out75bbPerTracklet = 0;
                            object.processingTime = 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.experimentName != null && message.hasOwnProperty("experimentName"))
                            object.experimentName = message.experimentName;
                        if (message.heuristicName != null && message.hasOwnProperty("heuristicName"))
                            object.heuristicName = message.heuristicName;
                        if (message.createdTime != null && message.hasOwnProperty("createdTime"))
                            object.createdTime = $root.google.protobuf.Timestamp.toObject(message.createdTime, options);
                        if (message.inNumTracklet != null && message.hasOwnProperty("inNumTracklet"))
                            object.inNumTracklet = message.inNumTracklet;
                        if (message.inNumBb != null && message.hasOwnProperty("inNumBb"))
                            object.inNumBb = message.inNumBb;
                        if (message.inBbPerTracklet != null && message.hasOwnProperty("inBbPerTracklet"))
                            object.inBbPerTracklet = options.json && !isFinite(message.inBbPerTracklet) ? String(message.inBbPerTracklet) : message.inBbPerTracklet;
                        if (message.inTimePerTracklet != null && message.hasOwnProperty("inTimePerTracklet"))
                            object.inTimePerTracklet = options.json && !isFinite(message.inTimePerTracklet) ? String(message.inTimePerTracklet) : message.inTimePerTracklet;
                        if (message.in25bbPerTracklet != null && message.hasOwnProperty("in25bbPerTracklet"))
                            object.in25bbPerTracklet = message.in25bbPerTracklet;
                        if (message.in50bbPerTracklet != null && message.hasOwnProperty("in50bbPerTracklet"))
                            object.in50bbPerTracklet = message.in50bbPerTracklet;
                        if (message.in75bbPerTracklet != null && message.hasOwnProperty("in75bbPerTracklet"))
                            object.in75bbPerTracklet = message.in75bbPerTracklet;
                        if (message.outNumTracklet != null && message.hasOwnProperty("outNumTracklet"))
                            object.outNumTracklet = message.outNumTracklet;
                        if (message.outNumBb != null && message.hasOwnProperty("outNumBb"))
                            object.outNumBb = message.outNumBb;
                        if (message.outBbPerTracklet != null && message.hasOwnProperty("outBbPerTracklet"))
                            object.outBbPerTracklet = options.json && !isFinite(message.outBbPerTracklet) ? String(message.outBbPerTracklet) : message.outBbPerTracklet;
                        if (message.outTimePerTracklet != null && message.hasOwnProperty("outTimePerTracklet"))
                            object.outTimePerTracklet = options.json && !isFinite(message.outTimePerTracklet) ? String(message.outTimePerTracklet) : message.outTimePerTracklet;
                        if (message.out25bbPerTracklet != null && message.hasOwnProperty("out25bbPerTracklet"))
                            object.out25bbPerTracklet = message.out25bbPerTracklet;
                        if (message.out50bbPerTracklet != null && message.hasOwnProperty("out50bbPerTracklet"))
                            object.out50bbPerTracklet = message.out50bbPerTracklet;
                        if (message.out75bbPerTracklet != null && message.hasOwnProperty("out75bbPerTracklet"))
                            object.out75bbPerTracklet = message.out75bbPerTracklet;
                        if (message.processingTime != null && message.hasOwnProperty("processingTime"))
                            object.processingTime = options.json && !isFinite(message.processingTime) ? String(message.processingTime) : message.processingTime;
                        return object;
                    };
    
                    /**
                     * Converts this MergingStatisticRow to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MergingStatisticRow
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MergingStatisticRow.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MergingStatisticRow;
                })();
    
                v1.MergingStatisticResponse = (function() {
    
                    /**
                     * Properties of a MergingStatisticResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IMergingStatisticResponse
                     * @property {Array.<palexy.streaming.v1.IMergingStatisticRow>|null} [rows] MergingStatisticResponse rows
                     */
    
                    /**
                     * Constructs a new MergingStatisticResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MergingStatisticResponse.
                     * @implements IMergingStatisticResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IMergingStatisticResponse=} [properties] Properties to set
                     */
                    function MergingStatisticResponse(properties) {
                        this.rows = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MergingStatisticResponse rows.
                     * @member {Array.<palexy.streaming.v1.IMergingStatisticRow>} rows
                     * @memberof palexy.streaming.v1.MergingStatisticResponse
                     * @instance
                     */
                    MergingStatisticResponse.prototype.rows = $util.emptyArray;
    
                    /**
                     * Creates a new MergingStatisticResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MergingStatisticResponse
                     * @static
                     * @param {palexy.streaming.v1.IMergingStatisticResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MergingStatisticResponse} MergingStatisticResponse instance
                     */
                    MergingStatisticResponse.create = function create(properties) {
                        return new MergingStatisticResponse(properties);
                    };
    
                    /**
                     * Encodes the specified MergingStatisticResponse message. Does not implicitly {@link palexy.streaming.v1.MergingStatisticResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MergingStatisticResponse
                     * @static
                     * @param {palexy.streaming.v1.IMergingStatisticResponse} message MergingStatisticResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergingStatisticResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rows != null && message.rows.length)
                            for (var i = 0; i < message.rows.length; ++i)
                                $root.palexy.streaming.v1.MergingStatisticRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MergingStatisticResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.MergingStatisticResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MergingStatisticResponse
                     * @static
                     * @param {palexy.streaming.v1.IMergingStatisticResponse} message MergingStatisticResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MergingStatisticResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MergingStatisticResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MergingStatisticResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MergingStatisticResponse} MergingStatisticResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergingStatisticResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MergingStatisticResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.rows && message.rows.length))
                                    message.rows = [];
                                message.rows.push($root.palexy.streaming.v1.MergingStatisticRow.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MergingStatisticResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MergingStatisticResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MergingStatisticResponse} MergingStatisticResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MergingStatisticResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MergingStatisticResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MergingStatisticResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MergingStatisticResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rows != null && message.hasOwnProperty("rows")) {
                            if (!Array.isArray(message.rows))
                                return "rows: array expected";
                            for (var i = 0; i < message.rows.length; ++i) {
                                var error = $root.palexy.streaming.v1.MergingStatisticRow.verify(message.rows[i]);
                                if (error)
                                    return "rows." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a MergingStatisticResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MergingStatisticResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MergingStatisticResponse} MergingStatisticResponse
                     */
                    MergingStatisticResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MergingStatisticResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.MergingStatisticResponse();
                        if (object.rows) {
                            if (!Array.isArray(object.rows))
                                throw TypeError(".palexy.streaming.v1.MergingStatisticResponse.rows: array expected");
                            message.rows = [];
                            for (var i = 0; i < object.rows.length; ++i) {
                                if (typeof object.rows[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.MergingStatisticResponse.rows: object expected");
                                message.rows[i] = $root.palexy.streaming.v1.MergingStatisticRow.fromObject(object.rows[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MergingStatisticResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MergingStatisticResponse
                     * @static
                     * @param {palexy.streaming.v1.MergingStatisticResponse} message MergingStatisticResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MergingStatisticResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.rows = [];
                        if (message.rows && message.rows.length) {
                            object.rows = [];
                            for (var j = 0; j < message.rows.length; ++j)
                                object.rows[j] = $root.palexy.streaming.v1.MergingStatisticRow.toObject(message.rows[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this MergingStatisticResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MergingStatisticResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MergingStatisticResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MergingStatisticResponse;
                })();
    
                /**
                 * AgeGroup enum.
                 * @name palexy.streaming.v1.AgeGroup
                 * @enum {number}
                 * @property {number} UNKNOWN_AGE_GROUP=0 UNKNOWN_AGE_GROUP value
                 * @property {number} G_LESS_THAN_12=1 G_LESS_THAN_12 value
                 * @property {number} G_12_20=2 G_12_20 value
                 * @property {number} G_20_30=3 G_20_30 value
                 * @property {number} G_30_40=4 G_30_40 value
                 * @property {number} G_GREATER_THAN_40=5 G_GREATER_THAN_40 value
                 */
                v1.AgeGroup = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_AGE_GROUP"] = 0;
                    values[valuesById[1] = "G_LESS_THAN_12"] = 1;
                    values[valuesById[2] = "G_12_20"] = 2;
                    values[valuesById[3] = "G_20_30"] = 3;
                    values[valuesById[4] = "G_30_40"] = 4;
                    values[valuesById[5] = "G_GREATER_THAN_40"] = 5;
                    return values;
                })();
    
                v1.StoreNotificationService = (function() {
    
                    /**
                     * Constructs a new StoreNotificationService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreNotificationService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StoreNotificationService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (StoreNotificationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StoreNotificationService;
    
                    /**
                     * Creates new StoreNotificationService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StoreNotificationService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StoreNotificationService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreNotificationService#createNotification}.
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @typedef CreateNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreNotification} [response] StoreNotification
                     */
    
                    /**
                     * Calls CreateNotification.
                     * @function createNotification
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IStoreNotification} request StoreNotification message or plain object
                     * @param {palexy.streaming.v1.StoreNotificationService.CreateNotificationCallback} callback Node-style callback called with the error, if any, and StoreNotification
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreNotificationService.prototype.createNotification = function createNotification(request, callback) {
                        return this.rpcCall(createNotification, $root.palexy.streaming.v1.StoreNotification, $root.palexy.streaming.v1.StoreNotification, request, callback);
                    }, "name", { value: "CreateNotification" });
    
                    /**
                     * Calls CreateNotification.
                     * @function createNotification
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IStoreNotification} request StoreNotification message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreNotification>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreNotificationService#listNotification}.
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @typedef ListNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStoreNotificationResponse} [response] ListStoreNotificationResponse
                     */
    
                    /**
                     * Calls ListNotification.
                     * @function listNotification
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreNotificationRequest} request ListStoreNotificationRequest message or plain object
                     * @param {palexy.streaming.v1.StoreNotificationService.ListNotificationCallback} callback Node-style callback called with the error, if any, and ListStoreNotificationResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreNotificationService.prototype.listNotification = function listNotification(request, callback) {
                        return this.rpcCall(listNotification, $root.palexy.streaming.v1.ListStoreNotificationRequest, $root.palexy.streaming.v1.ListStoreNotificationResponse, request, callback);
                    }, "name", { value: "ListNotification" });
    
                    /**
                     * Calls ListNotification.
                     * @function listNotification
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreNotificationRequest} request ListStoreNotificationRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStoreNotificationResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreNotificationService#getNotification}.
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @typedef GetNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreNotification} [response] StoreNotification
                     */
    
                    /**
                     * Calls GetNotification.
                     * @function getNotification
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoreNotificationRequest} request GetStoreNotificationRequest message or plain object
                     * @param {palexy.streaming.v1.StoreNotificationService.GetNotificationCallback} callback Node-style callback called with the error, if any, and StoreNotification
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreNotificationService.prototype.getNotification = function getNotification(request, callback) {
                        return this.rpcCall(getNotification, $root.palexy.streaming.v1.GetStoreNotificationRequest, $root.palexy.streaming.v1.StoreNotification, request, callback);
                    }, "name", { value: "GetNotification" });
    
                    /**
                     * Calls GetNotification.
                     * @function getNotification
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoreNotificationRequest} request GetStoreNotificationRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreNotification>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreNotificationService#updateNotification}.
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @typedef UpdateNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreNotification} [response] StoreNotification
                     */
    
                    /**
                     * Calls UpdateNotification.
                     * @function updateNotification
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStoreNotificationRequest} request UpdateStoreNotificationRequest message or plain object
                     * @param {palexy.streaming.v1.StoreNotificationService.UpdateNotificationCallback} callback Node-style callback called with the error, if any, and StoreNotification
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreNotificationService.prototype.updateNotification = function updateNotification(request, callback) {
                        return this.rpcCall(updateNotification, $root.palexy.streaming.v1.UpdateStoreNotificationRequest, $root.palexy.streaming.v1.StoreNotification, request, callback);
                    }, "name", { value: "UpdateNotification" });
    
                    /**
                     * Calls UpdateNotification.
                     * @function updateNotification
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStoreNotificationRequest} request UpdateStoreNotificationRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreNotification>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreNotificationService#resolveNotification}.
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @typedef ResolveNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreNotification} [response] StoreNotification
                     */
    
                    /**
                     * Calls ResolveNotification.
                     * @function resolveNotification
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IResolveStoreNotificationRequest} request ResolveStoreNotificationRequest message or plain object
                     * @param {palexy.streaming.v1.StoreNotificationService.ResolveNotificationCallback} callback Node-style callback called with the error, if any, and StoreNotification
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreNotificationService.prototype.resolveNotification = function resolveNotification(request, callback) {
                        return this.rpcCall(resolveNotification, $root.palexy.streaming.v1.ResolveStoreNotificationRequest, $root.palexy.streaming.v1.StoreNotification, request, callback);
                    }, "name", { value: "ResolveNotification" });
    
                    /**
                     * Calls ResolveNotification.
                     * @function resolveNotification
                     * @memberof palexy.streaming.v1.StoreNotificationService
                     * @instance
                     * @param {palexy.streaming.v1.IResolveStoreNotificationRequest} request ResolveStoreNotificationRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreNotification>} Promise
                     * @variation 2
                     */
    
                    return StoreNotificationService;
                })();
    
                v1.Account = (function() {
    
                    /**
                     * Properties of an Account.
                     * @memberof palexy.streaming.v1
                     * @interface IAccount
                     * @property {number|null} [id] Account id
                     * @property {string|null} [username] Account username
                     * @property {string|null} [email] Account email
                     * @property {string|null} [fullName] Account fullName
                     */
    
                    /**
                     * Constructs a new Account.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an Account.
                     * @implements IAccount
                     * @constructor
                     * @param {palexy.streaming.v1.IAccount=} [properties] Properties to set
                     */
                    function Account(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Account id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.Account
                     * @instance
                     */
                    Account.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Account username.
                     * @member {string} username
                     * @memberof palexy.streaming.v1.Account
                     * @instance
                     */
                    Account.prototype.username = "";
    
                    /**
                     * Account email.
                     * @member {string} email
                     * @memberof palexy.streaming.v1.Account
                     * @instance
                     */
                    Account.prototype.email = "";
    
                    /**
                     * Account fullName.
                     * @member {string} fullName
                     * @memberof palexy.streaming.v1.Account
                     * @instance
                     */
                    Account.prototype.fullName = "";
    
                    /**
                     * Creates a new Account instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Account
                     * @static
                     * @param {palexy.streaming.v1.IAccount=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Account} Account instance
                     */
                    Account.create = function create(properties) {
                        return new Account(properties);
                    };
    
                    /**
                     * Encodes the specified Account message. Does not implicitly {@link palexy.streaming.v1.Account.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Account
                     * @static
                     * @param {palexy.streaming.v1.IAccount} message Account message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Account.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.username);
                        if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
                        if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.fullName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Account message, length delimited. Does not implicitly {@link palexy.streaming.v1.Account.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Account
                     * @static
                     * @param {palexy.streaming.v1.IAccount} message Account message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Account.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Account message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Account
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Account} Account
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Account.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Account();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.username = reader.string();
                                break;
                            case 3:
                                message.email = reader.string();
                                break;
                            case 4:
                                message.fullName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Account message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Account
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Account} Account
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Account.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Account message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Account
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Account.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.username != null && message.hasOwnProperty("username"))
                            if (!$util.isString(message.username))
                                return "username: string expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.fullName != null && message.hasOwnProperty("fullName"))
                            if (!$util.isString(message.fullName))
                                return "fullName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an Account message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Account
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Account} Account
                     */
                    Account.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Account)
                            return object;
                        var message = new $root.palexy.streaming.v1.Account();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.username != null)
                            message.username = String(object.username);
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.fullName != null)
                            message.fullName = String(object.fullName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an Account message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Account
                     * @static
                     * @param {palexy.streaming.v1.Account} message Account
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Account.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.username = "";
                            object.email = "";
                            object.fullName = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.username != null && message.hasOwnProperty("username"))
                            object.username = message.username;
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.fullName != null && message.hasOwnProperty("fullName"))
                            object.fullName = message.fullName;
                        return object;
                    };
    
                    /**
                     * Converts this Account to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Account
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Account.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Account;
                })();
    
                v1.StoreNotification = (function() {
    
                    /**
                     * Properties of a StoreNotification.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreNotification
                     * @property {number|null} [id] StoreNotification id
                     * @property {number|null} [companyId] StoreNotification companyId
                     * @property {google.protobuf.IInt64Value|null} [storeId] StoreNotification storeId
                     * @property {palexy.streaming.v1.StoreNotification.Type|null} [type] StoreNotification type
                     * @property {string|null} [message] StoreNotification message
                     * @property {palexy.streaming.v1.StoreNotification.Level|null} [level] StoreNotification level
                     * @property {palexy.streaming.v1.StoreNotification.State|null} [state] StoreNotification state
                     * @property {number|null} [startTimeMs] StoreNotification startTimeMs
                     * @property {number|null} [endTimeMs] StoreNotification endTimeMs
                     * @property {number|null} [createTimeMs] StoreNotification createTimeMs
                     * @property {number|null} [updateTimeMs] StoreNotification updateTimeMs
                     * @property {number|null} [resolveTimeMs] StoreNotification resolveTimeMs
                     * @property {number|null} [creatorId] StoreNotification creatorId
                     * @property {number|null} [resolverId] StoreNotification resolverId
                     * @property {string|null} [timezone] StoreNotification timezone
                     * @property {palexy.streaming.v1.ICompany|null} [company] StoreNotification company
                     * @property {palexy.streaming.v1.IStore|null} [store] StoreNotification store
                     * @property {palexy.streaming.v1.IAccount|null} [creator] StoreNotification creator
                     * @property {palexy.streaming.v1.IAccount|null} [resolver] StoreNotification resolver
                     */
    
                    /**
                     * Constructs a new StoreNotification.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreNotification.
                     * @implements IStoreNotification
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreNotification=} [properties] Properties to set
                     */
                    function StoreNotification(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreNotification id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreNotification companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreNotification storeId.
                     * @member {google.protobuf.IInt64Value|null|undefined} storeId
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.storeId = null;
    
                    /**
                     * StoreNotification type.
                     * @member {palexy.streaming.v1.StoreNotification.Type} type
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.type = 0;
    
                    /**
                     * StoreNotification message.
                     * @member {string} message
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.message = "";
    
                    /**
                     * StoreNotification level.
                     * @member {palexy.streaming.v1.StoreNotification.Level} level
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.level = 0;
    
                    /**
                     * StoreNotification state.
                     * @member {palexy.streaming.v1.StoreNotification.State} state
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.state = 0;
    
                    /**
                     * StoreNotification startTimeMs.
                     * @member {number} startTimeMs
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreNotification endTimeMs.
                     * @member {number} endTimeMs
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreNotification createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreNotification updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreNotification resolveTimeMs.
                     * @member {number} resolveTimeMs
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.resolveTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreNotification creatorId.
                     * @member {number} creatorId
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.creatorId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreNotification resolverId.
                     * @member {number} resolverId
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.resolverId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreNotification timezone.
                     * @member {string} timezone
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.timezone = "";
    
                    /**
                     * StoreNotification company.
                     * @member {palexy.streaming.v1.ICompany|null|undefined} company
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.company = null;
    
                    /**
                     * StoreNotification store.
                     * @member {palexy.streaming.v1.IStore|null|undefined} store
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.store = null;
    
                    /**
                     * StoreNotification creator.
                     * @member {palexy.streaming.v1.IAccount|null|undefined} creator
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.creator = null;
    
                    /**
                     * StoreNotification resolver.
                     * @member {palexy.streaming.v1.IAccount|null|undefined} resolver
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     */
                    StoreNotification.prototype.resolver = null;
    
                    /**
                     * Creates a new StoreNotification instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @static
                     * @param {palexy.streaming.v1.IStoreNotification=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreNotification} StoreNotification instance
                     */
                    StoreNotification.create = function create(properties) {
                        return new StoreNotification(properties);
                    };
    
                    /**
                     * Encodes the specified StoreNotification message. Does not implicitly {@link palexy.streaming.v1.StoreNotification.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @static
                     * @param {palexy.streaming.v1.IStoreNotification} message StoreNotification message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreNotification.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            $root.google.protobuf.Int64Value.encode(message.storeId, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.message);
                        if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.level);
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.state);
                        if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.startTimeMs);
                        if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int64(message.endTimeMs);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.updateTimeMs);
                        if (message.resolveTimeMs != null && Object.hasOwnProperty.call(message, "resolveTimeMs"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int64(message.resolveTimeMs);
                        if (message.creatorId != null && Object.hasOwnProperty.call(message, "creatorId"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int64(message.creatorId);
                        if (message.resolverId != null && Object.hasOwnProperty.call(message, "resolverId"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int64(message.resolverId);
                        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.timezone);
                        if (message.company != null && Object.hasOwnProperty.call(message, "company"))
                            $root.palexy.streaming.v1.Company.encode(message.company, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.store != null && Object.hasOwnProperty.call(message, "store"))
                            $root.palexy.streaming.v1.Store.encode(message.store, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        if (message.creator != null && Object.hasOwnProperty.call(message, "creator"))
                            $root.palexy.streaming.v1.Account.encode(message.creator, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                        if (message.resolver != null && Object.hasOwnProperty.call(message, "resolver"))
                            $root.palexy.streaming.v1.Account.encode(message.resolver, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreNotification message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreNotification.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @static
                     * @param {palexy.streaming.v1.IStoreNotification} message StoreNotification message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreNotification.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreNotification message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreNotification} StoreNotification
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreNotification.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreNotification();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.storeId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.type = reader.int32();
                                break;
                            case 5:
                                message.message = reader.string();
                                break;
                            case 6:
                                message.level = reader.int32();
                                break;
                            case 7:
                                message.state = reader.int32();
                                break;
                            case 8:
                                message.startTimeMs = reader.int64();
                                break;
                            case 9:
                                message.endTimeMs = reader.int64();
                                break;
                            case 10:
                                message.createTimeMs = reader.int64();
                                break;
                            case 11:
                                message.updateTimeMs = reader.int64();
                                break;
                            case 12:
                                message.resolveTimeMs = reader.int64();
                                break;
                            case 13:
                                message.creatorId = reader.int64();
                                break;
                            case 14:
                                message.resolverId = reader.int64();
                                break;
                            case 15:
                                message.timezone = reader.string();
                                break;
                            case 16:
                                message.company = $root.palexy.streaming.v1.Company.decode(reader, reader.uint32());
                                break;
                            case 17:
                                message.store = $root.palexy.streaming.v1.Store.decode(reader, reader.uint32());
                                break;
                            case 18:
                                message.creator = $root.palexy.streaming.v1.Account.decode(reader, reader.uint32());
                                break;
                            case 19:
                                message.resolver = $root.palexy.streaming.v1.Account.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreNotification message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreNotification} StoreNotification
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreNotification.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreNotification message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreNotification.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.storeId);
                            if (error)
                                return "storeId." + error;
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        if (message.level != null && message.hasOwnProperty("level"))
                            switch (message.level) {
                            default:
                                return "level: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                            if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                                return "startTimeMs: integer|Long expected";
                        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                            if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                                return "endTimeMs: integer|Long expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        if (message.resolveTimeMs != null && message.hasOwnProperty("resolveTimeMs"))
                            if (!$util.isInteger(message.resolveTimeMs) && !(message.resolveTimeMs && $util.isInteger(message.resolveTimeMs.low) && $util.isInteger(message.resolveTimeMs.high)))
                                return "resolveTimeMs: integer|Long expected";
                        if (message.creatorId != null && message.hasOwnProperty("creatorId"))
                            if (!$util.isInteger(message.creatorId) && !(message.creatorId && $util.isInteger(message.creatorId.low) && $util.isInteger(message.creatorId.high)))
                                return "creatorId: integer|Long expected";
                        if (message.resolverId != null && message.hasOwnProperty("resolverId"))
                            if (!$util.isInteger(message.resolverId) && !(message.resolverId && $util.isInteger(message.resolverId.low) && $util.isInteger(message.resolverId.high)))
                                return "resolverId: integer|Long expected";
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            if (!$util.isString(message.timezone))
                                return "timezone: string expected";
                        if (message.company != null && message.hasOwnProperty("company")) {
                            var error = $root.palexy.streaming.v1.Company.verify(message.company);
                            if (error)
                                return "company." + error;
                        }
                        if (message.store != null && message.hasOwnProperty("store")) {
                            var error = $root.palexy.streaming.v1.Store.verify(message.store);
                            if (error)
                                return "store." + error;
                        }
                        if (message.creator != null && message.hasOwnProperty("creator")) {
                            var error = $root.palexy.streaming.v1.Account.verify(message.creator);
                            if (error)
                                return "creator." + error;
                        }
                        if (message.resolver != null && message.hasOwnProperty("resolver")) {
                            var error = $root.palexy.streaming.v1.Account.verify(message.resolver);
                            if (error)
                                return "resolver." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a StoreNotification message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreNotification} StoreNotification
                     */
                    StoreNotification.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreNotification)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreNotification();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeId != null) {
                            if (typeof object.storeId !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreNotification.storeId: object expected");
                            message.storeId = $root.google.protobuf.Int64Value.fromObject(object.storeId);
                        }
                        switch (object.type) {
                        case "UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "CAMERA_BROKEN":
                        case 1:
                            message.type = 1;
                            break;
                        case "MISSING_DATA_RECOVER_IN_PROGRESS":
                        case 2:
                            message.type = 2;
                            break;
                        case "CUSTOM":
                        case 3:
                            message.type = 3;
                            break;
                        case "CAMERA_SHIFT":
                        case 4:
                            message.type = 4;
                            break;
                        }
                        if (object.message != null)
                            message.message = String(object.message);
                        switch (object.level) {
                        case "LEVEL_UNKNOWN":
                        case 0:
                            message.level = 0;
                            break;
                        case "INFO":
                        case 1:
                            message.level = 1;
                            break;
                        case "WARN":
                        case 2:
                            message.level = 2;
                            break;
                        case "ERROR":
                        case 3:
                            message.level = 3;
                            break;
                        }
                        switch (object.state) {
                        case "STATE_UNKNOWN":
                        case 0:
                            message.state = 0;
                            break;
                        case "ACTIVE":
                        case 1:
                            message.state = 1;
                            break;
                        case "RESOLVED":
                        case 2:
                            message.state = 2;
                            break;
                        case "CANCELLED":
                        case 3:
                            message.state = 3;
                            break;
                        case "CANNOT_RESOLVE":
                        case 4:
                            message.state = 4;
                            break;
                        }
                        if (object.startTimeMs != null)
                            if ($util.Long)
                                (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                            else if (typeof object.startTimeMs === "string")
                                message.startTimeMs = parseInt(object.startTimeMs, 10);
                            else if (typeof object.startTimeMs === "number")
                                message.startTimeMs = object.startTimeMs;
                            else if (typeof object.startTimeMs === "object")
                                message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
                        if (object.endTimeMs != null)
                            if ($util.Long)
                                (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                            else if (typeof object.endTimeMs === "string")
                                message.endTimeMs = parseInt(object.endTimeMs, 10);
                            else if (typeof object.endTimeMs === "number")
                                message.endTimeMs = object.endTimeMs;
                            else if (typeof object.endTimeMs === "object")
                                message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        if (object.resolveTimeMs != null)
                            if ($util.Long)
                                (message.resolveTimeMs = $util.Long.fromValue(object.resolveTimeMs)).unsigned = false;
                            else if (typeof object.resolveTimeMs === "string")
                                message.resolveTimeMs = parseInt(object.resolveTimeMs, 10);
                            else if (typeof object.resolveTimeMs === "number")
                                message.resolveTimeMs = object.resolveTimeMs;
                            else if (typeof object.resolveTimeMs === "object")
                                message.resolveTimeMs = new $util.LongBits(object.resolveTimeMs.low >>> 0, object.resolveTimeMs.high >>> 0).toNumber();
                        if (object.creatorId != null)
                            if ($util.Long)
                                (message.creatorId = $util.Long.fromValue(object.creatorId)).unsigned = false;
                            else if (typeof object.creatorId === "string")
                                message.creatorId = parseInt(object.creatorId, 10);
                            else if (typeof object.creatorId === "number")
                                message.creatorId = object.creatorId;
                            else if (typeof object.creatorId === "object")
                                message.creatorId = new $util.LongBits(object.creatorId.low >>> 0, object.creatorId.high >>> 0).toNumber();
                        if (object.resolverId != null)
                            if ($util.Long)
                                (message.resolverId = $util.Long.fromValue(object.resolverId)).unsigned = false;
                            else if (typeof object.resolverId === "string")
                                message.resolverId = parseInt(object.resolverId, 10);
                            else if (typeof object.resolverId === "number")
                                message.resolverId = object.resolverId;
                            else if (typeof object.resolverId === "object")
                                message.resolverId = new $util.LongBits(object.resolverId.low >>> 0, object.resolverId.high >>> 0).toNumber();
                        if (object.timezone != null)
                            message.timezone = String(object.timezone);
                        if (object.company != null) {
                            if (typeof object.company !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreNotification.company: object expected");
                            message.company = $root.palexy.streaming.v1.Company.fromObject(object.company);
                        }
                        if (object.store != null) {
                            if (typeof object.store !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreNotification.store: object expected");
                            message.store = $root.palexy.streaming.v1.Store.fromObject(object.store);
                        }
                        if (object.creator != null) {
                            if (typeof object.creator !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreNotification.creator: object expected");
                            message.creator = $root.palexy.streaming.v1.Account.fromObject(object.creator);
                        }
                        if (object.resolver != null) {
                            if (typeof object.resolver !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreNotification.resolver: object expected");
                            message.resolver = $root.palexy.streaming.v1.Account.fromObject(object.resolver);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreNotification message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @static
                     * @param {palexy.streaming.v1.StoreNotification} message StoreNotification
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreNotification.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.storeId = null;
                            object.type = options.enums === String ? "UNKNOWN" : 0;
                            object.message = "";
                            object.level = options.enums === String ? "LEVEL_UNKNOWN" : 0;
                            object.state = options.enums === String ? "STATE_UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.startTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.endTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.resolveTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.resolveTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.creatorId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.creatorId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.resolverId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.resolverId = options.longs === String ? "0" : 0;
                            object.timezone = "";
                            object.company = null;
                            object.store = null;
                            object.creator = null;
                            object.resolver = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = $root.google.protobuf.Int64Value.toObject(message.storeId, options);
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.StoreNotification.Type[message.type] : message.type;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        if (message.level != null && message.hasOwnProperty("level"))
                            object.level = options.enums === String ? $root.palexy.streaming.v1.StoreNotification.Level[message.level] : message.level;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.streaming.v1.StoreNotification.State[message.state] : message.state;
                        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                            if (typeof message.startTimeMs === "number")
                                object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                            else
                                object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                            if (typeof message.endTimeMs === "number")
                                object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                            else
                                object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        if (message.resolveTimeMs != null && message.hasOwnProperty("resolveTimeMs"))
                            if (typeof message.resolveTimeMs === "number")
                                object.resolveTimeMs = options.longs === String ? String(message.resolveTimeMs) : message.resolveTimeMs;
                            else
                                object.resolveTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.resolveTimeMs) : options.longs === Number ? new $util.LongBits(message.resolveTimeMs.low >>> 0, message.resolveTimeMs.high >>> 0).toNumber() : message.resolveTimeMs;
                        if (message.creatorId != null && message.hasOwnProperty("creatorId"))
                            if (typeof message.creatorId === "number")
                                object.creatorId = options.longs === String ? String(message.creatorId) : message.creatorId;
                            else
                                object.creatorId = options.longs === String ? $util.Long.prototype.toString.call(message.creatorId) : options.longs === Number ? new $util.LongBits(message.creatorId.low >>> 0, message.creatorId.high >>> 0).toNumber() : message.creatorId;
                        if (message.resolverId != null && message.hasOwnProperty("resolverId"))
                            if (typeof message.resolverId === "number")
                                object.resolverId = options.longs === String ? String(message.resolverId) : message.resolverId;
                            else
                                object.resolverId = options.longs === String ? $util.Long.prototype.toString.call(message.resolverId) : options.longs === Number ? new $util.LongBits(message.resolverId.low >>> 0, message.resolverId.high >>> 0).toNumber() : message.resolverId;
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            object.timezone = message.timezone;
                        if (message.company != null && message.hasOwnProperty("company"))
                            object.company = $root.palexy.streaming.v1.Company.toObject(message.company, options);
                        if (message.store != null && message.hasOwnProperty("store"))
                            object.store = $root.palexy.streaming.v1.Store.toObject(message.store, options);
                        if (message.creator != null && message.hasOwnProperty("creator"))
                            object.creator = $root.palexy.streaming.v1.Account.toObject(message.creator, options);
                        if (message.resolver != null && message.hasOwnProperty("resolver"))
                            object.resolver = $root.palexy.streaming.v1.Account.toObject(message.resolver, options);
                        return object;
                    };
    
                    /**
                     * Converts this StoreNotification to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreNotification
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreNotification.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Type enum.
                     * @name palexy.streaming.v1.StoreNotification.Type
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} CAMERA_BROKEN=1 CAMERA_BROKEN value
                     * @property {number} MISSING_DATA_RECOVER_IN_PROGRESS=2 MISSING_DATA_RECOVER_IN_PROGRESS value
                     * @property {number} CUSTOM=3 CUSTOM value
                     * @property {number} CAMERA_SHIFT=4 CAMERA_SHIFT value
                     */
                    StoreNotification.Type = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "CAMERA_BROKEN"] = 1;
                        values[valuesById[2] = "MISSING_DATA_RECOVER_IN_PROGRESS"] = 2;
                        values[valuesById[3] = "CUSTOM"] = 3;
                        values[valuesById[4] = "CAMERA_SHIFT"] = 4;
                        return values;
                    })();
    
                    /**
                     * Level enum.
                     * @name palexy.streaming.v1.StoreNotification.Level
                     * @enum {number}
                     * @property {number} LEVEL_UNKNOWN=0 LEVEL_UNKNOWN value
                     * @property {number} INFO=1 INFO value
                     * @property {number} WARN=2 WARN value
                     * @property {number} ERROR=3 ERROR value
                     */
                    StoreNotification.Level = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "LEVEL_UNKNOWN"] = 0;
                        values[valuesById[1] = "INFO"] = 1;
                        values[valuesById[2] = "WARN"] = 2;
                        values[valuesById[3] = "ERROR"] = 3;
                        return values;
                    })();
    
                    /**
                     * State enum.
                     * @name palexy.streaming.v1.StoreNotification.State
                     * @enum {number}
                     * @property {number} STATE_UNKNOWN=0 STATE_UNKNOWN value
                     * @property {number} ACTIVE=1 ACTIVE value
                     * @property {number} RESOLVED=2 RESOLVED value
                     * @property {number} CANCELLED=3 CANCELLED value
                     * @property {number} CANNOT_RESOLVE=4 CANNOT_RESOLVE value
                     */
                    StoreNotification.State = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "STATE_UNKNOWN"] = 0;
                        values[valuesById[1] = "ACTIVE"] = 1;
                        values[valuesById[2] = "RESOLVED"] = 2;
                        values[valuesById[3] = "CANCELLED"] = 3;
                        values[valuesById[4] = "CANNOT_RESOLVE"] = 4;
                        return values;
                    })();
    
                    return StoreNotification;
                })();
    
                v1.ListStoreNotificationRequest = (function() {
    
                    /**
                     * Properties of a ListStoreNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreNotificationRequest
                     * @property {palexy.streaming.v1.StoreNotification.Type|null} [type] ListStoreNotificationRequest type
                     * @property {Array.<palexy.streaming.v1.StoreNotification.State>|null} [states] ListStoreNotificationRequest states
                     * @property {number|null} [pageSize] ListStoreNotificationRequest pageSize
                     * @property {string|null} [pageToken] ListStoreNotificationRequest pageToken
                     * @property {google.protobuf.IInt64Value|null} [companyId] ListStoreNotificationRequest companyId
                     * @property {Array.<number>|null} [storeIds] ListStoreNotificationRequest storeIds
                     * @property {string|null} [startDate] ListStoreNotificationRequest startDate
                     * @property {string|null} [endDate] ListStoreNotificationRequest endDate
                     */
    
                    /**
                     * Constructs a new ListStoreNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreNotificationRequest.
                     * @implements IListStoreNotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreNotificationRequest=} [properties] Properties to set
                     */
                    function ListStoreNotificationRequest(properties) {
                        this.states = [];
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreNotificationRequest type.
                     * @member {palexy.streaming.v1.StoreNotification.Type} type
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @instance
                     */
                    ListStoreNotificationRequest.prototype.type = 0;
    
                    /**
                     * ListStoreNotificationRequest states.
                     * @member {Array.<palexy.streaming.v1.StoreNotification.State>} states
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @instance
                     */
                    ListStoreNotificationRequest.prototype.states = $util.emptyArray;
    
                    /**
                     * ListStoreNotificationRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @instance
                     */
                    ListStoreNotificationRequest.prototype.pageSize = 0;
    
                    /**
                     * ListStoreNotificationRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @instance
                     */
                    ListStoreNotificationRequest.prototype.pageToken = "";
    
                    /**
                     * ListStoreNotificationRequest companyId.
                     * @member {google.protobuf.IInt64Value|null|undefined} companyId
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @instance
                     */
                    ListStoreNotificationRequest.prototype.companyId = null;
    
                    /**
                     * ListStoreNotificationRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @instance
                     */
                    ListStoreNotificationRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * ListStoreNotificationRequest startDate.
                     * @member {string} startDate
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @instance
                     */
                    ListStoreNotificationRequest.prototype.startDate = "";
    
                    /**
                     * ListStoreNotificationRequest endDate.
                     * @member {string} endDate
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @instance
                     */
                    ListStoreNotificationRequest.prototype.endDate = "";
    
                    /**
                     * Creates a new ListStoreNotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreNotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreNotificationRequest} ListStoreNotificationRequest instance
                     */
                    ListStoreNotificationRequest.create = function create(properties) {
                        return new ListStoreNotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreNotificationRequest message. Does not implicitly {@link palexy.streaming.v1.ListStoreNotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreNotificationRequest} message ListStoreNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreNotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                        if (message.states != null && message.states.length) {
                            writer.uint32(/* id 4, wireType 2 =*/34).fork();
                            for (var i = 0; i < message.states.length; ++i)
                                writer.int32(message.states[i]);
                            writer.ldelim();
                        }
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.pageToken);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            $root.google.protobuf.Int64Value.encode(message.companyId, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 8, wireType 2 =*/66).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.startDate);
                        if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.endDate);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreNotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreNotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreNotificationRequest} message ListStoreNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreNotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreNotificationRequest} ListStoreNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreNotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreNotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 3:
                                message.type = reader.int32();
                                break;
                            case 4:
                                if (!(message.states && message.states.length))
                                    message.states = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.states.push(reader.int32());
                                } else
                                    message.states.push(reader.int32());
                                break;
                            case 5:
                                message.pageSize = reader.int32();
                                break;
                            case 6:
                                message.pageToken = reader.string();
                                break;
                            case 7:
                                message.companyId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 8:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 9:
                                message.startDate = reader.string();
                                break;
                            case 10:
                                message.endDate = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreNotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreNotificationRequest} ListStoreNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreNotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreNotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.states != null && message.hasOwnProperty("states")) {
                            if (!Array.isArray(message.states))
                                return "states: array expected";
                            for (var i = 0; i < message.states.length; ++i)
                                switch (message.states[i]) {
                                default:
                                    return "states: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                    break;
                                }
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.companyId);
                            if (error)
                                return "companyId." + error;
                        }
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreNotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreNotificationRequest} ListStoreNotificationRequest
                     */
                    ListStoreNotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreNotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreNotificationRequest();
                        switch (object.type) {
                        case "UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "CAMERA_BROKEN":
                        case 1:
                            message.type = 1;
                            break;
                        case "MISSING_DATA_RECOVER_IN_PROGRESS":
                        case 2:
                            message.type = 2;
                            break;
                        case "CUSTOM":
                        case 3:
                            message.type = 3;
                            break;
                        case "CAMERA_SHIFT":
                        case 4:
                            message.type = 4;
                            break;
                        }
                        if (object.states) {
                            if (!Array.isArray(object.states))
                                throw TypeError(".palexy.streaming.v1.ListStoreNotificationRequest.states: array expected");
                            message.states = [];
                            for (var i = 0; i < object.states.length; ++i)
                                switch (object.states[i]) {
                                default:
                                case "STATE_UNKNOWN":
                                case 0:
                                    message.states[i] = 0;
                                    break;
                                case "ACTIVE":
                                case 1:
                                    message.states[i] = 1;
                                    break;
                                case "RESOLVED":
                                case 2:
                                    message.states[i] = 2;
                                    break;
                                case "CANCELLED":
                                case 3:
                                    message.states[i] = 3;
                                    break;
                                case "CANNOT_RESOLVE":
                                case 4:
                                    message.states[i] = 4;
                                    break;
                                }
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.companyId != null) {
                            if (typeof object.companyId !== "object")
                                throw TypeError(".palexy.streaming.v1.ListStoreNotificationRequest.companyId: object expected");
                            message.companyId = $root.google.protobuf.Int64Value.fromObject(object.companyId);
                        }
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.streaming.v1.ListStoreNotificationRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreNotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStoreNotificationRequest} message ListStoreNotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreNotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.states = [];
                            object.storeIds = [];
                        }
                        if (options.defaults) {
                            object.type = options.enums === String ? "UNKNOWN" : 0;
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.companyId = null;
                            object.startDate = "";
                            object.endDate = "";
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.StoreNotification.Type[message.type] : message.type;
                        if (message.states && message.states.length) {
                            object.states = [];
                            for (var j = 0; j < message.states.length; ++j)
                                object.states[j] = options.enums === String ? $root.palexy.streaming.v1.StoreNotification.State[message.states[j]] : message.states[j];
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            object.companyId = $root.google.protobuf.Int64Value.toObject(message.companyId, options);
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreNotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreNotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreNotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreNotificationRequest;
                })();
    
                v1.ListStoreNotificationResponse = (function() {
    
                    /**
                     * Properties of a ListStoreNotificationResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreNotificationResponse
                     * @property {Array.<palexy.streaming.v1.IStoreNotification>|null} [notifications] ListStoreNotificationResponse notifications
                     * @property {number|null} [totalElements] ListStoreNotificationResponse totalElements
                     * @property {string|null} [nextPageToken] ListStoreNotificationResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListStoreNotificationResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreNotificationResponse.
                     * @implements IListStoreNotificationResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreNotificationResponse=} [properties] Properties to set
                     */
                    function ListStoreNotificationResponse(properties) {
                        this.notifications = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreNotificationResponse notifications.
                     * @member {Array.<palexy.streaming.v1.IStoreNotification>} notifications
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @instance
                     */
                    ListStoreNotificationResponse.prototype.notifications = $util.emptyArray;
    
                    /**
                     * ListStoreNotificationResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @instance
                     */
                    ListStoreNotificationResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListStoreNotificationResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @instance
                     */
                    ListStoreNotificationResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListStoreNotificationResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreNotificationResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreNotificationResponse} ListStoreNotificationResponse instance
                     */
                    ListStoreNotificationResponse.create = function create(properties) {
                        return new ListStoreNotificationResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreNotificationResponse message. Does not implicitly {@link palexy.streaming.v1.ListStoreNotificationResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreNotificationResponse} message ListStoreNotificationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreNotificationResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notifications != null && message.notifications.length)
                            for (var i = 0; i < message.notifications.length; ++i)
                                $root.palexy.streaming.v1.StoreNotification.encode(message.notifications[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreNotificationResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreNotificationResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreNotificationResponse} message ListStoreNotificationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreNotificationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreNotificationResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreNotificationResponse} ListStoreNotificationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreNotificationResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreNotificationResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.notifications && message.notifications.length))
                                    message.notifications = [];
                                message.notifications.push($root.palexy.streaming.v1.StoreNotification.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int64();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreNotificationResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreNotificationResponse} ListStoreNotificationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreNotificationResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreNotificationResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreNotificationResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notifications != null && message.hasOwnProperty("notifications")) {
                            if (!Array.isArray(message.notifications))
                                return "notifications: array expected";
                            for (var i = 0; i < message.notifications.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreNotification.verify(message.notifications[i]);
                                if (error)
                                    return "notifications." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreNotificationResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreNotificationResponse} ListStoreNotificationResponse
                     */
                    ListStoreNotificationResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreNotificationResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreNotificationResponse();
                        if (object.notifications) {
                            if (!Array.isArray(object.notifications))
                                throw TypeError(".palexy.streaming.v1.ListStoreNotificationResponse.notifications: array expected");
                            message.notifications = [];
                            for (var i = 0; i < object.notifications.length; ++i) {
                                if (typeof object.notifications[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStoreNotificationResponse.notifications: object expected");
                                message.notifications[i] = $root.palexy.streaming.v1.StoreNotification.fromObject(object.notifications[i]);
                            }
                        }
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreNotificationResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStoreNotificationResponse} message ListStoreNotificationResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreNotificationResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.notifications = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                            object.nextPageToken = "";
                        }
                        if (message.notifications && message.notifications.length) {
                            object.notifications = [];
                            for (var j = 0; j < message.notifications.length; ++j)
                                object.notifications[j] = $root.palexy.streaming.v1.StoreNotification.toObject(message.notifications[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreNotificationResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreNotificationResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreNotificationResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreNotificationResponse;
                })();
    
                v1.GetStoreNotificationRequest = (function() {
    
                    /**
                     * Properties of a GetStoreNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStoreNotificationRequest
                     * @property {number|null} [notificationId] GetStoreNotificationRequest notificationId
                     */
    
                    /**
                     * Constructs a new GetStoreNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStoreNotificationRequest.
                     * @implements IGetStoreNotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStoreNotificationRequest=} [properties] Properties to set
                     */
                    function GetStoreNotificationRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStoreNotificationRequest notificationId.
                     * @member {number} notificationId
                     * @memberof palexy.streaming.v1.GetStoreNotificationRequest
                     * @instance
                     */
                    GetStoreNotificationRequest.prototype.notificationId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetStoreNotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreNotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStoreNotificationRequest} GetStoreNotificationRequest instance
                     */
                    GetStoreNotificationRequest.create = function create(properties) {
                        return new GetStoreNotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStoreNotificationRequest message. Does not implicitly {@link palexy.streaming.v1.GetStoreNotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreNotificationRequest} message GetStoreNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreNotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notificationId != null && Object.hasOwnProperty.call(message, "notificationId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.notificationId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStoreNotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStoreNotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreNotificationRequest} message GetStoreNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStoreNotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStoreNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStoreNotificationRequest} GetStoreNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreNotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStoreNotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.notificationId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStoreNotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStoreNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStoreNotificationRequest} GetStoreNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStoreNotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStoreNotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStoreNotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (!$util.isInteger(message.notificationId) && !(message.notificationId && $util.isInteger(message.notificationId.low) && $util.isInteger(message.notificationId.high)))
                                return "notificationId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStoreNotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStoreNotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStoreNotificationRequest} GetStoreNotificationRequest
                     */
                    GetStoreNotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStoreNotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStoreNotificationRequest();
                        if (object.notificationId != null)
                            if ($util.Long)
                                (message.notificationId = $util.Long.fromValue(object.notificationId)).unsigned = false;
                            else if (typeof object.notificationId === "string")
                                message.notificationId = parseInt(object.notificationId, 10);
                            else if (typeof object.notificationId === "number")
                                message.notificationId = object.notificationId;
                            else if (typeof object.notificationId === "object")
                                message.notificationId = new $util.LongBits(object.notificationId.low >>> 0, object.notificationId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStoreNotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStoreNotificationRequest} message GetStoreNotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStoreNotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.notificationId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.notificationId = options.longs === String ? "0" : 0;
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (typeof message.notificationId === "number")
                                object.notificationId = options.longs === String ? String(message.notificationId) : message.notificationId;
                            else
                                object.notificationId = options.longs === String ? $util.Long.prototype.toString.call(message.notificationId) : options.longs === Number ? new $util.LongBits(message.notificationId.low >>> 0, message.notificationId.high >>> 0).toNumber() : message.notificationId;
                        return object;
                    };
    
                    /**
                     * Converts this GetStoreNotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStoreNotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStoreNotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStoreNotificationRequest;
                })();
    
                v1.UpdateStoreNotificationRequest = (function() {
    
                    /**
                     * Properties of an UpdateStoreNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateStoreNotificationRequest
                     * @property {palexy.streaming.v1.IStoreNotification|null} [notification] UpdateStoreNotificationRequest notification
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateStoreNotificationRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateStoreNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateStoreNotificationRequest.
                     * @implements IUpdateStoreNotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateStoreNotificationRequest=} [properties] Properties to set
                     */
                    function UpdateStoreNotificationRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateStoreNotificationRequest notification.
                     * @member {palexy.streaming.v1.IStoreNotification|null|undefined} notification
                     * @memberof palexy.streaming.v1.UpdateStoreNotificationRequest
                     * @instance
                     */
                    UpdateStoreNotificationRequest.prototype.notification = null;
    
                    /**
                     * UpdateStoreNotificationRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateStoreNotificationRequest
                     * @instance
                     */
                    UpdateStoreNotificationRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateStoreNotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreNotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateStoreNotificationRequest} UpdateStoreNotificationRequest instance
                     */
                    UpdateStoreNotificationRequest.create = function create(properties) {
                        return new UpdateStoreNotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateStoreNotificationRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateStoreNotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreNotificationRequest} message UpdateStoreNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStoreNotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notification != null && Object.hasOwnProperty.call(message, "notification"))
                            $root.palexy.streaming.v1.StoreNotification.encode(message.notification, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateStoreNotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateStoreNotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreNotificationRequest} message UpdateStoreNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStoreNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateStoreNotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateStoreNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateStoreNotificationRequest} UpdateStoreNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStoreNotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateStoreNotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.notification = $root.palexy.streaming.v1.StoreNotification.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateStoreNotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStoreNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateStoreNotificationRequest} UpdateStoreNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStoreNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateStoreNotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateStoreNotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateStoreNotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notification != null && message.hasOwnProperty("notification")) {
                            var error = $root.palexy.streaming.v1.StoreNotification.verify(message.notification);
                            if (error)
                                return "notification." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateStoreNotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateStoreNotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateStoreNotificationRequest} UpdateStoreNotificationRequest
                     */
                    UpdateStoreNotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateStoreNotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateStoreNotificationRequest();
                        if (object.notification != null) {
                            if (typeof object.notification !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStoreNotificationRequest.notification: object expected");
                            message.notification = $root.palexy.streaming.v1.StoreNotification.fromObject(object.notification);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStoreNotificationRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateStoreNotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateStoreNotificationRequest} message UpdateStoreNotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateStoreNotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.notification = null;
                            object.updateMask = null;
                        }
                        if (message.notification != null && message.hasOwnProperty("notification"))
                            object.notification = $root.palexy.streaming.v1.StoreNotification.toObject(message.notification, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateStoreNotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateStoreNotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateStoreNotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateStoreNotificationRequest;
                })();
    
                v1.ResolveStoreNotificationRequest = (function() {
    
                    /**
                     * Properties of a ResolveStoreNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IResolveStoreNotificationRequest
                     * @property {number|null} [notificationId] ResolveStoreNotificationRequest notificationId
                     * @property {number|null} [resolverId] ResolveStoreNotificationRequest resolverId
                     * @property {palexy.streaming.v1.StoreNotification.State|null} [state] ResolveStoreNotificationRequest state
                     */
    
                    /**
                     * Constructs a new ResolveStoreNotificationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ResolveStoreNotificationRequest.
                     * @implements IResolveStoreNotificationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IResolveStoreNotificationRequest=} [properties] Properties to set
                     */
                    function ResolveStoreNotificationRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ResolveStoreNotificationRequest notificationId.
                     * @member {number} notificationId
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @instance
                     */
                    ResolveStoreNotificationRequest.prototype.notificationId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ResolveStoreNotificationRequest resolverId.
                     * @member {number} resolverId
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @instance
                     */
                    ResolveStoreNotificationRequest.prototype.resolverId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ResolveStoreNotificationRequest state.
                     * @member {palexy.streaming.v1.StoreNotification.State} state
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @instance
                     */
                    ResolveStoreNotificationRequest.prototype.state = 0;
    
                    /**
                     * Creates a new ResolveStoreNotificationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IResolveStoreNotificationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ResolveStoreNotificationRequest} ResolveStoreNotificationRequest instance
                     */
                    ResolveStoreNotificationRequest.create = function create(properties) {
                        return new ResolveStoreNotificationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ResolveStoreNotificationRequest message. Does not implicitly {@link palexy.streaming.v1.ResolveStoreNotificationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IResolveStoreNotificationRequest} message ResolveStoreNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResolveStoreNotificationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notificationId != null && Object.hasOwnProperty.call(message, "notificationId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.notificationId);
                        if (message.resolverId != null && Object.hasOwnProperty.call(message, "resolverId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.resolverId);
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.state);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ResolveStoreNotificationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ResolveStoreNotificationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.IResolveStoreNotificationRequest} message ResolveStoreNotificationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResolveStoreNotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ResolveStoreNotificationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ResolveStoreNotificationRequest} ResolveStoreNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResolveStoreNotificationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ResolveStoreNotificationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.notificationId = reader.int64();
                                break;
                            case 2:
                                message.resolverId = reader.int64();
                                break;
                            case 3:
                                message.state = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ResolveStoreNotificationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ResolveStoreNotificationRequest} ResolveStoreNotificationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResolveStoreNotificationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ResolveStoreNotificationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ResolveStoreNotificationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (!$util.isInteger(message.notificationId) && !(message.notificationId && $util.isInteger(message.notificationId.low) && $util.isInteger(message.notificationId.high)))
                                return "notificationId: integer|Long expected";
                        if (message.resolverId != null && message.hasOwnProperty("resolverId"))
                            if (!$util.isInteger(message.resolverId) && !(message.resolverId && $util.isInteger(message.resolverId.low) && $util.isInteger(message.resolverId.high)))
                                return "resolverId: integer|Long expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a ResolveStoreNotificationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ResolveStoreNotificationRequest} ResolveStoreNotificationRequest
                     */
                    ResolveStoreNotificationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ResolveStoreNotificationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ResolveStoreNotificationRequest();
                        if (object.notificationId != null)
                            if ($util.Long)
                                (message.notificationId = $util.Long.fromValue(object.notificationId)).unsigned = false;
                            else if (typeof object.notificationId === "string")
                                message.notificationId = parseInt(object.notificationId, 10);
                            else if (typeof object.notificationId === "number")
                                message.notificationId = object.notificationId;
                            else if (typeof object.notificationId === "object")
                                message.notificationId = new $util.LongBits(object.notificationId.low >>> 0, object.notificationId.high >>> 0).toNumber();
                        if (object.resolverId != null)
                            if ($util.Long)
                                (message.resolverId = $util.Long.fromValue(object.resolverId)).unsigned = false;
                            else if (typeof object.resolverId === "string")
                                message.resolverId = parseInt(object.resolverId, 10);
                            else if (typeof object.resolverId === "number")
                                message.resolverId = object.resolverId;
                            else if (typeof object.resolverId === "object")
                                message.resolverId = new $util.LongBits(object.resolverId.low >>> 0, object.resolverId.high >>> 0).toNumber();
                        switch (object.state) {
                        case "STATE_UNKNOWN":
                        case 0:
                            message.state = 0;
                            break;
                        case "ACTIVE":
                        case 1:
                            message.state = 1;
                            break;
                        case "RESOLVED":
                        case 2:
                            message.state = 2;
                            break;
                        case "CANCELLED":
                        case 3:
                            message.state = 3;
                            break;
                        case "CANNOT_RESOLVE":
                        case 4:
                            message.state = 4;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ResolveStoreNotificationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @static
                     * @param {palexy.streaming.v1.ResolveStoreNotificationRequest} message ResolveStoreNotificationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ResolveStoreNotificationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.notificationId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.notificationId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.resolverId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.resolverId = options.longs === String ? "0" : 0;
                            object.state = options.enums === String ? "STATE_UNKNOWN" : 0;
                        }
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (typeof message.notificationId === "number")
                                object.notificationId = options.longs === String ? String(message.notificationId) : message.notificationId;
                            else
                                object.notificationId = options.longs === String ? $util.Long.prototype.toString.call(message.notificationId) : options.longs === Number ? new $util.LongBits(message.notificationId.low >>> 0, message.notificationId.high >>> 0).toNumber() : message.notificationId;
                        if (message.resolverId != null && message.hasOwnProperty("resolverId"))
                            if (typeof message.resolverId === "number")
                                object.resolverId = options.longs === String ? String(message.resolverId) : message.resolverId;
                            else
                                object.resolverId = options.longs === String ? $util.Long.prototype.toString.call(message.resolverId) : options.longs === Number ? new $util.LongBits(message.resolverId.low >>> 0, message.resolverId.high >>> 0).toNumber() : message.resolverId;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.streaming.v1.StoreNotification.State[message.state] : message.state;
                        return object;
                    };
    
                    /**
                     * Converts this ResolveStoreNotificationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ResolveStoreNotificationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ResolveStoreNotificationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ResolveStoreNotificationRequest;
                })();
    
                v1.AccountService = (function() {
    
                    /**
                     * Constructs a new AccountService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AccountService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function AccountService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (AccountService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AccountService;
    
                    /**
                     * Creates new AccountService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.AccountService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {AccountService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    AccountService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AccountService#listPalexyQcAccounts}.
                     * @memberof palexy.streaming.v1.AccountService
                     * @typedef ListPalexyQcAccountsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListAccountsResponse} [response] ListAccountsResponse
                     */
    
                    /**
                     * Calls ListPalexyQcAccounts.
                     * @function listPalexyQcAccounts
                     * @memberof palexy.streaming.v1.AccountService
                     * @instance
                     * @param {google.protobuf.IEmpty} request Empty message or plain object
                     * @param {palexy.streaming.v1.AccountService.ListPalexyQcAccountsCallback} callback Node-style callback called with the error, if any, and ListAccountsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AccountService.prototype.listPalexyQcAccounts = function listPalexyQcAccounts(request, callback) {
                        return this.rpcCall(listPalexyQcAccounts, $root.google.protobuf.Empty, $root.palexy.streaming.v1.ListAccountsResponse, request, callback);
                    }, "name", { value: "ListPalexyQcAccounts" });
    
                    /**
                     * Calls ListPalexyQcAccounts.
                     * @function listPalexyQcAccounts
                     * @memberof palexy.streaming.v1.AccountService
                     * @instance
                     * @param {google.protobuf.IEmpty} request Empty message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListAccountsResponse>} Promise
                     * @variation 2
                     */
    
                    return AccountService;
                })();
    
                v1.ListAccountsResponse = (function() {
    
                    /**
                     * Properties of a ListAccountsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListAccountsResponse
                     * @property {Array.<palexy.streaming.v1.IAccount>|null} [accounts] ListAccountsResponse accounts
                     */
    
                    /**
                     * Constructs a new ListAccountsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListAccountsResponse.
                     * @implements IListAccountsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListAccountsResponse=} [properties] Properties to set
                     */
                    function ListAccountsResponse(properties) {
                        this.accounts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListAccountsResponse accounts.
                     * @member {Array.<palexy.streaming.v1.IAccount>} accounts
                     * @memberof palexy.streaming.v1.ListAccountsResponse
                     * @instance
                     */
                    ListAccountsResponse.prototype.accounts = $util.emptyArray;
    
                    /**
                     * Creates a new ListAccountsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListAccountsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAccountsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListAccountsResponse} ListAccountsResponse instance
                     */
                    ListAccountsResponse.create = function create(properties) {
                        return new ListAccountsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListAccountsResponse message. Does not implicitly {@link palexy.streaming.v1.ListAccountsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListAccountsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAccountsResponse} message ListAccountsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAccountsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.accounts != null && message.accounts.length)
                            for (var i = 0; i < message.accounts.length; ++i)
                                $root.palexy.streaming.v1.Account.encode(message.accounts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListAccountsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListAccountsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListAccountsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAccountsResponse} message ListAccountsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAccountsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListAccountsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListAccountsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListAccountsResponse} ListAccountsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAccountsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListAccountsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.accounts && message.accounts.length))
                                    message.accounts = [];
                                message.accounts.push($root.palexy.streaming.v1.Account.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListAccountsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListAccountsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListAccountsResponse} ListAccountsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAccountsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListAccountsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListAccountsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListAccountsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.accounts != null && message.hasOwnProperty("accounts")) {
                            if (!Array.isArray(message.accounts))
                                return "accounts: array expected";
                            for (var i = 0; i < message.accounts.length; ++i) {
                                var error = $root.palexy.streaming.v1.Account.verify(message.accounts[i]);
                                if (error)
                                    return "accounts." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListAccountsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListAccountsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListAccountsResponse} ListAccountsResponse
                     */
                    ListAccountsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListAccountsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListAccountsResponse();
                        if (object.accounts) {
                            if (!Array.isArray(object.accounts))
                                throw TypeError(".palexy.streaming.v1.ListAccountsResponse.accounts: array expected");
                            message.accounts = [];
                            for (var i = 0; i < object.accounts.length; ++i) {
                                if (typeof object.accounts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListAccountsResponse.accounts: object expected");
                                message.accounts[i] = $root.palexy.streaming.v1.Account.fromObject(object.accounts[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListAccountsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListAccountsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListAccountsResponse} message ListAccountsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListAccountsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.accounts = [];
                        if (message.accounts && message.accounts.length) {
                            object.accounts = [];
                            for (var j = 0; j < message.accounts.length; ++j)
                                object.accounts[j] = $root.palexy.streaming.v1.Account.toObject(message.accounts[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListAccountsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListAccountsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListAccountsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListAccountsResponse;
                })();
    
                v1.ReportSchedule = (function() {
    
                    /**
                     * Properties of a ReportSchedule.
                     * @memberof palexy.streaming.v1
                     * @interface IReportSchedule
                     * @property {number|null} [id] ReportSchedule id
                     * @property {string|null} [scheduleName] ReportSchedule scheduleName
                     * @property {number|null} [companyId] ReportSchedule companyId
                     * @property {google.protobuf.ITimestamp|null} [createTime] ReportSchedule createTime
                     * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] ReportSchedule lastUpdateTime
                     * @property {palexy.streaming.v1.ReportSchedule.ScheduleType|null} [type] ReportSchedule type
                     * @property {google.protobuf.ITimestamp|null} [appliedDateTime] ReportSchedule appliedDateTime
                     * @property {string|null} [timezone] ReportSchedule timezone
                     * @property {string|null} [startSendingTime] ReportSchedule startSendingTime
                     * @property {palexy.streaming.v1.ReportSchedule.ReportType|null} [reportType] ReportSchedule reportType
                     * @property {number|null} [receiveReportDay] ReportSchedule receiveReportDay
                     * @property {Array.<palexy.streaming.v1.IReportScheduleReceiver>|null} [receivers] ReportSchedule receivers
                     * @property {string|null} [nextSendTime] ReportSchedule nextSendTime
                     * @property {palexy.streaming.v1.IDateRange|null} [latestReportDateRange] ReportSchedule latestReportDateRange
                     */
    
                    /**
                     * Constructs a new ReportSchedule.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ReportSchedule.
                     * @implements IReportSchedule
                     * @constructor
                     * @param {palexy.streaming.v1.IReportSchedule=} [properties] Properties to set
                     */
                    function ReportSchedule(properties) {
                        this.receivers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReportSchedule id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ReportSchedule scheduleName.
                     * @member {string} scheduleName
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.scheduleName = "";
    
                    /**
                     * ReportSchedule companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ReportSchedule createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.createTime = null;
    
                    /**
                     * ReportSchedule lastUpdateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.lastUpdateTime = null;
    
                    /**
                     * ReportSchedule type.
                     * @member {palexy.streaming.v1.ReportSchedule.ScheduleType} type
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.type = 0;
    
                    /**
                     * ReportSchedule appliedDateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} appliedDateTime
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.appliedDateTime = null;
    
                    /**
                     * ReportSchedule timezone.
                     * @member {string} timezone
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.timezone = "";
    
                    /**
                     * ReportSchedule startSendingTime.
                     * @member {string} startSendingTime
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.startSendingTime = "";
    
                    /**
                     * ReportSchedule reportType.
                     * @member {palexy.streaming.v1.ReportSchedule.ReportType} reportType
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.reportType = 0;
    
                    /**
                     * ReportSchedule receiveReportDay.
                     * @member {number} receiveReportDay
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.receiveReportDay = 0;
    
                    /**
                     * ReportSchedule receivers.
                     * @member {Array.<palexy.streaming.v1.IReportScheduleReceiver>} receivers
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.receivers = $util.emptyArray;
    
                    /**
                     * ReportSchedule nextSendTime.
                     * @member {string} nextSendTime
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.nextSendTime = "";
    
                    /**
                     * ReportSchedule latestReportDateRange.
                     * @member {palexy.streaming.v1.IDateRange|null|undefined} latestReportDateRange
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     */
                    ReportSchedule.prototype.latestReportDateRange = null;
    
                    /**
                     * Creates a new ReportSchedule instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @static
                     * @param {palexy.streaming.v1.IReportSchedule=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ReportSchedule} ReportSchedule instance
                     */
                    ReportSchedule.create = function create(properties) {
                        return new ReportSchedule(properties);
                    };
    
                    /**
                     * Encodes the specified ReportSchedule message. Does not implicitly {@link palexy.streaming.v1.ReportSchedule.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @static
                     * @param {palexy.streaming.v1.IReportSchedule} message ReportSchedule message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportSchedule.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.scheduleName != null && Object.hasOwnProperty.call(message, "scheduleName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.scheduleName);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.companyId);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.type);
                        if (message.appliedDateTime != null && Object.hasOwnProperty.call(message, "appliedDateTime"))
                            $root.google.protobuf.Timestamp.encode(message.appliedDateTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.timezone);
                        if (message.startSendingTime != null && Object.hasOwnProperty.call(message, "startSendingTime"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.startSendingTime);
                        if (message.reportType != null && Object.hasOwnProperty.call(message, "reportType"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.reportType);
                        if (message.receiveReportDay != null && Object.hasOwnProperty.call(message, "receiveReportDay"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.receiveReportDay);
                        if (message.receivers != null && message.receivers.length)
                            for (var i = 0; i < message.receivers.length; ++i)
                                $root.palexy.streaming.v1.ReportScheduleReceiver.encode(message.receivers[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                        if (message.nextSendTime != null && Object.hasOwnProperty.call(message, "nextSendTime"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.nextSendTime);
                        if (message.latestReportDateRange != null && Object.hasOwnProperty.call(message, "latestReportDateRange"))
                            $root.palexy.streaming.v1.DateRange.encode(message.latestReportDateRange, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReportSchedule message, length delimited. Does not implicitly {@link palexy.streaming.v1.ReportSchedule.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @static
                     * @param {palexy.streaming.v1.IReportSchedule} message ReportSchedule message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportSchedule.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReportSchedule message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ReportSchedule} ReportSchedule
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportSchedule.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ReportSchedule();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.scheduleName = reader.string();
                                break;
                            case 3:
                                message.companyId = reader.int64();
                                break;
                            case 4:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.type = reader.int32();
                                break;
                            case 7:
                                message.appliedDateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.timezone = reader.string();
                                break;
                            case 10:
                                message.startSendingTime = reader.string();
                                break;
                            case 12:
                                message.reportType = reader.int32();
                                break;
                            case 13:
                                message.receiveReportDay = reader.int32();
                                break;
                            case 14:
                                if (!(message.receivers && message.receivers.length))
                                    message.receivers = [];
                                message.receivers.push($root.palexy.streaming.v1.ReportScheduleReceiver.decode(reader, reader.uint32()));
                                break;
                            case 15:
                                message.nextSendTime = reader.string();
                                break;
                            case 16:
                                message.latestReportDateRange = $root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReportSchedule message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ReportSchedule} ReportSchedule
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportSchedule.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReportSchedule message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReportSchedule.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.scheduleName != null && message.hasOwnProperty("scheduleName"))
                            if (!$util.isString(message.scheduleName))
                                return "scheduleName: string expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                            if (error)
                                return "lastUpdateTime." + error;
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.appliedDateTime != null && message.hasOwnProperty("appliedDateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.appliedDateTime);
                            if (error)
                                return "appliedDateTime." + error;
                        }
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            if (!$util.isString(message.timezone))
                                return "timezone: string expected";
                        if (message.startSendingTime != null && message.hasOwnProperty("startSendingTime"))
                            if (!$util.isString(message.startSendingTime))
                                return "startSendingTime: string expected";
                        if (message.reportType != null && message.hasOwnProperty("reportType"))
                            switch (message.reportType) {
                            default:
                                return "reportType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.receiveReportDay != null && message.hasOwnProperty("receiveReportDay"))
                            if (!$util.isInteger(message.receiveReportDay))
                                return "receiveReportDay: integer expected";
                        if (message.receivers != null && message.hasOwnProperty("receivers")) {
                            if (!Array.isArray(message.receivers))
                                return "receivers: array expected";
                            for (var i = 0; i < message.receivers.length; ++i) {
                                var error = $root.palexy.streaming.v1.ReportScheduleReceiver.verify(message.receivers[i]);
                                if (error)
                                    return "receivers." + error;
                            }
                        }
                        if (message.nextSendTime != null && message.hasOwnProperty("nextSendTime"))
                            if (!$util.isString(message.nextSendTime))
                                return "nextSendTime: string expected";
                        if (message.latestReportDateRange != null && message.hasOwnProperty("latestReportDateRange")) {
                            var error = $root.palexy.streaming.v1.DateRange.verify(message.latestReportDateRange);
                            if (error)
                                return "latestReportDateRange." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ReportSchedule message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ReportSchedule} ReportSchedule
                     */
                    ReportSchedule.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ReportSchedule)
                            return object;
                        var message = new $root.palexy.streaming.v1.ReportSchedule();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.scheduleName != null)
                            message.scheduleName = String(object.scheduleName);
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.ReportSchedule.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.lastUpdateTime != null) {
                            if (typeof object.lastUpdateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.ReportSchedule.lastUpdateTime: object expected");
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                        }
                        switch (object.type) {
                        case "UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "WEEKLY":
                        case 1:
                            message.type = 1;
                            break;
                        case "MONTHLY":
                        case 2:
                            message.type = 2;
                            break;
                        }
                        if (object.appliedDateTime != null) {
                            if (typeof object.appliedDateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.ReportSchedule.appliedDateTime: object expected");
                            message.appliedDateTime = $root.google.protobuf.Timestamp.fromObject(object.appliedDateTime);
                        }
                        if (object.timezone != null)
                            message.timezone = String(object.timezone);
                        if (object.startSendingTime != null)
                            message.startSendingTime = String(object.startSendingTime);
                        switch (object.reportType) {
                        case "UNKNOWN_REPORT_TYPE":
                        case 0:
                            message.reportType = 0;
                            break;
                        case "BASIC":
                        case 1:
                            message.reportType = 1;
                            break;
                        case "STORE_EVENTS":
                        case 2:
                            message.reportType = 2;
                            break;
                        }
                        if (object.receiveReportDay != null)
                            message.receiveReportDay = object.receiveReportDay | 0;
                        if (object.receivers) {
                            if (!Array.isArray(object.receivers))
                                throw TypeError(".palexy.streaming.v1.ReportSchedule.receivers: array expected");
                            message.receivers = [];
                            for (var i = 0; i < object.receivers.length; ++i) {
                                if (typeof object.receivers[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ReportSchedule.receivers: object expected");
                                message.receivers[i] = $root.palexy.streaming.v1.ReportScheduleReceiver.fromObject(object.receivers[i]);
                            }
                        }
                        if (object.nextSendTime != null)
                            message.nextSendTime = String(object.nextSendTime);
                        if (object.latestReportDateRange != null) {
                            if (typeof object.latestReportDateRange !== "object")
                                throw TypeError(".palexy.streaming.v1.ReportSchedule.latestReportDateRange: object expected");
                            message.latestReportDateRange = $root.palexy.streaming.v1.DateRange.fromObject(object.latestReportDateRange);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReportSchedule message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @static
                     * @param {palexy.streaming.v1.ReportSchedule} message ReportSchedule
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReportSchedule.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.receivers = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.scheduleName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.createTime = null;
                            object.lastUpdateTime = null;
                            object.type = options.enums === String ? "UNKNOWN" : 0;
                            object.appliedDateTime = null;
                            object.timezone = "";
                            object.startSendingTime = "";
                            object.reportType = options.enums === String ? "UNKNOWN_REPORT_TYPE" : 0;
                            object.receiveReportDay = 0;
                            object.nextSendTime = "";
                            object.latestReportDateRange = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.scheduleName != null && message.hasOwnProperty("scheduleName"))
                            object.scheduleName = message.scheduleName;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                            object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.ReportSchedule.ScheduleType[message.type] : message.type;
                        if (message.appliedDateTime != null && message.hasOwnProperty("appliedDateTime"))
                            object.appliedDateTime = $root.google.protobuf.Timestamp.toObject(message.appliedDateTime, options);
                        if (message.timezone != null && message.hasOwnProperty("timezone"))
                            object.timezone = message.timezone;
                        if (message.startSendingTime != null && message.hasOwnProperty("startSendingTime"))
                            object.startSendingTime = message.startSendingTime;
                        if (message.reportType != null && message.hasOwnProperty("reportType"))
                            object.reportType = options.enums === String ? $root.palexy.streaming.v1.ReportSchedule.ReportType[message.reportType] : message.reportType;
                        if (message.receiveReportDay != null && message.hasOwnProperty("receiveReportDay"))
                            object.receiveReportDay = message.receiveReportDay;
                        if (message.receivers && message.receivers.length) {
                            object.receivers = [];
                            for (var j = 0; j < message.receivers.length; ++j)
                                object.receivers[j] = $root.palexy.streaming.v1.ReportScheduleReceiver.toObject(message.receivers[j], options);
                        }
                        if (message.nextSendTime != null && message.hasOwnProperty("nextSendTime"))
                            object.nextSendTime = message.nextSendTime;
                        if (message.latestReportDateRange != null && message.hasOwnProperty("latestReportDateRange"))
                            object.latestReportDateRange = $root.palexy.streaming.v1.DateRange.toObject(message.latestReportDateRange, options);
                        return object;
                    };
    
                    /**
                     * Converts this ReportSchedule to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ReportSchedule
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReportSchedule.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * ScheduleType enum.
                     * @name palexy.streaming.v1.ReportSchedule.ScheduleType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} WEEKLY=1 WEEKLY value
                     * @property {number} MONTHLY=2 MONTHLY value
                     */
                    ReportSchedule.ScheduleType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "WEEKLY"] = 1;
                        values[valuesById[2] = "MONTHLY"] = 2;
                        return values;
                    })();
    
                    /**
                     * ReportType enum.
                     * @name palexy.streaming.v1.ReportSchedule.ReportType
                     * @enum {number}
                     * @property {number} UNKNOWN_REPORT_TYPE=0 UNKNOWN_REPORT_TYPE value
                     * @property {number} BASIC=1 BASIC value
                     * @property {number} STORE_EVENTS=2 STORE_EVENTS value
                     */
                    ReportSchedule.ReportType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_REPORT_TYPE"] = 0;
                        values[valuesById[1] = "BASIC"] = 1;
                        values[valuesById[2] = "STORE_EVENTS"] = 2;
                        return values;
                    })();
    
                    return ReportSchedule;
                })();
    
                v1.ReportScheduleReceiver = (function() {
    
                    /**
                     * Properties of a ReportScheduleReceiver.
                     * @memberof palexy.streaming.v1
                     * @interface IReportScheduleReceiver
                     * @property {palexy.streaming.v1.ReportScheduleReceiver.Type|null} [type] ReportScheduleReceiver type
                     * @property {string|null} [singleUserEmail] ReportScheduleReceiver singleUserEmail
                     * @property {number|null} [groupId] ReportScheduleReceiver groupId
                     */
    
                    /**
                     * Constructs a new ReportScheduleReceiver.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ReportScheduleReceiver.
                     * @implements IReportScheduleReceiver
                     * @constructor
                     * @param {palexy.streaming.v1.IReportScheduleReceiver=} [properties] Properties to set
                     */
                    function ReportScheduleReceiver(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReportScheduleReceiver type.
                     * @member {palexy.streaming.v1.ReportScheduleReceiver.Type} type
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @instance
                     */
                    ReportScheduleReceiver.prototype.type = 0;
    
                    /**
                     * ReportScheduleReceiver singleUserEmail.
                     * @member {string|null|undefined} singleUserEmail
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @instance
                     */
                    ReportScheduleReceiver.prototype.singleUserEmail = null;
    
                    /**
                     * ReportScheduleReceiver groupId.
                     * @member {number|null|undefined} groupId
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @instance
                     */
                    ReportScheduleReceiver.prototype.groupId = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * ReportScheduleReceiver receiver.
                     * @member {"singleUserEmail"|"groupId"|undefined} receiver
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @instance
                     */
                    Object.defineProperty(ReportScheduleReceiver.prototype, "receiver", {
                        get: $util.oneOfGetter($oneOfFields = ["singleUserEmail", "groupId"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new ReportScheduleReceiver instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @static
                     * @param {palexy.streaming.v1.IReportScheduleReceiver=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ReportScheduleReceiver} ReportScheduleReceiver instance
                     */
                    ReportScheduleReceiver.create = function create(properties) {
                        return new ReportScheduleReceiver(properties);
                    };
    
                    /**
                     * Encodes the specified ReportScheduleReceiver message. Does not implicitly {@link palexy.streaming.v1.ReportScheduleReceiver.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @static
                     * @param {palexy.streaming.v1.IReportScheduleReceiver} message ReportScheduleReceiver message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportScheduleReceiver.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        if (message.singleUserEmail != null && Object.hasOwnProperty.call(message, "singleUserEmail"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.singleUserEmail);
                        if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.groupId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReportScheduleReceiver message, length delimited. Does not implicitly {@link palexy.streaming.v1.ReportScheduleReceiver.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @static
                     * @param {palexy.streaming.v1.IReportScheduleReceiver} message ReportScheduleReceiver message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportScheduleReceiver.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReportScheduleReceiver message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ReportScheduleReceiver} ReportScheduleReceiver
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportScheduleReceiver.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ReportScheduleReceiver();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.type = reader.int32();
                                break;
                            case 2:
                                message.singleUserEmail = reader.string();
                                break;
                            case 3:
                                message.groupId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReportScheduleReceiver message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ReportScheduleReceiver} ReportScheduleReceiver
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportScheduleReceiver.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReportScheduleReceiver message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReportScheduleReceiver.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.singleUserEmail != null && message.hasOwnProperty("singleUserEmail")) {
                            properties.receiver = 1;
                            if (!$util.isString(message.singleUserEmail))
                                return "singleUserEmail: string expected";
                        }
                        if (message.groupId != null && message.hasOwnProperty("groupId")) {
                            if (properties.receiver === 1)
                                return "receiver: multiple values";
                            properties.receiver = 1;
                            if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                                return "groupId: integer|Long expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ReportScheduleReceiver message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ReportScheduleReceiver} ReportScheduleReceiver
                     */
                    ReportScheduleReceiver.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ReportScheduleReceiver)
                            return object;
                        var message = new $root.palexy.streaming.v1.ReportScheduleReceiver();
                        switch (object.type) {
                        case "UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "SINGLE_USER":
                        case 1:
                            message.type = 1;
                            break;
                        case "ICA_GROUP":
                        case 2:
                            message.type = 2;
                            break;
                        }
                        if (object.singleUserEmail != null)
                            message.singleUserEmail = String(object.singleUserEmail);
                        if (object.groupId != null)
                            if ($util.Long)
                                (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = false;
                            else if (typeof object.groupId === "string")
                                message.groupId = parseInt(object.groupId, 10);
                            else if (typeof object.groupId === "number")
                                message.groupId = object.groupId;
                            else if (typeof object.groupId === "object")
                                message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReportScheduleReceiver message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @static
                     * @param {palexy.streaming.v1.ReportScheduleReceiver} message ReportScheduleReceiver
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReportScheduleReceiver.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.type = options.enums === String ? "UNKNOWN" : 0;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.ReportScheduleReceiver.Type[message.type] : message.type;
                        if (message.singleUserEmail != null && message.hasOwnProperty("singleUserEmail")) {
                            object.singleUserEmail = message.singleUserEmail;
                            if (options.oneofs)
                                object.receiver = "singleUserEmail";
                        }
                        if (message.groupId != null && message.hasOwnProperty("groupId")) {
                            if (typeof message.groupId === "number")
                                object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                            else
                                object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber() : message.groupId;
                            if (options.oneofs)
                                object.receiver = "groupId";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ReportScheduleReceiver to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ReportScheduleReceiver
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReportScheduleReceiver.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Type enum.
                     * @name palexy.streaming.v1.ReportScheduleReceiver.Type
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} SINGLE_USER=1 SINGLE_USER value
                     * @property {number} ICA_GROUP=2 ICA_GROUP value
                     */
                    ReportScheduleReceiver.Type = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "SINGLE_USER"] = 1;
                        values[valuesById[2] = "ICA_GROUP"] = 2;
                        return values;
                    })();
    
                    return ReportScheduleReceiver;
                })();
    
                v1.ListReportScheduleReceiverRequest = (function() {
    
                    /**
                     * Properties of a ListReportScheduleReceiverRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListReportScheduleReceiverRequest
                     * @property {number|null} [scheduleId] ListReportScheduleReceiverRequest scheduleId
                     */
    
                    /**
                     * Constructs a new ListReportScheduleReceiverRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListReportScheduleReceiverRequest.
                     * @implements IListReportScheduleReceiverRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListReportScheduleReceiverRequest=} [properties] Properties to set
                     */
                    function ListReportScheduleReceiverRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListReportScheduleReceiverRequest scheduleId.
                     * @member {number} scheduleId
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverRequest
                     * @instance
                     */
                    ListReportScheduleReceiverRequest.prototype.scheduleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListReportScheduleReceiverRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverRequest
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleReceiverRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListReportScheduleReceiverRequest} ListReportScheduleReceiverRequest instance
                     */
                    ListReportScheduleReceiverRequest.create = function create(properties) {
                        return new ListReportScheduleReceiverRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListReportScheduleReceiverRequest message. Does not implicitly {@link palexy.streaming.v1.ListReportScheduleReceiverRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverRequest
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleReceiverRequest} message ListReportScheduleReceiverRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListReportScheduleReceiverRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.scheduleId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListReportScheduleReceiverRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListReportScheduleReceiverRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverRequest
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleReceiverRequest} message ListReportScheduleReceiverRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListReportScheduleReceiverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListReportScheduleReceiverRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListReportScheduleReceiverRequest} ListReportScheduleReceiverRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListReportScheduleReceiverRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListReportScheduleReceiverRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.scheduleId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListReportScheduleReceiverRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListReportScheduleReceiverRequest} ListReportScheduleReceiverRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListReportScheduleReceiverRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListReportScheduleReceiverRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListReportScheduleReceiverRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                            if (!$util.isInteger(message.scheduleId) && !(message.scheduleId && $util.isInteger(message.scheduleId.low) && $util.isInteger(message.scheduleId.high)))
                                return "scheduleId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListReportScheduleReceiverRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListReportScheduleReceiverRequest} ListReportScheduleReceiverRequest
                     */
                    ListReportScheduleReceiverRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListReportScheduleReceiverRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListReportScheduleReceiverRequest();
                        if (object.scheduleId != null)
                            if ($util.Long)
                                (message.scheduleId = $util.Long.fromValue(object.scheduleId)).unsigned = false;
                            else if (typeof object.scheduleId === "string")
                                message.scheduleId = parseInt(object.scheduleId, 10);
                            else if (typeof object.scheduleId === "number")
                                message.scheduleId = object.scheduleId;
                            else if (typeof object.scheduleId === "object")
                                message.scheduleId = new $util.LongBits(object.scheduleId.low >>> 0, object.scheduleId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListReportScheduleReceiverRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverRequest
                     * @static
                     * @param {palexy.streaming.v1.ListReportScheduleReceiverRequest} message ListReportScheduleReceiverRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListReportScheduleReceiverRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.scheduleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.scheduleId = options.longs === String ? "0" : 0;
                        if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                            if (typeof message.scheduleId === "number")
                                object.scheduleId = options.longs === String ? String(message.scheduleId) : message.scheduleId;
                            else
                                object.scheduleId = options.longs === String ? $util.Long.prototype.toString.call(message.scheduleId) : options.longs === Number ? new $util.LongBits(message.scheduleId.low >>> 0, message.scheduleId.high >>> 0).toNumber() : message.scheduleId;
                        return object;
                    };
    
                    /**
                     * Converts this ListReportScheduleReceiverRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListReportScheduleReceiverRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListReportScheduleReceiverRequest;
                })();
    
                v1.ListReportScheduleReceiverResponse = (function() {
    
                    /**
                     * Properties of a ListReportScheduleReceiverResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListReportScheduleReceiverResponse
                     * @property {Array.<palexy.streaming.v1.IReportScheduleReceiver>|null} [receivers] ListReportScheduleReceiverResponse receivers
                     */
    
                    /**
                     * Constructs a new ListReportScheduleReceiverResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListReportScheduleReceiverResponse.
                     * @implements IListReportScheduleReceiverResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListReportScheduleReceiverResponse=} [properties] Properties to set
                     */
                    function ListReportScheduleReceiverResponse(properties) {
                        this.receivers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListReportScheduleReceiverResponse receivers.
                     * @member {Array.<palexy.streaming.v1.IReportScheduleReceiver>} receivers
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverResponse
                     * @instance
                     */
                    ListReportScheduleReceiverResponse.prototype.receivers = $util.emptyArray;
    
                    /**
                     * Creates a new ListReportScheduleReceiverResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverResponse
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleReceiverResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListReportScheduleReceiverResponse} ListReportScheduleReceiverResponse instance
                     */
                    ListReportScheduleReceiverResponse.create = function create(properties) {
                        return new ListReportScheduleReceiverResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListReportScheduleReceiverResponse message. Does not implicitly {@link palexy.streaming.v1.ListReportScheduleReceiverResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverResponse
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleReceiverResponse} message ListReportScheduleReceiverResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListReportScheduleReceiverResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.receivers != null && message.receivers.length)
                            for (var i = 0; i < message.receivers.length; ++i)
                                $root.palexy.streaming.v1.ReportScheduleReceiver.encode(message.receivers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListReportScheduleReceiverResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListReportScheduleReceiverResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverResponse
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleReceiverResponse} message ListReportScheduleReceiverResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListReportScheduleReceiverResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListReportScheduleReceiverResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListReportScheduleReceiverResponse} ListReportScheduleReceiverResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListReportScheduleReceiverResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListReportScheduleReceiverResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.receivers && message.receivers.length))
                                    message.receivers = [];
                                message.receivers.push($root.palexy.streaming.v1.ReportScheduleReceiver.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListReportScheduleReceiverResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListReportScheduleReceiverResponse} ListReportScheduleReceiverResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListReportScheduleReceiverResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListReportScheduleReceiverResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListReportScheduleReceiverResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.receivers != null && message.hasOwnProperty("receivers")) {
                            if (!Array.isArray(message.receivers))
                                return "receivers: array expected";
                            for (var i = 0; i < message.receivers.length; ++i) {
                                var error = $root.palexy.streaming.v1.ReportScheduleReceiver.verify(message.receivers[i]);
                                if (error)
                                    return "receivers." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListReportScheduleReceiverResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListReportScheduleReceiverResponse} ListReportScheduleReceiverResponse
                     */
                    ListReportScheduleReceiverResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListReportScheduleReceiverResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListReportScheduleReceiverResponse();
                        if (object.receivers) {
                            if (!Array.isArray(object.receivers))
                                throw TypeError(".palexy.streaming.v1.ListReportScheduleReceiverResponse.receivers: array expected");
                            message.receivers = [];
                            for (var i = 0; i < object.receivers.length; ++i) {
                                if (typeof object.receivers[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListReportScheduleReceiverResponse.receivers: object expected");
                                message.receivers[i] = $root.palexy.streaming.v1.ReportScheduleReceiver.fromObject(object.receivers[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListReportScheduleReceiverResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverResponse
                     * @static
                     * @param {palexy.streaming.v1.ListReportScheduleReceiverResponse} message ListReportScheduleReceiverResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListReportScheduleReceiverResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.receivers = [];
                        if (message.receivers && message.receivers.length) {
                            object.receivers = [];
                            for (var j = 0; j < message.receivers.length; ++j)
                                object.receivers[j] = $root.palexy.streaming.v1.ReportScheduleReceiver.toObject(message.receivers[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListReportScheduleReceiverResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListReportScheduleReceiverResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListReportScheduleReceiverResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListReportScheduleReceiverResponse;
                })();
    
                v1.ListAccessibleStoresRequest = (function() {
    
                    /**
                     * Properties of a ListAccessibleStoresRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListAccessibleStoresRequest
                     * @property {string|null} [accountEmail] ListAccessibleStoresRequest accountEmail
                     * @property {number|null} [companyId] ListAccessibleStoresRequest companyId
                     */
    
                    /**
                     * Constructs a new ListAccessibleStoresRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListAccessibleStoresRequest.
                     * @implements IListAccessibleStoresRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListAccessibleStoresRequest=} [properties] Properties to set
                     */
                    function ListAccessibleStoresRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListAccessibleStoresRequest accountEmail.
                     * @member {string} accountEmail
                     * @memberof palexy.streaming.v1.ListAccessibleStoresRequest
                     * @instance
                     */
                    ListAccessibleStoresRequest.prototype.accountEmail = "";
    
                    /**
                     * ListAccessibleStoresRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListAccessibleStoresRequest
                     * @instance
                     */
                    ListAccessibleStoresRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListAccessibleStoresRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListAccessibleStoresRequest
                     * @static
                     * @param {palexy.streaming.v1.IListAccessibleStoresRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListAccessibleStoresRequest} ListAccessibleStoresRequest instance
                     */
                    ListAccessibleStoresRequest.create = function create(properties) {
                        return new ListAccessibleStoresRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListAccessibleStoresRequest message. Does not implicitly {@link palexy.streaming.v1.ListAccessibleStoresRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListAccessibleStoresRequest
                     * @static
                     * @param {palexy.streaming.v1.IListAccessibleStoresRequest} message ListAccessibleStoresRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAccessibleStoresRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.accountEmail != null && Object.hasOwnProperty.call(message, "accountEmail"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountEmail);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListAccessibleStoresRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListAccessibleStoresRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListAccessibleStoresRequest
                     * @static
                     * @param {palexy.streaming.v1.IListAccessibleStoresRequest} message ListAccessibleStoresRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAccessibleStoresRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListAccessibleStoresRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListAccessibleStoresRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListAccessibleStoresRequest} ListAccessibleStoresRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAccessibleStoresRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListAccessibleStoresRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.accountEmail = reader.string();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListAccessibleStoresRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListAccessibleStoresRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListAccessibleStoresRequest} ListAccessibleStoresRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAccessibleStoresRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListAccessibleStoresRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListAccessibleStoresRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListAccessibleStoresRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.accountEmail != null && message.hasOwnProperty("accountEmail"))
                            if (!$util.isString(message.accountEmail))
                                return "accountEmail: string expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListAccessibleStoresRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListAccessibleStoresRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListAccessibleStoresRequest} ListAccessibleStoresRequest
                     */
                    ListAccessibleStoresRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListAccessibleStoresRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListAccessibleStoresRequest();
                        if (object.accountEmail != null)
                            message.accountEmail = String(object.accountEmail);
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListAccessibleStoresRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListAccessibleStoresRequest
                     * @static
                     * @param {palexy.streaming.v1.ListAccessibleStoresRequest} message ListAccessibleStoresRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListAccessibleStoresRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.accountEmail = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                        }
                        if (message.accountEmail != null && message.hasOwnProperty("accountEmail"))
                            object.accountEmail = message.accountEmail;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        return object;
                    };
    
                    /**
                     * Converts this ListAccessibleStoresRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListAccessibleStoresRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListAccessibleStoresRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListAccessibleStoresRequest;
                })();
    
                v1.ListAccessibleStoresResponse = (function() {
    
                    /**
                     * Properties of a ListAccessibleStoresResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListAccessibleStoresResponse
                     * @property {Array.<palexy.streaming.v1.IStore>|null} [stores] ListAccessibleStoresResponse stores
                     */
    
                    /**
                     * Constructs a new ListAccessibleStoresResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListAccessibleStoresResponse.
                     * @implements IListAccessibleStoresResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListAccessibleStoresResponse=} [properties] Properties to set
                     */
                    function ListAccessibleStoresResponse(properties) {
                        this.stores = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListAccessibleStoresResponse stores.
                     * @member {Array.<palexy.streaming.v1.IStore>} stores
                     * @memberof palexy.streaming.v1.ListAccessibleStoresResponse
                     * @instance
                     */
                    ListAccessibleStoresResponse.prototype.stores = $util.emptyArray;
    
                    /**
                     * Creates a new ListAccessibleStoresResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListAccessibleStoresResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAccessibleStoresResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListAccessibleStoresResponse} ListAccessibleStoresResponse instance
                     */
                    ListAccessibleStoresResponse.create = function create(properties) {
                        return new ListAccessibleStoresResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListAccessibleStoresResponse message. Does not implicitly {@link palexy.streaming.v1.ListAccessibleStoresResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListAccessibleStoresResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAccessibleStoresResponse} message ListAccessibleStoresResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAccessibleStoresResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stores != null && message.stores.length)
                            for (var i = 0; i < message.stores.length; ++i)
                                $root.palexy.streaming.v1.Store.encode(message.stores[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListAccessibleStoresResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListAccessibleStoresResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListAccessibleStoresResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAccessibleStoresResponse} message ListAccessibleStoresResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAccessibleStoresResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListAccessibleStoresResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListAccessibleStoresResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListAccessibleStoresResponse} ListAccessibleStoresResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAccessibleStoresResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListAccessibleStoresResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.stores && message.stores.length))
                                    message.stores = [];
                                message.stores.push($root.palexy.streaming.v1.Store.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListAccessibleStoresResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListAccessibleStoresResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListAccessibleStoresResponse} ListAccessibleStoresResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAccessibleStoresResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListAccessibleStoresResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListAccessibleStoresResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListAccessibleStoresResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stores != null && message.hasOwnProperty("stores")) {
                            if (!Array.isArray(message.stores))
                                return "stores: array expected";
                            for (var i = 0; i < message.stores.length; ++i) {
                                var error = $root.palexy.streaming.v1.Store.verify(message.stores[i]);
                                if (error)
                                    return "stores." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListAccessibleStoresResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListAccessibleStoresResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListAccessibleStoresResponse} ListAccessibleStoresResponse
                     */
                    ListAccessibleStoresResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListAccessibleStoresResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListAccessibleStoresResponse();
                        if (object.stores) {
                            if (!Array.isArray(object.stores))
                                throw TypeError(".palexy.streaming.v1.ListAccessibleStoresResponse.stores: array expected");
                            message.stores = [];
                            for (var i = 0; i < object.stores.length; ++i) {
                                if (typeof object.stores[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListAccessibleStoresResponse.stores: object expected");
                                message.stores[i] = $root.palexy.streaming.v1.Store.fromObject(object.stores[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListAccessibleStoresResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListAccessibleStoresResponse
                     * @static
                     * @param {palexy.streaming.v1.ListAccessibleStoresResponse} message ListAccessibleStoresResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListAccessibleStoresResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.stores = [];
                        if (message.stores && message.stores.length) {
                            object.stores = [];
                            for (var j = 0; j < message.stores.length; ++j)
                                object.stores[j] = $root.palexy.streaming.v1.Store.toObject(message.stores[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListAccessibleStoresResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListAccessibleStoresResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListAccessibleStoresResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListAccessibleStoresResponse;
                })();
    
                v1.ScheduledReportService = (function() {
    
                    /**
                     * Constructs a new ScheduledReportService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ScheduledReportService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ScheduledReportService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (ScheduledReportService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ScheduledReportService;
    
                    /**
                     * Creates new ScheduledReportService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ScheduledReportService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ScheduledReportService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledReportService#getEmailsFromReceiver}.
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @typedef GetEmailsFromReceiverCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.GetEmailsFromReceiverResponse} [response] GetEmailsFromReceiverResponse
                     */
    
                    /**
                     * Calls GetEmailsFromReceiver.
                     * @function getEmailsFromReceiver
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IGetEmailsFromReceiverRequest} request GetEmailsFromReceiverRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledReportService.GetEmailsFromReceiverCallback} callback Node-style callback called with the error, if any, and GetEmailsFromReceiverResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledReportService.prototype.getEmailsFromReceiver = function getEmailsFromReceiver(request, callback) {
                        return this.rpcCall(getEmailsFromReceiver, $root.palexy.streaming.v1.GetEmailsFromReceiverRequest, $root.palexy.streaming.v1.GetEmailsFromReceiverResponse, request, callback);
                    }, "name", { value: "GetEmailsFromReceiver" });
    
                    /**
                     * Calls GetEmailsFromReceiver.
                     * @function getEmailsFromReceiver
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IGetEmailsFromReceiverRequest} request GetEmailsFromReceiverRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.GetEmailsFromReceiverResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledReportService#listReportScheduleReceiver}.
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @typedef ListReportScheduleReceiverCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListReportScheduleReceiverResponse} [response] ListReportScheduleReceiverResponse
                     */
    
                    /**
                     * Calls ListReportScheduleReceiver.
                     * @function listReportScheduleReceiver
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IListReportScheduleReceiverRequest} request ListReportScheduleReceiverRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledReportService.ListReportScheduleReceiverCallback} callback Node-style callback called with the error, if any, and ListReportScheduleReceiverResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledReportService.prototype.listReportScheduleReceiver = function listReportScheduleReceiver(request, callback) {
                        return this.rpcCall(listReportScheduleReceiver, $root.palexy.streaming.v1.ListReportScheduleReceiverRequest, $root.palexy.streaming.v1.ListReportScheduleReceiverResponse, request, callback);
                    }, "name", { value: "ListReportScheduleReceiver" });
    
                    /**
                     * Calls ListReportScheduleReceiver.
                     * @function listReportScheduleReceiver
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IListReportScheduleReceiverRequest} request ListReportScheduleReceiverRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListReportScheduleReceiverResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledReportService#listAccessibleStores}.
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @typedef ListAccessibleStoresCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListAccessibleStoresResponse} [response] ListAccessibleStoresResponse
                     */
    
                    /**
                     * Calls ListAccessibleStores.
                     * @function listAccessibleStores
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IListAccessibleStoresRequest} request ListAccessibleStoresRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledReportService.ListAccessibleStoresCallback} callback Node-style callback called with the error, if any, and ListAccessibleStoresResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledReportService.prototype.listAccessibleStores = function listAccessibleStores(request, callback) {
                        return this.rpcCall(listAccessibleStores, $root.palexy.streaming.v1.ListAccessibleStoresRequest, $root.palexy.streaming.v1.ListAccessibleStoresResponse, request, callback);
                    }, "name", { value: "ListAccessibleStores" });
    
                    /**
                     * Calls ListAccessibleStores.
                     * @function listAccessibleStores
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IListAccessibleStoresRequest} request ListAccessibleStoresRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListAccessibleStoresResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledReportService#getScheduledReport}.
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @typedef GetScheduledReportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.GetScheduledReportResponse} [response] GetScheduledReportResponse
                     */
    
                    /**
                     * Calls GetScheduledReport.
                     * @function getScheduledReport
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IGetScheduledReportRequest} request GetScheduledReportRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledReportService.GetScheduledReportCallback} callback Node-style callback called with the error, if any, and GetScheduledReportResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledReportService.prototype.getScheduledReport = function getScheduledReport(request, callback) {
                        return this.rpcCall(getScheduledReport, $root.palexy.streaming.v1.GetScheduledReportRequest, $root.palexy.streaming.v1.GetScheduledReportResponse, request, callback);
                    }, "name", { value: "GetScheduledReport" });
    
                    /**
                     * Calls GetScheduledReport.
                     * @function getScheduledReport
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IGetScheduledReportRequest} request GetScheduledReportRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.GetScheduledReportResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledReportService#updateNextSendTime}.
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @typedef UpdateNextSendTimeCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.UpdateNextSendTimeResponse} [response] UpdateNextSendTimeResponse
                     */
    
                    /**
                     * Calls UpdateNextSendTime.
                     * @function updateNextSendTime
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateNextSendTimeRequest} request UpdateNextSendTimeRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledReportService.UpdateNextSendTimeCallback} callback Node-style callback called with the error, if any, and UpdateNextSendTimeResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledReportService.prototype.updateNextSendTime = function updateNextSendTime(request, callback) {
                        return this.rpcCall(updateNextSendTime, $root.palexy.streaming.v1.UpdateNextSendTimeRequest, $root.palexy.streaming.v1.UpdateNextSendTimeResponse, request, callback);
                    }, "name", { value: "UpdateNextSendTime" });
    
                    /**
                     * Calls UpdateNextSendTime.
                     * @function updateNextSendTime
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateNextSendTimeRequest} request UpdateNextSendTimeRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.UpdateNextSendTimeResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledReportService#listReportSchedule}.
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @typedef ListReportScheduleCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListReportScheduleResponse} [response] ListReportScheduleResponse
                     */
    
                    /**
                     * Calls ListReportSchedule.
                     * @function listReportSchedule
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IListReportScheduleRequest} request ListReportScheduleRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledReportService.ListReportScheduleCallback} callback Node-style callback called with the error, if any, and ListReportScheduleResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledReportService.prototype.listReportSchedule = function listReportSchedule(request, callback) {
                        return this.rpcCall(listReportSchedule, $root.palexy.streaming.v1.ListReportScheduleRequest, $root.palexy.streaming.v1.ListReportScheduleResponse, request, callback);
                    }, "name", { value: "ListReportSchedule" });
    
                    /**
                     * Calls ListReportSchedule.
                     * @function listReportSchedule
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IListReportScheduleRequest} request ListReportScheduleRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListReportScheduleResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledReportService#createReportSchedule}.
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @typedef CreateReportScheduleCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ReportSchedule} [response] ReportSchedule
                     */
    
                    /**
                     * Calls CreateReportSchedule.
                     * @function createReportSchedule
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateReportScheduleRequest} request CreateReportScheduleRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledReportService.CreateReportScheduleCallback} callback Node-style callback called with the error, if any, and ReportSchedule
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledReportService.prototype.createReportSchedule = function createReportSchedule(request, callback) {
                        return this.rpcCall(createReportSchedule, $root.palexy.streaming.v1.CreateReportScheduleRequest, $root.palexy.streaming.v1.ReportSchedule, request, callback);
                    }, "name", { value: "CreateReportSchedule" });
    
                    /**
                     * Calls CreateReportSchedule.
                     * @function createReportSchedule
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateReportScheduleRequest} request CreateReportScheduleRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ReportSchedule>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledReportService#getReportSchedule}.
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @typedef GetReportScheduleCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ReportSchedule} [response] ReportSchedule
                     */
    
                    /**
                     * Calls GetReportSchedule.
                     * @function getReportSchedule
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IGetReportScheduleRequest} request GetReportScheduleRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledReportService.GetReportScheduleCallback} callback Node-style callback called with the error, if any, and ReportSchedule
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledReportService.prototype.getReportSchedule = function getReportSchedule(request, callback) {
                        return this.rpcCall(getReportSchedule, $root.palexy.streaming.v1.GetReportScheduleRequest, $root.palexy.streaming.v1.ReportSchedule, request, callback);
                    }, "name", { value: "GetReportSchedule" });
    
                    /**
                     * Calls GetReportSchedule.
                     * @function getReportSchedule
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IGetReportScheduleRequest} request GetReportScheduleRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ReportSchedule>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledReportService#updateReportSchedule}.
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @typedef UpdateReportScheduleCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ReportSchedule} [response] ReportSchedule
                     */
    
                    /**
                     * Calls UpdateReportSchedule.
                     * @function updateReportSchedule
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateReportScheduleRequest} request UpdateReportScheduleRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledReportService.UpdateReportScheduleCallback} callback Node-style callback called with the error, if any, and ReportSchedule
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledReportService.prototype.updateReportSchedule = function updateReportSchedule(request, callback) {
                        return this.rpcCall(updateReportSchedule, $root.palexy.streaming.v1.UpdateReportScheduleRequest, $root.palexy.streaming.v1.ReportSchedule, request, callback);
                    }, "name", { value: "UpdateReportSchedule" });
    
                    /**
                     * Calls UpdateReportSchedule.
                     * @function updateReportSchedule
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateReportScheduleRequest} request UpdateReportScheduleRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ReportSchedule>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledReportService#deleteReportSchedule}.
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @typedef DeleteReportScheduleCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteReportSchedule.
                     * @function deleteReportSchedule
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteReportScheduleRequest} request DeleteReportScheduleRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledReportService.DeleteReportScheduleCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledReportService.prototype.deleteReportSchedule = function deleteReportSchedule(request, callback) {
                        return this.rpcCall(deleteReportSchedule, $root.palexy.streaming.v1.DeleteReportScheduleRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteReportSchedule" });
    
                    /**
                     * Calls DeleteReportSchedule.
                     * @function deleteReportSchedule
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteReportScheduleRequest} request DeleteReportScheduleRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledReportService#sendOutReport}.
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @typedef SendOutReportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls SendOutReport.
                     * @function sendOutReport
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.ISendOutReportRequest} request SendOutReportRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledReportService.SendOutReportCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledReportService.prototype.sendOutReport = function sendOutReport(request, callback) {
                        return this.rpcCall(sendOutReport, $root.palexy.streaming.v1.SendOutReportRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "SendOutReport" });
    
                    /**
                     * Calls SendOutReport.
                     * @function sendOutReport
                     * @memberof palexy.streaming.v1.ScheduledReportService
                     * @instance
                     * @param {palexy.streaming.v1.ISendOutReportRequest} request SendOutReportRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return ScheduledReportService;
                })();
    
                v1.GetEmailsFromReceiverRequest = (function() {
    
                    /**
                     * Properties of a GetEmailsFromReceiverRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetEmailsFromReceiverRequest
                     * @property {Array.<palexy.streaming.v1.IReportScheduleReceiver>|null} [receivers] GetEmailsFromReceiverRequest receivers
                     */
    
                    /**
                     * Constructs a new GetEmailsFromReceiverRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetEmailsFromReceiverRequest.
                     * @implements IGetEmailsFromReceiverRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetEmailsFromReceiverRequest=} [properties] Properties to set
                     */
                    function GetEmailsFromReceiverRequest(properties) {
                        this.receivers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetEmailsFromReceiverRequest receivers.
                     * @member {Array.<palexy.streaming.v1.IReportScheduleReceiver>} receivers
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverRequest
                     * @instance
                     */
                    GetEmailsFromReceiverRequest.prototype.receivers = $util.emptyArray;
    
                    /**
                     * Creates a new GetEmailsFromReceiverRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetEmailsFromReceiverRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetEmailsFromReceiverRequest} GetEmailsFromReceiverRequest instance
                     */
                    GetEmailsFromReceiverRequest.create = function create(properties) {
                        return new GetEmailsFromReceiverRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetEmailsFromReceiverRequest message. Does not implicitly {@link palexy.streaming.v1.GetEmailsFromReceiverRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetEmailsFromReceiverRequest} message GetEmailsFromReceiverRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetEmailsFromReceiverRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.receivers != null && message.receivers.length)
                            for (var i = 0; i < message.receivers.length; ++i)
                                $root.palexy.streaming.v1.ReportScheduleReceiver.encode(message.receivers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetEmailsFromReceiverRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetEmailsFromReceiverRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetEmailsFromReceiverRequest} message GetEmailsFromReceiverRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetEmailsFromReceiverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetEmailsFromReceiverRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetEmailsFromReceiverRequest} GetEmailsFromReceiverRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetEmailsFromReceiverRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetEmailsFromReceiverRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.receivers && message.receivers.length))
                                    message.receivers = [];
                                message.receivers.push($root.palexy.streaming.v1.ReportScheduleReceiver.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetEmailsFromReceiverRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetEmailsFromReceiverRequest} GetEmailsFromReceiverRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetEmailsFromReceiverRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetEmailsFromReceiverRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetEmailsFromReceiverRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.receivers != null && message.hasOwnProperty("receivers")) {
                            if (!Array.isArray(message.receivers))
                                return "receivers: array expected";
                            for (var i = 0; i < message.receivers.length; ++i) {
                                var error = $root.palexy.streaming.v1.ReportScheduleReceiver.verify(message.receivers[i]);
                                if (error)
                                    return "receivers." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetEmailsFromReceiverRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetEmailsFromReceiverRequest} GetEmailsFromReceiverRequest
                     */
                    GetEmailsFromReceiverRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetEmailsFromReceiverRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetEmailsFromReceiverRequest();
                        if (object.receivers) {
                            if (!Array.isArray(object.receivers))
                                throw TypeError(".palexy.streaming.v1.GetEmailsFromReceiverRequest.receivers: array expected");
                            message.receivers = [];
                            for (var i = 0; i < object.receivers.length; ++i) {
                                if (typeof object.receivers[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.GetEmailsFromReceiverRequest.receivers: object expected");
                                message.receivers[i] = $root.palexy.streaming.v1.ReportScheduleReceiver.fromObject(object.receivers[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetEmailsFromReceiverRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverRequest
                     * @static
                     * @param {palexy.streaming.v1.GetEmailsFromReceiverRequest} message GetEmailsFromReceiverRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetEmailsFromReceiverRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.receivers = [];
                        if (message.receivers && message.receivers.length) {
                            object.receivers = [];
                            for (var j = 0; j < message.receivers.length; ++j)
                                object.receivers[j] = $root.palexy.streaming.v1.ReportScheduleReceiver.toObject(message.receivers[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetEmailsFromReceiverRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetEmailsFromReceiverRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetEmailsFromReceiverRequest;
                })();
    
                v1.GetEmailsFromReceiverResponse = (function() {
    
                    /**
                     * Properties of a GetEmailsFromReceiverResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IGetEmailsFromReceiverResponse
                     * @property {Array.<string>|null} [emails] GetEmailsFromReceiverResponse emails
                     */
    
                    /**
                     * Constructs a new GetEmailsFromReceiverResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetEmailsFromReceiverResponse.
                     * @implements IGetEmailsFromReceiverResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IGetEmailsFromReceiverResponse=} [properties] Properties to set
                     */
                    function GetEmailsFromReceiverResponse(properties) {
                        this.emails = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetEmailsFromReceiverResponse emails.
                     * @member {Array.<string>} emails
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverResponse
                     * @instance
                     */
                    GetEmailsFromReceiverResponse.prototype.emails = $util.emptyArray;
    
                    /**
                     * Creates a new GetEmailsFromReceiverResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetEmailsFromReceiverResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetEmailsFromReceiverResponse} GetEmailsFromReceiverResponse instance
                     */
                    GetEmailsFromReceiverResponse.create = function create(properties) {
                        return new GetEmailsFromReceiverResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetEmailsFromReceiverResponse message. Does not implicitly {@link palexy.streaming.v1.GetEmailsFromReceiverResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetEmailsFromReceiverResponse} message GetEmailsFromReceiverResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetEmailsFromReceiverResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.emails != null && message.emails.length)
                            for (var i = 0; i < message.emails.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.emails[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetEmailsFromReceiverResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetEmailsFromReceiverResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetEmailsFromReceiverResponse} message GetEmailsFromReceiverResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetEmailsFromReceiverResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetEmailsFromReceiverResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetEmailsFromReceiverResponse} GetEmailsFromReceiverResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetEmailsFromReceiverResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetEmailsFromReceiverResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.emails && message.emails.length))
                                    message.emails = [];
                                message.emails.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetEmailsFromReceiverResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetEmailsFromReceiverResponse} GetEmailsFromReceiverResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetEmailsFromReceiverResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetEmailsFromReceiverResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetEmailsFromReceiverResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.emails != null && message.hasOwnProperty("emails")) {
                            if (!Array.isArray(message.emails))
                                return "emails: array expected";
                            for (var i = 0; i < message.emails.length; ++i)
                                if (!$util.isString(message.emails[i]))
                                    return "emails: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetEmailsFromReceiverResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetEmailsFromReceiverResponse} GetEmailsFromReceiverResponse
                     */
                    GetEmailsFromReceiverResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetEmailsFromReceiverResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetEmailsFromReceiverResponse();
                        if (object.emails) {
                            if (!Array.isArray(object.emails))
                                throw TypeError(".palexy.streaming.v1.GetEmailsFromReceiverResponse.emails: array expected");
                            message.emails = [];
                            for (var i = 0; i < object.emails.length; ++i)
                                message.emails[i] = String(object.emails[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetEmailsFromReceiverResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverResponse
                     * @static
                     * @param {palexy.streaming.v1.GetEmailsFromReceiverResponse} message GetEmailsFromReceiverResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetEmailsFromReceiverResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.emails = [];
                        if (message.emails && message.emails.length) {
                            object.emails = [];
                            for (var j = 0; j < message.emails.length; ++j)
                                object.emails[j] = message.emails[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetEmailsFromReceiverResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetEmailsFromReceiverResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetEmailsFromReceiverResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetEmailsFromReceiverResponse;
                })();
    
                v1.GetScheduledReportRequest = (function() {
    
                    /**
                     * Properties of a GetScheduledReportRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetScheduledReportRequest
                     * @property {palexy.streaming.v1.IDateRange|null} [dateRange] GetScheduledReportRequest dateRange
                     * @property {string|null} [email] GetScheduledReportRequest email
                     * @property {number|null} [scheduleId] GetScheduledReportRequest scheduleId
                     */
    
                    /**
                     * Constructs a new GetScheduledReportRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetScheduledReportRequest.
                     * @implements IGetScheduledReportRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetScheduledReportRequest=} [properties] Properties to set
                     */
                    function GetScheduledReportRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetScheduledReportRequest dateRange.
                     * @member {palexy.streaming.v1.IDateRange|null|undefined} dateRange
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @instance
                     */
                    GetScheduledReportRequest.prototype.dateRange = null;
    
                    /**
                     * GetScheduledReportRequest email.
                     * @member {string} email
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @instance
                     */
                    GetScheduledReportRequest.prototype.email = "";
    
                    /**
                     * GetScheduledReportRequest scheduleId.
                     * @member {number} scheduleId
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @instance
                     */
                    GetScheduledReportRequest.prototype.scheduleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetScheduledReportRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetScheduledReportRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetScheduledReportRequest} GetScheduledReportRequest instance
                     */
                    GetScheduledReportRequest.create = function create(properties) {
                        return new GetScheduledReportRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetScheduledReportRequest message. Does not implicitly {@link palexy.streaming.v1.GetScheduledReportRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetScheduledReportRequest} message GetScheduledReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetScheduledReportRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dateRange != null && Object.hasOwnProperty.call(message, "dateRange"))
                            $root.palexy.streaming.v1.DateRange.encode(message.dateRange, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
                        if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.scheduleId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetScheduledReportRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetScheduledReportRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetScheduledReportRequest} message GetScheduledReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetScheduledReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetScheduledReportRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetScheduledReportRequest} GetScheduledReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetScheduledReportRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetScheduledReportRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 2:
                                message.dateRange = $root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.email = reader.string();
                                break;
                            case 4:
                                message.scheduleId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetScheduledReportRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetScheduledReportRequest} GetScheduledReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetScheduledReportRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetScheduledReportRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetScheduledReportRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dateRange != null && message.hasOwnProperty("dateRange")) {
                            var error = $root.palexy.streaming.v1.DateRange.verify(message.dateRange);
                            if (error)
                                return "dateRange." + error;
                        }
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                            if (!$util.isInteger(message.scheduleId) && !(message.scheduleId && $util.isInteger(message.scheduleId.low) && $util.isInteger(message.scheduleId.high)))
                                return "scheduleId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetScheduledReportRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetScheduledReportRequest} GetScheduledReportRequest
                     */
                    GetScheduledReportRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetScheduledReportRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetScheduledReportRequest();
                        if (object.dateRange != null) {
                            if (typeof object.dateRange !== "object")
                                throw TypeError(".palexy.streaming.v1.GetScheduledReportRequest.dateRange: object expected");
                            message.dateRange = $root.palexy.streaming.v1.DateRange.fromObject(object.dateRange);
                        }
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.scheduleId != null)
                            if ($util.Long)
                                (message.scheduleId = $util.Long.fromValue(object.scheduleId)).unsigned = false;
                            else if (typeof object.scheduleId === "string")
                                message.scheduleId = parseInt(object.scheduleId, 10);
                            else if (typeof object.scheduleId === "number")
                                message.scheduleId = object.scheduleId;
                            else if (typeof object.scheduleId === "object")
                                message.scheduleId = new $util.LongBits(object.scheduleId.low >>> 0, object.scheduleId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetScheduledReportRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @static
                     * @param {palexy.streaming.v1.GetScheduledReportRequest} message GetScheduledReportRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetScheduledReportRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.dateRange = null;
                            object.email = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.scheduleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.scheduleId = options.longs === String ? "0" : 0;
                        }
                        if (message.dateRange != null && message.hasOwnProperty("dateRange"))
                            object.dateRange = $root.palexy.streaming.v1.DateRange.toObject(message.dateRange, options);
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                            if (typeof message.scheduleId === "number")
                                object.scheduleId = options.longs === String ? String(message.scheduleId) : message.scheduleId;
                            else
                                object.scheduleId = options.longs === String ? $util.Long.prototype.toString.call(message.scheduleId) : options.longs === Number ? new $util.LongBits(message.scheduleId.low >>> 0, message.scheduleId.high >>> 0).toNumber() : message.scheduleId;
                        return object;
                    };
    
                    /**
                     * Converts this GetScheduledReportRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetScheduledReportRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetScheduledReportRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetScheduledReportRequest;
                })();
    
                v1.GetScheduledReportResponse = (function() {
    
                    /**
                     * Properties of a GetScheduledReportResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IGetScheduledReportResponse
                     * @property {palexy.streaming.v1.IScheduledReport|null} [scheduleReport] GetScheduledReportResponse scheduleReport
                     */
    
                    /**
                     * Constructs a new GetScheduledReportResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetScheduledReportResponse.
                     * @implements IGetScheduledReportResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IGetScheduledReportResponse=} [properties] Properties to set
                     */
                    function GetScheduledReportResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetScheduledReportResponse scheduleReport.
                     * @member {palexy.streaming.v1.IScheduledReport|null|undefined} scheduleReport
                     * @memberof palexy.streaming.v1.GetScheduledReportResponse
                     * @instance
                     */
                    GetScheduledReportResponse.prototype.scheduleReport = null;
    
                    /**
                     * Creates a new GetScheduledReportResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetScheduledReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetScheduledReportResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetScheduledReportResponse} GetScheduledReportResponse instance
                     */
                    GetScheduledReportResponse.create = function create(properties) {
                        return new GetScheduledReportResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetScheduledReportResponse message. Does not implicitly {@link palexy.streaming.v1.GetScheduledReportResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetScheduledReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetScheduledReportResponse} message GetScheduledReportResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetScheduledReportResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.scheduleReport != null && Object.hasOwnProperty.call(message, "scheduleReport"))
                            $root.palexy.streaming.v1.ScheduledReport.encode(message.scheduleReport, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetScheduledReportResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetScheduledReportResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetScheduledReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetScheduledReportResponse} message GetScheduledReportResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetScheduledReportResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetScheduledReportResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetScheduledReportResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetScheduledReportResponse} GetScheduledReportResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetScheduledReportResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetScheduledReportResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.scheduleReport = $root.palexy.streaming.v1.ScheduledReport.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetScheduledReportResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetScheduledReportResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetScheduledReportResponse} GetScheduledReportResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetScheduledReportResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetScheduledReportResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetScheduledReportResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetScheduledReportResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.scheduleReport != null && message.hasOwnProperty("scheduleReport")) {
                            var error = $root.palexy.streaming.v1.ScheduledReport.verify(message.scheduleReport);
                            if (error)
                                return "scheduleReport." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetScheduledReportResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetScheduledReportResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetScheduledReportResponse} GetScheduledReportResponse
                     */
                    GetScheduledReportResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetScheduledReportResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetScheduledReportResponse();
                        if (object.scheduleReport != null) {
                            if (typeof object.scheduleReport !== "object")
                                throw TypeError(".palexy.streaming.v1.GetScheduledReportResponse.scheduleReport: object expected");
                            message.scheduleReport = $root.palexy.streaming.v1.ScheduledReport.fromObject(object.scheduleReport);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetScheduledReportResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetScheduledReportResponse
                     * @static
                     * @param {palexy.streaming.v1.GetScheduledReportResponse} message GetScheduledReportResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetScheduledReportResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.scheduleReport = null;
                        if (message.scheduleReport != null && message.hasOwnProperty("scheduleReport"))
                            object.scheduleReport = $root.palexy.streaming.v1.ScheduledReport.toObject(message.scheduleReport, options);
                        return object;
                    };
    
                    /**
                     * Converts this GetScheduledReportResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetScheduledReportResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetScheduledReportResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetScheduledReportResponse;
                })();
    
                v1.UpdateNextSendTimeRequest = (function() {
    
                    /**
                     * Properties of an UpdateNextSendTimeRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateNextSendTimeRequest
                     * @property {google.protobuf.IInt32Value|null} [startOfWeek] UpdateNextSendTimeRequest startOfWeek
                     * @property {number|null} [scheduleId] UpdateNextSendTimeRequest scheduleId
                     */
    
                    /**
                     * Constructs a new UpdateNextSendTimeRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateNextSendTimeRequest.
                     * @implements IUpdateNextSendTimeRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateNextSendTimeRequest=} [properties] Properties to set
                     */
                    function UpdateNextSendTimeRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateNextSendTimeRequest startOfWeek.
                     * @member {google.protobuf.IInt32Value|null|undefined} startOfWeek
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeRequest
                     * @instance
                     */
                    UpdateNextSendTimeRequest.prototype.startOfWeek = null;
    
                    /**
                     * UpdateNextSendTimeRequest scheduleId.
                     * @member {number} scheduleId
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeRequest
                     * @instance
                     */
                    UpdateNextSendTimeRequest.prototype.scheduleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new UpdateNextSendTimeRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNextSendTimeRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateNextSendTimeRequest} UpdateNextSendTimeRequest instance
                     */
                    UpdateNextSendTimeRequest.create = function create(properties) {
                        return new UpdateNextSendTimeRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateNextSendTimeRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateNextSendTimeRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNextSendTimeRequest} message UpdateNextSendTimeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateNextSendTimeRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startOfWeek != null && Object.hasOwnProperty.call(message, "startOfWeek"))
                            $root.google.protobuf.Int32Value.encode(message.startOfWeek, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.scheduleId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateNextSendTimeRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateNextSendTimeRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNextSendTimeRequest} message UpdateNextSendTimeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateNextSendTimeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateNextSendTimeRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateNextSendTimeRequest} UpdateNextSendTimeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateNextSendTimeRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateNextSendTimeRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startOfWeek = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.scheduleId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateNextSendTimeRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateNextSendTimeRequest} UpdateNextSendTimeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateNextSendTimeRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateNextSendTimeRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateNextSendTimeRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.startOfWeek != null && message.hasOwnProperty("startOfWeek")) {
                            var error = $root.google.protobuf.Int32Value.verify(message.startOfWeek);
                            if (error)
                                return "startOfWeek." + error;
                        }
                        if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                            if (!$util.isInteger(message.scheduleId) && !(message.scheduleId && $util.isInteger(message.scheduleId.low) && $util.isInteger(message.scheduleId.high)))
                                return "scheduleId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpdateNextSendTimeRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateNextSendTimeRequest} UpdateNextSendTimeRequest
                     */
                    UpdateNextSendTimeRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateNextSendTimeRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateNextSendTimeRequest();
                        if (object.startOfWeek != null) {
                            if (typeof object.startOfWeek !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateNextSendTimeRequest.startOfWeek: object expected");
                            message.startOfWeek = $root.google.protobuf.Int32Value.fromObject(object.startOfWeek);
                        }
                        if (object.scheduleId != null)
                            if ($util.Long)
                                (message.scheduleId = $util.Long.fromValue(object.scheduleId)).unsigned = false;
                            else if (typeof object.scheduleId === "string")
                                message.scheduleId = parseInt(object.scheduleId, 10);
                            else if (typeof object.scheduleId === "number")
                                message.scheduleId = object.scheduleId;
                            else if (typeof object.scheduleId === "object")
                                message.scheduleId = new $util.LongBits(object.scheduleId.low >>> 0, object.scheduleId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateNextSendTimeRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateNextSendTimeRequest} message UpdateNextSendTimeRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateNextSendTimeRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startOfWeek = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.scheduleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.scheduleId = options.longs === String ? "0" : 0;
                        }
                        if (message.startOfWeek != null && message.hasOwnProperty("startOfWeek"))
                            object.startOfWeek = $root.google.protobuf.Int32Value.toObject(message.startOfWeek, options);
                        if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                            if (typeof message.scheduleId === "number")
                                object.scheduleId = options.longs === String ? String(message.scheduleId) : message.scheduleId;
                            else
                                object.scheduleId = options.longs === String ? $util.Long.prototype.toString.call(message.scheduleId) : options.longs === Number ? new $util.LongBits(message.scheduleId.low >>> 0, message.scheduleId.high >>> 0).toNumber() : message.scheduleId;
                        return object;
                    };
    
                    /**
                     * Converts this UpdateNextSendTimeRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateNextSendTimeRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateNextSendTimeRequest;
                })();
    
                v1.UpdateNextSendTimeResponse = (function() {
    
                    /**
                     * Properties of an UpdateNextSendTimeResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateNextSendTimeResponse
                     * @property {google.protobuf.ITimestamp|null} [nextSendTime] UpdateNextSendTimeResponse nextSendTime
                     */
    
                    /**
                     * Constructs a new UpdateNextSendTimeResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateNextSendTimeResponse.
                     * @implements IUpdateNextSendTimeResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateNextSendTimeResponse=} [properties] Properties to set
                     */
                    function UpdateNextSendTimeResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateNextSendTimeResponse nextSendTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} nextSendTime
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeResponse
                     * @instance
                     */
                    UpdateNextSendTimeResponse.prototype.nextSendTime = null;
    
                    /**
                     * Creates a new UpdateNextSendTimeResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeResponse
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNextSendTimeResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateNextSendTimeResponse} UpdateNextSendTimeResponse instance
                     */
                    UpdateNextSendTimeResponse.create = function create(properties) {
                        return new UpdateNextSendTimeResponse(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateNextSendTimeResponse message. Does not implicitly {@link palexy.streaming.v1.UpdateNextSendTimeResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeResponse
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNextSendTimeResponse} message UpdateNextSendTimeResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateNextSendTimeResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.nextSendTime != null && Object.hasOwnProperty.call(message, "nextSendTime"))
                            $root.google.protobuf.Timestamp.encode(message.nextSendTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateNextSendTimeResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateNextSendTimeResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeResponse
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNextSendTimeResponse} message UpdateNextSendTimeResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateNextSendTimeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateNextSendTimeResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateNextSendTimeResponse} UpdateNextSendTimeResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateNextSendTimeResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateNextSendTimeResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.nextSendTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateNextSendTimeResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateNextSendTimeResponse} UpdateNextSendTimeResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateNextSendTimeResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateNextSendTimeResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateNextSendTimeResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.nextSendTime != null && message.hasOwnProperty("nextSendTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.nextSendTime);
                            if (error)
                                return "nextSendTime." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateNextSendTimeResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateNextSendTimeResponse} UpdateNextSendTimeResponse
                     */
                    UpdateNextSendTimeResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateNextSendTimeResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateNextSendTimeResponse();
                        if (object.nextSendTime != null) {
                            if (typeof object.nextSendTime !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateNextSendTimeResponse.nextSendTime: object expected");
                            message.nextSendTime = $root.google.protobuf.Timestamp.fromObject(object.nextSendTime);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateNextSendTimeResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeResponse
                     * @static
                     * @param {palexy.streaming.v1.UpdateNextSendTimeResponse} message UpdateNextSendTimeResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateNextSendTimeResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.nextSendTime = null;
                        if (message.nextSendTime != null && message.hasOwnProperty("nextSendTime"))
                            object.nextSendTime = $root.google.protobuf.Timestamp.toObject(message.nextSendTime, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateNextSendTimeResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateNextSendTimeResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateNextSendTimeResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateNextSendTimeResponse;
                })();
    
                v1.ListReportScheduleRequest = (function() {
    
                    /**
                     * Properties of a ListReportScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListReportScheduleRequest
                     * @property {palexy.streaming.v1.ListReportScheduleRequest.IListReportScheduleFilter|null} [filter] ListReportScheduleRequest filter
                     * @property {string|null} [pageToken] ListReportScheduleRequest pageToken
                     * @property {number|null} [pageSize] ListReportScheduleRequest pageSize
                     * @property {boolean|null} [includeReceivers] ListReportScheduleRequest includeReceivers
                     * @property {boolean|null} [includeLatestReportDateRange] ListReportScheduleRequest includeLatestReportDateRange
                     */
    
                    /**
                     * Constructs a new ListReportScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListReportScheduleRequest.
                     * @implements IListReportScheduleRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListReportScheduleRequest=} [properties] Properties to set
                     */
                    function ListReportScheduleRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListReportScheduleRequest filter.
                     * @member {palexy.streaming.v1.ListReportScheduleRequest.IListReportScheduleFilter|null|undefined} filter
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @instance
                     */
                    ListReportScheduleRequest.prototype.filter = null;
    
                    /**
                     * ListReportScheduleRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @instance
                     */
                    ListReportScheduleRequest.prototype.pageToken = "";
    
                    /**
                     * ListReportScheduleRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @instance
                     */
                    ListReportScheduleRequest.prototype.pageSize = 0;
    
                    /**
                     * ListReportScheduleRequest includeReceivers.
                     * @member {boolean} includeReceivers
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @instance
                     */
                    ListReportScheduleRequest.prototype.includeReceivers = false;
    
                    /**
                     * ListReportScheduleRequest includeLatestReportDateRange.
                     * @member {boolean} includeLatestReportDateRange
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @instance
                     */
                    ListReportScheduleRequest.prototype.includeLatestReportDateRange = false;
    
                    /**
                     * Creates a new ListReportScheduleRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListReportScheduleRequest} ListReportScheduleRequest instance
                     */
                    ListReportScheduleRequest.create = function create(properties) {
                        return new ListReportScheduleRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListReportScheduleRequest message. Does not implicitly {@link palexy.streaming.v1.ListReportScheduleRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleRequest} message ListReportScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListReportScheduleRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                            $root.palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter.encode(message.filter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pageToken);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
                        if (message.includeReceivers != null && Object.hasOwnProperty.call(message, "includeReceivers"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeReceivers);
                        if (message.includeLatestReportDateRange != null && Object.hasOwnProperty.call(message, "includeLatestReportDateRange"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.includeLatestReportDateRange);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListReportScheduleRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListReportScheduleRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleRequest} message ListReportScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListReportScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListReportScheduleRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListReportScheduleRequest} ListReportScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListReportScheduleRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListReportScheduleRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.filter = $root.palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.pageToken = reader.string();
                                break;
                            case 3:
                                message.pageSize = reader.int32();
                                break;
                            case 4:
                                message.includeReceivers = reader.bool();
                                break;
                            case 6:
                                message.includeLatestReportDateRange = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListReportScheduleRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListReportScheduleRequest} ListReportScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListReportScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListReportScheduleRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListReportScheduleRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.filter != null && message.hasOwnProperty("filter")) {
                            var error = $root.palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter.verify(message.filter);
                            if (error)
                                return "filter." + error;
                        }
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.includeReceivers != null && message.hasOwnProperty("includeReceivers"))
                            if (typeof message.includeReceivers !== "boolean")
                                return "includeReceivers: boolean expected";
                        if (message.includeLatestReportDateRange != null && message.hasOwnProperty("includeLatestReportDateRange"))
                            if (typeof message.includeLatestReportDateRange !== "boolean")
                                return "includeLatestReportDateRange: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListReportScheduleRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListReportScheduleRequest} ListReportScheduleRequest
                     */
                    ListReportScheduleRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListReportScheduleRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListReportScheduleRequest();
                        if (object.filter != null) {
                            if (typeof object.filter !== "object")
                                throw TypeError(".palexy.streaming.v1.ListReportScheduleRequest.filter: object expected");
                            message.filter = $root.palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter.fromObject(object.filter);
                        }
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.includeReceivers != null)
                            message.includeReceivers = Boolean(object.includeReceivers);
                        if (object.includeLatestReportDateRange != null)
                            message.includeLatestReportDateRange = Boolean(object.includeLatestReportDateRange);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListReportScheduleRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.ListReportScheduleRequest} message ListReportScheduleRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListReportScheduleRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.filter = null;
                            object.pageToken = "";
                            object.pageSize = 0;
                            object.includeReceivers = false;
                            object.includeLatestReportDateRange = false;
                        }
                        if (message.filter != null && message.hasOwnProperty("filter"))
                            object.filter = $root.palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter.toObject(message.filter, options);
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.includeReceivers != null && message.hasOwnProperty("includeReceivers"))
                            object.includeReceivers = message.includeReceivers;
                        if (message.includeLatestReportDateRange != null && message.hasOwnProperty("includeLatestReportDateRange"))
                            object.includeLatestReportDateRange = message.includeLatestReportDateRange;
                        return object;
                    };
    
                    /**
                     * Converts this ListReportScheduleRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListReportScheduleRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListReportScheduleRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    ListReportScheduleRequest.ListReportScheduleFilter = (function() {
    
                        /**
                         * Properties of a ListReportScheduleFilter.
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest
                         * @interface IListReportScheduleFilter
                         * @property {number|null} [companyId] ListReportScheduleFilter companyId
                         * @property {string|null} [name] ListReportScheduleFilter name
                         * @property {Array.<palexy.streaming.v1.ReportSchedule.ScheduleType>|null} [scheduleTypes] ListReportScheduleFilter scheduleTypes
                         * @property {google.protobuf.IBoolValue|null} [overdue] ListReportScheduleFilter overdue
                         * @property {Array.<number>|null} [scheduleIds] ListReportScheduleFilter scheduleIds
                         */
    
                        /**
                         * Constructs a new ListReportScheduleFilter.
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest
                         * @classdesc Represents a ListReportScheduleFilter.
                         * @implements IListReportScheduleFilter
                         * @constructor
                         * @param {palexy.streaming.v1.ListReportScheduleRequest.IListReportScheduleFilter=} [properties] Properties to set
                         */
                        function ListReportScheduleFilter(properties) {
                            this.scheduleTypes = [];
                            this.scheduleIds = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * ListReportScheduleFilter companyId.
                         * @member {number} companyId
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @instance
                         */
                        ListReportScheduleFilter.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * ListReportScheduleFilter name.
                         * @member {string} name
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @instance
                         */
                        ListReportScheduleFilter.prototype.name = "";
    
                        /**
                         * ListReportScheduleFilter scheduleTypes.
                         * @member {Array.<palexy.streaming.v1.ReportSchedule.ScheduleType>} scheduleTypes
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @instance
                         */
                        ListReportScheduleFilter.prototype.scheduleTypes = $util.emptyArray;
    
                        /**
                         * ListReportScheduleFilter overdue.
                         * @member {google.protobuf.IBoolValue|null|undefined} overdue
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @instance
                         */
                        ListReportScheduleFilter.prototype.overdue = null;
    
                        /**
                         * ListReportScheduleFilter scheduleIds.
                         * @member {Array.<number>} scheduleIds
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @instance
                         */
                        ListReportScheduleFilter.prototype.scheduleIds = $util.emptyArray;
    
                        /**
                         * Creates a new ListReportScheduleFilter instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @static
                         * @param {palexy.streaming.v1.ListReportScheduleRequest.IListReportScheduleFilter=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter} ListReportScheduleFilter instance
                         */
                        ListReportScheduleFilter.create = function create(properties) {
                            return new ListReportScheduleFilter(properties);
                        };
    
                        /**
                         * Encodes the specified ListReportScheduleFilter message. Does not implicitly {@link palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @static
                         * @param {palexy.streaming.v1.ListReportScheduleRequest.IListReportScheduleFilter} message ListReportScheduleFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ListReportScheduleFilter.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                            if (message.scheduleTypes != null && message.scheduleTypes.length) {
                                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                                for (var i = 0; i < message.scheduleTypes.length; ++i)
                                    writer.int32(message.scheduleTypes[i]);
                                writer.ldelim();
                            }
                            if (message.overdue != null && Object.hasOwnProperty.call(message, "overdue"))
                                $root.google.protobuf.BoolValue.encode(message.overdue, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.scheduleIds != null && message.scheduleIds.length) {
                                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                                for (var i = 0; i < message.scheduleIds.length; ++i)
                                    writer.int64(message.scheduleIds[i]);
                                writer.ldelim();
                            }
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified ListReportScheduleFilter message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @static
                         * @param {palexy.streaming.v1.ListReportScheduleRequest.IListReportScheduleFilter} message ListReportScheduleFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ListReportScheduleFilter.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a ListReportScheduleFilter message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter} ListReportScheduleFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ListReportScheduleFilter.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.companyId = reader.int64();
                                    break;
                                case 5:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    if (!(message.scheduleTypes && message.scheduleTypes.length))
                                        message.scheduleTypes = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.scheduleTypes.push(reader.int32());
                                    } else
                                        message.scheduleTypes.push(reader.int32());
                                    break;
                                case 3:
                                    message.overdue = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    if (!(message.scheduleIds && message.scheduleIds.length))
                                        message.scheduleIds = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.scheduleIds.push(reader.int64());
                                    } else
                                        message.scheduleIds.push(reader.int64());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a ListReportScheduleFilter message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter} ListReportScheduleFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ListReportScheduleFilter.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a ListReportScheduleFilter message.
                         * @function verify
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ListReportScheduleFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.companyId != null && message.hasOwnProperty("companyId"))
                                if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                    return "companyId: integer|Long expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.scheduleTypes != null && message.hasOwnProperty("scheduleTypes")) {
                                if (!Array.isArray(message.scheduleTypes))
                                    return "scheduleTypes: array expected";
                                for (var i = 0; i < message.scheduleTypes.length; ++i)
                                    switch (message.scheduleTypes[i]) {
                                    default:
                                        return "scheduleTypes: enum value[] expected";
                                    case 0:
                                    case 1:
                                    case 2:
                                        break;
                                    }
                            }
                            if (message.overdue != null && message.hasOwnProperty("overdue")) {
                                var error = $root.google.protobuf.BoolValue.verify(message.overdue);
                                if (error)
                                    return "overdue." + error;
                            }
                            if (message.scheduleIds != null && message.hasOwnProperty("scheduleIds")) {
                                if (!Array.isArray(message.scheduleIds))
                                    return "scheduleIds: array expected";
                                for (var i = 0; i < message.scheduleIds.length; ++i)
                                    if (!$util.isInteger(message.scheduleIds[i]) && !(message.scheduleIds[i] && $util.isInteger(message.scheduleIds[i].low) && $util.isInteger(message.scheduleIds[i].high)))
                                        return "scheduleIds: integer|Long[] expected";
                            }
                            return null;
                        };
    
                        /**
                         * Creates a ListReportScheduleFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter} ListReportScheduleFilter
                         */
                        ListReportScheduleFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter)
                                return object;
                            var message = new $root.palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter();
                            if (object.companyId != null)
                                if ($util.Long)
                                    (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                                else if (typeof object.companyId === "string")
                                    message.companyId = parseInt(object.companyId, 10);
                                else if (typeof object.companyId === "number")
                                    message.companyId = object.companyId;
                                else if (typeof object.companyId === "object")
                                    message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.scheduleTypes) {
                                if (!Array.isArray(object.scheduleTypes))
                                    throw TypeError(".palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter.scheduleTypes: array expected");
                                message.scheduleTypes = [];
                                for (var i = 0; i < object.scheduleTypes.length; ++i)
                                    switch (object.scheduleTypes[i]) {
                                    default:
                                    case "UNKNOWN":
                                    case 0:
                                        message.scheduleTypes[i] = 0;
                                        break;
                                    case "WEEKLY":
                                    case 1:
                                        message.scheduleTypes[i] = 1;
                                        break;
                                    case "MONTHLY":
                                    case 2:
                                        message.scheduleTypes[i] = 2;
                                        break;
                                    }
                            }
                            if (object.overdue != null) {
                                if (typeof object.overdue !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter.overdue: object expected");
                                message.overdue = $root.google.protobuf.BoolValue.fromObject(object.overdue);
                            }
                            if (object.scheduleIds) {
                                if (!Array.isArray(object.scheduleIds))
                                    throw TypeError(".palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter.scheduleIds: array expected");
                                message.scheduleIds = [];
                                for (var i = 0; i < object.scheduleIds.length; ++i)
                                    if ($util.Long)
                                        (message.scheduleIds[i] = $util.Long.fromValue(object.scheduleIds[i])).unsigned = false;
                                    else if (typeof object.scheduleIds[i] === "string")
                                        message.scheduleIds[i] = parseInt(object.scheduleIds[i], 10);
                                    else if (typeof object.scheduleIds[i] === "number")
                                        message.scheduleIds[i] = object.scheduleIds[i];
                                    else if (typeof object.scheduleIds[i] === "object")
                                        message.scheduleIds[i] = new $util.LongBits(object.scheduleIds[i].low >>> 0, object.scheduleIds[i].high >>> 0).toNumber();
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a ListReportScheduleFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @static
                         * @param {palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter} message ListReportScheduleFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ListReportScheduleFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.scheduleTypes = [];
                                object.scheduleIds = [];
                            }
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.companyId = options.longs === String ? "0" : 0;
                                object.overdue = null;
                                object.name = "";
                            }
                            if (message.companyId != null && message.hasOwnProperty("companyId"))
                                if (typeof message.companyId === "number")
                                    object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                                else
                                    object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                            if (message.scheduleTypes && message.scheduleTypes.length) {
                                object.scheduleTypes = [];
                                for (var j = 0; j < message.scheduleTypes.length; ++j)
                                    object.scheduleTypes[j] = options.enums === String ? $root.palexy.streaming.v1.ReportSchedule.ScheduleType[message.scheduleTypes[j]] : message.scheduleTypes[j];
                            }
                            if (message.overdue != null && message.hasOwnProperty("overdue"))
                                object.overdue = $root.google.protobuf.BoolValue.toObject(message.overdue, options);
                            if (message.scheduleIds && message.scheduleIds.length) {
                                object.scheduleIds = [];
                                for (var j = 0; j < message.scheduleIds.length; ++j)
                                    if (typeof message.scheduleIds[j] === "number")
                                        object.scheduleIds[j] = options.longs === String ? String(message.scheduleIds[j]) : message.scheduleIds[j];
                                    else
                                        object.scheduleIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.scheduleIds[j]) : options.longs === Number ? new $util.LongBits(message.scheduleIds[j].low >>> 0, message.scheduleIds[j].high >>> 0).toNumber() : message.scheduleIds[j];
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            return object;
                        };
    
                        /**
                         * Converts this ListReportScheduleFilter to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.ListReportScheduleRequest.ListReportScheduleFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ListReportScheduleFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return ListReportScheduleFilter;
                    })();
    
                    return ListReportScheduleRequest;
                })();
    
                v1.ListReportScheduleResponse = (function() {
    
                    /**
                     * Properties of a ListReportScheduleResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListReportScheduleResponse
                     * @property {Array.<palexy.streaming.v1.IReportSchedule>|null} [schedules] ListReportScheduleResponse schedules
                     * @property {number|null} [totalElements] ListReportScheduleResponse totalElements
                     * @property {string|null} [nextPageToken] ListReportScheduleResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListReportScheduleResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListReportScheduleResponse.
                     * @implements IListReportScheduleResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListReportScheduleResponse=} [properties] Properties to set
                     */
                    function ListReportScheduleResponse(properties) {
                        this.schedules = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListReportScheduleResponse schedules.
                     * @member {Array.<palexy.streaming.v1.IReportSchedule>} schedules
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @instance
                     */
                    ListReportScheduleResponse.prototype.schedules = $util.emptyArray;
    
                    /**
                     * ListReportScheduleResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @instance
                     */
                    ListReportScheduleResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListReportScheduleResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @instance
                     */
                    ListReportScheduleResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListReportScheduleResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListReportScheduleResponse} ListReportScheduleResponse instance
                     */
                    ListReportScheduleResponse.create = function create(properties) {
                        return new ListReportScheduleResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListReportScheduleResponse message. Does not implicitly {@link palexy.streaming.v1.ListReportScheduleResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleResponse} message ListReportScheduleResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListReportScheduleResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.schedules != null && message.schedules.length)
                            for (var i = 0; i < message.schedules.length; ++i)
                                $root.palexy.streaming.v1.ReportSchedule.encode(message.schedules[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListReportScheduleResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListReportScheduleResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @static
                     * @param {palexy.streaming.v1.IListReportScheduleResponse} message ListReportScheduleResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListReportScheduleResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListReportScheduleResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListReportScheduleResponse} ListReportScheduleResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListReportScheduleResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListReportScheduleResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.schedules && message.schedules.length))
                                    message.schedules = [];
                                message.schedules.push($root.palexy.streaming.v1.ReportSchedule.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int64();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListReportScheduleResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListReportScheduleResponse} ListReportScheduleResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListReportScheduleResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListReportScheduleResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListReportScheduleResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.schedules != null && message.hasOwnProperty("schedules")) {
                            if (!Array.isArray(message.schedules))
                                return "schedules: array expected";
                            for (var i = 0; i < message.schedules.length; ++i) {
                                var error = $root.palexy.streaming.v1.ReportSchedule.verify(message.schedules[i]);
                                if (error)
                                    return "schedules." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListReportScheduleResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListReportScheduleResponse} ListReportScheduleResponse
                     */
                    ListReportScheduleResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListReportScheduleResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListReportScheduleResponse();
                        if (object.schedules) {
                            if (!Array.isArray(object.schedules))
                                throw TypeError(".palexy.streaming.v1.ListReportScheduleResponse.schedules: array expected");
                            message.schedules = [];
                            for (var i = 0; i < object.schedules.length; ++i) {
                                if (typeof object.schedules[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListReportScheduleResponse.schedules: object expected");
                                message.schedules[i] = $root.palexy.streaming.v1.ReportSchedule.fromObject(object.schedules[i]);
                            }
                        }
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListReportScheduleResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @static
                     * @param {palexy.streaming.v1.ListReportScheduleResponse} message ListReportScheduleResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListReportScheduleResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.schedules = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                            object.nextPageToken = "";
                        }
                        if (message.schedules && message.schedules.length) {
                            object.schedules = [];
                            for (var j = 0; j < message.schedules.length; ++j)
                                object.schedules[j] = $root.palexy.streaming.v1.ReportSchedule.toObject(message.schedules[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListReportScheduleResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListReportScheduleResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListReportScheduleResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListReportScheduleResponse;
                })();
    
                v1.CreateReportScheduleRequest = (function() {
    
                    /**
                     * Properties of a CreateReportScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateReportScheduleRequest
                     * @property {palexy.streaming.v1.IReportSchedule|null} [schedule] CreateReportScheduleRequest schedule
                     */
    
                    /**
                     * Constructs a new CreateReportScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateReportScheduleRequest.
                     * @implements ICreateReportScheduleRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateReportScheduleRequest=} [properties] Properties to set
                     */
                    function CreateReportScheduleRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateReportScheduleRequest schedule.
                     * @member {palexy.streaming.v1.IReportSchedule|null|undefined} schedule
                     * @memberof palexy.streaming.v1.CreateReportScheduleRequest
                     * @instance
                     */
                    CreateReportScheduleRequest.prototype.schedule = null;
    
                    /**
                     * Creates a new CreateReportScheduleRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateReportScheduleRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateReportScheduleRequest} CreateReportScheduleRequest instance
                     */
                    CreateReportScheduleRequest.create = function create(properties) {
                        return new CreateReportScheduleRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateReportScheduleRequest message. Does not implicitly {@link palexy.streaming.v1.CreateReportScheduleRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateReportScheduleRequest} message CreateReportScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateReportScheduleRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.schedule != null && Object.hasOwnProperty.call(message, "schedule"))
                            $root.palexy.streaming.v1.ReportSchedule.encode(message.schedule, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateReportScheduleRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateReportScheduleRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateReportScheduleRequest} message CreateReportScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateReportScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateReportScheduleRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateReportScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateReportScheduleRequest} CreateReportScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateReportScheduleRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateReportScheduleRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.schedule = $root.palexy.streaming.v1.ReportSchedule.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateReportScheduleRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateReportScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateReportScheduleRequest} CreateReportScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateReportScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateReportScheduleRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateReportScheduleRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateReportScheduleRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.schedule != null && message.hasOwnProperty("schedule")) {
                            var error = $root.palexy.streaming.v1.ReportSchedule.verify(message.schedule);
                            if (error)
                                return "schedule." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateReportScheduleRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateReportScheduleRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateReportScheduleRequest} CreateReportScheduleRequest
                     */
                    CreateReportScheduleRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateReportScheduleRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateReportScheduleRequest();
                        if (object.schedule != null) {
                            if (typeof object.schedule !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateReportScheduleRequest.schedule: object expected");
                            message.schedule = $root.palexy.streaming.v1.ReportSchedule.fromObject(object.schedule);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateReportScheduleRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateReportScheduleRequest} message CreateReportScheduleRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateReportScheduleRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.schedule = null;
                        if (message.schedule != null && message.hasOwnProperty("schedule"))
                            object.schedule = $root.palexy.streaming.v1.ReportSchedule.toObject(message.schedule, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateReportScheduleRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateReportScheduleRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateReportScheduleRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateReportScheduleRequest;
                })();
    
                v1.GetReportScheduleRequest = (function() {
    
                    /**
                     * Properties of a GetReportScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetReportScheduleRequest
                     * @property {number|null} [id] GetReportScheduleRequest id
                     * @property {boolean|null} [includeReceivers] GetReportScheduleRequest includeReceivers
                     */
    
                    /**
                     * Constructs a new GetReportScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetReportScheduleRequest.
                     * @implements IGetReportScheduleRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetReportScheduleRequest=} [properties] Properties to set
                     */
                    function GetReportScheduleRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetReportScheduleRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.GetReportScheduleRequest
                     * @instance
                     */
                    GetReportScheduleRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetReportScheduleRequest includeReceivers.
                     * @member {boolean} includeReceivers
                     * @memberof palexy.streaming.v1.GetReportScheduleRequest
                     * @instance
                     */
                    GetReportScheduleRequest.prototype.includeReceivers = false;
    
                    /**
                     * Creates a new GetReportScheduleRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetReportScheduleRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetReportScheduleRequest} GetReportScheduleRequest instance
                     */
                    GetReportScheduleRequest.create = function create(properties) {
                        return new GetReportScheduleRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetReportScheduleRequest message. Does not implicitly {@link palexy.streaming.v1.GetReportScheduleRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetReportScheduleRequest} message GetReportScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetReportScheduleRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.includeReceivers != null && Object.hasOwnProperty.call(message, "includeReceivers"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeReceivers);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetReportScheduleRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetReportScheduleRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetReportScheduleRequest} message GetReportScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetReportScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetReportScheduleRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetReportScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetReportScheduleRequest} GetReportScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetReportScheduleRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetReportScheduleRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.includeReceivers = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetReportScheduleRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetReportScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetReportScheduleRequest} GetReportScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetReportScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetReportScheduleRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetReportScheduleRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetReportScheduleRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.includeReceivers != null && message.hasOwnProperty("includeReceivers"))
                            if (typeof message.includeReceivers !== "boolean")
                                return "includeReceivers: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetReportScheduleRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetReportScheduleRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetReportScheduleRequest} GetReportScheduleRequest
                     */
                    GetReportScheduleRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetReportScheduleRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetReportScheduleRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.includeReceivers != null)
                            message.includeReceivers = Boolean(object.includeReceivers);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetReportScheduleRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.GetReportScheduleRequest} message GetReportScheduleRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetReportScheduleRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.includeReceivers = false;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.includeReceivers != null && message.hasOwnProperty("includeReceivers"))
                            object.includeReceivers = message.includeReceivers;
                        return object;
                    };
    
                    /**
                     * Converts this GetReportScheduleRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetReportScheduleRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetReportScheduleRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetReportScheduleRequest;
                })();
    
                v1.UpdateReportScheduleRequest = (function() {
    
                    /**
                     * Properties of an UpdateReportScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateReportScheduleRequest
                     * @property {number|null} [id] UpdateReportScheduleRequest id
                     * @property {palexy.streaming.v1.IReportSchedule|null} [schedule] UpdateReportScheduleRequest schedule
                     */
    
                    /**
                     * Constructs a new UpdateReportScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateReportScheduleRequest.
                     * @implements IUpdateReportScheduleRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateReportScheduleRequest=} [properties] Properties to set
                     */
                    function UpdateReportScheduleRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateReportScheduleRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.UpdateReportScheduleRequest
                     * @instance
                     */
                    UpdateReportScheduleRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * UpdateReportScheduleRequest schedule.
                     * @member {palexy.streaming.v1.IReportSchedule|null|undefined} schedule
                     * @memberof palexy.streaming.v1.UpdateReportScheduleRequest
                     * @instance
                     */
                    UpdateReportScheduleRequest.prototype.schedule = null;
    
                    /**
                     * Creates a new UpdateReportScheduleRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateReportScheduleRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateReportScheduleRequest} UpdateReportScheduleRequest instance
                     */
                    UpdateReportScheduleRequest.create = function create(properties) {
                        return new UpdateReportScheduleRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateReportScheduleRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateReportScheduleRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateReportScheduleRequest} message UpdateReportScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateReportScheduleRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.schedule != null && Object.hasOwnProperty.call(message, "schedule"))
                            $root.palexy.streaming.v1.ReportSchedule.encode(message.schedule, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateReportScheduleRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateReportScheduleRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateReportScheduleRequest} message UpdateReportScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateReportScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateReportScheduleRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateReportScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateReportScheduleRequest} UpdateReportScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateReportScheduleRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateReportScheduleRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.schedule = $root.palexy.streaming.v1.ReportSchedule.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateReportScheduleRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateReportScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateReportScheduleRequest} UpdateReportScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateReportScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateReportScheduleRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateReportScheduleRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateReportScheduleRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.schedule != null && message.hasOwnProperty("schedule")) {
                            var error = $root.palexy.streaming.v1.ReportSchedule.verify(message.schedule);
                            if (error)
                                return "schedule." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateReportScheduleRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateReportScheduleRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateReportScheduleRequest} UpdateReportScheduleRequest
                     */
                    UpdateReportScheduleRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateReportScheduleRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateReportScheduleRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.schedule != null) {
                            if (typeof object.schedule !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateReportScheduleRequest.schedule: object expected");
                            message.schedule = $root.palexy.streaming.v1.ReportSchedule.fromObject(object.schedule);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateReportScheduleRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateReportScheduleRequest} message UpdateReportScheduleRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateReportScheduleRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.schedule = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.schedule != null && message.hasOwnProperty("schedule"))
                            object.schedule = $root.palexy.streaming.v1.ReportSchedule.toObject(message.schedule, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateReportScheduleRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateReportScheduleRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateReportScheduleRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateReportScheduleRequest;
                })();
    
                v1.DeleteReportScheduleRequest = (function() {
    
                    /**
                     * Properties of a DeleteReportScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteReportScheduleRequest
                     * @property {number|null} [id] DeleteReportScheduleRequest id
                     */
    
                    /**
                     * Constructs a new DeleteReportScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteReportScheduleRequest.
                     * @implements IDeleteReportScheduleRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteReportScheduleRequest=} [properties] Properties to set
                     */
                    function DeleteReportScheduleRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteReportScheduleRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.DeleteReportScheduleRequest
                     * @instance
                     */
                    DeleteReportScheduleRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteReportScheduleRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteReportScheduleRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteReportScheduleRequest} DeleteReportScheduleRequest instance
                     */
                    DeleteReportScheduleRequest.create = function create(properties) {
                        return new DeleteReportScheduleRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteReportScheduleRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteReportScheduleRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteReportScheduleRequest} message DeleteReportScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteReportScheduleRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteReportScheduleRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteReportScheduleRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteReportScheduleRequest} message DeleteReportScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteReportScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteReportScheduleRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteReportScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteReportScheduleRequest} DeleteReportScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteReportScheduleRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteReportScheduleRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteReportScheduleRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteReportScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteReportScheduleRequest} DeleteReportScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteReportScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteReportScheduleRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteReportScheduleRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteReportScheduleRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteReportScheduleRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteReportScheduleRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteReportScheduleRequest} DeleteReportScheduleRequest
                     */
                    DeleteReportScheduleRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteReportScheduleRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteReportScheduleRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteReportScheduleRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteReportScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteReportScheduleRequest} message DeleteReportScheduleRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteReportScheduleRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteReportScheduleRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteReportScheduleRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteReportScheduleRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteReportScheduleRequest;
                })();
    
                v1.SendOutReportRequest = (function() {
    
                    /**
                     * Properties of a SendOutReportRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ISendOutReportRequest
                     * @property {number|null} [scheduleId] SendOutReportRequest scheduleId
                     * @property {Array.<string>|null} [emails] SendOutReportRequest emails
                     */
    
                    /**
                     * Constructs a new SendOutReportRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SendOutReportRequest.
                     * @implements ISendOutReportRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ISendOutReportRequest=} [properties] Properties to set
                     */
                    function SendOutReportRequest(properties) {
                        this.emails = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SendOutReportRequest scheduleId.
                     * @member {number} scheduleId
                     * @memberof palexy.streaming.v1.SendOutReportRequest
                     * @instance
                     */
                    SendOutReportRequest.prototype.scheduleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * SendOutReportRequest emails.
                     * @member {Array.<string>} emails
                     * @memberof palexy.streaming.v1.SendOutReportRequest
                     * @instance
                     */
                    SendOutReportRequest.prototype.emails = $util.emptyArray;
    
                    /**
                     * Creates a new SendOutReportRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SendOutReportRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendOutReportRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SendOutReportRequest} SendOutReportRequest instance
                     */
                    SendOutReportRequest.create = function create(properties) {
                        return new SendOutReportRequest(properties);
                    };
    
                    /**
                     * Encodes the specified SendOutReportRequest message. Does not implicitly {@link palexy.streaming.v1.SendOutReportRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SendOutReportRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendOutReportRequest} message SendOutReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendOutReportRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.scheduleId);
                        if (message.emails != null && message.emails.length)
                            for (var i = 0; i < message.emails.length; ++i)
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.emails[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SendOutReportRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.SendOutReportRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SendOutReportRequest
                     * @static
                     * @param {palexy.streaming.v1.ISendOutReportRequest} message SendOutReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SendOutReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SendOutReportRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SendOutReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SendOutReportRequest} SendOutReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendOutReportRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SendOutReportRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.scheduleId = reader.int64();
                                break;
                            case 3:
                                if (!(message.emails && message.emails.length))
                                    message.emails = [];
                                message.emails.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SendOutReportRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SendOutReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SendOutReportRequest} SendOutReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SendOutReportRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SendOutReportRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SendOutReportRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SendOutReportRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                            if (!$util.isInteger(message.scheduleId) && !(message.scheduleId && $util.isInteger(message.scheduleId.low) && $util.isInteger(message.scheduleId.high)))
                                return "scheduleId: integer|Long expected";
                        if (message.emails != null && message.hasOwnProperty("emails")) {
                            if (!Array.isArray(message.emails))
                                return "emails: array expected";
                            for (var i = 0; i < message.emails.length; ++i)
                                if (!$util.isString(message.emails[i]))
                                    return "emails: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a SendOutReportRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SendOutReportRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SendOutReportRequest} SendOutReportRequest
                     */
                    SendOutReportRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SendOutReportRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.SendOutReportRequest();
                        if (object.scheduleId != null)
                            if ($util.Long)
                                (message.scheduleId = $util.Long.fromValue(object.scheduleId)).unsigned = false;
                            else if (typeof object.scheduleId === "string")
                                message.scheduleId = parseInt(object.scheduleId, 10);
                            else if (typeof object.scheduleId === "number")
                                message.scheduleId = object.scheduleId;
                            else if (typeof object.scheduleId === "object")
                                message.scheduleId = new $util.LongBits(object.scheduleId.low >>> 0, object.scheduleId.high >>> 0).toNumber();
                        if (object.emails) {
                            if (!Array.isArray(object.emails))
                                throw TypeError(".palexy.streaming.v1.SendOutReportRequest.emails: array expected");
                            message.emails = [];
                            for (var i = 0; i < object.emails.length; ++i)
                                message.emails[i] = String(object.emails[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SendOutReportRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SendOutReportRequest
                     * @static
                     * @param {palexy.streaming.v1.SendOutReportRequest} message SendOutReportRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SendOutReportRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.emails = [];
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.scheduleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.scheduleId = options.longs === String ? "0" : 0;
                        if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                            if (typeof message.scheduleId === "number")
                                object.scheduleId = options.longs === String ? String(message.scheduleId) : message.scheduleId;
                            else
                                object.scheduleId = options.longs === String ? $util.Long.prototype.toString.call(message.scheduleId) : options.longs === Number ? new $util.LongBits(message.scheduleId.low >>> 0, message.scheduleId.high >>> 0).toNumber() : message.scheduleId;
                        if (message.emails && message.emails.length) {
                            object.emails = [];
                            for (var j = 0; j < message.emails.length; ++j)
                                object.emails[j] = message.emails[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this SendOutReportRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SendOutReportRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SendOutReportRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SendOutReportRequest;
                })();
    
                v1.ReportScheduleLog = (function() {
    
                    /**
                     * Properties of a ReportScheduleLog.
                     * @memberof palexy.streaming.v1
                     * @interface IReportScheduleLog
                     * @property {number|null} [id] ReportScheduleLog id
                     * @property {string|null} [email] ReportScheduleLog email
                     * @property {number|null} [scheduleId] ReportScheduleLog scheduleId
                     * @property {google.protobuf.ITimestamp|null} [createTime] ReportScheduleLog createTime
                     * @property {google.protobuf.ITimestamp|null} [lastSentTime] ReportScheduleLog lastSentTime
                     */
    
                    /**
                     * Constructs a new ReportScheduleLog.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ReportScheduleLog.
                     * @implements IReportScheduleLog
                     * @constructor
                     * @param {palexy.streaming.v1.IReportScheduleLog=} [properties] Properties to set
                     */
                    function ReportScheduleLog(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReportScheduleLog id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @instance
                     */
                    ReportScheduleLog.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ReportScheduleLog email.
                     * @member {string} email
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @instance
                     */
                    ReportScheduleLog.prototype.email = "";
    
                    /**
                     * ReportScheduleLog scheduleId.
                     * @member {number} scheduleId
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @instance
                     */
                    ReportScheduleLog.prototype.scheduleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ReportScheduleLog createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @instance
                     */
                    ReportScheduleLog.prototype.createTime = null;
    
                    /**
                     * ReportScheduleLog lastSentTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastSentTime
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @instance
                     */
                    ReportScheduleLog.prototype.lastSentTime = null;
    
                    /**
                     * Creates a new ReportScheduleLog instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @static
                     * @param {palexy.streaming.v1.IReportScheduleLog=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ReportScheduleLog} ReportScheduleLog instance
                     */
                    ReportScheduleLog.create = function create(properties) {
                        return new ReportScheduleLog(properties);
                    };
    
                    /**
                     * Encodes the specified ReportScheduleLog message. Does not implicitly {@link palexy.streaming.v1.ReportScheduleLog.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @static
                     * @param {palexy.streaming.v1.IReportScheduleLog} message ReportScheduleLog message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportScheduleLog.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                        if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.scheduleId);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.lastSentTime != null && Object.hasOwnProperty.call(message, "lastSentTime"))
                            $root.google.protobuf.Timestamp.encode(message.lastSentTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReportScheduleLog message, length delimited. Does not implicitly {@link palexy.streaming.v1.ReportScheduleLog.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @static
                     * @param {palexy.streaming.v1.IReportScheduleLog} message ReportScheduleLog message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportScheduleLog.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReportScheduleLog message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ReportScheduleLog} ReportScheduleLog
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportScheduleLog.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ReportScheduleLog();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.email = reader.string();
                                break;
                            case 3:
                                message.scheduleId = reader.int64();
                                break;
                            case 4:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.lastSentTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReportScheduleLog message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ReportScheduleLog} ReportScheduleLog
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportScheduleLog.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReportScheduleLog message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReportScheduleLog.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                            if (!$util.isInteger(message.scheduleId) && !(message.scheduleId && $util.isInteger(message.scheduleId.low) && $util.isInteger(message.scheduleId.high)))
                                return "scheduleId: integer|Long expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.lastSentTime != null && message.hasOwnProperty("lastSentTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastSentTime);
                            if (error)
                                return "lastSentTime." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ReportScheduleLog message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ReportScheduleLog} ReportScheduleLog
                     */
                    ReportScheduleLog.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ReportScheduleLog)
                            return object;
                        var message = new $root.palexy.streaming.v1.ReportScheduleLog();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.scheduleId != null)
                            if ($util.Long)
                                (message.scheduleId = $util.Long.fromValue(object.scheduleId)).unsigned = false;
                            else if (typeof object.scheduleId === "string")
                                message.scheduleId = parseInt(object.scheduleId, 10);
                            else if (typeof object.scheduleId === "number")
                                message.scheduleId = object.scheduleId;
                            else if (typeof object.scheduleId === "object")
                                message.scheduleId = new $util.LongBits(object.scheduleId.low >>> 0, object.scheduleId.high >>> 0).toNumber();
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.ReportScheduleLog.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.lastSentTime != null) {
                            if (typeof object.lastSentTime !== "object")
                                throw TypeError(".palexy.streaming.v1.ReportScheduleLog.lastSentTime: object expected");
                            message.lastSentTime = $root.google.protobuf.Timestamp.fromObject(object.lastSentTime);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReportScheduleLog message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @static
                     * @param {palexy.streaming.v1.ReportScheduleLog} message ReportScheduleLog
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReportScheduleLog.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.email = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.scheduleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.scheduleId = options.longs === String ? "0" : 0;
                            object.createTime = null;
                            object.lastSentTime = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                            if (typeof message.scheduleId === "number")
                                object.scheduleId = options.longs === String ? String(message.scheduleId) : message.scheduleId;
                            else
                                object.scheduleId = options.longs === String ? $util.Long.prototype.toString.call(message.scheduleId) : options.longs === Number ? new $util.LongBits(message.scheduleId.low >>> 0, message.scheduleId.high >>> 0).toNumber() : message.scheduleId;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.lastSentTime != null && message.hasOwnProperty("lastSentTime"))
                            object.lastSentTime = $root.google.protobuf.Timestamp.toObject(message.lastSentTime, options);
                        return object;
                    };
    
                    /**
                     * Converts this ReportScheduleLog to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ReportScheduleLog
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReportScheduleLog.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ReportScheduleLog;
                })();
    
                v1.ScheduledReport = (function() {
    
                    /**
                     * Properties of a ScheduledReport.
                     * @memberof palexy.streaming.v1
                     * @interface IScheduledReport
                     * @property {palexy.streaming.v1.IDateRange|null} [dateRange] ScheduledReport dateRange
                     * @property {palexy.streaming.v1.ReportSchedule.ScheduleType|null} [type] ScheduledReport type
                     * @property {Array.<palexy.streaming.v1.IScheduledReportWidget>|null} [items] ScheduledReport items
                     * @property {palexy.streaming.v1.ReportSchedule.ReportType|null} [reportType] ScheduledReport reportType
                     */
    
                    /**
                     * Constructs a new ScheduledReport.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ScheduledReport.
                     * @implements IScheduledReport
                     * @constructor
                     * @param {palexy.streaming.v1.IScheduledReport=} [properties] Properties to set
                     */
                    function ScheduledReport(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ScheduledReport dateRange.
                     * @member {palexy.streaming.v1.IDateRange|null|undefined} dateRange
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @instance
                     */
                    ScheduledReport.prototype.dateRange = null;
    
                    /**
                     * ScheduledReport type.
                     * @member {palexy.streaming.v1.ReportSchedule.ScheduleType} type
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @instance
                     */
                    ScheduledReport.prototype.type = 0;
    
                    /**
                     * ScheduledReport items.
                     * @member {Array.<palexy.streaming.v1.IScheduledReportWidget>} items
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @instance
                     */
                    ScheduledReport.prototype.items = $util.emptyArray;
    
                    /**
                     * ScheduledReport reportType.
                     * @member {palexy.streaming.v1.ReportSchedule.ReportType} reportType
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @instance
                     */
                    ScheduledReport.prototype.reportType = 0;
    
                    /**
                     * Creates a new ScheduledReport instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @static
                     * @param {palexy.streaming.v1.IScheduledReport=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ScheduledReport} ScheduledReport instance
                     */
                    ScheduledReport.create = function create(properties) {
                        return new ScheduledReport(properties);
                    };
    
                    /**
                     * Encodes the specified ScheduledReport message. Does not implicitly {@link palexy.streaming.v1.ScheduledReport.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @static
                     * @param {palexy.streaming.v1.IScheduledReport} message ScheduledReport message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScheduledReport.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dateRange != null && Object.hasOwnProperty.call(message, "dateRange"))
                            $root.palexy.streaming.v1.DateRange.encode(message.dateRange, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.palexy.streaming.v1.ScheduledReportWidget.encode(message.items[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.reportType != null && Object.hasOwnProperty.call(message, "reportType"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.reportType);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ScheduledReport message, length delimited. Does not implicitly {@link palexy.streaming.v1.ScheduledReport.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @static
                     * @param {palexy.streaming.v1.IScheduledReport} message ScheduledReport message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScheduledReport.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ScheduledReport message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ScheduledReport} ScheduledReport
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScheduledReport.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ScheduledReport();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dateRange = $root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.type = reader.int32();
                                break;
                            case 3:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.palexy.streaming.v1.ScheduledReportWidget.decode(reader, reader.uint32()));
                                break;
                            case 4:
                                message.reportType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ScheduledReport message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ScheduledReport} ScheduledReport
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScheduledReport.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ScheduledReport message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ScheduledReport.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dateRange != null && message.hasOwnProperty("dateRange")) {
                            var error = $root.palexy.streaming.v1.DateRange.verify(message.dateRange);
                            if (error)
                                return "dateRange." + error;
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.palexy.streaming.v1.ScheduledReportWidget.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        if (message.reportType != null && message.hasOwnProperty("reportType"))
                            switch (message.reportType) {
                            default:
                                return "reportType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a ScheduledReport message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ScheduledReport} ScheduledReport
                     */
                    ScheduledReport.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ScheduledReport)
                            return object;
                        var message = new $root.palexy.streaming.v1.ScheduledReport();
                        if (object.dateRange != null) {
                            if (typeof object.dateRange !== "object")
                                throw TypeError(".palexy.streaming.v1.ScheduledReport.dateRange: object expected");
                            message.dateRange = $root.palexy.streaming.v1.DateRange.fromObject(object.dateRange);
                        }
                        switch (object.type) {
                        case "UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "WEEKLY":
                        case 1:
                            message.type = 1;
                            break;
                        case "MONTHLY":
                        case 2:
                            message.type = 2;
                            break;
                        }
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".palexy.streaming.v1.ScheduledReport.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ScheduledReport.items: object expected");
                                message.items[i] = $root.palexy.streaming.v1.ScheduledReportWidget.fromObject(object.items[i]);
                            }
                        }
                        switch (object.reportType) {
                        case "UNKNOWN_REPORT_TYPE":
                        case 0:
                            message.reportType = 0;
                            break;
                        case "BASIC":
                        case 1:
                            message.reportType = 1;
                            break;
                        case "STORE_EVENTS":
                        case 2:
                            message.reportType = 2;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ScheduledReport message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @static
                     * @param {palexy.streaming.v1.ScheduledReport} message ScheduledReport
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ScheduledReport.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (options.defaults) {
                            object.dateRange = null;
                            object.type = options.enums === String ? "UNKNOWN" : 0;
                            object.reportType = options.enums === String ? "UNKNOWN_REPORT_TYPE" : 0;
                        }
                        if (message.dateRange != null && message.hasOwnProperty("dateRange"))
                            object.dateRange = $root.palexy.streaming.v1.DateRange.toObject(message.dateRange, options);
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.ReportSchedule.ScheduleType[message.type] : message.type;
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.palexy.streaming.v1.ScheduledReportWidget.toObject(message.items[j], options);
                        }
                        if (message.reportType != null && message.hasOwnProperty("reportType"))
                            object.reportType = options.enums === String ? $root.palexy.streaming.v1.ReportSchedule.ReportType[message.reportType] : message.reportType;
                        return object;
                    };
    
                    /**
                     * Converts this ScheduledReport to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ScheduledReport
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ScheduledReport.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ScheduledReport;
                })();
    
                v1.ScheduledReportWidget = (function() {
    
                    /**
                     * Properties of a ScheduledReportWidget.
                     * @memberof palexy.streaming.v1
                     * @interface IScheduledReportWidget
                     * @property {string|null} [widgetId] ScheduledReportWidget widgetId
                     * @property {palexy.streaming.v1.IScheduledReportWidgetData|null} [data] ScheduledReportWidget data
                     * @property {palexy.streaming.v1.IWidgetInfo|null} [widgetInfo] ScheduledReportWidget widgetInfo
                     */
    
                    /**
                     * Constructs a new ScheduledReportWidget.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ScheduledReportWidget.
                     * @implements IScheduledReportWidget
                     * @constructor
                     * @param {palexy.streaming.v1.IScheduledReportWidget=} [properties] Properties to set
                     */
                    function ScheduledReportWidget(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ScheduledReportWidget widgetId.
                     * @member {string} widgetId
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @instance
                     */
                    ScheduledReportWidget.prototype.widgetId = "";
    
                    /**
                     * ScheduledReportWidget data.
                     * @member {palexy.streaming.v1.IScheduledReportWidgetData|null|undefined} data
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @instance
                     */
                    ScheduledReportWidget.prototype.data = null;
    
                    /**
                     * ScheduledReportWidget widgetInfo.
                     * @member {palexy.streaming.v1.IWidgetInfo|null|undefined} widgetInfo
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @instance
                     */
                    ScheduledReportWidget.prototype.widgetInfo = null;
    
                    /**
                     * Creates a new ScheduledReportWidget instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @static
                     * @param {palexy.streaming.v1.IScheduledReportWidget=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ScheduledReportWidget} ScheduledReportWidget instance
                     */
                    ScheduledReportWidget.create = function create(properties) {
                        return new ScheduledReportWidget(properties);
                    };
    
                    /**
                     * Encodes the specified ScheduledReportWidget message. Does not implicitly {@link palexy.streaming.v1.ScheduledReportWidget.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @static
                     * @param {palexy.streaming.v1.IScheduledReportWidget} message ScheduledReportWidget message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScheduledReportWidget.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.widgetId != null && Object.hasOwnProperty.call(message, "widgetId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.widgetId);
                        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                            $root.palexy.streaming.v1.ScheduledReportWidgetData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.widgetInfo != null && Object.hasOwnProperty.call(message, "widgetInfo"))
                            $root.palexy.streaming.v1.WidgetInfo.encode(message.widgetInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ScheduledReportWidget message, length delimited. Does not implicitly {@link palexy.streaming.v1.ScheduledReportWidget.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @static
                     * @param {palexy.streaming.v1.IScheduledReportWidget} message ScheduledReportWidget message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScheduledReportWidget.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ScheduledReportWidget message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ScheduledReportWidget} ScheduledReportWidget
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScheduledReportWidget.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ScheduledReportWidget();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.widgetId = reader.string();
                                break;
                            case 2:
                                message.data = $root.palexy.streaming.v1.ScheduledReportWidgetData.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.widgetInfo = $root.palexy.streaming.v1.WidgetInfo.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ScheduledReportWidget message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ScheduledReportWidget} ScheduledReportWidget
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScheduledReportWidget.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ScheduledReportWidget message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ScheduledReportWidget.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.widgetId != null && message.hasOwnProperty("widgetId"))
                            if (!$util.isString(message.widgetId))
                                return "widgetId: string expected";
                        if (message.data != null && message.hasOwnProperty("data")) {
                            var error = $root.palexy.streaming.v1.ScheduledReportWidgetData.verify(message.data);
                            if (error)
                                return "data." + error;
                        }
                        if (message.widgetInfo != null && message.hasOwnProperty("widgetInfo")) {
                            var error = $root.palexy.streaming.v1.WidgetInfo.verify(message.widgetInfo);
                            if (error)
                                return "widgetInfo." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ScheduledReportWidget message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ScheduledReportWidget} ScheduledReportWidget
                     */
                    ScheduledReportWidget.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ScheduledReportWidget)
                            return object;
                        var message = new $root.palexy.streaming.v1.ScheduledReportWidget();
                        if (object.widgetId != null)
                            message.widgetId = String(object.widgetId);
                        if (object.data != null) {
                            if (typeof object.data !== "object")
                                throw TypeError(".palexy.streaming.v1.ScheduledReportWidget.data: object expected");
                            message.data = $root.palexy.streaming.v1.ScheduledReportWidgetData.fromObject(object.data);
                        }
                        if (object.widgetInfo != null) {
                            if (typeof object.widgetInfo !== "object")
                                throw TypeError(".palexy.streaming.v1.ScheduledReportWidget.widgetInfo: object expected");
                            message.widgetInfo = $root.palexy.streaming.v1.WidgetInfo.fromObject(object.widgetInfo);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ScheduledReportWidget message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @static
                     * @param {palexy.streaming.v1.ScheduledReportWidget} message ScheduledReportWidget
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ScheduledReportWidget.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.widgetId = "";
                            object.data = null;
                            object.widgetInfo = null;
                        }
                        if (message.widgetId != null && message.hasOwnProperty("widgetId"))
                            object.widgetId = message.widgetId;
                        if (message.data != null && message.hasOwnProperty("data"))
                            object.data = $root.palexy.streaming.v1.ScheduledReportWidgetData.toObject(message.data, options);
                        if (message.widgetInfo != null && message.hasOwnProperty("widgetInfo"))
                            object.widgetInfo = $root.palexy.streaming.v1.WidgetInfo.toObject(message.widgetInfo, options);
                        return object;
                    };
    
                    /**
                     * Converts this ScheduledReportWidget to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ScheduledReportWidget
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ScheduledReportWidget.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ScheduledReportWidget;
                })();
    
                v1.ScheduledReportWidgetData = (function() {
    
                    /**
                     * Properties of a ScheduledReportWidgetData.
                     * @memberof palexy.streaming.v1
                     * @interface IScheduledReportWidgetData
                     * @property {palexy.streaming.v1.IReportData|null} [data] ScheduledReportWidgetData data
                     */
    
                    /**
                     * Constructs a new ScheduledReportWidgetData.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ScheduledReportWidgetData.
                     * @implements IScheduledReportWidgetData
                     * @constructor
                     * @param {palexy.streaming.v1.IScheduledReportWidgetData=} [properties] Properties to set
                     */
                    function ScheduledReportWidgetData(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ScheduledReportWidgetData data.
                     * @member {palexy.streaming.v1.IReportData|null|undefined} data
                     * @memberof palexy.streaming.v1.ScheduledReportWidgetData
                     * @instance
                     */
                    ScheduledReportWidgetData.prototype.data = null;
    
                    /**
                     * Creates a new ScheduledReportWidgetData instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ScheduledReportWidgetData
                     * @static
                     * @param {palexy.streaming.v1.IScheduledReportWidgetData=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ScheduledReportWidgetData} ScheduledReportWidgetData instance
                     */
                    ScheduledReportWidgetData.create = function create(properties) {
                        return new ScheduledReportWidgetData(properties);
                    };
    
                    /**
                     * Encodes the specified ScheduledReportWidgetData message. Does not implicitly {@link palexy.streaming.v1.ScheduledReportWidgetData.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ScheduledReportWidgetData
                     * @static
                     * @param {palexy.streaming.v1.IScheduledReportWidgetData} message ScheduledReportWidgetData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScheduledReportWidgetData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                            $root.palexy.streaming.v1.ReportData.encode(message.data, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ScheduledReportWidgetData message, length delimited. Does not implicitly {@link palexy.streaming.v1.ScheduledReportWidgetData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ScheduledReportWidgetData
                     * @static
                     * @param {palexy.streaming.v1.IScheduledReportWidgetData} message ScheduledReportWidgetData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScheduledReportWidgetData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ScheduledReportWidgetData message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ScheduledReportWidgetData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ScheduledReportWidgetData} ScheduledReportWidgetData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScheduledReportWidgetData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ScheduledReportWidgetData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 3:
                                message.data = $root.palexy.streaming.v1.ReportData.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ScheduledReportWidgetData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ScheduledReportWidgetData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ScheduledReportWidgetData} ScheduledReportWidgetData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScheduledReportWidgetData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ScheduledReportWidgetData message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ScheduledReportWidgetData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ScheduledReportWidgetData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.data != null && message.hasOwnProperty("data")) {
                            var error = $root.palexy.streaming.v1.ReportData.verify(message.data);
                            if (error)
                                return "data." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ScheduledReportWidgetData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ScheduledReportWidgetData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ScheduledReportWidgetData} ScheduledReportWidgetData
                     */
                    ScheduledReportWidgetData.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ScheduledReportWidgetData)
                            return object;
                        var message = new $root.palexy.streaming.v1.ScheduledReportWidgetData();
                        if (object.data != null) {
                            if (typeof object.data !== "object")
                                throw TypeError(".palexy.streaming.v1.ScheduledReportWidgetData.data: object expected");
                            message.data = $root.palexy.streaming.v1.ReportData.fromObject(object.data);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ScheduledReportWidgetData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ScheduledReportWidgetData
                     * @static
                     * @param {palexy.streaming.v1.ScheduledReportWidgetData} message ScheduledReportWidgetData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ScheduledReportWidgetData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.data = null;
                        if (message.data != null && message.hasOwnProperty("data"))
                            object.data = $root.palexy.streaming.v1.ReportData.toObject(message.data, options);
                        return object;
                    };
    
                    /**
                     * Converts this ScheduledReportWidgetData to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ScheduledReportWidgetData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ScheduledReportWidgetData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ScheduledReportWidgetData;
                })();
    
                v1.WidgetInfo = (function() {
    
                    /**
                     * Properties of a WidgetInfo.
                     * @memberof palexy.streaming.v1
                     * @interface IWidgetInfo
                     * @property {palexy.streaming.v1.IStoreMetadata|null} [storeMetadata] WidgetInfo storeMetadata
                     */
    
                    /**
                     * Constructs a new WidgetInfo.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a WidgetInfo.
                     * @implements IWidgetInfo
                     * @constructor
                     * @param {palexy.streaming.v1.IWidgetInfo=} [properties] Properties to set
                     */
                    function WidgetInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * WidgetInfo storeMetadata.
                     * @member {palexy.streaming.v1.IStoreMetadata|null|undefined} storeMetadata
                     * @memberof palexy.streaming.v1.WidgetInfo
                     * @instance
                     */
                    WidgetInfo.prototype.storeMetadata = null;
    
                    /**
                     * Creates a new WidgetInfo instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.WidgetInfo
                     * @static
                     * @param {palexy.streaming.v1.IWidgetInfo=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.WidgetInfo} WidgetInfo instance
                     */
                    WidgetInfo.create = function create(properties) {
                        return new WidgetInfo(properties);
                    };
    
                    /**
                     * Encodes the specified WidgetInfo message. Does not implicitly {@link palexy.streaming.v1.WidgetInfo.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.WidgetInfo
                     * @static
                     * @param {palexy.streaming.v1.IWidgetInfo} message WidgetInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WidgetInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeMetadata != null && Object.hasOwnProperty.call(message, "storeMetadata"))
                            $root.palexy.streaming.v1.StoreMetadata.encode(message.storeMetadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified WidgetInfo message, length delimited. Does not implicitly {@link palexy.streaming.v1.WidgetInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.WidgetInfo
                     * @static
                     * @param {palexy.streaming.v1.IWidgetInfo} message WidgetInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WidgetInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a WidgetInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.WidgetInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.WidgetInfo} WidgetInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WidgetInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.WidgetInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeMetadata = $root.palexy.streaming.v1.StoreMetadata.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a WidgetInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.WidgetInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.WidgetInfo} WidgetInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WidgetInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a WidgetInfo message.
                     * @function verify
                     * @memberof palexy.streaming.v1.WidgetInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    WidgetInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeMetadata != null && message.hasOwnProperty("storeMetadata")) {
                            var error = $root.palexy.streaming.v1.StoreMetadata.verify(message.storeMetadata);
                            if (error)
                                return "storeMetadata." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a WidgetInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.WidgetInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.WidgetInfo} WidgetInfo
                     */
                    WidgetInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.WidgetInfo)
                            return object;
                        var message = new $root.palexy.streaming.v1.WidgetInfo();
                        if (object.storeMetadata != null) {
                            if (typeof object.storeMetadata !== "object")
                                throw TypeError(".palexy.streaming.v1.WidgetInfo.storeMetadata: object expected");
                            message.storeMetadata = $root.palexy.streaming.v1.StoreMetadata.fromObject(object.storeMetadata);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a WidgetInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.WidgetInfo
                     * @static
                     * @param {palexy.streaming.v1.WidgetInfo} message WidgetInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    WidgetInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.storeMetadata = null;
                        if (message.storeMetadata != null && message.hasOwnProperty("storeMetadata"))
                            object.storeMetadata = $root.palexy.streaming.v1.StoreMetadata.toObject(message.storeMetadata, options);
                        return object;
                    };
    
                    /**
                     * Converts this WidgetInfo to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.WidgetInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    WidgetInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return WidgetInfo;
                })();
    
                v1.StoreMetadata = (function() {
    
                    /**
                     * Properties of a StoreMetadata.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreMetadata
                     * @property {number|null} [id] StoreMetadata id
                     * @property {string|null} [label] StoreMetadata label
                     * @property {number|null} [dimensionIndex] StoreMetadata dimensionIndex
                     */
    
                    /**
                     * Constructs a new StoreMetadata.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreMetadata.
                     * @implements IStoreMetadata
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreMetadata=} [properties] Properties to set
                     */
                    function StoreMetadata(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreMetadata id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @instance
                     */
                    StoreMetadata.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreMetadata label.
                     * @member {string} label
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @instance
                     */
                    StoreMetadata.prototype.label = "";
    
                    /**
                     * StoreMetadata dimensionIndex.
                     * @member {number} dimensionIndex
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @instance
                     */
                    StoreMetadata.prototype.dimensionIndex = 0;
    
                    /**
                     * Creates a new StoreMetadata instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @static
                     * @param {palexy.streaming.v1.IStoreMetadata=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreMetadata} StoreMetadata instance
                     */
                    StoreMetadata.create = function create(properties) {
                        return new StoreMetadata(properties);
                    };
    
                    /**
                     * Encodes the specified StoreMetadata message. Does not implicitly {@link palexy.streaming.v1.StoreMetadata.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @static
                     * @param {palexy.streaming.v1.IStoreMetadata} message StoreMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreMetadata.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
                        if (message.dimensionIndex != null && Object.hasOwnProperty.call(message, "dimensionIndex"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.dimensionIndex);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreMetadata message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreMetadata.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @static
                     * @param {palexy.streaming.v1.IStoreMetadata} message StoreMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreMetadata message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreMetadata} StoreMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreMetadata.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreMetadata();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.label = reader.string();
                                break;
                            case 3:
                                message.dimensionIndex = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreMetadata message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreMetadata} StoreMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreMetadata.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreMetadata message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreMetadata.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.label != null && message.hasOwnProperty("label"))
                            if (!$util.isString(message.label))
                                return "label: string expected";
                        if (message.dimensionIndex != null && message.hasOwnProperty("dimensionIndex"))
                            if (!$util.isInteger(message.dimensionIndex))
                                return "dimensionIndex: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a StoreMetadata message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreMetadata} StoreMetadata
                     */
                    StoreMetadata.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreMetadata)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreMetadata();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.label != null)
                            message.label = String(object.label);
                        if (object.dimensionIndex != null)
                            message.dimensionIndex = object.dimensionIndex | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreMetadata message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @static
                     * @param {palexy.streaming.v1.StoreMetadata} message StoreMetadata
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreMetadata.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.label = "";
                            object.dimensionIndex = 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.label != null && message.hasOwnProperty("label"))
                            object.label = message.label;
                        if (message.dimensionIndex != null && message.hasOwnProperty("dimensionIndex"))
                            object.dimensionIndex = message.dimensionIndex;
                        return object;
                    };
    
                    /**
                     * Converts this StoreMetadata to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreMetadata
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreMetadata.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StoreMetadata;
                })();
    
                v1.ReportData = (function() {
    
                    /**
                     * Properties of a ReportData.
                     * @memberof palexy.streaming.v1
                     * @interface IReportData
                     * @property {Array.<string>|null} [dates] ReportData dates
                     * @property {Array.<string>|null} [dimensions] ReportData dimensions
                     * @property {Array.<number>|null} [metricTotals] ReportData metricTotals
                     * @property {Array.<palexy.streaming.v1.IReportMetric>|null} [metrics] ReportData metrics
                     * @property {Array.<palexy.streaming.v1.IReportRow>|null} [rows] ReportData rows
                     */
    
                    /**
                     * Constructs a new ReportData.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ReportData.
                     * @implements IReportData
                     * @constructor
                     * @param {palexy.streaming.v1.IReportData=} [properties] Properties to set
                     */
                    function ReportData(properties) {
                        this.dates = [];
                        this.dimensions = [];
                        this.metricTotals = [];
                        this.metrics = [];
                        this.rows = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReportData dates.
                     * @member {Array.<string>} dates
                     * @memberof palexy.streaming.v1.ReportData
                     * @instance
                     */
                    ReportData.prototype.dates = $util.emptyArray;
    
                    /**
                     * ReportData dimensions.
                     * @member {Array.<string>} dimensions
                     * @memberof palexy.streaming.v1.ReportData
                     * @instance
                     */
                    ReportData.prototype.dimensions = $util.emptyArray;
    
                    /**
                     * ReportData metricTotals.
                     * @member {Array.<number>} metricTotals
                     * @memberof palexy.streaming.v1.ReportData
                     * @instance
                     */
                    ReportData.prototype.metricTotals = $util.emptyArray;
    
                    /**
                     * ReportData metrics.
                     * @member {Array.<palexy.streaming.v1.IReportMetric>} metrics
                     * @memberof palexy.streaming.v1.ReportData
                     * @instance
                     */
                    ReportData.prototype.metrics = $util.emptyArray;
    
                    /**
                     * ReportData rows.
                     * @member {Array.<palexy.streaming.v1.IReportRow>} rows
                     * @memberof palexy.streaming.v1.ReportData
                     * @instance
                     */
                    ReportData.prototype.rows = $util.emptyArray;
    
                    /**
                     * Creates a new ReportData instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ReportData
                     * @static
                     * @param {palexy.streaming.v1.IReportData=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ReportData} ReportData instance
                     */
                    ReportData.create = function create(properties) {
                        return new ReportData(properties);
                    };
    
                    /**
                     * Encodes the specified ReportData message. Does not implicitly {@link palexy.streaming.v1.ReportData.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ReportData
                     * @static
                     * @param {palexy.streaming.v1.IReportData} message ReportData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dates != null && message.dates.length)
                            for (var i = 0; i < message.dates.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dates[i]);
                        if (message.dimensions != null && message.dimensions.length)
                            for (var i = 0; i < message.dimensions.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dimensions[i]);
                        if (message.metricTotals != null && message.metricTotals.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.metricTotals.length; ++i)
                                writer.double(message.metricTotals[i]);
                            writer.ldelim();
                        }
                        if (message.metrics != null && message.metrics.length)
                            for (var i = 0; i < message.metrics.length; ++i)
                                $root.palexy.streaming.v1.ReportMetric.encode(message.metrics[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.rows != null && message.rows.length)
                            for (var i = 0; i < message.rows.length; ++i)
                                $root.palexy.streaming.v1.ReportRow.encode(message.rows[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReportData message, length delimited. Does not implicitly {@link palexy.streaming.v1.ReportData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ReportData
                     * @static
                     * @param {palexy.streaming.v1.IReportData} message ReportData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReportData message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ReportData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ReportData} ReportData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ReportData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.dates && message.dates.length))
                                    message.dates = [];
                                message.dates.push(reader.string());
                                break;
                            case 2:
                                if (!(message.dimensions && message.dimensions.length))
                                    message.dimensions = [];
                                message.dimensions.push(reader.string());
                                break;
                            case 3:
                                if (!(message.metricTotals && message.metricTotals.length))
                                    message.metricTotals = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.metricTotals.push(reader.double());
                                } else
                                    message.metricTotals.push(reader.double());
                                break;
                            case 4:
                                if (!(message.metrics && message.metrics.length))
                                    message.metrics = [];
                                message.metrics.push($root.palexy.streaming.v1.ReportMetric.decode(reader, reader.uint32()));
                                break;
                            case 5:
                                if (!(message.rows && message.rows.length))
                                    message.rows = [];
                                message.rows.push($root.palexy.streaming.v1.ReportRow.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReportData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ReportData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ReportData} ReportData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReportData message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ReportData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReportData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dates != null && message.hasOwnProperty("dates")) {
                            if (!Array.isArray(message.dates))
                                return "dates: array expected";
                            for (var i = 0; i < message.dates.length; ++i)
                                if (!$util.isString(message.dates[i]))
                                    return "dates: string[] expected";
                        }
                        if (message.dimensions != null && message.hasOwnProperty("dimensions")) {
                            if (!Array.isArray(message.dimensions))
                                return "dimensions: array expected";
                            for (var i = 0; i < message.dimensions.length; ++i)
                                if (!$util.isString(message.dimensions[i]))
                                    return "dimensions: string[] expected";
                        }
                        if (message.metricTotals != null && message.hasOwnProperty("metricTotals")) {
                            if (!Array.isArray(message.metricTotals))
                                return "metricTotals: array expected";
                            for (var i = 0; i < message.metricTotals.length; ++i)
                                if (typeof message.metricTotals[i] !== "number")
                                    return "metricTotals: number[] expected";
                        }
                        if (message.metrics != null && message.hasOwnProperty("metrics")) {
                            if (!Array.isArray(message.metrics))
                                return "metrics: array expected";
                            for (var i = 0; i < message.metrics.length; ++i) {
                                var error = $root.palexy.streaming.v1.ReportMetric.verify(message.metrics[i]);
                                if (error)
                                    return "metrics." + error;
                            }
                        }
                        if (message.rows != null && message.hasOwnProperty("rows")) {
                            if (!Array.isArray(message.rows))
                                return "rows: array expected";
                            for (var i = 0; i < message.rows.length; ++i) {
                                var error = $root.palexy.streaming.v1.ReportRow.verify(message.rows[i]);
                                if (error)
                                    return "rows." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ReportData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ReportData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ReportData} ReportData
                     */
                    ReportData.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ReportData)
                            return object;
                        var message = new $root.palexy.streaming.v1.ReportData();
                        if (object.dates) {
                            if (!Array.isArray(object.dates))
                                throw TypeError(".palexy.streaming.v1.ReportData.dates: array expected");
                            message.dates = [];
                            for (var i = 0; i < object.dates.length; ++i)
                                message.dates[i] = String(object.dates[i]);
                        }
                        if (object.dimensions) {
                            if (!Array.isArray(object.dimensions))
                                throw TypeError(".palexy.streaming.v1.ReportData.dimensions: array expected");
                            message.dimensions = [];
                            for (var i = 0; i < object.dimensions.length; ++i)
                                message.dimensions[i] = String(object.dimensions[i]);
                        }
                        if (object.metricTotals) {
                            if (!Array.isArray(object.metricTotals))
                                throw TypeError(".palexy.streaming.v1.ReportData.metricTotals: array expected");
                            message.metricTotals = [];
                            for (var i = 0; i < object.metricTotals.length; ++i)
                                message.metricTotals[i] = Number(object.metricTotals[i]);
                        }
                        if (object.metrics) {
                            if (!Array.isArray(object.metrics))
                                throw TypeError(".palexy.streaming.v1.ReportData.metrics: array expected");
                            message.metrics = [];
                            for (var i = 0; i < object.metrics.length; ++i) {
                                if (typeof object.metrics[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ReportData.metrics: object expected");
                                message.metrics[i] = $root.palexy.streaming.v1.ReportMetric.fromObject(object.metrics[i]);
                            }
                        }
                        if (object.rows) {
                            if (!Array.isArray(object.rows))
                                throw TypeError(".palexy.streaming.v1.ReportData.rows: array expected");
                            message.rows = [];
                            for (var i = 0; i < object.rows.length; ++i) {
                                if (typeof object.rows[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ReportData.rows: object expected");
                                message.rows[i] = $root.palexy.streaming.v1.ReportRow.fromObject(object.rows[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReportData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ReportData
                     * @static
                     * @param {palexy.streaming.v1.ReportData} message ReportData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReportData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.dates = [];
                            object.dimensions = [];
                            object.metricTotals = [];
                            object.metrics = [];
                            object.rows = [];
                        }
                        if (message.dates && message.dates.length) {
                            object.dates = [];
                            for (var j = 0; j < message.dates.length; ++j)
                                object.dates[j] = message.dates[j];
                        }
                        if (message.dimensions && message.dimensions.length) {
                            object.dimensions = [];
                            for (var j = 0; j < message.dimensions.length; ++j)
                                object.dimensions[j] = message.dimensions[j];
                        }
                        if (message.metricTotals && message.metricTotals.length) {
                            object.metricTotals = [];
                            for (var j = 0; j < message.metricTotals.length; ++j)
                                object.metricTotals[j] = options.json && !isFinite(message.metricTotals[j]) ? String(message.metricTotals[j]) : message.metricTotals[j];
                        }
                        if (message.metrics && message.metrics.length) {
                            object.metrics = [];
                            for (var j = 0; j < message.metrics.length; ++j)
                                object.metrics[j] = $root.palexy.streaming.v1.ReportMetric.toObject(message.metrics[j], options);
                        }
                        if (message.rows && message.rows.length) {
                            object.rows = [];
                            for (var j = 0; j < message.rows.length; ++j)
                                object.rows[j] = $root.palexy.streaming.v1.ReportRow.toObject(message.rows[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ReportData to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ReportData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReportData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ReportData;
                })();
    
                v1.ReportMetric = (function() {
    
                    /**
                     * Properties of a ReportMetric.
                     * @memberof palexy.streaming.v1
                     * @interface IReportMetric
                     * @property {string|null} [id] ReportMetric id
                     * @property {number|null} [dateRangeIndex] ReportMetric dateRangeIndex
                     */
    
                    /**
                     * Constructs a new ReportMetric.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ReportMetric.
                     * @implements IReportMetric
                     * @constructor
                     * @param {palexy.streaming.v1.IReportMetric=} [properties] Properties to set
                     */
                    function ReportMetric(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReportMetric id.
                     * @member {string} id
                     * @memberof palexy.streaming.v1.ReportMetric
                     * @instance
                     */
                    ReportMetric.prototype.id = "";
    
                    /**
                     * ReportMetric dateRangeIndex.
                     * @member {number} dateRangeIndex
                     * @memberof palexy.streaming.v1.ReportMetric
                     * @instance
                     */
                    ReportMetric.prototype.dateRangeIndex = 0;
    
                    /**
                     * Creates a new ReportMetric instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ReportMetric
                     * @static
                     * @param {palexy.streaming.v1.IReportMetric=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ReportMetric} ReportMetric instance
                     */
                    ReportMetric.create = function create(properties) {
                        return new ReportMetric(properties);
                    };
    
                    /**
                     * Encodes the specified ReportMetric message. Does not implicitly {@link palexy.streaming.v1.ReportMetric.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ReportMetric
                     * @static
                     * @param {palexy.streaming.v1.IReportMetric} message ReportMetric message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportMetric.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.dateRangeIndex != null && Object.hasOwnProperty.call(message, "dateRangeIndex"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateRangeIndex);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReportMetric message, length delimited. Does not implicitly {@link palexy.streaming.v1.ReportMetric.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ReportMetric
                     * @static
                     * @param {palexy.streaming.v1.IReportMetric} message ReportMetric message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportMetric.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReportMetric message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ReportMetric
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ReportMetric} ReportMetric
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportMetric.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ReportMetric();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.dateRangeIndex = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReportMetric message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ReportMetric
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ReportMetric} ReportMetric
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportMetric.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReportMetric message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ReportMetric
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReportMetric.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.dateRangeIndex != null && message.hasOwnProperty("dateRangeIndex"))
                            if (!$util.isInteger(message.dateRangeIndex))
                                return "dateRangeIndex: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ReportMetric message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ReportMetric
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ReportMetric} ReportMetric
                     */
                    ReportMetric.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ReportMetric)
                            return object;
                        var message = new $root.palexy.streaming.v1.ReportMetric();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.dateRangeIndex != null)
                            message.dateRangeIndex = object.dateRangeIndex | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReportMetric message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ReportMetric
                     * @static
                     * @param {palexy.streaming.v1.ReportMetric} message ReportMetric
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReportMetric.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.dateRangeIndex = 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.dateRangeIndex != null && message.hasOwnProperty("dateRangeIndex"))
                            object.dateRangeIndex = message.dateRangeIndex;
                        return object;
                    };
    
                    /**
                     * Converts this ReportMetric to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ReportMetric
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReportMetric.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ReportMetric;
                })();
    
                v1.ReportRow = (function() {
    
                    /**
                     * Properties of a ReportRow.
                     * @memberof palexy.streaming.v1
                     * @interface IReportRow
                     * @property {Array.<string>|null} [dimensionValues] ReportRow dimensionValues
                     * @property {Array.<number>|null} [metricValues] ReportRow metricValues
                     */
    
                    /**
                     * Constructs a new ReportRow.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ReportRow.
                     * @implements IReportRow
                     * @constructor
                     * @param {palexy.streaming.v1.IReportRow=} [properties] Properties to set
                     */
                    function ReportRow(properties) {
                        this.dimensionValues = [];
                        this.metricValues = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReportRow dimensionValues.
                     * @member {Array.<string>} dimensionValues
                     * @memberof palexy.streaming.v1.ReportRow
                     * @instance
                     */
                    ReportRow.prototype.dimensionValues = $util.emptyArray;
    
                    /**
                     * ReportRow metricValues.
                     * @member {Array.<number>} metricValues
                     * @memberof palexy.streaming.v1.ReportRow
                     * @instance
                     */
                    ReportRow.prototype.metricValues = $util.emptyArray;
    
                    /**
                     * Creates a new ReportRow instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ReportRow
                     * @static
                     * @param {palexy.streaming.v1.IReportRow=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ReportRow} ReportRow instance
                     */
                    ReportRow.create = function create(properties) {
                        return new ReportRow(properties);
                    };
    
                    /**
                     * Encodes the specified ReportRow message. Does not implicitly {@link palexy.streaming.v1.ReportRow.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ReportRow
                     * @static
                     * @param {palexy.streaming.v1.IReportRow} message ReportRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportRow.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dimensionValues != null && message.dimensionValues.length)
                            for (var i = 0; i < message.dimensionValues.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dimensionValues[i]);
                        if (message.metricValues != null && message.metricValues.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.metricValues.length; ++i)
                                writer.double(message.metricValues[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReportRow message, length delimited. Does not implicitly {@link palexy.streaming.v1.ReportRow.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ReportRow
                     * @static
                     * @param {palexy.streaming.v1.IReportRow} message ReportRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReportRow.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReportRow message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ReportRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ReportRow} ReportRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportRow.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ReportRow();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.dimensionValues && message.dimensionValues.length))
                                    message.dimensionValues = [];
                                message.dimensionValues.push(reader.string());
                                break;
                            case 2:
                                if (!(message.metricValues && message.metricValues.length))
                                    message.metricValues = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.metricValues.push(reader.double());
                                } else
                                    message.metricValues.push(reader.double());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReportRow message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ReportRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ReportRow} ReportRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReportRow.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReportRow message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ReportRow
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReportRow.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dimensionValues != null && message.hasOwnProperty("dimensionValues")) {
                            if (!Array.isArray(message.dimensionValues))
                                return "dimensionValues: array expected";
                            for (var i = 0; i < message.dimensionValues.length; ++i)
                                if (!$util.isString(message.dimensionValues[i]))
                                    return "dimensionValues: string[] expected";
                        }
                        if (message.metricValues != null && message.hasOwnProperty("metricValues")) {
                            if (!Array.isArray(message.metricValues))
                                return "metricValues: array expected";
                            for (var i = 0; i < message.metricValues.length; ++i)
                                if (typeof message.metricValues[i] !== "number")
                                    return "metricValues: number[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ReportRow message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ReportRow
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ReportRow} ReportRow
                     */
                    ReportRow.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ReportRow)
                            return object;
                        var message = new $root.palexy.streaming.v1.ReportRow();
                        if (object.dimensionValues) {
                            if (!Array.isArray(object.dimensionValues))
                                throw TypeError(".palexy.streaming.v1.ReportRow.dimensionValues: array expected");
                            message.dimensionValues = [];
                            for (var i = 0; i < object.dimensionValues.length; ++i)
                                message.dimensionValues[i] = String(object.dimensionValues[i]);
                        }
                        if (object.metricValues) {
                            if (!Array.isArray(object.metricValues))
                                throw TypeError(".palexy.streaming.v1.ReportRow.metricValues: array expected");
                            message.metricValues = [];
                            for (var i = 0; i < object.metricValues.length; ++i)
                                message.metricValues[i] = Number(object.metricValues[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReportRow message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ReportRow
                     * @static
                     * @param {palexy.streaming.v1.ReportRow} message ReportRow
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReportRow.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.dimensionValues = [];
                            object.metricValues = [];
                        }
                        if (message.dimensionValues && message.dimensionValues.length) {
                            object.dimensionValues = [];
                            for (var j = 0; j < message.dimensionValues.length; ++j)
                                object.dimensionValues[j] = message.dimensionValues[j];
                        }
                        if (message.metricValues && message.metricValues.length) {
                            object.metricValues = [];
                            for (var j = 0; j < message.metricValues.length; ++j)
                                object.metricValues[j] = options.json && !isFinite(message.metricValues[j]) ? String(message.metricValues[j]) : message.metricValues[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ReportRow to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ReportRow
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReportRow.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ReportRow;
                })();
    
                v1.CustomerActivityService = (function() {
    
                    /**
                     * Constructs a new CustomerActivityService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CustomerActivityService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function CustomerActivityService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (CustomerActivityService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CustomerActivityService;
    
                    /**
                     * Creates new CustomerActivityService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.CustomerActivityService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {CustomerActivityService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    CustomerActivityService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CustomerActivityService#aggregateDataLevel2}.
                     * @memberof palexy.streaming.v1.CustomerActivityService
                     * @typedef AggregateDataLevel2Callback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.AggregateDataLevel2Response} [response] AggregateDataLevel2Response
                     */
    
                    /**
                     * Calls AggregateDataLevel2.
                     * @function aggregateDataLevel2
                     * @memberof palexy.streaming.v1.CustomerActivityService
                     * @instance
                     * @param {palexy.streaming.v1.IAggregateDataLevel2Request} request AggregateDataLevel2Request message or plain object
                     * @param {palexy.streaming.v1.CustomerActivityService.AggregateDataLevel2Callback} callback Node-style callback called with the error, if any, and AggregateDataLevel2Response
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CustomerActivityService.prototype.aggregateDataLevel2 = function aggregateDataLevel2(request, callback) {
                        return this.rpcCall(aggregateDataLevel2, $root.palexy.streaming.v1.AggregateDataLevel2Request, $root.palexy.streaming.v1.AggregateDataLevel2Response, request, callback);
                    }, "name", { value: "AggregateDataLevel2" });
    
                    /**
                     * Calls AggregateDataLevel2.
                     * @function aggregateDataLevel2
                     * @memberof palexy.streaming.v1.CustomerActivityService
                     * @instance
                     * @param {palexy.streaming.v1.IAggregateDataLevel2Request} request AggregateDataLevel2Request message or plain object
                     * @returns {Promise<palexy.streaming.v1.AggregateDataLevel2Response>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CustomerActivityService#getAggregateLevel2Params}.
                     * @memberof palexy.streaming.v1.CustomerActivityService
                     * @typedef GetAggregateLevel2ParamsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.AggregateLevel2Params} [response] AggregateLevel2Params
                     */
    
                    /**
                     * Calls GetAggregateLevel2Params.
                     * @function getAggregateLevel2Params
                     * @memberof palexy.streaming.v1.CustomerActivityService
                     * @instance
                     * @param {palexy.streaming.v1.IGetAggregateLevel2ParamsRequest} request GetAggregateLevel2ParamsRequest message or plain object
                     * @param {palexy.streaming.v1.CustomerActivityService.GetAggregateLevel2ParamsCallback} callback Node-style callback called with the error, if any, and AggregateLevel2Params
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CustomerActivityService.prototype.getAggregateLevel2Params = function getAggregateLevel2Params(request, callback) {
                        return this.rpcCall(getAggregateLevel2Params, $root.palexy.streaming.v1.GetAggregateLevel2ParamsRequest, $root.palexy.streaming.v1.AggregateLevel2Params, request, callback);
                    }, "name", { value: "GetAggregateLevel2Params" });
    
                    /**
                     * Calls GetAggregateLevel2Params.
                     * @function getAggregateLevel2Params
                     * @memberof palexy.streaming.v1.CustomerActivityService
                     * @instance
                     * @param {palexy.streaming.v1.IGetAggregateLevel2ParamsRequest} request GetAggregateLevel2ParamsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.AggregateLevel2Params>} Promise
                     * @variation 2
                     */
    
                    return CustomerActivityService;
                })();
    
                v1.AggregateDataLevel2Request = (function() {
    
                    /**
                     * Properties of an AggregateDataLevel2Request.
                     * @memberof palexy.streaming.v1
                     * @interface IAggregateDataLevel2Request
                     * @property {number|null} [storeId] AggregateDataLevel2Request storeId
                     * @property {number|null} [dateId] AggregateDataLevel2Request dateId
                     */
    
                    /**
                     * Constructs a new AggregateDataLevel2Request.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AggregateDataLevel2Request.
                     * @implements IAggregateDataLevel2Request
                     * @constructor
                     * @param {palexy.streaming.v1.IAggregateDataLevel2Request=} [properties] Properties to set
                     */
                    function AggregateDataLevel2Request(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AggregateDataLevel2Request storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Request
                     * @instance
                     */
                    AggregateDataLevel2Request.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AggregateDataLevel2Request dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Request
                     * @instance
                     */
                    AggregateDataLevel2Request.prototype.dateId = 0;
    
                    /**
                     * Creates a new AggregateDataLevel2Request instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Request
                     * @static
                     * @param {palexy.streaming.v1.IAggregateDataLevel2Request=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AggregateDataLevel2Request} AggregateDataLevel2Request instance
                     */
                    AggregateDataLevel2Request.create = function create(properties) {
                        return new AggregateDataLevel2Request(properties);
                    };
    
                    /**
                     * Encodes the specified AggregateDataLevel2Request message. Does not implicitly {@link palexy.streaming.v1.AggregateDataLevel2Request.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Request
                     * @static
                     * @param {palexy.streaming.v1.IAggregateDataLevel2Request} message AggregateDataLevel2Request message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateDataLevel2Request.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AggregateDataLevel2Request message, length delimited. Does not implicitly {@link palexy.streaming.v1.AggregateDataLevel2Request.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Request
                     * @static
                     * @param {palexy.streaming.v1.IAggregateDataLevel2Request} message AggregateDataLevel2Request message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateDataLevel2Request.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AggregateDataLevel2Request message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Request
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AggregateDataLevel2Request} AggregateDataLevel2Request
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateDataLevel2Request.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AggregateDataLevel2Request();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AggregateDataLevel2Request message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Request
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AggregateDataLevel2Request} AggregateDataLevel2Request
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateDataLevel2Request.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AggregateDataLevel2Request message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Request
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AggregateDataLevel2Request.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an AggregateDataLevel2Request message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Request
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AggregateDataLevel2Request} AggregateDataLevel2Request
                     */
                    AggregateDataLevel2Request.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AggregateDataLevel2Request)
                            return object;
                        var message = new $root.palexy.streaming.v1.AggregateDataLevel2Request();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AggregateDataLevel2Request message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Request
                     * @static
                     * @param {palexy.streaming.v1.AggregateDataLevel2Request} message AggregateDataLevel2Request
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AggregateDataLevel2Request.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        return object;
                    };
    
                    /**
                     * Converts this AggregateDataLevel2Request to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Request
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AggregateDataLevel2Request.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AggregateDataLevel2Request;
                })();
    
                v1.AggregateDataLevel2Response = (function() {
    
                    /**
                     * Properties of an AggregateDataLevel2Response.
                     * @memberof palexy.streaming.v1
                     * @interface IAggregateDataLevel2Response
                     */
    
                    /**
                     * Constructs a new AggregateDataLevel2Response.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AggregateDataLevel2Response.
                     * @implements IAggregateDataLevel2Response
                     * @constructor
                     * @param {palexy.streaming.v1.IAggregateDataLevel2Response=} [properties] Properties to set
                     */
                    function AggregateDataLevel2Response(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new AggregateDataLevel2Response instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Response
                     * @static
                     * @param {palexy.streaming.v1.IAggregateDataLevel2Response=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AggregateDataLevel2Response} AggregateDataLevel2Response instance
                     */
                    AggregateDataLevel2Response.create = function create(properties) {
                        return new AggregateDataLevel2Response(properties);
                    };
    
                    /**
                     * Encodes the specified AggregateDataLevel2Response message. Does not implicitly {@link palexy.streaming.v1.AggregateDataLevel2Response.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Response
                     * @static
                     * @param {palexy.streaming.v1.IAggregateDataLevel2Response} message AggregateDataLevel2Response message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateDataLevel2Response.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AggregateDataLevel2Response message, length delimited. Does not implicitly {@link palexy.streaming.v1.AggregateDataLevel2Response.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Response
                     * @static
                     * @param {palexy.streaming.v1.IAggregateDataLevel2Response} message AggregateDataLevel2Response message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateDataLevel2Response.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AggregateDataLevel2Response message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Response
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AggregateDataLevel2Response} AggregateDataLevel2Response
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateDataLevel2Response.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AggregateDataLevel2Response();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AggregateDataLevel2Response message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Response
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AggregateDataLevel2Response} AggregateDataLevel2Response
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateDataLevel2Response.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AggregateDataLevel2Response message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Response
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AggregateDataLevel2Response.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates an AggregateDataLevel2Response message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Response
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AggregateDataLevel2Response} AggregateDataLevel2Response
                     */
                    AggregateDataLevel2Response.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AggregateDataLevel2Response)
                            return object;
                        return new $root.palexy.streaming.v1.AggregateDataLevel2Response();
                    };
    
                    /**
                     * Creates a plain object from an AggregateDataLevel2Response message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Response
                     * @static
                     * @param {palexy.streaming.v1.AggregateDataLevel2Response} message AggregateDataLevel2Response
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AggregateDataLevel2Response.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this AggregateDataLevel2Response to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AggregateDataLevel2Response
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AggregateDataLevel2Response.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AggregateDataLevel2Response;
                })();
    
                v1.GetAggregateLevel2ParamsRequest = (function() {
    
                    /**
                     * Properties of a GetAggregateLevel2ParamsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetAggregateLevel2ParamsRequest
                     * @property {number|null} [storeId] GetAggregateLevel2ParamsRequest storeId
                     * @property {number|null} [dateId] GetAggregateLevel2ParamsRequest dateId
                     * @property {boolean|null} [includeStaffingData] GetAggregateLevel2ParamsRequest includeStaffingData
                     * @property {boolean|null} [includePosData] GetAggregateLevel2ParamsRequest includePosData
                     * @property {boolean|null} [includeForecastData] GetAggregateLevel2ParamsRequest includeForecastData
                     * @property {boolean|null} [includeFileData] GetAggregateLevel2ParamsRequest includeFileData
                     */
    
                    /**
                     * Constructs a new GetAggregateLevel2ParamsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetAggregateLevel2ParamsRequest.
                     * @implements IGetAggregateLevel2ParamsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetAggregateLevel2ParamsRequest=} [properties] Properties to set
                     */
                    function GetAggregateLevel2ParamsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetAggregateLevel2ParamsRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @instance
                     */
                    GetAggregateLevel2ParamsRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetAggregateLevel2ParamsRequest dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @instance
                     */
                    GetAggregateLevel2ParamsRequest.prototype.dateId = 0;
    
                    /**
                     * GetAggregateLevel2ParamsRequest includeStaffingData.
                     * @member {boolean} includeStaffingData
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @instance
                     */
                    GetAggregateLevel2ParamsRequest.prototype.includeStaffingData = false;
    
                    /**
                     * GetAggregateLevel2ParamsRequest includePosData.
                     * @member {boolean} includePosData
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @instance
                     */
                    GetAggregateLevel2ParamsRequest.prototype.includePosData = false;
    
                    /**
                     * GetAggregateLevel2ParamsRequest includeForecastData.
                     * @member {boolean} includeForecastData
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @instance
                     */
                    GetAggregateLevel2ParamsRequest.prototype.includeForecastData = false;
    
                    /**
                     * GetAggregateLevel2ParamsRequest includeFileData.
                     * @member {boolean} includeFileData
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @instance
                     */
                    GetAggregateLevel2ParamsRequest.prototype.includeFileData = false;
    
                    /**
                     * Creates a new GetAggregateLevel2ParamsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetAggregateLevel2ParamsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetAggregateLevel2ParamsRequest} GetAggregateLevel2ParamsRequest instance
                     */
                    GetAggregateLevel2ParamsRequest.create = function create(properties) {
                        return new GetAggregateLevel2ParamsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetAggregateLevel2ParamsRequest message. Does not implicitly {@link palexy.streaming.v1.GetAggregateLevel2ParamsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetAggregateLevel2ParamsRequest} message GetAggregateLevel2ParamsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetAggregateLevel2ParamsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateId);
                        if (message.includeStaffingData != null && Object.hasOwnProperty.call(message, "includeStaffingData"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeStaffingData);
                        if (message.includePosData != null && Object.hasOwnProperty.call(message, "includePosData"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includePosData);
                        if (message.includeForecastData != null && Object.hasOwnProperty.call(message, "includeForecastData"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.includeForecastData);
                        if (message.includeFileData != null && Object.hasOwnProperty.call(message, "includeFileData"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.includeFileData);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetAggregateLevel2ParamsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetAggregateLevel2ParamsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetAggregateLevel2ParamsRequest} message GetAggregateLevel2ParamsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetAggregateLevel2ParamsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetAggregateLevel2ParamsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetAggregateLevel2ParamsRequest} GetAggregateLevel2ParamsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetAggregateLevel2ParamsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetAggregateLevel2ParamsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.int32();
                                break;
                            case 3:
                                message.includeStaffingData = reader.bool();
                                break;
                            case 4:
                                message.includePosData = reader.bool();
                                break;
                            case 5:
                                message.includeForecastData = reader.bool();
                                break;
                            case 6:
                                message.includeFileData = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetAggregateLevel2ParamsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetAggregateLevel2ParamsRequest} GetAggregateLevel2ParamsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetAggregateLevel2ParamsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetAggregateLevel2ParamsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetAggregateLevel2ParamsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.includeStaffingData != null && message.hasOwnProperty("includeStaffingData"))
                            if (typeof message.includeStaffingData !== "boolean")
                                return "includeStaffingData: boolean expected";
                        if (message.includePosData != null && message.hasOwnProperty("includePosData"))
                            if (typeof message.includePosData !== "boolean")
                                return "includePosData: boolean expected";
                        if (message.includeForecastData != null && message.hasOwnProperty("includeForecastData"))
                            if (typeof message.includeForecastData !== "boolean")
                                return "includeForecastData: boolean expected";
                        if (message.includeFileData != null && message.hasOwnProperty("includeFileData"))
                            if (typeof message.includeFileData !== "boolean")
                                return "includeFileData: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetAggregateLevel2ParamsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetAggregateLevel2ParamsRequest} GetAggregateLevel2ParamsRequest
                     */
                    GetAggregateLevel2ParamsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetAggregateLevel2ParamsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetAggregateLevel2ParamsRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.includeStaffingData != null)
                            message.includeStaffingData = Boolean(object.includeStaffingData);
                        if (object.includePosData != null)
                            message.includePosData = Boolean(object.includePosData);
                        if (object.includeForecastData != null)
                            message.includeForecastData = Boolean(object.includeForecastData);
                        if (object.includeFileData != null)
                            message.includeFileData = Boolean(object.includeFileData);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetAggregateLevel2ParamsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @static
                     * @param {palexy.streaming.v1.GetAggregateLevel2ParamsRequest} message GetAggregateLevel2ParamsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetAggregateLevel2ParamsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                            object.includeStaffingData = false;
                            object.includePosData = false;
                            object.includeForecastData = false;
                            object.includeFileData = false;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.includeStaffingData != null && message.hasOwnProperty("includeStaffingData"))
                            object.includeStaffingData = message.includeStaffingData;
                        if (message.includePosData != null && message.hasOwnProperty("includePosData"))
                            object.includePosData = message.includePosData;
                        if (message.includeForecastData != null && message.hasOwnProperty("includeForecastData"))
                            object.includeForecastData = message.includeForecastData;
                        if (message.includeFileData != null && message.hasOwnProperty("includeFileData"))
                            object.includeFileData = message.includeFileData;
                        return object;
                    };
    
                    /**
                     * Converts this GetAggregateLevel2ParamsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetAggregateLevel2ParamsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetAggregateLevel2ParamsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetAggregateLevel2ParamsRequest;
                })();
    
                v1.AggregateLevel2Params = (function() {
    
                    /**
                     * Properties of an AggregateLevel2Params.
                     * @memberof palexy.streaming.v1
                     * @interface IAggregateLevel2Params
                     * @property {number|null} [onTimeInteractionThresholdSeconds] AggregateLevel2Params onTimeInteractionThresholdSeconds
                     * @property {number|null} [onTimeGreetingThresholdSeconds] AggregateLevel2Params onTimeGreetingThresholdSeconds
                     * @property {number|null} [timeToGreetingOutlierThresholdSeconds] AggregateLevel2Params timeToGreetingOutlierThresholdSeconds
                     * @property {number|null} [bounceThresholdSeconds] AggregateLevel2Params bounceThresholdSeconds
                     * @property {Array.<number>|null} [storeVisitAreaIds] AggregateLevel2Params storeVisitAreaIds
                     * @property {number|null} [sectionVisitDurationLowerBoundSeconds] AggregateLevel2Params sectionVisitDurationLowerBoundSeconds
                     * @property {Array.<palexy.streaming.v1.IMetadataType>|null} [metadataTypes] AggregateLevel2Params metadataTypes
                     * @property {boolean|null} [useNetAmount] AggregateLevel2Params useNetAmount
                     * @property {boolean|null} [useSalesTransactionsAsNetSalesTransactions] AggregateLevel2Params useSalesTransactionsAsNetSalesTransactions
                     * @property {boolean|null} [useSalesItemsAsNetSalesItems] AggregateLevel2Params useSalesItemsAsNetSalesItems
                     * @property {boolean|null} [includeReturningItemsForDiscountAnalysis] AggregateLevel2Params includeReturningItemsForDiscountAnalysis
                     * @property {Array.<palexy.streaming.v1.IStaff>|null} [staff] AggregateLevel2Params staff
                     * @property {palexy.streaming.v1.IForecastPrediction|null} [forecastVisit] AggregateLevel2Params forecastVisit
                     * @property {palexy.streaming.v1.IForecastPrediction|null} [forecastNetSales] AggregateLevel2Params forecastNetSales
                     * @property {string|null} [uriPath] AggregateLevel2Params uriPath
                     * @property {Uint8Array|null} [fileData] AggregateLevel2Params fileData
                     * @property {Array.<palexy.streaming.v1.IPosTransaction>|null} [posTransactions] AggregateLevel2Params posTransactions
                     * @property {Array.<palexy.streaming.v1.IProductSectionMapping>|null} [productSectionMapping] AggregateLevel2Params productSectionMapping
                     * @property {Array.<palexy.streaming.v1.IParsedStaffAllocation>|null} [staffAllocations] AggregateLevel2Params staffAllocations
                     */
    
                    /**
                     * Constructs a new AggregateLevel2Params.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AggregateLevel2Params.
                     * @implements IAggregateLevel2Params
                     * @constructor
                     * @param {palexy.streaming.v1.IAggregateLevel2Params=} [properties] Properties to set
                     */
                    function AggregateLevel2Params(properties) {
                        this.storeVisitAreaIds = [];
                        this.metadataTypes = [];
                        this.staff = [];
                        this.posTransactions = [];
                        this.productSectionMapping = [];
                        this.staffAllocations = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AggregateLevel2Params onTimeInteractionThresholdSeconds.
                     * @member {number} onTimeInteractionThresholdSeconds
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.onTimeInteractionThresholdSeconds = 0;
    
                    /**
                     * AggregateLevel2Params onTimeGreetingThresholdSeconds.
                     * @member {number} onTimeGreetingThresholdSeconds
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.onTimeGreetingThresholdSeconds = 0;
    
                    /**
                     * AggregateLevel2Params timeToGreetingOutlierThresholdSeconds.
                     * @member {number} timeToGreetingOutlierThresholdSeconds
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.timeToGreetingOutlierThresholdSeconds = 0;
    
                    /**
                     * AggregateLevel2Params bounceThresholdSeconds.
                     * @member {number} bounceThresholdSeconds
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.bounceThresholdSeconds = 0;
    
                    /**
                     * AggregateLevel2Params storeVisitAreaIds.
                     * @member {Array.<number>} storeVisitAreaIds
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.storeVisitAreaIds = $util.emptyArray;
    
                    /**
                     * AggregateLevel2Params sectionVisitDurationLowerBoundSeconds.
                     * @member {number} sectionVisitDurationLowerBoundSeconds
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.sectionVisitDurationLowerBoundSeconds = 0;
    
                    /**
                     * AggregateLevel2Params metadataTypes.
                     * @member {Array.<palexy.streaming.v1.IMetadataType>} metadataTypes
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.metadataTypes = $util.emptyArray;
    
                    /**
                     * AggregateLevel2Params useNetAmount.
                     * @member {boolean} useNetAmount
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.useNetAmount = false;
    
                    /**
                     * AggregateLevel2Params useSalesTransactionsAsNetSalesTransactions.
                     * @member {boolean} useSalesTransactionsAsNetSalesTransactions
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.useSalesTransactionsAsNetSalesTransactions = false;
    
                    /**
                     * AggregateLevel2Params useSalesItemsAsNetSalesItems.
                     * @member {boolean} useSalesItemsAsNetSalesItems
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.useSalesItemsAsNetSalesItems = false;
    
                    /**
                     * AggregateLevel2Params includeReturningItemsForDiscountAnalysis.
                     * @member {boolean} includeReturningItemsForDiscountAnalysis
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.includeReturningItemsForDiscountAnalysis = false;
    
                    /**
                     * AggregateLevel2Params staff.
                     * @member {Array.<palexy.streaming.v1.IStaff>} staff
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.staff = $util.emptyArray;
    
                    /**
                     * AggregateLevel2Params forecastVisit.
                     * @member {palexy.streaming.v1.IForecastPrediction|null|undefined} forecastVisit
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.forecastVisit = null;
    
                    /**
                     * AggregateLevel2Params forecastNetSales.
                     * @member {palexy.streaming.v1.IForecastPrediction|null|undefined} forecastNetSales
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.forecastNetSales = null;
    
                    /**
                     * AggregateLevel2Params uriPath.
                     * @member {string|null|undefined} uriPath
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.uriPath = null;
    
                    /**
                     * AggregateLevel2Params fileData.
                     * @member {Uint8Array|null|undefined} fileData
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.fileData = null;
    
                    /**
                     * AggregateLevel2Params posTransactions.
                     * @member {Array.<palexy.streaming.v1.IPosTransaction>} posTransactions
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.posTransactions = $util.emptyArray;
    
                    /**
                     * AggregateLevel2Params productSectionMapping.
                     * @member {Array.<palexy.streaming.v1.IProductSectionMapping>} productSectionMapping
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.productSectionMapping = $util.emptyArray;
    
                    /**
                     * AggregateLevel2Params staffAllocations.
                     * @member {Array.<palexy.streaming.v1.IParsedStaffAllocation>} staffAllocations
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    AggregateLevel2Params.prototype.staffAllocations = $util.emptyArray;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * AggregateLevel2Params aggregatedLevel_1Data.
                     * @member {"uriPath"|"fileData"|undefined} aggregatedLevel_1Data
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     */
                    Object.defineProperty(AggregateLevel2Params.prototype, "aggregatedLevel_1Data", {
                        get: $util.oneOfGetter($oneOfFields = ["uriPath", "fileData"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new AggregateLevel2Params instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @static
                     * @param {palexy.streaming.v1.IAggregateLevel2Params=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AggregateLevel2Params} AggregateLevel2Params instance
                     */
                    AggregateLevel2Params.create = function create(properties) {
                        return new AggregateLevel2Params(properties);
                    };
    
                    /**
                     * Encodes the specified AggregateLevel2Params message. Does not implicitly {@link palexy.streaming.v1.AggregateLevel2Params.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @static
                     * @param {palexy.streaming.v1.IAggregateLevel2Params} message AggregateLevel2Params message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateLevel2Params.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.onTimeInteractionThresholdSeconds != null && Object.hasOwnProperty.call(message, "onTimeInteractionThresholdSeconds"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.onTimeInteractionThresholdSeconds);
                        if (message.onTimeGreetingThresholdSeconds != null && Object.hasOwnProperty.call(message, "onTimeGreetingThresholdSeconds"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.onTimeGreetingThresholdSeconds);
                        if (message.timeToGreetingOutlierThresholdSeconds != null && Object.hasOwnProperty.call(message, "timeToGreetingOutlierThresholdSeconds"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.timeToGreetingOutlierThresholdSeconds);
                        if (message.storeVisitAreaIds != null && message.storeVisitAreaIds.length) {
                            writer.uint32(/* id 4, wireType 2 =*/34).fork();
                            for (var i = 0; i < message.storeVisitAreaIds.length; ++i)
                                writer.int64(message.storeVisitAreaIds[i]);
                            writer.ldelim();
                        }
                        if (message.sectionVisitDurationLowerBoundSeconds != null && Object.hasOwnProperty.call(message, "sectionVisitDurationLowerBoundSeconds"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.sectionVisitDurationLowerBoundSeconds);
                        if (message.metadataTypes != null && message.metadataTypes.length)
                            for (var i = 0; i < message.metadataTypes.length; ++i)
                                $root.palexy.streaming.v1.MetadataType.encode(message.metadataTypes[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.useNetAmount != null && Object.hasOwnProperty.call(message, "useNetAmount"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.useNetAmount);
                        if (message.useSalesTransactionsAsNetSalesTransactions != null && Object.hasOwnProperty.call(message, "useSalesTransactionsAsNetSalesTransactions"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.useSalesTransactionsAsNetSalesTransactions);
                        if (message.useSalesItemsAsNetSalesItems != null && Object.hasOwnProperty.call(message, "useSalesItemsAsNetSalesItems"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.useSalesItemsAsNetSalesItems);
                        if (message.staff != null && message.staff.length)
                            for (var i = 0; i < message.staff.length; ++i)
                                $root.palexy.streaming.v1.Staff.encode(message.staff[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.forecastVisit != null && Object.hasOwnProperty.call(message, "forecastVisit"))
                            $root.palexy.streaming.v1.ForecastPrediction.encode(message.forecastVisit, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.forecastNetSales != null && Object.hasOwnProperty.call(message, "forecastNetSales"))
                            $root.palexy.streaming.v1.ForecastPrediction.encode(message.forecastNetSales, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                        if (message.uriPath != null && Object.hasOwnProperty.call(message, "uriPath"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.uriPath);
                        if (message.fileData != null && Object.hasOwnProperty.call(message, "fileData"))
                            writer.uint32(/* id 14, wireType 2 =*/114).bytes(message.fileData);
                        if (message.posTransactions != null && message.posTransactions.length)
                            for (var i = 0; i < message.posTransactions.length; ++i)
                                $root.palexy.streaming.v1.PosTransaction.encode(message.posTransactions[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                        if (message.productSectionMapping != null && message.productSectionMapping.length)
                            for (var i = 0; i < message.productSectionMapping.length; ++i)
                                $root.palexy.streaming.v1.ProductSectionMapping.encode(message.productSectionMapping[i], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.staffAllocations != null && message.staffAllocations.length)
                            for (var i = 0; i < message.staffAllocations.length; ++i)
                                $root.palexy.streaming.v1.ParsedStaffAllocation.encode(message.staffAllocations[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        if (message.bounceThresholdSeconds != null && Object.hasOwnProperty.call(message, "bounceThresholdSeconds"))
                            writer.uint32(/* id 18, wireType 0 =*/144).int32(message.bounceThresholdSeconds);
                        if (message.includeReturningItemsForDiscountAnalysis != null && Object.hasOwnProperty.call(message, "includeReturningItemsForDiscountAnalysis"))
                            writer.uint32(/* id 19, wireType 0 =*/152).bool(message.includeReturningItemsForDiscountAnalysis);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AggregateLevel2Params message, length delimited. Does not implicitly {@link palexy.streaming.v1.AggregateLevel2Params.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @static
                     * @param {palexy.streaming.v1.IAggregateLevel2Params} message AggregateLevel2Params message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateLevel2Params.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AggregateLevel2Params message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AggregateLevel2Params} AggregateLevel2Params
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateLevel2Params.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AggregateLevel2Params();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.onTimeInteractionThresholdSeconds = reader.int32();
                                break;
                            case 2:
                                message.onTimeGreetingThresholdSeconds = reader.int32();
                                break;
                            case 3:
                                message.timeToGreetingOutlierThresholdSeconds = reader.int32();
                                break;
                            case 18:
                                message.bounceThresholdSeconds = reader.int32();
                                break;
                            case 4:
                                if (!(message.storeVisitAreaIds && message.storeVisitAreaIds.length))
                                    message.storeVisitAreaIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeVisitAreaIds.push(reader.int64());
                                } else
                                    message.storeVisitAreaIds.push(reader.int64());
                                break;
                            case 5:
                                message.sectionVisitDurationLowerBoundSeconds = reader.int32();
                                break;
                            case 6:
                                if (!(message.metadataTypes && message.metadataTypes.length))
                                    message.metadataTypes = [];
                                message.metadataTypes.push($root.palexy.streaming.v1.MetadataType.decode(reader, reader.uint32()));
                                break;
                            case 7:
                                message.useNetAmount = reader.bool();
                                break;
                            case 8:
                                message.useSalesTransactionsAsNetSalesTransactions = reader.bool();
                                break;
                            case 9:
                                message.useSalesItemsAsNetSalesItems = reader.bool();
                                break;
                            case 19:
                                message.includeReturningItemsForDiscountAnalysis = reader.bool();
                                break;
                            case 10:
                                if (!(message.staff && message.staff.length))
                                    message.staff = [];
                                message.staff.push($root.palexy.streaming.v1.Staff.decode(reader, reader.uint32()));
                                break;
                            case 11:
                                message.forecastVisit = $root.palexy.streaming.v1.ForecastPrediction.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.forecastNetSales = $root.palexy.streaming.v1.ForecastPrediction.decode(reader, reader.uint32());
                                break;
                            case 13:
                                message.uriPath = reader.string();
                                break;
                            case 14:
                                message.fileData = reader.bytes();
                                break;
                            case 15:
                                if (!(message.posTransactions && message.posTransactions.length))
                                    message.posTransactions = [];
                                message.posTransactions.push($root.palexy.streaming.v1.PosTransaction.decode(reader, reader.uint32()));
                                break;
                            case 16:
                                if (!(message.productSectionMapping && message.productSectionMapping.length))
                                    message.productSectionMapping = [];
                                message.productSectionMapping.push($root.palexy.streaming.v1.ProductSectionMapping.decode(reader, reader.uint32()));
                                break;
                            case 17:
                                if (!(message.staffAllocations && message.staffAllocations.length))
                                    message.staffAllocations = [];
                                message.staffAllocations.push($root.palexy.streaming.v1.ParsedStaffAllocation.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AggregateLevel2Params message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AggregateLevel2Params} AggregateLevel2Params
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateLevel2Params.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AggregateLevel2Params message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AggregateLevel2Params.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.onTimeInteractionThresholdSeconds != null && message.hasOwnProperty("onTimeInteractionThresholdSeconds"))
                            if (!$util.isInteger(message.onTimeInteractionThresholdSeconds))
                                return "onTimeInteractionThresholdSeconds: integer expected";
                        if (message.onTimeGreetingThresholdSeconds != null && message.hasOwnProperty("onTimeGreetingThresholdSeconds"))
                            if (!$util.isInteger(message.onTimeGreetingThresholdSeconds))
                                return "onTimeGreetingThresholdSeconds: integer expected";
                        if (message.timeToGreetingOutlierThresholdSeconds != null && message.hasOwnProperty("timeToGreetingOutlierThresholdSeconds"))
                            if (!$util.isInteger(message.timeToGreetingOutlierThresholdSeconds))
                                return "timeToGreetingOutlierThresholdSeconds: integer expected";
                        if (message.bounceThresholdSeconds != null && message.hasOwnProperty("bounceThresholdSeconds"))
                            if (!$util.isInteger(message.bounceThresholdSeconds))
                                return "bounceThresholdSeconds: integer expected";
                        if (message.storeVisitAreaIds != null && message.hasOwnProperty("storeVisitAreaIds")) {
                            if (!Array.isArray(message.storeVisitAreaIds))
                                return "storeVisitAreaIds: array expected";
                            for (var i = 0; i < message.storeVisitAreaIds.length; ++i)
                                if (!$util.isInteger(message.storeVisitAreaIds[i]) && !(message.storeVisitAreaIds[i] && $util.isInteger(message.storeVisitAreaIds[i].low) && $util.isInteger(message.storeVisitAreaIds[i].high)))
                                    return "storeVisitAreaIds: integer|Long[] expected";
                        }
                        if (message.sectionVisitDurationLowerBoundSeconds != null && message.hasOwnProperty("sectionVisitDurationLowerBoundSeconds"))
                            if (!$util.isInteger(message.sectionVisitDurationLowerBoundSeconds))
                                return "sectionVisitDurationLowerBoundSeconds: integer expected";
                        if (message.metadataTypes != null && message.hasOwnProperty("metadataTypes")) {
                            if (!Array.isArray(message.metadataTypes))
                                return "metadataTypes: array expected";
                            for (var i = 0; i < message.metadataTypes.length; ++i) {
                                var error = $root.palexy.streaming.v1.MetadataType.verify(message.metadataTypes[i]);
                                if (error)
                                    return "metadataTypes." + error;
                            }
                        }
                        if (message.useNetAmount != null && message.hasOwnProperty("useNetAmount"))
                            if (typeof message.useNetAmount !== "boolean")
                                return "useNetAmount: boolean expected";
                        if (message.useSalesTransactionsAsNetSalesTransactions != null && message.hasOwnProperty("useSalesTransactionsAsNetSalesTransactions"))
                            if (typeof message.useSalesTransactionsAsNetSalesTransactions !== "boolean")
                                return "useSalesTransactionsAsNetSalesTransactions: boolean expected";
                        if (message.useSalesItemsAsNetSalesItems != null && message.hasOwnProperty("useSalesItemsAsNetSalesItems"))
                            if (typeof message.useSalesItemsAsNetSalesItems !== "boolean")
                                return "useSalesItemsAsNetSalesItems: boolean expected";
                        if (message.includeReturningItemsForDiscountAnalysis != null && message.hasOwnProperty("includeReturningItemsForDiscountAnalysis"))
                            if (typeof message.includeReturningItemsForDiscountAnalysis !== "boolean")
                                return "includeReturningItemsForDiscountAnalysis: boolean expected";
                        if (message.staff != null && message.hasOwnProperty("staff")) {
                            if (!Array.isArray(message.staff))
                                return "staff: array expected";
                            for (var i = 0; i < message.staff.length; ++i) {
                                var error = $root.palexy.streaming.v1.Staff.verify(message.staff[i]);
                                if (error)
                                    return "staff." + error;
                            }
                        }
                        if (message.forecastVisit != null && message.hasOwnProperty("forecastVisit")) {
                            var error = $root.palexy.streaming.v1.ForecastPrediction.verify(message.forecastVisit);
                            if (error)
                                return "forecastVisit." + error;
                        }
                        if (message.forecastNetSales != null && message.hasOwnProperty("forecastNetSales")) {
                            var error = $root.palexy.streaming.v1.ForecastPrediction.verify(message.forecastNetSales);
                            if (error)
                                return "forecastNetSales." + error;
                        }
                        if (message.uriPath != null && message.hasOwnProperty("uriPath")) {
                            properties.aggregatedLevel_1Data = 1;
                            if (!$util.isString(message.uriPath))
                                return "uriPath: string expected";
                        }
                        if (message.fileData != null && message.hasOwnProperty("fileData")) {
                            if (properties.aggregatedLevel_1Data === 1)
                                return "aggregatedLevel_1Data: multiple values";
                            properties.aggregatedLevel_1Data = 1;
                            if (!(message.fileData && typeof message.fileData.length === "number" || $util.isString(message.fileData)))
                                return "fileData: buffer expected";
                        }
                        if (message.posTransactions != null && message.hasOwnProperty("posTransactions")) {
                            if (!Array.isArray(message.posTransactions))
                                return "posTransactions: array expected";
                            for (var i = 0; i < message.posTransactions.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosTransaction.verify(message.posTransactions[i]);
                                if (error)
                                    return "posTransactions." + error;
                            }
                        }
                        if (message.productSectionMapping != null && message.hasOwnProperty("productSectionMapping")) {
                            if (!Array.isArray(message.productSectionMapping))
                                return "productSectionMapping: array expected";
                            for (var i = 0; i < message.productSectionMapping.length; ++i) {
                                var error = $root.palexy.streaming.v1.ProductSectionMapping.verify(message.productSectionMapping[i]);
                                if (error)
                                    return "productSectionMapping." + error;
                            }
                        }
                        if (message.staffAllocations != null && message.hasOwnProperty("staffAllocations")) {
                            if (!Array.isArray(message.staffAllocations))
                                return "staffAllocations: array expected";
                            for (var i = 0; i < message.staffAllocations.length; ++i) {
                                var error = $root.palexy.streaming.v1.ParsedStaffAllocation.verify(message.staffAllocations[i]);
                                if (error)
                                    return "staffAllocations." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an AggregateLevel2Params message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AggregateLevel2Params} AggregateLevel2Params
                     */
                    AggregateLevel2Params.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AggregateLevel2Params)
                            return object;
                        var message = new $root.palexy.streaming.v1.AggregateLevel2Params();
                        if (object.onTimeInteractionThresholdSeconds != null)
                            message.onTimeInteractionThresholdSeconds = object.onTimeInteractionThresholdSeconds | 0;
                        if (object.onTimeGreetingThresholdSeconds != null)
                            message.onTimeGreetingThresholdSeconds = object.onTimeGreetingThresholdSeconds | 0;
                        if (object.timeToGreetingOutlierThresholdSeconds != null)
                            message.timeToGreetingOutlierThresholdSeconds = object.timeToGreetingOutlierThresholdSeconds | 0;
                        if (object.bounceThresholdSeconds != null)
                            message.bounceThresholdSeconds = object.bounceThresholdSeconds | 0;
                        if (object.storeVisitAreaIds) {
                            if (!Array.isArray(object.storeVisitAreaIds))
                                throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.storeVisitAreaIds: array expected");
                            message.storeVisitAreaIds = [];
                            for (var i = 0; i < object.storeVisitAreaIds.length; ++i)
                                if ($util.Long)
                                    (message.storeVisitAreaIds[i] = $util.Long.fromValue(object.storeVisitAreaIds[i])).unsigned = false;
                                else if (typeof object.storeVisitAreaIds[i] === "string")
                                    message.storeVisitAreaIds[i] = parseInt(object.storeVisitAreaIds[i], 10);
                                else if (typeof object.storeVisitAreaIds[i] === "number")
                                    message.storeVisitAreaIds[i] = object.storeVisitAreaIds[i];
                                else if (typeof object.storeVisitAreaIds[i] === "object")
                                    message.storeVisitAreaIds[i] = new $util.LongBits(object.storeVisitAreaIds[i].low >>> 0, object.storeVisitAreaIds[i].high >>> 0).toNumber();
                        }
                        if (object.sectionVisitDurationLowerBoundSeconds != null)
                            message.sectionVisitDurationLowerBoundSeconds = object.sectionVisitDurationLowerBoundSeconds | 0;
                        if (object.metadataTypes) {
                            if (!Array.isArray(object.metadataTypes))
                                throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.metadataTypes: array expected");
                            message.metadataTypes = [];
                            for (var i = 0; i < object.metadataTypes.length; ++i) {
                                if (typeof object.metadataTypes[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.metadataTypes: object expected");
                                message.metadataTypes[i] = $root.palexy.streaming.v1.MetadataType.fromObject(object.metadataTypes[i]);
                            }
                        }
                        if (object.useNetAmount != null)
                            message.useNetAmount = Boolean(object.useNetAmount);
                        if (object.useSalesTransactionsAsNetSalesTransactions != null)
                            message.useSalesTransactionsAsNetSalesTransactions = Boolean(object.useSalesTransactionsAsNetSalesTransactions);
                        if (object.useSalesItemsAsNetSalesItems != null)
                            message.useSalesItemsAsNetSalesItems = Boolean(object.useSalesItemsAsNetSalesItems);
                        if (object.includeReturningItemsForDiscountAnalysis != null)
                            message.includeReturningItemsForDiscountAnalysis = Boolean(object.includeReturningItemsForDiscountAnalysis);
                        if (object.staff) {
                            if (!Array.isArray(object.staff))
                                throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.staff: array expected");
                            message.staff = [];
                            for (var i = 0; i < object.staff.length; ++i) {
                                if (typeof object.staff[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.staff: object expected");
                                message.staff[i] = $root.palexy.streaming.v1.Staff.fromObject(object.staff[i]);
                            }
                        }
                        if (object.forecastVisit != null) {
                            if (typeof object.forecastVisit !== "object")
                                throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.forecastVisit: object expected");
                            message.forecastVisit = $root.palexy.streaming.v1.ForecastPrediction.fromObject(object.forecastVisit);
                        }
                        if (object.forecastNetSales != null) {
                            if (typeof object.forecastNetSales !== "object")
                                throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.forecastNetSales: object expected");
                            message.forecastNetSales = $root.palexy.streaming.v1.ForecastPrediction.fromObject(object.forecastNetSales);
                        }
                        if (object.uriPath != null)
                            message.uriPath = String(object.uriPath);
                        if (object.fileData != null)
                            if (typeof object.fileData === "string")
                                $util.base64.decode(object.fileData, message.fileData = $util.newBuffer($util.base64.length(object.fileData)), 0);
                            else if (object.fileData.length)
                                message.fileData = object.fileData;
                        if (object.posTransactions) {
                            if (!Array.isArray(object.posTransactions))
                                throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.posTransactions: array expected");
                            message.posTransactions = [];
                            for (var i = 0; i < object.posTransactions.length; ++i) {
                                if (typeof object.posTransactions[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.posTransactions: object expected");
                                message.posTransactions[i] = $root.palexy.streaming.v1.PosTransaction.fromObject(object.posTransactions[i]);
                            }
                        }
                        if (object.productSectionMapping) {
                            if (!Array.isArray(object.productSectionMapping))
                                throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.productSectionMapping: array expected");
                            message.productSectionMapping = [];
                            for (var i = 0; i < object.productSectionMapping.length; ++i) {
                                if (typeof object.productSectionMapping[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.productSectionMapping: object expected");
                                message.productSectionMapping[i] = $root.palexy.streaming.v1.ProductSectionMapping.fromObject(object.productSectionMapping[i]);
                            }
                        }
                        if (object.staffAllocations) {
                            if (!Array.isArray(object.staffAllocations))
                                throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.staffAllocations: array expected");
                            message.staffAllocations = [];
                            for (var i = 0; i < object.staffAllocations.length; ++i) {
                                if (typeof object.staffAllocations[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.AggregateLevel2Params.staffAllocations: object expected");
                                message.staffAllocations[i] = $root.palexy.streaming.v1.ParsedStaffAllocation.fromObject(object.staffAllocations[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AggregateLevel2Params message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @static
                     * @param {palexy.streaming.v1.AggregateLevel2Params} message AggregateLevel2Params
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AggregateLevel2Params.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.storeVisitAreaIds = [];
                            object.metadataTypes = [];
                            object.staff = [];
                            object.posTransactions = [];
                            object.productSectionMapping = [];
                            object.staffAllocations = [];
                        }
                        if (options.defaults) {
                            object.onTimeInteractionThresholdSeconds = 0;
                            object.onTimeGreetingThresholdSeconds = 0;
                            object.timeToGreetingOutlierThresholdSeconds = 0;
                            object.sectionVisitDurationLowerBoundSeconds = 0;
                            object.useNetAmount = false;
                            object.useSalesTransactionsAsNetSalesTransactions = false;
                            object.useSalesItemsAsNetSalesItems = false;
                            object.forecastVisit = null;
                            object.forecastNetSales = null;
                            object.bounceThresholdSeconds = 0;
                            object.includeReturningItemsForDiscountAnalysis = false;
                        }
                        if (message.onTimeInteractionThresholdSeconds != null && message.hasOwnProperty("onTimeInteractionThresholdSeconds"))
                            object.onTimeInteractionThresholdSeconds = message.onTimeInteractionThresholdSeconds;
                        if (message.onTimeGreetingThresholdSeconds != null && message.hasOwnProperty("onTimeGreetingThresholdSeconds"))
                            object.onTimeGreetingThresholdSeconds = message.onTimeGreetingThresholdSeconds;
                        if (message.timeToGreetingOutlierThresholdSeconds != null && message.hasOwnProperty("timeToGreetingOutlierThresholdSeconds"))
                            object.timeToGreetingOutlierThresholdSeconds = message.timeToGreetingOutlierThresholdSeconds;
                        if (message.storeVisitAreaIds && message.storeVisitAreaIds.length) {
                            object.storeVisitAreaIds = [];
                            for (var j = 0; j < message.storeVisitAreaIds.length; ++j)
                                if (typeof message.storeVisitAreaIds[j] === "number")
                                    object.storeVisitAreaIds[j] = options.longs === String ? String(message.storeVisitAreaIds[j]) : message.storeVisitAreaIds[j];
                                else
                                    object.storeVisitAreaIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeVisitAreaIds[j]) : options.longs === Number ? new $util.LongBits(message.storeVisitAreaIds[j].low >>> 0, message.storeVisitAreaIds[j].high >>> 0).toNumber() : message.storeVisitAreaIds[j];
                        }
                        if (message.sectionVisitDurationLowerBoundSeconds != null && message.hasOwnProperty("sectionVisitDurationLowerBoundSeconds"))
                            object.sectionVisitDurationLowerBoundSeconds = message.sectionVisitDurationLowerBoundSeconds;
                        if (message.metadataTypes && message.metadataTypes.length) {
                            object.metadataTypes = [];
                            for (var j = 0; j < message.metadataTypes.length; ++j)
                                object.metadataTypes[j] = $root.palexy.streaming.v1.MetadataType.toObject(message.metadataTypes[j], options);
                        }
                        if (message.useNetAmount != null && message.hasOwnProperty("useNetAmount"))
                            object.useNetAmount = message.useNetAmount;
                        if (message.useSalesTransactionsAsNetSalesTransactions != null && message.hasOwnProperty("useSalesTransactionsAsNetSalesTransactions"))
                            object.useSalesTransactionsAsNetSalesTransactions = message.useSalesTransactionsAsNetSalesTransactions;
                        if (message.useSalesItemsAsNetSalesItems != null && message.hasOwnProperty("useSalesItemsAsNetSalesItems"))
                            object.useSalesItemsAsNetSalesItems = message.useSalesItemsAsNetSalesItems;
                        if (message.staff && message.staff.length) {
                            object.staff = [];
                            for (var j = 0; j < message.staff.length; ++j)
                                object.staff[j] = $root.palexy.streaming.v1.Staff.toObject(message.staff[j], options);
                        }
                        if (message.forecastVisit != null && message.hasOwnProperty("forecastVisit"))
                            object.forecastVisit = $root.palexy.streaming.v1.ForecastPrediction.toObject(message.forecastVisit, options);
                        if (message.forecastNetSales != null && message.hasOwnProperty("forecastNetSales"))
                            object.forecastNetSales = $root.palexy.streaming.v1.ForecastPrediction.toObject(message.forecastNetSales, options);
                        if (message.uriPath != null && message.hasOwnProperty("uriPath")) {
                            object.uriPath = message.uriPath;
                            if (options.oneofs)
                                object.aggregatedLevel_1Data = "uriPath";
                        }
                        if (message.fileData != null && message.hasOwnProperty("fileData")) {
                            object.fileData = options.bytes === String ? $util.base64.encode(message.fileData, 0, message.fileData.length) : options.bytes === Array ? Array.prototype.slice.call(message.fileData) : message.fileData;
                            if (options.oneofs)
                                object.aggregatedLevel_1Data = "fileData";
                        }
                        if (message.posTransactions && message.posTransactions.length) {
                            object.posTransactions = [];
                            for (var j = 0; j < message.posTransactions.length; ++j)
                                object.posTransactions[j] = $root.palexy.streaming.v1.PosTransaction.toObject(message.posTransactions[j], options);
                        }
                        if (message.productSectionMapping && message.productSectionMapping.length) {
                            object.productSectionMapping = [];
                            for (var j = 0; j < message.productSectionMapping.length; ++j)
                                object.productSectionMapping[j] = $root.palexy.streaming.v1.ProductSectionMapping.toObject(message.productSectionMapping[j], options);
                        }
                        if (message.staffAllocations && message.staffAllocations.length) {
                            object.staffAllocations = [];
                            for (var j = 0; j < message.staffAllocations.length; ++j)
                                object.staffAllocations[j] = $root.palexy.streaming.v1.ParsedStaffAllocation.toObject(message.staffAllocations[j], options);
                        }
                        if (message.bounceThresholdSeconds != null && message.hasOwnProperty("bounceThresholdSeconds"))
                            object.bounceThresholdSeconds = message.bounceThresholdSeconds;
                        if (message.includeReturningItemsForDiscountAnalysis != null && message.hasOwnProperty("includeReturningItemsForDiscountAnalysis"))
                            object.includeReturningItemsForDiscountAnalysis = message.includeReturningItemsForDiscountAnalysis;
                        return object;
                    };
    
                    /**
                     * Converts this AggregateLevel2Params to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AggregateLevel2Params
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AggregateLevel2Params.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AggregateLevel2Params;
                })();
    
                v1.CustomerJourneyService = (function() {
    
                    /**
                     * Constructs a new CustomerJourneyService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CustomerJourneyService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function CustomerJourneyService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (CustomerJourneyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CustomerJourneyService;
    
                    /**
                     * Creates new CustomerJourneyService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.CustomerJourneyService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {CustomerJourneyService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    CustomerJourneyService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.CustomerJourneyService#listCustomerJourney}.
                     * @memberof palexy.streaming.v1.CustomerJourneyService
                     * @typedef listCustomerJourneyCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListCustomerJourneyResponse} [response] ListCustomerJourneyResponse
                     */
    
                    /**
                     * Calls listCustomerJourney.
                     * @function listCustomerJourney
                     * @memberof palexy.streaming.v1.CustomerJourneyService
                     * @instance
                     * @param {palexy.streaming.v1.IListCustomerJourneyRequest} request ListCustomerJourneyRequest message or plain object
                     * @param {palexy.streaming.v1.CustomerJourneyService.listCustomerJourneyCallback} callback Node-style callback called with the error, if any, and ListCustomerJourneyResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CustomerJourneyService.prototype.listCustomerJourney = function listCustomerJourney(request, callback) {
                        return this.rpcCall(listCustomerJourney, $root.palexy.streaming.v1.ListCustomerJourneyRequest, $root.palexy.streaming.v1.ListCustomerJourneyResponse, request, callback);
                    }, "name", { value: "listCustomerJourney" });
    
                    /**
                     * Calls listCustomerJourney.
                     * @function listCustomerJourney
                     * @memberof palexy.streaming.v1.CustomerJourneyService
                     * @instance
                     * @param {palexy.streaming.v1.IListCustomerJourneyRequest} request ListCustomerJourneyRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListCustomerJourneyResponse>} Promise
                     * @variation 2
                     */
    
                    return CustomerJourneyService;
                })();
    
                v1.ListCustomerJourneyRequest = (function() {
    
                    /**
                     * Properties of a ListCustomerJourneyRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListCustomerJourneyRequest
                     * @property {number|null} [storeId] ListCustomerJourneyRequest storeId
                     * @property {string|null} [startDateId] ListCustomerJourneyRequest startDateId
                     * @property {string|null} [endDateId] ListCustomerJourneyRequest endDateId
                     * @property {number|null} [metadataTypeId] ListCustomerJourneyRequest metadataTypeId
                     * @property {google.protobuf.IBoolValue|null} [isBuyer] ListCustomerJourneyRequest isBuyer
                     */
    
                    /**
                     * Constructs a new ListCustomerJourneyRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListCustomerJourneyRequest.
                     * @implements IListCustomerJourneyRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListCustomerJourneyRequest=} [properties] Properties to set
                     */
                    function ListCustomerJourneyRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCustomerJourneyRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @instance
                     */
                    ListCustomerJourneyRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListCustomerJourneyRequest startDateId.
                     * @member {string} startDateId
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @instance
                     */
                    ListCustomerJourneyRequest.prototype.startDateId = "";
    
                    /**
                     * ListCustomerJourneyRequest endDateId.
                     * @member {string} endDateId
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @instance
                     */
                    ListCustomerJourneyRequest.prototype.endDateId = "";
    
                    /**
                     * ListCustomerJourneyRequest metadataTypeId.
                     * @member {number} metadataTypeId
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @instance
                     */
                    ListCustomerJourneyRequest.prototype.metadataTypeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListCustomerJourneyRequest isBuyer.
                     * @member {google.protobuf.IBoolValue|null|undefined} isBuyer
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @instance
                     */
                    ListCustomerJourneyRequest.prototype.isBuyer = null;
    
                    /**
                     * Creates a new ListCustomerJourneyRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCustomerJourneyRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListCustomerJourneyRequest} ListCustomerJourneyRequest instance
                     */
                    ListCustomerJourneyRequest.create = function create(properties) {
                        return new ListCustomerJourneyRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListCustomerJourneyRequest message. Does not implicitly {@link palexy.streaming.v1.ListCustomerJourneyRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCustomerJourneyRequest} message ListCustomerJourneyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCustomerJourneyRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.endDateId);
                        if (message.metadataTypeId != null && Object.hasOwnProperty.call(message, "metadataTypeId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.metadataTypeId);
                        if (message.isBuyer != null && Object.hasOwnProperty.call(message, "isBuyer"))
                            $root.google.protobuf.BoolValue.encode(message.isBuyer, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCustomerJourneyRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListCustomerJourneyRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @static
                     * @param {palexy.streaming.v1.IListCustomerJourneyRequest} message ListCustomerJourneyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCustomerJourneyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCustomerJourneyRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListCustomerJourneyRequest} ListCustomerJourneyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCustomerJourneyRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListCustomerJourneyRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.startDateId = reader.string();
                                break;
                            case 3:
                                message.endDateId = reader.string();
                                break;
                            case 4:
                                message.metadataTypeId = reader.int64();
                                break;
                            case 5:
                                message.isBuyer = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCustomerJourneyRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListCustomerJourneyRequest} ListCustomerJourneyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCustomerJourneyRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCustomerJourneyRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCustomerJourneyRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isString(message.startDateId))
                                return "startDateId: string expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isString(message.endDateId))
                                return "endDateId: string expected";
                        if (message.metadataTypeId != null && message.hasOwnProperty("metadataTypeId"))
                            if (!$util.isInteger(message.metadataTypeId) && !(message.metadataTypeId && $util.isInteger(message.metadataTypeId.low) && $util.isInteger(message.metadataTypeId.high)))
                                return "metadataTypeId: integer|Long expected";
                        if (message.isBuyer != null && message.hasOwnProperty("isBuyer")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isBuyer);
                            if (error)
                                return "isBuyer." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListCustomerJourneyRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListCustomerJourneyRequest} ListCustomerJourneyRequest
                     */
                    ListCustomerJourneyRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListCustomerJourneyRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListCustomerJourneyRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.startDateId != null)
                            message.startDateId = String(object.startDateId);
                        if (object.endDateId != null)
                            message.endDateId = String(object.endDateId);
                        if (object.metadataTypeId != null)
                            if ($util.Long)
                                (message.metadataTypeId = $util.Long.fromValue(object.metadataTypeId)).unsigned = false;
                            else if (typeof object.metadataTypeId === "string")
                                message.metadataTypeId = parseInt(object.metadataTypeId, 10);
                            else if (typeof object.metadataTypeId === "number")
                                message.metadataTypeId = object.metadataTypeId;
                            else if (typeof object.metadataTypeId === "object")
                                message.metadataTypeId = new $util.LongBits(object.metadataTypeId.low >>> 0, object.metadataTypeId.high >>> 0).toNumber();
                        if (object.isBuyer != null) {
                            if (typeof object.isBuyer !== "object")
                                throw TypeError(".palexy.streaming.v1.ListCustomerJourneyRequest.isBuyer: object expected");
                            message.isBuyer = $root.google.protobuf.BoolValue.fromObject(object.isBuyer);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCustomerJourneyRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @static
                     * @param {palexy.streaming.v1.ListCustomerJourneyRequest} message ListCustomerJourneyRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCustomerJourneyRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.startDateId = "";
                            object.endDateId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataTypeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataTypeId = options.longs === String ? "0" : 0;
                            object.isBuyer = null;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.metadataTypeId != null && message.hasOwnProperty("metadataTypeId"))
                            if (typeof message.metadataTypeId === "number")
                                object.metadataTypeId = options.longs === String ? String(message.metadataTypeId) : message.metadataTypeId;
                            else
                                object.metadataTypeId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataTypeId) : options.longs === Number ? new $util.LongBits(message.metadataTypeId.low >>> 0, message.metadataTypeId.high >>> 0).toNumber() : message.metadataTypeId;
                        if (message.isBuyer != null && message.hasOwnProperty("isBuyer"))
                            object.isBuyer = $root.google.protobuf.BoolValue.toObject(message.isBuyer, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListCustomerJourneyRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListCustomerJourneyRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCustomerJourneyRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCustomerJourneyRequest;
                })();
    
                v1.ListCustomerJourneyResponse = (function() {
    
                    /**
                     * Properties of a ListCustomerJourneyResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListCustomerJourneyResponse
                     * @property {Array.<palexy.streaming.v1.ICustomerJourney>|null} [journeys] ListCustomerJourneyResponse journeys
                     */
    
                    /**
                     * Constructs a new ListCustomerJourneyResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListCustomerJourneyResponse.
                     * @implements IListCustomerJourneyResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListCustomerJourneyResponse=} [properties] Properties to set
                     */
                    function ListCustomerJourneyResponse(properties) {
                        this.journeys = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListCustomerJourneyResponse journeys.
                     * @member {Array.<palexy.streaming.v1.ICustomerJourney>} journeys
                     * @memberof palexy.streaming.v1.ListCustomerJourneyResponse
                     * @instance
                     */
                    ListCustomerJourneyResponse.prototype.journeys = $util.emptyArray;
    
                    /**
                     * Creates a new ListCustomerJourneyResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListCustomerJourneyResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCustomerJourneyResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListCustomerJourneyResponse} ListCustomerJourneyResponse instance
                     */
                    ListCustomerJourneyResponse.create = function create(properties) {
                        return new ListCustomerJourneyResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListCustomerJourneyResponse message. Does not implicitly {@link palexy.streaming.v1.ListCustomerJourneyResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListCustomerJourneyResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCustomerJourneyResponse} message ListCustomerJourneyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCustomerJourneyResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.journeys != null && message.journeys.length)
                            for (var i = 0; i < message.journeys.length; ++i)
                                $root.palexy.streaming.v1.CustomerJourney.encode(message.journeys[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListCustomerJourneyResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListCustomerJourneyResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListCustomerJourneyResponse
                     * @static
                     * @param {palexy.streaming.v1.IListCustomerJourneyResponse} message ListCustomerJourneyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListCustomerJourneyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListCustomerJourneyResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListCustomerJourneyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListCustomerJourneyResponse} ListCustomerJourneyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCustomerJourneyResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListCustomerJourneyResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.journeys && message.journeys.length))
                                    message.journeys = [];
                                message.journeys.push($root.palexy.streaming.v1.CustomerJourney.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListCustomerJourneyResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListCustomerJourneyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListCustomerJourneyResponse} ListCustomerJourneyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListCustomerJourneyResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListCustomerJourneyResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListCustomerJourneyResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListCustomerJourneyResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.journeys != null && message.hasOwnProperty("journeys")) {
                            if (!Array.isArray(message.journeys))
                                return "journeys: array expected";
                            for (var i = 0; i < message.journeys.length; ++i) {
                                var error = $root.palexy.streaming.v1.CustomerJourney.verify(message.journeys[i]);
                                if (error)
                                    return "journeys." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListCustomerJourneyResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListCustomerJourneyResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListCustomerJourneyResponse} ListCustomerJourneyResponse
                     */
                    ListCustomerJourneyResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListCustomerJourneyResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListCustomerJourneyResponse();
                        if (object.journeys) {
                            if (!Array.isArray(object.journeys))
                                throw TypeError(".palexy.streaming.v1.ListCustomerJourneyResponse.journeys: array expected");
                            message.journeys = [];
                            for (var i = 0; i < object.journeys.length; ++i) {
                                if (typeof object.journeys[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListCustomerJourneyResponse.journeys: object expected");
                                message.journeys[i] = $root.palexy.streaming.v1.CustomerJourney.fromObject(object.journeys[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListCustomerJourneyResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListCustomerJourneyResponse
                     * @static
                     * @param {palexy.streaming.v1.ListCustomerJourneyResponse} message ListCustomerJourneyResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListCustomerJourneyResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.journeys = [];
                        if (message.journeys && message.journeys.length) {
                            object.journeys = [];
                            for (var j = 0; j < message.journeys.length; ++j)
                                object.journeys[j] = $root.palexy.streaming.v1.CustomerJourney.toObject(message.journeys[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListCustomerJourneyResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListCustomerJourneyResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListCustomerJourneyResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListCustomerJourneyResponse;
                })();
    
                v1.CustomerJourney = (function() {
    
                    /**
                     * Properties of a CustomerJourney.
                     * @memberof palexy.streaming.v1
                     * @interface ICustomerJourney
                     * @property {string|null} [visitorId] CustomerJourney visitorId
                     * @property {Array.<palexy.streaming.v1.ICustomerJourneyStep>|null} [steps] CustomerJourney steps
                     */
    
                    /**
                     * Constructs a new CustomerJourney.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CustomerJourney.
                     * @implements ICustomerJourney
                     * @constructor
                     * @param {palexy.streaming.v1.ICustomerJourney=} [properties] Properties to set
                     */
                    function CustomerJourney(properties) {
                        this.steps = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CustomerJourney visitorId.
                     * @member {string} visitorId
                     * @memberof palexy.streaming.v1.CustomerJourney
                     * @instance
                     */
                    CustomerJourney.prototype.visitorId = "";
    
                    /**
                     * CustomerJourney steps.
                     * @member {Array.<palexy.streaming.v1.ICustomerJourneyStep>} steps
                     * @memberof palexy.streaming.v1.CustomerJourney
                     * @instance
                     */
                    CustomerJourney.prototype.steps = $util.emptyArray;
    
                    /**
                     * Creates a new CustomerJourney instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CustomerJourney
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourney=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CustomerJourney} CustomerJourney instance
                     */
                    CustomerJourney.create = function create(properties) {
                        return new CustomerJourney(properties);
                    };
    
                    /**
                     * Encodes the specified CustomerJourney message. Does not implicitly {@link palexy.streaming.v1.CustomerJourney.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CustomerJourney
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourney} message CustomerJourney message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerJourney.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.visitorId != null && Object.hasOwnProperty.call(message, "visitorId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.visitorId);
                        if (message.steps != null && message.steps.length)
                            for (var i = 0; i < message.steps.length; ++i)
                                $root.palexy.streaming.v1.CustomerJourneyStep.encode(message.steps[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CustomerJourney message, length delimited. Does not implicitly {@link palexy.streaming.v1.CustomerJourney.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CustomerJourney
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourney} message CustomerJourney message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerJourney.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CustomerJourney message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CustomerJourney
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CustomerJourney} CustomerJourney
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerJourney.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CustomerJourney();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.visitorId = reader.string();
                                break;
                            case 2:
                                if (!(message.steps && message.steps.length))
                                    message.steps = [];
                                message.steps.push($root.palexy.streaming.v1.CustomerJourneyStep.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CustomerJourney message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CustomerJourney
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CustomerJourney} CustomerJourney
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerJourney.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CustomerJourney message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CustomerJourney
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomerJourney.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.visitorId != null && message.hasOwnProperty("visitorId"))
                            if (!$util.isString(message.visitorId))
                                return "visitorId: string expected";
                        if (message.steps != null && message.hasOwnProperty("steps")) {
                            if (!Array.isArray(message.steps))
                                return "steps: array expected";
                            for (var i = 0; i < message.steps.length; ++i) {
                                var error = $root.palexy.streaming.v1.CustomerJourneyStep.verify(message.steps[i]);
                                if (error)
                                    return "steps." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CustomerJourney message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CustomerJourney
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CustomerJourney} CustomerJourney
                     */
                    CustomerJourney.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CustomerJourney)
                            return object;
                        var message = new $root.palexy.streaming.v1.CustomerJourney();
                        if (object.visitorId != null)
                            message.visitorId = String(object.visitorId);
                        if (object.steps) {
                            if (!Array.isArray(object.steps))
                                throw TypeError(".palexy.streaming.v1.CustomerJourney.steps: array expected");
                            message.steps = [];
                            for (var i = 0; i < object.steps.length; ++i) {
                                if (typeof object.steps[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.CustomerJourney.steps: object expected");
                                message.steps[i] = $root.palexy.streaming.v1.CustomerJourneyStep.fromObject(object.steps[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CustomerJourney message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CustomerJourney
                     * @static
                     * @param {palexy.streaming.v1.CustomerJourney} message CustomerJourney
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomerJourney.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.steps = [];
                        if (options.defaults)
                            object.visitorId = "";
                        if (message.visitorId != null && message.hasOwnProperty("visitorId"))
                            object.visitorId = message.visitorId;
                        if (message.steps && message.steps.length) {
                            object.steps = [];
                            for (var j = 0; j < message.steps.length; ++j)
                                object.steps[j] = $root.palexy.streaming.v1.CustomerJourneyStep.toObject(message.steps[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CustomerJourney to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CustomerJourney
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomerJourney.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CustomerJourney;
                })();
    
                v1.CustomerJourneyStep = (function() {
    
                    /**
                     * Properties of a CustomerJourneyStep.
                     * @memberof palexy.streaming.v1
                     * @interface ICustomerJourneyStep
                     * @property {string|null} [startTime] CustomerJourneyStep startTime
                     * @property {string|null} [endTime] CustomerJourneyStep endTime
                     * @property {string|null} [actionType] CustomerJourneyStep actionType
                     * @property {palexy.streaming.v1.ICustomerJourneySectionVisitDetail|null} [sectionVisitDetail] CustomerJourneyStep sectionVisitDetail
                     * @property {palexy.streaming.v1.ICustomerJourneyTransactionDetail|null} [transactionDetail] CustomerJourneyStep transactionDetail
                     */
    
                    /**
                     * Constructs a new CustomerJourneyStep.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CustomerJourneyStep.
                     * @implements ICustomerJourneyStep
                     * @constructor
                     * @param {palexy.streaming.v1.ICustomerJourneyStep=} [properties] Properties to set
                     */
                    function CustomerJourneyStep(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CustomerJourneyStep startTime.
                     * @member {string} startTime
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @instance
                     */
                    CustomerJourneyStep.prototype.startTime = "";
    
                    /**
                     * CustomerJourneyStep endTime.
                     * @member {string} endTime
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @instance
                     */
                    CustomerJourneyStep.prototype.endTime = "";
    
                    /**
                     * CustomerJourneyStep actionType.
                     * @member {string} actionType
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @instance
                     */
                    CustomerJourneyStep.prototype.actionType = "";
    
                    /**
                     * CustomerJourneyStep sectionVisitDetail.
                     * @member {palexy.streaming.v1.ICustomerJourneySectionVisitDetail|null|undefined} sectionVisitDetail
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @instance
                     */
                    CustomerJourneyStep.prototype.sectionVisitDetail = null;
    
                    /**
                     * CustomerJourneyStep transactionDetail.
                     * @member {palexy.streaming.v1.ICustomerJourneyTransactionDetail|null|undefined} transactionDetail
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @instance
                     */
                    CustomerJourneyStep.prototype.transactionDetail = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * CustomerJourneyStep detail.
                     * @member {"sectionVisitDetail"|"transactionDetail"|undefined} detail
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @instance
                     */
                    Object.defineProperty(CustomerJourneyStep.prototype, "detail", {
                        get: $util.oneOfGetter($oneOfFields = ["sectionVisitDetail", "transactionDetail"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new CustomerJourneyStep instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourneyStep=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CustomerJourneyStep} CustomerJourneyStep instance
                     */
                    CustomerJourneyStep.create = function create(properties) {
                        return new CustomerJourneyStep(properties);
                    };
    
                    /**
                     * Encodes the specified CustomerJourneyStep message. Does not implicitly {@link palexy.streaming.v1.CustomerJourneyStep.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourneyStep} message CustomerJourneyStep message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerJourneyStep.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.startTime);
                        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.endTime);
                        if (message.actionType != null && Object.hasOwnProperty.call(message, "actionType"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.actionType);
                        if (message.sectionVisitDetail != null && Object.hasOwnProperty.call(message, "sectionVisitDetail"))
                            $root.palexy.streaming.v1.CustomerJourneySectionVisitDetail.encode(message.sectionVisitDetail, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.transactionDetail != null && Object.hasOwnProperty.call(message, "transactionDetail"))
                            $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.encode(message.transactionDetail, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CustomerJourneyStep message, length delimited. Does not implicitly {@link palexy.streaming.v1.CustomerJourneyStep.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourneyStep} message CustomerJourneyStep message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerJourneyStep.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CustomerJourneyStep message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CustomerJourneyStep} CustomerJourneyStep
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerJourneyStep.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CustomerJourneyStep();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startTime = reader.string();
                                break;
                            case 2:
                                message.endTime = reader.string();
                                break;
                            case 3:
                                message.actionType = reader.string();
                                break;
                            case 4:
                                message.sectionVisitDetail = $root.palexy.streaming.v1.CustomerJourneySectionVisitDetail.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.transactionDetail = $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CustomerJourneyStep message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CustomerJourneyStep} CustomerJourneyStep
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerJourneyStep.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CustomerJourneyStep message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomerJourneyStep.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            if (!$util.isString(message.startTime))
                                return "startTime: string expected";
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            if (!$util.isString(message.endTime))
                                return "endTime: string expected";
                        if (message.actionType != null && message.hasOwnProperty("actionType"))
                            if (!$util.isString(message.actionType))
                                return "actionType: string expected";
                        if (message.sectionVisitDetail != null && message.hasOwnProperty("sectionVisitDetail")) {
                            properties.detail = 1;
                            {
                                var error = $root.palexy.streaming.v1.CustomerJourneySectionVisitDetail.verify(message.sectionVisitDetail);
                                if (error)
                                    return "sectionVisitDetail." + error;
                            }
                        }
                        if (message.transactionDetail != null && message.hasOwnProperty("transactionDetail")) {
                            if (properties.detail === 1)
                                return "detail: multiple values";
                            properties.detail = 1;
                            {
                                var error = $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.verify(message.transactionDetail);
                                if (error)
                                    return "transactionDetail." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CustomerJourneyStep message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CustomerJourneyStep} CustomerJourneyStep
                     */
                    CustomerJourneyStep.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CustomerJourneyStep)
                            return object;
                        var message = new $root.palexy.streaming.v1.CustomerJourneyStep();
                        if (object.startTime != null)
                            message.startTime = String(object.startTime);
                        if (object.endTime != null)
                            message.endTime = String(object.endTime);
                        if (object.actionType != null)
                            message.actionType = String(object.actionType);
                        if (object.sectionVisitDetail != null) {
                            if (typeof object.sectionVisitDetail !== "object")
                                throw TypeError(".palexy.streaming.v1.CustomerJourneyStep.sectionVisitDetail: object expected");
                            message.sectionVisitDetail = $root.palexy.streaming.v1.CustomerJourneySectionVisitDetail.fromObject(object.sectionVisitDetail);
                        }
                        if (object.transactionDetail != null) {
                            if (typeof object.transactionDetail !== "object")
                                throw TypeError(".palexy.streaming.v1.CustomerJourneyStep.transactionDetail: object expected");
                            message.transactionDetail = $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.fromObject(object.transactionDetail);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CustomerJourneyStep message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @static
                     * @param {palexy.streaming.v1.CustomerJourneyStep} message CustomerJourneyStep
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomerJourneyStep.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startTime = "";
                            object.endTime = "";
                            object.actionType = "";
                        }
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            object.startTime = message.startTime;
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            object.endTime = message.endTime;
                        if (message.actionType != null && message.hasOwnProperty("actionType"))
                            object.actionType = message.actionType;
                        if (message.sectionVisitDetail != null && message.hasOwnProperty("sectionVisitDetail")) {
                            object.sectionVisitDetail = $root.palexy.streaming.v1.CustomerJourneySectionVisitDetail.toObject(message.sectionVisitDetail, options);
                            if (options.oneofs)
                                object.detail = "sectionVisitDetail";
                        }
                        if (message.transactionDetail != null && message.hasOwnProperty("transactionDetail")) {
                            object.transactionDetail = $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.toObject(message.transactionDetail, options);
                            if (options.oneofs)
                                object.detail = "transactionDetail";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CustomerJourneyStep to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CustomerJourneyStep
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomerJourneyStep.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CustomerJourneyStep;
                })();
    
                v1.CustomerJourneySectionVisitDetail = (function() {
    
                    /**
                     * Properties of a CustomerJourneySectionVisitDetail.
                     * @memberof palexy.streaming.v1
                     * @interface ICustomerJourneySectionVisitDetail
                     * @property {number|null} [metadataValueId] CustomerJourneySectionVisitDetail metadataValueId
                     * @property {string|null} [metadataValueName] CustomerJourneySectionVisitDetail metadataValueName
                     */
    
                    /**
                     * Constructs a new CustomerJourneySectionVisitDetail.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CustomerJourneySectionVisitDetail.
                     * @implements ICustomerJourneySectionVisitDetail
                     * @constructor
                     * @param {palexy.streaming.v1.ICustomerJourneySectionVisitDetail=} [properties] Properties to set
                     */
                    function CustomerJourneySectionVisitDetail(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CustomerJourneySectionVisitDetail metadataValueId.
                     * @member {number} metadataValueId
                     * @memberof palexy.streaming.v1.CustomerJourneySectionVisitDetail
                     * @instance
                     */
                    CustomerJourneySectionVisitDetail.prototype.metadataValueId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CustomerJourneySectionVisitDetail metadataValueName.
                     * @member {string} metadataValueName
                     * @memberof palexy.streaming.v1.CustomerJourneySectionVisitDetail
                     * @instance
                     */
                    CustomerJourneySectionVisitDetail.prototype.metadataValueName = "";
    
                    /**
                     * Creates a new CustomerJourneySectionVisitDetail instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CustomerJourneySectionVisitDetail
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourneySectionVisitDetail=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CustomerJourneySectionVisitDetail} CustomerJourneySectionVisitDetail instance
                     */
                    CustomerJourneySectionVisitDetail.create = function create(properties) {
                        return new CustomerJourneySectionVisitDetail(properties);
                    };
    
                    /**
                     * Encodes the specified CustomerJourneySectionVisitDetail message. Does not implicitly {@link palexy.streaming.v1.CustomerJourneySectionVisitDetail.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CustomerJourneySectionVisitDetail
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourneySectionVisitDetail} message CustomerJourneySectionVisitDetail message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerJourneySectionVisitDetail.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.metadataValueId != null && Object.hasOwnProperty.call(message, "metadataValueId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.metadataValueId);
                        if (message.metadataValueName != null && Object.hasOwnProperty.call(message, "metadataValueName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.metadataValueName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CustomerJourneySectionVisitDetail message, length delimited. Does not implicitly {@link palexy.streaming.v1.CustomerJourneySectionVisitDetail.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CustomerJourneySectionVisitDetail
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourneySectionVisitDetail} message CustomerJourneySectionVisitDetail message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerJourneySectionVisitDetail.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CustomerJourneySectionVisitDetail message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CustomerJourneySectionVisitDetail
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CustomerJourneySectionVisitDetail} CustomerJourneySectionVisitDetail
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerJourneySectionVisitDetail.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CustomerJourneySectionVisitDetail();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.metadataValueId = reader.int64();
                                break;
                            case 2:
                                message.metadataValueName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CustomerJourneySectionVisitDetail message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CustomerJourneySectionVisitDetail
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CustomerJourneySectionVisitDetail} CustomerJourneySectionVisitDetail
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerJourneySectionVisitDetail.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CustomerJourneySectionVisitDetail message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CustomerJourneySectionVisitDetail
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomerJourneySectionVisitDetail.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.metadataValueId != null && message.hasOwnProperty("metadataValueId"))
                            if (!$util.isInteger(message.metadataValueId) && !(message.metadataValueId && $util.isInteger(message.metadataValueId.low) && $util.isInteger(message.metadataValueId.high)))
                                return "metadataValueId: integer|Long expected";
                        if (message.metadataValueName != null && message.hasOwnProperty("metadataValueName"))
                            if (!$util.isString(message.metadataValueName))
                                return "metadataValueName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a CustomerJourneySectionVisitDetail message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CustomerJourneySectionVisitDetail
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CustomerJourneySectionVisitDetail} CustomerJourneySectionVisitDetail
                     */
                    CustomerJourneySectionVisitDetail.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CustomerJourneySectionVisitDetail)
                            return object;
                        var message = new $root.palexy.streaming.v1.CustomerJourneySectionVisitDetail();
                        if (object.metadataValueId != null)
                            if ($util.Long)
                                (message.metadataValueId = $util.Long.fromValue(object.metadataValueId)).unsigned = false;
                            else if (typeof object.metadataValueId === "string")
                                message.metadataValueId = parseInt(object.metadataValueId, 10);
                            else if (typeof object.metadataValueId === "number")
                                message.metadataValueId = object.metadataValueId;
                            else if (typeof object.metadataValueId === "object")
                                message.metadataValueId = new $util.LongBits(object.metadataValueId.low >>> 0, object.metadataValueId.high >>> 0).toNumber();
                        if (object.metadataValueName != null)
                            message.metadataValueName = String(object.metadataValueName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CustomerJourneySectionVisitDetail message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CustomerJourneySectionVisitDetail
                     * @static
                     * @param {palexy.streaming.v1.CustomerJourneySectionVisitDetail} message CustomerJourneySectionVisitDetail
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomerJourneySectionVisitDetail.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.metadataValueId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.metadataValueId = options.longs === String ? "0" : 0;
                            object.metadataValueName = "";
                        }
                        if (message.metadataValueId != null && message.hasOwnProperty("metadataValueId"))
                            if (typeof message.metadataValueId === "number")
                                object.metadataValueId = options.longs === String ? String(message.metadataValueId) : message.metadataValueId;
                            else
                                object.metadataValueId = options.longs === String ? $util.Long.prototype.toString.call(message.metadataValueId) : options.longs === Number ? new $util.LongBits(message.metadataValueId.low >>> 0, message.metadataValueId.high >>> 0).toNumber() : message.metadataValueId;
                        if (message.metadataValueName != null && message.hasOwnProperty("metadataValueName"))
                            object.metadataValueName = message.metadataValueName;
                        return object;
                    };
    
                    /**
                     * Converts this CustomerJourneySectionVisitDetail to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CustomerJourneySectionVisitDetail
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomerJourneySectionVisitDetail.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CustomerJourneySectionVisitDetail;
                })();
    
                v1.CustomerJourneyTransactionDetail = (function() {
    
                    /**
                     * Properties of a CustomerJourneyTransactionDetail.
                     * @memberof palexy.streaming.v1
                     * @interface ICustomerJourneyTransactionDetail
                     * @property {Array.<palexy.streaming.v1.CustomerJourneyTransactionDetail.IItem>|null} [items] CustomerJourneyTransactionDetail items
                     */
    
                    /**
                     * Constructs a new CustomerJourneyTransactionDetail.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CustomerJourneyTransactionDetail.
                     * @implements ICustomerJourneyTransactionDetail
                     * @constructor
                     * @param {palexy.streaming.v1.ICustomerJourneyTransactionDetail=} [properties] Properties to set
                     */
                    function CustomerJourneyTransactionDetail(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CustomerJourneyTransactionDetail items.
                     * @member {Array.<palexy.streaming.v1.CustomerJourneyTransactionDetail.IItem>} items
                     * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                     * @instance
                     */
                    CustomerJourneyTransactionDetail.prototype.items = $util.emptyArray;
    
                    /**
                     * Creates a new CustomerJourneyTransactionDetail instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourneyTransactionDetail=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CustomerJourneyTransactionDetail} CustomerJourneyTransactionDetail instance
                     */
                    CustomerJourneyTransactionDetail.create = function create(properties) {
                        return new CustomerJourneyTransactionDetail(properties);
                    };
    
                    /**
                     * Encodes the specified CustomerJourneyTransactionDetail message. Does not implicitly {@link palexy.streaming.v1.CustomerJourneyTransactionDetail.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourneyTransactionDetail} message CustomerJourneyTransactionDetail message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerJourneyTransactionDetail.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.Item.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CustomerJourneyTransactionDetail message, length delimited. Does not implicitly {@link palexy.streaming.v1.CustomerJourneyTransactionDetail.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                     * @static
                     * @param {palexy.streaming.v1.ICustomerJourneyTransactionDetail} message CustomerJourneyTransactionDetail message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerJourneyTransactionDetail.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CustomerJourneyTransactionDetail message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CustomerJourneyTransactionDetail} CustomerJourneyTransactionDetail
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerJourneyTransactionDetail.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CustomerJourneyTransactionDetail();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.palexy.streaming.v1.CustomerJourneyTransactionDetail.Item.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CustomerJourneyTransactionDetail message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CustomerJourneyTransactionDetail} CustomerJourneyTransactionDetail
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerJourneyTransactionDetail.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CustomerJourneyTransactionDetail message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomerJourneyTransactionDetail.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.Item.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CustomerJourneyTransactionDetail message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CustomerJourneyTransactionDetail} CustomerJourneyTransactionDetail
                     */
                    CustomerJourneyTransactionDetail.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CustomerJourneyTransactionDetail)
                            return object;
                        var message = new $root.palexy.streaming.v1.CustomerJourneyTransactionDetail();
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".palexy.streaming.v1.CustomerJourneyTransactionDetail.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.CustomerJourneyTransactionDetail.items: object expected");
                                message.items[i] = $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.Item.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CustomerJourneyTransactionDetail message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                     * @static
                     * @param {palexy.streaming.v1.CustomerJourneyTransactionDetail} message CustomerJourneyTransactionDetail
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomerJourneyTransactionDetail.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.Item.toObject(message.items[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CustomerJourneyTransactionDetail to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomerJourneyTransactionDetail.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    CustomerJourneyTransactionDetail.Item = (function() {
    
                        /**
                         * Properties of an Item.
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                         * @interface IItem
                         * @property {number|null} [itemId] Item itemId
                         * @property {string|null} [itemName] Item itemName
                         * @property {number|null} [quantity] Item quantity
                         */
    
                        /**
                         * Constructs a new Item.
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail
                         * @classdesc Represents an Item.
                         * @implements IItem
                         * @constructor
                         * @param {palexy.streaming.v1.CustomerJourneyTransactionDetail.IItem=} [properties] Properties to set
                         */
                        function Item(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * Item itemId.
                         * @member {number} itemId
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @instance
                         */
                        Item.prototype.itemId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * Item itemName.
                         * @member {string} itemName
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @instance
                         */
                        Item.prototype.itemName = "";
    
                        /**
                         * Item quantity.
                         * @member {number} quantity
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @instance
                         */
                        Item.prototype.quantity = 0;
    
                        /**
                         * Creates a new Item instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @static
                         * @param {palexy.streaming.v1.CustomerJourneyTransactionDetail.IItem=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.CustomerJourneyTransactionDetail.Item} Item instance
                         */
                        Item.create = function create(properties) {
                            return new Item(properties);
                        };
    
                        /**
                         * Encodes the specified Item message. Does not implicitly {@link palexy.streaming.v1.CustomerJourneyTransactionDetail.Item.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @static
                         * @param {palexy.streaming.v1.CustomerJourneyTransactionDetail.IItem} message Item message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Item.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.itemId != null && Object.hasOwnProperty.call(message, "itemId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.itemId);
                            if (message.itemName != null && Object.hasOwnProperty.call(message, "itemName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.itemName);
                            if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                                writer.uint32(/* id 3, wireType 1 =*/25).double(message.quantity);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified Item message, length delimited. Does not implicitly {@link palexy.streaming.v1.CustomerJourneyTransactionDetail.Item.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @static
                         * @param {palexy.streaming.v1.CustomerJourneyTransactionDetail.IItem} message Item message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Item.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes an Item message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.CustomerJourneyTransactionDetail.Item} Item
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Item.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.Item();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.itemId = reader.int64();
                                    break;
                                case 2:
                                    message.itemName = reader.string();
                                    break;
                                case 3:
                                    message.quantity = reader.double();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes an Item message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.CustomerJourneyTransactionDetail.Item} Item
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Item.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies an Item message.
                         * @function verify
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Item.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.itemId != null && message.hasOwnProperty("itemId"))
                                if (!$util.isInteger(message.itemId) && !(message.itemId && $util.isInteger(message.itemId.low) && $util.isInteger(message.itemId.high)))
                                    return "itemId: integer|Long expected";
                            if (message.itemName != null && message.hasOwnProperty("itemName"))
                                if (!$util.isString(message.itemName))
                                    return "itemName: string expected";
                            if (message.quantity != null && message.hasOwnProperty("quantity"))
                                if (typeof message.quantity !== "number")
                                    return "quantity: number expected";
                            return null;
                        };
    
                        /**
                         * Creates an Item message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.CustomerJourneyTransactionDetail.Item} Item
                         */
                        Item.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.Item)
                                return object;
                            var message = new $root.palexy.streaming.v1.CustomerJourneyTransactionDetail.Item();
                            if (object.itemId != null)
                                if ($util.Long)
                                    (message.itemId = $util.Long.fromValue(object.itemId)).unsigned = false;
                                else if (typeof object.itemId === "string")
                                    message.itemId = parseInt(object.itemId, 10);
                                else if (typeof object.itemId === "number")
                                    message.itemId = object.itemId;
                                else if (typeof object.itemId === "object")
                                    message.itemId = new $util.LongBits(object.itemId.low >>> 0, object.itemId.high >>> 0).toNumber();
                            if (object.itemName != null)
                                message.itemName = String(object.itemName);
                            if (object.quantity != null)
                                message.quantity = Number(object.quantity);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from an Item message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @static
                         * @param {palexy.streaming.v1.CustomerJourneyTransactionDetail.Item} message Item
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Item.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.itemId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.itemId = options.longs === String ? "0" : 0;
                                object.itemName = "";
                                object.quantity = 0;
                            }
                            if (message.itemId != null && message.hasOwnProperty("itemId"))
                                if (typeof message.itemId === "number")
                                    object.itemId = options.longs === String ? String(message.itemId) : message.itemId;
                                else
                                    object.itemId = options.longs === String ? $util.Long.prototype.toString.call(message.itemId) : options.longs === Number ? new $util.LongBits(message.itemId.low >>> 0, message.itemId.high >>> 0).toNumber() : message.itemId;
                            if (message.itemName != null && message.hasOwnProperty("itemName"))
                                object.itemName = message.itemName;
                            if (message.quantity != null && message.hasOwnProperty("quantity"))
                                object.quantity = options.json && !isFinite(message.quantity) ? String(message.quantity) : message.quantity;
                            return object;
                        };
    
                        /**
                         * Converts this Item to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.CustomerJourneyTransactionDetail.Item
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Item.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return Item;
                    })();
    
                    return CustomerJourneyTransactionDetail;
                })();
    
                v1.StoreEventService = (function() {
    
                    /**
                     * Constructs a new StoreEventService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreEventService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StoreEventService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (StoreEventService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StoreEventService;
    
                    /**
                     * Creates new StoreEventService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StoreEventService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StoreEventService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreEventService#createStoreEvent}.
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @typedef CreateStoreEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreEvent} [response] StoreEvent
                     */
    
                    /**
                     * Calls CreateStoreEvent.
                     * @function createStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStoreEventRequest} request CreateStoreEventRequest message or plain object
                     * @param {palexy.streaming.v1.StoreEventService.CreateStoreEventCallback} callback Node-style callback called with the error, if any, and StoreEvent
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreEventService.prototype.createStoreEvent = function createStoreEvent(request, callback) {
                        return this.rpcCall(createStoreEvent, $root.palexy.streaming.v1.CreateStoreEventRequest, $root.palexy.streaming.v1.StoreEvent, request, callback);
                    }, "name", { value: "CreateStoreEvent" });
    
                    /**
                     * Calls CreateStoreEvent.
                     * @function createStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStoreEventRequest} request CreateStoreEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreEvent>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreEventService#batchCreateStoreEvent}.
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @typedef BatchCreateStoreEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchCreateStoreEventResponse} [response] BatchCreateStoreEventResponse
                     */
    
                    /**
                     * Calls BatchCreateStoreEvent.
                     * @function batchCreateStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreateStoreEventRequest} request BatchCreateStoreEventRequest message or plain object
                     * @param {palexy.streaming.v1.StoreEventService.BatchCreateStoreEventCallback} callback Node-style callback called with the error, if any, and BatchCreateStoreEventResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreEventService.prototype.batchCreateStoreEvent = function batchCreateStoreEvent(request, callback) {
                        return this.rpcCall(batchCreateStoreEvent, $root.palexy.streaming.v1.BatchCreateStoreEventRequest, $root.palexy.streaming.v1.BatchCreateStoreEventResponse, request, callback);
                    }, "name", { value: "BatchCreateStoreEvent" });
    
                    /**
                     * Calls BatchCreateStoreEvent.
                     * @function batchCreateStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreateStoreEventRequest} request BatchCreateStoreEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchCreateStoreEventResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreEventService#getStoreEvent}.
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @typedef GetStoreEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreEvent} [response] StoreEvent
                     */
    
                    /**
                     * Calls GetStoreEvent.
                     * @function getStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoreEventRequest} request GetStoreEventRequest message or plain object
                     * @param {palexy.streaming.v1.StoreEventService.GetStoreEventCallback} callback Node-style callback called with the error, if any, and StoreEvent
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreEventService.prototype.getStoreEvent = function getStoreEvent(request, callback) {
                        return this.rpcCall(getStoreEvent, $root.palexy.streaming.v1.GetStoreEventRequest, $root.palexy.streaming.v1.StoreEvent, request, callback);
                    }, "name", { value: "GetStoreEvent" });
    
                    /**
                     * Calls GetStoreEvent.
                     * @function getStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStoreEventRequest} request GetStoreEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreEvent>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreEventService#updateStoreEvent}.
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @typedef UpdateStoreEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreEvent} [response] StoreEvent
                     */
    
                    /**
                     * Calls UpdateStoreEvent.
                     * @function updateStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStoreEventRequest} request UpdateStoreEventRequest message or plain object
                     * @param {palexy.streaming.v1.StoreEventService.UpdateStoreEventCallback} callback Node-style callback called with the error, if any, and StoreEvent
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreEventService.prototype.updateStoreEvent = function updateStoreEvent(request, callback) {
                        return this.rpcCall(updateStoreEvent, $root.palexy.streaming.v1.UpdateStoreEventRequest, $root.palexy.streaming.v1.StoreEvent, request, callback);
                    }, "name", { value: "UpdateStoreEvent" });
    
                    /**
                     * Calls UpdateStoreEvent.
                     * @function updateStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStoreEventRequest} request UpdateStoreEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreEvent>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreEventService#deleteStoreEvent}.
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @typedef DeleteStoreEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteStoreEvent.
                     * @function deleteStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteStoreEventRequest} request DeleteStoreEventRequest message or plain object
                     * @param {palexy.streaming.v1.StoreEventService.DeleteStoreEventCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreEventService.prototype.deleteStoreEvent = function deleteStoreEvent(request, callback) {
                        return this.rpcCall(deleteStoreEvent, $root.palexy.streaming.v1.DeleteStoreEventRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteStoreEvent" });
    
                    /**
                     * Calls DeleteStoreEvent.
                     * @function deleteStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteStoreEventRequest} request DeleteStoreEventRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreEventService#listStoreEvent}.
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @typedef ListStoreEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStoreEventResponse} [response] ListStoreEventResponse
                     */
    
                    /**
                     * Calls ListStoreEvent.
                     * @function listStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreEventRequest} request ListStoreEventRequest message or plain object
                     * @param {palexy.streaming.v1.StoreEventService.ListStoreEventCallback} callback Node-style callback called with the error, if any, and ListStoreEventResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreEventService.prototype.listStoreEvent = function listStoreEvent(request, callback) {
                        return this.rpcCall(listStoreEvent, $root.palexy.streaming.v1.ListStoreEventRequest, $root.palexy.streaming.v1.ListStoreEventResponse, request, callback);
                    }, "name", { value: "ListStoreEvent" });
    
                    /**
                     * Calls ListStoreEvent.
                     * @function listStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreEventRequest} request ListStoreEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStoreEventResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreEventService#listStoreEventReviewLog}.
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @typedef ListStoreEventReviewLogCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStoreEventReviewLogResponse} [response] ListStoreEventReviewLogResponse
                     */
    
                    /**
                     * Calls ListStoreEventReviewLog.
                     * @function listStoreEventReviewLog
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreEventReviewLogRequest} request ListStoreEventReviewLogRequest message or plain object
                     * @param {palexy.streaming.v1.StoreEventService.ListStoreEventReviewLogCallback} callback Node-style callback called with the error, if any, and ListStoreEventReviewLogResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreEventService.prototype.listStoreEventReviewLog = function listStoreEventReviewLog(request, callback) {
                        return this.rpcCall(listStoreEventReviewLog, $root.palexy.streaming.v1.ListStoreEventReviewLogRequest, $root.palexy.streaming.v1.ListStoreEventReviewLogResponse, request, callback);
                    }, "name", { value: "ListStoreEventReviewLog" });
    
                    /**
                     * Calls ListStoreEventReviewLog.
                     * @function listStoreEventReviewLog
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreEventReviewLogRequest} request ListStoreEventReviewLogRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStoreEventReviewLogResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreEventService#reviewStoreEvent}.
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @typedef ReviewStoreEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreEventReviewLog} [response] StoreEventReviewLog
                     */
    
                    /**
                     * Calls ReviewStoreEvent.
                     * @function reviewStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IReviewStoreEventRequest} request ReviewStoreEventRequest message or plain object
                     * @param {palexy.streaming.v1.StoreEventService.ReviewStoreEventCallback} callback Node-style callback called with the error, if any, and StoreEventReviewLog
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreEventService.prototype.reviewStoreEvent = function reviewStoreEvent(request, callback) {
                        return this.rpcCall(reviewStoreEvent, $root.palexy.streaming.v1.ReviewStoreEventRequest, $root.palexy.streaming.v1.StoreEventReviewLog, request, callback);
                    }, "name", { value: "ReviewStoreEvent" });
    
                    /**
                     * Calls ReviewStoreEvent.
                     * @function reviewStoreEvent
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IReviewStoreEventRequest} request ReviewStoreEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreEventReviewLog>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreEventService#fetchStoreEventReport}.
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @typedef FetchStoreEventReportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreEventAnalyticsReport} [response] StoreEventAnalyticsReport
                     */
    
                    /**
                     * Calls FetchStoreEventReport.
                     * @function fetchStoreEventReport
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchStoreEventAnalyticsReportRequest} request FetchStoreEventAnalyticsReportRequest message or plain object
                     * @param {palexy.streaming.v1.StoreEventService.FetchStoreEventReportCallback} callback Node-style callback called with the error, if any, and StoreEventAnalyticsReport
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreEventService.prototype.fetchStoreEventReport = function fetchStoreEventReport(request, callback) {
                        return this.rpcCall(fetchStoreEventReport, $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest, $root.palexy.streaming.v1.StoreEventAnalyticsReport, request, callback);
                    }, "name", { value: "FetchStoreEventReport" });
    
                    /**
                     * Calls FetchStoreEventReport.
                     * @function fetchStoreEventReport
                     * @memberof palexy.streaming.v1.StoreEventService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchStoreEventAnalyticsReportRequest} request FetchStoreEventAnalyticsReportRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreEventAnalyticsReport>} Promise
                     * @variation 2
                     */
    
                    return StoreEventService;
                })();
    
                v1.FetchStoreEventAnalyticsReportRequest = (function() {
    
                    /**
                     * Properties of a FetchStoreEventAnalyticsReportRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IFetchStoreEventAnalyticsReportRequest
                     * @property {Array.<string>|null} [dates] FetchStoreEventAnalyticsReportRequest dates
                     * @property {Array.<palexy.streaming.v1.StoreEventAnalyticsReport.Dimension>|null} [dimensions] FetchStoreEventAnalyticsReportRequest dimensions
                     * @property {Array.<palexy.streaming.v1.StoreEventAnalyticsReport.Metric>|null} [metrics] FetchStoreEventAnalyticsReportRequest metrics
                     * @property {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.IFilter|null} [reportFilter] FetchStoreEventAnalyticsReportRequest reportFilter
                     */
    
                    /**
                     * Constructs a new FetchStoreEventAnalyticsReportRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a FetchStoreEventAnalyticsReportRequest.
                     * @implements IFetchStoreEventAnalyticsReportRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IFetchStoreEventAnalyticsReportRequest=} [properties] Properties to set
                     */
                    function FetchStoreEventAnalyticsReportRequest(properties) {
                        this.dates = [];
                        this.dimensions = [];
                        this.metrics = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FetchStoreEventAnalyticsReportRequest dates.
                     * @member {Array.<string>} dates
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @instance
                     */
                    FetchStoreEventAnalyticsReportRequest.prototype.dates = $util.emptyArray;
    
                    /**
                     * FetchStoreEventAnalyticsReportRequest dimensions.
                     * @member {Array.<palexy.streaming.v1.StoreEventAnalyticsReport.Dimension>} dimensions
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @instance
                     */
                    FetchStoreEventAnalyticsReportRequest.prototype.dimensions = $util.emptyArray;
    
                    /**
                     * FetchStoreEventAnalyticsReportRequest metrics.
                     * @member {Array.<palexy.streaming.v1.StoreEventAnalyticsReport.Metric>} metrics
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @instance
                     */
                    FetchStoreEventAnalyticsReportRequest.prototype.metrics = $util.emptyArray;
    
                    /**
                     * FetchStoreEventAnalyticsReportRequest reportFilter.
                     * @member {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.IFilter|null|undefined} reportFilter
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @instance
                     */
                    FetchStoreEventAnalyticsReportRequest.prototype.reportFilter = null;
    
                    /**
                     * Creates a new FetchStoreEventAnalyticsReportRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchStoreEventAnalyticsReportRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest} FetchStoreEventAnalyticsReportRequest instance
                     */
                    FetchStoreEventAnalyticsReportRequest.create = function create(properties) {
                        return new FetchStoreEventAnalyticsReportRequest(properties);
                    };
    
                    /**
                     * Encodes the specified FetchStoreEventAnalyticsReportRequest message. Does not implicitly {@link palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchStoreEventAnalyticsReportRequest} message FetchStoreEventAnalyticsReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchStoreEventAnalyticsReportRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dates != null && message.dates.length)
                            for (var i = 0; i < message.dates.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dates[i]);
                        if (message.dimensions != null && message.dimensions.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.dimensions.length; ++i)
                                writer.int32(message.dimensions[i]);
                            writer.ldelim();
                        }
                        if (message.metrics != null && message.metrics.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.metrics.length; ++i)
                                writer.int32(message.metrics[i]);
                            writer.ldelim();
                        }
                        if (message.reportFilter != null && Object.hasOwnProperty.call(message, "reportFilter"))
                            $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter.encode(message.reportFilter, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FetchStoreEventAnalyticsReportRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IFetchStoreEventAnalyticsReportRequest} message FetchStoreEventAnalyticsReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FetchStoreEventAnalyticsReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FetchStoreEventAnalyticsReportRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest} FetchStoreEventAnalyticsReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchStoreEventAnalyticsReportRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.dates && message.dates.length))
                                    message.dates = [];
                                message.dates.push(reader.string());
                                break;
                            case 2:
                                if (!(message.dimensions && message.dimensions.length))
                                    message.dimensions = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.dimensions.push(reader.int32());
                                } else
                                    message.dimensions.push(reader.int32());
                                break;
                            case 3:
                                if (!(message.metrics && message.metrics.length))
                                    message.metrics = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.metrics.push(reader.int32());
                                } else
                                    message.metrics.push(reader.int32());
                                break;
                            case 4:
                                message.reportFilter = $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FetchStoreEventAnalyticsReportRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest} FetchStoreEventAnalyticsReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FetchStoreEventAnalyticsReportRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FetchStoreEventAnalyticsReportRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FetchStoreEventAnalyticsReportRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dates != null && message.hasOwnProperty("dates")) {
                            if (!Array.isArray(message.dates))
                                return "dates: array expected";
                            for (var i = 0; i < message.dates.length; ++i)
                                if (!$util.isString(message.dates[i]))
                                    return "dates: string[] expected";
                        }
                        if (message.dimensions != null && message.hasOwnProperty("dimensions")) {
                            if (!Array.isArray(message.dimensions))
                                return "dimensions: array expected";
                            for (var i = 0; i < message.dimensions.length; ++i)
                                switch (message.dimensions[i]) {
                                default:
                                    return "dimensions: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 18:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                case 13:
                                case 14:
                                case 15:
                                case 16:
                                case 17:
                                case 19:
                                case 20:
                                case 21:
                                case 22:
                                case 23:
                                case 24:
                                case 25:
                                case 26:
                                case 27:
                                case 28:
                                case 29:
                                case 30:
                                case 31:
                                case 32:
                                case 33:
                                case 34:
                                case 35:
                                case 36:
                                case 37:
                                case 38:
                                case 39:
                                case 40:
                                case 41:
                                case 42:
                                case 43:
                                case 44:
                                case 45:
                                case 46:
                                case 47:
                                case 48:
                                case 49:
                                case 50:
                                case 51:
                                case 52:
                                case 53:
                                case 54:
                                case 55:
                                case 56:
                                case 57:
                                case 58:
                                case 59:
                                case 60:
                                case 61:
                                case 62:
                                case 63:
                                case 64:
                                case 65:
                                case 66:
                                case 67:
                                case 68:
                                case 69:
                                case 70:
                                case 71:
                                case 72:
                                case 73:
                                case 74:
                                case 75:
                                case 76:
                                case 77:
                                case 78:
                                case 79:
                                case 80:
                                case 81:
                                case 82:
                                case 83:
                                case 84:
                                case 85:
                                case 86:
                                case 87:
                                case 88:
                                case 89:
                                case 90:
                                case 91:
                                case 92:
                                case 93:
                                case 94:
                                case 95:
                                case 96:
                                case 97:
                                case 98:
                                case 99:
                                case 100:
                                case 101:
                                case 102:
                                case 103:
                                case 104:
                                case 105:
                                case 106:
                                case 107:
                                case 108:
                                    break;
                                }
                        }
                        if (message.metrics != null && message.hasOwnProperty("metrics")) {
                            if (!Array.isArray(message.metrics))
                                return "metrics: array expected";
                            for (var i = 0; i < message.metrics.length; ++i)
                                switch (message.metrics[i]) {
                                default:
                                    return "metrics: enum value[] expected";
                                case 0:
                                case 1:
                                    break;
                                }
                        }
                        if (message.reportFilter != null && message.hasOwnProperty("reportFilter")) {
                            var error = $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter.verify(message.reportFilter);
                            if (error)
                                return "reportFilter." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a FetchStoreEventAnalyticsReportRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest} FetchStoreEventAnalyticsReportRequest
                     */
                    FetchStoreEventAnalyticsReportRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest();
                        if (object.dates) {
                            if (!Array.isArray(object.dates))
                                throw TypeError(".palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.dates: array expected");
                            message.dates = [];
                            for (var i = 0; i < object.dates.length; ++i)
                                message.dates[i] = String(object.dates[i]);
                        }
                        if (object.dimensions) {
                            if (!Array.isArray(object.dimensions))
                                throw TypeError(".palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.dimensions: array expected");
                            message.dimensions = [];
                            for (var i = 0; i < object.dimensions.length; ++i)
                                switch (object.dimensions[i]) {
                                default:
                                case "UNKNOWN_DIMENSION":
                                case 0:
                                    message.dimensions[i] = 0;
                                    break;
                                case "STATUS":
                                case 1:
                                    message.dimensions[i] = 1;
                                    break;
                                case "EVENT_TYPE":
                                case 2:
                                    message.dimensions[i] = 2;
                                    break;
                                case "NTH_DAY":
                                case 3:
                                    message.dimensions[i] = 3;
                                    break;
                                case "NTH_WEEK":
                                case 4:
                                    message.dimensions[i] = 4;
                                    break;
                                case "CUSTOM_NTH_WEEK":
                                case 18:
                                    message.dimensions[i] = 18;
                                    break;
                                case "NTH_MONTH":
                                case 5:
                                    message.dimensions[i] = 5;
                                    break;
                                case "STORE_NAME":
                                case 6:
                                    message.dimensions[i] = 6;
                                    break;
                                case "NTH_QUARTER":
                                case 7:
                                    message.dimensions[i] = 7;
                                    break;
                                case "STORE_METADATA_1":
                                case 8:
                                    message.dimensions[i] = 8;
                                    break;
                                case "STORE_METADATA_2":
                                case 9:
                                    message.dimensions[i] = 9;
                                    break;
                                case "STORE_METADATA_3":
                                case 10:
                                    message.dimensions[i] = 10;
                                    break;
                                case "STORE_METADATA_4":
                                case 11:
                                    message.dimensions[i] = 11;
                                    break;
                                case "STORE_METADATA_5":
                                case 12:
                                    message.dimensions[i] = 12;
                                    break;
                                case "STORE_METADATA_6":
                                case 13:
                                    message.dimensions[i] = 13;
                                    break;
                                case "STORE_METADATA_7":
                                case 14:
                                    message.dimensions[i] = 14;
                                    break;
                                case "STORE_METADATA_8":
                                case 15:
                                    message.dimensions[i] = 15;
                                    break;
                                case "STORE_METADATA_9":
                                case 16:
                                    message.dimensions[i] = 16;
                                    break;
                                case "STORE_METADATA_10":
                                case 17:
                                    message.dimensions[i] = 17;
                                    break;
                                case "STORE_METADATA_11":
                                case 19:
                                    message.dimensions[i] = 19;
                                    break;
                                case "STORE_METADATA_12":
                                case 20:
                                    message.dimensions[i] = 20;
                                    break;
                                case "STORE_METADATA_13":
                                case 21:
                                    message.dimensions[i] = 21;
                                    break;
                                case "STORE_METADATA_14":
                                case 22:
                                    message.dimensions[i] = 22;
                                    break;
                                case "STORE_METADATA_15":
                                case 23:
                                    message.dimensions[i] = 23;
                                    break;
                                case "STORE_METADATA_16":
                                case 24:
                                    message.dimensions[i] = 24;
                                    break;
                                case "STORE_METADATA_17":
                                case 25:
                                    message.dimensions[i] = 25;
                                    break;
                                case "STORE_METADATA_18":
                                case 26:
                                    message.dimensions[i] = 26;
                                    break;
                                case "STORE_METADATA_19":
                                case 27:
                                    message.dimensions[i] = 27;
                                    break;
                                case "STORE_METADATA_20":
                                case 28:
                                    message.dimensions[i] = 28;
                                    break;
                                case "STORE_METADATA_21":
                                case 29:
                                    message.dimensions[i] = 29;
                                    break;
                                case "STORE_METADATA_22":
                                case 30:
                                    message.dimensions[i] = 30;
                                    break;
                                case "STORE_METADATA_23":
                                case 31:
                                    message.dimensions[i] = 31;
                                    break;
                                case "STORE_METADATA_24":
                                case 32:
                                    message.dimensions[i] = 32;
                                    break;
                                case "STORE_METADATA_25":
                                case 33:
                                    message.dimensions[i] = 33;
                                    break;
                                case "STORE_METADATA_26":
                                case 34:
                                    message.dimensions[i] = 34;
                                    break;
                                case "STORE_METADATA_27":
                                case 35:
                                    message.dimensions[i] = 35;
                                    break;
                                case "STORE_METADATA_28":
                                case 36:
                                    message.dimensions[i] = 36;
                                    break;
                                case "STORE_METADATA_29":
                                case 37:
                                    message.dimensions[i] = 37;
                                    break;
                                case "STORE_METADATA_30":
                                case 38:
                                    message.dimensions[i] = 38;
                                    break;
                                case "STORE_METADATA_31":
                                case 39:
                                    message.dimensions[i] = 39;
                                    break;
                                case "STORE_METADATA_32":
                                case 40:
                                    message.dimensions[i] = 40;
                                    break;
                                case "STORE_METADATA_33":
                                case 41:
                                    message.dimensions[i] = 41;
                                    break;
                                case "STORE_METADATA_34":
                                case 42:
                                    message.dimensions[i] = 42;
                                    break;
                                case "STORE_METADATA_35":
                                case 43:
                                    message.dimensions[i] = 43;
                                    break;
                                case "STORE_METADATA_36":
                                case 44:
                                    message.dimensions[i] = 44;
                                    break;
                                case "STORE_METADATA_37":
                                case 45:
                                    message.dimensions[i] = 45;
                                    break;
                                case "STORE_METADATA_38":
                                case 46:
                                    message.dimensions[i] = 46;
                                    break;
                                case "STORE_METADATA_39":
                                case 47:
                                    message.dimensions[i] = 47;
                                    break;
                                case "STORE_METADATA_40":
                                case 48:
                                    message.dimensions[i] = 48;
                                    break;
                                case "STORE_METADATA_41":
                                case 49:
                                    message.dimensions[i] = 49;
                                    break;
                                case "STORE_METADATA_42":
                                case 50:
                                    message.dimensions[i] = 50;
                                    break;
                                case "STORE_METADATA_43":
                                case 51:
                                    message.dimensions[i] = 51;
                                    break;
                                case "STORE_METADATA_44":
                                case 52:
                                    message.dimensions[i] = 52;
                                    break;
                                case "STORE_METADATA_45":
                                case 53:
                                    message.dimensions[i] = 53;
                                    break;
                                case "STORE_METADATA_46":
                                case 54:
                                    message.dimensions[i] = 54;
                                    break;
                                case "STORE_METADATA_47":
                                case 55:
                                    message.dimensions[i] = 55;
                                    break;
                                case "STORE_METADATA_48":
                                case 56:
                                    message.dimensions[i] = 56;
                                    break;
                                case "STORE_METADATA_49":
                                case 57:
                                    message.dimensions[i] = 57;
                                    break;
                                case "STORE_METADATA_50":
                                case 58:
                                    message.dimensions[i] = 58;
                                    break;
                                case "STORE_METADATA_51":
                                case 59:
                                    message.dimensions[i] = 59;
                                    break;
                                case "STORE_METADATA_52":
                                case 60:
                                    message.dimensions[i] = 60;
                                    break;
                                case "STORE_METADATA_53":
                                case 61:
                                    message.dimensions[i] = 61;
                                    break;
                                case "STORE_METADATA_54":
                                case 62:
                                    message.dimensions[i] = 62;
                                    break;
                                case "STORE_METADATA_55":
                                case 63:
                                    message.dimensions[i] = 63;
                                    break;
                                case "STORE_METADATA_56":
                                case 64:
                                    message.dimensions[i] = 64;
                                    break;
                                case "STORE_METADATA_57":
                                case 65:
                                    message.dimensions[i] = 65;
                                    break;
                                case "STORE_METADATA_58":
                                case 66:
                                    message.dimensions[i] = 66;
                                    break;
                                case "STORE_METADATA_59":
                                case 67:
                                    message.dimensions[i] = 67;
                                    break;
                                case "STORE_METADATA_60":
                                case 68:
                                    message.dimensions[i] = 68;
                                    break;
                                case "STORE_METADATA_61":
                                case 69:
                                    message.dimensions[i] = 69;
                                    break;
                                case "STORE_METADATA_62":
                                case 70:
                                    message.dimensions[i] = 70;
                                    break;
                                case "STORE_METADATA_63":
                                case 71:
                                    message.dimensions[i] = 71;
                                    break;
                                case "STORE_METADATA_64":
                                case 72:
                                    message.dimensions[i] = 72;
                                    break;
                                case "STORE_METADATA_65":
                                case 73:
                                    message.dimensions[i] = 73;
                                    break;
                                case "STORE_METADATA_66":
                                case 74:
                                    message.dimensions[i] = 74;
                                    break;
                                case "STORE_METADATA_67":
                                case 75:
                                    message.dimensions[i] = 75;
                                    break;
                                case "STORE_METADATA_68":
                                case 76:
                                    message.dimensions[i] = 76;
                                    break;
                                case "STORE_METADATA_69":
                                case 77:
                                    message.dimensions[i] = 77;
                                    break;
                                case "STORE_METADATA_70":
                                case 78:
                                    message.dimensions[i] = 78;
                                    break;
                                case "STORE_METADATA_71":
                                case 79:
                                    message.dimensions[i] = 79;
                                    break;
                                case "STORE_METADATA_72":
                                case 80:
                                    message.dimensions[i] = 80;
                                    break;
                                case "STORE_METADATA_73":
                                case 81:
                                    message.dimensions[i] = 81;
                                    break;
                                case "STORE_METADATA_74":
                                case 82:
                                    message.dimensions[i] = 82;
                                    break;
                                case "STORE_METADATA_75":
                                case 83:
                                    message.dimensions[i] = 83;
                                    break;
                                case "STORE_METADATA_76":
                                case 84:
                                    message.dimensions[i] = 84;
                                    break;
                                case "STORE_METADATA_77":
                                case 85:
                                    message.dimensions[i] = 85;
                                    break;
                                case "STORE_METADATA_78":
                                case 86:
                                    message.dimensions[i] = 86;
                                    break;
                                case "STORE_METADATA_79":
                                case 87:
                                    message.dimensions[i] = 87;
                                    break;
                                case "STORE_METADATA_80":
                                case 88:
                                    message.dimensions[i] = 88;
                                    break;
                                case "STORE_METADATA_81":
                                case 89:
                                    message.dimensions[i] = 89;
                                    break;
                                case "STORE_METADATA_82":
                                case 90:
                                    message.dimensions[i] = 90;
                                    break;
                                case "STORE_METADATA_83":
                                case 91:
                                    message.dimensions[i] = 91;
                                    break;
                                case "STORE_METADATA_84":
                                case 92:
                                    message.dimensions[i] = 92;
                                    break;
                                case "STORE_METADATA_85":
                                case 93:
                                    message.dimensions[i] = 93;
                                    break;
                                case "STORE_METADATA_86":
                                case 94:
                                    message.dimensions[i] = 94;
                                    break;
                                case "STORE_METADATA_87":
                                case 95:
                                    message.dimensions[i] = 95;
                                    break;
                                case "STORE_METADATA_88":
                                case 96:
                                    message.dimensions[i] = 96;
                                    break;
                                case "STORE_METADATA_89":
                                case 97:
                                    message.dimensions[i] = 97;
                                    break;
                                case "STORE_METADATA_90":
                                case 98:
                                    message.dimensions[i] = 98;
                                    break;
                                case "STORE_METADATA_91":
                                case 99:
                                    message.dimensions[i] = 99;
                                    break;
                                case "STORE_METADATA_92":
                                case 100:
                                    message.dimensions[i] = 100;
                                    break;
                                case "STORE_METADATA_93":
                                case 101:
                                    message.dimensions[i] = 101;
                                    break;
                                case "STORE_METADATA_94":
                                case 102:
                                    message.dimensions[i] = 102;
                                    break;
                                case "STORE_METADATA_95":
                                case 103:
                                    message.dimensions[i] = 103;
                                    break;
                                case "STORE_METADATA_96":
                                case 104:
                                    message.dimensions[i] = 104;
                                    break;
                                case "STORE_METADATA_97":
                                case 105:
                                    message.dimensions[i] = 105;
                                    break;
                                case "STORE_METADATA_98":
                                case 106:
                                    message.dimensions[i] = 106;
                                    break;
                                case "STORE_METADATA_99":
                                case 107:
                                    message.dimensions[i] = 107;
                                    break;
                                case "STORE_METADATA_100":
                                case 108:
                                    message.dimensions[i] = 108;
                                    break;
                                }
                        }
                        if (object.metrics) {
                            if (!Array.isArray(object.metrics))
                                throw TypeError(".palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.metrics: array expected");
                            message.metrics = [];
                            for (var i = 0; i < object.metrics.length; ++i)
                                switch (object.metrics[i]) {
                                default:
                                case "UNKNOWN_METRIC":
                                case 0:
                                    message.metrics[i] = 0;
                                    break;
                                case "NUMBER_OF_STORE_EVENTS":
                                case 1:
                                    message.metrics[i] = 1;
                                    break;
                                }
                        }
                        if (object.reportFilter != null) {
                            if (typeof object.reportFilter !== "object")
                                throw TypeError(".palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.reportFilter: object expected");
                            message.reportFilter = $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter.fromObject(object.reportFilter);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FetchStoreEventAnalyticsReportRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @static
                     * @param {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest} message FetchStoreEventAnalyticsReportRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FetchStoreEventAnalyticsReportRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.dates = [];
                            object.dimensions = [];
                            object.metrics = [];
                        }
                        if (options.defaults)
                            object.reportFilter = null;
                        if (message.dates && message.dates.length) {
                            object.dates = [];
                            for (var j = 0; j < message.dates.length; ++j)
                                object.dates[j] = message.dates[j];
                        }
                        if (message.dimensions && message.dimensions.length) {
                            object.dimensions = [];
                            for (var j = 0; j < message.dimensions.length; ++j)
                                object.dimensions[j] = options.enums === String ? $root.palexy.streaming.v1.StoreEventAnalyticsReport.Dimension[message.dimensions[j]] : message.dimensions[j];
                        }
                        if (message.metrics && message.metrics.length) {
                            object.metrics = [];
                            for (var j = 0; j < message.metrics.length; ++j)
                                object.metrics[j] = options.enums === String ? $root.palexy.streaming.v1.StoreEventAnalyticsReport.Metric[message.metrics[j]] : message.metrics[j];
                        }
                        if (message.reportFilter != null && message.hasOwnProperty("reportFilter"))
                            object.reportFilter = $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter.toObject(message.reportFilter, options);
                        return object;
                    };
    
                    /**
                     * Converts this FetchStoreEventAnalyticsReportRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FetchStoreEventAnalyticsReportRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    FetchStoreEventAnalyticsReportRequest.Filter = (function() {
    
                        /**
                         * Properties of a Filter.
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                         * @interface IFilter
                         * @property {number|null} [companyId] Filter companyId
                         * @property {Array.<number>|null} [storeIds] Filter storeIds
                         * @property {Array.<palexy.streaming.v1.StoreEvent.StoreEventStatus>|null} [statuses] Filter statuses
                         * @property {Array.<palexy.streaming.v1.StoreEventType>|null} [types] Filter types
                         * @property {boolean|null} [isTestEvent] Filter isTestEvent
                         */
    
                        /**
                         * Constructs a new Filter.
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest
                         * @classdesc Represents a Filter.
                         * @implements IFilter
                         * @constructor
                         * @param {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.IFilter=} [properties] Properties to set
                         */
                        function Filter(properties) {
                            this.storeIds = [];
                            this.statuses = [];
                            this.types = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * Filter companyId.
                         * @member {number} companyId
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * Filter storeIds.
                         * @member {Array.<number>} storeIds
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.storeIds = $util.emptyArray;
    
                        /**
                         * Filter statuses.
                         * @member {Array.<palexy.streaming.v1.StoreEvent.StoreEventStatus>} statuses
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.statuses = $util.emptyArray;
    
                        /**
                         * Filter types.
                         * @member {Array.<palexy.streaming.v1.StoreEventType>} types
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.types = $util.emptyArray;
    
                        /**
                         * Filter isTestEvent.
                         * @member {boolean} isTestEvent
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.isTestEvent = false;
    
                        /**
                         * Creates a new Filter instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @static
                         * @param {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.IFilter=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter} Filter instance
                         */
                        Filter.create = function create(properties) {
                            return new Filter(properties);
                        };
    
                        /**
                         * Encodes the specified Filter message. Does not implicitly {@link palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @static
                         * @param {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.IFilter} message Filter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Filter.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                            if (message.storeIds != null && message.storeIds.length) {
                                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                                for (var i = 0; i < message.storeIds.length; ++i)
                                    writer.int64(message.storeIds[i]);
                                writer.ldelim();
                            }
                            if (message.statuses != null && message.statuses.length) {
                                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                                for (var i = 0; i < message.statuses.length; ++i)
                                    writer.int32(message.statuses[i]);
                                writer.ldelim();
                            }
                            if (message.types != null && message.types.length) {
                                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                                for (var i = 0; i < message.types.length; ++i)
                                    writer.int32(message.types[i]);
                                writer.ldelim();
                            }
                            if (message.isTestEvent != null && Object.hasOwnProperty.call(message, "isTestEvent"))
                                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isTestEvent);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified Filter message, length delimited. Does not implicitly {@link palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @static
                         * @param {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.IFilter} message Filter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Filter.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a Filter message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter} Filter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Filter.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.companyId = reader.int64();
                                    break;
                                case 2:
                                    if (!(message.storeIds && message.storeIds.length))
                                        message.storeIds = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.storeIds.push(reader.int64());
                                    } else
                                        message.storeIds.push(reader.int64());
                                    break;
                                case 3:
                                    if (!(message.statuses && message.statuses.length))
                                        message.statuses = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.statuses.push(reader.int32());
                                    } else
                                        message.statuses.push(reader.int32());
                                    break;
                                case 4:
                                    if (!(message.types && message.types.length))
                                        message.types = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.types.push(reader.int32());
                                    } else
                                        message.types.push(reader.int32());
                                    break;
                                case 5:
                                    message.isTestEvent = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a Filter message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter} Filter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Filter.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a Filter message.
                         * @function verify
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Filter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.companyId != null && message.hasOwnProperty("companyId"))
                                if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                    return "companyId: integer|Long expected";
                            if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                                if (!Array.isArray(message.storeIds))
                                    return "storeIds: array expected";
                                for (var i = 0; i < message.storeIds.length; ++i)
                                    if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                        return "storeIds: integer|Long[] expected";
                            }
                            if (message.statuses != null && message.hasOwnProperty("statuses")) {
                                if (!Array.isArray(message.statuses))
                                    return "statuses: array expected";
                                for (var i = 0; i < message.statuses.length; ++i)
                                    switch (message.statuses[i]) {
                                    default:
                                        return "statuses: enum value[] expected";
                                    case 0:
                                    case 1:
                                    case 2:
                                    case 3:
                                        break;
                                    }
                            }
                            if (message.types != null && message.hasOwnProperty("types")) {
                                if (!Array.isArray(message.types))
                                    return "types: array expected";
                                for (var i = 0; i < message.types.length; ++i)
                                    switch (message.types[i]) {
                                    default:
                                        return "types: enum value[] expected";
                                    case 0:
                                    case 1:
                                    case 2:
                                    case 3:
                                    case 4:
                                    case 5:
                                    case 6:
                                    case 7:
                                    case 8:
                                    case 9:
                                    case 10:
                                    case 11:
                                    case 12:
                                        break;
                                    }
                            }
                            if (message.isTestEvent != null && message.hasOwnProperty("isTestEvent"))
                                if (typeof message.isTestEvent !== "boolean")
                                    return "isTestEvent: boolean expected";
                            return null;
                        };
    
                        /**
                         * Creates a Filter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter} Filter
                         */
                        Filter.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter)
                                return object;
                            var message = new $root.palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter();
                            if (object.companyId != null)
                                if ($util.Long)
                                    (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                                else if (typeof object.companyId === "string")
                                    message.companyId = parseInt(object.companyId, 10);
                                else if (typeof object.companyId === "number")
                                    message.companyId = object.companyId;
                                else if (typeof object.companyId === "object")
                                    message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                            if (object.storeIds) {
                                if (!Array.isArray(object.storeIds))
                                    throw TypeError(".palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter.storeIds: array expected");
                                message.storeIds = [];
                                for (var i = 0; i < object.storeIds.length; ++i)
                                    if ($util.Long)
                                        (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                    else if (typeof object.storeIds[i] === "string")
                                        message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                    else if (typeof object.storeIds[i] === "number")
                                        message.storeIds[i] = object.storeIds[i];
                                    else if (typeof object.storeIds[i] === "object")
                                        message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                            }
                            if (object.statuses) {
                                if (!Array.isArray(object.statuses))
                                    throw TypeError(".palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter.statuses: array expected");
                                message.statuses = [];
                                for (var i = 0; i < object.statuses.length; ++i)
                                    switch (object.statuses[i]) {
                                    default:
                                    case "UNKNOWN":
                                    case 0:
                                        message.statuses[i] = 0;
                                        break;
                                    case "NOT_REVIEWED":
                                    case 1:
                                        message.statuses[i] = 1;
                                        break;
                                    case "APPROVED":
                                    case 2:
                                        message.statuses[i] = 2;
                                        break;
                                    case "REJECTED":
                                    case 3:
                                        message.statuses[i] = 3;
                                        break;
                                    }
                            }
                            if (object.types) {
                                if (!Array.isArray(object.types))
                                    throw TypeError(".palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter.types: array expected");
                                message.types = [];
                                for (var i = 0; i < object.types.length; ++i)
                                    switch (object.types[i]) {
                                    default:
                                    case "UNKNOWN_STORE_EVENT_TYPE":
                                    case 0:
                                        message.types[i] = 0;
                                        break;
                                    case "MISSED_CUSTOMER_INTERACTION":
                                    case 1:
                                        message.types[i] = 1;
                                        break;
                                    case "SUSPICIOUS_CASHIER_ACTIVITY":
                                    case 2:
                                        message.types[i] = 2;
                                        break;
                                    case "SOP_NON_COMPLIANCE":
                                    case 3:
                                        message.types[i] = 3;
                                        break;
                                    case "STAFF_ATTENDANCE_NON_COMPLIANCE":
                                    case 4:
                                        message.types[i] = 4;
                                        break;
                                    case "STAFF_GATHERING":
                                    case 5:
                                        message.types[i] = 5;
                                        break;
                                    case "STORE_OPEN_CLOSE_TIME_NON_COMPLIANCE":
                                    case 6:
                                        message.types[i] = 6;
                                        break;
                                    case "CUSTOMER_NEEDS_ASSISTANCE":
                                    case 7:
                                        message.types[i] = 7;
                                        break;
                                    case "STAFF_NOT_WEAR_GLOVE":
                                    case 8:
                                        message.types[i] = 8;
                                        break;
                                    case "NOT_GREETED_ON_TIME":
                                    case 9:
                                        message.types[i] = 9;
                                        break;
                                    case "STAFF_MISSING":
                                    case 10:
                                        message.types[i] = 10;
                                        break;
                                    case "STAFF_LEAVING_SPECIAL_AREA_WITHOUT_FOLLOWING_SOP":
                                    case 11:
                                        message.types[i] = 11;
                                        break;
                                    case "HIGH_TRAFFIC_CHECKOUT":
                                    case 12:
                                        message.types[i] = 12;
                                        break;
                                    }
                            }
                            if (object.isTestEvent != null)
                                message.isTestEvent = Boolean(object.isTestEvent);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a Filter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @static
                         * @param {palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter} message Filter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Filter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.storeIds = [];
                                object.statuses = [];
                                object.types = [];
                            }
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.companyId = options.longs === String ? "0" : 0;
                                object.isTestEvent = false;
                            }
                            if (message.companyId != null && message.hasOwnProperty("companyId"))
                                if (typeof message.companyId === "number")
                                    object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                                else
                                    object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                            if (message.storeIds && message.storeIds.length) {
                                object.storeIds = [];
                                for (var j = 0; j < message.storeIds.length; ++j)
                                    if (typeof message.storeIds[j] === "number")
                                        object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                    else
                                        object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                            }
                            if (message.statuses && message.statuses.length) {
                                object.statuses = [];
                                for (var j = 0; j < message.statuses.length; ++j)
                                    object.statuses[j] = options.enums === String ? $root.palexy.streaming.v1.StoreEvent.StoreEventStatus[message.statuses[j]] : message.statuses[j];
                            }
                            if (message.types && message.types.length) {
                                object.types = [];
                                for (var j = 0; j < message.types.length; ++j)
                                    object.types[j] = options.enums === String ? $root.palexy.streaming.v1.StoreEventType[message.types[j]] : message.types[j];
                            }
                            if (message.isTestEvent != null && message.hasOwnProperty("isTestEvent"))
                                object.isTestEvent = message.isTestEvent;
                            return object;
                        };
    
                        /**
                         * Converts this Filter to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.FetchStoreEventAnalyticsReportRequest.Filter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Filter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return Filter;
                    })();
    
                    return FetchStoreEventAnalyticsReportRequest;
                })();
    
                v1.StoreEventAnalyticsReport = (function() {
    
                    /**
                     * Properties of a StoreEventAnalyticsReport.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreEventAnalyticsReport
                     * @property {Array.<string>|null} [dates] StoreEventAnalyticsReport dates
                     * @property {Array.<palexy.streaming.v1.StoreEventAnalyticsReport.Dimension>|null} [dimensions] StoreEventAnalyticsReport dimensions
                     * @property {Array.<palexy.streaming.v1.StoreEventAnalyticsReport.Metric>|null} [metrics] StoreEventAnalyticsReport metrics
                     * @property {Array.<palexy.streaming.v1.StoreEventAnalyticsReport.IRow>|null} [rows] StoreEventAnalyticsReport rows
                     */
    
                    /**
                     * Constructs a new StoreEventAnalyticsReport.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreEventAnalyticsReport.
                     * @implements IStoreEventAnalyticsReport
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreEventAnalyticsReport=} [properties] Properties to set
                     */
                    function StoreEventAnalyticsReport(properties) {
                        this.dates = [];
                        this.dimensions = [];
                        this.metrics = [];
                        this.rows = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreEventAnalyticsReport dates.
                     * @member {Array.<string>} dates
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @instance
                     */
                    StoreEventAnalyticsReport.prototype.dates = $util.emptyArray;
    
                    /**
                     * StoreEventAnalyticsReport dimensions.
                     * @member {Array.<palexy.streaming.v1.StoreEventAnalyticsReport.Dimension>} dimensions
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @instance
                     */
                    StoreEventAnalyticsReport.prototype.dimensions = $util.emptyArray;
    
                    /**
                     * StoreEventAnalyticsReport metrics.
                     * @member {Array.<palexy.streaming.v1.StoreEventAnalyticsReport.Metric>} metrics
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @instance
                     */
                    StoreEventAnalyticsReport.prototype.metrics = $util.emptyArray;
    
                    /**
                     * StoreEventAnalyticsReport rows.
                     * @member {Array.<palexy.streaming.v1.StoreEventAnalyticsReport.IRow>} rows
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @instance
                     */
                    StoreEventAnalyticsReport.prototype.rows = $util.emptyArray;
    
                    /**
                     * Creates a new StoreEventAnalyticsReport instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @static
                     * @param {palexy.streaming.v1.IStoreEventAnalyticsReport=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreEventAnalyticsReport} StoreEventAnalyticsReport instance
                     */
                    StoreEventAnalyticsReport.create = function create(properties) {
                        return new StoreEventAnalyticsReport(properties);
                    };
    
                    /**
                     * Encodes the specified StoreEventAnalyticsReport message. Does not implicitly {@link palexy.streaming.v1.StoreEventAnalyticsReport.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @static
                     * @param {palexy.streaming.v1.IStoreEventAnalyticsReport} message StoreEventAnalyticsReport message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreEventAnalyticsReport.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dates != null && message.dates.length)
                            for (var i = 0; i < message.dates.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dates[i]);
                        if (message.dimensions != null && message.dimensions.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.dimensions.length; ++i)
                                writer.int32(message.dimensions[i]);
                            writer.ldelim();
                        }
                        if (message.metrics != null && message.metrics.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.metrics.length; ++i)
                                writer.int32(message.metrics[i]);
                            writer.ldelim();
                        }
                        if (message.rows != null && message.rows.length)
                            for (var i = 0; i < message.rows.length; ++i)
                                $root.palexy.streaming.v1.StoreEventAnalyticsReport.Row.encode(message.rows[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreEventAnalyticsReport message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreEventAnalyticsReport.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @static
                     * @param {palexy.streaming.v1.IStoreEventAnalyticsReport} message StoreEventAnalyticsReport message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreEventAnalyticsReport.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreEventAnalyticsReport message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreEventAnalyticsReport} StoreEventAnalyticsReport
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreEventAnalyticsReport.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreEventAnalyticsReport();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.dates && message.dates.length))
                                    message.dates = [];
                                message.dates.push(reader.string());
                                break;
                            case 2:
                                if (!(message.dimensions && message.dimensions.length))
                                    message.dimensions = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.dimensions.push(reader.int32());
                                } else
                                    message.dimensions.push(reader.int32());
                                break;
                            case 3:
                                if (!(message.metrics && message.metrics.length))
                                    message.metrics = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.metrics.push(reader.int32());
                                } else
                                    message.metrics.push(reader.int32());
                                break;
                            case 4:
                                if (!(message.rows && message.rows.length))
                                    message.rows = [];
                                message.rows.push($root.palexy.streaming.v1.StoreEventAnalyticsReport.Row.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreEventAnalyticsReport message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreEventAnalyticsReport} StoreEventAnalyticsReport
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreEventAnalyticsReport.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreEventAnalyticsReport message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreEventAnalyticsReport.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dates != null && message.hasOwnProperty("dates")) {
                            if (!Array.isArray(message.dates))
                                return "dates: array expected";
                            for (var i = 0; i < message.dates.length; ++i)
                                if (!$util.isString(message.dates[i]))
                                    return "dates: string[] expected";
                        }
                        if (message.dimensions != null && message.hasOwnProperty("dimensions")) {
                            if (!Array.isArray(message.dimensions))
                                return "dimensions: array expected";
                            for (var i = 0; i < message.dimensions.length; ++i)
                                switch (message.dimensions[i]) {
                                default:
                                    return "dimensions: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 18:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                case 13:
                                case 14:
                                case 15:
                                case 16:
                                case 17:
                                case 19:
                                case 20:
                                case 21:
                                case 22:
                                case 23:
                                case 24:
                                case 25:
                                case 26:
                                case 27:
                                case 28:
                                case 29:
                                case 30:
                                case 31:
                                case 32:
                                case 33:
                                case 34:
                                case 35:
                                case 36:
                                case 37:
                                case 38:
                                case 39:
                                case 40:
                                case 41:
                                case 42:
                                case 43:
                                case 44:
                                case 45:
                                case 46:
                                case 47:
                                case 48:
                                case 49:
                                case 50:
                                case 51:
                                case 52:
                                case 53:
                                case 54:
                                case 55:
                                case 56:
                                case 57:
                                case 58:
                                case 59:
                                case 60:
                                case 61:
                                case 62:
                                case 63:
                                case 64:
                                case 65:
                                case 66:
                                case 67:
                                case 68:
                                case 69:
                                case 70:
                                case 71:
                                case 72:
                                case 73:
                                case 74:
                                case 75:
                                case 76:
                                case 77:
                                case 78:
                                case 79:
                                case 80:
                                case 81:
                                case 82:
                                case 83:
                                case 84:
                                case 85:
                                case 86:
                                case 87:
                                case 88:
                                case 89:
                                case 90:
                                case 91:
                                case 92:
                                case 93:
                                case 94:
                                case 95:
                                case 96:
                                case 97:
                                case 98:
                                case 99:
                                case 100:
                                case 101:
                                case 102:
                                case 103:
                                case 104:
                                case 105:
                                case 106:
                                case 107:
                                case 108:
                                    break;
                                }
                        }
                        if (message.metrics != null && message.hasOwnProperty("metrics")) {
                            if (!Array.isArray(message.metrics))
                                return "metrics: array expected";
                            for (var i = 0; i < message.metrics.length; ++i)
                                switch (message.metrics[i]) {
                                default:
                                    return "metrics: enum value[] expected";
                                case 0:
                                case 1:
                                    break;
                                }
                        }
                        if (message.rows != null && message.hasOwnProperty("rows")) {
                            if (!Array.isArray(message.rows))
                                return "rows: array expected";
                            for (var i = 0; i < message.rows.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreEventAnalyticsReport.Row.verify(message.rows[i]);
                                if (error)
                                    return "rows." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a StoreEventAnalyticsReport message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreEventAnalyticsReport} StoreEventAnalyticsReport
                     */
                    StoreEventAnalyticsReport.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreEventAnalyticsReport)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreEventAnalyticsReport();
                        if (object.dates) {
                            if (!Array.isArray(object.dates))
                                throw TypeError(".palexy.streaming.v1.StoreEventAnalyticsReport.dates: array expected");
                            message.dates = [];
                            for (var i = 0; i < object.dates.length; ++i)
                                message.dates[i] = String(object.dates[i]);
                        }
                        if (object.dimensions) {
                            if (!Array.isArray(object.dimensions))
                                throw TypeError(".palexy.streaming.v1.StoreEventAnalyticsReport.dimensions: array expected");
                            message.dimensions = [];
                            for (var i = 0; i < object.dimensions.length; ++i)
                                switch (object.dimensions[i]) {
                                default:
                                case "UNKNOWN_DIMENSION":
                                case 0:
                                    message.dimensions[i] = 0;
                                    break;
                                case "STATUS":
                                case 1:
                                    message.dimensions[i] = 1;
                                    break;
                                case "EVENT_TYPE":
                                case 2:
                                    message.dimensions[i] = 2;
                                    break;
                                case "NTH_DAY":
                                case 3:
                                    message.dimensions[i] = 3;
                                    break;
                                case "NTH_WEEK":
                                case 4:
                                    message.dimensions[i] = 4;
                                    break;
                                case "CUSTOM_NTH_WEEK":
                                case 18:
                                    message.dimensions[i] = 18;
                                    break;
                                case "NTH_MONTH":
                                case 5:
                                    message.dimensions[i] = 5;
                                    break;
                                case "STORE_NAME":
                                case 6:
                                    message.dimensions[i] = 6;
                                    break;
                                case "NTH_QUARTER":
                                case 7:
                                    message.dimensions[i] = 7;
                                    break;
                                case "STORE_METADATA_1":
                                case 8:
                                    message.dimensions[i] = 8;
                                    break;
                                case "STORE_METADATA_2":
                                case 9:
                                    message.dimensions[i] = 9;
                                    break;
                                case "STORE_METADATA_3":
                                case 10:
                                    message.dimensions[i] = 10;
                                    break;
                                case "STORE_METADATA_4":
                                case 11:
                                    message.dimensions[i] = 11;
                                    break;
                                case "STORE_METADATA_5":
                                case 12:
                                    message.dimensions[i] = 12;
                                    break;
                                case "STORE_METADATA_6":
                                case 13:
                                    message.dimensions[i] = 13;
                                    break;
                                case "STORE_METADATA_7":
                                case 14:
                                    message.dimensions[i] = 14;
                                    break;
                                case "STORE_METADATA_8":
                                case 15:
                                    message.dimensions[i] = 15;
                                    break;
                                case "STORE_METADATA_9":
                                case 16:
                                    message.dimensions[i] = 16;
                                    break;
                                case "STORE_METADATA_10":
                                case 17:
                                    message.dimensions[i] = 17;
                                    break;
                                case "STORE_METADATA_11":
                                case 19:
                                    message.dimensions[i] = 19;
                                    break;
                                case "STORE_METADATA_12":
                                case 20:
                                    message.dimensions[i] = 20;
                                    break;
                                case "STORE_METADATA_13":
                                case 21:
                                    message.dimensions[i] = 21;
                                    break;
                                case "STORE_METADATA_14":
                                case 22:
                                    message.dimensions[i] = 22;
                                    break;
                                case "STORE_METADATA_15":
                                case 23:
                                    message.dimensions[i] = 23;
                                    break;
                                case "STORE_METADATA_16":
                                case 24:
                                    message.dimensions[i] = 24;
                                    break;
                                case "STORE_METADATA_17":
                                case 25:
                                    message.dimensions[i] = 25;
                                    break;
                                case "STORE_METADATA_18":
                                case 26:
                                    message.dimensions[i] = 26;
                                    break;
                                case "STORE_METADATA_19":
                                case 27:
                                    message.dimensions[i] = 27;
                                    break;
                                case "STORE_METADATA_20":
                                case 28:
                                    message.dimensions[i] = 28;
                                    break;
                                case "STORE_METADATA_21":
                                case 29:
                                    message.dimensions[i] = 29;
                                    break;
                                case "STORE_METADATA_22":
                                case 30:
                                    message.dimensions[i] = 30;
                                    break;
                                case "STORE_METADATA_23":
                                case 31:
                                    message.dimensions[i] = 31;
                                    break;
                                case "STORE_METADATA_24":
                                case 32:
                                    message.dimensions[i] = 32;
                                    break;
                                case "STORE_METADATA_25":
                                case 33:
                                    message.dimensions[i] = 33;
                                    break;
                                case "STORE_METADATA_26":
                                case 34:
                                    message.dimensions[i] = 34;
                                    break;
                                case "STORE_METADATA_27":
                                case 35:
                                    message.dimensions[i] = 35;
                                    break;
                                case "STORE_METADATA_28":
                                case 36:
                                    message.dimensions[i] = 36;
                                    break;
                                case "STORE_METADATA_29":
                                case 37:
                                    message.dimensions[i] = 37;
                                    break;
                                case "STORE_METADATA_30":
                                case 38:
                                    message.dimensions[i] = 38;
                                    break;
                                case "STORE_METADATA_31":
                                case 39:
                                    message.dimensions[i] = 39;
                                    break;
                                case "STORE_METADATA_32":
                                case 40:
                                    message.dimensions[i] = 40;
                                    break;
                                case "STORE_METADATA_33":
                                case 41:
                                    message.dimensions[i] = 41;
                                    break;
                                case "STORE_METADATA_34":
                                case 42:
                                    message.dimensions[i] = 42;
                                    break;
                                case "STORE_METADATA_35":
                                case 43:
                                    message.dimensions[i] = 43;
                                    break;
                                case "STORE_METADATA_36":
                                case 44:
                                    message.dimensions[i] = 44;
                                    break;
                                case "STORE_METADATA_37":
                                case 45:
                                    message.dimensions[i] = 45;
                                    break;
                                case "STORE_METADATA_38":
                                case 46:
                                    message.dimensions[i] = 46;
                                    break;
                                case "STORE_METADATA_39":
                                case 47:
                                    message.dimensions[i] = 47;
                                    break;
                                case "STORE_METADATA_40":
                                case 48:
                                    message.dimensions[i] = 48;
                                    break;
                                case "STORE_METADATA_41":
                                case 49:
                                    message.dimensions[i] = 49;
                                    break;
                                case "STORE_METADATA_42":
                                case 50:
                                    message.dimensions[i] = 50;
                                    break;
                                case "STORE_METADATA_43":
                                case 51:
                                    message.dimensions[i] = 51;
                                    break;
                                case "STORE_METADATA_44":
                                case 52:
                                    message.dimensions[i] = 52;
                                    break;
                                case "STORE_METADATA_45":
                                case 53:
                                    message.dimensions[i] = 53;
                                    break;
                                case "STORE_METADATA_46":
                                case 54:
                                    message.dimensions[i] = 54;
                                    break;
                                case "STORE_METADATA_47":
                                case 55:
                                    message.dimensions[i] = 55;
                                    break;
                                case "STORE_METADATA_48":
                                case 56:
                                    message.dimensions[i] = 56;
                                    break;
                                case "STORE_METADATA_49":
                                case 57:
                                    message.dimensions[i] = 57;
                                    break;
                                case "STORE_METADATA_50":
                                case 58:
                                    message.dimensions[i] = 58;
                                    break;
                                case "STORE_METADATA_51":
                                case 59:
                                    message.dimensions[i] = 59;
                                    break;
                                case "STORE_METADATA_52":
                                case 60:
                                    message.dimensions[i] = 60;
                                    break;
                                case "STORE_METADATA_53":
                                case 61:
                                    message.dimensions[i] = 61;
                                    break;
                                case "STORE_METADATA_54":
                                case 62:
                                    message.dimensions[i] = 62;
                                    break;
                                case "STORE_METADATA_55":
                                case 63:
                                    message.dimensions[i] = 63;
                                    break;
                                case "STORE_METADATA_56":
                                case 64:
                                    message.dimensions[i] = 64;
                                    break;
                                case "STORE_METADATA_57":
                                case 65:
                                    message.dimensions[i] = 65;
                                    break;
                                case "STORE_METADATA_58":
                                case 66:
                                    message.dimensions[i] = 66;
                                    break;
                                case "STORE_METADATA_59":
                                case 67:
                                    message.dimensions[i] = 67;
                                    break;
                                case "STORE_METADATA_60":
                                case 68:
                                    message.dimensions[i] = 68;
                                    break;
                                case "STORE_METADATA_61":
                                case 69:
                                    message.dimensions[i] = 69;
                                    break;
                                case "STORE_METADATA_62":
                                case 70:
                                    message.dimensions[i] = 70;
                                    break;
                                case "STORE_METADATA_63":
                                case 71:
                                    message.dimensions[i] = 71;
                                    break;
                                case "STORE_METADATA_64":
                                case 72:
                                    message.dimensions[i] = 72;
                                    break;
                                case "STORE_METADATA_65":
                                case 73:
                                    message.dimensions[i] = 73;
                                    break;
                                case "STORE_METADATA_66":
                                case 74:
                                    message.dimensions[i] = 74;
                                    break;
                                case "STORE_METADATA_67":
                                case 75:
                                    message.dimensions[i] = 75;
                                    break;
                                case "STORE_METADATA_68":
                                case 76:
                                    message.dimensions[i] = 76;
                                    break;
                                case "STORE_METADATA_69":
                                case 77:
                                    message.dimensions[i] = 77;
                                    break;
                                case "STORE_METADATA_70":
                                case 78:
                                    message.dimensions[i] = 78;
                                    break;
                                case "STORE_METADATA_71":
                                case 79:
                                    message.dimensions[i] = 79;
                                    break;
                                case "STORE_METADATA_72":
                                case 80:
                                    message.dimensions[i] = 80;
                                    break;
                                case "STORE_METADATA_73":
                                case 81:
                                    message.dimensions[i] = 81;
                                    break;
                                case "STORE_METADATA_74":
                                case 82:
                                    message.dimensions[i] = 82;
                                    break;
                                case "STORE_METADATA_75":
                                case 83:
                                    message.dimensions[i] = 83;
                                    break;
                                case "STORE_METADATA_76":
                                case 84:
                                    message.dimensions[i] = 84;
                                    break;
                                case "STORE_METADATA_77":
                                case 85:
                                    message.dimensions[i] = 85;
                                    break;
                                case "STORE_METADATA_78":
                                case 86:
                                    message.dimensions[i] = 86;
                                    break;
                                case "STORE_METADATA_79":
                                case 87:
                                    message.dimensions[i] = 87;
                                    break;
                                case "STORE_METADATA_80":
                                case 88:
                                    message.dimensions[i] = 88;
                                    break;
                                case "STORE_METADATA_81":
                                case 89:
                                    message.dimensions[i] = 89;
                                    break;
                                case "STORE_METADATA_82":
                                case 90:
                                    message.dimensions[i] = 90;
                                    break;
                                case "STORE_METADATA_83":
                                case 91:
                                    message.dimensions[i] = 91;
                                    break;
                                case "STORE_METADATA_84":
                                case 92:
                                    message.dimensions[i] = 92;
                                    break;
                                case "STORE_METADATA_85":
                                case 93:
                                    message.dimensions[i] = 93;
                                    break;
                                case "STORE_METADATA_86":
                                case 94:
                                    message.dimensions[i] = 94;
                                    break;
                                case "STORE_METADATA_87":
                                case 95:
                                    message.dimensions[i] = 95;
                                    break;
                                case "STORE_METADATA_88":
                                case 96:
                                    message.dimensions[i] = 96;
                                    break;
                                case "STORE_METADATA_89":
                                case 97:
                                    message.dimensions[i] = 97;
                                    break;
                                case "STORE_METADATA_90":
                                case 98:
                                    message.dimensions[i] = 98;
                                    break;
                                case "STORE_METADATA_91":
                                case 99:
                                    message.dimensions[i] = 99;
                                    break;
                                case "STORE_METADATA_92":
                                case 100:
                                    message.dimensions[i] = 100;
                                    break;
                                case "STORE_METADATA_93":
                                case 101:
                                    message.dimensions[i] = 101;
                                    break;
                                case "STORE_METADATA_94":
                                case 102:
                                    message.dimensions[i] = 102;
                                    break;
                                case "STORE_METADATA_95":
                                case 103:
                                    message.dimensions[i] = 103;
                                    break;
                                case "STORE_METADATA_96":
                                case 104:
                                    message.dimensions[i] = 104;
                                    break;
                                case "STORE_METADATA_97":
                                case 105:
                                    message.dimensions[i] = 105;
                                    break;
                                case "STORE_METADATA_98":
                                case 106:
                                    message.dimensions[i] = 106;
                                    break;
                                case "STORE_METADATA_99":
                                case 107:
                                    message.dimensions[i] = 107;
                                    break;
                                case "STORE_METADATA_100":
                                case 108:
                                    message.dimensions[i] = 108;
                                    break;
                                }
                        }
                        if (object.metrics) {
                            if (!Array.isArray(object.metrics))
                                throw TypeError(".palexy.streaming.v1.StoreEventAnalyticsReport.metrics: array expected");
                            message.metrics = [];
                            for (var i = 0; i < object.metrics.length; ++i)
                                switch (object.metrics[i]) {
                                default:
                                case "UNKNOWN_METRIC":
                                case 0:
                                    message.metrics[i] = 0;
                                    break;
                                case "NUMBER_OF_STORE_EVENTS":
                                case 1:
                                    message.metrics[i] = 1;
                                    break;
                                }
                        }
                        if (object.rows) {
                            if (!Array.isArray(object.rows))
                                throw TypeError(".palexy.streaming.v1.StoreEventAnalyticsReport.rows: array expected");
                            message.rows = [];
                            for (var i = 0; i < object.rows.length; ++i) {
                                if (typeof object.rows[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.StoreEventAnalyticsReport.rows: object expected");
                                message.rows[i] = $root.palexy.streaming.v1.StoreEventAnalyticsReport.Row.fromObject(object.rows[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreEventAnalyticsReport message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @static
                     * @param {palexy.streaming.v1.StoreEventAnalyticsReport} message StoreEventAnalyticsReport
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreEventAnalyticsReport.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.dates = [];
                            object.dimensions = [];
                            object.metrics = [];
                            object.rows = [];
                        }
                        if (message.dates && message.dates.length) {
                            object.dates = [];
                            for (var j = 0; j < message.dates.length; ++j)
                                object.dates[j] = message.dates[j];
                        }
                        if (message.dimensions && message.dimensions.length) {
                            object.dimensions = [];
                            for (var j = 0; j < message.dimensions.length; ++j)
                                object.dimensions[j] = options.enums === String ? $root.palexy.streaming.v1.StoreEventAnalyticsReport.Dimension[message.dimensions[j]] : message.dimensions[j];
                        }
                        if (message.metrics && message.metrics.length) {
                            object.metrics = [];
                            for (var j = 0; j < message.metrics.length; ++j)
                                object.metrics[j] = options.enums === String ? $root.palexy.streaming.v1.StoreEventAnalyticsReport.Metric[message.metrics[j]] : message.metrics[j];
                        }
                        if (message.rows && message.rows.length) {
                            object.rows = [];
                            for (var j = 0; j < message.rows.length; ++j)
                                object.rows[j] = $root.palexy.streaming.v1.StoreEventAnalyticsReport.Row.toObject(message.rows[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this StoreEventAnalyticsReport to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreEventAnalyticsReport.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Dimension enum.
                     * @name palexy.streaming.v1.StoreEventAnalyticsReport.Dimension
                     * @enum {number}
                     * @property {number} UNKNOWN_DIMENSION=0 UNKNOWN_DIMENSION value
                     * @property {number} STATUS=1 STATUS value
                     * @property {number} EVENT_TYPE=2 EVENT_TYPE value
                     * @property {number} NTH_DAY=3 NTH_DAY value
                     * @property {number} NTH_WEEK=4 NTH_WEEK value
                     * @property {number} CUSTOM_NTH_WEEK=18 CUSTOM_NTH_WEEK value
                     * @property {number} NTH_MONTH=5 NTH_MONTH value
                     * @property {number} STORE_NAME=6 STORE_NAME value
                     * @property {number} NTH_QUARTER=7 NTH_QUARTER value
                     * @property {number} STORE_METADATA_1=8 STORE_METADATA_1 value
                     * @property {number} STORE_METADATA_2=9 STORE_METADATA_2 value
                     * @property {number} STORE_METADATA_3=10 STORE_METADATA_3 value
                     * @property {number} STORE_METADATA_4=11 STORE_METADATA_4 value
                     * @property {number} STORE_METADATA_5=12 STORE_METADATA_5 value
                     * @property {number} STORE_METADATA_6=13 STORE_METADATA_6 value
                     * @property {number} STORE_METADATA_7=14 STORE_METADATA_7 value
                     * @property {number} STORE_METADATA_8=15 STORE_METADATA_8 value
                     * @property {number} STORE_METADATA_9=16 STORE_METADATA_9 value
                     * @property {number} STORE_METADATA_10=17 STORE_METADATA_10 value
                     * @property {number} STORE_METADATA_11=19 STORE_METADATA_11 value
                     * @property {number} STORE_METADATA_12=20 STORE_METADATA_12 value
                     * @property {number} STORE_METADATA_13=21 STORE_METADATA_13 value
                     * @property {number} STORE_METADATA_14=22 STORE_METADATA_14 value
                     * @property {number} STORE_METADATA_15=23 STORE_METADATA_15 value
                     * @property {number} STORE_METADATA_16=24 STORE_METADATA_16 value
                     * @property {number} STORE_METADATA_17=25 STORE_METADATA_17 value
                     * @property {number} STORE_METADATA_18=26 STORE_METADATA_18 value
                     * @property {number} STORE_METADATA_19=27 STORE_METADATA_19 value
                     * @property {number} STORE_METADATA_20=28 STORE_METADATA_20 value
                     * @property {number} STORE_METADATA_21=29 STORE_METADATA_21 value
                     * @property {number} STORE_METADATA_22=30 STORE_METADATA_22 value
                     * @property {number} STORE_METADATA_23=31 STORE_METADATA_23 value
                     * @property {number} STORE_METADATA_24=32 STORE_METADATA_24 value
                     * @property {number} STORE_METADATA_25=33 STORE_METADATA_25 value
                     * @property {number} STORE_METADATA_26=34 STORE_METADATA_26 value
                     * @property {number} STORE_METADATA_27=35 STORE_METADATA_27 value
                     * @property {number} STORE_METADATA_28=36 STORE_METADATA_28 value
                     * @property {number} STORE_METADATA_29=37 STORE_METADATA_29 value
                     * @property {number} STORE_METADATA_30=38 STORE_METADATA_30 value
                     * @property {number} STORE_METADATA_31=39 STORE_METADATA_31 value
                     * @property {number} STORE_METADATA_32=40 STORE_METADATA_32 value
                     * @property {number} STORE_METADATA_33=41 STORE_METADATA_33 value
                     * @property {number} STORE_METADATA_34=42 STORE_METADATA_34 value
                     * @property {number} STORE_METADATA_35=43 STORE_METADATA_35 value
                     * @property {number} STORE_METADATA_36=44 STORE_METADATA_36 value
                     * @property {number} STORE_METADATA_37=45 STORE_METADATA_37 value
                     * @property {number} STORE_METADATA_38=46 STORE_METADATA_38 value
                     * @property {number} STORE_METADATA_39=47 STORE_METADATA_39 value
                     * @property {number} STORE_METADATA_40=48 STORE_METADATA_40 value
                     * @property {number} STORE_METADATA_41=49 STORE_METADATA_41 value
                     * @property {number} STORE_METADATA_42=50 STORE_METADATA_42 value
                     * @property {number} STORE_METADATA_43=51 STORE_METADATA_43 value
                     * @property {number} STORE_METADATA_44=52 STORE_METADATA_44 value
                     * @property {number} STORE_METADATA_45=53 STORE_METADATA_45 value
                     * @property {number} STORE_METADATA_46=54 STORE_METADATA_46 value
                     * @property {number} STORE_METADATA_47=55 STORE_METADATA_47 value
                     * @property {number} STORE_METADATA_48=56 STORE_METADATA_48 value
                     * @property {number} STORE_METADATA_49=57 STORE_METADATA_49 value
                     * @property {number} STORE_METADATA_50=58 STORE_METADATA_50 value
                     * @property {number} STORE_METADATA_51=59 STORE_METADATA_51 value
                     * @property {number} STORE_METADATA_52=60 STORE_METADATA_52 value
                     * @property {number} STORE_METADATA_53=61 STORE_METADATA_53 value
                     * @property {number} STORE_METADATA_54=62 STORE_METADATA_54 value
                     * @property {number} STORE_METADATA_55=63 STORE_METADATA_55 value
                     * @property {number} STORE_METADATA_56=64 STORE_METADATA_56 value
                     * @property {number} STORE_METADATA_57=65 STORE_METADATA_57 value
                     * @property {number} STORE_METADATA_58=66 STORE_METADATA_58 value
                     * @property {number} STORE_METADATA_59=67 STORE_METADATA_59 value
                     * @property {number} STORE_METADATA_60=68 STORE_METADATA_60 value
                     * @property {number} STORE_METADATA_61=69 STORE_METADATA_61 value
                     * @property {number} STORE_METADATA_62=70 STORE_METADATA_62 value
                     * @property {number} STORE_METADATA_63=71 STORE_METADATA_63 value
                     * @property {number} STORE_METADATA_64=72 STORE_METADATA_64 value
                     * @property {number} STORE_METADATA_65=73 STORE_METADATA_65 value
                     * @property {number} STORE_METADATA_66=74 STORE_METADATA_66 value
                     * @property {number} STORE_METADATA_67=75 STORE_METADATA_67 value
                     * @property {number} STORE_METADATA_68=76 STORE_METADATA_68 value
                     * @property {number} STORE_METADATA_69=77 STORE_METADATA_69 value
                     * @property {number} STORE_METADATA_70=78 STORE_METADATA_70 value
                     * @property {number} STORE_METADATA_71=79 STORE_METADATA_71 value
                     * @property {number} STORE_METADATA_72=80 STORE_METADATA_72 value
                     * @property {number} STORE_METADATA_73=81 STORE_METADATA_73 value
                     * @property {number} STORE_METADATA_74=82 STORE_METADATA_74 value
                     * @property {number} STORE_METADATA_75=83 STORE_METADATA_75 value
                     * @property {number} STORE_METADATA_76=84 STORE_METADATA_76 value
                     * @property {number} STORE_METADATA_77=85 STORE_METADATA_77 value
                     * @property {number} STORE_METADATA_78=86 STORE_METADATA_78 value
                     * @property {number} STORE_METADATA_79=87 STORE_METADATA_79 value
                     * @property {number} STORE_METADATA_80=88 STORE_METADATA_80 value
                     * @property {number} STORE_METADATA_81=89 STORE_METADATA_81 value
                     * @property {number} STORE_METADATA_82=90 STORE_METADATA_82 value
                     * @property {number} STORE_METADATA_83=91 STORE_METADATA_83 value
                     * @property {number} STORE_METADATA_84=92 STORE_METADATA_84 value
                     * @property {number} STORE_METADATA_85=93 STORE_METADATA_85 value
                     * @property {number} STORE_METADATA_86=94 STORE_METADATA_86 value
                     * @property {number} STORE_METADATA_87=95 STORE_METADATA_87 value
                     * @property {number} STORE_METADATA_88=96 STORE_METADATA_88 value
                     * @property {number} STORE_METADATA_89=97 STORE_METADATA_89 value
                     * @property {number} STORE_METADATA_90=98 STORE_METADATA_90 value
                     * @property {number} STORE_METADATA_91=99 STORE_METADATA_91 value
                     * @property {number} STORE_METADATA_92=100 STORE_METADATA_92 value
                     * @property {number} STORE_METADATA_93=101 STORE_METADATA_93 value
                     * @property {number} STORE_METADATA_94=102 STORE_METADATA_94 value
                     * @property {number} STORE_METADATA_95=103 STORE_METADATA_95 value
                     * @property {number} STORE_METADATA_96=104 STORE_METADATA_96 value
                     * @property {number} STORE_METADATA_97=105 STORE_METADATA_97 value
                     * @property {number} STORE_METADATA_98=106 STORE_METADATA_98 value
                     * @property {number} STORE_METADATA_99=107 STORE_METADATA_99 value
                     * @property {number} STORE_METADATA_100=108 STORE_METADATA_100 value
                     */
                    StoreEventAnalyticsReport.Dimension = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_DIMENSION"] = 0;
                        values[valuesById[1] = "STATUS"] = 1;
                        values[valuesById[2] = "EVENT_TYPE"] = 2;
                        values[valuesById[3] = "NTH_DAY"] = 3;
                        values[valuesById[4] = "NTH_WEEK"] = 4;
                        values[valuesById[18] = "CUSTOM_NTH_WEEK"] = 18;
                        values[valuesById[5] = "NTH_MONTH"] = 5;
                        values[valuesById[6] = "STORE_NAME"] = 6;
                        values[valuesById[7] = "NTH_QUARTER"] = 7;
                        values[valuesById[8] = "STORE_METADATA_1"] = 8;
                        values[valuesById[9] = "STORE_METADATA_2"] = 9;
                        values[valuesById[10] = "STORE_METADATA_3"] = 10;
                        values[valuesById[11] = "STORE_METADATA_4"] = 11;
                        values[valuesById[12] = "STORE_METADATA_5"] = 12;
                        values[valuesById[13] = "STORE_METADATA_6"] = 13;
                        values[valuesById[14] = "STORE_METADATA_7"] = 14;
                        values[valuesById[15] = "STORE_METADATA_8"] = 15;
                        values[valuesById[16] = "STORE_METADATA_9"] = 16;
                        values[valuesById[17] = "STORE_METADATA_10"] = 17;
                        values[valuesById[19] = "STORE_METADATA_11"] = 19;
                        values[valuesById[20] = "STORE_METADATA_12"] = 20;
                        values[valuesById[21] = "STORE_METADATA_13"] = 21;
                        values[valuesById[22] = "STORE_METADATA_14"] = 22;
                        values[valuesById[23] = "STORE_METADATA_15"] = 23;
                        values[valuesById[24] = "STORE_METADATA_16"] = 24;
                        values[valuesById[25] = "STORE_METADATA_17"] = 25;
                        values[valuesById[26] = "STORE_METADATA_18"] = 26;
                        values[valuesById[27] = "STORE_METADATA_19"] = 27;
                        values[valuesById[28] = "STORE_METADATA_20"] = 28;
                        values[valuesById[29] = "STORE_METADATA_21"] = 29;
                        values[valuesById[30] = "STORE_METADATA_22"] = 30;
                        values[valuesById[31] = "STORE_METADATA_23"] = 31;
                        values[valuesById[32] = "STORE_METADATA_24"] = 32;
                        values[valuesById[33] = "STORE_METADATA_25"] = 33;
                        values[valuesById[34] = "STORE_METADATA_26"] = 34;
                        values[valuesById[35] = "STORE_METADATA_27"] = 35;
                        values[valuesById[36] = "STORE_METADATA_28"] = 36;
                        values[valuesById[37] = "STORE_METADATA_29"] = 37;
                        values[valuesById[38] = "STORE_METADATA_30"] = 38;
                        values[valuesById[39] = "STORE_METADATA_31"] = 39;
                        values[valuesById[40] = "STORE_METADATA_32"] = 40;
                        values[valuesById[41] = "STORE_METADATA_33"] = 41;
                        values[valuesById[42] = "STORE_METADATA_34"] = 42;
                        values[valuesById[43] = "STORE_METADATA_35"] = 43;
                        values[valuesById[44] = "STORE_METADATA_36"] = 44;
                        values[valuesById[45] = "STORE_METADATA_37"] = 45;
                        values[valuesById[46] = "STORE_METADATA_38"] = 46;
                        values[valuesById[47] = "STORE_METADATA_39"] = 47;
                        values[valuesById[48] = "STORE_METADATA_40"] = 48;
                        values[valuesById[49] = "STORE_METADATA_41"] = 49;
                        values[valuesById[50] = "STORE_METADATA_42"] = 50;
                        values[valuesById[51] = "STORE_METADATA_43"] = 51;
                        values[valuesById[52] = "STORE_METADATA_44"] = 52;
                        values[valuesById[53] = "STORE_METADATA_45"] = 53;
                        values[valuesById[54] = "STORE_METADATA_46"] = 54;
                        values[valuesById[55] = "STORE_METADATA_47"] = 55;
                        values[valuesById[56] = "STORE_METADATA_48"] = 56;
                        values[valuesById[57] = "STORE_METADATA_49"] = 57;
                        values[valuesById[58] = "STORE_METADATA_50"] = 58;
                        values[valuesById[59] = "STORE_METADATA_51"] = 59;
                        values[valuesById[60] = "STORE_METADATA_52"] = 60;
                        values[valuesById[61] = "STORE_METADATA_53"] = 61;
                        values[valuesById[62] = "STORE_METADATA_54"] = 62;
                        values[valuesById[63] = "STORE_METADATA_55"] = 63;
                        values[valuesById[64] = "STORE_METADATA_56"] = 64;
                        values[valuesById[65] = "STORE_METADATA_57"] = 65;
                        values[valuesById[66] = "STORE_METADATA_58"] = 66;
                        values[valuesById[67] = "STORE_METADATA_59"] = 67;
                        values[valuesById[68] = "STORE_METADATA_60"] = 68;
                        values[valuesById[69] = "STORE_METADATA_61"] = 69;
                        values[valuesById[70] = "STORE_METADATA_62"] = 70;
                        values[valuesById[71] = "STORE_METADATA_63"] = 71;
                        values[valuesById[72] = "STORE_METADATA_64"] = 72;
                        values[valuesById[73] = "STORE_METADATA_65"] = 73;
                        values[valuesById[74] = "STORE_METADATA_66"] = 74;
                        values[valuesById[75] = "STORE_METADATA_67"] = 75;
                        values[valuesById[76] = "STORE_METADATA_68"] = 76;
                        values[valuesById[77] = "STORE_METADATA_69"] = 77;
                        values[valuesById[78] = "STORE_METADATA_70"] = 78;
                        values[valuesById[79] = "STORE_METADATA_71"] = 79;
                        values[valuesById[80] = "STORE_METADATA_72"] = 80;
                        values[valuesById[81] = "STORE_METADATA_73"] = 81;
                        values[valuesById[82] = "STORE_METADATA_74"] = 82;
                        values[valuesById[83] = "STORE_METADATA_75"] = 83;
                        values[valuesById[84] = "STORE_METADATA_76"] = 84;
                        values[valuesById[85] = "STORE_METADATA_77"] = 85;
                        values[valuesById[86] = "STORE_METADATA_78"] = 86;
                        values[valuesById[87] = "STORE_METADATA_79"] = 87;
                        values[valuesById[88] = "STORE_METADATA_80"] = 88;
                        values[valuesById[89] = "STORE_METADATA_81"] = 89;
                        values[valuesById[90] = "STORE_METADATA_82"] = 90;
                        values[valuesById[91] = "STORE_METADATA_83"] = 91;
                        values[valuesById[92] = "STORE_METADATA_84"] = 92;
                        values[valuesById[93] = "STORE_METADATA_85"] = 93;
                        values[valuesById[94] = "STORE_METADATA_86"] = 94;
                        values[valuesById[95] = "STORE_METADATA_87"] = 95;
                        values[valuesById[96] = "STORE_METADATA_88"] = 96;
                        values[valuesById[97] = "STORE_METADATA_89"] = 97;
                        values[valuesById[98] = "STORE_METADATA_90"] = 98;
                        values[valuesById[99] = "STORE_METADATA_91"] = 99;
                        values[valuesById[100] = "STORE_METADATA_92"] = 100;
                        values[valuesById[101] = "STORE_METADATA_93"] = 101;
                        values[valuesById[102] = "STORE_METADATA_94"] = 102;
                        values[valuesById[103] = "STORE_METADATA_95"] = 103;
                        values[valuesById[104] = "STORE_METADATA_96"] = 104;
                        values[valuesById[105] = "STORE_METADATA_97"] = 105;
                        values[valuesById[106] = "STORE_METADATA_98"] = 106;
                        values[valuesById[107] = "STORE_METADATA_99"] = 107;
                        values[valuesById[108] = "STORE_METADATA_100"] = 108;
                        return values;
                    })();
    
                    /**
                     * Metric enum.
                     * @name palexy.streaming.v1.StoreEventAnalyticsReport.Metric
                     * @enum {number}
                     * @property {number} UNKNOWN_METRIC=0 UNKNOWN_METRIC value
                     * @property {number} NUMBER_OF_STORE_EVENTS=1 NUMBER_OF_STORE_EVENTS value
                     */
                    StoreEventAnalyticsReport.Metric = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_METRIC"] = 0;
                        values[valuesById[1] = "NUMBER_OF_STORE_EVENTS"] = 1;
                        return values;
                    })();
    
                    StoreEventAnalyticsReport.Row = (function() {
    
                        /**
                         * Properties of a Row.
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                         * @interface IRow
                         * @property {Array.<string>|null} [dimensionValues] Row dimensionValues
                         * @property {Array.<number>|null} [metricValues] Row metricValues
                         */
    
                        /**
                         * Constructs a new Row.
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport
                         * @classdesc Represents a Row.
                         * @implements IRow
                         * @constructor
                         * @param {palexy.streaming.v1.StoreEventAnalyticsReport.IRow=} [properties] Properties to set
                         */
                        function Row(properties) {
                            this.dimensionValues = [];
                            this.metricValues = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * Row dimensionValues.
                         * @member {Array.<string>} dimensionValues
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport.Row
                         * @instance
                         */
                        Row.prototype.dimensionValues = $util.emptyArray;
    
                        /**
                         * Row metricValues.
                         * @member {Array.<number>} metricValues
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport.Row
                         * @instance
                         */
                        Row.prototype.metricValues = $util.emptyArray;
    
                        /**
                         * Creates a new Row instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport.Row
                         * @static
                         * @param {palexy.streaming.v1.StoreEventAnalyticsReport.IRow=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.StoreEventAnalyticsReport.Row} Row instance
                         */
                        Row.create = function create(properties) {
                            return new Row(properties);
                        };
    
                        /**
                         * Encodes the specified Row message. Does not implicitly {@link palexy.streaming.v1.StoreEventAnalyticsReport.Row.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport.Row
                         * @static
                         * @param {palexy.streaming.v1.StoreEventAnalyticsReport.IRow} message Row message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Row.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.dimensionValues != null && message.dimensionValues.length)
                                for (var i = 0; i < message.dimensionValues.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.dimensionValues[i]);
                            if (message.metricValues != null && message.metricValues.length) {
                                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                                for (var i = 0; i < message.metricValues.length; ++i)
                                    writer.double(message.metricValues[i]);
                                writer.ldelim();
                            }
                            return writer;
                        };
    
                        /**
                         * Encodes the specified Row message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreEventAnalyticsReport.Row.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport.Row
                         * @static
                         * @param {palexy.streaming.v1.StoreEventAnalyticsReport.IRow} message Row message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Row.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a Row message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport.Row
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.StoreEventAnalyticsReport.Row} Row
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Row.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreEventAnalyticsReport.Row();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.dimensionValues && message.dimensionValues.length))
                                        message.dimensionValues = [];
                                    message.dimensionValues.push(reader.string());
                                    break;
                                case 2:
                                    if (!(message.metricValues && message.metricValues.length))
                                        message.metricValues = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.metricValues.push(reader.double());
                                    } else
                                        message.metricValues.push(reader.double());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a Row message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport.Row
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.StoreEventAnalyticsReport.Row} Row
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Row.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a Row message.
                         * @function verify
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport.Row
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Row.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.dimensionValues != null && message.hasOwnProperty("dimensionValues")) {
                                if (!Array.isArray(message.dimensionValues))
                                    return "dimensionValues: array expected";
                                for (var i = 0; i < message.dimensionValues.length; ++i)
                                    if (!$util.isString(message.dimensionValues[i]))
                                        return "dimensionValues: string[] expected";
                            }
                            if (message.metricValues != null && message.hasOwnProperty("metricValues")) {
                                if (!Array.isArray(message.metricValues))
                                    return "metricValues: array expected";
                                for (var i = 0; i < message.metricValues.length; ++i)
                                    if (typeof message.metricValues[i] !== "number")
                                        return "metricValues: number[] expected";
                            }
                            return null;
                        };
    
                        /**
                         * Creates a Row message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport.Row
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.StoreEventAnalyticsReport.Row} Row
                         */
                        Row.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.StoreEventAnalyticsReport.Row)
                                return object;
                            var message = new $root.palexy.streaming.v1.StoreEventAnalyticsReport.Row();
                            if (object.dimensionValues) {
                                if (!Array.isArray(object.dimensionValues))
                                    throw TypeError(".palexy.streaming.v1.StoreEventAnalyticsReport.Row.dimensionValues: array expected");
                                message.dimensionValues = [];
                                for (var i = 0; i < object.dimensionValues.length; ++i)
                                    message.dimensionValues[i] = String(object.dimensionValues[i]);
                            }
                            if (object.metricValues) {
                                if (!Array.isArray(object.metricValues))
                                    throw TypeError(".palexy.streaming.v1.StoreEventAnalyticsReport.Row.metricValues: array expected");
                                message.metricValues = [];
                                for (var i = 0; i < object.metricValues.length; ++i)
                                    message.metricValues[i] = Number(object.metricValues[i]);
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a Row message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport.Row
                         * @static
                         * @param {palexy.streaming.v1.StoreEventAnalyticsReport.Row} message Row
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Row.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.dimensionValues = [];
                                object.metricValues = [];
                            }
                            if (message.dimensionValues && message.dimensionValues.length) {
                                object.dimensionValues = [];
                                for (var j = 0; j < message.dimensionValues.length; ++j)
                                    object.dimensionValues[j] = message.dimensionValues[j];
                            }
                            if (message.metricValues && message.metricValues.length) {
                                object.metricValues = [];
                                for (var j = 0; j < message.metricValues.length; ++j)
                                    object.metricValues[j] = options.json && !isFinite(message.metricValues[j]) ? String(message.metricValues[j]) : message.metricValues[j];
                            }
                            return object;
                        };
    
                        /**
                         * Converts this Row to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.StoreEventAnalyticsReport.Row
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Row.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return Row;
                    })();
    
                    return StoreEventAnalyticsReport;
                })();
    
                v1.ListStoreEventReviewLogRequest = (function() {
    
                    /**
                     * Properties of a ListStoreEventReviewLogRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreEventReviewLogRequest
                     * @property {number|null} [id] ListStoreEventReviewLogRequest id
                     */
    
                    /**
                     * Constructs a new ListStoreEventReviewLogRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreEventReviewLogRequest.
                     * @implements IListStoreEventReviewLogRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreEventReviewLogRequest=} [properties] Properties to set
                     */
                    function ListStoreEventReviewLogRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreEventReviewLogRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogRequest
                     * @instance
                     */
                    ListStoreEventReviewLogRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListStoreEventReviewLogRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventReviewLogRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreEventReviewLogRequest} ListStoreEventReviewLogRequest instance
                     */
                    ListStoreEventReviewLogRequest.create = function create(properties) {
                        return new ListStoreEventReviewLogRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreEventReviewLogRequest message. Does not implicitly {@link palexy.streaming.v1.ListStoreEventReviewLogRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventReviewLogRequest} message ListStoreEventReviewLogRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreEventReviewLogRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreEventReviewLogRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreEventReviewLogRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventReviewLogRequest} message ListStoreEventReviewLogRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreEventReviewLogRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreEventReviewLogRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreEventReviewLogRequest} ListStoreEventReviewLogRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreEventReviewLogRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreEventReviewLogRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreEventReviewLogRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreEventReviewLogRequest} ListStoreEventReviewLogRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreEventReviewLogRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreEventReviewLogRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreEventReviewLogRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreEventReviewLogRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreEventReviewLogRequest} ListStoreEventReviewLogRequest
                     */
                    ListStoreEventReviewLogRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreEventReviewLogRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreEventReviewLogRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreEventReviewLogRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStoreEventReviewLogRequest} message ListStoreEventReviewLogRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreEventReviewLogRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreEventReviewLogRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreEventReviewLogRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreEventReviewLogRequest;
                })();
    
                v1.ListStoreEventReviewLogResponse = (function() {
    
                    /**
                     * Properties of a ListStoreEventReviewLogResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreEventReviewLogResponse
                     * @property {Array.<palexy.streaming.v1.IStoreEventReviewLog>|null} [reviewLogs] ListStoreEventReviewLogResponse reviewLogs
                     */
    
                    /**
                     * Constructs a new ListStoreEventReviewLogResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreEventReviewLogResponse.
                     * @implements IListStoreEventReviewLogResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreEventReviewLogResponse=} [properties] Properties to set
                     */
                    function ListStoreEventReviewLogResponse(properties) {
                        this.reviewLogs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreEventReviewLogResponse reviewLogs.
                     * @member {Array.<palexy.streaming.v1.IStoreEventReviewLog>} reviewLogs
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogResponse
                     * @instance
                     */
                    ListStoreEventReviewLogResponse.prototype.reviewLogs = $util.emptyArray;
    
                    /**
                     * Creates a new ListStoreEventReviewLogResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventReviewLogResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreEventReviewLogResponse} ListStoreEventReviewLogResponse instance
                     */
                    ListStoreEventReviewLogResponse.create = function create(properties) {
                        return new ListStoreEventReviewLogResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreEventReviewLogResponse message. Does not implicitly {@link palexy.streaming.v1.ListStoreEventReviewLogResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventReviewLogResponse} message ListStoreEventReviewLogResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreEventReviewLogResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reviewLogs != null && message.reviewLogs.length)
                            for (var i = 0; i < message.reviewLogs.length; ++i)
                                $root.palexy.streaming.v1.StoreEventReviewLog.encode(message.reviewLogs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreEventReviewLogResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreEventReviewLogResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventReviewLogResponse} message ListStoreEventReviewLogResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreEventReviewLogResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreEventReviewLogResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreEventReviewLogResponse} ListStoreEventReviewLogResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreEventReviewLogResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreEventReviewLogResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.reviewLogs && message.reviewLogs.length))
                                    message.reviewLogs = [];
                                message.reviewLogs.push($root.palexy.streaming.v1.StoreEventReviewLog.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreEventReviewLogResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreEventReviewLogResponse} ListStoreEventReviewLogResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreEventReviewLogResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreEventReviewLogResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreEventReviewLogResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reviewLogs != null && message.hasOwnProperty("reviewLogs")) {
                            if (!Array.isArray(message.reviewLogs))
                                return "reviewLogs: array expected";
                            for (var i = 0; i < message.reviewLogs.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreEventReviewLog.verify(message.reviewLogs[i]);
                                if (error)
                                    return "reviewLogs." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreEventReviewLogResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreEventReviewLogResponse} ListStoreEventReviewLogResponse
                     */
                    ListStoreEventReviewLogResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreEventReviewLogResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreEventReviewLogResponse();
                        if (object.reviewLogs) {
                            if (!Array.isArray(object.reviewLogs))
                                throw TypeError(".palexy.streaming.v1.ListStoreEventReviewLogResponse.reviewLogs: array expected");
                            message.reviewLogs = [];
                            for (var i = 0; i < object.reviewLogs.length; ++i) {
                                if (typeof object.reviewLogs[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStoreEventReviewLogResponse.reviewLogs: object expected");
                                message.reviewLogs[i] = $root.palexy.streaming.v1.StoreEventReviewLog.fromObject(object.reviewLogs[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreEventReviewLogResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStoreEventReviewLogResponse} message ListStoreEventReviewLogResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreEventReviewLogResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.reviewLogs = [];
                        if (message.reviewLogs && message.reviewLogs.length) {
                            object.reviewLogs = [];
                            for (var j = 0; j < message.reviewLogs.length; ++j)
                                object.reviewLogs[j] = $root.palexy.streaming.v1.StoreEventReviewLog.toObject(message.reviewLogs[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreEventReviewLogResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreEventReviewLogResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreEventReviewLogResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreEventReviewLogResponse;
                })();
    
                v1.CreateStoreEventRequest = (function() {
    
                    /**
                     * Properties of a CreateStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateStoreEventRequest
                     * @property {palexy.streaming.v1.IStoreEvent|null} [event] CreateStoreEventRequest event
                     */
    
                    /**
                     * Constructs a new CreateStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateStoreEventRequest.
                     * @implements ICreateStoreEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateStoreEventRequest=} [properties] Properties to set
                     */
                    function CreateStoreEventRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateStoreEventRequest event.
                     * @member {palexy.streaming.v1.IStoreEvent|null|undefined} event
                     * @memberof palexy.streaming.v1.CreateStoreEventRequest
                     * @instance
                     */
                    CreateStoreEventRequest.prototype.event = null;
    
                    /**
                     * Creates a new CreateStoreEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateStoreEventRequest} CreateStoreEventRequest instance
                     */
                    CreateStoreEventRequest.create = function create(properties) {
                        return new CreateStoreEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateStoreEventRequest message. Does not implicitly {@link palexy.streaming.v1.CreateStoreEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreEventRequest} message CreateStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStoreEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.event != null && Object.hasOwnProperty.call(message, "event"))
                            $root.palexy.streaming.v1.StoreEvent.encode(message.event, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateStoreEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateStoreEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreEventRequest} message CreateStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStoreEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateStoreEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateStoreEventRequest} CreateStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStoreEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateStoreEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.event = $root.palexy.streaming.v1.StoreEvent.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateStoreEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateStoreEventRequest} CreateStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStoreEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateStoreEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateStoreEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.event != null && message.hasOwnProperty("event")) {
                            var error = $root.palexy.streaming.v1.StoreEvent.verify(message.event);
                            if (error)
                                return "event." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateStoreEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateStoreEventRequest} CreateStoreEventRequest
                     */
                    CreateStoreEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateStoreEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateStoreEventRequest();
                        if (object.event != null) {
                            if (typeof object.event !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateStoreEventRequest.event: object expected");
                            message.event = $root.palexy.streaming.v1.StoreEvent.fromObject(object.event);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateStoreEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateStoreEventRequest} message CreateStoreEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateStoreEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.event = null;
                        if (message.event != null && message.hasOwnProperty("event"))
                            object.event = $root.palexy.streaming.v1.StoreEvent.toObject(message.event, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateStoreEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateStoreEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateStoreEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateStoreEventRequest;
                })();
    
                v1.BatchCreateStoreEventRequest = (function() {
    
                    /**
                     * Properties of a BatchCreateStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreateStoreEventRequest
                     * @property {Array.<palexy.streaming.v1.IStoreEvent>|null} [events] BatchCreateStoreEventRequest events
                     */
    
                    /**
                     * Constructs a new BatchCreateStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreateStoreEventRequest.
                     * @implements IBatchCreateStoreEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreateStoreEventRequest=} [properties] Properties to set
                     */
                    function BatchCreateStoreEventRequest(properties) {
                        this.events = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateStoreEventRequest events.
                     * @member {Array.<palexy.streaming.v1.IStoreEvent>} events
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventRequest
                     * @instance
                     */
                    BatchCreateStoreEventRequest.prototype.events = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateStoreEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreateStoreEventRequest} BatchCreateStoreEventRequest instance
                     */
                    BatchCreateStoreEventRequest.create = function create(properties) {
                        return new BatchCreateStoreEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateStoreEventRequest message. Does not implicitly {@link palexy.streaming.v1.BatchCreateStoreEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreEventRequest} message BatchCreateStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStoreEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.events != null && message.events.length)
                            for (var i = 0; i < message.events.length; ++i)
                                $root.palexy.streaming.v1.StoreEvent.encode(message.events[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateStoreEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreateStoreEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreEventRequest} message BatchCreateStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStoreEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateStoreEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreateStoreEventRequest} BatchCreateStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStoreEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreateStoreEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.events && message.events.length))
                                    message.events = [];
                                message.events.push($root.palexy.streaming.v1.StoreEvent.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateStoreEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreateStoreEventRequest} BatchCreateStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStoreEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateStoreEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateStoreEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.events != null && message.hasOwnProperty("events")) {
                            if (!Array.isArray(message.events))
                                return "events: array expected";
                            for (var i = 0; i < message.events.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreEvent.verify(message.events[i]);
                                if (error)
                                    return "events." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateStoreEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreateStoreEventRequest} BatchCreateStoreEventRequest
                     */
                    BatchCreateStoreEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreateStoreEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreateStoreEventRequest();
                        if (object.events) {
                            if (!Array.isArray(object.events))
                                throw TypeError(".palexy.streaming.v1.BatchCreateStoreEventRequest.events: array expected");
                            message.events = [];
                            for (var i = 0; i < object.events.length; ++i) {
                                if (typeof object.events[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreateStoreEventRequest.events: object expected");
                                message.events[i] = $root.palexy.streaming.v1.StoreEvent.fromObject(object.events[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateStoreEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchCreateStoreEventRequest} message BatchCreateStoreEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateStoreEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.events = [];
                        if (message.events && message.events.length) {
                            object.events = [];
                            for (var j = 0; j < message.events.length; ++j)
                                object.events[j] = $root.palexy.streaming.v1.StoreEvent.toObject(message.events[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateStoreEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateStoreEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateStoreEventRequest;
                })();
    
                v1.BatchCreateStoreEventResponse = (function() {
    
                    /**
                     * Properties of a BatchCreateStoreEventResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreateStoreEventResponse
                     * @property {Array.<palexy.streaming.v1.IStoreEvent>|null} [events] BatchCreateStoreEventResponse events
                     */
    
                    /**
                     * Constructs a new BatchCreateStoreEventResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreateStoreEventResponse.
                     * @implements IBatchCreateStoreEventResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreateStoreEventResponse=} [properties] Properties to set
                     */
                    function BatchCreateStoreEventResponse(properties) {
                        this.events = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateStoreEventResponse events.
                     * @member {Array.<palexy.streaming.v1.IStoreEvent>} events
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventResponse
                     * @instance
                     */
                    BatchCreateStoreEventResponse.prototype.events = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateStoreEventResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreEventResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreateStoreEventResponse} BatchCreateStoreEventResponse instance
                     */
                    BatchCreateStoreEventResponse.create = function create(properties) {
                        return new BatchCreateStoreEventResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateStoreEventResponse message. Does not implicitly {@link palexy.streaming.v1.BatchCreateStoreEventResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreEventResponse} message BatchCreateStoreEventResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStoreEventResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.events != null && message.events.length)
                            for (var i = 0; i < message.events.length; ++i)
                                $root.palexy.streaming.v1.StoreEvent.encode(message.events[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateStoreEventResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreateStoreEventResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreEventResponse} message BatchCreateStoreEventResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStoreEventResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateStoreEventResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreateStoreEventResponse} BatchCreateStoreEventResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStoreEventResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreateStoreEventResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.events && message.events.length))
                                    message.events = [];
                                message.events.push($root.palexy.streaming.v1.StoreEvent.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateStoreEventResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreateStoreEventResponse} BatchCreateStoreEventResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStoreEventResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateStoreEventResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateStoreEventResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.events != null && message.hasOwnProperty("events")) {
                            if (!Array.isArray(message.events))
                                return "events: array expected";
                            for (var i = 0; i < message.events.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreEvent.verify(message.events[i]);
                                if (error)
                                    return "events." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateStoreEventResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreateStoreEventResponse} BatchCreateStoreEventResponse
                     */
                    BatchCreateStoreEventResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreateStoreEventResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreateStoreEventResponse();
                        if (object.events) {
                            if (!Array.isArray(object.events))
                                throw TypeError(".palexy.streaming.v1.BatchCreateStoreEventResponse.events: array expected");
                            message.events = [];
                            for (var i = 0; i < object.events.length; ++i) {
                                if (typeof object.events[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreateStoreEventResponse.events: object expected");
                                message.events[i] = $root.palexy.streaming.v1.StoreEvent.fromObject(object.events[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateStoreEventResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchCreateStoreEventResponse} message BatchCreateStoreEventResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateStoreEventResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.events = [];
                        if (message.events && message.events.length) {
                            object.events = [];
                            for (var j = 0; j < message.events.length; ++j)
                                object.events[j] = $root.palexy.streaming.v1.StoreEvent.toObject(message.events[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateStoreEventResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreateStoreEventResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateStoreEventResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateStoreEventResponse;
                })();
    
                v1.GetStoreEventRequest = (function() {
    
                    /**
                     * Properties of a GetStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStoreEventRequest
                     * @property {number|null} [id] GetStoreEventRequest id
                     */
    
                    /**
                     * Constructs a new GetStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStoreEventRequest.
                     * @implements IGetStoreEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStoreEventRequest=} [properties] Properties to set
                     */
                    function GetStoreEventRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStoreEventRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.GetStoreEventRequest
                     * @instance
                     */
                    GetStoreEventRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetStoreEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStoreEventRequest} GetStoreEventRequest instance
                     */
                    GetStoreEventRequest.create = function create(properties) {
                        return new GetStoreEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStoreEventRequest message. Does not implicitly {@link palexy.streaming.v1.GetStoreEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreEventRequest} message GetStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStoreEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStoreEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoreEventRequest} message GetStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStoreEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStoreEventRequest} GetStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStoreEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStoreEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStoreEventRequest} GetStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStoreEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStoreEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStoreEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStoreEventRequest} GetStoreEventRequest
                     */
                    GetStoreEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStoreEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStoreEventRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStoreEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStoreEventRequest} message GetStoreEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStoreEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this GetStoreEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStoreEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStoreEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStoreEventRequest;
                })();
    
                v1.UpdateStoreEventRequest = (function() {
    
                    /**
                     * Properties of an UpdateStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateStoreEventRequest
                     * @property {palexy.streaming.v1.IStoreEvent|null} [event] UpdateStoreEventRequest event
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateStoreEventRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateStoreEventRequest.
                     * @implements IUpdateStoreEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateStoreEventRequest=} [properties] Properties to set
                     */
                    function UpdateStoreEventRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateStoreEventRequest event.
                     * @member {palexy.streaming.v1.IStoreEvent|null|undefined} event
                     * @memberof palexy.streaming.v1.UpdateStoreEventRequest
                     * @instance
                     */
                    UpdateStoreEventRequest.prototype.event = null;
    
                    /**
                     * UpdateStoreEventRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateStoreEventRequest
                     * @instance
                     */
                    UpdateStoreEventRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateStoreEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateStoreEventRequest} UpdateStoreEventRequest instance
                     */
                    UpdateStoreEventRequest.create = function create(properties) {
                        return new UpdateStoreEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateStoreEventRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateStoreEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreEventRequest} message UpdateStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStoreEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.event != null && Object.hasOwnProperty.call(message, "event"))
                            $root.palexy.streaming.v1.StoreEvent.encode(message.event, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateStoreEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateStoreEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStoreEventRequest} message UpdateStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStoreEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateStoreEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateStoreEventRequest} UpdateStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStoreEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateStoreEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.event = $root.palexy.streaming.v1.StoreEvent.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateStoreEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateStoreEventRequest} UpdateStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStoreEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateStoreEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateStoreEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.event != null && message.hasOwnProperty("event")) {
                            var error = $root.palexy.streaming.v1.StoreEvent.verify(message.event);
                            if (error)
                                return "event." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateStoreEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateStoreEventRequest} UpdateStoreEventRequest
                     */
                    UpdateStoreEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateStoreEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateStoreEventRequest();
                        if (object.event != null) {
                            if (typeof object.event !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStoreEventRequest.event: object expected");
                            message.event = $root.palexy.streaming.v1.StoreEvent.fromObject(object.event);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStoreEventRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateStoreEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateStoreEventRequest} message UpdateStoreEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateStoreEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.event = null;
                            object.updateMask = null;
                        }
                        if (message.event != null && message.hasOwnProperty("event"))
                            object.event = $root.palexy.streaming.v1.StoreEvent.toObject(message.event, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateStoreEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateStoreEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateStoreEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateStoreEventRequest;
                })();
    
                v1.DeleteStoreEventRequest = (function() {
    
                    /**
                     * Properties of a DeleteStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteStoreEventRequest
                     * @property {number|null} [id] DeleteStoreEventRequest id
                     */
    
                    /**
                     * Constructs a new DeleteStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteStoreEventRequest.
                     * @implements IDeleteStoreEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteStoreEventRequest=} [properties] Properties to set
                     */
                    function DeleteStoreEventRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteStoreEventRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.DeleteStoreEventRequest
                     * @instance
                     */
                    DeleteStoreEventRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteStoreEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStoreEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteStoreEventRequest} DeleteStoreEventRequest instance
                     */
                    DeleteStoreEventRequest.create = function create(properties) {
                        return new DeleteStoreEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteStoreEventRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteStoreEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStoreEventRequest} message DeleteStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStoreEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteStoreEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteStoreEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStoreEventRequest} message DeleteStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStoreEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteStoreEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteStoreEventRequest} DeleteStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStoreEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteStoreEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteStoreEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteStoreEventRequest} DeleteStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStoreEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteStoreEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteStoreEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteStoreEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteStoreEventRequest} DeleteStoreEventRequest
                     */
                    DeleteStoreEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteStoreEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteStoreEventRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteStoreEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteStoreEventRequest} message DeleteStoreEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteStoreEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteStoreEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteStoreEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteStoreEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteStoreEventRequest;
                })();
    
                v1.ListStoreEventRequest = (function() {
    
                    /**
                     * Properties of a ListStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreEventRequest
                     * @property {palexy.streaming.v1.ListStoreEventRequest.IStoreEventFilter|null} [storeEventFilter] ListStoreEventRequest storeEventFilter
                     * @property {number|null} [pageSize] ListStoreEventRequest pageSize
                     * @property {number|null} [pageIndex] ListStoreEventRequest pageIndex
                     */
    
                    /**
                     * Constructs a new ListStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreEventRequest.
                     * @implements IListStoreEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreEventRequest=} [properties] Properties to set
                     */
                    function ListStoreEventRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreEventRequest storeEventFilter.
                     * @member {palexy.streaming.v1.ListStoreEventRequest.IStoreEventFilter|null|undefined} storeEventFilter
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @instance
                     */
                    ListStoreEventRequest.prototype.storeEventFilter = null;
    
                    /**
                     * ListStoreEventRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @instance
                     */
                    ListStoreEventRequest.prototype.pageSize = 0;
    
                    /**
                     * ListStoreEventRequest pageIndex.
                     * @member {number} pageIndex
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @instance
                     */
                    ListStoreEventRequest.prototype.pageIndex = 0;
    
                    /**
                     * Creates a new ListStoreEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreEventRequest} ListStoreEventRequest instance
                     */
                    ListStoreEventRequest.create = function create(properties) {
                        return new ListStoreEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreEventRequest message. Does not implicitly {@link palexy.streaming.v1.ListStoreEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventRequest} message ListStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeEventFilter != null && Object.hasOwnProperty.call(message, "storeEventFilter"))
                            $root.palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter.encode(message.storeEventFilter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageSize);
                        if (message.pageIndex != null && Object.hasOwnProperty.call(message, "pageIndex"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageIndex);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventRequest} message ListStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreEventRequest} ListStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeEventFilter = $root.palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.pageSize = reader.int32();
                                break;
                            case 3:
                                message.pageIndex = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreEventRequest} ListStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeEventFilter != null && message.hasOwnProperty("storeEventFilter")) {
                            var error = $root.palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter.verify(message.storeEventFilter);
                            if (error)
                                return "storeEventFilter." + error;
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageIndex != null && message.hasOwnProperty("pageIndex"))
                            if (!$util.isInteger(message.pageIndex))
                                return "pageIndex: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreEventRequest} ListStoreEventRequest
                     */
                    ListStoreEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreEventRequest();
                        if (object.storeEventFilter != null) {
                            if (typeof object.storeEventFilter !== "object")
                                throw TypeError(".palexy.streaming.v1.ListStoreEventRequest.storeEventFilter: object expected");
                            message.storeEventFilter = $root.palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter.fromObject(object.storeEventFilter);
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageIndex != null)
                            message.pageIndex = object.pageIndex | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStoreEventRequest} message ListStoreEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.storeEventFilter = null;
                            object.pageSize = 0;
                            object.pageIndex = 0;
                        }
                        if (message.storeEventFilter != null && message.hasOwnProperty("storeEventFilter"))
                            object.storeEventFilter = $root.palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter.toObject(message.storeEventFilter, options);
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageIndex != null && message.hasOwnProperty("pageIndex"))
                            object.pageIndex = message.pageIndex;
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    ListStoreEventRequest.StoreEventFilter = (function() {
    
                        /**
                         * Properties of a StoreEventFilter.
                         * @memberof palexy.streaming.v1.ListStoreEventRequest
                         * @interface IStoreEventFilter
                         * @property {Array.<number>|null} [storeIds] StoreEventFilter storeIds
                         * @property {string|null} [startDate] StoreEventFilter startDate
                         * @property {string|null} [endDate] StoreEventFilter endDate
                         * @property {palexy.streaming.v1.StoreEventType|null} [type] StoreEventFilter type
                         * @property {Array.<palexy.streaming.v1.StoreEvent.StoreEventStatus>|null} [statuses] StoreEventFilter statuses
                         * @property {boolean|null} [isTestEvent] StoreEventFilter isTestEvent
                         * @property {Array.<palexy.streaming.v1.StoreEventType>|null} [types] StoreEventFilter types
                         */
    
                        /**
                         * Constructs a new StoreEventFilter.
                         * @memberof palexy.streaming.v1.ListStoreEventRequest
                         * @classdesc Represents a StoreEventFilter.
                         * @implements IStoreEventFilter
                         * @constructor
                         * @param {palexy.streaming.v1.ListStoreEventRequest.IStoreEventFilter=} [properties] Properties to set
                         */
                        function StoreEventFilter(properties) {
                            this.storeIds = [];
                            this.statuses = [];
                            this.types = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * StoreEventFilter storeIds.
                         * @member {Array.<number>} storeIds
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @instance
                         */
                        StoreEventFilter.prototype.storeIds = $util.emptyArray;
    
                        /**
                         * StoreEventFilter startDate.
                         * @member {string} startDate
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @instance
                         */
                        StoreEventFilter.prototype.startDate = "";
    
                        /**
                         * StoreEventFilter endDate.
                         * @member {string} endDate
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @instance
                         */
                        StoreEventFilter.prototype.endDate = "";
    
                        /**
                         * StoreEventFilter type.
                         * @member {palexy.streaming.v1.StoreEventType} type
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @instance
                         */
                        StoreEventFilter.prototype.type = 0;
    
                        /**
                         * StoreEventFilter statuses.
                         * @member {Array.<palexy.streaming.v1.StoreEvent.StoreEventStatus>} statuses
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @instance
                         */
                        StoreEventFilter.prototype.statuses = $util.emptyArray;
    
                        /**
                         * StoreEventFilter isTestEvent.
                         * @member {boolean} isTestEvent
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @instance
                         */
                        StoreEventFilter.prototype.isTestEvent = false;
    
                        /**
                         * StoreEventFilter types.
                         * @member {Array.<palexy.streaming.v1.StoreEventType>} types
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @instance
                         */
                        StoreEventFilter.prototype.types = $util.emptyArray;
    
                        /**
                         * Creates a new StoreEventFilter instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @static
                         * @param {palexy.streaming.v1.ListStoreEventRequest.IStoreEventFilter=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter} StoreEventFilter instance
                         */
                        StoreEventFilter.create = function create(properties) {
                            return new StoreEventFilter(properties);
                        };
    
                        /**
                         * Encodes the specified StoreEventFilter message. Does not implicitly {@link palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @static
                         * @param {palexy.streaming.v1.ListStoreEventRequest.IStoreEventFilter} message StoreEventFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StoreEventFilter.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeIds != null && message.storeIds.length) {
                                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                                for (var i = 0; i < message.storeIds.length; ++i)
                                    writer.int64(message.storeIds[i]);
                                writer.ldelim();
                            }
                            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.startDate);
                            if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.endDate);
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                            if (message.statuses != null && message.statuses.length) {
                                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                                for (var i = 0; i < message.statuses.length; ++i)
                                    writer.int32(message.statuses[i]);
                                writer.ldelim();
                            }
                            if (message.isTestEvent != null && Object.hasOwnProperty.call(message, "isTestEvent"))
                                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isTestEvent);
                            if (message.types != null && message.types.length) {
                                writer.uint32(/* id 7, wireType 2 =*/58).fork();
                                for (var i = 0; i < message.types.length; ++i)
                                    writer.int32(message.types[i]);
                                writer.ldelim();
                            }
                            return writer;
                        };
    
                        /**
                         * Encodes the specified StoreEventFilter message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @static
                         * @param {palexy.streaming.v1.ListStoreEventRequest.IStoreEventFilter} message StoreEventFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StoreEventFilter.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a StoreEventFilter message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter} StoreEventFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StoreEventFilter.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.storeIds && message.storeIds.length))
                                        message.storeIds = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.storeIds.push(reader.int64());
                                    } else
                                        message.storeIds.push(reader.int64());
                                    break;
                                case 2:
                                    message.startDate = reader.string();
                                    break;
                                case 3:
                                    message.endDate = reader.string();
                                    break;
                                case 4:
                                    message.type = reader.int32();
                                    break;
                                case 5:
                                    if (!(message.statuses && message.statuses.length))
                                        message.statuses = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.statuses.push(reader.int32());
                                    } else
                                        message.statuses.push(reader.int32());
                                    break;
                                case 6:
                                    message.isTestEvent = reader.bool();
                                    break;
                                case 7:
                                    if (!(message.types && message.types.length))
                                        message.types = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.types.push(reader.int32());
                                    } else
                                        message.types.push(reader.int32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a StoreEventFilter message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter} StoreEventFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StoreEventFilter.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a StoreEventFilter message.
                         * @function verify
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StoreEventFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                                if (!Array.isArray(message.storeIds))
                                    return "storeIds: array expected";
                                for (var i = 0; i < message.storeIds.length; ++i)
                                    if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                        return "storeIds: integer|Long[] expected";
                            }
                            if (message.startDate != null && message.hasOwnProperty("startDate"))
                                if (!$util.isString(message.startDate))
                                    return "startDate: string expected";
                            if (message.endDate != null && message.hasOwnProperty("endDate"))
                                if (!$util.isString(message.endDate))
                                    return "endDate: string expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                    break;
                                }
                            if (message.statuses != null && message.hasOwnProperty("statuses")) {
                                if (!Array.isArray(message.statuses))
                                    return "statuses: array expected";
                                for (var i = 0; i < message.statuses.length; ++i)
                                    switch (message.statuses[i]) {
                                    default:
                                        return "statuses: enum value[] expected";
                                    case 0:
                                    case 1:
                                    case 2:
                                    case 3:
                                        break;
                                    }
                            }
                            if (message.isTestEvent != null && message.hasOwnProperty("isTestEvent"))
                                if (typeof message.isTestEvent !== "boolean")
                                    return "isTestEvent: boolean expected";
                            if (message.types != null && message.hasOwnProperty("types")) {
                                if (!Array.isArray(message.types))
                                    return "types: array expected";
                                for (var i = 0; i < message.types.length; ++i)
                                    switch (message.types[i]) {
                                    default:
                                        return "types: enum value[] expected";
                                    case 0:
                                    case 1:
                                    case 2:
                                    case 3:
                                    case 4:
                                    case 5:
                                    case 6:
                                    case 7:
                                    case 8:
                                    case 9:
                                    case 10:
                                    case 11:
                                    case 12:
                                        break;
                                    }
                            }
                            return null;
                        };
    
                        /**
                         * Creates a StoreEventFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter} StoreEventFilter
                         */
                        StoreEventFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter)
                                return object;
                            var message = new $root.palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter();
                            if (object.storeIds) {
                                if (!Array.isArray(object.storeIds))
                                    throw TypeError(".palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter.storeIds: array expected");
                                message.storeIds = [];
                                for (var i = 0; i < object.storeIds.length; ++i)
                                    if ($util.Long)
                                        (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                    else if (typeof object.storeIds[i] === "string")
                                        message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                    else if (typeof object.storeIds[i] === "number")
                                        message.storeIds[i] = object.storeIds[i];
                                    else if (typeof object.storeIds[i] === "object")
                                        message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                            }
                            if (object.startDate != null)
                                message.startDate = String(object.startDate);
                            if (object.endDate != null)
                                message.endDate = String(object.endDate);
                            switch (object.type) {
                            case "UNKNOWN_STORE_EVENT_TYPE":
                            case 0:
                                message.type = 0;
                                break;
                            case "MISSED_CUSTOMER_INTERACTION":
                            case 1:
                                message.type = 1;
                                break;
                            case "SUSPICIOUS_CASHIER_ACTIVITY":
                            case 2:
                                message.type = 2;
                                break;
                            case "SOP_NON_COMPLIANCE":
                            case 3:
                                message.type = 3;
                                break;
                            case "STAFF_ATTENDANCE_NON_COMPLIANCE":
                            case 4:
                                message.type = 4;
                                break;
                            case "STAFF_GATHERING":
                            case 5:
                                message.type = 5;
                                break;
                            case "STORE_OPEN_CLOSE_TIME_NON_COMPLIANCE":
                            case 6:
                                message.type = 6;
                                break;
                            case "CUSTOMER_NEEDS_ASSISTANCE":
                            case 7:
                                message.type = 7;
                                break;
                            case "STAFF_NOT_WEAR_GLOVE":
                            case 8:
                                message.type = 8;
                                break;
                            case "NOT_GREETED_ON_TIME":
                            case 9:
                                message.type = 9;
                                break;
                            case "STAFF_MISSING":
                            case 10:
                                message.type = 10;
                                break;
                            case "STAFF_LEAVING_SPECIAL_AREA_WITHOUT_FOLLOWING_SOP":
                            case 11:
                                message.type = 11;
                                break;
                            case "HIGH_TRAFFIC_CHECKOUT":
                            case 12:
                                message.type = 12;
                                break;
                            }
                            if (object.statuses) {
                                if (!Array.isArray(object.statuses))
                                    throw TypeError(".palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter.statuses: array expected");
                                message.statuses = [];
                                for (var i = 0; i < object.statuses.length; ++i)
                                    switch (object.statuses[i]) {
                                    default:
                                    case "UNKNOWN":
                                    case 0:
                                        message.statuses[i] = 0;
                                        break;
                                    case "NOT_REVIEWED":
                                    case 1:
                                        message.statuses[i] = 1;
                                        break;
                                    case "APPROVED":
                                    case 2:
                                        message.statuses[i] = 2;
                                        break;
                                    case "REJECTED":
                                    case 3:
                                        message.statuses[i] = 3;
                                        break;
                                    }
                            }
                            if (object.isTestEvent != null)
                                message.isTestEvent = Boolean(object.isTestEvent);
                            if (object.types) {
                                if (!Array.isArray(object.types))
                                    throw TypeError(".palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter.types: array expected");
                                message.types = [];
                                for (var i = 0; i < object.types.length; ++i)
                                    switch (object.types[i]) {
                                    default:
                                    case "UNKNOWN_STORE_EVENT_TYPE":
                                    case 0:
                                        message.types[i] = 0;
                                        break;
                                    case "MISSED_CUSTOMER_INTERACTION":
                                    case 1:
                                        message.types[i] = 1;
                                        break;
                                    case "SUSPICIOUS_CASHIER_ACTIVITY":
                                    case 2:
                                        message.types[i] = 2;
                                        break;
                                    case "SOP_NON_COMPLIANCE":
                                    case 3:
                                        message.types[i] = 3;
                                        break;
                                    case "STAFF_ATTENDANCE_NON_COMPLIANCE":
                                    case 4:
                                        message.types[i] = 4;
                                        break;
                                    case "STAFF_GATHERING":
                                    case 5:
                                        message.types[i] = 5;
                                        break;
                                    case "STORE_OPEN_CLOSE_TIME_NON_COMPLIANCE":
                                    case 6:
                                        message.types[i] = 6;
                                        break;
                                    case "CUSTOMER_NEEDS_ASSISTANCE":
                                    case 7:
                                        message.types[i] = 7;
                                        break;
                                    case "STAFF_NOT_WEAR_GLOVE":
                                    case 8:
                                        message.types[i] = 8;
                                        break;
                                    case "NOT_GREETED_ON_TIME":
                                    case 9:
                                        message.types[i] = 9;
                                        break;
                                    case "STAFF_MISSING":
                                    case 10:
                                        message.types[i] = 10;
                                        break;
                                    case "STAFF_LEAVING_SPECIAL_AREA_WITHOUT_FOLLOWING_SOP":
                                    case 11:
                                        message.types[i] = 11;
                                        break;
                                    case "HIGH_TRAFFIC_CHECKOUT":
                                    case 12:
                                        message.types[i] = 12;
                                        break;
                                    }
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a StoreEventFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @static
                         * @param {palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter} message StoreEventFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StoreEventFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.storeIds = [];
                                object.statuses = [];
                                object.types = [];
                            }
                            if (options.defaults) {
                                object.startDate = "";
                                object.endDate = "";
                                object.type = options.enums === String ? "UNKNOWN_STORE_EVENT_TYPE" : 0;
                                object.isTestEvent = false;
                            }
                            if (message.storeIds && message.storeIds.length) {
                                object.storeIds = [];
                                for (var j = 0; j < message.storeIds.length; ++j)
                                    if (typeof message.storeIds[j] === "number")
                                        object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                    else
                                        object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                            }
                            if (message.startDate != null && message.hasOwnProperty("startDate"))
                                object.startDate = message.startDate;
                            if (message.endDate != null && message.hasOwnProperty("endDate"))
                                object.endDate = message.endDate;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.palexy.streaming.v1.StoreEventType[message.type] : message.type;
                            if (message.statuses && message.statuses.length) {
                                object.statuses = [];
                                for (var j = 0; j < message.statuses.length; ++j)
                                    object.statuses[j] = options.enums === String ? $root.palexy.streaming.v1.StoreEvent.StoreEventStatus[message.statuses[j]] : message.statuses[j];
                            }
                            if (message.isTestEvent != null && message.hasOwnProperty("isTestEvent"))
                                object.isTestEvent = message.isTestEvent;
                            if (message.types && message.types.length) {
                                object.types = [];
                                for (var j = 0; j < message.types.length; ++j)
                                    object.types[j] = options.enums === String ? $root.palexy.streaming.v1.StoreEventType[message.types[j]] : message.types[j];
                            }
                            return object;
                        };
    
                        /**
                         * Converts this StoreEventFilter to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.ListStoreEventRequest.StoreEventFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StoreEventFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return StoreEventFilter;
                    })();
    
                    return ListStoreEventRequest;
                })();
    
                v1.ListStoreEventResponse = (function() {
    
                    /**
                     * Properties of a ListStoreEventResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreEventResponse
                     * @property {Array.<palexy.streaming.v1.IStoreEvent>|null} [events] ListStoreEventResponse events
                     * @property {number|null} [pageSize] ListStoreEventResponse pageSize
                     * @property {number|null} [pageIndex] ListStoreEventResponse pageIndex
                     * @property {number|null} [totalElements] ListStoreEventResponse totalElements
                     */
    
                    /**
                     * Constructs a new ListStoreEventResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreEventResponse.
                     * @implements IListStoreEventResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreEventResponse=} [properties] Properties to set
                     */
                    function ListStoreEventResponse(properties) {
                        this.events = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreEventResponse events.
                     * @member {Array.<palexy.streaming.v1.IStoreEvent>} events
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @instance
                     */
                    ListStoreEventResponse.prototype.events = $util.emptyArray;
    
                    /**
                     * ListStoreEventResponse pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @instance
                     */
                    ListStoreEventResponse.prototype.pageSize = 0;
    
                    /**
                     * ListStoreEventResponse pageIndex.
                     * @member {number} pageIndex
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @instance
                     */
                    ListStoreEventResponse.prototype.pageIndex = 0;
    
                    /**
                     * ListStoreEventResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @instance
                     */
                    ListStoreEventResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListStoreEventResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreEventResponse} ListStoreEventResponse instance
                     */
                    ListStoreEventResponse.create = function create(properties) {
                        return new ListStoreEventResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreEventResponse message. Does not implicitly {@link palexy.streaming.v1.ListStoreEventResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventResponse} message ListStoreEventResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreEventResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.events != null && message.events.length)
                            for (var i = 0; i < message.events.length; ++i)
                                $root.palexy.streaming.v1.StoreEvent.encode(message.events[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageSize);
                        if (message.pageIndex != null && Object.hasOwnProperty.call(message, "pageIndex"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageIndex);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.totalElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreEventResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreEventResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreEventResponse} message ListStoreEventResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreEventResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreEventResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreEventResponse} ListStoreEventResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreEventResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreEventResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.events && message.events.length))
                                    message.events = [];
                                message.events.push($root.palexy.streaming.v1.StoreEvent.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.pageSize = reader.int32();
                                break;
                            case 3:
                                message.pageIndex = reader.int32();
                                break;
                            case 4:
                                message.totalElements = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreEventResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreEventResponse} ListStoreEventResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreEventResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreEventResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreEventResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.events != null && message.hasOwnProperty("events")) {
                            if (!Array.isArray(message.events))
                                return "events: array expected";
                            for (var i = 0; i < message.events.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreEvent.verify(message.events[i]);
                                if (error)
                                    return "events." + error;
                            }
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageIndex != null && message.hasOwnProperty("pageIndex"))
                            if (!$util.isInteger(message.pageIndex))
                                return "pageIndex: integer expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreEventResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreEventResponse} ListStoreEventResponse
                     */
                    ListStoreEventResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreEventResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreEventResponse();
                        if (object.events) {
                            if (!Array.isArray(object.events))
                                throw TypeError(".palexy.streaming.v1.ListStoreEventResponse.events: array expected");
                            message.events = [];
                            for (var i = 0; i < object.events.length; ++i) {
                                if (typeof object.events[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStoreEventResponse.events: object expected");
                                message.events[i] = $root.palexy.streaming.v1.StoreEvent.fromObject(object.events[i]);
                            }
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageIndex != null)
                            message.pageIndex = object.pageIndex | 0;
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreEventResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStoreEventResponse} message ListStoreEventResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreEventResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.events = [];
                        if (options.defaults) {
                            object.pageSize = 0;
                            object.pageIndex = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                        }
                        if (message.events && message.events.length) {
                            object.events = [];
                            for (var j = 0; j < message.events.length; ++j)
                                object.events[j] = $root.palexy.streaming.v1.StoreEvent.toObject(message.events[j], options);
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageIndex != null && message.hasOwnProperty("pageIndex"))
                            object.pageIndex = message.pageIndex;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreEventResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreEventResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreEventResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreEventResponse;
                })();
    
                /**
                 * StoreEventType enum.
                 * @name palexy.streaming.v1.StoreEventType
                 * @enum {number}
                 * @property {number} UNKNOWN_STORE_EVENT_TYPE=0 UNKNOWN_STORE_EVENT_TYPE value
                 * @property {number} MISSED_CUSTOMER_INTERACTION=1 MISSED_CUSTOMER_INTERACTION value
                 * @property {number} SUSPICIOUS_CASHIER_ACTIVITY=2 SUSPICIOUS_CASHIER_ACTIVITY value
                 * @property {number} SOP_NON_COMPLIANCE=3 SOP_NON_COMPLIANCE value
                 * @property {number} STAFF_ATTENDANCE_NON_COMPLIANCE=4 STAFF_ATTENDANCE_NON_COMPLIANCE value
                 * @property {number} STAFF_GATHERING=5 STAFF_GATHERING value
                 * @property {number} STORE_OPEN_CLOSE_TIME_NON_COMPLIANCE=6 STORE_OPEN_CLOSE_TIME_NON_COMPLIANCE value
                 * @property {number} CUSTOMER_NEEDS_ASSISTANCE=7 CUSTOMER_NEEDS_ASSISTANCE value
                 * @property {number} STAFF_NOT_WEAR_GLOVE=8 STAFF_NOT_WEAR_GLOVE value
                 * @property {number} NOT_GREETED_ON_TIME=9 NOT_GREETED_ON_TIME value
                 * @property {number} STAFF_MISSING=10 STAFF_MISSING value
                 * @property {number} STAFF_LEAVING_SPECIAL_AREA_WITHOUT_FOLLOWING_SOP=11 STAFF_LEAVING_SPECIAL_AREA_WITHOUT_FOLLOWING_SOP value
                 * @property {number} HIGH_TRAFFIC_CHECKOUT=12 HIGH_TRAFFIC_CHECKOUT value
                 */
                v1.StoreEventType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_STORE_EVENT_TYPE"] = 0;
                    values[valuesById[1] = "MISSED_CUSTOMER_INTERACTION"] = 1;
                    values[valuesById[2] = "SUSPICIOUS_CASHIER_ACTIVITY"] = 2;
                    values[valuesById[3] = "SOP_NON_COMPLIANCE"] = 3;
                    values[valuesById[4] = "STAFF_ATTENDANCE_NON_COMPLIANCE"] = 4;
                    values[valuesById[5] = "STAFF_GATHERING"] = 5;
                    values[valuesById[6] = "STORE_OPEN_CLOSE_TIME_NON_COMPLIANCE"] = 6;
                    values[valuesById[7] = "CUSTOMER_NEEDS_ASSISTANCE"] = 7;
                    values[valuesById[8] = "STAFF_NOT_WEAR_GLOVE"] = 8;
                    values[valuesById[9] = "NOT_GREETED_ON_TIME"] = 9;
                    values[valuesById[10] = "STAFF_MISSING"] = 10;
                    values[valuesById[11] = "STAFF_LEAVING_SPECIAL_AREA_WITHOUT_FOLLOWING_SOP"] = 11;
                    values[valuesById[12] = "HIGH_TRAFFIC_CHECKOUT"] = 12;
                    return values;
                })();
    
                v1.StoreEvent = (function() {
    
                    /**
                     * Properties of a StoreEvent.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreEvent
                     * @property {number|null} [id] StoreEvent id
                     * @property {palexy.streaming.v1.StoreEventType|null} [type] StoreEvent type
                     * @property {number|null} [storeId] StoreEvent storeId
                     * @property {string|null} [dateId] StoreEvent dateId
                     * @property {google.protobuf.ITimestamp|null} [startTime] StoreEvent startTime
                     * @property {number|null} [durationInSeconds] StoreEvent durationInSeconds
                     * @property {palexy.streaming.v1.IStoreEventDetails|null} [details] StoreEvent details
                     * @property {Array.<string>|null} [cameraIds] StoreEvent cameraIds
                     * @property {boolean|null} [isRealtime] StoreEvent isRealtime
                     * @property {palexy.streaming.v1.StoreEvent.StoreEventStatus|null} [status] StoreEvent status
                     * @property {string|null} [description] StoreEvent description
                     * @property {string|null} [latestReviewReason] StoreEvent latestReviewReason
                     * @property {boolean|null} [isTestEvent] StoreEvent isTestEvent
                     * @property {google.protobuf.ITimestamp|null} [createTime] StoreEvent createTime
                     * @property {google.protobuf.ITimestamp|null} [updateTime] StoreEvent updateTime
                     */
    
                    /**
                     * Constructs a new StoreEvent.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreEvent.
                     * @implements IStoreEvent
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreEvent=} [properties] Properties to set
                     */
                    function StoreEvent(properties) {
                        this.cameraIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreEvent id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreEvent type.
                     * @member {palexy.streaming.v1.StoreEventType} type
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.type = 0;
    
                    /**
                     * StoreEvent storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreEvent dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.dateId = "";
    
                    /**
                     * StoreEvent startTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} startTime
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.startTime = null;
    
                    /**
                     * StoreEvent durationInSeconds.
                     * @member {number} durationInSeconds
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.durationInSeconds = 0;
    
                    /**
                     * StoreEvent details.
                     * @member {palexy.streaming.v1.IStoreEventDetails|null|undefined} details
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.details = null;
    
                    /**
                     * StoreEvent cameraIds.
                     * @member {Array.<string>} cameraIds
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.cameraIds = $util.emptyArray;
    
                    /**
                     * StoreEvent isRealtime.
                     * @member {boolean} isRealtime
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.isRealtime = false;
    
                    /**
                     * StoreEvent status.
                     * @member {palexy.streaming.v1.StoreEvent.StoreEventStatus} status
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.status = 0;
    
                    /**
                     * StoreEvent description.
                     * @member {string} description
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.description = "";
    
                    /**
                     * StoreEvent latestReviewReason.
                     * @member {string} latestReviewReason
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.latestReviewReason = "";
    
                    /**
                     * StoreEvent isTestEvent.
                     * @member {boolean} isTestEvent
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.isTestEvent = false;
    
                    /**
                     * StoreEvent createTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} createTime
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.createTime = null;
    
                    /**
                     * StoreEvent updateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} updateTime
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     */
                    StoreEvent.prototype.updateTime = null;
    
                    /**
                     * Creates a new StoreEvent instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @static
                     * @param {palexy.streaming.v1.IStoreEvent=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreEvent} StoreEvent instance
                     */
                    StoreEvent.create = function create(properties) {
                        return new StoreEvent(properties);
                    };
    
                    /**
                     * Encodes the specified StoreEvent message. Does not implicitly {@link palexy.streaming.v1.StoreEvent.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @static
                     * @param {palexy.streaming.v1.IStoreEvent} message StoreEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.dateId);
                        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                            $root.google.protobuf.Timestamp.encode(message.startTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.durationInSeconds != null && Object.hasOwnProperty.call(message, "durationInSeconds"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.durationInSeconds);
                        if (message.details != null && Object.hasOwnProperty.call(message, "details"))
                            $root.palexy.streaming.v1.StoreEventDetails.encode(message.details, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.cameraIds != null && message.cameraIds.length)
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                writer.uint32(/* id 8, wireType 2 =*/66).string(message.cameraIds[i]);
                        if (message.isRealtime != null && Object.hasOwnProperty.call(message, "isRealtime"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isRealtime);
                        if (message.createTime != null && Object.hasOwnProperty.call(message, "createTime"))
                            $root.google.protobuf.Timestamp.encode(message.createTime, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.updateTime != null && Object.hasOwnProperty.call(message, "updateTime"))
                            $root.google.protobuf.Timestamp.encode(message.updateTime, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.status);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.description);
                        if (message.latestReviewReason != null && Object.hasOwnProperty.call(message, "latestReviewReason"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.latestReviewReason);
                        if (message.isTestEvent != null && Object.hasOwnProperty.call(message, "isTestEvent"))
                            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.isTestEvent);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreEvent message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @static
                     * @param {palexy.streaming.v1.IStoreEvent} message StoreEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreEvent} StoreEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.type = reader.int32();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 4:
                                message.dateId = reader.string();
                                break;
                            case 5:
                                message.startTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.durationInSeconds = reader.int32();
                                break;
                            case 7:
                                message.details = $root.palexy.streaming.v1.StoreEventDetails.decode(reader, reader.uint32());
                                break;
                            case 8:
                                if (!(message.cameraIds && message.cameraIds.length))
                                    message.cameraIds = [];
                                message.cameraIds.push(reader.string());
                                break;
                            case 9:
                                message.isRealtime = reader.bool();
                                break;
                            case 12:
                                message.status = reader.int32();
                                break;
                            case 13:
                                message.description = reader.string();
                                break;
                            case 14:
                                message.latestReviewReason = reader.string();
                                break;
                            case 15:
                                message.isTestEvent = reader.bool();
                                break;
                            case 10:
                                message.createTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.updateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreEvent} StoreEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreEvent message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                                break;
                            }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.startTime != null && message.hasOwnProperty("startTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.startTime);
                            if (error)
                                return "startTime." + error;
                        }
                        if (message.durationInSeconds != null && message.hasOwnProperty("durationInSeconds"))
                            if (!$util.isInteger(message.durationInSeconds))
                                return "durationInSeconds: integer expected";
                        if (message.details != null && message.hasOwnProperty("details")) {
                            var error = $root.palexy.streaming.v1.StoreEventDetails.verify(message.details);
                            if (error)
                                return "details." + error;
                        }
                        if (message.cameraIds != null && message.hasOwnProperty("cameraIds")) {
                            if (!Array.isArray(message.cameraIds))
                                return "cameraIds: array expected";
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                if (!$util.isString(message.cameraIds[i]))
                                    return "cameraIds: string[] expected";
                        }
                        if (message.isRealtime != null && message.hasOwnProperty("isRealtime"))
                            if (typeof message.isRealtime !== "boolean")
                                return "isRealtime: boolean expected";
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.latestReviewReason != null && message.hasOwnProperty("latestReviewReason"))
                            if (!$util.isString(message.latestReviewReason))
                                return "latestReviewReason: string expected";
                        if (message.isTestEvent != null && message.hasOwnProperty("isTestEvent"))
                            if (typeof message.isTestEvent !== "boolean")
                                return "isTestEvent: boolean expected";
                        if (message.createTime != null && message.hasOwnProperty("createTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createTime);
                            if (error)
                                return "createTime." + error;
                        }
                        if (message.updateTime != null && message.hasOwnProperty("updateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.updateTime);
                            if (error)
                                return "updateTime." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a StoreEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreEvent} StoreEvent
                     */
                    StoreEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreEvent)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreEvent();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        switch (object.type) {
                        case "UNKNOWN_STORE_EVENT_TYPE":
                        case 0:
                            message.type = 0;
                            break;
                        case "MISSED_CUSTOMER_INTERACTION":
                        case 1:
                            message.type = 1;
                            break;
                        case "SUSPICIOUS_CASHIER_ACTIVITY":
                        case 2:
                            message.type = 2;
                            break;
                        case "SOP_NON_COMPLIANCE":
                        case 3:
                            message.type = 3;
                            break;
                        case "STAFF_ATTENDANCE_NON_COMPLIANCE":
                        case 4:
                            message.type = 4;
                            break;
                        case "STAFF_GATHERING":
                        case 5:
                            message.type = 5;
                            break;
                        case "STORE_OPEN_CLOSE_TIME_NON_COMPLIANCE":
                        case 6:
                            message.type = 6;
                            break;
                        case "CUSTOMER_NEEDS_ASSISTANCE":
                        case 7:
                            message.type = 7;
                            break;
                        case "STAFF_NOT_WEAR_GLOVE":
                        case 8:
                            message.type = 8;
                            break;
                        case "NOT_GREETED_ON_TIME":
                        case 9:
                            message.type = 9;
                            break;
                        case "STAFF_MISSING":
                        case 10:
                            message.type = 10;
                            break;
                        case "STAFF_LEAVING_SPECIAL_AREA_WITHOUT_FOLLOWING_SOP":
                        case 11:
                            message.type = 11;
                            break;
                        case "HIGH_TRAFFIC_CHECKOUT":
                        case 12:
                            message.type = 12;
                            break;
                        }
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.startTime != null) {
                            if (typeof object.startTime !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEvent.startTime: object expected");
                            message.startTime = $root.google.protobuf.Timestamp.fromObject(object.startTime);
                        }
                        if (object.durationInSeconds != null)
                            message.durationInSeconds = object.durationInSeconds | 0;
                        if (object.details != null) {
                            if (typeof object.details !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEvent.details: object expected");
                            message.details = $root.palexy.streaming.v1.StoreEventDetails.fromObject(object.details);
                        }
                        if (object.cameraIds) {
                            if (!Array.isArray(object.cameraIds))
                                throw TypeError(".palexy.streaming.v1.StoreEvent.cameraIds: array expected");
                            message.cameraIds = [];
                            for (var i = 0; i < object.cameraIds.length; ++i)
                                message.cameraIds[i] = String(object.cameraIds[i]);
                        }
                        if (object.isRealtime != null)
                            message.isRealtime = Boolean(object.isRealtime);
                        switch (object.status) {
                        case "UNKNOWN":
                        case 0:
                            message.status = 0;
                            break;
                        case "NOT_REVIEWED":
                        case 1:
                            message.status = 1;
                            break;
                        case "APPROVED":
                        case 2:
                            message.status = 2;
                            break;
                        case "REJECTED":
                        case 3:
                            message.status = 3;
                            break;
                        }
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.latestReviewReason != null)
                            message.latestReviewReason = String(object.latestReviewReason);
                        if (object.isTestEvent != null)
                            message.isTestEvent = Boolean(object.isTestEvent);
                        if (object.createTime != null) {
                            if (typeof object.createTime !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEvent.createTime: object expected");
                            message.createTime = $root.google.protobuf.Timestamp.fromObject(object.createTime);
                        }
                        if (object.updateTime != null) {
                            if (typeof object.updateTime !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEvent.updateTime: object expected");
                            message.updateTime = $root.google.protobuf.Timestamp.fromObject(object.updateTime);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @static
                     * @param {palexy.streaming.v1.StoreEvent} message StoreEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.cameraIds = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.type = options.enums === String ? "UNKNOWN_STORE_EVENT_TYPE" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.startTime = null;
                            object.durationInSeconds = 0;
                            object.details = null;
                            object.isRealtime = false;
                            object.createTime = null;
                            object.updateTime = null;
                            object.status = options.enums === String ? "UNKNOWN" : 0;
                            object.description = "";
                            object.latestReviewReason = "";
                            object.isTestEvent = false;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.StoreEventType[message.type] : message.type;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            object.startTime = $root.google.protobuf.Timestamp.toObject(message.startTime, options);
                        if (message.durationInSeconds != null && message.hasOwnProperty("durationInSeconds"))
                            object.durationInSeconds = message.durationInSeconds;
                        if (message.details != null && message.hasOwnProperty("details"))
                            object.details = $root.palexy.streaming.v1.StoreEventDetails.toObject(message.details, options);
                        if (message.cameraIds && message.cameraIds.length) {
                            object.cameraIds = [];
                            for (var j = 0; j < message.cameraIds.length; ++j)
                                object.cameraIds[j] = message.cameraIds[j];
                        }
                        if (message.isRealtime != null && message.hasOwnProperty("isRealtime"))
                            object.isRealtime = message.isRealtime;
                        if (message.createTime != null && message.hasOwnProperty("createTime"))
                            object.createTime = $root.google.protobuf.Timestamp.toObject(message.createTime, options);
                        if (message.updateTime != null && message.hasOwnProperty("updateTime"))
                            object.updateTime = $root.google.protobuf.Timestamp.toObject(message.updateTime, options);
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.palexy.streaming.v1.StoreEvent.StoreEventStatus[message.status] : message.status;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.latestReviewReason != null && message.hasOwnProperty("latestReviewReason"))
                            object.latestReviewReason = message.latestReviewReason;
                        if (message.isTestEvent != null && message.hasOwnProperty("isTestEvent"))
                            object.isTestEvent = message.isTestEvent;
                        return object;
                    };
    
                    /**
                     * Converts this StoreEvent to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * StoreEventStatus enum.
                     * @name palexy.streaming.v1.StoreEvent.StoreEventStatus
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} NOT_REVIEWED=1 NOT_REVIEWED value
                     * @property {number} APPROVED=2 APPROVED value
                     * @property {number} REJECTED=3 REJECTED value
                     */
                    StoreEvent.StoreEventStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "NOT_REVIEWED"] = 1;
                        values[valuesById[2] = "APPROVED"] = 2;
                        values[valuesById[3] = "REJECTED"] = 3;
                        return values;
                    })();
    
                    return StoreEvent;
                })();
    
                v1.CriticalMoment = (function() {
    
                    /**
                     * Properties of a CriticalMoment.
                     * @memberof palexy.streaming.v1
                     * @interface ICriticalMoment
                     * @property {string|null} [label] CriticalMoment label
                     * @property {number|null} [momentInSecond] CriticalMoment momentInSecond
                     */
    
                    /**
                     * Constructs a new CriticalMoment.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CriticalMoment.
                     * @implements ICriticalMoment
                     * @constructor
                     * @param {palexy.streaming.v1.ICriticalMoment=} [properties] Properties to set
                     */
                    function CriticalMoment(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CriticalMoment label.
                     * @member {string} label
                     * @memberof palexy.streaming.v1.CriticalMoment
                     * @instance
                     */
                    CriticalMoment.prototype.label = "";
    
                    /**
                     * CriticalMoment momentInSecond.
                     * @member {number} momentInSecond
                     * @memberof palexy.streaming.v1.CriticalMoment
                     * @instance
                     */
                    CriticalMoment.prototype.momentInSecond = 0;
    
                    /**
                     * Creates a new CriticalMoment instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CriticalMoment
                     * @static
                     * @param {palexy.streaming.v1.ICriticalMoment=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CriticalMoment} CriticalMoment instance
                     */
                    CriticalMoment.create = function create(properties) {
                        return new CriticalMoment(properties);
                    };
    
                    /**
                     * Encodes the specified CriticalMoment message. Does not implicitly {@link palexy.streaming.v1.CriticalMoment.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CriticalMoment
                     * @static
                     * @param {palexy.streaming.v1.ICriticalMoment} message CriticalMoment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CriticalMoment.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                        if (message.momentInSecond != null && Object.hasOwnProperty.call(message, "momentInSecond"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.momentInSecond);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CriticalMoment message, length delimited. Does not implicitly {@link palexy.streaming.v1.CriticalMoment.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CriticalMoment
                     * @static
                     * @param {palexy.streaming.v1.ICriticalMoment} message CriticalMoment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CriticalMoment.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CriticalMoment message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CriticalMoment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CriticalMoment} CriticalMoment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CriticalMoment.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CriticalMoment();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.label = reader.string();
                                break;
                            case 2:
                                message.momentInSecond = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CriticalMoment message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CriticalMoment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CriticalMoment} CriticalMoment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CriticalMoment.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CriticalMoment message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CriticalMoment
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CriticalMoment.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.label != null && message.hasOwnProperty("label"))
                            if (!$util.isString(message.label))
                                return "label: string expected";
                        if (message.momentInSecond != null && message.hasOwnProperty("momentInSecond"))
                            if (!$util.isInteger(message.momentInSecond))
                                return "momentInSecond: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a CriticalMoment message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CriticalMoment
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CriticalMoment} CriticalMoment
                     */
                    CriticalMoment.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CriticalMoment)
                            return object;
                        var message = new $root.palexy.streaming.v1.CriticalMoment();
                        if (object.label != null)
                            message.label = String(object.label);
                        if (object.momentInSecond != null)
                            message.momentInSecond = object.momentInSecond | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CriticalMoment message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CriticalMoment
                     * @static
                     * @param {palexy.streaming.v1.CriticalMoment} message CriticalMoment
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CriticalMoment.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.label = "";
                            object.momentInSecond = 0;
                        }
                        if (message.label != null && message.hasOwnProperty("label"))
                            object.label = message.label;
                        if (message.momentInSecond != null && message.hasOwnProperty("momentInSecond"))
                            object.momentInSecond = message.momentInSecond;
                        return object;
                    };
    
                    /**
                     * Converts this CriticalMoment to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CriticalMoment
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CriticalMoment.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CriticalMoment;
                })();
    
                v1.StoreEventReviewLog = (function() {
    
                    /**
                     * Properties of a StoreEventReviewLog.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreEventReviewLog
                     * @property {number|null} [id] StoreEventReviewLog id
                     * @property {number|null} [reviewerId] StoreEventReviewLog reviewerId
                     * @property {string|null} [reviewerAccountName] StoreEventReviewLog reviewerAccountName
                     * @property {number|null} [storeEventId] StoreEventReviewLog storeEventId
                     * @property {number|null} [reviewTimeMs] StoreEventReviewLog reviewTimeMs
                     * @property {palexy.streaming.v1.StoreEventReviewLog.ReviewStatus|null} [storeEventStatus] StoreEventReviewLog storeEventStatus
                     * @property {string|null} [reason] StoreEventReviewLog reason
                     */
    
                    /**
                     * Constructs a new StoreEventReviewLog.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreEventReviewLog.
                     * @implements IStoreEventReviewLog
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreEventReviewLog=} [properties] Properties to set
                     */
                    function StoreEventReviewLog(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreEventReviewLog id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @instance
                     */
                    StoreEventReviewLog.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreEventReviewLog reviewerId.
                     * @member {number} reviewerId
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @instance
                     */
                    StoreEventReviewLog.prototype.reviewerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreEventReviewLog reviewerAccountName.
                     * @member {string} reviewerAccountName
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @instance
                     */
                    StoreEventReviewLog.prototype.reviewerAccountName = "";
    
                    /**
                     * StoreEventReviewLog storeEventId.
                     * @member {number} storeEventId
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @instance
                     */
                    StoreEventReviewLog.prototype.storeEventId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreEventReviewLog reviewTimeMs.
                     * @member {number} reviewTimeMs
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @instance
                     */
                    StoreEventReviewLog.prototype.reviewTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreEventReviewLog storeEventStatus.
                     * @member {palexy.streaming.v1.StoreEventReviewLog.ReviewStatus} storeEventStatus
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @instance
                     */
                    StoreEventReviewLog.prototype.storeEventStatus = 0;
    
                    /**
                     * StoreEventReviewLog reason.
                     * @member {string} reason
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @instance
                     */
                    StoreEventReviewLog.prototype.reason = "";
    
                    /**
                     * Creates a new StoreEventReviewLog instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @static
                     * @param {palexy.streaming.v1.IStoreEventReviewLog=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreEventReviewLog} StoreEventReviewLog instance
                     */
                    StoreEventReviewLog.create = function create(properties) {
                        return new StoreEventReviewLog(properties);
                    };
    
                    /**
                     * Encodes the specified StoreEventReviewLog message. Does not implicitly {@link palexy.streaming.v1.StoreEventReviewLog.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @static
                     * @param {palexy.streaming.v1.IStoreEventReviewLog} message StoreEventReviewLog message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreEventReviewLog.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.reviewerId != null && Object.hasOwnProperty.call(message, "reviewerId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.reviewerId);
                        if (message.reviewerAccountName != null && Object.hasOwnProperty.call(message, "reviewerAccountName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.reviewerAccountName);
                        if (message.storeEventId != null && Object.hasOwnProperty.call(message, "storeEventId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.storeEventId);
                        if (message.reviewTimeMs != null && Object.hasOwnProperty.call(message, "reviewTimeMs"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.reviewTimeMs);
                        if (message.storeEventStatus != null && Object.hasOwnProperty.call(message, "storeEventStatus"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.storeEventStatus);
                        if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.reason);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreEventReviewLog message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreEventReviewLog.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @static
                     * @param {palexy.streaming.v1.IStoreEventReviewLog} message StoreEventReviewLog message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreEventReviewLog.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreEventReviewLog message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreEventReviewLog} StoreEventReviewLog
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreEventReviewLog.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreEventReviewLog();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.reviewerId = reader.int64();
                                break;
                            case 3:
                                message.reviewerAccountName = reader.string();
                                break;
                            case 4:
                                message.storeEventId = reader.int64();
                                break;
                            case 5:
                                message.reviewTimeMs = reader.int64();
                                break;
                            case 6:
                                message.storeEventStatus = reader.int32();
                                break;
                            case 7:
                                message.reason = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreEventReviewLog message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreEventReviewLog} StoreEventReviewLog
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreEventReviewLog.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreEventReviewLog message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreEventReviewLog.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.reviewerId != null && message.hasOwnProperty("reviewerId"))
                            if (!$util.isInteger(message.reviewerId) && !(message.reviewerId && $util.isInteger(message.reviewerId.low) && $util.isInteger(message.reviewerId.high)))
                                return "reviewerId: integer|Long expected";
                        if (message.reviewerAccountName != null && message.hasOwnProperty("reviewerAccountName"))
                            if (!$util.isString(message.reviewerAccountName))
                                return "reviewerAccountName: string expected";
                        if (message.storeEventId != null && message.hasOwnProperty("storeEventId"))
                            if (!$util.isInteger(message.storeEventId) && !(message.storeEventId && $util.isInteger(message.storeEventId.low) && $util.isInteger(message.storeEventId.high)))
                                return "storeEventId: integer|Long expected";
                        if (message.reviewTimeMs != null && message.hasOwnProperty("reviewTimeMs"))
                            if (!$util.isInteger(message.reviewTimeMs) && !(message.reviewTimeMs && $util.isInteger(message.reviewTimeMs.low) && $util.isInteger(message.reviewTimeMs.high)))
                                return "reviewTimeMs: integer|Long expected";
                        if (message.storeEventStatus != null && message.hasOwnProperty("storeEventStatus"))
                            switch (message.storeEventStatus) {
                            default:
                                return "storeEventStatus: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.reason != null && message.hasOwnProperty("reason"))
                            if (!$util.isString(message.reason))
                                return "reason: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a StoreEventReviewLog message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreEventReviewLog} StoreEventReviewLog
                     */
                    StoreEventReviewLog.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreEventReviewLog)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreEventReviewLog();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.reviewerId != null)
                            if ($util.Long)
                                (message.reviewerId = $util.Long.fromValue(object.reviewerId)).unsigned = false;
                            else if (typeof object.reviewerId === "string")
                                message.reviewerId = parseInt(object.reviewerId, 10);
                            else if (typeof object.reviewerId === "number")
                                message.reviewerId = object.reviewerId;
                            else if (typeof object.reviewerId === "object")
                                message.reviewerId = new $util.LongBits(object.reviewerId.low >>> 0, object.reviewerId.high >>> 0).toNumber();
                        if (object.reviewerAccountName != null)
                            message.reviewerAccountName = String(object.reviewerAccountName);
                        if (object.storeEventId != null)
                            if ($util.Long)
                                (message.storeEventId = $util.Long.fromValue(object.storeEventId)).unsigned = false;
                            else if (typeof object.storeEventId === "string")
                                message.storeEventId = parseInt(object.storeEventId, 10);
                            else if (typeof object.storeEventId === "number")
                                message.storeEventId = object.storeEventId;
                            else if (typeof object.storeEventId === "object")
                                message.storeEventId = new $util.LongBits(object.storeEventId.low >>> 0, object.storeEventId.high >>> 0).toNumber();
                        if (object.reviewTimeMs != null)
                            if ($util.Long)
                                (message.reviewTimeMs = $util.Long.fromValue(object.reviewTimeMs)).unsigned = false;
                            else if (typeof object.reviewTimeMs === "string")
                                message.reviewTimeMs = parseInt(object.reviewTimeMs, 10);
                            else if (typeof object.reviewTimeMs === "number")
                                message.reviewTimeMs = object.reviewTimeMs;
                            else if (typeof object.reviewTimeMs === "object")
                                message.reviewTimeMs = new $util.LongBits(object.reviewTimeMs.low >>> 0, object.reviewTimeMs.high >>> 0).toNumber();
                        switch (object.storeEventStatus) {
                        case "UNKNOWN":
                        case 0:
                            message.storeEventStatus = 0;
                            break;
                        case "APPROVED":
                        case 1:
                            message.storeEventStatus = 1;
                            break;
                        case "REJECTED":
                        case 2:
                            message.storeEventStatus = 2;
                            break;
                        case "COMMENTED":
                        case 3:
                            message.storeEventStatus = 3;
                            break;
                        }
                        if (object.reason != null)
                            message.reason = String(object.reason);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreEventReviewLog message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @static
                     * @param {palexy.streaming.v1.StoreEventReviewLog} message StoreEventReviewLog
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreEventReviewLog.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.reviewerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.reviewerId = options.longs === String ? "0" : 0;
                            object.reviewerAccountName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeEventId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeEventId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.reviewTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.reviewTimeMs = options.longs === String ? "0" : 0;
                            object.storeEventStatus = options.enums === String ? "UNKNOWN" : 0;
                            object.reason = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.reviewerId != null && message.hasOwnProperty("reviewerId"))
                            if (typeof message.reviewerId === "number")
                                object.reviewerId = options.longs === String ? String(message.reviewerId) : message.reviewerId;
                            else
                                object.reviewerId = options.longs === String ? $util.Long.prototype.toString.call(message.reviewerId) : options.longs === Number ? new $util.LongBits(message.reviewerId.low >>> 0, message.reviewerId.high >>> 0).toNumber() : message.reviewerId;
                        if (message.reviewerAccountName != null && message.hasOwnProperty("reviewerAccountName"))
                            object.reviewerAccountName = message.reviewerAccountName;
                        if (message.storeEventId != null && message.hasOwnProperty("storeEventId"))
                            if (typeof message.storeEventId === "number")
                                object.storeEventId = options.longs === String ? String(message.storeEventId) : message.storeEventId;
                            else
                                object.storeEventId = options.longs === String ? $util.Long.prototype.toString.call(message.storeEventId) : options.longs === Number ? new $util.LongBits(message.storeEventId.low >>> 0, message.storeEventId.high >>> 0).toNumber() : message.storeEventId;
                        if (message.reviewTimeMs != null && message.hasOwnProperty("reviewTimeMs"))
                            if (typeof message.reviewTimeMs === "number")
                                object.reviewTimeMs = options.longs === String ? String(message.reviewTimeMs) : message.reviewTimeMs;
                            else
                                object.reviewTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.reviewTimeMs) : options.longs === Number ? new $util.LongBits(message.reviewTimeMs.low >>> 0, message.reviewTimeMs.high >>> 0).toNumber() : message.reviewTimeMs;
                        if (message.storeEventStatus != null && message.hasOwnProperty("storeEventStatus"))
                            object.storeEventStatus = options.enums === String ? $root.palexy.streaming.v1.StoreEventReviewLog.ReviewStatus[message.storeEventStatus] : message.storeEventStatus;
                        if (message.reason != null && message.hasOwnProperty("reason"))
                            object.reason = message.reason;
                        return object;
                    };
    
                    /**
                     * Converts this StoreEventReviewLog to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreEventReviewLog
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreEventReviewLog.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * ReviewStatus enum.
                     * @name palexy.streaming.v1.StoreEventReviewLog.ReviewStatus
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} APPROVED=1 APPROVED value
                     * @property {number} REJECTED=2 REJECTED value
                     * @property {number} COMMENTED=3 COMMENTED value
                     */
                    StoreEventReviewLog.ReviewStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "APPROVED"] = 1;
                        values[valuesById[2] = "REJECTED"] = 2;
                        values[valuesById[3] = "COMMENTED"] = 3;
                        return values;
                    })();
    
                    return StoreEventReviewLog;
                })();
    
                v1.ReviewStoreEventRequest = (function() {
    
                    /**
                     * Properties of a ReviewStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IReviewStoreEventRequest
                     * @property {number|null} [eventId] ReviewStoreEventRequest eventId
                     * @property {number|null} [reviewerId] ReviewStoreEventRequest reviewerId
                     * @property {string|null} [reason] ReviewStoreEventRequest reason
                     * @property {palexy.streaming.v1.StoreEventReviewLog.ReviewStatus|null} [storeEventStatus] ReviewStoreEventRequest storeEventStatus
                     */
    
                    /**
                     * Constructs a new ReviewStoreEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ReviewStoreEventRequest.
                     * @implements IReviewStoreEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IReviewStoreEventRequest=} [properties] Properties to set
                     */
                    function ReviewStoreEventRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReviewStoreEventRequest eventId.
                     * @member {number} eventId
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @instance
                     */
                    ReviewStoreEventRequest.prototype.eventId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ReviewStoreEventRequest reviewerId.
                     * @member {number} reviewerId
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @instance
                     */
                    ReviewStoreEventRequest.prototype.reviewerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ReviewStoreEventRequest reason.
                     * @member {string} reason
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @instance
                     */
                    ReviewStoreEventRequest.prototype.reason = "";
    
                    /**
                     * ReviewStoreEventRequest storeEventStatus.
                     * @member {palexy.streaming.v1.StoreEventReviewLog.ReviewStatus} storeEventStatus
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @instance
                     */
                    ReviewStoreEventRequest.prototype.storeEventStatus = 0;
    
                    /**
                     * Creates a new ReviewStoreEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IReviewStoreEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ReviewStoreEventRequest} ReviewStoreEventRequest instance
                     */
                    ReviewStoreEventRequest.create = function create(properties) {
                        return new ReviewStoreEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ReviewStoreEventRequest message. Does not implicitly {@link palexy.streaming.v1.ReviewStoreEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IReviewStoreEventRequest} message ReviewStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReviewStoreEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.eventId != null && Object.hasOwnProperty.call(message, "eventId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.eventId);
                        if (message.reviewerId != null && Object.hasOwnProperty.call(message, "reviewerId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.reviewerId);
                        if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.reason);
                        if (message.storeEventStatus != null && Object.hasOwnProperty.call(message, "storeEventStatus"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.storeEventStatus);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReviewStoreEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ReviewStoreEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IReviewStoreEventRequest} message ReviewStoreEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReviewStoreEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReviewStoreEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ReviewStoreEventRequest} ReviewStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReviewStoreEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ReviewStoreEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.eventId = reader.int64();
                                break;
                            case 2:
                                message.reviewerId = reader.int64();
                                break;
                            case 4:
                                message.reason = reader.string();
                                break;
                            case 5:
                                message.storeEventStatus = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReviewStoreEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ReviewStoreEventRequest} ReviewStoreEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReviewStoreEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReviewStoreEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReviewStoreEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.eventId != null && message.hasOwnProperty("eventId"))
                            if (!$util.isInteger(message.eventId) && !(message.eventId && $util.isInteger(message.eventId.low) && $util.isInteger(message.eventId.high)))
                                return "eventId: integer|Long expected";
                        if (message.reviewerId != null && message.hasOwnProperty("reviewerId"))
                            if (!$util.isInteger(message.reviewerId) && !(message.reviewerId && $util.isInteger(message.reviewerId.low) && $util.isInteger(message.reviewerId.high)))
                                return "reviewerId: integer|Long expected";
                        if (message.reason != null && message.hasOwnProperty("reason"))
                            if (!$util.isString(message.reason))
                                return "reason: string expected";
                        if (message.storeEventStatus != null && message.hasOwnProperty("storeEventStatus"))
                            switch (message.storeEventStatus) {
                            default:
                                return "storeEventStatus: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a ReviewStoreEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ReviewStoreEventRequest} ReviewStoreEventRequest
                     */
                    ReviewStoreEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ReviewStoreEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ReviewStoreEventRequest();
                        if (object.eventId != null)
                            if ($util.Long)
                                (message.eventId = $util.Long.fromValue(object.eventId)).unsigned = false;
                            else if (typeof object.eventId === "string")
                                message.eventId = parseInt(object.eventId, 10);
                            else if (typeof object.eventId === "number")
                                message.eventId = object.eventId;
                            else if (typeof object.eventId === "object")
                                message.eventId = new $util.LongBits(object.eventId.low >>> 0, object.eventId.high >>> 0).toNumber();
                        if (object.reviewerId != null)
                            if ($util.Long)
                                (message.reviewerId = $util.Long.fromValue(object.reviewerId)).unsigned = false;
                            else if (typeof object.reviewerId === "string")
                                message.reviewerId = parseInt(object.reviewerId, 10);
                            else if (typeof object.reviewerId === "number")
                                message.reviewerId = object.reviewerId;
                            else if (typeof object.reviewerId === "object")
                                message.reviewerId = new $util.LongBits(object.reviewerId.low >>> 0, object.reviewerId.high >>> 0).toNumber();
                        if (object.reason != null)
                            message.reason = String(object.reason);
                        switch (object.storeEventStatus) {
                        case "UNKNOWN":
                        case 0:
                            message.storeEventStatus = 0;
                            break;
                        case "APPROVED":
                        case 1:
                            message.storeEventStatus = 1;
                            break;
                        case "REJECTED":
                        case 2:
                            message.storeEventStatus = 2;
                            break;
                        case "COMMENTED":
                        case 3:
                            message.storeEventStatus = 3;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReviewStoreEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ReviewStoreEventRequest} message ReviewStoreEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReviewStoreEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.eventId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.eventId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.reviewerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.reviewerId = options.longs === String ? "0" : 0;
                            object.reason = "";
                            object.storeEventStatus = options.enums === String ? "UNKNOWN" : 0;
                        }
                        if (message.eventId != null && message.hasOwnProperty("eventId"))
                            if (typeof message.eventId === "number")
                                object.eventId = options.longs === String ? String(message.eventId) : message.eventId;
                            else
                                object.eventId = options.longs === String ? $util.Long.prototype.toString.call(message.eventId) : options.longs === Number ? new $util.LongBits(message.eventId.low >>> 0, message.eventId.high >>> 0).toNumber() : message.eventId;
                        if (message.reviewerId != null && message.hasOwnProperty("reviewerId"))
                            if (typeof message.reviewerId === "number")
                                object.reviewerId = options.longs === String ? String(message.reviewerId) : message.reviewerId;
                            else
                                object.reviewerId = options.longs === String ? $util.Long.prototype.toString.call(message.reviewerId) : options.longs === Number ? new $util.LongBits(message.reviewerId.low >>> 0, message.reviewerId.high >>> 0).toNumber() : message.reviewerId;
                        if (message.reason != null && message.hasOwnProperty("reason"))
                            object.reason = message.reason;
                        if (message.storeEventStatus != null && message.hasOwnProperty("storeEventStatus"))
                            object.storeEventStatus = options.enums === String ? $root.palexy.streaming.v1.StoreEventReviewLog.ReviewStatus[message.storeEventStatus] : message.storeEventStatus;
                        return object;
                    };
    
                    /**
                     * Converts this ReviewStoreEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ReviewStoreEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReviewStoreEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ReviewStoreEventRequest;
                })();
    
                v1.StoreEventDetails = (function() {
    
                    /**
                     * Properties of a StoreEventDetails.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreEventDetails
                     * @property {palexy.streaming.v1.IMissedCustomerInteractionDetails|null} [missedCustomerInteractionDetails] StoreEventDetails missedCustomerInteractionDetails
                     * @property {palexy.streaming.v1.ISuspiciousCashierActivityDetails|null} [suspiciousCashierActivityDetails] StoreEventDetails suspiciousCashierActivityDetails
                     * @property {palexy.streaming.v1.ISopNonComplianceActivityDetails|null} [sopNonComplianceActivityDetails] StoreEventDetails sopNonComplianceActivityDetails
                     * @property {palexy.streaming.v1.IStaffGatheringDetails|null} [staffGatheringDetails] StoreEventDetails staffGatheringDetails
                     * @property {palexy.streaming.v1.IStaffAttendanceNonComplianceDetails|null} [staffAttendanceNonComplianceDetails] StoreEventDetails staffAttendanceNonComplianceDetails
                     * @property {palexy.streaming.v1.IStoreOpenCloseTimeNonComplianceDetails|null} [storeOpenCloseTimeNonComplianceDetails] StoreEventDetails storeOpenCloseTimeNonComplianceDetails
                     * @property {palexy.streaming.v1.IStaffLeavingSpecialAreaWoFollowingSopDetails|null} [staffLeavingSpecialAreaWoFollowingSopDetails] StoreEventDetails staffLeavingSpecialAreaWoFollowingSopDetails
                     * @property {palexy.streaming.v1.IArtifact|null} [thumbnailImage] StoreEventDetails thumbnailImage
                     * @property {Array.<palexy.streaming.v1.IArtifact>|null} [artifacts] StoreEventDetails artifacts
                     * @property {Array.<palexy.streaming.v1.ICriticalMoment>|null} [criticalMoments] StoreEventDetails criticalMoments
                     */
    
                    /**
                     * Constructs a new StoreEventDetails.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreEventDetails.
                     * @implements IStoreEventDetails
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreEventDetails=} [properties] Properties to set
                     */
                    function StoreEventDetails(properties) {
                        this.artifacts = [];
                        this.criticalMoments = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreEventDetails missedCustomerInteractionDetails.
                     * @member {palexy.streaming.v1.IMissedCustomerInteractionDetails|null|undefined} missedCustomerInteractionDetails
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     */
                    StoreEventDetails.prototype.missedCustomerInteractionDetails = null;
    
                    /**
                     * StoreEventDetails suspiciousCashierActivityDetails.
                     * @member {palexy.streaming.v1.ISuspiciousCashierActivityDetails|null|undefined} suspiciousCashierActivityDetails
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     */
                    StoreEventDetails.prototype.suspiciousCashierActivityDetails = null;
    
                    /**
                     * StoreEventDetails sopNonComplianceActivityDetails.
                     * @member {palexy.streaming.v1.ISopNonComplianceActivityDetails|null|undefined} sopNonComplianceActivityDetails
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     */
                    StoreEventDetails.prototype.sopNonComplianceActivityDetails = null;
    
                    /**
                     * StoreEventDetails staffGatheringDetails.
                     * @member {palexy.streaming.v1.IStaffGatheringDetails|null|undefined} staffGatheringDetails
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     */
                    StoreEventDetails.prototype.staffGatheringDetails = null;
    
                    /**
                     * StoreEventDetails staffAttendanceNonComplianceDetails.
                     * @member {palexy.streaming.v1.IStaffAttendanceNonComplianceDetails|null|undefined} staffAttendanceNonComplianceDetails
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     */
                    StoreEventDetails.prototype.staffAttendanceNonComplianceDetails = null;
    
                    /**
                     * StoreEventDetails storeOpenCloseTimeNonComplianceDetails.
                     * @member {palexy.streaming.v1.IStoreOpenCloseTimeNonComplianceDetails|null|undefined} storeOpenCloseTimeNonComplianceDetails
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     */
                    StoreEventDetails.prototype.storeOpenCloseTimeNonComplianceDetails = null;
    
                    /**
                     * StoreEventDetails staffLeavingSpecialAreaWoFollowingSopDetails.
                     * @member {palexy.streaming.v1.IStaffLeavingSpecialAreaWoFollowingSopDetails|null|undefined} staffLeavingSpecialAreaWoFollowingSopDetails
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     */
                    StoreEventDetails.prototype.staffLeavingSpecialAreaWoFollowingSopDetails = null;
    
                    /**
                     * StoreEventDetails thumbnailImage.
                     * @member {palexy.streaming.v1.IArtifact|null|undefined} thumbnailImage
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     */
                    StoreEventDetails.prototype.thumbnailImage = null;
    
                    /**
                     * StoreEventDetails artifacts.
                     * @member {Array.<palexy.streaming.v1.IArtifact>} artifacts
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     */
                    StoreEventDetails.prototype.artifacts = $util.emptyArray;
    
                    /**
                     * StoreEventDetails criticalMoments.
                     * @member {Array.<palexy.streaming.v1.ICriticalMoment>} criticalMoments
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     */
                    StoreEventDetails.prototype.criticalMoments = $util.emptyArray;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * StoreEventDetails details.
                     * @member {"missedCustomerInteractionDetails"|"suspiciousCashierActivityDetails"|"sopNonComplianceActivityDetails"|"staffGatheringDetails"|"staffAttendanceNonComplianceDetails"|"storeOpenCloseTimeNonComplianceDetails"|"staffLeavingSpecialAreaWoFollowingSopDetails"|undefined} details
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     */
                    Object.defineProperty(StoreEventDetails.prototype, "details", {
                        get: $util.oneOfGetter($oneOfFields = ["missedCustomerInteractionDetails", "suspiciousCashierActivityDetails", "sopNonComplianceActivityDetails", "staffGatheringDetails", "staffAttendanceNonComplianceDetails", "storeOpenCloseTimeNonComplianceDetails", "staffLeavingSpecialAreaWoFollowingSopDetails"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new StoreEventDetails instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @static
                     * @param {palexy.streaming.v1.IStoreEventDetails=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreEventDetails} StoreEventDetails instance
                     */
                    StoreEventDetails.create = function create(properties) {
                        return new StoreEventDetails(properties);
                    };
    
                    /**
                     * Encodes the specified StoreEventDetails message. Does not implicitly {@link palexy.streaming.v1.StoreEventDetails.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @static
                     * @param {palexy.streaming.v1.IStoreEventDetails} message StoreEventDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreEventDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.missedCustomerInteractionDetails != null && Object.hasOwnProperty.call(message, "missedCustomerInteractionDetails"))
                            $root.palexy.streaming.v1.MissedCustomerInteractionDetails.encode(message.missedCustomerInteractionDetails, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.thumbnailImage != null && Object.hasOwnProperty.call(message, "thumbnailImage"))
                            $root.palexy.streaming.v1.Artifact.encode(message.thumbnailImage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.suspiciousCashierActivityDetails != null && Object.hasOwnProperty.call(message, "suspiciousCashierActivityDetails"))
                            $root.palexy.streaming.v1.SuspiciousCashierActivityDetails.encode(message.suspiciousCashierActivityDetails, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.sopNonComplianceActivityDetails != null && Object.hasOwnProperty.call(message, "sopNonComplianceActivityDetails"))
                            $root.palexy.streaming.v1.SopNonComplianceActivityDetails.encode(message.sopNonComplianceActivityDetails, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.staffGatheringDetails != null && Object.hasOwnProperty.call(message, "staffGatheringDetails"))
                            $root.palexy.streaming.v1.StaffGatheringDetails.encode(message.staffGatheringDetails, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.staffAttendanceNonComplianceDetails != null && Object.hasOwnProperty.call(message, "staffAttendanceNonComplianceDetails"))
                            $root.palexy.streaming.v1.StaffAttendanceNonComplianceDetails.encode(message.staffAttendanceNonComplianceDetails, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.storeOpenCloseTimeNonComplianceDetails != null && Object.hasOwnProperty.call(message, "storeOpenCloseTimeNonComplianceDetails"))
                            $root.palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails.encode(message.storeOpenCloseTimeNonComplianceDetails, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.artifacts != null && message.artifacts.length)
                            for (var i = 0; i < message.artifacts.length; ++i)
                                $root.palexy.streaming.v1.Artifact.encode(message.artifacts[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.criticalMoments != null && message.criticalMoments.length)
                            for (var i = 0; i < message.criticalMoments.length; ++i)
                                $root.palexy.streaming.v1.CriticalMoment.encode(message.criticalMoments[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.staffLeavingSpecialAreaWoFollowingSopDetails != null && Object.hasOwnProperty.call(message, "staffLeavingSpecialAreaWoFollowingSopDetails"))
                            $root.palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.encode(message.staffLeavingSpecialAreaWoFollowingSopDetails, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreEventDetails message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreEventDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @static
                     * @param {palexy.streaming.v1.IStoreEventDetails} message StoreEventDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreEventDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreEventDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreEventDetails} StoreEventDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreEventDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreEventDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.missedCustomerInteractionDetails = $root.palexy.streaming.v1.MissedCustomerInteractionDetails.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.suspiciousCashierActivityDetails = $root.palexy.streaming.v1.SuspiciousCashierActivityDetails.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.sopNonComplianceActivityDetails = $root.palexy.streaming.v1.SopNonComplianceActivityDetails.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.staffGatheringDetails = $root.palexy.streaming.v1.StaffGatheringDetails.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.staffAttendanceNonComplianceDetails = $root.palexy.streaming.v1.StaffAttendanceNonComplianceDetails.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.storeOpenCloseTimeNonComplianceDetails = $root.palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.staffLeavingSpecialAreaWoFollowingSopDetails = $root.palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.thumbnailImage = $root.palexy.streaming.v1.Artifact.decode(reader, reader.uint32());
                                break;
                            case 8:
                                if (!(message.artifacts && message.artifacts.length))
                                    message.artifacts = [];
                                message.artifacts.push($root.palexy.streaming.v1.Artifact.decode(reader, reader.uint32()));
                                break;
                            case 9:
                                if (!(message.criticalMoments && message.criticalMoments.length))
                                    message.criticalMoments = [];
                                message.criticalMoments.push($root.palexy.streaming.v1.CriticalMoment.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreEventDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreEventDetails} StoreEventDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreEventDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreEventDetails message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreEventDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.missedCustomerInteractionDetails != null && message.hasOwnProperty("missedCustomerInteractionDetails")) {
                            properties.details = 1;
                            {
                                var error = $root.palexy.streaming.v1.MissedCustomerInteractionDetails.verify(message.missedCustomerInteractionDetails);
                                if (error)
                                    return "missedCustomerInteractionDetails." + error;
                            }
                        }
                        if (message.suspiciousCashierActivityDetails != null && message.hasOwnProperty("suspiciousCashierActivityDetails")) {
                            if (properties.details === 1)
                                return "details: multiple values";
                            properties.details = 1;
                            {
                                var error = $root.palexy.streaming.v1.SuspiciousCashierActivityDetails.verify(message.suspiciousCashierActivityDetails);
                                if (error)
                                    return "suspiciousCashierActivityDetails." + error;
                            }
                        }
                        if (message.sopNonComplianceActivityDetails != null && message.hasOwnProperty("sopNonComplianceActivityDetails")) {
                            if (properties.details === 1)
                                return "details: multiple values";
                            properties.details = 1;
                            {
                                var error = $root.palexy.streaming.v1.SopNonComplianceActivityDetails.verify(message.sopNonComplianceActivityDetails);
                                if (error)
                                    return "sopNonComplianceActivityDetails." + error;
                            }
                        }
                        if (message.staffGatheringDetails != null && message.hasOwnProperty("staffGatheringDetails")) {
                            if (properties.details === 1)
                                return "details: multiple values";
                            properties.details = 1;
                            {
                                var error = $root.palexy.streaming.v1.StaffGatheringDetails.verify(message.staffGatheringDetails);
                                if (error)
                                    return "staffGatheringDetails." + error;
                            }
                        }
                        if (message.staffAttendanceNonComplianceDetails != null && message.hasOwnProperty("staffAttendanceNonComplianceDetails")) {
                            if (properties.details === 1)
                                return "details: multiple values";
                            properties.details = 1;
                            {
                                var error = $root.palexy.streaming.v1.StaffAttendanceNonComplianceDetails.verify(message.staffAttendanceNonComplianceDetails);
                                if (error)
                                    return "staffAttendanceNonComplianceDetails." + error;
                            }
                        }
                        if (message.storeOpenCloseTimeNonComplianceDetails != null && message.hasOwnProperty("storeOpenCloseTimeNonComplianceDetails")) {
                            if (properties.details === 1)
                                return "details: multiple values";
                            properties.details = 1;
                            {
                                var error = $root.palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails.verify(message.storeOpenCloseTimeNonComplianceDetails);
                                if (error)
                                    return "storeOpenCloseTimeNonComplianceDetails." + error;
                            }
                        }
                        if (message.staffLeavingSpecialAreaWoFollowingSopDetails != null && message.hasOwnProperty("staffLeavingSpecialAreaWoFollowingSopDetails")) {
                            if (properties.details === 1)
                                return "details: multiple values";
                            properties.details = 1;
                            {
                                var error = $root.palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.verify(message.staffLeavingSpecialAreaWoFollowingSopDetails);
                                if (error)
                                    return "staffLeavingSpecialAreaWoFollowingSopDetails." + error;
                            }
                        }
                        if (message.thumbnailImage != null && message.hasOwnProperty("thumbnailImage")) {
                            var error = $root.palexy.streaming.v1.Artifact.verify(message.thumbnailImage);
                            if (error)
                                return "thumbnailImage." + error;
                        }
                        if (message.artifacts != null && message.hasOwnProperty("artifacts")) {
                            if (!Array.isArray(message.artifacts))
                                return "artifacts: array expected";
                            for (var i = 0; i < message.artifacts.length; ++i) {
                                var error = $root.palexy.streaming.v1.Artifact.verify(message.artifacts[i]);
                                if (error)
                                    return "artifacts." + error;
                            }
                        }
                        if (message.criticalMoments != null && message.hasOwnProperty("criticalMoments")) {
                            if (!Array.isArray(message.criticalMoments))
                                return "criticalMoments: array expected";
                            for (var i = 0; i < message.criticalMoments.length; ++i) {
                                var error = $root.palexy.streaming.v1.CriticalMoment.verify(message.criticalMoments[i]);
                                if (error)
                                    return "criticalMoments." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a StoreEventDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreEventDetails} StoreEventDetails
                     */
                    StoreEventDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreEventDetails)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreEventDetails();
                        if (object.missedCustomerInteractionDetails != null) {
                            if (typeof object.missedCustomerInteractionDetails !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEventDetails.missedCustomerInteractionDetails: object expected");
                            message.missedCustomerInteractionDetails = $root.palexy.streaming.v1.MissedCustomerInteractionDetails.fromObject(object.missedCustomerInteractionDetails);
                        }
                        if (object.suspiciousCashierActivityDetails != null) {
                            if (typeof object.suspiciousCashierActivityDetails !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEventDetails.suspiciousCashierActivityDetails: object expected");
                            message.suspiciousCashierActivityDetails = $root.palexy.streaming.v1.SuspiciousCashierActivityDetails.fromObject(object.suspiciousCashierActivityDetails);
                        }
                        if (object.sopNonComplianceActivityDetails != null) {
                            if (typeof object.sopNonComplianceActivityDetails !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEventDetails.sopNonComplianceActivityDetails: object expected");
                            message.sopNonComplianceActivityDetails = $root.palexy.streaming.v1.SopNonComplianceActivityDetails.fromObject(object.sopNonComplianceActivityDetails);
                        }
                        if (object.staffGatheringDetails != null) {
                            if (typeof object.staffGatheringDetails !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEventDetails.staffGatheringDetails: object expected");
                            message.staffGatheringDetails = $root.palexy.streaming.v1.StaffGatheringDetails.fromObject(object.staffGatheringDetails);
                        }
                        if (object.staffAttendanceNonComplianceDetails != null) {
                            if (typeof object.staffAttendanceNonComplianceDetails !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEventDetails.staffAttendanceNonComplianceDetails: object expected");
                            message.staffAttendanceNonComplianceDetails = $root.palexy.streaming.v1.StaffAttendanceNonComplianceDetails.fromObject(object.staffAttendanceNonComplianceDetails);
                        }
                        if (object.storeOpenCloseTimeNonComplianceDetails != null) {
                            if (typeof object.storeOpenCloseTimeNonComplianceDetails !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEventDetails.storeOpenCloseTimeNonComplianceDetails: object expected");
                            message.storeOpenCloseTimeNonComplianceDetails = $root.palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails.fromObject(object.storeOpenCloseTimeNonComplianceDetails);
                        }
                        if (object.staffLeavingSpecialAreaWoFollowingSopDetails != null) {
                            if (typeof object.staffLeavingSpecialAreaWoFollowingSopDetails !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEventDetails.staffLeavingSpecialAreaWoFollowingSopDetails: object expected");
                            message.staffLeavingSpecialAreaWoFollowingSopDetails = $root.palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.fromObject(object.staffLeavingSpecialAreaWoFollowingSopDetails);
                        }
                        if (object.thumbnailImage != null) {
                            if (typeof object.thumbnailImage !== "object")
                                throw TypeError(".palexy.streaming.v1.StoreEventDetails.thumbnailImage: object expected");
                            message.thumbnailImage = $root.palexy.streaming.v1.Artifact.fromObject(object.thumbnailImage);
                        }
                        if (object.artifacts) {
                            if (!Array.isArray(object.artifacts))
                                throw TypeError(".palexy.streaming.v1.StoreEventDetails.artifacts: array expected");
                            message.artifacts = [];
                            for (var i = 0; i < object.artifacts.length; ++i) {
                                if (typeof object.artifacts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.StoreEventDetails.artifacts: object expected");
                                message.artifacts[i] = $root.palexy.streaming.v1.Artifact.fromObject(object.artifacts[i]);
                            }
                        }
                        if (object.criticalMoments) {
                            if (!Array.isArray(object.criticalMoments))
                                throw TypeError(".palexy.streaming.v1.StoreEventDetails.criticalMoments: array expected");
                            message.criticalMoments = [];
                            for (var i = 0; i < object.criticalMoments.length; ++i) {
                                if (typeof object.criticalMoments[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.StoreEventDetails.criticalMoments: object expected");
                                message.criticalMoments[i] = $root.palexy.streaming.v1.CriticalMoment.fromObject(object.criticalMoments[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreEventDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @static
                     * @param {palexy.streaming.v1.StoreEventDetails} message StoreEventDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreEventDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.artifacts = [];
                            object.criticalMoments = [];
                        }
                        if (options.defaults)
                            object.thumbnailImage = null;
                        if (message.missedCustomerInteractionDetails != null && message.hasOwnProperty("missedCustomerInteractionDetails")) {
                            object.missedCustomerInteractionDetails = $root.palexy.streaming.v1.MissedCustomerInteractionDetails.toObject(message.missedCustomerInteractionDetails, options);
                            if (options.oneofs)
                                object.details = "missedCustomerInteractionDetails";
                        }
                        if (message.thumbnailImage != null && message.hasOwnProperty("thumbnailImage"))
                            object.thumbnailImage = $root.palexy.streaming.v1.Artifact.toObject(message.thumbnailImage, options);
                        if (message.suspiciousCashierActivityDetails != null && message.hasOwnProperty("suspiciousCashierActivityDetails")) {
                            object.suspiciousCashierActivityDetails = $root.palexy.streaming.v1.SuspiciousCashierActivityDetails.toObject(message.suspiciousCashierActivityDetails, options);
                            if (options.oneofs)
                                object.details = "suspiciousCashierActivityDetails";
                        }
                        if (message.sopNonComplianceActivityDetails != null && message.hasOwnProperty("sopNonComplianceActivityDetails")) {
                            object.sopNonComplianceActivityDetails = $root.palexy.streaming.v1.SopNonComplianceActivityDetails.toObject(message.sopNonComplianceActivityDetails, options);
                            if (options.oneofs)
                                object.details = "sopNonComplianceActivityDetails";
                        }
                        if (message.staffGatheringDetails != null && message.hasOwnProperty("staffGatheringDetails")) {
                            object.staffGatheringDetails = $root.palexy.streaming.v1.StaffGatheringDetails.toObject(message.staffGatheringDetails, options);
                            if (options.oneofs)
                                object.details = "staffGatheringDetails";
                        }
                        if (message.staffAttendanceNonComplianceDetails != null && message.hasOwnProperty("staffAttendanceNonComplianceDetails")) {
                            object.staffAttendanceNonComplianceDetails = $root.palexy.streaming.v1.StaffAttendanceNonComplianceDetails.toObject(message.staffAttendanceNonComplianceDetails, options);
                            if (options.oneofs)
                                object.details = "staffAttendanceNonComplianceDetails";
                        }
                        if (message.storeOpenCloseTimeNonComplianceDetails != null && message.hasOwnProperty("storeOpenCloseTimeNonComplianceDetails")) {
                            object.storeOpenCloseTimeNonComplianceDetails = $root.palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails.toObject(message.storeOpenCloseTimeNonComplianceDetails, options);
                            if (options.oneofs)
                                object.details = "storeOpenCloseTimeNonComplianceDetails";
                        }
                        if (message.artifacts && message.artifacts.length) {
                            object.artifacts = [];
                            for (var j = 0; j < message.artifacts.length; ++j)
                                object.artifacts[j] = $root.palexy.streaming.v1.Artifact.toObject(message.artifacts[j], options);
                        }
                        if (message.criticalMoments && message.criticalMoments.length) {
                            object.criticalMoments = [];
                            for (var j = 0; j < message.criticalMoments.length; ++j)
                                object.criticalMoments[j] = $root.palexy.streaming.v1.CriticalMoment.toObject(message.criticalMoments[j], options);
                        }
                        if (message.staffLeavingSpecialAreaWoFollowingSopDetails != null && message.hasOwnProperty("staffLeavingSpecialAreaWoFollowingSopDetails")) {
                            object.staffLeavingSpecialAreaWoFollowingSopDetails = $root.palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.toObject(message.staffLeavingSpecialAreaWoFollowingSopDetails, options);
                            if (options.oneofs)
                                object.details = "staffLeavingSpecialAreaWoFollowingSopDetails";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this StoreEventDetails to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreEventDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreEventDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StoreEventDetails;
                })();
    
                v1.StoreOpenCloseTimeNonComplianceDetails = (function() {
    
                    /**
                     * Properties of a StoreOpenCloseTimeNonComplianceDetails.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreOpenCloseTimeNonComplianceDetails
                     * @property {Array.<palexy.streaming.v1.IArtifact>|null} [artifacts] StoreOpenCloseTimeNonComplianceDetails artifacts
                     * @property {boolean|null} [isLateOpen] StoreOpenCloseTimeNonComplianceDetails isLateOpen
                     */
    
                    /**
                     * Constructs a new StoreOpenCloseTimeNonComplianceDetails.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreOpenCloseTimeNonComplianceDetails.
                     * @implements IStoreOpenCloseTimeNonComplianceDetails
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreOpenCloseTimeNonComplianceDetails=} [properties] Properties to set
                     */
                    function StoreOpenCloseTimeNonComplianceDetails(properties) {
                        this.artifacts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreOpenCloseTimeNonComplianceDetails artifacts.
                     * @member {Array.<palexy.streaming.v1.IArtifact>} artifacts
                     * @memberof palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails
                     * @instance
                     */
                    StoreOpenCloseTimeNonComplianceDetails.prototype.artifacts = $util.emptyArray;
    
                    /**
                     * StoreOpenCloseTimeNonComplianceDetails isLateOpen.
                     * @member {boolean} isLateOpen
                     * @memberof palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails
                     * @instance
                     */
                    StoreOpenCloseTimeNonComplianceDetails.prototype.isLateOpen = false;
    
                    /**
                     * Creates a new StoreOpenCloseTimeNonComplianceDetails instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails
                     * @static
                     * @param {palexy.streaming.v1.IStoreOpenCloseTimeNonComplianceDetails=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails} StoreOpenCloseTimeNonComplianceDetails instance
                     */
                    StoreOpenCloseTimeNonComplianceDetails.create = function create(properties) {
                        return new StoreOpenCloseTimeNonComplianceDetails(properties);
                    };
    
                    /**
                     * Encodes the specified StoreOpenCloseTimeNonComplianceDetails message. Does not implicitly {@link palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails
                     * @static
                     * @param {palexy.streaming.v1.IStoreOpenCloseTimeNonComplianceDetails} message StoreOpenCloseTimeNonComplianceDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreOpenCloseTimeNonComplianceDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.artifacts != null && message.artifacts.length)
                            for (var i = 0; i < message.artifacts.length; ++i)
                                $root.palexy.streaming.v1.Artifact.encode(message.artifacts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.isLateOpen != null && Object.hasOwnProperty.call(message, "isLateOpen"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isLateOpen);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreOpenCloseTimeNonComplianceDetails message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails
                     * @static
                     * @param {palexy.streaming.v1.IStoreOpenCloseTimeNonComplianceDetails} message StoreOpenCloseTimeNonComplianceDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreOpenCloseTimeNonComplianceDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreOpenCloseTimeNonComplianceDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails} StoreOpenCloseTimeNonComplianceDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreOpenCloseTimeNonComplianceDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.artifacts && message.artifacts.length))
                                    message.artifacts = [];
                                message.artifacts.push($root.palexy.streaming.v1.Artifact.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.isLateOpen = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreOpenCloseTimeNonComplianceDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails} StoreOpenCloseTimeNonComplianceDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreOpenCloseTimeNonComplianceDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreOpenCloseTimeNonComplianceDetails message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreOpenCloseTimeNonComplianceDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.artifacts != null && message.hasOwnProperty("artifacts")) {
                            if (!Array.isArray(message.artifacts))
                                return "artifacts: array expected";
                            for (var i = 0; i < message.artifacts.length; ++i) {
                                var error = $root.palexy.streaming.v1.Artifact.verify(message.artifacts[i]);
                                if (error)
                                    return "artifacts." + error;
                            }
                        }
                        if (message.isLateOpen != null && message.hasOwnProperty("isLateOpen"))
                            if (typeof message.isLateOpen !== "boolean")
                                return "isLateOpen: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a StoreOpenCloseTimeNonComplianceDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails} StoreOpenCloseTimeNonComplianceDetails
                     */
                    StoreOpenCloseTimeNonComplianceDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails();
                        if (object.artifacts) {
                            if (!Array.isArray(object.artifacts))
                                throw TypeError(".palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails.artifacts: array expected");
                            message.artifacts = [];
                            for (var i = 0; i < object.artifacts.length; ++i) {
                                if (typeof object.artifacts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails.artifacts: object expected");
                                message.artifacts[i] = $root.palexy.streaming.v1.Artifact.fromObject(object.artifacts[i]);
                            }
                        }
                        if (object.isLateOpen != null)
                            message.isLateOpen = Boolean(object.isLateOpen);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreOpenCloseTimeNonComplianceDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails
                     * @static
                     * @param {palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails} message StoreOpenCloseTimeNonComplianceDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreOpenCloseTimeNonComplianceDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.artifacts = [];
                        if (options.defaults)
                            object.isLateOpen = false;
                        if (message.artifacts && message.artifacts.length) {
                            object.artifacts = [];
                            for (var j = 0; j < message.artifacts.length; ++j)
                                object.artifacts[j] = $root.palexy.streaming.v1.Artifact.toObject(message.artifacts[j], options);
                        }
                        if (message.isLateOpen != null && message.hasOwnProperty("isLateOpen"))
                            object.isLateOpen = message.isLateOpen;
                        return object;
                    };
    
                    /**
                     * Converts this StoreOpenCloseTimeNonComplianceDetails to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreOpenCloseTimeNonComplianceDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreOpenCloseTimeNonComplianceDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StoreOpenCloseTimeNonComplianceDetails;
                })();
    
                v1.MissedCustomerInteractionDetails = (function() {
    
                    /**
                     * Properties of a MissedCustomerInteractionDetails.
                     * @memberof palexy.streaming.v1
                     * @interface IMissedCustomerInteractionDetails
                     * @property {Array.<palexy.streaming.v1.IArtifact>|null} [artifacts] MissedCustomerInteractionDetails artifacts
                     */
    
                    /**
                     * Constructs a new MissedCustomerInteractionDetails.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MissedCustomerInteractionDetails.
                     * @implements IMissedCustomerInteractionDetails
                     * @constructor
                     * @param {palexy.streaming.v1.IMissedCustomerInteractionDetails=} [properties] Properties to set
                     */
                    function MissedCustomerInteractionDetails(properties) {
                        this.artifacts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MissedCustomerInteractionDetails artifacts.
                     * @member {Array.<palexy.streaming.v1.IArtifact>} artifacts
                     * @memberof palexy.streaming.v1.MissedCustomerInteractionDetails
                     * @instance
                     */
                    MissedCustomerInteractionDetails.prototype.artifacts = $util.emptyArray;
    
                    /**
                     * Creates a new MissedCustomerInteractionDetails instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.MissedCustomerInteractionDetails
                     * @static
                     * @param {palexy.streaming.v1.IMissedCustomerInteractionDetails=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.MissedCustomerInteractionDetails} MissedCustomerInteractionDetails instance
                     */
                    MissedCustomerInteractionDetails.create = function create(properties) {
                        return new MissedCustomerInteractionDetails(properties);
                    };
    
                    /**
                     * Encodes the specified MissedCustomerInteractionDetails message. Does not implicitly {@link palexy.streaming.v1.MissedCustomerInteractionDetails.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.MissedCustomerInteractionDetails
                     * @static
                     * @param {palexy.streaming.v1.IMissedCustomerInteractionDetails} message MissedCustomerInteractionDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MissedCustomerInteractionDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.artifacts != null && message.artifacts.length)
                            for (var i = 0; i < message.artifacts.length; ++i)
                                $root.palexy.streaming.v1.Artifact.encode(message.artifacts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MissedCustomerInteractionDetails message, length delimited. Does not implicitly {@link palexy.streaming.v1.MissedCustomerInteractionDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.MissedCustomerInteractionDetails
                     * @static
                     * @param {palexy.streaming.v1.IMissedCustomerInteractionDetails} message MissedCustomerInteractionDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MissedCustomerInteractionDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MissedCustomerInteractionDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.MissedCustomerInteractionDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.MissedCustomerInteractionDetails} MissedCustomerInteractionDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MissedCustomerInteractionDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.MissedCustomerInteractionDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.artifacts && message.artifacts.length))
                                    message.artifacts = [];
                                message.artifacts.push($root.palexy.streaming.v1.Artifact.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MissedCustomerInteractionDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.MissedCustomerInteractionDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.MissedCustomerInteractionDetails} MissedCustomerInteractionDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MissedCustomerInteractionDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MissedCustomerInteractionDetails message.
                     * @function verify
                     * @memberof palexy.streaming.v1.MissedCustomerInteractionDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MissedCustomerInteractionDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.artifacts != null && message.hasOwnProperty("artifacts")) {
                            if (!Array.isArray(message.artifacts))
                                return "artifacts: array expected";
                            for (var i = 0; i < message.artifacts.length; ++i) {
                                var error = $root.palexy.streaming.v1.Artifact.verify(message.artifacts[i]);
                                if (error)
                                    return "artifacts." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a MissedCustomerInteractionDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.MissedCustomerInteractionDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.MissedCustomerInteractionDetails} MissedCustomerInteractionDetails
                     */
                    MissedCustomerInteractionDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.MissedCustomerInteractionDetails)
                            return object;
                        var message = new $root.palexy.streaming.v1.MissedCustomerInteractionDetails();
                        if (object.artifacts) {
                            if (!Array.isArray(object.artifacts))
                                throw TypeError(".palexy.streaming.v1.MissedCustomerInteractionDetails.artifacts: array expected");
                            message.artifacts = [];
                            for (var i = 0; i < object.artifacts.length; ++i) {
                                if (typeof object.artifacts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.MissedCustomerInteractionDetails.artifacts: object expected");
                                message.artifacts[i] = $root.palexy.streaming.v1.Artifact.fromObject(object.artifacts[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MissedCustomerInteractionDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.MissedCustomerInteractionDetails
                     * @static
                     * @param {palexy.streaming.v1.MissedCustomerInteractionDetails} message MissedCustomerInteractionDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MissedCustomerInteractionDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.artifacts = [];
                        if (message.artifacts && message.artifacts.length) {
                            object.artifacts = [];
                            for (var j = 0; j < message.artifacts.length; ++j)
                                object.artifacts[j] = $root.palexy.streaming.v1.Artifact.toObject(message.artifacts[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this MissedCustomerInteractionDetails to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.MissedCustomerInteractionDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MissedCustomerInteractionDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return MissedCustomerInteractionDetails;
                })();
    
                v1.StaffGatheringDetails = (function() {
    
                    /**
                     * Properties of a StaffGatheringDetails.
                     * @memberof palexy.streaming.v1
                     * @interface IStaffGatheringDetails
                     * @property {Array.<palexy.streaming.v1.IArtifact>|null} [artifacts] StaffGatheringDetails artifacts
                     */
    
                    /**
                     * Constructs a new StaffGatheringDetails.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffGatheringDetails.
                     * @implements IStaffGatheringDetails
                     * @constructor
                     * @param {palexy.streaming.v1.IStaffGatheringDetails=} [properties] Properties to set
                     */
                    function StaffGatheringDetails(properties) {
                        this.artifacts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StaffGatheringDetails artifacts.
                     * @member {Array.<palexy.streaming.v1.IArtifact>} artifacts
                     * @memberof palexy.streaming.v1.StaffGatheringDetails
                     * @instance
                     */
                    StaffGatheringDetails.prototype.artifacts = $util.emptyArray;
    
                    /**
                     * Creates a new StaffGatheringDetails instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffGatheringDetails
                     * @static
                     * @param {palexy.streaming.v1.IStaffGatheringDetails=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StaffGatheringDetails} StaffGatheringDetails instance
                     */
                    StaffGatheringDetails.create = function create(properties) {
                        return new StaffGatheringDetails(properties);
                    };
    
                    /**
                     * Encodes the specified StaffGatheringDetails message. Does not implicitly {@link palexy.streaming.v1.StaffGatheringDetails.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StaffGatheringDetails
                     * @static
                     * @param {palexy.streaming.v1.IStaffGatheringDetails} message StaffGatheringDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffGatheringDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.artifacts != null && message.artifacts.length)
                            for (var i = 0; i < message.artifacts.length; ++i)
                                $root.palexy.streaming.v1.Artifact.encode(message.artifacts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StaffGatheringDetails message, length delimited. Does not implicitly {@link palexy.streaming.v1.StaffGatheringDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StaffGatheringDetails
                     * @static
                     * @param {palexy.streaming.v1.IStaffGatheringDetails} message StaffGatheringDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffGatheringDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StaffGatheringDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StaffGatheringDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StaffGatheringDetails} StaffGatheringDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffGatheringDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StaffGatheringDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.artifacts && message.artifacts.length))
                                    message.artifacts = [];
                                message.artifacts.push($root.palexy.streaming.v1.Artifact.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StaffGatheringDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StaffGatheringDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StaffGatheringDetails} StaffGatheringDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffGatheringDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StaffGatheringDetails message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StaffGatheringDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StaffGatheringDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.artifacts != null && message.hasOwnProperty("artifacts")) {
                            if (!Array.isArray(message.artifacts))
                                return "artifacts: array expected";
                            for (var i = 0; i < message.artifacts.length; ++i) {
                                var error = $root.palexy.streaming.v1.Artifact.verify(message.artifacts[i]);
                                if (error)
                                    return "artifacts." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a StaffGatheringDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StaffGatheringDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StaffGatheringDetails} StaffGatheringDetails
                     */
                    StaffGatheringDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StaffGatheringDetails)
                            return object;
                        var message = new $root.palexy.streaming.v1.StaffGatheringDetails();
                        if (object.artifacts) {
                            if (!Array.isArray(object.artifacts))
                                throw TypeError(".palexy.streaming.v1.StaffGatheringDetails.artifacts: array expected");
                            message.artifacts = [];
                            for (var i = 0; i < object.artifacts.length; ++i) {
                                if (typeof object.artifacts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.StaffGatheringDetails.artifacts: object expected");
                                message.artifacts[i] = $root.palexy.streaming.v1.Artifact.fromObject(object.artifacts[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StaffGatheringDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StaffGatheringDetails
                     * @static
                     * @param {palexy.streaming.v1.StaffGatheringDetails} message StaffGatheringDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StaffGatheringDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.artifacts = [];
                        if (message.artifacts && message.artifacts.length) {
                            object.artifacts = [];
                            for (var j = 0; j < message.artifacts.length; ++j)
                                object.artifacts[j] = $root.palexy.streaming.v1.Artifact.toObject(message.artifacts[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this StaffGatheringDetails to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StaffGatheringDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StaffGatheringDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StaffGatheringDetails;
                })();
    
                v1.StaffAttendanceNonComplianceDetails = (function() {
    
                    /**
                     * Properties of a StaffAttendanceNonComplianceDetails.
                     * @memberof palexy.streaming.v1
                     * @interface IStaffAttendanceNonComplianceDetails
                     * @property {Array.<palexy.streaming.v1.IArtifact>|null} [artifacts] StaffAttendanceNonComplianceDetails artifacts
                     * @property {number|null} [expectedNoStaffs] StaffAttendanceNonComplianceDetails expectedNoStaffs
                     * @property {number|null} [maxNumStaffs] StaffAttendanceNonComplianceDetails maxNumStaffs
                     */
    
                    /**
                     * Constructs a new StaffAttendanceNonComplianceDetails.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffAttendanceNonComplianceDetails.
                     * @implements IStaffAttendanceNonComplianceDetails
                     * @constructor
                     * @param {palexy.streaming.v1.IStaffAttendanceNonComplianceDetails=} [properties] Properties to set
                     */
                    function StaffAttendanceNonComplianceDetails(properties) {
                        this.artifacts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StaffAttendanceNonComplianceDetails artifacts.
                     * @member {Array.<palexy.streaming.v1.IArtifact>} artifacts
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @instance
                     */
                    StaffAttendanceNonComplianceDetails.prototype.artifacts = $util.emptyArray;
    
                    /**
                     * StaffAttendanceNonComplianceDetails expectedNoStaffs.
                     * @member {number} expectedNoStaffs
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @instance
                     */
                    StaffAttendanceNonComplianceDetails.prototype.expectedNoStaffs = 0;
    
                    /**
                     * StaffAttendanceNonComplianceDetails maxNumStaffs.
                     * @member {number} maxNumStaffs
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @instance
                     */
                    StaffAttendanceNonComplianceDetails.prototype.maxNumStaffs = 0;
    
                    /**
                     * Creates a new StaffAttendanceNonComplianceDetails instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @static
                     * @param {palexy.streaming.v1.IStaffAttendanceNonComplianceDetails=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StaffAttendanceNonComplianceDetails} StaffAttendanceNonComplianceDetails instance
                     */
                    StaffAttendanceNonComplianceDetails.create = function create(properties) {
                        return new StaffAttendanceNonComplianceDetails(properties);
                    };
    
                    /**
                     * Encodes the specified StaffAttendanceNonComplianceDetails message. Does not implicitly {@link palexy.streaming.v1.StaffAttendanceNonComplianceDetails.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @static
                     * @param {palexy.streaming.v1.IStaffAttendanceNonComplianceDetails} message StaffAttendanceNonComplianceDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffAttendanceNonComplianceDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.artifacts != null && message.artifacts.length)
                            for (var i = 0; i < message.artifacts.length; ++i)
                                $root.palexy.streaming.v1.Artifact.encode(message.artifacts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.expectedNoStaffs != null && Object.hasOwnProperty.call(message, "expectedNoStaffs"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.expectedNoStaffs);
                        if (message.maxNumStaffs != null && Object.hasOwnProperty.call(message, "maxNumStaffs"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.maxNumStaffs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StaffAttendanceNonComplianceDetails message, length delimited. Does not implicitly {@link palexy.streaming.v1.StaffAttendanceNonComplianceDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @static
                     * @param {palexy.streaming.v1.IStaffAttendanceNonComplianceDetails} message StaffAttendanceNonComplianceDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffAttendanceNonComplianceDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StaffAttendanceNonComplianceDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StaffAttendanceNonComplianceDetails} StaffAttendanceNonComplianceDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffAttendanceNonComplianceDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StaffAttendanceNonComplianceDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.artifacts && message.artifacts.length))
                                    message.artifacts = [];
                                message.artifacts.push($root.palexy.streaming.v1.Artifact.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.expectedNoStaffs = reader.int32();
                                break;
                            case 3:
                                message.maxNumStaffs = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StaffAttendanceNonComplianceDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StaffAttendanceNonComplianceDetails} StaffAttendanceNonComplianceDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffAttendanceNonComplianceDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StaffAttendanceNonComplianceDetails message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StaffAttendanceNonComplianceDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.artifacts != null && message.hasOwnProperty("artifacts")) {
                            if (!Array.isArray(message.artifacts))
                                return "artifacts: array expected";
                            for (var i = 0; i < message.artifacts.length; ++i) {
                                var error = $root.palexy.streaming.v1.Artifact.verify(message.artifacts[i]);
                                if (error)
                                    return "artifacts." + error;
                            }
                        }
                        if (message.expectedNoStaffs != null && message.hasOwnProperty("expectedNoStaffs"))
                            if (!$util.isInteger(message.expectedNoStaffs))
                                return "expectedNoStaffs: integer expected";
                        if (message.maxNumStaffs != null && message.hasOwnProperty("maxNumStaffs"))
                            if (!$util.isInteger(message.maxNumStaffs))
                                return "maxNumStaffs: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a StaffAttendanceNonComplianceDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StaffAttendanceNonComplianceDetails} StaffAttendanceNonComplianceDetails
                     */
                    StaffAttendanceNonComplianceDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StaffAttendanceNonComplianceDetails)
                            return object;
                        var message = new $root.palexy.streaming.v1.StaffAttendanceNonComplianceDetails();
                        if (object.artifacts) {
                            if (!Array.isArray(object.artifacts))
                                throw TypeError(".palexy.streaming.v1.StaffAttendanceNonComplianceDetails.artifacts: array expected");
                            message.artifacts = [];
                            for (var i = 0; i < object.artifacts.length; ++i) {
                                if (typeof object.artifacts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.StaffAttendanceNonComplianceDetails.artifacts: object expected");
                                message.artifacts[i] = $root.palexy.streaming.v1.Artifact.fromObject(object.artifacts[i]);
                            }
                        }
                        if (object.expectedNoStaffs != null)
                            message.expectedNoStaffs = object.expectedNoStaffs | 0;
                        if (object.maxNumStaffs != null)
                            message.maxNumStaffs = object.maxNumStaffs | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StaffAttendanceNonComplianceDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @static
                     * @param {palexy.streaming.v1.StaffAttendanceNonComplianceDetails} message StaffAttendanceNonComplianceDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StaffAttendanceNonComplianceDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.artifacts = [];
                        if (options.defaults) {
                            object.expectedNoStaffs = 0;
                            object.maxNumStaffs = 0;
                        }
                        if (message.artifacts && message.artifacts.length) {
                            object.artifacts = [];
                            for (var j = 0; j < message.artifacts.length; ++j)
                                object.artifacts[j] = $root.palexy.streaming.v1.Artifact.toObject(message.artifacts[j], options);
                        }
                        if (message.expectedNoStaffs != null && message.hasOwnProperty("expectedNoStaffs"))
                            object.expectedNoStaffs = message.expectedNoStaffs;
                        if (message.maxNumStaffs != null && message.hasOwnProperty("maxNumStaffs"))
                            object.maxNumStaffs = message.maxNumStaffs;
                        return object;
                    };
    
                    /**
                     * Converts this StaffAttendanceNonComplianceDetails to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StaffAttendanceNonComplianceDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StaffAttendanceNonComplianceDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StaffAttendanceNonComplianceDetails;
                })();
    
                v1.SuspiciousCashierActivityDetails = (function() {
    
                    /**
                     * Properties of a SuspiciousCashierActivityDetails.
                     * @memberof palexy.streaming.v1
                     * @interface ISuspiciousCashierActivityDetails
                     * @property {palexy.streaming.v1.SuspiciousCashierActivityDetails.ActivityType|null} [activityType] SuspiciousCashierActivityDetails activityType
                     * @property {Array.<palexy.streaming.v1.IArtifact>|null} [artifacts] SuspiciousCashierActivityDetails artifacts
                     * @property {string|null} [nearestPreviousTransactionDatetime] SuspiciousCashierActivityDetails nearestPreviousTransactionDatetime
                     * @property {string|null} [nearestNextTransactionDatetime] SuspiciousCashierActivityDetails nearestNextTransactionDatetime
                     */
    
                    /**
                     * Constructs a new SuspiciousCashierActivityDetails.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SuspiciousCashierActivityDetails.
                     * @implements ISuspiciousCashierActivityDetails
                     * @constructor
                     * @param {palexy.streaming.v1.ISuspiciousCashierActivityDetails=} [properties] Properties to set
                     */
                    function SuspiciousCashierActivityDetails(properties) {
                        this.artifacts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SuspiciousCashierActivityDetails activityType.
                     * @member {palexy.streaming.v1.SuspiciousCashierActivityDetails.ActivityType} activityType
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @instance
                     */
                    SuspiciousCashierActivityDetails.prototype.activityType = 0;
    
                    /**
                     * SuspiciousCashierActivityDetails artifacts.
                     * @member {Array.<palexy.streaming.v1.IArtifact>} artifacts
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @instance
                     */
                    SuspiciousCashierActivityDetails.prototype.artifacts = $util.emptyArray;
    
                    /**
                     * SuspiciousCashierActivityDetails nearestPreviousTransactionDatetime.
                     * @member {string} nearestPreviousTransactionDatetime
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @instance
                     */
                    SuspiciousCashierActivityDetails.prototype.nearestPreviousTransactionDatetime = "";
    
                    /**
                     * SuspiciousCashierActivityDetails nearestNextTransactionDatetime.
                     * @member {string} nearestNextTransactionDatetime
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @instance
                     */
                    SuspiciousCashierActivityDetails.prototype.nearestNextTransactionDatetime = "";
    
                    /**
                     * Creates a new SuspiciousCashierActivityDetails instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @static
                     * @param {palexy.streaming.v1.ISuspiciousCashierActivityDetails=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SuspiciousCashierActivityDetails} SuspiciousCashierActivityDetails instance
                     */
                    SuspiciousCashierActivityDetails.create = function create(properties) {
                        return new SuspiciousCashierActivityDetails(properties);
                    };
    
                    /**
                     * Encodes the specified SuspiciousCashierActivityDetails message. Does not implicitly {@link palexy.streaming.v1.SuspiciousCashierActivityDetails.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @static
                     * @param {palexy.streaming.v1.ISuspiciousCashierActivityDetails} message SuspiciousCashierActivityDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SuspiciousCashierActivityDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.activityType != null && Object.hasOwnProperty.call(message, "activityType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.activityType);
                        if (message.artifacts != null && message.artifacts.length)
                            for (var i = 0; i < message.artifacts.length; ++i)
                                $root.palexy.streaming.v1.Artifact.encode(message.artifacts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.nearestPreviousTransactionDatetime != null && Object.hasOwnProperty.call(message, "nearestPreviousTransactionDatetime"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nearestPreviousTransactionDatetime);
                        if (message.nearestNextTransactionDatetime != null && Object.hasOwnProperty.call(message, "nearestNextTransactionDatetime"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.nearestNextTransactionDatetime);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SuspiciousCashierActivityDetails message, length delimited. Does not implicitly {@link palexy.streaming.v1.SuspiciousCashierActivityDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @static
                     * @param {palexy.streaming.v1.ISuspiciousCashierActivityDetails} message SuspiciousCashierActivityDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SuspiciousCashierActivityDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SuspiciousCashierActivityDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SuspiciousCashierActivityDetails} SuspiciousCashierActivityDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SuspiciousCashierActivityDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SuspiciousCashierActivityDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.activityType = reader.int32();
                                break;
                            case 2:
                                if (!(message.artifacts && message.artifacts.length))
                                    message.artifacts = [];
                                message.artifacts.push($root.palexy.streaming.v1.Artifact.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.nearestPreviousTransactionDatetime = reader.string();
                                break;
                            case 4:
                                message.nearestNextTransactionDatetime = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SuspiciousCashierActivityDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SuspiciousCashierActivityDetails} SuspiciousCashierActivityDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SuspiciousCashierActivityDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SuspiciousCashierActivityDetails message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SuspiciousCashierActivityDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.activityType != null && message.hasOwnProperty("activityType"))
                            switch (message.activityType) {
                            default:
                                return "activityType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.artifacts != null && message.hasOwnProperty("artifacts")) {
                            if (!Array.isArray(message.artifacts))
                                return "artifacts: array expected";
                            for (var i = 0; i < message.artifacts.length; ++i) {
                                var error = $root.palexy.streaming.v1.Artifact.verify(message.artifacts[i]);
                                if (error)
                                    return "artifacts." + error;
                            }
                        }
                        if (message.nearestPreviousTransactionDatetime != null && message.hasOwnProperty("nearestPreviousTransactionDatetime"))
                            if (!$util.isString(message.nearestPreviousTransactionDatetime))
                                return "nearestPreviousTransactionDatetime: string expected";
                        if (message.nearestNextTransactionDatetime != null && message.hasOwnProperty("nearestNextTransactionDatetime"))
                            if (!$util.isString(message.nearestNextTransactionDatetime))
                                return "nearestNextTransactionDatetime: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a SuspiciousCashierActivityDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SuspiciousCashierActivityDetails} SuspiciousCashierActivityDetails
                     */
                    SuspiciousCashierActivityDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SuspiciousCashierActivityDetails)
                            return object;
                        var message = new $root.palexy.streaming.v1.SuspiciousCashierActivityDetails();
                        switch (object.activityType) {
                        case "UNKNOWN":
                        case 0:
                            message.activityType = 0;
                            break;
                        case "OTHERS":
                        case 1:
                            message.activityType = 1;
                            break;
                        case "NO_BILL":
                        case 2:
                            message.activityType = 2;
                            break;
                        case "STAFF_STEALING":
                        case 3:
                            message.activityType = 3;
                            break;
                        }
                        if (object.artifacts) {
                            if (!Array.isArray(object.artifacts))
                                throw TypeError(".palexy.streaming.v1.SuspiciousCashierActivityDetails.artifacts: array expected");
                            message.artifacts = [];
                            for (var i = 0; i < object.artifacts.length; ++i) {
                                if (typeof object.artifacts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.SuspiciousCashierActivityDetails.artifacts: object expected");
                                message.artifacts[i] = $root.palexy.streaming.v1.Artifact.fromObject(object.artifacts[i]);
                            }
                        }
                        if (object.nearestPreviousTransactionDatetime != null)
                            message.nearestPreviousTransactionDatetime = String(object.nearestPreviousTransactionDatetime);
                        if (object.nearestNextTransactionDatetime != null)
                            message.nearestNextTransactionDatetime = String(object.nearestNextTransactionDatetime);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SuspiciousCashierActivityDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @static
                     * @param {palexy.streaming.v1.SuspiciousCashierActivityDetails} message SuspiciousCashierActivityDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SuspiciousCashierActivityDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.artifacts = [];
                        if (options.defaults) {
                            object.activityType = options.enums === String ? "UNKNOWN" : 0;
                            object.nearestPreviousTransactionDatetime = "";
                            object.nearestNextTransactionDatetime = "";
                        }
                        if (message.activityType != null && message.hasOwnProperty("activityType"))
                            object.activityType = options.enums === String ? $root.palexy.streaming.v1.SuspiciousCashierActivityDetails.ActivityType[message.activityType] : message.activityType;
                        if (message.artifacts && message.artifacts.length) {
                            object.artifacts = [];
                            for (var j = 0; j < message.artifacts.length; ++j)
                                object.artifacts[j] = $root.palexy.streaming.v1.Artifact.toObject(message.artifacts[j], options);
                        }
                        if (message.nearestPreviousTransactionDatetime != null && message.hasOwnProperty("nearestPreviousTransactionDatetime"))
                            object.nearestPreviousTransactionDatetime = message.nearestPreviousTransactionDatetime;
                        if (message.nearestNextTransactionDatetime != null && message.hasOwnProperty("nearestNextTransactionDatetime"))
                            object.nearestNextTransactionDatetime = message.nearestNextTransactionDatetime;
                        return object;
                    };
    
                    /**
                     * Converts this SuspiciousCashierActivityDetails to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SuspiciousCashierActivityDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SuspiciousCashierActivityDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * ActivityType enum.
                     * @name palexy.streaming.v1.SuspiciousCashierActivityDetails.ActivityType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} OTHERS=1 OTHERS value
                     * @property {number} NO_BILL=2 NO_BILL value
                     * @property {number} STAFF_STEALING=3 STAFF_STEALING value
                     */
                    SuspiciousCashierActivityDetails.ActivityType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "OTHERS"] = 1;
                        values[valuesById[2] = "NO_BILL"] = 2;
                        values[valuesById[3] = "STAFF_STEALING"] = 3;
                        return values;
                    })();
    
                    return SuspiciousCashierActivityDetails;
                })();
    
                v1.StaffLeavingSpecialAreaWoFollowingSopDetails = (function() {
    
                    /**
                     * Properties of a StaffLeavingSpecialAreaWoFollowingSopDetails.
                     * @memberof palexy.streaming.v1
                     * @interface IStaffLeavingSpecialAreaWoFollowingSopDetails
                     * @property {palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.ActivityType|null} [activityType] StaffLeavingSpecialAreaWoFollowingSopDetails activityType
                     * @property {Array.<palexy.streaming.v1.IArtifact>|null} [artifacts] StaffLeavingSpecialAreaWoFollowingSopDetails artifacts
                     */
    
                    /**
                     * Constructs a new StaffLeavingSpecialAreaWoFollowingSopDetails.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffLeavingSpecialAreaWoFollowingSopDetails.
                     * @implements IStaffLeavingSpecialAreaWoFollowingSopDetails
                     * @constructor
                     * @param {palexy.streaming.v1.IStaffLeavingSpecialAreaWoFollowingSopDetails=} [properties] Properties to set
                     */
                    function StaffLeavingSpecialAreaWoFollowingSopDetails(properties) {
                        this.artifacts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StaffLeavingSpecialAreaWoFollowingSopDetails activityType.
                     * @member {palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.ActivityType} activityType
                     * @memberof palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @instance
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.prototype.activityType = 0;
    
                    /**
                     * StaffLeavingSpecialAreaWoFollowingSopDetails artifacts.
                     * @member {Array.<palexy.streaming.v1.IArtifact>} artifacts
                     * @memberof palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @instance
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.prototype.artifacts = $util.emptyArray;
    
                    /**
                     * Creates a new StaffLeavingSpecialAreaWoFollowingSopDetails instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @static
                     * @param {palexy.streaming.v1.IStaffLeavingSpecialAreaWoFollowingSopDetails=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails} StaffLeavingSpecialAreaWoFollowingSopDetails instance
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.create = function create(properties) {
                        return new StaffLeavingSpecialAreaWoFollowingSopDetails(properties);
                    };
    
                    /**
                     * Encodes the specified StaffLeavingSpecialAreaWoFollowingSopDetails message. Does not implicitly {@link palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @static
                     * @param {palexy.streaming.v1.IStaffLeavingSpecialAreaWoFollowingSopDetails} message StaffLeavingSpecialAreaWoFollowingSopDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.activityType != null && Object.hasOwnProperty.call(message, "activityType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.activityType);
                        if (message.artifacts != null && message.artifacts.length)
                            for (var i = 0; i < message.artifacts.length; ++i)
                                $root.palexy.streaming.v1.Artifact.encode(message.artifacts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StaffLeavingSpecialAreaWoFollowingSopDetails message, length delimited. Does not implicitly {@link palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @static
                     * @param {palexy.streaming.v1.IStaffLeavingSpecialAreaWoFollowingSopDetails} message StaffLeavingSpecialAreaWoFollowingSopDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StaffLeavingSpecialAreaWoFollowingSopDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails} StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.activityType = reader.int32();
                                break;
                            case 2:
                                if (!(message.artifacts && message.artifacts.length))
                                    message.artifacts = [];
                                message.artifacts.push($root.palexy.streaming.v1.Artifact.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StaffLeavingSpecialAreaWoFollowingSopDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails} StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StaffLeavingSpecialAreaWoFollowingSopDetails message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.activityType != null && message.hasOwnProperty("activityType"))
                            switch (message.activityType) {
                            default:
                                return "activityType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.artifacts != null && message.hasOwnProperty("artifacts")) {
                            if (!Array.isArray(message.artifacts))
                                return "artifacts: array expected";
                            for (var i = 0; i < message.artifacts.length; ++i) {
                                var error = $root.palexy.streaming.v1.Artifact.verify(message.artifacts[i]);
                                if (error)
                                    return "artifacts." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a StaffLeavingSpecialAreaWoFollowingSopDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails} StaffLeavingSpecialAreaWoFollowingSopDetails
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails)
                            return object;
                        var message = new $root.palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails();
                        switch (object.activityType) {
                        case "UNKNOWN":
                        case 0:
                            message.activityType = 0;
                            break;
                        case "BODY_NOT_CHECKED":
                        case 1:
                            message.activityType = 1;
                            break;
                        }
                        if (object.artifacts) {
                            if (!Array.isArray(object.artifacts))
                                throw TypeError(".palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.artifacts: array expected");
                            message.artifacts = [];
                            for (var i = 0; i < object.artifacts.length; ++i) {
                                if (typeof object.artifacts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.artifacts: object expected");
                                message.artifacts[i] = $root.palexy.streaming.v1.Artifact.fromObject(object.artifacts[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StaffLeavingSpecialAreaWoFollowingSopDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @static
                     * @param {palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails} message StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.artifacts = [];
                        if (options.defaults)
                            object.activityType = options.enums === String ? "UNKNOWN" : 0;
                        if (message.activityType != null && message.hasOwnProperty("activityType"))
                            object.activityType = options.enums === String ? $root.palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.ActivityType[message.activityType] : message.activityType;
                        if (message.artifacts && message.artifacts.length) {
                            object.artifacts = [];
                            for (var j = 0; j < message.artifacts.length; ++j)
                                object.artifacts[j] = $root.palexy.streaming.v1.Artifact.toObject(message.artifacts[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this StaffLeavingSpecialAreaWoFollowingSopDetails to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * ActivityType enum.
                     * @name palexy.streaming.v1.StaffLeavingSpecialAreaWoFollowingSopDetails.ActivityType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} BODY_NOT_CHECKED=1 BODY_NOT_CHECKED value
                     */
                    StaffLeavingSpecialAreaWoFollowingSopDetails.ActivityType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "BODY_NOT_CHECKED"] = 1;
                        return values;
                    })();
    
                    return StaffLeavingSpecialAreaWoFollowingSopDetails;
                })();
    
                v1.SopNonComplianceActivityDetails = (function() {
    
                    /**
                     * Properties of a SopNonComplianceActivityDetails.
                     * @memberof palexy.streaming.v1
                     * @interface ISopNonComplianceActivityDetails
                     * @property {Array.<palexy.streaming.v1.IArtifact>|null} [artifacts] SopNonComplianceActivityDetails artifacts
                     */
    
                    /**
                     * Constructs a new SopNonComplianceActivityDetails.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SopNonComplianceActivityDetails.
                     * @implements ISopNonComplianceActivityDetails
                     * @constructor
                     * @param {palexy.streaming.v1.ISopNonComplianceActivityDetails=} [properties] Properties to set
                     */
                    function SopNonComplianceActivityDetails(properties) {
                        this.artifacts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SopNonComplianceActivityDetails artifacts.
                     * @member {Array.<palexy.streaming.v1.IArtifact>} artifacts
                     * @memberof palexy.streaming.v1.SopNonComplianceActivityDetails
                     * @instance
                     */
                    SopNonComplianceActivityDetails.prototype.artifacts = $util.emptyArray;
    
                    /**
                     * Creates a new SopNonComplianceActivityDetails instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SopNonComplianceActivityDetails
                     * @static
                     * @param {palexy.streaming.v1.ISopNonComplianceActivityDetails=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SopNonComplianceActivityDetails} SopNonComplianceActivityDetails instance
                     */
                    SopNonComplianceActivityDetails.create = function create(properties) {
                        return new SopNonComplianceActivityDetails(properties);
                    };
    
                    /**
                     * Encodes the specified SopNonComplianceActivityDetails message. Does not implicitly {@link palexy.streaming.v1.SopNonComplianceActivityDetails.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SopNonComplianceActivityDetails
                     * @static
                     * @param {palexy.streaming.v1.ISopNonComplianceActivityDetails} message SopNonComplianceActivityDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SopNonComplianceActivityDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.artifacts != null && message.artifacts.length)
                            for (var i = 0; i < message.artifacts.length; ++i)
                                $root.palexy.streaming.v1.Artifact.encode(message.artifacts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SopNonComplianceActivityDetails message, length delimited. Does not implicitly {@link palexy.streaming.v1.SopNonComplianceActivityDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SopNonComplianceActivityDetails
                     * @static
                     * @param {palexy.streaming.v1.ISopNonComplianceActivityDetails} message SopNonComplianceActivityDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SopNonComplianceActivityDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SopNonComplianceActivityDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SopNonComplianceActivityDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SopNonComplianceActivityDetails} SopNonComplianceActivityDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SopNonComplianceActivityDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SopNonComplianceActivityDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.artifacts && message.artifacts.length))
                                    message.artifacts = [];
                                message.artifacts.push($root.palexy.streaming.v1.Artifact.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SopNonComplianceActivityDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SopNonComplianceActivityDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SopNonComplianceActivityDetails} SopNonComplianceActivityDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SopNonComplianceActivityDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SopNonComplianceActivityDetails message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SopNonComplianceActivityDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SopNonComplianceActivityDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.artifacts != null && message.hasOwnProperty("artifacts")) {
                            if (!Array.isArray(message.artifacts))
                                return "artifacts: array expected";
                            for (var i = 0; i < message.artifacts.length; ++i) {
                                var error = $root.palexy.streaming.v1.Artifact.verify(message.artifacts[i]);
                                if (error)
                                    return "artifacts." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a SopNonComplianceActivityDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SopNonComplianceActivityDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SopNonComplianceActivityDetails} SopNonComplianceActivityDetails
                     */
                    SopNonComplianceActivityDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SopNonComplianceActivityDetails)
                            return object;
                        var message = new $root.palexy.streaming.v1.SopNonComplianceActivityDetails();
                        if (object.artifacts) {
                            if (!Array.isArray(object.artifacts))
                                throw TypeError(".palexy.streaming.v1.SopNonComplianceActivityDetails.artifacts: array expected");
                            message.artifacts = [];
                            for (var i = 0; i < object.artifacts.length; ++i) {
                                if (typeof object.artifacts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.SopNonComplianceActivityDetails.artifacts: object expected");
                                message.artifacts[i] = $root.palexy.streaming.v1.Artifact.fromObject(object.artifacts[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SopNonComplianceActivityDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SopNonComplianceActivityDetails
                     * @static
                     * @param {palexy.streaming.v1.SopNonComplianceActivityDetails} message SopNonComplianceActivityDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SopNonComplianceActivityDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.artifacts = [];
                        if (message.artifacts && message.artifacts.length) {
                            object.artifacts = [];
                            for (var j = 0; j < message.artifacts.length; ++j)
                                object.artifacts[j] = $root.palexy.streaming.v1.Artifact.toObject(message.artifacts[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this SopNonComplianceActivityDetails to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SopNonComplianceActivityDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SopNonComplianceActivityDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SopNonComplianceActivityDetails;
                })();
    
                v1.EmbeddedReportService = (function() {
    
                    /**
                     * Constructs a new EmbeddedReportService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an EmbeddedReportService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function EmbeddedReportService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (EmbeddedReportService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = EmbeddedReportService;
    
                    /**
                     * Creates new EmbeddedReportService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {EmbeddedReportService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    EmbeddedReportService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.EmbeddedReportService#createEmbeddedReport}.
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @typedef CreateEmbeddedReportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.EmbeddedReport} [response] EmbeddedReport
                     */
    
                    /**
                     * Calls CreateEmbeddedReport.
                     * @function createEmbeddedReport
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateEmbeddedReportRequest} request CreateEmbeddedReportRequest message or plain object
                     * @param {palexy.streaming.v1.EmbeddedReportService.CreateEmbeddedReportCallback} callback Node-style callback called with the error, if any, and EmbeddedReport
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(EmbeddedReportService.prototype.createEmbeddedReport = function createEmbeddedReport(request, callback) {
                        return this.rpcCall(createEmbeddedReport, $root.palexy.streaming.v1.CreateEmbeddedReportRequest, $root.palexy.streaming.v1.EmbeddedReport, request, callback);
                    }, "name", { value: "CreateEmbeddedReport" });
    
                    /**
                     * Calls CreateEmbeddedReport.
                     * @function createEmbeddedReport
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateEmbeddedReportRequest} request CreateEmbeddedReportRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.EmbeddedReport>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.EmbeddedReportService#getEmbeddedReport}.
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @typedef GetEmbeddedReportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.EmbeddedReport} [response] EmbeddedReport
                     */
    
                    /**
                     * Calls GetEmbeddedReport.
                     * @function getEmbeddedReport
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @instance
                     * @param {palexy.streaming.v1.IGetEmbeddedReportRequest} request GetEmbeddedReportRequest message or plain object
                     * @param {palexy.streaming.v1.EmbeddedReportService.GetEmbeddedReportCallback} callback Node-style callback called with the error, if any, and EmbeddedReport
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(EmbeddedReportService.prototype.getEmbeddedReport = function getEmbeddedReport(request, callback) {
                        return this.rpcCall(getEmbeddedReport, $root.palexy.streaming.v1.GetEmbeddedReportRequest, $root.palexy.streaming.v1.EmbeddedReport, request, callback);
                    }, "name", { value: "GetEmbeddedReport" });
    
                    /**
                     * Calls GetEmbeddedReport.
                     * @function getEmbeddedReport
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @instance
                     * @param {palexy.streaming.v1.IGetEmbeddedReportRequest} request GetEmbeddedReportRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.EmbeddedReport>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.EmbeddedReportService#updateEmbeddedReport}.
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @typedef UpdateEmbeddedReportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.EmbeddedReport} [response] EmbeddedReport
                     */
    
                    /**
                     * Calls UpdateEmbeddedReport.
                     * @function updateEmbeddedReport
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateEmbeddedReportRequest} request UpdateEmbeddedReportRequest message or plain object
                     * @param {palexy.streaming.v1.EmbeddedReportService.UpdateEmbeddedReportCallback} callback Node-style callback called with the error, if any, and EmbeddedReport
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(EmbeddedReportService.prototype.updateEmbeddedReport = function updateEmbeddedReport(request, callback) {
                        return this.rpcCall(updateEmbeddedReport, $root.palexy.streaming.v1.UpdateEmbeddedReportRequest, $root.palexy.streaming.v1.EmbeddedReport, request, callback);
                    }, "name", { value: "UpdateEmbeddedReport" });
    
                    /**
                     * Calls UpdateEmbeddedReport.
                     * @function updateEmbeddedReport
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateEmbeddedReportRequest} request UpdateEmbeddedReportRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.EmbeddedReport>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.EmbeddedReportService#deleteEmbeddedReport}.
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @typedef DeleteEmbeddedReportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteEmbeddedReport.
                     * @function deleteEmbeddedReport
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteEmbeddedReportRequest} request DeleteEmbeddedReportRequest message or plain object
                     * @param {palexy.streaming.v1.EmbeddedReportService.DeleteEmbeddedReportCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(EmbeddedReportService.prototype.deleteEmbeddedReport = function deleteEmbeddedReport(request, callback) {
                        return this.rpcCall(deleteEmbeddedReport, $root.palexy.streaming.v1.DeleteEmbeddedReportRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteEmbeddedReport" });
    
                    /**
                     * Calls DeleteEmbeddedReport.
                     * @function deleteEmbeddedReport
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteEmbeddedReportRequest} request DeleteEmbeddedReportRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.EmbeddedReportService#listEmbeddedReport}.
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @typedef ListEmbeddedReportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListEmbeddedReportResponse} [response] ListEmbeddedReportResponse
                     */
    
                    /**
                     * Calls ListEmbeddedReport.
                     * @function listEmbeddedReport
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @instance
                     * @param {palexy.streaming.v1.IListEmbeddedReportRequest} request ListEmbeddedReportRequest message or plain object
                     * @param {palexy.streaming.v1.EmbeddedReportService.ListEmbeddedReportCallback} callback Node-style callback called with the error, if any, and ListEmbeddedReportResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(EmbeddedReportService.prototype.listEmbeddedReport = function listEmbeddedReport(request, callback) {
                        return this.rpcCall(listEmbeddedReport, $root.palexy.streaming.v1.ListEmbeddedReportRequest, $root.palexy.streaming.v1.ListEmbeddedReportResponse, request, callback);
                    }, "name", { value: "ListEmbeddedReport" });
    
                    /**
                     * Calls ListEmbeddedReport.
                     * @function listEmbeddedReport
                     * @memberof palexy.streaming.v1.EmbeddedReportService
                     * @instance
                     * @param {palexy.streaming.v1.IListEmbeddedReportRequest} request ListEmbeddedReportRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListEmbeddedReportResponse>} Promise
                     * @variation 2
                     */
    
                    return EmbeddedReportService;
                })();
    
                v1.CreateEmbeddedReportRequest = (function() {
    
                    /**
                     * Properties of a CreateEmbeddedReportRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateEmbeddedReportRequest
                     * @property {palexy.streaming.v1.IEmbeddedReport|null} [report] CreateEmbeddedReportRequest report
                     */
    
                    /**
                     * Constructs a new CreateEmbeddedReportRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateEmbeddedReportRequest.
                     * @implements ICreateEmbeddedReportRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateEmbeddedReportRequest=} [properties] Properties to set
                     */
                    function CreateEmbeddedReportRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateEmbeddedReportRequest report.
                     * @member {palexy.streaming.v1.IEmbeddedReport|null|undefined} report
                     * @memberof palexy.streaming.v1.CreateEmbeddedReportRequest
                     * @instance
                     */
                    CreateEmbeddedReportRequest.prototype.report = null;
    
                    /**
                     * Creates a new CreateEmbeddedReportRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateEmbeddedReportRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateEmbeddedReportRequest} CreateEmbeddedReportRequest instance
                     */
                    CreateEmbeddedReportRequest.create = function create(properties) {
                        return new CreateEmbeddedReportRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateEmbeddedReportRequest message. Does not implicitly {@link palexy.streaming.v1.CreateEmbeddedReportRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateEmbeddedReportRequest} message CreateEmbeddedReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateEmbeddedReportRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.report != null && Object.hasOwnProperty.call(message, "report"))
                            $root.palexy.streaming.v1.EmbeddedReport.encode(message.report, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateEmbeddedReportRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateEmbeddedReportRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateEmbeddedReportRequest} message CreateEmbeddedReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateEmbeddedReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateEmbeddedReportRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateEmbeddedReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateEmbeddedReportRequest} CreateEmbeddedReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateEmbeddedReportRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateEmbeddedReportRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.report = $root.palexy.streaming.v1.EmbeddedReport.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateEmbeddedReportRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateEmbeddedReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateEmbeddedReportRequest} CreateEmbeddedReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateEmbeddedReportRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateEmbeddedReportRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateEmbeddedReportRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateEmbeddedReportRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.report != null && message.hasOwnProperty("report")) {
                            var error = $root.palexy.streaming.v1.EmbeddedReport.verify(message.report);
                            if (error)
                                return "report." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateEmbeddedReportRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateEmbeddedReportRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateEmbeddedReportRequest} CreateEmbeddedReportRequest
                     */
                    CreateEmbeddedReportRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateEmbeddedReportRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateEmbeddedReportRequest();
                        if (object.report != null) {
                            if (typeof object.report !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateEmbeddedReportRequest.report: object expected");
                            message.report = $root.palexy.streaming.v1.EmbeddedReport.fromObject(object.report);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateEmbeddedReportRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateEmbeddedReportRequest} message CreateEmbeddedReportRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateEmbeddedReportRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.report = null;
                        if (message.report != null && message.hasOwnProperty("report"))
                            object.report = $root.palexy.streaming.v1.EmbeddedReport.toObject(message.report, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateEmbeddedReportRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateEmbeddedReportRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateEmbeddedReportRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateEmbeddedReportRequest;
                })();
    
                v1.GetEmbeddedReportRequest = (function() {
    
                    /**
                     * Properties of a GetEmbeddedReportRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetEmbeddedReportRequest
                     * @property {number|null} [id] GetEmbeddedReportRequest id
                     */
    
                    /**
                     * Constructs a new GetEmbeddedReportRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetEmbeddedReportRequest.
                     * @implements IGetEmbeddedReportRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetEmbeddedReportRequest=} [properties] Properties to set
                     */
                    function GetEmbeddedReportRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetEmbeddedReportRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.GetEmbeddedReportRequest
                     * @instance
                     */
                    GetEmbeddedReportRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetEmbeddedReportRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetEmbeddedReportRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetEmbeddedReportRequest} GetEmbeddedReportRequest instance
                     */
                    GetEmbeddedReportRequest.create = function create(properties) {
                        return new GetEmbeddedReportRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetEmbeddedReportRequest message. Does not implicitly {@link palexy.streaming.v1.GetEmbeddedReportRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetEmbeddedReportRequest} message GetEmbeddedReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetEmbeddedReportRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetEmbeddedReportRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetEmbeddedReportRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetEmbeddedReportRequest} message GetEmbeddedReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetEmbeddedReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetEmbeddedReportRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetEmbeddedReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetEmbeddedReportRequest} GetEmbeddedReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetEmbeddedReportRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetEmbeddedReportRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetEmbeddedReportRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetEmbeddedReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetEmbeddedReportRequest} GetEmbeddedReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetEmbeddedReportRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetEmbeddedReportRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetEmbeddedReportRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetEmbeddedReportRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetEmbeddedReportRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetEmbeddedReportRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetEmbeddedReportRequest} GetEmbeddedReportRequest
                     */
                    GetEmbeddedReportRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetEmbeddedReportRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetEmbeddedReportRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetEmbeddedReportRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.GetEmbeddedReportRequest} message GetEmbeddedReportRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetEmbeddedReportRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this GetEmbeddedReportRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetEmbeddedReportRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetEmbeddedReportRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetEmbeddedReportRequest;
                })();
    
                v1.UpdateEmbeddedReportRequest = (function() {
    
                    /**
                     * Properties of an UpdateEmbeddedReportRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateEmbeddedReportRequest
                     * @property {palexy.streaming.v1.IEmbeddedReport|null} [report] UpdateEmbeddedReportRequest report
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateEmbeddedReportRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateEmbeddedReportRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateEmbeddedReportRequest.
                     * @implements IUpdateEmbeddedReportRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateEmbeddedReportRequest=} [properties] Properties to set
                     */
                    function UpdateEmbeddedReportRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateEmbeddedReportRequest report.
                     * @member {palexy.streaming.v1.IEmbeddedReport|null|undefined} report
                     * @memberof palexy.streaming.v1.UpdateEmbeddedReportRequest
                     * @instance
                     */
                    UpdateEmbeddedReportRequest.prototype.report = null;
    
                    /**
                     * UpdateEmbeddedReportRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateEmbeddedReportRequest
                     * @instance
                     */
                    UpdateEmbeddedReportRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateEmbeddedReportRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateEmbeddedReportRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateEmbeddedReportRequest} UpdateEmbeddedReportRequest instance
                     */
                    UpdateEmbeddedReportRequest.create = function create(properties) {
                        return new UpdateEmbeddedReportRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateEmbeddedReportRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateEmbeddedReportRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateEmbeddedReportRequest} message UpdateEmbeddedReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateEmbeddedReportRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.report != null && Object.hasOwnProperty.call(message, "report"))
                            $root.palexy.streaming.v1.EmbeddedReport.encode(message.report, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateEmbeddedReportRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateEmbeddedReportRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateEmbeddedReportRequest} message UpdateEmbeddedReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateEmbeddedReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateEmbeddedReportRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateEmbeddedReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateEmbeddedReportRequest} UpdateEmbeddedReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateEmbeddedReportRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateEmbeddedReportRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.report = $root.palexy.streaming.v1.EmbeddedReport.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateEmbeddedReportRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateEmbeddedReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateEmbeddedReportRequest} UpdateEmbeddedReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateEmbeddedReportRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateEmbeddedReportRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateEmbeddedReportRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateEmbeddedReportRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.report != null && message.hasOwnProperty("report")) {
                            var error = $root.palexy.streaming.v1.EmbeddedReport.verify(message.report);
                            if (error)
                                return "report." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateEmbeddedReportRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateEmbeddedReportRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateEmbeddedReportRequest} UpdateEmbeddedReportRequest
                     */
                    UpdateEmbeddedReportRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateEmbeddedReportRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateEmbeddedReportRequest();
                        if (object.report != null) {
                            if (typeof object.report !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateEmbeddedReportRequest.report: object expected");
                            message.report = $root.palexy.streaming.v1.EmbeddedReport.fromObject(object.report);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateEmbeddedReportRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateEmbeddedReportRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateEmbeddedReportRequest} message UpdateEmbeddedReportRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateEmbeddedReportRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.report = null;
                            object.updateMask = null;
                        }
                        if (message.report != null && message.hasOwnProperty("report"))
                            object.report = $root.palexy.streaming.v1.EmbeddedReport.toObject(message.report, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateEmbeddedReportRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateEmbeddedReportRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateEmbeddedReportRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateEmbeddedReportRequest;
                })();
    
                v1.DeleteEmbeddedReportRequest = (function() {
    
                    /**
                     * Properties of a DeleteEmbeddedReportRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteEmbeddedReportRequest
                     * @property {number|null} [id] DeleteEmbeddedReportRequest id
                     */
    
                    /**
                     * Constructs a new DeleteEmbeddedReportRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteEmbeddedReportRequest.
                     * @implements IDeleteEmbeddedReportRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteEmbeddedReportRequest=} [properties] Properties to set
                     */
                    function DeleteEmbeddedReportRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteEmbeddedReportRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.DeleteEmbeddedReportRequest
                     * @instance
                     */
                    DeleteEmbeddedReportRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteEmbeddedReportRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteEmbeddedReportRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteEmbeddedReportRequest} DeleteEmbeddedReportRequest instance
                     */
                    DeleteEmbeddedReportRequest.create = function create(properties) {
                        return new DeleteEmbeddedReportRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteEmbeddedReportRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteEmbeddedReportRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteEmbeddedReportRequest} message DeleteEmbeddedReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteEmbeddedReportRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteEmbeddedReportRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteEmbeddedReportRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteEmbeddedReportRequest} message DeleteEmbeddedReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteEmbeddedReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteEmbeddedReportRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteEmbeddedReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteEmbeddedReportRequest} DeleteEmbeddedReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteEmbeddedReportRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteEmbeddedReportRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteEmbeddedReportRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteEmbeddedReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteEmbeddedReportRequest} DeleteEmbeddedReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteEmbeddedReportRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteEmbeddedReportRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteEmbeddedReportRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteEmbeddedReportRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteEmbeddedReportRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteEmbeddedReportRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteEmbeddedReportRequest} DeleteEmbeddedReportRequest
                     */
                    DeleteEmbeddedReportRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteEmbeddedReportRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteEmbeddedReportRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteEmbeddedReportRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteEmbeddedReportRequest} message DeleteEmbeddedReportRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteEmbeddedReportRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteEmbeddedReportRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteEmbeddedReportRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteEmbeddedReportRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteEmbeddedReportRequest;
                })();
    
                v1.ListEmbeddedReportRequest = (function() {
    
                    /**
                     * Properties of a ListEmbeddedReportRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListEmbeddedReportRequest
                     * @property {palexy.streaming.v1.ListEmbeddedReportRequest.IReportFilter|null} [reportFilter] ListEmbeddedReportRequest reportFilter
                     */
    
                    /**
                     * Constructs a new ListEmbeddedReportRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListEmbeddedReportRequest.
                     * @implements IListEmbeddedReportRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListEmbeddedReportRequest=} [properties] Properties to set
                     */
                    function ListEmbeddedReportRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListEmbeddedReportRequest reportFilter.
                     * @member {palexy.streaming.v1.ListEmbeddedReportRequest.IReportFilter|null|undefined} reportFilter
                     * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                     * @instance
                     */
                    ListEmbeddedReportRequest.prototype.reportFilter = null;
    
                    /**
                     * Creates a new ListEmbeddedReportRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IListEmbeddedReportRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListEmbeddedReportRequest} ListEmbeddedReportRequest instance
                     */
                    ListEmbeddedReportRequest.create = function create(properties) {
                        return new ListEmbeddedReportRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListEmbeddedReportRequest message. Does not implicitly {@link palexy.streaming.v1.ListEmbeddedReportRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IListEmbeddedReportRequest} message ListEmbeddedReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListEmbeddedReportRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reportFilter != null && Object.hasOwnProperty.call(message, "reportFilter"))
                            $root.palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter.encode(message.reportFilter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListEmbeddedReportRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListEmbeddedReportRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IListEmbeddedReportRequest} message ListEmbeddedReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListEmbeddedReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListEmbeddedReportRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListEmbeddedReportRequest} ListEmbeddedReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListEmbeddedReportRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListEmbeddedReportRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.reportFilter = $root.palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListEmbeddedReportRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListEmbeddedReportRequest} ListEmbeddedReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListEmbeddedReportRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListEmbeddedReportRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListEmbeddedReportRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reportFilter != null && message.hasOwnProperty("reportFilter")) {
                            var error = $root.palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter.verify(message.reportFilter);
                            if (error)
                                return "reportFilter." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListEmbeddedReportRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListEmbeddedReportRequest} ListEmbeddedReportRequest
                     */
                    ListEmbeddedReportRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListEmbeddedReportRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListEmbeddedReportRequest();
                        if (object.reportFilter != null) {
                            if (typeof object.reportFilter !== "object")
                                throw TypeError(".palexy.streaming.v1.ListEmbeddedReportRequest.reportFilter: object expected");
                            message.reportFilter = $root.palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter.fromObject(object.reportFilter);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListEmbeddedReportRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                     * @static
                     * @param {palexy.streaming.v1.ListEmbeddedReportRequest} message ListEmbeddedReportRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListEmbeddedReportRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.reportFilter = null;
                        if (message.reportFilter != null && message.hasOwnProperty("reportFilter"))
                            object.reportFilter = $root.palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter.toObject(message.reportFilter, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListEmbeddedReportRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListEmbeddedReportRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    ListEmbeddedReportRequest.ReportFilter = (function() {
    
                        /**
                         * Properties of a ReportFilter.
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                         * @interface IReportFilter
                         * @property {number|null} [companyId] ReportFilter companyId
                         */
    
                        /**
                         * Constructs a new ReportFilter.
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest
                         * @classdesc Represents a ReportFilter.
                         * @implements IReportFilter
                         * @constructor
                         * @param {palexy.streaming.v1.ListEmbeddedReportRequest.IReportFilter=} [properties] Properties to set
                         */
                        function ReportFilter(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * ReportFilter companyId.
                         * @member {number} companyId
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter
                         * @instance
                         */
                        ReportFilter.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * Creates a new ReportFilter instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter
                         * @static
                         * @param {palexy.streaming.v1.ListEmbeddedReportRequest.IReportFilter=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter} ReportFilter instance
                         */
                        ReportFilter.create = function create(properties) {
                            return new ReportFilter(properties);
                        };
    
                        /**
                         * Encodes the specified ReportFilter message. Does not implicitly {@link palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter
                         * @static
                         * @param {palexy.streaming.v1.ListEmbeddedReportRequest.IReportFilter} message ReportFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReportFilter.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified ReportFilter message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter
                         * @static
                         * @param {palexy.streaming.v1.ListEmbeddedReportRequest.IReportFilter} message ReportFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReportFilter.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a ReportFilter message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter} ReportFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReportFilter.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.companyId = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a ReportFilter message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter} ReportFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReportFilter.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a ReportFilter message.
                         * @function verify
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ReportFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.companyId != null && message.hasOwnProperty("companyId"))
                                if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                    return "companyId: integer|Long expected";
                            return null;
                        };
    
                        /**
                         * Creates a ReportFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter} ReportFilter
                         */
                        ReportFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter)
                                return object;
                            var message = new $root.palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter();
                            if (object.companyId != null)
                                if ($util.Long)
                                    (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                                else if (typeof object.companyId === "string")
                                    message.companyId = parseInt(object.companyId, 10);
                                else if (typeof object.companyId === "number")
                                    message.companyId = object.companyId;
                                else if (typeof object.companyId === "object")
                                    message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a ReportFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter
                         * @static
                         * @param {palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter} message ReportFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ReportFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.companyId = options.longs === String ? "0" : 0;
                            if (message.companyId != null && message.hasOwnProperty("companyId"))
                                if (typeof message.companyId === "number")
                                    object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                                else
                                    object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                            return object;
                        };
    
                        /**
                         * Converts this ReportFilter to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.ListEmbeddedReportRequest.ReportFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ReportFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return ReportFilter;
                    })();
    
                    return ListEmbeddedReportRequest;
                })();
    
                v1.ListEmbeddedReportResponse = (function() {
    
                    /**
                     * Properties of a ListEmbeddedReportResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListEmbeddedReportResponse
                     * @property {Array.<palexy.streaming.v1.IEmbeddedReport>|null} [reports] ListEmbeddedReportResponse reports
                     */
    
                    /**
                     * Constructs a new ListEmbeddedReportResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListEmbeddedReportResponse.
                     * @implements IListEmbeddedReportResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListEmbeddedReportResponse=} [properties] Properties to set
                     */
                    function ListEmbeddedReportResponse(properties) {
                        this.reports = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListEmbeddedReportResponse reports.
                     * @member {Array.<palexy.streaming.v1.IEmbeddedReport>} reports
                     * @memberof palexy.streaming.v1.ListEmbeddedReportResponse
                     * @instance
                     */
                    ListEmbeddedReportResponse.prototype.reports = $util.emptyArray;
    
                    /**
                     * Creates a new ListEmbeddedReportResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListEmbeddedReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IListEmbeddedReportResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListEmbeddedReportResponse} ListEmbeddedReportResponse instance
                     */
                    ListEmbeddedReportResponse.create = function create(properties) {
                        return new ListEmbeddedReportResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListEmbeddedReportResponse message. Does not implicitly {@link palexy.streaming.v1.ListEmbeddedReportResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListEmbeddedReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IListEmbeddedReportResponse} message ListEmbeddedReportResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListEmbeddedReportResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reports != null && message.reports.length)
                            for (var i = 0; i < message.reports.length; ++i)
                                $root.palexy.streaming.v1.EmbeddedReport.encode(message.reports[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListEmbeddedReportResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListEmbeddedReportResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListEmbeddedReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IListEmbeddedReportResponse} message ListEmbeddedReportResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListEmbeddedReportResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListEmbeddedReportResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListEmbeddedReportResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListEmbeddedReportResponse} ListEmbeddedReportResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListEmbeddedReportResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListEmbeddedReportResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.reports && message.reports.length))
                                    message.reports = [];
                                message.reports.push($root.palexy.streaming.v1.EmbeddedReport.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListEmbeddedReportResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListEmbeddedReportResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListEmbeddedReportResponse} ListEmbeddedReportResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListEmbeddedReportResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListEmbeddedReportResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListEmbeddedReportResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListEmbeddedReportResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reports != null && message.hasOwnProperty("reports")) {
                            if (!Array.isArray(message.reports))
                                return "reports: array expected";
                            for (var i = 0; i < message.reports.length; ++i) {
                                var error = $root.palexy.streaming.v1.EmbeddedReport.verify(message.reports[i]);
                                if (error)
                                    return "reports." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListEmbeddedReportResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListEmbeddedReportResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListEmbeddedReportResponse} ListEmbeddedReportResponse
                     */
                    ListEmbeddedReportResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListEmbeddedReportResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListEmbeddedReportResponse();
                        if (object.reports) {
                            if (!Array.isArray(object.reports))
                                throw TypeError(".palexy.streaming.v1.ListEmbeddedReportResponse.reports: array expected");
                            message.reports = [];
                            for (var i = 0; i < object.reports.length; ++i) {
                                if (typeof object.reports[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListEmbeddedReportResponse.reports: object expected");
                                message.reports[i] = $root.palexy.streaming.v1.EmbeddedReport.fromObject(object.reports[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListEmbeddedReportResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListEmbeddedReportResponse
                     * @static
                     * @param {palexy.streaming.v1.ListEmbeddedReportResponse} message ListEmbeddedReportResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListEmbeddedReportResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.reports = [];
                        if (message.reports && message.reports.length) {
                            object.reports = [];
                            for (var j = 0; j < message.reports.length; ++j)
                                object.reports[j] = $root.palexy.streaming.v1.EmbeddedReport.toObject(message.reports[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListEmbeddedReportResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListEmbeddedReportResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListEmbeddedReportResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListEmbeddedReportResponse;
                })();
    
                v1.EmbeddedReport = (function() {
    
                    /**
                     * Properties of an EmbeddedReport.
                     * @memberof palexy.streaming.v1
                     * @interface IEmbeddedReport
                     * @property {number|null} [id] EmbeddedReport id
                     * @property {string|null} [name] EmbeddedReport name
                     * @property {string|null} [iframeLink] EmbeddedReport iframeLink
                     * @property {string|null} [width] EmbeddedReport width
                     * @property {string|null} [height] EmbeddedReport height
                     * @property {number|null} [companyId] EmbeddedReport companyId
                     * @property {Array.<number>|null} [sharedAccountIds] EmbeddedReport sharedAccountIds
                     * @property {palexy.streaming.v1.EmbeddedReportSource|null} [source] EmbeddedReport source
                     * @property {number|null} [createTimeMs] EmbeddedReport createTimeMs
                     * @property {number|null} [updateTimeMs] EmbeddedReport updateTimeMs
                     */
    
                    /**
                     * Constructs a new EmbeddedReport.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an EmbeddedReport.
                     * @implements IEmbeddedReport
                     * @constructor
                     * @param {palexy.streaming.v1.IEmbeddedReport=} [properties] Properties to set
                     */
                    function EmbeddedReport(properties) {
                        this.sharedAccountIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * EmbeddedReport id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @instance
                     */
                    EmbeddedReport.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * EmbeddedReport name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @instance
                     */
                    EmbeddedReport.prototype.name = "";
    
                    /**
                     * EmbeddedReport iframeLink.
                     * @member {string} iframeLink
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @instance
                     */
                    EmbeddedReport.prototype.iframeLink = "";
    
                    /**
                     * EmbeddedReport width.
                     * @member {string} width
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @instance
                     */
                    EmbeddedReport.prototype.width = "";
    
                    /**
                     * EmbeddedReport height.
                     * @member {string} height
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @instance
                     */
                    EmbeddedReport.prototype.height = "";
    
                    /**
                     * EmbeddedReport companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @instance
                     */
                    EmbeddedReport.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * EmbeddedReport sharedAccountIds.
                     * @member {Array.<number>} sharedAccountIds
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @instance
                     */
                    EmbeddedReport.prototype.sharedAccountIds = $util.emptyArray;
    
                    /**
                     * EmbeddedReport source.
                     * @member {palexy.streaming.v1.EmbeddedReportSource} source
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @instance
                     */
                    EmbeddedReport.prototype.source = 0;
    
                    /**
                     * EmbeddedReport createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @instance
                     */
                    EmbeddedReport.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * EmbeddedReport updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @instance
                     */
                    EmbeddedReport.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new EmbeddedReport instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @static
                     * @param {palexy.streaming.v1.IEmbeddedReport=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.EmbeddedReport} EmbeddedReport instance
                     */
                    EmbeddedReport.create = function create(properties) {
                        return new EmbeddedReport(properties);
                    };
    
                    /**
                     * Encodes the specified EmbeddedReport message. Does not implicitly {@link palexy.streaming.v1.EmbeddedReport.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @static
                     * @param {palexy.streaming.v1.IEmbeddedReport} message EmbeddedReport message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EmbeddedReport.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.iframeLink != null && Object.hasOwnProperty.call(message, "iframeLink"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.iframeLink);
                        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.width);
                        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.height);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.companyId);
                        if (message.sharedAccountIds != null && message.sharedAccountIds.length) {
                            writer.uint32(/* id 7, wireType 2 =*/58).fork();
                            for (var i = 0; i < message.sharedAccountIds.length; ++i)
                                writer.int64(message.sharedAccountIds[i]);
                            writer.ldelim();
                        }
                        if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.source);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int64(message.updateTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified EmbeddedReport message, length delimited. Does not implicitly {@link palexy.streaming.v1.EmbeddedReport.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @static
                     * @param {palexy.streaming.v1.IEmbeddedReport} message EmbeddedReport message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EmbeddedReport.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an EmbeddedReport message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.EmbeddedReport} EmbeddedReport
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EmbeddedReport.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.EmbeddedReport();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.iframeLink = reader.string();
                                break;
                            case 4:
                                message.width = reader.string();
                                break;
                            case 5:
                                message.height = reader.string();
                                break;
                            case 6:
                                message.companyId = reader.int64();
                                break;
                            case 7:
                                if (!(message.sharedAccountIds && message.sharedAccountIds.length))
                                    message.sharedAccountIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.sharedAccountIds.push(reader.int64());
                                } else
                                    message.sharedAccountIds.push(reader.int64());
                                break;
                            case 8:
                                message.source = reader.int32();
                                break;
                            case 9:
                                message.createTimeMs = reader.int64();
                                break;
                            case 10:
                                message.updateTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an EmbeddedReport message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.EmbeddedReport} EmbeddedReport
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EmbeddedReport.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an EmbeddedReport message.
                     * @function verify
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EmbeddedReport.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.iframeLink != null && message.hasOwnProperty("iframeLink"))
                            if (!$util.isString(message.iframeLink))
                                return "iframeLink: string expected";
                        if (message.width != null && message.hasOwnProperty("width"))
                            if (!$util.isString(message.width))
                                return "width: string expected";
                        if (message.height != null && message.hasOwnProperty("height"))
                            if (!$util.isString(message.height))
                                return "height: string expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.sharedAccountIds != null && message.hasOwnProperty("sharedAccountIds")) {
                            if (!Array.isArray(message.sharedAccountIds))
                                return "sharedAccountIds: array expected";
                            for (var i = 0; i < message.sharedAccountIds.length; ++i)
                                if (!$util.isInteger(message.sharedAccountIds[i]) && !(message.sharedAccountIds[i] && $util.isInteger(message.sharedAccountIds[i].low) && $util.isInteger(message.sharedAccountIds[i].high)))
                                    return "sharedAccountIds: integer|Long[] expected";
                        }
                        if (message.source != null && message.hasOwnProperty("source"))
                            switch (message.source) {
                            default:
                                return "source: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an EmbeddedReport message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.EmbeddedReport} EmbeddedReport
                     */
                    EmbeddedReport.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.EmbeddedReport)
                            return object;
                        var message = new $root.palexy.streaming.v1.EmbeddedReport();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.iframeLink != null)
                            message.iframeLink = String(object.iframeLink);
                        if (object.width != null)
                            message.width = String(object.width);
                        if (object.height != null)
                            message.height = String(object.height);
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.sharedAccountIds) {
                            if (!Array.isArray(object.sharedAccountIds))
                                throw TypeError(".palexy.streaming.v1.EmbeddedReport.sharedAccountIds: array expected");
                            message.sharedAccountIds = [];
                            for (var i = 0; i < object.sharedAccountIds.length; ++i)
                                if ($util.Long)
                                    (message.sharedAccountIds[i] = $util.Long.fromValue(object.sharedAccountIds[i])).unsigned = false;
                                else if (typeof object.sharedAccountIds[i] === "string")
                                    message.sharedAccountIds[i] = parseInt(object.sharedAccountIds[i], 10);
                                else if (typeof object.sharedAccountIds[i] === "number")
                                    message.sharedAccountIds[i] = object.sharedAccountIds[i];
                                else if (typeof object.sharedAccountIds[i] === "object")
                                    message.sharedAccountIds[i] = new $util.LongBits(object.sharedAccountIds[i].low >>> 0, object.sharedAccountIds[i].high >>> 0).toNumber();
                        }
                        switch (object.source) {
                        case "UNKNOWN_SOURCE":
                        case 0:
                            message.source = 0;
                            break;
                        case "DATA_STUDIO":
                        case 1:
                            message.source = 1;
                            break;
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an EmbeddedReport message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @static
                     * @param {palexy.streaming.v1.EmbeddedReport} message EmbeddedReport
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EmbeddedReport.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.sharedAccountIds = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.iframeLink = "";
                            object.width = "";
                            object.height = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.source = options.enums === String ? "UNKNOWN_SOURCE" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.iframeLink != null && message.hasOwnProperty("iframeLink"))
                            object.iframeLink = message.iframeLink;
                        if (message.width != null && message.hasOwnProperty("width"))
                            object.width = message.width;
                        if (message.height != null && message.hasOwnProperty("height"))
                            object.height = message.height;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.sharedAccountIds && message.sharedAccountIds.length) {
                            object.sharedAccountIds = [];
                            for (var j = 0; j < message.sharedAccountIds.length; ++j)
                                if (typeof message.sharedAccountIds[j] === "number")
                                    object.sharedAccountIds[j] = options.longs === String ? String(message.sharedAccountIds[j]) : message.sharedAccountIds[j];
                                else
                                    object.sharedAccountIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.sharedAccountIds[j]) : options.longs === Number ? new $util.LongBits(message.sharedAccountIds[j].low >>> 0, message.sharedAccountIds[j].high >>> 0).toNumber() : message.sharedAccountIds[j];
                        }
                        if (message.source != null && message.hasOwnProperty("source"))
                            object.source = options.enums === String ? $root.palexy.streaming.v1.EmbeddedReportSource[message.source] : message.source;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this EmbeddedReport to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.EmbeddedReport
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EmbeddedReport.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return EmbeddedReport;
                })();
    
                /**
                 * EmbeddedReportSource enum.
                 * @name palexy.streaming.v1.EmbeddedReportSource
                 * @enum {number}
                 * @property {number} UNKNOWN_SOURCE=0 UNKNOWN_SOURCE value
                 * @property {number} DATA_STUDIO=1 DATA_STUDIO value
                 */
                v1.EmbeddedReportSource = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_SOURCE"] = 0;
                    values[valuesById[1] = "DATA_STUDIO"] = 1;
                    return values;
                })();
    
                v1.StaffDataService = (function() {
    
                    /**
                     * Constructs a new StaffDataService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffDataService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StaffDataService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (StaffDataService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StaffDataService;
    
                    /**
                     * Creates new StaffDataService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StaffDataService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StaffDataService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffDataService#createStaffImportConfig}.
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @typedef CreateStaffImportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffImportConfig} [response] StaffImportConfig
                     */
    
                    /**
                     * Calls CreateStaffImportConfig.
                     * @function createStaffImportConfig
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStaffImportConfigRequest} request CreateStaffImportConfigRequest message or plain object
                     * @param {palexy.streaming.v1.StaffDataService.CreateStaffImportConfigCallback} callback Node-style callback called with the error, if any, and StaffImportConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffDataService.prototype.createStaffImportConfig = function createStaffImportConfig(request, callback) {
                        return this.rpcCall(createStaffImportConfig, $root.palexy.streaming.v1.CreateStaffImportConfigRequest, $root.palexy.streaming.v1.StaffImportConfig, request, callback);
                    }, "name", { value: "CreateStaffImportConfig" });
    
                    /**
                     * Calls CreateStaffImportConfig.
                     * @function createStaffImportConfig
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStaffImportConfigRequest} request CreateStaffImportConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffImportConfig>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffDataService#updateStaffImportConfig}.
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @typedef UpdateStaffImportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffImportConfig} [response] StaffImportConfig
                     */
    
                    /**
                     * Calls UpdateStaffImportConfig.
                     * @function updateStaffImportConfig
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStaffImportConfigRequest} request UpdateStaffImportConfigRequest message or plain object
                     * @param {palexy.streaming.v1.StaffDataService.UpdateStaffImportConfigCallback} callback Node-style callback called with the error, if any, and StaffImportConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffDataService.prototype.updateStaffImportConfig = function updateStaffImportConfig(request, callback) {
                        return this.rpcCall(updateStaffImportConfig, $root.palexy.streaming.v1.UpdateStaffImportConfigRequest, $root.palexy.streaming.v1.StaffImportConfig, request, callback);
                    }, "name", { value: "UpdateStaffImportConfig" });
    
                    /**
                     * Calls UpdateStaffImportConfig.
                     * @function updateStaffImportConfig
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStaffImportConfigRequest} request UpdateStaffImportConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffImportConfig>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffDataService#deleteStaffImportConfig}.
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @typedef DeleteStaffImportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteStaffImportConfig.
                     * @function deleteStaffImportConfig
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteStaffImportConfigRequest} request DeleteStaffImportConfigRequest message or plain object
                     * @param {palexy.streaming.v1.StaffDataService.DeleteStaffImportConfigCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffDataService.prototype.deleteStaffImportConfig = function deleteStaffImportConfig(request, callback) {
                        return this.rpcCall(deleteStaffImportConfig, $root.palexy.streaming.v1.DeleteStaffImportConfigRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteStaffImportConfig" });
    
                    /**
                     * Calls DeleteStaffImportConfig.
                     * @function deleteStaffImportConfig
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteStaffImportConfigRequest} request DeleteStaffImportConfigRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffDataService#listStaffImportConfig}.
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @typedef ListStaffImportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStaffImportConfigResponse} [response] ListStaffImportConfigResponse
                     */
    
                    /**
                     * Calls ListStaffImportConfig.
                     * @function listStaffImportConfig
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffImportConfigRequest} request ListStaffImportConfigRequest message or plain object
                     * @param {palexy.streaming.v1.StaffDataService.ListStaffImportConfigCallback} callback Node-style callback called with the error, if any, and ListStaffImportConfigResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffDataService.prototype.listStaffImportConfig = function listStaffImportConfig(request, callback) {
                        return this.rpcCall(listStaffImportConfig, $root.palexy.streaming.v1.ListStaffImportConfigRequest, $root.palexy.streaming.v1.ListStaffImportConfigResponse, request, callback);
                    }, "name", { value: "ListStaffImportConfig" });
    
                    /**
                     * Calls ListStaffImportConfig.
                     * @function listStaffImportConfig
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffImportConfigRequest} request ListStaffImportConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStaffImportConfigResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffDataService#getStaffImportConfig}.
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @typedef GetStaffImportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffImportConfig} [response] StaffImportConfig
                     */
    
                    /**
                     * Calls GetStaffImportConfig.
                     * @function getStaffImportConfig
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffImportConfigRequest} request GetStaffImportConfigRequest message or plain object
                     * @param {palexy.streaming.v1.StaffDataService.GetStaffImportConfigCallback} callback Node-style callback called with the error, if any, and StaffImportConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffDataService.prototype.getStaffImportConfig = function getStaffImportConfig(request, callback) {
                        return this.rpcCall(getStaffImportConfig, $root.palexy.streaming.v1.GetStaffImportConfigRequest, $root.palexy.streaming.v1.StaffImportConfig, request, callback);
                    }, "name", { value: "GetStaffImportConfig" });
    
                    /**
                     * Calls GetStaffImportConfig.
                     * @function getStaffImportConfig
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffImportConfigRequest} request GetStaffImportConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffImportConfig>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffDataService#importStaffData}.
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @typedef ImportStaffDataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls ImportStaffData.
                     * @function importStaffData
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IImportStaffDataRequest} request ImportStaffDataRequest message or plain object
                     * @param {palexy.streaming.v1.StaffDataService.ImportStaffDataCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffDataService.prototype.importStaffData = function importStaffData(request, callback) {
                        return this.rpcCall(importStaffData, $root.palexy.streaming.v1.ImportStaffDataRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "ImportStaffData" });
    
                    /**
                     * Calls ImportStaffData.
                     * @function importStaffData
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IImportStaffDataRequest} request ImportStaffDataRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffDataService#listStaffAllocation}.
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @typedef ListStaffAllocationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStaffAllocationResponse} [response] ListStaffAllocationResponse
                     */
    
                    /**
                     * Calls ListStaffAllocation.
                     * @function listStaffAllocation
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffAllocationRequest} request ListStaffAllocationRequest message or plain object
                     * @param {palexy.streaming.v1.StaffDataService.ListStaffAllocationCallback} callback Node-style callback called with the error, if any, and ListStaffAllocationResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffDataService.prototype.listStaffAllocation = function listStaffAllocation(request, callback) {
                        return this.rpcCall(listStaffAllocation, $root.palexy.streaming.v1.ListStaffAllocationRequest, $root.palexy.streaming.v1.ListStaffAllocationResponse, request, callback);
                    }, "name", { value: "ListStaffAllocation" });
    
                    /**
                     * Calls ListStaffAllocation.
                     * @function listStaffAllocation
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffAllocationRequest} request ListStaffAllocationRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStaffAllocationResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffDataService#getStaffImportConfigByCompanyId}.
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @typedef GetStaffImportConfigByCompanyIdCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffImportConfig} [response] StaffImportConfig
                     */
    
                    /**
                     * Calls GetStaffImportConfigByCompanyId.
                     * @function getStaffImportConfigByCompanyId
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffImportConfigByCompanyIdRequest} request GetStaffImportConfigByCompanyIdRequest message or plain object
                     * @param {palexy.streaming.v1.StaffDataService.GetStaffImportConfigByCompanyIdCallback} callback Node-style callback called with the error, if any, and StaffImportConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffDataService.prototype.getStaffImportConfigByCompanyId = function getStaffImportConfigByCompanyId(request, callback) {
                        return this.rpcCall(getStaffImportConfigByCompanyId, $root.palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest, $root.palexy.streaming.v1.StaffImportConfig, request, callback);
                    }, "name", { value: "GetStaffImportConfigByCompanyId" });
    
                    /**
                     * Calls GetStaffImportConfigByCompanyId.
                     * @function getStaffImportConfigByCompanyId
                     * @memberof palexy.streaming.v1.StaffDataService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffImportConfigByCompanyIdRequest} request GetStaffImportConfigByCompanyIdRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffImportConfig>} Promise
                     * @variation 2
                     */
    
                    return StaffDataService;
                })();
    
                v1.ListStaffAllocationRequest = (function() {
    
                    /**
                     * Properties of a ListStaffAllocationRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffAllocationRequest
                     * @property {Array.<number>|null} [storeIds] ListStaffAllocationRequest storeIds
                     * @property {number|null} [startDateId] ListStaffAllocationRequest startDateId
                     * @property {number|null} [endDateId] ListStaffAllocationRequest endDateId
                     * @property {number|null} [pageSize] ListStaffAllocationRequest pageSize
                     * @property {string|null} [pageToken] ListStaffAllocationRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListStaffAllocationRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffAllocationRequest.
                     * @implements IListStaffAllocationRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffAllocationRequest=} [properties] Properties to set
                     */
                    function ListStaffAllocationRequest(properties) {
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffAllocationRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @instance
                     */
                    ListStaffAllocationRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * ListStaffAllocationRequest startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @instance
                     */
                    ListStaffAllocationRequest.prototype.startDateId = 0;
    
                    /**
                     * ListStaffAllocationRequest endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @instance
                     */
                    ListStaffAllocationRequest.prototype.endDateId = 0;
    
                    /**
                     * ListStaffAllocationRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @instance
                     */
                    ListStaffAllocationRequest.prototype.pageSize = 0;
    
                    /**
                     * ListStaffAllocationRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @instance
                     */
                    ListStaffAllocationRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListStaffAllocationRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffAllocationRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffAllocationRequest} ListStaffAllocationRequest instance
                     */
                    ListStaffAllocationRequest.create = function create(properties) {
                        return new ListStaffAllocationRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffAllocationRequest message. Does not implicitly {@link palexy.streaming.v1.ListStaffAllocationRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffAllocationRequest} message ListStaffAllocationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffAllocationRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.endDateId);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffAllocationRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffAllocationRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffAllocationRequest} message ListStaffAllocationRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffAllocationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffAllocationRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffAllocationRequest} ListStaffAllocationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffAllocationRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffAllocationRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 2:
                                message.startDateId = reader.int32();
                                break;
                            case 3:
                                message.endDateId = reader.int32();
                                break;
                            case 4:
                                message.pageSize = reader.int32();
                                break;
                            case 5:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffAllocationRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffAllocationRequest} ListStaffAllocationRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffAllocationRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffAllocationRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffAllocationRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffAllocationRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffAllocationRequest} ListStaffAllocationRequest
                     */
                    ListStaffAllocationRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffAllocationRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffAllocationRequest();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.streaming.v1.ListStaffAllocationRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffAllocationRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStaffAllocationRequest} message ListStaffAllocationRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffAllocationRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeIds = [];
                        if (options.defaults) {
                            object.startDateId = 0;
                            object.endDateId = 0;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffAllocationRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffAllocationRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffAllocationRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffAllocationRequest;
                })();
    
                v1.ListStaffAllocationResponse = (function() {
    
                    /**
                     * Properties of a ListStaffAllocationResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffAllocationResponse
                     * @property {Array.<palexy.streaming.v1.IParsedStaffAllocation>|null} [allocations] ListStaffAllocationResponse allocations
                     * @property {string|null} [nextPageToken] ListStaffAllocationResponse nextPageToken
                     * @property {number|null} [totalElements] ListStaffAllocationResponse totalElements
                     */
    
                    /**
                     * Constructs a new ListStaffAllocationResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffAllocationResponse.
                     * @implements IListStaffAllocationResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffAllocationResponse=} [properties] Properties to set
                     */
                    function ListStaffAllocationResponse(properties) {
                        this.allocations = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffAllocationResponse allocations.
                     * @member {Array.<palexy.streaming.v1.IParsedStaffAllocation>} allocations
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @instance
                     */
                    ListStaffAllocationResponse.prototype.allocations = $util.emptyArray;
    
                    /**
                     * ListStaffAllocationResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @instance
                     */
                    ListStaffAllocationResponse.prototype.nextPageToken = "";
    
                    /**
                     * ListStaffAllocationResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @instance
                     */
                    ListStaffAllocationResponse.prototype.totalElements = 0;
    
                    /**
                     * Creates a new ListStaffAllocationResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffAllocationResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffAllocationResponse} ListStaffAllocationResponse instance
                     */
                    ListStaffAllocationResponse.create = function create(properties) {
                        return new ListStaffAllocationResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffAllocationResponse message. Does not implicitly {@link palexy.streaming.v1.ListStaffAllocationResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffAllocationResponse} message ListStaffAllocationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffAllocationResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.allocations != null && message.allocations.length)
                            for (var i = 0; i < message.allocations.length; ++i)
                                $root.palexy.streaming.v1.ParsedStaffAllocation.encode(message.allocations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffAllocationResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffAllocationResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffAllocationResponse} message ListStaffAllocationResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffAllocationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffAllocationResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffAllocationResponse} ListStaffAllocationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffAllocationResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffAllocationResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.allocations && message.allocations.length))
                                    message.allocations = [];
                                message.allocations.push($root.palexy.streaming.v1.ParsedStaffAllocation.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            case 3:
                                message.totalElements = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffAllocationResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffAllocationResponse} ListStaffAllocationResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffAllocationResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffAllocationResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffAllocationResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.allocations != null && message.hasOwnProperty("allocations")) {
                            if (!Array.isArray(message.allocations))
                                return "allocations: array expected";
                            for (var i = 0; i < message.allocations.length; ++i) {
                                var error = $root.palexy.streaming.v1.ParsedStaffAllocation.verify(message.allocations[i]);
                                if (error)
                                    return "allocations." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffAllocationResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffAllocationResponse} ListStaffAllocationResponse
                     */
                    ListStaffAllocationResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffAllocationResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffAllocationResponse();
                        if (object.allocations) {
                            if (!Array.isArray(object.allocations))
                                throw TypeError(".palexy.streaming.v1.ListStaffAllocationResponse.allocations: array expected");
                            message.allocations = [];
                            for (var i = 0; i < object.allocations.length; ++i) {
                                if (typeof object.allocations[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStaffAllocationResponse.allocations: object expected");
                                message.allocations[i] = $root.palexy.streaming.v1.ParsedStaffAllocation.fromObject(object.allocations[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffAllocationResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStaffAllocationResponse} message ListStaffAllocationResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffAllocationResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.allocations = [];
                        if (options.defaults) {
                            object.nextPageToken = "";
                            object.totalElements = 0;
                        }
                        if (message.allocations && message.allocations.length) {
                            object.allocations = [];
                            for (var j = 0; j < message.allocations.length; ++j)
                                object.allocations[j] = $root.palexy.streaming.v1.ParsedStaffAllocation.toObject(message.allocations[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffAllocationResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffAllocationResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffAllocationResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffAllocationResponse;
                })();
    
                v1.GetStaffImportConfigByCompanyIdRequest = (function() {
    
                    /**
                     * Properties of a GetStaffImportConfigByCompanyIdRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStaffImportConfigByCompanyIdRequest
                     * @property {number|null} [companyId] GetStaffImportConfigByCompanyIdRequest companyId
                     */
    
                    /**
                     * Constructs a new GetStaffImportConfigByCompanyIdRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStaffImportConfigByCompanyIdRequest.
                     * @implements IGetStaffImportConfigByCompanyIdRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStaffImportConfigByCompanyIdRequest=} [properties] Properties to set
                     */
                    function GetStaffImportConfigByCompanyIdRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStaffImportConfigByCompanyIdRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest
                     * @instance
                     */
                    GetStaffImportConfigByCompanyIdRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetStaffImportConfigByCompanyIdRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffImportConfigByCompanyIdRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest} GetStaffImportConfigByCompanyIdRequest instance
                     */
                    GetStaffImportConfigByCompanyIdRequest.create = function create(properties) {
                        return new GetStaffImportConfigByCompanyIdRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStaffImportConfigByCompanyIdRequest message. Does not implicitly {@link palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffImportConfigByCompanyIdRequest} message GetStaffImportConfigByCompanyIdRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffImportConfigByCompanyIdRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStaffImportConfigByCompanyIdRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffImportConfigByCompanyIdRequest} message GetStaffImportConfigByCompanyIdRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffImportConfigByCompanyIdRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStaffImportConfigByCompanyIdRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest} GetStaffImportConfigByCompanyIdRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffImportConfigByCompanyIdRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStaffImportConfigByCompanyIdRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest} GetStaffImportConfigByCompanyIdRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffImportConfigByCompanyIdRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStaffImportConfigByCompanyIdRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStaffImportConfigByCompanyIdRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStaffImportConfigByCompanyIdRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest} GetStaffImportConfigByCompanyIdRequest
                     */
                    GetStaffImportConfigByCompanyIdRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStaffImportConfigByCompanyIdRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest} message GetStaffImportConfigByCompanyIdRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStaffImportConfigByCompanyIdRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        return object;
                    };
    
                    /**
                     * Converts this GetStaffImportConfigByCompanyIdRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStaffImportConfigByCompanyIdRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStaffImportConfigByCompanyIdRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStaffImportConfigByCompanyIdRequest;
                })();
    
                v1.StaffImportConfig = (function() {
    
                    /**
                     * Properties of a StaffImportConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IStaffImportConfig
                     * @property {number|null} [id] StaffImportConfig id
                     * @property {number|null} [companyId] StaffImportConfig companyId
                     * @property {string|null} [apiUsername] StaffImportConfig apiUsername
                     * @property {string|null} [apiPassword] StaffImportConfig apiPassword
                     * @property {string|null} [apiIntegrationKey] StaffImportConfig apiIntegrationKey
                     * @property {string|null} [apiBaseUrl] StaffImportConfig apiBaseUrl
                     * @property {number|null} [lastEditorAccountId] StaffImportConfig lastEditorAccountId
                     * @property {number|null} [createdTimeMs] StaffImportConfig createdTimeMs
                     * @property {number|null} [lastUpdatedTimeMs] StaffImportConfig lastUpdatedTimeMs
                     * @property {Array.<string>|null} [includedRoles] StaffImportConfig includedRoles
                     * @property {Array.<string>|null} [excludedRoles] StaffImportConfig excludedRoles
                     * @property {Array.<string>|null} [includedSecondRoles] StaffImportConfig includedSecondRoles
                     * @property {string|null} [companyName] StaffImportConfig companyName
                     * @property {palexy.streaming.v1.IAsyncJobStatusSummary|null} [manualJobStatusSummary] StaffImportConfig manualJobStatusSummary
                     * @property {palexy.streaming.v1.IAsyncJobStatusSummary|null} [autoJobStatusSummary] StaffImportConfig autoJobStatusSummary
                     */
    
                    /**
                     * Constructs a new StaffImportConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffImportConfig.
                     * @implements IStaffImportConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IStaffImportConfig=} [properties] Properties to set
                     */
                    function StaffImportConfig(properties) {
                        this.includedRoles = [];
                        this.excludedRoles = [];
                        this.includedSecondRoles = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StaffImportConfig id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffImportConfig companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffImportConfig apiUsername.
                     * @member {string} apiUsername
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.apiUsername = "";
    
                    /**
                     * StaffImportConfig apiPassword.
                     * @member {string} apiPassword
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.apiPassword = "";
    
                    /**
                     * StaffImportConfig apiIntegrationKey.
                     * @member {string} apiIntegrationKey
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.apiIntegrationKey = "";
    
                    /**
                     * StaffImportConfig apiBaseUrl.
                     * @member {string} apiBaseUrl
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.apiBaseUrl = "";
    
                    /**
                     * StaffImportConfig lastEditorAccountId.
                     * @member {number} lastEditorAccountId
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.lastEditorAccountId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffImportConfig createdTimeMs.
                     * @member {number} createdTimeMs
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.createdTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffImportConfig lastUpdatedTimeMs.
                     * @member {number} lastUpdatedTimeMs
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.lastUpdatedTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffImportConfig includedRoles.
                     * @member {Array.<string>} includedRoles
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.includedRoles = $util.emptyArray;
    
                    /**
                     * StaffImportConfig excludedRoles.
                     * @member {Array.<string>} excludedRoles
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.excludedRoles = $util.emptyArray;
    
                    /**
                     * StaffImportConfig includedSecondRoles.
                     * @member {Array.<string>} includedSecondRoles
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.includedSecondRoles = $util.emptyArray;
    
                    /**
                     * StaffImportConfig companyName.
                     * @member {string} companyName
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.companyName = "";
    
                    /**
                     * StaffImportConfig manualJobStatusSummary.
                     * @member {palexy.streaming.v1.IAsyncJobStatusSummary|null|undefined} manualJobStatusSummary
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.manualJobStatusSummary = null;
    
                    /**
                     * StaffImportConfig autoJobStatusSummary.
                     * @member {palexy.streaming.v1.IAsyncJobStatusSummary|null|undefined} autoJobStatusSummary
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     */
                    StaffImportConfig.prototype.autoJobStatusSummary = null;
    
                    /**
                     * Creates a new StaffImportConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @static
                     * @param {palexy.streaming.v1.IStaffImportConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StaffImportConfig} StaffImportConfig instance
                     */
                    StaffImportConfig.create = function create(properties) {
                        return new StaffImportConfig(properties);
                    };
    
                    /**
                     * Encodes the specified StaffImportConfig message. Does not implicitly {@link palexy.streaming.v1.StaffImportConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @static
                     * @param {palexy.streaming.v1.IStaffImportConfig} message StaffImportConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffImportConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.apiUsername != null && Object.hasOwnProperty.call(message, "apiUsername"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.apiUsername);
                        if (message.apiPassword != null && Object.hasOwnProperty.call(message, "apiPassword"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.apiPassword);
                        if (message.apiIntegrationKey != null && Object.hasOwnProperty.call(message, "apiIntegrationKey"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.apiIntegrationKey);
                        if (message.apiBaseUrl != null && Object.hasOwnProperty.call(message, "apiBaseUrl"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.apiBaseUrl);
                        if (message.lastEditorAccountId != null && Object.hasOwnProperty.call(message, "lastEditorAccountId"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.lastEditorAccountId);
                        if (message.createdTimeMs != null && Object.hasOwnProperty.call(message, "createdTimeMs"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.createdTimeMs);
                        if (message.lastUpdatedTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdatedTimeMs"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int64(message.lastUpdatedTimeMs);
                        if (message.companyName != null && Object.hasOwnProperty.call(message, "companyName"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.companyName);
                        if (message.includedRoles != null && message.includedRoles.length)
                            for (var i = 0; i < message.includedRoles.length; ++i)
                                writer.uint32(/* id 12, wireType 2 =*/98).string(message.includedRoles[i]);
                        if (message.excludedRoles != null && message.excludedRoles.length)
                            for (var i = 0; i < message.excludedRoles.length; ++i)
                                writer.uint32(/* id 13, wireType 2 =*/106).string(message.excludedRoles[i]);
                        if (message.manualJobStatusSummary != null && Object.hasOwnProperty.call(message, "manualJobStatusSummary"))
                            $root.palexy.streaming.v1.AsyncJobStatusSummary.encode(message.manualJobStatusSummary, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                        if (message.autoJobStatusSummary != null && Object.hasOwnProperty.call(message, "autoJobStatusSummary"))
                            $root.palexy.streaming.v1.AsyncJobStatusSummary.encode(message.autoJobStatusSummary, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                        if (message.includedSecondRoles != null && message.includedSecondRoles.length)
                            for (var i = 0; i < message.includedSecondRoles.length; ++i)
                                writer.uint32(/* id 16, wireType 2 =*/130).string(message.includedSecondRoles[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StaffImportConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.StaffImportConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @static
                     * @param {palexy.streaming.v1.IStaffImportConfig} message StaffImportConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffImportConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StaffImportConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StaffImportConfig} StaffImportConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffImportConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StaffImportConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.apiUsername = reader.string();
                                break;
                            case 4:
                                message.apiPassword = reader.string();
                                break;
                            case 5:
                                message.apiIntegrationKey = reader.string();
                                break;
                            case 6:
                                message.apiBaseUrl = reader.string();
                                break;
                            case 7:
                                message.lastEditorAccountId = reader.int64();
                                break;
                            case 8:
                                message.createdTimeMs = reader.int64();
                                break;
                            case 9:
                                message.lastUpdatedTimeMs = reader.int64();
                                break;
                            case 12:
                                if (!(message.includedRoles && message.includedRoles.length))
                                    message.includedRoles = [];
                                message.includedRoles.push(reader.string());
                                break;
                            case 13:
                                if (!(message.excludedRoles && message.excludedRoles.length))
                                    message.excludedRoles = [];
                                message.excludedRoles.push(reader.string());
                                break;
                            case 16:
                                if (!(message.includedSecondRoles && message.includedSecondRoles.length))
                                    message.includedSecondRoles = [];
                                message.includedSecondRoles.push(reader.string());
                                break;
                            case 10:
                                message.companyName = reader.string();
                                break;
                            case 14:
                                message.manualJobStatusSummary = $root.palexy.streaming.v1.AsyncJobStatusSummary.decode(reader, reader.uint32());
                                break;
                            case 15:
                                message.autoJobStatusSummary = $root.palexy.streaming.v1.AsyncJobStatusSummary.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StaffImportConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StaffImportConfig} StaffImportConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffImportConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StaffImportConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StaffImportConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.apiUsername != null && message.hasOwnProperty("apiUsername"))
                            if (!$util.isString(message.apiUsername))
                                return "apiUsername: string expected";
                        if (message.apiPassword != null && message.hasOwnProperty("apiPassword"))
                            if (!$util.isString(message.apiPassword))
                                return "apiPassword: string expected";
                        if (message.apiIntegrationKey != null && message.hasOwnProperty("apiIntegrationKey"))
                            if (!$util.isString(message.apiIntegrationKey))
                                return "apiIntegrationKey: string expected";
                        if (message.apiBaseUrl != null && message.hasOwnProperty("apiBaseUrl"))
                            if (!$util.isString(message.apiBaseUrl))
                                return "apiBaseUrl: string expected";
                        if (message.lastEditorAccountId != null && message.hasOwnProperty("lastEditorAccountId"))
                            if (!$util.isInteger(message.lastEditorAccountId) && !(message.lastEditorAccountId && $util.isInteger(message.lastEditorAccountId.low) && $util.isInteger(message.lastEditorAccountId.high)))
                                return "lastEditorAccountId: integer|Long expected";
                        if (message.createdTimeMs != null && message.hasOwnProperty("createdTimeMs"))
                            if (!$util.isInteger(message.createdTimeMs) && !(message.createdTimeMs && $util.isInteger(message.createdTimeMs.low) && $util.isInteger(message.createdTimeMs.high)))
                                return "createdTimeMs: integer|Long expected";
                        if (message.lastUpdatedTimeMs != null && message.hasOwnProperty("lastUpdatedTimeMs"))
                            if (!$util.isInteger(message.lastUpdatedTimeMs) && !(message.lastUpdatedTimeMs && $util.isInteger(message.lastUpdatedTimeMs.low) && $util.isInteger(message.lastUpdatedTimeMs.high)))
                                return "lastUpdatedTimeMs: integer|Long expected";
                        if (message.includedRoles != null && message.hasOwnProperty("includedRoles")) {
                            if (!Array.isArray(message.includedRoles))
                                return "includedRoles: array expected";
                            for (var i = 0; i < message.includedRoles.length; ++i)
                                if (!$util.isString(message.includedRoles[i]))
                                    return "includedRoles: string[] expected";
                        }
                        if (message.excludedRoles != null && message.hasOwnProperty("excludedRoles")) {
                            if (!Array.isArray(message.excludedRoles))
                                return "excludedRoles: array expected";
                            for (var i = 0; i < message.excludedRoles.length; ++i)
                                if (!$util.isString(message.excludedRoles[i]))
                                    return "excludedRoles: string[] expected";
                        }
                        if (message.includedSecondRoles != null && message.hasOwnProperty("includedSecondRoles")) {
                            if (!Array.isArray(message.includedSecondRoles))
                                return "includedSecondRoles: array expected";
                            for (var i = 0; i < message.includedSecondRoles.length; ++i)
                                if (!$util.isString(message.includedSecondRoles[i]))
                                    return "includedSecondRoles: string[] expected";
                        }
                        if (message.companyName != null && message.hasOwnProperty("companyName"))
                            if (!$util.isString(message.companyName))
                                return "companyName: string expected";
                        if (message.manualJobStatusSummary != null && message.hasOwnProperty("manualJobStatusSummary")) {
                            var error = $root.palexy.streaming.v1.AsyncJobStatusSummary.verify(message.manualJobStatusSummary);
                            if (error)
                                return "manualJobStatusSummary." + error;
                        }
                        if (message.autoJobStatusSummary != null && message.hasOwnProperty("autoJobStatusSummary")) {
                            var error = $root.palexy.streaming.v1.AsyncJobStatusSummary.verify(message.autoJobStatusSummary);
                            if (error)
                                return "autoJobStatusSummary." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a StaffImportConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StaffImportConfig} StaffImportConfig
                     */
                    StaffImportConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StaffImportConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.StaffImportConfig();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.apiUsername != null)
                            message.apiUsername = String(object.apiUsername);
                        if (object.apiPassword != null)
                            message.apiPassword = String(object.apiPassword);
                        if (object.apiIntegrationKey != null)
                            message.apiIntegrationKey = String(object.apiIntegrationKey);
                        if (object.apiBaseUrl != null)
                            message.apiBaseUrl = String(object.apiBaseUrl);
                        if (object.lastEditorAccountId != null)
                            if ($util.Long)
                                (message.lastEditorAccountId = $util.Long.fromValue(object.lastEditorAccountId)).unsigned = false;
                            else if (typeof object.lastEditorAccountId === "string")
                                message.lastEditorAccountId = parseInt(object.lastEditorAccountId, 10);
                            else if (typeof object.lastEditorAccountId === "number")
                                message.lastEditorAccountId = object.lastEditorAccountId;
                            else if (typeof object.lastEditorAccountId === "object")
                                message.lastEditorAccountId = new $util.LongBits(object.lastEditorAccountId.low >>> 0, object.lastEditorAccountId.high >>> 0).toNumber();
                        if (object.createdTimeMs != null)
                            if ($util.Long)
                                (message.createdTimeMs = $util.Long.fromValue(object.createdTimeMs)).unsigned = false;
                            else if (typeof object.createdTimeMs === "string")
                                message.createdTimeMs = parseInt(object.createdTimeMs, 10);
                            else if (typeof object.createdTimeMs === "number")
                                message.createdTimeMs = object.createdTimeMs;
                            else if (typeof object.createdTimeMs === "object")
                                message.createdTimeMs = new $util.LongBits(object.createdTimeMs.low >>> 0, object.createdTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdatedTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdatedTimeMs = $util.Long.fromValue(object.lastUpdatedTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdatedTimeMs === "string")
                                message.lastUpdatedTimeMs = parseInt(object.lastUpdatedTimeMs, 10);
                            else if (typeof object.lastUpdatedTimeMs === "number")
                                message.lastUpdatedTimeMs = object.lastUpdatedTimeMs;
                            else if (typeof object.lastUpdatedTimeMs === "object")
                                message.lastUpdatedTimeMs = new $util.LongBits(object.lastUpdatedTimeMs.low >>> 0, object.lastUpdatedTimeMs.high >>> 0).toNumber();
                        if (object.includedRoles) {
                            if (!Array.isArray(object.includedRoles))
                                throw TypeError(".palexy.streaming.v1.StaffImportConfig.includedRoles: array expected");
                            message.includedRoles = [];
                            for (var i = 0; i < object.includedRoles.length; ++i)
                                message.includedRoles[i] = String(object.includedRoles[i]);
                        }
                        if (object.excludedRoles) {
                            if (!Array.isArray(object.excludedRoles))
                                throw TypeError(".palexy.streaming.v1.StaffImportConfig.excludedRoles: array expected");
                            message.excludedRoles = [];
                            for (var i = 0; i < object.excludedRoles.length; ++i)
                                message.excludedRoles[i] = String(object.excludedRoles[i]);
                        }
                        if (object.includedSecondRoles) {
                            if (!Array.isArray(object.includedSecondRoles))
                                throw TypeError(".palexy.streaming.v1.StaffImportConfig.includedSecondRoles: array expected");
                            message.includedSecondRoles = [];
                            for (var i = 0; i < object.includedSecondRoles.length; ++i)
                                message.includedSecondRoles[i] = String(object.includedSecondRoles[i]);
                        }
                        if (object.companyName != null)
                            message.companyName = String(object.companyName);
                        if (object.manualJobStatusSummary != null) {
                            if (typeof object.manualJobStatusSummary !== "object")
                                throw TypeError(".palexy.streaming.v1.StaffImportConfig.manualJobStatusSummary: object expected");
                            message.manualJobStatusSummary = $root.palexy.streaming.v1.AsyncJobStatusSummary.fromObject(object.manualJobStatusSummary);
                        }
                        if (object.autoJobStatusSummary != null) {
                            if (typeof object.autoJobStatusSummary !== "object")
                                throw TypeError(".palexy.streaming.v1.StaffImportConfig.autoJobStatusSummary: object expected");
                            message.autoJobStatusSummary = $root.palexy.streaming.v1.AsyncJobStatusSummary.fromObject(object.autoJobStatusSummary);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StaffImportConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @static
                     * @param {palexy.streaming.v1.StaffImportConfig} message StaffImportConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StaffImportConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.includedRoles = [];
                            object.excludedRoles = [];
                            object.includedSecondRoles = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.apiUsername = "";
                            object.apiPassword = "";
                            object.apiIntegrationKey = "";
                            object.apiBaseUrl = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastEditorAccountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastEditorAccountId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createdTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createdTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdatedTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdatedTimeMs = options.longs === String ? "0" : 0;
                            object.companyName = "";
                            object.manualJobStatusSummary = null;
                            object.autoJobStatusSummary = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.apiUsername != null && message.hasOwnProperty("apiUsername"))
                            object.apiUsername = message.apiUsername;
                        if (message.apiPassword != null && message.hasOwnProperty("apiPassword"))
                            object.apiPassword = message.apiPassword;
                        if (message.apiIntegrationKey != null && message.hasOwnProperty("apiIntegrationKey"))
                            object.apiIntegrationKey = message.apiIntegrationKey;
                        if (message.apiBaseUrl != null && message.hasOwnProperty("apiBaseUrl"))
                            object.apiBaseUrl = message.apiBaseUrl;
                        if (message.lastEditorAccountId != null && message.hasOwnProperty("lastEditorAccountId"))
                            if (typeof message.lastEditorAccountId === "number")
                                object.lastEditorAccountId = options.longs === String ? String(message.lastEditorAccountId) : message.lastEditorAccountId;
                            else
                                object.lastEditorAccountId = options.longs === String ? $util.Long.prototype.toString.call(message.lastEditorAccountId) : options.longs === Number ? new $util.LongBits(message.lastEditorAccountId.low >>> 0, message.lastEditorAccountId.high >>> 0).toNumber() : message.lastEditorAccountId;
                        if (message.createdTimeMs != null && message.hasOwnProperty("createdTimeMs"))
                            if (typeof message.createdTimeMs === "number")
                                object.createdTimeMs = options.longs === String ? String(message.createdTimeMs) : message.createdTimeMs;
                            else
                                object.createdTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createdTimeMs) : options.longs === Number ? new $util.LongBits(message.createdTimeMs.low >>> 0, message.createdTimeMs.high >>> 0).toNumber() : message.createdTimeMs;
                        if (message.lastUpdatedTimeMs != null && message.hasOwnProperty("lastUpdatedTimeMs"))
                            if (typeof message.lastUpdatedTimeMs === "number")
                                object.lastUpdatedTimeMs = options.longs === String ? String(message.lastUpdatedTimeMs) : message.lastUpdatedTimeMs;
                            else
                                object.lastUpdatedTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdatedTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdatedTimeMs.low >>> 0, message.lastUpdatedTimeMs.high >>> 0).toNumber() : message.lastUpdatedTimeMs;
                        if (message.companyName != null && message.hasOwnProperty("companyName"))
                            object.companyName = message.companyName;
                        if (message.includedRoles && message.includedRoles.length) {
                            object.includedRoles = [];
                            for (var j = 0; j < message.includedRoles.length; ++j)
                                object.includedRoles[j] = message.includedRoles[j];
                        }
                        if (message.excludedRoles && message.excludedRoles.length) {
                            object.excludedRoles = [];
                            for (var j = 0; j < message.excludedRoles.length; ++j)
                                object.excludedRoles[j] = message.excludedRoles[j];
                        }
                        if (message.manualJobStatusSummary != null && message.hasOwnProperty("manualJobStatusSummary"))
                            object.manualJobStatusSummary = $root.palexy.streaming.v1.AsyncJobStatusSummary.toObject(message.manualJobStatusSummary, options);
                        if (message.autoJobStatusSummary != null && message.hasOwnProperty("autoJobStatusSummary"))
                            object.autoJobStatusSummary = $root.palexy.streaming.v1.AsyncJobStatusSummary.toObject(message.autoJobStatusSummary, options);
                        if (message.includedSecondRoles && message.includedSecondRoles.length) {
                            object.includedSecondRoles = [];
                            for (var j = 0; j < message.includedSecondRoles.length; ++j)
                                object.includedSecondRoles[j] = message.includedSecondRoles[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this StaffImportConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StaffImportConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StaffImportConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StaffImportConfig;
                })();
    
                v1.CreateStaffImportConfigRequest = (function() {
    
                    /**
                     * Properties of a CreateStaffImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateStaffImportConfigRequest
                     * @property {palexy.streaming.v1.IStaffImportConfig|null} [config] CreateStaffImportConfigRequest config
                     */
    
                    /**
                     * Constructs a new CreateStaffImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateStaffImportConfigRequest.
                     * @implements ICreateStaffImportConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateStaffImportConfigRequest=} [properties] Properties to set
                     */
                    function CreateStaffImportConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateStaffImportConfigRequest config.
                     * @member {palexy.streaming.v1.IStaffImportConfig|null|undefined} config
                     * @memberof palexy.streaming.v1.CreateStaffImportConfigRequest
                     * @instance
                     */
                    CreateStaffImportConfigRequest.prototype.config = null;
    
                    /**
                     * Creates a new CreateStaffImportConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffImportConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateStaffImportConfigRequest} CreateStaffImportConfigRequest instance
                     */
                    CreateStaffImportConfigRequest.create = function create(properties) {
                        return new CreateStaffImportConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateStaffImportConfigRequest message. Does not implicitly {@link palexy.streaming.v1.CreateStaffImportConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffImportConfigRequest} message CreateStaffImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStaffImportConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                            $root.palexy.streaming.v1.StaffImportConfig.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateStaffImportConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateStaffImportConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffImportConfigRequest} message CreateStaffImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStaffImportConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateStaffImportConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateStaffImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateStaffImportConfigRequest} CreateStaffImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStaffImportConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateStaffImportConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.config = $root.palexy.streaming.v1.StaffImportConfig.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateStaffImportConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateStaffImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateStaffImportConfigRequest} CreateStaffImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStaffImportConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateStaffImportConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateStaffImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateStaffImportConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.config != null && message.hasOwnProperty("config")) {
                            var error = $root.palexy.streaming.v1.StaffImportConfig.verify(message.config);
                            if (error)
                                return "config." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateStaffImportConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateStaffImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateStaffImportConfigRequest} CreateStaffImportConfigRequest
                     */
                    CreateStaffImportConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateStaffImportConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateStaffImportConfigRequest();
                        if (object.config != null) {
                            if (typeof object.config !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateStaffImportConfigRequest.config: object expected");
                            message.config = $root.palexy.streaming.v1.StaffImportConfig.fromObject(object.config);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateStaffImportConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateStaffImportConfigRequest} message CreateStaffImportConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateStaffImportConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.config = null;
                        if (message.config != null && message.hasOwnProperty("config"))
                            object.config = $root.palexy.streaming.v1.StaffImportConfig.toObject(message.config, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateStaffImportConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateStaffImportConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateStaffImportConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateStaffImportConfigRequest;
                })();
    
                v1.UpdateStaffImportConfigRequest = (function() {
    
                    /**
                     * Properties of an UpdateStaffImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateStaffImportConfigRequest
                     * @property {palexy.streaming.v1.IStaffImportConfig|null} [config] UpdateStaffImportConfigRequest config
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateStaffImportConfigRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateStaffImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateStaffImportConfigRequest.
                     * @implements IUpdateStaffImportConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateStaffImportConfigRequest=} [properties] Properties to set
                     */
                    function UpdateStaffImportConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateStaffImportConfigRequest config.
                     * @member {palexy.streaming.v1.IStaffImportConfig|null|undefined} config
                     * @memberof palexy.streaming.v1.UpdateStaffImportConfigRequest
                     * @instance
                     */
                    UpdateStaffImportConfigRequest.prototype.config = null;
    
                    /**
                     * UpdateStaffImportConfigRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateStaffImportConfigRequest
                     * @instance
                     */
                    UpdateStaffImportConfigRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateStaffImportConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffImportConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateStaffImportConfigRequest} UpdateStaffImportConfigRequest instance
                     */
                    UpdateStaffImportConfigRequest.create = function create(properties) {
                        return new UpdateStaffImportConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateStaffImportConfigRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateStaffImportConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffImportConfigRequest} message UpdateStaffImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStaffImportConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                            $root.palexy.streaming.v1.StaffImportConfig.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateStaffImportConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateStaffImportConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffImportConfigRequest} message UpdateStaffImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStaffImportConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateStaffImportConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateStaffImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateStaffImportConfigRequest} UpdateStaffImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStaffImportConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateStaffImportConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.config = $root.palexy.streaming.v1.StaffImportConfig.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateStaffImportConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStaffImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateStaffImportConfigRequest} UpdateStaffImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStaffImportConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateStaffImportConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateStaffImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateStaffImportConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.config != null && message.hasOwnProperty("config")) {
                            var error = $root.palexy.streaming.v1.StaffImportConfig.verify(message.config);
                            if (error)
                                return "config." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateStaffImportConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateStaffImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateStaffImportConfigRequest} UpdateStaffImportConfigRequest
                     */
                    UpdateStaffImportConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateStaffImportConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateStaffImportConfigRequest();
                        if (object.config != null) {
                            if (typeof object.config !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStaffImportConfigRequest.config: object expected");
                            message.config = $root.palexy.streaming.v1.StaffImportConfig.fromObject(object.config);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStaffImportConfigRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateStaffImportConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateStaffImportConfigRequest} message UpdateStaffImportConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateStaffImportConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.config = null;
                            object.updateMask = null;
                        }
                        if (message.config != null && message.hasOwnProperty("config"))
                            object.config = $root.palexy.streaming.v1.StaffImportConfig.toObject(message.config, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateStaffImportConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateStaffImportConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateStaffImportConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateStaffImportConfigRequest;
                })();
    
                v1.DeleteStaffImportConfigRequest = (function() {
    
                    /**
                     * Properties of a DeleteStaffImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteStaffImportConfigRequest
                     * @property {number|null} [configId] DeleteStaffImportConfigRequest configId
                     */
    
                    /**
                     * Constructs a new DeleteStaffImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteStaffImportConfigRequest.
                     * @implements IDeleteStaffImportConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteStaffImportConfigRequest=} [properties] Properties to set
                     */
                    function DeleteStaffImportConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteStaffImportConfigRequest configId.
                     * @member {number} configId
                     * @memberof palexy.streaming.v1.DeleteStaffImportConfigRequest
                     * @instance
                     */
                    DeleteStaffImportConfigRequest.prototype.configId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteStaffImportConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStaffImportConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteStaffImportConfigRequest} DeleteStaffImportConfigRequest instance
                     */
                    DeleteStaffImportConfigRequest.create = function create(properties) {
                        return new DeleteStaffImportConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteStaffImportConfigRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteStaffImportConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStaffImportConfigRequest} message DeleteStaffImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStaffImportConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.configId != null && Object.hasOwnProperty.call(message, "configId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.configId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteStaffImportConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteStaffImportConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStaffImportConfigRequest} message DeleteStaffImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStaffImportConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteStaffImportConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteStaffImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteStaffImportConfigRequest} DeleteStaffImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStaffImportConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteStaffImportConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.configId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteStaffImportConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteStaffImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteStaffImportConfigRequest} DeleteStaffImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStaffImportConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteStaffImportConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteStaffImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteStaffImportConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.configId != null && message.hasOwnProperty("configId"))
                            if (!$util.isInteger(message.configId) && !(message.configId && $util.isInteger(message.configId.low) && $util.isInteger(message.configId.high)))
                                return "configId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteStaffImportConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteStaffImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteStaffImportConfigRequest} DeleteStaffImportConfigRequest
                     */
                    DeleteStaffImportConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteStaffImportConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteStaffImportConfigRequest();
                        if (object.configId != null)
                            if ($util.Long)
                                (message.configId = $util.Long.fromValue(object.configId)).unsigned = false;
                            else if (typeof object.configId === "string")
                                message.configId = parseInt(object.configId, 10);
                            else if (typeof object.configId === "number")
                                message.configId = object.configId;
                            else if (typeof object.configId === "object")
                                message.configId = new $util.LongBits(object.configId.low >>> 0, object.configId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteStaffImportConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteStaffImportConfigRequest} message DeleteStaffImportConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteStaffImportConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.configId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.configId = options.longs === String ? "0" : 0;
                        if (message.configId != null && message.hasOwnProperty("configId"))
                            if (typeof message.configId === "number")
                                object.configId = options.longs === String ? String(message.configId) : message.configId;
                            else
                                object.configId = options.longs === String ? $util.Long.prototype.toString.call(message.configId) : options.longs === Number ? new $util.LongBits(message.configId.low >>> 0, message.configId.high >>> 0).toNumber() : message.configId;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteStaffImportConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteStaffImportConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteStaffImportConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteStaffImportConfigRequest;
                })();
    
                v1.ListStaffImportConfigRequest = (function() {
    
                    /**
                     * Properties of a ListStaffImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffImportConfigRequest
                     * @property {number|null} [pageSize] ListStaffImportConfigRequest pageSize
                     * @property {string|null} [pageToken] ListStaffImportConfigRequest pageToken
                     * @property {Array.<number>|null} [companyIds] ListStaffImportConfigRequest companyIds
                     * @property {boolean|null} [includeCompanyInfo] ListStaffImportConfigRequest includeCompanyInfo
                     * @property {boolean|null} [fetchJobStatus] ListStaffImportConfigRequest fetchJobStatus
                     */
    
                    /**
                     * Constructs a new ListStaffImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffImportConfigRequest.
                     * @implements IListStaffImportConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffImportConfigRequest=} [properties] Properties to set
                     */
                    function ListStaffImportConfigRequest(properties) {
                        this.companyIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffImportConfigRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @instance
                     */
                    ListStaffImportConfigRequest.prototype.pageSize = 0;
    
                    /**
                     * ListStaffImportConfigRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @instance
                     */
                    ListStaffImportConfigRequest.prototype.pageToken = "";
    
                    /**
                     * ListStaffImportConfigRequest companyIds.
                     * @member {Array.<number>} companyIds
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @instance
                     */
                    ListStaffImportConfigRequest.prototype.companyIds = $util.emptyArray;
    
                    /**
                     * ListStaffImportConfigRequest includeCompanyInfo.
                     * @member {boolean} includeCompanyInfo
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @instance
                     */
                    ListStaffImportConfigRequest.prototype.includeCompanyInfo = false;
    
                    /**
                     * ListStaffImportConfigRequest fetchJobStatus.
                     * @member {boolean} fetchJobStatus
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @instance
                     */
                    ListStaffImportConfigRequest.prototype.fetchJobStatus = false;
    
                    /**
                     * Creates a new ListStaffImportConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffImportConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffImportConfigRequest} ListStaffImportConfigRequest instance
                     */
                    ListStaffImportConfigRequest.create = function create(properties) {
                        return new ListStaffImportConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffImportConfigRequest message. Does not implicitly {@link palexy.streaming.v1.ListStaffImportConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffImportConfigRequest} message ListStaffImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffImportConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pageToken);
                        if (message.companyIds != null && message.companyIds.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.companyIds.length; ++i)
                                writer.int64(message.companyIds[i]);
                            writer.ldelim();
                        }
                        if (message.includeCompanyInfo != null && Object.hasOwnProperty.call(message, "includeCompanyInfo"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeCompanyInfo);
                        if (message.fetchJobStatus != null && Object.hasOwnProperty.call(message, "fetchJobStatus"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.fetchJobStatus);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffImportConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffImportConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffImportConfigRequest} message ListStaffImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffImportConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffImportConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffImportConfigRequest} ListStaffImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffImportConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffImportConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.pageSize = reader.int32();
                                break;
                            case 2:
                                message.pageToken = reader.string();
                                break;
                            case 3:
                                if (!(message.companyIds && message.companyIds.length))
                                    message.companyIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.companyIds.push(reader.int64());
                                } else
                                    message.companyIds.push(reader.int64());
                                break;
                            case 4:
                                message.includeCompanyInfo = reader.bool();
                                break;
                            case 5:
                                message.fetchJobStatus = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffImportConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffImportConfigRequest} ListStaffImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffImportConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffImportConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffImportConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                            if (!Array.isArray(message.companyIds))
                                return "companyIds: array expected";
                            for (var i = 0; i < message.companyIds.length; ++i)
                                if (!$util.isInteger(message.companyIds[i]) && !(message.companyIds[i] && $util.isInteger(message.companyIds[i].low) && $util.isInteger(message.companyIds[i].high)))
                                    return "companyIds: integer|Long[] expected";
                        }
                        if (message.includeCompanyInfo != null && message.hasOwnProperty("includeCompanyInfo"))
                            if (typeof message.includeCompanyInfo !== "boolean")
                                return "includeCompanyInfo: boolean expected";
                        if (message.fetchJobStatus != null && message.hasOwnProperty("fetchJobStatus"))
                            if (typeof message.fetchJobStatus !== "boolean")
                                return "fetchJobStatus: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffImportConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffImportConfigRequest} ListStaffImportConfigRequest
                     */
                    ListStaffImportConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffImportConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffImportConfigRequest();
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.companyIds) {
                            if (!Array.isArray(object.companyIds))
                                throw TypeError(".palexy.streaming.v1.ListStaffImportConfigRequest.companyIds: array expected");
                            message.companyIds = [];
                            for (var i = 0; i < object.companyIds.length; ++i)
                                if ($util.Long)
                                    (message.companyIds[i] = $util.Long.fromValue(object.companyIds[i])).unsigned = false;
                                else if (typeof object.companyIds[i] === "string")
                                    message.companyIds[i] = parseInt(object.companyIds[i], 10);
                                else if (typeof object.companyIds[i] === "number")
                                    message.companyIds[i] = object.companyIds[i];
                                else if (typeof object.companyIds[i] === "object")
                                    message.companyIds[i] = new $util.LongBits(object.companyIds[i].low >>> 0, object.companyIds[i].high >>> 0).toNumber();
                        }
                        if (object.includeCompanyInfo != null)
                            message.includeCompanyInfo = Boolean(object.includeCompanyInfo);
                        if (object.fetchJobStatus != null)
                            message.fetchJobStatus = Boolean(object.fetchJobStatus);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffImportConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStaffImportConfigRequest} message ListStaffImportConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffImportConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.companyIds = [];
                        if (options.defaults) {
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.includeCompanyInfo = false;
                            object.fetchJobStatus = false;
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.companyIds && message.companyIds.length) {
                            object.companyIds = [];
                            for (var j = 0; j < message.companyIds.length; ++j)
                                if (typeof message.companyIds[j] === "number")
                                    object.companyIds[j] = options.longs === String ? String(message.companyIds[j]) : message.companyIds[j];
                                else
                                    object.companyIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.companyIds[j]) : options.longs === Number ? new $util.LongBits(message.companyIds[j].low >>> 0, message.companyIds[j].high >>> 0).toNumber() : message.companyIds[j];
                        }
                        if (message.includeCompanyInfo != null && message.hasOwnProperty("includeCompanyInfo"))
                            object.includeCompanyInfo = message.includeCompanyInfo;
                        if (message.fetchJobStatus != null && message.hasOwnProperty("fetchJobStatus"))
                            object.fetchJobStatus = message.fetchJobStatus;
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffImportConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffImportConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffImportConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffImportConfigRequest;
                })();
    
                v1.ListStaffImportConfigResponse = (function() {
    
                    /**
                     * Properties of a ListStaffImportConfigResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffImportConfigResponse
                     * @property {string|null} [nextPageToken] ListStaffImportConfigResponse nextPageToken
                     * @property {number|null} [totalElements] ListStaffImportConfigResponse totalElements
                     * @property {Array.<palexy.streaming.v1.IStaffImportConfig>|null} [staffImportConfigs] ListStaffImportConfigResponse staffImportConfigs
                     */
    
                    /**
                     * Constructs a new ListStaffImportConfigResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffImportConfigResponse.
                     * @implements IListStaffImportConfigResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffImportConfigResponse=} [properties] Properties to set
                     */
                    function ListStaffImportConfigResponse(properties) {
                        this.staffImportConfigs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffImportConfigResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @instance
                     */
                    ListStaffImportConfigResponse.prototype.nextPageToken = "";
    
                    /**
                     * ListStaffImportConfigResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @instance
                     */
                    ListStaffImportConfigResponse.prototype.totalElements = 0;
    
                    /**
                     * ListStaffImportConfigResponse staffImportConfigs.
                     * @member {Array.<palexy.streaming.v1.IStaffImportConfig>} staffImportConfigs
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @instance
                     */
                    ListStaffImportConfigResponse.prototype.staffImportConfigs = $util.emptyArray;
    
                    /**
                     * Creates a new ListStaffImportConfigResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffImportConfigResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffImportConfigResponse} ListStaffImportConfigResponse instance
                     */
                    ListStaffImportConfigResponse.create = function create(properties) {
                        return new ListStaffImportConfigResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffImportConfigResponse message. Does not implicitly {@link palexy.streaming.v1.ListStaffImportConfigResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffImportConfigResponse} message ListStaffImportConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffImportConfigResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.nextPageToken);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.staffImportConfigs != null && message.staffImportConfigs.length)
                            for (var i = 0; i < message.staffImportConfigs.length; ++i)
                                $root.palexy.streaming.v1.StaffImportConfig.encode(message.staffImportConfigs[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffImportConfigResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffImportConfigResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffImportConfigResponse} message ListStaffImportConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffImportConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffImportConfigResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffImportConfigResponse} ListStaffImportConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffImportConfigResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffImportConfigResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.nextPageToken = reader.string();
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                if (!(message.staffImportConfigs && message.staffImportConfigs.length))
                                    message.staffImportConfigs = [];
                                message.staffImportConfigs.push($root.palexy.streaming.v1.StaffImportConfig.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffImportConfigResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffImportConfigResponse} ListStaffImportConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffImportConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffImportConfigResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffImportConfigResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.staffImportConfigs != null && message.hasOwnProperty("staffImportConfigs")) {
                            if (!Array.isArray(message.staffImportConfigs))
                                return "staffImportConfigs: array expected";
                            for (var i = 0; i < message.staffImportConfigs.length; ++i) {
                                var error = $root.palexy.streaming.v1.StaffImportConfig.verify(message.staffImportConfigs[i]);
                                if (error)
                                    return "staffImportConfigs." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffImportConfigResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffImportConfigResponse} ListStaffImportConfigResponse
                     */
                    ListStaffImportConfigResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffImportConfigResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffImportConfigResponse();
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.staffImportConfigs) {
                            if (!Array.isArray(object.staffImportConfigs))
                                throw TypeError(".palexy.streaming.v1.ListStaffImportConfigResponse.staffImportConfigs: array expected");
                            message.staffImportConfigs = [];
                            for (var i = 0; i < object.staffImportConfigs.length; ++i) {
                                if (typeof object.staffImportConfigs[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStaffImportConfigResponse.staffImportConfigs: object expected");
                                message.staffImportConfigs[i] = $root.palexy.streaming.v1.StaffImportConfig.fromObject(object.staffImportConfigs[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffImportConfigResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStaffImportConfigResponse} message ListStaffImportConfigResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffImportConfigResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.staffImportConfigs = [];
                        if (options.defaults) {
                            object.nextPageToken = "";
                            object.totalElements = 0;
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.staffImportConfigs && message.staffImportConfigs.length) {
                            object.staffImportConfigs = [];
                            for (var j = 0; j < message.staffImportConfigs.length; ++j)
                                object.staffImportConfigs[j] = $root.palexy.streaming.v1.StaffImportConfig.toObject(message.staffImportConfigs[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffImportConfigResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffImportConfigResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffImportConfigResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffImportConfigResponse;
                })();
    
                v1.ImportStaffDataRequest = (function() {
    
                    /**
                     * Properties of an ImportStaffDataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IImportStaffDataRequest
                     * @property {Array.<palexy.streaming.v1.IParsedStaffAllocation>|null} [allocations] ImportStaffDataRequest allocations
                     * @property {number|null} [companyId] ImportStaffDataRequest companyId
                     * @property {string|null} [dateId] ImportStaffDataRequest dateId
                     */
    
                    /**
                     * Constructs a new ImportStaffDataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ImportStaffDataRequest.
                     * @implements IImportStaffDataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IImportStaffDataRequest=} [properties] Properties to set
                     */
                    function ImportStaffDataRequest(properties) {
                        this.allocations = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ImportStaffDataRequest allocations.
                     * @member {Array.<palexy.streaming.v1.IParsedStaffAllocation>} allocations
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @instance
                     */
                    ImportStaffDataRequest.prototype.allocations = $util.emptyArray;
    
                    /**
                     * ImportStaffDataRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @instance
                     */
                    ImportStaffDataRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ImportStaffDataRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @instance
                     */
                    ImportStaffDataRequest.prototype.dateId = "";
    
                    /**
                     * Creates a new ImportStaffDataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportStaffDataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ImportStaffDataRequest} ImportStaffDataRequest instance
                     */
                    ImportStaffDataRequest.create = function create(properties) {
                        return new ImportStaffDataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ImportStaffDataRequest message. Does not implicitly {@link palexy.streaming.v1.ImportStaffDataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportStaffDataRequest} message ImportStaffDataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportStaffDataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.allocations != null && message.allocations.length)
                            for (var i = 0; i < message.allocations.length; ++i)
                                $root.palexy.streaming.v1.ParsedStaffAllocation.encode(message.allocations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.dateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ImportStaffDataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ImportStaffDataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @static
                     * @param {palexy.streaming.v1.IImportStaffDataRequest} message ImportStaffDataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImportStaffDataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ImportStaffDataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ImportStaffDataRequest} ImportStaffDataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportStaffDataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ImportStaffDataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.allocations && message.allocations.length))
                                    message.allocations = [];
                                message.allocations.push($root.palexy.streaming.v1.ParsedStaffAllocation.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.dateId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ImportStaffDataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ImportStaffDataRequest} ImportStaffDataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImportStaffDataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ImportStaffDataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImportStaffDataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.allocations != null && message.hasOwnProperty("allocations")) {
                            if (!Array.isArray(message.allocations))
                                return "allocations: array expected";
                            for (var i = 0; i < message.allocations.length; ++i) {
                                var error = $root.palexy.streaming.v1.ParsedStaffAllocation.verify(message.allocations[i]);
                                if (error)
                                    return "allocations." + error;
                            }
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an ImportStaffDataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ImportStaffDataRequest} ImportStaffDataRequest
                     */
                    ImportStaffDataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ImportStaffDataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ImportStaffDataRequest();
                        if (object.allocations) {
                            if (!Array.isArray(object.allocations))
                                throw TypeError(".palexy.streaming.v1.ImportStaffDataRequest.allocations: array expected");
                            message.allocations = [];
                            for (var i = 0; i < object.allocations.length; ++i) {
                                if (typeof object.allocations[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ImportStaffDataRequest.allocations: object expected");
                                message.allocations[i] = $root.palexy.streaming.v1.ParsedStaffAllocation.fromObject(object.allocations[i]);
                            }
                        }
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ImportStaffDataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @static
                     * @param {palexy.streaming.v1.ImportStaffDataRequest} message ImportStaffDataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImportStaffDataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.allocations = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                        }
                        if (message.allocations && message.allocations.length) {
                            object.allocations = [];
                            for (var j = 0; j < message.allocations.length; ++j)
                                object.allocations[j] = $root.palexy.streaming.v1.ParsedStaffAllocation.toObject(message.allocations[j], options);
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        return object;
                    };
    
                    /**
                     * Converts this ImportStaffDataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ImportStaffDataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImportStaffDataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ImportStaffDataRequest;
                })();
    
                v1.GetStaffImportConfigRequest = (function() {
    
                    /**
                     * Properties of a GetStaffImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStaffImportConfigRequest
                     * @property {number|null} [configId] GetStaffImportConfigRequest configId
                     */
    
                    /**
                     * Constructs a new GetStaffImportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStaffImportConfigRequest.
                     * @implements IGetStaffImportConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStaffImportConfigRequest=} [properties] Properties to set
                     */
                    function GetStaffImportConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStaffImportConfigRequest configId.
                     * @member {number} configId
                     * @memberof palexy.streaming.v1.GetStaffImportConfigRequest
                     * @instance
                     */
                    GetStaffImportConfigRequest.prototype.configId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetStaffImportConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffImportConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStaffImportConfigRequest} GetStaffImportConfigRequest instance
                     */
                    GetStaffImportConfigRequest.create = function create(properties) {
                        return new GetStaffImportConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStaffImportConfigRequest message. Does not implicitly {@link palexy.streaming.v1.GetStaffImportConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffImportConfigRequest} message GetStaffImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffImportConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.configId != null && Object.hasOwnProperty.call(message, "configId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.configId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStaffImportConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStaffImportConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffImportConfigRequest} message GetStaffImportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffImportConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStaffImportConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStaffImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStaffImportConfigRequest} GetStaffImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffImportConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStaffImportConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.configId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStaffImportConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffImportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStaffImportConfigRequest} GetStaffImportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffImportConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStaffImportConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStaffImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStaffImportConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.configId != null && message.hasOwnProperty("configId"))
                            if (!$util.isInteger(message.configId) && !(message.configId && $util.isInteger(message.configId.low) && $util.isInteger(message.configId.high)))
                                return "configId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStaffImportConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStaffImportConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStaffImportConfigRequest} GetStaffImportConfigRequest
                     */
                    GetStaffImportConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStaffImportConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStaffImportConfigRequest();
                        if (object.configId != null)
                            if ($util.Long)
                                (message.configId = $util.Long.fromValue(object.configId)).unsigned = false;
                            else if (typeof object.configId === "string")
                                message.configId = parseInt(object.configId, 10);
                            else if (typeof object.configId === "number")
                                message.configId = object.configId;
                            else if (typeof object.configId === "object")
                                message.configId = new $util.LongBits(object.configId.low >>> 0, object.configId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStaffImportConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStaffImportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStaffImportConfigRequest} message GetStaffImportConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStaffImportConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.configId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.configId = options.longs === String ? "0" : 0;
                        if (message.configId != null && message.hasOwnProperty("configId"))
                            if (typeof message.configId === "number")
                                object.configId = options.longs === String ? String(message.configId) : message.configId;
                            else
                                object.configId = options.longs === String ? $util.Long.prototype.toString.call(message.configId) : options.longs === Number ? new $util.LongBits(message.configId.low >>> 0, message.configId.high >>> 0).toNumber() : message.configId;
                        return object;
                    };
    
                    /**
                     * Converts this GetStaffImportConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStaffImportConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStaffImportConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStaffImportConfigRequest;
                })();
    
                v1.ParsedStaffAllocation = (function() {
    
                    /**
                     * Properties of a ParsedStaffAllocation.
                     * @memberof palexy.streaming.v1
                     * @interface IParsedStaffAllocation
                     * @property {number|null} [dateId] ParsedStaffAllocation dateId
                     * @property {string|null} [startTime] ParsedStaffAllocation startTime
                     * @property {string|null} [endTime] ParsedStaffAllocation endTime
                     * @property {string|null} [staffId] ParsedStaffAllocation staffId
                     * @property {string|null} [staffName] ParsedStaffAllocation staffName
                     * @property {string|null} [staffRole] ParsedStaffAllocation staffRole
                     * @property {string|null} [description] ParsedStaffAllocation description
                     * @property {string|null} [storeCode] ParsedStaffAllocation storeCode
                     * @property {number|null} [storeId] ParsedStaffAllocation storeId
                     * @property {string|null} [staffSecondRole] ParsedStaffAllocation staffSecondRole
                     */
    
                    /**
                     * Constructs a new ParsedStaffAllocation.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ParsedStaffAllocation.
                     * @implements IParsedStaffAllocation
                     * @constructor
                     * @param {palexy.streaming.v1.IParsedStaffAllocation=} [properties] Properties to set
                     */
                    function ParsedStaffAllocation(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ParsedStaffAllocation dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @instance
                     */
                    ParsedStaffAllocation.prototype.dateId = 0;
    
                    /**
                     * ParsedStaffAllocation startTime.
                     * @member {string} startTime
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @instance
                     */
                    ParsedStaffAllocation.prototype.startTime = "";
    
                    /**
                     * ParsedStaffAllocation endTime.
                     * @member {string} endTime
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @instance
                     */
                    ParsedStaffAllocation.prototype.endTime = "";
    
                    /**
                     * ParsedStaffAllocation staffId.
                     * @member {string} staffId
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @instance
                     */
                    ParsedStaffAllocation.prototype.staffId = "";
    
                    /**
                     * ParsedStaffAllocation staffName.
                     * @member {string} staffName
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @instance
                     */
                    ParsedStaffAllocation.prototype.staffName = "";
    
                    /**
                     * ParsedStaffAllocation staffRole.
                     * @member {string} staffRole
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @instance
                     */
                    ParsedStaffAllocation.prototype.staffRole = "";
    
                    /**
                     * ParsedStaffAllocation description.
                     * @member {string} description
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @instance
                     */
                    ParsedStaffAllocation.prototype.description = "";
    
                    /**
                     * ParsedStaffAllocation storeCode.
                     * @member {string} storeCode
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @instance
                     */
                    ParsedStaffAllocation.prototype.storeCode = "";
    
                    /**
                     * ParsedStaffAllocation storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @instance
                     */
                    ParsedStaffAllocation.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ParsedStaffAllocation staffSecondRole.
                     * @member {string} staffSecondRole
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @instance
                     */
                    ParsedStaffAllocation.prototype.staffSecondRole = "";
    
                    /**
                     * Creates a new ParsedStaffAllocation instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @static
                     * @param {palexy.streaming.v1.IParsedStaffAllocation=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ParsedStaffAllocation} ParsedStaffAllocation instance
                     */
                    ParsedStaffAllocation.create = function create(properties) {
                        return new ParsedStaffAllocation(properties);
                    };
    
                    /**
                     * Encodes the specified ParsedStaffAllocation message. Does not implicitly {@link palexy.streaming.v1.ParsedStaffAllocation.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @static
                     * @param {palexy.streaming.v1.IParsedStaffAllocation} message ParsedStaffAllocation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ParsedStaffAllocation.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dateId);
                        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.startTime);
                        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.endTime);
                        if (message.staffId != null && Object.hasOwnProperty.call(message, "staffId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.staffId);
                        if (message.staffName != null && Object.hasOwnProperty.call(message, "staffName"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.staffName);
                        if (message.staffRole != null && Object.hasOwnProperty.call(message, "staffRole"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.staffRole);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.description);
                        if (message.storeCode != null && Object.hasOwnProperty.call(message, "storeCode"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.storeCode);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int64(message.storeId);
                        if (message.staffSecondRole != null && Object.hasOwnProperty.call(message, "staffSecondRole"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.staffSecondRole);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ParsedStaffAllocation message, length delimited. Does not implicitly {@link palexy.streaming.v1.ParsedStaffAllocation.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @static
                     * @param {palexy.streaming.v1.IParsedStaffAllocation} message ParsedStaffAllocation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ParsedStaffAllocation.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ParsedStaffAllocation message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ParsedStaffAllocation} ParsedStaffAllocation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ParsedStaffAllocation.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ParsedStaffAllocation();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dateId = reader.int32();
                                break;
                            case 2:
                                message.startTime = reader.string();
                                break;
                            case 3:
                                message.endTime = reader.string();
                                break;
                            case 4:
                                message.staffId = reader.string();
                                break;
                            case 5:
                                message.staffName = reader.string();
                                break;
                            case 6:
                                message.staffRole = reader.string();
                                break;
                            case 7:
                                message.description = reader.string();
                                break;
                            case 8:
                                message.storeCode = reader.string();
                                break;
                            case 9:
                                message.storeId = reader.int64();
                                break;
                            case 10:
                                message.staffSecondRole = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ParsedStaffAllocation message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ParsedStaffAllocation} ParsedStaffAllocation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ParsedStaffAllocation.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ParsedStaffAllocation message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ParsedStaffAllocation.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            if (!$util.isString(message.startTime))
                                return "startTime: string expected";
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            if (!$util.isString(message.endTime))
                                return "endTime: string expected";
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (!$util.isString(message.staffId))
                                return "staffId: string expected";
                        if (message.staffName != null && message.hasOwnProperty("staffName"))
                            if (!$util.isString(message.staffName))
                                return "staffName: string expected";
                        if (message.staffRole != null && message.hasOwnProperty("staffRole"))
                            if (!$util.isString(message.staffRole))
                                return "staffRole: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.storeCode != null && message.hasOwnProperty("storeCode"))
                            if (!$util.isString(message.storeCode))
                                return "storeCode: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.staffSecondRole != null && message.hasOwnProperty("staffSecondRole"))
                            if (!$util.isString(message.staffSecondRole))
                                return "staffSecondRole: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ParsedStaffAllocation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ParsedStaffAllocation} ParsedStaffAllocation
                     */
                    ParsedStaffAllocation.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ParsedStaffAllocation)
                            return object;
                        var message = new $root.palexy.streaming.v1.ParsedStaffAllocation();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.startTime != null)
                            message.startTime = String(object.startTime);
                        if (object.endTime != null)
                            message.endTime = String(object.endTime);
                        if (object.staffId != null)
                            message.staffId = String(object.staffId);
                        if (object.staffName != null)
                            message.staffName = String(object.staffName);
                        if (object.staffRole != null)
                            message.staffRole = String(object.staffRole);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.storeCode != null)
                            message.storeCode = String(object.storeCode);
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.staffSecondRole != null)
                            message.staffSecondRole = String(object.staffSecondRole);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ParsedStaffAllocation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @static
                     * @param {palexy.streaming.v1.ParsedStaffAllocation} message ParsedStaffAllocation
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ParsedStaffAllocation.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.dateId = 0;
                            object.startTime = "";
                            object.endTime = "";
                            object.staffId = "";
                            object.staffName = "";
                            object.staffRole = "";
                            object.description = "";
                            object.storeCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.staffSecondRole = "";
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            object.startTime = message.startTime;
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            object.endTime = message.endTime;
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            object.staffId = message.staffId;
                        if (message.staffName != null && message.hasOwnProperty("staffName"))
                            object.staffName = message.staffName;
                        if (message.staffRole != null && message.hasOwnProperty("staffRole"))
                            object.staffRole = message.staffRole;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.storeCode != null && message.hasOwnProperty("storeCode"))
                            object.storeCode = message.storeCode;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.staffSecondRole != null && message.hasOwnProperty("staffSecondRole"))
                            object.staffSecondRole = message.staffSecondRole;
                        return object;
                    };
    
                    /**
                     * Converts this ParsedStaffAllocation to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ParsedStaffAllocation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ParsedStaffAllocation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ParsedStaffAllocation;
                })();
    
                v1.RawICAConsistencyService = (function() {
    
                    /**
                     * Constructs a new RawICAConsistencyService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RawICAConsistencyService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function RawICAConsistencyService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (RawICAConsistencyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RawICAConsistencyService;
    
                    /**
                     * Creates new RawICAConsistencyService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {RawICAConsistencyService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    RawICAConsistencyService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAConsistencyService#createOrUpdateRawICAConsistency}.
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @typedef CreateOrUpdateRawICAConsistencyCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse} [response] CreateOrUpdateRawICAConsistencyResponse
                     */
    
                    /**
                     * Calls CreateOrUpdateRawICAConsistency.
                     * @function createOrUpdateRawICAConsistency
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateOrUpdateRawICAConsistencyRequest} request CreateOrUpdateRawICAConsistencyRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAConsistencyService.CreateOrUpdateRawICAConsistencyCallback} callback Node-style callback called with the error, if any, and CreateOrUpdateRawICAConsistencyResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAConsistencyService.prototype.createOrUpdateRawICAConsistency = function createOrUpdateRawICAConsistency(request, callback) {
                        return this.rpcCall(createOrUpdateRawICAConsistency, $root.palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest, $root.palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse, request, callback);
                    }, "name", { value: "CreateOrUpdateRawICAConsistency" });
    
                    /**
                     * Calls CreateOrUpdateRawICAConsistency.
                     * @function createOrUpdateRawICAConsistency
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateOrUpdateRawICAConsistencyRequest} request CreateOrUpdateRawICAConsistencyRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAConsistencyService#listRawICAConsistency}.
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @typedef ListRawICAConsistencyCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListRawICAConsistencyResponse} [response] ListRawICAConsistencyResponse
                     */
    
                    /**
                     * Calls ListRawICAConsistency.
                     * @function listRawICAConsistency
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.IListRawICAConsistencyRequest} request ListRawICAConsistencyRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAConsistencyService.ListRawICAConsistencyCallback} callback Node-style callback called with the error, if any, and ListRawICAConsistencyResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAConsistencyService.prototype.listRawICAConsistency = function listRawICAConsistency(request, callback) {
                        return this.rpcCall(listRawICAConsistency, $root.palexy.streaming.v1.ListRawICAConsistencyRequest, $root.palexy.streaming.v1.ListRawICAConsistencyResponse, request, callback);
                    }, "name", { value: "ListRawICAConsistency" });
    
                    /**
                     * Calls ListRawICAConsistency.
                     * @function listRawICAConsistency
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.IListRawICAConsistencyRequest} request ListRawICAConsistencyRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListRawICAConsistencyResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAConsistencyService#getRawICAConsistency}.
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @typedef GetRawICAConsistencyCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.RawICAConsistency} [response] RawICAConsistency
                     */
    
                    /**
                     * Calls GetRawICAConsistency.
                     * @function getRawICAConsistency
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.IGetRawICAConsistencyRequest} request GetRawICAConsistencyRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAConsistencyService.GetRawICAConsistencyCallback} callback Node-style callback called with the error, if any, and RawICAConsistency
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAConsistencyService.prototype.getRawICAConsistency = function getRawICAConsistency(request, callback) {
                        return this.rpcCall(getRawICAConsistency, $root.palexy.streaming.v1.GetRawICAConsistencyRequest, $root.palexy.streaming.v1.RawICAConsistency, request, callback);
                    }, "name", { value: "GetRawICAConsistency" });
    
                    /**
                     * Calls GetRawICAConsistency.
                     * @function getRawICAConsistency
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.IGetRawICAConsistencyRequest} request GetRawICAConsistencyRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.RawICAConsistency>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAConsistencyService#updateRawICAConsistencyState}.
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @typedef UpdateRawICAConsistencyStateCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.RawICAConsistency} [response] RawICAConsistency
                     */
    
                    /**
                     * Calls UpdateRawICAConsistencyState.
                     * @function updateRawICAConsistencyState
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateRawICAConsistencyStateRequest} request UpdateRawICAConsistencyStateRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAConsistencyService.UpdateRawICAConsistencyStateCallback} callback Node-style callback called with the error, if any, and RawICAConsistency
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAConsistencyService.prototype.updateRawICAConsistencyState = function updateRawICAConsistencyState(request, callback) {
                        return this.rpcCall(updateRawICAConsistencyState, $root.palexy.streaming.v1.UpdateRawICAConsistencyStateRequest, $root.palexy.streaming.v1.RawICAConsistency, request, callback);
                    }, "name", { value: "UpdateRawICAConsistencyState" });
    
                    /**
                     * Calls UpdateRawICAConsistencyState.
                     * @function updateRawICAConsistencyState
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateRawICAConsistencyStateRequest} request UpdateRawICAConsistencyStateRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.RawICAConsistency>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAConsistencyService#getRecommendedSimulatingSource}.
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @typedef GetRecommendedSimulatingSourceCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.GetRecommendedSimulatingSourceResponse} [response] GetRecommendedSimulatingSourceResponse
                     */
    
                    /**
                     * Calls GetRecommendedSimulatingSource.
                     * @function getRecommendedSimulatingSource
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.IGetRecommendedSimulatingSourceRequest} request GetRecommendedSimulatingSourceRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAConsistencyService.GetRecommendedSimulatingSourceCallback} callback Node-style callback called with the error, if any, and GetRecommendedSimulatingSourceResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAConsistencyService.prototype.getRecommendedSimulatingSource = function getRecommendedSimulatingSource(request, callback) {
                        return this.rpcCall(getRecommendedSimulatingSource, $root.palexy.streaming.v1.GetRecommendedSimulatingSourceRequest, $root.palexy.streaming.v1.GetRecommendedSimulatingSourceResponse, request, callback);
                    }, "name", { value: "GetRecommendedSimulatingSource" });
    
                    /**
                     * Calls GetRecommendedSimulatingSource.
                     * @function getRecommendedSimulatingSource
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.IGetRecommendedSimulatingSourceRequest} request GetRecommendedSimulatingSourceRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.GetRecommendedSimulatingSourceResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAConsistencyService#simulateRawICAData}.
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @typedef SimulateRawICADataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.SimulateRawICADataResponse} [response] SimulateRawICADataResponse
                     */
    
                    /**
                     * Calls SimulateRawICAData.
                     * @function simulateRawICAData
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.ISimulateRawICADataRequest} request SimulateRawICADataRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAConsistencyService.SimulateRawICADataCallback} callback Node-style callback called with the error, if any, and SimulateRawICADataResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAConsistencyService.prototype.simulateRawICAData = function simulateRawICAData(request, callback) {
                        return this.rpcCall(simulateRawICAData, $root.palexy.streaming.v1.SimulateRawICADataRequest, $root.palexy.streaming.v1.SimulateRawICADataResponse, request, callback);
                    }, "name", { value: "SimulateRawICAData" });
    
                    /**
                     * Calls SimulateRawICAData.
                     * @function simulateRawICAData
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.ISimulateRawICADataRequest} request SimulateRawICADataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.SimulateRawICADataResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.RawICAConsistencyService#revertSimulatedRawICAData}.
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @typedef RevertSimulatedRawICADataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.RevertSimulatedRawICADataResponse} [response] RevertSimulatedRawICADataResponse
                     */
    
                    /**
                     * Calls RevertSimulatedRawICAData.
                     * @function revertSimulatedRawICAData
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.IRevertSimulatedRawICADataRequest} request RevertSimulatedRawICADataRequest message or plain object
                     * @param {palexy.streaming.v1.RawICAConsistencyService.RevertSimulatedRawICADataCallback} callback Node-style callback called with the error, if any, and RevertSimulatedRawICADataResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RawICAConsistencyService.prototype.revertSimulatedRawICAData = function revertSimulatedRawICAData(request, callback) {
                        return this.rpcCall(revertSimulatedRawICAData, $root.palexy.streaming.v1.RevertSimulatedRawICADataRequest, $root.palexy.streaming.v1.RevertSimulatedRawICADataResponse, request, callback);
                    }, "name", { value: "RevertSimulatedRawICAData" });
    
                    /**
                     * Calls RevertSimulatedRawICAData.
                     * @function revertSimulatedRawICAData
                     * @memberof palexy.streaming.v1.RawICAConsistencyService
                     * @instance
                     * @param {palexy.streaming.v1.IRevertSimulatedRawICADataRequest} request RevertSimulatedRawICADataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.RevertSimulatedRawICADataResponse>} Promise
                     * @variation 2
                     */
    
                    return RawICAConsistencyService;
                })();
    
                v1.CreateOrUpdateRawICAConsistencyRequest = (function() {
    
                    /**
                     * Properties of a CreateOrUpdateRawICAConsistencyRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateOrUpdateRawICAConsistencyRequest
                     * @property {number|null} [storeId] CreateOrUpdateRawICAConsistencyRequest storeId
                     * @property {number|null} [dateId] CreateOrUpdateRawICAConsistencyRequest dateId
                     */
    
                    /**
                     * Constructs a new CreateOrUpdateRawICAConsistencyRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateOrUpdateRawICAConsistencyRequest.
                     * @implements ICreateOrUpdateRawICAConsistencyRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateOrUpdateRawICAConsistencyRequest=} [properties] Properties to set
                     */
                    function CreateOrUpdateRawICAConsistencyRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateOrUpdateRawICAConsistencyRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest
                     * @instance
                     */
                    CreateOrUpdateRawICAConsistencyRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CreateOrUpdateRawICAConsistencyRequest dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest
                     * @instance
                     */
                    CreateOrUpdateRawICAConsistencyRequest.prototype.dateId = 0;
    
                    /**
                     * Creates a new CreateOrUpdateRawICAConsistencyRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateOrUpdateRawICAConsistencyRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest} CreateOrUpdateRawICAConsistencyRequest instance
                     */
                    CreateOrUpdateRawICAConsistencyRequest.create = function create(properties) {
                        return new CreateOrUpdateRawICAConsistencyRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateOrUpdateRawICAConsistencyRequest message. Does not implicitly {@link palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateOrUpdateRawICAConsistencyRequest} message CreateOrUpdateRawICAConsistencyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateOrUpdateRawICAConsistencyRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateOrUpdateRawICAConsistencyRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateOrUpdateRawICAConsistencyRequest} message CreateOrUpdateRawICAConsistencyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateOrUpdateRawICAConsistencyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateOrUpdateRawICAConsistencyRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest} CreateOrUpdateRawICAConsistencyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateOrUpdateRawICAConsistencyRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateOrUpdateRawICAConsistencyRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest} CreateOrUpdateRawICAConsistencyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateOrUpdateRawICAConsistencyRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateOrUpdateRawICAConsistencyRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateOrUpdateRawICAConsistencyRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateOrUpdateRawICAConsistencyRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest} CreateOrUpdateRawICAConsistencyRequest
                     */
                    CreateOrUpdateRawICAConsistencyRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateOrUpdateRawICAConsistencyRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest} message CreateOrUpdateRawICAConsistencyRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateOrUpdateRawICAConsistencyRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        return object;
                    };
    
                    /**
                     * Converts this CreateOrUpdateRawICAConsistencyRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateOrUpdateRawICAConsistencyRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateOrUpdateRawICAConsistencyRequest;
                })();
    
                v1.CreateOrUpdateRawICAConsistencyResponse = (function() {
    
                    /**
                     * Properties of a CreateOrUpdateRawICAConsistencyResponse.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateOrUpdateRawICAConsistencyResponse
                     * @property {palexy.streaming.v1.IRawICAConsistency|null} [rawIcaConsistency] CreateOrUpdateRawICAConsistencyResponse rawIcaConsistency
                     * @property {boolean|null} [created] CreateOrUpdateRawICAConsistencyResponse created
                     */
    
                    /**
                     * Constructs a new CreateOrUpdateRawICAConsistencyResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateOrUpdateRawICAConsistencyResponse.
                     * @implements ICreateOrUpdateRawICAConsistencyResponse
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateOrUpdateRawICAConsistencyResponse=} [properties] Properties to set
                     */
                    function CreateOrUpdateRawICAConsistencyResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateOrUpdateRawICAConsistencyResponse rawIcaConsistency.
                     * @member {palexy.streaming.v1.IRawICAConsistency|null|undefined} rawIcaConsistency
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse
                     * @instance
                     */
                    CreateOrUpdateRawICAConsistencyResponse.prototype.rawIcaConsistency = null;
    
                    /**
                     * CreateOrUpdateRawICAConsistencyResponse created.
                     * @member {boolean} created
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse
                     * @instance
                     */
                    CreateOrUpdateRawICAConsistencyResponse.prototype.created = false;
    
                    /**
                     * Creates a new CreateOrUpdateRawICAConsistencyResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse
                     * @static
                     * @param {palexy.streaming.v1.ICreateOrUpdateRawICAConsistencyResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse} CreateOrUpdateRawICAConsistencyResponse instance
                     */
                    CreateOrUpdateRawICAConsistencyResponse.create = function create(properties) {
                        return new CreateOrUpdateRawICAConsistencyResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateOrUpdateRawICAConsistencyResponse message. Does not implicitly {@link palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse
                     * @static
                     * @param {palexy.streaming.v1.ICreateOrUpdateRawICAConsistencyResponse} message CreateOrUpdateRawICAConsistencyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateOrUpdateRawICAConsistencyResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rawIcaConsistency != null && Object.hasOwnProperty.call(message, "rawIcaConsistency"))
                            $root.palexy.streaming.v1.RawICAConsistency.encode(message.rawIcaConsistency, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.created);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateOrUpdateRawICAConsistencyResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse
                     * @static
                     * @param {palexy.streaming.v1.ICreateOrUpdateRawICAConsistencyResponse} message CreateOrUpdateRawICAConsistencyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateOrUpdateRawICAConsistencyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateOrUpdateRawICAConsistencyResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse} CreateOrUpdateRawICAConsistencyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateOrUpdateRawICAConsistencyResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.rawIcaConsistency = $root.palexy.streaming.v1.RawICAConsistency.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.created = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateOrUpdateRawICAConsistencyResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse} CreateOrUpdateRawICAConsistencyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateOrUpdateRawICAConsistencyResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateOrUpdateRawICAConsistencyResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateOrUpdateRawICAConsistencyResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rawIcaConsistency != null && message.hasOwnProperty("rawIcaConsistency")) {
                            var error = $root.palexy.streaming.v1.RawICAConsistency.verify(message.rawIcaConsistency);
                            if (error)
                                return "rawIcaConsistency." + error;
                        }
                        if (message.created != null && message.hasOwnProperty("created"))
                            if (typeof message.created !== "boolean")
                                return "created: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateOrUpdateRawICAConsistencyResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse} CreateOrUpdateRawICAConsistencyResponse
                     */
                    CreateOrUpdateRawICAConsistencyResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse();
                        if (object.rawIcaConsistency != null) {
                            if (typeof object.rawIcaConsistency !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse.rawIcaConsistency: object expected");
                            message.rawIcaConsistency = $root.palexy.streaming.v1.RawICAConsistency.fromObject(object.rawIcaConsistency);
                        }
                        if (object.created != null)
                            message.created = Boolean(object.created);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateOrUpdateRawICAConsistencyResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse
                     * @static
                     * @param {palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse} message CreateOrUpdateRawICAConsistencyResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateOrUpdateRawICAConsistencyResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.rawIcaConsistency = null;
                            object.created = false;
                        }
                        if (message.rawIcaConsistency != null && message.hasOwnProperty("rawIcaConsistency"))
                            object.rawIcaConsistency = $root.palexy.streaming.v1.RawICAConsistency.toObject(message.rawIcaConsistency, options);
                        if (message.created != null && message.hasOwnProperty("created"))
                            object.created = message.created;
                        return object;
                    };
    
                    /**
                     * Converts this CreateOrUpdateRawICAConsistencyResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateOrUpdateRawICAConsistencyResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateOrUpdateRawICAConsistencyResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateOrUpdateRawICAConsistencyResponse;
                })();
    
                v1.ListRawICAConsistencyRequest = (function() {
    
                    /**
                     * Properties of a ListRawICAConsistencyRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListRawICAConsistencyRequest
                     * @property {number|null} [companyId] ListRawICAConsistencyRequest companyId
                     * @property {Array.<number>|null} [storeIds] ListRawICAConsistencyRequest storeIds
                     * @property {Array.<palexy.streaming.v1.RawICAConsistency.State>|null} [states] ListRawICAConsistencyRequest states
                     * @property {number|null} [pageSize] ListRawICAConsistencyRequest pageSize
                     * @property {string|null} [pageToken] ListRawICAConsistencyRequest pageToken
                     * @property {google.protobuf.IInt64Value|null} [lastChangedFromMs] ListRawICAConsistencyRequest lastChangedFromMs
                     * @property {palexy.streaming.v1.IDateFilter|null} [fromDate] ListRawICAConsistencyRequest fromDate
                     * @property {palexy.streaming.v1.IDateFilter|null} [toDate] ListRawICAConsistencyRequest toDate
                     */
    
                    /**
                     * Constructs a new ListRawICAConsistencyRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListRawICAConsistencyRequest.
                     * @implements IListRawICAConsistencyRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListRawICAConsistencyRequest=} [properties] Properties to set
                     */
                    function ListRawICAConsistencyRequest(properties) {
                        this.storeIds = [];
                        this.states = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListRawICAConsistencyRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @instance
                     */
                    ListRawICAConsistencyRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListRawICAConsistencyRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @instance
                     */
                    ListRawICAConsistencyRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * ListRawICAConsistencyRequest states.
                     * @member {Array.<palexy.streaming.v1.RawICAConsistency.State>} states
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @instance
                     */
                    ListRawICAConsistencyRequest.prototype.states = $util.emptyArray;
    
                    /**
                     * ListRawICAConsistencyRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @instance
                     */
                    ListRawICAConsistencyRequest.prototype.pageSize = 0;
    
                    /**
                     * ListRawICAConsistencyRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @instance
                     */
                    ListRawICAConsistencyRequest.prototype.pageToken = "";
    
                    /**
                     * ListRawICAConsistencyRequest lastChangedFromMs.
                     * @member {google.protobuf.IInt64Value|null|undefined} lastChangedFromMs
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @instance
                     */
                    ListRawICAConsistencyRequest.prototype.lastChangedFromMs = null;
    
                    /**
                     * ListRawICAConsistencyRequest fromDate.
                     * @member {palexy.streaming.v1.IDateFilter|null|undefined} fromDate
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @instance
                     */
                    ListRawICAConsistencyRequest.prototype.fromDate = null;
    
                    /**
                     * ListRawICAConsistencyRequest toDate.
                     * @member {palexy.streaming.v1.IDateFilter|null|undefined} toDate
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @instance
                     */
                    ListRawICAConsistencyRequest.prototype.toDate = null;
    
                    /**
                     * Creates a new ListRawICAConsistencyRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAConsistencyRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListRawICAConsistencyRequest} ListRawICAConsistencyRequest instance
                     */
                    ListRawICAConsistencyRequest.create = function create(properties) {
                        return new ListRawICAConsistencyRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListRawICAConsistencyRequest message. Does not implicitly {@link palexy.streaming.v1.ListRawICAConsistencyRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAConsistencyRequest} message ListRawICAConsistencyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICAConsistencyRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.states != null && message.states.length) {
                            writer.uint32(/* id 5, wireType 2 =*/42).fork();
                            for (var i = 0; i < message.states.length; ++i)
                                writer.int32(message.states[i]);
                            writer.ldelim();
                        }
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.pageToken);
                        if (message.lastChangedFromMs != null && Object.hasOwnProperty.call(message, "lastChangedFromMs"))
                            $root.google.protobuf.Int64Value.encode(message.lastChangedFromMs, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.fromDate != null && Object.hasOwnProperty.call(message, "fromDate"))
                            $root.palexy.streaming.v1.DateFilter.encode(message.fromDate, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.toDate != null && Object.hasOwnProperty.call(message, "toDate"))
                            $root.palexy.streaming.v1.DateFilter.encode(message.toDate, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListRawICAConsistencyRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListRawICAConsistencyRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAConsistencyRequest} message ListRawICAConsistencyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICAConsistencyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListRawICAConsistencyRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListRawICAConsistencyRequest} ListRawICAConsistencyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICAConsistencyRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListRawICAConsistencyRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 5:
                                if (!(message.states && message.states.length))
                                    message.states = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.states.push(reader.int32());
                                } else
                                    message.states.push(reader.int32());
                                break;
                            case 6:
                                message.pageSize = reader.int32();
                                break;
                            case 7:
                                message.pageToken = reader.string();
                                break;
                            case 8:
                                message.lastChangedFromMs = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.fromDate = $root.palexy.streaming.v1.DateFilter.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.toDate = $root.palexy.streaming.v1.DateFilter.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListRawICAConsistencyRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListRawICAConsistencyRequest} ListRawICAConsistencyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICAConsistencyRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListRawICAConsistencyRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListRawICAConsistencyRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.states != null && message.hasOwnProperty("states")) {
                            if (!Array.isArray(message.states))
                                return "states: array expected";
                            for (var i = 0; i < message.states.length; ++i)
                                switch (message.states[i]) {
                                default:
                                    return "states: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                    break;
                                }
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.lastChangedFromMs != null && message.hasOwnProperty("lastChangedFromMs")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.lastChangedFromMs);
                            if (error)
                                return "lastChangedFromMs." + error;
                        }
                        if (message.fromDate != null && message.hasOwnProperty("fromDate")) {
                            var error = $root.palexy.streaming.v1.DateFilter.verify(message.fromDate);
                            if (error)
                                return "fromDate." + error;
                        }
                        if (message.toDate != null && message.hasOwnProperty("toDate")) {
                            var error = $root.palexy.streaming.v1.DateFilter.verify(message.toDate);
                            if (error)
                                return "toDate." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListRawICAConsistencyRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListRawICAConsistencyRequest} ListRawICAConsistencyRequest
                     */
                    ListRawICAConsistencyRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListRawICAConsistencyRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListRawICAConsistencyRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.streaming.v1.ListRawICAConsistencyRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.states) {
                            if (!Array.isArray(object.states))
                                throw TypeError(".palexy.streaming.v1.ListRawICAConsistencyRequest.states: array expected");
                            message.states = [];
                            for (var i = 0; i < object.states.length; ++i)
                                switch (object.states[i]) {
                                default:
                                case "UNKNOWN":
                                case 0:
                                    message.states[i] = 0;
                                    break;
                                case "NORMAL":
                                case 1:
                                    message.states[i] = 1;
                                    break;
                                case "CONSIDERABLE_LOSS":
                                case 2:
                                    message.states[i] = 2;
                                    break;
                                case "CONSIDERABLE_LOSS_BUT_INSIGNIFICANT":
                                case 3:
                                    message.states[i] = 3;
                                    break;
                                case "SIMULATED":
                                case 4:
                                    message.states[i] = 4;
                                    break;
                                case "INVALID":
                                case 5:
                                    message.states[i] = 5;
                                    break;
                                }
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.lastChangedFromMs != null) {
                            if (typeof object.lastChangedFromMs !== "object")
                                throw TypeError(".palexy.streaming.v1.ListRawICAConsistencyRequest.lastChangedFromMs: object expected");
                            message.lastChangedFromMs = $root.google.protobuf.Int64Value.fromObject(object.lastChangedFromMs);
                        }
                        if (object.fromDate != null) {
                            if (typeof object.fromDate !== "object")
                                throw TypeError(".palexy.streaming.v1.ListRawICAConsistencyRequest.fromDate: object expected");
                            message.fromDate = $root.palexy.streaming.v1.DateFilter.fromObject(object.fromDate);
                        }
                        if (object.toDate != null) {
                            if (typeof object.toDate !== "object")
                                throw TypeError(".palexy.streaming.v1.ListRawICAConsistencyRequest.toDate: object expected");
                            message.toDate = $root.palexy.streaming.v1.DateFilter.fromObject(object.toDate);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListRawICAConsistencyRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.ListRawICAConsistencyRequest} message ListRawICAConsistencyRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListRawICAConsistencyRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.storeIds = [];
                            object.states = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.lastChangedFromMs = null;
                            object.fromDate = null;
                            object.toDate = null;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.states && message.states.length) {
                            object.states = [];
                            for (var j = 0; j < message.states.length; ++j)
                                object.states[j] = options.enums === String ? $root.palexy.streaming.v1.RawICAConsistency.State[message.states[j]] : message.states[j];
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.lastChangedFromMs != null && message.hasOwnProperty("lastChangedFromMs"))
                            object.lastChangedFromMs = $root.google.protobuf.Int64Value.toObject(message.lastChangedFromMs, options);
                        if (message.fromDate != null && message.hasOwnProperty("fromDate"))
                            object.fromDate = $root.palexy.streaming.v1.DateFilter.toObject(message.fromDate, options);
                        if (message.toDate != null && message.hasOwnProperty("toDate"))
                            object.toDate = $root.palexy.streaming.v1.DateFilter.toObject(message.toDate, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListRawICAConsistencyRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListRawICAConsistencyRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListRawICAConsistencyRequest;
                })();
    
                v1.ListRawICAConsistencyResponse = (function() {
    
                    /**
                     * Properties of a ListRawICAConsistencyResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListRawICAConsistencyResponse
                     * @property {Array.<palexy.streaming.v1.IRawICAConsistency>|null} [rawIcaConsistencies] ListRawICAConsistencyResponse rawIcaConsistencies
                     * @property {number|null} [totalElements] ListRawICAConsistencyResponse totalElements
                     * @property {string|null} [nextPageToken] ListRawICAConsistencyResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListRawICAConsistencyResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListRawICAConsistencyResponse.
                     * @implements IListRawICAConsistencyResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListRawICAConsistencyResponse=} [properties] Properties to set
                     */
                    function ListRawICAConsistencyResponse(properties) {
                        this.rawIcaConsistencies = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListRawICAConsistencyResponse rawIcaConsistencies.
                     * @member {Array.<palexy.streaming.v1.IRawICAConsistency>} rawIcaConsistencies
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @instance
                     */
                    ListRawICAConsistencyResponse.prototype.rawIcaConsistencies = $util.emptyArray;
    
                    /**
                     * ListRawICAConsistencyResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @instance
                     */
                    ListRawICAConsistencyResponse.prototype.totalElements = 0;
    
                    /**
                     * ListRawICAConsistencyResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @instance
                     */
                    ListRawICAConsistencyResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListRawICAConsistencyResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAConsistencyResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListRawICAConsistencyResponse} ListRawICAConsistencyResponse instance
                     */
                    ListRawICAConsistencyResponse.create = function create(properties) {
                        return new ListRawICAConsistencyResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListRawICAConsistencyResponse message. Does not implicitly {@link palexy.streaming.v1.ListRawICAConsistencyResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAConsistencyResponse} message ListRawICAConsistencyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICAConsistencyResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rawIcaConsistencies != null && message.rawIcaConsistencies.length)
                            for (var i = 0; i < message.rawIcaConsistencies.length; ++i)
                                $root.palexy.streaming.v1.RawICAConsistency.encode(message.rawIcaConsistencies[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListRawICAConsistencyResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListRawICAConsistencyResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @static
                     * @param {palexy.streaming.v1.IListRawICAConsistencyResponse} message ListRawICAConsistencyResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListRawICAConsistencyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListRawICAConsistencyResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListRawICAConsistencyResponse} ListRawICAConsistencyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICAConsistencyResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListRawICAConsistencyResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.rawIcaConsistencies && message.rawIcaConsistencies.length))
                                    message.rawIcaConsistencies = [];
                                message.rawIcaConsistencies.push($root.palexy.streaming.v1.RawICAConsistency.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListRawICAConsistencyResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListRawICAConsistencyResponse} ListRawICAConsistencyResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListRawICAConsistencyResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListRawICAConsistencyResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListRawICAConsistencyResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rawIcaConsistencies != null && message.hasOwnProperty("rawIcaConsistencies")) {
                            if (!Array.isArray(message.rawIcaConsistencies))
                                return "rawIcaConsistencies: array expected";
                            for (var i = 0; i < message.rawIcaConsistencies.length; ++i) {
                                var error = $root.palexy.streaming.v1.RawICAConsistency.verify(message.rawIcaConsistencies[i]);
                                if (error)
                                    return "rawIcaConsistencies." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListRawICAConsistencyResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListRawICAConsistencyResponse} ListRawICAConsistencyResponse
                     */
                    ListRawICAConsistencyResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListRawICAConsistencyResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListRawICAConsistencyResponse();
                        if (object.rawIcaConsistencies) {
                            if (!Array.isArray(object.rawIcaConsistencies))
                                throw TypeError(".palexy.streaming.v1.ListRawICAConsistencyResponse.rawIcaConsistencies: array expected");
                            message.rawIcaConsistencies = [];
                            for (var i = 0; i < object.rawIcaConsistencies.length; ++i) {
                                if (typeof object.rawIcaConsistencies[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListRawICAConsistencyResponse.rawIcaConsistencies: object expected");
                                message.rawIcaConsistencies[i] = $root.palexy.streaming.v1.RawICAConsistency.fromObject(object.rawIcaConsistencies[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListRawICAConsistencyResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @static
                     * @param {palexy.streaming.v1.ListRawICAConsistencyResponse} message ListRawICAConsistencyResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListRawICAConsistencyResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.rawIcaConsistencies = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.rawIcaConsistencies && message.rawIcaConsistencies.length) {
                            object.rawIcaConsistencies = [];
                            for (var j = 0; j < message.rawIcaConsistencies.length; ++j)
                                object.rawIcaConsistencies[j] = $root.palexy.streaming.v1.RawICAConsistency.toObject(message.rawIcaConsistencies[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListRawICAConsistencyResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListRawICAConsistencyResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListRawICAConsistencyResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListRawICAConsistencyResponse;
                })();
    
                v1.GetRawICAConsistencyRequest = (function() {
    
                    /**
                     * Properties of a GetRawICAConsistencyRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetRawICAConsistencyRequest
                     * @property {number|null} [id] GetRawICAConsistencyRequest id
                     */
    
                    /**
                     * Constructs a new GetRawICAConsistencyRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetRawICAConsistencyRequest.
                     * @implements IGetRawICAConsistencyRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetRawICAConsistencyRequest=} [properties] Properties to set
                     */
                    function GetRawICAConsistencyRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetRawICAConsistencyRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.GetRawICAConsistencyRequest
                     * @instance
                     */
                    GetRawICAConsistencyRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetRawICAConsistencyRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetRawICAConsistencyRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetRawICAConsistencyRequest} GetRawICAConsistencyRequest instance
                     */
                    GetRawICAConsistencyRequest.create = function create(properties) {
                        return new GetRawICAConsistencyRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetRawICAConsistencyRequest message. Does not implicitly {@link palexy.streaming.v1.GetRawICAConsistencyRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetRawICAConsistencyRequest} message GetRawICAConsistencyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetRawICAConsistencyRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetRawICAConsistencyRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetRawICAConsistencyRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetRawICAConsistencyRequest} message GetRawICAConsistencyRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetRawICAConsistencyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetRawICAConsistencyRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetRawICAConsistencyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetRawICAConsistencyRequest} GetRawICAConsistencyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetRawICAConsistencyRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetRawICAConsistencyRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetRawICAConsistencyRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetRawICAConsistencyRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetRawICAConsistencyRequest} GetRawICAConsistencyRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetRawICAConsistencyRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetRawICAConsistencyRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetRawICAConsistencyRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetRawICAConsistencyRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetRawICAConsistencyRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetRawICAConsistencyRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetRawICAConsistencyRequest} GetRawICAConsistencyRequest
                     */
                    GetRawICAConsistencyRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetRawICAConsistencyRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetRawICAConsistencyRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetRawICAConsistencyRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetRawICAConsistencyRequest
                     * @static
                     * @param {palexy.streaming.v1.GetRawICAConsistencyRequest} message GetRawICAConsistencyRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetRawICAConsistencyRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this GetRawICAConsistencyRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetRawICAConsistencyRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetRawICAConsistencyRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetRawICAConsistencyRequest;
                })();
    
                v1.UpdateRawICAConsistencyStateRequest = (function() {
    
                    /**
                     * Properties of an UpdateRawICAConsistencyStateRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateRawICAConsistencyStateRequest
                     * @property {number|null} [id] UpdateRawICAConsistencyStateRequest id
                     * @property {palexy.streaming.v1.RawICAConsistency.State|null} [newState] UpdateRawICAConsistencyStateRequest newState
                     * @property {number|null} [editorAccountId] UpdateRawICAConsistencyStateRequest editorAccountId
                     */
    
                    /**
                     * Constructs a new UpdateRawICAConsistencyStateRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateRawICAConsistencyStateRequest.
                     * @implements IUpdateRawICAConsistencyStateRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateRawICAConsistencyStateRequest=} [properties] Properties to set
                     */
                    function UpdateRawICAConsistencyStateRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateRawICAConsistencyStateRequest id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @instance
                     */
                    UpdateRawICAConsistencyStateRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * UpdateRawICAConsistencyStateRequest newState.
                     * @member {palexy.streaming.v1.RawICAConsistency.State} newState
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @instance
                     */
                    UpdateRawICAConsistencyStateRequest.prototype.newState = 0;
    
                    /**
                     * UpdateRawICAConsistencyStateRequest editorAccountId.
                     * @member {number} editorAccountId
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @instance
                     */
                    UpdateRawICAConsistencyStateRequest.prototype.editorAccountId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new UpdateRawICAConsistencyStateRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateRawICAConsistencyStateRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateRawICAConsistencyStateRequest} UpdateRawICAConsistencyStateRequest instance
                     */
                    UpdateRawICAConsistencyStateRequest.create = function create(properties) {
                        return new UpdateRawICAConsistencyStateRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateRawICAConsistencyStateRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateRawICAConsistencyStateRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateRawICAConsistencyStateRequest} message UpdateRawICAConsistencyStateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateRawICAConsistencyStateRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.newState != null && Object.hasOwnProperty.call(message, "newState"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.newState);
                        if (message.editorAccountId != null && Object.hasOwnProperty.call(message, "editorAccountId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.editorAccountId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateRawICAConsistencyStateRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateRawICAConsistencyStateRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateRawICAConsistencyStateRequest} message UpdateRawICAConsistencyStateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateRawICAConsistencyStateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateRawICAConsistencyStateRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateRawICAConsistencyStateRequest} UpdateRawICAConsistencyStateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateRawICAConsistencyStateRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateRawICAConsistencyStateRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.newState = reader.int32();
                                break;
                            case 3:
                                message.editorAccountId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateRawICAConsistencyStateRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateRawICAConsistencyStateRequest} UpdateRawICAConsistencyStateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateRawICAConsistencyStateRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateRawICAConsistencyStateRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateRawICAConsistencyStateRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.newState != null && message.hasOwnProperty("newState"))
                            switch (message.newState) {
                            default:
                                return "newState: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        if (message.editorAccountId != null && message.hasOwnProperty("editorAccountId"))
                            if (!$util.isInteger(message.editorAccountId) && !(message.editorAccountId && $util.isInteger(message.editorAccountId.low) && $util.isInteger(message.editorAccountId.high)))
                                return "editorAccountId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an UpdateRawICAConsistencyStateRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateRawICAConsistencyStateRequest} UpdateRawICAConsistencyStateRequest
                     */
                    UpdateRawICAConsistencyStateRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateRawICAConsistencyStateRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateRawICAConsistencyStateRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        switch (object.newState) {
                        case "UNKNOWN":
                        case 0:
                            message.newState = 0;
                            break;
                        case "NORMAL":
                        case 1:
                            message.newState = 1;
                            break;
                        case "CONSIDERABLE_LOSS":
                        case 2:
                            message.newState = 2;
                            break;
                        case "CONSIDERABLE_LOSS_BUT_INSIGNIFICANT":
                        case 3:
                            message.newState = 3;
                            break;
                        case "SIMULATED":
                        case 4:
                            message.newState = 4;
                            break;
                        case "INVALID":
                        case 5:
                            message.newState = 5;
                            break;
                        }
                        if (object.editorAccountId != null)
                            if ($util.Long)
                                (message.editorAccountId = $util.Long.fromValue(object.editorAccountId)).unsigned = false;
                            else if (typeof object.editorAccountId === "string")
                                message.editorAccountId = parseInt(object.editorAccountId, 10);
                            else if (typeof object.editorAccountId === "number")
                                message.editorAccountId = object.editorAccountId;
                            else if (typeof object.editorAccountId === "object")
                                message.editorAccountId = new $util.LongBits(object.editorAccountId.low >>> 0, object.editorAccountId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateRawICAConsistencyStateRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateRawICAConsistencyStateRequest} message UpdateRawICAConsistencyStateRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateRawICAConsistencyStateRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.newState = options.enums === String ? "UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.editorAccountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.editorAccountId = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.newState != null && message.hasOwnProperty("newState"))
                            object.newState = options.enums === String ? $root.palexy.streaming.v1.RawICAConsistency.State[message.newState] : message.newState;
                        if (message.editorAccountId != null && message.hasOwnProperty("editorAccountId"))
                            if (typeof message.editorAccountId === "number")
                                object.editorAccountId = options.longs === String ? String(message.editorAccountId) : message.editorAccountId;
                            else
                                object.editorAccountId = options.longs === String ? $util.Long.prototype.toString.call(message.editorAccountId) : options.longs === Number ? new $util.LongBits(message.editorAccountId.low >>> 0, message.editorAccountId.high >>> 0).toNumber() : message.editorAccountId;
                        return object;
                    };
    
                    /**
                     * Converts this UpdateRawICAConsistencyStateRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateRawICAConsistencyStateRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateRawICAConsistencyStateRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateRawICAConsistencyStateRequest;
                })();
    
                v1.RawICAConsistency = (function() {
    
                    /**
                     * Properties of a RawICAConsistency.
                     * @memberof palexy.streaming.v1
                     * @interface IRawICAConsistency
                     * @property {number|null} [id] RawICAConsistency id
                     * @property {number|null} [companyId] RawICAConsistency companyId
                     * @property {number|null} [storeId] RawICAConsistency storeId
                     * @property {number|null} [dateId] RawICAConsistency dateId
                     * @property {palexy.streaming.v1.ITimeRangeList|null} [requiredStreamingIntervals] RawICAConsistency requiredStreamingIntervals
                     * @property {Object.<string,palexy.streaming.v1.ITimeRangeList>|null} [missingStreamingIntervalsByCameraId] RawICAConsistency missingStreamingIntervalsByCameraId
                     * @property {number|null} [requiredStreamingDurationSeconds] RawICAConsistency requiredStreamingDurationSeconds
                     * @property {number|null} [actualStreamingDurationSeconds] RawICAConsistency actualStreamingDurationSeconds
                     * @property {number|null} [missingStreamingDurationSeconds] RawICAConsistency missingStreamingDurationSeconds
                     * @property {palexy.streaming.v1.RawICAConsistency.State|null} [state] RawICAConsistency state
                     * @property {palexy.streaming.v1.ITimeRangeList|null} [simulatedIntervals] RawICAConsistency simulatedIntervals
                     * @property {google.protobuf.IInt64Value|null} [lastEditorAccountId] RawICAConsistency lastEditorAccountId
                     * @property {number|null} [createTimeMs] RawICAConsistency createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] RawICAConsistency lastUpdateTimeMs
                     * @property {Array.<string>|null} [simulatedCameraIds] RawICAConsistency simulatedCameraIds
                     * @property {string|null} [lastEditorUsername] RawICAConsistency lastEditorUsername
                     */
    
                    /**
                     * Constructs a new RawICAConsistency.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RawICAConsistency.
                     * @implements IRawICAConsistency
                     * @constructor
                     * @param {palexy.streaming.v1.IRawICAConsistency=} [properties] Properties to set
                     */
                    function RawICAConsistency(properties) {
                        this.missingStreamingIntervalsByCameraId = {};
                        this.simulatedCameraIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RawICAConsistency id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICAConsistency companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICAConsistency storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICAConsistency dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.dateId = 0;
    
                    /**
                     * RawICAConsistency requiredStreamingIntervals.
                     * @member {palexy.streaming.v1.ITimeRangeList|null|undefined} requiredStreamingIntervals
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.requiredStreamingIntervals = null;
    
                    /**
                     * RawICAConsistency missingStreamingIntervalsByCameraId.
                     * @member {Object.<string,palexy.streaming.v1.ITimeRangeList>} missingStreamingIntervalsByCameraId
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.missingStreamingIntervalsByCameraId = $util.emptyObject;
    
                    /**
                     * RawICAConsistency requiredStreamingDurationSeconds.
                     * @member {number} requiredStreamingDurationSeconds
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.requiredStreamingDurationSeconds = 0;
    
                    /**
                     * RawICAConsistency actualStreamingDurationSeconds.
                     * @member {number} actualStreamingDurationSeconds
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.actualStreamingDurationSeconds = 0;
    
                    /**
                     * RawICAConsistency missingStreamingDurationSeconds.
                     * @member {number} missingStreamingDurationSeconds
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.missingStreamingDurationSeconds = 0;
    
                    /**
                     * RawICAConsistency state.
                     * @member {palexy.streaming.v1.RawICAConsistency.State} state
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.state = 0;
    
                    /**
                     * RawICAConsistency simulatedIntervals.
                     * @member {palexy.streaming.v1.ITimeRangeList|null|undefined} simulatedIntervals
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.simulatedIntervals = null;
    
                    /**
                     * RawICAConsistency lastEditorAccountId.
                     * @member {google.protobuf.IInt64Value|null|undefined} lastEditorAccountId
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.lastEditorAccountId = null;
    
                    /**
                     * RawICAConsistency createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICAConsistency lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICAConsistency simulatedCameraIds.
                     * @member {Array.<string>} simulatedCameraIds
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.simulatedCameraIds = $util.emptyArray;
    
                    /**
                     * RawICAConsistency lastEditorUsername.
                     * @member {string} lastEditorUsername
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     */
                    RawICAConsistency.prototype.lastEditorUsername = "";
    
                    /**
                     * Creates a new RawICAConsistency instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @static
                     * @param {palexy.streaming.v1.IRawICAConsistency=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RawICAConsistency} RawICAConsistency instance
                     */
                    RawICAConsistency.create = function create(properties) {
                        return new RawICAConsistency(properties);
                    };
    
                    /**
                     * Encodes the specified RawICAConsistency message. Does not implicitly {@link palexy.streaming.v1.RawICAConsistency.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @static
                     * @param {palexy.streaming.v1.IRawICAConsistency} message RawICAConsistency message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RawICAConsistency.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.dateId);
                        if (message.requiredStreamingIntervals != null && Object.hasOwnProperty.call(message, "requiredStreamingIntervals"))
                            $root.palexy.streaming.v1.TimeRangeList.encode(message.requiredStreamingIntervals, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.missingStreamingIntervalsByCameraId != null && Object.hasOwnProperty.call(message, "missingStreamingIntervalsByCameraId"))
                            for (var keys = Object.keys(message.missingStreamingIntervalsByCameraId), i = 0; i < keys.length; ++i) {
                                writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                $root.palexy.streaming.v1.TimeRangeList.encode(message.missingStreamingIntervalsByCameraId[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                            }
                        if (message.requiredStreamingDurationSeconds != null && Object.hasOwnProperty.call(message, "requiredStreamingDurationSeconds"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.requiredStreamingDurationSeconds);
                        if (message.actualStreamingDurationSeconds != null && Object.hasOwnProperty.call(message, "actualStreamingDurationSeconds"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.actualStreamingDurationSeconds);
                        if (message.missingStreamingDurationSeconds != null && Object.hasOwnProperty.call(message, "missingStreamingDurationSeconds"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.missingStreamingDurationSeconds);
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.state);
                        if (message.simulatedIntervals != null && Object.hasOwnProperty.call(message, "simulatedIntervals"))
                            $root.palexy.streaming.v1.TimeRangeList.encode(message.simulatedIntervals, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.lastEditorAccountId != null && Object.hasOwnProperty.call(message, "lastEditorAccountId"))
                            $root.google.protobuf.Int64Value.encode(message.lastEditorAccountId, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int64(message.lastUpdateTimeMs);
                        if (message.lastEditorUsername != null && Object.hasOwnProperty.call(message, "lastEditorUsername"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.lastEditorUsername);
                        if (message.simulatedCameraIds != null && message.simulatedCameraIds.length)
                            for (var i = 0; i < message.simulatedCameraIds.length; ++i)
                                writer.uint32(/* id 16, wireType 2 =*/130).string(message.simulatedCameraIds[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RawICAConsistency message, length delimited. Does not implicitly {@link palexy.streaming.v1.RawICAConsistency.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @static
                     * @param {palexy.streaming.v1.IRawICAConsistency} message RawICAConsistency message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RawICAConsistency.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RawICAConsistency message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RawICAConsistency} RawICAConsistency
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RawICAConsistency.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RawICAConsistency(), key, value;
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 4:
                                message.dateId = reader.int32();
                                break;
                            case 5:
                                message.requiredStreamingIntervals = $root.palexy.streaming.v1.TimeRangeList.decode(reader, reader.uint32());
                                break;
                            case 6:
                                if (message.missingStreamingIntervalsByCameraId === $util.emptyObject)
                                    message.missingStreamingIntervalsByCameraId = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.palexy.streaming.v1.TimeRangeList.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.missingStreamingIntervalsByCameraId[key] = value;
                                break;
                            case 7:
                                message.requiredStreamingDurationSeconds = reader.int32();
                                break;
                            case 8:
                                message.actualStreamingDurationSeconds = reader.int32();
                                break;
                            case 9:
                                message.missingStreamingDurationSeconds = reader.int32();
                                break;
                            case 10:
                                message.state = reader.int32();
                                break;
                            case 11:
                                message.simulatedIntervals = $root.palexy.streaming.v1.TimeRangeList.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.lastEditorAccountId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 13:
                                message.createTimeMs = reader.int64();
                                break;
                            case 14:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 16:
                                if (!(message.simulatedCameraIds && message.simulatedCameraIds.length))
                                    message.simulatedCameraIds = [];
                                message.simulatedCameraIds.push(reader.string());
                                break;
                            case 15:
                                message.lastEditorUsername = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RawICAConsistency message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RawICAConsistency} RawICAConsistency
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RawICAConsistency.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RawICAConsistency message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RawICAConsistency.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.requiredStreamingIntervals != null && message.hasOwnProperty("requiredStreamingIntervals")) {
                            var error = $root.palexy.streaming.v1.TimeRangeList.verify(message.requiredStreamingIntervals);
                            if (error)
                                return "requiredStreamingIntervals." + error;
                        }
                        if (message.missingStreamingIntervalsByCameraId != null && message.hasOwnProperty("missingStreamingIntervalsByCameraId")) {
                            if (!$util.isObject(message.missingStreamingIntervalsByCameraId))
                                return "missingStreamingIntervalsByCameraId: object expected";
                            var key = Object.keys(message.missingStreamingIntervalsByCameraId);
                            for (var i = 0; i < key.length; ++i) {
                                var error = $root.palexy.streaming.v1.TimeRangeList.verify(message.missingStreamingIntervalsByCameraId[key[i]]);
                                if (error)
                                    return "missingStreamingIntervalsByCameraId." + error;
                            }
                        }
                        if (message.requiredStreamingDurationSeconds != null && message.hasOwnProperty("requiredStreamingDurationSeconds"))
                            if (!$util.isInteger(message.requiredStreamingDurationSeconds))
                                return "requiredStreamingDurationSeconds: integer expected";
                        if (message.actualStreamingDurationSeconds != null && message.hasOwnProperty("actualStreamingDurationSeconds"))
                            if (!$util.isInteger(message.actualStreamingDurationSeconds))
                                return "actualStreamingDurationSeconds: integer expected";
                        if (message.missingStreamingDurationSeconds != null && message.hasOwnProperty("missingStreamingDurationSeconds"))
                            if (!$util.isInteger(message.missingStreamingDurationSeconds))
                                return "missingStreamingDurationSeconds: integer expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        if (message.simulatedIntervals != null && message.hasOwnProperty("simulatedIntervals")) {
                            var error = $root.palexy.streaming.v1.TimeRangeList.verify(message.simulatedIntervals);
                            if (error)
                                return "simulatedIntervals." + error;
                        }
                        if (message.lastEditorAccountId != null && message.hasOwnProperty("lastEditorAccountId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.lastEditorAccountId);
                            if (error)
                                return "lastEditorAccountId." + error;
                        }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.simulatedCameraIds != null && message.hasOwnProperty("simulatedCameraIds")) {
                            if (!Array.isArray(message.simulatedCameraIds))
                                return "simulatedCameraIds: array expected";
                            for (var i = 0; i < message.simulatedCameraIds.length; ++i)
                                if (!$util.isString(message.simulatedCameraIds[i]))
                                    return "simulatedCameraIds: string[] expected";
                        }
                        if (message.lastEditorUsername != null && message.hasOwnProperty("lastEditorUsername"))
                            if (!$util.isString(message.lastEditorUsername))
                                return "lastEditorUsername: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a RawICAConsistency message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RawICAConsistency} RawICAConsistency
                     */
                    RawICAConsistency.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RawICAConsistency)
                            return object;
                        var message = new $root.palexy.streaming.v1.RawICAConsistency();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.requiredStreamingIntervals != null) {
                            if (typeof object.requiredStreamingIntervals !== "object")
                                throw TypeError(".palexy.streaming.v1.RawICAConsistency.requiredStreamingIntervals: object expected");
                            message.requiredStreamingIntervals = $root.palexy.streaming.v1.TimeRangeList.fromObject(object.requiredStreamingIntervals);
                        }
                        if (object.missingStreamingIntervalsByCameraId) {
                            if (typeof object.missingStreamingIntervalsByCameraId !== "object")
                                throw TypeError(".palexy.streaming.v1.RawICAConsistency.missingStreamingIntervalsByCameraId: object expected");
                            message.missingStreamingIntervalsByCameraId = {};
                            for (var keys = Object.keys(object.missingStreamingIntervalsByCameraId), i = 0; i < keys.length; ++i) {
                                if (typeof object.missingStreamingIntervalsByCameraId[keys[i]] !== "object")
                                    throw TypeError(".palexy.streaming.v1.RawICAConsistency.missingStreamingIntervalsByCameraId: object expected");
                                message.missingStreamingIntervalsByCameraId[keys[i]] = $root.palexy.streaming.v1.TimeRangeList.fromObject(object.missingStreamingIntervalsByCameraId[keys[i]]);
                            }
                        }
                        if (object.requiredStreamingDurationSeconds != null)
                            message.requiredStreamingDurationSeconds = object.requiredStreamingDurationSeconds | 0;
                        if (object.actualStreamingDurationSeconds != null)
                            message.actualStreamingDurationSeconds = object.actualStreamingDurationSeconds | 0;
                        if (object.missingStreamingDurationSeconds != null)
                            message.missingStreamingDurationSeconds = object.missingStreamingDurationSeconds | 0;
                        switch (object.state) {
                        case "UNKNOWN":
                        case 0:
                            message.state = 0;
                            break;
                        case "NORMAL":
                        case 1:
                            message.state = 1;
                            break;
                        case "CONSIDERABLE_LOSS":
                        case 2:
                            message.state = 2;
                            break;
                        case "CONSIDERABLE_LOSS_BUT_INSIGNIFICANT":
                        case 3:
                            message.state = 3;
                            break;
                        case "SIMULATED":
                        case 4:
                            message.state = 4;
                            break;
                        case "INVALID":
                        case 5:
                            message.state = 5;
                            break;
                        }
                        if (object.simulatedIntervals != null) {
                            if (typeof object.simulatedIntervals !== "object")
                                throw TypeError(".palexy.streaming.v1.RawICAConsistency.simulatedIntervals: object expected");
                            message.simulatedIntervals = $root.palexy.streaming.v1.TimeRangeList.fromObject(object.simulatedIntervals);
                        }
                        if (object.lastEditorAccountId != null) {
                            if (typeof object.lastEditorAccountId !== "object")
                                throw TypeError(".palexy.streaming.v1.RawICAConsistency.lastEditorAccountId: object expected");
                            message.lastEditorAccountId = $root.google.protobuf.Int64Value.fromObject(object.lastEditorAccountId);
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.simulatedCameraIds) {
                            if (!Array.isArray(object.simulatedCameraIds))
                                throw TypeError(".palexy.streaming.v1.RawICAConsistency.simulatedCameraIds: array expected");
                            message.simulatedCameraIds = [];
                            for (var i = 0; i < object.simulatedCameraIds.length; ++i)
                                message.simulatedCameraIds[i] = String(object.simulatedCameraIds[i]);
                        }
                        if (object.lastEditorUsername != null)
                            message.lastEditorUsername = String(object.lastEditorUsername);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RawICAConsistency message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @static
                     * @param {palexy.streaming.v1.RawICAConsistency} message RawICAConsistency
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RawICAConsistency.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.simulatedCameraIds = [];
                        if (options.objects || options.defaults)
                            object.missingStreamingIntervalsByCameraId = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                            object.requiredStreamingIntervals = null;
                            object.requiredStreamingDurationSeconds = 0;
                            object.actualStreamingDurationSeconds = 0;
                            object.missingStreamingDurationSeconds = 0;
                            object.state = options.enums === String ? "UNKNOWN" : 0;
                            object.simulatedIntervals = null;
                            object.lastEditorAccountId = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            object.lastEditorUsername = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.requiredStreamingIntervals != null && message.hasOwnProperty("requiredStreamingIntervals"))
                            object.requiredStreamingIntervals = $root.palexy.streaming.v1.TimeRangeList.toObject(message.requiredStreamingIntervals, options);
                        var keys2;
                        if (message.missingStreamingIntervalsByCameraId && (keys2 = Object.keys(message.missingStreamingIntervalsByCameraId)).length) {
                            object.missingStreamingIntervalsByCameraId = {};
                            for (var j = 0; j < keys2.length; ++j)
                                object.missingStreamingIntervalsByCameraId[keys2[j]] = $root.palexy.streaming.v1.TimeRangeList.toObject(message.missingStreamingIntervalsByCameraId[keys2[j]], options);
                        }
                        if (message.requiredStreamingDurationSeconds != null && message.hasOwnProperty("requiredStreamingDurationSeconds"))
                            object.requiredStreamingDurationSeconds = message.requiredStreamingDurationSeconds;
                        if (message.actualStreamingDurationSeconds != null && message.hasOwnProperty("actualStreamingDurationSeconds"))
                            object.actualStreamingDurationSeconds = message.actualStreamingDurationSeconds;
                        if (message.missingStreamingDurationSeconds != null && message.hasOwnProperty("missingStreamingDurationSeconds"))
                            object.missingStreamingDurationSeconds = message.missingStreamingDurationSeconds;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.streaming.v1.RawICAConsistency.State[message.state] : message.state;
                        if (message.simulatedIntervals != null && message.hasOwnProperty("simulatedIntervals"))
                            object.simulatedIntervals = $root.palexy.streaming.v1.TimeRangeList.toObject(message.simulatedIntervals, options);
                        if (message.lastEditorAccountId != null && message.hasOwnProperty("lastEditorAccountId"))
                            object.lastEditorAccountId = $root.google.protobuf.Int64Value.toObject(message.lastEditorAccountId, options);
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.lastEditorUsername != null && message.hasOwnProperty("lastEditorUsername"))
                            object.lastEditorUsername = message.lastEditorUsername;
                        if (message.simulatedCameraIds && message.simulatedCameraIds.length) {
                            object.simulatedCameraIds = [];
                            for (var j = 0; j < message.simulatedCameraIds.length; ++j)
                                object.simulatedCameraIds[j] = message.simulatedCameraIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this RawICAConsistency to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RawICAConsistency
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RawICAConsistency.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * State enum.
                     * @name palexy.streaming.v1.RawICAConsistency.State
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} NORMAL=1 NORMAL value
                     * @property {number} CONSIDERABLE_LOSS=2 CONSIDERABLE_LOSS value
                     * @property {number} CONSIDERABLE_LOSS_BUT_INSIGNIFICANT=3 CONSIDERABLE_LOSS_BUT_INSIGNIFICANT value
                     * @property {number} SIMULATED=4 SIMULATED value
                     * @property {number} INVALID=5 INVALID value
                     */
                    RawICAConsistency.State = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "NORMAL"] = 1;
                        values[valuesById[2] = "CONSIDERABLE_LOSS"] = 2;
                        values[valuesById[3] = "CONSIDERABLE_LOSS_BUT_INSIGNIFICANT"] = 3;
                        values[valuesById[4] = "SIMULATED"] = 4;
                        values[valuesById[5] = "INVALID"] = 5;
                        return values;
                    })();
    
                    return RawICAConsistency;
                })();
    
                v1.GetRecommendedSimulatingSourceRequest = (function() {
    
                    /**
                     * Properties of a GetRecommendedSimulatingSourceRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetRecommendedSimulatingSourceRequest
                     * @property {number|null} [rawIcaConsistencyId] GetRecommendedSimulatingSourceRequest rawIcaConsistencyId
                     * @property {number|null} [expectedWholeDayVisits] GetRecommendedSimulatingSourceRequest expectedWholeDayVisits
                     * @property {number|null} [maxResults] GetRecommendedSimulatingSourceRequest maxResults
                     * @property {Array.<string>|null} [cameraIds] GetRecommendedSimulatingSourceRequest cameraIds
                     */
    
                    /**
                     * Constructs a new GetRecommendedSimulatingSourceRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetRecommendedSimulatingSourceRequest.
                     * @implements IGetRecommendedSimulatingSourceRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetRecommendedSimulatingSourceRequest=} [properties] Properties to set
                     */
                    function GetRecommendedSimulatingSourceRequest(properties) {
                        this.cameraIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetRecommendedSimulatingSourceRequest rawIcaConsistencyId.
                     * @member {number} rawIcaConsistencyId
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @instance
                     */
                    GetRecommendedSimulatingSourceRequest.prototype.rawIcaConsistencyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetRecommendedSimulatingSourceRequest expectedWholeDayVisits.
                     * @member {number} expectedWholeDayVisits
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @instance
                     */
                    GetRecommendedSimulatingSourceRequest.prototype.expectedWholeDayVisits = 0;
    
                    /**
                     * GetRecommendedSimulatingSourceRequest maxResults.
                     * @member {number} maxResults
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @instance
                     */
                    GetRecommendedSimulatingSourceRequest.prototype.maxResults = 0;
    
                    /**
                     * GetRecommendedSimulatingSourceRequest cameraIds.
                     * @member {Array.<string>} cameraIds
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @instance
                     */
                    GetRecommendedSimulatingSourceRequest.prototype.cameraIds = $util.emptyArray;
    
                    /**
                     * Creates a new GetRecommendedSimulatingSourceRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetRecommendedSimulatingSourceRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetRecommendedSimulatingSourceRequest} GetRecommendedSimulatingSourceRequest instance
                     */
                    GetRecommendedSimulatingSourceRequest.create = function create(properties) {
                        return new GetRecommendedSimulatingSourceRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetRecommendedSimulatingSourceRequest message. Does not implicitly {@link palexy.streaming.v1.GetRecommendedSimulatingSourceRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetRecommendedSimulatingSourceRequest} message GetRecommendedSimulatingSourceRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetRecommendedSimulatingSourceRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rawIcaConsistencyId != null && Object.hasOwnProperty.call(message, "rawIcaConsistencyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.rawIcaConsistencyId);
                        if (message.expectedWholeDayVisits != null && Object.hasOwnProperty.call(message, "expectedWholeDayVisits"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.expectedWholeDayVisits);
                        if (message.maxResults != null && Object.hasOwnProperty.call(message, "maxResults"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.maxResults);
                        if (message.cameraIds != null && message.cameraIds.length)
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.cameraIds[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetRecommendedSimulatingSourceRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetRecommendedSimulatingSourceRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetRecommendedSimulatingSourceRequest} message GetRecommendedSimulatingSourceRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetRecommendedSimulatingSourceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetRecommendedSimulatingSourceRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetRecommendedSimulatingSourceRequest} GetRecommendedSimulatingSourceRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetRecommendedSimulatingSourceRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetRecommendedSimulatingSourceRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.rawIcaConsistencyId = reader.int64();
                                break;
                            case 2:
                                message.expectedWholeDayVisits = reader.int32();
                                break;
                            case 3:
                                message.maxResults = reader.int32();
                                break;
                            case 4:
                                if (!(message.cameraIds && message.cameraIds.length))
                                    message.cameraIds = [];
                                message.cameraIds.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetRecommendedSimulatingSourceRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetRecommendedSimulatingSourceRequest} GetRecommendedSimulatingSourceRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetRecommendedSimulatingSourceRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetRecommendedSimulatingSourceRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetRecommendedSimulatingSourceRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rawIcaConsistencyId != null && message.hasOwnProperty("rawIcaConsistencyId"))
                            if (!$util.isInteger(message.rawIcaConsistencyId) && !(message.rawIcaConsistencyId && $util.isInteger(message.rawIcaConsistencyId.low) && $util.isInteger(message.rawIcaConsistencyId.high)))
                                return "rawIcaConsistencyId: integer|Long expected";
                        if (message.expectedWholeDayVisits != null && message.hasOwnProperty("expectedWholeDayVisits"))
                            if (!$util.isInteger(message.expectedWholeDayVisits))
                                return "expectedWholeDayVisits: integer expected";
                        if (message.maxResults != null && message.hasOwnProperty("maxResults"))
                            if (!$util.isInteger(message.maxResults))
                                return "maxResults: integer expected";
                        if (message.cameraIds != null && message.hasOwnProperty("cameraIds")) {
                            if (!Array.isArray(message.cameraIds))
                                return "cameraIds: array expected";
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                if (!$util.isString(message.cameraIds[i]))
                                    return "cameraIds: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetRecommendedSimulatingSourceRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetRecommendedSimulatingSourceRequest} GetRecommendedSimulatingSourceRequest
                     */
                    GetRecommendedSimulatingSourceRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetRecommendedSimulatingSourceRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetRecommendedSimulatingSourceRequest();
                        if (object.rawIcaConsistencyId != null)
                            if ($util.Long)
                                (message.rawIcaConsistencyId = $util.Long.fromValue(object.rawIcaConsistencyId)).unsigned = false;
                            else if (typeof object.rawIcaConsistencyId === "string")
                                message.rawIcaConsistencyId = parseInt(object.rawIcaConsistencyId, 10);
                            else if (typeof object.rawIcaConsistencyId === "number")
                                message.rawIcaConsistencyId = object.rawIcaConsistencyId;
                            else if (typeof object.rawIcaConsistencyId === "object")
                                message.rawIcaConsistencyId = new $util.LongBits(object.rawIcaConsistencyId.low >>> 0, object.rawIcaConsistencyId.high >>> 0).toNumber();
                        if (object.expectedWholeDayVisits != null)
                            message.expectedWholeDayVisits = object.expectedWholeDayVisits | 0;
                        if (object.maxResults != null)
                            message.maxResults = object.maxResults | 0;
                        if (object.cameraIds) {
                            if (!Array.isArray(object.cameraIds))
                                throw TypeError(".palexy.streaming.v1.GetRecommendedSimulatingSourceRequest.cameraIds: array expected");
                            message.cameraIds = [];
                            for (var i = 0; i < object.cameraIds.length; ++i)
                                message.cameraIds[i] = String(object.cameraIds[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetRecommendedSimulatingSourceRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @static
                     * @param {palexy.streaming.v1.GetRecommendedSimulatingSourceRequest} message GetRecommendedSimulatingSourceRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetRecommendedSimulatingSourceRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.cameraIds = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.rawIcaConsistencyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.rawIcaConsistencyId = options.longs === String ? "0" : 0;
                            object.expectedWholeDayVisits = 0;
                            object.maxResults = 0;
                        }
                        if (message.rawIcaConsistencyId != null && message.hasOwnProperty("rawIcaConsistencyId"))
                            if (typeof message.rawIcaConsistencyId === "number")
                                object.rawIcaConsistencyId = options.longs === String ? String(message.rawIcaConsistencyId) : message.rawIcaConsistencyId;
                            else
                                object.rawIcaConsistencyId = options.longs === String ? $util.Long.prototype.toString.call(message.rawIcaConsistencyId) : options.longs === Number ? new $util.LongBits(message.rawIcaConsistencyId.low >>> 0, message.rawIcaConsistencyId.high >>> 0).toNumber() : message.rawIcaConsistencyId;
                        if (message.expectedWholeDayVisits != null && message.hasOwnProperty("expectedWholeDayVisits"))
                            object.expectedWholeDayVisits = message.expectedWholeDayVisits;
                        if (message.maxResults != null && message.hasOwnProperty("maxResults"))
                            object.maxResults = message.maxResults;
                        if (message.cameraIds && message.cameraIds.length) {
                            object.cameraIds = [];
                            for (var j = 0; j < message.cameraIds.length; ++j)
                                object.cameraIds[j] = message.cameraIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetRecommendedSimulatingSourceRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetRecommendedSimulatingSourceRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetRecommendedSimulatingSourceRequest;
                })();
    
                v1.GetRecommendedSimulatingSourceResponse = (function() {
    
                    /**
                     * Properties of a GetRecommendedSimulatingSourceResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IGetRecommendedSimulatingSourceResponse
                     * @property {number|null} [expectedWholeDayVisits] GetRecommendedSimulatingSourceResponse expectedWholeDayVisits
                     * @property {Array.<palexy.streaming.v1.ITimeRange>|null} [intervals] GetRecommendedSimulatingSourceResponse intervals
                     * @property {Array.<palexy.streaming.v1.IRecommendedSimulatingSource>|null} [recommendedSimulatingSources] GetRecommendedSimulatingSourceResponse recommendedSimulatingSources
                     */
    
                    /**
                     * Constructs a new GetRecommendedSimulatingSourceResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetRecommendedSimulatingSourceResponse.
                     * @implements IGetRecommendedSimulatingSourceResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IGetRecommendedSimulatingSourceResponse=} [properties] Properties to set
                     */
                    function GetRecommendedSimulatingSourceResponse(properties) {
                        this.intervals = [];
                        this.recommendedSimulatingSources = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetRecommendedSimulatingSourceResponse expectedWholeDayVisits.
                     * @member {number} expectedWholeDayVisits
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @instance
                     */
                    GetRecommendedSimulatingSourceResponse.prototype.expectedWholeDayVisits = 0;
    
                    /**
                     * GetRecommendedSimulatingSourceResponse intervals.
                     * @member {Array.<palexy.streaming.v1.ITimeRange>} intervals
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @instance
                     */
                    GetRecommendedSimulatingSourceResponse.prototype.intervals = $util.emptyArray;
    
                    /**
                     * GetRecommendedSimulatingSourceResponse recommendedSimulatingSources.
                     * @member {Array.<palexy.streaming.v1.IRecommendedSimulatingSource>} recommendedSimulatingSources
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @instance
                     */
                    GetRecommendedSimulatingSourceResponse.prototype.recommendedSimulatingSources = $util.emptyArray;
    
                    /**
                     * Creates a new GetRecommendedSimulatingSourceResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetRecommendedSimulatingSourceResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetRecommendedSimulatingSourceResponse} GetRecommendedSimulatingSourceResponse instance
                     */
                    GetRecommendedSimulatingSourceResponse.create = function create(properties) {
                        return new GetRecommendedSimulatingSourceResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetRecommendedSimulatingSourceResponse message. Does not implicitly {@link palexy.streaming.v1.GetRecommendedSimulatingSourceResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetRecommendedSimulatingSourceResponse} message GetRecommendedSimulatingSourceResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetRecommendedSimulatingSourceResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.expectedWholeDayVisits != null && Object.hasOwnProperty.call(message, "expectedWholeDayVisits"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.expectedWholeDayVisits);
                        if (message.intervals != null && message.intervals.length)
                            for (var i = 0; i < message.intervals.length; ++i)
                                $root.palexy.streaming.v1.TimeRange.encode(message.intervals[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.recommendedSimulatingSources != null && message.recommendedSimulatingSources.length)
                            for (var i = 0; i < message.recommendedSimulatingSources.length; ++i)
                                $root.palexy.streaming.v1.RecommendedSimulatingSource.encode(message.recommendedSimulatingSources[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetRecommendedSimulatingSourceResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetRecommendedSimulatingSourceResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetRecommendedSimulatingSourceResponse} message GetRecommendedSimulatingSourceResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetRecommendedSimulatingSourceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetRecommendedSimulatingSourceResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetRecommendedSimulatingSourceResponse} GetRecommendedSimulatingSourceResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetRecommendedSimulatingSourceResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetRecommendedSimulatingSourceResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.expectedWholeDayVisits = reader.int32();
                                break;
                            case 2:
                                if (!(message.intervals && message.intervals.length))
                                    message.intervals = [];
                                message.intervals.push($root.palexy.streaming.v1.TimeRange.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                if (!(message.recommendedSimulatingSources && message.recommendedSimulatingSources.length))
                                    message.recommendedSimulatingSources = [];
                                message.recommendedSimulatingSources.push($root.palexy.streaming.v1.RecommendedSimulatingSource.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetRecommendedSimulatingSourceResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetRecommendedSimulatingSourceResponse} GetRecommendedSimulatingSourceResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetRecommendedSimulatingSourceResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetRecommendedSimulatingSourceResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetRecommendedSimulatingSourceResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.expectedWholeDayVisits != null && message.hasOwnProperty("expectedWholeDayVisits"))
                            if (!$util.isInteger(message.expectedWholeDayVisits))
                                return "expectedWholeDayVisits: integer expected";
                        if (message.intervals != null && message.hasOwnProperty("intervals")) {
                            if (!Array.isArray(message.intervals))
                                return "intervals: array expected";
                            for (var i = 0; i < message.intervals.length; ++i) {
                                var error = $root.palexy.streaming.v1.TimeRange.verify(message.intervals[i]);
                                if (error)
                                    return "intervals." + error;
                            }
                        }
                        if (message.recommendedSimulatingSources != null && message.hasOwnProperty("recommendedSimulatingSources")) {
                            if (!Array.isArray(message.recommendedSimulatingSources))
                                return "recommendedSimulatingSources: array expected";
                            for (var i = 0; i < message.recommendedSimulatingSources.length; ++i) {
                                var error = $root.palexy.streaming.v1.RecommendedSimulatingSource.verify(message.recommendedSimulatingSources[i]);
                                if (error)
                                    return "recommendedSimulatingSources." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetRecommendedSimulatingSourceResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetRecommendedSimulatingSourceResponse} GetRecommendedSimulatingSourceResponse
                     */
                    GetRecommendedSimulatingSourceResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetRecommendedSimulatingSourceResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetRecommendedSimulatingSourceResponse();
                        if (object.expectedWholeDayVisits != null)
                            message.expectedWholeDayVisits = object.expectedWholeDayVisits | 0;
                        if (object.intervals) {
                            if (!Array.isArray(object.intervals))
                                throw TypeError(".palexy.streaming.v1.GetRecommendedSimulatingSourceResponse.intervals: array expected");
                            message.intervals = [];
                            for (var i = 0; i < object.intervals.length; ++i) {
                                if (typeof object.intervals[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.GetRecommendedSimulatingSourceResponse.intervals: object expected");
                                message.intervals[i] = $root.palexy.streaming.v1.TimeRange.fromObject(object.intervals[i]);
                            }
                        }
                        if (object.recommendedSimulatingSources) {
                            if (!Array.isArray(object.recommendedSimulatingSources))
                                throw TypeError(".palexy.streaming.v1.GetRecommendedSimulatingSourceResponse.recommendedSimulatingSources: array expected");
                            message.recommendedSimulatingSources = [];
                            for (var i = 0; i < object.recommendedSimulatingSources.length; ++i) {
                                if (typeof object.recommendedSimulatingSources[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.GetRecommendedSimulatingSourceResponse.recommendedSimulatingSources: object expected");
                                message.recommendedSimulatingSources[i] = $root.palexy.streaming.v1.RecommendedSimulatingSource.fromObject(object.recommendedSimulatingSources[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetRecommendedSimulatingSourceResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @static
                     * @param {palexy.streaming.v1.GetRecommendedSimulatingSourceResponse} message GetRecommendedSimulatingSourceResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetRecommendedSimulatingSourceResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.intervals = [];
                            object.recommendedSimulatingSources = [];
                        }
                        if (options.defaults)
                            object.expectedWholeDayVisits = 0;
                        if (message.expectedWholeDayVisits != null && message.hasOwnProperty("expectedWholeDayVisits"))
                            object.expectedWholeDayVisits = message.expectedWholeDayVisits;
                        if (message.intervals && message.intervals.length) {
                            object.intervals = [];
                            for (var j = 0; j < message.intervals.length; ++j)
                                object.intervals[j] = $root.palexy.streaming.v1.TimeRange.toObject(message.intervals[j], options);
                        }
                        if (message.recommendedSimulatingSources && message.recommendedSimulatingSources.length) {
                            object.recommendedSimulatingSources = [];
                            for (var j = 0; j < message.recommendedSimulatingSources.length; ++j)
                                object.recommendedSimulatingSources[j] = $root.palexy.streaming.v1.RecommendedSimulatingSource.toObject(message.recommendedSimulatingSources[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetRecommendedSimulatingSourceResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetRecommendedSimulatingSourceResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetRecommendedSimulatingSourceResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetRecommendedSimulatingSourceResponse;
                })();
    
                v1.RecommendedSimulatingSource = (function() {
    
                    /**
                     * Properties of a RecommendedSimulatingSource.
                     * @memberof palexy.streaming.v1
                     * @interface IRecommendedSimulatingSource
                     * @property {number|null} [storeId] RecommendedSimulatingSource storeId
                     * @property {number|null} [dateId] RecommendedSimulatingSource dateId
                     * @property {number|null} [sourceTotalVisits] RecommendedSimulatingSource sourceTotalVisits
                     * @property {number|null} [finalTotalVisits] RecommendedSimulatingSource finalTotalVisits
                     * @property {number|null} [addedVisitsCount] RecommendedSimulatingSource addedVisitsCount
                     * @property {number|null} [removedVisitsCount] RecommendedSimulatingSource removedVisitsCount
                     * @property {number|null} [finalConversionRate] RecommendedSimulatingSource finalConversionRate
                     */
    
                    /**
                     * Constructs a new RecommendedSimulatingSource.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RecommendedSimulatingSource.
                     * @implements IRecommendedSimulatingSource
                     * @constructor
                     * @param {palexy.streaming.v1.IRecommendedSimulatingSource=} [properties] Properties to set
                     */
                    function RecommendedSimulatingSource(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RecommendedSimulatingSource storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @instance
                     */
                    RecommendedSimulatingSource.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RecommendedSimulatingSource dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @instance
                     */
                    RecommendedSimulatingSource.prototype.dateId = 0;
    
                    /**
                     * RecommendedSimulatingSource sourceTotalVisits.
                     * @member {number} sourceTotalVisits
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @instance
                     */
                    RecommendedSimulatingSource.prototype.sourceTotalVisits = 0;
    
                    /**
                     * RecommendedSimulatingSource finalTotalVisits.
                     * @member {number} finalTotalVisits
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @instance
                     */
                    RecommendedSimulatingSource.prototype.finalTotalVisits = 0;
    
                    /**
                     * RecommendedSimulatingSource addedVisitsCount.
                     * @member {number} addedVisitsCount
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @instance
                     */
                    RecommendedSimulatingSource.prototype.addedVisitsCount = 0;
    
                    /**
                     * RecommendedSimulatingSource removedVisitsCount.
                     * @member {number} removedVisitsCount
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @instance
                     */
                    RecommendedSimulatingSource.prototype.removedVisitsCount = 0;
    
                    /**
                     * RecommendedSimulatingSource finalConversionRate.
                     * @member {number} finalConversionRate
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @instance
                     */
                    RecommendedSimulatingSource.prototype.finalConversionRate = 0;
    
                    /**
                     * Creates a new RecommendedSimulatingSource instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @static
                     * @param {palexy.streaming.v1.IRecommendedSimulatingSource=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RecommendedSimulatingSource} RecommendedSimulatingSource instance
                     */
                    RecommendedSimulatingSource.create = function create(properties) {
                        return new RecommendedSimulatingSource(properties);
                    };
    
                    /**
                     * Encodes the specified RecommendedSimulatingSource message. Does not implicitly {@link palexy.streaming.v1.RecommendedSimulatingSource.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @static
                     * @param {palexy.streaming.v1.IRecommendedSimulatingSource} message RecommendedSimulatingSource message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RecommendedSimulatingSource.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateId);
                        if (message.sourceTotalVisits != null && Object.hasOwnProperty.call(message, "sourceTotalVisits"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sourceTotalVisits);
                        if (message.finalTotalVisits != null && Object.hasOwnProperty.call(message, "finalTotalVisits"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.finalTotalVisits);
                        if (message.addedVisitsCount != null && Object.hasOwnProperty.call(message, "addedVisitsCount"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.addedVisitsCount);
                        if (message.removedVisitsCount != null && Object.hasOwnProperty.call(message, "removedVisitsCount"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.removedVisitsCount);
                        if (message.finalConversionRate != null && Object.hasOwnProperty.call(message, "finalConversionRate"))
                            writer.uint32(/* id 7, wireType 1 =*/57).double(message.finalConversionRate);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RecommendedSimulatingSource message, length delimited. Does not implicitly {@link palexy.streaming.v1.RecommendedSimulatingSource.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @static
                     * @param {palexy.streaming.v1.IRecommendedSimulatingSource} message RecommendedSimulatingSource message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RecommendedSimulatingSource.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RecommendedSimulatingSource message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RecommendedSimulatingSource} RecommendedSimulatingSource
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RecommendedSimulatingSource.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RecommendedSimulatingSource();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.int32();
                                break;
                            case 3:
                                message.sourceTotalVisits = reader.int32();
                                break;
                            case 4:
                                message.finalTotalVisits = reader.int32();
                                break;
                            case 5:
                                message.addedVisitsCount = reader.int32();
                                break;
                            case 6:
                                message.removedVisitsCount = reader.int32();
                                break;
                            case 7:
                                message.finalConversionRate = reader.double();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RecommendedSimulatingSource message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RecommendedSimulatingSource} RecommendedSimulatingSource
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RecommendedSimulatingSource.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RecommendedSimulatingSource message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RecommendedSimulatingSource.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.sourceTotalVisits != null && message.hasOwnProperty("sourceTotalVisits"))
                            if (!$util.isInteger(message.sourceTotalVisits))
                                return "sourceTotalVisits: integer expected";
                        if (message.finalTotalVisits != null && message.hasOwnProperty("finalTotalVisits"))
                            if (!$util.isInteger(message.finalTotalVisits))
                                return "finalTotalVisits: integer expected";
                        if (message.addedVisitsCount != null && message.hasOwnProperty("addedVisitsCount"))
                            if (!$util.isInteger(message.addedVisitsCount))
                                return "addedVisitsCount: integer expected";
                        if (message.removedVisitsCount != null && message.hasOwnProperty("removedVisitsCount"))
                            if (!$util.isInteger(message.removedVisitsCount))
                                return "removedVisitsCount: integer expected";
                        if (message.finalConversionRate != null && message.hasOwnProperty("finalConversionRate"))
                            if (typeof message.finalConversionRate !== "number")
                                return "finalConversionRate: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a RecommendedSimulatingSource message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RecommendedSimulatingSource} RecommendedSimulatingSource
                     */
                    RecommendedSimulatingSource.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RecommendedSimulatingSource)
                            return object;
                        var message = new $root.palexy.streaming.v1.RecommendedSimulatingSource();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.sourceTotalVisits != null)
                            message.sourceTotalVisits = object.sourceTotalVisits | 0;
                        if (object.finalTotalVisits != null)
                            message.finalTotalVisits = object.finalTotalVisits | 0;
                        if (object.addedVisitsCount != null)
                            message.addedVisitsCount = object.addedVisitsCount | 0;
                        if (object.removedVisitsCount != null)
                            message.removedVisitsCount = object.removedVisitsCount | 0;
                        if (object.finalConversionRate != null)
                            message.finalConversionRate = Number(object.finalConversionRate);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RecommendedSimulatingSource message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @static
                     * @param {palexy.streaming.v1.RecommendedSimulatingSource} message RecommendedSimulatingSource
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RecommendedSimulatingSource.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                            object.sourceTotalVisits = 0;
                            object.finalTotalVisits = 0;
                            object.addedVisitsCount = 0;
                            object.removedVisitsCount = 0;
                            object.finalConversionRate = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.sourceTotalVisits != null && message.hasOwnProperty("sourceTotalVisits"))
                            object.sourceTotalVisits = message.sourceTotalVisits;
                        if (message.finalTotalVisits != null && message.hasOwnProperty("finalTotalVisits"))
                            object.finalTotalVisits = message.finalTotalVisits;
                        if (message.addedVisitsCount != null && message.hasOwnProperty("addedVisitsCount"))
                            object.addedVisitsCount = message.addedVisitsCount;
                        if (message.removedVisitsCount != null && message.hasOwnProperty("removedVisitsCount"))
                            object.removedVisitsCount = message.removedVisitsCount;
                        if (message.finalConversionRate != null && message.hasOwnProperty("finalConversionRate"))
                            object.finalConversionRate = options.json && !isFinite(message.finalConversionRate) ? String(message.finalConversionRate) : message.finalConversionRate;
                        return object;
                    };
    
                    /**
                     * Converts this RecommendedSimulatingSource to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RecommendedSimulatingSource
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RecommendedSimulatingSource.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RecommendedSimulatingSource;
                })();
    
                v1.SimulateRawICADataRequest = (function() {
    
                    /**
                     * Properties of a SimulateRawICADataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ISimulateRawICADataRequest
                     * @property {number|null} [rawIcaConsistencyId] SimulateRawICADataRequest rawIcaConsistencyId
                     * @property {number|null} [sourceStoreId] SimulateRawICADataRequest sourceStoreId
                     * @property {number|null} [sourceDateId] SimulateRawICADataRequest sourceDateId
                     * @property {Array.<palexy.streaming.v1.ITimeRange>|null} [intervals] SimulateRawICADataRequest intervals
                     * @property {boolean|null} [dryRun] SimulateRawICADataRequest dryRun
                     * @property {number|null} [editorAccountId] SimulateRawICADataRequest editorAccountId
                     * @property {Array.<string>|null} [cameraIds] SimulateRawICADataRequest cameraIds
                     */
    
                    /**
                     * Constructs a new SimulateRawICADataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SimulateRawICADataRequest.
                     * @implements ISimulateRawICADataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ISimulateRawICADataRequest=} [properties] Properties to set
                     */
                    function SimulateRawICADataRequest(properties) {
                        this.intervals = [];
                        this.cameraIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SimulateRawICADataRequest rawIcaConsistencyId.
                     * @member {number} rawIcaConsistencyId
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @instance
                     */
                    SimulateRawICADataRequest.prototype.rawIcaConsistencyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * SimulateRawICADataRequest sourceStoreId.
                     * @member {number} sourceStoreId
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @instance
                     */
                    SimulateRawICADataRequest.prototype.sourceStoreId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * SimulateRawICADataRequest sourceDateId.
                     * @member {number} sourceDateId
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @instance
                     */
                    SimulateRawICADataRequest.prototype.sourceDateId = 0;
    
                    /**
                     * SimulateRawICADataRequest intervals.
                     * @member {Array.<palexy.streaming.v1.ITimeRange>} intervals
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @instance
                     */
                    SimulateRawICADataRequest.prototype.intervals = $util.emptyArray;
    
                    /**
                     * SimulateRawICADataRequest dryRun.
                     * @member {boolean} dryRun
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @instance
                     */
                    SimulateRawICADataRequest.prototype.dryRun = false;
    
                    /**
                     * SimulateRawICADataRequest editorAccountId.
                     * @member {number} editorAccountId
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @instance
                     */
                    SimulateRawICADataRequest.prototype.editorAccountId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * SimulateRawICADataRequest cameraIds.
                     * @member {Array.<string>} cameraIds
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @instance
                     */
                    SimulateRawICADataRequest.prototype.cameraIds = $util.emptyArray;
    
                    /**
                     * Creates a new SimulateRawICADataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.ISimulateRawICADataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SimulateRawICADataRequest} SimulateRawICADataRequest instance
                     */
                    SimulateRawICADataRequest.create = function create(properties) {
                        return new SimulateRawICADataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified SimulateRawICADataRequest message. Does not implicitly {@link palexy.streaming.v1.SimulateRawICADataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.ISimulateRawICADataRequest} message SimulateRawICADataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SimulateRawICADataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rawIcaConsistencyId != null && Object.hasOwnProperty.call(message, "rawIcaConsistencyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.rawIcaConsistencyId);
                        if (message.sourceStoreId != null && Object.hasOwnProperty.call(message, "sourceStoreId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.sourceStoreId);
                        if (message.sourceDateId != null && Object.hasOwnProperty.call(message, "sourceDateId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sourceDateId);
                        if (message.intervals != null && message.intervals.length)
                            for (var i = 0; i < message.intervals.length; ++i)
                                $root.palexy.streaming.v1.TimeRange.encode(message.intervals[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.dryRun != null && Object.hasOwnProperty.call(message, "dryRun"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.dryRun);
                        if (message.editorAccountId != null && Object.hasOwnProperty.call(message, "editorAccountId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.editorAccountId);
                        if (message.cameraIds != null && message.cameraIds.length)
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.cameraIds[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SimulateRawICADataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.SimulateRawICADataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.ISimulateRawICADataRequest} message SimulateRawICADataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SimulateRawICADataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SimulateRawICADataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SimulateRawICADataRequest} SimulateRawICADataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SimulateRawICADataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SimulateRawICADataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.rawIcaConsistencyId = reader.int64();
                                break;
                            case 2:
                                message.sourceStoreId = reader.int64();
                                break;
                            case 3:
                                message.sourceDateId = reader.int32();
                                break;
                            case 4:
                                if (!(message.intervals && message.intervals.length))
                                    message.intervals = [];
                                message.intervals.push($root.palexy.streaming.v1.TimeRange.decode(reader, reader.uint32()));
                                break;
                            case 5:
                                message.dryRun = reader.bool();
                                break;
                            case 6:
                                message.editorAccountId = reader.int64();
                                break;
                            case 7:
                                if (!(message.cameraIds && message.cameraIds.length))
                                    message.cameraIds = [];
                                message.cameraIds.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SimulateRawICADataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SimulateRawICADataRequest} SimulateRawICADataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SimulateRawICADataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SimulateRawICADataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SimulateRawICADataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rawIcaConsistencyId != null && message.hasOwnProperty("rawIcaConsistencyId"))
                            if (!$util.isInteger(message.rawIcaConsistencyId) && !(message.rawIcaConsistencyId && $util.isInteger(message.rawIcaConsistencyId.low) && $util.isInteger(message.rawIcaConsistencyId.high)))
                                return "rawIcaConsistencyId: integer|Long expected";
                        if (message.sourceStoreId != null && message.hasOwnProperty("sourceStoreId"))
                            if (!$util.isInteger(message.sourceStoreId) && !(message.sourceStoreId && $util.isInteger(message.sourceStoreId.low) && $util.isInteger(message.sourceStoreId.high)))
                                return "sourceStoreId: integer|Long expected";
                        if (message.sourceDateId != null && message.hasOwnProperty("sourceDateId"))
                            if (!$util.isInteger(message.sourceDateId))
                                return "sourceDateId: integer expected";
                        if (message.intervals != null && message.hasOwnProperty("intervals")) {
                            if (!Array.isArray(message.intervals))
                                return "intervals: array expected";
                            for (var i = 0; i < message.intervals.length; ++i) {
                                var error = $root.palexy.streaming.v1.TimeRange.verify(message.intervals[i]);
                                if (error)
                                    return "intervals." + error;
                            }
                        }
                        if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                            if (typeof message.dryRun !== "boolean")
                                return "dryRun: boolean expected";
                        if (message.editorAccountId != null && message.hasOwnProperty("editorAccountId"))
                            if (!$util.isInteger(message.editorAccountId) && !(message.editorAccountId && $util.isInteger(message.editorAccountId.low) && $util.isInteger(message.editorAccountId.high)))
                                return "editorAccountId: integer|Long expected";
                        if (message.cameraIds != null && message.hasOwnProperty("cameraIds")) {
                            if (!Array.isArray(message.cameraIds))
                                return "cameraIds: array expected";
                            for (var i = 0; i < message.cameraIds.length; ++i)
                                if (!$util.isString(message.cameraIds[i]))
                                    return "cameraIds: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a SimulateRawICADataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SimulateRawICADataRequest} SimulateRawICADataRequest
                     */
                    SimulateRawICADataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SimulateRawICADataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.SimulateRawICADataRequest();
                        if (object.rawIcaConsistencyId != null)
                            if ($util.Long)
                                (message.rawIcaConsistencyId = $util.Long.fromValue(object.rawIcaConsistencyId)).unsigned = false;
                            else if (typeof object.rawIcaConsistencyId === "string")
                                message.rawIcaConsistencyId = parseInt(object.rawIcaConsistencyId, 10);
                            else if (typeof object.rawIcaConsistencyId === "number")
                                message.rawIcaConsistencyId = object.rawIcaConsistencyId;
                            else if (typeof object.rawIcaConsistencyId === "object")
                                message.rawIcaConsistencyId = new $util.LongBits(object.rawIcaConsistencyId.low >>> 0, object.rawIcaConsistencyId.high >>> 0).toNumber();
                        if (object.sourceStoreId != null)
                            if ($util.Long)
                                (message.sourceStoreId = $util.Long.fromValue(object.sourceStoreId)).unsigned = false;
                            else if (typeof object.sourceStoreId === "string")
                                message.sourceStoreId = parseInt(object.sourceStoreId, 10);
                            else if (typeof object.sourceStoreId === "number")
                                message.sourceStoreId = object.sourceStoreId;
                            else if (typeof object.sourceStoreId === "object")
                                message.sourceStoreId = new $util.LongBits(object.sourceStoreId.low >>> 0, object.sourceStoreId.high >>> 0).toNumber();
                        if (object.sourceDateId != null)
                            message.sourceDateId = object.sourceDateId | 0;
                        if (object.intervals) {
                            if (!Array.isArray(object.intervals))
                                throw TypeError(".palexy.streaming.v1.SimulateRawICADataRequest.intervals: array expected");
                            message.intervals = [];
                            for (var i = 0; i < object.intervals.length; ++i) {
                                if (typeof object.intervals[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.SimulateRawICADataRequest.intervals: object expected");
                                message.intervals[i] = $root.palexy.streaming.v1.TimeRange.fromObject(object.intervals[i]);
                            }
                        }
                        if (object.dryRun != null)
                            message.dryRun = Boolean(object.dryRun);
                        if (object.editorAccountId != null)
                            if ($util.Long)
                                (message.editorAccountId = $util.Long.fromValue(object.editorAccountId)).unsigned = false;
                            else if (typeof object.editorAccountId === "string")
                                message.editorAccountId = parseInt(object.editorAccountId, 10);
                            else if (typeof object.editorAccountId === "number")
                                message.editorAccountId = object.editorAccountId;
                            else if (typeof object.editorAccountId === "object")
                                message.editorAccountId = new $util.LongBits(object.editorAccountId.low >>> 0, object.editorAccountId.high >>> 0).toNumber();
                        if (object.cameraIds) {
                            if (!Array.isArray(object.cameraIds))
                                throw TypeError(".palexy.streaming.v1.SimulateRawICADataRequest.cameraIds: array expected");
                            message.cameraIds = [];
                            for (var i = 0; i < object.cameraIds.length; ++i)
                                message.cameraIds[i] = String(object.cameraIds[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SimulateRawICADataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.SimulateRawICADataRequest} message SimulateRawICADataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SimulateRawICADataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.intervals = [];
                            object.cameraIds = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.rawIcaConsistencyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.rawIcaConsistencyId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sourceStoreId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sourceStoreId = options.longs === String ? "0" : 0;
                            object.sourceDateId = 0;
                            object.dryRun = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.editorAccountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.editorAccountId = options.longs === String ? "0" : 0;
                        }
                        if (message.rawIcaConsistencyId != null && message.hasOwnProperty("rawIcaConsistencyId"))
                            if (typeof message.rawIcaConsistencyId === "number")
                                object.rawIcaConsistencyId = options.longs === String ? String(message.rawIcaConsistencyId) : message.rawIcaConsistencyId;
                            else
                                object.rawIcaConsistencyId = options.longs === String ? $util.Long.prototype.toString.call(message.rawIcaConsistencyId) : options.longs === Number ? new $util.LongBits(message.rawIcaConsistencyId.low >>> 0, message.rawIcaConsistencyId.high >>> 0).toNumber() : message.rawIcaConsistencyId;
                        if (message.sourceStoreId != null && message.hasOwnProperty("sourceStoreId"))
                            if (typeof message.sourceStoreId === "number")
                                object.sourceStoreId = options.longs === String ? String(message.sourceStoreId) : message.sourceStoreId;
                            else
                                object.sourceStoreId = options.longs === String ? $util.Long.prototype.toString.call(message.sourceStoreId) : options.longs === Number ? new $util.LongBits(message.sourceStoreId.low >>> 0, message.sourceStoreId.high >>> 0).toNumber() : message.sourceStoreId;
                        if (message.sourceDateId != null && message.hasOwnProperty("sourceDateId"))
                            object.sourceDateId = message.sourceDateId;
                        if (message.intervals && message.intervals.length) {
                            object.intervals = [];
                            for (var j = 0; j < message.intervals.length; ++j)
                                object.intervals[j] = $root.palexy.streaming.v1.TimeRange.toObject(message.intervals[j], options);
                        }
                        if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                            object.dryRun = message.dryRun;
                        if (message.editorAccountId != null && message.hasOwnProperty("editorAccountId"))
                            if (typeof message.editorAccountId === "number")
                                object.editorAccountId = options.longs === String ? String(message.editorAccountId) : message.editorAccountId;
                            else
                                object.editorAccountId = options.longs === String ? $util.Long.prototype.toString.call(message.editorAccountId) : options.longs === Number ? new $util.LongBits(message.editorAccountId.low >>> 0, message.editorAccountId.high >>> 0).toNumber() : message.editorAccountId;
                        if (message.cameraIds && message.cameraIds.length) {
                            object.cameraIds = [];
                            for (var j = 0; j < message.cameraIds.length; ++j)
                                object.cameraIds[j] = message.cameraIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this SimulateRawICADataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SimulateRawICADataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SimulateRawICADataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SimulateRawICADataRequest;
                })();
    
                v1.SimulateRawICADataResponse = (function() {
    
                    /**
                     * Properties of a SimulateRawICADataResponse.
                     * @memberof palexy.streaming.v1
                     * @interface ISimulateRawICADataResponse
                     * @property {number|null} [sourceTotalVisits] SimulateRawICADataResponse sourceTotalVisits
                     * @property {number|null} [finalTotalVisits] SimulateRawICADataResponse finalTotalVisits
                     * @property {number|null} [addedVisitsCount] SimulateRawICADataResponse addedVisitsCount
                     * @property {number|null} [removedVisitsCount] SimulateRawICADataResponse removedVisitsCount
                     * @property {number|null} [finalConversionRate] SimulateRawICADataResponse finalConversionRate
                     */
    
                    /**
                     * Constructs a new SimulateRawICADataResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SimulateRawICADataResponse.
                     * @implements ISimulateRawICADataResponse
                     * @constructor
                     * @param {palexy.streaming.v1.ISimulateRawICADataResponse=} [properties] Properties to set
                     */
                    function SimulateRawICADataResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SimulateRawICADataResponse sourceTotalVisits.
                     * @member {number} sourceTotalVisits
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @instance
                     */
                    SimulateRawICADataResponse.prototype.sourceTotalVisits = 0;
    
                    /**
                     * SimulateRawICADataResponse finalTotalVisits.
                     * @member {number} finalTotalVisits
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @instance
                     */
                    SimulateRawICADataResponse.prototype.finalTotalVisits = 0;
    
                    /**
                     * SimulateRawICADataResponse addedVisitsCount.
                     * @member {number} addedVisitsCount
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @instance
                     */
                    SimulateRawICADataResponse.prototype.addedVisitsCount = 0;
    
                    /**
                     * SimulateRawICADataResponse removedVisitsCount.
                     * @member {number} removedVisitsCount
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @instance
                     */
                    SimulateRawICADataResponse.prototype.removedVisitsCount = 0;
    
                    /**
                     * SimulateRawICADataResponse finalConversionRate.
                     * @member {number} finalConversionRate
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @instance
                     */
                    SimulateRawICADataResponse.prototype.finalConversionRate = 0;
    
                    /**
                     * Creates a new SimulateRawICADataResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.ISimulateRawICADataResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SimulateRawICADataResponse} SimulateRawICADataResponse instance
                     */
                    SimulateRawICADataResponse.create = function create(properties) {
                        return new SimulateRawICADataResponse(properties);
                    };
    
                    /**
                     * Encodes the specified SimulateRawICADataResponse message. Does not implicitly {@link palexy.streaming.v1.SimulateRawICADataResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.ISimulateRawICADataResponse} message SimulateRawICADataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SimulateRawICADataResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sourceTotalVisits != null && Object.hasOwnProperty.call(message, "sourceTotalVisits"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.sourceTotalVisits);
                        if (message.finalTotalVisits != null && Object.hasOwnProperty.call(message, "finalTotalVisits"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.finalTotalVisits);
                        if (message.addedVisitsCount != null && Object.hasOwnProperty.call(message, "addedVisitsCount"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.addedVisitsCount);
                        if (message.removedVisitsCount != null && Object.hasOwnProperty.call(message, "removedVisitsCount"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.removedVisitsCount);
                        if (message.finalConversionRate != null && Object.hasOwnProperty.call(message, "finalConversionRate"))
                            writer.uint32(/* id 5, wireType 1 =*/41).double(message.finalConversionRate);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SimulateRawICADataResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.SimulateRawICADataResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.ISimulateRawICADataResponse} message SimulateRawICADataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SimulateRawICADataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SimulateRawICADataResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SimulateRawICADataResponse} SimulateRawICADataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SimulateRawICADataResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SimulateRawICADataResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sourceTotalVisits = reader.int32();
                                break;
                            case 2:
                                message.finalTotalVisits = reader.int32();
                                break;
                            case 3:
                                message.addedVisitsCount = reader.int32();
                                break;
                            case 4:
                                message.removedVisitsCount = reader.int32();
                                break;
                            case 5:
                                message.finalConversionRate = reader.double();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SimulateRawICADataResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SimulateRawICADataResponse} SimulateRawICADataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SimulateRawICADataResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SimulateRawICADataResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SimulateRawICADataResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sourceTotalVisits != null && message.hasOwnProperty("sourceTotalVisits"))
                            if (!$util.isInteger(message.sourceTotalVisits))
                                return "sourceTotalVisits: integer expected";
                        if (message.finalTotalVisits != null && message.hasOwnProperty("finalTotalVisits"))
                            if (!$util.isInteger(message.finalTotalVisits))
                                return "finalTotalVisits: integer expected";
                        if (message.addedVisitsCount != null && message.hasOwnProperty("addedVisitsCount"))
                            if (!$util.isInteger(message.addedVisitsCount))
                                return "addedVisitsCount: integer expected";
                        if (message.removedVisitsCount != null && message.hasOwnProperty("removedVisitsCount"))
                            if (!$util.isInteger(message.removedVisitsCount))
                                return "removedVisitsCount: integer expected";
                        if (message.finalConversionRate != null && message.hasOwnProperty("finalConversionRate"))
                            if (typeof message.finalConversionRate !== "number")
                                return "finalConversionRate: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a SimulateRawICADataResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SimulateRawICADataResponse} SimulateRawICADataResponse
                     */
                    SimulateRawICADataResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SimulateRawICADataResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.SimulateRawICADataResponse();
                        if (object.sourceTotalVisits != null)
                            message.sourceTotalVisits = object.sourceTotalVisits | 0;
                        if (object.finalTotalVisits != null)
                            message.finalTotalVisits = object.finalTotalVisits | 0;
                        if (object.addedVisitsCount != null)
                            message.addedVisitsCount = object.addedVisitsCount | 0;
                        if (object.removedVisitsCount != null)
                            message.removedVisitsCount = object.removedVisitsCount | 0;
                        if (object.finalConversionRate != null)
                            message.finalConversionRate = Number(object.finalConversionRate);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SimulateRawICADataResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.SimulateRawICADataResponse} message SimulateRawICADataResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SimulateRawICADataResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.sourceTotalVisits = 0;
                            object.finalTotalVisits = 0;
                            object.addedVisitsCount = 0;
                            object.removedVisitsCount = 0;
                            object.finalConversionRate = 0;
                        }
                        if (message.sourceTotalVisits != null && message.hasOwnProperty("sourceTotalVisits"))
                            object.sourceTotalVisits = message.sourceTotalVisits;
                        if (message.finalTotalVisits != null && message.hasOwnProperty("finalTotalVisits"))
                            object.finalTotalVisits = message.finalTotalVisits;
                        if (message.addedVisitsCount != null && message.hasOwnProperty("addedVisitsCount"))
                            object.addedVisitsCount = message.addedVisitsCount;
                        if (message.removedVisitsCount != null && message.hasOwnProperty("removedVisitsCount"))
                            object.removedVisitsCount = message.removedVisitsCount;
                        if (message.finalConversionRate != null && message.hasOwnProperty("finalConversionRate"))
                            object.finalConversionRate = options.json && !isFinite(message.finalConversionRate) ? String(message.finalConversionRate) : message.finalConversionRate;
                        return object;
                    };
    
                    /**
                     * Converts this SimulateRawICADataResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SimulateRawICADataResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SimulateRawICADataResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SimulateRawICADataResponse;
                })();
    
                v1.RevertSimulatedRawICADataRequest = (function() {
    
                    /**
                     * Properties of a RevertSimulatedRawICADataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IRevertSimulatedRawICADataRequest
                     * @property {number|null} [rawIcaConsistencyId] RevertSimulatedRawICADataRequest rawIcaConsistencyId
                     * @property {number|null} [editorAccountId] RevertSimulatedRawICADataRequest editorAccountId
                     */
    
                    /**
                     * Constructs a new RevertSimulatedRawICADataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RevertSimulatedRawICADataRequest.
                     * @implements IRevertSimulatedRawICADataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IRevertSimulatedRawICADataRequest=} [properties] Properties to set
                     */
                    function RevertSimulatedRawICADataRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RevertSimulatedRawICADataRequest rawIcaConsistencyId.
                     * @member {number} rawIcaConsistencyId
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataRequest
                     * @instance
                     */
                    RevertSimulatedRawICADataRequest.prototype.rawIcaConsistencyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RevertSimulatedRawICADataRequest editorAccountId.
                     * @member {number} editorAccountId
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataRequest
                     * @instance
                     */
                    RevertSimulatedRawICADataRequest.prototype.editorAccountId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new RevertSimulatedRawICADataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.IRevertSimulatedRawICADataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RevertSimulatedRawICADataRequest} RevertSimulatedRawICADataRequest instance
                     */
                    RevertSimulatedRawICADataRequest.create = function create(properties) {
                        return new RevertSimulatedRawICADataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified RevertSimulatedRawICADataRequest message. Does not implicitly {@link palexy.streaming.v1.RevertSimulatedRawICADataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.IRevertSimulatedRawICADataRequest} message RevertSimulatedRawICADataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RevertSimulatedRawICADataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rawIcaConsistencyId != null && Object.hasOwnProperty.call(message, "rawIcaConsistencyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.rawIcaConsistencyId);
                        if (message.editorAccountId != null && Object.hasOwnProperty.call(message, "editorAccountId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.editorAccountId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RevertSimulatedRawICADataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.RevertSimulatedRawICADataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.IRevertSimulatedRawICADataRequest} message RevertSimulatedRawICADataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RevertSimulatedRawICADataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RevertSimulatedRawICADataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RevertSimulatedRawICADataRequest} RevertSimulatedRawICADataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RevertSimulatedRawICADataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RevertSimulatedRawICADataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.rawIcaConsistencyId = reader.int64();
                                break;
                            case 2:
                                message.editorAccountId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RevertSimulatedRawICADataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RevertSimulatedRawICADataRequest} RevertSimulatedRawICADataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RevertSimulatedRawICADataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RevertSimulatedRawICADataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RevertSimulatedRawICADataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rawIcaConsistencyId != null && message.hasOwnProperty("rawIcaConsistencyId"))
                            if (!$util.isInteger(message.rawIcaConsistencyId) && !(message.rawIcaConsistencyId && $util.isInteger(message.rawIcaConsistencyId.low) && $util.isInteger(message.rawIcaConsistencyId.high)))
                                return "rawIcaConsistencyId: integer|Long expected";
                        if (message.editorAccountId != null && message.hasOwnProperty("editorAccountId"))
                            if (!$util.isInteger(message.editorAccountId) && !(message.editorAccountId && $util.isInteger(message.editorAccountId.low) && $util.isInteger(message.editorAccountId.high)))
                                return "editorAccountId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a RevertSimulatedRawICADataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RevertSimulatedRawICADataRequest} RevertSimulatedRawICADataRequest
                     */
                    RevertSimulatedRawICADataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RevertSimulatedRawICADataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.RevertSimulatedRawICADataRequest();
                        if (object.rawIcaConsistencyId != null)
                            if ($util.Long)
                                (message.rawIcaConsistencyId = $util.Long.fromValue(object.rawIcaConsistencyId)).unsigned = false;
                            else if (typeof object.rawIcaConsistencyId === "string")
                                message.rawIcaConsistencyId = parseInt(object.rawIcaConsistencyId, 10);
                            else if (typeof object.rawIcaConsistencyId === "number")
                                message.rawIcaConsistencyId = object.rawIcaConsistencyId;
                            else if (typeof object.rawIcaConsistencyId === "object")
                                message.rawIcaConsistencyId = new $util.LongBits(object.rawIcaConsistencyId.low >>> 0, object.rawIcaConsistencyId.high >>> 0).toNumber();
                        if (object.editorAccountId != null)
                            if ($util.Long)
                                (message.editorAccountId = $util.Long.fromValue(object.editorAccountId)).unsigned = false;
                            else if (typeof object.editorAccountId === "string")
                                message.editorAccountId = parseInt(object.editorAccountId, 10);
                            else if (typeof object.editorAccountId === "number")
                                message.editorAccountId = object.editorAccountId;
                            else if (typeof object.editorAccountId === "object")
                                message.editorAccountId = new $util.LongBits(object.editorAccountId.low >>> 0, object.editorAccountId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RevertSimulatedRawICADataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataRequest
                     * @static
                     * @param {palexy.streaming.v1.RevertSimulatedRawICADataRequest} message RevertSimulatedRawICADataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RevertSimulatedRawICADataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.rawIcaConsistencyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.rawIcaConsistencyId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.editorAccountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.editorAccountId = options.longs === String ? "0" : 0;
                        }
                        if (message.rawIcaConsistencyId != null && message.hasOwnProperty("rawIcaConsistencyId"))
                            if (typeof message.rawIcaConsistencyId === "number")
                                object.rawIcaConsistencyId = options.longs === String ? String(message.rawIcaConsistencyId) : message.rawIcaConsistencyId;
                            else
                                object.rawIcaConsistencyId = options.longs === String ? $util.Long.prototype.toString.call(message.rawIcaConsistencyId) : options.longs === Number ? new $util.LongBits(message.rawIcaConsistencyId.low >>> 0, message.rawIcaConsistencyId.high >>> 0).toNumber() : message.rawIcaConsistencyId;
                        if (message.editorAccountId != null && message.hasOwnProperty("editorAccountId"))
                            if (typeof message.editorAccountId === "number")
                                object.editorAccountId = options.longs === String ? String(message.editorAccountId) : message.editorAccountId;
                            else
                                object.editorAccountId = options.longs === String ? $util.Long.prototype.toString.call(message.editorAccountId) : options.longs === Number ? new $util.LongBits(message.editorAccountId.low >>> 0, message.editorAccountId.high >>> 0).toNumber() : message.editorAccountId;
                        return object;
                    };
    
                    /**
                     * Converts this RevertSimulatedRawICADataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RevertSimulatedRawICADataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RevertSimulatedRawICADataRequest;
                })();
    
                v1.RevertSimulatedRawICADataResponse = (function() {
    
                    /**
                     * Properties of a RevertSimulatedRawICADataResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IRevertSimulatedRawICADataResponse
                     * @property {palexy.streaming.v1.RawICAConsistency.State|null} [newState] RevertSimulatedRawICADataResponse newState
                     */
    
                    /**
                     * Constructs a new RevertSimulatedRawICADataResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RevertSimulatedRawICADataResponse.
                     * @implements IRevertSimulatedRawICADataResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IRevertSimulatedRawICADataResponse=} [properties] Properties to set
                     */
                    function RevertSimulatedRawICADataResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RevertSimulatedRawICADataResponse newState.
                     * @member {palexy.streaming.v1.RawICAConsistency.State} newState
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataResponse
                     * @instance
                     */
                    RevertSimulatedRawICADataResponse.prototype.newState = 0;
    
                    /**
                     * Creates a new RevertSimulatedRawICADataResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.IRevertSimulatedRawICADataResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RevertSimulatedRawICADataResponse} RevertSimulatedRawICADataResponse instance
                     */
                    RevertSimulatedRawICADataResponse.create = function create(properties) {
                        return new RevertSimulatedRawICADataResponse(properties);
                    };
    
                    /**
                     * Encodes the specified RevertSimulatedRawICADataResponse message. Does not implicitly {@link palexy.streaming.v1.RevertSimulatedRawICADataResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.IRevertSimulatedRawICADataResponse} message RevertSimulatedRawICADataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RevertSimulatedRawICADataResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.newState != null && Object.hasOwnProperty.call(message, "newState"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.newState);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RevertSimulatedRawICADataResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.RevertSimulatedRawICADataResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.IRevertSimulatedRawICADataResponse} message RevertSimulatedRawICADataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RevertSimulatedRawICADataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RevertSimulatedRawICADataResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RevertSimulatedRawICADataResponse} RevertSimulatedRawICADataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RevertSimulatedRawICADataResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RevertSimulatedRawICADataResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.newState = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RevertSimulatedRawICADataResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RevertSimulatedRawICADataResponse} RevertSimulatedRawICADataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RevertSimulatedRawICADataResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RevertSimulatedRawICADataResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RevertSimulatedRawICADataResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.newState != null && message.hasOwnProperty("newState"))
                            switch (message.newState) {
                            default:
                                return "newState: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a RevertSimulatedRawICADataResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RevertSimulatedRawICADataResponse} RevertSimulatedRawICADataResponse
                     */
                    RevertSimulatedRawICADataResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RevertSimulatedRawICADataResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.RevertSimulatedRawICADataResponse();
                        switch (object.newState) {
                        case "UNKNOWN":
                        case 0:
                            message.newState = 0;
                            break;
                        case "NORMAL":
                        case 1:
                            message.newState = 1;
                            break;
                        case "CONSIDERABLE_LOSS":
                        case 2:
                            message.newState = 2;
                            break;
                        case "CONSIDERABLE_LOSS_BUT_INSIGNIFICANT":
                        case 3:
                            message.newState = 3;
                            break;
                        case "SIMULATED":
                        case 4:
                            message.newState = 4;
                            break;
                        case "INVALID":
                        case 5:
                            message.newState = 5;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RevertSimulatedRawICADataResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataResponse
                     * @static
                     * @param {palexy.streaming.v1.RevertSimulatedRawICADataResponse} message RevertSimulatedRawICADataResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RevertSimulatedRawICADataResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.newState = options.enums === String ? "UNKNOWN" : 0;
                        if (message.newState != null && message.hasOwnProperty("newState"))
                            object.newState = options.enums === String ? $root.palexy.streaming.v1.RawICAConsistency.State[message.newState] : message.newState;
                        return object;
                    };
    
                    /**
                     * Converts this RevertSimulatedRawICADataResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RevertSimulatedRawICADataResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RevertSimulatedRawICADataResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RevertSimulatedRawICADataResponse;
                })();
    
                v1.ReportService = (function() {
    
                    /**
                     * Constructs a new ReportService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ReportService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ReportService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (ReportService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ReportService;
    
                    /**
                     * Creates new ReportService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.ReportService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ReportService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ReportService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ReportService#getReport}.
                     * @memberof palexy.streaming.v1.ReportService
                     * @typedef GetReportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.GetReportResponse} [response] GetReportResponse
                     */
    
                    /**
                     * Calls GetReport.
                     * @function getReport
                     * @memberof palexy.streaming.v1.ReportService
                     * @instance
                     * @param {palexy.streaming.v1.IGetReportRequest} request GetReportRequest message or plain object
                     * @param {palexy.streaming.v1.ReportService.GetReportCallback} callback Node-style callback called with the error, if any, and GetReportResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ReportService.prototype.getReport = function getReport(request, callback) {
                        return this.rpcCall(getReport, $root.palexy.streaming.v1.GetReportRequest, $root.palexy.streaming.v1.GetReportResponse, request, callback);
                    }, "name", { value: "GetReport" });
    
                    /**
                     * Calls GetReport.
                     * @function getReport
                     * @memberof palexy.streaming.v1.ReportService
                     * @instance
                     * @param {palexy.streaming.v1.IGetReportRequest} request GetReportRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.GetReportResponse>} Promise
                     * @variation 2
                     */
    
                    return ReportService;
                })();
    
                v1.GetReportRequest = (function() {
    
                    /**
                     * Properties of a GetReportRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetReportRequest
                     * @property {Array.<string>|null} [dates] GetReportRequest dates
                     * @property {Array.<string>|null} [dimensions] GetReportRequest dimensions
                     * @property {Array.<string>|null} [metrics] GetReportRequest metrics
                     * @property {Array.<string>|null} [sorts] GetReportRequest sorts
                     * @property {palexy.streaming.v1.GetReportRequest.IFilter|null} [filter] GetReportRequest filter
                     * @property {boolean|null} [showTotals] GetReportRequest showTotals
                     * @property {boolean|null} [includeTargets] GetReportRequest includeTargets
                     */
    
                    /**
                     * Constructs a new GetReportRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetReportRequest.
                     * @implements IGetReportRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetReportRequest=} [properties] Properties to set
                     */
                    function GetReportRequest(properties) {
                        this.dates = [];
                        this.dimensions = [];
                        this.metrics = [];
                        this.sorts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetReportRequest dates.
                     * @member {Array.<string>} dates
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @instance
                     */
                    GetReportRequest.prototype.dates = $util.emptyArray;
    
                    /**
                     * GetReportRequest dimensions.
                     * @member {Array.<string>} dimensions
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @instance
                     */
                    GetReportRequest.prototype.dimensions = $util.emptyArray;
    
                    /**
                     * GetReportRequest metrics.
                     * @member {Array.<string>} metrics
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @instance
                     */
                    GetReportRequest.prototype.metrics = $util.emptyArray;
    
                    /**
                     * GetReportRequest sorts.
                     * @member {Array.<string>} sorts
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @instance
                     */
                    GetReportRequest.prototype.sorts = $util.emptyArray;
    
                    /**
                     * GetReportRequest filter.
                     * @member {palexy.streaming.v1.GetReportRequest.IFilter|null|undefined} filter
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @instance
                     */
                    GetReportRequest.prototype.filter = null;
    
                    /**
                     * GetReportRequest showTotals.
                     * @member {boolean} showTotals
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @instance
                     */
                    GetReportRequest.prototype.showTotals = false;
    
                    /**
                     * GetReportRequest includeTargets.
                     * @member {boolean} includeTargets
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @instance
                     */
                    GetReportRequest.prototype.includeTargets = false;
    
                    /**
                     * Creates a new GetReportRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetReportRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetReportRequest} GetReportRequest instance
                     */
                    GetReportRequest.create = function create(properties) {
                        return new GetReportRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetReportRequest message. Does not implicitly {@link palexy.streaming.v1.GetReportRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetReportRequest} message GetReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetReportRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dates != null && message.dates.length)
                            for (var i = 0; i < message.dates.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dates[i]);
                        if (message.dimensions != null && message.dimensions.length)
                            for (var i = 0; i < message.dimensions.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dimensions[i]);
                        if (message.metrics != null && message.metrics.length)
                            for (var i = 0; i < message.metrics.length; ++i)
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.metrics[i]);
                        if (message.sorts != null && message.sorts.length)
                            for (var i = 0; i < message.sorts.length; ++i)
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.sorts[i]);
                        if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                            $root.palexy.streaming.v1.GetReportRequest.Filter.encode(message.filter, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.showTotals != null && Object.hasOwnProperty.call(message, "showTotals"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.showTotals);
                        if (message.includeTargets != null && Object.hasOwnProperty.call(message, "includeTargets"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.includeTargets);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetReportRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetReportRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetReportRequest} message GetReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetReportRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetReportRequest} GetReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetReportRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetReportRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.dates && message.dates.length))
                                    message.dates = [];
                                message.dates.push(reader.string());
                                break;
                            case 2:
                                if (!(message.dimensions && message.dimensions.length))
                                    message.dimensions = [];
                                message.dimensions.push(reader.string());
                                break;
                            case 3:
                                if (!(message.metrics && message.metrics.length))
                                    message.metrics = [];
                                message.metrics.push(reader.string());
                                break;
                            case 4:
                                if (!(message.sorts && message.sorts.length))
                                    message.sorts = [];
                                message.sorts.push(reader.string());
                                break;
                            case 5:
                                message.filter = $root.palexy.streaming.v1.GetReportRequest.Filter.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.showTotals = reader.bool();
                                break;
                            case 7:
                                message.includeTargets = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetReportRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetReportRequest} GetReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetReportRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetReportRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetReportRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dates != null && message.hasOwnProperty("dates")) {
                            if (!Array.isArray(message.dates))
                                return "dates: array expected";
                            for (var i = 0; i < message.dates.length; ++i)
                                if (!$util.isString(message.dates[i]))
                                    return "dates: string[] expected";
                        }
                        if (message.dimensions != null && message.hasOwnProperty("dimensions")) {
                            if (!Array.isArray(message.dimensions))
                                return "dimensions: array expected";
                            for (var i = 0; i < message.dimensions.length; ++i)
                                if (!$util.isString(message.dimensions[i]))
                                    return "dimensions: string[] expected";
                        }
                        if (message.metrics != null && message.hasOwnProperty("metrics")) {
                            if (!Array.isArray(message.metrics))
                                return "metrics: array expected";
                            for (var i = 0; i < message.metrics.length; ++i)
                                if (!$util.isString(message.metrics[i]))
                                    return "metrics: string[] expected";
                        }
                        if (message.sorts != null && message.hasOwnProperty("sorts")) {
                            if (!Array.isArray(message.sorts))
                                return "sorts: array expected";
                            for (var i = 0; i < message.sorts.length; ++i)
                                if (!$util.isString(message.sorts[i]))
                                    return "sorts: string[] expected";
                        }
                        if (message.filter != null && message.hasOwnProperty("filter")) {
                            var error = $root.palexy.streaming.v1.GetReportRequest.Filter.verify(message.filter);
                            if (error)
                                return "filter." + error;
                        }
                        if (message.showTotals != null && message.hasOwnProperty("showTotals"))
                            if (typeof message.showTotals !== "boolean")
                                return "showTotals: boolean expected";
                        if (message.includeTargets != null && message.hasOwnProperty("includeTargets"))
                            if (typeof message.includeTargets !== "boolean")
                                return "includeTargets: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetReportRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetReportRequest} GetReportRequest
                     */
                    GetReportRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetReportRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetReportRequest();
                        if (object.dates) {
                            if (!Array.isArray(object.dates))
                                throw TypeError(".palexy.streaming.v1.GetReportRequest.dates: array expected");
                            message.dates = [];
                            for (var i = 0; i < object.dates.length; ++i)
                                message.dates[i] = String(object.dates[i]);
                        }
                        if (object.dimensions) {
                            if (!Array.isArray(object.dimensions))
                                throw TypeError(".palexy.streaming.v1.GetReportRequest.dimensions: array expected");
                            message.dimensions = [];
                            for (var i = 0; i < object.dimensions.length; ++i)
                                message.dimensions[i] = String(object.dimensions[i]);
                        }
                        if (object.metrics) {
                            if (!Array.isArray(object.metrics))
                                throw TypeError(".palexy.streaming.v1.GetReportRequest.metrics: array expected");
                            message.metrics = [];
                            for (var i = 0; i < object.metrics.length; ++i)
                                message.metrics[i] = String(object.metrics[i]);
                        }
                        if (object.sorts) {
                            if (!Array.isArray(object.sorts))
                                throw TypeError(".palexy.streaming.v1.GetReportRequest.sorts: array expected");
                            message.sorts = [];
                            for (var i = 0; i < object.sorts.length; ++i)
                                message.sorts[i] = String(object.sorts[i]);
                        }
                        if (object.filter != null) {
                            if (typeof object.filter !== "object")
                                throw TypeError(".palexy.streaming.v1.GetReportRequest.filter: object expected");
                            message.filter = $root.palexy.streaming.v1.GetReportRequest.Filter.fromObject(object.filter);
                        }
                        if (object.showTotals != null)
                            message.showTotals = Boolean(object.showTotals);
                        if (object.includeTargets != null)
                            message.includeTargets = Boolean(object.includeTargets);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetReportRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @static
                     * @param {palexy.streaming.v1.GetReportRequest} message GetReportRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetReportRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.dates = [];
                            object.dimensions = [];
                            object.metrics = [];
                            object.sorts = [];
                        }
                        if (options.defaults) {
                            object.filter = null;
                            object.showTotals = false;
                            object.includeTargets = false;
                        }
                        if (message.dates && message.dates.length) {
                            object.dates = [];
                            for (var j = 0; j < message.dates.length; ++j)
                                object.dates[j] = message.dates[j];
                        }
                        if (message.dimensions && message.dimensions.length) {
                            object.dimensions = [];
                            for (var j = 0; j < message.dimensions.length; ++j)
                                object.dimensions[j] = message.dimensions[j];
                        }
                        if (message.metrics && message.metrics.length) {
                            object.metrics = [];
                            for (var j = 0; j < message.metrics.length; ++j)
                                object.metrics[j] = message.metrics[j];
                        }
                        if (message.sorts && message.sorts.length) {
                            object.sorts = [];
                            for (var j = 0; j < message.sorts.length; ++j)
                                object.sorts[j] = message.sorts[j];
                        }
                        if (message.filter != null && message.hasOwnProperty("filter"))
                            object.filter = $root.palexy.streaming.v1.GetReportRequest.Filter.toObject(message.filter, options);
                        if (message.showTotals != null && message.hasOwnProperty("showTotals"))
                            object.showTotals = message.showTotals;
                        if (message.includeTargets != null && message.hasOwnProperty("includeTargets"))
                            object.includeTargets = message.includeTargets;
                        return object;
                    };
    
                    /**
                     * Converts this GetReportRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetReportRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetReportRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    GetReportRequest.Filter = (function() {
    
                        /**
                         * Properties of a Filter.
                         * @memberof palexy.streaming.v1.GetReportRequest
                         * @interface IFilter
                         * @property {number|null} [companyId] Filter companyId
                         * @property {Array.<number>|null} [storeIds] Filter storeIds
                         * @property {string|null} [gender] Filter gender
                         * @property {Array.<number>|null} [ageRanges] Filter ageRanges
                         * @property {Array.<number>|null} [hourBins] Filter hourBins
                         * @property {Array.<number>|null} [metadataValueIds] Filter metadataValueIds
                         * @property {Array.<palexy.streaming.v1.GetReportRequest.IDimensionFilter>|null} [dimensionFilters] Filter dimensionFilters
                         */
    
                        /**
                         * Constructs a new Filter.
                         * @memberof palexy.streaming.v1.GetReportRequest
                         * @classdesc Represents a Filter.
                         * @implements IFilter
                         * @constructor
                         * @param {palexy.streaming.v1.GetReportRequest.IFilter=} [properties] Properties to set
                         */
                        function Filter(properties) {
                            this.storeIds = [];
                            this.ageRanges = [];
                            this.hourBins = [];
                            this.metadataValueIds = [];
                            this.dimensionFilters = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * Filter companyId.
                         * @member {number} companyId
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * Filter storeIds.
                         * @member {Array.<number>} storeIds
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.storeIds = $util.emptyArray;
    
                        /**
                         * Filter gender.
                         * @member {string} gender
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.gender = "";
    
                        /**
                         * Filter ageRanges.
                         * @member {Array.<number>} ageRanges
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.ageRanges = $util.emptyArray;
    
                        /**
                         * Filter hourBins.
                         * @member {Array.<number>} hourBins
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.hourBins = $util.emptyArray;
    
                        /**
                         * Filter metadataValueIds.
                         * @member {Array.<number>} metadataValueIds
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.metadataValueIds = $util.emptyArray;
    
                        /**
                         * Filter dimensionFilters.
                         * @member {Array.<palexy.streaming.v1.GetReportRequest.IDimensionFilter>} dimensionFilters
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @instance
                         */
                        Filter.prototype.dimensionFilters = $util.emptyArray;
    
                        /**
                         * Creates a new Filter instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @static
                         * @param {palexy.streaming.v1.GetReportRequest.IFilter=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.GetReportRequest.Filter} Filter instance
                         */
                        Filter.create = function create(properties) {
                            return new Filter(properties);
                        };
    
                        /**
                         * Encodes the specified Filter message. Does not implicitly {@link palexy.streaming.v1.GetReportRequest.Filter.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @static
                         * @param {palexy.streaming.v1.GetReportRequest.IFilter} message Filter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Filter.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                            if (message.storeIds != null && message.storeIds.length) {
                                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                                for (var i = 0; i < message.storeIds.length; ++i)
                                    writer.int64(message.storeIds[i]);
                                writer.ldelim();
                            }
                            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.gender);
                            if (message.ageRanges != null && message.ageRanges.length) {
                                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                                for (var i = 0; i < message.ageRanges.length; ++i)
                                    writer.int32(message.ageRanges[i]);
                                writer.ldelim();
                            }
                            if (message.hourBins != null && message.hourBins.length) {
                                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                                for (var i = 0; i < message.hourBins.length; ++i)
                                    writer.int32(message.hourBins[i]);
                                writer.ldelim();
                            }
                            if (message.metadataValueIds != null && message.metadataValueIds.length) {
                                writer.uint32(/* id 6, wireType 2 =*/50).fork();
                                for (var i = 0; i < message.metadataValueIds.length; ++i)
                                    writer.int64(message.metadataValueIds[i]);
                                writer.ldelim();
                            }
                            if (message.dimensionFilters != null && message.dimensionFilters.length)
                                for (var i = 0; i < message.dimensionFilters.length; ++i)
                                    $root.palexy.streaming.v1.GetReportRequest.DimensionFilter.encode(message.dimensionFilters[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            return writer;
                        };
    
                        /**
                         * Encodes the specified Filter message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetReportRequest.Filter.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @static
                         * @param {palexy.streaming.v1.GetReportRequest.IFilter} message Filter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Filter.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a Filter message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.GetReportRequest.Filter} Filter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Filter.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetReportRequest.Filter();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.companyId = reader.int64();
                                    break;
                                case 2:
                                    if (!(message.storeIds && message.storeIds.length))
                                        message.storeIds = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.storeIds.push(reader.int64());
                                    } else
                                        message.storeIds.push(reader.int64());
                                    break;
                                case 3:
                                    message.gender = reader.string();
                                    break;
                                case 4:
                                    if (!(message.ageRanges && message.ageRanges.length))
                                        message.ageRanges = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.ageRanges.push(reader.int32());
                                    } else
                                        message.ageRanges.push(reader.int32());
                                    break;
                                case 5:
                                    if (!(message.hourBins && message.hourBins.length))
                                        message.hourBins = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.hourBins.push(reader.int32());
                                    } else
                                        message.hourBins.push(reader.int32());
                                    break;
                                case 6:
                                    if (!(message.metadataValueIds && message.metadataValueIds.length))
                                        message.metadataValueIds = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.metadataValueIds.push(reader.int64());
                                    } else
                                        message.metadataValueIds.push(reader.int64());
                                    break;
                                case 7:
                                    if (!(message.dimensionFilters && message.dimensionFilters.length))
                                        message.dimensionFilters = [];
                                    message.dimensionFilters.push($root.palexy.streaming.v1.GetReportRequest.DimensionFilter.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a Filter message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.GetReportRequest.Filter} Filter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Filter.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a Filter message.
                         * @function verify
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Filter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.companyId != null && message.hasOwnProperty("companyId"))
                                if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                    return "companyId: integer|Long expected";
                            if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                                if (!Array.isArray(message.storeIds))
                                    return "storeIds: array expected";
                                for (var i = 0; i < message.storeIds.length; ++i)
                                    if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                        return "storeIds: integer|Long[] expected";
                            }
                            if (message.gender != null && message.hasOwnProperty("gender"))
                                if (!$util.isString(message.gender))
                                    return "gender: string expected";
                            if (message.ageRanges != null && message.hasOwnProperty("ageRanges")) {
                                if (!Array.isArray(message.ageRanges))
                                    return "ageRanges: array expected";
                                for (var i = 0; i < message.ageRanges.length; ++i)
                                    if (!$util.isInteger(message.ageRanges[i]))
                                        return "ageRanges: integer[] expected";
                            }
                            if (message.hourBins != null && message.hasOwnProperty("hourBins")) {
                                if (!Array.isArray(message.hourBins))
                                    return "hourBins: array expected";
                                for (var i = 0; i < message.hourBins.length; ++i)
                                    if (!$util.isInteger(message.hourBins[i]))
                                        return "hourBins: integer[] expected";
                            }
                            if (message.metadataValueIds != null && message.hasOwnProperty("metadataValueIds")) {
                                if (!Array.isArray(message.metadataValueIds))
                                    return "metadataValueIds: array expected";
                                for (var i = 0; i < message.metadataValueIds.length; ++i)
                                    if (!$util.isInteger(message.metadataValueIds[i]) && !(message.metadataValueIds[i] && $util.isInteger(message.metadataValueIds[i].low) && $util.isInteger(message.metadataValueIds[i].high)))
                                        return "metadataValueIds: integer|Long[] expected";
                            }
                            if (message.dimensionFilters != null && message.hasOwnProperty("dimensionFilters")) {
                                if (!Array.isArray(message.dimensionFilters))
                                    return "dimensionFilters: array expected";
                                for (var i = 0; i < message.dimensionFilters.length; ++i) {
                                    var error = $root.palexy.streaming.v1.GetReportRequest.DimensionFilter.verify(message.dimensionFilters[i]);
                                    if (error)
                                        return "dimensionFilters." + error;
                                }
                            }
                            return null;
                        };
    
                        /**
                         * Creates a Filter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.GetReportRequest.Filter} Filter
                         */
                        Filter.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.GetReportRequest.Filter)
                                return object;
                            var message = new $root.palexy.streaming.v1.GetReportRequest.Filter();
                            if (object.companyId != null)
                                if ($util.Long)
                                    (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                                else if (typeof object.companyId === "string")
                                    message.companyId = parseInt(object.companyId, 10);
                                else if (typeof object.companyId === "number")
                                    message.companyId = object.companyId;
                                else if (typeof object.companyId === "object")
                                    message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                            if (object.storeIds) {
                                if (!Array.isArray(object.storeIds))
                                    throw TypeError(".palexy.streaming.v1.GetReportRequest.Filter.storeIds: array expected");
                                message.storeIds = [];
                                for (var i = 0; i < object.storeIds.length; ++i)
                                    if ($util.Long)
                                        (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                    else if (typeof object.storeIds[i] === "string")
                                        message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                    else if (typeof object.storeIds[i] === "number")
                                        message.storeIds[i] = object.storeIds[i];
                                    else if (typeof object.storeIds[i] === "object")
                                        message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                            }
                            if (object.gender != null)
                                message.gender = String(object.gender);
                            if (object.ageRanges) {
                                if (!Array.isArray(object.ageRanges))
                                    throw TypeError(".palexy.streaming.v1.GetReportRequest.Filter.ageRanges: array expected");
                                message.ageRanges = [];
                                for (var i = 0; i < object.ageRanges.length; ++i)
                                    message.ageRanges[i] = object.ageRanges[i] | 0;
                            }
                            if (object.hourBins) {
                                if (!Array.isArray(object.hourBins))
                                    throw TypeError(".palexy.streaming.v1.GetReportRequest.Filter.hourBins: array expected");
                                message.hourBins = [];
                                for (var i = 0; i < object.hourBins.length; ++i)
                                    message.hourBins[i] = object.hourBins[i] | 0;
                            }
                            if (object.metadataValueIds) {
                                if (!Array.isArray(object.metadataValueIds))
                                    throw TypeError(".palexy.streaming.v1.GetReportRequest.Filter.metadataValueIds: array expected");
                                message.metadataValueIds = [];
                                for (var i = 0; i < object.metadataValueIds.length; ++i)
                                    if ($util.Long)
                                        (message.metadataValueIds[i] = $util.Long.fromValue(object.metadataValueIds[i])).unsigned = false;
                                    else if (typeof object.metadataValueIds[i] === "string")
                                        message.metadataValueIds[i] = parseInt(object.metadataValueIds[i], 10);
                                    else if (typeof object.metadataValueIds[i] === "number")
                                        message.metadataValueIds[i] = object.metadataValueIds[i];
                                    else if (typeof object.metadataValueIds[i] === "object")
                                        message.metadataValueIds[i] = new $util.LongBits(object.metadataValueIds[i].low >>> 0, object.metadataValueIds[i].high >>> 0).toNumber();
                            }
                            if (object.dimensionFilters) {
                                if (!Array.isArray(object.dimensionFilters))
                                    throw TypeError(".palexy.streaming.v1.GetReportRequest.Filter.dimensionFilters: array expected");
                                message.dimensionFilters = [];
                                for (var i = 0; i < object.dimensionFilters.length; ++i) {
                                    if (typeof object.dimensionFilters[i] !== "object")
                                        throw TypeError(".palexy.streaming.v1.GetReportRequest.Filter.dimensionFilters: object expected");
                                    message.dimensionFilters[i] = $root.palexy.streaming.v1.GetReportRequest.DimensionFilter.fromObject(object.dimensionFilters[i]);
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a Filter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @static
                         * @param {palexy.streaming.v1.GetReportRequest.Filter} message Filter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Filter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.storeIds = [];
                                object.ageRanges = [];
                                object.hourBins = [];
                                object.metadataValueIds = [];
                                object.dimensionFilters = [];
                            }
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.companyId = options.longs === String ? "0" : 0;
                                object.gender = "";
                            }
                            if (message.companyId != null && message.hasOwnProperty("companyId"))
                                if (typeof message.companyId === "number")
                                    object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                                else
                                    object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                            if (message.storeIds && message.storeIds.length) {
                                object.storeIds = [];
                                for (var j = 0; j < message.storeIds.length; ++j)
                                    if (typeof message.storeIds[j] === "number")
                                        object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                    else
                                        object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                            }
                            if (message.gender != null && message.hasOwnProperty("gender"))
                                object.gender = message.gender;
                            if (message.ageRanges && message.ageRanges.length) {
                                object.ageRanges = [];
                                for (var j = 0; j < message.ageRanges.length; ++j)
                                    object.ageRanges[j] = message.ageRanges[j];
                            }
                            if (message.hourBins && message.hourBins.length) {
                                object.hourBins = [];
                                for (var j = 0; j < message.hourBins.length; ++j)
                                    object.hourBins[j] = message.hourBins[j];
                            }
                            if (message.metadataValueIds && message.metadataValueIds.length) {
                                object.metadataValueIds = [];
                                for (var j = 0; j < message.metadataValueIds.length; ++j)
                                    if (typeof message.metadataValueIds[j] === "number")
                                        object.metadataValueIds[j] = options.longs === String ? String(message.metadataValueIds[j]) : message.metadataValueIds[j];
                                    else
                                        object.metadataValueIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.metadataValueIds[j]) : options.longs === Number ? new $util.LongBits(message.metadataValueIds[j].low >>> 0, message.metadataValueIds[j].high >>> 0).toNumber() : message.metadataValueIds[j];
                            }
                            if (message.dimensionFilters && message.dimensionFilters.length) {
                                object.dimensionFilters = [];
                                for (var j = 0; j < message.dimensionFilters.length; ++j)
                                    object.dimensionFilters[j] = $root.palexy.streaming.v1.GetReportRequest.DimensionFilter.toObject(message.dimensionFilters[j], options);
                            }
                            return object;
                        };
    
                        /**
                         * Converts this Filter to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.GetReportRequest.Filter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Filter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return Filter;
                    })();
    
                    GetReportRequest.DimensionFilter = (function() {
    
                        /**
                         * Properties of a DimensionFilter.
                         * @memberof palexy.streaming.v1.GetReportRequest
                         * @interface IDimensionFilter
                         * @property {string|null} [dimension] DimensionFilter dimension
                         * @property {Array.<string>|null} [values] DimensionFilter values
                         */
    
                        /**
                         * Constructs a new DimensionFilter.
                         * @memberof palexy.streaming.v1.GetReportRequest
                         * @classdesc Represents a DimensionFilter.
                         * @implements IDimensionFilter
                         * @constructor
                         * @param {palexy.streaming.v1.GetReportRequest.IDimensionFilter=} [properties] Properties to set
                         */
                        function DimensionFilter(properties) {
                            this.values = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * DimensionFilter dimension.
                         * @member {string} dimension
                         * @memberof palexy.streaming.v1.GetReportRequest.DimensionFilter
                         * @instance
                         */
                        DimensionFilter.prototype.dimension = "";
    
                        /**
                         * DimensionFilter values.
                         * @member {Array.<string>} values
                         * @memberof palexy.streaming.v1.GetReportRequest.DimensionFilter
                         * @instance
                         */
                        DimensionFilter.prototype.values = $util.emptyArray;
    
                        /**
                         * Creates a new DimensionFilter instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.GetReportRequest.DimensionFilter
                         * @static
                         * @param {palexy.streaming.v1.GetReportRequest.IDimensionFilter=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.GetReportRequest.DimensionFilter} DimensionFilter instance
                         */
                        DimensionFilter.create = function create(properties) {
                            return new DimensionFilter(properties);
                        };
    
                        /**
                         * Encodes the specified DimensionFilter message. Does not implicitly {@link palexy.streaming.v1.GetReportRequest.DimensionFilter.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.GetReportRequest.DimensionFilter
                         * @static
                         * @param {palexy.streaming.v1.GetReportRequest.IDimensionFilter} message DimensionFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DimensionFilter.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.dimension != null && Object.hasOwnProperty.call(message, "dimension"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dimension);
                            if (message.values != null && message.values.length)
                                for (var i = 0; i < message.values.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.values[i]);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified DimensionFilter message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetReportRequest.DimensionFilter.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.GetReportRequest.DimensionFilter
                         * @static
                         * @param {palexy.streaming.v1.GetReportRequest.IDimensionFilter} message DimensionFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DimensionFilter.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a DimensionFilter message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.GetReportRequest.DimensionFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.GetReportRequest.DimensionFilter} DimensionFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DimensionFilter.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetReportRequest.DimensionFilter();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.dimension = reader.string();
                                    break;
                                case 2:
                                    if (!(message.values && message.values.length))
                                        message.values = [];
                                    message.values.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a DimensionFilter message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.GetReportRequest.DimensionFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.GetReportRequest.DimensionFilter} DimensionFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DimensionFilter.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a DimensionFilter message.
                         * @function verify
                         * @memberof palexy.streaming.v1.GetReportRequest.DimensionFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DimensionFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.dimension != null && message.hasOwnProperty("dimension"))
                                if (!$util.isString(message.dimension))
                                    return "dimension: string expected";
                            if (message.values != null && message.hasOwnProperty("values")) {
                                if (!Array.isArray(message.values))
                                    return "values: array expected";
                                for (var i = 0; i < message.values.length; ++i)
                                    if (!$util.isString(message.values[i]))
                                        return "values: string[] expected";
                            }
                            return null;
                        };
    
                        /**
                         * Creates a DimensionFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.GetReportRequest.DimensionFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.GetReportRequest.DimensionFilter} DimensionFilter
                         */
                        DimensionFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.GetReportRequest.DimensionFilter)
                                return object;
                            var message = new $root.palexy.streaming.v1.GetReportRequest.DimensionFilter();
                            if (object.dimension != null)
                                message.dimension = String(object.dimension);
                            if (object.values) {
                                if (!Array.isArray(object.values))
                                    throw TypeError(".palexy.streaming.v1.GetReportRequest.DimensionFilter.values: array expected");
                                message.values = [];
                                for (var i = 0; i < object.values.length; ++i)
                                    message.values[i] = String(object.values[i]);
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a DimensionFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.GetReportRequest.DimensionFilter
                         * @static
                         * @param {palexy.streaming.v1.GetReportRequest.DimensionFilter} message DimensionFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DimensionFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.values = [];
                            if (options.defaults)
                                object.dimension = "";
                            if (message.dimension != null && message.hasOwnProperty("dimension"))
                                object.dimension = message.dimension;
                            if (message.values && message.values.length) {
                                object.values = [];
                                for (var j = 0; j < message.values.length; ++j)
                                    object.values[j] = message.values[j];
                            }
                            return object;
                        };
    
                        /**
                         * Converts this DimensionFilter to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.GetReportRequest.DimensionFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DimensionFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return DimensionFilter;
                    })();
    
                    return GetReportRequest;
                })();
    
                v1.GetReportResponse = (function() {
    
                    /**
                     * Properties of a GetReportResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IGetReportResponse
                     * @property {Array.<string>|null} [dates] GetReportResponse dates
                     * @property {Array.<string>|null} [dimensions] GetReportResponse dimensions
                     * @property {Array.<number>|null} [metricTotals] GetReportResponse metricTotals
                     * @property {Array.<palexy.streaming.v1.GetReportResponse.IMetric>|null} [metrics] GetReportResponse metrics
                     * @property {Array.<palexy.streaming.v1.GetReportResponse.IRow>|null} [rows] GetReportResponse rows
                     */
    
                    /**
                     * Constructs a new GetReportResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetReportResponse.
                     * @implements IGetReportResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IGetReportResponse=} [properties] Properties to set
                     */
                    function GetReportResponse(properties) {
                        this.dates = [];
                        this.dimensions = [];
                        this.metricTotals = [];
                        this.metrics = [];
                        this.rows = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetReportResponse dates.
                     * @member {Array.<string>} dates
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @instance
                     */
                    GetReportResponse.prototype.dates = $util.emptyArray;
    
                    /**
                     * GetReportResponse dimensions.
                     * @member {Array.<string>} dimensions
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @instance
                     */
                    GetReportResponse.prototype.dimensions = $util.emptyArray;
    
                    /**
                     * GetReportResponse metricTotals.
                     * @member {Array.<number>} metricTotals
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @instance
                     */
                    GetReportResponse.prototype.metricTotals = $util.emptyArray;
    
                    /**
                     * GetReportResponse metrics.
                     * @member {Array.<palexy.streaming.v1.GetReportResponse.IMetric>} metrics
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @instance
                     */
                    GetReportResponse.prototype.metrics = $util.emptyArray;
    
                    /**
                     * GetReportResponse rows.
                     * @member {Array.<palexy.streaming.v1.GetReportResponse.IRow>} rows
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @instance
                     */
                    GetReportResponse.prototype.rows = $util.emptyArray;
    
                    /**
                     * Creates a new GetReportResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetReportResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetReportResponse} GetReportResponse instance
                     */
                    GetReportResponse.create = function create(properties) {
                        return new GetReportResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetReportResponse message. Does not implicitly {@link palexy.streaming.v1.GetReportResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetReportResponse} message GetReportResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetReportResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dates != null && message.dates.length)
                            for (var i = 0; i < message.dates.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dates[i]);
                        if (message.dimensions != null && message.dimensions.length)
                            for (var i = 0; i < message.dimensions.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dimensions[i]);
                        if (message.metricTotals != null && message.metricTotals.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.metricTotals.length; ++i)
                                writer.double(message.metricTotals[i]);
                            writer.ldelim();
                        }
                        if (message.metrics != null && message.metrics.length)
                            for (var i = 0; i < message.metrics.length; ++i)
                                $root.palexy.streaming.v1.GetReportResponse.Metric.encode(message.metrics[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.rows != null && message.rows.length)
                            for (var i = 0; i < message.rows.length; ++i)
                                $root.palexy.streaming.v1.GetReportResponse.Row.encode(message.rows[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetReportResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetReportResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetReportResponse} message GetReportResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetReportResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetReportResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetReportResponse} GetReportResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetReportResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetReportResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.dates && message.dates.length))
                                    message.dates = [];
                                message.dates.push(reader.string());
                                break;
                            case 2:
                                if (!(message.dimensions && message.dimensions.length))
                                    message.dimensions = [];
                                message.dimensions.push(reader.string());
                                break;
                            case 3:
                                if (!(message.metricTotals && message.metricTotals.length))
                                    message.metricTotals = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.metricTotals.push(reader.double());
                                } else
                                    message.metricTotals.push(reader.double());
                                break;
                            case 4:
                                if (!(message.metrics && message.metrics.length))
                                    message.metrics = [];
                                message.metrics.push($root.palexy.streaming.v1.GetReportResponse.Metric.decode(reader, reader.uint32()));
                                break;
                            case 5:
                                if (!(message.rows && message.rows.length))
                                    message.rows = [];
                                message.rows.push($root.palexy.streaming.v1.GetReportResponse.Row.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetReportResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetReportResponse} GetReportResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetReportResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetReportResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetReportResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dates != null && message.hasOwnProperty("dates")) {
                            if (!Array.isArray(message.dates))
                                return "dates: array expected";
                            for (var i = 0; i < message.dates.length; ++i)
                                if (!$util.isString(message.dates[i]))
                                    return "dates: string[] expected";
                        }
                        if (message.dimensions != null && message.hasOwnProperty("dimensions")) {
                            if (!Array.isArray(message.dimensions))
                                return "dimensions: array expected";
                            for (var i = 0; i < message.dimensions.length; ++i)
                                if (!$util.isString(message.dimensions[i]))
                                    return "dimensions: string[] expected";
                        }
                        if (message.metricTotals != null && message.hasOwnProperty("metricTotals")) {
                            if (!Array.isArray(message.metricTotals))
                                return "metricTotals: array expected";
                            for (var i = 0; i < message.metricTotals.length; ++i)
                                if (typeof message.metricTotals[i] !== "number")
                                    return "metricTotals: number[] expected";
                        }
                        if (message.metrics != null && message.hasOwnProperty("metrics")) {
                            if (!Array.isArray(message.metrics))
                                return "metrics: array expected";
                            for (var i = 0; i < message.metrics.length; ++i) {
                                var error = $root.palexy.streaming.v1.GetReportResponse.Metric.verify(message.metrics[i]);
                                if (error)
                                    return "metrics." + error;
                            }
                        }
                        if (message.rows != null && message.hasOwnProperty("rows")) {
                            if (!Array.isArray(message.rows))
                                return "rows: array expected";
                            for (var i = 0; i < message.rows.length; ++i) {
                                var error = $root.palexy.streaming.v1.GetReportResponse.Row.verify(message.rows[i]);
                                if (error)
                                    return "rows." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetReportResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetReportResponse} GetReportResponse
                     */
                    GetReportResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetReportResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetReportResponse();
                        if (object.dates) {
                            if (!Array.isArray(object.dates))
                                throw TypeError(".palexy.streaming.v1.GetReportResponse.dates: array expected");
                            message.dates = [];
                            for (var i = 0; i < object.dates.length; ++i)
                                message.dates[i] = String(object.dates[i]);
                        }
                        if (object.dimensions) {
                            if (!Array.isArray(object.dimensions))
                                throw TypeError(".palexy.streaming.v1.GetReportResponse.dimensions: array expected");
                            message.dimensions = [];
                            for (var i = 0; i < object.dimensions.length; ++i)
                                message.dimensions[i] = String(object.dimensions[i]);
                        }
                        if (object.metricTotals) {
                            if (!Array.isArray(object.metricTotals))
                                throw TypeError(".palexy.streaming.v1.GetReportResponse.metricTotals: array expected");
                            message.metricTotals = [];
                            for (var i = 0; i < object.metricTotals.length; ++i)
                                message.metricTotals[i] = Number(object.metricTotals[i]);
                        }
                        if (object.metrics) {
                            if (!Array.isArray(object.metrics))
                                throw TypeError(".palexy.streaming.v1.GetReportResponse.metrics: array expected");
                            message.metrics = [];
                            for (var i = 0; i < object.metrics.length; ++i) {
                                if (typeof object.metrics[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.GetReportResponse.metrics: object expected");
                                message.metrics[i] = $root.palexy.streaming.v1.GetReportResponse.Metric.fromObject(object.metrics[i]);
                            }
                        }
                        if (object.rows) {
                            if (!Array.isArray(object.rows))
                                throw TypeError(".palexy.streaming.v1.GetReportResponse.rows: array expected");
                            message.rows = [];
                            for (var i = 0; i < object.rows.length; ++i) {
                                if (typeof object.rows[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.GetReportResponse.rows: object expected");
                                message.rows[i] = $root.palexy.streaming.v1.GetReportResponse.Row.fromObject(object.rows[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetReportResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @static
                     * @param {palexy.streaming.v1.GetReportResponse} message GetReportResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetReportResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.dates = [];
                            object.dimensions = [];
                            object.metricTotals = [];
                            object.metrics = [];
                            object.rows = [];
                        }
                        if (message.dates && message.dates.length) {
                            object.dates = [];
                            for (var j = 0; j < message.dates.length; ++j)
                                object.dates[j] = message.dates[j];
                        }
                        if (message.dimensions && message.dimensions.length) {
                            object.dimensions = [];
                            for (var j = 0; j < message.dimensions.length; ++j)
                                object.dimensions[j] = message.dimensions[j];
                        }
                        if (message.metricTotals && message.metricTotals.length) {
                            object.metricTotals = [];
                            for (var j = 0; j < message.metricTotals.length; ++j)
                                object.metricTotals[j] = options.json && !isFinite(message.metricTotals[j]) ? String(message.metricTotals[j]) : message.metricTotals[j];
                        }
                        if (message.metrics && message.metrics.length) {
                            object.metrics = [];
                            for (var j = 0; j < message.metrics.length; ++j)
                                object.metrics[j] = $root.palexy.streaming.v1.GetReportResponse.Metric.toObject(message.metrics[j], options);
                        }
                        if (message.rows && message.rows.length) {
                            object.rows = [];
                            for (var j = 0; j < message.rows.length; ++j)
                                object.rows[j] = $root.palexy.streaming.v1.GetReportResponse.Row.toObject(message.rows[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetReportResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetReportResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetReportResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    GetReportResponse.Metric = (function() {
    
                        /**
                         * Properties of a Metric.
                         * @memberof palexy.streaming.v1.GetReportResponse
                         * @interface IMetric
                         * @property {string|null} [id] Metric id
                         * @property {number|null} [dateRangeIndex] Metric dateRangeIndex
                         * @property {boolean|null} [isTarget] Metric isTarget
                         */
    
                        /**
                         * Constructs a new Metric.
                         * @memberof palexy.streaming.v1.GetReportResponse
                         * @classdesc Represents a Metric.
                         * @implements IMetric
                         * @constructor
                         * @param {palexy.streaming.v1.GetReportResponse.IMetric=} [properties] Properties to set
                         */
                        function Metric(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * Metric id.
                         * @member {string} id
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @instance
                         */
                        Metric.prototype.id = "";
    
                        /**
                         * Metric dateRangeIndex.
                         * @member {number} dateRangeIndex
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @instance
                         */
                        Metric.prototype.dateRangeIndex = 0;
    
                        /**
                         * Metric isTarget.
                         * @member {boolean} isTarget
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @instance
                         */
                        Metric.prototype.isTarget = false;
    
                        /**
                         * Creates a new Metric instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @static
                         * @param {palexy.streaming.v1.GetReportResponse.IMetric=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.GetReportResponse.Metric} Metric instance
                         */
                        Metric.create = function create(properties) {
                            return new Metric(properties);
                        };
    
                        /**
                         * Encodes the specified Metric message. Does not implicitly {@link palexy.streaming.v1.GetReportResponse.Metric.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @static
                         * @param {palexy.streaming.v1.GetReportResponse.IMetric} message Metric message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Metric.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.dateRangeIndex != null && Object.hasOwnProperty.call(message, "dateRangeIndex"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateRangeIndex);
                            if (message.isTarget != null && Object.hasOwnProperty.call(message, "isTarget"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isTarget);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified Metric message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetReportResponse.Metric.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @static
                         * @param {palexy.streaming.v1.GetReportResponse.IMetric} message Metric message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Metric.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a Metric message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.GetReportResponse.Metric} Metric
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Metric.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetReportResponse.Metric();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.dateRangeIndex = reader.int32();
                                    break;
                                case 3:
                                    message.isTarget = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a Metric message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.GetReportResponse.Metric} Metric
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Metric.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a Metric message.
                         * @function verify
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Metric.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.dateRangeIndex != null && message.hasOwnProperty("dateRangeIndex"))
                                if (!$util.isInteger(message.dateRangeIndex))
                                    return "dateRangeIndex: integer expected";
                            if (message.isTarget != null && message.hasOwnProperty("isTarget"))
                                if (typeof message.isTarget !== "boolean")
                                    return "isTarget: boolean expected";
                            return null;
                        };
    
                        /**
                         * Creates a Metric message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.GetReportResponse.Metric} Metric
                         */
                        Metric.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.GetReportResponse.Metric)
                                return object;
                            var message = new $root.palexy.streaming.v1.GetReportResponse.Metric();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.dateRangeIndex != null)
                                message.dateRangeIndex = object.dateRangeIndex | 0;
                            if (object.isTarget != null)
                                message.isTarget = Boolean(object.isTarget);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a Metric message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @static
                         * @param {palexy.streaming.v1.GetReportResponse.Metric} message Metric
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Metric.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.dateRangeIndex = 0;
                                object.isTarget = false;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.dateRangeIndex != null && message.hasOwnProperty("dateRangeIndex"))
                                object.dateRangeIndex = message.dateRangeIndex;
                            if (message.isTarget != null && message.hasOwnProperty("isTarget"))
                                object.isTarget = message.isTarget;
                            return object;
                        };
    
                        /**
                         * Converts this Metric to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.GetReportResponse.Metric
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Metric.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return Metric;
                    })();
    
                    GetReportResponse.Row = (function() {
    
                        /**
                         * Properties of a Row.
                         * @memberof palexy.streaming.v1.GetReportResponse
                         * @interface IRow
                         * @property {Array.<string>|null} [dimensionValues] Row dimensionValues
                         * @property {Array.<number>|null} [metricValues] Row metricValues
                         */
    
                        /**
                         * Constructs a new Row.
                         * @memberof palexy.streaming.v1.GetReportResponse
                         * @classdesc Represents a Row.
                         * @implements IRow
                         * @constructor
                         * @param {palexy.streaming.v1.GetReportResponse.IRow=} [properties] Properties to set
                         */
                        function Row(properties) {
                            this.dimensionValues = [];
                            this.metricValues = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * Row dimensionValues.
                         * @member {Array.<string>} dimensionValues
                         * @memberof palexy.streaming.v1.GetReportResponse.Row
                         * @instance
                         */
                        Row.prototype.dimensionValues = $util.emptyArray;
    
                        /**
                         * Row metricValues.
                         * @member {Array.<number>} metricValues
                         * @memberof palexy.streaming.v1.GetReportResponse.Row
                         * @instance
                         */
                        Row.prototype.metricValues = $util.emptyArray;
    
                        /**
                         * Creates a new Row instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.GetReportResponse.Row
                         * @static
                         * @param {palexy.streaming.v1.GetReportResponse.IRow=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.GetReportResponse.Row} Row instance
                         */
                        Row.create = function create(properties) {
                            return new Row(properties);
                        };
    
                        /**
                         * Encodes the specified Row message. Does not implicitly {@link palexy.streaming.v1.GetReportResponse.Row.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.GetReportResponse.Row
                         * @static
                         * @param {palexy.streaming.v1.GetReportResponse.IRow} message Row message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Row.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.dimensionValues != null && message.dimensionValues.length)
                                for (var i = 0; i < message.dimensionValues.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.dimensionValues[i]);
                            if (message.metricValues != null && message.metricValues.length) {
                                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                                for (var i = 0; i < message.metricValues.length; ++i)
                                    writer.double(message.metricValues[i]);
                                writer.ldelim();
                            }
                            return writer;
                        };
    
                        /**
                         * Encodes the specified Row message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetReportResponse.Row.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.GetReportResponse.Row
                         * @static
                         * @param {palexy.streaming.v1.GetReportResponse.IRow} message Row message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Row.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a Row message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.GetReportResponse.Row
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.GetReportResponse.Row} Row
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Row.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetReportResponse.Row();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.dimensionValues && message.dimensionValues.length))
                                        message.dimensionValues = [];
                                    message.dimensionValues.push(reader.string());
                                    break;
                                case 2:
                                    if (!(message.metricValues && message.metricValues.length))
                                        message.metricValues = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.metricValues.push(reader.double());
                                    } else
                                        message.metricValues.push(reader.double());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a Row message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.GetReportResponse.Row
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.GetReportResponse.Row} Row
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Row.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a Row message.
                         * @function verify
                         * @memberof palexy.streaming.v1.GetReportResponse.Row
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Row.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.dimensionValues != null && message.hasOwnProperty("dimensionValues")) {
                                if (!Array.isArray(message.dimensionValues))
                                    return "dimensionValues: array expected";
                                for (var i = 0; i < message.dimensionValues.length; ++i)
                                    if (!$util.isString(message.dimensionValues[i]))
                                        return "dimensionValues: string[] expected";
                            }
                            if (message.metricValues != null && message.hasOwnProperty("metricValues")) {
                                if (!Array.isArray(message.metricValues))
                                    return "metricValues: array expected";
                                for (var i = 0; i < message.metricValues.length; ++i)
                                    if (typeof message.metricValues[i] !== "number")
                                        return "metricValues: number[] expected";
                            }
                            return null;
                        };
    
                        /**
                         * Creates a Row message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.GetReportResponse.Row
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.GetReportResponse.Row} Row
                         */
                        Row.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.GetReportResponse.Row)
                                return object;
                            var message = new $root.palexy.streaming.v1.GetReportResponse.Row();
                            if (object.dimensionValues) {
                                if (!Array.isArray(object.dimensionValues))
                                    throw TypeError(".palexy.streaming.v1.GetReportResponse.Row.dimensionValues: array expected");
                                message.dimensionValues = [];
                                for (var i = 0; i < object.dimensionValues.length; ++i)
                                    message.dimensionValues[i] = String(object.dimensionValues[i]);
                            }
                            if (object.metricValues) {
                                if (!Array.isArray(object.metricValues))
                                    throw TypeError(".palexy.streaming.v1.GetReportResponse.Row.metricValues: array expected");
                                message.metricValues = [];
                                for (var i = 0; i < object.metricValues.length; ++i)
                                    message.metricValues[i] = Number(object.metricValues[i]);
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a Row message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.GetReportResponse.Row
                         * @static
                         * @param {palexy.streaming.v1.GetReportResponse.Row} message Row
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Row.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.dimensionValues = [];
                                object.metricValues = [];
                            }
                            if (message.dimensionValues && message.dimensionValues.length) {
                                object.dimensionValues = [];
                                for (var j = 0; j < message.dimensionValues.length; ++j)
                                    object.dimensionValues[j] = message.dimensionValues[j];
                            }
                            if (message.metricValues && message.metricValues.length) {
                                object.metricValues = [];
                                for (var j = 0; j < message.metricValues.length; ++j)
                                    object.metricValues[j] = options.json && !isFinite(message.metricValues[j]) ? String(message.metricValues[j]) : message.metricValues[j];
                            }
                            return object;
                        };
    
                        /**
                         * Converts this Row to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.GetReportResponse.Row
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Row.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return Row;
                    })();
    
                    return GetReportResponse;
                })();
    
                v1.NpsMeasurementService = (function() {
    
                    /**
                     * Constructs a new NpsMeasurementService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a NpsMeasurementService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function NpsMeasurementService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (NpsMeasurementService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = NpsMeasurementService;
    
                    /**
                     * Creates new NpsMeasurementService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {NpsMeasurementService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    NpsMeasurementService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NpsMeasurementService#createNpsCampaign}.
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @typedef CreateNpsCampaignCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.NpsCampaign} [response] NpsCampaign
                     */
    
                    /**
                     * Calls CreateNpsCampaign.
                     * @function createNpsCampaign
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.INpsCampaign} request NpsCampaign message or plain object
                     * @param {palexy.streaming.v1.NpsMeasurementService.CreateNpsCampaignCallback} callback Node-style callback called with the error, if any, and NpsCampaign
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NpsMeasurementService.prototype.createNpsCampaign = function createNpsCampaign(request, callback) {
                        return this.rpcCall(createNpsCampaign, $root.palexy.streaming.v1.NpsCampaign, $root.palexy.streaming.v1.NpsCampaign, request, callback);
                    }, "name", { value: "CreateNpsCampaign" });
    
                    /**
                     * Calls CreateNpsCampaign.
                     * @function createNpsCampaign
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.INpsCampaign} request NpsCampaign message or plain object
                     * @returns {Promise<palexy.streaming.v1.NpsCampaign>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NpsMeasurementService#listNpsCampaign}.
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @typedef ListNpsCampaignCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListNpsCampaignResponse} [response] ListNpsCampaignResponse
                     */
    
                    /**
                     * Calls ListNpsCampaign.
                     * @function listNpsCampaign
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.IListNpsCampaignRequest} request ListNpsCampaignRequest message or plain object
                     * @param {palexy.streaming.v1.NpsMeasurementService.ListNpsCampaignCallback} callback Node-style callback called with the error, if any, and ListNpsCampaignResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NpsMeasurementService.prototype.listNpsCampaign = function listNpsCampaign(request, callback) {
                        return this.rpcCall(listNpsCampaign, $root.palexy.streaming.v1.ListNpsCampaignRequest, $root.palexy.streaming.v1.ListNpsCampaignResponse, request, callback);
                    }, "name", { value: "ListNpsCampaign" });
    
                    /**
                     * Calls ListNpsCampaign.
                     * @function listNpsCampaign
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.IListNpsCampaignRequest} request ListNpsCampaignRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListNpsCampaignResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NpsMeasurementService#getNpsCampaign}.
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @typedef GetNpsCampaignCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.NpsCampaign} [response] NpsCampaign
                     */
    
                    /**
                     * Calls GetNpsCampaign.
                     * @function getNpsCampaign
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.IGetNpsCampaignRequest} request GetNpsCampaignRequest message or plain object
                     * @param {palexy.streaming.v1.NpsMeasurementService.GetNpsCampaignCallback} callback Node-style callback called with the error, if any, and NpsCampaign
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NpsMeasurementService.prototype.getNpsCampaign = function getNpsCampaign(request, callback) {
                        return this.rpcCall(getNpsCampaign, $root.palexy.streaming.v1.GetNpsCampaignRequest, $root.palexy.streaming.v1.NpsCampaign, request, callback);
                    }, "name", { value: "GetNpsCampaign" });
    
                    /**
                     * Calls GetNpsCampaign.
                     * @function getNpsCampaign
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.IGetNpsCampaignRequest} request GetNpsCampaignRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.NpsCampaign>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NpsMeasurementService#updateNpsCampaign}.
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @typedef UpdateNpsCampaignCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.NpsCampaign} [response] NpsCampaign
                     */
    
                    /**
                     * Calls UpdateNpsCampaign.
                     * @function updateNpsCampaign
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateNpsCampaignRequest} request UpdateNpsCampaignRequest message or plain object
                     * @param {palexy.streaming.v1.NpsMeasurementService.UpdateNpsCampaignCallback} callback Node-style callback called with the error, if any, and NpsCampaign
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NpsMeasurementService.prototype.updateNpsCampaign = function updateNpsCampaign(request, callback) {
                        return this.rpcCall(updateNpsCampaign, $root.palexy.streaming.v1.UpdateNpsCampaignRequest, $root.palexy.streaming.v1.NpsCampaign, request, callback);
                    }, "name", { value: "UpdateNpsCampaign" });
    
                    /**
                     * Calls UpdateNpsCampaign.
                     * @function updateNpsCampaign
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateNpsCampaignRequest} request UpdateNpsCampaignRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.NpsCampaign>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NpsMeasurementService#getActiveNpsCampaign}.
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @typedef GetActiveNpsCampaignCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.GetActiveNpsCampaignResponse} [response] GetActiveNpsCampaignResponse
                     */
    
                    /**
                     * Calls GetActiveNpsCampaign.
                     * @function getActiveNpsCampaign
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.IGetActiveNpsCampaignRequest} request GetActiveNpsCampaignRequest message or plain object
                     * @param {palexy.streaming.v1.NpsMeasurementService.GetActiveNpsCampaignCallback} callback Node-style callback called with the error, if any, and GetActiveNpsCampaignResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NpsMeasurementService.prototype.getActiveNpsCampaign = function getActiveNpsCampaign(request, callback) {
                        return this.rpcCall(getActiveNpsCampaign, $root.palexy.streaming.v1.GetActiveNpsCampaignRequest, $root.palexy.streaming.v1.GetActiveNpsCampaignResponse, request, callback);
                    }, "name", { value: "GetActiveNpsCampaign" });
    
                    /**
                     * Calls GetActiveNpsCampaign.
                     * @function getActiveNpsCampaign
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.IGetActiveNpsCampaignRequest} request GetActiveNpsCampaignRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.GetActiveNpsCampaignResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.NpsMeasurementService#createNpsResponse}.
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @typedef CreateNpsResponseCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.NpsResponse} [response] NpsResponse
                     */
    
                    /**
                     * Calls CreateNpsResponse.
                     * @function createNpsResponse
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.INpsResponse} request NpsResponse message or plain object
                     * @param {palexy.streaming.v1.NpsMeasurementService.CreateNpsResponseCallback} callback Node-style callback called with the error, if any, and NpsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(NpsMeasurementService.prototype.createNpsResponse = function createNpsResponse(request, callback) {
                        return this.rpcCall(createNpsResponse, $root.palexy.streaming.v1.NpsResponse, $root.palexy.streaming.v1.NpsResponse, request, callback);
                    }, "name", { value: "CreateNpsResponse" });
    
                    /**
                     * Calls CreateNpsResponse.
                     * @function createNpsResponse
                     * @memberof palexy.streaming.v1.NpsMeasurementService
                     * @instance
                     * @param {palexy.streaming.v1.INpsResponse} request NpsResponse message or plain object
                     * @returns {Promise<palexy.streaming.v1.NpsResponse>} Promise
                     * @variation 2
                     */
    
                    return NpsMeasurementService;
                })();
    
                v1.ListNpsCampaignRequest = (function() {
    
                    /**
                     * Properties of a ListNpsCampaignRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListNpsCampaignRequest
                     * @property {number|null} [pageSize] ListNpsCampaignRequest pageSize
                     * @property {string|null} [pageToken] ListNpsCampaignRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListNpsCampaignRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListNpsCampaignRequest.
                     * @implements IListNpsCampaignRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListNpsCampaignRequest=} [properties] Properties to set
                     */
                    function ListNpsCampaignRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListNpsCampaignRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListNpsCampaignRequest
                     * @instance
                     */
                    ListNpsCampaignRequest.prototype.pageSize = 0;
    
                    /**
                     * ListNpsCampaignRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListNpsCampaignRequest
                     * @instance
                     */
                    ListNpsCampaignRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListNpsCampaignRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IListNpsCampaignRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListNpsCampaignRequest} ListNpsCampaignRequest instance
                     */
                    ListNpsCampaignRequest.create = function create(properties) {
                        return new ListNpsCampaignRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListNpsCampaignRequest message. Does not implicitly {@link palexy.streaming.v1.ListNpsCampaignRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IListNpsCampaignRequest} message ListNpsCampaignRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListNpsCampaignRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListNpsCampaignRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListNpsCampaignRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IListNpsCampaignRequest} message ListNpsCampaignRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListNpsCampaignRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListNpsCampaignRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListNpsCampaignRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListNpsCampaignRequest} ListNpsCampaignRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListNpsCampaignRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListNpsCampaignRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.pageSize = reader.int32();
                                break;
                            case 2:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListNpsCampaignRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListNpsCampaignRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListNpsCampaignRequest} ListNpsCampaignRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListNpsCampaignRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListNpsCampaignRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListNpsCampaignRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListNpsCampaignRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListNpsCampaignRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListNpsCampaignRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListNpsCampaignRequest} ListNpsCampaignRequest
                     */
                    ListNpsCampaignRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListNpsCampaignRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListNpsCampaignRequest();
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListNpsCampaignRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.ListNpsCampaignRequest} message ListNpsCampaignRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListNpsCampaignRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListNpsCampaignRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListNpsCampaignRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListNpsCampaignRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListNpsCampaignRequest;
                })();
    
                v1.ListNpsCampaignResponse = (function() {
    
                    /**
                     * Properties of a ListNpsCampaignResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListNpsCampaignResponse
                     * @property {Array.<palexy.streaming.v1.INpsCampaign>|null} [campaigns] ListNpsCampaignResponse campaigns
                     * @property {number|null} [totalElements] ListNpsCampaignResponse totalElements
                     * @property {string|null} [nextPageToken] ListNpsCampaignResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListNpsCampaignResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListNpsCampaignResponse.
                     * @implements IListNpsCampaignResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListNpsCampaignResponse=} [properties] Properties to set
                     */
                    function ListNpsCampaignResponse(properties) {
                        this.campaigns = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListNpsCampaignResponse campaigns.
                     * @member {Array.<palexy.streaming.v1.INpsCampaign>} campaigns
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @instance
                     */
                    ListNpsCampaignResponse.prototype.campaigns = $util.emptyArray;
    
                    /**
                     * ListNpsCampaignResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @instance
                     */
                    ListNpsCampaignResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListNpsCampaignResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @instance
                     */
                    ListNpsCampaignResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListNpsCampaignResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @static
                     * @param {palexy.streaming.v1.IListNpsCampaignResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListNpsCampaignResponse} ListNpsCampaignResponse instance
                     */
                    ListNpsCampaignResponse.create = function create(properties) {
                        return new ListNpsCampaignResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListNpsCampaignResponse message. Does not implicitly {@link palexy.streaming.v1.ListNpsCampaignResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @static
                     * @param {palexy.streaming.v1.IListNpsCampaignResponse} message ListNpsCampaignResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListNpsCampaignResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.campaigns != null && message.campaigns.length)
                            for (var i = 0; i < message.campaigns.length; ++i)
                                $root.palexy.streaming.v1.NpsCampaign.encode(message.campaigns[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListNpsCampaignResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListNpsCampaignResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @static
                     * @param {palexy.streaming.v1.IListNpsCampaignResponse} message ListNpsCampaignResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListNpsCampaignResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListNpsCampaignResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListNpsCampaignResponse} ListNpsCampaignResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListNpsCampaignResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListNpsCampaignResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.campaigns && message.campaigns.length))
                                    message.campaigns = [];
                                message.campaigns.push($root.palexy.streaming.v1.NpsCampaign.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int64();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListNpsCampaignResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListNpsCampaignResponse} ListNpsCampaignResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListNpsCampaignResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListNpsCampaignResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListNpsCampaignResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.campaigns != null && message.hasOwnProperty("campaigns")) {
                            if (!Array.isArray(message.campaigns))
                                return "campaigns: array expected";
                            for (var i = 0; i < message.campaigns.length; ++i) {
                                var error = $root.palexy.streaming.v1.NpsCampaign.verify(message.campaigns[i]);
                                if (error)
                                    return "campaigns." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListNpsCampaignResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListNpsCampaignResponse} ListNpsCampaignResponse
                     */
                    ListNpsCampaignResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListNpsCampaignResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListNpsCampaignResponse();
                        if (object.campaigns) {
                            if (!Array.isArray(object.campaigns))
                                throw TypeError(".palexy.streaming.v1.ListNpsCampaignResponse.campaigns: array expected");
                            message.campaigns = [];
                            for (var i = 0; i < object.campaigns.length; ++i) {
                                if (typeof object.campaigns[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListNpsCampaignResponse.campaigns: object expected");
                                message.campaigns[i] = $root.palexy.streaming.v1.NpsCampaign.fromObject(object.campaigns[i]);
                            }
                        }
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListNpsCampaignResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @static
                     * @param {palexy.streaming.v1.ListNpsCampaignResponse} message ListNpsCampaignResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListNpsCampaignResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.campaigns = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                            object.nextPageToken = "";
                        }
                        if (message.campaigns && message.campaigns.length) {
                            object.campaigns = [];
                            for (var j = 0; j < message.campaigns.length; ++j)
                                object.campaigns[j] = $root.palexy.streaming.v1.NpsCampaign.toObject(message.campaigns[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListNpsCampaignResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListNpsCampaignResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListNpsCampaignResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListNpsCampaignResponse;
                })();
    
                v1.GetNpsCampaignRequest = (function() {
    
                    /**
                     * Properties of a GetNpsCampaignRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetNpsCampaignRequest
                     * @property {number|null} [campaignId] GetNpsCampaignRequest campaignId
                     */
    
                    /**
                     * Constructs a new GetNpsCampaignRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetNpsCampaignRequest.
                     * @implements IGetNpsCampaignRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetNpsCampaignRequest=} [properties] Properties to set
                     */
                    function GetNpsCampaignRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetNpsCampaignRequest campaignId.
                     * @member {number} campaignId
                     * @memberof palexy.streaming.v1.GetNpsCampaignRequest
                     * @instance
                     */
                    GetNpsCampaignRequest.prototype.campaignId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetNpsCampaignRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetNpsCampaignRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetNpsCampaignRequest} GetNpsCampaignRequest instance
                     */
                    GetNpsCampaignRequest.create = function create(properties) {
                        return new GetNpsCampaignRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetNpsCampaignRequest message. Does not implicitly {@link palexy.streaming.v1.GetNpsCampaignRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetNpsCampaignRequest} message GetNpsCampaignRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNpsCampaignRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.campaignId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetNpsCampaignRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetNpsCampaignRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetNpsCampaignRequest} message GetNpsCampaignRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNpsCampaignRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetNpsCampaignRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetNpsCampaignRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetNpsCampaignRequest} GetNpsCampaignRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNpsCampaignRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetNpsCampaignRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.campaignId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetNpsCampaignRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetNpsCampaignRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetNpsCampaignRequest} GetNpsCampaignRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNpsCampaignRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetNpsCampaignRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetNpsCampaignRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetNpsCampaignRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                            if (!$util.isInteger(message.campaignId) && !(message.campaignId && $util.isInteger(message.campaignId.low) && $util.isInteger(message.campaignId.high)))
                                return "campaignId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetNpsCampaignRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetNpsCampaignRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetNpsCampaignRequest} GetNpsCampaignRequest
                     */
                    GetNpsCampaignRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetNpsCampaignRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetNpsCampaignRequest();
                        if (object.campaignId != null)
                            if ($util.Long)
                                (message.campaignId = $util.Long.fromValue(object.campaignId)).unsigned = false;
                            else if (typeof object.campaignId === "string")
                                message.campaignId = parseInt(object.campaignId, 10);
                            else if (typeof object.campaignId === "number")
                                message.campaignId = object.campaignId;
                            else if (typeof object.campaignId === "object")
                                message.campaignId = new $util.LongBits(object.campaignId.low >>> 0, object.campaignId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetNpsCampaignRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.GetNpsCampaignRequest} message GetNpsCampaignRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetNpsCampaignRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.campaignId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.campaignId = options.longs === String ? "0" : 0;
                        if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                            if (typeof message.campaignId === "number")
                                object.campaignId = options.longs === String ? String(message.campaignId) : message.campaignId;
                            else
                                object.campaignId = options.longs === String ? $util.Long.prototype.toString.call(message.campaignId) : options.longs === Number ? new $util.LongBits(message.campaignId.low >>> 0, message.campaignId.high >>> 0).toNumber() : message.campaignId;
                        return object;
                    };
    
                    /**
                     * Converts this GetNpsCampaignRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetNpsCampaignRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetNpsCampaignRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetNpsCampaignRequest;
                })();
    
                v1.UpdateNpsCampaignRequest = (function() {
    
                    /**
                     * Properties of an UpdateNpsCampaignRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateNpsCampaignRequest
                     * @property {palexy.streaming.v1.INpsCampaign|null} [campaign] UpdateNpsCampaignRequest campaign
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateNpsCampaignRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateNpsCampaignRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateNpsCampaignRequest.
                     * @implements IUpdateNpsCampaignRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateNpsCampaignRequest=} [properties] Properties to set
                     */
                    function UpdateNpsCampaignRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateNpsCampaignRequest campaign.
                     * @member {palexy.streaming.v1.INpsCampaign|null|undefined} campaign
                     * @memberof palexy.streaming.v1.UpdateNpsCampaignRequest
                     * @instance
                     */
                    UpdateNpsCampaignRequest.prototype.campaign = null;
    
                    /**
                     * UpdateNpsCampaignRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateNpsCampaignRequest
                     * @instance
                     */
                    UpdateNpsCampaignRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateNpsCampaignRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNpsCampaignRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateNpsCampaignRequest} UpdateNpsCampaignRequest instance
                     */
                    UpdateNpsCampaignRequest.create = function create(properties) {
                        return new UpdateNpsCampaignRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateNpsCampaignRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateNpsCampaignRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNpsCampaignRequest} message UpdateNpsCampaignRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateNpsCampaignRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.campaign != null && Object.hasOwnProperty.call(message, "campaign"))
                            $root.palexy.streaming.v1.NpsCampaign.encode(message.campaign, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateNpsCampaignRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateNpsCampaignRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateNpsCampaignRequest} message UpdateNpsCampaignRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateNpsCampaignRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateNpsCampaignRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateNpsCampaignRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateNpsCampaignRequest} UpdateNpsCampaignRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateNpsCampaignRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateNpsCampaignRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.campaign = $root.palexy.streaming.v1.NpsCampaign.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateNpsCampaignRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateNpsCampaignRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateNpsCampaignRequest} UpdateNpsCampaignRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateNpsCampaignRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateNpsCampaignRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateNpsCampaignRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateNpsCampaignRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.campaign != null && message.hasOwnProperty("campaign")) {
                            var error = $root.palexy.streaming.v1.NpsCampaign.verify(message.campaign);
                            if (error)
                                return "campaign." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateNpsCampaignRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateNpsCampaignRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateNpsCampaignRequest} UpdateNpsCampaignRequest
                     */
                    UpdateNpsCampaignRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateNpsCampaignRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateNpsCampaignRequest();
                        if (object.campaign != null) {
                            if (typeof object.campaign !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateNpsCampaignRequest.campaign: object expected");
                            message.campaign = $root.palexy.streaming.v1.NpsCampaign.fromObject(object.campaign);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateNpsCampaignRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateNpsCampaignRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateNpsCampaignRequest} message UpdateNpsCampaignRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateNpsCampaignRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.campaign = null;
                            object.updateMask = null;
                        }
                        if (message.campaign != null && message.hasOwnProperty("campaign"))
                            object.campaign = $root.palexy.streaming.v1.NpsCampaign.toObject(message.campaign, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateNpsCampaignRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateNpsCampaignRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateNpsCampaignRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateNpsCampaignRequest;
                })();
    
                v1.GetActiveNpsCampaignRequest = (function() {
    
                    /**
                     * Properties of a GetActiveNpsCampaignRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetActiveNpsCampaignRequest
                     * @property {number|null} [accountId] GetActiveNpsCampaignRequest accountId
                     */
    
                    /**
                     * Constructs a new GetActiveNpsCampaignRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetActiveNpsCampaignRequest.
                     * @implements IGetActiveNpsCampaignRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetActiveNpsCampaignRequest=} [properties] Properties to set
                     */
                    function GetActiveNpsCampaignRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetActiveNpsCampaignRequest accountId.
                     * @member {number} accountId
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignRequest
                     * @instance
                     */
                    GetActiveNpsCampaignRequest.prototype.accountId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetActiveNpsCampaignRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetActiveNpsCampaignRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetActiveNpsCampaignRequest} GetActiveNpsCampaignRequest instance
                     */
                    GetActiveNpsCampaignRequest.create = function create(properties) {
                        return new GetActiveNpsCampaignRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetActiveNpsCampaignRequest message. Does not implicitly {@link palexy.streaming.v1.GetActiveNpsCampaignRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetActiveNpsCampaignRequest} message GetActiveNpsCampaignRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetActiveNpsCampaignRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.accountId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetActiveNpsCampaignRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetActiveNpsCampaignRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetActiveNpsCampaignRequest} message GetActiveNpsCampaignRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetActiveNpsCampaignRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetActiveNpsCampaignRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetActiveNpsCampaignRequest} GetActiveNpsCampaignRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetActiveNpsCampaignRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetActiveNpsCampaignRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.accountId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetActiveNpsCampaignRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetActiveNpsCampaignRequest} GetActiveNpsCampaignRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetActiveNpsCampaignRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetActiveNpsCampaignRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetActiveNpsCampaignRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (!$util.isInteger(message.accountId) && !(message.accountId && $util.isInteger(message.accountId.low) && $util.isInteger(message.accountId.high)))
                                return "accountId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetActiveNpsCampaignRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetActiveNpsCampaignRequest} GetActiveNpsCampaignRequest
                     */
                    GetActiveNpsCampaignRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetActiveNpsCampaignRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetActiveNpsCampaignRequest();
                        if (object.accountId != null)
                            if ($util.Long)
                                (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = false;
                            else if (typeof object.accountId === "string")
                                message.accountId = parseInt(object.accountId, 10);
                            else if (typeof object.accountId === "number")
                                message.accountId = object.accountId;
                            else if (typeof object.accountId === "object")
                                message.accountId = new $util.LongBits(object.accountId.low >>> 0, object.accountId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetActiveNpsCampaignRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignRequest
                     * @static
                     * @param {palexy.streaming.v1.GetActiveNpsCampaignRequest} message GetActiveNpsCampaignRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetActiveNpsCampaignRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.accountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.accountId = options.longs === String ? "0" : 0;
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (typeof message.accountId === "number")
                                object.accountId = options.longs === String ? String(message.accountId) : message.accountId;
                            else
                                object.accountId = options.longs === String ? $util.Long.prototype.toString.call(message.accountId) : options.longs === Number ? new $util.LongBits(message.accountId.low >>> 0, message.accountId.high >>> 0).toNumber() : message.accountId;
                        return object;
                    };
    
                    /**
                     * Converts this GetActiveNpsCampaignRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetActiveNpsCampaignRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetActiveNpsCampaignRequest;
                })();
    
                v1.GetActiveNpsCampaignResponse = (function() {
    
                    /**
                     * Properties of a GetActiveNpsCampaignResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IGetActiveNpsCampaignResponse
                     * @property {palexy.streaming.v1.INpsCampaign|null} [campaign] GetActiveNpsCampaignResponse campaign
                     */
    
                    /**
                     * Constructs a new GetActiveNpsCampaignResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetActiveNpsCampaignResponse.
                     * @implements IGetActiveNpsCampaignResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IGetActiveNpsCampaignResponse=} [properties] Properties to set
                     */
                    function GetActiveNpsCampaignResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetActiveNpsCampaignResponse campaign.
                     * @member {palexy.streaming.v1.INpsCampaign|null|undefined} campaign
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignResponse
                     * @instance
                     */
                    GetActiveNpsCampaignResponse.prototype.campaign = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * GetActiveNpsCampaignResponse option.
                     * @member {"campaign"|undefined} option
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignResponse
                     * @instance
                     */
                    Object.defineProperty(GetActiveNpsCampaignResponse.prototype, "option", {
                        get: $util.oneOfGetter($oneOfFields = ["campaign"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new GetActiveNpsCampaignResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetActiveNpsCampaignResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetActiveNpsCampaignResponse} GetActiveNpsCampaignResponse instance
                     */
                    GetActiveNpsCampaignResponse.create = function create(properties) {
                        return new GetActiveNpsCampaignResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetActiveNpsCampaignResponse message. Does not implicitly {@link palexy.streaming.v1.GetActiveNpsCampaignResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetActiveNpsCampaignResponse} message GetActiveNpsCampaignResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetActiveNpsCampaignResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.campaign != null && Object.hasOwnProperty.call(message, "campaign"))
                            $root.palexy.streaming.v1.NpsCampaign.encode(message.campaign, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetActiveNpsCampaignResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetActiveNpsCampaignResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetActiveNpsCampaignResponse} message GetActiveNpsCampaignResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetActiveNpsCampaignResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetActiveNpsCampaignResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetActiveNpsCampaignResponse} GetActiveNpsCampaignResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetActiveNpsCampaignResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetActiveNpsCampaignResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.campaign = $root.palexy.streaming.v1.NpsCampaign.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetActiveNpsCampaignResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetActiveNpsCampaignResponse} GetActiveNpsCampaignResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetActiveNpsCampaignResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetActiveNpsCampaignResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetActiveNpsCampaignResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.campaign != null && message.hasOwnProperty("campaign")) {
                            properties.option = 1;
                            {
                                var error = $root.palexy.streaming.v1.NpsCampaign.verify(message.campaign);
                                if (error)
                                    return "campaign." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetActiveNpsCampaignResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetActiveNpsCampaignResponse} GetActiveNpsCampaignResponse
                     */
                    GetActiveNpsCampaignResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetActiveNpsCampaignResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetActiveNpsCampaignResponse();
                        if (object.campaign != null) {
                            if (typeof object.campaign !== "object")
                                throw TypeError(".palexy.streaming.v1.GetActiveNpsCampaignResponse.campaign: object expected");
                            message.campaign = $root.palexy.streaming.v1.NpsCampaign.fromObject(object.campaign);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetActiveNpsCampaignResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignResponse
                     * @static
                     * @param {palexy.streaming.v1.GetActiveNpsCampaignResponse} message GetActiveNpsCampaignResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetActiveNpsCampaignResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.campaign != null && message.hasOwnProperty("campaign")) {
                            object.campaign = $root.palexy.streaming.v1.NpsCampaign.toObject(message.campaign, options);
                            if (options.oneofs)
                                object.option = "campaign";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetActiveNpsCampaignResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetActiveNpsCampaignResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetActiveNpsCampaignResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetActiveNpsCampaignResponse;
                })();
    
                v1.NpsCampaign = (function() {
    
                    /**
                     * Properties of a NpsCampaign.
                     * @memberof palexy.streaming.v1
                     * @interface INpsCampaign
                     * @property {number|null} [id] NpsCampaign id
                     * @property {string|null} [name] NpsCampaign name
                     * @property {number|null} [startTimeMs] NpsCampaign startTimeMs
                     * @property {number|null} [endTimeMs] NpsCampaign endTimeMs
                     * @property {number|null} [createTimeMs] NpsCampaign createTimeMs
                     * @property {boolean|null} [disabled] NpsCampaign disabled
                     */
    
                    /**
                     * Constructs a new NpsCampaign.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a NpsCampaign.
                     * @implements INpsCampaign
                     * @constructor
                     * @param {palexy.streaming.v1.INpsCampaign=} [properties] Properties to set
                     */
                    function NpsCampaign(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * NpsCampaign id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @instance
                     */
                    NpsCampaign.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * NpsCampaign name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @instance
                     */
                    NpsCampaign.prototype.name = "";
    
                    /**
                     * NpsCampaign startTimeMs.
                     * @member {number} startTimeMs
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @instance
                     */
                    NpsCampaign.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * NpsCampaign endTimeMs.
                     * @member {number} endTimeMs
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @instance
                     */
                    NpsCampaign.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * NpsCampaign createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @instance
                     */
                    NpsCampaign.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * NpsCampaign disabled.
                     * @member {boolean} disabled
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @instance
                     */
                    NpsCampaign.prototype.disabled = false;
    
                    /**
                     * Creates a new NpsCampaign instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @static
                     * @param {palexy.streaming.v1.INpsCampaign=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.NpsCampaign} NpsCampaign instance
                     */
                    NpsCampaign.create = function create(properties) {
                        return new NpsCampaign(properties);
                    };
    
                    /**
                     * Encodes the specified NpsCampaign message. Does not implicitly {@link palexy.streaming.v1.NpsCampaign.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @static
                     * @param {palexy.streaming.v1.INpsCampaign} message NpsCampaign message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NpsCampaign.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.startTimeMs);
                        if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.endTimeMs);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.createTimeMs);
                        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.disabled);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified NpsCampaign message, length delimited. Does not implicitly {@link palexy.streaming.v1.NpsCampaign.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @static
                     * @param {palexy.streaming.v1.INpsCampaign} message NpsCampaign message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NpsCampaign.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a NpsCampaign message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.NpsCampaign} NpsCampaign
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NpsCampaign.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.NpsCampaign();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.startTimeMs = reader.int64();
                                break;
                            case 4:
                                message.endTimeMs = reader.int64();
                                break;
                            case 5:
                                message.createTimeMs = reader.int64();
                                break;
                            case 6:
                                message.disabled = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a NpsCampaign message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.NpsCampaign} NpsCampaign
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NpsCampaign.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a NpsCampaign message.
                     * @function verify
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NpsCampaign.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                            if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                                return "startTimeMs: integer|Long expected";
                        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                            if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                                return "endTimeMs: integer|Long expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.disabled != null && message.hasOwnProperty("disabled"))
                            if (typeof message.disabled !== "boolean")
                                return "disabled: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a NpsCampaign message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.NpsCampaign} NpsCampaign
                     */
                    NpsCampaign.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.NpsCampaign)
                            return object;
                        var message = new $root.palexy.streaming.v1.NpsCampaign();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.startTimeMs != null)
                            if ($util.Long)
                                (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                            else if (typeof object.startTimeMs === "string")
                                message.startTimeMs = parseInt(object.startTimeMs, 10);
                            else if (typeof object.startTimeMs === "number")
                                message.startTimeMs = object.startTimeMs;
                            else if (typeof object.startTimeMs === "object")
                                message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
                        if (object.endTimeMs != null)
                            if ($util.Long)
                                (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                            else if (typeof object.endTimeMs === "string")
                                message.endTimeMs = parseInt(object.endTimeMs, 10);
                            else if (typeof object.endTimeMs === "number")
                                message.endTimeMs = object.endTimeMs;
                            else if (typeof object.endTimeMs === "object")
                                message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.disabled != null)
                            message.disabled = Boolean(object.disabled);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a NpsCampaign message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @static
                     * @param {palexy.streaming.v1.NpsCampaign} message NpsCampaign
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NpsCampaign.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.startTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.endTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            object.disabled = false;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                            if (typeof message.startTimeMs === "number")
                                object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                            else
                                object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                            if (typeof message.endTimeMs === "number")
                                object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                            else
                                object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.disabled != null && message.hasOwnProperty("disabled"))
                            object.disabled = message.disabled;
                        return object;
                    };
    
                    /**
                     * Converts this NpsCampaign to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.NpsCampaign
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NpsCampaign.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return NpsCampaign;
                })();
    
                v1.NpsResponse = (function() {
    
                    /**
                     * Properties of a NpsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface INpsResponse
                     * @property {number|null} [campaignId] NpsResponse campaignId
                     * @property {number|null} [accountId] NpsResponse accountId
                     * @property {number|null} [score] NpsResponse score
                     * @property {string|null} [message] NpsResponse message
                     * @property {boolean|null} [rejected] NpsResponse rejected
                     * @property {number|null} [createTimeMs] NpsResponse createTimeMs
                     */
    
                    /**
                     * Constructs a new NpsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a NpsResponse.
                     * @implements INpsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.INpsResponse=} [properties] Properties to set
                     */
                    function NpsResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * NpsResponse campaignId.
                     * @member {number} campaignId
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @instance
                     */
                    NpsResponse.prototype.campaignId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * NpsResponse accountId.
                     * @member {number} accountId
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @instance
                     */
                    NpsResponse.prototype.accountId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * NpsResponse score.
                     * @member {number} score
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @instance
                     */
                    NpsResponse.prototype.score = 0;
    
                    /**
                     * NpsResponse message.
                     * @member {string} message
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @instance
                     */
                    NpsResponse.prototype.message = "";
    
                    /**
                     * NpsResponse rejected.
                     * @member {boolean} rejected
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @instance
                     */
                    NpsResponse.prototype.rejected = false;
    
                    /**
                     * NpsResponse createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @instance
                     */
                    NpsResponse.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new NpsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @static
                     * @param {palexy.streaming.v1.INpsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.NpsResponse} NpsResponse instance
                     */
                    NpsResponse.create = function create(properties) {
                        return new NpsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified NpsResponse message. Does not implicitly {@link palexy.streaming.v1.NpsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @static
                     * @param {palexy.streaming.v1.INpsResponse} message NpsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NpsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.campaignId);
                        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.accountId);
                        if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.score);
                        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
                        if (message.rejected != null && Object.hasOwnProperty.call(message, "rejected"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.rejected);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified NpsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.NpsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @static
                     * @param {palexy.streaming.v1.INpsResponse} message NpsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NpsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a NpsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.NpsResponse} NpsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NpsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.NpsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.campaignId = reader.int64();
                                break;
                            case 2:
                                message.accountId = reader.int64();
                                break;
                            case 3:
                                message.score = reader.int32();
                                break;
                            case 4:
                                message.message = reader.string();
                                break;
                            case 5:
                                message.rejected = reader.bool();
                                break;
                            case 6:
                                message.createTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a NpsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.NpsResponse} NpsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NpsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a NpsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NpsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                            if (!$util.isInteger(message.campaignId) && !(message.campaignId && $util.isInteger(message.campaignId.low) && $util.isInteger(message.campaignId.high)))
                                return "campaignId: integer|Long expected";
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (!$util.isInteger(message.accountId) && !(message.accountId && $util.isInteger(message.accountId.low) && $util.isInteger(message.accountId.high)))
                                return "accountId: integer|Long expected";
                        if (message.score != null && message.hasOwnProperty("score"))
                            if (!$util.isInteger(message.score))
                                return "score: integer expected";
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        if (message.rejected != null && message.hasOwnProperty("rejected"))
                            if (typeof message.rejected !== "boolean")
                                return "rejected: boolean expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a NpsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.NpsResponse} NpsResponse
                     */
                    NpsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.NpsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.NpsResponse();
                        if (object.campaignId != null)
                            if ($util.Long)
                                (message.campaignId = $util.Long.fromValue(object.campaignId)).unsigned = false;
                            else if (typeof object.campaignId === "string")
                                message.campaignId = parseInt(object.campaignId, 10);
                            else if (typeof object.campaignId === "number")
                                message.campaignId = object.campaignId;
                            else if (typeof object.campaignId === "object")
                                message.campaignId = new $util.LongBits(object.campaignId.low >>> 0, object.campaignId.high >>> 0).toNumber();
                        if (object.accountId != null)
                            if ($util.Long)
                                (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = false;
                            else if (typeof object.accountId === "string")
                                message.accountId = parseInt(object.accountId, 10);
                            else if (typeof object.accountId === "number")
                                message.accountId = object.accountId;
                            else if (typeof object.accountId === "object")
                                message.accountId = new $util.LongBits(object.accountId.low >>> 0, object.accountId.high >>> 0).toNumber();
                        if (object.score != null)
                            message.score = object.score | 0;
                        if (object.message != null)
                            message.message = String(object.message);
                        if (object.rejected != null)
                            message.rejected = Boolean(object.rejected);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a NpsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @static
                     * @param {palexy.streaming.v1.NpsResponse} message NpsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NpsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.campaignId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.campaignId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.accountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.accountId = options.longs === String ? "0" : 0;
                            object.score = 0;
                            object.message = "";
                            object.rejected = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                            if (typeof message.campaignId === "number")
                                object.campaignId = options.longs === String ? String(message.campaignId) : message.campaignId;
                            else
                                object.campaignId = options.longs === String ? $util.Long.prototype.toString.call(message.campaignId) : options.longs === Number ? new $util.LongBits(message.campaignId.low >>> 0, message.campaignId.high >>> 0).toNumber() : message.campaignId;
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (typeof message.accountId === "number")
                                object.accountId = options.longs === String ? String(message.accountId) : message.accountId;
                            else
                                object.accountId = options.longs === String ? $util.Long.prototype.toString.call(message.accountId) : options.longs === Number ? new $util.LongBits(message.accountId.low >>> 0, message.accountId.high >>> 0).toNumber() : message.accountId;
                        if (message.score != null && message.hasOwnProperty("score"))
                            object.score = message.score;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        if (message.rejected != null && message.hasOwnProperty("rejected"))
                            object.rejected = message.rejected;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this NpsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.NpsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NpsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return NpsResponse;
                })();
    
                v1.StaffService = (function() {
    
                    /**
                     * Constructs a new StaffService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StaffService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (StaffService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StaffService;
    
                    /**
                     * Creates new StaffService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StaffService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StaffService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffService#createStaff}.
                     * @memberof palexy.streaming.v1.StaffService
                     * @typedef CreateStaffCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Staff} [response] Staff
                     */
    
                    /**
                     * Calls CreateStaff.
                     * @function createStaff
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStaffRequest} request CreateStaffRequest message or plain object
                     * @param {palexy.streaming.v1.StaffService.CreateStaffCallback} callback Node-style callback called with the error, if any, and Staff
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffService.prototype.createStaff = function createStaff(request, callback) {
                        return this.rpcCall(createStaff, $root.palexy.streaming.v1.CreateStaffRequest, $root.palexy.streaming.v1.Staff, request, callback);
                    }, "name", { value: "CreateStaff" });
    
                    /**
                     * Calls CreateStaff.
                     * @function createStaff
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStaffRequest} request CreateStaffRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Staff>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffService#updateStaff}.
                     * @memberof palexy.streaming.v1.StaffService
                     * @typedef UpdateStaffCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Staff} [response] Staff
                     */
    
                    /**
                     * Calls UpdateStaff.
                     * @function updateStaff
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStaffRequest} request UpdateStaffRequest message or plain object
                     * @param {palexy.streaming.v1.StaffService.UpdateStaffCallback} callback Node-style callback called with the error, if any, and Staff
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffService.prototype.updateStaff = function updateStaff(request, callback) {
                        return this.rpcCall(updateStaff, $root.palexy.streaming.v1.UpdateStaffRequest, $root.palexy.streaming.v1.Staff, request, callback);
                    }, "name", { value: "UpdateStaff" });
    
                    /**
                     * Calls UpdateStaff.
                     * @function updateStaff
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStaffRequest} request UpdateStaffRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Staff>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffService#getStaff}.
                     * @memberof palexy.streaming.v1.StaffService
                     * @typedef GetStaffCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Staff} [response] Staff
                     */
    
                    /**
                     * Calls GetStaff.
                     * @function getStaff
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffRequest} request GetStaffRequest message or plain object
                     * @param {palexy.streaming.v1.StaffService.GetStaffCallback} callback Node-style callback called with the error, if any, and Staff
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffService.prototype.getStaff = function getStaff(request, callback) {
                        return this.rpcCall(getStaff, $root.palexy.streaming.v1.GetStaffRequest, $root.palexy.streaming.v1.Staff, request, callback);
                    }, "name", { value: "GetStaff" });
    
                    /**
                     * Calls GetStaff.
                     * @function getStaff
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffRequest} request GetStaffRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Staff>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffService#listStaff}.
                     * @memberof palexy.streaming.v1.StaffService
                     * @typedef ListStaffCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStaffResponse} [response] ListStaffResponse
                     */
    
                    /**
                     * Calls ListStaff.
                     * @function listStaff
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffRequest} request ListStaffRequest message or plain object
                     * @param {palexy.streaming.v1.StaffService.ListStaffCallback} callback Node-style callback called with the error, if any, and ListStaffResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffService.prototype.listStaff = function listStaff(request, callback) {
                        return this.rpcCall(listStaff, $root.palexy.streaming.v1.ListStaffRequest, $root.palexy.streaming.v1.ListStaffResponse, request, callback);
                    }, "name", { value: "ListStaff" });
    
                    /**
                     * Calls ListStaff.
                     * @function listStaff
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffRequest} request ListStaffRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStaffResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffService#batchCreateStaff}.
                     * @memberof palexy.streaming.v1.StaffService
                     * @typedef BatchCreateStaffCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchCreateStaffResponse} [response] BatchCreateStaffResponse
                     */
    
                    /**
                     * Calls BatchCreateStaff.
                     * @function batchCreateStaff
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreateStaffRequest} request BatchCreateStaffRequest message or plain object
                     * @param {palexy.streaming.v1.StaffService.BatchCreateStaffCallback} callback Node-style callback called with the error, if any, and BatchCreateStaffResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffService.prototype.batchCreateStaff = function batchCreateStaff(request, callback) {
                        return this.rpcCall(batchCreateStaff, $root.palexy.streaming.v1.BatchCreateStaffRequest, $root.palexy.streaming.v1.BatchCreateStaffResponse, request, callback);
                    }, "name", { value: "BatchCreateStaff" });
    
                    /**
                     * Calls BatchCreateStaff.
                     * @function batchCreateStaff
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreateStaffRequest} request BatchCreateStaffRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchCreateStaffResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffService#listUnmappedStaffId}.
                     * @memberof palexy.streaming.v1.StaffService
                     * @typedef ListUnmappedStaffIdCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListUnmappedStaffIdResponse} [response] ListUnmappedStaffIdResponse
                     */
    
                    /**
                     * Calls ListUnmappedStaffId.
                     * @function listUnmappedStaffId
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IListUnmappedStaffIdRequest} request ListUnmappedStaffIdRequest message or plain object
                     * @param {palexy.streaming.v1.StaffService.ListUnmappedStaffIdCallback} callback Node-style callback called with the error, if any, and ListUnmappedStaffIdResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffService.prototype.listUnmappedStaffId = function listUnmappedStaffId(request, callback) {
                        return this.rpcCall(listUnmappedStaffId, $root.palexy.streaming.v1.ListUnmappedStaffIdRequest, $root.palexy.streaming.v1.ListUnmappedStaffIdResponse, request, callback);
                    }, "name", { value: "ListUnmappedStaffId" });
    
                    /**
                     * Calls ListUnmappedStaffId.
                     * @function listUnmappedStaffId
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IListUnmappedStaffIdRequest} request ListUnmappedStaffIdRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListUnmappedStaffIdResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffService#listStaffSalesHistory}.
                     * @memberof palexy.streaming.v1.StaffService
                     * @typedef ListStaffSalesHistoryCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStaffSalesHistoryResponse} [response] ListStaffSalesHistoryResponse
                     */
    
                    /**
                     * Calls ListStaffSalesHistory.
                     * @function listStaffSalesHistory
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffSalesHistoryRequest} request ListStaffSalesHistoryRequest message or plain object
                     * @param {palexy.streaming.v1.StaffService.ListStaffSalesHistoryCallback} callback Node-style callback called with the error, if any, and ListStaffSalesHistoryResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffService.prototype.listStaffSalesHistory = function listStaffSalesHistory(request, callback) {
                        return this.rpcCall(listStaffSalesHistory, $root.palexy.streaming.v1.ListStaffSalesHistoryRequest, $root.palexy.streaming.v1.ListStaffSalesHistoryResponse, request, callback);
                    }, "name", { value: "ListStaffSalesHistory" });
    
                    /**
                     * Calls ListStaffSalesHistory.
                     * @function listStaffSalesHistory
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffSalesHistoryRequest} request ListStaffSalesHistoryRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStaffSalesHistoryResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffService#assignStaffToStore}.
                     * @memberof palexy.streaming.v1.StaffService
                     * @typedef AssignStaffToStoreCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.AssignStaffToStoreResponse} [response] AssignStaffToStoreResponse
                     */
    
                    /**
                     * Calls AssignStaffToStore.
                     * @function assignStaffToStore
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IAssignStaffToStoreRequest} request AssignStaffToStoreRequest message or plain object
                     * @param {palexy.streaming.v1.StaffService.AssignStaffToStoreCallback} callback Node-style callback called with the error, if any, and AssignStaffToStoreResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffService.prototype.assignStaffToStore = function assignStaffToStore(request, callback) {
                        return this.rpcCall(assignStaffToStore, $root.palexy.streaming.v1.AssignStaffToStoreRequest, $root.palexy.streaming.v1.AssignStaffToStoreResponse, request, callback);
                    }, "name", { value: "AssignStaffToStore" });
    
                    /**
                     * Calls AssignStaffToStore.
                     * @function assignStaffToStore
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IAssignStaffToStoreRequest} request AssignStaffToStoreRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.AssignStaffToStoreResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffService#removeStaffFromStore}.
                     * @memberof palexy.streaming.v1.StaffService
                     * @typedef RemoveStaffFromStoreCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.RemoveStaffFromStoreResponse} [response] RemoveStaffFromStoreResponse
                     */
    
                    /**
                     * Calls RemoveStaffFromStore.
                     * @function removeStaffFromStore
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IRemoveStaffFromStoreRequest} request RemoveStaffFromStoreRequest message or plain object
                     * @param {palexy.streaming.v1.StaffService.RemoveStaffFromStoreCallback} callback Node-style callback called with the error, if any, and RemoveStaffFromStoreResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffService.prototype.removeStaffFromStore = function removeStaffFromStore(request, callback) {
                        return this.rpcCall(removeStaffFromStore, $root.palexy.streaming.v1.RemoveStaffFromStoreRequest, $root.palexy.streaming.v1.RemoveStaffFromStoreResponse, request, callback);
                    }, "name", { value: "RemoveStaffFromStore" });
    
                    /**
                     * Calls RemoveStaffFromStore.
                     * @function removeStaffFromStore
                     * @memberof palexy.streaming.v1.StaffService
                     * @instance
                     * @param {palexy.streaming.v1.IRemoveStaffFromStoreRequest} request RemoveStaffFromStoreRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.RemoveStaffFromStoreResponse>} Promise
                     * @variation 2
                     */
    
                    return StaffService;
                })();
    
                v1.Staff = (function() {
    
                    /**
                     * Properties of a Staff.
                     * @memberof palexy.streaming.v1
                     * @interface IStaff
                     * @property {number|null} [id] Staff id
                     * @property {number|null} [storeId] Staff storeId
                     * @property {string|null} [fullName] Staff fullName
                     * @property {string|null} [title] Staff title
                     * @property {string|null} [mobile] Staff mobile
                     * @property {boolean|null} [disabled] Staff disabled
                     * @property {string|null} [profilePhotoUrl] Staff profilePhotoUrl
                     * @property {number|null} [createTimeMs] Staff createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] Staff lastUpdateTimeMs
                     * @property {number|null} [companyId] Staff companyId
                     * @property {Array.<palexy.streaming.v1.IStaffAssignedStore>|null} [assignedStores] Staff assignedStores
                     * @property {Array.<string>|null} [posExternalStaffId] Staff posExternalStaffId
                     * @property {Array.<string>|null} [staffingExternalStaffId] Staff staffingExternalStaffId
                     * @property {boolean|null} [isSyncPosStaff] Staff isSyncPosStaff
                     */
    
                    /**
                     * Constructs a new Staff.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Staff.
                     * @implements IStaff
                     * @constructor
                     * @param {palexy.streaming.v1.IStaff=} [properties] Properties to set
                     */
                    function Staff(properties) {
                        this.assignedStores = [];
                        this.posExternalStaffId = [];
                        this.staffingExternalStaffId = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Staff id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Staff storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Staff fullName.
                     * @member {string} fullName
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.fullName = "";
    
                    /**
                     * Staff title.
                     * @member {string} title
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.title = "";
    
                    /**
                     * Staff mobile.
                     * @member {string} mobile
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.mobile = "";
    
                    /**
                     * Staff disabled.
                     * @member {boolean} disabled
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.disabled = false;
    
                    /**
                     * Staff profilePhotoUrl.
                     * @member {string} profilePhotoUrl
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.profilePhotoUrl = "";
    
                    /**
                     * Staff createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Staff lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Staff companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Staff assignedStores.
                     * @member {Array.<palexy.streaming.v1.IStaffAssignedStore>} assignedStores
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.assignedStores = $util.emptyArray;
    
                    /**
                     * Staff posExternalStaffId.
                     * @member {Array.<string>} posExternalStaffId
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.posExternalStaffId = $util.emptyArray;
    
                    /**
                     * Staff staffingExternalStaffId.
                     * @member {Array.<string>} staffingExternalStaffId
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.staffingExternalStaffId = $util.emptyArray;
    
                    /**
                     * Staff isSyncPosStaff.
                     * @member {boolean} isSyncPosStaff
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     */
                    Staff.prototype.isSyncPosStaff = false;
    
                    /**
                     * Creates a new Staff instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Staff
                     * @static
                     * @param {palexy.streaming.v1.IStaff=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Staff} Staff instance
                     */
                    Staff.create = function create(properties) {
                        return new Staff(properties);
                    };
    
                    /**
                     * Encodes the specified Staff message. Does not implicitly {@link palexy.streaming.v1.Staff.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Staff
                     * @static
                     * @param {palexy.streaming.v1.IStaff} message Staff message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Staff.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fullName);
                        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.title);
                        if (message.mobile != null && Object.hasOwnProperty.call(message, "mobile"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.mobile);
                        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.disabled);
                        if (message.profilePhotoUrl != null && Object.hasOwnProperty.call(message, "profilePhotoUrl"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.profilePhotoUrl);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int64(message.lastUpdateTimeMs);
                        if (message.posExternalStaffId != null && message.posExternalStaffId.length)
                            for (var i = 0; i < message.posExternalStaffId.length; ++i)
                                writer.uint32(/* id 10, wireType 2 =*/82).string(message.posExternalStaffId[i]);
                        if (message.staffingExternalStaffId != null && message.staffingExternalStaffId.length)
                            for (var i = 0; i < message.staffingExternalStaffId.length; ++i)
                                writer.uint32(/* id 11, wireType 2 =*/90).string(message.staffingExternalStaffId[i]);
                        if (message.isSyncPosStaff != null && Object.hasOwnProperty.call(message, "isSyncPosStaff"))
                            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isSyncPosStaff);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int64(message.companyId);
                        if (message.assignedStores != null && message.assignedStores.length)
                            for (var i = 0; i < message.assignedStores.length; ++i)
                                $root.palexy.streaming.v1.StaffAssignedStore.encode(message.assignedStores[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Staff message, length delimited. Does not implicitly {@link palexy.streaming.v1.Staff.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Staff
                     * @static
                     * @param {palexy.streaming.v1.IStaff} message Staff message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Staff.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Staff message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Staff
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Staff} Staff
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Staff.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Staff();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.fullName = reader.string();
                                break;
                            case 4:
                                message.title = reader.string();
                                break;
                            case 5:
                                message.mobile = reader.string();
                                break;
                            case 6:
                                message.disabled = reader.bool();
                                break;
                            case 7:
                                message.profilePhotoUrl = reader.string();
                                break;
                            case 8:
                                message.createTimeMs = reader.int64();
                                break;
                            case 9:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 13:
                                message.companyId = reader.int64();
                                break;
                            case 14:
                                if (!(message.assignedStores && message.assignedStores.length))
                                    message.assignedStores = [];
                                message.assignedStores.push($root.palexy.streaming.v1.StaffAssignedStore.decode(reader, reader.uint32()));
                                break;
                            case 10:
                                if (!(message.posExternalStaffId && message.posExternalStaffId.length))
                                    message.posExternalStaffId = [];
                                message.posExternalStaffId.push(reader.string());
                                break;
                            case 11:
                                if (!(message.staffingExternalStaffId && message.staffingExternalStaffId.length))
                                    message.staffingExternalStaffId = [];
                                message.staffingExternalStaffId.push(reader.string());
                                break;
                            case 12:
                                message.isSyncPosStaff = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Staff message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Staff
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Staff} Staff
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Staff.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Staff message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Staff
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Staff.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.fullName != null && message.hasOwnProperty("fullName"))
                            if (!$util.isString(message.fullName))
                                return "fullName: string expected";
                        if (message.title != null && message.hasOwnProperty("title"))
                            if (!$util.isString(message.title))
                                return "title: string expected";
                        if (message.mobile != null && message.hasOwnProperty("mobile"))
                            if (!$util.isString(message.mobile))
                                return "mobile: string expected";
                        if (message.disabled != null && message.hasOwnProperty("disabled"))
                            if (typeof message.disabled !== "boolean")
                                return "disabled: boolean expected";
                        if (message.profilePhotoUrl != null && message.hasOwnProperty("profilePhotoUrl"))
                            if (!$util.isString(message.profilePhotoUrl))
                                return "profilePhotoUrl: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.assignedStores != null && message.hasOwnProperty("assignedStores")) {
                            if (!Array.isArray(message.assignedStores))
                                return "assignedStores: array expected";
                            for (var i = 0; i < message.assignedStores.length; ++i) {
                                var error = $root.palexy.streaming.v1.StaffAssignedStore.verify(message.assignedStores[i]);
                                if (error)
                                    return "assignedStores." + error;
                            }
                        }
                        if (message.posExternalStaffId != null && message.hasOwnProperty("posExternalStaffId")) {
                            if (!Array.isArray(message.posExternalStaffId))
                                return "posExternalStaffId: array expected";
                            for (var i = 0; i < message.posExternalStaffId.length; ++i)
                                if (!$util.isString(message.posExternalStaffId[i]))
                                    return "posExternalStaffId: string[] expected";
                        }
                        if (message.staffingExternalStaffId != null && message.hasOwnProperty("staffingExternalStaffId")) {
                            if (!Array.isArray(message.staffingExternalStaffId))
                                return "staffingExternalStaffId: array expected";
                            for (var i = 0; i < message.staffingExternalStaffId.length; ++i)
                                if (!$util.isString(message.staffingExternalStaffId[i]))
                                    return "staffingExternalStaffId: string[] expected";
                        }
                        if (message.isSyncPosStaff != null && message.hasOwnProperty("isSyncPosStaff"))
                            if (typeof message.isSyncPosStaff !== "boolean")
                                return "isSyncPosStaff: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a Staff message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Staff
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Staff} Staff
                     */
                    Staff.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Staff)
                            return object;
                        var message = new $root.palexy.streaming.v1.Staff();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.fullName != null)
                            message.fullName = String(object.fullName);
                        if (object.title != null)
                            message.title = String(object.title);
                        if (object.mobile != null)
                            message.mobile = String(object.mobile);
                        if (object.disabled != null)
                            message.disabled = Boolean(object.disabled);
                        if (object.profilePhotoUrl != null)
                            message.profilePhotoUrl = String(object.profilePhotoUrl);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.assignedStores) {
                            if (!Array.isArray(object.assignedStores))
                                throw TypeError(".palexy.streaming.v1.Staff.assignedStores: array expected");
                            message.assignedStores = [];
                            for (var i = 0; i < object.assignedStores.length; ++i) {
                                if (typeof object.assignedStores[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.Staff.assignedStores: object expected");
                                message.assignedStores[i] = $root.palexy.streaming.v1.StaffAssignedStore.fromObject(object.assignedStores[i]);
                            }
                        }
                        if (object.posExternalStaffId) {
                            if (!Array.isArray(object.posExternalStaffId))
                                throw TypeError(".palexy.streaming.v1.Staff.posExternalStaffId: array expected");
                            message.posExternalStaffId = [];
                            for (var i = 0; i < object.posExternalStaffId.length; ++i)
                                message.posExternalStaffId[i] = String(object.posExternalStaffId[i]);
                        }
                        if (object.staffingExternalStaffId) {
                            if (!Array.isArray(object.staffingExternalStaffId))
                                throw TypeError(".palexy.streaming.v1.Staff.staffingExternalStaffId: array expected");
                            message.staffingExternalStaffId = [];
                            for (var i = 0; i < object.staffingExternalStaffId.length; ++i)
                                message.staffingExternalStaffId[i] = String(object.staffingExternalStaffId[i]);
                        }
                        if (object.isSyncPosStaff != null)
                            message.isSyncPosStaff = Boolean(object.isSyncPosStaff);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Staff message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Staff
                     * @static
                     * @param {palexy.streaming.v1.Staff} message Staff
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Staff.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.posExternalStaffId = [];
                            object.staffingExternalStaffId = [];
                            object.assignedStores = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.fullName = "";
                            object.title = "";
                            object.mobile = "";
                            object.disabled = false;
                            object.profilePhotoUrl = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                            object.isSyncPosStaff = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.fullName != null && message.hasOwnProperty("fullName"))
                            object.fullName = message.fullName;
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = message.title;
                        if (message.mobile != null && message.hasOwnProperty("mobile"))
                            object.mobile = message.mobile;
                        if (message.disabled != null && message.hasOwnProperty("disabled"))
                            object.disabled = message.disabled;
                        if (message.profilePhotoUrl != null && message.hasOwnProperty("profilePhotoUrl"))
                            object.profilePhotoUrl = message.profilePhotoUrl;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.posExternalStaffId && message.posExternalStaffId.length) {
                            object.posExternalStaffId = [];
                            for (var j = 0; j < message.posExternalStaffId.length; ++j)
                                object.posExternalStaffId[j] = message.posExternalStaffId[j];
                        }
                        if (message.staffingExternalStaffId && message.staffingExternalStaffId.length) {
                            object.staffingExternalStaffId = [];
                            for (var j = 0; j < message.staffingExternalStaffId.length; ++j)
                                object.staffingExternalStaffId[j] = message.staffingExternalStaffId[j];
                        }
                        if (message.isSyncPosStaff != null && message.hasOwnProperty("isSyncPosStaff"))
                            object.isSyncPosStaff = message.isSyncPosStaff;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.assignedStores && message.assignedStores.length) {
                            object.assignedStores = [];
                            for (var j = 0; j < message.assignedStores.length; ++j)
                                object.assignedStores[j] = $root.palexy.streaming.v1.StaffAssignedStore.toObject(message.assignedStores[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Staff to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Staff
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Staff.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Staff;
                })();
    
                v1.StaffAssignedStore = (function() {
    
                    /**
                     * Properties of a StaffAssignedStore.
                     * @memberof palexy.streaming.v1
                     * @interface IStaffAssignedStore
                     * @property {number|null} [storeId] StaffAssignedStore storeId
                     * @property {string|null} [auditUsername] StaffAssignedStore auditUsername
                     * @property {number|null} [createTimeMs] StaffAssignedStore createTimeMs
                     * @property {palexy.streaming.v1.StaffAssignedStore.StaffType|null} [staffType] StaffAssignedStore staffType
                     */
    
                    /**
                     * Constructs a new StaffAssignedStore.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffAssignedStore.
                     * @implements IStaffAssignedStore
                     * @constructor
                     * @param {palexy.streaming.v1.IStaffAssignedStore=} [properties] Properties to set
                     */
                    function StaffAssignedStore(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StaffAssignedStore storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @instance
                     */
                    StaffAssignedStore.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffAssignedStore auditUsername.
                     * @member {string} auditUsername
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @instance
                     */
                    StaffAssignedStore.prototype.auditUsername = "";
    
                    /**
                     * StaffAssignedStore createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @instance
                     */
                    StaffAssignedStore.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffAssignedStore staffType.
                     * @member {palexy.streaming.v1.StaffAssignedStore.StaffType} staffType
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @instance
                     */
                    StaffAssignedStore.prototype.staffType = 0;
    
                    /**
                     * Creates a new StaffAssignedStore instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @static
                     * @param {palexy.streaming.v1.IStaffAssignedStore=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StaffAssignedStore} StaffAssignedStore instance
                     */
                    StaffAssignedStore.create = function create(properties) {
                        return new StaffAssignedStore(properties);
                    };
    
                    /**
                     * Encodes the specified StaffAssignedStore message. Does not implicitly {@link palexy.streaming.v1.StaffAssignedStore.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @static
                     * @param {palexy.streaming.v1.IStaffAssignedStore} message StaffAssignedStore message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffAssignedStore.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.auditUsername != null && Object.hasOwnProperty.call(message, "auditUsername"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.auditUsername);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.createTimeMs);
                        if (message.staffType != null && Object.hasOwnProperty.call(message, "staffType"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.staffType);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StaffAssignedStore message, length delimited. Does not implicitly {@link palexy.streaming.v1.StaffAssignedStore.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @static
                     * @param {palexy.streaming.v1.IStaffAssignedStore} message StaffAssignedStore message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffAssignedStore.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StaffAssignedStore message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StaffAssignedStore} StaffAssignedStore
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffAssignedStore.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StaffAssignedStore();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.auditUsername = reader.string();
                                break;
                            case 3:
                                message.createTimeMs = reader.int64();
                                break;
                            case 4:
                                message.staffType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StaffAssignedStore message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StaffAssignedStore} StaffAssignedStore
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffAssignedStore.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StaffAssignedStore message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StaffAssignedStore.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.auditUsername != null && message.hasOwnProperty("auditUsername"))
                            if (!$util.isString(message.auditUsername))
                                return "auditUsername: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.staffType != null && message.hasOwnProperty("staffType"))
                            switch (message.staffType) {
                            default:
                                return "staffType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a StaffAssignedStore message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StaffAssignedStore} StaffAssignedStore
                     */
                    StaffAssignedStore.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StaffAssignedStore)
                            return object;
                        var message = new $root.palexy.streaming.v1.StaffAssignedStore();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.auditUsername != null)
                            message.auditUsername = String(object.auditUsername);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        switch (object.staffType) {
                        case "UNKNOWN_STAFF_TYPE":
                        case 0:
                            message.staffType = 0;
                            break;
                        case "PERMANENT":
                        case 1:
                            message.staffType = 1;
                            break;
                        case "BORROWED":
                        case 2:
                            message.staffType = 2;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StaffAssignedStore message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @static
                     * @param {palexy.streaming.v1.StaffAssignedStore} message StaffAssignedStore
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StaffAssignedStore.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.auditUsername = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            object.staffType = options.enums === String ? "UNKNOWN_STAFF_TYPE" : 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.auditUsername != null && message.hasOwnProperty("auditUsername"))
                            object.auditUsername = message.auditUsername;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.staffType != null && message.hasOwnProperty("staffType"))
                            object.staffType = options.enums === String ? $root.palexy.streaming.v1.StaffAssignedStore.StaffType[message.staffType] : message.staffType;
                        return object;
                    };
    
                    /**
                     * Converts this StaffAssignedStore to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StaffAssignedStore
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StaffAssignedStore.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * StaffType enum.
                     * @name palexy.streaming.v1.StaffAssignedStore.StaffType
                     * @enum {number}
                     * @property {number} UNKNOWN_STAFF_TYPE=0 UNKNOWN_STAFF_TYPE value
                     * @property {number} PERMANENT=1 PERMANENT value
                     * @property {number} BORROWED=2 BORROWED value
                     */
                    StaffAssignedStore.StaffType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_STAFF_TYPE"] = 0;
                        values[valuesById[1] = "PERMANENT"] = 1;
                        values[valuesById[2] = "BORROWED"] = 2;
                        return values;
                    })();
    
                    return StaffAssignedStore;
                })();
    
                v1.CreateStaffRequest = (function() {
    
                    /**
                     * Properties of a CreateStaffRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateStaffRequest
                     * @property {palexy.streaming.v1.IStaff|null} [staff] CreateStaffRequest staff
                     */
    
                    /**
                     * Constructs a new CreateStaffRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateStaffRequest.
                     * @implements ICreateStaffRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateStaffRequest=} [properties] Properties to set
                     */
                    function CreateStaffRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateStaffRequest staff.
                     * @member {palexy.streaming.v1.IStaff|null|undefined} staff
                     * @memberof palexy.streaming.v1.CreateStaffRequest
                     * @instance
                     */
                    CreateStaffRequest.prototype.staff = null;
    
                    /**
                     * Creates a new CreateStaffRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateStaffRequest} CreateStaffRequest instance
                     */
                    CreateStaffRequest.create = function create(properties) {
                        return new CreateStaffRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateStaffRequest message. Does not implicitly {@link palexy.streaming.v1.CreateStaffRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffRequest} message CreateStaffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStaffRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staff != null && Object.hasOwnProperty.call(message, "staff"))
                            $root.palexy.streaming.v1.Staff.encode(message.staff, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateStaffRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateStaffRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffRequest} message CreateStaffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStaffRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateStaffRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateStaffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateStaffRequest} CreateStaffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStaffRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateStaffRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staff = $root.palexy.streaming.v1.Staff.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateStaffRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateStaffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateStaffRequest} CreateStaffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStaffRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateStaffRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateStaffRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateStaffRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staff != null && message.hasOwnProperty("staff")) {
                            var error = $root.palexy.streaming.v1.Staff.verify(message.staff);
                            if (error)
                                return "staff." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateStaffRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateStaffRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateStaffRequest} CreateStaffRequest
                     */
                    CreateStaffRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateStaffRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateStaffRequest();
                        if (object.staff != null) {
                            if (typeof object.staff !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateStaffRequest.staff: object expected");
                            message.staff = $root.palexy.streaming.v1.Staff.fromObject(object.staff);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateStaffRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateStaffRequest} message CreateStaffRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateStaffRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.staff = null;
                        if (message.staff != null && message.hasOwnProperty("staff"))
                            object.staff = $root.palexy.streaming.v1.Staff.toObject(message.staff, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateStaffRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateStaffRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateStaffRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateStaffRequest;
                })();
    
                v1.UpdateStaffRequest = (function() {
    
                    /**
                     * Properties of an UpdateStaffRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateStaffRequest
                     * @property {palexy.streaming.v1.IStaff|null} [staff] UpdateStaffRequest staff
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateStaffRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateStaffRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateStaffRequest.
                     * @implements IUpdateStaffRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateStaffRequest=} [properties] Properties to set
                     */
                    function UpdateStaffRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateStaffRequest staff.
                     * @member {palexy.streaming.v1.IStaff|null|undefined} staff
                     * @memberof palexy.streaming.v1.UpdateStaffRequest
                     * @instance
                     */
                    UpdateStaffRequest.prototype.staff = null;
    
                    /**
                     * UpdateStaffRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateStaffRequest
                     * @instance
                     */
                    UpdateStaffRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateStaffRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateStaffRequest} UpdateStaffRequest instance
                     */
                    UpdateStaffRequest.create = function create(properties) {
                        return new UpdateStaffRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateStaffRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateStaffRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffRequest} message UpdateStaffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStaffRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staff != null && Object.hasOwnProperty.call(message, "staff"))
                            $root.palexy.streaming.v1.Staff.encode(message.staff, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateStaffRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateStaffRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffRequest} message UpdateStaffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStaffRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateStaffRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateStaffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateStaffRequest} UpdateStaffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStaffRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateStaffRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staff = $root.palexy.streaming.v1.Staff.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateStaffRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStaffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateStaffRequest} UpdateStaffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStaffRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateStaffRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateStaffRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateStaffRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staff != null && message.hasOwnProperty("staff")) {
                            var error = $root.palexy.streaming.v1.Staff.verify(message.staff);
                            if (error)
                                return "staff." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateStaffRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateStaffRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateStaffRequest} UpdateStaffRequest
                     */
                    UpdateStaffRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateStaffRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateStaffRequest();
                        if (object.staff != null) {
                            if (typeof object.staff !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStaffRequest.staff: object expected");
                            message.staff = $root.palexy.streaming.v1.Staff.fromObject(object.staff);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStaffRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateStaffRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateStaffRequest} message UpdateStaffRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateStaffRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.staff = null;
                            object.updateMask = null;
                        }
                        if (message.staff != null && message.hasOwnProperty("staff"))
                            object.staff = $root.palexy.streaming.v1.Staff.toObject(message.staff, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateStaffRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateStaffRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateStaffRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateStaffRequest;
                })();
    
                v1.GetStaffRequest = (function() {
    
                    /**
                     * Properties of a GetStaffRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStaffRequest
                     * @property {number|null} [staffId] GetStaffRequest staffId
                     */
    
                    /**
                     * Constructs a new GetStaffRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStaffRequest.
                     * @implements IGetStaffRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStaffRequest=} [properties] Properties to set
                     */
                    function GetStaffRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStaffRequest staffId.
                     * @member {number} staffId
                     * @memberof palexy.streaming.v1.GetStaffRequest
                     * @instance
                     */
                    GetStaffRequest.prototype.staffId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetStaffRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStaffRequest} GetStaffRequest instance
                     */
                    GetStaffRequest.create = function create(properties) {
                        return new GetStaffRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStaffRequest message. Does not implicitly {@link palexy.streaming.v1.GetStaffRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffRequest} message GetStaffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffId != null && Object.hasOwnProperty.call(message, "staffId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.staffId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStaffRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStaffRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffRequest} message GetStaffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStaffRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStaffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStaffRequest} GetStaffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStaffRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStaffRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStaffRequest} GetStaffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStaffRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStaffRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStaffRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (!$util.isInteger(message.staffId) && !(message.staffId && $util.isInteger(message.staffId.low) && $util.isInteger(message.staffId.high)))
                                return "staffId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStaffRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStaffRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStaffRequest} GetStaffRequest
                     */
                    GetStaffRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStaffRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStaffRequest();
                        if (object.staffId != null)
                            if ($util.Long)
                                (message.staffId = $util.Long.fromValue(object.staffId)).unsigned = false;
                            else if (typeof object.staffId === "string")
                                message.staffId = parseInt(object.staffId, 10);
                            else if (typeof object.staffId === "number")
                                message.staffId = object.staffId;
                            else if (typeof object.staffId === "object")
                                message.staffId = new $util.LongBits(object.staffId.low >>> 0, object.staffId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStaffRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStaffRequest} message GetStaffRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStaffRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffId = options.longs === String ? "0" : 0;
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (typeof message.staffId === "number")
                                object.staffId = options.longs === String ? String(message.staffId) : message.staffId;
                            else
                                object.staffId = options.longs === String ? $util.Long.prototype.toString.call(message.staffId) : options.longs === Number ? new $util.LongBits(message.staffId.low >>> 0, message.staffId.high >>> 0).toNumber() : message.staffId;
                        return object;
                    };
    
                    /**
                     * Converts this GetStaffRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStaffRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStaffRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStaffRequest;
                })();
    
                v1.ListStaffRequest = (function() {
    
                    /**
                     * Properties of a ListStaffRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffRequest
                     * @property {Array.<number>|null} [storeId] ListStaffRequest storeId
                     * @property {google.protobuf.IBoolValue|null} [disabled] ListStaffRequest disabled
                     * @property {string|null} [searchKey] ListStaffRequest searchKey
                     * @property {number|null} [pageSize] ListStaffRequest pageSize
                     * @property {string|null} [pageToken] ListStaffRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListStaffRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffRequest.
                     * @implements IListStaffRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffRequest=} [properties] Properties to set
                     */
                    function ListStaffRequest(properties) {
                        this.storeId = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffRequest storeId.
                     * @member {Array.<number>} storeId
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @instance
                     */
                    ListStaffRequest.prototype.storeId = $util.emptyArray;
    
                    /**
                     * ListStaffRequest disabled.
                     * @member {google.protobuf.IBoolValue|null|undefined} disabled
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @instance
                     */
                    ListStaffRequest.prototype.disabled = null;
    
                    /**
                     * ListStaffRequest searchKey.
                     * @member {string} searchKey
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @instance
                     */
                    ListStaffRequest.prototype.searchKey = "";
    
                    /**
                     * ListStaffRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @instance
                     */
                    ListStaffRequest.prototype.pageSize = 0;
    
                    /**
                     * ListStaffRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @instance
                     */
                    ListStaffRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListStaffRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffRequest} ListStaffRequest instance
                     */
                    ListStaffRequest.create = function create(properties) {
                        return new ListStaffRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffRequest message. Does not implicitly {@link palexy.streaming.v1.ListStaffRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffRequest} message ListStaffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && message.storeId.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.storeId.length; ++i)
                                writer.int64(message.storeId[i]);
                            writer.ldelim();
                        }
                        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                            $root.google.protobuf.BoolValue.encode(message.disabled, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.searchKey != null && Object.hasOwnProperty.call(message, "searchKey"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.searchKey);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffRequest} message ListStaffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffRequest} ListStaffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeId && message.storeId.length))
                                    message.storeId = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeId.push(reader.int64());
                                } else
                                    message.storeId.push(reader.int64());
                                break;
                            case 2:
                                message.disabled = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.searchKey = reader.string();
                                break;
                            case 4:
                                message.pageSize = reader.int32();
                                break;
                            case 5:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffRequest} ListStaffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            if (!Array.isArray(message.storeId))
                                return "storeId: array expected";
                            for (var i = 0; i < message.storeId.length; ++i)
                                if (!$util.isInteger(message.storeId[i]) && !(message.storeId[i] && $util.isInteger(message.storeId[i].low) && $util.isInteger(message.storeId[i].high)))
                                    return "storeId: integer|Long[] expected";
                        }
                        if (message.disabled != null && message.hasOwnProperty("disabled")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.disabled);
                            if (error)
                                return "disabled." + error;
                        }
                        if (message.searchKey != null && message.hasOwnProperty("searchKey"))
                            if (!$util.isString(message.searchKey))
                                return "searchKey: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffRequest} ListStaffRequest
                     */
                    ListStaffRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffRequest();
                        if (object.storeId) {
                            if (!Array.isArray(object.storeId))
                                throw TypeError(".palexy.streaming.v1.ListStaffRequest.storeId: array expected");
                            message.storeId = [];
                            for (var i = 0; i < object.storeId.length; ++i)
                                if ($util.Long)
                                    (message.storeId[i] = $util.Long.fromValue(object.storeId[i])).unsigned = false;
                                else if (typeof object.storeId[i] === "string")
                                    message.storeId[i] = parseInt(object.storeId[i], 10);
                                else if (typeof object.storeId[i] === "number")
                                    message.storeId[i] = object.storeId[i];
                                else if (typeof object.storeId[i] === "object")
                                    message.storeId[i] = new $util.LongBits(object.storeId[i].low >>> 0, object.storeId[i].high >>> 0).toNumber();
                        }
                        if (object.disabled != null) {
                            if (typeof object.disabled !== "object")
                                throw TypeError(".palexy.streaming.v1.ListStaffRequest.disabled: object expected");
                            message.disabled = $root.google.protobuf.BoolValue.fromObject(object.disabled);
                        }
                        if (object.searchKey != null)
                            message.searchKey = String(object.searchKey);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStaffRequest} message ListStaffRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeId = [];
                        if (options.defaults) {
                            object.disabled = null;
                            object.searchKey = "";
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.storeId && message.storeId.length) {
                            object.storeId = [];
                            for (var j = 0; j < message.storeId.length; ++j)
                                if (typeof message.storeId[j] === "number")
                                    object.storeId[j] = options.longs === String ? String(message.storeId[j]) : message.storeId[j];
                                else
                                    object.storeId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeId[j]) : options.longs === Number ? new $util.LongBits(message.storeId[j].low >>> 0, message.storeId[j].high >>> 0).toNumber() : message.storeId[j];
                        }
                        if (message.disabled != null && message.hasOwnProperty("disabled"))
                            object.disabled = $root.google.protobuf.BoolValue.toObject(message.disabled, options);
                        if (message.searchKey != null && message.hasOwnProperty("searchKey"))
                            object.searchKey = message.searchKey;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffRequest;
                })();
    
                v1.ListStaffResponse = (function() {
    
                    /**
                     * Properties of a ListStaffResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffResponse
                     * @property {Array.<palexy.streaming.v1.IStaff>|null} [staff] ListStaffResponse staff
                     * @property {number|null} [totalElements] ListStaffResponse totalElements
                     * @property {string|null} [nextPageToken] ListStaffResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListStaffResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffResponse.
                     * @implements IListStaffResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffResponse=} [properties] Properties to set
                     */
                    function ListStaffResponse(properties) {
                        this.staff = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffResponse staff.
                     * @member {Array.<palexy.streaming.v1.IStaff>} staff
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @instance
                     */
                    ListStaffResponse.prototype.staff = $util.emptyArray;
    
                    /**
                     * ListStaffResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @instance
                     */
                    ListStaffResponse.prototype.totalElements = 0;
    
                    /**
                     * ListStaffResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @instance
                     */
                    ListStaffResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListStaffResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffResponse} ListStaffResponse instance
                     */
                    ListStaffResponse.create = function create(properties) {
                        return new ListStaffResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffResponse message. Does not implicitly {@link palexy.streaming.v1.ListStaffResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffResponse} message ListStaffResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staff != null && message.staff.length)
                            for (var i = 0; i < message.staff.length; ++i)
                                $root.palexy.streaming.v1.Staff.encode(message.staff[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffResponse} message ListStaffResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffResponse} ListStaffResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.staff && message.staff.length))
                                    message.staff = [];
                                message.staff.push($root.palexy.streaming.v1.Staff.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffResponse} ListStaffResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staff != null && message.hasOwnProperty("staff")) {
                            if (!Array.isArray(message.staff))
                                return "staff: array expected";
                            for (var i = 0; i < message.staff.length; ++i) {
                                var error = $root.palexy.streaming.v1.Staff.verify(message.staff[i]);
                                if (error)
                                    return "staff." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffResponse} ListStaffResponse
                     */
                    ListStaffResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffResponse();
                        if (object.staff) {
                            if (!Array.isArray(object.staff))
                                throw TypeError(".palexy.streaming.v1.ListStaffResponse.staff: array expected");
                            message.staff = [];
                            for (var i = 0; i < object.staff.length; ++i) {
                                if (typeof object.staff[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStaffResponse.staff: object expected");
                                message.staff[i] = $root.palexy.streaming.v1.Staff.fromObject(object.staff[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStaffResponse} message ListStaffResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.staff = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.staff && message.staff.length) {
                            object.staff = [];
                            for (var j = 0; j < message.staff.length; ++j)
                                object.staff[j] = $root.palexy.streaming.v1.Staff.toObject(message.staff[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffResponse;
                })();
    
                v1.BatchCreateStaffRequest = (function() {
    
                    /**
                     * Properties of a BatchCreateStaffRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreateStaffRequest
                     * @property {number|null} [companyId] BatchCreateStaffRequest companyId
                     * @property {Array.<palexy.streaming.v1.IStaff>|null} [staff] BatchCreateStaffRequest staff
                     * @property {boolean|null} [preview] BatchCreateStaffRequest preview
                     */
    
                    /**
                     * Constructs a new BatchCreateStaffRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreateStaffRequest.
                     * @implements IBatchCreateStaffRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreateStaffRequest=} [properties] Properties to set
                     */
                    function BatchCreateStaffRequest(properties) {
                        this.staff = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateStaffRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @instance
                     */
                    BatchCreateStaffRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * BatchCreateStaffRequest staff.
                     * @member {Array.<palexy.streaming.v1.IStaff>} staff
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @instance
                     */
                    BatchCreateStaffRequest.prototype.staff = $util.emptyArray;
    
                    /**
                     * BatchCreateStaffRequest preview.
                     * @member {boolean} preview
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @instance
                     */
                    BatchCreateStaffRequest.prototype.preview = false;
    
                    /**
                     * Creates a new BatchCreateStaffRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStaffRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreateStaffRequest} BatchCreateStaffRequest instance
                     */
                    BatchCreateStaffRequest.create = function create(properties) {
                        return new BatchCreateStaffRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateStaffRequest message. Does not implicitly {@link palexy.streaming.v1.BatchCreateStaffRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStaffRequest} message BatchCreateStaffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStaffRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.staff != null && message.staff.length)
                            for (var i = 0; i < message.staff.length; ++i)
                                $root.palexy.streaming.v1.Staff.encode(message.staff[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.preview);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateStaffRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreateStaffRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStaffRequest} message BatchCreateStaffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStaffRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateStaffRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreateStaffRequest} BatchCreateStaffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStaffRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreateStaffRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                if (!(message.staff && message.staff.length))
                                    message.staff = [];
                                message.staff.push($root.palexy.streaming.v1.Staff.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.preview = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateStaffRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreateStaffRequest} BatchCreateStaffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStaffRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateStaffRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateStaffRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.staff != null && message.hasOwnProperty("staff")) {
                            if (!Array.isArray(message.staff))
                                return "staff: array expected";
                            for (var i = 0; i < message.staff.length; ++i) {
                                var error = $root.palexy.streaming.v1.Staff.verify(message.staff[i]);
                                if (error)
                                    return "staff." + error;
                            }
                        }
                        if (message.preview != null && message.hasOwnProperty("preview"))
                            if (typeof message.preview !== "boolean")
                                return "preview: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateStaffRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreateStaffRequest} BatchCreateStaffRequest
                     */
                    BatchCreateStaffRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreateStaffRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreateStaffRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.staff) {
                            if (!Array.isArray(object.staff))
                                throw TypeError(".palexy.streaming.v1.BatchCreateStaffRequest.staff: array expected");
                            message.staff = [];
                            for (var i = 0; i < object.staff.length; ++i) {
                                if (typeof object.staff[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreateStaffRequest.staff: object expected");
                                message.staff[i] = $root.palexy.streaming.v1.Staff.fromObject(object.staff[i]);
                            }
                        }
                        if (object.preview != null)
                            message.preview = Boolean(object.preview);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateStaffRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchCreateStaffRequest} message BatchCreateStaffRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateStaffRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.staff = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.preview = false;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.staff && message.staff.length) {
                            object.staff = [];
                            for (var j = 0; j < message.staff.length; ++j)
                                object.staff[j] = $root.palexy.streaming.v1.Staff.toObject(message.staff[j], options);
                        }
                        if (message.preview != null && message.hasOwnProperty("preview"))
                            object.preview = message.preview;
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateStaffRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreateStaffRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateStaffRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateStaffRequest;
                })();
    
                v1.BatchCreateStaffResponse = (function() {
    
                    /**
                     * Properties of a BatchCreateStaffResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreateStaffResponse
                     * @property {Array.<palexy.streaming.v1.IBatchCreateStaffResult>|null} [results] BatchCreateStaffResponse results
                     */
    
                    /**
                     * Constructs a new BatchCreateStaffResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreateStaffResponse.
                     * @implements IBatchCreateStaffResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreateStaffResponse=} [properties] Properties to set
                     */
                    function BatchCreateStaffResponse(properties) {
                        this.results = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateStaffResponse results.
                     * @member {Array.<palexy.streaming.v1.IBatchCreateStaffResult>} results
                     * @memberof palexy.streaming.v1.BatchCreateStaffResponse
                     * @instance
                     */
                    BatchCreateStaffResponse.prototype.results = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateStaffResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreateStaffResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStaffResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreateStaffResponse} BatchCreateStaffResponse instance
                     */
                    BatchCreateStaffResponse.create = function create(properties) {
                        return new BatchCreateStaffResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateStaffResponse message. Does not implicitly {@link palexy.streaming.v1.BatchCreateStaffResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreateStaffResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStaffResponse} message BatchCreateStaffResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStaffResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.results != null && message.results.length)
                            for (var i = 0; i < message.results.length; ++i)
                                $root.palexy.streaming.v1.BatchCreateStaffResult.encode(message.results[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateStaffResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreateStaffResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStaffResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStaffResponse} message BatchCreateStaffResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStaffResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateStaffResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreateStaffResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreateStaffResponse} BatchCreateStaffResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStaffResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreateStaffResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.results && message.results.length))
                                    message.results = [];
                                message.results.push($root.palexy.streaming.v1.BatchCreateStaffResult.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateStaffResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStaffResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreateStaffResponse} BatchCreateStaffResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStaffResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateStaffResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreateStaffResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateStaffResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.results != null && message.hasOwnProperty("results")) {
                            if (!Array.isArray(message.results))
                                return "results: array expected";
                            for (var i = 0; i < message.results.length; ++i) {
                                var error = $root.palexy.streaming.v1.BatchCreateStaffResult.verify(message.results[i]);
                                if (error)
                                    return "results." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateStaffResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreateStaffResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreateStaffResponse} BatchCreateStaffResponse
                     */
                    BatchCreateStaffResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreateStaffResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreateStaffResponse();
                        if (object.results) {
                            if (!Array.isArray(object.results))
                                throw TypeError(".palexy.streaming.v1.BatchCreateStaffResponse.results: array expected");
                            message.results = [];
                            for (var i = 0; i < object.results.length; ++i) {
                                if (typeof object.results[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreateStaffResponse.results: object expected");
                                message.results[i] = $root.palexy.streaming.v1.BatchCreateStaffResult.fromObject(object.results[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateStaffResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreateStaffResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchCreateStaffResponse} message BatchCreateStaffResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateStaffResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.results = [];
                        if (message.results && message.results.length) {
                            object.results = [];
                            for (var j = 0; j < message.results.length; ++j)
                                object.results[j] = $root.palexy.streaming.v1.BatchCreateStaffResult.toObject(message.results[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateStaffResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreateStaffResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateStaffResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateStaffResponse;
                })();
    
                v1.BatchCreateStaffResult = (function() {
    
                    /**
                     * Properties of a BatchCreateStaffResult.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreateStaffResult
                     * @property {palexy.streaming.v1.IStaff|null} [staff] BatchCreateStaffResult staff
                     * @property {number|null} [rowNum] BatchCreateStaffResult rowNum
                     * @property {boolean|null} [newStaff] BatchCreateStaffResult newStaff
                     * @property {boolean|null} [existed] BatchCreateStaffResult existed
                     * @property {string|null} [failedReason] BatchCreateStaffResult failedReason
                     */
    
                    /**
                     * Constructs a new BatchCreateStaffResult.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreateStaffResult.
                     * @implements IBatchCreateStaffResult
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreateStaffResult=} [properties] Properties to set
                     */
                    function BatchCreateStaffResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateStaffResult staff.
                     * @member {palexy.streaming.v1.IStaff|null|undefined} staff
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @instance
                     */
                    BatchCreateStaffResult.prototype.staff = null;
    
                    /**
                     * BatchCreateStaffResult rowNum.
                     * @member {number} rowNum
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @instance
                     */
                    BatchCreateStaffResult.prototype.rowNum = 0;
    
                    /**
                     * BatchCreateStaffResult newStaff.
                     * @member {boolean} newStaff
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @instance
                     */
                    BatchCreateStaffResult.prototype.newStaff = false;
    
                    /**
                     * BatchCreateStaffResult existed.
                     * @member {boolean} existed
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @instance
                     */
                    BatchCreateStaffResult.prototype.existed = false;
    
                    /**
                     * BatchCreateStaffResult failedReason.
                     * @member {string} failedReason
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @instance
                     */
                    BatchCreateStaffResult.prototype.failedReason = "";
    
                    /**
                     * Creates a new BatchCreateStaffResult instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStaffResult=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreateStaffResult} BatchCreateStaffResult instance
                     */
                    BatchCreateStaffResult.create = function create(properties) {
                        return new BatchCreateStaffResult(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateStaffResult message. Does not implicitly {@link palexy.streaming.v1.BatchCreateStaffResult.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStaffResult} message BatchCreateStaffResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStaffResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staff != null && Object.hasOwnProperty.call(message, "staff"))
                            $root.palexy.streaming.v1.Staff.encode(message.staff, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.rowNum != null && Object.hasOwnProperty.call(message, "rowNum"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.rowNum);
                        if (message.newStaff != null && Object.hasOwnProperty.call(message, "newStaff"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.newStaff);
                        if (message.existed != null && Object.hasOwnProperty.call(message, "existed"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.existed);
                        if (message.failedReason != null && Object.hasOwnProperty.call(message, "failedReason"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.failedReason);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateStaffResult message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreateStaffResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStaffResult} message BatchCreateStaffResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStaffResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateStaffResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreateStaffResult} BatchCreateStaffResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStaffResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreateStaffResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staff = $root.palexy.streaming.v1.Staff.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.rowNum = reader.int32();
                                break;
                            case 3:
                                message.newStaff = reader.bool();
                                break;
                            case 4:
                                message.existed = reader.bool();
                                break;
                            case 5:
                                message.failedReason = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateStaffResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreateStaffResult} BatchCreateStaffResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStaffResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateStaffResult message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateStaffResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staff != null && message.hasOwnProperty("staff")) {
                            var error = $root.palexy.streaming.v1.Staff.verify(message.staff);
                            if (error)
                                return "staff." + error;
                        }
                        if (message.rowNum != null && message.hasOwnProperty("rowNum"))
                            if (!$util.isInteger(message.rowNum))
                                return "rowNum: integer expected";
                        if (message.newStaff != null && message.hasOwnProperty("newStaff"))
                            if (typeof message.newStaff !== "boolean")
                                return "newStaff: boolean expected";
                        if (message.existed != null && message.hasOwnProperty("existed"))
                            if (typeof message.existed !== "boolean")
                                return "existed: boolean expected";
                        if (message.failedReason != null && message.hasOwnProperty("failedReason"))
                            if (!$util.isString(message.failedReason))
                                return "failedReason: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateStaffResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreateStaffResult} BatchCreateStaffResult
                     */
                    BatchCreateStaffResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreateStaffResult)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreateStaffResult();
                        if (object.staff != null) {
                            if (typeof object.staff !== "object")
                                throw TypeError(".palexy.streaming.v1.BatchCreateStaffResult.staff: object expected");
                            message.staff = $root.palexy.streaming.v1.Staff.fromObject(object.staff);
                        }
                        if (object.rowNum != null)
                            message.rowNum = object.rowNum | 0;
                        if (object.newStaff != null)
                            message.newStaff = Boolean(object.newStaff);
                        if (object.existed != null)
                            message.existed = Boolean(object.existed);
                        if (object.failedReason != null)
                            message.failedReason = String(object.failedReason);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateStaffResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @static
                     * @param {palexy.streaming.v1.BatchCreateStaffResult} message BatchCreateStaffResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateStaffResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.staff = null;
                            object.rowNum = 0;
                            object.newStaff = false;
                            object.existed = false;
                            object.failedReason = "";
                        }
                        if (message.staff != null && message.hasOwnProperty("staff"))
                            object.staff = $root.palexy.streaming.v1.Staff.toObject(message.staff, options);
                        if (message.rowNum != null && message.hasOwnProperty("rowNum"))
                            object.rowNum = message.rowNum;
                        if (message.newStaff != null && message.hasOwnProperty("newStaff"))
                            object.newStaff = message.newStaff;
                        if (message.existed != null && message.hasOwnProperty("existed"))
                            object.existed = message.existed;
                        if (message.failedReason != null && message.hasOwnProperty("failedReason"))
                            object.failedReason = message.failedReason;
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateStaffResult to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreateStaffResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateStaffResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateStaffResult;
                })();
    
                v1.ListUnmappedStaffIdRequest = (function() {
    
                    /**
                     * Properties of a ListUnmappedStaffIdRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListUnmappedStaffIdRequest
                     * @property {Array.<number>|null} [storeId] ListUnmappedStaffIdRequest storeId
                     * @property {palexy.streaming.v1.IDateFilter|null} [fromDate] ListUnmappedStaffIdRequest fromDate
                     * @property {boolean|null} [includePosSource] ListUnmappedStaffIdRequest includePosSource
                     * @property {boolean|null} [includeStaffingSource] ListUnmappedStaffIdRequest includeStaffingSource
                     */
    
                    /**
                     * Constructs a new ListUnmappedStaffIdRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListUnmappedStaffIdRequest.
                     * @implements IListUnmappedStaffIdRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListUnmappedStaffIdRequest=} [properties] Properties to set
                     */
                    function ListUnmappedStaffIdRequest(properties) {
                        this.storeId = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListUnmappedStaffIdRequest storeId.
                     * @member {Array.<number>} storeId
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @instance
                     */
                    ListUnmappedStaffIdRequest.prototype.storeId = $util.emptyArray;
    
                    /**
                     * ListUnmappedStaffIdRequest fromDate.
                     * @member {palexy.streaming.v1.IDateFilter|null|undefined} fromDate
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @instance
                     */
                    ListUnmappedStaffIdRequest.prototype.fromDate = null;
    
                    /**
                     * ListUnmappedStaffIdRequest includePosSource.
                     * @member {boolean} includePosSource
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @instance
                     */
                    ListUnmappedStaffIdRequest.prototype.includePosSource = false;
    
                    /**
                     * ListUnmappedStaffIdRequest includeStaffingSource.
                     * @member {boolean} includeStaffingSource
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @instance
                     */
                    ListUnmappedStaffIdRequest.prototype.includeStaffingSource = false;
    
                    /**
                     * Creates a new ListUnmappedStaffIdRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @static
                     * @param {palexy.streaming.v1.IListUnmappedStaffIdRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListUnmappedStaffIdRequest} ListUnmappedStaffIdRequest instance
                     */
                    ListUnmappedStaffIdRequest.create = function create(properties) {
                        return new ListUnmappedStaffIdRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListUnmappedStaffIdRequest message. Does not implicitly {@link palexy.streaming.v1.ListUnmappedStaffIdRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @static
                     * @param {palexy.streaming.v1.IListUnmappedStaffIdRequest} message ListUnmappedStaffIdRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListUnmappedStaffIdRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && message.storeId.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.storeId.length; ++i)
                                writer.int64(message.storeId[i]);
                            writer.ldelim();
                        }
                        if (message.fromDate != null && Object.hasOwnProperty.call(message, "fromDate"))
                            $root.palexy.streaming.v1.DateFilter.encode(message.fromDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.includePosSource != null && Object.hasOwnProperty.call(message, "includePosSource"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includePosSource);
                        if (message.includeStaffingSource != null && Object.hasOwnProperty.call(message, "includeStaffingSource"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeStaffingSource);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListUnmappedStaffIdRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListUnmappedStaffIdRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @static
                     * @param {palexy.streaming.v1.IListUnmappedStaffIdRequest} message ListUnmappedStaffIdRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListUnmappedStaffIdRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListUnmappedStaffIdRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListUnmappedStaffIdRequest} ListUnmappedStaffIdRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListUnmappedStaffIdRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListUnmappedStaffIdRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeId && message.storeId.length))
                                    message.storeId = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeId.push(reader.int64());
                                } else
                                    message.storeId.push(reader.int64());
                                break;
                            case 2:
                                message.fromDate = $root.palexy.streaming.v1.DateFilter.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.includePosSource = reader.bool();
                                break;
                            case 4:
                                message.includeStaffingSource = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListUnmappedStaffIdRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListUnmappedStaffIdRequest} ListUnmappedStaffIdRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListUnmappedStaffIdRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListUnmappedStaffIdRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListUnmappedStaffIdRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            if (!Array.isArray(message.storeId))
                                return "storeId: array expected";
                            for (var i = 0; i < message.storeId.length; ++i)
                                if (!$util.isInteger(message.storeId[i]) && !(message.storeId[i] && $util.isInteger(message.storeId[i].low) && $util.isInteger(message.storeId[i].high)))
                                    return "storeId: integer|Long[] expected";
                        }
                        if (message.fromDate != null && message.hasOwnProperty("fromDate")) {
                            var error = $root.palexy.streaming.v1.DateFilter.verify(message.fromDate);
                            if (error)
                                return "fromDate." + error;
                        }
                        if (message.includePosSource != null && message.hasOwnProperty("includePosSource"))
                            if (typeof message.includePosSource !== "boolean")
                                return "includePosSource: boolean expected";
                        if (message.includeStaffingSource != null && message.hasOwnProperty("includeStaffingSource"))
                            if (typeof message.includeStaffingSource !== "boolean")
                                return "includeStaffingSource: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListUnmappedStaffIdRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListUnmappedStaffIdRequest} ListUnmappedStaffIdRequest
                     */
                    ListUnmappedStaffIdRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListUnmappedStaffIdRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListUnmappedStaffIdRequest();
                        if (object.storeId) {
                            if (!Array.isArray(object.storeId))
                                throw TypeError(".palexy.streaming.v1.ListUnmappedStaffIdRequest.storeId: array expected");
                            message.storeId = [];
                            for (var i = 0; i < object.storeId.length; ++i)
                                if ($util.Long)
                                    (message.storeId[i] = $util.Long.fromValue(object.storeId[i])).unsigned = false;
                                else if (typeof object.storeId[i] === "string")
                                    message.storeId[i] = parseInt(object.storeId[i], 10);
                                else if (typeof object.storeId[i] === "number")
                                    message.storeId[i] = object.storeId[i];
                                else if (typeof object.storeId[i] === "object")
                                    message.storeId[i] = new $util.LongBits(object.storeId[i].low >>> 0, object.storeId[i].high >>> 0).toNumber();
                        }
                        if (object.fromDate != null) {
                            if (typeof object.fromDate !== "object")
                                throw TypeError(".palexy.streaming.v1.ListUnmappedStaffIdRequest.fromDate: object expected");
                            message.fromDate = $root.palexy.streaming.v1.DateFilter.fromObject(object.fromDate);
                        }
                        if (object.includePosSource != null)
                            message.includePosSource = Boolean(object.includePosSource);
                        if (object.includeStaffingSource != null)
                            message.includeStaffingSource = Boolean(object.includeStaffingSource);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListUnmappedStaffIdRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @static
                     * @param {palexy.streaming.v1.ListUnmappedStaffIdRequest} message ListUnmappedStaffIdRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListUnmappedStaffIdRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeId = [];
                        if (options.defaults) {
                            object.fromDate = null;
                            object.includePosSource = false;
                            object.includeStaffingSource = false;
                        }
                        if (message.storeId && message.storeId.length) {
                            object.storeId = [];
                            for (var j = 0; j < message.storeId.length; ++j)
                                if (typeof message.storeId[j] === "number")
                                    object.storeId[j] = options.longs === String ? String(message.storeId[j]) : message.storeId[j];
                                else
                                    object.storeId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeId[j]) : options.longs === Number ? new $util.LongBits(message.storeId[j].low >>> 0, message.storeId[j].high >>> 0).toNumber() : message.storeId[j];
                        }
                        if (message.fromDate != null && message.hasOwnProperty("fromDate"))
                            object.fromDate = $root.palexy.streaming.v1.DateFilter.toObject(message.fromDate, options);
                        if (message.includePosSource != null && message.hasOwnProperty("includePosSource"))
                            object.includePosSource = message.includePosSource;
                        if (message.includeStaffingSource != null && message.hasOwnProperty("includeStaffingSource"))
                            object.includeStaffingSource = message.includeStaffingSource;
                        return object;
                    };
    
                    /**
                     * Converts this ListUnmappedStaffIdRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListUnmappedStaffIdRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListUnmappedStaffIdRequest;
                })();
    
                v1.ListUnmappedStaffIdResponse = (function() {
    
                    /**
                     * Properties of a ListUnmappedStaffIdResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListUnmappedStaffIdResponse
                     * @property {Array.<palexy.streaming.v1.IUnmappedStaffId>|null} [posId] ListUnmappedStaffIdResponse posId
                     * @property {Array.<palexy.streaming.v1.IUnmappedStaffId>|null} [staffingId] ListUnmappedStaffIdResponse staffingId
                     */
    
                    /**
                     * Constructs a new ListUnmappedStaffIdResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListUnmappedStaffIdResponse.
                     * @implements IListUnmappedStaffIdResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListUnmappedStaffIdResponse=} [properties] Properties to set
                     */
                    function ListUnmappedStaffIdResponse(properties) {
                        this.posId = [];
                        this.staffingId = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListUnmappedStaffIdResponse posId.
                     * @member {Array.<palexy.streaming.v1.IUnmappedStaffId>} posId
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdResponse
                     * @instance
                     */
                    ListUnmappedStaffIdResponse.prototype.posId = $util.emptyArray;
    
                    /**
                     * ListUnmappedStaffIdResponse staffingId.
                     * @member {Array.<palexy.streaming.v1.IUnmappedStaffId>} staffingId
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdResponse
                     * @instance
                     */
                    ListUnmappedStaffIdResponse.prototype.staffingId = $util.emptyArray;
    
                    /**
                     * Creates a new ListUnmappedStaffIdResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdResponse
                     * @static
                     * @param {palexy.streaming.v1.IListUnmappedStaffIdResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListUnmappedStaffIdResponse} ListUnmappedStaffIdResponse instance
                     */
                    ListUnmappedStaffIdResponse.create = function create(properties) {
                        return new ListUnmappedStaffIdResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListUnmappedStaffIdResponse message. Does not implicitly {@link palexy.streaming.v1.ListUnmappedStaffIdResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdResponse
                     * @static
                     * @param {palexy.streaming.v1.IListUnmappedStaffIdResponse} message ListUnmappedStaffIdResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListUnmappedStaffIdResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.posId != null && message.posId.length)
                            for (var i = 0; i < message.posId.length; ++i)
                                $root.palexy.streaming.v1.UnmappedStaffId.encode(message.posId[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.staffingId != null && message.staffingId.length)
                            for (var i = 0; i < message.staffingId.length; ++i)
                                $root.palexy.streaming.v1.UnmappedStaffId.encode(message.staffingId[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListUnmappedStaffIdResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListUnmappedStaffIdResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdResponse
                     * @static
                     * @param {palexy.streaming.v1.IListUnmappedStaffIdResponse} message ListUnmappedStaffIdResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListUnmappedStaffIdResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListUnmappedStaffIdResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListUnmappedStaffIdResponse} ListUnmappedStaffIdResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListUnmappedStaffIdResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListUnmappedStaffIdResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.posId && message.posId.length))
                                    message.posId = [];
                                message.posId.push($root.palexy.streaming.v1.UnmappedStaffId.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.staffingId && message.staffingId.length))
                                    message.staffingId = [];
                                message.staffingId.push($root.palexy.streaming.v1.UnmappedStaffId.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListUnmappedStaffIdResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListUnmappedStaffIdResponse} ListUnmappedStaffIdResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListUnmappedStaffIdResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListUnmappedStaffIdResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListUnmappedStaffIdResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.posId != null && message.hasOwnProperty("posId")) {
                            if (!Array.isArray(message.posId))
                                return "posId: array expected";
                            for (var i = 0; i < message.posId.length; ++i) {
                                var error = $root.palexy.streaming.v1.UnmappedStaffId.verify(message.posId[i]);
                                if (error)
                                    return "posId." + error;
                            }
                        }
                        if (message.staffingId != null && message.hasOwnProperty("staffingId")) {
                            if (!Array.isArray(message.staffingId))
                                return "staffingId: array expected";
                            for (var i = 0; i < message.staffingId.length; ++i) {
                                var error = $root.palexy.streaming.v1.UnmappedStaffId.verify(message.staffingId[i]);
                                if (error)
                                    return "staffingId." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListUnmappedStaffIdResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListUnmappedStaffIdResponse} ListUnmappedStaffIdResponse
                     */
                    ListUnmappedStaffIdResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListUnmappedStaffIdResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListUnmappedStaffIdResponse();
                        if (object.posId) {
                            if (!Array.isArray(object.posId))
                                throw TypeError(".palexy.streaming.v1.ListUnmappedStaffIdResponse.posId: array expected");
                            message.posId = [];
                            for (var i = 0; i < object.posId.length; ++i) {
                                if (typeof object.posId[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListUnmappedStaffIdResponse.posId: object expected");
                                message.posId[i] = $root.palexy.streaming.v1.UnmappedStaffId.fromObject(object.posId[i]);
                            }
                        }
                        if (object.staffingId) {
                            if (!Array.isArray(object.staffingId))
                                throw TypeError(".palexy.streaming.v1.ListUnmappedStaffIdResponse.staffingId: array expected");
                            message.staffingId = [];
                            for (var i = 0; i < object.staffingId.length; ++i) {
                                if (typeof object.staffingId[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListUnmappedStaffIdResponse.staffingId: object expected");
                                message.staffingId[i] = $root.palexy.streaming.v1.UnmappedStaffId.fromObject(object.staffingId[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListUnmappedStaffIdResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdResponse
                     * @static
                     * @param {palexy.streaming.v1.ListUnmappedStaffIdResponse} message ListUnmappedStaffIdResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListUnmappedStaffIdResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.posId = [];
                            object.staffingId = [];
                        }
                        if (message.posId && message.posId.length) {
                            object.posId = [];
                            for (var j = 0; j < message.posId.length; ++j)
                                object.posId[j] = $root.palexy.streaming.v1.UnmappedStaffId.toObject(message.posId[j], options);
                        }
                        if (message.staffingId && message.staffingId.length) {
                            object.staffingId = [];
                            for (var j = 0; j < message.staffingId.length; ++j)
                                object.staffingId[j] = $root.palexy.streaming.v1.UnmappedStaffId.toObject(message.staffingId[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListUnmappedStaffIdResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListUnmappedStaffIdResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListUnmappedStaffIdResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListUnmappedStaffIdResponse;
                })();
    
                v1.UnmappedStaffId = (function() {
    
                    /**
                     * Properties of an UnmappedStaffId.
                     * @memberof palexy.streaming.v1
                     * @interface IUnmappedStaffId
                     * @property {string|null} [externalStaffId] UnmappedStaffId externalStaffId
                     * @property {string|null} [latestPresentDate] UnmappedStaffId latestPresentDate
                     */
    
                    /**
                     * Constructs a new UnmappedStaffId.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UnmappedStaffId.
                     * @implements IUnmappedStaffId
                     * @constructor
                     * @param {palexy.streaming.v1.IUnmappedStaffId=} [properties] Properties to set
                     */
                    function UnmappedStaffId(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UnmappedStaffId externalStaffId.
                     * @member {string} externalStaffId
                     * @memberof palexy.streaming.v1.UnmappedStaffId
                     * @instance
                     */
                    UnmappedStaffId.prototype.externalStaffId = "";
    
                    /**
                     * UnmappedStaffId latestPresentDate.
                     * @member {string} latestPresentDate
                     * @memberof palexy.streaming.v1.UnmappedStaffId
                     * @instance
                     */
                    UnmappedStaffId.prototype.latestPresentDate = "";
    
                    /**
                     * Creates a new UnmappedStaffId instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UnmappedStaffId
                     * @static
                     * @param {palexy.streaming.v1.IUnmappedStaffId=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UnmappedStaffId} UnmappedStaffId instance
                     */
                    UnmappedStaffId.create = function create(properties) {
                        return new UnmappedStaffId(properties);
                    };
    
                    /**
                     * Encodes the specified UnmappedStaffId message. Does not implicitly {@link palexy.streaming.v1.UnmappedStaffId.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UnmappedStaffId
                     * @static
                     * @param {palexy.streaming.v1.IUnmappedStaffId} message UnmappedStaffId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UnmappedStaffId.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.externalStaffId != null && Object.hasOwnProperty.call(message, "externalStaffId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalStaffId);
                        if (message.latestPresentDate != null && Object.hasOwnProperty.call(message, "latestPresentDate"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.latestPresentDate);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UnmappedStaffId message, length delimited. Does not implicitly {@link palexy.streaming.v1.UnmappedStaffId.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UnmappedStaffId
                     * @static
                     * @param {palexy.streaming.v1.IUnmappedStaffId} message UnmappedStaffId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UnmappedStaffId.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UnmappedStaffId message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UnmappedStaffId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UnmappedStaffId} UnmappedStaffId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UnmappedStaffId.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UnmappedStaffId();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.externalStaffId = reader.string();
                                break;
                            case 2:
                                message.latestPresentDate = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UnmappedStaffId message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UnmappedStaffId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UnmappedStaffId} UnmappedStaffId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UnmappedStaffId.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UnmappedStaffId message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UnmappedStaffId
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UnmappedStaffId.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.externalStaffId != null && message.hasOwnProperty("externalStaffId"))
                            if (!$util.isString(message.externalStaffId))
                                return "externalStaffId: string expected";
                        if (message.latestPresentDate != null && message.hasOwnProperty("latestPresentDate"))
                            if (!$util.isString(message.latestPresentDate))
                                return "latestPresentDate: string expected";
                        return null;
                    };
    
                    /**
                     * Creates an UnmappedStaffId message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UnmappedStaffId
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UnmappedStaffId} UnmappedStaffId
                     */
                    UnmappedStaffId.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UnmappedStaffId)
                            return object;
                        var message = new $root.palexy.streaming.v1.UnmappedStaffId();
                        if (object.externalStaffId != null)
                            message.externalStaffId = String(object.externalStaffId);
                        if (object.latestPresentDate != null)
                            message.latestPresentDate = String(object.latestPresentDate);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UnmappedStaffId message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UnmappedStaffId
                     * @static
                     * @param {palexy.streaming.v1.UnmappedStaffId} message UnmappedStaffId
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UnmappedStaffId.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.externalStaffId = "";
                            object.latestPresentDate = "";
                        }
                        if (message.externalStaffId != null && message.hasOwnProperty("externalStaffId"))
                            object.externalStaffId = message.externalStaffId;
                        if (message.latestPresentDate != null && message.hasOwnProperty("latestPresentDate"))
                            object.latestPresentDate = message.latestPresentDate;
                        return object;
                    };
    
                    /**
                     * Converts this UnmappedStaffId to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UnmappedStaffId
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UnmappedStaffId.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UnmappedStaffId;
                })();
    
                v1.ListStaffSalesHistoryRequest = (function() {
    
                    /**
                     * Properties of a ListStaffSalesHistoryRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffSalesHistoryRequest
                     * @property {number|null} [staffId] ListStaffSalesHistoryRequest staffId
                     * @property {palexy.streaming.v1.IDateFilter|null} [fromDate] ListStaffSalesHistoryRequest fromDate
                     * @property {palexy.streaming.v1.IDateFilter|null} [toDate] ListStaffSalesHistoryRequest toDate
                     * @property {Array.<number>|null} [storeId] ListStaffSalesHistoryRequest storeId
                     * @property {number|null} [pageSize] ListStaffSalesHistoryRequest pageSize
                     * @property {string|null} [pageToken] ListStaffSalesHistoryRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListStaffSalesHistoryRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffSalesHistoryRequest.
                     * @implements IListStaffSalesHistoryRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffSalesHistoryRequest=} [properties] Properties to set
                     */
                    function ListStaffSalesHistoryRequest(properties) {
                        this.storeId = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffSalesHistoryRequest staffId.
                     * @member {number} staffId
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @instance
                     */
                    ListStaffSalesHistoryRequest.prototype.staffId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListStaffSalesHistoryRequest fromDate.
                     * @member {palexy.streaming.v1.IDateFilter|null|undefined} fromDate
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @instance
                     */
                    ListStaffSalesHistoryRequest.prototype.fromDate = null;
    
                    /**
                     * ListStaffSalesHistoryRequest toDate.
                     * @member {palexy.streaming.v1.IDateFilter|null|undefined} toDate
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @instance
                     */
                    ListStaffSalesHistoryRequest.prototype.toDate = null;
    
                    /**
                     * ListStaffSalesHistoryRequest storeId.
                     * @member {Array.<number>} storeId
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @instance
                     */
                    ListStaffSalesHistoryRequest.prototype.storeId = $util.emptyArray;
    
                    /**
                     * ListStaffSalesHistoryRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @instance
                     */
                    ListStaffSalesHistoryRequest.prototype.pageSize = 0;
    
                    /**
                     * ListStaffSalesHistoryRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @instance
                     */
                    ListStaffSalesHistoryRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListStaffSalesHistoryRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffSalesHistoryRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffSalesHistoryRequest} ListStaffSalesHistoryRequest instance
                     */
                    ListStaffSalesHistoryRequest.create = function create(properties) {
                        return new ListStaffSalesHistoryRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffSalesHistoryRequest message. Does not implicitly {@link palexy.streaming.v1.ListStaffSalesHistoryRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffSalesHistoryRequest} message ListStaffSalesHistoryRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffSalesHistoryRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffId != null && Object.hasOwnProperty.call(message, "staffId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.staffId);
                        if (message.fromDate != null && Object.hasOwnProperty.call(message, "fromDate"))
                            $root.palexy.streaming.v1.DateFilter.encode(message.fromDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.pageToken);
                        if (message.toDate != null && Object.hasOwnProperty.call(message, "toDate"))
                            $root.palexy.streaming.v1.DateFilter.encode(message.toDate, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.storeId != null && message.storeId.length) {
                            writer.uint32(/* id 6, wireType 2 =*/50).fork();
                            for (var i = 0; i < message.storeId.length; ++i)
                                writer.int64(message.storeId[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffSalesHistoryRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffSalesHistoryRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffSalesHistoryRequest} message ListStaffSalesHistoryRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffSalesHistoryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffSalesHistoryRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffSalesHistoryRequest} ListStaffSalesHistoryRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffSalesHistoryRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffSalesHistoryRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffId = reader.int64();
                                break;
                            case 2:
                                message.fromDate = $root.palexy.streaming.v1.DateFilter.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.toDate = $root.palexy.streaming.v1.DateFilter.decode(reader, reader.uint32());
                                break;
                            case 6:
                                if (!(message.storeId && message.storeId.length))
                                    message.storeId = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeId.push(reader.int64());
                                } else
                                    message.storeId.push(reader.int64());
                                break;
                            case 3:
                                message.pageSize = reader.int32();
                                break;
                            case 4:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffSalesHistoryRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffSalesHistoryRequest} ListStaffSalesHistoryRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffSalesHistoryRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffSalesHistoryRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffSalesHistoryRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (!$util.isInteger(message.staffId) && !(message.staffId && $util.isInteger(message.staffId.low) && $util.isInteger(message.staffId.high)))
                                return "staffId: integer|Long expected";
                        if (message.fromDate != null && message.hasOwnProperty("fromDate")) {
                            var error = $root.palexy.streaming.v1.DateFilter.verify(message.fromDate);
                            if (error)
                                return "fromDate." + error;
                        }
                        if (message.toDate != null && message.hasOwnProperty("toDate")) {
                            var error = $root.palexy.streaming.v1.DateFilter.verify(message.toDate);
                            if (error)
                                return "toDate." + error;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            if (!Array.isArray(message.storeId))
                                return "storeId: array expected";
                            for (var i = 0; i < message.storeId.length; ++i)
                                if (!$util.isInteger(message.storeId[i]) && !(message.storeId[i] && $util.isInteger(message.storeId[i].low) && $util.isInteger(message.storeId[i].high)))
                                    return "storeId: integer|Long[] expected";
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffSalesHistoryRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffSalesHistoryRequest} ListStaffSalesHistoryRequest
                     */
                    ListStaffSalesHistoryRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffSalesHistoryRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffSalesHistoryRequest();
                        if (object.staffId != null)
                            if ($util.Long)
                                (message.staffId = $util.Long.fromValue(object.staffId)).unsigned = false;
                            else if (typeof object.staffId === "string")
                                message.staffId = parseInt(object.staffId, 10);
                            else if (typeof object.staffId === "number")
                                message.staffId = object.staffId;
                            else if (typeof object.staffId === "object")
                                message.staffId = new $util.LongBits(object.staffId.low >>> 0, object.staffId.high >>> 0).toNumber();
                        if (object.fromDate != null) {
                            if (typeof object.fromDate !== "object")
                                throw TypeError(".palexy.streaming.v1.ListStaffSalesHistoryRequest.fromDate: object expected");
                            message.fromDate = $root.palexy.streaming.v1.DateFilter.fromObject(object.fromDate);
                        }
                        if (object.toDate != null) {
                            if (typeof object.toDate !== "object")
                                throw TypeError(".palexy.streaming.v1.ListStaffSalesHistoryRequest.toDate: object expected");
                            message.toDate = $root.palexy.streaming.v1.DateFilter.fromObject(object.toDate);
                        }
                        if (object.storeId) {
                            if (!Array.isArray(object.storeId))
                                throw TypeError(".palexy.streaming.v1.ListStaffSalesHistoryRequest.storeId: array expected");
                            message.storeId = [];
                            for (var i = 0; i < object.storeId.length; ++i)
                                if ($util.Long)
                                    (message.storeId[i] = $util.Long.fromValue(object.storeId[i])).unsigned = false;
                                else if (typeof object.storeId[i] === "string")
                                    message.storeId[i] = parseInt(object.storeId[i], 10);
                                else if (typeof object.storeId[i] === "number")
                                    message.storeId[i] = object.storeId[i];
                                else if (typeof object.storeId[i] === "object")
                                    message.storeId[i] = new $util.LongBits(object.storeId[i].low >>> 0, object.storeId[i].high >>> 0).toNumber();
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffSalesHistoryRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStaffSalesHistoryRequest} message ListStaffSalesHistoryRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffSalesHistoryRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeId = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffId = options.longs === String ? "0" : 0;
                            object.fromDate = null;
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.toDate = null;
                        }
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (typeof message.staffId === "number")
                                object.staffId = options.longs === String ? String(message.staffId) : message.staffId;
                            else
                                object.staffId = options.longs === String ? $util.Long.prototype.toString.call(message.staffId) : options.longs === Number ? new $util.LongBits(message.staffId.low >>> 0, message.staffId.high >>> 0).toNumber() : message.staffId;
                        if (message.fromDate != null && message.hasOwnProperty("fromDate"))
                            object.fromDate = $root.palexy.streaming.v1.DateFilter.toObject(message.fromDate, options);
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.toDate != null && message.hasOwnProperty("toDate"))
                            object.toDate = $root.palexy.streaming.v1.DateFilter.toObject(message.toDate, options);
                        if (message.storeId && message.storeId.length) {
                            object.storeId = [];
                            for (var j = 0; j < message.storeId.length; ++j)
                                if (typeof message.storeId[j] === "number")
                                    object.storeId[j] = options.longs === String ? String(message.storeId[j]) : message.storeId[j];
                                else
                                    object.storeId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeId[j]) : options.longs === Number ? new $util.LongBits(message.storeId[j].low >>> 0, message.storeId[j].high >>> 0).toNumber() : message.storeId[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffSalesHistoryRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffSalesHistoryRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffSalesHistoryRequest;
                })();
    
                v1.ListStaffSalesHistoryResponse = (function() {
    
                    /**
                     * Properties of a ListStaffSalesHistoryResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffSalesHistoryResponse
                     * @property {Array.<palexy.streaming.v1.IPosTransaction>|null} [transactionList] ListStaffSalesHistoryResponse transactionList
                     * @property {number|null} [totalElements] ListStaffSalesHistoryResponse totalElements
                     * @property {string|null} [nextPageToken] ListStaffSalesHistoryResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListStaffSalesHistoryResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffSalesHistoryResponse.
                     * @implements IListStaffSalesHistoryResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffSalesHistoryResponse=} [properties] Properties to set
                     */
                    function ListStaffSalesHistoryResponse(properties) {
                        this.transactionList = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffSalesHistoryResponse transactionList.
                     * @member {Array.<palexy.streaming.v1.IPosTransaction>} transactionList
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @instance
                     */
                    ListStaffSalesHistoryResponse.prototype.transactionList = $util.emptyArray;
    
                    /**
                     * ListStaffSalesHistoryResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @instance
                     */
                    ListStaffSalesHistoryResponse.prototype.totalElements = 0;
    
                    /**
                     * ListStaffSalesHistoryResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @instance
                     */
                    ListStaffSalesHistoryResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListStaffSalesHistoryResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffSalesHistoryResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffSalesHistoryResponse} ListStaffSalesHistoryResponse instance
                     */
                    ListStaffSalesHistoryResponse.create = function create(properties) {
                        return new ListStaffSalesHistoryResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffSalesHistoryResponse message. Does not implicitly {@link palexy.streaming.v1.ListStaffSalesHistoryResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffSalesHistoryResponse} message ListStaffSalesHistoryResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffSalesHistoryResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.transactionList != null && message.transactionList.length)
                            for (var i = 0; i < message.transactionList.length; ++i)
                                $root.palexy.streaming.v1.PosTransaction.encode(message.transactionList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffSalesHistoryResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffSalesHistoryResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffSalesHistoryResponse} message ListStaffSalesHistoryResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffSalesHistoryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffSalesHistoryResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffSalesHistoryResponse} ListStaffSalesHistoryResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffSalesHistoryResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffSalesHistoryResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.transactionList && message.transactionList.length))
                                    message.transactionList = [];
                                message.transactionList.push($root.palexy.streaming.v1.PosTransaction.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffSalesHistoryResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffSalesHistoryResponse} ListStaffSalesHistoryResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffSalesHistoryResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffSalesHistoryResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffSalesHistoryResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.transactionList != null && message.hasOwnProperty("transactionList")) {
                            if (!Array.isArray(message.transactionList))
                                return "transactionList: array expected";
                            for (var i = 0; i < message.transactionList.length; ++i) {
                                var error = $root.palexy.streaming.v1.PosTransaction.verify(message.transactionList[i]);
                                if (error)
                                    return "transactionList." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffSalesHistoryResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffSalesHistoryResponse} ListStaffSalesHistoryResponse
                     */
                    ListStaffSalesHistoryResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffSalesHistoryResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffSalesHistoryResponse();
                        if (object.transactionList) {
                            if (!Array.isArray(object.transactionList))
                                throw TypeError(".palexy.streaming.v1.ListStaffSalesHistoryResponse.transactionList: array expected");
                            message.transactionList = [];
                            for (var i = 0; i < object.transactionList.length; ++i) {
                                if (typeof object.transactionList[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStaffSalesHistoryResponse.transactionList: object expected");
                                message.transactionList[i] = $root.palexy.streaming.v1.PosTransaction.fromObject(object.transactionList[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffSalesHistoryResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStaffSalesHistoryResponse} message ListStaffSalesHistoryResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffSalesHistoryResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.transactionList = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.transactionList && message.transactionList.length) {
                            object.transactionList = [];
                            for (var j = 0; j < message.transactionList.length; ++j)
                                object.transactionList[j] = $root.palexy.streaming.v1.PosTransaction.toObject(message.transactionList[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffSalesHistoryResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffSalesHistoryResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffSalesHistoryResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffSalesHistoryResponse;
                })();
    
                v1.AssignStaffToStoreRequest = (function() {
    
                    /**
                     * Properties of an AssignStaffToStoreRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IAssignStaffToStoreRequest
                     * @property {number|null} [storeId] AssignStaffToStoreRequest storeId
                     * @property {number|null} [staffId] AssignStaffToStoreRequest staffId
                     * @property {string|null} [auditUsername] AssignStaffToStoreRequest auditUsername
                     * @property {palexy.streaming.v1.StaffAssignedStore.StaffType|null} [staffType] AssignStaffToStoreRequest staffType
                     */
    
                    /**
                     * Constructs a new AssignStaffToStoreRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AssignStaffToStoreRequest.
                     * @implements IAssignStaffToStoreRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IAssignStaffToStoreRequest=} [properties] Properties to set
                     */
                    function AssignStaffToStoreRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AssignStaffToStoreRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @instance
                     */
                    AssignStaffToStoreRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AssignStaffToStoreRequest staffId.
                     * @member {number} staffId
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @instance
                     */
                    AssignStaffToStoreRequest.prototype.staffId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AssignStaffToStoreRequest auditUsername.
                     * @member {string} auditUsername
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @instance
                     */
                    AssignStaffToStoreRequest.prototype.auditUsername = "";
    
                    /**
                     * AssignStaffToStoreRequest staffType.
                     * @member {palexy.streaming.v1.StaffAssignedStore.StaffType} staffType
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @instance
                     */
                    AssignStaffToStoreRequest.prototype.staffType = 0;
    
                    /**
                     * Creates a new AssignStaffToStoreRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.IAssignStaffToStoreRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AssignStaffToStoreRequest} AssignStaffToStoreRequest instance
                     */
                    AssignStaffToStoreRequest.create = function create(properties) {
                        return new AssignStaffToStoreRequest(properties);
                    };
    
                    /**
                     * Encodes the specified AssignStaffToStoreRequest message. Does not implicitly {@link palexy.streaming.v1.AssignStaffToStoreRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.IAssignStaffToStoreRequest} message AssignStaffToStoreRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AssignStaffToStoreRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.staffId != null && Object.hasOwnProperty.call(message, "staffId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.staffId);
                        if (message.auditUsername != null && Object.hasOwnProperty.call(message, "auditUsername"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.auditUsername);
                        if (message.staffType != null && Object.hasOwnProperty.call(message, "staffType"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.staffType);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AssignStaffToStoreRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.AssignStaffToStoreRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.IAssignStaffToStoreRequest} message AssignStaffToStoreRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AssignStaffToStoreRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AssignStaffToStoreRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AssignStaffToStoreRequest} AssignStaffToStoreRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AssignStaffToStoreRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AssignStaffToStoreRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.staffId = reader.int64();
                                break;
                            case 3:
                                message.auditUsername = reader.string();
                                break;
                            case 4:
                                message.staffType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AssignStaffToStoreRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AssignStaffToStoreRequest} AssignStaffToStoreRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AssignStaffToStoreRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AssignStaffToStoreRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AssignStaffToStoreRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (!$util.isInteger(message.staffId) && !(message.staffId && $util.isInteger(message.staffId.low) && $util.isInteger(message.staffId.high)))
                                return "staffId: integer|Long expected";
                        if (message.auditUsername != null && message.hasOwnProperty("auditUsername"))
                            if (!$util.isString(message.auditUsername))
                                return "auditUsername: string expected";
                        if (message.staffType != null && message.hasOwnProperty("staffType"))
                            switch (message.staffType) {
                            default:
                                return "staffType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates an AssignStaffToStoreRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AssignStaffToStoreRequest} AssignStaffToStoreRequest
                     */
                    AssignStaffToStoreRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AssignStaffToStoreRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.AssignStaffToStoreRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.staffId != null)
                            if ($util.Long)
                                (message.staffId = $util.Long.fromValue(object.staffId)).unsigned = false;
                            else if (typeof object.staffId === "string")
                                message.staffId = parseInt(object.staffId, 10);
                            else if (typeof object.staffId === "number")
                                message.staffId = object.staffId;
                            else if (typeof object.staffId === "object")
                                message.staffId = new $util.LongBits(object.staffId.low >>> 0, object.staffId.high >>> 0).toNumber();
                        if (object.auditUsername != null)
                            message.auditUsername = String(object.auditUsername);
                        switch (object.staffType) {
                        case "UNKNOWN_STAFF_TYPE":
                        case 0:
                            message.staffType = 0;
                            break;
                        case "PERMANENT":
                        case 1:
                            message.staffType = 1;
                            break;
                        case "BORROWED":
                        case 2:
                            message.staffType = 2;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AssignStaffToStoreRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.AssignStaffToStoreRequest} message AssignStaffToStoreRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AssignStaffToStoreRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffId = options.longs === String ? "0" : 0;
                            object.auditUsername = "";
                            object.staffType = options.enums === String ? "UNKNOWN_STAFF_TYPE" : 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (typeof message.staffId === "number")
                                object.staffId = options.longs === String ? String(message.staffId) : message.staffId;
                            else
                                object.staffId = options.longs === String ? $util.Long.prototype.toString.call(message.staffId) : options.longs === Number ? new $util.LongBits(message.staffId.low >>> 0, message.staffId.high >>> 0).toNumber() : message.staffId;
                        if (message.auditUsername != null && message.hasOwnProperty("auditUsername"))
                            object.auditUsername = message.auditUsername;
                        if (message.staffType != null && message.hasOwnProperty("staffType"))
                            object.staffType = options.enums === String ? $root.palexy.streaming.v1.StaffAssignedStore.StaffType[message.staffType] : message.staffType;
                        return object;
                    };
    
                    /**
                     * Converts this AssignStaffToStoreRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AssignStaffToStoreRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AssignStaffToStoreRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AssignStaffToStoreRequest;
                })();
    
                v1.AssignStaffToStoreResponse = (function() {
    
                    /**
                     * Properties of an AssignStaffToStoreResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IAssignStaffToStoreResponse
                     */
    
                    /**
                     * Constructs a new AssignStaffToStoreResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AssignStaffToStoreResponse.
                     * @implements IAssignStaffToStoreResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IAssignStaffToStoreResponse=} [properties] Properties to set
                     */
                    function AssignStaffToStoreResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new AssignStaffToStoreResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AssignStaffToStoreResponse
                     * @static
                     * @param {palexy.streaming.v1.IAssignStaffToStoreResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AssignStaffToStoreResponse} AssignStaffToStoreResponse instance
                     */
                    AssignStaffToStoreResponse.create = function create(properties) {
                        return new AssignStaffToStoreResponse(properties);
                    };
    
                    /**
                     * Encodes the specified AssignStaffToStoreResponse message. Does not implicitly {@link palexy.streaming.v1.AssignStaffToStoreResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AssignStaffToStoreResponse
                     * @static
                     * @param {palexy.streaming.v1.IAssignStaffToStoreResponse} message AssignStaffToStoreResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AssignStaffToStoreResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AssignStaffToStoreResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.AssignStaffToStoreResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AssignStaffToStoreResponse
                     * @static
                     * @param {palexy.streaming.v1.IAssignStaffToStoreResponse} message AssignStaffToStoreResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AssignStaffToStoreResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AssignStaffToStoreResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AssignStaffToStoreResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AssignStaffToStoreResponse} AssignStaffToStoreResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AssignStaffToStoreResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AssignStaffToStoreResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AssignStaffToStoreResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AssignStaffToStoreResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AssignStaffToStoreResponse} AssignStaffToStoreResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AssignStaffToStoreResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AssignStaffToStoreResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AssignStaffToStoreResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AssignStaffToStoreResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates an AssignStaffToStoreResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AssignStaffToStoreResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AssignStaffToStoreResponse} AssignStaffToStoreResponse
                     */
                    AssignStaffToStoreResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AssignStaffToStoreResponse)
                            return object;
                        return new $root.palexy.streaming.v1.AssignStaffToStoreResponse();
                    };
    
                    /**
                     * Creates a plain object from an AssignStaffToStoreResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AssignStaffToStoreResponse
                     * @static
                     * @param {palexy.streaming.v1.AssignStaffToStoreResponse} message AssignStaffToStoreResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AssignStaffToStoreResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this AssignStaffToStoreResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AssignStaffToStoreResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AssignStaffToStoreResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AssignStaffToStoreResponse;
                })();
    
                v1.RemoveStaffFromStoreRequest = (function() {
    
                    /**
                     * Properties of a RemoveStaffFromStoreRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IRemoveStaffFromStoreRequest
                     * @property {number|null} [storeId] RemoveStaffFromStoreRequest storeId
                     * @property {number|null} [staffId] RemoveStaffFromStoreRequest staffId
                     * @property {string|null} [auditUsername] RemoveStaffFromStoreRequest auditUsername
                     */
    
                    /**
                     * Constructs a new RemoveStaffFromStoreRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RemoveStaffFromStoreRequest.
                     * @implements IRemoveStaffFromStoreRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IRemoveStaffFromStoreRequest=} [properties] Properties to set
                     */
                    function RemoveStaffFromStoreRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RemoveStaffFromStoreRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @instance
                     */
                    RemoveStaffFromStoreRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RemoveStaffFromStoreRequest staffId.
                     * @member {number} staffId
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @instance
                     */
                    RemoveStaffFromStoreRequest.prototype.staffId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RemoveStaffFromStoreRequest auditUsername.
                     * @member {string} auditUsername
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @instance
                     */
                    RemoveStaffFromStoreRequest.prototype.auditUsername = "";
    
                    /**
                     * Creates a new RemoveStaffFromStoreRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.IRemoveStaffFromStoreRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RemoveStaffFromStoreRequest} RemoveStaffFromStoreRequest instance
                     */
                    RemoveStaffFromStoreRequest.create = function create(properties) {
                        return new RemoveStaffFromStoreRequest(properties);
                    };
    
                    /**
                     * Encodes the specified RemoveStaffFromStoreRequest message. Does not implicitly {@link palexy.streaming.v1.RemoveStaffFromStoreRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.IRemoveStaffFromStoreRequest} message RemoveStaffFromStoreRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveStaffFromStoreRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.staffId != null && Object.hasOwnProperty.call(message, "staffId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.staffId);
                        if (message.auditUsername != null && Object.hasOwnProperty.call(message, "auditUsername"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.auditUsername);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RemoveStaffFromStoreRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.RemoveStaffFromStoreRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.IRemoveStaffFromStoreRequest} message RemoveStaffFromStoreRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveStaffFromStoreRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RemoveStaffFromStoreRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RemoveStaffFromStoreRequest} RemoveStaffFromStoreRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveStaffFromStoreRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RemoveStaffFromStoreRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.staffId = reader.int64();
                                break;
                            case 3:
                                message.auditUsername = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RemoveStaffFromStoreRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RemoveStaffFromStoreRequest} RemoveStaffFromStoreRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveStaffFromStoreRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RemoveStaffFromStoreRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RemoveStaffFromStoreRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (!$util.isInteger(message.staffId) && !(message.staffId && $util.isInteger(message.staffId.low) && $util.isInteger(message.staffId.high)))
                                return "staffId: integer|Long expected";
                        if (message.auditUsername != null && message.hasOwnProperty("auditUsername"))
                            if (!$util.isString(message.auditUsername))
                                return "auditUsername: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a RemoveStaffFromStoreRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RemoveStaffFromStoreRequest} RemoveStaffFromStoreRequest
                     */
                    RemoveStaffFromStoreRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RemoveStaffFromStoreRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.RemoveStaffFromStoreRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.staffId != null)
                            if ($util.Long)
                                (message.staffId = $util.Long.fromValue(object.staffId)).unsigned = false;
                            else if (typeof object.staffId === "string")
                                message.staffId = parseInt(object.staffId, 10);
                            else if (typeof object.staffId === "number")
                                message.staffId = object.staffId;
                            else if (typeof object.staffId === "object")
                                message.staffId = new $util.LongBits(object.staffId.low >>> 0, object.staffId.high >>> 0).toNumber();
                        if (object.auditUsername != null)
                            message.auditUsername = String(object.auditUsername);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RemoveStaffFromStoreRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @static
                     * @param {palexy.streaming.v1.RemoveStaffFromStoreRequest} message RemoveStaffFromStoreRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RemoveStaffFromStoreRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffId = options.longs === String ? "0" : 0;
                            object.auditUsername = "";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (typeof message.staffId === "number")
                                object.staffId = options.longs === String ? String(message.staffId) : message.staffId;
                            else
                                object.staffId = options.longs === String ? $util.Long.prototype.toString.call(message.staffId) : options.longs === Number ? new $util.LongBits(message.staffId.low >>> 0, message.staffId.high >>> 0).toNumber() : message.staffId;
                        if (message.auditUsername != null && message.hasOwnProperty("auditUsername"))
                            object.auditUsername = message.auditUsername;
                        return object;
                    };
    
                    /**
                     * Converts this RemoveStaffFromStoreRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RemoveStaffFromStoreRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RemoveStaffFromStoreRequest;
                })();
    
                v1.RemoveStaffFromStoreResponse = (function() {
    
                    /**
                     * Properties of a RemoveStaffFromStoreResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IRemoveStaffFromStoreResponse
                     */
    
                    /**
                     * Constructs a new RemoveStaffFromStoreResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RemoveStaffFromStoreResponse.
                     * @implements IRemoveStaffFromStoreResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IRemoveStaffFromStoreResponse=} [properties] Properties to set
                     */
                    function RemoveStaffFromStoreResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new RemoveStaffFromStoreResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreResponse
                     * @static
                     * @param {palexy.streaming.v1.IRemoveStaffFromStoreResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RemoveStaffFromStoreResponse} RemoveStaffFromStoreResponse instance
                     */
                    RemoveStaffFromStoreResponse.create = function create(properties) {
                        return new RemoveStaffFromStoreResponse(properties);
                    };
    
                    /**
                     * Encodes the specified RemoveStaffFromStoreResponse message. Does not implicitly {@link palexy.streaming.v1.RemoveStaffFromStoreResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreResponse
                     * @static
                     * @param {palexy.streaming.v1.IRemoveStaffFromStoreResponse} message RemoveStaffFromStoreResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveStaffFromStoreResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RemoveStaffFromStoreResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.RemoveStaffFromStoreResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreResponse
                     * @static
                     * @param {palexy.streaming.v1.IRemoveStaffFromStoreResponse} message RemoveStaffFromStoreResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveStaffFromStoreResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RemoveStaffFromStoreResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RemoveStaffFromStoreResponse} RemoveStaffFromStoreResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveStaffFromStoreResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RemoveStaffFromStoreResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RemoveStaffFromStoreResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RemoveStaffFromStoreResponse} RemoveStaffFromStoreResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveStaffFromStoreResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RemoveStaffFromStoreResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RemoveStaffFromStoreResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a RemoveStaffFromStoreResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RemoveStaffFromStoreResponse} RemoveStaffFromStoreResponse
                     */
                    RemoveStaffFromStoreResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RemoveStaffFromStoreResponse)
                            return object;
                        return new $root.palexy.streaming.v1.RemoveStaffFromStoreResponse();
                    };
    
                    /**
                     * Creates a plain object from a RemoveStaffFromStoreResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreResponse
                     * @static
                     * @param {palexy.streaming.v1.RemoveStaffFromStoreResponse} message RemoveStaffFromStoreResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RemoveStaffFromStoreResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this RemoveStaffFromStoreResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RemoveStaffFromStoreResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RemoveStaffFromStoreResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RemoveStaffFromStoreResponse;
                })();
    
                v1.AsyncQueueSenderService = (function() {
    
                    /**
                     * Constructs a new AsyncQueueSenderService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AsyncQueueSenderService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function AsyncQueueSenderService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (AsyncQueueSenderService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AsyncQueueSenderService;
    
                    /**
                     * Creates new AsyncQueueSenderService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.AsyncQueueSenderService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {AsyncQueueSenderService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    AsyncQueueSenderService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AsyncQueueSenderService#executeTask}.
                     * @memberof palexy.streaming.v1.AsyncQueueSenderService
                     * @typedef ExecuteTaskCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls ExecuteTask.
                     * @function executeTask
                     * @memberof palexy.streaming.v1.AsyncQueueSenderService
                     * @instance
                     * @param {palexy.streaming.v1.IAsyncQueueSenderExecuteTaskRequest} request AsyncQueueSenderExecuteTaskRequest message or plain object
                     * @param {palexy.streaming.v1.AsyncQueueSenderService.ExecuteTaskCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AsyncQueueSenderService.prototype.executeTask = function executeTask(request, callback) {
                        return this.rpcCall(executeTask, $root.palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "ExecuteTask" });
    
                    /**
                     * Calls ExecuteTask.
                     * @function executeTask
                     * @memberof palexy.streaming.v1.AsyncQueueSenderService
                     * @instance
                     * @param {palexy.streaming.v1.IAsyncQueueSenderExecuteTaskRequest} request AsyncQueueSenderExecuteTaskRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AsyncQueueSenderService#listTasks}.
                     * @memberof palexy.streaming.v1.AsyncQueueSenderService
                     * @typedef ListTasksCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListAsyncQueueSenderTasksResponse} [response] ListAsyncQueueSenderTasksResponse
                     */
    
                    /**
                     * Calls ListTasks.
                     * @function listTasks
                     * @memberof palexy.streaming.v1.AsyncQueueSenderService
                     * @instance
                     * @param {palexy.streaming.v1.IListAsyncQueueSenderTasksRequest} request ListAsyncQueueSenderTasksRequest message or plain object
                     * @param {palexy.streaming.v1.AsyncQueueSenderService.ListTasksCallback} callback Node-style callback called with the error, if any, and ListAsyncQueueSenderTasksResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AsyncQueueSenderService.prototype.listTasks = function listTasks(request, callback) {
                        return this.rpcCall(listTasks, $root.palexy.streaming.v1.ListAsyncQueueSenderTasksRequest, $root.palexy.streaming.v1.ListAsyncQueueSenderTasksResponse, request, callback);
                    }, "name", { value: "ListTasks" });
    
                    /**
                     * Calls ListTasks.
                     * @function listTasks
                     * @memberof palexy.streaming.v1.AsyncQueueSenderService
                     * @instance
                     * @param {palexy.streaming.v1.IListAsyncQueueSenderTasksRequest} request ListAsyncQueueSenderTasksRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListAsyncQueueSenderTasksResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.AsyncQueueSenderService#batchDeleteTasks}.
                     * @memberof palexy.streaming.v1.AsyncQueueSenderService
                     * @typedef BatchDeleteTasksCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls BatchDeleteTasks.
                     * @function batchDeleteTasks
                     * @memberof palexy.streaming.v1.AsyncQueueSenderService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchDeleteTasksRequest} request BatchDeleteTasksRequest message or plain object
                     * @param {palexy.streaming.v1.AsyncQueueSenderService.BatchDeleteTasksCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AsyncQueueSenderService.prototype.batchDeleteTasks = function batchDeleteTasks(request, callback) {
                        return this.rpcCall(batchDeleteTasks, $root.palexy.streaming.v1.BatchDeleteTasksRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "BatchDeleteTasks" });
    
                    /**
                     * Calls BatchDeleteTasks.
                     * @function batchDeleteTasks
                     * @memberof palexy.streaming.v1.AsyncQueueSenderService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchDeleteTasksRequest} request BatchDeleteTasksRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return AsyncQueueSenderService;
                })();
    
                v1.AsyncQueueSenderTask = (function() {
    
                    /**
                     * Properties of an AsyncQueueSenderTask.
                     * @memberof palexy.streaming.v1
                     * @interface IAsyncQueueSenderTask
                     * @property {number|null} [id] AsyncQueueSenderTask id
                     * @property {Uint8Array|null} [message] AsyncQueueSenderTask message
                     * @property {string|null} [destinationQueue] AsyncQueueSenderTask destinationQueue
                     * @property {string|null} [traceId] AsyncQueueSenderTask traceId
                     * @property {palexy.streaming.v1.AsyncQueueSenderTask.AsyncQueueSenderTaskState|null} [state] AsyncQueueSenderTask state
                     * @property {number|null} [createTimeMs] AsyncQueueSenderTask createTimeMs
                     * @property {number|null} [updateTimeMs] AsyncQueueSenderTask updateTimeMs
                     * @property {number|null} [sentTimeMs] AsyncQueueSenderTask sentTimeMs
                     */
    
                    /**
                     * Constructs a new AsyncQueueSenderTask.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AsyncQueueSenderTask.
                     * @implements IAsyncQueueSenderTask
                     * @constructor
                     * @param {palexy.streaming.v1.IAsyncQueueSenderTask=} [properties] Properties to set
                     */
                    function AsyncQueueSenderTask(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AsyncQueueSenderTask id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @instance
                     */
                    AsyncQueueSenderTask.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AsyncQueueSenderTask message.
                     * @member {Uint8Array} message
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @instance
                     */
                    AsyncQueueSenderTask.prototype.message = $util.newBuffer([]);
    
                    /**
                     * AsyncQueueSenderTask destinationQueue.
                     * @member {string} destinationQueue
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @instance
                     */
                    AsyncQueueSenderTask.prototype.destinationQueue = "";
    
                    /**
                     * AsyncQueueSenderTask traceId.
                     * @member {string} traceId
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @instance
                     */
                    AsyncQueueSenderTask.prototype.traceId = "";
    
                    /**
                     * AsyncQueueSenderTask state.
                     * @member {palexy.streaming.v1.AsyncQueueSenderTask.AsyncQueueSenderTaskState} state
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @instance
                     */
                    AsyncQueueSenderTask.prototype.state = 0;
    
                    /**
                     * AsyncQueueSenderTask createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @instance
                     */
                    AsyncQueueSenderTask.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AsyncQueueSenderTask updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @instance
                     */
                    AsyncQueueSenderTask.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * AsyncQueueSenderTask sentTimeMs.
                     * @member {number} sentTimeMs
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @instance
                     */
                    AsyncQueueSenderTask.prototype.sentTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new AsyncQueueSenderTask instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @static
                     * @param {palexy.streaming.v1.IAsyncQueueSenderTask=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AsyncQueueSenderTask} AsyncQueueSenderTask instance
                     */
                    AsyncQueueSenderTask.create = function create(properties) {
                        return new AsyncQueueSenderTask(properties);
                    };
    
                    /**
                     * Encodes the specified AsyncQueueSenderTask message. Does not implicitly {@link palexy.streaming.v1.AsyncQueueSenderTask.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @static
                     * @param {palexy.streaming.v1.IAsyncQueueSenderTask} message AsyncQueueSenderTask message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AsyncQueueSenderTask.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.message);
                        if (message.destinationQueue != null && Object.hasOwnProperty.call(message, "destinationQueue"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.destinationQueue);
                        if (message.traceId != null && Object.hasOwnProperty.call(message, "traceId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.traceId);
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.state);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.updateTimeMs);
                        if (message.sentTimeMs != null && Object.hasOwnProperty.call(message, "sentTimeMs"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.sentTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AsyncQueueSenderTask message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncQueueSenderTask.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @static
                     * @param {palexy.streaming.v1.IAsyncQueueSenderTask} message AsyncQueueSenderTask message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AsyncQueueSenderTask.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AsyncQueueSenderTask message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AsyncQueueSenderTask} AsyncQueueSenderTask
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AsyncQueueSenderTask.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncQueueSenderTask();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.message = reader.bytes();
                                break;
                            case 3:
                                message.destinationQueue = reader.string();
                                break;
                            case 4:
                                message.traceId = reader.string();
                                break;
                            case 5:
                                message.state = reader.int32();
                                break;
                            case 6:
                                message.createTimeMs = reader.int64();
                                break;
                            case 7:
                                message.updateTimeMs = reader.int64();
                                break;
                            case 8:
                                message.sentTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AsyncQueueSenderTask message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AsyncQueueSenderTask} AsyncQueueSenderTask
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AsyncQueueSenderTask.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AsyncQueueSenderTask message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AsyncQueueSenderTask.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!(message.message && typeof message.message.length === "number" || $util.isString(message.message)))
                                return "message: buffer expected";
                        if (message.destinationQueue != null && message.hasOwnProperty("destinationQueue"))
                            if (!$util.isString(message.destinationQueue))
                                return "destinationQueue: string expected";
                        if (message.traceId != null && message.hasOwnProperty("traceId"))
                            if (!$util.isString(message.traceId))
                                return "traceId: string expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        if (message.sentTimeMs != null && message.hasOwnProperty("sentTimeMs"))
                            if (!$util.isInteger(message.sentTimeMs) && !(message.sentTimeMs && $util.isInteger(message.sentTimeMs.low) && $util.isInteger(message.sentTimeMs.high)))
                                return "sentTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an AsyncQueueSenderTask message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AsyncQueueSenderTask} AsyncQueueSenderTask
                     */
                    AsyncQueueSenderTask.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AsyncQueueSenderTask)
                            return object;
                        var message = new $root.palexy.streaming.v1.AsyncQueueSenderTask();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.message != null)
                            if (typeof object.message === "string")
                                $util.base64.decode(object.message, message.message = $util.newBuffer($util.base64.length(object.message)), 0);
                            else if (object.message.length)
                                message.message = object.message;
                        if (object.destinationQueue != null)
                            message.destinationQueue = String(object.destinationQueue);
                        if (object.traceId != null)
                            message.traceId = String(object.traceId);
                        switch (object.state) {
                        case "UNKNOWN":
                        case 0:
                            message.state = 0;
                            break;
                        case "WAITING_TO_SEND":
                        case 1:
                            message.state = 1;
                            break;
                        case "SENT":
                        case 2:
                            message.state = 2;
                            break;
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        if (object.sentTimeMs != null)
                            if ($util.Long)
                                (message.sentTimeMs = $util.Long.fromValue(object.sentTimeMs)).unsigned = false;
                            else if (typeof object.sentTimeMs === "string")
                                message.sentTimeMs = parseInt(object.sentTimeMs, 10);
                            else if (typeof object.sentTimeMs === "number")
                                message.sentTimeMs = object.sentTimeMs;
                            else if (typeof object.sentTimeMs === "object")
                                message.sentTimeMs = new $util.LongBits(object.sentTimeMs.low >>> 0, object.sentTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AsyncQueueSenderTask message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @static
                     * @param {palexy.streaming.v1.AsyncQueueSenderTask} message AsyncQueueSenderTask
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AsyncQueueSenderTask.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if (options.bytes === String)
                                object.message = "";
                            else {
                                object.message = [];
                                if (options.bytes !== Array)
                                    object.message = $util.newBuffer(object.message);
                            }
                            object.destinationQueue = "";
                            object.traceId = "";
                            object.state = options.enums === String ? "UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sentTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sentTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = options.bytes === String ? $util.base64.encode(message.message, 0, message.message.length) : options.bytes === Array ? Array.prototype.slice.call(message.message) : message.message;
                        if (message.destinationQueue != null && message.hasOwnProperty("destinationQueue"))
                            object.destinationQueue = message.destinationQueue;
                        if (message.traceId != null && message.hasOwnProperty("traceId"))
                            object.traceId = message.traceId;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.streaming.v1.AsyncQueueSenderTask.AsyncQueueSenderTaskState[message.state] : message.state;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        if (message.sentTimeMs != null && message.hasOwnProperty("sentTimeMs"))
                            if (typeof message.sentTimeMs === "number")
                                object.sentTimeMs = options.longs === String ? String(message.sentTimeMs) : message.sentTimeMs;
                            else
                                object.sentTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.sentTimeMs) : options.longs === Number ? new $util.LongBits(message.sentTimeMs.low >>> 0, message.sentTimeMs.high >>> 0).toNumber() : message.sentTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this AsyncQueueSenderTask to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTask
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AsyncQueueSenderTask.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * AsyncQueueSenderTaskState enum.
                     * @name palexy.streaming.v1.AsyncQueueSenderTask.AsyncQueueSenderTaskState
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} WAITING_TO_SEND=1 WAITING_TO_SEND value
                     * @property {number} SENT=2 SENT value
                     */
                    AsyncQueueSenderTask.AsyncQueueSenderTaskState = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "WAITING_TO_SEND"] = 1;
                        values[valuesById[2] = "SENT"] = 2;
                        return values;
                    })();
    
                    return AsyncQueueSenderTask;
                })();
    
                v1.ListAsyncQueueSenderTasksRequest = (function() {
    
                    /**
                     * Properties of a ListAsyncQueueSenderTasksRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListAsyncQueueSenderTasksRequest
                     * @property {number|null} [pageSize] ListAsyncQueueSenderTasksRequest pageSize
                     * @property {string|null} [pageToken] ListAsyncQueueSenderTasksRequest pageToken
                     * @property {palexy.streaming.v1.IAsyncQueueSenderTaskFilter|null} [filter] ListAsyncQueueSenderTasksRequest filter
                     */
    
                    /**
                     * Constructs a new ListAsyncQueueSenderTasksRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListAsyncQueueSenderTasksRequest.
                     * @implements IListAsyncQueueSenderTasksRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListAsyncQueueSenderTasksRequest=} [properties] Properties to set
                     */
                    function ListAsyncQueueSenderTasksRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListAsyncQueueSenderTasksRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @instance
                     */
                    ListAsyncQueueSenderTasksRequest.prototype.pageSize = 0;
    
                    /**
                     * ListAsyncQueueSenderTasksRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @instance
                     */
                    ListAsyncQueueSenderTasksRequest.prototype.pageToken = "";
    
                    /**
                     * ListAsyncQueueSenderTasksRequest filter.
                     * @member {palexy.streaming.v1.IAsyncQueueSenderTaskFilter|null|undefined} filter
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @instance
                     */
                    ListAsyncQueueSenderTasksRequest.prototype.filter = null;
    
                    /**
                     * Creates a new ListAsyncQueueSenderTasksRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncQueueSenderTasksRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListAsyncQueueSenderTasksRequest} ListAsyncQueueSenderTasksRequest instance
                     */
                    ListAsyncQueueSenderTasksRequest.create = function create(properties) {
                        return new ListAsyncQueueSenderTasksRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListAsyncQueueSenderTasksRequest message. Does not implicitly {@link palexy.streaming.v1.ListAsyncQueueSenderTasksRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncQueueSenderTasksRequest} message ListAsyncQueueSenderTasksRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAsyncQueueSenderTasksRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pageToken);
                        if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                            $root.palexy.streaming.v1.AsyncQueueSenderTaskFilter.encode(message.filter, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListAsyncQueueSenderTasksRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListAsyncQueueSenderTasksRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncQueueSenderTasksRequest} message ListAsyncQueueSenderTasksRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAsyncQueueSenderTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListAsyncQueueSenderTasksRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListAsyncQueueSenderTasksRequest} ListAsyncQueueSenderTasksRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAsyncQueueSenderTasksRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListAsyncQueueSenderTasksRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.pageSize = reader.int32();
                                break;
                            case 2:
                                message.pageToken = reader.string();
                                break;
                            case 3:
                                message.filter = $root.palexy.streaming.v1.AsyncQueueSenderTaskFilter.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListAsyncQueueSenderTasksRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListAsyncQueueSenderTasksRequest} ListAsyncQueueSenderTasksRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAsyncQueueSenderTasksRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListAsyncQueueSenderTasksRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListAsyncQueueSenderTasksRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.filter != null && message.hasOwnProperty("filter")) {
                            var error = $root.palexy.streaming.v1.AsyncQueueSenderTaskFilter.verify(message.filter);
                            if (error)
                                return "filter." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListAsyncQueueSenderTasksRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListAsyncQueueSenderTasksRequest} ListAsyncQueueSenderTasksRequest
                     */
                    ListAsyncQueueSenderTasksRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListAsyncQueueSenderTasksRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListAsyncQueueSenderTasksRequest();
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.filter != null) {
                            if (typeof object.filter !== "object")
                                throw TypeError(".palexy.streaming.v1.ListAsyncQueueSenderTasksRequest.filter: object expected");
                            message.filter = $root.palexy.streaming.v1.AsyncQueueSenderTaskFilter.fromObject(object.filter);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListAsyncQueueSenderTasksRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.ListAsyncQueueSenderTasksRequest} message ListAsyncQueueSenderTasksRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListAsyncQueueSenderTasksRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.filter = null;
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.filter != null && message.hasOwnProperty("filter"))
                            object.filter = $root.palexy.streaming.v1.AsyncQueueSenderTaskFilter.toObject(message.filter, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListAsyncQueueSenderTasksRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListAsyncQueueSenderTasksRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListAsyncQueueSenderTasksRequest;
                })();
    
                v1.AsyncQueueSenderTaskFilter = (function() {
    
                    /**
                     * Properties of an AsyncQueueSenderTaskFilter.
                     * @memberof palexy.streaming.v1
                     * @interface IAsyncQueueSenderTaskFilter
                     * @property {palexy.streaming.v1.AsyncQueueSenderTask.AsyncQueueSenderTaskState|null} [state] AsyncQueueSenderTaskFilter state
                     * @property {number|null} [maxCreateTimeMs] AsyncQueueSenderTaskFilter maxCreateTimeMs
                     */
    
                    /**
                     * Constructs a new AsyncQueueSenderTaskFilter.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AsyncQueueSenderTaskFilter.
                     * @implements IAsyncQueueSenderTaskFilter
                     * @constructor
                     * @param {palexy.streaming.v1.IAsyncQueueSenderTaskFilter=} [properties] Properties to set
                     */
                    function AsyncQueueSenderTaskFilter(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AsyncQueueSenderTaskFilter state.
                     * @member {palexy.streaming.v1.AsyncQueueSenderTask.AsyncQueueSenderTaskState} state
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTaskFilter
                     * @instance
                     */
                    AsyncQueueSenderTaskFilter.prototype.state = 0;
    
                    /**
                     * AsyncQueueSenderTaskFilter maxCreateTimeMs.
                     * @member {number} maxCreateTimeMs
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTaskFilter
                     * @instance
                     */
                    AsyncQueueSenderTaskFilter.prototype.maxCreateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new AsyncQueueSenderTaskFilter instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTaskFilter
                     * @static
                     * @param {palexy.streaming.v1.IAsyncQueueSenderTaskFilter=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AsyncQueueSenderTaskFilter} AsyncQueueSenderTaskFilter instance
                     */
                    AsyncQueueSenderTaskFilter.create = function create(properties) {
                        return new AsyncQueueSenderTaskFilter(properties);
                    };
    
                    /**
                     * Encodes the specified AsyncQueueSenderTaskFilter message. Does not implicitly {@link palexy.streaming.v1.AsyncQueueSenderTaskFilter.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTaskFilter
                     * @static
                     * @param {palexy.streaming.v1.IAsyncQueueSenderTaskFilter} message AsyncQueueSenderTaskFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AsyncQueueSenderTaskFilter.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
                        if (message.maxCreateTimeMs != null && Object.hasOwnProperty.call(message, "maxCreateTimeMs"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.maxCreateTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AsyncQueueSenderTaskFilter message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncQueueSenderTaskFilter.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTaskFilter
                     * @static
                     * @param {palexy.streaming.v1.IAsyncQueueSenderTaskFilter} message AsyncQueueSenderTaskFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AsyncQueueSenderTaskFilter.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AsyncQueueSenderTaskFilter message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTaskFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AsyncQueueSenderTaskFilter} AsyncQueueSenderTaskFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AsyncQueueSenderTaskFilter.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncQueueSenderTaskFilter();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.state = reader.int32();
                                break;
                            case 2:
                                message.maxCreateTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AsyncQueueSenderTaskFilter message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTaskFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AsyncQueueSenderTaskFilter} AsyncQueueSenderTaskFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AsyncQueueSenderTaskFilter.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AsyncQueueSenderTaskFilter message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTaskFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AsyncQueueSenderTaskFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.maxCreateTimeMs != null && message.hasOwnProperty("maxCreateTimeMs"))
                            if (!$util.isInteger(message.maxCreateTimeMs) && !(message.maxCreateTimeMs && $util.isInteger(message.maxCreateTimeMs.low) && $util.isInteger(message.maxCreateTimeMs.high)))
                                return "maxCreateTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an AsyncQueueSenderTaskFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTaskFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AsyncQueueSenderTaskFilter} AsyncQueueSenderTaskFilter
                     */
                    AsyncQueueSenderTaskFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AsyncQueueSenderTaskFilter)
                            return object;
                        var message = new $root.palexy.streaming.v1.AsyncQueueSenderTaskFilter();
                        switch (object.state) {
                        case "UNKNOWN":
                        case 0:
                            message.state = 0;
                            break;
                        case "WAITING_TO_SEND":
                        case 1:
                            message.state = 1;
                            break;
                        case "SENT":
                        case 2:
                            message.state = 2;
                            break;
                        }
                        if (object.maxCreateTimeMs != null)
                            if ($util.Long)
                                (message.maxCreateTimeMs = $util.Long.fromValue(object.maxCreateTimeMs)).unsigned = false;
                            else if (typeof object.maxCreateTimeMs === "string")
                                message.maxCreateTimeMs = parseInt(object.maxCreateTimeMs, 10);
                            else if (typeof object.maxCreateTimeMs === "number")
                                message.maxCreateTimeMs = object.maxCreateTimeMs;
                            else if (typeof object.maxCreateTimeMs === "object")
                                message.maxCreateTimeMs = new $util.LongBits(object.maxCreateTimeMs.low >>> 0, object.maxCreateTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AsyncQueueSenderTaskFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTaskFilter
                     * @static
                     * @param {palexy.streaming.v1.AsyncQueueSenderTaskFilter} message AsyncQueueSenderTaskFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AsyncQueueSenderTaskFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.state = options.enums === String ? "UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.maxCreateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.maxCreateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.streaming.v1.AsyncQueueSenderTask.AsyncQueueSenderTaskState[message.state] : message.state;
                        if (message.maxCreateTimeMs != null && message.hasOwnProperty("maxCreateTimeMs"))
                            if (typeof message.maxCreateTimeMs === "number")
                                object.maxCreateTimeMs = options.longs === String ? String(message.maxCreateTimeMs) : message.maxCreateTimeMs;
                            else
                                object.maxCreateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.maxCreateTimeMs) : options.longs === Number ? new $util.LongBits(message.maxCreateTimeMs.low >>> 0, message.maxCreateTimeMs.high >>> 0).toNumber() : message.maxCreateTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this AsyncQueueSenderTaskFilter to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AsyncQueueSenderTaskFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AsyncQueueSenderTaskFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AsyncQueueSenderTaskFilter;
                })();
    
                v1.ListAsyncQueueSenderTasksResponse = (function() {
    
                    /**
                     * Properties of a ListAsyncQueueSenderTasksResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListAsyncQueueSenderTasksResponse
                     * @property {Array.<palexy.streaming.v1.IAsyncQueueSenderTask>|null} [tasks] ListAsyncQueueSenderTasksResponse tasks
                     * @property {number|null} [totalElements] ListAsyncQueueSenderTasksResponse totalElements
                     * @property {string|null} [nextPageToken] ListAsyncQueueSenderTasksResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListAsyncQueueSenderTasksResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListAsyncQueueSenderTasksResponse.
                     * @implements IListAsyncQueueSenderTasksResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListAsyncQueueSenderTasksResponse=} [properties] Properties to set
                     */
                    function ListAsyncQueueSenderTasksResponse(properties) {
                        this.tasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListAsyncQueueSenderTasksResponse tasks.
                     * @member {Array.<palexy.streaming.v1.IAsyncQueueSenderTask>} tasks
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @instance
                     */
                    ListAsyncQueueSenderTasksResponse.prototype.tasks = $util.emptyArray;
    
                    /**
                     * ListAsyncQueueSenderTasksResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @instance
                     */
                    ListAsyncQueueSenderTasksResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListAsyncQueueSenderTasksResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @instance
                     */
                    ListAsyncQueueSenderTasksResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListAsyncQueueSenderTasksResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncQueueSenderTasksResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListAsyncQueueSenderTasksResponse} ListAsyncQueueSenderTasksResponse instance
                     */
                    ListAsyncQueueSenderTasksResponse.create = function create(properties) {
                        return new ListAsyncQueueSenderTasksResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListAsyncQueueSenderTasksResponse message. Does not implicitly {@link palexy.streaming.v1.ListAsyncQueueSenderTasksResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncQueueSenderTasksResponse} message ListAsyncQueueSenderTasksResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAsyncQueueSenderTasksResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.tasks != null && message.tasks.length)
                            for (var i = 0; i < message.tasks.length; ++i)
                                $root.palexy.streaming.v1.AsyncQueueSenderTask.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListAsyncQueueSenderTasksResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListAsyncQueueSenderTasksResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @static
                     * @param {palexy.streaming.v1.IListAsyncQueueSenderTasksResponse} message ListAsyncQueueSenderTasksResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListAsyncQueueSenderTasksResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListAsyncQueueSenderTasksResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListAsyncQueueSenderTasksResponse} ListAsyncQueueSenderTasksResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAsyncQueueSenderTasksResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListAsyncQueueSenderTasksResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.palexy.streaming.v1.AsyncQueueSenderTask.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int64();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListAsyncQueueSenderTasksResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListAsyncQueueSenderTasksResponse} ListAsyncQueueSenderTasksResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListAsyncQueueSenderTasksResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListAsyncQueueSenderTasksResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListAsyncQueueSenderTasksResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.tasks != null && message.hasOwnProperty("tasks")) {
                            if (!Array.isArray(message.tasks))
                                return "tasks: array expected";
                            for (var i = 0; i < message.tasks.length; ++i) {
                                var error = $root.palexy.streaming.v1.AsyncQueueSenderTask.verify(message.tasks[i]);
                                if (error)
                                    return "tasks." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListAsyncQueueSenderTasksResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListAsyncQueueSenderTasksResponse} ListAsyncQueueSenderTasksResponse
                     */
                    ListAsyncQueueSenderTasksResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListAsyncQueueSenderTasksResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListAsyncQueueSenderTasksResponse();
                        if (object.tasks) {
                            if (!Array.isArray(object.tasks))
                                throw TypeError(".palexy.streaming.v1.ListAsyncQueueSenderTasksResponse.tasks: array expected");
                            message.tasks = [];
                            for (var i = 0; i < object.tasks.length; ++i) {
                                if (typeof object.tasks[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListAsyncQueueSenderTasksResponse.tasks: object expected");
                                message.tasks[i] = $root.palexy.streaming.v1.AsyncQueueSenderTask.fromObject(object.tasks[i]);
                            }
                        }
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListAsyncQueueSenderTasksResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @static
                     * @param {palexy.streaming.v1.ListAsyncQueueSenderTasksResponse} message ListAsyncQueueSenderTasksResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListAsyncQueueSenderTasksResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.tasks = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                            object.nextPageToken = "";
                        }
                        if (message.tasks && message.tasks.length) {
                            object.tasks = [];
                            for (var j = 0; j < message.tasks.length; ++j)
                                object.tasks[j] = $root.palexy.streaming.v1.AsyncQueueSenderTask.toObject(message.tasks[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListAsyncQueueSenderTasksResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListAsyncQueueSenderTasksResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListAsyncQueueSenderTasksResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListAsyncQueueSenderTasksResponse;
                })();
    
                v1.BatchDeleteTasksRequest = (function() {
    
                    /**
                     * Properties of a BatchDeleteTasksRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchDeleteTasksRequest
                     * @property {Array.<number>|null} [taskIds] BatchDeleteTasksRequest taskIds
                     */
    
                    /**
                     * Constructs a new BatchDeleteTasksRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchDeleteTasksRequest.
                     * @implements IBatchDeleteTasksRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchDeleteTasksRequest=} [properties] Properties to set
                     */
                    function BatchDeleteTasksRequest(properties) {
                        this.taskIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchDeleteTasksRequest taskIds.
                     * @member {Array.<number>} taskIds
                     * @memberof palexy.streaming.v1.BatchDeleteTasksRequest
                     * @instance
                     */
                    BatchDeleteTasksRequest.prototype.taskIds = $util.emptyArray;
    
                    /**
                     * Creates a new BatchDeleteTasksRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchDeleteTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteTasksRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchDeleteTasksRequest} BatchDeleteTasksRequest instance
                     */
                    BatchDeleteTasksRequest.create = function create(properties) {
                        return new BatchDeleteTasksRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchDeleteTasksRequest message. Does not implicitly {@link palexy.streaming.v1.BatchDeleteTasksRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchDeleteTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteTasksRequest} message BatchDeleteTasksRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteTasksRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.taskIds != null && message.taskIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.taskIds.length; ++i)
                                writer.int64(message.taskIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchDeleteTasksRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchDeleteTasksRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchDeleteTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteTasksRequest} message BatchDeleteTasksRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchDeleteTasksRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchDeleteTasksRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchDeleteTasksRequest} BatchDeleteTasksRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteTasksRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchDeleteTasksRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.taskIds && message.taskIds.length))
                                    message.taskIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.taskIds.push(reader.int64());
                                } else
                                    message.taskIds.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchDeleteTasksRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchDeleteTasksRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchDeleteTasksRequest} BatchDeleteTasksRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteTasksRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchDeleteTasksRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchDeleteTasksRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchDeleteTasksRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.taskIds != null && message.hasOwnProperty("taskIds")) {
                            if (!Array.isArray(message.taskIds))
                                return "taskIds: array expected";
                            for (var i = 0; i < message.taskIds.length; ++i)
                                if (!$util.isInteger(message.taskIds[i]) && !(message.taskIds[i] && $util.isInteger(message.taskIds[i].low) && $util.isInteger(message.taskIds[i].high)))
                                    return "taskIds: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchDeleteTasksRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchDeleteTasksRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchDeleteTasksRequest} BatchDeleteTasksRequest
                     */
                    BatchDeleteTasksRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchDeleteTasksRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchDeleteTasksRequest();
                        if (object.taskIds) {
                            if (!Array.isArray(object.taskIds))
                                throw TypeError(".palexy.streaming.v1.BatchDeleteTasksRequest.taskIds: array expected");
                            message.taskIds = [];
                            for (var i = 0; i < object.taskIds.length; ++i)
                                if ($util.Long)
                                    (message.taskIds[i] = $util.Long.fromValue(object.taskIds[i])).unsigned = false;
                                else if (typeof object.taskIds[i] === "string")
                                    message.taskIds[i] = parseInt(object.taskIds[i], 10);
                                else if (typeof object.taskIds[i] === "number")
                                    message.taskIds[i] = object.taskIds[i];
                                else if (typeof object.taskIds[i] === "object")
                                    message.taskIds[i] = new $util.LongBits(object.taskIds[i].low >>> 0, object.taskIds[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchDeleteTasksRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchDeleteTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchDeleteTasksRequest} message BatchDeleteTasksRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchDeleteTasksRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.taskIds = [];
                        if (message.taskIds && message.taskIds.length) {
                            object.taskIds = [];
                            for (var j = 0; j < message.taskIds.length; ++j)
                                if (typeof message.taskIds[j] === "number")
                                    object.taskIds[j] = options.longs === String ? String(message.taskIds[j]) : message.taskIds[j];
                                else
                                    object.taskIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.taskIds[j]) : options.longs === Number ? new $util.LongBits(message.taskIds[j].low >>> 0, message.taskIds[j].high >>> 0).toNumber() : message.taskIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchDeleteTasksRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchDeleteTasksRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchDeleteTasksRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchDeleteTasksRequest;
                })();
    
                v1.AsyncQueueSenderExecuteTaskRequest = (function() {
    
                    /**
                     * Properties of an AsyncQueueSenderExecuteTaskRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IAsyncQueueSenderExecuteTaskRequest
                     * @property {number|null} [taskId] AsyncQueueSenderExecuteTaskRequest taskId
                     */
    
                    /**
                     * Constructs a new AsyncQueueSenderExecuteTaskRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AsyncQueueSenderExecuteTaskRequest.
                     * @implements IAsyncQueueSenderExecuteTaskRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IAsyncQueueSenderExecuteTaskRequest=} [properties] Properties to set
                     */
                    function AsyncQueueSenderExecuteTaskRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AsyncQueueSenderExecuteTaskRequest taskId.
                     * @member {number} taskId
                     * @memberof palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest
                     * @instance
                     */
                    AsyncQueueSenderExecuteTaskRequest.prototype.taskId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new AsyncQueueSenderExecuteTaskRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest
                     * @static
                     * @param {palexy.streaming.v1.IAsyncQueueSenderExecuteTaskRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest} AsyncQueueSenderExecuteTaskRequest instance
                     */
                    AsyncQueueSenderExecuteTaskRequest.create = function create(properties) {
                        return new AsyncQueueSenderExecuteTaskRequest(properties);
                    };
    
                    /**
                     * Encodes the specified AsyncQueueSenderExecuteTaskRequest message. Does not implicitly {@link palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest
                     * @static
                     * @param {palexy.streaming.v1.IAsyncQueueSenderExecuteTaskRequest} message AsyncQueueSenderExecuteTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AsyncQueueSenderExecuteTaskRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.taskId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AsyncQueueSenderExecuteTaskRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest
                     * @static
                     * @param {palexy.streaming.v1.IAsyncQueueSenderExecuteTaskRequest} message AsyncQueueSenderExecuteTaskRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AsyncQueueSenderExecuteTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AsyncQueueSenderExecuteTaskRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest} AsyncQueueSenderExecuteTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AsyncQueueSenderExecuteTaskRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.taskId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AsyncQueueSenderExecuteTaskRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest} AsyncQueueSenderExecuteTaskRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AsyncQueueSenderExecuteTaskRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AsyncQueueSenderExecuteTaskRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AsyncQueueSenderExecuteTaskRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            if (!$util.isInteger(message.taskId) && !(message.taskId && $util.isInteger(message.taskId.low) && $util.isInteger(message.taskId.high)))
                                return "taskId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an AsyncQueueSenderExecuteTaskRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest} AsyncQueueSenderExecuteTaskRequest
                     */
                    AsyncQueueSenderExecuteTaskRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest();
                        if (object.taskId != null)
                            if ($util.Long)
                                (message.taskId = $util.Long.fromValue(object.taskId)).unsigned = false;
                            else if (typeof object.taskId === "string")
                                message.taskId = parseInt(object.taskId, 10);
                            else if (typeof object.taskId === "number")
                                message.taskId = object.taskId;
                            else if (typeof object.taskId === "object")
                                message.taskId = new $util.LongBits(object.taskId.low >>> 0, object.taskId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AsyncQueueSenderExecuteTaskRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest
                     * @static
                     * @param {palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest} message AsyncQueueSenderExecuteTaskRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AsyncQueueSenderExecuteTaskRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.taskId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.taskId = options.longs === String ? "0" : 0;
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            if (typeof message.taskId === "number")
                                object.taskId = options.longs === String ? String(message.taskId) : message.taskId;
                            else
                                object.taskId = options.longs === String ? $util.Long.prototype.toString.call(message.taskId) : options.longs === Number ? new $util.LongBits(message.taskId.low >>> 0, message.taskId.high >>> 0).toNumber() : message.taskId;
                        return object;
                    };
    
                    /**
                     * Converts this AsyncQueueSenderExecuteTaskRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AsyncQueueSenderExecuteTaskRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AsyncQueueSenderExecuteTaskRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AsyncQueueSenderExecuteTaskRequest;
                })();
    
                v1.ExportReportConfigService = (function() {
    
                    /**
                     * Constructs a new ExportReportConfigService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ExportReportConfigService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ExportReportConfigService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (ExportReportConfigService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ExportReportConfigService;
    
                    /**
                     * Creates new ExportReportConfigService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ExportReportConfigService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ExportReportConfigService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ExportReportConfigService#createExportReportConfig}.
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @typedef CreateExportReportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ExportReportConfig} [response] ExportReportConfig
                     */
    
                    /**
                     * Calls CreateExportReportConfig.
                     * @function createExportReportConfig
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IExportReportConfig} request ExportReportConfig message or plain object
                     * @param {palexy.streaming.v1.ExportReportConfigService.CreateExportReportConfigCallback} callback Node-style callback called with the error, if any, and ExportReportConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExportReportConfigService.prototype.createExportReportConfig = function createExportReportConfig(request, callback) {
                        return this.rpcCall(createExportReportConfig, $root.palexy.streaming.v1.ExportReportConfig, $root.palexy.streaming.v1.ExportReportConfig, request, callback);
                    }, "name", { value: "CreateExportReportConfig" });
    
                    /**
                     * Calls CreateExportReportConfig.
                     * @function createExportReportConfig
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IExportReportConfig} request ExportReportConfig message or plain object
                     * @returns {Promise<palexy.streaming.v1.ExportReportConfig>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ExportReportConfigService#updateExportReportConfig}.
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @typedef UpdateExportReportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ExportReportConfig} [response] ExportReportConfig
                     */
    
                    /**
                     * Calls UpdateExportReportConfig.
                     * @function updateExportReportConfig
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateExportReportConfigRequest} request UpdateExportReportConfigRequest message or plain object
                     * @param {palexy.streaming.v1.ExportReportConfigService.UpdateExportReportConfigCallback} callback Node-style callback called with the error, if any, and ExportReportConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExportReportConfigService.prototype.updateExportReportConfig = function updateExportReportConfig(request, callback) {
                        return this.rpcCall(updateExportReportConfig, $root.palexy.streaming.v1.UpdateExportReportConfigRequest, $root.palexy.streaming.v1.ExportReportConfig, request, callback);
                    }, "name", { value: "UpdateExportReportConfig" });
    
                    /**
                     * Calls UpdateExportReportConfig.
                     * @function updateExportReportConfig
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateExportReportConfigRequest} request UpdateExportReportConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ExportReportConfig>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ExportReportConfigService#deleteExportReportConfig}.
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @typedef DeleteExportReportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteExportReportConfig.
                     * @function deleteExportReportConfig
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteExportReportConfigRequest} request DeleteExportReportConfigRequest message or plain object
                     * @param {palexy.streaming.v1.ExportReportConfigService.DeleteExportReportConfigCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExportReportConfigService.prototype.deleteExportReportConfig = function deleteExportReportConfig(request, callback) {
                        return this.rpcCall(deleteExportReportConfig, $root.palexy.streaming.v1.DeleteExportReportConfigRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteExportReportConfig" });
    
                    /**
                     * Calls DeleteExportReportConfig.
                     * @function deleteExportReportConfig
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteExportReportConfigRequest} request DeleteExportReportConfigRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ExportReportConfigService#listExportReportConfig}.
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @typedef ListExportReportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListExportReportConfigResponse} [response] ListExportReportConfigResponse
                     */
    
                    /**
                     * Calls ListExportReportConfig.
                     * @function listExportReportConfig
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IListExportReportConfigRequest} request ListExportReportConfigRequest message or plain object
                     * @param {palexy.streaming.v1.ExportReportConfigService.ListExportReportConfigCallback} callback Node-style callback called with the error, if any, and ListExportReportConfigResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExportReportConfigService.prototype.listExportReportConfig = function listExportReportConfig(request, callback) {
                        return this.rpcCall(listExportReportConfig, $root.palexy.streaming.v1.ListExportReportConfigRequest, $root.palexy.streaming.v1.ListExportReportConfigResponse, request, callback);
                    }, "name", { value: "ListExportReportConfig" });
    
                    /**
                     * Calls ListExportReportConfig.
                     * @function listExportReportConfig
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IListExportReportConfigRequest} request ListExportReportConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListExportReportConfigResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ExportReportConfigService#getExportReportConfig}.
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @typedef GetExportReportConfigCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ExportReportConfig} [response] ExportReportConfig
                     */
    
                    /**
                     * Calls GetExportReportConfig.
                     * @function getExportReportConfig
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IGetExportReportConfigRequest} request GetExportReportConfigRequest message or plain object
                     * @param {palexy.streaming.v1.ExportReportConfigService.GetExportReportConfigCallback} callback Node-style callback called with the error, if any, and ExportReportConfig
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ExportReportConfigService.prototype.getExportReportConfig = function getExportReportConfig(request, callback) {
                        return this.rpcCall(getExportReportConfig, $root.palexy.streaming.v1.GetExportReportConfigRequest, $root.palexy.streaming.v1.ExportReportConfig, request, callback);
                    }, "name", { value: "GetExportReportConfig" });
    
                    /**
                     * Calls GetExportReportConfig.
                     * @function getExportReportConfig
                     * @memberof palexy.streaming.v1.ExportReportConfigService
                     * @instance
                     * @param {palexy.streaming.v1.IGetExportReportConfigRequest} request GetExportReportConfigRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ExportReportConfig>} Promise
                     * @variation 2
                     */
    
                    return ExportReportConfigService;
                })();
    
                v1.UpdateExportReportConfigRequest = (function() {
    
                    /**
                     * Properties of an UpdateExportReportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateExportReportConfigRequest
                     * @property {palexy.streaming.v1.IExportReportConfig|null} [config] UpdateExportReportConfigRequest config
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateExportReportConfigRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateExportReportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateExportReportConfigRequest.
                     * @implements IUpdateExportReportConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateExportReportConfigRequest=} [properties] Properties to set
                     */
                    function UpdateExportReportConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateExportReportConfigRequest config.
                     * @member {palexy.streaming.v1.IExportReportConfig|null|undefined} config
                     * @memberof palexy.streaming.v1.UpdateExportReportConfigRequest
                     * @instance
                     */
                    UpdateExportReportConfigRequest.prototype.config = null;
    
                    /**
                     * UpdateExportReportConfigRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateExportReportConfigRequest
                     * @instance
                     */
                    UpdateExportReportConfigRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateExportReportConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateExportReportConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateExportReportConfigRequest} UpdateExportReportConfigRequest instance
                     */
                    UpdateExportReportConfigRequest.create = function create(properties) {
                        return new UpdateExportReportConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateExportReportConfigRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateExportReportConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateExportReportConfigRequest} message UpdateExportReportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateExportReportConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                            $root.palexy.streaming.v1.ExportReportConfig.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateExportReportConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateExportReportConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateExportReportConfigRequest} message UpdateExportReportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateExportReportConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateExportReportConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateExportReportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateExportReportConfigRequest} UpdateExportReportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateExportReportConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateExportReportConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.config = $root.palexy.streaming.v1.ExportReportConfig.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateExportReportConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateExportReportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateExportReportConfigRequest} UpdateExportReportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateExportReportConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateExportReportConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateExportReportConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateExportReportConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.config != null && message.hasOwnProperty("config")) {
                            var error = $root.palexy.streaming.v1.ExportReportConfig.verify(message.config);
                            if (error)
                                return "config." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateExportReportConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateExportReportConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateExportReportConfigRequest} UpdateExportReportConfigRequest
                     */
                    UpdateExportReportConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateExportReportConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateExportReportConfigRequest();
                        if (object.config != null) {
                            if (typeof object.config !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateExportReportConfigRequest.config: object expected");
                            message.config = $root.palexy.streaming.v1.ExportReportConfig.fromObject(object.config);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateExportReportConfigRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateExportReportConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateExportReportConfigRequest} message UpdateExportReportConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateExportReportConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.config = null;
                            object.updateMask = null;
                        }
                        if (message.config != null && message.hasOwnProperty("config"))
                            object.config = $root.palexy.streaming.v1.ExportReportConfig.toObject(message.config, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateExportReportConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateExportReportConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateExportReportConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateExportReportConfigRequest;
                })();
    
                v1.DeleteExportReportConfigRequest = (function() {
    
                    /**
                     * Properties of a DeleteExportReportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteExportReportConfigRequest
                     * @property {number|null} [configId] DeleteExportReportConfigRequest configId
                     */
    
                    /**
                     * Constructs a new DeleteExportReportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteExportReportConfigRequest.
                     * @implements IDeleteExportReportConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteExportReportConfigRequest=} [properties] Properties to set
                     */
                    function DeleteExportReportConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteExportReportConfigRequest configId.
                     * @member {number} configId
                     * @memberof palexy.streaming.v1.DeleteExportReportConfigRequest
                     * @instance
                     */
                    DeleteExportReportConfigRequest.prototype.configId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteExportReportConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteExportReportConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteExportReportConfigRequest} DeleteExportReportConfigRequest instance
                     */
                    DeleteExportReportConfigRequest.create = function create(properties) {
                        return new DeleteExportReportConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteExportReportConfigRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteExportReportConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteExportReportConfigRequest} message DeleteExportReportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteExportReportConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.configId != null && Object.hasOwnProperty.call(message, "configId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.configId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteExportReportConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteExportReportConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteExportReportConfigRequest} message DeleteExportReportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteExportReportConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteExportReportConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteExportReportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteExportReportConfigRequest} DeleteExportReportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteExportReportConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteExportReportConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.configId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteExportReportConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteExportReportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteExportReportConfigRequest} DeleteExportReportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteExportReportConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteExportReportConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteExportReportConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteExportReportConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.configId != null && message.hasOwnProperty("configId"))
                            if (!$util.isInteger(message.configId) && !(message.configId && $util.isInteger(message.configId.low) && $util.isInteger(message.configId.high)))
                                return "configId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteExportReportConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteExportReportConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteExportReportConfigRequest} DeleteExportReportConfigRequest
                     */
                    DeleteExportReportConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteExportReportConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteExportReportConfigRequest();
                        if (object.configId != null)
                            if ($util.Long)
                                (message.configId = $util.Long.fromValue(object.configId)).unsigned = false;
                            else if (typeof object.configId === "string")
                                message.configId = parseInt(object.configId, 10);
                            else if (typeof object.configId === "number")
                                message.configId = object.configId;
                            else if (typeof object.configId === "object")
                                message.configId = new $util.LongBits(object.configId.low >>> 0, object.configId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteExportReportConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteExportReportConfigRequest} message DeleteExportReportConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteExportReportConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.configId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.configId = options.longs === String ? "0" : 0;
                        if (message.configId != null && message.hasOwnProperty("configId"))
                            if (typeof message.configId === "number")
                                object.configId = options.longs === String ? String(message.configId) : message.configId;
                            else
                                object.configId = options.longs === String ? $util.Long.prototype.toString.call(message.configId) : options.longs === Number ? new $util.LongBits(message.configId.low >>> 0, message.configId.high >>> 0).toNumber() : message.configId;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteExportReportConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteExportReportConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteExportReportConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteExportReportConfigRequest;
                })();
    
                v1.ListExportReportConfigRequest = (function() {
    
                    /**
                     * Properties of a ListExportReportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListExportReportConfigRequest
                     * @property {number|null} [companyId] ListExportReportConfigRequest companyId
                     * @property {number|null} [pageSize] ListExportReportConfigRequest pageSize
                     * @property {string|null} [pageToken] ListExportReportConfigRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListExportReportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListExportReportConfigRequest.
                     * @implements IListExportReportConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListExportReportConfigRequest=} [properties] Properties to set
                     */
                    function ListExportReportConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListExportReportConfigRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @instance
                     */
                    ListExportReportConfigRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListExportReportConfigRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @instance
                     */
                    ListExportReportConfigRequest.prototype.pageSize = 0;
    
                    /**
                     * ListExportReportConfigRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @instance
                     */
                    ListExportReportConfigRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListExportReportConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IListExportReportConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListExportReportConfigRequest} ListExportReportConfigRequest instance
                     */
                    ListExportReportConfigRequest.create = function create(properties) {
                        return new ListExportReportConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListExportReportConfigRequest message. Does not implicitly {@link palexy.streaming.v1.ListExportReportConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IListExportReportConfigRequest} message ListExportReportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExportReportConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListExportReportConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListExportReportConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IListExportReportConfigRequest} message ListExportReportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExportReportConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListExportReportConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListExportReportConfigRequest} ListExportReportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExportReportConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListExportReportConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.pageSize = reader.int32();
                                break;
                            case 3:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListExportReportConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListExportReportConfigRequest} ListExportReportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExportReportConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListExportReportConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListExportReportConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListExportReportConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListExportReportConfigRequest} ListExportReportConfigRequest
                     */
                    ListExportReportConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListExportReportConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListExportReportConfigRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListExportReportConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.ListExportReportConfigRequest} message ListExportReportConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListExportReportConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListExportReportConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListExportReportConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListExportReportConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListExportReportConfigRequest;
                })();
    
                v1.ListExportReportConfigResponse = (function() {
    
                    /**
                     * Properties of a ListExportReportConfigResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListExportReportConfigResponse
                     * @property {Array.<palexy.streaming.v1.IExportReportConfig>|null} [configs] ListExportReportConfigResponse configs
                     * @property {string|null} [nextPageToken] ListExportReportConfigResponse nextPageToken
                     * @property {number|null} [totalElements] ListExportReportConfigResponse totalElements
                     */
    
                    /**
                     * Constructs a new ListExportReportConfigResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListExportReportConfigResponse.
                     * @implements IListExportReportConfigResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListExportReportConfigResponse=} [properties] Properties to set
                     */
                    function ListExportReportConfigResponse(properties) {
                        this.configs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListExportReportConfigResponse configs.
                     * @member {Array.<palexy.streaming.v1.IExportReportConfig>} configs
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @instance
                     */
                    ListExportReportConfigResponse.prototype.configs = $util.emptyArray;
    
                    /**
                     * ListExportReportConfigResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @instance
                     */
                    ListExportReportConfigResponse.prototype.nextPageToken = "";
    
                    /**
                     * ListExportReportConfigResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @instance
                     */
                    ListExportReportConfigResponse.prototype.totalElements = 0;
    
                    /**
                     * Creates a new ListExportReportConfigResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.IListExportReportConfigResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListExportReportConfigResponse} ListExportReportConfigResponse instance
                     */
                    ListExportReportConfigResponse.create = function create(properties) {
                        return new ListExportReportConfigResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListExportReportConfigResponse message. Does not implicitly {@link palexy.streaming.v1.ListExportReportConfigResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.IListExportReportConfigResponse} message ListExportReportConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExportReportConfigResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.configs != null && message.configs.length)
                            for (var i = 0; i < message.configs.length; ++i)
                                $root.palexy.streaming.v1.ExportReportConfig.encode(message.configs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListExportReportConfigResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListExportReportConfigResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.IListExportReportConfigResponse} message ListExportReportConfigResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListExportReportConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListExportReportConfigResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListExportReportConfigResponse} ListExportReportConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExportReportConfigResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListExportReportConfigResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.configs && message.configs.length))
                                    message.configs = [];
                                message.configs.push($root.palexy.streaming.v1.ExportReportConfig.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            case 3:
                                message.totalElements = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListExportReportConfigResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListExportReportConfigResponse} ListExportReportConfigResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListExportReportConfigResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListExportReportConfigResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListExportReportConfigResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.configs != null && message.hasOwnProperty("configs")) {
                            if (!Array.isArray(message.configs))
                                return "configs: array expected";
                            for (var i = 0; i < message.configs.length; ++i) {
                                var error = $root.palexy.streaming.v1.ExportReportConfig.verify(message.configs[i]);
                                if (error)
                                    return "configs." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListExportReportConfigResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListExportReportConfigResponse} ListExportReportConfigResponse
                     */
                    ListExportReportConfigResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListExportReportConfigResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListExportReportConfigResponse();
                        if (object.configs) {
                            if (!Array.isArray(object.configs))
                                throw TypeError(".palexy.streaming.v1.ListExportReportConfigResponse.configs: array expected");
                            message.configs = [];
                            for (var i = 0; i < object.configs.length; ++i) {
                                if (typeof object.configs[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListExportReportConfigResponse.configs: object expected");
                                message.configs[i] = $root.palexy.streaming.v1.ExportReportConfig.fromObject(object.configs[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListExportReportConfigResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @static
                     * @param {palexy.streaming.v1.ListExportReportConfigResponse} message ListExportReportConfigResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListExportReportConfigResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.configs = [];
                        if (options.defaults) {
                            object.nextPageToken = "";
                            object.totalElements = 0;
                        }
                        if (message.configs && message.configs.length) {
                            object.configs = [];
                            for (var j = 0; j < message.configs.length; ++j)
                                object.configs[j] = $root.palexy.streaming.v1.ExportReportConfig.toObject(message.configs[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        return object;
                    };
    
                    /**
                     * Converts this ListExportReportConfigResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListExportReportConfigResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListExportReportConfigResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListExportReportConfigResponse;
                })();
    
                v1.GetExportReportConfigRequest = (function() {
    
                    /**
                     * Properties of a GetExportReportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetExportReportConfigRequest
                     * @property {number|null} [configId] GetExportReportConfigRequest configId
                     */
    
                    /**
                     * Constructs a new GetExportReportConfigRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetExportReportConfigRequest.
                     * @implements IGetExportReportConfigRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetExportReportConfigRequest=} [properties] Properties to set
                     */
                    function GetExportReportConfigRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetExportReportConfigRequest configId.
                     * @member {number} configId
                     * @memberof palexy.streaming.v1.GetExportReportConfigRequest
                     * @instance
                     */
                    GetExportReportConfigRequest.prototype.configId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetExportReportConfigRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetExportReportConfigRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetExportReportConfigRequest} GetExportReportConfigRequest instance
                     */
                    GetExportReportConfigRequest.create = function create(properties) {
                        return new GetExportReportConfigRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetExportReportConfigRequest message. Does not implicitly {@link palexy.streaming.v1.GetExportReportConfigRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetExportReportConfigRequest} message GetExportReportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetExportReportConfigRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.configId != null && Object.hasOwnProperty.call(message, "configId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.configId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetExportReportConfigRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetExportReportConfigRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetExportReportConfigRequest} message GetExportReportConfigRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetExportReportConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetExportReportConfigRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetExportReportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetExportReportConfigRequest} GetExportReportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetExportReportConfigRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetExportReportConfigRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.configId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetExportReportConfigRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetExportReportConfigRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetExportReportConfigRequest} GetExportReportConfigRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetExportReportConfigRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetExportReportConfigRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetExportReportConfigRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetExportReportConfigRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.configId != null && message.hasOwnProperty("configId"))
                            if (!$util.isInteger(message.configId) && !(message.configId && $util.isInteger(message.configId.low) && $util.isInteger(message.configId.high)))
                                return "configId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetExportReportConfigRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetExportReportConfigRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetExportReportConfigRequest} GetExportReportConfigRequest
                     */
                    GetExportReportConfigRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetExportReportConfigRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetExportReportConfigRequest();
                        if (object.configId != null)
                            if ($util.Long)
                                (message.configId = $util.Long.fromValue(object.configId)).unsigned = false;
                            else if (typeof object.configId === "string")
                                message.configId = parseInt(object.configId, 10);
                            else if (typeof object.configId === "number")
                                message.configId = object.configId;
                            else if (typeof object.configId === "object")
                                message.configId = new $util.LongBits(object.configId.low >>> 0, object.configId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetExportReportConfigRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetExportReportConfigRequest
                     * @static
                     * @param {palexy.streaming.v1.GetExportReportConfigRequest} message GetExportReportConfigRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetExportReportConfigRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.configId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.configId = options.longs === String ? "0" : 0;
                        if (message.configId != null && message.hasOwnProperty("configId"))
                            if (typeof message.configId === "number")
                                object.configId = options.longs === String ? String(message.configId) : message.configId;
                            else
                                object.configId = options.longs === String ? $util.Long.prototype.toString.call(message.configId) : options.longs === Number ? new $util.LongBits(message.configId.low >>> 0, message.configId.high >>> 0).toNumber() : message.configId;
                        return object;
                    };
    
                    /**
                     * Converts this GetExportReportConfigRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetExportReportConfigRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetExportReportConfigRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetExportReportConfigRequest;
                })();
    
                v1.ExportReportConfig = (function() {
    
                    /**
                     * Properties of an ExportReportConfig.
                     * @memberof palexy.streaming.v1
                     * @interface IExportReportConfig
                     * @property {number|null} [id] ExportReportConfig id
                     * @property {number|null} [companyId] ExportReportConfig companyId
                     * @property {string|null} [reportName] ExportReportConfig reportName
                     * @property {palexy.streaming.v1.IGetReportRequest|null} [reportRequest] ExportReportConfig reportRequest
                     * @property {palexy.streaming.v1.ExportReportConfig.FileFormat|null} [format] ExportReportConfig format
                     * @property {palexy.streaming.v1.ExportReportConfig.StorageProvider|null} [storageProvider] ExportReportConfig storageProvider
                     * @property {number|null} [createTimeMs] ExportReportConfig createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] ExportReportConfig lastUpdateTimeMs
                     * @property {palexy.streaming.v1.ExportReportConfig.ISftpConfig|null} [sftpConfig] ExportReportConfig sftpConfig
                     */
    
                    /**
                     * Constructs a new ExportReportConfig.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ExportReportConfig.
                     * @implements IExportReportConfig
                     * @constructor
                     * @param {palexy.streaming.v1.IExportReportConfig=} [properties] Properties to set
                     */
                    function ExportReportConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExportReportConfig id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @instance
                     */
                    ExportReportConfig.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExportReportConfig companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @instance
                     */
                    ExportReportConfig.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExportReportConfig reportName.
                     * @member {string} reportName
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @instance
                     */
                    ExportReportConfig.prototype.reportName = "";
    
                    /**
                     * ExportReportConfig reportRequest.
                     * @member {palexy.streaming.v1.IGetReportRequest|null|undefined} reportRequest
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @instance
                     */
                    ExportReportConfig.prototype.reportRequest = null;
    
                    /**
                     * ExportReportConfig format.
                     * @member {palexy.streaming.v1.ExportReportConfig.FileFormat} format
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @instance
                     */
                    ExportReportConfig.prototype.format = 0;
    
                    /**
                     * ExportReportConfig storageProvider.
                     * @member {palexy.streaming.v1.ExportReportConfig.StorageProvider} storageProvider
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @instance
                     */
                    ExportReportConfig.prototype.storageProvider = 0;
    
                    /**
                     * ExportReportConfig createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @instance
                     */
                    ExportReportConfig.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExportReportConfig lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @instance
                     */
                    ExportReportConfig.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ExportReportConfig sftpConfig.
                     * @member {palexy.streaming.v1.ExportReportConfig.ISftpConfig|null|undefined} sftpConfig
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @instance
                     */
                    ExportReportConfig.prototype.sftpConfig = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * ExportReportConfig storageConfig.
                     * @member {"sftpConfig"|undefined} storageConfig
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @instance
                     */
                    Object.defineProperty(ExportReportConfig.prototype, "storageConfig", {
                        get: $util.oneOfGetter($oneOfFields = ["sftpConfig"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new ExportReportConfig instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @static
                     * @param {palexy.streaming.v1.IExportReportConfig=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ExportReportConfig} ExportReportConfig instance
                     */
                    ExportReportConfig.create = function create(properties) {
                        return new ExportReportConfig(properties);
                    };
    
                    /**
                     * Encodes the specified ExportReportConfig message. Does not implicitly {@link palexy.streaming.v1.ExportReportConfig.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @static
                     * @param {palexy.streaming.v1.IExportReportConfig} message ExportReportConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportReportConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.reportName != null && Object.hasOwnProperty.call(message, "reportName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.reportName);
                        if (message.reportRequest != null && Object.hasOwnProperty.call(message, "reportRequest"))
                            $root.palexy.streaming.v1.GetReportRequest.encode(message.reportRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.format != null && Object.hasOwnProperty.call(message, "format"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.format);
                        if (message.storageProvider != null && Object.hasOwnProperty.call(message, "storageProvider"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.storageProvider);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.lastUpdateTimeMs);
                        if (message.sftpConfig != null && Object.hasOwnProperty.call(message, "sftpConfig"))
                            $root.palexy.streaming.v1.ExportReportConfig.SftpConfig.encode(message.sftpConfig, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExportReportConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.ExportReportConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @static
                     * @param {palexy.streaming.v1.IExportReportConfig} message ExportReportConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExportReportConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExportReportConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ExportReportConfig} ExportReportConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportReportConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ExportReportConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.reportName = reader.string();
                                break;
                            case 4:
                                message.reportRequest = $root.palexy.streaming.v1.GetReportRequest.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.format = reader.int32();
                                break;
                            case 6:
                                message.storageProvider = reader.int32();
                                break;
                            case 7:
                                message.createTimeMs = reader.int64();
                                break;
                            case 8:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 9:
                                message.sftpConfig = $root.palexy.streaming.v1.ExportReportConfig.SftpConfig.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExportReportConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ExportReportConfig} ExportReportConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExportReportConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExportReportConfig message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExportReportConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.reportName != null && message.hasOwnProperty("reportName"))
                            if (!$util.isString(message.reportName))
                                return "reportName: string expected";
                        if (message.reportRequest != null && message.hasOwnProperty("reportRequest")) {
                            var error = $root.palexy.streaming.v1.GetReportRequest.verify(message.reportRequest);
                            if (error)
                                return "reportRequest." + error;
                        }
                        if (message.format != null && message.hasOwnProperty("format"))
                            switch (message.format) {
                            default:
                                return "format: enum value expected";
                            case 0:
                                break;
                            }
                        if (message.storageProvider != null && message.hasOwnProperty("storageProvider"))
                            switch (message.storageProvider) {
                            default:
                                return "storageProvider: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.sftpConfig != null && message.hasOwnProperty("sftpConfig")) {
                            properties.storageConfig = 1;
                            {
                                var error = $root.palexy.streaming.v1.ExportReportConfig.SftpConfig.verify(message.sftpConfig);
                                if (error)
                                    return "sftpConfig." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ExportReportConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ExportReportConfig} ExportReportConfig
                     */
                    ExportReportConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ExportReportConfig)
                            return object;
                        var message = new $root.palexy.streaming.v1.ExportReportConfig();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.reportName != null)
                            message.reportName = String(object.reportName);
                        if (object.reportRequest != null) {
                            if (typeof object.reportRequest !== "object")
                                throw TypeError(".palexy.streaming.v1.ExportReportConfig.reportRequest: object expected");
                            message.reportRequest = $root.palexy.streaming.v1.GetReportRequest.fromObject(object.reportRequest);
                        }
                        switch (object.format) {
                        case "CSV":
                        case 0:
                            message.format = 0;
                            break;
                        }
                        switch (object.storageProvider) {
                        case "UNKNOWN":
                        case 0:
                            message.storageProvider = 0;
                            break;
                        case "SFTP":
                        case 1:
                            message.storageProvider = 1;
                            break;
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.sftpConfig != null) {
                            if (typeof object.sftpConfig !== "object")
                                throw TypeError(".palexy.streaming.v1.ExportReportConfig.sftpConfig: object expected");
                            message.sftpConfig = $root.palexy.streaming.v1.ExportReportConfig.SftpConfig.fromObject(object.sftpConfig);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExportReportConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @static
                     * @param {palexy.streaming.v1.ExportReportConfig} message ExportReportConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExportReportConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.reportName = "";
                            object.reportRequest = null;
                            object.format = options.enums === String ? "CSV" : 0;
                            object.storageProvider = options.enums === String ? "UNKNOWN" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.reportName != null && message.hasOwnProperty("reportName"))
                            object.reportName = message.reportName;
                        if (message.reportRequest != null && message.hasOwnProperty("reportRequest"))
                            object.reportRequest = $root.palexy.streaming.v1.GetReportRequest.toObject(message.reportRequest, options);
                        if (message.format != null && message.hasOwnProperty("format"))
                            object.format = options.enums === String ? $root.palexy.streaming.v1.ExportReportConfig.FileFormat[message.format] : message.format;
                        if (message.storageProvider != null && message.hasOwnProperty("storageProvider"))
                            object.storageProvider = options.enums === String ? $root.palexy.streaming.v1.ExportReportConfig.StorageProvider[message.storageProvider] : message.storageProvider;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.sftpConfig != null && message.hasOwnProperty("sftpConfig")) {
                            object.sftpConfig = $root.palexy.streaming.v1.ExportReportConfig.SftpConfig.toObject(message.sftpConfig, options);
                            if (options.oneofs)
                                object.storageConfig = "sftpConfig";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ExportReportConfig to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ExportReportConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExportReportConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * FileFormat enum.
                     * @name palexy.streaming.v1.ExportReportConfig.FileFormat
                     * @enum {number}
                     * @property {number} CSV=0 CSV value
                     */
                    ExportReportConfig.FileFormat = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "CSV"] = 0;
                        return values;
                    })();
    
                    /**
                     * StorageProvider enum.
                     * @name palexy.streaming.v1.ExportReportConfig.StorageProvider
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} SFTP=1 SFTP value
                     */
                    ExportReportConfig.StorageProvider = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "SFTP"] = 1;
                        return values;
                    })();
    
                    ExportReportConfig.SftpConfig = (function() {
    
                        /**
                         * Properties of a SftpConfig.
                         * @memberof palexy.streaming.v1.ExportReportConfig
                         * @interface ISftpConfig
                         * @property {string|null} [baseUrl] SftpConfig baseUrl
                         * @property {string|null} [username] SftpConfig username
                         * @property {string|null} [password] SftpConfig password
                         * @property {string|null} [integrationKey] SftpConfig integrationKey
                         */
    
                        /**
                         * Constructs a new SftpConfig.
                         * @memberof palexy.streaming.v1.ExportReportConfig
                         * @classdesc Represents a SftpConfig.
                         * @implements ISftpConfig
                         * @constructor
                         * @param {palexy.streaming.v1.ExportReportConfig.ISftpConfig=} [properties] Properties to set
                         */
                        function SftpConfig(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * SftpConfig baseUrl.
                         * @member {string} baseUrl
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @instance
                         */
                        SftpConfig.prototype.baseUrl = "";
    
                        /**
                         * SftpConfig username.
                         * @member {string} username
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @instance
                         */
                        SftpConfig.prototype.username = "";
    
                        /**
                         * SftpConfig password.
                         * @member {string} password
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @instance
                         */
                        SftpConfig.prototype.password = "";
    
                        /**
                         * SftpConfig integrationKey.
                         * @member {string} integrationKey
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @instance
                         */
                        SftpConfig.prototype.integrationKey = "";
    
                        /**
                         * Creates a new SftpConfig instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @static
                         * @param {palexy.streaming.v1.ExportReportConfig.ISftpConfig=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.ExportReportConfig.SftpConfig} SftpConfig instance
                         */
                        SftpConfig.create = function create(properties) {
                            return new SftpConfig(properties);
                        };
    
                        /**
                         * Encodes the specified SftpConfig message. Does not implicitly {@link palexy.streaming.v1.ExportReportConfig.SftpConfig.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @static
                         * @param {palexy.streaming.v1.ExportReportConfig.ISftpConfig} message SftpConfig message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SftpConfig.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.baseUrl != null && Object.hasOwnProperty.call(message, "baseUrl"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.baseUrl);
                            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.username);
                            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.password);
                            if (message.integrationKey != null && Object.hasOwnProperty.call(message, "integrationKey"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.integrationKey);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified SftpConfig message, length delimited. Does not implicitly {@link palexy.streaming.v1.ExportReportConfig.SftpConfig.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @static
                         * @param {palexy.streaming.v1.ExportReportConfig.ISftpConfig} message SftpConfig message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SftpConfig.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a SftpConfig message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.ExportReportConfig.SftpConfig} SftpConfig
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SftpConfig.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ExportReportConfig.SftpConfig();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.baseUrl = reader.string();
                                    break;
                                case 2:
                                    message.username = reader.string();
                                    break;
                                case 3:
                                    message.password = reader.string();
                                    break;
                                case 4:
                                    message.integrationKey = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a SftpConfig message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.ExportReportConfig.SftpConfig} SftpConfig
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SftpConfig.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a SftpConfig message.
                         * @function verify
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SftpConfig.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.baseUrl != null && message.hasOwnProperty("baseUrl"))
                                if (!$util.isString(message.baseUrl))
                                    return "baseUrl: string expected";
                            if (message.username != null && message.hasOwnProperty("username"))
                                if (!$util.isString(message.username))
                                    return "username: string expected";
                            if (message.password != null && message.hasOwnProperty("password"))
                                if (!$util.isString(message.password))
                                    return "password: string expected";
                            if (message.integrationKey != null && message.hasOwnProperty("integrationKey"))
                                if (!$util.isString(message.integrationKey))
                                    return "integrationKey: string expected";
                            return null;
                        };
    
                        /**
                         * Creates a SftpConfig message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.ExportReportConfig.SftpConfig} SftpConfig
                         */
                        SftpConfig.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.ExportReportConfig.SftpConfig)
                                return object;
                            var message = new $root.palexy.streaming.v1.ExportReportConfig.SftpConfig();
                            if (object.baseUrl != null)
                                message.baseUrl = String(object.baseUrl);
                            if (object.username != null)
                                message.username = String(object.username);
                            if (object.password != null)
                                message.password = String(object.password);
                            if (object.integrationKey != null)
                                message.integrationKey = String(object.integrationKey);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a SftpConfig message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @static
                         * @param {palexy.streaming.v1.ExportReportConfig.SftpConfig} message SftpConfig
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SftpConfig.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.baseUrl = "";
                                object.username = "";
                                object.password = "";
                                object.integrationKey = "";
                            }
                            if (message.baseUrl != null && message.hasOwnProperty("baseUrl"))
                                object.baseUrl = message.baseUrl;
                            if (message.username != null && message.hasOwnProperty("username"))
                                object.username = message.username;
                            if (message.password != null && message.hasOwnProperty("password"))
                                object.password = message.password;
                            if (message.integrationKey != null && message.hasOwnProperty("integrationKey"))
                                object.integrationKey = message.integrationKey;
                            return object;
                        };
    
                        /**
                         * Converts this SftpConfig to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.ExportReportConfig.SftpConfig
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SftpConfig.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return SftpConfig;
                    })();
    
                    return ExportReportConfig;
                })();
    
                v1.ForecastService = (function() {
    
                    /**
                     * Constructs a new ForecastService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ForecastService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ForecastService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (ForecastService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ForecastService;
    
                    /**
                     * Creates new ForecastService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.ForecastService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ForecastService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ForecastService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ForecastService#listForecastModelVersion}.
                     * @memberof palexy.streaming.v1.ForecastService
                     * @typedef ListForecastModelVersionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListForecastModelVersionResponse} [response] ListForecastModelVersionResponse
                     */
    
                    /**
                     * Calls ListForecastModelVersion.
                     * @function listForecastModelVersion
                     * @memberof palexy.streaming.v1.ForecastService
                     * @instance
                     * @param {palexy.streaming.v1.IListForecastModelVersionRequest} request ListForecastModelVersionRequest message or plain object
                     * @param {palexy.streaming.v1.ForecastService.ListForecastModelVersionCallback} callback Node-style callback called with the error, if any, and ListForecastModelVersionResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ForecastService.prototype.listForecastModelVersion = function listForecastModelVersion(request, callback) {
                        return this.rpcCall(listForecastModelVersion, $root.palexy.streaming.v1.ListForecastModelVersionRequest, $root.palexy.streaming.v1.ListForecastModelVersionResponse, request, callback);
                    }, "name", { value: "ListForecastModelVersion" });
    
                    /**
                     * Calls ListForecastModelVersion.
                     * @function listForecastModelVersion
                     * @memberof palexy.streaming.v1.ForecastService
                     * @instance
                     * @param {palexy.streaming.v1.IListForecastModelVersionRequest} request ListForecastModelVersionRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListForecastModelVersionResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ForecastService#getForecastModelVersion}.
                     * @memberof palexy.streaming.v1.ForecastService
                     * @typedef GetForecastModelVersionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ForecastModelVersion} [response] ForecastModelVersion
                     */
    
                    /**
                     * Calls GetForecastModelVersion.
                     * @function getForecastModelVersion
                     * @memberof palexy.streaming.v1.ForecastService
                     * @instance
                     * @param {palexy.streaming.v1.IGetForecastModelVersionRequest} request GetForecastModelVersionRequest message or plain object
                     * @param {palexy.streaming.v1.ForecastService.GetForecastModelVersionCallback} callback Node-style callback called with the error, if any, and ForecastModelVersion
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ForecastService.prototype.getForecastModelVersion = function getForecastModelVersion(request, callback) {
                        return this.rpcCall(getForecastModelVersion, $root.palexy.streaming.v1.GetForecastModelVersionRequest, $root.palexy.streaming.v1.ForecastModelVersion, request, callback);
                    }, "name", { value: "GetForecastModelVersion" });
    
                    /**
                     * Calls GetForecastModelVersion.
                     * @function getForecastModelVersion
                     * @memberof palexy.streaming.v1.ForecastService
                     * @instance
                     * @param {palexy.streaming.v1.IGetForecastModelVersionRequest} request GetForecastModelVersionRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ForecastModelVersion>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ForecastService#triggerCreateTasks}.
                     * @memberof palexy.streaming.v1.ForecastService
                     * @typedef TriggerCreateTasksCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls TriggerCreateTasks.
                     * @function triggerCreateTasks
                     * @memberof palexy.streaming.v1.ForecastService
                     * @instance
                     * @param {palexy.streaming.v1.ITriggerCreateTasksRequest} request TriggerCreateTasksRequest message or plain object
                     * @param {palexy.streaming.v1.ForecastService.TriggerCreateTasksCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ForecastService.prototype.triggerCreateTasks = function triggerCreateTasks(request, callback) {
                        return this.rpcCall(triggerCreateTasks, $root.palexy.streaming.v1.TriggerCreateTasksRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "TriggerCreateTasks" });
    
                    /**
                     * Calls TriggerCreateTasks.
                     * @function triggerCreateTasks
                     * @memberof palexy.streaming.v1.ForecastService
                     * @instance
                     * @param {palexy.streaming.v1.ITriggerCreateTasksRequest} request TriggerCreateTasksRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ForecastService#batchCreatePrediction}.
                     * @memberof palexy.streaming.v1.ForecastService
                     * @typedef BatchCreatePredictionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchCreatePredictionResponse} [response] BatchCreatePredictionResponse
                     */
    
                    /**
                     * Calls BatchCreatePrediction.
                     * @function batchCreatePrediction
                     * @memberof palexy.streaming.v1.ForecastService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreatePredictionRequest} request BatchCreatePredictionRequest message or plain object
                     * @param {palexy.streaming.v1.ForecastService.BatchCreatePredictionCallback} callback Node-style callback called with the error, if any, and BatchCreatePredictionResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ForecastService.prototype.batchCreatePrediction = function batchCreatePrediction(request, callback) {
                        return this.rpcCall(batchCreatePrediction, $root.palexy.streaming.v1.BatchCreatePredictionRequest, $root.palexy.streaming.v1.BatchCreatePredictionResponse, request, callback);
                    }, "name", { value: "BatchCreatePrediction" });
    
                    /**
                     * Calls BatchCreatePrediction.
                     * @function batchCreatePrediction
                     * @memberof palexy.streaming.v1.ForecastService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreatePredictionRequest} request BatchCreatePredictionRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchCreatePredictionResponse>} Promise
                     * @variation 2
                     */
    
                    return ForecastService;
                })();
    
                v1.ListForecastModelVersionRequest = (function() {
    
                    /**
                     * Properties of a ListForecastModelVersionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListForecastModelVersionRequest
                     */
    
                    /**
                     * Constructs a new ListForecastModelVersionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListForecastModelVersionRequest.
                     * @implements IListForecastModelVersionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListForecastModelVersionRequest=} [properties] Properties to set
                     */
                    function ListForecastModelVersionRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new ListForecastModelVersionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListForecastModelVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.IListForecastModelVersionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListForecastModelVersionRequest} ListForecastModelVersionRequest instance
                     */
                    ListForecastModelVersionRequest.create = function create(properties) {
                        return new ListForecastModelVersionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListForecastModelVersionRequest message. Does not implicitly {@link palexy.streaming.v1.ListForecastModelVersionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListForecastModelVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.IListForecastModelVersionRequest} message ListForecastModelVersionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListForecastModelVersionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListForecastModelVersionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListForecastModelVersionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListForecastModelVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.IListForecastModelVersionRequest} message ListForecastModelVersionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListForecastModelVersionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListForecastModelVersionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListForecastModelVersionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListForecastModelVersionRequest} ListForecastModelVersionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListForecastModelVersionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListForecastModelVersionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListForecastModelVersionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListForecastModelVersionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListForecastModelVersionRequest} ListForecastModelVersionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListForecastModelVersionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListForecastModelVersionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListForecastModelVersionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListForecastModelVersionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListForecastModelVersionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListForecastModelVersionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListForecastModelVersionRequest} ListForecastModelVersionRequest
                     */
                    ListForecastModelVersionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListForecastModelVersionRequest)
                            return object;
                        return new $root.palexy.streaming.v1.ListForecastModelVersionRequest();
                    };
    
                    /**
                     * Creates a plain object from a ListForecastModelVersionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListForecastModelVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.ListForecastModelVersionRequest} message ListForecastModelVersionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListForecastModelVersionRequest.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this ListForecastModelVersionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListForecastModelVersionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListForecastModelVersionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListForecastModelVersionRequest;
                })();
    
                v1.ListForecastModelVersionResponse = (function() {
    
                    /**
                     * Properties of a ListForecastModelVersionResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListForecastModelVersionResponse
                     * @property {Array.<palexy.streaming.v1.IForecastModelVersion>|null} [forecastModelVersions] ListForecastModelVersionResponse forecastModelVersions
                     */
    
                    /**
                     * Constructs a new ListForecastModelVersionResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListForecastModelVersionResponse.
                     * @implements IListForecastModelVersionResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListForecastModelVersionResponse=} [properties] Properties to set
                     */
                    function ListForecastModelVersionResponse(properties) {
                        this.forecastModelVersions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListForecastModelVersionResponse forecastModelVersions.
                     * @member {Array.<palexy.streaming.v1.IForecastModelVersion>} forecastModelVersions
                     * @memberof palexy.streaming.v1.ListForecastModelVersionResponse
                     * @instance
                     */
                    ListForecastModelVersionResponse.prototype.forecastModelVersions = $util.emptyArray;
    
                    /**
                     * Creates a new ListForecastModelVersionResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListForecastModelVersionResponse
                     * @static
                     * @param {palexy.streaming.v1.IListForecastModelVersionResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListForecastModelVersionResponse} ListForecastModelVersionResponse instance
                     */
                    ListForecastModelVersionResponse.create = function create(properties) {
                        return new ListForecastModelVersionResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListForecastModelVersionResponse message. Does not implicitly {@link palexy.streaming.v1.ListForecastModelVersionResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListForecastModelVersionResponse
                     * @static
                     * @param {palexy.streaming.v1.IListForecastModelVersionResponse} message ListForecastModelVersionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListForecastModelVersionResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.forecastModelVersions != null && message.forecastModelVersions.length)
                            for (var i = 0; i < message.forecastModelVersions.length; ++i)
                                $root.palexy.streaming.v1.ForecastModelVersion.encode(message.forecastModelVersions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListForecastModelVersionResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListForecastModelVersionResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListForecastModelVersionResponse
                     * @static
                     * @param {palexy.streaming.v1.IListForecastModelVersionResponse} message ListForecastModelVersionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListForecastModelVersionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListForecastModelVersionResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListForecastModelVersionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListForecastModelVersionResponse} ListForecastModelVersionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListForecastModelVersionResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListForecastModelVersionResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.forecastModelVersions && message.forecastModelVersions.length))
                                    message.forecastModelVersions = [];
                                message.forecastModelVersions.push($root.palexy.streaming.v1.ForecastModelVersion.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListForecastModelVersionResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListForecastModelVersionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListForecastModelVersionResponse} ListForecastModelVersionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListForecastModelVersionResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListForecastModelVersionResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListForecastModelVersionResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListForecastModelVersionResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.forecastModelVersions != null && message.hasOwnProperty("forecastModelVersions")) {
                            if (!Array.isArray(message.forecastModelVersions))
                                return "forecastModelVersions: array expected";
                            for (var i = 0; i < message.forecastModelVersions.length; ++i) {
                                var error = $root.palexy.streaming.v1.ForecastModelVersion.verify(message.forecastModelVersions[i]);
                                if (error)
                                    return "forecastModelVersions." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListForecastModelVersionResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListForecastModelVersionResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListForecastModelVersionResponse} ListForecastModelVersionResponse
                     */
                    ListForecastModelVersionResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListForecastModelVersionResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListForecastModelVersionResponse();
                        if (object.forecastModelVersions) {
                            if (!Array.isArray(object.forecastModelVersions))
                                throw TypeError(".palexy.streaming.v1.ListForecastModelVersionResponse.forecastModelVersions: array expected");
                            message.forecastModelVersions = [];
                            for (var i = 0; i < object.forecastModelVersions.length; ++i) {
                                if (typeof object.forecastModelVersions[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListForecastModelVersionResponse.forecastModelVersions: object expected");
                                message.forecastModelVersions[i] = $root.palexy.streaming.v1.ForecastModelVersion.fromObject(object.forecastModelVersions[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListForecastModelVersionResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListForecastModelVersionResponse
                     * @static
                     * @param {palexy.streaming.v1.ListForecastModelVersionResponse} message ListForecastModelVersionResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListForecastModelVersionResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.forecastModelVersions = [];
                        if (message.forecastModelVersions && message.forecastModelVersions.length) {
                            object.forecastModelVersions = [];
                            for (var j = 0; j < message.forecastModelVersions.length; ++j)
                                object.forecastModelVersions[j] = $root.palexy.streaming.v1.ForecastModelVersion.toObject(message.forecastModelVersions[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListForecastModelVersionResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListForecastModelVersionResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListForecastModelVersionResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListForecastModelVersionResponse;
                })();
    
                v1.GetForecastModelVersionRequest = (function() {
    
                    /**
                     * Properties of a GetForecastModelVersionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetForecastModelVersionRequest
                     * @property {number|null} [modelId] GetForecastModelVersionRequest modelId
                     * @property {number|null} [versionId] GetForecastModelVersionRequest versionId
                     */
    
                    /**
                     * Constructs a new GetForecastModelVersionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetForecastModelVersionRequest.
                     * @implements IGetForecastModelVersionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetForecastModelVersionRequest=} [properties] Properties to set
                     */
                    function GetForecastModelVersionRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetForecastModelVersionRequest modelId.
                     * @member {number} modelId
                     * @memberof palexy.streaming.v1.GetForecastModelVersionRequest
                     * @instance
                     */
                    GetForecastModelVersionRequest.prototype.modelId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetForecastModelVersionRequest versionId.
                     * @member {number} versionId
                     * @memberof palexy.streaming.v1.GetForecastModelVersionRequest
                     * @instance
                     */
                    GetForecastModelVersionRequest.prototype.versionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetForecastModelVersionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetForecastModelVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetForecastModelVersionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetForecastModelVersionRequest} GetForecastModelVersionRequest instance
                     */
                    GetForecastModelVersionRequest.create = function create(properties) {
                        return new GetForecastModelVersionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetForecastModelVersionRequest message. Does not implicitly {@link palexy.streaming.v1.GetForecastModelVersionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetForecastModelVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetForecastModelVersionRequest} message GetForecastModelVersionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetForecastModelVersionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.modelId != null && Object.hasOwnProperty.call(message, "modelId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.modelId);
                        if (message.versionId != null && Object.hasOwnProperty.call(message, "versionId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.versionId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetForecastModelVersionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetForecastModelVersionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetForecastModelVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetForecastModelVersionRequest} message GetForecastModelVersionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetForecastModelVersionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetForecastModelVersionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetForecastModelVersionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetForecastModelVersionRequest} GetForecastModelVersionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetForecastModelVersionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetForecastModelVersionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.modelId = reader.int64();
                                break;
                            case 2:
                                message.versionId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetForecastModelVersionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetForecastModelVersionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetForecastModelVersionRequest} GetForecastModelVersionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetForecastModelVersionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetForecastModelVersionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetForecastModelVersionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetForecastModelVersionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.modelId != null && message.hasOwnProperty("modelId"))
                            if (!$util.isInteger(message.modelId) && !(message.modelId && $util.isInteger(message.modelId.low) && $util.isInteger(message.modelId.high)))
                                return "modelId: integer|Long expected";
                        if (message.versionId != null && message.hasOwnProperty("versionId"))
                            if (!$util.isInteger(message.versionId) && !(message.versionId && $util.isInteger(message.versionId.low) && $util.isInteger(message.versionId.high)))
                                return "versionId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetForecastModelVersionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetForecastModelVersionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetForecastModelVersionRequest} GetForecastModelVersionRequest
                     */
                    GetForecastModelVersionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetForecastModelVersionRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetForecastModelVersionRequest();
                        if (object.modelId != null)
                            if ($util.Long)
                                (message.modelId = $util.Long.fromValue(object.modelId)).unsigned = false;
                            else if (typeof object.modelId === "string")
                                message.modelId = parseInt(object.modelId, 10);
                            else if (typeof object.modelId === "number")
                                message.modelId = object.modelId;
                            else if (typeof object.modelId === "object")
                                message.modelId = new $util.LongBits(object.modelId.low >>> 0, object.modelId.high >>> 0).toNumber();
                        if (object.versionId != null)
                            if ($util.Long)
                                (message.versionId = $util.Long.fromValue(object.versionId)).unsigned = false;
                            else if (typeof object.versionId === "string")
                                message.versionId = parseInt(object.versionId, 10);
                            else if (typeof object.versionId === "number")
                                message.versionId = object.versionId;
                            else if (typeof object.versionId === "object")
                                message.versionId = new $util.LongBits(object.versionId.low >>> 0, object.versionId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetForecastModelVersionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetForecastModelVersionRequest
                     * @static
                     * @param {palexy.streaming.v1.GetForecastModelVersionRequest} message GetForecastModelVersionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetForecastModelVersionRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.modelId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.modelId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.versionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.versionId = options.longs === String ? "0" : 0;
                        }
                        if (message.modelId != null && message.hasOwnProperty("modelId"))
                            if (typeof message.modelId === "number")
                                object.modelId = options.longs === String ? String(message.modelId) : message.modelId;
                            else
                                object.modelId = options.longs === String ? $util.Long.prototype.toString.call(message.modelId) : options.longs === Number ? new $util.LongBits(message.modelId.low >>> 0, message.modelId.high >>> 0).toNumber() : message.modelId;
                        if (message.versionId != null && message.hasOwnProperty("versionId"))
                            if (typeof message.versionId === "number")
                                object.versionId = options.longs === String ? String(message.versionId) : message.versionId;
                            else
                                object.versionId = options.longs === String ? $util.Long.prototype.toString.call(message.versionId) : options.longs === Number ? new $util.LongBits(message.versionId.low >>> 0, message.versionId.high >>> 0).toNumber() : message.versionId;
                        return object;
                    };
    
                    /**
                     * Converts this GetForecastModelVersionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetForecastModelVersionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetForecastModelVersionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetForecastModelVersionRequest;
                })();
    
                v1.TriggerCreateTasksRequest = (function() {
    
                    /**
                     * Properties of a TriggerCreateTasksRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ITriggerCreateTasksRequest
                     */
    
                    /**
                     * Constructs a new TriggerCreateTasksRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a TriggerCreateTasksRequest.
                     * @implements ITriggerCreateTasksRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ITriggerCreateTasksRequest=} [properties] Properties to set
                     */
                    function TriggerCreateTasksRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new TriggerCreateTasksRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.TriggerCreateTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.ITriggerCreateTasksRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.TriggerCreateTasksRequest} TriggerCreateTasksRequest instance
                     */
                    TriggerCreateTasksRequest.create = function create(properties) {
                        return new TriggerCreateTasksRequest(properties);
                    };
    
                    /**
                     * Encodes the specified TriggerCreateTasksRequest message. Does not implicitly {@link palexy.streaming.v1.TriggerCreateTasksRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.TriggerCreateTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.ITriggerCreateTasksRequest} message TriggerCreateTasksRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TriggerCreateTasksRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TriggerCreateTasksRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.TriggerCreateTasksRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.TriggerCreateTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.ITriggerCreateTasksRequest} message TriggerCreateTasksRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TriggerCreateTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TriggerCreateTasksRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.TriggerCreateTasksRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.TriggerCreateTasksRequest} TriggerCreateTasksRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TriggerCreateTasksRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.TriggerCreateTasksRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TriggerCreateTasksRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.TriggerCreateTasksRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.TriggerCreateTasksRequest} TriggerCreateTasksRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TriggerCreateTasksRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TriggerCreateTasksRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.TriggerCreateTasksRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TriggerCreateTasksRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a TriggerCreateTasksRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.TriggerCreateTasksRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.TriggerCreateTasksRequest} TriggerCreateTasksRequest
                     */
                    TriggerCreateTasksRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.TriggerCreateTasksRequest)
                            return object;
                        return new $root.palexy.streaming.v1.TriggerCreateTasksRequest();
                    };
    
                    /**
                     * Creates a plain object from a TriggerCreateTasksRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.TriggerCreateTasksRequest
                     * @static
                     * @param {palexy.streaming.v1.TriggerCreateTasksRequest} message TriggerCreateTasksRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TriggerCreateTasksRequest.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this TriggerCreateTasksRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.TriggerCreateTasksRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TriggerCreateTasksRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TriggerCreateTasksRequest;
                })();
    
                v1.BatchCreatePredictionRequest = (function() {
    
                    /**
                     * Properties of a BatchCreatePredictionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreatePredictionRequest
                     * @property {Array.<palexy.streaming.v1.IForecastPrediction>|null} [predictions] BatchCreatePredictionRequest predictions
                     */
    
                    /**
                     * Constructs a new BatchCreatePredictionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreatePredictionRequest.
                     * @implements IBatchCreatePredictionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreatePredictionRequest=} [properties] Properties to set
                     */
                    function BatchCreatePredictionRequest(properties) {
                        this.predictions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreatePredictionRequest predictions.
                     * @member {Array.<palexy.streaming.v1.IForecastPrediction>} predictions
                     * @memberof palexy.streaming.v1.BatchCreatePredictionRequest
                     * @instance
                     */
                    BatchCreatePredictionRequest.prototype.predictions = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreatePredictionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreatePredictionRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreatePredictionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreatePredictionRequest} BatchCreatePredictionRequest instance
                     */
                    BatchCreatePredictionRequest.create = function create(properties) {
                        return new BatchCreatePredictionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreatePredictionRequest message. Does not implicitly {@link palexy.streaming.v1.BatchCreatePredictionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreatePredictionRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreatePredictionRequest} message BatchCreatePredictionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreatePredictionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.predictions != null && message.predictions.length)
                            for (var i = 0; i < message.predictions.length; ++i)
                                $root.palexy.streaming.v1.ForecastPrediction.encode(message.predictions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreatePredictionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreatePredictionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreatePredictionRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreatePredictionRequest} message BatchCreatePredictionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreatePredictionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreatePredictionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreatePredictionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreatePredictionRequest} BatchCreatePredictionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreatePredictionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreatePredictionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.predictions && message.predictions.length))
                                    message.predictions = [];
                                message.predictions.push($root.palexy.streaming.v1.ForecastPrediction.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreatePredictionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreatePredictionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreatePredictionRequest} BatchCreatePredictionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreatePredictionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreatePredictionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreatePredictionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreatePredictionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.predictions != null && message.hasOwnProperty("predictions")) {
                            if (!Array.isArray(message.predictions))
                                return "predictions: array expected";
                            for (var i = 0; i < message.predictions.length; ++i) {
                                var error = $root.palexy.streaming.v1.ForecastPrediction.verify(message.predictions[i]);
                                if (error)
                                    return "predictions." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreatePredictionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreatePredictionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreatePredictionRequest} BatchCreatePredictionRequest
                     */
                    BatchCreatePredictionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreatePredictionRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreatePredictionRequest();
                        if (object.predictions) {
                            if (!Array.isArray(object.predictions))
                                throw TypeError(".palexy.streaming.v1.BatchCreatePredictionRequest.predictions: array expected");
                            message.predictions = [];
                            for (var i = 0; i < object.predictions.length; ++i) {
                                if (typeof object.predictions[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreatePredictionRequest.predictions: object expected");
                                message.predictions[i] = $root.palexy.streaming.v1.ForecastPrediction.fromObject(object.predictions[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreatePredictionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreatePredictionRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchCreatePredictionRequest} message BatchCreatePredictionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreatePredictionRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.predictions = [];
                        if (message.predictions && message.predictions.length) {
                            object.predictions = [];
                            for (var j = 0; j < message.predictions.length; ++j)
                                object.predictions[j] = $root.palexy.streaming.v1.ForecastPrediction.toObject(message.predictions[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreatePredictionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreatePredictionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreatePredictionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreatePredictionRequest;
                })();
    
                v1.BatchCreatePredictionResponse = (function() {
    
                    /**
                     * Properties of a BatchCreatePredictionResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreatePredictionResponse
                     * @property {Array.<palexy.streaming.v1.IForecastPrediction>|null} [predictions] BatchCreatePredictionResponse predictions
                     */
    
                    /**
                     * Constructs a new BatchCreatePredictionResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreatePredictionResponse.
                     * @implements IBatchCreatePredictionResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreatePredictionResponse=} [properties] Properties to set
                     */
                    function BatchCreatePredictionResponse(properties) {
                        this.predictions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreatePredictionResponse predictions.
                     * @member {Array.<palexy.streaming.v1.IForecastPrediction>} predictions
                     * @memberof palexy.streaming.v1.BatchCreatePredictionResponse
                     * @instance
                     */
                    BatchCreatePredictionResponse.prototype.predictions = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreatePredictionResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreatePredictionResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreatePredictionResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreatePredictionResponse} BatchCreatePredictionResponse instance
                     */
                    BatchCreatePredictionResponse.create = function create(properties) {
                        return new BatchCreatePredictionResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreatePredictionResponse message. Does not implicitly {@link palexy.streaming.v1.BatchCreatePredictionResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreatePredictionResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreatePredictionResponse} message BatchCreatePredictionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreatePredictionResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.predictions != null && message.predictions.length)
                            for (var i = 0; i < message.predictions.length; ++i)
                                $root.palexy.streaming.v1.ForecastPrediction.encode(message.predictions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreatePredictionResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreatePredictionResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreatePredictionResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreatePredictionResponse} message BatchCreatePredictionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreatePredictionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreatePredictionResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreatePredictionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreatePredictionResponse} BatchCreatePredictionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreatePredictionResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreatePredictionResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.predictions && message.predictions.length))
                                    message.predictions = [];
                                message.predictions.push($root.palexy.streaming.v1.ForecastPrediction.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreatePredictionResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreatePredictionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreatePredictionResponse} BatchCreatePredictionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreatePredictionResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreatePredictionResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreatePredictionResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreatePredictionResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.predictions != null && message.hasOwnProperty("predictions")) {
                            if (!Array.isArray(message.predictions))
                                return "predictions: array expected";
                            for (var i = 0; i < message.predictions.length; ++i) {
                                var error = $root.palexy.streaming.v1.ForecastPrediction.verify(message.predictions[i]);
                                if (error)
                                    return "predictions." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreatePredictionResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreatePredictionResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreatePredictionResponse} BatchCreatePredictionResponse
                     */
                    BatchCreatePredictionResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreatePredictionResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreatePredictionResponse();
                        if (object.predictions) {
                            if (!Array.isArray(object.predictions))
                                throw TypeError(".palexy.streaming.v1.BatchCreatePredictionResponse.predictions: array expected");
                            message.predictions = [];
                            for (var i = 0; i < object.predictions.length; ++i) {
                                if (typeof object.predictions[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreatePredictionResponse.predictions: object expected");
                                message.predictions[i] = $root.palexy.streaming.v1.ForecastPrediction.fromObject(object.predictions[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreatePredictionResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreatePredictionResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchCreatePredictionResponse} message BatchCreatePredictionResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreatePredictionResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.predictions = [];
                        if (message.predictions && message.predictions.length) {
                            object.predictions = [];
                            for (var j = 0; j < message.predictions.length; ++j)
                                object.predictions[j] = $root.palexy.streaming.v1.ForecastPrediction.toObject(message.predictions[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreatePredictionResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreatePredictionResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreatePredictionResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreatePredictionResponse;
                })();
    
                v1.ForecastModel = (function() {
    
                    /**
                     * Properties of a ForecastModel.
                     * @memberof palexy.streaming.v1
                     * @interface IForecastModel
                     * @property {number|null} [id] ForecastModel id
                     * @property {string|null} [modelName] ForecastModel modelName
                     * @property {number|null} [forecastHorizon] ForecastModel forecastHorizon
                     * @property {palexy.streaming.v1.ForecastModel.Metric|null} [forecastMetric] ForecastModel forecastMetric
                     * @property {palexy.streaming.v1.ForecastModel.Granularity|null} [granularity] ForecastModel granularity
                     */
    
                    /**
                     * Constructs a new ForecastModel.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ForecastModel.
                     * @implements IForecastModel
                     * @constructor
                     * @param {palexy.streaming.v1.IForecastModel=} [properties] Properties to set
                     */
                    function ForecastModel(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ForecastModel id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @instance
                     */
                    ForecastModel.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ForecastModel modelName.
                     * @member {string} modelName
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @instance
                     */
                    ForecastModel.prototype.modelName = "";
    
                    /**
                     * ForecastModel forecastHorizon.
                     * @member {number} forecastHorizon
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @instance
                     */
                    ForecastModel.prototype.forecastHorizon = 0;
    
                    /**
                     * ForecastModel forecastMetric.
                     * @member {palexy.streaming.v1.ForecastModel.Metric} forecastMetric
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @instance
                     */
                    ForecastModel.prototype.forecastMetric = 0;
    
                    /**
                     * ForecastModel granularity.
                     * @member {palexy.streaming.v1.ForecastModel.Granularity} granularity
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @instance
                     */
                    ForecastModel.prototype.granularity = 0;
    
                    /**
                     * Creates a new ForecastModel instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @static
                     * @param {palexy.streaming.v1.IForecastModel=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ForecastModel} ForecastModel instance
                     */
                    ForecastModel.create = function create(properties) {
                        return new ForecastModel(properties);
                    };
    
                    /**
                     * Encodes the specified ForecastModel message. Does not implicitly {@link palexy.streaming.v1.ForecastModel.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @static
                     * @param {palexy.streaming.v1.IForecastModel} message ForecastModel message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastModel.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.modelName != null && Object.hasOwnProperty.call(message, "modelName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.modelName);
                        if (message.forecastHorizon != null && Object.hasOwnProperty.call(message, "forecastHorizon"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.forecastHorizon);
                        if (message.forecastMetric != null && Object.hasOwnProperty.call(message, "forecastMetric"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.forecastMetric);
                        if (message.granularity != null && Object.hasOwnProperty.call(message, "granularity"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.granularity);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ForecastModel message, length delimited. Does not implicitly {@link palexy.streaming.v1.ForecastModel.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @static
                     * @param {palexy.streaming.v1.IForecastModel} message ForecastModel message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastModel.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ForecastModel message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ForecastModel} ForecastModel
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastModel.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ForecastModel();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.modelName = reader.string();
                                break;
                            case 3:
                                message.forecastHorizon = reader.int32();
                                break;
                            case 4:
                                message.forecastMetric = reader.int32();
                                break;
                            case 5:
                                message.granularity = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ForecastModel message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ForecastModel} ForecastModel
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastModel.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ForecastModel message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ForecastModel.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.modelName != null && message.hasOwnProperty("modelName"))
                            if (!$util.isString(message.modelName))
                                return "modelName: string expected";
                        if (message.forecastHorizon != null && message.hasOwnProperty("forecastHorizon"))
                            if (!$util.isInteger(message.forecastHorizon))
                                return "forecastHorizon: integer expected";
                        if (message.forecastMetric != null && message.hasOwnProperty("forecastMetric"))
                            switch (message.forecastMetric) {
                            default:
                                return "forecastMetric: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.granularity != null && message.hasOwnProperty("granularity"))
                            switch (message.granularity) {
                            default:
                                return "granularity: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a ForecastModel message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ForecastModel} ForecastModel
                     */
                    ForecastModel.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ForecastModel)
                            return object;
                        var message = new $root.palexy.streaming.v1.ForecastModel();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.modelName != null)
                            message.modelName = String(object.modelName);
                        if (object.forecastHorizon != null)
                            message.forecastHorizon = object.forecastHorizon | 0;
                        switch (object.forecastMetric) {
                        case "UNKNOWN_METRIC":
                        case 0:
                            message.forecastMetric = 0;
                            break;
                        case "VISITS":
                        case 1:
                            message.forecastMetric = 1;
                            break;
                        case "NET_SALES":
                        case 2:
                            message.forecastMetric = 2;
                            break;
                        }
                        switch (object.granularity) {
                        case "UNKNOWN_GRANULARITY":
                        case 0:
                            message.granularity = 0;
                            break;
                        case "HOURLY":
                        case 1:
                            message.granularity = 1;
                            break;
                        case "DAILY":
                        case 2:
                            message.granularity = 2;
                            break;
                        case "HOURLY_AND_DAILY":
                        case 3:
                            message.granularity = 3;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ForecastModel message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @static
                     * @param {palexy.streaming.v1.ForecastModel} message ForecastModel
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ForecastModel.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.modelName = "";
                            object.forecastHorizon = 0;
                            object.forecastMetric = options.enums === String ? "UNKNOWN_METRIC" : 0;
                            object.granularity = options.enums === String ? "UNKNOWN_GRANULARITY" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.modelName != null && message.hasOwnProperty("modelName"))
                            object.modelName = message.modelName;
                        if (message.forecastHorizon != null && message.hasOwnProperty("forecastHorizon"))
                            object.forecastHorizon = message.forecastHorizon;
                        if (message.forecastMetric != null && message.hasOwnProperty("forecastMetric"))
                            object.forecastMetric = options.enums === String ? $root.palexy.streaming.v1.ForecastModel.Metric[message.forecastMetric] : message.forecastMetric;
                        if (message.granularity != null && message.hasOwnProperty("granularity"))
                            object.granularity = options.enums === String ? $root.palexy.streaming.v1.ForecastModel.Granularity[message.granularity] : message.granularity;
                        return object;
                    };
    
                    /**
                     * Converts this ForecastModel to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ForecastModel
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ForecastModel.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Granularity enum.
                     * @name palexy.streaming.v1.ForecastModel.Granularity
                     * @enum {number}
                     * @property {number} UNKNOWN_GRANULARITY=0 UNKNOWN_GRANULARITY value
                     * @property {number} HOURLY=1 HOURLY value
                     * @property {number} DAILY=2 DAILY value
                     * @property {number} HOURLY_AND_DAILY=3 HOURLY_AND_DAILY value
                     */
                    ForecastModel.Granularity = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_GRANULARITY"] = 0;
                        values[valuesById[1] = "HOURLY"] = 1;
                        values[valuesById[2] = "DAILY"] = 2;
                        values[valuesById[3] = "HOURLY_AND_DAILY"] = 3;
                        return values;
                    })();
    
                    /**
                     * Metric enum.
                     * @name palexy.streaming.v1.ForecastModel.Metric
                     * @enum {number}
                     * @property {number} UNKNOWN_METRIC=0 UNKNOWN_METRIC value
                     * @property {number} VISITS=1 VISITS value
                     * @property {number} NET_SALES=2 NET_SALES value
                     */
                    ForecastModel.Metric = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_METRIC"] = 0;
                        values[valuesById[1] = "VISITS"] = 1;
                        values[valuesById[2] = "NET_SALES"] = 2;
                        return values;
                    })();
    
                    return ForecastModel;
                })();
    
                v1.ForecastModelVersion = (function() {
    
                    /**
                     * Properties of a ForecastModelVersion.
                     * @memberof palexy.streaming.v1
                     * @interface IForecastModelVersion
                     * @property {number|null} [id] ForecastModelVersion id
                     * @property {palexy.streaming.v1.IForecastModel|null} [model] ForecastModelVersion model
                     * @property {string|null} [versionName] ForecastModelVersion versionName
                     * @property {boolean|null} [isEnabledForInferring] ForecastModelVersion isEnabledForInferring
                     * @property {boolean|null} [isActive] ForecastModelVersion isActive
                     * @property {Object.<string,string>|null} [additionalProperties] ForecastModelVersion additionalProperties
                     */
    
                    /**
                     * Constructs a new ForecastModelVersion.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ForecastModelVersion.
                     * @implements IForecastModelVersion
                     * @constructor
                     * @param {palexy.streaming.v1.IForecastModelVersion=} [properties] Properties to set
                     */
                    function ForecastModelVersion(properties) {
                        this.additionalProperties = {};
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ForecastModelVersion id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @instance
                     */
                    ForecastModelVersion.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ForecastModelVersion model.
                     * @member {palexy.streaming.v1.IForecastModel|null|undefined} model
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @instance
                     */
                    ForecastModelVersion.prototype.model = null;
    
                    /**
                     * ForecastModelVersion versionName.
                     * @member {string} versionName
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @instance
                     */
                    ForecastModelVersion.prototype.versionName = "";
    
                    /**
                     * ForecastModelVersion isEnabledForInferring.
                     * @member {boolean} isEnabledForInferring
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @instance
                     */
                    ForecastModelVersion.prototype.isEnabledForInferring = false;
    
                    /**
                     * ForecastModelVersion isActive.
                     * @member {boolean} isActive
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @instance
                     */
                    ForecastModelVersion.prototype.isActive = false;
    
                    /**
                     * ForecastModelVersion additionalProperties.
                     * @member {Object.<string,string>} additionalProperties
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @instance
                     */
                    ForecastModelVersion.prototype.additionalProperties = $util.emptyObject;
    
                    /**
                     * Creates a new ForecastModelVersion instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @static
                     * @param {palexy.streaming.v1.IForecastModelVersion=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ForecastModelVersion} ForecastModelVersion instance
                     */
                    ForecastModelVersion.create = function create(properties) {
                        return new ForecastModelVersion(properties);
                    };
    
                    /**
                     * Encodes the specified ForecastModelVersion message. Does not implicitly {@link palexy.streaming.v1.ForecastModelVersion.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @static
                     * @param {palexy.streaming.v1.IForecastModelVersion} message ForecastModelVersion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastModelVersion.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                            $root.palexy.streaming.v1.ForecastModel.encode(message.model, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.versionName != null && Object.hasOwnProperty.call(message, "versionName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.versionName);
                        if (message.isEnabledForInferring != null && Object.hasOwnProperty.call(message, "isEnabledForInferring"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isEnabledForInferring);
                        if (message.isActive != null && Object.hasOwnProperty.call(message, "isActive"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isActive);
                        if (message.additionalProperties != null && Object.hasOwnProperty.call(message, "additionalProperties"))
                            for (var keys = Object.keys(message.additionalProperties), i = 0; i < keys.length; ++i)
                                writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.additionalProperties[keys[i]]).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ForecastModelVersion message, length delimited. Does not implicitly {@link palexy.streaming.v1.ForecastModelVersion.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @static
                     * @param {palexy.streaming.v1.IForecastModelVersion} message ForecastModelVersion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastModelVersion.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ForecastModelVersion message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ForecastModelVersion} ForecastModelVersion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastModelVersion.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ForecastModelVersion(), key, value;
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.model = $root.palexy.streaming.v1.ForecastModel.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.versionName = reader.string();
                                break;
                            case 4:
                                message.isEnabledForInferring = reader.bool();
                                break;
                            case 5:
                                message.isActive = reader.bool();
                                break;
                            case 6:
                                if (message.additionalProperties === $util.emptyObject)
                                    message.additionalProperties = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = "";
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.additionalProperties[key] = value;
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ForecastModelVersion message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ForecastModelVersion} ForecastModelVersion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastModelVersion.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ForecastModelVersion message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ForecastModelVersion.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.model != null && message.hasOwnProperty("model")) {
                            var error = $root.palexy.streaming.v1.ForecastModel.verify(message.model);
                            if (error)
                                return "model." + error;
                        }
                        if (message.versionName != null && message.hasOwnProperty("versionName"))
                            if (!$util.isString(message.versionName))
                                return "versionName: string expected";
                        if (message.isEnabledForInferring != null && message.hasOwnProperty("isEnabledForInferring"))
                            if (typeof message.isEnabledForInferring !== "boolean")
                                return "isEnabledForInferring: boolean expected";
                        if (message.isActive != null && message.hasOwnProperty("isActive"))
                            if (typeof message.isActive !== "boolean")
                                return "isActive: boolean expected";
                        if (message.additionalProperties != null && message.hasOwnProperty("additionalProperties")) {
                            if (!$util.isObject(message.additionalProperties))
                                return "additionalProperties: object expected";
                            var key = Object.keys(message.additionalProperties);
                            for (var i = 0; i < key.length; ++i)
                                if (!$util.isString(message.additionalProperties[key[i]]))
                                    return "additionalProperties: string{k:string} expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ForecastModelVersion message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ForecastModelVersion} ForecastModelVersion
                     */
                    ForecastModelVersion.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ForecastModelVersion)
                            return object;
                        var message = new $root.palexy.streaming.v1.ForecastModelVersion();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.model != null) {
                            if (typeof object.model !== "object")
                                throw TypeError(".palexy.streaming.v1.ForecastModelVersion.model: object expected");
                            message.model = $root.palexy.streaming.v1.ForecastModel.fromObject(object.model);
                        }
                        if (object.versionName != null)
                            message.versionName = String(object.versionName);
                        if (object.isEnabledForInferring != null)
                            message.isEnabledForInferring = Boolean(object.isEnabledForInferring);
                        if (object.isActive != null)
                            message.isActive = Boolean(object.isActive);
                        if (object.additionalProperties) {
                            if (typeof object.additionalProperties !== "object")
                                throw TypeError(".palexy.streaming.v1.ForecastModelVersion.additionalProperties: object expected");
                            message.additionalProperties = {};
                            for (var keys = Object.keys(object.additionalProperties), i = 0; i < keys.length; ++i)
                                message.additionalProperties[keys[i]] = String(object.additionalProperties[keys[i]]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ForecastModelVersion message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @static
                     * @param {palexy.streaming.v1.ForecastModelVersion} message ForecastModelVersion
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ForecastModelVersion.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.objects || options.defaults)
                            object.additionalProperties = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.model = null;
                            object.versionName = "";
                            object.isEnabledForInferring = false;
                            object.isActive = false;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.model != null && message.hasOwnProperty("model"))
                            object.model = $root.palexy.streaming.v1.ForecastModel.toObject(message.model, options);
                        if (message.versionName != null && message.hasOwnProperty("versionName"))
                            object.versionName = message.versionName;
                        if (message.isEnabledForInferring != null && message.hasOwnProperty("isEnabledForInferring"))
                            object.isEnabledForInferring = message.isEnabledForInferring;
                        if (message.isActive != null && message.hasOwnProperty("isActive"))
                            object.isActive = message.isActive;
                        var keys2;
                        if (message.additionalProperties && (keys2 = Object.keys(message.additionalProperties)).length) {
                            object.additionalProperties = {};
                            for (var j = 0; j < keys2.length; ++j)
                                object.additionalProperties[keys2[j]] = message.additionalProperties[keys2[j]];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ForecastModelVersion to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ForecastModelVersion
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ForecastModelVersion.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ForecastModelVersion;
                })();
    
                v1.ForecastPrediction = (function() {
    
                    /**
                     * Properties of a ForecastPrediction.
                     * @memberof palexy.streaming.v1
                     * @interface IForecastPrediction
                     * @property {number|null} [modelVersionId] ForecastPrediction modelVersionId
                     * @property {number|null} [historicalDataDateId] ForecastPrediction historicalDataDateId
                     * @property {Array.<palexy.streaming.v1.IForecastStoreWholeDay>|null} [storeWholeDayForecasts] ForecastPrediction storeWholeDayForecasts
                     * @property {Array.<palexy.streaming.v1.IForecastStoreHour>|null} [storeHourForecasts] ForecastPrediction storeHourForecasts
                     */
    
                    /**
                     * Constructs a new ForecastPrediction.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ForecastPrediction.
                     * @implements IForecastPrediction
                     * @constructor
                     * @param {palexy.streaming.v1.IForecastPrediction=} [properties] Properties to set
                     */
                    function ForecastPrediction(properties) {
                        this.storeWholeDayForecasts = [];
                        this.storeHourForecasts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ForecastPrediction modelVersionId.
                     * @member {number} modelVersionId
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @instance
                     */
                    ForecastPrediction.prototype.modelVersionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ForecastPrediction historicalDataDateId.
                     * @member {number} historicalDataDateId
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @instance
                     */
                    ForecastPrediction.prototype.historicalDataDateId = 0;
    
                    /**
                     * ForecastPrediction storeWholeDayForecasts.
                     * @member {Array.<palexy.streaming.v1.IForecastStoreWholeDay>} storeWholeDayForecasts
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @instance
                     */
                    ForecastPrediction.prototype.storeWholeDayForecasts = $util.emptyArray;
    
                    /**
                     * ForecastPrediction storeHourForecasts.
                     * @member {Array.<palexy.streaming.v1.IForecastStoreHour>} storeHourForecasts
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @instance
                     */
                    ForecastPrediction.prototype.storeHourForecasts = $util.emptyArray;
    
                    /**
                     * Creates a new ForecastPrediction instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @static
                     * @param {palexy.streaming.v1.IForecastPrediction=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ForecastPrediction} ForecastPrediction instance
                     */
                    ForecastPrediction.create = function create(properties) {
                        return new ForecastPrediction(properties);
                    };
    
                    /**
                     * Encodes the specified ForecastPrediction message. Does not implicitly {@link palexy.streaming.v1.ForecastPrediction.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @static
                     * @param {palexy.streaming.v1.IForecastPrediction} message ForecastPrediction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastPrediction.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.modelVersionId != null && Object.hasOwnProperty.call(message, "modelVersionId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.modelVersionId);
                        if (message.historicalDataDateId != null && Object.hasOwnProperty.call(message, "historicalDataDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.historicalDataDateId);
                        if (message.storeWholeDayForecasts != null && message.storeWholeDayForecasts.length)
                            for (var i = 0; i < message.storeWholeDayForecasts.length; ++i)
                                $root.palexy.streaming.v1.ForecastStoreWholeDay.encode(message.storeWholeDayForecasts[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.storeHourForecasts != null && message.storeHourForecasts.length)
                            for (var i = 0; i < message.storeHourForecasts.length; ++i)
                                $root.palexy.streaming.v1.ForecastStoreHour.encode(message.storeHourForecasts[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ForecastPrediction message, length delimited. Does not implicitly {@link palexy.streaming.v1.ForecastPrediction.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @static
                     * @param {palexy.streaming.v1.IForecastPrediction} message ForecastPrediction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastPrediction.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ForecastPrediction message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ForecastPrediction} ForecastPrediction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastPrediction.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ForecastPrediction();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.modelVersionId = reader.int64();
                                break;
                            case 2:
                                message.historicalDataDateId = reader.int32();
                                break;
                            case 3:
                                if (!(message.storeWholeDayForecasts && message.storeWholeDayForecasts.length))
                                    message.storeWholeDayForecasts = [];
                                message.storeWholeDayForecasts.push($root.palexy.streaming.v1.ForecastStoreWholeDay.decode(reader, reader.uint32()));
                                break;
                            case 4:
                                if (!(message.storeHourForecasts && message.storeHourForecasts.length))
                                    message.storeHourForecasts = [];
                                message.storeHourForecasts.push($root.palexy.streaming.v1.ForecastStoreHour.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ForecastPrediction message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ForecastPrediction} ForecastPrediction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastPrediction.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ForecastPrediction message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ForecastPrediction.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.modelVersionId != null && message.hasOwnProperty("modelVersionId"))
                            if (!$util.isInteger(message.modelVersionId) && !(message.modelVersionId && $util.isInteger(message.modelVersionId.low) && $util.isInteger(message.modelVersionId.high)))
                                return "modelVersionId: integer|Long expected";
                        if (message.historicalDataDateId != null && message.hasOwnProperty("historicalDataDateId"))
                            if (!$util.isInteger(message.historicalDataDateId))
                                return "historicalDataDateId: integer expected";
                        if (message.storeWholeDayForecasts != null && message.hasOwnProperty("storeWholeDayForecasts")) {
                            if (!Array.isArray(message.storeWholeDayForecasts))
                                return "storeWholeDayForecasts: array expected";
                            for (var i = 0; i < message.storeWholeDayForecasts.length; ++i) {
                                var error = $root.palexy.streaming.v1.ForecastStoreWholeDay.verify(message.storeWholeDayForecasts[i]);
                                if (error)
                                    return "storeWholeDayForecasts." + error;
                            }
                        }
                        if (message.storeHourForecasts != null && message.hasOwnProperty("storeHourForecasts")) {
                            if (!Array.isArray(message.storeHourForecasts))
                                return "storeHourForecasts: array expected";
                            for (var i = 0; i < message.storeHourForecasts.length; ++i) {
                                var error = $root.palexy.streaming.v1.ForecastStoreHour.verify(message.storeHourForecasts[i]);
                                if (error)
                                    return "storeHourForecasts." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ForecastPrediction message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ForecastPrediction} ForecastPrediction
                     */
                    ForecastPrediction.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ForecastPrediction)
                            return object;
                        var message = new $root.palexy.streaming.v1.ForecastPrediction();
                        if (object.modelVersionId != null)
                            if ($util.Long)
                                (message.modelVersionId = $util.Long.fromValue(object.modelVersionId)).unsigned = false;
                            else if (typeof object.modelVersionId === "string")
                                message.modelVersionId = parseInt(object.modelVersionId, 10);
                            else if (typeof object.modelVersionId === "number")
                                message.modelVersionId = object.modelVersionId;
                            else if (typeof object.modelVersionId === "object")
                                message.modelVersionId = new $util.LongBits(object.modelVersionId.low >>> 0, object.modelVersionId.high >>> 0).toNumber();
                        if (object.historicalDataDateId != null)
                            message.historicalDataDateId = object.historicalDataDateId | 0;
                        if (object.storeWholeDayForecasts) {
                            if (!Array.isArray(object.storeWholeDayForecasts))
                                throw TypeError(".palexy.streaming.v1.ForecastPrediction.storeWholeDayForecasts: array expected");
                            message.storeWholeDayForecasts = [];
                            for (var i = 0; i < object.storeWholeDayForecasts.length; ++i) {
                                if (typeof object.storeWholeDayForecasts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ForecastPrediction.storeWholeDayForecasts: object expected");
                                message.storeWholeDayForecasts[i] = $root.palexy.streaming.v1.ForecastStoreWholeDay.fromObject(object.storeWholeDayForecasts[i]);
                            }
                        }
                        if (object.storeHourForecasts) {
                            if (!Array.isArray(object.storeHourForecasts))
                                throw TypeError(".palexy.streaming.v1.ForecastPrediction.storeHourForecasts: array expected");
                            message.storeHourForecasts = [];
                            for (var i = 0; i < object.storeHourForecasts.length; ++i) {
                                if (typeof object.storeHourForecasts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ForecastPrediction.storeHourForecasts: object expected");
                                message.storeHourForecasts[i] = $root.palexy.streaming.v1.ForecastStoreHour.fromObject(object.storeHourForecasts[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ForecastPrediction message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @static
                     * @param {palexy.streaming.v1.ForecastPrediction} message ForecastPrediction
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ForecastPrediction.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.storeWholeDayForecasts = [];
                            object.storeHourForecasts = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.modelVersionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.modelVersionId = options.longs === String ? "0" : 0;
                            object.historicalDataDateId = 0;
                        }
                        if (message.modelVersionId != null && message.hasOwnProperty("modelVersionId"))
                            if (typeof message.modelVersionId === "number")
                                object.modelVersionId = options.longs === String ? String(message.modelVersionId) : message.modelVersionId;
                            else
                                object.modelVersionId = options.longs === String ? $util.Long.prototype.toString.call(message.modelVersionId) : options.longs === Number ? new $util.LongBits(message.modelVersionId.low >>> 0, message.modelVersionId.high >>> 0).toNumber() : message.modelVersionId;
                        if (message.historicalDataDateId != null && message.hasOwnProperty("historicalDataDateId"))
                            object.historicalDataDateId = message.historicalDataDateId;
                        if (message.storeWholeDayForecasts && message.storeWholeDayForecasts.length) {
                            object.storeWholeDayForecasts = [];
                            for (var j = 0; j < message.storeWholeDayForecasts.length; ++j)
                                object.storeWholeDayForecasts[j] = $root.palexy.streaming.v1.ForecastStoreWholeDay.toObject(message.storeWholeDayForecasts[j], options);
                        }
                        if (message.storeHourForecasts && message.storeHourForecasts.length) {
                            object.storeHourForecasts = [];
                            for (var j = 0; j < message.storeHourForecasts.length; ++j)
                                object.storeHourForecasts[j] = $root.palexy.streaming.v1.ForecastStoreHour.toObject(message.storeHourForecasts[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ForecastPrediction to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ForecastPrediction
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ForecastPrediction.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ForecastPrediction;
                })();
    
                v1.ForecastStoreWholeDay = (function() {
    
                    /**
                     * Properties of a ForecastStoreWholeDay.
                     * @memberof palexy.streaming.v1
                     * @interface IForecastStoreWholeDay
                     * @property {number|null} [storeId] ForecastStoreWholeDay storeId
                     * @property {number|null} [dateId] ForecastStoreWholeDay dateId
                     * @property {number|null} [forecastValue] ForecastStoreWholeDay forecastValue
                     */
    
                    /**
                     * Constructs a new ForecastStoreWholeDay.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ForecastStoreWholeDay.
                     * @implements IForecastStoreWholeDay
                     * @constructor
                     * @param {palexy.streaming.v1.IForecastStoreWholeDay=} [properties] Properties to set
                     */
                    function ForecastStoreWholeDay(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ForecastStoreWholeDay storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @instance
                     */
                    ForecastStoreWholeDay.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ForecastStoreWholeDay dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @instance
                     */
                    ForecastStoreWholeDay.prototype.dateId = 0;
    
                    /**
                     * ForecastStoreWholeDay forecastValue.
                     * @member {number} forecastValue
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @instance
                     */
                    ForecastStoreWholeDay.prototype.forecastValue = 0;
    
                    /**
                     * Creates a new ForecastStoreWholeDay instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @static
                     * @param {palexy.streaming.v1.IForecastStoreWholeDay=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ForecastStoreWholeDay} ForecastStoreWholeDay instance
                     */
                    ForecastStoreWholeDay.create = function create(properties) {
                        return new ForecastStoreWholeDay(properties);
                    };
    
                    /**
                     * Encodes the specified ForecastStoreWholeDay message. Does not implicitly {@link palexy.streaming.v1.ForecastStoreWholeDay.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @static
                     * @param {palexy.streaming.v1.IForecastStoreWholeDay} message ForecastStoreWholeDay message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastStoreWholeDay.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateId);
                        if (message.forecastValue != null && Object.hasOwnProperty.call(message, "forecastValue"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.forecastValue);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ForecastStoreWholeDay message, length delimited. Does not implicitly {@link palexy.streaming.v1.ForecastStoreWholeDay.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @static
                     * @param {palexy.streaming.v1.IForecastStoreWholeDay} message ForecastStoreWholeDay message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastStoreWholeDay.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ForecastStoreWholeDay message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ForecastStoreWholeDay} ForecastStoreWholeDay
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastStoreWholeDay.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ForecastStoreWholeDay();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.int32();
                                break;
                            case 3:
                                message.forecastValue = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ForecastStoreWholeDay message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ForecastStoreWholeDay} ForecastStoreWholeDay
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastStoreWholeDay.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ForecastStoreWholeDay message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ForecastStoreWholeDay.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.forecastValue != null && message.hasOwnProperty("forecastValue"))
                            if (typeof message.forecastValue !== "number")
                                return "forecastValue: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a ForecastStoreWholeDay message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ForecastStoreWholeDay} ForecastStoreWholeDay
                     */
                    ForecastStoreWholeDay.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ForecastStoreWholeDay)
                            return object;
                        var message = new $root.palexy.streaming.v1.ForecastStoreWholeDay();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.forecastValue != null)
                            message.forecastValue = Number(object.forecastValue);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ForecastStoreWholeDay message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @static
                     * @param {palexy.streaming.v1.ForecastStoreWholeDay} message ForecastStoreWholeDay
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ForecastStoreWholeDay.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                            object.forecastValue = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.forecastValue != null && message.hasOwnProperty("forecastValue"))
                            object.forecastValue = options.json && !isFinite(message.forecastValue) ? String(message.forecastValue) : message.forecastValue;
                        return object;
                    };
    
                    /**
                     * Converts this ForecastStoreWholeDay to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ForecastStoreWholeDay
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ForecastStoreWholeDay.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ForecastStoreWholeDay;
                })();
    
                v1.ForecastStoreHour = (function() {
    
                    /**
                     * Properties of a ForecastStoreHour.
                     * @memberof palexy.streaming.v1
                     * @interface IForecastStoreHour
                     * @property {number|null} [storeId] ForecastStoreHour storeId
                     * @property {number|null} [dateId] ForecastStoreHour dateId
                     * @property {number|null} [hour] ForecastStoreHour hour
                     * @property {number|null} [forecastValue] ForecastStoreHour forecastValue
                     */
    
                    /**
                     * Constructs a new ForecastStoreHour.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ForecastStoreHour.
                     * @implements IForecastStoreHour
                     * @constructor
                     * @param {palexy.streaming.v1.IForecastStoreHour=} [properties] Properties to set
                     */
                    function ForecastStoreHour(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ForecastStoreHour storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @instance
                     */
                    ForecastStoreHour.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ForecastStoreHour dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @instance
                     */
                    ForecastStoreHour.prototype.dateId = 0;
    
                    /**
                     * ForecastStoreHour hour.
                     * @member {number} hour
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @instance
                     */
                    ForecastStoreHour.prototype.hour = 0;
    
                    /**
                     * ForecastStoreHour forecastValue.
                     * @member {number} forecastValue
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @instance
                     */
                    ForecastStoreHour.prototype.forecastValue = 0;
    
                    /**
                     * Creates a new ForecastStoreHour instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @static
                     * @param {palexy.streaming.v1.IForecastStoreHour=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ForecastStoreHour} ForecastStoreHour instance
                     */
                    ForecastStoreHour.create = function create(properties) {
                        return new ForecastStoreHour(properties);
                    };
    
                    /**
                     * Encodes the specified ForecastStoreHour message. Does not implicitly {@link palexy.streaming.v1.ForecastStoreHour.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @static
                     * @param {palexy.streaming.v1.IForecastStoreHour} message ForecastStoreHour message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastStoreHour.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateId);
                        if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.hour);
                        if (message.forecastValue != null && Object.hasOwnProperty.call(message, "forecastValue"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.forecastValue);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ForecastStoreHour message, length delimited. Does not implicitly {@link palexy.streaming.v1.ForecastStoreHour.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @static
                     * @param {palexy.streaming.v1.IForecastStoreHour} message ForecastStoreHour message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ForecastStoreHour.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ForecastStoreHour message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ForecastStoreHour} ForecastStoreHour
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastStoreHour.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ForecastStoreHour();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.int32();
                                break;
                            case 3:
                                message.hour = reader.int32();
                                break;
                            case 4:
                                message.forecastValue = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ForecastStoreHour message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ForecastStoreHour} ForecastStoreHour
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ForecastStoreHour.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ForecastStoreHour message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ForecastStoreHour.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.hour != null && message.hasOwnProperty("hour"))
                            if (!$util.isInteger(message.hour))
                                return "hour: integer expected";
                        if (message.forecastValue != null && message.hasOwnProperty("forecastValue"))
                            if (typeof message.forecastValue !== "number")
                                return "forecastValue: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a ForecastStoreHour message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ForecastStoreHour} ForecastStoreHour
                     */
                    ForecastStoreHour.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ForecastStoreHour)
                            return object;
                        var message = new $root.palexy.streaming.v1.ForecastStoreHour();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.hour != null)
                            message.hour = object.hour | 0;
                        if (object.forecastValue != null)
                            message.forecastValue = Number(object.forecastValue);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ForecastStoreHour message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @static
                     * @param {palexy.streaming.v1.ForecastStoreHour} message ForecastStoreHour
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ForecastStoreHour.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                            object.hour = 0;
                            object.forecastValue = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.hour != null && message.hasOwnProperty("hour"))
                            object.hour = message.hour;
                        if (message.forecastValue != null && message.hasOwnProperty("forecastValue"))
                            object.forecastValue = options.json && !isFinite(message.forecastValue) ? String(message.forecastValue) : message.forecastValue;
                        return object;
                    };
    
                    /**
                     * Converts this ForecastStoreHour to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ForecastStoreHour
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ForecastStoreHour.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ForecastStoreHour;
                })();
    
                v1.StoreSnapshotService = (function() {
    
                    /**
                     * Constructs a new StoreSnapshotService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreSnapshotService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StoreSnapshotService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (StoreSnapshotService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StoreSnapshotService;
    
                    /**
                     * Creates new StoreSnapshotService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StoreSnapshotService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StoreSnapshotService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreSnapshotService#createStoreSnapshot}.
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @typedef CreateStoreSnapshotCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StoreSnapshot} [response] StoreSnapshot
                     */
    
                    /**
                     * Calls CreateStoreSnapshot.
                     * @function createStoreSnapshot
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStoreSnapshotRequest} request CreateStoreSnapshotRequest message or plain object
                     * @param {palexy.streaming.v1.StoreSnapshotService.CreateStoreSnapshotCallback} callback Node-style callback called with the error, if any, and StoreSnapshot
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreSnapshotService.prototype.createStoreSnapshot = function createStoreSnapshot(request, callback) {
                        return this.rpcCall(createStoreSnapshot, $root.palexy.streaming.v1.CreateStoreSnapshotRequest, $root.palexy.streaming.v1.StoreSnapshot, request, callback);
                    }, "name", { value: "CreateStoreSnapshot" });
    
                    /**
                     * Calls CreateStoreSnapshot.
                     * @function createStoreSnapshot
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStoreSnapshotRequest} request CreateStoreSnapshotRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StoreSnapshot>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreSnapshotService#batchCreateStoreSnapshot}.
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @typedef BatchCreateStoreSnapshotCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchCreateStoreSnapshotResponse} [response] BatchCreateStoreSnapshotResponse
                     */
    
                    /**
                     * Calls BatchCreateStoreSnapshot.
                     * @function batchCreateStoreSnapshot
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreateStoreSnapshotRequest} request BatchCreateStoreSnapshotRequest message or plain object
                     * @param {palexy.streaming.v1.StoreSnapshotService.BatchCreateStoreSnapshotCallback} callback Node-style callback called with the error, if any, and BatchCreateStoreSnapshotResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreSnapshotService.prototype.batchCreateStoreSnapshot = function batchCreateStoreSnapshot(request, callback) {
                        return this.rpcCall(batchCreateStoreSnapshot, $root.palexy.streaming.v1.BatchCreateStoreSnapshotRequest, $root.palexy.streaming.v1.BatchCreateStoreSnapshotResponse, request, callback);
                    }, "name", { value: "BatchCreateStoreSnapshot" });
    
                    /**
                     * Calls BatchCreateStoreSnapshot.
                     * @function batchCreateStoreSnapshot
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCreateStoreSnapshotRequest} request BatchCreateStoreSnapshotRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchCreateStoreSnapshotResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreSnapshotService#listStoreSnapshots}.
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @typedef ListStoreSnapshotsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStoreSnapshotsResponse} [response] ListStoreSnapshotsResponse
                     */
    
                    /**
                     * Calls ListStoreSnapshots.
                     * @function listStoreSnapshots
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreSnapshotsRequest} request ListStoreSnapshotsRequest message or plain object
                     * @param {palexy.streaming.v1.StoreSnapshotService.ListStoreSnapshotsCallback} callback Node-style callback called with the error, if any, and ListStoreSnapshotsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreSnapshotService.prototype.listStoreSnapshots = function listStoreSnapshots(request, callback) {
                        return this.rpcCall(listStoreSnapshots, $root.palexy.streaming.v1.ListStoreSnapshotsRequest, $root.palexy.streaming.v1.ListStoreSnapshotsResponse, request, callback);
                    }, "name", { value: "ListStoreSnapshots" });
    
                    /**
                     * Calls ListStoreSnapshots.
                     * @function listStoreSnapshots
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreSnapshotsRequest} request ListStoreSnapshotsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStoreSnapshotsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreSnapshotService#fetchStoreSnapshotsForCleaningUp}.
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @typedef FetchStoreSnapshotsForCleaningUpCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.FetchStoreSnapshotsResponse} [response] FetchStoreSnapshotsResponse
                     */
    
                    /**
                     * Calls FetchStoreSnapshotsForCleaningUp.
                     * @function fetchStoreSnapshotsForCleaningUp
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchStoreSnapshotsForCleaningUpRequest} request FetchStoreSnapshotsForCleaningUpRequest message or plain object
                     * @param {palexy.streaming.v1.StoreSnapshotService.FetchStoreSnapshotsForCleaningUpCallback} callback Node-style callback called with the error, if any, and FetchStoreSnapshotsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreSnapshotService.prototype.fetchStoreSnapshotsForCleaningUp = function fetchStoreSnapshotsForCleaningUp(request, callback) {
                        return this.rpcCall(fetchStoreSnapshotsForCleaningUp, $root.palexy.streaming.v1.FetchStoreSnapshotsForCleaningUpRequest, $root.palexy.streaming.v1.FetchStoreSnapshotsResponse, request, callback);
                    }, "name", { value: "FetchStoreSnapshotsForCleaningUp" });
    
                    /**
                     * Calls FetchStoreSnapshotsForCleaningUp.
                     * @function fetchStoreSnapshotsForCleaningUp
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @instance
                     * @param {palexy.streaming.v1.IFetchStoreSnapshotsForCleaningUpRequest} request FetchStoreSnapshotsForCleaningUpRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.FetchStoreSnapshotsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreSnapshotService#batchDeleteStoreSnapshots}.
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @typedef BatchDeleteStoreSnapshotsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls BatchDeleteStoreSnapshots.
                     * @function batchDeleteStoreSnapshots
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchDeleteStoreSnapshotRequest} request BatchDeleteStoreSnapshotRequest message or plain object
                     * @param {palexy.streaming.v1.StoreSnapshotService.BatchDeleteStoreSnapshotsCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreSnapshotService.prototype.batchDeleteStoreSnapshots = function batchDeleteStoreSnapshots(request, callback) {
                        return this.rpcCall(batchDeleteStoreSnapshots, $root.palexy.streaming.v1.BatchDeleteStoreSnapshotRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "BatchDeleteStoreSnapshots" });
    
                    /**
                     * Calls BatchDeleteStoreSnapshots.
                     * @function batchDeleteStoreSnapshots
                     * @memberof palexy.streaming.v1.StoreSnapshotService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchDeleteStoreSnapshotRequest} request BatchDeleteStoreSnapshotRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return StoreSnapshotService;
                })();
    
                v1.StoreSnapshot = (function() {
    
                    /**
                     * Properties of a StoreSnapshot.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreSnapshot
                     * @property {number|null} [id] StoreSnapshot id
                     * @property {number|null} [storeId] StoreSnapshot storeId
                     * @property {string|null} [cameraId] StoreSnapshot cameraId
                     * @property {string|null} [capturedTime] StoreSnapshot capturedTime
                     * @property {string|null} [scheduledTime] StoreSnapshot scheduledTime
                     * @property {string|null} [thumbnailUrl] StoreSnapshot thumbnailUrl
                     * @property {string|null} [originalImageUrl] StoreSnapshot originalImageUrl
                     * @property {string|null} [originalImageFile] StoreSnapshot originalImageFile
                     * @property {string|null} [thumbnailFile] StoreSnapshot thumbnailFile
                     */
    
                    /**
                     * Constructs a new StoreSnapshot.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreSnapshot.
                     * @implements IStoreSnapshot
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreSnapshot=} [properties] Properties to set
                     */
                    function StoreSnapshot(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreSnapshot id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @instance
                     */
                    StoreSnapshot.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreSnapshot storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @instance
                     */
                    StoreSnapshot.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreSnapshot cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @instance
                     */
                    StoreSnapshot.prototype.cameraId = "";
    
                    /**
                     * StoreSnapshot capturedTime.
                     * @member {string} capturedTime
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @instance
                     */
                    StoreSnapshot.prototype.capturedTime = "";
    
                    /**
                     * StoreSnapshot scheduledTime.
                     * @member {string} scheduledTime
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @instance
                     */
                    StoreSnapshot.prototype.scheduledTime = "";
    
                    /**
                     * StoreSnapshot thumbnailUrl.
                     * @member {string} thumbnailUrl
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @instance
                     */
                    StoreSnapshot.prototype.thumbnailUrl = "";
    
                    /**
                     * StoreSnapshot originalImageUrl.
                     * @member {string} originalImageUrl
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @instance
                     */
                    StoreSnapshot.prototype.originalImageUrl = "";
    
                    /**
                     * StoreSnapshot originalImageFile.
                     * @member {string} originalImageFile
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @instance
                     */
                    StoreSnapshot.prototype.originalImageFile = "";
    
                    /**
                     * StoreSnapshot thumbnailFile.
                     * @member {string} thumbnailFile
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @instance
                     */
                    StoreSnapshot.prototype.thumbnailFile = "";
    
                    /**
                     * Creates a new StoreSnapshot instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @static
                     * @param {palexy.streaming.v1.IStoreSnapshot=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreSnapshot} StoreSnapshot instance
                     */
                    StoreSnapshot.create = function create(properties) {
                        return new StoreSnapshot(properties);
                    };
    
                    /**
                     * Encodes the specified StoreSnapshot message. Does not implicitly {@link palexy.streaming.v1.StoreSnapshot.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @static
                     * @param {palexy.streaming.v1.IStoreSnapshot} message StoreSnapshot message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreSnapshot.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.cameraId);
                        if (message.capturedTime != null && Object.hasOwnProperty.call(message, "capturedTime"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.capturedTime);
                        if (message.scheduledTime != null && Object.hasOwnProperty.call(message, "scheduledTime"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.scheduledTime);
                        if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.thumbnailUrl);
                        if (message.originalImageUrl != null && Object.hasOwnProperty.call(message, "originalImageUrl"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.originalImageUrl);
                        if (message.originalImageFile != null && Object.hasOwnProperty.call(message, "originalImageFile"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.originalImageFile);
                        if (message.thumbnailFile != null && Object.hasOwnProperty.call(message, "thumbnailFile"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.thumbnailFile);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreSnapshot message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreSnapshot.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @static
                     * @param {palexy.streaming.v1.IStoreSnapshot} message StoreSnapshot message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreSnapshot.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreSnapshot message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreSnapshot} StoreSnapshot
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreSnapshot.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreSnapshot();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.cameraId = reader.string();
                                break;
                            case 4:
                                message.capturedTime = reader.string();
                                break;
                            case 5:
                                message.scheduledTime = reader.string();
                                break;
                            case 6:
                                message.thumbnailUrl = reader.string();
                                break;
                            case 7:
                                message.originalImageUrl = reader.string();
                                break;
                            case 8:
                                message.originalImageFile = reader.string();
                                break;
                            case 9:
                                message.thumbnailFile = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreSnapshot message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreSnapshot} StoreSnapshot
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreSnapshot.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreSnapshot message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreSnapshot.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.capturedTime != null && message.hasOwnProperty("capturedTime"))
                            if (!$util.isString(message.capturedTime))
                                return "capturedTime: string expected";
                        if (message.scheduledTime != null && message.hasOwnProperty("scheduledTime"))
                            if (!$util.isString(message.scheduledTime))
                                return "scheduledTime: string expected";
                        if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                            if (!$util.isString(message.thumbnailUrl))
                                return "thumbnailUrl: string expected";
                        if (message.originalImageUrl != null && message.hasOwnProperty("originalImageUrl"))
                            if (!$util.isString(message.originalImageUrl))
                                return "originalImageUrl: string expected";
                        if (message.originalImageFile != null && message.hasOwnProperty("originalImageFile"))
                            if (!$util.isString(message.originalImageFile))
                                return "originalImageFile: string expected";
                        if (message.thumbnailFile != null && message.hasOwnProperty("thumbnailFile"))
                            if (!$util.isString(message.thumbnailFile))
                                return "thumbnailFile: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a StoreSnapshot message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreSnapshot} StoreSnapshot
                     */
                    StoreSnapshot.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreSnapshot)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreSnapshot();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.capturedTime != null)
                            message.capturedTime = String(object.capturedTime);
                        if (object.scheduledTime != null)
                            message.scheduledTime = String(object.scheduledTime);
                        if (object.thumbnailUrl != null)
                            message.thumbnailUrl = String(object.thumbnailUrl);
                        if (object.originalImageUrl != null)
                            message.originalImageUrl = String(object.originalImageUrl);
                        if (object.originalImageFile != null)
                            message.originalImageFile = String(object.originalImageFile);
                        if (object.thumbnailFile != null)
                            message.thumbnailFile = String(object.thumbnailFile);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreSnapshot message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @static
                     * @param {palexy.streaming.v1.StoreSnapshot} message StoreSnapshot
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreSnapshot.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.cameraId = "";
                            object.capturedTime = "";
                            object.scheduledTime = "";
                            object.thumbnailUrl = "";
                            object.originalImageUrl = "";
                            object.originalImageFile = "";
                            object.thumbnailFile = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.capturedTime != null && message.hasOwnProperty("capturedTime"))
                            object.capturedTime = message.capturedTime;
                        if (message.scheduledTime != null && message.hasOwnProperty("scheduledTime"))
                            object.scheduledTime = message.scheduledTime;
                        if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                            object.thumbnailUrl = message.thumbnailUrl;
                        if (message.originalImageUrl != null && message.hasOwnProperty("originalImageUrl"))
                            object.originalImageUrl = message.originalImageUrl;
                        if (message.originalImageFile != null && message.hasOwnProperty("originalImageFile"))
                            object.originalImageFile = message.originalImageFile;
                        if (message.thumbnailFile != null && message.hasOwnProperty("thumbnailFile"))
                            object.thumbnailFile = message.thumbnailFile;
                        return object;
                    };
    
                    /**
                     * Converts this StoreSnapshot to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreSnapshot
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreSnapshot.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StoreSnapshot;
                })();
    
                v1.CreateStoreSnapshotRequest = (function() {
    
                    /**
                     * Properties of a CreateStoreSnapshotRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateStoreSnapshotRequest
                     * @property {number|null} [storeId] CreateStoreSnapshotRequest storeId
                     * @property {string|null} [cameraId] CreateStoreSnapshotRequest cameraId
                     * @property {string|null} [capturedTime] CreateStoreSnapshotRequest capturedTime
                     * @property {string|null} [scheduledTime] CreateStoreSnapshotRequest scheduledTime
                     * @property {palexy.streaming.v1.Artifact.IGCSFile|null} [thumbnail] CreateStoreSnapshotRequest thumbnail
                     * @property {palexy.streaming.v1.Artifact.IGCSFile|null} [originalImage] CreateStoreSnapshotRequest originalImage
                     */
    
                    /**
                     * Constructs a new CreateStoreSnapshotRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateStoreSnapshotRequest.
                     * @implements ICreateStoreSnapshotRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateStoreSnapshotRequest=} [properties] Properties to set
                     */
                    function CreateStoreSnapshotRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateStoreSnapshotRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @instance
                     */
                    CreateStoreSnapshotRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CreateStoreSnapshotRequest cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @instance
                     */
                    CreateStoreSnapshotRequest.prototype.cameraId = "";
    
                    /**
                     * CreateStoreSnapshotRequest capturedTime.
                     * @member {string} capturedTime
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @instance
                     */
                    CreateStoreSnapshotRequest.prototype.capturedTime = "";
    
                    /**
                     * CreateStoreSnapshotRequest scheduledTime.
                     * @member {string} scheduledTime
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @instance
                     */
                    CreateStoreSnapshotRequest.prototype.scheduledTime = "";
    
                    /**
                     * CreateStoreSnapshotRequest thumbnail.
                     * @member {palexy.streaming.v1.Artifact.IGCSFile|null|undefined} thumbnail
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @instance
                     */
                    CreateStoreSnapshotRequest.prototype.thumbnail = null;
    
                    /**
                     * CreateStoreSnapshotRequest originalImage.
                     * @member {palexy.streaming.v1.Artifact.IGCSFile|null|undefined} originalImage
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @instance
                     */
                    CreateStoreSnapshotRequest.prototype.originalImage = null;
    
                    /**
                     * Creates a new CreateStoreSnapshotRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreSnapshotRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateStoreSnapshotRequest} CreateStoreSnapshotRequest instance
                     */
                    CreateStoreSnapshotRequest.create = function create(properties) {
                        return new CreateStoreSnapshotRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateStoreSnapshotRequest message. Does not implicitly {@link palexy.streaming.v1.CreateStoreSnapshotRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreSnapshotRequest} message CreateStoreSnapshotRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStoreSnapshotRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.cameraId);
                        if (message.capturedTime != null && Object.hasOwnProperty.call(message, "capturedTime"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.capturedTime);
                        if (message.scheduledTime != null && Object.hasOwnProperty.call(message, "scheduledTime"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.scheduledTime);
                        if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                            $root.palexy.streaming.v1.Artifact.GCSFile.encode(message.thumbnail, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.originalImage != null && Object.hasOwnProperty.call(message, "originalImage"))
                            $root.palexy.streaming.v1.Artifact.GCSFile.encode(message.originalImage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateStoreSnapshotRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateStoreSnapshotRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStoreSnapshotRequest} message CreateStoreSnapshotRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStoreSnapshotRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateStoreSnapshotRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateStoreSnapshotRequest} CreateStoreSnapshotRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStoreSnapshotRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateStoreSnapshotRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.cameraId = reader.string();
                                break;
                            case 3:
                                message.capturedTime = reader.string();
                                break;
                            case 4:
                                message.scheduledTime = reader.string();
                                break;
                            case 5:
                                message.thumbnail = $root.palexy.streaming.v1.Artifact.GCSFile.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.originalImage = $root.palexy.streaming.v1.Artifact.GCSFile.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateStoreSnapshotRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateStoreSnapshotRequest} CreateStoreSnapshotRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStoreSnapshotRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateStoreSnapshotRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateStoreSnapshotRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.capturedTime != null && message.hasOwnProperty("capturedTime"))
                            if (!$util.isString(message.capturedTime))
                                return "capturedTime: string expected";
                        if (message.scheduledTime != null && message.hasOwnProperty("scheduledTime"))
                            if (!$util.isString(message.scheduledTime))
                                return "scheduledTime: string expected";
                        if (message.thumbnail != null && message.hasOwnProperty("thumbnail")) {
                            var error = $root.palexy.streaming.v1.Artifact.GCSFile.verify(message.thumbnail);
                            if (error)
                                return "thumbnail." + error;
                        }
                        if (message.originalImage != null && message.hasOwnProperty("originalImage")) {
                            var error = $root.palexy.streaming.v1.Artifact.GCSFile.verify(message.originalImage);
                            if (error)
                                return "originalImage." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateStoreSnapshotRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateStoreSnapshotRequest} CreateStoreSnapshotRequest
                     */
                    CreateStoreSnapshotRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateStoreSnapshotRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateStoreSnapshotRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.capturedTime != null)
                            message.capturedTime = String(object.capturedTime);
                        if (object.scheduledTime != null)
                            message.scheduledTime = String(object.scheduledTime);
                        if (object.thumbnail != null) {
                            if (typeof object.thumbnail !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateStoreSnapshotRequest.thumbnail: object expected");
                            message.thumbnail = $root.palexy.streaming.v1.Artifact.GCSFile.fromObject(object.thumbnail);
                        }
                        if (object.originalImage != null) {
                            if (typeof object.originalImage !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateStoreSnapshotRequest.originalImage: object expected");
                            message.originalImage = $root.palexy.streaming.v1.Artifact.GCSFile.fromObject(object.originalImage);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateStoreSnapshotRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateStoreSnapshotRequest} message CreateStoreSnapshotRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateStoreSnapshotRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.cameraId = "";
                            object.capturedTime = "";
                            object.scheduledTime = "";
                            object.thumbnail = null;
                            object.originalImage = null;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.capturedTime != null && message.hasOwnProperty("capturedTime"))
                            object.capturedTime = message.capturedTime;
                        if (message.scheduledTime != null && message.hasOwnProperty("scheduledTime"))
                            object.scheduledTime = message.scheduledTime;
                        if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                            object.thumbnail = $root.palexy.streaming.v1.Artifact.GCSFile.toObject(message.thumbnail, options);
                        if (message.originalImage != null && message.hasOwnProperty("originalImage"))
                            object.originalImage = $root.palexy.streaming.v1.Artifact.GCSFile.toObject(message.originalImage, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateStoreSnapshotRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateStoreSnapshotRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateStoreSnapshotRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateStoreSnapshotRequest;
                })();
    
                v1.BatchCreateStoreSnapshotRequest = (function() {
    
                    /**
                     * Properties of a BatchCreateStoreSnapshotRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreateStoreSnapshotRequest
                     * @property {Array.<palexy.streaming.v1.ICreateStoreSnapshotRequest>|null} [requests] BatchCreateStoreSnapshotRequest requests
                     */
    
                    /**
                     * Constructs a new BatchCreateStoreSnapshotRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreateStoreSnapshotRequest.
                     * @implements IBatchCreateStoreSnapshotRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreateStoreSnapshotRequest=} [properties] Properties to set
                     */
                    function BatchCreateStoreSnapshotRequest(properties) {
                        this.requests = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateStoreSnapshotRequest requests.
                     * @member {Array.<palexy.streaming.v1.ICreateStoreSnapshotRequest>} requests
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotRequest
                     * @instance
                     */
                    BatchCreateStoreSnapshotRequest.prototype.requests = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateStoreSnapshotRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreSnapshotRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreateStoreSnapshotRequest} BatchCreateStoreSnapshotRequest instance
                     */
                    BatchCreateStoreSnapshotRequest.create = function create(properties) {
                        return new BatchCreateStoreSnapshotRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateStoreSnapshotRequest message. Does not implicitly {@link palexy.streaming.v1.BatchCreateStoreSnapshotRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreSnapshotRequest} message BatchCreateStoreSnapshotRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStoreSnapshotRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.requests != null && message.requests.length)
                            for (var i = 0; i < message.requests.length; ++i)
                                $root.palexy.streaming.v1.CreateStoreSnapshotRequest.encode(message.requests[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateStoreSnapshotRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreateStoreSnapshotRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreSnapshotRequest} message BatchCreateStoreSnapshotRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStoreSnapshotRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateStoreSnapshotRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreateStoreSnapshotRequest} BatchCreateStoreSnapshotRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStoreSnapshotRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreateStoreSnapshotRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.requests && message.requests.length))
                                    message.requests = [];
                                message.requests.push($root.palexy.streaming.v1.CreateStoreSnapshotRequest.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateStoreSnapshotRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreateStoreSnapshotRequest} BatchCreateStoreSnapshotRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStoreSnapshotRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateStoreSnapshotRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateStoreSnapshotRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.requests != null && message.hasOwnProperty("requests")) {
                            if (!Array.isArray(message.requests))
                                return "requests: array expected";
                            for (var i = 0; i < message.requests.length; ++i) {
                                var error = $root.palexy.streaming.v1.CreateStoreSnapshotRequest.verify(message.requests[i]);
                                if (error)
                                    return "requests." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateStoreSnapshotRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreateStoreSnapshotRequest} BatchCreateStoreSnapshotRequest
                     */
                    BatchCreateStoreSnapshotRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreateStoreSnapshotRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreateStoreSnapshotRequest();
                        if (object.requests) {
                            if (!Array.isArray(object.requests))
                                throw TypeError(".palexy.streaming.v1.BatchCreateStoreSnapshotRequest.requests: array expected");
                            message.requests = [];
                            for (var i = 0; i < object.requests.length; ++i) {
                                if (typeof object.requests[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreateStoreSnapshotRequest.requests: object expected");
                                message.requests[i] = $root.palexy.streaming.v1.CreateStoreSnapshotRequest.fromObject(object.requests[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateStoreSnapshotRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchCreateStoreSnapshotRequest} message BatchCreateStoreSnapshotRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateStoreSnapshotRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.requests = [];
                        if (message.requests && message.requests.length) {
                            object.requests = [];
                            for (var j = 0; j < message.requests.length; ++j)
                                object.requests[j] = $root.palexy.streaming.v1.CreateStoreSnapshotRequest.toObject(message.requests[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateStoreSnapshotRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateStoreSnapshotRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateStoreSnapshotRequest;
                })();
    
                v1.BatchCreateStoreSnapshotResponse = (function() {
    
                    /**
                     * Properties of a BatchCreateStoreSnapshotResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCreateStoreSnapshotResponse
                     * @property {Array.<palexy.streaming.v1.IStoreSnapshot>|null} [storeSnapshots] BatchCreateStoreSnapshotResponse storeSnapshots
                     */
    
                    /**
                     * Constructs a new BatchCreateStoreSnapshotResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCreateStoreSnapshotResponse.
                     * @implements IBatchCreateStoreSnapshotResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCreateStoreSnapshotResponse=} [properties] Properties to set
                     */
                    function BatchCreateStoreSnapshotResponse(properties) {
                        this.storeSnapshots = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateStoreSnapshotResponse storeSnapshots.
                     * @member {Array.<palexy.streaming.v1.IStoreSnapshot>} storeSnapshots
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotResponse
                     * @instance
                     */
                    BatchCreateStoreSnapshotResponse.prototype.storeSnapshots = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCreateStoreSnapshotResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreSnapshotResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCreateStoreSnapshotResponse} BatchCreateStoreSnapshotResponse instance
                     */
                    BatchCreateStoreSnapshotResponse.create = function create(properties) {
                        return new BatchCreateStoreSnapshotResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateStoreSnapshotResponse message. Does not implicitly {@link palexy.streaming.v1.BatchCreateStoreSnapshotResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreSnapshotResponse} message BatchCreateStoreSnapshotResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStoreSnapshotResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeSnapshots != null && message.storeSnapshots.length)
                            for (var i = 0; i < message.storeSnapshots.length; ++i)
                                $root.palexy.streaming.v1.StoreSnapshot.encode(message.storeSnapshots[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateStoreSnapshotResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCreateStoreSnapshotResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchCreateStoreSnapshotResponse} message BatchCreateStoreSnapshotResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateStoreSnapshotResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateStoreSnapshotResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCreateStoreSnapshotResponse} BatchCreateStoreSnapshotResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStoreSnapshotResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCreateStoreSnapshotResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeSnapshots && message.storeSnapshots.length))
                                    message.storeSnapshots = [];
                                message.storeSnapshots.push($root.palexy.streaming.v1.StoreSnapshot.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateStoreSnapshotResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCreateStoreSnapshotResponse} BatchCreateStoreSnapshotResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateStoreSnapshotResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateStoreSnapshotResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateStoreSnapshotResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeSnapshots != null && message.hasOwnProperty("storeSnapshots")) {
                            if (!Array.isArray(message.storeSnapshots))
                                return "storeSnapshots: array expected";
                            for (var i = 0; i < message.storeSnapshots.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreSnapshot.verify(message.storeSnapshots[i]);
                                if (error)
                                    return "storeSnapshots." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateStoreSnapshotResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCreateStoreSnapshotResponse} BatchCreateStoreSnapshotResponse
                     */
                    BatchCreateStoreSnapshotResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCreateStoreSnapshotResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCreateStoreSnapshotResponse();
                        if (object.storeSnapshots) {
                            if (!Array.isArray(object.storeSnapshots))
                                throw TypeError(".palexy.streaming.v1.BatchCreateStoreSnapshotResponse.storeSnapshots: array expected");
                            message.storeSnapshots = [];
                            for (var i = 0; i < object.storeSnapshots.length; ++i) {
                                if (typeof object.storeSnapshots[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchCreateStoreSnapshotResponse.storeSnapshots: object expected");
                                message.storeSnapshots[i] = $root.palexy.streaming.v1.StoreSnapshot.fromObject(object.storeSnapshots[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateStoreSnapshotResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchCreateStoreSnapshotResponse} message BatchCreateStoreSnapshotResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateStoreSnapshotResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeSnapshots = [];
                        if (message.storeSnapshots && message.storeSnapshots.length) {
                            object.storeSnapshots = [];
                            for (var j = 0; j < message.storeSnapshots.length; ++j)
                                object.storeSnapshots[j] = $root.palexy.streaming.v1.StoreSnapshot.toObject(message.storeSnapshots[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateStoreSnapshotResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCreateStoreSnapshotResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateStoreSnapshotResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateStoreSnapshotResponse;
                })();
    
                v1.ListStoreSnapshotsRequest = (function() {
    
                    /**
                     * Properties of a ListStoreSnapshotsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreSnapshotsRequest
                     * @property {number|null} [storeId] ListStoreSnapshotsRequest storeId
                     * @property {string|null} [dateId] ListStoreSnapshotsRequest dateId
                     * @property {string|null} [timeId] ListStoreSnapshotsRequest timeId
                     * @property {string|null} [cameraId] ListStoreSnapshotsRequest cameraId
                     */
    
                    /**
                     * Constructs a new ListStoreSnapshotsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreSnapshotsRequest.
                     * @implements IListStoreSnapshotsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreSnapshotsRequest=} [properties] Properties to set
                     */
                    function ListStoreSnapshotsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreSnapshotsRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @instance
                     */
                    ListStoreSnapshotsRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListStoreSnapshotsRequest dateId.
                     * @member {string} dateId
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @instance
                     */
                    ListStoreSnapshotsRequest.prototype.dateId = "";
    
                    /**
                     * ListStoreSnapshotsRequest timeId.
                     * @member {string} timeId
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @instance
                     */
                    ListStoreSnapshotsRequest.prototype.timeId = "";
    
                    /**
                     * ListStoreSnapshotsRequest cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @instance
                     */
                    ListStoreSnapshotsRequest.prototype.cameraId = "";
    
                    /**
                     * Creates a new ListStoreSnapshotsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreSnapshotsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreSnapshotsRequest} ListStoreSnapshotsRequest instance
                     */
                    ListStoreSnapshotsRequest.create = function create(properties) {
                        return new ListStoreSnapshotsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreSnapshotsRequest message. Does not implicitly {@link palexy.streaming.v1.ListStoreSnapshotsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreSnapshotsRequest} message ListStoreSnapshotsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreSnapshotsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.timeId);
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.cameraId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreSnapshotsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreSnapshotsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreSnapshotsRequest} message ListStoreSnapshotsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreSnapshotsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreSnapshotsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreSnapshotsRequest} ListStoreSnapshotsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreSnapshotsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreSnapshotsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            case 3:
                                message.timeId = reader.string();
                                break;
                            case 4:
                                message.cameraId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreSnapshotsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreSnapshotsRequest} ListStoreSnapshotsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreSnapshotsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreSnapshotsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreSnapshotsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        if (message.timeId != null && message.hasOwnProperty("timeId"))
                            if (!$util.isString(message.timeId))
                                return "timeId: string expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreSnapshotsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreSnapshotsRequest} ListStoreSnapshotsRequest
                     */
                    ListStoreSnapshotsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreSnapshotsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreSnapshotsRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        if (object.timeId != null)
                            message.timeId = String(object.timeId);
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreSnapshotsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStoreSnapshotsRequest} message ListStoreSnapshotsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreSnapshotsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = "";
                            object.timeId = "";
                            object.cameraId = "";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.timeId != null && message.hasOwnProperty("timeId"))
                            object.timeId = message.timeId;
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreSnapshotsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreSnapshotsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreSnapshotsRequest;
                })();
    
                v1.ListStoreSnapshotsResponse = (function() {
    
                    /**
                     * Properties of a ListStoreSnapshotsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreSnapshotsResponse
                     * @property {Array.<palexy.streaming.v1.IStoreSnapshot>|null} [storeSnapshots] ListStoreSnapshotsResponse storeSnapshots
                     */
    
                    /**
                     * Constructs a new ListStoreSnapshotsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreSnapshotsResponse.
                     * @implements IListStoreSnapshotsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreSnapshotsResponse=} [properties] Properties to set
                     */
                    function ListStoreSnapshotsResponse(properties) {
                        this.storeSnapshots = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreSnapshotsResponse storeSnapshots.
                     * @member {Array.<palexy.streaming.v1.IStoreSnapshot>} storeSnapshots
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsResponse
                     * @instance
                     */
                    ListStoreSnapshotsResponse.prototype.storeSnapshots = $util.emptyArray;
    
                    /**
                     * Creates a new ListStoreSnapshotsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreSnapshotsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreSnapshotsResponse} ListStoreSnapshotsResponse instance
                     */
                    ListStoreSnapshotsResponse.create = function create(properties) {
                        return new ListStoreSnapshotsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreSnapshotsResponse message. Does not implicitly {@link palexy.streaming.v1.ListStoreSnapshotsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreSnapshotsResponse} message ListStoreSnapshotsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreSnapshotsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeSnapshots != null && message.storeSnapshots.length)
                            for (var i = 0; i < message.storeSnapshots.length; ++i)
                                $root.palexy.streaming.v1.StoreSnapshot.encode(message.storeSnapshots[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreSnapshotsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreSnapshotsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreSnapshotsResponse} message ListStoreSnapshotsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreSnapshotsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreSnapshotsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreSnapshotsResponse} ListStoreSnapshotsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreSnapshotsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreSnapshotsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeSnapshots && message.storeSnapshots.length))
                                    message.storeSnapshots = [];
                                message.storeSnapshots.push($root.palexy.streaming.v1.StoreSnapshot.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreSnapshotsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreSnapshotsResponse} ListStoreSnapshotsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreSnapshotsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreSnapshotsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreSnapshotsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeSnapshots != null && message.hasOwnProperty("storeSnapshots")) {
                            if (!Array.isArray(message.storeSnapshots))
                                return "storeSnapshots: array expected";
                            for (var i = 0; i < message.storeSnapshots.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreSnapshot.verify(message.storeSnapshots[i]);
                                if (error)
                                    return "storeSnapshots." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreSnapshotsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreSnapshotsResponse} ListStoreSnapshotsResponse
                     */
                    ListStoreSnapshotsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreSnapshotsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreSnapshotsResponse();
                        if (object.storeSnapshots) {
                            if (!Array.isArray(object.storeSnapshots))
                                throw TypeError(".palexy.streaming.v1.ListStoreSnapshotsResponse.storeSnapshots: array expected");
                            message.storeSnapshots = [];
                            for (var i = 0; i < object.storeSnapshots.length; ++i) {
                                if (typeof object.storeSnapshots[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStoreSnapshotsResponse.storeSnapshots: object expected");
                                message.storeSnapshots[i] = $root.palexy.streaming.v1.StoreSnapshot.fromObject(object.storeSnapshots[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreSnapshotsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStoreSnapshotsResponse} message ListStoreSnapshotsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreSnapshotsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeSnapshots = [];
                        if (message.storeSnapshots && message.storeSnapshots.length) {
                            object.storeSnapshots = [];
                            for (var j = 0; j < message.storeSnapshots.length; ++j)
                                object.storeSnapshots[j] = $root.palexy.streaming.v1.StoreSnapshot.toObject(message.storeSnapshots[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreSnapshotsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreSnapshotsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreSnapshotsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreSnapshotsResponse;
                })();
    
                v1.BatchDeleteStoreSnapshotRequest = (function() {
    
                    /**
                     * Properties of a BatchDeleteStoreSnapshotRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchDeleteStoreSnapshotRequest
                     * @property {Array.<number>|null} [storeSnapshotIds] BatchDeleteStoreSnapshotRequest storeSnapshotIds
                     */
    
                    /**
                     * Constructs a new BatchDeleteStoreSnapshotRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchDeleteStoreSnapshotRequest.
                     * @implements IBatchDeleteStoreSnapshotRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchDeleteStoreSnapshotRequest=} [properties] Properties to set
                     */
                    function BatchDeleteStoreSnapshotRequest(properties) {
                        this.storeSnapshotIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchDeleteStoreSnapshotRequest storeSnapshotIds.
                     * @member {Array.<number>} storeSnapshotIds
                     * @memberof palexy.streaming.v1.BatchDeleteStoreSnapshotRequest
                     * @instance
                     */
                    BatchDeleteStoreSnapshotRequest.prototype.storeSnapshotIds = $util.emptyArray;
    
                    /**
                     * Creates a new BatchDeleteStoreSnapshotRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchDeleteStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteStoreSnapshotRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchDeleteStoreSnapshotRequest} BatchDeleteStoreSnapshotRequest instance
                     */
                    BatchDeleteStoreSnapshotRequest.create = function create(properties) {
                        return new BatchDeleteStoreSnapshotRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchDeleteStoreSnapshotRequest message. Does not implicitly {@link palexy.streaming.v1.BatchDeleteStoreSnapshotRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchDeleteStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteStoreSnapshotRequest} message BatchDeleteStoreSnapshotRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteStoreSnapshotRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeSnapshotIds != null && message.storeSnapshotIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.storeSnapshotIds.length; ++i)
                                writer.int64(message.storeSnapshotIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchDeleteStoreSnapshotRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchDeleteStoreSnapshotRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchDeleteStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDeleteStoreSnapshotRequest} message BatchDeleteStoreSnapshotRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDeleteStoreSnapshotRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchDeleteStoreSnapshotRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchDeleteStoreSnapshotRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchDeleteStoreSnapshotRequest} BatchDeleteStoreSnapshotRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteStoreSnapshotRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchDeleteStoreSnapshotRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeSnapshotIds && message.storeSnapshotIds.length))
                                    message.storeSnapshotIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeSnapshotIds.push(reader.int64());
                                } else
                                    message.storeSnapshotIds.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchDeleteStoreSnapshotRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchDeleteStoreSnapshotRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchDeleteStoreSnapshotRequest} BatchDeleteStoreSnapshotRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDeleteStoreSnapshotRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchDeleteStoreSnapshotRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchDeleteStoreSnapshotRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchDeleteStoreSnapshotRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeSnapshotIds != null && message.hasOwnProperty("storeSnapshotIds")) {
                            if (!Array.isArray(message.storeSnapshotIds))
                                return "storeSnapshotIds: array expected";
                            for (var i = 0; i < message.storeSnapshotIds.length; ++i)
                                if (!$util.isInteger(message.storeSnapshotIds[i]) && !(message.storeSnapshotIds[i] && $util.isInteger(message.storeSnapshotIds[i].low) && $util.isInteger(message.storeSnapshotIds[i].high)))
                                    return "storeSnapshotIds: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchDeleteStoreSnapshotRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchDeleteStoreSnapshotRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchDeleteStoreSnapshotRequest} BatchDeleteStoreSnapshotRequest
                     */
                    BatchDeleteStoreSnapshotRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchDeleteStoreSnapshotRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchDeleteStoreSnapshotRequest();
                        if (object.storeSnapshotIds) {
                            if (!Array.isArray(object.storeSnapshotIds))
                                throw TypeError(".palexy.streaming.v1.BatchDeleteStoreSnapshotRequest.storeSnapshotIds: array expected");
                            message.storeSnapshotIds = [];
                            for (var i = 0; i < object.storeSnapshotIds.length; ++i)
                                if ($util.Long)
                                    (message.storeSnapshotIds[i] = $util.Long.fromValue(object.storeSnapshotIds[i])).unsigned = false;
                                else if (typeof object.storeSnapshotIds[i] === "string")
                                    message.storeSnapshotIds[i] = parseInt(object.storeSnapshotIds[i], 10);
                                else if (typeof object.storeSnapshotIds[i] === "number")
                                    message.storeSnapshotIds[i] = object.storeSnapshotIds[i];
                                else if (typeof object.storeSnapshotIds[i] === "object")
                                    message.storeSnapshotIds[i] = new $util.LongBits(object.storeSnapshotIds[i].low >>> 0, object.storeSnapshotIds[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchDeleteStoreSnapshotRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchDeleteStoreSnapshotRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchDeleteStoreSnapshotRequest} message BatchDeleteStoreSnapshotRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchDeleteStoreSnapshotRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeSnapshotIds = [];
                        if (message.storeSnapshotIds && message.storeSnapshotIds.length) {
                            object.storeSnapshotIds = [];
                            for (var j = 0; j < message.storeSnapshotIds.length; ++j)
                                if (typeof message.storeSnapshotIds[j] === "number")
                                    object.storeSnapshotIds[j] = options.longs === String ? String(message.storeSnapshotIds[j]) : message.storeSnapshotIds[j];
                                else
                                    object.storeSnapshotIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeSnapshotIds[j]) : options.longs === Number ? new $util.LongBits(message.storeSnapshotIds[j].low >>> 0, message.storeSnapshotIds[j].high >>> 0).toNumber() : message.storeSnapshotIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchDeleteStoreSnapshotRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchDeleteStoreSnapshotRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchDeleteStoreSnapshotRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchDeleteStoreSnapshotRequest;
                })();
    
                v1.StoreWeatherService = (function() {
    
                    /**
                     * Constructs a new StoreWeatherService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreWeatherService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StoreWeatherService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (StoreWeatherService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StoreWeatherService;
    
                    /**
                     * Creates new StoreWeatherService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreWeatherService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StoreWeatherService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StoreWeatherService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreWeatherService#batchUpdateStoreWeather}.
                     * @memberof palexy.streaming.v1.StoreWeatherService
                     * @typedef BatchUpdateStoreWeatherCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchUpdateStoreWeatherResponse} [response] BatchUpdateStoreWeatherResponse
                     */
    
                    /**
                     * Calls BatchUpdateStoreWeather.
                     * @function batchUpdateStoreWeather
                     * @memberof palexy.streaming.v1.StoreWeatherService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchUpdateStoreWeatherRequest} request BatchUpdateStoreWeatherRequest message or plain object
                     * @param {palexy.streaming.v1.StoreWeatherService.BatchUpdateStoreWeatherCallback} callback Node-style callback called with the error, if any, and BatchUpdateStoreWeatherResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreWeatherService.prototype.batchUpdateStoreWeather = function batchUpdateStoreWeather(request, callback) {
                        return this.rpcCall(batchUpdateStoreWeather, $root.palexy.streaming.v1.BatchUpdateStoreWeatherRequest, $root.palexy.streaming.v1.BatchUpdateStoreWeatherResponse, request, callback);
                    }, "name", { value: "BatchUpdateStoreWeather" });
    
                    /**
                     * Calls BatchUpdateStoreWeather.
                     * @function batchUpdateStoreWeather
                     * @memberof palexy.streaming.v1.StoreWeatherService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchUpdateStoreWeatherRequest} request BatchUpdateStoreWeatherRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchUpdateStoreWeatherResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreWeatherService#listStoreWeather}.
                     * @memberof palexy.streaming.v1.StoreWeatherService
                     * @typedef ListStoreWeatherCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStoreWeatherResponse} [response] ListStoreWeatherResponse
                     */
    
                    /**
                     * Calls ListStoreWeather.
                     * @function listStoreWeather
                     * @memberof palexy.streaming.v1.StoreWeatherService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreWeatherRequest} request ListStoreWeatherRequest message or plain object
                     * @param {palexy.streaming.v1.StoreWeatherService.ListStoreWeatherCallback} callback Node-style callback called with the error, if any, and ListStoreWeatherResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreWeatherService.prototype.listStoreWeather = function listStoreWeather(request, callback) {
                        return this.rpcCall(listStoreWeather, $root.palexy.streaming.v1.ListStoreWeatherRequest, $root.palexy.streaming.v1.ListStoreWeatherResponse, request, callback);
                    }, "name", { value: "ListStoreWeather" });
    
                    /**
                     * Calls ListStoreWeather.
                     * @function listStoreWeather
                     * @memberof palexy.streaming.v1.StoreWeatherService
                     * @instance
                     * @param {palexy.streaming.v1.IListStoreWeatherRequest} request ListStoreWeatherRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStoreWeatherResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StoreWeatherService#triggerCreateTasks}.
                     * @memberof palexy.streaming.v1.StoreWeatherService
                     * @typedef TriggerCreateTasksCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls TriggerCreateTasks.
                     * @function triggerCreateTasks
                     * @memberof palexy.streaming.v1.StoreWeatherService
                     * @instance
                     * @param {google.protobuf.IEmpty} request Empty message or plain object
                     * @param {palexy.streaming.v1.StoreWeatherService.TriggerCreateTasksCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreWeatherService.prototype.triggerCreateTasks = function triggerCreateTasks(request, callback) {
                        return this.rpcCall(triggerCreateTasks, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "TriggerCreateTasks" });
    
                    /**
                     * Calls TriggerCreateTasks.
                     * @function triggerCreateTasks
                     * @memberof palexy.streaming.v1.StoreWeatherService
                     * @instance
                     * @param {google.protobuf.IEmpty} request Empty message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return StoreWeatherService;
                })();
    
                /**
                 * WeatherDataSource enum.
                 * @name palexy.streaming.v1.WeatherDataSource
                 * @enum {number}
                 * @property {number} UNKNOWN_DATA_SOURCE=0 UNKNOWN_DATA_SOURCE value
                 * @property {number} WEATHERAPI=1 WEATHERAPI value
                 * @property {number} WEATHERBIT=2 WEATHERBIT value
                 */
                v1.WeatherDataSource = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_DATA_SOURCE"] = 0;
                    values[valuesById[1] = "WEATHERAPI"] = 1;
                    values[valuesById[2] = "WEATHERBIT"] = 2;
                    return values;
                })();
    
                /**
                 * AirQualityDataSource enum.
                 * @name palexy.streaming.v1.AirQualityDataSource
                 * @enum {number}
                 * @property {number} UNKNOWN_AIR_QUALITY_DATA_SOURCE=0 UNKNOWN_AIR_QUALITY_DATA_SOURCE value
                 * @property {number} AIR_QUALITY_WEATHERBIT=1 AIR_QUALITY_WEATHERBIT value
                 */
                v1.AirQualityDataSource = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_AIR_QUALITY_DATA_SOURCE"] = 0;
                    values[valuesById[1] = "AIR_QUALITY_WEATHERBIT"] = 1;
                    return values;
                })();
    
                v1.StoreWeather = (function() {
    
                    /**
                     * Properties of a StoreWeather.
                     * @memberof palexy.streaming.v1
                     * @interface IStoreWeather
                     * @property {number|null} [storeId] StoreWeather storeId
                     * @property {number|null} [dateId] StoreWeather dateId
                     * @property {number|null} [hour] StoreWeather hour
                     * @property {palexy.streaming.v1.StoreWeather.Granularity|null} [granularity] StoreWeather granularity
                     * @property {palexy.streaming.v1.StoreWeather.WeatherCondition|null} [condition] StoreWeather condition
                     * @property {string|null} [conditionDetail] StoreWeather conditionDetail
                     * @property {number|null} [minTemperatureInC] StoreWeather minTemperatureInC
                     * @property {number|null} [avgTemperatureInC] StoreWeather avgTemperatureInC
                     * @property {number|null} [maxTemperatureInC] StoreWeather maxTemperatureInC
                     * @property {number|null} [feelsLikeInC] StoreWeather feelsLikeInC
                     * @property {number|null} [chanceOfRain] StoreWeather chanceOfRain
                     * @property {number|null} [precipitationInMm] StoreWeather precipitationInMm
                     * @property {number|null} [cloud] StoreWeather cloud
                     * @property {number|null} [humidity] StoreWeather humidity
                     * @property {number|null} [uv] StoreWeather uv
                     * @property {number|null} [aqi] StoreWeather aqi
                     * @property {palexy.streaming.v1.WeatherDataSource|null} [dataSource] StoreWeather dataSource
                     * @property {palexy.streaming.v1.AirQualityDataSource|null} [airQualityDataSource] StoreWeather airQualityDataSource
                     * @property {Array.<number>|null} [rainyHours] StoreWeather rainyHours
                     * @property {palexy.streaming.v1.StoreWeather.AirQualityCondition|null} [airQualityCondition] StoreWeather airQualityCondition
                     */
    
                    /**
                     * Constructs a new StoreWeather.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StoreWeather.
                     * @implements IStoreWeather
                     * @constructor
                     * @param {palexy.streaming.v1.IStoreWeather=} [properties] Properties to set
                     */
                    function StoreWeather(properties) {
                        this.rainyHours = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreWeather storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreWeather dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.dateId = 0;
    
                    /**
                     * StoreWeather hour.
                     * @member {number} hour
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.hour = 0;
    
                    /**
                     * StoreWeather granularity.
                     * @member {palexy.streaming.v1.StoreWeather.Granularity} granularity
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.granularity = 0;
    
                    /**
                     * StoreWeather condition.
                     * @member {palexy.streaming.v1.StoreWeather.WeatherCondition} condition
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.condition = 0;
    
                    /**
                     * StoreWeather conditionDetail.
                     * @member {string} conditionDetail
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.conditionDetail = "";
    
                    /**
                     * StoreWeather minTemperatureInC.
                     * @member {number} minTemperatureInC
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.minTemperatureInC = 0;
    
                    /**
                     * StoreWeather avgTemperatureInC.
                     * @member {number} avgTemperatureInC
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.avgTemperatureInC = 0;
    
                    /**
                     * StoreWeather maxTemperatureInC.
                     * @member {number} maxTemperatureInC
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.maxTemperatureInC = 0;
    
                    /**
                     * StoreWeather feelsLikeInC.
                     * @member {number} feelsLikeInC
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.feelsLikeInC = 0;
    
                    /**
                     * StoreWeather chanceOfRain.
                     * @member {number} chanceOfRain
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.chanceOfRain = 0;
    
                    /**
                     * StoreWeather precipitationInMm.
                     * @member {number} precipitationInMm
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.precipitationInMm = 0;
    
                    /**
                     * StoreWeather cloud.
                     * @member {number} cloud
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.cloud = 0;
    
                    /**
                     * StoreWeather humidity.
                     * @member {number} humidity
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.humidity = 0;
    
                    /**
                     * StoreWeather uv.
                     * @member {number} uv
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.uv = 0;
    
                    /**
                     * StoreWeather aqi.
                     * @member {number} aqi
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.aqi = 0;
    
                    /**
                     * StoreWeather dataSource.
                     * @member {palexy.streaming.v1.WeatherDataSource} dataSource
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.dataSource = 0;
    
                    /**
                     * StoreWeather airQualityDataSource.
                     * @member {palexy.streaming.v1.AirQualityDataSource} airQualityDataSource
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.airQualityDataSource = 0;
    
                    /**
                     * StoreWeather rainyHours.
                     * @member {Array.<number>} rainyHours
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.rainyHours = $util.emptyArray;
    
                    /**
                     * StoreWeather airQualityCondition.
                     * @member {palexy.streaming.v1.StoreWeather.AirQualityCondition} airQualityCondition
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     */
                    StoreWeather.prototype.airQualityCondition = 0;
    
                    /**
                     * Creates a new StoreWeather instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @static
                     * @param {palexy.streaming.v1.IStoreWeather=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StoreWeather} StoreWeather instance
                     */
                    StoreWeather.create = function create(properties) {
                        return new StoreWeather(properties);
                    };
    
                    /**
                     * Encodes the specified StoreWeather message. Does not implicitly {@link palexy.streaming.v1.StoreWeather.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @static
                     * @param {palexy.streaming.v1.IStoreWeather} message StoreWeather message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreWeather.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dateId);
                        if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.hour);
                        if (message.granularity != null && Object.hasOwnProperty.call(message, "granularity"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.granularity);
                        if (message.condition != null && Object.hasOwnProperty.call(message, "condition"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.condition);
                        if (message.conditionDetail != null && Object.hasOwnProperty.call(message, "conditionDetail"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.conditionDetail);
                        if (message.minTemperatureInC != null && Object.hasOwnProperty.call(message, "minTemperatureInC"))
                            writer.uint32(/* id 7, wireType 5 =*/61).float(message.minTemperatureInC);
                        if (message.avgTemperatureInC != null && Object.hasOwnProperty.call(message, "avgTemperatureInC"))
                            writer.uint32(/* id 8, wireType 5 =*/69).float(message.avgTemperatureInC);
                        if (message.maxTemperatureInC != null && Object.hasOwnProperty.call(message, "maxTemperatureInC"))
                            writer.uint32(/* id 9, wireType 5 =*/77).float(message.maxTemperatureInC);
                        if (message.feelsLikeInC != null && Object.hasOwnProperty.call(message, "feelsLikeInC"))
                            writer.uint32(/* id 10, wireType 5 =*/85).float(message.feelsLikeInC);
                        if (message.chanceOfRain != null && Object.hasOwnProperty.call(message, "chanceOfRain"))
                            writer.uint32(/* id 11, wireType 5 =*/93).float(message.chanceOfRain);
                        if (message.precipitationInMm != null && Object.hasOwnProperty.call(message, "precipitationInMm"))
                            writer.uint32(/* id 12, wireType 5 =*/101).float(message.precipitationInMm);
                        if (message.cloud != null && Object.hasOwnProperty.call(message, "cloud"))
                            writer.uint32(/* id 13, wireType 5 =*/109).float(message.cloud);
                        if (message.humidity != null && Object.hasOwnProperty.call(message, "humidity"))
                            writer.uint32(/* id 14, wireType 5 =*/117).float(message.humidity);
                        if (message.uv != null && Object.hasOwnProperty.call(message, "uv"))
                            writer.uint32(/* id 15, wireType 5 =*/125).float(message.uv);
                        if (message.dataSource != null && Object.hasOwnProperty.call(message, "dataSource"))
                            writer.uint32(/* id 16, wireType 0 =*/128).int32(message.dataSource);
                        if (message.aqi != null && Object.hasOwnProperty.call(message, "aqi"))
                            writer.uint32(/* id 17, wireType 5 =*/141).float(message.aqi);
                        if (message.airQualityDataSource != null && Object.hasOwnProperty.call(message, "airQualityDataSource"))
                            writer.uint32(/* id 18, wireType 0 =*/144).int32(message.airQualityDataSource);
                        if (message.rainyHours != null && message.rainyHours.length) {
                            writer.uint32(/* id 19, wireType 2 =*/154).fork();
                            for (var i = 0; i < message.rainyHours.length; ++i)
                                writer.int32(message.rainyHours[i]);
                            writer.ldelim();
                        }
                        if (message.airQualityCondition != null && Object.hasOwnProperty.call(message, "airQualityCondition"))
                            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.airQualityCondition);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreWeather message, length delimited. Does not implicitly {@link palexy.streaming.v1.StoreWeather.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @static
                     * @param {palexy.streaming.v1.IStoreWeather} message StoreWeather message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreWeather.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreWeather message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StoreWeather} StoreWeather
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreWeather.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StoreWeather();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.dateId = reader.int32();
                                break;
                            case 3:
                                message.hour = reader.int32();
                                break;
                            case 4:
                                message.granularity = reader.int32();
                                break;
                            case 5:
                                message.condition = reader.int32();
                                break;
                            case 6:
                                message.conditionDetail = reader.string();
                                break;
                            case 7:
                                message.minTemperatureInC = reader.float();
                                break;
                            case 8:
                                message.avgTemperatureInC = reader.float();
                                break;
                            case 9:
                                message.maxTemperatureInC = reader.float();
                                break;
                            case 10:
                                message.feelsLikeInC = reader.float();
                                break;
                            case 11:
                                message.chanceOfRain = reader.float();
                                break;
                            case 12:
                                message.precipitationInMm = reader.float();
                                break;
                            case 13:
                                message.cloud = reader.float();
                                break;
                            case 14:
                                message.humidity = reader.float();
                                break;
                            case 15:
                                message.uv = reader.float();
                                break;
                            case 17:
                                message.aqi = reader.float();
                                break;
                            case 16:
                                message.dataSource = reader.int32();
                                break;
                            case 18:
                                message.airQualityDataSource = reader.int32();
                                break;
                            case 19:
                                if (!(message.rainyHours && message.rainyHours.length))
                                    message.rainyHours = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.rainyHours.push(reader.int32());
                                } else
                                    message.rainyHours.push(reader.int32());
                                break;
                            case 20:
                                message.airQualityCondition = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreWeather message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StoreWeather} StoreWeather
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreWeather.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreWeather message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreWeather.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.hour != null && message.hasOwnProperty("hour"))
                            if (!$util.isInteger(message.hour))
                                return "hour: integer expected";
                        if (message.granularity != null && message.hasOwnProperty("granularity"))
                            switch (message.granularity) {
                            default:
                                return "granularity: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.condition != null && message.hasOwnProperty("condition"))
                            switch (message.condition) {
                            default:
                                return "condition: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.conditionDetail != null && message.hasOwnProperty("conditionDetail"))
                            if (!$util.isString(message.conditionDetail))
                                return "conditionDetail: string expected";
                        if (message.minTemperatureInC != null && message.hasOwnProperty("minTemperatureInC"))
                            if (typeof message.minTemperatureInC !== "number")
                                return "minTemperatureInC: number expected";
                        if (message.avgTemperatureInC != null && message.hasOwnProperty("avgTemperatureInC"))
                            if (typeof message.avgTemperatureInC !== "number")
                                return "avgTemperatureInC: number expected";
                        if (message.maxTemperatureInC != null && message.hasOwnProperty("maxTemperatureInC"))
                            if (typeof message.maxTemperatureInC !== "number")
                                return "maxTemperatureInC: number expected";
                        if (message.feelsLikeInC != null && message.hasOwnProperty("feelsLikeInC"))
                            if (typeof message.feelsLikeInC !== "number")
                                return "feelsLikeInC: number expected";
                        if (message.chanceOfRain != null && message.hasOwnProperty("chanceOfRain"))
                            if (typeof message.chanceOfRain !== "number")
                                return "chanceOfRain: number expected";
                        if (message.precipitationInMm != null && message.hasOwnProperty("precipitationInMm"))
                            if (typeof message.precipitationInMm !== "number")
                                return "precipitationInMm: number expected";
                        if (message.cloud != null && message.hasOwnProperty("cloud"))
                            if (typeof message.cloud !== "number")
                                return "cloud: number expected";
                        if (message.humidity != null && message.hasOwnProperty("humidity"))
                            if (typeof message.humidity !== "number")
                                return "humidity: number expected";
                        if (message.uv != null && message.hasOwnProperty("uv"))
                            if (typeof message.uv !== "number")
                                return "uv: number expected";
                        if (message.aqi != null && message.hasOwnProperty("aqi"))
                            if (typeof message.aqi !== "number")
                                return "aqi: number expected";
                        if (message.dataSource != null && message.hasOwnProperty("dataSource"))
                            switch (message.dataSource) {
                            default:
                                return "dataSource: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.airQualityDataSource != null && message.hasOwnProperty("airQualityDataSource"))
                            switch (message.airQualityDataSource) {
                            default:
                                return "airQualityDataSource: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.rainyHours != null && message.hasOwnProperty("rainyHours")) {
                            if (!Array.isArray(message.rainyHours))
                                return "rainyHours: array expected";
                            for (var i = 0; i < message.rainyHours.length; ++i)
                                if (!$util.isInteger(message.rainyHours[i]))
                                    return "rainyHours: integer[] expected";
                        }
                        if (message.airQualityCondition != null && message.hasOwnProperty("airQualityCondition"))
                            switch (message.airQualityCondition) {
                            default:
                                return "airQualityCondition: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a StoreWeather message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StoreWeather} StoreWeather
                     */
                    StoreWeather.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StoreWeather)
                            return object;
                        var message = new $root.palexy.streaming.v1.StoreWeather();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.hour != null)
                            message.hour = object.hour | 0;
                        switch (object.granularity) {
                        case "UNKNOWN":
                        case 0:
                            message.granularity = 0;
                            break;
                        case "DAILY":
                        case 1:
                            message.granularity = 1;
                            break;
                        case "HOURLY":
                        case 2:
                            message.granularity = 2;
                            break;
                        }
                        switch (object.condition) {
                        case "UNKNOWN_CONDITION":
                        case 0:
                            message.condition = 0;
                            break;
                        case "CLEAR":
                        case 1:
                            message.condition = 1;
                            break;
                        case "PARTLY_CLOUDY":
                        case 2:
                            message.condition = 2;
                            break;
                        case "CLOUDY":
                        case 3:
                            message.condition = 3;
                            break;
                        case "RAINY":
                        case 4:
                            message.condition = 4;
                            break;
                        }
                        if (object.conditionDetail != null)
                            message.conditionDetail = String(object.conditionDetail);
                        if (object.minTemperatureInC != null)
                            message.minTemperatureInC = Number(object.minTemperatureInC);
                        if (object.avgTemperatureInC != null)
                            message.avgTemperatureInC = Number(object.avgTemperatureInC);
                        if (object.maxTemperatureInC != null)
                            message.maxTemperatureInC = Number(object.maxTemperatureInC);
                        if (object.feelsLikeInC != null)
                            message.feelsLikeInC = Number(object.feelsLikeInC);
                        if (object.chanceOfRain != null)
                            message.chanceOfRain = Number(object.chanceOfRain);
                        if (object.precipitationInMm != null)
                            message.precipitationInMm = Number(object.precipitationInMm);
                        if (object.cloud != null)
                            message.cloud = Number(object.cloud);
                        if (object.humidity != null)
                            message.humidity = Number(object.humidity);
                        if (object.uv != null)
                            message.uv = Number(object.uv);
                        if (object.aqi != null)
                            message.aqi = Number(object.aqi);
                        switch (object.dataSource) {
                        case "UNKNOWN_DATA_SOURCE":
                        case 0:
                            message.dataSource = 0;
                            break;
                        case "WEATHERAPI":
                        case 1:
                            message.dataSource = 1;
                            break;
                        case "WEATHERBIT":
                        case 2:
                            message.dataSource = 2;
                            break;
                        }
                        switch (object.airQualityDataSource) {
                        case "UNKNOWN_AIR_QUALITY_DATA_SOURCE":
                        case 0:
                            message.airQualityDataSource = 0;
                            break;
                        case "AIR_QUALITY_WEATHERBIT":
                        case 1:
                            message.airQualityDataSource = 1;
                            break;
                        }
                        if (object.rainyHours) {
                            if (!Array.isArray(object.rainyHours))
                                throw TypeError(".palexy.streaming.v1.StoreWeather.rainyHours: array expected");
                            message.rainyHours = [];
                            for (var i = 0; i < object.rainyHours.length; ++i)
                                message.rainyHours[i] = object.rainyHours[i] | 0;
                        }
                        switch (object.airQualityCondition) {
                        case "UNKNOWN_AQ_CONDITION":
                        case 0:
                            message.airQualityCondition = 0;
                            break;
                        case "GOOD":
                        case 1:
                            message.airQualityCondition = 1;
                            break;
                        case "MODERATE":
                        case 2:
                            message.airQualityCondition = 2;
                            break;
                        case "UNHEALTHY_FOR_SENSITIVE_GROUPS":
                        case 3:
                            message.airQualityCondition = 3;
                            break;
                        case "UNHEALTHY":
                        case 4:
                            message.airQualityCondition = 4;
                            break;
                        case "VERY_UNHEALTHY":
                        case 5:
                            message.airQualityCondition = 5;
                            break;
                        case "HAZARDOUS":
                        case 6:
                            message.airQualityCondition = 6;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreWeather message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @static
                     * @param {palexy.streaming.v1.StoreWeather} message StoreWeather
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreWeather.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.rainyHours = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                            object.hour = 0;
                            object.granularity = options.enums === String ? "UNKNOWN" : 0;
                            object.condition = options.enums === String ? "UNKNOWN_CONDITION" : 0;
                            object.conditionDetail = "";
                            object.minTemperatureInC = 0;
                            object.avgTemperatureInC = 0;
                            object.maxTemperatureInC = 0;
                            object.feelsLikeInC = 0;
                            object.chanceOfRain = 0;
                            object.precipitationInMm = 0;
                            object.cloud = 0;
                            object.humidity = 0;
                            object.uv = 0;
                            object.dataSource = options.enums === String ? "UNKNOWN_DATA_SOURCE" : 0;
                            object.aqi = 0;
                            object.airQualityDataSource = options.enums === String ? "UNKNOWN_AIR_QUALITY_DATA_SOURCE" : 0;
                            object.airQualityCondition = options.enums === String ? "UNKNOWN_AQ_CONDITION" : 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.hour != null && message.hasOwnProperty("hour"))
                            object.hour = message.hour;
                        if (message.granularity != null && message.hasOwnProperty("granularity"))
                            object.granularity = options.enums === String ? $root.palexy.streaming.v1.StoreWeather.Granularity[message.granularity] : message.granularity;
                        if (message.condition != null && message.hasOwnProperty("condition"))
                            object.condition = options.enums === String ? $root.palexy.streaming.v1.StoreWeather.WeatherCondition[message.condition] : message.condition;
                        if (message.conditionDetail != null && message.hasOwnProperty("conditionDetail"))
                            object.conditionDetail = message.conditionDetail;
                        if (message.minTemperatureInC != null && message.hasOwnProperty("minTemperatureInC"))
                            object.minTemperatureInC = options.json && !isFinite(message.minTemperatureInC) ? String(message.minTemperatureInC) : message.minTemperatureInC;
                        if (message.avgTemperatureInC != null && message.hasOwnProperty("avgTemperatureInC"))
                            object.avgTemperatureInC = options.json && !isFinite(message.avgTemperatureInC) ? String(message.avgTemperatureInC) : message.avgTemperatureInC;
                        if (message.maxTemperatureInC != null && message.hasOwnProperty("maxTemperatureInC"))
                            object.maxTemperatureInC = options.json && !isFinite(message.maxTemperatureInC) ? String(message.maxTemperatureInC) : message.maxTemperatureInC;
                        if (message.feelsLikeInC != null && message.hasOwnProperty("feelsLikeInC"))
                            object.feelsLikeInC = options.json && !isFinite(message.feelsLikeInC) ? String(message.feelsLikeInC) : message.feelsLikeInC;
                        if (message.chanceOfRain != null && message.hasOwnProperty("chanceOfRain"))
                            object.chanceOfRain = options.json && !isFinite(message.chanceOfRain) ? String(message.chanceOfRain) : message.chanceOfRain;
                        if (message.precipitationInMm != null && message.hasOwnProperty("precipitationInMm"))
                            object.precipitationInMm = options.json && !isFinite(message.precipitationInMm) ? String(message.precipitationInMm) : message.precipitationInMm;
                        if (message.cloud != null && message.hasOwnProperty("cloud"))
                            object.cloud = options.json && !isFinite(message.cloud) ? String(message.cloud) : message.cloud;
                        if (message.humidity != null && message.hasOwnProperty("humidity"))
                            object.humidity = options.json && !isFinite(message.humidity) ? String(message.humidity) : message.humidity;
                        if (message.uv != null && message.hasOwnProperty("uv"))
                            object.uv = options.json && !isFinite(message.uv) ? String(message.uv) : message.uv;
                        if (message.dataSource != null && message.hasOwnProperty("dataSource"))
                            object.dataSource = options.enums === String ? $root.palexy.streaming.v1.WeatherDataSource[message.dataSource] : message.dataSource;
                        if (message.aqi != null && message.hasOwnProperty("aqi"))
                            object.aqi = options.json && !isFinite(message.aqi) ? String(message.aqi) : message.aqi;
                        if (message.airQualityDataSource != null && message.hasOwnProperty("airQualityDataSource"))
                            object.airQualityDataSource = options.enums === String ? $root.palexy.streaming.v1.AirQualityDataSource[message.airQualityDataSource] : message.airQualityDataSource;
                        if (message.rainyHours && message.rainyHours.length) {
                            object.rainyHours = [];
                            for (var j = 0; j < message.rainyHours.length; ++j)
                                object.rainyHours[j] = message.rainyHours[j];
                        }
                        if (message.airQualityCondition != null && message.hasOwnProperty("airQualityCondition"))
                            object.airQualityCondition = options.enums === String ? $root.palexy.streaming.v1.StoreWeather.AirQualityCondition[message.airQualityCondition] : message.airQualityCondition;
                        return object;
                    };
    
                    /**
                     * Converts this StoreWeather to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StoreWeather
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreWeather.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * WeatherCondition enum.
                     * @name palexy.streaming.v1.StoreWeather.WeatherCondition
                     * @enum {number}
                     * @property {number} UNKNOWN_CONDITION=0 UNKNOWN_CONDITION value
                     * @property {number} CLEAR=1 CLEAR value
                     * @property {number} PARTLY_CLOUDY=2 PARTLY_CLOUDY value
                     * @property {number} CLOUDY=3 CLOUDY value
                     * @property {number} RAINY=4 RAINY value
                     */
                    StoreWeather.WeatherCondition = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_CONDITION"] = 0;
                        values[valuesById[1] = "CLEAR"] = 1;
                        values[valuesById[2] = "PARTLY_CLOUDY"] = 2;
                        values[valuesById[3] = "CLOUDY"] = 3;
                        values[valuesById[4] = "RAINY"] = 4;
                        return values;
                    })();
    
                    /**
                     * AirQualityCondition enum.
                     * @name palexy.streaming.v1.StoreWeather.AirQualityCondition
                     * @enum {number}
                     * @property {number} UNKNOWN_AQ_CONDITION=0 UNKNOWN_AQ_CONDITION value
                     * @property {number} GOOD=1 GOOD value
                     * @property {number} MODERATE=2 MODERATE value
                     * @property {number} UNHEALTHY_FOR_SENSITIVE_GROUPS=3 UNHEALTHY_FOR_SENSITIVE_GROUPS value
                     * @property {number} UNHEALTHY=4 UNHEALTHY value
                     * @property {number} VERY_UNHEALTHY=5 VERY_UNHEALTHY value
                     * @property {number} HAZARDOUS=6 HAZARDOUS value
                     */
                    StoreWeather.AirQualityCondition = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_AQ_CONDITION"] = 0;
                        values[valuesById[1] = "GOOD"] = 1;
                        values[valuesById[2] = "MODERATE"] = 2;
                        values[valuesById[3] = "UNHEALTHY_FOR_SENSITIVE_GROUPS"] = 3;
                        values[valuesById[4] = "UNHEALTHY"] = 4;
                        values[valuesById[5] = "VERY_UNHEALTHY"] = 5;
                        values[valuesById[6] = "HAZARDOUS"] = 6;
                        return values;
                    })();
    
                    /**
                     * Granularity enum.
                     * @name palexy.streaming.v1.StoreWeather.Granularity
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} DAILY=1 DAILY value
                     * @property {number} HOURLY=2 HOURLY value
                     */
                    StoreWeather.Granularity = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "DAILY"] = 1;
                        values[valuesById[2] = "HOURLY"] = 2;
                        return values;
                    })();
    
                    return StoreWeather;
                })();
    
                v1.BatchUpdateStoreWeatherRequest = (function() {
    
                    /**
                     * Properties of a BatchUpdateStoreWeatherRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchUpdateStoreWeatherRequest
                     * @property {Array.<palexy.streaming.v1.IStoreWeather>|null} [storeWeathers] BatchUpdateStoreWeatherRequest storeWeathers
                     */
    
                    /**
                     * Constructs a new BatchUpdateStoreWeatherRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchUpdateStoreWeatherRequest.
                     * @implements IBatchUpdateStoreWeatherRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchUpdateStoreWeatherRequest=} [properties] Properties to set
                     */
                    function BatchUpdateStoreWeatherRequest(properties) {
                        this.storeWeathers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchUpdateStoreWeatherRequest storeWeathers.
                     * @member {Array.<palexy.streaming.v1.IStoreWeather>} storeWeathers
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherRequest
                     * @instance
                     */
                    BatchUpdateStoreWeatherRequest.prototype.storeWeathers = $util.emptyArray;
    
                    /**
                     * Creates a new BatchUpdateStoreWeatherRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateStoreWeatherRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchUpdateStoreWeatherRequest} BatchUpdateStoreWeatherRequest instance
                     */
                    BatchUpdateStoreWeatherRequest.create = function create(properties) {
                        return new BatchUpdateStoreWeatherRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchUpdateStoreWeatherRequest message. Does not implicitly {@link palexy.streaming.v1.BatchUpdateStoreWeatherRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateStoreWeatherRequest} message BatchUpdateStoreWeatherRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateStoreWeatherRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeWeathers != null && message.storeWeathers.length)
                            for (var i = 0; i < message.storeWeathers.length; ++i)
                                $root.palexy.streaming.v1.StoreWeather.encode(message.storeWeathers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchUpdateStoreWeatherRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchUpdateStoreWeatherRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateStoreWeatherRequest} message BatchUpdateStoreWeatherRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateStoreWeatherRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchUpdateStoreWeatherRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchUpdateStoreWeatherRequest} BatchUpdateStoreWeatherRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateStoreWeatherRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchUpdateStoreWeatherRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeWeathers && message.storeWeathers.length))
                                    message.storeWeathers = [];
                                message.storeWeathers.push($root.palexy.streaming.v1.StoreWeather.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchUpdateStoreWeatherRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchUpdateStoreWeatherRequest} BatchUpdateStoreWeatherRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateStoreWeatherRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchUpdateStoreWeatherRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchUpdateStoreWeatherRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeWeathers != null && message.hasOwnProperty("storeWeathers")) {
                            if (!Array.isArray(message.storeWeathers))
                                return "storeWeathers: array expected";
                            for (var i = 0; i < message.storeWeathers.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreWeather.verify(message.storeWeathers[i]);
                                if (error)
                                    return "storeWeathers." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchUpdateStoreWeatherRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchUpdateStoreWeatherRequest} BatchUpdateStoreWeatherRequest
                     */
                    BatchUpdateStoreWeatherRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchUpdateStoreWeatherRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchUpdateStoreWeatherRequest();
                        if (object.storeWeathers) {
                            if (!Array.isArray(object.storeWeathers))
                                throw TypeError(".palexy.streaming.v1.BatchUpdateStoreWeatherRequest.storeWeathers: array expected");
                            message.storeWeathers = [];
                            for (var i = 0; i < object.storeWeathers.length; ++i) {
                                if (typeof object.storeWeathers[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchUpdateStoreWeatherRequest.storeWeathers: object expected");
                                message.storeWeathers[i] = $root.palexy.streaming.v1.StoreWeather.fromObject(object.storeWeathers[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchUpdateStoreWeatherRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchUpdateStoreWeatherRequest} message BatchUpdateStoreWeatherRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchUpdateStoreWeatherRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeWeathers = [];
                        if (message.storeWeathers && message.storeWeathers.length) {
                            object.storeWeathers = [];
                            for (var j = 0; j < message.storeWeathers.length; ++j)
                                object.storeWeathers[j] = $root.palexy.streaming.v1.StoreWeather.toObject(message.storeWeathers[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchUpdateStoreWeatherRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchUpdateStoreWeatherRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchUpdateStoreWeatherRequest;
                })();
    
                v1.BatchUpdateStoreWeatherResponse = (function() {
    
                    /**
                     * Properties of a BatchUpdateStoreWeatherResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchUpdateStoreWeatherResponse
                     * @property {Array.<palexy.streaming.v1.IStoreWeather>|null} [storeWeathers] BatchUpdateStoreWeatherResponse storeWeathers
                     */
    
                    /**
                     * Constructs a new BatchUpdateStoreWeatherResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchUpdateStoreWeatherResponse.
                     * @implements IBatchUpdateStoreWeatherResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchUpdateStoreWeatherResponse=} [properties] Properties to set
                     */
                    function BatchUpdateStoreWeatherResponse(properties) {
                        this.storeWeathers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchUpdateStoreWeatherResponse storeWeathers.
                     * @member {Array.<palexy.streaming.v1.IStoreWeather>} storeWeathers
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherResponse
                     * @instance
                     */
                    BatchUpdateStoreWeatherResponse.prototype.storeWeathers = $util.emptyArray;
    
                    /**
                     * Creates a new BatchUpdateStoreWeatherResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateStoreWeatherResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchUpdateStoreWeatherResponse} BatchUpdateStoreWeatherResponse instance
                     */
                    BatchUpdateStoreWeatherResponse.create = function create(properties) {
                        return new BatchUpdateStoreWeatherResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchUpdateStoreWeatherResponse message. Does not implicitly {@link palexy.streaming.v1.BatchUpdateStoreWeatherResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateStoreWeatherResponse} message BatchUpdateStoreWeatherResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateStoreWeatherResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeWeathers != null && message.storeWeathers.length)
                            for (var i = 0; i < message.storeWeathers.length; ++i)
                                $root.palexy.streaming.v1.StoreWeather.encode(message.storeWeathers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchUpdateStoreWeatherResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchUpdateStoreWeatherResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchUpdateStoreWeatherResponse} message BatchUpdateStoreWeatherResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateStoreWeatherResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchUpdateStoreWeatherResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchUpdateStoreWeatherResponse} BatchUpdateStoreWeatherResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateStoreWeatherResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchUpdateStoreWeatherResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeWeathers && message.storeWeathers.length))
                                    message.storeWeathers = [];
                                message.storeWeathers.push($root.palexy.streaming.v1.StoreWeather.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchUpdateStoreWeatherResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchUpdateStoreWeatherResponse} BatchUpdateStoreWeatherResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateStoreWeatherResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchUpdateStoreWeatherResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchUpdateStoreWeatherResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeWeathers != null && message.hasOwnProperty("storeWeathers")) {
                            if (!Array.isArray(message.storeWeathers))
                                return "storeWeathers: array expected";
                            for (var i = 0; i < message.storeWeathers.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreWeather.verify(message.storeWeathers[i]);
                                if (error)
                                    return "storeWeathers." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchUpdateStoreWeatherResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchUpdateStoreWeatherResponse} BatchUpdateStoreWeatherResponse
                     */
                    BatchUpdateStoreWeatherResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchUpdateStoreWeatherResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchUpdateStoreWeatherResponse();
                        if (object.storeWeathers) {
                            if (!Array.isArray(object.storeWeathers))
                                throw TypeError(".palexy.streaming.v1.BatchUpdateStoreWeatherResponse.storeWeathers: array expected");
                            message.storeWeathers = [];
                            for (var i = 0; i < object.storeWeathers.length; ++i) {
                                if (typeof object.storeWeathers[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchUpdateStoreWeatherResponse.storeWeathers: object expected");
                                message.storeWeathers[i] = $root.palexy.streaming.v1.StoreWeather.fromObject(object.storeWeathers[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchUpdateStoreWeatherResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchUpdateStoreWeatherResponse} message BatchUpdateStoreWeatherResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchUpdateStoreWeatherResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeWeathers = [];
                        if (message.storeWeathers && message.storeWeathers.length) {
                            object.storeWeathers = [];
                            for (var j = 0; j < message.storeWeathers.length; ++j)
                                object.storeWeathers[j] = $root.palexy.streaming.v1.StoreWeather.toObject(message.storeWeathers[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchUpdateStoreWeatherResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchUpdateStoreWeatherResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchUpdateStoreWeatherResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchUpdateStoreWeatherResponse;
                })();
    
                v1.ListStoreWeatherRequest = (function() {
    
                    /**
                     * Properties of a ListStoreWeatherRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreWeatherRequest
                     * @property {number|null} [storeId] ListStoreWeatherRequest storeId
                     * @property {number|null} [startDateId] ListStoreWeatherRequest startDateId
                     * @property {number|null} [endDateId] ListStoreWeatherRequest endDateId
                     * @property {Array.<palexy.streaming.v1.StoreWeather.Granularity>|null} [granularities] ListStoreWeatherRequest granularities
                     */
    
                    /**
                     * Constructs a new ListStoreWeatherRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreWeatherRequest.
                     * @implements IListStoreWeatherRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreWeatherRequest=} [properties] Properties to set
                     */
                    function ListStoreWeatherRequest(properties) {
                        this.granularities = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreWeatherRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @instance
                     */
                    ListStoreWeatherRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListStoreWeatherRequest startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @instance
                     */
                    ListStoreWeatherRequest.prototype.startDateId = 0;
    
                    /**
                     * ListStoreWeatherRequest endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @instance
                     */
                    ListStoreWeatherRequest.prototype.endDateId = 0;
    
                    /**
                     * ListStoreWeatherRequest granularities.
                     * @member {Array.<palexy.streaming.v1.StoreWeather.Granularity>} granularities
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @instance
                     */
                    ListStoreWeatherRequest.prototype.granularities = $util.emptyArray;
    
                    /**
                     * Creates a new ListStoreWeatherRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreWeatherRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreWeatherRequest} ListStoreWeatherRequest instance
                     */
                    ListStoreWeatherRequest.create = function create(properties) {
                        return new ListStoreWeatherRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreWeatherRequest message. Does not implicitly {@link palexy.streaming.v1.ListStoreWeatherRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreWeatherRequest} message ListStoreWeatherRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreWeatherRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.endDateId);
                        if (message.granularities != null && message.granularities.length) {
                            writer.uint32(/* id 4, wireType 2 =*/34).fork();
                            for (var i = 0; i < message.granularities.length; ++i)
                                writer.int32(message.granularities[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreWeatherRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreWeatherRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStoreWeatherRequest} message ListStoreWeatherRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreWeatherRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreWeatherRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreWeatherRequest} ListStoreWeatherRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreWeatherRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreWeatherRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.startDateId = reader.int32();
                                break;
                            case 3:
                                message.endDateId = reader.int32();
                                break;
                            case 4:
                                if (!(message.granularities && message.granularities.length))
                                    message.granularities = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.granularities.push(reader.int32());
                                } else
                                    message.granularities.push(reader.int32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreWeatherRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreWeatherRequest} ListStoreWeatherRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreWeatherRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreWeatherRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreWeatherRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        if (message.granularities != null && message.hasOwnProperty("granularities")) {
                            if (!Array.isArray(message.granularities))
                                return "granularities: array expected";
                            for (var i = 0; i < message.granularities.length; ++i)
                                switch (message.granularities[i]) {
                                default:
                                    return "granularities: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                    break;
                                }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreWeatherRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreWeatherRequest} ListStoreWeatherRequest
                     */
                    ListStoreWeatherRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreWeatherRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreWeatherRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        if (object.granularities) {
                            if (!Array.isArray(object.granularities))
                                throw TypeError(".palexy.streaming.v1.ListStoreWeatherRequest.granularities: array expected");
                            message.granularities = [];
                            for (var i = 0; i < object.granularities.length; ++i)
                                switch (object.granularities[i]) {
                                default:
                                case "UNKNOWN":
                                case 0:
                                    message.granularities[i] = 0;
                                    break;
                                case "DAILY":
                                case 1:
                                    message.granularities[i] = 1;
                                    break;
                                case "HOURLY":
                                case 2:
                                    message.granularities[i] = 2;
                                    break;
                                }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreWeatherRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStoreWeatherRequest} message ListStoreWeatherRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreWeatherRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.granularities = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.startDateId = 0;
                            object.endDateId = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.granularities && message.granularities.length) {
                            object.granularities = [];
                            for (var j = 0; j < message.granularities.length; ++j)
                                object.granularities[j] = options.enums === String ? $root.palexy.streaming.v1.StoreWeather.Granularity[message.granularities[j]] : message.granularities[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreWeatherRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreWeatherRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreWeatherRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreWeatherRequest;
                })();
    
                v1.ListStoreWeatherResponse = (function() {
    
                    /**
                     * Properties of a ListStoreWeatherResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStoreWeatherResponse
                     * @property {Array.<palexy.streaming.v1.IStoreWeather>|null} [storeWeathers] ListStoreWeatherResponse storeWeathers
                     */
    
                    /**
                     * Constructs a new ListStoreWeatherResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStoreWeatherResponse.
                     * @implements IListStoreWeatherResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStoreWeatherResponse=} [properties] Properties to set
                     */
                    function ListStoreWeatherResponse(properties) {
                        this.storeWeathers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreWeatherResponse storeWeathers.
                     * @member {Array.<palexy.streaming.v1.IStoreWeather>} storeWeathers
                     * @memberof palexy.streaming.v1.ListStoreWeatherResponse
                     * @instance
                     */
                    ListStoreWeatherResponse.prototype.storeWeathers = $util.emptyArray;
    
                    /**
                     * Creates a new ListStoreWeatherResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStoreWeatherResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreWeatherResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStoreWeatherResponse} ListStoreWeatherResponse instance
                     */
                    ListStoreWeatherResponse.create = function create(properties) {
                        return new ListStoreWeatherResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreWeatherResponse message. Does not implicitly {@link palexy.streaming.v1.ListStoreWeatherResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStoreWeatherResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreWeatherResponse} message ListStoreWeatherResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreWeatherResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeWeathers != null && message.storeWeathers.length)
                            for (var i = 0; i < message.storeWeathers.length; ++i)
                                $root.palexy.streaming.v1.StoreWeather.encode(message.storeWeathers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreWeatherResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStoreWeatherResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreWeatherResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStoreWeatherResponse} message ListStoreWeatherResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreWeatherResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreWeatherResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStoreWeatherResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStoreWeatherResponse} ListStoreWeatherResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreWeatherResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStoreWeatherResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.storeWeathers && message.storeWeathers.length))
                                    message.storeWeathers = [];
                                message.storeWeathers.push($root.palexy.streaming.v1.StoreWeather.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreWeatherResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStoreWeatherResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStoreWeatherResponse} ListStoreWeatherResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreWeatherResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreWeatherResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStoreWeatherResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreWeatherResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeWeathers != null && message.hasOwnProperty("storeWeathers")) {
                            if (!Array.isArray(message.storeWeathers))
                                return "storeWeathers: array expected";
                            for (var i = 0; i < message.storeWeathers.length; ++i) {
                                var error = $root.palexy.streaming.v1.StoreWeather.verify(message.storeWeathers[i]);
                                if (error)
                                    return "storeWeathers." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreWeatherResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStoreWeatherResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStoreWeatherResponse} ListStoreWeatherResponse
                     */
                    ListStoreWeatherResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStoreWeatherResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStoreWeatherResponse();
                        if (object.storeWeathers) {
                            if (!Array.isArray(object.storeWeathers))
                                throw TypeError(".palexy.streaming.v1.ListStoreWeatherResponse.storeWeathers: array expected");
                            message.storeWeathers = [];
                            for (var i = 0; i < object.storeWeathers.length; ++i) {
                                if (typeof object.storeWeathers[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStoreWeatherResponse.storeWeathers: object expected");
                                message.storeWeathers[i] = $root.palexy.streaming.v1.StoreWeather.fromObject(object.storeWeathers[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreWeatherResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStoreWeatherResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStoreWeatherResponse} message ListStoreWeatherResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreWeatherResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeWeathers = [];
                        if (message.storeWeathers && message.storeWeathers.length) {
                            object.storeWeathers = [];
                            for (var j = 0; j < message.storeWeathers.length; ++j)
                                object.storeWeathers[j] = $root.palexy.streaming.v1.StoreWeather.toObject(message.storeWeathers[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreWeatherResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStoreWeatherResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreWeatherResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreWeatherResponse;
                })();
    
                v1.ScheduledTaskEventService = (function() {
    
                    /**
                     * Constructs a new ScheduledTaskEventService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ScheduledTaskEventService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ScheduledTaskEventService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (ScheduledTaskEventService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ScheduledTaskEventService;
    
                    /**
                     * Creates new ScheduledTaskEventService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.ScheduledTaskEventService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ScheduledTaskEventService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ScheduledTaskEventService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledTaskEventService#createScheduledTaskEvent}.
                     * @memberof palexy.streaming.v1.ScheduledTaskEventService
                     * @typedef CreateScheduledTaskEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ScheduledTaskEvent} [response] ScheduledTaskEvent
                     */
    
                    /**
                     * Calls CreateScheduledTaskEvent.
                     * @function createScheduledTaskEvent
                     * @memberof palexy.streaming.v1.ScheduledTaskEventService
                     * @instance
                     * @param {palexy.streaming.v1.IScheduledTaskEvent} request ScheduledTaskEvent message or plain object
                     * @param {palexy.streaming.v1.ScheduledTaskEventService.CreateScheduledTaskEventCallback} callback Node-style callback called with the error, if any, and ScheduledTaskEvent
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledTaskEventService.prototype.createScheduledTaskEvent = function createScheduledTaskEvent(request, callback) {
                        return this.rpcCall(createScheduledTaskEvent, $root.palexy.streaming.v1.ScheduledTaskEvent, $root.palexy.streaming.v1.ScheduledTaskEvent, request, callback);
                    }, "name", { value: "CreateScheduledTaskEvent" });
    
                    /**
                     * Calls CreateScheduledTaskEvent.
                     * @function createScheduledTaskEvent
                     * @memberof palexy.streaming.v1.ScheduledTaskEventService
                     * @instance
                     * @param {palexy.streaming.v1.IScheduledTaskEvent} request ScheduledTaskEvent message or plain object
                     * @returns {Promise<palexy.streaming.v1.ScheduledTaskEvent>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ScheduledTaskEventService#listScheduledTaskEvents}.
                     * @memberof palexy.streaming.v1.ScheduledTaskEventService
                     * @typedef ListScheduledTaskEventsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListScheduledTaskEventResponse} [response] ListScheduledTaskEventResponse
                     */
    
                    /**
                     * Calls ListScheduledTaskEvents.
                     * @function listScheduledTaskEvents
                     * @memberof palexy.streaming.v1.ScheduledTaskEventService
                     * @instance
                     * @param {palexy.streaming.v1.IListScheduledTaskEventRequest} request ListScheduledTaskEventRequest message or plain object
                     * @param {palexy.streaming.v1.ScheduledTaskEventService.ListScheduledTaskEventsCallback} callback Node-style callback called with the error, if any, and ListScheduledTaskEventResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ScheduledTaskEventService.prototype.listScheduledTaskEvents = function listScheduledTaskEvents(request, callback) {
                        return this.rpcCall(listScheduledTaskEvents, $root.palexy.streaming.v1.ListScheduledTaskEventRequest, $root.palexy.streaming.v1.ListScheduledTaskEventResponse, request, callback);
                    }, "name", { value: "ListScheduledTaskEvents" });
    
                    /**
                     * Calls ListScheduledTaskEvents.
                     * @function listScheduledTaskEvents
                     * @memberof palexy.streaming.v1.ScheduledTaskEventService
                     * @instance
                     * @param {palexy.streaming.v1.IListScheduledTaskEventRequest} request ListScheduledTaskEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListScheduledTaskEventResponse>} Promise
                     * @variation 2
                     */
    
                    return ScheduledTaskEventService;
                })();
    
                v1.ScheduledTaskEvent = (function() {
    
                    /**
                     * Properties of a ScheduledTaskEvent.
                     * @memberof palexy.streaming.v1
                     * @interface IScheduledTaskEvent
                     * @property {number|null} [id] ScheduledTaskEvent id
                     * @property {palexy.streaming.v1.ScheduledTaskType|null} [scheduledTaskType] ScheduledTaskEvent scheduledTaskType
                     * @property {string|null} [eventDate] ScheduledTaskEvent eventDate
                     * @property {number|null} [companyId] ScheduledTaskEvent companyId
                     * @property {google.protobuf.IInt64Value|null} [storeId] ScheduledTaskEvent storeId
                     * @property {google.protobuf.IStringValue|null} [countryCode] ScheduledTaskEvent countryCode
                     * @property {number|null} [createTimeMs] ScheduledTaskEvent createTimeMs
                     */
    
                    /**
                     * Constructs a new ScheduledTaskEvent.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ScheduledTaskEvent.
                     * @implements IScheduledTaskEvent
                     * @constructor
                     * @param {palexy.streaming.v1.IScheduledTaskEvent=} [properties] Properties to set
                     */
                    function ScheduledTaskEvent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ScheduledTaskEvent id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @instance
                     */
                    ScheduledTaskEvent.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ScheduledTaskEvent scheduledTaskType.
                     * @member {palexy.streaming.v1.ScheduledTaskType} scheduledTaskType
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @instance
                     */
                    ScheduledTaskEvent.prototype.scheduledTaskType = 0;
    
                    /**
                     * ScheduledTaskEvent eventDate.
                     * @member {string} eventDate
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @instance
                     */
                    ScheduledTaskEvent.prototype.eventDate = "";
    
                    /**
                     * ScheduledTaskEvent companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @instance
                     */
                    ScheduledTaskEvent.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ScheduledTaskEvent storeId.
                     * @member {google.protobuf.IInt64Value|null|undefined} storeId
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @instance
                     */
                    ScheduledTaskEvent.prototype.storeId = null;
    
                    /**
                     * ScheduledTaskEvent countryCode.
                     * @member {google.protobuf.IStringValue|null|undefined} countryCode
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @instance
                     */
                    ScheduledTaskEvent.prototype.countryCode = null;
    
                    /**
                     * ScheduledTaskEvent createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @instance
                     */
                    ScheduledTaskEvent.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ScheduledTaskEvent instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @static
                     * @param {palexy.streaming.v1.IScheduledTaskEvent=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ScheduledTaskEvent} ScheduledTaskEvent instance
                     */
                    ScheduledTaskEvent.create = function create(properties) {
                        return new ScheduledTaskEvent(properties);
                    };
    
                    /**
                     * Encodes the specified ScheduledTaskEvent message. Does not implicitly {@link palexy.streaming.v1.ScheduledTaskEvent.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @static
                     * @param {palexy.streaming.v1.IScheduledTaskEvent} message ScheduledTaskEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScheduledTaskEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.scheduledTaskType != null && Object.hasOwnProperty.call(message, "scheduledTaskType"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.scheduledTaskType);
                        if (message.eventDate != null && Object.hasOwnProperty.call(message, "eventDate"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.eventDate);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.companyId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            $root.google.protobuf.Int64Value.encode(message.storeId, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createTimeMs);
                        if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode"))
                            $root.google.protobuf.StringValue.encode(message.countryCode, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ScheduledTaskEvent message, length delimited. Does not implicitly {@link palexy.streaming.v1.ScheduledTaskEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @static
                     * @param {palexy.streaming.v1.IScheduledTaskEvent} message ScheduledTaskEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ScheduledTaskEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ScheduledTaskEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ScheduledTaskEvent} ScheduledTaskEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScheduledTaskEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ScheduledTaskEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.scheduledTaskType = reader.int32();
                                break;
                            case 3:
                                message.eventDate = reader.string();
                                break;
                            case 4:
                                message.companyId = reader.int64();
                                break;
                            case 5:
                                message.storeId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.countryCode = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.createTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ScheduledTaskEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ScheduledTaskEvent} ScheduledTaskEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ScheduledTaskEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ScheduledTaskEvent message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ScheduledTaskEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.scheduledTaskType != null && message.hasOwnProperty("scheduledTaskType"))
                            switch (message.scheduledTaskType) {
                            default:
                                return "scheduledTaskType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.eventDate != null && message.hasOwnProperty("eventDate"))
                            if (!$util.isString(message.eventDate))
                                return "eventDate: string expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.storeId);
                            if (error)
                                return "storeId." + error;
                        }
                        if (message.countryCode != null && message.hasOwnProperty("countryCode")) {
                            var error = $root.google.protobuf.StringValue.verify(message.countryCode);
                            if (error)
                                return "countryCode." + error;
                        }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ScheduledTaskEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ScheduledTaskEvent} ScheduledTaskEvent
                     */
                    ScheduledTaskEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ScheduledTaskEvent)
                            return object;
                        var message = new $root.palexy.streaming.v1.ScheduledTaskEvent();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        switch (object.scheduledTaskType) {
                        case "SCHEDULED_TASK_TYPE_UNKNOWN":
                        case 0:
                            message.scheduledTaskType = 0;
                            break;
                        case "SCHEDULED_CREATE_WEEKLY_INSIGHTS_TASKS":
                        case 1:
                            message.scheduledTaskType = 1;
                            break;
                        case "SCHEDULED_CREATE_MONTHLY_INSIGHTS_TASKS":
                        case 2:
                            message.scheduledTaskType = 2;
                            break;
                        case "SCHEDULED_CREATE_MONTHLY_TREND_REPORT_TASKS":
                        case 3:
                            message.scheduledTaskType = 3;
                            break;
                        }
                        if (object.eventDate != null)
                            message.eventDate = String(object.eventDate);
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeId != null) {
                            if (typeof object.storeId !== "object")
                                throw TypeError(".palexy.streaming.v1.ScheduledTaskEvent.storeId: object expected");
                            message.storeId = $root.google.protobuf.Int64Value.fromObject(object.storeId);
                        }
                        if (object.countryCode != null) {
                            if (typeof object.countryCode !== "object")
                                throw TypeError(".palexy.streaming.v1.ScheduledTaskEvent.countryCode: object expected");
                            message.countryCode = $root.google.protobuf.StringValue.fromObject(object.countryCode);
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ScheduledTaskEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @static
                     * @param {palexy.streaming.v1.ScheduledTaskEvent} message ScheduledTaskEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ScheduledTaskEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.scheduledTaskType = options.enums === String ? "SCHEDULED_TASK_TYPE_UNKNOWN" : 0;
                            object.eventDate = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.storeId = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            object.countryCode = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.scheduledTaskType != null && message.hasOwnProperty("scheduledTaskType"))
                            object.scheduledTaskType = options.enums === String ? $root.palexy.streaming.v1.ScheduledTaskType[message.scheduledTaskType] : message.scheduledTaskType;
                        if (message.eventDate != null && message.hasOwnProperty("eventDate"))
                            object.eventDate = message.eventDate;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = $root.google.protobuf.Int64Value.toObject(message.storeId, options);
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                            object.countryCode = $root.google.protobuf.StringValue.toObject(message.countryCode, options);
                        return object;
                    };
    
                    /**
                     * Converts this ScheduledTaskEvent to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ScheduledTaskEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ScheduledTaskEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ScheduledTaskEvent;
                })();
    
                v1.ListScheduledTaskEventRequest = (function() {
    
                    /**
                     * Properties of a ListScheduledTaskEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListScheduledTaskEventRequest
                     * @property {palexy.streaming.v1.ScheduledTaskType|null} [scheduledTaskType] ListScheduledTaskEventRequest scheduledTaskType
                     * @property {string|null} [eventStartDateId] ListScheduledTaskEventRequest eventStartDateId
                     * @property {string|null} [eventEndDateId] ListScheduledTaskEventRequest eventEndDateId
                     * @property {number|null} [pageSize] ListScheduledTaskEventRequest pageSize
                     * @property {string|null} [pageToken] ListScheduledTaskEventRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListScheduledTaskEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListScheduledTaskEventRequest.
                     * @implements IListScheduledTaskEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListScheduledTaskEventRequest=} [properties] Properties to set
                     */
                    function ListScheduledTaskEventRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListScheduledTaskEventRequest scheduledTaskType.
                     * @member {palexy.streaming.v1.ScheduledTaskType} scheduledTaskType
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @instance
                     */
                    ListScheduledTaskEventRequest.prototype.scheduledTaskType = 0;
    
                    /**
                     * ListScheduledTaskEventRequest eventStartDateId.
                     * @member {string} eventStartDateId
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @instance
                     */
                    ListScheduledTaskEventRequest.prototype.eventStartDateId = "";
    
                    /**
                     * ListScheduledTaskEventRequest eventEndDateId.
                     * @member {string} eventEndDateId
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @instance
                     */
                    ListScheduledTaskEventRequest.prototype.eventEndDateId = "";
    
                    /**
                     * ListScheduledTaskEventRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @instance
                     */
                    ListScheduledTaskEventRequest.prototype.pageSize = 0;
    
                    /**
                     * ListScheduledTaskEventRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @instance
                     */
                    ListScheduledTaskEventRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListScheduledTaskEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IListScheduledTaskEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListScheduledTaskEventRequest} ListScheduledTaskEventRequest instance
                     */
                    ListScheduledTaskEventRequest.create = function create(properties) {
                        return new ListScheduledTaskEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListScheduledTaskEventRequest message. Does not implicitly {@link palexy.streaming.v1.ListScheduledTaskEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IListScheduledTaskEventRequest} message ListScheduledTaskEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListScheduledTaskEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.scheduledTaskType != null && Object.hasOwnProperty.call(message, "scheduledTaskType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.scheduledTaskType);
                        if (message.eventStartDateId != null && Object.hasOwnProperty.call(message, "eventStartDateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.eventStartDateId);
                        if (message.eventEndDateId != null && Object.hasOwnProperty.call(message, "eventEndDateId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.eventEndDateId);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListScheduledTaskEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListScheduledTaskEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IListScheduledTaskEventRequest} message ListScheduledTaskEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListScheduledTaskEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListScheduledTaskEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListScheduledTaskEventRequest} ListScheduledTaskEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListScheduledTaskEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListScheduledTaskEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.scheduledTaskType = reader.int32();
                                break;
                            case 2:
                                message.eventStartDateId = reader.string();
                                break;
                            case 3:
                                message.eventEndDateId = reader.string();
                                break;
                            case 4:
                                message.pageSize = reader.int32();
                                break;
                            case 5:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListScheduledTaskEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListScheduledTaskEventRequest} ListScheduledTaskEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListScheduledTaskEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListScheduledTaskEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListScheduledTaskEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.scheduledTaskType != null && message.hasOwnProperty("scheduledTaskType"))
                            switch (message.scheduledTaskType) {
                            default:
                                return "scheduledTaskType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.eventStartDateId != null && message.hasOwnProperty("eventStartDateId"))
                            if (!$util.isString(message.eventStartDateId))
                                return "eventStartDateId: string expected";
                        if (message.eventEndDateId != null && message.hasOwnProperty("eventEndDateId"))
                            if (!$util.isString(message.eventEndDateId))
                                return "eventEndDateId: string expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListScheduledTaskEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListScheduledTaskEventRequest} ListScheduledTaskEventRequest
                     */
                    ListScheduledTaskEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListScheduledTaskEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListScheduledTaskEventRequest();
                        switch (object.scheduledTaskType) {
                        case "SCHEDULED_TASK_TYPE_UNKNOWN":
                        case 0:
                            message.scheduledTaskType = 0;
                            break;
                        case "SCHEDULED_CREATE_WEEKLY_INSIGHTS_TASKS":
                        case 1:
                            message.scheduledTaskType = 1;
                            break;
                        case "SCHEDULED_CREATE_MONTHLY_INSIGHTS_TASKS":
                        case 2:
                            message.scheduledTaskType = 2;
                            break;
                        case "SCHEDULED_CREATE_MONTHLY_TREND_REPORT_TASKS":
                        case 3:
                            message.scheduledTaskType = 3;
                            break;
                        }
                        if (object.eventStartDateId != null)
                            message.eventStartDateId = String(object.eventStartDateId);
                        if (object.eventEndDateId != null)
                            message.eventEndDateId = String(object.eventEndDateId);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListScheduledTaskEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ListScheduledTaskEventRequest} message ListScheduledTaskEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListScheduledTaskEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.scheduledTaskType = options.enums === String ? "SCHEDULED_TASK_TYPE_UNKNOWN" : 0;
                            object.eventStartDateId = "";
                            object.eventEndDateId = "";
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.scheduledTaskType != null && message.hasOwnProperty("scheduledTaskType"))
                            object.scheduledTaskType = options.enums === String ? $root.palexy.streaming.v1.ScheduledTaskType[message.scheduledTaskType] : message.scheduledTaskType;
                        if (message.eventStartDateId != null && message.hasOwnProperty("eventStartDateId"))
                            object.eventStartDateId = message.eventStartDateId;
                        if (message.eventEndDateId != null && message.hasOwnProperty("eventEndDateId"))
                            object.eventEndDateId = message.eventEndDateId;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListScheduledTaskEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListScheduledTaskEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListScheduledTaskEventRequest;
                })();
    
                v1.ListScheduledTaskEventResponse = (function() {
    
                    /**
                     * Properties of a ListScheduledTaskEventResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListScheduledTaskEventResponse
                     * @property {Array.<palexy.streaming.v1.IScheduledTaskEvent>|null} [events] ListScheduledTaskEventResponse events
                     * @property {number|null} [totalElements] ListScheduledTaskEventResponse totalElements
                     * @property {string|null} [nextPageToken] ListScheduledTaskEventResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListScheduledTaskEventResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListScheduledTaskEventResponse.
                     * @implements IListScheduledTaskEventResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListScheduledTaskEventResponse=} [properties] Properties to set
                     */
                    function ListScheduledTaskEventResponse(properties) {
                        this.events = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListScheduledTaskEventResponse events.
                     * @member {Array.<palexy.streaming.v1.IScheduledTaskEvent>} events
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @instance
                     */
                    ListScheduledTaskEventResponse.prototype.events = $util.emptyArray;
    
                    /**
                     * ListScheduledTaskEventResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @instance
                     */
                    ListScheduledTaskEventResponse.prototype.totalElements = 0;
    
                    /**
                     * ListScheduledTaskEventResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @instance
                     */
                    ListScheduledTaskEventResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListScheduledTaskEventResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IListScheduledTaskEventResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListScheduledTaskEventResponse} ListScheduledTaskEventResponse instance
                     */
                    ListScheduledTaskEventResponse.create = function create(properties) {
                        return new ListScheduledTaskEventResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListScheduledTaskEventResponse message. Does not implicitly {@link palexy.streaming.v1.ListScheduledTaskEventResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IListScheduledTaskEventResponse} message ListScheduledTaskEventResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListScheduledTaskEventResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.events != null && message.events.length)
                            for (var i = 0; i < message.events.length; ++i)
                                $root.palexy.streaming.v1.ScheduledTaskEvent.encode(message.events[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListScheduledTaskEventResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListScheduledTaskEventResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IListScheduledTaskEventResponse} message ListScheduledTaskEventResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListScheduledTaskEventResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListScheduledTaskEventResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListScheduledTaskEventResponse} ListScheduledTaskEventResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListScheduledTaskEventResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListScheduledTaskEventResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.events && message.events.length))
                                    message.events = [];
                                message.events.push($root.palexy.streaming.v1.ScheduledTaskEvent.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListScheduledTaskEventResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListScheduledTaskEventResponse} ListScheduledTaskEventResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListScheduledTaskEventResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListScheduledTaskEventResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListScheduledTaskEventResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.events != null && message.hasOwnProperty("events")) {
                            if (!Array.isArray(message.events))
                                return "events: array expected";
                            for (var i = 0; i < message.events.length; ++i) {
                                var error = $root.palexy.streaming.v1.ScheduledTaskEvent.verify(message.events[i]);
                                if (error)
                                    return "events." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListScheduledTaskEventResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListScheduledTaskEventResponse} ListScheduledTaskEventResponse
                     */
                    ListScheduledTaskEventResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListScheduledTaskEventResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListScheduledTaskEventResponse();
                        if (object.events) {
                            if (!Array.isArray(object.events))
                                throw TypeError(".palexy.streaming.v1.ListScheduledTaskEventResponse.events: array expected");
                            message.events = [];
                            for (var i = 0; i < object.events.length; ++i) {
                                if (typeof object.events[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListScheduledTaskEventResponse.events: object expected");
                                message.events[i] = $root.palexy.streaming.v1.ScheduledTaskEvent.fromObject(object.events[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListScheduledTaskEventResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @static
                     * @param {palexy.streaming.v1.ListScheduledTaskEventResponse} message ListScheduledTaskEventResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListScheduledTaskEventResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.events = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.events && message.events.length) {
                            object.events = [];
                            for (var j = 0; j < message.events.length; ++j)
                                object.events[j] = $root.palexy.streaming.v1.ScheduledTaskEvent.toObject(message.events[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListScheduledTaskEventResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListScheduledTaskEventResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListScheduledTaskEventResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListScheduledTaskEventResponse;
                })();
    
                /**
                 * ScheduledTaskType enum.
                 * @name palexy.streaming.v1.ScheduledTaskType
                 * @enum {number}
                 * @property {number} SCHEDULED_TASK_TYPE_UNKNOWN=0 SCHEDULED_TASK_TYPE_UNKNOWN value
                 * @property {number} SCHEDULED_CREATE_WEEKLY_INSIGHTS_TASKS=1 SCHEDULED_CREATE_WEEKLY_INSIGHTS_TASKS value
                 * @property {number} SCHEDULED_CREATE_MONTHLY_INSIGHTS_TASKS=2 SCHEDULED_CREATE_MONTHLY_INSIGHTS_TASKS value
                 * @property {number} SCHEDULED_CREATE_MONTHLY_TREND_REPORT_TASKS=3 SCHEDULED_CREATE_MONTHLY_TREND_REPORT_TASKS value
                 */
                v1.ScheduledTaskType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SCHEDULED_TASK_TYPE_UNKNOWN"] = 0;
                    values[valuesById[1] = "SCHEDULED_CREATE_WEEKLY_INSIGHTS_TASKS"] = 1;
                    values[valuesById[2] = "SCHEDULED_CREATE_MONTHLY_INSIGHTS_TASKS"] = 2;
                    values[valuesById[3] = "SCHEDULED_CREATE_MONTHLY_TREND_REPORT_TASKS"] = 3;
                    return values;
                })();
    
                v1.InsightUnderlyingDataChangedEventService = (function() {
    
                    /**
                     * Constructs a new InsightUnderlyingDataChangedEventService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an InsightUnderlyingDataChangedEventService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function InsightUnderlyingDataChangedEventService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (InsightUnderlyingDataChangedEventService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = InsightUnderlyingDataChangedEventService;
    
                    /**
                     * Creates new InsightUnderlyingDataChangedEventService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {InsightUnderlyingDataChangedEventService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    InsightUnderlyingDataChangedEventService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.InsightUnderlyingDataChangedEventService#listInsightUnderlyingDataChangedEvent}.
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @typedef ListInsightUnderlyingDataChangedEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse} [response] ListInsightUnderlyingDataChangedEventResponse
                     */
    
                    /**
                     * Calls ListInsightUnderlyingDataChangedEvent.
                     * @function listInsightUnderlyingDataChangedEvent
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.IListInsightUnderlyingDataChangedEventRequest} request ListInsightUnderlyingDataChangedEventRequest message or plain object
                     * @param {palexy.streaming.v1.InsightUnderlyingDataChangedEventService.ListInsightUnderlyingDataChangedEventCallback} callback Node-style callback called with the error, if any, and ListInsightUnderlyingDataChangedEventResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightUnderlyingDataChangedEventService.prototype.listInsightUnderlyingDataChangedEvent = function listInsightUnderlyingDataChangedEvent(request, callback) {
                        return this.rpcCall(listInsightUnderlyingDataChangedEvent, $root.palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest, $root.palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse, request, callback);
                    }, "name", { value: "ListInsightUnderlyingDataChangedEvent" });
    
                    /**
                     * Calls ListInsightUnderlyingDataChangedEvent.
                     * @function listInsightUnderlyingDataChangedEvent
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.IListInsightUnderlyingDataChangedEventRequest} request ListInsightUnderlyingDataChangedEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.InsightUnderlyingDataChangedEventService#createInsightUnderlyingDataChangedEvent}.
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @typedef CreateInsightUnderlyingDataChangedEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.InsightUnderlyingDataChangedEvent} [response] InsightUnderlyingDataChangedEvent
                     */
    
                    /**
                     * Calls CreateInsightUnderlyingDataChangedEvent.
                     * @function createInsightUnderlyingDataChangedEvent
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateInsightUnderlyingDataChangedEventRequest} request CreateInsightUnderlyingDataChangedEventRequest message or plain object
                     * @param {palexy.streaming.v1.InsightUnderlyingDataChangedEventService.CreateInsightUnderlyingDataChangedEventCallback} callback Node-style callback called with the error, if any, and InsightUnderlyingDataChangedEvent
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightUnderlyingDataChangedEventService.prototype.createInsightUnderlyingDataChangedEvent = function createInsightUnderlyingDataChangedEvent(request, callback) {
                        return this.rpcCall(createInsightUnderlyingDataChangedEvent, $root.palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest, $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent, request, callback);
                    }, "name", { value: "CreateInsightUnderlyingDataChangedEvent" });
    
                    /**
                     * Calls CreateInsightUnderlyingDataChangedEvent.
                     * @function createInsightUnderlyingDataChangedEvent
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateInsightUnderlyingDataChangedEventRequest} request CreateInsightUnderlyingDataChangedEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.InsightUnderlyingDataChangedEvent>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.InsightUnderlyingDataChangedEventService#batchCompleteInsightUnderlyingDataChangedEvent}.
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @typedef BatchCompleteInsightUnderlyingDataChangedEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls BatchCompleteInsightUnderlyingDataChangedEvent.
                     * @function batchCompleteInsightUnderlyingDataChangedEvent
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCompleteInsightUnderlyingDataChangedEventRequest} request BatchCompleteInsightUnderlyingDataChangedEventRequest message or plain object
                     * @param {palexy.streaming.v1.InsightUnderlyingDataChangedEventService.BatchCompleteInsightUnderlyingDataChangedEventCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightUnderlyingDataChangedEventService.prototype.batchCompleteInsightUnderlyingDataChangedEvent = function batchCompleteInsightUnderlyingDataChangedEvent(request, callback) {
                        return this.rpcCall(batchCompleteInsightUnderlyingDataChangedEvent, $root.palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "BatchCompleteInsightUnderlyingDataChangedEvent" });
    
                    /**
                     * Calls BatchCompleteInsightUnderlyingDataChangedEvent.
                     * @function batchCompleteInsightUnderlyingDataChangedEvent
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchCompleteInsightUnderlyingDataChangedEventRequest} request BatchCompleteInsightUnderlyingDataChangedEventRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.InsightUnderlyingDataChangedEventService#processUnderlyingDataChangedEvent}.
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @typedef ProcessUnderlyingDataChangedEventCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse} [response] ProcessUnderlyingDataChangedEventResponse
                     */
    
                    /**
                     * Calls ProcessUnderlyingDataChangedEvent.
                     * @function processUnderlyingDataChangedEvent
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.IProcessUnderlyingDataChangedEventRequest} request ProcessUnderlyingDataChangedEventRequest message or plain object
                     * @param {palexy.streaming.v1.InsightUnderlyingDataChangedEventService.ProcessUnderlyingDataChangedEventCallback} callback Node-style callback called with the error, if any, and ProcessUnderlyingDataChangedEventResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightUnderlyingDataChangedEventService.prototype.processUnderlyingDataChangedEvent = function processUnderlyingDataChangedEvent(request, callback) {
                        return this.rpcCall(processUnderlyingDataChangedEvent, $root.palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest, $root.palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse, request, callback);
                    }, "name", { value: "ProcessUnderlyingDataChangedEvent" });
    
                    /**
                     * Calls ProcessUnderlyingDataChangedEvent.
                     * @function processUnderlyingDataChangedEvent
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEventService
                     * @instance
                     * @param {palexy.streaming.v1.IProcessUnderlyingDataChangedEventRequest} request ProcessUnderlyingDataChangedEventRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse>} Promise
                     * @variation 2
                     */
    
                    return InsightUnderlyingDataChangedEventService;
                })();
    
                v1.InsightUnderlyingDataChangedEvent = (function() {
    
                    /**
                     * Properties of an InsightUnderlyingDataChangedEvent.
                     * @memberof palexy.streaming.v1
                     * @interface IInsightUnderlyingDataChangedEvent
                     * @property {number|null} [id] InsightUnderlyingDataChangedEvent id
                     * @property {number|null} [companyId] InsightUnderlyingDataChangedEvent companyId
                     * @property {Array.<number>|null} [storeIds] InsightUnderlyingDataChangedEvent storeIds
                     * @property {number|null} [startDateId] InsightUnderlyingDataChangedEvent startDateId
                     * @property {number|null} [endDateId] InsightUnderlyingDataChangedEvent endDateId
                     * @property {palexy.streaming.v1.DataSource|null} [dataSource] InsightUnderlyingDataChangedEvent dataSource
                     * @property {Array.<number>|null} [asyncJobDependencyIds] InsightUnderlyingDataChangedEvent asyncJobDependencyIds
                     * @property {boolean|null} [processed] InsightUnderlyingDataChangedEvent processed
                     * @property {number|null} [createTimeMs] InsightUnderlyingDataChangedEvent createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] InsightUnderlyingDataChangedEvent lastUpdateTimeMs
                     */
    
                    /**
                     * Constructs a new InsightUnderlyingDataChangedEvent.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an InsightUnderlyingDataChangedEvent.
                     * @implements IInsightUnderlyingDataChangedEvent
                     * @constructor
                     * @param {palexy.streaming.v1.IInsightUnderlyingDataChangedEvent=} [properties] Properties to set
                     */
                    function InsightUnderlyingDataChangedEvent(properties) {
                        this.storeIds = [];
                        this.asyncJobDependencyIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * InsightUnderlyingDataChangedEvent id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @instance
                     */
                    InsightUnderlyingDataChangedEvent.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * InsightUnderlyingDataChangedEvent companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @instance
                     */
                    InsightUnderlyingDataChangedEvent.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * InsightUnderlyingDataChangedEvent storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @instance
                     */
                    InsightUnderlyingDataChangedEvent.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * InsightUnderlyingDataChangedEvent startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @instance
                     */
                    InsightUnderlyingDataChangedEvent.prototype.startDateId = 0;
    
                    /**
                     * InsightUnderlyingDataChangedEvent endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @instance
                     */
                    InsightUnderlyingDataChangedEvent.prototype.endDateId = 0;
    
                    /**
                     * InsightUnderlyingDataChangedEvent dataSource.
                     * @member {palexy.streaming.v1.DataSource} dataSource
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @instance
                     */
                    InsightUnderlyingDataChangedEvent.prototype.dataSource = 0;
    
                    /**
                     * InsightUnderlyingDataChangedEvent asyncJobDependencyIds.
                     * @member {Array.<number>} asyncJobDependencyIds
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @instance
                     */
                    InsightUnderlyingDataChangedEvent.prototype.asyncJobDependencyIds = $util.emptyArray;
    
                    /**
                     * InsightUnderlyingDataChangedEvent processed.
                     * @member {boolean} processed
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @instance
                     */
                    InsightUnderlyingDataChangedEvent.prototype.processed = false;
    
                    /**
                     * InsightUnderlyingDataChangedEvent createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @instance
                     */
                    InsightUnderlyingDataChangedEvent.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * InsightUnderlyingDataChangedEvent lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @instance
                     */
                    InsightUnderlyingDataChangedEvent.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new InsightUnderlyingDataChangedEvent instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @static
                     * @param {palexy.streaming.v1.IInsightUnderlyingDataChangedEvent=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.InsightUnderlyingDataChangedEvent} InsightUnderlyingDataChangedEvent instance
                     */
                    InsightUnderlyingDataChangedEvent.create = function create(properties) {
                        return new InsightUnderlyingDataChangedEvent(properties);
                    };
    
                    /**
                     * Encodes the specified InsightUnderlyingDataChangedEvent message. Does not implicitly {@link palexy.streaming.v1.InsightUnderlyingDataChangedEvent.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @static
                     * @param {palexy.streaming.v1.IInsightUnderlyingDataChangedEvent} message InsightUnderlyingDataChangedEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InsightUnderlyingDataChangedEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.endDateId);
                        if (message.dataSource != null && Object.hasOwnProperty.call(message, "dataSource"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.dataSource);
                        if (message.asyncJobDependencyIds != null && message.asyncJobDependencyIds.length) {
                            writer.uint32(/* id 7, wireType 2 =*/58).fork();
                            for (var i = 0; i < message.asyncJobDependencyIds.length; ++i)
                                writer.int64(message.asyncJobDependencyIds[i]);
                            writer.ldelim();
                        }
                        if (message.processed != null && Object.hasOwnProperty.call(message, "processed"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.processed);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int64(message.lastUpdateTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified InsightUnderlyingDataChangedEvent message, length delimited. Does not implicitly {@link palexy.streaming.v1.InsightUnderlyingDataChangedEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @static
                     * @param {palexy.streaming.v1.IInsightUnderlyingDataChangedEvent} message InsightUnderlyingDataChangedEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InsightUnderlyingDataChangedEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an InsightUnderlyingDataChangedEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.InsightUnderlyingDataChangedEvent} InsightUnderlyingDataChangedEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InsightUnderlyingDataChangedEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 4:
                                message.startDateId = reader.int32();
                                break;
                            case 5:
                                message.endDateId = reader.int32();
                                break;
                            case 6:
                                message.dataSource = reader.int32();
                                break;
                            case 7:
                                if (!(message.asyncJobDependencyIds && message.asyncJobDependencyIds.length))
                                    message.asyncJobDependencyIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.asyncJobDependencyIds.push(reader.int64());
                                } else
                                    message.asyncJobDependencyIds.push(reader.int64());
                                break;
                            case 8:
                                message.processed = reader.bool();
                                break;
                            case 9:
                                message.createTimeMs = reader.int64();
                                break;
                            case 10:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an InsightUnderlyingDataChangedEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.InsightUnderlyingDataChangedEvent} InsightUnderlyingDataChangedEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InsightUnderlyingDataChangedEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an InsightUnderlyingDataChangedEvent message.
                     * @function verify
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    InsightUnderlyingDataChangedEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        if (message.dataSource != null && message.hasOwnProperty("dataSource"))
                            switch (message.dataSource) {
                            default:
                                return "dataSource: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        if (message.asyncJobDependencyIds != null && message.hasOwnProperty("asyncJobDependencyIds")) {
                            if (!Array.isArray(message.asyncJobDependencyIds))
                                return "asyncJobDependencyIds: array expected";
                            for (var i = 0; i < message.asyncJobDependencyIds.length; ++i)
                                if (!$util.isInteger(message.asyncJobDependencyIds[i]) && !(message.asyncJobDependencyIds[i] && $util.isInteger(message.asyncJobDependencyIds[i].low) && $util.isInteger(message.asyncJobDependencyIds[i].high)))
                                    return "asyncJobDependencyIds: integer|Long[] expected";
                        }
                        if (message.processed != null && message.hasOwnProperty("processed"))
                            if (typeof message.processed !== "boolean")
                                return "processed: boolean expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an InsightUnderlyingDataChangedEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.InsightUnderlyingDataChangedEvent} InsightUnderlyingDataChangedEvent
                     */
                    InsightUnderlyingDataChangedEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent)
                            return object;
                        var message = new $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.streaming.v1.InsightUnderlyingDataChangedEvent.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        switch (object.dataSource) {
                        case "DS_UNKNOWN":
                        case 0:
                            message.dataSource = 0;
                            break;
                        case "VIDEO":
                        case 1:
                            message.dataSource = 1;
                            break;
                        case "POS":
                        case 2:
                            message.dataSource = 2;
                            break;
                        case "STAFFING":
                        case 3:
                            message.dataSource = 3;
                            break;
                        case "FORECAST":
                        case 4:
                            message.dataSource = 4;
                            break;
                        case "TARGET":
                        case 5:
                            message.dataSource = 5;
                            break;
                        }
                        if (object.asyncJobDependencyIds) {
                            if (!Array.isArray(object.asyncJobDependencyIds))
                                throw TypeError(".palexy.streaming.v1.InsightUnderlyingDataChangedEvent.asyncJobDependencyIds: array expected");
                            message.asyncJobDependencyIds = [];
                            for (var i = 0; i < object.asyncJobDependencyIds.length; ++i)
                                if ($util.Long)
                                    (message.asyncJobDependencyIds[i] = $util.Long.fromValue(object.asyncJobDependencyIds[i])).unsigned = false;
                                else if (typeof object.asyncJobDependencyIds[i] === "string")
                                    message.asyncJobDependencyIds[i] = parseInt(object.asyncJobDependencyIds[i], 10);
                                else if (typeof object.asyncJobDependencyIds[i] === "number")
                                    message.asyncJobDependencyIds[i] = object.asyncJobDependencyIds[i];
                                else if (typeof object.asyncJobDependencyIds[i] === "object")
                                    message.asyncJobDependencyIds[i] = new $util.LongBits(object.asyncJobDependencyIds[i].low >>> 0, object.asyncJobDependencyIds[i].high >>> 0).toNumber();
                        }
                        if (object.processed != null)
                            message.processed = Boolean(object.processed);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an InsightUnderlyingDataChangedEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @static
                     * @param {palexy.streaming.v1.InsightUnderlyingDataChangedEvent} message InsightUnderlyingDataChangedEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    InsightUnderlyingDataChangedEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.storeIds = [];
                            object.asyncJobDependencyIds = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.startDateId = 0;
                            object.endDateId = 0;
                            object.dataSource = options.enums === String ? "DS_UNKNOWN" : 0;
                            object.processed = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.dataSource != null && message.hasOwnProperty("dataSource"))
                            object.dataSource = options.enums === String ? $root.palexy.streaming.v1.DataSource[message.dataSource] : message.dataSource;
                        if (message.asyncJobDependencyIds && message.asyncJobDependencyIds.length) {
                            object.asyncJobDependencyIds = [];
                            for (var j = 0; j < message.asyncJobDependencyIds.length; ++j)
                                if (typeof message.asyncJobDependencyIds[j] === "number")
                                    object.asyncJobDependencyIds[j] = options.longs === String ? String(message.asyncJobDependencyIds[j]) : message.asyncJobDependencyIds[j];
                                else
                                    object.asyncJobDependencyIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.asyncJobDependencyIds[j]) : options.longs === Number ? new $util.LongBits(message.asyncJobDependencyIds[j].low >>> 0, message.asyncJobDependencyIds[j].high >>> 0).toNumber() : message.asyncJobDependencyIds[j];
                        }
                        if (message.processed != null && message.hasOwnProperty("processed"))
                            object.processed = message.processed;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this InsightUnderlyingDataChangedEvent to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.InsightUnderlyingDataChangedEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    InsightUnderlyingDataChangedEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return InsightUnderlyingDataChangedEvent;
                })();
    
                v1.ListInsightUnderlyingDataChangedEventRequest = (function() {
    
                    /**
                     * Properties of a ListInsightUnderlyingDataChangedEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListInsightUnderlyingDataChangedEventRequest
                     * @property {google.protobuf.IInt64Value|null} [companyId] ListInsightUnderlyingDataChangedEventRequest companyId
                     * @property {google.protobuf.IBoolValue|null} [processed] ListInsightUnderlyingDataChangedEventRequest processed
                     * @property {boolean|null} [dependenciesReadyOnly] ListInsightUnderlyingDataChangedEventRequest dependenciesReadyOnly
                     * @property {number|null} [pageSize] ListInsightUnderlyingDataChangedEventRequest pageSize
                     * @property {string|null} [pageToken] ListInsightUnderlyingDataChangedEventRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListInsightUnderlyingDataChangedEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListInsightUnderlyingDataChangedEventRequest.
                     * @implements IListInsightUnderlyingDataChangedEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListInsightUnderlyingDataChangedEventRequest=} [properties] Properties to set
                     */
                    function ListInsightUnderlyingDataChangedEventRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListInsightUnderlyingDataChangedEventRequest companyId.
                     * @member {google.protobuf.IInt64Value|null|undefined} companyId
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @instance
                     */
                    ListInsightUnderlyingDataChangedEventRequest.prototype.companyId = null;
    
                    /**
                     * ListInsightUnderlyingDataChangedEventRequest processed.
                     * @member {google.protobuf.IBoolValue|null|undefined} processed
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @instance
                     */
                    ListInsightUnderlyingDataChangedEventRequest.prototype.processed = null;
    
                    /**
                     * ListInsightUnderlyingDataChangedEventRequest dependenciesReadyOnly.
                     * @member {boolean} dependenciesReadyOnly
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @instance
                     */
                    ListInsightUnderlyingDataChangedEventRequest.prototype.dependenciesReadyOnly = false;
    
                    /**
                     * ListInsightUnderlyingDataChangedEventRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @instance
                     */
                    ListInsightUnderlyingDataChangedEventRequest.prototype.pageSize = 0;
    
                    /**
                     * ListInsightUnderlyingDataChangedEventRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @instance
                     */
                    ListInsightUnderlyingDataChangedEventRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListInsightUnderlyingDataChangedEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IListInsightUnderlyingDataChangedEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest} ListInsightUnderlyingDataChangedEventRequest instance
                     */
                    ListInsightUnderlyingDataChangedEventRequest.create = function create(properties) {
                        return new ListInsightUnderlyingDataChangedEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListInsightUnderlyingDataChangedEventRequest message. Does not implicitly {@link palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IListInsightUnderlyingDataChangedEventRequest} message ListInsightUnderlyingDataChangedEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListInsightUnderlyingDataChangedEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            $root.google.protobuf.Int64Value.encode(message.companyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.processed != null && Object.hasOwnProperty.call(message, "processed"))
                            $root.google.protobuf.BoolValue.encode(message.processed, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.dependenciesReadyOnly != null && Object.hasOwnProperty.call(message, "dependenciesReadyOnly"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.dependenciesReadyOnly);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListInsightUnderlyingDataChangedEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IListInsightUnderlyingDataChangedEventRequest} message ListInsightUnderlyingDataChangedEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListInsightUnderlyingDataChangedEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListInsightUnderlyingDataChangedEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest} ListInsightUnderlyingDataChangedEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListInsightUnderlyingDataChangedEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.processed = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.dependenciesReadyOnly = reader.bool();
                                break;
                            case 4:
                                message.pageSize = reader.int32();
                                break;
                            case 5:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListInsightUnderlyingDataChangedEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest} ListInsightUnderlyingDataChangedEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListInsightUnderlyingDataChangedEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListInsightUnderlyingDataChangedEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListInsightUnderlyingDataChangedEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.companyId);
                            if (error)
                                return "companyId." + error;
                        }
                        if (message.processed != null && message.hasOwnProperty("processed")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.processed);
                            if (error)
                                return "processed." + error;
                        }
                        if (message.dependenciesReadyOnly != null && message.hasOwnProperty("dependenciesReadyOnly"))
                            if (typeof message.dependenciesReadyOnly !== "boolean")
                                return "dependenciesReadyOnly: boolean expected";
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListInsightUnderlyingDataChangedEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest} ListInsightUnderlyingDataChangedEventRequest
                     */
                    ListInsightUnderlyingDataChangedEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest();
                        if (object.companyId != null) {
                            if (typeof object.companyId !== "object")
                                throw TypeError(".palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest.companyId: object expected");
                            message.companyId = $root.google.protobuf.Int64Value.fromObject(object.companyId);
                        }
                        if (object.processed != null) {
                            if (typeof object.processed !== "object")
                                throw TypeError(".palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest.processed: object expected");
                            message.processed = $root.google.protobuf.BoolValue.fromObject(object.processed);
                        }
                        if (object.dependenciesReadyOnly != null)
                            message.dependenciesReadyOnly = Boolean(object.dependenciesReadyOnly);
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListInsightUnderlyingDataChangedEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest} message ListInsightUnderlyingDataChangedEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListInsightUnderlyingDataChangedEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.companyId = null;
                            object.processed = null;
                            object.dependenciesReadyOnly = false;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            object.companyId = $root.google.protobuf.Int64Value.toObject(message.companyId, options);
                        if (message.processed != null && message.hasOwnProperty("processed"))
                            object.processed = $root.google.protobuf.BoolValue.toObject(message.processed, options);
                        if (message.dependenciesReadyOnly != null && message.hasOwnProperty("dependenciesReadyOnly"))
                            object.dependenciesReadyOnly = message.dependenciesReadyOnly;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListInsightUnderlyingDataChangedEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListInsightUnderlyingDataChangedEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListInsightUnderlyingDataChangedEventRequest;
                })();
    
                v1.ListInsightUnderlyingDataChangedEventResponse = (function() {
    
                    /**
                     * Properties of a ListInsightUnderlyingDataChangedEventResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListInsightUnderlyingDataChangedEventResponse
                     * @property {Array.<palexy.streaming.v1.IInsightUnderlyingDataChangedEvent>|null} [events] ListInsightUnderlyingDataChangedEventResponse events
                     * @property {number|null} [totalElements] ListInsightUnderlyingDataChangedEventResponse totalElements
                     * @property {string|null} [nextPageToken] ListInsightUnderlyingDataChangedEventResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListInsightUnderlyingDataChangedEventResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListInsightUnderlyingDataChangedEventResponse.
                     * @implements IListInsightUnderlyingDataChangedEventResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListInsightUnderlyingDataChangedEventResponse=} [properties] Properties to set
                     */
                    function ListInsightUnderlyingDataChangedEventResponse(properties) {
                        this.events = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListInsightUnderlyingDataChangedEventResponse events.
                     * @member {Array.<palexy.streaming.v1.IInsightUnderlyingDataChangedEvent>} events
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @instance
                     */
                    ListInsightUnderlyingDataChangedEventResponse.prototype.events = $util.emptyArray;
    
                    /**
                     * ListInsightUnderlyingDataChangedEventResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @instance
                     */
                    ListInsightUnderlyingDataChangedEventResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListInsightUnderlyingDataChangedEventResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @instance
                     */
                    ListInsightUnderlyingDataChangedEventResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListInsightUnderlyingDataChangedEventResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IListInsightUnderlyingDataChangedEventResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse} ListInsightUnderlyingDataChangedEventResponse instance
                     */
                    ListInsightUnderlyingDataChangedEventResponse.create = function create(properties) {
                        return new ListInsightUnderlyingDataChangedEventResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListInsightUnderlyingDataChangedEventResponse message. Does not implicitly {@link palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IListInsightUnderlyingDataChangedEventResponse} message ListInsightUnderlyingDataChangedEventResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListInsightUnderlyingDataChangedEventResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.events != null && message.events.length)
                            for (var i = 0; i < message.events.length; ++i)
                                $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent.encode(message.events[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListInsightUnderlyingDataChangedEventResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IListInsightUnderlyingDataChangedEventResponse} message ListInsightUnderlyingDataChangedEventResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListInsightUnderlyingDataChangedEventResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListInsightUnderlyingDataChangedEventResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse} ListInsightUnderlyingDataChangedEventResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListInsightUnderlyingDataChangedEventResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.events && message.events.length))
                                    message.events = [];
                                message.events.push($root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int64();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListInsightUnderlyingDataChangedEventResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse} ListInsightUnderlyingDataChangedEventResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListInsightUnderlyingDataChangedEventResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListInsightUnderlyingDataChangedEventResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListInsightUnderlyingDataChangedEventResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.events != null && message.hasOwnProperty("events")) {
                            if (!Array.isArray(message.events))
                                return "events: array expected";
                            for (var i = 0; i < message.events.length; ++i) {
                                var error = $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent.verify(message.events[i]);
                                if (error)
                                    return "events." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListInsightUnderlyingDataChangedEventResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse} ListInsightUnderlyingDataChangedEventResponse
                     */
                    ListInsightUnderlyingDataChangedEventResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse();
                        if (object.events) {
                            if (!Array.isArray(object.events))
                                throw TypeError(".palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse.events: array expected");
                            message.events = [];
                            for (var i = 0; i < object.events.length; ++i) {
                                if (typeof object.events[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse.events: object expected");
                                message.events[i] = $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent.fromObject(object.events[i]);
                            }
                        }
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListInsightUnderlyingDataChangedEventResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @static
                     * @param {palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse} message ListInsightUnderlyingDataChangedEventResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListInsightUnderlyingDataChangedEventResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.events = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                            object.nextPageToken = "";
                        }
                        if (message.events && message.events.length) {
                            object.events = [];
                            for (var j = 0; j < message.events.length; ++j)
                                object.events[j] = $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent.toObject(message.events[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListInsightUnderlyingDataChangedEventResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListInsightUnderlyingDataChangedEventResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListInsightUnderlyingDataChangedEventResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListInsightUnderlyingDataChangedEventResponse;
                })();
    
                v1.CreateInsightUnderlyingDataChangedEventRequest = (function() {
    
                    /**
                     * Properties of a CreateInsightUnderlyingDataChangedEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateInsightUnderlyingDataChangedEventRequest
                     * @property {palexy.streaming.v1.IInsightUnderlyingDataChangedEvent|null} [event] CreateInsightUnderlyingDataChangedEventRequest event
                     * @property {Array.<palexy.streaming.v1.IAsyncJob>|null} [asyncJobDependencies] CreateInsightUnderlyingDataChangedEventRequest asyncJobDependencies
                     */
    
                    /**
                     * Constructs a new CreateInsightUnderlyingDataChangedEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateInsightUnderlyingDataChangedEventRequest.
                     * @implements ICreateInsightUnderlyingDataChangedEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateInsightUnderlyingDataChangedEventRequest=} [properties] Properties to set
                     */
                    function CreateInsightUnderlyingDataChangedEventRequest(properties) {
                        this.asyncJobDependencies = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateInsightUnderlyingDataChangedEventRequest event.
                     * @member {palexy.streaming.v1.IInsightUnderlyingDataChangedEvent|null|undefined} event
                     * @memberof palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest
                     * @instance
                     */
                    CreateInsightUnderlyingDataChangedEventRequest.prototype.event = null;
    
                    /**
                     * CreateInsightUnderlyingDataChangedEventRequest asyncJobDependencies.
                     * @member {Array.<palexy.streaming.v1.IAsyncJob>} asyncJobDependencies
                     * @memberof palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest
                     * @instance
                     */
                    CreateInsightUnderlyingDataChangedEventRequest.prototype.asyncJobDependencies = $util.emptyArray;
    
                    /**
                     * Creates a new CreateInsightUnderlyingDataChangedEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateInsightUnderlyingDataChangedEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest} CreateInsightUnderlyingDataChangedEventRequest instance
                     */
                    CreateInsightUnderlyingDataChangedEventRequest.create = function create(properties) {
                        return new CreateInsightUnderlyingDataChangedEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateInsightUnderlyingDataChangedEventRequest message. Does not implicitly {@link palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateInsightUnderlyingDataChangedEventRequest} message CreateInsightUnderlyingDataChangedEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateInsightUnderlyingDataChangedEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.event != null && Object.hasOwnProperty.call(message, "event"))
                            $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent.encode(message.event, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.asyncJobDependencies != null && message.asyncJobDependencies.length)
                            for (var i = 0; i < message.asyncJobDependencies.length; ++i)
                                $root.palexy.streaming.v1.AsyncJob.encode(message.asyncJobDependencies[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateInsightUnderlyingDataChangedEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateInsightUnderlyingDataChangedEventRequest} message CreateInsightUnderlyingDataChangedEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateInsightUnderlyingDataChangedEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateInsightUnderlyingDataChangedEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest} CreateInsightUnderlyingDataChangedEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateInsightUnderlyingDataChangedEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.event = $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.asyncJobDependencies && message.asyncJobDependencies.length))
                                    message.asyncJobDependencies = [];
                                message.asyncJobDependencies.push($root.palexy.streaming.v1.AsyncJob.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateInsightUnderlyingDataChangedEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest} CreateInsightUnderlyingDataChangedEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateInsightUnderlyingDataChangedEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateInsightUnderlyingDataChangedEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateInsightUnderlyingDataChangedEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.event != null && message.hasOwnProperty("event")) {
                            var error = $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent.verify(message.event);
                            if (error)
                                return "event." + error;
                        }
                        if (message.asyncJobDependencies != null && message.hasOwnProperty("asyncJobDependencies")) {
                            if (!Array.isArray(message.asyncJobDependencies))
                                return "asyncJobDependencies: array expected";
                            for (var i = 0; i < message.asyncJobDependencies.length; ++i) {
                                var error = $root.palexy.streaming.v1.AsyncJob.verify(message.asyncJobDependencies[i]);
                                if (error)
                                    return "asyncJobDependencies." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateInsightUnderlyingDataChangedEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest} CreateInsightUnderlyingDataChangedEventRequest
                     */
                    CreateInsightUnderlyingDataChangedEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest();
                        if (object.event != null) {
                            if (typeof object.event !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest.event: object expected");
                            message.event = $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent.fromObject(object.event);
                        }
                        if (object.asyncJobDependencies) {
                            if (!Array.isArray(object.asyncJobDependencies))
                                throw TypeError(".palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest.asyncJobDependencies: array expected");
                            message.asyncJobDependencies = [];
                            for (var i = 0; i < object.asyncJobDependencies.length; ++i) {
                                if (typeof object.asyncJobDependencies[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest.asyncJobDependencies: object expected");
                                message.asyncJobDependencies[i] = $root.palexy.streaming.v1.AsyncJob.fromObject(object.asyncJobDependencies[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateInsightUnderlyingDataChangedEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest} message CreateInsightUnderlyingDataChangedEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateInsightUnderlyingDataChangedEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.asyncJobDependencies = [];
                        if (options.defaults)
                            object.event = null;
                        if (message.event != null && message.hasOwnProperty("event"))
                            object.event = $root.palexy.streaming.v1.InsightUnderlyingDataChangedEvent.toObject(message.event, options);
                        if (message.asyncJobDependencies && message.asyncJobDependencies.length) {
                            object.asyncJobDependencies = [];
                            for (var j = 0; j < message.asyncJobDependencies.length; ++j)
                                object.asyncJobDependencies[j] = $root.palexy.streaming.v1.AsyncJob.toObject(message.asyncJobDependencies[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this CreateInsightUnderlyingDataChangedEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateInsightUnderlyingDataChangedEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateInsightUnderlyingDataChangedEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateInsightUnderlyingDataChangedEventRequest;
                })();
    
                v1.BatchCompleteInsightUnderlyingDataChangedEventRequest = (function() {
    
                    /**
                     * Properties of a BatchCompleteInsightUnderlyingDataChangedEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @property {Array.<number>|null} [eventIds] BatchCompleteInsightUnderlyingDataChangedEventRequest eventIds
                     */
    
                    /**
                     * Constructs a new BatchCompleteInsightUnderlyingDataChangedEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchCompleteInsightUnderlyingDataChangedEventRequest.
                     * @implements IBatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchCompleteInsightUnderlyingDataChangedEventRequest=} [properties] Properties to set
                     */
                    function BatchCompleteInsightUnderlyingDataChangedEventRequest(properties) {
                        this.eventIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCompleteInsightUnderlyingDataChangedEventRequest eventIds.
                     * @member {Array.<number>} eventIds
                     * @memberof palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @instance
                     */
                    BatchCompleteInsightUnderlyingDataChangedEventRequest.prototype.eventIds = $util.emptyArray;
    
                    /**
                     * Creates a new BatchCompleteInsightUnderlyingDataChangedEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCompleteInsightUnderlyingDataChangedEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest} BatchCompleteInsightUnderlyingDataChangedEventRequest instance
                     */
                    BatchCompleteInsightUnderlyingDataChangedEventRequest.create = function create(properties) {
                        return new BatchCompleteInsightUnderlyingDataChangedEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCompleteInsightUnderlyingDataChangedEventRequest message. Does not implicitly {@link palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCompleteInsightUnderlyingDataChangedEventRequest} message BatchCompleteInsightUnderlyingDataChangedEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCompleteInsightUnderlyingDataChangedEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.eventIds != null && message.eventIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.eventIds.length; ++i)
                                writer.int64(message.eventIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCompleteInsightUnderlyingDataChangedEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchCompleteInsightUnderlyingDataChangedEventRequest} message BatchCompleteInsightUnderlyingDataChangedEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCompleteInsightUnderlyingDataChangedEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCompleteInsightUnderlyingDataChangedEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest} BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCompleteInsightUnderlyingDataChangedEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.eventIds && message.eventIds.length))
                                    message.eventIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.eventIds.push(reader.int64());
                                } else
                                    message.eventIds.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCompleteInsightUnderlyingDataChangedEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest} BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCompleteInsightUnderlyingDataChangedEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCompleteInsightUnderlyingDataChangedEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCompleteInsightUnderlyingDataChangedEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.eventIds != null && message.hasOwnProperty("eventIds")) {
                            if (!Array.isArray(message.eventIds))
                                return "eventIds: array expected";
                            for (var i = 0; i < message.eventIds.length; ++i)
                                if (!$util.isInteger(message.eventIds[i]) && !(message.eventIds[i] && $util.isInteger(message.eventIds[i].low) && $util.isInteger(message.eventIds[i].high)))
                                    return "eventIds: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchCompleteInsightUnderlyingDataChangedEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest} BatchCompleteInsightUnderlyingDataChangedEventRequest
                     */
                    BatchCompleteInsightUnderlyingDataChangedEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest();
                        if (object.eventIds) {
                            if (!Array.isArray(object.eventIds))
                                throw TypeError(".palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest.eventIds: array expected");
                            message.eventIds = [];
                            for (var i = 0; i < object.eventIds.length; ++i)
                                if ($util.Long)
                                    (message.eventIds[i] = $util.Long.fromValue(object.eventIds[i])).unsigned = false;
                                else if (typeof object.eventIds[i] === "string")
                                    message.eventIds[i] = parseInt(object.eventIds[i], 10);
                                else if (typeof object.eventIds[i] === "number")
                                    message.eventIds[i] = object.eventIds[i];
                                else if (typeof object.eventIds[i] === "object")
                                    message.eventIds[i] = new $util.LongBits(object.eventIds[i].low >>> 0, object.eventIds[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCompleteInsightUnderlyingDataChangedEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest} message BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCompleteInsightUnderlyingDataChangedEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.eventIds = [];
                        if (message.eventIds && message.eventIds.length) {
                            object.eventIds = [];
                            for (var j = 0; j < message.eventIds.length; ++j)
                                if (typeof message.eventIds[j] === "number")
                                    object.eventIds[j] = options.longs === String ? String(message.eventIds[j]) : message.eventIds[j];
                                else
                                    object.eventIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.eventIds[j]) : options.longs === Number ? new $util.LongBits(message.eventIds[j].low >>> 0, message.eventIds[j].high >>> 0).toNumber() : message.eventIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchCompleteInsightUnderlyingDataChangedEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchCompleteInsightUnderlyingDataChangedEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCompleteInsightUnderlyingDataChangedEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCompleteInsightUnderlyingDataChangedEventRequest;
                })();
    
                v1.ProcessUnderlyingDataChangedEventRequest = (function() {
    
                    /**
                     * Properties of a ProcessUnderlyingDataChangedEventRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IProcessUnderlyingDataChangedEventRequest
                     * @property {number|null} [batchSize] ProcessUnderlyingDataChangedEventRequest batchSize
                     */
    
                    /**
                     * Constructs a new ProcessUnderlyingDataChangedEventRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ProcessUnderlyingDataChangedEventRequest.
                     * @implements IProcessUnderlyingDataChangedEventRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IProcessUnderlyingDataChangedEventRequest=} [properties] Properties to set
                     */
                    function ProcessUnderlyingDataChangedEventRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ProcessUnderlyingDataChangedEventRequest batchSize.
                     * @member {number} batchSize
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest
                     * @instance
                     */
                    ProcessUnderlyingDataChangedEventRequest.prototype.batchSize = 0;
    
                    /**
                     * Creates a new ProcessUnderlyingDataChangedEventRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IProcessUnderlyingDataChangedEventRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest} ProcessUnderlyingDataChangedEventRequest instance
                     */
                    ProcessUnderlyingDataChangedEventRequest.create = function create(properties) {
                        return new ProcessUnderlyingDataChangedEventRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ProcessUnderlyingDataChangedEventRequest message. Does not implicitly {@link palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IProcessUnderlyingDataChangedEventRequest} message ProcessUnderlyingDataChangedEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProcessUnderlyingDataChangedEventRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.batchSize != null && Object.hasOwnProperty.call(message, "batchSize"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.batchSize);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ProcessUnderlyingDataChangedEventRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.IProcessUnderlyingDataChangedEventRequest} message ProcessUnderlyingDataChangedEventRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProcessUnderlyingDataChangedEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ProcessUnderlyingDataChangedEventRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest} ProcessUnderlyingDataChangedEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProcessUnderlyingDataChangedEventRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.batchSize = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ProcessUnderlyingDataChangedEventRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest} ProcessUnderlyingDataChangedEventRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProcessUnderlyingDataChangedEventRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ProcessUnderlyingDataChangedEventRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProcessUnderlyingDataChangedEventRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.batchSize != null && message.hasOwnProperty("batchSize"))
                            if (!$util.isInteger(message.batchSize))
                                return "batchSize: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ProcessUnderlyingDataChangedEventRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest} ProcessUnderlyingDataChangedEventRequest
                     */
                    ProcessUnderlyingDataChangedEventRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest();
                        if (object.batchSize != null)
                            message.batchSize = object.batchSize | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ProcessUnderlyingDataChangedEventRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest
                     * @static
                     * @param {palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest} message ProcessUnderlyingDataChangedEventRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProcessUnderlyingDataChangedEventRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.batchSize = 0;
                        if (message.batchSize != null && message.hasOwnProperty("batchSize"))
                            object.batchSize = message.batchSize;
                        return object;
                    };
    
                    /**
                     * Converts this ProcessUnderlyingDataChangedEventRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProcessUnderlyingDataChangedEventRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ProcessUnderlyingDataChangedEventRequest;
                })();
    
                v1.ProcessUnderlyingDataChangedEventResponse = (function() {
    
                    /**
                     * Properties of a ProcessUnderlyingDataChangedEventResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IProcessUnderlyingDataChangedEventResponse
                     * @property {number|null} [processedCount] ProcessUnderlyingDataChangedEventResponse processedCount
                     */
    
                    /**
                     * Constructs a new ProcessUnderlyingDataChangedEventResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ProcessUnderlyingDataChangedEventResponse.
                     * @implements IProcessUnderlyingDataChangedEventResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IProcessUnderlyingDataChangedEventResponse=} [properties] Properties to set
                     */
                    function ProcessUnderlyingDataChangedEventResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ProcessUnderlyingDataChangedEventResponse processedCount.
                     * @member {number} processedCount
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse
                     * @instance
                     */
                    ProcessUnderlyingDataChangedEventResponse.prototype.processedCount = 0;
    
                    /**
                     * Creates a new ProcessUnderlyingDataChangedEventResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IProcessUnderlyingDataChangedEventResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse} ProcessUnderlyingDataChangedEventResponse instance
                     */
                    ProcessUnderlyingDataChangedEventResponse.create = function create(properties) {
                        return new ProcessUnderlyingDataChangedEventResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ProcessUnderlyingDataChangedEventResponse message. Does not implicitly {@link palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IProcessUnderlyingDataChangedEventResponse} message ProcessUnderlyingDataChangedEventResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProcessUnderlyingDataChangedEventResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.processedCount != null && Object.hasOwnProperty.call(message, "processedCount"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.processedCount);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ProcessUnderlyingDataChangedEventResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse
                     * @static
                     * @param {palexy.streaming.v1.IProcessUnderlyingDataChangedEventResponse} message ProcessUnderlyingDataChangedEventResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProcessUnderlyingDataChangedEventResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ProcessUnderlyingDataChangedEventResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse} ProcessUnderlyingDataChangedEventResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProcessUnderlyingDataChangedEventResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.processedCount = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ProcessUnderlyingDataChangedEventResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse} ProcessUnderlyingDataChangedEventResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProcessUnderlyingDataChangedEventResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ProcessUnderlyingDataChangedEventResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProcessUnderlyingDataChangedEventResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.processedCount != null && message.hasOwnProperty("processedCount"))
                            if (!$util.isInteger(message.processedCount))
                                return "processedCount: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ProcessUnderlyingDataChangedEventResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse} ProcessUnderlyingDataChangedEventResponse
                     */
                    ProcessUnderlyingDataChangedEventResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse();
                        if (object.processedCount != null)
                            message.processedCount = object.processedCount | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ProcessUnderlyingDataChangedEventResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse
                     * @static
                     * @param {palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse} message ProcessUnderlyingDataChangedEventResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProcessUnderlyingDataChangedEventResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.processedCount = 0;
                        if (message.processedCount != null && message.hasOwnProperty("processedCount"))
                            object.processedCount = message.processedCount;
                        return object;
                    };
    
                    /**
                     * Converts this ProcessUnderlyingDataChangedEventResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ProcessUnderlyingDataChangedEventResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProcessUnderlyingDataChangedEventResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ProcessUnderlyingDataChangedEventResponse;
                })();
    
                v1.ImageProcessingModel = (function() {
    
                    /**
                     * Properties of an ImageProcessingModel.
                     * @memberof palexy.streaming.v1
                     * @interface IImageProcessingModel
                     * @property {number|null} [id] ImageProcessingModel id
                     * @property {string|null} [name] ImageProcessingModel name
                     * @property {string|null} [description] ImageProcessingModel description
                     * @property {palexy.streaming.v1.ImageProcessingModel.ModelType|null} [type] ImageProcessingModel type
                     * @property {palexy.streaming.v1.Artifact.IGCSFile|null} [modelFile] ImageProcessingModel modelFile
                     * @property {number|null} [createTimeMs] ImageProcessingModel createTimeMs
                     */
    
                    /**
                     * Constructs a new ImageProcessingModel.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ImageProcessingModel.
                     * @implements IImageProcessingModel
                     * @constructor
                     * @param {palexy.streaming.v1.IImageProcessingModel=} [properties] Properties to set
                     */
                    function ImageProcessingModel(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ImageProcessingModel id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @instance
                     */
                    ImageProcessingModel.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ImageProcessingModel name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @instance
                     */
                    ImageProcessingModel.prototype.name = "";
    
                    /**
                     * ImageProcessingModel description.
                     * @member {string} description
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @instance
                     */
                    ImageProcessingModel.prototype.description = "";
    
                    /**
                     * ImageProcessingModel type.
                     * @member {palexy.streaming.v1.ImageProcessingModel.ModelType} type
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @instance
                     */
                    ImageProcessingModel.prototype.type = 0;
    
                    /**
                     * ImageProcessingModel modelFile.
                     * @member {palexy.streaming.v1.Artifact.IGCSFile|null|undefined} modelFile
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @instance
                     */
                    ImageProcessingModel.prototype.modelFile = null;
    
                    /**
                     * ImageProcessingModel createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @instance
                     */
                    ImageProcessingModel.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ImageProcessingModel instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @static
                     * @param {palexy.streaming.v1.IImageProcessingModel=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ImageProcessingModel} ImageProcessingModel instance
                     */
                    ImageProcessingModel.create = function create(properties) {
                        return new ImageProcessingModel(properties);
                    };
    
                    /**
                     * Encodes the specified ImageProcessingModel message. Does not implicitly {@link palexy.streaming.v1.ImageProcessingModel.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @static
                     * @param {palexy.streaming.v1.IImageProcessingModel} message ImageProcessingModel message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImageProcessingModel.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                        if (message.modelFile != null && Object.hasOwnProperty.call(message, "modelFile"))
                            $root.palexy.streaming.v1.Artifact.GCSFile.encode(message.modelFile, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ImageProcessingModel message, length delimited. Does not implicitly {@link palexy.streaming.v1.ImageProcessingModel.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @static
                     * @param {palexy.streaming.v1.IImageProcessingModel} message ImageProcessingModel message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImageProcessingModel.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ImageProcessingModel message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ImageProcessingModel} ImageProcessingModel
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImageProcessingModel.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ImageProcessingModel();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.description = reader.string();
                                break;
                            case 4:
                                message.type = reader.int32();
                                break;
                            case 5:
                                message.modelFile = $root.palexy.streaming.v1.Artifact.GCSFile.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.createTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ImageProcessingModel message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ImageProcessingModel} ImageProcessingModel
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImageProcessingModel.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ImageProcessingModel message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImageProcessingModel.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.modelFile != null && message.hasOwnProperty("modelFile")) {
                            var error = $root.palexy.streaming.v1.Artifact.GCSFile.verify(message.modelFile);
                            if (error)
                                return "modelFile." + error;
                        }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an ImageProcessingModel message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ImageProcessingModel} ImageProcessingModel
                     */
                    ImageProcessingModel.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ImageProcessingModel)
                            return object;
                        var message = new $root.palexy.streaming.v1.ImageProcessingModel();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.description != null)
                            message.description = String(object.description);
                        switch (object.type) {
                        case "UNKNOWN_MODEL_TYPE":
                        case 0:
                            message.type = 0;
                            break;
                        case "PERSON_REID":
                        case 1:
                            message.type = 1;
                            break;
                        case "PERSON_DETECTION":
                        case 2:
                            message.type = 2;
                            break;
                        case "GENDER_AGE_GROUP":
                        case 3:
                            message.type = 3;
                            break;
                        case "BOUNDING_BOX_ATTIRIBUTE":
                        case 4:
                            message.type = 4;
                            break;
                        }
                        if (object.modelFile != null) {
                            if (typeof object.modelFile !== "object")
                                throw TypeError(".palexy.streaming.v1.ImageProcessingModel.modelFile: object expected");
                            message.modelFile = $root.palexy.streaming.v1.Artifact.GCSFile.fromObject(object.modelFile);
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ImageProcessingModel message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @static
                     * @param {palexy.streaming.v1.ImageProcessingModel} message ImageProcessingModel
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImageProcessingModel.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.description = "";
                            object.type = options.enums === String ? "UNKNOWN_MODEL_TYPE" : 0;
                            object.modelFile = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.ImageProcessingModel.ModelType[message.type] : message.type;
                        if (message.modelFile != null && message.hasOwnProperty("modelFile"))
                            object.modelFile = $root.palexy.streaming.v1.Artifact.GCSFile.toObject(message.modelFile, options);
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this ImageProcessingModel to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ImageProcessingModel
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImageProcessingModel.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * ModelType enum.
                     * @name palexy.streaming.v1.ImageProcessingModel.ModelType
                     * @enum {number}
                     * @property {number} UNKNOWN_MODEL_TYPE=0 UNKNOWN_MODEL_TYPE value
                     * @property {number} PERSON_REID=1 PERSON_REID value
                     * @property {number} PERSON_DETECTION=2 PERSON_DETECTION value
                     * @property {number} GENDER_AGE_GROUP=3 GENDER_AGE_GROUP value
                     * @property {number} BOUNDING_BOX_ATTIRIBUTE=4 BOUNDING_BOX_ATTIRIBUTE value
                     */
                    ImageProcessingModel.ModelType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_MODEL_TYPE"] = 0;
                        values[valuesById[1] = "PERSON_REID"] = 1;
                        values[valuesById[2] = "PERSON_DETECTION"] = 2;
                        values[valuesById[3] = "GENDER_AGE_GROUP"] = 3;
                        values[valuesById[4] = "BOUNDING_BOX_ATTIRIBUTE"] = 4;
                        return values;
                    })();
    
                    return ImageProcessingModel;
                })();
    
                v1.ImageProcessingModelService = (function() {
    
                    /**
                     * Constructs a new ImageProcessingModelService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an ImageProcessingModelService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ImageProcessingModelService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (ImageProcessingModelService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ImageProcessingModelService;
    
                    /**
                     * Creates new ImageProcessingModelService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.ImageProcessingModelService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ImageProcessingModelService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ImageProcessingModelService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ImageProcessingModelService#getImageProcessingModel}.
                     * @memberof palexy.streaming.v1.ImageProcessingModelService
                     * @typedef GetImageProcessingModelCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ImageProcessingModel} [response] ImageProcessingModel
                     */
    
                    /**
                     * Calls GetImageProcessingModel.
                     * @function getImageProcessingModel
                     * @memberof palexy.streaming.v1.ImageProcessingModelService
                     * @instance
                     * @param {palexy.streaming.v1.IGetImageProcessingModelRequest} request GetImageProcessingModelRequest message or plain object
                     * @param {palexy.streaming.v1.ImageProcessingModelService.GetImageProcessingModelCallback} callback Node-style callback called with the error, if any, and ImageProcessingModel
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ImageProcessingModelService.prototype.getImageProcessingModel = function getImageProcessingModel(request, callback) {
                        return this.rpcCall(getImageProcessingModel, $root.palexy.streaming.v1.GetImageProcessingModelRequest, $root.palexy.streaming.v1.ImageProcessingModel, request, callback);
                    }, "name", { value: "GetImageProcessingModel" });
    
                    /**
                     * Calls GetImageProcessingModel.
                     * @function getImageProcessingModel
                     * @memberof palexy.streaming.v1.ImageProcessingModelService
                     * @instance
                     * @param {palexy.streaming.v1.IGetImageProcessingModelRequest} request GetImageProcessingModelRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ImageProcessingModel>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ImageProcessingModelService#listImageProcessingModels}.
                     * @memberof palexy.streaming.v1.ImageProcessingModelService
                     * @typedef ListImageProcessingModelsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListImageProcessingModelsResponse} [response] ListImageProcessingModelsResponse
                     */
    
                    /**
                     * Calls ListImageProcessingModels.
                     * @function listImageProcessingModels
                     * @memberof palexy.streaming.v1.ImageProcessingModelService
                     * @instance
                     * @param {palexy.streaming.v1.IListImageProcessingModelsRequest} request ListImageProcessingModelsRequest message or plain object
                     * @param {palexy.streaming.v1.ImageProcessingModelService.ListImageProcessingModelsCallback} callback Node-style callback called with the error, if any, and ListImageProcessingModelsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ImageProcessingModelService.prototype.listImageProcessingModels = function listImageProcessingModels(request, callback) {
                        return this.rpcCall(listImageProcessingModels, $root.palexy.streaming.v1.ListImageProcessingModelsRequest, $root.palexy.streaming.v1.ListImageProcessingModelsResponse, request, callback);
                    }, "name", { value: "ListImageProcessingModels" });
    
                    /**
                     * Calls ListImageProcessingModels.
                     * @function listImageProcessingModels
                     * @memberof palexy.streaming.v1.ImageProcessingModelService
                     * @instance
                     * @param {palexy.streaming.v1.IListImageProcessingModelsRequest} request ListImageProcessingModelsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListImageProcessingModelsResponse>} Promise
                     * @variation 2
                     */
    
                    return ImageProcessingModelService;
                })();
    
                v1.GetImageProcessingModelRequest = (function() {
    
                    /**
                     * Properties of a GetImageProcessingModelRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetImageProcessingModelRequest
                     * @property {string|null} [name] GetImageProcessingModelRequest name
                     */
    
                    /**
                     * Constructs a new GetImageProcessingModelRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetImageProcessingModelRequest.
                     * @implements IGetImageProcessingModelRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetImageProcessingModelRequest=} [properties] Properties to set
                     */
                    function GetImageProcessingModelRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetImageProcessingModelRequest name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.GetImageProcessingModelRequest
                     * @instance
                     */
                    GetImageProcessingModelRequest.prototype.name = "";
    
                    /**
                     * Creates a new GetImageProcessingModelRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetImageProcessingModelRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetImageProcessingModelRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetImageProcessingModelRequest} GetImageProcessingModelRequest instance
                     */
                    GetImageProcessingModelRequest.create = function create(properties) {
                        return new GetImageProcessingModelRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetImageProcessingModelRequest message. Does not implicitly {@link palexy.streaming.v1.GetImageProcessingModelRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetImageProcessingModelRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetImageProcessingModelRequest} message GetImageProcessingModelRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetImageProcessingModelRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetImageProcessingModelRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetImageProcessingModelRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetImageProcessingModelRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetImageProcessingModelRequest} message GetImageProcessingModelRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetImageProcessingModelRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetImageProcessingModelRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetImageProcessingModelRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetImageProcessingModelRequest} GetImageProcessingModelRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetImageProcessingModelRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetImageProcessingModelRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetImageProcessingModelRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetImageProcessingModelRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetImageProcessingModelRequest} GetImageProcessingModelRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetImageProcessingModelRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetImageProcessingModelRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetImageProcessingModelRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetImageProcessingModelRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetImageProcessingModelRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetImageProcessingModelRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetImageProcessingModelRequest} GetImageProcessingModelRequest
                     */
                    GetImageProcessingModelRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetImageProcessingModelRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetImageProcessingModelRequest();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetImageProcessingModelRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetImageProcessingModelRequest
                     * @static
                     * @param {palexy.streaming.v1.GetImageProcessingModelRequest} message GetImageProcessingModelRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetImageProcessingModelRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };
    
                    /**
                     * Converts this GetImageProcessingModelRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetImageProcessingModelRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetImageProcessingModelRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetImageProcessingModelRequest;
                })();
    
                v1.ListImageProcessingModelsRequest = (function() {
    
                    /**
                     * Properties of a ListImageProcessingModelsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListImageProcessingModelsRequest
                     * @property {palexy.streaming.v1.ImageProcessingModel.ModelType|null} [modelType] ListImageProcessingModelsRequest modelType
                     */
    
                    /**
                     * Constructs a new ListImageProcessingModelsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListImageProcessingModelsRequest.
                     * @implements IListImageProcessingModelsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListImageProcessingModelsRequest=} [properties] Properties to set
                     */
                    function ListImageProcessingModelsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListImageProcessingModelsRequest modelType.
                     * @member {palexy.streaming.v1.ImageProcessingModel.ModelType} modelType
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsRequest
                     * @instance
                     */
                    ListImageProcessingModelsRequest.prototype.modelType = 0;
    
                    /**
                     * Creates a new ListImageProcessingModelsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListImageProcessingModelsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListImageProcessingModelsRequest} ListImageProcessingModelsRequest instance
                     */
                    ListImageProcessingModelsRequest.create = function create(properties) {
                        return new ListImageProcessingModelsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListImageProcessingModelsRequest message. Does not implicitly {@link palexy.streaming.v1.ListImageProcessingModelsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListImageProcessingModelsRequest} message ListImageProcessingModelsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListImageProcessingModelsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.modelType != null && Object.hasOwnProperty.call(message, "modelType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.modelType);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListImageProcessingModelsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListImageProcessingModelsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListImageProcessingModelsRequest} message ListImageProcessingModelsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListImageProcessingModelsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListImageProcessingModelsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListImageProcessingModelsRequest} ListImageProcessingModelsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListImageProcessingModelsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListImageProcessingModelsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.modelType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListImageProcessingModelsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListImageProcessingModelsRequest} ListImageProcessingModelsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListImageProcessingModelsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListImageProcessingModelsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListImageProcessingModelsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.modelType != null && message.hasOwnProperty("modelType"))
                            switch (message.modelType) {
                            default:
                                return "modelType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a ListImageProcessingModelsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListImageProcessingModelsRequest} ListImageProcessingModelsRequest
                     */
                    ListImageProcessingModelsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListImageProcessingModelsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListImageProcessingModelsRequest();
                        switch (object.modelType) {
                        case "UNKNOWN_MODEL_TYPE":
                        case 0:
                            message.modelType = 0;
                            break;
                        case "PERSON_REID":
                        case 1:
                            message.modelType = 1;
                            break;
                        case "PERSON_DETECTION":
                        case 2:
                            message.modelType = 2;
                            break;
                        case "GENDER_AGE_GROUP":
                        case 3:
                            message.modelType = 3;
                            break;
                        case "BOUNDING_BOX_ATTIRIBUTE":
                        case 4:
                            message.modelType = 4;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListImageProcessingModelsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsRequest
                     * @static
                     * @param {palexy.streaming.v1.ListImageProcessingModelsRequest} message ListImageProcessingModelsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListImageProcessingModelsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.modelType = options.enums === String ? "UNKNOWN_MODEL_TYPE" : 0;
                        if (message.modelType != null && message.hasOwnProperty("modelType"))
                            object.modelType = options.enums === String ? $root.palexy.streaming.v1.ImageProcessingModel.ModelType[message.modelType] : message.modelType;
                        return object;
                    };
    
                    /**
                     * Converts this ListImageProcessingModelsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListImageProcessingModelsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListImageProcessingModelsRequest;
                })();
    
                v1.ListImageProcessingModelsResponse = (function() {
    
                    /**
                     * Properties of a ListImageProcessingModelsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListImageProcessingModelsResponse
                     * @property {Array.<palexy.streaming.v1.IImageProcessingModel>|null} [models] ListImageProcessingModelsResponse models
                     */
    
                    /**
                     * Constructs a new ListImageProcessingModelsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListImageProcessingModelsResponse.
                     * @implements IListImageProcessingModelsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListImageProcessingModelsResponse=} [properties] Properties to set
                     */
                    function ListImageProcessingModelsResponse(properties) {
                        this.models = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListImageProcessingModelsResponse models.
                     * @member {Array.<palexy.streaming.v1.IImageProcessingModel>} models
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsResponse
                     * @instance
                     */
                    ListImageProcessingModelsResponse.prototype.models = $util.emptyArray;
    
                    /**
                     * Creates a new ListImageProcessingModelsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListImageProcessingModelsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListImageProcessingModelsResponse} ListImageProcessingModelsResponse instance
                     */
                    ListImageProcessingModelsResponse.create = function create(properties) {
                        return new ListImageProcessingModelsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListImageProcessingModelsResponse message. Does not implicitly {@link palexy.streaming.v1.ListImageProcessingModelsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListImageProcessingModelsResponse} message ListImageProcessingModelsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListImageProcessingModelsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.models != null && message.models.length)
                            for (var i = 0; i < message.models.length; ++i)
                                $root.palexy.streaming.v1.ImageProcessingModel.encode(message.models[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListImageProcessingModelsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListImageProcessingModelsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListImageProcessingModelsResponse} message ListImageProcessingModelsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListImageProcessingModelsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListImageProcessingModelsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListImageProcessingModelsResponse} ListImageProcessingModelsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListImageProcessingModelsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListImageProcessingModelsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.models && message.models.length))
                                    message.models = [];
                                message.models.push($root.palexy.streaming.v1.ImageProcessingModel.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListImageProcessingModelsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListImageProcessingModelsResponse} ListImageProcessingModelsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListImageProcessingModelsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListImageProcessingModelsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListImageProcessingModelsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.models != null && message.hasOwnProperty("models")) {
                            if (!Array.isArray(message.models))
                                return "models: array expected";
                            for (var i = 0; i < message.models.length; ++i) {
                                var error = $root.palexy.streaming.v1.ImageProcessingModel.verify(message.models[i]);
                                if (error)
                                    return "models." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListImageProcessingModelsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListImageProcessingModelsResponse} ListImageProcessingModelsResponse
                     */
                    ListImageProcessingModelsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListImageProcessingModelsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListImageProcessingModelsResponse();
                        if (object.models) {
                            if (!Array.isArray(object.models))
                                throw TypeError(".palexy.streaming.v1.ListImageProcessingModelsResponse.models: array expected");
                            message.models = [];
                            for (var i = 0; i < object.models.length; ++i) {
                                if (typeof object.models[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListImageProcessingModelsResponse.models: object expected");
                                message.models[i] = $root.palexy.streaming.v1.ImageProcessingModel.fromObject(object.models[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListImageProcessingModelsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListImageProcessingModelsResponse} message ListImageProcessingModelsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListImageProcessingModelsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.models = [];
                        if (message.models && message.models.length) {
                            object.models = [];
                            for (var j = 0; j < message.models.length; ++j)
                                object.models[j] = $root.palexy.streaming.v1.ImageProcessingModel.toObject(message.models[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListImageProcessingModelsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListImageProcessingModelsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListImageProcessingModelsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListImageProcessingModelsResponse;
                })();
    
                v1.StaffPositionService = (function() {
    
                    /**
                     * Constructs a new StaffPositionService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffPositionService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StaffPositionService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (StaffPositionService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StaffPositionService;
    
                    /**
                     * Creates new StaffPositionService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StaffPositionService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StaffPositionService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffPositionService#getStaffPosition}.
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @typedef GetStaffPositionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffPosition} [response] StaffPosition
                     */
    
                    /**
                     * Calls GetStaffPosition.
                     * @function getStaffPosition
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffPositionRequest} request GetStaffPositionRequest message or plain object
                     * @param {palexy.streaming.v1.StaffPositionService.GetStaffPositionCallback} callback Node-style callback called with the error, if any, and StaffPosition
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffPositionService.prototype.getStaffPosition = function getStaffPosition(request, callback) {
                        return this.rpcCall(getStaffPosition, $root.palexy.streaming.v1.GetStaffPositionRequest, $root.palexy.streaming.v1.StaffPosition, request, callback);
                    }, "name", { value: "GetStaffPosition" });
    
                    /**
                     * Calls GetStaffPosition.
                     * @function getStaffPosition
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffPositionRequest} request GetStaffPositionRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffPosition>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffPositionService#listStaffPositions}.
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @typedef ListStaffPositionsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStaffPositionsResponse} [response] ListStaffPositionsResponse
                     */
    
                    /**
                     * Calls ListStaffPositions.
                     * @function listStaffPositions
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffPositionsRequest} request ListStaffPositionsRequest message or plain object
                     * @param {palexy.streaming.v1.StaffPositionService.ListStaffPositionsCallback} callback Node-style callback called with the error, if any, and ListStaffPositionsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffPositionService.prototype.listStaffPositions = function listStaffPositions(request, callback) {
                        return this.rpcCall(listStaffPositions, $root.palexy.streaming.v1.ListStaffPositionsRequest, $root.palexy.streaming.v1.ListStaffPositionsResponse, request, callback);
                    }, "name", { value: "ListStaffPositions" });
    
                    /**
                     * Calls ListStaffPositions.
                     * @function listStaffPositions
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffPositionsRequest} request ListStaffPositionsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStaffPositionsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffPositionService#createStaffPosition}.
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @typedef CreateStaffPositionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffPosition} [response] StaffPosition
                     */
    
                    /**
                     * Calls CreateStaffPosition.
                     * @function createStaffPosition
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStaffPositionRequest} request CreateStaffPositionRequest message or plain object
                     * @param {palexy.streaming.v1.StaffPositionService.CreateStaffPositionCallback} callback Node-style callback called with the error, if any, and StaffPosition
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffPositionService.prototype.createStaffPosition = function createStaffPosition(request, callback) {
                        return this.rpcCall(createStaffPosition, $root.palexy.streaming.v1.CreateStaffPositionRequest, $root.palexy.streaming.v1.StaffPosition, request, callback);
                    }, "name", { value: "CreateStaffPosition" });
    
                    /**
                     * Calls CreateStaffPosition.
                     * @function createStaffPosition
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStaffPositionRequest} request CreateStaffPositionRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffPosition>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffPositionService#updateStaffPosition}.
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @typedef UpdateStaffPositionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffPosition} [response] StaffPosition
                     */
    
                    /**
                     * Calls UpdateStaffPosition.
                     * @function updateStaffPosition
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStaffPositionRequest} request UpdateStaffPositionRequest message or plain object
                     * @param {palexy.streaming.v1.StaffPositionService.UpdateStaffPositionCallback} callback Node-style callback called with the error, if any, and StaffPosition
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffPositionService.prototype.updateStaffPosition = function updateStaffPosition(request, callback) {
                        return this.rpcCall(updateStaffPosition, $root.palexy.streaming.v1.UpdateStaffPositionRequest, $root.palexy.streaming.v1.StaffPosition, request, callback);
                    }, "name", { value: "UpdateStaffPosition" });
    
                    /**
                     * Calls UpdateStaffPosition.
                     * @function updateStaffPosition
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStaffPositionRequest} request UpdateStaffPositionRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffPosition>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffPositionService#deleteStaffPosition}.
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @typedef DeleteStaffPositionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteStaffPosition.
                     * @function deleteStaffPosition
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteStaffPositionRequest} request DeleteStaffPositionRequest message or plain object
                     * @param {palexy.streaming.v1.StaffPositionService.DeleteStaffPositionCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffPositionService.prototype.deleteStaffPosition = function deleteStaffPosition(request, callback) {
                        return this.rpcCall(deleteStaffPosition, $root.palexy.streaming.v1.DeleteStaffPositionRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteStaffPosition" });
    
                    /**
                     * Calls DeleteStaffPosition.
                     * @function deleteStaffPosition
                     * @memberof palexy.streaming.v1.StaffPositionService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteStaffPositionRequest} request DeleteStaffPositionRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return StaffPositionService;
                })();
    
                v1.ListStaffPositionsRequest = (function() {
    
                    /**
                     * Properties of a ListStaffPositionsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffPositionsRequest
                     * @property {number|null} [companyId] ListStaffPositionsRequest companyId
                     */
    
                    /**
                     * Constructs a new ListStaffPositionsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffPositionsRequest.
                     * @implements IListStaffPositionsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffPositionsRequest=} [properties] Properties to set
                     */
                    function ListStaffPositionsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffPositionsRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListStaffPositionsRequest
                     * @instance
                     */
                    ListStaffPositionsRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListStaffPositionsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffPositionsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffPositionsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffPositionsRequest} ListStaffPositionsRequest instance
                     */
                    ListStaffPositionsRequest.create = function create(properties) {
                        return new ListStaffPositionsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffPositionsRequest message. Does not implicitly {@link palexy.streaming.v1.ListStaffPositionsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffPositionsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffPositionsRequest} message ListStaffPositionsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffPositionsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffPositionsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffPositionsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffPositionsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffPositionsRequest} message ListStaffPositionsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffPositionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffPositionsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffPositionsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffPositionsRequest} ListStaffPositionsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffPositionsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffPositionsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffPositionsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffPositionsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffPositionsRequest} ListStaffPositionsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffPositionsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffPositionsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffPositionsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffPositionsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffPositionsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffPositionsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffPositionsRequest} ListStaffPositionsRequest
                     */
                    ListStaffPositionsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffPositionsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffPositionsRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffPositionsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffPositionsRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStaffPositionsRequest} message ListStaffPositionsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffPositionsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffPositionsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffPositionsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffPositionsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffPositionsRequest;
                })();
    
                v1.ListStaffPositionsResponse = (function() {
    
                    /**
                     * Properties of a ListStaffPositionsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffPositionsResponse
                     * @property {Array.<palexy.streaming.v1.IStaffPosition>|null} [staffPositions] ListStaffPositionsResponse staffPositions
                     */
    
                    /**
                     * Constructs a new ListStaffPositionsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffPositionsResponse.
                     * @implements IListStaffPositionsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffPositionsResponse=} [properties] Properties to set
                     */
                    function ListStaffPositionsResponse(properties) {
                        this.staffPositions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffPositionsResponse staffPositions.
                     * @member {Array.<palexy.streaming.v1.IStaffPosition>} staffPositions
                     * @memberof palexy.streaming.v1.ListStaffPositionsResponse
                     * @instance
                     */
                    ListStaffPositionsResponse.prototype.staffPositions = $util.emptyArray;
    
                    /**
                     * Creates a new ListStaffPositionsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffPositionsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffPositionsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffPositionsResponse} ListStaffPositionsResponse instance
                     */
                    ListStaffPositionsResponse.create = function create(properties) {
                        return new ListStaffPositionsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffPositionsResponse message. Does not implicitly {@link palexy.streaming.v1.ListStaffPositionsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffPositionsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffPositionsResponse} message ListStaffPositionsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffPositionsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffPositions != null && message.staffPositions.length)
                            for (var i = 0; i < message.staffPositions.length; ++i)
                                $root.palexy.streaming.v1.StaffPosition.encode(message.staffPositions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffPositionsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffPositionsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffPositionsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffPositionsResponse} message ListStaffPositionsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffPositionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffPositionsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffPositionsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffPositionsResponse} ListStaffPositionsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffPositionsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffPositionsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.staffPositions && message.staffPositions.length))
                                    message.staffPositions = [];
                                message.staffPositions.push($root.palexy.streaming.v1.StaffPosition.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffPositionsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffPositionsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffPositionsResponse} ListStaffPositionsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffPositionsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffPositionsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffPositionsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffPositionsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffPositions != null && message.hasOwnProperty("staffPositions")) {
                            if (!Array.isArray(message.staffPositions))
                                return "staffPositions: array expected";
                            for (var i = 0; i < message.staffPositions.length; ++i) {
                                var error = $root.palexy.streaming.v1.StaffPosition.verify(message.staffPositions[i]);
                                if (error)
                                    return "staffPositions." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffPositionsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffPositionsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffPositionsResponse} ListStaffPositionsResponse
                     */
                    ListStaffPositionsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffPositionsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffPositionsResponse();
                        if (object.staffPositions) {
                            if (!Array.isArray(object.staffPositions))
                                throw TypeError(".palexy.streaming.v1.ListStaffPositionsResponse.staffPositions: array expected");
                            message.staffPositions = [];
                            for (var i = 0; i < object.staffPositions.length; ++i) {
                                if (typeof object.staffPositions[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStaffPositionsResponse.staffPositions: object expected");
                                message.staffPositions[i] = $root.palexy.streaming.v1.StaffPosition.fromObject(object.staffPositions[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffPositionsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffPositionsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStaffPositionsResponse} message ListStaffPositionsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffPositionsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.staffPositions = [];
                        if (message.staffPositions && message.staffPositions.length) {
                            object.staffPositions = [];
                            for (var j = 0; j < message.staffPositions.length; ++j)
                                object.staffPositions[j] = $root.palexy.streaming.v1.StaffPosition.toObject(message.staffPositions[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffPositionsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffPositionsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffPositionsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffPositionsResponse;
                })();
    
                v1.CreateStaffPositionRequest = (function() {
    
                    /**
                     * Properties of a CreateStaffPositionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateStaffPositionRequest
                     * @property {palexy.streaming.v1.IStaffPosition|null} [staffPosition] CreateStaffPositionRequest staffPosition
                     */
    
                    /**
                     * Constructs a new CreateStaffPositionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateStaffPositionRequest.
                     * @implements ICreateStaffPositionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateStaffPositionRequest=} [properties] Properties to set
                     */
                    function CreateStaffPositionRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateStaffPositionRequest staffPosition.
                     * @member {palexy.streaming.v1.IStaffPosition|null|undefined} staffPosition
                     * @memberof palexy.streaming.v1.CreateStaffPositionRequest
                     * @instance
                     */
                    CreateStaffPositionRequest.prototype.staffPosition = null;
    
                    /**
                     * Creates a new CreateStaffPositionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffPositionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateStaffPositionRequest} CreateStaffPositionRequest instance
                     */
                    CreateStaffPositionRequest.create = function create(properties) {
                        return new CreateStaffPositionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateStaffPositionRequest message. Does not implicitly {@link palexy.streaming.v1.CreateStaffPositionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffPositionRequest} message CreateStaffPositionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStaffPositionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffPosition != null && Object.hasOwnProperty.call(message, "staffPosition"))
                            $root.palexy.streaming.v1.StaffPosition.encode(message.staffPosition, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateStaffPositionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateStaffPositionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffPositionRequest} message CreateStaffPositionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStaffPositionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateStaffPositionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateStaffPositionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateStaffPositionRequest} CreateStaffPositionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStaffPositionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateStaffPositionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffPosition = $root.palexy.streaming.v1.StaffPosition.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateStaffPositionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateStaffPositionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateStaffPositionRequest} CreateStaffPositionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStaffPositionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateStaffPositionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateStaffPositionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateStaffPositionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffPosition != null && message.hasOwnProperty("staffPosition")) {
                            var error = $root.palexy.streaming.v1.StaffPosition.verify(message.staffPosition);
                            if (error)
                                return "staffPosition." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateStaffPositionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateStaffPositionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateStaffPositionRequest} CreateStaffPositionRequest
                     */
                    CreateStaffPositionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateStaffPositionRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateStaffPositionRequest();
                        if (object.staffPosition != null) {
                            if (typeof object.staffPosition !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateStaffPositionRequest.staffPosition: object expected");
                            message.staffPosition = $root.palexy.streaming.v1.StaffPosition.fromObject(object.staffPosition);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateStaffPositionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateStaffPositionRequest} message CreateStaffPositionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateStaffPositionRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.staffPosition = null;
                        if (message.staffPosition != null && message.hasOwnProperty("staffPosition"))
                            object.staffPosition = $root.palexy.streaming.v1.StaffPosition.toObject(message.staffPosition, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateStaffPositionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateStaffPositionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateStaffPositionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateStaffPositionRequest;
                })();
    
                v1.DeleteStaffPositionRequest = (function() {
    
                    /**
                     * Properties of a DeleteStaffPositionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteStaffPositionRequest
                     * @property {number|null} [staffPositionId] DeleteStaffPositionRequest staffPositionId
                     */
    
                    /**
                     * Constructs a new DeleteStaffPositionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteStaffPositionRequest.
                     * @implements IDeleteStaffPositionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteStaffPositionRequest=} [properties] Properties to set
                     */
                    function DeleteStaffPositionRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteStaffPositionRequest staffPositionId.
                     * @member {number} staffPositionId
                     * @memberof palexy.streaming.v1.DeleteStaffPositionRequest
                     * @instance
                     */
                    DeleteStaffPositionRequest.prototype.staffPositionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteStaffPositionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStaffPositionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteStaffPositionRequest} DeleteStaffPositionRequest instance
                     */
                    DeleteStaffPositionRequest.create = function create(properties) {
                        return new DeleteStaffPositionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteStaffPositionRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteStaffPositionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStaffPositionRequest} message DeleteStaffPositionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStaffPositionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffPositionId != null && Object.hasOwnProperty.call(message, "staffPositionId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.staffPositionId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteStaffPositionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteStaffPositionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStaffPositionRequest} message DeleteStaffPositionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStaffPositionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteStaffPositionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteStaffPositionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteStaffPositionRequest} DeleteStaffPositionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStaffPositionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteStaffPositionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffPositionId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteStaffPositionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteStaffPositionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteStaffPositionRequest} DeleteStaffPositionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStaffPositionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteStaffPositionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteStaffPositionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteStaffPositionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffPositionId != null && message.hasOwnProperty("staffPositionId"))
                            if (!$util.isInteger(message.staffPositionId) && !(message.staffPositionId && $util.isInteger(message.staffPositionId.low) && $util.isInteger(message.staffPositionId.high)))
                                return "staffPositionId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteStaffPositionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteStaffPositionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteStaffPositionRequest} DeleteStaffPositionRequest
                     */
                    DeleteStaffPositionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteStaffPositionRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteStaffPositionRequest();
                        if (object.staffPositionId != null)
                            if ($util.Long)
                                (message.staffPositionId = $util.Long.fromValue(object.staffPositionId)).unsigned = false;
                            else if (typeof object.staffPositionId === "string")
                                message.staffPositionId = parseInt(object.staffPositionId, 10);
                            else if (typeof object.staffPositionId === "number")
                                message.staffPositionId = object.staffPositionId;
                            else if (typeof object.staffPositionId === "object")
                                message.staffPositionId = new $util.LongBits(object.staffPositionId.low >>> 0, object.staffPositionId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteStaffPositionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteStaffPositionRequest} message DeleteStaffPositionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteStaffPositionRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffPositionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffPositionId = options.longs === String ? "0" : 0;
                        if (message.staffPositionId != null && message.hasOwnProperty("staffPositionId"))
                            if (typeof message.staffPositionId === "number")
                                object.staffPositionId = options.longs === String ? String(message.staffPositionId) : message.staffPositionId;
                            else
                                object.staffPositionId = options.longs === String ? $util.Long.prototype.toString.call(message.staffPositionId) : options.longs === Number ? new $util.LongBits(message.staffPositionId.low >>> 0, message.staffPositionId.high >>> 0).toNumber() : message.staffPositionId;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteStaffPositionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteStaffPositionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteStaffPositionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteStaffPositionRequest;
                })();
    
                v1.GetStaffPositionRequest = (function() {
    
                    /**
                     * Properties of a GetStaffPositionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStaffPositionRequest
                     * @property {number|null} [staffPositionId] GetStaffPositionRequest staffPositionId
                     */
    
                    /**
                     * Constructs a new GetStaffPositionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStaffPositionRequest.
                     * @implements IGetStaffPositionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStaffPositionRequest=} [properties] Properties to set
                     */
                    function GetStaffPositionRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStaffPositionRequest staffPositionId.
                     * @member {number} staffPositionId
                     * @memberof palexy.streaming.v1.GetStaffPositionRequest
                     * @instance
                     */
                    GetStaffPositionRequest.prototype.staffPositionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetStaffPositionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffPositionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStaffPositionRequest} GetStaffPositionRequest instance
                     */
                    GetStaffPositionRequest.create = function create(properties) {
                        return new GetStaffPositionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStaffPositionRequest message. Does not implicitly {@link palexy.streaming.v1.GetStaffPositionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffPositionRequest} message GetStaffPositionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffPositionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffPositionId != null && Object.hasOwnProperty.call(message, "staffPositionId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.staffPositionId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStaffPositionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStaffPositionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffPositionRequest} message GetStaffPositionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffPositionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStaffPositionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStaffPositionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStaffPositionRequest} GetStaffPositionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffPositionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStaffPositionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffPositionId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStaffPositionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffPositionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStaffPositionRequest} GetStaffPositionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffPositionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStaffPositionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStaffPositionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStaffPositionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffPositionId != null && message.hasOwnProperty("staffPositionId"))
                            if (!$util.isInteger(message.staffPositionId) && !(message.staffPositionId && $util.isInteger(message.staffPositionId.low) && $util.isInteger(message.staffPositionId.high)))
                                return "staffPositionId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStaffPositionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStaffPositionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStaffPositionRequest} GetStaffPositionRequest
                     */
                    GetStaffPositionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStaffPositionRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStaffPositionRequest();
                        if (object.staffPositionId != null)
                            if ($util.Long)
                                (message.staffPositionId = $util.Long.fromValue(object.staffPositionId)).unsigned = false;
                            else if (typeof object.staffPositionId === "string")
                                message.staffPositionId = parseInt(object.staffPositionId, 10);
                            else if (typeof object.staffPositionId === "number")
                                message.staffPositionId = object.staffPositionId;
                            else if (typeof object.staffPositionId === "object")
                                message.staffPositionId = new $util.LongBits(object.staffPositionId.low >>> 0, object.staffPositionId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStaffPositionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStaffPositionRequest} message GetStaffPositionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStaffPositionRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffPositionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffPositionId = options.longs === String ? "0" : 0;
                        if (message.staffPositionId != null && message.hasOwnProperty("staffPositionId"))
                            if (typeof message.staffPositionId === "number")
                                object.staffPositionId = options.longs === String ? String(message.staffPositionId) : message.staffPositionId;
                            else
                                object.staffPositionId = options.longs === String ? $util.Long.prototype.toString.call(message.staffPositionId) : options.longs === Number ? new $util.LongBits(message.staffPositionId.low >>> 0, message.staffPositionId.high >>> 0).toNumber() : message.staffPositionId;
                        return object;
                    };
    
                    /**
                     * Converts this GetStaffPositionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStaffPositionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStaffPositionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStaffPositionRequest;
                })();
    
                v1.UpdateStaffPositionRequest = (function() {
    
                    /**
                     * Properties of an UpdateStaffPositionRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateStaffPositionRequest
                     * @property {palexy.streaming.v1.IStaffPosition|null} [staffPosition] UpdateStaffPositionRequest staffPosition
                     */
    
                    /**
                     * Constructs a new UpdateStaffPositionRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateStaffPositionRequest.
                     * @implements IUpdateStaffPositionRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateStaffPositionRequest=} [properties] Properties to set
                     */
                    function UpdateStaffPositionRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateStaffPositionRequest staffPosition.
                     * @member {palexy.streaming.v1.IStaffPosition|null|undefined} staffPosition
                     * @memberof palexy.streaming.v1.UpdateStaffPositionRequest
                     * @instance
                     */
                    UpdateStaffPositionRequest.prototype.staffPosition = null;
    
                    /**
                     * Creates a new UpdateStaffPositionRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffPositionRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateStaffPositionRequest} UpdateStaffPositionRequest instance
                     */
                    UpdateStaffPositionRequest.create = function create(properties) {
                        return new UpdateStaffPositionRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateStaffPositionRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateStaffPositionRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffPositionRequest} message UpdateStaffPositionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStaffPositionRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffPosition != null && Object.hasOwnProperty.call(message, "staffPosition"))
                            $root.palexy.streaming.v1.StaffPosition.encode(message.staffPosition, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateStaffPositionRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateStaffPositionRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffPositionRequest} message UpdateStaffPositionRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStaffPositionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateStaffPositionRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateStaffPositionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateStaffPositionRequest} UpdateStaffPositionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStaffPositionRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateStaffPositionRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffPosition = $root.palexy.streaming.v1.StaffPosition.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateStaffPositionRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStaffPositionRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateStaffPositionRequest} UpdateStaffPositionRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStaffPositionRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateStaffPositionRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateStaffPositionRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateStaffPositionRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffPosition != null && message.hasOwnProperty("staffPosition")) {
                            var error = $root.palexy.streaming.v1.StaffPosition.verify(message.staffPosition);
                            if (error)
                                return "staffPosition." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateStaffPositionRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateStaffPositionRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateStaffPositionRequest} UpdateStaffPositionRequest
                     */
                    UpdateStaffPositionRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateStaffPositionRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateStaffPositionRequest();
                        if (object.staffPosition != null) {
                            if (typeof object.staffPosition !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStaffPositionRequest.staffPosition: object expected");
                            message.staffPosition = $root.palexy.streaming.v1.StaffPosition.fromObject(object.staffPosition);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateStaffPositionRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateStaffPositionRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateStaffPositionRequest} message UpdateStaffPositionRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateStaffPositionRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.staffPosition = null;
                        if (message.staffPosition != null && message.hasOwnProperty("staffPosition"))
                            object.staffPosition = $root.palexy.streaming.v1.StaffPosition.toObject(message.staffPosition, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateStaffPositionRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateStaffPositionRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateStaffPositionRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateStaffPositionRequest;
                })();
    
                v1.StaffPosition = (function() {
    
                    /**
                     * Properties of a StaffPosition.
                     * @memberof palexy.streaming.v1
                     * @interface IStaffPosition
                     * @property {number|null} [id] StaffPosition id
                     * @property {number|null} [companyId] StaffPosition companyId
                     * @property {string|null} [name] StaffPosition name
                     * @property {string|null} [description] StaffPosition description
                     * @property {number|null} [createTimeMs] StaffPosition createTimeMs
                     * @property {number|null} [updateTimeMs] StaffPosition updateTimeMs
                     */
    
                    /**
                     * Constructs a new StaffPosition.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffPosition.
                     * @implements IStaffPosition
                     * @constructor
                     * @param {palexy.streaming.v1.IStaffPosition=} [properties] Properties to set
                     */
                    function StaffPosition(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StaffPosition id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @instance
                     */
                    StaffPosition.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffPosition companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @instance
                     */
                    StaffPosition.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffPosition name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @instance
                     */
                    StaffPosition.prototype.name = "";
    
                    /**
                     * StaffPosition description.
                     * @member {string} description
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @instance
                     */
                    StaffPosition.prototype.description = "";
    
                    /**
                     * StaffPosition createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @instance
                     */
                    StaffPosition.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffPosition updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @instance
                     */
                    StaffPosition.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new StaffPosition instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @static
                     * @param {palexy.streaming.v1.IStaffPosition=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StaffPosition} StaffPosition instance
                     */
                    StaffPosition.create = function create(properties) {
                        return new StaffPosition(properties);
                    };
    
                    /**
                     * Encodes the specified StaffPosition message. Does not implicitly {@link palexy.streaming.v1.StaffPosition.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @static
                     * @param {palexy.streaming.v1.IStaffPosition} message StaffPosition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffPosition.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.updateTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StaffPosition message, length delimited. Does not implicitly {@link palexy.streaming.v1.StaffPosition.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @static
                     * @param {palexy.streaming.v1.IStaffPosition} message StaffPosition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffPosition.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StaffPosition message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StaffPosition} StaffPosition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffPosition.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StaffPosition();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.name = reader.string();
                                break;
                            case 4:
                                message.description = reader.string();
                                break;
                            case 5:
                                message.createTimeMs = reader.int64();
                                break;
                            case 6:
                                message.updateTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StaffPosition message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StaffPosition} StaffPosition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffPosition.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StaffPosition message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StaffPosition.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a StaffPosition message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StaffPosition} StaffPosition
                     */
                    StaffPosition.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StaffPosition)
                            return object;
                        var message = new $root.palexy.streaming.v1.StaffPosition();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StaffPosition message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @static
                     * @param {palexy.streaming.v1.StaffPosition} message StaffPosition
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StaffPosition.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.description = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this StaffPosition to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StaffPosition
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StaffPosition.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StaffPosition;
                })();
    
                v1.ShiftTemplateService = (function() {
    
                    /**
                     * Constructs a new ShiftTemplateService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ShiftTemplateService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ShiftTemplateService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (ShiftTemplateService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ShiftTemplateService;
    
                    /**
                     * Creates new ShiftTemplateService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ShiftTemplateService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ShiftTemplateService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ShiftTemplateService#getShiftTemplate}.
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @typedef GetShiftTemplateCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ShiftTemplate} [response] ShiftTemplate
                     */
    
                    /**
                     * Calls GetShiftTemplate.
                     * @function getShiftTemplate
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @instance
                     * @param {palexy.streaming.v1.IGetShiftTemplateRequest} request GetShiftTemplateRequest message or plain object
                     * @param {palexy.streaming.v1.ShiftTemplateService.GetShiftTemplateCallback} callback Node-style callback called with the error, if any, and ShiftTemplate
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShiftTemplateService.prototype.getShiftTemplate = function getShiftTemplate(request, callback) {
                        return this.rpcCall(getShiftTemplate, $root.palexy.streaming.v1.GetShiftTemplateRequest, $root.palexy.streaming.v1.ShiftTemplate, request, callback);
                    }, "name", { value: "GetShiftTemplate" });
    
                    /**
                     * Calls GetShiftTemplate.
                     * @function getShiftTemplate
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @instance
                     * @param {palexy.streaming.v1.IGetShiftTemplateRequest} request GetShiftTemplateRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ShiftTemplate>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ShiftTemplateService#listShiftTemplates}.
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @typedef ListShiftTemplatesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListShiftTemplatesResponse} [response] ListShiftTemplatesResponse
                     */
    
                    /**
                     * Calls ListShiftTemplates.
                     * @function listShiftTemplates
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @instance
                     * @param {palexy.streaming.v1.IListShiftTemplatesRequest} request ListShiftTemplatesRequest message or plain object
                     * @param {palexy.streaming.v1.ShiftTemplateService.ListShiftTemplatesCallback} callback Node-style callback called with the error, if any, and ListShiftTemplatesResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShiftTemplateService.prototype.listShiftTemplates = function listShiftTemplates(request, callback) {
                        return this.rpcCall(listShiftTemplates, $root.palexy.streaming.v1.ListShiftTemplatesRequest, $root.palexy.streaming.v1.ListShiftTemplatesResponse, request, callback);
                    }, "name", { value: "ListShiftTemplates" });
    
                    /**
                     * Calls ListShiftTemplates.
                     * @function listShiftTemplates
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @instance
                     * @param {palexy.streaming.v1.IListShiftTemplatesRequest} request ListShiftTemplatesRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListShiftTemplatesResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ShiftTemplateService#createShiftTemplate}.
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @typedef CreateShiftTemplateCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ShiftTemplate} [response] ShiftTemplate
                     */
    
                    /**
                     * Calls CreateShiftTemplate.
                     * @function createShiftTemplate
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateShiftTemplateRequest} request CreateShiftTemplateRequest message or plain object
                     * @param {palexy.streaming.v1.ShiftTemplateService.CreateShiftTemplateCallback} callback Node-style callback called with the error, if any, and ShiftTemplate
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShiftTemplateService.prototype.createShiftTemplate = function createShiftTemplate(request, callback) {
                        return this.rpcCall(createShiftTemplate, $root.palexy.streaming.v1.CreateShiftTemplateRequest, $root.palexy.streaming.v1.ShiftTemplate, request, callback);
                    }, "name", { value: "CreateShiftTemplate" });
    
                    /**
                     * Calls CreateShiftTemplate.
                     * @function createShiftTemplate
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateShiftTemplateRequest} request CreateShiftTemplateRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ShiftTemplate>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ShiftTemplateService#updateShiftTemplate}.
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @typedef UpdateShiftTemplateCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ShiftTemplate} [response] ShiftTemplate
                     */
    
                    /**
                     * Calls UpdateShiftTemplate.
                     * @function updateShiftTemplate
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateShiftTemplateRequest} request UpdateShiftTemplateRequest message or plain object
                     * @param {palexy.streaming.v1.ShiftTemplateService.UpdateShiftTemplateCallback} callback Node-style callback called with the error, if any, and ShiftTemplate
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShiftTemplateService.prototype.updateShiftTemplate = function updateShiftTemplate(request, callback) {
                        return this.rpcCall(updateShiftTemplate, $root.palexy.streaming.v1.UpdateShiftTemplateRequest, $root.palexy.streaming.v1.ShiftTemplate, request, callback);
                    }, "name", { value: "UpdateShiftTemplate" });
    
                    /**
                     * Calls UpdateShiftTemplate.
                     * @function updateShiftTemplate
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateShiftTemplateRequest} request UpdateShiftTemplateRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ShiftTemplate>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.ShiftTemplateService#deleteShiftTemplate}.
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @typedef DeleteShiftTemplateCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteShiftTemplate.
                     * @function deleteShiftTemplate
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteShiftTemplateRequest} request DeleteShiftTemplateRequest message or plain object
                     * @param {palexy.streaming.v1.ShiftTemplateService.DeleteShiftTemplateCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShiftTemplateService.prototype.deleteShiftTemplate = function deleteShiftTemplate(request, callback) {
                        return this.rpcCall(deleteShiftTemplate, $root.palexy.streaming.v1.DeleteShiftTemplateRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteShiftTemplate" });
    
                    /**
                     * Calls DeleteShiftTemplate.
                     * @function deleteShiftTemplate
                     * @memberof palexy.streaming.v1.ShiftTemplateService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteShiftTemplateRequest} request DeleteShiftTemplateRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return ShiftTemplateService;
                })();
    
                v1.GetShiftTemplateRequest = (function() {
    
                    /**
                     * Properties of a GetShiftTemplateRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetShiftTemplateRequest
                     * @property {number|null} [shiftTemplateId] GetShiftTemplateRequest shiftTemplateId
                     */
    
                    /**
                     * Constructs a new GetShiftTemplateRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetShiftTemplateRequest.
                     * @implements IGetShiftTemplateRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetShiftTemplateRequest=} [properties] Properties to set
                     */
                    function GetShiftTemplateRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetShiftTemplateRequest shiftTemplateId.
                     * @member {number} shiftTemplateId
                     * @memberof palexy.streaming.v1.GetShiftTemplateRequest
                     * @instance
                     */
                    GetShiftTemplateRequest.prototype.shiftTemplateId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetShiftTemplateRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetShiftTemplateRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetShiftTemplateRequest} GetShiftTemplateRequest instance
                     */
                    GetShiftTemplateRequest.create = function create(properties) {
                        return new GetShiftTemplateRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetShiftTemplateRequest message. Does not implicitly {@link palexy.streaming.v1.GetShiftTemplateRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetShiftTemplateRequest} message GetShiftTemplateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetShiftTemplateRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.shiftTemplateId != null && Object.hasOwnProperty.call(message, "shiftTemplateId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.shiftTemplateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetShiftTemplateRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetShiftTemplateRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetShiftTemplateRequest} message GetShiftTemplateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetShiftTemplateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetShiftTemplateRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetShiftTemplateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetShiftTemplateRequest} GetShiftTemplateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetShiftTemplateRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetShiftTemplateRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.shiftTemplateId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetShiftTemplateRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetShiftTemplateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetShiftTemplateRequest} GetShiftTemplateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetShiftTemplateRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetShiftTemplateRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetShiftTemplateRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetShiftTemplateRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.shiftTemplateId != null && message.hasOwnProperty("shiftTemplateId"))
                            if (!$util.isInteger(message.shiftTemplateId) && !(message.shiftTemplateId && $util.isInteger(message.shiftTemplateId.low) && $util.isInteger(message.shiftTemplateId.high)))
                                return "shiftTemplateId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetShiftTemplateRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetShiftTemplateRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetShiftTemplateRequest} GetShiftTemplateRequest
                     */
                    GetShiftTemplateRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetShiftTemplateRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetShiftTemplateRequest();
                        if (object.shiftTemplateId != null)
                            if ($util.Long)
                                (message.shiftTemplateId = $util.Long.fromValue(object.shiftTemplateId)).unsigned = false;
                            else if (typeof object.shiftTemplateId === "string")
                                message.shiftTemplateId = parseInt(object.shiftTemplateId, 10);
                            else if (typeof object.shiftTemplateId === "number")
                                message.shiftTemplateId = object.shiftTemplateId;
                            else if (typeof object.shiftTemplateId === "object")
                                message.shiftTemplateId = new $util.LongBits(object.shiftTemplateId.low >>> 0, object.shiftTemplateId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetShiftTemplateRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.GetShiftTemplateRequest} message GetShiftTemplateRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetShiftTemplateRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.shiftTemplateId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.shiftTemplateId = options.longs === String ? "0" : 0;
                        if (message.shiftTemplateId != null && message.hasOwnProperty("shiftTemplateId"))
                            if (typeof message.shiftTemplateId === "number")
                                object.shiftTemplateId = options.longs === String ? String(message.shiftTemplateId) : message.shiftTemplateId;
                            else
                                object.shiftTemplateId = options.longs === String ? $util.Long.prototype.toString.call(message.shiftTemplateId) : options.longs === Number ? new $util.LongBits(message.shiftTemplateId.low >>> 0, message.shiftTemplateId.high >>> 0).toNumber() : message.shiftTemplateId;
                        return object;
                    };
    
                    /**
                     * Converts this GetShiftTemplateRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetShiftTemplateRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetShiftTemplateRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetShiftTemplateRequest;
                })();
    
                v1.ListShiftTemplatesRequest = (function() {
    
                    /**
                     * Properties of a ListShiftTemplatesRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListShiftTemplatesRequest
                     * @property {number|null} [companyId] ListShiftTemplatesRequest companyId
                     * @property {boolean|null} [includeCreatorInfo] ListShiftTemplatesRequest includeCreatorInfo
                     */
    
                    /**
                     * Constructs a new ListShiftTemplatesRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListShiftTemplatesRequest.
                     * @implements IListShiftTemplatesRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListShiftTemplatesRequest=} [properties] Properties to set
                     */
                    function ListShiftTemplatesRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListShiftTemplatesRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListShiftTemplatesRequest
                     * @instance
                     */
                    ListShiftTemplatesRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListShiftTemplatesRequest includeCreatorInfo.
                     * @member {boolean} includeCreatorInfo
                     * @memberof palexy.streaming.v1.ListShiftTemplatesRequest
                     * @instance
                     */
                    ListShiftTemplatesRequest.prototype.includeCreatorInfo = false;
    
                    /**
                     * Creates a new ListShiftTemplatesRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListShiftTemplatesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListShiftTemplatesRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListShiftTemplatesRequest} ListShiftTemplatesRequest instance
                     */
                    ListShiftTemplatesRequest.create = function create(properties) {
                        return new ListShiftTemplatesRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListShiftTemplatesRequest message. Does not implicitly {@link palexy.streaming.v1.ListShiftTemplatesRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListShiftTemplatesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListShiftTemplatesRequest} message ListShiftTemplatesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListShiftTemplatesRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.includeCreatorInfo != null && Object.hasOwnProperty.call(message, "includeCreatorInfo"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeCreatorInfo);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListShiftTemplatesRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListShiftTemplatesRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListShiftTemplatesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListShiftTemplatesRequest} message ListShiftTemplatesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListShiftTemplatesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListShiftTemplatesRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListShiftTemplatesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListShiftTemplatesRequest} ListShiftTemplatesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListShiftTemplatesRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListShiftTemplatesRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.includeCreatorInfo = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListShiftTemplatesRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListShiftTemplatesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListShiftTemplatesRequest} ListShiftTemplatesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListShiftTemplatesRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListShiftTemplatesRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListShiftTemplatesRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListShiftTemplatesRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.includeCreatorInfo != null && message.hasOwnProperty("includeCreatorInfo"))
                            if (typeof message.includeCreatorInfo !== "boolean")
                                return "includeCreatorInfo: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListShiftTemplatesRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListShiftTemplatesRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListShiftTemplatesRequest} ListShiftTemplatesRequest
                     */
                    ListShiftTemplatesRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListShiftTemplatesRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListShiftTemplatesRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.includeCreatorInfo != null)
                            message.includeCreatorInfo = Boolean(object.includeCreatorInfo);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListShiftTemplatesRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListShiftTemplatesRequest
                     * @static
                     * @param {palexy.streaming.v1.ListShiftTemplatesRequest} message ListShiftTemplatesRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListShiftTemplatesRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.includeCreatorInfo = false;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.includeCreatorInfo != null && message.hasOwnProperty("includeCreatorInfo"))
                            object.includeCreatorInfo = message.includeCreatorInfo;
                        return object;
                    };
    
                    /**
                     * Converts this ListShiftTemplatesRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListShiftTemplatesRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListShiftTemplatesRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListShiftTemplatesRequest;
                })();
    
                v1.ListShiftTemplatesResponse = (function() {
    
                    /**
                     * Properties of a ListShiftTemplatesResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListShiftTemplatesResponse
                     * @property {Array.<palexy.streaming.v1.IShiftTemplate>|null} [shiftTemplates] ListShiftTemplatesResponse shiftTemplates
                     */
    
                    /**
                     * Constructs a new ListShiftTemplatesResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListShiftTemplatesResponse.
                     * @implements IListShiftTemplatesResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListShiftTemplatesResponse=} [properties] Properties to set
                     */
                    function ListShiftTemplatesResponse(properties) {
                        this.shiftTemplates = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListShiftTemplatesResponse shiftTemplates.
                     * @member {Array.<palexy.streaming.v1.IShiftTemplate>} shiftTemplates
                     * @memberof palexy.streaming.v1.ListShiftTemplatesResponse
                     * @instance
                     */
                    ListShiftTemplatesResponse.prototype.shiftTemplates = $util.emptyArray;
    
                    /**
                     * Creates a new ListShiftTemplatesResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListShiftTemplatesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListShiftTemplatesResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListShiftTemplatesResponse} ListShiftTemplatesResponse instance
                     */
                    ListShiftTemplatesResponse.create = function create(properties) {
                        return new ListShiftTemplatesResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListShiftTemplatesResponse message. Does not implicitly {@link palexy.streaming.v1.ListShiftTemplatesResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListShiftTemplatesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListShiftTemplatesResponse} message ListShiftTemplatesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListShiftTemplatesResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.shiftTemplates != null && message.shiftTemplates.length)
                            for (var i = 0; i < message.shiftTemplates.length; ++i)
                                $root.palexy.streaming.v1.ShiftTemplate.encode(message.shiftTemplates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListShiftTemplatesResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListShiftTemplatesResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListShiftTemplatesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListShiftTemplatesResponse} message ListShiftTemplatesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListShiftTemplatesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListShiftTemplatesResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListShiftTemplatesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListShiftTemplatesResponse} ListShiftTemplatesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListShiftTemplatesResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListShiftTemplatesResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.shiftTemplates && message.shiftTemplates.length))
                                    message.shiftTemplates = [];
                                message.shiftTemplates.push($root.palexy.streaming.v1.ShiftTemplate.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListShiftTemplatesResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListShiftTemplatesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListShiftTemplatesResponse} ListShiftTemplatesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListShiftTemplatesResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListShiftTemplatesResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListShiftTemplatesResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListShiftTemplatesResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.shiftTemplates != null && message.hasOwnProperty("shiftTemplates")) {
                            if (!Array.isArray(message.shiftTemplates))
                                return "shiftTemplates: array expected";
                            for (var i = 0; i < message.shiftTemplates.length; ++i) {
                                var error = $root.palexy.streaming.v1.ShiftTemplate.verify(message.shiftTemplates[i]);
                                if (error)
                                    return "shiftTemplates." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListShiftTemplatesResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListShiftTemplatesResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListShiftTemplatesResponse} ListShiftTemplatesResponse
                     */
                    ListShiftTemplatesResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListShiftTemplatesResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListShiftTemplatesResponse();
                        if (object.shiftTemplates) {
                            if (!Array.isArray(object.shiftTemplates))
                                throw TypeError(".palexy.streaming.v1.ListShiftTemplatesResponse.shiftTemplates: array expected");
                            message.shiftTemplates = [];
                            for (var i = 0; i < object.shiftTemplates.length; ++i) {
                                if (typeof object.shiftTemplates[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListShiftTemplatesResponse.shiftTemplates: object expected");
                                message.shiftTemplates[i] = $root.palexy.streaming.v1.ShiftTemplate.fromObject(object.shiftTemplates[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListShiftTemplatesResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListShiftTemplatesResponse
                     * @static
                     * @param {palexy.streaming.v1.ListShiftTemplatesResponse} message ListShiftTemplatesResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListShiftTemplatesResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.shiftTemplates = [];
                        if (message.shiftTemplates && message.shiftTemplates.length) {
                            object.shiftTemplates = [];
                            for (var j = 0; j < message.shiftTemplates.length; ++j)
                                object.shiftTemplates[j] = $root.palexy.streaming.v1.ShiftTemplate.toObject(message.shiftTemplates[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListShiftTemplatesResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListShiftTemplatesResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListShiftTemplatesResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListShiftTemplatesResponse;
                })();
    
                v1.CreateShiftTemplateRequest = (function() {
    
                    /**
                     * Properties of a CreateShiftTemplateRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateShiftTemplateRequest
                     * @property {palexy.streaming.v1.IShiftTemplate|null} [shiftTemplate] CreateShiftTemplateRequest shiftTemplate
                     */
    
                    /**
                     * Constructs a new CreateShiftTemplateRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateShiftTemplateRequest.
                     * @implements ICreateShiftTemplateRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateShiftTemplateRequest=} [properties] Properties to set
                     */
                    function CreateShiftTemplateRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateShiftTemplateRequest shiftTemplate.
                     * @member {palexy.streaming.v1.IShiftTemplate|null|undefined} shiftTemplate
                     * @memberof palexy.streaming.v1.CreateShiftTemplateRequest
                     * @instance
                     */
                    CreateShiftTemplateRequest.prototype.shiftTemplate = null;
    
                    /**
                     * Creates a new CreateShiftTemplateRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateShiftTemplateRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateShiftTemplateRequest} CreateShiftTemplateRequest instance
                     */
                    CreateShiftTemplateRequest.create = function create(properties) {
                        return new CreateShiftTemplateRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateShiftTemplateRequest message. Does not implicitly {@link palexy.streaming.v1.CreateShiftTemplateRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateShiftTemplateRequest} message CreateShiftTemplateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateShiftTemplateRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.shiftTemplate != null && Object.hasOwnProperty.call(message, "shiftTemplate"))
                            $root.palexy.streaming.v1.ShiftTemplate.encode(message.shiftTemplate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateShiftTemplateRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateShiftTemplateRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateShiftTemplateRequest} message CreateShiftTemplateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateShiftTemplateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateShiftTemplateRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateShiftTemplateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateShiftTemplateRequest} CreateShiftTemplateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateShiftTemplateRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateShiftTemplateRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.shiftTemplate = $root.palexy.streaming.v1.ShiftTemplate.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateShiftTemplateRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateShiftTemplateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateShiftTemplateRequest} CreateShiftTemplateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateShiftTemplateRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateShiftTemplateRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateShiftTemplateRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateShiftTemplateRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.shiftTemplate != null && message.hasOwnProperty("shiftTemplate")) {
                            var error = $root.palexy.streaming.v1.ShiftTemplate.verify(message.shiftTemplate);
                            if (error)
                                return "shiftTemplate." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateShiftTemplateRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateShiftTemplateRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateShiftTemplateRequest} CreateShiftTemplateRequest
                     */
                    CreateShiftTemplateRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateShiftTemplateRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateShiftTemplateRequest();
                        if (object.shiftTemplate != null) {
                            if (typeof object.shiftTemplate !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateShiftTemplateRequest.shiftTemplate: object expected");
                            message.shiftTemplate = $root.palexy.streaming.v1.ShiftTemplate.fromObject(object.shiftTemplate);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateShiftTemplateRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateShiftTemplateRequest} message CreateShiftTemplateRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateShiftTemplateRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.shiftTemplate = null;
                        if (message.shiftTemplate != null && message.hasOwnProperty("shiftTemplate"))
                            object.shiftTemplate = $root.palexy.streaming.v1.ShiftTemplate.toObject(message.shiftTemplate, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateShiftTemplateRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateShiftTemplateRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateShiftTemplateRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateShiftTemplateRequest;
                })();
    
                v1.UpdateShiftTemplateRequest = (function() {
    
                    /**
                     * Properties of an UpdateShiftTemplateRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateShiftTemplateRequest
                     * @property {palexy.streaming.v1.IShiftTemplate|null} [shiftTemplate] UpdateShiftTemplateRequest shiftTemplate
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateShiftTemplateRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateShiftTemplateRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateShiftTemplateRequest.
                     * @implements IUpdateShiftTemplateRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateShiftTemplateRequest=} [properties] Properties to set
                     */
                    function UpdateShiftTemplateRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateShiftTemplateRequest shiftTemplate.
                     * @member {palexy.streaming.v1.IShiftTemplate|null|undefined} shiftTemplate
                     * @memberof palexy.streaming.v1.UpdateShiftTemplateRequest
                     * @instance
                     */
                    UpdateShiftTemplateRequest.prototype.shiftTemplate = null;
    
                    /**
                     * UpdateShiftTemplateRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateShiftTemplateRequest
                     * @instance
                     */
                    UpdateShiftTemplateRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateShiftTemplateRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateShiftTemplateRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateShiftTemplateRequest} UpdateShiftTemplateRequest instance
                     */
                    UpdateShiftTemplateRequest.create = function create(properties) {
                        return new UpdateShiftTemplateRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateShiftTemplateRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateShiftTemplateRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateShiftTemplateRequest} message UpdateShiftTemplateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateShiftTemplateRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.shiftTemplate != null && Object.hasOwnProperty.call(message, "shiftTemplate"))
                            $root.palexy.streaming.v1.ShiftTemplate.encode(message.shiftTemplate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateShiftTemplateRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateShiftTemplateRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateShiftTemplateRequest} message UpdateShiftTemplateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateShiftTemplateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateShiftTemplateRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateShiftTemplateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateShiftTemplateRequest} UpdateShiftTemplateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateShiftTemplateRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateShiftTemplateRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.shiftTemplate = $root.palexy.streaming.v1.ShiftTemplate.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateShiftTemplateRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateShiftTemplateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateShiftTemplateRequest} UpdateShiftTemplateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateShiftTemplateRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateShiftTemplateRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateShiftTemplateRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateShiftTemplateRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.shiftTemplate != null && message.hasOwnProperty("shiftTemplate")) {
                            var error = $root.palexy.streaming.v1.ShiftTemplate.verify(message.shiftTemplate);
                            if (error)
                                return "shiftTemplate." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateShiftTemplateRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateShiftTemplateRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateShiftTemplateRequest} UpdateShiftTemplateRequest
                     */
                    UpdateShiftTemplateRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateShiftTemplateRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateShiftTemplateRequest();
                        if (object.shiftTemplate != null) {
                            if (typeof object.shiftTemplate !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateShiftTemplateRequest.shiftTemplate: object expected");
                            message.shiftTemplate = $root.palexy.streaming.v1.ShiftTemplate.fromObject(object.shiftTemplate);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateShiftTemplateRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateShiftTemplateRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateShiftTemplateRequest} message UpdateShiftTemplateRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateShiftTemplateRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.shiftTemplate = null;
                            object.updateMask = null;
                        }
                        if (message.shiftTemplate != null && message.hasOwnProperty("shiftTemplate"))
                            object.shiftTemplate = $root.palexy.streaming.v1.ShiftTemplate.toObject(message.shiftTemplate, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateShiftTemplateRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateShiftTemplateRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateShiftTemplateRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateShiftTemplateRequest;
                })();
    
                v1.DeleteShiftTemplateRequest = (function() {
    
                    /**
                     * Properties of a DeleteShiftTemplateRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteShiftTemplateRequest
                     * @property {number|null} [shiftTemplateId] DeleteShiftTemplateRequest shiftTemplateId
                     */
    
                    /**
                     * Constructs a new DeleteShiftTemplateRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteShiftTemplateRequest.
                     * @implements IDeleteShiftTemplateRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteShiftTemplateRequest=} [properties] Properties to set
                     */
                    function DeleteShiftTemplateRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteShiftTemplateRequest shiftTemplateId.
                     * @member {number} shiftTemplateId
                     * @memberof palexy.streaming.v1.DeleteShiftTemplateRequest
                     * @instance
                     */
                    DeleteShiftTemplateRequest.prototype.shiftTemplateId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteShiftTemplateRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteShiftTemplateRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteShiftTemplateRequest} DeleteShiftTemplateRequest instance
                     */
                    DeleteShiftTemplateRequest.create = function create(properties) {
                        return new DeleteShiftTemplateRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteShiftTemplateRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteShiftTemplateRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteShiftTemplateRequest} message DeleteShiftTemplateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteShiftTemplateRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.shiftTemplateId != null && Object.hasOwnProperty.call(message, "shiftTemplateId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.shiftTemplateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteShiftTemplateRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteShiftTemplateRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteShiftTemplateRequest} message DeleteShiftTemplateRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteShiftTemplateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteShiftTemplateRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteShiftTemplateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteShiftTemplateRequest} DeleteShiftTemplateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteShiftTemplateRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteShiftTemplateRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.shiftTemplateId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteShiftTemplateRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteShiftTemplateRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteShiftTemplateRequest} DeleteShiftTemplateRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteShiftTemplateRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteShiftTemplateRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteShiftTemplateRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteShiftTemplateRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.shiftTemplateId != null && message.hasOwnProperty("shiftTemplateId"))
                            if (!$util.isInteger(message.shiftTemplateId) && !(message.shiftTemplateId && $util.isInteger(message.shiftTemplateId.low) && $util.isInteger(message.shiftTemplateId.high)))
                                return "shiftTemplateId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteShiftTemplateRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteShiftTemplateRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteShiftTemplateRequest} DeleteShiftTemplateRequest
                     */
                    DeleteShiftTemplateRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteShiftTemplateRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteShiftTemplateRequest();
                        if (object.shiftTemplateId != null)
                            if ($util.Long)
                                (message.shiftTemplateId = $util.Long.fromValue(object.shiftTemplateId)).unsigned = false;
                            else if (typeof object.shiftTemplateId === "string")
                                message.shiftTemplateId = parseInt(object.shiftTemplateId, 10);
                            else if (typeof object.shiftTemplateId === "number")
                                message.shiftTemplateId = object.shiftTemplateId;
                            else if (typeof object.shiftTemplateId === "object")
                                message.shiftTemplateId = new $util.LongBits(object.shiftTemplateId.low >>> 0, object.shiftTemplateId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteShiftTemplateRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteShiftTemplateRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteShiftTemplateRequest} message DeleteShiftTemplateRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteShiftTemplateRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.shiftTemplateId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.shiftTemplateId = options.longs === String ? "0" : 0;
                        if (message.shiftTemplateId != null && message.hasOwnProperty("shiftTemplateId"))
                            if (typeof message.shiftTemplateId === "number")
                                object.shiftTemplateId = options.longs === String ? String(message.shiftTemplateId) : message.shiftTemplateId;
                            else
                                object.shiftTemplateId = options.longs === String ? $util.Long.prototype.toString.call(message.shiftTemplateId) : options.longs === Number ? new $util.LongBits(message.shiftTemplateId.low >>> 0, message.shiftTemplateId.high >>> 0).toNumber() : message.shiftTemplateId;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteShiftTemplateRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteShiftTemplateRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteShiftTemplateRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteShiftTemplateRequest;
                })();
    
                v1.ShiftTemplate = (function() {
    
                    /**
                     * Properties of a ShiftTemplate.
                     * @memberof palexy.streaming.v1
                     * @interface IShiftTemplate
                     * @property {number|null} [id] ShiftTemplate id
                     * @property {number|null} [companyId] ShiftTemplate companyId
                     * @property {string|null} [shiftName] ShiftTemplate shiftName
                     * @property {string|null} [note] ShiftTemplate note
                     * @property {string|null} [startTime] ShiftTemplate startTime
                     * @property {string|null} [endTime] ShiftTemplate endTime
                     * @property {boolean|null} [isCrossDayShift] ShiftTemplate isCrossDayShift
                     * @property {string|null} [color] ShiftTemplate color
                     * @property {number|null} [creatorId] ShiftTemplate creatorId
                     * @property {number|null} [staffPositionId] ShiftTemplate staffPositionId
                     * @property {number|null} [createTimeMs] ShiftTemplate createTimeMs
                     * @property {number|null} [updateTimeMs] ShiftTemplate updateTimeMs
                     * @property {palexy.streaming.v1.IAccount|null} [creator] ShiftTemplate creator
                     * @property {palexy.streaming.v1.IStaffPosition|null} [staffPosition] ShiftTemplate staffPosition
                     * @property {boolean|null} [isCommonTemplate] ShiftTemplate isCommonTemplate
                     */
    
                    /**
                     * Constructs a new ShiftTemplate.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ShiftTemplate.
                     * @implements IShiftTemplate
                     * @constructor
                     * @param {palexy.streaming.v1.IShiftTemplate=} [properties] Properties to set
                     */
                    function ShiftTemplate(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ShiftTemplate id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ShiftTemplate companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ShiftTemplate shiftName.
                     * @member {string} shiftName
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.shiftName = "";
    
                    /**
                     * ShiftTemplate note.
                     * @member {string} note
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.note = "";
    
                    /**
                     * ShiftTemplate startTime.
                     * @member {string} startTime
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.startTime = "";
    
                    /**
                     * ShiftTemplate endTime.
                     * @member {string} endTime
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.endTime = "";
    
                    /**
                     * ShiftTemplate isCrossDayShift.
                     * @member {boolean} isCrossDayShift
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.isCrossDayShift = false;
    
                    /**
                     * ShiftTemplate color.
                     * @member {string} color
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.color = "";
    
                    /**
                     * ShiftTemplate creatorId.
                     * @member {number} creatorId
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.creatorId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ShiftTemplate staffPositionId.
                     * @member {number} staffPositionId
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.staffPositionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ShiftTemplate createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ShiftTemplate updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ShiftTemplate creator.
                     * @member {palexy.streaming.v1.IAccount|null|undefined} creator
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.creator = null;
    
                    /**
                     * ShiftTemplate staffPosition.
                     * @member {palexy.streaming.v1.IStaffPosition|null|undefined} staffPosition
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.staffPosition = null;
    
                    /**
                     * ShiftTemplate isCommonTemplate.
                     * @member {boolean} isCommonTemplate
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     */
                    ShiftTemplate.prototype.isCommonTemplate = false;
    
                    /**
                     * Creates a new ShiftTemplate instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @static
                     * @param {palexy.streaming.v1.IShiftTemplate=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ShiftTemplate} ShiftTemplate instance
                     */
                    ShiftTemplate.create = function create(properties) {
                        return new ShiftTemplate(properties);
                    };
    
                    /**
                     * Encodes the specified ShiftTemplate message. Does not implicitly {@link palexy.streaming.v1.ShiftTemplate.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @static
                     * @param {palexy.streaming.v1.IShiftTemplate} message ShiftTemplate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShiftTemplate.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.shiftName != null && Object.hasOwnProperty.call(message, "shiftName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.shiftName);
                        if (message.note != null && Object.hasOwnProperty.call(message, "note"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.note);
                        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.startTime);
                        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.endTime);
                        if (message.isCrossDayShift != null && Object.hasOwnProperty.call(message, "isCrossDayShift"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isCrossDayShift);
                        if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.color);
                        if (message.creatorId != null && Object.hasOwnProperty.call(message, "creatorId"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int64(message.creatorId);
                        if (message.staffPositionId != null && Object.hasOwnProperty.call(message, "staffPositionId"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int64(message.staffPositionId);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int64(message.updateTimeMs);
                        if (message.creator != null && Object.hasOwnProperty.call(message, "creator"))
                            $root.palexy.streaming.v1.Account.encode(message.creator, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.staffPosition != null && Object.hasOwnProperty.call(message, "staffPosition"))
                            $root.palexy.streaming.v1.StaffPosition.encode(message.staffPosition, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                        if (message.isCommonTemplate != null && Object.hasOwnProperty.call(message, "isCommonTemplate"))
                            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.isCommonTemplate);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ShiftTemplate message, length delimited. Does not implicitly {@link palexy.streaming.v1.ShiftTemplate.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @static
                     * @param {palexy.streaming.v1.IShiftTemplate} message ShiftTemplate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShiftTemplate.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ShiftTemplate message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ShiftTemplate} ShiftTemplate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShiftTemplate.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ShiftTemplate();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.shiftName = reader.string();
                                break;
                            case 4:
                                message.note = reader.string();
                                break;
                            case 5:
                                message.startTime = reader.string();
                                break;
                            case 6:
                                message.endTime = reader.string();
                                break;
                            case 7:
                                message.isCrossDayShift = reader.bool();
                                break;
                            case 8:
                                message.color = reader.string();
                                break;
                            case 9:
                                message.creatorId = reader.int64();
                                break;
                            case 10:
                                message.staffPositionId = reader.int64();
                                break;
                            case 11:
                                message.createTimeMs = reader.int64();
                                break;
                            case 12:
                                message.updateTimeMs = reader.int64();
                                break;
                            case 13:
                                message.creator = $root.palexy.streaming.v1.Account.decode(reader, reader.uint32());
                                break;
                            case 14:
                                message.staffPosition = $root.palexy.streaming.v1.StaffPosition.decode(reader, reader.uint32());
                                break;
                            case 15:
                                message.isCommonTemplate = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ShiftTemplate message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ShiftTemplate} ShiftTemplate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShiftTemplate.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ShiftTemplate message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShiftTemplate.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.shiftName != null && message.hasOwnProperty("shiftName"))
                            if (!$util.isString(message.shiftName))
                                return "shiftName: string expected";
                        if (message.note != null && message.hasOwnProperty("note"))
                            if (!$util.isString(message.note))
                                return "note: string expected";
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            if (!$util.isString(message.startTime))
                                return "startTime: string expected";
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            if (!$util.isString(message.endTime))
                                return "endTime: string expected";
                        if (message.isCrossDayShift != null && message.hasOwnProperty("isCrossDayShift"))
                            if (typeof message.isCrossDayShift !== "boolean")
                                return "isCrossDayShift: boolean expected";
                        if (message.color != null && message.hasOwnProperty("color"))
                            if (!$util.isString(message.color))
                                return "color: string expected";
                        if (message.creatorId != null && message.hasOwnProperty("creatorId"))
                            if (!$util.isInteger(message.creatorId) && !(message.creatorId && $util.isInteger(message.creatorId.low) && $util.isInteger(message.creatorId.high)))
                                return "creatorId: integer|Long expected";
                        if (message.staffPositionId != null && message.hasOwnProperty("staffPositionId"))
                            if (!$util.isInteger(message.staffPositionId) && !(message.staffPositionId && $util.isInteger(message.staffPositionId.low) && $util.isInteger(message.staffPositionId.high)))
                                return "staffPositionId: integer|Long expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        if (message.creator != null && message.hasOwnProperty("creator")) {
                            var error = $root.palexy.streaming.v1.Account.verify(message.creator);
                            if (error)
                                return "creator." + error;
                        }
                        if (message.staffPosition != null && message.hasOwnProperty("staffPosition")) {
                            var error = $root.palexy.streaming.v1.StaffPosition.verify(message.staffPosition);
                            if (error)
                                return "staffPosition." + error;
                        }
                        if (message.isCommonTemplate != null && message.hasOwnProperty("isCommonTemplate"))
                            if (typeof message.isCommonTemplate !== "boolean")
                                return "isCommonTemplate: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ShiftTemplate message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ShiftTemplate} ShiftTemplate
                     */
                    ShiftTemplate.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ShiftTemplate)
                            return object;
                        var message = new $root.palexy.streaming.v1.ShiftTemplate();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.shiftName != null)
                            message.shiftName = String(object.shiftName);
                        if (object.note != null)
                            message.note = String(object.note);
                        if (object.startTime != null)
                            message.startTime = String(object.startTime);
                        if (object.endTime != null)
                            message.endTime = String(object.endTime);
                        if (object.isCrossDayShift != null)
                            message.isCrossDayShift = Boolean(object.isCrossDayShift);
                        if (object.color != null)
                            message.color = String(object.color);
                        if (object.creatorId != null)
                            if ($util.Long)
                                (message.creatorId = $util.Long.fromValue(object.creatorId)).unsigned = false;
                            else if (typeof object.creatorId === "string")
                                message.creatorId = parseInt(object.creatorId, 10);
                            else if (typeof object.creatorId === "number")
                                message.creatorId = object.creatorId;
                            else if (typeof object.creatorId === "object")
                                message.creatorId = new $util.LongBits(object.creatorId.low >>> 0, object.creatorId.high >>> 0).toNumber();
                        if (object.staffPositionId != null)
                            if ($util.Long)
                                (message.staffPositionId = $util.Long.fromValue(object.staffPositionId)).unsigned = false;
                            else if (typeof object.staffPositionId === "string")
                                message.staffPositionId = parseInt(object.staffPositionId, 10);
                            else if (typeof object.staffPositionId === "number")
                                message.staffPositionId = object.staffPositionId;
                            else if (typeof object.staffPositionId === "object")
                                message.staffPositionId = new $util.LongBits(object.staffPositionId.low >>> 0, object.staffPositionId.high >>> 0).toNumber();
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        if (object.creator != null) {
                            if (typeof object.creator !== "object")
                                throw TypeError(".palexy.streaming.v1.ShiftTemplate.creator: object expected");
                            message.creator = $root.palexy.streaming.v1.Account.fromObject(object.creator);
                        }
                        if (object.staffPosition != null) {
                            if (typeof object.staffPosition !== "object")
                                throw TypeError(".palexy.streaming.v1.ShiftTemplate.staffPosition: object expected");
                            message.staffPosition = $root.palexy.streaming.v1.StaffPosition.fromObject(object.staffPosition);
                        }
                        if (object.isCommonTemplate != null)
                            message.isCommonTemplate = Boolean(object.isCommonTemplate);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ShiftTemplate message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @static
                     * @param {palexy.streaming.v1.ShiftTemplate} message ShiftTemplate
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShiftTemplate.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.shiftName = "";
                            object.note = "";
                            object.startTime = "";
                            object.endTime = "";
                            object.isCrossDayShift = false;
                            object.color = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.creatorId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.creatorId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffPositionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffPositionId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                            object.creator = null;
                            object.staffPosition = null;
                            object.isCommonTemplate = false;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.shiftName != null && message.hasOwnProperty("shiftName"))
                            object.shiftName = message.shiftName;
                        if (message.note != null && message.hasOwnProperty("note"))
                            object.note = message.note;
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            object.startTime = message.startTime;
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            object.endTime = message.endTime;
                        if (message.isCrossDayShift != null && message.hasOwnProperty("isCrossDayShift"))
                            object.isCrossDayShift = message.isCrossDayShift;
                        if (message.color != null && message.hasOwnProperty("color"))
                            object.color = message.color;
                        if (message.creatorId != null && message.hasOwnProperty("creatorId"))
                            if (typeof message.creatorId === "number")
                                object.creatorId = options.longs === String ? String(message.creatorId) : message.creatorId;
                            else
                                object.creatorId = options.longs === String ? $util.Long.prototype.toString.call(message.creatorId) : options.longs === Number ? new $util.LongBits(message.creatorId.low >>> 0, message.creatorId.high >>> 0).toNumber() : message.creatorId;
                        if (message.staffPositionId != null && message.hasOwnProperty("staffPositionId"))
                            if (typeof message.staffPositionId === "number")
                                object.staffPositionId = options.longs === String ? String(message.staffPositionId) : message.staffPositionId;
                            else
                                object.staffPositionId = options.longs === String ? $util.Long.prototype.toString.call(message.staffPositionId) : options.longs === Number ? new $util.LongBits(message.staffPositionId.low >>> 0, message.staffPositionId.high >>> 0).toNumber() : message.staffPositionId;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        if (message.creator != null && message.hasOwnProperty("creator"))
                            object.creator = $root.palexy.streaming.v1.Account.toObject(message.creator, options);
                        if (message.staffPosition != null && message.hasOwnProperty("staffPosition"))
                            object.staffPosition = $root.palexy.streaming.v1.StaffPosition.toObject(message.staffPosition, options);
                        if (message.isCommonTemplate != null && message.hasOwnProperty("isCommonTemplate"))
                            object.isCommonTemplate = message.isCommonTemplate;
                        return object;
                    };
    
                    /**
                     * Converts this ShiftTemplate to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ShiftTemplate
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShiftTemplate.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ShiftTemplate;
                })();
    
                v1.TimeOffTypeService = (function() {
    
                    /**
                     * Constructs a new TimeOffTypeService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a TimeOffTypeService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function TimeOffTypeService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (TimeOffTypeService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TimeOffTypeService;
    
                    /**
                     * Creates new TimeOffTypeService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {TimeOffTypeService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    TimeOffTypeService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.TimeOffTypeService#listTimeOffTypes}.
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @typedef ListTimeOffTypesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListTimeOffTypesResponse} [response] ListTimeOffTypesResponse
                     */
    
                    /**
                     * Calls ListTimeOffTypes.
                     * @function listTimeOffTypes
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @instance
                     * @param {palexy.streaming.v1.IListTimeOffTypesRequest} request ListTimeOffTypesRequest message or plain object
                     * @param {palexy.streaming.v1.TimeOffTypeService.ListTimeOffTypesCallback} callback Node-style callback called with the error, if any, and ListTimeOffTypesResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(TimeOffTypeService.prototype.listTimeOffTypes = function listTimeOffTypes(request, callback) {
                        return this.rpcCall(listTimeOffTypes, $root.palexy.streaming.v1.ListTimeOffTypesRequest, $root.palexy.streaming.v1.ListTimeOffTypesResponse, request, callback);
                    }, "name", { value: "ListTimeOffTypes" });
    
                    /**
                     * Calls ListTimeOffTypes.
                     * @function listTimeOffTypes
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @instance
                     * @param {palexy.streaming.v1.IListTimeOffTypesRequest} request ListTimeOffTypesRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListTimeOffTypesResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.TimeOffTypeService#createTimeOffType}.
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @typedef CreateTimeOffTypeCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.TimeOffType} [response] TimeOffType
                     */
    
                    /**
                     * Calls CreateTimeOffType.
                     * @function createTimeOffType
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateTimeOffTypeRequest} request CreateTimeOffTypeRequest message or plain object
                     * @param {palexy.streaming.v1.TimeOffTypeService.CreateTimeOffTypeCallback} callback Node-style callback called with the error, if any, and TimeOffType
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(TimeOffTypeService.prototype.createTimeOffType = function createTimeOffType(request, callback) {
                        return this.rpcCall(createTimeOffType, $root.palexy.streaming.v1.CreateTimeOffTypeRequest, $root.palexy.streaming.v1.TimeOffType, request, callback);
                    }, "name", { value: "CreateTimeOffType" });
    
                    /**
                     * Calls CreateTimeOffType.
                     * @function createTimeOffType
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateTimeOffTypeRequest} request CreateTimeOffTypeRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.TimeOffType>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.TimeOffTypeService#updateTimeOffType}.
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @typedef UpdateTimeOffTypeCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.TimeOffType} [response] TimeOffType
                     */
    
                    /**
                     * Calls UpdateTimeOffType.
                     * @function updateTimeOffType
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateTimeOffTypeRequest} request UpdateTimeOffTypeRequest message or plain object
                     * @param {palexy.streaming.v1.TimeOffTypeService.UpdateTimeOffTypeCallback} callback Node-style callback called with the error, if any, and TimeOffType
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(TimeOffTypeService.prototype.updateTimeOffType = function updateTimeOffType(request, callback) {
                        return this.rpcCall(updateTimeOffType, $root.palexy.streaming.v1.UpdateTimeOffTypeRequest, $root.palexy.streaming.v1.TimeOffType, request, callback);
                    }, "name", { value: "UpdateTimeOffType" });
    
                    /**
                     * Calls UpdateTimeOffType.
                     * @function updateTimeOffType
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateTimeOffTypeRequest} request UpdateTimeOffTypeRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.TimeOffType>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.TimeOffTypeService#getTimeOffType}.
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @typedef GetTimeOffTypeCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.TimeOffType} [response] TimeOffType
                     */
    
                    /**
                     * Calls GetTimeOffType.
                     * @function getTimeOffType
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @instance
                     * @param {palexy.streaming.v1.IGetTimeOffTypeRequest} request GetTimeOffTypeRequest message or plain object
                     * @param {palexy.streaming.v1.TimeOffTypeService.GetTimeOffTypeCallback} callback Node-style callback called with the error, if any, and TimeOffType
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(TimeOffTypeService.prototype.getTimeOffType = function getTimeOffType(request, callback) {
                        return this.rpcCall(getTimeOffType, $root.palexy.streaming.v1.GetTimeOffTypeRequest, $root.palexy.streaming.v1.TimeOffType, request, callback);
                    }, "name", { value: "GetTimeOffType" });
    
                    /**
                     * Calls GetTimeOffType.
                     * @function getTimeOffType
                     * @memberof palexy.streaming.v1.TimeOffTypeService
                     * @instance
                     * @param {palexy.streaming.v1.IGetTimeOffTypeRequest} request GetTimeOffTypeRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.TimeOffType>} Promise
                     * @variation 2
                     */
    
                    return TimeOffTypeService;
                })();
    
                v1.ListTimeOffTypesRequest = (function() {
    
                    /**
                     * Properties of a ListTimeOffTypesRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListTimeOffTypesRequest
                     * @property {number|null} [companyId] ListTimeOffTypesRequest companyId
                     * @property {boolean|null} [includeDisabled] ListTimeOffTypesRequest includeDisabled
                     */
    
                    /**
                     * Constructs a new ListTimeOffTypesRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListTimeOffTypesRequest.
                     * @implements IListTimeOffTypesRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListTimeOffTypesRequest=} [properties] Properties to set
                     */
                    function ListTimeOffTypesRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListTimeOffTypesRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.ListTimeOffTypesRequest
                     * @instance
                     */
                    ListTimeOffTypesRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListTimeOffTypesRequest includeDisabled.
                     * @member {boolean} includeDisabled
                     * @memberof palexy.streaming.v1.ListTimeOffTypesRequest
                     * @instance
                     */
                    ListTimeOffTypesRequest.prototype.includeDisabled = false;
    
                    /**
                     * Creates a new ListTimeOffTypesRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListTimeOffTypesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListTimeOffTypesRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListTimeOffTypesRequest} ListTimeOffTypesRequest instance
                     */
                    ListTimeOffTypesRequest.create = function create(properties) {
                        return new ListTimeOffTypesRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListTimeOffTypesRequest message. Does not implicitly {@link palexy.streaming.v1.ListTimeOffTypesRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListTimeOffTypesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListTimeOffTypesRequest} message ListTimeOffTypesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTimeOffTypesRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.includeDisabled != null && Object.hasOwnProperty.call(message, "includeDisabled"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeDisabled);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListTimeOffTypesRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListTimeOffTypesRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListTimeOffTypesRequest
                     * @static
                     * @param {palexy.streaming.v1.IListTimeOffTypesRequest} message ListTimeOffTypesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTimeOffTypesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListTimeOffTypesRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListTimeOffTypesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListTimeOffTypesRequest} ListTimeOffTypesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTimeOffTypesRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListTimeOffTypesRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.includeDisabled = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListTimeOffTypesRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListTimeOffTypesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListTimeOffTypesRequest} ListTimeOffTypesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTimeOffTypesRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListTimeOffTypesRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListTimeOffTypesRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListTimeOffTypesRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.includeDisabled != null && message.hasOwnProperty("includeDisabled"))
                            if (typeof message.includeDisabled !== "boolean")
                                return "includeDisabled: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListTimeOffTypesRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListTimeOffTypesRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListTimeOffTypesRequest} ListTimeOffTypesRequest
                     */
                    ListTimeOffTypesRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListTimeOffTypesRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListTimeOffTypesRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.includeDisabled != null)
                            message.includeDisabled = Boolean(object.includeDisabled);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListTimeOffTypesRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListTimeOffTypesRequest
                     * @static
                     * @param {palexy.streaming.v1.ListTimeOffTypesRequest} message ListTimeOffTypesRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListTimeOffTypesRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.includeDisabled = false;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.includeDisabled != null && message.hasOwnProperty("includeDisabled"))
                            object.includeDisabled = message.includeDisabled;
                        return object;
                    };
    
                    /**
                     * Converts this ListTimeOffTypesRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListTimeOffTypesRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListTimeOffTypesRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListTimeOffTypesRequest;
                })();
    
                v1.ListTimeOffTypesResponse = (function() {
    
                    /**
                     * Properties of a ListTimeOffTypesResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListTimeOffTypesResponse
                     * @property {Array.<palexy.streaming.v1.ITimeOffType>|null} [timeOffTypes] ListTimeOffTypesResponse timeOffTypes
                     */
    
                    /**
                     * Constructs a new ListTimeOffTypesResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListTimeOffTypesResponse.
                     * @implements IListTimeOffTypesResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListTimeOffTypesResponse=} [properties] Properties to set
                     */
                    function ListTimeOffTypesResponse(properties) {
                        this.timeOffTypes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListTimeOffTypesResponse timeOffTypes.
                     * @member {Array.<palexy.streaming.v1.ITimeOffType>} timeOffTypes
                     * @memberof palexy.streaming.v1.ListTimeOffTypesResponse
                     * @instance
                     */
                    ListTimeOffTypesResponse.prototype.timeOffTypes = $util.emptyArray;
    
                    /**
                     * Creates a new ListTimeOffTypesResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListTimeOffTypesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListTimeOffTypesResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListTimeOffTypesResponse} ListTimeOffTypesResponse instance
                     */
                    ListTimeOffTypesResponse.create = function create(properties) {
                        return new ListTimeOffTypesResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListTimeOffTypesResponse message. Does not implicitly {@link palexy.streaming.v1.ListTimeOffTypesResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListTimeOffTypesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListTimeOffTypesResponse} message ListTimeOffTypesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTimeOffTypesResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.timeOffTypes != null && message.timeOffTypes.length)
                            for (var i = 0; i < message.timeOffTypes.length; ++i)
                                $root.palexy.streaming.v1.TimeOffType.encode(message.timeOffTypes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListTimeOffTypesResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListTimeOffTypesResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListTimeOffTypesResponse
                     * @static
                     * @param {palexy.streaming.v1.IListTimeOffTypesResponse} message ListTimeOffTypesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListTimeOffTypesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListTimeOffTypesResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListTimeOffTypesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListTimeOffTypesResponse} ListTimeOffTypesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTimeOffTypesResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListTimeOffTypesResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.timeOffTypes && message.timeOffTypes.length))
                                    message.timeOffTypes = [];
                                message.timeOffTypes.push($root.palexy.streaming.v1.TimeOffType.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListTimeOffTypesResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListTimeOffTypesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListTimeOffTypesResponse} ListTimeOffTypesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListTimeOffTypesResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListTimeOffTypesResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListTimeOffTypesResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListTimeOffTypesResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.timeOffTypes != null && message.hasOwnProperty("timeOffTypes")) {
                            if (!Array.isArray(message.timeOffTypes))
                                return "timeOffTypes: array expected";
                            for (var i = 0; i < message.timeOffTypes.length; ++i) {
                                var error = $root.palexy.streaming.v1.TimeOffType.verify(message.timeOffTypes[i]);
                                if (error)
                                    return "timeOffTypes." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListTimeOffTypesResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListTimeOffTypesResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListTimeOffTypesResponse} ListTimeOffTypesResponse
                     */
                    ListTimeOffTypesResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListTimeOffTypesResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListTimeOffTypesResponse();
                        if (object.timeOffTypes) {
                            if (!Array.isArray(object.timeOffTypes))
                                throw TypeError(".palexy.streaming.v1.ListTimeOffTypesResponse.timeOffTypes: array expected");
                            message.timeOffTypes = [];
                            for (var i = 0; i < object.timeOffTypes.length; ++i) {
                                if (typeof object.timeOffTypes[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListTimeOffTypesResponse.timeOffTypes: object expected");
                                message.timeOffTypes[i] = $root.palexy.streaming.v1.TimeOffType.fromObject(object.timeOffTypes[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListTimeOffTypesResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListTimeOffTypesResponse
                     * @static
                     * @param {palexy.streaming.v1.ListTimeOffTypesResponse} message ListTimeOffTypesResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListTimeOffTypesResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.timeOffTypes = [];
                        if (message.timeOffTypes && message.timeOffTypes.length) {
                            object.timeOffTypes = [];
                            for (var j = 0; j < message.timeOffTypes.length; ++j)
                                object.timeOffTypes[j] = $root.palexy.streaming.v1.TimeOffType.toObject(message.timeOffTypes[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListTimeOffTypesResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListTimeOffTypesResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListTimeOffTypesResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListTimeOffTypesResponse;
                })();
    
                v1.CreateTimeOffTypeRequest = (function() {
    
                    /**
                     * Properties of a CreateTimeOffTypeRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateTimeOffTypeRequest
                     * @property {palexy.streaming.v1.ITimeOffType|null} [timeOffType] CreateTimeOffTypeRequest timeOffType
                     */
    
                    /**
                     * Constructs a new CreateTimeOffTypeRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateTimeOffTypeRequest.
                     * @implements ICreateTimeOffTypeRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateTimeOffTypeRequest=} [properties] Properties to set
                     */
                    function CreateTimeOffTypeRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateTimeOffTypeRequest timeOffType.
                     * @member {palexy.streaming.v1.ITimeOffType|null|undefined} timeOffType
                     * @memberof palexy.streaming.v1.CreateTimeOffTypeRequest
                     * @instance
                     */
                    CreateTimeOffTypeRequest.prototype.timeOffType = null;
    
                    /**
                     * Creates a new CreateTimeOffTypeRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateTimeOffTypeRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateTimeOffTypeRequest} CreateTimeOffTypeRequest instance
                     */
                    CreateTimeOffTypeRequest.create = function create(properties) {
                        return new CreateTimeOffTypeRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateTimeOffTypeRequest message. Does not implicitly {@link palexy.streaming.v1.CreateTimeOffTypeRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateTimeOffTypeRequest} message CreateTimeOffTypeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTimeOffTypeRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.timeOffType != null && Object.hasOwnProperty.call(message, "timeOffType"))
                            $root.palexy.streaming.v1.TimeOffType.encode(message.timeOffType, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateTimeOffTypeRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateTimeOffTypeRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateTimeOffTypeRequest} message CreateTimeOffTypeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateTimeOffTypeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateTimeOffTypeRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateTimeOffTypeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateTimeOffTypeRequest} CreateTimeOffTypeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTimeOffTypeRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateTimeOffTypeRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.timeOffType = $root.palexy.streaming.v1.TimeOffType.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateTimeOffTypeRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateTimeOffTypeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateTimeOffTypeRequest} CreateTimeOffTypeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateTimeOffTypeRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateTimeOffTypeRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateTimeOffTypeRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateTimeOffTypeRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.timeOffType != null && message.hasOwnProperty("timeOffType")) {
                            var error = $root.palexy.streaming.v1.TimeOffType.verify(message.timeOffType);
                            if (error)
                                return "timeOffType." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateTimeOffTypeRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateTimeOffTypeRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateTimeOffTypeRequest} CreateTimeOffTypeRequest
                     */
                    CreateTimeOffTypeRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateTimeOffTypeRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateTimeOffTypeRequest();
                        if (object.timeOffType != null) {
                            if (typeof object.timeOffType !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateTimeOffTypeRequest.timeOffType: object expected");
                            message.timeOffType = $root.palexy.streaming.v1.TimeOffType.fromObject(object.timeOffType);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateTimeOffTypeRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateTimeOffTypeRequest} message CreateTimeOffTypeRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateTimeOffTypeRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.timeOffType = null;
                        if (message.timeOffType != null && message.hasOwnProperty("timeOffType"))
                            object.timeOffType = $root.palexy.streaming.v1.TimeOffType.toObject(message.timeOffType, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateTimeOffTypeRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateTimeOffTypeRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateTimeOffTypeRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateTimeOffTypeRequest;
                })();
    
                v1.UpdateTimeOffTypeRequest = (function() {
    
                    /**
                     * Properties of an UpdateTimeOffTypeRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateTimeOffTypeRequest
                     * @property {palexy.streaming.v1.ITimeOffType|null} [timeOffType] UpdateTimeOffTypeRequest timeOffType
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateTimeOffTypeRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateTimeOffTypeRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateTimeOffTypeRequest.
                     * @implements IUpdateTimeOffTypeRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateTimeOffTypeRequest=} [properties] Properties to set
                     */
                    function UpdateTimeOffTypeRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateTimeOffTypeRequest timeOffType.
                     * @member {palexy.streaming.v1.ITimeOffType|null|undefined} timeOffType
                     * @memberof palexy.streaming.v1.UpdateTimeOffTypeRequest
                     * @instance
                     */
                    UpdateTimeOffTypeRequest.prototype.timeOffType = null;
    
                    /**
                     * UpdateTimeOffTypeRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateTimeOffTypeRequest
                     * @instance
                     */
                    UpdateTimeOffTypeRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateTimeOffTypeRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateTimeOffTypeRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateTimeOffTypeRequest} UpdateTimeOffTypeRequest instance
                     */
                    UpdateTimeOffTypeRequest.create = function create(properties) {
                        return new UpdateTimeOffTypeRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateTimeOffTypeRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateTimeOffTypeRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateTimeOffTypeRequest} message UpdateTimeOffTypeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateTimeOffTypeRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.timeOffType != null && Object.hasOwnProperty.call(message, "timeOffType"))
                            $root.palexy.streaming.v1.TimeOffType.encode(message.timeOffType, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateTimeOffTypeRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateTimeOffTypeRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateTimeOffTypeRequest} message UpdateTimeOffTypeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateTimeOffTypeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateTimeOffTypeRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateTimeOffTypeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateTimeOffTypeRequest} UpdateTimeOffTypeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateTimeOffTypeRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateTimeOffTypeRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.timeOffType = $root.palexy.streaming.v1.TimeOffType.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateTimeOffTypeRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateTimeOffTypeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateTimeOffTypeRequest} UpdateTimeOffTypeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateTimeOffTypeRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateTimeOffTypeRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateTimeOffTypeRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateTimeOffTypeRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.timeOffType != null && message.hasOwnProperty("timeOffType")) {
                            var error = $root.palexy.streaming.v1.TimeOffType.verify(message.timeOffType);
                            if (error)
                                return "timeOffType." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateTimeOffTypeRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateTimeOffTypeRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateTimeOffTypeRequest} UpdateTimeOffTypeRequest
                     */
                    UpdateTimeOffTypeRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateTimeOffTypeRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateTimeOffTypeRequest();
                        if (object.timeOffType != null) {
                            if (typeof object.timeOffType !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateTimeOffTypeRequest.timeOffType: object expected");
                            message.timeOffType = $root.palexy.streaming.v1.TimeOffType.fromObject(object.timeOffType);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateTimeOffTypeRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateTimeOffTypeRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateTimeOffTypeRequest} message UpdateTimeOffTypeRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateTimeOffTypeRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.timeOffType = null;
                            object.updateMask = null;
                        }
                        if (message.timeOffType != null && message.hasOwnProperty("timeOffType"))
                            object.timeOffType = $root.palexy.streaming.v1.TimeOffType.toObject(message.timeOffType, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateTimeOffTypeRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateTimeOffTypeRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateTimeOffTypeRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateTimeOffTypeRequest;
                })();
    
                v1.GetTimeOffTypeRequest = (function() {
    
                    /**
                     * Properties of a GetTimeOffTypeRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetTimeOffTypeRequest
                     * @property {number|null} [timeOffTypeId] GetTimeOffTypeRequest timeOffTypeId
                     */
    
                    /**
                     * Constructs a new GetTimeOffTypeRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetTimeOffTypeRequest.
                     * @implements IGetTimeOffTypeRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetTimeOffTypeRequest=} [properties] Properties to set
                     */
                    function GetTimeOffTypeRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetTimeOffTypeRequest timeOffTypeId.
                     * @member {number} timeOffTypeId
                     * @memberof palexy.streaming.v1.GetTimeOffTypeRequest
                     * @instance
                     */
                    GetTimeOffTypeRequest.prototype.timeOffTypeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetTimeOffTypeRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetTimeOffTypeRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetTimeOffTypeRequest} GetTimeOffTypeRequest instance
                     */
                    GetTimeOffTypeRequest.create = function create(properties) {
                        return new GetTimeOffTypeRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetTimeOffTypeRequest message. Does not implicitly {@link palexy.streaming.v1.GetTimeOffTypeRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetTimeOffTypeRequest} message GetTimeOffTypeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetTimeOffTypeRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.timeOffTypeId != null && Object.hasOwnProperty.call(message, "timeOffTypeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.timeOffTypeId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetTimeOffTypeRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetTimeOffTypeRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetTimeOffTypeRequest} message GetTimeOffTypeRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetTimeOffTypeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetTimeOffTypeRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetTimeOffTypeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetTimeOffTypeRequest} GetTimeOffTypeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetTimeOffTypeRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetTimeOffTypeRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.timeOffTypeId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetTimeOffTypeRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetTimeOffTypeRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetTimeOffTypeRequest} GetTimeOffTypeRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetTimeOffTypeRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetTimeOffTypeRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetTimeOffTypeRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetTimeOffTypeRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.timeOffTypeId != null && message.hasOwnProperty("timeOffTypeId"))
                            if (!$util.isInteger(message.timeOffTypeId) && !(message.timeOffTypeId && $util.isInteger(message.timeOffTypeId.low) && $util.isInteger(message.timeOffTypeId.high)))
                                return "timeOffTypeId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetTimeOffTypeRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetTimeOffTypeRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetTimeOffTypeRequest} GetTimeOffTypeRequest
                     */
                    GetTimeOffTypeRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetTimeOffTypeRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetTimeOffTypeRequest();
                        if (object.timeOffTypeId != null)
                            if ($util.Long)
                                (message.timeOffTypeId = $util.Long.fromValue(object.timeOffTypeId)).unsigned = false;
                            else if (typeof object.timeOffTypeId === "string")
                                message.timeOffTypeId = parseInt(object.timeOffTypeId, 10);
                            else if (typeof object.timeOffTypeId === "number")
                                message.timeOffTypeId = object.timeOffTypeId;
                            else if (typeof object.timeOffTypeId === "object")
                                message.timeOffTypeId = new $util.LongBits(object.timeOffTypeId.low >>> 0, object.timeOffTypeId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetTimeOffTypeRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetTimeOffTypeRequest
                     * @static
                     * @param {palexy.streaming.v1.GetTimeOffTypeRequest} message GetTimeOffTypeRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetTimeOffTypeRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.timeOffTypeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.timeOffTypeId = options.longs === String ? "0" : 0;
                        if (message.timeOffTypeId != null && message.hasOwnProperty("timeOffTypeId"))
                            if (typeof message.timeOffTypeId === "number")
                                object.timeOffTypeId = options.longs === String ? String(message.timeOffTypeId) : message.timeOffTypeId;
                            else
                                object.timeOffTypeId = options.longs === String ? $util.Long.prototype.toString.call(message.timeOffTypeId) : options.longs === Number ? new $util.LongBits(message.timeOffTypeId.low >>> 0, message.timeOffTypeId.high >>> 0).toNumber() : message.timeOffTypeId;
                        return object;
                    };
    
                    /**
                     * Converts this GetTimeOffTypeRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetTimeOffTypeRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetTimeOffTypeRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetTimeOffTypeRequest;
                })();
    
                v1.TimeOffType = (function() {
    
                    /**
                     * Properties of a TimeOffType.
                     * @memberof palexy.streaming.v1
                     * @interface ITimeOffType
                     * @property {number|null} [id] TimeOffType id
                     * @property {number|null} [companyId] TimeOffType companyId
                     * @property {string|null} [name] TimeOffType name
                     * @property {string|null} [shortName] TimeOffType shortName
                     * @property {string|null} [description] TimeOffType description
                     * @property {boolean|null} [isAllDay] TimeOffType isAllDay
                     * @property {number|null} [totalHours] TimeOffType totalHours
                     * @property {boolean|null} [isDisabled] TimeOffType isDisabled
                     * @property {palexy.streaming.v1.TimeOffType.PaidType|null} [paidType] TimeOffType paidType
                     * @property {number|null} [createTimeMs] TimeOffType createTimeMs
                     * @property {number|null} [updateTimeMs] TimeOffType updateTimeMs
                     */
    
                    /**
                     * Constructs a new TimeOffType.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a TimeOffType.
                     * @implements ITimeOffType
                     * @constructor
                     * @param {palexy.streaming.v1.ITimeOffType=} [properties] Properties to set
                     */
                    function TimeOffType(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TimeOffType id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     */
                    TimeOffType.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * TimeOffType companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     */
                    TimeOffType.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * TimeOffType name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     */
                    TimeOffType.prototype.name = "";
    
                    /**
                     * TimeOffType shortName.
                     * @member {string} shortName
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     */
                    TimeOffType.prototype.shortName = "";
    
                    /**
                     * TimeOffType description.
                     * @member {string} description
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     */
                    TimeOffType.prototype.description = "";
    
                    /**
                     * TimeOffType isAllDay.
                     * @member {boolean} isAllDay
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     */
                    TimeOffType.prototype.isAllDay = false;
    
                    /**
                     * TimeOffType totalHours.
                     * @member {number} totalHours
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     */
                    TimeOffType.prototype.totalHours = 0;
    
                    /**
                     * TimeOffType isDisabled.
                     * @member {boolean} isDisabled
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     */
                    TimeOffType.prototype.isDisabled = false;
    
                    /**
                     * TimeOffType paidType.
                     * @member {palexy.streaming.v1.TimeOffType.PaidType} paidType
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     */
                    TimeOffType.prototype.paidType = 0;
    
                    /**
                     * TimeOffType createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     */
                    TimeOffType.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * TimeOffType updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     */
                    TimeOffType.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new TimeOffType instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @static
                     * @param {palexy.streaming.v1.ITimeOffType=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.TimeOffType} TimeOffType instance
                     */
                    TimeOffType.create = function create(properties) {
                        return new TimeOffType(properties);
                    };
    
                    /**
                     * Encodes the specified TimeOffType message. Does not implicitly {@link palexy.streaming.v1.TimeOffType.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @static
                     * @param {palexy.streaming.v1.ITimeOffType} message TimeOffType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TimeOffType.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                        if (message.shortName != null && Object.hasOwnProperty.call(message, "shortName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.shortName);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
                        if (message.isAllDay != null && Object.hasOwnProperty.call(message, "isAllDay"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isAllDay);
                        if (message.totalHours != null && Object.hasOwnProperty.call(message, "totalHours"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.totalHours);
                        if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isDisabled);
                        if (message.paidType != null && Object.hasOwnProperty.call(message, "paidType"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.paidType);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.updateTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TimeOffType message, length delimited. Does not implicitly {@link palexy.streaming.v1.TimeOffType.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @static
                     * @param {palexy.streaming.v1.ITimeOffType} message TimeOffType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TimeOffType.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TimeOffType message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.TimeOffType} TimeOffType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TimeOffType.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.TimeOffType();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.name = reader.string();
                                break;
                            case 4:
                                message.shortName = reader.string();
                                break;
                            case 5:
                                message.description = reader.string();
                                break;
                            case 6:
                                message.isAllDay = reader.bool();
                                break;
                            case 7:
                                message.totalHours = reader.int32();
                                break;
                            case 8:
                                message.isDisabled = reader.bool();
                                break;
                            case 9:
                                message.paidType = reader.int32();
                                break;
                            case 10:
                                message.createTimeMs = reader.int64();
                                break;
                            case 11:
                                message.updateTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TimeOffType message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.TimeOffType} TimeOffType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TimeOffType.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TimeOffType message.
                     * @function verify
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TimeOffType.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.shortName != null && message.hasOwnProperty("shortName"))
                            if (!$util.isString(message.shortName))
                                return "shortName: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.isAllDay != null && message.hasOwnProperty("isAllDay"))
                            if (typeof message.isAllDay !== "boolean")
                                return "isAllDay: boolean expected";
                        if (message.totalHours != null && message.hasOwnProperty("totalHours"))
                            if (!$util.isInteger(message.totalHours))
                                return "totalHours: integer expected";
                        if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                            if (typeof message.isDisabled !== "boolean")
                                return "isDisabled: boolean expected";
                        if (message.paidType != null && message.hasOwnProperty("paidType"))
                            switch (message.paidType) {
                            default:
                                return "paidType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a TimeOffType message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.TimeOffType} TimeOffType
                     */
                    TimeOffType.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.TimeOffType)
                            return object;
                        var message = new $root.palexy.streaming.v1.TimeOffType();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.shortName != null)
                            message.shortName = String(object.shortName);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.isAllDay != null)
                            message.isAllDay = Boolean(object.isAllDay);
                        if (object.totalHours != null)
                            message.totalHours = object.totalHours | 0;
                        if (object.isDisabled != null)
                            message.isDisabled = Boolean(object.isDisabled);
                        switch (object.paidType) {
                        case "PAID":
                        case 0:
                            message.paidType = 0;
                            break;
                        case "UNPAID":
                        case 1:
                            message.paidType = 1;
                            break;
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TimeOffType message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @static
                     * @param {palexy.streaming.v1.TimeOffType} message TimeOffType
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TimeOffType.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.shortName = "";
                            object.description = "";
                            object.isAllDay = false;
                            object.totalHours = 0;
                            object.isDisabled = false;
                            object.paidType = options.enums === String ? "PAID" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.shortName != null && message.hasOwnProperty("shortName"))
                            object.shortName = message.shortName;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.isAllDay != null && message.hasOwnProperty("isAllDay"))
                            object.isAllDay = message.isAllDay;
                        if (message.totalHours != null && message.hasOwnProperty("totalHours"))
                            object.totalHours = message.totalHours;
                        if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                            object.isDisabled = message.isDisabled;
                        if (message.paidType != null && message.hasOwnProperty("paidType"))
                            object.paidType = options.enums === String ? $root.palexy.streaming.v1.TimeOffType.PaidType[message.paidType] : message.paidType;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this TimeOffType to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.TimeOffType
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TimeOffType.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * PaidType enum.
                     * @name palexy.streaming.v1.TimeOffType.PaidType
                     * @enum {number}
                     * @property {number} PAID=0 PAID value
                     * @property {number} UNPAID=1 UNPAID value
                     */
                    TimeOffType.PaidType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "PAID"] = 0;
                        values[valuesById[1] = "UNPAID"] = 1;
                        return values;
                    })();
    
                    return TimeOffType;
                })();
    
                v1.DraftCreateStaffShiftRequest = (function() {
    
                    /**
                     * Properties of a DraftCreateStaffShiftRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDraftCreateStaffShiftRequest
                     * @property {palexy.streaming.v1.IStaffShift|null} [staffShift] DraftCreateStaffShiftRequest staffShift
                     */
    
                    /**
                     * Constructs a new DraftCreateStaffShiftRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DraftCreateStaffShiftRequest.
                     * @implements IDraftCreateStaffShiftRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDraftCreateStaffShiftRequest=} [properties] Properties to set
                     */
                    function DraftCreateStaffShiftRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DraftCreateStaffShiftRequest staffShift.
                     * @member {palexy.streaming.v1.IStaffShift|null|undefined} staffShift
                     * @memberof palexy.streaming.v1.DraftCreateStaffShiftRequest
                     * @instance
                     */
                    DraftCreateStaffShiftRequest.prototype.staffShift = null;
    
                    /**
                     * Creates a new DraftCreateStaffShiftRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DraftCreateStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftCreateStaffShiftRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DraftCreateStaffShiftRequest} DraftCreateStaffShiftRequest instance
                     */
                    DraftCreateStaffShiftRequest.create = function create(properties) {
                        return new DraftCreateStaffShiftRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DraftCreateStaffShiftRequest message. Does not implicitly {@link palexy.streaming.v1.DraftCreateStaffShiftRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DraftCreateStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftCreateStaffShiftRequest} message DraftCreateStaffShiftRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftCreateStaffShiftRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffShift != null && Object.hasOwnProperty.call(message, "staffShift"))
                            $root.palexy.streaming.v1.StaffShift.encode(message.staffShift, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DraftCreateStaffShiftRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DraftCreateStaffShiftRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DraftCreateStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftCreateStaffShiftRequest} message DraftCreateStaffShiftRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftCreateStaffShiftRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DraftCreateStaffShiftRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DraftCreateStaffShiftRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DraftCreateStaffShiftRequest} DraftCreateStaffShiftRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftCreateStaffShiftRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DraftCreateStaffShiftRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffShift = $root.palexy.streaming.v1.StaffShift.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DraftCreateStaffShiftRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DraftCreateStaffShiftRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DraftCreateStaffShiftRequest} DraftCreateStaffShiftRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftCreateStaffShiftRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DraftCreateStaffShiftRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DraftCreateStaffShiftRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DraftCreateStaffShiftRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffShift != null && message.hasOwnProperty("staffShift")) {
                            var error = $root.palexy.streaming.v1.StaffShift.verify(message.staffShift);
                            if (error)
                                return "staffShift." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DraftCreateStaffShiftRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DraftCreateStaffShiftRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DraftCreateStaffShiftRequest} DraftCreateStaffShiftRequest
                     */
                    DraftCreateStaffShiftRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DraftCreateStaffShiftRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DraftCreateStaffShiftRequest();
                        if (object.staffShift != null) {
                            if (typeof object.staffShift !== "object")
                                throw TypeError(".palexy.streaming.v1.DraftCreateStaffShiftRequest.staffShift: object expected");
                            message.staffShift = $root.palexy.streaming.v1.StaffShift.fromObject(object.staffShift);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DraftCreateStaffShiftRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DraftCreateStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.DraftCreateStaffShiftRequest} message DraftCreateStaffShiftRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DraftCreateStaffShiftRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.staffShift = null;
                        if (message.staffShift != null && message.hasOwnProperty("staffShift"))
                            object.staffShift = $root.palexy.streaming.v1.StaffShift.toObject(message.staffShift, options);
                        return object;
                    };
    
                    /**
                     * Converts this DraftCreateStaffShiftRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DraftCreateStaffShiftRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DraftCreateStaffShiftRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DraftCreateStaffShiftRequest;
                })();
    
                v1.DraftEditStaffShiftRequest = (function() {
    
                    /**
                     * Properties of a DraftEditStaffShiftRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDraftEditStaffShiftRequest
                     * @property {palexy.streaming.v1.IStaffShift|null} [staffShift] DraftEditStaffShiftRequest staffShift
                     * @property {google.protobuf.IFieldMask|null} [updateMask] DraftEditStaffShiftRequest updateMask
                     */
    
                    /**
                     * Constructs a new DraftEditStaffShiftRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DraftEditStaffShiftRequest.
                     * @implements IDraftEditStaffShiftRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDraftEditStaffShiftRequest=} [properties] Properties to set
                     */
                    function DraftEditStaffShiftRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DraftEditStaffShiftRequest staffShift.
                     * @member {palexy.streaming.v1.IStaffShift|null|undefined} staffShift
                     * @memberof palexy.streaming.v1.DraftEditStaffShiftRequest
                     * @instance
                     */
                    DraftEditStaffShiftRequest.prototype.staffShift = null;
    
                    /**
                     * DraftEditStaffShiftRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.DraftEditStaffShiftRequest
                     * @instance
                     */
                    DraftEditStaffShiftRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new DraftEditStaffShiftRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DraftEditStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftEditStaffShiftRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DraftEditStaffShiftRequest} DraftEditStaffShiftRequest instance
                     */
                    DraftEditStaffShiftRequest.create = function create(properties) {
                        return new DraftEditStaffShiftRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DraftEditStaffShiftRequest message. Does not implicitly {@link palexy.streaming.v1.DraftEditStaffShiftRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DraftEditStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftEditStaffShiftRequest} message DraftEditStaffShiftRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftEditStaffShiftRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffShift != null && Object.hasOwnProperty.call(message, "staffShift"))
                            $root.palexy.streaming.v1.StaffShift.encode(message.staffShift, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DraftEditStaffShiftRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DraftEditStaffShiftRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DraftEditStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftEditStaffShiftRequest} message DraftEditStaffShiftRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftEditStaffShiftRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DraftEditStaffShiftRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DraftEditStaffShiftRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DraftEditStaffShiftRequest} DraftEditStaffShiftRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftEditStaffShiftRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DraftEditStaffShiftRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffShift = $root.palexy.streaming.v1.StaffShift.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DraftEditStaffShiftRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DraftEditStaffShiftRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DraftEditStaffShiftRequest} DraftEditStaffShiftRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftEditStaffShiftRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DraftEditStaffShiftRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DraftEditStaffShiftRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DraftEditStaffShiftRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffShift != null && message.hasOwnProperty("staffShift")) {
                            var error = $root.palexy.streaming.v1.StaffShift.verify(message.staffShift);
                            if (error)
                                return "staffShift." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DraftEditStaffShiftRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DraftEditStaffShiftRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DraftEditStaffShiftRequest} DraftEditStaffShiftRequest
                     */
                    DraftEditStaffShiftRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DraftEditStaffShiftRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DraftEditStaffShiftRequest();
                        if (object.staffShift != null) {
                            if (typeof object.staffShift !== "object")
                                throw TypeError(".palexy.streaming.v1.DraftEditStaffShiftRequest.staffShift: object expected");
                            message.staffShift = $root.palexy.streaming.v1.StaffShift.fromObject(object.staffShift);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.DraftEditStaffShiftRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DraftEditStaffShiftRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DraftEditStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.DraftEditStaffShiftRequest} message DraftEditStaffShiftRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DraftEditStaffShiftRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.staffShift = null;
                            object.updateMask = null;
                        }
                        if (message.staffShift != null && message.hasOwnProperty("staffShift"))
                            object.staffShift = $root.palexy.streaming.v1.StaffShift.toObject(message.staffShift, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this DraftEditStaffShiftRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DraftEditStaffShiftRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DraftEditStaffShiftRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DraftEditStaffShiftRequest;
                })();
    
                v1.SwapStaffShiftsRequest = (function() {
    
                    /**
                     * Properties of a SwapStaffShiftsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ISwapStaffShiftsRequest
                     * @property {number|null} [firstShiftId] SwapStaffShiftsRequest firstShiftId
                     * @property {number|null} [secondShiftId] SwapStaffShiftsRequest secondShiftId
                     */
    
                    /**
                     * Constructs a new SwapStaffShiftsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SwapStaffShiftsRequest.
                     * @implements ISwapStaffShiftsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ISwapStaffShiftsRequest=} [properties] Properties to set
                     */
                    function SwapStaffShiftsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SwapStaffShiftsRequest firstShiftId.
                     * @member {number} firstShiftId
                     * @memberof palexy.streaming.v1.SwapStaffShiftsRequest
                     * @instance
                     */
                    SwapStaffShiftsRequest.prototype.firstShiftId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * SwapStaffShiftsRequest secondShiftId.
                     * @member {number} secondShiftId
                     * @memberof palexy.streaming.v1.SwapStaffShiftsRequest
                     * @instance
                     */
                    SwapStaffShiftsRequest.prototype.secondShiftId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new SwapStaffShiftsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SwapStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.ISwapStaffShiftsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SwapStaffShiftsRequest} SwapStaffShiftsRequest instance
                     */
                    SwapStaffShiftsRequest.create = function create(properties) {
                        return new SwapStaffShiftsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified SwapStaffShiftsRequest message. Does not implicitly {@link palexy.streaming.v1.SwapStaffShiftsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SwapStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.ISwapStaffShiftsRequest} message SwapStaffShiftsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SwapStaffShiftsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.firstShiftId != null && Object.hasOwnProperty.call(message, "firstShiftId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.firstShiftId);
                        if (message.secondShiftId != null && Object.hasOwnProperty.call(message, "secondShiftId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.secondShiftId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SwapStaffShiftsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.SwapStaffShiftsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SwapStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.ISwapStaffShiftsRequest} message SwapStaffShiftsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SwapStaffShiftsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SwapStaffShiftsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SwapStaffShiftsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SwapStaffShiftsRequest} SwapStaffShiftsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SwapStaffShiftsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SwapStaffShiftsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.firstShiftId = reader.int64();
                                break;
                            case 2:
                                message.secondShiftId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SwapStaffShiftsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SwapStaffShiftsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SwapStaffShiftsRequest} SwapStaffShiftsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SwapStaffShiftsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SwapStaffShiftsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SwapStaffShiftsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SwapStaffShiftsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.firstShiftId != null && message.hasOwnProperty("firstShiftId"))
                            if (!$util.isInteger(message.firstShiftId) && !(message.firstShiftId && $util.isInteger(message.firstShiftId.low) && $util.isInteger(message.firstShiftId.high)))
                                return "firstShiftId: integer|Long expected";
                        if (message.secondShiftId != null && message.hasOwnProperty("secondShiftId"))
                            if (!$util.isInteger(message.secondShiftId) && !(message.secondShiftId && $util.isInteger(message.secondShiftId.low) && $util.isInteger(message.secondShiftId.high)))
                                return "secondShiftId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a SwapStaffShiftsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SwapStaffShiftsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SwapStaffShiftsRequest} SwapStaffShiftsRequest
                     */
                    SwapStaffShiftsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SwapStaffShiftsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.SwapStaffShiftsRequest();
                        if (object.firstShiftId != null)
                            if ($util.Long)
                                (message.firstShiftId = $util.Long.fromValue(object.firstShiftId)).unsigned = false;
                            else if (typeof object.firstShiftId === "string")
                                message.firstShiftId = parseInt(object.firstShiftId, 10);
                            else if (typeof object.firstShiftId === "number")
                                message.firstShiftId = object.firstShiftId;
                            else if (typeof object.firstShiftId === "object")
                                message.firstShiftId = new $util.LongBits(object.firstShiftId.low >>> 0, object.firstShiftId.high >>> 0).toNumber();
                        if (object.secondShiftId != null)
                            if ($util.Long)
                                (message.secondShiftId = $util.Long.fromValue(object.secondShiftId)).unsigned = false;
                            else if (typeof object.secondShiftId === "string")
                                message.secondShiftId = parseInt(object.secondShiftId, 10);
                            else if (typeof object.secondShiftId === "number")
                                message.secondShiftId = object.secondShiftId;
                            else if (typeof object.secondShiftId === "object")
                                message.secondShiftId = new $util.LongBits(object.secondShiftId.low >>> 0, object.secondShiftId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SwapStaffShiftsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SwapStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.SwapStaffShiftsRequest} message SwapStaffShiftsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SwapStaffShiftsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.firstShiftId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.firstShiftId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.secondShiftId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.secondShiftId = options.longs === String ? "0" : 0;
                        }
                        if (message.firstShiftId != null && message.hasOwnProperty("firstShiftId"))
                            if (typeof message.firstShiftId === "number")
                                object.firstShiftId = options.longs === String ? String(message.firstShiftId) : message.firstShiftId;
                            else
                                object.firstShiftId = options.longs === String ? $util.Long.prototype.toString.call(message.firstShiftId) : options.longs === Number ? new $util.LongBits(message.firstShiftId.low >>> 0, message.firstShiftId.high >>> 0).toNumber() : message.firstShiftId;
                        if (message.secondShiftId != null && message.hasOwnProperty("secondShiftId"))
                            if (typeof message.secondShiftId === "number")
                                object.secondShiftId = options.longs === String ? String(message.secondShiftId) : message.secondShiftId;
                            else
                                object.secondShiftId = options.longs === String ? $util.Long.prototype.toString.call(message.secondShiftId) : options.longs === Number ? new $util.LongBits(message.secondShiftId.low >>> 0, message.secondShiftId.high >>> 0).toNumber() : message.secondShiftId;
                        return object;
                    };
    
                    /**
                     * Converts this SwapStaffShiftsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SwapStaffShiftsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SwapStaffShiftsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SwapStaffShiftsRequest;
                })();
    
                v1.SwapStaffShiftsResponse = (function() {
    
                    /**
                     * Properties of a SwapStaffShiftsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface ISwapStaffShiftsResponse
                     * @property {palexy.streaming.v1.IStaffShift|null} [firstShift] SwapStaffShiftsResponse firstShift
                     * @property {palexy.streaming.v1.IStaffShift|null} [secondShift] SwapStaffShiftsResponse secondShift
                     */
    
                    /**
                     * Constructs a new SwapStaffShiftsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a SwapStaffShiftsResponse.
                     * @implements ISwapStaffShiftsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.ISwapStaffShiftsResponse=} [properties] Properties to set
                     */
                    function SwapStaffShiftsResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SwapStaffShiftsResponse firstShift.
                     * @member {palexy.streaming.v1.IStaffShift|null|undefined} firstShift
                     * @memberof palexy.streaming.v1.SwapStaffShiftsResponse
                     * @instance
                     */
                    SwapStaffShiftsResponse.prototype.firstShift = null;
    
                    /**
                     * SwapStaffShiftsResponse secondShift.
                     * @member {palexy.streaming.v1.IStaffShift|null|undefined} secondShift
                     * @memberof palexy.streaming.v1.SwapStaffShiftsResponse
                     * @instance
                     */
                    SwapStaffShiftsResponse.prototype.secondShift = null;
    
                    /**
                     * Creates a new SwapStaffShiftsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.SwapStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.ISwapStaffShiftsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.SwapStaffShiftsResponse} SwapStaffShiftsResponse instance
                     */
                    SwapStaffShiftsResponse.create = function create(properties) {
                        return new SwapStaffShiftsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified SwapStaffShiftsResponse message. Does not implicitly {@link palexy.streaming.v1.SwapStaffShiftsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.SwapStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.ISwapStaffShiftsResponse} message SwapStaffShiftsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SwapStaffShiftsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.firstShift != null && Object.hasOwnProperty.call(message, "firstShift"))
                            $root.palexy.streaming.v1.StaffShift.encode(message.firstShift, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.secondShift != null && Object.hasOwnProperty.call(message, "secondShift"))
                            $root.palexy.streaming.v1.StaffShift.encode(message.secondShift, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SwapStaffShiftsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.SwapStaffShiftsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.SwapStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.ISwapStaffShiftsResponse} message SwapStaffShiftsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SwapStaffShiftsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SwapStaffShiftsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.SwapStaffShiftsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.SwapStaffShiftsResponse} SwapStaffShiftsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SwapStaffShiftsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.SwapStaffShiftsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.firstShift = $root.palexy.streaming.v1.StaffShift.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.secondShift = $root.palexy.streaming.v1.StaffShift.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SwapStaffShiftsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.SwapStaffShiftsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.SwapStaffShiftsResponse} SwapStaffShiftsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SwapStaffShiftsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SwapStaffShiftsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.SwapStaffShiftsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SwapStaffShiftsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.firstShift != null && message.hasOwnProperty("firstShift")) {
                            var error = $root.palexy.streaming.v1.StaffShift.verify(message.firstShift);
                            if (error)
                                return "firstShift." + error;
                        }
                        if (message.secondShift != null && message.hasOwnProperty("secondShift")) {
                            var error = $root.palexy.streaming.v1.StaffShift.verify(message.secondShift);
                            if (error)
                                return "secondShift." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a SwapStaffShiftsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.SwapStaffShiftsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.SwapStaffShiftsResponse} SwapStaffShiftsResponse
                     */
                    SwapStaffShiftsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.SwapStaffShiftsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.SwapStaffShiftsResponse();
                        if (object.firstShift != null) {
                            if (typeof object.firstShift !== "object")
                                throw TypeError(".palexy.streaming.v1.SwapStaffShiftsResponse.firstShift: object expected");
                            message.firstShift = $root.palexy.streaming.v1.StaffShift.fromObject(object.firstShift);
                        }
                        if (object.secondShift != null) {
                            if (typeof object.secondShift !== "object")
                                throw TypeError(".palexy.streaming.v1.SwapStaffShiftsResponse.secondShift: object expected");
                            message.secondShift = $root.palexy.streaming.v1.StaffShift.fromObject(object.secondShift);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SwapStaffShiftsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.SwapStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.SwapStaffShiftsResponse} message SwapStaffShiftsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SwapStaffShiftsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.firstShift = null;
                            object.secondShift = null;
                        }
                        if (message.firstShift != null && message.hasOwnProperty("firstShift"))
                            object.firstShift = $root.palexy.streaming.v1.StaffShift.toObject(message.firstShift, options);
                        if (message.secondShift != null && message.hasOwnProperty("secondShift"))
                            object.secondShift = $root.palexy.streaming.v1.StaffShift.toObject(message.secondShift, options);
                        return object;
                    };
    
                    /**
                     * Converts this SwapStaffShiftsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.SwapStaffShiftsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SwapStaffShiftsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SwapStaffShiftsResponse;
                })();
    
                v1.DraftDeleteStaffShiftRequest = (function() {
    
                    /**
                     * Properties of a DraftDeleteStaffShiftRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDraftDeleteStaffShiftRequest
                     * @property {number|null} [staffShiftId] DraftDeleteStaffShiftRequest staffShiftId
                     * @property {number|null} [updateTimeMs] DraftDeleteStaffShiftRequest updateTimeMs
                     */
    
                    /**
                     * Constructs a new DraftDeleteStaffShiftRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DraftDeleteStaffShiftRequest.
                     * @implements IDraftDeleteStaffShiftRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDraftDeleteStaffShiftRequest=} [properties] Properties to set
                     */
                    function DraftDeleteStaffShiftRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DraftDeleteStaffShiftRequest staffShiftId.
                     * @member {number} staffShiftId
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftRequest
                     * @instance
                     */
                    DraftDeleteStaffShiftRequest.prototype.staffShiftId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DraftDeleteStaffShiftRequest updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftRequest
                     * @instance
                     */
                    DraftDeleteStaffShiftRequest.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DraftDeleteStaffShiftRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffShiftRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DraftDeleteStaffShiftRequest} DraftDeleteStaffShiftRequest instance
                     */
                    DraftDeleteStaffShiftRequest.create = function create(properties) {
                        return new DraftDeleteStaffShiftRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DraftDeleteStaffShiftRequest message. Does not implicitly {@link palexy.streaming.v1.DraftDeleteStaffShiftRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffShiftRequest} message DraftDeleteStaffShiftRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftDeleteStaffShiftRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffShiftId != null && Object.hasOwnProperty.call(message, "staffShiftId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.staffShiftId);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.updateTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DraftDeleteStaffShiftRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DraftDeleteStaffShiftRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffShiftRequest} message DraftDeleteStaffShiftRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftDeleteStaffShiftRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DraftDeleteStaffShiftRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DraftDeleteStaffShiftRequest} DraftDeleteStaffShiftRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftDeleteStaffShiftRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DraftDeleteStaffShiftRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffShiftId = reader.int64();
                                break;
                            case 2:
                                message.updateTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DraftDeleteStaffShiftRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DraftDeleteStaffShiftRequest} DraftDeleteStaffShiftRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftDeleteStaffShiftRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DraftDeleteStaffShiftRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DraftDeleteStaffShiftRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffShiftId != null && message.hasOwnProperty("staffShiftId"))
                            if (!$util.isInteger(message.staffShiftId) && !(message.staffShiftId && $util.isInteger(message.staffShiftId.low) && $util.isInteger(message.staffShiftId.high)))
                                return "staffShiftId: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DraftDeleteStaffShiftRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DraftDeleteStaffShiftRequest} DraftDeleteStaffShiftRequest
                     */
                    DraftDeleteStaffShiftRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DraftDeleteStaffShiftRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DraftDeleteStaffShiftRequest();
                        if (object.staffShiftId != null)
                            if ($util.Long)
                                (message.staffShiftId = $util.Long.fromValue(object.staffShiftId)).unsigned = false;
                            else if (typeof object.staffShiftId === "string")
                                message.staffShiftId = parseInt(object.staffShiftId, 10);
                            else if (typeof object.staffShiftId === "number")
                                message.staffShiftId = object.staffShiftId;
                            else if (typeof object.staffShiftId === "object")
                                message.staffShiftId = new $util.LongBits(object.staffShiftId.low >>> 0, object.staffShiftId.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DraftDeleteStaffShiftRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.DraftDeleteStaffShiftRequest} message DraftDeleteStaffShiftRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DraftDeleteStaffShiftRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffShiftId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffShiftId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.staffShiftId != null && message.hasOwnProperty("staffShiftId"))
                            if (typeof message.staffShiftId === "number")
                                object.staffShiftId = options.longs === String ? String(message.staffShiftId) : message.staffShiftId;
                            else
                                object.staffShiftId = options.longs === String ? $util.Long.prototype.toString.call(message.staffShiftId) : options.longs === Number ? new $util.LongBits(message.staffShiftId.low >>> 0, message.staffShiftId.high >>> 0).toNumber() : message.staffShiftId;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this DraftDeleteStaffShiftRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DraftDeleteStaffShiftRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DraftDeleteStaffShiftRequest;
                })();
    
                v1.DraftDeleteStaffShiftResponse = (function() {
    
                    /**
                     * Properties of a DraftDeleteStaffShiftResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IDraftDeleteStaffShiftResponse
                     * @property {palexy.streaming.v1.IStaffShift|null} [staffShift] DraftDeleteStaffShiftResponse staffShift
                     */
    
                    /**
                     * Constructs a new DraftDeleteStaffShiftResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DraftDeleteStaffShiftResponse.
                     * @implements IDraftDeleteStaffShiftResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IDraftDeleteStaffShiftResponse=} [properties] Properties to set
                     */
                    function DraftDeleteStaffShiftResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DraftDeleteStaffShiftResponse staffShift.
                     * @member {palexy.streaming.v1.IStaffShift|null|undefined} staffShift
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftResponse
                     * @instance
                     */
                    DraftDeleteStaffShiftResponse.prototype.staffShift = null;
    
                    /**
                     * Creates a new DraftDeleteStaffShiftResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftResponse
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffShiftResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DraftDeleteStaffShiftResponse} DraftDeleteStaffShiftResponse instance
                     */
                    DraftDeleteStaffShiftResponse.create = function create(properties) {
                        return new DraftDeleteStaffShiftResponse(properties);
                    };
    
                    /**
                     * Encodes the specified DraftDeleteStaffShiftResponse message. Does not implicitly {@link palexy.streaming.v1.DraftDeleteStaffShiftResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftResponse
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffShiftResponse} message DraftDeleteStaffShiftResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftDeleteStaffShiftResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffShift != null && Object.hasOwnProperty.call(message, "staffShift"))
                            $root.palexy.streaming.v1.StaffShift.encode(message.staffShift, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DraftDeleteStaffShiftResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.DraftDeleteStaffShiftResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftResponse
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffShiftResponse} message DraftDeleteStaffShiftResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftDeleteStaffShiftResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DraftDeleteStaffShiftResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DraftDeleteStaffShiftResponse} DraftDeleteStaffShiftResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftDeleteStaffShiftResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DraftDeleteStaffShiftResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffShift = $root.palexy.streaming.v1.StaffShift.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DraftDeleteStaffShiftResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DraftDeleteStaffShiftResponse} DraftDeleteStaffShiftResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftDeleteStaffShiftResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DraftDeleteStaffShiftResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DraftDeleteStaffShiftResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffShift != null && message.hasOwnProperty("staffShift")) {
                            var error = $root.palexy.streaming.v1.StaffShift.verify(message.staffShift);
                            if (error)
                                return "staffShift." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DraftDeleteStaffShiftResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DraftDeleteStaffShiftResponse} DraftDeleteStaffShiftResponse
                     */
                    DraftDeleteStaffShiftResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DraftDeleteStaffShiftResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.DraftDeleteStaffShiftResponse();
                        if (object.staffShift != null) {
                            if (typeof object.staffShift !== "object")
                                throw TypeError(".palexy.streaming.v1.DraftDeleteStaffShiftResponse.staffShift: object expected");
                            message.staffShift = $root.palexy.streaming.v1.StaffShift.fromObject(object.staffShift);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DraftDeleteStaffShiftResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftResponse
                     * @static
                     * @param {palexy.streaming.v1.DraftDeleteStaffShiftResponse} message DraftDeleteStaffShiftResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DraftDeleteStaffShiftResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.staffShift = null;
                        if (message.staffShift != null && message.hasOwnProperty("staffShift"))
                            object.staffShift = $root.palexy.streaming.v1.StaffShift.toObject(message.staffShift, options);
                        return object;
                    };
    
                    /**
                     * Converts this DraftDeleteStaffShiftResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DraftDeleteStaffShiftResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DraftDeleteStaffShiftResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DraftDeleteStaffShiftResponse;
                })();
    
                v1.GetStaffShiftRequest = (function() {
    
                    /**
                     * Properties of a GetStaffShiftRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStaffShiftRequest
                     * @property {number|null} [staffShiftId] GetStaffShiftRequest staffShiftId
                     */
    
                    /**
                     * Constructs a new GetStaffShiftRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStaffShiftRequest.
                     * @implements IGetStaffShiftRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStaffShiftRequest=} [properties] Properties to set
                     */
                    function GetStaffShiftRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStaffShiftRequest staffShiftId.
                     * @member {number} staffShiftId
                     * @memberof palexy.streaming.v1.GetStaffShiftRequest
                     * @instance
                     */
                    GetStaffShiftRequest.prototype.staffShiftId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetStaffShiftRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffShiftRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStaffShiftRequest} GetStaffShiftRequest instance
                     */
                    GetStaffShiftRequest.create = function create(properties) {
                        return new GetStaffShiftRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStaffShiftRequest message. Does not implicitly {@link palexy.streaming.v1.GetStaffShiftRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffShiftRequest} message GetStaffShiftRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffShiftRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffShiftId != null && Object.hasOwnProperty.call(message, "staffShiftId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.staffShiftId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStaffShiftRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStaffShiftRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffShiftRequest} message GetStaffShiftRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffShiftRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStaffShiftRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStaffShiftRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStaffShiftRequest} GetStaffShiftRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffShiftRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStaffShiftRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffShiftId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStaffShiftRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffShiftRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStaffShiftRequest} GetStaffShiftRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffShiftRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStaffShiftRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStaffShiftRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStaffShiftRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffShiftId != null && message.hasOwnProperty("staffShiftId"))
                            if (!$util.isInteger(message.staffShiftId) && !(message.staffShiftId && $util.isInteger(message.staffShiftId.low) && $util.isInteger(message.staffShiftId.high)))
                                return "staffShiftId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStaffShiftRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStaffShiftRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStaffShiftRequest} GetStaffShiftRequest
                     */
                    GetStaffShiftRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStaffShiftRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStaffShiftRequest();
                        if (object.staffShiftId != null)
                            if ($util.Long)
                                (message.staffShiftId = $util.Long.fromValue(object.staffShiftId)).unsigned = false;
                            else if (typeof object.staffShiftId === "string")
                                message.staffShiftId = parseInt(object.staffShiftId, 10);
                            else if (typeof object.staffShiftId === "number")
                                message.staffShiftId = object.staffShiftId;
                            else if (typeof object.staffShiftId === "object")
                                message.staffShiftId = new $util.LongBits(object.staffShiftId.low >>> 0, object.staffShiftId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStaffShiftRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStaffShiftRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStaffShiftRequest} message GetStaffShiftRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStaffShiftRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffShiftId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffShiftId = options.longs === String ? "0" : 0;
                        if (message.staffShiftId != null && message.hasOwnProperty("staffShiftId"))
                            if (typeof message.staffShiftId === "number")
                                object.staffShiftId = options.longs === String ? String(message.staffShiftId) : message.staffShiftId;
                            else
                                object.staffShiftId = options.longs === String ? $util.Long.prototype.toString.call(message.staffShiftId) : options.longs === Number ? new $util.LongBits(message.staffShiftId.low >>> 0, message.staffShiftId.high >>> 0).toNumber() : message.staffShiftId;
                        return object;
                    };
    
                    /**
                     * Converts this GetStaffShiftRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStaffShiftRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStaffShiftRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStaffShiftRequest;
                })();
    
                v1.StaffShift = (function() {
    
                    /**
                     * Properties of a StaffShift.
                     * @memberof palexy.streaming.v1
                     * @interface IStaffShift
                     * @property {number|null} [id] StaffShift id
                     * @property {number|null} [storeId] StaffShift storeId
                     * @property {number|null} [staffId] StaffShift staffId
                     * @property {number|null} [dateId] StaffShift dateId
                     * @property {string|null} [note] StaffShift note
                     * @property {string|null} [startTime] StaffShift startTime
                     * @property {string|null} [endTime] StaffShift endTime
                     * @property {boolean|null} [isCrossDayShift] StaffShift isCrossDayShift
                     * @property {string|null} [color] StaffShift color
                     * @property {string|null} [shiftName] StaffShift shiftName
                     * @property {number|null} [staffPositionId] StaffShift staffPositionId
                     * @property {number|null} [createTimeMs] StaffShift createTimeMs
                     * @property {number|null} [updateTimeMs] StaffShift updateTimeMs
                     * @property {number|null} [originalId] StaffShift originalId
                     * @property {palexy.streaming.v1.StaffScheduleState|null} [state] StaffShift state
                     * @property {palexy.streaming.v1.IStaffPosition|null} [staffPosition] StaffShift staffPosition
                     * @property {palexy.streaming.v1.IStaff|null} [staff] StaffShift staff
                     */
    
                    /**
                     * Constructs a new StaffShift.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffShift.
                     * @implements IStaffShift
                     * @constructor
                     * @param {palexy.streaming.v1.IStaffShift=} [properties] Properties to set
                     */
                    function StaffShift(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StaffShift id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffShift storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffShift staffId.
                     * @member {number} staffId
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.staffId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffShift dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.dateId = 0;
    
                    /**
                     * StaffShift note.
                     * @member {string} note
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.note = "";
    
                    /**
                     * StaffShift startTime.
                     * @member {string} startTime
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.startTime = "";
    
                    /**
                     * StaffShift endTime.
                     * @member {string} endTime
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.endTime = "";
    
                    /**
                     * StaffShift isCrossDayShift.
                     * @member {boolean} isCrossDayShift
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.isCrossDayShift = false;
    
                    /**
                     * StaffShift color.
                     * @member {string} color
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.color = "";
    
                    /**
                     * StaffShift shiftName.
                     * @member {string} shiftName
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.shiftName = "";
    
                    /**
                     * StaffShift staffPositionId.
                     * @member {number} staffPositionId
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.staffPositionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffShift createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffShift updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffShift originalId.
                     * @member {number} originalId
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.originalId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffShift state.
                     * @member {palexy.streaming.v1.StaffScheduleState} state
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.state = 0;
    
                    /**
                     * StaffShift staffPosition.
                     * @member {palexy.streaming.v1.IStaffPosition|null|undefined} staffPosition
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.staffPosition = null;
    
                    /**
                     * StaffShift staff.
                     * @member {palexy.streaming.v1.IStaff|null|undefined} staff
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     */
                    StaffShift.prototype.staff = null;
    
                    /**
                     * Creates a new StaffShift instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffShift
                     * @static
                     * @param {palexy.streaming.v1.IStaffShift=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StaffShift} StaffShift instance
                     */
                    StaffShift.create = function create(properties) {
                        return new StaffShift(properties);
                    };
    
                    /**
                     * Encodes the specified StaffShift message. Does not implicitly {@link palexy.streaming.v1.StaffShift.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StaffShift
                     * @static
                     * @param {palexy.streaming.v1.IStaffShift} message StaffShift message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffShift.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.staffId != null && Object.hasOwnProperty.call(message, "staffId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.staffId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.dateId);
                        if (message.note != null && Object.hasOwnProperty.call(message, "note"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.note);
                        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.startTime);
                        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.endTime);
                        if (message.isCrossDayShift != null && Object.hasOwnProperty.call(message, "isCrossDayShift"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isCrossDayShift);
                        if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.color);
                        if (message.shiftName != null && Object.hasOwnProperty.call(message, "shiftName"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.shiftName);
                        if (message.staffPositionId != null && Object.hasOwnProperty.call(message, "staffPositionId"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.staffPositionId);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int64(message.updateTimeMs);
                        if (message.originalId != null && Object.hasOwnProperty.call(message, "originalId"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int64(message.originalId);
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 15, wireType 0 =*/120).int32(message.state);
                        if (message.staffPosition != null && Object.hasOwnProperty.call(message, "staffPosition"))
                            $root.palexy.streaming.v1.StaffPosition.encode(message.staffPosition, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.staff != null && Object.hasOwnProperty.call(message, "staff"))
                            $root.palexy.streaming.v1.Staff.encode(message.staff, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StaffShift message, length delimited. Does not implicitly {@link palexy.streaming.v1.StaffShift.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StaffShift
                     * @static
                     * @param {palexy.streaming.v1.IStaffShift} message StaffShift message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffShift.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StaffShift message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StaffShift
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StaffShift} StaffShift
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffShift.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StaffShift();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.staffId = reader.int64();
                                break;
                            case 4:
                                message.dateId = reader.int32();
                                break;
                            case 5:
                                message.note = reader.string();
                                break;
                            case 6:
                                message.startTime = reader.string();
                                break;
                            case 7:
                                message.endTime = reader.string();
                                break;
                            case 8:
                                message.isCrossDayShift = reader.bool();
                                break;
                            case 9:
                                message.color = reader.string();
                                break;
                            case 10:
                                message.shiftName = reader.string();
                                break;
                            case 11:
                                message.staffPositionId = reader.int64();
                                break;
                            case 12:
                                message.createTimeMs = reader.int64();
                                break;
                            case 13:
                                message.updateTimeMs = reader.int64();
                                break;
                            case 14:
                                message.originalId = reader.int64();
                                break;
                            case 15:
                                message.state = reader.int32();
                                break;
                            case 16:
                                message.staffPosition = $root.palexy.streaming.v1.StaffPosition.decode(reader, reader.uint32());
                                break;
                            case 17:
                                message.staff = $root.palexy.streaming.v1.Staff.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StaffShift message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StaffShift
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StaffShift} StaffShift
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffShift.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StaffShift message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StaffShift
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StaffShift.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (!$util.isInteger(message.staffId) && !(message.staffId && $util.isInteger(message.staffId.low) && $util.isInteger(message.staffId.high)))
                                return "staffId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.note != null && message.hasOwnProperty("note"))
                            if (!$util.isString(message.note))
                                return "note: string expected";
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            if (!$util.isString(message.startTime))
                                return "startTime: string expected";
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            if (!$util.isString(message.endTime))
                                return "endTime: string expected";
                        if (message.isCrossDayShift != null && message.hasOwnProperty("isCrossDayShift"))
                            if (typeof message.isCrossDayShift !== "boolean")
                                return "isCrossDayShift: boolean expected";
                        if (message.color != null && message.hasOwnProperty("color"))
                            if (!$util.isString(message.color))
                                return "color: string expected";
                        if (message.shiftName != null && message.hasOwnProperty("shiftName"))
                            if (!$util.isString(message.shiftName))
                                return "shiftName: string expected";
                        if (message.staffPositionId != null && message.hasOwnProperty("staffPositionId"))
                            if (!$util.isInteger(message.staffPositionId) && !(message.staffPositionId && $util.isInteger(message.staffPositionId.low) && $util.isInteger(message.staffPositionId.high)))
                                return "staffPositionId: integer|Long expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        if (message.originalId != null && message.hasOwnProperty("originalId"))
                            if (!$util.isInteger(message.originalId) && !(message.originalId && $util.isInteger(message.originalId.low) && $util.isInteger(message.originalId.high)))
                                return "originalId: integer|Long expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.staffPosition != null && message.hasOwnProperty("staffPosition")) {
                            var error = $root.palexy.streaming.v1.StaffPosition.verify(message.staffPosition);
                            if (error)
                                return "staffPosition." + error;
                        }
                        if (message.staff != null && message.hasOwnProperty("staff")) {
                            var error = $root.palexy.streaming.v1.Staff.verify(message.staff);
                            if (error)
                                return "staff." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a StaffShift message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StaffShift
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StaffShift} StaffShift
                     */
                    StaffShift.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StaffShift)
                            return object;
                        var message = new $root.palexy.streaming.v1.StaffShift();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.staffId != null)
                            if ($util.Long)
                                (message.staffId = $util.Long.fromValue(object.staffId)).unsigned = false;
                            else if (typeof object.staffId === "string")
                                message.staffId = parseInt(object.staffId, 10);
                            else if (typeof object.staffId === "number")
                                message.staffId = object.staffId;
                            else if (typeof object.staffId === "object")
                                message.staffId = new $util.LongBits(object.staffId.low >>> 0, object.staffId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.note != null)
                            message.note = String(object.note);
                        if (object.startTime != null)
                            message.startTime = String(object.startTime);
                        if (object.endTime != null)
                            message.endTime = String(object.endTime);
                        if (object.isCrossDayShift != null)
                            message.isCrossDayShift = Boolean(object.isCrossDayShift);
                        if (object.color != null)
                            message.color = String(object.color);
                        if (object.shiftName != null)
                            message.shiftName = String(object.shiftName);
                        if (object.staffPositionId != null)
                            if ($util.Long)
                                (message.staffPositionId = $util.Long.fromValue(object.staffPositionId)).unsigned = false;
                            else if (typeof object.staffPositionId === "string")
                                message.staffPositionId = parseInt(object.staffPositionId, 10);
                            else if (typeof object.staffPositionId === "number")
                                message.staffPositionId = object.staffPositionId;
                            else if (typeof object.staffPositionId === "object")
                                message.staffPositionId = new $util.LongBits(object.staffPositionId.low >>> 0, object.staffPositionId.high >>> 0).toNumber();
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        if (object.originalId != null)
                            if ($util.Long)
                                (message.originalId = $util.Long.fromValue(object.originalId)).unsigned = false;
                            else if (typeof object.originalId === "string")
                                message.originalId = parseInt(object.originalId, 10);
                            else if (typeof object.originalId === "number")
                                message.originalId = object.originalId;
                            else if (typeof object.originalId === "object")
                                message.originalId = new $util.LongBits(object.originalId.low >>> 0, object.originalId.high >>> 0).toNumber();
                        switch (object.state) {
                        case "DRAFT_CREATED":
                        case 0:
                            message.state = 0;
                            break;
                        case "DRAFT_UPDATED":
                        case 1:
                            message.state = 1;
                            break;
                        case "DRAFT_DELETED":
                        case 2:
                            message.state = 2;
                            break;
                        case "PUBLISHED":
                        case 3:
                            message.state = 3;
                            break;
                        }
                        if (object.staffPosition != null) {
                            if (typeof object.staffPosition !== "object")
                                throw TypeError(".palexy.streaming.v1.StaffShift.staffPosition: object expected");
                            message.staffPosition = $root.palexy.streaming.v1.StaffPosition.fromObject(object.staffPosition);
                        }
                        if (object.staff != null) {
                            if (typeof object.staff !== "object")
                                throw TypeError(".palexy.streaming.v1.StaffShift.staff: object expected");
                            message.staff = $root.palexy.streaming.v1.Staff.fromObject(object.staff);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StaffShift message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StaffShift
                     * @static
                     * @param {palexy.streaming.v1.StaffShift} message StaffShift
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StaffShift.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                            object.note = "";
                            object.startTime = "";
                            object.endTime = "";
                            object.isCrossDayShift = false;
                            object.color = "";
                            object.shiftName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffPositionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffPositionId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.originalId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.originalId = options.longs === String ? "0" : 0;
                            object.state = options.enums === String ? "DRAFT_CREATED" : 0;
                            object.staffPosition = null;
                            object.staff = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (typeof message.staffId === "number")
                                object.staffId = options.longs === String ? String(message.staffId) : message.staffId;
                            else
                                object.staffId = options.longs === String ? $util.Long.prototype.toString.call(message.staffId) : options.longs === Number ? new $util.LongBits(message.staffId.low >>> 0, message.staffId.high >>> 0).toNumber() : message.staffId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.note != null && message.hasOwnProperty("note"))
                            object.note = message.note;
                        if (message.startTime != null && message.hasOwnProperty("startTime"))
                            object.startTime = message.startTime;
                        if (message.endTime != null && message.hasOwnProperty("endTime"))
                            object.endTime = message.endTime;
                        if (message.isCrossDayShift != null && message.hasOwnProperty("isCrossDayShift"))
                            object.isCrossDayShift = message.isCrossDayShift;
                        if (message.color != null && message.hasOwnProperty("color"))
                            object.color = message.color;
                        if (message.shiftName != null && message.hasOwnProperty("shiftName"))
                            object.shiftName = message.shiftName;
                        if (message.staffPositionId != null && message.hasOwnProperty("staffPositionId"))
                            if (typeof message.staffPositionId === "number")
                                object.staffPositionId = options.longs === String ? String(message.staffPositionId) : message.staffPositionId;
                            else
                                object.staffPositionId = options.longs === String ? $util.Long.prototype.toString.call(message.staffPositionId) : options.longs === Number ? new $util.LongBits(message.staffPositionId.low >>> 0, message.staffPositionId.high >>> 0).toNumber() : message.staffPositionId;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        if (message.originalId != null && message.hasOwnProperty("originalId"))
                            if (typeof message.originalId === "number")
                                object.originalId = options.longs === String ? String(message.originalId) : message.originalId;
                            else
                                object.originalId = options.longs === String ? $util.Long.prototype.toString.call(message.originalId) : options.longs === Number ? new $util.LongBits(message.originalId.low >>> 0, message.originalId.high >>> 0).toNumber() : message.originalId;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.streaming.v1.StaffScheduleState[message.state] : message.state;
                        if (message.staffPosition != null && message.hasOwnProperty("staffPosition"))
                            object.staffPosition = $root.palexy.streaming.v1.StaffPosition.toObject(message.staffPosition, options);
                        if (message.staff != null && message.hasOwnProperty("staff"))
                            object.staff = $root.palexy.streaming.v1.Staff.toObject(message.staff, options);
                        return object;
                    };
    
                    /**
                     * Converts this StaffShift to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StaffShift
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StaffShift.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StaffShift;
                })();
    
                /**
                 * StaffScheduleState enum.
                 * @name palexy.streaming.v1.StaffScheduleState
                 * @enum {number}
                 * @property {number} DRAFT_CREATED=0 DRAFT_CREATED value
                 * @property {number} DRAFT_UPDATED=1 DRAFT_UPDATED value
                 * @property {number} DRAFT_DELETED=2 DRAFT_DELETED value
                 * @property {number} PUBLISHED=3 PUBLISHED value
                 */
                v1.StaffScheduleState = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "DRAFT_CREATED"] = 0;
                    values[valuesById[1] = "DRAFT_UPDATED"] = 1;
                    values[valuesById[2] = "DRAFT_DELETED"] = 2;
                    values[valuesById[3] = "PUBLISHED"] = 3;
                    return values;
                })();
    
                v1.DraftCreateStaffTimeOffRequest = (function() {
    
                    /**
                     * Properties of a DraftCreateStaffTimeOffRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDraftCreateStaffTimeOffRequest
                     * @property {palexy.streaming.v1.IStaffTimeOff|null} [staffTimeOff] DraftCreateStaffTimeOffRequest staffTimeOff
                     */
    
                    /**
                     * Constructs a new DraftCreateStaffTimeOffRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DraftCreateStaffTimeOffRequest.
                     * @implements IDraftCreateStaffTimeOffRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDraftCreateStaffTimeOffRequest=} [properties] Properties to set
                     */
                    function DraftCreateStaffTimeOffRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DraftCreateStaffTimeOffRequest staffTimeOff.
                     * @member {palexy.streaming.v1.IStaffTimeOff|null|undefined} staffTimeOff
                     * @memberof palexy.streaming.v1.DraftCreateStaffTimeOffRequest
                     * @instance
                     */
                    DraftCreateStaffTimeOffRequest.prototype.staffTimeOff = null;
    
                    /**
                     * Creates a new DraftCreateStaffTimeOffRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DraftCreateStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftCreateStaffTimeOffRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DraftCreateStaffTimeOffRequest} DraftCreateStaffTimeOffRequest instance
                     */
                    DraftCreateStaffTimeOffRequest.create = function create(properties) {
                        return new DraftCreateStaffTimeOffRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DraftCreateStaffTimeOffRequest message. Does not implicitly {@link palexy.streaming.v1.DraftCreateStaffTimeOffRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DraftCreateStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftCreateStaffTimeOffRequest} message DraftCreateStaffTimeOffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftCreateStaffTimeOffRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffTimeOff != null && Object.hasOwnProperty.call(message, "staffTimeOff"))
                            $root.palexy.streaming.v1.StaffTimeOff.encode(message.staffTimeOff, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DraftCreateStaffTimeOffRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DraftCreateStaffTimeOffRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DraftCreateStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftCreateStaffTimeOffRequest} message DraftCreateStaffTimeOffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftCreateStaffTimeOffRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DraftCreateStaffTimeOffRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DraftCreateStaffTimeOffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DraftCreateStaffTimeOffRequest} DraftCreateStaffTimeOffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftCreateStaffTimeOffRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DraftCreateStaffTimeOffRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffTimeOff = $root.palexy.streaming.v1.StaffTimeOff.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DraftCreateStaffTimeOffRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DraftCreateStaffTimeOffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DraftCreateStaffTimeOffRequest} DraftCreateStaffTimeOffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftCreateStaffTimeOffRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DraftCreateStaffTimeOffRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DraftCreateStaffTimeOffRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DraftCreateStaffTimeOffRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffTimeOff != null && message.hasOwnProperty("staffTimeOff")) {
                            var error = $root.palexy.streaming.v1.StaffTimeOff.verify(message.staffTimeOff);
                            if (error)
                                return "staffTimeOff." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DraftCreateStaffTimeOffRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DraftCreateStaffTimeOffRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DraftCreateStaffTimeOffRequest} DraftCreateStaffTimeOffRequest
                     */
                    DraftCreateStaffTimeOffRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DraftCreateStaffTimeOffRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DraftCreateStaffTimeOffRequest();
                        if (object.staffTimeOff != null) {
                            if (typeof object.staffTimeOff !== "object")
                                throw TypeError(".palexy.streaming.v1.DraftCreateStaffTimeOffRequest.staffTimeOff: object expected");
                            message.staffTimeOff = $root.palexy.streaming.v1.StaffTimeOff.fromObject(object.staffTimeOff);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DraftCreateStaffTimeOffRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DraftCreateStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.DraftCreateStaffTimeOffRequest} message DraftCreateStaffTimeOffRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DraftCreateStaffTimeOffRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.staffTimeOff = null;
                        if (message.staffTimeOff != null && message.hasOwnProperty("staffTimeOff"))
                            object.staffTimeOff = $root.palexy.streaming.v1.StaffTimeOff.toObject(message.staffTimeOff, options);
                        return object;
                    };
    
                    /**
                     * Converts this DraftCreateStaffTimeOffRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DraftCreateStaffTimeOffRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DraftCreateStaffTimeOffRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DraftCreateStaffTimeOffRequest;
                })();
    
                v1.DraftEditStaffTimeOffRequest = (function() {
    
                    /**
                     * Properties of a DraftEditStaffTimeOffRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDraftEditStaffTimeOffRequest
                     * @property {palexy.streaming.v1.IStaffTimeOff|null} [staffTimeOff] DraftEditStaffTimeOffRequest staffTimeOff
                     * @property {google.protobuf.IFieldMask|null} [updateMask] DraftEditStaffTimeOffRequest updateMask
                     */
    
                    /**
                     * Constructs a new DraftEditStaffTimeOffRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DraftEditStaffTimeOffRequest.
                     * @implements IDraftEditStaffTimeOffRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDraftEditStaffTimeOffRequest=} [properties] Properties to set
                     */
                    function DraftEditStaffTimeOffRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DraftEditStaffTimeOffRequest staffTimeOff.
                     * @member {palexy.streaming.v1.IStaffTimeOff|null|undefined} staffTimeOff
                     * @memberof palexy.streaming.v1.DraftEditStaffTimeOffRequest
                     * @instance
                     */
                    DraftEditStaffTimeOffRequest.prototype.staffTimeOff = null;
    
                    /**
                     * DraftEditStaffTimeOffRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.DraftEditStaffTimeOffRequest
                     * @instance
                     */
                    DraftEditStaffTimeOffRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new DraftEditStaffTimeOffRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DraftEditStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftEditStaffTimeOffRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DraftEditStaffTimeOffRequest} DraftEditStaffTimeOffRequest instance
                     */
                    DraftEditStaffTimeOffRequest.create = function create(properties) {
                        return new DraftEditStaffTimeOffRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DraftEditStaffTimeOffRequest message. Does not implicitly {@link palexy.streaming.v1.DraftEditStaffTimeOffRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DraftEditStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftEditStaffTimeOffRequest} message DraftEditStaffTimeOffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftEditStaffTimeOffRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffTimeOff != null && Object.hasOwnProperty.call(message, "staffTimeOff"))
                            $root.palexy.streaming.v1.StaffTimeOff.encode(message.staffTimeOff, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DraftEditStaffTimeOffRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DraftEditStaffTimeOffRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DraftEditStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftEditStaffTimeOffRequest} message DraftEditStaffTimeOffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftEditStaffTimeOffRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DraftEditStaffTimeOffRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DraftEditStaffTimeOffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DraftEditStaffTimeOffRequest} DraftEditStaffTimeOffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftEditStaffTimeOffRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DraftEditStaffTimeOffRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffTimeOff = $root.palexy.streaming.v1.StaffTimeOff.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DraftEditStaffTimeOffRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DraftEditStaffTimeOffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DraftEditStaffTimeOffRequest} DraftEditStaffTimeOffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftEditStaffTimeOffRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DraftEditStaffTimeOffRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DraftEditStaffTimeOffRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DraftEditStaffTimeOffRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffTimeOff != null && message.hasOwnProperty("staffTimeOff")) {
                            var error = $root.palexy.streaming.v1.StaffTimeOff.verify(message.staffTimeOff);
                            if (error)
                                return "staffTimeOff." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DraftEditStaffTimeOffRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DraftEditStaffTimeOffRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DraftEditStaffTimeOffRequest} DraftEditStaffTimeOffRequest
                     */
                    DraftEditStaffTimeOffRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DraftEditStaffTimeOffRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DraftEditStaffTimeOffRequest();
                        if (object.staffTimeOff != null) {
                            if (typeof object.staffTimeOff !== "object")
                                throw TypeError(".palexy.streaming.v1.DraftEditStaffTimeOffRequest.staffTimeOff: object expected");
                            message.staffTimeOff = $root.palexy.streaming.v1.StaffTimeOff.fromObject(object.staffTimeOff);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.DraftEditStaffTimeOffRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DraftEditStaffTimeOffRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DraftEditStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.DraftEditStaffTimeOffRequest} message DraftEditStaffTimeOffRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DraftEditStaffTimeOffRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.staffTimeOff = null;
                            object.updateMask = null;
                        }
                        if (message.staffTimeOff != null && message.hasOwnProperty("staffTimeOff"))
                            object.staffTimeOff = $root.palexy.streaming.v1.StaffTimeOff.toObject(message.staffTimeOff, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this DraftEditStaffTimeOffRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DraftEditStaffTimeOffRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DraftEditStaffTimeOffRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DraftEditStaffTimeOffRequest;
                })();
    
                v1.DraftDeleteStaffTimeOffRequest = (function() {
    
                    /**
                     * Properties of a DraftDeleteStaffTimeOffRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDraftDeleteStaffTimeOffRequest
                     * @property {number|null} [staffTimeOffId] DraftDeleteStaffTimeOffRequest staffTimeOffId
                     * @property {number|null} [updateTimeMs] DraftDeleteStaffTimeOffRequest updateTimeMs
                     */
    
                    /**
                     * Constructs a new DraftDeleteStaffTimeOffRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DraftDeleteStaffTimeOffRequest.
                     * @implements IDraftDeleteStaffTimeOffRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDraftDeleteStaffTimeOffRequest=} [properties] Properties to set
                     */
                    function DraftDeleteStaffTimeOffRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DraftDeleteStaffTimeOffRequest staffTimeOffId.
                     * @member {number} staffTimeOffId
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffRequest
                     * @instance
                     */
                    DraftDeleteStaffTimeOffRequest.prototype.staffTimeOffId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DraftDeleteStaffTimeOffRequest updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffRequest
                     * @instance
                     */
                    DraftDeleteStaffTimeOffRequest.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DraftDeleteStaffTimeOffRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffTimeOffRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DraftDeleteStaffTimeOffRequest} DraftDeleteStaffTimeOffRequest instance
                     */
                    DraftDeleteStaffTimeOffRequest.create = function create(properties) {
                        return new DraftDeleteStaffTimeOffRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DraftDeleteStaffTimeOffRequest message. Does not implicitly {@link palexy.streaming.v1.DraftDeleteStaffTimeOffRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffTimeOffRequest} message DraftDeleteStaffTimeOffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftDeleteStaffTimeOffRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffTimeOffId != null && Object.hasOwnProperty.call(message, "staffTimeOffId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.staffTimeOffId);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.updateTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DraftDeleteStaffTimeOffRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DraftDeleteStaffTimeOffRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffTimeOffRequest} message DraftDeleteStaffTimeOffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftDeleteStaffTimeOffRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DraftDeleteStaffTimeOffRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DraftDeleteStaffTimeOffRequest} DraftDeleteStaffTimeOffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftDeleteStaffTimeOffRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DraftDeleteStaffTimeOffRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffTimeOffId = reader.int64();
                                break;
                            case 2:
                                message.updateTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DraftDeleteStaffTimeOffRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DraftDeleteStaffTimeOffRequest} DraftDeleteStaffTimeOffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftDeleteStaffTimeOffRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DraftDeleteStaffTimeOffRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DraftDeleteStaffTimeOffRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffTimeOffId != null && message.hasOwnProperty("staffTimeOffId"))
                            if (!$util.isInteger(message.staffTimeOffId) && !(message.staffTimeOffId && $util.isInteger(message.staffTimeOffId.low) && $util.isInteger(message.staffTimeOffId.high)))
                                return "staffTimeOffId: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DraftDeleteStaffTimeOffRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DraftDeleteStaffTimeOffRequest} DraftDeleteStaffTimeOffRequest
                     */
                    DraftDeleteStaffTimeOffRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DraftDeleteStaffTimeOffRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DraftDeleteStaffTimeOffRequest();
                        if (object.staffTimeOffId != null)
                            if ($util.Long)
                                (message.staffTimeOffId = $util.Long.fromValue(object.staffTimeOffId)).unsigned = false;
                            else if (typeof object.staffTimeOffId === "string")
                                message.staffTimeOffId = parseInt(object.staffTimeOffId, 10);
                            else if (typeof object.staffTimeOffId === "number")
                                message.staffTimeOffId = object.staffTimeOffId;
                            else if (typeof object.staffTimeOffId === "object")
                                message.staffTimeOffId = new $util.LongBits(object.staffTimeOffId.low >>> 0, object.staffTimeOffId.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DraftDeleteStaffTimeOffRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.DraftDeleteStaffTimeOffRequest} message DraftDeleteStaffTimeOffRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DraftDeleteStaffTimeOffRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffTimeOffId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffTimeOffId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.staffTimeOffId != null && message.hasOwnProperty("staffTimeOffId"))
                            if (typeof message.staffTimeOffId === "number")
                                object.staffTimeOffId = options.longs === String ? String(message.staffTimeOffId) : message.staffTimeOffId;
                            else
                                object.staffTimeOffId = options.longs === String ? $util.Long.prototype.toString.call(message.staffTimeOffId) : options.longs === Number ? new $util.LongBits(message.staffTimeOffId.low >>> 0, message.staffTimeOffId.high >>> 0).toNumber() : message.staffTimeOffId;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this DraftDeleteStaffTimeOffRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DraftDeleteStaffTimeOffRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DraftDeleteStaffTimeOffRequest;
                })();
    
                v1.DraftDeleteStaffTimeOffResponse = (function() {
    
                    /**
                     * Properties of a DraftDeleteStaffTimeOffResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IDraftDeleteStaffTimeOffResponse
                     * @property {palexy.streaming.v1.IStaffTimeOff|null} [staffTimeOff] DraftDeleteStaffTimeOffResponse staffTimeOff
                     */
    
                    /**
                     * Constructs a new DraftDeleteStaffTimeOffResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DraftDeleteStaffTimeOffResponse.
                     * @implements IDraftDeleteStaffTimeOffResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IDraftDeleteStaffTimeOffResponse=} [properties] Properties to set
                     */
                    function DraftDeleteStaffTimeOffResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DraftDeleteStaffTimeOffResponse staffTimeOff.
                     * @member {palexy.streaming.v1.IStaffTimeOff|null|undefined} staffTimeOff
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffResponse
                     * @instance
                     */
                    DraftDeleteStaffTimeOffResponse.prototype.staffTimeOff = null;
    
                    /**
                     * Creates a new DraftDeleteStaffTimeOffResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffResponse
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffTimeOffResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DraftDeleteStaffTimeOffResponse} DraftDeleteStaffTimeOffResponse instance
                     */
                    DraftDeleteStaffTimeOffResponse.create = function create(properties) {
                        return new DraftDeleteStaffTimeOffResponse(properties);
                    };
    
                    /**
                     * Encodes the specified DraftDeleteStaffTimeOffResponse message. Does not implicitly {@link palexy.streaming.v1.DraftDeleteStaffTimeOffResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffResponse
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffTimeOffResponse} message DraftDeleteStaffTimeOffResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftDeleteStaffTimeOffResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffTimeOff != null && Object.hasOwnProperty.call(message, "staffTimeOff"))
                            $root.palexy.streaming.v1.StaffTimeOff.encode(message.staffTimeOff, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DraftDeleteStaffTimeOffResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.DraftDeleteStaffTimeOffResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffResponse
                     * @static
                     * @param {palexy.streaming.v1.IDraftDeleteStaffTimeOffResponse} message DraftDeleteStaffTimeOffResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DraftDeleteStaffTimeOffResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DraftDeleteStaffTimeOffResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DraftDeleteStaffTimeOffResponse} DraftDeleteStaffTimeOffResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftDeleteStaffTimeOffResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DraftDeleteStaffTimeOffResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffTimeOff = $root.palexy.streaming.v1.StaffTimeOff.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DraftDeleteStaffTimeOffResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DraftDeleteStaffTimeOffResponse} DraftDeleteStaffTimeOffResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DraftDeleteStaffTimeOffResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DraftDeleteStaffTimeOffResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DraftDeleteStaffTimeOffResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffTimeOff != null && message.hasOwnProperty("staffTimeOff")) {
                            var error = $root.palexy.streaming.v1.StaffTimeOff.verify(message.staffTimeOff);
                            if (error)
                                return "staffTimeOff." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DraftDeleteStaffTimeOffResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DraftDeleteStaffTimeOffResponse} DraftDeleteStaffTimeOffResponse
                     */
                    DraftDeleteStaffTimeOffResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DraftDeleteStaffTimeOffResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.DraftDeleteStaffTimeOffResponse();
                        if (object.staffTimeOff != null) {
                            if (typeof object.staffTimeOff !== "object")
                                throw TypeError(".palexy.streaming.v1.DraftDeleteStaffTimeOffResponse.staffTimeOff: object expected");
                            message.staffTimeOff = $root.palexy.streaming.v1.StaffTimeOff.fromObject(object.staffTimeOff);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DraftDeleteStaffTimeOffResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffResponse
                     * @static
                     * @param {palexy.streaming.v1.DraftDeleteStaffTimeOffResponse} message DraftDeleteStaffTimeOffResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DraftDeleteStaffTimeOffResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.staffTimeOff = null;
                        if (message.staffTimeOff != null && message.hasOwnProperty("staffTimeOff"))
                            object.staffTimeOff = $root.palexy.streaming.v1.StaffTimeOff.toObject(message.staffTimeOff, options);
                        return object;
                    };
    
                    /**
                     * Converts this DraftDeleteStaffTimeOffResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DraftDeleteStaffTimeOffResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DraftDeleteStaffTimeOffResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DraftDeleteStaffTimeOffResponse;
                })();
    
                v1.GetStaffTimeOffRequest = (function() {
    
                    /**
                     * Properties of a GetStaffTimeOffRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStaffTimeOffRequest
                     * @property {number|null} [staffTimeOffId] GetStaffTimeOffRequest staffTimeOffId
                     */
    
                    /**
                     * Constructs a new GetStaffTimeOffRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStaffTimeOffRequest.
                     * @implements IGetStaffTimeOffRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStaffTimeOffRequest=} [properties] Properties to set
                     */
                    function GetStaffTimeOffRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStaffTimeOffRequest staffTimeOffId.
                     * @member {number} staffTimeOffId
                     * @memberof palexy.streaming.v1.GetStaffTimeOffRequest
                     * @instance
                     */
                    GetStaffTimeOffRequest.prototype.staffTimeOffId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetStaffTimeOffRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffTimeOffRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStaffTimeOffRequest} GetStaffTimeOffRequest instance
                     */
                    GetStaffTimeOffRequest.create = function create(properties) {
                        return new GetStaffTimeOffRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStaffTimeOffRequest message. Does not implicitly {@link palexy.streaming.v1.GetStaffTimeOffRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffTimeOffRequest} message GetStaffTimeOffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffTimeOffRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffTimeOffId != null && Object.hasOwnProperty.call(message, "staffTimeOffId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.staffTimeOffId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStaffTimeOffRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStaffTimeOffRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffTimeOffRequest} message GetStaffTimeOffRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffTimeOffRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStaffTimeOffRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStaffTimeOffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStaffTimeOffRequest} GetStaffTimeOffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffTimeOffRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStaffTimeOffRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffTimeOffId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStaffTimeOffRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffTimeOffRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStaffTimeOffRequest} GetStaffTimeOffRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffTimeOffRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStaffTimeOffRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStaffTimeOffRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStaffTimeOffRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffTimeOffId != null && message.hasOwnProperty("staffTimeOffId"))
                            if (!$util.isInteger(message.staffTimeOffId) && !(message.staffTimeOffId && $util.isInteger(message.staffTimeOffId.low) && $util.isInteger(message.staffTimeOffId.high)))
                                return "staffTimeOffId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStaffTimeOffRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStaffTimeOffRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStaffTimeOffRequest} GetStaffTimeOffRequest
                     */
                    GetStaffTimeOffRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStaffTimeOffRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStaffTimeOffRequest();
                        if (object.staffTimeOffId != null)
                            if ($util.Long)
                                (message.staffTimeOffId = $util.Long.fromValue(object.staffTimeOffId)).unsigned = false;
                            else if (typeof object.staffTimeOffId === "string")
                                message.staffTimeOffId = parseInt(object.staffTimeOffId, 10);
                            else if (typeof object.staffTimeOffId === "number")
                                message.staffTimeOffId = object.staffTimeOffId;
                            else if (typeof object.staffTimeOffId === "object")
                                message.staffTimeOffId = new $util.LongBits(object.staffTimeOffId.low >>> 0, object.staffTimeOffId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStaffTimeOffRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStaffTimeOffRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStaffTimeOffRequest} message GetStaffTimeOffRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStaffTimeOffRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffTimeOffId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffTimeOffId = options.longs === String ? "0" : 0;
                        if (message.staffTimeOffId != null && message.hasOwnProperty("staffTimeOffId"))
                            if (typeof message.staffTimeOffId === "number")
                                object.staffTimeOffId = options.longs === String ? String(message.staffTimeOffId) : message.staffTimeOffId;
                            else
                                object.staffTimeOffId = options.longs === String ? $util.Long.prototype.toString.call(message.staffTimeOffId) : options.longs === Number ? new $util.LongBits(message.staffTimeOffId.low >>> 0, message.staffTimeOffId.high >>> 0).toNumber() : message.staffTimeOffId;
                        return object;
                    };
    
                    /**
                     * Converts this GetStaffTimeOffRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStaffTimeOffRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStaffTimeOffRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStaffTimeOffRequest;
                })();
    
                v1.StaffTimeOff = (function() {
    
                    /**
                     * Properties of a StaffTimeOff.
                     * @memberof palexy.streaming.v1
                     * @interface IStaffTimeOff
                     * @property {number|null} [id] StaffTimeOff id
                     * @property {number|null} [storeId] StaffTimeOff storeId
                     * @property {number|null} [staffId] StaffTimeOff staffId
                     * @property {number|null} [dateId] StaffTimeOff dateId
                     * @property {string|null} [name] StaffTimeOff name
                     * @property {string|null} [shortName] StaffTimeOff shortName
                     * @property {string|null} [description] StaffTimeOff description
                     * @property {boolean|null} [isAllDay] StaffTimeOff isAllDay
                     * @property {number|null} [totalHours] StaffTimeOff totalHours
                     * @property {palexy.streaming.v1.TimeOffType.PaidType|null} [paidType] StaffTimeOff paidType
                     * @property {number|null} [createTimeMs] StaffTimeOff createTimeMs
                     * @property {number|null} [updateTimeMs] StaffTimeOff updateTimeMs
                     * @property {number|null} [originalId] StaffTimeOff originalId
                     * @property {palexy.streaming.v1.StaffScheduleState|null} [state] StaffTimeOff state
                     */
    
                    /**
                     * Constructs a new StaffTimeOff.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffTimeOff.
                     * @implements IStaffTimeOff
                     * @constructor
                     * @param {palexy.streaming.v1.IStaffTimeOff=} [properties] Properties to set
                     */
                    function StaffTimeOff(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StaffTimeOff id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffTimeOff storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffTimeOff staffId.
                     * @member {number} staffId
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.staffId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffTimeOff dateId.
                     * @member {number} dateId
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.dateId = 0;
    
                    /**
                     * StaffTimeOff name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.name = "";
    
                    /**
                     * StaffTimeOff shortName.
                     * @member {string} shortName
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.shortName = "";
    
                    /**
                     * StaffTimeOff description.
                     * @member {string} description
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.description = "";
    
                    /**
                     * StaffTimeOff isAllDay.
                     * @member {boolean} isAllDay
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.isAllDay = false;
    
                    /**
                     * StaffTimeOff totalHours.
                     * @member {number} totalHours
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.totalHours = 0;
    
                    /**
                     * StaffTimeOff paidType.
                     * @member {palexy.streaming.v1.TimeOffType.PaidType} paidType
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.paidType = 0;
    
                    /**
                     * StaffTimeOff createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffTimeOff updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffTimeOff originalId.
                     * @member {number} originalId
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.originalId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffTimeOff state.
                     * @member {palexy.streaming.v1.StaffScheduleState} state
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     */
                    StaffTimeOff.prototype.state = 0;
    
                    /**
                     * Creates a new StaffTimeOff instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @static
                     * @param {palexy.streaming.v1.IStaffTimeOff=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StaffTimeOff} StaffTimeOff instance
                     */
                    StaffTimeOff.create = function create(properties) {
                        return new StaffTimeOff(properties);
                    };
    
                    /**
                     * Encodes the specified StaffTimeOff message. Does not implicitly {@link palexy.streaming.v1.StaffTimeOff.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @static
                     * @param {palexy.streaming.v1.IStaffTimeOff} message StaffTimeOff message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffTimeOff.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.staffId != null && Object.hasOwnProperty.call(message, "staffId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.staffId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.dateId);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                        if (message.shortName != null && Object.hasOwnProperty.call(message, "shortName"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.shortName);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.description);
                        if (message.isAllDay != null && Object.hasOwnProperty.call(message, "isAllDay"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isAllDay);
                        if (message.totalHours != null && Object.hasOwnProperty.call(message, "totalHours"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.totalHours);
                        if (message.paidType != null && Object.hasOwnProperty.call(message, "paidType"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.paidType);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int64(message.updateTimeMs);
                        if (message.originalId != null && Object.hasOwnProperty.call(message, "originalId"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int64(message.originalId);
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.state);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StaffTimeOff message, length delimited. Does not implicitly {@link palexy.streaming.v1.StaffTimeOff.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @static
                     * @param {palexy.streaming.v1.IStaffTimeOff} message StaffTimeOff message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffTimeOff.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StaffTimeOff message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StaffTimeOff} StaffTimeOff
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffTimeOff.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StaffTimeOff();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.staffId = reader.int64();
                                break;
                            case 4:
                                message.dateId = reader.int32();
                                break;
                            case 5:
                                message.name = reader.string();
                                break;
                            case 6:
                                message.shortName = reader.string();
                                break;
                            case 7:
                                message.description = reader.string();
                                break;
                            case 8:
                                message.isAllDay = reader.bool();
                                break;
                            case 9:
                                message.totalHours = reader.int32();
                                break;
                            case 10:
                                message.paidType = reader.int32();
                                break;
                            case 11:
                                message.createTimeMs = reader.int64();
                                break;
                            case 12:
                                message.updateTimeMs = reader.int64();
                                break;
                            case 13:
                                message.originalId = reader.int64();
                                break;
                            case 14:
                                message.state = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StaffTimeOff message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StaffTimeOff} StaffTimeOff
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffTimeOff.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StaffTimeOff message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StaffTimeOff.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (!$util.isInteger(message.staffId) && !(message.staffId && $util.isInteger(message.staffId.low) && $util.isInteger(message.staffId.high)))
                                return "staffId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.shortName != null && message.hasOwnProperty("shortName"))
                            if (!$util.isString(message.shortName))
                                return "shortName: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.isAllDay != null && message.hasOwnProperty("isAllDay"))
                            if (typeof message.isAllDay !== "boolean")
                                return "isAllDay: boolean expected";
                        if (message.totalHours != null && message.hasOwnProperty("totalHours"))
                            if (!$util.isInteger(message.totalHours))
                                return "totalHours: integer expected";
                        if (message.paidType != null && message.hasOwnProperty("paidType"))
                            switch (message.paidType) {
                            default:
                                return "paidType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        if (message.originalId != null && message.hasOwnProperty("originalId"))
                            if (!$util.isInteger(message.originalId) && !(message.originalId && $util.isInteger(message.originalId.low) && $util.isInteger(message.originalId.high)))
                                return "originalId: integer|Long expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a StaffTimeOff message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StaffTimeOff} StaffTimeOff
                     */
                    StaffTimeOff.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StaffTimeOff)
                            return object;
                        var message = new $root.palexy.streaming.v1.StaffTimeOff();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.staffId != null)
                            if ($util.Long)
                                (message.staffId = $util.Long.fromValue(object.staffId)).unsigned = false;
                            else if (typeof object.staffId === "string")
                                message.staffId = parseInt(object.staffId, 10);
                            else if (typeof object.staffId === "number")
                                message.staffId = object.staffId;
                            else if (typeof object.staffId === "object")
                                message.staffId = new $util.LongBits(object.staffId.low >>> 0, object.staffId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.shortName != null)
                            message.shortName = String(object.shortName);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.isAllDay != null)
                            message.isAllDay = Boolean(object.isAllDay);
                        if (object.totalHours != null)
                            message.totalHours = object.totalHours | 0;
                        switch (object.paidType) {
                        case "PAID":
                        case 0:
                            message.paidType = 0;
                            break;
                        case "UNPAID":
                        case 1:
                            message.paidType = 1;
                            break;
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        if (object.originalId != null)
                            if ($util.Long)
                                (message.originalId = $util.Long.fromValue(object.originalId)).unsigned = false;
                            else if (typeof object.originalId === "string")
                                message.originalId = parseInt(object.originalId, 10);
                            else if (typeof object.originalId === "number")
                                message.originalId = object.originalId;
                            else if (typeof object.originalId === "object")
                                message.originalId = new $util.LongBits(object.originalId.low >>> 0, object.originalId.high >>> 0).toNumber();
                        switch (object.state) {
                        case "DRAFT_CREATED":
                        case 0:
                            message.state = 0;
                            break;
                        case "DRAFT_UPDATED":
                        case 1:
                            message.state = 1;
                            break;
                        case "DRAFT_DELETED":
                        case 2:
                            message.state = 2;
                            break;
                        case "PUBLISHED":
                        case 3:
                            message.state = 3;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StaffTimeOff message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @static
                     * @param {palexy.streaming.v1.StaffTimeOff} message StaffTimeOff
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StaffTimeOff.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                            object.name = "";
                            object.shortName = "";
                            object.description = "";
                            object.isAllDay = false;
                            object.totalHours = 0;
                            object.paidType = options.enums === String ? "PAID" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.originalId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.originalId = options.longs === String ? "0" : 0;
                            object.state = options.enums === String ? "DRAFT_CREATED" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (typeof message.staffId === "number")
                                object.staffId = options.longs === String ? String(message.staffId) : message.staffId;
                            else
                                object.staffId = options.longs === String ? $util.Long.prototype.toString.call(message.staffId) : options.longs === Number ? new $util.LongBits(message.staffId.low >>> 0, message.staffId.high >>> 0).toNumber() : message.staffId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.shortName != null && message.hasOwnProperty("shortName"))
                            object.shortName = message.shortName;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.isAllDay != null && message.hasOwnProperty("isAllDay"))
                            object.isAllDay = message.isAllDay;
                        if (message.totalHours != null && message.hasOwnProperty("totalHours"))
                            object.totalHours = message.totalHours;
                        if (message.paidType != null && message.hasOwnProperty("paidType"))
                            object.paidType = options.enums === String ? $root.palexy.streaming.v1.TimeOffType.PaidType[message.paidType] : message.paidType;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        if (message.originalId != null && message.hasOwnProperty("originalId"))
                            if (typeof message.originalId === "number")
                                object.originalId = options.longs === String ? String(message.originalId) : message.originalId;
                            else
                                object.originalId = options.longs === String ? $util.Long.prototype.toString.call(message.originalId) : options.longs === Number ? new $util.LongBits(message.originalId.low >>> 0, message.originalId.high >>> 0).toNumber() : message.originalId;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.streaming.v1.StaffScheduleState[message.state] : message.state;
                        return object;
                    };
    
                    /**
                     * Converts this StaffTimeOff to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StaffTimeOff
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StaffTimeOff.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StaffTimeOff;
                })();
    
                v1.StaffScheduleService = (function() {
    
                    /**
                     * Constructs a new StaffScheduleService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffScheduleService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StaffScheduleService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (StaffScheduleService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StaffScheduleService;
    
                    /**
                     * Creates new StaffScheduleService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StaffScheduleService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StaffScheduleService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#draftCreateStaffShift}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef DraftCreateStaffShiftCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffShift} [response] StaffShift
                     */
    
                    /**
                     * Calls DraftCreateStaffShift.
                     * @function draftCreateStaffShift
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftCreateStaffShiftRequest} request DraftCreateStaffShiftRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.DraftCreateStaffShiftCallback} callback Node-style callback called with the error, if any, and StaffShift
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.draftCreateStaffShift = function draftCreateStaffShift(request, callback) {
                        return this.rpcCall(draftCreateStaffShift, $root.palexy.streaming.v1.DraftCreateStaffShiftRequest, $root.palexy.streaming.v1.StaffShift, request, callback);
                    }, "name", { value: "DraftCreateStaffShift" });
    
                    /**
                     * Calls DraftCreateStaffShift.
                     * @function draftCreateStaffShift
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftCreateStaffShiftRequest} request DraftCreateStaffShiftRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffShift>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#draftEditStaffShift}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef DraftEditStaffShiftCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffShift} [response] StaffShift
                     */
    
                    /**
                     * Calls DraftEditStaffShift.
                     * @function draftEditStaffShift
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftEditStaffShiftRequest} request DraftEditStaffShiftRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.DraftEditStaffShiftCallback} callback Node-style callback called with the error, if any, and StaffShift
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.draftEditStaffShift = function draftEditStaffShift(request, callback) {
                        return this.rpcCall(draftEditStaffShift, $root.palexy.streaming.v1.DraftEditStaffShiftRequest, $root.palexy.streaming.v1.StaffShift, request, callback);
                    }, "name", { value: "DraftEditStaffShift" });
    
                    /**
                     * Calls DraftEditStaffShift.
                     * @function draftEditStaffShift
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftEditStaffShiftRequest} request DraftEditStaffShiftRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffShift>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#swapStaffShifts}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef SwapStaffShiftsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.SwapStaffShiftsResponse} [response] SwapStaffShiftsResponse
                     */
    
                    /**
                     * Calls SwapStaffShifts.
                     * @function swapStaffShifts
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.ISwapStaffShiftsRequest} request SwapStaffShiftsRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.SwapStaffShiftsCallback} callback Node-style callback called with the error, if any, and SwapStaffShiftsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.swapStaffShifts = function swapStaffShifts(request, callback) {
                        return this.rpcCall(swapStaffShifts, $root.palexy.streaming.v1.SwapStaffShiftsRequest, $root.palexy.streaming.v1.SwapStaffShiftsResponse, request, callback);
                    }, "name", { value: "SwapStaffShifts" });
    
                    /**
                     * Calls SwapStaffShifts.
                     * @function swapStaffShifts
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.ISwapStaffShiftsRequest} request SwapStaffShiftsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.SwapStaffShiftsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#draftDeleteStaffShift}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef DraftDeleteStaffShiftCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.DraftDeleteStaffShiftResponse} [response] DraftDeleteStaffShiftResponse
                     */
    
                    /**
                     * Calls DraftDeleteStaffShift.
                     * @function draftDeleteStaffShift
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftDeleteStaffShiftRequest} request DraftDeleteStaffShiftRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.DraftDeleteStaffShiftCallback} callback Node-style callback called with the error, if any, and DraftDeleteStaffShiftResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.draftDeleteStaffShift = function draftDeleteStaffShift(request, callback) {
                        return this.rpcCall(draftDeleteStaffShift, $root.palexy.streaming.v1.DraftDeleteStaffShiftRequest, $root.palexy.streaming.v1.DraftDeleteStaffShiftResponse, request, callback);
                    }, "name", { value: "DraftDeleteStaffShift" });
    
                    /**
                     * Calls DraftDeleteStaffShift.
                     * @function draftDeleteStaffShift
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftDeleteStaffShiftRequest} request DraftDeleteStaffShiftRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.DraftDeleteStaffShiftResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#getStaffShift}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef GetStaffShiftCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffShift} [response] StaffShift
                     */
    
                    /**
                     * Calls GetStaffShift.
                     * @function getStaffShift
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffShiftRequest} request GetStaffShiftRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.GetStaffShiftCallback} callback Node-style callback called with the error, if any, and StaffShift
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.getStaffShift = function getStaffShift(request, callback) {
                        return this.rpcCall(getStaffShift, $root.palexy.streaming.v1.GetStaffShiftRequest, $root.palexy.streaming.v1.StaffShift, request, callback);
                    }, "name", { value: "GetStaffShift" });
    
                    /**
                     * Calls GetStaffShift.
                     * @function getStaffShift
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffShiftRequest} request GetStaffShiftRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffShift>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#listStaffShifts}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef ListStaffShiftsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListStaffShiftsResponse} [response] ListStaffShiftsResponse
                     */
    
                    /**
                     * Calls ListStaffShifts.
                     * @function listStaffShifts
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffShiftsRequest} request ListStaffShiftsRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.ListStaffShiftsCallback} callback Node-style callback called with the error, if any, and ListStaffShiftsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.listStaffShifts = function listStaffShifts(request, callback) {
                        return this.rpcCall(listStaffShifts, $root.palexy.streaming.v1.ListStaffShiftsRequest, $root.palexy.streaming.v1.ListStaffShiftsResponse, request, callback);
                    }, "name", { value: "ListStaffShifts" });
    
                    /**
                     * Calls ListStaffShifts.
                     * @function listStaffShifts
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IListStaffShiftsRequest} request ListStaffShiftsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListStaffShiftsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#batchDraftCreateStaffShifts}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef BatchDraftCreateStaffShiftsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse} [response] BatchDraftCreateStaffShiftsResponse
                     */
    
                    /**
                     * Calls BatchDraftCreateStaffShifts.
                     * @function batchDraftCreateStaffShifts
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchDraftCreateStaffShiftsRequest} request BatchDraftCreateStaffShiftsRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.BatchDraftCreateStaffShiftsCallback} callback Node-style callback called with the error, if any, and BatchDraftCreateStaffShiftsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.batchDraftCreateStaffShifts = function batchDraftCreateStaffShifts(request, callback) {
                        return this.rpcCall(batchDraftCreateStaffShifts, $root.palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest, $root.palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse, request, callback);
                    }, "name", { value: "BatchDraftCreateStaffShifts" });
    
                    /**
                     * Calls BatchDraftCreateStaffShifts.
                     * @function batchDraftCreateStaffShifts
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IBatchDraftCreateStaffShiftsRequest} request BatchDraftCreateStaffShiftsRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#draftCreateStaffTimeOff}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef DraftCreateStaffTimeOffCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffTimeOff} [response] StaffTimeOff
                     */
    
                    /**
                     * Calls DraftCreateStaffTimeOff.
                     * @function draftCreateStaffTimeOff
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftCreateStaffTimeOffRequest} request DraftCreateStaffTimeOffRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.DraftCreateStaffTimeOffCallback} callback Node-style callback called with the error, if any, and StaffTimeOff
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.draftCreateStaffTimeOff = function draftCreateStaffTimeOff(request, callback) {
                        return this.rpcCall(draftCreateStaffTimeOff, $root.palexy.streaming.v1.DraftCreateStaffTimeOffRequest, $root.palexy.streaming.v1.StaffTimeOff, request, callback);
                    }, "name", { value: "DraftCreateStaffTimeOff" });
    
                    /**
                     * Calls DraftCreateStaffTimeOff.
                     * @function draftCreateStaffTimeOff
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftCreateStaffTimeOffRequest} request DraftCreateStaffTimeOffRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffTimeOff>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#draftEditStaffTimeOff}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef DraftEditStaffTimeOffCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffTimeOff} [response] StaffTimeOff
                     */
    
                    /**
                     * Calls DraftEditStaffTimeOff.
                     * @function draftEditStaffTimeOff
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftEditStaffTimeOffRequest} request DraftEditStaffTimeOffRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.DraftEditStaffTimeOffCallback} callback Node-style callback called with the error, if any, and StaffTimeOff
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.draftEditStaffTimeOff = function draftEditStaffTimeOff(request, callback) {
                        return this.rpcCall(draftEditStaffTimeOff, $root.palexy.streaming.v1.DraftEditStaffTimeOffRequest, $root.palexy.streaming.v1.StaffTimeOff, request, callback);
                    }, "name", { value: "DraftEditStaffTimeOff" });
    
                    /**
                     * Calls DraftEditStaffTimeOff.
                     * @function draftEditStaffTimeOff
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftEditStaffTimeOffRequest} request DraftEditStaffTimeOffRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffTimeOff>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#draftDeleteStaffTimeOff}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef DraftDeleteStaffTimeOffCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.DraftDeleteStaffTimeOffResponse} [response] DraftDeleteStaffTimeOffResponse
                     */
    
                    /**
                     * Calls DraftDeleteStaffTimeOff.
                     * @function draftDeleteStaffTimeOff
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftDeleteStaffTimeOffRequest} request DraftDeleteStaffTimeOffRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.DraftDeleteStaffTimeOffCallback} callback Node-style callback called with the error, if any, and DraftDeleteStaffTimeOffResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.draftDeleteStaffTimeOff = function draftDeleteStaffTimeOff(request, callback) {
                        return this.rpcCall(draftDeleteStaffTimeOff, $root.palexy.streaming.v1.DraftDeleteStaffTimeOffRequest, $root.palexy.streaming.v1.DraftDeleteStaffTimeOffResponse, request, callback);
                    }, "name", { value: "DraftDeleteStaffTimeOff" });
    
                    /**
                     * Calls DraftDeleteStaffTimeOff.
                     * @function draftDeleteStaffTimeOff
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDraftDeleteStaffTimeOffRequest} request DraftDeleteStaffTimeOffRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.DraftDeleteStaffTimeOffResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#getStaffTimeOff}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef GetStaffTimeOffCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffTimeOff} [response] StaffTimeOff
                     */
    
                    /**
                     * Calls GetStaffTimeOff.
                     * @function getStaffTimeOff
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffTimeOffRequest} request GetStaffTimeOffRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.GetStaffTimeOffCallback} callback Node-style callback called with the error, if any, and StaffTimeOff
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.getStaffTimeOff = function getStaffTimeOff(request, callback) {
                        return this.rpcCall(getStaffTimeOff, $root.palexy.streaming.v1.GetStaffTimeOffRequest, $root.palexy.streaming.v1.StaffTimeOff, request, callback);
                    }, "name", { value: "GetStaffTimeOff" });
    
                    /**
                     * Calls GetStaffTimeOff.
                     * @function getStaffTimeOff
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffTimeOffRequest} request GetStaffTimeOffRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffTimeOff>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#createDemoData}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef CreateDemoDataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.CreateDemoDataResponse} [response] CreateDemoDataResponse
                     */
    
                    /**
                     * Calls CreateDemoData.
                     * @function createDemoData
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateDemoDataRequest} request CreateDemoDataRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.CreateDemoDataCallback} callback Node-style callback called with the error, if any, and CreateDemoDataResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.createDemoData = function createDemoData(request, callback) {
                        return this.rpcCall(createDemoData, $root.palexy.streaming.v1.CreateDemoDataRequest, $root.palexy.streaming.v1.CreateDemoDataResponse, request, callback);
                    }, "name", { value: "CreateDemoData" });
    
                    /**
                     * Calls CreateDemoData.
                     * @function createDemoData
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateDemoDataRequest} request CreateDemoDataRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.CreateDemoDataResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#getStaffSchedule}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef GetStaffScheduleCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.GetStaffScheduleResponse} [response] GetStaffScheduleResponse
                     */
    
                    /**
                     * Calls GetStaffSchedule.
                     * @function getStaffSchedule
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffScheduleRequest} request GetStaffScheduleRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.GetStaffScheduleCallback} callback Node-style callback called with the error, if any, and GetStaffScheduleResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.getStaffSchedule = function getStaffSchedule(request, callback) {
                        return this.rpcCall(getStaffSchedule, $root.palexy.streaming.v1.GetStaffScheduleRequest, $root.palexy.streaming.v1.GetStaffScheduleResponse, request, callback);
                    }, "name", { value: "GetStaffSchedule" });
    
                    /**
                     * Calls GetStaffSchedule.
                     * @function getStaffSchedule
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffScheduleRequest} request GetStaffScheduleRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.GetStaffScheduleResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#publishStaffSchedule}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef PublishStaffScheduleCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls PublishStaffSchedule.
                     * @function publishStaffSchedule
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IPublishStaffScheduleRequest} request PublishStaffScheduleRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.PublishStaffScheduleCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.publishStaffSchedule = function publishStaffSchedule(request, callback) {
                        return this.rpcCall(publishStaffSchedule, $root.palexy.streaming.v1.PublishStaffScheduleRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "PublishStaffSchedule" });
    
                    /**
                     * Calls PublishStaffSchedule.
                     * @function publishStaffSchedule
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IPublishStaffScheduleRequest} request PublishStaffScheduleRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#discardDraftStaffSchedule}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef DiscardDraftStaffScheduleCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DiscardDraftStaffSchedule.
                     * @function discardDraftStaffSchedule
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDiscardDraftStaffScheduleRequest} request DiscardDraftStaffScheduleRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.DiscardDraftStaffScheduleCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.discardDraftStaffSchedule = function discardDraftStaffSchedule(request, callback) {
                        return this.rpcCall(discardDraftStaffSchedule, $root.palexy.streaming.v1.DiscardDraftStaffScheduleRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DiscardDraftStaffSchedule" });
    
                    /**
                     * Calls DiscardDraftStaffSchedule.
                     * @function discardDraftStaffSchedule
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDiscardDraftStaffScheduleRequest} request DiscardDraftStaffScheduleRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#copySchedule}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef CopyScheduleCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls CopySchedule.
                     * @function copySchedule
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.ICopyScheduleRequest} request CopyScheduleRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.CopyScheduleCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.copySchedule = function copySchedule(request, callback) {
                        return this.rpcCall(copySchedule, $root.palexy.streaming.v1.CopyScheduleRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "CopySchedule" });
    
                    /**
                     * Calls CopySchedule.
                     * @function copySchedule
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.ICopyScheduleRequest} request CopyScheduleRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#getStaffScheduleGeneralSetting}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef GetStaffScheduleGeneralSettingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffScheduleGeneralSetting} [response] StaffScheduleGeneralSetting
                     */
    
                    /**
                     * Calls GetStaffScheduleGeneralSetting.
                     * @function getStaffScheduleGeneralSetting
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffScheduleGeneralSettingRequest} request GetStaffScheduleGeneralSettingRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.GetStaffScheduleGeneralSettingCallback} callback Node-style callback called with the error, if any, and StaffScheduleGeneralSetting
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.getStaffScheduleGeneralSetting = function getStaffScheduleGeneralSetting(request, callback) {
                        return this.rpcCall(getStaffScheduleGeneralSetting, $root.palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest, $root.palexy.streaming.v1.StaffScheduleGeneralSetting, request, callback);
                    }, "name", { value: "GetStaffScheduleGeneralSetting" });
    
                    /**
                     * Calls GetStaffScheduleGeneralSetting.
                     * @function getStaffScheduleGeneralSetting
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IGetStaffScheduleGeneralSettingRequest} request GetStaffScheduleGeneralSettingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffScheduleGeneralSetting>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#createStaffScheduleGeneralSetting}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef CreateStaffScheduleGeneralSettingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffScheduleGeneralSetting} [response] StaffScheduleGeneralSetting
                     */
    
                    /**
                     * Calls CreateStaffScheduleGeneralSetting.
                     * @function createStaffScheduleGeneralSetting
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStaffScheduleGeneralSettingRequest} request CreateStaffScheduleGeneralSettingRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.CreateStaffScheduleGeneralSettingCallback} callback Node-style callback called with the error, if any, and StaffScheduleGeneralSetting
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.createStaffScheduleGeneralSetting = function createStaffScheduleGeneralSetting(request, callback) {
                        return this.rpcCall(createStaffScheduleGeneralSetting, $root.palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest, $root.palexy.streaming.v1.StaffScheduleGeneralSetting, request, callback);
                    }, "name", { value: "CreateStaffScheduleGeneralSetting" });
    
                    /**
                     * Calls CreateStaffScheduleGeneralSetting.
                     * @function createStaffScheduleGeneralSetting
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.ICreateStaffScheduleGeneralSettingRequest} request CreateStaffScheduleGeneralSettingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffScheduleGeneralSetting>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#updateStaffScheduleGeneralSetting}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef UpdateStaffScheduleGeneralSettingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.StaffScheduleGeneralSetting} [response] StaffScheduleGeneralSetting
                     */
    
                    /**
                     * Calls UpdateStaffScheduleGeneralSetting.
                     * @function updateStaffScheduleGeneralSetting
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStaffScheduleGeneralSettingRequest} request UpdateStaffScheduleGeneralSettingRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.UpdateStaffScheduleGeneralSettingCallback} callback Node-style callback called with the error, if any, and StaffScheduleGeneralSetting
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.updateStaffScheduleGeneralSetting = function updateStaffScheduleGeneralSetting(request, callback) {
                        return this.rpcCall(updateStaffScheduleGeneralSetting, $root.palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest, $root.palexy.streaming.v1.StaffScheduleGeneralSetting, request, callback);
                    }, "name", { value: "UpdateStaffScheduleGeneralSetting" });
    
                    /**
                     * Calls UpdateStaffScheduleGeneralSetting.
                     * @function updateStaffScheduleGeneralSetting
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateStaffScheduleGeneralSettingRequest} request UpdateStaffScheduleGeneralSettingRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.StaffScheduleGeneralSetting>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.StaffScheduleService#deleteStaffScheduleGeneralSetting}.
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @typedef DeleteStaffScheduleGeneralSettingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteStaffScheduleGeneralSetting.
                     * @function deleteStaffScheduleGeneralSetting
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteStaffScheduleGeneralSettingRequest} request DeleteStaffScheduleGeneralSettingRequest message or plain object
                     * @param {palexy.streaming.v1.StaffScheduleService.DeleteStaffScheduleGeneralSettingCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StaffScheduleService.prototype.deleteStaffScheduleGeneralSetting = function deleteStaffScheduleGeneralSetting(request, callback) {
                        return this.rpcCall(deleteStaffScheduleGeneralSetting, $root.palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteStaffScheduleGeneralSetting" });
    
                    /**
                     * Calls DeleteStaffScheduleGeneralSetting.
                     * @function deleteStaffScheduleGeneralSetting
                     * @memberof palexy.streaming.v1.StaffScheduleService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteStaffScheduleGeneralSettingRequest} request DeleteStaffScheduleGeneralSettingRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return StaffScheduleService;
                })();
    
                v1.BatchDraftCreateStaffShiftsRequest = (function() {
    
                    /**
                     * Properties of a BatchDraftCreateStaffShiftsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchDraftCreateStaffShiftsRequest
                     * @property {number|null} [storeId] BatchDraftCreateStaffShiftsRequest storeId
                     * @property {number|null} [startDateId] BatchDraftCreateStaffShiftsRequest startDateId
                     * @property {number|null} [endDateId] BatchDraftCreateStaffShiftsRequest endDateId
                     * @property {Array.<palexy.insights.v1.IProposedShift>|null} [proposedShifts] BatchDraftCreateStaffShiftsRequest proposedShifts
                     */
    
                    /**
                     * Constructs a new BatchDraftCreateStaffShiftsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchDraftCreateStaffShiftsRequest.
                     * @implements IBatchDraftCreateStaffShiftsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchDraftCreateStaffShiftsRequest=} [properties] Properties to set
                     */
                    function BatchDraftCreateStaffShiftsRequest(properties) {
                        this.proposedShifts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchDraftCreateStaffShiftsRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @instance
                     */
                    BatchDraftCreateStaffShiftsRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * BatchDraftCreateStaffShiftsRequest startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @instance
                     */
                    BatchDraftCreateStaffShiftsRequest.prototype.startDateId = 0;
    
                    /**
                     * BatchDraftCreateStaffShiftsRequest endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @instance
                     */
                    BatchDraftCreateStaffShiftsRequest.prototype.endDateId = 0;
    
                    /**
                     * BatchDraftCreateStaffShiftsRequest proposedShifts.
                     * @member {Array.<palexy.insights.v1.IProposedShift>} proposedShifts
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @instance
                     */
                    BatchDraftCreateStaffShiftsRequest.prototype.proposedShifts = $util.emptyArray;
    
                    /**
                     * Creates a new BatchDraftCreateStaffShiftsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDraftCreateStaffShiftsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest} BatchDraftCreateStaffShiftsRequest instance
                     */
                    BatchDraftCreateStaffShiftsRequest.create = function create(properties) {
                        return new BatchDraftCreateStaffShiftsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchDraftCreateStaffShiftsRequest message. Does not implicitly {@link palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDraftCreateStaffShiftsRequest} message BatchDraftCreateStaffShiftsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDraftCreateStaffShiftsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.endDateId);
                        if (message.proposedShifts != null && message.proposedShifts.length)
                            for (var i = 0; i < message.proposedShifts.length; ++i)
                                $root.palexy.insights.v1.ProposedShift.encode(message.proposedShifts[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchDraftCreateStaffShiftsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.IBatchDraftCreateStaffShiftsRequest} message BatchDraftCreateStaffShiftsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDraftCreateStaffShiftsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchDraftCreateStaffShiftsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest} BatchDraftCreateStaffShiftsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDraftCreateStaffShiftsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.startDateId = reader.int32();
                                break;
                            case 3:
                                message.endDateId = reader.int32();
                                break;
                            case 4:
                                if (!(message.proposedShifts && message.proposedShifts.length))
                                    message.proposedShifts = [];
                                message.proposedShifts.push($root.palexy.insights.v1.ProposedShift.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchDraftCreateStaffShiftsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest} BatchDraftCreateStaffShiftsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDraftCreateStaffShiftsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchDraftCreateStaffShiftsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchDraftCreateStaffShiftsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        if (message.proposedShifts != null && message.hasOwnProperty("proposedShifts")) {
                            if (!Array.isArray(message.proposedShifts))
                                return "proposedShifts: array expected";
                            for (var i = 0; i < message.proposedShifts.length; ++i) {
                                var error = $root.palexy.insights.v1.ProposedShift.verify(message.proposedShifts[i]);
                                if (error)
                                    return "proposedShifts." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchDraftCreateStaffShiftsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest} BatchDraftCreateStaffShiftsRequest
                     */
                    BatchDraftCreateStaffShiftsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        if (object.proposedShifts) {
                            if (!Array.isArray(object.proposedShifts))
                                throw TypeError(".palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest.proposedShifts: array expected");
                            message.proposedShifts = [];
                            for (var i = 0; i < object.proposedShifts.length; ++i) {
                                if (typeof object.proposedShifts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest.proposedShifts: object expected");
                                message.proposedShifts[i] = $root.palexy.insights.v1.ProposedShift.fromObject(object.proposedShifts[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchDraftCreateStaffShiftsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest} message BatchDraftCreateStaffShiftsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchDraftCreateStaffShiftsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.proposedShifts = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.startDateId = 0;
                            object.endDateId = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.proposedShifts && message.proposedShifts.length) {
                            object.proposedShifts = [];
                            for (var j = 0; j < message.proposedShifts.length; ++j)
                                object.proposedShifts[j] = $root.palexy.insights.v1.ProposedShift.toObject(message.proposedShifts[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchDraftCreateStaffShiftsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchDraftCreateStaffShiftsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchDraftCreateStaffShiftsRequest;
                })();
    
                v1.BatchDraftCreateStaffShiftsResponse = (function() {
    
                    /**
                     * Properties of a BatchDraftCreateStaffShiftsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IBatchDraftCreateStaffShiftsResponse
                     * @property {Array.<palexy.streaming.v1.IStaffShift>|null} [staffShifts] BatchDraftCreateStaffShiftsResponse staffShifts
                     */
    
                    /**
                     * Constructs a new BatchDraftCreateStaffShiftsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BatchDraftCreateStaffShiftsResponse.
                     * @implements IBatchDraftCreateStaffShiftsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IBatchDraftCreateStaffShiftsResponse=} [properties] Properties to set
                     */
                    function BatchDraftCreateStaffShiftsResponse(properties) {
                        this.staffShifts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchDraftCreateStaffShiftsResponse staffShifts.
                     * @member {Array.<palexy.streaming.v1.IStaffShift>} staffShifts
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse
                     * @instance
                     */
                    BatchDraftCreateStaffShiftsResponse.prototype.staffShifts = $util.emptyArray;
    
                    /**
                     * Creates a new BatchDraftCreateStaffShiftsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchDraftCreateStaffShiftsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse} BatchDraftCreateStaffShiftsResponse instance
                     */
                    BatchDraftCreateStaffShiftsResponse.create = function create(properties) {
                        return new BatchDraftCreateStaffShiftsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchDraftCreateStaffShiftsResponse message. Does not implicitly {@link palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchDraftCreateStaffShiftsResponse} message BatchDraftCreateStaffShiftsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDraftCreateStaffShiftsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffShifts != null && message.staffShifts.length)
                            for (var i = 0; i < message.staffShifts.length; ++i)
                                $root.palexy.streaming.v1.StaffShift.encode(message.staffShifts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchDraftCreateStaffShiftsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.IBatchDraftCreateStaffShiftsResponse} message BatchDraftCreateStaffShiftsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchDraftCreateStaffShiftsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchDraftCreateStaffShiftsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse} BatchDraftCreateStaffShiftsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDraftCreateStaffShiftsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.staffShifts && message.staffShifts.length))
                                    message.staffShifts = [];
                                message.staffShifts.push($root.palexy.streaming.v1.StaffShift.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchDraftCreateStaffShiftsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse} BatchDraftCreateStaffShiftsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchDraftCreateStaffShiftsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchDraftCreateStaffShiftsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchDraftCreateStaffShiftsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffShifts != null && message.hasOwnProperty("staffShifts")) {
                            if (!Array.isArray(message.staffShifts))
                                return "staffShifts: array expected";
                            for (var i = 0; i < message.staffShifts.length; ++i) {
                                var error = $root.palexy.streaming.v1.StaffShift.verify(message.staffShifts[i]);
                                if (error)
                                    return "staffShifts." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchDraftCreateStaffShiftsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse} BatchDraftCreateStaffShiftsResponse
                     */
                    BatchDraftCreateStaffShiftsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse();
                        if (object.staffShifts) {
                            if (!Array.isArray(object.staffShifts))
                                throw TypeError(".palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse.staffShifts: array expected");
                            message.staffShifts = [];
                            for (var i = 0; i < object.staffShifts.length; ++i) {
                                if (typeof object.staffShifts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse.staffShifts: object expected");
                                message.staffShifts[i] = $root.palexy.streaming.v1.StaffShift.fromObject(object.staffShifts[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchDraftCreateStaffShiftsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse} message BatchDraftCreateStaffShiftsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchDraftCreateStaffShiftsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.staffShifts = [];
                        if (message.staffShifts && message.staffShifts.length) {
                            object.staffShifts = [];
                            for (var j = 0; j < message.staffShifts.length; ++j)
                                object.staffShifts[j] = $root.palexy.streaming.v1.StaffShift.toObject(message.staffShifts[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchDraftCreateStaffShiftsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BatchDraftCreateStaffShiftsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchDraftCreateStaffShiftsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchDraftCreateStaffShiftsResponse;
                })();
    
                v1.GetStaffScheduleGeneralSettingRequest = (function() {
    
                    /**
                     * Properties of a GetStaffScheduleGeneralSettingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStaffScheduleGeneralSettingRequest
                     * @property {number|null} [companyId] GetStaffScheduleGeneralSettingRequest companyId
                     * @property {number|null} [settingId] GetStaffScheduleGeneralSettingRequest settingId
                     */
    
                    /**
                     * Constructs a new GetStaffScheduleGeneralSettingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStaffScheduleGeneralSettingRequest.
                     * @implements IGetStaffScheduleGeneralSettingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStaffScheduleGeneralSettingRequest=} [properties] Properties to set
                     */
                    function GetStaffScheduleGeneralSettingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStaffScheduleGeneralSettingRequest companyId.
                     * @member {number|null|undefined} companyId
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @instance
                     */
                    GetStaffScheduleGeneralSettingRequest.prototype.companyId = null;
    
                    /**
                     * GetStaffScheduleGeneralSettingRequest settingId.
                     * @member {number|null|undefined} settingId
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @instance
                     */
                    GetStaffScheduleGeneralSettingRequest.prototype.settingId = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * GetStaffScheduleGeneralSettingRequest filter.
                     * @member {"companyId"|"settingId"|undefined} filter
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @instance
                     */
                    Object.defineProperty(GetStaffScheduleGeneralSettingRequest.prototype, "filter", {
                        get: $util.oneOfGetter($oneOfFields = ["companyId", "settingId"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new GetStaffScheduleGeneralSettingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffScheduleGeneralSettingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest} GetStaffScheduleGeneralSettingRequest instance
                     */
                    GetStaffScheduleGeneralSettingRequest.create = function create(properties) {
                        return new GetStaffScheduleGeneralSettingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStaffScheduleGeneralSettingRequest message. Does not implicitly {@link palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffScheduleGeneralSettingRequest} message GetStaffScheduleGeneralSettingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffScheduleGeneralSettingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.settingId != null && Object.hasOwnProperty.call(message, "settingId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.settingId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStaffScheduleGeneralSettingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffScheduleGeneralSettingRequest} message GetStaffScheduleGeneralSettingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffScheduleGeneralSettingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStaffScheduleGeneralSettingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest} GetStaffScheduleGeneralSettingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffScheduleGeneralSettingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                message.settingId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStaffScheduleGeneralSettingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest} GetStaffScheduleGeneralSettingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffScheduleGeneralSettingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStaffScheduleGeneralSettingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStaffScheduleGeneralSettingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.companyId != null && message.hasOwnProperty("companyId")) {
                            properties.filter = 1;
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        }
                        if (message.settingId != null && message.hasOwnProperty("settingId")) {
                            if (properties.filter === 1)
                                return "filter: multiple values";
                            properties.filter = 1;
                            if (!$util.isInteger(message.settingId) && !(message.settingId && $util.isInteger(message.settingId.low) && $util.isInteger(message.settingId.high)))
                                return "settingId: integer|Long expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetStaffScheduleGeneralSettingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest} GetStaffScheduleGeneralSettingRequest
                     */
                    GetStaffScheduleGeneralSettingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.settingId != null)
                            if ($util.Long)
                                (message.settingId = $util.Long.fromValue(object.settingId)).unsigned = false;
                            else if (typeof object.settingId === "string")
                                message.settingId = parseInt(object.settingId, 10);
                            else if (typeof object.settingId === "number")
                                message.settingId = object.settingId;
                            else if (typeof object.settingId === "object")
                                message.settingId = new $util.LongBits(object.settingId.low >>> 0, object.settingId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStaffScheduleGeneralSettingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest} message GetStaffScheduleGeneralSettingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStaffScheduleGeneralSettingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.companyId != null && message.hasOwnProperty("companyId")) {
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                            if (options.oneofs)
                                object.filter = "companyId";
                        }
                        if (message.settingId != null && message.hasOwnProperty("settingId")) {
                            if (typeof message.settingId === "number")
                                object.settingId = options.longs === String ? String(message.settingId) : message.settingId;
                            else
                                object.settingId = options.longs === String ? $util.Long.prototype.toString.call(message.settingId) : options.longs === Number ? new $util.LongBits(message.settingId.low >>> 0, message.settingId.high >>> 0).toNumber() : message.settingId;
                            if (options.oneofs)
                                object.filter = "settingId";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetStaffScheduleGeneralSettingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStaffScheduleGeneralSettingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStaffScheduleGeneralSettingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStaffScheduleGeneralSettingRequest;
                })();
    
                v1.CreateDemoDataRequest = (function() {
    
                    /**
                     * Properties of a CreateDemoDataRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateDemoDataRequest
                     * @property {number|null} [storeId] CreateDemoDataRequest storeId
                     * @property {number|null} [startDateId] CreateDemoDataRequest startDateId
                     */
    
                    /**
                     * Constructs a new CreateDemoDataRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateDemoDataRequest.
                     * @implements ICreateDemoDataRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateDemoDataRequest=} [properties] Properties to set
                     */
                    function CreateDemoDataRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateDemoDataRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.CreateDemoDataRequest
                     * @instance
                     */
                    CreateDemoDataRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CreateDemoDataRequest startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.streaming.v1.CreateDemoDataRequest
                     * @instance
                     */
                    CreateDemoDataRequest.prototype.startDateId = 0;
    
                    /**
                     * Creates a new CreateDemoDataRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateDemoDataRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateDemoDataRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateDemoDataRequest} CreateDemoDataRequest instance
                     */
                    CreateDemoDataRequest.create = function create(properties) {
                        return new CreateDemoDataRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateDemoDataRequest message. Does not implicitly {@link palexy.streaming.v1.CreateDemoDataRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateDemoDataRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateDemoDataRequest} message CreateDemoDataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDemoDataRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.startDateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateDemoDataRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateDemoDataRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateDemoDataRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateDemoDataRequest} message CreateDemoDataRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDemoDataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateDemoDataRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateDemoDataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateDemoDataRequest} CreateDemoDataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDemoDataRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateDemoDataRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.startDateId = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateDemoDataRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateDemoDataRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateDemoDataRequest} CreateDemoDataRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDemoDataRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateDemoDataRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateDemoDataRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateDemoDataRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateDemoDataRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateDemoDataRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateDemoDataRequest} CreateDemoDataRequest
                     */
                    CreateDemoDataRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateDemoDataRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateDemoDataRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateDemoDataRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateDemoDataRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateDemoDataRequest} message CreateDemoDataRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateDemoDataRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.startDateId = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        return object;
                    };
    
                    /**
                     * Converts this CreateDemoDataRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateDemoDataRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateDemoDataRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateDemoDataRequest;
                })();
    
                v1.CreateDemoDataResponse = (function() {
    
                    /**
                     * Properties of a CreateDemoDataResponse.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateDemoDataResponse
                     */
    
                    /**
                     * Constructs a new CreateDemoDataResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateDemoDataResponse.
                     * @implements ICreateDemoDataResponse
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateDemoDataResponse=} [properties] Properties to set
                     */
                    function CreateDemoDataResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new CreateDemoDataResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateDemoDataResponse
                     * @static
                     * @param {palexy.streaming.v1.ICreateDemoDataResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateDemoDataResponse} CreateDemoDataResponse instance
                     */
                    CreateDemoDataResponse.create = function create(properties) {
                        return new CreateDemoDataResponse(properties);
                    };
    
                    /**
                     * Encodes the specified CreateDemoDataResponse message. Does not implicitly {@link palexy.streaming.v1.CreateDemoDataResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateDemoDataResponse
                     * @static
                     * @param {palexy.streaming.v1.ICreateDemoDataResponse} message CreateDemoDataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDemoDataResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateDemoDataResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateDemoDataResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateDemoDataResponse
                     * @static
                     * @param {palexy.streaming.v1.ICreateDemoDataResponse} message CreateDemoDataResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDemoDataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateDemoDataResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateDemoDataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateDemoDataResponse} CreateDemoDataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDemoDataResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateDemoDataResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateDemoDataResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateDemoDataResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateDemoDataResponse} CreateDemoDataResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDemoDataResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateDemoDataResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateDemoDataResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateDemoDataResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a CreateDemoDataResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateDemoDataResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateDemoDataResponse} CreateDemoDataResponse
                     */
                    CreateDemoDataResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateDemoDataResponse)
                            return object;
                        return new $root.palexy.streaming.v1.CreateDemoDataResponse();
                    };
    
                    /**
                     * Creates a plain object from a CreateDemoDataResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateDemoDataResponse
                     * @static
                     * @param {palexy.streaming.v1.CreateDemoDataResponse} message CreateDemoDataResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateDemoDataResponse.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this CreateDemoDataResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateDemoDataResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateDemoDataResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateDemoDataResponse;
                })();
    
                v1.ListStaffShiftsRequest = (function() {
    
                    /**
                     * Properties of a ListStaffShiftsRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffShiftsRequest
                     * @property {number|null} [storeId] ListStaffShiftsRequest storeId
                     * @property {number|null} [startDateId] ListStaffShiftsRequest startDateId
                     * @property {number|null} [endDateId] ListStaffShiftsRequest endDateId
                     * @property {palexy.streaming.v1.StaffScheduleState|null} [state] ListStaffShiftsRequest state
                     * @property {boolean|null} [includeStaffPosition] ListStaffShiftsRequest includeStaffPosition
                     * @property {boolean|null} [includeStaff] ListStaffShiftsRequest includeStaff
                     */
    
                    /**
                     * Constructs a new ListStaffShiftsRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffShiftsRequest.
                     * @implements IListStaffShiftsRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffShiftsRequest=} [properties] Properties to set
                     */
                    function ListStaffShiftsRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffShiftsRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @instance
                     */
                    ListStaffShiftsRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListStaffShiftsRequest startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @instance
                     */
                    ListStaffShiftsRequest.prototype.startDateId = 0;
    
                    /**
                     * ListStaffShiftsRequest endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @instance
                     */
                    ListStaffShiftsRequest.prototype.endDateId = 0;
    
                    /**
                     * ListStaffShiftsRequest state.
                     * @member {palexy.streaming.v1.StaffScheduleState} state
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @instance
                     */
                    ListStaffShiftsRequest.prototype.state = 0;
    
                    /**
                     * ListStaffShiftsRequest includeStaffPosition.
                     * @member {boolean} includeStaffPosition
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @instance
                     */
                    ListStaffShiftsRequest.prototype.includeStaffPosition = false;
    
                    /**
                     * ListStaffShiftsRequest includeStaff.
                     * @member {boolean} includeStaff
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @instance
                     */
                    ListStaffShiftsRequest.prototype.includeStaff = false;
    
                    /**
                     * Creates a new ListStaffShiftsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffShiftsRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffShiftsRequest} ListStaffShiftsRequest instance
                     */
                    ListStaffShiftsRequest.create = function create(properties) {
                        return new ListStaffShiftsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffShiftsRequest message. Does not implicitly {@link palexy.streaming.v1.ListStaffShiftsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffShiftsRequest} message ListStaffShiftsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffShiftsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.endDateId);
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.state);
                        if (message.includeStaffPosition != null && Object.hasOwnProperty.call(message, "includeStaffPosition"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.includeStaffPosition);
                        if (message.includeStaff != null && Object.hasOwnProperty.call(message, "includeStaff"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.includeStaff);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffShiftsRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffShiftsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.IListStaffShiftsRequest} message ListStaffShiftsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffShiftsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffShiftsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffShiftsRequest} ListStaffShiftsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffShiftsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffShiftsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.startDateId = reader.int32();
                                break;
                            case 3:
                                message.endDateId = reader.int32();
                                break;
                            case 4:
                                message.state = reader.int32();
                                break;
                            case 5:
                                message.includeStaffPosition = reader.bool();
                                break;
                            case 7:
                                message.includeStaff = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffShiftsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffShiftsRequest} ListStaffShiftsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffShiftsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffShiftsRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffShiftsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.includeStaffPosition != null && message.hasOwnProperty("includeStaffPosition"))
                            if (typeof message.includeStaffPosition !== "boolean")
                                return "includeStaffPosition: boolean expected";
                        if (message.includeStaff != null && message.hasOwnProperty("includeStaff"))
                            if (typeof message.includeStaff !== "boolean")
                                return "includeStaff: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffShiftsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffShiftsRequest} ListStaffShiftsRequest
                     */
                    ListStaffShiftsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffShiftsRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffShiftsRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        switch (object.state) {
                        case "DRAFT_CREATED":
                        case 0:
                            message.state = 0;
                            break;
                        case "DRAFT_UPDATED":
                        case 1:
                            message.state = 1;
                            break;
                        case "DRAFT_DELETED":
                        case 2:
                            message.state = 2;
                            break;
                        case "PUBLISHED":
                        case 3:
                            message.state = 3;
                            break;
                        }
                        if (object.includeStaffPosition != null)
                            message.includeStaffPosition = Boolean(object.includeStaffPosition);
                        if (object.includeStaff != null)
                            message.includeStaff = Boolean(object.includeStaff);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffShiftsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @static
                     * @param {palexy.streaming.v1.ListStaffShiftsRequest} message ListStaffShiftsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffShiftsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.startDateId = 0;
                            object.endDateId = 0;
                            object.state = options.enums === String ? "DRAFT_CREATED" : 0;
                            object.includeStaffPosition = false;
                            object.includeStaff = false;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.streaming.v1.StaffScheduleState[message.state] : message.state;
                        if (message.includeStaffPosition != null && message.hasOwnProperty("includeStaffPosition"))
                            object.includeStaffPosition = message.includeStaffPosition;
                        if (message.includeStaff != null && message.hasOwnProperty("includeStaff"))
                            object.includeStaff = message.includeStaff;
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffShiftsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffShiftsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffShiftsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffShiftsRequest;
                })();
    
                v1.ListStaffShiftsResponse = (function() {
    
                    /**
                     * Properties of a ListStaffShiftsResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListStaffShiftsResponse
                     * @property {Array.<palexy.streaming.v1.IStaffShift>|null} [staffShifts] ListStaffShiftsResponse staffShifts
                     */
    
                    /**
                     * Constructs a new ListStaffShiftsResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListStaffShiftsResponse.
                     * @implements IListStaffShiftsResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListStaffShiftsResponse=} [properties] Properties to set
                     */
                    function ListStaffShiftsResponse(properties) {
                        this.staffShifts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStaffShiftsResponse staffShifts.
                     * @member {Array.<palexy.streaming.v1.IStaffShift>} staffShifts
                     * @memberof palexy.streaming.v1.ListStaffShiftsResponse
                     * @instance
                     */
                    ListStaffShiftsResponse.prototype.staffShifts = $util.emptyArray;
    
                    /**
                     * Creates a new ListStaffShiftsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffShiftsResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListStaffShiftsResponse} ListStaffShiftsResponse instance
                     */
                    ListStaffShiftsResponse.create = function create(properties) {
                        return new ListStaffShiftsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStaffShiftsResponse message. Does not implicitly {@link palexy.streaming.v1.ListStaffShiftsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffShiftsResponse} message ListStaffShiftsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffShiftsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffShifts != null && message.staffShifts.length)
                            for (var i = 0; i < message.staffShifts.length; ++i)
                                $root.palexy.streaming.v1.StaffShift.encode(message.staffShifts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStaffShiftsResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListStaffShiftsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.IListStaffShiftsResponse} message ListStaffShiftsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStaffShiftsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStaffShiftsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListStaffShiftsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListStaffShiftsResponse} ListStaffShiftsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffShiftsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListStaffShiftsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.staffShifts && message.staffShifts.length))
                                    message.staffShifts = [];
                                message.staffShifts.push($root.palexy.streaming.v1.StaffShift.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStaffShiftsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListStaffShiftsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListStaffShiftsResponse} ListStaffShiftsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStaffShiftsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStaffShiftsResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListStaffShiftsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStaffShiftsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffShifts != null && message.hasOwnProperty("staffShifts")) {
                            if (!Array.isArray(message.staffShifts))
                                return "staffShifts: array expected";
                            for (var i = 0; i < message.staffShifts.length; ++i) {
                                var error = $root.palexy.streaming.v1.StaffShift.verify(message.staffShifts[i]);
                                if (error)
                                    return "staffShifts." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListStaffShiftsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListStaffShiftsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListStaffShiftsResponse} ListStaffShiftsResponse
                     */
                    ListStaffShiftsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListStaffShiftsResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListStaffShiftsResponse();
                        if (object.staffShifts) {
                            if (!Array.isArray(object.staffShifts))
                                throw TypeError(".palexy.streaming.v1.ListStaffShiftsResponse.staffShifts: array expected");
                            message.staffShifts = [];
                            for (var i = 0; i < object.staffShifts.length; ++i) {
                                if (typeof object.staffShifts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListStaffShiftsResponse.staffShifts: object expected");
                                message.staffShifts[i] = $root.palexy.streaming.v1.StaffShift.fromObject(object.staffShifts[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStaffShiftsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListStaffShiftsResponse
                     * @static
                     * @param {palexy.streaming.v1.ListStaffShiftsResponse} message ListStaffShiftsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStaffShiftsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.staffShifts = [];
                        if (message.staffShifts && message.staffShifts.length) {
                            object.staffShifts = [];
                            for (var j = 0; j < message.staffShifts.length; ++j)
                                object.staffShifts[j] = $root.palexy.streaming.v1.StaffShift.toObject(message.staffShifts[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListStaffShiftsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListStaffShiftsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStaffShiftsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStaffShiftsResponse;
                })();
    
                v1.CreateStaffScheduleGeneralSettingRequest = (function() {
    
                    /**
                     * Properties of a CreateStaffScheduleGeneralSettingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICreateStaffScheduleGeneralSettingRequest
                     * @property {palexy.streaming.v1.IStaffScheduleGeneralSetting|null} [staffScheduleGeneralSetting] CreateStaffScheduleGeneralSettingRequest staffScheduleGeneralSetting
                     */
    
                    /**
                     * Constructs a new CreateStaffScheduleGeneralSettingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CreateStaffScheduleGeneralSettingRequest.
                     * @implements ICreateStaffScheduleGeneralSettingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICreateStaffScheduleGeneralSettingRequest=} [properties] Properties to set
                     */
                    function CreateStaffScheduleGeneralSettingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateStaffScheduleGeneralSettingRequest staffScheduleGeneralSetting.
                     * @member {palexy.streaming.v1.IStaffScheduleGeneralSetting|null|undefined} staffScheduleGeneralSetting
                     * @memberof palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest
                     * @instance
                     */
                    CreateStaffScheduleGeneralSettingRequest.prototype.staffScheduleGeneralSetting = null;
    
                    /**
                     * Creates a new CreateStaffScheduleGeneralSettingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffScheduleGeneralSettingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest} CreateStaffScheduleGeneralSettingRequest instance
                     */
                    CreateStaffScheduleGeneralSettingRequest.create = function create(properties) {
                        return new CreateStaffScheduleGeneralSettingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateStaffScheduleGeneralSettingRequest message. Does not implicitly {@link palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffScheduleGeneralSettingRequest} message CreateStaffScheduleGeneralSettingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStaffScheduleGeneralSettingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffScheduleGeneralSetting != null && Object.hasOwnProperty.call(message, "staffScheduleGeneralSetting"))
                            $root.palexy.streaming.v1.StaffScheduleGeneralSetting.encode(message.staffScheduleGeneralSetting, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateStaffScheduleGeneralSettingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.ICreateStaffScheduleGeneralSettingRequest} message CreateStaffScheduleGeneralSettingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateStaffScheduleGeneralSettingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateStaffScheduleGeneralSettingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest} CreateStaffScheduleGeneralSettingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStaffScheduleGeneralSettingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffScheduleGeneralSetting = $root.palexy.streaming.v1.StaffScheduleGeneralSetting.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateStaffScheduleGeneralSettingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest} CreateStaffScheduleGeneralSettingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateStaffScheduleGeneralSettingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateStaffScheduleGeneralSettingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateStaffScheduleGeneralSettingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffScheduleGeneralSetting != null && message.hasOwnProperty("staffScheduleGeneralSetting")) {
                            var error = $root.palexy.streaming.v1.StaffScheduleGeneralSetting.verify(message.staffScheduleGeneralSetting);
                            if (error)
                                return "staffScheduleGeneralSetting." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateStaffScheduleGeneralSettingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest} CreateStaffScheduleGeneralSettingRequest
                     */
                    CreateStaffScheduleGeneralSettingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest();
                        if (object.staffScheduleGeneralSetting != null) {
                            if (typeof object.staffScheduleGeneralSetting !== "object")
                                throw TypeError(".palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest.staffScheduleGeneralSetting: object expected");
                            message.staffScheduleGeneralSetting = $root.palexy.streaming.v1.StaffScheduleGeneralSetting.fromObject(object.staffScheduleGeneralSetting);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateStaffScheduleGeneralSettingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest} message CreateStaffScheduleGeneralSettingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateStaffScheduleGeneralSettingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.staffScheduleGeneralSetting = null;
                        if (message.staffScheduleGeneralSetting != null && message.hasOwnProperty("staffScheduleGeneralSetting"))
                            object.staffScheduleGeneralSetting = $root.palexy.streaming.v1.StaffScheduleGeneralSetting.toObject(message.staffScheduleGeneralSetting, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateStaffScheduleGeneralSettingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CreateStaffScheduleGeneralSettingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateStaffScheduleGeneralSettingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateStaffScheduleGeneralSettingRequest;
                })();
    
                v1.UpdateStaffScheduleGeneralSettingRequest = (function() {
    
                    /**
                     * Properties of an UpdateStaffScheduleGeneralSettingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateStaffScheduleGeneralSettingRequest
                     * @property {palexy.streaming.v1.IStaffScheduleGeneralSetting|null} [staffScheduleGeneralSetting] UpdateStaffScheduleGeneralSettingRequest staffScheduleGeneralSetting
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateStaffScheduleGeneralSettingRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateStaffScheduleGeneralSettingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateStaffScheduleGeneralSettingRequest.
                     * @implements IUpdateStaffScheduleGeneralSettingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateStaffScheduleGeneralSettingRequest=} [properties] Properties to set
                     */
                    function UpdateStaffScheduleGeneralSettingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateStaffScheduleGeneralSettingRequest staffScheduleGeneralSetting.
                     * @member {palexy.streaming.v1.IStaffScheduleGeneralSetting|null|undefined} staffScheduleGeneralSetting
                     * @memberof palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest
                     * @instance
                     */
                    UpdateStaffScheduleGeneralSettingRequest.prototype.staffScheduleGeneralSetting = null;
    
                    /**
                     * UpdateStaffScheduleGeneralSettingRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest
                     * @instance
                     */
                    UpdateStaffScheduleGeneralSettingRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateStaffScheduleGeneralSettingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffScheduleGeneralSettingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest} UpdateStaffScheduleGeneralSettingRequest instance
                     */
                    UpdateStaffScheduleGeneralSettingRequest.create = function create(properties) {
                        return new UpdateStaffScheduleGeneralSettingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateStaffScheduleGeneralSettingRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffScheduleGeneralSettingRequest} message UpdateStaffScheduleGeneralSettingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStaffScheduleGeneralSettingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffScheduleGeneralSetting != null && Object.hasOwnProperty.call(message, "staffScheduleGeneralSetting"))
                            $root.palexy.streaming.v1.StaffScheduleGeneralSetting.encode(message.staffScheduleGeneralSetting, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateStaffScheduleGeneralSettingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateStaffScheduleGeneralSettingRequest} message UpdateStaffScheduleGeneralSettingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStaffScheduleGeneralSettingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateStaffScheduleGeneralSettingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest} UpdateStaffScheduleGeneralSettingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStaffScheduleGeneralSettingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.staffScheduleGeneralSetting = $root.palexy.streaming.v1.StaffScheduleGeneralSetting.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateStaffScheduleGeneralSettingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest} UpdateStaffScheduleGeneralSettingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStaffScheduleGeneralSettingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateStaffScheduleGeneralSettingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateStaffScheduleGeneralSettingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffScheduleGeneralSetting != null && message.hasOwnProperty("staffScheduleGeneralSetting")) {
                            var error = $root.palexy.streaming.v1.StaffScheduleGeneralSetting.verify(message.staffScheduleGeneralSetting);
                            if (error)
                                return "staffScheduleGeneralSetting." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateStaffScheduleGeneralSettingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest} UpdateStaffScheduleGeneralSettingRequest
                     */
                    UpdateStaffScheduleGeneralSettingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest();
                        if (object.staffScheduleGeneralSetting != null) {
                            if (typeof object.staffScheduleGeneralSetting !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest.staffScheduleGeneralSetting: object expected");
                            message.staffScheduleGeneralSetting = $root.palexy.streaming.v1.StaffScheduleGeneralSetting.fromObject(object.staffScheduleGeneralSetting);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateStaffScheduleGeneralSettingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest} message UpdateStaffScheduleGeneralSettingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateStaffScheduleGeneralSettingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.staffScheduleGeneralSetting = null;
                            object.updateMask = null;
                        }
                        if (message.staffScheduleGeneralSetting != null && message.hasOwnProperty("staffScheduleGeneralSetting"))
                            object.staffScheduleGeneralSetting = $root.palexy.streaming.v1.StaffScheduleGeneralSetting.toObject(message.staffScheduleGeneralSetting, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateStaffScheduleGeneralSettingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateStaffScheduleGeneralSettingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateStaffScheduleGeneralSettingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateStaffScheduleGeneralSettingRequest;
                })();
    
                v1.DeleteStaffScheduleGeneralSettingRequest = (function() {
    
                    /**
                     * Properties of a DeleteStaffScheduleGeneralSettingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteStaffScheduleGeneralSettingRequest
                     * @property {number|null} [settingId] DeleteStaffScheduleGeneralSettingRequest settingId
                     */
    
                    /**
                     * Constructs a new DeleteStaffScheduleGeneralSettingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteStaffScheduleGeneralSettingRequest.
                     * @implements IDeleteStaffScheduleGeneralSettingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteStaffScheduleGeneralSettingRequest=} [properties] Properties to set
                     */
                    function DeleteStaffScheduleGeneralSettingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteStaffScheduleGeneralSettingRequest settingId.
                     * @member {number} settingId
                     * @memberof palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest
                     * @instance
                     */
                    DeleteStaffScheduleGeneralSettingRequest.prototype.settingId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteStaffScheduleGeneralSettingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStaffScheduleGeneralSettingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest} DeleteStaffScheduleGeneralSettingRequest instance
                     */
                    DeleteStaffScheduleGeneralSettingRequest.create = function create(properties) {
                        return new DeleteStaffScheduleGeneralSettingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteStaffScheduleGeneralSettingRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStaffScheduleGeneralSettingRequest} message DeleteStaffScheduleGeneralSettingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStaffScheduleGeneralSettingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.settingId != null && Object.hasOwnProperty.call(message, "settingId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.settingId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteStaffScheduleGeneralSettingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteStaffScheduleGeneralSettingRequest} message DeleteStaffScheduleGeneralSettingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStaffScheduleGeneralSettingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteStaffScheduleGeneralSettingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest} DeleteStaffScheduleGeneralSettingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStaffScheduleGeneralSettingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.settingId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteStaffScheduleGeneralSettingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest} DeleteStaffScheduleGeneralSettingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStaffScheduleGeneralSettingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteStaffScheduleGeneralSettingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteStaffScheduleGeneralSettingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.settingId != null && message.hasOwnProperty("settingId"))
                            if (!$util.isInteger(message.settingId) && !(message.settingId && $util.isInteger(message.settingId.low) && $util.isInteger(message.settingId.high)))
                                return "settingId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteStaffScheduleGeneralSettingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest} DeleteStaffScheduleGeneralSettingRequest
                     */
                    DeleteStaffScheduleGeneralSettingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest();
                        if (object.settingId != null)
                            if ($util.Long)
                                (message.settingId = $util.Long.fromValue(object.settingId)).unsigned = false;
                            else if (typeof object.settingId === "string")
                                message.settingId = parseInt(object.settingId, 10);
                            else if (typeof object.settingId === "number")
                                message.settingId = object.settingId;
                            else if (typeof object.settingId === "object")
                                message.settingId = new $util.LongBits(object.settingId.low >>> 0, object.settingId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteStaffScheduleGeneralSettingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest} message DeleteStaffScheduleGeneralSettingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteStaffScheduleGeneralSettingRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.settingId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.settingId = options.longs === String ? "0" : 0;
                        if (message.settingId != null && message.hasOwnProperty("settingId"))
                            if (typeof message.settingId === "number")
                                object.settingId = options.longs === String ? String(message.settingId) : message.settingId;
                            else
                                object.settingId = options.longs === String ? $util.Long.prototype.toString.call(message.settingId) : options.longs === Number ? new $util.LongBits(message.settingId.low >>> 0, message.settingId.high >>> 0).toNumber() : message.settingId;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteStaffScheduleGeneralSettingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteStaffScheduleGeneralSettingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteStaffScheduleGeneralSettingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteStaffScheduleGeneralSettingRequest;
                })();
    
                v1.GetStaffScheduleRequest = (function() {
    
                    /**
                     * Properties of a GetStaffScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStaffScheduleRequest
                     * @property {number|null} [startDateId] GetStaffScheduleRequest startDateId
                     * @property {number|null} [endDateId] GetStaffScheduleRequest endDateId
                     * @property {number|null} [storeId] GetStaffScheduleRequest storeId
                     * @property {palexy.streaming.v1.IIdList|null} [staffIds] GetStaffScheduleRequest staffIds
                     */
    
                    /**
                     * Constructs a new GetStaffScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStaffScheduleRequest.
                     * @implements IGetStaffScheduleRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStaffScheduleRequest=} [properties] Properties to set
                     */
                    function GetStaffScheduleRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStaffScheduleRequest startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @instance
                     */
                    GetStaffScheduleRequest.prototype.startDateId = 0;
    
                    /**
                     * GetStaffScheduleRequest endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @instance
                     */
                    GetStaffScheduleRequest.prototype.endDateId = 0;
    
                    /**
                     * GetStaffScheduleRequest storeId.
                     * @member {number|null|undefined} storeId
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @instance
                     */
                    GetStaffScheduleRequest.prototype.storeId = null;
    
                    /**
                     * GetStaffScheduleRequest staffIds.
                     * @member {palexy.streaming.v1.IIdList|null|undefined} staffIds
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @instance
                     */
                    GetStaffScheduleRequest.prototype.staffIds = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * GetStaffScheduleRequest filterOption.
                     * @member {"storeId"|"staffIds"|undefined} filterOption
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @instance
                     */
                    Object.defineProperty(GetStaffScheduleRequest.prototype, "filterOption", {
                        get: $util.oneOfGetter($oneOfFields = ["storeId", "staffIds"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new GetStaffScheduleRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffScheduleRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStaffScheduleRequest} GetStaffScheduleRequest instance
                     */
                    GetStaffScheduleRequest.create = function create(properties) {
                        return new GetStaffScheduleRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStaffScheduleRequest message. Does not implicitly {@link palexy.streaming.v1.GetStaffScheduleRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffScheduleRequest} message GetStaffScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffScheduleRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.endDateId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        if (message.staffIds != null && Object.hasOwnProperty.call(message, "staffIds"))
                            $root.palexy.streaming.v1.IdList.encode(message.staffIds, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStaffScheduleRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStaffScheduleRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffScheduleRequest} message GetStaffScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStaffScheduleRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStaffScheduleRequest} GetStaffScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffScheduleRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStaffScheduleRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startDateId = reader.int32();
                                break;
                            case 2:
                                message.endDateId = reader.int32();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 4:
                                message.staffIds = $root.palexy.streaming.v1.IdList.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStaffScheduleRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStaffScheduleRequest} GetStaffScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStaffScheduleRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStaffScheduleRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            properties.filterOption = 1;
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        }
                        if (message.staffIds != null && message.hasOwnProperty("staffIds")) {
                            if (properties.filterOption === 1)
                                return "filterOption: multiple values";
                            properties.filterOption = 1;
                            {
                                var error = $root.palexy.streaming.v1.IdList.verify(message.staffIds);
                                if (error)
                                    return "staffIds." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetStaffScheduleRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStaffScheduleRequest} GetStaffScheduleRequest
                     */
                    GetStaffScheduleRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStaffScheduleRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStaffScheduleRequest();
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.staffIds != null) {
                            if (typeof object.staffIds !== "object")
                                throw TypeError(".palexy.streaming.v1.GetStaffScheduleRequest.staffIds: object expected");
                            message.staffIds = $root.palexy.streaming.v1.IdList.fromObject(object.staffIds);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStaffScheduleRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStaffScheduleRequest} message GetStaffScheduleRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStaffScheduleRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startDateId = 0;
                            object.endDateId = 0;
                        }
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            if (options.oneofs)
                                object.filterOption = "storeId";
                        }
                        if (message.staffIds != null && message.hasOwnProperty("staffIds")) {
                            object.staffIds = $root.palexy.streaming.v1.IdList.toObject(message.staffIds, options);
                            if (options.oneofs)
                                object.filterOption = "staffIds";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetStaffScheduleRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStaffScheduleRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStaffScheduleRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStaffScheduleRequest;
                })();
    
                v1.GetStaffScheduleResponse = (function() {
    
                    /**
                     * Properties of a GetStaffScheduleResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStaffScheduleResponse
                     * @property {Array.<palexy.streaming.v1.IStaffShift>|null} [staffShifts] GetStaffScheduleResponse staffShifts
                     * @property {Array.<palexy.streaming.v1.IStaffTimeOff>|null} [staffTimeOffs] GetStaffScheduleResponse staffTimeOffs
                     */
    
                    /**
                     * Constructs a new GetStaffScheduleResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStaffScheduleResponse.
                     * @implements IGetStaffScheduleResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStaffScheduleResponse=} [properties] Properties to set
                     */
                    function GetStaffScheduleResponse(properties) {
                        this.staffShifts = [];
                        this.staffTimeOffs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStaffScheduleResponse staffShifts.
                     * @member {Array.<palexy.streaming.v1.IStaffShift>} staffShifts
                     * @memberof palexy.streaming.v1.GetStaffScheduleResponse
                     * @instance
                     */
                    GetStaffScheduleResponse.prototype.staffShifts = $util.emptyArray;
    
                    /**
                     * GetStaffScheduleResponse staffTimeOffs.
                     * @member {Array.<palexy.streaming.v1.IStaffTimeOff>} staffTimeOffs
                     * @memberof palexy.streaming.v1.GetStaffScheduleResponse
                     * @instance
                     */
                    GetStaffScheduleResponse.prototype.staffTimeOffs = $util.emptyArray;
    
                    /**
                     * Creates a new GetStaffScheduleResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStaffScheduleResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffScheduleResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStaffScheduleResponse} GetStaffScheduleResponse instance
                     */
                    GetStaffScheduleResponse.create = function create(properties) {
                        return new GetStaffScheduleResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetStaffScheduleResponse message. Does not implicitly {@link palexy.streaming.v1.GetStaffScheduleResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStaffScheduleResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffScheduleResponse} message GetStaffScheduleResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffScheduleResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.staffShifts != null && message.staffShifts.length)
                            for (var i = 0; i < message.staffShifts.length; ++i)
                                $root.palexy.streaming.v1.StaffShift.encode(message.staffShifts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.staffTimeOffs != null && message.staffTimeOffs.length)
                            for (var i = 0; i < message.staffTimeOffs.length; ++i)
                                $root.palexy.streaming.v1.StaffTimeOff.encode(message.staffTimeOffs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStaffScheduleResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStaffScheduleResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffScheduleResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetStaffScheduleResponse} message GetStaffScheduleResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStaffScheduleResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStaffScheduleResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStaffScheduleResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStaffScheduleResponse} GetStaffScheduleResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffScheduleResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStaffScheduleResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.staffShifts && message.staffShifts.length))
                                    message.staffShifts = [];
                                message.staffShifts.push($root.palexy.streaming.v1.StaffShift.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.staffTimeOffs && message.staffTimeOffs.length))
                                    message.staffTimeOffs = [];
                                message.staffTimeOffs.push($root.palexy.streaming.v1.StaffTimeOff.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStaffScheduleResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStaffScheduleResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStaffScheduleResponse} GetStaffScheduleResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStaffScheduleResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStaffScheduleResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStaffScheduleResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStaffScheduleResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.staffShifts != null && message.hasOwnProperty("staffShifts")) {
                            if (!Array.isArray(message.staffShifts))
                                return "staffShifts: array expected";
                            for (var i = 0; i < message.staffShifts.length; ++i) {
                                var error = $root.palexy.streaming.v1.StaffShift.verify(message.staffShifts[i]);
                                if (error)
                                    return "staffShifts." + error;
                            }
                        }
                        if (message.staffTimeOffs != null && message.hasOwnProperty("staffTimeOffs")) {
                            if (!Array.isArray(message.staffTimeOffs))
                                return "staffTimeOffs: array expected";
                            for (var i = 0; i < message.staffTimeOffs.length; ++i) {
                                var error = $root.palexy.streaming.v1.StaffTimeOff.verify(message.staffTimeOffs[i]);
                                if (error)
                                    return "staffTimeOffs." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetStaffScheduleResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStaffScheduleResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStaffScheduleResponse} GetStaffScheduleResponse
                     */
                    GetStaffScheduleResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStaffScheduleResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStaffScheduleResponse();
                        if (object.staffShifts) {
                            if (!Array.isArray(object.staffShifts))
                                throw TypeError(".palexy.streaming.v1.GetStaffScheduleResponse.staffShifts: array expected");
                            message.staffShifts = [];
                            for (var i = 0; i < object.staffShifts.length; ++i) {
                                if (typeof object.staffShifts[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.GetStaffScheduleResponse.staffShifts: object expected");
                                message.staffShifts[i] = $root.palexy.streaming.v1.StaffShift.fromObject(object.staffShifts[i]);
                            }
                        }
                        if (object.staffTimeOffs) {
                            if (!Array.isArray(object.staffTimeOffs))
                                throw TypeError(".palexy.streaming.v1.GetStaffScheduleResponse.staffTimeOffs: array expected");
                            message.staffTimeOffs = [];
                            for (var i = 0; i < object.staffTimeOffs.length; ++i) {
                                if (typeof object.staffTimeOffs[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.GetStaffScheduleResponse.staffTimeOffs: object expected");
                                message.staffTimeOffs[i] = $root.palexy.streaming.v1.StaffTimeOff.fromObject(object.staffTimeOffs[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStaffScheduleResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStaffScheduleResponse
                     * @static
                     * @param {palexy.streaming.v1.GetStaffScheduleResponse} message GetStaffScheduleResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStaffScheduleResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.staffShifts = [];
                            object.staffTimeOffs = [];
                        }
                        if (message.staffShifts && message.staffShifts.length) {
                            object.staffShifts = [];
                            for (var j = 0; j < message.staffShifts.length; ++j)
                                object.staffShifts[j] = $root.palexy.streaming.v1.StaffShift.toObject(message.staffShifts[j], options);
                        }
                        if (message.staffTimeOffs && message.staffTimeOffs.length) {
                            object.staffTimeOffs = [];
                            for (var j = 0; j < message.staffTimeOffs.length; ++j)
                                object.staffTimeOffs[j] = $root.palexy.streaming.v1.StaffTimeOff.toObject(message.staffTimeOffs[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetStaffScheduleResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStaffScheduleResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStaffScheduleResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStaffScheduleResponse;
                })();
    
                v1.PublishStaffScheduleRequest = (function() {
    
                    /**
                     * Properties of a PublishStaffScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IPublishStaffScheduleRequest
                     * @property {number|null} [startDateId] PublishStaffScheduleRequest startDateId
                     * @property {number|null} [endDateId] PublishStaffScheduleRequest endDateId
                     * @property {number|null} [storeId] PublishStaffScheduleRequest storeId
                     */
    
                    /**
                     * Constructs a new PublishStaffScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a PublishStaffScheduleRequest.
                     * @implements IPublishStaffScheduleRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IPublishStaffScheduleRequest=} [properties] Properties to set
                     */
                    function PublishStaffScheduleRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PublishStaffScheduleRequest startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @instance
                     */
                    PublishStaffScheduleRequest.prototype.startDateId = 0;
    
                    /**
                     * PublishStaffScheduleRequest endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @instance
                     */
                    PublishStaffScheduleRequest.prototype.endDateId = 0;
    
                    /**
                     * PublishStaffScheduleRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @instance
                     */
                    PublishStaffScheduleRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new PublishStaffScheduleRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IPublishStaffScheduleRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.PublishStaffScheduleRequest} PublishStaffScheduleRequest instance
                     */
                    PublishStaffScheduleRequest.create = function create(properties) {
                        return new PublishStaffScheduleRequest(properties);
                    };
    
                    /**
                     * Encodes the specified PublishStaffScheduleRequest message. Does not implicitly {@link palexy.streaming.v1.PublishStaffScheduleRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IPublishStaffScheduleRequest} message PublishStaffScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PublishStaffScheduleRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.endDateId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PublishStaffScheduleRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.PublishStaffScheduleRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IPublishStaffScheduleRequest} message PublishStaffScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PublishStaffScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PublishStaffScheduleRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.PublishStaffScheduleRequest} PublishStaffScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PublishStaffScheduleRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.PublishStaffScheduleRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startDateId = reader.int32();
                                break;
                            case 2:
                                message.endDateId = reader.int32();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PublishStaffScheduleRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.PublishStaffScheduleRequest} PublishStaffScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PublishStaffScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PublishStaffScheduleRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PublishStaffScheduleRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a PublishStaffScheduleRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.PublishStaffScheduleRequest} PublishStaffScheduleRequest
                     */
                    PublishStaffScheduleRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.PublishStaffScheduleRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.PublishStaffScheduleRequest();
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PublishStaffScheduleRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.PublishStaffScheduleRequest} message PublishStaffScheduleRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PublishStaffScheduleRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startDateId = 0;
                            object.endDateId = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                        }
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        return object;
                    };
    
                    /**
                     * Converts this PublishStaffScheduleRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.PublishStaffScheduleRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PublishStaffScheduleRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PublishStaffScheduleRequest;
                })();
    
                v1.DiscardDraftStaffScheduleRequest = (function() {
    
                    /**
                     * Properties of a DiscardDraftStaffScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDiscardDraftStaffScheduleRequest
                     * @property {number|null} [startDateId] DiscardDraftStaffScheduleRequest startDateId
                     * @property {number|null} [endDateId] DiscardDraftStaffScheduleRequest endDateId
                     * @property {number|null} [storeId] DiscardDraftStaffScheduleRequest storeId
                     */
    
                    /**
                     * Constructs a new DiscardDraftStaffScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DiscardDraftStaffScheduleRequest.
                     * @implements IDiscardDraftStaffScheduleRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDiscardDraftStaffScheduleRequest=} [properties] Properties to set
                     */
                    function DiscardDraftStaffScheduleRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DiscardDraftStaffScheduleRequest startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @instance
                     */
                    DiscardDraftStaffScheduleRequest.prototype.startDateId = 0;
    
                    /**
                     * DiscardDraftStaffScheduleRequest endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @instance
                     */
                    DiscardDraftStaffScheduleRequest.prototype.endDateId = 0;
    
                    /**
                     * DiscardDraftStaffScheduleRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @instance
                     */
                    DiscardDraftStaffScheduleRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DiscardDraftStaffScheduleRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IDiscardDraftStaffScheduleRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DiscardDraftStaffScheduleRequest} DiscardDraftStaffScheduleRequest instance
                     */
                    DiscardDraftStaffScheduleRequest.create = function create(properties) {
                        return new DiscardDraftStaffScheduleRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DiscardDraftStaffScheduleRequest message. Does not implicitly {@link palexy.streaming.v1.DiscardDraftStaffScheduleRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IDiscardDraftStaffScheduleRequest} message DiscardDraftStaffScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DiscardDraftStaffScheduleRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.endDateId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DiscardDraftStaffScheduleRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DiscardDraftStaffScheduleRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.IDiscardDraftStaffScheduleRequest} message DiscardDraftStaffScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DiscardDraftStaffScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DiscardDraftStaffScheduleRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DiscardDraftStaffScheduleRequest} DiscardDraftStaffScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DiscardDraftStaffScheduleRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DiscardDraftStaffScheduleRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startDateId = reader.int32();
                                break;
                            case 2:
                                message.endDateId = reader.int32();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DiscardDraftStaffScheduleRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DiscardDraftStaffScheduleRequest} DiscardDraftStaffScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DiscardDraftStaffScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DiscardDraftStaffScheduleRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DiscardDraftStaffScheduleRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DiscardDraftStaffScheduleRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DiscardDraftStaffScheduleRequest} DiscardDraftStaffScheduleRequest
                     */
                    DiscardDraftStaffScheduleRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DiscardDraftStaffScheduleRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DiscardDraftStaffScheduleRequest();
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DiscardDraftStaffScheduleRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.DiscardDraftStaffScheduleRequest} message DiscardDraftStaffScheduleRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DiscardDraftStaffScheduleRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startDateId = 0;
                            object.endDateId = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                        }
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        return object;
                    };
    
                    /**
                     * Converts this DiscardDraftStaffScheduleRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DiscardDraftStaffScheduleRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DiscardDraftStaffScheduleRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DiscardDraftStaffScheduleRequest;
                })();
    
                v1.CopyScheduleRequest = (function() {
    
                    /**
                     * Properties of a CopyScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @interface ICopyScheduleRequest
                     * @property {number|null} [storeId] CopyScheduleRequest storeId
                     * @property {number|null} [fromStartDateId] CopyScheduleRequest fromStartDateId
                     * @property {number|null} [fromEndDateId] CopyScheduleRequest fromEndDateId
                     * @property {number|null} [toStartDateId] CopyScheduleRequest toStartDateId
                     * @property {number|null} [toEndDateId] CopyScheduleRequest toEndDateId
                     */
    
                    /**
                     * Constructs a new CopyScheduleRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a CopyScheduleRequest.
                     * @implements ICopyScheduleRequest
                     * @constructor
                     * @param {palexy.streaming.v1.ICopyScheduleRequest=} [properties] Properties to set
                     */
                    function CopyScheduleRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CopyScheduleRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @instance
                     */
                    CopyScheduleRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * CopyScheduleRequest fromStartDateId.
                     * @member {number} fromStartDateId
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @instance
                     */
                    CopyScheduleRequest.prototype.fromStartDateId = 0;
    
                    /**
                     * CopyScheduleRequest fromEndDateId.
                     * @member {number} fromEndDateId
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @instance
                     */
                    CopyScheduleRequest.prototype.fromEndDateId = 0;
    
                    /**
                     * CopyScheduleRequest toStartDateId.
                     * @member {number} toStartDateId
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @instance
                     */
                    CopyScheduleRequest.prototype.toStartDateId = 0;
    
                    /**
                     * CopyScheduleRequest toEndDateId.
                     * @member {number} toEndDateId
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @instance
                     */
                    CopyScheduleRequest.prototype.toEndDateId = 0;
    
                    /**
                     * Creates a new CopyScheduleRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.ICopyScheduleRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.CopyScheduleRequest} CopyScheduleRequest instance
                     */
                    CopyScheduleRequest.create = function create(properties) {
                        return new CopyScheduleRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CopyScheduleRequest message. Does not implicitly {@link palexy.streaming.v1.CopyScheduleRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.ICopyScheduleRequest} message CopyScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CopyScheduleRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.fromStartDateId != null && Object.hasOwnProperty.call(message, "fromStartDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.fromStartDateId);
                        if (message.fromEndDateId != null && Object.hasOwnProperty.call(message, "fromEndDateId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.fromEndDateId);
                        if (message.toStartDateId != null && Object.hasOwnProperty.call(message, "toStartDateId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.toStartDateId);
                        if (message.toEndDateId != null && Object.hasOwnProperty.call(message, "toEndDateId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.toEndDateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CopyScheduleRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.CopyScheduleRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.ICopyScheduleRequest} message CopyScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CopyScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CopyScheduleRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.CopyScheduleRequest} CopyScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CopyScheduleRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.CopyScheduleRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.fromStartDateId = reader.int32();
                                break;
                            case 3:
                                message.fromEndDateId = reader.int32();
                                break;
                            case 4:
                                message.toStartDateId = reader.int32();
                                break;
                            case 5:
                                message.toEndDateId = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CopyScheduleRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.CopyScheduleRequest} CopyScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CopyScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CopyScheduleRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CopyScheduleRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.fromStartDateId != null && message.hasOwnProperty("fromStartDateId"))
                            if (!$util.isInteger(message.fromStartDateId))
                                return "fromStartDateId: integer expected";
                        if (message.fromEndDateId != null && message.hasOwnProperty("fromEndDateId"))
                            if (!$util.isInteger(message.fromEndDateId))
                                return "fromEndDateId: integer expected";
                        if (message.toStartDateId != null && message.hasOwnProperty("toStartDateId"))
                            if (!$util.isInteger(message.toStartDateId))
                                return "toStartDateId: integer expected";
                        if (message.toEndDateId != null && message.hasOwnProperty("toEndDateId"))
                            if (!$util.isInteger(message.toEndDateId))
                                return "toEndDateId: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a CopyScheduleRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.CopyScheduleRequest} CopyScheduleRequest
                     */
                    CopyScheduleRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.CopyScheduleRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.CopyScheduleRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.fromStartDateId != null)
                            message.fromStartDateId = object.fromStartDateId | 0;
                        if (object.fromEndDateId != null)
                            message.fromEndDateId = object.fromEndDateId | 0;
                        if (object.toStartDateId != null)
                            message.toStartDateId = object.toStartDateId | 0;
                        if (object.toEndDateId != null)
                            message.toEndDateId = object.toEndDateId | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CopyScheduleRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @static
                     * @param {palexy.streaming.v1.CopyScheduleRequest} message CopyScheduleRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CopyScheduleRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.fromStartDateId = 0;
                            object.fromEndDateId = 0;
                            object.toStartDateId = 0;
                            object.toEndDateId = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.fromStartDateId != null && message.hasOwnProperty("fromStartDateId"))
                            object.fromStartDateId = message.fromStartDateId;
                        if (message.fromEndDateId != null && message.hasOwnProperty("fromEndDateId"))
                            object.fromEndDateId = message.fromEndDateId;
                        if (message.toStartDateId != null && message.hasOwnProperty("toStartDateId"))
                            object.toStartDateId = message.toStartDateId;
                        if (message.toEndDateId != null && message.hasOwnProperty("toEndDateId"))
                            object.toEndDateId = message.toEndDateId;
                        return object;
                    };
    
                    /**
                     * Converts this CopyScheduleRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.CopyScheduleRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CopyScheduleRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CopyScheduleRequest;
                })();
    
                v1.StaffScheduleGeneralSetting = (function() {
    
                    /**
                     * Properties of a StaffScheduleGeneralSetting.
                     * @memberof palexy.streaming.v1
                     * @interface IStaffScheduleGeneralSetting
                     * @property {number|null} [id] StaffScheduleGeneralSetting id
                     * @property {number|null} [companyId] StaffScheduleGeneralSetting companyId
                     * @property {number|null} [recommendedMaxHoursPerWeek] StaffScheduleGeneralSetting recommendedMaxHoursPerWeek
                     * @property {number|null} [recommendedMaxHoursPerDay] StaffScheduleGeneralSetting recommendedMaxHoursPerDay
                     * @property {number|null} [recommendedMinHoursBetweenLongShifts] StaffScheduleGeneralSetting recommendedMinHoursBetweenLongShifts
                     * @property {number|null} [createTimeMs] StaffScheduleGeneralSetting createTimeMs
                     * @property {number|null} [updateTimeMs] StaffScheduleGeneralSetting updateTimeMs
                     * @property {number|null} [usualNumOfShiftsPerStaffPerWeek] StaffScheduleGeneralSetting usualNumOfShiftsPerStaffPerWeek
                     */
    
                    /**
                     * Constructs a new StaffScheduleGeneralSetting.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a StaffScheduleGeneralSetting.
                     * @implements IStaffScheduleGeneralSetting
                     * @constructor
                     * @param {palexy.streaming.v1.IStaffScheduleGeneralSetting=} [properties] Properties to set
                     */
                    function StaffScheduleGeneralSetting(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StaffScheduleGeneralSetting id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @instance
                     */
                    StaffScheduleGeneralSetting.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffScheduleGeneralSetting companyId.
                     * @member {number} companyId
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @instance
                     */
                    StaffScheduleGeneralSetting.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffScheduleGeneralSetting recommendedMaxHoursPerWeek.
                     * @member {number} recommendedMaxHoursPerWeek
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @instance
                     */
                    StaffScheduleGeneralSetting.prototype.recommendedMaxHoursPerWeek = 0;
    
                    /**
                     * StaffScheduleGeneralSetting recommendedMaxHoursPerDay.
                     * @member {number} recommendedMaxHoursPerDay
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @instance
                     */
                    StaffScheduleGeneralSetting.prototype.recommendedMaxHoursPerDay = 0;
    
                    /**
                     * StaffScheduleGeneralSetting recommendedMinHoursBetweenLongShifts.
                     * @member {number} recommendedMinHoursBetweenLongShifts
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @instance
                     */
                    StaffScheduleGeneralSetting.prototype.recommendedMinHoursBetweenLongShifts = 0;
    
                    /**
                     * StaffScheduleGeneralSetting createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @instance
                     */
                    StaffScheduleGeneralSetting.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffScheduleGeneralSetting updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @instance
                     */
                    StaffScheduleGeneralSetting.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StaffScheduleGeneralSetting usualNumOfShiftsPerStaffPerWeek.
                     * @member {number} usualNumOfShiftsPerStaffPerWeek
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @instance
                     */
                    StaffScheduleGeneralSetting.prototype.usualNumOfShiftsPerStaffPerWeek = 0;
    
                    /**
                     * Creates a new StaffScheduleGeneralSetting instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @static
                     * @param {palexy.streaming.v1.IStaffScheduleGeneralSetting=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.StaffScheduleGeneralSetting} StaffScheduleGeneralSetting instance
                     */
                    StaffScheduleGeneralSetting.create = function create(properties) {
                        return new StaffScheduleGeneralSetting(properties);
                    };
    
                    /**
                     * Encodes the specified StaffScheduleGeneralSetting message. Does not implicitly {@link palexy.streaming.v1.StaffScheduleGeneralSetting.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @static
                     * @param {palexy.streaming.v1.IStaffScheduleGeneralSetting} message StaffScheduleGeneralSetting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffScheduleGeneralSetting.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.recommendedMaxHoursPerWeek != null && Object.hasOwnProperty.call(message, "recommendedMaxHoursPerWeek"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.recommendedMaxHoursPerWeek);
                        if (message.recommendedMaxHoursPerDay != null && Object.hasOwnProperty.call(message, "recommendedMaxHoursPerDay"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.recommendedMaxHoursPerDay);
                        if (message.recommendedMinHoursBetweenLongShifts != null && Object.hasOwnProperty.call(message, "recommendedMinHoursBetweenLongShifts"))
                            writer.uint32(/* id 5, wireType 5 =*/45).float(message.recommendedMinHoursBetweenLongShifts);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.updateTimeMs);
                        if (message.usualNumOfShiftsPerStaffPerWeek != null && Object.hasOwnProperty.call(message, "usualNumOfShiftsPerStaffPerWeek"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.usualNumOfShiftsPerStaffPerWeek);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StaffScheduleGeneralSetting message, length delimited. Does not implicitly {@link palexy.streaming.v1.StaffScheduleGeneralSetting.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @static
                     * @param {palexy.streaming.v1.IStaffScheduleGeneralSetting} message StaffScheduleGeneralSetting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StaffScheduleGeneralSetting.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StaffScheduleGeneralSetting message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.StaffScheduleGeneralSetting} StaffScheduleGeneralSetting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffScheduleGeneralSetting.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.StaffScheduleGeneralSetting();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.recommendedMaxHoursPerWeek = reader.float();
                                break;
                            case 4:
                                message.recommendedMaxHoursPerDay = reader.float();
                                break;
                            case 5:
                                message.recommendedMinHoursBetweenLongShifts = reader.float();
                                break;
                            case 6:
                                message.createTimeMs = reader.int64();
                                break;
                            case 7:
                                message.updateTimeMs = reader.int64();
                                break;
                            case 8:
                                message.usualNumOfShiftsPerStaffPerWeek = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StaffScheduleGeneralSetting message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.StaffScheduleGeneralSetting} StaffScheduleGeneralSetting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StaffScheduleGeneralSetting.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StaffScheduleGeneralSetting message.
                     * @function verify
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StaffScheduleGeneralSetting.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.recommendedMaxHoursPerWeek != null && message.hasOwnProperty("recommendedMaxHoursPerWeek"))
                            if (typeof message.recommendedMaxHoursPerWeek !== "number")
                                return "recommendedMaxHoursPerWeek: number expected";
                        if (message.recommendedMaxHoursPerDay != null && message.hasOwnProperty("recommendedMaxHoursPerDay"))
                            if (typeof message.recommendedMaxHoursPerDay !== "number")
                                return "recommendedMaxHoursPerDay: number expected";
                        if (message.recommendedMinHoursBetweenLongShifts != null && message.hasOwnProperty("recommendedMinHoursBetweenLongShifts"))
                            if (typeof message.recommendedMinHoursBetweenLongShifts !== "number")
                                return "recommendedMinHoursBetweenLongShifts: number expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        if (message.usualNumOfShiftsPerStaffPerWeek != null && message.hasOwnProperty("usualNumOfShiftsPerStaffPerWeek"))
                            if (!$util.isInteger(message.usualNumOfShiftsPerStaffPerWeek))
                                return "usualNumOfShiftsPerStaffPerWeek: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a StaffScheduleGeneralSetting message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.StaffScheduleGeneralSetting} StaffScheduleGeneralSetting
                     */
                    StaffScheduleGeneralSetting.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.StaffScheduleGeneralSetting)
                            return object;
                        var message = new $root.palexy.streaming.v1.StaffScheduleGeneralSetting();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.recommendedMaxHoursPerWeek != null)
                            message.recommendedMaxHoursPerWeek = Number(object.recommendedMaxHoursPerWeek);
                        if (object.recommendedMaxHoursPerDay != null)
                            message.recommendedMaxHoursPerDay = Number(object.recommendedMaxHoursPerDay);
                        if (object.recommendedMinHoursBetweenLongShifts != null)
                            message.recommendedMinHoursBetweenLongShifts = Number(object.recommendedMinHoursBetweenLongShifts);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        if (object.usualNumOfShiftsPerStaffPerWeek != null)
                            message.usualNumOfShiftsPerStaffPerWeek = object.usualNumOfShiftsPerStaffPerWeek | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StaffScheduleGeneralSetting message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @static
                     * @param {palexy.streaming.v1.StaffScheduleGeneralSetting} message StaffScheduleGeneralSetting
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StaffScheduleGeneralSetting.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.recommendedMaxHoursPerWeek = 0;
                            object.recommendedMaxHoursPerDay = 0;
                            object.recommendedMinHoursBetweenLongShifts = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                            object.usualNumOfShiftsPerStaffPerWeek = 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.recommendedMaxHoursPerWeek != null && message.hasOwnProperty("recommendedMaxHoursPerWeek"))
                            object.recommendedMaxHoursPerWeek = options.json && !isFinite(message.recommendedMaxHoursPerWeek) ? String(message.recommendedMaxHoursPerWeek) : message.recommendedMaxHoursPerWeek;
                        if (message.recommendedMaxHoursPerDay != null && message.hasOwnProperty("recommendedMaxHoursPerDay"))
                            object.recommendedMaxHoursPerDay = options.json && !isFinite(message.recommendedMaxHoursPerDay) ? String(message.recommendedMaxHoursPerDay) : message.recommendedMaxHoursPerDay;
                        if (message.recommendedMinHoursBetweenLongShifts != null && message.hasOwnProperty("recommendedMinHoursBetweenLongShifts"))
                            object.recommendedMinHoursBetweenLongShifts = options.json && !isFinite(message.recommendedMinHoursBetweenLongShifts) ? String(message.recommendedMinHoursBetweenLongShifts) : message.recommendedMinHoursBetweenLongShifts;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        if (message.usualNumOfShiftsPerStaffPerWeek != null && message.hasOwnProperty("usualNumOfShiftsPerStaffPerWeek"))
                            object.usualNumOfShiftsPerStaffPerWeek = message.usualNumOfShiftsPerStaffPerWeek;
                        return object;
                    };
    
                    /**
                     * Converts this StaffScheduleGeneralSetting to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.StaffScheduleGeneralSetting
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StaffScheduleGeneralSetting.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StaffScheduleGeneralSetting;
                })();
    
                v1.GetStoresForLateVideoCheckingRequest = (function() {
    
                    /**
                     * Properties of a GetStoresForLateVideoCheckingRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStoresForLateVideoCheckingRequest
                     */
    
                    /**
                     * Constructs a new GetStoresForLateVideoCheckingRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStoresForLateVideoCheckingRequest.
                     * @implements IGetStoresForLateVideoCheckingRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStoresForLateVideoCheckingRequest=} [properties] Properties to set
                     */
                    function GetStoresForLateVideoCheckingRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Creates a new GetStoresForLateVideoCheckingRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoresForLateVideoCheckingRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStoresForLateVideoCheckingRequest} GetStoresForLateVideoCheckingRequest instance
                     */
                    GetStoresForLateVideoCheckingRequest.create = function create(properties) {
                        return new GetStoresForLateVideoCheckingRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStoresForLateVideoCheckingRequest message. Does not implicitly {@link palexy.streaming.v1.GetStoresForLateVideoCheckingRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoresForLateVideoCheckingRequest} message GetStoresForLateVideoCheckingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoresForLateVideoCheckingRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStoresForLateVideoCheckingRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStoresForLateVideoCheckingRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetStoresForLateVideoCheckingRequest} message GetStoresForLateVideoCheckingRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoresForLateVideoCheckingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStoresForLateVideoCheckingRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStoresForLateVideoCheckingRequest} GetStoresForLateVideoCheckingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoresForLateVideoCheckingRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStoresForLateVideoCheckingRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStoresForLateVideoCheckingRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStoresForLateVideoCheckingRequest} GetStoresForLateVideoCheckingRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoresForLateVideoCheckingRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStoresForLateVideoCheckingRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStoresForLateVideoCheckingRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStoresForLateVideoCheckingRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStoresForLateVideoCheckingRequest} GetStoresForLateVideoCheckingRequest
                     */
                    GetStoresForLateVideoCheckingRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStoresForLateVideoCheckingRequest)
                            return object;
                        return new $root.palexy.streaming.v1.GetStoresForLateVideoCheckingRequest();
                    };
    
                    /**
                     * Creates a plain object from a GetStoresForLateVideoCheckingRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingRequest
                     * @static
                     * @param {palexy.streaming.v1.GetStoresForLateVideoCheckingRequest} message GetStoresForLateVideoCheckingRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStoresForLateVideoCheckingRequest.toObject = function toObject() {
                        return {};
                    };
    
                    /**
                     * Converts this GetStoresForLateVideoCheckingRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStoresForLateVideoCheckingRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStoresForLateVideoCheckingRequest;
                })();
    
                v1.GetStoresForLateVideoCheckingResponse = (function() {
    
                    /**
                     * Properties of a GetStoresForLateVideoCheckingResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IGetStoresForLateVideoCheckingResponse
                     * @property {Array.<palexy.streaming.v1.IStore>|null} [stores] GetStoresForLateVideoCheckingResponse stores
                     */
    
                    /**
                     * Constructs a new GetStoresForLateVideoCheckingResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetStoresForLateVideoCheckingResponse.
                     * @implements IGetStoresForLateVideoCheckingResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IGetStoresForLateVideoCheckingResponse=} [properties] Properties to set
                     */
                    function GetStoresForLateVideoCheckingResponse(properties) {
                        this.stores = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStoresForLateVideoCheckingResponse stores.
                     * @member {Array.<palexy.streaming.v1.IStore>} stores
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingResponse
                     * @instance
                     */
                    GetStoresForLateVideoCheckingResponse.prototype.stores = $util.emptyArray;
    
                    /**
                     * Creates a new GetStoresForLateVideoCheckingResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetStoresForLateVideoCheckingResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetStoresForLateVideoCheckingResponse} GetStoresForLateVideoCheckingResponse instance
                     */
                    GetStoresForLateVideoCheckingResponse.create = function create(properties) {
                        return new GetStoresForLateVideoCheckingResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetStoresForLateVideoCheckingResponse message. Does not implicitly {@link palexy.streaming.v1.GetStoresForLateVideoCheckingResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetStoresForLateVideoCheckingResponse} message GetStoresForLateVideoCheckingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoresForLateVideoCheckingResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stores != null && message.stores.length)
                            for (var i = 0; i < message.stores.length; ++i)
                                $root.palexy.streaming.v1.Store.encode(message.stores[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStoresForLateVideoCheckingResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetStoresForLateVideoCheckingResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingResponse
                     * @static
                     * @param {palexy.streaming.v1.IGetStoresForLateVideoCheckingResponse} message GetStoresForLateVideoCheckingResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoresForLateVideoCheckingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStoresForLateVideoCheckingResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetStoresForLateVideoCheckingResponse} GetStoresForLateVideoCheckingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoresForLateVideoCheckingResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetStoresForLateVideoCheckingResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.stores && message.stores.length))
                                    message.stores = [];
                                message.stores.push($root.palexy.streaming.v1.Store.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStoresForLateVideoCheckingResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetStoresForLateVideoCheckingResponse} GetStoresForLateVideoCheckingResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoresForLateVideoCheckingResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStoresForLateVideoCheckingResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStoresForLateVideoCheckingResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stores != null && message.hasOwnProperty("stores")) {
                            if (!Array.isArray(message.stores))
                                return "stores: array expected";
                            for (var i = 0; i < message.stores.length; ++i) {
                                var error = $root.palexy.streaming.v1.Store.verify(message.stores[i]);
                                if (error)
                                    return "stores." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetStoresForLateVideoCheckingResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetStoresForLateVideoCheckingResponse} GetStoresForLateVideoCheckingResponse
                     */
                    GetStoresForLateVideoCheckingResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetStoresForLateVideoCheckingResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetStoresForLateVideoCheckingResponse();
                        if (object.stores) {
                            if (!Array.isArray(object.stores))
                                throw TypeError(".palexy.streaming.v1.GetStoresForLateVideoCheckingResponse.stores: array expected");
                            message.stores = [];
                            for (var i = 0; i < object.stores.length; ++i) {
                                if (typeof object.stores[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.GetStoresForLateVideoCheckingResponse.stores: object expected");
                                message.stores[i] = $root.palexy.streaming.v1.Store.fromObject(object.stores[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStoresForLateVideoCheckingResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingResponse
                     * @static
                     * @param {palexy.streaming.v1.GetStoresForLateVideoCheckingResponse} message GetStoresForLateVideoCheckingResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStoresForLateVideoCheckingResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.stores = [];
                        if (message.stores && message.stores.length) {
                            object.stores = [];
                            for (var j = 0; j < message.stores.length; ++j)
                                object.stores[j] = $root.palexy.streaming.v1.Store.toObject(message.stores[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetStoresForLateVideoCheckingResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetStoresForLateVideoCheckingResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStoresForLateVideoCheckingResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStoresForLateVideoCheckingResponse;
                })();
    
                v1.MallService = (function() {
    
                    /**
                     * Constructs a new MallService service.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a MallService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function MallService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (MallService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MallService;
    
                    /**
                     * Creates new MallService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.streaming.v1.MallService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {MallService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    MallService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.MallService#getMall}.
                     * @memberof palexy.streaming.v1.MallService
                     * @typedef GetMallCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Mall} [response] Mall
                     */
    
                    /**
                     * Calls GetMall.
                     * @function getMall
                     * @memberof palexy.streaming.v1.MallService
                     * @instance
                     * @param {palexy.streaming.v1.IGetMallRequest} request GetMallRequest message or plain object
                     * @param {palexy.streaming.v1.MallService.GetMallCallback} callback Node-style callback called with the error, if any, and Mall
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(MallService.prototype.getMall = function getMall(request, callback) {
                        return this.rpcCall(getMall, $root.palexy.streaming.v1.GetMallRequest, $root.palexy.streaming.v1.Mall, request, callback);
                    }, "name", { value: "GetMall" });
    
                    /**
                     * Calls GetMall.
                     * @function getMall
                     * @memberof palexy.streaming.v1.MallService
                     * @instance
                     * @param {palexy.streaming.v1.IGetMallRequest} request GetMallRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Mall>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.MallService#listMalls}.
                     * @memberof palexy.streaming.v1.MallService
                     * @typedef ListMallsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.ListMallResponse} [response] ListMallResponse
                     */
    
                    /**
                     * Calls ListMalls.
                     * @function listMalls
                     * @memberof palexy.streaming.v1.MallService
                     * @instance
                     * @param {palexy.streaming.v1.IListMallRequest} request ListMallRequest message or plain object
                     * @param {palexy.streaming.v1.MallService.ListMallsCallback} callback Node-style callback called with the error, if any, and ListMallResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(MallService.prototype.listMalls = function listMalls(request, callback) {
                        return this.rpcCall(listMalls, $root.palexy.streaming.v1.ListMallRequest, $root.palexy.streaming.v1.ListMallResponse, request, callback);
                    }, "name", { value: "ListMalls" });
    
                    /**
                     * Calls ListMalls.
                     * @function listMalls
                     * @memberof palexy.streaming.v1.MallService
                     * @instance
                     * @param {palexy.streaming.v1.IListMallRequest} request ListMallRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.ListMallResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.MallService#createMall}.
                     * @memberof palexy.streaming.v1.MallService
                     * @typedef CreateMallCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Mall} [response] Mall
                     */
    
                    /**
                     * Calls CreateMall.
                     * @function createMall
                     * @memberof palexy.streaming.v1.MallService
                     * @instance
                     * @param {palexy.streaming.v1.IMall} request Mall message or plain object
                     * @param {palexy.streaming.v1.MallService.CreateMallCallback} callback Node-style callback called with the error, if any, and Mall
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(MallService.prototype.createMall = function createMall(request, callback) {
                        return this.rpcCall(createMall, $root.palexy.streaming.v1.Mall, $root.palexy.streaming.v1.Mall, request, callback);
                    }, "name", { value: "CreateMall" });
    
                    /**
                     * Calls CreateMall.
                     * @function createMall
                     * @memberof palexy.streaming.v1.MallService
                     * @instance
                     * @param {palexy.streaming.v1.IMall} request Mall message or plain object
                     * @returns {Promise<palexy.streaming.v1.Mall>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.MallService#updateMall}.
                     * @memberof palexy.streaming.v1.MallService
                     * @typedef UpdateMallCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.streaming.v1.Mall} [response] Mall
                     */
    
                    /**
                     * Calls UpdateMall.
                     * @function updateMall
                     * @memberof palexy.streaming.v1.MallService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateMallRequest} request UpdateMallRequest message or plain object
                     * @param {palexy.streaming.v1.MallService.UpdateMallCallback} callback Node-style callback called with the error, if any, and Mall
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(MallService.prototype.updateMall = function updateMall(request, callback) {
                        return this.rpcCall(updateMall, $root.palexy.streaming.v1.UpdateMallRequest, $root.palexy.streaming.v1.Mall, request, callback);
                    }, "name", { value: "UpdateMall" });
    
                    /**
                     * Calls UpdateMall.
                     * @function updateMall
                     * @memberof palexy.streaming.v1.MallService
                     * @instance
                     * @param {palexy.streaming.v1.IUpdateMallRequest} request UpdateMallRequest message or plain object
                     * @returns {Promise<palexy.streaming.v1.Mall>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.streaming.v1.MallService#deleteMall}.
                     * @memberof palexy.streaming.v1.MallService
                     * @typedef DeleteMallCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteMall.
                     * @function deleteMall
                     * @memberof palexy.streaming.v1.MallService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteMallRequest} request DeleteMallRequest message or plain object
                     * @param {palexy.streaming.v1.MallService.DeleteMallCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(MallService.prototype.deleteMall = function deleteMall(request, callback) {
                        return this.rpcCall(deleteMall, $root.palexy.streaming.v1.DeleteMallRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteMall" });
    
                    /**
                     * Calls DeleteMall.
                     * @function deleteMall
                     * @memberof palexy.streaming.v1.MallService
                     * @instance
                     * @param {palexy.streaming.v1.IDeleteMallRequest} request DeleteMallRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return MallService;
                })();
    
                v1.Mall = (function() {
    
                    /**
                     * Properties of a Mall.
                     * @memberof palexy.streaming.v1
                     * @interface IMall
                     * @property {number|null} [id] Mall id
                     * @property {string|null} [name] Mall name
                     * @property {string|null} [description] Mall description
                     * @property {string|null} [country] Mall country
                     * @property {string|null} [addressPrimary] Mall addressPrimary
                     * @property {string|null} [addressSecondary] Mall addressSecondary
                     * @property {string|null} [dependentLocality] Mall dependentLocality
                     * @property {string|null} [locality] Mall locality
                     * @property {string|null} [adminArea] Mall adminArea
                     * @property {palexy.streaming.v1.IGeoCoordinate|null} [geoCoordinate] Mall geoCoordinate
                     * @property {palexy.streaming.v1.Mall.MallType|null} [mallType] Mall mallType
                     * @property {number|null} [createTimeMs] Mall createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] Mall lastUpdateTimeMs
                     */
    
                    /**
                     * Constructs a new Mall.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a Mall.
                     * @implements IMall
                     * @constructor
                     * @param {palexy.streaming.v1.IMall=} [properties] Properties to set
                     */
                    function Mall(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Mall id.
                     * @member {number} id
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Mall name.
                     * @member {string} name
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.name = "";
    
                    /**
                     * Mall description.
                     * @member {string} description
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.description = "";
    
                    /**
                     * Mall country.
                     * @member {string} country
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.country = "";
    
                    /**
                     * Mall addressPrimary.
                     * @member {string} addressPrimary
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.addressPrimary = "";
    
                    /**
                     * Mall addressSecondary.
                     * @member {string} addressSecondary
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.addressSecondary = "";
    
                    /**
                     * Mall dependentLocality.
                     * @member {string} dependentLocality
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.dependentLocality = "";
    
                    /**
                     * Mall locality.
                     * @member {string} locality
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.locality = "";
    
                    /**
                     * Mall adminArea.
                     * @member {string} adminArea
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.adminArea = "";
    
                    /**
                     * Mall geoCoordinate.
                     * @member {palexy.streaming.v1.IGeoCoordinate|null|undefined} geoCoordinate
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.geoCoordinate = null;
    
                    /**
                     * Mall mallType.
                     * @member {palexy.streaming.v1.Mall.MallType} mallType
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.mallType = 0;
    
                    /**
                     * Mall createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Mall lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     */
                    Mall.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new Mall instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Mall
                     * @static
                     * @param {palexy.streaming.v1.IMall=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Mall} Mall instance
                     */
                    Mall.create = function create(properties) {
                        return new Mall(properties);
                    };
    
                    /**
                     * Encodes the specified Mall message. Does not implicitly {@link palexy.streaming.v1.Mall.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Mall
                     * @static
                     * @param {palexy.streaming.v1.IMall} message Mall message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Mall.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                        if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.country);
                        if (message.addressPrimary != null && Object.hasOwnProperty.call(message, "addressPrimary"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.addressPrimary);
                        if (message.addressSecondary != null && Object.hasOwnProperty.call(message, "addressSecondary"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.addressSecondary);
                        if (message.dependentLocality != null && Object.hasOwnProperty.call(message, "dependentLocality"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.dependentLocality);
                        if (message.locality != null && Object.hasOwnProperty.call(message, "locality"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.locality);
                        if (message.adminArea != null && Object.hasOwnProperty.call(message, "adminArea"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.adminArea);
                        if (message.geoCoordinate != null && Object.hasOwnProperty.call(message, "geoCoordinate"))
                            $root.palexy.streaming.v1.GeoCoordinate.encode(message.geoCoordinate, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.mallType != null && Object.hasOwnProperty.call(message, "mallType"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.mallType);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int64(message.lastUpdateTimeMs);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Mall message, length delimited. Does not implicitly {@link palexy.streaming.v1.Mall.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Mall
                     * @static
                     * @param {palexy.streaming.v1.IMall} message Mall message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Mall.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Mall message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Mall
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Mall} Mall
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Mall.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Mall();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.description = reader.string();
                                break;
                            case 4:
                                message.country = reader.string();
                                break;
                            case 5:
                                message.addressPrimary = reader.string();
                                break;
                            case 6:
                                message.addressSecondary = reader.string();
                                break;
                            case 7:
                                message.dependentLocality = reader.string();
                                break;
                            case 8:
                                message.locality = reader.string();
                                break;
                            case 9:
                                message.adminArea = reader.string();
                                break;
                            case 10:
                                message.geoCoordinate = $root.palexy.streaming.v1.GeoCoordinate.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.mallType = reader.int32();
                                break;
                            case 12:
                                message.createTimeMs = reader.int64();
                                break;
                            case 13:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Mall message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Mall
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Mall} Mall
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Mall.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Mall message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Mall
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Mall.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.country != null && message.hasOwnProperty("country"))
                            if (!$util.isString(message.country))
                                return "country: string expected";
                        if (message.addressPrimary != null && message.hasOwnProperty("addressPrimary"))
                            if (!$util.isString(message.addressPrimary))
                                return "addressPrimary: string expected";
                        if (message.addressSecondary != null && message.hasOwnProperty("addressSecondary"))
                            if (!$util.isString(message.addressSecondary))
                                return "addressSecondary: string expected";
                        if (message.dependentLocality != null && message.hasOwnProperty("dependentLocality"))
                            if (!$util.isString(message.dependentLocality))
                                return "dependentLocality: string expected";
                        if (message.locality != null && message.hasOwnProperty("locality"))
                            if (!$util.isString(message.locality))
                                return "locality: string expected";
                        if (message.adminArea != null && message.hasOwnProperty("adminArea"))
                            if (!$util.isString(message.adminArea))
                                return "adminArea: string expected";
                        if (message.geoCoordinate != null && message.hasOwnProperty("geoCoordinate")) {
                            var error = $root.palexy.streaming.v1.GeoCoordinate.verify(message.geoCoordinate);
                            if (error)
                                return "geoCoordinate." + error;
                        }
                        if (message.mallType != null && message.hasOwnProperty("mallType"))
                            switch (message.mallType) {
                            default:
                                return "mallType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a Mall message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Mall
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Mall} Mall
                     */
                    Mall.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Mall)
                            return object;
                        var message = new $root.palexy.streaming.v1.Mall();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.country != null)
                            message.country = String(object.country);
                        if (object.addressPrimary != null)
                            message.addressPrimary = String(object.addressPrimary);
                        if (object.addressSecondary != null)
                            message.addressSecondary = String(object.addressSecondary);
                        if (object.dependentLocality != null)
                            message.dependentLocality = String(object.dependentLocality);
                        if (object.locality != null)
                            message.locality = String(object.locality);
                        if (object.adminArea != null)
                            message.adminArea = String(object.adminArea);
                        if (object.geoCoordinate != null) {
                            if (typeof object.geoCoordinate !== "object")
                                throw TypeError(".palexy.streaming.v1.Mall.geoCoordinate: object expected");
                            message.geoCoordinate = $root.palexy.streaming.v1.GeoCoordinate.fromObject(object.geoCoordinate);
                        }
                        switch (object.mallType) {
                        case "UNKNOWN_MALL_TYPE":
                        case 0:
                            message.mallType = 0;
                            break;
                        case "TOURIST":
                        case 1:
                            message.mallType = 1;
                            break;
                        case "LOCAL":
                        case 2:
                            message.mallType = 2;
                            break;
                        }
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Mall message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Mall
                     * @static
                     * @param {palexy.streaming.v1.Mall} message Mall
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Mall.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            object.description = "";
                            object.country = "";
                            object.addressPrimary = "";
                            object.addressSecondary = "";
                            object.dependentLocality = "";
                            object.locality = "";
                            object.adminArea = "";
                            object.geoCoordinate = null;
                            object.mallType = options.enums === String ? "UNKNOWN_MALL_TYPE" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.country != null && message.hasOwnProperty("country"))
                            object.country = message.country;
                        if (message.addressPrimary != null && message.hasOwnProperty("addressPrimary"))
                            object.addressPrimary = message.addressPrimary;
                        if (message.addressSecondary != null && message.hasOwnProperty("addressSecondary"))
                            object.addressSecondary = message.addressSecondary;
                        if (message.dependentLocality != null && message.hasOwnProperty("dependentLocality"))
                            object.dependentLocality = message.dependentLocality;
                        if (message.locality != null && message.hasOwnProperty("locality"))
                            object.locality = message.locality;
                        if (message.adminArea != null && message.hasOwnProperty("adminArea"))
                            object.adminArea = message.adminArea;
                        if (message.geoCoordinate != null && message.hasOwnProperty("geoCoordinate"))
                            object.geoCoordinate = $root.palexy.streaming.v1.GeoCoordinate.toObject(message.geoCoordinate, options);
                        if (message.mallType != null && message.hasOwnProperty("mallType"))
                            object.mallType = options.enums === String ? $root.palexy.streaming.v1.Mall.MallType[message.mallType] : message.mallType;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        return object;
                    };
    
                    /**
                     * Converts this Mall to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Mall
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Mall.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * MallType enum.
                     * @name palexy.streaming.v1.Mall.MallType
                     * @enum {number}
                     * @property {number} UNKNOWN_MALL_TYPE=0 UNKNOWN_MALL_TYPE value
                     * @property {number} TOURIST=1 TOURIST value
                     * @property {number} LOCAL=2 LOCAL value
                     */
                    Mall.MallType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN_MALL_TYPE"] = 0;
                        values[valuesById[1] = "TOURIST"] = 1;
                        values[valuesById[2] = "LOCAL"] = 2;
                        return values;
                    })();
    
                    return Mall;
                })();
    
                v1.GetMallRequest = (function() {
    
                    /**
                     * Properties of a GetMallRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IGetMallRequest
                     * @property {number|null} [mallId] GetMallRequest mallId
                     */
    
                    /**
                     * Constructs a new GetMallRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a GetMallRequest.
                     * @implements IGetMallRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IGetMallRequest=} [properties] Properties to set
                     */
                    function GetMallRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetMallRequest mallId.
                     * @member {number} mallId
                     * @memberof palexy.streaming.v1.GetMallRequest
                     * @instance
                     */
                    GetMallRequest.prototype.mallId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetMallRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.GetMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetMallRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.GetMallRequest} GetMallRequest instance
                     */
                    GetMallRequest.create = function create(properties) {
                        return new GetMallRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetMallRequest message. Does not implicitly {@link palexy.streaming.v1.GetMallRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.GetMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetMallRequest} message GetMallRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetMallRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mallId != null && Object.hasOwnProperty.call(message, "mallId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.mallId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetMallRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.GetMallRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.GetMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IGetMallRequest} message GetMallRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetMallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetMallRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.GetMallRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.GetMallRequest} GetMallRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetMallRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.GetMallRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.mallId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetMallRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.GetMallRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.GetMallRequest} GetMallRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetMallRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetMallRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.GetMallRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetMallRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mallId != null && message.hasOwnProperty("mallId"))
                            if (!$util.isInteger(message.mallId) && !(message.mallId && $util.isInteger(message.mallId.low) && $util.isInteger(message.mallId.high)))
                                return "mallId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetMallRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.GetMallRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.GetMallRequest} GetMallRequest
                     */
                    GetMallRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.GetMallRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.GetMallRequest();
                        if (object.mallId != null)
                            if ($util.Long)
                                (message.mallId = $util.Long.fromValue(object.mallId)).unsigned = false;
                            else if (typeof object.mallId === "string")
                                message.mallId = parseInt(object.mallId, 10);
                            else if (typeof object.mallId === "number")
                                message.mallId = object.mallId;
                            else if (typeof object.mallId === "object")
                                message.mallId = new $util.LongBits(object.mallId.low >>> 0, object.mallId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetMallRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.GetMallRequest
                     * @static
                     * @param {palexy.streaming.v1.GetMallRequest} message GetMallRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetMallRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.mallId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.mallId = options.longs === String ? "0" : 0;
                        if (message.mallId != null && message.hasOwnProperty("mallId"))
                            if (typeof message.mallId === "number")
                                object.mallId = options.longs === String ? String(message.mallId) : message.mallId;
                            else
                                object.mallId = options.longs === String ? $util.Long.prototype.toString.call(message.mallId) : options.longs === Number ? new $util.LongBits(message.mallId.low >>> 0, message.mallId.high >>> 0).toNumber() : message.mallId;
                        return object;
                    };
    
                    /**
                     * Converts this GetMallRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.GetMallRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetMallRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetMallRequest;
                })();
    
                v1.ListMallRequest = (function() {
    
                    /**
                     * Properties of a ListMallRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IListMallRequest
                     * @property {palexy.streaming.v1.ListMallRequest.IMallFilter|null} [filter] ListMallRequest filter
                     * @property {number|null} [pageSize] ListMallRequest pageSize
                     * @property {string|null} [pageToken] ListMallRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListMallRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListMallRequest.
                     * @implements IListMallRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IListMallRequest=} [properties] Properties to set
                     */
                    function ListMallRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListMallRequest filter.
                     * @member {palexy.streaming.v1.ListMallRequest.IMallFilter|null|undefined} filter
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @instance
                     */
                    ListMallRequest.prototype.filter = null;
    
                    /**
                     * ListMallRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @instance
                     */
                    ListMallRequest.prototype.pageSize = 0;
    
                    /**
                     * ListMallRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @instance
                     */
                    ListMallRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListMallRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IListMallRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListMallRequest} ListMallRequest instance
                     */
                    ListMallRequest.create = function create(properties) {
                        return new ListMallRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListMallRequest message. Does not implicitly {@link palexy.streaming.v1.ListMallRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IListMallRequest} message ListMallRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMallRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                            $root.palexy.streaming.v1.ListMallRequest.MallFilter.encode(message.filter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListMallRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListMallRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IListMallRequest} message ListMallRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListMallRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListMallRequest} ListMallRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMallRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListMallRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.filter = $root.palexy.streaming.v1.ListMallRequest.MallFilter.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.pageSize = reader.int32();
                                break;
                            case 3:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListMallRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListMallRequest} ListMallRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMallRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListMallRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListMallRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.filter != null && message.hasOwnProperty("filter")) {
                            var error = $root.palexy.streaming.v1.ListMallRequest.MallFilter.verify(message.filter);
                            if (error)
                                return "filter." + error;
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListMallRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListMallRequest} ListMallRequest
                     */
                    ListMallRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListMallRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListMallRequest();
                        if (object.filter != null) {
                            if (typeof object.filter !== "object")
                                throw TypeError(".palexy.streaming.v1.ListMallRequest.filter: object expected");
                            message.filter = $root.palexy.streaming.v1.ListMallRequest.MallFilter.fromObject(object.filter);
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListMallRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @static
                     * @param {palexy.streaming.v1.ListMallRequest} message ListMallRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListMallRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.filter = null;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.filter != null && message.hasOwnProperty("filter"))
                            object.filter = $root.palexy.streaming.v1.ListMallRequest.MallFilter.toObject(message.filter, options);
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListMallRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListMallRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListMallRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    ListMallRequest.MallFilter = (function() {
    
                        /**
                         * Properties of a MallFilter.
                         * @memberof palexy.streaming.v1.ListMallRequest
                         * @interface IMallFilter
                         * @property {string|null} [name] MallFilter name
                         * @property {Array.<number>|null} [ids] MallFilter ids
                         */
    
                        /**
                         * Constructs a new MallFilter.
                         * @memberof palexy.streaming.v1.ListMallRequest
                         * @classdesc Represents a MallFilter.
                         * @implements IMallFilter
                         * @constructor
                         * @param {palexy.streaming.v1.ListMallRequest.IMallFilter=} [properties] Properties to set
                         */
                        function MallFilter(properties) {
                            this.ids = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * MallFilter name.
                         * @member {string} name
                         * @memberof palexy.streaming.v1.ListMallRequest.MallFilter
                         * @instance
                         */
                        MallFilter.prototype.name = "";
    
                        /**
                         * MallFilter ids.
                         * @member {Array.<number>} ids
                         * @memberof palexy.streaming.v1.ListMallRequest.MallFilter
                         * @instance
                         */
                        MallFilter.prototype.ids = $util.emptyArray;
    
                        /**
                         * Creates a new MallFilter instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.ListMallRequest.MallFilter
                         * @static
                         * @param {palexy.streaming.v1.ListMallRequest.IMallFilter=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.ListMallRequest.MallFilter} MallFilter instance
                         */
                        MallFilter.create = function create(properties) {
                            return new MallFilter(properties);
                        };
    
                        /**
                         * Encodes the specified MallFilter message. Does not implicitly {@link palexy.streaming.v1.ListMallRequest.MallFilter.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.ListMallRequest.MallFilter
                         * @static
                         * @param {palexy.streaming.v1.ListMallRequest.IMallFilter} message MallFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        MallFilter.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.ids != null && message.ids.length) {
                                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                                for (var i = 0; i < message.ids.length; ++i)
                                    writer.int64(message.ids[i]);
                                writer.ldelim();
                            }
                            return writer;
                        };
    
                        /**
                         * Encodes the specified MallFilter message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListMallRequest.MallFilter.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.ListMallRequest.MallFilter
                         * @static
                         * @param {palexy.streaming.v1.ListMallRequest.IMallFilter} message MallFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        MallFilter.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a MallFilter message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.ListMallRequest.MallFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.ListMallRequest.MallFilter} MallFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        MallFilter.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListMallRequest.MallFilter();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    if (!(message.ids && message.ids.length))
                                        message.ids = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.ids.push(reader.int64());
                                    } else
                                        message.ids.push(reader.int64());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a MallFilter message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.ListMallRequest.MallFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.ListMallRequest.MallFilter} MallFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        MallFilter.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a MallFilter message.
                         * @function verify
                         * @memberof palexy.streaming.v1.ListMallRequest.MallFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        MallFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.ids != null && message.hasOwnProperty("ids")) {
                                if (!Array.isArray(message.ids))
                                    return "ids: array expected";
                                for (var i = 0; i < message.ids.length; ++i)
                                    if (!$util.isInteger(message.ids[i]) && !(message.ids[i] && $util.isInteger(message.ids[i].low) && $util.isInteger(message.ids[i].high)))
                                        return "ids: integer|Long[] expected";
                            }
                            return null;
                        };
    
                        /**
                         * Creates a MallFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.ListMallRequest.MallFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.ListMallRequest.MallFilter} MallFilter
                         */
                        MallFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.ListMallRequest.MallFilter)
                                return object;
                            var message = new $root.palexy.streaming.v1.ListMallRequest.MallFilter();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.ids) {
                                if (!Array.isArray(object.ids))
                                    throw TypeError(".palexy.streaming.v1.ListMallRequest.MallFilter.ids: array expected");
                                message.ids = [];
                                for (var i = 0; i < object.ids.length; ++i)
                                    if ($util.Long)
                                        (message.ids[i] = $util.Long.fromValue(object.ids[i])).unsigned = false;
                                    else if (typeof object.ids[i] === "string")
                                        message.ids[i] = parseInt(object.ids[i], 10);
                                    else if (typeof object.ids[i] === "number")
                                        message.ids[i] = object.ids[i];
                                    else if (typeof object.ids[i] === "object")
                                        message.ids[i] = new $util.LongBits(object.ids[i].low >>> 0, object.ids[i].high >>> 0).toNumber();
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a MallFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.ListMallRequest.MallFilter
                         * @static
                         * @param {palexy.streaming.v1.ListMallRequest.MallFilter} message MallFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        MallFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.ids = [];
                            if (options.defaults)
                                object.name = "";
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.ids && message.ids.length) {
                                object.ids = [];
                                for (var j = 0; j < message.ids.length; ++j)
                                    if (typeof message.ids[j] === "number")
                                        object.ids[j] = options.longs === String ? String(message.ids[j]) : message.ids[j];
                                    else
                                        object.ids[j] = options.longs === String ? $util.Long.prototype.toString.call(message.ids[j]) : options.longs === Number ? new $util.LongBits(message.ids[j].low >>> 0, message.ids[j].high >>> 0).toNumber() : message.ids[j];
                            }
                            return object;
                        };
    
                        /**
                         * Converts this MallFilter to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.ListMallRequest.MallFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        MallFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return MallFilter;
                    })();
    
                    return ListMallRequest;
                })();
    
                v1.ListMallResponse = (function() {
    
                    /**
                     * Properties of a ListMallResponse.
                     * @memberof palexy.streaming.v1
                     * @interface IListMallResponse
                     * @property {Array.<palexy.streaming.v1.IMall>|null} [malls] ListMallResponse malls
                     * @property {number|null} [totalElements] ListMallResponse totalElements
                     * @property {string|null} [nextPageToken] ListMallResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListMallResponse.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a ListMallResponse.
                     * @implements IListMallResponse
                     * @constructor
                     * @param {palexy.streaming.v1.IListMallResponse=} [properties] Properties to set
                     */
                    function ListMallResponse(properties) {
                        this.malls = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListMallResponse malls.
                     * @member {Array.<palexy.streaming.v1.IMall>} malls
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @instance
                     */
                    ListMallResponse.prototype.malls = $util.emptyArray;
    
                    /**
                     * ListMallResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @instance
                     */
                    ListMallResponse.prototype.totalElements = 0;
    
                    /**
                     * ListMallResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @instance
                     */
                    ListMallResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListMallResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @static
                     * @param {palexy.streaming.v1.IListMallResponse=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.ListMallResponse} ListMallResponse instance
                     */
                    ListMallResponse.create = function create(properties) {
                        return new ListMallResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListMallResponse message. Does not implicitly {@link palexy.streaming.v1.ListMallResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @static
                     * @param {palexy.streaming.v1.IListMallResponse} message ListMallResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMallResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.malls != null && message.malls.length)
                            for (var i = 0; i < message.malls.length; ++i)
                                $root.palexy.streaming.v1.Mall.encode(message.malls[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListMallResponse message, length delimited. Does not implicitly {@link palexy.streaming.v1.ListMallResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @static
                     * @param {palexy.streaming.v1.IListMallResponse} message ListMallResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListMallResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListMallResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.ListMallResponse} ListMallResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMallResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.ListMallResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.malls && message.malls.length))
                                    message.malls = [];
                                message.malls.push($root.palexy.streaming.v1.Mall.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int32();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListMallResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.ListMallResponse} ListMallResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListMallResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListMallResponse message.
                     * @function verify
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListMallResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.malls != null && message.hasOwnProperty("malls")) {
                            if (!Array.isArray(message.malls))
                                return "malls: array expected";
                            for (var i = 0; i < message.malls.length; ++i) {
                                var error = $root.palexy.streaming.v1.Mall.verify(message.malls[i]);
                                if (error)
                                    return "malls." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements))
                                return "totalElements: integer expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListMallResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.ListMallResponse} ListMallResponse
                     */
                    ListMallResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.ListMallResponse)
                            return object;
                        var message = new $root.palexy.streaming.v1.ListMallResponse();
                        if (object.malls) {
                            if (!Array.isArray(object.malls))
                                throw TypeError(".palexy.streaming.v1.ListMallResponse.malls: array expected");
                            message.malls = [];
                            for (var i = 0; i < object.malls.length; ++i) {
                                if (typeof object.malls[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.ListMallResponse.malls: object expected");
                                message.malls[i] = $root.palexy.streaming.v1.Mall.fromObject(object.malls[i]);
                            }
                        }
                        if (object.totalElements != null)
                            message.totalElements = object.totalElements | 0;
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListMallResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @static
                     * @param {palexy.streaming.v1.ListMallResponse} message ListMallResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListMallResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.malls = [];
                        if (options.defaults) {
                            object.totalElements = 0;
                            object.nextPageToken = "";
                        }
                        if (message.malls && message.malls.length) {
                            object.malls = [];
                            for (var j = 0; j < message.malls.length; ++j)
                                object.malls[j] = $root.palexy.streaming.v1.Mall.toObject(message.malls[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            object.totalElements = message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListMallResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.ListMallResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListMallResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListMallResponse;
                })();
    
                v1.UpdateMallRequest = (function() {
    
                    /**
                     * Properties of an UpdateMallRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IUpdateMallRequest
                     * @property {palexy.streaming.v1.IMall|null} [mall] UpdateMallRequest mall
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateMallRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateMallRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an UpdateMallRequest.
                     * @implements IUpdateMallRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IUpdateMallRequest=} [properties] Properties to set
                     */
                    function UpdateMallRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateMallRequest mall.
                     * @member {palexy.streaming.v1.IMall|null|undefined} mall
                     * @memberof palexy.streaming.v1.UpdateMallRequest
                     * @instance
                     */
                    UpdateMallRequest.prototype.mall = null;
    
                    /**
                     * UpdateMallRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.streaming.v1.UpdateMallRequest
                     * @instance
                     */
                    UpdateMallRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateMallRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.UpdateMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateMallRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.UpdateMallRequest} UpdateMallRequest instance
                     */
                    UpdateMallRequest.create = function create(properties) {
                        return new UpdateMallRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateMallRequest message. Does not implicitly {@link palexy.streaming.v1.UpdateMallRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.UpdateMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateMallRequest} message UpdateMallRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateMallRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mall != null && Object.hasOwnProperty.call(message, "mall"))
                            $root.palexy.streaming.v1.Mall.encode(message.mall, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateMallRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.UpdateMallRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.UpdateMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IUpdateMallRequest} message UpdateMallRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateMallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateMallRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.UpdateMallRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.UpdateMallRequest} UpdateMallRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateMallRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.UpdateMallRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.mall = $root.palexy.streaming.v1.Mall.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateMallRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.UpdateMallRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.UpdateMallRequest} UpdateMallRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateMallRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateMallRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.UpdateMallRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateMallRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mall != null && message.hasOwnProperty("mall")) {
                            var error = $root.palexy.streaming.v1.Mall.verify(message.mall);
                            if (error)
                                return "mall." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateMallRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.UpdateMallRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.UpdateMallRequest} UpdateMallRequest
                     */
                    UpdateMallRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.UpdateMallRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.UpdateMallRequest();
                        if (object.mall != null) {
                            if (typeof object.mall !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateMallRequest.mall: object expected");
                            message.mall = $root.palexy.streaming.v1.Mall.fromObject(object.mall);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.streaming.v1.UpdateMallRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateMallRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.UpdateMallRequest
                     * @static
                     * @param {palexy.streaming.v1.UpdateMallRequest} message UpdateMallRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateMallRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.mall = null;
                            object.updateMask = null;
                        }
                        if (message.mall != null && message.hasOwnProperty("mall"))
                            object.mall = $root.palexy.streaming.v1.Mall.toObject(message.mall, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateMallRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.UpdateMallRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateMallRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateMallRequest;
                })();
    
                v1.DeleteMallRequest = (function() {
    
                    /**
                     * Properties of a DeleteMallRequest.
                     * @memberof palexy.streaming.v1
                     * @interface IDeleteMallRequest
                     * @property {number|null} [mallId] DeleteMallRequest mallId
                     */
    
                    /**
                     * Constructs a new DeleteMallRequest.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DeleteMallRequest.
                     * @implements IDeleteMallRequest
                     * @constructor
                     * @param {palexy.streaming.v1.IDeleteMallRequest=} [properties] Properties to set
                     */
                    function DeleteMallRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteMallRequest mallId.
                     * @member {number} mallId
                     * @memberof palexy.streaming.v1.DeleteMallRequest
                     * @instance
                     */
                    DeleteMallRequest.prototype.mallId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteMallRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DeleteMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteMallRequest=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DeleteMallRequest} DeleteMallRequest instance
                     */
                    DeleteMallRequest.create = function create(properties) {
                        return new DeleteMallRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteMallRequest message. Does not implicitly {@link palexy.streaming.v1.DeleteMallRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DeleteMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteMallRequest} message DeleteMallRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteMallRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mallId != null && Object.hasOwnProperty.call(message, "mallId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.mallId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteMallRequest message, length delimited. Does not implicitly {@link palexy.streaming.v1.DeleteMallRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DeleteMallRequest
                     * @static
                     * @param {palexy.streaming.v1.IDeleteMallRequest} message DeleteMallRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteMallRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteMallRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DeleteMallRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DeleteMallRequest} DeleteMallRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteMallRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DeleteMallRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.mallId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteMallRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DeleteMallRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DeleteMallRequest} DeleteMallRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteMallRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteMallRequest message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DeleteMallRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteMallRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mallId != null && message.hasOwnProperty("mallId"))
                            if (!$util.isInteger(message.mallId) && !(message.mallId && $util.isInteger(message.mallId.low) && $util.isInteger(message.mallId.high)))
                                return "mallId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteMallRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DeleteMallRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DeleteMallRequest} DeleteMallRequest
                     */
                    DeleteMallRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DeleteMallRequest)
                            return object;
                        var message = new $root.palexy.streaming.v1.DeleteMallRequest();
                        if (object.mallId != null)
                            if ($util.Long)
                                (message.mallId = $util.Long.fromValue(object.mallId)).unsigned = false;
                            else if (typeof object.mallId === "string")
                                message.mallId = parseInt(object.mallId, 10);
                            else if (typeof object.mallId === "number")
                                message.mallId = object.mallId;
                            else if (typeof object.mallId === "object")
                                message.mallId = new $util.LongBits(object.mallId.low >>> 0, object.mallId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteMallRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DeleteMallRequest
                     * @static
                     * @param {palexy.streaming.v1.DeleteMallRequest} message DeleteMallRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteMallRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.mallId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.mallId = options.longs === String ? "0" : 0;
                        if (message.mallId != null && message.hasOwnProperty("mallId"))
                            if (typeof message.mallId === "number")
                                object.mallId = options.longs === String ? String(message.mallId) : message.mallId;
                            else
                                object.mallId = options.longs === String ? $util.Long.prototype.toString.call(message.mallId) : options.longs === Number ? new $util.LongBits(message.mallId.low >>> 0, message.mallId.high >>> 0).toNumber() : message.mallId;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteMallRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DeleteMallRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteMallRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteMallRequest;
                })();
    
                /**
                 * RawIcaStorageProvider enum.
                 * @name palexy.streaming.v1.RawIcaStorageProvider
                 * @enum {number}
                 * @property {number} RAW_PROVIDER_UNKNOWN=0 RAW_PROVIDER_UNKNOWN value
                 * @property {number} DATABASE=1 DATABASE value
                 * @property {number} GCP=2 GCP value
                 */
                v1.RawIcaStorageProvider = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "RAW_PROVIDER_UNKNOWN"] = 0;
                    values[valuesById[1] = "DATABASE"] = 1;
                    values[valuesById[2] = "GCP"] = 2;
                    return values;
                })();
    
                v1.DateRange = (function() {
    
                    /**
                     * Properties of a DateRange.
                     * @memberof palexy.streaming.v1
                     * @interface IDateRange
                     * @property {string|null} [startDate] DateRange startDate
                     * @property {string|null} [endDate] DateRange endDate
                     */
    
                    /**
                     * Constructs a new DateRange.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DateRange.
                     * @implements IDateRange
                     * @constructor
                     * @param {palexy.streaming.v1.IDateRange=} [properties] Properties to set
                     */
                    function DateRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DateRange startDate.
                     * @member {string} startDate
                     * @memberof palexy.streaming.v1.DateRange
                     * @instance
                     */
                    DateRange.prototype.startDate = "";
    
                    /**
                     * DateRange endDate.
                     * @member {string} endDate
                     * @memberof palexy.streaming.v1.DateRange
                     * @instance
                     */
                    DateRange.prototype.endDate = "";
    
                    /**
                     * Creates a new DateRange instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DateRange
                     * @static
                     * @param {palexy.streaming.v1.IDateRange=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DateRange} DateRange instance
                     */
                    DateRange.create = function create(properties) {
                        return new DateRange(properties);
                    };
    
                    /**
                     * Encodes the specified DateRange message. Does not implicitly {@link palexy.streaming.v1.DateRange.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DateRange
                     * @static
                     * @param {palexy.streaming.v1.IDateRange} message DateRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DateRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.startDate);
                        if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.endDate);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DateRange message, length delimited. Does not implicitly {@link palexy.streaming.v1.DateRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DateRange
                     * @static
                     * @param {palexy.streaming.v1.IDateRange} message DateRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DateRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DateRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DateRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DateRange} DateRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DateRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DateRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startDate = reader.string();
                                break;
                            case 2:
                                message.endDate = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DateRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DateRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DateRange} DateRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DateRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DateRange message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DateRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DateRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a DateRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DateRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DateRange} DateRange
                     */
                    DateRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DateRange)
                            return object;
                        var message = new $root.palexy.streaming.v1.DateRange();
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DateRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DateRange
                     * @static
                     * @param {palexy.streaming.v1.DateRange} message DateRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DateRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startDate = "";
                            object.endDate = "";
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        return object;
                    };
    
                    /**
                     * Converts this DateRange to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DateRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DateRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DateRange;
                })();
    
                v1.TimeRange = (function() {
    
                    /**
                     * Properties of a TimeRange.
                     * @memberof palexy.streaming.v1
                     * @interface ITimeRange
                     * @property {number|null} [startSecondOfDay] TimeRange startSecondOfDay
                     * @property {number|null} [endSecondOfDay] TimeRange endSecondOfDay
                     */
    
                    /**
                     * Constructs a new TimeRange.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a TimeRange.
                     * @implements ITimeRange
                     * @constructor
                     * @param {palexy.streaming.v1.ITimeRange=} [properties] Properties to set
                     */
                    function TimeRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TimeRange startSecondOfDay.
                     * @member {number} startSecondOfDay
                     * @memberof palexy.streaming.v1.TimeRange
                     * @instance
                     */
                    TimeRange.prototype.startSecondOfDay = 0;
    
                    /**
                     * TimeRange endSecondOfDay.
                     * @member {number} endSecondOfDay
                     * @memberof palexy.streaming.v1.TimeRange
                     * @instance
                     */
                    TimeRange.prototype.endSecondOfDay = 0;
    
                    /**
                     * Creates a new TimeRange instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.TimeRange
                     * @static
                     * @param {palexy.streaming.v1.ITimeRange=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.TimeRange} TimeRange instance
                     */
                    TimeRange.create = function create(properties) {
                        return new TimeRange(properties);
                    };
    
                    /**
                     * Encodes the specified TimeRange message. Does not implicitly {@link palexy.streaming.v1.TimeRange.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.TimeRange
                     * @static
                     * @param {palexy.streaming.v1.ITimeRange} message TimeRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TimeRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startSecondOfDay != null && Object.hasOwnProperty.call(message, "startSecondOfDay"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.startSecondOfDay);
                        if (message.endSecondOfDay != null && Object.hasOwnProperty.call(message, "endSecondOfDay"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.endSecondOfDay);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TimeRange message, length delimited. Does not implicitly {@link palexy.streaming.v1.TimeRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.TimeRange
                     * @static
                     * @param {palexy.streaming.v1.ITimeRange} message TimeRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TimeRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TimeRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.TimeRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.TimeRange} TimeRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TimeRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.TimeRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startSecondOfDay = reader.int32();
                                break;
                            case 2:
                                message.endSecondOfDay = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TimeRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.TimeRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.TimeRange} TimeRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TimeRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TimeRange message.
                     * @function verify
                     * @memberof palexy.streaming.v1.TimeRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TimeRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.startSecondOfDay != null && message.hasOwnProperty("startSecondOfDay"))
                            if (!$util.isInteger(message.startSecondOfDay))
                                return "startSecondOfDay: integer expected";
                        if (message.endSecondOfDay != null && message.hasOwnProperty("endSecondOfDay"))
                            if (!$util.isInteger(message.endSecondOfDay))
                                return "endSecondOfDay: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a TimeRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.TimeRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.TimeRange} TimeRange
                     */
                    TimeRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.TimeRange)
                            return object;
                        var message = new $root.palexy.streaming.v1.TimeRange();
                        if (object.startSecondOfDay != null)
                            message.startSecondOfDay = object.startSecondOfDay | 0;
                        if (object.endSecondOfDay != null)
                            message.endSecondOfDay = object.endSecondOfDay | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TimeRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.TimeRange
                     * @static
                     * @param {palexy.streaming.v1.TimeRange} message TimeRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TimeRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startSecondOfDay = 0;
                            object.endSecondOfDay = 0;
                        }
                        if (message.startSecondOfDay != null && message.hasOwnProperty("startSecondOfDay"))
                            object.startSecondOfDay = message.startSecondOfDay;
                        if (message.endSecondOfDay != null && message.hasOwnProperty("endSecondOfDay"))
                            object.endSecondOfDay = message.endSecondOfDay;
                        return object;
                    };
    
                    /**
                     * Converts this TimeRange to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.TimeRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TimeRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TimeRange;
                })();
    
                v1.TimeRangeList = (function() {
    
                    /**
                     * Properties of a TimeRangeList.
                     * @memberof palexy.streaming.v1
                     * @interface ITimeRangeList
                     * @property {Array.<palexy.streaming.v1.ITimeRange>|null} [timeRanges] TimeRangeList timeRanges
                     */
    
                    /**
                     * Constructs a new TimeRangeList.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a TimeRangeList.
                     * @implements ITimeRangeList
                     * @constructor
                     * @param {palexy.streaming.v1.ITimeRangeList=} [properties] Properties to set
                     */
                    function TimeRangeList(properties) {
                        this.timeRanges = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TimeRangeList timeRanges.
                     * @member {Array.<palexy.streaming.v1.ITimeRange>} timeRanges
                     * @memberof palexy.streaming.v1.TimeRangeList
                     * @instance
                     */
                    TimeRangeList.prototype.timeRanges = $util.emptyArray;
    
                    /**
                     * Creates a new TimeRangeList instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.TimeRangeList
                     * @static
                     * @param {palexy.streaming.v1.ITimeRangeList=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.TimeRangeList} TimeRangeList instance
                     */
                    TimeRangeList.create = function create(properties) {
                        return new TimeRangeList(properties);
                    };
    
                    /**
                     * Encodes the specified TimeRangeList message. Does not implicitly {@link palexy.streaming.v1.TimeRangeList.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.TimeRangeList
                     * @static
                     * @param {palexy.streaming.v1.ITimeRangeList} message TimeRangeList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TimeRangeList.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.timeRanges != null && message.timeRanges.length)
                            for (var i = 0; i < message.timeRanges.length; ++i)
                                $root.palexy.streaming.v1.TimeRange.encode(message.timeRanges[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TimeRangeList message, length delimited. Does not implicitly {@link palexy.streaming.v1.TimeRangeList.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.TimeRangeList
                     * @static
                     * @param {palexy.streaming.v1.ITimeRangeList} message TimeRangeList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TimeRangeList.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TimeRangeList message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.TimeRangeList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.TimeRangeList} TimeRangeList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TimeRangeList.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.TimeRangeList();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.timeRanges && message.timeRanges.length))
                                    message.timeRanges = [];
                                message.timeRanges.push($root.palexy.streaming.v1.TimeRange.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TimeRangeList message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.TimeRangeList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.TimeRangeList} TimeRangeList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TimeRangeList.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TimeRangeList message.
                     * @function verify
                     * @memberof palexy.streaming.v1.TimeRangeList
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TimeRangeList.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.timeRanges != null && message.hasOwnProperty("timeRanges")) {
                            if (!Array.isArray(message.timeRanges))
                                return "timeRanges: array expected";
                            for (var i = 0; i < message.timeRanges.length; ++i) {
                                var error = $root.palexy.streaming.v1.TimeRange.verify(message.timeRanges[i]);
                                if (error)
                                    return "timeRanges." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a TimeRangeList message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.TimeRangeList
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.TimeRangeList} TimeRangeList
                     */
                    TimeRangeList.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.TimeRangeList)
                            return object;
                        var message = new $root.palexy.streaming.v1.TimeRangeList();
                        if (object.timeRanges) {
                            if (!Array.isArray(object.timeRanges))
                                throw TypeError(".palexy.streaming.v1.TimeRangeList.timeRanges: array expected");
                            message.timeRanges = [];
                            for (var i = 0; i < object.timeRanges.length; ++i) {
                                if (typeof object.timeRanges[i] !== "object")
                                    throw TypeError(".palexy.streaming.v1.TimeRangeList.timeRanges: object expected");
                                message.timeRanges[i] = $root.palexy.streaming.v1.TimeRange.fromObject(object.timeRanges[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TimeRangeList message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.TimeRangeList
                     * @static
                     * @param {palexy.streaming.v1.TimeRangeList} message TimeRangeList
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TimeRangeList.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.timeRanges = [];
                        if (message.timeRanges && message.timeRanges.length) {
                            object.timeRanges = [];
                            for (var j = 0; j < message.timeRanges.length; ++j)
                                object.timeRanges[j] = $root.palexy.streaming.v1.TimeRange.toObject(message.timeRanges[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this TimeRangeList to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.TimeRangeList
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TimeRangeList.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return TimeRangeList;
                })();
    
                v1.DateFilter = (function() {
    
                    /**
                     * Properties of a DateFilter.
                     * @memberof palexy.streaming.v1
                     * @interface IDateFilter
                     * @property {number|null} [daysAgo] DateFilter daysAgo
                     * @property {string|null} [dateId] DateFilter dateId
                     */
    
                    /**
                     * Constructs a new DateFilter.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a DateFilter.
                     * @implements IDateFilter
                     * @constructor
                     * @param {palexy.streaming.v1.IDateFilter=} [properties] Properties to set
                     */
                    function DateFilter(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DateFilter daysAgo.
                     * @member {number|null|undefined} daysAgo
                     * @memberof palexy.streaming.v1.DateFilter
                     * @instance
                     */
                    DateFilter.prototype.daysAgo = null;
    
                    /**
                     * DateFilter dateId.
                     * @member {string|null|undefined} dateId
                     * @memberof palexy.streaming.v1.DateFilter
                     * @instance
                     */
                    DateFilter.prototype.dateId = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * DateFilter filterOneof.
                     * @member {"daysAgo"|"dateId"|undefined} filterOneof
                     * @memberof palexy.streaming.v1.DateFilter
                     * @instance
                     */
                    Object.defineProperty(DateFilter.prototype, "filterOneof", {
                        get: $util.oneOfGetter($oneOfFields = ["daysAgo", "dateId"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new DateFilter instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.DateFilter
                     * @static
                     * @param {palexy.streaming.v1.IDateFilter=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.DateFilter} DateFilter instance
                     */
                    DateFilter.create = function create(properties) {
                        return new DateFilter(properties);
                    };
    
                    /**
                     * Encodes the specified DateFilter message. Does not implicitly {@link palexy.streaming.v1.DateFilter.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.DateFilter
                     * @static
                     * @param {palexy.streaming.v1.IDateFilter} message DateFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DateFilter.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.daysAgo != null && Object.hasOwnProperty.call(message, "daysAgo"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.daysAgo);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DateFilter message, length delimited. Does not implicitly {@link palexy.streaming.v1.DateFilter.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.DateFilter
                     * @static
                     * @param {palexy.streaming.v1.IDateFilter} message DateFilter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DateFilter.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DateFilter message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.DateFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.DateFilter} DateFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DateFilter.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.DateFilter();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.daysAgo = reader.int32();
                                break;
                            case 2:
                                message.dateId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DateFilter message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.DateFilter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.DateFilter} DateFilter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DateFilter.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DateFilter message.
                     * @function verify
                     * @memberof palexy.streaming.v1.DateFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DateFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.daysAgo != null && message.hasOwnProperty("daysAgo")) {
                            properties.filterOneof = 1;
                            if (!$util.isInteger(message.daysAgo))
                                return "daysAgo: integer expected";
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId")) {
                            if (properties.filterOneof === 1)
                                return "filterOneof: multiple values";
                            properties.filterOneof = 1;
                            if (!$util.isString(message.dateId))
                                return "dateId: string expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DateFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.DateFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.DateFilter} DateFilter
                     */
                    DateFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.DateFilter)
                            return object;
                        var message = new $root.palexy.streaming.v1.DateFilter();
                        if (object.daysAgo != null)
                            message.daysAgo = object.daysAgo | 0;
                        if (object.dateId != null)
                            message.dateId = String(object.dateId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DateFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.DateFilter
                     * @static
                     * @param {palexy.streaming.v1.DateFilter} message DateFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DateFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.daysAgo != null && message.hasOwnProperty("daysAgo")) {
                            object.daysAgo = message.daysAgo;
                            if (options.oneofs)
                                object.filterOneof = "daysAgo";
                        }
                        if (message.dateId != null && message.hasOwnProperty("dateId")) {
                            object.dateId = message.dateId;
                            if (options.oneofs)
                                object.filterOneof = "dateId";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this DateFilter to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.DateFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DateFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DateFilter;
                })();
    
                v1.IdList = (function() {
    
                    /**
                     * Properties of an IdList.
                     * @memberof palexy.streaming.v1
                     * @interface IIdList
                     * @property {Array.<number>|null} [ids] IdList ids
                     */
    
                    /**
                     * Constructs a new IdList.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an IdList.
                     * @implements IIdList
                     * @constructor
                     * @param {palexy.streaming.v1.IIdList=} [properties] Properties to set
                     */
                    function IdList(properties) {
                        this.ids = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * IdList ids.
                     * @member {Array.<number>} ids
                     * @memberof palexy.streaming.v1.IdList
                     * @instance
                     */
                    IdList.prototype.ids = $util.emptyArray;
    
                    /**
                     * Creates a new IdList instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.IdList
                     * @static
                     * @param {palexy.streaming.v1.IIdList=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.IdList} IdList instance
                     */
                    IdList.create = function create(properties) {
                        return new IdList(properties);
                    };
    
                    /**
                     * Encodes the specified IdList message. Does not implicitly {@link palexy.streaming.v1.IdList.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.IdList
                     * @static
                     * @param {palexy.streaming.v1.IIdList} message IdList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IdList.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.ids != null && message.ids.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.ids.length; ++i)
                                writer.int64(message.ids[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified IdList message, length delimited. Does not implicitly {@link palexy.streaming.v1.IdList.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.IdList
                     * @static
                     * @param {palexy.streaming.v1.IIdList} message IdList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IdList.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an IdList message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.IdList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.IdList} IdList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IdList.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.IdList();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.ids && message.ids.length))
                                    message.ids = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.ids.push(reader.int64());
                                } else
                                    message.ids.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an IdList message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.IdList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.IdList} IdList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IdList.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an IdList message.
                     * @function verify
                     * @memberof palexy.streaming.v1.IdList
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    IdList.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.ids != null && message.hasOwnProperty("ids")) {
                            if (!Array.isArray(message.ids))
                                return "ids: array expected";
                            for (var i = 0; i < message.ids.length; ++i)
                                if (!$util.isInteger(message.ids[i]) && !(message.ids[i] && $util.isInteger(message.ids[i].low) && $util.isInteger(message.ids[i].high)))
                                    return "ids: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates an IdList message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.IdList
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.IdList} IdList
                     */
                    IdList.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.IdList)
                            return object;
                        var message = new $root.palexy.streaming.v1.IdList();
                        if (object.ids) {
                            if (!Array.isArray(object.ids))
                                throw TypeError(".palexy.streaming.v1.IdList.ids: array expected");
                            message.ids = [];
                            for (var i = 0; i < object.ids.length; ++i)
                                if ($util.Long)
                                    (message.ids[i] = $util.Long.fromValue(object.ids[i])).unsigned = false;
                                else if (typeof object.ids[i] === "string")
                                    message.ids[i] = parseInt(object.ids[i], 10);
                                else if (typeof object.ids[i] === "number")
                                    message.ids[i] = object.ids[i];
                                else if (typeof object.ids[i] === "object")
                                    message.ids[i] = new $util.LongBits(object.ids[i].low >>> 0, object.ids[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an IdList message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.IdList
                     * @static
                     * @param {palexy.streaming.v1.IdList} message IdList
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IdList.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.ids = [];
                        if (message.ids && message.ids.length) {
                            object.ids = [];
                            for (var j = 0; j < message.ids.length; ++j)
                                if (typeof message.ids[j] === "number")
                                    object.ids[j] = options.longs === String ? String(message.ids[j]) : message.ids[j];
                                else
                                    object.ids[j] = options.longs === String ? $util.Long.prototype.toString.call(message.ids[j]) : options.longs === Number ? new $util.LongBits(message.ids[j].low >>> 0, message.ids[j].high >>> 0).toNumber() : message.ids[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this IdList to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.IdList
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IdList.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return IdList;
                })();
    
                v1.RawICARecord = (function() {
    
                    /**
                     * Properties of a RawICARecord.
                     * @memberof palexy.streaming.v1
                     * @interface IRawICARecord
                     * @property {string|null} [cameraId] RawICARecord cameraId
                     * @property {number|null} [coordinateCameraX] RawICARecord coordinateCameraX
                     * @property {number|null} [coordinateCameraY] RawICARecord coordinateCameraY
                     * @property {string|null} [recordingDateTime] RawICARecord recordingDateTime
                     * @property {number|null} [coordinateFloorX] RawICARecord coordinateFloorX
                     * @property {number|null} [coordinateFloorY] RawICARecord coordinateFloorY
                     * @property {string|null} [personId] RawICARecord personId
                     * @property {string|null} [actionType] RawICARecord actionType
                     * @property {string|null} [tileId] RawICARecord tileId
                     * @property {boolean|null} [isStaff] RawICARecord isStaff
                     * @property {string|null} [gender] RawICARecord gender
                     * @property {number|null} [ageRange] RawICARecord ageRange
                     * @property {number|null} [storeId] RawICARecord storeId
                     * @property {number|null} [areaId] RawICARecord areaId
                     * @property {number|null} [duration] RawICARecord duration
                     * @property {string|null} [customPersonType] RawICARecord customPersonType
                     * @property {number|null} [height] RawICARecord height
                     * @property {string|null} [visitorGroupId] RawICARecord visitorGroupId
                     * @property {palexy.streaming.v1.RawICARecord.VisitType|null} [visitType] RawICARecord visitType
                     * @property {palexy.streaming.v1.IVisitorDirection|null} [visitorDirection] RawICARecord visitorDirection
                     * @property {number|null} [validBbProb] RawICARecord validBbProb
                     * @property {string|null} [checkoutGroupId] RawICARecord checkoutGroupId
                     * @property {palexy.streaming.v1.IBoundingBoxCoordinates|null} [boundingBoxCoordinates] RawICARecord boundingBoxCoordinates
                     * @property {string|null} [imagePath] RawICARecord imagePath
                     * @property {Array.<number>|null} [featureVector] RawICARecord featureVector
                     * @property {Array.<number>|null} [ageRangeData] RawICARecord ageRangeData
                     * @property {string|null} [recordUuid] RawICARecord recordUuid
                     */
    
                    /**
                     * Constructs a new RawICARecord.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a RawICARecord.
                     * @implements IRawICARecord
                     * @constructor
                     * @param {palexy.streaming.v1.IRawICARecord=} [properties] Properties to set
                     */
                    function RawICARecord(properties) {
                        this.featureVector = [];
                        this.ageRangeData = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RawICARecord cameraId.
                     * @member {string} cameraId
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.cameraId = "";
    
                    /**
                     * RawICARecord coordinateCameraX.
                     * @member {number} coordinateCameraX
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.coordinateCameraX = 0;
    
                    /**
                     * RawICARecord coordinateCameraY.
                     * @member {number} coordinateCameraY
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.coordinateCameraY = 0;
    
                    /**
                     * RawICARecord recordingDateTime.
                     * @member {string} recordingDateTime
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.recordingDateTime = "";
    
                    /**
                     * RawICARecord coordinateFloorX.
                     * @member {number} coordinateFloorX
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.coordinateFloorX = 0;
    
                    /**
                     * RawICARecord coordinateFloorY.
                     * @member {number} coordinateFloorY
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.coordinateFloorY = 0;
    
                    /**
                     * RawICARecord personId.
                     * @member {string} personId
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.personId = "";
    
                    /**
                     * RawICARecord actionType.
                     * @member {string} actionType
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.actionType = "";
    
                    /**
                     * RawICARecord tileId.
                     * @member {string} tileId
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.tileId = "";
    
                    /**
                     * RawICARecord isStaff.
                     * @member {boolean} isStaff
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.isStaff = false;
    
                    /**
                     * RawICARecord gender.
                     * @member {string} gender
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.gender = "";
    
                    /**
                     * RawICARecord ageRange.
                     * @member {number} ageRange
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.ageRange = 0;
    
                    /**
                     * RawICARecord storeId.
                     * @member {number} storeId
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICARecord areaId.
                     * @member {number} areaId
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.areaId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RawICARecord duration.
                     * @member {number} duration
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.duration = 0;
    
                    /**
                     * RawICARecord customPersonType.
                     * @member {string} customPersonType
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.customPersonType = "";
    
                    /**
                     * RawICARecord height.
                     * @member {number} height
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.height = 0;
    
                    /**
                     * RawICARecord visitorGroupId.
                     * @member {string} visitorGroupId
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.visitorGroupId = "";
    
                    /**
                     * RawICARecord visitType.
                     * @member {palexy.streaming.v1.RawICARecord.VisitType} visitType
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.visitType = 0;
    
                    /**
                     * RawICARecord visitorDirection.
                     * @member {palexy.streaming.v1.IVisitorDirection|null|undefined} visitorDirection
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.visitorDirection = null;
    
                    /**
                     * RawICARecord validBbProb.
                     * @member {number} validBbProb
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.validBbProb = 0;
    
                    /**
                     * RawICARecord checkoutGroupId.
                     * @member {string} checkoutGroupId
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.checkoutGroupId = "";
    
                    /**
                     * RawICARecord boundingBoxCoordinates.
                     * @member {palexy.streaming.v1.IBoundingBoxCoordinates|null|undefined} boundingBoxCoordinates
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.boundingBoxCoordinates = null;
    
                    /**
                     * RawICARecord imagePath.
                     * @member {string} imagePath
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.imagePath = "";
    
                    /**
                     * RawICARecord featureVector.
                     * @member {Array.<number>} featureVector
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.featureVector = $util.emptyArray;
    
                    /**
                     * RawICARecord ageRangeData.
                     * @member {Array.<number>} ageRangeData
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.ageRangeData = $util.emptyArray;
    
                    /**
                     * RawICARecord recordUuid.
                     * @member {string} recordUuid
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     */
                    RawICARecord.prototype.recordUuid = "";
    
                    /**
                     * Creates a new RawICARecord instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @static
                     * @param {palexy.streaming.v1.IRawICARecord=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.RawICARecord} RawICARecord instance
                     */
                    RawICARecord.create = function create(properties) {
                        return new RawICARecord(properties);
                    };
    
                    /**
                     * Encodes the specified RawICARecord message. Does not implicitly {@link palexy.streaming.v1.RawICARecord.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @static
                     * @param {palexy.streaming.v1.IRawICARecord} message RawICARecord message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RawICARecord.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cameraId != null && Object.hasOwnProperty.call(message, "cameraId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cameraId);
                        if (message.coordinateCameraX != null && Object.hasOwnProperty.call(message, "coordinateCameraX"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.coordinateCameraX);
                        if (message.coordinateCameraY != null && Object.hasOwnProperty.call(message, "coordinateCameraY"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.coordinateCameraY);
                        if (message.recordingDateTime != null && Object.hasOwnProperty.call(message, "recordingDateTime"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.recordingDateTime);
                        if (message.coordinateFloorX != null && Object.hasOwnProperty.call(message, "coordinateFloorX"))
                            writer.uint32(/* id 5, wireType 5 =*/45).float(message.coordinateFloorX);
                        if (message.coordinateFloorY != null && Object.hasOwnProperty.call(message, "coordinateFloorY"))
                            writer.uint32(/* id 6, wireType 5 =*/53).float(message.coordinateFloorY);
                        if (message.personId != null && Object.hasOwnProperty.call(message, "personId"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.personId);
                        if (message.actionType != null && Object.hasOwnProperty.call(message, "actionType"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.actionType);
                        if (message.tileId != null && Object.hasOwnProperty.call(message, "tileId"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.tileId);
                        if (message.isStaff != null && Object.hasOwnProperty.call(message, "isStaff"))
                            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isStaff);
                        if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.gender);
                        if (message.ageRange != null && Object.hasOwnProperty.call(message, "ageRange"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.ageRange);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int64(message.storeId);
                        if (message.areaId != null && Object.hasOwnProperty.call(message, "areaId"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int64(message.areaId);
                        if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                            writer.uint32(/* id 15, wireType 5 =*/125).float(message.duration);
                        if (message.boundingBoxCoordinates != null && Object.hasOwnProperty.call(message, "boundingBoxCoordinates"))
                            $root.palexy.streaming.v1.BoundingBoxCoordinates.encode(message.boundingBoxCoordinates, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.imagePath != null && Object.hasOwnProperty.call(message, "imagePath"))
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.imagePath);
                        if (message.featureVector != null && message.featureVector.length) {
                            writer.uint32(/* id 18, wireType 2 =*/146).fork();
                            for (var i = 0; i < message.featureVector.length; ++i)
                                writer.float(message.featureVector[i]);
                            writer.ldelim();
                        }
                        if (message.customPersonType != null && Object.hasOwnProperty.call(message, "customPersonType"))
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.customPersonType);
                        if (message.ageRangeData != null && message.ageRangeData.length) {
                            writer.uint32(/* id 20, wireType 2 =*/162).fork();
                            for (var i = 0; i < message.ageRangeData.length; ++i)
                                writer.float(message.ageRangeData[i]);
                            writer.ldelim();
                        }
                        if (message.recordUuid != null && Object.hasOwnProperty.call(message, "recordUuid"))
                            writer.uint32(/* id 21, wireType 2 =*/170).string(message.recordUuid);
                        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                            writer.uint32(/* id 22, wireType 0 =*/176).int32(message.height);
                        if (message.visitorGroupId != null && Object.hasOwnProperty.call(message, "visitorGroupId"))
                            writer.uint32(/* id 23, wireType 2 =*/186).string(message.visitorGroupId);
                        if (message.visitType != null && Object.hasOwnProperty.call(message, "visitType"))
                            writer.uint32(/* id 24, wireType 0 =*/192).int32(message.visitType);
                        if (message.visitorDirection != null && Object.hasOwnProperty.call(message, "visitorDirection"))
                            $root.palexy.streaming.v1.VisitorDirection.encode(message.visitorDirection, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                        if (message.validBbProb != null && Object.hasOwnProperty.call(message, "validBbProb"))
                            writer.uint32(/* id 26, wireType 5 =*/213).float(message.validBbProb);
                        if (message.checkoutGroupId != null && Object.hasOwnProperty.call(message, "checkoutGroupId"))
                            writer.uint32(/* id 27, wireType 2 =*/218).string(message.checkoutGroupId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RawICARecord message, length delimited. Does not implicitly {@link palexy.streaming.v1.RawICARecord.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @static
                     * @param {palexy.streaming.v1.IRawICARecord} message RawICARecord message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RawICARecord.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RawICARecord message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.RawICARecord} RawICARecord
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RawICARecord.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.RawICARecord();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cameraId = reader.string();
                                break;
                            case 2:
                                message.coordinateCameraX = reader.int32();
                                break;
                            case 3:
                                message.coordinateCameraY = reader.int32();
                                break;
                            case 4:
                                message.recordingDateTime = reader.string();
                                break;
                            case 5:
                                message.coordinateFloorX = reader.float();
                                break;
                            case 6:
                                message.coordinateFloorY = reader.float();
                                break;
                            case 7:
                                message.personId = reader.string();
                                break;
                            case 8:
                                message.actionType = reader.string();
                                break;
                            case 9:
                                message.tileId = reader.string();
                                break;
                            case 10:
                                message.isStaff = reader.bool();
                                break;
                            case 11:
                                message.gender = reader.string();
                                break;
                            case 12:
                                message.ageRange = reader.int32();
                                break;
                            case 13:
                                message.storeId = reader.int64();
                                break;
                            case 14:
                                message.areaId = reader.int64();
                                break;
                            case 15:
                                message.duration = reader.float();
                                break;
                            case 19:
                                message.customPersonType = reader.string();
                                break;
                            case 22:
                                message.height = reader.int32();
                                break;
                            case 23:
                                message.visitorGroupId = reader.string();
                                break;
                            case 24:
                                message.visitType = reader.int32();
                                break;
                            case 25:
                                message.visitorDirection = $root.palexy.streaming.v1.VisitorDirection.decode(reader, reader.uint32());
                                break;
                            case 26:
                                message.validBbProb = reader.float();
                                break;
                            case 27:
                                message.checkoutGroupId = reader.string();
                                break;
                            case 16:
                                message.boundingBoxCoordinates = $root.palexy.streaming.v1.BoundingBoxCoordinates.decode(reader, reader.uint32());
                                break;
                            case 17:
                                message.imagePath = reader.string();
                                break;
                            case 18:
                                if (!(message.featureVector && message.featureVector.length))
                                    message.featureVector = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.featureVector.push(reader.float());
                                } else
                                    message.featureVector.push(reader.float());
                                break;
                            case 20:
                                if (!(message.ageRangeData && message.ageRangeData.length))
                                    message.ageRangeData = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.ageRangeData.push(reader.float());
                                } else
                                    message.ageRangeData.push(reader.float());
                                break;
                            case 21:
                                message.recordUuid = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RawICARecord message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.RawICARecord} RawICARecord
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RawICARecord.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RawICARecord message.
                     * @function verify
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RawICARecord.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            if (!$util.isString(message.cameraId))
                                return "cameraId: string expected";
                        if (message.coordinateCameraX != null && message.hasOwnProperty("coordinateCameraX"))
                            if (!$util.isInteger(message.coordinateCameraX))
                                return "coordinateCameraX: integer expected";
                        if (message.coordinateCameraY != null && message.hasOwnProperty("coordinateCameraY"))
                            if (!$util.isInteger(message.coordinateCameraY))
                                return "coordinateCameraY: integer expected";
                        if (message.recordingDateTime != null && message.hasOwnProperty("recordingDateTime"))
                            if (!$util.isString(message.recordingDateTime))
                                return "recordingDateTime: string expected";
                        if (message.coordinateFloorX != null && message.hasOwnProperty("coordinateFloorX"))
                            if (typeof message.coordinateFloorX !== "number")
                                return "coordinateFloorX: number expected";
                        if (message.coordinateFloorY != null && message.hasOwnProperty("coordinateFloorY"))
                            if (typeof message.coordinateFloorY !== "number")
                                return "coordinateFloorY: number expected";
                        if (message.personId != null && message.hasOwnProperty("personId"))
                            if (!$util.isString(message.personId))
                                return "personId: string expected";
                        if (message.actionType != null && message.hasOwnProperty("actionType"))
                            if (!$util.isString(message.actionType))
                                return "actionType: string expected";
                        if (message.tileId != null && message.hasOwnProperty("tileId"))
                            if (!$util.isString(message.tileId))
                                return "tileId: string expected";
                        if (message.isStaff != null && message.hasOwnProperty("isStaff"))
                            if (typeof message.isStaff !== "boolean")
                                return "isStaff: boolean expected";
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            if (!$util.isString(message.gender))
                                return "gender: string expected";
                        if (message.ageRange != null && message.hasOwnProperty("ageRange"))
                            if (!$util.isInteger(message.ageRange))
                                return "ageRange: integer expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.areaId != null && message.hasOwnProperty("areaId"))
                            if (!$util.isInteger(message.areaId) && !(message.areaId && $util.isInteger(message.areaId.low) && $util.isInteger(message.areaId.high)))
                                return "areaId: integer|Long expected";
                        if (message.duration != null && message.hasOwnProperty("duration"))
                            if (typeof message.duration !== "number")
                                return "duration: number expected";
                        if (message.customPersonType != null && message.hasOwnProperty("customPersonType"))
                            if (!$util.isString(message.customPersonType))
                                return "customPersonType: string expected";
                        if (message.height != null && message.hasOwnProperty("height"))
                            if (!$util.isInteger(message.height))
                                return "height: integer expected";
                        if (message.visitorGroupId != null && message.hasOwnProperty("visitorGroupId"))
                            if (!$util.isString(message.visitorGroupId))
                                return "visitorGroupId: string expected";
                        if (message.visitType != null && message.hasOwnProperty("visitType"))
                            switch (message.visitType) {
                            default:
                                return "visitType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.visitorDirection != null && message.hasOwnProperty("visitorDirection")) {
                            var error = $root.palexy.streaming.v1.VisitorDirection.verify(message.visitorDirection);
                            if (error)
                                return "visitorDirection." + error;
                        }
                        if (message.validBbProb != null && message.hasOwnProperty("validBbProb"))
                            if (typeof message.validBbProb !== "number")
                                return "validBbProb: number expected";
                        if (message.checkoutGroupId != null && message.hasOwnProperty("checkoutGroupId"))
                            if (!$util.isString(message.checkoutGroupId))
                                return "checkoutGroupId: string expected";
                        if (message.boundingBoxCoordinates != null && message.hasOwnProperty("boundingBoxCoordinates")) {
                            var error = $root.palexy.streaming.v1.BoundingBoxCoordinates.verify(message.boundingBoxCoordinates);
                            if (error)
                                return "boundingBoxCoordinates." + error;
                        }
                        if (message.imagePath != null && message.hasOwnProperty("imagePath"))
                            if (!$util.isString(message.imagePath))
                                return "imagePath: string expected";
                        if (message.featureVector != null && message.hasOwnProperty("featureVector")) {
                            if (!Array.isArray(message.featureVector))
                                return "featureVector: array expected";
                            for (var i = 0; i < message.featureVector.length; ++i)
                                if (typeof message.featureVector[i] !== "number")
                                    return "featureVector: number[] expected";
                        }
                        if (message.ageRangeData != null && message.hasOwnProperty("ageRangeData")) {
                            if (!Array.isArray(message.ageRangeData))
                                return "ageRangeData: array expected";
                            for (var i = 0; i < message.ageRangeData.length; ++i)
                                if (typeof message.ageRangeData[i] !== "number")
                                    return "ageRangeData: number[] expected";
                        }
                        if (message.recordUuid != null && message.hasOwnProperty("recordUuid"))
                            if (!$util.isString(message.recordUuid))
                                return "recordUuid: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a RawICARecord message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.RawICARecord} RawICARecord
                     */
                    RawICARecord.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.RawICARecord)
                            return object;
                        var message = new $root.palexy.streaming.v1.RawICARecord();
                        if (object.cameraId != null)
                            message.cameraId = String(object.cameraId);
                        if (object.coordinateCameraX != null)
                            message.coordinateCameraX = object.coordinateCameraX | 0;
                        if (object.coordinateCameraY != null)
                            message.coordinateCameraY = object.coordinateCameraY | 0;
                        if (object.recordingDateTime != null)
                            message.recordingDateTime = String(object.recordingDateTime);
                        if (object.coordinateFloorX != null)
                            message.coordinateFloorX = Number(object.coordinateFloorX);
                        if (object.coordinateFloorY != null)
                            message.coordinateFloorY = Number(object.coordinateFloorY);
                        if (object.personId != null)
                            message.personId = String(object.personId);
                        if (object.actionType != null)
                            message.actionType = String(object.actionType);
                        if (object.tileId != null)
                            message.tileId = String(object.tileId);
                        if (object.isStaff != null)
                            message.isStaff = Boolean(object.isStaff);
                        if (object.gender != null)
                            message.gender = String(object.gender);
                        if (object.ageRange != null)
                            message.ageRange = object.ageRange | 0;
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.areaId != null)
                            if ($util.Long)
                                (message.areaId = $util.Long.fromValue(object.areaId)).unsigned = false;
                            else if (typeof object.areaId === "string")
                                message.areaId = parseInt(object.areaId, 10);
                            else if (typeof object.areaId === "number")
                                message.areaId = object.areaId;
                            else if (typeof object.areaId === "object")
                                message.areaId = new $util.LongBits(object.areaId.low >>> 0, object.areaId.high >>> 0).toNumber();
                        if (object.duration != null)
                            message.duration = Number(object.duration);
                        if (object.customPersonType != null)
                            message.customPersonType = String(object.customPersonType);
                        if (object.height != null)
                            message.height = object.height | 0;
                        if (object.visitorGroupId != null)
                            message.visitorGroupId = String(object.visitorGroupId);
                        switch (object.visitType) {
                        case "UNKNOWN":
                        case 0:
                            message.visitType = 0;
                            break;
                        case "ENTRANCE":
                        case 1:
                            message.visitType = 1;
                            break;
                        case "QUEUE_TIME":
                        case 2:
                            message.visitType = 2;
                            break;
                        case "PRODUCT_SECTION":
                        case 3:
                            message.visitType = 3;
                            break;
                        }
                        if (object.visitorDirection != null) {
                            if (typeof object.visitorDirection !== "object")
                                throw TypeError(".palexy.streaming.v1.RawICARecord.visitorDirection: object expected");
                            message.visitorDirection = $root.palexy.streaming.v1.VisitorDirection.fromObject(object.visitorDirection);
                        }
                        if (object.validBbProb != null)
                            message.validBbProb = Number(object.validBbProb);
                        if (object.checkoutGroupId != null)
                            message.checkoutGroupId = String(object.checkoutGroupId);
                        if (object.boundingBoxCoordinates != null) {
                            if (typeof object.boundingBoxCoordinates !== "object")
                                throw TypeError(".palexy.streaming.v1.RawICARecord.boundingBoxCoordinates: object expected");
                            message.boundingBoxCoordinates = $root.palexy.streaming.v1.BoundingBoxCoordinates.fromObject(object.boundingBoxCoordinates);
                        }
                        if (object.imagePath != null)
                            message.imagePath = String(object.imagePath);
                        if (object.featureVector) {
                            if (!Array.isArray(object.featureVector))
                                throw TypeError(".palexy.streaming.v1.RawICARecord.featureVector: array expected");
                            message.featureVector = [];
                            for (var i = 0; i < object.featureVector.length; ++i)
                                message.featureVector[i] = Number(object.featureVector[i]);
                        }
                        if (object.ageRangeData) {
                            if (!Array.isArray(object.ageRangeData))
                                throw TypeError(".palexy.streaming.v1.RawICARecord.ageRangeData: array expected");
                            message.ageRangeData = [];
                            for (var i = 0; i < object.ageRangeData.length; ++i)
                                message.ageRangeData[i] = Number(object.ageRangeData[i]);
                        }
                        if (object.recordUuid != null)
                            message.recordUuid = String(object.recordUuid);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RawICARecord message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @static
                     * @param {palexy.streaming.v1.RawICARecord} message RawICARecord
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RawICARecord.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.featureVector = [];
                            object.ageRangeData = [];
                        }
                        if (options.defaults) {
                            object.cameraId = "";
                            object.coordinateCameraX = 0;
                            object.coordinateCameraY = 0;
                            object.recordingDateTime = "";
                            object.coordinateFloorX = 0;
                            object.coordinateFloorY = 0;
                            object.personId = "";
                            object.actionType = "";
                            object.tileId = "";
                            object.isStaff = false;
                            object.gender = "";
                            object.ageRange = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.areaId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.areaId = options.longs === String ? "0" : 0;
                            object.duration = 0;
                            object.boundingBoxCoordinates = null;
                            object.imagePath = "";
                            object.customPersonType = "";
                            object.recordUuid = "";
                            object.height = 0;
                            object.visitorGroupId = "";
                            object.visitType = options.enums === String ? "UNKNOWN" : 0;
                            object.visitorDirection = null;
                            object.validBbProb = 0;
                            object.checkoutGroupId = "";
                        }
                        if (message.cameraId != null && message.hasOwnProperty("cameraId"))
                            object.cameraId = message.cameraId;
                        if (message.coordinateCameraX != null && message.hasOwnProperty("coordinateCameraX"))
                            object.coordinateCameraX = message.coordinateCameraX;
                        if (message.coordinateCameraY != null && message.hasOwnProperty("coordinateCameraY"))
                            object.coordinateCameraY = message.coordinateCameraY;
                        if (message.recordingDateTime != null && message.hasOwnProperty("recordingDateTime"))
                            object.recordingDateTime = message.recordingDateTime;
                        if (message.coordinateFloorX != null && message.hasOwnProperty("coordinateFloorX"))
                            object.coordinateFloorX = options.json && !isFinite(message.coordinateFloorX) ? String(message.coordinateFloorX) : message.coordinateFloorX;
                        if (message.coordinateFloorY != null && message.hasOwnProperty("coordinateFloorY"))
                            object.coordinateFloorY = options.json && !isFinite(message.coordinateFloorY) ? String(message.coordinateFloorY) : message.coordinateFloorY;
                        if (message.personId != null && message.hasOwnProperty("personId"))
                            object.personId = message.personId;
                        if (message.actionType != null && message.hasOwnProperty("actionType"))
                            object.actionType = message.actionType;
                        if (message.tileId != null && message.hasOwnProperty("tileId"))
                            object.tileId = message.tileId;
                        if (message.isStaff != null && message.hasOwnProperty("isStaff"))
                            object.isStaff = message.isStaff;
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            object.gender = message.gender;
                        if (message.ageRange != null && message.hasOwnProperty("ageRange"))
                            object.ageRange = message.ageRange;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.areaId != null && message.hasOwnProperty("areaId"))
                            if (typeof message.areaId === "number")
                                object.areaId = options.longs === String ? String(message.areaId) : message.areaId;
                            else
                                object.areaId = options.longs === String ? $util.Long.prototype.toString.call(message.areaId) : options.longs === Number ? new $util.LongBits(message.areaId.low >>> 0, message.areaId.high >>> 0).toNumber() : message.areaId;
                        if (message.duration != null && message.hasOwnProperty("duration"))
                            object.duration = options.json && !isFinite(message.duration) ? String(message.duration) : message.duration;
                        if (message.boundingBoxCoordinates != null && message.hasOwnProperty("boundingBoxCoordinates"))
                            object.boundingBoxCoordinates = $root.palexy.streaming.v1.BoundingBoxCoordinates.toObject(message.boundingBoxCoordinates, options);
                        if (message.imagePath != null && message.hasOwnProperty("imagePath"))
                            object.imagePath = message.imagePath;
                        if (message.featureVector && message.featureVector.length) {
                            object.featureVector = [];
                            for (var j = 0; j < message.featureVector.length; ++j)
                                object.featureVector[j] = options.json && !isFinite(message.featureVector[j]) ? String(message.featureVector[j]) : message.featureVector[j];
                        }
                        if (message.customPersonType != null && message.hasOwnProperty("customPersonType"))
                            object.customPersonType = message.customPersonType;
                        if (message.ageRangeData && message.ageRangeData.length) {
                            object.ageRangeData = [];
                            for (var j = 0; j < message.ageRangeData.length; ++j)
                                object.ageRangeData[j] = options.json && !isFinite(message.ageRangeData[j]) ? String(message.ageRangeData[j]) : message.ageRangeData[j];
                        }
                        if (message.recordUuid != null && message.hasOwnProperty("recordUuid"))
                            object.recordUuid = message.recordUuid;
                        if (message.height != null && message.hasOwnProperty("height"))
                            object.height = message.height;
                        if (message.visitorGroupId != null && message.hasOwnProperty("visitorGroupId"))
                            object.visitorGroupId = message.visitorGroupId;
                        if (message.visitType != null && message.hasOwnProperty("visitType"))
                            object.visitType = options.enums === String ? $root.palexy.streaming.v1.RawICARecord.VisitType[message.visitType] : message.visitType;
                        if (message.visitorDirection != null && message.hasOwnProperty("visitorDirection"))
                            object.visitorDirection = $root.palexy.streaming.v1.VisitorDirection.toObject(message.visitorDirection, options);
                        if (message.validBbProb != null && message.hasOwnProperty("validBbProb"))
                            object.validBbProb = options.json && !isFinite(message.validBbProb) ? String(message.validBbProb) : message.validBbProb;
                        if (message.checkoutGroupId != null && message.hasOwnProperty("checkoutGroupId"))
                            object.checkoutGroupId = message.checkoutGroupId;
                        return object;
                    };
    
                    /**
                     * Converts this RawICARecord to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.RawICARecord
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RawICARecord.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * VisitType enum.
                     * @name palexy.streaming.v1.RawICARecord.VisitType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} ENTRANCE=1 ENTRANCE value
                     * @property {number} QUEUE_TIME=2 QUEUE_TIME value
                     * @property {number} PRODUCT_SECTION=3 PRODUCT_SECTION value
                     */
                    RawICARecord.VisitType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "ENTRANCE"] = 1;
                        values[valuesById[2] = "QUEUE_TIME"] = 2;
                        values[valuesById[3] = "PRODUCT_SECTION"] = 3;
                        return values;
                    })();
    
                    return RawICARecord;
                })();
    
                v1.VisitorDirection = (function() {
    
                    /**
                     * Properties of a VisitorDirection.
                     * @memberof palexy.streaming.v1
                     * @interface IVisitorDirection
                     * @property {number|null} [bodyDirectionAngle] VisitorDirection bodyDirectionAngle
                     * @property {number|null} [bodyDirectionProb] VisitorDirection bodyDirectionProb
                     */
    
                    /**
                     * Constructs a new VisitorDirection.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a VisitorDirection.
                     * @implements IVisitorDirection
                     * @constructor
                     * @param {palexy.streaming.v1.IVisitorDirection=} [properties] Properties to set
                     */
                    function VisitorDirection(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * VisitorDirection bodyDirectionAngle.
                     * @member {number} bodyDirectionAngle
                     * @memberof palexy.streaming.v1.VisitorDirection
                     * @instance
                     */
                    VisitorDirection.prototype.bodyDirectionAngle = 0;
    
                    /**
                     * VisitorDirection bodyDirectionProb.
                     * @member {number} bodyDirectionProb
                     * @memberof palexy.streaming.v1.VisitorDirection
                     * @instance
                     */
                    VisitorDirection.prototype.bodyDirectionProb = 0;
    
                    /**
                     * Creates a new VisitorDirection instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.VisitorDirection
                     * @static
                     * @param {palexy.streaming.v1.IVisitorDirection=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.VisitorDirection} VisitorDirection instance
                     */
                    VisitorDirection.create = function create(properties) {
                        return new VisitorDirection(properties);
                    };
    
                    /**
                     * Encodes the specified VisitorDirection message. Does not implicitly {@link palexy.streaming.v1.VisitorDirection.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.VisitorDirection
                     * @static
                     * @param {palexy.streaming.v1.IVisitorDirection} message VisitorDirection message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VisitorDirection.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.bodyDirectionAngle != null && Object.hasOwnProperty.call(message, "bodyDirectionAngle"))
                            writer.uint32(/* id 1, wireType 5 =*/13).float(message.bodyDirectionAngle);
                        if (message.bodyDirectionProb != null && Object.hasOwnProperty.call(message, "bodyDirectionProb"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.bodyDirectionProb);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified VisitorDirection message, length delimited. Does not implicitly {@link palexy.streaming.v1.VisitorDirection.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.VisitorDirection
                     * @static
                     * @param {palexy.streaming.v1.IVisitorDirection} message VisitorDirection message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VisitorDirection.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a VisitorDirection message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.VisitorDirection
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.VisitorDirection} VisitorDirection
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VisitorDirection.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.VisitorDirection();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.bodyDirectionAngle = reader.float();
                                break;
                            case 2:
                                message.bodyDirectionProb = reader.float();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a VisitorDirection message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.VisitorDirection
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.VisitorDirection} VisitorDirection
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VisitorDirection.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a VisitorDirection message.
                     * @function verify
                     * @memberof palexy.streaming.v1.VisitorDirection
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    VisitorDirection.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.bodyDirectionAngle != null && message.hasOwnProperty("bodyDirectionAngle"))
                            if (typeof message.bodyDirectionAngle !== "number")
                                return "bodyDirectionAngle: number expected";
                        if (message.bodyDirectionProb != null && message.hasOwnProperty("bodyDirectionProb"))
                            if (typeof message.bodyDirectionProb !== "number")
                                return "bodyDirectionProb: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a VisitorDirection message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.VisitorDirection
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.VisitorDirection} VisitorDirection
                     */
                    VisitorDirection.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.VisitorDirection)
                            return object;
                        var message = new $root.palexy.streaming.v1.VisitorDirection();
                        if (object.bodyDirectionAngle != null)
                            message.bodyDirectionAngle = Number(object.bodyDirectionAngle);
                        if (object.bodyDirectionProb != null)
                            message.bodyDirectionProb = Number(object.bodyDirectionProb);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a VisitorDirection message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.VisitorDirection
                     * @static
                     * @param {palexy.streaming.v1.VisitorDirection} message VisitorDirection
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    VisitorDirection.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.bodyDirectionAngle = 0;
                            object.bodyDirectionProb = 0;
                        }
                        if (message.bodyDirectionAngle != null && message.hasOwnProperty("bodyDirectionAngle"))
                            object.bodyDirectionAngle = options.json && !isFinite(message.bodyDirectionAngle) ? String(message.bodyDirectionAngle) : message.bodyDirectionAngle;
                        if (message.bodyDirectionProb != null && message.hasOwnProperty("bodyDirectionProb"))
                            object.bodyDirectionProb = options.json && !isFinite(message.bodyDirectionProb) ? String(message.bodyDirectionProb) : message.bodyDirectionProb;
                        return object;
                    };
    
                    /**
                     * Converts this VisitorDirection to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.VisitorDirection
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    VisitorDirection.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return VisitorDirection;
                })();
    
                v1.BoundingBoxCoordinates = (function() {
    
                    /**
                     * Properties of a BoundingBoxCoordinates.
                     * @memberof palexy.streaming.v1
                     * @interface IBoundingBoxCoordinates
                     * @property {number|null} [xMin] BoundingBoxCoordinates xMin
                     * @property {number|null} [xMax] BoundingBoxCoordinates xMax
                     * @property {number|null} [yMin] BoundingBoxCoordinates yMin
                     * @property {number|null} [yMax] BoundingBoxCoordinates yMax
                     */
    
                    /**
                     * Constructs a new BoundingBoxCoordinates.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents a BoundingBoxCoordinates.
                     * @implements IBoundingBoxCoordinates
                     * @constructor
                     * @param {palexy.streaming.v1.IBoundingBoxCoordinates=} [properties] Properties to set
                     */
                    function BoundingBoxCoordinates(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BoundingBoxCoordinates xMin.
                     * @member {number} xMin
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @instance
                     */
                    BoundingBoxCoordinates.prototype.xMin = 0;
    
                    /**
                     * BoundingBoxCoordinates xMax.
                     * @member {number} xMax
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @instance
                     */
                    BoundingBoxCoordinates.prototype.xMax = 0;
    
                    /**
                     * BoundingBoxCoordinates yMin.
                     * @member {number} yMin
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @instance
                     */
                    BoundingBoxCoordinates.prototype.yMin = 0;
    
                    /**
                     * BoundingBoxCoordinates yMax.
                     * @member {number} yMax
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @instance
                     */
                    BoundingBoxCoordinates.prototype.yMax = 0;
    
                    /**
                     * Creates a new BoundingBoxCoordinates instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @static
                     * @param {palexy.streaming.v1.IBoundingBoxCoordinates=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.BoundingBoxCoordinates} BoundingBoxCoordinates instance
                     */
                    BoundingBoxCoordinates.create = function create(properties) {
                        return new BoundingBoxCoordinates(properties);
                    };
    
                    /**
                     * Encodes the specified BoundingBoxCoordinates message. Does not implicitly {@link palexy.streaming.v1.BoundingBoxCoordinates.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @static
                     * @param {palexy.streaming.v1.IBoundingBoxCoordinates} message BoundingBoxCoordinates message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BoundingBoxCoordinates.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.xMin != null && Object.hasOwnProperty.call(message, "xMin"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.xMin);
                        if (message.xMax != null && Object.hasOwnProperty.call(message, "xMax"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.xMax);
                        if (message.yMin != null && Object.hasOwnProperty.call(message, "yMin"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.yMin);
                        if (message.yMax != null && Object.hasOwnProperty.call(message, "yMax"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.yMax);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BoundingBoxCoordinates message, length delimited. Does not implicitly {@link palexy.streaming.v1.BoundingBoxCoordinates.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @static
                     * @param {palexy.streaming.v1.IBoundingBoxCoordinates} message BoundingBoxCoordinates message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BoundingBoxCoordinates.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BoundingBoxCoordinates message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.BoundingBoxCoordinates} BoundingBoxCoordinates
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BoundingBoxCoordinates.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.BoundingBoxCoordinates();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.xMin = reader.int32();
                                break;
                            case 2:
                                message.xMax = reader.int32();
                                break;
                            case 3:
                                message.yMin = reader.int32();
                                break;
                            case 4:
                                message.yMax = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BoundingBoxCoordinates message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.BoundingBoxCoordinates} BoundingBoxCoordinates
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BoundingBoxCoordinates.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BoundingBoxCoordinates message.
                     * @function verify
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BoundingBoxCoordinates.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.xMin != null && message.hasOwnProperty("xMin"))
                            if (!$util.isInteger(message.xMin))
                                return "xMin: integer expected";
                        if (message.xMax != null && message.hasOwnProperty("xMax"))
                            if (!$util.isInteger(message.xMax))
                                return "xMax: integer expected";
                        if (message.yMin != null && message.hasOwnProperty("yMin"))
                            if (!$util.isInteger(message.yMin))
                                return "yMin: integer expected";
                        if (message.yMax != null && message.hasOwnProperty("yMax"))
                            if (!$util.isInteger(message.yMax))
                                return "yMax: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a BoundingBoxCoordinates message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.BoundingBoxCoordinates} BoundingBoxCoordinates
                     */
                    BoundingBoxCoordinates.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.BoundingBoxCoordinates)
                            return object;
                        var message = new $root.palexy.streaming.v1.BoundingBoxCoordinates();
                        if (object.xMin != null)
                            message.xMin = object.xMin | 0;
                        if (object.xMax != null)
                            message.xMax = object.xMax | 0;
                        if (object.yMin != null)
                            message.yMin = object.yMin | 0;
                        if (object.yMax != null)
                            message.yMax = object.yMax | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BoundingBoxCoordinates message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @static
                     * @param {palexy.streaming.v1.BoundingBoxCoordinates} message BoundingBoxCoordinates
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BoundingBoxCoordinates.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.xMin = 0;
                            object.xMax = 0;
                            object.yMin = 0;
                            object.yMax = 0;
                        }
                        if (message.xMin != null && message.hasOwnProperty("xMin"))
                            object.xMin = message.xMin;
                        if (message.xMax != null && message.hasOwnProperty("xMax"))
                            object.xMax = message.xMax;
                        if (message.yMin != null && message.hasOwnProperty("yMin"))
                            object.yMin = message.yMin;
                        if (message.yMax != null && message.hasOwnProperty("yMax"))
                            object.yMax = message.yMax;
                        return object;
                    };
    
                    /**
                     * Converts this BoundingBoxCoordinates to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.BoundingBoxCoordinates
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BoundingBoxCoordinates.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BoundingBoxCoordinates;
                })();
    
                /**
                 * DataSource enum.
                 * @name palexy.streaming.v1.DataSource
                 * @enum {number}
                 * @property {number} DS_UNKNOWN=0 DS_UNKNOWN value
                 * @property {number} VIDEO=1 VIDEO value
                 * @property {number} POS=2 POS value
                 * @property {number} STAFFING=3 STAFFING value
                 * @property {number} FORECAST=4 FORECAST value
                 * @property {number} TARGET=5 TARGET value
                 */
                v1.DataSource = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "DS_UNKNOWN"] = 0;
                    values[valuesById[1] = "VIDEO"] = 1;
                    values[valuesById[2] = "POS"] = 2;
                    values[valuesById[3] = "STAFFING"] = 3;
                    values[valuesById[4] = "FORECAST"] = 4;
                    values[valuesById[5] = "TARGET"] = 5;
                    return values;
                })();
    
                /**
                 * MetricEnum enum.
                 * @name palexy.streaming.v1.MetricEnum
                 * @enum {number}
                 * @property {number} METRIC_UNKNOWN=0 METRIC_UNKNOWN value
                 * @property {number} METRIC_BUYER_TRAFFIC=1 METRIC_BUYER_TRAFFIC value
                 * @property {number} METRIC_BUYER_TRAFFIC_WITH_INTERACTION=2 METRIC_BUYER_TRAFFIC_WITH_INTERACTION value
                 * @property {number} METRIC_INTERACTION=3 METRIC_INTERACTION value
                 * @property {number} METRIC_VISIT=4 METRIC_VISIT value
                 * @property {number} METRIC_GROUP_VISIT=5 METRIC_GROUP_VISIT value
                 * @property {number} METRIC_GROUP_INTERACTION=6 METRIC_GROUP_INTERACTION value
                 * @property {number} METRIC_GROUP_BUYER_TRAFFIC=7 METRIC_GROUP_BUYER_TRAFFIC value
                 * @property {number} METRIC_GROUP_BUYER_TRAFFIC_WITH_INTERACTION=8 METRIC_GROUP_BUYER_TRAFFIC_WITH_INTERACTION value
                 * @property {number} METRIC_BUYER_RATE=9 METRIC_BUYER_RATE value
                 * @property {number} METRIC_INTERACTION_RATE=10 METRIC_INTERACTION_RATE value
                 * @property {number} METRIC_INTERACTION_TIME=11 METRIC_INTERACTION_TIME value
                 * @property {number} METRIC_INTERACTION_TIME_AVG=12 METRIC_INTERACTION_TIME_AVG value
                 * @property {number} METRIC_INTERACTION_LEVEL=13 METRIC_INTERACTION_LEVEL value
                 * @property {number} METRIC_INTERACTION_BUYER_RATE=14 METRIC_INTERACTION_BUYER_RATE value
                 * @property {number} METRIC_AVG_TIME_TO_FIRST_INTERACTION=15 METRIC_AVG_TIME_TO_FIRST_INTERACTION value
                 * @property {number} METRIC_ON_TIME_INTERACTION_RATE=16 METRIC_ON_TIME_INTERACTION_RATE value
                 * @property {number} METRIC_GROUP_BUYER_RATE=17 METRIC_GROUP_BUYER_RATE value
                 * @property {number} METRIC_GROUP_INTERACTION_RATE=18 METRIC_GROUP_INTERACTION_RATE value
                 * @property {number} METRIC_GROUP_INTERACTION_BUYER_RATE=19 METRIC_GROUP_INTERACTION_BUYER_RATE value
                 * @property {number} METRIC_LYING=20 METRIC_LYING value
                 * @property {number} METRIC_LYING_RATE=21 METRIC_LYING_RATE value
                 * @property {number} METRIC_LYING_TIME_AVG=22 METRIC_LYING_TIME_AVG value
                 * @property {number} METRIC_LYING_EXACT_ONE=23 METRIC_LYING_EXACT_ONE value
                 * @property {number} METRIC_LYING_AT_LEAST_ONE=24 METRIC_LYING_AT_LEAST_ONE value
                 * @property {number} METRIC_LYING_AT_LEAST_TWO=25 METRIC_LYING_AT_LEAST_TWO value
                 * @property {number} METRIC_DWELL_TIME=26 METRIC_DWELL_TIME value
                 * @property {number} METRIC_DWELL_TIME_AVG=27 METRIC_DWELL_TIME_AVG value
                 * @property {number} METRIC_TOTAL_GREETED_CUSTOMERS=28 METRIC_TOTAL_GREETED_CUSTOMERS value
                 * @property {number} METRIC_GREETING_RATE=29 METRIC_GREETING_RATE value
                 * @property {number} METRIC_AVG_TIME_TO_FIRST_GREETING=30 METRIC_AVG_TIME_TO_FIRST_GREETING value
                 * @property {number} METRIC_ON_TIME_GREETING_RATE=31 METRIC_ON_TIME_GREETING_RATE value
                 * @property {number} METRIC_FOOT_TRAFFIC=32 METRIC_FOOT_TRAFFIC value
                 * @property {number} METRIC_VISITOR_OCCUPANCY=33 METRIC_VISITOR_OCCUPANCY value
                 * @property {number} METRIC_TOTAL_GROUPS=34 METRIC_TOTAL_GROUPS value
                 * @property {number} METRIC_TOTAL_VISITORS_GOING_AS_GROUPS=35 METRIC_TOTAL_VISITORS_GOING_AS_GROUPS value
                 * @property {number} METRIC_GROUP_RATE=36 METRIC_GROUP_RATE value
                 * @property {number} METRIC_AVG_GROUP_SIZE=37 METRIC_AVG_GROUP_SIZE value
                 * @property {number} METRIC_TOTAL_GROUPS_WITH_KIDS=38 METRIC_TOTAL_GROUPS_WITH_KIDS value
                 * @property {number} METRIC_TOTAL_KNOWN_GROUPS=39 METRIC_TOTAL_KNOWN_GROUPS value
                 * @property {number} METRIC_GROUPS_WITH_KIDS_RATE=40 METRIC_GROUPS_WITH_KIDS_RATE value
                 * @property {number} METRIC_TOTAL_GROUPS_AND_INDIVIDUALS=41 METRIC_TOTAL_GROUPS_AND_INDIVIDUALS value
                 * @property {number} METRIC_CONVERSION_BY_OPPORTUNITIES=42 METRIC_CONVERSION_BY_OPPORTUNITIES value
                 * @property {number} METRIC_PERCENT_SECTION_VISIT=43 METRIC_PERCENT_SECTION_VISIT value
                 * @property {number} METRIC_PERCENT_SECTION_PASS_BY=44 METRIC_PERCENT_SECTION_PASS_BY value
                 * @property {number} METRIC_PASS_BY=45 METRIC_PASS_BY value
                 * @property {number} METRIC_CAPTURE_RATE=46 METRIC_CAPTURE_RATE value
                 * @property {number} METRIC_TRANSACTION=47 METRIC_TRANSACTION value
                 * @property {number} METRIC_CONVERSION_RATE=48 METRIC_CONVERSION_RATE value
                 * @property {number} METRIC_NET_SALES_AMOUNT=49 METRIC_NET_SALES_AMOUNT value
                 * @property {number} METRIC_NET_SALES_ITEMS=50 METRIC_NET_SALES_ITEMS value
                 * @property {number} METRIC_NET_SALES_CONTRIBUTION=51 METRIC_NET_SALES_CONTRIBUTION value
                 * @property {number} METRIC_NET_SALES_RANKING=52 METRIC_NET_SALES_RANKING value
                 * @property {number} METRIC_SHOPPER_YIELD=53 METRIC_SHOPPER_YIELD value
                 * @property {number} METRIC_ATV=54 METRIC_ATV value
                 * @property {number} METRIC_UPT=55 METRIC_UPT value
                 * @property {number} METRIC_UPDPS=56 METRIC_UPDPS value
                 * @property {number} METRIC_ASP=57 METRIC_ASP value
                 * @property {number} METRIC_STAFF_HOURS=58 METRIC_STAFF_HOURS value
                 * @property {number} METRIC_VISITOR_PER_STAFF_HOUR=59 METRIC_VISITOR_PER_STAFF_HOUR value
                 * @property {number} METRIC_SALES_PER_STAFF_HOUR=60 METRIC_SALES_PER_STAFF_HOUR value
                 * @property {number} METRIC_AVERAGE_VISITORS_PER_STAFF_IN_HOUR=61 METRIC_AVERAGE_VISITORS_PER_STAFF_IN_HOUR value
                 * @property {number} METRIC_MAX_VISITORS_PER_STAFF_IN_HOUR=62 METRIC_MAX_VISITORS_PER_STAFF_IN_HOUR value
                 * @property {number} METRIC_FORECAST_AVERAGE_VISITORS_PER_STAFF_IN_HOUR=63 METRIC_FORECAST_AVERAGE_VISITORS_PER_STAFF_IN_HOUR value
                 * @property {number} METRIC_FORECAST_MAX_VISITORS_PER_STAFF_IN_HOUR=64 METRIC_FORECAST_MAX_VISITORS_PER_STAFF_IN_HOUR value
                 * @property {number} METRIC_ROUNDED_STAFF_HOURS=65 METRIC_ROUNDED_STAFF_HOURS value
                 * @property {number} METRIC_STAFF_HOUR_WITH_VISITOR_COUNT=66 METRIC_STAFF_HOUR_WITH_VISITOR_COUNT value
                 * @property {number} METRIC_VISITORS_PER_SQM=67 METRIC_VISITORS_PER_SQM value
                 * @property {number} METRIC_AVG_VISITOR_PER_STORE_PER_DAY=68 METRIC_AVG_VISITOR_PER_STORE_PER_DAY value
                 * @property {number} METRIC_DAYS_WITH_VISITORS=69 METRIC_DAYS_WITH_VISITORS value
                 * @property {number} METRIC_DAYS_WITH_NET_SALES_ITEMS=70 METRIC_DAYS_WITH_NET_SALES_ITEMS value
                 * @property {number} METRIC_SALES_PER_SQM=71 METRIC_SALES_PER_SQM value
                 * @property {number} METRIC_SALES_PER_SALES_AREA_SQM=72 METRIC_SALES_PER_SALES_AREA_SQM value
                 * @property {number} METRIC_ESTIMATED_VISIT=73 METRIC_ESTIMATED_VISIT value
                 * @property {number} METRIC_ESTIMATED_PASS_BY=74 METRIC_ESTIMATED_PASS_BY value
                 * @property {number} METRIC_WALK_IN=75 METRIC_WALK_IN value
                 * @property {number} METRIC_PERCENT_SECTION_ESTIMATED_PASS_BY=76 METRIC_PERCENT_SECTION_ESTIMATED_PASS_BY value
                 * @property {number} METRIC_CUSTOM_ACTION_1=77 METRIC_CUSTOM_ACTION_1 value
                 * @property {number} METRIC_CUSTOM_ACTION_1_RATE=78 METRIC_CUSTOM_ACTION_1_RATE value
                 * @property {number} METRIC_CONVERTED_CUSTOM_ACTION_1_RATE=79 METRIC_CONVERTED_CUSTOM_ACTION_1_RATE value
                 * @property {number} METRIC_AVG_SALES_PER_STORE_PER_DAY=80 METRIC_AVG_SALES_PER_STORE_PER_DAY value
                 * @property {number} METRIC_STAFF_TOTAL_SERVED_VISITORS=81 METRIC_STAFF_TOTAL_SERVED_VISITORS value
                 * @property {number} METRIC_STAFF_CONVERSION_RATE=82 METRIC_STAFF_CONVERSION_RATE value
                 * @property {number} METRIC_STAFF_SALES_PER_SERVED_VISITORS=83 METRIC_STAFF_SALES_PER_SERVED_VISITORS value
                 * @property {number} METRIC_STAFF_SERVED_VISITORS_PER_STAFF_HOUR=84 METRIC_STAFF_SERVED_VISITORS_PER_STAFF_HOUR value
                 * @property {number} METRIC_STAFF_PERCENTAGE_OF_SALES=85 METRIC_STAFF_PERCENTAGE_OF_SALES value
                 * @property {number} METRIC_DAYS_WITH_SALES=86 METRIC_DAYS_WITH_SALES value
                 * @property {number} METRIC_VIDEO_GROUP_DISTRIBUTION=87 METRIC_VIDEO_GROUP_DISTRIBUTION value
                 * @property {number} METRIC_BOUNCE_RATE=88 METRIC_BOUNCE_RATE value
                 * @property {number} METRIC_AVG_MALL_VISITOR_PER_STORE_PER_DAY=89 METRIC_AVG_MALL_VISITOR_PER_STORE_PER_DAY value
                 * @property {number} METRIC_TOTAL_ON_TIME_GREETED_CUSTOMERS=90 METRIC_TOTAL_ON_TIME_GREETED_CUSTOMERS value
                 * @property {number} METRIC_STAFF_STORE_NET_SALES_AMOUNT=91 METRIC_STAFF_STORE_NET_SALES_AMOUNT value
                 * @property {number} METRIC_STAFF_UNIQUE_INTERACTIONS=92 METRIC_STAFF_UNIQUE_INTERACTIONS value
                 * @property {number} METRIC_STAFF_INTERACTION_RATE=93 METRIC_STAFF_INTERACTION_RATE value
                 * @property {number} METRIC_TOTAL_CHECKOUT_VISITORS=94 METRIC_TOTAL_CHECKOUT_VISITORS value
                 * @property {number} METRIC_MAX_CONCURRENT_CHECKOUT_VISITORS=95 METRIC_MAX_CONCURRENT_CHECKOUT_VISITORS value
                 * @property {number} METRIC_AVG_CONCURRENT_CHECKOUT_VISITORS=96 METRIC_AVG_CONCURRENT_CHECKOUT_VISITORS value
                 * @property {number} METRIC_AVG_CHECKOUT_TIME=97 METRIC_AVG_CHECKOUT_TIME value
                 * @property {number} METRIC_MAX_CHECKOUT_TIME=98 METRIC_MAX_CHECKOUT_TIME value
                 * @property {number} METRIC_TOTAL_CHECKOUT_OPPORTUNITIES=99 METRIC_TOTAL_CHECKOUT_OPPORTUNITIES value
                 * @property {number} METRIC_MAX_CONCURRENT_CHECKOUT_OPPORTUNITIES=100 METRIC_MAX_CONCURRENT_CHECKOUT_OPPORTUNITIES value
                 * @property {number} METRIC_AVG_CONCURRENT_CHECKOUT_OPPORTUNITIES=101 METRIC_AVG_CONCURRENT_CHECKOUT_OPPORTUNITIES value
                 * @property {number} METRIC_AVG_CHECKOUT_TIME_BY_OPPORTUNITIES=102 METRIC_AVG_CHECKOUT_TIME_BY_OPPORTUNITIES value
                 * @property {number} METRIC_MAX_CHECKOUT_TIME_BY_OPPORTUNITIES=103 METRIC_MAX_CHECKOUT_TIME_BY_OPPORTUNITIES value
                 * @property {number} METRIC_DISCOUNT_AMOUNT=104 METRIC_DISCOUNT_AMOUNT value
                 * @property {number} METRIC_DISCOUNT_RATE=105 METRIC_DISCOUNT_RATE value
                 * @property {number} METRIC_GROSS_AMOUNT=106 METRIC_GROSS_AMOUNT value
                 */
                v1.MetricEnum = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "METRIC_UNKNOWN"] = 0;
                    values[valuesById[1] = "METRIC_BUYER_TRAFFIC"] = 1;
                    values[valuesById[2] = "METRIC_BUYER_TRAFFIC_WITH_INTERACTION"] = 2;
                    values[valuesById[3] = "METRIC_INTERACTION"] = 3;
                    values[valuesById[4] = "METRIC_VISIT"] = 4;
                    values[valuesById[5] = "METRIC_GROUP_VISIT"] = 5;
                    values[valuesById[6] = "METRIC_GROUP_INTERACTION"] = 6;
                    values[valuesById[7] = "METRIC_GROUP_BUYER_TRAFFIC"] = 7;
                    values[valuesById[8] = "METRIC_GROUP_BUYER_TRAFFIC_WITH_INTERACTION"] = 8;
                    values[valuesById[9] = "METRIC_BUYER_RATE"] = 9;
                    values[valuesById[10] = "METRIC_INTERACTION_RATE"] = 10;
                    values[valuesById[11] = "METRIC_INTERACTION_TIME"] = 11;
                    values[valuesById[12] = "METRIC_INTERACTION_TIME_AVG"] = 12;
                    values[valuesById[13] = "METRIC_INTERACTION_LEVEL"] = 13;
                    values[valuesById[14] = "METRIC_INTERACTION_BUYER_RATE"] = 14;
                    values[valuesById[15] = "METRIC_AVG_TIME_TO_FIRST_INTERACTION"] = 15;
                    values[valuesById[16] = "METRIC_ON_TIME_INTERACTION_RATE"] = 16;
                    values[valuesById[17] = "METRIC_GROUP_BUYER_RATE"] = 17;
                    values[valuesById[18] = "METRIC_GROUP_INTERACTION_RATE"] = 18;
                    values[valuesById[19] = "METRIC_GROUP_INTERACTION_BUYER_RATE"] = 19;
                    values[valuesById[20] = "METRIC_LYING"] = 20;
                    values[valuesById[21] = "METRIC_LYING_RATE"] = 21;
                    values[valuesById[22] = "METRIC_LYING_TIME_AVG"] = 22;
                    values[valuesById[23] = "METRIC_LYING_EXACT_ONE"] = 23;
                    values[valuesById[24] = "METRIC_LYING_AT_LEAST_ONE"] = 24;
                    values[valuesById[25] = "METRIC_LYING_AT_LEAST_TWO"] = 25;
                    values[valuesById[26] = "METRIC_DWELL_TIME"] = 26;
                    values[valuesById[27] = "METRIC_DWELL_TIME_AVG"] = 27;
                    values[valuesById[28] = "METRIC_TOTAL_GREETED_CUSTOMERS"] = 28;
                    values[valuesById[29] = "METRIC_GREETING_RATE"] = 29;
                    values[valuesById[30] = "METRIC_AVG_TIME_TO_FIRST_GREETING"] = 30;
                    values[valuesById[31] = "METRIC_ON_TIME_GREETING_RATE"] = 31;
                    values[valuesById[32] = "METRIC_FOOT_TRAFFIC"] = 32;
                    values[valuesById[33] = "METRIC_VISITOR_OCCUPANCY"] = 33;
                    values[valuesById[34] = "METRIC_TOTAL_GROUPS"] = 34;
                    values[valuesById[35] = "METRIC_TOTAL_VISITORS_GOING_AS_GROUPS"] = 35;
                    values[valuesById[36] = "METRIC_GROUP_RATE"] = 36;
                    values[valuesById[37] = "METRIC_AVG_GROUP_SIZE"] = 37;
                    values[valuesById[38] = "METRIC_TOTAL_GROUPS_WITH_KIDS"] = 38;
                    values[valuesById[39] = "METRIC_TOTAL_KNOWN_GROUPS"] = 39;
                    values[valuesById[40] = "METRIC_GROUPS_WITH_KIDS_RATE"] = 40;
                    values[valuesById[41] = "METRIC_TOTAL_GROUPS_AND_INDIVIDUALS"] = 41;
                    values[valuesById[42] = "METRIC_CONVERSION_BY_OPPORTUNITIES"] = 42;
                    values[valuesById[43] = "METRIC_PERCENT_SECTION_VISIT"] = 43;
                    values[valuesById[44] = "METRIC_PERCENT_SECTION_PASS_BY"] = 44;
                    values[valuesById[45] = "METRIC_PASS_BY"] = 45;
                    values[valuesById[46] = "METRIC_CAPTURE_RATE"] = 46;
                    values[valuesById[47] = "METRIC_TRANSACTION"] = 47;
                    values[valuesById[48] = "METRIC_CONVERSION_RATE"] = 48;
                    values[valuesById[49] = "METRIC_NET_SALES_AMOUNT"] = 49;
                    values[valuesById[50] = "METRIC_NET_SALES_ITEMS"] = 50;
                    values[valuesById[51] = "METRIC_NET_SALES_CONTRIBUTION"] = 51;
                    values[valuesById[52] = "METRIC_NET_SALES_RANKING"] = 52;
                    values[valuesById[53] = "METRIC_SHOPPER_YIELD"] = 53;
                    values[valuesById[54] = "METRIC_ATV"] = 54;
                    values[valuesById[55] = "METRIC_UPT"] = 55;
                    values[valuesById[56] = "METRIC_UPDPS"] = 56;
                    values[valuesById[57] = "METRIC_ASP"] = 57;
                    values[valuesById[58] = "METRIC_STAFF_HOURS"] = 58;
                    values[valuesById[59] = "METRIC_VISITOR_PER_STAFF_HOUR"] = 59;
                    values[valuesById[60] = "METRIC_SALES_PER_STAFF_HOUR"] = 60;
                    values[valuesById[61] = "METRIC_AVERAGE_VISITORS_PER_STAFF_IN_HOUR"] = 61;
                    values[valuesById[62] = "METRIC_MAX_VISITORS_PER_STAFF_IN_HOUR"] = 62;
                    values[valuesById[63] = "METRIC_FORECAST_AVERAGE_VISITORS_PER_STAFF_IN_HOUR"] = 63;
                    values[valuesById[64] = "METRIC_FORECAST_MAX_VISITORS_PER_STAFF_IN_HOUR"] = 64;
                    values[valuesById[65] = "METRIC_ROUNDED_STAFF_HOURS"] = 65;
                    values[valuesById[66] = "METRIC_STAFF_HOUR_WITH_VISITOR_COUNT"] = 66;
                    values[valuesById[67] = "METRIC_VISITORS_PER_SQM"] = 67;
                    values[valuesById[68] = "METRIC_AVG_VISITOR_PER_STORE_PER_DAY"] = 68;
                    values[valuesById[69] = "METRIC_DAYS_WITH_VISITORS"] = 69;
                    values[valuesById[70] = "METRIC_DAYS_WITH_NET_SALES_ITEMS"] = 70;
                    values[valuesById[71] = "METRIC_SALES_PER_SQM"] = 71;
                    values[valuesById[72] = "METRIC_SALES_PER_SALES_AREA_SQM"] = 72;
                    values[valuesById[73] = "METRIC_ESTIMATED_VISIT"] = 73;
                    values[valuesById[74] = "METRIC_ESTIMATED_PASS_BY"] = 74;
                    values[valuesById[75] = "METRIC_WALK_IN"] = 75;
                    values[valuesById[76] = "METRIC_PERCENT_SECTION_ESTIMATED_PASS_BY"] = 76;
                    values[valuesById[77] = "METRIC_CUSTOM_ACTION_1"] = 77;
                    values[valuesById[78] = "METRIC_CUSTOM_ACTION_1_RATE"] = 78;
                    values[valuesById[79] = "METRIC_CONVERTED_CUSTOM_ACTION_1_RATE"] = 79;
                    values[valuesById[80] = "METRIC_AVG_SALES_PER_STORE_PER_DAY"] = 80;
                    values[valuesById[81] = "METRIC_STAFF_TOTAL_SERVED_VISITORS"] = 81;
                    values[valuesById[82] = "METRIC_STAFF_CONVERSION_RATE"] = 82;
                    values[valuesById[83] = "METRIC_STAFF_SALES_PER_SERVED_VISITORS"] = 83;
                    values[valuesById[84] = "METRIC_STAFF_SERVED_VISITORS_PER_STAFF_HOUR"] = 84;
                    values[valuesById[85] = "METRIC_STAFF_PERCENTAGE_OF_SALES"] = 85;
                    values[valuesById[86] = "METRIC_DAYS_WITH_SALES"] = 86;
                    values[valuesById[87] = "METRIC_VIDEO_GROUP_DISTRIBUTION"] = 87;
                    values[valuesById[88] = "METRIC_BOUNCE_RATE"] = 88;
                    values[valuesById[89] = "METRIC_AVG_MALL_VISITOR_PER_STORE_PER_DAY"] = 89;
                    values[valuesById[90] = "METRIC_TOTAL_ON_TIME_GREETED_CUSTOMERS"] = 90;
                    values[valuesById[91] = "METRIC_STAFF_STORE_NET_SALES_AMOUNT"] = 91;
                    values[valuesById[92] = "METRIC_STAFF_UNIQUE_INTERACTIONS"] = 92;
                    values[valuesById[93] = "METRIC_STAFF_INTERACTION_RATE"] = 93;
                    values[valuesById[94] = "METRIC_TOTAL_CHECKOUT_VISITORS"] = 94;
                    values[valuesById[95] = "METRIC_MAX_CONCURRENT_CHECKOUT_VISITORS"] = 95;
                    values[valuesById[96] = "METRIC_AVG_CONCURRENT_CHECKOUT_VISITORS"] = 96;
                    values[valuesById[97] = "METRIC_AVG_CHECKOUT_TIME"] = 97;
                    values[valuesById[98] = "METRIC_MAX_CHECKOUT_TIME"] = 98;
                    values[valuesById[99] = "METRIC_TOTAL_CHECKOUT_OPPORTUNITIES"] = 99;
                    values[valuesById[100] = "METRIC_MAX_CONCURRENT_CHECKOUT_OPPORTUNITIES"] = 100;
                    values[valuesById[101] = "METRIC_AVG_CONCURRENT_CHECKOUT_OPPORTUNITIES"] = 101;
                    values[valuesById[102] = "METRIC_AVG_CHECKOUT_TIME_BY_OPPORTUNITIES"] = 102;
                    values[valuesById[103] = "METRIC_MAX_CHECKOUT_TIME_BY_OPPORTUNITIES"] = 103;
                    values[valuesById[104] = "METRIC_DISCOUNT_AMOUNT"] = 104;
                    values[valuesById[105] = "METRIC_DISCOUNT_RATE"] = 105;
                    values[valuesById[106] = "METRIC_GROSS_AMOUNT"] = 106;
                    return values;
                })();
    
                /**
                 * DayOfWeek enum.
                 * @name palexy.streaming.v1.DayOfWeek
                 * @enum {number}
                 * @property {number} DAY_OF_WEEK_UNSPECIFIED=0 DAY_OF_WEEK_UNSPECIFIED value
                 * @property {number} MONDAY=1 MONDAY value
                 * @property {number} TUESDAY=2 TUESDAY value
                 * @property {number} WEDNESDAY=3 WEDNESDAY value
                 * @property {number} THURSDAY=4 THURSDAY value
                 * @property {number} FRIDAY=5 FRIDAY value
                 * @property {number} SATURDAY=6 SATURDAY value
                 * @property {number} SUNDAY=7 SUNDAY value
                 */
                v1.DayOfWeek = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "DAY_OF_WEEK_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "MONDAY"] = 1;
                    values[valuesById[2] = "TUESDAY"] = 2;
                    values[valuesById[3] = "WEDNESDAY"] = 3;
                    values[valuesById[4] = "THURSDAY"] = 4;
                    values[valuesById[5] = "FRIDAY"] = 5;
                    values[valuesById[6] = "SATURDAY"] = 6;
                    values[valuesById[7] = "SUNDAY"] = 7;
                    return values;
                })();
    
                v1.AsyncJobStatusSummary = (function() {
    
                    /**
                     * Properties of an AsyncJobStatusSummary.
                     * @memberof palexy.streaming.v1
                     * @interface IAsyncJobStatusSummary
                     * @property {string|null} [jobType] AsyncJobStatusSummary jobType
                     * @property {number|null} [ongoingJobCount] AsyncJobStatusSummary ongoingJobCount
                     * @property {number|null} [lastRecurringTimeMs] AsyncJobStatusSummary lastRecurringTimeMs
                     */
    
                    /**
                     * Constructs a new AsyncJobStatusSummary.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an AsyncJobStatusSummary.
                     * @implements IAsyncJobStatusSummary
                     * @constructor
                     * @param {palexy.streaming.v1.IAsyncJobStatusSummary=} [properties] Properties to set
                     */
                    function AsyncJobStatusSummary(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * AsyncJobStatusSummary jobType.
                     * @member {string} jobType
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @instance
                     */
                    AsyncJobStatusSummary.prototype.jobType = "";
    
                    /**
                     * AsyncJobStatusSummary ongoingJobCount.
                     * @member {number} ongoingJobCount
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @instance
                     */
                    AsyncJobStatusSummary.prototype.ongoingJobCount = 0;
    
                    /**
                     * AsyncJobStatusSummary lastRecurringTimeMs.
                     * @member {number} lastRecurringTimeMs
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @instance
                     */
                    AsyncJobStatusSummary.prototype.lastRecurringTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new AsyncJobStatusSummary instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @static
                     * @param {palexy.streaming.v1.IAsyncJobStatusSummary=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.AsyncJobStatusSummary} AsyncJobStatusSummary instance
                     */
                    AsyncJobStatusSummary.create = function create(properties) {
                        return new AsyncJobStatusSummary(properties);
                    };
    
                    /**
                     * Encodes the specified AsyncJobStatusSummary message. Does not implicitly {@link palexy.streaming.v1.AsyncJobStatusSummary.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @static
                     * @param {palexy.streaming.v1.IAsyncJobStatusSummary} message AsyncJobStatusSummary message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AsyncJobStatusSummary.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.ongoingJobCount != null && Object.hasOwnProperty.call(message, "ongoingJobCount"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ongoingJobCount);
                        if (message.lastRecurringTimeMs != null && Object.hasOwnProperty.call(message, "lastRecurringTimeMs"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.lastRecurringTimeMs);
                        if (message.jobType != null && Object.hasOwnProperty.call(message, "jobType"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.jobType);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified AsyncJobStatusSummary message, length delimited. Does not implicitly {@link palexy.streaming.v1.AsyncJobStatusSummary.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @static
                     * @param {palexy.streaming.v1.IAsyncJobStatusSummary} message AsyncJobStatusSummary message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AsyncJobStatusSummary.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an AsyncJobStatusSummary message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.AsyncJobStatusSummary} AsyncJobStatusSummary
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AsyncJobStatusSummary.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.AsyncJobStatusSummary();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 4:
                                message.jobType = reader.string();
                                break;
                            case 2:
                                message.ongoingJobCount = reader.int32();
                                break;
                            case 3:
                                message.lastRecurringTimeMs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an AsyncJobStatusSummary message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.AsyncJobStatusSummary} AsyncJobStatusSummary
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AsyncJobStatusSummary.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an AsyncJobStatusSummary message.
                     * @function verify
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AsyncJobStatusSummary.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.jobType != null && message.hasOwnProperty("jobType"))
                            if (!$util.isString(message.jobType))
                                return "jobType: string expected";
                        if (message.ongoingJobCount != null && message.hasOwnProperty("ongoingJobCount"))
                            if (!$util.isInteger(message.ongoingJobCount))
                                return "ongoingJobCount: integer expected";
                        if (message.lastRecurringTimeMs != null && message.hasOwnProperty("lastRecurringTimeMs"))
                            if (!$util.isInteger(message.lastRecurringTimeMs) && !(message.lastRecurringTimeMs && $util.isInteger(message.lastRecurringTimeMs.low) && $util.isInteger(message.lastRecurringTimeMs.high)))
                                return "lastRecurringTimeMs: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates an AsyncJobStatusSummary message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.AsyncJobStatusSummary} AsyncJobStatusSummary
                     */
                    AsyncJobStatusSummary.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.AsyncJobStatusSummary)
                            return object;
                        var message = new $root.palexy.streaming.v1.AsyncJobStatusSummary();
                        if (object.jobType != null)
                            message.jobType = String(object.jobType);
                        if (object.ongoingJobCount != null)
                            message.ongoingJobCount = object.ongoingJobCount | 0;
                        if (object.lastRecurringTimeMs != null)
                            if ($util.Long)
                                (message.lastRecurringTimeMs = $util.Long.fromValue(object.lastRecurringTimeMs)).unsigned = false;
                            else if (typeof object.lastRecurringTimeMs === "string")
                                message.lastRecurringTimeMs = parseInt(object.lastRecurringTimeMs, 10);
                            else if (typeof object.lastRecurringTimeMs === "number")
                                message.lastRecurringTimeMs = object.lastRecurringTimeMs;
                            else if (typeof object.lastRecurringTimeMs === "object")
                                message.lastRecurringTimeMs = new $util.LongBits(object.lastRecurringTimeMs.low >>> 0, object.lastRecurringTimeMs.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an AsyncJobStatusSummary message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @static
                     * @param {palexy.streaming.v1.AsyncJobStatusSummary} message AsyncJobStatusSummary
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AsyncJobStatusSummary.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.ongoingJobCount = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastRecurringTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastRecurringTimeMs = options.longs === String ? "0" : 0;
                            object.jobType = "";
                        }
                        if (message.ongoingJobCount != null && message.hasOwnProperty("ongoingJobCount"))
                            object.ongoingJobCount = message.ongoingJobCount;
                        if (message.lastRecurringTimeMs != null && message.hasOwnProperty("lastRecurringTimeMs"))
                            if (typeof message.lastRecurringTimeMs === "number")
                                object.lastRecurringTimeMs = options.longs === String ? String(message.lastRecurringTimeMs) : message.lastRecurringTimeMs;
                            else
                                object.lastRecurringTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastRecurringTimeMs) : options.longs === Number ? new $util.LongBits(message.lastRecurringTimeMs.low >>> 0, message.lastRecurringTimeMs.high >>> 0).toNumber() : message.lastRecurringTimeMs;
                        if (message.jobType != null && message.hasOwnProperty("jobType"))
                            object.jobType = message.jobType;
                        return object;
                    };
    
                    /**
                     * Converts this AsyncJobStatusSummary to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.AsyncJobStatusSummary
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AsyncJobStatusSummary.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return AsyncJobStatusSummary;
                })();
    
                v1.Artifact = (function() {
    
                    /**
                     * Properties of an Artifact.
                     * @memberof palexy.streaming.v1
                     * @interface IArtifact
                     * @property {palexy.streaming.v1.Artifact.ArtifactType|null} [type] Artifact type
                     * @property {palexy.streaming.v1.StorageProvider|null} [storageProvider] Artifact storageProvider
                     * @property {string|null} [description] Artifact description
                     * @property {palexy.streaming.v1.Artifact.IGCSFile|null} [gcsFile] Artifact gcsFile
                     * @property {palexy.streaming.v1.Artifact.IGDriveFile|null} [gdriveFile] Artifact gdriveFile
                     */
    
                    /**
                     * Constructs a new Artifact.
                     * @memberof palexy.streaming.v1
                     * @classdesc Represents an Artifact.
                     * @implements IArtifact
                     * @constructor
                     * @param {palexy.streaming.v1.IArtifact=} [properties] Properties to set
                     */
                    function Artifact(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Artifact type.
                     * @member {palexy.streaming.v1.Artifact.ArtifactType} type
                     * @memberof palexy.streaming.v1.Artifact
                     * @instance
                     */
                    Artifact.prototype.type = 0;
    
                    /**
                     * Artifact storageProvider.
                     * @member {palexy.streaming.v1.StorageProvider} storageProvider
                     * @memberof palexy.streaming.v1.Artifact
                     * @instance
                     */
                    Artifact.prototype.storageProvider = 0;
    
                    /**
                     * Artifact description.
                     * @member {string} description
                     * @memberof palexy.streaming.v1.Artifact
                     * @instance
                     */
                    Artifact.prototype.description = "";
    
                    /**
                     * Artifact gcsFile.
                     * @member {palexy.streaming.v1.Artifact.IGCSFile|null|undefined} gcsFile
                     * @memberof palexy.streaming.v1.Artifact
                     * @instance
                     */
                    Artifact.prototype.gcsFile = null;
    
                    /**
                     * Artifact gdriveFile.
                     * @member {palexy.streaming.v1.Artifact.IGDriveFile|null|undefined} gdriveFile
                     * @memberof palexy.streaming.v1.Artifact
                     * @instance
                     */
                    Artifact.prototype.gdriveFile = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * Artifact storage.
                     * @member {"gcsFile"|"gdriveFile"|undefined} storage
                     * @memberof palexy.streaming.v1.Artifact
                     * @instance
                     */
                    Object.defineProperty(Artifact.prototype, "storage", {
                        get: $util.oneOfGetter($oneOfFields = ["gcsFile", "gdriveFile"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new Artifact instance using the specified properties.
                     * @function create
                     * @memberof palexy.streaming.v1.Artifact
                     * @static
                     * @param {palexy.streaming.v1.IArtifact=} [properties] Properties to set
                     * @returns {palexy.streaming.v1.Artifact} Artifact instance
                     */
                    Artifact.create = function create(properties) {
                        return new Artifact(properties);
                    };
    
                    /**
                     * Encodes the specified Artifact message. Does not implicitly {@link palexy.streaming.v1.Artifact.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.streaming.v1.Artifact
                     * @static
                     * @param {palexy.streaming.v1.IArtifact} message Artifact message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Artifact.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        if (message.storageProvider != null && Object.hasOwnProperty.call(message, "storageProvider"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.storageProvider);
                        if (message.gcsFile != null && Object.hasOwnProperty.call(message, "gcsFile"))
                            $root.palexy.streaming.v1.Artifact.GCSFile.encode(message.gcsFile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.gdriveFile != null && Object.hasOwnProperty.call(message, "gdriveFile"))
                            $root.palexy.streaming.v1.Artifact.GDriveFile.encode(message.gdriveFile, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Artifact message, length delimited. Does not implicitly {@link palexy.streaming.v1.Artifact.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.streaming.v1.Artifact
                     * @static
                     * @param {palexy.streaming.v1.IArtifact} message Artifact message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Artifact.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Artifact message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.streaming.v1.Artifact
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.streaming.v1.Artifact} Artifact
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Artifact.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Artifact();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.type = reader.int32();
                                break;
                            case 2:
                                message.storageProvider = reader.int32();
                                break;
                            case 5:
                                message.description = reader.string();
                                break;
                            case 3:
                                message.gcsFile = $root.palexy.streaming.v1.Artifact.GCSFile.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.gdriveFile = $root.palexy.streaming.v1.Artifact.GDriveFile.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Artifact message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.streaming.v1.Artifact
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.streaming.v1.Artifact} Artifact
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Artifact.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Artifact message.
                     * @function verify
                     * @memberof palexy.streaming.v1.Artifact
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Artifact.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.storageProvider != null && message.hasOwnProperty("storageProvider"))
                            switch (message.storageProvider) {
                            default:
                                return "storageProvider: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.gcsFile != null && message.hasOwnProperty("gcsFile")) {
                            properties.storage = 1;
                            {
                                var error = $root.palexy.streaming.v1.Artifact.GCSFile.verify(message.gcsFile);
                                if (error)
                                    return "gcsFile." + error;
                            }
                        }
                        if (message.gdriveFile != null && message.hasOwnProperty("gdriveFile")) {
                            if (properties.storage === 1)
                                return "storage: multiple values";
                            properties.storage = 1;
                            {
                                var error = $root.palexy.streaming.v1.Artifact.GDriveFile.verify(message.gdriveFile);
                                if (error)
                                    return "gdriveFile." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an Artifact message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.streaming.v1.Artifact
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.streaming.v1.Artifact} Artifact
                     */
                    Artifact.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.streaming.v1.Artifact)
                            return object;
                        var message = new $root.palexy.streaming.v1.Artifact();
                        switch (object.type) {
                        case "UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "IMAGE":
                        case 1:
                            message.type = 1;
                            break;
                        case "VIDEO":
                        case 2:
                            message.type = 2;
                            break;
                        case "ZIP":
                        case 3:
                            message.type = 3;
                            break;
                        }
                        switch (object.storageProvider) {
                        case "UNKNOWN_PROVIDER":
                        case 0:
                            message.storageProvider = 0;
                            break;
                        case "GCS":
                        case 1:
                            message.storageProvider = 1;
                            break;
                        case "GDRIVE":
                        case 2:
                            message.storageProvider = 2;
                            break;
                        }
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.gcsFile != null) {
                            if (typeof object.gcsFile !== "object")
                                throw TypeError(".palexy.streaming.v1.Artifact.gcsFile: object expected");
                            message.gcsFile = $root.palexy.streaming.v1.Artifact.GCSFile.fromObject(object.gcsFile);
                        }
                        if (object.gdriveFile != null) {
                            if (typeof object.gdriveFile !== "object")
                                throw TypeError(".palexy.streaming.v1.Artifact.gdriveFile: object expected");
                            message.gdriveFile = $root.palexy.streaming.v1.Artifact.GDriveFile.fromObject(object.gdriveFile);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an Artifact message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.streaming.v1.Artifact
                     * @static
                     * @param {palexy.streaming.v1.Artifact} message Artifact
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Artifact.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.type = options.enums === String ? "UNKNOWN" : 0;
                            object.storageProvider = options.enums === String ? "UNKNOWN_PROVIDER" : 0;
                            object.description = "";
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.streaming.v1.Artifact.ArtifactType[message.type] : message.type;
                        if (message.storageProvider != null && message.hasOwnProperty("storageProvider"))
                            object.storageProvider = options.enums === String ? $root.palexy.streaming.v1.StorageProvider[message.storageProvider] : message.storageProvider;
                        if (message.gcsFile != null && message.hasOwnProperty("gcsFile")) {
                            object.gcsFile = $root.palexy.streaming.v1.Artifact.GCSFile.toObject(message.gcsFile, options);
                            if (options.oneofs)
                                object.storage = "gcsFile";
                        }
                        if (message.gdriveFile != null && message.hasOwnProperty("gdriveFile")) {
                            object.gdriveFile = $root.palexy.streaming.v1.Artifact.GDriveFile.toObject(message.gdriveFile, options);
                            if (options.oneofs)
                                object.storage = "gdriveFile";
                        }
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        return object;
                    };
    
                    /**
                     * Converts this Artifact to JSON.
                     * @function toJSON
                     * @memberof palexy.streaming.v1.Artifact
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Artifact.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * ArtifactType enum.
                     * @name palexy.streaming.v1.Artifact.ArtifactType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} IMAGE=1 IMAGE value
                     * @property {number} VIDEO=2 VIDEO value
                     * @property {number} ZIP=3 ZIP value
                     */
                    Artifact.ArtifactType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "IMAGE"] = 1;
                        values[valuesById[2] = "VIDEO"] = 2;
                        values[valuesById[3] = "ZIP"] = 3;
                        return values;
                    })();
    
                    Artifact.GCSFile = (function() {
    
                        /**
                         * Properties of a GCSFile.
                         * @memberof palexy.streaming.v1.Artifact
                         * @interface IGCSFile
                         * @property {string|null} [bucket] GCSFile bucket
                         * @property {string|null} [path] GCSFile path
                         */
    
                        /**
                         * Constructs a new GCSFile.
                         * @memberof palexy.streaming.v1.Artifact
                         * @classdesc Represents a GCSFile.
                         * @implements IGCSFile
                         * @constructor
                         * @param {palexy.streaming.v1.Artifact.IGCSFile=} [properties] Properties to set
                         */
                        function GCSFile(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * GCSFile bucket.
                         * @member {string} bucket
                         * @memberof palexy.streaming.v1.Artifact.GCSFile
                         * @instance
                         */
                        GCSFile.prototype.bucket = "";
    
                        /**
                         * GCSFile path.
                         * @member {string} path
                         * @memberof palexy.streaming.v1.Artifact.GCSFile
                         * @instance
                         */
                        GCSFile.prototype.path = "";
    
                        /**
                         * Creates a new GCSFile instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.Artifact.GCSFile
                         * @static
                         * @param {palexy.streaming.v1.Artifact.IGCSFile=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.Artifact.GCSFile} GCSFile instance
                         */
                        GCSFile.create = function create(properties) {
                            return new GCSFile(properties);
                        };
    
                        /**
                         * Encodes the specified GCSFile message. Does not implicitly {@link palexy.streaming.v1.Artifact.GCSFile.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.Artifact.GCSFile
                         * @static
                         * @param {palexy.streaming.v1.Artifact.IGCSFile} message GCSFile message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GCSFile.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.bucket != null && Object.hasOwnProperty.call(message, "bucket"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.bucket);
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified GCSFile message, length delimited. Does not implicitly {@link palexy.streaming.v1.Artifact.GCSFile.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.Artifact.GCSFile
                         * @static
                         * @param {palexy.streaming.v1.Artifact.IGCSFile} message GCSFile message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GCSFile.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a GCSFile message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.Artifact.GCSFile
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.Artifact.GCSFile} GCSFile
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GCSFile.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Artifact.GCSFile();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.bucket = reader.string();
                                    break;
                                case 2:
                                    message.path = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a GCSFile message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.Artifact.GCSFile
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.Artifact.GCSFile} GCSFile
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GCSFile.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a GCSFile message.
                         * @function verify
                         * @memberof palexy.streaming.v1.Artifact.GCSFile
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GCSFile.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.bucket != null && message.hasOwnProperty("bucket"))
                                if (!$util.isString(message.bucket))
                                    return "bucket: string expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            return null;
                        };
    
                        /**
                         * Creates a GCSFile message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.Artifact.GCSFile
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.Artifact.GCSFile} GCSFile
                         */
                        GCSFile.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.Artifact.GCSFile)
                                return object;
                            var message = new $root.palexy.streaming.v1.Artifact.GCSFile();
                            if (object.bucket != null)
                                message.bucket = String(object.bucket);
                            if (object.path != null)
                                message.path = String(object.path);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a GCSFile message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.Artifact.GCSFile
                         * @static
                         * @param {palexy.streaming.v1.Artifact.GCSFile} message GCSFile
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GCSFile.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.bucket = "";
                                object.path = "";
                            }
                            if (message.bucket != null && message.hasOwnProperty("bucket"))
                                object.bucket = message.bucket;
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            return object;
                        };
    
                        /**
                         * Converts this GCSFile to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.Artifact.GCSFile
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GCSFile.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return GCSFile;
                    })();
    
                    Artifact.GDriveFile = (function() {
    
                        /**
                         * Properties of a GDriveFile.
                         * @memberof palexy.streaming.v1.Artifact
                         * @interface IGDriveFile
                         * @property {string|null} [id] GDriveFile id
                         * @property {string|null} [link] GDriveFile link
                         * @property {Array.<string>|null} [parentIds] GDriveFile parentIds
                         */
    
                        /**
                         * Constructs a new GDriveFile.
                         * @memberof palexy.streaming.v1.Artifact
                         * @classdesc Represents a GDriveFile.
                         * @implements IGDriveFile
                         * @constructor
                         * @param {palexy.streaming.v1.Artifact.IGDriveFile=} [properties] Properties to set
                         */
                        function GDriveFile(properties) {
                            this.parentIds = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * GDriveFile id.
                         * @member {string} id
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @instance
                         */
                        GDriveFile.prototype.id = "";
    
                        /**
                         * GDriveFile link.
                         * @member {string} link
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @instance
                         */
                        GDriveFile.prototype.link = "";
    
                        /**
                         * GDriveFile parentIds.
                         * @member {Array.<string>} parentIds
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @instance
                         */
                        GDriveFile.prototype.parentIds = $util.emptyArray;
    
                        /**
                         * Creates a new GDriveFile instance using the specified properties.
                         * @function create
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @static
                         * @param {palexy.streaming.v1.Artifact.IGDriveFile=} [properties] Properties to set
                         * @returns {palexy.streaming.v1.Artifact.GDriveFile} GDriveFile instance
                         */
                        GDriveFile.create = function create(properties) {
                            return new GDriveFile(properties);
                        };
    
                        /**
                         * Encodes the specified GDriveFile message. Does not implicitly {@link palexy.streaming.v1.Artifact.GDriveFile.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @static
                         * @param {palexy.streaming.v1.Artifact.IGDriveFile} message GDriveFile message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GDriveFile.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.link);
                            if (message.parentIds != null && message.parentIds.length)
                                for (var i = 0; i < message.parentIds.length; ++i)
                                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.parentIds[i]);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified GDriveFile message, length delimited. Does not implicitly {@link palexy.streaming.v1.Artifact.GDriveFile.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @static
                         * @param {palexy.streaming.v1.Artifact.IGDriveFile} message GDriveFile message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GDriveFile.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a GDriveFile message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.streaming.v1.Artifact.GDriveFile} GDriveFile
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GDriveFile.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.streaming.v1.Artifact.GDriveFile();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.id = reader.string();
                                    break;
                                case 2:
                                    message.link = reader.string();
                                    break;
                                case 3:
                                    if (!(message.parentIds && message.parentIds.length))
                                        message.parentIds = [];
                                    message.parentIds.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a GDriveFile message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.streaming.v1.Artifact.GDriveFile} GDriveFile
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GDriveFile.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a GDriveFile message.
                         * @function verify
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GDriveFile.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.link != null && message.hasOwnProperty("link"))
                                if (!$util.isString(message.link))
                                    return "link: string expected";
                            if (message.parentIds != null && message.hasOwnProperty("parentIds")) {
                                if (!Array.isArray(message.parentIds))
                                    return "parentIds: array expected";
                                for (var i = 0; i < message.parentIds.length; ++i)
                                    if (!$util.isString(message.parentIds[i]))
                                        return "parentIds: string[] expected";
                            }
                            return null;
                        };
    
                        /**
                         * Creates a GDriveFile message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.streaming.v1.Artifact.GDriveFile} GDriveFile
                         */
                        GDriveFile.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.streaming.v1.Artifact.GDriveFile)
                                return object;
                            var message = new $root.palexy.streaming.v1.Artifact.GDriveFile();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.link != null)
                                message.link = String(object.link);
                            if (object.parentIds) {
                                if (!Array.isArray(object.parentIds))
                                    throw TypeError(".palexy.streaming.v1.Artifact.GDriveFile.parentIds: array expected");
                                message.parentIds = [];
                                for (var i = 0; i < object.parentIds.length; ++i)
                                    message.parentIds[i] = String(object.parentIds[i]);
                            }
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a GDriveFile message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @static
                         * @param {palexy.streaming.v1.Artifact.GDriveFile} message GDriveFile
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GDriveFile.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.parentIds = [];
                            if (options.defaults) {
                                object.id = "";
                                object.link = "";
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.link != null && message.hasOwnProperty("link"))
                                object.link = message.link;
                            if (message.parentIds && message.parentIds.length) {
                                object.parentIds = [];
                                for (var j = 0; j < message.parentIds.length; ++j)
                                    object.parentIds[j] = message.parentIds[j];
                            }
                            return object;
                        };
    
                        /**
                         * Converts this GDriveFile to JSON.
                         * @function toJSON
                         * @memberof palexy.streaming.v1.Artifact.GDriveFile
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GDriveFile.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return GDriveFile;
                    })();
    
                    return Artifact;
                })();
    
                /**
                 * StorageProvider enum.
                 * @name palexy.streaming.v1.StorageProvider
                 * @enum {number}
                 * @property {number} UNKNOWN_PROVIDER=0 UNKNOWN_PROVIDER value
                 * @property {number} GCS=1 GCS value
                 * @property {number} GDRIVE=2 GDRIVE value
                 */
                v1.StorageProvider = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_PROVIDER"] = 0;
                    values[valuesById[1] = "GCS"] = 1;
                    values[valuesById[2] = "GDRIVE"] = 2;
                    return values;
                })();
    
                return v1;
            })();
    
            return streaming;
        })();
    
        palexy.store_issue = (function() {
    
            /**
             * Namespace store_issue.
             * @memberof palexy
             * @namespace
             */
            var store_issue = {};
    
            store_issue.v1 = (function() {
    
                /**
                 * Namespace v1.
                 * @memberof palexy.store_issue
                 * @namespace
                 */
                var v1 = {};
    
                v1.StoreIssue = (function() {
    
                    /**
                     * Properties of a StoreIssue.
                     * @memberof palexy.store_issue.v1
                     * @interface IStoreIssue
                     * @property {number|null} [id] StoreIssue id
                     * @property {number|null} [storeId] StoreIssue storeId
                     * @property {palexy.store_issue.v1.StoreIssueType|null} [type] StoreIssue type
                     * @property {palexy.store_issue.v1.StoreIssueState|null} [state] StoreIssue state
                     * @property {string|null} [description] StoreIssue description
                     * @property {number|null} [createTimeMs] StoreIssue createTimeMs
                     * @property {number|null} [updateTimeMs] StoreIssue updateTimeMs
                     * @property {number|null} [occurredDateId] StoreIssue occurredDateId
                     * @property {string|null} [storeName] StoreIssue storeName
                     */
    
                    /**
                     * Constructs a new StoreIssue.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents a StoreIssue.
                     * @implements IStoreIssue
                     * @constructor
                     * @param {palexy.store_issue.v1.IStoreIssue=} [properties] Properties to set
                     */
                    function StoreIssue(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreIssue id.
                     * @member {number} id
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @instance
                     */
                    StoreIssue.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreIssue storeId.
                     * @member {number} storeId
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @instance
                     */
                    StoreIssue.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreIssue type.
                     * @member {palexy.store_issue.v1.StoreIssueType} type
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @instance
                     */
                    StoreIssue.prototype.type = 0;
    
                    /**
                     * StoreIssue state.
                     * @member {palexy.store_issue.v1.StoreIssueState} state
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @instance
                     */
                    StoreIssue.prototype.state = 0;
    
                    /**
                     * StoreIssue description.
                     * @member {string} description
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @instance
                     */
                    StoreIssue.prototype.description = "";
    
                    /**
                     * StoreIssue createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @instance
                     */
                    StoreIssue.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreIssue updateTimeMs.
                     * @member {number} updateTimeMs
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @instance
                     */
                    StoreIssue.prototype.updateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreIssue occurredDateId.
                     * @member {number} occurredDateId
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @instance
                     */
                    StoreIssue.prototype.occurredDateId = 0;
    
                    /**
                     * StoreIssue storeName.
                     * @member {string} storeName
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @instance
                     */
                    StoreIssue.prototype.storeName = "";
    
                    /**
                     * Creates a new StoreIssue instance using the specified properties.
                     * @function create
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @static
                     * @param {palexy.store_issue.v1.IStoreIssue=} [properties] Properties to set
                     * @returns {palexy.store_issue.v1.StoreIssue} StoreIssue instance
                     */
                    StoreIssue.create = function create(properties) {
                        return new StoreIssue(properties);
                    };
    
                    /**
                     * Encodes the specified StoreIssue message. Does not implicitly {@link palexy.store_issue.v1.StoreIssue.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @static
                     * @param {palexy.store_issue.v1.IStoreIssue} message StoreIssue message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreIssue.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.storeId);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.state);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createTimeMs);
                        if (message.updateTimeMs != null && Object.hasOwnProperty.call(message, "updateTimeMs"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.updateTimeMs);
                        if (message.occurredDateId != null && Object.hasOwnProperty.call(message, "occurredDateId"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.occurredDateId);
                        if (message.storeName != null && Object.hasOwnProperty.call(message, "storeName"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.storeName);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreIssue message, length delimited. Does not implicitly {@link palexy.store_issue.v1.StoreIssue.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @static
                     * @param {palexy.store_issue.v1.IStoreIssue} message StoreIssue message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreIssue.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreIssue message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.store_issue.v1.StoreIssue} StoreIssue
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreIssue.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.store_issue.v1.StoreIssue();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.storeId = reader.int64();
                                break;
                            case 3:
                                message.type = reader.int32();
                                break;
                            case 4:
                                message.state = reader.int32();
                                break;
                            case 5:
                                message.description = reader.string();
                                break;
                            case 6:
                                message.createTimeMs = reader.int64();
                                break;
                            case 7:
                                message.updateTimeMs = reader.int64();
                                break;
                            case 8:
                                message.occurredDateId = reader.int32();
                                break;
                            case 9:
                                message.storeName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreIssue message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.store_issue.v1.StoreIssue} StoreIssue
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreIssue.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreIssue message.
                     * @function verify
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreIssue.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (!$util.isInteger(message.updateTimeMs) && !(message.updateTimeMs && $util.isInteger(message.updateTimeMs.low) && $util.isInteger(message.updateTimeMs.high)))
                                return "updateTimeMs: integer|Long expected";
                        if (message.occurredDateId != null && message.hasOwnProperty("occurredDateId"))
                            if (!$util.isInteger(message.occurredDateId))
                                return "occurredDateId: integer expected";
                        if (message.storeName != null && message.hasOwnProperty("storeName"))
                            if (!$util.isString(message.storeName))
                                return "storeName: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a StoreIssue message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.store_issue.v1.StoreIssue} StoreIssue
                     */
                    StoreIssue.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.store_issue.v1.StoreIssue)
                            return object;
                        var message = new $root.palexy.store_issue.v1.StoreIssue();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        switch (object.type) {
                        case "UNKNOWN_STORE_ISSUE_TYPE":
                        case 0:
                            message.type = 0;
                            break;
                        case "SECTION_CONFIG":
                        case 1:
                            message.type = 1;
                            break;
                        case "UPLOAD_VIDEOS":
                        case 2:
                            message.type = 2;
                            break;
                        case "PREPROCESSING":
                        case 3:
                            message.type = 3;
                            break;
                        }
                        switch (object.state) {
                        case "UNKNOWN_STORE_ISSUE_STATE":
                        case 0:
                            message.state = 0;
                            break;
                        case "ACTIVE":
                        case 1:
                            message.state = 1;
                            break;
                        case "RESOLVED":
                        case 2:
                            message.state = 2;
                            break;
                        }
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.updateTimeMs != null)
                            if ($util.Long)
                                (message.updateTimeMs = $util.Long.fromValue(object.updateTimeMs)).unsigned = false;
                            else if (typeof object.updateTimeMs === "string")
                                message.updateTimeMs = parseInt(object.updateTimeMs, 10);
                            else if (typeof object.updateTimeMs === "number")
                                message.updateTimeMs = object.updateTimeMs;
                            else if (typeof object.updateTimeMs === "object")
                                message.updateTimeMs = new $util.LongBits(object.updateTimeMs.low >>> 0, object.updateTimeMs.high >>> 0).toNumber();
                        if (object.occurredDateId != null)
                            message.occurredDateId = object.occurredDateId | 0;
                        if (object.storeName != null)
                            message.storeName = String(object.storeName);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreIssue message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @static
                     * @param {palexy.store_issue.v1.StoreIssue} message StoreIssue
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreIssue.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.type = options.enums === String ? "UNKNOWN_STORE_ISSUE_TYPE" : 0;
                            object.state = options.enums === String ? "UNKNOWN_STORE_ISSUE_STATE" : 0;
                            object.description = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updateTimeMs = options.longs === String ? "0" : 0;
                            object.occurredDateId = 0;
                            object.storeName = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.store_issue.v1.StoreIssueType[message.type] : message.type;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.store_issue.v1.StoreIssueState[message.state] : message.state;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.updateTimeMs != null && message.hasOwnProperty("updateTimeMs"))
                            if (typeof message.updateTimeMs === "number")
                                object.updateTimeMs = options.longs === String ? String(message.updateTimeMs) : message.updateTimeMs;
                            else
                                object.updateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.updateTimeMs) : options.longs === Number ? new $util.LongBits(message.updateTimeMs.low >>> 0, message.updateTimeMs.high >>> 0).toNumber() : message.updateTimeMs;
                        if (message.occurredDateId != null && message.hasOwnProperty("occurredDateId"))
                            object.occurredDateId = message.occurredDateId;
                        if (message.storeName != null && message.hasOwnProperty("storeName"))
                            object.storeName = message.storeName;
                        return object;
                    };
    
                    /**
                     * Converts this StoreIssue to JSON.
                     * @function toJSON
                     * @memberof palexy.store_issue.v1.StoreIssue
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreIssue.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StoreIssue;
                })();
    
                v1.StoreIssueService = (function() {
    
                    /**
                     * Constructs a new StoreIssueService service.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents a StoreIssueService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StoreIssueService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (StoreIssueService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StoreIssueService;
    
                    /**
                     * Creates new StoreIssueService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StoreIssueService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StoreIssueService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.store_issue.v1.StoreIssueService#raiseStoreIssue}.
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @typedef RaiseStoreIssueCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.store_issue.v1.StoreIssue} [response] StoreIssue
                     */
    
                    /**
                     * Calls RaiseStoreIssue.
                     * @function raiseStoreIssue
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IRaiseStoreIssueRequest} request RaiseStoreIssueRequest message or plain object
                     * @param {palexy.store_issue.v1.StoreIssueService.RaiseStoreIssueCallback} callback Node-style callback called with the error, if any, and StoreIssue
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreIssueService.prototype.raiseStoreIssue = function raiseStoreIssue(request, callback) {
                        return this.rpcCall(raiseStoreIssue, $root.palexy.store_issue.v1.RaiseStoreIssueRequest, $root.palexy.store_issue.v1.StoreIssue, request, callback);
                    }, "name", { value: "RaiseStoreIssue" });
    
                    /**
                     * Calls RaiseStoreIssue.
                     * @function raiseStoreIssue
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IRaiseStoreIssueRequest} request RaiseStoreIssueRequest message or plain object
                     * @returns {Promise<palexy.store_issue.v1.StoreIssue>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.store_issue.v1.StoreIssueService#getStoreIssue}.
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @typedef GetStoreIssueCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.store_issue.v1.StoreIssue} [response] StoreIssue
                     */
    
                    /**
                     * Calls GetStoreIssue.
                     * @function getStoreIssue
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IGetStoreIssueRequest} request GetStoreIssueRequest message or plain object
                     * @param {palexy.store_issue.v1.StoreIssueService.GetStoreIssueCallback} callback Node-style callback called with the error, if any, and StoreIssue
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreIssueService.prototype.getStoreIssue = function getStoreIssue(request, callback) {
                        return this.rpcCall(getStoreIssue, $root.palexy.store_issue.v1.GetStoreIssueRequest, $root.palexy.store_issue.v1.StoreIssue, request, callback);
                    }, "name", { value: "GetStoreIssue" });
    
                    /**
                     * Calls GetStoreIssue.
                     * @function getStoreIssue
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IGetStoreIssueRequest} request GetStoreIssueRequest message or plain object
                     * @returns {Promise<palexy.store_issue.v1.StoreIssue>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.store_issue.v1.StoreIssueService#listStoreIssues}.
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @typedef ListStoreIssuesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.store_issue.v1.ListStoreIssuesResponse} [response] ListStoreIssuesResponse
                     */
    
                    /**
                     * Calls ListStoreIssues.
                     * @function listStoreIssues
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IListStoreIssuesRequest} request ListStoreIssuesRequest message or plain object
                     * @param {palexy.store_issue.v1.StoreIssueService.ListStoreIssuesCallback} callback Node-style callback called with the error, if any, and ListStoreIssuesResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreIssueService.prototype.listStoreIssues = function listStoreIssues(request, callback) {
                        return this.rpcCall(listStoreIssues, $root.palexy.store_issue.v1.ListStoreIssuesRequest, $root.palexy.store_issue.v1.ListStoreIssuesResponse, request, callback);
                    }, "name", { value: "ListStoreIssues" });
    
                    /**
                     * Calls ListStoreIssues.
                     * @function listStoreIssues
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IListStoreIssuesRequest} request ListStoreIssuesRequest message or plain object
                     * @returns {Promise<palexy.store_issue.v1.ListStoreIssuesResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.store_issue.v1.StoreIssueService#updateStoreIssue}.
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @typedef UpdateStoreIssueCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.store_issue.v1.StoreIssue} [response] StoreIssue
                     */
    
                    /**
                     * Calls UpdateStoreIssue.
                     * @function updateStoreIssue
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IUpdateStoreIssueRequest} request UpdateStoreIssueRequest message or plain object
                     * @param {palexy.store_issue.v1.StoreIssueService.UpdateStoreIssueCallback} callback Node-style callback called with the error, if any, and StoreIssue
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreIssueService.prototype.updateStoreIssue = function updateStoreIssue(request, callback) {
                        return this.rpcCall(updateStoreIssue, $root.palexy.store_issue.v1.UpdateStoreIssueRequest, $root.palexy.store_issue.v1.StoreIssue, request, callback);
                    }, "name", { value: "UpdateStoreIssue" });
    
                    /**
                     * Calls UpdateStoreIssue.
                     * @function updateStoreIssue
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IUpdateStoreIssueRequest} request UpdateStoreIssueRequest message or plain object
                     * @returns {Promise<palexy.store_issue.v1.StoreIssue>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.store_issue.v1.StoreIssueService#deleteStoreIssue}.
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @typedef DeleteStoreIssueCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteStoreIssue.
                     * @function deleteStoreIssue
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IDeleteStoreIssueRequest} request DeleteStoreIssueRequest message or plain object
                     * @param {palexy.store_issue.v1.StoreIssueService.DeleteStoreIssueCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreIssueService.prototype.deleteStoreIssue = function deleteStoreIssue(request, callback) {
                        return this.rpcCall(deleteStoreIssue, $root.palexy.store_issue.v1.DeleteStoreIssueRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteStoreIssue" });
    
                    /**
                     * Calls DeleteStoreIssue.
                     * @function deleteStoreIssue
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IDeleteStoreIssueRequest} request DeleteStoreIssueRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.store_issue.v1.StoreIssueService#getStoreIssueReport}.
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @typedef GetStoreIssueReportCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.store_issue.v1.StoreIssueReportResponse} [response] StoreIssueReportResponse
                     */
    
                    /**
                     * Calls GetStoreIssueReport.
                     * @function getStoreIssueReport
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IStoreIssueReportRequest} request StoreIssueReportRequest message or plain object
                     * @param {palexy.store_issue.v1.StoreIssueService.GetStoreIssueReportCallback} callback Node-style callback called with the error, if any, and StoreIssueReportResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreIssueService.prototype.getStoreIssueReport = function getStoreIssueReport(request, callback) {
                        return this.rpcCall(getStoreIssueReport, $root.palexy.store_issue.v1.StoreIssueReportRequest, $root.palexy.store_issue.v1.StoreIssueReportResponse, request, callback);
                    }, "name", { value: "GetStoreIssueReport" });
    
                    /**
                     * Calls GetStoreIssueReport.
                     * @function getStoreIssueReport
                     * @memberof palexy.store_issue.v1.StoreIssueService
                     * @instance
                     * @param {palexy.store_issue.v1.IStoreIssueReportRequest} request StoreIssueReportRequest message or plain object
                     * @returns {Promise<palexy.store_issue.v1.StoreIssueReportResponse>} Promise
                     * @variation 2
                     */
    
                    return StoreIssueService;
                })();
    
                v1.DeleteStoreIssueRequest = (function() {
    
                    /**
                     * Properties of a DeleteStoreIssueRequest.
                     * @memberof palexy.store_issue.v1
                     * @interface IDeleteStoreIssueRequest
                     * @property {number|null} [issueId] DeleteStoreIssueRequest issueId
                     */
    
                    /**
                     * Constructs a new DeleteStoreIssueRequest.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents a DeleteStoreIssueRequest.
                     * @implements IDeleteStoreIssueRequest
                     * @constructor
                     * @param {palexy.store_issue.v1.IDeleteStoreIssueRequest=} [properties] Properties to set
                     */
                    function DeleteStoreIssueRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteStoreIssueRequest issueId.
                     * @member {number} issueId
                     * @memberof palexy.store_issue.v1.DeleteStoreIssueRequest
                     * @instance
                     */
                    DeleteStoreIssueRequest.prototype.issueId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteStoreIssueRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.store_issue.v1.DeleteStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IDeleteStoreIssueRequest=} [properties] Properties to set
                     * @returns {palexy.store_issue.v1.DeleteStoreIssueRequest} DeleteStoreIssueRequest instance
                     */
                    DeleteStoreIssueRequest.create = function create(properties) {
                        return new DeleteStoreIssueRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteStoreIssueRequest message. Does not implicitly {@link palexy.store_issue.v1.DeleteStoreIssueRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.store_issue.v1.DeleteStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IDeleteStoreIssueRequest} message DeleteStoreIssueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStoreIssueRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.issueId != null && Object.hasOwnProperty.call(message, "issueId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.issueId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteStoreIssueRequest message, length delimited. Does not implicitly {@link palexy.store_issue.v1.DeleteStoreIssueRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.store_issue.v1.DeleteStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IDeleteStoreIssueRequest} message DeleteStoreIssueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteStoreIssueRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteStoreIssueRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.store_issue.v1.DeleteStoreIssueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.store_issue.v1.DeleteStoreIssueRequest} DeleteStoreIssueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStoreIssueRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.store_issue.v1.DeleteStoreIssueRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.issueId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteStoreIssueRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.store_issue.v1.DeleteStoreIssueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.store_issue.v1.DeleteStoreIssueRequest} DeleteStoreIssueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteStoreIssueRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteStoreIssueRequest message.
                     * @function verify
                     * @memberof palexy.store_issue.v1.DeleteStoreIssueRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteStoreIssueRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.issueId != null && message.hasOwnProperty("issueId"))
                            if (!$util.isInteger(message.issueId) && !(message.issueId && $util.isInteger(message.issueId.low) && $util.isInteger(message.issueId.high)))
                                return "issueId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteStoreIssueRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.store_issue.v1.DeleteStoreIssueRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.store_issue.v1.DeleteStoreIssueRequest} DeleteStoreIssueRequest
                     */
                    DeleteStoreIssueRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.store_issue.v1.DeleteStoreIssueRequest)
                            return object;
                        var message = new $root.palexy.store_issue.v1.DeleteStoreIssueRequest();
                        if (object.issueId != null)
                            if ($util.Long)
                                (message.issueId = $util.Long.fromValue(object.issueId)).unsigned = false;
                            else if (typeof object.issueId === "string")
                                message.issueId = parseInt(object.issueId, 10);
                            else if (typeof object.issueId === "number")
                                message.issueId = object.issueId;
                            else if (typeof object.issueId === "object")
                                message.issueId = new $util.LongBits(object.issueId.low >>> 0, object.issueId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteStoreIssueRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.store_issue.v1.DeleteStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.DeleteStoreIssueRequest} message DeleteStoreIssueRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteStoreIssueRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.issueId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.issueId = options.longs === String ? "0" : 0;
                        if (message.issueId != null && message.hasOwnProperty("issueId"))
                            if (typeof message.issueId === "number")
                                object.issueId = options.longs === String ? String(message.issueId) : message.issueId;
                            else
                                object.issueId = options.longs === String ? $util.Long.prototype.toString.call(message.issueId) : options.longs === Number ? new $util.LongBits(message.issueId.low >>> 0, message.issueId.high >>> 0).toNumber() : message.issueId;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteStoreIssueRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.store_issue.v1.DeleteStoreIssueRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteStoreIssueRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteStoreIssueRequest;
                })();
    
                v1.UpdateStoreIssueRequest = (function() {
    
                    /**
                     * Properties of an UpdateStoreIssueRequest.
                     * @memberof palexy.store_issue.v1
                     * @interface IUpdateStoreIssueRequest
                     * @property {palexy.store_issue.v1.IStoreIssue|null} [storeIssue] UpdateStoreIssueRequest storeIssue
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateStoreIssueRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateStoreIssueRequest.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents an UpdateStoreIssueRequest.
                     * @implements IUpdateStoreIssueRequest
                     * @constructor
                     * @param {palexy.store_issue.v1.IUpdateStoreIssueRequest=} [properties] Properties to set
                     */
                    function UpdateStoreIssueRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateStoreIssueRequest storeIssue.
                     * @member {palexy.store_issue.v1.IStoreIssue|null|undefined} storeIssue
                     * @memberof palexy.store_issue.v1.UpdateStoreIssueRequest
                     * @instance
                     */
                    UpdateStoreIssueRequest.prototype.storeIssue = null;
    
                    /**
                     * UpdateStoreIssueRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.store_issue.v1.UpdateStoreIssueRequest
                     * @instance
                     */
                    UpdateStoreIssueRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateStoreIssueRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.store_issue.v1.UpdateStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IUpdateStoreIssueRequest=} [properties] Properties to set
                     * @returns {palexy.store_issue.v1.UpdateStoreIssueRequest} UpdateStoreIssueRequest instance
                     */
                    UpdateStoreIssueRequest.create = function create(properties) {
                        return new UpdateStoreIssueRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateStoreIssueRequest message. Does not implicitly {@link palexy.store_issue.v1.UpdateStoreIssueRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.store_issue.v1.UpdateStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IUpdateStoreIssueRequest} message UpdateStoreIssueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStoreIssueRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeIssue != null && Object.hasOwnProperty.call(message, "storeIssue"))
                            $root.palexy.store_issue.v1.StoreIssue.encode(message.storeIssue, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateStoreIssueRequest message, length delimited. Does not implicitly {@link palexy.store_issue.v1.UpdateStoreIssueRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.store_issue.v1.UpdateStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IUpdateStoreIssueRequest} message UpdateStoreIssueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateStoreIssueRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateStoreIssueRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.store_issue.v1.UpdateStoreIssueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.store_issue.v1.UpdateStoreIssueRequest} UpdateStoreIssueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStoreIssueRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.store_issue.v1.UpdateStoreIssueRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeIssue = $root.palexy.store_issue.v1.StoreIssue.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateStoreIssueRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.store_issue.v1.UpdateStoreIssueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.store_issue.v1.UpdateStoreIssueRequest} UpdateStoreIssueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateStoreIssueRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateStoreIssueRequest message.
                     * @function verify
                     * @memberof palexy.store_issue.v1.UpdateStoreIssueRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateStoreIssueRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeIssue != null && message.hasOwnProperty("storeIssue")) {
                            var error = $root.palexy.store_issue.v1.StoreIssue.verify(message.storeIssue);
                            if (error)
                                return "storeIssue." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateStoreIssueRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.store_issue.v1.UpdateStoreIssueRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.store_issue.v1.UpdateStoreIssueRequest} UpdateStoreIssueRequest
                     */
                    UpdateStoreIssueRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.store_issue.v1.UpdateStoreIssueRequest)
                            return object;
                        var message = new $root.palexy.store_issue.v1.UpdateStoreIssueRequest();
                        if (object.storeIssue != null) {
                            if (typeof object.storeIssue !== "object")
                                throw TypeError(".palexy.store_issue.v1.UpdateStoreIssueRequest.storeIssue: object expected");
                            message.storeIssue = $root.palexy.store_issue.v1.StoreIssue.fromObject(object.storeIssue);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.store_issue.v1.UpdateStoreIssueRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateStoreIssueRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.store_issue.v1.UpdateStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.UpdateStoreIssueRequest} message UpdateStoreIssueRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateStoreIssueRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.storeIssue = null;
                            object.updateMask = null;
                        }
                        if (message.storeIssue != null && message.hasOwnProperty("storeIssue"))
                            object.storeIssue = $root.palexy.store_issue.v1.StoreIssue.toObject(message.storeIssue, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateStoreIssueRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.store_issue.v1.UpdateStoreIssueRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateStoreIssueRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateStoreIssueRequest;
                })();
    
                v1.RaiseStoreIssueRequest = (function() {
    
                    /**
                     * Properties of a RaiseStoreIssueRequest.
                     * @memberof palexy.store_issue.v1
                     * @interface IRaiseStoreIssueRequest
                     * @property {number|null} [storeId] RaiseStoreIssueRequest storeId
                     * @property {palexy.store_issue.v1.StoreIssueType|null} [type] RaiseStoreIssueRequest type
                     * @property {string|null} [description] RaiseStoreIssueRequest description
                     * @property {number|null} [occurredDateId] RaiseStoreIssueRequest occurredDateId
                     */
    
                    /**
                     * Constructs a new RaiseStoreIssueRequest.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents a RaiseStoreIssueRequest.
                     * @implements IRaiseStoreIssueRequest
                     * @constructor
                     * @param {palexy.store_issue.v1.IRaiseStoreIssueRequest=} [properties] Properties to set
                     */
                    function RaiseStoreIssueRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RaiseStoreIssueRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @instance
                     */
                    RaiseStoreIssueRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * RaiseStoreIssueRequest type.
                     * @member {palexy.store_issue.v1.StoreIssueType} type
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @instance
                     */
                    RaiseStoreIssueRequest.prototype.type = 0;
    
                    /**
                     * RaiseStoreIssueRequest description.
                     * @member {string} description
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @instance
                     */
                    RaiseStoreIssueRequest.prototype.description = "";
    
                    /**
                     * RaiseStoreIssueRequest occurredDateId.
                     * @member {number} occurredDateId
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @instance
                     */
                    RaiseStoreIssueRequest.prototype.occurredDateId = 0;
    
                    /**
                     * Creates a new RaiseStoreIssueRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IRaiseStoreIssueRequest=} [properties] Properties to set
                     * @returns {palexy.store_issue.v1.RaiseStoreIssueRequest} RaiseStoreIssueRequest instance
                     */
                    RaiseStoreIssueRequest.create = function create(properties) {
                        return new RaiseStoreIssueRequest(properties);
                    };
    
                    /**
                     * Encodes the specified RaiseStoreIssueRequest message. Does not implicitly {@link palexy.store_issue.v1.RaiseStoreIssueRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IRaiseStoreIssueRequest} message RaiseStoreIssueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RaiseStoreIssueRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                        if (message.occurredDateId != null && Object.hasOwnProperty.call(message, "occurredDateId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.occurredDateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RaiseStoreIssueRequest message, length delimited. Does not implicitly {@link palexy.store_issue.v1.RaiseStoreIssueRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IRaiseStoreIssueRequest} message RaiseStoreIssueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RaiseStoreIssueRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RaiseStoreIssueRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.store_issue.v1.RaiseStoreIssueRequest} RaiseStoreIssueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RaiseStoreIssueRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.store_issue.v1.RaiseStoreIssueRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.type = reader.int32();
                                break;
                            case 3:
                                message.description = reader.string();
                                break;
                            case 4:
                                message.occurredDateId = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RaiseStoreIssueRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.store_issue.v1.RaiseStoreIssueRequest} RaiseStoreIssueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RaiseStoreIssueRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RaiseStoreIssueRequest message.
                     * @function verify
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RaiseStoreIssueRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.occurredDateId != null && message.hasOwnProperty("occurredDateId"))
                            if (!$util.isInteger(message.occurredDateId))
                                return "occurredDateId: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a RaiseStoreIssueRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.store_issue.v1.RaiseStoreIssueRequest} RaiseStoreIssueRequest
                     */
                    RaiseStoreIssueRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.store_issue.v1.RaiseStoreIssueRequest)
                            return object;
                        var message = new $root.palexy.store_issue.v1.RaiseStoreIssueRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        switch (object.type) {
                        case "UNKNOWN_STORE_ISSUE_TYPE":
                        case 0:
                            message.type = 0;
                            break;
                        case "SECTION_CONFIG":
                        case 1:
                            message.type = 1;
                            break;
                        case "UPLOAD_VIDEOS":
                        case 2:
                            message.type = 2;
                            break;
                        case "PREPROCESSING":
                        case 3:
                            message.type = 3;
                            break;
                        }
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.occurredDateId != null)
                            message.occurredDateId = object.occurredDateId | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RaiseStoreIssueRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.RaiseStoreIssueRequest} message RaiseStoreIssueRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RaiseStoreIssueRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.type = options.enums === String ? "UNKNOWN_STORE_ISSUE_TYPE" : 0;
                            object.description = "";
                            object.occurredDateId = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.store_issue.v1.StoreIssueType[message.type] : message.type;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.occurredDateId != null && message.hasOwnProperty("occurredDateId"))
                            object.occurredDateId = message.occurredDateId;
                        return object;
                    };
    
                    /**
                     * Converts this RaiseStoreIssueRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.store_issue.v1.RaiseStoreIssueRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RaiseStoreIssueRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RaiseStoreIssueRequest;
                })();
    
                v1.GetStoreIssueRequest = (function() {
    
                    /**
                     * Properties of a GetStoreIssueRequest.
                     * @memberof palexy.store_issue.v1
                     * @interface IGetStoreIssueRequest
                     * @property {number|null} [issueId] GetStoreIssueRequest issueId
                     */
    
                    /**
                     * Constructs a new GetStoreIssueRequest.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents a GetStoreIssueRequest.
                     * @implements IGetStoreIssueRequest
                     * @constructor
                     * @param {palexy.store_issue.v1.IGetStoreIssueRequest=} [properties] Properties to set
                     */
                    function GetStoreIssueRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetStoreIssueRequest issueId.
                     * @member {number} issueId
                     * @memberof palexy.store_issue.v1.GetStoreIssueRequest
                     * @instance
                     */
                    GetStoreIssueRequest.prototype.issueId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetStoreIssueRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.store_issue.v1.GetStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IGetStoreIssueRequest=} [properties] Properties to set
                     * @returns {palexy.store_issue.v1.GetStoreIssueRequest} GetStoreIssueRequest instance
                     */
                    GetStoreIssueRequest.create = function create(properties) {
                        return new GetStoreIssueRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetStoreIssueRequest message. Does not implicitly {@link palexy.store_issue.v1.GetStoreIssueRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.store_issue.v1.GetStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IGetStoreIssueRequest} message GetStoreIssueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreIssueRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.issueId != null && Object.hasOwnProperty.call(message, "issueId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.issueId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetStoreIssueRequest message, length delimited. Does not implicitly {@link palexy.store_issue.v1.GetStoreIssueRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.store_issue.v1.GetStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.IGetStoreIssueRequest} message GetStoreIssueRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreIssueRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetStoreIssueRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.store_issue.v1.GetStoreIssueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.store_issue.v1.GetStoreIssueRequest} GetStoreIssueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreIssueRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.store_issue.v1.GetStoreIssueRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.issueId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetStoreIssueRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.store_issue.v1.GetStoreIssueRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.store_issue.v1.GetStoreIssueRequest} GetStoreIssueRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreIssueRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetStoreIssueRequest message.
                     * @function verify
                     * @memberof palexy.store_issue.v1.GetStoreIssueRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStoreIssueRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.issueId != null && message.hasOwnProperty("issueId"))
                            if (!$util.isInteger(message.issueId) && !(message.issueId && $util.isInteger(message.issueId.low) && $util.isInteger(message.issueId.high)))
                                return "issueId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetStoreIssueRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.store_issue.v1.GetStoreIssueRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.store_issue.v1.GetStoreIssueRequest} GetStoreIssueRequest
                     */
                    GetStoreIssueRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.store_issue.v1.GetStoreIssueRequest)
                            return object;
                        var message = new $root.palexy.store_issue.v1.GetStoreIssueRequest();
                        if (object.issueId != null)
                            if ($util.Long)
                                (message.issueId = $util.Long.fromValue(object.issueId)).unsigned = false;
                            else if (typeof object.issueId === "string")
                                message.issueId = parseInt(object.issueId, 10);
                            else if (typeof object.issueId === "number")
                                message.issueId = object.issueId;
                            else if (typeof object.issueId === "object")
                                message.issueId = new $util.LongBits(object.issueId.low >>> 0, object.issueId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetStoreIssueRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.store_issue.v1.GetStoreIssueRequest
                     * @static
                     * @param {palexy.store_issue.v1.GetStoreIssueRequest} message GetStoreIssueRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStoreIssueRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.issueId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.issueId = options.longs === String ? "0" : 0;
                        if (message.issueId != null && message.hasOwnProperty("issueId"))
                            if (typeof message.issueId === "number")
                                object.issueId = options.longs === String ? String(message.issueId) : message.issueId;
                            else
                                object.issueId = options.longs === String ? $util.Long.prototype.toString.call(message.issueId) : options.longs === Number ? new $util.LongBits(message.issueId.low >>> 0, message.issueId.high >>> 0).toNumber() : message.issueId;
                        return object;
                    };
    
                    /**
                     * Converts this GetStoreIssueRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.store_issue.v1.GetStoreIssueRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStoreIssueRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetStoreIssueRequest;
                })();
    
                v1.ListStoreIssuesRequest = (function() {
    
                    /**
                     * Properties of a ListStoreIssuesRequest.
                     * @memberof palexy.store_issue.v1
                     * @interface IListStoreIssuesRequest
                     * @property {number|null} [storeId] ListStoreIssuesRequest storeId
                     * @property {number|null} [companyId] ListStoreIssuesRequest companyId
                     * @property {palexy.store_issue.v1.StoreIssueState|null} [issueState] ListStoreIssuesRequest issueState
                     * @property {palexy.store_issue.v1.StoreIssueType|null} [issueType] ListStoreIssuesRequest issueType
                     * @property {number|null} [pageSize] ListStoreIssuesRequest pageSize
                     * @property {string|null} [pageToken] ListStoreIssuesRequest pageToken
                     * @property {string|null} [startDate] ListStoreIssuesRequest startDate
                     * @property {string|null} [endDate] ListStoreIssuesRequest endDate
                     */
    
                    /**
                     * Constructs a new ListStoreIssuesRequest.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents a ListStoreIssuesRequest.
                     * @implements IListStoreIssuesRequest
                     * @constructor
                     * @param {palexy.store_issue.v1.IListStoreIssuesRequest=} [properties] Properties to set
                     */
                    function ListStoreIssuesRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreIssuesRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @instance
                     */
                    ListStoreIssuesRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListStoreIssuesRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @instance
                     */
                    ListStoreIssuesRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListStoreIssuesRequest issueState.
                     * @member {palexy.store_issue.v1.StoreIssueState} issueState
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @instance
                     */
                    ListStoreIssuesRequest.prototype.issueState = 0;
    
                    /**
                     * ListStoreIssuesRequest issueType.
                     * @member {palexy.store_issue.v1.StoreIssueType} issueType
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @instance
                     */
                    ListStoreIssuesRequest.prototype.issueType = 0;
    
                    /**
                     * ListStoreIssuesRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @instance
                     */
                    ListStoreIssuesRequest.prototype.pageSize = 0;
    
                    /**
                     * ListStoreIssuesRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @instance
                     */
                    ListStoreIssuesRequest.prototype.pageToken = "";
    
                    /**
                     * ListStoreIssuesRequest startDate.
                     * @member {string} startDate
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @instance
                     */
                    ListStoreIssuesRequest.prototype.startDate = "";
    
                    /**
                     * ListStoreIssuesRequest endDate.
                     * @member {string} endDate
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @instance
                     */
                    ListStoreIssuesRequest.prototype.endDate = "";
    
                    /**
                     * Creates a new ListStoreIssuesRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @static
                     * @param {palexy.store_issue.v1.IListStoreIssuesRequest=} [properties] Properties to set
                     * @returns {palexy.store_issue.v1.ListStoreIssuesRequest} ListStoreIssuesRequest instance
                     */
                    ListStoreIssuesRequest.create = function create(properties) {
                        return new ListStoreIssuesRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreIssuesRequest message. Does not implicitly {@link palexy.store_issue.v1.ListStoreIssuesRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @static
                     * @param {palexy.store_issue.v1.IListStoreIssuesRequest} message ListStoreIssuesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreIssuesRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.issueState != null && Object.hasOwnProperty.call(message, "issueState"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.issueState);
                        if (message.issueType != null && Object.hasOwnProperty.call(message, "issueType"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.issueType);
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.pageToken);
                        if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.startDate);
                        if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.endDate);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreIssuesRequest message, length delimited. Does not implicitly {@link palexy.store_issue.v1.ListStoreIssuesRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @static
                     * @param {palexy.store_issue.v1.IListStoreIssuesRequest} message ListStoreIssuesRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreIssuesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreIssuesRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.store_issue.v1.ListStoreIssuesRequest} ListStoreIssuesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreIssuesRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.store_issue.v1.ListStoreIssuesRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.issueState = reader.int32();
                                break;
                            case 4:
                                message.issueType = reader.int32();
                                break;
                            case 5:
                                message.pageSize = reader.int32();
                                break;
                            case 6:
                                message.pageToken = reader.string();
                                break;
                            case 7:
                                message.startDate = reader.string();
                                break;
                            case 8:
                                message.endDate = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreIssuesRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.store_issue.v1.ListStoreIssuesRequest} ListStoreIssuesRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreIssuesRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreIssuesRequest message.
                     * @function verify
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreIssuesRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.issueState != null && message.hasOwnProperty("issueState"))
                            switch (message.issueState) {
                            default:
                                return "issueState: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.issueType != null && message.hasOwnProperty("issueType"))
                            switch (message.issueType) {
                            default:
                                return "issueType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreIssuesRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.store_issue.v1.ListStoreIssuesRequest} ListStoreIssuesRequest
                     */
                    ListStoreIssuesRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.store_issue.v1.ListStoreIssuesRequest)
                            return object;
                        var message = new $root.palexy.store_issue.v1.ListStoreIssuesRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        switch (object.issueState) {
                        case "UNKNOWN_STORE_ISSUE_STATE":
                        case 0:
                            message.issueState = 0;
                            break;
                        case "ACTIVE":
                        case 1:
                            message.issueState = 1;
                            break;
                        case "RESOLVED":
                        case 2:
                            message.issueState = 2;
                            break;
                        }
                        switch (object.issueType) {
                        case "UNKNOWN_STORE_ISSUE_TYPE":
                        case 0:
                            message.issueType = 0;
                            break;
                        case "SECTION_CONFIG":
                        case 1:
                            message.issueType = 1;
                            break;
                        case "UPLOAD_VIDEOS":
                        case 2:
                            message.issueType = 2;
                            break;
                        case "PREPROCESSING":
                        case 3:
                            message.issueType = 3;
                            break;
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreIssuesRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @static
                     * @param {palexy.store_issue.v1.ListStoreIssuesRequest} message ListStoreIssuesRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreIssuesRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.issueState = options.enums === String ? "UNKNOWN_STORE_ISSUE_STATE" : 0;
                            object.issueType = options.enums === String ? "UNKNOWN_STORE_ISSUE_TYPE" : 0;
                            object.pageSize = 0;
                            object.pageToken = "";
                            object.startDate = "";
                            object.endDate = "";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.issueState != null && message.hasOwnProperty("issueState"))
                            object.issueState = options.enums === String ? $root.palexy.store_issue.v1.StoreIssueState[message.issueState] : message.issueState;
                        if (message.issueType != null && message.hasOwnProperty("issueType"))
                            object.issueType = options.enums === String ? $root.palexy.store_issue.v1.StoreIssueType[message.issueType] : message.issueType;
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreIssuesRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.store_issue.v1.ListStoreIssuesRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreIssuesRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreIssuesRequest;
                })();
    
                v1.ListStoreIssuesResponse = (function() {
    
                    /**
                     * Properties of a ListStoreIssuesResponse.
                     * @memberof palexy.store_issue.v1
                     * @interface IListStoreIssuesResponse
                     * @property {Array.<palexy.store_issue.v1.IStoreIssue>|null} [issues] ListStoreIssuesResponse issues
                     * @property {string|null} [nextPageToken] ListStoreIssuesResponse nextPageToken
                     * @property {number|null} [totalElements] ListStoreIssuesResponse totalElements
                     */
    
                    /**
                     * Constructs a new ListStoreIssuesResponse.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents a ListStoreIssuesResponse.
                     * @implements IListStoreIssuesResponse
                     * @constructor
                     * @param {palexy.store_issue.v1.IListStoreIssuesResponse=} [properties] Properties to set
                     */
                    function ListStoreIssuesResponse(properties) {
                        this.issues = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListStoreIssuesResponse issues.
                     * @member {Array.<palexy.store_issue.v1.IStoreIssue>} issues
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @instance
                     */
                    ListStoreIssuesResponse.prototype.issues = $util.emptyArray;
    
                    /**
                     * ListStoreIssuesResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @instance
                     */
                    ListStoreIssuesResponse.prototype.nextPageToken = "";
    
                    /**
                     * ListStoreIssuesResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @instance
                     */
                    ListStoreIssuesResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new ListStoreIssuesResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @static
                     * @param {palexy.store_issue.v1.IListStoreIssuesResponse=} [properties] Properties to set
                     * @returns {palexy.store_issue.v1.ListStoreIssuesResponse} ListStoreIssuesResponse instance
                     */
                    ListStoreIssuesResponse.create = function create(properties) {
                        return new ListStoreIssuesResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListStoreIssuesResponse message. Does not implicitly {@link palexy.store_issue.v1.ListStoreIssuesResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @static
                     * @param {palexy.store_issue.v1.IListStoreIssuesResponse} message ListStoreIssuesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreIssuesResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.issues != null && message.issues.length)
                            for (var i = 0; i < message.issues.length; ++i)
                                $root.palexy.store_issue.v1.StoreIssue.encode(message.issues[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nextPageToken);
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalElements);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListStoreIssuesResponse message, length delimited. Does not implicitly {@link palexy.store_issue.v1.ListStoreIssuesResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @static
                     * @param {palexy.store_issue.v1.IListStoreIssuesResponse} message ListStoreIssuesResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListStoreIssuesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListStoreIssuesResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.store_issue.v1.ListStoreIssuesResponse} ListStoreIssuesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreIssuesResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.store_issue.v1.ListStoreIssuesResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.issues && message.issues.length))
                                    message.issues = [];
                                message.issues.push($root.palexy.store_issue.v1.StoreIssue.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.nextPageToken = reader.string();
                                break;
                            case 3:
                                message.totalElements = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListStoreIssuesResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.store_issue.v1.ListStoreIssuesResponse} ListStoreIssuesResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListStoreIssuesResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListStoreIssuesResponse message.
                     * @function verify
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListStoreIssuesResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.issues != null && message.hasOwnProperty("issues")) {
                            if (!Array.isArray(message.issues))
                                return "issues: array expected";
                            for (var i = 0; i < message.issues.length; ++i) {
                                var error = $root.palexy.store_issue.v1.StoreIssue.verify(message.issues[i]);
                                if (error)
                                    return "issues." + error;
                            }
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListStoreIssuesResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.store_issue.v1.ListStoreIssuesResponse} ListStoreIssuesResponse
                     */
                    ListStoreIssuesResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.store_issue.v1.ListStoreIssuesResponse)
                            return object;
                        var message = new $root.palexy.store_issue.v1.ListStoreIssuesResponse();
                        if (object.issues) {
                            if (!Array.isArray(object.issues))
                                throw TypeError(".palexy.store_issue.v1.ListStoreIssuesResponse.issues: array expected");
                            message.issues = [];
                            for (var i = 0; i < object.issues.length; ++i) {
                                if (typeof object.issues[i] !== "object")
                                    throw TypeError(".palexy.store_issue.v1.ListStoreIssuesResponse.issues: object expected");
                                message.issues[i] = $root.palexy.store_issue.v1.StoreIssue.fromObject(object.issues[i]);
                            }
                        }
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListStoreIssuesResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @static
                     * @param {palexy.store_issue.v1.ListStoreIssuesResponse} message ListStoreIssuesResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListStoreIssuesResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.issues = [];
                        if (options.defaults) {
                            object.nextPageToken = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                        }
                        if (message.issues && message.issues.length) {
                            object.issues = [];
                            for (var j = 0; j < message.issues.length; ++j)
                                object.issues[j] = $root.palexy.store_issue.v1.StoreIssue.toObject(message.issues[j], options);
                        }
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        return object;
                    };
    
                    /**
                     * Converts this ListStoreIssuesResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.store_issue.v1.ListStoreIssuesResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListStoreIssuesResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListStoreIssuesResponse;
                })();
    
                /**
                 * StoreIssueType enum.
                 * @name palexy.store_issue.v1.StoreIssueType
                 * @enum {number}
                 * @property {number} UNKNOWN_STORE_ISSUE_TYPE=0 UNKNOWN_STORE_ISSUE_TYPE value
                 * @property {number} SECTION_CONFIG=1 SECTION_CONFIG value
                 * @property {number} UPLOAD_VIDEOS=2 UPLOAD_VIDEOS value
                 * @property {number} PREPROCESSING=3 PREPROCESSING value
                 */
                v1.StoreIssueType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_STORE_ISSUE_TYPE"] = 0;
                    values[valuesById[1] = "SECTION_CONFIG"] = 1;
                    values[valuesById[2] = "UPLOAD_VIDEOS"] = 2;
                    values[valuesById[3] = "PREPROCESSING"] = 3;
                    return values;
                })();
    
                /**
                 * StoreIssueState enum.
                 * @name palexy.store_issue.v1.StoreIssueState
                 * @enum {number}
                 * @property {number} UNKNOWN_STORE_ISSUE_STATE=0 UNKNOWN_STORE_ISSUE_STATE value
                 * @property {number} ACTIVE=1 ACTIVE value
                 * @property {number} RESOLVED=2 RESOLVED value
                 */
                v1.StoreIssueState = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_STORE_ISSUE_STATE"] = 0;
                    values[valuesById[1] = "ACTIVE"] = 1;
                    values[valuesById[2] = "RESOLVED"] = 2;
                    return values;
                })();
    
                v1.StoreIssueReportRequest = (function() {
    
                    /**
                     * Properties of a StoreIssueReportRequest.
                     * @memberof palexy.store_issue.v1
                     * @interface IStoreIssueReportRequest
                     * @property {number|null} [storeId] StoreIssueReportRequest storeId
                     * @property {number|null} [companyId] StoreIssueReportRequest companyId
                     * @property {string|null} [startDate] StoreIssueReportRequest startDate
                     * @property {string|null} [endDate] StoreIssueReportRequest endDate
                     */
    
                    /**
                     * Constructs a new StoreIssueReportRequest.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents a StoreIssueReportRequest.
                     * @implements IStoreIssueReportRequest
                     * @constructor
                     * @param {palexy.store_issue.v1.IStoreIssueReportRequest=} [properties] Properties to set
                     */
                    function StoreIssueReportRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreIssueReportRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @instance
                     */
                    StoreIssueReportRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreIssueReportRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @instance
                     */
                    StoreIssueReportRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * StoreIssueReportRequest startDate.
                     * @member {string} startDate
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @instance
                     */
                    StoreIssueReportRequest.prototype.startDate = "";
    
                    /**
                     * StoreIssueReportRequest endDate.
                     * @member {string} endDate
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @instance
                     */
                    StoreIssueReportRequest.prototype.endDate = "";
    
                    /**
                     * Creates a new StoreIssueReportRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @static
                     * @param {palexy.store_issue.v1.IStoreIssueReportRequest=} [properties] Properties to set
                     * @returns {palexy.store_issue.v1.StoreIssueReportRequest} StoreIssueReportRequest instance
                     */
                    StoreIssueReportRequest.create = function create(properties) {
                        return new StoreIssueReportRequest(properties);
                    };
    
                    /**
                     * Encodes the specified StoreIssueReportRequest message. Does not implicitly {@link palexy.store_issue.v1.StoreIssueReportRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @static
                     * @param {palexy.store_issue.v1.IStoreIssueReportRequest} message StoreIssueReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreIssueReportRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.startDate);
                        if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.endDate);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreIssueReportRequest message, length delimited. Does not implicitly {@link palexy.store_issue.v1.StoreIssueReportRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @static
                     * @param {palexy.store_issue.v1.IStoreIssueReportRequest} message StoreIssueReportRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreIssueReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreIssueReportRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.store_issue.v1.StoreIssueReportRequest} StoreIssueReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreIssueReportRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.store_issue.v1.StoreIssueReportRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.startDate = reader.string();
                                break;
                            case 4:
                                message.endDate = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreIssueReportRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.store_issue.v1.StoreIssueReportRequest} StoreIssueReportRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreIssueReportRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreIssueReportRequest message.
                     * @function verify
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreIssueReportRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a StoreIssueReportRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.store_issue.v1.StoreIssueReportRequest} StoreIssueReportRequest
                     */
                    StoreIssueReportRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.store_issue.v1.StoreIssueReportRequest)
                            return object;
                        var message = new $root.palexy.store_issue.v1.StoreIssueReportRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreIssueReportRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @static
                     * @param {palexy.store_issue.v1.StoreIssueReportRequest} message StoreIssueReportRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreIssueReportRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.startDate = "";
                            object.endDate = "";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        return object;
                    };
    
                    /**
                     * Converts this StoreIssueReportRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.store_issue.v1.StoreIssueReportRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreIssueReportRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StoreIssueReportRequest;
                })();
    
                v1.StoreIssueReportResponse = (function() {
    
                    /**
                     * Properties of a StoreIssueReportResponse.
                     * @memberof palexy.store_issue.v1
                     * @interface IStoreIssueReportResponse
                     * @property {Array.<palexy.store_issue.v1.ICountIssueByType>|null} [countByType] StoreIssueReportResponse countByType
                     * @property {Array.<palexy.store_issue.v1.ICountIssueByState>|null} [countByState] StoreIssueReportResponse countByState
                     */
    
                    /**
                     * Constructs a new StoreIssueReportResponse.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents a StoreIssueReportResponse.
                     * @implements IStoreIssueReportResponse
                     * @constructor
                     * @param {palexy.store_issue.v1.IStoreIssueReportResponse=} [properties] Properties to set
                     */
                    function StoreIssueReportResponse(properties) {
                        this.countByType = [];
                        this.countByState = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * StoreIssueReportResponse countByType.
                     * @member {Array.<palexy.store_issue.v1.ICountIssueByType>} countByType
                     * @memberof palexy.store_issue.v1.StoreIssueReportResponse
                     * @instance
                     */
                    StoreIssueReportResponse.prototype.countByType = $util.emptyArray;
    
                    /**
                     * StoreIssueReportResponse countByState.
                     * @member {Array.<palexy.store_issue.v1.ICountIssueByState>} countByState
                     * @memberof palexy.store_issue.v1.StoreIssueReportResponse
                     * @instance
                     */
                    StoreIssueReportResponse.prototype.countByState = $util.emptyArray;
    
                    /**
                     * Creates a new StoreIssueReportResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.store_issue.v1.StoreIssueReportResponse
                     * @static
                     * @param {palexy.store_issue.v1.IStoreIssueReportResponse=} [properties] Properties to set
                     * @returns {palexy.store_issue.v1.StoreIssueReportResponse} StoreIssueReportResponse instance
                     */
                    StoreIssueReportResponse.create = function create(properties) {
                        return new StoreIssueReportResponse(properties);
                    };
    
                    /**
                     * Encodes the specified StoreIssueReportResponse message. Does not implicitly {@link palexy.store_issue.v1.StoreIssueReportResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.store_issue.v1.StoreIssueReportResponse
                     * @static
                     * @param {palexy.store_issue.v1.IStoreIssueReportResponse} message StoreIssueReportResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreIssueReportResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.countByType != null && message.countByType.length)
                            for (var i = 0; i < message.countByType.length; ++i)
                                $root.palexy.store_issue.v1.CountIssueByType.encode(message.countByType[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.countByState != null && message.countByState.length)
                            for (var i = 0; i < message.countByState.length; ++i)
                                $root.palexy.store_issue.v1.CountIssueByState.encode(message.countByState[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified StoreIssueReportResponse message, length delimited. Does not implicitly {@link palexy.store_issue.v1.StoreIssueReportResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.store_issue.v1.StoreIssueReportResponse
                     * @static
                     * @param {palexy.store_issue.v1.IStoreIssueReportResponse} message StoreIssueReportResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreIssueReportResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a StoreIssueReportResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.store_issue.v1.StoreIssueReportResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.store_issue.v1.StoreIssueReportResponse} StoreIssueReportResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreIssueReportResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.store_issue.v1.StoreIssueReportResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.countByType && message.countByType.length))
                                    message.countByType = [];
                                message.countByType.push($root.palexy.store_issue.v1.CountIssueByType.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.countByState && message.countByState.length))
                                    message.countByState = [];
                                message.countByState.push($root.palexy.store_issue.v1.CountIssueByState.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a StoreIssueReportResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.store_issue.v1.StoreIssueReportResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.store_issue.v1.StoreIssueReportResponse} StoreIssueReportResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreIssueReportResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a StoreIssueReportResponse message.
                     * @function verify
                     * @memberof palexy.store_issue.v1.StoreIssueReportResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreIssueReportResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.countByType != null && message.hasOwnProperty("countByType")) {
                            if (!Array.isArray(message.countByType))
                                return "countByType: array expected";
                            for (var i = 0; i < message.countByType.length; ++i) {
                                var error = $root.palexy.store_issue.v1.CountIssueByType.verify(message.countByType[i]);
                                if (error)
                                    return "countByType." + error;
                            }
                        }
                        if (message.countByState != null && message.hasOwnProperty("countByState")) {
                            if (!Array.isArray(message.countByState))
                                return "countByState: array expected";
                            for (var i = 0; i < message.countByState.length; ++i) {
                                var error = $root.palexy.store_issue.v1.CountIssueByState.verify(message.countByState[i]);
                                if (error)
                                    return "countByState." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a StoreIssueReportResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.store_issue.v1.StoreIssueReportResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.store_issue.v1.StoreIssueReportResponse} StoreIssueReportResponse
                     */
                    StoreIssueReportResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.store_issue.v1.StoreIssueReportResponse)
                            return object;
                        var message = new $root.palexy.store_issue.v1.StoreIssueReportResponse();
                        if (object.countByType) {
                            if (!Array.isArray(object.countByType))
                                throw TypeError(".palexy.store_issue.v1.StoreIssueReportResponse.countByType: array expected");
                            message.countByType = [];
                            for (var i = 0; i < object.countByType.length; ++i) {
                                if (typeof object.countByType[i] !== "object")
                                    throw TypeError(".palexy.store_issue.v1.StoreIssueReportResponse.countByType: object expected");
                                message.countByType[i] = $root.palexy.store_issue.v1.CountIssueByType.fromObject(object.countByType[i]);
                            }
                        }
                        if (object.countByState) {
                            if (!Array.isArray(object.countByState))
                                throw TypeError(".palexy.store_issue.v1.StoreIssueReportResponse.countByState: array expected");
                            message.countByState = [];
                            for (var i = 0; i < object.countByState.length; ++i) {
                                if (typeof object.countByState[i] !== "object")
                                    throw TypeError(".palexy.store_issue.v1.StoreIssueReportResponse.countByState: object expected");
                                message.countByState[i] = $root.palexy.store_issue.v1.CountIssueByState.fromObject(object.countByState[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a StoreIssueReportResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.store_issue.v1.StoreIssueReportResponse
                     * @static
                     * @param {palexy.store_issue.v1.StoreIssueReportResponse} message StoreIssueReportResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreIssueReportResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.countByType = [];
                            object.countByState = [];
                        }
                        if (message.countByType && message.countByType.length) {
                            object.countByType = [];
                            for (var j = 0; j < message.countByType.length; ++j)
                                object.countByType[j] = $root.palexy.store_issue.v1.CountIssueByType.toObject(message.countByType[j], options);
                        }
                        if (message.countByState && message.countByState.length) {
                            object.countByState = [];
                            for (var j = 0; j < message.countByState.length; ++j)
                                object.countByState[j] = $root.palexy.store_issue.v1.CountIssueByState.toObject(message.countByState[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this StoreIssueReportResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.store_issue.v1.StoreIssueReportResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreIssueReportResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return StoreIssueReportResponse;
                })();
    
                v1.CountIssueByType = (function() {
    
                    /**
                     * Properties of a CountIssueByType.
                     * @memberof palexy.store_issue.v1
                     * @interface ICountIssueByType
                     * @property {palexy.store_issue.v1.StoreIssueType|null} [type] CountIssueByType type
                     * @property {number|null} [count] CountIssueByType count
                     */
    
                    /**
                     * Constructs a new CountIssueByType.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents a CountIssueByType.
                     * @implements ICountIssueByType
                     * @constructor
                     * @param {palexy.store_issue.v1.ICountIssueByType=} [properties] Properties to set
                     */
                    function CountIssueByType(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CountIssueByType type.
                     * @member {palexy.store_issue.v1.StoreIssueType} type
                     * @memberof palexy.store_issue.v1.CountIssueByType
                     * @instance
                     */
                    CountIssueByType.prototype.type = 0;
    
                    /**
                     * CountIssueByType count.
                     * @member {number} count
                     * @memberof palexy.store_issue.v1.CountIssueByType
                     * @instance
                     */
                    CountIssueByType.prototype.count = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new CountIssueByType instance using the specified properties.
                     * @function create
                     * @memberof palexy.store_issue.v1.CountIssueByType
                     * @static
                     * @param {palexy.store_issue.v1.ICountIssueByType=} [properties] Properties to set
                     * @returns {palexy.store_issue.v1.CountIssueByType} CountIssueByType instance
                     */
                    CountIssueByType.create = function create(properties) {
                        return new CountIssueByType(properties);
                    };
    
                    /**
                     * Encodes the specified CountIssueByType message. Does not implicitly {@link palexy.store_issue.v1.CountIssueByType.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.store_issue.v1.CountIssueByType
                     * @static
                     * @param {palexy.store_issue.v1.ICountIssueByType} message CountIssueByType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CountIssueByType.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.count);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CountIssueByType message, length delimited. Does not implicitly {@link palexy.store_issue.v1.CountIssueByType.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.store_issue.v1.CountIssueByType
                     * @static
                     * @param {palexy.store_issue.v1.ICountIssueByType} message CountIssueByType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CountIssueByType.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CountIssueByType message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.store_issue.v1.CountIssueByType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.store_issue.v1.CountIssueByType} CountIssueByType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CountIssueByType.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.store_issue.v1.CountIssueByType();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.type = reader.int32();
                                break;
                            case 2:
                                message.count = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CountIssueByType message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.store_issue.v1.CountIssueByType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.store_issue.v1.CountIssueByType} CountIssueByType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CountIssueByType.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CountIssueByType message.
                     * @function verify
                     * @memberof palexy.store_issue.v1.CountIssueByType
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CountIssueByType.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.count != null && message.hasOwnProperty("count"))
                            if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                                return "count: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a CountIssueByType message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.store_issue.v1.CountIssueByType
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.store_issue.v1.CountIssueByType} CountIssueByType
                     */
                    CountIssueByType.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.store_issue.v1.CountIssueByType)
                            return object;
                        var message = new $root.palexy.store_issue.v1.CountIssueByType();
                        switch (object.type) {
                        case "UNKNOWN_STORE_ISSUE_TYPE":
                        case 0:
                            message.type = 0;
                            break;
                        case "SECTION_CONFIG":
                        case 1:
                            message.type = 1;
                            break;
                        case "UPLOAD_VIDEOS":
                        case 2:
                            message.type = 2;
                            break;
                        case "PREPROCESSING":
                        case 3:
                            message.type = 3;
                            break;
                        }
                        if (object.count != null)
                            if ($util.Long)
                                (message.count = $util.Long.fromValue(object.count)).unsigned = false;
                            else if (typeof object.count === "string")
                                message.count = parseInt(object.count, 10);
                            else if (typeof object.count === "number")
                                message.count = object.count;
                            else if (typeof object.count === "object")
                                message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CountIssueByType message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.store_issue.v1.CountIssueByType
                     * @static
                     * @param {palexy.store_issue.v1.CountIssueByType} message CountIssueByType
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CountIssueByType.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.type = options.enums === String ? "UNKNOWN_STORE_ISSUE_TYPE" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.count = options.longs === String ? "0" : 0;
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.store_issue.v1.StoreIssueType[message.type] : message.type;
                        if (message.count != null && message.hasOwnProperty("count"))
                            if (typeof message.count === "number")
                                object.count = options.longs === String ? String(message.count) : message.count;
                            else
                                object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber() : message.count;
                        return object;
                    };
    
                    /**
                     * Converts this CountIssueByType to JSON.
                     * @function toJSON
                     * @memberof palexy.store_issue.v1.CountIssueByType
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CountIssueByType.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CountIssueByType;
                })();
    
                v1.CountIssueByState = (function() {
    
                    /**
                     * Properties of a CountIssueByState.
                     * @memberof palexy.store_issue.v1
                     * @interface ICountIssueByState
                     * @property {palexy.store_issue.v1.StoreIssueState|null} [state] CountIssueByState state
                     * @property {number|null} [count] CountIssueByState count
                     */
    
                    /**
                     * Constructs a new CountIssueByState.
                     * @memberof palexy.store_issue.v1
                     * @classdesc Represents a CountIssueByState.
                     * @implements ICountIssueByState
                     * @constructor
                     * @param {palexy.store_issue.v1.ICountIssueByState=} [properties] Properties to set
                     */
                    function CountIssueByState(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CountIssueByState state.
                     * @member {palexy.store_issue.v1.StoreIssueState} state
                     * @memberof palexy.store_issue.v1.CountIssueByState
                     * @instance
                     */
                    CountIssueByState.prototype.state = 0;
    
                    /**
                     * CountIssueByState count.
                     * @member {number} count
                     * @memberof palexy.store_issue.v1.CountIssueByState
                     * @instance
                     */
                    CountIssueByState.prototype.count = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new CountIssueByState instance using the specified properties.
                     * @function create
                     * @memberof palexy.store_issue.v1.CountIssueByState
                     * @static
                     * @param {palexy.store_issue.v1.ICountIssueByState=} [properties] Properties to set
                     * @returns {palexy.store_issue.v1.CountIssueByState} CountIssueByState instance
                     */
                    CountIssueByState.create = function create(properties) {
                        return new CountIssueByState(properties);
                    };
    
                    /**
                     * Encodes the specified CountIssueByState message. Does not implicitly {@link palexy.store_issue.v1.CountIssueByState.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.store_issue.v1.CountIssueByState
                     * @static
                     * @param {palexy.store_issue.v1.ICountIssueByState} message CountIssueByState message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CountIssueByState.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
                        if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.count);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CountIssueByState message, length delimited. Does not implicitly {@link palexy.store_issue.v1.CountIssueByState.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.store_issue.v1.CountIssueByState
                     * @static
                     * @param {palexy.store_issue.v1.ICountIssueByState} message CountIssueByState message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CountIssueByState.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CountIssueByState message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.store_issue.v1.CountIssueByState
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.store_issue.v1.CountIssueByState} CountIssueByState
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CountIssueByState.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.store_issue.v1.CountIssueByState();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.state = reader.int32();
                                break;
                            case 2:
                                message.count = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CountIssueByState message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.store_issue.v1.CountIssueByState
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.store_issue.v1.CountIssueByState} CountIssueByState
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CountIssueByState.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CountIssueByState message.
                     * @function verify
                     * @memberof palexy.store_issue.v1.CountIssueByState
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CountIssueByState.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.count != null && message.hasOwnProperty("count"))
                            if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                                return "count: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a CountIssueByState message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.store_issue.v1.CountIssueByState
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.store_issue.v1.CountIssueByState} CountIssueByState
                     */
                    CountIssueByState.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.store_issue.v1.CountIssueByState)
                            return object;
                        var message = new $root.palexy.store_issue.v1.CountIssueByState();
                        switch (object.state) {
                        case "UNKNOWN_STORE_ISSUE_STATE":
                        case 0:
                            message.state = 0;
                            break;
                        case "ACTIVE":
                        case 1:
                            message.state = 1;
                            break;
                        case "RESOLVED":
                        case 2:
                            message.state = 2;
                            break;
                        }
                        if (object.count != null)
                            if ($util.Long)
                                (message.count = $util.Long.fromValue(object.count)).unsigned = false;
                            else if (typeof object.count === "string")
                                message.count = parseInt(object.count, 10);
                            else if (typeof object.count === "number")
                                message.count = object.count;
                            else if (typeof object.count === "object")
                                message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CountIssueByState message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.store_issue.v1.CountIssueByState
                     * @static
                     * @param {palexy.store_issue.v1.CountIssueByState} message CountIssueByState
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CountIssueByState.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.state = options.enums === String ? "UNKNOWN_STORE_ISSUE_STATE" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.count = options.longs === String ? "0" : 0;
                        }
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.palexy.store_issue.v1.StoreIssueState[message.state] : message.state;
                        if (message.count != null && message.hasOwnProperty("count"))
                            if (typeof message.count === "number")
                                object.count = options.longs === String ? String(message.count) : message.count;
                            else
                                object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber() : message.count;
                        return object;
                    };
    
                    /**
                     * Converts this CountIssueByState to JSON.
                     * @function toJSON
                     * @memberof palexy.store_issue.v1.CountIssueByState
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CountIssueByState.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CountIssueByState;
                })();
    
                return v1;
            })();
    
            return store_issue;
        })();
    
        palexy.insights = (function() {
    
            /**
             * Namespace insights.
             * @memberof palexy
             * @namespace
             */
            var insights = {};
    
            insights.v1 = (function() {
    
                /**
                 * Namespace v1.
                 * @memberof palexy.insights
                 * @namespace
                 */
                var v1 = {};
    
                v1.InsightsService = (function() {
    
                    /**
                     * Constructs a new InsightsService service.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents an InsightsService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function InsightsService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (InsightsService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = InsightsService;
    
                    /**
                     * Creates new InsightsService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.insights.v1.InsightsService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {InsightsService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    InsightsService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.insights.v1.InsightsService#listInsights}.
                     * @memberof palexy.insights.v1.InsightsService
                     * @typedef ListInsightsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.insights.v1.ListInsightsResponse} [response] ListInsightsResponse
                     */
    
                    /**
                     * Calls ListInsights.
                     * @function listInsights
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IListInsightsRequest} request ListInsightsRequest message or plain object
                     * @param {palexy.insights.v1.InsightsService.ListInsightsCallback} callback Node-style callback called with the error, if any, and ListInsightsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightsService.prototype.listInsights = function listInsights(request, callback) {
                        return this.rpcCall(listInsights, $root.palexy.insights.v1.ListInsightsRequest, $root.palexy.insights.v1.ListInsightsResponse, request, callback);
                    }, "name", { value: "ListInsights" });
    
                    /**
                     * Calls ListInsights.
                     * @function listInsights
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IListInsightsRequest} request ListInsightsRequest message or plain object
                     * @returns {Promise<palexy.insights.v1.ListInsightsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.insights.v1.InsightsService#getInsight}.
                     * @memberof palexy.insights.v1.InsightsService
                     * @typedef GetInsightCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.insights.v1.Insight} [response] Insight
                     */
    
                    /**
                     * Calls GetInsight.
                     * @function getInsight
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IGetInsightRequest} request GetInsightRequest message or plain object
                     * @param {palexy.insights.v1.InsightsService.GetInsightCallback} callback Node-style callback called with the error, if any, and Insight
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightsService.prototype.getInsight = function getInsight(request, callback) {
                        return this.rpcCall(getInsight, $root.palexy.insights.v1.GetInsightRequest, $root.palexy.insights.v1.Insight, request, callback);
                    }, "name", { value: "GetInsight" });
    
                    /**
                     * Calls GetInsight.
                     * @function getInsight
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IGetInsightRequest} request GetInsightRequest message or plain object
                     * @returns {Promise<palexy.insights.v1.Insight>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.insights.v1.InsightsService#updateInsight}.
                     * @memberof palexy.insights.v1.InsightsService
                     * @typedef UpdateInsightCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.insights.v1.Insight} [response] Insight
                     */
    
                    /**
                     * Calls UpdateInsight.
                     * @function updateInsight
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IUpdateInsightRequest} request UpdateInsightRequest message or plain object
                     * @param {palexy.insights.v1.InsightsService.UpdateInsightCallback} callback Node-style callback called with the error, if any, and Insight
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightsService.prototype.updateInsight = function updateInsight(request, callback) {
                        return this.rpcCall(updateInsight, $root.palexy.insights.v1.UpdateInsightRequest, $root.palexy.insights.v1.Insight, request, callback);
                    }, "name", { value: "UpdateInsight" });
    
                    /**
                     * Calls UpdateInsight.
                     * @function updateInsight
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IUpdateInsightRequest} request UpdateInsightRequest message or plain object
                     * @returns {Promise<palexy.insights.v1.Insight>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.insights.v1.InsightsService#deleteInsight}.
                     * @memberof palexy.insights.v1.InsightsService
                     * @typedef DeleteInsightCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteInsight.
                     * @function deleteInsight
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IDeleteInsightRequest} request DeleteInsightRequest message or plain object
                     * @param {palexy.insights.v1.InsightsService.DeleteInsightCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightsService.prototype.deleteInsight = function deleteInsight(request, callback) {
                        return this.rpcCall(deleteInsight, $root.palexy.insights.v1.DeleteInsightRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteInsight" });
    
                    /**
                     * Calls DeleteInsight.
                     * @function deleteInsight
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IDeleteInsightRequest} request DeleteInsightRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.insights.v1.InsightsService#batchGetInsights}.
                     * @memberof palexy.insights.v1.InsightsService
                     * @typedef BatchGetInsightsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.insights.v1.BatchGetInsightsResponse} [response] BatchGetInsightsResponse
                     */
    
                    /**
                     * Calls BatchGetInsights.
                     * @function batchGetInsights
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IBatchGetInsightsRequest} request BatchGetInsightsRequest message or plain object
                     * @param {palexy.insights.v1.InsightsService.BatchGetInsightsCallback} callback Node-style callback called with the error, if any, and BatchGetInsightsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightsService.prototype.batchGetInsights = function batchGetInsights(request, callback) {
                        return this.rpcCall(batchGetInsights, $root.palexy.insights.v1.BatchGetInsightsRequest, $root.palexy.insights.v1.BatchGetInsightsResponse, request, callback);
                    }, "name", { value: "BatchGetInsights" });
    
                    /**
                     * Calls BatchGetInsights.
                     * @function batchGetInsights
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IBatchGetInsightsRequest} request BatchGetInsightsRequest message or plain object
                     * @returns {Promise<palexy.insights.v1.BatchGetInsightsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.insights.v1.InsightsService#batchUpdateInsights}.
                     * @memberof palexy.insights.v1.InsightsService
                     * @typedef BatchUpdateInsightsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls BatchUpdateInsights.
                     * @function batchUpdateInsights
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IBatchUpdateInsightsRequest} request BatchUpdateInsightsRequest message or plain object
                     * @param {palexy.insights.v1.InsightsService.BatchUpdateInsightsCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightsService.prototype.batchUpdateInsights = function batchUpdateInsights(request, callback) {
                        return this.rpcCall(batchUpdateInsights, $root.palexy.insights.v1.BatchUpdateInsightsRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "BatchUpdateInsights" });
    
                    /**
                     * Calls BatchUpdateInsights.
                     * @function batchUpdateInsights
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IBatchUpdateInsightsRequest} request BatchUpdateInsightsRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.insights.v1.InsightsService#batchCreateOrUpdateInsights}.
                     * @memberof palexy.insights.v1.InsightsService
                     * @typedef BatchCreateOrUpdateInsightsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls BatchCreateOrUpdateInsights.
                     * @function batchCreateOrUpdateInsights
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IBatchCreateOrUpdateInsightsRequest} request BatchCreateOrUpdateInsightsRequest message or plain object
                     * @param {palexy.insights.v1.InsightsService.BatchCreateOrUpdateInsightsCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightsService.prototype.batchCreateOrUpdateInsights = function batchCreateOrUpdateInsights(request, callback) {
                        return this.rpcCall(batchCreateOrUpdateInsights, $root.palexy.insights.v1.BatchCreateOrUpdateInsightsRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "BatchCreateOrUpdateInsights" });
    
                    /**
                     * Calls BatchCreateOrUpdateInsights.
                     * @function batchCreateOrUpdateInsights
                     * @memberof palexy.insights.v1.InsightsService
                     * @instance
                     * @param {palexy.insights.v1.IBatchCreateOrUpdateInsightsRequest} request BatchCreateOrUpdateInsightsRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return InsightsService;
                })();
    
                v1.Insight = (function() {
    
                    /**
                     * Properties of an Insight.
                     * @memberof palexy.insights.v1
                     * @interface IInsight
                     * @property {number|null} [id] Insight id
                     * @property {number|null} [companyId] Insight companyId
                     * @property {google.protobuf.IInt64Value|null} [storeId] Insight storeId
                     * @property {palexy.insights.v1.InsightPeriodType|null} [periodType] Insight periodType
                     * @property {number|null} [startDateId] Insight startDateId
                     * @property {number|null} [endDateId] Insight endDateId
                     * @property {palexy.insights.v1.InsightType|null} [type] Insight type
                     * @property {palexy.streaming.v1.MetricEnum|null} [metric] Insight metric
                     * @property {boolean|null} [invalid] Insight invalid
                     * @property {boolean|null} [waitingForRevalidating] Insight waitingForRevalidating
                     * @property {boolean|null} [isTest] Insight isTest
                     * @property {Array.<number>|null} [supportingStoreIds] Insight supportingStoreIds
                     * @property {Array.<palexy.streaming.v1.IDateRange>|null} [supportingDateRanges] Insight supportingDateRanges
                     * @property {Array.<palexy.streaming.v1.DataSource>|null} [dataSources] Insight dataSources
                     * @property {number|null} [financialImpactValue] Insight financialImpactValue
                     * @property {number|null} [createTimeMs] Insight createTimeMs
                     * @property {number|null} [lastUpdateTimeMs] Insight lastUpdateTimeMs
                     * @property {palexy.insights.v1.IThreeWeeksTrendInsight|null} [threeWeeksTrendInsight] Insight threeWeeksTrendInsight
                     */
    
                    /**
                     * Constructs a new Insight.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents an Insight.
                     * @implements IInsight
                     * @constructor
                     * @param {palexy.insights.v1.IInsight=} [properties] Properties to set
                     */
                    function Insight(properties) {
                        this.supportingStoreIds = [];
                        this.supportingDateRanges = [];
                        this.dataSources = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Insight id.
                     * @member {number} id
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Insight companyId.
                     * @member {number} companyId
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Insight storeId.
                     * @member {google.protobuf.IInt64Value|null|undefined} storeId
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.storeId = null;
    
                    /**
                     * Insight periodType.
                     * @member {palexy.insights.v1.InsightPeriodType} periodType
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.periodType = 0;
    
                    /**
                     * Insight startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.startDateId = 0;
    
                    /**
                     * Insight endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.endDateId = 0;
    
                    /**
                     * Insight type.
                     * @member {palexy.insights.v1.InsightType} type
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.type = 0;
    
                    /**
                     * Insight metric.
                     * @member {palexy.streaming.v1.MetricEnum} metric
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.metric = 0;
    
                    /**
                     * Insight invalid.
                     * @member {boolean} invalid
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.invalid = false;
    
                    /**
                     * Insight waitingForRevalidating.
                     * @member {boolean} waitingForRevalidating
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.waitingForRevalidating = false;
    
                    /**
                     * Insight isTest.
                     * @member {boolean} isTest
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.isTest = false;
    
                    /**
                     * Insight supportingStoreIds.
                     * @member {Array.<number>} supportingStoreIds
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.supportingStoreIds = $util.emptyArray;
    
                    /**
                     * Insight supportingDateRanges.
                     * @member {Array.<palexy.streaming.v1.IDateRange>} supportingDateRanges
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.supportingDateRanges = $util.emptyArray;
    
                    /**
                     * Insight dataSources.
                     * @member {Array.<palexy.streaming.v1.DataSource>} dataSources
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.dataSources = $util.emptyArray;
    
                    /**
                     * Insight financialImpactValue.
                     * @member {number} financialImpactValue
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.financialImpactValue = 0;
    
                    /**
                     * Insight createTimeMs.
                     * @member {number} createTimeMs
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.createTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Insight lastUpdateTimeMs.
                     * @member {number} lastUpdateTimeMs
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.lastUpdateTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Insight threeWeeksTrendInsight.
                     * @member {palexy.insights.v1.IThreeWeeksTrendInsight|null|undefined} threeWeeksTrendInsight
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Insight.prototype.threeWeeksTrendInsight = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * Insight insightData.
                     * @member {"threeWeeksTrendInsight"|undefined} insightData
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     */
                    Object.defineProperty(Insight.prototype, "insightData", {
                        get: $util.oneOfGetter($oneOfFields = ["threeWeeksTrendInsight"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new Insight instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.Insight
                     * @static
                     * @param {palexy.insights.v1.IInsight=} [properties] Properties to set
                     * @returns {palexy.insights.v1.Insight} Insight instance
                     */
                    Insight.create = function create(properties) {
                        return new Insight(properties);
                    };
    
                    /**
                     * Encodes the specified Insight message. Does not implicitly {@link palexy.insights.v1.Insight.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.Insight
                     * @static
                     * @param {palexy.insights.v1.IInsight} message Insight message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Insight.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            $root.google.protobuf.Int64Value.encode(message.storeId, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.periodType != null && Object.hasOwnProperty.call(message, "periodType"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.periodType);
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.endDateId);
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.type);
                        if (message.metric != null && Object.hasOwnProperty.call(message, "metric"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.metric);
                        if (message.invalid != null && Object.hasOwnProperty.call(message, "invalid"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.invalid);
                        if (message.waitingForRevalidating != null && Object.hasOwnProperty.call(message, "waitingForRevalidating"))
                            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.waitingForRevalidating);
                        if (message.isTest != null && Object.hasOwnProperty.call(message, "isTest"))
                            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isTest);
                        if (message.supportingStoreIds != null && message.supportingStoreIds.length) {
                            writer.uint32(/* id 12, wireType 2 =*/98).fork();
                            for (var i = 0; i < message.supportingStoreIds.length; ++i)
                                writer.int64(message.supportingStoreIds[i]);
                            writer.ldelim();
                        }
                        if (message.supportingDateRanges != null && message.supportingDateRanges.length)
                            for (var i = 0; i < message.supportingDateRanges.length; ++i)
                                $root.palexy.streaming.v1.DateRange.encode(message.supportingDateRanges[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.dataSources != null && message.dataSources.length) {
                            writer.uint32(/* id 14, wireType 2 =*/114).fork();
                            for (var i = 0; i < message.dataSources.length; ++i)
                                writer.int32(message.dataSources[i]);
                            writer.ldelim();
                        }
                        if (message.financialImpactValue != null && Object.hasOwnProperty.call(message, "financialImpactValue"))
                            writer.uint32(/* id 15, wireType 1 =*/121).double(message.financialImpactValue);
                        if (message.createTimeMs != null && Object.hasOwnProperty.call(message, "createTimeMs"))
                            writer.uint32(/* id 16, wireType 0 =*/128).int64(message.createTimeMs);
                        if (message.lastUpdateTimeMs != null && Object.hasOwnProperty.call(message, "lastUpdateTimeMs"))
                            writer.uint32(/* id 17, wireType 0 =*/136).int64(message.lastUpdateTimeMs);
                        if (message.threeWeeksTrendInsight != null && Object.hasOwnProperty.call(message, "threeWeeksTrendInsight"))
                            $root.palexy.insights.v1.ThreeWeeksTrendInsight.encode(message.threeWeeksTrendInsight, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Insight message, length delimited. Does not implicitly {@link palexy.insights.v1.Insight.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.Insight
                     * @static
                     * @param {palexy.insights.v1.IInsight} message Insight message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Insight.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Insight message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.Insight
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.Insight} Insight
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Insight.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.Insight();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                message.storeId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.periodType = reader.int32();
                                break;
                            case 5:
                                message.startDateId = reader.int32();
                                break;
                            case 6:
                                message.endDateId = reader.int32();
                                break;
                            case 7:
                                message.type = reader.int32();
                                break;
                            case 8:
                                message.metric = reader.int32();
                                break;
                            case 9:
                                message.invalid = reader.bool();
                                break;
                            case 10:
                                message.waitingForRevalidating = reader.bool();
                                break;
                            case 11:
                                message.isTest = reader.bool();
                                break;
                            case 12:
                                if (!(message.supportingStoreIds && message.supportingStoreIds.length))
                                    message.supportingStoreIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.supportingStoreIds.push(reader.int64());
                                } else
                                    message.supportingStoreIds.push(reader.int64());
                                break;
                            case 13:
                                if (!(message.supportingDateRanges && message.supportingDateRanges.length))
                                    message.supportingDateRanges = [];
                                message.supportingDateRanges.push($root.palexy.streaming.v1.DateRange.decode(reader, reader.uint32()));
                                break;
                            case 14:
                                if (!(message.dataSources && message.dataSources.length))
                                    message.dataSources = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.dataSources.push(reader.int32());
                                } else
                                    message.dataSources.push(reader.int32());
                                break;
                            case 15:
                                message.financialImpactValue = reader.double();
                                break;
                            case 16:
                                message.createTimeMs = reader.int64();
                                break;
                            case 17:
                                message.lastUpdateTimeMs = reader.int64();
                                break;
                            case 100:
                                message.threeWeeksTrendInsight = $root.palexy.insights.v1.ThreeWeeksTrendInsight.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Insight message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.Insight
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.Insight} Insight
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Insight.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Insight message.
                     * @function verify
                     * @memberof palexy.insights.v1.Insight
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Insight.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.storeId);
                            if (error)
                                return "storeId." + error;
                        }
                        if (message.periodType != null && message.hasOwnProperty("periodType"))
                            switch (message.periodType) {
                            default:
                                return "periodType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.metric != null && message.hasOwnProperty("metric"))
                            switch (message.metric) {
                            default:
                                return "metric: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                            case 22:
                            case 23:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 28:
                            case 29:
                            case 30:
                            case 31:
                            case 32:
                            case 33:
                            case 34:
                            case 35:
                            case 36:
                            case 37:
                            case 38:
                            case 39:
                            case 40:
                            case 41:
                            case 42:
                            case 43:
                            case 44:
                            case 45:
                            case 46:
                            case 47:
                            case 48:
                            case 49:
                            case 50:
                            case 51:
                            case 52:
                            case 53:
                            case 54:
                            case 55:
                            case 56:
                            case 57:
                            case 58:
                            case 59:
                            case 60:
                            case 61:
                            case 62:
                            case 63:
                            case 64:
                            case 65:
                            case 66:
                            case 67:
                            case 68:
                            case 69:
                            case 70:
                            case 71:
                            case 72:
                            case 73:
                            case 74:
                            case 75:
                            case 76:
                            case 77:
                            case 78:
                            case 79:
                            case 80:
                            case 81:
                            case 82:
                            case 83:
                            case 84:
                            case 85:
                            case 86:
                            case 87:
                            case 88:
                            case 89:
                            case 90:
                            case 91:
                            case 92:
                            case 93:
                            case 94:
                            case 95:
                            case 96:
                            case 97:
                            case 98:
                            case 99:
                            case 100:
                            case 101:
                            case 102:
                            case 103:
                            case 104:
                            case 105:
                            case 106:
                                break;
                            }
                        if (message.invalid != null && message.hasOwnProperty("invalid"))
                            if (typeof message.invalid !== "boolean")
                                return "invalid: boolean expected";
                        if (message.waitingForRevalidating != null && message.hasOwnProperty("waitingForRevalidating"))
                            if (typeof message.waitingForRevalidating !== "boolean")
                                return "waitingForRevalidating: boolean expected";
                        if (message.isTest != null && message.hasOwnProperty("isTest"))
                            if (typeof message.isTest !== "boolean")
                                return "isTest: boolean expected";
                        if (message.supportingStoreIds != null && message.hasOwnProperty("supportingStoreIds")) {
                            if (!Array.isArray(message.supportingStoreIds))
                                return "supportingStoreIds: array expected";
                            for (var i = 0; i < message.supportingStoreIds.length; ++i)
                                if (!$util.isInteger(message.supportingStoreIds[i]) && !(message.supportingStoreIds[i] && $util.isInteger(message.supportingStoreIds[i].low) && $util.isInteger(message.supportingStoreIds[i].high)))
                                    return "supportingStoreIds: integer|Long[] expected";
                        }
                        if (message.supportingDateRanges != null && message.hasOwnProperty("supportingDateRanges")) {
                            if (!Array.isArray(message.supportingDateRanges))
                                return "supportingDateRanges: array expected";
                            for (var i = 0; i < message.supportingDateRanges.length; ++i) {
                                var error = $root.palexy.streaming.v1.DateRange.verify(message.supportingDateRanges[i]);
                                if (error)
                                    return "supportingDateRanges." + error;
                            }
                        }
                        if (message.dataSources != null && message.hasOwnProperty("dataSources")) {
                            if (!Array.isArray(message.dataSources))
                                return "dataSources: array expected";
                            for (var i = 0; i < message.dataSources.length; ++i)
                                switch (message.dataSources[i]) {
                                default:
                                    return "dataSources: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                    break;
                                }
                        }
                        if (message.financialImpactValue != null && message.hasOwnProperty("financialImpactValue"))
                            if (typeof message.financialImpactValue !== "number")
                                return "financialImpactValue: number expected";
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (!$util.isInteger(message.createTimeMs) && !(message.createTimeMs && $util.isInteger(message.createTimeMs.low) && $util.isInteger(message.createTimeMs.high)))
                                return "createTimeMs: integer|Long expected";
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (!$util.isInteger(message.lastUpdateTimeMs) && !(message.lastUpdateTimeMs && $util.isInteger(message.lastUpdateTimeMs.low) && $util.isInteger(message.lastUpdateTimeMs.high)))
                                return "lastUpdateTimeMs: integer|Long expected";
                        if (message.threeWeeksTrendInsight != null && message.hasOwnProperty("threeWeeksTrendInsight")) {
                            properties.insightData = 1;
                            {
                                var error = $root.palexy.insights.v1.ThreeWeeksTrendInsight.verify(message.threeWeeksTrendInsight);
                                if (error)
                                    return "threeWeeksTrendInsight." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates an Insight message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.Insight
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.Insight} Insight
                     */
                    Insight.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.Insight)
                            return object;
                        var message = new $root.palexy.insights.v1.Insight();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeId != null) {
                            if (typeof object.storeId !== "object")
                                throw TypeError(".palexy.insights.v1.Insight.storeId: object expected");
                            message.storeId = $root.google.protobuf.Int64Value.fromObject(object.storeId);
                        }
                        switch (object.periodType) {
                        case "INSIGHT_PERIOD_TYPE_UNKNOWN":
                        case 0:
                            message.periodType = 0;
                            break;
                        case "THIS_WEEK":
                        case 1:
                            message.periodType = 1;
                            break;
                        case "LAST_WEEK":
                        case 2:
                            message.periodType = 2;
                            break;
                        case "THIS_MONTH":
                        case 3:
                            message.periodType = 3;
                            break;
                        case "LAST_MONTH":
                        case 4:
                            message.periodType = 4;
                            break;
                        }
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        switch (object.type) {
                        case "INSIGHT_TYPE_UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "THREE_WEEKS_TREND":
                        case 1:
                            message.type = 1;
                            break;
                        }
                        switch (object.metric) {
                        case "METRIC_UNKNOWN":
                        case 0:
                            message.metric = 0;
                            break;
                        case "METRIC_BUYER_TRAFFIC":
                        case 1:
                            message.metric = 1;
                            break;
                        case "METRIC_BUYER_TRAFFIC_WITH_INTERACTION":
                        case 2:
                            message.metric = 2;
                            break;
                        case "METRIC_INTERACTION":
                        case 3:
                            message.metric = 3;
                            break;
                        case "METRIC_VISIT":
                        case 4:
                            message.metric = 4;
                            break;
                        case "METRIC_GROUP_VISIT":
                        case 5:
                            message.metric = 5;
                            break;
                        case "METRIC_GROUP_INTERACTION":
                        case 6:
                            message.metric = 6;
                            break;
                        case "METRIC_GROUP_BUYER_TRAFFIC":
                        case 7:
                            message.metric = 7;
                            break;
                        case "METRIC_GROUP_BUYER_TRAFFIC_WITH_INTERACTION":
                        case 8:
                            message.metric = 8;
                            break;
                        case "METRIC_BUYER_RATE":
                        case 9:
                            message.metric = 9;
                            break;
                        case "METRIC_INTERACTION_RATE":
                        case 10:
                            message.metric = 10;
                            break;
                        case "METRIC_INTERACTION_TIME":
                        case 11:
                            message.metric = 11;
                            break;
                        case "METRIC_INTERACTION_TIME_AVG":
                        case 12:
                            message.metric = 12;
                            break;
                        case "METRIC_INTERACTION_LEVEL":
                        case 13:
                            message.metric = 13;
                            break;
                        case "METRIC_INTERACTION_BUYER_RATE":
                        case 14:
                            message.metric = 14;
                            break;
                        case "METRIC_AVG_TIME_TO_FIRST_INTERACTION":
                        case 15:
                            message.metric = 15;
                            break;
                        case "METRIC_ON_TIME_INTERACTION_RATE":
                        case 16:
                            message.metric = 16;
                            break;
                        case "METRIC_GROUP_BUYER_RATE":
                        case 17:
                            message.metric = 17;
                            break;
                        case "METRIC_GROUP_INTERACTION_RATE":
                        case 18:
                            message.metric = 18;
                            break;
                        case "METRIC_GROUP_INTERACTION_BUYER_RATE":
                        case 19:
                            message.metric = 19;
                            break;
                        case "METRIC_LYING":
                        case 20:
                            message.metric = 20;
                            break;
                        case "METRIC_LYING_RATE":
                        case 21:
                            message.metric = 21;
                            break;
                        case "METRIC_LYING_TIME_AVG":
                        case 22:
                            message.metric = 22;
                            break;
                        case "METRIC_LYING_EXACT_ONE":
                        case 23:
                            message.metric = 23;
                            break;
                        case "METRIC_LYING_AT_LEAST_ONE":
                        case 24:
                            message.metric = 24;
                            break;
                        case "METRIC_LYING_AT_LEAST_TWO":
                        case 25:
                            message.metric = 25;
                            break;
                        case "METRIC_DWELL_TIME":
                        case 26:
                            message.metric = 26;
                            break;
                        case "METRIC_DWELL_TIME_AVG":
                        case 27:
                            message.metric = 27;
                            break;
                        case "METRIC_TOTAL_GREETED_CUSTOMERS":
                        case 28:
                            message.metric = 28;
                            break;
                        case "METRIC_GREETING_RATE":
                        case 29:
                            message.metric = 29;
                            break;
                        case "METRIC_AVG_TIME_TO_FIRST_GREETING":
                        case 30:
                            message.metric = 30;
                            break;
                        case "METRIC_ON_TIME_GREETING_RATE":
                        case 31:
                            message.metric = 31;
                            break;
                        case "METRIC_FOOT_TRAFFIC":
                        case 32:
                            message.metric = 32;
                            break;
                        case "METRIC_VISITOR_OCCUPANCY":
                        case 33:
                            message.metric = 33;
                            break;
                        case "METRIC_TOTAL_GROUPS":
                        case 34:
                            message.metric = 34;
                            break;
                        case "METRIC_TOTAL_VISITORS_GOING_AS_GROUPS":
                        case 35:
                            message.metric = 35;
                            break;
                        case "METRIC_GROUP_RATE":
                        case 36:
                            message.metric = 36;
                            break;
                        case "METRIC_AVG_GROUP_SIZE":
                        case 37:
                            message.metric = 37;
                            break;
                        case "METRIC_TOTAL_GROUPS_WITH_KIDS":
                        case 38:
                            message.metric = 38;
                            break;
                        case "METRIC_TOTAL_KNOWN_GROUPS":
                        case 39:
                            message.metric = 39;
                            break;
                        case "METRIC_GROUPS_WITH_KIDS_RATE":
                        case 40:
                            message.metric = 40;
                            break;
                        case "METRIC_TOTAL_GROUPS_AND_INDIVIDUALS":
                        case 41:
                            message.metric = 41;
                            break;
                        case "METRIC_CONVERSION_BY_OPPORTUNITIES":
                        case 42:
                            message.metric = 42;
                            break;
                        case "METRIC_PERCENT_SECTION_VISIT":
                        case 43:
                            message.metric = 43;
                            break;
                        case "METRIC_PERCENT_SECTION_PASS_BY":
                        case 44:
                            message.metric = 44;
                            break;
                        case "METRIC_PASS_BY":
                        case 45:
                            message.metric = 45;
                            break;
                        case "METRIC_CAPTURE_RATE":
                        case 46:
                            message.metric = 46;
                            break;
                        case "METRIC_TRANSACTION":
                        case 47:
                            message.metric = 47;
                            break;
                        case "METRIC_CONVERSION_RATE":
                        case 48:
                            message.metric = 48;
                            break;
                        case "METRIC_NET_SALES_AMOUNT":
                        case 49:
                            message.metric = 49;
                            break;
                        case "METRIC_NET_SALES_ITEMS":
                        case 50:
                            message.metric = 50;
                            break;
                        case "METRIC_NET_SALES_CONTRIBUTION":
                        case 51:
                            message.metric = 51;
                            break;
                        case "METRIC_NET_SALES_RANKING":
                        case 52:
                            message.metric = 52;
                            break;
                        case "METRIC_SHOPPER_YIELD":
                        case 53:
                            message.metric = 53;
                            break;
                        case "METRIC_ATV":
                        case 54:
                            message.metric = 54;
                            break;
                        case "METRIC_UPT":
                        case 55:
                            message.metric = 55;
                            break;
                        case "METRIC_UPDPS":
                        case 56:
                            message.metric = 56;
                            break;
                        case "METRIC_ASP":
                        case 57:
                            message.metric = 57;
                            break;
                        case "METRIC_STAFF_HOURS":
                        case 58:
                            message.metric = 58;
                            break;
                        case "METRIC_VISITOR_PER_STAFF_HOUR":
                        case 59:
                            message.metric = 59;
                            break;
                        case "METRIC_SALES_PER_STAFF_HOUR":
                        case 60:
                            message.metric = 60;
                            break;
                        case "METRIC_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                        case 61:
                            message.metric = 61;
                            break;
                        case "METRIC_MAX_VISITORS_PER_STAFF_IN_HOUR":
                        case 62:
                            message.metric = 62;
                            break;
                        case "METRIC_FORECAST_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                        case 63:
                            message.metric = 63;
                            break;
                        case "METRIC_FORECAST_MAX_VISITORS_PER_STAFF_IN_HOUR":
                        case 64:
                            message.metric = 64;
                            break;
                        case "METRIC_ROUNDED_STAFF_HOURS":
                        case 65:
                            message.metric = 65;
                            break;
                        case "METRIC_STAFF_HOUR_WITH_VISITOR_COUNT":
                        case 66:
                            message.metric = 66;
                            break;
                        case "METRIC_VISITORS_PER_SQM":
                        case 67:
                            message.metric = 67;
                            break;
                        case "METRIC_AVG_VISITOR_PER_STORE_PER_DAY":
                        case 68:
                            message.metric = 68;
                            break;
                        case "METRIC_DAYS_WITH_VISITORS":
                        case 69:
                            message.metric = 69;
                            break;
                        case "METRIC_DAYS_WITH_NET_SALES_ITEMS":
                        case 70:
                            message.metric = 70;
                            break;
                        case "METRIC_SALES_PER_SQM":
                        case 71:
                            message.metric = 71;
                            break;
                        case "METRIC_SALES_PER_SALES_AREA_SQM":
                        case 72:
                            message.metric = 72;
                            break;
                        case "METRIC_ESTIMATED_VISIT":
                        case 73:
                            message.metric = 73;
                            break;
                        case "METRIC_ESTIMATED_PASS_BY":
                        case 74:
                            message.metric = 74;
                            break;
                        case "METRIC_WALK_IN":
                        case 75:
                            message.metric = 75;
                            break;
                        case "METRIC_PERCENT_SECTION_ESTIMATED_PASS_BY":
                        case 76:
                            message.metric = 76;
                            break;
                        case "METRIC_CUSTOM_ACTION_1":
                        case 77:
                            message.metric = 77;
                            break;
                        case "METRIC_CUSTOM_ACTION_1_RATE":
                        case 78:
                            message.metric = 78;
                            break;
                        case "METRIC_CONVERTED_CUSTOM_ACTION_1_RATE":
                        case 79:
                            message.metric = 79;
                            break;
                        case "METRIC_AVG_SALES_PER_STORE_PER_DAY":
                        case 80:
                            message.metric = 80;
                            break;
                        case "METRIC_STAFF_TOTAL_SERVED_VISITORS":
                        case 81:
                            message.metric = 81;
                            break;
                        case "METRIC_STAFF_CONVERSION_RATE":
                        case 82:
                            message.metric = 82;
                            break;
                        case "METRIC_STAFF_SALES_PER_SERVED_VISITORS":
                        case 83:
                            message.metric = 83;
                            break;
                        case "METRIC_STAFF_SERVED_VISITORS_PER_STAFF_HOUR":
                        case 84:
                            message.metric = 84;
                            break;
                        case "METRIC_STAFF_PERCENTAGE_OF_SALES":
                        case 85:
                            message.metric = 85;
                            break;
                        case "METRIC_DAYS_WITH_SALES":
                        case 86:
                            message.metric = 86;
                            break;
                        case "METRIC_VIDEO_GROUP_DISTRIBUTION":
                        case 87:
                            message.metric = 87;
                            break;
                        case "METRIC_BOUNCE_RATE":
                        case 88:
                            message.metric = 88;
                            break;
                        case "METRIC_AVG_MALL_VISITOR_PER_STORE_PER_DAY":
                        case 89:
                            message.metric = 89;
                            break;
                        case "METRIC_TOTAL_ON_TIME_GREETED_CUSTOMERS":
                        case 90:
                            message.metric = 90;
                            break;
                        case "METRIC_STAFF_STORE_NET_SALES_AMOUNT":
                        case 91:
                            message.metric = 91;
                            break;
                        case "METRIC_STAFF_UNIQUE_INTERACTIONS":
                        case 92:
                            message.metric = 92;
                            break;
                        case "METRIC_STAFF_INTERACTION_RATE":
                        case 93:
                            message.metric = 93;
                            break;
                        case "METRIC_TOTAL_CHECKOUT_VISITORS":
                        case 94:
                            message.metric = 94;
                            break;
                        case "METRIC_MAX_CONCURRENT_CHECKOUT_VISITORS":
                        case 95:
                            message.metric = 95;
                            break;
                        case "METRIC_AVG_CONCURRENT_CHECKOUT_VISITORS":
                        case 96:
                            message.metric = 96;
                            break;
                        case "METRIC_AVG_CHECKOUT_TIME":
                        case 97:
                            message.metric = 97;
                            break;
                        case "METRIC_MAX_CHECKOUT_TIME":
                        case 98:
                            message.metric = 98;
                            break;
                        case "METRIC_TOTAL_CHECKOUT_OPPORTUNITIES":
                        case 99:
                            message.metric = 99;
                            break;
                        case "METRIC_MAX_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                        case 100:
                            message.metric = 100;
                            break;
                        case "METRIC_AVG_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                        case 101:
                            message.metric = 101;
                            break;
                        case "METRIC_AVG_CHECKOUT_TIME_BY_OPPORTUNITIES":
                        case 102:
                            message.metric = 102;
                            break;
                        case "METRIC_MAX_CHECKOUT_TIME_BY_OPPORTUNITIES":
                        case 103:
                            message.metric = 103;
                            break;
                        case "METRIC_DISCOUNT_AMOUNT":
                        case 104:
                            message.metric = 104;
                            break;
                        case "METRIC_DISCOUNT_RATE":
                        case 105:
                            message.metric = 105;
                            break;
                        case "METRIC_GROSS_AMOUNT":
                        case 106:
                            message.metric = 106;
                            break;
                        }
                        if (object.invalid != null)
                            message.invalid = Boolean(object.invalid);
                        if (object.waitingForRevalidating != null)
                            message.waitingForRevalidating = Boolean(object.waitingForRevalidating);
                        if (object.isTest != null)
                            message.isTest = Boolean(object.isTest);
                        if (object.supportingStoreIds) {
                            if (!Array.isArray(object.supportingStoreIds))
                                throw TypeError(".palexy.insights.v1.Insight.supportingStoreIds: array expected");
                            message.supportingStoreIds = [];
                            for (var i = 0; i < object.supportingStoreIds.length; ++i)
                                if ($util.Long)
                                    (message.supportingStoreIds[i] = $util.Long.fromValue(object.supportingStoreIds[i])).unsigned = false;
                                else if (typeof object.supportingStoreIds[i] === "string")
                                    message.supportingStoreIds[i] = parseInt(object.supportingStoreIds[i], 10);
                                else if (typeof object.supportingStoreIds[i] === "number")
                                    message.supportingStoreIds[i] = object.supportingStoreIds[i];
                                else if (typeof object.supportingStoreIds[i] === "object")
                                    message.supportingStoreIds[i] = new $util.LongBits(object.supportingStoreIds[i].low >>> 0, object.supportingStoreIds[i].high >>> 0).toNumber();
                        }
                        if (object.supportingDateRanges) {
                            if (!Array.isArray(object.supportingDateRanges))
                                throw TypeError(".palexy.insights.v1.Insight.supportingDateRanges: array expected");
                            message.supportingDateRanges = [];
                            for (var i = 0; i < object.supportingDateRanges.length; ++i) {
                                if (typeof object.supportingDateRanges[i] !== "object")
                                    throw TypeError(".palexy.insights.v1.Insight.supportingDateRanges: object expected");
                                message.supportingDateRanges[i] = $root.palexy.streaming.v1.DateRange.fromObject(object.supportingDateRanges[i]);
                            }
                        }
                        if (object.dataSources) {
                            if (!Array.isArray(object.dataSources))
                                throw TypeError(".palexy.insights.v1.Insight.dataSources: array expected");
                            message.dataSources = [];
                            for (var i = 0; i < object.dataSources.length; ++i)
                                switch (object.dataSources[i]) {
                                default:
                                case "DS_UNKNOWN":
                                case 0:
                                    message.dataSources[i] = 0;
                                    break;
                                case "VIDEO":
                                case 1:
                                    message.dataSources[i] = 1;
                                    break;
                                case "POS":
                                case 2:
                                    message.dataSources[i] = 2;
                                    break;
                                case "STAFFING":
                                case 3:
                                    message.dataSources[i] = 3;
                                    break;
                                case "FORECAST":
                                case 4:
                                    message.dataSources[i] = 4;
                                    break;
                                case "TARGET":
                                case 5:
                                    message.dataSources[i] = 5;
                                    break;
                                }
                        }
                        if (object.financialImpactValue != null)
                            message.financialImpactValue = Number(object.financialImpactValue);
                        if (object.createTimeMs != null)
                            if ($util.Long)
                                (message.createTimeMs = $util.Long.fromValue(object.createTimeMs)).unsigned = false;
                            else if (typeof object.createTimeMs === "string")
                                message.createTimeMs = parseInt(object.createTimeMs, 10);
                            else if (typeof object.createTimeMs === "number")
                                message.createTimeMs = object.createTimeMs;
                            else if (typeof object.createTimeMs === "object")
                                message.createTimeMs = new $util.LongBits(object.createTimeMs.low >>> 0, object.createTimeMs.high >>> 0).toNumber();
                        if (object.lastUpdateTimeMs != null)
                            if ($util.Long)
                                (message.lastUpdateTimeMs = $util.Long.fromValue(object.lastUpdateTimeMs)).unsigned = false;
                            else if (typeof object.lastUpdateTimeMs === "string")
                                message.lastUpdateTimeMs = parseInt(object.lastUpdateTimeMs, 10);
                            else if (typeof object.lastUpdateTimeMs === "number")
                                message.lastUpdateTimeMs = object.lastUpdateTimeMs;
                            else if (typeof object.lastUpdateTimeMs === "object")
                                message.lastUpdateTimeMs = new $util.LongBits(object.lastUpdateTimeMs.low >>> 0, object.lastUpdateTimeMs.high >>> 0).toNumber();
                        if (object.threeWeeksTrendInsight != null) {
                            if (typeof object.threeWeeksTrendInsight !== "object")
                                throw TypeError(".palexy.insights.v1.Insight.threeWeeksTrendInsight: object expected");
                            message.threeWeeksTrendInsight = $root.palexy.insights.v1.ThreeWeeksTrendInsight.fromObject(object.threeWeeksTrendInsight);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an Insight message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.Insight
                     * @static
                     * @param {palexy.insights.v1.Insight} message Insight
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Insight.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.supportingStoreIds = [];
                            object.supportingDateRanges = [];
                            object.dataSources = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.storeId = null;
                            object.periodType = options.enums === String ? "INSIGHT_PERIOD_TYPE_UNKNOWN" : 0;
                            object.startDateId = 0;
                            object.endDateId = 0;
                            object.type = options.enums === String ? "INSIGHT_TYPE_UNKNOWN" : 0;
                            object.metric = options.enums === String ? "METRIC_UNKNOWN" : 0;
                            object.invalid = false;
                            object.waitingForRevalidating = false;
                            object.isTest = false;
                            object.financialImpactValue = 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.createTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.createTimeMs = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastUpdateTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastUpdateTimeMs = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = $root.google.protobuf.Int64Value.toObject(message.storeId, options);
                        if (message.periodType != null && message.hasOwnProperty("periodType"))
                            object.periodType = options.enums === String ? $root.palexy.insights.v1.InsightPeriodType[message.periodType] : message.periodType;
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.insights.v1.InsightType[message.type] : message.type;
                        if (message.metric != null && message.hasOwnProperty("metric"))
                            object.metric = options.enums === String ? $root.palexy.streaming.v1.MetricEnum[message.metric] : message.metric;
                        if (message.invalid != null && message.hasOwnProperty("invalid"))
                            object.invalid = message.invalid;
                        if (message.waitingForRevalidating != null && message.hasOwnProperty("waitingForRevalidating"))
                            object.waitingForRevalidating = message.waitingForRevalidating;
                        if (message.isTest != null && message.hasOwnProperty("isTest"))
                            object.isTest = message.isTest;
                        if (message.supportingStoreIds && message.supportingStoreIds.length) {
                            object.supportingStoreIds = [];
                            for (var j = 0; j < message.supportingStoreIds.length; ++j)
                                if (typeof message.supportingStoreIds[j] === "number")
                                    object.supportingStoreIds[j] = options.longs === String ? String(message.supportingStoreIds[j]) : message.supportingStoreIds[j];
                                else
                                    object.supportingStoreIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.supportingStoreIds[j]) : options.longs === Number ? new $util.LongBits(message.supportingStoreIds[j].low >>> 0, message.supportingStoreIds[j].high >>> 0).toNumber() : message.supportingStoreIds[j];
                        }
                        if (message.supportingDateRanges && message.supportingDateRanges.length) {
                            object.supportingDateRanges = [];
                            for (var j = 0; j < message.supportingDateRanges.length; ++j)
                                object.supportingDateRanges[j] = $root.palexy.streaming.v1.DateRange.toObject(message.supportingDateRanges[j], options);
                        }
                        if (message.dataSources && message.dataSources.length) {
                            object.dataSources = [];
                            for (var j = 0; j < message.dataSources.length; ++j)
                                object.dataSources[j] = options.enums === String ? $root.palexy.streaming.v1.DataSource[message.dataSources[j]] : message.dataSources[j];
                        }
                        if (message.financialImpactValue != null && message.hasOwnProperty("financialImpactValue"))
                            object.financialImpactValue = options.json && !isFinite(message.financialImpactValue) ? String(message.financialImpactValue) : message.financialImpactValue;
                        if (message.createTimeMs != null && message.hasOwnProperty("createTimeMs"))
                            if (typeof message.createTimeMs === "number")
                                object.createTimeMs = options.longs === String ? String(message.createTimeMs) : message.createTimeMs;
                            else
                                object.createTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.createTimeMs) : options.longs === Number ? new $util.LongBits(message.createTimeMs.low >>> 0, message.createTimeMs.high >>> 0).toNumber() : message.createTimeMs;
                        if (message.lastUpdateTimeMs != null && message.hasOwnProperty("lastUpdateTimeMs"))
                            if (typeof message.lastUpdateTimeMs === "number")
                                object.lastUpdateTimeMs = options.longs === String ? String(message.lastUpdateTimeMs) : message.lastUpdateTimeMs;
                            else
                                object.lastUpdateTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastUpdateTimeMs) : options.longs === Number ? new $util.LongBits(message.lastUpdateTimeMs.low >>> 0, message.lastUpdateTimeMs.high >>> 0).toNumber() : message.lastUpdateTimeMs;
                        if (message.threeWeeksTrendInsight != null && message.hasOwnProperty("threeWeeksTrendInsight")) {
                            object.threeWeeksTrendInsight = $root.palexy.insights.v1.ThreeWeeksTrendInsight.toObject(message.threeWeeksTrendInsight, options);
                            if (options.oneofs)
                                object.insightData = "threeWeeksTrendInsight";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Insight to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.Insight
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Insight.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Insight;
                })();
    
                /**
                 * InsightPeriodType enum.
                 * @name palexy.insights.v1.InsightPeriodType
                 * @enum {number}
                 * @property {number} INSIGHT_PERIOD_TYPE_UNKNOWN=0 INSIGHT_PERIOD_TYPE_UNKNOWN value
                 * @property {number} THIS_WEEK=1 THIS_WEEK value
                 * @property {number} LAST_WEEK=2 LAST_WEEK value
                 * @property {number} THIS_MONTH=3 THIS_MONTH value
                 * @property {number} LAST_MONTH=4 LAST_MONTH value
                 */
                v1.InsightPeriodType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "INSIGHT_PERIOD_TYPE_UNKNOWN"] = 0;
                    values[valuesById[1] = "THIS_WEEK"] = 1;
                    values[valuesById[2] = "LAST_WEEK"] = 2;
                    values[valuesById[3] = "THIS_MONTH"] = 3;
                    values[valuesById[4] = "LAST_MONTH"] = 4;
                    return values;
                })();
    
                /**
                 * InsightType enum.
                 * @name palexy.insights.v1.InsightType
                 * @enum {number}
                 * @property {number} INSIGHT_TYPE_UNKNOWN=0 INSIGHT_TYPE_UNKNOWN value
                 * @property {number} THREE_WEEKS_TREND=1 THREE_WEEKS_TREND value
                 */
                v1.InsightType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "INSIGHT_TYPE_UNKNOWN"] = 0;
                    values[valuesById[1] = "THREE_WEEKS_TREND"] = 1;
                    return values;
                })();
    
                v1.ThreeWeeksTrendInsight = (function() {
    
                    /**
                     * Properties of a ThreeWeeksTrendInsight.
                     * @memberof palexy.insights.v1
                     * @interface IThreeWeeksTrendInsight
                     * @property {Array.<number>|null} [values] ThreeWeeksTrendInsight values
                     * @property {Array.<palexy.insights.v1.ISupportingInsightMetricDelta>|null} [supportingMetrics] ThreeWeeksTrendInsight supportingMetrics
                     */
    
                    /**
                     * Constructs a new ThreeWeeksTrendInsight.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a ThreeWeeksTrendInsight.
                     * @implements IThreeWeeksTrendInsight
                     * @constructor
                     * @param {palexy.insights.v1.IThreeWeeksTrendInsight=} [properties] Properties to set
                     */
                    function ThreeWeeksTrendInsight(properties) {
                        this.values = [];
                        this.supportingMetrics = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ThreeWeeksTrendInsight values.
                     * @member {Array.<number>} values
                     * @memberof palexy.insights.v1.ThreeWeeksTrendInsight
                     * @instance
                     */
                    ThreeWeeksTrendInsight.prototype.values = $util.emptyArray;
    
                    /**
                     * ThreeWeeksTrendInsight supportingMetrics.
                     * @member {Array.<palexy.insights.v1.ISupportingInsightMetricDelta>} supportingMetrics
                     * @memberof palexy.insights.v1.ThreeWeeksTrendInsight
                     * @instance
                     */
                    ThreeWeeksTrendInsight.prototype.supportingMetrics = $util.emptyArray;
    
                    /**
                     * Creates a new ThreeWeeksTrendInsight instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.ThreeWeeksTrendInsight
                     * @static
                     * @param {palexy.insights.v1.IThreeWeeksTrendInsight=} [properties] Properties to set
                     * @returns {palexy.insights.v1.ThreeWeeksTrendInsight} ThreeWeeksTrendInsight instance
                     */
                    ThreeWeeksTrendInsight.create = function create(properties) {
                        return new ThreeWeeksTrendInsight(properties);
                    };
    
                    /**
                     * Encodes the specified ThreeWeeksTrendInsight message. Does not implicitly {@link palexy.insights.v1.ThreeWeeksTrendInsight.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.ThreeWeeksTrendInsight
                     * @static
                     * @param {palexy.insights.v1.IThreeWeeksTrendInsight} message ThreeWeeksTrendInsight message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ThreeWeeksTrendInsight.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.values != null && message.values.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.values.length; ++i)
                                writer.double(message.values[i]);
                            writer.ldelim();
                        }
                        if (message.supportingMetrics != null && message.supportingMetrics.length)
                            for (var i = 0; i < message.supportingMetrics.length; ++i)
                                $root.palexy.insights.v1.SupportingInsightMetricDelta.encode(message.supportingMetrics[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ThreeWeeksTrendInsight message, length delimited. Does not implicitly {@link palexy.insights.v1.ThreeWeeksTrendInsight.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.ThreeWeeksTrendInsight
                     * @static
                     * @param {palexy.insights.v1.IThreeWeeksTrendInsight} message ThreeWeeksTrendInsight message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ThreeWeeksTrendInsight.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ThreeWeeksTrendInsight message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.ThreeWeeksTrendInsight
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.ThreeWeeksTrendInsight} ThreeWeeksTrendInsight
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ThreeWeeksTrendInsight.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.ThreeWeeksTrendInsight();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.values && message.values.length))
                                    message.values = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.values.push(reader.double());
                                } else
                                    message.values.push(reader.double());
                                break;
                            case 2:
                                if (!(message.supportingMetrics && message.supportingMetrics.length))
                                    message.supportingMetrics = [];
                                message.supportingMetrics.push($root.palexy.insights.v1.SupportingInsightMetricDelta.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ThreeWeeksTrendInsight message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.ThreeWeeksTrendInsight
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.ThreeWeeksTrendInsight} ThreeWeeksTrendInsight
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ThreeWeeksTrendInsight.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ThreeWeeksTrendInsight message.
                     * @function verify
                     * @memberof palexy.insights.v1.ThreeWeeksTrendInsight
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ThreeWeeksTrendInsight.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.values != null && message.hasOwnProperty("values")) {
                            if (!Array.isArray(message.values))
                                return "values: array expected";
                            for (var i = 0; i < message.values.length; ++i)
                                if (typeof message.values[i] !== "number")
                                    return "values: number[] expected";
                        }
                        if (message.supportingMetrics != null && message.hasOwnProperty("supportingMetrics")) {
                            if (!Array.isArray(message.supportingMetrics))
                                return "supportingMetrics: array expected";
                            for (var i = 0; i < message.supportingMetrics.length; ++i) {
                                var error = $root.palexy.insights.v1.SupportingInsightMetricDelta.verify(message.supportingMetrics[i]);
                                if (error)
                                    return "supportingMetrics." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ThreeWeeksTrendInsight message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.ThreeWeeksTrendInsight
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.ThreeWeeksTrendInsight} ThreeWeeksTrendInsight
                     */
                    ThreeWeeksTrendInsight.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.ThreeWeeksTrendInsight)
                            return object;
                        var message = new $root.palexy.insights.v1.ThreeWeeksTrendInsight();
                        if (object.values) {
                            if (!Array.isArray(object.values))
                                throw TypeError(".palexy.insights.v1.ThreeWeeksTrendInsight.values: array expected");
                            message.values = [];
                            for (var i = 0; i < object.values.length; ++i)
                                message.values[i] = Number(object.values[i]);
                        }
                        if (object.supportingMetrics) {
                            if (!Array.isArray(object.supportingMetrics))
                                throw TypeError(".palexy.insights.v1.ThreeWeeksTrendInsight.supportingMetrics: array expected");
                            message.supportingMetrics = [];
                            for (var i = 0; i < object.supportingMetrics.length; ++i) {
                                if (typeof object.supportingMetrics[i] !== "object")
                                    throw TypeError(".palexy.insights.v1.ThreeWeeksTrendInsight.supportingMetrics: object expected");
                                message.supportingMetrics[i] = $root.palexy.insights.v1.SupportingInsightMetricDelta.fromObject(object.supportingMetrics[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ThreeWeeksTrendInsight message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.ThreeWeeksTrendInsight
                     * @static
                     * @param {palexy.insights.v1.ThreeWeeksTrendInsight} message ThreeWeeksTrendInsight
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ThreeWeeksTrendInsight.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.values = [];
                            object.supportingMetrics = [];
                        }
                        if (message.values && message.values.length) {
                            object.values = [];
                            for (var j = 0; j < message.values.length; ++j)
                                object.values[j] = options.json && !isFinite(message.values[j]) ? String(message.values[j]) : message.values[j];
                        }
                        if (message.supportingMetrics && message.supportingMetrics.length) {
                            object.supportingMetrics = [];
                            for (var j = 0; j < message.supportingMetrics.length; ++j)
                                object.supportingMetrics[j] = $root.palexy.insights.v1.SupportingInsightMetricDelta.toObject(message.supportingMetrics[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ThreeWeeksTrendInsight to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.ThreeWeeksTrendInsight
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ThreeWeeksTrendInsight.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ThreeWeeksTrendInsight;
                })();
    
                v1.SupportingInsightMetricDelta = (function() {
    
                    /**
                     * Properties of a SupportingInsightMetricDelta.
                     * @memberof palexy.insights.v1
                     * @interface ISupportingInsightMetricDelta
                     * @property {palexy.streaming.v1.MetricEnum|null} [metric] SupportingInsightMetricDelta metric
                     * @property {number|null} [value] SupportingInsightMetricDelta value
                     * @property {number|null} [baseValue] SupportingInsightMetricDelta baseValue
                     */
    
                    /**
                     * Constructs a new SupportingInsightMetricDelta.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a SupportingInsightMetricDelta.
                     * @implements ISupportingInsightMetricDelta
                     * @constructor
                     * @param {palexy.insights.v1.ISupportingInsightMetricDelta=} [properties] Properties to set
                     */
                    function SupportingInsightMetricDelta(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * SupportingInsightMetricDelta metric.
                     * @member {palexy.streaming.v1.MetricEnum} metric
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @instance
                     */
                    SupportingInsightMetricDelta.prototype.metric = 0;
    
                    /**
                     * SupportingInsightMetricDelta value.
                     * @member {number} value
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @instance
                     */
                    SupportingInsightMetricDelta.prototype.value = 0;
    
                    /**
                     * SupportingInsightMetricDelta baseValue.
                     * @member {number} baseValue
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @instance
                     */
                    SupportingInsightMetricDelta.prototype.baseValue = 0;
    
                    /**
                     * Creates a new SupportingInsightMetricDelta instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @static
                     * @param {palexy.insights.v1.ISupportingInsightMetricDelta=} [properties] Properties to set
                     * @returns {palexy.insights.v1.SupportingInsightMetricDelta} SupportingInsightMetricDelta instance
                     */
                    SupportingInsightMetricDelta.create = function create(properties) {
                        return new SupportingInsightMetricDelta(properties);
                    };
    
                    /**
                     * Encodes the specified SupportingInsightMetricDelta message. Does not implicitly {@link palexy.insights.v1.SupportingInsightMetricDelta.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @static
                     * @param {palexy.insights.v1.ISupportingInsightMetricDelta} message SupportingInsightMetricDelta message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SupportingInsightMetricDelta.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.metric != null && Object.hasOwnProperty.call(message, "metric"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.metric);
                        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                            writer.uint32(/* id 2, wireType 1 =*/17).double(message.value);
                        if (message.baseValue != null && Object.hasOwnProperty.call(message, "baseValue"))
                            writer.uint32(/* id 3, wireType 1 =*/25).double(message.baseValue);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified SupportingInsightMetricDelta message, length delimited. Does not implicitly {@link palexy.insights.v1.SupportingInsightMetricDelta.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @static
                     * @param {palexy.insights.v1.ISupportingInsightMetricDelta} message SupportingInsightMetricDelta message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SupportingInsightMetricDelta.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a SupportingInsightMetricDelta message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.SupportingInsightMetricDelta} SupportingInsightMetricDelta
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SupportingInsightMetricDelta.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.SupportingInsightMetricDelta();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.metric = reader.int32();
                                break;
                            case 2:
                                message.value = reader.double();
                                break;
                            case 3:
                                message.baseValue = reader.double();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a SupportingInsightMetricDelta message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.SupportingInsightMetricDelta} SupportingInsightMetricDelta
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SupportingInsightMetricDelta.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a SupportingInsightMetricDelta message.
                     * @function verify
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SupportingInsightMetricDelta.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.metric != null && message.hasOwnProperty("metric"))
                            switch (message.metric) {
                            default:
                                return "metric: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 20:
                            case 21:
                            case 22:
                            case 23:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 28:
                            case 29:
                            case 30:
                            case 31:
                            case 32:
                            case 33:
                            case 34:
                            case 35:
                            case 36:
                            case 37:
                            case 38:
                            case 39:
                            case 40:
                            case 41:
                            case 42:
                            case 43:
                            case 44:
                            case 45:
                            case 46:
                            case 47:
                            case 48:
                            case 49:
                            case 50:
                            case 51:
                            case 52:
                            case 53:
                            case 54:
                            case 55:
                            case 56:
                            case 57:
                            case 58:
                            case 59:
                            case 60:
                            case 61:
                            case 62:
                            case 63:
                            case 64:
                            case 65:
                            case 66:
                            case 67:
                            case 68:
                            case 69:
                            case 70:
                            case 71:
                            case 72:
                            case 73:
                            case 74:
                            case 75:
                            case 76:
                            case 77:
                            case 78:
                            case 79:
                            case 80:
                            case 81:
                            case 82:
                            case 83:
                            case 84:
                            case 85:
                            case 86:
                            case 87:
                            case 88:
                            case 89:
                            case 90:
                            case 91:
                            case 92:
                            case 93:
                            case 94:
                            case 95:
                            case 96:
                            case 97:
                            case 98:
                            case 99:
                            case 100:
                            case 101:
                            case 102:
                            case 103:
                            case 104:
                            case 105:
                            case 106:
                                break;
                            }
                        if (message.value != null && message.hasOwnProperty("value"))
                            if (typeof message.value !== "number")
                                return "value: number expected";
                        if (message.baseValue != null && message.hasOwnProperty("baseValue"))
                            if (typeof message.baseValue !== "number")
                                return "baseValue: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a SupportingInsightMetricDelta message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.SupportingInsightMetricDelta} SupportingInsightMetricDelta
                     */
                    SupportingInsightMetricDelta.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.SupportingInsightMetricDelta)
                            return object;
                        var message = new $root.palexy.insights.v1.SupportingInsightMetricDelta();
                        switch (object.metric) {
                        case "METRIC_UNKNOWN":
                        case 0:
                            message.metric = 0;
                            break;
                        case "METRIC_BUYER_TRAFFIC":
                        case 1:
                            message.metric = 1;
                            break;
                        case "METRIC_BUYER_TRAFFIC_WITH_INTERACTION":
                        case 2:
                            message.metric = 2;
                            break;
                        case "METRIC_INTERACTION":
                        case 3:
                            message.metric = 3;
                            break;
                        case "METRIC_VISIT":
                        case 4:
                            message.metric = 4;
                            break;
                        case "METRIC_GROUP_VISIT":
                        case 5:
                            message.metric = 5;
                            break;
                        case "METRIC_GROUP_INTERACTION":
                        case 6:
                            message.metric = 6;
                            break;
                        case "METRIC_GROUP_BUYER_TRAFFIC":
                        case 7:
                            message.metric = 7;
                            break;
                        case "METRIC_GROUP_BUYER_TRAFFIC_WITH_INTERACTION":
                        case 8:
                            message.metric = 8;
                            break;
                        case "METRIC_BUYER_RATE":
                        case 9:
                            message.metric = 9;
                            break;
                        case "METRIC_INTERACTION_RATE":
                        case 10:
                            message.metric = 10;
                            break;
                        case "METRIC_INTERACTION_TIME":
                        case 11:
                            message.metric = 11;
                            break;
                        case "METRIC_INTERACTION_TIME_AVG":
                        case 12:
                            message.metric = 12;
                            break;
                        case "METRIC_INTERACTION_LEVEL":
                        case 13:
                            message.metric = 13;
                            break;
                        case "METRIC_INTERACTION_BUYER_RATE":
                        case 14:
                            message.metric = 14;
                            break;
                        case "METRIC_AVG_TIME_TO_FIRST_INTERACTION":
                        case 15:
                            message.metric = 15;
                            break;
                        case "METRIC_ON_TIME_INTERACTION_RATE":
                        case 16:
                            message.metric = 16;
                            break;
                        case "METRIC_GROUP_BUYER_RATE":
                        case 17:
                            message.metric = 17;
                            break;
                        case "METRIC_GROUP_INTERACTION_RATE":
                        case 18:
                            message.metric = 18;
                            break;
                        case "METRIC_GROUP_INTERACTION_BUYER_RATE":
                        case 19:
                            message.metric = 19;
                            break;
                        case "METRIC_LYING":
                        case 20:
                            message.metric = 20;
                            break;
                        case "METRIC_LYING_RATE":
                        case 21:
                            message.metric = 21;
                            break;
                        case "METRIC_LYING_TIME_AVG":
                        case 22:
                            message.metric = 22;
                            break;
                        case "METRIC_LYING_EXACT_ONE":
                        case 23:
                            message.metric = 23;
                            break;
                        case "METRIC_LYING_AT_LEAST_ONE":
                        case 24:
                            message.metric = 24;
                            break;
                        case "METRIC_LYING_AT_LEAST_TWO":
                        case 25:
                            message.metric = 25;
                            break;
                        case "METRIC_DWELL_TIME":
                        case 26:
                            message.metric = 26;
                            break;
                        case "METRIC_DWELL_TIME_AVG":
                        case 27:
                            message.metric = 27;
                            break;
                        case "METRIC_TOTAL_GREETED_CUSTOMERS":
                        case 28:
                            message.metric = 28;
                            break;
                        case "METRIC_GREETING_RATE":
                        case 29:
                            message.metric = 29;
                            break;
                        case "METRIC_AVG_TIME_TO_FIRST_GREETING":
                        case 30:
                            message.metric = 30;
                            break;
                        case "METRIC_ON_TIME_GREETING_RATE":
                        case 31:
                            message.metric = 31;
                            break;
                        case "METRIC_FOOT_TRAFFIC":
                        case 32:
                            message.metric = 32;
                            break;
                        case "METRIC_VISITOR_OCCUPANCY":
                        case 33:
                            message.metric = 33;
                            break;
                        case "METRIC_TOTAL_GROUPS":
                        case 34:
                            message.metric = 34;
                            break;
                        case "METRIC_TOTAL_VISITORS_GOING_AS_GROUPS":
                        case 35:
                            message.metric = 35;
                            break;
                        case "METRIC_GROUP_RATE":
                        case 36:
                            message.metric = 36;
                            break;
                        case "METRIC_AVG_GROUP_SIZE":
                        case 37:
                            message.metric = 37;
                            break;
                        case "METRIC_TOTAL_GROUPS_WITH_KIDS":
                        case 38:
                            message.metric = 38;
                            break;
                        case "METRIC_TOTAL_KNOWN_GROUPS":
                        case 39:
                            message.metric = 39;
                            break;
                        case "METRIC_GROUPS_WITH_KIDS_RATE":
                        case 40:
                            message.metric = 40;
                            break;
                        case "METRIC_TOTAL_GROUPS_AND_INDIVIDUALS":
                        case 41:
                            message.metric = 41;
                            break;
                        case "METRIC_CONVERSION_BY_OPPORTUNITIES":
                        case 42:
                            message.metric = 42;
                            break;
                        case "METRIC_PERCENT_SECTION_VISIT":
                        case 43:
                            message.metric = 43;
                            break;
                        case "METRIC_PERCENT_SECTION_PASS_BY":
                        case 44:
                            message.metric = 44;
                            break;
                        case "METRIC_PASS_BY":
                        case 45:
                            message.metric = 45;
                            break;
                        case "METRIC_CAPTURE_RATE":
                        case 46:
                            message.metric = 46;
                            break;
                        case "METRIC_TRANSACTION":
                        case 47:
                            message.metric = 47;
                            break;
                        case "METRIC_CONVERSION_RATE":
                        case 48:
                            message.metric = 48;
                            break;
                        case "METRIC_NET_SALES_AMOUNT":
                        case 49:
                            message.metric = 49;
                            break;
                        case "METRIC_NET_SALES_ITEMS":
                        case 50:
                            message.metric = 50;
                            break;
                        case "METRIC_NET_SALES_CONTRIBUTION":
                        case 51:
                            message.metric = 51;
                            break;
                        case "METRIC_NET_SALES_RANKING":
                        case 52:
                            message.metric = 52;
                            break;
                        case "METRIC_SHOPPER_YIELD":
                        case 53:
                            message.metric = 53;
                            break;
                        case "METRIC_ATV":
                        case 54:
                            message.metric = 54;
                            break;
                        case "METRIC_UPT":
                        case 55:
                            message.metric = 55;
                            break;
                        case "METRIC_UPDPS":
                        case 56:
                            message.metric = 56;
                            break;
                        case "METRIC_ASP":
                        case 57:
                            message.metric = 57;
                            break;
                        case "METRIC_STAFF_HOURS":
                        case 58:
                            message.metric = 58;
                            break;
                        case "METRIC_VISITOR_PER_STAFF_HOUR":
                        case 59:
                            message.metric = 59;
                            break;
                        case "METRIC_SALES_PER_STAFF_HOUR":
                        case 60:
                            message.metric = 60;
                            break;
                        case "METRIC_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                        case 61:
                            message.metric = 61;
                            break;
                        case "METRIC_MAX_VISITORS_PER_STAFF_IN_HOUR":
                        case 62:
                            message.metric = 62;
                            break;
                        case "METRIC_FORECAST_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                        case 63:
                            message.metric = 63;
                            break;
                        case "METRIC_FORECAST_MAX_VISITORS_PER_STAFF_IN_HOUR":
                        case 64:
                            message.metric = 64;
                            break;
                        case "METRIC_ROUNDED_STAFF_HOURS":
                        case 65:
                            message.metric = 65;
                            break;
                        case "METRIC_STAFF_HOUR_WITH_VISITOR_COUNT":
                        case 66:
                            message.metric = 66;
                            break;
                        case "METRIC_VISITORS_PER_SQM":
                        case 67:
                            message.metric = 67;
                            break;
                        case "METRIC_AVG_VISITOR_PER_STORE_PER_DAY":
                        case 68:
                            message.metric = 68;
                            break;
                        case "METRIC_DAYS_WITH_VISITORS":
                        case 69:
                            message.metric = 69;
                            break;
                        case "METRIC_DAYS_WITH_NET_SALES_ITEMS":
                        case 70:
                            message.metric = 70;
                            break;
                        case "METRIC_SALES_PER_SQM":
                        case 71:
                            message.metric = 71;
                            break;
                        case "METRIC_SALES_PER_SALES_AREA_SQM":
                        case 72:
                            message.metric = 72;
                            break;
                        case "METRIC_ESTIMATED_VISIT":
                        case 73:
                            message.metric = 73;
                            break;
                        case "METRIC_ESTIMATED_PASS_BY":
                        case 74:
                            message.metric = 74;
                            break;
                        case "METRIC_WALK_IN":
                        case 75:
                            message.metric = 75;
                            break;
                        case "METRIC_PERCENT_SECTION_ESTIMATED_PASS_BY":
                        case 76:
                            message.metric = 76;
                            break;
                        case "METRIC_CUSTOM_ACTION_1":
                        case 77:
                            message.metric = 77;
                            break;
                        case "METRIC_CUSTOM_ACTION_1_RATE":
                        case 78:
                            message.metric = 78;
                            break;
                        case "METRIC_CONVERTED_CUSTOM_ACTION_1_RATE":
                        case 79:
                            message.metric = 79;
                            break;
                        case "METRIC_AVG_SALES_PER_STORE_PER_DAY":
                        case 80:
                            message.metric = 80;
                            break;
                        case "METRIC_STAFF_TOTAL_SERVED_VISITORS":
                        case 81:
                            message.metric = 81;
                            break;
                        case "METRIC_STAFF_CONVERSION_RATE":
                        case 82:
                            message.metric = 82;
                            break;
                        case "METRIC_STAFF_SALES_PER_SERVED_VISITORS":
                        case 83:
                            message.metric = 83;
                            break;
                        case "METRIC_STAFF_SERVED_VISITORS_PER_STAFF_HOUR":
                        case 84:
                            message.metric = 84;
                            break;
                        case "METRIC_STAFF_PERCENTAGE_OF_SALES":
                        case 85:
                            message.metric = 85;
                            break;
                        case "METRIC_DAYS_WITH_SALES":
                        case 86:
                            message.metric = 86;
                            break;
                        case "METRIC_VIDEO_GROUP_DISTRIBUTION":
                        case 87:
                            message.metric = 87;
                            break;
                        case "METRIC_BOUNCE_RATE":
                        case 88:
                            message.metric = 88;
                            break;
                        case "METRIC_AVG_MALL_VISITOR_PER_STORE_PER_DAY":
                        case 89:
                            message.metric = 89;
                            break;
                        case "METRIC_TOTAL_ON_TIME_GREETED_CUSTOMERS":
                        case 90:
                            message.metric = 90;
                            break;
                        case "METRIC_STAFF_STORE_NET_SALES_AMOUNT":
                        case 91:
                            message.metric = 91;
                            break;
                        case "METRIC_STAFF_UNIQUE_INTERACTIONS":
                        case 92:
                            message.metric = 92;
                            break;
                        case "METRIC_STAFF_INTERACTION_RATE":
                        case 93:
                            message.metric = 93;
                            break;
                        case "METRIC_TOTAL_CHECKOUT_VISITORS":
                        case 94:
                            message.metric = 94;
                            break;
                        case "METRIC_MAX_CONCURRENT_CHECKOUT_VISITORS":
                        case 95:
                            message.metric = 95;
                            break;
                        case "METRIC_AVG_CONCURRENT_CHECKOUT_VISITORS":
                        case 96:
                            message.metric = 96;
                            break;
                        case "METRIC_AVG_CHECKOUT_TIME":
                        case 97:
                            message.metric = 97;
                            break;
                        case "METRIC_MAX_CHECKOUT_TIME":
                        case 98:
                            message.metric = 98;
                            break;
                        case "METRIC_TOTAL_CHECKOUT_OPPORTUNITIES":
                        case 99:
                            message.metric = 99;
                            break;
                        case "METRIC_MAX_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                        case 100:
                            message.metric = 100;
                            break;
                        case "METRIC_AVG_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                        case 101:
                            message.metric = 101;
                            break;
                        case "METRIC_AVG_CHECKOUT_TIME_BY_OPPORTUNITIES":
                        case 102:
                            message.metric = 102;
                            break;
                        case "METRIC_MAX_CHECKOUT_TIME_BY_OPPORTUNITIES":
                        case 103:
                            message.metric = 103;
                            break;
                        case "METRIC_DISCOUNT_AMOUNT":
                        case 104:
                            message.metric = 104;
                            break;
                        case "METRIC_DISCOUNT_RATE":
                        case 105:
                            message.metric = 105;
                            break;
                        case "METRIC_GROSS_AMOUNT":
                        case 106:
                            message.metric = 106;
                            break;
                        }
                        if (object.value != null)
                            message.value = Number(object.value);
                        if (object.baseValue != null)
                            message.baseValue = Number(object.baseValue);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a SupportingInsightMetricDelta message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @static
                     * @param {palexy.insights.v1.SupportingInsightMetricDelta} message SupportingInsightMetricDelta
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SupportingInsightMetricDelta.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.metric = options.enums === String ? "METRIC_UNKNOWN" : 0;
                            object.value = 0;
                            object.baseValue = 0;
                        }
                        if (message.metric != null && message.hasOwnProperty("metric"))
                            object.metric = options.enums === String ? $root.palexy.streaming.v1.MetricEnum[message.metric] : message.metric;
                        if (message.value != null && message.hasOwnProperty("value"))
                            object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                        if (message.baseValue != null && message.hasOwnProperty("baseValue"))
                            object.baseValue = options.json && !isFinite(message.baseValue) ? String(message.baseValue) : message.baseValue;
                        return object;
                    };
    
                    /**
                     * Converts this SupportingInsightMetricDelta to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.SupportingInsightMetricDelta
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SupportingInsightMetricDelta.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return SupportingInsightMetricDelta;
                })();
    
                v1.ListInsightsRequest = (function() {
    
                    /**
                     * Properties of a ListInsightsRequest.
                     * @memberof palexy.insights.v1
                     * @interface IListInsightsRequest
                     * @property {google.protobuf.IInt64Value|null} [companyId] ListInsightsRequest companyId
                     * @property {Array.<number>|null} [storeIds] ListInsightsRequest storeIds
                     * @property {Array.<palexy.insights.v1.InsightPeriodType>|null} [periodTypes] ListInsightsRequest periodTypes
                     * @property {Array.<palexy.insights.v1.InsightType>|null} [types] ListInsightsRequest types
                     * @property {Array.<palexy.streaming.v1.MetricEnum>|null} [metrics] ListInsightsRequest metrics
                     * @property {palexy.streaming.v1.IDateFilter|null} [fromDate] ListInsightsRequest fromDate
                     * @property {palexy.streaming.v1.IDateFilter|null} [toDate] ListInsightsRequest toDate
                     * @property {google.protobuf.IBoolValue|null} [invalid] ListInsightsRequest invalid
                     * @property {google.protobuf.IBoolValue|null} [waitingForRevalidating] ListInsightsRequest waitingForRevalidating
                     * @property {google.protobuf.IBoolValue|null} [isTest] ListInsightsRequest isTest
                     * @property {number|null} [pageSize] ListInsightsRequest pageSize
                     * @property {string|null} [pageToken] ListInsightsRequest pageToken
                     */
    
                    /**
                     * Constructs a new ListInsightsRequest.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a ListInsightsRequest.
                     * @implements IListInsightsRequest
                     * @constructor
                     * @param {palexy.insights.v1.IListInsightsRequest=} [properties] Properties to set
                     */
                    function ListInsightsRequest(properties) {
                        this.storeIds = [];
                        this.periodTypes = [];
                        this.types = [];
                        this.metrics = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListInsightsRequest companyId.
                     * @member {google.protobuf.IInt64Value|null|undefined} companyId
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.companyId = null;
    
                    /**
                     * ListInsightsRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * ListInsightsRequest periodTypes.
                     * @member {Array.<palexy.insights.v1.InsightPeriodType>} periodTypes
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.periodTypes = $util.emptyArray;
    
                    /**
                     * ListInsightsRequest types.
                     * @member {Array.<palexy.insights.v1.InsightType>} types
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.types = $util.emptyArray;
    
                    /**
                     * ListInsightsRequest metrics.
                     * @member {Array.<palexy.streaming.v1.MetricEnum>} metrics
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.metrics = $util.emptyArray;
    
                    /**
                     * ListInsightsRequest fromDate.
                     * @member {palexy.streaming.v1.IDateFilter|null|undefined} fromDate
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.fromDate = null;
    
                    /**
                     * ListInsightsRequest toDate.
                     * @member {palexy.streaming.v1.IDateFilter|null|undefined} toDate
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.toDate = null;
    
                    /**
                     * ListInsightsRequest invalid.
                     * @member {google.protobuf.IBoolValue|null|undefined} invalid
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.invalid = null;
    
                    /**
                     * ListInsightsRequest waitingForRevalidating.
                     * @member {google.protobuf.IBoolValue|null|undefined} waitingForRevalidating
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.waitingForRevalidating = null;
    
                    /**
                     * ListInsightsRequest isTest.
                     * @member {google.protobuf.IBoolValue|null|undefined} isTest
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.isTest = null;
    
                    /**
                     * ListInsightsRequest pageSize.
                     * @member {number} pageSize
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.pageSize = 0;
    
                    /**
                     * ListInsightsRequest pageToken.
                     * @member {string} pageToken
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     */
                    ListInsightsRequest.prototype.pageToken = "";
    
                    /**
                     * Creates a new ListInsightsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IListInsightsRequest=} [properties] Properties to set
                     * @returns {palexy.insights.v1.ListInsightsRequest} ListInsightsRequest instance
                     */
                    ListInsightsRequest.create = function create(properties) {
                        return new ListInsightsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListInsightsRequest message. Does not implicitly {@link palexy.insights.v1.ListInsightsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IListInsightsRequest} message ListInsightsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListInsightsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            $root.google.protobuf.Int64Value.encode(message.companyId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.periodTypes != null && message.periodTypes.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.periodTypes.length; ++i)
                                writer.int32(message.periodTypes[i]);
                            writer.ldelim();
                        }
                        if (message.types != null && message.types.length) {
                            writer.uint32(/* id 4, wireType 2 =*/34).fork();
                            for (var i = 0; i < message.types.length; ++i)
                                writer.int32(message.types[i]);
                            writer.ldelim();
                        }
                        if (message.metrics != null && message.metrics.length) {
                            writer.uint32(/* id 5, wireType 2 =*/42).fork();
                            for (var i = 0; i < message.metrics.length; ++i)
                                writer.int32(message.metrics[i]);
                            writer.ldelim();
                        }
                        if (message.fromDate != null && Object.hasOwnProperty.call(message, "fromDate"))
                            $root.palexy.streaming.v1.DateFilter.encode(message.fromDate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.toDate != null && Object.hasOwnProperty.call(message, "toDate"))
                            $root.palexy.streaming.v1.DateFilter.encode(message.toDate, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.invalid != null && Object.hasOwnProperty.call(message, "invalid"))
                            $root.google.protobuf.BoolValue.encode(message.invalid, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.waitingForRevalidating != null && Object.hasOwnProperty.call(message, "waitingForRevalidating"))
                            $root.google.protobuf.BoolValue.encode(message.waitingForRevalidating, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.isTest != null && Object.hasOwnProperty.call(message, "isTest"))
                            $root.google.protobuf.BoolValue.encode(message.isTest, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                            writer.uint32(/* id 98, wireType 0 =*/784).int32(message.pageSize);
                        if (message.pageToken != null && Object.hasOwnProperty.call(message, "pageToken"))
                            writer.uint32(/* id 99, wireType 2 =*/794).string(message.pageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListInsightsRequest message, length delimited. Does not implicitly {@link palexy.insights.v1.ListInsightsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IListInsightsRequest} message ListInsightsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListInsightsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListInsightsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.ListInsightsRequest} ListInsightsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListInsightsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.ListInsightsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = $root.google.protobuf.Int64Value.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 3:
                                if (!(message.periodTypes && message.periodTypes.length))
                                    message.periodTypes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.periodTypes.push(reader.int32());
                                } else
                                    message.periodTypes.push(reader.int32());
                                break;
                            case 4:
                                if (!(message.types && message.types.length))
                                    message.types = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.types.push(reader.int32());
                                } else
                                    message.types.push(reader.int32());
                                break;
                            case 5:
                                if (!(message.metrics && message.metrics.length))
                                    message.metrics = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.metrics.push(reader.int32());
                                } else
                                    message.metrics.push(reader.int32());
                                break;
                            case 6:
                                message.fromDate = $root.palexy.streaming.v1.DateFilter.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.toDate = $root.palexy.streaming.v1.DateFilter.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.invalid = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.waitingForRevalidating = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.isTest = $root.google.protobuf.BoolValue.decode(reader, reader.uint32());
                                break;
                            case 98:
                                message.pageSize = reader.int32();
                                break;
                            case 99:
                                message.pageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListInsightsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.ListInsightsRequest} ListInsightsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListInsightsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListInsightsRequest message.
                     * @function verify
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListInsightsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId")) {
                            var error = $root.google.protobuf.Int64Value.verify(message.companyId);
                            if (error)
                                return "companyId." + error;
                        }
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.periodTypes != null && message.hasOwnProperty("periodTypes")) {
                            if (!Array.isArray(message.periodTypes))
                                return "periodTypes: array expected";
                            for (var i = 0; i < message.periodTypes.length; ++i)
                                switch (message.periodTypes[i]) {
                                default:
                                    return "periodTypes: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                    break;
                                }
                        }
                        if (message.types != null && message.hasOwnProperty("types")) {
                            if (!Array.isArray(message.types))
                                return "types: array expected";
                            for (var i = 0; i < message.types.length; ++i)
                                switch (message.types[i]) {
                                default:
                                    return "types: enum value[] expected";
                                case 0:
                                case 1:
                                    break;
                                }
                        }
                        if (message.metrics != null && message.hasOwnProperty("metrics")) {
                            if (!Array.isArray(message.metrics))
                                return "metrics: array expected";
                            for (var i = 0; i < message.metrics.length; ++i)
                                switch (message.metrics[i]) {
                                default:
                                    return "metrics: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                case 13:
                                case 14:
                                case 15:
                                case 16:
                                case 17:
                                case 18:
                                case 19:
                                case 20:
                                case 21:
                                case 22:
                                case 23:
                                case 24:
                                case 25:
                                case 26:
                                case 27:
                                case 28:
                                case 29:
                                case 30:
                                case 31:
                                case 32:
                                case 33:
                                case 34:
                                case 35:
                                case 36:
                                case 37:
                                case 38:
                                case 39:
                                case 40:
                                case 41:
                                case 42:
                                case 43:
                                case 44:
                                case 45:
                                case 46:
                                case 47:
                                case 48:
                                case 49:
                                case 50:
                                case 51:
                                case 52:
                                case 53:
                                case 54:
                                case 55:
                                case 56:
                                case 57:
                                case 58:
                                case 59:
                                case 60:
                                case 61:
                                case 62:
                                case 63:
                                case 64:
                                case 65:
                                case 66:
                                case 67:
                                case 68:
                                case 69:
                                case 70:
                                case 71:
                                case 72:
                                case 73:
                                case 74:
                                case 75:
                                case 76:
                                case 77:
                                case 78:
                                case 79:
                                case 80:
                                case 81:
                                case 82:
                                case 83:
                                case 84:
                                case 85:
                                case 86:
                                case 87:
                                case 88:
                                case 89:
                                case 90:
                                case 91:
                                case 92:
                                case 93:
                                case 94:
                                case 95:
                                case 96:
                                case 97:
                                case 98:
                                case 99:
                                case 100:
                                case 101:
                                case 102:
                                case 103:
                                case 104:
                                case 105:
                                case 106:
                                    break;
                                }
                        }
                        if (message.fromDate != null && message.hasOwnProperty("fromDate")) {
                            var error = $root.palexy.streaming.v1.DateFilter.verify(message.fromDate);
                            if (error)
                                return "fromDate." + error;
                        }
                        if (message.toDate != null && message.hasOwnProperty("toDate")) {
                            var error = $root.palexy.streaming.v1.DateFilter.verify(message.toDate);
                            if (error)
                                return "toDate." + error;
                        }
                        if (message.invalid != null && message.hasOwnProperty("invalid")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.invalid);
                            if (error)
                                return "invalid." + error;
                        }
                        if (message.waitingForRevalidating != null && message.hasOwnProperty("waitingForRevalidating")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.waitingForRevalidating);
                            if (error)
                                return "waitingForRevalidating." + error;
                        }
                        if (message.isTest != null && message.hasOwnProperty("isTest")) {
                            var error = $root.google.protobuf.BoolValue.verify(message.isTest);
                            if (error)
                                return "isTest." + error;
                        }
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            if (!$util.isInteger(message.pageSize))
                                return "pageSize: integer expected";
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            if (!$util.isString(message.pageToken))
                                return "pageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListInsightsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.ListInsightsRequest} ListInsightsRequest
                     */
                    ListInsightsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.ListInsightsRequest)
                            return object;
                        var message = new $root.palexy.insights.v1.ListInsightsRequest();
                        if (object.companyId != null) {
                            if (typeof object.companyId !== "object")
                                throw TypeError(".palexy.insights.v1.ListInsightsRequest.companyId: object expected");
                            message.companyId = $root.google.protobuf.Int64Value.fromObject(object.companyId);
                        }
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.insights.v1.ListInsightsRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        if (object.periodTypes) {
                            if (!Array.isArray(object.periodTypes))
                                throw TypeError(".palexy.insights.v1.ListInsightsRequest.periodTypes: array expected");
                            message.periodTypes = [];
                            for (var i = 0; i < object.periodTypes.length; ++i)
                                switch (object.periodTypes[i]) {
                                default:
                                case "INSIGHT_PERIOD_TYPE_UNKNOWN":
                                case 0:
                                    message.periodTypes[i] = 0;
                                    break;
                                case "THIS_WEEK":
                                case 1:
                                    message.periodTypes[i] = 1;
                                    break;
                                case "LAST_WEEK":
                                case 2:
                                    message.periodTypes[i] = 2;
                                    break;
                                case "THIS_MONTH":
                                case 3:
                                    message.periodTypes[i] = 3;
                                    break;
                                case "LAST_MONTH":
                                case 4:
                                    message.periodTypes[i] = 4;
                                    break;
                                }
                        }
                        if (object.types) {
                            if (!Array.isArray(object.types))
                                throw TypeError(".palexy.insights.v1.ListInsightsRequest.types: array expected");
                            message.types = [];
                            for (var i = 0; i < object.types.length; ++i)
                                switch (object.types[i]) {
                                default:
                                case "INSIGHT_TYPE_UNKNOWN":
                                case 0:
                                    message.types[i] = 0;
                                    break;
                                case "THREE_WEEKS_TREND":
                                case 1:
                                    message.types[i] = 1;
                                    break;
                                }
                        }
                        if (object.metrics) {
                            if (!Array.isArray(object.metrics))
                                throw TypeError(".palexy.insights.v1.ListInsightsRequest.metrics: array expected");
                            message.metrics = [];
                            for (var i = 0; i < object.metrics.length; ++i)
                                switch (object.metrics[i]) {
                                default:
                                case "METRIC_UNKNOWN":
                                case 0:
                                    message.metrics[i] = 0;
                                    break;
                                case "METRIC_BUYER_TRAFFIC":
                                case 1:
                                    message.metrics[i] = 1;
                                    break;
                                case "METRIC_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 2:
                                    message.metrics[i] = 2;
                                    break;
                                case "METRIC_INTERACTION":
                                case 3:
                                    message.metrics[i] = 3;
                                    break;
                                case "METRIC_VISIT":
                                case 4:
                                    message.metrics[i] = 4;
                                    break;
                                case "METRIC_GROUP_VISIT":
                                case 5:
                                    message.metrics[i] = 5;
                                    break;
                                case "METRIC_GROUP_INTERACTION":
                                case 6:
                                    message.metrics[i] = 6;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC":
                                case 7:
                                    message.metrics[i] = 7;
                                    break;
                                case "METRIC_GROUP_BUYER_TRAFFIC_WITH_INTERACTION":
                                case 8:
                                    message.metrics[i] = 8;
                                    break;
                                case "METRIC_BUYER_RATE":
                                case 9:
                                    message.metrics[i] = 9;
                                    break;
                                case "METRIC_INTERACTION_RATE":
                                case 10:
                                    message.metrics[i] = 10;
                                    break;
                                case "METRIC_INTERACTION_TIME":
                                case 11:
                                    message.metrics[i] = 11;
                                    break;
                                case "METRIC_INTERACTION_TIME_AVG":
                                case 12:
                                    message.metrics[i] = 12;
                                    break;
                                case "METRIC_INTERACTION_LEVEL":
                                case 13:
                                    message.metrics[i] = 13;
                                    break;
                                case "METRIC_INTERACTION_BUYER_RATE":
                                case 14:
                                    message.metrics[i] = 14;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_INTERACTION":
                                case 15:
                                    message.metrics[i] = 15;
                                    break;
                                case "METRIC_ON_TIME_INTERACTION_RATE":
                                case 16:
                                    message.metrics[i] = 16;
                                    break;
                                case "METRIC_GROUP_BUYER_RATE":
                                case 17:
                                    message.metrics[i] = 17;
                                    break;
                                case "METRIC_GROUP_INTERACTION_RATE":
                                case 18:
                                    message.metrics[i] = 18;
                                    break;
                                case "METRIC_GROUP_INTERACTION_BUYER_RATE":
                                case 19:
                                    message.metrics[i] = 19;
                                    break;
                                case "METRIC_LYING":
                                case 20:
                                    message.metrics[i] = 20;
                                    break;
                                case "METRIC_LYING_RATE":
                                case 21:
                                    message.metrics[i] = 21;
                                    break;
                                case "METRIC_LYING_TIME_AVG":
                                case 22:
                                    message.metrics[i] = 22;
                                    break;
                                case "METRIC_LYING_EXACT_ONE":
                                case 23:
                                    message.metrics[i] = 23;
                                    break;
                                case "METRIC_LYING_AT_LEAST_ONE":
                                case 24:
                                    message.metrics[i] = 24;
                                    break;
                                case "METRIC_LYING_AT_LEAST_TWO":
                                case 25:
                                    message.metrics[i] = 25;
                                    break;
                                case "METRIC_DWELL_TIME":
                                case 26:
                                    message.metrics[i] = 26;
                                    break;
                                case "METRIC_DWELL_TIME_AVG":
                                case 27:
                                    message.metrics[i] = 27;
                                    break;
                                case "METRIC_TOTAL_GREETED_CUSTOMERS":
                                case 28:
                                    message.metrics[i] = 28;
                                    break;
                                case "METRIC_GREETING_RATE":
                                case 29:
                                    message.metrics[i] = 29;
                                    break;
                                case "METRIC_AVG_TIME_TO_FIRST_GREETING":
                                case 30:
                                    message.metrics[i] = 30;
                                    break;
                                case "METRIC_ON_TIME_GREETING_RATE":
                                case 31:
                                    message.metrics[i] = 31;
                                    break;
                                case "METRIC_FOOT_TRAFFIC":
                                case 32:
                                    message.metrics[i] = 32;
                                    break;
                                case "METRIC_VISITOR_OCCUPANCY":
                                case 33:
                                    message.metrics[i] = 33;
                                    break;
                                case "METRIC_TOTAL_GROUPS":
                                case 34:
                                    message.metrics[i] = 34;
                                    break;
                                case "METRIC_TOTAL_VISITORS_GOING_AS_GROUPS":
                                case 35:
                                    message.metrics[i] = 35;
                                    break;
                                case "METRIC_GROUP_RATE":
                                case 36:
                                    message.metrics[i] = 36;
                                    break;
                                case "METRIC_AVG_GROUP_SIZE":
                                case 37:
                                    message.metrics[i] = 37;
                                    break;
                                case "METRIC_TOTAL_GROUPS_WITH_KIDS":
                                case 38:
                                    message.metrics[i] = 38;
                                    break;
                                case "METRIC_TOTAL_KNOWN_GROUPS":
                                case 39:
                                    message.metrics[i] = 39;
                                    break;
                                case "METRIC_GROUPS_WITH_KIDS_RATE":
                                case 40:
                                    message.metrics[i] = 40;
                                    break;
                                case "METRIC_TOTAL_GROUPS_AND_INDIVIDUALS":
                                case 41:
                                    message.metrics[i] = 41;
                                    break;
                                case "METRIC_CONVERSION_BY_OPPORTUNITIES":
                                case 42:
                                    message.metrics[i] = 42;
                                    break;
                                case "METRIC_PERCENT_SECTION_VISIT":
                                case 43:
                                    message.metrics[i] = 43;
                                    break;
                                case "METRIC_PERCENT_SECTION_PASS_BY":
                                case 44:
                                    message.metrics[i] = 44;
                                    break;
                                case "METRIC_PASS_BY":
                                case 45:
                                    message.metrics[i] = 45;
                                    break;
                                case "METRIC_CAPTURE_RATE":
                                case 46:
                                    message.metrics[i] = 46;
                                    break;
                                case "METRIC_TRANSACTION":
                                case 47:
                                    message.metrics[i] = 47;
                                    break;
                                case "METRIC_CONVERSION_RATE":
                                case 48:
                                    message.metrics[i] = 48;
                                    break;
                                case "METRIC_NET_SALES_AMOUNT":
                                case 49:
                                    message.metrics[i] = 49;
                                    break;
                                case "METRIC_NET_SALES_ITEMS":
                                case 50:
                                    message.metrics[i] = 50;
                                    break;
                                case "METRIC_NET_SALES_CONTRIBUTION":
                                case 51:
                                    message.metrics[i] = 51;
                                    break;
                                case "METRIC_NET_SALES_RANKING":
                                case 52:
                                    message.metrics[i] = 52;
                                    break;
                                case "METRIC_SHOPPER_YIELD":
                                case 53:
                                    message.metrics[i] = 53;
                                    break;
                                case "METRIC_ATV":
                                case 54:
                                    message.metrics[i] = 54;
                                    break;
                                case "METRIC_UPT":
                                case 55:
                                    message.metrics[i] = 55;
                                    break;
                                case "METRIC_UPDPS":
                                case 56:
                                    message.metrics[i] = 56;
                                    break;
                                case "METRIC_ASP":
                                case 57:
                                    message.metrics[i] = 57;
                                    break;
                                case "METRIC_STAFF_HOURS":
                                case 58:
                                    message.metrics[i] = 58;
                                    break;
                                case "METRIC_VISITOR_PER_STAFF_HOUR":
                                case 59:
                                    message.metrics[i] = 59;
                                    break;
                                case "METRIC_SALES_PER_STAFF_HOUR":
                                case 60:
                                    message.metrics[i] = 60;
                                    break;
                                case "METRIC_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 61:
                                    message.metrics[i] = 61;
                                    break;
                                case "METRIC_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 62:
                                    message.metrics[i] = 62;
                                    break;
                                case "METRIC_FORECAST_AVERAGE_VISITORS_PER_STAFF_IN_HOUR":
                                case 63:
                                    message.metrics[i] = 63;
                                    break;
                                case "METRIC_FORECAST_MAX_VISITORS_PER_STAFF_IN_HOUR":
                                case 64:
                                    message.metrics[i] = 64;
                                    break;
                                case "METRIC_ROUNDED_STAFF_HOURS":
                                case 65:
                                    message.metrics[i] = 65;
                                    break;
                                case "METRIC_STAFF_HOUR_WITH_VISITOR_COUNT":
                                case 66:
                                    message.metrics[i] = 66;
                                    break;
                                case "METRIC_VISITORS_PER_SQM":
                                case 67:
                                    message.metrics[i] = 67;
                                    break;
                                case "METRIC_AVG_VISITOR_PER_STORE_PER_DAY":
                                case 68:
                                    message.metrics[i] = 68;
                                    break;
                                case "METRIC_DAYS_WITH_VISITORS":
                                case 69:
                                    message.metrics[i] = 69;
                                    break;
                                case "METRIC_DAYS_WITH_NET_SALES_ITEMS":
                                case 70:
                                    message.metrics[i] = 70;
                                    break;
                                case "METRIC_SALES_PER_SQM":
                                case 71:
                                    message.metrics[i] = 71;
                                    break;
                                case "METRIC_SALES_PER_SALES_AREA_SQM":
                                case 72:
                                    message.metrics[i] = 72;
                                    break;
                                case "METRIC_ESTIMATED_VISIT":
                                case 73:
                                    message.metrics[i] = 73;
                                    break;
                                case "METRIC_ESTIMATED_PASS_BY":
                                case 74:
                                    message.metrics[i] = 74;
                                    break;
                                case "METRIC_WALK_IN":
                                case 75:
                                    message.metrics[i] = 75;
                                    break;
                                case "METRIC_PERCENT_SECTION_ESTIMATED_PASS_BY":
                                case 76:
                                    message.metrics[i] = 76;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1":
                                case 77:
                                    message.metrics[i] = 77;
                                    break;
                                case "METRIC_CUSTOM_ACTION_1_RATE":
                                case 78:
                                    message.metrics[i] = 78;
                                    break;
                                case "METRIC_CONVERTED_CUSTOM_ACTION_1_RATE":
                                case 79:
                                    message.metrics[i] = 79;
                                    break;
                                case "METRIC_AVG_SALES_PER_STORE_PER_DAY":
                                case 80:
                                    message.metrics[i] = 80;
                                    break;
                                case "METRIC_STAFF_TOTAL_SERVED_VISITORS":
                                case 81:
                                    message.metrics[i] = 81;
                                    break;
                                case "METRIC_STAFF_CONVERSION_RATE":
                                case 82:
                                    message.metrics[i] = 82;
                                    break;
                                case "METRIC_STAFF_SALES_PER_SERVED_VISITORS":
                                case 83:
                                    message.metrics[i] = 83;
                                    break;
                                case "METRIC_STAFF_SERVED_VISITORS_PER_STAFF_HOUR":
                                case 84:
                                    message.metrics[i] = 84;
                                    break;
                                case "METRIC_STAFF_PERCENTAGE_OF_SALES":
                                case 85:
                                    message.metrics[i] = 85;
                                    break;
                                case "METRIC_DAYS_WITH_SALES":
                                case 86:
                                    message.metrics[i] = 86;
                                    break;
                                case "METRIC_VIDEO_GROUP_DISTRIBUTION":
                                case 87:
                                    message.metrics[i] = 87;
                                    break;
                                case "METRIC_BOUNCE_RATE":
                                case 88:
                                    message.metrics[i] = 88;
                                    break;
                                case "METRIC_AVG_MALL_VISITOR_PER_STORE_PER_DAY":
                                case 89:
                                    message.metrics[i] = 89;
                                    break;
                                case "METRIC_TOTAL_ON_TIME_GREETED_CUSTOMERS":
                                case 90:
                                    message.metrics[i] = 90;
                                    break;
                                case "METRIC_STAFF_STORE_NET_SALES_AMOUNT":
                                case 91:
                                    message.metrics[i] = 91;
                                    break;
                                case "METRIC_STAFF_UNIQUE_INTERACTIONS":
                                case 92:
                                    message.metrics[i] = 92;
                                    break;
                                case "METRIC_STAFF_INTERACTION_RATE":
                                case 93:
                                    message.metrics[i] = 93;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_VISITORS":
                                case 94:
                                    message.metrics[i] = 94;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_VISITORS":
                                case 95:
                                    message.metrics[i] = 95;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_VISITORS":
                                case 96:
                                    message.metrics[i] = 96;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME":
                                case 97:
                                    message.metrics[i] = 97;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME":
                                case 98:
                                    message.metrics[i] = 98;
                                    break;
                                case "METRIC_TOTAL_CHECKOUT_OPPORTUNITIES":
                                case 99:
                                    message.metrics[i] = 99;
                                    break;
                                case "METRIC_MAX_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 100:
                                    message.metrics[i] = 100;
                                    break;
                                case "METRIC_AVG_CONCURRENT_CHECKOUT_OPPORTUNITIES":
                                case 101:
                                    message.metrics[i] = 101;
                                    break;
                                case "METRIC_AVG_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 102:
                                    message.metrics[i] = 102;
                                    break;
                                case "METRIC_MAX_CHECKOUT_TIME_BY_OPPORTUNITIES":
                                case 103:
                                    message.metrics[i] = 103;
                                    break;
                                case "METRIC_DISCOUNT_AMOUNT":
                                case 104:
                                    message.metrics[i] = 104;
                                    break;
                                case "METRIC_DISCOUNT_RATE":
                                case 105:
                                    message.metrics[i] = 105;
                                    break;
                                case "METRIC_GROSS_AMOUNT":
                                case 106:
                                    message.metrics[i] = 106;
                                    break;
                                }
                        }
                        if (object.fromDate != null) {
                            if (typeof object.fromDate !== "object")
                                throw TypeError(".palexy.insights.v1.ListInsightsRequest.fromDate: object expected");
                            message.fromDate = $root.palexy.streaming.v1.DateFilter.fromObject(object.fromDate);
                        }
                        if (object.toDate != null) {
                            if (typeof object.toDate !== "object")
                                throw TypeError(".palexy.insights.v1.ListInsightsRequest.toDate: object expected");
                            message.toDate = $root.palexy.streaming.v1.DateFilter.fromObject(object.toDate);
                        }
                        if (object.invalid != null) {
                            if (typeof object.invalid !== "object")
                                throw TypeError(".palexy.insights.v1.ListInsightsRequest.invalid: object expected");
                            message.invalid = $root.google.protobuf.BoolValue.fromObject(object.invalid);
                        }
                        if (object.waitingForRevalidating != null) {
                            if (typeof object.waitingForRevalidating !== "object")
                                throw TypeError(".palexy.insights.v1.ListInsightsRequest.waitingForRevalidating: object expected");
                            message.waitingForRevalidating = $root.google.protobuf.BoolValue.fromObject(object.waitingForRevalidating);
                        }
                        if (object.isTest != null) {
                            if (typeof object.isTest !== "object")
                                throw TypeError(".palexy.insights.v1.ListInsightsRequest.isTest: object expected");
                            message.isTest = $root.google.protobuf.BoolValue.fromObject(object.isTest);
                        }
                        if (object.pageSize != null)
                            message.pageSize = object.pageSize | 0;
                        if (object.pageToken != null)
                            message.pageToken = String(object.pageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListInsightsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.ListInsightsRequest} message ListInsightsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListInsightsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.storeIds = [];
                            object.periodTypes = [];
                            object.types = [];
                            object.metrics = [];
                        }
                        if (options.defaults) {
                            object.companyId = null;
                            object.fromDate = null;
                            object.toDate = null;
                            object.invalid = null;
                            object.waitingForRevalidating = null;
                            object.isTest = null;
                            object.pageSize = 0;
                            object.pageToken = "";
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            object.companyId = $root.google.protobuf.Int64Value.toObject(message.companyId, options);
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.periodTypes && message.periodTypes.length) {
                            object.periodTypes = [];
                            for (var j = 0; j < message.periodTypes.length; ++j)
                                object.periodTypes[j] = options.enums === String ? $root.palexy.insights.v1.InsightPeriodType[message.periodTypes[j]] : message.periodTypes[j];
                        }
                        if (message.types && message.types.length) {
                            object.types = [];
                            for (var j = 0; j < message.types.length; ++j)
                                object.types[j] = options.enums === String ? $root.palexy.insights.v1.InsightType[message.types[j]] : message.types[j];
                        }
                        if (message.metrics && message.metrics.length) {
                            object.metrics = [];
                            for (var j = 0; j < message.metrics.length; ++j)
                                object.metrics[j] = options.enums === String ? $root.palexy.streaming.v1.MetricEnum[message.metrics[j]] : message.metrics[j];
                        }
                        if (message.fromDate != null && message.hasOwnProperty("fromDate"))
                            object.fromDate = $root.palexy.streaming.v1.DateFilter.toObject(message.fromDate, options);
                        if (message.toDate != null && message.hasOwnProperty("toDate"))
                            object.toDate = $root.palexy.streaming.v1.DateFilter.toObject(message.toDate, options);
                        if (message.invalid != null && message.hasOwnProperty("invalid"))
                            object.invalid = $root.google.protobuf.BoolValue.toObject(message.invalid, options);
                        if (message.waitingForRevalidating != null && message.hasOwnProperty("waitingForRevalidating"))
                            object.waitingForRevalidating = $root.google.protobuf.BoolValue.toObject(message.waitingForRevalidating, options);
                        if (message.isTest != null && message.hasOwnProperty("isTest"))
                            object.isTest = $root.google.protobuf.BoolValue.toObject(message.isTest, options);
                        if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                            object.pageSize = message.pageSize;
                        if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                            object.pageToken = message.pageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListInsightsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.ListInsightsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListInsightsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListInsightsRequest;
                })();
    
                v1.ListInsightsResponse = (function() {
    
                    /**
                     * Properties of a ListInsightsResponse.
                     * @memberof palexy.insights.v1
                     * @interface IListInsightsResponse
                     * @property {Array.<palexy.insights.v1.IInsight>|null} [insights] ListInsightsResponse insights
                     * @property {number|null} [totalElements] ListInsightsResponse totalElements
                     * @property {string|null} [nextPageToken] ListInsightsResponse nextPageToken
                     */
    
                    /**
                     * Constructs a new ListInsightsResponse.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a ListInsightsResponse.
                     * @implements IListInsightsResponse
                     * @constructor
                     * @param {palexy.insights.v1.IListInsightsResponse=} [properties] Properties to set
                     */
                    function ListInsightsResponse(properties) {
                        this.insights = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListInsightsResponse insights.
                     * @member {Array.<palexy.insights.v1.IInsight>} insights
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @instance
                     */
                    ListInsightsResponse.prototype.insights = $util.emptyArray;
    
                    /**
                     * ListInsightsResponse totalElements.
                     * @member {number} totalElements
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @instance
                     */
                    ListInsightsResponse.prototype.totalElements = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ListInsightsResponse nextPageToken.
                     * @member {string} nextPageToken
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @instance
                     */
                    ListInsightsResponse.prototype.nextPageToken = "";
    
                    /**
                     * Creates a new ListInsightsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @static
                     * @param {palexy.insights.v1.IListInsightsResponse=} [properties] Properties to set
                     * @returns {palexy.insights.v1.ListInsightsResponse} ListInsightsResponse instance
                     */
                    ListInsightsResponse.create = function create(properties) {
                        return new ListInsightsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListInsightsResponse message. Does not implicitly {@link palexy.insights.v1.ListInsightsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @static
                     * @param {palexy.insights.v1.IListInsightsResponse} message ListInsightsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListInsightsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.insights != null && message.insights.length)
                            for (var i = 0; i < message.insights.length; ++i)
                                $root.palexy.insights.v1.Insight.encode(message.insights[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.totalElements != null && Object.hasOwnProperty.call(message, "totalElements"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalElements);
                        if (message.nextPageToken != null && Object.hasOwnProperty.call(message, "nextPageToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.nextPageToken);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListInsightsResponse message, length delimited. Does not implicitly {@link palexy.insights.v1.ListInsightsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @static
                     * @param {palexy.insights.v1.IListInsightsResponse} message ListInsightsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListInsightsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListInsightsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.ListInsightsResponse} ListInsightsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListInsightsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.ListInsightsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.insights && message.insights.length))
                                    message.insights = [];
                                message.insights.push($root.palexy.insights.v1.Insight.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.totalElements = reader.int64();
                                break;
                            case 3:
                                message.nextPageToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListInsightsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.ListInsightsResponse} ListInsightsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListInsightsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListInsightsResponse message.
                     * @function verify
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListInsightsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.insights != null && message.hasOwnProperty("insights")) {
                            if (!Array.isArray(message.insights))
                                return "insights: array expected";
                            for (var i = 0; i < message.insights.length; ++i) {
                                var error = $root.palexy.insights.v1.Insight.verify(message.insights[i]);
                                if (error)
                                    return "insights." + error;
                            }
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (!$util.isInteger(message.totalElements) && !(message.totalElements && $util.isInteger(message.totalElements.low) && $util.isInteger(message.totalElements.high)))
                                return "totalElements: integer|Long expected";
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            if (!$util.isString(message.nextPageToken))
                                return "nextPageToken: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListInsightsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.ListInsightsResponse} ListInsightsResponse
                     */
                    ListInsightsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.ListInsightsResponse)
                            return object;
                        var message = new $root.palexy.insights.v1.ListInsightsResponse();
                        if (object.insights) {
                            if (!Array.isArray(object.insights))
                                throw TypeError(".palexy.insights.v1.ListInsightsResponse.insights: array expected");
                            message.insights = [];
                            for (var i = 0; i < object.insights.length; ++i) {
                                if (typeof object.insights[i] !== "object")
                                    throw TypeError(".palexy.insights.v1.ListInsightsResponse.insights: object expected");
                                message.insights[i] = $root.palexy.insights.v1.Insight.fromObject(object.insights[i]);
                            }
                        }
                        if (object.totalElements != null)
                            if ($util.Long)
                                (message.totalElements = $util.Long.fromValue(object.totalElements)).unsigned = false;
                            else if (typeof object.totalElements === "string")
                                message.totalElements = parseInt(object.totalElements, 10);
                            else if (typeof object.totalElements === "number")
                                message.totalElements = object.totalElements;
                            else if (typeof object.totalElements === "object")
                                message.totalElements = new $util.LongBits(object.totalElements.low >>> 0, object.totalElements.high >>> 0).toNumber();
                        if (object.nextPageToken != null)
                            message.nextPageToken = String(object.nextPageToken);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListInsightsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @static
                     * @param {palexy.insights.v1.ListInsightsResponse} message ListInsightsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListInsightsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.insights = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.totalElements = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalElements = options.longs === String ? "0" : 0;
                            object.nextPageToken = "";
                        }
                        if (message.insights && message.insights.length) {
                            object.insights = [];
                            for (var j = 0; j < message.insights.length; ++j)
                                object.insights[j] = $root.palexy.insights.v1.Insight.toObject(message.insights[j], options);
                        }
                        if (message.totalElements != null && message.hasOwnProperty("totalElements"))
                            if (typeof message.totalElements === "number")
                                object.totalElements = options.longs === String ? String(message.totalElements) : message.totalElements;
                            else
                                object.totalElements = options.longs === String ? $util.Long.prototype.toString.call(message.totalElements) : options.longs === Number ? new $util.LongBits(message.totalElements.low >>> 0, message.totalElements.high >>> 0).toNumber() : message.totalElements;
                        if (message.nextPageToken != null && message.hasOwnProperty("nextPageToken"))
                            object.nextPageToken = message.nextPageToken;
                        return object;
                    };
    
                    /**
                     * Converts this ListInsightsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.ListInsightsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListInsightsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListInsightsResponse;
                })();
    
                v1.GetInsightRequest = (function() {
    
                    /**
                     * Properties of a GetInsightRequest.
                     * @memberof palexy.insights.v1
                     * @interface IGetInsightRequest
                     * @property {number|null} [insightId] GetInsightRequest insightId
                     */
    
                    /**
                     * Constructs a new GetInsightRequest.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a GetInsightRequest.
                     * @implements IGetInsightRequest
                     * @constructor
                     * @param {palexy.insights.v1.IGetInsightRequest=} [properties] Properties to set
                     */
                    function GetInsightRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetInsightRequest insightId.
                     * @member {number} insightId
                     * @memberof palexy.insights.v1.GetInsightRequest
                     * @instance
                     */
                    GetInsightRequest.prototype.insightId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetInsightRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.GetInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IGetInsightRequest=} [properties] Properties to set
                     * @returns {palexy.insights.v1.GetInsightRequest} GetInsightRequest instance
                     */
                    GetInsightRequest.create = function create(properties) {
                        return new GetInsightRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetInsightRequest message. Does not implicitly {@link palexy.insights.v1.GetInsightRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.GetInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IGetInsightRequest} message GetInsightRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetInsightRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.insightId != null && Object.hasOwnProperty.call(message, "insightId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.insightId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetInsightRequest message, length delimited. Does not implicitly {@link palexy.insights.v1.GetInsightRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.GetInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IGetInsightRequest} message GetInsightRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetInsightRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetInsightRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.GetInsightRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.GetInsightRequest} GetInsightRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetInsightRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.GetInsightRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.insightId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetInsightRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.GetInsightRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.GetInsightRequest} GetInsightRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetInsightRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetInsightRequest message.
                     * @function verify
                     * @memberof palexy.insights.v1.GetInsightRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetInsightRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.insightId != null && message.hasOwnProperty("insightId"))
                            if (!$util.isInteger(message.insightId) && !(message.insightId && $util.isInteger(message.insightId.low) && $util.isInteger(message.insightId.high)))
                                return "insightId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetInsightRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.GetInsightRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.GetInsightRequest} GetInsightRequest
                     */
                    GetInsightRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.GetInsightRequest)
                            return object;
                        var message = new $root.palexy.insights.v1.GetInsightRequest();
                        if (object.insightId != null)
                            if ($util.Long)
                                (message.insightId = $util.Long.fromValue(object.insightId)).unsigned = false;
                            else if (typeof object.insightId === "string")
                                message.insightId = parseInt(object.insightId, 10);
                            else if (typeof object.insightId === "number")
                                message.insightId = object.insightId;
                            else if (typeof object.insightId === "object")
                                message.insightId = new $util.LongBits(object.insightId.low >>> 0, object.insightId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetInsightRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.GetInsightRequest
                     * @static
                     * @param {palexy.insights.v1.GetInsightRequest} message GetInsightRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetInsightRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.insightId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.insightId = options.longs === String ? "0" : 0;
                        if (message.insightId != null && message.hasOwnProperty("insightId"))
                            if (typeof message.insightId === "number")
                                object.insightId = options.longs === String ? String(message.insightId) : message.insightId;
                            else
                                object.insightId = options.longs === String ? $util.Long.prototype.toString.call(message.insightId) : options.longs === Number ? new $util.LongBits(message.insightId.low >>> 0, message.insightId.high >>> 0).toNumber() : message.insightId;
                        return object;
                    };
    
                    /**
                     * Converts this GetInsightRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.GetInsightRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetInsightRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetInsightRequest;
                })();
    
                v1.UpdateInsightRequest = (function() {
    
                    /**
                     * Properties of an UpdateInsightRequest.
                     * @memberof palexy.insights.v1
                     * @interface IUpdateInsightRequest
                     * @property {palexy.insights.v1.IInsight|null} [insight] UpdateInsightRequest insight
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateInsightRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateInsightRequest.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents an UpdateInsightRequest.
                     * @implements IUpdateInsightRequest
                     * @constructor
                     * @param {palexy.insights.v1.IUpdateInsightRequest=} [properties] Properties to set
                     */
                    function UpdateInsightRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateInsightRequest insight.
                     * @member {palexy.insights.v1.IInsight|null|undefined} insight
                     * @memberof palexy.insights.v1.UpdateInsightRequest
                     * @instance
                     */
                    UpdateInsightRequest.prototype.insight = null;
    
                    /**
                     * UpdateInsightRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.insights.v1.UpdateInsightRequest
                     * @instance
                     */
                    UpdateInsightRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateInsightRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.UpdateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IUpdateInsightRequest=} [properties] Properties to set
                     * @returns {palexy.insights.v1.UpdateInsightRequest} UpdateInsightRequest instance
                     */
                    UpdateInsightRequest.create = function create(properties) {
                        return new UpdateInsightRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateInsightRequest message. Does not implicitly {@link palexy.insights.v1.UpdateInsightRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.UpdateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IUpdateInsightRequest} message UpdateInsightRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateInsightRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.insight != null && Object.hasOwnProperty.call(message, "insight"))
                            $root.palexy.insights.v1.Insight.encode(message.insight, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateInsightRequest message, length delimited. Does not implicitly {@link palexy.insights.v1.UpdateInsightRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.UpdateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IUpdateInsightRequest} message UpdateInsightRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateInsightRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateInsightRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.UpdateInsightRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.UpdateInsightRequest} UpdateInsightRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateInsightRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.UpdateInsightRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.insight = $root.palexy.insights.v1.Insight.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateInsightRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.UpdateInsightRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.UpdateInsightRequest} UpdateInsightRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateInsightRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateInsightRequest message.
                     * @function verify
                     * @memberof palexy.insights.v1.UpdateInsightRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateInsightRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.insight != null && message.hasOwnProperty("insight")) {
                            var error = $root.palexy.insights.v1.Insight.verify(message.insight);
                            if (error)
                                return "insight." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateInsightRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.UpdateInsightRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.UpdateInsightRequest} UpdateInsightRequest
                     */
                    UpdateInsightRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.UpdateInsightRequest)
                            return object;
                        var message = new $root.palexy.insights.v1.UpdateInsightRequest();
                        if (object.insight != null) {
                            if (typeof object.insight !== "object")
                                throw TypeError(".palexy.insights.v1.UpdateInsightRequest.insight: object expected");
                            message.insight = $root.palexy.insights.v1.Insight.fromObject(object.insight);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.insights.v1.UpdateInsightRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateInsightRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.UpdateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.UpdateInsightRequest} message UpdateInsightRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateInsightRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.insight = null;
                            object.updateMask = null;
                        }
                        if (message.insight != null && message.hasOwnProperty("insight"))
                            object.insight = $root.palexy.insights.v1.Insight.toObject(message.insight, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateInsightRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.UpdateInsightRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateInsightRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateInsightRequest;
                })();
    
                v1.DeleteInsightRequest = (function() {
    
                    /**
                     * Properties of a DeleteInsightRequest.
                     * @memberof palexy.insights.v1
                     * @interface IDeleteInsightRequest
                     * @property {number|null} [insightId] DeleteInsightRequest insightId
                     */
    
                    /**
                     * Constructs a new DeleteInsightRequest.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a DeleteInsightRequest.
                     * @implements IDeleteInsightRequest
                     * @constructor
                     * @param {palexy.insights.v1.IDeleteInsightRequest=} [properties] Properties to set
                     */
                    function DeleteInsightRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteInsightRequest insightId.
                     * @member {number} insightId
                     * @memberof palexy.insights.v1.DeleteInsightRequest
                     * @instance
                     */
                    DeleteInsightRequest.prototype.insightId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteInsightRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.DeleteInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IDeleteInsightRequest=} [properties] Properties to set
                     * @returns {palexy.insights.v1.DeleteInsightRequest} DeleteInsightRequest instance
                     */
                    DeleteInsightRequest.create = function create(properties) {
                        return new DeleteInsightRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteInsightRequest message. Does not implicitly {@link palexy.insights.v1.DeleteInsightRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.DeleteInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IDeleteInsightRequest} message DeleteInsightRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteInsightRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.insightId != null && Object.hasOwnProperty.call(message, "insightId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.insightId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteInsightRequest message, length delimited. Does not implicitly {@link palexy.insights.v1.DeleteInsightRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.DeleteInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IDeleteInsightRequest} message DeleteInsightRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteInsightRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteInsightRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.DeleteInsightRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.DeleteInsightRequest} DeleteInsightRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteInsightRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.DeleteInsightRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.insightId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteInsightRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.DeleteInsightRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.DeleteInsightRequest} DeleteInsightRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteInsightRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteInsightRequest message.
                     * @function verify
                     * @memberof palexy.insights.v1.DeleteInsightRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteInsightRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.insightId != null && message.hasOwnProperty("insightId"))
                            if (!$util.isInteger(message.insightId) && !(message.insightId && $util.isInteger(message.insightId.low) && $util.isInteger(message.insightId.high)))
                                return "insightId: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteInsightRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.DeleteInsightRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.DeleteInsightRequest} DeleteInsightRequest
                     */
                    DeleteInsightRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.DeleteInsightRequest)
                            return object;
                        var message = new $root.palexy.insights.v1.DeleteInsightRequest();
                        if (object.insightId != null)
                            if ($util.Long)
                                (message.insightId = $util.Long.fromValue(object.insightId)).unsigned = false;
                            else if (typeof object.insightId === "string")
                                message.insightId = parseInt(object.insightId, 10);
                            else if (typeof object.insightId === "number")
                                message.insightId = object.insightId;
                            else if (typeof object.insightId === "object")
                                message.insightId = new $util.LongBits(object.insightId.low >>> 0, object.insightId.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteInsightRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.DeleteInsightRequest
                     * @static
                     * @param {palexy.insights.v1.DeleteInsightRequest} message DeleteInsightRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteInsightRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.insightId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.insightId = options.longs === String ? "0" : 0;
                        if (message.insightId != null && message.hasOwnProperty("insightId"))
                            if (typeof message.insightId === "number")
                                object.insightId = options.longs === String ? String(message.insightId) : message.insightId;
                            else
                                object.insightId = options.longs === String ? $util.Long.prototype.toString.call(message.insightId) : options.longs === Number ? new $util.LongBits(message.insightId.low >>> 0, message.insightId.high >>> 0).toNumber() : message.insightId;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteInsightRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.DeleteInsightRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteInsightRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteInsightRequest;
                })();
    
                v1.BatchGetInsightsRequest = (function() {
    
                    /**
                     * Properties of a BatchGetInsightsRequest.
                     * @memberof palexy.insights.v1
                     * @interface IBatchGetInsightsRequest
                     * @property {Array.<number>|null} [insightIds] BatchGetInsightsRequest insightIds
                     */
    
                    /**
                     * Constructs a new BatchGetInsightsRequest.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a BatchGetInsightsRequest.
                     * @implements IBatchGetInsightsRequest
                     * @constructor
                     * @param {palexy.insights.v1.IBatchGetInsightsRequest=} [properties] Properties to set
                     */
                    function BatchGetInsightsRequest(properties) {
                        this.insightIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchGetInsightsRequest insightIds.
                     * @member {Array.<number>} insightIds
                     * @memberof palexy.insights.v1.BatchGetInsightsRequest
                     * @instance
                     */
                    BatchGetInsightsRequest.prototype.insightIds = $util.emptyArray;
    
                    /**
                     * Creates a new BatchGetInsightsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.BatchGetInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IBatchGetInsightsRequest=} [properties] Properties to set
                     * @returns {palexy.insights.v1.BatchGetInsightsRequest} BatchGetInsightsRequest instance
                     */
                    BatchGetInsightsRequest.create = function create(properties) {
                        return new BatchGetInsightsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchGetInsightsRequest message. Does not implicitly {@link palexy.insights.v1.BatchGetInsightsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.BatchGetInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IBatchGetInsightsRequest} message BatchGetInsightsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchGetInsightsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.insightIds != null && message.insightIds.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.insightIds.length; ++i)
                                writer.int64(message.insightIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchGetInsightsRequest message, length delimited. Does not implicitly {@link palexy.insights.v1.BatchGetInsightsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.BatchGetInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IBatchGetInsightsRequest} message BatchGetInsightsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchGetInsightsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchGetInsightsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.BatchGetInsightsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.BatchGetInsightsRequest} BatchGetInsightsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchGetInsightsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.BatchGetInsightsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.insightIds && message.insightIds.length))
                                    message.insightIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.insightIds.push(reader.int64());
                                } else
                                    message.insightIds.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchGetInsightsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.BatchGetInsightsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.BatchGetInsightsRequest} BatchGetInsightsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchGetInsightsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchGetInsightsRequest message.
                     * @function verify
                     * @memberof palexy.insights.v1.BatchGetInsightsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchGetInsightsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.insightIds != null && message.hasOwnProperty("insightIds")) {
                            if (!Array.isArray(message.insightIds))
                                return "insightIds: array expected";
                            for (var i = 0; i < message.insightIds.length; ++i)
                                if (!$util.isInteger(message.insightIds[i]) && !(message.insightIds[i] && $util.isInteger(message.insightIds[i].low) && $util.isInteger(message.insightIds[i].high)))
                                    return "insightIds: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchGetInsightsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.BatchGetInsightsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.BatchGetInsightsRequest} BatchGetInsightsRequest
                     */
                    BatchGetInsightsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.BatchGetInsightsRequest)
                            return object;
                        var message = new $root.palexy.insights.v1.BatchGetInsightsRequest();
                        if (object.insightIds) {
                            if (!Array.isArray(object.insightIds))
                                throw TypeError(".palexy.insights.v1.BatchGetInsightsRequest.insightIds: array expected");
                            message.insightIds = [];
                            for (var i = 0; i < object.insightIds.length; ++i)
                                if ($util.Long)
                                    (message.insightIds[i] = $util.Long.fromValue(object.insightIds[i])).unsigned = false;
                                else if (typeof object.insightIds[i] === "string")
                                    message.insightIds[i] = parseInt(object.insightIds[i], 10);
                                else if (typeof object.insightIds[i] === "number")
                                    message.insightIds[i] = object.insightIds[i];
                                else if (typeof object.insightIds[i] === "object")
                                    message.insightIds[i] = new $util.LongBits(object.insightIds[i].low >>> 0, object.insightIds[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchGetInsightsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.BatchGetInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.BatchGetInsightsRequest} message BatchGetInsightsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchGetInsightsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.insightIds = [];
                        if (message.insightIds && message.insightIds.length) {
                            object.insightIds = [];
                            for (var j = 0; j < message.insightIds.length; ++j)
                                if (typeof message.insightIds[j] === "number")
                                    object.insightIds[j] = options.longs === String ? String(message.insightIds[j]) : message.insightIds[j];
                                else
                                    object.insightIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.insightIds[j]) : options.longs === Number ? new $util.LongBits(message.insightIds[j].low >>> 0, message.insightIds[j].high >>> 0).toNumber() : message.insightIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchGetInsightsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.BatchGetInsightsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchGetInsightsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchGetInsightsRequest;
                })();
    
                v1.BatchGetInsightsResponse = (function() {
    
                    /**
                     * Properties of a BatchGetInsightsResponse.
                     * @memberof palexy.insights.v1
                     * @interface IBatchGetInsightsResponse
                     * @property {Array.<palexy.insights.v1.IInsight>|null} [insights] BatchGetInsightsResponse insights
                     */
    
                    /**
                     * Constructs a new BatchGetInsightsResponse.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a BatchGetInsightsResponse.
                     * @implements IBatchGetInsightsResponse
                     * @constructor
                     * @param {palexy.insights.v1.IBatchGetInsightsResponse=} [properties] Properties to set
                     */
                    function BatchGetInsightsResponse(properties) {
                        this.insights = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchGetInsightsResponse insights.
                     * @member {Array.<palexy.insights.v1.IInsight>} insights
                     * @memberof palexy.insights.v1.BatchGetInsightsResponse
                     * @instance
                     */
                    BatchGetInsightsResponse.prototype.insights = $util.emptyArray;
    
                    /**
                     * Creates a new BatchGetInsightsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.BatchGetInsightsResponse
                     * @static
                     * @param {palexy.insights.v1.IBatchGetInsightsResponse=} [properties] Properties to set
                     * @returns {palexy.insights.v1.BatchGetInsightsResponse} BatchGetInsightsResponse instance
                     */
                    BatchGetInsightsResponse.create = function create(properties) {
                        return new BatchGetInsightsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified BatchGetInsightsResponse message. Does not implicitly {@link palexy.insights.v1.BatchGetInsightsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.BatchGetInsightsResponse
                     * @static
                     * @param {palexy.insights.v1.IBatchGetInsightsResponse} message BatchGetInsightsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchGetInsightsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.insights != null && message.insights.length)
                            for (var i = 0; i < message.insights.length; ++i)
                                $root.palexy.insights.v1.Insight.encode(message.insights[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchGetInsightsResponse message, length delimited. Does not implicitly {@link palexy.insights.v1.BatchGetInsightsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.BatchGetInsightsResponse
                     * @static
                     * @param {palexy.insights.v1.IBatchGetInsightsResponse} message BatchGetInsightsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchGetInsightsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchGetInsightsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.BatchGetInsightsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.BatchGetInsightsResponse} BatchGetInsightsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchGetInsightsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.BatchGetInsightsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.insights && message.insights.length))
                                    message.insights = [];
                                message.insights.push($root.palexy.insights.v1.Insight.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchGetInsightsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.BatchGetInsightsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.BatchGetInsightsResponse} BatchGetInsightsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchGetInsightsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchGetInsightsResponse message.
                     * @function verify
                     * @memberof palexy.insights.v1.BatchGetInsightsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchGetInsightsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.insights != null && message.hasOwnProperty("insights")) {
                            if (!Array.isArray(message.insights))
                                return "insights: array expected";
                            for (var i = 0; i < message.insights.length; ++i) {
                                var error = $root.palexy.insights.v1.Insight.verify(message.insights[i]);
                                if (error)
                                    return "insights." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchGetInsightsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.BatchGetInsightsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.BatchGetInsightsResponse} BatchGetInsightsResponse
                     */
                    BatchGetInsightsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.BatchGetInsightsResponse)
                            return object;
                        var message = new $root.palexy.insights.v1.BatchGetInsightsResponse();
                        if (object.insights) {
                            if (!Array.isArray(object.insights))
                                throw TypeError(".palexy.insights.v1.BatchGetInsightsResponse.insights: array expected");
                            message.insights = [];
                            for (var i = 0; i < object.insights.length; ++i) {
                                if (typeof object.insights[i] !== "object")
                                    throw TypeError(".palexy.insights.v1.BatchGetInsightsResponse.insights: object expected");
                                message.insights[i] = $root.palexy.insights.v1.Insight.fromObject(object.insights[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchGetInsightsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.BatchGetInsightsResponse
                     * @static
                     * @param {palexy.insights.v1.BatchGetInsightsResponse} message BatchGetInsightsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchGetInsightsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.insights = [];
                        if (message.insights && message.insights.length) {
                            object.insights = [];
                            for (var j = 0; j < message.insights.length; ++j)
                                object.insights[j] = $root.palexy.insights.v1.Insight.toObject(message.insights[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this BatchGetInsightsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.BatchGetInsightsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchGetInsightsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchGetInsightsResponse;
                })();
    
                v1.BatchUpdateInsightsRequest = (function() {
    
                    /**
                     * Properties of a BatchUpdateInsightsRequest.
                     * @memberof palexy.insights.v1
                     * @interface IBatchUpdateInsightsRequest
                     * @property {Array.<palexy.insights.v1.IInsight>|null} [insights] BatchUpdateInsightsRequest insights
                     * @property {google.protobuf.IFieldMask|null} [updateMask] BatchUpdateInsightsRequest updateMask
                     */
    
                    /**
                     * Constructs a new BatchUpdateInsightsRequest.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a BatchUpdateInsightsRequest.
                     * @implements IBatchUpdateInsightsRequest
                     * @constructor
                     * @param {palexy.insights.v1.IBatchUpdateInsightsRequest=} [properties] Properties to set
                     */
                    function BatchUpdateInsightsRequest(properties) {
                        this.insights = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchUpdateInsightsRequest insights.
                     * @member {Array.<palexy.insights.v1.IInsight>} insights
                     * @memberof palexy.insights.v1.BatchUpdateInsightsRequest
                     * @instance
                     */
                    BatchUpdateInsightsRequest.prototype.insights = $util.emptyArray;
    
                    /**
                     * BatchUpdateInsightsRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.insights.v1.BatchUpdateInsightsRequest
                     * @instance
                     */
                    BatchUpdateInsightsRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new BatchUpdateInsightsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.BatchUpdateInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IBatchUpdateInsightsRequest=} [properties] Properties to set
                     * @returns {palexy.insights.v1.BatchUpdateInsightsRequest} BatchUpdateInsightsRequest instance
                     */
                    BatchUpdateInsightsRequest.create = function create(properties) {
                        return new BatchUpdateInsightsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchUpdateInsightsRequest message. Does not implicitly {@link palexy.insights.v1.BatchUpdateInsightsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.BatchUpdateInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IBatchUpdateInsightsRequest} message BatchUpdateInsightsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateInsightsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.insights != null && message.insights.length)
                            for (var i = 0; i < message.insights.length; ++i)
                                $root.palexy.insights.v1.Insight.encode(message.insights[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchUpdateInsightsRequest message, length delimited. Does not implicitly {@link palexy.insights.v1.BatchUpdateInsightsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.BatchUpdateInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IBatchUpdateInsightsRequest} message BatchUpdateInsightsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchUpdateInsightsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchUpdateInsightsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.BatchUpdateInsightsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.BatchUpdateInsightsRequest} BatchUpdateInsightsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateInsightsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.BatchUpdateInsightsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.insights && message.insights.length))
                                    message.insights = [];
                                message.insights.push($root.palexy.insights.v1.Insight.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchUpdateInsightsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.BatchUpdateInsightsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.BatchUpdateInsightsRequest} BatchUpdateInsightsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchUpdateInsightsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchUpdateInsightsRequest message.
                     * @function verify
                     * @memberof palexy.insights.v1.BatchUpdateInsightsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchUpdateInsightsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.insights != null && message.hasOwnProperty("insights")) {
                            if (!Array.isArray(message.insights))
                                return "insights: array expected";
                            for (var i = 0; i < message.insights.length; ++i) {
                                var error = $root.palexy.insights.v1.Insight.verify(message.insights[i]);
                                if (error)
                                    return "insights." + error;
                            }
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a BatchUpdateInsightsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.BatchUpdateInsightsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.BatchUpdateInsightsRequest} BatchUpdateInsightsRequest
                     */
                    BatchUpdateInsightsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.BatchUpdateInsightsRequest)
                            return object;
                        var message = new $root.palexy.insights.v1.BatchUpdateInsightsRequest();
                        if (object.insights) {
                            if (!Array.isArray(object.insights))
                                throw TypeError(".palexy.insights.v1.BatchUpdateInsightsRequest.insights: array expected");
                            message.insights = [];
                            for (var i = 0; i < object.insights.length; ++i) {
                                if (typeof object.insights[i] !== "object")
                                    throw TypeError(".palexy.insights.v1.BatchUpdateInsightsRequest.insights: object expected");
                                message.insights[i] = $root.palexy.insights.v1.Insight.fromObject(object.insights[i]);
                            }
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.insights.v1.BatchUpdateInsightsRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchUpdateInsightsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.BatchUpdateInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.BatchUpdateInsightsRequest} message BatchUpdateInsightsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchUpdateInsightsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.insights = [];
                        if (options.defaults)
                            object.updateMask = null;
                        if (message.insights && message.insights.length) {
                            object.insights = [];
                            for (var j = 0; j < message.insights.length; ++j)
                                object.insights[j] = $root.palexy.insights.v1.Insight.toObject(message.insights[j], options);
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this BatchUpdateInsightsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.BatchUpdateInsightsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchUpdateInsightsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchUpdateInsightsRequest;
                })();
    
                v1.BatchCreateOrUpdateInsightsRequest = (function() {
    
                    /**
                     * Properties of a BatchCreateOrUpdateInsightsRequest.
                     * @memberof palexy.insights.v1
                     * @interface IBatchCreateOrUpdateInsightsRequest
                     * @property {Array.<palexy.insights.v1.IInsight>|null} [insights] BatchCreateOrUpdateInsightsRequest insights
                     * @property {number|null} [companyId] BatchCreateOrUpdateInsightsRequest companyId
                     * @property {Array.<number>|null} [storeIds] BatchCreateOrUpdateInsightsRequest storeIds
                     * @property {palexy.insights.v1.InsightType|null} [type] BatchCreateOrUpdateInsightsRequest type
                     * @property {number|null} [startDateId] BatchCreateOrUpdateInsightsRequest startDateId
                     * @property {number|null} [endDateId] BatchCreateOrUpdateInsightsRequest endDateId
                     * @property {boolean|null} [invalidateExisting] BatchCreateOrUpdateInsightsRequest invalidateExisting
                     */
    
                    /**
                     * Constructs a new BatchCreateOrUpdateInsightsRequest.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a BatchCreateOrUpdateInsightsRequest.
                     * @implements IBatchCreateOrUpdateInsightsRequest
                     * @constructor
                     * @param {palexy.insights.v1.IBatchCreateOrUpdateInsightsRequest=} [properties] Properties to set
                     */
                    function BatchCreateOrUpdateInsightsRequest(properties) {
                        this.insights = [];
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * BatchCreateOrUpdateInsightsRequest insights.
                     * @member {Array.<palexy.insights.v1.IInsight>} insights
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @instance
                     */
                    BatchCreateOrUpdateInsightsRequest.prototype.insights = $util.emptyArray;
    
                    /**
                     * BatchCreateOrUpdateInsightsRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @instance
                     */
                    BatchCreateOrUpdateInsightsRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * BatchCreateOrUpdateInsightsRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @instance
                     */
                    BatchCreateOrUpdateInsightsRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * BatchCreateOrUpdateInsightsRequest type.
                     * @member {palexy.insights.v1.InsightType} type
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @instance
                     */
                    BatchCreateOrUpdateInsightsRequest.prototype.type = 0;
    
                    /**
                     * BatchCreateOrUpdateInsightsRequest startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @instance
                     */
                    BatchCreateOrUpdateInsightsRequest.prototype.startDateId = 0;
    
                    /**
                     * BatchCreateOrUpdateInsightsRequest endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @instance
                     */
                    BatchCreateOrUpdateInsightsRequest.prototype.endDateId = 0;
    
                    /**
                     * BatchCreateOrUpdateInsightsRequest invalidateExisting.
                     * @member {boolean} invalidateExisting
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @instance
                     */
                    BatchCreateOrUpdateInsightsRequest.prototype.invalidateExisting = false;
    
                    /**
                     * Creates a new BatchCreateOrUpdateInsightsRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IBatchCreateOrUpdateInsightsRequest=} [properties] Properties to set
                     * @returns {palexy.insights.v1.BatchCreateOrUpdateInsightsRequest} BatchCreateOrUpdateInsightsRequest instance
                     */
                    BatchCreateOrUpdateInsightsRequest.create = function create(properties) {
                        return new BatchCreateOrUpdateInsightsRequest(properties);
                    };
    
                    /**
                     * Encodes the specified BatchCreateOrUpdateInsightsRequest message. Does not implicitly {@link palexy.insights.v1.BatchCreateOrUpdateInsightsRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IBatchCreateOrUpdateInsightsRequest} message BatchCreateOrUpdateInsightsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateOrUpdateInsightsRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.insights != null && message.insights.length)
                            for (var i = 0; i < message.insights.length; ++i)
                                $root.palexy.insights.v1.Insight.encode(message.insights[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.endDateId);
                        if (message.invalidateExisting != null && Object.hasOwnProperty.call(message, "invalidateExisting"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.invalidateExisting);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified BatchCreateOrUpdateInsightsRequest message, length delimited. Does not implicitly {@link palexy.insights.v1.BatchCreateOrUpdateInsightsRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.IBatchCreateOrUpdateInsightsRequest} message BatchCreateOrUpdateInsightsRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BatchCreateOrUpdateInsightsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a BatchCreateOrUpdateInsightsRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.BatchCreateOrUpdateInsightsRequest} BatchCreateOrUpdateInsightsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateOrUpdateInsightsRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.BatchCreateOrUpdateInsightsRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.insights && message.insights.length))
                                    message.insights = [];
                                message.insights.push($root.palexy.insights.v1.Insight.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.companyId = reader.int64();
                                break;
                            case 3:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 4:
                                message.type = reader.int32();
                                break;
                            case 5:
                                message.startDateId = reader.int32();
                                break;
                            case 6:
                                message.endDateId = reader.int32();
                                break;
                            case 7:
                                message.invalidateExisting = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a BatchCreateOrUpdateInsightsRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.BatchCreateOrUpdateInsightsRequest} BatchCreateOrUpdateInsightsRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BatchCreateOrUpdateInsightsRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a BatchCreateOrUpdateInsightsRequest message.
                     * @function verify
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BatchCreateOrUpdateInsightsRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.insights != null && message.hasOwnProperty("insights")) {
                            if (!Array.isArray(message.insights))
                                return "insights: array expected";
                            for (var i = 0; i < message.insights.length; ++i) {
                                var error = $root.palexy.insights.v1.Insight.verify(message.insights[i]);
                                if (error)
                                    return "insights." + error;
                            }
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        if (message.invalidateExisting != null && message.hasOwnProperty("invalidateExisting"))
                            if (typeof message.invalidateExisting !== "boolean")
                                return "invalidateExisting: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a BatchCreateOrUpdateInsightsRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.BatchCreateOrUpdateInsightsRequest} BatchCreateOrUpdateInsightsRequest
                     */
                    BatchCreateOrUpdateInsightsRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.BatchCreateOrUpdateInsightsRequest)
                            return object;
                        var message = new $root.palexy.insights.v1.BatchCreateOrUpdateInsightsRequest();
                        if (object.insights) {
                            if (!Array.isArray(object.insights))
                                throw TypeError(".palexy.insights.v1.BatchCreateOrUpdateInsightsRequest.insights: array expected");
                            message.insights = [];
                            for (var i = 0; i < object.insights.length; ++i) {
                                if (typeof object.insights[i] !== "object")
                                    throw TypeError(".palexy.insights.v1.BatchCreateOrUpdateInsightsRequest.insights: object expected");
                                message.insights[i] = $root.palexy.insights.v1.Insight.fromObject(object.insights[i]);
                            }
                        }
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.insights.v1.BatchCreateOrUpdateInsightsRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        switch (object.type) {
                        case "INSIGHT_TYPE_UNKNOWN":
                        case 0:
                            message.type = 0;
                            break;
                        case "THREE_WEEKS_TREND":
                        case 1:
                            message.type = 1;
                            break;
                        }
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        if (object.invalidateExisting != null)
                            message.invalidateExisting = Boolean(object.invalidateExisting);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a BatchCreateOrUpdateInsightsRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @static
                     * @param {palexy.insights.v1.BatchCreateOrUpdateInsightsRequest} message BatchCreateOrUpdateInsightsRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BatchCreateOrUpdateInsightsRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.insights = [];
                            object.storeIds = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.type = options.enums === String ? "INSIGHT_TYPE_UNKNOWN" : 0;
                            object.startDateId = 0;
                            object.endDateId = 0;
                            object.invalidateExisting = false;
                        }
                        if (message.insights && message.insights.length) {
                            object.insights = [];
                            for (var j = 0; j < message.insights.length; ++j)
                                object.insights[j] = $root.palexy.insights.v1.Insight.toObject(message.insights[j], options);
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.palexy.insights.v1.InsightType[message.type] : message.type;
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.invalidateExisting != null && message.hasOwnProperty("invalidateExisting"))
                            object.invalidateExisting = message.invalidateExisting;
                        return object;
                    };
    
                    /**
                     * Converts this BatchCreateOrUpdateInsightsRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.BatchCreateOrUpdateInsightsRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BatchCreateOrUpdateInsightsRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return BatchCreateOrUpdateInsightsRequest;
                })();
    
                v1.InsightGeneratorService = (function() {
    
                    /**
                     * Constructs a new InsightGeneratorService service.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents an InsightGeneratorService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function InsightGeneratorService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (InsightGeneratorService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = InsightGeneratorService;
    
                    /**
                     * Creates new InsightGeneratorService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.insights.v1.InsightGeneratorService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {InsightGeneratorService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    InsightGeneratorService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.insights.v1.InsightGeneratorService#generateInsight}.
                     * @memberof palexy.insights.v1.InsightGeneratorService
                     * @typedef GenerateInsightCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.insights.v1.GenerateInsightResponse} [response] GenerateInsightResponse
                     */
    
                    /**
                     * Calls GenerateInsight.
                     * @function generateInsight
                     * @memberof palexy.insights.v1.InsightGeneratorService
                     * @instance
                     * @param {palexy.insights.v1.IGenerateInsightRequest} request GenerateInsightRequest message or plain object
                     * @param {palexy.insights.v1.InsightGeneratorService.GenerateInsightCallback} callback Node-style callback called with the error, if any, and GenerateInsightResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightGeneratorService.prototype.generateInsight = function generateInsight(request, callback) {
                        return this.rpcCall(generateInsight, $root.palexy.insights.v1.GenerateInsightRequest, $root.palexy.insights.v1.GenerateInsightResponse, request, callback);
                    }, "name", { value: "GenerateInsight" });
    
                    /**
                     * Calls GenerateInsight.
                     * @function generateInsight
                     * @memberof palexy.insights.v1.InsightGeneratorService
                     * @instance
                     * @param {palexy.insights.v1.IGenerateInsightRequest} request GenerateInsightRequest message or plain object
                     * @returns {Promise<palexy.insights.v1.GenerateInsightResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.insights.v1.InsightGeneratorService#revalidateInsight}.
                     * @memberof palexy.insights.v1.InsightGeneratorService
                     * @typedef RevalidateInsightCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.insights.v1.RevalidateInsightResponse} [response] RevalidateInsightResponse
                     */
    
                    /**
                     * Calls RevalidateInsight.
                     * @function revalidateInsight
                     * @memberof palexy.insights.v1.InsightGeneratorService
                     * @instance
                     * @param {palexy.insights.v1.IRevalidateInsightRequest} request RevalidateInsightRequest message or plain object
                     * @param {palexy.insights.v1.InsightGeneratorService.RevalidateInsightCallback} callback Node-style callback called with the error, if any, and RevalidateInsightResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(InsightGeneratorService.prototype.revalidateInsight = function revalidateInsight(request, callback) {
                        return this.rpcCall(revalidateInsight, $root.palexy.insights.v1.RevalidateInsightRequest, $root.palexy.insights.v1.RevalidateInsightResponse, request, callback);
                    }, "name", { value: "RevalidateInsight" });
    
                    /**
                     * Calls RevalidateInsight.
                     * @function revalidateInsight
                     * @memberof palexy.insights.v1.InsightGeneratorService
                     * @instance
                     * @param {palexy.insights.v1.IRevalidateInsightRequest} request RevalidateInsightRequest message or plain object
                     * @returns {Promise<palexy.insights.v1.RevalidateInsightResponse>} Promise
                     * @variation 2
                     */
    
                    return InsightGeneratorService;
                })();
    
                v1.GenerateInsightRequest = (function() {
    
                    /**
                     * Properties of a GenerateInsightRequest.
                     * @memberof palexy.insights.v1
                     * @interface IGenerateInsightRequest
                     * @property {number|null} [companyId] GenerateInsightRequest companyId
                     * @property {Array.<number>|null} [storeIds] GenerateInsightRequest storeIds
                     * @property {palexy.insights.v1.InsightType|null} [insightType] GenerateInsightRequest insightType
                     * @property {number|null} [startDateId] GenerateInsightRequest startDateId
                     * @property {number|null} [endDateId] GenerateInsightRequest endDateId
                     */
    
                    /**
                     * Constructs a new GenerateInsightRequest.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a GenerateInsightRequest.
                     * @implements IGenerateInsightRequest
                     * @constructor
                     * @param {palexy.insights.v1.IGenerateInsightRequest=} [properties] Properties to set
                     */
                    function GenerateInsightRequest(properties) {
                        this.storeIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GenerateInsightRequest companyId.
                     * @member {number} companyId
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @instance
                     */
                    GenerateInsightRequest.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GenerateInsightRequest storeIds.
                     * @member {Array.<number>} storeIds
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @instance
                     */
                    GenerateInsightRequest.prototype.storeIds = $util.emptyArray;
    
                    /**
                     * GenerateInsightRequest insightType.
                     * @member {palexy.insights.v1.InsightType} insightType
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @instance
                     */
                    GenerateInsightRequest.prototype.insightType = 0;
    
                    /**
                     * GenerateInsightRequest startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @instance
                     */
                    GenerateInsightRequest.prototype.startDateId = 0;
    
                    /**
                     * GenerateInsightRequest endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @instance
                     */
                    GenerateInsightRequest.prototype.endDateId = 0;
    
                    /**
                     * Creates a new GenerateInsightRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IGenerateInsightRequest=} [properties] Properties to set
                     * @returns {palexy.insights.v1.GenerateInsightRequest} GenerateInsightRequest instance
                     */
                    GenerateInsightRequest.create = function create(properties) {
                        return new GenerateInsightRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GenerateInsightRequest message. Does not implicitly {@link palexy.insights.v1.GenerateInsightRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IGenerateInsightRequest} message GenerateInsightRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateInsightRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                        if (message.storeIds != null && message.storeIds.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.storeIds.length; ++i)
                                writer.int64(message.storeIds[i]);
                            writer.ldelim();
                        }
                        if (message.insightType != null && Object.hasOwnProperty.call(message, "insightType"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.insightType);
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.endDateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GenerateInsightRequest message, length delimited. Does not implicitly {@link palexy.insights.v1.GenerateInsightRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IGenerateInsightRequest} message GenerateInsightRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateInsightRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GenerateInsightRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.GenerateInsightRequest} GenerateInsightRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateInsightRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.GenerateInsightRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.companyId = reader.int64();
                                break;
                            case 2:
                                if (!(message.storeIds && message.storeIds.length))
                                    message.storeIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.storeIds.push(reader.int64());
                                } else
                                    message.storeIds.push(reader.int64());
                                break;
                            case 3:
                                message.insightType = reader.int32();
                                break;
                            case 4:
                                message.startDateId = reader.int32();
                                break;
                            case 5:
                                message.endDateId = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GenerateInsightRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.GenerateInsightRequest} GenerateInsightRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateInsightRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GenerateInsightRequest message.
                     * @function verify
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GenerateInsightRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                                return "companyId: integer|Long expected";
                        if (message.storeIds != null && message.hasOwnProperty("storeIds")) {
                            if (!Array.isArray(message.storeIds))
                                return "storeIds: array expected";
                            for (var i = 0; i < message.storeIds.length; ++i)
                                if (!$util.isInteger(message.storeIds[i]) && !(message.storeIds[i] && $util.isInteger(message.storeIds[i].low) && $util.isInteger(message.storeIds[i].high)))
                                    return "storeIds: integer|Long[] expected";
                        }
                        if (message.insightType != null && message.hasOwnProperty("insightType"))
                            switch (message.insightType) {
                            default:
                                return "insightType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a GenerateInsightRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.GenerateInsightRequest} GenerateInsightRequest
                     */
                    GenerateInsightRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.GenerateInsightRequest)
                            return object;
                        var message = new $root.palexy.insights.v1.GenerateInsightRequest();
                        if (object.companyId != null)
                            if ($util.Long)
                                (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                            else if (typeof object.companyId === "string")
                                message.companyId = parseInt(object.companyId, 10);
                            else if (typeof object.companyId === "number")
                                message.companyId = object.companyId;
                            else if (typeof object.companyId === "object")
                                message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                        if (object.storeIds) {
                            if (!Array.isArray(object.storeIds))
                                throw TypeError(".palexy.insights.v1.GenerateInsightRequest.storeIds: array expected");
                            message.storeIds = [];
                            for (var i = 0; i < object.storeIds.length; ++i)
                                if ($util.Long)
                                    (message.storeIds[i] = $util.Long.fromValue(object.storeIds[i])).unsigned = false;
                                else if (typeof object.storeIds[i] === "string")
                                    message.storeIds[i] = parseInt(object.storeIds[i], 10);
                                else if (typeof object.storeIds[i] === "number")
                                    message.storeIds[i] = object.storeIds[i];
                                else if (typeof object.storeIds[i] === "object")
                                    message.storeIds[i] = new $util.LongBits(object.storeIds[i].low >>> 0, object.storeIds[i].high >>> 0).toNumber();
                        }
                        switch (object.insightType) {
                        case "INSIGHT_TYPE_UNKNOWN":
                        case 0:
                            message.insightType = 0;
                            break;
                        case "THREE_WEEKS_TREND":
                        case 1:
                            message.insightType = 1;
                            break;
                        }
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GenerateInsightRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.GenerateInsightRequest} message GenerateInsightRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GenerateInsightRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.storeIds = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.companyId = options.longs === String ? "0" : 0;
                            object.insightType = options.enums === String ? "INSIGHT_TYPE_UNKNOWN" : 0;
                            object.startDateId = 0;
                            object.endDateId = 0;
                        }
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (typeof message.companyId === "number")
                                object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                            else
                                object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                        if (message.storeIds && message.storeIds.length) {
                            object.storeIds = [];
                            for (var j = 0; j < message.storeIds.length; ++j)
                                if (typeof message.storeIds[j] === "number")
                                    object.storeIds[j] = options.longs === String ? String(message.storeIds[j]) : message.storeIds[j];
                                else
                                    object.storeIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.storeIds[j]) : options.longs === Number ? new $util.LongBits(message.storeIds[j].low >>> 0, message.storeIds[j].high >>> 0).toNumber() : message.storeIds[j];
                        }
                        if (message.insightType != null && message.hasOwnProperty("insightType"))
                            object.insightType = options.enums === String ? $root.palexy.insights.v1.InsightType[message.insightType] : message.insightType;
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        return object;
                    };
    
                    /**
                     * Converts this GenerateInsightRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.GenerateInsightRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GenerateInsightRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GenerateInsightRequest;
                })();
    
                v1.GenerateInsightResponse = (function() {
    
                    /**
                     * Properties of a GenerateInsightResponse.
                     * @memberof palexy.insights.v1
                     * @interface IGenerateInsightResponse
                     * @property {Array.<palexy.insights.v1.IInsight>|null} [insights] GenerateInsightResponse insights
                     */
    
                    /**
                     * Constructs a new GenerateInsightResponse.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a GenerateInsightResponse.
                     * @implements IGenerateInsightResponse
                     * @constructor
                     * @param {palexy.insights.v1.IGenerateInsightResponse=} [properties] Properties to set
                     */
                    function GenerateInsightResponse(properties) {
                        this.insights = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GenerateInsightResponse insights.
                     * @member {Array.<palexy.insights.v1.IInsight>} insights
                     * @memberof palexy.insights.v1.GenerateInsightResponse
                     * @instance
                     */
                    GenerateInsightResponse.prototype.insights = $util.emptyArray;
    
                    /**
                     * Creates a new GenerateInsightResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.GenerateInsightResponse
                     * @static
                     * @param {palexy.insights.v1.IGenerateInsightResponse=} [properties] Properties to set
                     * @returns {palexy.insights.v1.GenerateInsightResponse} GenerateInsightResponse instance
                     */
                    GenerateInsightResponse.create = function create(properties) {
                        return new GenerateInsightResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GenerateInsightResponse message. Does not implicitly {@link palexy.insights.v1.GenerateInsightResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.GenerateInsightResponse
                     * @static
                     * @param {palexy.insights.v1.IGenerateInsightResponse} message GenerateInsightResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateInsightResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.insights != null && message.insights.length)
                            for (var i = 0; i < message.insights.length; ++i)
                                $root.palexy.insights.v1.Insight.encode(message.insights[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GenerateInsightResponse message, length delimited. Does not implicitly {@link palexy.insights.v1.GenerateInsightResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.GenerateInsightResponse
                     * @static
                     * @param {palexy.insights.v1.IGenerateInsightResponse} message GenerateInsightResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateInsightResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GenerateInsightResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.GenerateInsightResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.GenerateInsightResponse} GenerateInsightResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateInsightResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.GenerateInsightResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.insights && message.insights.length))
                                    message.insights = [];
                                message.insights.push($root.palexy.insights.v1.Insight.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GenerateInsightResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.GenerateInsightResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.GenerateInsightResponse} GenerateInsightResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateInsightResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GenerateInsightResponse message.
                     * @function verify
                     * @memberof palexy.insights.v1.GenerateInsightResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GenerateInsightResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.insights != null && message.hasOwnProperty("insights")) {
                            if (!Array.isArray(message.insights))
                                return "insights: array expected";
                            for (var i = 0; i < message.insights.length; ++i) {
                                var error = $root.palexy.insights.v1.Insight.verify(message.insights[i]);
                                if (error)
                                    return "insights." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GenerateInsightResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.GenerateInsightResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.GenerateInsightResponse} GenerateInsightResponse
                     */
                    GenerateInsightResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.GenerateInsightResponse)
                            return object;
                        var message = new $root.palexy.insights.v1.GenerateInsightResponse();
                        if (object.insights) {
                            if (!Array.isArray(object.insights))
                                throw TypeError(".palexy.insights.v1.GenerateInsightResponse.insights: array expected");
                            message.insights = [];
                            for (var i = 0; i < object.insights.length; ++i) {
                                if (typeof object.insights[i] !== "object")
                                    throw TypeError(".palexy.insights.v1.GenerateInsightResponse.insights: object expected");
                                message.insights[i] = $root.palexy.insights.v1.Insight.fromObject(object.insights[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GenerateInsightResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.GenerateInsightResponse
                     * @static
                     * @param {palexy.insights.v1.GenerateInsightResponse} message GenerateInsightResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GenerateInsightResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.insights = [];
                        if (message.insights && message.insights.length) {
                            object.insights = [];
                            for (var j = 0; j < message.insights.length; ++j)
                                object.insights[j] = $root.palexy.insights.v1.Insight.toObject(message.insights[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GenerateInsightResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.GenerateInsightResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GenerateInsightResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GenerateInsightResponse;
                })();
    
                v1.RevalidateInsightRequest = (function() {
    
                    /**
                     * Properties of a RevalidateInsightRequest.
                     * @memberof palexy.insights.v1
                     * @interface IRevalidateInsightRequest
                     * @property {Array.<palexy.insights.v1.IInsight>|null} [insights] RevalidateInsightRequest insights
                     */
    
                    /**
                     * Constructs a new RevalidateInsightRequest.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a RevalidateInsightRequest.
                     * @implements IRevalidateInsightRequest
                     * @constructor
                     * @param {palexy.insights.v1.IRevalidateInsightRequest=} [properties] Properties to set
                     */
                    function RevalidateInsightRequest(properties) {
                        this.insights = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RevalidateInsightRequest insights.
                     * @member {Array.<palexy.insights.v1.IInsight>} insights
                     * @memberof palexy.insights.v1.RevalidateInsightRequest
                     * @instance
                     */
                    RevalidateInsightRequest.prototype.insights = $util.emptyArray;
    
                    /**
                     * Creates a new RevalidateInsightRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.RevalidateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IRevalidateInsightRequest=} [properties] Properties to set
                     * @returns {palexy.insights.v1.RevalidateInsightRequest} RevalidateInsightRequest instance
                     */
                    RevalidateInsightRequest.create = function create(properties) {
                        return new RevalidateInsightRequest(properties);
                    };
    
                    /**
                     * Encodes the specified RevalidateInsightRequest message. Does not implicitly {@link palexy.insights.v1.RevalidateInsightRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.RevalidateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IRevalidateInsightRequest} message RevalidateInsightRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RevalidateInsightRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.insights != null && message.insights.length)
                            for (var i = 0; i < message.insights.length; ++i)
                                $root.palexy.insights.v1.Insight.encode(message.insights[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RevalidateInsightRequest message, length delimited. Does not implicitly {@link palexy.insights.v1.RevalidateInsightRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.RevalidateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.IRevalidateInsightRequest} message RevalidateInsightRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RevalidateInsightRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RevalidateInsightRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.RevalidateInsightRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.RevalidateInsightRequest} RevalidateInsightRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RevalidateInsightRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.RevalidateInsightRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.insights && message.insights.length))
                                    message.insights = [];
                                message.insights.push($root.palexy.insights.v1.Insight.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RevalidateInsightRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.RevalidateInsightRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.RevalidateInsightRequest} RevalidateInsightRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RevalidateInsightRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RevalidateInsightRequest message.
                     * @function verify
                     * @memberof palexy.insights.v1.RevalidateInsightRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RevalidateInsightRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.insights != null && message.hasOwnProperty("insights")) {
                            if (!Array.isArray(message.insights))
                                return "insights: array expected";
                            for (var i = 0; i < message.insights.length; ++i) {
                                var error = $root.palexy.insights.v1.Insight.verify(message.insights[i]);
                                if (error)
                                    return "insights." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a RevalidateInsightRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.RevalidateInsightRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.RevalidateInsightRequest} RevalidateInsightRequest
                     */
                    RevalidateInsightRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.RevalidateInsightRequest)
                            return object;
                        var message = new $root.palexy.insights.v1.RevalidateInsightRequest();
                        if (object.insights) {
                            if (!Array.isArray(object.insights))
                                throw TypeError(".palexy.insights.v1.RevalidateInsightRequest.insights: array expected");
                            message.insights = [];
                            for (var i = 0; i < object.insights.length; ++i) {
                                if (typeof object.insights[i] !== "object")
                                    throw TypeError(".palexy.insights.v1.RevalidateInsightRequest.insights: object expected");
                                message.insights[i] = $root.palexy.insights.v1.Insight.fromObject(object.insights[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RevalidateInsightRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.RevalidateInsightRequest
                     * @static
                     * @param {palexy.insights.v1.RevalidateInsightRequest} message RevalidateInsightRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RevalidateInsightRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.insights = [];
                        if (message.insights && message.insights.length) {
                            object.insights = [];
                            for (var j = 0; j < message.insights.length; ++j)
                                object.insights[j] = $root.palexy.insights.v1.Insight.toObject(message.insights[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this RevalidateInsightRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.RevalidateInsightRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RevalidateInsightRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RevalidateInsightRequest;
                })();
    
                v1.RevalidateInsightResponse = (function() {
    
                    /**
                     * Properties of a RevalidateInsightResponse.
                     * @memberof palexy.insights.v1
                     * @interface IRevalidateInsightResponse
                     * @property {Array.<palexy.insights.v1.IInsight>|null} [insights] RevalidateInsightResponse insights
                     */
    
                    /**
                     * Constructs a new RevalidateInsightResponse.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a RevalidateInsightResponse.
                     * @implements IRevalidateInsightResponse
                     * @constructor
                     * @param {palexy.insights.v1.IRevalidateInsightResponse=} [properties] Properties to set
                     */
                    function RevalidateInsightResponse(properties) {
                        this.insights = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RevalidateInsightResponse insights.
                     * @member {Array.<palexy.insights.v1.IInsight>} insights
                     * @memberof palexy.insights.v1.RevalidateInsightResponse
                     * @instance
                     */
                    RevalidateInsightResponse.prototype.insights = $util.emptyArray;
    
                    /**
                     * Creates a new RevalidateInsightResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.RevalidateInsightResponse
                     * @static
                     * @param {palexy.insights.v1.IRevalidateInsightResponse=} [properties] Properties to set
                     * @returns {palexy.insights.v1.RevalidateInsightResponse} RevalidateInsightResponse instance
                     */
                    RevalidateInsightResponse.create = function create(properties) {
                        return new RevalidateInsightResponse(properties);
                    };
    
                    /**
                     * Encodes the specified RevalidateInsightResponse message. Does not implicitly {@link palexy.insights.v1.RevalidateInsightResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.RevalidateInsightResponse
                     * @static
                     * @param {palexy.insights.v1.IRevalidateInsightResponse} message RevalidateInsightResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RevalidateInsightResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.insights != null && message.insights.length)
                            for (var i = 0; i < message.insights.length; ++i)
                                $root.palexy.insights.v1.Insight.encode(message.insights[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RevalidateInsightResponse message, length delimited. Does not implicitly {@link palexy.insights.v1.RevalidateInsightResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.RevalidateInsightResponse
                     * @static
                     * @param {palexy.insights.v1.IRevalidateInsightResponse} message RevalidateInsightResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RevalidateInsightResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RevalidateInsightResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.RevalidateInsightResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.RevalidateInsightResponse} RevalidateInsightResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RevalidateInsightResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.RevalidateInsightResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 2:
                                if (!(message.insights && message.insights.length))
                                    message.insights = [];
                                message.insights.push($root.palexy.insights.v1.Insight.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RevalidateInsightResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.RevalidateInsightResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.RevalidateInsightResponse} RevalidateInsightResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RevalidateInsightResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RevalidateInsightResponse message.
                     * @function verify
                     * @memberof palexy.insights.v1.RevalidateInsightResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RevalidateInsightResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.insights != null && message.hasOwnProperty("insights")) {
                            if (!Array.isArray(message.insights))
                                return "insights: array expected";
                            for (var i = 0; i < message.insights.length; ++i) {
                                var error = $root.palexy.insights.v1.Insight.verify(message.insights[i]);
                                if (error)
                                    return "insights." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a RevalidateInsightResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.RevalidateInsightResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.RevalidateInsightResponse} RevalidateInsightResponse
                     */
                    RevalidateInsightResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.RevalidateInsightResponse)
                            return object;
                        var message = new $root.palexy.insights.v1.RevalidateInsightResponse();
                        if (object.insights) {
                            if (!Array.isArray(object.insights))
                                throw TypeError(".palexy.insights.v1.RevalidateInsightResponse.insights: array expected");
                            message.insights = [];
                            for (var i = 0; i < object.insights.length; ++i) {
                                if (typeof object.insights[i] !== "object")
                                    throw TypeError(".palexy.insights.v1.RevalidateInsightResponse.insights: object expected");
                                message.insights[i] = $root.palexy.insights.v1.Insight.fromObject(object.insights[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RevalidateInsightResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.RevalidateInsightResponse
                     * @static
                     * @param {palexy.insights.v1.RevalidateInsightResponse} message RevalidateInsightResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RevalidateInsightResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.insights = [];
                        if (message.insights && message.insights.length) {
                            object.insights = [];
                            for (var j = 0; j < message.insights.length; ++j)
                                object.insights[j] = $root.palexy.insights.v1.Insight.toObject(message.insights[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this RevalidateInsightResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.RevalidateInsightResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RevalidateInsightResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RevalidateInsightResponse;
                })();
    
                v1.AutoScheduleService = (function() {
    
                    /**
                     * Constructs a new AutoScheduleService service.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents an AutoScheduleService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function AutoScheduleService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (AutoScheduleService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AutoScheduleService;
    
                    /**
                     * Creates new AutoScheduleService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.insights.v1.AutoScheduleService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {AutoScheduleService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    AutoScheduleService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.insights.v1.AutoScheduleService#generateRecommendedSchedule}.
                     * @memberof palexy.insights.v1.AutoScheduleService
                     * @typedef GenerateRecommendedScheduleCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse} [response] GenerateRecommendedScheduleResponse
                     */
    
                    /**
                     * Calls GenerateRecommendedSchedule.
                     * @function generateRecommendedSchedule
                     * @memberof palexy.insights.v1.AutoScheduleService
                     * @instance
                     * @param {palexy.insights.v1.IGenerateRecommendedScheduleRequest} request GenerateRecommendedScheduleRequest message or plain object
                     * @param {palexy.insights.v1.AutoScheduleService.GenerateRecommendedScheduleCallback} callback Node-style callback called with the error, if any, and GenerateRecommendedScheduleResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AutoScheduleService.prototype.generateRecommendedSchedule = function generateRecommendedSchedule(request, callback) {
                        return this.rpcCall(generateRecommendedSchedule, $root.palexy.insights.v1.GenerateRecommendedScheduleRequest, $root.palexy.insights.v1.GenerateRecommendedScheduleResponse, request, callback);
                    }, "name", { value: "GenerateRecommendedSchedule" });
    
                    /**
                     * Calls GenerateRecommendedSchedule.
                     * @function generateRecommendedSchedule
                     * @memberof palexy.insights.v1.AutoScheduleService
                     * @instance
                     * @param {palexy.insights.v1.IGenerateRecommendedScheduleRequest} request GenerateRecommendedScheduleRequest message or plain object
                     * @returns {Promise<palexy.insights.v1.GenerateRecommendedScheduleResponse>} Promise
                     * @variation 2
                     */
    
                    return AutoScheduleService;
                })();
    
                v1.GenerateRecommendedScheduleRequest = (function() {
    
                    /**
                     * Properties of a GenerateRecommendedScheduleRequest.
                     * @memberof palexy.insights.v1
                     * @interface IGenerateRecommendedScheduleRequest
                     * @property {number|null} [storeId] GenerateRecommendedScheduleRequest storeId
                     * @property {number|null} [startDateId] GenerateRecommendedScheduleRequest startDateId
                     * @property {number|null} [endDateId] GenerateRecommendedScheduleRequest endDateId
                     * @property {Array.<number>|null} [shiftTemplateIds] GenerateRecommendedScheduleRequest shiftTemplateIds
                     * @property {Array.<number>|null} [staffIds] GenerateRecommendedScheduleRequest staffIds
                     */
    
                    /**
                     * Constructs a new GenerateRecommendedScheduleRequest.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a GenerateRecommendedScheduleRequest.
                     * @implements IGenerateRecommendedScheduleRequest
                     * @constructor
                     * @param {palexy.insights.v1.IGenerateRecommendedScheduleRequest=} [properties] Properties to set
                     */
                    function GenerateRecommendedScheduleRequest(properties) {
                        this.shiftTemplateIds = [];
                        this.staffIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GenerateRecommendedScheduleRequest storeId.
                     * @member {number} storeId
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @instance
                     */
                    GenerateRecommendedScheduleRequest.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GenerateRecommendedScheduleRequest startDateId.
                     * @member {number} startDateId
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @instance
                     */
                    GenerateRecommendedScheduleRequest.prototype.startDateId = 0;
    
                    /**
                     * GenerateRecommendedScheduleRequest endDateId.
                     * @member {number} endDateId
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @instance
                     */
                    GenerateRecommendedScheduleRequest.prototype.endDateId = 0;
    
                    /**
                     * GenerateRecommendedScheduleRequest shiftTemplateIds.
                     * @member {Array.<number>} shiftTemplateIds
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @instance
                     */
                    GenerateRecommendedScheduleRequest.prototype.shiftTemplateIds = $util.emptyArray;
    
                    /**
                     * GenerateRecommendedScheduleRequest staffIds.
                     * @member {Array.<number>} staffIds
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @instance
                     */
                    GenerateRecommendedScheduleRequest.prototype.staffIds = $util.emptyArray;
    
                    /**
                     * Creates a new GenerateRecommendedScheduleRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @static
                     * @param {palexy.insights.v1.IGenerateRecommendedScheduleRequest=} [properties] Properties to set
                     * @returns {palexy.insights.v1.GenerateRecommendedScheduleRequest} GenerateRecommendedScheduleRequest instance
                     */
                    GenerateRecommendedScheduleRequest.create = function create(properties) {
                        return new GenerateRecommendedScheduleRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GenerateRecommendedScheduleRequest message. Does not implicitly {@link palexy.insights.v1.GenerateRecommendedScheduleRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @static
                     * @param {palexy.insights.v1.IGenerateRecommendedScheduleRequest} message GenerateRecommendedScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateRecommendedScheduleRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                        if (message.startDateId != null && Object.hasOwnProperty.call(message, "startDateId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.startDateId);
                        if (message.endDateId != null && Object.hasOwnProperty.call(message, "endDateId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.endDateId);
                        if (message.shiftTemplateIds != null && message.shiftTemplateIds.length) {
                            writer.uint32(/* id 4, wireType 2 =*/34).fork();
                            for (var i = 0; i < message.shiftTemplateIds.length; ++i)
                                writer.int64(message.shiftTemplateIds[i]);
                            writer.ldelim();
                        }
                        if (message.staffIds != null && message.staffIds.length) {
                            writer.uint32(/* id 5, wireType 2 =*/42).fork();
                            for (var i = 0; i < message.staffIds.length; ++i)
                                writer.int64(message.staffIds[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GenerateRecommendedScheduleRequest message, length delimited. Does not implicitly {@link palexy.insights.v1.GenerateRecommendedScheduleRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @static
                     * @param {palexy.insights.v1.IGenerateRecommendedScheduleRequest} message GenerateRecommendedScheduleRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateRecommendedScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GenerateRecommendedScheduleRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.GenerateRecommendedScheduleRequest} GenerateRecommendedScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateRecommendedScheduleRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.GenerateRecommendedScheduleRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.int64();
                                break;
                            case 2:
                                message.startDateId = reader.int32();
                                break;
                            case 3:
                                message.endDateId = reader.int32();
                                break;
                            case 4:
                                if (!(message.shiftTemplateIds && message.shiftTemplateIds.length))
                                    message.shiftTemplateIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.shiftTemplateIds.push(reader.int64());
                                } else
                                    message.shiftTemplateIds.push(reader.int64());
                                break;
                            case 5:
                                if (!(message.staffIds && message.staffIds.length))
                                    message.staffIds = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.staffIds.push(reader.int64());
                                } else
                                    message.staffIds.push(reader.int64());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GenerateRecommendedScheduleRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.GenerateRecommendedScheduleRequest} GenerateRecommendedScheduleRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateRecommendedScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GenerateRecommendedScheduleRequest message.
                     * @function verify
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GenerateRecommendedScheduleRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            if (!$util.isInteger(message.startDateId))
                                return "startDateId: integer expected";
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            if (!$util.isInteger(message.endDateId))
                                return "endDateId: integer expected";
                        if (message.shiftTemplateIds != null && message.hasOwnProperty("shiftTemplateIds")) {
                            if (!Array.isArray(message.shiftTemplateIds))
                                return "shiftTemplateIds: array expected";
                            for (var i = 0; i < message.shiftTemplateIds.length; ++i)
                                if (!$util.isInteger(message.shiftTemplateIds[i]) && !(message.shiftTemplateIds[i] && $util.isInteger(message.shiftTemplateIds[i].low) && $util.isInteger(message.shiftTemplateIds[i].high)))
                                    return "shiftTemplateIds: integer|Long[] expected";
                        }
                        if (message.staffIds != null && message.hasOwnProperty("staffIds")) {
                            if (!Array.isArray(message.staffIds))
                                return "staffIds: array expected";
                            for (var i = 0; i < message.staffIds.length; ++i)
                                if (!$util.isInteger(message.staffIds[i]) && !(message.staffIds[i] && $util.isInteger(message.staffIds[i].low) && $util.isInteger(message.staffIds[i].high)))
                                    return "staffIds: integer|Long[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GenerateRecommendedScheduleRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.GenerateRecommendedScheduleRequest} GenerateRecommendedScheduleRequest
                     */
                    GenerateRecommendedScheduleRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.GenerateRecommendedScheduleRequest)
                            return object;
                        var message = new $root.palexy.insights.v1.GenerateRecommendedScheduleRequest();
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.startDateId != null)
                            message.startDateId = object.startDateId | 0;
                        if (object.endDateId != null)
                            message.endDateId = object.endDateId | 0;
                        if (object.shiftTemplateIds) {
                            if (!Array.isArray(object.shiftTemplateIds))
                                throw TypeError(".palexy.insights.v1.GenerateRecommendedScheduleRequest.shiftTemplateIds: array expected");
                            message.shiftTemplateIds = [];
                            for (var i = 0; i < object.shiftTemplateIds.length; ++i)
                                if ($util.Long)
                                    (message.shiftTemplateIds[i] = $util.Long.fromValue(object.shiftTemplateIds[i])).unsigned = false;
                                else if (typeof object.shiftTemplateIds[i] === "string")
                                    message.shiftTemplateIds[i] = parseInt(object.shiftTemplateIds[i], 10);
                                else if (typeof object.shiftTemplateIds[i] === "number")
                                    message.shiftTemplateIds[i] = object.shiftTemplateIds[i];
                                else if (typeof object.shiftTemplateIds[i] === "object")
                                    message.shiftTemplateIds[i] = new $util.LongBits(object.shiftTemplateIds[i].low >>> 0, object.shiftTemplateIds[i].high >>> 0).toNumber();
                        }
                        if (object.staffIds) {
                            if (!Array.isArray(object.staffIds))
                                throw TypeError(".palexy.insights.v1.GenerateRecommendedScheduleRequest.staffIds: array expected");
                            message.staffIds = [];
                            for (var i = 0; i < object.staffIds.length; ++i)
                                if ($util.Long)
                                    (message.staffIds[i] = $util.Long.fromValue(object.staffIds[i])).unsigned = false;
                                else if (typeof object.staffIds[i] === "string")
                                    message.staffIds[i] = parseInt(object.staffIds[i], 10);
                                else if (typeof object.staffIds[i] === "number")
                                    message.staffIds[i] = object.staffIds[i];
                                else if (typeof object.staffIds[i] === "object")
                                    message.staffIds[i] = new $util.LongBits(object.staffIds[i].low >>> 0, object.staffIds[i].high >>> 0).toNumber();
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GenerateRecommendedScheduleRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @static
                     * @param {palexy.insights.v1.GenerateRecommendedScheduleRequest} message GenerateRecommendedScheduleRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GenerateRecommendedScheduleRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.shiftTemplateIds = [];
                            object.staffIds = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.startDateId = 0;
                            object.endDateId = 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.startDateId != null && message.hasOwnProperty("startDateId"))
                            object.startDateId = message.startDateId;
                        if (message.endDateId != null && message.hasOwnProperty("endDateId"))
                            object.endDateId = message.endDateId;
                        if (message.shiftTemplateIds && message.shiftTemplateIds.length) {
                            object.shiftTemplateIds = [];
                            for (var j = 0; j < message.shiftTemplateIds.length; ++j)
                                if (typeof message.shiftTemplateIds[j] === "number")
                                    object.shiftTemplateIds[j] = options.longs === String ? String(message.shiftTemplateIds[j]) : message.shiftTemplateIds[j];
                                else
                                    object.shiftTemplateIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.shiftTemplateIds[j]) : options.longs === Number ? new $util.LongBits(message.shiftTemplateIds[j].low >>> 0, message.shiftTemplateIds[j].high >>> 0).toNumber() : message.shiftTemplateIds[j];
                        }
                        if (message.staffIds && message.staffIds.length) {
                            object.staffIds = [];
                            for (var j = 0; j < message.staffIds.length; ++j)
                                if (typeof message.staffIds[j] === "number")
                                    object.staffIds[j] = options.longs === String ? String(message.staffIds[j]) : message.staffIds[j];
                                else
                                    object.staffIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.staffIds[j]) : options.longs === Number ? new $util.LongBits(message.staffIds[j].low >>> 0, message.staffIds[j].high >>> 0).toNumber() : message.staffIds[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GenerateRecommendedScheduleRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GenerateRecommendedScheduleRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GenerateRecommendedScheduleRequest;
                })();
    
                v1.GenerateRecommendedScheduleResponse = (function() {
    
                    /**
                     * Properties of a GenerateRecommendedScheduleResponse.
                     * @memberof palexy.insights.v1
                     * @interface IGenerateRecommendedScheduleResponse
                     * @property {Array.<palexy.insights.v1.IProposedShift>|null} [proposedShifts] GenerateRecommendedScheduleResponse proposedShifts
                     * @property {palexy.insights.v1.GenerateRecommendedScheduleResponse.IStatisticSummary|null} [oldScheduleSummary] GenerateRecommendedScheduleResponse oldScheduleSummary
                     * @property {palexy.insights.v1.GenerateRecommendedScheduleResponse.IStatisticSummary|null} [newScheduleSummary] GenerateRecommendedScheduleResponse newScheduleSummary
                     * @property {Array.<palexy.insights.v1.GenerateRecommendedScheduleResponse.INotableOverloadedHourChange>|null} [notableOverloadedHourChanges] GenerateRecommendedScheduleResponse notableOverloadedHourChanges
                     */
    
                    /**
                     * Constructs a new GenerateRecommendedScheduleResponse.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a GenerateRecommendedScheduleResponse.
                     * @implements IGenerateRecommendedScheduleResponse
                     * @constructor
                     * @param {palexy.insights.v1.IGenerateRecommendedScheduleResponse=} [properties] Properties to set
                     */
                    function GenerateRecommendedScheduleResponse(properties) {
                        this.proposedShifts = [];
                        this.notableOverloadedHourChanges = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GenerateRecommendedScheduleResponse proposedShifts.
                     * @member {Array.<palexy.insights.v1.IProposedShift>} proposedShifts
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @instance
                     */
                    GenerateRecommendedScheduleResponse.prototype.proposedShifts = $util.emptyArray;
    
                    /**
                     * GenerateRecommendedScheduleResponse oldScheduleSummary.
                     * @member {palexy.insights.v1.GenerateRecommendedScheduleResponse.IStatisticSummary|null|undefined} oldScheduleSummary
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @instance
                     */
                    GenerateRecommendedScheduleResponse.prototype.oldScheduleSummary = null;
    
                    /**
                     * GenerateRecommendedScheduleResponse newScheduleSummary.
                     * @member {palexy.insights.v1.GenerateRecommendedScheduleResponse.IStatisticSummary|null|undefined} newScheduleSummary
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @instance
                     */
                    GenerateRecommendedScheduleResponse.prototype.newScheduleSummary = null;
    
                    /**
                     * GenerateRecommendedScheduleResponse notableOverloadedHourChanges.
                     * @member {Array.<palexy.insights.v1.GenerateRecommendedScheduleResponse.INotableOverloadedHourChange>} notableOverloadedHourChanges
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @instance
                     */
                    GenerateRecommendedScheduleResponse.prototype.notableOverloadedHourChanges = $util.emptyArray;
    
                    /**
                     * Creates a new GenerateRecommendedScheduleResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @static
                     * @param {palexy.insights.v1.IGenerateRecommendedScheduleResponse=} [properties] Properties to set
                     * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse} GenerateRecommendedScheduleResponse instance
                     */
                    GenerateRecommendedScheduleResponse.create = function create(properties) {
                        return new GenerateRecommendedScheduleResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GenerateRecommendedScheduleResponse message. Does not implicitly {@link palexy.insights.v1.GenerateRecommendedScheduleResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @static
                     * @param {palexy.insights.v1.IGenerateRecommendedScheduleResponse} message GenerateRecommendedScheduleResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateRecommendedScheduleResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.proposedShifts != null && message.proposedShifts.length)
                            for (var i = 0; i < message.proposedShifts.length; ++i)
                                $root.palexy.insights.v1.ProposedShift.encode(message.proposedShifts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.oldScheduleSummary != null && Object.hasOwnProperty.call(message, "oldScheduleSummary"))
                            $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.encode(message.oldScheduleSummary, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.newScheduleSummary != null && Object.hasOwnProperty.call(message, "newScheduleSummary"))
                            $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.encode(message.newScheduleSummary, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.notableOverloadedHourChanges != null && message.notableOverloadedHourChanges.length)
                            for (var i = 0; i < message.notableOverloadedHourChanges.length; ++i)
                                $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange.encode(message.notableOverloadedHourChanges[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GenerateRecommendedScheduleResponse message, length delimited. Does not implicitly {@link palexy.insights.v1.GenerateRecommendedScheduleResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @static
                     * @param {palexy.insights.v1.IGenerateRecommendedScheduleResponse} message GenerateRecommendedScheduleResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GenerateRecommendedScheduleResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GenerateRecommendedScheduleResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse} GenerateRecommendedScheduleResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateRecommendedScheduleResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.GenerateRecommendedScheduleResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.proposedShifts && message.proposedShifts.length))
                                    message.proposedShifts = [];
                                message.proposedShifts.push($root.palexy.insights.v1.ProposedShift.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.oldScheduleSummary = $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.newScheduleSummary = $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.decode(reader, reader.uint32());
                                break;
                            case 4:
                                if (!(message.notableOverloadedHourChanges && message.notableOverloadedHourChanges.length))
                                    message.notableOverloadedHourChanges = [];
                                message.notableOverloadedHourChanges.push($root.palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GenerateRecommendedScheduleResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse} GenerateRecommendedScheduleResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GenerateRecommendedScheduleResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GenerateRecommendedScheduleResponse message.
                     * @function verify
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GenerateRecommendedScheduleResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.proposedShifts != null && message.hasOwnProperty("proposedShifts")) {
                            if (!Array.isArray(message.proposedShifts))
                                return "proposedShifts: array expected";
                            for (var i = 0; i < message.proposedShifts.length; ++i) {
                                var error = $root.palexy.insights.v1.ProposedShift.verify(message.proposedShifts[i]);
                                if (error)
                                    return "proposedShifts." + error;
                            }
                        }
                        if (message.oldScheduleSummary != null && message.hasOwnProperty("oldScheduleSummary")) {
                            var error = $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.verify(message.oldScheduleSummary);
                            if (error)
                                return "oldScheduleSummary." + error;
                        }
                        if (message.newScheduleSummary != null && message.hasOwnProperty("newScheduleSummary")) {
                            var error = $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.verify(message.newScheduleSummary);
                            if (error)
                                return "newScheduleSummary." + error;
                        }
                        if (message.notableOverloadedHourChanges != null && message.hasOwnProperty("notableOverloadedHourChanges")) {
                            if (!Array.isArray(message.notableOverloadedHourChanges))
                                return "notableOverloadedHourChanges: array expected";
                            for (var i = 0; i < message.notableOverloadedHourChanges.length; ++i) {
                                var error = $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange.verify(message.notableOverloadedHourChanges[i]);
                                if (error)
                                    return "notableOverloadedHourChanges." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GenerateRecommendedScheduleResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse} GenerateRecommendedScheduleResponse
                     */
                    GenerateRecommendedScheduleResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.GenerateRecommendedScheduleResponse)
                            return object;
                        var message = new $root.palexy.insights.v1.GenerateRecommendedScheduleResponse();
                        if (object.proposedShifts) {
                            if (!Array.isArray(object.proposedShifts))
                                throw TypeError(".palexy.insights.v1.GenerateRecommendedScheduleResponse.proposedShifts: array expected");
                            message.proposedShifts = [];
                            for (var i = 0; i < object.proposedShifts.length; ++i) {
                                if (typeof object.proposedShifts[i] !== "object")
                                    throw TypeError(".palexy.insights.v1.GenerateRecommendedScheduleResponse.proposedShifts: object expected");
                                message.proposedShifts[i] = $root.palexy.insights.v1.ProposedShift.fromObject(object.proposedShifts[i]);
                            }
                        }
                        if (object.oldScheduleSummary != null) {
                            if (typeof object.oldScheduleSummary !== "object")
                                throw TypeError(".palexy.insights.v1.GenerateRecommendedScheduleResponse.oldScheduleSummary: object expected");
                            message.oldScheduleSummary = $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.fromObject(object.oldScheduleSummary);
                        }
                        if (object.newScheduleSummary != null) {
                            if (typeof object.newScheduleSummary !== "object")
                                throw TypeError(".palexy.insights.v1.GenerateRecommendedScheduleResponse.newScheduleSummary: object expected");
                            message.newScheduleSummary = $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.fromObject(object.newScheduleSummary);
                        }
                        if (object.notableOverloadedHourChanges) {
                            if (!Array.isArray(object.notableOverloadedHourChanges))
                                throw TypeError(".palexy.insights.v1.GenerateRecommendedScheduleResponse.notableOverloadedHourChanges: array expected");
                            message.notableOverloadedHourChanges = [];
                            for (var i = 0; i < object.notableOverloadedHourChanges.length; ++i) {
                                if (typeof object.notableOverloadedHourChanges[i] !== "object")
                                    throw TypeError(".palexy.insights.v1.GenerateRecommendedScheduleResponse.notableOverloadedHourChanges: object expected");
                                message.notableOverloadedHourChanges[i] = $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange.fromObject(object.notableOverloadedHourChanges[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GenerateRecommendedScheduleResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @static
                     * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse} message GenerateRecommendedScheduleResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GenerateRecommendedScheduleResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.proposedShifts = [];
                            object.notableOverloadedHourChanges = [];
                        }
                        if (options.defaults) {
                            object.oldScheduleSummary = null;
                            object.newScheduleSummary = null;
                        }
                        if (message.proposedShifts && message.proposedShifts.length) {
                            object.proposedShifts = [];
                            for (var j = 0; j < message.proposedShifts.length; ++j)
                                object.proposedShifts[j] = $root.palexy.insights.v1.ProposedShift.toObject(message.proposedShifts[j], options);
                        }
                        if (message.oldScheduleSummary != null && message.hasOwnProperty("oldScheduleSummary"))
                            object.oldScheduleSummary = $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.toObject(message.oldScheduleSummary, options);
                        if (message.newScheduleSummary != null && message.hasOwnProperty("newScheduleSummary"))
                            object.newScheduleSummary = $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.toObject(message.newScheduleSummary, options);
                        if (message.notableOverloadedHourChanges && message.notableOverloadedHourChanges.length) {
                            object.notableOverloadedHourChanges = [];
                            for (var j = 0; j < message.notableOverloadedHourChanges.length; ++j)
                                object.notableOverloadedHourChanges[j] = $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange.toObject(message.notableOverloadedHourChanges[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GenerateRecommendedScheduleResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GenerateRecommendedScheduleResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    GenerateRecommendedScheduleResponse.StatisticSummary = (function() {
    
                        /**
                         * Properties of a StatisticSummary.
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                         * @interface IStatisticSummary
                         * @property {number|null} [totalShifts] StatisticSummary totalShifts
                         * @property {number|null} [totalStaffHours] StatisticSummary totalStaffHours
                         * @property {number|null} [avgVisitorsPerStaff] StatisticSummary avgVisitorsPerStaff
                         * @property {number|null} [maxVisitorsPerStaff] StatisticSummary maxVisitorsPerStaff
                         */
    
                        /**
                         * Constructs a new StatisticSummary.
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                         * @classdesc Represents a StatisticSummary.
                         * @implements IStatisticSummary
                         * @constructor
                         * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse.IStatisticSummary=} [properties] Properties to set
                         */
                        function StatisticSummary(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * StatisticSummary totalShifts.
                         * @member {number} totalShifts
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @instance
                         */
                        StatisticSummary.prototype.totalShifts = 0;
    
                        /**
                         * StatisticSummary totalStaffHours.
                         * @member {number} totalStaffHours
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @instance
                         */
                        StatisticSummary.prototype.totalStaffHours = 0;
    
                        /**
                         * StatisticSummary avgVisitorsPerStaff.
                         * @member {number} avgVisitorsPerStaff
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @instance
                         */
                        StatisticSummary.prototype.avgVisitorsPerStaff = 0;
    
                        /**
                         * StatisticSummary maxVisitorsPerStaff.
                         * @member {number} maxVisitorsPerStaff
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @instance
                         */
                        StatisticSummary.prototype.maxVisitorsPerStaff = 0;
    
                        /**
                         * Creates a new StatisticSummary instance using the specified properties.
                         * @function create
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @static
                         * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse.IStatisticSummary=} [properties] Properties to set
                         * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary} StatisticSummary instance
                         */
                        StatisticSummary.create = function create(properties) {
                            return new StatisticSummary(properties);
                        };
    
                        /**
                         * Encodes the specified StatisticSummary message. Does not implicitly {@link palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @static
                         * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse.IStatisticSummary} message StatisticSummary message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StatisticSummary.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.totalShifts != null && Object.hasOwnProperty.call(message, "totalShifts"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.totalShifts);
                            if (message.totalStaffHours != null && Object.hasOwnProperty.call(message, "totalStaffHours"))
                                writer.uint32(/* id 2, wireType 1 =*/17).double(message.totalStaffHours);
                            if (message.avgVisitorsPerStaff != null && Object.hasOwnProperty.call(message, "avgVisitorsPerStaff"))
                                writer.uint32(/* id 3, wireType 1 =*/25).double(message.avgVisitorsPerStaff);
                            if (message.maxVisitorsPerStaff != null && Object.hasOwnProperty.call(message, "maxVisitorsPerStaff"))
                                writer.uint32(/* id 4, wireType 1 =*/33).double(message.maxVisitorsPerStaff);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified StatisticSummary message, length delimited. Does not implicitly {@link palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @static
                         * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse.IStatisticSummary} message StatisticSummary message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StatisticSummary.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a StatisticSummary message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary} StatisticSummary
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StatisticSummary.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.totalShifts = reader.int32();
                                    break;
                                case 2:
                                    message.totalStaffHours = reader.double();
                                    break;
                                case 3:
                                    message.avgVisitorsPerStaff = reader.double();
                                    break;
                                case 4:
                                    message.maxVisitorsPerStaff = reader.double();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a StatisticSummary message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary} StatisticSummary
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StatisticSummary.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a StatisticSummary message.
                         * @function verify
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StatisticSummary.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.totalShifts != null && message.hasOwnProperty("totalShifts"))
                                if (!$util.isInteger(message.totalShifts))
                                    return "totalShifts: integer expected";
                            if (message.totalStaffHours != null && message.hasOwnProperty("totalStaffHours"))
                                if (typeof message.totalStaffHours !== "number")
                                    return "totalStaffHours: number expected";
                            if (message.avgVisitorsPerStaff != null && message.hasOwnProperty("avgVisitorsPerStaff"))
                                if (typeof message.avgVisitorsPerStaff !== "number")
                                    return "avgVisitorsPerStaff: number expected";
                            if (message.maxVisitorsPerStaff != null && message.hasOwnProperty("maxVisitorsPerStaff"))
                                if (typeof message.maxVisitorsPerStaff !== "number")
                                    return "maxVisitorsPerStaff: number expected";
                            return null;
                        };
    
                        /**
                         * Creates a StatisticSummary message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary} StatisticSummary
                         */
                        StatisticSummary.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary)
                                return object;
                            var message = new $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary();
                            if (object.totalShifts != null)
                                message.totalShifts = object.totalShifts | 0;
                            if (object.totalStaffHours != null)
                                message.totalStaffHours = Number(object.totalStaffHours);
                            if (object.avgVisitorsPerStaff != null)
                                message.avgVisitorsPerStaff = Number(object.avgVisitorsPerStaff);
                            if (object.maxVisitorsPerStaff != null)
                                message.maxVisitorsPerStaff = Number(object.maxVisitorsPerStaff);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a StatisticSummary message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @static
                         * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary} message StatisticSummary
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StatisticSummary.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.totalShifts = 0;
                                object.totalStaffHours = 0;
                                object.avgVisitorsPerStaff = 0;
                                object.maxVisitorsPerStaff = 0;
                            }
                            if (message.totalShifts != null && message.hasOwnProperty("totalShifts"))
                                object.totalShifts = message.totalShifts;
                            if (message.totalStaffHours != null && message.hasOwnProperty("totalStaffHours"))
                                object.totalStaffHours = options.json && !isFinite(message.totalStaffHours) ? String(message.totalStaffHours) : message.totalStaffHours;
                            if (message.avgVisitorsPerStaff != null && message.hasOwnProperty("avgVisitorsPerStaff"))
                                object.avgVisitorsPerStaff = options.json && !isFinite(message.avgVisitorsPerStaff) ? String(message.avgVisitorsPerStaff) : message.avgVisitorsPerStaff;
                            if (message.maxVisitorsPerStaff != null && message.hasOwnProperty("maxVisitorsPerStaff"))
                                object.maxVisitorsPerStaff = options.json && !isFinite(message.maxVisitorsPerStaff) ? String(message.maxVisitorsPerStaff) : message.maxVisitorsPerStaff;
                            return object;
                        };
    
                        /**
                         * Converts this StatisticSummary to JSON.
                         * @function toJSON
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.StatisticSummary
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StatisticSummary.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return StatisticSummary;
                    })();
    
                    GenerateRecommendedScheduleResponse.NotableOverloadedHourChange = (function() {
    
                        /**
                         * Properties of a NotableOverloadedHourChange.
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                         * @interface INotableOverloadedHourChange
                         * @property {number|null} [dateId] NotableOverloadedHourChange dateId
                         * @property {number|null} [hour] NotableOverloadedHourChange hour
                         * @property {number|null} [oldVisitorsPerStaff] NotableOverloadedHourChange oldVisitorsPerStaff
                         * @property {number|null} [newVisitorsPerStaff] NotableOverloadedHourChange newVisitorsPerStaff
                         */
    
                        /**
                         * Constructs a new NotableOverloadedHourChange.
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse
                         * @classdesc Represents a NotableOverloadedHourChange.
                         * @implements INotableOverloadedHourChange
                         * @constructor
                         * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse.INotableOverloadedHourChange=} [properties] Properties to set
                         */
                        function NotableOverloadedHourChange(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * NotableOverloadedHourChange dateId.
                         * @member {number} dateId
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @instance
                         */
                        NotableOverloadedHourChange.prototype.dateId = 0;
    
                        /**
                         * NotableOverloadedHourChange hour.
                         * @member {number} hour
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @instance
                         */
                        NotableOverloadedHourChange.prototype.hour = 0;
    
                        /**
                         * NotableOverloadedHourChange oldVisitorsPerStaff.
                         * @member {number} oldVisitorsPerStaff
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @instance
                         */
                        NotableOverloadedHourChange.prototype.oldVisitorsPerStaff = 0;
    
                        /**
                         * NotableOverloadedHourChange newVisitorsPerStaff.
                         * @member {number} newVisitorsPerStaff
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @instance
                         */
                        NotableOverloadedHourChange.prototype.newVisitorsPerStaff = 0;
    
                        /**
                         * Creates a new NotableOverloadedHourChange instance using the specified properties.
                         * @function create
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @static
                         * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse.INotableOverloadedHourChange=} [properties] Properties to set
                         * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange} NotableOverloadedHourChange instance
                         */
                        NotableOverloadedHourChange.create = function create(properties) {
                            return new NotableOverloadedHourChange(properties);
                        };
    
                        /**
                         * Encodes the specified NotableOverloadedHourChange message. Does not implicitly {@link palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @static
                         * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse.INotableOverloadedHourChange} message NotableOverloadedHourChange message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NotableOverloadedHourChange.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dateId);
                            if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.hour);
                            if (message.oldVisitorsPerStaff != null && Object.hasOwnProperty.call(message, "oldVisitorsPerStaff"))
                                writer.uint32(/* id 3, wireType 1 =*/25).double(message.oldVisitorsPerStaff);
                            if (message.newVisitorsPerStaff != null && Object.hasOwnProperty.call(message, "newVisitorsPerStaff"))
                                writer.uint32(/* id 4, wireType 1 =*/33).double(message.newVisitorsPerStaff);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified NotableOverloadedHourChange message, length delimited. Does not implicitly {@link palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @static
                         * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse.INotableOverloadedHourChange} message NotableOverloadedHourChange message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NotableOverloadedHourChange.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a NotableOverloadedHourChange message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange} NotableOverloadedHourChange
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NotableOverloadedHourChange.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.dateId = reader.int32();
                                    break;
                                case 2:
                                    message.hour = reader.int32();
                                    break;
                                case 3:
                                    message.oldVisitorsPerStaff = reader.double();
                                    break;
                                case 4:
                                    message.newVisitorsPerStaff = reader.double();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a NotableOverloadedHourChange message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange} NotableOverloadedHourChange
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NotableOverloadedHourChange.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a NotableOverloadedHourChange message.
                         * @function verify
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NotableOverloadedHourChange.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                if (!$util.isInteger(message.dateId))
                                    return "dateId: integer expected";
                            if (message.hour != null && message.hasOwnProperty("hour"))
                                if (!$util.isInteger(message.hour))
                                    return "hour: integer expected";
                            if (message.oldVisitorsPerStaff != null && message.hasOwnProperty("oldVisitorsPerStaff"))
                                if (typeof message.oldVisitorsPerStaff !== "number")
                                    return "oldVisitorsPerStaff: number expected";
                            if (message.newVisitorsPerStaff != null && message.hasOwnProperty("newVisitorsPerStaff"))
                                if (typeof message.newVisitorsPerStaff !== "number")
                                    return "newVisitorsPerStaff: number expected";
                            return null;
                        };
    
                        /**
                         * Creates a NotableOverloadedHourChange message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange} NotableOverloadedHourChange
                         */
                        NotableOverloadedHourChange.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange)
                                return object;
                            var message = new $root.palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange();
                            if (object.dateId != null)
                                message.dateId = object.dateId | 0;
                            if (object.hour != null)
                                message.hour = object.hour | 0;
                            if (object.oldVisitorsPerStaff != null)
                                message.oldVisitorsPerStaff = Number(object.oldVisitorsPerStaff);
                            if (object.newVisitorsPerStaff != null)
                                message.newVisitorsPerStaff = Number(object.newVisitorsPerStaff);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a NotableOverloadedHourChange message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @static
                         * @param {palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange} message NotableOverloadedHourChange
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NotableOverloadedHourChange.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.dateId = 0;
                                object.hour = 0;
                                object.oldVisitorsPerStaff = 0;
                                object.newVisitorsPerStaff = 0;
                            }
                            if (message.dateId != null && message.hasOwnProperty("dateId"))
                                object.dateId = message.dateId;
                            if (message.hour != null && message.hasOwnProperty("hour"))
                                object.hour = message.hour;
                            if (message.oldVisitorsPerStaff != null && message.hasOwnProperty("oldVisitorsPerStaff"))
                                object.oldVisitorsPerStaff = options.json && !isFinite(message.oldVisitorsPerStaff) ? String(message.oldVisitorsPerStaff) : message.oldVisitorsPerStaff;
                            if (message.newVisitorsPerStaff != null && message.hasOwnProperty("newVisitorsPerStaff"))
                                object.newVisitorsPerStaff = options.json && !isFinite(message.newVisitorsPerStaff) ? String(message.newVisitorsPerStaff) : message.newVisitorsPerStaff;
                            return object;
                        };
    
                        /**
                         * Converts this NotableOverloadedHourChange to JSON.
                         * @function toJSON
                         * @memberof palexy.insights.v1.GenerateRecommendedScheduleResponse.NotableOverloadedHourChange
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NotableOverloadedHourChange.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return NotableOverloadedHourChange;
                    })();
    
                    return GenerateRecommendedScheduleResponse;
                })();
    
                v1.ProposedShift = (function() {
    
                    /**
                     * Properties of a ProposedShift.
                     * @memberof palexy.insights.v1
                     * @interface IProposedShift
                     * @property {number|null} [shiftTemplateId] ProposedShift shiftTemplateId
                     * @property {number|null} [staffId] ProposedShift staffId
                     * @property {number|null} [dateId] ProposedShift dateId
                     */
    
                    /**
                     * Constructs a new ProposedShift.
                     * @memberof palexy.insights.v1
                     * @classdesc Represents a ProposedShift.
                     * @implements IProposedShift
                     * @constructor
                     * @param {palexy.insights.v1.IProposedShift=} [properties] Properties to set
                     */
                    function ProposedShift(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ProposedShift shiftTemplateId.
                     * @member {number} shiftTemplateId
                     * @memberof palexy.insights.v1.ProposedShift
                     * @instance
                     */
                    ProposedShift.prototype.shiftTemplateId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ProposedShift staffId.
                     * @member {number} staffId
                     * @memberof palexy.insights.v1.ProposedShift
                     * @instance
                     */
                    ProposedShift.prototype.staffId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * ProposedShift dateId.
                     * @member {number} dateId
                     * @memberof palexy.insights.v1.ProposedShift
                     * @instance
                     */
                    ProposedShift.prototype.dateId = 0;
    
                    /**
                     * Creates a new ProposedShift instance using the specified properties.
                     * @function create
                     * @memberof palexy.insights.v1.ProposedShift
                     * @static
                     * @param {palexy.insights.v1.IProposedShift=} [properties] Properties to set
                     * @returns {palexy.insights.v1.ProposedShift} ProposedShift instance
                     */
                    ProposedShift.create = function create(properties) {
                        return new ProposedShift(properties);
                    };
    
                    /**
                     * Encodes the specified ProposedShift message. Does not implicitly {@link palexy.insights.v1.ProposedShift.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.insights.v1.ProposedShift
                     * @static
                     * @param {palexy.insights.v1.IProposedShift} message ProposedShift message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProposedShift.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.shiftTemplateId != null && Object.hasOwnProperty.call(message, "shiftTemplateId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.shiftTemplateId);
                        if (message.staffId != null && Object.hasOwnProperty.call(message, "staffId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.staffId);
                        if (message.dateId != null && Object.hasOwnProperty.call(message, "dateId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.dateId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ProposedShift message, length delimited. Does not implicitly {@link palexy.insights.v1.ProposedShift.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.insights.v1.ProposedShift
                     * @static
                     * @param {palexy.insights.v1.IProposedShift} message ProposedShift message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProposedShift.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ProposedShift message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.insights.v1.ProposedShift
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.insights.v1.ProposedShift} ProposedShift
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProposedShift.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.insights.v1.ProposedShift();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.shiftTemplateId = reader.int64();
                                break;
                            case 2:
                                message.staffId = reader.int64();
                                break;
                            case 3:
                                message.dateId = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ProposedShift message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.insights.v1.ProposedShift
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.insights.v1.ProposedShift} ProposedShift
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProposedShift.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ProposedShift message.
                     * @function verify
                     * @memberof palexy.insights.v1.ProposedShift
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProposedShift.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.shiftTemplateId != null && message.hasOwnProperty("shiftTemplateId"))
                            if (!$util.isInteger(message.shiftTemplateId) && !(message.shiftTemplateId && $util.isInteger(message.shiftTemplateId.low) && $util.isInteger(message.shiftTemplateId.high)))
                                return "shiftTemplateId: integer|Long expected";
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (!$util.isInteger(message.staffId) && !(message.staffId && $util.isInteger(message.staffId.low) && $util.isInteger(message.staffId.high)))
                                return "staffId: integer|Long expected";
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            if (!$util.isInteger(message.dateId))
                                return "dateId: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ProposedShift message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.insights.v1.ProposedShift
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.insights.v1.ProposedShift} ProposedShift
                     */
                    ProposedShift.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.insights.v1.ProposedShift)
                            return object;
                        var message = new $root.palexy.insights.v1.ProposedShift();
                        if (object.shiftTemplateId != null)
                            if ($util.Long)
                                (message.shiftTemplateId = $util.Long.fromValue(object.shiftTemplateId)).unsigned = false;
                            else if (typeof object.shiftTemplateId === "string")
                                message.shiftTemplateId = parseInt(object.shiftTemplateId, 10);
                            else if (typeof object.shiftTemplateId === "number")
                                message.shiftTemplateId = object.shiftTemplateId;
                            else if (typeof object.shiftTemplateId === "object")
                                message.shiftTemplateId = new $util.LongBits(object.shiftTemplateId.low >>> 0, object.shiftTemplateId.high >>> 0).toNumber();
                        if (object.staffId != null)
                            if ($util.Long)
                                (message.staffId = $util.Long.fromValue(object.staffId)).unsigned = false;
                            else if (typeof object.staffId === "string")
                                message.staffId = parseInt(object.staffId, 10);
                            else if (typeof object.staffId === "number")
                                message.staffId = object.staffId;
                            else if (typeof object.staffId === "object")
                                message.staffId = new $util.LongBits(object.staffId.low >>> 0, object.staffId.high >>> 0).toNumber();
                        if (object.dateId != null)
                            message.dateId = object.dateId | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ProposedShift message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.insights.v1.ProposedShift
                     * @static
                     * @param {palexy.insights.v1.ProposedShift} message ProposedShift
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProposedShift.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.shiftTemplateId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.shiftTemplateId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.staffId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.staffId = options.longs === String ? "0" : 0;
                            object.dateId = 0;
                        }
                        if (message.shiftTemplateId != null && message.hasOwnProperty("shiftTemplateId"))
                            if (typeof message.shiftTemplateId === "number")
                                object.shiftTemplateId = options.longs === String ? String(message.shiftTemplateId) : message.shiftTemplateId;
                            else
                                object.shiftTemplateId = options.longs === String ? $util.Long.prototype.toString.call(message.shiftTemplateId) : options.longs === Number ? new $util.LongBits(message.shiftTemplateId.low >>> 0, message.shiftTemplateId.high >>> 0).toNumber() : message.shiftTemplateId;
                        if (message.staffId != null && message.hasOwnProperty("staffId"))
                            if (typeof message.staffId === "number")
                                object.staffId = options.longs === String ? String(message.staffId) : message.staffId;
                            else
                                object.staffId = options.longs === String ? $util.Long.prototype.toString.call(message.staffId) : options.longs === Number ? new $util.LongBits(message.staffId.low >>> 0, message.staffId.high >>> 0).toNumber() : message.staffId;
                        if (message.dateId != null && message.hasOwnProperty("dateId"))
                            object.dateId = message.dateId;
                        return object;
                    };
    
                    /**
                     * Converts this ProposedShift to JSON.
                     * @function toJSON
                     * @memberof palexy.insights.v1.ProposedShift
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProposedShift.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ProposedShift;
                })();
    
                return v1;
            })();
    
            return insights;
        })();
    
        palexy.dvr = (function() {
    
            /**
             * Namespace dvr.
             * @memberof palexy
             * @namespace
             */
            var dvr = {};
    
            dvr.v1 = (function() {
    
                /**
                 * Namespace v1.
                 * @memberof palexy.dvr
                 * @namespace
                 */
                var v1 = {};
    
                v1.DVRService = (function() {
    
                    /**
                     * Constructs a new DVRService service.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a DVRService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function DVRService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (DVRService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = DVRService;
    
                    /**
                     * Creates new DVRService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.dvr.v1.DVRService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {DVRService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    DVRService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.dvr.v1.DVRService#listDVR}.
                     * @memberof palexy.dvr.v1.DVRService
                     * @typedef ListDVRCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.dvr.v1.ListDVRResponse} [response] ListDVRResponse
                     */
    
                    /**
                     * Calls ListDVR.
                     * @function listDVR
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {palexy.dvr.v1.IListDVRRequest} request ListDVRRequest message or plain object
                     * @param {palexy.dvr.v1.DVRService.ListDVRCallback} callback Node-style callback called with the error, if any, and ListDVRResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(DVRService.prototype.listDVR = function listDVR(request, callback) {
                        return this.rpcCall(listDVR, $root.palexy.dvr.v1.ListDVRRequest, $root.palexy.dvr.v1.ListDVRResponse, request, callback);
                    }, "name", { value: "ListDVR" });
    
                    /**
                     * Calls ListDVR.
                     * @function listDVR
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {palexy.dvr.v1.IListDVRRequest} request ListDVRRequest message or plain object
                     * @returns {Promise<palexy.dvr.v1.ListDVRResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.dvr.v1.DVRService#createDVR}.
                     * @memberof palexy.dvr.v1.DVRService
                     * @typedef CreateDVRCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.dvr.v1.DVR} [response] DVR
                     */
    
                    /**
                     * Calls CreateDVR.
                     * @function createDVR
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {palexy.dvr.v1.ICreateDVRRequest} request CreateDVRRequest message or plain object
                     * @param {palexy.dvr.v1.DVRService.CreateDVRCallback} callback Node-style callback called with the error, if any, and DVR
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(DVRService.prototype.createDVR = function createDVR(request, callback) {
                        return this.rpcCall(createDVR, $root.palexy.dvr.v1.CreateDVRRequest, $root.palexy.dvr.v1.DVR, request, callback);
                    }, "name", { value: "CreateDVR" });
    
                    /**
                     * Calls CreateDVR.
                     * @function createDVR
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {palexy.dvr.v1.ICreateDVRRequest} request CreateDVRRequest message or plain object
                     * @returns {Promise<palexy.dvr.v1.DVR>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.dvr.v1.DVRService#getSupportedModels}.
                     * @memberof palexy.dvr.v1.DVRService
                     * @typedef GetSupportedModelsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.dvr.v1.GetSupportedModelsResponse} [response] GetSupportedModelsResponse
                     */
    
                    /**
                     * Calls GetSupportedModels.
                     * @function getSupportedModels
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {google.protobuf.IEmpty} request Empty message or plain object
                     * @param {palexy.dvr.v1.DVRService.GetSupportedModelsCallback} callback Node-style callback called with the error, if any, and GetSupportedModelsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(DVRService.prototype.getSupportedModels = function getSupportedModels(request, callback) {
                        return this.rpcCall(getSupportedModels, $root.google.protobuf.Empty, $root.palexy.dvr.v1.GetSupportedModelsResponse, request, callback);
                    }, "name", { value: "GetSupportedModels" });
    
                    /**
                     * Calls GetSupportedModels.
                     * @function getSupportedModels
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {google.protobuf.IEmpty} request Empty message or plain object
                     * @returns {Promise<palexy.dvr.v1.GetSupportedModelsResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.dvr.v1.DVRService#getDVR}.
                     * @memberof palexy.dvr.v1.DVRService
                     * @typedef GetDVRCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.dvr.v1.DVR} [response] DVR
                     */
    
                    /**
                     * Calls GetDVR.
                     * @function getDVR
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {palexy.dvr.v1.IGetDVRRequest} request GetDVRRequest message or plain object
                     * @param {palexy.dvr.v1.DVRService.GetDVRCallback} callback Node-style callback called with the error, if any, and DVR
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(DVRService.prototype.getDVR = function getDVR(request, callback) {
                        return this.rpcCall(getDVR, $root.palexy.dvr.v1.GetDVRRequest, $root.palexy.dvr.v1.DVR, request, callback);
                    }, "name", { value: "GetDVR" });
    
                    /**
                     * Calls GetDVR.
                     * @function getDVR
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {palexy.dvr.v1.IGetDVRRequest} request GetDVRRequest message or plain object
                     * @returns {Promise<palexy.dvr.v1.DVR>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.dvr.v1.DVRService#updateDVR}.
                     * @memberof palexy.dvr.v1.DVRService
                     * @typedef UpdateDVRCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.dvr.v1.DVR} [response] DVR
                     */
    
                    /**
                     * Calls UpdateDVR.
                     * @function updateDVR
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {palexy.dvr.v1.IUpdateDVRRequest} request UpdateDVRRequest message or plain object
                     * @param {palexy.dvr.v1.DVRService.UpdateDVRCallback} callback Node-style callback called with the error, if any, and DVR
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(DVRService.prototype.updateDVR = function updateDVR(request, callback) {
                        return this.rpcCall(updateDVR, $root.palexy.dvr.v1.UpdateDVRRequest, $root.palexy.dvr.v1.DVR, request, callback);
                    }, "name", { value: "UpdateDVR" });
    
                    /**
                     * Calls UpdateDVR.
                     * @function updateDVR
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {palexy.dvr.v1.IUpdateDVRRequest} request UpdateDVRRequest message or plain object
                     * @returns {Promise<palexy.dvr.v1.DVR>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.dvr.v1.DVRService#deleteDVR}.
                     * @memberof palexy.dvr.v1.DVRService
                     * @typedef DeleteDVRCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteDVR.
                     * @function deleteDVR
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {palexy.dvr.v1.IDeleteDVRRequest} request DeleteDVRRequest message or plain object
                     * @param {palexy.dvr.v1.DVRService.DeleteDVRCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(DVRService.prototype.deleteDVR = function deleteDVR(request, callback) {
                        return this.rpcCall(deleteDVR, $root.palexy.dvr.v1.DeleteDVRRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteDVR" });
    
                    /**
                     * Calls DeleteDVR.
                     * @function deleteDVR
                     * @memberof palexy.dvr.v1.DVRService
                     * @instance
                     * @param {palexy.dvr.v1.IDeleteDVRRequest} request DeleteDVRRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return DVRService;
                })();
    
                v1.DVRCameraService = (function() {
    
                    /**
                     * Constructs a new DVRCameraService service.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a DVRCameraService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function DVRCameraService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (DVRCameraService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = DVRCameraService;
    
                    /**
                     * Creates new DVRCameraService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {DVRCameraService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    DVRCameraService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.dvr.v1.DVRCameraService#listDVRCamera}.
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @typedef ListDVRCameraCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.dvr.v1.ListDVRCameraResponse} [response] ListDVRCameraResponse
                     */
    
                    /**
                     * Calls ListDVRCamera.
                     * @function listDVRCamera
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @instance
                     * @param {palexy.dvr.v1.IListDVRCameraRequest} request ListDVRCameraRequest message or plain object
                     * @param {palexy.dvr.v1.DVRCameraService.ListDVRCameraCallback} callback Node-style callback called with the error, if any, and ListDVRCameraResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(DVRCameraService.prototype.listDVRCamera = function listDVRCamera(request, callback) {
                        return this.rpcCall(listDVRCamera, $root.palexy.dvr.v1.ListDVRCameraRequest, $root.palexy.dvr.v1.ListDVRCameraResponse, request, callback);
                    }, "name", { value: "ListDVRCamera" });
    
                    /**
                     * Calls ListDVRCamera.
                     * @function listDVRCamera
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @instance
                     * @param {palexy.dvr.v1.IListDVRCameraRequest} request ListDVRCameraRequest message or plain object
                     * @returns {Promise<palexy.dvr.v1.ListDVRCameraResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.dvr.v1.DVRCameraService#createDVRCamera}.
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @typedef CreateDVRCameraCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.dvr.v1.DVRCamera} [response] DVRCamera
                     */
    
                    /**
                     * Calls CreateDVRCamera.
                     * @function createDVRCamera
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @instance
                     * @param {palexy.dvr.v1.ICreateDVRCameraRequest} request CreateDVRCameraRequest message or plain object
                     * @param {palexy.dvr.v1.DVRCameraService.CreateDVRCameraCallback} callback Node-style callback called with the error, if any, and DVRCamera
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(DVRCameraService.prototype.createDVRCamera = function createDVRCamera(request, callback) {
                        return this.rpcCall(createDVRCamera, $root.palexy.dvr.v1.CreateDVRCameraRequest, $root.palexy.dvr.v1.DVRCamera, request, callback);
                    }, "name", { value: "CreateDVRCamera" });
    
                    /**
                     * Calls CreateDVRCamera.
                     * @function createDVRCamera
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @instance
                     * @param {palexy.dvr.v1.ICreateDVRCameraRequest} request CreateDVRCameraRequest message or plain object
                     * @returns {Promise<palexy.dvr.v1.DVRCamera>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.dvr.v1.DVRCameraService#getDVRCamera}.
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @typedef GetDVRCameraCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.dvr.v1.DVRCamera} [response] DVRCamera
                     */
    
                    /**
                     * Calls GetDVRCamera.
                     * @function getDVRCamera
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @instance
                     * @param {palexy.dvr.v1.IGetDVRCameraRequest} request GetDVRCameraRequest message or plain object
                     * @param {palexy.dvr.v1.DVRCameraService.GetDVRCameraCallback} callback Node-style callback called with the error, if any, and DVRCamera
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(DVRCameraService.prototype.getDVRCamera = function getDVRCamera(request, callback) {
                        return this.rpcCall(getDVRCamera, $root.palexy.dvr.v1.GetDVRCameraRequest, $root.palexy.dvr.v1.DVRCamera, request, callback);
                    }, "name", { value: "GetDVRCamera" });
    
                    /**
                     * Calls GetDVRCamera.
                     * @function getDVRCamera
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @instance
                     * @param {palexy.dvr.v1.IGetDVRCameraRequest} request GetDVRCameraRequest message or plain object
                     * @returns {Promise<palexy.dvr.v1.DVRCamera>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.dvr.v1.DVRCameraService#updateDVRCamera}.
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @typedef UpdateDVRCameraCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.dvr.v1.DVRCamera} [response] DVRCamera
                     */
    
                    /**
                     * Calls UpdateDVRCamera.
                     * @function updateDVRCamera
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @instance
                     * @param {palexy.dvr.v1.IUpdateDVRCameraRequest} request UpdateDVRCameraRequest message or plain object
                     * @param {palexy.dvr.v1.DVRCameraService.UpdateDVRCameraCallback} callback Node-style callback called with the error, if any, and DVRCamera
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(DVRCameraService.prototype.updateDVRCamera = function updateDVRCamera(request, callback) {
                        return this.rpcCall(updateDVRCamera, $root.palexy.dvr.v1.UpdateDVRCameraRequest, $root.palexy.dvr.v1.DVRCamera, request, callback);
                    }, "name", { value: "UpdateDVRCamera" });
    
                    /**
                     * Calls UpdateDVRCamera.
                     * @function updateDVRCamera
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @instance
                     * @param {palexy.dvr.v1.IUpdateDVRCameraRequest} request UpdateDVRCameraRequest message or plain object
                     * @returns {Promise<palexy.dvr.v1.DVRCamera>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.dvr.v1.DVRCameraService#deleteDVRCamera}.
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @typedef DeleteDVRCameraCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls DeleteDVRCamera.
                     * @function deleteDVRCamera
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @instance
                     * @param {palexy.dvr.v1.IDeleteDVRCameraRequest} request DeleteDVRCameraRequest message or plain object
                     * @param {palexy.dvr.v1.DVRCameraService.DeleteDVRCameraCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(DVRCameraService.prototype.deleteDVRCamera = function deleteDVRCamera(request, callback) {
                        return this.rpcCall(deleteDVRCamera, $root.palexy.dvr.v1.DeleteDVRCameraRequest, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "DeleteDVRCamera" });
    
                    /**
                     * Calls DeleteDVRCamera.
                     * @function deleteDVRCamera
                     * @memberof palexy.dvr.v1.DVRCameraService
                     * @instance
                     * @param {palexy.dvr.v1.IDeleteDVRCameraRequest} request DeleteDVRCameraRequest message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return DVRCameraService;
                })();
    
                v1.DVR = (function() {
    
                    /**
                     * Properties of a DVR.
                     * @memberof palexy.dvr.v1
                     * @interface IDVR
                     * @property {number|null} [id] DVR id
                     * @property {string|null} [name] DVR name
                     * @property {number|null} [storeId] DVR storeId
                     * @property {Array.<palexy.dvr.v1.IDVRCamera>|null} [dvrCameras] DVR dvrCameras
                     * @property {string|null} [model] DVR model
                     * @property {boolean|null} [isTimeSynchronized] DVR isTimeSynchronized
                     * @property {boolean|null} [isMotionStoringDisabled] DVR isMotionStoringDisabled
                     * @property {string|null} [userName] DVR userName
                     * @property {string|null} [password] DVR password
                     * @property {google.protobuf.ITimestamp|null} [authenticationLastUpdated] DVR authenticationLastUpdated
                     * @property {string|null} [authenticationInstructionMarkdown] DVR authenticationInstructionMarkdown
                     * @property {string|null} [expectedFileNamePattern] DVR expectedFileNamePattern
                     * @property {palexy.dvr.v1.IRecorderIntegration|null} [recorderIntegration] DVR recorderIntegration
                     */
    
                    /**
                     * Constructs a new DVR.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a DVR.
                     * @implements IDVR
                     * @constructor
                     * @param {palexy.dvr.v1.IDVR=} [properties] Properties to set
                     */
                    function DVR(properties) {
                        this.dvrCameras = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DVR id.
                     * @member {number} id
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DVR name.
                     * @member {string} name
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.name = "";
    
                    /**
                     * DVR storeId.
                     * @member {number} storeId
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DVR dvrCameras.
                     * @member {Array.<palexy.dvr.v1.IDVRCamera>} dvrCameras
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.dvrCameras = $util.emptyArray;
    
                    /**
                     * DVR model.
                     * @member {string} model
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.model = "";
    
                    /**
                     * DVR isTimeSynchronized.
                     * @member {boolean} isTimeSynchronized
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.isTimeSynchronized = false;
    
                    /**
                     * DVR isMotionStoringDisabled.
                     * @member {boolean} isMotionStoringDisabled
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.isMotionStoringDisabled = false;
    
                    /**
                     * DVR userName.
                     * @member {string} userName
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.userName = "";
    
                    /**
                     * DVR password.
                     * @member {string} password
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.password = "";
    
                    /**
                     * DVR authenticationLastUpdated.
                     * @member {google.protobuf.ITimestamp|null|undefined} authenticationLastUpdated
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.authenticationLastUpdated = null;
    
                    /**
                     * DVR authenticationInstructionMarkdown.
                     * @member {string} authenticationInstructionMarkdown
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.authenticationInstructionMarkdown = "";
    
                    /**
                     * DVR expectedFileNamePattern.
                     * @member {string} expectedFileNamePattern
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.expectedFileNamePattern = "";
    
                    /**
                     * DVR recorderIntegration.
                     * @member {palexy.dvr.v1.IRecorderIntegration|null|undefined} recorderIntegration
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     */
                    DVR.prototype.recorderIntegration = null;
    
                    /**
                     * Creates a new DVR instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.DVR
                     * @static
                     * @param {palexy.dvr.v1.IDVR=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.DVR} DVR instance
                     */
                    DVR.create = function create(properties) {
                        return new DVR(properties);
                    };
    
                    /**
                     * Encodes the specified DVR message. Does not implicitly {@link palexy.dvr.v1.DVR.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.DVR
                     * @static
                     * @param {palexy.dvr.v1.IDVR} message DVR message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DVR.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.storeId);
                        if (message.dvrCameras != null && message.dvrCameras.length)
                            for (var i = 0; i < message.dvrCameras.length; ++i)
                                $root.palexy.dvr.v1.DVRCamera.encode(message.dvrCameras[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.model);
                        if (message.isTimeSynchronized != null && Object.hasOwnProperty.call(message, "isTimeSynchronized"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isTimeSynchronized);
                        if (message.isMotionStoringDisabled != null && Object.hasOwnProperty.call(message, "isMotionStoringDisabled"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isMotionStoringDisabled);
                        if (message.userName != null && Object.hasOwnProperty.call(message, "userName"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.userName);
                        if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.password);
                        if (message.authenticationLastUpdated != null && Object.hasOwnProperty.call(message, "authenticationLastUpdated"))
                            $root.google.protobuf.Timestamp.encode(message.authenticationLastUpdated, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.authenticationInstructionMarkdown != null && Object.hasOwnProperty.call(message, "authenticationInstructionMarkdown"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.authenticationInstructionMarkdown);
                        if (message.expectedFileNamePattern != null && Object.hasOwnProperty.call(message, "expectedFileNamePattern"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.expectedFileNamePattern);
                        if (message.recorderIntegration != null && Object.hasOwnProperty.call(message, "recorderIntegration"))
                            $root.palexy.dvr.v1.RecorderIntegration.encode(message.recorderIntegration, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DVR message, length delimited. Does not implicitly {@link palexy.dvr.v1.DVR.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.DVR
                     * @static
                     * @param {palexy.dvr.v1.IDVR} message DVR message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DVR.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DVR message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.DVR
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.DVR} DVR
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DVR.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.DVR();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.storeId = reader.int64();
                                break;
                            case 4:
                                if (!(message.dvrCameras && message.dvrCameras.length))
                                    message.dvrCameras = [];
                                message.dvrCameras.push($root.palexy.dvr.v1.DVRCamera.decode(reader, reader.uint32()));
                                break;
                            case 5:
                                message.model = reader.string();
                                break;
                            case 6:
                                message.isTimeSynchronized = reader.bool();
                                break;
                            case 7:
                                message.isMotionStoringDisabled = reader.bool();
                                break;
                            case 8:
                                message.userName = reader.string();
                                break;
                            case 9:
                                message.password = reader.string();
                                break;
                            case 10:
                                message.authenticationLastUpdated = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.authenticationInstructionMarkdown = reader.string();
                                break;
                            case 12:
                                message.expectedFileNamePattern = reader.string();
                                break;
                            case 13:
                                message.recorderIntegration = $root.palexy.dvr.v1.RecorderIntegration.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DVR message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.DVR
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.DVR} DVR
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DVR.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DVR message.
                     * @function verify
                     * @memberof palexy.dvr.v1.DVR
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DVR.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                return "storeId: integer|Long expected";
                        if (message.dvrCameras != null && message.hasOwnProperty("dvrCameras")) {
                            if (!Array.isArray(message.dvrCameras))
                                return "dvrCameras: array expected";
                            for (var i = 0; i < message.dvrCameras.length; ++i) {
                                var error = $root.palexy.dvr.v1.DVRCamera.verify(message.dvrCameras[i]);
                                if (error)
                                    return "dvrCameras." + error;
                            }
                        }
                        if (message.model != null && message.hasOwnProperty("model"))
                            if (!$util.isString(message.model))
                                return "model: string expected";
                        if (message.isTimeSynchronized != null && message.hasOwnProperty("isTimeSynchronized"))
                            if (typeof message.isTimeSynchronized !== "boolean")
                                return "isTimeSynchronized: boolean expected";
                        if (message.isMotionStoringDisabled != null && message.hasOwnProperty("isMotionStoringDisabled"))
                            if (typeof message.isMotionStoringDisabled !== "boolean")
                                return "isMotionStoringDisabled: boolean expected";
                        if (message.userName != null && message.hasOwnProperty("userName"))
                            if (!$util.isString(message.userName))
                                return "userName: string expected";
                        if (message.password != null && message.hasOwnProperty("password"))
                            if (!$util.isString(message.password))
                                return "password: string expected";
                        if (message.authenticationLastUpdated != null && message.hasOwnProperty("authenticationLastUpdated")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.authenticationLastUpdated);
                            if (error)
                                return "authenticationLastUpdated." + error;
                        }
                        if (message.authenticationInstructionMarkdown != null && message.hasOwnProperty("authenticationInstructionMarkdown"))
                            if (!$util.isString(message.authenticationInstructionMarkdown))
                                return "authenticationInstructionMarkdown: string expected";
                        if (message.expectedFileNamePattern != null && message.hasOwnProperty("expectedFileNamePattern"))
                            if (!$util.isString(message.expectedFileNamePattern))
                                return "expectedFileNamePattern: string expected";
                        if (message.recorderIntegration != null && message.hasOwnProperty("recorderIntegration")) {
                            var error = $root.palexy.dvr.v1.RecorderIntegration.verify(message.recorderIntegration);
                            if (error)
                                return "recorderIntegration." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DVR message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.DVR
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.DVR} DVR
                     */
                    DVR.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.DVR)
                            return object;
                        var message = new $root.palexy.dvr.v1.DVR();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.storeId != null)
                            if ($util.Long)
                                (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                            else if (typeof object.storeId === "string")
                                message.storeId = parseInt(object.storeId, 10);
                            else if (typeof object.storeId === "number")
                                message.storeId = object.storeId;
                            else if (typeof object.storeId === "object")
                                message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                        if (object.dvrCameras) {
                            if (!Array.isArray(object.dvrCameras))
                                throw TypeError(".palexy.dvr.v1.DVR.dvrCameras: array expected");
                            message.dvrCameras = [];
                            for (var i = 0; i < object.dvrCameras.length; ++i) {
                                if (typeof object.dvrCameras[i] !== "object")
                                    throw TypeError(".palexy.dvr.v1.DVR.dvrCameras: object expected");
                                message.dvrCameras[i] = $root.palexy.dvr.v1.DVRCamera.fromObject(object.dvrCameras[i]);
                            }
                        }
                        if (object.model != null)
                            message.model = String(object.model);
                        if (object.isTimeSynchronized != null)
                            message.isTimeSynchronized = Boolean(object.isTimeSynchronized);
                        if (object.isMotionStoringDisabled != null)
                            message.isMotionStoringDisabled = Boolean(object.isMotionStoringDisabled);
                        if (object.userName != null)
                            message.userName = String(object.userName);
                        if (object.password != null)
                            message.password = String(object.password);
                        if (object.authenticationLastUpdated != null) {
                            if (typeof object.authenticationLastUpdated !== "object")
                                throw TypeError(".palexy.dvr.v1.DVR.authenticationLastUpdated: object expected");
                            message.authenticationLastUpdated = $root.google.protobuf.Timestamp.fromObject(object.authenticationLastUpdated);
                        }
                        if (object.authenticationInstructionMarkdown != null)
                            message.authenticationInstructionMarkdown = String(object.authenticationInstructionMarkdown);
                        if (object.expectedFileNamePattern != null)
                            message.expectedFileNamePattern = String(object.expectedFileNamePattern);
                        if (object.recorderIntegration != null) {
                            if (typeof object.recorderIntegration !== "object")
                                throw TypeError(".palexy.dvr.v1.DVR.recorderIntegration: object expected");
                            message.recorderIntegration = $root.palexy.dvr.v1.RecorderIntegration.fromObject(object.recorderIntegration);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DVR message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.DVR
                     * @static
                     * @param {palexy.dvr.v1.DVR} message DVR
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DVR.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.dvrCameras = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.name = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeId = options.longs === String ? "0" : 0;
                            object.model = "";
                            object.isTimeSynchronized = false;
                            object.isMotionStoringDisabled = false;
                            object.userName = "";
                            object.password = "";
                            object.authenticationLastUpdated = null;
                            object.authenticationInstructionMarkdown = "";
                            object.expectedFileNamePattern = "";
                            object.recorderIntegration = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (typeof message.storeId === "number")
                                object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                            else
                                object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                        if (message.dvrCameras && message.dvrCameras.length) {
                            object.dvrCameras = [];
                            for (var j = 0; j < message.dvrCameras.length; ++j)
                                object.dvrCameras[j] = $root.palexy.dvr.v1.DVRCamera.toObject(message.dvrCameras[j], options);
                        }
                        if (message.model != null && message.hasOwnProperty("model"))
                            object.model = message.model;
                        if (message.isTimeSynchronized != null && message.hasOwnProperty("isTimeSynchronized"))
                            object.isTimeSynchronized = message.isTimeSynchronized;
                        if (message.isMotionStoringDisabled != null && message.hasOwnProperty("isMotionStoringDisabled"))
                            object.isMotionStoringDisabled = message.isMotionStoringDisabled;
                        if (message.userName != null && message.hasOwnProperty("userName"))
                            object.userName = message.userName;
                        if (message.password != null && message.hasOwnProperty("password"))
                            object.password = message.password;
                        if (message.authenticationLastUpdated != null && message.hasOwnProperty("authenticationLastUpdated"))
                            object.authenticationLastUpdated = $root.google.protobuf.Timestamp.toObject(message.authenticationLastUpdated, options);
                        if (message.authenticationInstructionMarkdown != null && message.hasOwnProperty("authenticationInstructionMarkdown"))
                            object.authenticationInstructionMarkdown = message.authenticationInstructionMarkdown;
                        if (message.expectedFileNamePattern != null && message.hasOwnProperty("expectedFileNamePattern"))
                            object.expectedFileNamePattern = message.expectedFileNamePattern;
                        if (message.recorderIntegration != null && message.hasOwnProperty("recorderIntegration"))
                            object.recorderIntegration = $root.palexy.dvr.v1.RecorderIntegration.toObject(message.recorderIntegration, options);
                        return object;
                    };
    
                    /**
                     * Converts this DVR to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.DVR
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DVR.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Manufacturer enum.
                     * @name palexy.dvr.v1.DVR.Manufacturer
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} DAHUA=1 DAHUA value
                     * @property {number} HIKVISION=2 HIKVISION value
                     * @property {number} KBVISION=3 KBVISION value
                     * @property {number} VNG_CLOUD_CAM=4 VNG_CLOUD_CAM value
                     * @property {number} SAFIE=5 SAFIE value
                     */
                    DVR.Manufacturer = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "DAHUA"] = 1;
                        values[valuesById[2] = "HIKVISION"] = 2;
                        values[valuesById[3] = "KBVISION"] = 3;
                        values[valuesById[4] = "VNG_CLOUD_CAM"] = 4;
                        values[valuesById[5] = "SAFIE"] = 5;
                        return values;
                    })();
    
                    return DVR;
                })();
    
                v1.RecorderIntegration = (function() {
    
                    /**
                     * Properties of a RecorderIntegration.
                     * @memberof palexy.dvr.v1
                     * @interface IRecorderIntegration
                     * @property {string|null} [ipAddress] RecorderIntegration ipAddress
                     * @property {number|null} [port] RecorderIntegration port
                     * @property {palexy.dvr.v1.RecorderIntegration.IntegrationType|null} [integrationType] RecorderIntegration integrationType
                     * @property {string|null} [apiKey] RecorderIntegration apiKey
                     */
    
                    /**
                     * Constructs a new RecorderIntegration.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a RecorderIntegration.
                     * @implements IRecorderIntegration
                     * @constructor
                     * @param {palexy.dvr.v1.IRecorderIntegration=} [properties] Properties to set
                     */
                    function RecorderIntegration(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RecorderIntegration ipAddress.
                     * @member {string} ipAddress
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @instance
                     */
                    RecorderIntegration.prototype.ipAddress = "";
    
                    /**
                     * RecorderIntegration port.
                     * @member {number} port
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @instance
                     */
                    RecorderIntegration.prototype.port = 0;
    
                    /**
                     * RecorderIntegration integrationType.
                     * @member {palexy.dvr.v1.RecorderIntegration.IntegrationType} integrationType
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @instance
                     */
                    RecorderIntegration.prototype.integrationType = 0;
    
                    /**
                     * RecorderIntegration apiKey.
                     * @member {string} apiKey
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @instance
                     */
                    RecorderIntegration.prototype.apiKey = "";
    
                    /**
                     * Creates a new RecorderIntegration instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @static
                     * @param {palexy.dvr.v1.IRecorderIntegration=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.RecorderIntegration} RecorderIntegration instance
                     */
                    RecorderIntegration.create = function create(properties) {
                        return new RecorderIntegration(properties);
                    };
    
                    /**
                     * Encodes the specified RecorderIntegration message. Does not implicitly {@link palexy.dvr.v1.RecorderIntegration.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @static
                     * @param {palexy.dvr.v1.IRecorderIntegration} message RecorderIntegration message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RecorderIntegration.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.ipAddress != null && Object.hasOwnProperty.call(message, "ipAddress"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.ipAddress);
                        if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.port);
                        if (message.integrationType != null && Object.hasOwnProperty.call(message, "integrationType"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.integrationType);
                        if (message.apiKey != null && Object.hasOwnProperty.call(message, "apiKey"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.apiKey);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RecorderIntegration message, length delimited. Does not implicitly {@link palexy.dvr.v1.RecorderIntegration.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @static
                     * @param {palexy.dvr.v1.IRecorderIntegration} message RecorderIntegration message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RecorderIntegration.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RecorderIntegration message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.RecorderIntegration} RecorderIntegration
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RecorderIntegration.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.RecorderIntegration();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.ipAddress = reader.string();
                                break;
                            case 2:
                                message.port = reader.int32();
                                break;
                            case 3:
                                message.integrationType = reader.int32();
                                break;
                            case 4:
                                message.apiKey = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RecorderIntegration message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.RecorderIntegration} RecorderIntegration
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RecorderIntegration.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RecorderIntegration message.
                     * @function verify
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RecorderIntegration.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.ipAddress != null && message.hasOwnProperty("ipAddress"))
                            if (!$util.isString(message.ipAddress))
                                return "ipAddress: string expected";
                        if (message.port != null && message.hasOwnProperty("port"))
                            if (!$util.isInteger(message.port))
                                return "port: integer expected";
                        if (message.integrationType != null && message.hasOwnProperty("integrationType"))
                            switch (message.integrationType) {
                            default:
                                return "integrationType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.apiKey != null && message.hasOwnProperty("apiKey"))
                            if (!$util.isString(message.apiKey))
                                return "apiKey: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a RecorderIntegration message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.RecorderIntegration} RecorderIntegration
                     */
                    RecorderIntegration.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.RecorderIntegration)
                            return object;
                        var message = new $root.palexy.dvr.v1.RecorderIntegration();
                        if (object.ipAddress != null)
                            message.ipAddress = String(object.ipAddress);
                        if (object.port != null)
                            message.port = object.port | 0;
                        switch (object.integrationType) {
                        case "UNKNOWN":
                        case 0:
                            message.integrationType = 0;
                            break;
                        case "SDK":
                        case 1:
                            message.integrationType = 1;
                            break;
                        case "API":
                        case 2:
                            message.integrationType = 2;
                            break;
                        }
                        if (object.apiKey != null)
                            message.apiKey = String(object.apiKey);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RecorderIntegration message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @static
                     * @param {palexy.dvr.v1.RecorderIntegration} message RecorderIntegration
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RecorderIntegration.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.ipAddress = "";
                            object.port = 0;
                            object.integrationType = options.enums === String ? "UNKNOWN" : 0;
                            object.apiKey = "";
                        }
                        if (message.ipAddress != null && message.hasOwnProperty("ipAddress"))
                            object.ipAddress = message.ipAddress;
                        if (message.port != null && message.hasOwnProperty("port"))
                            object.port = message.port;
                        if (message.integrationType != null && message.hasOwnProperty("integrationType"))
                            object.integrationType = options.enums === String ? $root.palexy.dvr.v1.RecorderIntegration.IntegrationType[message.integrationType] : message.integrationType;
                        if (message.apiKey != null && message.hasOwnProperty("apiKey"))
                            object.apiKey = message.apiKey;
                        return object;
                    };
    
                    /**
                     * Converts this RecorderIntegration to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.RecorderIntegration
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RecorderIntegration.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * IntegrationType enum.
                     * @name palexy.dvr.v1.RecorderIntegration.IntegrationType
                     * @enum {number}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} SDK=1 SDK value
                     * @property {number} API=2 API value
                     */
                    RecorderIntegration.IntegrationType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "SDK"] = 1;
                        values[valuesById[2] = "API"] = 2;
                        return values;
                    })();
    
                    return RecorderIntegration;
                })();
    
                v1.DVRCamera = (function() {
    
                    /**
                     * Properties of a DVRCamera.
                     * @memberof palexy.dvr.v1
                     * @interface IDVRCamera
                     * @property {number|null} [id] DVRCamera id
                     * @property {string|null} [dvrCameraName] DVRCamera dvrCameraName
                     * @property {number|null} [dvrTimeDeltaSec] DVRCamera dvrTimeDeltaSec
                     * @property {number|null} [dvrId] DVRCamera dvrId
                     * @property {number|null} [channel] DVRCamera channel
                     */
    
                    /**
                     * Constructs a new DVRCamera.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a DVRCamera.
                     * @implements IDVRCamera
                     * @constructor
                     * @param {palexy.dvr.v1.IDVRCamera=} [properties] Properties to set
                     */
                    function DVRCamera(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DVRCamera id.
                     * @member {number} id
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @instance
                     */
                    DVRCamera.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DVRCamera dvrCameraName.
                     * @member {string} dvrCameraName
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @instance
                     */
                    DVRCamera.prototype.dvrCameraName = "";
    
                    /**
                     * DVRCamera dvrTimeDeltaSec.
                     * @member {number} dvrTimeDeltaSec
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @instance
                     */
                    DVRCamera.prototype.dvrTimeDeltaSec = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DVRCamera dvrId.
                     * @member {number} dvrId
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @instance
                     */
                    DVRCamera.prototype.dvrId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * DVRCamera channel.
                     * @member {number} channel
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @instance
                     */
                    DVRCamera.prototype.channel = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DVRCamera instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @static
                     * @param {palexy.dvr.v1.IDVRCamera=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.DVRCamera} DVRCamera instance
                     */
                    DVRCamera.create = function create(properties) {
                        return new DVRCamera(properties);
                    };
    
                    /**
                     * Encodes the specified DVRCamera message. Does not implicitly {@link palexy.dvr.v1.DVRCamera.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @static
                     * @param {palexy.dvr.v1.IDVRCamera} message DVRCamera message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DVRCamera.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.dvrCameraName != null && Object.hasOwnProperty.call(message, "dvrCameraName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dvrCameraName);
                        if (message.dvrTimeDeltaSec != null && Object.hasOwnProperty.call(message, "dvrTimeDeltaSec"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.dvrTimeDeltaSec);
                        if (message.dvrId != null && Object.hasOwnProperty.call(message, "dvrId"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.dvrId);
                        if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.channel);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DVRCamera message, length delimited. Does not implicitly {@link palexy.dvr.v1.DVRCamera.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @static
                     * @param {palexy.dvr.v1.IDVRCamera} message DVRCamera message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DVRCamera.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DVRCamera message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.DVRCamera} DVRCamera
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DVRCamera.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.DVRCamera();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.dvrCameraName = reader.string();
                                break;
                            case 3:
                                message.dvrTimeDeltaSec = reader.int64();
                                break;
                            case 4:
                                message.dvrId = reader.int64();
                                break;
                            case 5:
                                message.channel = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DVRCamera message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.DVRCamera} DVRCamera
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DVRCamera.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DVRCamera message.
                     * @function verify
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DVRCamera.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.dvrCameraName != null && message.hasOwnProperty("dvrCameraName"))
                            if (!$util.isString(message.dvrCameraName))
                                return "dvrCameraName: string expected";
                        if (message.dvrTimeDeltaSec != null && message.hasOwnProperty("dvrTimeDeltaSec"))
                            if (!$util.isInteger(message.dvrTimeDeltaSec) && !(message.dvrTimeDeltaSec && $util.isInteger(message.dvrTimeDeltaSec.low) && $util.isInteger(message.dvrTimeDeltaSec.high)))
                                return "dvrTimeDeltaSec: integer|Long expected";
                        if (message.dvrId != null && message.hasOwnProperty("dvrId"))
                            if (!$util.isInteger(message.dvrId) && !(message.dvrId && $util.isInteger(message.dvrId.low) && $util.isInteger(message.dvrId.high)))
                                return "dvrId: integer|Long expected";
                        if (message.channel != null && message.hasOwnProperty("channel"))
                            if (!$util.isInteger(message.channel) && !(message.channel && $util.isInteger(message.channel.low) && $util.isInteger(message.channel.high)))
                                return "channel: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DVRCamera message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.DVRCamera} DVRCamera
                     */
                    DVRCamera.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.DVRCamera)
                            return object;
                        var message = new $root.palexy.dvr.v1.DVRCamera();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.dvrCameraName != null)
                            message.dvrCameraName = String(object.dvrCameraName);
                        if (object.dvrTimeDeltaSec != null)
                            if ($util.Long)
                                (message.dvrTimeDeltaSec = $util.Long.fromValue(object.dvrTimeDeltaSec)).unsigned = false;
                            else if (typeof object.dvrTimeDeltaSec === "string")
                                message.dvrTimeDeltaSec = parseInt(object.dvrTimeDeltaSec, 10);
                            else if (typeof object.dvrTimeDeltaSec === "number")
                                message.dvrTimeDeltaSec = object.dvrTimeDeltaSec;
                            else if (typeof object.dvrTimeDeltaSec === "object")
                                message.dvrTimeDeltaSec = new $util.LongBits(object.dvrTimeDeltaSec.low >>> 0, object.dvrTimeDeltaSec.high >>> 0).toNumber();
                        if (object.dvrId != null)
                            if ($util.Long)
                                (message.dvrId = $util.Long.fromValue(object.dvrId)).unsigned = false;
                            else if (typeof object.dvrId === "string")
                                message.dvrId = parseInt(object.dvrId, 10);
                            else if (typeof object.dvrId === "number")
                                message.dvrId = object.dvrId;
                            else if (typeof object.dvrId === "object")
                                message.dvrId = new $util.LongBits(object.dvrId.low >>> 0, object.dvrId.high >>> 0).toNumber();
                        if (object.channel != null)
                            if ($util.Long)
                                (message.channel = $util.Long.fromValue(object.channel)).unsigned = false;
                            else if (typeof object.channel === "string")
                                message.channel = parseInt(object.channel, 10);
                            else if (typeof object.channel === "number")
                                message.channel = object.channel;
                            else if (typeof object.channel === "object")
                                message.channel = new $util.LongBits(object.channel.low >>> 0, object.channel.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DVRCamera message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @static
                     * @param {palexy.dvr.v1.DVRCamera} message DVRCamera
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DVRCamera.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.dvrCameraName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.dvrTimeDeltaSec = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.dvrTimeDeltaSec = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.dvrId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.dvrId = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.channel = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.channel = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.dvrCameraName != null && message.hasOwnProperty("dvrCameraName"))
                            object.dvrCameraName = message.dvrCameraName;
                        if (message.dvrTimeDeltaSec != null && message.hasOwnProperty("dvrTimeDeltaSec"))
                            if (typeof message.dvrTimeDeltaSec === "number")
                                object.dvrTimeDeltaSec = options.longs === String ? String(message.dvrTimeDeltaSec) : message.dvrTimeDeltaSec;
                            else
                                object.dvrTimeDeltaSec = options.longs === String ? $util.Long.prototype.toString.call(message.dvrTimeDeltaSec) : options.longs === Number ? new $util.LongBits(message.dvrTimeDeltaSec.low >>> 0, message.dvrTimeDeltaSec.high >>> 0).toNumber() : message.dvrTimeDeltaSec;
                        if (message.dvrId != null && message.hasOwnProperty("dvrId"))
                            if (typeof message.dvrId === "number")
                                object.dvrId = options.longs === String ? String(message.dvrId) : message.dvrId;
                            else
                                object.dvrId = options.longs === String ? $util.Long.prototype.toString.call(message.dvrId) : options.longs === Number ? new $util.LongBits(message.dvrId.low >>> 0, message.dvrId.high >>> 0).toNumber() : message.dvrId;
                        if (message.channel != null && message.hasOwnProperty("channel"))
                            if (typeof message.channel === "number")
                                object.channel = options.longs === String ? String(message.channel) : message.channel;
                            else
                                object.channel = options.longs === String ? $util.Long.prototype.toString.call(message.channel) : options.longs === Number ? new $util.LongBits(message.channel.low >>> 0, message.channel.high >>> 0).toNumber() : message.channel;
                        return object;
                    };
    
                    /**
                     * Converts this DVRCamera to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.DVRCamera
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DVRCamera.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DVRCamera;
                })();
    
                v1.ListDVRRequest = (function() {
    
                    /**
                     * Properties of a ListDVRRequest.
                     * @memberof palexy.dvr.v1
                     * @interface IListDVRRequest
                     * @property {palexy.dvr.v1.ListDVRRequest.IDVRFilter|null} [dvrFilter] ListDVRRequest dvrFilter
                     * @property {boolean|null} [includeDvrCamera] ListDVRRequest includeDvrCamera
                     */
    
                    /**
                     * Constructs a new ListDVRRequest.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a ListDVRRequest.
                     * @implements IListDVRRequest
                     * @constructor
                     * @param {palexy.dvr.v1.IListDVRRequest=} [properties] Properties to set
                     */
                    function ListDVRRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListDVRRequest dvrFilter.
                     * @member {palexy.dvr.v1.ListDVRRequest.IDVRFilter|null|undefined} dvrFilter
                     * @memberof palexy.dvr.v1.ListDVRRequest
                     * @instance
                     */
                    ListDVRRequest.prototype.dvrFilter = null;
    
                    /**
                     * ListDVRRequest includeDvrCamera.
                     * @member {boolean} includeDvrCamera
                     * @memberof palexy.dvr.v1.ListDVRRequest
                     * @instance
                     */
                    ListDVRRequest.prototype.includeDvrCamera = false;
    
                    /**
                     * Creates a new ListDVRRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.ListDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IListDVRRequest=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.ListDVRRequest} ListDVRRequest instance
                     */
                    ListDVRRequest.create = function create(properties) {
                        return new ListDVRRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListDVRRequest message. Does not implicitly {@link palexy.dvr.v1.ListDVRRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.ListDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IListDVRRequest} message ListDVRRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListDVRRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dvrFilter != null && Object.hasOwnProperty.call(message, "dvrFilter"))
                            $root.palexy.dvr.v1.ListDVRRequest.DVRFilter.encode(message.dvrFilter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.includeDvrCamera != null && Object.hasOwnProperty.call(message, "includeDvrCamera"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeDvrCamera);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListDVRRequest message, length delimited. Does not implicitly {@link palexy.dvr.v1.ListDVRRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.ListDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IListDVRRequest} message ListDVRRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListDVRRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListDVRRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.ListDVRRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.ListDVRRequest} ListDVRRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListDVRRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.ListDVRRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dvrFilter = $root.palexy.dvr.v1.ListDVRRequest.DVRFilter.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.includeDvrCamera = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListDVRRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.ListDVRRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.ListDVRRequest} ListDVRRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListDVRRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListDVRRequest message.
                     * @function verify
                     * @memberof palexy.dvr.v1.ListDVRRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListDVRRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dvrFilter != null && message.hasOwnProperty("dvrFilter")) {
                            var error = $root.palexy.dvr.v1.ListDVRRequest.DVRFilter.verify(message.dvrFilter);
                            if (error)
                                return "dvrFilter." + error;
                        }
                        if (message.includeDvrCamera != null && message.hasOwnProperty("includeDvrCamera"))
                            if (typeof message.includeDvrCamera !== "boolean")
                                return "includeDvrCamera: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a ListDVRRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.ListDVRRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.ListDVRRequest} ListDVRRequest
                     */
                    ListDVRRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.ListDVRRequest)
                            return object;
                        var message = new $root.palexy.dvr.v1.ListDVRRequest();
                        if (object.dvrFilter != null) {
                            if (typeof object.dvrFilter !== "object")
                                throw TypeError(".palexy.dvr.v1.ListDVRRequest.dvrFilter: object expected");
                            message.dvrFilter = $root.palexy.dvr.v1.ListDVRRequest.DVRFilter.fromObject(object.dvrFilter);
                        }
                        if (object.includeDvrCamera != null)
                            message.includeDvrCamera = Boolean(object.includeDvrCamera);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListDVRRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.ListDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.ListDVRRequest} message ListDVRRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListDVRRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.dvrFilter = null;
                            object.includeDvrCamera = false;
                        }
                        if (message.dvrFilter != null && message.hasOwnProperty("dvrFilter"))
                            object.dvrFilter = $root.palexy.dvr.v1.ListDVRRequest.DVRFilter.toObject(message.dvrFilter, options);
                        if (message.includeDvrCamera != null && message.hasOwnProperty("includeDvrCamera"))
                            object.includeDvrCamera = message.includeDvrCamera;
                        return object;
                    };
    
                    /**
                     * Converts this ListDVRRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.ListDVRRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListDVRRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    ListDVRRequest.DVRFilter = (function() {
    
                        /**
                         * Properties of a DVRFilter.
                         * @memberof palexy.dvr.v1.ListDVRRequest
                         * @interface IDVRFilter
                         * @property {number|null} [storeId] DVRFilter storeId
                         */
    
                        /**
                         * Constructs a new DVRFilter.
                         * @memberof palexy.dvr.v1.ListDVRRequest
                         * @classdesc Represents a DVRFilter.
                         * @implements IDVRFilter
                         * @constructor
                         * @param {palexy.dvr.v1.ListDVRRequest.IDVRFilter=} [properties] Properties to set
                         */
                        function DVRFilter(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * DVRFilter storeId.
                         * @member {number} storeId
                         * @memberof palexy.dvr.v1.ListDVRRequest.DVRFilter
                         * @instance
                         */
                        DVRFilter.prototype.storeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * Creates a new DVRFilter instance using the specified properties.
                         * @function create
                         * @memberof palexy.dvr.v1.ListDVRRequest.DVRFilter
                         * @static
                         * @param {palexy.dvr.v1.ListDVRRequest.IDVRFilter=} [properties] Properties to set
                         * @returns {palexy.dvr.v1.ListDVRRequest.DVRFilter} DVRFilter instance
                         */
                        DVRFilter.create = function create(properties) {
                            return new DVRFilter(properties);
                        };
    
                        /**
                         * Encodes the specified DVRFilter message. Does not implicitly {@link palexy.dvr.v1.ListDVRRequest.DVRFilter.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.dvr.v1.ListDVRRequest.DVRFilter
                         * @static
                         * @param {palexy.dvr.v1.ListDVRRequest.IDVRFilter} message DVRFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DVRFilter.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.storeId != null && Object.hasOwnProperty.call(message, "storeId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.storeId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified DVRFilter message, length delimited. Does not implicitly {@link palexy.dvr.v1.ListDVRRequest.DVRFilter.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.dvr.v1.ListDVRRequest.DVRFilter
                         * @static
                         * @param {palexy.dvr.v1.ListDVRRequest.IDVRFilter} message DVRFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DVRFilter.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a DVRFilter message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.dvr.v1.ListDVRRequest.DVRFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.dvr.v1.ListDVRRequest.DVRFilter} DVRFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DVRFilter.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.ListDVRRequest.DVRFilter();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.storeId = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a DVRFilter message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.dvr.v1.ListDVRRequest.DVRFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.dvr.v1.ListDVRRequest.DVRFilter} DVRFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DVRFilter.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a DVRFilter message.
                         * @function verify
                         * @memberof palexy.dvr.v1.ListDVRRequest.DVRFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DVRFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (!$util.isInteger(message.storeId) && !(message.storeId && $util.isInteger(message.storeId.low) && $util.isInteger(message.storeId.high)))
                                    return "storeId: integer|Long expected";
                            return null;
                        };
    
                        /**
                         * Creates a DVRFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.dvr.v1.ListDVRRequest.DVRFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.dvr.v1.ListDVRRequest.DVRFilter} DVRFilter
                         */
                        DVRFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.dvr.v1.ListDVRRequest.DVRFilter)
                                return object;
                            var message = new $root.palexy.dvr.v1.ListDVRRequest.DVRFilter();
                            if (object.storeId != null)
                                if ($util.Long)
                                    (message.storeId = $util.Long.fromValue(object.storeId)).unsigned = false;
                                else if (typeof object.storeId === "string")
                                    message.storeId = parseInt(object.storeId, 10);
                                else if (typeof object.storeId === "number")
                                    message.storeId = object.storeId;
                                else if (typeof object.storeId === "object")
                                    message.storeId = new $util.LongBits(object.storeId.low >>> 0, object.storeId.high >>> 0).toNumber();
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a DVRFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.dvr.v1.ListDVRRequest.DVRFilter
                         * @static
                         * @param {palexy.dvr.v1.ListDVRRequest.DVRFilter} message DVRFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DVRFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.storeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.storeId = options.longs === String ? "0" : 0;
                            if (message.storeId != null && message.hasOwnProperty("storeId"))
                                if (typeof message.storeId === "number")
                                    object.storeId = options.longs === String ? String(message.storeId) : message.storeId;
                                else
                                    object.storeId = options.longs === String ? $util.Long.prototype.toString.call(message.storeId) : options.longs === Number ? new $util.LongBits(message.storeId.low >>> 0, message.storeId.high >>> 0).toNumber() : message.storeId;
                            return object;
                        };
    
                        /**
                         * Converts this DVRFilter to JSON.
                         * @function toJSON
                         * @memberof palexy.dvr.v1.ListDVRRequest.DVRFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DVRFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return DVRFilter;
                    })();
    
                    return ListDVRRequest;
                })();
    
                v1.ListDVRResponse = (function() {
    
                    /**
                     * Properties of a ListDVRResponse.
                     * @memberof palexy.dvr.v1
                     * @interface IListDVRResponse
                     * @property {Array.<palexy.dvr.v1.IDVR>|null} [dvrs] ListDVRResponse dvrs
                     */
    
                    /**
                     * Constructs a new ListDVRResponse.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a ListDVRResponse.
                     * @implements IListDVRResponse
                     * @constructor
                     * @param {palexy.dvr.v1.IListDVRResponse=} [properties] Properties to set
                     */
                    function ListDVRResponse(properties) {
                        this.dvrs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListDVRResponse dvrs.
                     * @member {Array.<palexy.dvr.v1.IDVR>} dvrs
                     * @memberof palexy.dvr.v1.ListDVRResponse
                     * @instance
                     */
                    ListDVRResponse.prototype.dvrs = $util.emptyArray;
    
                    /**
                     * Creates a new ListDVRResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.ListDVRResponse
                     * @static
                     * @param {palexy.dvr.v1.IListDVRResponse=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.ListDVRResponse} ListDVRResponse instance
                     */
                    ListDVRResponse.create = function create(properties) {
                        return new ListDVRResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListDVRResponse message. Does not implicitly {@link palexy.dvr.v1.ListDVRResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.ListDVRResponse
                     * @static
                     * @param {palexy.dvr.v1.IListDVRResponse} message ListDVRResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListDVRResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dvrs != null && message.dvrs.length)
                            for (var i = 0; i < message.dvrs.length; ++i)
                                $root.palexy.dvr.v1.DVR.encode(message.dvrs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListDVRResponse message, length delimited. Does not implicitly {@link palexy.dvr.v1.ListDVRResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.ListDVRResponse
                     * @static
                     * @param {palexy.dvr.v1.IListDVRResponse} message ListDVRResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListDVRResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListDVRResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.ListDVRResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.ListDVRResponse} ListDVRResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListDVRResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.ListDVRResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.dvrs && message.dvrs.length))
                                    message.dvrs = [];
                                message.dvrs.push($root.palexy.dvr.v1.DVR.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListDVRResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.ListDVRResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.ListDVRResponse} ListDVRResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListDVRResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListDVRResponse message.
                     * @function verify
                     * @memberof palexy.dvr.v1.ListDVRResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListDVRResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dvrs != null && message.hasOwnProperty("dvrs")) {
                            if (!Array.isArray(message.dvrs))
                                return "dvrs: array expected";
                            for (var i = 0; i < message.dvrs.length; ++i) {
                                var error = $root.palexy.dvr.v1.DVR.verify(message.dvrs[i]);
                                if (error)
                                    return "dvrs." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListDVRResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.ListDVRResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.ListDVRResponse} ListDVRResponse
                     */
                    ListDVRResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.ListDVRResponse)
                            return object;
                        var message = new $root.palexy.dvr.v1.ListDVRResponse();
                        if (object.dvrs) {
                            if (!Array.isArray(object.dvrs))
                                throw TypeError(".palexy.dvr.v1.ListDVRResponse.dvrs: array expected");
                            message.dvrs = [];
                            for (var i = 0; i < object.dvrs.length; ++i) {
                                if (typeof object.dvrs[i] !== "object")
                                    throw TypeError(".palexy.dvr.v1.ListDVRResponse.dvrs: object expected");
                                message.dvrs[i] = $root.palexy.dvr.v1.DVR.fromObject(object.dvrs[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListDVRResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.ListDVRResponse
                     * @static
                     * @param {palexy.dvr.v1.ListDVRResponse} message ListDVRResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListDVRResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.dvrs = [];
                        if (message.dvrs && message.dvrs.length) {
                            object.dvrs = [];
                            for (var j = 0; j < message.dvrs.length; ++j)
                                object.dvrs[j] = $root.palexy.dvr.v1.DVR.toObject(message.dvrs[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListDVRResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.ListDVRResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListDVRResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListDVRResponse;
                })();
    
                v1.CreateDVRRequest = (function() {
    
                    /**
                     * Properties of a CreateDVRRequest.
                     * @memberof palexy.dvr.v1
                     * @interface ICreateDVRRequest
                     * @property {palexy.dvr.v1.IDVR|null} [dvr] CreateDVRRequest dvr
                     */
    
                    /**
                     * Constructs a new CreateDVRRequest.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a CreateDVRRequest.
                     * @implements ICreateDVRRequest
                     * @constructor
                     * @param {palexy.dvr.v1.ICreateDVRRequest=} [properties] Properties to set
                     */
                    function CreateDVRRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateDVRRequest dvr.
                     * @member {palexy.dvr.v1.IDVR|null|undefined} dvr
                     * @memberof palexy.dvr.v1.CreateDVRRequest
                     * @instance
                     */
                    CreateDVRRequest.prototype.dvr = null;
    
                    /**
                     * Creates a new CreateDVRRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.CreateDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.ICreateDVRRequest=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.CreateDVRRequest} CreateDVRRequest instance
                     */
                    CreateDVRRequest.create = function create(properties) {
                        return new CreateDVRRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateDVRRequest message. Does not implicitly {@link palexy.dvr.v1.CreateDVRRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.CreateDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.ICreateDVRRequest} message CreateDVRRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDVRRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dvr != null && Object.hasOwnProperty.call(message, "dvr"))
                            $root.palexy.dvr.v1.DVR.encode(message.dvr, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateDVRRequest message, length delimited. Does not implicitly {@link palexy.dvr.v1.CreateDVRRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.CreateDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.ICreateDVRRequest} message CreateDVRRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDVRRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateDVRRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.CreateDVRRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.CreateDVRRequest} CreateDVRRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDVRRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.CreateDVRRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dvr = $root.palexy.dvr.v1.DVR.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateDVRRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.CreateDVRRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.CreateDVRRequest} CreateDVRRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDVRRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateDVRRequest message.
                     * @function verify
                     * @memberof palexy.dvr.v1.CreateDVRRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateDVRRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dvr != null && message.hasOwnProperty("dvr")) {
                            var error = $root.palexy.dvr.v1.DVR.verify(message.dvr);
                            if (error)
                                return "dvr." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateDVRRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.CreateDVRRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.CreateDVRRequest} CreateDVRRequest
                     */
                    CreateDVRRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.CreateDVRRequest)
                            return object;
                        var message = new $root.palexy.dvr.v1.CreateDVRRequest();
                        if (object.dvr != null) {
                            if (typeof object.dvr !== "object")
                                throw TypeError(".palexy.dvr.v1.CreateDVRRequest.dvr: object expected");
                            message.dvr = $root.palexy.dvr.v1.DVR.fromObject(object.dvr);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateDVRRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.CreateDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.CreateDVRRequest} message CreateDVRRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateDVRRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.dvr = null;
                        if (message.dvr != null && message.hasOwnProperty("dvr"))
                            object.dvr = $root.palexy.dvr.v1.DVR.toObject(message.dvr, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateDVRRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.CreateDVRRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateDVRRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateDVRRequest;
                })();
    
                v1.GetSupportedModelsResponse = (function() {
    
                    /**
                     * Properties of a GetSupportedModelsResponse.
                     * @memberof palexy.dvr.v1
                     * @interface IGetSupportedModelsResponse
                     * @property {Array.<string>|null} [models] GetSupportedModelsResponse models
                     */
    
                    /**
                     * Constructs a new GetSupportedModelsResponse.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a GetSupportedModelsResponse.
                     * @implements IGetSupportedModelsResponse
                     * @constructor
                     * @param {palexy.dvr.v1.IGetSupportedModelsResponse=} [properties] Properties to set
                     */
                    function GetSupportedModelsResponse(properties) {
                        this.models = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetSupportedModelsResponse models.
                     * @member {Array.<string>} models
                     * @memberof palexy.dvr.v1.GetSupportedModelsResponse
                     * @instance
                     */
                    GetSupportedModelsResponse.prototype.models = $util.emptyArray;
    
                    /**
                     * Creates a new GetSupportedModelsResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.GetSupportedModelsResponse
                     * @static
                     * @param {palexy.dvr.v1.IGetSupportedModelsResponse=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.GetSupportedModelsResponse} GetSupportedModelsResponse instance
                     */
                    GetSupportedModelsResponse.create = function create(properties) {
                        return new GetSupportedModelsResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetSupportedModelsResponse message. Does not implicitly {@link palexy.dvr.v1.GetSupportedModelsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.GetSupportedModelsResponse
                     * @static
                     * @param {palexy.dvr.v1.IGetSupportedModelsResponse} message GetSupportedModelsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetSupportedModelsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.models != null && message.models.length)
                            for (var i = 0; i < message.models.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.models[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetSupportedModelsResponse message, length delimited. Does not implicitly {@link palexy.dvr.v1.GetSupportedModelsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.GetSupportedModelsResponse
                     * @static
                     * @param {palexy.dvr.v1.IGetSupportedModelsResponse} message GetSupportedModelsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetSupportedModelsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetSupportedModelsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.GetSupportedModelsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.GetSupportedModelsResponse} GetSupportedModelsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetSupportedModelsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.GetSupportedModelsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.models && message.models.length))
                                    message.models = [];
                                message.models.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetSupportedModelsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.GetSupportedModelsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.GetSupportedModelsResponse} GetSupportedModelsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetSupportedModelsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetSupportedModelsResponse message.
                     * @function verify
                     * @memberof palexy.dvr.v1.GetSupportedModelsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetSupportedModelsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.models != null && message.hasOwnProperty("models")) {
                            if (!Array.isArray(message.models))
                                return "models: array expected";
                            for (var i = 0; i < message.models.length; ++i)
                                if (!$util.isString(message.models[i]))
                                    return "models: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetSupportedModelsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.GetSupportedModelsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.GetSupportedModelsResponse} GetSupportedModelsResponse
                     */
                    GetSupportedModelsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.GetSupportedModelsResponse)
                            return object;
                        var message = new $root.palexy.dvr.v1.GetSupportedModelsResponse();
                        if (object.models) {
                            if (!Array.isArray(object.models))
                                throw TypeError(".palexy.dvr.v1.GetSupportedModelsResponse.models: array expected");
                            message.models = [];
                            for (var i = 0; i < object.models.length; ++i)
                                message.models[i] = String(object.models[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetSupportedModelsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.GetSupportedModelsResponse
                     * @static
                     * @param {palexy.dvr.v1.GetSupportedModelsResponse} message GetSupportedModelsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetSupportedModelsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.models = [];
                        if (message.models && message.models.length) {
                            object.models = [];
                            for (var j = 0; j < message.models.length; ++j)
                                object.models[j] = message.models[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this GetSupportedModelsResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.GetSupportedModelsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetSupportedModelsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetSupportedModelsResponse;
                })();
    
                v1.GetDVRRequest = (function() {
    
                    /**
                     * Properties of a GetDVRRequest.
                     * @memberof palexy.dvr.v1
                     * @interface IGetDVRRequest
                     * @property {number|null} [id] GetDVRRequest id
                     * @property {boolean|null} [includeDvrCamera] GetDVRRequest includeDvrCamera
                     */
    
                    /**
                     * Constructs a new GetDVRRequest.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a GetDVRRequest.
                     * @implements IGetDVRRequest
                     * @constructor
                     * @param {palexy.dvr.v1.IGetDVRRequest=} [properties] Properties to set
                     */
                    function GetDVRRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetDVRRequest id.
                     * @member {number} id
                     * @memberof palexy.dvr.v1.GetDVRRequest
                     * @instance
                     */
                    GetDVRRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * GetDVRRequest includeDvrCamera.
                     * @member {boolean} includeDvrCamera
                     * @memberof palexy.dvr.v1.GetDVRRequest
                     * @instance
                     */
                    GetDVRRequest.prototype.includeDvrCamera = false;
    
                    /**
                     * Creates a new GetDVRRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.GetDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IGetDVRRequest=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.GetDVRRequest} GetDVRRequest instance
                     */
                    GetDVRRequest.create = function create(properties) {
                        return new GetDVRRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetDVRRequest message. Does not implicitly {@link palexy.dvr.v1.GetDVRRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.GetDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IGetDVRRequest} message GetDVRRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetDVRRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.includeDvrCamera != null && Object.hasOwnProperty.call(message, "includeDvrCamera"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeDvrCamera);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetDVRRequest message, length delimited. Does not implicitly {@link palexy.dvr.v1.GetDVRRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.GetDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IGetDVRRequest} message GetDVRRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetDVRRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetDVRRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.GetDVRRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.GetDVRRequest} GetDVRRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetDVRRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.GetDVRRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.includeDvrCamera = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetDVRRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.GetDVRRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.GetDVRRequest} GetDVRRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetDVRRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetDVRRequest message.
                     * @function verify
                     * @memberof palexy.dvr.v1.GetDVRRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetDVRRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.includeDvrCamera != null && message.hasOwnProperty("includeDvrCamera"))
                            if (typeof message.includeDvrCamera !== "boolean")
                                return "includeDvrCamera: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetDVRRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.GetDVRRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.GetDVRRequest} GetDVRRequest
                     */
                    GetDVRRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.GetDVRRequest)
                            return object;
                        var message = new $root.palexy.dvr.v1.GetDVRRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.includeDvrCamera != null)
                            message.includeDvrCamera = Boolean(object.includeDvrCamera);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetDVRRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.GetDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.GetDVRRequest} message GetDVRRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetDVRRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.includeDvrCamera = false;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.includeDvrCamera != null && message.hasOwnProperty("includeDvrCamera"))
                            object.includeDvrCamera = message.includeDvrCamera;
                        return object;
                    };
    
                    /**
                     * Converts this GetDVRRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.GetDVRRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetDVRRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetDVRRequest;
                })();
    
                v1.UpdateDVRRequest = (function() {
    
                    /**
                     * Properties of an UpdateDVRRequest.
                     * @memberof palexy.dvr.v1
                     * @interface IUpdateDVRRequest
                     * @property {palexy.dvr.v1.IDVR|null} [dvr] UpdateDVRRequest dvr
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateDVRRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateDVRRequest.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents an UpdateDVRRequest.
                     * @implements IUpdateDVRRequest
                     * @constructor
                     * @param {palexy.dvr.v1.IUpdateDVRRequest=} [properties] Properties to set
                     */
                    function UpdateDVRRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateDVRRequest dvr.
                     * @member {palexy.dvr.v1.IDVR|null|undefined} dvr
                     * @memberof palexy.dvr.v1.UpdateDVRRequest
                     * @instance
                     */
                    UpdateDVRRequest.prototype.dvr = null;
    
                    /**
                     * UpdateDVRRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.dvr.v1.UpdateDVRRequest
                     * @instance
                     */
                    UpdateDVRRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateDVRRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.UpdateDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IUpdateDVRRequest=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.UpdateDVRRequest} UpdateDVRRequest instance
                     */
                    UpdateDVRRequest.create = function create(properties) {
                        return new UpdateDVRRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateDVRRequest message. Does not implicitly {@link palexy.dvr.v1.UpdateDVRRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.UpdateDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IUpdateDVRRequest} message UpdateDVRRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateDVRRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dvr != null && Object.hasOwnProperty.call(message, "dvr"))
                            $root.palexy.dvr.v1.DVR.encode(message.dvr, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateDVRRequest message, length delimited. Does not implicitly {@link palexy.dvr.v1.UpdateDVRRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.UpdateDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IUpdateDVRRequest} message UpdateDVRRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateDVRRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateDVRRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.UpdateDVRRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.UpdateDVRRequest} UpdateDVRRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateDVRRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.UpdateDVRRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dvr = $root.palexy.dvr.v1.DVR.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateDVRRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.UpdateDVRRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.UpdateDVRRequest} UpdateDVRRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateDVRRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateDVRRequest message.
                     * @function verify
                     * @memberof palexy.dvr.v1.UpdateDVRRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateDVRRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dvr != null && message.hasOwnProperty("dvr")) {
                            var error = $root.palexy.dvr.v1.DVR.verify(message.dvr);
                            if (error)
                                return "dvr." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateDVRRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.UpdateDVRRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.UpdateDVRRequest} UpdateDVRRequest
                     */
                    UpdateDVRRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.UpdateDVRRequest)
                            return object;
                        var message = new $root.palexy.dvr.v1.UpdateDVRRequest();
                        if (object.dvr != null) {
                            if (typeof object.dvr !== "object")
                                throw TypeError(".palexy.dvr.v1.UpdateDVRRequest.dvr: object expected");
                            message.dvr = $root.palexy.dvr.v1.DVR.fromObject(object.dvr);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.dvr.v1.UpdateDVRRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateDVRRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.UpdateDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.UpdateDVRRequest} message UpdateDVRRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateDVRRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.dvr = null;
                            object.updateMask = null;
                        }
                        if (message.dvr != null && message.hasOwnProperty("dvr"))
                            object.dvr = $root.palexy.dvr.v1.DVR.toObject(message.dvr, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateDVRRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.UpdateDVRRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateDVRRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateDVRRequest;
                })();
    
                v1.DeleteDVRRequest = (function() {
    
                    /**
                     * Properties of a DeleteDVRRequest.
                     * @memberof palexy.dvr.v1
                     * @interface IDeleteDVRRequest
                     * @property {number|null} [id] DeleteDVRRequest id
                     */
    
                    /**
                     * Constructs a new DeleteDVRRequest.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a DeleteDVRRequest.
                     * @implements IDeleteDVRRequest
                     * @constructor
                     * @param {palexy.dvr.v1.IDeleteDVRRequest=} [properties] Properties to set
                     */
                    function DeleteDVRRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteDVRRequest id.
                     * @member {number} id
                     * @memberof palexy.dvr.v1.DeleteDVRRequest
                     * @instance
                     */
                    DeleteDVRRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteDVRRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.DeleteDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IDeleteDVRRequest=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.DeleteDVRRequest} DeleteDVRRequest instance
                     */
                    DeleteDVRRequest.create = function create(properties) {
                        return new DeleteDVRRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteDVRRequest message. Does not implicitly {@link palexy.dvr.v1.DeleteDVRRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.DeleteDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IDeleteDVRRequest} message DeleteDVRRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteDVRRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteDVRRequest message, length delimited. Does not implicitly {@link palexy.dvr.v1.DeleteDVRRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.DeleteDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.IDeleteDVRRequest} message DeleteDVRRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteDVRRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteDVRRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.DeleteDVRRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.DeleteDVRRequest} DeleteDVRRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteDVRRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.DeleteDVRRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteDVRRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.DeleteDVRRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.DeleteDVRRequest} DeleteDVRRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteDVRRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteDVRRequest message.
                     * @function verify
                     * @memberof palexy.dvr.v1.DeleteDVRRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteDVRRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteDVRRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.DeleteDVRRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.DeleteDVRRequest} DeleteDVRRequest
                     */
                    DeleteDVRRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.DeleteDVRRequest)
                            return object;
                        var message = new $root.palexy.dvr.v1.DeleteDVRRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteDVRRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.DeleteDVRRequest
                     * @static
                     * @param {palexy.dvr.v1.DeleteDVRRequest} message DeleteDVRRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteDVRRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteDVRRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.DeleteDVRRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteDVRRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteDVRRequest;
                })();
    
                v1.ListDVRCameraRequest = (function() {
    
                    /**
                     * Properties of a ListDVRCameraRequest.
                     * @memberof palexy.dvr.v1
                     * @interface IListDVRCameraRequest
                     * @property {palexy.dvr.v1.ListDVRCameraRequest.IDVRCameraFilter|null} [dvrCameraFilter] ListDVRCameraRequest dvrCameraFilter
                     */
    
                    /**
                     * Constructs a new ListDVRCameraRequest.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a ListDVRCameraRequest.
                     * @implements IListDVRCameraRequest
                     * @constructor
                     * @param {palexy.dvr.v1.IListDVRCameraRequest=} [properties] Properties to set
                     */
                    function ListDVRCameraRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListDVRCameraRequest dvrCameraFilter.
                     * @member {palexy.dvr.v1.ListDVRCameraRequest.IDVRCameraFilter|null|undefined} dvrCameraFilter
                     * @memberof palexy.dvr.v1.ListDVRCameraRequest
                     * @instance
                     */
                    ListDVRCameraRequest.prototype.dvrCameraFilter = null;
    
                    /**
                     * Creates a new ListDVRCameraRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.ListDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IListDVRCameraRequest=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.ListDVRCameraRequest} ListDVRCameraRequest instance
                     */
                    ListDVRCameraRequest.create = function create(properties) {
                        return new ListDVRCameraRequest(properties);
                    };
    
                    /**
                     * Encodes the specified ListDVRCameraRequest message. Does not implicitly {@link palexy.dvr.v1.ListDVRCameraRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.ListDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IListDVRCameraRequest} message ListDVRCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListDVRCameraRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dvrCameraFilter != null && Object.hasOwnProperty.call(message, "dvrCameraFilter"))
                            $root.palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter.encode(message.dvrCameraFilter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListDVRCameraRequest message, length delimited. Does not implicitly {@link palexy.dvr.v1.ListDVRCameraRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.ListDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IListDVRCameraRequest} message ListDVRCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListDVRCameraRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListDVRCameraRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.ListDVRCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.ListDVRCameraRequest} ListDVRCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListDVRCameraRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.ListDVRCameraRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dvrCameraFilter = $root.palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListDVRCameraRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.ListDVRCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.ListDVRCameraRequest} ListDVRCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListDVRCameraRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListDVRCameraRequest message.
                     * @function verify
                     * @memberof palexy.dvr.v1.ListDVRCameraRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListDVRCameraRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dvrCameraFilter != null && message.hasOwnProperty("dvrCameraFilter")) {
                            var error = $root.palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter.verify(message.dvrCameraFilter);
                            if (error)
                                return "dvrCameraFilter." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListDVRCameraRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.ListDVRCameraRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.ListDVRCameraRequest} ListDVRCameraRequest
                     */
                    ListDVRCameraRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.ListDVRCameraRequest)
                            return object;
                        var message = new $root.palexy.dvr.v1.ListDVRCameraRequest();
                        if (object.dvrCameraFilter != null) {
                            if (typeof object.dvrCameraFilter !== "object")
                                throw TypeError(".palexy.dvr.v1.ListDVRCameraRequest.dvrCameraFilter: object expected");
                            message.dvrCameraFilter = $root.palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter.fromObject(object.dvrCameraFilter);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListDVRCameraRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.ListDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.ListDVRCameraRequest} message ListDVRCameraRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListDVRCameraRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.dvrCameraFilter = null;
                        if (message.dvrCameraFilter != null && message.hasOwnProperty("dvrCameraFilter"))
                            object.dvrCameraFilter = $root.palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter.toObject(message.dvrCameraFilter, options);
                        return object;
                    };
    
                    /**
                     * Converts this ListDVRCameraRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.ListDVRCameraRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListDVRCameraRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    ListDVRCameraRequest.DVRCameraFilter = (function() {
    
                        /**
                         * Properties of a DVRCameraFilter.
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest
                         * @interface IDVRCameraFilter
                         * @property {number|null} [dvrId] DVRCameraFilter dvrId
                         */
    
                        /**
                         * Constructs a new DVRCameraFilter.
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest
                         * @classdesc Represents a DVRCameraFilter.
                         * @implements IDVRCameraFilter
                         * @constructor
                         * @param {palexy.dvr.v1.ListDVRCameraRequest.IDVRCameraFilter=} [properties] Properties to set
                         */
                        function DVRCameraFilter(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * DVRCameraFilter dvrId.
                         * @member {number} dvrId
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter
                         * @instance
                         */
                        DVRCameraFilter.prototype.dvrId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                        /**
                         * Creates a new DVRCameraFilter instance using the specified properties.
                         * @function create
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter
                         * @static
                         * @param {palexy.dvr.v1.ListDVRCameraRequest.IDVRCameraFilter=} [properties] Properties to set
                         * @returns {palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter} DVRCameraFilter instance
                         */
                        DVRCameraFilter.create = function create(properties) {
                            return new DVRCameraFilter(properties);
                        };
    
                        /**
                         * Encodes the specified DVRCameraFilter message. Does not implicitly {@link palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter.verify|verify} messages.
                         * @function encode
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter
                         * @static
                         * @param {palexy.dvr.v1.ListDVRCameraRequest.IDVRCameraFilter} message DVRCameraFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DVRCameraFilter.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.dvrId != null && Object.hasOwnProperty.call(message, "dvrId"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.dvrId);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified DVRCameraFilter message, length delimited. Does not implicitly {@link palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter
                         * @static
                         * @param {palexy.dvr.v1.ListDVRCameraRequest.IDVRCameraFilter} message DVRCameraFilter message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DVRCameraFilter.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes a DVRCameraFilter message from the specified reader or buffer.
                         * @function decode
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter} DVRCameraFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DVRCameraFilter.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.dvrId = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes a DVRCameraFilter message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter} DVRCameraFilter
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DVRCameraFilter.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies a DVRCameraFilter message.
                         * @function verify
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DVRCameraFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.dvrId != null && message.hasOwnProperty("dvrId"))
                                if (!$util.isInteger(message.dvrId) && !(message.dvrId && $util.isInteger(message.dvrId.low) && $util.isInteger(message.dvrId.high)))
                                    return "dvrId: integer|Long expected";
                            return null;
                        };
    
                        /**
                         * Creates a DVRCameraFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter} DVRCameraFilter
                         */
                        DVRCameraFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter)
                                return object;
                            var message = new $root.palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter();
                            if (object.dvrId != null)
                                if ($util.Long)
                                    (message.dvrId = $util.Long.fromValue(object.dvrId)).unsigned = false;
                                else if (typeof object.dvrId === "string")
                                    message.dvrId = parseInt(object.dvrId, 10);
                                else if (typeof object.dvrId === "number")
                                    message.dvrId = object.dvrId;
                                else if (typeof object.dvrId === "object")
                                    message.dvrId = new $util.LongBits(object.dvrId.low >>> 0, object.dvrId.high >>> 0).toNumber();
                            return message;
                        };
    
                        /**
                         * Creates a plain object from a DVRCameraFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter
                         * @static
                         * @param {palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter} message DVRCameraFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DVRCameraFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.dvrId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.dvrId = options.longs === String ? "0" : 0;
                            if (message.dvrId != null && message.hasOwnProperty("dvrId"))
                                if (typeof message.dvrId === "number")
                                    object.dvrId = options.longs === String ? String(message.dvrId) : message.dvrId;
                                else
                                    object.dvrId = options.longs === String ? $util.Long.prototype.toString.call(message.dvrId) : options.longs === Number ? new $util.LongBits(message.dvrId.low >>> 0, message.dvrId.high >>> 0).toNumber() : message.dvrId;
                            return object;
                        };
    
                        /**
                         * Converts this DVRCameraFilter to JSON.
                         * @function toJSON
                         * @memberof palexy.dvr.v1.ListDVRCameraRequest.DVRCameraFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DVRCameraFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        return DVRCameraFilter;
                    })();
    
                    return ListDVRCameraRequest;
                })();
    
                v1.ListDVRCameraResponse = (function() {
    
                    /**
                     * Properties of a ListDVRCameraResponse.
                     * @memberof palexy.dvr.v1
                     * @interface IListDVRCameraResponse
                     * @property {Array.<palexy.dvr.v1.IDVRCamera>|null} [dvrCameras] ListDVRCameraResponse dvrCameras
                     */
    
                    /**
                     * Constructs a new ListDVRCameraResponse.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a ListDVRCameraResponse.
                     * @implements IListDVRCameraResponse
                     * @constructor
                     * @param {palexy.dvr.v1.IListDVRCameraResponse=} [properties] Properties to set
                     */
                    function ListDVRCameraResponse(properties) {
                        this.dvrCameras = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ListDVRCameraResponse dvrCameras.
                     * @member {Array.<palexy.dvr.v1.IDVRCamera>} dvrCameras
                     * @memberof palexy.dvr.v1.ListDVRCameraResponse
                     * @instance
                     */
                    ListDVRCameraResponse.prototype.dvrCameras = $util.emptyArray;
    
                    /**
                     * Creates a new ListDVRCameraResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.ListDVRCameraResponse
                     * @static
                     * @param {palexy.dvr.v1.IListDVRCameraResponse=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.ListDVRCameraResponse} ListDVRCameraResponse instance
                     */
                    ListDVRCameraResponse.create = function create(properties) {
                        return new ListDVRCameraResponse(properties);
                    };
    
                    /**
                     * Encodes the specified ListDVRCameraResponse message. Does not implicitly {@link palexy.dvr.v1.ListDVRCameraResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.ListDVRCameraResponse
                     * @static
                     * @param {palexy.dvr.v1.IListDVRCameraResponse} message ListDVRCameraResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListDVRCameraResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dvrCameras != null && message.dvrCameras.length)
                            for (var i = 0; i < message.dvrCameras.length; ++i)
                                $root.palexy.dvr.v1.DVRCamera.encode(message.dvrCameras[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ListDVRCameraResponse message, length delimited. Does not implicitly {@link palexy.dvr.v1.ListDVRCameraResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.ListDVRCameraResponse
                     * @static
                     * @param {palexy.dvr.v1.IListDVRCameraResponse} message ListDVRCameraResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ListDVRCameraResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ListDVRCameraResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.ListDVRCameraResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.ListDVRCameraResponse} ListDVRCameraResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListDVRCameraResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.ListDVRCameraResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.dvrCameras && message.dvrCameras.length))
                                    message.dvrCameras = [];
                                message.dvrCameras.push($root.palexy.dvr.v1.DVRCamera.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ListDVRCameraResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.ListDVRCameraResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.ListDVRCameraResponse} ListDVRCameraResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ListDVRCameraResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ListDVRCameraResponse message.
                     * @function verify
                     * @memberof palexy.dvr.v1.ListDVRCameraResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ListDVRCameraResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dvrCameras != null && message.hasOwnProperty("dvrCameras")) {
                            if (!Array.isArray(message.dvrCameras))
                                return "dvrCameras: array expected";
                            for (var i = 0; i < message.dvrCameras.length; ++i) {
                                var error = $root.palexy.dvr.v1.DVRCamera.verify(message.dvrCameras[i]);
                                if (error)
                                    return "dvrCameras." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ListDVRCameraResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.ListDVRCameraResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.ListDVRCameraResponse} ListDVRCameraResponse
                     */
                    ListDVRCameraResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.ListDVRCameraResponse)
                            return object;
                        var message = new $root.palexy.dvr.v1.ListDVRCameraResponse();
                        if (object.dvrCameras) {
                            if (!Array.isArray(object.dvrCameras))
                                throw TypeError(".palexy.dvr.v1.ListDVRCameraResponse.dvrCameras: array expected");
                            message.dvrCameras = [];
                            for (var i = 0; i < object.dvrCameras.length; ++i) {
                                if (typeof object.dvrCameras[i] !== "object")
                                    throw TypeError(".palexy.dvr.v1.ListDVRCameraResponse.dvrCameras: object expected");
                                message.dvrCameras[i] = $root.palexy.dvr.v1.DVRCamera.fromObject(object.dvrCameras[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ListDVRCameraResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.ListDVRCameraResponse
                     * @static
                     * @param {palexy.dvr.v1.ListDVRCameraResponse} message ListDVRCameraResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ListDVRCameraResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.dvrCameras = [];
                        if (message.dvrCameras && message.dvrCameras.length) {
                            object.dvrCameras = [];
                            for (var j = 0; j < message.dvrCameras.length; ++j)
                                object.dvrCameras[j] = $root.palexy.dvr.v1.DVRCamera.toObject(message.dvrCameras[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ListDVRCameraResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.ListDVRCameraResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ListDVRCameraResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ListDVRCameraResponse;
                })();
    
                v1.CreateDVRCameraRequest = (function() {
    
                    /**
                     * Properties of a CreateDVRCameraRequest.
                     * @memberof palexy.dvr.v1
                     * @interface ICreateDVRCameraRequest
                     * @property {palexy.dvr.v1.IDVRCamera|null} [dvrCamera] CreateDVRCameraRequest dvrCamera
                     */
    
                    /**
                     * Constructs a new CreateDVRCameraRequest.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a CreateDVRCameraRequest.
                     * @implements ICreateDVRCameraRequest
                     * @constructor
                     * @param {palexy.dvr.v1.ICreateDVRCameraRequest=} [properties] Properties to set
                     */
                    function CreateDVRCameraRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CreateDVRCameraRequest dvrCamera.
                     * @member {palexy.dvr.v1.IDVRCamera|null|undefined} dvrCamera
                     * @memberof palexy.dvr.v1.CreateDVRCameraRequest
                     * @instance
                     */
                    CreateDVRCameraRequest.prototype.dvrCamera = null;
    
                    /**
                     * Creates a new CreateDVRCameraRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.CreateDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.ICreateDVRCameraRequest=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.CreateDVRCameraRequest} CreateDVRCameraRequest instance
                     */
                    CreateDVRCameraRequest.create = function create(properties) {
                        return new CreateDVRCameraRequest(properties);
                    };
    
                    /**
                     * Encodes the specified CreateDVRCameraRequest message. Does not implicitly {@link palexy.dvr.v1.CreateDVRCameraRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.CreateDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.ICreateDVRCameraRequest} message CreateDVRCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDVRCameraRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dvrCamera != null && Object.hasOwnProperty.call(message, "dvrCamera"))
                            $root.palexy.dvr.v1.DVRCamera.encode(message.dvrCamera, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CreateDVRCameraRequest message, length delimited. Does not implicitly {@link palexy.dvr.v1.CreateDVRCameraRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.CreateDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.ICreateDVRCameraRequest} message CreateDVRCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDVRCameraRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CreateDVRCameraRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.CreateDVRCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.CreateDVRCameraRequest} CreateDVRCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDVRCameraRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.CreateDVRCameraRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dvrCamera = $root.palexy.dvr.v1.DVRCamera.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CreateDVRCameraRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.CreateDVRCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.CreateDVRCameraRequest} CreateDVRCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDVRCameraRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CreateDVRCameraRequest message.
                     * @function verify
                     * @memberof palexy.dvr.v1.CreateDVRCameraRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateDVRCameraRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dvrCamera != null && message.hasOwnProperty("dvrCamera")) {
                            var error = $root.palexy.dvr.v1.DVRCamera.verify(message.dvrCamera);
                            if (error)
                                return "dvrCamera." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CreateDVRCameraRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.CreateDVRCameraRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.CreateDVRCameraRequest} CreateDVRCameraRequest
                     */
                    CreateDVRCameraRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.CreateDVRCameraRequest)
                            return object;
                        var message = new $root.palexy.dvr.v1.CreateDVRCameraRequest();
                        if (object.dvrCamera != null) {
                            if (typeof object.dvrCamera !== "object")
                                throw TypeError(".palexy.dvr.v1.CreateDVRCameraRequest.dvrCamera: object expected");
                            message.dvrCamera = $root.palexy.dvr.v1.DVRCamera.fromObject(object.dvrCamera);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CreateDVRCameraRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.CreateDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.CreateDVRCameraRequest} message CreateDVRCameraRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateDVRCameraRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.dvrCamera = null;
                        if (message.dvrCamera != null && message.hasOwnProperty("dvrCamera"))
                            object.dvrCamera = $root.palexy.dvr.v1.DVRCamera.toObject(message.dvrCamera, options);
                        return object;
                    };
    
                    /**
                     * Converts this CreateDVRCameraRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.CreateDVRCameraRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateDVRCameraRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return CreateDVRCameraRequest;
                })();
    
                v1.GetDVRCameraRequest = (function() {
    
                    /**
                     * Properties of a GetDVRCameraRequest.
                     * @memberof palexy.dvr.v1
                     * @interface IGetDVRCameraRequest
                     * @property {number|null} [id] GetDVRCameraRequest id
                     */
    
                    /**
                     * Constructs a new GetDVRCameraRequest.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a GetDVRCameraRequest.
                     * @implements IGetDVRCameraRequest
                     * @constructor
                     * @param {palexy.dvr.v1.IGetDVRCameraRequest=} [properties] Properties to set
                     */
                    function GetDVRCameraRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetDVRCameraRequest id.
                     * @member {number} id
                     * @memberof palexy.dvr.v1.GetDVRCameraRequest
                     * @instance
                     */
                    GetDVRCameraRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new GetDVRCameraRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.GetDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IGetDVRCameraRequest=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.GetDVRCameraRequest} GetDVRCameraRequest instance
                     */
                    GetDVRCameraRequest.create = function create(properties) {
                        return new GetDVRCameraRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetDVRCameraRequest message. Does not implicitly {@link palexy.dvr.v1.GetDVRCameraRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.GetDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IGetDVRCameraRequest} message GetDVRCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetDVRCameraRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetDVRCameraRequest message, length delimited. Does not implicitly {@link palexy.dvr.v1.GetDVRCameraRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.GetDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IGetDVRCameraRequest} message GetDVRCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetDVRCameraRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetDVRCameraRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.GetDVRCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.GetDVRCameraRequest} GetDVRCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetDVRCameraRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.GetDVRCameraRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetDVRCameraRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.GetDVRCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.GetDVRCameraRequest} GetDVRCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetDVRCameraRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetDVRCameraRequest message.
                     * @function verify
                     * @memberof palexy.dvr.v1.GetDVRCameraRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetDVRCameraRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetDVRCameraRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.GetDVRCameraRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.GetDVRCameraRequest} GetDVRCameraRequest
                     */
                    GetDVRCameraRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.GetDVRCameraRequest)
                            return object;
                        var message = new $root.palexy.dvr.v1.GetDVRCameraRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetDVRCameraRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.GetDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.GetDVRCameraRequest} message GetDVRCameraRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetDVRCameraRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this GetDVRCameraRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.GetDVRCameraRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetDVRCameraRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetDVRCameraRequest;
                })();
    
                v1.UpdateDVRCameraRequest = (function() {
    
                    /**
                     * Properties of an UpdateDVRCameraRequest.
                     * @memberof palexy.dvr.v1
                     * @interface IUpdateDVRCameraRequest
                     * @property {palexy.dvr.v1.IDVRCamera|null} [dvrCamera] UpdateDVRCameraRequest dvrCamera
                     * @property {google.protobuf.IFieldMask|null} [updateMask] UpdateDVRCameraRequest updateMask
                     */
    
                    /**
                     * Constructs a new UpdateDVRCameraRequest.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents an UpdateDVRCameraRequest.
                     * @implements IUpdateDVRCameraRequest
                     * @constructor
                     * @param {palexy.dvr.v1.IUpdateDVRCameraRequest=} [properties] Properties to set
                     */
                    function UpdateDVRCameraRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * UpdateDVRCameraRequest dvrCamera.
                     * @member {palexy.dvr.v1.IDVRCamera|null|undefined} dvrCamera
                     * @memberof palexy.dvr.v1.UpdateDVRCameraRequest
                     * @instance
                     */
                    UpdateDVRCameraRequest.prototype.dvrCamera = null;
    
                    /**
                     * UpdateDVRCameraRequest updateMask.
                     * @member {google.protobuf.IFieldMask|null|undefined} updateMask
                     * @memberof palexy.dvr.v1.UpdateDVRCameraRequest
                     * @instance
                     */
                    UpdateDVRCameraRequest.prototype.updateMask = null;
    
                    /**
                     * Creates a new UpdateDVRCameraRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.UpdateDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IUpdateDVRCameraRequest=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.UpdateDVRCameraRequest} UpdateDVRCameraRequest instance
                     */
                    UpdateDVRCameraRequest.create = function create(properties) {
                        return new UpdateDVRCameraRequest(properties);
                    };
    
                    /**
                     * Encodes the specified UpdateDVRCameraRequest message. Does not implicitly {@link palexy.dvr.v1.UpdateDVRCameraRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.UpdateDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IUpdateDVRCameraRequest} message UpdateDVRCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateDVRCameraRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dvrCamera != null && Object.hasOwnProperty.call(message, "dvrCamera"))
                            $root.palexy.dvr.v1.DVRCamera.encode(message.dvrCamera, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                            $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified UpdateDVRCameraRequest message, length delimited. Does not implicitly {@link palexy.dvr.v1.UpdateDVRCameraRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.UpdateDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IUpdateDVRCameraRequest} message UpdateDVRCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateDVRCameraRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an UpdateDVRCameraRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.UpdateDVRCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.UpdateDVRCameraRequest} UpdateDVRCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateDVRCameraRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.UpdateDVRCameraRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dvrCamera = $root.palexy.dvr.v1.DVRCamera.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an UpdateDVRCameraRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.UpdateDVRCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.UpdateDVRCameraRequest} UpdateDVRCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateDVRCameraRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an UpdateDVRCameraRequest message.
                     * @function verify
                     * @memberof palexy.dvr.v1.UpdateDVRCameraRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateDVRCameraRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dvrCamera != null && message.hasOwnProperty("dvrCamera")) {
                            var error = $root.palexy.dvr.v1.DVRCamera.verify(message.dvrCamera);
                            if (error)
                                return "dvrCamera." + error;
                        }
                        if (message.updateMask != null && message.hasOwnProperty("updateMask")) {
                            var error = $root.google.protobuf.FieldMask.verify(message.updateMask);
                            if (error)
                                return "updateMask." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an UpdateDVRCameraRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.UpdateDVRCameraRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.UpdateDVRCameraRequest} UpdateDVRCameraRequest
                     */
                    UpdateDVRCameraRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.UpdateDVRCameraRequest)
                            return object;
                        var message = new $root.palexy.dvr.v1.UpdateDVRCameraRequest();
                        if (object.dvrCamera != null) {
                            if (typeof object.dvrCamera !== "object")
                                throw TypeError(".palexy.dvr.v1.UpdateDVRCameraRequest.dvrCamera: object expected");
                            message.dvrCamera = $root.palexy.dvr.v1.DVRCamera.fromObject(object.dvrCamera);
                        }
                        if (object.updateMask != null) {
                            if (typeof object.updateMask !== "object")
                                throw TypeError(".palexy.dvr.v1.UpdateDVRCameraRequest.updateMask: object expected");
                            message.updateMask = $root.google.protobuf.FieldMask.fromObject(object.updateMask);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an UpdateDVRCameraRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.UpdateDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.UpdateDVRCameraRequest} message UpdateDVRCameraRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateDVRCameraRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.dvrCamera = null;
                            object.updateMask = null;
                        }
                        if (message.dvrCamera != null && message.hasOwnProperty("dvrCamera"))
                            object.dvrCamera = $root.palexy.dvr.v1.DVRCamera.toObject(message.dvrCamera, options);
                        if (message.updateMask != null && message.hasOwnProperty("updateMask"))
                            object.updateMask = $root.google.protobuf.FieldMask.toObject(message.updateMask, options);
                        return object;
                    };
    
                    /**
                     * Converts this UpdateDVRCameraRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.UpdateDVRCameraRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateDVRCameraRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return UpdateDVRCameraRequest;
                })();
    
                v1.DeleteDVRCameraRequest = (function() {
    
                    /**
                     * Properties of a DeleteDVRCameraRequest.
                     * @memberof palexy.dvr.v1
                     * @interface IDeleteDVRCameraRequest
                     * @property {number|null} [id] DeleteDVRCameraRequest id
                     */
    
                    /**
                     * Constructs a new DeleteDVRCameraRequest.
                     * @memberof palexy.dvr.v1
                     * @classdesc Represents a DeleteDVRCameraRequest.
                     * @implements IDeleteDVRCameraRequest
                     * @constructor
                     * @param {palexy.dvr.v1.IDeleteDVRCameraRequest=} [properties] Properties to set
                     */
                    function DeleteDVRCameraRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DeleteDVRCameraRequest id.
                     * @member {number} id
                     * @memberof palexy.dvr.v1.DeleteDVRCameraRequest
                     * @instance
                     */
                    DeleteDVRCameraRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Creates a new DeleteDVRCameraRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.dvr.v1.DeleteDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IDeleteDVRCameraRequest=} [properties] Properties to set
                     * @returns {palexy.dvr.v1.DeleteDVRCameraRequest} DeleteDVRCameraRequest instance
                     */
                    DeleteDVRCameraRequest.create = function create(properties) {
                        return new DeleteDVRCameraRequest(properties);
                    };
    
                    /**
                     * Encodes the specified DeleteDVRCameraRequest message. Does not implicitly {@link palexy.dvr.v1.DeleteDVRCameraRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.dvr.v1.DeleteDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IDeleteDVRCameraRequest} message DeleteDVRCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteDVRCameraRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DeleteDVRCameraRequest message, length delimited. Does not implicitly {@link palexy.dvr.v1.DeleteDVRCameraRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.dvr.v1.DeleteDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.IDeleteDVRCameraRequest} message DeleteDVRCameraRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteDVRCameraRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DeleteDVRCameraRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.dvr.v1.DeleteDVRCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.dvr.v1.DeleteDVRCameraRequest} DeleteDVRCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteDVRCameraRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.dvr.v1.DeleteDVRCameraRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DeleteDVRCameraRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.dvr.v1.DeleteDVRCameraRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.dvr.v1.DeleteDVRCameraRequest} DeleteDVRCameraRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteDVRCameraRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DeleteDVRCameraRequest message.
                     * @function verify
                     * @memberof palexy.dvr.v1.DeleteDVRCameraRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteDVRCameraRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };
    
                    /**
                     * Creates a DeleteDVRCameraRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.dvr.v1.DeleteDVRCameraRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.dvr.v1.DeleteDVRCameraRequest} DeleteDVRCameraRequest
                     */
                    DeleteDVRCameraRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.dvr.v1.DeleteDVRCameraRequest)
                            return object;
                        var message = new $root.palexy.dvr.v1.DeleteDVRCameraRequest();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DeleteDVRCameraRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.dvr.v1.DeleteDVRCameraRequest
                     * @static
                     * @param {palexy.dvr.v1.DeleteDVRCameraRequest} message DeleteDVRCameraRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteDVRCameraRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };
    
                    /**
                     * Converts this DeleteDVRCameraRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.dvr.v1.DeleteDVRCameraRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteDVRCameraRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return DeleteDVRCameraRequest;
                })();
    
                return v1;
            })();
    
            return dvr;
        })();
    
        palexy.janus = (function() {
    
            /**
             * Namespace janus.
             * @memberof palexy
             * @namespace
             */
            var janus = {};
    
            janus.v1 = (function() {
    
                /**
                 * Namespace v1.
                 * @memberof palexy.janus
                 * @namespace
                 */
                var v1 = {};
    
                v1.ApiKeyService = (function() {
    
                    /**
                     * Constructs a new ApiKeyService service.
                     * @memberof palexy.janus.v1
                     * @classdesc Represents an ApiKeyService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ApiKeyService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }
    
                    (ApiKeyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ApiKeyService;
    
                    /**
                     * Creates new ApiKeyService service using the specified rpc implementation.
                     * @function create
                     * @memberof palexy.janus.v1.ApiKeyService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ApiKeyService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ApiKeyService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };
    
                    /**
                     * Callback as used by {@link palexy.janus.v1.ApiKeyService#getAccountInfoByToken}.
                     * @memberof palexy.janus.v1.ApiKeyService
                     * @typedef GetAccountInfoByTokenCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {palexy.janus.v1.GetAccountInfoByTokenResponse} [response] GetAccountInfoByTokenResponse
                     */
    
                    /**
                     * Calls GetAccountInfoByToken.
                     * @function getAccountInfoByToken
                     * @memberof palexy.janus.v1.ApiKeyService
                     * @instance
                     * @param {palexy.janus.v1.IGetAccountInfoByTokenRequest} request GetAccountInfoByTokenRequest message or plain object
                     * @param {palexy.janus.v1.ApiKeyService.GetAccountInfoByTokenCallback} callback Node-style callback called with the error, if any, and GetAccountInfoByTokenResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ApiKeyService.prototype.getAccountInfoByToken = function getAccountInfoByToken(request, callback) {
                        return this.rpcCall(getAccountInfoByToken, $root.palexy.janus.v1.GetAccountInfoByTokenRequest, $root.palexy.janus.v1.GetAccountInfoByTokenResponse, request, callback);
                    }, "name", { value: "GetAccountInfoByToken" });
    
                    /**
                     * Calls GetAccountInfoByToken.
                     * @function getAccountInfoByToken
                     * @memberof palexy.janus.v1.ApiKeyService
                     * @instance
                     * @param {palexy.janus.v1.IGetAccountInfoByTokenRequest} request GetAccountInfoByTokenRequest message or plain object
                     * @returns {Promise<palexy.janus.v1.GetAccountInfoByTokenResponse>} Promise
                     * @variation 2
                     */
    
                    /**
                     * Callback as used by {@link palexy.janus.v1.ApiKeyService#check}.
                     * @memberof palexy.janus.v1.ApiKeyService
                     * @typedef CheckCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {google.protobuf.Empty} [response] Empty
                     */
    
                    /**
                     * Calls Check.
                     * @function check
                     * @memberof palexy.janus.v1.ApiKeyService
                     * @instance
                     * @param {google.protobuf.IEmpty} request Empty message or plain object
                     * @param {palexy.janus.v1.ApiKeyService.CheckCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ApiKeyService.prototype.check = function check(request, callback) {
                        return this.rpcCall(check, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
                    }, "name", { value: "Check" });
    
                    /**
                     * Calls Check.
                     * @function check
                     * @memberof palexy.janus.v1.ApiKeyService
                     * @instance
                     * @param {google.protobuf.IEmpty} request Empty message or plain object
                     * @returns {Promise<google.protobuf.Empty>} Promise
                     * @variation 2
                     */
    
                    return ApiKeyService;
                })();
    
                v1.GetAccountInfoByTokenRequest = (function() {
    
                    /**
                     * Properties of a GetAccountInfoByTokenRequest.
                     * @memberof palexy.janus.v1
                     * @interface IGetAccountInfoByTokenRequest
                     * @property {string|null} [token] GetAccountInfoByTokenRequest token
                     */
    
                    /**
                     * Constructs a new GetAccountInfoByTokenRequest.
                     * @memberof palexy.janus.v1
                     * @classdesc Represents a GetAccountInfoByTokenRequest.
                     * @implements IGetAccountInfoByTokenRequest
                     * @constructor
                     * @param {palexy.janus.v1.IGetAccountInfoByTokenRequest=} [properties] Properties to set
                     */
                    function GetAccountInfoByTokenRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetAccountInfoByTokenRequest token.
                     * @member {string} token
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenRequest
                     * @instance
                     */
                    GetAccountInfoByTokenRequest.prototype.token = "";
    
                    /**
                     * Creates a new GetAccountInfoByTokenRequest instance using the specified properties.
                     * @function create
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenRequest
                     * @static
                     * @param {palexy.janus.v1.IGetAccountInfoByTokenRequest=} [properties] Properties to set
                     * @returns {palexy.janus.v1.GetAccountInfoByTokenRequest} GetAccountInfoByTokenRequest instance
                     */
                    GetAccountInfoByTokenRequest.create = function create(properties) {
                        return new GetAccountInfoByTokenRequest(properties);
                    };
    
                    /**
                     * Encodes the specified GetAccountInfoByTokenRequest message. Does not implicitly {@link palexy.janus.v1.GetAccountInfoByTokenRequest.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenRequest
                     * @static
                     * @param {palexy.janus.v1.IGetAccountInfoByTokenRequest} message GetAccountInfoByTokenRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetAccountInfoByTokenRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetAccountInfoByTokenRequest message, length delimited. Does not implicitly {@link palexy.janus.v1.GetAccountInfoByTokenRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenRequest
                     * @static
                     * @param {palexy.janus.v1.IGetAccountInfoByTokenRequest} message GetAccountInfoByTokenRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetAccountInfoByTokenRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetAccountInfoByTokenRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.janus.v1.GetAccountInfoByTokenRequest} GetAccountInfoByTokenRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetAccountInfoByTokenRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.janus.v1.GetAccountInfoByTokenRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.token = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetAccountInfoByTokenRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.janus.v1.GetAccountInfoByTokenRequest} GetAccountInfoByTokenRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetAccountInfoByTokenRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetAccountInfoByTokenRequest message.
                     * @function verify
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetAccountInfoByTokenRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.token != null && message.hasOwnProperty("token"))
                            if (!$util.isString(message.token))
                                return "token: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a GetAccountInfoByTokenRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.janus.v1.GetAccountInfoByTokenRequest} GetAccountInfoByTokenRequest
                     */
                    GetAccountInfoByTokenRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.janus.v1.GetAccountInfoByTokenRequest)
                            return object;
                        var message = new $root.palexy.janus.v1.GetAccountInfoByTokenRequest();
                        if (object.token != null)
                            message.token = String(object.token);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetAccountInfoByTokenRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenRequest
                     * @static
                     * @param {palexy.janus.v1.GetAccountInfoByTokenRequest} message GetAccountInfoByTokenRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetAccountInfoByTokenRequest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.token = "";
                        if (message.token != null && message.hasOwnProperty("token"))
                            object.token = message.token;
                        return object;
                    };
    
                    /**
                     * Converts this GetAccountInfoByTokenRequest to JSON.
                     * @function toJSON
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetAccountInfoByTokenRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetAccountInfoByTokenRequest;
                })();
    
                v1.GetAccountInfoByTokenResponse = (function() {
    
                    /**
                     * Properties of a GetAccountInfoByTokenResponse.
                     * @memberof palexy.janus.v1
                     * @interface IGetAccountInfoByTokenResponse
                     * @property {palexy.janus.v1.IAccount|null} [account] GetAccountInfoByTokenResponse account
                     */
    
                    /**
                     * Constructs a new GetAccountInfoByTokenResponse.
                     * @memberof palexy.janus.v1
                     * @classdesc Represents a GetAccountInfoByTokenResponse.
                     * @implements IGetAccountInfoByTokenResponse
                     * @constructor
                     * @param {palexy.janus.v1.IGetAccountInfoByTokenResponse=} [properties] Properties to set
                     */
                    function GetAccountInfoByTokenResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * GetAccountInfoByTokenResponse account.
                     * @member {palexy.janus.v1.IAccount|null|undefined} account
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenResponse
                     * @instance
                     */
                    GetAccountInfoByTokenResponse.prototype.account = null;
    
                    /**
                     * Creates a new GetAccountInfoByTokenResponse instance using the specified properties.
                     * @function create
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenResponse
                     * @static
                     * @param {palexy.janus.v1.IGetAccountInfoByTokenResponse=} [properties] Properties to set
                     * @returns {palexy.janus.v1.GetAccountInfoByTokenResponse} GetAccountInfoByTokenResponse instance
                     */
                    GetAccountInfoByTokenResponse.create = function create(properties) {
                        return new GetAccountInfoByTokenResponse(properties);
                    };
    
                    /**
                     * Encodes the specified GetAccountInfoByTokenResponse message. Does not implicitly {@link palexy.janus.v1.GetAccountInfoByTokenResponse.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenResponse
                     * @static
                     * @param {palexy.janus.v1.IGetAccountInfoByTokenResponse} message GetAccountInfoByTokenResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetAccountInfoByTokenResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                            $root.palexy.janus.v1.Account.encode(message.account, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified GetAccountInfoByTokenResponse message, length delimited. Does not implicitly {@link palexy.janus.v1.GetAccountInfoByTokenResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenResponse
                     * @static
                     * @param {palexy.janus.v1.IGetAccountInfoByTokenResponse} message GetAccountInfoByTokenResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetAccountInfoByTokenResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a GetAccountInfoByTokenResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.janus.v1.GetAccountInfoByTokenResponse} GetAccountInfoByTokenResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetAccountInfoByTokenResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.janus.v1.GetAccountInfoByTokenResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.account = $root.palexy.janus.v1.Account.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a GetAccountInfoByTokenResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.janus.v1.GetAccountInfoByTokenResponse} GetAccountInfoByTokenResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetAccountInfoByTokenResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a GetAccountInfoByTokenResponse message.
                     * @function verify
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetAccountInfoByTokenResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.account != null && message.hasOwnProperty("account")) {
                            var error = $root.palexy.janus.v1.Account.verify(message.account);
                            if (error)
                                return "account." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a GetAccountInfoByTokenResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.janus.v1.GetAccountInfoByTokenResponse} GetAccountInfoByTokenResponse
                     */
                    GetAccountInfoByTokenResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.janus.v1.GetAccountInfoByTokenResponse)
                            return object;
                        var message = new $root.palexy.janus.v1.GetAccountInfoByTokenResponse();
                        if (object.account != null) {
                            if (typeof object.account !== "object")
                                throw TypeError(".palexy.janus.v1.GetAccountInfoByTokenResponse.account: object expected");
                            message.account = $root.palexy.janus.v1.Account.fromObject(object.account);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a GetAccountInfoByTokenResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenResponse
                     * @static
                     * @param {palexy.janus.v1.GetAccountInfoByTokenResponse} message GetAccountInfoByTokenResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetAccountInfoByTokenResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.account = null;
                        if (message.account != null && message.hasOwnProperty("account"))
                            object.account = $root.palexy.janus.v1.Account.toObject(message.account, options);
                        return object;
                    };
    
                    /**
                     * Converts this GetAccountInfoByTokenResponse to JSON.
                     * @function toJSON
                     * @memberof palexy.janus.v1.GetAccountInfoByTokenResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetAccountInfoByTokenResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return GetAccountInfoByTokenResponse;
                })();
    
                v1.Account = (function() {
    
                    /**
                     * Properties of an Account.
                     * @memberof palexy.janus.v1
                     * @interface IAccount
                     * @property {number|null} [id] Account id
                     * @property {string|null} [username] Account username
                     * @property {string|null} [fullName] Account fullName
                     * @property {string|null} [email] Account email
                     * @property {Array.<string>|null} [roles] Account roles
                     */
    
                    /**
                     * Constructs a new Account.
                     * @memberof palexy.janus.v1
                     * @classdesc Represents an Account.
                     * @implements IAccount
                     * @constructor
                     * @param {palexy.janus.v1.IAccount=} [properties] Properties to set
                     */
                    function Account(properties) {
                        this.roles = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Account id.
                     * @member {number} id
                     * @memberof palexy.janus.v1.Account
                     * @instance
                     */
                    Account.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                    /**
                     * Account username.
                     * @member {string} username
                     * @memberof palexy.janus.v1.Account
                     * @instance
                     */
                    Account.prototype.username = "";
    
                    /**
                     * Account fullName.
                     * @member {string} fullName
                     * @memberof palexy.janus.v1.Account
                     * @instance
                     */
                    Account.prototype.fullName = "";
    
                    /**
                     * Account email.
                     * @member {string} email
                     * @memberof palexy.janus.v1.Account
                     * @instance
                     */
                    Account.prototype.email = "";
    
                    /**
                     * Account roles.
                     * @member {Array.<string>} roles
                     * @memberof palexy.janus.v1.Account
                     * @instance
                     */
                    Account.prototype.roles = $util.emptyArray;
    
                    /**
                     * Creates a new Account instance using the specified properties.
                     * @function create
                     * @memberof palexy.janus.v1.Account
                     * @static
                     * @param {palexy.janus.v1.IAccount=} [properties] Properties to set
                     * @returns {palexy.janus.v1.Account} Account instance
                     */
                    Account.create = function create(properties) {
                        return new Account(properties);
                    };
    
                    /**
                     * Encodes the specified Account message. Does not implicitly {@link palexy.janus.v1.Account.verify|verify} messages.
                     * @function encode
                     * @memberof palexy.janus.v1.Account
                     * @static
                     * @param {palexy.janus.v1.IAccount} message Account message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Account.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.username);
                        if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fullName);
                        if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                        if (message.roles != null && message.roles.length)
                            for (var i = 0; i < message.roles.length; ++i)
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.roles[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Account message, length delimited. Does not implicitly {@link palexy.janus.v1.Account.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof palexy.janus.v1.Account
                     * @static
                     * @param {palexy.janus.v1.IAccount} message Account message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Account.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Account message from the specified reader or buffer.
                     * @function decode
                     * @memberof palexy.janus.v1.Account
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {palexy.janus.v1.Account} Account
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Account.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.palexy.janus.v1.Account();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.username = reader.string();
                                break;
                            case 3:
                                message.fullName = reader.string();
                                break;
                            case 4:
                                message.email = reader.string();
                                break;
                            case 5:
                                if (!(message.roles && message.roles.length))
                                    message.roles = [];
                                message.roles.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Account message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof palexy.janus.v1.Account
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {palexy.janus.v1.Account} Account
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Account.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Account message.
                     * @function verify
                     * @memberof palexy.janus.v1.Account
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Account.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.username != null && message.hasOwnProperty("username"))
                            if (!$util.isString(message.username))
                                return "username: string expected";
                        if (message.fullName != null && message.hasOwnProperty("fullName"))
                            if (!$util.isString(message.fullName))
                                return "fullName: string expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.roles != null && message.hasOwnProperty("roles")) {
                            if (!Array.isArray(message.roles))
                                return "roles: array expected";
                            for (var i = 0; i < message.roles.length; ++i)
                                if (!$util.isString(message.roles[i]))
                                    return "roles: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates an Account message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof palexy.janus.v1.Account
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {palexy.janus.v1.Account} Account
                     */
                    Account.fromObject = function fromObject(object) {
                        if (object instanceof $root.palexy.janus.v1.Account)
                            return object;
                        var message = new $root.palexy.janus.v1.Account();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.username != null)
                            message.username = String(object.username);
                        if (object.fullName != null)
                            message.fullName = String(object.fullName);
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.roles) {
                            if (!Array.isArray(object.roles))
                                throw TypeError(".palexy.janus.v1.Account.roles: array expected");
                            message.roles = [];
                            for (var i = 0; i < object.roles.length; ++i)
                                message.roles[i] = String(object.roles[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an Account message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof palexy.janus.v1.Account
                     * @static
                     * @param {palexy.janus.v1.Account} message Account
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Account.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.roles = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.username = "";
                            object.fullName = "";
                            object.email = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.username != null && message.hasOwnProperty("username"))
                            object.username = message.username;
                        if (message.fullName != null && message.hasOwnProperty("fullName"))
                            object.fullName = message.fullName;
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.roles && message.roles.length) {
                            object.roles = [];
                            for (var j = 0; j < message.roles.length; ++j)
                                object.roles[j] = message.roles[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Account to JSON.
                     * @function toJSON
                     * @memberof palexy.janus.v1.Account
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Account.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Account;
                })();
    
                return v1;
            })();
    
            return janus;
        })();
    
        return palexy;
    })();
    
    $root.google = (function() {
    
        /**
         * Namespace google.
         * @exports google
         * @namespace
         */
        var google = {};
    
        google.api = (function() {
    
            /**
             * Namespace api.
             * @memberof google
             * @namespace
             */
            var api = {};
    
            api.Http = (function() {
    
                /**
                 * Properties of a Http.
                 * @memberof google.api
                 * @interface IHttp
                 * @property {Array.<google.api.IHttpRule>|null} [rules] Http rules
                 * @property {boolean|null} [fullyDecodeReservedExpansion] Http fullyDecodeReservedExpansion
                 */
    
                /**
                 * Constructs a new Http.
                 * @memberof google.api
                 * @classdesc Represents a Http.
                 * @implements IHttp
                 * @constructor
                 * @param {google.api.IHttp=} [properties] Properties to set
                 */
                function Http(properties) {
                    this.rules = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Http rules.
                 * @member {Array.<google.api.IHttpRule>} rules
                 * @memberof google.api.Http
                 * @instance
                 */
                Http.prototype.rules = $util.emptyArray;
    
                /**
                 * Http fullyDecodeReservedExpansion.
                 * @member {boolean} fullyDecodeReservedExpansion
                 * @memberof google.api.Http
                 * @instance
                 */
                Http.prototype.fullyDecodeReservedExpansion = false;
    
                /**
                 * Creates a new Http instance using the specified properties.
                 * @function create
                 * @memberof google.api.Http
                 * @static
                 * @param {google.api.IHttp=} [properties] Properties to set
                 * @returns {google.api.Http} Http instance
                 */
                Http.create = function create(properties) {
                    return new Http(properties);
                };
    
                /**
                 * Encodes the specified Http message. Does not implicitly {@link google.api.Http.verify|verify} messages.
                 * @function encode
                 * @memberof google.api.Http
                 * @static
                 * @param {google.api.IHttp} message Http message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Http.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.rules != null && message.rules.length)
                        for (var i = 0; i < message.rules.length; ++i)
                            $root.google.api.HttpRule.encode(message.rules[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fullyDecodeReservedExpansion != null && Object.hasOwnProperty.call(message, "fullyDecodeReservedExpansion"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.fullyDecodeReservedExpansion);
                    return writer;
                };
    
                /**
                 * Encodes the specified Http message, length delimited. Does not implicitly {@link google.api.Http.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.api.Http
                 * @static
                 * @param {google.api.IHttp} message Http message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Http.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Http message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.api.Http
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.api.Http} Http
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Http.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.api.Http();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.rules && message.rules.length))
                                message.rules = [];
                            message.rules.push($root.google.api.HttpRule.decode(reader, reader.uint32()));
                            break;
                        case 2:
                            message.fullyDecodeReservedExpansion = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Http message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.api.Http
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.api.Http} Http
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Http.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Http message.
                 * @function verify
                 * @memberof google.api.Http
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Http.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.rules != null && message.hasOwnProperty("rules")) {
                        if (!Array.isArray(message.rules))
                            return "rules: array expected";
                        for (var i = 0; i < message.rules.length; ++i) {
                            var error = $root.google.api.HttpRule.verify(message.rules[i]);
                            if (error)
                                return "rules." + error;
                        }
                    }
                    if (message.fullyDecodeReservedExpansion != null && message.hasOwnProperty("fullyDecodeReservedExpansion"))
                        if (typeof message.fullyDecodeReservedExpansion !== "boolean")
                            return "fullyDecodeReservedExpansion: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a Http message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.api.Http
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.api.Http} Http
                 */
                Http.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.api.Http)
                        return object;
                    var message = new $root.google.api.Http();
                    if (object.rules) {
                        if (!Array.isArray(object.rules))
                            throw TypeError(".google.api.Http.rules: array expected");
                        message.rules = [];
                        for (var i = 0; i < object.rules.length; ++i) {
                            if (typeof object.rules[i] !== "object")
                                throw TypeError(".google.api.Http.rules: object expected");
                            message.rules[i] = $root.google.api.HttpRule.fromObject(object.rules[i]);
                        }
                    }
                    if (object.fullyDecodeReservedExpansion != null)
                        message.fullyDecodeReservedExpansion = Boolean(object.fullyDecodeReservedExpansion);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Http message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.api.Http
                 * @static
                 * @param {google.api.Http} message Http
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Http.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.rules = [];
                    if (options.defaults)
                        object.fullyDecodeReservedExpansion = false;
                    if (message.rules && message.rules.length) {
                        object.rules = [];
                        for (var j = 0; j < message.rules.length; ++j)
                            object.rules[j] = $root.google.api.HttpRule.toObject(message.rules[j], options);
                    }
                    if (message.fullyDecodeReservedExpansion != null && message.hasOwnProperty("fullyDecodeReservedExpansion"))
                        object.fullyDecodeReservedExpansion = message.fullyDecodeReservedExpansion;
                    return object;
                };
    
                /**
                 * Converts this Http to JSON.
                 * @function toJSON
                 * @memberof google.api.Http
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Http.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Http;
            })();
    
            api.HttpRule = (function() {
    
                /**
                 * Properties of a HttpRule.
                 * @memberof google.api
                 * @interface IHttpRule
                 * @property {string|null} [selector] HttpRule selector
                 * @property {string|null} [get] HttpRule get
                 * @property {string|null} [put] HttpRule put
                 * @property {string|null} [post] HttpRule post
                 * @property {string|null} ["delete"] HttpRule delete
                 * @property {string|null} [patch] HttpRule patch
                 * @property {google.api.ICustomHttpPattern|null} [custom] HttpRule custom
                 * @property {string|null} [body] HttpRule body
                 * @property {string|null} [responseBody] HttpRule responseBody
                 * @property {Array.<google.api.IHttpRule>|null} [additionalBindings] HttpRule additionalBindings
                 */
    
                /**
                 * Constructs a new HttpRule.
                 * @memberof google.api
                 * @classdesc Represents a HttpRule.
                 * @implements IHttpRule
                 * @constructor
                 * @param {google.api.IHttpRule=} [properties] Properties to set
                 */
                function HttpRule(properties) {
                    this.additionalBindings = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * HttpRule selector.
                 * @member {string} selector
                 * @memberof google.api.HttpRule
                 * @instance
                 */
                HttpRule.prototype.selector = "";
    
                /**
                 * HttpRule get.
                 * @member {string|null|undefined} get
                 * @memberof google.api.HttpRule
                 * @instance
                 */
                HttpRule.prototype.get = null;
    
                /**
                 * HttpRule put.
                 * @member {string|null|undefined} put
                 * @memberof google.api.HttpRule
                 * @instance
                 */
                HttpRule.prototype.put = null;
    
                /**
                 * HttpRule post.
                 * @member {string|null|undefined} post
                 * @memberof google.api.HttpRule
                 * @instance
                 */
                HttpRule.prototype.post = null;
    
                /**
                 * HttpRule delete.
                 * @member {string|null|undefined} delete
                 * @memberof google.api.HttpRule
                 * @instance
                 */
                HttpRule.prototype["delete"] = null;
    
                /**
                 * HttpRule patch.
                 * @member {string|null|undefined} patch
                 * @memberof google.api.HttpRule
                 * @instance
                 */
                HttpRule.prototype.patch = null;
    
                /**
                 * HttpRule custom.
                 * @member {google.api.ICustomHttpPattern|null|undefined} custom
                 * @memberof google.api.HttpRule
                 * @instance
                 */
                HttpRule.prototype.custom = null;
    
                /**
                 * HttpRule body.
                 * @member {string} body
                 * @memberof google.api.HttpRule
                 * @instance
                 */
                HttpRule.prototype.body = "";
    
                /**
                 * HttpRule responseBody.
                 * @member {string} responseBody
                 * @memberof google.api.HttpRule
                 * @instance
                 */
                HttpRule.prototype.responseBody = "";
    
                /**
                 * HttpRule additionalBindings.
                 * @member {Array.<google.api.IHttpRule>} additionalBindings
                 * @memberof google.api.HttpRule
                 * @instance
                 */
                HttpRule.prototype.additionalBindings = $util.emptyArray;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * HttpRule pattern.
                 * @member {"get"|"put"|"post"|"delete"|"patch"|"custom"|undefined} pattern
                 * @memberof google.api.HttpRule
                 * @instance
                 */
                Object.defineProperty(HttpRule.prototype, "pattern", {
                    get: $util.oneOfGetter($oneOfFields = ["get", "put", "post", "delete", "patch", "custom"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new HttpRule instance using the specified properties.
                 * @function create
                 * @memberof google.api.HttpRule
                 * @static
                 * @param {google.api.IHttpRule=} [properties] Properties to set
                 * @returns {google.api.HttpRule} HttpRule instance
                 */
                HttpRule.create = function create(properties) {
                    return new HttpRule(properties);
                };
    
                /**
                 * Encodes the specified HttpRule message. Does not implicitly {@link google.api.HttpRule.verify|verify} messages.
                 * @function encode
                 * @memberof google.api.HttpRule
                 * @static
                 * @param {google.api.IHttpRule} message HttpRule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HttpRule.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.selector != null && Object.hasOwnProperty.call(message, "selector"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.selector);
                    if (message.get != null && Object.hasOwnProperty.call(message, "get"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.get);
                    if (message.put != null && Object.hasOwnProperty.call(message, "put"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.put);
                    if (message.post != null && Object.hasOwnProperty.call(message, "post"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.post);
                    if (message["delete"] != null && Object.hasOwnProperty.call(message, "delete"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message["delete"]);
                    if (message.patch != null && Object.hasOwnProperty.call(message, "patch"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.patch);
                    if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.body);
                    if (message.custom != null && Object.hasOwnProperty.call(message, "custom"))
                        $root.google.api.CustomHttpPattern.encode(message.custom, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.additionalBindings != null && message.additionalBindings.length)
                        for (var i = 0; i < message.additionalBindings.length; ++i)
                            $root.google.api.HttpRule.encode(message.additionalBindings[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.responseBody != null && Object.hasOwnProperty.call(message, "responseBody"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.responseBody);
                    return writer;
                };
    
                /**
                 * Encodes the specified HttpRule message, length delimited. Does not implicitly {@link google.api.HttpRule.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.api.HttpRule
                 * @static
                 * @param {google.api.IHttpRule} message HttpRule message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HttpRule.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a HttpRule message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.api.HttpRule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.api.HttpRule} HttpRule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HttpRule.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.api.HttpRule();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.selector = reader.string();
                            break;
                        case 2:
                            message.get = reader.string();
                            break;
                        case 3:
                            message.put = reader.string();
                            break;
                        case 4:
                            message.post = reader.string();
                            break;
                        case 5:
                            message["delete"] = reader.string();
                            break;
                        case 6:
                            message.patch = reader.string();
                            break;
                        case 8:
                            message.custom = $root.google.api.CustomHttpPattern.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.body = reader.string();
                            break;
                        case 12:
                            message.responseBody = reader.string();
                            break;
                        case 11:
                            if (!(message.additionalBindings && message.additionalBindings.length))
                                message.additionalBindings = [];
                            message.additionalBindings.push($root.google.api.HttpRule.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a HttpRule message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.api.HttpRule
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.api.HttpRule} HttpRule
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HttpRule.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a HttpRule message.
                 * @function verify
                 * @memberof google.api.HttpRule
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                HttpRule.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.selector != null && message.hasOwnProperty("selector"))
                        if (!$util.isString(message.selector))
                            return "selector: string expected";
                    if (message.get != null && message.hasOwnProperty("get")) {
                        properties.pattern = 1;
                        if (!$util.isString(message.get))
                            return "get: string expected";
                    }
                    if (message.put != null && message.hasOwnProperty("put")) {
                        if (properties.pattern === 1)
                            return "pattern: multiple values";
                        properties.pattern = 1;
                        if (!$util.isString(message.put))
                            return "put: string expected";
                    }
                    if (message.post != null && message.hasOwnProperty("post")) {
                        if (properties.pattern === 1)
                            return "pattern: multiple values";
                        properties.pattern = 1;
                        if (!$util.isString(message.post))
                            return "post: string expected";
                    }
                    if (message["delete"] != null && message.hasOwnProperty("delete")) {
                        if (properties.pattern === 1)
                            return "pattern: multiple values";
                        properties.pattern = 1;
                        if (!$util.isString(message["delete"]))
                            return "delete: string expected";
                    }
                    if (message.patch != null && message.hasOwnProperty("patch")) {
                        if (properties.pattern === 1)
                            return "pattern: multiple values";
                        properties.pattern = 1;
                        if (!$util.isString(message.patch))
                            return "patch: string expected";
                    }
                    if (message.custom != null && message.hasOwnProperty("custom")) {
                        if (properties.pattern === 1)
                            return "pattern: multiple values";
                        properties.pattern = 1;
                        {
                            var error = $root.google.api.CustomHttpPattern.verify(message.custom);
                            if (error)
                                return "custom." + error;
                        }
                    }
                    if (message.body != null && message.hasOwnProperty("body"))
                        if (!$util.isString(message.body))
                            return "body: string expected";
                    if (message.responseBody != null && message.hasOwnProperty("responseBody"))
                        if (!$util.isString(message.responseBody))
                            return "responseBody: string expected";
                    if (message.additionalBindings != null && message.hasOwnProperty("additionalBindings")) {
                        if (!Array.isArray(message.additionalBindings))
                            return "additionalBindings: array expected";
                        for (var i = 0; i < message.additionalBindings.length; ++i) {
                            var error = $root.google.api.HttpRule.verify(message.additionalBindings[i]);
                            if (error)
                                return "additionalBindings." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a HttpRule message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.api.HttpRule
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.api.HttpRule} HttpRule
                 */
                HttpRule.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.api.HttpRule)
                        return object;
                    var message = new $root.google.api.HttpRule();
                    if (object.selector != null)
                        message.selector = String(object.selector);
                    if (object.get != null)
                        message.get = String(object.get);
                    if (object.put != null)
                        message.put = String(object.put);
                    if (object.post != null)
                        message.post = String(object.post);
                    if (object["delete"] != null)
                        message["delete"] = String(object["delete"]);
                    if (object.patch != null)
                        message.patch = String(object.patch);
                    if (object.custom != null) {
                        if (typeof object.custom !== "object")
                            throw TypeError(".google.api.HttpRule.custom: object expected");
                        message.custom = $root.google.api.CustomHttpPattern.fromObject(object.custom);
                    }
                    if (object.body != null)
                        message.body = String(object.body);
                    if (object.responseBody != null)
                        message.responseBody = String(object.responseBody);
                    if (object.additionalBindings) {
                        if (!Array.isArray(object.additionalBindings))
                            throw TypeError(".google.api.HttpRule.additionalBindings: array expected");
                        message.additionalBindings = [];
                        for (var i = 0; i < object.additionalBindings.length; ++i) {
                            if (typeof object.additionalBindings[i] !== "object")
                                throw TypeError(".google.api.HttpRule.additionalBindings: object expected");
                            message.additionalBindings[i] = $root.google.api.HttpRule.fromObject(object.additionalBindings[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a HttpRule message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.api.HttpRule
                 * @static
                 * @param {google.api.HttpRule} message HttpRule
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                HttpRule.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.additionalBindings = [];
                    if (options.defaults) {
                        object.selector = "";
                        object.body = "";
                        object.responseBody = "";
                    }
                    if (message.selector != null && message.hasOwnProperty("selector"))
                        object.selector = message.selector;
                    if (message.get != null && message.hasOwnProperty("get")) {
                        object.get = message.get;
                        if (options.oneofs)
                            object.pattern = "get";
                    }
                    if (message.put != null && message.hasOwnProperty("put")) {
                        object.put = message.put;
                        if (options.oneofs)
                            object.pattern = "put";
                    }
                    if (message.post != null && message.hasOwnProperty("post")) {
                        object.post = message.post;
                        if (options.oneofs)
                            object.pattern = "post";
                    }
                    if (message["delete"] != null && message.hasOwnProperty("delete")) {
                        object["delete"] = message["delete"];
                        if (options.oneofs)
                            object.pattern = "delete";
                    }
                    if (message.patch != null && message.hasOwnProperty("patch")) {
                        object.patch = message.patch;
                        if (options.oneofs)
                            object.pattern = "patch";
                    }
                    if (message.body != null && message.hasOwnProperty("body"))
                        object.body = message.body;
                    if (message.custom != null && message.hasOwnProperty("custom")) {
                        object.custom = $root.google.api.CustomHttpPattern.toObject(message.custom, options);
                        if (options.oneofs)
                            object.pattern = "custom";
                    }
                    if (message.additionalBindings && message.additionalBindings.length) {
                        object.additionalBindings = [];
                        for (var j = 0; j < message.additionalBindings.length; ++j)
                            object.additionalBindings[j] = $root.google.api.HttpRule.toObject(message.additionalBindings[j], options);
                    }
                    if (message.responseBody != null && message.hasOwnProperty("responseBody"))
                        object.responseBody = message.responseBody;
                    return object;
                };
    
                /**
                 * Converts this HttpRule to JSON.
                 * @function toJSON
                 * @memberof google.api.HttpRule
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                HttpRule.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return HttpRule;
            })();
    
            api.CustomHttpPattern = (function() {
    
                /**
                 * Properties of a CustomHttpPattern.
                 * @memberof google.api
                 * @interface ICustomHttpPattern
                 * @property {string|null} [kind] CustomHttpPattern kind
                 * @property {string|null} [path] CustomHttpPattern path
                 */
    
                /**
                 * Constructs a new CustomHttpPattern.
                 * @memberof google.api
                 * @classdesc Represents a CustomHttpPattern.
                 * @implements ICustomHttpPattern
                 * @constructor
                 * @param {google.api.ICustomHttpPattern=} [properties] Properties to set
                 */
                function CustomHttpPattern(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * CustomHttpPattern kind.
                 * @member {string} kind
                 * @memberof google.api.CustomHttpPattern
                 * @instance
                 */
                CustomHttpPattern.prototype.kind = "";
    
                /**
                 * CustomHttpPattern path.
                 * @member {string} path
                 * @memberof google.api.CustomHttpPattern
                 * @instance
                 */
                CustomHttpPattern.prototype.path = "";
    
                /**
                 * Creates a new CustomHttpPattern instance using the specified properties.
                 * @function create
                 * @memberof google.api.CustomHttpPattern
                 * @static
                 * @param {google.api.ICustomHttpPattern=} [properties] Properties to set
                 * @returns {google.api.CustomHttpPattern} CustomHttpPattern instance
                 */
                CustomHttpPattern.create = function create(properties) {
                    return new CustomHttpPattern(properties);
                };
    
                /**
                 * Encodes the specified CustomHttpPattern message. Does not implicitly {@link google.api.CustomHttpPattern.verify|verify} messages.
                 * @function encode
                 * @memberof google.api.CustomHttpPattern
                 * @static
                 * @param {google.api.ICustomHttpPattern} message CustomHttpPattern message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomHttpPattern.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.kind);
                    if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
                    return writer;
                };
    
                /**
                 * Encodes the specified CustomHttpPattern message, length delimited. Does not implicitly {@link google.api.CustomHttpPattern.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.api.CustomHttpPattern
                 * @static
                 * @param {google.api.ICustomHttpPattern} message CustomHttpPattern message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CustomHttpPattern.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a CustomHttpPattern message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.api.CustomHttpPattern
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.api.CustomHttpPattern} CustomHttpPattern
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomHttpPattern.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.api.CustomHttpPattern();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.kind = reader.string();
                            break;
                        case 2:
                            message.path = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a CustomHttpPattern message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.api.CustomHttpPattern
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.api.CustomHttpPattern} CustomHttpPattern
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CustomHttpPattern.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a CustomHttpPattern message.
                 * @function verify
                 * @memberof google.api.CustomHttpPattern
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CustomHttpPattern.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.kind != null && message.hasOwnProperty("kind"))
                        if (!$util.isString(message.kind))
                            return "kind: string expected";
                    if (message.path != null && message.hasOwnProperty("path"))
                        if (!$util.isString(message.path))
                            return "path: string expected";
                    return null;
                };
    
                /**
                 * Creates a CustomHttpPattern message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.api.CustomHttpPattern
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.api.CustomHttpPattern} CustomHttpPattern
                 */
                CustomHttpPattern.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.api.CustomHttpPattern)
                        return object;
                    var message = new $root.google.api.CustomHttpPattern();
                    if (object.kind != null)
                        message.kind = String(object.kind);
                    if (object.path != null)
                        message.path = String(object.path);
                    return message;
                };
    
                /**
                 * Creates a plain object from a CustomHttpPattern message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.api.CustomHttpPattern
                 * @static
                 * @param {google.api.CustomHttpPattern} message CustomHttpPattern
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CustomHttpPattern.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.kind = "";
                        object.path = "";
                    }
                    if (message.kind != null && message.hasOwnProperty("kind"))
                        object.kind = message.kind;
                    if (message.path != null && message.hasOwnProperty("path"))
                        object.path = message.path;
                    return object;
                };
    
                /**
                 * Converts this CustomHttpPattern to JSON.
                 * @function toJSON
                 * @memberof google.api.CustomHttpPattern
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CustomHttpPattern.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return CustomHttpPattern;
            })();
    
            return api;
        })();
    
        google.protobuf = (function() {
    
            /**
             * Namespace protobuf.
             * @memberof google
             * @namespace
             */
            var protobuf = {};
    
            protobuf.FileDescriptorSet = (function() {
    
                /**
                 * Properties of a FileDescriptorSet.
                 * @memberof google.protobuf
                 * @interface IFileDescriptorSet
                 * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
                 */
    
                /**
                 * Constructs a new FileDescriptorSet.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileDescriptorSet.
                 * @implements IFileDescriptorSet
                 * @constructor
                 * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
                 */
                function FileDescriptorSet(properties) {
                    this.file = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileDescriptorSet file.
                 * @member {Array.<google.protobuf.IFileDescriptorProto>} file
                 * @memberof google.protobuf.FileDescriptorSet
                 * @instance
                 */
                FileDescriptorSet.prototype.file = $util.emptyArray;
    
                /**
                 * Creates a new FileDescriptorSet instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
                 */
                FileDescriptorSet.create = function create(properties) {
                    return new FileDescriptorSet(properties);
                };
    
                /**
                 * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorSet.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.file != null && message.file.length)
                        for (var i = 0; i < message.file.length; ++i)
                            $root.google.protobuf.FileDescriptorProto.encode(message.file[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FileDescriptorSet message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorSet.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileDescriptorSet message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorSet.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorSet();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.file && message.file.length))
                                message.file = [];
                            message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileDescriptorSet message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorSet.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileDescriptorSet message.
                 * @function verify
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileDescriptorSet.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.file != null && message.hasOwnProperty("file")) {
                        if (!Array.isArray(message.file))
                            return "file: array expected";
                        for (var i = 0; i < message.file.length; ++i) {
                            var error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                            if (error)
                                return "file." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
                 */
                FileDescriptorSet.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileDescriptorSet)
                        return object;
                    var message = new $root.google.protobuf.FileDescriptorSet();
                    if (object.file) {
                        if (!Array.isArray(object.file))
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                        message.file = [];
                        for (var i = 0; i < object.file.length; ++i) {
                            if (typeof object.file[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                            message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileDescriptorSet
                 * @static
                 * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileDescriptorSet.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.file = [];
                    if (message.file && message.file.length) {
                        object.file = [];
                        for (var j = 0; j < message.file.length; ++j)
                            object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this FileDescriptorSet to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileDescriptorSet
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileDescriptorSet.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return FileDescriptorSet;
            })();
    
            protobuf.FileDescriptorProto = (function() {
    
                /**
                 * Properties of a FileDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IFileDescriptorProto
                 * @property {string|null} [name] FileDescriptorProto name
                 * @property {string|null} ["package"] FileDescriptorProto package
                 * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
                 * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
                 * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
                 * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
                 * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
                 * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
                 * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
                 * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
                 * @property {string|null} [syntax] FileDescriptorProto syntax
                 */
    
                /**
                 * Constructs a new FileDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileDescriptorProto.
                 * @implements IFileDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
                 */
                function FileDescriptorProto(properties) {
                    this.dependency = [];
                    this.publicDependency = [];
                    this.weakDependency = [];
                    this.messageType = [];
                    this.enumType = [];
                    this.service = [];
                    this.extension = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.name = "";
    
                /**
                 * FileDescriptorProto package.
                 * @member {string} package
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype["package"] = "";
    
                /**
                 * FileDescriptorProto dependency.
                 * @member {Array.<string>} dependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.dependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto publicDependency.
                 * @member {Array.<number>} publicDependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.publicDependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto weakDependency.
                 * @member {Array.<number>} weakDependency
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.weakDependency = $util.emptyArray;
    
                /**
                 * FileDescriptorProto messageType.
                 * @member {Array.<google.protobuf.IDescriptorProto>} messageType
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.messageType = $util.emptyArray;
    
                /**
                 * FileDescriptorProto enumType.
                 * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.enumType = $util.emptyArray;
    
                /**
                 * FileDescriptorProto service.
                 * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.service = $util.emptyArray;
    
                /**
                 * FileDescriptorProto extension.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.extension = $util.emptyArray;
    
                /**
                 * FileDescriptorProto options.
                 * @member {google.protobuf.IFileOptions|null|undefined} options
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.options = null;
    
                /**
                 * FileDescriptorProto sourceCodeInfo.
                 * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.sourceCodeInfo = null;
    
                /**
                 * FileDescriptorProto syntax.
                 * @member {string} syntax
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 */
                FileDescriptorProto.prototype.syntax = "";
    
                /**
                 * Creates a new FileDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
                 */
                FileDescriptorProto.create = function create(properties) {
                    return new FileDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message["package"]);
                    if (message.dependency != null && message.dependency.length)
                        for (var i = 0; i < message.dependency.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.dependency[i]);
                    if (message.messageType != null && message.messageType.length)
                        for (var i = 0; i < message.messageType.length; ++i)
                            $root.google.protobuf.DescriptorProto.encode(message.messageType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.enumType != null && message.enumType.length)
                        for (var i = 0; i < message.enumType.length; ++i)
                            $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.service != null && message.service.length)
                        for (var i = 0; i < message.service.length; ++i)
                            $root.google.protobuf.ServiceDescriptorProto.encode(message.service[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.extension != null && message.extension.length)
                        for (var i = 0; i < message.extension.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.FileOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, "sourceCodeInfo"))
                        $root.google.protobuf.SourceCodeInfo.encode(message.sourceCodeInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.publicDependency != null && message.publicDependency.length)
                        for (var i = 0; i < message.publicDependency.length; ++i)
                            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.publicDependency[i]);
                    if (message.weakDependency != null && message.weakDependency.length)
                        for (var i = 0; i < message.weakDependency.length; ++i)
                            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.weakDependency[i]);
                    if (message.syntax != null && Object.hasOwnProperty.call(message, "syntax"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.syntax);
                    return writer;
                };
    
                /**
                 * Encodes the specified FileDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message["package"] = reader.string();
                            break;
                        case 3:
                            if (!(message.dependency && message.dependency.length))
                                message.dependency = [];
                            message.dependency.push(reader.string());
                            break;
                        case 10:
                            if (!(message.publicDependency && message.publicDependency.length))
                                message.publicDependency = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.publicDependency.push(reader.int32());
                            } else
                                message.publicDependency.push(reader.int32());
                            break;
                        case 11:
                            if (!(message.weakDependency && message.weakDependency.length))
                                message.weakDependency = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.weakDependency.push(reader.int32());
                            } else
                                message.weakDependency.push(reader.int32());
                            break;
                        case 4:
                            if (!(message.messageType && message.messageType.length))
                                message.messageType = [];
                            message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 5:
                            if (!(message.enumType && message.enumType.length))
                                message.enumType = [];
                            message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 6:
                            if (!(message.service && message.service.length))
                                message.service = [];
                            message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 7:
                            if (!(message.extension && message.extension.length))
                                message.extension = [];
                            message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 8:
                            message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32());
                            break;
                        case 9:
                            message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32());
                            break;
                        case 12:
                            message.syntax = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message["package"] != null && message.hasOwnProperty("package"))
                        if (!$util.isString(message["package"]))
                            return "package: string expected";
                    if (message.dependency != null && message.hasOwnProperty("dependency")) {
                        if (!Array.isArray(message.dependency))
                            return "dependency: array expected";
                        for (var i = 0; i < message.dependency.length; ++i)
                            if (!$util.isString(message.dependency[i]))
                                return "dependency: string[] expected";
                    }
                    if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                        if (!Array.isArray(message.publicDependency))
                            return "publicDependency: array expected";
                        for (var i = 0; i < message.publicDependency.length; ++i)
                            if (!$util.isInteger(message.publicDependency[i]))
                                return "publicDependency: integer[] expected";
                    }
                    if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                        if (!Array.isArray(message.weakDependency))
                            return "weakDependency: array expected";
                        for (var i = 0; i < message.weakDependency.length; ++i)
                            if (!$util.isInteger(message.weakDependency[i]))
                                return "weakDependency: integer[] expected";
                    }
                    if (message.messageType != null && message.hasOwnProperty("messageType")) {
                        if (!Array.isArray(message.messageType))
                            return "messageType: array expected";
                        for (var i = 0; i < message.messageType.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                            if (error)
                                return "messageType." + error;
                        }
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        if (!Array.isArray(message.enumType))
                            return "enumType: array expected";
                        for (var i = 0; i < message.enumType.length; ++i) {
                            var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                            if (error)
                                return "enumType." + error;
                        }
                    }
                    if (message.service != null && message.hasOwnProperty("service")) {
                        if (!Array.isArray(message.service))
                            return "service: array expected";
                        for (var i = 0; i < message.service.length; ++i) {
                            var error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                            if (error)
                                return "service." + error;
                        }
                    }
                    if (message.extension != null && message.hasOwnProperty("extension")) {
                        if (!Array.isArray(message.extension))
                            return "extension: array expected";
                        for (var i = 0; i < message.extension.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                            if (error)
                                return "extension." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.FileOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                        var error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                        if (error)
                            return "sourceCodeInfo." + error;
                    }
                    if (message.syntax != null && message.hasOwnProperty("syntax"))
                        if (!$util.isString(message.syntax))
                            return "syntax: string expected";
                    return null;
                };
    
                /**
                 * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
                 */
                FileDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.FileDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object["package"] != null)
                        message["package"] = String(object["package"]);
                    if (object.dependency) {
                        if (!Array.isArray(object.dependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                        message.dependency = [];
                        for (var i = 0; i < object.dependency.length; ++i)
                            message.dependency[i] = String(object.dependency[i]);
                    }
                    if (object.publicDependency) {
                        if (!Array.isArray(object.publicDependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                        message.publicDependency = [];
                        for (var i = 0; i < object.publicDependency.length; ++i)
                            message.publicDependency[i] = object.publicDependency[i] | 0;
                    }
                    if (object.weakDependency) {
                        if (!Array.isArray(object.weakDependency))
                            throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                        message.weakDependency = [];
                        for (var i = 0; i < object.weakDependency.length; ++i)
                            message.weakDependency[i] = object.weakDependency[i] | 0;
                    }
                    if (object.messageType) {
                        if (!Array.isArray(object.messageType))
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                        message.messageType = [];
                        for (var i = 0; i < object.messageType.length; ++i) {
                            if (typeof object.messageType[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                            message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                        }
                    }
                    if (object.enumType) {
                        if (!Array.isArray(object.enumType))
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                        message.enumType = [];
                        for (var i = 0; i < object.enumType.length; ++i) {
                            if (typeof object.enumType[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                            message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                        }
                    }
                    if (object.service) {
                        if (!Array.isArray(object.service))
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                        message.service = [];
                        for (var i = 0; i < object.service.length; ++i) {
                            if (typeof object.service[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                            message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                        }
                    }
                    if (object.extension) {
                        if (!Array.isArray(object.extension))
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                        message.extension = [];
                        for (var i = 0; i < object.extension.length; ++i) {
                            if (typeof object.extension[i] !== "object")
                                throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                            message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                    }
                    if (object.sourceCodeInfo != null) {
                        if (typeof object.sourceCodeInfo !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                        message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                    }
                    if (object.syntax != null)
                        message.syntax = String(object.syntax);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileDescriptorProto
                 * @static
                 * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.dependency = [];
                        object.messageType = [];
                        object.enumType = [];
                        object.service = [];
                        object.extension = [];
                        object.publicDependency = [];
                        object.weakDependency = [];
                    }
                    if (options.defaults) {
                        object.name = "";
                        object["package"] = "";
                        object.options = null;
                        object.sourceCodeInfo = null;
                        object.syntax = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message["package"] != null && message.hasOwnProperty("package"))
                        object["package"] = message["package"];
                    if (message.dependency && message.dependency.length) {
                        object.dependency = [];
                        for (var j = 0; j < message.dependency.length; ++j)
                            object.dependency[j] = message.dependency[j];
                    }
                    if (message.messageType && message.messageType.length) {
                        object.messageType = [];
                        for (var j = 0; j < message.messageType.length; ++j)
                            object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                    }
                    if (message.enumType && message.enumType.length) {
                        object.enumType = [];
                        for (var j = 0; j < message.enumType.length; ++j)
                            object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                    }
                    if (message.service && message.service.length) {
                        object.service = [];
                        for (var j = 0; j < message.service.length; ++j)
                            object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                    }
                    if (message.extension && message.extension.length) {
                        object.extension = [];
                        for (var j = 0; j < message.extension.length; ++j)
                            object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                    if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                        object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                    if (message.publicDependency && message.publicDependency.length) {
                        object.publicDependency = [];
                        for (var j = 0; j < message.publicDependency.length; ++j)
                            object.publicDependency[j] = message.publicDependency[j];
                    }
                    if (message.weakDependency && message.weakDependency.length) {
                        object.weakDependency = [];
                        for (var j = 0; j < message.weakDependency.length; ++j)
                            object.weakDependency[j] = message.weakDependency[j];
                    }
                    if (message.syntax != null && message.hasOwnProperty("syntax"))
                        object.syntax = message.syntax;
                    return object;
                };
    
                /**
                 * Converts this FileDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return FileDescriptorProto;
            })();
    
            protobuf.DescriptorProto = (function() {
    
                /**
                 * Properties of a DescriptorProto.
                 * @memberof google.protobuf
                 * @interface IDescriptorProto
                 * @property {string|null} [name] DescriptorProto name
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
                 * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
                 * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
                 * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
                 * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
                 * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
                 * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
                 * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
                 * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
                 */
    
                /**
                 * Constructs a new DescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a DescriptorProto.
                 * @implements IDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
                 */
                function DescriptorProto(properties) {
                    this.field = [];
                    this.extension = [];
                    this.nestedType = [];
                    this.enumType = [];
                    this.extensionRange = [];
                    this.oneofDecl = [];
                    this.reservedRange = [];
                    this.reservedName = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.name = "";
    
                /**
                 * DescriptorProto field.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.field = $util.emptyArray;
    
                /**
                 * DescriptorProto extension.
                 * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.extension = $util.emptyArray;
    
                /**
                 * DescriptorProto nestedType.
                 * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.nestedType = $util.emptyArray;
    
                /**
                 * DescriptorProto enumType.
                 * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.enumType = $util.emptyArray;
    
                /**
                 * DescriptorProto extensionRange.
                 * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.extensionRange = $util.emptyArray;
    
                /**
                 * DescriptorProto oneofDecl.
                 * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.oneofDecl = $util.emptyArray;
    
                /**
                 * DescriptorProto options.
                 * @member {google.protobuf.IMessageOptions|null|undefined} options
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.options = null;
    
                /**
                 * DescriptorProto reservedRange.
                 * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.reservedRange = $util.emptyArray;
    
                /**
                 * DescriptorProto reservedName.
                 * @member {Array.<string>} reservedName
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 */
                DescriptorProto.prototype.reservedName = $util.emptyArray;
    
                /**
                 * Creates a new DescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
                 */
                DescriptorProto.create = function create(properties) {
                    return new DescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.field != null && message.field.length)
                        for (var i = 0; i < message.field.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.field[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.nestedType != null && message.nestedType.length)
                        for (var i = 0; i < message.nestedType.length; ++i)
                            $root.google.protobuf.DescriptorProto.encode(message.nestedType[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.enumType != null && message.enumType.length)
                        for (var i = 0; i < message.enumType.length; ++i)
                            $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.extensionRange != null && message.extensionRange.length)
                        for (var i = 0; i < message.extensionRange.length; ++i)
                            $root.google.protobuf.DescriptorProto.ExtensionRange.encode(message.extensionRange[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.extension != null && message.extension.length)
                        for (var i = 0; i < message.extension.length; ++i)
                            $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.MessageOptions.encode(message.options, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.oneofDecl != null && message.oneofDecl.length)
                        for (var i = 0; i < message.oneofDecl.length; ++i)
                            $root.google.protobuf.OneofDescriptorProto.encode(message.oneofDecl[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.reservedRange != null && message.reservedRange.length)
                        for (var i = 0; i < message.reservedRange.length; ++i)
                            $root.google.protobuf.DescriptorProto.ReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.reservedName != null && message.reservedName.length)
                        for (var i = 0; i < message.reservedName.length; ++i)
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.reservedName[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified DescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            if (!(message.field && message.field.length))
                                message.field = [];
                            message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 6:
                            if (!(message.extension && message.extension.length))
                                message.extension = [];
                            message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 3:
                            if (!(message.nestedType && message.nestedType.length))
                                message.nestedType = [];
                            message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 4:
                            if (!(message.enumType && message.enumType.length))
                                message.enumType = [];
                            message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 5:
                            if (!(message.extensionRange && message.extensionRange.length))
                                message.extensionRange = [];
                            message.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()));
                            break;
                        case 8:
                            if (!(message.oneofDecl && message.oneofDecl.length))
                                message.oneofDecl = [];
                            message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 7:
                            message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32());
                            break;
                        case 9:
                            if (!(message.reservedRange && message.reservedRange.length))
                                message.reservedRange = [];
                            message.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()));
                            break;
                        case 10:
                            if (!(message.reservedName && message.reservedName.length))
                                message.reservedName = [];
                            message.reservedName.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.field != null && message.hasOwnProperty("field")) {
                        if (!Array.isArray(message.field))
                            return "field: array expected";
                        for (var i = 0; i < message.field.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                            if (error)
                                return "field." + error;
                        }
                    }
                    if (message.extension != null && message.hasOwnProperty("extension")) {
                        if (!Array.isArray(message.extension))
                            return "extension: array expected";
                        for (var i = 0; i < message.extension.length; ++i) {
                            var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                            if (error)
                                return "extension." + error;
                        }
                    }
                    if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                        if (!Array.isArray(message.nestedType))
                            return "nestedType: array expected";
                        for (var i = 0; i < message.nestedType.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                            if (error)
                                return "nestedType." + error;
                        }
                    }
                    if (message.enumType != null && message.hasOwnProperty("enumType")) {
                        if (!Array.isArray(message.enumType))
                            return "enumType: array expected";
                        for (var i = 0; i < message.enumType.length; ++i) {
                            var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                            if (error)
                                return "enumType." + error;
                        }
                    }
                    if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                        if (!Array.isArray(message.extensionRange))
                            return "extensionRange: array expected";
                        for (var i = 0; i < message.extensionRange.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                            if (error)
                                return "extensionRange." + error;
                        }
                    }
                    if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                        if (!Array.isArray(message.oneofDecl))
                            return "oneofDecl: array expected";
                        for (var i = 0; i < message.oneofDecl.length; ++i) {
                            var error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                            if (error)
                                return "oneofDecl." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.MessageOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                        if (!Array.isArray(message.reservedRange))
                            return "reservedRange: array expected";
                        for (var i = 0; i < message.reservedRange.length; ++i) {
                            var error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                            if (error)
                                return "reservedRange." + error;
                        }
                    }
                    if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                        if (!Array.isArray(message.reservedName))
                            return "reservedName: array expected";
                        for (var i = 0; i < message.reservedName.length; ++i)
                            if (!$util.isString(message.reservedName[i]))
                                return "reservedName: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto} DescriptorProto
                 */
                DescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.field) {
                        if (!Array.isArray(object.field))
                            throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                        message.field = [];
                        for (var i = 0; i < object.field.length; ++i) {
                            if (typeof object.field[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                            message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                        }
                    }
                    if (object.extension) {
                        if (!Array.isArray(object.extension))
                            throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                        message.extension = [];
                        for (var i = 0; i < object.extension.length; ++i) {
                            if (typeof object.extension[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                            message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                        }
                    }
                    if (object.nestedType) {
                        if (!Array.isArray(object.nestedType))
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                        message.nestedType = [];
                        for (var i = 0; i < object.nestedType.length; ++i) {
                            if (typeof object.nestedType[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                            message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                        }
                    }
                    if (object.enumType) {
                        if (!Array.isArray(object.enumType))
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                        message.enumType = [];
                        for (var i = 0; i < object.enumType.length; ++i) {
                            if (typeof object.enumType[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                            message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                        }
                    }
                    if (object.extensionRange) {
                        if (!Array.isArray(object.extensionRange))
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                        message.extensionRange = [];
                        for (var i = 0; i < object.extensionRange.length; ++i) {
                            if (typeof object.extensionRange[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                            message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                        }
                    }
                    if (object.oneofDecl) {
                        if (!Array.isArray(object.oneofDecl))
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                        message.oneofDecl = [];
                        for (var i = 0; i < object.oneofDecl.length; ++i) {
                            if (typeof object.oneofDecl[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                            message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                    }
                    if (object.reservedRange) {
                        if (!Array.isArray(object.reservedRange))
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                        message.reservedRange = [];
                        for (var i = 0; i < object.reservedRange.length; ++i) {
                            if (typeof object.reservedRange[i] !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                            message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                        }
                    }
                    if (object.reservedName) {
                        if (!Array.isArray(object.reservedName))
                            throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                        message.reservedName = [];
                        for (var i = 0; i < object.reservedName.length; ++i)
                            message.reservedName[i] = String(object.reservedName[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto
                 * @static
                 * @param {google.protobuf.DescriptorProto} message DescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.field = [];
                        object.nestedType = [];
                        object.enumType = [];
                        object.extensionRange = [];
                        object.extension = [];
                        object.oneofDecl = [];
                        object.reservedRange = [];
                        object.reservedName = [];
                    }
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.field && message.field.length) {
                        object.field = [];
                        for (var j = 0; j < message.field.length; ++j)
                            object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                    }
                    if (message.nestedType && message.nestedType.length) {
                        object.nestedType = [];
                        for (var j = 0; j < message.nestedType.length; ++j)
                            object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                    }
                    if (message.enumType && message.enumType.length) {
                        object.enumType = [];
                        for (var j = 0; j < message.enumType.length; ++j)
                            object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                    }
                    if (message.extensionRange && message.extensionRange.length) {
                        object.extensionRange = [];
                        for (var j = 0; j < message.extensionRange.length; ++j)
                            object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                    }
                    if (message.extension && message.extension.length) {
                        object.extension = [];
                        for (var j = 0; j < message.extension.length; ++j)
                            object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                    if (message.oneofDecl && message.oneofDecl.length) {
                        object.oneofDecl = [];
                        for (var j = 0; j < message.oneofDecl.length; ++j)
                            object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                    }
                    if (message.reservedRange && message.reservedRange.length) {
                        object.reservedRange = [];
                        for (var j = 0; j < message.reservedRange.length; ++j)
                            object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                    }
                    if (message.reservedName && message.reservedName.length) {
                        object.reservedName = [];
                        for (var j = 0; j < message.reservedName.length; ++j)
                            object.reservedName[j] = message.reservedName[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this DescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                DescriptorProto.ExtensionRange = (function() {
    
                    /**
                     * Properties of an ExtensionRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @interface IExtensionRange
                     * @property {number|null} [start] ExtensionRange start
                     * @property {number|null} [end] ExtensionRange end
                     * @property {google.protobuf.IExtensionRangeOptions|null} [options] ExtensionRange options
                     */
    
                    /**
                     * Constructs a new ExtensionRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @classdesc Represents an ExtensionRange.
                     * @implements IExtensionRange
                     * @constructor
                     * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                     */
                    function ExtensionRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ExtensionRange start.
                     * @member {number} start
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     */
                    ExtensionRange.prototype.start = 0;
    
                    /**
                     * ExtensionRange end.
                     * @member {number} end
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     */
                    ExtensionRange.prototype.end = 0;
    
                    /**
                     * ExtensionRange options.
                     * @member {google.protobuf.IExtensionRangeOptions|null|undefined} options
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     */
                    ExtensionRange.prototype.options = null;
    
                    /**
                     * Creates a new ExtensionRange instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                     */
                    ExtensionRange.create = function create(properties) {
                        return new ExtensionRange(properties);
                    };
    
                    /**
                     * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExtensionRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                        if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                            $root.google.protobuf.ExtensionRangeOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ExtensionRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExtensionRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an ExtensionRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExtensionRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.start = reader.int32();
                                break;
                            case 2:
                                message.end = reader.int32();
                                break;
                            case 3:
                                message.options = $root.google.protobuf.ExtensionRangeOptions.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an ExtensionRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExtensionRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an ExtensionRange message.
                     * @function verify
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExtensionRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.start != null && message.hasOwnProperty("start"))
                            if (!$util.isInteger(message.start))
                                return "start: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        if (message.options != null && message.hasOwnProperty("options")) {
                            var error = $root.google.protobuf.ExtensionRangeOptions.verify(message.options);
                            if (error)
                                return "options." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                     */
                    ExtensionRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                            return object;
                        var message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                        if (object.start != null)
                            message.start = object.start | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        if (object.options != null) {
                            if (typeof object.options !== "object")
                                throw TypeError(".google.protobuf.DescriptorProto.ExtensionRange.options: object expected");
                            message.options = $root.google.protobuf.ExtensionRangeOptions.fromObject(object.options);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExtensionRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.start = 0;
                            object.end = 0;
                            object.options = null;
                        }
                        if (message.start != null && message.hasOwnProperty("start"))
                            object.start = message.start;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        if (message.options != null && message.hasOwnProperty("options"))
                            object.options = $root.google.protobuf.ExtensionRangeOptions.toObject(message.options, options);
                        return object;
                    };
    
                    /**
                     * Converts this ExtensionRange to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.DescriptorProto.ExtensionRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExtensionRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ExtensionRange;
                })();
    
                DescriptorProto.ReservedRange = (function() {
    
                    /**
                     * Properties of a ReservedRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @interface IReservedRange
                     * @property {number|null} [start] ReservedRange start
                     * @property {number|null} [end] ReservedRange end
                     */
    
                    /**
                     * Constructs a new ReservedRange.
                     * @memberof google.protobuf.DescriptorProto
                     * @classdesc Represents a ReservedRange.
                     * @implements IReservedRange
                     * @constructor
                     * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                     */
                    function ReservedRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ReservedRange start.
                     * @member {number} start
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     */
                    ReservedRange.prototype.start = 0;
    
                    /**
                     * ReservedRange end.
                     * @member {number} end
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     */
                    ReservedRange.prototype.end = 0;
    
                    /**
                     * Creates a new ReservedRange instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                     */
                    ReservedRange.create = function create(properties) {
                        return new ReservedRange(properties);
                    };
    
                    /**
                     * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReservedRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ReservedRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReservedRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ReservedRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReservedRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.start = reader.int32();
                                break;
                            case 2:
                                message.end = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ReservedRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReservedRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ReservedRange message.
                     * @function verify
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReservedRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.start != null && message.hasOwnProperty("start"))
                            if (!$util.isInteger(message.start))
                                return "start: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                     */
                    ReservedRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                            return object;
                        var message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                        if (object.start != null)
                            message.start = object.start | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @static
                     * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReservedRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.start = 0;
                            object.end = 0;
                        }
                        if (message.start != null && message.hasOwnProperty("start"))
                            object.start = message.start;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this ReservedRange to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.DescriptorProto.ReservedRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReservedRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ReservedRange;
                })();
    
                return DescriptorProto;
            })();
    
            protobuf.ExtensionRangeOptions = (function() {
    
                /**
                 * Properties of an ExtensionRangeOptions.
                 * @memberof google.protobuf
                 * @interface IExtensionRangeOptions
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ExtensionRangeOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new ExtensionRangeOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an ExtensionRangeOptions.
                 * @implements IExtensionRangeOptions
                 * @constructor
                 * @param {google.protobuf.IExtensionRangeOptions=} [properties] Properties to set
                 */
                function ExtensionRangeOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ExtensionRangeOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.ExtensionRangeOptions
                 * @instance
                 */
                ExtensionRangeOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new ExtensionRangeOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.ExtensionRangeOptions
                 * @static
                 * @param {google.protobuf.IExtensionRangeOptions=} [properties] Properties to set
                 * @returns {google.protobuf.ExtensionRangeOptions} ExtensionRangeOptions instance
                 */
                ExtensionRangeOptions.create = function create(properties) {
                    return new ExtensionRangeOptions(properties);
                };
    
                /**
                 * Encodes the specified ExtensionRangeOptions message. Does not implicitly {@link google.protobuf.ExtensionRangeOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.ExtensionRangeOptions
                 * @static
                 * @param {google.protobuf.IExtensionRangeOptions} message ExtensionRangeOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRangeOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ExtensionRangeOptions message, length delimited. Does not implicitly {@link google.protobuf.ExtensionRangeOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.ExtensionRangeOptions
                 * @static
                 * @param {google.protobuf.IExtensionRangeOptions} message ExtensionRangeOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRangeOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ExtensionRangeOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.ExtensionRangeOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.ExtensionRangeOptions} ExtensionRangeOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRangeOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ExtensionRangeOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ExtensionRangeOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.ExtensionRangeOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.ExtensionRangeOptions} ExtensionRangeOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRangeOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ExtensionRangeOptions message.
                 * @function verify
                 * @memberof google.protobuf.ExtensionRangeOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtensionRangeOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an ExtensionRangeOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.ExtensionRangeOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.ExtensionRangeOptions} ExtensionRangeOptions
                 */
                ExtensionRangeOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.ExtensionRangeOptions)
                        return object;
                    var message = new $root.google.protobuf.ExtensionRangeOptions();
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.ExtensionRangeOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.ExtensionRangeOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an ExtensionRangeOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.ExtensionRangeOptions
                 * @static
                 * @param {google.protobuf.ExtensionRangeOptions} message ExtensionRangeOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtensionRangeOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ExtensionRangeOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.ExtensionRangeOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtensionRangeOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return ExtensionRangeOptions;
            })();
    
            protobuf.FieldDescriptorProto = (function() {
    
                /**
                 * Properties of a FieldDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IFieldDescriptorProto
                 * @property {string|null} [name] FieldDescriptorProto name
                 * @property {number|null} [number] FieldDescriptorProto number
                 * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
                 * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
                 * @property {string|null} [typeName] FieldDescriptorProto typeName
                 * @property {string|null} [extendee] FieldDescriptorProto extendee
                 * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
                 * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
                 * @property {string|null} [jsonName] FieldDescriptorProto jsonName
                 * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
                 */
    
                /**
                 * Constructs a new FieldDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a FieldDescriptorProto.
                 * @implements IFieldDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
                 */
                function FieldDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.name = "";
    
                /**
                 * FieldDescriptorProto number.
                 * @member {number} number
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.number = 0;
    
                /**
                 * FieldDescriptorProto label.
                 * @member {google.protobuf.FieldDescriptorProto.Label} label
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.label = 1;
    
                /**
                 * FieldDescriptorProto type.
                 * @member {google.protobuf.FieldDescriptorProto.Type} type
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.type = 1;
    
                /**
                 * FieldDescriptorProto typeName.
                 * @member {string} typeName
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.typeName = "";
    
                /**
                 * FieldDescriptorProto extendee.
                 * @member {string} extendee
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.extendee = "";
    
                /**
                 * FieldDescriptorProto defaultValue.
                 * @member {string} defaultValue
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.defaultValue = "";
    
                /**
                 * FieldDescriptorProto oneofIndex.
                 * @member {number} oneofIndex
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.oneofIndex = 0;
    
                /**
                 * FieldDescriptorProto jsonName.
                 * @member {string} jsonName
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.jsonName = "";
    
                /**
                 * FieldDescriptorProto options.
                 * @member {google.protobuf.IFieldOptions|null|undefined} options
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 */
                FieldDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new FieldDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
                 */
                FieldDescriptorProto.create = function create(properties) {
                    return new FieldDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.extendee != null && Object.hasOwnProperty.call(message, "extendee"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendee);
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.number);
                    if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.label);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                    if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.typeName);
                    if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.defaultValue);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.FieldOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.oneofIndex != null && Object.hasOwnProperty.call(message, "oneofIndex"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.oneofIndex);
                    if (message.jsonName != null && Object.hasOwnProperty.call(message, "jsonName"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonName);
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 3:
                            message.number = reader.int32();
                            break;
                        case 4:
                            message.label = reader.int32();
                            break;
                        case 5:
                            message.type = reader.int32();
                            break;
                        case 6:
                            message.typeName = reader.string();
                            break;
                        case 2:
                            message.extendee = reader.string();
                            break;
                        case 7:
                            message.defaultValue = reader.string();
                            break;
                        case 9:
                            message.oneofIndex = reader.int32();
                            break;
                        case 10:
                            message.jsonName = reader.string();
                            break;
                        case 8:
                            message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isInteger(message.number))
                            return "number: integer expected";
                    if (message.label != null && message.hasOwnProperty("label"))
                        switch (message.label) {
                        default:
                            return "label: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                            break;
                        }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.extendee != null && message.hasOwnProperty("extendee"))
                        if (!$util.isString(message.extendee))
                            return "extendee: string expected";
                    if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                        if (!$util.isString(message.defaultValue))
                            return "defaultValue: string expected";
                    if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                        if (!$util.isInteger(message.oneofIndex))
                            return "oneofIndex: integer expected";
                    if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                        if (!$util.isString(message.jsonName))
                            return "jsonName: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.FieldOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
                 */
                FieldDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.FieldDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.number != null)
                        message.number = object.number | 0;
                    switch (object.label) {
                    case "LABEL_OPTIONAL":
                    case 1:
                        message.label = 1;
                        break;
                    case "LABEL_REQUIRED":
                    case 2:
                        message.label = 2;
                        break;
                    case "LABEL_REPEATED":
                    case 3:
                        message.label = 3;
                        break;
                    }
                    switch (object.type) {
                    case "TYPE_DOUBLE":
                    case 1:
                        message.type = 1;
                        break;
                    case "TYPE_FLOAT":
                    case 2:
                        message.type = 2;
                        break;
                    case "TYPE_INT64":
                    case 3:
                        message.type = 3;
                        break;
                    case "TYPE_UINT64":
                    case 4:
                        message.type = 4;
                        break;
                    case "TYPE_INT32":
                    case 5:
                        message.type = 5;
                        break;
                    case "TYPE_FIXED64":
                    case 6:
                        message.type = 6;
                        break;
                    case "TYPE_FIXED32":
                    case 7:
                        message.type = 7;
                        break;
                    case "TYPE_BOOL":
                    case 8:
                        message.type = 8;
                        break;
                    case "TYPE_STRING":
                    case 9:
                        message.type = 9;
                        break;
                    case "TYPE_GROUP":
                    case 10:
                        message.type = 10;
                        break;
                    case "TYPE_MESSAGE":
                    case 11:
                        message.type = 11;
                        break;
                    case "TYPE_BYTES":
                    case 12:
                        message.type = 12;
                        break;
                    case "TYPE_UINT32":
                    case 13:
                        message.type = 13;
                        break;
                    case "TYPE_ENUM":
                    case 14:
                        message.type = 14;
                        break;
                    case "TYPE_SFIXED32":
                    case 15:
                        message.type = 15;
                        break;
                    case "TYPE_SFIXED64":
                    case 16:
                        message.type = 16;
                        break;
                    case "TYPE_SINT32":
                    case 17:
                        message.type = 17;
                        break;
                    case "TYPE_SINT64":
                    case 18:
                        message.type = 18;
                        break;
                    }
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.extendee != null)
                        message.extendee = String(object.extendee);
                    if (object.defaultValue != null)
                        message.defaultValue = String(object.defaultValue);
                    if (object.oneofIndex != null)
                        message.oneofIndex = object.oneofIndex | 0;
                    if (object.jsonName != null)
                        message.jsonName = String(object.jsonName);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @static
                 * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.extendee = "";
                        object.number = 0;
                        object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                        object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                        object.typeName = "";
                        object.defaultValue = "";
                        object.options = null;
                        object.oneofIndex = 0;
                        object.jsonName = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.extendee != null && message.hasOwnProperty("extendee"))
                        object.extendee = message.extendee;
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.label != null && message.hasOwnProperty("label"))
                        object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                        object.defaultValue = message.defaultValue;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                    if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                        object.oneofIndex = message.oneofIndex;
                    if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                        object.jsonName = message.jsonName;
                    return object;
                };
    
                /**
                 * Converts this FieldDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FieldDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Type enum.
                 * @name google.protobuf.FieldDescriptorProto.Type
                 * @enum {number}
                 * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
                 * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
                 * @property {number} TYPE_INT64=3 TYPE_INT64 value
                 * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
                 * @property {number} TYPE_INT32=5 TYPE_INT32 value
                 * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
                 * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
                 * @property {number} TYPE_BOOL=8 TYPE_BOOL value
                 * @property {number} TYPE_STRING=9 TYPE_STRING value
                 * @property {number} TYPE_GROUP=10 TYPE_GROUP value
                 * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
                 * @property {number} TYPE_BYTES=12 TYPE_BYTES value
                 * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
                 * @property {number} TYPE_ENUM=14 TYPE_ENUM value
                 * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
                 * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
                 * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
                 * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
                 */
                FieldDescriptorProto.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                    values[valuesById[2] = "TYPE_FLOAT"] = 2;
                    values[valuesById[3] = "TYPE_INT64"] = 3;
                    values[valuesById[4] = "TYPE_UINT64"] = 4;
                    values[valuesById[5] = "TYPE_INT32"] = 5;
                    values[valuesById[6] = "TYPE_FIXED64"] = 6;
                    values[valuesById[7] = "TYPE_FIXED32"] = 7;
                    values[valuesById[8] = "TYPE_BOOL"] = 8;
                    values[valuesById[9] = "TYPE_STRING"] = 9;
                    values[valuesById[10] = "TYPE_GROUP"] = 10;
                    values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                    values[valuesById[12] = "TYPE_BYTES"] = 12;
                    values[valuesById[13] = "TYPE_UINT32"] = 13;
                    values[valuesById[14] = "TYPE_ENUM"] = 14;
                    values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                    values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                    values[valuesById[17] = "TYPE_SINT32"] = 17;
                    values[valuesById[18] = "TYPE_SINT64"] = 18;
                    return values;
                })();
    
                /**
                 * Label enum.
                 * @name google.protobuf.FieldDescriptorProto.Label
                 * @enum {number}
                 * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
                 * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
                 * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
                 */
                FieldDescriptorProto.Label = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                    values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                    values[valuesById[3] = "LABEL_REPEATED"] = 3;
                    return values;
                })();
    
                return FieldDescriptorProto;
            })();
    
            protobuf.OneofDescriptorProto = (function() {
    
                /**
                 * Properties of an OneofDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IOneofDescriptorProto
                 * @property {string|null} [name] OneofDescriptorProto name
                 * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
                 */
    
                /**
                 * Constructs a new OneofDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an OneofDescriptorProto.
                 * @implements IOneofDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
                 */
                function OneofDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OneofDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 */
                OneofDescriptorProto.prototype.name = "";
    
                /**
                 * OneofDescriptorProto options.
                 * @member {google.protobuf.IOneofOptions|null|undefined} options
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 */
                OneofDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new OneofDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
                 */
                OneofDescriptorProto.create = function create(properties) {
                    return new OneofDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.OneofOptions.encode(message.options, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OneofDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OneofDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OneofDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OneofDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OneofDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.OneofOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
                 */
                OneofDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.OneofDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @static
                 * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OneofDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this OneofDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.OneofDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OneofDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return OneofDescriptorProto;
            })();
    
            protobuf.EnumDescriptorProto = (function() {
    
                /**
                 * Properties of an EnumDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IEnumDescriptorProto
                 * @property {string|null} [name] EnumDescriptorProto name
                 * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
                 * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
                 * @property {Array.<google.protobuf.EnumDescriptorProto.IEnumReservedRange>|null} [reservedRange] EnumDescriptorProto reservedRange
                 * @property {Array.<string>|null} [reservedName] EnumDescriptorProto reservedName
                 */
    
                /**
                 * Constructs a new EnumDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumDescriptorProto.
                 * @implements IEnumDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
                 */
                function EnumDescriptorProto(properties) {
                    this.value = [];
                    this.reservedRange = [];
                    this.reservedName = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.name = "";
    
                /**
                 * EnumDescriptorProto value.
                 * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.value = $util.emptyArray;
    
                /**
                 * EnumDescriptorProto options.
                 * @member {google.protobuf.IEnumOptions|null|undefined} options
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.options = null;
    
                /**
                 * EnumDescriptorProto reservedRange.
                 * @member {Array.<google.protobuf.EnumDescriptorProto.IEnumReservedRange>} reservedRange
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.reservedRange = $util.emptyArray;
    
                /**
                 * EnumDescriptorProto reservedName.
                 * @member {Array.<string>} reservedName
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 */
                EnumDescriptorProto.prototype.reservedName = $util.emptyArray;
    
                /**
                 * Creates a new EnumDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
                 */
                EnumDescriptorProto.create = function create(properties) {
                    return new EnumDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.value != null && message.value.length)
                        for (var i = 0; i < message.value.length; ++i)
                            $root.google.protobuf.EnumValueDescriptorProto.encode(message.value[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.EnumOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.reservedRange != null && message.reservedRange.length)
                        for (var i = 0; i < message.reservedRange.length; ++i)
                            $root.google.protobuf.EnumDescriptorProto.EnumReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.reservedName != null && message.reservedName.length)
                        for (var i = 0; i < message.reservedName.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.reservedName[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            if (!(message.value && message.value.length))
                                message.value = [];
                            message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 3:
                            message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32());
                            break;
                        case 4:
                            if (!(message.reservedRange && message.reservedRange.length))
                                message.reservedRange = [];
                            message.reservedRange.push($root.google.protobuf.EnumDescriptorProto.EnumReservedRange.decode(reader, reader.uint32()));
                            break;
                        case 5:
                            if (!(message.reservedName && message.reservedName.length))
                                message.reservedName = [];
                            message.reservedName.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.value != null && message.hasOwnProperty("value")) {
                        if (!Array.isArray(message.value))
                            return "value: array expected";
                        for (var i = 0; i < message.value.length; ++i) {
                            var error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                            if (error)
                                return "value." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.EnumOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                        if (!Array.isArray(message.reservedRange))
                            return "reservedRange: array expected";
                        for (var i = 0; i < message.reservedRange.length; ++i) {
                            var error = $root.google.protobuf.EnumDescriptorProto.EnumReservedRange.verify(message.reservedRange[i]);
                            if (error)
                                return "reservedRange." + error;
                        }
                    }
                    if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                        if (!Array.isArray(message.reservedName))
                            return "reservedName: array expected";
                        for (var i = 0; i < message.reservedName.length; ++i)
                            if (!$util.isString(message.reservedName[i]))
                                return "reservedName: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
                 */
                EnumDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.EnumDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.value) {
                        if (!Array.isArray(object.value))
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                        message.value = [];
                        for (var i = 0; i < object.value.length; ++i) {
                            if (typeof object.value[i] !== "object")
                                throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                            message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                    }
                    if (object.reservedRange) {
                        if (!Array.isArray(object.reservedRange))
                            throw TypeError(".google.protobuf.EnumDescriptorProto.reservedRange: array expected");
                        message.reservedRange = [];
                        for (var i = 0; i < object.reservedRange.length; ++i) {
                            if (typeof object.reservedRange[i] !== "object")
                                throw TypeError(".google.protobuf.EnumDescriptorProto.reservedRange: object expected");
                            message.reservedRange[i] = $root.google.protobuf.EnumDescriptorProto.EnumReservedRange.fromObject(object.reservedRange[i]);
                        }
                    }
                    if (object.reservedName) {
                        if (!Array.isArray(object.reservedName))
                            throw TypeError(".google.protobuf.EnumDescriptorProto.reservedName: array expected");
                        message.reservedName = [];
                        for (var i = 0; i < object.reservedName.length; ++i)
                            message.reservedName[i] = String(object.reservedName[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @static
                 * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.value = [];
                        object.reservedRange = [];
                        object.reservedName = [];
                    }
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.value && message.value.length) {
                        object.value = [];
                        for (var j = 0; j < message.value.length; ++j)
                            object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                    if (message.reservedRange && message.reservedRange.length) {
                        object.reservedRange = [];
                        for (var j = 0; j < message.reservedRange.length; ++j)
                            object.reservedRange[j] = $root.google.protobuf.EnumDescriptorProto.EnumReservedRange.toObject(message.reservedRange[j], options);
                    }
                    if (message.reservedName && message.reservedName.length) {
                        object.reservedName = [];
                        for (var j = 0; j < message.reservedName.length; ++j)
                            object.reservedName[j] = message.reservedName[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this EnumDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                EnumDescriptorProto.EnumReservedRange = (function() {
    
                    /**
                     * Properties of an EnumReservedRange.
                     * @memberof google.protobuf.EnumDescriptorProto
                     * @interface IEnumReservedRange
                     * @property {number|null} [start] EnumReservedRange start
                     * @property {number|null} [end] EnumReservedRange end
                     */
    
                    /**
                     * Constructs a new EnumReservedRange.
                     * @memberof google.protobuf.EnumDescriptorProto
                     * @classdesc Represents an EnumReservedRange.
                     * @implements IEnumReservedRange
                     * @constructor
                     * @param {google.protobuf.EnumDescriptorProto.IEnumReservedRange=} [properties] Properties to set
                     */
                    function EnumReservedRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * EnumReservedRange start.
                     * @member {number} start
                     * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                     * @instance
                     */
                    EnumReservedRange.prototype.start = 0;
    
                    /**
                     * EnumReservedRange end.
                     * @member {number} end
                     * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                     * @instance
                     */
                    EnumReservedRange.prototype.end = 0;
    
                    /**
                     * Creates a new EnumReservedRange instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                     * @static
                     * @param {google.protobuf.EnumDescriptorProto.IEnumReservedRange=} [properties] Properties to set
                     * @returns {google.protobuf.EnumDescriptorProto.EnumReservedRange} EnumReservedRange instance
                     */
                    EnumReservedRange.create = function create(properties) {
                        return new EnumReservedRange(properties);
                    };
    
                    /**
                     * Encodes the specified EnumReservedRange message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.EnumReservedRange.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                     * @static
                     * @param {google.protobuf.EnumDescriptorProto.IEnumReservedRange} message EnumReservedRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EnumReservedRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified EnumReservedRange message, length delimited. Does not implicitly {@link google.protobuf.EnumDescriptorProto.EnumReservedRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                     * @static
                     * @param {google.protobuf.EnumDescriptorProto.IEnumReservedRange} message EnumReservedRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EnumReservedRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an EnumReservedRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.EnumDescriptorProto.EnumReservedRange} EnumReservedRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EnumReservedRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto.EnumReservedRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.start = reader.int32();
                                break;
                            case 2:
                                message.end = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an EnumReservedRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.EnumDescriptorProto.EnumReservedRange} EnumReservedRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EnumReservedRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an EnumReservedRange message.
                     * @function verify
                     * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EnumReservedRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.start != null && message.hasOwnProperty("start"))
                            if (!$util.isInteger(message.start))
                                return "start: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an EnumReservedRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.EnumDescriptorProto.EnumReservedRange} EnumReservedRange
                     */
                    EnumReservedRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.EnumDescriptorProto.EnumReservedRange)
                            return object;
                        var message = new $root.google.protobuf.EnumDescriptorProto.EnumReservedRange();
                        if (object.start != null)
                            message.start = object.start | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an EnumReservedRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                     * @static
                     * @param {google.protobuf.EnumDescriptorProto.EnumReservedRange} message EnumReservedRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EnumReservedRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.start = 0;
                            object.end = 0;
                        }
                        if (message.start != null && message.hasOwnProperty("start"))
                            object.start = message.start;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this EnumReservedRange to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EnumReservedRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return EnumReservedRange;
                })();
    
                return EnumDescriptorProto;
            })();
    
            protobuf.EnumValueDescriptorProto = (function() {
    
                /**
                 * Properties of an EnumValueDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IEnumValueDescriptorProto
                 * @property {string|null} [name] EnumValueDescriptorProto name
                 * @property {number|null} [number] EnumValueDescriptorProto number
                 * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
                 */
    
                /**
                 * Constructs a new EnumValueDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumValueDescriptorProto.
                 * @implements IEnumValueDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
                 */
                function EnumValueDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumValueDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.name = "";
    
                /**
                 * EnumValueDescriptorProto number.
                 * @member {number} number
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.number = 0;
    
                /**
                 * EnumValueDescriptorProto options.
                 * @member {google.protobuf.IEnumValueOptions|null|undefined} options
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 */
                EnumValueDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new EnumValueDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
                 */
                EnumValueDescriptorProto.create = function create(properties) {
                    return new EnumValueDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.number);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.EnumValueOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumValueDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.number = reader.int32();
                            break;
                        case 3:
                            message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumValueDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumValueDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumValueDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isInteger(message.number))
                            return "number: integer expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
                 */
                EnumValueDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.EnumValueDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.number != null)
                        message.number = object.number | 0;
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @static
                 * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumValueDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.number = 0;
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this EnumValueDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumValueDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return EnumValueDescriptorProto;
            })();
    
            protobuf.ServiceDescriptorProto = (function() {
    
                /**
                 * Properties of a ServiceDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IServiceDescriptorProto
                 * @property {string|null} [name] ServiceDescriptorProto name
                 * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
                 * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
                 */
    
                /**
                 * Constructs a new ServiceDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a ServiceDescriptorProto.
                 * @implements IServiceDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
                 */
                function ServiceDescriptorProto(properties) {
                    this.method = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ServiceDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.name = "";
    
                /**
                 * ServiceDescriptorProto method.
                 * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.method = $util.emptyArray;
    
                /**
                 * ServiceDescriptorProto options.
                 * @member {google.protobuf.IServiceOptions|null|undefined} options
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 */
                ServiceDescriptorProto.prototype.options = null;
    
                /**
                 * Creates a new ServiceDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
                 */
                ServiceDescriptorProto.create = function create(properties) {
                    return new ServiceDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.method != null && message.method.length)
                        for (var i = 0; i < message.method.length; ++i)
                            $root.google.protobuf.MethodDescriptorProto.encode(message.method[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.ServiceOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ServiceDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            if (!(message.method && message.method.length))
                                message.method = [];
                            message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        case 3:
                            message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ServiceDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ServiceDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.method != null && message.hasOwnProperty("method")) {
                        if (!Array.isArray(message.method))
                            return "method: array expected";
                        for (var i = 0; i < message.method.length; ++i) {
                            var error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                            if (error)
                                return "method." + error;
                        }
                    }
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.ServiceOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
                 */
                ServiceDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.ServiceDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.method) {
                        if (!Array.isArray(object.method))
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                        message.method = [];
                        for (var i = 0; i < object.method.length; ++i) {
                            if (typeof object.method[i] !== "object")
                                throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                            message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                        }
                    }
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @static
                 * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.method = [];
                    if (options.defaults) {
                        object.name = "";
                        object.options = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.method && message.method.length) {
                        object.method = [];
                        for (var j = 0; j < message.method.length; ++j)
                            object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                    }
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                    return object;
                };
    
                /**
                 * Converts this ServiceDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.ServiceDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return ServiceDescriptorProto;
            })();
    
            protobuf.MethodDescriptorProto = (function() {
    
                /**
                 * Properties of a MethodDescriptorProto.
                 * @memberof google.protobuf
                 * @interface IMethodDescriptorProto
                 * @property {string|null} [name] MethodDescriptorProto name
                 * @property {string|null} [inputType] MethodDescriptorProto inputType
                 * @property {string|null} [outputType] MethodDescriptorProto outputType
                 * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
                 * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
                 * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
                 */
    
                /**
                 * Constructs a new MethodDescriptorProto.
                 * @memberof google.protobuf
                 * @classdesc Represents a MethodDescriptorProto.
                 * @implements IMethodDescriptorProto
                 * @constructor
                 * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
                 */
                function MethodDescriptorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MethodDescriptorProto name.
                 * @member {string} name
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.name = "";
    
                /**
                 * MethodDescriptorProto inputType.
                 * @member {string} inputType
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.inputType = "";
    
                /**
                 * MethodDescriptorProto outputType.
                 * @member {string} outputType
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.outputType = "";
    
                /**
                 * MethodDescriptorProto options.
                 * @member {google.protobuf.IMethodOptions|null|undefined} options
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.options = null;
    
                /**
                 * MethodDescriptorProto clientStreaming.
                 * @member {boolean} clientStreaming
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.clientStreaming = false;
    
                /**
                 * MethodDescriptorProto serverStreaming.
                 * @member {boolean} serverStreaming
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 */
                MethodDescriptorProto.prototype.serverStreaming = false;
    
                /**
                 * Creates a new MethodDescriptorProto instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
                 */
                MethodDescriptorProto.create = function create(properties) {
                    return new MethodDescriptorProto(properties);
                };
    
                /**
                 * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodDescriptorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.inputType != null && Object.hasOwnProperty.call(message, "inputType"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputType);
                    if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputType);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.MethodOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.clientStreaming != null && Object.hasOwnProperty.call(message, "clientStreaming"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clientStreaming);
                    if (message.serverStreaming != null && Object.hasOwnProperty.call(message, "serverStreaming"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.serverStreaming);
                    return writer;
                };
    
                /**
                 * Encodes the specified MethodDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MethodDescriptorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodDescriptorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodDescriptorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.inputType = reader.string();
                            break;
                        case 3:
                            message.outputType = reader.string();
                            break;
                        case 4:
                            message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.clientStreaming = reader.bool();
                            break;
                        case 6:
                            message.serverStreaming = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MethodDescriptorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MethodDescriptorProto message.
                 * @function verify
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MethodDescriptorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        if (!$util.isString(message.inputType))
                            return "inputType: string expected";
                    if (message.outputType != null && message.hasOwnProperty("outputType"))
                        if (!$util.isString(message.outputType))
                            return "outputType: string expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        var error = $root.google.protobuf.MethodOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                        if (typeof message.clientStreaming !== "boolean")
                            return "clientStreaming: boolean expected";
                    if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                        if (typeof message.serverStreaming !== "boolean")
                            return "serverStreaming: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
                 */
                MethodDescriptorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                        return object;
                    var message = new $root.google.protobuf.MethodDescriptorProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.inputType != null)
                        message.inputType = String(object.inputType);
                    if (object.outputType != null)
                        message.outputType = String(object.outputType);
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                        message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                    }
                    if (object.clientStreaming != null)
                        message.clientStreaming = Boolean(object.clientStreaming);
                    if (object.serverStreaming != null)
                        message.serverStreaming = Boolean(object.serverStreaming);
                    return message;
                };
    
                /**
                 * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @static
                 * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MethodDescriptorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.inputType = "";
                        object.outputType = "";
                        object.options = null;
                        object.clientStreaming = false;
                        object.serverStreaming = false;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        object.inputType = message.inputType;
                    if (message.outputType != null && message.hasOwnProperty("outputType"))
                        object.outputType = message.outputType;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                    if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                        object.clientStreaming = message.clientStreaming;
                    if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                        object.serverStreaming = message.serverStreaming;
                    return object;
                };
    
                /**
                 * Converts this MethodDescriptorProto to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MethodDescriptorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MethodDescriptorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return MethodDescriptorProto;
            })();
    
            protobuf.FileOptions = (function() {
    
                /**
                 * Properties of a FileOptions.
                 * @memberof google.protobuf
                 * @interface IFileOptions
                 * @property {string|null} [javaPackage] FileOptions javaPackage
                 * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
                 * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
                 * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
                 * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
                 * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
                 * @property {string|null} [goPackage] FileOptions goPackage
                 * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
                 * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
                 * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
                 * @property {boolean|null} [phpGenericServices] FileOptions phpGenericServices
                 * @property {boolean|null} [deprecated] FileOptions deprecated
                 * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
                 * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
                 * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
                 * @property {string|null} [swiftPrefix] FileOptions swiftPrefix
                 * @property {string|null} [phpClassPrefix] FileOptions phpClassPrefix
                 * @property {string|null} [phpNamespace] FileOptions phpNamespace
                 * @property {string|null} [phpMetadataNamespace] FileOptions phpMetadataNamespace
                 * @property {string|null} [rubyPackage] FileOptions rubyPackage
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new FileOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a FileOptions.
                 * @implements IFileOptions
                 * @constructor
                 * @param {google.protobuf.IFileOptions=} [properties] Properties to set
                 */
                function FileOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FileOptions javaPackage.
                 * @member {string} javaPackage
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaPackage = "";
    
                /**
                 * FileOptions javaOuterClassname.
                 * @member {string} javaOuterClassname
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaOuterClassname = "";
    
                /**
                 * FileOptions javaMultipleFiles.
                 * @member {boolean} javaMultipleFiles
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaMultipleFiles = false;
    
                /**
                 * FileOptions javaGenerateEqualsAndHash.
                 * @member {boolean} javaGenerateEqualsAndHash
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaGenerateEqualsAndHash = false;
    
                /**
                 * FileOptions javaStringCheckUtf8.
                 * @member {boolean} javaStringCheckUtf8
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaStringCheckUtf8 = false;
    
                /**
                 * FileOptions optimizeFor.
                 * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.optimizeFor = 1;
    
                /**
                 * FileOptions goPackage.
                 * @member {string} goPackage
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.goPackage = "";
    
                /**
                 * FileOptions ccGenericServices.
                 * @member {boolean} ccGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.ccGenericServices = false;
    
                /**
                 * FileOptions javaGenericServices.
                 * @member {boolean} javaGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.javaGenericServices = false;
    
                /**
                 * FileOptions pyGenericServices.
                 * @member {boolean} pyGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.pyGenericServices = false;
    
                /**
                 * FileOptions phpGenericServices.
                 * @member {boolean} phpGenericServices
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.phpGenericServices = false;
    
                /**
                 * FileOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.deprecated = false;
    
                /**
                 * FileOptions ccEnableArenas.
                 * @member {boolean} ccEnableArenas
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.ccEnableArenas = false;
    
                /**
                 * FileOptions objcClassPrefix.
                 * @member {string} objcClassPrefix
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.objcClassPrefix = "";
    
                /**
                 * FileOptions csharpNamespace.
                 * @member {string} csharpNamespace
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.csharpNamespace = "";
    
                /**
                 * FileOptions swiftPrefix.
                 * @member {string} swiftPrefix
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.swiftPrefix = "";
    
                /**
                 * FileOptions phpClassPrefix.
                 * @member {string} phpClassPrefix
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.phpClassPrefix = "";
    
                /**
                 * FileOptions phpNamespace.
                 * @member {string} phpNamespace
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.phpNamespace = "";
    
                /**
                 * FileOptions phpMetadataNamespace.
                 * @member {string} phpMetadataNamespace
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.phpMetadataNamespace = "";
    
                /**
                 * FileOptions rubyPackage.
                 * @member {string} rubyPackage
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.rubyPackage = "";
    
                /**
                 * FileOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 */
                FileOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new FileOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions=} [properties] Properties to set
                 * @returns {google.protobuf.FileOptions} FileOptions instance
                 */
                FileOptions.create = function create(properties) {
                    return new FileOptions(properties);
                };
    
                /**
                 * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.javaPackage != null && Object.hasOwnProperty.call(message, "javaPackage"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.javaPackage);
                    if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, "javaOuterClassname"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.javaOuterClassname);
                    if (message.optimizeFor != null && Object.hasOwnProperty.call(message, "optimizeFor"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.optimizeFor);
                    if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, "javaMultipleFiles"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.javaMultipleFiles);
                    if (message.goPackage != null && Object.hasOwnProperty.call(message, "goPackage"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.goPackage);
                    if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, "ccGenericServices"))
                        writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ccGenericServices);
                    if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, "javaGenericServices"))
                        writer.uint32(/* id 17, wireType 0 =*/136).bool(message.javaGenericServices);
                    if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, "pyGenericServices"))
                        writer.uint32(/* id 18, wireType 0 =*/144).bool(message.pyGenericServices);
                    if (message.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(message, "javaGenerateEqualsAndHash"))
                        writer.uint32(/* id 20, wireType 0 =*/160).bool(message.javaGenerateEqualsAndHash);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 23, wireType 0 =*/184).bool(message.deprecated);
                    if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, "javaStringCheckUtf8"))
                        writer.uint32(/* id 27, wireType 0 =*/216).bool(message.javaStringCheckUtf8);
                    if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, "ccEnableArenas"))
                        writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ccEnableArenas);
                    if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, "objcClassPrefix"))
                        writer.uint32(/* id 36, wireType 2 =*/290).string(message.objcClassPrefix);
                    if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, "csharpNamespace"))
                        writer.uint32(/* id 37, wireType 2 =*/298).string(message.csharpNamespace);
                    if (message.swiftPrefix != null && Object.hasOwnProperty.call(message, "swiftPrefix"))
                        writer.uint32(/* id 39, wireType 2 =*/314).string(message.swiftPrefix);
                    if (message.phpClassPrefix != null && Object.hasOwnProperty.call(message, "phpClassPrefix"))
                        writer.uint32(/* id 40, wireType 2 =*/322).string(message.phpClassPrefix);
                    if (message.phpNamespace != null && Object.hasOwnProperty.call(message, "phpNamespace"))
                        writer.uint32(/* id 41, wireType 2 =*/330).string(message.phpNamespace);
                    if (message.phpGenericServices != null && Object.hasOwnProperty.call(message, "phpGenericServices"))
                        writer.uint32(/* id 42, wireType 0 =*/336).bool(message.phpGenericServices);
                    if (message.phpMetadataNamespace != null && Object.hasOwnProperty.call(message, "phpMetadataNamespace"))
                        writer.uint32(/* id 44, wireType 2 =*/354).string(message.phpMetadataNamespace);
                    if (message.rubyPackage != null && Object.hasOwnProperty.call(message, "rubyPackage"))
                        writer.uint32(/* id 45, wireType 2 =*/362).string(message.rubyPackage);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FileOptions message, length delimited. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FileOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FileOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FileOptions} FileOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.javaPackage = reader.string();
                            break;
                        case 8:
                            message.javaOuterClassname = reader.string();
                            break;
                        case 10:
                            message.javaMultipleFiles = reader.bool();
                            break;
                        case 20:
                            message.javaGenerateEqualsAndHash = reader.bool();
                            break;
                        case 27:
                            message.javaStringCheckUtf8 = reader.bool();
                            break;
                        case 9:
                            message.optimizeFor = reader.int32();
                            break;
                        case 11:
                            message.goPackage = reader.string();
                            break;
                        case 16:
                            message.ccGenericServices = reader.bool();
                            break;
                        case 17:
                            message.javaGenericServices = reader.bool();
                            break;
                        case 18:
                            message.pyGenericServices = reader.bool();
                            break;
                        case 42:
                            message.phpGenericServices = reader.bool();
                            break;
                        case 23:
                            message.deprecated = reader.bool();
                            break;
                        case 31:
                            message.ccEnableArenas = reader.bool();
                            break;
                        case 36:
                            message.objcClassPrefix = reader.string();
                            break;
                        case 37:
                            message.csharpNamespace = reader.string();
                            break;
                        case 39:
                            message.swiftPrefix = reader.string();
                            break;
                        case 40:
                            message.phpClassPrefix = reader.string();
                            break;
                        case 41:
                            message.phpNamespace = reader.string();
                            break;
                        case 44:
                            message.phpMetadataNamespace = reader.string();
                            break;
                        case 45:
                            message.rubyPackage = reader.string();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FileOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FileOptions} FileOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FileOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FileOptions message.
                 * @function verify
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FileOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                        if (!$util.isString(message.javaPackage))
                            return "javaPackage: string expected";
                    if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                        if (!$util.isString(message.javaOuterClassname))
                            return "javaOuterClassname: string expected";
                    if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                        if (typeof message.javaMultipleFiles !== "boolean")
                            return "javaMultipleFiles: boolean expected";
                    if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                        if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                            return "javaGenerateEqualsAndHash: boolean expected";
                    if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                        if (typeof message.javaStringCheckUtf8 !== "boolean")
                            return "javaStringCheckUtf8: boolean expected";
                    if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                        switch (message.optimizeFor) {
                        default:
                            return "optimizeFor: enum value expected";
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                        if (!$util.isString(message.goPackage))
                            return "goPackage: string expected";
                    if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                        if (typeof message.ccGenericServices !== "boolean")
                            return "ccGenericServices: boolean expected";
                    if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                        if (typeof message.javaGenericServices !== "boolean")
                            return "javaGenericServices: boolean expected";
                    if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                        if (typeof message.pyGenericServices !== "boolean")
                            return "pyGenericServices: boolean expected";
                    if (message.phpGenericServices != null && message.hasOwnProperty("phpGenericServices"))
                        if (typeof message.phpGenericServices !== "boolean")
                            return "phpGenericServices: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                        if (typeof message.ccEnableArenas !== "boolean")
                            return "ccEnableArenas: boolean expected";
                    if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                        if (!$util.isString(message.objcClassPrefix))
                            return "objcClassPrefix: string expected";
                    if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                        if (!$util.isString(message.csharpNamespace))
                            return "csharpNamespace: string expected";
                    if (message.swiftPrefix != null && message.hasOwnProperty("swiftPrefix"))
                        if (!$util.isString(message.swiftPrefix))
                            return "swiftPrefix: string expected";
                    if (message.phpClassPrefix != null && message.hasOwnProperty("phpClassPrefix"))
                        if (!$util.isString(message.phpClassPrefix))
                            return "phpClassPrefix: string expected";
                    if (message.phpNamespace != null && message.hasOwnProperty("phpNamespace"))
                        if (!$util.isString(message.phpNamespace))
                            return "phpNamespace: string expected";
                    if (message.phpMetadataNamespace != null && message.hasOwnProperty("phpMetadataNamespace"))
                        if (!$util.isString(message.phpMetadataNamespace))
                            return "phpMetadataNamespace: string expected";
                    if (message.rubyPackage != null && message.hasOwnProperty("rubyPackage"))
                        if (!$util.isString(message.rubyPackage))
                            return "rubyPackage: string expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FileOptions} FileOptions
                 */
                FileOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FileOptions)
                        return object;
                    var message = new $root.google.protobuf.FileOptions();
                    if (object.javaPackage != null)
                        message.javaPackage = String(object.javaPackage);
                    if (object.javaOuterClassname != null)
                        message.javaOuterClassname = String(object.javaOuterClassname);
                    if (object.javaMultipleFiles != null)
                        message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                    if (object.javaGenerateEqualsAndHash != null)
                        message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                    if (object.javaStringCheckUtf8 != null)
                        message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                    switch (object.optimizeFor) {
                    case "SPEED":
                    case 1:
                        message.optimizeFor = 1;
                        break;
                    case "CODE_SIZE":
                    case 2:
                        message.optimizeFor = 2;
                        break;
                    case "LITE_RUNTIME":
                    case 3:
                        message.optimizeFor = 3;
                        break;
                    }
                    if (object.goPackage != null)
                        message.goPackage = String(object.goPackage);
                    if (object.ccGenericServices != null)
                        message.ccGenericServices = Boolean(object.ccGenericServices);
                    if (object.javaGenericServices != null)
                        message.javaGenericServices = Boolean(object.javaGenericServices);
                    if (object.pyGenericServices != null)
                        message.pyGenericServices = Boolean(object.pyGenericServices);
                    if (object.phpGenericServices != null)
                        message.phpGenericServices = Boolean(object.phpGenericServices);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.ccEnableArenas != null)
                        message.ccEnableArenas = Boolean(object.ccEnableArenas);
                    if (object.objcClassPrefix != null)
                        message.objcClassPrefix = String(object.objcClassPrefix);
                    if (object.csharpNamespace != null)
                        message.csharpNamespace = String(object.csharpNamespace);
                    if (object.swiftPrefix != null)
                        message.swiftPrefix = String(object.swiftPrefix);
                    if (object.phpClassPrefix != null)
                        message.phpClassPrefix = String(object.phpClassPrefix);
                    if (object.phpNamespace != null)
                        message.phpNamespace = String(object.phpNamespace);
                    if (object.phpMetadataNamespace != null)
                        message.phpMetadataNamespace = String(object.phpMetadataNamespace);
                    if (object.rubyPackage != null)
                        message.rubyPackage = String(object.rubyPackage);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FileOptions
                 * @static
                 * @param {google.protobuf.FileOptions} message FileOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FileOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.javaPackage = "";
                        object.javaOuterClassname = "";
                        object.optimizeFor = options.enums === String ? "SPEED" : 1;
                        object.javaMultipleFiles = false;
                        object.goPackage = "";
                        object.ccGenericServices = false;
                        object.javaGenericServices = false;
                        object.pyGenericServices = false;
                        object.javaGenerateEqualsAndHash = false;
                        object.deprecated = false;
                        object.javaStringCheckUtf8 = false;
                        object.ccEnableArenas = false;
                        object.objcClassPrefix = "";
                        object.csharpNamespace = "";
                        object.swiftPrefix = "";
                        object.phpClassPrefix = "";
                        object.phpNamespace = "";
                        object.phpGenericServices = false;
                        object.phpMetadataNamespace = "";
                        object.rubyPackage = "";
                    }
                    if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                        object.javaPackage = message.javaPackage;
                    if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                        object.javaOuterClassname = message.javaOuterClassname;
                    if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                        object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                    if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                        object.javaMultipleFiles = message.javaMultipleFiles;
                    if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                        object.goPackage = message.goPackage;
                    if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                        object.ccGenericServices = message.ccGenericServices;
                    if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                        object.javaGenericServices = message.javaGenericServices;
                    if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                        object.pyGenericServices = message.pyGenericServices;
                    if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                        object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                        object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                    if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                        object.ccEnableArenas = message.ccEnableArenas;
                    if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                        object.objcClassPrefix = message.objcClassPrefix;
                    if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                        object.csharpNamespace = message.csharpNamespace;
                    if (message.swiftPrefix != null && message.hasOwnProperty("swiftPrefix"))
                        object.swiftPrefix = message.swiftPrefix;
                    if (message.phpClassPrefix != null && message.hasOwnProperty("phpClassPrefix"))
                        object.phpClassPrefix = message.phpClassPrefix;
                    if (message.phpNamespace != null && message.hasOwnProperty("phpNamespace"))
                        object.phpNamespace = message.phpNamespace;
                    if (message.phpGenericServices != null && message.hasOwnProperty("phpGenericServices"))
                        object.phpGenericServices = message.phpGenericServices;
                    if (message.phpMetadataNamespace != null && message.hasOwnProperty("phpMetadataNamespace"))
                        object.phpMetadataNamespace = message.phpMetadataNamespace;
                    if (message.rubyPackage != null && message.hasOwnProperty("rubyPackage"))
                        object.rubyPackage = message.rubyPackage;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this FileOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FileOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FileOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * OptimizeMode enum.
                 * @name google.protobuf.FileOptions.OptimizeMode
                 * @enum {number}
                 * @property {number} SPEED=1 SPEED value
                 * @property {number} CODE_SIZE=2 CODE_SIZE value
                 * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
                 */
                FileOptions.OptimizeMode = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[1] = "SPEED"] = 1;
                    values[valuesById[2] = "CODE_SIZE"] = 2;
                    values[valuesById[3] = "LITE_RUNTIME"] = 3;
                    return values;
                })();
    
                return FileOptions;
            })();
    
            protobuf.MessageOptions = (function() {
    
                /**
                 * Properties of a MessageOptions.
                 * @memberof google.protobuf
                 * @interface IMessageOptions
                 * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
                 * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
                 * @property {boolean|null} [deprecated] MessageOptions deprecated
                 * @property {boolean|null} [mapEntry] MessageOptions mapEntry
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new MessageOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a MessageOptions.
                 * @implements IMessageOptions
                 * @constructor
                 * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
                 */
                function MessageOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MessageOptions messageSetWireFormat.
                 * @member {boolean} messageSetWireFormat
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.messageSetWireFormat = false;
    
                /**
                 * MessageOptions noStandardDescriptorAccessor.
                 * @member {boolean} noStandardDescriptorAccessor
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.noStandardDescriptorAccessor = false;
    
                /**
                 * MessageOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.deprecated = false;
    
                /**
                 * MessageOptions mapEntry.
                 * @member {boolean} mapEntry
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.mapEntry = false;
    
                /**
                 * MessageOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 */
                MessageOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new MessageOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
                 * @returns {google.protobuf.MessageOptions} MessageOptions instance
                 */
                MessageOptions.create = function create(properties) {
                    return new MessageOptions(properties);
                };
    
                /**
                 * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, "messageSetWireFormat"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messageSetWireFormat);
                    if (message.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(message, "noStandardDescriptorAccessor"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noStandardDescriptorAccessor);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.mapEntry != null && Object.hasOwnProperty.call(message, "mapEntry"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mapEntry);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MessageOptions message, length delimited. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MessageOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MessageOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.messageSetWireFormat = reader.bool();
                            break;
                        case 2:
                            message.noStandardDescriptorAccessor = reader.bool();
                            break;
                        case 3:
                            message.deprecated = reader.bool();
                            break;
                        case 7:
                            message.mapEntry = reader.bool();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MessageOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MessageOptions message.
                 * @function verify
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MessageOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                        if (typeof message.messageSetWireFormat !== "boolean")
                            return "messageSetWireFormat: boolean expected";
                    if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                        if (typeof message.noStandardDescriptorAccessor !== "boolean")
                            return "noStandardDescriptorAccessor: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                        if (typeof message.mapEntry !== "boolean")
                            return "mapEntry: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MessageOptions} MessageOptions
                 */
                MessageOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MessageOptions)
                        return object;
                    var message = new $root.google.protobuf.MessageOptions();
                    if (object.messageSetWireFormat != null)
                        message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                    if (object.noStandardDescriptorAccessor != null)
                        message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.mapEntry != null)
                        message.mapEntry = Boolean(object.mapEntry);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MessageOptions
                 * @static
                 * @param {google.protobuf.MessageOptions} message MessageOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MessageOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.messageSetWireFormat = false;
                        object.noStandardDescriptorAccessor = false;
                        object.deprecated = false;
                        object.mapEntry = false;
                    }
                    if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                        object.messageSetWireFormat = message.messageSetWireFormat;
                    if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                        object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                        object.mapEntry = message.mapEntry;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this MessageOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MessageOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MessageOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return MessageOptions;
            })();
    
            protobuf.FieldOptions = (function() {
    
                /**
                 * Properties of a FieldOptions.
                 * @memberof google.protobuf
                 * @interface IFieldOptions
                 * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
                 * @property {boolean|null} [packed] FieldOptions packed
                 * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
                 * @property {boolean|null} [lazy] FieldOptions lazy
                 * @property {boolean|null} [deprecated] FieldOptions deprecated
                 * @property {boolean|null} [weak] FieldOptions weak
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new FieldOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a FieldOptions.
                 * @implements IFieldOptions
                 * @constructor
                 * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
                 */
                function FieldOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldOptions ctype.
                 * @member {google.protobuf.FieldOptions.CType} ctype
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.ctype = 0;
    
                /**
                 * FieldOptions packed.
                 * @member {boolean} packed
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.packed = false;
    
                /**
                 * FieldOptions jstype.
                 * @member {google.protobuf.FieldOptions.JSType} jstype
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.jstype = 0;
    
                /**
                 * FieldOptions lazy.
                 * @member {boolean} lazy
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.lazy = false;
    
                /**
                 * FieldOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.deprecated = false;
    
                /**
                 * FieldOptions weak.
                 * @member {boolean} weak
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.weak = false;
    
                /**
                 * FieldOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 */
                FieldOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new FieldOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
                 * @returns {google.protobuf.FieldOptions} FieldOptions instance
                 */
                FieldOptions.create = function create(properties) {
                    return new FieldOptions(properties);
                };
    
                /**
                 * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ctype != null && Object.hasOwnProperty.call(message, "ctype"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ctype);
                    if (message.packed != null && Object.hasOwnProperty.call(message, "packed"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.packed);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.lazy != null && Object.hasOwnProperty.call(message, "lazy"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.lazy);
                    if (message.jstype != null && Object.hasOwnProperty.call(message, "jstype"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.jstype);
                    if (message.weak != null && Object.hasOwnProperty.call(message, "weak"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.weak);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldOptions message, length delimited. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ctype = reader.int32();
                            break;
                        case 2:
                            message.packed = reader.bool();
                            break;
                        case 6:
                            message.jstype = reader.int32();
                            break;
                        case 5:
                            message.lazy = reader.bool();
                            break;
                        case 3:
                            message.deprecated = reader.bool();
                            break;
                        case 10:
                            message.weak = reader.bool();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldOptions message.
                 * @function verify
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ctype != null && message.hasOwnProperty("ctype"))
                        switch (message.ctype) {
                        default:
                            return "ctype: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.packed != null && message.hasOwnProperty("packed"))
                        if (typeof message.packed !== "boolean")
                            return "packed: boolean expected";
                    if (message.jstype != null && message.hasOwnProperty("jstype"))
                        switch (message.jstype) {
                        default:
                            return "jstype: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.lazy != null && message.hasOwnProperty("lazy"))
                        if (typeof message.lazy !== "boolean")
                            return "lazy: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.weak != null && message.hasOwnProperty("weak"))
                        if (typeof message.weak !== "boolean")
                            return "weak: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FieldOptions} FieldOptions
                 */
                FieldOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FieldOptions)
                        return object;
                    var message = new $root.google.protobuf.FieldOptions();
                    switch (object.ctype) {
                    case "STRING":
                    case 0:
                        message.ctype = 0;
                        break;
                    case "CORD":
                    case 1:
                        message.ctype = 1;
                        break;
                    case "STRING_PIECE":
                    case 2:
                        message.ctype = 2;
                        break;
                    }
                    if (object.packed != null)
                        message.packed = Boolean(object.packed);
                    switch (object.jstype) {
                    case "JS_NORMAL":
                    case 0:
                        message.jstype = 0;
                        break;
                    case "JS_STRING":
                    case 1:
                        message.jstype = 1;
                        break;
                    case "JS_NUMBER":
                    case 2:
                        message.jstype = 2;
                        break;
                    }
                    if (object.lazy != null)
                        message.lazy = Boolean(object.lazy);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.weak != null)
                        message.weak = Boolean(object.weak);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FieldOptions
                 * @static
                 * @param {google.protobuf.FieldOptions} message FieldOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.ctype = options.enums === String ? "STRING" : 0;
                        object.packed = false;
                        object.deprecated = false;
                        object.lazy = false;
                        object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                        object.weak = false;
                    }
                    if (message.ctype != null && message.hasOwnProperty("ctype"))
                        object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                    if (message.packed != null && message.hasOwnProperty("packed"))
                        object.packed = message.packed;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.lazy != null && message.hasOwnProperty("lazy"))
                        object.lazy = message.lazy;
                    if (message.jstype != null && message.hasOwnProperty("jstype"))
                        object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                    if (message.weak != null && message.hasOwnProperty("weak"))
                        object.weak = message.weak;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this FieldOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FieldOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * CType enum.
                 * @name google.protobuf.FieldOptions.CType
                 * @enum {number}
                 * @property {number} STRING=0 STRING value
                 * @property {number} CORD=1 CORD value
                 * @property {number} STRING_PIECE=2 STRING_PIECE value
                 */
                FieldOptions.CType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STRING"] = 0;
                    values[valuesById[1] = "CORD"] = 1;
                    values[valuesById[2] = "STRING_PIECE"] = 2;
                    return values;
                })();
    
                /**
                 * JSType enum.
                 * @name google.protobuf.FieldOptions.JSType
                 * @enum {number}
                 * @property {number} JS_NORMAL=0 JS_NORMAL value
                 * @property {number} JS_STRING=1 JS_STRING value
                 * @property {number} JS_NUMBER=2 JS_NUMBER value
                 */
                FieldOptions.JSType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "JS_NORMAL"] = 0;
                    values[valuesById[1] = "JS_STRING"] = 1;
                    values[valuesById[2] = "JS_NUMBER"] = 2;
                    return values;
                })();
    
                return FieldOptions;
            })();
    
            protobuf.OneofOptions = (function() {
    
                /**
                 * Properties of an OneofOptions.
                 * @memberof google.protobuf
                 * @interface IOneofOptions
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new OneofOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an OneofOptions.
                 * @implements IOneofOptions
                 * @constructor
                 * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
                 */
                function OneofOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * OneofOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.OneofOptions
                 * @instance
                 */
                OneofOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new OneofOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
                 * @returns {google.protobuf.OneofOptions} OneofOptions instance
                 */
                OneofOptions.create = function create(properties) {
                    return new OneofOptions(properties);
                };
    
                /**
                 * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified OneofOptions message, length delimited. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OneofOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an OneofOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an OneofOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OneofOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an OneofOptions message.
                 * @function verify
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OneofOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.OneofOptions} OneofOptions
                 */
                OneofOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.OneofOptions)
                        return object;
                    var message = new $root.google.protobuf.OneofOptions();
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.OneofOptions
                 * @static
                 * @param {google.protobuf.OneofOptions} message OneofOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OneofOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this OneofOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.OneofOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OneofOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return OneofOptions;
            })();
    
            protobuf.EnumOptions = (function() {
    
                /**
                 * Properties of an EnumOptions.
                 * @memberof google.protobuf
                 * @interface IEnumOptions
                 * @property {boolean|null} [allowAlias] EnumOptions allowAlias
                 * @property {boolean|null} [deprecated] EnumOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new EnumOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumOptions.
                 * @implements IEnumOptions
                 * @constructor
                 * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
                 */
                function EnumOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumOptions allowAlias.
                 * @member {boolean} allowAlias
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.allowAlias = false;
    
                /**
                 * EnumOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.deprecated = false;
    
                /**
                 * EnumOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 */
                EnumOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new EnumOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
                 * @returns {google.protobuf.EnumOptions} EnumOptions instance
                 */
                EnumOptions.create = function create(properties) {
                    return new EnumOptions(properties);
                };
    
                /**
                 * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.allowAlias != null && Object.hasOwnProperty.call(message, "allowAlias"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowAlias);
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2:
                            message.allowAlias = reader.bool();
                            break;
                        case 3:
                            message.deprecated = reader.bool();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumOptions message.
                 * @function verify
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                        if (typeof message.allowAlias !== "boolean")
                            return "allowAlias: boolean expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumOptions} EnumOptions
                 */
                EnumOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumOptions)
                        return object;
                    var message = new $root.google.protobuf.EnumOptions();
                    if (object.allowAlias != null)
                        message.allowAlias = Boolean(object.allowAlias);
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumOptions
                 * @static
                 * @param {google.protobuf.EnumOptions} message EnumOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.allowAlias = false;
                        object.deprecated = false;
                    }
                    if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                        object.allowAlias = message.allowAlias;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this EnumOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return EnumOptions;
            })();
    
            protobuf.EnumValueOptions = (function() {
    
                /**
                 * Properties of an EnumValueOptions.
                 * @memberof google.protobuf
                 * @interface IEnumValueOptions
                 * @property {boolean|null} [deprecated] EnumValueOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
                 * @property {string|null} [".palexy.streaming.v1.key"] EnumValueOptions .palexy.streaming.v1.key
                 */
    
                /**
                 * Constructs a new EnumValueOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents an EnumValueOptions.
                 * @implements IEnumValueOptions
                 * @constructor
                 * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
                 */
                function EnumValueOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * EnumValueOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype.deprecated = false;
    
                /**
                 * EnumValueOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * EnumValueOptions .palexy.streaming.v1.key.
                 * @member {string} .palexy.streaming.v1.key
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 */
                EnumValueOptions.prototype[".palexy.streaming.v1.key"] = "";
    
                /**
                 * Creates a new EnumValueOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
                 */
                EnumValueOptions.create = function create(properties) {
                    return new EnumValueOptions(properties);
                };
    
                /**
                 * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    if (message[".palexy.streaming.v1.key"] != null && Object.hasOwnProperty.call(message, ".palexy.streaming.v1.key"))
                        writer.uint32(/* id 1001, wireType 2 =*/8010).string(message[".palexy.streaming.v1.key"]);
                    return writer;
                };
    
                /**
                 * Encodes the specified EnumValueOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumValueOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an EnumValueOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.deprecated = reader.bool();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        case 1001:
                            message[".palexy.streaming.v1.key"] = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an EnumValueOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumValueOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an EnumValueOptions message.
                 * @function verify
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumValueOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    if (message[".palexy.streaming.v1.key"] != null && message.hasOwnProperty(".palexy.streaming.v1.key"))
                        if (!$util.isString(message[".palexy.streaming.v1.key"]))
                            return ".palexy.streaming.v1.key: string expected";
                    return null;
                };
    
                /**
                 * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
                 */
                EnumValueOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumValueOptions)
                        return object;
                    var message = new $root.google.protobuf.EnumValueOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    if (object[".palexy.streaming.v1.key"] != null)
                        message[".palexy.streaming.v1.key"] = String(object[".palexy.streaming.v1.key"]);
                    return message;
                };
    
                /**
                 * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumValueOptions
                 * @static
                 * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumValueOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.deprecated = false;
                        object[".palexy.streaming.v1.key"] = "";
                    }
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    if (message[".palexy.streaming.v1.key"] != null && message.hasOwnProperty(".palexy.streaming.v1.key"))
                        object[".palexy.streaming.v1.key"] = message[".palexy.streaming.v1.key"];
                    return object;
                };
    
                /**
                 * Converts this EnumValueOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumValueOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumValueOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return EnumValueOptions;
            })();
    
            protobuf.ServiceOptions = (function() {
    
                /**
                 * Properties of a ServiceOptions.
                 * @memberof google.protobuf
                 * @interface IServiceOptions
                 * @property {boolean|null} [deprecated] ServiceOptions deprecated
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
                 */
    
                /**
                 * Constructs a new ServiceOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a ServiceOptions.
                 * @implements IServiceOptions
                 * @constructor
                 * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
                 */
                function ServiceOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ServiceOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 */
                ServiceOptions.prototype.deprecated = false;
    
                /**
                 * ServiceOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 */
                ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * Creates a new ServiceOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
                 */
                ServiceOptions.create = function create(properties) {
                    return new ServiceOptions(properties);
                };
    
                /**
                 * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified ServiceOptions message, length delimited. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a ServiceOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 33:
                            message.deprecated = reader.bool();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a ServiceOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a ServiceOptions message.
                 * @function verify
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.ServiceOptions} ServiceOptions
                 */
                ServiceOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.ServiceOptions)
                        return object;
                    var message = new $root.google.protobuf.ServiceOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.ServiceOptions
                 * @static
                 * @param {google.protobuf.ServiceOptions} message ServiceOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults)
                        object.deprecated = false;
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this ServiceOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.ServiceOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return ServiceOptions;
            })();
    
            protobuf.MethodOptions = (function() {
    
                /**
                 * Properties of a MethodOptions.
                 * @memberof google.protobuf
                 * @interface IMethodOptions
                 * @property {boolean|null} [deprecated] MethodOptions deprecated
                 * @property {google.protobuf.MethodOptions.IdempotencyLevel|null} [idempotencyLevel] MethodOptions idempotencyLevel
                 * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
                 * @property {google.api.IHttpRule|null} [".google.api.http"] MethodOptions .google.api.http
                 */
    
                /**
                 * Constructs a new MethodOptions.
                 * @memberof google.protobuf
                 * @classdesc Represents a MethodOptions.
                 * @implements IMethodOptions
                 * @constructor
                 * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
                 */
                function MethodOptions(properties) {
                    this.uninterpretedOption = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MethodOptions deprecated.
                 * @member {boolean} deprecated
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 */
                MethodOptions.prototype.deprecated = false;
    
                /**
                 * MethodOptions idempotencyLevel.
                 * @member {google.protobuf.MethodOptions.IdempotencyLevel} idempotencyLevel
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 */
                MethodOptions.prototype.idempotencyLevel = 0;
    
                /**
                 * MethodOptions uninterpretedOption.
                 * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 */
                MethodOptions.prototype.uninterpretedOption = $util.emptyArray;
    
                /**
                 * MethodOptions .google.api.http.
                 * @member {google.api.IHttpRule|null|undefined} .google.api.http
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 */
                MethodOptions.prototype[".google.api.http"] = null;
    
                /**
                 * Creates a new MethodOptions instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
                 * @returns {google.protobuf.MethodOptions} MethodOptions instance
                 */
                MethodOptions.create = function create(properties) {
                    return new MethodOptions(properties);
                };
    
                /**
                 * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodOptions.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                        writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                    if (message.idempotencyLevel != null && Object.hasOwnProperty.call(message, "idempotencyLevel"))
                        writer.uint32(/* id 34, wireType 0 =*/272).int32(message.idempotencyLevel);
                    if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                        for (var i = 0; i < message.uninterpretedOption.length; ++i)
                            $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                    if (message[".google.api.http"] != null && Object.hasOwnProperty.call(message, ".google.api.http"))
                        $root.google.api.HttpRule.encode(message[".google.api.http"], writer.uint32(/* id 72295728, wireType 2 =*/578365826).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MethodOptions message, length delimited. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MethodOptions.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MethodOptions message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodOptions.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodOptions();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 33:
                            message.deprecated = reader.bool();
                            break;
                        case 34:
                            message.idempotencyLevel = reader.int32();
                            break;
                        case 999:
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        case 72295728:
                            message[".google.api.http"] = $root.google.api.HttpRule.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MethodOptions message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MethodOptions.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MethodOptions message.
                 * @function verify
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MethodOptions.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        if (typeof message.deprecated !== "boolean")
                            return "deprecated: boolean expected";
                    if (message.idempotencyLevel != null && message.hasOwnProperty("idempotencyLevel"))
                        switch (message.idempotencyLevel) {
                        default:
                            return "idempotencyLevel: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                        if (!Array.isArray(message.uninterpretedOption))
                            return "uninterpretedOption: array expected";
                        for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                            if (error)
                                return "uninterpretedOption." + error;
                        }
                    }
                    if (message[".google.api.http"] != null && message.hasOwnProperty(".google.api.http")) {
                        var error = $root.google.api.HttpRule.verify(message[".google.api.http"]);
                        if (error)
                            return ".google.api.http." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.MethodOptions} MethodOptions
                 */
                MethodOptions.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.MethodOptions)
                        return object;
                    var message = new $root.google.protobuf.MethodOptions();
                    if (object.deprecated != null)
                        message.deprecated = Boolean(object.deprecated);
                    switch (object.idempotencyLevel) {
                    case "IDEMPOTENCY_UNKNOWN":
                    case 0:
                        message.idempotencyLevel = 0;
                        break;
                    case "NO_SIDE_EFFECTS":
                    case 1:
                        message.idempotencyLevel = 1;
                        break;
                    case "IDEMPOTENT":
                    case 2:
                        message.idempotencyLevel = 2;
                        break;
                    }
                    if (object.uninterpretedOption) {
                        if (!Array.isArray(object.uninterpretedOption))
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                        message.uninterpretedOption = [];
                        for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                            if (typeof object.uninterpretedOption[i] !== "object")
                                throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                        }
                    }
                    if (object[".google.api.http"] != null) {
                        if (typeof object[".google.api.http"] !== "object")
                            throw TypeError(".google.protobuf.MethodOptions..google.api.http: object expected");
                        message[".google.api.http"] = $root.google.api.HttpRule.fromObject(object[".google.api.http"]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.MethodOptions
                 * @static
                 * @param {google.protobuf.MethodOptions} message MethodOptions
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MethodOptions.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.uninterpretedOption = [];
                    if (options.defaults) {
                        object.deprecated = false;
                        object.idempotencyLevel = options.enums === String ? "IDEMPOTENCY_UNKNOWN" : 0;
                        object[".google.api.http"] = null;
                    }
                    if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                        object.deprecated = message.deprecated;
                    if (message.idempotencyLevel != null && message.hasOwnProperty("idempotencyLevel"))
                        object.idempotencyLevel = options.enums === String ? $root.google.protobuf.MethodOptions.IdempotencyLevel[message.idempotencyLevel] : message.idempotencyLevel;
                    if (message.uninterpretedOption && message.uninterpretedOption.length) {
                        object.uninterpretedOption = [];
                        for (var j = 0; j < message.uninterpretedOption.length; ++j)
                            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                    }
                    if (message[".google.api.http"] != null && message.hasOwnProperty(".google.api.http"))
                        object[".google.api.http"] = $root.google.api.HttpRule.toObject(message[".google.api.http"], options);
                    return object;
                };
    
                /**
                 * Converts this MethodOptions to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.MethodOptions
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MethodOptions.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * IdempotencyLevel enum.
                 * @name google.protobuf.MethodOptions.IdempotencyLevel
                 * @enum {number}
                 * @property {number} IDEMPOTENCY_UNKNOWN=0 IDEMPOTENCY_UNKNOWN value
                 * @property {number} NO_SIDE_EFFECTS=1 NO_SIDE_EFFECTS value
                 * @property {number} IDEMPOTENT=2 IDEMPOTENT value
                 */
                MethodOptions.IdempotencyLevel = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "IDEMPOTENCY_UNKNOWN"] = 0;
                    values[valuesById[1] = "NO_SIDE_EFFECTS"] = 1;
                    values[valuesById[2] = "IDEMPOTENT"] = 2;
                    return values;
                })();
    
                return MethodOptions;
            })();
    
            protobuf.UninterpretedOption = (function() {
    
                /**
                 * Properties of an UninterpretedOption.
                 * @memberof google.protobuf
                 * @interface IUninterpretedOption
                 * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
                 * @property {string|null} [identifierValue] UninterpretedOption identifierValue
                 * @property {number|null} [positiveIntValue] UninterpretedOption positiveIntValue
                 * @property {number|null} [negativeIntValue] UninterpretedOption negativeIntValue
                 * @property {number|null} [doubleValue] UninterpretedOption doubleValue
                 * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
                 * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
                 */
    
                /**
                 * Constructs a new UninterpretedOption.
                 * @memberof google.protobuf
                 * @classdesc Represents an UninterpretedOption.
                 * @implements IUninterpretedOption
                 * @constructor
                 * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
                 */
                function UninterpretedOption(properties) {
                    this.name = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UninterpretedOption name.
                 * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.name = $util.emptyArray;
    
                /**
                 * UninterpretedOption identifierValue.
                 * @member {string} identifierValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.identifierValue = "";
    
                /**
                 * UninterpretedOption positiveIntValue.
                 * @member {number} positiveIntValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
                /**
                 * UninterpretedOption negativeIntValue.
                 * @member {number} negativeIntValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * UninterpretedOption doubleValue.
                 * @member {number} doubleValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.doubleValue = 0;
    
                /**
                 * UninterpretedOption stringValue.
                 * @member {Uint8Array} stringValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.stringValue = $util.newBuffer([]);
    
                /**
                 * UninterpretedOption aggregateValue.
                 * @member {string} aggregateValue
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 */
                UninterpretedOption.prototype.aggregateValue = "";
    
                /**
                 * Creates a new UninterpretedOption instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
                 */
                UninterpretedOption.create = function create(properties) {
                    return new UninterpretedOption(properties);
                };
    
                /**
                 * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UninterpretedOption.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && message.name.length)
                        for (var i = 0; i < message.name.length; ++i)
                            $root.google.protobuf.UninterpretedOption.NamePart.encode(message.name[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.identifierValue != null && Object.hasOwnProperty.call(message, "identifierValue"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierValue);
                    if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, "positiveIntValue"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positiveIntValue);
                    if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, "negativeIntValue"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.negativeIntValue);
                    if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.doubleValue);
                    if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                        writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.stringValue);
                    if (message.aggregateValue != null && Object.hasOwnProperty.call(message, "aggregateValue"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.aggregateValue);
                    return writer;
                };
    
                /**
                 * Encodes the specified UninterpretedOption message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UninterpretedOption.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an UninterpretedOption message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UninterpretedOption.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2:
                            if (!(message.name && message.name.length))
                                message.name = [];
                            message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()));
                            break;
                        case 3:
                            message.identifierValue = reader.string();
                            break;
                        case 4:
                            message.positiveIntValue = reader.uint64();
                            break;
                        case 5:
                            message.negativeIntValue = reader.int64();
                            break;
                        case 6:
                            message.doubleValue = reader.double();
                            break;
                        case 7:
                            message.stringValue = reader.bytes();
                            break;
                        case 8:
                            message.aggregateValue = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an UninterpretedOption message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UninterpretedOption.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an UninterpretedOption message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UninterpretedOption.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        if (!Array.isArray(message.name))
                            return "name: array expected";
                        for (var i = 0; i < message.name.length; ++i) {
                            var error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                            if (error)
                                return "name." + error;
                        }
                    }
                    if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                        if (!$util.isString(message.identifierValue))
                            return "identifierValue: string expected";
                    if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                        if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                            return "positiveIntValue: integer|Long expected";
                    if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                        if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                            return "negativeIntValue: integer|Long expected";
                    if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                        if (typeof message.doubleValue !== "number")
                            return "doubleValue: number expected";
                    if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                        if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                            return "stringValue: buffer expected";
                    if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                        if (!$util.isString(message.aggregateValue))
                            return "aggregateValue: string expected";
                    return null;
                };
    
                /**
                 * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
                 */
                UninterpretedOption.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption)
                        return object;
                    var message = new $root.google.protobuf.UninterpretedOption();
                    if (object.name) {
                        if (!Array.isArray(object.name))
                            throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                        message.name = [];
                        for (var i = 0; i < object.name.length; ++i) {
                            if (typeof object.name[i] !== "object")
                                throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                            message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                        }
                    }
                    if (object.identifierValue != null)
                        message.identifierValue = String(object.identifierValue);
                    if (object.positiveIntValue != null)
                        if ($util.Long)
                            (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                        else if (typeof object.positiveIntValue === "string")
                            message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                        else if (typeof object.positiveIntValue === "number")
                            message.positiveIntValue = object.positiveIntValue;
                        else if (typeof object.positiveIntValue === "object")
                            message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                    if (object.negativeIntValue != null)
                        if ($util.Long)
                            (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                        else if (typeof object.negativeIntValue === "string")
                            message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                        else if (typeof object.negativeIntValue === "number")
                            message.negativeIntValue = object.negativeIntValue;
                        else if (typeof object.negativeIntValue === "object")
                            message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                    if (object.doubleValue != null)
                        message.doubleValue = Number(object.doubleValue);
                    if (object.stringValue != null)
                        if (typeof object.stringValue === "string")
                            $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                        else if (object.stringValue.length)
                            message.stringValue = object.stringValue;
                    if (object.aggregateValue != null)
                        message.aggregateValue = String(object.aggregateValue);
                    return message;
                };
    
                /**
                 * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption
                 * @static
                 * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UninterpretedOption.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.name = [];
                    if (options.defaults) {
                        object.identifierValue = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.positiveIntValue = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.negativeIntValue = options.longs === String ? "0" : 0;
                        object.doubleValue = 0;
                        if (options.bytes === String)
                            object.stringValue = "";
                        else {
                            object.stringValue = [];
                            if (options.bytes !== Array)
                                object.stringValue = $util.newBuffer(object.stringValue);
                        }
                        object.aggregateValue = "";
                    }
                    if (message.name && message.name.length) {
                        object.name = [];
                        for (var j = 0; j < message.name.length; ++j)
                            object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                    }
                    if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                        object.identifierValue = message.identifierValue;
                    if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                        if (typeof message.positiveIntValue === "number")
                            object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                        else
                            object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                    if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                        if (typeof message.negativeIntValue === "number")
                            object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                        else
                            object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                    if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                        object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                    if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                        object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                    if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                        object.aggregateValue = message.aggregateValue;
                    return object;
                };
    
                /**
                 * Converts this UninterpretedOption to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UninterpretedOption.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                UninterpretedOption.NamePart = (function() {
    
                    /**
                     * Properties of a NamePart.
                     * @memberof google.protobuf.UninterpretedOption
                     * @interface INamePart
                     * @property {string} namePart NamePart namePart
                     * @property {boolean} isExtension NamePart isExtension
                     */
    
                    /**
                     * Constructs a new NamePart.
                     * @memberof google.protobuf.UninterpretedOption
                     * @classdesc Represents a NamePart.
                     * @implements INamePart
                     * @constructor
                     * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                     */
                    function NamePart(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * NamePart namePart.
                     * @member {string} namePart
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     */
                    NamePart.prototype.namePart = "";
    
                    /**
                     * NamePart isExtension.
                     * @member {boolean} isExtension
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     */
                    NamePart.prototype.isExtension = false;
    
                    /**
                     * Creates a new NamePart instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                     */
                    NamePart.create = function create(properties) {
                        return new NamePart(properties);
                    };
    
                    /**
                     * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NamePart.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.namePart);
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExtension);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified NamePart message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NamePart.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a NamePart message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NamePart.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption.NamePart();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.namePart = reader.string();
                                break;
                            case 2:
                                message.isExtension = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        if (!message.hasOwnProperty("namePart"))
                            throw $util.ProtocolError("missing required 'namePart'", { instance: message });
                        if (!message.hasOwnProperty("isExtension"))
                            throw $util.ProtocolError("missing required 'isExtension'", { instance: message });
                        return message;
                    };
    
                    /**
                     * Decodes a NamePart message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NamePart.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a NamePart message.
                     * @function verify
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NamePart.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (!$util.isString(message.namePart))
                            return "namePart: string expected";
                        if (typeof message.isExtension !== "boolean")
                            return "isExtension: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                     */
                    NamePart.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                            return object;
                        var message = new $root.google.protobuf.UninterpretedOption.NamePart();
                        if (object.namePart != null)
                            message.namePart = String(object.namePart);
                        if (object.isExtension != null)
                            message.isExtension = Boolean(object.isExtension);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @static
                     * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NamePart.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.namePart = "";
                            object.isExtension = false;
                        }
                        if (message.namePart != null && message.hasOwnProperty("namePart"))
                            object.namePart = message.namePart;
                        if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                            object.isExtension = message.isExtension;
                        return object;
                    };
    
                    /**
                     * Converts this NamePart to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.UninterpretedOption.NamePart
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NamePart.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return NamePart;
                })();
    
                return UninterpretedOption;
            })();
    
            protobuf.SourceCodeInfo = (function() {
    
                /**
                 * Properties of a SourceCodeInfo.
                 * @memberof google.protobuf
                 * @interface ISourceCodeInfo
                 * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
                 */
    
                /**
                 * Constructs a new SourceCodeInfo.
                 * @memberof google.protobuf
                 * @classdesc Represents a SourceCodeInfo.
                 * @implements ISourceCodeInfo
                 * @constructor
                 * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
                 */
                function SourceCodeInfo(properties) {
                    this.location = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SourceCodeInfo location.
                 * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
                 * @memberof google.protobuf.SourceCodeInfo
                 * @instance
                 */
                SourceCodeInfo.prototype.location = $util.emptyArray;
    
                /**
                 * Creates a new SourceCodeInfo instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
                 */
                SourceCodeInfo.create = function create(properties) {
                    return new SourceCodeInfo(properties);
                };
    
                /**
                 * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SourceCodeInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.location != null && message.location.length)
                        for (var i = 0; i < message.location.length; ++i)
                            $root.google.protobuf.SourceCodeInfo.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SourceCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SourceCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SourceCodeInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SourceCodeInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.location && message.location.length))
                                message.location = [];
                            message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SourceCodeInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SourceCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SourceCodeInfo message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SourceCodeInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.location != null && message.hasOwnProperty("location")) {
                        if (!Array.isArray(message.location))
                            return "location: array expected";
                        for (var i = 0; i < message.location.length; ++i) {
                            var error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                            if (error)
                                return "location." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
                 */
                SourceCodeInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo)
                        return object;
                    var message = new $root.google.protobuf.SourceCodeInfo();
                    if (object.location) {
                        if (!Array.isArray(object.location))
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                        message.location = [];
                        for (var i = 0; i < object.location.length; ++i) {
                            if (typeof object.location[i] !== "object")
                                throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                            message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo
                 * @static
                 * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SourceCodeInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.location = [];
                    if (message.location && message.location.length) {
                        object.location = [];
                        for (var j = 0; j < message.location.length; ++j)
                            object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this SourceCodeInfo to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SourceCodeInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                SourceCodeInfo.Location = (function() {
    
                    /**
                     * Properties of a Location.
                     * @memberof google.protobuf.SourceCodeInfo
                     * @interface ILocation
                     * @property {Array.<number>|null} [path] Location path
                     * @property {Array.<number>|null} [span] Location span
                     * @property {string|null} [leadingComments] Location leadingComments
                     * @property {string|null} [trailingComments] Location trailingComments
                     * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                     */
    
                    /**
                     * Constructs a new Location.
                     * @memberof google.protobuf.SourceCodeInfo
                     * @classdesc Represents a Location.
                     * @implements ILocation
                     * @constructor
                     * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                     */
                    function Location(properties) {
                        this.path = [];
                        this.span = [];
                        this.leadingDetachedComments = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Location path.
                     * @member {Array.<number>} path
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.path = $util.emptyArray;
    
                    /**
                     * Location span.
                     * @member {Array.<number>} span
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.span = $util.emptyArray;
    
                    /**
                     * Location leadingComments.
                     * @member {string} leadingComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.leadingComments = "";
    
                    /**
                     * Location trailingComments.
                     * @member {string} trailingComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.trailingComments = "";
    
                    /**
                     * Location leadingDetachedComments.
                     * @member {Array.<string>} leadingDetachedComments
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     */
                    Location.prototype.leadingDetachedComments = $util.emptyArray;
    
                    /**
                     * Creates a new Location instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                     */
                    Location.create = function create(properties) {
                        return new Location(properties);
                    };
    
                    /**
                     * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Location.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.path != null && message.path.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.path.length; ++i)
                                writer.int32(message.path[i]);
                            writer.ldelim();
                        }
                        if (message.span != null && message.span.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.span.length; ++i)
                                writer.int32(message.span[i]);
                            writer.ldelim();
                        }
                        if (message.leadingComments != null && Object.hasOwnProperty.call(message, "leadingComments"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.leadingComments);
                        if (message.trailingComments != null && Object.hasOwnProperty.call(message, "trailingComments"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.trailingComments);
                        if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
                            for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.leadingDetachedComments[i]);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Location message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Location.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Location message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Location.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo.Location();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.path && message.path.length))
                                    message.path = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.path.push(reader.int32());
                                } else
                                    message.path.push(reader.int32());
                                break;
                            case 2:
                                if (!(message.span && message.span.length))
                                    message.span = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.span.push(reader.int32());
                                } else
                                    message.span.push(reader.int32());
                                break;
                            case 3:
                                message.leadingComments = reader.string();
                                break;
                            case 4:
                                message.trailingComments = reader.string();
                                break;
                            case 6:
                                if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                                    message.leadingDetachedComments = [];
                                message.leadingDetachedComments.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Location message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Location.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Location message.
                     * @function verify
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Location.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.path != null && message.hasOwnProperty("path")) {
                            if (!Array.isArray(message.path))
                                return "path: array expected";
                            for (var i = 0; i < message.path.length; ++i)
                                if (!$util.isInteger(message.path[i]))
                                    return "path: integer[] expected";
                        }
                        if (message.span != null && message.hasOwnProperty("span")) {
                            if (!Array.isArray(message.span))
                                return "span: array expected";
                            for (var i = 0; i < message.span.length; ++i)
                                if (!$util.isInteger(message.span[i]))
                                    return "span: integer[] expected";
                        }
                        if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                            if (!$util.isString(message.leadingComments))
                                return "leadingComments: string expected";
                        if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                            if (!$util.isString(message.trailingComments))
                                return "trailingComments: string expected";
                        if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                            if (!Array.isArray(message.leadingDetachedComments))
                                return "leadingDetachedComments: array expected";
                            for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                                if (!$util.isString(message.leadingDetachedComments[i]))
                                    return "leadingDetachedComments: string[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Location message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.SourceCodeInfo.Location} Location
                     */
                    Location.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                            return object;
                        var message = new $root.google.protobuf.SourceCodeInfo.Location();
                        if (object.path) {
                            if (!Array.isArray(object.path))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                            message.path = [];
                            for (var i = 0; i < object.path.length; ++i)
                                message.path[i] = object.path[i] | 0;
                        }
                        if (object.span) {
                            if (!Array.isArray(object.span))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                            message.span = [];
                            for (var i = 0; i < object.span.length; ++i)
                                message.span[i] = object.span[i] | 0;
                        }
                        if (object.leadingComments != null)
                            message.leadingComments = String(object.leadingComments);
                        if (object.trailingComments != null)
                            message.trailingComments = String(object.trailingComments);
                        if (object.leadingDetachedComments) {
                            if (!Array.isArray(object.leadingDetachedComments))
                                throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                            message.leadingDetachedComments = [];
                            for (var i = 0; i < object.leadingDetachedComments.length; ++i)
                                message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Location message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @static
                     * @param {google.protobuf.SourceCodeInfo.Location} message Location
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Location.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.path = [];
                            object.span = [];
                            object.leadingDetachedComments = [];
                        }
                        if (options.defaults) {
                            object.leadingComments = "";
                            object.trailingComments = "";
                        }
                        if (message.path && message.path.length) {
                            object.path = [];
                            for (var j = 0; j < message.path.length; ++j)
                                object.path[j] = message.path[j];
                        }
                        if (message.span && message.span.length) {
                            object.span = [];
                            for (var j = 0; j < message.span.length; ++j)
                                object.span[j] = message.span[j];
                        }
                        if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                            object.leadingComments = message.leadingComments;
                        if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                            object.trailingComments = message.trailingComments;
                        if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                            object.leadingDetachedComments = [];
                            for (var j = 0; j < message.leadingDetachedComments.length; ++j)
                                object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Location to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.SourceCodeInfo.Location
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Location.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Location;
                })();
    
                return SourceCodeInfo;
            })();
    
            protobuf.GeneratedCodeInfo = (function() {
    
                /**
                 * Properties of a GeneratedCodeInfo.
                 * @memberof google.protobuf
                 * @interface IGeneratedCodeInfo
                 * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
                 */
    
                /**
                 * Constructs a new GeneratedCodeInfo.
                 * @memberof google.protobuf
                 * @classdesc Represents a GeneratedCodeInfo.
                 * @implements IGeneratedCodeInfo
                 * @constructor
                 * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
                 */
                function GeneratedCodeInfo(properties) {
                    this.annotation = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * GeneratedCodeInfo annotation.
                 * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @instance
                 */
                GeneratedCodeInfo.prototype.annotation = $util.emptyArray;
    
                /**
                 * Creates a new GeneratedCodeInfo instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
                 */
                GeneratedCodeInfo.create = function create(properties) {
                    return new GeneratedCodeInfo(properties);
                };
    
                /**
                 * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCodeInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.annotation != null && message.annotation.length)
                        for (var i = 0; i < message.annotation.length; ++i)
                            $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(message.annotation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified GeneratedCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GeneratedCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCodeInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.annotation && message.annotation.length))
                                message.annotation = [];
                            message.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a GeneratedCodeInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GeneratedCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a GeneratedCodeInfo message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GeneratedCodeInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.annotation != null && message.hasOwnProperty("annotation")) {
                        if (!Array.isArray(message.annotation))
                            return "annotation: array expected";
                        for (var i = 0; i < message.annotation.length; ++i) {
                            var error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                            if (error)
                                return "annotation." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
                 */
                GeneratedCodeInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                        return object;
                    var message = new $root.google.protobuf.GeneratedCodeInfo();
                    if (object.annotation) {
                        if (!Array.isArray(object.annotation))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                        message.annotation = [];
                        for (var i = 0; i < object.annotation.length; ++i) {
                            if (typeof object.annotation[i] !== "object")
                                throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                            message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GeneratedCodeInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.annotation = [];
                    if (message.annotation && message.annotation.length) {
                        object.annotation = [];
                        for (var j = 0; j < message.annotation.length; ++j)
                            object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this GeneratedCodeInfo to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                GeneratedCodeInfo.Annotation = (function() {
    
                    /**
                     * Properties of an Annotation.
                     * @memberof google.protobuf.GeneratedCodeInfo
                     * @interface IAnnotation
                     * @property {Array.<number>|null} [path] Annotation path
                     * @property {string|null} [sourceFile] Annotation sourceFile
                     * @property {number|null} [begin] Annotation begin
                     * @property {number|null} [end] Annotation end
                     */
    
                    /**
                     * Constructs a new Annotation.
                     * @memberof google.protobuf.GeneratedCodeInfo
                     * @classdesc Represents an Annotation.
                     * @implements IAnnotation
                     * @constructor
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                     */
                    function Annotation(properties) {
                        this.path = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Annotation path.
                     * @member {Array.<number>} path
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.path = $util.emptyArray;
    
                    /**
                     * Annotation sourceFile.
                     * @member {string} sourceFile
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.sourceFile = "";
    
                    /**
                     * Annotation begin.
                     * @member {number} begin
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.begin = 0;
    
                    /**
                     * Annotation end.
                     * @member {number} end
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     */
                    Annotation.prototype.end = 0;
    
                    /**
                     * Creates a new Annotation instance using the specified properties.
                     * @function create
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                     */
                    Annotation.create = function create(properties) {
                        return new Annotation(properties);
                    };
    
                    /**
                     * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                     * @function encode
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Annotation.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.path != null && message.path.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.path.length; ++i)
                                writer.int32(message.path[i]);
                            writer.ldelim();
                        }
                        if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceFile);
                        if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.begin);
                        if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.end);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Annotation message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Annotation.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes an Annotation message from the specified reader or buffer.
                     * @function decode
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Annotation.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.path && message.path.length))
                                    message.path = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.path.push(reader.int32());
                                } else
                                    message.path.push(reader.int32());
                                break;
                            case 2:
                                message.sourceFile = reader.string();
                                break;
                            case 3:
                                message.begin = reader.int32();
                                break;
                            case 4:
                                message.end = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes an Annotation message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Annotation.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies an Annotation message.
                     * @function verify
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Annotation.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.path != null && message.hasOwnProperty("path")) {
                            if (!Array.isArray(message.path))
                                return "path: array expected";
                            for (var i = 0; i < message.path.length; ++i)
                                if (!$util.isInteger(message.path[i]))
                                    return "path: integer[] expected";
                        }
                        if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                            if (!$util.isString(message.sourceFile))
                                return "sourceFile: string expected";
                        if (message.begin != null && message.hasOwnProperty("begin"))
                            if (!$util.isInteger(message.begin))
                                return "begin: integer expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isInteger(message.end))
                                return "end: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                     */
                    Annotation.fromObject = function fromObject(object) {
                        if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                            return object;
                        var message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                        if (object.path) {
                            if (!Array.isArray(object.path))
                                throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                            message.path = [];
                            for (var i = 0; i < object.path.length; ++i)
                                message.path[i] = object.path[i] | 0;
                        }
                        if (object.sourceFile != null)
                            message.sourceFile = String(object.sourceFile);
                        if (object.begin != null)
                            message.begin = object.begin | 0;
                        if (object.end != null)
                            message.end = object.end | 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @static
                     * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Annotation.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.path = [];
                        if (options.defaults) {
                            object.sourceFile = "";
                            object.begin = 0;
                            object.end = 0;
                        }
                        if (message.path && message.path.length) {
                            object.path = [];
                            for (var j = 0; j < message.path.length; ++j)
                                object.path[j] = message.path[j];
                        }
                        if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                            object.sourceFile = message.sourceFile;
                        if (message.begin != null && message.hasOwnProperty("begin"))
                            object.begin = message.begin;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        return object;
                    };
    
                    /**
                     * Converts this Annotation to JSON.
                     * @function toJSON
                     * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Annotation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return Annotation;
                })();
    
                return GeneratedCodeInfo;
            })();
    
            protobuf.Timestamp = (function() {
    
                /**
                 * Properties of a Timestamp.
                 * @memberof google.protobuf
                 * @interface ITimestamp
                 * @property {number|null} [seconds] Timestamp seconds
                 * @property {number|null} [nanos] Timestamp nanos
                 */
    
                /**
                 * Constructs a new Timestamp.
                 * @memberof google.protobuf
                 * @classdesc Represents a Timestamp.
                 * @implements ITimestamp
                 * @constructor
                 * @param {google.protobuf.ITimestamp=} [properties] Properties to set
                 */
                function Timestamp(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Timestamp seconds.
                 * @member {number} seconds
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 */
                Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Timestamp nanos.
                 * @member {number} nanos
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 */
                Timestamp.prototype.nanos = 0;
    
                /**
                 * Creates a new Timestamp instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp=} [properties] Properties to set
                 * @returns {google.protobuf.Timestamp} Timestamp instance
                 */
                Timestamp.create = function create(properties) {
                    return new Timestamp(properties);
                };
    
                /**
                 * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timestamp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                    if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                    return writer;
                };
    
                /**
                 * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Timestamp message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Timestamp} Timestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timestamp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.seconds = reader.int64();
                            break;
                        case 2:
                            message.nanos = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Timestamp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Timestamp} Timestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timestamp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Timestamp message.
                 * @function verify
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Timestamp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                            return "seconds: integer|Long expected";
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        if (!$util.isInteger(message.nanos))
                            return "nanos: integer expected";
                    return null;
                };
    
                /**
                 * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Timestamp} Timestamp
                 */
                Timestamp.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Timestamp)
                        return object;
                    var message = new $root.google.protobuf.Timestamp();
                    if (object.seconds != null)
                        if ($util.Long)
                            (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                        else if (typeof object.seconds === "string")
                            message.seconds = parseInt(object.seconds, 10);
                        else if (typeof object.seconds === "number")
                            message.seconds = object.seconds;
                        else if (typeof object.seconds === "object")
                            message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                    if (object.nanos != null)
                        message.nanos = object.nanos | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.Timestamp} message Timestamp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Timestamp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.seconds = options.longs === String ? "0" : 0;
                        object.nanos = 0;
                    }
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        if (typeof message.seconds === "number")
                            object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                        else
                            object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        object.nanos = message.nanos;
                    return object;
                };
    
                /**
                 * Converts this Timestamp to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Timestamp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Timestamp;
            })();
    
            protobuf.Empty = (function() {
    
                /**
                 * Properties of an Empty.
                 * @memberof google.protobuf
                 * @interface IEmpty
                 */
    
                /**
                 * Constructs a new Empty.
                 * @memberof google.protobuf
                 * @classdesc Represents an Empty.
                 * @implements IEmpty
                 * @constructor
                 * @param {google.protobuf.IEmpty=} [properties] Properties to set
                 */
                function Empty(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new Empty instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Empty
                 * @static
                 * @param {google.protobuf.IEmpty=} [properties] Properties to set
                 * @returns {google.protobuf.Empty} Empty instance
                 */
                Empty.create = function create(properties) {
                    return new Empty(properties);
                };
    
                /**
                 * Encodes the specified Empty message. Does not implicitly {@link google.protobuf.Empty.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Empty
                 * @static
                 * @param {google.protobuf.IEmpty} message Empty message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Empty.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified Empty message, length delimited. Does not implicitly {@link google.protobuf.Empty.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Empty
                 * @static
                 * @param {google.protobuf.IEmpty} message Empty message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Empty.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Empty message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Empty
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Empty} Empty
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Empty.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Empty();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Empty message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Empty
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Empty} Empty
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Empty.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Empty message.
                 * @function verify
                 * @memberof google.protobuf.Empty
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Empty.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates an Empty message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Empty
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Empty} Empty
                 */
                Empty.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Empty)
                        return object;
                    return new $root.google.protobuf.Empty();
                };
    
                /**
                 * Creates a plain object from an Empty message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Empty
                 * @static
                 * @param {google.protobuf.Empty} message Empty
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Empty.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this Empty to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Empty
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Empty.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Empty;
            })();
    
            protobuf.FieldMask = (function() {
    
                /**
                 * Properties of a FieldMask.
                 * @memberof google.protobuf
                 * @interface IFieldMask
                 * @property {Array.<string>|null} [paths] FieldMask paths
                 */
    
                /**
                 * Constructs a new FieldMask.
                 * @memberof google.protobuf
                 * @classdesc Represents a FieldMask.
                 * @implements IFieldMask
                 * @constructor
                 * @param {google.protobuf.IFieldMask=} [properties] Properties to set
                 */
                function FieldMask(properties) {
                    this.paths = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FieldMask paths.
                 * @member {Array.<string>} paths
                 * @memberof google.protobuf.FieldMask
                 * @instance
                 */
                FieldMask.prototype.paths = $util.emptyArray;
    
                /**
                 * Creates a new FieldMask instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {google.protobuf.IFieldMask=} [properties] Properties to set
                 * @returns {google.protobuf.FieldMask} FieldMask instance
                 */
                FieldMask.create = function create(properties) {
                    return new FieldMask(properties);
                };
    
                /**
                 * Encodes the specified FieldMask message. Does not implicitly {@link google.protobuf.FieldMask.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {google.protobuf.IFieldMask} message FieldMask message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldMask.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.paths != null && message.paths.length)
                        for (var i = 0; i < message.paths.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.paths[i]);
                    return writer;
                };
    
                /**
                 * Encodes the specified FieldMask message, length delimited. Does not implicitly {@link google.protobuf.FieldMask.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {google.protobuf.IFieldMask} message FieldMask message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FieldMask.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FieldMask message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FieldMask} FieldMask
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldMask.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldMask();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.paths && message.paths.length))
                                message.paths = [];
                            message.paths.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FieldMask message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FieldMask} FieldMask
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FieldMask.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FieldMask message.
                 * @function verify
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FieldMask.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.paths != null && message.hasOwnProperty("paths")) {
                        if (!Array.isArray(message.paths))
                            return "paths: array expected";
                        for (var i = 0; i < message.paths.length; ++i)
                            if (!$util.isString(message.paths[i]))
                                return "paths: string[] expected";
                    }
                    return null;
                };
    
                /**
                 * Creates a FieldMask message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FieldMask} FieldMask
                 */
                FieldMask.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FieldMask)
                        return object;
                    var message = new $root.google.protobuf.FieldMask();
                    if (object.paths) {
                        if (!Array.isArray(object.paths))
                            throw TypeError(".google.protobuf.FieldMask.paths: array expected");
                        message.paths = [];
                        for (var i = 0; i < object.paths.length; ++i)
                            message.paths[i] = String(object.paths[i]);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a FieldMask message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FieldMask
                 * @static
                 * @param {google.protobuf.FieldMask} message FieldMask
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FieldMask.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.paths = [];
                    if (message.paths && message.paths.length) {
                        object.paths = [];
                        for (var j = 0; j < message.paths.length; ++j)
                            object.paths[j] = message.paths[j];
                    }
                    return object;
                };
    
                /**
                 * Converts this FieldMask to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FieldMask
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FieldMask.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return FieldMask;
            })();
    
            protobuf.DoubleValue = (function() {
    
                /**
                 * Properties of a DoubleValue.
                 * @memberof google.protobuf
                 * @interface IDoubleValue
                 * @property {number|null} [value] DoubleValue value
                 */
    
                /**
                 * Constructs a new DoubleValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a DoubleValue.
                 * @implements IDoubleValue
                 * @constructor
                 * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
                 */
                function DoubleValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * DoubleValue value.
                 * @member {number} value
                 * @memberof google.protobuf.DoubleValue
                 * @instance
                 */
                DoubleValue.prototype.value = 0;
    
                /**
                 * Creates a new DoubleValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
                 * @returns {google.protobuf.DoubleValue} DoubleValue instance
                 */
                DoubleValue.create = function create(properties) {
                    return new DoubleValue(properties);
                };
    
                /**
                 * Encodes the specified DoubleValue message. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.IDoubleValue} message DoubleValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DoubleValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 1 =*/9).double(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified DoubleValue message, length delimited. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.IDoubleValue} message DoubleValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DoubleValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a DoubleValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DoubleValue} DoubleValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DoubleValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DoubleValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.double();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a DoubleValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DoubleValue} DoubleValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DoubleValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a DoubleValue message.
                 * @function verify
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DoubleValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value !== "number")
                            return "value: number expected";
                    return null;
                };
    
                /**
                 * Creates a DoubleValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DoubleValue} DoubleValue
                 */
                DoubleValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DoubleValue)
                        return object;
                    var message = new $root.google.protobuf.DoubleValue();
                    if (object.value != null)
                        message.value = Number(object.value);
                    return message;
                };
    
                /**
                 * Creates a plain object from a DoubleValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.DoubleValue} message DoubleValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DoubleValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                    return object;
                };
    
                /**
                 * Converts this DoubleValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DoubleValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DoubleValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return DoubleValue;
            })();
    
            protobuf.FloatValue = (function() {
    
                /**
                 * Properties of a FloatValue.
                 * @memberof google.protobuf
                 * @interface IFloatValue
                 * @property {number|null} [value] FloatValue value
                 */
    
                /**
                 * Constructs a new FloatValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a FloatValue.
                 * @implements IFloatValue
                 * @constructor
                 * @param {google.protobuf.IFloatValue=} [properties] Properties to set
                 */
                function FloatValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * FloatValue value.
                 * @member {number} value
                 * @memberof google.protobuf.FloatValue
                 * @instance
                 */
                FloatValue.prototype.value = 0;
    
                /**
                 * Creates a new FloatValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.IFloatValue=} [properties] Properties to set
                 * @returns {google.protobuf.FloatValue} FloatValue instance
                 */
                FloatValue.create = function create(properties) {
                    return new FloatValue(properties);
                };
    
                /**
                 * Encodes the specified FloatValue message. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.IFloatValue} message FloatValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FloatValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 5 =*/13).float(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified FloatValue message, length delimited. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.IFloatValue} message FloatValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FloatValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a FloatValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FloatValue} FloatValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FloatValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FloatValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.float();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a FloatValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FloatValue} FloatValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FloatValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a FloatValue message.
                 * @function verify
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FloatValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value !== "number")
                            return "value: number expected";
                    return null;
                };
    
                /**
                 * Creates a FloatValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FloatValue} FloatValue
                 */
                FloatValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FloatValue)
                        return object;
                    var message = new $root.google.protobuf.FloatValue();
                    if (object.value != null)
                        message.value = Number(object.value);
                    return message;
                };
    
                /**
                 * Creates a plain object from a FloatValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.FloatValue} message FloatValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FloatValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                    return object;
                };
    
                /**
                 * Converts this FloatValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FloatValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FloatValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return FloatValue;
            })();
    
            protobuf.Int64Value = (function() {
    
                /**
                 * Properties of an Int64Value.
                 * @memberof google.protobuf
                 * @interface IInt64Value
                 * @property {number|null} [value] Int64Value value
                 */
    
                /**
                 * Constructs a new Int64Value.
                 * @memberof google.protobuf
                 * @classdesc Represents an Int64Value.
                 * @implements IInt64Value
                 * @constructor
                 * @param {google.protobuf.IInt64Value=} [properties] Properties to set
                 */
                function Int64Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Int64Value value.
                 * @member {number} value
                 * @memberof google.protobuf.Int64Value
                 * @instance
                 */
                Int64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Creates a new Int64Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.IInt64Value=} [properties] Properties to set
                 * @returns {google.protobuf.Int64Value} Int64Value instance
                 */
                Int64Value.create = function create(properties) {
                    return new Int64Value(properties);
                };
    
                /**
                 * Encodes the specified Int64Value message. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.IInt64Value} message Int64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int64Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified Int64Value message, length delimited. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.IInt64Value} message Int64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int64Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Int64Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Int64Value} Int64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int64Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int64Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Int64Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Int64Value} Int64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int64Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Int64Value message.
                 * @function verify
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Int64Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                            return "value: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates an Int64Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Int64Value} Int64Value
                 */
                Int64Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Int64Value)
                        return object;
                    var message = new $root.google.protobuf.Int64Value();
                    if (object.value != null)
                        if ($util.Long)
                            (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                        else if (typeof object.value === "string")
                            message.value = parseInt(object.value, 10);
                        else if (typeof object.value === "number")
                            message.value = object.value;
                        else if (typeof object.value === "object")
                            message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
                    return message;
                };
    
                /**
                 * Creates a plain object from an Int64Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.Int64Value} message Int64Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Int64Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.value = options.longs === String ? "0" : 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value === "number")
                            object.value = options.longs === String ? String(message.value) : message.value;
                        else
                            object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
                    return object;
                };
    
                /**
                 * Converts this Int64Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Int64Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Int64Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Int64Value;
            })();
    
            protobuf.UInt64Value = (function() {
    
                /**
                 * Properties of a UInt64Value.
                 * @memberof google.protobuf
                 * @interface IUInt64Value
                 * @property {number|null} [value] UInt64Value value
                 */
    
                /**
                 * Constructs a new UInt64Value.
                 * @memberof google.protobuf
                 * @classdesc Represents a UInt64Value.
                 * @implements IUInt64Value
                 * @constructor
                 * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
                 */
                function UInt64Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UInt64Value value.
                 * @member {number} value
                 * @memberof google.protobuf.UInt64Value
                 * @instance
                 */
                UInt64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
                /**
                 * Creates a new UInt64Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
                 * @returns {google.protobuf.UInt64Value} UInt64Value instance
                 */
                UInt64Value.create = function create(properties) {
                    return new UInt64Value(properties);
                };
    
                /**
                 * Encodes the specified UInt64Value message. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.IUInt64Value} message UInt64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt64Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified UInt64Value message, length delimited. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.IUInt64Value} message UInt64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt64Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UInt64Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UInt64Value} UInt64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt64Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt64Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UInt64Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UInt64Value} UInt64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt64Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UInt64Value message.
                 * @function verify
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UInt64Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                            return "value: integer|Long expected";
                    return null;
                };
    
                /**
                 * Creates a UInt64Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UInt64Value} UInt64Value
                 */
                UInt64Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UInt64Value)
                        return object;
                    var message = new $root.google.protobuf.UInt64Value();
                    if (object.value != null)
                        if ($util.Long)
                            (message.value = $util.Long.fromValue(object.value)).unsigned = true;
                        else if (typeof object.value === "string")
                            message.value = parseInt(object.value, 10);
                        else if (typeof object.value === "number")
                            message.value = object.value;
                        else if (typeof object.value === "object")
                            message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber(true);
                    return message;
                };
    
                /**
                 * Creates a plain object from a UInt64Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.UInt64Value} message UInt64Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UInt64Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.value = options.longs === String ? "0" : 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value === "number")
                            object.value = options.longs === String ? String(message.value) : message.value;
                        else
                            object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber(true) : message.value;
                    return object;
                };
    
                /**
                 * Converts this UInt64Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UInt64Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UInt64Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return UInt64Value;
            })();
    
            protobuf.Int32Value = (function() {
    
                /**
                 * Properties of an Int32Value.
                 * @memberof google.protobuf
                 * @interface IInt32Value
                 * @property {number|null} [value] Int32Value value
                 */
    
                /**
                 * Constructs a new Int32Value.
                 * @memberof google.protobuf
                 * @classdesc Represents an Int32Value.
                 * @implements IInt32Value
                 * @constructor
                 * @param {google.protobuf.IInt32Value=} [properties] Properties to set
                 */
                function Int32Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Int32Value value.
                 * @member {number} value
                 * @memberof google.protobuf.Int32Value
                 * @instance
                 */
                Int32Value.prototype.value = 0;
    
                /**
                 * Creates a new Int32Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.IInt32Value=} [properties] Properties to set
                 * @returns {google.protobuf.Int32Value} Int32Value instance
                 */
                Int32Value.create = function create(properties) {
                    return new Int32Value(properties);
                };
    
                /**
                 * Encodes the specified Int32Value message. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.IInt32Value} message Int32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int32Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified Int32Value message, length delimited. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.IInt32Value} message Int32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int32Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an Int32Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Int32Value} Int32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int32Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int32Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an Int32Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Int32Value} Int32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int32Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an Int32Value message.
                 * @function verify
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Int32Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value))
                            return "value: integer expected";
                    return null;
                };
    
                /**
                 * Creates an Int32Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Int32Value} Int32Value
                 */
                Int32Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Int32Value)
                        return object;
                    var message = new $root.google.protobuf.Int32Value();
                    if (object.value != null)
                        message.value = object.value | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from an Int32Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.Int32Value} message Int32Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Int32Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };
    
                /**
                 * Converts this Int32Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Int32Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Int32Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Int32Value;
            })();
    
            protobuf.UInt32Value = (function() {
    
                /**
                 * Properties of a UInt32Value.
                 * @memberof google.protobuf
                 * @interface IUInt32Value
                 * @property {number|null} [value] UInt32Value value
                 */
    
                /**
                 * Constructs a new UInt32Value.
                 * @memberof google.protobuf
                 * @classdesc Represents a UInt32Value.
                 * @implements IUInt32Value
                 * @constructor
                 * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
                 */
                function UInt32Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * UInt32Value value.
                 * @member {number} value
                 * @memberof google.protobuf.UInt32Value
                 * @instance
                 */
                UInt32Value.prototype.value = 0;
    
                /**
                 * Creates a new UInt32Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
                 * @returns {google.protobuf.UInt32Value} UInt32Value instance
                 */
                UInt32Value.create = function create(properties) {
                    return new UInt32Value(properties);
                };
    
                /**
                 * Encodes the specified UInt32Value message. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.IUInt32Value} message UInt32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt32Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified UInt32Value message, length delimited. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.IUInt32Value} message UInt32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt32Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a UInt32Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UInt32Value} UInt32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt32Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt32Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.uint32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a UInt32Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UInt32Value} UInt32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt32Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a UInt32Value message.
                 * @function verify
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UInt32Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value))
                            return "value: integer expected";
                    return null;
                };
    
                /**
                 * Creates a UInt32Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UInt32Value} UInt32Value
                 */
                UInt32Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UInt32Value)
                        return object;
                    var message = new $root.google.protobuf.UInt32Value();
                    if (object.value != null)
                        message.value = object.value >>> 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a UInt32Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.UInt32Value} message UInt32Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UInt32Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };
    
                /**
                 * Converts this UInt32Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UInt32Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UInt32Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return UInt32Value;
            })();
    
            protobuf.BoolValue = (function() {
    
                /**
                 * Properties of a BoolValue.
                 * @memberof google.protobuf
                 * @interface IBoolValue
                 * @property {boolean|null} [value] BoolValue value
                 */
    
                /**
                 * Constructs a new BoolValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a BoolValue.
                 * @implements IBoolValue
                 * @constructor
                 * @param {google.protobuf.IBoolValue=} [properties] Properties to set
                 */
                function BoolValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BoolValue value.
                 * @member {boolean} value
                 * @memberof google.protobuf.BoolValue
                 * @instance
                 */
                BoolValue.prototype.value = false;
    
                /**
                 * Creates a new BoolValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.IBoolValue=} [properties] Properties to set
                 * @returns {google.protobuf.BoolValue} BoolValue instance
                 */
                BoolValue.create = function create(properties) {
                    return new BoolValue(properties);
                };
    
                /**
                 * Encodes the specified BoolValue message. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.IBoolValue} message BoolValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BoolValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified BoolValue message, length delimited. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.IBoolValue} message BoolValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BoolValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BoolValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.BoolValue} BoolValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BoolValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BoolValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BoolValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.BoolValue} BoolValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BoolValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BoolValue message.
                 * @function verify
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BoolValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value !== "boolean")
                            return "value: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a BoolValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.BoolValue} BoolValue
                 */
                BoolValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.BoolValue)
                        return object;
                    var message = new $root.google.protobuf.BoolValue();
                    if (object.value != null)
                        message.value = Boolean(object.value);
                    return message;
                };
    
                /**
                 * Creates a plain object from a BoolValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.BoolValue} message BoolValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BoolValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = false;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };
    
                /**
                 * Converts this BoolValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.BoolValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BoolValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return BoolValue;
            })();
    
            protobuf.StringValue = (function() {
    
                /**
                 * Properties of a StringValue.
                 * @memberof google.protobuf
                 * @interface IStringValue
                 * @property {string|null} [value] StringValue value
                 */
    
                /**
                 * Constructs a new StringValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a StringValue.
                 * @implements IStringValue
                 * @constructor
                 * @param {google.protobuf.IStringValue=} [properties] Properties to set
                 */
                function StringValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * StringValue value.
                 * @member {string} value
                 * @memberof google.protobuf.StringValue
                 * @instance
                 */
                StringValue.prototype.value = "";
    
                /**
                 * Creates a new StringValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.IStringValue=} [properties] Properties to set
                 * @returns {google.protobuf.StringValue} StringValue instance
                 */
                StringValue.create = function create(properties) {
                    return new StringValue(properties);
                };
    
                /**
                 * Encodes the specified StringValue message. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.IStringValue} message StringValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StringValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified StringValue message, length delimited. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.IStringValue} message StringValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StringValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a StringValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.StringValue} StringValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StringValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.StringValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a StringValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.StringValue} StringValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StringValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a StringValue message.
                 * @function verify
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StringValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    return null;
                };
    
                /**
                 * Creates a StringValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.StringValue} StringValue
                 */
                StringValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.StringValue)
                        return object;
                    var message = new $root.google.protobuf.StringValue();
                    if (object.value != null)
                        message.value = String(object.value);
                    return message;
                };
    
                /**
                 * Creates a plain object from a StringValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.StringValue} message StringValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StringValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = "";
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };
    
                /**
                 * Converts this StringValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.StringValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StringValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return StringValue;
            })();
    
            protobuf.BytesValue = (function() {
    
                /**
                 * Properties of a BytesValue.
                 * @memberof google.protobuf
                 * @interface IBytesValue
                 * @property {Uint8Array|null} [value] BytesValue value
                 */
    
                /**
                 * Constructs a new BytesValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a BytesValue.
                 * @implements IBytesValue
                 * @constructor
                 * @param {google.protobuf.IBytesValue=} [properties] Properties to set
                 */
                function BytesValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BytesValue value.
                 * @member {Uint8Array} value
                 * @memberof google.protobuf.BytesValue
                 * @instance
                 */
                BytesValue.prototype.value = $util.newBuffer([]);
    
                /**
                 * Creates a new BytesValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.IBytesValue=} [properties] Properties to set
                 * @returns {google.protobuf.BytesValue} BytesValue instance
                 */
                BytesValue.create = function create(properties) {
                    return new BytesValue(properties);
                };
    
                /**
                 * Encodes the specified BytesValue message. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.IBytesValue} message BytesValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BytesValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.value);
                    return writer;
                };
    
                /**
                 * Encodes the specified BytesValue message, length delimited. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.IBytesValue} message BytesValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BytesValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BytesValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.BytesValue} BytesValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BytesValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BytesValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.bytes();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BytesValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.BytesValue} BytesValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BytesValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BytesValue message.
                 * @function verify
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BytesValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                            return "value: buffer expected";
                    return null;
                };
    
                /**
                 * Creates a BytesValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.BytesValue} BytesValue
                 */
                BytesValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.BytesValue)
                        return object;
                    var message = new $root.google.protobuf.BytesValue();
                    if (object.value != null)
                        if (typeof object.value === "string")
                            $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                        else if (object.value.length)
                            message.value = object.value;
                    return message;
                };
    
                /**
                 * Creates a plain object from a BytesValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.BytesValue} message BytesValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BytesValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if (options.bytes === String)
                            object.value = "";
                        else {
                            object.value = [];
                            if (options.bytes !== Array)
                                object.value = $util.newBuffer(object.value);
                        }
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                    return object;
                };
    
                /**
                 * Converts this BytesValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.BytesValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BytesValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return BytesValue;
            })();
    
            protobuf.Duration = (function() {
    
                /**
                 * Properties of a Duration.
                 * @memberof google.protobuf
                 * @interface IDuration
                 * @property {number|null} [seconds] Duration seconds
                 * @property {number|null} [nanos] Duration nanos
                 */
    
                /**
                 * Constructs a new Duration.
                 * @memberof google.protobuf
                 * @classdesc Represents a Duration.
                 * @implements IDuration
                 * @constructor
                 * @param {google.protobuf.IDuration=} [properties] Properties to set
                 */
                function Duration(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Duration seconds.
                 * @member {number} seconds
                 * @memberof google.protobuf.Duration
                 * @instance
                 */
                Duration.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Duration nanos.
                 * @member {number} nanos
                 * @memberof google.protobuf.Duration
                 * @instance
                 */
                Duration.prototype.nanos = 0;
    
                /**
                 * Creates a new Duration instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Duration
                 * @static
                 * @param {google.protobuf.IDuration=} [properties] Properties to set
                 * @returns {google.protobuf.Duration} Duration instance
                 */
                Duration.create = function create(properties) {
                    return new Duration(properties);
                };
    
                /**
                 * Encodes the specified Duration message. Does not implicitly {@link google.protobuf.Duration.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Duration
                 * @static
                 * @param {google.protobuf.IDuration} message Duration message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Duration.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                    if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                    return writer;
                };
    
                /**
                 * Encodes the specified Duration message, length delimited. Does not implicitly {@link google.protobuf.Duration.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Duration
                 * @static
                 * @param {google.protobuf.IDuration} message Duration message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Duration.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Duration message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Duration
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Duration} Duration
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Duration.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Duration();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.seconds = reader.int64();
                            break;
                        case 2:
                            message.nanos = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Duration message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Duration
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Duration} Duration
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Duration.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Duration message.
                 * @function verify
                 * @memberof google.protobuf.Duration
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Duration.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                            return "seconds: integer|Long expected";
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        if (!$util.isInteger(message.nanos))
                            return "nanos: integer expected";
                    return null;
                };
    
                /**
                 * Creates a Duration message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Duration
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Duration} Duration
                 */
                Duration.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Duration)
                        return object;
                    var message = new $root.google.protobuf.Duration();
                    if (object.seconds != null)
                        if ($util.Long)
                            (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                        else if (typeof object.seconds === "string")
                            message.seconds = parseInt(object.seconds, 10);
                        else if (typeof object.seconds === "number")
                            message.seconds = object.seconds;
                        else if (typeof object.seconds === "object")
                            message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                    if (object.nanos != null)
                        message.nanos = object.nanos | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a Duration message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Duration
                 * @static
                 * @param {google.protobuf.Duration} message Duration
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Duration.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.seconds = options.longs === String ? "0" : 0;
                        object.nanos = 0;
                    }
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        if (typeof message.seconds === "number")
                            object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                        else
                            object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        object.nanos = message.nanos;
                    return object;
                };
    
                /**
                 * Converts this Duration to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Duration
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Duration.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Duration;
            })();
    
            return protobuf;
        })();
    
        return google;
    })();

    return $root;
});
